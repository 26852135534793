import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';
import GmailIntegrationAlertContainer from '../../containers/gmailIntegrationAlertContainer';
import GmailConnected from '../gmailConnected';
import GmailNotConnected from '../gmailNotConnected';
import './gmailIntegration.scss';

class GmailIntegration extends Component {
  render() {
    const {
      gmailIntegration: { fetching },
    } = this.props;

    return (
      <div className="gmail-integration">
        <GmailIntegrationAlertContainer />
        {fetching ? (
          <div className="spinner">
            <LoadingSpinner size={50} imageUrl={getImageUrl} />
          </div>
        ) : (
          this._getGmailView()
        )}
      </div>
    );
  }

  _getGmailView = () => {
    const {
      actionCreators: { connectGoogle },
      gmailIntegration,
    } = this.props;
    const isConnected = gmailIntegration['valid_credentials?'];

    return isConnected ? (
      <GmailConnected {...this.props} />
    ) : (
      <GmailNotConnected connectGoogle={connectGoogle} />
    );
  };
}

GmailIntegration.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  gmailIntegration: PropTypes.object.isRequired,
};

export default GmailIntegration;
