import intersection from 'lodash/intersection';
import get from 'lodash/get';
import I18N from 'languages';

export function isAdmin() {
  const account = window.toutAppBootstrap.accountData;
  return account.admin || false;
}

export function isCurrentUser(userId) {
  const account = window.toutAppBootstrap.accountData;
  return userId === account.id;
}

const mapIdList = (ids = []) => {
  const map = {};
  ids.forEach((id) => {
    map[id] = true;
  });
  return map;
};

export function isMyTeamFilterEnabled() {
  return get(
    window.toutAppBootstrap,
    'accountData.subscription.template_my_team_filter_enabled',
    false
  );
}

/* Everyone is currently fixed in team names so using i18n */
const teams = [];
const getMemoizedTeams = () => {
  if (teams.length) {
    return teams;
  }

  const account = window.toutAppBootstrap.accountData || {
    subscription: { teams: [] },
  };
  return [
    { ...account.subscription.teams[0], name: I18N.getStr('common.everyone') },
    ...account.subscription.teams.slice(1),
  ];
};

export function getTeams(ids) {
  const account = window.toutAppBootstrap.accountData;
  const idList = ids ? mapIdList(ids) : mapIdList(account.team_ids);
  return getMemoizedTeams().filter((elem) => account.admin || idList[elem.id]);
}

export function getAdminTeams() {
  const account = window.toutAppBootstrap.accountData;
  return getTeams().filter((elem) => elem.admin_ids.indexOf(account.id) > -1);
}

export function commonUserAdminTeamPermission(userId) {
  const account = window.toutAppBootstrap.accountData;
  return (
    isCurrentUser(userId) ||
    account.admin === true ||
    intersection(getAdminTeams().map((team) => team.id), account.team_ids)
      .length > 0
  );
}

export function isMemberOfTeams(teamIdsToCheckInclusionIn) {
  const { team_ids: teamIds } = window.toutAppBootstrap.accountData;
  return intersection(teamIdsToCheckInclusionIn, teamIds).length > 0;
}
