import React from 'react';
import PropTypes from 'prop-types';
import DropdownMenuItem from 'components/dropdownMenuItem';
import './dropdownMenu.scss';

const DropdownMenu = ({ items, bottom, stickToTop }) => {
  let topAdjusted, bottomAdjusted;
  if (bottom == null) {
    topAdjusted = stickToTop ? '8px' : 'calc(50% - 10px)';
    bottomAdjusted = 'unset';
  } else {
    topAdjusted = 'unset';
    bottomAdjusted = '13px';
  }
  return (
    <div
      className="tout-dropdown-menu"
      style={{
        '--bottom-adjusted-before': bottomAdjusted,
        '--top-adjusted-before': topAdjusted,
      }}
    >
      {items.map((item, index) => {
        const { label, action, disabled, id } = item;
        return (
          <div
            className="tout-dropdown-menu-item-container"
            key={`tout-dropdown-menu-item-${label}`}
          >
            <DropdownMenuItem
              disabled={disabled}
              id={id}
              label={label}
              onSelect={action}
            />
            {index !== items.length - 1 && (
              <div className="tout-menu-divider" />
            )}
          </div>
        );
      })}
    </div>
  );
};

DropdownMenu.propTypes = {
  bottom: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  stickToTop: PropTypes.bool,
};

export default DropdownMenu;
