import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SettingsUnsubscribesEditor from 'web/settings/unsubscribes/components/settingsUnsubscribesEditor';
import { saveUserUnsubscribes } from '../actionCreators/userUnsubscribesActionCreators';
import { updateUnsubscribeSettings } from 'web/settings/unsubscribes/actionCreators/settingsUnsubscribesActionCreators';

class UserUnsubscribesEditorContainer extends Component {
  render() {
    const { defaultBody, save } = this.props;

    return (
      <SettingsUnsubscribesEditor
        save={save}
        updateEditorBody={this.updateEditorBody}
        id="user-unsubscribe-settings"
        defaultBody={defaultBody}
        options={{
          addDefaultLink: true,
          disableDynamicFields: true,
          disableAddContent: true,
        }}
      />
    );
  }

  updateEditorBody = (body) => {
    const { updateSettings } = this.props;

    updateSettings({
      block_unsubscribe: body,
    });
  };
}

UserUnsubscribesEditorContainer.propTypes = {
  defaultBody: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  defaultBody: state.settingsUnsubscribes.block_unsubscribe,
});

const mapDispatchToProps = {
  save: saveUserUnsubscribes,
  updateSettings: updateUnsubscribeSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserUnsubscribesEditorContainer);
