import { createSelector } from 'reselect';
import { DateTypes } from 'web/liveFeed/tasks/libs/tasksConstants';
import moment from 'moment';
import {
  sortTodoPriorityAndTime,
  sortOverduePriorityAndTime,
  getFilterDates,
} from 'web/liveFeed/tasks/helpers/tasksSortFilter';

const tasksUncompleted = (state) => state.tasksUncompleted;
const selectedTodoDateFilter = (state) =>
  state.selectedTodoDateFilter || DateTypes.todayAndTomorrow;
const selectedOverdueDateFilter = (state) =>
  state.selectedOverdueDateFilter || DateTypes.lastThreeDays;

export const todoFilteredSortedTasks = createSelector(
  selectedTodoDateFilter,
  tasksUncompleted,
  (dateFilter, tasks) => {
    const { start, end } = getFilterDates(dateFilter);
    return tasks
      .filter((task) => {
        const remindAt = moment(task.remind_at);
        // MT: Once we upgrade to moment v2.13.0+, we could use
        // the inclusivity operators and do something like
        // `remindAt.isBetween(start, end, null, '[]')`
        return remindAt.isSameOrAfter(start) && remindAt.isSameOrBefore(end);
      })
      .sort(sortTodoPriorityAndTime)
      .toList()
      .toArray();
  }
);

export const overdueFilteredSortedTasks = createSelector(
  selectedOverdueDateFilter,
  tasksUncompleted,
  (dateFilter, tasks) => {
    const { start, end } = getFilterDates(dateFilter);
    return tasks
      .filter((task) => {
        const remindAt = moment(task.remind_at);
        return remindAt.isSameOrAfter(start) && remindAt.isSameOrBefore(end);
      })
      .sort(sortOverduePriorityAndTime)
      .toList()
      .toArray();
  }
);
