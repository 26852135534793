import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { PopupSize } from 'libs/constantsShared';
import { getGroups } from './selectors/campaignsTabGroupsSelectors';
import Popup, { PopupBackgroundColors } from 'components/popups';
import CreateNewCategory from 'web/categories/components/createNewCategory/createNewCategory';
import CreateNewCampaign from 'web/campaigns/campaignsTab/components/createNewCampaign';
import ShareCampaign from 'web/campaigns/campaignsTab/components/shareCampaign/shareCampaign';
import CampaignsUpdateCategory from 'web/campaigns/campaignsTab/components/campaignsUpdateCategory';
import CloneCampaignConfimation from 'web/campaigns/campaignsTab/components/cloneCampaignConfirmation';
import {
  GlobalAlertIds,
  GlobalPopupIds,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import {
  createCampaign,
  createNewCampaignOnChange,
  updateStateCampaign,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsActionCreators';
import { affirmCloneCampaign } from 'web/campaigns/campaignsTab/actionCreators/campaignsCloneActionCreators';
import { updatePreview } from 'web/previewEmails/actionCreators/previewEmailsActionCreators';
import {
  updateScheduledLaunch,
  updateWhen,
} from 'web/previewEmails/actionCreators/scheduledLaunchActionCreators';
import {
  addAndLaunchCampaignEmail,
  addAndLaunchCampaignTask,
  backToPreview,
  checkTimeNotPast,
  donePreviews,
  saveAndChangePreview,
} from 'web/previewEmails/actionCreators/previewEmailsExternalActionCreators';
import {
  defaultCatchAlert,
  warnDataLoss,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsTabPopupActionCreators';
import {
  clearPopupAlert,
  closePopup,
  openPopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  createNewCategory,
  createNewCategoryOnChange,
  deleteCategoryConfirm,
  updateCategoryInput,
  updateCategory,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsCategoriesActionCreators';
import ConfirmDelete from 'web/campaigns/campaignsTab/components/confirmDelete/confirmDelete';
import CampaignActiveWarning from 'web/campaigns/campaignsTab/components/campaignActiveWarning/campaignActiveWarning';
import { deleteCampaign } from './actionCreators/campaignDetailsActionCreators';
import {
  shareCampaign,
  updateCampaignSharedWith,
  confirmShareCampaign,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsShareActionCreators';
import { getAlert } from 'web/campaigns/campaignsTab/helpers/campaignsTabPopup';
import { removeActivePeople } from 'web/campaigns/people/actionCreators/campaignsPeopleActionCreators';
import CampaignEditWarning from 'web/campaigns/campaignsTab/components/campaignEditWarning/campaignEditWarning';
import { addPeopleToCampaign } from 'web/modals/addToCampaign/actionCreators/addPeopleActionCreators';
import find from 'lodash/find';

class GlobalPopupContainer extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { dispatch } = this.props;

    const previewEmailsActionCreators = {
      defaultCatch: (err) =>
        defaultCatchAlert(err, GlobalAlertIds.launchCampaignIssue),
      invalidDynamicFields: (invalid) =>
        setPopupAlert(GlobalAlertIds.invalidPreviewDynamicFields, invalid),
      invalidInput: (invalid) =>
        setPopupAlert(GlobalAlertIds.invalidPreviewInput, invalid),
      invalidPromptFields: (invalid) =>
        setPopupAlert(GlobalAlertIds.invalidPreviewPromptFields, invalid),
      launched: (data) => openPopup(GlobalPopupIds.campaignLaunched, data),
      loading: (state) => setPopupLoading(state),
      openPreviews: (isEmail) =>
        openPopup(
          isEmail ? GlobalPopupIds.previewEmail : GlobalPopupIds.previewTask
        ),
      openSchedule: () => openPopup(GlobalPopupIds.scheduleLaunch),
      openSchedulePast: () => openPopup(GlobalPopupIds.scheduleLaunchPast),
      warnDataLoss: (state) => warnDataLoss(state),
    };

    this.actionCreators = {
      addPeopleToCampaign: () => dispatch(addPeopleToCampaign()),
      affirmCloneCampaign: (category, id) =>
        dispatch(affirmCloneCampaign(category, id)),
      backToPreview: () => dispatch(backToPreview(previewEmailsActionCreators)),
      backToScheduleLaunch: () =>
        dispatch(openPopup(GlobalPopupIds.scheduleLaunch)),
      changePreview: (personId, viewType) =>
        dispatch(
          saveAndChangePreview(personId, viewType, previewEmailsActionCreators)
        ),
      checkTimeNotPast: () =>
        dispatch(checkTimeNotPast(previewEmailsActionCreators)),
      closePopup: () => dispatch(closePopup()),
      confirmShareCampaign: () =>
        dispatch(confirmShareCampaign(this.props.selectedCampaign)),
      createCampaign: () => dispatch(createCampaign()),
      createNewCampaignOnChange: (data) =>
        dispatch(createNewCampaignOnChange(data)),
      createNewCategoryOnChange: (cat) =>
        dispatch(createNewCategoryOnChange(cat)),
      deleteCampaign: () => dispatch(deleteCampaign()),
      deleteCategoryConfirm: (id) => dispatch(deleteCategoryConfirm(id)),
      donePreviews: () => dispatch(donePreviews(previewEmailsActionCreators)),
      hideAlert: () => dispatch(clearPopupAlert()),
      launchCampaignEmail: () =>
        dispatch(addAndLaunchCampaignEmail(previewEmailsActionCreators)),
      launchCampaignTask: () =>
        dispatch(addAndLaunchCampaignTask(previewEmailsActionCreators)),
      removeActivePeople: (ids, markSuccess, callback) =>
        dispatch(removeActivePeople(ids, markSuccess, callback)),
      saveNewCategory: () => dispatch(createNewCategory()),
      saveShareCampaign: () =>
        dispatch(shareCampaign(this.props.selectedCampaign)),
      setLoading: (state) => dispatch(setPopupLoading(state)),
      updateCampaignSharedWith: (ids) =>
        dispatch(updateCampaignSharedWith(ids)),
      updateCategory: () =>
        dispatch(updateCategory(this.props.selectedCampaign)),
      updateCategoryInput: (cat) => dispatch(updateCategoryInput(cat)),
      updatePreview: (id, data) => dispatch(updatePreview(data)),
      updateScheduledLaunch: (data) => dispatch(updateScheduledLaunch(data)),
      updateStateCampaign: (data) => dispatch(updateStateCampaign(data)),
      updateWhen: (when) => dispatch(updateWhen(when)),
      warnDataLoss: () => dispatch(warnDataLoss()),
      warnDataLossHide: () => dispatch(warnDataLoss(true)),
    };
  }

  render() {
    const { popup } = this.props;
    let object = {};
    if (popup) {
      object = this._getGlobalPopupObject();
    }
    return <div>{popup && object.children && <Popup {...object} />}</div>;
  }

  _getGlobalPopupObject() {
    const { alert, alertTextValues, loading } = this.props;
    return {
      ...this._getMainComponent(),
      alert: getAlert(alert, alertTextValues, this.actionCreators),
      getImageUrl,
      loading,
      ...this._getPopupOverlay(),
    };
  }

  _getPopupOverlay() {
    const {
      dataLossTextId,
      intl: { formatMessage },
      unshareCampaignWarning,
    } = this.props;

    if (Object.keys(unshareCampaignWarning).length > 0) {
      return {
        onOutsideClick: this.actionCreators.closePopup,
        overlay: {
          color: PopupBackgroundColors.yellow,
          footer: {
            onPrimary: this.actionCreators.confirmShareCampaign,
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.unshare',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
          },
          textId: 'web.campaigns.unshareStopCampaigns',
          textValues: { active_count: unshareCampaignWarning.activeCount },
        },
      };
    } else if (dataLossTextId.length) {
      return {
        onOutsideClick: this.actionCreators.closePopup,
        overlay: {
          color: PopupBackgroundColors.white,
          footer: {
            onPrimary: this.actionCreators.closePopup,
            onSecondary: this.actionCreators.warnDataLossHide,
            primaryTextId: 'common.close',
            secondaryTextId: 'common.back',
          },
          header: {
            onClose: this.actionCreators.closePopup,
          },
          textId: 'web.campaigns.warnChangesLoss',
          textValues: { dataLosing: formatMessage({ id: dataLossTextId }) },
        },
      };
    } else {
      return undefined;
    }
  }

  _getMainComponent() {
    /*eslint complexity: [1, 100]*/
    const { popup } = this.props;

    switch (popup) {
      case GlobalPopupIds.createNewCampaign: {
        const { campaignCategories, createNewCampaignState } = this.props;

        return {
          children: (
            <CreateNewCampaign
              categories={campaignCategories}
              onChange={this.actionCreators.createNewCampaignOnChange}
              state={createNewCampaignState}
              updateState={this.actionCreators.updateStateCampaign}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.createCampaign,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled: !(
              createNewCampaignState.category &&
              createNewCampaignState.name.trim().length > 0
            ),
            primaryTextId: 'common.continue',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.createNewCampaign',
          },
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.createNewCategory: {
        const {
          newCategoryState: { isNewCampaign, name },
        } = this.props;
        return {
          children: (
            <CreateNewCategory
              isFirstCampaign={isNewCampaign}
              newCategory={name}
              onCategoryChange={this.actionCreators.createNewCategoryOnChange}
              onCreate={this.actionCreators.saveNewCategory}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.saveNewCategory,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled: !name,
            primaryTextId: 'common.create',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.createNewCategory',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.confirmCampaignDeletion: {
        const { deleteCampaignState } = this.props;
        return {
          children: (
            <ConfirmDelete
              admin={deleteCampaignState.admin}
              teamMembers={deleteCampaignState.teamMembers}
              userActives={deleteCampaignState.userActives}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.deleteCampaign,
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.ok',
            secondaryTextId: 'common.cancel',
          },
          header: {
            textId: 'web.campaigns.confirmDelete',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.campaignActiveWarning: {
        return {
          children: <CampaignActiveWarning />,
          footer: {
            onPrimary: this.actionCreators.closePopup,
            primaryTextId: 'common.ok',
          },
          header: {
            textId: 'web.campaigns.cannotPerformAction',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.shareCampaign: {
        const { selectedCampaign, newSharedWith } = this.props;
        return {
          children: (
            <ShareCampaign
              newSharedWith={newSharedWith}
              selectedCampaign={selectedCampaign}
              updateCampaign={this.actionCreators.shareCampaign}
              updateCampaignSharedWith={
                this.actionCreators.updateCampaignSharedWith
              }
            />
          ),
          footer: {
            onPrimary: this.actionCreators.saveShareCampaign,
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.share',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.shareCampaign',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.updateCampaignCategory: {
        const {
          selectedCampaign,
          campaignCategories,
          updatedCategory,
        } = this.props;
        return {
          children: (
            <CampaignsUpdateCategory
              categories={campaignCategories}
              selectedCampaign={selectedCampaign}
              updateCategory={this.actionCreators.updateCategoryInput}
              updatedCategory={updatedCategory}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.updateCategory,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled:
              !updatedCategory ||
              !updatedCategory.value ||
              selectedCampaign.category_id === updatedCategory.value,
            primaryTextId: 'common.move',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.moveToNewCategory',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.deleteCampaignCategory: {
        const {
          intl: { formatMessage },
          popupData: { categoryId, campaignsCount },
        } = this.props;
        const message = campaignsCount
          ? formatMessage({ id: 'web.campaigns.deleteCategoryConfirm' })
          : formatMessage({ id: 'web.campaigns.deleteCategoryConfirmEmpty' });
        return {
          children: (
            <div className="margin-top-full margin-bottom-full">{message}</div>
          ),
          footer: {
            onPrimary: !campaignsCount
              ? this.actionCreators.deleteCategoryConfirm.bind(this, categoryId)
              : this.actionCreators.closePopup,
            onSecondary: !campaignsCount && this.actionCreators.closePopup,
            primaryTextId: !campaignsCount ? 'common.delete' : 'common.ok',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.deleteCampaignCategory',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.removeConfirmation: {
        const {
          intl: { formatMessage },
          popupData: { callback, ids, markSuccess },
        } = this.props;
        return {
          children: (
            <div className="margin-top-full margin-bottom-full">
              {markSuccess
                ? formatMessage(
                    { id: 'web.campaigns.markSuccessConfirmation' },
                    { people: ids.length }
                  )
                : formatMessage(
                    { id: 'web.campaigns.removeConfirmation' },
                    { people: ids.length }
                  )}
            </div>
          ),
          footer: {
            onPrimary: this.actionCreators.removeActivePeople.bind(
              this,
              ids,
              markSuccess,
              callback
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.yes',
            secondaryTextId: 'common.no',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.stopCampaign',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.cloneCampaignConfirmation: {
        const {
          popupData: { clonedCampaign },
        } = this.props;
        const backgroundColor = PopupBackgroundColors.blue;
        return {
          backgroundColor,
          children: <CloneCampaignConfimation />,
          footer: {
            onPrimary: this.actionCreators.affirmCloneCampaign.bind(
              null,
              clonedCampaign.category_id,
              clonedCampaign.id
            ),
            primaryColor: `white-${backgroundColor}`,
            primaryTextId: 'common.ok',
          },
          fullPadding: true,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.large,
        };
      }
      case GlobalPopupIds.editLiveCampaignWarning: {
        const { selectedCampaign, account } = this.props;
        return {
          children: (
            <CampaignEditWarning
              account={account}
              actionCreators={this.actionCreators}
              selectedCampaign={selectedCampaign}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.closePopup,
            primaryTextId: 'common.ok',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'common.warning',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      default:
        return null;
    }
  }
}

GlobalPopupContainer.propTypes = {
  account: PropTypes.object.isRequired,
  alert: PropTypes.string.isRequired,
  alertTextValues: PropTypes.object.isRequired,
  autocompleteLoading: PropTypes.bool,
  autocompleteResults: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string.isRequired,
  campaignCategories: PropTypes.array,
  campaignsPeopleLoading: PropTypes.bool,
  createNewCampaignState: PropTypes.object.isRequired,
  dataLossTextId: PropTypes.string,
  deleteCampaignState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  identities: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  newCategoryState: PropTypes.object.isRequired,
  newSharedWith: PropTypes.array.isRequired,
  peopleSearchAddState: PropTypes.object.isRequired,
  popup: PropTypes.string,
  popupData: PropTypes.object,
  previewEmailsPreviews: PropTypes.object.isRequired,
  previewEmailsScheduled: PropTypes.object.isRequired,
  salesforceImportFailures: PropTypes.object,
  selectedCampaign: PropTypes.object,
  timezones: PropTypes.array.isRequired,
  unshareCampaignWarning: PropTypes.object.isRequired,
  updatedCategory: PropTypes.object.isRequired,
};

GlobalPopupContainer.defaultProps = {
  autocompleteLoading: undefined,
  campaignCategories: undefined,
  campaignsPeopleLoading: undefined,
  dataLossTextId: undefined,
  popup: undefined,
  popupData: undefined,
  salesforceImportFailures: undefined,
  selectedCampaign: undefined,
};

function mapStateToProps(state) {
  return {
    account: state.user, //todo migrate all account refs
    alert: state.popupAlert,
    alertTextValues: state.popupAlertTextValues,
    autocompleteLoading: state.autocompleteLoading,
    autocompleteResults: state.autocompleteResults,
    autocompleteValue: state.autocompleteValue,
    campaignCategories: state.campaignCategories,
    campaignsPeopleLoading: state.campaignsPeopleLoading,
    createNewCampaignState: state.campaignsTabCreateNewCampaignState,
    dataLossTextId: state.campaignsTabGlobalDataLossTextId,
    deleteCampaignState: state.campaignsTabDeleteCampaignState,
    groups: getGroups(state),
    identities: state.identities,
    loading: state.popupLoading,
    newCategoryState: state.campaignsNewCategoryState,
    newSharedWith: state.campaignsNewSharedWith,
    peopleSearchAddState: state.peopleSearchAddState,
    popup: state.popup,
    popupData: state.popupData,
    previewEmailsPreviews: state.previewEmailsPreviews,
    previewEmailsScheduled: state.previewEmailsScheduled,
    salesforceImportFailures: state.addToCampaignSalesforceImportFailures,
    selectedCampaign: find(state.campaigns, {
      id: state.campaignsTabSelectedCampaign,
    }),
    timezones: state.timezones,
    unshareCampaignWarning: state.unshareCampaignWarning,
    updatedCategory: state.campaignsUpdatedCategory,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(GlobalPopupContainer);
