import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert, { AlertColors } from 'components/alert';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { UnsubscribeFormAlertIds } from '../libs/unsubscribeFormConstants';
import { UnsubscribeOptionsAlertIds } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';

const UnsubscribeFormAlertContainer = ({ alert: { id }, closeAlert }) => {
  const defaultSettings = {
    color: AlertColors.redDark,
    location: 'top',
    onClose: closeAlert,
  };

  switch (id) {
    case UnsubscribeOptionsAlertIds.getError:
    case UnsubscribeFormAlertIds.unsubscribeManualError:
      return (
        <Alert {...defaultSettings} textId="web.common.alert.genericIssue" />
      );

    default:
      return '';
  }
};

UnsubscribeFormAlertContainer.propTypes = {
  alert: PropTypes.object.isRequired,
  closeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.viewAlert,
});

const mapDispatchToProps = {
  closeAlert: closeViewAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeFormAlertContainer);
