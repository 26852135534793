import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';

export const templatesSearchString = (state = '', { type, string = '' }) => {
  switch (type) {
    case TemplatesActionTypes.search.set:
      return string;
    case TemplatesActionTypes.table.reset:
    case TemplatesActionTypes.search.reset:
      return '';
    default:
      return state;
  }
};

export const templatesSearchFilter = (state = {}, { type, filter = {} }) => {
  switch (type) {
    case TemplatesActionTypes.search.setFilter:
      return filter;
    case TemplatesActionTypes.table.reset:
    case TemplatesActionTypes.search.reset:
      return {};
    default:
      return state;
  }
};
