import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import findIndex from 'lodash/findIndex';

export function campaignsSidebarSearchResults(state = [], action) {
  switch (action.type) {
    case ActionTypes.data.sidebarSearchResults:
      return action.data;
    default:
      return state;
  }
}

export function campaignsTabSelectedCampaign(state = null, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.data.campaignSelected:
      return action.data;
    default:
      return state;
  }
}

export function campaignsTabNewDayNumber(state = '', action) {
  // store as string so input is controlled
  switch (action.type) {
    case ActionTypes.campaignsTab.data.newDayNumber:
      return action.data;
    default:
      return state;
  }
}

export function campaignsNewSharedWith(state = [], action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.data.newSharedWith:
      return action.data;
    case PopupActionTypes.close:
      return [];
    default:
      return state;
  }
}

export function campaignsTabCampaignTemplates(state = [], action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.data.campaignTemplates:
      return action.data;
    case ActionTypes.campaignsTab.data.campaignTemplateAdd:
      return [...state, action.data];
    case ActionTypes.campaignsTab.data.campaignTemplateUpdate: {
      const templateIndex = findIndex(state, { id: action.id });
      if (templateIndex > -1) {
        return [
          ...state.slice(0, templateIndex),
          action.data,
          ...state.slice(templateIndex + 1),
        ];
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

export function campaignTemplatesLoading(state = false, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.data.campaignTemplatesLoading:
      return true;
    case ActionTypes.campaignsTab.data.campaignTemplatesLoaded:
      return false;
    default:
      return state;
  }
}
