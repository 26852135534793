import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import I18N from 'languages';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import './pageHeader.scss';
import { isSpecialCategory } from 'web/templates/helpers/templatesHelper';

class PageHeader extends PureComponent {
  render() {
    const {
      buttons,
      className,
      children,
      fillHeight,
      intl: { formatMessage },
      rightChildren,
      leftChildren,
      text,
      textId,
    } = this.props;
    const pageTextId = isSpecialCategory(text)
      ? `common.${text.toLowerCase()}`
      : textId;
    return (
      <div
        className={classNames('page-header', className, {
          'fill-height': fillHeight,
        })}
      >
        <div className="page-header-row">
          <h1 className="page-header-title text-overflow">
            {(pageTextId && formatMessage({ id: pageTextId })) || text}
          </h1>
          {leftChildren}
          <div className="align-right">
            {(buttons.length > 0 && this._getButtons()) || rightChildren}
          </div>
        </div>
        {children}
      </div>
    );
  }

  _getButtons = () => {
    const { buttons } = this.props;
    return (
      <div className="page-header-buttons">
        {buttons.map(({ text: buttonText, textId: buttonTextId, ...rest }) => (
          <Button
            className="margin-left-half"
            key={`page-header-${buttonText || buttonTextId}`}
            size={ButtonSizes.medium}
            {...rest}
          >
            {(buttonTextId && <FormattedMessage id={buttonTextId} />) ||
              buttonText}
          </Button>
        ))}
      </div>
    );
  };
}

export const PageHeaderButtonShape = PropTypes.shape({
  color: PropTypes.oneOf(Object.values(ButtonColors)),
  onClick: PropTypes.func,
  text: PropTypes.string,
  textId: PropTypes.string,
});

PageHeader.propTypes = {
  buttons: PropTypes.arrayOf(PageHeaderButtonShape),
  className: PropTypes.string,
  children: PropTypes.node,
  fillHeight: PropTypes.bool,
  intl: intlShape.isRequired,
  rightChildren: PropTypes.node,
  leftChildren: PropTypes.node,
  text: PropTypes.string,
  textId: PropTypes.string,
};

PageHeader.defaultProps = {
  buttons: [],
  className: '',
  children: null,
  fillHeight: false,
  rightChildren: undefined,
  leftChildren: undefined,
  text: '',
  textId: '',
};

export default injectIntl(PageHeader);

export let UnwrappedPageHeader; //eslint-disable-line import/no-mutable-exports
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedPageHeader = PageHeader;
}
