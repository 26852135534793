import { basePost } from 'web/services/api';
import { TEAM_ID } from 'web/modals/chooseMarketoWorkspaces/libs/chooseMarketoWorkspacesConstants';
import { Urls } from 'web/libs/routes';

export const inviteNewUsers = (emails, workspaceIds) =>
  new Promise((resolve, reject) => {
    const workspaces = emails.reduce((accumulator, email) => {
      accumulator[email] = workspaceIds;
      return accumulator;
    }, {});
    basePost(
      Urls.inviteNewUsers,
      { emails, workspaces, team_ids: TEAM_ID },
      resolve,
      reject
    );
  });
