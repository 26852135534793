import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import EngagementChart from '../engagementChart';
import { ToutEvents } from 'web/liveFeed/libs/liveFeedConstants';
import './liveFeedStats.scss';

class LiveFeedStats extends Component {
  render() {
    const { analytics, chartEvent } = this.props;
    const today = moment()
      .startOf('day')
      .toDate();
    const { count = 0, click = 0, open = 0, reply = 0 } =
      analytics[today] || {};
    return (
      <div className="live-feed-stats flex">
        <div className="count-content">
          <div className="count-text">
            <FormattedMessage id="web.liveFeed.header.emailCount" />
          </div>
          <div className="email-sent-count">{count}</div>
          <div className="engagement-count">
            <span
              className={this.getStatClasses(ToutEvents.open)}
              onClick={this.viewClick}
            >
              {open}
            </span>
            <span
              className={this.getStatClasses(ToutEvents.click)}
              onClick={this.clickRateClick}
            >
              {click}
            </span>
            <span
              className={this.getStatClasses(ToutEvents.reply)}
              onClick={this.replyClick}
            >
              {reply}
            </span>
          </div>
        </div>
        <div className="chart-content flex-right">
          <EngagementChart analytics={analytics} event={chartEvent} />
        </div>
      </div>
    );
  }

  getStatClasses = (event) =>
    classNames(`icon-${event}rate stat center-text`, {
      selected: this.props.chartEvent === event,
    });

  viewClick = () => this.props.updateChartEvent(ToutEvents.open);

  clickRateClick = () => this.props.updateChartEvent(ToutEvents.click);

  replyClick = () => this.props.updateChartEvent(ToutEvents.reply);
}

LiveFeedStats.propTypes = {
  // eslint-disable-next-line react/require-default-props
  analytics: PropTypes.object.isRequired,
  chartEvent: PropTypes.string.isRequired,
  updateChartEvent: PropTypes.func.isRequired,
};

LiveFeedStats.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  analytics: {},
};

export default LiveFeedStats;
