import { basePut, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const updateAutoAppendContent = (params = { auto_append_content: '' }) =>
  new Promise((resolve, reject) => {
    basePut(Urls.autoAppendModify, params, resolve, reject);
  });

export const deleteAutoAppendContent = () =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.autoAppendDelete, {}, resolve, reject);
  });
