import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Popup from 'components/popups/popup';
import ProgressBar from 'components/progressBar';
import { PopupSize } from 'libs/constantsShared';
import { PopupBackgroundColors } from 'components/popups';
import {
  SalesforceCustomizationPopupIds,
  SalesforceCustomizationOverlayIds,
  PROGRESS_BAR_HEIGHT,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import SalesforceCustomizationVersions from '../salesforceCustomizationVersions';

class SalesforceCustomizationUpdatePopup extends Component {
  render() {
    const {
      actionCreators,
      isProcessing,
      progress,
      popupId,
      availableVersions,
      installedVersions,
    } = this.props;
    const isPopupIdUninstall =
      popupId ===
      SalesforceCustomizationPopupIds.salesforceCustomizationUninstallation;

    const messageId = isPopupIdUninstall
      ? 'web.salesforceCustomization.popup.uninstallation.content'
      : 'web.salesforceCustomization.popup.installation.content';

    const popupObject = {
      children: (
        <div>
          <div className="margin-bottom-full">
            <FormattedMessage id={messageId} />
          </div>
          <ProgressBar
            progress={progress}
            height={PROGRESS_BAR_HEIGHT}
            animate={isProcessing}
          />
          <SalesforceCustomizationVersions
            availableVersions={availableVersions}
            installedVersions={installedVersions}
            popupId={popupId}
          />
        </div>
      ),
      footer: {
        onPrimary: actionCreators.closeOrShowOverlay,
        onSecondary: actionCreators.closeOrShowOverlay,
        primaryTextId: 'common.ok',
        secondaryTextId: 'common.hide',
        primaryDisabled: true,
      },
      header: {
        onClose: actionCreators.closeOrShowOverlay,
        textId: isPopupIdUninstall
          ? 'web.salesforceCustomization.popup.uninstallation.title'
          : 'web.salesforceCustomization.popup.installation.title',
      },
      onOutsideClick: actionCreators.closeOrShowOverlay,
      size: PopupSize.small,
      overlay: this._getPopupOverlay(),
    };

    return (
      <Popup {...popupObject} className="salesforce-customization-popup" />
    );
  }

  _getPopupOverlay() {
    const { actionCreators, popupOverlay } = this.props;

    switch (popupOverlay) {
      case SalesforceCustomizationOverlayIds.salesforceCustomizationExitWarning:
        return {
          color: PopupBackgroundColors.white,
          footer: {
            onPrimary: actionCreators.closePopup,
            onSecondary: actionCreators.hidePopupOverlay,
            primaryTextId: 'common.close',
            secondaryTextId: 'common.back',
          },
          header: {
            onClose: actionCreators.closePopup,
          },
          onOutsideClick: actionCreators.closePopup,
          textId:
            'web.salesforceCustomization.popup.installation.warningMessage',
        };
      default:
        return undefined;
    }
  }
}

SalesforceCustomizationUpdatePopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  availableVersions: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  popupOverlay: PropTypes.string,
  progress: PropTypes.number.isRequired,
  popupId: PropTypes.string.isRequired,
  installedVersions: PropTypes.array.isRequired,
};

export default SalesforceCustomizationUpdatePopup;
