import castArray from 'lodash/castArray';
import without from 'lodash/without';
import map from 'lodash/map';
import compact from 'lodash/compact';
import {
  TeamManagementEvents,
  TeamManagementActions,
} from 'web/libs/mixpanelEvents';
import { isAdmin } from 'web/user/selectors/userSelectors';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  openPopup,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  grantTeamAdmin,
  revokeTeamAdmin,
} from 'web/teams/actionCreators/teamsActionCreators';
import {
  getTeamMembers,
  batchDestroyTeamMembers,
  updateTeamMember,
  createTeamMembersInTeams,
} from 'web/teamMembers/actionCreators/teamMembersActionCreators';
import { TeamManagementAlertIds } from '../libs/teamManagementConstants';
import TeamMembersActionTypes from '../libs/teamMembersActionTypes';
import { TeamMembersPopupIds } from '../libs/teamMembersConstants';
import { getSelectedTeamId } from '../selectors/teamsSelector';
import { getMasterTeam } from 'web/teams/selectors/teamsSelectors';
import { shouldInvalidateMasterTeamMembers } from 'web/teamMembers/selectors/teamMembersSelectors';
import {
  allMembersTableExternals,
  teamMembersTableExternals,
} from '../helpers/teamMembersHelpers';

export const setEditTeamsLoading = (loading = true) => ({
  type: TeamMembersActionTypes.setEditTeamsLoading,
  loading,
});

export const setAddTeamMembersToTeamsLoading = (loading = true) => ({
  type: TeamMembersActionTypes.setAddTeamMembersToTeamsLoading,
  loading,
});

export const setDeleteTeamMembersLoading = (loading = true) => ({
  type: TeamMembersActionTypes.setDeleteTeamMembersLoading,
  loading,
});

export const setChangeRoleLoading = (loading = true) => ({
  type: TeamMembersActionTypes.setChangeRoleLoading,
  loading,
});

export const setAddTeamMembersToTeamLoading = (loading = true) => ({
  type: TeamMembersActionTypes.setAddTeamMembersToTeamLoading,
  loading,
});

export const teamMembersStartup = () => async (dispatch, getState) => {
  const masterTeam = getMasterTeam(getState());

  if (shouldInvalidateMasterTeamMembers(getState())) {
    await dispatch(getTeamMembers(masterTeam.id));
  }
};

export const openEditTeamsPopup = (teamMember) => (dispatch) => {
  dispatch(
    openPopup(
      TeamMembersPopupIds.editTeams,
      { teamMember },
      {
        analytics: {
          event: TeamManagementEvents.teamManagement,
          properties: {
            'Action Type': TeamManagementActions.editTeamsPopup,
          },
        },
      }
    )
  );
};

export const editTeams = (teamMember, teamIds) => (dispatch) => {
  dispatch({
    type: TeamMembersActionTypes.editTeams,
    payload: { teamMember, teamIds },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.editTeams,
        },
      },
    },
  });
  dispatch(setEditTeamsLoading());
  dispatch(updateTeamMember(teamMember.id, teamIds))
    .then(() => {
      teamMembersTableExternals.clearSelectedIds();
      dispatch(closePopup());
      dispatch(openViewAlert(TeamManagementAlertIds.dataChangesSaved));
    })
    .catch((errors) => {
      dispatch(
        openPopup(TeamMembersPopupIds.editTeams, { teamMember, errors })
      );
    })
    .finally(() => {
      dispatch(setEditTeamsLoading(false));
    });
};

export const openAddTeamMembersToTeamsPopup = (userIds = []) => (dispatch) => {
  dispatch(
    openPopup(
      TeamMembersPopupIds.addTeamMembersToTeams,
      {
        userIds,
      },
      {
        analytics: {
          event: TeamManagementEvents.teamManagement,
          properties: {
            'Action Type': TeamManagementActions.addTeamMembersToTeamsPopup,
          },
        },
      }
    )
  );
};

export const addTeamMembersToTeams = (userIds, teamIds) => (dispatch) => {
  dispatch({
    type: TeamMembersActionTypes.addTeamMembersToTeams,
    payload: { userIds, teamIds },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.addTeamMembersToTeams,
        },
      },
    },
  });
  dispatch(setAddTeamMembersToTeamsLoading());
  dispatch(createTeamMembersInTeams(userIds, teamIds))
    .then(() => {
      allMembersTableExternals.clearSelectedIds();
      teamMembersTableExternals.clearSelectedIds();
      dispatch(closePopup());
      dispatch(
        openViewAlert(TeamManagementAlertIds.addToTeamsSuccess, {
          membersCount: userIds.length,
        })
      );
    })
    .catch((errors) => {
      dispatch(
        openPopup(TeamMembersPopupIds.addTeamMembersToTeams, {
          userIds,
          errors,
        })
      );
    })
    .finally(() => {
      dispatch(setAddTeamMembersToTeamsLoading(false));
    });
};

export const openAddTeamMembersToTeamPopup = (teamId) => (dispatch) => {
  dispatch(
    openPopup(
      TeamMembersPopupIds.addTeamMembersToTeam,
      { teamId },
      {
        analytics: {
          event: TeamManagementEvents.teamManagement,
          properties: {
            'Action Type': TeamManagementActions.addTeamMembersToTeamPopup,
          },
        },
      }
    )
  );
};

export const addTeamMembersToTeam = (teamId, teamMemberIds) => (dispatch) => {
  dispatch({
    type: TeamMembersActionTypes.addTeamMembersToTeam,
    payload: { teamMemberIds, teamId },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.addTeamMembersToTeam,
        },
      },
    },
  });
  dispatch(setAddTeamMembersToTeamLoading());
  dispatch(createTeamMembersInTeams(teamMemberIds, castArray(teamId)))
    .then(() => {
      teamMembersTableExternals.clearSelectedIds();
      dispatch(closePopup());
    })
    .catch((errors) => {
      dispatch(
        openPopup(TeamMembersPopupIds.addTeamMembersToTeam, {
          teamId,
          errors,
        })
      );
    })
    .finally(() => {
      dispatch(setAddTeamMembersToTeamLoading(false));
    });
};

export const openDeleteTeamMembersConfirmationPopup = (ids = []) => (
  dispatch
) => {
  dispatch(
    openPopup(
      TeamMembersPopupIds.deleteTeamMembersConfirmation,
      { ids: map(ids, Number) },
      {
        analytics: {
          event: TeamManagementEvents.teamManagement,
          properties: {
            'Action Type':
              TeamManagementActions.deleteTeamMembersConfirmationPopup,
            Amount: ids.length,
          },
        },
      }
    )
  );
};

export const openDeleteTeamMemberConfirmationPopup = ({ id }) => (dispatch) => {
  dispatch(openDeleteTeamMembersConfirmationPopup([id]));
};

export const deleteTeamMembers = (ids) => (dispatch, getState) => {
  dispatch({
    type: TeamMembersActionTypes.deleteTeamMembers,
    payload: { ids },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.deleteTeamMembers,
        },
      },
    },
  });
  const selectedTeamId = getSelectedTeamId(getState());
  const { id: currentUserId } = getState().user;
  const isCurrentUserAdmin = isAdmin(getState());

  let teamMemberIds = map(ids, Number);
  teamMemberIds = isCurrentUserAdmin
    ? teamMemberIds
    : without(teamMemberIds, currentUserId);
  teamMemberIds = compact(teamMemberIds);

  if (!teamMemberIds.length) {
    dispatch(closePopup());
    dispatch(
      openViewAlert(TeamManagementAlertIds.bulkDeleteFromTeamChangesSaved)
    );
    return;
  }

  dispatch(setDeleteTeamMembersLoading());
  dispatch(batchDestroyTeamMembers(selectedTeamId, teamMemberIds))
    .then(() => {
      teamMembersTableExternals.clearSelectedIds();
      dispatch(closePopup());
    })
    .catch((errors) => {
      dispatch(
        openPopup(TeamMembersPopupIds.deleteTeamMembersConfirmation, {
          errors,
          ids: teamMemberIds,
        })
      );
    })
    .finally(() => {
      dispatch(setDeleteTeamMembersLoading(false));
    });
};

export const grantAdminRole = (userId) => (dispatch, getState) => {
  dispatch({
    type: TeamMembersActionTypes.grantAdminRole,
    payload: { id: userId },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.grantAdminRole,
        },
      },
    },
  });
  const selectedTeamId = getSelectedTeamId(getState());
  dispatch(setChangeRoleLoading());
  dispatch(grantTeamAdmin(selectedTeamId, userId))
    .catch(() => {
      dispatch(openViewAlert(TeamManagementAlertIds.dataChangesNotSaved));
    })
    .finally(() => {
      dispatch(setChangeRoleLoading(false));
    });
};

export const grantUserRole = (userId) => (dispatch, getState) => {
  dispatch({
    type: TeamMembersActionTypes.grantUserRole,
    payload: { id: userId },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.grantUserRole,
        },
      },
    },
  });
  const selectedTeamId = getSelectedTeamId(getState());
  dispatch(setChangeRoleLoading());
  dispatch(revokeTeamAdmin(selectedTeamId, userId))
    .catch(() => {
      dispatch(openViewAlert(TeamManagementAlertIds.dataChangesNotSaved));
    })
    .finally(() => {
      dispatch(setChangeRoleLoading(false));
    });
};
