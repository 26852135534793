import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PeopleSimpleGrid from 'components/peopleSimpleGrid';
import PersonDeleteStatus from './personDeleteStatus';
import './peopleDelete.scss';

class PeopleDelete extends Component {
  getConfirmMessage() {
    const { deletableCount } = this.props;
    return (
      <div>
        <FormattedHTMLMessage
          id="web.peopleDelete.confirmDelete"
          values={{ deletableCount }}
        />
      </div>
    );
  }

  getWarningMessage() {
    const { people, deletableCount, unsubscribedCount, isAdmin } = this.props;
    const totalCount = people.length;
    const notDeletableCount = totalCount - deletableCount;

    return (
      <div className="people-delete-modal-warnings tout-icon-warning">
        <div>
          <FormattedHTMLMessage
            id="web.peopleDelete.warning"
            values={{ notDeletableCount, totalCount }}
          />
        </div>
        {unsubscribedCount > 0 && (
          <div className="margin-top-half">
            <FormattedMessage
              id="web.peopleDelete.unsubscribedWarning"
              values={{ isAdmin }}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { people, deletableCount } = this.props;
    const totalCount = people.length;

    return (
      <div className="people-delete-modal">
        {deletableCount === totalCount
          ? this.getConfirmMessage()
          : this.getWarningMessage()}
        <PeopleSimpleGrid people={people} status={PersonDeleteStatus} />
      </div>
    );
  }
}

PeopleDelete.propTypes = {
  people: PropTypes.array.isRequired,
  deletableCount: PropTypes.number.isRequired,
  unsubscribedCount: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default injectIntl(PeopleDelete);
