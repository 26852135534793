import React, { Fragment } from 'react';
import TeamMembersPopupContainer from '../../../containers/teamMembersPopupContainer';
import TeamsPopupContainer from '../../../containers/teamsPopupContainer';
import './teamManagementPopups.scss';

const TeamManagementPopups = () => (
  <Fragment>
    <TeamMembersPopupContainer />
    <TeamsPopupContainer />
  </Fragment>
);

export default TeamManagementPopups;
