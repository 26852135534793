export const WidthMode = {
  PERCENT: 'PERCENT',
  PIXEL: 'PIXEL',
};

export const Align = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

export const SortingDirection = {
  ASC: 'ASCENDING',
  DESC: 'DESCENDING',
};

export const AnimationColors = {
  blue: 'blue',
  green: 'green',
  red: 'red',
};

export const SelectableWidth = 60;
export const SearchMinimum = 3;
export const SearchGroupMaximum = 2;

export const Pagination = { perPageValues: [25, 50, 75, 100] };
export const ColumnOrderDragType = 'COLUMN_ORDER_DRAG_HEADER';
