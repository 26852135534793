import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Label from 'components/label';
import Text from 'components/inputs/text';
import Select2 from 'components/selects/select2';
import { PITCH_TEMPLATE } from 'web/templates/libs/templatesConstants';
import { isSpecialCategory } from 'web/templates/helpers/templatesHelper';
import I18N from 'languages';

const LABEL_WIDTH = 60;

class CreateTemplate extends Component {
  componentWillMount() {
    const {
      categories,
      intl: { formatMessage },
    } = this.props;
    this.textPlaceholderText = formatMessage({
      id: 'web.templates.createTemplate.textPlaceholder',
    });
    this.noResultsText = formatMessage({
      id: 'web.templates.createTemplate.noResults',
    });
    this.selectPlaceholderText = formatMessage({
      id: 'web.templates.createTemplate.selectPlaceholder',
    });
    this.options = this._formatOptions(categories);
  }

  render() {
    const { createTemplateState } = this.props;

    return (
      <div className="create-template">
        <Label
          id="create-template-name"
          messageKey="common.name"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Text
            onChange={this.updateName}
            placeholder={this.textPlaceholderText}
            value={createTemplateState.name}
          />
        </Label>
        <Label
          classWrapper="margin-top-most"
          id="create-template-category"
          messageKey="common.category"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Select2
            clearable={false}
            noResultsText={this.noResultsText}
            onChange={this.updateCategory}
            options={this.options}
            placeholder={this.selectPlaceholderText}
            searchable
            value={createTemplateState.categoryId}
          />
        </Label>
      </div>
    );
  }

  _formatOptions(categories) {
    return categories.map((category) => ({
      value: category.id,
      label:
        category.category_type === PITCH_TEMPLATE && isSpecialCategory(category)
          ? I18N.getStr(`common.${category.name.toLowerCase()}`)
          : category.name,
    }));
  }

  updateName = (name) => {
    this.props.updateState({ name });
  };

  updateCategory = (category) => {
    this.props.updateState({ categoryId: category.value });
  };
}

CreateTemplate.propTypes = {
  createTemplateState: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default injectIntl(CreateTemplate);
