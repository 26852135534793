import React from 'react';
import PropTypes from 'prop-types';
import AdminUnsubscribesIntegrations from '../containers/adminUnsubscribesIntegrationsContainer';
import AdminUnsubscribesMessagingSettings from '../containers/adminUnsubscribesMessagingSettingsContainer';
import { AdminUnsubscribeTabs } from '../libs/adminUnsubscribesConstants';

const AdminUnsubscribesTabContent = ({ tab }) => {
  switch (tab) {
    case AdminUnsubscribeTabs.messaging:
      return <AdminUnsubscribesMessagingSettings />;

    case AdminUnsubscribeTabs.salesforce:
      return <AdminUnsubscribesIntegrations />;

    default:
      return null;
  }
};

AdminUnsubscribesTabContent.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default AdminUnsubscribesTabContent;
