import ActionTypes from 'web/people/libs/peopleActionTypes';

export const slideOutMenuOpen = (state = true, action) => {
  switch (action.type) {
    case ActionTypes.peopleTabSettings.setSlideOutMenuOpen:
      return action.state;
    default:
      return state;
  }
};
