import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CampaignDetailsEnum } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import CampaignDetailsHeader from '../campaignDetailsHeader/campaignDetailsHeader';
import CampaignTabView from '../campaignTabView/campaignTabView';
import CampaignSetupOptions from '../campaignSetupOptions';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import './campaignDetailsView.scss';

class CampaignDetailsView extends Component {
  state = { shownWarning: false };

  componentDidMount() {
    const { actionCreators, selectedCampaign, selectedDetailsTab } = this.props;
    const sharedTeams = selectedCampaign.team_ids;
    const teams =
      selectedCampaign.team_ids && sharedTeams.length
        ? { team_ids: sharedTeams }
        : {};
    actionCreators.currentUsers(selectedCampaign.id, teams);
    this._openEditWarning(selectedDetailsTab);
  }

  componentDidUpdate() {
    const { selectedDetailsTab } = this.props;
    this._openEditWarning(selectedDetailsTab);
  }

  render() {
    const {
      account,
      addingNewCampaignDay,
      attachments,
      actionCreators,
      campaignsPeople,
      campaignsPeopleSearchString,
      campaignDayEditing,
      campaignDayLoading,
      campaignsPeopleFilter,
      campaignsPeopleViewAsFilter,
      campaignsPeoplePagination,
      campaignsTabViewFetching,
      campaignsTabViewLoading,
      campaignEditStates,
      campaignTemplates,
      campaignTemplatesLoading,
      ckeBodies,
      editingSteps,
      editName,
      isEditingName,
      newDayNumber,
      onCampaignTabSelected,
      selectedCampaign,
      selectedDetailsTab,
      settings,
      tasks,
      tasksDue,
      timezone,
      timezones,
      updateCampaign,
      campaignsPeopleLength,
      userCanEditCampaigns,
    } = this.props;

    return (
      <div className="campaignDetailsView">
        {campaignTemplatesLoading ? (
          <LoadingSpinner imageUrl={getImageUrl} />
        ) : null}
        <CampaignDetailsHeader
          account={account}
          actionCreators={actionCreators}
          editName={editName}
          isEditingName={isEditingName}
          onCampaignTabSelected={onCampaignTabSelected}
          selectedCampaign={selectedCampaign}
          selectedDetailsTab={selectedDetailsTab}
          settings={settings}
          tasksCount={tasksDue || 0}
          updateCampaign={updateCampaign}
          campaignsPeopleLength={campaignsPeopleLength}
          userCanEditCampaigns={userCanEditCampaigns}
        />
        {selectedDetailsTab === CampaignDetailsEnum.setup.value ? (
          <CampaignSetupOptions
            actionCreators={actionCreators}
            isHorizontal={settings.setupHorizontal}
          />
        ) : null}
        <CampaignTabView
          account={account}
          actionCreators={actionCreators}
          addingNewCampaignDay={addingNewCampaignDay}
          attachments={attachments}
          campaignDayEditing={campaignDayEditing}
          campaignDayLoading={campaignDayLoading}
          campaignEditStates={campaignEditStates}
          campaignsPeople={campaignsPeople}
          campaignsPeopleFilter={campaignsPeopleFilter}
          campaignsPeopleViewAsFilter={campaignsPeopleViewAsFilter}
          campaignsPeoplePagination={campaignsPeoplePagination}
          campaignsPeopleSearchString={campaignsPeopleSearchString}
          campaignsTabViewFetching={campaignsTabViewFetching}
          campaignsTabViewLoading={campaignsTabViewLoading}
          campaignTemplates={campaignTemplates}
          ckeBodies={ckeBodies}
          editingSteps={editingSteps}
          newDayNumber={newDayNumber}
          selectedCampaign={selectedCampaign}
          selectedDetailsTab={selectedDetailsTab}
          settings={settings}
          tasks={tasks}
          timezone={timezone}
          timezones={timezones}
          userCanEditCampaigns={userCanEditCampaigns}
        />
      </div>
    );
  }

  _openEditWarning = (selectedDetailsTab) => {
    const { shownWarning } = this.state;
    if (
      selectedDetailsTab === CampaignDetailsEnum.setup.value &&
      !shownWarning
    ) {
      this.props.actionCreators.openEditWarningAlert();
      this.setState({ shownWarning: true });
    }
  };
}

CampaignDetailsView.propTypes = {
  account: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  addingNewCampaignDay: PropTypes.bool.isRequired,
  attachments: PropTypes.object.isRequired,
  campaignDayEditing: PropTypes.object.isRequired,
  campaignDayLoading: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaignsPeople: PropTypes.array.isRequired,
  campaignsPeopleLength: PropTypes.number.isRequired,
  campaignsPeopleFilter: PropTypes.string.isRequired,
  campaignsPeopleViewAsFilter: PropTypes.number.isRequired,
  campaignsPeoplePagination: PropTypes.number.isRequired,
  campaignsPeopleSearchString: PropTypes.string.isRequired,
  campaignsTabViewFetching: PropTypes.bool.isRequired,
  campaignsTabViewLoading: PropTypes.bool.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  campaignTemplatesLoading: PropTypes.bool.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  editingSteps: PropTypes.object.isRequired,
  editName: PropTypes.func.isRequired,
  isEditingName: PropTypes.bool.isRequired,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onCampaignTabSelected: PropTypes.func.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  selectedDetailsTab: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  tasksDue: PropTypes.number,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignDetailsView.defaultProps = {
  tasksDue: 0,
  userCanEditCampaigns: true,
};

export default CampaignDetailsView;
