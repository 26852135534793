import find from 'lodash/find';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { SmtpServerAlertIds } from '../../libs/smtpServerConstants';
import Alert, { AlertColors } from 'components/alert';
import 'web/settings/emailServer/components/emailServerAlert/emailServerAlert.scss';

class SmtpServerAlert extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  isOnlyDefaultServer = (identities) => identities.length === 1;

  getAlert = () => {
    const {
      alert: { id },
      closeViewAlert,
      isTeamSmtpDeliveryChannelExists,
      identities,
      isGmailDeliveryChannelExists,
      isOutlookDeliveryChannelExists,
    } = this.props;

    const defaultAlert = {
      classes: 'email-server-error-alert',
      color: AlertColors.redDark,
      manual: true,
      onClose: closeViewAlert,
    };

    switch (id) {
      case SmtpServerAlertIds.connected:
        if (isTeamSmtpDeliveryChannelExists) {
          if (this.isOnlyDefaultServer(identities)) {
            return {
              ...defaultAlert,
              color: AlertColors.greenDark,
              html: true,
              textId:
                'web.settings.smtpServer.alert.connected.subscriptionEmailServerExistsAndSingleIdentity',
            };
          } else {
            return {
              ...defaultAlert,
              color: AlertColors.greenDark,
              html: true,
              textId:
                'web.settings.smtpServer.alert.connected.subscriptionEmailServerExistsAndMultipleIdentities',
              textValues: { id: find(identities, { isDefault: false }).id },
            };
          }
        } else {
          return {
            ...defaultAlert,
            color: AlertColors.greenDark,
            html: true,
            textId:
              'web.settings.smtpServer.alert.connected.noSubscriptionEmailServerExists',
            textValues: { id: find(identities, { isDefault: true }).id },
          };
        }
      case SmtpServerAlertIds.serverValidationGmailSmtpErrorMessage:
        if (isTeamSmtpDeliveryChannelExists) {
          if (isGmailDeliveryChannelExists) {
            if (this.isOnlyDefaultServer(identities)) {
              return {
                ...defaultAlert,
                html: true,
                textId:
                  'web.settings.emailServer.gmailConnectedWithOneIdentityErrorMessage',
              };
            } else {
              return {
                ...defaultAlert,
                html: true,
                textId: 'web.settings.emailServer.gmailConnectedErrorMessage',
                textValues: { id: find(identities, { isDefault: false }).id },
              };
            }
          } else if (identities.length === 1) {
            return {
              ...defaultAlert,
              html: true,
              textId:
                'web.settings.emailServer.gmailNotConnectedWithOneIdentityErrorMessage',
            };
          } else {
            return {
              ...defaultAlert,
              html: true,
              textId: 'web.settings.emailServer.gmailNotConnectedErrorMessage',
            };
          }
        } else if (isGmailDeliveryChannelExists) {
          return {
            ...defaultAlert,
            html: true,
            textId: 'web.settings.emailServer.gmailConnectedErrorMessage',
            textValues: { id: find(identities, { isDefault: true }).id },
          };
        } else {
          return {
            ...defaultAlert,
            html: true,
            textId: 'web.settings.emailServer.gmailNotConnectedErrorMessage',
          };
        }
      case SmtpServerAlertIds.serverValidationOutlookSmtpErrorMessage:
        if (isTeamSmtpDeliveryChannelExists) {
          if (isOutlookDeliveryChannelExists) {
            if (this.isOnlyDefaultServer(identities)) {
              return {
                ...defaultAlert,
                html: true,
                textId:
                  'web.settings.emailServer.outlookConnectedWithOneIdentityErrorMessage',
              };
            } else {
              return {
                ...defaultAlert,
                html: true,
                textId: 'web.settings.emailServer.outlookConnectedErrorMessage',
                textValues: { id: find(identities, { isDefault: false }).id },
              };
            }
          } else if (identities.length === 1) {
            return {
              ...defaultAlert,
              html: true,
              textId:
                'web.settings.emailServer.outlookNotConnectedWithOneIdentityErrorMessage',
            };
          } else {
            return {
              ...defaultAlert,
              html: true,
              textId:
                'web.settings.emailServer.outlookNotConnectedErrorMessage',
            };
          }
        } else if (isOutlookDeliveryChannelExists) {
          return {
            ...defaultAlert,
            html: true,
            textId: 'web.settings.emailServer.outlookConnectedErrorMessage',
            textValues: { id: find(identities, { isDefault: true }).id },
          };
        } else {
          return {
            ...defaultAlert,
            html: true,
            textId: 'web.settings.emailServer.outlookNotConnectedErrorMessage',
          };
        }

      default:
        return null;
    }
  };
}

SmtpServerAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
  identities: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  isGmailDeliveryChannelExists: PropTypes.bool,
  isTeamSmtpDeliveryChannelExists: PropTypes.bool,
};

SmtpServerAlert.defaultProps = {
  isGmailDeliveryChannelExists: false,
  isOutlookDeliveryChannelExists: false,
  isTeamSmtpDeliveryChannelExists: false,
};

export default injectIntl(SmtpServerAlert);
