/* Warning: prod settings are set in constants.js. This is done to avoid including all our environments in prod build. */

const BackendUrls = {
  local: 'https://localhost:3008',
  dev: 'https://dev.toutapp.com',
  qa1: 'https://qa1.toutapp.com',
  staging: 'https://staging.toutapp.com',
  production: 'https://toutapp.com',
};

const PusherEnvs = {
  local: 'development',
  dev: 'development',
  qa1: 'qa1',
  qa2: 'qa2',
  staging: 'staging',
  production: 'production',
};

// const ToutEnv = {
//   local: 'local',
//   dev: 'dev',
//   qa1: 'qa1',
//   qa2: 'qa2',
//   staging: 'staging',
//   production: 'production'
// };

/*eslint no-process-env: 0*/
export const BaseUrl = BackendUrls[process.env.TOUT_ENV] || BackendUrls.staging;
export const PusherEnvironment =
  PusherEnvs[process.env.TOUT_ENV] || PusherEnvs.staging;
