import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18N from 'languages';
import upperFirst from 'lodash/upperFirst';
import { track } from 'web/services/mixpanelService';
import {
  UnsubscribeEvents,
  UnsubscribeProperties,
  UnsubscribePropertyTypes,
} from 'web/libs/mixpanelEvents';
import {
  UNSUBSCRIBE_HISTORY_ITEM_TYPE,
  UNSUBSCRIBE_SOURCE,
} from 'web/person/libs/personDetailsConstants';

class UnsubscribeHistoryTableItem extends Component {
  render() {
    const { item } = this.props;

    const details = this.getDetails(item);
    const source = this.getSource(item);

    return (
      <div className="unsubscribe-history-table-item" onClick={this.onClick}>
        <div className="unsubscribe-history-table-block date-cell">
          {I18N.getLocalizedDate(item.created_at, I18N.DateFormats.DATE_SLASH)}
        </div>
        <div
          className="unsubscribe-history-table-block details-cell color-gray text-italic"
          title={details}
        >
          {details}
        </div>
        <div className="unsubscribe-history-table-block source-cell">
          {source}
        </div>
      </div>
    );
  }

  onClick = () => {
    track(UnsubscribeEvents.history, {
      [UnsubscribeProperties.actionType]: UnsubscribePropertyTypes.click,
    });
  };

  getDetailsCreated = (item) => {
    const { unsubscribeReasons } = this.props;
    const unsubscribed =
      item.reason_description ||
      unsubscribeReasons[item.unsubscribe_reason_id] ||
      '';

    return `${I18N.getStr('common.unsubscribed')}${
      unsubscribed ? ' - ' : ''
    }${unsubscribed}`;
  };

  getDetailsDeleted = (item) => {
    const { unsubscribeOptInReasons } = this.props;
    const unsubscribed =
      unsubscribeOptInReasons[item.unsubscribe_opt_in_reason_id] || '';

    return `${I18N.getStr('common.resubscribed')}${
      unsubscribed ? ' - ' : ''
    }${unsubscribed}`;
  };

  getDetails = (item) => {
    switch (item.state) {
      case UNSUBSCRIBE_HISTORY_ITEM_TYPE.created:
        return this.getDetailsCreated(item);

      case UNSUBSCRIBE_HISTORY_ITEM_TYPE.deleted:
        return this.getDetailsDeleted(item);

      default:
        return '';
    }
  };

  getSource = (item) => {
    const { formatMessage, userSubscriptionUsers } = this.props;
    const { state, block_way: blockWay, user_id: userId } = item;

    switch (state) {
      case UNSUBSCRIBE_HISTORY_ITEM_TYPE.created:
        return UNSUBSCRIBE_SOURCE.manual === upperFirst(blockWay)
          ? I18N.getStr('web.person.personDetails.unsubscribeHistory.manual')
          : upperFirst(blockWay);

      case UNSUBSCRIBE_HISTORY_ITEM_TYPE.deleted:
        return userSubscriptionUsers[userId]
          ? userSubscriptionUsers[userId].name
          : formatMessage({ id: 'common.userDeleted' });
      default:
        return '';
    }
  };
}

UnsubscribeHistoryTableItem.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  unsubscribeOptInReasons: PropTypes.object.isRequired,
  unsubscribeReasons: PropTypes.object.isRequired,
  userSubscriptionUsers: PropTypes.object.isRequired,
};

export default UnsubscribeHistoryTableItem;
