import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select2 from 'components/selects/select2';

class Select extends Component {
  render() {
    const { input, ...other } = this.props;

    return (
      <Select2
        {...input}
        {...other}
        onChange={this.onChange}
        onBlur={this.onBlur}
        menuContainerStyle={{ top: 'auto', bottom: '100%' }}
      />
    );
  }

  onChange = (option) => {
    const {
      input: { onChange },
    } = this.props;

    onChange(option ? option.value : null);
  };

  onBlur = () => {
    const {
      input: { value, onBlur },
    } = this.props;

    onBlur(value);
  };
}

Select.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  isCreatable: PropTypes.bool,
  meta: PropTypes.object,
};

Select.defaultProps = {
  className: '',
  input: {
    value: 0,
    onChange: () => {},
    onBlur: () => {},
  },
  isCreatable: false,
  meta: {},
};

export default Select;
