import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import './peopleHeader.scss';

const PeopleHeader = ({ className }) => {
  const classes = classNames('people-simple-grid-header', className);
  return (
    <div className={classes}>
      <div className="people-header-contact text-medium">
        <FormattedMessage id="common.person" />
      </div>
      <div className="people-header-status text-medium">
        <FormattedMessage id="common.status" />
      </div>
    </div>
  );
};

PeopleHeader.defaultProps = {
  className: '',
};

PeopleHeader.propTypes = {
  className: PropTypes.string,
};

export default PeopleHeader;
