import {
  AlertIds,
  ModalOverlayIds,
} from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import { AlertColors } from 'components/alert';
import { PopupBackgroundColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { Urls } from 'web/libs/routes';

/*eslint-disable complexity*/
export function getPopupAlert(id, textValues, actionCreators) {
  const defaultAlert = {
    color: AlertColors.red,
    onClose: actionCreators.clearAlert,
    textId: 'web.campaigns.genericApiError',
  };

  const infoAlert = {
    ...defaultAlert,
    color: AlertColors.blue,
  };

  switch (id) {
    case AlertIds.addPeopleNeedsToBeEmail:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.addPeopleNeedsToBeEmail',
      };
    case AlertIds.launchCampaignIssue:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.launchCampaignIssue',
      };
    case AlertIds.genericApiIssue:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.genericApiError',
      };
    case AlertIds.unverifiedUser:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.unverifiedUser',
      };
    case AlertIds.invalidPreviewInput:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.invalidPreviewInput',
        textValues,
      };
    case AlertIds.invalidPreviewDynamicFields:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.invalidPreviewDynamicFields',
        textValues,
      };
    case AlertIds.emailLimitsReachedAddPeople:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.emailLimitsAddPeople',
        textValues,
      };
    case AlertIds.addPeopleCallFail:
      return {
        ...defaultAlert,
        textId: 'web.campaignsTab.addPeople.failed',
      };
    case PeopleAlertIds.peopleSearchAdd.groupAlreadyIncluded:
      return {
        ...defaultAlert,
        textId: 'web.campaignsTab.addPeople.groupAlreadyIncluded',
      };
    case PeopleAlertIds.peopleSearchAdd.miscAlreadyIncluded:
      return {
        ...defaultAlert,
        color: AlertColors.yellow,
        textId: 'web.campaignsTab.addPeople.miscAlreadyIncluded',
      };
    case PeopleAlertIds.peopleSearchAdd.personAlreadyIncluded:
      return {
        ...defaultAlert,
        textId: 'web.campaignsTab.addPeople.personAlreadyIncluded',
      };
    case PeopleAlertIds.peopleSearchAdd.getPeopleInGroupFail:
      return {
        ...defaultAlert,
        textId: 'web.campaignsTab.addPeople.failedGetPeople',
      };
    case PeopleAlertIds.peopleSearchAdd.addNewPeopleDisabled:
      return {
        ...infoAlert,
        textId: 'people.addNewDisabled',
      };
    case PeopleAlertIds.peopleSearchAdd.needsToBeEmail:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.addPeopleNeedsToBeEmail',
      };
    case AlertIds.noSteps:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.noStepError',
      };
    case AlertIds.marketoWorkspacesError:
      return {
        ...defaultAlert,
        textId: 'web.addToCampaign.marketoWorkspacesError',
      };
    case AlertIds.invalidPreviewPromptFields:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.invalidPreviewPromptFields',
        textValues,
      };
    default:
      return null;
  }
}
/*eslint-enable complexity*/

/*eslint complexity: [1, 20]*/
export const getPopupOverlay = (props, actionCreators) => {
  const {
    dataLossTextId,
    intl: { formatMessage },
    popupOverlay,
    popupOverlayTextValues,
    windowMode,
    saleforceConnectLink,
  } = props;
  const defaultOnClose =
    (!windowMode && actionCreators.closeAddToCampaign) || null;

  if (popupOverlay === ModalOverlayIds.emailLimits) {
    const { total, current, remaining } = popupOverlayTextValues;
    const currentOver = current < 0;
    return {
      color: PopupBackgroundColors.red,
      footer: {
        onPrimary: actionCreators.closeAddToCampaign,
        primaryTextId: 'common.close',
      },
      header: {
        onClose: defaultOnClose,
      },
      onOutsideClick: actionCreators.closeAddToCampaign,
      size: PopupSize.small,
      textId: 'web.addToCampaign.emailLimits',
      textValues: {
        current: Math.abs(current),
        currentOver,
        remaining: Math.abs(remaining),
        total,
      },
    };
  } else if (dataLossTextId.length) {
    return {
      color: PopupBackgroundColors.yellow,
      footer: {
        onPrimary: actionCreators.closeAddToCampaign,
        onSecondary: actionCreators.warnDataLossHide,
        primaryTextId: 'common.close',
        secondaryTextId: 'common.back',
      },
      header: {
        onClose: defaultOnClose,
      },
      onOutsideClick: actionCreators.closeAddToCampaign,
      textId: 'web.campaigns.warnChangesLoss',
      textValues: { dataLosing: formatMessage({ id: dataLossTextId }) },
    };
  } else if (popupOverlay === ModalOverlayIds.retryPeopleData) {
    return {
      color: PopupBackgroundColors.yellow,
      footer: {
        onPrimary: actionCreators.retryPeopleData,
        onSecondary: actionCreators.closeAddToCampaign,
        primaryTextId: 'common.retry',
        secondaryTextId: 'common.cancel',
      },
      header: {
        onClose: defaultOnClose,
      },
      onOutsideClick: actionCreators.closeAddToCampaign,
      size: PopupSize.small,
      textId: 'web.addToCampaign.retryPeopleData',
    };
  } else if (
    popupOverlay === ModalOverlayIds.salesforceError ||
    popupOverlay === ModalOverlayIds.salesConnectError
  ) {
    const textId =
      popupOverlayTextValues.textId || 'web.addToCampaign.salesforceError';
    const errorText = popupOverlayTextValues.text || '';

    return {
      color: PopupBackgroundColors.red,
      footer: {
        onPrimary: actionCreators.retrySalesforceCall,
        onSecondary: actionCreators.closeAddToCampaign,
        primaryTextId: 'common.retry',
        secondaryTextId: 'common.cancel',
      },
      header: {
        onClose: defaultOnClose,
      },
      html: true,
      onOutsideClick: actionCreators.closeAddToCampaign,
      size: PopupSize.small,
      textId,
      textValues: {
        errorMsg: errorText,
        hasError: errorText.length > 0,
        link: saleforceConnectLink,
      },
    };
  } else if (popupOverlay === ModalOverlayIds.notAToutContact) {
    const link = Urls.peoplePageUrl;
    return {
      color: PopupBackgroundColors.red,
      footer: {
        onPrimary: actionCreators.closeAddToCampaign,
        primaryTextId: 'common.ok',
      },
      header: {
        onClose: defaultOnClose,
      },
      html: true,
      onOutsideClick: actionCreators.closeAddToCampaign,
      size: PopupSize.small,
      textId: 'web.addToCampaign.notAToutContact',
      textValues: { link },
    };
  } else if (popupOverlay === ModalOverlayIds.notValidEmail) {
    const textId =
      popupOverlayTextValues.textId || 'web.campaigns.addPeopleNeedsToBeEmail';
    return {
      color: PopupBackgroundColors.red,
      footer: {
        onPrimary: actionCreators.closeAddToCampaign,
        primaryTextId: 'common.ok',
      },
      header: {
        onClose: defaultOnClose,
      },
      html: true,
      onOutsideClick: actionCreators.closeAddToCampaign,
      size: PopupSize.small,
      textId,
    };
  } else {
    return undefined;
  }
};
