import ActionTypes from 'table/modules/actionTypes';
import trackerEvents from 'table/modules/tracker/trackerEvents';
import track from 'table/modules/tracker';

export function onClearAll() {
  return {
    type: ActionTypes.selectableClearSelectedItems,
  };
}

export function onCheckAll(checked, isAll = false) {
  return (dispatch, getState) => {
    const {
      paginationItems,
      pagination,
      selectableItems,
      selectableHeaderSelected,
    } = getState();
    /* use pagination's ids if pagination on, the selectAll wasn't just clicked, and header row is already on (e.g., being unchecked) */
    const items =
      pagination && !isAll && !selectableHeaderSelected
        ? paginationItems
        : selectableItems;

    dispatch({
      type: ActionTypes.selectableSelectAllItems,
      checked,
      items: items.filter((elem) => !elem.isSelectedDisabled),
    });

    track(trackerEvents.selectableAllSelected, { checked, isAll });
  };
}

export function selectAllItems(selectAllRequest, totalCount) {
  return (dispatch) => {
    const params = {
      ...selectAllRequest.requestParams,
      page: 1,
      per_page: totalCount,
    };

    selectAllRequest.selectAllHandler(params).then((data) => {
      const items = data.result.map((id) => ({ id }));

      dispatch({
        type: ActionTypes.selectableSelectAllItems,
        checked: true,
        items,
      });

      track(trackerEvents.selectableAllSelected, {
        checked: true,
        isAll: true,
      });
    });
  };
}

export function onCheckClickRow(checked, item) {
  return {
    type: ActionTypes.selectableSetSelectedItem,
    checked,
    item,
  };
}

export function onCheckClickWithShift(checked, item, items) {
  return {
    type: ActionTypes.selectableRangeSelection,
    checked,
    item,
    items,
  };
}

export function onHighlightClickRow(item) {
  return { item, type: ActionTypes.selectableSetOneSelectedItem };
}
