import { baseGet, basePut } from 'web/services/api';
import { TEMPLATES_TAG_UPDATE_ACTIONS } from 'web/templates/libs/templatesConstants';
import { Urls } from 'web/libs/routes';

export function getTemplateDetails(id) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.templatesId.replace(':#id', id),
      {
        details: true,
      },
      resolve,
      reject
    );
  });
}

export function updateTemplateDetails(id, template, attachments) {
  const data = {
    attachment_id: attachments,
    details: true,
    pitch_template: template,
  };
  return new Promise((resolve, reject) => {
    basePut(Urls.templatesId.replace(':#id', id), data, resolve, reject);
  });
}

export function updateTemplateTags(id, tag, user, add = true) {
  const data = {
    type: 'PitchTemplate',
    tag,
    viewer: user,
  };
  const action = add
    ? TEMPLATES_TAG_UPDATE_ACTIONS.add
    : TEMPLATES_TAG_UPDATE_ACTIONS.remove;
  return new Promise((resolve, reject) => {
    basePut(
      Urls.templatesTag.replace(':#id', id).replace(':#action', action),
      data,
      resolve,
      reject
    );
  });
}

export const updateBypassUnsubscribe = (id, bypassUnsubscribe) => {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.templateBypassUnsubscribe(id),
      { bypass_unsubscribe: bypassUnsubscribe },
      resolve,
      reject
    );
  });
};
