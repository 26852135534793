import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Switch, { SwitchColors } from 'components/buttons/switch';

class ProfileAccessSwitchAll extends Component {
  render() {
    const {
      intl: { formatMessage },
      switchAllProfileAvailableUsers,
      profiles: { isCheckedAllAvailableUsers },
    } = this.props;
    const switchLabel = isCheckedAllAvailableUsers
      ? formatMessage({
          id:
            'web.salesforceCustomization.wizard.items.profile.access.switch.disableAll',
        })
      : formatMessage({
          id:
            'web.salesforceCustomization.wizard.items.profile.access.switch.enableAll',
        });

    return (
      <Switch
        checked={isCheckedAllAvailableUsers}
        className="margin-bottom-half flex--horz-end"
        color={SwitchColors.blue}
        label={switchLabel}
        name="togglePermissionsEnabled"
        onChange={switchAllProfileAvailableUsers}
      />
    );
  }
}

ProfileAccessSwitchAll.propTypes = {
  intl: intlShape.isRequired,
  switchAllProfileAvailableUsers: PropTypes.func.isRequired,
  profiles: PropTypes.object.isRequired,
};

export default injectIntl(ProfileAccessSwitchAll);
