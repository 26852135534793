import TeamMembersActionTypes from '../libs/teamMembersActionTypes';

export const editTeamsLoading = (state = false, { type, loading }) => {
  switch (type) {
    case TeamMembersActionTypes.setEditTeamsLoading:
      return loading;
    default:
      return state;
  }
};

export const addTeamMembersToTeamsLoading = (
  state = false,
  { type, loading }
) => {
  switch (type) {
    case TeamMembersActionTypes.setAddTeamMembersToTeamsLoading:
      return loading;
    default:
      return state;
  }
};

export const deleteTeamMembersLoading = (state = false, { type, loading }) => {
  switch (type) {
    case TeamMembersActionTypes.setDeleteTeamMembersLoading:
      return loading;
    default:
      return state;
  }
};

export const changeRoleLoading = (state = false, { type, loading }) => {
  switch (type) {
    case TeamMembersActionTypes.setChangeRoleLoading:
      return loading;
    default:
      return state;
  }
};

export const addTeamMembersToTeamLoading = (
  state = false,
  { type, loading }
) => {
  switch (type) {
    case TeamMembersActionTypes.setAddTeamMembersToTeamLoading:
      return loading;
    default:
      return state;
  }
};
