import { createSelector } from 'reselect';
import { CampaignState } from 'web/libs/constants';
import {
  CAMPAIGN_FILTER_ID,
  SORT_ORDER_CONDITION,
  campaignFilterValues,
} from 'web/commandCenter/modules/campaignSmartFilter/libs/campaignSmartFilterConstants';
import { getSmartFilterById } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';

export const getEmailGridCampaingFilterValue = createSelector(
  [getSmartFilterById(CAMPAIGN_FILTER_ID)],
  (filter) =>
    (filter &&
      filter.filterValues &&
      filter.filterValues.length &&
      filter.filterValues[0].value) ||
    campaignFilterValues.noFilterSelected
);

const campaignsForCampaignFilter = (state) => state.campaigns;

export const getCampaignsForCampaignFilter = createSelector(
  [campaignsForCampaignFilter],
  (campaigns) =>
    campaigns
      .sort(
        (a) =>
          a.state === CampaignState.inProgress
            ? SORT_ORDER_CONDITION.HIGH
            : SORT_ORDER_CONDITION.LOW
      )
      .map(({ name, id }) => ({ id, name }))
);

export const getCampaignFilterSelectedCampaign = createSelector(
  [getEmailGridCampaingFilterValue, getCampaignsForCampaignFilter],
  (filterValue, campaigns) => {
    if (campaigns && campaigns.length) {
      const filterValueCampaign = campaigns.find(
        (x) => x.id === Number(filterValue)
      );
      return filterValueCampaign || campaigns[0];
    }
    return null;
  }
);
