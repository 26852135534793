import EmailSettingsActionTypes from '../libs/emailSettingsActionTypes';
import { EmailSettingsTabs } from '../libs/emailSettingsConstants';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import {
  openEditIdentitySlideOut,
  closeEditIdentitySlideOut,
  setEditIdentityId,
  setEditIdentityInfoInstance,
  setIdentityCardEditMode,
  setSignatureCardEditMode,
  setDeliveryChannelCardEditMode,
} from 'web/slideOuts/editIdentity/actionCreators/editIdentityActionCreators';
import { updateUnsubscribeSettings } from 'web/settings/unsubscribes/actionCreators/settingsUnsubscribesActionCreators';

export const setTab = (tab) => ({
  type: EmailSettingsActionTypes.setTab,
  tab,
});

export const emailSettingsStartup = ({
  tab,
  newIdentity,
  editIdentity,
  identityId,
  unsubscribe,
}) => (dispatch) => {
  const activeTab = Object.values(EmailSettingsTabs).includes(tab)
    ? tab
    : EmailSettingsTabs.identities;
  dispatch(commonStartup());
  dispatch(updateUnsubscribeSettings(unsubscribe));
  dispatch(setTab(activeTab));
  if (newIdentity) {
    dispatch(setIdentityCardEditMode(true));
    dispatch(setEditIdentityId(null));
    dispatch(setEditIdentityInfoInstance());
    dispatch(openEditIdentitySlideOut());
  } else if (editIdentity) {
    dispatch(setIdentityCardEditMode(false));
    dispatch(setSignatureCardEditMode(false));
    dispatch(setDeliveryChannelCardEditMode(false));
    dispatch(setEditIdentityId(identityId));
    dispatch(openEditIdentitySlideOut());
  } else {
    dispatch(closeEditIdentitySlideOut());
  }
};
