import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import lowerFirst from 'lodash/lowerFirst';
import classNames from 'classnames';
import Text from 'components/inputs/text';
import LoadingSpinner from 'components/loadingSpinner';
import './autocomplete.scss';

class Autocomplete extends Component {
  state = {
    focused: false,
    hideDropdown: true,
    selectionIndex: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { hideDropdown, focused } = this.state;
    if (focused && nextProps.results.length > 0 && hideDropdown) {
      this._hideDropdown(false);
    } else if (nextProps.results.length === 0 && !hideDropdown) {
      this._hideDropdown(true, true);
    }
  }

  _handleMouseEnter = (e) => {
    this.setState({
      selectionIndex: parseInt(e.currentTarget.id, 10),
    });
  };

  render() {
    const {
      children,
      className,
      dropdownScrollable,
      height,
      initValue: ignored2,
      intl: ignored3,
      loading,
      onKeyDown: ignored8,
      onLeaving: ignored4,
      onEmptyResults: ignored9,
      onSearchClick: ignored6,
      onSelection: ignored5,
      results,
      resultsRenderer: ignored7,
      hideWhenNoValue,
      setRef,
      showError,
      searchIcon,
      width,
      value,
      ...other
    } = this.props; // pulling out to save from passing to InputText
    const { hideDropdown, selectionIndex } = this.state;
    const hideDropdownSinceBlank = hideWhenNoValue && value === '';
    const classes = classNames('autocomplete', className);
    const dropdownClasses = classNames(
      'dropdown',
      { hidden: hideDropdown || hideDropdownSinceBlank },
      { 'scroll-allowed': dropdownScrollable }
    );
    const inputClasses = classNames({
      error: showError,
      open: !(hideDropdown || hideDropdownSinceBlank),
      'full-width': !width,
    });

    const style = { height: `${height}px` };
    if (width) {
      style.width = `${width}px`;
    }

    return (
      <div className={classes}>
        <Text
          {...other}
          className={inputClasses}
          onBlur={this._onBlur}
          onChange={this._handleInputChange}
          onFocus={this._onFocus}
          onKeyDown={this._handleKeyDown}
          setRef={setRef}
          style={style}
          value={value}
        />
        {loading ? (
          <div className="loading-container">
            <LoadingSpinner size={20} />
          </div>
        ) : (
          children
        )}
        <ul className={dropdownClasses}>
          {this._generateListItems(results, selectionIndex)}
        </ul>
      </div>
    );
  }

  //todo currently specific to contacts
  _generateListItems = (results, selectionIndex) => {
    const { resultsRenderer } = this.props;
    return results.map((result, key) => {
      const classes = classNames({ highlight: key === selectionIndex });
      return (
        //eslint-disable-next-line react/no-array-index-key
        <li
          className={classes}
          id={key}
          key={`${key}-${key === selectionIndex}`}
          onMouseDown={this._onClick}
          onMouseEnter={this._handleMouseEnter}
        >
          {resultsRenderer(result, key, selectionIndex, this)}
        </li>
      );
    });
  };

  static _defaultGenerateListItems = (result, key, selectionIndex, self) => {
    const {
      intl: { formatMessage },
    } = self.props;

    const name = self._formatName(result);

    let { address } = result;
    if (result.addressLocation) {
      address += `${address && address.length > 0 ? ' ' : ''}(${formatMessage({
        id: `common.${lowerFirst(result.addressLocation)}`,
      })})`;
    }

    const positionSeparator =
      result.title && result.companyName
        ? formatMessage({ id: 'common.separatorComma' })
        : '';
    const position = `${result.title}${positionSeparator}${result.companyName}`;

    return (
      <React.Fragment>
        <div className="first-row">
          <span className="text-overflow name">{name}</span>
          <span className="tout-icon-sent sent">{result.sentTotal}</span>
        </div>
        <div className="text-overflow">{address}</div>
        <div className="text-overflow">{position}</div>
      </React.Fragment>
    );
  };

  _formatName = (result) => {
    const spacing = result.firstName && result.lastName ? ' ' : '';
    return `${result.firstName}${spacing}${result.lastName}`;
  };

  _handleKeyDown = (e) => {
    const { onEmptyResults, onKeyDown, results } = this.props;
    const { selectionIndex } = this.state;

    if (e.keyCode === 38) {
      // up
      this._increment(false, selectionIndex, results.length);
    } else if (e.keyCode === 40) {
      // down
      this._increment(true, selectionIndex, results.length);
    } else if (e.keyCode === 9 || e.keyCode === 13) {
      // tab or return
      this._selected(results[selectionIndex]);
      if (results.length === 0 && onEmptyResults) {
        onEmptyResults();
      }
    } else if (onKeyDown) {
      onKeyDown(e);
      if (results.length === 0 && onEmptyResults) {
        onEmptyResults();
      }
    }
  };

  _increment = (down, index, length) => {
    const direction = down ? 1 : -1;
    let next = index + direction;
    if (next >= length) {
      next = 0;
    } else if (next < 0) {
      next = length - 1;
    }

    this.setState({
      selectionIndex: next,
    });
  };

  _selected = (chosen) => {
    const { onSelection } = this.props;

    if (typeof chosen === 'object') {
      onSelection(chosen);
    } else {
      this._onBlur();
    }
  };

  _onClick = (e) => {
    const { results } = this.props;
    e.preventDefault();

    this._hideDropdown(true);
    this._selected(results[e.currentTarget.id]);
  };

  _onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  _onBlur = () => {
    const { onLeaving, value } = this.props;

    this._hideDropdown(true);
    if (onLeaving) {
      onLeaving(value);
    }
  };

  _handleInputChange = (value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  _hideDropdown = (state, stayFocused) => {
    this.setState({
      hideDropdown: state,
      focused: stayFocused ? true : !state,
    });
  };
}

Autocomplete.propTypes = {
  className: PropTypes.string,
  dropdownScrollable: PropTypes.bool,
  height: PropTypes.number,
  initValue: PropTypes.string,
  intl: intlShape.isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onEmptyResults: PropTypes.func,
  onLeaving: PropTypes.func,
  onSelection: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  resultsRenderer: PropTypes.func,
  searchIcon: PropTypes.bool,
  showError: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

Autocomplete.defaultProps = {
  className: '',
  dropdownScrollable: false,
  height: 30,
  initValue: '',
  loading: false,
  onChange: null,
  onEmptyResults: null,
  onLeaving: null,
  resultsRenderer: Autocomplete._defaultGenerateListItems,
  searchIcon: false,
  showError: false,
};

export default injectIntl(Autocomplete);
