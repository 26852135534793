import { combineReducers } from 'redux';
import merge from 'lodash/merge';
import adminSettingsRootReducer from '../adminSettings/reducers/adminSettingsRootReducer';
import accountSettingsRootReducer from '../accountSettings/reducers/accountSettingsRootReducer';
import * as sharing from '../sharing/reducers/sharingSettingsReducers';
import * as sharingPopup from '../sharing/reducers/sharingSettingsPopupReducers';
import identitiesTable from '../identitiesTable/reducers/identitiesTableRootReducer';
import emailPluginsRoot from '../emailPlugins/reducers/emailPluginsRootReducer';
import * as settingsUnsubscribes from '../unsubscribes/reducers/settingsUnsubscribeReducer';
import * as adminUnsubscribes from '../adminSettings/unsubscribes/reducers/adminUnsubscribesReducer';
import { yourIntegrations } from '../myProfile/components/yourIntegrations/reducers/yourIntegrationsReducer';
import * as timezoneSettingsReducer from '../myProfile/components/timezoneSettings/reducers/timezoneSettingsReduces';
import * as accountDetails from '../myProfile/components/accountDetails/reducers/accountDetailsReducers';
import * as myProfileAlertReducer from '../myProfile/reducers/myProfileReducers';

export const settingsRootReducer = combineReducers({
  adminSettings: adminSettingsRootReducer,
  accountSettings: accountSettingsRootReducer,
});

export default merge(
  {},
  sharing,
  sharingPopup,
  settingsUnsubscribes,
  adminUnsubscribes,
  emailPluginsRoot,
  identitiesTable,
  timezoneSettingsReducer,
  accountDetails,
  myProfileAlertReducer,
  timezoneSettingsReducer,
  { yourIntegrations }
);
