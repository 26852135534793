import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './taskEventSubject.scss';

const TaskEventSubject = (props) => {
  const { priority, overdue, subject } = props;
  return (
    <div className="task-event-subject">
      {priority === 'high' && (
        <span
          className={classNames('priority-label', { 'overdue-task': overdue })}
        >
          {'!!'}
          &nbsp;
        </span>
      )}
      <span className="text-overflow">{subject}</span>
    </div>
  );
};

TaskEventSubject.propTypes = {
  priority: PropTypes.string,
  overdue: PropTypes.bool,
  subject: PropTypes.string,
};

TaskEventSubject.defaultProps = {
  priority: '',
  overdue: false,
  subject: '',
};

export default TaskEventSubject;
