import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Select from '../base';
import './smartFilter.scss';

const ROW_HEIGHT = 30;

class SmartFilter extends PureComponent {
  render() {
    const { className, filters, height, selectedValue, width } = this.props;

    return (
      <div className={classNames('smart-filter', className)}>
        <Select
          className="smart-filter-select"
          full
          fullHeight
          itemRenderer={this.itemRenderer}
          items={filters}
          labelRenderer={this.labelRenderer}
          menuStyle={{
            position: 'absolute',
            width: `${width + 2}px`,
            top: `${height}px`,
            left: '-1px',
          }}
          noBorder
          onChange={this.onChange}
          rowHeight={ROW_HEIGHT}
          rowsShown={filters.length}
          selected={selectedValue}
          width={width}
        />
      </div>
    );
  }

  labelRenderer = (obj) => (
    <div>
      <span className="text-medium text-uppercase">
        <FormattedMessage id={this.props.labelId} />
      </span>
      <span className="smart-filter-label">{obj.label}</span>
    </div>
  );

  itemRenderer = (item) => (
    <div className="smart-filter-item">
      <div className={classNames('text-overflow', item.className)}>
        {item.label}
      </div>
      {item.helpText && (
        <div className="smart-filter-help text-overflow">{item.helpText}</div>
      )}
    </div>
  );

  onChange = (value) => {
    const { onChange, scrollTop } = this.props;
    onChange(value);
    scrollTop();
  };
}

export const SmartFilterItem = PropTypes.shape({
  className: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  value: PropTypes.string.isRequired,
});

SmartFilter.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.arrayOf(SmartFilterItem).isRequired,
  height: PropTypes.number,
  labelId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  scrollTop: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  width: PropTypes.number,
};

SmartFilter.defaultProps = {
  className: '',
  height: 44,
  labelId: 'selects.smartFilter.label',
  scrollTop: () => {},
  width: 200,
};

export default SmartFilter;
