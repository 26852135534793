import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './filterBlock.scss';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';

class FilterBlock extends Component {
  render() {
    const { filterLabel, filterValues } = this.props;
    if (!filterLabel) return null;

    const stringValues = this.getValues();
    const stringFilterLabel = `${filterLabel}:`;

    const removeBrackets = (str) => str.replace(/[()]/g, '');

    const isCampaignFilter =
      filterValues.length && filterValues[0].campaignOption;

    const formattedStringValues = getLocalizedDateFromGroupName(stringValues);

    return (
      <div className="filter-block">
        <div className="filter-label">
          {!isCampaignFilter && stringFilterLabel}
        </div>
        <div
          className="filter-values text-overflow"
          title={formattedStringValues}
        >
          {isCampaignFilter
            ? removeBrackets(formattedStringValues)
            : formattedStringValues}
        </div>
        <div className="tout-icon-close" onClick={this.remove} />
      </div>
    );
  }

  getValues = () => {
    const { filterPrefix, filterValues } = this.props;
    const stringValues =
      filterValues && filterValues.length
        ? `(${filterValues.map((x) => x.valueLabel).join(', ')})`
        : '';
    return filterPrefix ? `${filterPrefix} ${stringValues}` : stringValues;
  };

  remove = () => {
    const { removeFilter, filterId } = this.props;
    removeFilter(filterId);
  };
}

FilterBlock.propTypes = {
  filterId: PropTypes.string.isRequired,
  filterLabel: PropTypes.string,
  filterPrefix: PropTypes.string,
  filterValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      valueLabel: PropTypes.string,
    })
  ),
  removeFilter: PropTypes.func,
};

FilterBlock.defaultProps = {
  filterLabel: '',
  filterPrefix: '',
  filterValues: [],
  removeFilter: () => {},
};

export default FilterBlock;
