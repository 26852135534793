import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';

class UnsavedChangesPopup extends Component {
  render() {
    const { onStay, onDiscard } = this.props;

    return (
      <Popup
        footer={{
          onPrimary: onStay,
          onSecondary: onDiscard,
          primaryTextId: 'common.stay',
          secondaryTextId: 'common.discard',
        }}
        header={{
          onClose: onStay,
          textId: 'web.safelyChangeRoute.popup.unsavedChanges.header',
        }}
        onOutsideClick={onStay}
        size={PopupSize.small}
      >
        <FormattedMessage id="web.safelyChangeRoute.popup.unsavedChanges.body" />
      </Popup>
    );
  }
}

UnsavedChangesPopup.propTypes = {
  onStay: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export default UnsavedChangesPopup;
