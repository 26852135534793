import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header';
import PageTabNavBar from 'components/layouts/page/tabNavBar';
import PageTabNav from 'components/layouts/page/tabNav';
import PageView from 'components/layouts/page/view';
import EmailPluginsContainer from 'web/settings/emailPlugins/containers/emailPluginsContainer';
import { BackgroundColors } from 'libs/constantsStyles';
import EmailConnectionContainer from 'web/settings/emailConnection/containers/emailConnectionContainer';
import SmtpServerContainer from 'web/settings/smtpServer/containers';
import { EmailSettingsTabs } from '../libs/emailSettingsConstants';
import IdentititesContainer from '../../identitiesTable/container/identitiesTableContainer';
import SettingsUnsubscribesAlert from '../../unsubscribes/containers/settingsUnsubscribesAlertContainer';
import UserUnsubscribesMessagingContainer from 'web/settings/mySettings/unsubscribes/containers/userUnsubscribesMessagingContainer';
import EmailConnectionAlertContainer from 'web/settings/emailConnection/containers/emailConnectionAlertContainer';
import SmtpServerAlertContainer from 'web/settings/smtpServer/containers/smtpServerAlertContainer';
import { isEmailPluginsDisabled } from 'web/shared/helpers/featureFlagHelper';

import './emailSettings.scss';

class EmailSettings extends Component {
  render() {
    const {
      onTabSelect,
      onAddIdentity,
      intl: { formatMessage },
      tab,
      user,
    } = this.props;

    const hidePlugins = isEmailPluginsDisabled(user);

    const tabList = [
      {
        title: formatMessage({
          id: 'web.emailSettings.tabs.addressAndSignature',
        }),
        value: EmailSettingsTabs.identities,
      },
      {
        title: formatMessage({ id: 'web.emailSettings.tabs.emailConnection' }),
        value: EmailSettingsTabs.emailConnection,
      },
      {
        title: formatMessage({ id: 'web.emailSettings.tabs.plugins' }),
        value: EmailSettingsTabs.emailPlugins,
      },
      {
        title: formatMessage({ id: 'web.emailSettings.tabs.smtpServer' }),
        value: EmailSettingsTabs.smtpServer,
      },
      {
        title: formatMessage({ id: 'web.emailSettings.tabs.unsubscribe' }),
        value: EmailSettingsTabs.unsubscribe,
      },
    ];

    const headerButtons = [];
    if (tab === EmailSettingsTabs.identities) {
      headerButtons.push({
        onClick: () => {
          onAddIdentity();
        },
        textId: 'web.identities.addButton',
      });
    }

    return (
      <Page className="email-settings">
        <SettingsUnsubscribesAlert />
        <EmailConnectionAlertContainer />
        <SmtpServerAlertContainer key="email-server-alert-container" />
        <PageHeader buttons={headerButtons} textId="web.settings.email.title" />
        <PageTabNavBar border>
          <PageTabNav
            list={
              hidePlugins
                ? tabList.filter(
                    ({ value }) => value !== EmailSettingsTabs.emailPlugins
                  )
                : tabList
            }
            onTabSelect={onTabSelect}
            selectedTabValue={tab}
          />
        </PageTabNavBar>
        <PageView
          backgroundColor={BackgroundColors.grayExtraLight}
          className="email-settings-page-view"
        >
          {this.getTabPageView()}
        </PageView>
      </Page>
    );
  }

  getTabPageView = () => {
    const { tab } = this.props;

    switch (tab) {
      case EmailSettingsTabs.identities:
        return <IdentititesContainer />;
      case EmailSettingsTabs.emailConnection:
        return <EmailConnectionContainer />;
      case EmailSettingsTabs.emailPlugins:
        return <EmailPluginsContainer />;
      case EmailSettingsTabs.smtpServer:
        return <SmtpServerContainer />;
      case EmailSettingsTabs.unsubscribe:
        return <UserUnsubscribesMessagingContainer />;
      default:
        return null;
    }
  };
}

EmailSettings.propTypes = {
  intl: intlShape.isRequired,
  onAddIdentity: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(EmailSettings);
