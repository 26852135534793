import { createSelector } from 'reselect';
import get from 'lodash/get';
import { EMAIL_THROTTLING_SOURCE_TYPES } from 'web/settings/adminSettings/general/libs/generalPageConstants';

const getTeamSettings = (state) => state.teamSettings;
const getTeamSettingsFetching = (state) => state.teamSettingsFetching;
const getTeamSettingsFetched = (state) => state.teamSettingsFetched;

export const shouldLoadTeamSettings = createSelector(
  [getTeamSettingsFetching, getTeamSettingsFetched],
  (isTeamSettingsFetching, isTeamSettingsFetched) =>
    !(isTeamSettingsFetching || isTeamSettingsFetched)
);

export const teamEmailLimit = createSelector(
  [getTeamSettings],
  (teamSettings) => get(teamSettings, 'teamEmailLimit')
);

export const teamEmailLimitSource = createSelector(
  [getTeamSettings],
  (teamSettings) =>
    get(
      teamSettings,
      'teamEmailLimitSource',
      EMAIL_THROTTLING_SOURCE_TYPES.manual
    )
);

export const teamCampaignLimit = createSelector(
  [getTeamSettings],
  (teamSettings) => get(teamSettings, 'teamCampaignLimit')
);
