import LivefeedTasksActionTypes from '../libs/livefeedTasksActionTypes';
import TasksActionTypes from 'web/tasks/libs/tasksActionTypes';

export function selectedTodoDateFilter(state = '', { type, dateFilter }) {
  switch (type) {
    case LivefeedTasksActionTypes.selectTodoDateFilter:
      return dateFilter;
    default:
      return state;
  }
}

export function selectedOverdueDateFilter(state = '', { type, dateFilter }) {
  switch (type) {
    case LivefeedTasksActionTypes.selectOverdueDateFilter:
      return dateFilter;
    default:
      return state;
  }
}

const initFetchingTasks = false;

export function doneFetchingTasks(state = initFetchingTasks, { type }) {
  switch (type) {
    case TasksActionTypes.fetchingDone:
      return true;
    default:
      return state;
  }
}
