import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeViewAlert as closeViewAlertAC } from '../actionCreators/alertActionCreators';

const AlertContainer = ({ children, ...props }) => {
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { ...props })
  );

  return <>{childrenWithProps}</>;
};

AlertContainer.propTypes = {
  children: PropTypes.node.isRequired,
  viewAlert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  viewAlert: state.viewAlert,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeViewAlert: closeViewAlertAC,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertContainer);
