import { createAction } from '@reduxjs/toolkit';
import {
  getInvitations as getInvitationsCall,
  deleteInvitation as deleteInvitationCall,
  batchDestroyInvitations as batchDestroyInvitationsCall,
  resendInvitation as resendInvitationCall,
  resendInvitationByEmail as resendInvitationByEmailCall,
  batchResendInvitations as batchResendInvitationsCall,
  inviteManyUsers as inviteManyUsersCall,
} from '../services/invitationsService';
import { resendInvitationResponse } from 'web/invitations/libs/invitationsConstants';
import InvitationsActionTypes from '../libs/invitationsActionTypes';

export const setInvitationsFetched = (fetched = true) => ({
  fetched,
  type: InvitationsActionTypes.setInvitationsFetched,
});

export const setInvitationsFetching = (fetching = true) => ({
  fetching,
  type: InvitationsActionTypes.setInvitationsFetching,
});

export const setInvitations = (invitations) => ({
  invitations,
  type: InvitationsActionTypes.setInvitations,
});

export const updateInvitations = (invitations = []) => ({
  invitations,
  type: InvitationsActionTypes.updateInvitations,
});

export const getInvitations = () => (dispatch) => {
  dispatch(setInvitationsFetching());
  return getInvitationsCall()
    .then((invitations) => {
      dispatch(setInvitations(invitations));
      return invitations;
    })
    .finally(() => {
      dispatch(setInvitationsFetching(false));
      dispatch(setInvitationsFetched());
    });
};

export const deleteInvitation = (id) => (dispatch) =>
  deleteInvitationCall(id).then((result) => {
    dispatch({
      id,
      type: InvitationsActionTypes.deleteInvitation,
    });
    return result;
  });

export const batchDestroyInvitations = (ids) => (dispatch) =>
  batchDestroyInvitationsCall(ids).then((batches) => {
    const deletedIds = batches
      .filter((batch) => batch.status === 'success')
      .map((batch) => batch.id);
    dispatch({
      ids: deletedIds,
      type: InvitationsActionTypes.batchDestroyInvitations,
    });
    return batches;
  });

export const resendInvitation = (id) => (dispatch) =>
  resendInvitationCall(id).then((invitation) => {
    dispatch({
      id,
      invitation,
      type: InvitationsActionTypes.resendInvitation,
    });
    return invitation;
  });

export const batchResendInvitations = (ids) => (dispatch) =>
  batchResendInvitationsCall(ids).then((batches) => {
    const invitations = batches
      .filter((batch) => batch.status === 'success')
      .map((batch) => batch.invitation);
    dispatch({
      invitations,
      type: InvitationsActionTypes.batchResendInvitations,
    });
    return batches;
  });

export const inviteManyUsers = (data = {}) => (dispatch) =>
  inviteManyUsersCall(data).then((result = []) => {
    const successInvitations = result
      .filter(({ success, invitation }) => success && !!invitation)
      .map(({ invitation }) => invitation);
    dispatch(updateInvitations(successInvitations));
    return result;
  });

export const setResendInvitationLoading = createAction(
  'SET_RESEND_INVITATION_LOADING'
);

export const setResendInvitationResponse = createAction(
  'SET_RESEND_INVITATION_RESPONSE'
);

export const resendInvitationByEmail = (email) => (dispatch) => {
  dispatch(setResendInvitationLoading(true));
  resendInvitationByEmailCall(email)
    .then(() =>
      dispatch(setResendInvitationResponse(resendInvitationResponse.success))
    )
    .catch(() =>
      dispatch(setResendInvitationResponse(resendInvitationResponse.error))
    )
    .finally(() => dispatch(setResendInvitationLoading(false)));
};
