import React, { Component, Fragment } from 'react';
import DateRangePicker from 'components/dates/dateRangePicker';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  getSelectedValue,
  getDateRange,
  getIsCustomDateChanged,
} from 'web/tables/dateRangeSmartFilter/selectors/dateRangeSmartFilterSelectors';

import { TablesFiltersConstants } from 'web/tables/libs/tablesFiltersConstants';

import {
  setDateRangeSmartFilter,
  setIsCustomDateChanged,
} from 'web/tables/dateRangeSmartFilter/actionCreators/dateRangeSmartFilterActionCreators';
import { getFilterDatesRequired } from 'web/tables/helpers/itemsTableFilters';

const DATE_RANGE_WIDTH = 300;

/**
 * This is HOC component which add config options for initialize DateRange Smart filter.
 * And add custom date range picker for custom values
 * Use smartFilters component for data managment
 */
const dateRangeSmartFilter = (WrappedComponent) => {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  class DateRangeSmartFilterContainer extends Component {
    constructor(props) {
      super(props);

      const { dispatch, dateRangeSyncKey } = this.props;

      this.state = {
        openDateRangePicker: false,
      };

      this.actionCreators = {
        dateRangeSmartFilterSet: (selectedValue, start, end) => {
          dispatch(
            setDateRangeSmartFilter({
              collectionId: this.props.tableId,
              end,
              selectedValue,
              start,
            })
          );
        },
        setIsCustomDateChanged: () => {
          dispatch(setIsCustomDateChanged({ syncKey: dateRangeSyncKey }));
        },
      };
    }

    static displayName = `dateRangeSmartFilter${componentName}`;

    _getSmartFilters() {
      const {
        dateRangeFilters,
        smartFilters,
        intl: { formatMessage },
      } = this.props;

      const selectedValue = this._getSelectedValue();

      const updatedSmartFilters = [
        {
          filters: dateRangeFilters,
          label: formatMessage({ id: 'common.dateLabel' }),
          onChange: this.onDateSmartFilterChange.bind(this),
          selectedValue,
          width: DATE_RANGE_WIDTH,
        },
        ...smartFilters,
      ];

      return updatedSmartFilters;
    }

    render() {
      const {
        dates,
        datesSelectedValue,
        smartFilters,
        isCustomDateChanged,
        dateRangeFiltersChangeHandler: skip1,
        dateRangeSyncKey: skip2,
        ...passThroughProps
      } = this.props;

      const { openDateRangePicker } = this.state;

      const { end, start } = this._getStartEndDates(
        dates,
        datesSelectedValue,
        isCustomDateChanged
      );

      return (
        <Fragment>
          {componentName === 'ToutTable' ? (
            <WrappedComponent
              smartFilters={this._getSmartFilters()}
              {...passThroughProps}
            />
          ) : (
            <WrappedComponent
              {...this._getSmartFilters()[0]}
              {...passThroughProps}
            />
          )}

          {openDateRangePicker && (
            <DateRangePicker
              className="hidden"
              endDate={end}
              onDatesChange={this.onCustomDatesChange}
              startDate={start}
              updateStartDate={this.updateCustomStartDate}
              updateState={this.updateState}
              openOnMount
              withPortal
            />
          )}
        </Fragment>
      );
    }

    onDateSmartFilterChange(index, value) {
      if (value !== TablesFiltersConstants.custom) {
        this.actionCreators.dateRangeSmartFilterSet(value);
        this.setState({ openDateRangePicker: false });

        const { dateRangeFiltersChangeHandler } = this.props;
        if (dateRangeFiltersChangeHandler) {
          dateRangeFiltersChangeHandler();
        }
      } else {
        this.setState({ openDateRangePicker: true });
      }
    }

    _getStartEndDates(dates, datesSelectedValue, isCustomDateChanged) {
      let end;
      let start;

      if (isCustomDateChanged) {
        const actualDates = getFilterDatesRequired(
          datesSelectedValue,
          dates.start,
          dates.end
        );

        ({ start } = actualDates);
        ({ end } = actualDates);
      } else {
        start = moment().startOf('d');
        end = moment().endOf('d');
      }

      return { end, start };
    }

    _getSelectedValue() {
      const { dateRangeFilters, datesSelectedValue } = this.props;

      let selectedValue = datesSelectedValue;

      const isSelectedValueInCurrentFilters = dateRangeFilters.some(
        (dateRange) => dateRange.value === datesSelectedValue
      );

      if (!isSelectedValueInCurrentFilters) {
        selectedValue = dateRangeFilters[0].value;
      }

      return selectedValue;
    }

    onCustomDatesChange = (start, end) => {
      if (start && end) {
        this.actionCreators.dateRangeSmartFilterSet(
          TablesFiltersConstants.custom,
          start,
          end
        );

        const { dateRangeFiltersChangeHandler } = this.props;
        if (dateRangeFiltersChangeHandler) {
          dateRangeFiltersChangeHandler();
        }
      }
    };

    updateState = () => {
      this.setState({
        openDateRangePicker: false,
      });
    };

    updateCustomStartDate = () => {
      this.actionCreators.setIsCustomDateChanged();
    };

    static propTypes = {
      dateRangeFilters: PropTypes.array.isRequired,
      dateRangeFiltersChangeHandler: PropTypes.func,
      dateRangeSyncKey: PropTypes.string.isRequired,
      dates: PropTypes.object,
      datesSelectedValue: PropTypes.string.isRequired,
      intl: intlShape.isRequired,
      smartFilters: PropTypes.array,
      tableId: PropTypes.string.isRequired,
    };

    static defaultProps = {
      dateRangeFiltersChangeHandler: null,
      dates: {},
      smartFilters: [],
    };
  }

  const mapStateToProps = (state, ownProps) => ({
    dates: getDateRange(state, {
      collectionId: ownProps.tableId,
    }),
    datesSelectedValue: getSelectedValue(state, {
      collectionId: ownProps.tableId,
    }),
    isCustomDateChanged: getIsCustomDateChanged(state, {
      syncKey: ownProps.dateRangeSyncKey,
    }),
  });

  return connect(mapStateToProps)(injectIntl(DateRangeSmartFilterContainer));
};

export default dateRangeSmartFilter;
