/**
 *
 * CheckmarkCell
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/buttons/icon';
import { Colors } from 'libs/constantsShared';
import './checkmarkCell.scss';

const CheckmarkCell = ({ color, title, checked }) => (
  <div className="cell-checkmark">
    {checked && <Icon classes="tout-icon-check" color={color} title={title} />}
  </div>
);

CheckmarkCell.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
};

CheckmarkCell.defaultProps = {
  checked: false,
  color: Colors.blue,
  title: '',
};

export default CheckmarkCell;
