import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { BulkEmails } from 'web/composeWindow/libs/composeWindowConstants';
import bulkHoverTooltip from 'web/composeWindow/hoc/bulkHoverTooltip';
import './statusCell.scss';

const StatusCell = ({ rowData: { state } }) => {
  function getTextIdFromState() {
    switch (state) {
      case BulkEmails.stateReason.invalidDynamicFields:
        return 'web.composeWindow.bulkEmails.recipientState.fieldError';
      case BulkEmails.stateReason.blocked:
        return 'web.composeWindow.bulkEmails.recipientState.blocked';
      case BulkEmails.stateReason.unsubscribed:
        return 'web.composeWindow.bulkEmails.recipientState.unsubscribed';
      default:
        return null;
    }
  }

  function getCellColorClass() {
    const warnCssClass = 'warn';
    const errorCssClass = 'error';

    switch (state) {
      case BulkEmails.stateReason.invalidDynamicFields:
        return errorCssClass;
      case BulkEmails.stateReason.blocked:
      case BulkEmails.stateReason.unsubscribed:
        return warnCssClass;
      default:
        return null;
    }
  }

  return (
    <div className="status-cell">
      {state &&
        state !== BulkEmails.stateReason.success && (
          <p className={classNames('status-cell-text', getCellColorClass())}>
            <FormattedMessage id={getTextIdFromState()} />
          </p>
        )}
    </div>
  );
};

StatusCell.propTypes = {
  rowData: PropTypes.object,
};

StatusCell.defaultProps = {
  rowData: {},
};

export default bulkHoverTooltip(StatusCell);
