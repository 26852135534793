import { TimezonesCategories } from '../libs/timezonesConstants';
import I18N from 'languages';

export function formatTimezoneName(name, display) {
  const shown = name.split('/')[0];
  return I18N.getConstStr('web.campaigns.timeZones', `${shown} ${display}`);
}

function parseTimezone(zone) {
  return {
    displayName: zone.display_name,
    label: formatTimezoneName(zone.timezone, zone.display_name),
    offset: zone.offset || 0,
    value: zone.timezone, //official in chrome/owa
  };
}

/* Uses array of labels to set the order of parsing */
export function parseTimezones(
  data = {
    [TimezonesCategories.us]: [],
    [TimezonesCategories.international]: [],
  }
) {
  return [TimezonesCategories.us, TimezonesCategories.international].reduce(
    (timezones, key) => {
      const keyTimezones = data[key].map(parseTimezone);
      timezones.push(keyTimezones);

      return timezones;
    },
    []
  );
}
