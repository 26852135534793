import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Button from 'components/buttons/action';
import './fileDropZone.scss';

const ALL_TYPES_SUPPORTED = '*';

export default class FileDropZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragActive: false,
    };
    this._fileInputRef = null;
  }

  render() {
    const {
      children,
      className,
      empty,
      multiple,
      supportedFormats,
    } = this.props;
    const { dragActive } = this.state;
    const classes = classNames('fileDropZone', className, {
      active: dragActive,
    });

    //todo figure out way to do styles in scss?
    const inlineStyle = {
      // width: width || '100%',
      // height: height || '100%',
      // borderStyle: dragActive ? 'dashed' : ''
    };

    return (
      <div
        className={classes}
        onDragLeave={this._onDragLeave}
        onDragOver={this._onDragOver}
        onDrop={this._uploadFiles}
        style={inlineStyle}
      >
        {dragActive ? (
          <div className="center-content file-drop-zone-dashed">
            <FormattedMessage id="fileDropZone.dropIt" />
          </div>
        ) : (
          <div className="file-drop-zone-area">
            {empty ? (
              <div className="file-drop-zone-solid center-content">
                <span className="file-drop-zone-instructions">
                  <div className="file-drop-zone-drag">
                    <FormattedMessage id="fileDropZone.drag" />
                  </div>
                  <div className="file-drop-zone-divider">
                    <FormattedMessage id="fileDropZone.or" />
                  </div>
                  <Button
                    classes="file-drop-zone-click"
                    color="green"
                    onClick={this._onClick}
                  >
                    <FormattedMessage id="fileDropZone.click" />
                    <input
                      accept={supportedFormats}
                      className="hidden"
                      multiple={multiple}
                      onChange={this._uploadFiles}
                      ref={this._setFileInputRef}
                      type="file"
                    />
                  </Button>
                </span>
              </div>
            ) : (
              <div>{children}</div>
            )}
          </div>
        )}
      </div>
    );
  }

  _setFileInputRef = (input) => (this._fileInputRef = input);

  _onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';

    this.setState({
      dragActive: true,
    });
  };

  _onDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      dragActive: false,
    });
  };

  _onClick = () => {
    this._fileInputRef.click();
  };

  _filterFilesBySupportedTypes = (files) => {
    const { supportedFormats } = this.props;
    if (!supportedFormats || supportedFormats === ALL_TYPES_SUPPORTED) {
      return files;
    }
    return Array.prototype.slice
      .call(files)
      .filter(({ type }) => type && supportedFormats.includes(type));
  };

  _uploadFiles = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      dragActive: false,
    });

    let allFiles;
    if (e.dataTransfer) {
      allFiles = e.dataTransfer.files;
    } else if (e.target) {
      allFiles = e.target.files;
    }
    const supportedFiles = this._filterFilesBySupportedTypes(allFiles);
    if (supportedFiles.length) {
      this.props.uploadFiles(supportedFiles);
    }
  };
}

FileDropZone.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  empty: PropTypes.bool,
  multiple: PropTypes.bool,
  supportedFormats: PropTypes.string,
  uploadFiles: PropTypes.func.isRequired,
};

FileDropZone.defaultProps = {
  children: null,
  className: '',
  empty: false,
  multiple: false,
  supportedFormats: ALL_TYPES_SUPPORTED,
};
