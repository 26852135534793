import LiveFeedActionTypes from '../libs/liveFeedActionTypes';
import { LiveFeedTabsEnum } from 'web/liveFeed/libs/liveFeedConstants';

export function liveFeedIsLoading(state = false, { type = '', loading }) {
  switch (type) {
    case LiveFeedActionTypes.setLoading:
      return loading;
    default:
      return state;
  }
}

export function liveFeedSalesforceInstanceUrl(state = '', action) {
  switch (action.type) {
    case LiveFeedActionTypes.updateSalesforceInstanceUrl:
      return action.salesforceInstanceUrl;
    default:
      return state;
  }
}

export function liveFeedEngageUserAuthentication(
  state = false,
  { type = '', engageFeatureEnabled = false }
) {
  switch (type) {
    case LiveFeedActionTypes.engageUserAuthentication:
      return engageFeatureEnabled;
    default:
      return state;
  }
}

export function liveFeedSelectedTabValue(
  state = LiveFeedTabsEnum.engagement.value,
  { type = '' }
) {
  switch (type) {
    case LiveFeedActionTypes.tabSelected.engagement:
      return LiveFeedTabsEnum.engagement.value;
    case LiveFeedActionTypes.tabSelected.tasks:
      return LiveFeedTabsEnum.tasks.value;
    case LiveFeedActionTypes.tabSelected.target:
      return LiveFeedTabsEnum.target.value;
    default:
      return state;
  }
}
