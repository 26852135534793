import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './attachmentsCount.scss';

class AttachmentsCount extends PureComponent {
  render() {
    const { count, pastTense } = this.props;

    return (
      <div className="attachments-count">
        {count > 0 && (
          <div className="tout-icon-other margin-top-half">
            <span className="margin-left-half attachments-count-text">
              <FormattedMessage
                id="attachmentsCount.text"
                values={{ count, pastTense }}
              />
            </span>
          </div>
        )}
      </div>
    );
  }
}

AttachmentsCount.propTypes = {
  count: PropTypes.number.isRequired,
  pastTense: PropTypes.bool,
};

AttachmentsCount.defaultProps = {
  pastTense: false,
};

export default AttachmentsCount;
