import React from 'react';
import PropTypes from 'prop-types';
import analyticsCard from '../analyticsCard/analyticsCard';
import Metric from '../metric/metric';
import { injectIntl, intlShape } from 'react-intl';
import './campaignAnalyticsList.scss';

const CampaignAnalyticsList = ({ analytics }) => (
  <div className="campaign-analytics-list">
    {analytics.map(({ label, size, value, color }, index) => (
      <Metric
        key={`campaign-analytics-metric-list-${index}`} /* eslint-disable-line react/no-array-index-key */
        size={size}
        label={label}
        value={value}
        color={color}
      />
    ))}
  </div>
);

CampaignAnalyticsList.propTypes = {
  analytics: PropTypes.array.isRequired,
  cardLabel: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  intl: intlShape, // eslint-disable-line react/no-unused-prop-types
};

CampaignAnalyticsList.defaultProps = {
  intl: undefined,
};

export default injectIntl(analyticsCard(CampaignAnalyticsList));
