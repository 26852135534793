import { PopupBackgroundColors } from 'components/popups';
import { PersonDetailsPopupAlertIds } from '../libs/personDetailsConstants';

const getDefaultAlert = (clearAlert) => ({
  onClose: clearAlert,
  color: PopupBackgroundColors.red,
});

export function getAlert({ alert }, { clearAlert }) {
  switch (alert) {
    case PersonDetailsPopupAlertIds.generic:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.common.alert.genericIssue',
      };

    case PersonDetailsPopupAlertIds.invalidOptInReasonSelected:
      return {
        ...getDefaultAlert(clearAlert),
        textId:
          'web.person.personDetails.popup.removeUnsubscribe.invalidOptInReasonSelected',
      };

    default:
      return null;
  }
}
