/*eslint complexity:0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import bind from 'memoize-bind';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import { OrderedMap } from 'immutable';
import { getImageUrl } from 'web/libs/constants';
import { PopupSize } from 'libs/constantsShared';
import Popup from 'components/popups';
import { CommandCenterPopupIds } from 'web/commandCenter/libs/commandCenterConstants';
import PeoplePopupIds from 'web/people/libs/peoplePopupIds';
import { GlobalPopupIds } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import CommandCenterWelcome from '../components/commandCenterWelcome';
import AddPeopleToGroup from 'web/people/containers/addPeopleToGroup';
import { getWelcomeLoading } from '../selectors/commandCenterPopupSelectors';
import { getIsAddPeopleToGroupDisabled } from 'web/people/selectors/peopleSelectors';
import { filterWelcomeTasks } from '../services/commandCenterFilters';
import {
  clearPopupAlert as clearAlert,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { isAdmin } from 'web/shared/services/accountService';
import { removePeopleFromCampaign } from 'web/people/actionCreators/peopleRemoveFromCampaignActionCreators';
import { cloneCurrentSavedAdvancedSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/saveAdvancedSearchActionCreators';
import { deleteAdvancedSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/deleteAdvancedSearchActionCreators';
import { changeAdvancedSearchSavedName } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/saveAdvancedSearchActionCreators';
import { renameAdvancedSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/renameAdvancedSearchActionCreators';
import { addPeopleToGroupAction } from 'web/people/actionCreators/peopleGroupActionCreators';
import EmailGridAdvancedSearchContainer from 'web/commandCenter/modules/emailGridAdvancedSearch/containers/emailGridAdvancedSearchContainer';
import { getAlert } from './commandCenterPopupContainerHelper';
import PersonGroups from 'web/people/components/personGroups';
import DeleteAdvancedSearchComponent from 'web/commandCenter/modules/emailGridAdvancedSearch/components/deleteSearchPopupComponent';
import SaveAdvancedSearchComponent from 'web/commandCenter/modules/emailGridAdvancedSearch/components/emailGridAdvancedSearch/saveSearchPopUpComponent';
import AddToCampaignContainer from 'web/modals/addToCampaign/container/addToCampaignContainer';
import { BackgroundColors } from 'libs/constantsStyles';
import SavedAdvancedSearchHeader from 'web/commandCenter/modules/emailGridAdvancedSearch/components/emailGridAdvancedSearch/savedAdvancedSearchHeader';
import { getSelectedSavedSearchName } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';
import {
  removePitch,
  stopWorkflowForContacts,
} from 'web/commandCenter/actionCreators/commandCenterEmailsActionCreators';
import PeopleUnsubscribe from 'web/people/components/peopleUnsubscribe';
import { unsubscribePeople } from 'web/people/actionCreators/peopleUnsubscribeActionCreators';
import { ButtonColors } from 'components/buttons/action';

const EMPTY_POPUP = {};

class CommandCenterPopupContainer extends Component {
  render() {
    const { popup } = this.props;
    let object = EMPTY_POPUP;
    if (popup) {
      object = this._getGlobalPopupObject();
    }

    /* className used for exceptionSelectors for outside clicks in parent popup */
    return (
      <div className="command-center-popup-container">
        <AddToCampaignContainer key="people-popup-add-to-campaign-container" />
        {object.children ? <Popup {...object} /> : null}
      </div>
    );
  }

  _getGlobalPopupObject() {
    const { loading, clearAlert } = this.props;
    return {
      alert: getAlert(this.props, { clearAlert }),
      getImageUrl,
      loading,
      ...this._getMainComponent(),
    };
  }

  _getMainComponent() {
    const {
      intl: { formatMessage },
      loading,
      popup,
      closePopup,
      cloneCurrentSavedAdvancedSearch,
      deleteAdvancedSearch,
      removePeopleFromCampaign,
      addPeopleToGroupAction,
      isAddPeopleToGroupDisabled,
      popupData: { callBack: onSuccess },
      removePitch,
      stopWorkflowForContacts,
      unsubscribePeople,
    } = this.props;

    switch (popup) {
      case CommandCenterPopupIds.advancedSearchSave:
      case CommandCenterPopupIds.advancedSearchClone:
      case CommandCenterPopupIds.advancedSearch: {
        const {
          changeAdvancedSearchSavedName,
          selectedSavedSearchName,
          renameAdvancedSearch,
        } = this.props;

        const saveAdvancedSearchHeader = (name) => {
          changeAdvancedSearchSavedName({ name });
          renameAdvancedSearch();
        };

        return {
          children: <EmailGridAdvancedSearchContainer />,
          className: 'advanced-search',
          exceptionSelectors: ['.DayPicker'],
          header: {
            backgroundColor: BackgroundColors.white,
            children: (
              <SavedAdvancedSearchHeader
                onClone={cloneCurrentSavedAdvancedSearch}
                onSave={saveAdvancedSearchHeader}
                searchName={selectedSavedSearchName}
              />
            ),
            className: 'advanced-search-header flex--center-vert',
            onClose: closePopup,
            textColor: BackgroundColors.black,
          },
          onOutsideClick: closePopup,
          size: PopupSize.massive,
        };
      }
      case CommandCenterPopupIds.emailDeleteConfirmation: {
        const {
          popupData: { emailId, personName, email, subject },
        } = this.props;

        const confirmRemovingEmail = () => {
          removePitch(emailId);
          closePopup();
        };

        return {
          children: (
            <div className="margin-top-full margin-bottom-full">
              <FormattedHTMLMessage
                id="web.emails.deleteEmailConfirmationMessage"
                values={{
                  email,
                  personName,
                  subject,
                }}
              />
            </div>
          ),
          className: 'remove-confirmation',
          footer: {
            onPrimary: confirmRemovingEmail,
            onSecondary: closePopup,
            primaryTextId: 'common.yes',
            secondaryTextId: 'common.no',
          },
          header: {
            onClose: closePopup,
            textId: 'web.emails.deleteEmailConfirmationTitle',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case CommandCenterPopupIds.workflowEmailDeleteConfirmation: {
        const {
          popupData: { emailId, person_id },
        } = this.props;

        return {
          children: (
            <div className="margin-top-full margin-bottom-full">
              <FormattedHTMLMessage id="web.emails.deleteWorkflowEmailConfirmationMessage" />
            </div>
          ),
          className: 'remove-confirmation',
          footer: {
            onPrimary: () => {
              stopWorkflowForContacts(emailId, [person_id], true);
            },
            onSecondary: () => {
              stopWorkflowForContacts(emailId, [person_id]);
            },
            primaryTextId: 'conversationCampaign.markAsSuccess',
            secondaryTextId: 'web.emails.removeFromCampaign',
          },
          header: {
            onClose: closePopup,
            textId: 'web.emails.deleteCampaignEmailConfirmationTitle',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case CommandCenterPopupIds.welcome: {
        const {
          activeCampaigns,
          tasksUncompleted,
          welcomeLoading,
        } = this.props;
        return {
          children: !loading && (
            <CommandCenterWelcome
              {...filterWelcomeTasks(tasksUncompleted.valueSeq().toArray())}
              loading={welcomeLoading}
              runningCampaigns={activeCampaigns.length}
            />
          ),
          className: 'welcome',
          footer: {
            onPrimary: closePopup,
            primaryTextId: 'web.commandCenter.welcome.primaryText',
          },
          header: {
            text: formatMessage(
              { id: 'web.commandCenter.welcome.header' },
              { time: CommandCenterWelcome.getPopupTitle() }
            ),
          },
          loading: welcomeLoading,
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case PeoplePopupIds.personGroupsModal: {
        const {
          popupData: { groups, name },
        } = this.props;

        return {
          children: <PersonGroups personGroupsList={groups} />,
          className: 'person-groups-modal',
          footer: {
            onSecondary: closePopup,
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: closePopup,
            textId: 'web.people.personGroupsListHeader',
            textValues: {
              name,
            },
          },
          onOutsideClick: closePopup,
          size: PopupSize.large,
        };
      }
      case GlobalPopupIds.removeConfirmation: {
        const {
          isAdmin,
          popupData: { callback, ids },
        } = this.props;
        return {
          children: (
            <div className="margin-top-full margin-bottom-full">
              <FormattedMessage
                id="web.campaigns.removeConfirmation"
                values={{ people: ids.length }}
              />
            </div>
          ),
          className: 'remove-confirmation',
          footer: {
            onPrimary: bind(
              removePeopleFromCampaign,
              this,
              ids,
              isAdmin,
              callback
            ),
            onSecondary: closePopup,
            primaryTextId: 'common.yes',
            secondaryTextId: 'common.no',
          },
          header: {
            onClose: closePopup,
            textId: 'web.campaigns.stopCampaign',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case PeoplePopupIds.addPeopleToGroup: {
        return {
          children: <AddPeopleToGroup />,
          className: 'add-people-to-group',
          footer: {
            onPrimary: bind(addPeopleToGroupAction, this, onSuccess),
            onSecondary: closePopup,
            primaryDisabled: isAddPeopleToGroupDisabled,
            primaryTextId: 'common.add',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: closePopup,
            textId: 'web.people.addPeopleToGroupHeader',
          },
          onOutsideClick: closePopup,
          size: PopupSize.large,
        };
      }
      case PeoplePopupIds.unsubscribePeople: {
        const {
          popupData: { peopleIds },
        } = this.props;
        return {
          children: <PeopleUnsubscribe />,
          footer: {
            onPrimary: bind(unsubscribePeople, this, peopleIds, onSuccess),
            onSecondary: closePopup,
            primaryColor: ButtonColors.red,
            primaryText: this.props.intl.formatMessage(
              { id: 'web.peopleUnsubscribe.unsubscribe' },
              { count: peopleIds.length }
            ),
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: closePopup,
            text: this.props.intl.formatMessage(
              { id: 'web.peopleUnsubscribe.header' },
              { count: peopleIds.length }
            ),
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case CommandCenterPopupIds.advancedSearchDelete: {
        return {
          children: (
            <DeleteAdvancedSearchComponent onDelete={deleteAdvancedSearch} />
          ),
          className: 'advanced-search-delete',
          footer: {
            onPrimary: deleteAdvancedSearch,
            onSecondary: closePopup,
            primaryTextId: 'common.delete',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: closePopup,
            textId: 'web.commandCenter.advancedSearch.deleteHeader',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case CommandCenterPopupIds.advancedSearchRename: {
        const {
          changeAdvancedSearchSavedName,
          emailSavedAdvancedSearches,
          popupData: { savedSearchId },
          renameAdvancedSearch,
        } = this.props;
        const { name } = emailSavedAdvancedSearches[savedSearchId];
        changeAdvancedSearchSavedName({ name }); // sync between initial input state and redux store value
        return {
          children: (
            <SaveAdvancedSearchComponent
              defaultValue={name}
              onSave={renameAdvancedSearch}
              onSearcNameChange={(name) =>
                changeAdvancedSearchSavedName({ name })
              }
            />
          ),
          className: 'advanced-search-rename',
          footer: {
            onPrimary: renameAdvancedSearch,
            onSecondary: closePopup,
            primaryTextId: 'common.rename',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: closePopup,
            textId: 'web.commandCenter.advancedSearch.renameHeader',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      default:
        return EMPTY_POPUP;
    }
  }
}

CommandCenterPopupContainer.propTypes = {
  activeCampaigns: PropTypes.array.isRequired,
  alert: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object.isRequired,
  taskEditState: PropTypes.object.isRequired,
  tasksUncompleted: PropTypes.instanceOf(OrderedMap).isRequired,
  welcomeLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  activeCampaigns: state.commandCenterActiveCampaigns,
  alert: state.popupAlert,
  emailSavedAdvancedSearches: state.emailSavedAdvancedSearches,
  isAddPeopleToGroupDisabled: getIsAddPeopleToGroupDisabled(state),
  isAdmin: isAdmin(),
  loading: state.popupLoading,
  popup: state.popup,
  popupData: state.popupData,
  selectedSavedSearchName: getSelectedSavedSearchName(state),
  taskEditState: state.taskEditState,
  tasksUncompleted: state.tasksUncompleted,
  welcomeLoading: getWelcomeLoading(state),
});

const mapDispatchToProps = {
  addPeopleToGroupAction,
  changeAdvancedSearchSavedName,
  clearAlert,
  cloneCurrentSavedAdvancedSearch,
  closePopup,
  deleteAdvancedSearch,
  removePeopleFromCampaign,
  removePitch,
  renameAdvancedSearch,
  stopWorkflowForContacts,
  unsubscribePeople,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CommandCenterPopupContainer);
