import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header';
import PageTabNavBar from 'components/layouts/page/tabNavBar';
import PageTabNav from 'components/layouts/page/tabNav';
import PageView from 'components/layouts/page/view';
import { BackgroundColors } from 'libs/constantsStyles';
import { TrackingTabs } from '../libs/trackingConstants';
import CustomDomainContainer from '../containers/customDomainContainer';
import { isWebsiteTrackingDisabled } from 'web/shared/helpers/featureFlagHelper';
import WebsiteTrackingContainer from '../containers/websiteTrackingContainer';

class TrackingPage extends PureComponent {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
      user,
    } = this.props;

    this.tabList = [
      {
        title: formatMessage({
          id: 'web.settings.adminSettings.tracking.tabs.customDomainTracking',
        }),
        value: TrackingTabs.customDomainTracking,
      },
    ];

    if (!isWebsiteTrackingDisabled(user)) {
      this.tabList.push({
        title: formatMessage({
          id: 'web.settings.adminSettings.tracking.tabs.websiteTracking',
        }),
        value: TrackingTabs.websiteTracking,
      });
    }
  }

  render() {
    const { onTabSelect, tab } = this.props;

    return (
      <Page className="tracking-admin-page">
        <PageHeader textId="web.settings.adminSettings.tracking.title" />
        <PageTabNavBar border>
          <PageTabNav
            list={this.tabList}
            onTabSelect={onTabSelect}
            selectedTabValue={tab}
          />
        </PageTabNavBar>
        <PageView backgroundColor={BackgroundColors.grayExtraLight}>
          {this.tabPageView}
        </PageView>
      </Page>
    );
  }

  get tabPageView() {
    const { tab } = this.props;

    switch (tab) {
      case TrackingTabs.customDomainTracking:
        return <CustomDomainContainer />;
      case TrackingTabs.websiteTracking:
        return <WebsiteTrackingContainer />;
      default:
        return null;
    }
  }
}

TrackingPage.propTypes = {
  intl: intlShape.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default injectIntl(TrackingPage);
