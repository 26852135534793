import { createAction } from '@reduxjs/toolkit';
import { updateAdvancedSearch as updateAdvancedSearchService } from 'web/commandCenter/modules/emailGridAdvancedSearch/services/advancedSearchService';
import { CommandCenterPopupIds } from 'web/commandCenter/libs/commandCenterConstants';
import {
  openPopup,
  closePopup,
  setPopupLoading,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';
import {
  isSavedSearchNameEmpty,
  isSavedSearchExists,
} from 'web/commandCenter/helpers/emailHelpers';

export const renameSavedEmailAdvancedSearch = createAction(
  'RENAME_EMAIL_ADVANCED_SEARCH_SAVE'
);

export const openRenameSearchPopup = (savedSearchId) => (dispatch) =>
  dispatch(
    openPopup(CommandCenterPopupIds.advancedSearchRename, { savedSearchId })
  );

export const renameAdvancedSearch = () => async (dispatch, getState) => {
  const {
    advancedSearchSavedName,
    emailSavedAdvancedSearches,
    popupData: { savedSearchId },
    emailSelectedSavedAdvancedSearchId,
  } = getState();

  if (isSavedSearchNameEmpty(advancedSearchSavedName)) {
    dispatch(setPopupAlert(EmailAlertIds.savedSearchNameEmpty));
    return;
  }

  if (
    isSavedSearchExists(advancedSearchSavedName, emailSavedAdvancedSearches)
  ) {
    dispatch(setPopupAlert(EmailAlertIds.savedSearchNameExists));
    return;
  }

  const currentSearchId = savedSearchId || emailSelectedSavedAdvancedSearchId;

  const params = {
    conversation_filter: {
      name: advancedSearchSavedName,
    },
    savedSearchId: currentSearchId,
  };

  dispatch(setPopupLoading(true));
  try {
    await updateAdvancedSearchService(params);
    dispatch(setPopupLoading(false));
    dispatch(
      renameSavedEmailAdvancedSearch({
        name: advancedSearchSavedName,
        savedSearchId: currentSearchId,
      })
    );
    dispatch(closePopup());
  } catch (e) {
    dispatch(setPopupLoading(false));
    dispatch(setPopupAlert(EmailAlertIds.renameSavedSearchError));
  }
};
