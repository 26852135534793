import AddContactSlideOutActionTypes from '../libs/addContactSlideOutActionTypes';
import SlideOutsActionTypes from 'web/slideOuts/libs/slideOutsActionTypes';
import PersonDetailsSlideOutActionTypes from 'web/slideOuts/personDetails/libs/personDetailsSlideOutActionTypes';

export const addContactSlideOutIsOpen = (state = false, { type = '' }) => {
  switch (type) {
    case AddContactSlideOutActionTypes.open:
      return true;
    case AddContactSlideOutActionTypes.close:
    case SlideOutsActionTypes.close:
    case PersonDetailsSlideOutActionTypes.open:
      return false;
    default:
      return state;
  }
};
