import { PopupBackgroundColors } from 'components/popups';
import { SharingSettingsAlertIds } from '../../libs/sharingSettingsConstants';

const getDefaultAlert = (clearAlert) => ({
  color: PopupBackgroundColors.redDark,
  onClose: clearAlert,
});

export const getAlert = ({ alert }, { clearAlert }) => {
  switch (alert) {
    case SharingSettingsAlertIds.renameCategoryIssue:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.settings.sharing.alert.renameCategoryIssue',
      };
    case SharingSettingsAlertIds.settingSharingIssue:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.settings.sharing.alert.setSharingIssue',
      };
    case SharingSettingsAlertIds.getTeamsIssue:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.settings.sharing.alert.getTeamsIssue',
      };
    default:
      return null;
  }
};
