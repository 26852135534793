import { baseGet, basePost, basePut } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const getCompliance = (id) =>
  new Promise((resolve, reject) => {
    baseGet(`${Urls.peopleCompliance}/${id}/compliances`, {}, resolve, reject);
  });

export const saveCompliance = (id, complianceData = {}) =>
  new Promise((resolve, reject) => {
    basePost(
      `${Urls.peopleCompliance}/${id}/compliances`,
      { ...complianceData },
      resolve,
      reject
    );
  });

export const updateCompliance = (personId, complianceData = {}, complianceId) =>
  new Promise((resolve, reject) => {
    basePut(
      `${Urls.peopleCompliance}/${personId}/compliances/${complianceId}`,
      { ...complianceData },
      resolve,
      reject
    );
  });
