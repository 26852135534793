import { createSelector } from 'reselect';
import startCase from 'lodash/startCase';
import { getReasonLabelsGroupById } from 'web/unsubscribe/unsubscribeOptions/selectors/unsubscribeOptionsSelectors';
import { REASON_TYPE } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';
import {
  isUnsubscribed,
  getUnsubscribe,
} from 'web/person/helpers/personHelpers';
import {
  isUnsubscribedPerson,
  isGeneralPeopleGroup,
  getPrimaryEmailFromAddresses,
  getPeopleSidebarSearchResults,
} from 'web/people/helpers/peopleHelpers';
import {
  PEOPLE_GENERAL_GROUPS,
  PEOPLE_SIDEBAR_GENERAL_GROUP_LIST,
  DO_NOT_ADD_CONTACT_ID,
  EMAIL_FIELDS,
  DO_NOT_IMPORT_FIELD_ID,
  ADD_NEW_FIELD,
  MAX_GROUP_DESCRIPTION_LENGTH,
} from 'web/people/libs/peopleConstants';
import { isMsiActions as getIsMsiActions } from 'web/user/selectors/userSelectors';
import I18N from 'languages';
import get from 'lodash/get';

const getPeopleCurrentGroupId = (state) => state.peopleCurrentGroupId;
const getPeopleData = (state) => state.peopleGridData;
const getUser = (state) => state.user;
const getUnsubscribeReasons = (state) =>
  getReasonLabelsGroupById(state, REASON_TYPE.unsubscribe);
const getImportFieldMaps = (state) => state.importFieldMaps;
const getGroupsModalPersonId = (state) => state.popupData.personId;
const getSidebarSearchQuery = (state) => state.sidebarSearchQuery;
const getUserGroups = (state) => state.groups;
const getUserSubscriptionUsers = (state) => state.userSubscriptionUsers;

const getReasonLabel = (unsubscribe, unsubscribeReasons) => {
  const {
    reason_description: reasonDescription,
    unsubscribe_reason_id: unsubscribeReasonId,
  } = unsubscribe;

  if (reasonDescription) {
    return reasonDescription;
  } else if (unsubscribeReasonId) {
    return unsubscribeReasons[unsubscribeReasonId];
  }

  return '';
};

const getUnsubscribeInfo = (person, users, unsubscribeReasons) => {
  const unsubscribe = getUnsubscribe(person);

  const unsubscribeInfoDefault = {
    unsubscribe_date: '',
    unsubscribe_email: '',
    unsubscribe_reason: '',
    unsubscribe_type: '',
    unsubscriber_name: '',
  };

  if (!isUnsubscribed(person)) {
    return unsubscribeInfoDefault;
  }

  const {
    created_at: createdAt,
    block_way: blockWay,
    user_id: userId,
    email,
  } = unsubscribe;

  const unsubscribeInfo = {
    ...unsubscribeInfoDefault,
    unsubscribe_date: createdAt
      ? I18N.getLocalizedDate(createdAt, I18N.DateFormats.DATE_SLASH)
      : '',
    unsubscribe_email: email || '',
    unsubscribe_reason: getReasonLabel(unsubscribe, unsubscribeReasons),
    unsubscribe_type: startCase(blockWay, ''),
  };

  if (unsubscribe) {
    const unsubscriberIndex = users.findIndex(
      ({ id: currentUserId }) => currentUserId === userId
    );
    unsubscribeInfo.unsubscriber_name =
      unsubscriberIndex > -1 ? users[unsubscriberIndex].name : '';
  }

  return unsubscribeInfo;
};

const getSelectedAddPeopleToGroupPeople = (state) =>
  state.selectedAddPeopleToGroupPeople;

export const isCurrentGroupUnsubscribed = (state) =>
  state.peopleCurrentGroupId === PEOPLE_GENERAL_GROUPS.unsubscribed.id;

const getSubscribedGridData = (peopleData, users, isFilterUnsubscribed) => {
  const data = [];

  peopleData.forEach((person) => {
    if (isFilterUnsubscribed && isUnsubscribedPerson(person)) {
      return;
    }
    const primaryEmail = get(person, 'primary_addresses.email', '');
    data.push({
      ...person,
      campaign_name: get(person, 'workflow_details.name', null),
      company_name:
        get(person, 'company.name', null) || get(person, 'company', null),
      creator: users.find((item) => item.id === person.user_id),
      email: primaryEmail || getPrimaryEmailFromAddresses(person.addresses),
      phone: get(person, 'primary_addresses.phone', ''),
    });
  });

  return data;
};

const getUnsubscribedGridData = (peopleData, users, unsubscribeReasons) => {
  const data = [];

  peopleData.forEach((person) => {
    if (isUnsubscribedPerson(person)) {
      const unsubscribeInfo = getUnsubscribeInfo(
        person,
        users,
        unsubscribeReasons
      );
      const { unsubscribe_details: unsubscribeDetails = {} } = person;
      const unsubscribeDate = unsubscribeDetails.unsubscribe_date;
      data.push({
        ...person,
        ...unsubscribeInfo,
        company_name:
          get(person, 'company.name', null) || get(person, 'company', null),
        reason: getReasonLabel(unsubscribeDetails, unsubscribeReasons),
        unsubscribe_date: unsubscribeDate
          ? I18N.getLocalizedDate(unsubscribeDate, I18N.DateFormats.DATE_SLASH)
          : '',
        unsubscribe_email: get(person, 'primary_addresses.email', ''),
        unsubscribe_phone: get(person, 'primary_addresses.phone', ''),
      });
    }
  });

  return data;
};

export const getPeopleGridData = createSelector(
  [
    getPeopleData,
    getUser,
    getUnsubscribeReasons,
    isCurrentGroupUnsubscribed,
    getPeopleCurrentGroupId,
  ],
  (
    peopleData,
    { subscription: { users = [] } = {} },
    unsubscribeReasons,
    isGroupUnsubscribed,
    currentGroupId
  ) => {
    if (isGroupUnsubscribed) {
      return getUnsubscribedGridData(peopleData, users, unsubscribeReasons);
    }

    const isFilterUnsubscribed = !isGeneralPeopleGroup(currentGroupId);

    return getSubscribedGridData(peopleData, users, isFilterUnsubscribed);
  }
);

export const getAllPeopleGroups = createSelector([getUserGroups], (groups) => [
  ...groups,
  ...Object.values(PEOPLE_GENERAL_GROUPS),
]);

export const getCurrentPeopleGroupName = createSelector(
  [getAllPeopleGroups, getPeopleCurrentGroupId],
  (peopleGroups, peopleCurrentGroupId) => {
    const { name = '' } =
      peopleGroups.find(({ id }) => `${id}` === peopleCurrentGroupId) || {};
    return name;
  }
);

const isTeamGroupsEnabled = (state) =>
  !!get(state, 'user.subscription.team_groups_enabled');

export const getGroupSidebarList = createSelector(
  [getUserGroups, isTeamGroupsEnabled, getUser, getIsMsiActions],
  (groups, isTeamGroupsEnabledParam, user, isMsiActions) => {
    const myGroups = [];
    const teamGroups = [];
    const { admin } = user;

    groups.forEach((group) => {
      const { team_ids: teamIds, user_id: groupUserId } = group;
      const newGroup = {
        ...group,
        isEditable: user.id === groupUserId || admin,
      };

      if (
        Array.isArray(teamIds) &&
        teamIds.length > 0 &&
        isTeamGroupsEnabledParam
      ) {
        teamGroups.push(newGroup);
      } else {
        myGroups.push(newGroup);
      }
    });

    const dynamicGroups = {
      list: [PEOPLE_SIDEBAR_GENERAL_GROUP_LIST.dynamicGroups.unsubscribed],
      title: PEOPLE_SIDEBAR_GENERAL_GROUP_LIST.dynamicGroups.title,
    };

    if (!isMsiActions) {
      dynamicGroups.list.push(
        PEOPLE_SIDEBAR_GENERAL_GROUP_LIST.dynamicGroups.myContacts
      );
    }

    return {
      dynamicGroups,
      groups: PEOPLE_SIDEBAR_GENERAL_GROUP_LIST.groups,
      ...(myGroups.length
        ? {
            myGroups: {
              list: myGroups,
              title: I18N.getStr('common.myGroups'),
            },
          }
        : {}),
      ...(teamGroups.length
        ? {
            teamGroups: {
              list: teamGroups,
              title: I18N.getStr('common.teamGroups'),
            },
          }
        : {}),
    };
  }
);

export const getIsAddPeopleToGroupDisabled = createSelector(
  [getSelectedAddPeopleToGroupPeople],
  (people) =>
    !people.filter(
      (item) => item.emailId !== DO_NOT_ADD_CONTACT_ID && !item.isUnsubscribed
    ).length
);

export const getEmailIds = createSelector(
  [getSelectedAddPeopleToGroupPeople],
  (people) => {
    const resultArray = [];

    people.forEach(({ emailId, isUnsubscribed }) => {
      if (emailId !== DO_NOT_ADD_CONTACT_ID && !isUnsubscribed) {
        resultArray.push(emailId);
      }
    });

    return resultArray;
  }
);

export const getUpdateMappingData = createSelector(
  [getImportFieldMaps, getPeopleCurrentGroupId],
  (importFieldMaps, peopleCurrentGroupId) => {
    const mappings = importFieldMaps.reduce(
      (result, { csvField, salesConnectField }) => ({
        ...result,
        [csvField]:
          salesConnectField === ADD_NEW_FIELD
            ? DO_NOT_IMPORT_FIELD_ID
            : salesConnectField,
      }),
      {}
    );

    return {
      group_id: peopleCurrentGroupId,
      mappings,
    };
  }
);

export const getIsImportButtonVisible = createSelector(
  [getUserGroups, getPeopleCurrentGroupId, getUser],
  (groups, peopleCurrentGroupId, user) => {
    if (
      [
        PEOPLE_GENERAL_GROUPS.myContacts.id,
        PEOPLE_GENERAL_GROUPS.everyone.id,
      ].includes(peopleCurrentGroupId)
    ) {
      return true;
    }

    const { admin } = user;
    const { id: groupId, user_id: groupUserId } =
      groups.find(
        (group) => group.id.toString() === peopleCurrentGroupId.toString()
      ) || {};

    if (
      groupId &&
      !isGeneralPeopleGroup(groupId) &&
      (user.id === groupUserId || admin)
    ) {
      return true;
    }

    return false;
  }
);

export const getIsIncludesEmailField = createSelector(
  [getImportFieldMaps],
  (importFieldMaps) =>
    importFieldMaps.some(({ salesConnectField }) =>
      EMAIL_FIELDS.includes(salesConnectField)
    )
);

export const getIsDeleteIconDisabled = createSelector(
  [getUserGroups, getPeopleCurrentGroupId, getUser],
  (groups, peopleCurrentGroupId, { id: userId, admin }) => {
    if (isGeneralPeopleGroup(peopleCurrentGroupId)) {
      return true;
    }

    const { user_id: groupUserId } =
      groups.find(
        (group) => group.id.toString() === peopleCurrentGroupId.toString()
      ) || {};

    return !(userId === groupUserId || (admin && groupUserId));
  }
);

export const getIsShareIconDisabled = createSelector(
  [getUserGroups, getPeopleCurrentGroupId, getUser],
  (groups, peopleCurrentGroupId, { id: userId, admin }) => {
    if (isGeneralPeopleGroup(peopleCurrentGroupId)) {
      return true;
    }

    const { user_id: groupUserId } =
      groups.find(
        (group) => group.id.toString() === peopleCurrentGroupId.toString()
      ) || {};

    return !(userId === groupUserId || (admin && groupUserId));
  }
);

export const getSelectedGroup = createSelector(
  [getGroupSidebarList, getPeopleCurrentGroupId],
  (groupSidebarList, peopleCurrentGroupId) => {
    const groupTypes = Object.keys(groupSidebarList);

    for (let i = 0; i < groupTypes.length; i++) {
      const groupType = groupTypes[i];
      const group = groupSidebarList[groupType].list.find(
        (group) => group.id.toString() === peopleCurrentGroupId
      );
      if (group) {
        const { description } = group;
        return {
          ...group,
          truncatedDescription:
            description && description.length > MAX_GROUP_DESCRIPTION_LENGTH
              ? `${description.substr(0, MAX_GROUP_DESCRIPTION_LENGTH)}...`
              : description,
        };
      }
    }

    return null;
  }
);

export const getClickedPersonData = createSelector(
  [getGroupsModalPersonId, getPeopleData],
  (personId, peopleData) => {
    const personData = peopleData.find(({ id }) => personId === id);

    if (personData && personData.groups) {
      return {
        groups: personData.groups.map(({ group_id: id, group_name: name }) => ({
          id,
          name,
        })),
        name: `${personData.first_name} ${personData.last_name}`,
      };
    }

    return { groups: [] };
  }
);

export const getSidebarSearchResults = createSelector(
  [getSidebarSearchQuery, getGroupSidebarList],
  getPeopleSidebarSearchResults
);

export const getGroup = (groupId) =>
  createSelector([getUserGroups], (userGroups) => {
    const group = userGroups.find(
      ({ id }) => id.toString() === groupId.toString()
    );

    return group;
  });

export const getShareGroup = (groupId) =>
  createSelector(
    [getUserGroups, getUserSubscriptionUsers],
    (userGroups, userSubscriptionUsers) => {
      const shareGroup = userGroups.find(
        ({ id }) => id.toString() === groupId.toString()
      );

      if (!shareGroup) {
        return null;
      }

      shareGroup.creator = Object.values(userSubscriptionUsers).find(
        ({ id }) => id === shareGroup.user_id
      );

      return shareGroup;
    }
  );

export const getFormattedGroupsSelectOptions = createSelector(
  [getUserGroups],
  (groups) =>
    groups.map((group) => ({
      label: group.name,
      value: group.id,
      ...group,
    }))
);
