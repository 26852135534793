import React from 'react';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header/pageHeader';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import PageView from 'components/layouts/page/view';
import Cards from 'components/layouts/cards';
import CallOutcomeSettingsCard from 'web/settings/adminSettings/dialer/components/settingsCards/callOutcomeSettingsCard';
import CallReasonSettingsCard from 'web/settings/adminSettings/dialer/components/settingsCards/callReasonSettingsCard';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DialerSettingsAlert from '../components/alerts/dialerSettingsAlert';
import SalesEngagePhone from 'web/settings/adminSettings/general/components/salesEngagePhone/salesEngagePhone.js';
import { getActiveRecordingNotice } from 'web/modals/manageRecordingNotice/actionCreators/recordingNoticeActionCreators';
import {
  updatePhoneSettings,
  openManageRecordingNotice,
} from 'web/settings/adminSettings/general/actionCreators/salesEnagePhoneActionCreators';
import { bindActionCreators } from 'redux';
import { updateDialerSettings } from '../actionCreators/dialerSettingsActionCreators';

const DialerSettingsContainer = ({ actionCreators, isLoading }) => {
  const cards = [
    {
      children: (
        <SalesEngagePhone
          actionCreators={actionCreators}
          isSalesEngagePhoneEnabled
          isSalesPhoneConsentAndRecordingEnabled
        />
      ),
      className: 'my-profile-card',
      id: 'callRecordingSettings',
    },
    {
      children: <CallReasonSettingsCard actionCreators={actionCreators} />,
      className: 'my-profile-card',
      id: 'callReasonSettings',
    },
    {
      children: <CallOutcomeSettingsCard actionCreators={actionCreators} />,
      className: 'my-profile-card',
      id: 'callOutcomeSettings',
    },
  ];

  return (
    <div className="tout-ui-styles profile-container">
      {isLoading && <LoadingSpinner imageUrl={getImageUrl} />}
      <Page>
        <DialerSettingsAlert />
        <PageHeader textId="web.settings.adminSettings.dialerSettings.dialer" />
        <PageView>
          <Cards
            backgroundColor={BackgroundColors.grayExtraLight}
            cards={cards}
            marginAround={FULL}
            marginBetween={FULL}
            minWidth={400}
          />
        </PageView>
      </Page>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.callOutcomeOptionsLoading || state.callReasonOptionsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      getActiveRecordingNotice,
      openManageRecordingNotice,
      updateDialerSettings,
      updatePhoneSettings,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialerSettingsContainer);

DialerSettingsContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};
