import { AlertColors, AlertLocations } from 'components/alert';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';
import { CommandCenterPopupIds } from '../libs/commandCenterConstants';

export function getAlert({ alert, popup }, { clearAlert }) {
  const defaultAlert = {
    color: AlertColors.red,
    onClose: clearAlert,
    outsideClickDisabled:
      popup === CommandCenterPopupIds.advancedSearchSave ||
      popup === CommandCenterPopupIds.advancedSearch,
  };

  switch (alert) {
    case PeopleAlertIds.genericApiError:
      return {
        ...defaultAlert,
        location: AlertLocations.top,
        textId: 'web.campaigns.genericApiError',
      };
    case EmailAlertIds.deleteSavedSearchError:
      return {
        ...defaultAlert,
        textId: 'web.emails.deleteSavedSearchError',
      };
    case EmailAlertIds.renameSavedSearchError:
      return {
        ...defaultAlert,
        textId: 'web.emails.renameSavedSearchError',
      };
    case EmailAlertIds.savedSearchNameEmpty:
      return {
        ...defaultAlert,
        textId: 'web.emails.savedSearchNameEmpty',
      };
    case EmailAlertIds.savedSearchNameExists:
      return {
        ...defaultAlert,
        textId: 'web.emails.savedSearchNameExists',
      };
    case EmailAlertIds.missingSubStatusesError:
      return {
        ...defaultAlert,
        textId: 'web.emails.missingSubStatusesError',
      };
    case EmailAlertIds.cloneSavedSearchError:
      return {
        ...defaultAlert,
        textId: 'web.emails.cloneSavedSearchError',
      };
    case EmailAlertIds.updateSavedSearchError:
      return {
        ...defaultAlert,
        textId: 'web.emails.updateSavedSearchError',
      };
    default:
      return null;
  }
}
