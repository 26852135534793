import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Form, Field, reduxForm } from 'redux-form';
import Action, { ButtonColors } from 'components/buttons/action';
import { domainName as domainNameValidatorFactory } from 'web/form/helpers/validators';
import Text from 'web/form/components/fields/textWithErrors';
import './customDomainForm.scss';

class CustomDomainForm extends Component {
  constructor(props) {
    super(props);
    this.domainNameValidator = domainNameValidatorFactory(
      'web.settings.adminSettings.tracking.customDomainTracking.form.customDomain.domainNameInvalid'
    );
  }

  render() {
    const {
      intl: { formatMessage },
      pristine,
      submitting,
      invalid,
      handleSubmit,
      customDomain,
      openDeleteCustomDomainConfirmation,
    } = this.props;
    const isSubmitDisabled = pristine || submitting || invalid;

    return (
      <Form
        onSubmit={handleSubmit}
        noValidate
        className="custom-domain-form margin-0"
      >
        <Field
          className="custom-domain-form-field"
          name="custom_tracking_domain"
          validate={[this.domainNameValidator]}
          placeholder={formatMessage({
            id:
              'web.settings.adminSettings.tracking.customDomainTracking.form.customDomain.domain.placeholder',
          })}
          component={Text}
        />
        <div className="margin-top-20 right">
          {customDomain && (
            <Action
              color={ButtonColors.red}
              onClick={openDeleteCustomDomainConfirmation}
              type="button"
              disabled={submitting}
            >
              <FormattedMessage id="common.disconnect" />
            </Action>
          )}
          {customDomain &&
            !pristine && (
              <Action
                disabled={isSubmitDisabled}
                type="submit"
                className="margin-left-15"
              >
                <FormattedMessage id="common.update" />
              </Action>
            )}
          {!customDomain && (
            <Action disabled={isSubmitDisabled} type="submit">
              <FormattedMessage id="common.connect" />
            </Action>
          )}
        </div>
      </Form>
    );
  }
}

CustomDomainForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openDeleteCustomDomainConfirmation: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  customDomain: PropTypes.string,
};

CustomDomainForm.defaultProps = {
  customDomain: null,
};

export default compose(
  reduxForm({
    enableReinitialize: true,
  }),
  injectIntl
)(CustomDomainForm);
