import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import './campaignTypeCard.scss';

class CampaignTypeCard extends Component {
  componentWillMount() {
    const {
      intl: { formatMessage },
      text,
      textId,
    } = this.props;
    this.title = text || formatMessage({ id: textId });
  }

  render() {
    const { iconClassName } = this.props;

    return (
      <div className="campaign-type-card">
        <div className={iconClassName} />
        <h3 className="text-light text-center margin-top-full">{this.title}</h3>
      </div>
    );
  }
}

CampaignTypeCard.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  text: PropTypes.string,
  textId: PropTypes.string,
};

CampaignTypeCard.defaultProps = {
  text: '',
  textId: '',
};

export default injectIntl(CampaignTypeCard);
