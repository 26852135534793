import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { ProgressStatus } from 'libs/constantsShared';
import ProgressBar from 'components/progressBar';

import './imageUpload.scss';

const ImageUpload = ({
  file,
  files,
  intl: { formatMessage },
  isTipVisible,
}) => (
  <div className="imageUpload">
    {files.length ? (
      files.map((attachment) => (
        <div className="file-attachment-wrapper" key={attachment.name}>
          <span className="text-overflow name">{attachment.name}</span>
          <ProgressBar
            animate={attachment.status === ProgressStatus.uploading}
            height={20}
            progress={attachment.progress}
          />
        </div>
      ))
    ) : (
      <React.Fragment>
        <div className="image-upload-title margin-bottom-full text-overflow">
          {file.name || formatMessage({ id: 'common.noName' })}
        </div>
        <ProgressBar
          animate={file.status === ProgressStatus.uploading}
          height={20}
          progress={file.progress}
        />
      </React.Fragment>
    )}
    {isTipVisible && (
      <div className="margin-top-half text-center">
        {formatMessage({ id: 'imageUpload.tip' })}
      </div>
    )}
  </div>
);

ImageUpload.propTypes = {
  file: PropTypes.object,
  files: PropTypes.array,
  intl: intlShape.isRequired,
  isTipVisible: PropTypes.bool,
};

ImageUpload.defaultProps = {
  file: {},
  files: [],
  isTipVisible: true,
};

export default injectIntl(ImageUpload);
