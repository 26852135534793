import { basePost } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const getLoginSso = (email) =>
  new Promise((resolve, reject) => {
    const params = {
      commit: 'Login',
      domain: email,
    };

    basePost(Urls.loginSsoInit, params, resolve, reject);
  });
