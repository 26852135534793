import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Action from 'components/buttons/action';
import { BackgroundColors } from 'libs/constantsStyles';
import LabeledInputReduxForm from 'components/labeledInputReduxForm';
import { validate } from '../../helpers/loginValidation';
import OneClickButtons from '../oneClickButtons';
import FormLayout from 'components/layouts/formLayout';
import FormTitle from 'components/layouts/formLayout/formTitle';
import FormError from 'components/layouts/formLayout/formError';
import FormButtonsArea from 'components/layouts/formLayout/formButtonsArea';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl, Urls } from 'web/libs/constants';
import { navigateToUrl } from 'web/services/routerService';
import './login.scss';

const Login = ({
  account,
  handleSubmit,
  onSubmit,
  error,
  loading,
  isOwaMode,
  isGmailMode,
  isSFSandboxAuthenticationEnabled,
}) => {
  const onForgotPasswordLinkClick = (e) => {
    if (account.id) {
      e.preventDefault();
      e.stopPropagation();
      // if logged in, instead of a forgot password flow go to the profile page
      navigateToUrl(Urls.topNavbar.myProfile);
    }
  };

  return (
    <div className="login-form">
      {loading && <LoadingSpinner imageUrl={getImageUrl} />}
      <FormLayout className="login-form-wrapper">
        <FormTitle id="login.form.title" />
        <Form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <Field
              autoComplete="username"
              className="form-cell"
              component={LabeledInputReduxForm}
              id="username"
              label="common.email"
              name="username"
              type="text"
              autoFocus
            />
          </div>
          <div className="form-row">
            <Field
              autoComplete="current-password"
              autoFocus={false}
              className="form-cell"
              component={LabeledInputReduxForm}
              id="password"
              label="common.password"
              name="password"
              type="password"
            />
          </div>
          <FormError>{error && <FormattedMessage id={error} />}</FormError>

          <FormButtonsArea align="spaceBetween">
            <Link
              className="login-form-forgot-link"
              onClick={onForgotPasswordLinkClick}
              to={Urls.passwordResetNew}
            >
              <FormattedMessage id="login.form.forgotPasswordLink" />
            </Link>
            <Action
              className="medium login-form-login-button"
              color={BackgroundColors.blue}
              type="submit"
            >
              <FormattedMessage id="common.login" />
            </Action>
          </FormButtonsArea>
        </Form>
      </FormLayout>
      <OneClickButtons
        isGmailMode={isGmailMode}
        isOwaMode={isOwaMode}
        isSFSandboxAuthenticationEnabled={isSFSandboxAuthenticationEnabled}
      />
    </div>
  );
};

Login.propTypes = {
  account: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isGmailMode: PropTypes.bool,
  isOwaMode: PropTypes.bool,
  isSFSandboxAuthenticationEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

Login.defaultProps = {
  error: '',
  isGmailMode: false,
  isOwaMode: false,
  isSFSandboxAuthenticationEnabled: false,
  loading: false,
};

export default reduxForm({
  form: 'login',
  touchOnBlur: false,
  touchOnChange: false,
  validate,
})(Login);
