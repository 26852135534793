/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import './pageView.scss';

class PageView extends Component {
  render() {
    const { backgroundColor, children, className } = this.props;
    const containerClassName = classNames('page-view', className, {
      [`background-${backgroundColor}`]: backgroundColor,
    });

    return (
      <div className="page-view__wrapper">
        <div className={containerClassName}>{children}</div>
      </div>
    );
  }
}

export default PageView;

PageView.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PageView.defaultProps = {
  backgroundColor: BackgroundColors.grayExtraLight,
  className: '',
};
