import SharingSettingsActionTypes from '../libs/sharingSettingsActionTypes';
import {
  TabValues,
  SharingSettingsAlertIds,
} from '../libs/sharingSettingsConstants';
import {
  getCategoryPermissionsCampaigns,
  getCategoryPermissionsTemplates,
} from 'web/categories/services/permissionsCategoriesService';
import { setTableFetching } from './sharingSettingsTableActionCreators';
import { setSharingSettingsAlert } from './sharingSettingsActionCreators';

export const setSidebarLoading = (loading) => ({
  type: SharingSettingsActionTypes.setSidebarLoading,
  loading,
});

export const getTeamsByCategory = (categoryId) => (dispatch, getState) => {
  dispatch(setTableFetching(true));

  const get =
    (getState().settingsSharingSelectedTabValue === TabValues.templates &&
      getCategoryPermissionsTemplates) ||
    getCategoryPermissionsCampaigns;
  get(categoryId)
    .then((teams = []) => {
      dispatch({
        type: SharingSettingsActionTypes.teams,
        teams,
      });
      dispatch(setTableFetching(false));
    })
    .catch(() => {
      dispatch(setTableFetching(false));
      dispatch(setSharingSettingsAlert(SharingSettingsAlertIds.getTeamsIssue));
    });
};

export const onCategorySelect = ({ id }, index) => (dispatch) => {
  dispatch({
    type: SharingSettingsActionTypes.setSelectedCategory,
    index,
  });
  dispatch(getTeamsByCategory(id));
};
