import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/inputs/text';
import ComposeTemplatesCategorySelector from 'web/composeWindow/components/composeTemplatesCategorySelector/composeTemplatesCategorySelector';
import './composeTemplatesSearch.scss';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import {
  DEFAULT_TEMPLATE_CATEGORIES as DefaultCategories,
  RecentlyUsedCategory,
} from '../../libs/composeTemplatesConstants';

const ComposeTemplatesSearch = ({
  templateCategories,
  handleSearchTerm,
  searchTerm,
  selectedCategory,
  handleSelectCategory,
  intl: { formatMessage },
  hasRecentlyUsedTemplates,
}) => {
  const categories = [...DefaultCategories, ...templateCategories];
  if (hasRecentlyUsedTemplates) {
    categories.push(RecentlyUsedCategory);
  }

  return (
    <div className="compose-templates-search">
      <Text
        onChange={handleSearchTerm}
        placeholder={formatMessage({
          id: 'web.composeWindow.templates.search',
        })}
        value={searchTerm}
      />
      <FormattedMessage id="common.within" />
      <ComposeTemplatesCategorySelector
        key={selectedCategory.id}
        onSelect={handleSelectCategory}
        selectedCategory={selectedCategory}
        templateCategories={categories}
      />
    </div>
  );
};

ComposeTemplatesSearch.propTypes = {
  handleSearchTerm: PropTypes.func.isRequired,
  handleSelectCategory: PropTypes.func.isRequired,
  hasRecentlyUsedTemplates: PropTypes.bool,
  intl: intlShape.isRequired,
  searchTerm: PropTypes.string.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  templateCategories: PropTypes.array.isRequired,
};

ComposeTemplatesSearch.defaultProps = {
  hasRecentlyUsedTemplates: false,
};

export default injectIntl(ComposeTemplatesSearch);
