import ActionTypes from 'web/productTour/lib/productTourActionTypes';

const productTourLoadedImages = (state = [], { type, source = [] }) => {
  switch (type) {
    case ActionTypes.loadedImageSource:
      return [...state, ...source];
    default:
      return state;
  }
};

export { productTourLoadedImages };
