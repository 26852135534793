import uniq from 'lodash/uniq';
import { basePost } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function createWorkflowInstances(
  id = '',
  peopleIds = [],
  dontStart = true,
  identityId,
  previews,
  runAtMoment
) {
  // e.g. add people to campaign
  return new Promise((resolve, reject) => {
    const options = {
      dont_start: dontStart,
      identity_id: identityId,
      trigger_alerts: true,
    };

    if (runAtMoment) {
      options.run_at = runAtMoment.toISOString();
    }

    basePost(
      Urls.campaignInstances.replace(':#id', id),
      { person_ids: uniq(peopleIds), preview_data: previews, options },
      resolve,
      reject
    );
  });
}
