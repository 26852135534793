export default {
  export: {
    loading: 'PEOPLE_EXPORT_LOADING',
  },

  group: {
    clearAddNewGroupForm: 'PEOPLE_CLEAR_ADD_NEW_GROUP_FORM',
    setGroupShareTeamIds: 'SET_GROUP_SHARE_TEAM_IDS',
    setPeopleAddNewGroupDescription: 'PEOPLE_GROUP_SET_NEW_GROUP_DESCRIPTION',
    setPeopleAddNewGroupName: 'PEOPLE_GROUP_SET_NEW_GROUP_NAME',
    setSelectedAddPeopleToGroupGroupId:
      'SET_SELECTED_ADD_PEOPLE_TO_GROUP_GROUP_ID',
    setSelectedAddPeopleToGroupPeople:
      'SET_SELECTED_ADD_PEOPLE_TO_GROUP_PEOPLE',
    setSelectedEmailIdAddPeopleToGroup:
      'SET_SELECTED_EMAIL_ID_ADD_PEOPLE_TO_GROUP',
    setSelectedEmailIdsAddPeopleToGroup:
      'SET_SELECTED_EMAIL_IDS_ADD_PEOPLE_TO_GROUP',
    setShareGroup: 'SET_SHARE_GROUP',
    setShareGroupComment: 'SET_SHARE_GROUP_COMMENT',
    updateGroupName: 'PEOPLE_GROUP_UPDATE_GROUP_NAME',
  },

  import: {
    reset: 'PEOPLE_IMPORT_RESET',
    setAttachmentFileId: 'PEOPLE_SET_ATTACHMENT_FILE_ID',
    setFromUserManagementPage: 'PEOPLE_SET_FROM_USER_MANAGEMENT_PAGE',
    setImportFieldMap: 'PEOPLE_SET_IMPORT_FIELD_MAP',
    setImportFieldMaps: 'PEOPLE_SET_IMPORT_FIELD_MAPS',
    setLocalFile: 'PEOPLE_SET_LOCAL_FILE',
    setSalesConnectFieldList: 'PEOPLE_SET_SALES_CONNECT_FIELD_LIST',
  },

  people: {
    loading: 'PEOPLE_LOADING',
    set: 'PEOPLE_SET',
    setPeopleForDelete: 'SET_PEOPLE_FOR_DELETE',
    setSelectAllRequestParams: 'SET_PEOPLE_SELECT_REQUEST_PARAMS',
  },

  peopleAuthorization: {
    reset: 'PEOPLE_AUTHORIZATION_CLEAR',
    set: 'PEOPLE_AUTHORIZATION_SET',
  },

  peopleSearchAdd: {
    addGroup: 'PEOPLE_PEOPLE_SEARCH_ADD_ADD_GROUP',
    addPeople: 'PEOPLE_PEOPLE_SEARCH_ADD_ADD_PEOPLE',
    addPerson: 'PEOPLE_PEOPLE_SEARCH_ADD_ADD_PERSON',
    setIdentity: 'PEOPLE_PEOPLE_SEARCH_ADD_SET_IDENTITY',
    updateGroup: 'PEOPLE_PEOPLE_SEARCH_ADD_UPDATE_GROUP',
    updatePerson: 'PEOPLE_PEOPLE_SEARCH_ADD_UPDATE_PERSON',
  },

  peopleTabSettings: {
    setSlideOutMenuOpen: 'PEOPLE_SET_SLIDE_OUT_MENU_OPEN',
  },

  search: {
    reset: 'PEOPLE_SEARCH_RESET',
    set: 'PEOPLE_SEARCH_SET',
  },

  sidebar: {
    editingGroup: 'PEOPLE_SIDEBAR_EDITING_GROUP',
    setPeopleCurrentGroup: 'PEOPLE_SIDEBAR_SET_CURRENTGROUP',
  },

  smartFilters: {
    set: 'PEOPLE_SMART_FILTERS_SET',
  },

  table: {
    page: 'PEOPLE_TABLE_PAGE',
    perPage: 'PEOPLE_TABLE_PER_PAGE',
    reset: 'PEOPLE_TABLE_RESET',
    sortInfo: 'PEOPLE_TABLE_SORT_INFO',
  },
};
