import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import Select from 'web/form/components/fields/select';
import { injectIntl, intlShape } from 'react-intl';

class EditTeamsForm extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
    } = props;

    this.selectPlaceholder = formatMessage({
      id: 'common.search',
    });
  }

  render() {
    const {
      onEditTeamsFormSubmit,
      handleSubmit,
      teamsSelectOptionsWithoutSelected,
      intl: { formatMessage },
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(onEditTeamsFormSubmit)} noValidate>
        <Field
          className="margin-top-10"
          name="teams"
          component={Select}
          options={teamsSelectOptionsWithoutSelected}
          placeholder={this.selectPlaceholder}
          multi
          searchable
          clearable
          clearAllText={formatMessage({
            id: 'common.clearAll',
          })}
        />
      </Form>
    );
  }
}

EditTeamsForm.propTypes = {
  onEditTeamsFormSubmit: PropTypes.func.isRequired,
  teamsSelectOptionsWithoutSelected: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(reduxForm()(EditTeamsForm));
