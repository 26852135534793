import ActionTypes from 'web/libs/actionTypes/actionTypes';
import ModalsTaskActionTypes from '../libs/modalsTaskActionTypes';

export function modalsTaskInitStore(state = false, { type = '' }) {
  switch (type) {
    case ModalsTaskActionTypes.initStore:
      return true;
    case ActionTypes.modal.state.close:
      return false;
    default:
      return state;
  }
}

export function modalsTaskWindowMode(state = false, { type = '' }) {
  switch (type) {
    case ModalsTaskActionTypes.setWindowMode:
      return true;
    case ActionTypes.modal.state.close:
      return false;
    default:
      return state;
  }
}

export function modalsTaskSourceOpener(
  state = '',
  { type = '', sourceOpener }
) {
  switch (type) {
    case ModalsTaskActionTypes.setSourceOpener:
      return sourceOpener || state;
    case ActionTypes.modal.state.close:
      return '';
    default:
      return state;
  }
}
