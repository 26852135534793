export default {
  alertIds: {
    noValidateUnsubscribeText: 'NO_VALIDATE_UNSUBSCRIBE_TEXT_ALERT',
    saveUnsubscribeMessagingSuccess: 'SAVE_UNSUBSCRIBE_MESSAGING_SUCCESS_ALERT',
    saveUnsubscribeIntegrationsSuccess:
      'SAVE_UNSUBSCRIBE_INTEGRATIONS_SUCCESS_ALERT',
    saveUnsubscribeFailed: 'SAVE_UNSUBSCRIBE_FAILED_ALERT',
    saveUnsubscribeAutoAppendFailed: 'SAVE_UNSUBSCRIBE_AUTO_APPEND_ALERT',
    fetchingAdminSettingsError: 'FETCHING_ADMIN_SETTINGS_ERROR',
    invalidMarketoStandardPremium:
      'INVALID_MARKETO_CONNECTION_STANDARD_PREMIUM_PLAN',
    invalidMarketoOtherPlans: 'INVALID_MARKETO_CONNECTION_OTHER_PLAN',
  },
  errors: {
    unprocessableEntity: 'Unprocessable Entity',
  },
};
