import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createNewAddress,
  fetchSearchResults,
  wrappedAppendAddress as appendAddress,
  wrappedRemoveAddressByIndex as removeAddressByIndex,
  wrappedSetAddresses as setAddresses,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import {
  getCcAddresses,
  getTemplateBypassUnsubscribe,
  getValidationFailures,
} from 'web/composeWindow/selectors/composeWindowSelectors';
import AddressField from '../addressField/addressField';
import {
  AddressFieldLimits,
  AddressTypes,
} from 'web/composeWindow/libs/composeWindowConstants';

class CcField extends React.Component {
  componentDidMount() {
    const { disabled } = this.props;

    if (!disabled) {
      this.addressField.child.addressInput.focus();
    }
  }

  setRef = (field) => (this.addressField = field);

  render() {
    const {
      appendAddress,
      ccAddresses,
      errors,
      isBypassUnsubscribe,
      setAddresses,
      createNewAddress,
      removeAddressByIndex,
      fetchSearchResults,
      searchResults,
      disabled,
    } = this.props;

    return (
      <AddressField
        addresses={ccAddresses}
        appendAddress={appendAddress}
        createNewAddress={createNewAddress}
        disabled={disabled}
        errors={errors}
        fetchSearchResults={fetchSearchResults}
        fieldKey={AddressTypes.cc}
        isBypassUnsubscribe={isBypassUnsubscribe}
        label="common.ccCapitalized"
        limit={AddressFieldLimits.cc}
        ref={this.setRef}
        removeAddressByIndex={removeAddressByIndex}
        searchResults={searchResults}
        setAddresses={setAddresses}
      />
    );
  }
}

CcField.propTypes = {
  appendAddress: PropTypes.func.isRequired,
  ccAddresses: PropTypes.array,
  createNewAddress: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  fetchSearchResults: PropTypes.func.isRequired,
  isBypassUnsubscribe: PropTypes.bool,
  removeAddressByIndex: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  setAddresses: PropTypes.func.isRequired,
};

CcField.defaultProps = {
  ccAddresses: [],
  isBypassUnsubscribe: false,
};

function mapDispatchToProps(dispatch) {
  return {
    appendAddress: (address) =>
      dispatch(appendAddress({ address, key: AddressTypes.cc })),
    createNewAddress: (address) =>
      dispatch(createNewAddress({ address, key: AddressTypes.cc })),
    fetchSearchResults: (query) =>
      dispatch(fetchSearchResults({ query, key: AddressTypes.cc })),
    removeAddressByIndex: (index) =>
      dispatch(removeAddressByIndex({ index, key: AddressTypes.cc })),
    setAddresses: (addresses) =>
      dispatch(setAddresses({ addresses, key: AddressTypes.cc })),
  };
}

const mapStateToProps = (state) => ({
  ccAddresses: getCcAddresses(state),
  errors: getValidationFailures(state).cc,
  isBypassUnsubscribe: getTemplateBypassUnsubscribe(state),
  searchResults: state.composeAddresses.searchResults.cc,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CcField);
