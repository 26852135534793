import ViewersActionTypes from 'web/sidebar/viewers/libs/viewersActionTypes';

const initViewer = {};
export const sidebarViewer = (
  state = initViewer,
  { type, viewer = initViewer }
) => {
  switch (type) {
    case ViewersActionTypes.sidebarViewer.viewer.set:
      return viewer;
    default:
      return state;
  }
};
