import merge from 'lodash/merge';
import * as personDetails from './personDetailsReducers';
import * as personDetailsEditState from './personDetailsEditStateReducers';
import * as personDetailsNext from './personDetailsNextReducers';

export default merge(
  {},
  personDetails,
  personDetailsEditState,
  personDetailsNext
);
