/*eslint-disable*/
/* istanbul ignore next */
(function() {
  /*
   * Generated by PEG.js 0.7.0.
   *
   * http://pegjs.majda.cz/
   */

  function quote(s) {
    /*
     * ECMA-262, 5th ed., 7.8.4: All characters may appear literally in a
     * string literal except for the closing quote character, backslash,
     * carriage return, line separator, paragraph separator, and line feed.
     * Any character may appear in the form of an escape sequence.
     *
     * For portability, we also escape escape all control and non-ASCII
     * characters. Note that "\0" and "\v" escape sequences are not used
     * because JSHint does not like the first and IE the second.
     */
    return (
      '"' +
      s
        .replace(/\\/g, '\\\\') // backslash
        .replace(/"/g, '\\"') // closing quote character
        .replace(/\x08/g, '\\b') // backspace
        .replace(/\t/g, '\\t') // horizontal tab
        .replace(/\n/g, '\\n') // line feed
        .replace(/\f/g, '\\f') // form feed
        .replace(/\r/g, '\\r') // carriage return
        .replace(/[\x00-\x07\x0B\x0E-\x1F\x80-\uFFFF]/g, escape) +
      '"'
    );
  }

  var result = {
    /*
     * Parses the input with a generated parser. If the parsing is successfull,
     * returns a value explicitly or implicitly specified by the grammar from
     * which the parser was generated (see |PEG.buildParser|). If the parsing is
     * unsuccessful, throws |PEG.parser.SyntaxError| describing the error.
     */
    parse: function(input, startRule) {
      var parseFunctions = {
        wordings: parse_wordings,
        ineq: parse_ineq,
        words: parse_words,
        word: parse_word,
        sentence: parse_sentence,
        letter: parse_letter,
        text: parse_text,
        space: parse_space,
        inequality: parse_inequality,
        match: parse_match,
      };

      if (startRule !== undefined) {
        if (parseFunctions[startRule] === undefined) {
          throw new Error('Invalid rule name: ' + quote(startRule) + '.');
        }
      } else {
        startRule = 'wordings';
      }

      var pos = 0;
      var reportFailures = 0;
      var rightmostFailuresPos = 0;
      var rightmostFailuresExpected = [];

      function padLeft(input, padding, length) {
        var result = input;

        var padLength = length - input.length;
        for (var i = 0; i < padLength; i++) {
          result = padding + result;
        }

        return result;
      }

      function escape(ch) {
        var charCode = ch.charCodeAt(0);
        var escapeChar;
        var length;

        if (charCode <= 0xff) {
          escapeChar = 'x';
          length = 2;
        } else {
          escapeChar = 'u';
          length = 4;
        }

        return (
          '\\' +
          escapeChar +
          padLeft(charCode.toString(16).toUpperCase(), '0', length)
        );
      }

      function matchFailed(failure) {
        if (pos < rightmostFailuresPos) {
          return;
        }

        if (pos > rightmostFailuresPos) {
          rightmostFailuresPos = pos;
          rightmostFailuresExpected = [];
        }

        rightmostFailuresExpected.push(failure);
      }

      function parse_wordings() {
        var result0, result1;
        var pos0, pos1;

        pos0 = pos;
        pos1 = pos;
        result0 = parse_words();
        if (result0 !== null) {
          result1 = parse_ineq();
          if (result1 !== null) {
            result0 = [result0, result1];
          } else {
            result0 = null;
            pos = pos1;
          }
        } else {
          result0 = null;
          pos = pos1;
        }
        if (result0 !== null) {
          result0 = (function(offset, w, i) {
            if (i.length > 0) ret.push(i);
            if (w.length > 0) ret.push(w);
            return ret;
          })(pos0, result0[0], result0[1]);
        }
        if (result0 === null) {
          pos = pos0;
        }
        return result0;
      }

      function parse_ineq() {
        var result0,
          result1,
          result2,
          result3,
          result4,
          result5,
          result6,
          result7;
        var pos0, pos1, pos2;

        pos0 = pos;
        result0 = [];
        pos1 = pos;
        pos2 = pos;
        result1 = parse_word();
        if (result1 !== null) {
          result2 = parse_space();
          result2 = result2 !== null ? result2 : '';
          if (result2 !== null) {
            result3 = parse_inequality();
            if (result3 !== null) {
              result4 = parse_space();
              result4 = result4 !== null ? result4 : '';
              if (result4 !== null) {
                result5 = parse_word();
                if (result5 !== null) {
                  result6 = [];
                  result7 = parse_space();
                  while (result7 !== null) {
                    result6.push(result7);
                    result7 = parse_space();
                  }
                  if (result6 !== null) {
                    result1 = [
                      result1,
                      result2,
                      result3,
                      result4,
                      result5,
                      result6,
                    ];
                  } else {
                    result1 = null;
                    pos = pos2;
                  }
                } else {
                  result1 = null;
                  pos = pos2;
                }
              } else {
                result1 = null;
                pos = pos2;
              }
            } else {
              result1 = null;
              pos = pos2;
            }
          } else {
            result1 = null;
            pos = pos2;
          }
        } else {
          result1 = null;
          pos = pos2;
        }
        if (result1 !== null) {
          result1 = (function(offset, left, sign, right) {
            return { field: left, op: sign, val: right };
          })(pos1, result1[0], result1[2], result1[4]);
        }
        if (result1 === null) {
          pos = pos1;
        }
        while (result1 !== null) {
          result0.push(result1);
          pos1 = pos;
          pos2 = pos;
          result1 = parse_word();
          if (result1 !== null) {
            result2 = parse_space();
            result2 = result2 !== null ? result2 : '';
            if (result2 !== null) {
              result3 = parse_inequality();
              if (result3 !== null) {
                result4 = parse_space();
                result4 = result4 !== null ? result4 : '';
                if (result4 !== null) {
                  result5 = parse_word();
                  if (result5 !== null) {
                    result6 = [];
                    result7 = parse_space();
                    while (result7 !== null) {
                      result6.push(result7);
                      result7 = parse_space();
                    }
                    if (result6 !== null) {
                      result1 = [
                        result1,
                        result2,
                        result3,
                        result4,
                        result5,
                        result6,
                      ];
                    } else {
                      result1 = null;
                      pos = pos2;
                    }
                  } else {
                    result1 = null;
                    pos = pos2;
                  }
                } else {
                  result1 = null;
                  pos = pos2;
                }
              } else {
                result1 = null;
                pos = pos2;
              }
            } else {
              result1 = null;
              pos = pos2;
            }
          } else {
            result1 = null;
            pos = pos2;
          }
          if (result1 !== null) {
            result1 = (function(offset, left, sign, right) {
              return { field: left, op: sign, val: right };
            })(pos1, result1[0], result1[2], result1[4]);
          }
          if (result1 === null) {
            pos = pos1;
          }
        }
        if (result0 !== null) {
          result0 = (function(offset, w) {
            return w;
          })(pos0, result0);
        }
        if (result0 === null) {
          pos = pos0;
        }
        return result0;
      }

      function parse_words() {
        var result0, result1, result2, result3, result4, result5, result6;
        var pos0, pos1, pos2;

        pos0 = pos;
        result0 = [];
        pos1 = pos;
        pos2 = pos;
        result1 = parse_word();
        if (result1 !== null) {
          result2 = parse_space();
          result2 = result2 !== null ? result2 : '';
          if (result2 !== null) {
            result3 = parse_match();
            if (result3 !== null) {
              result4 = parse_space();
              result4 = result4 !== null ? result4 : '';
              if (result4 !== null) {
                result5 = parse_word();
                if (result5 !== null) {
                  result6 = parse_space();
                  result6 = result6 !== null ? result6 : '';
                  if (result6 !== null) {
                    result1 = [
                      result1,
                      result2,
                      result3,
                      result4,
                      result5,
                      result6,
                    ];
                  } else {
                    result1 = null;
                    pos = pos2;
                  }
                } else {
                  result1 = null;
                  pos = pos2;
                }
              } else {
                result1 = null;
                pos = pos2;
              }
            } else {
              result1 = null;
              pos = pos2;
            }
          } else {
            result1 = null;
            pos = pos2;
          }
        } else {
          result1 = null;
          pos = pos2;
        }
        if (result1 !== null) {
          result1 = (function(offset, left, sign, right) {
            return { field: left, op: sign, val: right };
          })(pos1, result1[0], result1[2], result1[4]);
        }
        if (result1 === null) {
          pos = pos1;
        }
        while (result1 !== null) {
          result0.push(result1);
          pos1 = pos;
          pos2 = pos;
          result1 = parse_word();
          if (result1 !== null) {
            result2 = parse_space();
            result2 = result2 !== null ? result2 : '';
            if (result2 !== null) {
              result3 = parse_match();
              if (result3 !== null) {
                result4 = parse_space();
                result4 = result4 !== null ? result4 : '';
                if (result4 !== null) {
                  result5 = parse_word();
                  if (result5 !== null) {
                    result6 = parse_space();
                    result6 = result6 !== null ? result6 : '';
                    if (result6 !== null) {
                      result1 = [
                        result1,
                        result2,
                        result3,
                        result4,
                        result5,
                        result6,
                      ];
                    } else {
                      result1 = null;
                      pos = pos2;
                    }
                  } else {
                    result1 = null;
                    pos = pos2;
                  }
                } else {
                  result1 = null;
                  pos = pos2;
                }
              } else {
                result1 = null;
                pos = pos2;
              }
            } else {
              result1 = null;
              pos = pos2;
            }
          } else {
            result1 = null;
            pos = pos2;
          }
          if (result1 !== null) {
            result1 = (function(offset, left, sign, right) {
              return { field: left, op: sign, val: right };
            })(pos1, result1[0], result1[2], result1[4]);
          }
          if (result1 === null) {
            pos = pos1;
          }
        }
        if (result0 !== null) {
          result0 = (function(offset, w) {
            return w;
          })(pos0, result0);
        }
        if (result0 === null) {
          pos = pos0;
        }
        return result0;
      }

      function parse_word() {
        var result0, result1;
        var pos0;

        result0 = parse_sentence();
        if (result0 === null) {
          pos0 = pos;
          result1 = parse_letter();
          if (result1 !== null) {
            result0 = [];
            while (result1 !== null) {
              result0.push(result1);
              result1 = parse_letter();
            }
          } else {
            result0 = null;
          }
          if (result0 !== null) {
            result0 = (function(offset, l) {
              return l.join('');
            })(pos0, result0);
          }
          if (result0 === null) {
            pos = pos0;
          }
        }
        return result0;
      }

      function parse_sentence() {
        var result0, result1, result2;
        var pos0, pos1;

        pos0 = pos;
        pos1 = pos;
        if (input.charCodeAt(pos) === 34) {
          result0 = '"';
          pos++;
        } else {
          result0 = null;
          if (reportFailures === 0) {
            matchFailed('"\\""');
          }
        }
        if (result0 !== null) {
          result2 = parse_text();
          if (result2 !== null) {
            result1 = [];
            while (result2 !== null) {
              result1.push(result2);
              result2 = parse_text();
            }
          } else {
            result1 = null;
          }
          if (result1 !== null) {
            if (input.charCodeAt(pos) === 34) {
              result2 = '"';
              pos++;
            } else {
              result2 = null;
              if (reportFailures === 0) {
                matchFailed('"\\""');
              }
            }
            if (result2 !== null) {
              result0 = [result0, result1, result2];
            } else {
              result0 = null;
              pos = pos1;
            }
          } else {
            result0 = null;
            pos = pos1;
          }
        } else {
          result0 = null;
          pos = pos1;
        }
        if (result0 !== null) {
          result0 = (function(offset, text) {
            return text.join('');
          })(pos0, result0[1]);
        }
        if (result0 === null) {
          pos = pos0;
        }
        if (result0 === null) {
          pos0 = pos;
          pos1 = pos;
          if (input.charCodeAt(pos) === 39) {
            result0 = "'";
            pos++;
          } else {
            result0 = null;
            if (reportFailures === 0) {
              matchFailed('"\'"');
            }
          }
          if (result0 !== null) {
            result2 = parse_text();
            if (result2 !== null) {
              result1 = [];
              while (result2 !== null) {
                result1.push(result2);
                result2 = parse_text();
              }
            } else {
              result1 = null;
            }
            if (result1 !== null) {
              if (input.charCodeAt(pos) === 39) {
                result2 = "'";
                pos++;
              } else {
                result2 = null;
                if (reportFailures === 0) {
                  matchFailed('"\'"');
                }
              }
              if (result2 !== null) {
                result0 = [result0, result1, result2];
              } else {
                result0 = null;
                pos = pos1;
              }
            } else {
              result0 = null;
              pos = pos1;
            }
          } else {
            result0 = null;
            pos = pos1;
          }
          if (result0 !== null) {
            result0 = (function(offset, text) {
              return text.join('');
            })(pos0, result0[1]);
          }
          if (result0 === null) {
            pos = pos0;
          }
        }
        return result0;
      }

      function parse_letter() {
        var result0;

        if (/^[^\t:<>=]/.test(input.charAt(pos))) {
          result0 = input.charAt(pos);
          pos++;
        } else {
          result0 = null;
          if (reportFailures === 0) {
            matchFailed('[^\\t:<>=]');
          }
        }
        return result0;
      }

      function parse_text() {
        var result0;

        if (/^[^"']/.test(input.charAt(pos))) {
          result0 = input.charAt(pos);
          pos++;
        } else {
          result0 = null;
          if (reportFailures === 0) {
            matchFailed('[^"\']');
          }
        }
        return result0;
      }

      function parse_space() {
        var result0;

        if (input.charCodeAt(pos) === 32) {
          result0 = ' ';
          pos++;
        } else {
          result0 = null;
          if (reportFailures === 0) {
            matchFailed('" "');
          }
        }
        return result0;
      }

      function parse_inequality() {
        var result0;

        if (input.substr(pos, 2) === '>=') {
          result0 = '>=';
          pos += 2;
        } else {
          result0 = null;
          if (reportFailures === 0) {
            matchFailed('">="');
          }
        }
        if (result0 === null) {
          if (input.substr(pos, 2) === '<=') {
            result0 = '<=';
            pos += 2;
          } else {
            result0 = null;
            if (reportFailures === 0) {
              matchFailed('"<="');
            }
          }
          if (result0 === null) {
            if (input.charCodeAt(pos) === 60) {
              result0 = '<';
              pos++;
            } else {
              result0 = null;
              if (reportFailures === 0) {
                matchFailed('"<"');
              }
            }
            if (result0 === null) {
              if (input.charCodeAt(pos) === 62) {
                result0 = '>';
                pos++;
              } else {
                result0 = null;
                if (reportFailures === 0) {
                  matchFailed('">"');
                }
              }
            }
          }
        }
        return result0;
      }

      function parse_match() {
        var result0;

        if (input.substr(pos, 2) === ':~') {
          result0 = ':~';
          pos += 2;
        } else {
          result0 = null;
          if (reportFailures === 0) {
            matchFailed('":~"');
          }
        }
        if (result0 === null) {
          if (input.charCodeAt(pos) === 58) {
            result0 = ':';
            pos++;
          } else {
            result0 = null;
            if (reportFailures === 0) {
              matchFailed('":"');
            }
          }
        }
        return result0;
      }

      function cleanupExpected(expected) {
        expected.sort();

        var lastExpected = null;
        var cleanExpected = [];
        for (var i = 0; i < expected.length; i++) {
          if (expected[i] !== lastExpected) {
            cleanExpected.push(expected[i]);
            lastExpected = expected[i];
          }
        }
        return cleanExpected;
      }

      function computeErrorPosition() {
        /*
         * The first idea was to use |String.split| to break the input up to the
         * error position along newlines and derive the line and column from
         * there. However IE's |split| implementation is so broken that it was
         * enough to prevent it.
         */

        var line = 1;
        var column = 1;
        var seenCR = false;

        for (var i = 0; i < Math.max(pos, rightmostFailuresPos); i++) {
          var ch = input.charAt(i);
          if (ch === '\n') {
            if (!seenCR) {
              line++;
            }
            column = 1;
            seenCR = false;
          } else if (ch === '\r' || ch === '\u2028' || ch === '\u2029') {
            line++;
            column = 1;
            seenCR = true;
          } else {
            column++;
            seenCR = false;
          }
        }

        return { line: line, column: column };
      }

      ret = [];

      var result = parseFunctions[startRule]();

      /*
       * The parser is now in one of the following three states:
       *
       * 1. The parser successfully parsed the whole input.
       *
       *    - |result !== null|
       *    - |pos === input.length|
       *    - |rightmostFailuresExpected| may or may not contain something
       *
       * 2. The parser successfully parsed only a part of the input.
       *
       *    - |result !== null|
       *    - |pos < input.length|
       *    - |rightmostFailuresExpected| may or may not contain something
       *
       * 3. The parser did not successfully parse any part of the input.
       *
       *   - |result === null|
       *   - |pos === 0|
       *   - |rightmostFailuresExpected| contains at least one failure
       *
       * All code following this comment (including called functions) must
       * handle these states.
       */
      if (result === null || pos !== input.length) {
        var offset = Math.max(pos, rightmostFailuresPos);
        var found = offset < input.length ? input.charAt(offset) : null;
        var errorPosition = computeErrorPosition();

        throw new this.SyntaxError(
          cleanupExpected(rightmostFailuresExpected),
          found,
          offset,
          errorPosition.line,
          errorPosition.column
        );
      }

      return result;
    },

    /* Returns the parser source code. */
    toSource: function() {
      return this._source;
    },
  };

  /* Thrown when a parser encounters a syntax error. */

  result.SyntaxError = function(expected, found, offset, line, column) {
    function buildMessage(expected, found) {
      var expectedHumanized, foundHumanized;

      switch (expected.length) {
        case 0:
          expectedHumanized = 'end of input';
          break;
        case 1:
          expectedHumanized = expected[0];
          break;
        default:
          expectedHumanized =
            expected.slice(0, expected.length - 1).join(', ') +
            ' or ' +
            expected[expected.length - 1];
      }

      foundHumanized = found ? quote(found) : 'end of input';

      return (
        'Expected ' + expectedHumanized + ' but ' + foundHumanized + ' found.'
      );
    }

    this.name = 'SyntaxError';
    this.expected = expected;
    this.found = found;
    this.message = buildMessage(expected, found);
    this.offset = offset;
    this.line = line;
    this.column = column;
  };

  result.SyntaxError.prototype = Error.prototype;

  return result;
})();

/*eslint-enable*/
