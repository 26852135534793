export const SalesforceTabs = {
  syncSettings: 'sync-settings',
  connectionsCustomizations: 'connections-customizations',
};

export const SalesforceSyncType = {
  api: 'API',
  bcc: 'BCC',
};

export const SalesforceBccForm = 'SALESFORCE_BCC_FORM';
