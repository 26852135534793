import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import './smartSelectReduxForm.scss';
import SmartSelect from '../smartSelect/smartSelect';
import { ValidationError } from 'web/form/helpers/validators';

class SmartSelectReduxForm extends Component {
  render() {
    const {
      input: { value },
      className,
      classesLabel,
      label,
      sideLabel,
      meta: { touched, error },
      ...other
    } = this.props;
    const fieldError = touched ? error : null;

    const labelClassName = classNames('labeled-input-label', classesLabel, {
      error: fieldError,
      'side-label': sideLabel,
    });

    return (
      <div
        className={classNames(
          'smart-select-redux-form',
          { 'side-label': sideLabel },
          className
        )}
      >
        <label className={labelClassName} htmlFor={other.id}>
          <FormattedMessage id={label} />
        </label>
        <SmartSelect
          inputValue={value}
          onChange={this.onChangeHandler}
          onSelect={this.onSelectHandler}
          {...other}
        />
        {fieldError && (
          <p className="labeled-input-error-msg">
            <FormattedMessage
              id={
                fieldError instanceof ValidationError
                  ? fieldError.messageId
                  : fieldError
              }
            />
          </p>
        )}
      </div>
    );
  }

  onSelectHandler = (selectedItem) => {
    const {
      input: { onChange },
      onSelect,
    } = this.props;
    onChange(selectedItem.value);

    if (onSelect) {
      onSelect(selectedItem.value);
    }
  };

  onChangeHandler = (inputValue = '') => {
    const {
      input: { onChange },
      onChange: localOnChange,
    } = this.props;
    onChange(inputValue);

    if (localOnChange) {
      localOnChange(inputValue);
    }
  };
}

SmartSelectReduxForm.propTypes = {
  classesLabel: PropTypes.string,
  className: PropTypes.string,
  exceptionSelectors: PropTypes.array, // outsideClickHandler
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  sideLabel: PropTypes.bool,
};

SmartSelectReduxForm.defaultProps = {
  classesLabel: '',
  className: '',
  exceptionSelectors: [],
  sideLabel: false,
};

export default SmartSelectReduxForm;
