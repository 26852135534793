import { LocalStorageKeys } from 'web/libs/constants';
import {
  didFetchWorkspace,
  getFirstWorkspaceId,
  getDefaultWorkspaceId,
} from 'web/marketo/workspaces/helpers/marketoWorkspacesHelpers';
import { onFilterChange } from 'web/modals/addToCampaign/actionCreators/filterActionCreators';
import { setPopupLoading } from 'web/popup/actionCreators/popupActionCreators';
import {
  getMarketoCampaignsForWorkspace,
  setSelectedMarketoCampaign,
} from 'web/modals/addToCampaign/actionCreators/marketoCampaignsActionCreators';
import { getSelectedWorkspace } from 'web/modals/addToCampaign/selectors/addToCampaignSelectors';
import { track } from 'web/services/mixpanelService';
import {
  CampaignActionEvents,
  CampaignProperties,
} from 'web/libs/mixpanelEvents';

export const initMarketoCampaignsForWorkspace = (workspaceName, campaign) => (
  dispatch,
  getState
) => {
  dispatch(setPopupLoading(true));
  return dispatch(
    getMarketoCampaignsForWorkspace(workspaceName, campaign)
  ).then(() => {
    dispatch(setPopupLoading(false));
  });
};

export const selectCurrentWorkspace = (workspaceId, campaign) => (
  dispatch,
  getState
) => {
  dispatch(
    onFilterChange(workspaceId, LocalStorageKeys.campaigns.lastWorkspaceModal)
  );

  track(CampaignActionEvents.addToCampaignAction, {
    'Action Type':
      CampaignProperties.addToCampaignActionTypes.selectMarketoWorkspace,
  });

  const state = getState();
  const { marketoCampaigns } = state;
  const { name: workspaceName } = getSelectedWorkspace(state);

  if (didFetchWorkspace(workspaceName, marketoCampaigns)) {
    dispatch(setSelectedMarketoCampaign(campaign));
    return Promise.resolve();
  } else {
    return dispatch(initMarketoCampaignsForWorkspace(workspaceName, campaign));
  }
};

export const initCurrentWorkspace = () => (dispatch, getState) => {
  const marketoWorkspaces = getState().marketoWorkspaces;
  const lastUsedWorkspace = window.localStorage.getItem(
    LocalStorageKeys.campaigns.lastWorkspaceModal
  );
  const currentWorkspace =
    parseInt(lastUsedWorkspace, 10) ||
    getDefaultWorkspaceId(marketoWorkspaces) ||
    getFirstWorkspaceId(marketoWorkspaces);

  return dispatch(selectCurrentWorkspace(currentWorkspace));
};
