/**
 * Convert property 'base' to '_error' for Redux Form.
 * Redux Form only takes a string for SubmissionError
 * whereas Rails returns errors for a type in an array.
 * We will only handle the first error for each type.
 */
export const convertErrorsForReduxForm = (errors) => {
  const _errors = {};

  Object.keys(errors).forEach((errorType) => {
    const errorMessage = errors[errorType][0];

    if (errorType === 'base') {
      _errors._error = errorMessage;
    } else {
      _errors[errorType] = errorMessage;
    }
  });

  return _errors;
};

export const defaultErrorMessage = {
  _error: 'common.errors.unexpected.body',
};
