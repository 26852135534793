import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl, ModalPopupIds } from 'web/libs/constants';
import { PopupSize } from 'libs/constantsShared';
import { CampaignTemplatesAlertIds } from 'web/modals/campaignTemplates/libs/campaignTemplatesConstants';
import Popup, { PopupBackgroundColors } from 'components/popups';
import UpdateCampaignTemplates from 'web/modals/campaignTemplates/components/updateCampaignTemplates';
import DeleteParentTemplate from 'web/modals/campaignTemplates/components/deleteParentTemplate/deleteParentTemplate';
import { closeModal } from 'web/modals/campaignTemplates/actionCreators/campaignTemplatesPopupActionCreators';
import { clearPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import {
  dontUpdate,
  setSelectedIds,
  updateCampaignTemplates,
  deleteCampaignTemplate,
} from 'web/modals/campaignTemplates/actionCreators/campaignTemplatesActionCreators';

class CampaignTemplatesModalContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      deleteCampaignTemplate: (id) => dispatch(deleteCampaignTemplate(id)),
      dontUpdate: () => dispatch(dontUpdate()),
      clearAlert: () => dispatch(clearPopupAlert()),
      closeModal: () => dispatch(closeModal()),
      setSelectedIds: (checked, ids) => dispatch(setSelectedIds(checked, ids)),
      updateCampaignTemplates: () => dispatch(updateCampaignTemplates()),
    };
  }

  render() {
    const popupObject = this._getGlobalPopupObject();
    const { modalLoading } = this.props;
    return (
      <div>
        {popupObject.children && (
          <Popup
            alert={this._getAlert()}
            getImageUrl={getImageUrl}
            loading={modalLoading}
            {...popupObject}
          />
        )}
      </div>
    );
  }

  _getGlobalPopupObject = () => {
    const {
      modal: { id, data },
    } = this.props;
    switch (id) {
      case ModalPopupIds.updateCampaignTemplates: {
        const { activeCampaignTemplates, selectedIds } = this.props;
        const totalSelected = Object.keys(selectedIds).length;
        return {
          children: (
            <UpdateCampaignTemplates
              actionCreators={this.actionCreators}
              activeCampaignTemplates={activeCampaignTemplates}
              isHeaderSelected={
                totalSelected === activeCampaignTemplates.length
              }
              selectedIds={selectedIds}
            />
          ),
          footer: {
            primaryTextId: 'common.update',
            onPrimary: this.actionCreators.updateCampaignTemplates,
            onSecondary: this.actionCreators.dontUpdate,
            primaryDisabled: !totalSelected,
            secondaryTextId: 'web.updateCampaignTemplates.dontUpdate',
          },
          header: {
            onClose: this.actionCreators.dontUpdate,
            textId: 'web.updateCampaignTemplates.title',
          },
          onOutsideClick: this.actionCreators.dontUpdate,
          size: PopupSize.large,
        };
      }
      case ModalPopupIds.deleteParentTemplate: {
        const { activeCampaignTemplates } = this.props;
        return {
          children: (
            <DeleteParentTemplate
              actionCreators={this.actionCreators}
              activeCampaignTemplates={activeCampaignTemplates}
            />
          ),
          footer: {
            primaryTextId: 'common.delete',
            onPrimary: this.actionCreators.deleteCampaignTemplate.bind(
              this,
              data.id
            ),
            secondaryTextId: 'common.cancel',
            onSecondary: this.actionCreators.closeModal,
          },
          header: {
            onClose: this.actionCreators.closeModal,
            textId: 'common.areYouSure',
          },
          onOutsideClick: this.actionCreators.closeModal,
          size: PopupSize.large,
        };
      }
      default:
        return {};
    }
  };

  _getAlert = () => {
    const { alert } = this.props;
    const defaultAlert = {
      color: PopupBackgroundColors.red,
      onClose: this.actionCreators.clearAlert,
      textId: 'web.updateCampaignTemplates.alert.issue',
    };
    switch (alert) {
      case CampaignTemplatesAlertIds.updateCampaignTemplateIssue:
        return {
          ...defaultAlert,
          textId: 'web.updateCampaignTemplates.alert.issue',
        };
      case CampaignTemplatesAlertIds.deleteParentTemplateIssue:
        return {
          ...defaultAlert,
          textId: 'web.campaignTemplates.alert.deleteParentTemplateIssue',
        };
      default:
        return null;
    }
  };
}

CampaignTemplatesModalContainer.propTypes = {
  activeCampaignTemplates: PropTypes.array.isRequired,
  alert: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  modal: PropTypes.object.isRequired,
  modalLoading: PropTypes.bool.isRequired,
  selectedIds: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    activeCampaignTemplates:
      state.campaignsUsingTemplateState.campaignTemplates,
    alert: state.popupAlert,
    modal: state.modal,
    modalLoading: state.modalLoading,
    selectedIds: state.updateCampaignTemplatesModalState.selectedIds,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(CampaignTemplatesModalContainer);
