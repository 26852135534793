import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import SalesforceCustomizationMain from 'web/modals/salesforceCustomization/components/salesforceCustomizationMain';
import SalesforceCustomizationMainV2 from 'web/salesforce/components/salesforceCustomizationsCard';
import SalesforceCustomizationPopupContainer from 'web/modals/salesforceCustomization/container/salesforceCustomizationPopupContainer';
import {
  unbindSalesforceCustomizationPE,
  bindSalesforceCustomizationPusherEvents as bindSalesforceCustomizationPusherEventsAC,
} from 'web/modals/salesforceCustomization/actionCreators/salesforceCustomizationPusherActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  customizationStatusCall as customizationStatusCallAC,
  setDefaultProcessValues as setDefaultProcessValuesAC,
} from 'web/modals/salesforceCustomization/actionCreators/salesforceCustomizationActionCreators';
import {
  getStatusConditions,
  getErrorMessages,
  getSortedAvailableVersions,
  getSortedInstalledVersions,
} from 'web/modals/salesforceCustomization/selectors/salesforceCustomizationSelectors';
import { isIntegrationConnected as isSalesforceConnected } from 'web/salesforce/selectors/salesforceSelectors';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { CONNECT_URL } from '../libs/salesforceCustomizationConstants';
import './salesforceCustomizationContainer.scss';

class SalesforceCustomizationContainer extends Component {
  componentDidMount() {
    const {
      actionCreators: {
        customizationStatusCall,
        bindSalesforceCustomizationPusherEvents,
      },
    } = this.props;
    customizationStatusCall();
    bindSalesforceCustomizationPusherEvents();
  }

  componentWillUnmount() {
    const {
      actionCreators: { setDefaultProcessValues },
    } = this.props;
    setDefaultProcessValues();
    unbindSalesforceCustomizationPE();
  }

  componentDidUpdate(prevProps = {}) {
    const { errors } = this.props;

    if (prevProps.errors.hasErrors !== errors.hasErrors) {
      toutBackboneHelper.viewStatus({ complete: true });
    }
  }

  render() {
    // TODO: temporary solution to keep working both old and new SF settings page.
    //  Remove when only new one remains.
    const { version } = this.props;
    const SalesforceCustomizationCard =
      version === 1
        ? SalesforceCustomizationMain
        : SalesforceCustomizationMainV2;
    return (
      <div className="salesforce-customization-container">
        <SalesforceCustomizationCard {...this.props} />
        <SalesforceCustomizationPopupContainer />
      </div>
    );
  }
}

SalesforceCustomizationContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  connectUrl: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  hasValidCredentials: PropTypes.bool.isRequired,
  isCustomizationInstalled: PropTypes.bool.isRequired,
  isCustomizationPackageInstalled: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isRunningCustomizationInstall: PropTypes.bool.isRequired,
  isRunningCustomizationUninstall: PropTypes.bool.isRequired,
  isSalesforceNotConnected: PropTypes.bool.isRequired,
  status: PropTypes.string,
  version: PropTypes.number,
};

SalesforceCustomizationContainer.defaultProps = {
  status: null,
  version: 1,
};

function mapStateToProps(state) {
  const errors = getErrorMessages(state);
  const status = getStatusConditions(state);
  const hasValidCredentials = isSalesforceConnected(state);
  const availableVersions = getSortedAvailableVersions(state);
  const installedVersions = getSortedInstalledVersions(state);

  return {
    availableVersions,
    connectUrl: CONNECT_URL,
    errors,
    hasValidCredentials,
    isCustomizationInstalled: status.isCustomizationInstalled,
    isCustomizationPackageInstalled: status.isCustomizationPackageInstalled,
    isCustomizationStatusNeed: status.isCustomizationStatusNeed,
    isRunningCustomizationInstall: status.isRunningCustomizationInstall,
    isRunningCustomizationUninstall: status.isRunningCustomizationUninstall,
    isProcessing: status.isProcessing,
    isSalesforceNotConnected: status.isSalesforceNotConnected,
    installedVersions,
    status: state.salesforceCustomization.status,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      openPopup,
      customizationStatusCall: customizationStatusCallAC,
      setDefaultProcessValues: setDefaultProcessValuesAC,
      bindSalesforceCustomizationPusherEvents: bindSalesforceCustomizationPusherEventsAC,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesforceCustomizationContainer);
