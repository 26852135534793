import React from 'react';

const BounceIcon = () => {
  return (
    <svg viewBox="0 0 12.81 11.14" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Layer 2" id="Layer_2">
        <g data-name="Layer 1" id="Layer_1-2">
          <path d="M1.67,2,5.61,6.41a.13.13,0,0,1,0,.16L4.94,7.91a.14.14,0,0,1-.24,0L1.51,2.17C1.44,2,1.54,1.94,1.67,2Z" />
          <path d="M6.53,11.14A12.34,12.34,0,0,1,.58,9.84,1.33,1.33,0,0,1,0,8.79,1.38,1.38,0,0,1,.51,7.65a3.23,3.23,0,0,1,1.91-.89.42.42,0,0,1,.45.4.43.43,0,0,1-.4.45A2.45,2.45,0,0,0,1,8.32a.53.53,0,0,0-.19.43A.47.47,0,0,0,1,9.12a11.6,11.6,0,0,0,5.5,1.17c2.51,0,4.21-.37,5-.95A.7.7,0,0,0,12,8.78c0-.2-.17-.42-.46-.61l-.1-.06a2,2,0,0,0-1.06-.4A.42.42,0,0,1,10,7.22a.43.43,0,0,1,.49-.35,2.85,2.85,0,0,1,1.37.52l.11.07a1.61,1.61,0,0,1,.85,1.3A1.53,1.53,0,0,1,12.07,10C11.07,10.74,9.31,11.09,6.53,11.14Z" />
          <path d="M12.37,4.21l-1.55-4A.25.25,0,0,0,10.45,0L6,2.82a.25.25,0,0,0,.08.46l1.61.34L5.21,8.29a.18.18,0,0,0,.16.26l2,0a.17.17,0,0,0,.14-.07l3.19-4.18,1.36.29A.26.26,0,0,0,12.37,4.21Z" />
        </g>
      </g>
    </svg>
  );
};

export default BounceIcon;
