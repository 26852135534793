import { baseGet, basePost, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const getEmailsByPersonId = (personId) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.emails, { person_id: personId }, resolve, reject);
  });

export const archiveEmail = (emailId) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.emailArchive.replace(':#id', emailId), {}, resolve, reject);
  });

export const unarchiveEmail = (emailId) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.emailUnarchive.replace(':#id', emailId), {}, resolve, reject);
  });

export const markSuccessEmail = (emailId) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.emailMarkSuccess.replace(':#id', emailId),
      {},
      resolve,
      reject
    );
  });

export const retrySendEmail = (emailId) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.emailRetrySend(emailId), {}, resolve, reject);
  });

export const emailsBulk = (params = {}) =>
  new Promise((resolve, reject) => {
    basePost(Urls.emailsBulk, params, resolve, reject);
  });

export const removeEmail = (emailId) =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.emailsId(emailId), {}, resolve, reject);
  });
