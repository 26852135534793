import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { navigateToEmailSettingsTab } from 'web/services/routerService';
import { EmailSettingsTabs } from 'web/settings/email/libs/emailSettingsConstants';
import EditIdentitySlideOut from '../components/editIdentitySlideOut';
import EditIdentitySlideOutPopupContainer from './editIdentitySlideOutPopupContainer';
import { injectIntl, intlShape } from 'react-intl';

class EditIdentitySlideOutContainer extends Component {
  render() {
    return (
      <EditIdentitySlideOutPopupContainer>
        <EditIdentitySlideOut onBack={this.onBack} {...this.props} />
      </EditIdentitySlideOutPopupContainer>
    );
  }

  onBack = () => {
    navigateToEmailSettingsTab(EmailSettingsTabs.identities);
  };
}

EditIdentitySlideOutContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isOpen: state.editIdentitySlideOutIsOpen,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(EditIdentitySlideOutContainer);
