import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import './card.scss';

class Card extends PureComponent {
  render() {
    const {
      backgroundColor,
      children,
      className,
      fillParent,
      onClick,
      disabled,
      shadow,
    } = this.props;
    const containerClassName = classNames('card', className, {
      [`background-${backgroundColor}`]: backgroundColor,
      [`material-shadow-${shadow}`]: shadow,
      'clickable-card': !disabled && onClick,
      disabled,
      'fill-parent': fillParent,
    });

    return (
      <div
        className={containerClassName}
        onClick={!disabled && onClick}
        style={this._getStyles()}
      >
        {children}
      </div>
    );
  }

  _getStyles = () => {
    const { disabled, height, style, width } = this.props;
    const styles = { ...style };

    if (height) {
      styles.height = `${height}px`;
      styles.overflow = 'auto';
    }
    if (width) {
      styles.width = `${width}px`;
    }
    if (disabled) {
      styles.cursor = 'not-allowed';
    }
    return styles;
  };
}

Card.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fillParent: PropTypes.bool, // requires parent to be display: flex and flex-direction: column
  height: PropTypes.number,
  onClick: PropTypes.func,
  shadow: PropTypes.number,
  style: PropTypes.object,
  width: PropTypes.number,
};

Card.defaultProps = {
  backgroundColor: BackgroundColors.white,
  className: '',
  disabled: false,
  fillParent: false,
  height: 0,
  onClick: null,
  shadow: 1,
  style: {},
  width: 0,
};

export default Card;
