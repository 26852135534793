import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import LabeledInput from 'components/labeledInput';
import Button from 'components/buttons/action';
import './campaignEditReminder.scss';

class CampaignEditReminder extends Component {
  constructor(props) {
    super(props);

    this.id = `edit-reminder-${Date.now()}`;

    this.state = {
      validAdd: false,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { action, createState, day, step } = this.props;
    createState(this.id, action, step || { day });
  }

  componentWillUnmount() {
    const { day, destroyState, step } = this.props;
    destroyState(this.id, step || { day });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      this.props.campaignEditStates[this.id] !==
      nextProps.campaignEditStates[this.id]
    ) {
      // could also just call _isValid() every time
      this._isValid(nextProps);
    }
  }

  render() {
    const {
      campaignEditStates,
      editingStep,
      intl: { formatMessage },
      previewMode,
    } = this.props;
    const { validAdd } = this.state;
    const state = campaignEditStates[this.id] || {};

    return (
      <div className="campaignEditReminder">
        <div className="subject-div">
          <LabeledInput
            disabled={previewMode}
            id="subject-input"
            messageKey="common.subject"
            onChange={this.onSubjectChange}
            placeholder={formatMessage({
              id: 'web.campaignsTab.campaignEditReminder.subjectPlaceholder',
            })}
            type="text"
            value={state.name}
            autoFocus
            sideLabel
            toutUiStyle
          />
        </div>
        <div className="notes-div margin-bottom-full">
          <LabeledInput
            autoFocus={false}
            classesInput="notes-height"
            classesLabel="notes-height"
            disabled={previewMode}
            id="notes-input"
            messageKey="common.notes"
            onChange={this.onNotesChange}
            placeholder={formatMessage({
              id: 'web.campaignsTab.campaignEditReminder.notesPlaceholder',
            })}
            type="textarea"
            value={state.notes}
            sideLabel
            toutUiStyle
          />
        </div>
        <div className="footer">
          <Button color="gray" disabled={previewMode} onClick={this._onCancel}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            classes="margin-left-full"
            color="blue"
            disabled={previewMode || !validAdd}
            onClick={this._onAction}
          >
            <FormattedMessage
              id={editingStep ? 'common.update' : 'common.add'}
            />
          </Button>
        </div>
      </div>
    );
  }

  _isValid = (nextProps) => {
    const state = nextProps.campaignEditStates[this.id];
    let validEditing = true;
    if (nextProps.editingStep) {
      validEditing =
        state.name !== nextProps.editingStep.name ||
        state.notes !== state.notesOriginal ||
        state.completeBeforeMoving !== nextProps.editingStep.blocking;
    }

    this.setState({
      validAdd: state.name.length && validEditing,
    });
  };

  onSubjectChange = (e) => {
    this.props.updateCampaignEditState(this.id, {
      name: e.currentTarget.value,
    });
  };

  onNotesChange = (e) => {
    this.props.updateCampaignEditState(this.id, {
      notes: e.currentTarget.value,
    });
  };

  onCheckboxChange = (checked, id) => {
    this.props.updateCampaignEditState(this.id, { [id]: checked });
  };

  _onCancel = () => {
    const { step, onCancel } = this.props;
    onCancel(this.id, step);
  };

  _onAction = () => {
    const { step, onAction } = this.props;
    onAction(this.id, step);
  };
}

CampaignEditReminder.propTypes = {
  action: PropTypes.string.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  createState: PropTypes.func.isRequired,
  day: PropTypes.number,
  destroyState: PropTypes.func.isRequired,
  editingStep: PropTypes.object,
  intl: intlShape.isRequired,
  onAction: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  previewMode: PropTypes.bool,
  step: PropTypes.object,
  updateCampaignEditState: PropTypes.func.isRequired,
  updateEditsOpen: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
};

CampaignEditReminder.defaultProps = {
  day: undefined,
  editingStep: undefined,
  previewMode: undefined,
  step: undefined,
  updateEditsOpen: undefined,
};

export default injectIntl(CampaignEditReminder);
