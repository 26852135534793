import { TasksTableDatesFilterValues } from 'web/tables/tasks/libs/tablesTasksConstants';
import { CommandCenterTasksTableId } from '../libs/commandCenterConstants';
import { initPusher } from 'web/tasks/actionCreators/tasksPusherActionCreators';
import { getTasksUncompleted } from 'web/tasks/actionCreators/tasksUncompletedActionCreators';
import { getFilterDatesRequired } from 'web/tables/helpers/itemsTableFilters';
import { setDatesShown } from 'web/tables/tasks/actionCreators/tablesTasksStateActionCreators';

export function getInitTasks() {
  return (dispatch, getState) => {
    const {
      sidebarViewer: { id: userId },
    } = getState();
    const dates = getFilterDatesRequired(
      TasksTableDatesFilterValues.todayOverdue
    );
    dispatch(initPusher());
    dispatch(setDatesShown(CommandCenterTasksTableId, dates));
    dispatch(getTasksUncompleted(dates, false, userId));
  };
}
