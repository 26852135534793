import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TrackingPage from './trackingPage';
import TrackingPopupContainer from '../containers/trackingPopupContainer';
import TrackingAlertsContainer from '../containers/trackingAlertContainer';
import './tracking.scss';

const Tracking = (props) => (
  <Fragment>
    <TrackingPage {...props} />
    <TrackingAlertsContainer />
    <TrackingPopupContainer />
  </Fragment>
);

Tracking.propTypes = {
  tab: PropTypes.string,
  onTabSelect: PropTypes.func.isRequired,
};

Tracking.defaultProps = {
  tab: null,
};

export default Tracking;
