import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from 'components/layouts/page';
import Cards, { Direction } from 'components/layouts/cards';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import PageView from 'components/layouts/page/view';
import SalesforceConnectionCard from 'web/salesforce/components/salesforceConnectionCard';
import SeCustomizationsCard from 'web/settings/accountSettings/salesforce/components/seCustomizationsCard/seCustomizationsCard';
import { isOwaPluginMode } from 'web/services/windowService';
import './connectionsCustomizations.scss';

import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';

class ConnectionsCustomizations extends Component {
  constructor(props) {
    super(props);

    const {
      actionCreators: { getSalesforceIntegration },
    } = props;
    getSalesforceIntegration();
  }

  cards = [SalesforceConnectionCard, SeCustomizationsCard];

  getCards = () => {
    const {
      actionCreators: {
        openConnectConfirmationPopup: onConnectSalesforce,
        openDisconnectConfirmationPopup: onDisconnectSalesforce,
      },
      ...other
    } = this.props;

    if (isOwaPluginMode()) {
      this.cards.splice(this.cards.length - 1, 1);
    }

    return this.cards.map((cardComponent, index) => ({
      children: React.createElement(cardComponent, {
        ...other,
        onConnectSalesforce,
        onDisconnectSalesforce,
      }),
      id: index,
    }));
  };

  render() {
    const { fetching, loading } = this.props;
    return fetching ? (
      <LoadingSpinner imageUrl={getImageUrl} key="loading-spinner" />
    ) : (
      [
        <Page className="salesforce-connections-customizations" key="page-view">
          <PageView className="salesforce-connections-customizations-page-view">
            <Cards
              backgroundColor={BackgroundColors.grayExtraLight}
              cards={this.getCards()}
              className="salesforce-connections-customizations-cards-container"
              direction={Direction.vertical}
              marginAround={FULL}
              marginBetween={FULL}
            />
          </PageView>
        </Page>,
        loading && (
          <LoadingSpinner imageUrl={getImageUrl} key="loading-spinner" />
        ),
      ]
    );
  }
}

ConnectionsCustomizations.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConnectionsCustomizations;
