import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { CampaignTypes } from 'libs/constantsShared';
import PeopleSimpleGrid from 'components/peopleSimpleGrid';
import PeopleRow from 'components/peopleSimpleGrid/peopleList/peopleRow';
import FailedContactImportItem from 'components/failedContactImportItem';
import PreviewTaskMessage from '../previewTaskMessage';
import PreviewStatus from './previewStatus';
import './previewTask.scss';

class PreviewTask extends Component {
  render() {
    const { campaign, campaignType, recipients, failedImports } = this.props;

    const people = recipients.ids.map((id) => recipients.map[id]);

    return (
      <div className="previewTask">
        <PreviewTaskMessage campaign={campaign} campaignType={campaignType} />
        <PeopleSimpleGrid
          people={people}
          status={PreviewStatus}
          additionalRow={this._getAdditionalRow(failedImports)}
        />
      </div>
    );
  }

  _getAdditionalRow(failedImports) {
    if (isEmpty(failedImports)) {
      return null;
    }

    return (
      <PeopleRow
        personCell={
          <FailedContactImportItem
            key="importFailures"
            className="people-list-person-item text-medium"
            failures={failedImports}
            simple
          />
        }
        statusCell={
          <FormattedMessage id="campaigns.previewTask.peopleFailedToImportFromSalesforce" />
        }
        isInvalid
      />
    );
  }
}

PreviewTask.propTypes = {
  campaign: PropTypes.object,
  campaignType: PropTypes.string,
  recipients: PropTypes.object.isRequired,
  failedImports: PropTypes.object,
};

PreviewTask.defaultProps = {
  campaign: {},
  campaignType: CampaignTypes.tout,
};

export default PreviewTask;
