import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function addToCampaignModalLoading(state = true, action) {
  switch (action.type) {
    case ActionTypes.api.campaigns.failure:
    case ActionTypes.api.categories.failure:
    case ActionTypes.addToCampaignModal.state.reset:
    case ActionTypes.modal.state.setComponent:
      return false;
    default:
      return state;
  }
}

export const initSelectedCampaign = {};
export const addToCampaignModalSelectedCampaign = (
  state = initSelectedCampaign,
  { type, campaign = initSelectedCampaign }
) => {
  switch (type) {
    case ActionTypes.addToCampaignModal.state.campaign:
      return campaign;
    case ActionTypes.modal.state.close:
      return initSelectedCampaign;
    default:
      return state;
  }
};

export function addToCampaignModalCategory(state = -1, action) {
  switch (action.type) {
    case ActionTypes.addToCampaignModal.state.category:
      return action.data;
    case ActionTypes.modal.state.close:
      return -1;
    default:
      return state;
  }
}

export function addToCampaignModalWindowMode(state = false, action) {
  switch (action.type) {
    case ActionTypes.addToCampaignModal.state.windowMode:
      return action.data;
    case ActionTypes.addToCampaignModal.state.reset:
      return false;
    default:
      return state;
  }
}

export function addToCampaignModalIframeMode(state = false, action) {
  switch (action.type) {
    case ActionTypes.addToCampaignModal.state.iframeMode:
      return action.data;
    case ActionTypes.addToCampaignModal.state.reset:
      return false;
    default:
      return state;
  }
}

export function addToCampaignModalSalesforcePeopleCall(state = false, action) {
  switch (action.type) {
    case ActionTypes.addToCampaignModal.state.salesforcePeopleDataRequest:
      return true;
    case ActionTypes.addToCampaignModal.state.salesforcePeopleDataSuccess:
    case ActionTypes.addToCampaignModal.state.salesforcePeopleDataFailure:
    case ActionTypes.addToCampaignModal.state.processingClear:
    case ActionTypes.addToCampaignModal.state.reset:
      return false;
    default:
      return state;
  }
}

export function addToCampaignModalCampaignsError(state = false, action) {
  switch (action.type) {
    case ActionTypes.api.campaigns.failure:
    case ActionTypes.api.categories.failure:
      return true;
    case ActionTypes.addToCampaignModal.state.reset:
      return false;
    default:
      return state;
  }
}

export function addToCampaignModalDataLossTextId(state = '', action) {
  switch (action.type) {
    case ActionTypes.addToCampaignModal.state.warnLosingData:
      return action.textId;
    case ActionTypes.modal.state.close:
      return '';
    default:
      return state;
  }
}

export function addToCampaignSalesforceProgress(state = 0, action) {
  switch (action.type) {
    case ActionTypes.addToCampaignModal.state.salesforceImportProgress:
      return action.percentComplete;
    case ActionTypes.addToCampaignModal.state.reset:
      return 0;
    default:
      return state;
  }
}

export const importFailures = {};
export function addToCampaignSalesforceImportFailures(
  state = importFailures,
  action
) {
  switch (action.type) {
    case ActionTypes.addToCampaignModal.state.salesforceImportFailures:
      return action.data;
    default:
      return state;
  }
}

export const initSourceOpener = '';
export const addToCampaignSourceOpener = (
  state = initSourceOpener,
  { type, sourceOpener = initSourceOpener }
) => {
  switch (type) {
    case ActionTypes.addToCampaignModal.state.sourceOpener:
      return sourceOpener;
    default:
      return state;
  }
};

// Preselected IDs are saved to allow us to retry a Salesforce call if it fails
export const initPreselectedIds = {
  count: 0,
  group: [],
  people: [],
  salesforce: [],
  email: [],
};
export const addToCampaignModalPreselectedIds = (
  state = initPreselectedIds,
  { type, preselectedIds = initPreselectedIds }
) => {
  switch (type) {
    case ActionTypes.addToCampaignModal.state.preselectedIds:
      return { ...state, ...preselectedIds };
    case ActionTypes.addToCampaignModal.state.processingClear:
    case ActionTypes.addToCampaignModal.state.reset:
      return initPreselectedIds;
    default:
      return state;
  }
};
