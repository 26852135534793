import React, { Component } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Popup from 'components/popups/popup';
import { PopupSize } from 'libs/constantsShared';

class IdentityDeletePopup extends Component {
  onRemoveItem = () => {
    const { actionCreators, popupData } = this.props;

    actionCreators.removeItem(popupData);
  };

  render() {
    const { actionCreators } = this.props;

    return (
      <Popup
        className="salesforce-customization-popup"
        footer={{
          onPrimary: this.onRemoveItem,
          onSecondary: actionCreators.closePopup,
          primaryTextId: 'common.delete',
          secondaryTextId: 'common.cancel',
        }}
        header={{
          onClose: actionCreators.closePopup,
          textId: 'web.identities.delete.popup.header',
        }}
        onOutsideClick={actionCreators.closePopup}
        size={PopupSize.small}
      >
        <div className="margin-bottom-full">
          <FormattedHTMLMessage id="web.identities.delete.popup.body" />
        </div>
      </Popup>
    );
  }
}

IdentityDeletePopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  popupData: PropTypes.object.isRequired,
};

export default injectIntl(IdentityDeletePopup);
