import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = (props) => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <defs>
          <style>
            {'.cls-1{fill:none;stroke:#33AB84;stroke-linejoin:round;}'}
          </style>
        </defs>
      </defs>
      <g data-name="8-Check" id="_8-Check">
        <circle
          className="cls-1"
          cx="16"
          cy="16"
          r="15"
          style={{ strokeWidth: '1px' }}
        />
        <polyline
          className="cls-1"
          points="8 16 13 21 24 10"
          style={{ strokeWidth: '2px' }}
        />
      </g>
    </svg>
  );
};

CheckIcon.defaultProps = {
  className: '',
};

CheckIcon.propTypes = {
  className: PropTypes.string,
};

export default CheckIcon;
