import React, { Fragment } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import AdminUnsubscribesEditor from '../containers/adminUnsubscribeEditorContainer';
import UnsubscribesShowForUser from '../containers/unsubscribesShowForUserContainer';
import { AdminUnsubscribeCardsWidth } from '../libs/adminUnsubscribesConstants';

const AdminUnsubscribesMessaging = () => (
  <Fragment width={AdminUnsubscribeCardsWidth.messaging}>
    <h3>
      <FormattedMessage id="web.settings.unsubscribe.admin.unsubscribeTitle" />
    </h3>
    <p className="margin-top-full">
      <FormattedHTMLMessage id="web.settings.unsubscribe.description" />
    </p>
    <UnsubscribesShowForUser />
    <div className="margin-top-full" />
    <AdminUnsubscribesEditor />
  </Fragment>
);

export default AdminUnsubscribesMessaging;
