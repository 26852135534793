import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup, { PopupButtonColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { FormattedHTMLMessage } from 'react-intl';
import DeleteUsersDisclaimer from '../deleteUsersDisclaimer';

class DeleteUserConfirmationPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      defaultAlert,
      deleteUserLoading,
      isMsiActions,
    } = this.props;

    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.onDeleteUser,
          onSecondary: closePopup,
          primaryTextId: 'common.delete',
          secondaryTextId: 'common.cancel',
          primaryColor: PopupButtonColors.red,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.userManagement.users.popup.deleteUsers.title',
          textValues: { count: 1 },
        }}
        loading={deleteUserLoading}
        getImageUrl={getImageUrl}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedHTMLMessage
          id={
            isMsiActions
              ? 'web.settings.adminSettings.userManagement.users.salesInsightActionsPlan.popup.deleteUsers.body'
              : 'web.settings.adminSettings.userManagement.users.popup.deleteUsers.body'
          }
          values={{ count: 1 }}
        />
        <DeleteUsersDisclaimer isMsiActions={isMsiActions} />
      </Popup>
    );
  }

  onDeleteUser = () => {
    const {
      actionCreators: { deleteUser },
      popupData: { id },
    } = this.props;

    deleteUser(id);
  };
}

DeleteUserConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  defaultAlert: PropTypes.object,
  deleteUserLoading: PropTypes.bool.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  popupData: PropTypes.object,
};

DeleteUserConfirmationPopup.defaultProps = {
  defaultAlert: null,
  popupData: {},
};

export default DeleteUserConfirmationPopup;
