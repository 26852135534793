import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function sidebarSearchQuery(state = '', action) {
  switch (action.type) {
    case ActionTypes.sidebar.searchQuery:
      return action.query;
    default:
      return state;
  }
}
