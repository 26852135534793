/**
 *
 * SmartFilter
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Shapes from 'table/modules/shapes';
import Select from 'components/selects/base';
import I18N from 'languages';
import './smartFilter.scss';

const ROW_HEIGHT = 30;

class SmartFilter extends PureComponent {
  render() {
    const { filters, height, selectedValue, width } = this.props;

    return (
      <div className="tout-smart-filter">
        <Select
          className="tout-smart-filter-select"
          full
          fullHeight
          itemRenderer={this.itemRenderer}
          items={filters}
          labelRenderer={this.labelRenderer}
          menuStyle={{
            position: 'absolute',
            width: `${width + 2}px`,
            top: `${height}px`,
            left: '-1px',
          }}
          noBorder
          onChange={this.onChange}
          rowHeight={ROW_HEIGHT}
          rowsShown={filters.length}
          selected={selectedValue}
          width={width}
        />
      </div>
    );
  }

  labelRenderer = (obj) => (
    <div>
      <span className="text-medium text-uppercase">{this.props.label}</span>
      <span className="tout-smart-filter-label">{obj.label}</span>
    </div>
  );

  itemRenderer(item) {
    return (
      <div className="tout-smart-filter-item">
        <div className={classNames('text-overflow', item.className)}>
          {item.label}
        </div>
        {item.helpText && (
          <div className="tout-smart-filter-help text-overflow">
            {item.helpText}
          </div>
        )}
      </div>
    );
  }

  onChange = (value) => {
    const { filters, index, onChange, scrollTop } = this.props;
    onChange(index, value, filters);
    scrollTop();
  };
}

SmartFilter.propTypes = {
  filters: PropTypes.arrayOf(Shapes.SmartFilter).isRequired,
  height: PropTypes.number.isRequired,
  index: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  scrollTop: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  width: PropTypes.number,
};

SmartFilter.defaultProps = {
  filters: [],
  index: 0,
  label: I18N.getStr('selects.smartFilter.label'), //intl
  scrollTop: () => {},
  selectedValue: 0,
  width: 200,
};

export default SmartFilter;
