import React from 'react';
import PropTypes from 'prop-types';
import TasksTable from 'web/tables/tasks';
import { TasksTableColumns } from 'web/tables/tasks/libs/tablesTasksConstants';
import { CommandCenterTasksTableId } from '../../libs/commandCenterConstants';
import './commandCenterTasks.scss';

const columns = [
  TasksTableColumns.markDone,
  TasksTableColumns.person,
  TasksTableColumns.campaign,
  TasksTableColumns.company,
  TasksTableColumns.subject,
  TasksTableColumns.type,
  TasksTableColumns.priority,
  TasksTableColumns.due,
  TasksTableColumns.actions,
];

const CommandCenterTasks = (props) => (
  <div className="command-center-tasks">
    <TasksTable
      {...props}
      columns={columns}
      tableId={CommandCenterTasksTableId}
    />
  </div>
);

CommandCenterTasks.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  loading: PropTypes.bool,
  tasks: PropTypes.array.isRequired,
};

CommandCenterTasks.defaultProps = {
  fetching: false,
  loading: false,
};

export default CommandCenterTasks;
