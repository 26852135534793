import { createSelector } from 'reselect';
import {
  isInstalledStatus,
  isNotInstalledStatus,
  isPackageInstalledStatus,
  isRunningInstallStatus,
  isRunningUninstallStatus,
  isProcessingStatus,
  isSalesforceNotConnected,
  isStatusNeedInfo,
  getSortedProfileUsers,
  isCheckedAllProfileUsers,
} from 'web/modals/salesforceCustomization/helpers/salesforceCustomizationHelpers';
import { SalesforceCustomizationVersionsIds } from '../libs/salesforceCustomizationConstants';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

const getCustomizationStatus = (state) => state.salesforceCustomization.status;
const getCustomizationErrors = (state) => state.salesforceCustomizationErrors;

const getAvailableVersions = (state) =>
  state.salesforceCustomizationVersions.availableVersions;
const getInstalledVersions = (state) =>
  state.salesforceCustomizationVersions.installedVersions;
const getCompletedVersions = (state) =>
  state.salesforceCustomizationVersions.completedVersions;

const getSalesforceProfiles = (state) => state.salesforceCustomizationProfiles;

export const getStatusConditions = createSelector(
  [getCustomizationStatus],
  (customizationStatus) => ({
    isCustomizationInstalled: isInstalledStatus(customizationStatus),
    isCustomizationNotInstalled: isNotInstalledStatus(customizationStatus),
    isCustomizationPackageInstalled: isPackageInstalledStatus(
      customizationStatus
    ),
    isCustomizationStatusNeed: isStatusNeedInfo(customizationStatus),
    isProcessing: isProcessingStatus(customizationStatus),
    isRunningCustomizationInstall: isRunningInstallStatus(customizationStatus),
    isRunningCustomizationUninstall: isRunningUninstallStatus(
      customizationStatus
    ),
    isSalesforceNotConnected: isSalesforceNotConnected(customizationStatus),
  })
);

export const getErrorMessages = createSelector(
  [getCustomizationErrors],
  (errors = {}) => ({
    description: errors.description || null,
    details: errors.details || null,
    hasErrors: !!errors.description || !!errors.details,
  })
);

export const getVersions = createSelector(
  [getAvailableVersions, getCompletedVersions, getInstalledVersions],
  (availableVersions, completedVersions, installedVersions) =>
    [...availableVersions, ...installedVersions].map((version) => ({
      name: version,
      [SalesforceCustomizationVersionsIds.available]: availableVersions.includes(
        version
      ),
      [SalesforceCustomizationVersionsIds.completed]: completedVersions.includes(
        version
      ),
      [SalesforceCustomizationVersionsIds.installed]: installedVersions.includes(
        version
      ),
    }))
);

export const getSortedAvailableVersions = createSelector(
  [getVersions],
  (versions) =>
    sortBy(
      filter(versions, SalesforceCustomizationVersionsIds.available),
      'name'
    )
);

export const getSortedInstalledVersions = createSelector(
  [getVersions],
  (versions) =>
    sortBy(
      filter(versions, SalesforceCustomizationVersionsIds.installed),
      'name'
    ).reverse()
);

export const getProfiles = createSelector(
  [getSalesforceProfiles],
  (profiles) => {
    const sortedProfileUsers = getSortedProfileUsers(profiles.availableUsers);

    return {
      ...profiles,
      availableUsers: sortedProfileUsers,
      isCheckedAllAvailableUsers: isCheckedAllProfileUsers(sortedProfileUsers),
    };
  }
);
