import SalesforceCustomizationActionTypes from 'web/modals/salesforceCustomization/libs/salesforceCustomizationActionTypes';
import { USERS_ADMINS } from '../libs/salesforceCustomizationConstants';

const initialState = {
  availableUsers: [],
  currentUser: USERS_ADMINS,
  loading: true,
};

export const salesforceCustomizationProfiles = (
  state = initialState,
  payload = {}
) => {
  const { type, ...data } = payload;

  switch (type) {
    case SalesforceCustomizationActionTypes.profiles.userSet:
      return {
        ...state,
        currentUser: data.currentUser,
      };

    case SalesforceCustomizationActionTypes.profiles.availableUsersSet:
    case SalesforceCustomizationActionTypes.profiles.availableUsersUpdate:
      return {
        ...state,
        availableUsers: data.availableUsers,
        loading: false,
      };

    default:
      return state;
  }
};
