import { getPrimaryEmail } from 'web/shared/services/peopleService';
import { SalesforceObjectTypes } from 'web/people/peopleSearchAdd/libs/peopleSearchAddConstants';
import { formatName } from 'web/shared/helpers/peopleFormatting';

export function parseIndividualPersonsToMap(people = []) {
  return people.reduce((prev, curr) => {
    if (curr.members) {
      curr.members.forEach((member) => {
        prev[member.id] = member;
      });
      return prev;
    } else {
      prev[curr.id] = curr;
      return prev;
    }
  }, {});
}

export const parsePersonForAddPeople = (person = {}) => {
  const {
    id,
    first_name: firstName,
    last_name: lastName,
    company: { name: companyName } = {},
    title,
    external_references: externalReferences,
    lead_fields: leadFields,
  } = person;

  const { address } = getPrimaryEmail(person) || {};

  return {
    address: address || '',
    companyName: companyName || '',
    externalReferences: externalReferences || [],
    leadFields: leadFields || [],
    firstName: firstName || '',
    id,
    lastName: lastName || '',
    title: title || '',
  };
};

export function parsePeopleForAddPeople(people, currentPeople) {
  let hasDupe = false;
  const individualPersonsIndex = parseIndividualPersonsToMap(currentPeople);
  const members = people.map((person) => {
    if (!hasDupe && individualPersonsIndex[person.id]) {
      hasDupe = true;
    }

    return parsePersonForAddPeople(person);
  });

  return { hasDupe, members };
}

export const parseSelectedIds = (selectedIds) => {
  const ids = selectedIds || {};
  const parsed = {
    group: ids.groupIds || [],
    people: ids.peopleIds || [],
    salesforce: ids.salesforceIds || [],
    email: ids.addresses || [],
  };
  parsed.count =
    parsed.group.length +
    parsed.people.length +
    parsed.salesforce.length +
    parsed.email.length;
  return parsed;
};

export const formatPerson = ({
  address,
  companyName,
  externalReferences,
  leadFields,
  firstName,
  id,
  lastName,
  title,
}) => ({
  address,
  companyName,
  externalReferences,
  leadFields,
  id,
  name: formatName(firstName, lastName, address),
  title,
});

export const parseAndFormatPeopleForAddPeople = (people, currentPeople) => {
  const { hasDupe, members: parsedPeople } = parsePeopleForAddPeople(
    people,
    currentPeople
  );
  const formatttedPeople = parsedPeople.map((person) => formatPerson(person));

  return { hasDupe, members: formatttedPeople };
};
