import { navigateToTemplatesTab } from 'web/services/routerService';
import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import { createTemplate as createTemplateCall } from 'web/templates/services/templateService';
import {
  setPopupAlert,
  setPopupLoading,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { templatesCategoryFilter } from 'web/templates/actionCreators/templateCategoriesActionCreators';
import {
  resetTable,
  setTableFilter,
  setTableSort,
} from 'web/templates/actionCreators/templatesTableActionCreators';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';
import { SORT_DIRECTION } from 'web/templates/libs/templatesConstants';
import { track } from 'web/services/mixpanelService';
import { TemplatesEvents } from 'web/libs/mixpanelEvents';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export const setMostRecentlyCreatedTemplate = (template = {}) => ({
  type: TemplatesActionTypes.mostRecentlyCreated.set,
  template,
});

export const resetMostRecentlyCreatedTemplate = () => ({
  type: TemplatesActionTypes.mostRecentlyCreated.reset,
});

export const addCategory = (category) => ({
  type: TemplatesActionTypes.categories.add,
  category,
});

export const addTemplate = (template) => ({
  type: TemplatesActionTypes.templates.add,
  template,
});

export const setTableOnCreate = (templateCategoryId) => (
  dispatch,
  getState
) => {
  const { templatesSelectedTemplateCategoryId } = getState();

  dispatch(setTableSort('updated_at', SORT_DIRECTION.descending));

  if (templatesSelectedTemplateCategoryId === templateCategoryId) {
    dispatch(setTableFilter(templatesCategoryFilter(templateCategoryId)));
  }
};

export const redirectOnCreate = (template) => (dispatch) => {
  const { category_id: templateCategoryId } = template;

  dispatch(resetTable());
  dispatch(setTableOnCreate(templateCategoryId));
  dispatch(setMostRecentlyCreatedTemplate(template));

  navigateToTemplatesTab(template, templateCategoryId, true);
};

export const createTemplateAndRedirect = () => (dispatch, getState) => {
  const {
    templatesCreateTemplateState: { categoryId, name },
  } = getState();

  dispatch(setPopupLoading(true));
  createTemplateCall({ categoryId, name })
    .then((template) => {
      dispatch(setPopupLoading(false));
      dispatch(closePopup());
      toutBackboneHelper.templateUpdate(template);
      dispatch(redirectOnCreate(template));
      track(TemplatesEvents.create);
    })
    .catch((error) => {
      let textValues;
      let templateAlertId = TemplateAlertIds.createTemplateError;
      const {
        template_limit: templateLimit,
        template_count: userTemplateCount,
      } = JSON.parse(error.response.text);
      const forbiddenStatusCode = 403;

      if (error.response.statusCode === forbiddenStatusCode) {
        templateAlertId = TemplateAlertIds.templateLimitExceededError;
        textValues = {
          templateLimit,
          userTemplateCount,
        };
      }

      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(templateAlertId, textValues));
    });
};

export const updateCreateTemplateState = (update) => ({
  type: TemplatesActionTypes.createTemplate.update,
  update,
});
