import { Urls } from 'web/libs/routes';

export function isCommandCenterPageActive(currentUrl) {
  return currentUrl.startsWith(Urls.commandCenter);
}

export function isTemplatesPageActive(currentUrl) {
  return (
    currentUrl.startsWith(Urls.topNavbar.templates) ||
    currentUrl.startsWith(Urls.topNavbar.template_categories)
  );
}

export function isCampaignsPageActive(currentUrl) {
  return (
    currentUrl.startsWith(Urls.topNavbar.campaigns) ||
    currentUrl.startsWith(Urls.topNavbar.campaign_categories)
  );
}
