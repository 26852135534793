import { MESSAGES, ICONS as ICON } from '../libs/yourIntegrationsConstants';
import { Urls } from 'web/libs/routes';
import find from 'lodash/find';
import { DeliveryChannelProviders } from 'web/deliveryChannels/libs/deliveryChannelsConstants';

function getStatus(icon, message, link) {
  return {
    icon,
    message,
    ...(link && { link }),
  };
}

export function getMarketoIntegrationStatus(data) {
  const {
    isAdmin,
    isAdminMarketoAuthEnabled,
    isSalesforceSetUpForCurrentAccount,
    isSalesforceBrokenForCurrentAccount,
    isMarketoSetUp,
  } = data;

  const isConnectToMarketo =
    isAdminMarketoAuthEnabled &&
    isSalesforceSetUpForCurrentAccount &&
    !isSalesforceBrokenForCurrentAccount &&
    isMarketoSetUp;

  if (isAdmin && isConnectToMarketo) {
    return getStatus(ICON.POSITIVE, MESSAGES.CONNECTED_MARKETO);
  }

  if (isAdmin && !isConnectToMarketo) {
    return getStatus(
      ICON.NEUTRAL,
      MESSAGES.MARKETO_CONNECTION_INFO_ADMIN,
      Urls.marketoAdminSettings
    );
  }

  if (!isAdmin && isConnectToMarketo) {
    return getStatus(ICON.NEUTRAL, MESSAGES.MARKETO_CONNECTION_INFO_NON_ADMIN);
  }

  return null;
}

export function getSalesforceIntegrationStatus(data, salesforceConnectLink) {
  const {
    isSalesforceSetUpForCurrentAccount,
    isSalesforceBrokenForCurrentAccount,
  } = data;

  if (
    isSalesforceSetUpForCurrentAccount &&
    !isSalesforceBrokenForCurrentAccount
  ) {
    return getStatus(ICON.POSITIVE, MESSAGES.CONNECTED_SALESFORCE);
  }
  if (
    !isSalesforceSetUpForCurrentAccount &&
    !isSalesforceBrokenForCurrentAccount
  ) {
    return getStatus(
      ICON.NEUTRAL,
      MESSAGES.NOT_CONNECTED_SALESFORCE,
      Urls.accountSalesforceConnecting
    );
  }
  if (
    isSalesforceSetUpForCurrentAccount &&
    isSalesforceBrokenForCurrentAccount
  ) {
    return getStatus(
      ICON.NEGATIVE,
      MESSAGES.FIX_SALESFORCE,
      salesforceConnectLink
    );
  }
  return null;
}

export function getReplyTrackingStatus(data) {
  const {
    deliveryChannels,
    currentDeliveryChannel,
    isGmailIntegrationSetUp,
    isGmailIntegrationBroken,
    isOutlookIntegrationSetUp,
    isOutlookIntegrationBroken,
  } = data;
  const isExchangeOnPremIntegartionSetUp =
    currentDeliveryChannel &&
    currentDeliveryChannel.provider === DeliveryChannelProviders.exchangeOnPrem;

  if (
    !isGmailIntegrationSetUp &&
    !isOutlookIntegrationSetUp &&
    !isExchangeOnPremIntegartionSetUp
  ) {
    return getStatus(
      ICON.NEUTRAL,
      MESSAGES.NOT_SETUP_REPLY_TRACKING,
      Urls.emailConnectionTab
    );
  }

  if (
    (isGmailIntegrationSetUp && isGmailIntegrationBroken) ||
    (isOutlookIntegrationSetUp && isOutlookIntegrationBroken)
  ) {
    return getStatus(
      ICON.NEGATIVE,
      MESSAGES.FIX_REPLY_TRACKING,
      Urls.emailConnectionTab
    );
  }

  if (isGmailIntegrationSetUp) {
    const deliveryChannel = find(deliveryChannels, [
      'provider',
      DeliveryChannelProviders.gmail,
    ]);

    const username = deliveryChannel && deliveryChannel.username;

    return getStatus(
      ICON.POSITIVE,
      MESSAGES.SETUP_GMAIL_REPLY_TRACKING,
      username
    );
  }

  if (isOutlookIntegrationSetUp) {
    const deliveryChannel = find(deliveryChannels, [
      'provider',
      DeliveryChannelProviders.office365,
    ]);

    const username = deliveryChannel && deliveryChannel.username;

    return getStatus(
      ICON.POSITIVE,
      MESSAGES.SETUP_OUTLOOK_REPLY_TRACKING,
      username
    );
  }

  if (isExchangeOnPremIntegartionSetUp) {
    const deliveryChannel = find(deliveryChannels, [
      'provider',
      DeliveryChannelProviders.exchangeOnPrem,
    ]);

    const username = deliveryChannel && deliveryChannel.username;

    return getStatus(
      ICON.POSITIVE,
      MESSAGES.SETUP_EXCHANGE_REPLY_TRACKING,
      username
    );
  }

  return null;
}

export function getPrimaryDeliveryChannelStatus(data) {
  const {
    currentDeliveryChannel,
    defaultIdentity,
    isAdmin,
    isGmailIntegrationSetUp,
    isGmailIntegrationBroken,
    isOutlookIntegrationSetUp,
    isOutlookIntegrationBroken,
    isCustomDeliveryChannelSetUp,
    isCustomDeliveryChannelBroken,
    isTeamSmtpServerSetUp,
    isTeamSmtpServerBroken,
    isToutIntegrationSetup,
  } = data;

  const isExchangeOnPremIntegartionSetUp =
    currentDeliveryChannel &&
    currentDeliveryChannel.provider === DeliveryChannelProviders.exchangeOnPrem;

  if (isAdmin) {
    if (
      !isGmailIntegrationSetUp &&
      !isOutlookIntegrationSetUp &&
      !isCustomDeliveryChannelSetUp &&
      !isTeamSmtpServerSetUp &&
      !isToutIntegrationSetup &&
      !isExchangeOnPremIntegartionSetUp
    ) {
      return getStatus(
        ICON.NEUTRAL,
        MESSAGES.NOT_SELECTED_DELIVERY_CHANNEL,
        Urls.emailConnectionTab
      );
    }
    if (isTeamSmtpServerSetUp && isTeamSmtpServerBroken) {
      return getStatus(
        ICON.NEGATIVE,
        MESSAGES.FIX_DELIVERY_CHANNEL,
        Urls.teamDeliveryChannelServer
      );
    }
  } else {
    if (
      !isGmailIntegrationSetUp &&
      !isOutlookIntegrationSetUp &&
      !isCustomDeliveryChannelSetUp &&
      !isTeamSmtpServerSetUp &&
      !isExchangeOnPremIntegartionSetUp
    ) {
      return getStatus(
        ICON.NEUTRAL,
        MESSAGES.ADMIN_NOT_SELECTED_DELIVERY_CHANNEL,
        Urls.emailConnectionTab
      );
    }
    if (isTeamSmtpServerSetUp && isTeamSmtpServerBroken) {
      return getStatus(ICON.NEGATIVE, MESSAGES.FIX_ADMIN_DELIVERY_CHANNEL);
    }
  }

  const provider = currentDeliveryChannel
    ? currentDeliveryChannel.provider
    : undefined;

  const server = currentDeliveryChannel
    ? currentDeliveryChannel.server
    : undefined;

  if (
    (isGmailIntegrationSetUp &&
      provider === DeliveryChannelProviders.gmail &&
      isGmailIntegrationBroken) ||
    (isOutlookIntegrationSetUp &&
      provider === DeliveryChannelProviders.office365 &&
      isOutlookIntegrationBroken)
  ) {
    return getStatus(
      ICON.NEGATIVE,
      MESSAGES.FIX_DELIVERY_CHANNEL,
      Urls.emailConnectionTab
    );
  }

  if (
    isCustomDeliveryChannelSetUp &&
    provider === DeliveryChannelProviders.smtp &&
    isCustomDeliveryChannelBroken
  ) {
    return getStatus(
      ICON.NEGATIVE,
      MESSAGES.FIX_DELIVERY_CHANNEL,
      Urls.smtpServerSettingsTab
    );
  }

  if (isTeamSmtpServerSetUp) {
    return {
      email: defaultIdentity.email,
      icon: ICON.POSITIVE,
      message: MESSAGES.EMAIL_SENT_VIA_TEAM_SMTP_SERVER,
      server,
    };
  }

  if (isGmailIntegrationSetUp && provider === DeliveryChannelProviders.gmail) {
    return {
      email: defaultIdentity.email,
      icon: ICON.POSITIVE,
      message: MESSAGES.EMAIL_SENT_VIA_GMAIL,
      username: currentDeliveryChannel.username,
    };
  }

  if (
    isOutlookIntegrationSetUp &&
    provider === DeliveryChannelProviders.office365
  ) {
    return {
      email: defaultIdentity.email,
      icon: ICON.POSITIVE,
      message: MESSAGES.EMAIL_SENT_VIA_OUTLOOK,
      username: currentDeliveryChannel.username,
    };
  }

  if (
    isCustomDeliveryChannelSetUp &&
    provider === DeliveryChannelProviders.smtp
  ) {
    return {
      email: defaultIdentity.email,
      icon: ICON.POSITIVE,
      message: MESSAGES.EMAIL_SENT_VIA_CUSTOM_DELIVERY_CHANNEL,
      server,
    };
  }

  if (isToutIntegrationSetup) {
    return {
      email: defaultIdentity.email,
      icon: ICON.POSITIVE,
      message: MESSAGES.EMAIL_SENT_VIA_TOUTAPP_DELIVERY_CHANNEL,
    };
  }

  if (isExchangeOnPremIntegartionSetUp) {
    return {
      email: defaultIdentity.email,
      icon: ICON.POSITIVE,
      message: MESSAGES.EMAIL_SENT_VIA_OUTLOOK_ON_PREM_DELIVERY_CHANNEL,
      username: currentDeliveryChannel.username,
    };
  }

  return null;
}
