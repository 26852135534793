/*
  Shapes used when passing props to the table container element
*/

import PropTypes from 'prop-types';
import {
  Align as AlignConstant,
  WidthMode as WidthModeConstant,
} from 'table/modules/constants';
import { HoverTooltipPlace } from 'components/hoverTooltip';

/* Used by Column */
const Align = PropTypes.oneOf([...Object.values(AlignConstant)]);
const WidthMode = PropTypes.oneOf([...Object.values(WidthModeConstant)]); //todo move to Shapes?

/* Used by Column */ const Cell = PropTypes.shape({
  // align: AlignShape,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  metadata: PropTypes.object, //.isRequired
});

// /* Used by Column */
// const CellHeader = PropTypes.shape({
//   // align: AlignShape,
//   component: PropTypes.node,
//   metadata: PropTypes.object.isRequired,
//   // onClick: PropTypes.func,
//   // sorter: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
// });

/* Used by Columns */
const Column = PropTypes.shape({
  align: Align,
  flex: PropTypes.bool,
  headerCell: Cell,
  id: PropTypes.string.isRequired,
  initHide: PropTypes.bool,
  name: PropTypes.string,
  rowCell: Cell,
  sorting: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.func,
  ]),
  tooltip: PropTypes.string,
  width: PropTypes.number,
  widthMode: WidthMode,
});

/* Straight Passthrough */
const Columns = PropTypes.arrayOf(Column).isRequired;

/* Straight Passthrough */
const ZeroState = PropTypes.shape({
  bodyText: PropTypes.string,
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.number,
  titleText: PropTypes.string,
});

/* Added to - onCheckClick, sort */
const Header = PropTypes.shape({
  height: PropTypes.number,
});

/* Added to - onCheckClick */
const Row = PropTypes.shape({
  height: PropTypes.number,
  onClick: PropTypes.func,
});

/* Modified - onClick (if allItems), currentPage (if allItems)
   Added to - totalPages
   Removed - perPage, total (if not allItems)
*/
const Pagination = PropTypes.shape({
  clearSelected: PropTypes.bool,
  currentPage: PropTypes.number,
  onClick: PropTypes.func,
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number,
});

const Searcher = PropTypes.shape({
  label: PropTypes.string.isRequired,
  searching: PropTypes.func.isRequired,
});

/* Modified (if allItems) - onChange, onPrefixRemove, onResultsSelected, prefix, value
   Added to - results */
const Search = PropTypes.shape({
  maxWidth: PropTypes.number,
  noDropdown: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onPrefixRemove: PropTypes.func,
  onResultSelected: PropTypes.func,
  placeholderText: PropTypes.string,
  prefix: PropTypes.string,
  searchers: PropTypes.arrayOf(Searcher),
  searching: PropTypes.func,
  value: PropTypes.string,
});

/* Used by SmartFilter */
const SmartFilterItem = PropTypes.shape({
  className: PropTypes.string,
  filtering: PropTypes.func,
  helpText: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

/* Used by SmartFilters
   Added to - onChange (if allItems)
   Modified - selected
*/
const SmartFilter = PropTypes.shape({
  filters: PropTypes.arrayOf(SmartFilterItem), //todo test adding req
  label: PropTypes.string,
  onChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

/* Modified - to array */
const SmartFilters = PropTypes.oneOfType([PropTypes.array, SmartFilter]);

/* Used by BulkActions */
const BulkActionsAction = PropTypes.shape({
  color: PropTypes.string,
  disabled: PropTypes.bool,
  disabledTooltipText: PropTypes.string,
  hideCount: PropTypes.bool,
  isHidden: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipPlace: PropTypes.oneOf(Object.values(HoverTooltipPlace)),
  tooltipText: PropTypes.string,
});

/* Added to - onClearAllClick, onSelectAllClick, selectedCount, totalCount */
const BulkActions = PropTypes.shape({
  SelectAllRequest: PropTypes.shape({
    requestParams: PropTypes.object,
    selectAllHandler: PropTypes.func,
  }),
  actions: PropTypes.arrayOf(BulkActionsAction).isRequired,
  itemsLabel: PropTypes.string,
  selectAll: PropTypes.bool,
  totalCount: PropTypes.number,
});

/* Added to - checked, onChange */
const ColumnFilter = PropTypes.bool;

export default {
  Align,
  BulkActions,
  BulkActionsAction,
  Cell,
  // CellHeader,
  Column,
  ColumnFilter,
  Columns,
  Header,
  Pagination,
  Row,
  Search,
  SmartFilter,
  SmartFilterItem,
  SmartFilters,
  ZeroState,
};
