import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownIcon from 'components/dropdownIcon';
import DropdownMenu from 'components/dropdownMenu';
import SidebarEditListItem from 'web/shared/components/sidebar/sidebarList/sidebarEditListItem/sidebarEditListItem';
import Icon from 'components/buttons/icon';
import HoverTooltip from 'components/hoverTooltip';
import './sidebarListItem.scss';
import { isSpecialCategory } from 'web/templates/helpers/templatesHelper';
import I18N from 'languages';
import withDndTarget from 'table/modules/withDnd/withDndTarget';

class SidebarListItem extends Component {
  render() {
    const {
      classes,
      isEditing,
      label,
      onEdit,
      onEditCancel,
      itemOptions,
      elementId,
      scrollRef,
      iconOptions,
      totalNumber,
      showTooltip,
    } = this.props;
    const boundItems =
      (itemOptions && this._bindActions(itemOptions, elementId)) || [];
    const ROW_HEIGHT = 42;

    const labelText = isSpecialCategory(label)
      ? I18N.getStr(`common.${label.toLowerCase()}`)
      : label;

    const labelTextHtml = (
      <div className="text-overflow label-name">{labelText}</div>
    );

    return (
      <div className={classNames(classes)} onClick={this._onSelect}>
        {iconOptions && !iconOptions.disabled && !isEditing ? (
          <div className="tout-ui-sidebar-warning-container">
            <HoverTooltip
              stayOpen={false}
              textId={iconOptions.title}
              tooltipId="sidebar-merge-tooltip"
            >
              <Icon className="tout-ui-sidebar-warning" />
            </HoverTooltip>
          </div>
        ) : null}
        {isEditing ? (
          <SidebarEditListItem
            id={elementId}
            onEdit={onEdit}
            onEditCancel={onEditCancel}
            value={labelText}
          />
        ) : (
          <div className="label-container">
            {showTooltip ? (
              <HoverTooltip
                stayOpen={false}
                textValue={labelText}
                tooltipId={`sidebar-list-item-${elementId}`}
              >
                <div className="text-overflow label-name">{labelTextHtml}</div>
              </HoverTooltip>
            ) : (
              <div className="text-overflow label-name">{labelTextHtml}</div>
            )}
            <div className="text-overflow label-total-number">
              {totalNumber}
            </div>
          </div>
        )}
        {itemOptions && itemOptions.length && !isEditing ? (
          <div className="tout-ui-sidebar-edit-container">
            <DropdownIcon
              childHeight={ROW_HEIGHT * boundItems.length}
              className="tout-ui-sidebar-dota"
              color="white"
              scrollRef={scrollRef}
            >
              <DropdownMenu items={boundItems} />
            </DropdownIcon>
          </div>
        ) : null}
      </div>
    );
  }

  _onSelect = () => {
    const { elementId, onSelect } = this.props;
    onSelect(elementId);
  };

  _bindActions = (itemOptions, id) =>
    itemOptions.map((element) => ({
      ...element,
      action: element.action.bind(this, id),
      disabled: element.disabled && element.disabled(id),
    }));
}

SidebarListItem.propTypes = {
  classes: PropTypes.string,
  elementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  iconOptions: PropTypes.object,
  isEditing: PropTypes.bool,
  itemOptions: PropTypes.array,
  label: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onEditCancel: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  scrollRef: PropTypes.object,
  showTooltip: PropTypes.bool,
  totalNumber: PropTypes.number,
};

SidebarListItem.defaultProps = {
  classes: '',
  iconOptions: {},
  isEditing: false,
  itemOptions: [],
  onEdit: undefined,
  onEditCancel: undefined,
  scrollRef: {},
  showTooltip: false,
  totalNumber: undefined,
};

export default SidebarListItem;
export const DndSidebarListItem = withDndTarget(SidebarListItem);
