import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddContact from '../components/addContact';
import { bindActionCreators } from 'redux';
import { getOldestAdminId } from 'web/user/selectors/userSelectors';
import {
  closeCardEditing,
  openCardEditing,
  clearTabAlert,
} from '../actionCreators/personDetailsActionCreators';
import * as infoACs from '../actionCreators/personDetailsInfoActionCreators';
import * as actionACs from '../actionCreators/personDetailsActionsActionCreators';
import { onInfoEditAddressTypeChange } from '../helpers/personDetailsMixpanel';
import {
  getCustomFieldsCardHasChanges,
  getGroupsAddable,
  getGroupsCardHasChanges,
  getInfoCardHasChanges,
  getPerson,
} from '../selectors/personDetailsSelectors';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import { getCurrentPeopleGroupName } from 'web/people/selectors/peopleSelectors';
import { updatePersonGroups } from 'web/person/actionCreators/personDetailsInfoActionCreators';

class AddContactContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    this.actionCreators = {
      ...bindActionCreators(
        {
          clearTabAlert,
          closeCardEditing,
          onInfoEditAddressTypeChange,
          openCardEditing,
          updatePersonGroups,
          ...infoACs,
          ...actionACs,
        },
        dispatch
      ),
    };
  }

  updatePersonAddresses = (addressType, index, edits = null) => {
    const {
      email: { addresses },
    } = this.props.infoEditState;
    let updatedAddresses = [...addresses];
    if (addressType !== 'email') {
      return;
    }
    if (edits) {
      updatedAddresses = [
        ...addresses.slice(0, index),
        {
          ...addresses[index],
          ...edits,
        },
        ...addresses.slice(index + 1),
      ];
    } else {
      updatedAddresses = [
        ...addresses.slice(0, index),
        ...addresses.slice(index + 1),
      ];
    }
    const mappedAddresses = updatedAddresses
      .filter((a) => a.value)
      .map((a, i) => ({
        id: `new${i}`,
        address: a.value,
        address_type: a.type,
        location: a.location,
      }));
    const editStateAddresses = mappedAddresses.map(({ address, id }) => ({
      label: address,
      value: id,
    }));
    // if no groups left - close group card to avoid errors
    if (mappedAddresses.length === 0) {
      this.actionCreators.closeCardEditing(PersonDetailsCards.groups);
    }
    this.actionCreators.updatePersonAddresses(mappedAddresses);
    this.actionCreators.setAddressesEditState(editStateAddresses);
  };

  removeInfoRow = (addressType, index) => {
    this.updatePersonAddresses(addressType, index);
    this.actionCreators.removeInfoRow(addressType, index);
  };

  updateInfoEditStateAddresses = (edits, addressType, index) => {
    this.updatePersonAddresses(addressType, index, edits);
    this.actionCreators.updateInfoEditStateAddresses(edits, addressType, index);
  };

  render() {
    const { dispatch: ignored1, open: ignored2, ...other } = this.props;
    return [
      <AddContact
        actionCreators={{
          ...this.actionCreators,
          updateInfoEditStateAddresses: this.updateInfoEditStateAddresses,
          removeInfoRow: this.removeInfoRow,
        }}
        key="person-details"
        {...other}
      />,
    ];
  }
}

AddContactContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    customFieldsCardHasChanges: getCustomFieldsCardHasChanges(state),
    customFieldsEditState: state.personDetailsCustomFieldsEditState,
    editing: state.personDetailsEditing,
    groupsAddable: getGroupsAddable(state),
    groupsCardHasChanges: getGroupsCardHasChanges(state),
    groupsEditState: state.personDetailsGroupsEditState,
    infoCardHasChanges: getInfoCardHasChanges(state),
    infoEditState: state.personDetailsInfoEditState,
    oldestAdminId: getOldestAdminId(state),
    person: getPerson(state),
    salesforceRequiredFields: state.personDetailsSalesforceRequiredFields,
    tabAlert: state.personDetailsTabAlert,
    tabLoading: state.personDetailsTabLoading,
    userSubscriptionUsers: state.userSubscriptionUsers,
    user: state.user,
    currentPeopleGroupName: getCurrentPeopleGroupName(state),
    currentPeopleGroupId: state.peopleCurrentGroupId,
  };
}

export default connect(mapStateToProps)(AddContactContainer);
