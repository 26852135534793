import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import LoadingSpinner from 'components/loadingSpinner';
import ViewMore from 'components/viewMore';
import LiveFeedEvent from 'web/liveFeed/components/liveFeedEvent';

const SHOWN_COUNT = 10;

class PersonDetailsToutEventsCard extends Component {
  render() {
    const { events, loading } = this.props;

    return (
      <div className="person-details-tout-events-card">
        {loading && <LoadingSpinner imageUrl={getImageUrl} />}
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.person.personDetails.toutEventsCard.title" />
        </h3>
        {(events.length > 0 && (
          <ViewMore
            itemRenderer={this.itemRenderer}
            items={events}
            showCount
            shownCount={SHOWN_COUNT}
            viewMoreOnClick={this.viewMoreOnClick}
          />
        )) || (
          <FormattedMessage id="web.person.personDetails.toutEventsCard.noEvents" />
        )}
      </div>
    );
  }

  itemRenderer = ({ id, ...event }) => (
    <LiveFeedEvent
      {...event}
      eventId={id}
      key={`person-details-tout-event-${id}`}
      openEmailInfo={this.props.openEmailInfo}
      showEmailLink={false}
      staticView
    />
  );

  viewMoreOnClick = () =>
    this.props.viewMoreOnClick(PersonDetailsCards.campaigns);
}

PersonDetailsToutEventsCard.propTypes = {
  events: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  openEmailInfo: PropTypes.func.isRequired,
  viewMoreOnClick: PropTypes.func.isRequired,
};

PersonDetailsToutEventsCard.defaultProps = {
  loading: false,
};

export default PersonDetailsToutEventsCard;
