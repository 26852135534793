import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import outsideClickHandler from 'components/hocs/outsideClickHandler';
import { keyNames, keyNamesToCodes } from 'components/hocs/keyDownHandler';
import Text from 'components/inputs/text';
import Icon, { IconColors } from 'components/buttons/icon';
import './listItemRename.scss';

class ListItemRename extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      updatedValue: this.props.value,
    };
  }

  render() {
    const {
      intl: { formatMessage },
      onCancel,
    } = this.props;
    const { updatedValue } = this.state;

    return (
      <div className="list-item-rename">
        <Text
          className="list-item-rename-text"
          onChange={this.onChange}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          placeholder={formatMessage({ id: 'list.listItemRename.placeholder' })}
          value={updatedValue}
        />
        <Icon
          className="tout-icon-list-item-rename-cancel"
          color={IconColors.darkGray}
          onClick={onCancel}
        />
      </div>
    );
  }

  onChange = (updatedValue) => {
    this.setState(() => ({ updatedValue }));
  };

  onClick = (e) => {
    e.stopPropagation();
  };

  onKeyDown = (e) => {
    if (
      e.keyCode === keyNamesToCodes[keyNames.tab] ||
      e.keyCode === keyNamesToCodes[keyNames.return]
    ) {
      this.onUpdate();
    }
  };

  onUpdate = () => {
    const { onCancel, onRename, id, value } = this.props;
    const { updatedValue } = this.state;

    if (updatedValue.length > 0 && value !== updatedValue) {
      onRename(id, updatedValue);
    } else {
      onCancel();
    }
  };

  onOutsideClick = () => {
    this.onUpdate();
  };
}

ListItemRename.propTypes = {
  id: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOutsideClick: PropTypes.func, //eslint-disable-line react/no-unused-prop-types
  onRename: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

ListItemRename.defaultProps = {
  onOutsideClick: null,
};

export default injectIntl(outsideClickHandler(ListItemRename));
