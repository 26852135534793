import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { TaskType, TaskPriority } from 'libs/constantsShared';
import Text from 'components/inputs/text';
import Textarea from 'components/inputs/textarea';
import Label from 'components/label';
import RadioGroup from 'components/buttons/radioGroup';
import Autocomplete from 'components/inputs/autocomplete';
import SmartSelect from 'components/selects/smartSelect';
import DatePicker from 'components/dates/datePicker';
import Checkbox from 'components/buttons/checkbox';
import './task2.scss';

const LABEL_WIDTH = 100; // for task subject
const ROWS_SHOWN = 6;

class Task2 extends Component {
  constructor(props) {
    super(props);
    const { user } = props;

    this.taskTypeItems = this._createTaskTypeItems(props.intl.formatMessage);
    this.taskPriorityItems = this._createPriorityItems(
      props.intl.formatMessage
    );
    this.state = {
      selectedItemId: user.id,
    };
  }

  render() {
    const {
      autocompleteLoading,
      autocompleteResults,
      autocompleteValue,
      intl: { formatMessage },
      onAutocompleteChange,
      onAutocompleteLeaving,
      onAutocompleteSelection,
      onUserAutocompleteChange,
      optionsDisableAll,
      optionsDisableAutocomplete,
      optionsDisableType,
      optionsMarkComplete,
      optionsShowContact,
      task,
      userAutocompleteValue,
      users,
      windowMode,
    } = this.props;
    const { selectedItemId } = this.state;

    return (
      <div className="task2">
        <div className="margin-top-full">
          <Label
            id="task-type"
            messageKey="task.type"
            width={LABEL_WIDTH}
            sideLabel
          >
            <RadioGroup
              disabled={optionsDisableAll || optionsDisableType}
              items={this.taskTypeItems}
              onChange={this.onTypeChange}
              selected={task.type || TaskType.email}
            />
          </Label>
        </div>
        <div className="margin-top-full">
          <Label
            id="task-name"
            messageKey="task.name"
            width={LABEL_WIDTH}
            sideLabel
          >
            <Text
              className=""
              disabled={optionsDisableAll}
              id="task-name"
              onChange={this.onTitleChange}
              placeholder={formatMessage({ id: 'task.subjectPlaceholder' })}
              value={task.title}
              autoFocus
            />
          </Label>
        </div>
        <div className="margin-top-full">
          <Label
            id="task-user"
            messageKey="task.assignTask"
            width={LABEL_WIDTH}
            sideLabel
          >
            <SmartSelect
              className="task-user-field"
              inputValue={userAutocompleteValue}
              items={this._parseUsers(users)}
              onChange={onUserAutocompleteChange}
              onReset={this.onUserReset}
              onSelect={this.onUserSelect}
              rowsShown={ROWS_SHOWN}
              selectedItem={selectedItemId}
            />
          </Label>
        </div>
        {optionsShowContact ? (
          <div className="margin-top-full">
            <Label
              id="task-contact"
              messageKey="common.person"
              width={LABEL_WIDTH}
              sideLabel
            >
              <Autocomplete
                autoFocus={false}
                className=""
                disabled={optionsDisableAll || optionsDisableAutocomplete}
                loading={autocompleteLoading}
                onChange={onAutocompleteChange}
                onLeaving={onAutocompleteLeaving}
                onSelection={onAutocompleteSelection}
                placeholder={formatMessage({ id: 'task.contactPlaceholder' })}
                results={autocompleteResults}
                value={autocompleteValue}
              />
            </Label>
          </div>
        ) : null}
        <div className="margin-top-full">
          <Label
            id="task-date"
            messageKey="common.dueDate"
            width={LABEL_WIDTH}
            sideLabel
          >
            <div className="task-date-container">
              <DatePicker
                className="task-date"
                date={task.date}
                disabled={optionsDisableAll}
                onDateChange={this.onDateChange}
                withPortal={windowMode}
              />
            </div>
          </Label>
        </div>
        <div className="margin-top-full">
          <Label
            id="task-priority"
            messageKey="common.priority"
            width={LABEL_WIDTH}
            sideLabel
          >
            <div className="task-priority">
              <RadioGroup
                disabled={optionsDisableAll}
                items={this.taskPriorityItems}
                onChange={this.onPriorityChange}
                selected={
                  (task.priority && task.priority.toLowerCase()) ||
                  TaskPriority.normal
                }
              />
            </div>
          </Label>
        </div>
        <div className="margin-top-full">
          <Label
            id="task-notes"
            messageKey="common.details"
            width={LABEL_WIDTH}
          >
            <Textarea
              autoFocus={false}
              className=""
              disabled={optionsDisableAll}
              id="task-notes"
              onChange={this.onNotesChange}
              placeholder={formatMessage({ id: 'task.notesPlaceholder' })}
              rows={4}
              value={task.notes || ''}
            />
          </Label>
        </div>
        {optionsMarkComplete ? (
          <Checkbox
            checked={task.markComplete}
            classes="margin-top-full"
            disabled={optionsDisableAll}
            label={formatMessage({ id: 'task.markComplete' })}
            name="markComplete"
            onChange={this.onMarkCompleteChange}
          />
        ) : null}
      </div>
    );
  }

  _createTaskTypeItems(formatMessage) {
    return [
      { label: formatMessage({ id: 'common.email' }), value: TaskType.email },
      { label: formatMessage({ id: 'common.call' }), value: TaskType.call },
      { label: formatMessage({ id: 'common.inMail' }), value: TaskType.inMail },
      { label: formatMessage({ id: 'common.custom' }), value: TaskType.other },
    ];
  }

  _createPriorityItems(formatMessage) {
    return [
      {
        classes: '',
        label: formatMessage({ id: 'task.normal' }),
        value: TaskPriority.normal,
      },
      {
        classes: 'high',
        label: formatMessage({ id: 'task.high' }),
        value: TaskPriority.high,
      },
    ];
  }

  _parseUsers() {
    return Object.values(this.props.users).map(({ email, id, name }) => ({
      label: `${name || email} (${email})`,
      value: id,
    }));
  }

  onTypeChange = (type) => {
    this.props.onChange({ type });
  };

  onTitleChange = (title) => {
    this.props.onChange({ title });
  };

  onDateChange = (dateMoment) => {
    this.props.onChange({ date: dateMoment });
  };

  onPriorityChange = (priority) => {
    this.props.onChange({ priority });
  };

  onNotesChange = (notes) => {
    this.props.onChange({ notes });
  };

  onMarkCompleteChange = (checked) => {
    this.props.onChange({ markComplete: checked });
  };

  onUserSelect = (data) => {
    const { onUserAutocompleteSelection } = this.props;
    this.setState({
      selectedItemId: data.value,
    });
    onUserAutocompleteSelection(data);
  };

  onUserReset = () => {
    const { onUserAutocompleteChange, users } = this.props;
    const { selectedItemId } = this.state;
    const { email, name } = users.find((user) => user.id === selectedItemId);
    onUserAutocompleteChange(`${name || email} (${email})`);
  };
}

Task2.propTypes = {
  autocompleteLoading: PropTypes.bool,
  autocompleteResults: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  onAutocompleteChange: PropTypes.func.isRequired,
  onAutocompleteLeaving: PropTypes.func.isRequired,
  onAutocompleteSelection: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUserAutocompleteChange: PropTypes.func.isRequired,
  onUserAutocompleteSelection: PropTypes.func.isRequired,
  optionsDisableAll: PropTypes.bool,
  optionsDisableAutocomplete: PropTypes.bool,
  optionsDisableType: PropTypes.bool,
  optionsMarkComplete: PropTypes.bool,
  optionsShowContact: PropTypes.bool,
  task: PropTypes.object,
  user: PropTypes.object.isRequired,
  userAutocompleteValue: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  windowMode: PropTypes.bool,
};

Task2.defaultProps = {
  autocompleteLoading: false,
  optionsDisableAll: false,
  optionsDisableAutocomplete: false,
  optionsDisableType: false,
  optionsMarkComplete: false,
  optionsShowContact: true,
  task: {},
  windowMode: false,
};

export default injectIntl(Task2);
