import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import I18N from 'languages';
import ManageRecordingPlayer from 'web/manageRecordingNotice/components/player';
import './consentMessageNameCell.scss';

class ConsentMessageNameCell extends PureComponent {
  render() {
    const {
      className,
      rowData: {
        audioData,
        file_attachment: { file_file_name: fileName },
        active,
      },
    } = this.props;
    const containerClasses = classNames(className, 'file-name-cell', {});
    const suffix = I18N.getStr(
      'web.settings.adminSettings.general.salesEngagePhoneCard.selectedLabel'
    );
    const label = active ? `${fileName} ${suffix}` : fileName;

    return (
      <div className={containerClasses}>
        {audioData ? (
          <ManageRecordingPlayer
            playingAudio={audioData.resources.page.original}
          />
        ) : null}
        <span className="player-audio-title">{label}</span>
        {active && <div className="tout-icon-check" />}
      </div>
    );
  }
}

ConsentMessageNameCell.propTypes = {
  className: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default ConsentMessageNameCell;
