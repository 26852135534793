import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampaignStepIcon from 'web/campaigns/campaignsTab/components/campaignStepIcon';
import { injectIntl, intlShape } from 'react-intl';
import { getFirstStep } from 'web/campaigns/campaignsTab/helpers/campaignsHelpers';
import './campaignStepCell.scss';

class MarketoCampaignStepCell extends Component {
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;
    this.noFirstStep = formatMessage({ id: 'web.campaigns.noFirstStep' });
  }

  render() {
    const { rowData: campaign } = this.props;
    const { action, name } = getFirstStep(campaign);
    const firstStepName = name || this.noFirstStep;

    return (
      <div className="campaign-step-cell">
        <CampaignStepIcon
          action={action}
          className="campaign-step-cell-icon"
          preventHover
        />
        <div className="text-overflow">{firstStepName}</div>
      </div>
    );
  }
}

MarketoCampaignStepCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(MarketoCampaignStepCell);
