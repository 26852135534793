import React from 'react';
import PropTypes from 'prop-types';
import { track } from 'web/services/mixpanelService';
import Icon from 'components/buttons/icon';
import { CommandCenterTaskEvents } from 'web/libs/mixpanelEvents';
import { injectIntl, intlShape } from 'react-intl';
import './emailQuickActionsCell.scss';

const EmailQuickActionsCell = ({
  intl: { formatMessage },
  actionCreators: {
    openCallModal,
    openAddTask,
    navigateToLinkedIn,
    replyToEmail,
  },
  rowData,
}) => {
  const openToutPhonePopup = (e) => {
    e.stopPropagation();
    e.preventDefault();
    track(CommandCenterTaskEvents.clickCall, { type: 'call' });
    openCallModal(rowData);
  };

  const openTaskModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { email, person_id: personId, id } = rowData;
    openAddTask({ parent_email: email, person_id: personId, pitch_id: id });
  };

  const clickToLinkedIn = (e) => {
    const { name } = rowData;
    e.stopPropagation();
    e.preventDefault();
    track(CommandCenterTaskEvents.openLinkedIn, { type: 'inmail' });
    navigateToLinkedIn(name);
  };

  const reply = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { id } = rowData;

    replyToEmail(id);
  };

  return (
    <div className="email-quick-actions-cell">
      <span>
        <Icon
          classes="pitch-icon pitch-icon-reply"
          color="gray"
          onClick={reply}
          title={formatMessage({ id: 'common.email' })}
        />
        <Icon
          classes="pitch-icon pitch-icon-phone"
          color="gray"
          onClick={openToutPhonePopup}
          title={formatMessage({ id: 'common.call' })}
        />
        <Icon
          classes="pitch-icon pitch-icon-inmail"
          color="gray"
          onClick={clickToLinkedIn}
          title={formatMessage({ id: 'common.inMail' })}
        />
        <Icon
          classes="pitch-icon pitch-icon-task"
          color="gray"
          onClick={openTaskModal}
          title={formatMessage({ id: 'common.task' })}
        />
      </span>
    </div>
  );
};

EmailQuickActionsCell.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  rowData: PropTypes.object.isRequired,
};

EmailQuickActionsCell.defaultProps = {};

export default injectIntl(EmailQuickActionsCell);
