import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import {
  GlobalAlertIds,
  GlobalPopupIds,
} from 'web/emailComposeBody/libs/emailComposeBodyConstants';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { createTemplate as createTemplateCall } from 'web/templates/services/templateService';
import { parseIds } from 'web/emailComposeBody/services/attachmentsService';
import { getTemplateCategories } from 'web/categories/actionCreators/categoriesTemplatesActionCreators';
import {
  closeEmailComposePopup,
  openEmailComposePopup,
  setEmailComposeAlert,
  setLoading,
  setInfoBoxState,
} from './popupActionCreators';
import { updateCreateTemplateState } from 'web/templates/actionCreators/templatesCreateActionCreators';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';

export function openSaveAsTemplate(componentId, { bcc, cc, subject }) {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(getTemplateCategories())
      .then(() => {
        dispatch(
          updateCreateTemplateState({
            bcc,
            categoryId: getState().currentCategory,
            cc,
            subject,
            id: componentId,
          })
        );
        dispatch(setInfoBoxState(componentId, ''));
        dispatch(
          openEmailComposePopup(
            GlobalPopupIds.saveAsTemplate,
            undefined,
            componentId
          )
        );
      })
      .catch(() => {
        dispatch(setEmailComposeAlert(GlobalAlertIds.openSaveAsTemplate));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function createTemplate() {
  return (dispatch, getState) => {
    const {
      emailComposeAttachmentsMap,
      emailComposeCkeBodies,
      templatesCreateTemplateState,
    } = getState();

    const {
      bcc,
      categoryId,
      cc,
      id,
      name,
      subject,
    } = templatesCreateTemplateState;
    const attachments = parseIds(emailComposeAttachmentsMap[id]);
    const body = emailComposeCkeBodies[id] || '';

    dispatch(setLoading(true));
    createTemplateCall({
      categoryId,
      name,
      subject,
      cc,
      bcc,
      body,
      attachments,
    })
      .then((templateData) => {
        dispatch({
          type: TemplatesActionTypes.allTemplates.add,
          template: templateData,
        });
        dispatch(setLoading(false));
        dispatch(closeEmailComposePopup());
        dispatch(closePopup());
        toutBackboneHelper.templateUpdate(templateData);
      })
      .catch(() => {
        dispatch(setLoading(false));
        dispatch(setEmailComposeAlert(GlobalAlertIds.createNewTemplate));
      });
  };
}
