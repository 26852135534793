import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isInvalid, submit } from 'redux-form';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  withdrawInvite,
  withdrawInvites,
  inviteUsers,
} from '../actionCreators/inviteesActionCreators';
import {
  getWithdrawInviteLoading,
  getWithdrawInvitesLoading,
  getInviteUsersPopupLoading,
  getWorkspacesLoading,
  getWorkspacesSelectOptions,
} from '../selectors/inviteesSelectors';
import { INVITE_USERS_FORM_ID } from '../libs/inviteesConstants';
import {
  allowedSeatsCount,
  usedSeatsCount,
  openSeatsCount,
} from 'web/user/selectors/userSelectors';
import InviteesPopup from '../components/popups/inviteesPopup';
import { isMarketoAdmin } from 'web/marketoSettings/selectors/adminSetupSelectors';
import {
  getTeamsSelectOptions,
  getLoading as getTeamsLoading,
} from '../../teamManagement/selectors/teamsSelector';

const InviteesPopupContainer = (props) => <InviteesPopup {...props} />;

const mapStateToProps = (state) => ({
  popup: state.popup,
  popupData: state.popupData,
  shouldShowWorkspaces: isMarketoAdmin(state),
  workspacesSelectOptions: getWorkspacesSelectOptions(state),
  teamsSelectOptions: getTeamsSelectOptions(state),
  withdrawInviteLoading: getWithdrawInviteLoading(state),
  withdrawInvitesLoading: getWithdrawInvitesLoading(state),
  workspacesLoading: getWorkspacesLoading(state),
  teamsLoading: getTeamsLoading(state),
  inviteUsersPopupLoading: getInviteUsersPopupLoading(state),
  isInvalidInviteUsersForm: isInvalid(INVITE_USERS_FORM_ID)(state),
  openSeatsCount: openSeatsCount(state),
  allowedSeatsCount: allowedSeatsCount(state),
  usedSeatsCount: usedSeatsCount(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      closePopup,
      withdrawInvite,
      withdrawInvites,
      inviteUsers,
      submitForm: submit,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteesPopupContainer);
