import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WebsiteTrackingPage from '../components/websiteTracking/websiteTrackingPage';
import { getRecentWebsiteTrackingEvents } from 'web/tracking/actionCreators/trackingActionCreators';

const WebsiteTrackingContainer = (props) => <WebsiteTrackingPage {...props} />;

WebsiteTrackingContainer.propTypes = {
  events: PropTypes.array,
  loading: PropTypes.bool,
  siteHash: PropTypes.string.isRequired,
};

WebsiteTrackingContainer.defaultProps = {
  events: [],
  loading: false,
};

const mapStateToProps = (state) => ({
  events: state.entities.tracking.websiteTracking.events,
  loading: state.entities.tracking.websiteTracking.fetching,
  siteHash: state.user.subscription.site_hash,
  user: state.user,
});

const mapDispatchToProps = {
  getRecentWebsiteTrackingEvents,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteTrackingContainer);
