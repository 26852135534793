import ActionTypes from 'table/modules/actionTypes';

export const columnOrder = (state = [], { type = '', columnsIdList }) => {
  switch (type) {
    case ActionTypes.columnOrderSetOrder:
      return columnsIdList;
    default:
      return state;
  }
};
