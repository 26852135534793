import { updateSubscriptionSettings } from 'web/user/actionCreators/userActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { uploadFile as uploadFileCall } from 'web/content/services/contentService';
import { GeneralPageAlertIds } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import { updateAdminSettings } from 'web/user/actionCreators/userActionCreators';
import { openModalManageRecordingNotice } from 'web/modals/manageRecordingNotice';
import { createRecording as createRecordingCall } from 'web/settings/adminSettings/general/services/recordingsApi';
import { setPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import { ProgressStatus } from 'libs/constantsShared';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { uploadProgress } from 'web/modals/imageInsert/actionCreators/imageInsertActionCreators';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { showSettingsAlert } from 'web/settings/adminSettings/dialer/actionCreators/dialerSettingsActionCreators.js';
import { DialerSettingsAlertIds } from 'web/settings/adminSettings/dialer/libs/dialerConstants.js';

export const updatePhoneSettingsOld = (isChecked = false, name = '') => (
  dispatch
) => {
  const params = { [name]: isChecked };
  return dispatch(updateSubscriptionSettings({ subscription: params }))
    .then(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesSaved));
    })
    .catch(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesNotSaved));
    });
};

export const updatePhoneSettings = (
  params,
  isSalesPhoneConsentAndRecordingEnabled = false
) => async (dispatch) => {
  try {
    if (!isSalesPhoneConsentAndRecordingEnabled) {
      await dispatch(
        updatePhoneSettingsOld(Object.values(params)[0], Object.keys(params)[0])
      );
    } else {
      await dispatch(updateAdminSettings({ admin_settings: { data: params } }));
    }
    dispatch(showSettingsAlert(DialerSettingsAlertIds.changesSaved));
  } catch (err) {
    dispatch(showSettingsAlert(DialerSettingsAlertIds.changesNotSaved));
  }
};

export const uploadFile = (file) => (dispatch) => {
  const getErrorKey = (keyStr) => {
    const startSliceKey = keyStr.lastIndexOf('.') + 1;
    return keyStr.slice(startSliceKey);
  };

  dispatch({
    data: {
      name: file.name,
      progress: 0,
      status: ProgressStatus.uploading,
    },
    type: ActionTypes.imageInsert.state.uploadStarting,
  });
  return uploadFileCall(file, uploadProgress(dispatch, file.name))
    .then(async (data) => {
      try {
        await createRecordingCall({ file_attachment_id: data.id });
        dispatch({
          data: {
            progress: 100,
            status: ProgressStatus.finished,
          },
          type: ActionTypes.imageInsert.state.uploadSuccess,
        });
        dispatch(closePopup());
      } catch (e) {
        const { error_key } = e;
        dispatch(setPopupAlert(GeneralPageAlertIds[getErrorKey(error_key)]));
        dispatch({
          data: {
            status: ProgressStatus.error,
          },
          type: ActionTypes.imageInsert.state.uploadProgressUpdate,
        });
      }
    })
    .catch((e) => {
      const { error_key } = e;
      dispatch(setPopupAlert(GeneralPageAlertIds[getErrorKey(error_key)]));
      dispatch({
        data: {
          status: ProgressStatus.error,
        },
        type: ActionTypes.imageInsert.state.uploadProgressUpdate,
      });
    });
};

export const openManageRecordingNotice = () => () => {
  openModalManageRecordingNotice();
};
