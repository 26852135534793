import ActionTypes from 'table/modules/actionTypes';
import trackerEvents from 'table/modules/tracker/trackerEvents';
import track from 'table/modules/tracker';

export function initSmartFilters(smartFilters) {
  return (dispatch) => {
    /* Set default values smartFilters for initial sorting and selection when allItems */
    if (Array.isArray(smartFilters)) {
      smartFilters.forEach((filter, index) => {
        if (filter && !filter.onChange && filter.selectedValue) {
          dispatch(
            onSmartFilterChange(
              index,
              filter.selectedValue,
              filter.filters,
              false
            )
          ); //eslint-disable-line no-use-before-define
        }
      });
    } else if (
      smartFilters &&
      !smartFilters.onChange &&
      smartFilters.selectedValue
    ) {
      dispatch(
        onSmartFilterChange(
          0,
          smartFilters.selectedValue,
          smartFilters.filters,
          false
        )
      ); //eslint-disable-line no-use-before-define
    }
  };
}

export function onSmartFilterChange(
  index,
  selectedValue = '',
  filters = [],
  trackChange = true
) {
  const selectedIndex = filters.findIndex(
    (filter) => filter.value === selectedValue
  );
  trackChange &&
    track(trackerEvents.smartFilterChanged, {
      filter: filters[selectedIndex].label,
      value: selectedValue,
    });
  return {
    type: ActionTypes.smartFilterSetSelectedValue,
    index,
    selectedValue,
    selectedIndex,
  };
}
