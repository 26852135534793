import isEmpty from 'lodash/isEmpty';
import { Regex } from 'web/libs/constants';

export const required = (value) => isEmpty((value || '').trim());

export const email = (value) => {
  const emailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !emailRegExp.test(value);
};

export const PhoneNumber = (value) => Regex.phone.test(value);

export const Website = (value) => Regex.website.test(value);
