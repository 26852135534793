import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors } from 'components/buttons/action';
import './commandCenterHeader.scss';

class CommandCenterHeader extends Component {
  render() {
    const {
      disabledAddTask,
      titleId,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="command-center-header">
        <div className="header-row">
          <h1>{formatMessage({ id: titleId })}</h1>
          <Button
            color={ButtonColors.blue}
            disabled={disabledAddTask}
            onClick={this.openTask}
            size="medium"
          >
            <FormattedMessage id="common.addTask" />
          </Button>
        </div>
      </div>
    );
  }

  openTask = () => {
    this.props.openTask();
  };
}

CommandCenterHeader.propTypes = {
  disabledAddTask: false,
};

CommandCenterHeader.propTypes = {
  disabledAddTask: PropTypes.bool,
  intl: intlShape.isRequired,
  openTask: PropTypes.func.isRequired,
  titleId: PropTypes.string.isRequired,
};

export default injectIntl(CommandCenterHeader);
