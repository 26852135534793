import {
  BULK_ACTIONS,
  TEMPLATE_SPECIAL_CATEGORY,
} from 'web/templates/libs/templatesConstants';
import {
  bulkUpdateSync as bulkUpdateSyncCall,
  bulkUpdateAsync as bulkUpdateAsyncCall,
} from 'web/templates/services/templatesBulkUpdateService';
import {
  closePopup,
  setPopupLoading,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  openTemplateBulkUpdateAlert,
  openTemplateUndoAlert,
} from 'web/templates/actionCreators/templatesAlertActionCreators';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { getTemplates } from 'web/templates/actionCreators/templatesFetchActionCreators';
import { setTemplateAddNewCategory } from 'web/templates/actionCreators/templateCategoriesActionCreators';
import { initializeBulkUpdate } from 'web/bulkUpdate/actionCreators/bulkUpdateProgressActionCreators';
import { track } from 'web/services/mixpanelService';
import {
  TablesEvents,
  TemplatesBulkTypes,
  TablesProperties,
  TablesSources,
} from 'web/libs/mixpanelEvents';
import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export const ASYNC_THRESHOLD = 25;

export const modalTitleIdMap = {
  [BULK_ACTIONS.archive]: 'web.templates.action.archiveTemplates',
  [BULK_ACTIONS.destroy]: 'web.templates.action.destroyTemplates',
  [BULK_ACTIONS.move]: 'web.templates.action.moveTemplates',
};

export const bulkUpdateCompleteAsync = (clearSelectedIds) => (
  dispatch,
  getState
) => {
  const {
    bulkUpdateResults: { successes },
  } = getState();
  dispatch(clearSelectedIds());
  toutBackboneHelper.templateRefetch(); // temp until react compose
  if (successes.length) {
    const notFilters = { id: successes };
    dispatch(getTemplates({ notFilters }));
  }
};

export const bulkUpdateCompleteSync = (clearSelectedIds, successes) => (
  dispatch
) => {
  dispatch(clearSelectedIds());
  toutBackboneHelper.templateRefetch(); // temp until react compose
  if (successes.length) {
    const notFilters = { id: successes };
    dispatch(getTemplates({ notFilters }));
  }
};

export const bulkUpdateAsync = (
  template_ids = [],
  bulkAction,
  updateAttributes = {},
  clearSelectedIds
) => (dispatch) => {
  dispatch(setPopupLoading(true));
  bulkUpdateAsyncCall({
    bulk_action: bulkAction,
    template_ids,
    ...updateAttributes,
  })
    .then(({ job_id: jobId }) => {
      const modalTitleId = modalTitleIdMap[bulkAction] || {};

      dispatch(setPopupLoading(false));
      dispatch(
        initializeBulkUpdate(
          jobId,
          null,
          modalTitleId,
          bulkUpdateCompleteAsync.bind(this, clearSelectedIds)
        )
      );
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(TemplateAlertIds.asyncUpdateError));
    });
};

export const bulkUpdateSync = (
  template_ids = [],
  bulkAction,
  updateAttributes = {},
  clearSelectedIds
) => (dispatch) => {
  dispatch(setPopupLoading(true));
  bulkUpdateSyncCall({
    bulk_action: bulkAction,
    template_ids,
    ...updateAttributes,
  })
    .then(({ successes, errors }) => {
      if (clearSelectedIds) {
        dispatch(bulkUpdateCompleteSync(clearSelectedIds, successes));
      }
      dispatch(closePopup());
      dispatch(
        openTemplateBulkUpdateAlert(successes, errors, updateAttributes)
      );
    })
    .catch(() => {
      dispatch(closePopup());
      dispatch(openViewAlert(TemplateAlertIds.syncUpdateError));
    });
};

const bulkUpdate = (ids = [], action, updateAttributes = {}, cb) => (
  dispatch
) => {
  const actionCreator =
    ids.length < ASYNC_THRESHOLD ? bulkUpdateSync : bulkUpdateAsync;
  dispatch(actionCreator(ids, action, updateAttributes, cb));

  const bulk = ids.length >= ASYNC_THRESHOLD;
  track(TablesEvents.bulkAction, {
    [TablesProperties.source]: TablesSources.templates,
    [TablesProperties.type]: TemplatesBulkTypes[action],
    bulk,
  });
};

export const bulkArchiveTemplate = (ids = [], cb) => (dispatch, getState) => {
  updateSetMovedTemplates(ids, dispatch, getState);
  dispatch(
    bulkUpdate(
      ids,
      BULK_ACTIONS.archive,
      {
        category: TEMPLATE_SPECIAL_CATEGORY.archived.name,
        category_id: TEMPLATE_SPECIAL_CATEGORY.archived.id,
      },
      cb
    )
  );
};

export const bulkDestroyTemplate = (ids = [], cb) => (dispatch, getState) => {
  updateSetMovedTemplates(ids, dispatch, getState);
  dispatch(
    bulkUpdate(
      ids,
      BULK_ACTIONS.destroy,
      { category: TEMPLATE_SPECIAL_CATEGORY.delete.name },
      cb
    )
  );
};

export const bulkMoveTemplate = (ids = [], categoryData, cb) => (
  dispatch,
  getState
) => {
  const { name, id } = categoryData;

  updateSetMovedTemplates(ids, dispatch, getState);
  dispatch(setTemplateAddNewCategory(categoryData));
  dispatch(
    bulkUpdate(ids, BULK_ACTIONS.move, { category: name, category_id: id }, cb)
  );
};

const updateSetMovedTemplates = (ids, dispatch, getState) => {
  const { templatesGridData } = getState();

  const templateIdMap = {};
  templatesGridData.forEach((template) => {
    templateIdMap[template.id] = template;
  });

  const templatesToMove = ids.map((id) => {
    return templateIdMap[id];
  });

  dispatch(setMovedTemplates(templatesToMove));
};

const setMovedTemplates = (movedTemplates) => ({
  movedTemplates,
  type: TemplatesActionTypes.templates.setMovedTemplates,
});

export const undoBulkMoveTemplate = () => async (dispatch, getState) => {
  const { templatesLastMoved } = getState();

  const categorySortedTemplates = {};
  const categoryNames = {};

  templatesLastMoved.forEach((template) => {
    const categoryTemplates = categorySortedTemplates[template.category_id];
    if (categoryTemplates) {
      categorySortedTemplates[template.category_id] = [
        ...categoryTemplates,
        template,
      ];
    } else {
      categorySortedTemplates[template.category_id] = [template];
    }

    categoryNames[template.category_id] = template.category;
  });

  const promises = [];
  for (let categoryId in categorySortedTemplates) {
    promises.push(
      bulkUpdateSyncCall({
        bulk_action: BULK_ACTIONS.move,
        category: categoryNames[categoryId],
        category_id: categoryId,
        template_ids: categorySortedTemplates[categoryId].map(
          (template) => template.id
        ),
      })
    );
  }

  dispatch(openViewAlert(TemplateAlertIds.processing));

  if (templatesLastMoved.length >= ASYNC_THRESHOLD) {
    dispatch(closePopup());
  }

  try {
    const responses = await Promise.all(promises);
    dispatch(getTemplates());
    let successes = [];
    let errors = [];
    responses.forEach((res) => {
      successes = successes.concat(res.successes);
      errors = errors.concat(res.errors);
    });

    dispatch(openTemplateUndoAlert(successes, errors));
    dispatch({ type: TemplatesActionTypes.templates.resetMovedTemplates });
  } catch (e) {
    dispatch(openViewAlert(TemplateAlertIds.syncUpdateError));
  }
};
