import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { Regex } from 'web/libs/constants';

export const getDynamicFieldsFromText = (text) => {
  if (!text) {
    return [];
  }

  const matches = text.match(Regex.dynamicField);
  if (matches == null) {
    return [];
  }

  return matches.map((str) => str.replace(/^{{[0-9]*\.?[0-9]+_/g, '{{#_'));
};

/**
 * Parse text and get incorrect dynamic fields
 * @param text String
 * @param dynamicFieldsActual Array[String]
 */
export const getIncorrectDynamicFields = (text, dynamicFieldsActual) => {
  let textDynamicFields = getDynamicFieldsFromText(text);
  textDynamicFields = uniq(textDynamicFields);
  return difference(textDynamicFields, dynamicFieldsActual);
};

export const getDynamicFieldsAsArray = (dynamicFields) =>
  dynamicFields.map((field) => field.value);

export const getNotFilledPrompt = (text) => {
  if (!text) {
    return [];
  }
  const notFilledPrompts = text.match(Regex.dynamicFieldsPrompt);
  return notFilledPrompts || [];
};
