import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import OptionDotaInfobox, { OptionShape } from 'components/optionDotas/infobox';
import './slideOutPaneActionBar.scss';

class SlideOutPaneActionBar extends Component {
  constructor() {
    super();
    this.optionsTriggerRef = null;
  }

  render() {
    const { buttons, className, onBack, options } = this.props;

    return (
      <div className={classNames('slide-out-pane-action-bar', className)}>
        {onBack && (
          <Button
            color={ButtonColors.blue}
            key="page-header-back"
            onClick={onBack}
            size={ButtonSizes.standard}
          >
            <FormattedMessage id="common.back" />
          </Button>
        )}
        <div className="slide-out-pane-action-bar-right">
          {buttons.map(
            (
              {
                onClick: ignored,
                text: buttonText,
                textId: buttonTextId,
                ...other
              },
              index
            ) => (
              <Button
                {...other}
                onClick={this.onClick}
                className="margin-left-half"
                data-index={index}
                key={`page-header-${buttonText || buttonTextId}`}
                size={ButtonSizes.standard}
              >
                {(buttonTextId && <FormattedMessage id={buttonTextId} />) ||
                  buttonText}
              </Button>
            )
          )}
          {options.length > 0 && <OptionDotaInfobox options={options} />}
        </div>
      </div>
    );
  }

  onClick = (e) => {
    const index = e.target.dataset.index || e.currentTarget.dataset.index;
    this.props.buttons[index].onClick();
  };
}

export const SlideOutPaneActionBarButtonShape = PropTypes.shape({
  color: PropTypes.oneOf(Object.values(ButtonColors)),
  onClick: PropTypes.func,
  text: PropTypes.string,
  textId: PropTypes.string,
});

SlideOutPaneActionBar.propTypes = {
  buttons: PropTypes.arrayOf(SlideOutPaneActionBarButtonShape),
  className: PropTypes.string,
  onBack: PropTypes.func,
  options: PropTypes.arrayOf(OptionShape),
};

SlideOutPaneActionBar.defaultProps = {
  buttons: [],
  className: '',
  onBack: undefined,
  options: [],
};
export default SlideOutPaneActionBar;
