import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Autocomplete from 'components/inputs/autocomplete';
import './peopleSmartFilter.scss';

const PeopleSmartFilter = ({
  actionCreators: {
    onAutocompleteLeaving,
    onPeopleAutocompleteChange,
    onPersonSelect,
  },
  autocompleteLoading,
  autocompleteResults,
  autocompleteValue,
  intl: { formatMessage },
}) => (
  <div className="people-smart-filter">
    <div className="people-filter-label">
      <FormattedMessage id="common.byPerson" />
    </div>
    <Autocomplete
      autoFocus={false}
      loading={autocompleteLoading}
      onChange={onPeopleAutocompleteChange}
      onLeaving={onAutocompleteLeaving}
      onSelection={onPersonSelect}
      placeholder={formatMessage({
        id: 'web.commandCenter.advancedSearch.byPersonPlaceholder',
      })}
      results={autocompleteResults}
      value={autocompleteValue}
    />
  </div>
);

PeopleSmartFilter.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  autocompleteLoading: PropTypes.bool.isRequired,
  autocompleteResults: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PeopleSmartFilter);
