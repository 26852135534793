import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { SalesforceType } from '../../../libs/salesforceConstants';
import './connectConfirmationPopup.scss';

const ConnectConfirmationPopup = (props) => {
  const {
    actionCreators: { closePopup, connectSalesforce },
    popupData: { type, disconnect },
  } = props;

  return (
    <Popup
      className="salesforce-connect-confirmation-popup"
      footer={{
        onPrimary: () => {
          connectSalesforce(type, disconnect);
        },
        onSecondary: closePopup,
        primaryTextId: 'common.ok',
        secondaryTextId: 'common.back',
      }}
      header={{
        onClose: closePopup,
        textId: 'web.salesforce.connectionCard.connectConfirmation.title',
      }}
      onOutsideClick={closePopup}
      size={PopupSize.small}
    >
      <Fragment>
        <p>
          <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.inOrderToProvide" />
        </p>
        <div>
          <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.thesePermissions" />
          <ul className="permissions-list">
            <li>
              <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.permissionList.accessToBasicInfo" />
            </li>
            <li>
              <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.permissionList.manageChatter" />
            </li>
            <li>
              <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.permissionList.dataViaWeb" />
            </li>
            <li>
              <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.permissionList.performRequests" />
            </li>
          </ul>
        </div>
        <p>
          <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.currentlyLoggedIn" />
        </p>
        <p>
          <FormattedHTMLMessage id="web.salesforce.connectionCard.connectConfirmation.clickOk" />
        </p>
      </Fragment>
    </Popup>
  );
};

ConnectConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popupData: PropTypes.shape({
    type: PropTypes.oneOf([
      SalesforceType.salesforce,
      SalesforceType.salesforceSandbox,
    ]),
  }).isRequired,
};

export default ConnectConfirmationPopup;
