import get from 'lodash/get';

const sortColumn = (columns, sortBy, dir) => {
  const currentColumns = [...columns];

  currentColumns.sort(function(a, b) {
    const propValueA = get(a, sortBy);
    const propValueB = get(b, sortBy);

    const nameA =
      typeof propValueA === 'string' ? propValueA.toUpperCase() : propValueA;
    const nameB =
      typeof propValueB === 'string' ? propValueB.toUpperCase() : propValueB;

    let comparison = 0;

    if (nameA > nameB) {
      comparison = dir === 'ASCENDING' ? 1 : -1;
    } else if (nameA < nameB) {
      comparison = dir === 'ASCENDING' ? -1 : 1;
    }

    return comparison;
  });

  return currentColumns;
};

export { sortColumn };
