import isEqual from 'lodash/isEqual';
import intersectionWith from 'lodash/intersectionWith';
import differenceWith from 'lodash/differenceWith';
import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';
import {
  AddressTypes,
  PersonDetailsCards,
  PersonDetailsTabAlertIds,
  SalesforceTypes,
} from '../libs/personDetailsConstants';
import { NotableTypes } from 'web/notes/libs/notesConstants';
import {
  getCustomFieldsFromEditState,
  getGroupsFromEditState,
  getInfoFromEditState,
} from '../helpers/personDetailsEditStateParsers';
import {
  addToSalesforce as addToSalesforceCall,
  getMarketoCampaigns,
  updatePerson,
} from 'web/people/services/peopleService';
import { getPerson } from '../selectors/personDetailsSelectors';
import {
  bulkAddToGroups,
  bulkRemoveFromGroups,
} from 'web/groups/services/groupsService';
import {
  createNote as createNoteCall,
  updateNote,
} from 'web/notes/services/notesService';
import { getGroups } from 'web/groups/actionCreators/groupsActionCreators';
import { clearPersonDetails } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import {
  saveCompliance as saveComplianceCall,
  updateCompliance,
} from 'web/compliance/services/complianceService';
import {
  closeCardEditing,
  openCardEditing,
  getPersonById,
  setTabAlert,
  setTabLoading,
} from './personDetailsActionCreators';
import { closeAddContact } from 'web/slideOuts/addContact/actionCreators/addContactSlideOutActionCreators';
import {
  formatCompliancePostData,
  parseSalesforceAccountInfo,
} from '../helpers/personDetailsParsers';
import {
  getInvalidAddressAlert,
  getInvalidCustomFieldAlert,
  isMissingRequiredFields,
  getMissingSalesforceFields,
  getMissingSalesforceFieldsFromEditState,
  pdvValidation,
  getInvalidApiKeysAlert,
} from '../helpers/personDetailsValidations';
import {
  validateAndCreate,
  ContactDuplicateError,
  ContactValidationError,
  isContactExists,
} from '../helpers/addPersonHelper';
import {
  getPrimaryAddress,
  validateCustomFields,
} from '../helpers/personHelpers';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import {
  PersonDetailsEvents,
  PersonDetailsProperties,
  PersonDetailsPropertyTypes,
} from 'web/libs/mixpanelEvents';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { track } from 'web/services/mixpanelService';

export const saveInfo = () => async (dispatch, getState) => {
  const state = getState();
  if (
    isMissingRequiredFields(
      PersonDetailsCards.info,
      state.personDetailsInfoEditState
    )
  ) {
    dispatch(setTabAlert(PersonDetailsTabAlertIds.missingRequiredFields));
    return;
  }

  const requiredFields = getMissingSalesforceFieldsFromEditState(state);
  if (Object.keys(requiredFields).length) {
    dispatch(
      setTabAlert(PersonDetailsTabAlertIds.missingRequiredSalesforceFields)
    );
    return;
  }

  const personAPI = getInfoFromEditState(state);
  try {
    await isContactExists(personAPI, state.user.id);
  } catch (e) {
    dispatch(setTabAlert(PersonDetailsTabAlertIds.duplicateContact));
    return;
  }

  dispatch(setTabLoading(true));

  updatePerson(personAPI.id, personAPI)
    .then((person) => {
      dispatch({
        type: PersonDetailsActionTypes.setPerson,
        person,
      });
      dispatch(closeCardEditing(PersonDetailsCards.info));
      dispatch({
        type: PersonDetailsActionTypes.setSalesforceRequiredFields,
        requiredFields,
      });
      track(PersonDetailsEvents.contact, {
        [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.save,
      });
    })
    .catch(() => {
      const returnedElements = pdvValidation(personAPI);
      if (returnedElements && returnedElements.length > 0) {
        dispatch(setTabAlert(...returnedElements));
      } else {
        dispatch(setTabAlert(PersonDetailsTabAlertIds.savePersonFail));
      }
    })
    .then(() => {
      dispatch(setTabLoading(false));
    });
};

export const displayAlertInvalidMessage = (invalidEmail) => (dispatch) => {
  dispatch(setTabAlert(...invalidEmail));
};
export const addInfoRow = (addressType) => ({
  type: PersonDetailsActionTypes.addInfoRow,
  addressType,
});

export const removeInfoRow = (addressType, index) => ({
  type: PersonDetailsActionTypes.removeInfoRow,
  addressType,
  index,
});

export const updateInfoEditStateContact = (edits) => ({
  type: PersonDetailsActionTypes.updateInfoEditStateContact,
  edits,
});

export const updateInfoEditStateAddresses = (edits, addressType, index) => ({
  type: PersonDetailsActionTypes.updateInfoEditStateAddresses,
  addressType,
  edits,
  index,
});

export const updateInfoEditStateSetPrimary = (primaryIndex, addressType) => ({
  type: PersonDetailsActionTypes.updateInfoEditStateSetPrimary,
  addressType,
  primaryIndex,
});

export const updateInfoEditStateSocial = (addressType, value) => ({
  type: PersonDetailsActionTypes.updateInfoEditStateSocial,
  addressType,
  value,
});

export const updateInfoApiKeys = (keyType, value, index) => ({
  type: PersonDetailsActionTypes.updateInfoEditApiKeys,
  keyType,
  value,
  index,
});

/* temp until People Grid Reacted */
const notifyPeopleGrid = (removals = []) => {
  removals.forEach((groupMember) => {
    toutBackboneHelper.personRemoveFromGroup(groupMember);
  });
};

const onValidateAndCreateError = (error, dispatch) => {
  if (error instanceof ContactDuplicateError) {
    dispatch(setTabAlert(PersonDetailsTabAlertIds.duplicateContact));
  } else if (error instanceof ContactValidationError) {
    dispatch(setTabAlert(error.alertId, error.textValue));
  } else {
    // server + default errors
    dispatch(setTabAlert(PersonDetailsTabAlertIds.createPersonFail));
  }
};

export const addPerson = () => (dispatch, getState) => {
  dispatch(setTabLoading(true));
  validateAndCreate(dispatch, getState)
    .then(() => {
      dispatch(closeAddContact());
      dispatch(openViewAlert(PeopleAlertIds.addPersonSuccess));
    })
    .catch((error) => {
      onValidateAndCreateError(error, dispatch);
    })
    .finally(() => dispatch(setTabLoading(false)));
};

export const createPersonAndAddNew = () => (dispatch, getState) => {
  dispatch(setTabLoading(true));
  validateAndCreate(dispatch, getState)
    .then(() => {
      // clear personDetails state
      dispatch(clearPersonDetails());
      // reopen card to clear it's edit state
      dispatch(openCardEditing(PersonDetailsCards.info));
      dispatch(closeCardEditing(PersonDetailsCards.groups));
      dispatch(closeCardEditing(PersonDetailsCards.customFields));
      dispatch(openViewAlert(PeopleAlertIds.addPersonSuccess));
    })
    .catch((error) => {
      onValidateAndCreateError(error, dispatch);
    })
    .finally(() => dispatch(setTabLoading(false)));
};

export const saveGroups = () => (dispatch, getState) => {
  const {
    personDetailsGroupsEditState: { groupsByAddressId: editStateGroups },
    personDetailsOriginalEditState: {
      [PersonDetailsCards.groups]: { groupsByAddressId: originalGroups },
    },
    personDetailsPerson: { id: personId },
  } = getState();

  dispatch(setTabLoading(true));

  const current = getGroupsFromEditState(editStateGroups);
  const original = getGroupsFromEditState(originalGroups);

  const unchanged = intersectionWith(current, original, isEqual);
  const additions = differenceWith(current, unchanged, isEqual);
  const removals = differenceWith(original, unchanged, isEqual);

  const promises = [];

  if (additions.length) {
    promises.push(bulkAddToGroups(additions));
  }

  if (removals.length) {
    promises.push(bulkRemoveFromGroups(removals));
  }

  Promise.all(promises)
    .then(() => {
      dispatch(getGroups(true));
      notifyPeopleGrid(removals);

      return dispatch(getPersonById(personId));
    })
    .then(() => {
      track(PersonDetailsEvents.groups, {
        [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.save,
      });
      dispatch(closeCardEditing(PersonDetailsCards.groups));
    })
    .catch(() => {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.saveGroupsFail));
    })
    .then(() => {
      dispatch(setTabLoading(false));
    });
};

export const updateGroupsEditState = (edits) => (dispatch) => {
  dispatch({ type: PersonDetailsActionTypes.updateGroupsEditState, edits });
  track(PersonDetailsEvents.groups, {
    [PersonDetailsProperties.actionType]:
      PersonDetailsPropertyTypes.addressChanged,
  });
};

export const updateGroupsEditStateAdd = (selectedEmailValue, group) => ({
  type: PersonDetailsActionTypes.updateGroupsEditStateAdd,
  group,
  selectedEmailValue,
});

export const setGroupEditState = (selectedEmailValue, group) => ({
  type: PersonDetailsActionTypes.setGroupEditState,
  group,
  selectedEmailValue,
});

export const setAddressesEditState = (addresses) => ({
  type: PersonDetailsActionTypes.setAddressesEditState,
  addresses,
});

export const updatePersonGroups = (groups) => ({
  type: PersonDetailsActionTypes.updateGroups,
  groups,
});

export const updatePersonAddresses = (addresses) => ({
  type: PersonDetailsActionTypes.updateAddresses,
  addresses,
});

export const updateGroupsEditStateRemove = (selectedEmailValue, index) => ({
  type: PersonDetailsActionTypes.updateGroupsEditStateRemove,
  index,
  selectedEmailValue,
});

export const updateGroupsEditStateRemoveAll = () => ({
  type: PersonDetailsActionTypes.updateGroupsEditStateRemoveAll,
});

export const updateSalesforceType = (salesforceType) => (
  dispatch,
  getState
) => {
  const { company, last_name: lastName } = getPerson(getState());
  const requiredFields = getMissingSalesforceFields(
    company,
    lastName,
    salesforceType
  );

  dispatch({
    type: PersonDetailsActionTypes.updatesalesforceType,
    salesforceType,
  });
  dispatch({
    type: PersonDetailsActionTypes.setSalesforceRequiredFields,
    requiredFields,
  });

  if (Object.keys(requiredFields).length) {
    dispatch(
      setTabAlert(PersonDetailsTabAlertIds.missingRequiredSalesforceFields)
    );
    dispatch(openCardEditing(PersonDetailsCards.info));
  }
};

export const addToSalesforce = () => (dispatch, getState) => {
  const state = getState();
  const person = getPerson(state);
  const {
    personDetailsSalesforceConnectionData: { userId },
    personDetailsAccountInfoEditState: { salesforceType },
  } = getState();

  dispatch(setTabLoading(true));
  const sobject = {
    Email: getPrimaryAddress(person.addresses).address,
    FirstName: person.first_name,
    LastName: person.last_name,
    OwnerId: userId,
    Phone: getPrimaryAddress(person.addresses, AddressTypes.phone).address,
    Title: person.title,
  };
  if (salesforceType === SalesforceTypes.lead && person.company) {
    sobject.Company = person.company.name;
  }
  return addToSalesforceCall(salesforceType, { sobject })
    .then((response) => {
      if (Array.isArray(response)) {
        throw response[0].message;
      }
      dispatch({
        type: PersonDetailsActionTypes.setPersonSalesforceId,
        salesforceType,
        salesforceId: response.id,
      });
      dispatch({
        type: PersonDetailsActionTypes.accountInfo,
        accountInfo: parseSalesforceAccountInfo({ CreatedDate: Date.now() }),
      });
      track(PersonDetailsEvents.accountInfo, {
        [PersonDetailsProperties.actionType]:
          PersonDetailsPropertyTypes.addToSF,
        [PersonDetailsProperties.type]: SalesforceTypes.lead
          ? PersonDetailsPropertyTypes.lead
          : PersonDetailsPropertyTypes.contact,
      });
    })
    .catch((errorMessage) => {
      switch (errorMessage) {
        case PersonDetailsTabAlertIds.addToSalesforceOptOutFieldError:
          dispatch(
            setTabAlert(
              PersonDetailsTabAlertIds.addToSalesforceOptOutFieldError
            )
          );
          break;
        case PersonDetailsTabAlertIds.addToSalesforceDuplicatedFieldError:
          dispatch(
            setTabAlert(
              PersonDetailsTabAlertIds.addToSalesforceDuplicatedFieldError
            )
          );
          break;
        case PersonDetailsTabAlertIds.addToSalesforceNoOwnership:
          dispatch(
            setTabAlert(PersonDetailsTabAlertIds.addToSalesforceNoOwnership)
          );
          break;
        default:
          dispatch(setTabAlert(PersonDetailsTabAlertIds.addToSalesforceFail));
      }
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
};

export const saveCustomFields = () => (dispatch, getState) => {
  const personAPI = getCustomFieldsFromEditState(getState());
  const { personDetailsCustomFieldsEditState } = getState();
  const validCustomFieldsAlert = validateCustomFields(
    personDetailsCustomFieldsEditState
  );
  if (!validCustomFieldsAlert) {
    dispatch(setTabLoading(true));
    updatePerson(personAPI.id, personAPI)
      .then((person) => {
        dispatch({
          type: PersonDetailsActionTypes.setPerson,
          person,
        });
        track(PersonDetailsEvents.customFields, {
          [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.save,
        });
        dispatch(closeCardEditing(PersonDetailsCards.customFields));
      })
      .catch(() => {
        dispatch(setTabAlert(PersonDetailsTabAlertIds.savePersonFail));
      })
      .finally(() => {
        dispatch(setTabLoading(false));
      });
  } else {
    dispatch(setTabAlert(...validCustomFieldsAlert));
  }
};

export const addCustomFieldsRow = () => ({
  type: PersonDetailsActionTypes.addCustomFieldsRow,
});

export const removeCustomFieldsRow = (index) => ({
  type: PersonDetailsActionTypes.removeCustomFieldsRow,
  index,
});

export const updateCustomFieldsEditState = (index, edits) => ({
  type: PersonDetailsActionTypes.updateCustomFieldsEditState,
  index,
  edits,
});

export const validateAddress = (type, value, phoneDetails) => (dispatch) => {
  const alert = getInvalidAddressAlert(type, value, phoneDetails);
  if (alert) {
    dispatch(setTabAlert(...alert));
  }
};

export const validateApiKeys = (type, value) => (dispatch) => {
  const alert = getInvalidApiKeysAlert(type, value);
  if (alert) {
    dispatch(setTabAlert(...alert));
  }
};

export const validateCustomField = (name, value) => (dispatch, getState) => {
  const alert = getInvalidCustomFieldAlert(
    name,
    value,
    getState().personDetailsCustomFieldsEditState
  );
  if (alert) {
    dispatch(setTabAlert(...alert));
  }
};

const notesService = (apiPromise, dispatch, isNew = false) => {
  dispatch(setTabLoading(true));
  apiPromise
    .then((note) => {
      dispatch({ type: PersonDetailsActionTypes.updateNote, note });
      track(PersonDetailsEvents.notes, {
        [PersonDetailsProperties.actionType]: isNew
          ? PersonDetailsPropertyTypes.add
          : PersonDetailsPropertyTypes.update,
      });
    })
    .catch(() => {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.saveNoteFail));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
};

export const createNote = (content) => (dispatch, getState) => {
  notesService(
    createNoteCall(
      content,
      getState().personDetailsPerson.id,
      NotableTypes.person
    ),
    dispatch,
    true
  );
};

export const editNote = (content, id) => (dispatch) => {
  notesService(updateNote(id, { content }), dispatch);
};

export const getMarketoCampaignsByPersonId = (id) => (dispatch) => {
  getMarketoCampaigns(id).then((marketoCampaigns) => {
    dispatch({
      type: PersonDetailsActionTypes.setMarketoCampaign,
      marketoCampaigns,
    });
  });
};

export const updateComplianceEditState = (edits) => ({
  type: PersonDetailsActionTypes.updateComplianceEditState,
  edits,
});

export const saveCompliance = () => (dispatch, getState) => {
  const {
    personDetailsComplianceEditState,
    personDetailsPerson: {
      id: personId,
      complianceDetails: { id: complianceId },
    },
  } = getState();

  const complianceInfo = formatCompliancePostData(
    personDetailsComplianceEditState
  );

  const complianceServiceCall = complianceId
    ? updateCompliance
    : saveComplianceCall;

  dispatch(setTabLoading(true));

  complianceServiceCall(personId, complianceInfo, complianceId)
    .then(() => dispatch(getPersonById(personId)))
    .then(() => {
      dispatch(closeCardEditing(PersonDetailsCards.compliance));
    })
    .catch(() => {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.saveComplianceFail));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
};
