import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PeopleRow = ({ className, personCell, statusCell, isInvalid }) => {
  const classes = classNames(
    'people-list-row',
    { 'invalid-person-row': isInvalid },
    className
  );
  return (
    <div className={classes}>
      {personCell}
      <div className="people-list-status">
        <div className="people-list-status-wording">{statusCell}</div>
      </div>
    </div>
  );
};

PeopleRow.propTypes = {
  className: PropTypes.string,
  isInvalid: PropTypes.bool,
  personCell: PropTypes.element.isRequired,
  statusCell: PropTypes.element.isRequired,
};

PeopleRow.defaultProps = {
  className: '',
  isInvalid: false,
};

export default PeopleRow;
