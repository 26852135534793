import DomainBlocksActionTypes from '../libs/domainBlocksActionTypes';
import {
  getDomainBlocks as getDomainBlocksCall,
  postDomainBlock as postDomainBlockCall,
  destroyDomainBlock as destroyDomainBlockCall,
} from '../services/domainBlocksApi';
import { validateDomainBlock } from 'web/settings/adminSettings/general/helpers/generalPageHelpers';

function showLoading() {
  return { type: DomainBlocksActionTypes.showLoading };
}

function hideLoading() {
  return { type: DomainBlocksActionTypes.hideLoading };
}

function addAllDomainBlocks(data) {
  return { type: DomainBlocksActionTypes.addAll, data };
}

function addOneDomainBlock(data) {
  return { type: DomainBlocksActionTypes.addOne, data };
}

function removeOneDomainBlock(data) {
  return { type: DomainBlocksActionTypes.removeOne, data };
}

function showErrorMessage(data) {
  return { type: DomainBlocksActionTypes.showError, data };
}

export function hideErrorMessage() {
  return { type: DomainBlocksActionTypes.hideError };
}

export function setNewDomainBlock(data) {
  return { type: DomainBlocksActionTypes.setNewDomainBlock, data };
}

export function clearNewDomainBlock() {
  return { type: DomainBlocksActionTypes.clearNewDomainBlock };
}

export function getDomainBlocks(data) {
  return (dispatch) => {
    dispatch(showLoading());

    getDomainBlocksCall(data)
      .then((blockedDomains) => {
        dispatch(addAllDomainBlocks(blockedDomains));
      })
      .catch((err) => {
        dispatch(showErrorMessage(err.response.body));
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
}

export function postDomainBlock() {
  return (dispatch, getState) => {
    const {
      newDomainBlock: domain,
      user: {
        id,
        subscription: { id: subscriptionId },
      },
    } = getState();

    const payload = {
      user_id: id,
      subscription_id: subscriptionId,
      domain,
    };

    dispatch(hideErrorMessage());
    dispatch(showLoading());

    const error = validateDomainBlock(domain);
    if (error.msg) {
      dispatch(showErrorMessage(error.msg));
      dispatch(hideLoading());
    } else {
      postDomainBlockCall(payload)
        .then((blockedDomain) => {
          dispatch(clearNewDomainBlock());
          dispatch(addOneDomainBlock(blockedDomain));
        })
        .catch((err) => {
          dispatch(showErrorMessage(err.response.body));
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };
}

export function destroyDomainBlock(data) {
  return (dispatch) => {
    dispatch(hideErrorMessage());
    dispatch(showLoading());

    destroyDomainBlockCall(data)
      .then(() => {
        dispatch(removeOneDomainBlock(data.id));
      })
      .catch((err) => {
        dispatch(showErrorMessage(err.response.body));
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
}

export function resetDomainBlocksSearch() {
  return {
    type: DomainBlocksActionTypes.clearSearchDomainBlock,
  };
}

export function setSearchDomain(value) {
  return {
    type: DomainBlocksActionTypes.domainStateSearchChange,
    value,
  };
}

export function handleSearchDomainChange(value) {
  return (dispatch) => {
    const params = { domain_search: value };
    dispatch(setSearchDomain(value));
    dispatch(getDomainBlocks(params));
  };
}
