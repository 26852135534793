import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Radio from 'components/buttons/radio';
import { EmailProviders } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import classNames from 'classnames';

import './emailConnectionSelection.scss';

class EmailConnectionSelection extends Component {
  render() {
    const {
      emailConnectionProvider,
      onClickEvent,
      intl: { formatMessage },
    } = this.props;

    const gmailLabelClass = classNames(
      'email-connection-selection-radio',
      'gmail-radio',
      {
        'radio-checked': emailConnectionProvider === EmailProviders.gmail,
      }
    );
    const outlookLabelClass = classNames(
      'email-connection-selection-radio',
      'outlook-radio',
      {
        'radio-checked': emailConnectionProvider === EmailProviders.outlook,
      }
    );

    return (
      <div className="email-connection-selection">
        <Radio
          checked={emailConnectionProvider === EmailProviders.gmail}
          className={gmailLabelClass}
          label={formatMessage({
            id: 'web.settings.emailConnection.modal.useGmail',
          })}
          name="email-connection-selection"
          onChange={this.onChange}
          onClickEvent={onClickEvent}
          value={EmailProviders.gmail}
        />
        <Radio
          checked={emailConnectionProvider === EmailProviders.outlook}
          className={outlookLabelClass}
          label={formatMessage({
            id: 'web.settings.emailConnection.modal.useOutlook',
          })}
          name="email-connection-selection"
          onChange={this.onChange}
          onClickEvent={onClickEvent}
          value={EmailProviders.outlook}
        />
      </div>
    );
  }

  onChange = (currentSelection) => {
    const { setEmailProvider } = this.props;

    setEmailProvider(currentSelection);
    this.props.onClickEvent(currentSelection);
  };

  static propTypes = {
    emailConnectionProvider: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onClickEvent: PropTypes.func.isRequired,
    setEmailProvider: PropTypes.func.isRequired,
  };
}

export default injectIntl(EmailConnectionSelection);
