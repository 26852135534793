import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Alert, { AlertColors } from 'components/alert';
import { GlobalAlertIds } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';

class CampaignsAlert extends Component {
  render() {
    return <div>{this._getAlertComponent()}</div>;
  }

  /*eslint complexity: [1, 30]*/
  _getAlertComponent = () => {
    const { actionCreators, campaignAlert } = this.props;
    const defaultProperties = {
      color: AlertColors.redDark,
      location: 'top',
      onClose: actionCreators.closeCampaignAlert,
    };

    switch (campaignAlert.id) {
      case GlobalAlertIds.launchCampaignNoPeople:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.campaignAlert.launchNoPeople"
          />
        );
      case GlobalAlertIds.launchCampaignNoSteps:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.campaignAlert.launchNoSteps"
          />
        );
      case GlobalAlertIds.campaignNameUniqueAlert:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaignsTab.createNewCampaign.failed"
          />
        );
      case GlobalAlertIds.launchCampaignIssue:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.launchCampaignIssue"
          />
        );
      case GlobalAlertIds.cloneCampaignSuccess:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.green}
            textId="web.campaigns.cloneSuccess"
          />
        );
      case GlobalAlertIds.cloneCampaignError:
        return (
          <Alert {...defaultProperties} textId="web.campaigns.cloneError" />
        );
      case GlobalAlertIds.moveCampaignSuccess:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.greenDark}
            textId="web.campaigns.moveSuccess"
          />
        );
      case GlobalAlertIds.campaignDayLimit:
        return (
          <Alert html {...defaultProperties} textId="web.campaigns.dayLimit" />
        );
      case GlobalAlertIds.removePeopleError:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.yellow}
            textId="web.campaigns.removePeopleError"
            textValues={{
              failures: campaignAlert.values.failures.length,
              successes: campaignAlert.values.successes.length,
            }}
          />
        );
      case GlobalAlertIds.removePeopleFailed:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.removePeopleFailed"
          />
        );
      case GlobalAlertIds.removePeopleSuccess:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.greenDark}
            textId="web.campaigns.removePeopleSuccess"
            textValues={{ successes: campaignAlert.values.successes.length }}
          />
        );
      case GlobalAlertIds.addPeopleCallSuccess:
        // just adding hooks to full text values so they aren't removed from language files: 'web.campaigns.campaignAlert.addPeopleWarning' 'web.campaigns.campaignAlert.addPeopleSuccess'
        return (
          <Alert
            {...defaultProperties}
            color={
              campaignAlert.values.people > 0
                ? AlertColors.greenDark
                : AlertColors.yellow
            }
            textId={`web.campaigns.campaignAlert.addPeople${
              campaignAlert.values.people > 0 ? 'Success' : 'Warning'
            }`}
            textValues={{
              dupes: campaignAlert.values.dupes,
              people: campaignAlert.values.people,
            }}
          />
        );
      case GlobalAlertIds.campaignTemplateErrors:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.campaignTemplateErrors"
            textValues={campaignAlert.values}
          />
        );
      case GlobalAlertIds.campaignTemplateErrorsEmails:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.campaignTemplateErrorsEmails"
            textValues={campaignAlert.values}
            html
          />
        );
      case GlobalAlertIds.settingsError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.campaignAlert.settingsError"
          />
        );
      case GlobalAlertIds.settingsSuccess:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.greenDark}
            textId="web.campaigns.campaignAlert.settingsSuccess"
          />
        );
      case GlobalAlertIds.shareCampaignSuccess:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.greenDark}
            textId="web.campaigns.campaignAlert.shareSuccess"
          />
        );
      case GlobalAlertIds.shareCampaignError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.campaignAlert.shareError"
          />
        );
      case GlobalAlertIds.newDayNumberRequired:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.yellow}
            textId="web.campaigns.campaignAlert.newDayNumberRequired"
          />
        );
      case GlobalAlertIds.editLiveCampaignWarning:
        return (
          <Alert
            html
            {...defaultProperties}
            action={actionCreators.openEditWarningPopup}
            actionLabel="common.details"
            color={AlertColors.blueDark}
            disableClick={false}
            textId="web.campaigns.editingLiveCampaignAlert"
            manual
          />
        );
      case GlobalAlertIds.renameCategoryError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.campaigns.campaignAlert.renameCategoryError"
            textValues={campaignAlert.values}
          />
        );
      default:
        return null;
    }
  };
}

CampaignsAlert.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  campaignAlert: PropTypes.object.isRequired,
};

export default CampaignsAlert;
