import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import CampaignStepIcon from '../../campaignStepIcon';
import CampaignStepInfo from '../campaignStepInfo';
import { canEditCampaign } from 'web/campaigns/campaignsTab/services/campaignService';
import CampaignStepMetrics from 'web/campaigns/campaignsTab/components/campaignDayStep/campaignStepMetrics';
import './campaignDayStepHeaderWide.scss';

const CampaignDayStepHeaderWide = ({
  deleteStep,
  editStep,
  intl: { formatMessage },
  readableAction,
  selectedCampaign,
  step,
  timezones,
  userCanEditCampaigns,
}) => (
  <div className="campaign-day-step-container">
    <div className="campaignDayStepHeaderWide">
      <div className="campaign-day-step-header-title campaign-day-step-header-col text-overflow">
        <CampaignStepIcon action={step.action} preventHover outline />
        <span className="campaign-day-step-header-title-action">
          {readableAction}
        </span>
      </div>
      <div className="campaign-day-step-header-col campaign-day-step-header-name text-overflow">
        {step.name}
      </div>
      <div className="campaign-day-step-header-schedule campaign-day-step-header-col">
        <CampaignStepInfo
          className="campaign-day-step-header-schedule-wide"
          rightIcon
          step={step}
          timezones={timezones}
          outline
        />
      </div>
      <div className="campaign-day-step-header-actions campaign-day-step-header-col">
        {userCanEditCampaigns && canEditCampaign(selectedCampaign) ? (
          <span>
            <a onClick={editStep} className="">
              {formatMessage({ id: 'common.edit' })}
            </a>
            <span className="campaign-day-step-header-actions-divider">
              {'|'}
            </span>
            <a onClick={deleteStep} className="">
              {formatMessage({ id: 'common.delete' })}
            </a>
          </span>
        ) : (
          <a onClick={editStep} className="">
            {formatMessage({ id: 'common.preview' })}
          </a>
        )}
      </div>
    </div>
    <div className="campaignDayStepHeaderWide day-step-metrics">
      <div className="metrics-label">
        <FormattedMessage id="web.campaigns.stepMetrics" />
      </div>
      <CampaignStepMetrics selectedCampaign={selectedCampaign} step={step} />
    </div>
  </div>
);

CampaignDayStepHeaderWide.propTypes = {
  selectedCampaign: PropTypes.object.isRequired,
  readableAction: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  deleteStep: PropTypes.func.isRequired,
  editStep: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignDayStepHeaderWide.defaultProps = {
  userCanEditCampaigns: true,
};

export default injectIntl(CampaignDayStepHeaderWide);
