export default {
  addRecommendedRow: 'EDIT_TEMPLATE_SLIDE_OUT_ADD_RECOMMENDED_ROW',
  addTemplateNote: 'EDIT_TEMPLATE_SLIDE_OUT_ADD_TEMPLATE_NOTE',
  clearTabAlert: 'EDIT_TEMPLATE_SLIDE_OUT_CLEAR_TAB_ALERT',
  close: 'EDIT_TEMPLATE_SLIDE_OUT_CLOSE',
  closeCardEditing: 'EDIT_TEMPLATE_SLIDE_OUT_CLOSE_CARD_EDITING',
  closeCardEditingAll: 'EDIT_TEMPLATE_SLIDE_OUT_CLOSE_CARD_EDITING_ALL',
  deleteTemplateNote: 'EDIT_TEMPLATE_SLIDE_OUT_DELETE_TEMPLATE_NOTE',
  getTemplateDetailsFailure:
    'EDIT_TEMPLATE_SLIDE_OUT_GET_TEMPLATE_DETAILS_FAILURE',
  initAnalyticsView: 'EDIT_TEMPLATE_SLIDE_OUT_INIT_ANALYTICS_VIEW',
  initEmailsView: 'EDIT_TEMPLATE_SLIDE_OUT_INIT_EMAILS_VIEW',
  open: 'EDIT_TEMPLATE_SLIDE_OUT_OPEN',
  openCardEditing: 'EDIT_TEMPLATE_SLIDE_OUT_OPEN_CARD_EDITING',
  removeRecommendedRow: 'EDIT_TEMPLATE_SLIDE_OUT_REMOVE_RECOMMENDED_ROW',
  setAnalytics: 'EDIT_TEMPLATE_SLIDE_OUT_SET_ANALYTICS',
  setAnalyticsDatesSelect: 'EDIT_TEMPLATE_SLIDE_OUT_SET_ANALYTICS_DATES_SELECT',
  setAnalyticsTeamsSelect: 'EDIT_TEMPLATE_SLIDE_OUT_SET_ANALYTICS_TEAMS_SELECT',
  saveTemplateDetailsFailure:
    'EDIT_TEMPLATE_SLIDE_OUT_SAVE_TEMPLATE_DETAILS_FAILURE',
  setEmail: 'EDIT_TEMPLATE_SLIDE_OUT_SET_EMAIL',
  setEmails: 'EDIT_TEMPLATE_SLIDE_OUT_SET_EMAILS',
  setEmailsTeamsSelect: 'EDIT_TEMPLATE_SLIDE_OUT_SET_EMAILS_TEAMS_SELECT',
  setEmailsStateSelect: 'EDIT_TEMPLATE_SLIDE_OUT_SET_EMAILS_STATE_SELECT',
  setSharableCategories: 'EDIT_TEMPLATE_SLIDE_OUT_SET_SHARABLE_CATEGORIES',
  setSharingState: 'EDIT_TEMPLATE_SLIDE_OUT_SET_SHARING_STATE',
  setTab: 'EDIT_TEMPLATE_SLIDE_OUT_SET_TAB',
  setTabAlert: 'EDIT_TEMPLATE_SLIDE_OUT_SET_TAB_ALERT',
  setTabLoading: 'EDIT_TEMPLATE_SLIDE_OUT_SET_TAB_LOADING',
  setTemplate: 'EDIT_TEMPLATE_SLIDE_OUT_SET_TEMPLATE',
  updateCloneState: 'EDIT_TEMPLATE_SLIDE_OUT_UPDATE_CLONE_STATE',
  updateRecommendEditState:
    'EDIT_TEMPLATE_SLIDE_OUT_UPDATE_RECOMMEND_EDIT_STATE',
  updateTemplateEditState: 'EDIT_TEMPLATE_SLIDE_OUT_UPDATE_TEMPLATE_EDIT_STATE',
  updateMoveCategory: 'EDIT_TEMPLATE_SLIDE_OUT_UPDATE_MOVE_CATEGORY',
  updateSharedCategory: 'EDIT_TEMPLATE_SLIDE_OUT_UPDATE_SHARED_CATEGORY',
  updateSharedTeamIds: 'EDIT_TEMPLATE_SLIDE_OUT_UPDATE_SHARED_TEAM_IDS',
  updateTemplateNote: 'EDIT_TEMPLATE_SLIDE_OUT_UPDATE_TEMPLATE_NOTE',
};
