import { baseGet, basePost, basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';

// Same as emailComposeBody templateService
function translateDataObject(data) {
  return Object.assign({}, data);
}

// Similar to emailComposeBody templateService
export function createCampaignTemplate(
  category,
  name,
  subject,
  cc,
  bcc,
  body,
  data = {},
  attachments = [],
  sourceId
) {
  return new Promise((resolve, reject) => {
    const pitchData = {
      bcc,
      body: body || '',
      category,
      cc,
      name,
      source_pitch_template_id: sourceId || null,
      subject,
      workflow_pitch_template: true,
      ...translateDataObject(data),
    };

    const postData = { attachment_id: attachments, pitch_template: pitchData };
    basePost(Urls.templates, postData, resolve, reject);
  });
}

export function editCampaignTemplate(template, attachments = [], sourceId) {
  return new Promise((resolve, reject) => {
    const data = {
      attachment_id: attachments,
      pitch_template: {
        ...template,
        body: template.body || '',
        source_pitch_template_id: sourceId,
      },
    };
    basePut(
      Urls.templatesId.replace(':#id', template.id),
      data,
      resolve,
      reject
    );
  });
}

export function getTemplateInfo(id, name = '', email = '') {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.templatesId.replace(':#id', id),
      {
        name,
        email,
        include_signature: false,
      },
      resolve,
      reject
    );
  });
}

export function getCampaignTemplates(campaignId) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.templates,
      { workflow_pitch_templates: true, workflow_id: campaignId },
      resolve,
      reject
    );
  });
}
