import SharingSettingsActionTypes from '../libs/sharingSettingsActionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';

const initValues = [];
export function sharingSettingsAddTeamsSelectedValues(
  state = initValues,
  { type = '', ids = [] }
) {
  switch (type) {
    case SharingSettingsActionTypes.addTeamsOnChange:
      return ids;
    case PopupActionTypes.close:
      return initValues;
    default:
      return state;
  }
}
