/* eslint-disable func-names */
import { getPrimaryEmailAddressDomain } from 'web/person/helpers/personHelpers';
import { isUnsubscribedPerson } from 'web/people/helpers/peopleHelpers';
import {
  BulkEmails,
  PitchActiveRecordError,
} from 'web/composeWindow/libs/composeWindowConstants';
import { Regex } from 'web/libs/constants';
import { HIGHSPOT_VALUE } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

export function buildHandleImageInsert(editorInstance, files, closeCb) {
  return function() {
    for (const imageFile of files) {
      editorInstance &&
        editorInstance.insertContent(
          `<img alt='${imageFile.name}' src='${imageFile.public_url}' /> `
        );
    }
    closeCb();
  };
}

export function buildHandleFileAttachmentInsert(
  editorInstance,
  editorId,
  allFiles,
  selectedFiles,
  addFilesCb,
  closeModalCb
) {
  return function() {
    const { ids: selectedIds, tracked } = selectedFiles;
    const selectedItems = allFiles.filter((file) => {
      const { id: fileId } = file;
      return selectedIds.includes(fileId.toString());
    });

    if (tracked) {
      const trackedContent = buildTrackedContentBody(selectedItems);
      editorInstance && editorInstance.insertContent(trackedContent);
    } else {
      addFilesCb(selectedItems, editorId);
    }
    closeModalCb();
  };
}

export function mapPitchesToPersonId(pitches) {
  const pitchesByRecipientId = {};

  pitches.forEach((pitch) => {
    pitchesByRecipientId[pitch.person_id] = pitch;
  });

  return pitchesByRecipientId;
}

export function hasDynamicFields(body, subject) {
  return Regex.dynamicField.test(body) || Regex.dynamicField.test(subject);
}

export function createDraftsForRecipients(
  recipients,
  {
    attachmentIds = [],
    bcc = [],
    body = '',
    cc = [],
    identity = {},
    sendAt = null,
    subject = '',
    templateId = null,
  }
) {
  const drafts = {};

  recipients.forEach((recipient) => {
    drafts[recipient.id] = {
      attachmentIds,
      bcc,
      body,
      cc,
      email: recipient.emailAddress,
      identity,
      sendAt,
      subject,
      templateId,
    };
  });

  return drafts;
}

export const formatCcBcc = (addresses) => {
  let addressesFormatted = '';
  addresses.forEach((address) => {
    if (addressesFormatted) {
      addressesFormatted = `${addressesFormatted}, <${address.email}>`;
    } else {
      addressesFormatted = `<${address.email}>`;
    }
  });

  return addressesFormatted;
};

export const formatDraftsForSend = (drafts) => {
  const formattedDrafts = {};
  for (const recipientId in drafts) {
    if (drafts[recipientId]) {
      const draft = { ...drafts[recipientId] };
      draft.cc = formatCcBcc(draft.cc);
      draft.bcc = formatCcBcc(draft.bcc);
      formattedDrafts[recipientId] = draft;
    }
  }

  return formattedDrafts;
};

export function mapIdsToAttachments(ids, attachments) {
  const attachmentsById = {};
  attachments.forEach((attachment) => {
    attachmentsById[attachment.id] = attachment;
  });

  return ids.map((id) => attachmentsById[id]);
}

export function mapTemplateIdToTemplate(templateId, templates) {
  return templates.find((template) => template.id === templateId) || null;
}

function replaceUrlWhitespace(url) {
  return url.replace(/ /gi, '%20');
}

export function buildTrackedContentBody(trackedContents) {
  return trackedContents
    .map(
      ({ document_url = '', file_file_name }) =>
        `&nbsp;<a href="${replaceUrlWhitespace(
          document_url
        )}" target="_blank" rel="noreferrer noopener">${file_file_name}</a>`
    )
    .join('');
}

export function parseHighspotContent(content = []) {
  return content.map(({ contentName, contentLink }) => ({
    document_url: contentLink,
    file_file_name: contentName || contentLink,
  }));
}

export function insertContentPartnerTrackedContent(data, editorInstance) {
  const { content, partnerName } = data;

  let parsedContent = null;

  if (partnerName === HIGHSPOT_VALUE) {
    parsedContent = parseHighspotContent(content);
  }

  if (parsedContent) {
    const trackedContent = buildTrackedContentBody(parsedContent);
    editorInstance.insertContent(trackedContent);
  }
}

export function handleBoxContent({ files, folders }) {
  return [...files, ...folders].map(({ name, link, ...rest }) => ({
    document_url: link,
    file_file_name: name,
    isSelectedDisabled: !link,
    ...rest,
  }));
}

export function findNextFifteenMinuteIntervalFrom(hours, minutes) {
  const nextIntervalMinutes = Math.ceil((minutes + 1) / 15) * 15;
  const plusHour = Math.floor(nextIntervalMinutes / 60);
  return {
    hours: hours + plusHour,
    minutes: nextIntervalMinutes % 60,
    seconds: 0,
  };
}

export function getUnsubscribeState(recipient, blockedDomainDetails = []) {
  const blockedDomains = blockedDomainDetails.map((detail) => detail.domain);
  const emailDomain = getPrimaryEmailAddressDomain(recipient);

  if (blockedDomains.includes(emailDomain)) {
    return BulkEmails.stateReason.blocked;
  } else if (isUnsubscribedPerson(recipient)) {
    return BulkEmails.stateReason.unsubscribed;
  } else {
    return null;
  }
}

export function formatTimeStringScheduledEmails(dateTime, timeZone) {
  const formattedTime = moment.parseZone(dateTime).format('LT dddd, MMMM Do');
  if (timeZone) {
    return `${formattedTime} (${timeZone})`;
  } else {
    return formattedTime;
  }
}

export function formatPersonAddressFromES(email, person) {
  return {
    addressableId: person.id,
    email,
    invalid: false,
    person,
    salesUnsubscribe: person.unsubscribed,
    type: 'Person',
    validated: true,
  };
}

export function hasBlockedStates(state) {
  return [
    BulkEmails.stateReason.unsubscribed,
    BulkEmails.stateReason.blocked,
  ].includes(state);
}

export function hasUndeliverableStates(state) {
  return [
    BulkEmails.stateReason.invalidDynamicFields,
    BulkEmails.stateReason.unsubscribed,
    BulkEmails.stateReason.blocked,
  ].includes(state);
}

export function hasDynamicFieldStates(state) {
  return [
    BulkEmails.stateReason.invalidDynamicFields,
    BulkEmails.stateReason.success,
  ].includes(state);
}

export function addressIsUndeliverable(address) {
  return (
    address.salesUnsubscribe ||
    address.marketingUnsubscribe ||
    address.domainBlocked
  );
}

export function getSuccessfulPitches(pitches) {
  return pitches.filter((pitch) => isEmpty(pitch.errors));
}

export function isLimitError(errors) {
  return errors.some((errorMessage) =>
    errorMessage.includes(PitchActiveRecordError.limit)
  );
}

export function isBlockedError(errors) {
  return errors.some((errorMessage) =>
    PitchActiveRecordError.unsubscribed.some((error) =>
      errorMessage.includes(error)
    )
  );
}

export function getLocalizedTimezone(currState) {
  let timeZone = null;
  if (
    currState.form &&
    currState.form.timezoneForm &&
    currState.form.timezoneForm.values &&
    currState.form.timezoneForm.values.timezone &&
    currState.form.timezoneForm.values.timezone.label
  ) {
    timeZone = currState.form.timezoneForm.values.timezone.label;
  }
  return timeZone;
}
