import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { func, number, array, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { setSidebarFilterInput } from 'web/sidebar/viewers/actionCreators/viewersActionCreators';
import Sidebar from 'web/shared/components/sidebar/sidebar';
import {
  onCommandCenterSidebarFilterSelect,
  onCommandCenterTaskSidebarFilterSelect,
} from 'web/commandCenter/actionCreators/commandCenterViewerActionCreators';
import { userViewersFilterItems } from 'web/sidebar/viewers/selectors/userSelectors';
import * as router from 'web/services/routerService';
import { SidebarMenuItems } from 'web/commandCenter/libs/commandCenterConstants';
import {
  selectCommandCenterMenuItem,
  setViewAsToEmailGridAdvancedSearchFilter,
} from 'web/commandCenter/actionCreators/commandCenterSidebarActionCreators';
import { lockResetEmailSelectedSavedAdvancedSearchId } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/openAdvancedSearchActionCreators';
import CommandCenterSidebarSaveSearchesContainer from './commandCenterSidebarSaveSearchesContainer';
import { getSidebarSelectedItemId } from 'web/commandCenter/selectors/commandCenterSelectors';
import './commandCenterSidebarContainer.scss';

class CommandCenterSidebarContainer extends Component {
  constructor(props) {
    super(props);
    const {
      lockResetEmailSelectedSavedAdvancedSearchId,
      setViewAsToEmailGridAdvancedSearchFilter,
      intl: { formatMessage },
      selectedListItem,
      user: { admin },
    } = props;

    this.filterLabel = formatMessage({ id: 'web.templates.viewAs' });

    if (admin && selectedListItem === SidebarMenuItems.emails.id) {
      lockResetEmailSelectedSavedAdvancedSearchId();
      setViewAsToEmailGridAdvancedSearchFilter();
    }

    this.sideBarListItems = Object.values(SidebarMenuItems);
  }

  onSideBarItemSelect = (selectedIndex) => {
    const { selectCommandCenterMenuItem } = this.props;

    selectCommandCenterMenuItem(selectedIndex);
    router.navigateToCommandCenterMenuItem(
      this.sideBarListItems[selectedIndex].urlHash
    );
  };

  render() {
    const { selectedListItem } = this.props;

    return (
      <Sidebar
        classes="command-center-sidebar"
        filter={this.getFilter()}
        list={{
          editingItemId: -1,
          itemOptions: [],
          listData: this.sideBarListItems,
          listLoading: false,
          onSelect: this.onSideBarItemSelect,
          selectedListItem,
        }}
      >
        <CommandCenterSidebarSaveSearchesContainer />
      </Sidebar>
    );
  }

  getFilter = () => {
    const {
      emailsFilterInput,
      emailsViewer: { viewerId },
      filterItems,
      onSidebarFilterSelect,
      onTaskSidebarFilterSelect,
      setSidebarFilterInput,
      selectedListItem,
      user: { admin },
    } = this.props;

    return admin
      ? {
          inputValue: emailsFilterInput,
          items: filterItems,
          label: this.filterLabel,
          onChange: setSidebarFilterInput,
          onSelect:
            selectedListItem === SidebarMenuItems.emails.id
              ? onSidebarFilterSelect
              : onTaskSidebarFilterSelect,
          selectedItem: viewerId,
        }
      : {};
  };
}

CommandCenterSidebarContainer.propTypes = {
  emailsFilterInput: string.isRequired,
  emailsViewer: object.isRequired,
  filterItems: array.isRequired,
  intl: intlShape.isRequired,
  lockResetEmailSelectedSavedAdvancedSearchId: func.isRequired,
  onSidebarFilterSelect: func.isRequired,
  onTaskSidebarFilterSelect: func.isRequired,
  selectCommandCenterMenuItem: func.isRequired,
  selectedListItem: number.isRequired,
  setSidebarFilterInput: func.isRequired,
  setViewAsToEmailGridAdvancedSearchFilter: func.isRequired,
  user: object.isRequired,
};

const mapStateToProps = (state) => ({
  emailsFilterInput: state.sidebarViewerFilterInput,
  emailsViewer: state.sidebarViewer,
  filterItems: userViewersFilterItems(state),
  selectedListItem: getSidebarSelectedItemId(state),
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      lockResetEmailSelectedSavedAdvancedSearchId,
      onSidebarFilterSelect: onCommandCenterSidebarFilterSelect,
      onTaskSidebarFilterSelect: onCommandCenterTaskSidebarFilterSelect,
      selectCommandCenterMenuItem,
      setSidebarFilterInput,
      setViewAsToEmailGridAdvancedSearchFilter,
    },
    dispatch
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CommandCenterSidebarContainer);
