import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import Icon, { IconColors } from 'components/buttons/icon';
import './notification.scss';

const Notification = (props) => {
  const { children, className, messageId, messageValues, iconOptions } = props;
  const classes = classNames('tout-notification', className);

  return (
    <div className={classes}>
      <Icon className="tout-lightbulb" {...iconOptions} />
      <div className="notification-text">
        {messageId ? (
          <FormattedHTMLMessage id={messageId} values={messageValues} />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  iconOptions: PropTypes.object,
  messageId: PropTypes.string,
  messageValues: PropTypes.object,
  children: PropTypes.node,
};

Notification.defaultProps = {
  className: '',
  messageId: null,
  messageValues: {},
  iconOptions: {
    color: IconColors.darkGrayLight,
  },
  children: null,
};

export default Notification;
