import last from 'lodash/last';
import {
  CUSTOMIZATION_WIZARD,
  CUSTOMIZATION_OPTIONS_PROFILE_ACCESS,
  USERS_SPECIFIC,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';

export function getCustomizationItems(wizardStep, rawCustomizationItems) {
  return (rawCustomizationItems[wizardStep] || []).map((category) => ({
    label: category.category,
    value: category.category,
    items: category.items.map((item) => ({
      ...item,
      id: item.label,
    })),
  }));
}

export function isFirstStep(currentStep) {
  return currentStep === CUSTOMIZATION_WIZARD[0];
}

export function isLastStep(currentStep) {
  return currentStep === last(CUSTOMIZATION_WIZARD);
}

export function getWizardSteps(currentProfileUser) {
  if (currentProfileUser === USERS_SPECIFIC) {
    return CUSTOMIZATION_WIZARD;
  } else {
    return CUSTOMIZATION_WIZARD.filter(
      (item) => item !== CUSTOMIZATION_OPTIONS_PROFILE_ACCESS
    );
  }
}
