import dialerActionTypes from '../libs/dialerActionTypes';

export const dialerSettingsAlertId = (state = null, { type, alertId }) => {
  switch (type) {
    case dialerActionTypes.showSettingsAlert:
      return alertId;
    case dialerActionTypes.hideSettingsAlert:
      return null;
    default:
      return state;
  }
};
