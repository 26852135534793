import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EmailServerPopupIds } from '../libs/emailServerConstants';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { removeEmailServer } from '../actionCreators/emailServerActionCreators';
import EmailServerRemoveConfirmationPopup from '../components/emailServerRemoveConfirmationPopup';

class EmailServerPopupContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          closePopup,
        },
        dispatch
      ),
      removeEmailServer: () => {
        dispatch(closePopup());
        dispatch(removeEmailServer());
      },
    };
  }

  render() {
    const { popup } = this.props;

    switch (popup) {
      case EmailServerPopupIds.removeConfirmation:
        return (
          <EmailServerRemoveConfirmationPopup
            actionCreators={this.actionCreators}
          />
        );

      default:
        return null;
    }
  }
}

EmailServerPopupContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  popup: PropTypes.string.isRequired,
  url: PropTypes.string,
};

EmailServerPopupContainer.defaultProps = {
  url: '',
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  url: state.popupData.url,
});

export default connect(mapStateToProps)(EmailServerPopupContainer);
