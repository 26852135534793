import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BackgroundColors, HALF } from 'libs/constantsStyles';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import Cards, { Direction } from 'components/layouts/cards';
import ContentPartners from '../contentPartners';
import DomainBlocks from '../domainBlocks';
import AutoAppendCard from '../autoApendCard';
import ContentLockDown from '../contentLockDown';
import CampaignLimits from '../campaignLimits';
import EmailLimits from '../emailLimits';
import EmailThrottlingLimit from '../emailThrottlingLimit';
import LoginManagement from '../loginManagement';

class GlobalSettingsPage extends Component {
  render() {
    return (
      <Page className="settings-admin-settings-general-components-general-page">
        <PageView>
          <div className="admin-general-components-page__wrapper">
            <Cards
              backgroundColor={BackgroundColors.grayExtraLight}
              cards={this.getLeftCards()}
              className="admin-general-page-cards-wrapper_left"
              direction={Direction.vertical}
              marginAround={HALF}
              marginBetween={HALF}
            />
            <div className="admin-general-page-cards-wrapper_right">
              <Cards
                backgroundColor={BackgroundColors.grayExtraLight}
                cards={this.getRightCards()}
                className="admin-general-page-cards"
                direction={Direction.vertical}
                marginAround={HALF}
                marginBetween={HALF}
              />
            </div>
          </div>
        </PageView>
      </Page>
    );
  }

  getLeftCards = () => {
    const cards = [];
    const {
      isAutoAppendEnabled,
      isMSIppSfSignInAndActivationEnabled,
    } = this.props;

    cards.push(DomainBlocks);
    cards.push(ContentPartners);

    if (isAutoAppendEnabled) {
      cards.push(AutoAppendCard);
    }

    cards.push(ContentLockDown);

    if (isMSIppSfSignInAndActivationEnabled) {
      cards.push(LoginManagement);
    }

    return cards.map((cardComponent, index) => ({
      children: React.createElement(cardComponent, this.props),
      id: index,
    }));
  };

  getRightCards = () => {
    const {
      actionCreators,
      defaultDeliveryChannel,
      emailThrottlingLimits,
      onTeamCampaignLimitFormError,
      onTeamEmailLimitFormError,
      onTeamEmailThrottlingLimitFormError,
      subscriptionCampaignLimit,
      subscriptionEmailLimit,
      teamCampaignLimit,
      teamEmailLimit,
      teamEmailLimitSource,
    } = this.props;
    const cards = [
      {
        children: (
          <EmailLimits
            actionCreators={actionCreators}
            defaultDeliveryChannel={defaultDeliveryChannel}
            onTeamEmailLimitFormError={onTeamEmailLimitFormError}
            subscriptionEmailLimit={subscriptionEmailLimit}
            teamEmailLimit={teamEmailLimit}
            teamEmailLimitSource={teamEmailLimitSource}
          />
        ),
        id: 1,
      },
      {
        children: (
          <EmailThrottlingLimit
            actionCreators={actionCreators}
            defaultDeliveryChannel={defaultDeliveryChannel}
            emailThrottlingLimits={emailThrottlingLimits}
            onTeamEmailThrottlingLimitFormError={
              onTeamEmailThrottlingLimitFormError
            }
          />
        ),
        id: 2,
      },
      {
        children: (
          <CampaignLimits
            actionCreators={actionCreators}
            onTeamCampaignLimitFormError={onTeamCampaignLimitFormError}
            subscriptionCampaignLimit={subscriptionCampaignLimit}
            teamCampaignLimit={teamCampaignLimit}
          />
        ),
        id: 3,
      },
    ];
    return cards;
  };
}

GlobalSettingsPage.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  defaultDeliveryChannel: PropTypes.object,
  emailThrottlingLimits: PropTypes.object.isRequired,
  isAutoAppendEnabled: PropTypes.bool.isRequired,
  isMSIppSfSignInAndActivationEnabled: PropTypes.bool.isRequired,
  isSalesEngagePhoneEnabled: PropTypes.bool.isRequired,
  onTeamCampaignLimitFormError: PropTypes.func.isRequired,
  onTeamEmailLimitFormError: PropTypes.func.isRequired,
  onTeamEmailThrottlingLimitFormError: PropTypes.func.isRequired,
  subscriptionCampaignLimit: PropTypes.number.isRequired,
  subscriptionEmailLimit: PropTypes.number.isRequired,
  teamCampaignLimit: PropTypes.string.isRequired,
  teamEmailLimit: PropTypes.string.isRequired,
  teamEmailLimitSource: PropTypes.string.isRequired,
};

GlobalSettingsPage.defaultProps = {
  defaultDeliveryChannel: null,
};

export default GlobalSettingsPage;
