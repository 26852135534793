import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl, ModalPopupIds } from 'web/libs/constants';
import { SourceOpener } from 'libs/constantsShared';
import { ModalsTaskAlertIds } from 'web/modals/task/libs/taskConstants';
import Popup, {
  PopupBackgroundColors,
  PopupAlignContentClasses,
} from 'components/popups';
import Task from 'web/task/containers/taskContainer';
import { taskStartup } from 'web/modals/task/actionCreators/startup';
import { clearPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import {
  closeModalTask,
  handleError,
  taskDeleteEditing,
  taskSaveEditing,
} from 'web/modals/task/actionCreators/taskActionCreators';
import { validCreate, validUpdate } from 'web/task/services/editStateService';
import { AlertIconTypes } from 'components/alert';
import { navigateToHowToShareGroup } from 'web/services/routerService';

const POPUP_SIZE = 650;

class TaskContainer extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { dispatch, mode, sourceOpener } = this.props;
    dispatch(taskStartup(mode, sourceOpener));

    this.actionCreators = {
      clearAlert: () => dispatch(clearPopupAlert()),
      closeModal: () => dispatch(closeModalTask()),
      handleError: (err) => dispatch(handleError(err)),
      taskDelete: () => dispatch(taskDeleteEditing()),
      taskSave: () => dispatch(taskSaveEditing()),
    };
  }

  render() {
    const { alert, loading, modal, sourceOpener } = this.props;
    const showInSeparateWindow = [
      SourceOpener.liveFeed,
      SourceOpener.salesforce,
    ].includes(sourceOpener);
    const isSalesforceOpener = sourceOpener === SourceOpener.salesforce;

    return modal.id === ModalPopupIds.task ? (
      <Popup
        alert={this._getAlert(alert)}
        disableClick={showInSeparateWindow}
        getImageUrl={getImageUrl}
        loading={loading}
        popUpClassName={
          showInSeparateWindow ? PopupAlignContentClasses.left : ''
        }
        showHeader={!isSalesforceOpener}
        {...this._getGlobalPopupObject()}
      />
    ) : null;
  }

  _getGlobalPopupObject = () => {
    const {
      canUserHandleContact,
      editState,
      options,
      task,
      windowMode,
    } = this.props;
    const isCampaign = !!(
      task &&
      task.workflow_details &&
      task.workflow_details.workflow_id
    );
    const exists = task && task.id;
    const isCompleted = task && task.done;
    const onClose = this.actionCreators.closeModal;

    const obj = {
      children: (
        <Task
          disableAll={isCompleted}
          disableAutocomplete={isCampaign}
          disableType={isCampaign}
          handleError={this.actionCreators.handleError}
          task={task}
          windowMode={windowMode}
          showContact
          {...options}
        />
      ),
      footer: {
        onPrimary: this.actionCreators.taskSave,
      },
      header: {
        onClose,
      },
      size: POPUP_SIZE,
    };

    if (isCompleted) {
      obj.header.textId = 'web.task.title.details';
      obj.footer.onPrimary = undefined;
      obj.footer.secondaryTextId = 'common.close';
      obj.footer.onSecondary = onClose;
    } else if (isCampaign && exists) {
      obj.header.textId = 'web.task.title.edit';
      obj.footer.primaryTextId = 'common.update';
      obj.footer.primaryDisabled =
        !canUserHandleContact || !validUpdate(editState);
      obj.footer.secondaryTextId = 'common.cancel';
      obj.footer.onSecondary = onClose;
    } else if (exists) {
      obj.header.textId = 'web.task.title.edit';
      obj.footer.primaryTextId = 'common.update';
      obj.footer.primaryDisabled =
        !canUserHandleContact || !validUpdate(editState);
      obj.footer.secondaryTextId = 'common.delete';
      obj.footer.secondaryColor = PopupBackgroundColors.red;
      obj.footer.onSecondary = this.actionCreators.taskDelete;
    } else {
      obj.header.textId = 'web.task.title.create';
      obj.footer.primaryTextId = 'common.create';
      obj.footer.primaryDisabled =
        !canUserHandleContact || !validCreate(editState);
      obj.footer.secondaryTextId = 'common.cancel';
      obj.footer.onSecondary = onClose;
    }

    return obj;
  };

  _getAlert = () => {
    const {
      alert,
      alertTextValues,
      intl: { formatMessage },
    } = this.props;
    const defaultAlert = {
      classes: '',
      color: PopupBackgroundColors.red,
      disableClick: true,
      onClose: this.actionCreators.clearAlert,
      textId: 'web.task.alert.saveFailed',
    };

    switch (alert) {
      case ModalsTaskAlertIds.deleteTaskFailed:
        return { ...defaultAlert, textId: 'web.task.alert.deleteTaskFailed' };
      case ModalsTaskAlertIds.saveTaskFailed:
        return { ...defaultAlert, textId: 'web.task.alert.saveTaskFailed' };
      case ModalsTaskAlertIds.unverifiedUser:
        return { ...defaultAlert, textId: 'web.task.alert.unverifiedUser' };
      case ModalsTaskAlertIds.noContactFound:
        return { ...defaultAlert, textId: 'web.task.alert.contactNotFound' };
      case ModalsTaskAlertIds.notSharedContactError:
        return {
          ...defaultAlert,
          action: navigateToHowToShareGroup,
          actionLabelText: formatMessage({
            id: 'web.liveFeed.pipeline.learnMore',
          }),
          color: PopupBackgroundColors.orange,
          icon: AlertIconTypes.warning,
          textId: 'web.task.alert.notSharedContactError',
          textValues: alertTextValues,
        };
      case ModalsTaskAlertIds.notLoggedIn:
        return { ...defaultAlert, textId: 'web.task.alert.loginError' };
      case ModalsTaskAlertIds.unprocessableEntityServerError:
        return {
          ...defaultAlert,
          textId: 'web.task.alert.unprocessableEntityServerError',
        };
      default:
        return null;
    }
  };
}

TaskContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  alertTextValues: PropTypes.object.isRequired,
  canUserHandleContact: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  editState: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  modal: PropTypes.object.isRequired,
  mode: PropTypes.string, // passed in
  options: PropTypes.object, // passed in
  sourceOpener: PropTypes.string, // passed in
  task: PropTypes.object, // passed in
  windowMode: PropTypes.bool.isRequired,
};

TaskContainer.defaultProps = {
  canUserHandleContact: true,
  mode: '',
  options: {},
  sourceOpener: '',
  task: {},
};

function mapStateToProps(state) {
  return {
    account: state.user,
    alert: state.popupAlert,
    alertTextValues: state.popupAlertTextValues,
    canUserHandleContact: state.canUserHandleContact,
    editState: state.taskEditState,
    groups: state.groups,
    loading: state.popupLoading,
    modal: state.modal,
    person: state.personDetailsPerson,
    windowMode: state.modalsTaskWindowMode,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(TaskContainer);
