import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AlertColors } from 'components/alert';

const popupWithErrors = (WrappedComponent) => {
  const componentName =
    WrappedComponent.displayName || WrappedComponent.name || 'UnnamedComponent';

  return class PopupWithErrors extends Component {
    static getDerivedStateFromProps(props, state) {
      const {
        popupData: { errors },
      } = props;

      if (errors && errors !== state.errors) {
        return { shouldShowAlert: true, errors };
      }

      if (!errors) {
        return { shouldShowAlert: false };
      }

      return null;
    }

    state = {
      shouldShowAlert: false,
      errors: null,
    };

    clearAlert = () => {
      this.setState({ shouldShowAlert: false });
    };

    getDefaultAlert = () => {
      const { shouldShowAlert } = this.state;
      return shouldShowAlert
        ? {
            color: AlertColors.red,
            onClose: this.clearAlert,
            textId: 'web.common.alert.genericIssue',
          }
        : null;
    };

    render() {
      const { shouldShowAlert } = this.state;
      return WrappedComponent ? (
        <WrappedComponent
          shouldShowAlert={shouldShowAlert}
          clearAlert={this.clearAlert}
          defaultAlert={this.getDefaultAlert()}
          {...this.props}
        />
      ) : null;
    }

    static propTypes = {
      popup: PropTypes.any,
      popupData: PropTypes.object,
    };

    static defaultProps = {
      popup: null,
      popupData: {},
    };

    static displayName = `popupWithErrors(${componentName})`;
  };
};

export default popupWithErrors;
