import { baseDelete, baseGet, basePost, basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';

const SFDCObjectTypes = {
  lead: 'Lead',
  contact: 'Contact',
};

export function findOrCreateContact(email, source = '') {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.peopleLookup,
      { email, hashed_object_type: 'no_pitches', source },
      resolve,
      reject
    );
  });
}

export function isContactExists(email, userId, addressId) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.personExists,
      { email, id: addressId, user_id: userId },
      resolve,
      reject
    );
  });
}

export const findOrCreatePerson = ({
  id,
  email,
  title,
  firstName,
  lastName,
  company,
}) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.peopleCreate,
      {
        address_type: 'email',
        company_name: company,
        email_address: email,
        first_name: firstName,
        last_name: lastName,
        location: 'Work',
        marketo_lead_id: id,
        title,
      },
      resolve,
      reject
    );
  });

export const getSalesforceAccountInfo = (payload) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.salesforceFind, payload, resolve, reject);
  });

export const addToSalesforce = (salesforceType, payload) =>
  new Promise((resolve, reject) => {
    const type = salesforceType[0].toUpperCase() + salesforceType.substr(1);
    basePost(
      Urls.addToSalesforce.replace(':#type', type),
      payload,
      resolve,
      reject
    );
  });

export function getPersonById(id = '', noPitches = true) {
  return new Promise((resolve, reject) => {
    const data = {};

    if (noPitches) {
      data.no_pitches = true;
    }

    baseGet(Urls.personById.replace(':#id', id), data, resolve, reject);
  });
}

export function getPeopleByGroup(id, limit = 1000, offset = 0) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.groupPeople.replace(':#id', id),
      { limit, offset },
      resolve,
      reject
    );
  });
}

export function getPeopleIdsByGroup(id, limit = 1000, offset = 0) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.groupPeopleIds.replace(':#id', id),
      { limit, offset },
      resolve,
      reject
    );
  });
}

export function getPeopleByIds(ids) {
  return new Promise((resolve, reject) => {
    basePost(Urls.peopleByIds, { ids, no_pitches: true }, resolve, reject);
  });
}

// MT: This method doesn't use SFDC Import. SIA subs have
// `people` records with SFDC IDs populated, so we don't
// need to Import them
export function getPeopleBySalesforceIdsWithoutImport(salesforceIds) {
  const requestParams = {};

  salesforceIds.forEach((salesforceId) => {
    if (salesforceId.type == SFDCObjectTypes.contact) {
      requestParams.salesforce_contact_ids = salesforceId.ids.toString();
    }
    if (salesforceId.type == SFDCObjectTypes.lead) {
      requestParams.salesforce_lead_ids = salesforceId.ids.toString();
    }
  });

  return new Promise((resolve, reject) => {
    baseGet(Urls.people, requestParams, resolve, reject);
  });
}

// MT: This method uses SFDC Import, which is only required
// for non-SIA subs
export function getPeopleBySalesforceIds(multipleRecipients) {
  var request_data = { options: { skip_alert: true } };
  multipleRecipients.forEach(function(recipient) {
    if (recipient.type == SFDCObjectTypes.contact) {
      request_data.salesforce_contact_ids = recipient.ids;
    }
    if (recipient.type == SFDCObjectTypes.lead) {
      request_data.salesforce_lead_ids = recipient.ids;
    }
  });

  return new Promise((resolve, reject) => {
    basePost(Urls.salesforceImport, request_data, resolve, reject);
  });
}

export function searchPeople(queryStr) {
  return new Promise((resolve, reject) => {
    baseGet(`${Urls.searchPeople}?query_str=${queryStr}`, {}, resolve, reject);
  });
}

/* legacy social object causing odd behavior on backend */
// eslint-disable-next-line no-unused-vars
export function updatePerson(id, { social: ignore1, ...person }) {
  return new Promise((resolve, reject) => {
    basePut(Urls.person.replace(':#id', id), { person }, resolve, reject);
  });
}

// eslint-disable-next-line no-unused-vars
export function addPerson({ social: ignore1, ...person }) {
  return new Promise((resolve, reject) => {
    basePost(Urls.people, { person }, resolve, reject);
  });
}

export function deletePerson(id) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.person.replace(':#id', id), {}, resolve, reject);
  });
}

export function unsubscribePerson(id, reasonDescription) {
  return new Promise((resolve, reject) => {
    const reason = reasonDescription
      ? { reason_description: reasonDescription }
      : {};

    basePost(
      Urls.personUnsubscribe.replace(':#id', id),
      {
        unsubscribe: {
          is_blocked: 1,
          ...reason,
        },
      },
      resolve,
      reject
    );
  });
}

export function unsubscribePeople(peopleIds) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.peopleUnsubscribe,
      {
        bulk_action: 'save',
        people_ids: peopleIds,
      },
      resolve,
      reject
    );
  });
}

export function removePersonUnsubscribe(id, optInReason) {
  return new Promise((resolve, reject) => {
    baseDelete(
      Urls.personUnsubscribe.replace(':#id', id),
      {
        unsubscribe_opt_in_reason_id: optInReason,
      },
      resolve,
      reject
    );
  });
}

export function getUnsubscribeOptInReasons() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.unsubscribeOptInReason, {}, resolve, reject);
  });
}

export const getPeopleFetch = (params) =>
  new Promise((resolve, reject) => {
    basePost(Urls.esFetch, params, resolve, reject);
  });

export function exportPerson(query) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.exportAPI,
      {
        es_query: query,
      },
      resolve,
      reject
    );
  });
}

export const getMarketoCampaigns = (personId) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.marketoActivities,
      { activityTypes: 'RequestCampaign', personId },
      resolve,
      reject
    );
  });

export const getUnsubscribeOptions = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.unsubscribeOptions, {}, resolve, reject);
  });

export const unsubscribeManual = (data) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.unsubscribeManual
        .replace(':#id', data.id)
        .replace(':#email', data.email),
      data,
      resolve,
      reject
    );
  });

export const getUnsubscribeHistory = (personId) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.unsubscribeHistory.replace(':#id', personId),
      {},
      resolve,
      reject
    );
  });

export const stopCampaignForContacts = (peopleIds, isAdmin, success = false) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.campaignsAllStop,
      { admin: isAdmin, people_ids: peopleIds, success },
      resolve,
      reject
    );
  });

export const savePeopleAuthorization = (peopleIds, compliance) =>
  new Promise((resolve, reject) => {
    basePost(
      `/compliances/bulk_upsert`,
      { compliance, people_ids: peopleIds },
      resolve,
      reject
    );
  });
