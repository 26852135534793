import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Form, Field, reduxForm } from 'redux-form';
import Action, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { email as emailValidatorFactory } from 'web/form/helpers/validators';
import Text from 'web/form/components/fields/textWithErrors';

import './bccForm.scss';

class AutoBccForm extends Component {
  constructor(props) {
    super(props);
    this.emailValidator = emailValidatorFactory();
  }

  render() {
    const {
      intl: { formatMessage },
      pristine,
      submitting,
      invalid,
      handleSubmit,
      bccIntegration,
      onClose,
    } = this.props;
    const isSubmitDisabled = pristine || submitting || invalid;

    return (
      <Form
        className="auto-bcc-form margin-0 clearfix"
        onSubmit={handleSubmit}
        noValidate
      >
        <Field
          className="bcc-form-field margin-top-20"
          component={Text}
          name="bcc"
          placeholder={formatMessage({
            id:
              'web.settings.accountSettings.integrations.bcc.form.placeholder',
          })}
          validate={[this.emailValidator]}
        />

        <div className="card-buttons margin-top-20">
          {!bccIntegration.bcc && (
            <Action
              className="right"
              disabled={isSubmitDisabled}
              size={ButtonSizes.medium}
              type="submit"
            >
              <FormattedMessage id="common.connect" />
            </Action>
          )}

          <Action
            className="margin-right-15 right"
            color={ButtonColors.gray}
            disabled={submitting}
            onClick={onClose}
            size={ButtonSizes.medium}
            type="button"
          >
            <FormattedMessage id="common.close" />
          </Action>
        </div>
      </Form>
    );
  }
}

AutoBccForm.propTypes = {
  bccIntegration: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  invalid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default compose(
  reduxForm({
    enableReinitialize: true,
  }),
  injectIntl
)(AutoBccForm);
