import merge from 'lodash/merge';
import editIdentity from '../editIdentity/reducers/editIdentityRootReducer';
import editTemplate from '../editTemplate/reducers/editTemplateRootReducer';
import personDetailsRoot from '../personDetails/reducers/personDetailsSlideOutRootReducer';
import addContactRoot from '../addContact/reducers/addContactSlideOutRootReducer';

export default merge(
  {},
  editIdentity,
  editTemplate,
  personDetailsRoot,
  addContactRoot
);
