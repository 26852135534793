import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { getCallOutcomeOptions } from './callOutcomeActionCreators';
import { getCallReasonOptions } from './callReasonActionCreators';
import DialerActionTypes from '../libs/dialerActionTypes';
import { updateAdminSettings } from 'web/user/actionCreators/userActionCreators';
import { DialerSettingsAlertIds } from '../libs/dialerConstants';

export const dialerSettingsStartup = () => (dispatch) => {
  dispatch(commonStartup());
  dispatch(getCallOutcomeOptions());
  dispatch(getCallReasonOptions());
};

export const showSettingsAlert = (alertId) => ({
  alertId,
  type: DialerActionTypes.showSettingsAlert,
});

export const hideSettingsAlert = () => ({
  type: DialerActionTypes.hideSettingsAlert,
});

export const updateDialerSettings = ({
  call_outcome_enabled,
  call_reason_enabled,
  force_call_outcome,
  force_call_reason,
}) => async (dispatch) => {
  try {
    await dispatch(
      updateAdminSettings({
        admin_settings: {
          data: {
            call_outcome_enabled,
            call_reason_enabled,
            force_call_outcome,
            force_call_reason,
          },
        },
      })
    );
    dispatch(showSettingsAlert(DialerSettingsAlertIds.changesSaved));
  } catch (err) {
    dispatch(showSettingsAlert(DialerSettingsAlertIds.changesNotSaved));
  }
};
