import {
  getRecordingNotice,
  getRecordingNoticeAudio,
  deleteRecordingNotice,
} from '../services/manageRecordingNoticeService';

export function manageRecordingNoticeGet(active) {
  return () => getRecordingNotice(active).then((data) => data);
}

export function recordingNoticeAudioGet(id, publicKey, fileName) {
  return () =>
    getRecordingNoticeAudio(id, publicKey, fileName).then((data) => data);
}

export function recordingNoticeDelete(id) {
  return () => deleteRecordingNotice(id);
}
