import TeamMembersActionTypes from '../libs/teamMembersActionTypes';
import {
  getTeamMembers as getTeamMembersCall,
  deleteUser as deleteUserCall,
  batchDestroyUsers as batchDestroyUsersCall,
  removeTeamMembers as removeTeamMembersCall,
  updateTeams as updateTeamsCall,
  teamsAddTeamMembers as teamsAddTeamMembersCall,
} from '../services/teamMembersServices';

export const setTeamMembersFetched = (teamId, fetched = true) => ({
  type: TeamMembersActionTypes.setTeamMembersFetched,
  teamId,
  fetched,
});

export const setTeamMembersFetching = (teamId, fetching = true) => ({
  type: TeamMembersActionTypes.setTeamMembersFetching,
  teamId,
  fetching,
});

export const setTeamMembers = (teamId, teamMembers) => ({
  type: TeamMembersActionTypes.setTeamMembers,
  teamId,
  teamMembers,
});

export const getTeamMembers = (teamId) => (dispatch) => {
  dispatch(setTeamMembersFetching(teamId));
  return getTeamMembersCall(teamId)
    .then((teamMembers) => {
      dispatch(setTeamMembers(teamId, teamMembers));
      return teamMembers;
    })
    .finally(() => {
      dispatch(setTeamMembersFetching(teamId, false));
      dispatch(setTeamMembersFetched(teamId));
    });
};

export const deleteUser = (id) => (dispatch) =>
  deleteUserCall(id).then((result) => {
    dispatch({
      type: TeamMembersActionTypes.deleteTeamMember,
      id,
    });
    return result;
  });

export const batchDestroyUsers = (ids) => (dispatch) =>
  batchDestroyUsersCall(ids).then((batches) => {
    const deletedIds = batches
      .filter((batch) => batch.status === 'success')
      .map((batch) => batch.id);
    dispatch({
      type: TeamMembersActionTypes.batchDestroyTeamMembers,
      ids: deletedIds,
    });
    return batches;
  });

export const batchDestroyTeamMembers = (teamId, ids) => (dispatch) =>
  removeTeamMembersCall(teamId, ids).then((result) => {
    const deletedIds = result.user_ids;
    dispatch({
      type: TeamMembersActionTypes.batchDestroyTeamMembers,
      ids: deletedIds,
      teamId,
    });
    return result;
  });

export const updateTeamMember = (userId, teamIds) => (dispatch) =>
  updateTeamsCall(userId, teamIds).then((teamMember) => {
    dispatch({
      type: TeamMembersActionTypes.updateTeamMember,
      teamMember,
    });
    return teamMember;
  });

export const createTeamMembersInTeams = (userIds, teamIds) => (dispatch) =>
  teamsAddTeamMembersCall(userIds, teamIds).then((teamsTeamMmebers) => {
    dispatch({
      type: TeamMembersActionTypes.createTeamMembersInTeams,
      teamsTeamMmebers,
    });
    return teamsTeamMmebers;
  });
