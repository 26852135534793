import React from 'react';
import PropTypes from 'prop-types';
import {
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';
import { ColorClasses, ColorHexCodes } from 'libs/constantsStyles';
import GraphTooltip from 'components/chart/customTooltip/graphTooltip';

const getLineStyle = (color) => ({
  data: { stroke: color, pointerEvents: 'none' },
});

const emptyLabel = () => '';

const SCATTER_SIZE = 4;
const getScatterStyle = (color) => ({
  data: {
    stroke: color,
    fill: ColorClasses.white,
    strokeWidth: 2,
  },
});

export default function LineChart({ color, ...other }) {
  return (
    <VictoryGroup {...other}>
      <VictoryLine style={getLineStyle(color)} />
      <VictoryScatter
        size={SCATTER_SIZE}
        style={getScatterStyle(color)}
        labels={emptyLabel}
        labelComponent={<VictoryTooltip flyoutComponent={<GraphTooltip />} />}
      />
    </VictoryGroup>
  );
}

LineChart.propTypes = {
  color: PropTypes.string,
};

LineChart.defaultProps = {
  color: ColorHexCodes.toutBlue,
};
