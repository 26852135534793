import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { getGroups } from 'web/groups/actionCreators/groupsActionCreators';
import { setAddPeopleIdentity } from 'web/people/peopleSearchAdd/actionCreators/identitiesActionCreators';
import { getIdentities } from 'web/user/actionCreators/identitiesActionCreators';

export const initPeopleSearchAddData = (selectedIds) => (
  dispatch,
  getState
) => {
  dispatch({ type: ActionTypes.autocomplete.state.clear });

  return Promise.all([dispatch(getIdentities()), dispatch(getGroups())]).then(
    () => {
      const { defaultIdentity } = getState();
      dispatch(setAddPeopleIdentity(defaultIdentity));
    }
  );
};
