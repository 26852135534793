/*  Map passed to constructor should be structured as:
 *  {
 *    column1: {
 *      primaryProperty: 'name',
 *      primaryDefaultDirection: SortDirections.asc,
 *      primaryType: SortTypes.text,
 *      secondaryProperty: 'date',
 *      secondaryDefaultDirection: SortDirections.desc,
 *      secondaryType: SortTypes.date
 *    },
 *    column2: {
 *      ...
 *    }, ...
 *  }
*/

import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { SortDirections, SortTypes } from './constantsShared';

export default class Sorter {
  constructor(map, defaultKey) {
    this.sortingMap = map;
    this.currentKey = defaultKey;
    this.currentPrimaryDirection = null;
  }

  // usePrimaryDefaultDirection - plan sort using newKey passed in
  sort(data, newKey, usePrimaryDefaultDirection = false) {
    if (newKey === this.currentKey && !usePrimaryDefaultDirection) {
      this.currentPrimaryDirection = this.currentPrimaryDirection
        ? this._reverse(this.currentPrimaryDirection)
        : this.sortingMap[newKey].primaryDefaultDirection;
    } else {
      this.currentKey = newKey;
      this.currentPrimaryDirection = this.sortingMap[
        newKey
      ].primaryDefaultDirection;
    }

    return orderBy(data, this._getSorters(), this._getOrders());
  }

  sortCurrentSettings(data) {
    return orderBy(data, this._getSorters(), this._getOrders());
  }

  // Used to set header's classes in component's render method
  getArrowClass(column) {
    return column === this.currentKey
      ? `sort-arrow ${this.currentPrimaryDirection}`
      : '';
  }

  _reverse(current) {
    return current === SortDirections.asc
      ? SortDirections.desc
      : SortDirections.asc;
  }

  _getSorter(which = 'primary') {
    const property = this.sortingMap[this.currentKey][`${which}Property`];

    switch (this.sortingMap[this.currentKey][`${which}Type`]) {
      case SortTypes.text:
        return this._alphabeticalSorter(property);
      case SortTypes.date:
        return this._dateSorter(property);
      case SortTypes.number:
        return this._numberSorter(property);
      default:
        return this._alphabeticalSorter(property);
    }
  }

  _getSorters() {
    return ['primary', 'secondary'].map((which) => this._getSorter(which));
  }

  _getOrders() {
    return [
      this.currentPrimaryDirection,
      this.sortingMap[this.currentKey]['secondaryDefaultDirection'],
    ];
  }

  _alphabeticalSorter(property) {
    return (item) => item[property].toLowerCase();
  }

  _dateSorter(property) {
    return (item) =>
      moment(item[property])
        .startOf('day')
        .unix();
  }

  _numberSorter(property) {
    return (item) => item[property];
  }
}
