import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import OptInReasons from 'web/unsubscribe/optInReasons/containers/optInReasonsContainer';
import './pesonDetailsRemoveUnsubscribe.scss';

const PersonDetailsRemoveUnsubscribe = ({ personEmail, userName }) => (
  <div className="person-details-remove-unsubscribe">
    <div className="remove-unsubscribe-icon-warning" />
    <div className="person-details-remove-unsubscribe-body">
      <p>
        <FormattedHTMLMessage
          id="web.person.personDetails.popup.removeUnsubscribe.body"
          values={{ personEmail, userName }}
        />
      </p>
      <OptInReasons />
    </div>
  </div>
);

PersonDetailsRemoveUnsubscribe.propTypes = {
  personEmail: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default PersonDetailsRemoveUnsubscribe;
