import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/label';
import Text from 'components/inputs/text';
import './createNewCategory.scss';

const INPUT_WIDTH = 135;
const RETURN_KEY_CODE = 13;

class CreateNewCategory extends Component {
  render() {
    const { newCategory } = this.props;
    const firstCampaignMessage = this.getFirstCampaignMessage();

    return (
      <div className="create-new-category margin-bottom-full">
        {firstCampaignMessage}
        <Label
          classWrapper="margin-top-full"
          id="create-new-category"
          messageKey="web.templates.categoryName"
          sideLabel
          width={INPUT_WIDTH}
        >
          <Text
            autoFocus
            id="create-new-category"
            onChange={this.onCategoryChange}
            onKeyDown={this.onKeyDown}
            type="text"
            value={newCategory}
          />
        </Label>
      </div>
    );
  }

  getFirstCampaignMessage = () => {
    const { isFirstCampaign } = this.props;
    return (
      isFirstCampaign && (
        <FormattedMessage id="web.campaigns.enterNewCategoryNewCampaign" />
      )
    );
  };

  onCategoryChange = (value) => {
    this.props.onCategoryChange(value);
  };

  onKeyDown = (e) => {
    if (e.keyCode === RETURN_KEY_CODE) {
      this.props.onCreate();
    }
  };
}

CreateNewCategory.propTypes = {
  isFirstCampaign: PropTypes.bool,
  newCategory: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

CreateNewCategory.defaultProps = {
  isFirstCampaign: false,
};

export default CreateNewCategory;
