import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Text from 'components/inputs/text';
import './dynamicFieldNumberChooser.scss';

const MAX_INPUT_LENGTH = 6;
const RETURN_KEY_CODE = 13;

class DynamicFieldNumberChooser extends Component {
  componentWillMount() {
    const {
      intl: { formatMessage },
      message,
      messageId,
    } = this.props;

    this.numberInputPlaceholder = formatMessage({
      id: 'dynamicFields.chooseNumberOfDaysPlaceholder',
    });
    this.prompt = message || formatMessage({ id: messageId });
  }

  render() {
    const { value } = this.props;

    return (
      <div>
        <div className="margin-top-full margin-bottom-full">{this.prompt}</div>
        <Text
          className="dynamic-field-number-input"
          maxLength={MAX_INPUT_LENGTH}
          onChange={this.onInputChange}
          onKeyDown={this.onKeyDown}
          placeholder={this.numberInputPlaceholder}
          value={value}
        />
      </div>
    );
  }

  onInputChange = (value = '') => {
    const { onChange } = this.props;

    if (!Number.isNaN(value)) {
      onChange(value);
    }
  };

  onKeyDown = (e) => {
    const { dynamicField, onSubmit, value } = this.props;

    if (e.keyCode === RETURN_KEY_CODE && value) {
      onSubmit(value, dynamicField);
    }
  };
}

DynamicFieldNumberChooser.propTypes = {
  dynamicField: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  message: PropTypes.string,
  messageId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

DynamicFieldNumberChooser.defaultProps = {
  message: '',
  messageId: '',
};

export default injectIntl(DynamicFieldNumberChooser);
