import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import IntegrationsPopups from '../components/popups';
import { deleteIntegration as deleteBccIntegration } from '../actionCreators/bccActionCreators';
import { deleteIntegration as deleteSlackIntegration } from '../actionCreators/slackActionCreators';
import { deleteIntegration as deleteBoxIntegration } from '../actionCreators/boxActionCreators';
import {
  navigateToSlackConnect,
  navigateToBoxConnect,
} from '../helpers/integrationsHelpers';
import { IntegrationsPopupIds } from '../libs/integrationConstants';

const IntegrationsPopupContainer = (props) => <IntegrationsPopups {...props} />;

const deleteIntegrationMap = {
  [IntegrationsPopupIds.deleteBcc]: deleteBccIntegration,
  [IntegrationsPopupIds.deleteSlack]: deleteSlackIntegration,
  [IntegrationsPopupIds.deleteBox]: deleteBoxIntegration,
};

const navigateToServiceMap = {
  [IntegrationsPopupIds.navigateToSlack]: navigateToSlackConnect,
  [IntegrationsPopupIds.navigateToBox]: navigateToBoxConnect,
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  popupData: state.popupData,
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(closePopup()),
  dispatch,
});

const mergeProps = (
  { popup, ...otherStateProps },
  { dispatch, ...otherDispatchProps },
  ownProps
) => ({
  popup,
  ...otherStateProps,
  ...otherDispatchProps,
  ...ownProps,
  deleteIntegration: () => dispatch(deleteIntegrationMap[popup]()),
  navigateToService: navigateToServiceMap[popup],
});

IntegrationsPopupContainer.propTypes = {
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object,
};

IntegrationsPopupContainer.defaultProps = {
  popupData: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(IntegrationsPopupContainer);
