import { getIntegration as getBcc } from './bccActionCreators';
import { getIntegration as getSlack } from './slackActionCreators';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { isContentPartnersLoaded } from '../selectors/integrationsSelectors';
import { getContentPartners } from 'web/modals/addContent/actionCreators/contentActionCreators';

export const integrationsStartup = () => (dispatch, getState) => {
  dispatch(commonStartup());

  //BCC
  dispatch(getBcc());

  //Slack
  dispatch(getSlack());

  //HighSpot
  if (!isContentPartnersLoaded(getState())) {
    dispatch(getContentPartners());
  }
};
