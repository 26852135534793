import React from 'react';
import PropTypes from 'prop-types';
import LinkCell from 'table/modules/cells/linkCell';
import TextCustomCell from 'table/modules/cells/textCustomCell';

function LinkOrTextCell(props) {
  const { rowData, onClick, formatText, property, showLink } = props;

  return showLink(rowData) ? (
    <LinkCell
      rowData={rowData}
      formatText={formatText}
      onClick={onClick}
      property={property}
    />
  ) : (
    <TextCustomCell
      rowData={rowData}
      formatText={formatText}
      property={property}
      wrapLines={1}
    />
  );
}

LinkOrTextCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  formatText: PropTypes.func.isRequired,
  property: PropTypes.string,
  showLink: PropTypes.func.isRequired,
};

export default LinkOrTextCell;
