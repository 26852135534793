import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';

export const openTemplateBulkUpdateAlert = (
  successes = [],
  errors = [],
  updateAttributes
) => (dispatch) => {
  const alertValues = {
    errors: errors.length,
    successes: successes.length,
    updateAttributes,
  };
  if (errors.length || !successes.length) {
    dispatch(openViewAlert(TemplateAlertIds.bulkUpdateError, alertValues));
  } else if (successes.length) {
    dispatch(openViewAlert(TemplateAlertIds.bulkUpdateSuccess, alertValues));
  }
};

export const openTemplateUndoAlert = (successes = [], errors = []) => (
  dispatch
) => {
  const alertValues = { errors: errors.length, successes: successes.length };

  if (!errors.length) {
    dispatch(openViewAlert(TemplateAlertIds.bulkUndoSuccess));
  } else {
    dispatch(openViewAlert(TemplateAlertIds.bulkUpdateError, alertValues));
  }
};
