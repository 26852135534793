import { navigateToUrl } from 'web/services/routerService';
import { Urls } from 'web/libs/constants';

export const navigateToSlackConnect = () => {
  navigateToUrl(Urls.slackAuth);
};

export const navigateToBoxConnect = () => {
  navigateToUrl(Urls.connectBox);
};
