import CCActionTypes from 'web/commandCenter/libs/commandCenterActionTypes';
import { moment } from 'languages';
import { LocalStorageKeys } from 'web/libs/constants';
import { CommandCenterPopupIds } from 'web/commandCenter/libs/commandCenterConstants';
import { commandCenterStartup } from 'web/commandCenter/actionCreators/commandCenterStartup';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { getActiveCampaigns } from 'web/commandCenter/services/campaignsService';
import { openModalTask } from 'web/modals/task';
import { track } from 'web/services/mixpanelService';
import { CommandCenterTaskEvents } from 'web/libs/mixpanelEvents';

export function getCampaignsActive() {
  return (dispatch) => {
    dispatch({ fetching: true, type: CCActionTypes.popup.dataFetching });
    getActiveCampaigns()
      .then((data) => {
        dispatch({ data, type: CCActionTypes.data.activeCampaigns });
        dispatch({ fetching: false, type: CCActionTypes.popup.dataFetching });
      })
      .catch(() => {
        dispatch({ fetching: false, type: CCActionTypes.popup.dataFetching });
      });
  };
}

export function initCommandCenter() {
  return (dispatch) => {
    dispatch(commandCenterStartup());
    dispatch({
      isChanged: false,
      type: CCActionTypes.state.changeViewAsFilter,
    });
    const lastOpened = window.localStorage.getItem(
      LocalStorageKeys.commandCenter.lastOpened
    );
    if (!lastOpened || !moment(lastOpened).isSame(moment(), 'd')) {
      dispatch(getCampaignsActive());
      dispatch(openPopup(CommandCenterPopupIds.welcome));
      window.localStorage.setItem(
        LocalStorageKeys.commandCenter.lastOpened,
        moment().toISOString()
      );
    }
  };
}

export function setLoading(loading = false) {
  return {
    loading,
    type: CCActionTypes.state.loading,
  };
}

export function openAddTask(task) {
  track(CommandCenterTaskEvents.addTask);
  openModalTask(task);
}
