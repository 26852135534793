export const AuthorizationTypes = {
  consent: 'consent',
  legitimateInterest: 'legitimate-interest',
  performanceOfAContract: 'performance-of-a-contract',
  complianceWithLegalObligation: 'compliance-of-legal-obligation',
  protectionOfVitalInterests: 'protection-of-vital-interests',
  publicInterest: 'public-interest',
  other: 'authorization-other',
};

export const SourceTypes = {
  chromeExtension: 'chrome-extension',
  crmSync: 'crm-sync',
  import: 'import',
  manualUpload: 'manual-upload',
  other: 'source-other',
  salesforce: 'salesforce',
  marketing: 'marketing',
  gmail: 'gmail',
  o365: 'o365',
  sales: 'sales-engagement',
};

export const ModalComponentIds = {
  authorizationEdit: 'MODAL_COMPONENT_AUTHORIZATION_EDIT',
  authorizationEditingSuccess: 'MODAL_COMPONENT_AUTHORIZATION_EDITING_SUCCESS',
  peopleSourceModal: 'PEOPLE_SOURCE_MODAL_ID',
  peopleSourceModalSuccess: 'PEOPLE_SOURCE_MODAL_SUCCESS_ID',
};

export const PEOPLE_SOURCE_POPUP_ID = 'PEOPLE_SOURCE';

export const PEOPLE_AUTHORIZATION_POPUP_ID = 'PEOPLE_AUTHORIZATION';
