import MarketoSubscriptionsActionTypes from 'web/marketo/subscriptions/libs/marketoSubscriptionsActionTypes.js';
import { getMarketoSubDetails as getMarketoSubDetailsCall } from 'web/marketo/subscriptions/services/marketoSubscriptionsServices.js';

export const setMarketoSubDetails = (details) => ({
  details,
  type: MarketoSubscriptionsActionTypes.setMarketoSubDetails,
});

export const getMarketoSubDetails = () => (dispatch) =>
  getMarketoSubDetailsCall()
    .then((details) => {
      dispatch(setMarketoSubDetails(details));
    })
    .catch(() => {});
