import {
  setSmartFilter,
  removeSmartFilter,
} from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { CAMPAIGN_FILTER_ID } from 'web/commandCenter/modules/campaignSmartFilter/libs/campaignSmartFilterConstants';

export const setCampaignFilterValue = (value) => (dispatch) => {
  dispatch(
    setSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: CAMPAIGN_FILTER_ID,
      payload: { ...value },
    })
  );
};

export const clearCampaignFilterValue = () => (dispatch) => {
  dispatch(
    removeSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: CAMPAIGN_FILTER_ID,
    })
  );
};
