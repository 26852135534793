import { createSelector } from 'reselect';
import { TEMPLATE_SPECIAL_CATEGORY } from 'web/templates/libs/templatesConstants';

const getTemplatesAll = (state) => state.templatesAll;

export const getTemplates = createSelector([getTemplatesAll], (templates) =>
  templates.filter(
    ({ category }) => category !== TEMPLATE_SPECIAL_CATEGORY.archived.apiName
  )
);

export const getDefaultDynamicFieldsActiveCategory = (state) => {
  const editorId = state.emailComposePopup.editorId;
  return !editorId ? '' : state.dynamicFieldsActiveCategory[editorId];
};
