import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './label.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export default class Label extends Component {
  render() {
    const {
      children,
      classChildren,
      className,
      classWrapper,
      forInput,
      id,
      messageKey,
      message,
      sideLabel,
      width,
      requiredAsterisk,
    } = this.props;
    const label = classNames('label-label', className, {
      'side-label': sideLabel,
      'input-label': forInput,
      required: requiredAsterisk,
    });
    const child = classNames('label-child', classChildren, {
      'side-label': sideLabel,
    });

    return (
      <div
        className={classNames('tout-label', classWrapper, {
          'side-label': sideLabel,
        })}
      >
        <label
          className={label}
          htmlFor={id}
          style={this._getWidth(sideLabel, width)}
        >
          {message || <FormattedMessage id={messageKey} />}
        </label>
        <div className={child} id={id}>
          {children}
        </div>
      </div>
    );
  }

  _getWidth(respectWidth, width) {
    if (respectWidth) {
      return { width: `${width}px` };
    }
    return { width: '100%' };
  }
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  classChildren: PropTypes.string,
  className: PropTypes.string,
  classWrapper: PropTypes.string,
  id: PropTypes.string.isRequired,
  message: PropTypes.string,
  messageKey: PropTypes.string,
  sideLabel: PropTypes.bool,
  forInput: PropTypes.bool,
  width: PropTypes.number,
  requiredAsterisk: PropTypes.bool,
};

Label.defaultProps = {
  classChildren: '',
  className: '',
  classWrapper: '',
  forInput: true,
  message: '',
  messageKey: '',
  sideLabel: false,
  width: 50,
  requiredAsterisk: false,
};
