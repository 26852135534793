import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { CampaignStepActions } from 'web/libs/constants';
import { TimezonesCategoriesOrderedEnum } from 'web/timezones/libs/timezonesConstants';
import {
  GlobalAlertIds,
  GlobalPopupIds,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import {
  openCampaignAlert,
  closeCampaignAlert,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsAlertActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  createStep,
  deleteStep as deleteCampaignStepCall,
  updateCampaignStep as updateCampaignStepCall,
  updateStep as updateStepCall,
} from 'web/campaigns/campaignsTab/services/campaignStepService';
import { getCampaign as getCampaignCall } from 'web/campaigns/campaignsTab/services/campaignService';
import { deleteFiles } from './campaignEditTemplateActionCreators';
import {
  createCampaignTemplate as createCampaignTemplateCall,
  editCampaignTemplate as editCampaignTemplateCall,
} from 'web/campaigns/campaignsTab/services/templateService';
import {
  parseIds,
  parseIdsForNewUploadsOnly,
} from 'web/campaigns/campaignsTab/services/attachmentsService';
import { updateCampaign } from './campaignDetailsActionCreators';
import {
  parseDynamicFieldsValidation,
  parseInvalidEmails,
  parseTask,
} from 'web/campaigns/campaignsTab/helpers/parsers';
import queue from 'async/queue';
import { standardizeTime } from 'web/campaigns/campaignsTab/helpers/formatting';
import { DEFAULT_SCHEDULE_TIME } from 'libs/constantsShared';

const queuedUpdateCall = queue((data, cb) => {
  updateCampaignStepCall(data).then((updatedData) => {
    toutBackboneHelper.campaignUpdate(updatedData);
    cb();
  });
});

const isEmailTask = (step) => step.action === CampaignStepActions.emailTask;

function shouldAutosend(step, timezone) {
  return !!(
    step.action === CampaignStepActions.email &&
    step.action_params.time &&
    timezone.value
  );
}

const shouldSendAtPreviousTime = (step, timezone) =>
  (step.action === CampaignStepActions.email &&
    !(step.action_params.time || timezone.value)) ||
  !(isEmailTask(step) || shouldAutosend(step, timezone));

export function initCampaignEditEmailState(
  editorId,
  step = { action_params: {} }
) {
  return (dispatch, getState) => {
    /*eslint complexity: [1, 14]*/
    const {
      campaignsTabCampaignTemplates,
      templates,
      userTimezone,
      timezones,
    } = getState();

    let campaignTemplate = {};
    let findTimezone = {};
    let time = DEFAULT_SCHEDULE_TIME;
    let tz = userTimezone;
    if (step.action_params) {
      const id = parseInt(step.action_params.template_id, 10);
      campaignTemplate = find(campaignsTabCampaignTemplates, { id }) || {};
      time = step.action_params.time || time;
      findTimezone =
        find(timezones[1], { displayName: step.action_params.timezone }) || {};
      tz = findTimezone.value || tz;
    }

    /* Don't ever want to show campaign template name so replace it with source if one exists */
    let sourceTemplateName = '';
    if (campaignTemplate.source_pitch_template_id) {
      const template =
        find(templates, { id: campaignTemplate.source_pitch_template_id }) ||
        {};
      sourceTemplateName = template.name || '';
    }

    dispatch({
      type: ActionTypes.campaignsTab.state.campaignEditStateInit,
      id: editorId,
      step,
      data: {
        autoSend: shouldAutosend(step, findTimezone),
        bcc: campaignTemplate.bcc || '',
        cc: campaignTemplate.cc || '',
        completeBeforeMoving: step.blocking ? step.blocking : true,
        emailComposeBodySelectedTemplate: {
          // needs to match template model
          body: campaignTemplate.body,
          id: campaignTemplate.id,
          name: sourceTemplateName,
          source_pitch_template_id: campaignTemplate.source_pitch_template_id,
          subject: campaignTemplate.subject,
          workflow_pitch_template: !!campaignTemplate.id,
        },
        manual: isEmailTask(step),
        subject: campaignTemplate.subject || '',
        previousTime: shouldSendAtPreviousTime(step, findTimezone),
        template: campaignTemplate,
        threadMessages: step.threaded,
        time,
        timezone: tz,
      },
    });
  };
}

export function initCampaignEditReminderState(editorId, action, step = {}) {
  let notesOriginal = step.action_params
    ? step.action_params.custom_step || step.action_params.script || ''
    : '';
  notesOriginal = parseTask(notesOriginal);

  return {
    type: ActionTypes.campaignsTab.state.campaignEditStateInit,
    id: editorId,
    step,
    data: {
      action,
      completeBeforeMoving: step.blocking ? step.blocking : true,
      name: step.name || '',
      notes: notesOriginal,
      notesOriginal,
    },
  };
}

export function updateCampaignEditState(editorId, data = {}) {
  return (dispatch, getState) => {
    const passedData = { ...data };
    /* Don't want to overwrite campaign template with template info */
    if (data.template && !data.template.workflow_pitch_template) {
      const { campaignsTabEditStates } = getState();
      const editState = campaignsTabEditStates[editorId];
      passedData.emailComposeBodySelectedTemplate = {
        body: data.template.body,
        name: data.template.name,
        subject: data.template.subject,
        source_pitch_template_id: data.template.id,
      };
      passedData.subject = data.template.subject || '';
      if (data.template.cc) {
        passedData.cc = `${editState.cc} ${data.template.cc}`;
      }
      if (data.template.bcc) {
        passedData.bcc = `${editState.bcc} ${data.template.bcc}`;
      }
      Reflect.deleteProperty(passedData, 'template');
    }

    dispatch({
      type: ActionTypes.campaignsTab.state.campaignEditStateUpdate,
      id: editorId,
      data: passedData,
    });
  };
}

export function destroyCampaignEditState(
  editorId,
  step = {},
  preserveFiles = false
) {
  return (dispatch, getState) => {
    /* if statement added to avoid double-cleaning up as compnonentUnmount is called w/ old props so calls destroy again */
    if (getState().campaignsTabEditingSteps[step.id || editorId]) {
      dispatch({
        type: ActionTypes.campaignsTab.state.campaignEditStateDestroy,
        id: editorId,
        day: step.day,
        stepId: step.id || editorId,
      });

      // Clean up added attachments - known bug: does not clean up files that haven't finished uploading //todo
      const ids = parseIdsForNewUploadsOnly(
        getState().emailComposeAttachmentsMap[editorId]
      );
      if (!preserveFiles && ids.length) {
        dispatch(deleteFiles(ids));
      }
    }
  };
}

export function addingNewDay() {
  return {
    type: ActionTypes.campaignsTab.state.addingNewDay,
  };
}

export function notAddingNewDay() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.campaignsTab.state.notAddingNewDay });
    dispatch(openCampaignAlert(GlobalAlertIds.newDayNumberRequired));
  };
}

export function setNewDayNumber(data) {
  return {
    type: ActionTypes.campaignsTab.data.newDayNumber,
    data,
  };
}

function resetNewDay() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.campaignsTab.state.notAddingNewDay });
    dispatch(setNewDayNumber(''));
  };
}

export function updateStepData(step) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
      id: step.day,
    });
    dispatch({
      type: ActionTypes.common.data.campaignUpdateStep,
      id: step.workflow_id,
      step,
    });
  };
}

const getEmailAction = (data) =>
  data.autoSend || data.previousTime
    ? CampaignStepActions.email
    : CampaignStepActions.emailTask;

export function createCampaignTemplate(data, body, editorId, day) {
  return (dispatch, getState) => {
    const {
      campaignsTabSelectedCampaign: campaignId,
      emailComposeAttachmentsMap,
      timezones,
      campaigns,
    } = getState();
    const campaign = find(campaigns, { id: campaignId }) || {};
    const attachments = parseIds(emailComposeAttachmentsMap[editorId]);

    dispatch({
      type: ActionTypes.campaignsTab.state.campaignDayLoadStart,
      id: day,
    });

    createCampaignTemplateCall(
      campaign.category,
      `${data.subject} - campaign`,
      data.subject,
      data.cc,
      data.bcc,
      body,
      {},
      attachments,
      data.emailComposeBodySelectedTemplate.source_pitch_template_id
    )
      .then((templateData) => {
        const timezone = find(timezones[1], { value: data.timezone }) || {};
        dispatch({
          type: ActionTypes.campaignsTab.data.campaignTemplateAdd,
          data: templateData,
        });
        return createStep({
          action: getEmailAction(data),
          completeBeforeMoving: data.completeBeforeMoving,
          day: data.day,
          name: data.subject,
          templateId: templateData.id,
          threadMessages: data.threadMessages,
          time: data.autoSend ? standardizeTime(data.time) : undefined,
          timezone: data.autoSend ? timezone.displayName || '' : undefined,
          workflowId: campaign.id,
        });
      })
      .then((stepData) => {
        dispatch({
          type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
          id: day,
        });
        dispatch({
          type: ActionTypes.common.data.campaignAddStep,
          id: campaign.id,
          step: stepData,
        });
        dispatch(resetNewDay());
        toutBackboneHelper.stepUpdate(stepData);
        dispatch(destroyCampaignEditState(editorId, stepData, true));
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
          id: day,
        });
      }); //todo else error?
  };
}

export function addEmailStep(editorId, day) {
  return (dispatch, getState) => {
    const { campaignsTabEditStates, emailComposeEditors } = getState();
    const instance = emailComposeEditors[editorId];
    if (instance) {
      const body = instance.getContent();
      const data = { ...campaignsTabEditStates[editorId], day };
      const { subjectError, bodyError } = parseDynamicFieldsValidation(
        body,
        data.subject
      );
      const ccErrors = parseInvalidEmails(data.cc);
      const bccErrors = parseInvalidEmails(data.bcc);

      if (subjectError || bodyError) {
        // problem is that it still closes - do in component?
        dispatch(
          openCampaignAlert(GlobalAlertIds.campaignTemplateErrors, {
            body: bodyError,
            subject: subjectError,
          })
        );
      } else if (ccErrors.length || bccErrors.length) {
        const ccExists = ccErrors.length > 0;
        const bccExists = bccErrors.length > 0;
        dispatch(
          openCampaignAlert(GlobalAlertIds.campaignTemplateErrorsEmails, {
            bothExist: ccExists && bccExists,
            ccExists,
            bccExists,
            ccs: ccErrors.join(', '),
            bccs: bccErrors.join(', '),
          })
        ); // still closes?
      } else {
        dispatch(createCampaignTemplate(data, body, editorId, day));
      }
    }
  };
}

const updatedTimeAndTimezone = (data) => (dispatch, getState) => {
  const updatedData = {};
  if (data.autoSend) {
    const timezoneObj =
      find(getState().timezones[TimezonesCategoriesOrderedEnum.international], {
        value: data.timezone,
      }) || {};
    updatedData.timezone = timezoneObj.displayName || data.timezone || '';
  } else {
    updatedData.timezone = null;
    updatedData.time = null;
  }
  return updatedData;
};

export function editCampaignTemplate(data, body, editorId, step) {
  return (dispatch, getState) => {
    const { emailComposeAttachmentsMap } = getState();
    dispatch({
      type: ActionTypes.campaignsTab.state.campaignDayLoadStart,
      id: step.day,
    });

    data.action = getEmailAction(data); // eslint-disable-line no-param-reassign
    const attachments = parseIds(emailComposeAttachmentsMap[editorId]);

    editCampaignTemplateCall(
      {
        ...data.template,
        body,
        subject: data.subject,
        name: `${data.subject} - campaign`,
        bcc: data.bcc,
        cc: data.cc,
      },
      attachments,
      data.emailComposeBodySelectedTemplate.source_pitch_template_id
    ) // attachments should all be finished uploading
      .then((templateData) => {
        data.templateId = templateData.id; // eslint-disable-line no-param-reassign
        dispatch({
          type: ActionTypes.campaignsTab.data.campaignTemplateUpdate,
          id: templateData.id,
          data: templateData,
        });
        const updatedData = {
          ...data,
          ...dispatch(updatedTimeAndTimezone(data)),
        };
        return updateStepCall(updatedData, step);
      })
      .then((stepData) => {
        dispatch(updateStepData(stepData));
        toutBackboneHelper.stepUpdate(stepData);
        dispatch(destroyCampaignEditState(editorId, stepData, true));
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
          id: step.day,
        });
      });
  }; //todo else error?
}

export function updateEmailStep(editorId, step) {
  return (dispatch, getState) => {
    const { campaignsTabEditStates, emailComposeEditors } = getState();
    const instance = emailComposeEditors[editorId];
    if (instance) {
      const body = instance.getContent();
      const data = { ...campaignsTabEditStates[editorId] };

      const { subjectError, bodyError } = parseDynamicFieldsValidation(
        body,
        data.subject
      );
      const ccErrors = parseInvalidEmails(data.cc);
      const bccErrors = parseInvalidEmails(data.bcc);

      if (subjectError || bodyError) {
        dispatch(
          openCampaignAlert(GlobalAlertIds.campaignTemplateErrors, {
            body: bodyError,
            subject: subjectError,
          })
        );
      } else if (ccErrors.length || bccErrors.length) {
        const ccExists = ccErrors.length > 0;
        const bccExists = bccErrors.length > 0;
        dispatch(
          openCampaignAlert(GlobalAlertIds.campaignTemplateErrorsEmails, {
            bothExist: ccExists && bccExists,
            ccExists,
            bccExists,
            ccs: ccErrors.join(', '),
            bccs: bccErrors.join(', '),
          })
        ); // still closes?
      } else {
        dispatch(editCampaignTemplate(data, body, editorId, step));
      }
    }
  }; //todo else error?
}

export function addStep(editorId, day, action) {
  return (dispatch, getState) => {
    const {
      campaignsTabEditStates,
      campaignsTabSelectedCampaign: campaignId,
    } = getState();
    const data = {
      ...campaignsTabEditStates[editorId],
      day,
      action,
      workflowId: campaignId,
    };

    dispatch({
      type: ActionTypes.campaignsTab.state.campaignDayLoadStart,
      id: day,
    });

    createStep(data)
      .then((stepData) => {
        dispatch({
          type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
          id: day,
        });
        dispatch({
          type: ActionTypes.common.data.campaignAddStep,
          id: campaignId,
          step: stepData,
        });
        dispatch(resetNewDay());
        toutBackboneHelper.stepUpdate(stepData);
        dispatch(destroyCampaignEditState(editorId, stepData, true));
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
          id: day,
        });
      }); //todo else error?
  };
}

export function updateStep(editorId, step) {
  return (dispatch, getState) => {
    const data = getState().campaignsTabEditStates[editorId];
    dispatch({
      type: ActionTypes.campaignsTab.state.campaignDayLoadStart,
      id: step.day,
    });

    updateStepCall(data, step)
      .then((stepData) => {
        dispatch(updateStepData(stepData));
        toutBackboneHelper.stepUpdate(stepData);
        dispatch(destroyCampaignEditState(editorId, stepData, true));
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
          id: step.day,
        });
      });
  }; //todo else error?
}

export function optimisticUpdateCampaignStep(updatedStep) {
  return (dispatch) => {
    queuedUpdateCall.push(cloneDeep(updatedStep));
    dispatch(resetNewDay());
    dispatch(updateStepData(updatedStep));
  };
}

export function updateCampaignStep(updatedCampaignStep, save) {
  return (dispatch, getState) => {
    const { campaigns } = getState();
    const updatedCampaigns = cloneDeep(campaigns);
    updatedCampaigns.forEach((campaign, index) => {
      if (campaign.id === updatedCampaignStep.workflow_id) {
        campaign.steps.forEach((step, stepIndex) => {
          if (step.id === updatedCampaignStep.id) {
            updatedCampaigns[index].steps[stepIndex] = updatedCampaignStep;
          }
        });
      }
    });

    dispatch({
      type: ActionTypes.data.success,
      data: updatedCampaigns,
    });

    if (save) {
      dispatch({
        type: ActionTypes.campaignsTab.state.campaignDayLoadStart,
        id: updatedCampaignStep.day,
      });
      updateCampaignStepCall(updatedCampaignStep)
        .then((data) => {
          dispatch({
            type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
            id: data.day,
          });
          dispatch({
            type: ActionTypes.common.data.campaignUpdateStep,
            id: data.workflow_id,
            step: data,
          });
          toutBackboneHelper.stepUpdate(data);
        })
        .catch(() => {
          dispatch({
            type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
            id: updatedCampaignStep.day,
          });
        });
    }
  };
}

export function deleteCampaignStep(deletedCampaignStep) {
  return (dispatch) => {
    const { day } = deletedCampaignStep;
    const campaignId = deletedCampaignStep.workflow_id;

    dispatch({
      type: ActionTypes.campaignsTab.state.campaignDayLoadStart,
      id: day,
    });

    deleteCampaignStepCall(deletedCampaignStep)
      .then(() => getCampaignCall(campaignId))
      .then((updatedCampaignData) => {
        dispatch(updateCampaign(updatedCampaignData, false));
        dispatch({
          type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
          id: day,
        });
        toutBackboneHelper.campaignUpdate(updatedCampaignData);
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.campaignsTab.state.campaignDayLoadEnd,
          id: deletedCampaignStep.day,
        });
      });
  };
}

export function openEditWarningAlert() {
  return (dispatch, getState) => {
    const { campaignsTabSelectedCampaign: campaignId, campaigns } = getState();
    const { currently_active_count: currentlyActiveCount } = find(campaigns, {
      id: campaignId,
    }) || {
      currently_active_count: {},
    };
    if (currentlyActiveCount && Object.keys(currentlyActiveCount).length) {
      dispatch(openCampaignAlert(GlobalAlertIds.editLiveCampaignWarning));
    }
  };
}

export function openEditWarningPopup() {
  return (dispatch) => {
    dispatch(closeCampaignAlert());
    dispatch(openPopup(GlobalPopupIds.editLiveCampaignWarning));
  };
}
