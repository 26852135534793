import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import {
  DynamicChatActivityTypeEnum,
  DynamicChatEventAttributes,
  MarketoEvents,
  ToutEvents,
  UNSEEN_EVENT_INDICATOR_REMOVAL_TIME,
  EVENT_ICONS,
} from '../../libs/liveFeedConstants';
import { TextWeights } from 'libs/constantsStyles';
import DateAgo from 'components/dates/dateAgo';
import TextButton from 'components/buttons/text';
import { Regex } from 'web/libs/constants';
import './liveFeedEvent.scss';

class LiveFeedEvent extends Component {
  constructor() {
    super();
    this.mountingTimeout = null;
  }

  componentDidMount() {
    const { unseen } = this.props;

    if (unseen) {
      if (document.hasFocus()) {
        this.markAsSeen();
      } else {
        window.addEventListener('focus', this.markAsSeen, { once: true });
      }
    }
  }

  componentWillUnmount() {
    if (this.mountingTimeout) {
      clearTimeout(this.mountingTimeout);
    }
  }

  render() {
    const { staticView, unseen } = this.props;

    return (
      <div
        className={classNames('live-feed-event-2', {
          'static-view': staticView,
        })}
      >
        <div className="live-feed-event-normal">
          <div className="live-feed-event-details">
            {this._getEvent()}
            {this._getEventMetadata()}
          </div>
          {unseen &&
            !staticView && <div className="live-feed-event-indicator">●</div>}
        </div>
      </div>
    );
  }

  _getEventMetadata = () => {
    const { date, showNowTime } = this.props;

    return (
      <div className="live-feed-event-metadata">
        <div className="live-feed-event-datetime">
          <DateAgo
            className="text-gray"
            datetime={date}
            showNowTime={showNowTime}
          />
        </div>
        {this._getLocation()}
      </div>
    );
  };

  markAsSeen = () => {
    const { eventId, markEventAsSeen } = this.props;

    setTimeout(() => {
      markEventAsSeen(eventId);
    }, UNSEEN_EVENT_INDICATOR_REMOVAL_TIME);
  };

  _onClick = () => {
    const { emailId, openEmailInfo } = this.props;
    openEmailInfo(emailId);
  };

  _getEmailInfo() {
    const {
      emailId,
      emailSubject,
      openEmailInfo,
      showEmailLink,
      staticView,
    } = this.props;

    if (
      staticView &&
      showEmailLink &&
      emailId &&
      emailSubject &&
      openEmailInfo
    ) {
      return (
        <TextButton
          onClick={this._onClick}
          text={emailSubject}
          weight={TextWeights.light}
          underline
        />
      );
    } else if (staticView && emailSubject) {
      return <span className="text-blue">{emailSubject}</span>;
    } else {
      return null;
    }
  }

  _getLocation = () => {
    const { city, state } = this.props;
    const hasCityState = !!(city && state);
    const stateValue = Regex.integer.test(state) ? '' : state;
    return (
      <div className="live-feed-event-location">
        {
          <FormattedHTMLMessage
            id="web.liveFeed.toutEvents.location"
            values={{
              city,
              hasCityState,
              stateValue,
            }}
          />
        }
      </div>
    );
  };

  _formatUrl = (url) => url.replace(/^https?:\/\//, '');

  _getEvent = () => {
    const INITIATED = 'Initiated';
    const SCHEDULED = 'Scheduled';
    const DROPPED = 'Dropped';
    const DYNAMIC_CHAT_ACTIVITY_SCHEDULED_DATE_TIME_FORMAT =
      'MMMM Do YYYY, h:mm:ss A';

    const {
      description,
      staticView,
      title,
      type,
      url,
      activity: { attributes, typeId },
    } = this.props;
    let nameElement = <span>{description}</span>;
    let eventIcon = null;
    const emailElement = this._getEmailInfo();
    const hasEmail = emailElement !== null;
    const circleProps = { className: 'fill-yellow', cx: 7, cy: 7, r: 7 }; //eslint-disable-line id-length

    switch (type) {
      case MarketoEvents.dynamicChat:
        switch (typeId) {
          case DynamicChatActivityTypeEnum.DIALOGUE_ENGAGED:
            if (
              attributes[DynamicChatEventAttributes.conversationStatus] ===
              INITIATED
            ) {
              nameElement = (
                <FormattedHTMLMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.initiatedConversation"
                  values={{
                    pageURL: attributes[DynamicChatEventAttributes.pageUrl],
                  }}
                />
              );
            } else {
              nameElement = (
                <FormattedHTMLMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.completedConversation"
                  values={{
                    pageURL: attributes[DynamicChatEventAttributes.pageUrl],
                  }}
                />
              );
            }
            break;
          case DynamicChatActivityTypeEnum.DIALOGUE_APPOINTMENT_SCHEDULED:
            if (
              attributes[DynamicChatEventAttributes.meetingStatus] === SCHEDULED
            ) {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.meetingScheduledFor"
                  values={{
                    time: moment(
                      attributes[DynamicChatEventAttributes.scheduledFor]
                    ).format(DYNAMIC_CHAT_ACTIVITY_SCHEDULED_DATE_TIME_FORMAT),
                  }}
                />
              );
            } else {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.meetingStatus"
                  values={{
                    status:
                      attributes[DynamicChatEventAttributes.meetingStatus],
                  }}
                />
              );
            }
            break;
          case DynamicChatActivityTypeEnum.DIALOGUE_GOAL_REACHED:
            nameElement = (
              <FormattedMessage
                id="web.liveFeed.marketoEvents.dynamicChat.reachedGoal"
                values={{
                  goalName: attributes[DynamicChatEventAttributes.goalName],
                }}
              />
            );
            break;
          case DynamicChatActivityTypeEnum.DOCUMENT_INTERACTED_WITH:
            nameElement = (
              <div>
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.interactedWithDoc"
                  values={{
                    docName:
                      attributes[DynamicChatEventAttributes.documentName],
                  }}
                />
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.docOpened"
                  values={{
                    hasOpened:
                      attributes[DynamicChatEventAttributes.documentOpened],
                  }}
                />
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.docDownloaded"
                  values={{
                    hasDownloaded:
                      attributes[DynamicChatEventAttributes.documentDownloaded],
                  }}
                />
              </div>
            );
            break;
          case DynamicChatActivityTypeEnum.ENGAGED_WITH_AN_AGENT:
            if (
              attributes[DynamicChatEventAttributes.conversationStatus] ===
              DROPPED
            ) {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.droppedFromLiveChat"
                  values={{
                    agentName: attributes[DynamicChatEventAttributes.agentName],
                  }}
                />
              );
            } else {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.completedLiveChat"
                  values={{
                    dialogueName:
                      attributes[DynamicChatEventAttributes.dialogueName],
                  }}
                />
              );
            }
            break;
          case DynamicChatActivityTypeEnum.CLICKED_LINK_IN_CHAT_DIALOGUE:
            nameElement = (
              <FormattedHTMLMessage
                id="web.liveFeed.marketoEvents.dynamicChat.clickedLinkInDialogue"
                values={{
                  dialogueName:
                    attributes[DynamicChatEventAttributes.dialogueName],
                  link: attributes[DynamicChatEventAttributes.link],
                }}
              />
            );
            break;
          case DynamicChatActivityTypeEnum.ENGAGED_WITH_CONVERSATIONAL_FORM:
            if (
              attributes[DynamicChatEventAttributes.conversationStatus] ===
              INITIATED
            ) {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.conversationInitiatedWithConversationalFlow"
                  values={{
                    conversationalFlowName:
                      attributes[
                        DynamicChatEventAttributes.conversationalFlowName
                      ],
                  }}
                />
              );
            } else {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.conversationCompletedWithConversationalFlow"
                  values={{
                    conversationalFlowName:
                      attributes[
                        DynamicChatEventAttributes.conversationalFlowName
                      ],
                  }}
                />
              );
            }
            break;
          case DynamicChatActivityTypeEnum.SCHEDULED_MEETING_IN_CONVERSATIONAL_FORM:
            nameElement = (
              <FormattedMessage
                id="web.liveFeed.marketoEvents.dynamicChat.scheduledMeetingInConversationalFlow"
                values={{
                  agentName: attributes[DynamicChatEventAttributes.agentName],
                  scheduledFor: moment(
                    attributes[DynamicChatEventAttributes.scheduledFor]
                  ).format(DYNAMIC_CHAT_ACTIVITY_SCHEDULED_DATE_TIME_FORMAT),
                }}
              />
            );
            break;
          case DynamicChatActivityTypeEnum.REACHED_CONVERSATIONAL_FORM_GOAL:
            nameElement = (
              <FormattedMessage
                id="web.liveFeed.marketoEvents.dynamicChat.reachedConversationalFlowGoal"
                values={{
                  goalName: attributes[DynamicChatEventAttributes.goalName],
                  conversationalFlowName:
                    attributes[
                      DynamicChatEventAttributes.conversationalFlowName
                    ],
                }}
              />
            );
            break;
          case DynamicChatActivityTypeEnum.INTERACTED_WITH_DOCUMENT_IN_CONVERSATIONAL_FORM:
            if (attributes[DynamicChatEventAttributes.documentDownloaded]) {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.docDownloadedInConversationalFlow"
                  values={{
                    documentName:
                      attributes[DynamicChatEventAttributes.documentName],
                  }}
                />
              );
            } else {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.docOpenedInConversationalFlow"
                  values={{
                    documentName:
                      attributes[DynamicChatEventAttributes.documentName],
                  }}
                />
              );
            }
            break;
          case DynamicChatActivityTypeEnum.ENGAGED_WITH_AN_AGENT_IN_CONVERSATIONAL_FORM:
            if (
              attributes[DynamicChatEventAttributes.conversationStatus] ===
              DROPPED
            ) {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.droppedFromLiveChatInConversationalFlow"
                  values={{
                    agentName: attributes[DynamicChatEventAttributes.agentName],
                  }}
                />
              );
            } else {
              nameElement = (
                <FormattedMessage
                  id="web.liveFeed.marketoEvents.dynamicChat.completedLiveChatInConversationalFlow"
                  values={{
                    conversationalFlowName:
                      attributes[
                        DynamicChatEventAttributes.conversationalFlowName
                      ],
                  }}
                />
              );
            }
            break;
          case DynamicChatActivityTypeEnum.CLICKED_LINK_IN_CONVERSATIONAL_FORM:
            nameElement = (
              <FormattedHTMLMessage
                id="web.liveFeed.marketoEvents.dynamicChat.clickedLinkInConversationalFlow"
                values={{
                  link: attributes[DynamicChatEventAttributes.link],
                  conversationalFlowName:
                    attributes[
                      DynamicChatEventAttributes.conversationalFlowName
                    ],
                }}
              />
            );
            break;
          default:
            break;
        }

        circleProps.className = 'fill-orange';
        eventIcon = EVENT_ICONS.interstingMoment;
        break;
      case MarketoEvents.milestone:
        nameElement = (
          <FormattedHTMLMessage
            id="web.liveFeed.marketoEvents.milestone"
            values={{ description }}
          />
        );
        circleProps.className = 'fill-purple';
        eventIcon = EVENT_ICONS.interstingMoment;
        break;
      case MarketoEvents.web:
        nameElement = (
          <FormattedHTMLMessage
            id="web.liveFeed.marketoEvents.web"
            values={{ description }}
          />
        );
        circleProps.className = 'fill-purple';
        eventIcon = EVENT_ICONS.interstingMoment;
        break;
      case ToutEvents.reply:
        nameElement = (
          <FormattedMessage
            id="web.liveFeed.toutEvents.reply"
            values={{ emailInfo: emailElement, hasEmail }}
          />
        );
        circleProps.className = 'fill-yellow';
        eventIcon = EVENT_ICONS.replied;
        break;
      case ToutEvents.open:
        nameElement = (
          <FormattedMessage
            id="web.liveFeed.toutEvents.open"
            values={{ emailInfo: emailElement, hasEmail }}
          />
        );
        circleProps.className = 'fill-blue';
        eventIcon = EVENT_ICONS.viewed;
        break;
      case ToutEvents.bounce:
      case ToutEvents.dropped:
        nameElement = <FormattedMessage id="web.liveFeed.toutEvents.bounced" />;
        circleProps.className = 'fill-red';
        eventIcon = EVENT_ICONS.bounced;
        break;
      case ToutEvents.click: {
        const displayUrl = this._formatUrl(url);
        const urlElement = (
          <a
            className="text-underline"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {displayUrl}
          </a>
        );
        nameElement = (
          <FormattedMessage
            id="web.liveFeed.toutEvents.click"
            values={{
              emailInfo: emailElement,
              hasEmail,
              url: urlElement,
            }}
          />
        );
        circleProps.className = 'fill-red';
        eventIcon = EVENT_ICONS.clicked;
        break;
      }
      case ToutEvents.autoReply:
        nameElement = (
          <FormattedMessage
            id="web.liveFeed.toutEvents.autoReply"
            values={{ emailInfo: emailElement, hasEmail }}
          />
        );
        eventIcon = EVENT_ICONS.autoReplied;
        break;
      case ToutEvents.action:
        nameElement = (
          <FormattedMessage
            id="web.liveFeed.toutEvents.action"
            values={{ title }}
          />
        );
        eventIcon = EVENT_ICONS.viewedContent;
        break;
      case ToutEvents.visit: {
        const displayUrl = this._formatUrl(url);
        const urlElement = (
          <a
            className="text-underline"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {displayUrl}
          </a>
        );
        nameElement = (
          <FormattedMessage
            id="web.liveFeed.toutEvents.visit"
            values={{ url: urlElement }}
          />
        );
        circleProps.className = 'fill-red';
        eventIcon = EVENT_ICONS.visited;
        break;
      }
      case MarketoEvents.email:
        nameElement = (
          <FormattedHTMLMessage
            id="web.liveFeed.marketoEvents.email"
            values={{ description }}
          />
        );
        circleProps.className = 'fill-purple';
        eventIcon = EVENT_ICONS.interstingMoment;
        break;
      default:
        break;
    }

    const descriptionClassName = classNames(
      'text-overflow live-feed-event-description',
      {
        'static-view text-wrap': staticView,
      }
    );

    return (
      <div className="live-feed-event-name">
        {this.getEventIcon(circleProps, eventIcon)}
        <div className={descriptionClassName}>{nameElement}</div>
      </div>
    );
  };

  getEventIcon = (circleProps, eventIcon) => {
    switch (eventIcon) {
      case EVENT_ICONS.viewed:
        return <div className="icon-viewed" />;
      case EVENT_ICONS.clicked:
        return <div className="icon-clicked" />;
      case EVENT_ICONS.replied:
        return <div className="icon-replied" />;
      case EVENT_ICONS.visited:
        return <div className="icon-visited" />;
      case EVENT_ICONS.interstingMoment:
        return <div className="icon-interestingMoment" />;
      case EVENT_ICONS.autoReplied:
        return <div className="icon-autoreplied" />;
      case EVENT_ICONS.bounced:
        return <div className="icon-bounced" />;
      case EVENT_ICONS.viewedContent:
        return <div className="icon-viewedcontent" />;
      default:
        return (
          <svg className="live-feed-event-svg" height={14} width={15}>
            <circle {...circleProps} />
            <circle
              className="live-feed-event-circle-inner fill-white"
              cx={7}
              cy={7}
              r={4}
            />
          </svg>
        );
    }
  };
}

LiveFeedEvent.propTypes = {
  activity: PropTypes.object,
  city: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  emailId: PropTypes.number,
  emailSubject: PropTypes.string,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  markEventAsSeen: PropTypes.func,
  openEmailInfo: PropTypes.func,
  showEmailLink: PropTypes.bool,
  showNowTime: PropTypes.number,
  state: PropTypes.string,
  staticView: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  unseen: PropTypes.bool,
  url: PropTypes.string,
};

LiveFeedEvent.defaultProps = {
  activity: {
    typeId: null,
    attributes: {},
  },
  city: '',
  date: '',
  description: '',
  emailId: 0,
  emailSubject: '',
  markEventAsSeen: () => {},
  openEmailInfo: null,
  showEmailLink: true,
  showNowTime: 50,
  state: '',
  staticView: false,
  title: '',
  type: '',
  unseen: false,
  url: '',
};

export default LiveFeedEvent;
