import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedHTMLMessage } from 'react-intl';

import './progressItem.scss';

const ProgressItem = ({
  activeStep,
  lastStep,
  titleId,
  skippedStep,
  completedStep,
}) => {
  const progressItemClass = classNames('progress-item', {
    'progress-item-complete': completedStep,
    'progress-item-lastStep': lastStep,
    'progress-item-skipped': skippedStep,
  });
  const circleWrapperClass = classNames('step', {
    'step-active': activeStep,
  });

  return (
    <div className={progressItemClass}>
      <div className={circleWrapperClass}>
        <h4 className="progress-item-title">
          <FormattedHTMLMessage id={titleId} />
        </h4>
        <div className="circle" />
      </div>
      {!lastStep ? <div className="line" /> : null}
    </div>
  );
};

ProgressItem.propTypes = {
  activeStep: PropTypes.bool,
  completedStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool,
  skippedStep: PropTypes.bool.isRequired,
  titleId: PropTypes.string.isRequired,
};

ProgressItem.defaultProps = {
  activeStep: null,
  lastStep: false,
};

export default ProgressItem;
