import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/label';
import { failedDeliveryReasonBackendStrings } from 'web/emails/helpers/emailsParsers';

const LABEL_WIDTH_SHORT = 30;
const LABEL_WIDTH_MEDIUM = 45;
const LABEL_WIDTH_LONG = 60;

const EmailsInfo = ({
  bcc,
  campaign,
  cc,
  id,
  identityEmail,
  identityName,
  reason,
}) => (
  <div className="emails-info flex--column">
    {reason && (
      <Label
        classChildren="text-overflow"
        forInput={false}
        id={`emails-info-reason-${id}`}
        messageKey="web.emails.emailsReason.reason"
        sideLabel
        width={LABEL_WIDTH_MEDIUM}
      >
        <div className="text-wrap">
          {failedDeliveryReasonBackendStrings(reason)}
        </div>
      </Label>
    )}
    {campaign && (
      <Label
        classChildren="text-overflow"
        forInput={false}
        id={`emails-info-campaign-${id}`}
        messageKey="web.emails.personEmail.campaign"
        sideLabel
        width={LABEL_WIDTH_LONG}
      >
        <div className="text-wrap">
          <FormattedMessage
            id="web.emails.personEmail.campaignSent"
            values={{
              day: campaign.day,
              url: <a href={campaign.url}>{campaign.name}</a>,
            }}
          />
        </div>
      </Label>
    )}
    <Label
      classChildren="text-overflow"
      forInput={false}
      id={`emails-info-from-${id}`}
      messageKey="web.emails.personEmail.from"
      sideLabel
      width={LABEL_WIDTH_SHORT}
    >
      <div className="text-wrap">
        <FormattedMessage
          id="web.emails.personEmail.fromText"
          values={{ email: identityEmail, name: identityName }}
        />
      </div>
    </Label>
    {cc && (
      <Label
        classChildren="text-overflow"
        forInput={false}
        id={`emails-info-cc-${id}`}
        messageKey="web.emails.personEmail.cc"
        sideLabel
        width={LABEL_WIDTH_SHORT}
      >
        <div className="text-wrap">{cc}</div>
      </Label>
    )}
    {bcc && (
      <Label
        classChildren="text-overflow"
        forInput={false}
        id={`emails-info-bcc-${id}`}
        messageKey="web.emails.personEmail.bcc"
        sideLabel
        width={LABEL_WIDTH_SHORT}
      >
        <div className="text-wrap">{bcc}</div>
      </Label>
    )}
  </div>
);

EmailsInfo.propTypes = {
  bcc: PropTypes.string,
  campaign: PropTypes.object,
  cc: PropTypes.string,
  id: PropTypes.number.isRequired,
  identityEmail: PropTypes.string,
  identityName: PropTypes.string,
  reason: PropTypes.string,
};

EmailsInfo.defaultProps = {
  bcc: '',
  campaign: null,
  cc: '',
  identityEmail: '',
  identityName: '',
  reason: '',
};

export default EmailsInfo;
