import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Urls } from 'web/libs/routes';

import NavBar from 'components/navBar/navBar';
import Part from 'components/navBar/part';
import Item from 'components/navBar/item';

import AlertItem from 'web/topNavbar/components/alert/AlertItem';
import QuestionMarkIcon from 'web/topNavbar/components/questionMark/questionMarkIcon';
import SettingsIcon from 'web/topNavbar/components/settings/settingsIcon';
import GongIcon from 'web/topNavbar/components/gong/gongIcon';
import GlobalBannerAlertsContainer from 'web/globalBannerAlerts/containers/globalBannerAlertsContainer';

import { openLiveFeedPopup } from 'web/topNavbar/helpers/liveFeedHelper';

import {
  isCampaignsPageActive,
  isCommandCenterPageActive,
  isTemplatesPageActive,
} from 'web/topNavbar/helpers/topNavbarHelper';

import './topNavbarContainer.scss';

const CLASS_NAME_TITLE_RIGHT = 'top-navbar-title-right';

export function TopNavbarContainer(props) {
  return (
    <React.Fragment>
      <NavBar showLogo>
        <Part left>
          <Item
            isActive={isCommandCenterPageActive}
            link={Urls.commandCenter}
            titleId="common.commandCenter"
          />
          <Item
            link={Urls.topNavbar.people}
            titleId="common.people"
          />
          <Item
            isActive={isTemplatesPageActive}
            link={Urls.topNavbar.templates}
            titleId="common.templates"
          />
          <Item
            isActive={isCampaignsPageActive}
            link={Urls.topNavbar.campaigns}
            titleId="common.campaigns"
          />
          <Item
            link={Urls.topNavbar.analytics}
            titleId="common.analytics"
          />
        </Part>
        <Part right>
          <Item
            icon="icon-mail"
            onClick={props.openEmailPopup}
            titleClassName={CLASS_NAME_TITLE_RIGHT}
            titleId="web.topnavbar.compose"
          />
          <Item
            icon="icon-call"
            onClick={props.openPhonePopup}
            titleClassName={CLASS_NAME_TITLE_RIGHT}
            titleId="web.topnavbar.call"
          />
          <AlertItem
            closeNotifications={props.closeNotifications}
            integrateOnCounterUpdate={props.integrateOnCounterUpdate}
            openNotifications={props.openNotifications}
            titleClassName={CLASS_NAME_TITLE_RIGHT}
          />
          <Item
            icon="icon-chart"
            onClick={openLiveFeedPopup}
            titleClassName={CLASS_NAME_TITLE_RIGHT}
            titleId="web.topnavbar.liveFeed"
          />
          <GongIcon titleClassName={CLASS_NAME_TITLE_RIGHT} />
          <QuestionMarkIcon {...props} />
          <SettingsIcon {...props} />
        </Part>
      </NavBar>
      <GlobalBannerAlertsContainer />
    </React.Fragment>
  );
}

TopNavbarContainer.propTypes = {
  closeNotifications: PropTypes.func.isRequired,
  integrateOnCounterUpdate: PropTypes.func.isRequired,
  openEmailPopup: PropTypes.func.isRequired,
  openNotifications: PropTypes.func.isRequired,
  openPhonePopup: PropTypes.func.isRequired,
};

export default compose(withRouter)(TopNavbarContainer);
