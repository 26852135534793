import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import './hoverTooltip.scss';

class HoverTooltip extends Component {
  render() {
    const {
      children,
      className,
      delayHide,
      delayShow,
      effect,
      getContent,
      place,
      stayOpen,
      tooltipId,
      ...others
    } = this.props;

    return (
      <div
        data-tip=""
        data-for={tooltipId}
        className={classNames('hover-tooltip', className)}
      >
        <ReactTooltip
          className={classNames('hover-tooltip-tooltip', className, {
            stayopen: stayOpen,
          })}
          delayHide={delayHide}
          delayShow={delayShow}
          effect={effect}
          getContent={getContent}
          id={tooltipId}
          place={place}
          {...others}
        >
          {this._getTip()}
        </ReactTooltip>
        {children}
      </div>
    );
  }

  _getTip = () => {
    const { getContent, textId, textValue } = this.props;

    if (!getContent && textValue) {
      return textValue;
    }

    if (!getContent && textId) {
      return <FormattedHTMLMessage id={textId} />;
    }

    return '';
  };
}

export const HoverTooltipPlace = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
};

export const HoverTooltipEffect = {
  float: 'float',
  solid: 'solid',
};

HoverTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  effect: PropTypes.oneOf(Object.values(HoverTooltipEffect)),
  getContent: PropTypes.func,
  others: PropTypes.array,
  place: PropTypes.oneOf(Object.values(HoverTooltipPlace)),
  stayOpen: PropTypes.bool,
  textId: PropTypes.string,
  tooltipId: PropTypes.string,
  textValue: PropTypes.string,
};

HoverTooltip.defaultProps = {
  children: null,
  className: '',
  delayHide: 500,
  delayShow: 400,
  effect: HoverTooltipEffect.solid,
  getContent: null,
  others: [],
  place: HoverTooltipPlace.bottom,
  stayOpen: true,
  textId: '',
  tooltipId: 'hover-tooltip-id',
  textValue: null,
};

export default HoverTooltip;
