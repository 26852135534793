import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navigateToTeamMembers } from 'web/services/routerService';
import { isAdmin } from 'web/user/selectors/userSelectors';
import {
  setEditingTeamId,
  updateTeam,
  openDeleteTeamConfirmationPopup,
  openCreateTeamPopup,
} from '../actionCreators/teamsActionCreators';
import {
  getSelectedTeamId,
  getLoading,
  getEditingTeamId,
  getTeams,
} from '../selectors/teamsSelector';
import Teams from '../components/teams';
import {
  teamMembersTableExternals,
  allMembersTableExternals,
} from '../helpers/teamMembersHelpers';

class TeamsContainer extends Component {
  render() {
    return <Teams {...this.props} selectTeam={this.selectTeam} />;
  }

  selectTeam = (teamId) => {
    allMembersTableExternals.clearSelectedIds();
    teamMembersTableExternals.clearSelectedIds();
    navigateToTeamMembers(teamId);
  };
}

const mapStateToProps = (state) => ({
  teams: getTeams(state),
  teamsLoading: getLoading(state),
  editingTeamId: getEditingTeamId(state),
  selectedTeamId: getSelectedTeamId(state),
  isTeamCreateDisabled: !isAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        openDeleteTeamConfirmationPopup,
        openCreateTeamPopup,
        updateTeam,
        setEditingTeamId,
      },
      dispatch
    ),
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamsContainer);
