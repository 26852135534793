import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import './previewDynamicFields.scss';

const PreviewDynamicFields = (props) => {
  const { disabled, onClick } = props;

  return <div className="preview-dynamic-fields">{getPreviewButton()}</div>;

  function getPreviewButton() {
    if (disabled) {
      return (
        <HoverTooltip
          className="preview-dynamic-fields-tooltip"
          delayHide={0}
          place={HoverTooltipPlace.top}
          textId="web.composeWindow.bulkEmails.previewTooltip"
          tooltipId="preview-disabled"
        >
          <div className="preview-dynamic-fields-button disabled">
            <FormattedMessage id="web.composeWindow.bulkEmails.previewDynamicFields" />
          </div>
        </HoverTooltip>
      );
    } else {
      return (
        <div className="preview-dynamic-fields-button" onClick={onClick}>
          <FormattedMessage id="web.composeWindow.bulkEmails.previewDynamicFields" />
        </div>
      );
    }
  }
};

PreviewDynamicFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PreviewDynamicFields;
