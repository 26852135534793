/**
 *
 * HeaderRow
 *
 */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Shapes from 'table/modules/shapes';
import {
  SelectableWidth,
  SortingDirection,
  WidthMode,
} from 'table/modules/constants';
import Cell from 'table/modules/cell';
import TableCheckbox from 'table/modules/tableCheckbox';
import DragAndDropCell from 'table/modules/cells/dragAndDropCell';
import './headerRow.scss';
import HeaderColumn from './headerColumn/headerColumn';
import HeaderColumnDropZone from './headerColumn/headerColumnDropZone';
import DraggableHeaderColumn from './headerColumn/draggableHeaderColumn';

class HeaderRow extends PureComponent {
  render() {
    const {
      changeColumnOrderHandler,
      columns,
      draggable,
      height,
      resizeTableHandler,
      width,
      isVisible,
      isResize,
      reorderColumnsEnable,
      sortingColumn,
      sortingDirection,
      parentTable,
    } = this.props;
    const styles = {};
    const headerCheckbox = this._getHeaderCheckbox();

    if (height) {
      styles.height = `${height}px`;
    }
    if (width) {
      styles.width = `${width}px`;
    }

    return (
      isVisible && (
        <div
          className={classNames('tout-header-row', {
            'tout-header-resize-row': isResize,
          })}
          style={styles}
        >
          {draggable && <DragAndDropCell disabled isHeader />}
          {headerCheckbox}
          {columns.map((column, index, array) => {
            const previousColumnId =
              index > 0 ? array[index - 1].id : undefined;

            return reorderColumnsEnable ? (
              <Fragment key={column.id}>
                <HeaderColumnDropZone
                  changeColumnOrderHandler={changeColumnOrderHandler}
                  columnId={column.id}
                  parentTable={parentTable}
                  previousColumnId={previousColumnId}
                />
                <DraggableHeaderColumn
                  column={column}
                  height={height}
                  isResize={isResize}
                  resizeTableHandler={resizeTableHandler}
                  sortingColumn={sortingColumn}
                  sortingDirection={sortingDirection}
                  sortingFunction={this._sort}
                />
                {index === array.length - 1 && (
                  <HeaderColumnDropZone
                    changeColumnOrderHandler={changeColumnOrderHandler}
                    parentTable={parentTable}
                    previousColumnId={column.id}
                  />
                )}
              </Fragment>
            ) : (
              <HeaderColumn
                column={column}
                height={height}
                isResize={isResize}
                key={column.id}
                resizeTableHandler={resizeTableHandler}
                sortingColumn={sortingColumn}
                sortingDirection={sortingDirection}
                sortingFunction={this._sort}
              />
            );
          })}
        </div>
      )
    );
  }

  _getHeaderCheckbox = () => {
    const { height, isSelected, onCheckClick, radio, selectable } = this.props;
    let checkbox = null;

    if (selectable || radio) {
      if (selectable) {
        checkbox = (
          <TableCheckbox
            checked={isSelected}
            name="select-all"
            onChange={onCheckClick}
          />
        );
      }

      return (
        <Cell
          height={height}
          name="tout-table-header-checkbox"
          width={SelectableWidth}
          widthMode={WidthMode.PIXEL}
          isHeader
        >
          {checkbox}
        </Cell>
      );
    }

    return null;
  };

  _sort = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.onSortClick(e.currentTarget.dataset.columnId);
  };
}

HeaderRow.propTypes = {
  changeColumnOrderHandler: PropTypes.func,
  columns: PropTypes.arrayOf(Shapes.Column).isRequired,
  draggable: PropTypes.bool,
  height: PropTypes.number,
  isResize: PropTypes.bool,
  isSelected: PropTypes.bool,
  isVisible: PropTypes.bool,
  onCheckClick: PropTypes.func,
  onSortClick: PropTypes.func,
  parentTable: PropTypes.instanceOf(Element),
  radio: PropTypes.bool,
  reorderColumnsEnable: PropTypes.bool,
  resizeTableHandler: PropTypes.func,
  selectable: PropTypes.bool,
  selectedIds: PropTypes.object,
  sortingColumn: PropTypes.string,
  sortingDirection: Shapes.SortingDirection,
  width: PropTypes.number,
};

HeaderRow.defaultProps = {
  changeColumnOrderHandler: () => {},
  draggable: false,
  height: 44,
  isResize: false,
  isSelected: false,
  isVisible: true,
  onCheckClick: null,
  onSortClick: null,
  parentTable: null,
  radio: false,
  reorderColumnsEnable: false,
  resizeTableHandler: () => {},
  selectable: false,
  selectedIds: {},
  sortingColumn: '',
  sortingDirection: SortingDirection.ASC,
  width: 0,
};

export default HeaderRow;
