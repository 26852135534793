import React, { useCallback, useRef } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Label from 'components/label';
import TextArea from 'web/form/components/fields/textArea/textArea.js';
import Action, { ButtonColors } from 'components/buttons/action';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateActivityCustomizationDetails } from '../../actionCreators/salesforceActionCreators';
import PropTypes from 'prop-types';
import { required } from 'web/form/helpers/validators.js';
import { SalesforcePopupIds } from 'web/salesforce/libs/salesforceConstants.js';
import { ConfigureSalesforceActivityDetailsLink } from '../../libs/salesforceConstants';
import I18N from 'languages';

const ActivityDetailsCustomizationCard = ({
  actionCreators: { closePopup, openPopup },
  change,
  dispatch,
  handleSubmit,
  invalid,
  pristine,
}) => {
  const memoizedRequired = useCallback(required(), [required]);
  const textareaRef = useRef();

  const handleFieldSelect = (fieldValue) => async () => {
    const cursorPos = textareaRef.current.selectionStart;
    const textBeforeCursor = textareaRef.current.value.substring(0, cursorPos);
    const textAfterCursor = textareaRef.current.value.substring(
      cursorPos,
      textareaRef.current.value.length
    );
    change('subjectLine', textBeforeCursor + fieldValue + textAfterCursor);
    await closePopup();
    textareaRef.current.focus();
    const newCursorPos = cursorPos + fieldValue.length;
    textareaRef.current.setSelectionRange(newCursorPos, newCursorPos);
  };

  const onSubmit = (values) => {
    dispatch(updateActivityCustomizationDetails(values.subjectLine));
  };

  const openDynamicFieldPopup = () => {
    openPopup(SalesforcePopupIds.activityCustomizationDynamicFields, {
      handleFieldSelect,
    });
  };

  return (
    <div className="salesforce-sync-card">
      <h3>
        <FormattedMessage id="web.settings.adminSettings.salesforce.syncSettings.activityDetailsCustomization.title" />
      </h3>
      <div className="margin-top-full">
        <p>
          <FormattedHTMLMessage
            id="web.settings.adminSettings.salesforce.syncSettings.activityDetailsCustomization.description"
            values={{ link: ConfigureSalesforceActivityDetailsLink(I18N.getLocale()) }}
          />
        </p>
      </div>
      <Label
        className="margin-bottom-none"
        id="subjectLineLabel"
        messageKey="web.settings.adminSettings.salesforce.syncSettings.activityDetailsCustomization.subjectLine"
      >
        {''}
      </Label>
      <Field
        autoFocus={false}
        component={TextArea}
        customRef={textareaRef}
        name="subjectLine"
        validate={memoizedRequired}
      />
      <Action
        className="margin-top-half"
        color={ButtonColors.gray}
        onClick={openDynamicFieldPopup}
      >
        <FormattedMessage id="web.settings.adminSettings.salesforce.syncSettings.activityDetailsCustomization.addDynamicField">
          {(msg) => <React.Fragment>{'{{ }} ' + msg}</React.Fragment>}
        </FormattedMessage>
      </Action>
      <div className="right">
        <Action
          className="margin-top-half right"
          disabled={invalid || pristine}
          onClick={handleSubmit(onSubmit)}
        >
          <FormattedMessage id="common.save" />
        </Action>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  initialValues: {
    subjectLine: get(
      state,
      'ui.settings.adminSettings.salesforce.activityDetailsCustomization',
      ''
    ),
  },
});

ActivityDetailsCustomizationCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default compose(
  connect(mapStateToProps),
  reduxForm({ enableReinitialize: true, form: 'activityDetailsCustomization' })
)(ActivityDetailsCustomizationCard);
