/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon, { IconColors } from 'components/buttons/icon';
import InfoBox from 'components/infoBox';
import List from 'components/list';
import outsideClickHandler from 'components/hocs/outsideClickHandler';
import { Appears } from 'libs/constantsShared';
import './optionDotasInfobox.scss';

class OptionDotasInfobox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appears: Appears.below,
      open: false,
    };

    this.infoBoxElement = React.createRef();
    this.overlapFrameOverflowValues = ['auto', 'scroll', 'hidden'];
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.state;
    if (open != prevState.open) {
      this.adjustPosition(open);
    }
  }

  adjustPosition = (open) => {
    let appears = Appears.below;

    if (open === true) {
      const INFOBOX_TRIANGLE_HEIGHT = 14;
      const scrollElement = this.getParentOverlapFrame(this.optionsTriggerRef);
      if (scrollElement) {
        const infoBoxClientRect = this.infoBoxElement.current.infoBoxRef.getBoundingClientRect();
        const frameClientRect = scrollElement.getBoundingClientRect();
        const infoBoxHeight =
          infoBoxClientRect.height + INFOBOX_TRIANGLE_HEIGHT;

        if (infoBoxHeight + infoBoxClientRect.top > frameClientRect.bottom) {
          appears = Appears.above;
        }
      }
    }

    this.setState({ appears });
  };

  getParentOverlapFrame = (node) => {
    if (node == null) {
      return null;
    }

    const nodeStyle = window.getComputedStyle(node);
    const overflowValues = [nodeStyle.overflow, nodeStyle.overflowY];
    if (
      overflowValues.some((x) => this.overlapFrameOverflowValues.includes(x))
    ) {
      return node;
    } else {
      return this.getParentOverlapFrame(node.parentNode);
    }
  };

  render() {
    const {
      classNameIcon,
      classNameInfoBox,
      classNameInfoBoxOptions,
      options,
    } = this.props;
    const { appears, open } = this.state;

    return (
      <div className="option-dotas-infobox">
        <Icon
          className={classNames('option-dotas-infobox-icon', classNameIcon)}
          color={IconColors.blue}
          onClick={this._toggleOpen}
          ref={this._setOptionsTriggerRef}
        />
        {open && (
          <InfoBox
            appears={appears}
            className={classNames(
              'option-dotas-infobox-infobox',
              classNameInfoBox
            )}
            outsideClickHandler={this._toggleOpen}
            ref={this.infoBoxElement}
            triggerRef={this.optionsTriggerRef}
          >
            <div
              className={classNames(
                'option-dotas-infobox-options',
                classNameInfoBoxOptions
              )}
            >
              <List handleSelected={this.handleSelected} items={options} />
            </div>
          </InfoBox>
        )}
      </div>
    );
  }

  _setOptionsTriggerRef = (icon) => {
    this.optionsTriggerRef = ReactDOM.findDOMNode(icon);
  };

  _toggleOpen = (e) => {
    if (e && e.preventDefault && e.stopPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
    //eslint-disable-next-line react/no-access-state-in-setstate
    const newOpen = !this.state.open;
    this.disableClick = !newOpen;
    this.setState({ open: newOpen });
  };

  handleSelected = (data) => {
    this._toggleOpen();
    data.onClick(data.value);
  };

  onOutsideClick = () => {
    // outsideClickHandler uses
    if (this.state.open) {
      this._toggleOpen();
    }
  };

  disableClick = true; // outsideClickHandler uses
}

export const OptionShape = PropTypes.shape({
  disabled: PropTypes.bool,
  disabledStyle: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

OptionDotasInfobox.propTypes = {
  classNameIcon: PropTypes.string,
  classNameInfoBox: PropTypes.string,
  classNameInfoBoxOptions: PropTypes.string,
  options: PropTypes.arrayOf(OptionShape).isRequired,
};

OptionDotasInfobox.defaultProps = {
  classNameIcon: '',
  classNameInfoBox: '',
  classNameInfoBoxOptions: '',
};

export default outsideClickHandler(OptionDotasInfobox);

//eslint-disable-next-line import/no-mutable-exports
export let UnwrappedOptionDotasInfobox;
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedOptionDotasInfobox = OptionDotasInfobox;
}
