import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import SubjectCCs from 'web/emailComposeBody/components/subjectCCs';
import Toolbar from 'web/emailComposeBody/components/toolbar';
import AttachmentsCollection from 'web/emailComposeBody/components/attachmentsCollection';
import NonHoverTooltipContainer from 'web/nonHoverTooltip/nonHoverTooltipContainer/nonHoverTooltipContainer';
import './emailComposeBody.scss';
import I18N from 'languages';
import { DefaultTemplateCard } from 'libs/constantsShared';
import TinyMCEEditor from 'web/tinyMceEditor/components/tinyMceEditor';

class EmailComposeBody extends Component {
  constructor(props) {
    super(props);

    this.defaultBody = this._getDefaultBody(props);
  }

  render() {
    const {
      actionCreators,
      attachments,
      bcc,
      cc,
      content,
      contentUploads,
      disableCCs,
      disableEdit,
      hideInsertTemplate,
      hideSaveAsTemplate,
      hideToolbar,
      id,
      infoBoxState,
      intl: { formatMessage },
      lastTemplateCategory,
      selectedTemplate,
      subject,
      templates,
      templatesFavorites,
    } = this.props;
    const selectedTemplateName =
      DefaultTemplateCard.name === selectedTemplate.name
        ? I18N.getConstStr('templateChooser', selectedTemplate.name)
        : selectedTemplate.name;
    this.defaultBody = this._getDefaultBody(this.props);

    const fixSelectFirefox = (elem, gotFocus) => {
      const pattern = /\bdraggable\b/;
      let parentElem = elem.target.closest('.campaign-day-step');

      while (parentElem !== null && !pattern.test(parentElem.className)) {
        parentElem = parentElem.parentNode;
      }

      if (parentElem !== null) {
        parentElem.draggable = !gotFocus;
      }
    };

    return (
      <div className="emailComposeBody">
        <SubjectCCs
          bcc={bcc}
          cc={cc}
          disableCCs={disableCCs}
          disabled={disableEdit}
          onBlur={(event) => fixSelectFirefox(event, false)}
          onFocus={(event) => fixSelectFirefox(event, true)}
          subject={subject}
          updateState={this.updateState}
        />
        {!hideToolbar ? (
          <Toolbar
            actionCreators={actionCreators}
            className="margin-bottom-full"
            content={content}
            contentUploads={contentUploads}
            disableSaveAsTemplate={this._isInvalidSaveAsTemplate()}
            hideInsertTemplate={hideInsertTemplate}
            hideSaveAsTemplate={hideSaveAsTemplate}
            infoBoxState={infoBoxState[id] || ''}
            lastTemplateCategory={lastTemplateCategory}
            selectedTemplateId={
              selectedTemplate.source_pitch_template_id ||
              selectedTemplate.id ||
              0
            }
            templates={templates}
            templatesFavorites={templatesFavorites}
            updateState={this.updateState}
          />
        ) : null}
        {selectedTemplate.name && (
          <div className="text-medium text-overflow margin-bottom-full">
            <span className="chosen-template">
              {formatMessage({ id: 'web.emailComposeBody.templateBase' })}
            </span>
            {selectedTemplateName}
          </div>
        )}
        {this._getComposeEditor()}
        <AttachmentsCollection
          attachments={attachments}
          deleteAttachment={actionCreators.deleteAttachment}
        />
        <NonHoverTooltipContainer />
      </div>
    );
  }

  _getDefaultBody(props) {
    if (props.selectedTemplate.body) {
      return props.selectedTemplate.body;
    } else if (props.defaultText) {
      return props.defaultText;
    } else {
      return '';
    }
  }

  updateState = (obj) => {
    const { id, updateState } = this.props;
    updateState(id, obj);
  };

  _isInvalidSaveAsTemplate = () => {
    const { body, subject } = this.props;
    return !body.length || !subject.length;
  };

  _getComposeEditor = () => {
    const {
      actionCreators: { updateBodies, storeEditorInstance },
      ckeOptions,
      contentPartners,
      id,
      isBoxIntegrationEnabled,
    } = this.props;

    return (
      contentPartners.loaded && (
        <TinyMCEEditor
          contentPartners={contentPartners}
          editorId={id}
          handleEditorChange={updateBodies}
          id={id}
          isBoxIntegrationEnabled={isBoxIntegrationEnabled}
          name={id}
          options={ckeOptions}
          storeEditorInstance={storeEditorInstance}
          value={this.defaultBody}
        />
      )
    );
  };
}

EmailComposeBody.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  attachments: PropTypes.array.isRequired,
  bcc: PropTypes.string,
  body: PropTypes.string,
  cc: PropTypes.string,
  ckeOptions: PropTypes.object,
  content: PropTypes.array.isRequired,
  contentPartners: PropTypes.object.isRequired,
  contentUploads: PropTypes.array.isRequired,
  defaultText: PropTypes.string,
  disableCCs: PropTypes.bool,
  disableEdit: PropTypes.bool,
  hideInsertTemplate: PropTypes.bool,
  hideSaveAsTemplate: PropTypes.bool,
  hideToolbar: PropTypes.bool,
  id: PropTypes.string.isRequired,
  infoBoxState: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isBoxIntegrationEnabled: PropTypes.bool.isRequired,
  lastTemplateCategory: PropTypes.string,
  selectedTemplate: PropTypes.object,
  subject: PropTypes.string,
  templates: PropTypes.array.isRequired,
  templatesFavorites: PropTypes.array.isRequired,
  updateState: PropTypes.func.isRequired,
};

EmailComposeBody.defaultProps = {
  bcc: '',
  body: '',
  cc: '',
  ckeOptions: {},
  defaultText: '',
  disableCCs: false,
  disableEdit: false,
  hideInsertTemplate: false,
  hideSaveAsTemplate: false,
  hideToolbar: false,
  selectedTemplate: {},
  subject: '',
};

export default injectIntl(EmailComposeBody);
