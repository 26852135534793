import React from 'react';
import { FormattedMessage } from 'react-intl';
import './addressSearchResult.scss';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';

export default function AddressSearchResult({ result }) {
  function renderPerson(result) {
    const {
      name,
      title,
      company,
      addresses = [],
      pitches_sent: pitchesSent,
    } = result;

    return (
      <div className="search-item">
        <div className="detail-container">
          <div className="first-row">
            <span className="text-overflow name">{name || addresses[0]}</span>
            <span className="tout-icon-sent sent">{pitchesSent}</span>
          </div>
          <div className="text-overflow">{addresses[0]}</div>
          <div className="text-overflow">{title}</div>
          <div className="text-overflow">{company}</div>
        </div>
      </div>
    );
  }

  function renderGroup(result) {
    const { name, pitchesSent, count } = result;

    const recipientCount = count ? (
      <FormattedMessage
        id="web.composeEmail.addressResult.groupAddressCount"
        values={{ count }}
      />
    ) : null;

    return (
      <div className="search-item">
        <div className="tout-icon-users" />
        <div className="detail-container">
          <div className="first-row">
            <span className="text-overflow name">{name}</span>
            <span className="tout-icon-sent sent">{pitchesSent || '0'}</span>
          </div>
          <div className="text-overflow">{recipientCount}</div>
        </div>
      </div>
    );
  }

  switch (result.es_document_type) {
    case ElasticSearchTypes.people:
      return renderPerson(result);
    case ElasticSearchTypes.groups:
      return renderGroup(result);
    default:
      return null;
  }
}
