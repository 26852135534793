import SmtpDeliveryChannelActionTypes from '../libs/smtpDeliveryChannelActionTypes';

export function smtpDeliveryChannelFetched(state = false, action) {
  switch (action.type) {
    case SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannel:
      return true;
    default:
      return state;
  }
}

export function smtpDeliveryChannelFetching(state = false, action) {
  switch (action.type) {
    case SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannel:
    case SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannelError:
      return false;
    case SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannelFetching:
      return true;
    default:
      return state;
  }
}

export function smtpDeliveryChannel(state = null, action) {
  switch (action.type) {
    case SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannel:
      return action.deliveryChannel;
    default:
      return state;
  }
}
