import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SalesforceCustomizationPopup from 'web/modals/salesforceCustomization/components/salesforceCustomizationPopup';
import {
  managePackage,
  setDefaultProcessValues,
} from 'web/modals/salesforceCustomization/actionCreators/salesforceCustomizationActionCreators';
import { hidePopupOverlay } from 'web/popup/actionCreators/popupActionCreators';
import {
  closeOrShowOverlay,
  closeSalesforcePopup,
} from 'web/modals/salesforceCustomization/actionCreators/salesforceCustomizationPopupActionCreators';
import {
  getStatusConditions,
  getErrorMessages,
  getSortedAvailableVersions,
  getSortedInstalledVersions,
} from 'web/modals/salesforceCustomization/selectors/salesforceCustomizationSelectors';

class SalesforceCustomizationPopupContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      install: () => dispatch(managePackage()),
      uninstall: () => dispatch(managePackage(false)),
      closePopup: () => dispatch(closeSalesforcePopup()),
      closeOrShowOverlay: () => dispatch(closeOrShowOverlay()),
      hidePopupOverlay: () => dispatch(hidePopupOverlay()),
      setDefaultProcessValues: () => dispatch(setDefaultProcessValues()),
    };
  }

  render() {
    return (
      <div className="salesforce-customization-popup-container">
        <SalesforceCustomizationPopup
          {...this.props}
          actionCreators={this.actionCreators}
        />
      </div>
    );
  }
}

SalesforceCustomizationPopupContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  popup: PropTypes.string,
  popupOverlay: PropTypes.string,
  progress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const { progress } = state.salesforceCustomization;
  const errors = getErrorMessages(state);
  const status = getStatusConditions(state);
  const availableVersions = getSortedAvailableVersions(state);
  const installedVersions = getSortedInstalledVersions(state);

  return {
    availableVersions,
    errors,
    progress,
    popup: state.popup,
    popupOverlay: state.popupOverlay,
    isProcessing: status.isProcessing,
    installedVersions,
  };
}

export default connect(mapStateToProps)(SalesforceCustomizationPopupContainer);
