import {
  getSalesforceIntegration,
  getAccount,
  deleteSalesforceIntegration,
} from 'web/salesforce/actionCreators/salesforceActionCreators';
import {
  shouldInvalidateIntegration,
  shouldInvalidateAccount,
} from 'web/salesforce/selectors/salesforceSelectors';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import ConnectionsCustomizationsActionTypes from '../libs/connectionsCustomizationsActionTypes';
import { customizationStatusCall } from 'web/modals/salesforceCustomization/actionCreators/salesforceCustomizationActionCreators';
import { isLoadedInIframe } from 'web/libs/commonHelper';
import { Urls } from 'web/libs/routes';
import { openNewTab, navigateToUrl } from 'web/services/routerService';
import {
  MARKETO_SALES_OUTBOX,
  SalesforceType,
} from 'web/salesforce/libs/salesforceConstants';
import { isOwaPluginMode } from 'web/services/windowService';

export const connectSalesforce = (type, disconnect) => (dispatch) => {
  if (disconnect) {
    dispatch(disconnectSalesforce());
  }

  const isIframe = isLoadedInIframe(MARKETO_SALES_OUTBOX);
  const isOwaPlugin = isOwaPluginMode();

  if (type === SalesforceType.salesforce) {
    if (isIframe && !isOwaPlugin) {
      dispatch(closePopup());
      openNewTab(Urls.connectToSalesforceNewUI, false, true);
    } else {
      navigateToUrl(
        isOwaPlugin
          ? Urls.owaConnectAdminToSalesforceNewUI
          : Urls.connectToSalesforceNewUI
      );
    }
  } else if (type === SalesforceType.salesforceSandbox) {
    if (isIframe && !isOwaPlugin) {
      dispatch(closePopup());
      openNewTab(Urls.connectToSalesforceSandboxNewUI, false, true);
    } else {
      navigateToUrl(
        isOwaPlugin
          ? Urls.owaConnectAdminToSalesforceSandboxNewUI
          : Urls.connectToSalesforceSandboxNewUI
      );
    }
  }
};

export const setDisconnectSalesforceLoading = (loading = true) => ({
  type: ConnectionsCustomizationsActionTypes.setDisconnectSalesforceLoading,
  loading,
});

export const connectionsCustomizationsStartup = () => (dispatch, getState) => {
  if (shouldInvalidateIntegration(getState())) {
    //await
    dispatch(getSalesforceIntegration());
  }
  // if there is a connection?
  if (shouldInvalidateAccount(getState())) {
    //await
    dispatch(getAccount());
  }
  dispatch(customizationStatusCall());
};

export const disconnectSalesforce = () => (dispatch) => {
  dispatch(setDisconnectSalesforceLoading());
  dispatch(closePopup());
  dispatch(deleteSalesforceIntegration()).finally(() => {
    dispatch(setDisconnectSalesforceLoading(false));
  });
};
