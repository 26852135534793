import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { getInstancePreviewByPId } from 'web/previewEmails/services/previewEmailsPreviewsService';
import { PreviewViewTypes } from 'libs/constantsShared';
import {
  parseDynamicFieldsLeftover,
  parsePreviewPitch,
  parsePromptFieldsLeftover,
} from 'web/previewEmails/helpers/parsers';

export function setPreviewCampaignLoading(loading) {
  return {
    type: ActionTypes.previewEmails.state.updatePreviewsState,
    data: { loading },
  };
}

export function setPreviewCampaignCurrentId(
  currentId,
  viewType = PreviewViewTypes.person
) {
  return {
    type: ActionTypes.previewEmails.state.updatePreviewsState,
    data: { currentId, viewType },
  };
}

export function updatePreview(data) {
  return {
    type: ActionTypes.previewEmails.state.updatePreview,
    data,
  };
}

/* Added b/c of race condition with opening CkEditor first time.  */
const INIT_RETRY_COUNT = 10;
let setHtmlRetries = INIT_RETRY_COUNT;
function tryToSetHtml(getState, body) {
  const { previewEmailsPreviews, emailComposeEditors } = getState();
  const instance = emailComposeEditors[previewEmailsPreviews.editorId];
  if (instance) {
    instance.setContent(body);
    setHtmlRetries = INIT_RETRY_COUNT;
  } else if (setHtmlRetries > 0) {
    setHtmlRetries--;
    setTimeout(() => tryToSetHtml(getState, body), 500);
  } else {
    setHtmlRetries = INIT_RETRY_COUNT;
  }
}

function setPreviewBody(body) {
  return (dispatch, getState) => {
    tryToSetHtml(getState, body);
  };
}

export function saveBody(finishedEditing = false) {
  return (dispatch, getState) => {
    const { previewEmailsPreviews, emailComposeEditors } = getState();
    const invalid = {
      body: false,
      subject: false,
      dynamicFieldsBody: false,
      dynamicFieldsSubject: false,
      promptFieldsBody: false,
      promptFieldsSubject: false,
      done: finishedEditing,
    };

    return new Promise((resolve, reject) => {
      const instance = emailComposeEditors[previewEmailsPreviews.editorId];
      if (instance) {
        const body = instance.getContent();
        if (body && body.length) {
          dispatch(updatePreview({ body }));
        } else {
          invalid.body = true;
        }

        const subject =
          previewEmailsPreviews.previewsMap[previewEmailsPreviews.currentId]
            .subject;
        if (!subject || !subject.length) {
          invalid.subject = true;
        }

        invalid.dynamicFieldsBody = parseDynamicFieldsLeftover(body);
        invalid.dynamicFieldsSubject = parseDynamicFieldsLeftover(subject);
        invalid.promptFieldsBody = parsePromptFieldsLeftover(body);
        invalid.promptFieldsSubject = parsePromptFieldsLeftover(subject);

        resolve(invalid);
      } else {
        reject();
      }
    });
  };
}

function getPreview(personId) {
  return (dispatch, getState) => {
    const { previewEmailsPreviews } = getState();
    dispatch(setPreviewCampaignLoading(true));

    return getInstancePreviewByPId(
      previewEmailsPreviews.campaignId,
      personId,
      previewEmailsPreviews.identity
    )
      .then((data) => {
        dispatch(setPreviewCampaignCurrentId(personId));
        dispatch(setPreviewBody(data.pitch.body));
        dispatch({
          type: ActionTypes.previewEmails.state.addPreview,
          id: personId,
          preview: parsePreviewPitch(data.pitch),
        });
        dispatch({
          type: ActionTypes.previewEmails.state.updatePreviewsState,
          data: { attachmentsCount: data.attachments.length || 0 },
        });
        dispatch(setPreviewCampaignLoading(false));
      })
      .catch(() => {
        dispatch(setPreviewCampaignLoading(false));
      });
  };
}

export function changePreview(personId, viewType = PreviewViewTypes.person) {
  return (dispatch, getState) => {
    const { previewEmailsPreviews } = getState();

    if (viewType === PreviewViewTypes.failedImports) {
      dispatch(setPreviewCampaignCurrentId(personId, viewType));
    } else if (previewEmailsPreviews.previewsMap[personId]) {
      dispatch(setPreviewCampaignCurrentId(personId, viewType));
      dispatch(
        setPreviewBody(previewEmailsPreviews.previewsMap[personId].body)
      );
    } else if (previewEmailsPreviews.recipients.map[personId].blockedReason) {
      dispatch(setPreviewCampaignCurrentId(personId, viewType));
    } else {
      dispatch(getPreview(personId));
    }
  };
}

export function openPreviewsEmail(id, openPreviewsAC) {
  return (dispatch) => {
    dispatch(getPreview(id)).then(() => {
      dispatch(openPreviewsAC());
    });
  };
}
