import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import File from 'components/file';
import './attachmentsCollection.scss';

const MEGABYTE = 1024 * 1024;
const SIZE_WARNING = 15 * MEGABYTE;

export default class AttachmentsCollection extends Component {
  render() {
    const { attachments, deleteAttachment } = this.props;
    const attachmentsSize = this._getAttachmentsSize(attachments);
    const size = (attachmentsSize / MEGABYTE).toFixed(2);

    return (
      <div className="attachmentsCollection margin-bottom-half">
        {attachments.length ? (
          <FormattedMessage id="web.emailComposeBody.attachmentCollection.attachments" />
        ) : null}
        {attachments.map((file) => (
          <File
            key={`attachment-${file.reference || file.id}`}
            classes="file-row"
            file={file}
            onClickX={deleteAttachment}
            showSize
          />
        ))}
        {attachmentsSize > SIZE_WARNING ? (
          <div className="margin-top-half attachments-collection-warning">
            <FormattedHTMLMessage
              id="web.emailComposeBody.attachmentCollection.warning"
              values={{ size }}
            />
          </div>
        ) : null}
      </div>
    );
  }

  _getAttachmentsSize(attachments = []) {
    return (
      attachments.reduce(
        (accumulator, current) => accumulator + current.file_file_size,
        0
      ) || 0
    );
  }
}

AttachmentsCollection.propTypes = {
  attachments: PropTypes.array.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
};
