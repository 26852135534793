import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'table/modules/table';
import TextCell from 'table/modules/cells/textCell';
import { Align } from 'table/modules/constants';
import { injectIntl, intlShape } from 'react-intl';
import './campaignsUsingTemplateTable.scss';

const TABLE_MAX_HEIGHT = 300;

class CampaignsUsingTemplateTable extends Component {
  render() {
    const {
      actionCreators,
      activeCampaignTemplates,
      intl: { formatMessage },
      isHeaderSelected,
      isPopup,
      selectable,
      selectedIds,
    } = this.props;
    const columns = this._getColumns();
    return (
      <div className="campaigns-using-template-table tout-table">
        <Table
          animateRow={false}
          columns={columns}
          header={{
            isSelected: isHeaderSelected,
            onCheckClick: actionCreators.setSelectedIds,
          }}
          items={activeCampaignTemplates}
          maxHeight={(isPopup && TABLE_MAX_HEIGHT) || 0}
          row={{
            onCheckClick: this.onCheckClick,
            onClick: selectedIds && this.onRowClick,
          }}
          selectable={selectable}
          selectedIds={selectedIds}
          zeroState={{
            bodyText: formatMessage({
              id: 'web.campaignsUsingTemplateTable.zeroState.body',
            }),
            titleText: formatMessage({
              id: 'web.campaignsUsingTemplateTable.zeroState.title',
            }),
          }}
        />
      </div>
    );
  }

  _getColumns = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return [
      {
        align: Align.LEFT,
        id: 'campaign',
        name: formatMessage({ id: 'web.campaignsUsingTemplateTable.campaign' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'campaignName',
          },
        },
      },
      {
        align: Align.LEFT,
        id: 'used-by',
        name: formatMessage({ id: 'web.campaignsUsingTemplateTable.usedBy' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'usedBy',
          },
        },
      },
      {
        id: 'active-people',
        name: formatMessage({
          id: 'web.campaignsUsingTemplateTable.activePeople',
        }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'activePeople',
          },
        },
      },
      {
        id: 'created',
        name: formatMessage({ id: 'web.campaignsUsingTemplateTable.created' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'createdAt',
          },
        },
      },
    ];
  };

  onCheckClick = (checked, { id } = {}) => {
    this.props.actionCreators.setSelectedIds(checked, [id]);
  };

  onRowClick = (rowData) => {
    this.props.actionCreators.setSelectedIds(undefined, [rowData.id]);
  };
}

CampaignsUsingTemplateTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  activeCampaignTemplates: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  isHeaderSelected: PropTypes.bool,
  isPopup: PropTypes.bool,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
  selectedIds: PropTypes.object,
};

CampaignsUsingTemplateTable.defaultProps = {
  isHeaderSelected: false,
  isPopup: false,
  loading: false,
  selectable: false,
};

export default injectIntl(CampaignsUsingTemplateTable);
