import React from 'react';
import PropTypes from 'prop-types';
import './taskEventContact.scss';
import { FormattedHTMLMessage } from 'react-intl';

const TaskEventContact = (props) => {
  const { parentName, title, company, openPdv } = props;

  return (
    <div className="task-event-contact">
      <div className="contact-name">
        <span className="text-overflow name" onClick={openPdv}>
          {parentName}
        </span>
      </div>
      <div className="contact-work">
        <span className="text-overflow">{title}</span>
        {title &&
          company && (
            <span>
              &nbsp;
              <FormattedHTMLMessage id="common.at" />
              &nbsp;
            </span>
          )}
        <span className="text-overflow">{company}</span>
      </div>
    </div>
  );
};

TaskEventContact.propTypes = {
  parentName: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  openPdv: PropTypes.func.isRequired,
};

TaskEventContact.defaultProps = {
  title: '',
  company: '',
  parentName: '',
};

export default TaskEventContact;
