import React from 'react';
import { string, func } from 'prop-types';
import Alert, { AlertColors } from 'components/alert';
import { MarketoSettingsAlertIds } from '../../libs/marketoSettingsConstants';

export default function MarketoAdminAlert({ closeAlert, alertId }) {
  switch (alertId) {
    case MarketoSettingsAlertIds.connectionFailed:
      return (
        <Alert
          color={AlertColors.redDark}
          onClose={closeAlert}
          textId="web.marketoSettings.connectionFailed"
        />
      );
    case MarketoSettingsAlertIds.disconnectionFailed:
      return (
        <Alert
          color={AlertColors.redDark}
          onClose={closeAlert}
          textId="web.marketoSettings.disconnectionFailed"
        />
      );
    case MarketoSettingsAlertIds.grantAccessSuccess:
      return (
        <Alert
          color={AlertColors.greenDark}
          onClose={closeAlert}
          textId="web.marketoSettings.grantSuccess"
        />
      );
    case MarketoSettingsAlertIds.revokeAccessSuccess:
      return (
        <Alert
          color={AlertColors.greenDark}
          onClose={closeAlert}
          textId="web.marketoSettings.revokeSuccess"
        />
      );
    case MarketoSettingsAlertIds.grantAccessFailed:
      return (
        <Alert
          color={AlertColors.redDark}
          onClose={closeAlert}
          textId="web.marketoSettings.grantAccessFailed"
        />
      );
    case MarketoSettingsAlertIds.grantAccessSomeFailed:
      return (
        <Alert
          color={AlertColors.yellow}
          onClose={closeAlert}
          textId="web.marketoSettings.grantAccessSomeFailed"
        />
      );
    case MarketoSettingsAlertIds.revokeAccessFailed:
      return (
        <Alert
          color={AlertColors.redDark}
          onClose={closeAlert}
          textId="web.marketoSettings.revokeAccessFailed"
        />
      );
    case MarketoSettingsAlertIds.getWorkspacesFailed:
      return (
        <Alert
          color={AlertColors.redDark}
          onClose={closeAlert}
          textId="web.marketoSettings.getWorkspacesFailed"
        />
      );
    case MarketoSettingsAlertIds.revokeAccessSomeFailed:
      return (
        <Alert
          color={AlertColors.yellow}
          onClose={closeAlert}
          textId="web.marketoSettings.revokeAccessSomeFailed"
        />
      );
    case MarketoSettingsAlertIds.allSelectedUsersConnected:
      return (
        <Alert
          color={AlertColors.yellow}
          onClose={closeAlert}
          textId="web.marketoSettings.allSelectedUsersConnected"
        />
      );
    case MarketoSettingsAlertIds.allSelectedUsersDisconnected:
      return (
        <Alert
          color={AlertColors.yellow}
          onClose={closeAlert}
          textId="web.marketoSettings.allSelectedUsersDisconnected"
        />
      );
    case MarketoSettingsAlertIds.alreadyExists:
      return (
        <Alert
          color={AlertColors.redDark}
          onClose={closeAlert}
          textId="web.marketoSettings.alreadyExists"
        />
      );
    default:
      return null;
  }
}

MarketoAdminAlert.propTypes = {
  alertId: string,
  closeAlert: func.isRequired,
};
