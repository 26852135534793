import ConnectionsCustomizationsActionTypes from '../libs/connectionsCustomizationsActionTypes';

export const disconnectSalesforceLoading = (
  state = false,
  { type, loading }
) => {
  switch (type) {
    case ConnectionsCustomizationsActionTypes.setDisconnectSalesforceLoading:
      return loading;
    default:
      return state;
  }
};
