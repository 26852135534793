import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse, { Timeouts } from 'components/collapse';
import Contact from '../contact';
import { FormattedHTMLMessage } from 'react-intl';
import Chevron, {
  ChevronColors,
  ChevronDirections,
} from 'components/buttons/chevron';
import './liveFeedCardHeader.scss';

class LiveFeedCardHeader extends PureComponent {
  state = {
    open: false,
  };

  render() {
    const {
      company,
      contactId,
      infoChildren,
      name,
      score,
      openChildren,
      showMarketoBorder,
      showToutBorder,
      targetTab,
      openPdv,
      unsubscribed,
    } = this.props;

    const { open } = this.state;
    const headerClassNames = classNames(
      'live-feed-card-header',
      'background-white',
      {
        'show-marketo-border': showMarketoBorder,
        'show-tout-border': showToutBorder,
        'show-unsubscribed': unsubscribed,
      }
    );

    return (
      <div className={headerClassNames}>
        <div className="live-feed-card-header-top">
          <div className="tout-icon-marketo-logo-container">
            {showMarketoBorder && <div className="tout-icon-marketo-logo" />}
          </div>
          <div
            className={classNames('live-feed-card-header-contact-row', {
              'target-contact-width': targetTab,
            })}
          >
            <Contact
              company={company}
              id={contactId}
              name={name}
              openPdv={openPdv}
            />
            {infoChildren && (
              <div className="live-feed-card-header-info-children">
                {infoChildren}
              </div>
            )}
          </div>
          <div className="flex flex-right">
            {showMarketoBorder &&
              targetTab && (
                <div className="score-wrapper margin-right-quarter">
                  <span className="lead-score">{score}</span>
                  <span className="lead-score-text text-overflow">
                    <FormattedHTMLMessage id="web.liveFeed.contactInfo.leadScore" />
                  </span>
                </div>
              )}
            {openChildren && (
              <Chevron
                color={ChevronColors.blue}
                direction={
                  (open && ChevronDirections.bottom) || ChevronDirections.right
                }
                onClick={this.toggleOpen}
              />
            )}
          </div>
        </div>
        <div className="live-feed-card-header-bottom">
          {openChildren && (
            <Collapse
              className="live-feed-card-header-collapse"
              in={open}
              timeoutEnter={Timeouts.twoFifty}
              timeoutExit={Timeouts.twoFifty}
            >
              <div className="live-feed-card-header-open-children">
                {openChildren}
              </div>
            </Collapse>
          )}
        </div>
      </div>
    );
  }

  toggleOpen = () => {
    const { toggleOpenChecks } = this.props;

    this.setState((state) => {
      if (!state.open) {
        toggleOpenChecks();
      }

      return {
        open: !state.open,
      };
    });
  };
}

LiveFeedCardHeader.propTypes = {
  company: PropTypes.string,
  contactId: PropTypes.number,
  infoChildren: PropTypes.node,
  name: PropTypes.string,
  openChildren: PropTypes.node,
  openPdv: PropTypes.func,
  score: PropTypes.number,
  showMarketoBorder: PropTypes.bool,
  showToutBorder: PropTypes.bool,
  targetTab: PropTypes.bool,
  toggleOpenChecks: PropTypes.func,
  unsubscribed: PropTypes.bool,
};

LiveFeedCardHeader.defaultProps = {
  company: '',
  contactId: 0,
  name: '',
  openChildren: null,
  score: null,
  showMarketoBorder: false,
  showToutBorder: false,
  targetTab: false,
  unsubscribed: false,
  infoChildren: null,
  openPdv: null,
  toggleOpenChecks: null,
};

export default LiveFeedCardHeader;
