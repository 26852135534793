import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OnboardingIntegrations from '../components/onboardingIntegrations';
import { isNewUser } from 'web/user/selectors/userSelectors';

const OnboardingIntegrationsContainer = (props) => (
  <OnboardingIntegrations {...props} />
);

OnboardingIntegrationsContainer.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isNewUser: isNewUser(state),
  userSettings: state.userSettings,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingIntegrationsContainer);
