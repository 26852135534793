import {
  openPopup,
  closePopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import PeoplePopupIds from 'web/people/libs/peoplePopupIds';
import { initModalAndDataForIds } from 'web/modals/addToCampaign/actionCreators/popupActionCreators';
import { getTimezones } from 'web/timezones/actionCreators/timezonesActionCreators';
import { parseSelectedIds } from 'web/people/peopleSearchAdd/helpers/parsers';
import { GlobalPopupIds } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import {
  setPeopleForDeletePopup,
  clearPeopleForDeletePopup,
} from './peopleDeleteActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  clearAddNewGroupForm,
  setPeopleAddNewGroupDescription,
  setPeopleAddNewGroupName,
  setSelectedAddPeopleToGroupGroupId,
  setSelectedAddPeopleToGroupPeople,
  setShareGroup,
  updateGroupShareTeamIds,
  updateShareGroupComment,
} from './peopleGroupActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { getPeopleByIds } from 'web/people/services/peopleService';
import {
  getPeopleForAddPeopleToGroup,
  isGeneralPeopleGroup,
} from 'web/people/helpers/peopleHelpers';
import {
  loadAvailableMapFields,
  resetImport,
} from './peopleImportActionCreators';
import { getGroup, getShareGroup } from 'web/people/selectors/peopleSelectors';

export const openCreateGroupPopup = () => (dispatch) => {
  dispatch(clearAddNewGroupForm());
  dispatch(openPopup(PeoplePopupIds.createGroup, {}));
};

export const openEditGroupPopup = (groupId) => (dispatch, getState) => {
  const { description, name } = getGroup(groupId)(getState());
  dispatch(setPeopleAddNewGroupDescription(description));
  dispatch(setPeopleAddNewGroupName(name));

  dispatch(openPopup(PeoplePopupIds.editGroup, { groupId }));
};

export const openAddGroupToCampaign = (ids) => (dispatch) => {
  const parsedIds = parseSelectedIds({ peopleIds: ids });

  dispatch(initModalAndDataForIds(parsedIds));
  dispatch(getTimezones());
};

export const openDeletePeoplePopup = (ids, callBack) => (dispatch) => {
  dispatch(
    openPopup(PeoplePopupIds.deletePeople, { callBack, peopleIds: ids })
  );
  dispatch(clearPeopleForDeletePopup());
  dispatch(setPopupLoading(true));

  getPeopleByIds(ids)
    .then((data) => {
      dispatch(setPeopleForDeletePopup(data));
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.loadContactsError));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};

export const openAddPeopleToGroup = (ids) => (dispatch, getState) => {
  if (ids.length === 0) {
    return;
  }

  const {
    peopleCurrentGroupId,
    groups: [firstGroup],
  } = getState();

  const addPeopleToGroupGroupId =
    !peopleCurrentGroupId || isGeneralPeopleGroup(peopleCurrentGroupId)
      ? firstGroup.id
      : parseInt(peopleCurrentGroupId, 10);

  dispatch(setSelectedAddPeopleToGroupGroupId({ id: addPeopleToGroupGroupId }));
  dispatch(openPopup(PeoplePopupIds.addPeopleToGroup, { ids }));
  dispatch(setPopupLoading(true));

  getPeopleByIds(ids)
    .then((data) => {
      dispatch(
        setSelectedAddPeopleToGroupPeople(getPeopleForAddPeopleToGroup(data))
      );
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.loadContactsError));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};

export const openSourcePeoplePopup = (ids, callBack) => (dispatch) => {
  dispatch(
    openPopup(PeoplePopupIds.sourcePeople, {
      callBack,
      peopleIds: ids,
    })
  );
};

export const openDeleteFromCampaignPeoplePopup = (ids, callback) => (
  dispatch
) => {
  dispatch(
    openPopup(GlobalPopupIds.removeConfirmation, {
      callback,
      ids,
    })
  );
};

export const openRemoveFromGroupPeoplePopup = (peopleIds, callBack) => (
  dispatch
) => {
  dispatch(
    openPopup(PeoplePopupIds.removePeopleFromGroup, {
      callBack,
      peopleIds,
    })
  );
};

export const openUnsubscribePeoplePopup = (ids, callBack) => (dispatch) => {
  dispatch(
    openPopup(PeoplePopupIds.unsubscribePeople, {
      callBack,
      peopleIds: ids,
    })
  );
};

export const openAuthorizationPeoplePopup = (ids, callBack) => (dispatch) => {
  dispatch(
    openPopup(PeoplePopupIds.authorizationPeople, {
      callBack,
      peopleIds: ids,
    })
  );
};

export const openDeleteGroupPopup = (groupId) => (dispatch) => {
  dispatch(openPopup(PeoplePopupIds.deleteGroup, { groupId }));
};

export const openShareGroupPopup = (groupId) => (dispatch, getState) => {
  const shareGroup = getShareGroup(groupId)(getState());

  if (!shareGroup) {
    return;
  }

  const teamIds =
    (Array.isArray(shareGroup.team_ids) && shareGroup.team_ids.slice(0)) || [];

  dispatch(setShareGroup(shareGroup));
  dispatch(updateGroupShareTeamIds(teamIds));
  dispatch(updateShareGroupComment(''));

  dispatch(openPopup(PeoplePopupIds.shareGroup));
};

export const openImportPeopleStepOne = () => (dispatch) => {
  dispatch(resetImport());
  dispatch(openPopup(PeoplePopupIds.importPeopleStepOne));
};

export const openImportPeopleStepTwo = () => (dispatch) => {
  dispatch(openPopup(PeoplePopupIds.importPeopleStepTwo));
  dispatch(loadAvailableMapFields());
};

export const openImportPeopleStepThree = () => (dispatch) => {
  dispatch(openPopup(PeoplePopupIds.importPeopleStepThree));
};

export const openPersonGroupsModal = (personId) => (dispatch) => {
  dispatch(openPopup(PeoplePopupIds.personGroupsModal, { personId }));
};

export const closeImportPeopleStepThree = () => (dispatch) => {
  dispatch(closePopup(PeoplePopupIds.importPeopleStepThree));
  dispatch(openViewAlert(PeopleAlertIds.importPeopleSuccess));
};
