/*eslint new-cap:0, complexity:0 */
import { Map, OrderedMap } from 'immutable';
import moment from 'moment';
import TasksActionTypes from '../libs/tasksActionTypes';
import {
  BASE_START,
  BASE_END,
  taskIsBetweenBase,
  taskIsBetweenDates,
} from '../services/tasksHelpers';

/* Tasks uncompleted should always store the tasks in base range after initial get so
 * it maintains the base range (-20->7) and a custom date range. */

const initTasks = OrderedMap({});
export function tasksUncompleted(
  state = initTasks,
  { type = '', data = [], id = '', ids = [], task = {}, uncompletedDates = {} }
) {
  switch (type) {
    case TasksActionTypes.uncompletedSuccess:
      return state.withMutations((map) => {
        data.forEach((item) => {
          map.set(item.id, item);
        });
      });
    case TasksActionTypes.update:
      if (!task.id) {
        return state;
      } else if (
        task.done ||
        task.skipped ||
        !task.remind_at ||
        !(
          taskIsBetweenDates(
            task,
            uncompletedDates.start,
            uncompletedDates.end
          ) ||
          taskIsBetweenDates(
            task,
            uncompletedDates.startCustom,
            uncompletedDates.endCustom
          )
        )
      ) {
        return state.delete(task.id);
      } else {
        return state.update(task.id, () => task);
      }
    case TasksActionTypes.completing:
      return state.delete(task.id, 10);
    case TasksActionTypes.uncompleting:
      return (task.id && state.update(task.id, () => task)) || state;
    case TasksActionTypes.destroy:
      return state.delete(parseInt(id, 10));
    case TasksActionTypes.completingBulk:
    case TasksActionTypes.destroyBulk:
      return state.withMutations((map) => {
        ids.forEach((item) => {
          map.delete(parseInt(item, 10));
        });
      });
    case TasksActionTypes.customReset:
      return state.filter(taskIsBetweenBase);
    default:
      return state;
  }
}

/* Used to keep track of what custom tasks have been received as assume the base date range is always stored. */
export function tasksUncompletedDates(
  state = Map({}),
  { type = '', start, end, isCustom = false }
) {
  switch (type) {
    case TasksActionTypes.uncompletedSuccess:
      return (
        (start &&
          end &&
          state.withMutations((map) => {
            const startId = `start${(isCustom && 'Custom') || ''}`;
            const currentStart = map.get(startId);
            if (!currentStart || start < currentStart) {
              map.set(startId, start);
            }
            const endId = `end${(isCustom && 'Custom') || ''}`;
            const currentEnd = map.get(endId);
            if (!currentEnd || end > currentEnd) {
              map.set(endId, end);
            }
          })) ||
        state
      );
    case TasksActionTypes.customReset:
      return state
        .set('start', moment(BASE_START))
        .set('end', moment(BASE_END))
        .delete('startCustom')
        .delete('endCustom');
    default:
      return state;
  }
}
