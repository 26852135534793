export default {
  clearAlert: 'DIAGNOSTICS_ERROR_CLEAR',
  datesShown: 'DIAGNOSTICS_TABLE_DATES_SHOWN',
  datesSmartFilter: 'DIAGNOSTICS_TABLE_DATES_SMART_FILTER',
  datesSmartFilterReset: 'DIAGNOSTICS_TABLE_DATES_SMART_FILTER_RESET',
  filterItems: 'DIAGNOSTICS_FILTER_ITEMS',
  getItems: 'DIAGNOSTICS_GET_ITEMS',
  removeItems: 'DIAGNOSTICS_REMOVE_ITEMS',
  retryItems: 'DIAGNOSTICS_RETRY_ITEMS',
  showAlert: 'DIAGNOSTICS_ERROR_SHOW',
  statusSmartFilter: 'DIAGNOSTICS_TABLE_STATUS_SMART_FILTER',
  tableInit: 'DIAGNOSTICS_TABLE_TABLE_INIT',
};
