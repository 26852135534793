import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPersonData } from 'web/composeWindow/selectors/composeWindowSelectors';
import MiniPersonDetailView from 'web/composeWindow/components/miniPersonDetailView/miniPersonDetailView';
import { fetchPersonDetailsById } from 'web/composeWindow/actionCreators/composeWindowActionCreators';

const MiniPDVContainer = (props) => {
  const {
    address,
    closeDetails,
    editorFocused,
    errored,
    fetchPersonDetails,
    loading,
    person,
  } = props;

  useLayoutEffect(
    () => {
      if (editorFocused) {
        closeDetails();
      }
    },
    [editorFocused]
  );

  useLayoutEffect(
    () => {
      fetchPersonDetails();
    },
    [address]
  );

  return (
    <MiniPersonDetailView
      person={person}
      errored={errored}
      loading={loading}
      closeDetails={closeDetails}
    />
  );
};

MiniPDVContainer.propTypes = {
  address: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  errored: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchPersonDetails: PropTypes.func.isRequired,
  closeDetails: PropTypes.func.isRequired,
  editorFocused: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      fetchPersonDetails: () =>
        fetchPersonDetailsById(
          ownProps.address.person ? ownProps.address.person.id : null
        ),
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  person: getPersonData(state),
  errored: state.composePersonDetails.errored,
  loading: state.composePersonDetails.loading,
  editorFocused: state.composeWindowState.editorFocused,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiniPDVContainer);
