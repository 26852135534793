import SharingSettingsActionTypes from '../libs/sharingSettingsActionTypes';
import {
  TabValues,
  SharingSettingsAlertIds,
} from '../libs/sharingSettingsConstants';
import { getCategories } from '../selectors/sharingSettingsSelectors';
import {
  closePopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  deleteCategoryPermissionCampaign,
  deleteCategoryPermissionTemplate,
  updateCategoryPermissionCampaign,
  updateCategoryPermissionTemplate,
} from 'web/categories/services/permissionsCategoriesService';

export const addTeams = () => (dispatch, getState) => {
  const state = getState();
  const {
    settingsSharingSelectedCategoryIndex: categoryIndex,
    sharingSettingsAddTeamsSelectedValues: teamIds,
    settingsSharingSelectedTabValue: tab,
  } = state;
  const category = getCategories(state)[categoryIndex];

  if (!category || !category.id) {
    return;
  }
  dispatch(setPopupLoading(true));

  const update =
    (tab === TabValues.templates && updateCategoryPermissionTemplate) ||
    updateCategoryPermissionCampaign;
  update(category.id, teamIds, false)
    .then((teams = []) => {
      dispatch({
        type: SharingSettingsActionTypes.addTeams,
        teams,
      });
      dispatch(closePopup());
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(SharingSettingsAlertIds.genericIssue));
    });
};

export const addTeamsOnChange = (ids) => ({
  type: SharingSettingsActionTypes.addTeamsOnChange,
  ids,
});

export const removeTeams = (teamIds = [], onSuccess = () => {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const {
    settingsSharingSelectedCategoryIndex: categoryIndex,
    settingsSharingSelectedTabValue: tab,
  } = state;
  const category = getCategories(state)[categoryIndex];

  if (!category || !category.id) {
    return;
  }

  dispatch(setPopupLoading(true));

  const del =
    (tab === TabValues.templates && deleteCategoryPermissionTemplate) ||
    deleteCategoryPermissionCampaign;
  del(category.id, teamIds)
    .then(() => {
      dispatch({
        type: SharingSettingsActionTypes.removeTeams,
        teamIds,
      });
      onSuccess();
      dispatch(closePopup());
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(SharingSettingsAlertIds.genericIssue));
    });
};
