import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Radio from 'components/buttons/radio';
import './sharingSettingsAccessCell.scss';

export const AccessRadios = {
  everyone: 'everyone',
  teamAdminOnly: 'teamAdminOnly',
};

class SharingSettingsAccessCell extends PureComponent {
  render() {
    const {
      rowData: { id, shareEveryone },
    } = this.props;

    return (
      <div className="sharing-settings-access-cell">
        <Radio
          checked={!shareEveryone}
          className="margin-right-full"
          inline
          label={
            <FormattedMessage id="web.settings.sharing.accessCell.teamAdminOnly" />
          }
          name={`sharing-settings-access-cell-${id}`}
          onChange={this.onChange}
          value={AccessRadios.teamAdminOnly}
        />
        <Radio
          checked={shareEveryone}
          inline
          label={
            <FormattedMessage id="web.settings.sharing.accessCell.everyone" />
          }
          name={`sharing-settings-access-cell-${id}`}
          onChange={this.onChange}
          value={AccessRadios.everyone}
        />
      </div>
    );
  }

  onChange = (value) =>
    this.props.onChange(this.props.rowData.id, value === AccessRadios.everyone);
}

SharingSettingsAccessCell.propTypes = {
  onChange: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};

SharingSettingsAccessCell.defaultProps = {
  rowData: {},
};

export default SharingSettingsAccessCell;
