/*  Options include:
    disableDynamicFields: bool
    readOnly: bool
*/
import { ModalPopupIds } from 'web/libs/constants';
import { default as I18N } from 'languages';

const DYNAMIC_FIELDS = 'dynamicFieldsReact';
const IMAGE_INSERT = 'imageInsertReact';
const DEFAULT_LINK = 'toutapp.com';

export default class CkEditor {
  constructor(id, options = {}, showModalAC, bodyAC, pasteAC) {
    this.id = id;
    this.updateBodiesObject = bodyAC;
    this.cachedContent = [];

    this._loadEditor(
      id,
      this._defaultConfig(options, showModalAC, bodyAC, pasteAC)
    );

    this.sanitizeHTML = this._getSanitizer();
  }

  destroyEditor() {
    const instance = this._getEditorInstance();
    if (instance) {
      instance.destroy(true);
    }
    CkEditor.cleanUpPopups();
  }

  _loadEditor(id, config) {
    window.CKEDITOR.replace(id, config);
  }

  _getSanitizer() {
    if (window.app && window.app.sanitizeHTML) {
      return window.app.sanitizeHTML;
    } else if (window.sanitize_html) {
      return window.sanitize_html;
    } else {
      return (text) => text;
    }
  }

  _getEditorInstance() {
    return window.CKEDITOR.instances[this.id];
  }

  setHtml(content = ' ') {
    this._handleContent('setHtml', content);
  }

  insertHtml(content = ' ') {
    this._handleContent('insertHtml', content);
  }

  // these below methods are aliases so that it matches the methods on the TinyMCE instance. This can be removed
  // once CKEditor is deprecated. We use the TinyMCE react library which provides the editor component so we do not need
  // a wrapper service like this.

  insertContent(content = ' ') {
    this.insertHtml(content);
  }

  setContent(content = ' ') {
    this.setHtml(content);
  }

  getContent() {
    const instance = this._getEditorInstance();
    if (instance) {
      return instance.getData();
    } else {
      return '';
    }
  }

  setReadOnly(readOnly) {
    const instance = this._getEditorInstance();
    if (instance) {
      instance.editable().setReadOnly(readOnly);
    }
  }

  _defaultConfig(options, showModalAC, bodyAC, pasteAC) {
    const self = this; // needed as scope not the same in object below
    // contextMenuDependencies are disabled to disable ckeditor contextmenu
    const contextMenuDependencies =
      ',spellcheck,liststyle,tabletools,scayt,menubutton,contextmenu';
    const config = {
      on: {
        pluginsLoaded: () => {
          // set so tout-next ckeditor plugin will call to create modal
          if (window.app && window.app.globals && !options.readOnly) {
            window.app.globals[DYNAMIC_FIELDS] = (editorName) =>
              showModalAC(ModalPopupIds.dynamicFields, editorName);
            window.app.globals[IMAGE_INSERT] = (editorName, editState) =>
              showModalAC(ModalPopupIds.imageInsert, editorName, editState);
          } else if (window.app && window.app.globals) {
            window.app.globals[DYNAMIC_FIELDS] = () => {};
            window.app.globals[IMAGE_INSERT] = () => {};
          }
        },
        instanceReady: (evt) => {
          const { editor } = evt;
          bodyAC(self.id, editor.getData());

          if (options.addDefaultLink) {
            self._setDefaultLink();
          }

          editor.on('paste', () => {
            const elem = editor.container.$.querySelector(
              '.cke_button__removeformat'
            );
            const position = elem.getBoundingClientRect();
            const text = {
              titleId: 'nonHoverTooltip.paste.title',
              bodyId: 'nonHoverTooltip.paste.body',
            };
            pasteAC(position, text);
          });

          if (self.cachedContent.length) {
            for (let i = 0; i < self.cachedContent.length; i++) {
              const current = self.cachedContent[i];
              self[current.type](current.content);
            }
            self.cachedContent = [];
          }

          if (options.readOnly) {
            self.setReadOnly(true);
          }
        },
        change: (evt) => {
          bodyAC(self.id, evt.editor.getData());
        },
        mode: (evt) => {
          if (evt.editor.mode === 'source') {
            const editable = self._getEditorInstance().editable();
            editable.attachListener(editable, 'input', () =>
              bodyAC(self.id, evt.editor.getData())
            );
          } else {
            bodyAC(self.id, evt.editor.getData());
          }
        },
      },
      removePlugins: `magicline${contextMenuDependencies}`,
      defaultLanguage: I18N.defaultLanguage,
      language: I18N.getLocale(),
    };

    if (options.disableDynamicFields) {
      config.removePlugins += ',toutdynamicfields';
    }

    return config;
  }

  _setDefaultLink() {
    window.CKEDITOR.on('dialogDefinition', (ev) => {
      // Take the dialog name and its definition from the event data.
      const dialogName = ev.data.name;
      const dialogDefinition = ev.data.definition;

      // Check if the definition is from the dialog window you are interested in (the "Link" dialog window).
      if (dialogName === 'link') {
        // Get a reference to the "Link Info" tab.
        const infoTab = dialogDefinition.getContents('info');

        // Set the default value for the URL field.
        const urlField = infoTab.get('url');
        urlField.default = DEFAULT_LINK;
      }
    });
  }

  _handleContent(type, content) {
    const instance = this._getEditorInstance();
    if (instance) {
      const editable = instance.editable();

      /* Bug found where editable is set to read only for no reason on init so adding to cache */
      if (editable && !editable.isReadOnly()) {
        editable[type](this.sanitizeHTML(content)); //todo switch off of app
        const data = instance.getData();
        if (data && data.length) {
          this.updateBodiesObject(this.id, data);
        } else if (content.length) {
          this.cachedContent.push({ type, content });
        }
      } else {
        this.cachedContent.push({ type, content });
      }
    }
  }

  static getPopupExceptionSelector(id) {
    return `.cke_editor_${id}_dialog`;
  }

  static cleanUpPopups() {
    if (window.app && window.app.globals) {
      Reflect.deleteProperty(window.app.globals, DYNAMIC_FIELDS);
      Reflect.deleteProperty(window.app.globals, IMAGE_INSERT);
    }
  }
}
