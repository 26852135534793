import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withReadMore from 'components/hocs/withReadMore';

class CommentContent extends Component {
  render() {
    const { className, content } = this.props;

    return (
      <div className={classNames('comment-content text-wrap', className)}>
        {content}
      </div>
    );
  }
}

CommentContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  height: PropTypes.number.isRequired, //eslint-disable-line
  useParentProps: PropTypes.bool, //eslint-disable-line
};

CommentContent.defaultProps = {
  className: '',
  content: '',
  useParentProps: true,
};

export default withReadMore({})(CommentContent);
