import EngageActionTypes from '../libs/engageActionTypes';
import {
  formatToutEvent,
  formatMarketoLeads,
  formatNotification,
} from 'web/liveFeed/engage/helpers/engageParsers';
import { checkLeadInSalesforce as checkLeadInSalesforceCall } from 'web/liveFeed/services/liveFeedService';
import { findOrCreatePerson as findOrCreatePersonCall } from 'web/people/services/peopleService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export const cardToggleOpenChecksPerson = (person) => (dispatch) => {
  const { addressId, id, email, personId, pitch_id: pitchId } = person;
  dispatch({ id, type: EngageActionTypes.addOpenChildrenId });
  const extraData = {
    addressId,
    personId,
    pitchId,
    salesforceId: '',
  };
  checkLeadInSalesforceCall(email)
    .then((response) => {
      const { sf_object_id: salesforceId } = response;
      extraData.salesforceId = salesforceId;
    })
    .catch()
    .finally(() => {
      dispatch({
        extraData,
        type: EngageActionTypes.engageEventsExtraData,
      });
      dispatch({ id, type: EngageActionTypes.removeOpenChildrenId });
    });
};

export const cardToggleOpenChecks = (lead) => (dispatch) => {
  const { id, email, pitch_id: pitchId } = lead;
  dispatch({ id, type: EngageActionTypes.addOpenChildrenId });
  const promises = [
    checkLeadInSalesforceCall(email),
    findOrCreatePersonCall(lead),
  ].map((promise) =>
    promise
      .then((result) => ({ result, success: true }))
      .catch((error) => ({ error, success: false }))
  );

  Promise.all(promises)
    .then((responses) => {
      const extraData = {
        addressId: '',
        personId: '',
        pitchId,
        salesforceId: '',
      };

      const [checkLeadResponse, personResponse] = responses;

      // found lead in salesforce
      if (checkLeadResponse.success) {
        const { sf_object_id: salesforceId } = checkLeadResponse.result;

        extraData.salesforceId = salesforceId;
      }

      // found or created person
      if (personResponse.success) {
        const {
          person: {
            primary_address: { id: addressId, addressable_id: personId },
          },
        } = personResponse.result;
        extraData.addressId = addressId;
        extraData.personId = personId;
      }

      dispatch({ extraData, type: EngageActionTypes.engageEventsExtraData });
    })
    .catch(() => {
      dispatch({
        extraData: {
          addressId: '',
          personId: '',
          pitchId,
          salesforceId: '',
        },
        type: EngageActionTypes.engageEventsExtraData,
      });
    })
    .finally(() => {
      dispatch({ id, type: EngageActionTypes.removeOpenChildrenId });
    });
};

export const updateEngageEvents = (leads) => (dispatch) => {
  dispatch({
    leads,
    type: EngageActionTypes.updateEngageEvents,
  });
};

export const updateToutEvents = (leads) => (dispatch) => {
  dispatch(updateEngageEvents([formatToutEvent(leads)]));
};

export const updateMarketoEvents = (leads) => (dispatch) => {
  const formattedMarketoLeads = formatMarketoLeads(leads);
  dispatch(updateEngageEvents(formattedMarketoLeads));

  const updateCount = formattedMarketoLeads.reduce(
    (sum, lead) => sum + lead.length,
    0
  );
  toutBackboneHelper.liveFeedEventsCountUpdate(updateCount);
};

export const updateNotifications = (notification) => (dispatch) => {
  const formattedNotification = formatNotification(notification);

  // MT: `updateEngageEvents` requires a list
  dispatch(updateEngageEvents([formattedNotification]));
};

export const markEventAsSeen = (eventId) => {
  const leads = [{ events: [{ id: eventId }] }];

  return {
    leads,
    type: EngageActionTypes.markEventAsSeen,
  };
};
