import { moment } from 'languages';

export const startOfToday = () => moment().startOf('d');

export const endOfToday = () => moment().endOf('d');

export const startOfTomorrow = () =>
  moment()
    .add(1, 'd')
    .startOf('d');

export const endOfTomorrow = () =>
  moment()
    .add(1, 'd')
    .endOf('d');

export const startOfYesterday = () =>
  moment()
    .subtract(1, 'd')
    .startOf('d');

export const endOfYesterday = () =>
  moment()
    .subtract(1, 'd')
    .endOf('d');

export const endOfNextNumDays = (num) =>
  moment()
    .add(num, 'd')
    .endOf('d');

export const startOfLastNumDays = (num) =>
  moment()
    .subtract(num, 'd')
    .startOf('d');

export const startOfNextNumDays = (num) =>
  moment()
    .add(num, 'd')
    .startOf('d');
