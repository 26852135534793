import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import TemplatePopupIds from 'web/templates/libs/templatePopupIds';
import { updateCreateTemplateState } from 'web/templates/actionCreators/templatesCreateActionCreators';

export const openMoveTemplatePopup = (ids, callback) => (dispatch) => {
  dispatch(openPopup(TemplatePopupIds.moveTemplate, { ids, callback }));
};

export const openArchiveTemplatePopup = (ids, callback) => (dispatch) => {
  dispatch(openPopup(TemplatePopupIds.archiveTemplate, { ids, callback }));
};

export const openDeleteTemplatePopup = (ids, callback) => (dispatch) => {
  dispatch(openPopup(TemplatePopupIds.deleteTemplate, { ids, callback }));
};

export const openCreateTemplatePopup = () => (dispatch, getState) => {
  dispatch(
    updateCreateTemplateState({
      categoryId: getState().templatesSelectedTemplateCategoryId,
    })
  );
  dispatch(openPopup(TemplatePopupIds.createTemplate, {}));
};

export const openCreateCategoryPopup = () => (dispatch) => {
  dispatch(openPopup(TemplatePopupIds.createCategory, {}));
};

export const openConfirmDropPopup = (ids, selectedCategory, callback) => (
  dispatch
) => {
  dispatch(
    openPopup(TemplatePopupIds.confirmDrop, { ids, selectedCategory, callback })
  );
};
