import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SafelyChangeRouteDummy } from 'components/hocs/safelyChangeRoute';
import { openUnsavedChangesPopup as openUnsavedChangesPopupAC } from '../actionCreators/safelyChangeRouteActionCreators';
import { SafelyChangeRoutePopupIds } from '../libs/safelyChangeRouteConstants';
import SafelyChangeRoutePopupContainer from './safelyChangeRoutePopupContainer';

class SafelyChangeRouteContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch, beforeUnsavedChangesPopupOpen } = props;

    this.actionCreators = {
      openUnsavedChangesPopup: (url) => {
        beforeUnsavedChangesPopupOpen(url);
        dispatch(openUnsavedChangesPopupAC(url));
      },
    };
  }

  render() {
    const { isUnsavedChangesPopupOpen, isSafeToChangeRoute } = this.props;

    const { openUnsavedChangesPopup } = this.actionCreators;

    return [
      <SafelyChangeRouteDummy
        key="safely-change-route-dummy"
        safelyChangeRoute={{
          onTryChangeRoute: openUnsavedChangesPopup,
          isSafeToChangeRoute: isUnsavedChangesPopupOpen || isSafeToChangeRoute,
        }}
      />,
      <SafelyChangeRoutePopupContainer key="safely-change-route-popup-container" />,
    ];
  }
}

SafelyChangeRouteContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isUnsavedChangesPopupOpen: PropTypes.bool.isRequired,
  isSafeToChangeRoute: PropTypes.bool.isRequired,
  beforeUnsavedChangesPopupOpen: PropTypes.func,
};

SafelyChangeRouteContainer.defaultProps = {
  beforeUnsavedChangesPopupOpen: () => {},
};

const mapStateToProps = (state) => ({
  isUnsavedChangesPopupOpen:
    state.popup === SafelyChangeRoutePopupIds.unsavedChanges,
});

export default connect(mapStateToProps)(SafelyChangeRouteContainer);
