import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const DateAgoComponent = ({ className, datetime, showNow, textId }) => {
  let displayElement;
  if (showNow) {
    displayElement = <FormattedMessage id="common.now" />;
  } else {
    displayElement =
      (textId && <FormattedMessage id={textId} values={{ datetime }} />) ||
      datetime;
  }

  return (
    <div className={classNames('date-ago-component', className)}>
      {displayElement}
    </div>
  );
};

DateAgoComponent.propTypes = {
  className: PropTypes.string,
  datetime: PropTypes.string.isRequired,
  showNow: PropTypes.bool,
  textId: PropTypes.string,
};

DateAgoComponent.defaultProps = {
  className: '',
  showNow: false,
  textId: '',
};

export default DateAgoComponent;
