const cashClean = (sources) => {
  for (let i = 0; i < sources.length; i++) {
    sources[i] += `?${Math.random()}`;
  }
};

const preloadImages = (sources, callback) => {
  cashClean(sources);

  let counter = 0;
  const result = [];

  const onLoad = () => {
    counter++;
    if (counter === sources.length) callback();
  };

  for (let source of sources) {
    const img = document.createElement('img');
    img.onload = img.onerror = onLoad;
    img.src = source;
    result.push(source);
  }

  return result;
};

export { preloadImages };
