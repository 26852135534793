import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/selects/base';
import classNames from 'classnames';
import './multiArraysSelect.scss';

export default class MultiArraysSelect extends PureComponent {
  render() {
    const { className, items, ...other } = this.props;

    return (
      <Select
        className={classNames('mulit-arrays-select', className)}
        items={this._parseItems(items)}
        {...other}
      />
    );
  }

  _parseItems = (items = []) => {
    let complete = [];
    items.forEach((array, index) => {
      if (index > 0) {
        complete.push({ separator: true });
      }
      complete = complete.concat(array);
    });
    return complete;
  };
}

MultiArraysSelect.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

MultiArraysSelect.defaultProps = {
  className: '',
};
