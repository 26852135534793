import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  wrappedHandleEditorChange as handleEditorChange,
  storeEditorInstance,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import {
  ModalComponentIds,
  COMPOSE_EDITOR_ID,
} from 'web/composeWindow/libs/composeWindowConstants';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import TinyMCE from 'web/shared/components/tinymce/tinymce';
import { isBoxIntegrationEnabled } from 'web/settings/adminSettings/general/selectors/generalPageSelectors';

const ComposeEditorContainer = (props) => {
  const {
    composeEditorContent,
    contentPartners,
    disabled,
    handleEditorChange,
    handleFocus,
    handleBlur,
    isBoxIntegrationEnabled,
    openBoxContentPopup,
    openContentFilesPopup,
    openHighSpotPopup,
    openImageInsertPopup,
    shouldMakeApiCall,
    storeEditorInstance,
  } = props;
  const { loaded: isContentPartnersLoaded } = contentPartners;

  return (
    <div className="tout-ui-styles compose-editor-container">
      {isContentPartnersLoaded && (
        <TinyMCE
          contentPartners={contentPartners}
          disabled={disabled}
          editorId={COMPOSE_EDITOR_ID}
          handleBlur={handleBlur}
          handleEditorChange={handleEditorChange}
          handleFocus={handleFocus}
          isBoxIntegrationEnabled={isBoxIntegrationEnabled}
          openBoxContentPopup={openBoxContentPopup}
          openContentFilesPopup={openContentFilesPopup}
          openHighSpotPopup={openHighSpotPopup}
          openImageInsertPopup={openImageInsertPopup}
          shouldMakeApiCall={shouldMakeApiCall}
          storeEditorInstance={storeEditorInstance}
          value={composeEditorContent}
          isComposeWindow
        />
      )}
    </div>
  );
};

ComposeEditorContainer.propTypes = {
  composeEditorContent: PropTypes.string,
  contentPartners: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleEditorChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  isBoxIntegrationEnabled: PropTypes.bool.isRequired,
  openBoxContentPopup: PropTypes.func,
  openContentFilesPopup: PropTypes.func.isRequired,
  openHighSpotPopup: PropTypes.func.isRequired,
  openImageInsertPopup: PropTypes.func.isRequired,
  storeEditorInstance: PropTypes.func.isRequired,
};

ComposeEditorContainer.defaultProps = {
  composeEditorContent: '',
  disabled: false,
  openBoxContentPopup: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        handleEditorChange,
        storeEditorInstance,
      },
      dispatch
    ),
    openBoxContentPopup: (editorId) => {
      dispatch(openPopup(ModalComponentIds.attachBoxContents, { editorId }));
    },
    openContentFilesPopup: (editorId) =>
      dispatch(
        openPopup(ModalComponentIds.attachFilesAndContent, { editorId })
      ),
    openHighSpotPopup: (editorId) => {
      dispatch(openPopup(ModalComponentIds.highSpotContent, { editorId }));
    },
    openImageInsertPopup: (editorId) =>
      dispatch(
        openPopup(ModalComponentIds.insertImageDragAndDrop, { editorId })
      ),
  };
}

const mapStateToProps = (state) => ({
  composeEditorContent: state.composeEditorContent,
  contentPartners: state.contentPartners,
  isBoxIntegrationEnabled: isBoxIntegrationEnabled(state),
  shouldMakeApiCall: state.composeBulkEmails.shouldMakeApiCall,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeEditorContainer);
