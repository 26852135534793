import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import BulkActionTypes from 'web/bulkUpdate/libs/bulkUpdateActionTypes';

const initProgress = 5;
export const bulkUpdateProgress = (
  state = initProgress,
  { type, percentComplete }
) => {
  switch (type) {
    case BulkActionTypes.progress.set:
      return percentComplete;
    case PopupActionTypes.open:
    case PopupActionTypes.close:
    case BulkActionTypes.progress.clear:
      return initProgress;
    default:
      return state;
  }
};

const initResultsState = { successes: [], errors: [], errorTypes: [] };
export const bulkUpdateResults = (
  state = initResultsState,
  { type, successes = [], errors = [], errorTypes = [] }
) => {
  switch (type) {
    case BulkActionTypes.results.set:
      return {
        successes: [...state.successes, ...successes],
        errors: [...state.errors, ...errors],
        errorTypes: [...state.errorTypes, ...errorTypes],
      };
    case PopupActionTypes.open:
    case PopupActionTypes.close:
      return initResultsState;
    default:
      return state;
  }
};

const initIsComplete = false;
export const bulkUpdateIsComplete = (
  state = initIsComplete,
  { type, isComplete = false }
) => {
  switch (type) {
    case BulkActionTypes.complete.set:
      return isComplete;
    case PopupActionTypes.open:
    case PopupActionTypes.close:
      return initIsComplete;
    default:
      return state;
  }
};
