import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  searchCampaignPeople as searchCampaignPeopleCall,
  PEOPLE_LIMIT,
} from '../services/campaignsPeopleService';
import { track } from 'web/services/mixpanelService';
import { CampaignPeopleTabEvents } from 'web/libs/mixpanelEvents';
import { tablePerPageFromStorage } from 'web/services/storageService';

const AUTOCOMPLETE_INPUT_MIN = 3;

export function resetCampaignPeopleSearch(keepSearchString = false) {
  return {
    all: !keepSearchString,
    type: ActionTypes.campaignPeople.state.resetSearch,
  };
}

export function searchCampaignPeople(
  id,
  campaignState,
  value = '',
  viewAsUserId = 0
) {
  return (dispatch, getState) => {
    dispatch({
      data: value,
      type: ActionTypes.campaignPeople.state.searchChange,
    });

    if (value.length >= AUTOCOMPLETE_INPUT_MIN) {
      dispatch({
        data: true,
        type: ActionTypes.campaignPeople.state.loading,
      });

      searchCampaignPeopleCall(
        id,
        campaignState,
        value,
        0,
        PEOPLE_LIMIT,
        viewAsUserId
      )
        .then((workflowInstances = []) => {
          const data = workflowInstances.filter(
            (wfi) => wfi.person_id && wfi.person
          );
          dispatch({
            data,
            type: ActionTypes.campaignPeople.data.peopleSearch,
          });
          dispatch({
            data: false,
            type: ActionTypes.campaignPeople.state.loading,
          });
          dispatch({ type: ActionTypes.campaignPeople.state.setSearching });
        })
        .catch(() => {
          dispatch({
            data: false,
            type: ActionTypes.campaignPeople.state.loading,
          });
        });
    } else {
      dispatch(resetCampaignPeopleSearch(true));
    }

    const { campaignsPeopleSearchString: str } = getState();
    if (value.length === 1 && str.length === 0) {
      track(CampaignPeopleTabEvents.search);
    }
  };
}

export function searchCampaignPeopleChange(id, value) {
  return (dispatch, getState) => {
    const { campaignsPeopleFilter, campaignsPeopleViewAsFilter } = getState();
    dispatch(
      searchCampaignPeople(
        id,
        campaignsPeopleFilter,
        value,
        campaignsPeopleViewAsFilter
      )
    );
  };
}

export function searchCampaignPeoplePagination(id, campaignState, newPage = 1) {
  return (dispatch, getState) => {
    const {
      campaignsPeopleSearchString: str,
      campaignsPeopleViewAsFilter,
    } = getState();

    dispatch({
      data: true,
      type: ActionTypes.campaignPeople.state.loading,
    });
    const perPage = tablePerPageFromStorage();
    searchCampaignPeopleCall(
      id,
      campaignState,
      str,
      (newPage - 1) * perPage,
      perPage,
      campaignsPeopleViewAsFilter
    )
      .then((workflowInstances = []) => {
        const data = workflowInstances.filter(
          (wfi) => wfi.person_id && wfi.person
        );
        dispatch({
          data,
          type: ActionTypes.campaignPeople.data.peopleSearch,
        });
        dispatch({
          data: false,
          type: ActionTypes.campaignPeople.state.loading,
        });
      })
      .catch(() => {
        dispatch({
          data: [],
          type: ActionTypes.campaignPeople.data.peopleSearch,
        });
        dispatch({
          data: false,
          type: ActionTypes.campaignPeople.state.loading,
        });
      });
  };
}
