import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import outsideClickHandler from 'components/hocs/outsideClickHandler';
import Text from 'components/inputs/text';
import './slideOutPaneHeaderEditingInput.scss';

class SlideOutPaneHeaderEditingInput extends PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = null;

    this.state = {
      error: false,
    };
  }

  componentWillMount() {
    this.placeholderText = this.props.intl.formatMessage({
      id: 'slideOutPane.header.editing.placeholder',
    });
  }

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.inputRef); //eslint-disable-line react/no-find-dom-node
    if (node) {
      node.select();
    }
  }

  render() {
    const { name } = this.props;
    const { error } = this.state;

    return (
      <Text
        className="slide-out-pane-header-editing-input"
        error={error}
        onChange={this.onChange}
        placeholder={this.placeholderText}
        ref={this._setInputRef}
        value={name}
      />
    );
  }

  _setInputRef = (node) => {
    this.inputRef = node;
  };

  onChange = (name = '') => {
    this.setState(() => ({
      error: name.length === 0,
    }));
    this.props.onChange(name);
  };
}

SlideOutPaneHeaderEditingInput.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SlideOutPaneHeaderEditingInput.defaultProps = {
  name: '',
};

export default injectIntl(outsideClickHandler(SlideOutPaneHeaderEditingInput));
