import React, { Component } from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import LabeledInputReduxForm from 'components/labeledInputReduxForm';
import SmartSelectReduxForm from 'components/selects/smartSelectReduxForm';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { JOB_TITLES, COUNTRIES } from '../../libs/createAccountConstants';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl, Urls } from 'web/libs/constants';
import FormLayout from 'components/layouts/formLayout';
import FormTitle from 'components/layouts/formLayout/formTitle';
import FormError from 'components/layouts/formLayout/formError';
import FormButtonsArea from 'components/layouts/formLayout/formButtonsArea';
import passwordWithTooltip from 'web/form/hoc/passwordWithTooltip';
import { required as requiredValidatorFactory } from 'web/form/helpers/validators';
import { hasOnboardingFeatureFlag } from 'web/shared/helpers/featureFlagHelper';
import { navigateToUrl } from 'web/services/routerService';
import {
  allPasswordValidators,
  allConfirmPasswordValidators,
} from 'web/form/libs/passwordValidators';
import './createAccount.scss';

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
      match: {
        params: { invitation_code: invitationCode },
      },
    } = this.props;

    this.isActiveMode = !invitationCode;

    const POSITION_LEFT = 'left';

    this.jobTitleItems = JOB_TITLES.map((titleId) => {
      const title = formatMessage({ id: titleId });
      return { label: title, value: title };
    });

    this.countryItems = COUNTRIES.map((countryId) => {
      const countryName = formatMessage({ id: countryId });
      return { label: countryName, value: countryName };
    });

    this.passwordFiledWithTooltip = passwordWithTooltip({
      place: POSITION_LEFT,
    })(LabeledInputReduxForm);

    this.firstNameRequiresValidator = requiredValidatorFactory(
      'createAccount.form.validate.firstNameRequired'
    );
    this.lastNameRequiredValidator = requiredValidatorFactory(
      'createAccount.form.validate.lastNameRequired'
    );
    this.jobTitleRequiredValidator = requiredValidatorFactory(
      'createAccount.form.validate.jobTitleRequired'
    );
    this.countryRequiredValidator = requiredValidatorFactory(
      'createAccount.form.validate.countryRequired'
    );
  }

  render() {
    const { error, loading, handleSubmit } = this.props;

    return (
      <div className="create-account-form">
        <FormLayout className="create-account-form-wrapper">
          <FormTitle
            id="createAccount.form.title"
            subTitleId="createAccount.form.subTitle"
          />
          {loading && <LoadingSpinner imageUrl={getImageUrl} />}
          <Form onSubmit={handleSubmit(this.onSubmitCreateAccount)}>
            <div className="form-row">
              <Field
                autoComplete="email"
                autoFocus={false}
                className="form-cell"
                component={LabeledInputReduxForm}
                id="user_email"
                label="createAccount.form.label.loginEmail"
                labelComment="common.loginEmail"
                name="email"
                type="text"
                disabled
              />
            </div>
            <div className="form-row">
              <Field
                autoComplete="first_name"
                autoFocus={false}
                className="form-cell"
                component={LabeledInputReduxForm}
                id="user_first_name"
                label="common.firstName"
                name="first_name"
                type="text"
                validate={this.firstNameRequiresValidator}
              />
              <Field
                autoComplete="last_name"
                autoFocus={false}
                className="form-cell"
                component={LabeledInputReduxForm}
                id="user_last_name"
                label="common.lastName"
                name="last_name"
                type="text"
                validate={this.lastNameRequiredValidator}
              />
            </div>
            <div className="form-row password-fields">
              <Field
                autoComplete="password"
                autoFocus={false}
                className="form-cell"
                component={this.passwordFiledWithTooltip}
                id="user_password"
                label="common.password"
                name="password"
                type="password"
                validate={allPasswordValidators}
              />
              <Field
                autoComplete="confirm_password"
                autoFocus={false}
                className="form-cell"
                component={LabeledInputReduxForm}
                id="user_confirm_password"
                label="login.setPassword.confirmPassword"
                name="confirm_password"
                type="password"
                validate={allConfirmPasswordValidators}
              />
            </div>
            <div>
              <div className="form-row">
                <Field
                  autoComplete="job_title"
                  autoFocus={false}
                  className="form-cell"
                  component={SmartSelectReduxForm}
                  id="user_job_title"
                  items={this.jobTitleItems}
                  label="createAccount.form.label.jobTitle"
                  name="job_title"
                  useOnlyPredefinedValues={false}
                  validate={this.jobTitleRequiredValidator}
                />
              </div>
              <div className="form-row">
                <Field
                  autoFocus={false}
                  className="form-cell"
                  component={SmartSelectReduxForm}
                  id="user_country"
                  items={this.countryItems}
                  label="createAccount.form.label.country"
                  name="country"
                  validate={this.countryRequiredValidator}
                />
              </div>
            </div>

            <FormError>{error && <FormattedMessage id={error} />}</FormError>
            <FormButtonsArea align="center">
              <Button
                className="create-account-button"
                color={ButtonColors.blue}
                disabled={loading}
                size={ButtonSizes.medium}
                type="submit"
              >
                <FormattedMessage id="common.signup" />
              </Button>
            </FormButtonsArea>
          </Form>
        </FormLayout>
      </div>
    );
  }

  onSubmitCreateAccount = (values) => {
    const {
      actionCreators: { createAccountRequest, createAccountActivationRequest },
      match: {
        params: {
          invitation_code: invitationCode,
          activation_code: activationCode,
        },
      },
    } = this.props;

    return this.isActiveMode
      ? createAccountActivationRequest(values, activationCode)
      : createAccountRequest(values, invitationCode).then((userInfo) => {
          const {
            history,
            actionCreators: { setLoading },
          } = this.props;

          if (hasOnboardingFeatureFlag(userInfo)) {
            navigateToUrl(Urls.onboarding.welcome);
          } else {
            history.push(Urls.getStarted);
            setLoading(false);
          }
        });
  };
}

CreateAccount.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      activation_code: PropTypes.string,
      invitation_code: PropTypes.string,
    }),
  }).isRequired,
};

CreateAccount.defaultProps = {
  error: '',
  history: {},
};

export default reduxForm({
  form: 'create-account-form',
})(injectIntl(CreateAccount));
