import React, { PureComponent } from 'react';
import { DragLayer } from 'react-dnd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getItemStyles } from './helpers/customDragLayer';
import './customDragLayer.scss';
import { ItemType as TableItemType } from './withDndSource';

class CustomDragLayer extends PureComponent {
  render() {
    const { isDragging, item, mouseOffset, itemType } = this.props;
    const itemsLength = (item && item.id && item.id.length) || 0;

    if (!isDragging || itemType !== TableItemType) {
      return null;
    }

    return (
      <div className="drag_layer__wrapper">
        <div className="drag_layer" style={getItemStyles(mouseOffset)}>
          <span className="drag_layer__icon" />
          <span>
            <FormattedMessage id="customDragLayer.movingItem" values={{ count: itemsLength }} />
          </span>
        </div>
      </div>
    );
  }
}

CustomDragLayer.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  mouseOffset: PropTypes.object,
  item: PropTypes.object,
  itemType: PropTypes.string,
};

CustomDragLayer.defaultProps = {
  item: {
    id: [],
  },
  itemType: null,
  mouseOffset: {},
};

export default DragLayer((monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  mouseOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
}))(CustomDragLayer);
