import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { TaskType } from 'libs/constantsShared';
import CampaignStepIcon from 'web/campaigns/campaignsTab/components/campaignStepIcon';
import { isDone } from 'web/tables/tasks/helpers/tablesTasksHelpers';
import './typeCell.scss';

class TypeCell extends Component {
  render() {
    const { rowData, isActionsDisabled } = this.props;
    const actionClassName = classNames('margin-left-half', {
      disabled: isActionsDisabled(rowData),
    });

    return (
      <div className="cell-type">
        <CampaignStepIcon
          action={rowData.remind_type}
          disabled={isActionsDisabled(rowData)}
          onClick={this._onClick}
          outline
        />
        <a
          className={actionClassName}
          href="javascript:void(0)"
          onClick={this._onClick}
        >
          {this._getText()}
        </a>
      </div>
    );
  }

  _onClick = (e) => {
    const { onClick, rowData, isActionsDisabled } = this.props;
    if (!isDone(rowData) && !isActionsDisabled(rowData)) {
      e.stopPropagation();
      onClick(rowData);
    }
  };

  _getText = () => {
    const {
      intl: { formatMessage },
      rowData: { remind_type: type },
    } = this.props;

    switch (type) {
      case TaskType.call:
        return formatMessage({ id: 'web.campaigns.tasks.callAction' });
      case TaskType.inMail:
        return formatMessage({ id: 'web.campaigns.tasks.inMailAction' });
      case TaskType.other:
        return formatMessage({ id: 'web.campaigns.tasks.customTaskAction' });
      case TaskType.email:
      default:
        return formatMessage({ id: 'web.campaigns.tasks.emailAction' });
    }
  };
}

TypeCell.propTypes = {
  intl: intlShape.isRequired,
  isActionsDisabled: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default injectIntl(TypeCell);
