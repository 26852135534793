import React, { Fragment } from 'react';
import UsersPopupContainer from '../../../containers/usersPopupContainer';
import InviteesPopupContainer from '../../../containers/inviteesPopupContainer';
import './userManagementPopups.scss';

const UserManagementPopups = () => (
  <Fragment>
    <UsersPopupContainer />
    <InviteesPopupContainer />
  </Fragment>
);

export default UserManagementPopups;
