import SalesforceCustomizationActionTypes from 'web/modals/salesforceCustomization/libs/salesforceCustomizationActionTypes';

const initialState = {
  description: null,
  details: null,
};

export const salesforceCustomizationErrors = (
  state = initialState,
  payload = {}
) => {
  const { type, ...data } = payload;

  switch (type) {
    case SalesforceCustomizationActionTypes.errors.set:
      return {
        ...state,
        ...data,
      };
    case SalesforceCustomizationActionTypes.errors.clear:
      return initialState;

    default:
      return state;
  }
};
