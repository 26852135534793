import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './failedImportPreview.scss';

class FailedImportPreview extends Component {
  render() {
    const { failedImports } = this.props;
    const values = Object.keys(failedImports).map((failure) => ({
      message: failure,
      count: failedImports[failure],
    }));

    return (
      <div className="failed-imports-preview">
        {values.map((failure, index) => this.renderMessageLine(failure, index))}
      </div>
    );
  }

  renderMessageLine = (failureMessage, index) => {
    return (
      <div className="warning-item" key={index}>
        <div className="tout-icon-warning" />
        <div className="text-medium wording">
          <FormattedMessage
            id="campaigns.failedImportPreview.failedItem"
            values={failureMessage}
          />
        </div>
      </div>
    );
  };
}

FailedImportPreview.propTypes = {
  failedImports: PropTypes.object,
};

FailedImportPreview.defaultProps = {
  failedImports: {},
};

export default FailedImportPreview;
