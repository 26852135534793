import ActionTypes from 'web/libs/actionTypes/actionTypes';
import ModalsRecordingNoticeActionTypes from '../libs/modalsRecordingNoticeActionTypes';

export function modalsRecordingNoticeInitStore(state = false, { type = '' }) {
  switch (type) {
    case ModalsRecordingNoticeActionTypes.initStore:
      return true;
    case ActionTypes.modal.state.close:
      return false;
    default:
      return state;
  }
}

export function modalsRecordingNoticeWindowMode(state = false, { type = '' }) {
  switch (type) {
    case ModalsRecordingNoticeActionTypes.setWindowMode:
      return true;
    case ActionTypes.modal.state.close:
      return false;
    default:
      return state;
  }
}
