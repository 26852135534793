import { BULK_ACTIONS } from 'web/templates/libs/templatesConstants';

export const CommandCenterTaskEvents = {
  addTask: 'Command Center Tasks Add Task Clicked',
  clickCall: 'Command Center Call Clicked',
  open: 'Command Center Tasks Open Action',
  openLinkedIn: 'Command Center Open LinkedIn account Action',
};

export const CampaignPeopleTabEvents = {
  filtered: 'Campaign People tab filtered',
  finished: 'Campaign Marked Finished',
  pagination: 'Campaign People Pagination',
  quickAction: 'Campaign Quick Action Taken',
  search: 'Campaign People Search Started',
  success: 'Campaign Marked Success',
};

export const CampaignTasksTabEvents = {
  open: 'Campaign Tasks Open Action',
};

export const CampaignSetupTabEvents = {
  viewClick: 'Campaign Setup View Clicked',
};

export const CampaignActionEvents = {
  addToCampaignAction: 'Add to Campaign Action',
  createCampaign: 'Create Campaign',
  manageCampaign: 'Manage Campaign',
  searchCampaign: 'Search Campaign',
  updateCampaignSettings: 'Campaign Settings Updated',
  useCampaign: 'Use Campaign',
};

export const CampaignProperties = {
  addToCampaignActionTypes: {
    chooseMarketing: 'Choose Marketing Campaign',
    chooseSales: 'Choose Sales Campaign',
    selectMarketoCampaign: 'Select Marketo Campaign',
    selectMarketoWorkspace: 'Select Marketo Workspace',
    selectToutCampaign: 'Select Marketo Sales Campaign',
    selectToutCategory: 'Select Marketo Sales Campaign',
  },
  mode: {
    a2c: 'Add to Campaign',
    campaignsAddPeople: 'Add People',
    compose: 'Compose',
  },
  step: {
    email: 'Email',
    task: 'Task',
  },
  type: {
    marketing: 'Marketing',
    sales: 'Sales',
  },
};

export const CampaignSetupTabProperties = {
  horizontal: 'Horizontal',
  vertical: 'Vertical',
};

export const CampaignTabEvents = {
  clicked: (tabName) => `Campaign ${tabName} tab clicked`,
  slideOutMenuOpen: 'Campaign Dota Menu Clicked',
};

export const CampaignListEvents = {
  filtered: 'Campaign List filtered',
};

export const CampaignTabProperties = {
  close: 'Close',
  open: 'Open',
  view: 'Campaigns Tab',
};

export const TablesSources = {
  campaignsPeople: 'Campaigns People Tab',
  campaignsTasks: 'Campaigns Tasks Tab',
  commandCenterTasks: 'Command Center Tasks',
  diagnostics: 'Diagnostics',
  templates: 'Templates Tab',
};

export const TablesEvents = {
  bulkAction: 'Bulk Action Taken',
  columnFilterChange: 'Column Filter Changed',
  dateChange: 'Date Filter Changed',
  gridSearch: 'Grid Searched',
  gridSearchSelected: 'Grid Searched Selected',
  viewChange: 'View Filter Changed',
};

export const TablesProperties = {
  checked: 'Checked',
  columnSearched: 'Column Searched',
  columnSelected: 'Column Selected',
  dateRange: 'Date Range',
  length: 'Length',
  secondarySearch: 'Secondary Search',
  source: 'Source',
  type: 'Type',
  view: 'View',
};

export const TablesTasksDateRangeProperties = {
  custom: 'Custom',
  nextSeven: 'Next 7 days',
  overdue: 'Overdue',
  today: 'Today',
  todayOverdue: 'Today & Overdue',
  tomorrow: 'Tomorrow',
  yesterday: 'Yesterday',
};

export const TablesTasksViewProperties = {
  closed: 'Closed',
  open: 'Open',
};

export const TablesTasksGridColumns = {
  company: 'Company',
  email: 'Email',
  name: 'Name',
  subject: 'Subject',
};

export const TablesTasksBulkTypes = {
  delete: 'Delete',
  done: 'Mark done',
  skip: 'Skip',
  success: 'Mark success',
};

export const TasksProperties = {
  actionType: 'ActionType',
  bulkActionEnabled: 'Bulk Action Enabled',
  campaignTask: 'Campaign Task',
  source: 'Source',
  taskType: 'Task Type',
};

export const HelpFormEvents = {
  bailed: 'Help form bailed',
  bailedPopAnswer: 'Help form bailed at popular answer',
  fullySubmitted: 'Help Form submitted',
  nailedIt: 'Help Form followed through and nailed it',
  started: 'Help Form opened',
  supportForm: 'Help Form followed through to support form',
};

export const TaskEvents = {
  complete: 'Complete Task',
  create: 'Create Task',
  edit: 'Edit Task',
};

export const TaskProperties = {
  actionType: {
    complete: 'Complete',
    delete: 'Delete',
    skip: 'Skip',
    success: 'Success',
    undone: 'Undone',
  },
  mode: {
    compose: 'Compose',
    widget: 'Task Widget',
  },
  type: {
    call: 'Call',
    custom: 'Custom',
    email: 'Email',
    inMail: 'InMail',
  },
};

export const SourceProperties = {
  analytics: 'Analytics Tab',
  campaigns: 'Campaigns Tab',
  commandCenter: 'Command Center',
  gmail: 'Gmail',
  liveFeed: 'Live Feed',
  outlook: 'Outlook',
  owa: 'OWA',
  peopleTab: 'People Tab',
  salesBeat: 'Sales Beat Tab',
  salesforce: 'Salesforce',
  sentFolder: 'Sent Folder',
  templates: 'Templates Tab',
};

export const TemplatesEvents = {
  create: 'Template Created',
  searchTemplate: 'Search Template',
};

export const TemplatesBulkTypes = {
  [BULK_ACTIONS.archive]: 'Archive',
  [BULK_ACTIONS.destroy]: 'Delete',
  [BULK_ACTIONS.move]: 'Move',
};

export const CategoriesEvents = {
  create: 'Create Category',
  update: 'Update Category',
};

export const CategoriesUpdateEventTypes = {
  delete: 'Delete Category',
  rename: 'Rename Category',
};

export const CategoriesTypes = {
  campaign: 'Campaign',
  template: 'Template',
};

export const MetadataApiEvents = {
  metadataApi: 'Metadata API',
};

export const MetadataApiProperties = {
  action: 'Action',
};

export const MetadataApiPropertyTypes = {
  installed: 'Installed',
  uninstalled: 'Uninstalled',
};

export const GongEvents = {
  open: 'Gong Open',
  ring: 'Gong Ring',
};

export const GongProperties = {
  source: 'Source',
};

export const PersonDetailsEvents = {
  accountInfo: 'Person Details Account Information',
  action: 'Person Details Action',
  contact: 'Person Details Contact',
  customFields: 'Person Details Custom Fields',
  groups: 'Person Details Group',
  marketoCampaigns: 'Person Details Marketo Campaigns',
  marketoEngagement: 'Person Details Marketo Engagement',
  notes: 'Person Details Notes',
  open: 'Person Details Opened',
  salesCalls: 'Person Details Sales Calls',
  salesCampaigns: 'Person Details Sales Campaigns',
  salesEmails: 'Person Details Sales Emails',
  salesEngagement: 'Person Details Sales Engagement',
  tabViewed: 'Person Details Tab Viewed',
};

export const PersonDetailsProperties = {
  actionType: 'Action Type',
  addressType: 'Address Type',
  source: 'Source',
  type: 'Type',
};

export const PersonDetailsPropertyTypes = {
  about: 'About',
  add: 'Add',
  addAsTemplate: 'Add As Template',
  addToSF: 'Add to Salesforce',
  addressChanged: 'Address Changed',
  addressClicked: 'Address Clicked',
  addressTypeChanged: 'Address Type Changed',
  archive: 'Archive',
  call: 'Call',
  campaign: 'Campaign',
  clicked: 'Clicked',
  contact: 'Contact',
  delete: 'Delete',
  editInSF: 'Edit in Salesforce',
  email: 'Email',
  engagement: 'Engagement',
  export: 'Export',
  facebook: 'Facebook',
  followUp: 'Follow Up',
  history: 'History',
  lead: 'Lead',
  linkedin: 'LinkedIn',
  lsn: 'Sales Navigator',
  markSuccess: 'Mark Success',
  openSalesEvents: 'Open Sales Events',
  other: 'Other',
  phone: 'Phone',
  remove: 'Remove',
  save: 'Save',
  showLess: 'Show Less',
  showMore: 'Show More',
  task: 'Task',
  tasks: 'Tasks',
  twitter: 'Twitter',
  unarchive: 'Unarchive',
  unsubscribe: 'Unsubscribe',
  update: 'Update',
  website: 'Website',
};

export const UnsubscribeEvents = {
  history: 'Unsubscribe History Table Clicks',
  search: 'Unsubscribe Group Search',
  sync: 'Unsubscribe Sync',
};

export const UnsubscribeProperties = {
  actionType: 'Action Type',
  settingsType: 'Settings Type',
};

export const UnsubscribePropertyTypes = {
  click: 'Click',
  marketingOptOut: 'Marketing Opt Out',
  salesOptOut: 'Sales Opt Out',
  search: 'Search',
};

export const LiveFeed = {
  engagement: 'Live Feed Clicked on Engagement Tab',
  target: 'Live Feed Clicked on Target Tab',
  tasks: 'Live Feed Clicked on Tasks Tab',
};

export const GetStartedEvents = {
  clickButton: 'Getting Started Page',
  completed_survey: 'Completed Survey',
};

export const GetStartedProperties = {
  actionType: 'Action Type',
};

export const GetStartedPropertyTypes = {
  connectToCRM: 'Connect to CRM',
  gmail: 'Gmail',
  outlook: 'Outlook',
};

export const LoginPageEvents = {
  clickButton: 'Login Page',
};

export const LoginPageProperties = {
  actionType: 'Action Type',
  source: 'Source',
};

export const LoginPagePropertyTypes = {
  gmail: 'Gmail',
  gmailButton: 'Gmail Button',
  owa: 'OWA',
  salesforceButton: 'Salesforce',
  singleSignOnButton: 'Single Sign on',
  webAppBrowser: 'Webapp (Browser)',
};

export const ResetPasswordEvents = {
  resetPassword: 'Reset Password',
};

export const ResetPasswordProperties = {
  source: 'Source',
};

export const ResetPasswordPropertyTypes = {
  forgotPassword: 'Forgot Password',
  gmail: 'Gmail',
  liveFeed: 'Live Feed',
  outlook: 'Outlook',
  owa: 'OWA',
};

export const SignUpEvents = {
  clickButton: 'Sign Up Form',
};

export const SignUpProperties = {
  activityType: 'Activity Type',
};

export const SignUpPropertyTypes = {
  signUp: 'Sign Up',
};

export const UserManagementEvents = {
  userManagement: 'User Management',
};

export const UserManagementActions = {
  deleteUser: 'Delete User',
  deleteUserConfirmationPopup: 'Open Delete User Confirmation Popup',
  deleteUsers: 'Delete Users',
  deleteUsersConfirmationPopup: 'Open Delete Users Confirmation Popup',
  grantAdminRole: 'Grant User "Admin" Role',
  grantUserRole: 'Grant User "User" Role',
  inviteUsers: 'Invite Users',
  inviteUsersPopup: 'Open Invite Users Popup',
  needMoresSeatsPopup: 'Open Need More Seats Popup',
  pageOpen: 'User Management Page Open',
  resendInvite: 'Resend Invite',
  resendInvites: 'Resend Invites',
  withdrawInvite: 'Withdraw Invite',
  withdrawInviteConfirmationPopup: 'Open Withdraw Invite Confirmation Popup',
  withdrawInvites: 'Withdraw Invites',
  withdrawInvitesConfirmationPopup: 'Open Withdraw Invites Confirmation Popup',
};

export const TeamManagementEvents = {
  teamManagement: 'Team Management',
};

export const TeamManagementActions = {
  addTeamMembersToTeam: 'Add Team Members To Team',
  addTeamMembersToTeamPopup: 'Open Add Team Members To Team Popup',
  addTeamMembersToTeams: 'Add Team Members To Teams',
  addTeamMembersToTeamsPopup: 'Open Add Team Members To Teams Popup',
  createTeam: 'Create Team',
  createTeamPopup: 'Open Create Team Popup',
  deleteTeam: 'Delete Team',
  deleteTeamConfirmationPopup: 'Open Delete Team Confirmation Popup',
  deleteTeamMembers: 'Delete Team Members',
  deleteTeamMembersConfirmationPopup:
    'Open Delete Team Members Confirmation Popup',
  editTeams: 'Edit Teams',
  editTeamsPopup: 'Open Edit Team Popup',
  grantTeamAdmin: 'Grant Team Member "Team Admin" Role',
  pageOpen: 'Team Management Page Open',
  revokeTeamAdmin: 'Grant Team Member "User" Role',
  updateTeam: 'Update Team',
};
