import I18N from 'languages';
import {
  getPersonName,
  getPrimaryEmailAddress,
  getPrimaryOtherAddress,
  getPrimaryPhoneAddress,
  getPrimaryWebsiteAddress,
} from './personHelpers';

export const getExportData = (person) => {
  const {
    first_name: firstName,
    last_name: lastName,
    company,
    title,
    updated_at: updatedAt,
    created_at: createdAt,
    salesforce_lead_id: salesforceLeadId,
    salesforce_contact_id: salesforceContactId,
    id,
    social: { facebook, twitter, linkedin },
    unsubscribes,
  } = person;
  const filename = getPersonName(person);
  const exportPersonData = {
    [I18N.getStr('web.person.personDetails.export.firstName')]: firstName,
    [I18N.getStr('web.person.personDetails.export.lastName')]: lastName,
    [I18N.getStr('web.person.personDetails.export.company')]:
      company && company.name,
    [I18N.getStr('web.person.personDetails.export.title')]: title,
    [I18N.getStr(
      'web.person.personDetails.export.emailId'
    )]: getPrimaryEmailAddress(person).address,
    [I18N.getStr(
      'web.person.personDetails.export.phoneNumber'
    )]: getPrimaryPhoneAddress(person).address,
    [I18N.getStr(
      'web.person.personDetails.export.website'
    )]: getPrimaryWebsiteAddress(person).address,
    [I18N.getStr(
      'web.person.personDetails.export.other'
    )]: getPrimaryOtherAddress(person).address,
    [I18N.getStr('web.person.personDetails.export.updatedAt')]: updatedAt
      ? I18N.getLocalizedDateTime(updatedAt, I18N.DateFormats.DATETIME_SLASH)
      : updatedAt,
    [I18N.getStr('web.person.personDetails.export.createdAt')]: createdAt
      ? I18N.getLocalizedDateTime(createdAt, I18N.DateFormats.DATETIME_SLASH)
      : createdAt,
    [I18N.getStr('web.person.personDetails.export.salesforceId')]:
      salesforceLeadId || salesforceContactId,
    [I18N.getStr('web.person.personDetails.export.personId')]: id,
    [I18N.getStr('web.person.personDetails.export.facebook')]: facebook.value,
    [I18N.getStr('web.person.personDetails.export.twitter')]: twitter.value,
    [I18N.getStr('web.person.personDetails.export.linkedIn')]: linkedin.value,
  };

  if (unsubscribes && unsubscribes.length > 0) {
    exportPersonData[
      I18N.getStr('web.person.personDetails.export.unsubscribed')
    ] = I18N.getStr('common.yes');
  }

  return {
    columns: Object.keys(exportPersonData),
    data: Object.values(exportPersonData),
    filename,
  };
};
