export default {
  asyncUpdateError: 'TEMPLATES_ASYNC_UPDATE_ERROR',
  bulkUndoSuccess: 'TEMPLATES_BULK_UNDO_SUCCESS',
  bulkUpdateError: 'TEMPLATES_BULK_UPDATE_ERROR',
  bulkUpdateSuccess: 'TEMPLATES_BULK_UPDATE_SUCCESS',
  createCategoryError: 'TEMPLATES_CREATE_CATEGORY_ERROR',
  createDuplicateCategoryError: 'TEMPLATES_CREATE_DUPLICATE_CATEGORY_ERROR',
  createTemplateError: 'TEMPLATES_CREATE_TEMPLATE_ERROR',
  deleteCategoryError: 'TEMPLATES_DELETE_CATEGORY_ERROR',
  deleteCategoryNotEmptyError: 'TEMPLATES_DELETE_CATEGORY_NOT_EMPTY',
  fetchError: 'TEMPLATES_FETCH_ERROR',
  getCategoriesError: 'TEMPLATES_GET_CATEGORIES_ERROR',
  processing: 'TEMPLATES_PROCESSING',
  renameCategoryError: 'TEMPLATES_RENAME_CATEGORY_ERROR',
  renameDuplicateCategoryError: 'TEMPLATES_RENAME_DUPLICATE_CATEGORY_ERROR',
  syncUpdateError: 'TEMPLATES_SYNC_UPDATE_ERROR',
  templateLimitExceededError: 'TEMPLATES_TEMPLATE_LIMIT_EXCEEDED_ERROR',
};
