import React from 'react';
import ImageInsert from 'components/imageInsert';
import { PopupSize, ProgressStatus } from 'libs/constantsShared';
import FilesAttachment from 'web/composeWindow/components/filesAttachment';
import {
  buildHandleImageInsert,
  buildHandleFileAttachmentInsert,
} from 'web/composeWindow/helpers/composeWindowHelpers';
import { boxName } from 'web/integrations/box/libs/boxConstants';

export function insertImageDragAndDropDetails({
  editorInstance,
  images,
  closeImageInsertModal,
  imageInsertUploadFiles,
}) {
  const handleImageInsert = buildHandleImageInsert(
    editorInstance,
    images,
    closeImageInsertModal
  );

  const allFinished = images.every(
    (imageFile) => imageFile.status === ProgressStatus.finished
  );
  let onPrimary;

  if (images.length && allFinished) {
    onPrimary = handleImageInsert;
  }
  return {
    children: (
      <ImageInsert
        files={images}
        uploadFiles={imageInsertUploadFiles}
        multiple
      />
    ),
    footer: {
      onPrimary,
      onSecondary: closeImageInsertModal,
      primaryTextId: 'common.insert',
      secondaryTextId: 'common.cancel',
    },
    header: {
      onClose: closeImageInsertModal,
      textId: 'web.imageInsert.title',
    },
    size: PopupSize.small,
  };
}

export function attachFilesAndContentDetails({
  boxContentFiles,
  contentProvider,
  content,
  editorInstance,
  editorId,
  fileAttachmentsLoading,
  grabSelectedIds,
  bulkDeleteFiles,
  toggleTrackedContent,
  uploadFiles,
  closePopup,
  selectedComposeFiles,
  addAttachmentToEmail,
  closeFileAttachmentModal,
  size = PopupSize.massive,
}) {
  const isBoxProvider = contentProvider === boxName;
  const files = isBoxProvider ? boxContentFiles : content;
  const insertFileAttachment = buildHandleFileAttachmentInsert(
    editorInstance,
    editorId,
    files,
    selectedComposeFiles,
    addAttachmentToEmail,
    closeFileAttachmentModal
  );

  const noFilesSelected = selectedComposeFiles.ids.length === 0;

  return {
    children: (
      <FilesAttachment
        bulkDeleteFiles={bulkDeleteFiles}
        contentProvider={contentProvider}
        files={files}
        grabSelectedIds={grabSelectedIds}
        isLoading={fileAttachmentsLoading}
        isTracked={selectedComposeFiles.tracked}
        toggleTrackedContent={toggleTrackedContent}
        uploadFiles={uploadFiles}
      />
    ),
    footer: {
      onPrimary: insertFileAttachment,
      onSecondary: closePopup,
      primaryDisabled: noFilesSelected,
      primaryTextId: 'common.insert',
      secondaryTextId: 'common.cancel',
    },
    header: {
      onClose: closeFileAttachmentModal,
      textId: 'web.emailComposeBody.toolbar.addContent',
    },
    size,
  };
}
