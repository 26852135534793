import {
  MESSAGES,
  ICONS as ICON,
} from '../libs/onboardingIntegrationsConstants';
import { Urls } from 'web/libs/routes';
import { steps } from 'web/onboarding/lib/stepsRouting';

function getStatus(icon, message, link, count, allSteps) {
  return {
    allSteps,
    count,
    icon,
    link,
    message,
  };
}

export function getOnboardingStatus(userSettings) {
  const { passedOnboardingSteps } = userSettings;
  const allSteps = steps.length;

  const countPassed = passedOnboardingSteps ? passedOnboardingSteps.length : 0;
  const icon = countPassed !== allSteps ? ICON.NEUTRAL : ICON.POSITIVE;

  return getStatus(
    icon,
    MESSAGES.ONBOARDING_STATUS_FINISHED,
    Urls.onboarding.welcome,
    countPassed,
    allSteps
  );
}
