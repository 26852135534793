import DiagnosticsActionTypes from '../libs/diagnosticsActionTypes';
import moment from 'moment';
import { fromJS, Map } from 'immutable';
import {
  MIN_TASK_DATE_RANGE,
  OVERDUE_TASK_DATE_RANGE,
} from 'web/tasks/libs/tasksConstants';

const initState = {
  allItems: [],
  error: false,
  fetching: true,
  items: [],
  loading: false,
};

export function diagnostics(state = initState, action) {
  switch (action.type) {
    case DiagnosticsActionTypes.getItems: {
      return {
        ...initState,
        allItems: action.items,
        fetching: false,
        items: action.items,
      };
    }
    case DiagnosticsActionTypes.showAlert: {
      return {
        ...initState,
        error: true,
        fetching: false,
      };
    }
    case DiagnosticsActionTypes.clearAlert: {
      return {
        ...initState,
        error: false,
        fetching: false,
      };
    }
    case DiagnosticsActionTypes.filterItems: {
      const filteredItems = state.allItems.filter((item) =>
        moment(item.createdAt).isBetween(
          moment(action.start),
          moment(action.end)
        )
      );
      return {
        ...initState,
        allItems: state.allItems,
        fetching: false,
        items: filteredItems,
      };
    }
    case DiagnosticsActionTypes.removeItems:
    case DiagnosticsActionTypes.retryItems: {
      const ids = action.ids.map((id) => +id);
      const rows = state.allItems.filter((item) => !ids.includes(item.id));
      return {
        ...initState,
        allItems: rows,
        fetching: false,
        items: rows,
      };
    }
    default:
      return state;
  }
}

export const initDates = {
  end: moment()
    .add(MIN_TASK_DATE_RANGE, 'd')
    .startOf('d'),
  start: moment()
    .subtract(OVERDUE_TASK_DATE_RANGE, 'd')
    .startOf('d'),
};
export function tablesDiagnosticsSelectedDates(
  state = new Map({}),
  { type = '', id, start, end }
) {
  if (!id) {
    return state;
  }
  switch (type) {
    case DiagnosticsActionTypes.tableInit:
      if (state.has(id)) {
        return state;
      } else {
        return state.set(id, fromJS(initDates));
      }
    case DiagnosticsActionTypes.datesShown: {
      const fallback = state.has(id) ? state.get(id).toJS() : initDates;
      return state.update(id, () =>
        fromJS({
          end: end || fallback.end,
          start: start || fallback.start,
        })
      );
    }
    case DiagnosticsActionTypes.datesSmartFilterReset:
      return state.update(id, () => fromJS(initDates));
    default:
      return state;
  }
}

export function tablesDiagnosticsStatusSmartFilterValue(
  state = new Map({}),
  { type = '', id, value = '' }
) {
  if (!id) {
    return state;
  }
  switch (type) {
    case DiagnosticsActionTypes.tableInit:
      if (state.has(id)) {
        return state;
      } else {
        return state.set(id);
      }
    case DiagnosticsActionTypes.statusSmartFilter:
    case DiagnosticsActionTypes.datesSmartFilter:
      return state.update(id, () => value);
    default:
      return state;
  }
}

export function tablesDiagnosticsDatesSmartFilterValue(
  state = new Map({}),
  { type = '', id, value = '' }
) {
  if (!id) {
    return state;
  }
  switch (type) {
    case DiagnosticsActionTypes.tableInit:
      if (state.has(id)) {
        return state;
      } else {
        return state.set(id);
      }
    case DiagnosticsActionTypes.statusSmartFilter:
    case DiagnosticsActionTypes.datesSmartFilter:
      return state.update(id, () => value);
    default:
      return state;
  }
}
