import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import Pill from 'components/pill';
import Select from 'components/selects/base';
import Select2 from 'components/selects/select2';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import './groupsEditing.scss';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';

class GroupsEditing extends Component {
  constructor(props) {
    super(props);
    const { formatMessage } = props.intl;

    this.placeholderText = formatMessage({
      id: 'web.person.personDetails.groups.searchPlaceholder',
    });
    this.noResultsText = formatMessage({
      id: 'web.person.personDetails.groups.noGroupsPlaceholder',
    });
  }

  render() {
    const {
      editState: { addresses, groupsByAddressId, selectedEmailValue },
      groupsAddable,
    } = this.props;
    const formattedGroupName = groupsAddable.map((group) => ({
      value: group.value,
      label: getLocalizedDateFromGroupName(group.label),
    }));

    return (
      <div className="person-details-groups-editing">
        <h3 className="margin-bottom-half">
          <FormattedMessage id="common.groups" />
        </h3>
        {addresses.length > 1 && (
          <Select
            className="margin-bottom-half"
            items={addresses}
            onChange={this.onEmailsChange}
            selected={selectedEmailValue}
          />
        )}
        <div
          className={classNames('groups-editing-row', {
            'margin-bottom-half':
              groupsByAddressId[selectedEmailValue] &&
              groupsByAddressId[selectedEmailValue].length === 0,
          })}
        >
          {groupsByAddressId[selectedEmailValue] &&
            groupsByAddressId[selectedEmailValue].map(
              ({ value, label }, index) => (
                <Pill
                  className="margin-right-half margin-bottom-half"
                  id={index}
                  key={`groups-editing-${value}`}
                  onClose={this.onRemove}
                  text={getLocalizedDateFromGroupName(label)}
                />
              )
            )}
        </div>
        <Select2
          clearable={false}
          disabled={this._disableAdding()}
          placeholder={this.placeholderText}
          noResultsText={this.noResultsText}
          onChange={this.onAdd}
          onCloseResetsInput={false}
          options={formattedGroupName}
        />
        {this.renderButtonsBlock()}
      </div>
    );
  }

  onEmailsChange = (selectedEmailValue) => {
    this.props.updateEditState({ selectedEmailValue });
  };

  save = () => this.props.save(PersonDetailsCards.groups);

  cancel = () => this.props.cancel(PersonDetailsCards.groups);

  onAdd = (group) => {
    const {
      editState: { selectedEmailValue },
      addGroup,
    } = this.props;
    addGroup(selectedEmailValue, group);
  };

  onRemove = (index) => {
    const {
      editState: { selectedEmailValue },
      removeGroup,
    } = this.props;
    removeGroup(selectedEmailValue, index);
  };

  renderButtonsBlock = () => (
    <div className="groups-editing-button-row margin-top-half">
      <Button
        color={ButtonColors.gray}
        onClick={this.cancel}
        size={ButtonSizes.standard}
      >
        <FormattedMessage id="common.cancel" />
      </Button>
      {!this.props.hiddenButtons && (
        <Button
          className="margin-left-full"
          color={ButtonColors.blue}
          disabled={!this.props.groupsCardHasChanges}
          onClick={this.save}
          size={ButtonSizes.standard}
        >
          <FormattedMessage id="common.save" />
        </Button>
      )}
    </div>
  );

  _disableAdding = () => {
    const {
      editState: { invalidAddressesMap, selectedEmailValue },
    } = this.props;
    return !!invalidAddressesMap[selectedEmailValue];
  };
}

GroupsEditing.propTypes = {
  addGroup: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  editState: PropTypes.object.isRequired,
  groupsAddable: PropTypes.array.isRequired,
  groupsCardHasChanges: PropTypes.bool.isRequired,
  hiddenButtons: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  removeGroup: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  updateEditState: PropTypes.func.isRequired,
};

GroupsEditing.defaultProps = {};

export default injectIntl(GroupsEditing);
