import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/buttons/icon';
import classNames from 'classnames';

import './slideOutMenu.scss';

const ICON_SIZE = 30;

class SlideOutMenu extends Component {
  render() {
    const { slideOutMenuOpen } = this.props;
    const menuItems = this.getMenuIcons();
    const slideOutStyles = {
      width: slideOutMenuOpen ? ICON_SIZE * menuItems.length : 0,
    };

    return (
      this.isShowMenuIcons() && (
        <div className="slide-out-container">
          <span className="transition-region" style={slideOutStyles}>
            <div className="buttons-container">{menuItems}</div>
          </span>
          <Icon
            classes="tout-icon-dota"
            color="blue"
            onClick={this.toggleShowItems}
          />
        </div>
      )
    );
  }

  toggleShowItems = () => {
    const { setSlideOutMenuOpen, slideOutMenuOpen } = this.props;

    setSlideOutMenuOpen(!slideOutMenuOpen);
  };

  isShowMenuIcons = () => {
    const { slideOutMenuItems, isVisible } = this.props;

    return (
      slideOutMenuItems.some(({ disabled }) => disabled !== true) && isVisible
    );
  };

  getMenuIcons = () => {
    const { slideOutMenuItems } = this.props;

    return slideOutMenuItems.map((item, index) => (
      <Icon
        classes={classNames('tout-icon-menu', item.className)}
        color="blue"
        data-index={index}
        disabled={item.disabled}
        key={`dota-icon-${item.icon}`}
        onClick={item.action}
        title={item.title || ''}
      />
    ));
  };
}

SlideOutMenu.propTypes = {
  setSlideOutMenuOpen: PropTypes.func.isRequired,
  slideOutMenuOpen: PropTypes.bool.isRequired,
  slideOutMenuItems: PropTypes.array.isRequired,
  isVisible: PropTypes.bool,
};

SlideOutMenu.defaultProps = {
  isVisible: true,
};

export default SlideOutMenu;
