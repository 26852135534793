import React from 'react';
import PropTypes from 'prop-types';
import { BlockedReason } from 'libs/constantsShared';
import { FormattedMessage } from 'react-intl';
import { Icons } from 'libs/constantsStyles';

const PreviewStatus = ({ person }) => {
  if (person.blockedReason === BlockedReason.alreadyActive) {
    const values = {
      campaignBy: person.activeCampaignBy,
      campaignByExists:
        person.activeCampaignBy && person.activeCampaignBy.length
          ? 'true'
          : 'false',
      campaignName: person.activeCampaignName,
      campaignNameExists:
        person.activeCampaignName && person.activeCampaignName.length
          ? 'true'
          : 'false',
      name,
    };

    return (
      <FormattedMessage
        id="campaigns.previewTask.alreadyActive"
        values={values}
      />
    );
  } else if (person.blockedReason === BlockedReason.blockUnsubscribed) {
    return <FormattedMessage id="campaigns.previewTask.blockUnsubscribed" />;
  } else if (person.blockedReason === BlockedReason.bothUnsubscribed) {
    return <FormattedMessage id="campaigns.previewTask.bothUnsubscribed" />;
  } else if (person.blockedReason === BlockedReason.marketoUnsubscribed) {
    return <FormattedMessage id="campaigns.previewTask.marketoUnsubscribed" />;
  } else if (person.blockedReason === BlockedReason.domainBlocked) {
    return <FormattedMessage id="campaigns.previewTask.domainBlocked" />;
  } else if (person.blockedReason === BlockedReason.unsubscribed) {
    return <FormattedMessage id="campaigns.previewTask.unsubscribed" />;
  } else if (person.blockedReason === BlockedReason.invalidEmails) {
    return <FormattedMessage id="campaigns.previewTask.invalidEmails" />;
  } else if (person.blockedReason === BlockedReason.missingEmails) {
    return <FormattedMessage id="campaigns.previewTask.missingEmails" />;
  } else if (person.blockedReason === BlockedReason.notMarketoLeads) {
    return <FormattedMessage id="campaigns.previewTask.notMarketoContact" />;
  } else if (person.blockedReason === BlockedReason.maxCampaignLimitReached) {
    return (
      <FormattedMessage id="campaigns.previewTask.maxCampaignLimitReached" />
    );
  } else {
    return (
      <div>
        <div className={Icons.circleCheck} />
        <FormattedMessage id="campaigns.previewTask.willBeAdded" />
      </div>
    );
  }
};

PreviewStatus.defaultProps = {
  person: {},
};

PreviewStatus.propTypes = {
  person: PropTypes.object,
};

export default PreviewStatus;
