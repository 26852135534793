import { AlertColors } from 'components/alert/alert';
import { DEFAULT_ERROR_TEXT_ID } from './accountDetailsConstants';

export const successAlertOptions = {
  color: AlertColors.green,
  location: 'top',
  manual: false,
  textId: 'web.settings.myProfile.alert.changesSaved',
};

export const errorAlertOptions = {
  color: AlertColors.red,
  location: 'top',
  manual: false,
  textId: DEFAULT_ERROR_TEXT_ID,
};
