import React from 'react';
import PropTypes from 'prop-types';
import { TrackingPopupIds } from '../../libs/trackingConstants';
import DeleteCustomDomainConfirmationPopup from './deleteCustomDomainConfirmationPopup';

const Popups = (props) => {
  const { popup } = props;

  switch (popup) {
    case TrackingPopupIds.deleteCustomDomain:
      return <DeleteCustomDomainConfirmationPopup {...props} />;
    default:
      return null;
  }
};

Popups.propTypes = {
  popup: PropTypes.any,
  popupData: PropTypes.any,
};

Popups.defaultProps = {
  popup: null,
  popupData: {},
};

export default Popups;
