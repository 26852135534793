import moment from 'moment';
import { DateLabels } from 'web/liveFeed/tasks/libs/tasksConstants';

export const getDateInformation = (taskRemindTime) => {
  const timeNow = moment().startOf('day');
  const remindTime = moment(taskRemindTime).startOf('day');
  const timeDiff = moment(remindTime).diff(timeNow, 'day');
  const monthDay = remindTime.format('MMM D');
  const dateInformation = {
    date: monthDay,
    i18nDate: null,
  };
  switch (timeDiff) {
    case -5: {
      dateInformation.i18nDate = DateLabels.fiveDaysAgo;
      break;
    }
    case -4: {
      dateInformation.i18nDate = DateLabels.fourDaysAgo;
      break;
    }
    case -3: {
      dateInformation.i18nDate = DateLabels.threeDaysAgo;
      break;
    }
    case -2: {
      dateInformation.i18nDate = DateLabels.twoDaysAgo;
      break;
    }
    case -1: {
      dateInformation.i18nDate = DateLabels.yesterday;
      break;
    }
    case 0: {
      dateInformation.i18nDate = DateLabels.today;
      break;
    }
    case 1: {
      dateInformation.i18nDate = DateLabels.tomorrow;
      break;
    }
    default:
      break;
  }
  return dateInformation;
};
