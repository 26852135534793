/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Popup from 'components/popups';
import { getImageUrl } from 'web/libs/constants';
import { closeAlert } from 'web/marketoSettings/actionCreators/adminSettingsActionCreators';
import MarketoAdminAlert from 'web/marketoSettings/components/adminAlert/marketoAdminAlert';
import MarketoAdminWorkspacesModal from 'web/marketoSettings/components/adminWorkspacesModal';
import { setWorkspaces } from '../actionCreators/chooseMarketoWorkspacesActionCreators';
import {
  closePopup,
  getWorkspaces,
  updateWorkspaces,
} from 'web/marketoSettings/actionCreators/adminPopupActionCreators';
import { startup } from '../actionCreators/chooseMarketoWorkspacesActionCreators';
import { ChooseMarketoWorkspacesPopupid } from '../libs/chooseMarketoWorkspacesConstants';

class ChooseMarketoWorkspacesContainer extends Component {
  componentWillMount() {
    const { dispatch, emails, success, failure } = this.props;
    dispatch(startup(emails, success, failure));

    this.actionCreators = bindActionCreators(
      {
        closeAlert,
        closePopup,
        getWorkspaces,
        setWorkspaces,
        updateWorkspaces,
      },
      dispatch
    );
  }

  render() {
    const {
      alertId,
      emails,
      popup,
      popupLoading,
      selectedWorkspaces,
      workspaces,
    } = this.props;
    const footer = {
      onPrimary: this.actionCreators.setWorkspaces,
      onSecondary: this.actionCreators.closePopup,
      primaryTextId: 'common.connect',
      secondaryTextId: 'common.cancel',
    };
    const header = {
      onClose: this.actionCreators.closePopup,
      textId: 'common.connect',
    };
    return (
      <div>
        <MarketoAdminAlert
          closeAlert={this.actionCreators.closeAlert}
          alertId={alertId}
        />
        {popup === ChooseMarketoWorkspacesPopupid && (
          <Popup
            footer={footer}
            getImageUrl={getImageUrl}
            header={header}
            loading={popupLoading}
            onOutsideClick={this.actionCreators.closePopup}
          >
            <MarketoAdminWorkspacesModal
              actionCreators={this.actionCreators}
              selectedWorkspaces={selectedWorkspaces}
              workspaces={workspaces}
              count={(emails && emails.length) || 1}
            >
              <FormattedMessage id="web.marketoSettings.assignWorkspaces" />
            </MarketoAdminWorkspacesModal>
          </Popup>
        )}
      </div>
    );
  }
}

ChooseMarketoWorkspacesContainer.propTypes = {
  alertId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  failure: PropTypes.func.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object,
  popupLoading: PropTypes.bool.isRequired,
  selectedWorkspaces: PropTypes.arrayOf(PropTypes.string),
  success: PropTypes.func.isRequired,
  workspaces: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  alertId: state.marketoAdminAlertId,
  popup: state.popup,
  popupLoading: state.popupLoading,
  workspaces: state.marketoAdminWorkspaces,
  selectedWorkspaces: state.marketoAdminSelectedWorkspaces,
});

export default connect(mapStateToProps)(ChooseMarketoWorkspacesContainer);
