import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SetPassword from '../components/setPassword';
import SetPasswordSuccess from '../components/setPasswordSuccess';
import unauthorizedAdobePage from 'components/hocs/unauthorizedAdobePage';
import {
  sendSetPasswordRequest,
  initializeSetPasswordPanel,
  clearAlert,
} from '../actionCreators/setPasswordActionCreators';

class SetPasswordContainer extends Component {
  componentDidMount() {
    this.props.actionCreators.initializeSetPasswordPanel();
  }

  render() {
    const { isPasswordChanged, actionCreators } = this.props;

    return (
      <div className="set-password-panel">
        {isPasswordChanged ? (
          <SetPasswordSuccess />
        ) : (
          <SetPassword actionCreators={actionCreators} {...this.props} />
        )}
      </div>
    );
  }
}

SetPasswordContainer.propTypes = {
  alertTextId: PropTypes.string,
  isPasswordChanged: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

SetPasswordContainer.defaultProps = {
  alertTextId: '',
  isPasswordChanged: false,
};

const mapStateToProps = (state) => ({
  alertTextId: state.setPasswordAlertTextId,
  isPasswordChanged: state.setPasswordIsPasswordChanged,
  loading: state.setPasswordRequestLoading,
});

const mapDispatchToProps = {
  clearAlert,
  initializeSetPasswordPanel,
  sendSetPasswordRequest,
};

const mergeProps = (stateProps, dispathProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispathProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  (component) =>
    unauthorizedAdobePage(component, {
      cardWidth: 450,
      titleKey: 'login.setPassword.title',
    })
)(SetPasswordContainer);
