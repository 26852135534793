import ActionTypes from 'web/libs/actionTypes/actionTypes';

const tryCacheCategory = (filterId, cacheKey) => {
  if (filterId && cacheKey) {
    window.localStorage.setItem(cacheKey, filterId);
  }
};

export const setCategoryId = (categoryId) => ({
  type: ActionTypes.addToCampaignModal.state.category,
  data: categoryId,
});

export const onFilterChange = (filterId, cacheKey) => (dispatch, getState) => {
  tryCacheCategory(filterId, cacheKey);
  dispatch(setCategoryId(filterId));
};
