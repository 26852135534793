import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const CLASS_TEXT_CENTER = 'text-center';

const DropdownItem = ({
  titleId,
  link,
  isOpenNewTab,
  isAlignCenterLink,
  onLinkCallback,
  className: itemClassName,
}) => {
  const onClickLink = (e) => {
    if (onLinkCallback) {
      e.preventDefault();
      onLinkCallback();
    }
  };

  return (
    <li
      className={classNames(
        'nav-dropdown-item',
        {
          [CLASS_TEXT_CENTER]: isAlignCenterLink,
        },
        itemClassName
      )}
    >
      <a
        className="dropdown-item"
        href={link}
        onClick={onClickLink}
        {...(isOpenNewTab
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : {})}
      >
        <FormattedMessage id={titleId} />
      </a>
    </li>
  );
};

DropdownItem.propTypes = {
  titleId: PropTypes.string,
  link: PropTypes.string,
  isOpenNewTab: PropTypes.bool,
  isAlignCenterLink: PropTypes.bool,
  onLinkCallback: PropTypes.func,
  className: PropTypes.string,
};

DropdownItem.defaultProps = {
  titleId: null,
  link: null,
  isOpenNewTab: null,
  isAlignCenterLink: null,
  onLinkCallback: null,
  className: null,
};

export default DropdownItem;
