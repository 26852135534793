import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SalesforceCustomizationPopupIds } from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import SalesforceCustomizationPreUninstallPopup from 'web/modals/salesforceCustomization/components/popups/salesforceCustomizationPreUninstallPopup';
import SalesforceCustomizationPreUpgradePopup from 'web/modals/salesforceCustomization/components/popups/salesforceCustomizationPreUpgradePopup';
import SalesforceCustomizationPostUpdatePopup from 'web/modals/salesforceCustomization/components/popups/salesforceCustomizationPostUpdatePopup';
import SalesforceCustomizationUpdatePopup from 'web/modals/salesforceCustomization/components/popups/salesforceCustomizationUpdatePopup';
import SalesforceCustomizationWizardContainer from 'web/modals/salesforceCustomization/container/salesforceCustomizationWizardContainer';

class SalesforceCustomizationPopup extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    const { popup } = this.props;
    // TODO: 'Salesforce Admin Settings' page implemented on backboneJS so jsPane is using for scrolling.
    // TODO: This code will be changed when page will be implemented in ReactJS
    const jsPane = this.getJsPaneElement();

    switch (popup) {
      case SalesforceCustomizationPopupIds.salesforceCustomizationPreInstall:
      case SalesforceCustomizationPopupIds.salesforceCustomizationPreUninstall:
      case SalesforceCustomizationPopupIds.salesforceCustomizationPreUpgrade:
      case SalesforceCustomizationPopupIds.salesforceCustomizationInstallation:
      case SalesforceCustomizationPopupIds.salesforceCustomizationUninstallation:
      case SalesforceCustomizationPopupIds.salesforceCustomizationPostUninstall:
      case SalesforceCustomizationPopupIds.salesforceCustomizationPostInstall:
        // Hide scrolling
        this.hideJsPaneScroll(jsPane);
        break;
      default:
        // Show scrolling
        this.showJsPaneScroll(jsPane);
        break;
    }
  }

  getJsPaneElement() {
    const jsPanes = document.getElementsByClassName('jspPane');

    if (!jsPanes && jsPanes.length <= 0) {
      return undefined;
    }
    return Reflect.apply(Array.prototype.find, jsPanes, [
      (element) =>
        element.firstElementChild.classList.contains('content-inner'),
    ]);
  }

  // TODO: This method used only for jsPane component it should be deleted when 'Salesforce Admin Settings' page will be implemented in ReactJS
  // TODO: scss classes should be also removed from salesforceCustomizationContainer.scss file
  hideJsPaneScroll(jsPane) {
    if (jsPane) {
      jsPane.classList.add('no-scroll');

      const firstScrollBar = jsPane.nextElementSibling;
      if (
        firstScrollBar &&
        (firstScrollBar.classList.contains('jspVerticalBar') ||
          firstScrollBar.classList.contains('jspHorizontalBar'))
      ) {
        firstScrollBar.classList.add('hide-element');
      }

      const secondScrollBar = firstScrollBar
        ? firstScrollBar.nextElementSibling
        : undefined;
      if (
        secondScrollBar &&
        (secondScrollBar.classList.contains('jspVerticalBar') ||
          secondScrollBar.classList.contains('jspHorizontalBar'))
      ) {
        secondScrollBar.classList.add('hide-element');
      }
    }
  }

  // TODO: This method used only for jsPane component it should be deleted when 'Salesforce Admin Settings' page will be implemented in ReactJS
  // TODO: scss classes should be also removed from salesforceCustomizationContainer.scss file
  showJsPaneScroll(jsPane) {
    if (jsPane) {
      jsPane.classList.remove('no-scroll');

      const firstScrollBar = jsPane.nextElementSibling;
      if (
        firstScrollBar &&
        (firstScrollBar.classList.contains('jspVerticalBar') ||
          firstScrollBar.classList.contains('jspHorizontalBar'))
      ) {
        firstScrollBar.classList.remove('hide-element');
      }

      const secondScrollBar = firstScrollBar
        ? firstScrollBar.nextElementSibling
        : undefined;
      if (
        secondScrollBar &&
        (secondScrollBar.classList.contains('jspVerticalBar') ||
          secondScrollBar.classList.contains('jspHorizontalBar'))
      ) {
        secondScrollBar.classList.remove('hide-element');
      }
    }
  }

  render() {
    return <div>{this._getPopupComponent()}</div>;
  }

  _getPopupComponent() {
    const { popup, ...others } = this.props;

    switch (popup) {
      case SalesforceCustomizationPopupIds.salesforceCustomizationPreInstall:
        return <SalesforceCustomizationWizardContainer />;
      case SalesforceCustomizationPopupIds.salesforceCustomizationPreUninstall:
        return (
          <SalesforceCustomizationPreUninstallPopup
            popupId={popup}
            {...others}
          />
        );
      case SalesforceCustomizationPopupIds.salesforceCustomizationPreUpgrade:
        return (
          <SalesforceCustomizationPreUpgradePopup popupId={popup} {...others} />
        );
      case SalesforceCustomizationPopupIds.salesforceCustomizationInstallation:
      case SalesforceCustomizationPopupIds.salesforceCustomizationUninstallation:
        return (
          <SalesforceCustomizationUpdatePopup popupId={popup} {...others} />
        );
      case SalesforceCustomizationPopupIds.salesforceCustomizationPostUninstall:
      case SalesforceCustomizationPopupIds.salesforceCustomizationPostInstall:
        return (
          <SalesforceCustomizationPostUpdatePopup popupId={popup} {...others} />
        );
      default:
        return null;
    }
  }
}

SalesforceCustomizationPopup.propTypes = {
  popup: PropTypes.string.isRequired,
};

export default SalesforceCustomizationPopup;
