import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import TextButton from 'components/buttons/text';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import ViewMore from 'components/viewMore';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import './campaignInfo.scss';

const SHOWN_COUNT = 2;

class CampaignInfo extends Component {
  successCallbackThunk = (workflowInstanceId) => {
    const { markSuccess } = this.props;

    return () => {
      markSuccess(workflowInstanceId);
    };
  };

  removalCallbackThunk = (workflowInstanceId) => {
    const { remove } = this.props;

    return () => {
      remove(workflowInstanceId);
    };
  };

  getHoverTipContent({ name }) {
    return () => (
      <FormattedMessage
        id="web.person.personDetails.campaignsCard.campaignInfo.addedBy"
        values={{ name }}
      />
    );
  }

  render() {
    const { activeCampaigns, viewMoreOnClick } = this.props;
    return (
      <div className="person-details-campaign-info">
        <h3 className="margin-top-full margin-bottom-half">
          <FormattedMessage id="web.person.personDetails.campaignsCard.campaignInfo.active" />
        </h3>
        {activeCampaigns.length > 0 ? (
          <div className="active-campaigns-display-table">
            <div className="active-campaigns-row text-medium background-gray-extra-light">
              <div className="row-data margin-left-quarter">
                <FormattedMessage id="web.person.personDetails.campaignsCard.campaignInfo.addedOn" />
              </div>
              <div className="row-name">
                <FormattedMessage id="web.person.personDetails.campaignsCard.campaignInfo.name" />
              </div>
              <div className="row-data">
                <FormattedMessage id="web.person.personDetails.campaignsCard.campaignInfo.actions" />
              </div>
            </div>
            <ViewMore
              className="active-campaigns-rows"
              itemRenderer={this.itemRenderer}
              items={activeCampaigns}
              shownCount={SHOWN_COUNT}
              viewMoreOnClick={viewMoreOnClick}
              showCount
            />
          </div>
        ) : (
          <div className="no-active-campaign">
            <FormattedMessage id="web.person.personDetails.campaignsCard.campaignInfo.none" />
          </div>
        )}
      </div>
    );
  }

  itemRenderer = ({
    startedAt,
    id,
    workflowInstanceId,
    day,
    name,
    startedBy,
    success,
    url,
  }) => (
    <div
      className="active-campaigns-row"
      key={`active-campaign-${id}-${startedAt}`}
    >
      <div className="row-data margin-left-quarter">
        <HoverTooltip
          delayHide={0}
          delayShow={0}
          place={HoverTooltipPlace.right}
          stayOpen={false}
          tooltipId={`campaign-started-by-tooltip-${id}`}
          getContent={this.getHoverTipContent(startedBy)}
        >
          <FormattedMessage
            id="common.dateFormatShort"
            values={{ date: startedAt }}
          />
        </HoverTooltip>
      </div>
      <div className="text-overflow row-name">
        <a
          className="text-underline"
          href={url}
          onClick={(event) => this.openItemClick(id, event)}
        >
          {name}
        </a>
        <span>
          <FormattedMessage id="web.person.personDetails.campaignsCard.campaignInfo.day" />
          {day}
        </span>
      </div>
      <div
        className={classNames('row-data', {
          'text-green': success,
        })}
      >
        <TextButton onClick={this.successCallbackThunk(workflowInstanceId)}>
          <FormattedMessage id="common.success" />
        </TextButton>
        <span>{' | '}</span>
        <TextButton onClick={this.removalCallbackThunk(workflowInstanceId)}>
          <FormattedMessage id="common.remove" />
        </TextButton>
      </div>
    </div>
  );

  viewMoreOnClick = () =>
    this.props.viewMoreOnClick(PersonDetailsCards.campaigns);

  openItemClick = (workFlowId, e) => {
    e.preventDefault();
    const { activeCampaigns, handleNavigateToCampaign } = this.props;
    const activeCampaign = activeCampaigns.find(
      (campaign) => campaign.id === workFlowId
    );
    handleNavigateToCampaign(activeCampaign);
  };
}

CampaignInfo.propTypes = {
  active: PropTypes.object,
  activeCampaigns: PropTypes.array,
  handleNavigateToCampaign: PropTypes.func.isRequired,
  markSuccess: PropTypes.func.isRequired,
  openItemClick: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  viewMoreOnClick: PropTypes.func.isRequired,
};

CampaignInfo.defaultProps = {
  active: null,
  activeCampaigns: [],
};

export default CampaignInfo;
