/*eslint id-length: 0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import classNames from 'classnames';
import Radio from 'components/buttons/radio';
import SalesforceCustomizationWizardCommonStep from '../commonStep';
import {
  USERS_ADMINS,
  USERS_ALL,
  USERS_SPECIFIC,
} from '../../../libs/salesforceCustomizationConstants';
import './salesforceCustomizationWizardProfileUsers.scss';

class SalesforceCustomizationWizardProfileUsers extends Component {
  render() {
    const {
      className,
      intl: { formatMessage },
      profiles,
    } = this.props;

    return (
      <SalesforceCustomizationWizardCommonStep
        className={classNames('salesforce-profile-users', className)}
      >
        <h3 className="margin-bottom-full">
          <FormattedMessage id="web.salesforceCustomization.wizard.items.profile.users.title" />
        </h3>
        <div className="user-list">
          <label
            className={classNames('user-item user-admin', {
              'background-blue-gray-light':
                profiles.currentUser === USERS_ADMINS,
            })}
            style={{
              backgroundImage: `url(${getImageUrl(
                'salesforceUsers/install_admin_only_dimmed',
                'png'
              )})`,
            }}
          >
            <Radio
              checked={profiles.currentUser === USERS_ADMINS}
              className="user-radio-label"
              label={formatMessage({
                id:
                  'web.salesforceCustomization.wizard.items.profile.users.admins.install',
              })}
              name="users"
              onChange={this._onChange}
              value={USERS_ADMINS}
            />
          </label>
          <label
            className={classNames('user-item user-all', {
              'background-blue-gray-light': profiles.currentUser === USERS_ALL,
            })}
            style={{
              backgroundImage: `url(${getImageUrl(
                'salesforceUsers/install_all_users_dimmed',
                'png'
              )})`,
            }}
          >
            <Radio
              checked={profiles.currentUser === USERS_ALL}
              className="user-radio-label"
              label={formatMessage({
                id:
                  'web.salesforceCustomization.wizard.items.profile.users.all.install',
              })}
              name="users"
              onChange={this._onChange}
              value={USERS_ALL}
            />
          </label>
          <label
            className={classNames('user-item user-specific', {
              'background-blue-gray-light':
                profiles.currentUser === USERS_SPECIFIC,
            })}
            style={{
              backgroundImage: `url(${getImageUrl(
                'salesforceUsers/install_select_profiles_dimmed',
                'png'
              )})`,
            }}
          >
            <Radio
              checked={profiles.currentUser === USERS_SPECIFIC}
              className="user-radio-label"
              label={formatMessage({
                id:
                  'web.salesforceCustomization.wizard.items.profile.users.specific.install',
              })}
              name="users"
              onChange={this._onChange}
              value={USERS_SPECIFIC}
            />
          </label>
        </div>
      </SalesforceCustomizationWizardCommonStep>
    );
  }

  _onChange = (value) => {
    const { setProfileUser } = this.props;

    setProfileUser(value);
  };
}

SalesforceCustomizationWizardProfileUsers.propTypes = {
  className: PropTypes.string,
  intl: intlShape.isRequired,
  profiles: PropTypes.object.isRequired,
  setProfileUser: PropTypes.func.isRequired,
};

SalesforceCustomizationWizardProfileUsers.defaultProps = {
  className: '',
};

export default injectIntl(SalesforceCustomizationWizardProfileUsers);
