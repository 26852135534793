import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './sortChevron.scss';

const SortChevron = ({ className, down, up }) => (
  <div className={classNames('sort-chevron', className)}>
    <div className={classNames('sort-chevron-up', { active: up })} />
    <div className={classNames('sort-chevron-down', { active: down })} />
  </div>
);

SortChevron.propTypes = {
  className: PropTypes.string,
  down: PropTypes.bool,
  up: PropTypes.bool,
};

SortChevron.defaultProps = {
  className: '',
  down: false,
  up: false,
};

export default SortChevron;
