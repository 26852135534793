/*global safari*/
import { PopupSize } from 'libs/constantsShared';
import queryString from 'query-string';

/* Note: windowMode window.open's seem to add 50px to the height but the width stays the same */
// http://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
const chromeBarHeight = !!window.chrome && !!window.chrome.webstore ? 74 : 0;
const safariBarHeight =
  (window.HTMLElement &&
    window.HTMLElement.toString().indexOf('Constructor') > 0) ||
  ((temp) => temp.toString() === '[object SafariRemoteNotification]')(
    !window.safari || safari.pushNotification
  )
    ? 47
    : 0;
const firefoxHeight = typeof InstallTrigger !== 'undefined' ? 51 : 0;
const ieHeight = /*@cc_on!@*/ false || !!document.documentMode ? 90 : 0;
const edgeHeight = ieHeight === 0 && !!window.StyleMedia ? 70 : 0;
const additionalHeight =
  chromeBarHeight || safariBarHeight || firefoxHeight || ieHeight || edgeHeight;
const MIN_GLOBAL_HEIGHT = 325 + additionalHeight;
const ADD_TO_CAMPAIGN_HEIGHT = 710 + additionalHeight;
const PREVIEW_EMAIL_HEIGHT = 750 + additionalHeight;
const PREVIEW_EMAIL_ATTACHEMENTS_HEIGHT = PREVIEW_EMAIL_HEIGHT + 34;
const PREVIEW_TASK_BASE_HEIGHT = 500 + additionalHeight;
const PREVIEW_TASK_MAX_HEIGHT = 550 + additionalHeight;
const SALESFORCE_PROGRESS_HEIGHT = 550 + additionalHeight;
const SCHEDULE_CAMPAIGN_HEIGHT = 700 + additionalHeight;
const LAUNCHED_HEIGHT = 350 + additionalHeight;
const GONG_CELEBRATION_HEIGHT = 351; // test
const PEOPLE_SEARCH_ADD_HEIGHT = 710 + additionalHeight;
const CAMPAIGN_TYPE_CHOOSER = 450 + additionalHeight;
const CAMPAIGN_DEFAULT_WIDTH = 680;
const PREVIEW_EMAIL_WIDTH = 1030;

export const isWindowsOS = () =>
  window.Office
    ? window.Office.context.platform === 'PC'
    : window.navigator.platform.includes('Win');

export const isMacOS = () =>
  window.Office
    ? window.Office.context.platform === 'Mac'
    : window.navigator.platform.includes('Mac');

export const isOwaPluginMode = () =>
  typeof window.Office !== 'undefined' &&
  window.Office !== null &&
  typeof window.Office.onReady === 'function';

// A2C open - safari 491x650, others 444x650

/* Large width, min height (basically one line popups that fall back on min-height 100px) */
export function resizeToLargeMin() {
  window.resizeTo(PopupSize.large, MIN_GLOBAL_HEIGHT);
}

export function resizeToAddToCampaign() {
  window.resizeTo(CAMPAIGN_DEFAULT_WIDTH, ADD_TO_CAMPAIGN_HEIGHT);
}

export function resizeToSalesforceProgress() {
  window.resizeTo(CAMPAIGN_DEFAULT_WIDTH, SALESFORCE_PROGRESS_HEIGHT);
}

export function resizeToPreviews(hasAttachments) {
  window.resizeTo(
    PREVIEW_EMAIL_WIDTH,
    hasAttachments ? PREVIEW_EMAIL_ATTACHEMENTS_HEIGHT : PREVIEW_EMAIL_HEIGHT
  );
}

export function resizeToPreviewTasks(tasksTotal) {
  window.resizeTo(
    CAMPAIGN_DEFAULT_WIDTH,
    tasksTotal > 1 ? PREVIEW_TASK_MAX_HEIGHT : PREVIEW_TASK_BASE_HEIGHT
  );
}

export function resizeToSchedule() {

  window.resizeTo(CAMPAIGN_DEFAULT_WIDTH, SCHEDULE_CAMPAIGN_HEIGHT);
}

export function resizeToLaunched() {
  window.resizeTo(CAMPAIGN_DEFAULT_WIDTH, LAUNCHED_HEIGHT);
}

export function resizeToGongCelebration() {
  window.resizeTo(PopupSize.small, GONG_CELEBRATION_HEIGHT);
}

export function resizeToPeopleSearchAdd() {
  window.resizeTo(CAMPAIGN_DEFAULT_WIDTH, PEOPLE_SEARCH_ADD_HEIGHT);
}

export function resizeToCampaignTypeChooser() {
  window.resizeTo(PopupSize.large, CAMPAIGN_TYPE_CHOOSER);
}

export function getUrlQueryStringParams(url = window.location.href) {
  const paramsString = url.split('?')[1];

  return paramsString ? queryString.parse(paramsString) : {};
}

export function postMessageToParentWindow(message, targetOrigin = '*') {
  if (window.parent && window.parent.postMessage) {
    window.parent.postMessage(message, targetOrigin)
  }
}

export function postMessageToOpenerWindow(message, targetOrigin = '*') {
  if (window.opener && window.opener.postMessage) {
    window.opener.postMessage(message, targetOrigin)
  }
}
