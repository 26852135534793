import React from 'react';
import { BulkEmails } from 'web/composeWindow/libs/composeWindowConstants';
import HoverTooltip from 'components/hoverTooltip';
import './bulkHoverTooltip.scss';

const bulkHoverTooltip = (Component) => (props) => {
  const {
    rowData: { state, id },
    tooltipId,
  } = props;

  function getTooltipTextId(state) {
    switch (state) {
      case BulkEmails.stateReason.blocked:
        return 'web.composeWindow.bulkEmails.blockTooltip';
      case BulkEmails.stateReason.unsubscribed:
        return 'web.composeWindow.bulkEmails.unsubscribeTooltip';
      case BulkEmails.stateReason.invalidDynamicFields:
        return 'web.composeWindow.bulkEmails.fieldErrorTooltip';
      default:
        return '';
    }
  }

  return (
    <HoverTooltip
      className="bulk-emails-warning-tooltip"
      place="left"
      textId={getTooltipTextId(state)}
      tooltipId={`bulk-warning-${tooltipId}-${id}`}
      multiline
    >
      <Component {...props} />
    </HoverTooltip>
  );
};

export default bulkHoverTooltip;
