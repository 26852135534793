import React from 'react';
import { DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { ItemType } from './withDndSource';
import { getViewableCategories } from 'web/templates/selectors/templatesSelectors';
import './withDndTarget.scss';
import { TEMPLATE_NON_UPDATABLE_CATEGORIES } from 'web/templates/libs/templatesConstants';
import { bulkMoveTemplate } from 'web/templates/actionCreators/templatesBulkActionCreators';
import { onClearAll } from '../selectable/selectableActionCreators';

const CLASS_ON_HOVER = 'target-item--hover';

// Eventually need to abstract this to be a generic dnd component rather than just for templates
export default function withDndTarget(WrappedComponent) {
  function WidthDndTarget(props) {
    const { connectDropTarget, isOver, ...restProps } = props;

    return connectDropTarget(
      <div className={isOver ? CLASS_ON_HOVER : ''}>
        <WrappedComponent {...restProps} />
      </div>
    );
  }

  const mapStateToProps = (store) => ({
    selectedTemplateCategoryId: store.templatesSelectedTemplateCategoryId,
    templatesCategories: getViewableCategories(store),
  });

  const mapDispatchToProps = {
    bulkMoveTemplate,
    onClearAll,
  };

  // TODO figure out where connect actions for templates
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    DropTarget(
      ItemType,
      {
        canDrop: (props) => {
          const { elementId, templatesCategories } = props;
          const selectedCategory = templatesCategories.find(
            ({ id }) => id === elementId
          );
          return selectedCategory && selectedCategory.id > 0;
        },
        // eslint-disable-next-line consistent-return
        drop: (props, monitor) => {
          const item = monitor.getItem();
          const draggedItemId = item && item.id;
          const selectedCategory =
            props.templatesCategories.find((tc) => tc.id === props.elementId) ||
            {};
          const isUpdateble = !TEMPLATE_NON_UPDATABLE_CATEGORIES.reduce(
            (acc, cat) => cat.id === selectedCategory.id || acc,
            false
          );
          if (
            props.selectedTemplateCategoryId !== selectedCategory.id &&
            isUpdateble
          ) {
            props.bulkMoveTemplate(
              draggedItemId,
              selectedCategory,
              props.onClearAll
            );
          }
        },
      },
      (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        dropResult: monitor.getDropResult(),
        isOver: monitor.isOver() && monitor.canDrop(),
      })
    )(WidthDndTarget)
  );
}
