import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { EmailConnectionAlertIds } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import Alert, { AlertColors } from 'components/alert';
import './emailConnectionAlert.scss';

class EmailConnectionAlert extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      alert: { id },
      closeViewAlert,
    } = this.props;

    const defaultAlert = {
      classes: 'email-connection-alert',
      color: AlertColors.redDark,
      onClose: closeViewAlert,
    };

    switch (id) {
      case EmailConnectionAlertIds.changesSaved:
        return {
          ...defaultAlert,
          color: AlertColors.greenDark,
          textId: 'web.settings.emailConnection.alert.changesSaved',
        };
      case EmailConnectionAlertIds.changesNotSaved:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.changesNotSaved',
        };
      case EmailConnectionAlertIds.emailConnectionDisconnected:
        return {
          ...defaultAlert,
          color: AlertColors.greenDark,
          textId: 'web.settings.emailConnection.alert.connectionDisconnected',
        };
      case EmailConnectionAlertIds.emailConnectionNotDisconnected:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.genericError',
        };
      case EmailConnectionAlertIds.emailConnectionConnected:
        return {
          ...defaultAlert,
          color: AlertColors.greenDark,
          textId: 'web.settings.emailConnection.alert.connectionConnected',
        };
      case EmailConnectionAlertIds.emailConnectionNotConnected:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.genericError',
        };
      case EmailConnectionAlertIds.emailConnectionFetchError:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.connectionsFetchError',
        };
      case EmailConnectionAlertIds.emailConnectionFailure:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.genericError',
        };
      default:
        return null;
    }
  };
}

EmailConnectionAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

export default EmailConnectionAlert;
