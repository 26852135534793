import { navigateToUrl } from 'web/services/routerService';

const emptyCallback = () => {};

export const isChromeBrowser = !!window.chrome;

export function installChromeExtension(
  url = '',
  successCallback = emptyCallback,
  failureCallback = emptyCallback
) {
  if (isChromeBrowser) {
    navigateToUrl(url);
  }
}
