export default {
  addTeams: 'SHARING_SETTINGS_ADD_TEAMS',
  addTeamsOnChange: 'SHARING_SETTINGS_ADD_TEAMS_ON_CHANGE',
  categories: 'SHARING_SETTINGS_CATEGORIES',
  initStore: 'SHARING_SETTINGS_INIT_STORE',
  removeTeams: 'SHARING_SETTINGS_REMOVE_TEAMS',
  setAlert: 'SHARING_SETTINGS_SET_ALERT',
  setPermissionsEnabled: 'SHARING_SETTINGS_SET_PERMISSIONS_ENABLED',
  setSelectedCategory: 'SHARING_SETTINGS_SET_SELECTED_CATEGORY',
  setSidebarLoading: 'SHARING_SETTINGS_SET_SIDEBAR_LOADING',
  setTab: 'SHARING_SETTINGS_SET_TAB',
  setTableFetching: 'SHARING_SETTINGS_SET_TABLE_FETCHING',
  setTableLoading: 'SHARING_SETTINGS_SET_TABLE_LOADING',
  teams: 'SHARING_SETTINGS_TEAMS',
  unmount: 'SHARING_SETTINGS_UNMOUT',
  updateCategory: 'SHARING_SETTINGS_UPDATE_CATEGORY',
  updateTeams: 'SHARING_SETTINGS_UPDATE_TEAMS',
};
