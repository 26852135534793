export const TRACKING_CUSTOM_DOMAIN_FORM_ID = 'tracking-custom-domain-form-id';

export const TrackingPopupIds = {
  deleteCustomDomain: 'tracking-custom-domain-delete-confirmation',
};

export const TrackingTabs = {
  customDomainTracking: 'custom-domain-tracking',
  websiteTracking: 'website-tracking',
};

export const TrackingAlertIds = {
  success: 'TRACKING_PAGE_FORM_DATA_SAVED_SUCCESSFULLY',
  failure: 'TRACKING_PAGE_FORM_DATA_NOT_SAVED',
  cnameNotValid: 'TRACKING_PAGE_FORM_CNAME_NOT_VALID',
  unableToGetWebsiteTrackingEvents: 'UNABLE_TO_GET_WEBSITE_TRACKING_EVENTS',
};

export const WebsiteTrackingEventsTableProperties = {
  tableId: 'website-tracking-events-table',
  standardColumnWidth: 290,
  lastColumnWidth: 100,
  standardRowHeight: 50,
  maxHeight: 250,
  onClick: () => {},
};

export const VISIT_TRACKING_SCRIPT = `<script type="text/javascript">
(function() {var t = document.createElement('script'); t.type = 'text/javascript'; t.async = true;var u = document.location.href;var ti = document.title;var i = "?title=" + encodeURIComponent(ti) + "&url=" + encodeURIComponent(u);t.src = 'https://go.toutapp.com/site/%SITE_HASH%' + i;var st = document.getElementsByTagName('script')[0];st.parentNode.insertBefore(t, st);})();
</script>`;

export const ACTION_TRACKING_SCRIPT = `<script type="text/javascript">
var tout_action_taken = "Ordered Something"; // You can change this to anything
var tout_automatically_mark_email_as_success = true; // You can change this to false

(function() {var t = document.createElement('script'); t.type = 'text/javascript'; t.async = true;var u = document.location.href;var ti = document.title;if(tout_automatically_mark_email_as_success){u+='#success=true';}var i = "?action_taken=" + encodeURIComponent(tout_action_taken) + "&title=" + encodeURIComponent(ti) + "&url=" + encodeURIComponent(u);t.src =  'https://go.toutapp.com/action/%SITE_HASH%' + i;var st = document.getElementsByTagName('script')[0];st.parentNode.insertBefore(t, st);})();
</script>`;
