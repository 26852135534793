import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/inputText/inputText';
import ReactDOM from 'react-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import classNames from 'classnames';
import HoverTooltip from 'components/hoverTooltip';

import './editableHeader.scss';

class EditableHeader extends Component {
  constructor(props) {
    super(props);

    this.inputRef = null;
    this.buttonRef = null;

    this.state = {
      error: false,
      isEditingName: false,
    };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this._handleClick, false);
    const node = ReactDOM.findDOMNode(this.inputRef); // eslint-disable-line react/no-find-dom-node
    node && node.select();
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this._handleClick, false);
  }

  _handleClick = (e) => {
    const { value } = this.props;
    const { isEditingName } = this.state;

    if (!isEditingName) {
      return;
    }

    const inputNode = ReactDOM.findDOMNode(this.inputRef); // eslint-disable-line react/no-find-dom-node
    const cancelButtonNode = ReactDOM.findDOMNode(this.buttonRef); // eslint-disable-line react/no-find-dom-node

    if (cancelButtonNode.contains(e.target)) {
      this._cancel();
    } else if (!inputNode.contains(e.target)) {
      if (value.trim().length === 0) {
        inputNode.focus();
      } else {
        e.preventDefault();
        this._save();
      }
    }
  };

  _errorState = (newValueLength, valueLength) => {
    if (newValueLength === 0) {
      this.setState({ error: true });
    } else if (valueLength === 0) {
      this.setState({ error: false });
    }
  };

  _handleInputChange = (event) => {
    const { value } = this.props;
    const {
      target: { value: newValue },
    } = event;

    this._errorState(newValue.length, value.length);

    this.setState({
      editingName: newValue,
    });
  };

  _handleKeyDown = (e) => {
    const { value } = this.props;
    if (e.keyCode === 9 || e.keyCode === 13) {
      // tab or return

      if (value.length > 0) {
        this._save();
      } else {
        this._errorState(0);
      }
    }
  };

  _setButtonRef = (node) => {
    this.buttonRef = node;
  };

  _setinputRef = (node) => {
    this.inputRef = node;
  };

  _cancel = () => {
    const { value } = this.props;

    this.setState({
      editingName: value,
      isEditingName: false,
    });
  };

  _save = () => {
    const { onSave } = this.props;
    const { editingName } = this.state;

    onSave(editingName);

    this.setState({
      isEditingName: false,
    });
  };

  _editName = () => {
    const { value, isEditable } = this.props;

    if (isEditable) {
      this.setState({
        editingName: value,
        isEditingName: true,
      });
    }
  };

  _getLabel = () => {
    const { value, isEditable, maxCharactersShow, className } = this.props;
    const labelTag = (shortvalue) => (
      <h1
        className={classNames('text-overflow', className, {
          'editable-header-title': isEditable,
        })}
        onClick={this._editName}
      >
        {shortvalue}
      </h1>
    );

    if (maxCharactersShow && value.length > maxCharactersShow) {
      const nameSubstring = `${value.substring(0, maxCharactersShow)}...`;

      return (
        <HoverTooltip
          className="margin-left-quarter"
          textValue={value}
          tooltipId="editableheader-tooltip"
        >
          {labelTag(nameSubstring)}
        </HoverTooltip>
      );
    }

    return labelTag(value);
  };

  render() {
    const {
      intl: { formatMessage },
      placeHolderTextId,
    } = this.props;
    const { error, isEditingName, editingName } = this.state;

    return (
      <div className="editable-header-container">
        {isEditingName ? (
          <div>
            <InputText
              className={classNames('editable-header', { error })}
              onChange={this._handleInputChange}
              onKeyDown={this._handleKeyDown}
              placeholder={formatMessage({ id: placeHolderTextId })}
              ref={this._setinputRef}
              type="text"
              value={editingName}
              autoFocus
              toutUiStyle
            />
            <Button
              color={ButtonColors.blue}
              onClick={this._cancel}
              ref={this._setButtonRef}
              size={ButtonSizes.medium}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
          </div>
        ) : (
          this._getLabel()
        )}
      </div>
    );
  }
}

EditableHeader.propTypes = {
  className: PropTypes.string,
  intl: intlShape.isRequired,
  isEditable: PropTypes.bool.isRequired,
  maxCharactersShow: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  placeHolderTextId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

EditableHeader.defaultProps = {
  className: '',
  maxCharactersShow: undefined,
};

export default injectIntl(EditableHeader);
