import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import './modalOverlay.scss';

const ModalOverlay = ({
  backgroundColorClass,
  classes,
  getImageUrl,
  html,
  loading,
  size,
  textId,
  textValues,
}) => {
  const overlayClasses = classNames(
    'modalOverlay',
    classes,
    backgroundColorClass,
    size
  );

  return (
    <div className={overlayClasses}>
      <div className="modal-overlay-text">
        {html ? (
          <FormattedHTMLMessage id={textId} values={textValues} />
        ) : (
          <FormattedMessage id={textId} values={textValues} />
        )}
        {loading ? (
          <div className="modal-overlay-loading ">
            <LoadingSpinner imageUrl={getImageUrl} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ModalOverlay.propTypes = {
  backgroundColorClass: PropTypes.string,
  classes: PropTypes.string,
  getImageUrl: PropTypes.func,
  html: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.string,
  textId: PropTypes.string.isRequired,
  textValues: PropTypes.object,
};

ModalOverlay.defaultProps = {
  backgroundColorClass: '',
  classes: '',
  getImageUrl: null,
  html: false,
  loading: false,
  size: '',
  textValues: {},
};

export default ModalOverlay;
