import moment from 'moment';
import sortBy from 'lodash/sortBy';
import {
  AddressLocations,
  AddressLocationsOrderedEnum,
  AddressTypes,
  AddressTypesMultiple,
  AddressTypesSingle,
  CallOutcomes,
} from '../libs/personDetailsConstants';
import {
  AuthorizationTypes,
  SourceTypes,
} from 'web/compliance/libs/complianceConstants';
import {
  isConsentAuthorizationType,
  isCustomAuthorizationType,
  isCustomSourceType,
  isOtherAuthorizationType,
  isOtherSourceType,
} from 'web/compliance/helpers/complianceHelpers';
import { TasksTableStatusFilterValues } from 'web/tables/tasks/libs/tablesTasksConstants';
import { getSocialMediaUrl } from './personSocialMediaHelpers';
import {
  commonUserAdminTeamPermission,
  getUserFromSubscriptionTeams,
} from 'web/user/services/teamsService';
import { isCurrentUser } from 'web/user/services/userService';
import { Urls } from 'web/libs/routes';
import { getPrimaryAddress, getPrimaryEmailAddress } from './personHelpers';

export const parseTasksFromAllReminders = (allReminders = [], status) => {
  const done = status === TasksTableStatusFilterValues.completed;
  return allReminders.reduce((tasks, task) => {
    if (task.done === done) {
      tasks.push(task);
    }
    return tasks;
  }, []);
};

export const parseTasksUncompletedCount = (allReminders = []) =>
  allReminders.reduce((count, task) => (!task.done ? count + 1 : count), 0);

export const parseAddressType = (type = AddressTypes.other) =>
  (type === AddressTypes.im && AddressTypes.other) || type;

export const parseAddressLocation = (location) => {
  const loc = location && location.toLowerCase();

  if (Object.values(AddressLocations).includes(loc)) {
    return loc;
  } else {
    return AddressLocations.other;
  }
};

const getInitCategoryArray = (length) => {
  const base = [];
  for (let i = 0; i < length; i++) {
    base.push([]);
  }
  return base;
}; // each line is run but weird istanbul false-positive

/* istanbul ignore next */ const getInitAddressesSorted = () => {
  const obj = {};
  const categoryLength = AddressTypesMultiple.size;

  AddressTypesMultiple.forEach((item) => {
    obj[item] = getInitCategoryArray(categoryLength);
  });

  return obj;
};

const getInitSocial = () =>
  [...AddressTypesSingle].reduce(
    (accumulator, type) => ({ ...accumulator, [type]: { value: '', type } }),
    {}
  );

const getPrimaryAddresses = (addresses) => {
  const primaries = {};
  AddressTypesMultiple.forEach((item) => {
    primaries[item] = getPrimaryAddress(addresses, item);
  });

  return primaries;
};

/* Output format:
  addressesSorted: { email: [[work addresses], [personal addresses], [mobile addresses], [other addresses]], phone: [...], website: [...], other: [...] }
  social: { facebook: {}, linkedin: {}, twitter: {} }
*/
export const parseAddresses = (addresses = []) => {
  const addressesSorted = getInitAddressesSorted();
  const social = getInitSocial();
  const primaries = getPrimaryAddresses(addresses);

  addresses.forEach(
    ({
      address,
      address_type: addressType,
      id,
      is_primary: isMain,
      location,
    }) => {
      const type = parseAddressType(addressType);
      const loc = parseAddressLocation(location);

      if (AddressTypesMultiple.has(type)) {
        const isPrimary = isMain || id === primaries[type].id;
        addressesSorted[type][AddressLocationsOrderedEnum[loc]].push({
          id,
          isPrimary,
          location: loc,
          type,
          value: address,
        });
      } else if (AddressTypesSingle.has(type)) {
        social[type] = {
          id,
          isPrimary: true,
          location: loc,
          type,
          value: getSocialMediaUrl(type, address),
        };
      }
    }
  );

  return { addressesSorted, social };
};

const parseGroupsAddressMap = (addresses) => {
  let groupsEmailsCount = 0;
  const addressMap = addresses.reduce(
    (accumulator, { id, address, address_type: addressType }) => {
      const validType = addressType === AddressTypes.email;

      if (validType) {
        groupsEmailsCount += 1;
      }

      accumulator[id] = {
        label: address,
        validType,
        value: id,
      };
      return accumulator;
    },
    {}
  );

  return { addressMap, groupsEmailsCount };
};

const parseGroupsByAddressId = (groupMembers, addressMap) =>
  groupMembers.reduce(
    (
      accumulator,
      { group_name: groupName, group_id: groupId, address_id: addressId }
    ) => {
      const group = { label: groupName, value: groupId };
      if (accumulator[addressId]) {
        accumulator[addressId].groupsList.push(group);
      } else {
        const { label, validType, value } = addressMap[addressId];
        if (validType) {
          accumulator[addressId] = {
            groupsList: [group],
            addressLabel: label,
            addressValue: value,
            addressValidType: validType,
          };
        }
      }

      return accumulator;
    },
    {}
  );

export const parseGroupsFromGroupMembers = (
  groupMembers = [],
  addresses = []
) => {
  const { addressMap, groupsEmailsCount } = parseGroupsAddressMap(addresses);
  let groupsByAddressId = {};
  if (groupMembers.length > 0 && Object.keys(addressMap).length > 0) {
    groupsByAddressId = parseGroupsByAddressId(groupMembers, addressMap);
  }

  return { groupsByAddressId, groupsEmailsCount };
};

export const parseCustomFields = (customFields) => {
  // customFields can be null so not using default param
  const fields = Object.keys(customFields || {}).map((name, index) => ({
    name,
    value: customFields[name],
    id: `${name}-${index}`,
  }));
  return sortBy(fields, ({ name }) => name.toLowerCase());
};

const parseCall = (
  {
    created_at: createdAt,
    duration = 0,
    id = 0,
    notes,
    recording_url: recordingUrl,
    subject,
    user_id: userId,
  },
  usersMap
) => ({
  createdAt: moment(createdAt).toDate(),
  duration,
  id,
  notes: notes || '',
  outcome: subject || CallOutcomes.none,
  recordingUrl: recordingUrl || '',
  ...getUserFromSubscriptionTeams(userId, usersMap),
});

export const parseCalls = (calls = [], usersMap) =>
  calls
    .map((note) => parseCall(note, usersMap))
    .sort((first, second) => (first.createdAt >= second.createdAt && -1) || 1);

export const parseCampaign = (
  {
    completed,
    day,
    ended_at: endedAt,
    name,
    started_at: startedAt,
    success,
    user_id: userId,
    workflow_id: id,
    workflow_instance_id: workflowInstanceId,
  } = {},
  user = {},
  subscriptionUserMap = {}
) => ({
  completed,
  day,
  endedAt: moment(endedAt).toDate(),
  id,
  name,
  startedAt: moment(startedAt).toDate(),
  startedBy: subscriptionUserMap[userId],
  success,
  url: Urls.campaignTab.replace(':#id', id).replace(':#tab', 'setup'),
  userCanUpdate: commonUserAdminTeamPermission(user, userId),
  userId,
  workflowInstanceId,
});

export const parseCampaigns = (
  campaigns = [],
  user = {},
  subscriptionUserMap = {}
) =>
  campaigns.map((campaign) =>
    parseCampaign(campaign, user, subscriptionUserMap)
  );

const getCompany = ({ Account, Company }) =>
  Company || (Account && Account.Company);
const getDealAge = ({ Account, CreatedDate }) => {
  const timestamp = (Account && Account.CreatedDate) || CreatedDate;
  return timestamp && moment(timestamp).fromNow(true);
};
const getTerritory = (accountInfo) => {
  // Salesforce allows for many addresses. It can be 'City', or 'MailCity',
  // or 'OtherCity', etc. This iterates through all combinations of prefixes and
  // locations.
  const placePrefixes = ['', 'Mailing', 'Other'];
  let placePrefix;
  return ['City', 'State', 'Country']
    .map((placeName) => {
      if (placePrefix != null) {
        return accountInfo[placePrefix + placeName];
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const prefix of placePrefixes) {
        const place = accountInfo[prefix + placeName];
        if (place) {
          placePrefix = prefix;
          return place;
        }
      }
      return null;
    })
    .filter((place) => place)
    .join(', ');
};

export const parseSalesforceAccountInfo = (accountInfo) => {
  if (!accountInfo) {
    return null;
  }
  const parsedAccountInfo = [];
  if (accountInfo.attributes) {
    parsedAccountInfo.push({
      textId: 'web.person.personDetails.accountInfo.salesforceType',
      value: accountInfo.attributes.type,
    });
  }
  const company = getCompany(accountInfo);
  if (company) {
    parsedAccountInfo.push({
      textId: 'common.company',
      value: company,
    });
  }
  if (accountInfo.Account && accountInfo.Account.Name) {
    parsedAccountInfo.push({
      textId: 'common.account',
      value: accountInfo.Account.Name,
    });
  }
  if (accountInfo.Industry) {
    parsedAccountInfo.push({
      textId: 'common.industry',
      value: accountInfo.Industry,
    });
  }
  const dealAge = getDealAge(accountInfo);
  if (dealAge) {
    parsedAccountInfo.push({
      textId: 'web.person.personDetails.accountInfo.dealAge',
      value: dealAge[0].toUpperCase() + dealAge.substr(1),
    });
  }
  if (accountInfo.Status) {
    parsedAccountInfo.push({
      textId: 'web.person.personDetails.accountInfo.salesStage',
      value: accountInfo.Status,
    });
  }
  const territory = getTerritory(accountInfo);
  if (territory) {
    parsedAccountInfo.push({
      textId: 'web.person.personDetails.accountInfo.territory',
      value: territory,
    });
  }
  return parsedAccountInfo.length ? parsedAccountInfo : null;
};

export const formatCompliancePostData = (complianceDetails = {}) => {
  const {
    authorizationType,
    consentDate,
    consentNotesValue,
    consentPurposeValue,
    otherAuthorizationValue,
    otherSourceValue,
    sourceType,
  } = complianceDetails;

  let notes;
  let purpose;
  let consentedAt = consentDate;
  let authorization = authorizationType;
  let source = sourceType;

  if (!isConsentAuthorizationType(authorization)) {
    notes = null;
    purpose = null;
    consentedAt = null;
  } else {
    purpose = consentPurposeValue.trim();
    notes = consentNotesValue.trim() || null;
  }
  if (!authorizationType) {
    authorization = null;
  } else if (isOtherAuthorizationType(authorization)) {
    authorization = otherAuthorizationValue.trim();
  }

  if (!source) {
    source = null;
  } else if (isOtherSourceType(source)) {
    source = otherSourceValue.trim();
  }

  return {
    compliance: {
      authorization,
      source,
      notes,
      purpose,
      consented_at: consentedAt,
    },
  };
};

export const parseComplianceInfo = (complianceInfo = {}) => {
  const {
    authorization,
    consented_at: consentedAt,
    notes,
    purpose,
    source,
  } = complianceInfo;

  const consentDate = consentedAt || moment().startOf('');
  const consentNotesValue = notes || '';
  const consentPurposeValue = purpose || '';
  let otherSourceValue;
  let sourceType;

  if ((source && isCustomSourceType(source)) || isOtherSourceType(source)) {
    otherSourceValue = source;
    sourceType = SourceTypes.other;
  } else {
    otherSourceValue = '';
    sourceType = source;
  }

  let otherAuthorizationValue;
  let authorizationType;
  if (
    (authorization && isCustomAuthorizationType(authorization)) ||
    isOtherAuthorizationType(authorization)
  ) {
    otherAuthorizationValue = authorization;
    authorizationType = AuthorizationTypes.other;
  } else {
    otherAuthorizationValue = '';
    authorizationType = authorization;
  }

  return {
    authorizationType,
    consentPurposeValue,
    consentNotesValue,
    sourceType,
    consentDate,
    otherSourceValue,
    otherAuthorizationValue,
  };
};
