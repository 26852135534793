/* Warning: prod settings are set in constants.js. This is done to avoid including all our environments in prod build. */

const BackendUrls = {
  local: 'https://localhost:3334',
  dev: 'https://dev.toutapp.com',
  qa1: 'https://qa1.toutapp.com',
  qa2: 'https://qa2.toutapp.com',
  staging: 'https://staging.toutapp.com',
  production: 'https://toutapp.com',
};

const ImagesUrls = {
  local: 'https://dev.toutapp.com:3334/images',
  dev: 'https://dev.toutapp.com:3334/images',
  qa1: 'https://d2owagxlf083ds.cloudfront.net/tout-react/qa1/images',
  qa2: 'https://d2owagxlf083ds.cloudfront.net/tout-react/qa2/images',
  staging: 'https://d2owagxlf083ds.cloudfront.net/tout-react/staging/images',
  production:
    'https://d2owagxlf083ds.cloudfront.net/tout-react/production/images',
};

const CustomFontsUrls = {
  local: 'https://dev.toutapp.com:3334/styles/custom-fonts',
  dev: 'https://dev.toutapp.com:3334/styles/custom-fonts',
  qa1: 'https://d2owagxlf083ds.cloudfront.net/tout-react/qa1/styles/custom-fonts',
  qa2: 'https://d2owagxlf083ds.cloudfront.net/tout-react/qa2/styles/custom-fonts',
  staging: 'https://d2owagxlf083ds.cloudfront.net/tout-react/staging/styles/custom-fonts',
  production:
    'https://d2owagxlf083ds.cloudfront.net/tout-react/production/styles/custom-fonts',
};

const PusherEnvs = {
  local: 'development',
  dev: 'development',
  qa1: 'qa1',
  qa2: 'qa2',
  staging: 'staging',
  production: 'production',
};

const PusherKeys = {
  development: '92a8b0fe940563b3dfcd',
  staging: '3b8099fe8829227fabc2',
  production: '3479fa99c49db0822997',
};

/*eslint no-process-env: 0*/
export const BaseUrl = BackendUrls[process.env.TOUT_ENV] || BackendUrls.staging;
export const BaseUrlImages =
  ImagesUrls[process.env.TOUT_ENV] || ImagesUrls.staging;
export const PusherEnvironment =
  PusherEnvs[process.env.TOUT_ENV] || PusherEnvs.staging;
export const PusherKey = PusherKeys[PusherEnvironment] || PusherKeys.staging;
export const BaseUrlCustomFonts =
  CustomFontsUrls[process.env.TOUT_ENV] || CustomFontsUrls.staging;
