import queue from 'async/queue';
import TasksActionTypes from '../libs/tasksActionTypes';
import { PusherEvents } from 'web/libs/constants';
import { taskDestroyData, taskUpdateData } from './tasksActionCreators';
import { getUserChannel } from 'web/services/pusherService';

/* Not sure how to unit test queued calls */
const queuedTaskUpdateCall = queue(({ data = {}, dispatch }, cb) => {
  dispatch(taskUpdateData(data, true));
  cb();
}, 100);

const queuedTaskDestroyCall = queue(({ data = {}, dispatch }, cb) => {
  dispatch(taskDestroyData(data));
  cb();
}, 100);

export function initPusher() {
  return (dispatch, getState) => {
    const { tasksInit } = getState();

    if (!tasksInit) {
      getUserChannel().bind(PusherEvents.taskUpdate, (data) =>
        queuedTaskUpdateCall.push({ data, dispatch })
      );
      getUserChannel().bind(PusherEvents.taskDestroy, (data) =>
        queuedTaskDestroyCall.push({ data, dispatch })
      );
      dispatch({ type: TasksActionTypes.init });
    }
  };
}
