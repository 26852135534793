import React from 'react';
import PropTypes from 'prop-types';
import UserManagementPage from '../userManagementPage';
import AlertContainer from 'web/view/containers/alertContainer';
import UserManagementAlerts from '../userManagementAlerts';
import UserManagementPopups from '../popups/userManagementPopups';
import './userManagement.scss';

const UserManagement = (props) => (
  <div className="user-management-main-component">
    <UserManagementPage {...props} />
    <AlertContainer>
      <UserManagementAlerts />
    </AlertContainer>
    <UserManagementPopups />
  </div>
);

UserManagement.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default UserManagement;
