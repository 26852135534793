import {
  HAS_OPTED_OUT_OF_EMAIL_VALUE,
  TOUT_OPT_OUT__C_VALUE,
} from '../libs/adminUnsubscribesConstants';
import { UnsubscribePropertyTypes } from 'web/libs/mixpanelEvents';

export const getSyncTrackPropertyType = (value) => {
  switch (value) {
    case HAS_OPTED_OUT_OF_EMAIL_VALUE:
      return UnsubscribePropertyTypes.marketingOptOut;

    case TOUT_OPT_OUT__C_VALUE:
      return UnsubscribePropertyTypes.salesOptOut;

    default:
      return '';
  }
};

export const buildSettingsUnsubscribePayload = (state) => {
  const {
    settingsUnsubscribes: {
      allow_override_unsubscribe_text,
      block_unsubscribe_text,
      enable_unsubscribe_sync,
      salesforce_sync_field,
      unsubscribe_auto_append,
    },
  } = state;

  return {
    salesforce: {
      enable_unsubscribe_sync,
    },
    unsubscribe_setting: {
      allow_override_unsubscribe_text,
      block_unsubscribe_text,
      salesforce_sync_field,
      unsubscribe_auto_append,
    },
  };
};
