import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isNil from 'lodash/isNil';
import Action, { ButtonColors } from 'components/buttons/action';

const Slack = ({
  hasValidSlackCredentials,
  openDeleteSlackConfirmation,
  openNavigateToSlackConfirmation,
}) => (
  <div>
    <h3>
      <FormattedMessage id="web.settings.accountSettings.integrations.slack.title" />
    </h3>
    <div className="margin-top-20">
      <FormattedMessage id="web.settings.accountSettings.integrations.slack.description" />
    </div>
    {!isNil(hasValidSlackCredentials) && (
      <div className="card-buttons margin-top-20 right">
        {hasValidSlackCredentials ? (
          <Action
            color={ButtonColors.red}
            onClick={openDeleteSlackConfirmation}
            type="button"
          >
            <FormattedMessage id="common.disconnect" />
          </Action>
        ) : (
          <Action type="button" onClick={openNavigateToSlackConfirmation}>
            <FormattedMessage id="common.connect" />
          </Action>
        )}
      </div>
    )}
  </div>
);

Slack.propTypes = {
  hasValidSlackCredentials: PropTypes.bool,
  openDeleteSlackConfirmation: PropTypes.func.isRequired,
  openNavigateToSlackConfirmation: PropTypes.func.isRequired,
};

Slack.defaultProps = {
  hasValidSlackCredentials: null,
};

export default Slack;
