import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select2 from 'components/selects/select2';
import { injectIntl, intlShape } from 'react-intl';

class CampaignsUpdateCategory extends Component {
  onCategoryChange = (cat) => {
    if (typeof cat === 'object' && !Array.isArray(cat)) {
      this.props.updateCategory(cat);
    }
  };

  render() {
    const {
      categories,
      intl: { formatMessage },
      selectedCampaign,
      updatedCategory,
    } = this.props;
    let names = categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
    names.shift(); // remove all category
    names = names.filter((name) => name.value !== selectedCampaign.category_id);
    return (
      <div>
        <div className="margin-top-half margin-bottom-full">
          <div className="text-medium">{selectedCampaign.name}</div>
          <span className="text-medium margin-right-half">
            {`${formatMessage({
              id: 'web.campaigns.currentCategory',
            })}:`}
          </span>
          <span className="text-light">{`${selectedCampaign.category}`}</span>
        </div>
        <Select2
          classes="margin-bottom-full"
          clearable={false}
          noResultsText={formatMessage({
            id: 'web.campaigns.categoryNotFound',
          })}
          onChange={this.onCategoryChange}
          options={names}
          placeholder={formatMessage({ id: 'web.campaigns.chooseCategory' })}
          value={(updatedCategory && updatedCategory.value) || ''}
          searchable
        />
      </div>
    );
  }
}

CampaignsUpdateCategory.propTypes = {
  categories: PropTypes.array,
  intl: intlShape.isRequired,
  selectedCampaign: PropTypes.object,
  updateCategory: PropTypes.func,
  updatedCategory: PropTypes.object,
};

CampaignsUpdateCategory.defaultProps = {
  categories: [],
  selectedCampaign: {},
  updateCategory: undefined,
  updatedCategory: {},
};

export default injectIntl(CampaignsUpdateCategory);
