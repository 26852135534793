import React from 'react';
import PropTypes from 'prop-types';
import { ColorClasses } from 'libs/constantsStyles';

const CloseIcon = (props) => {
  const { width, height, color, onClick } = props;
  return (
    <svg
      height={height}
      onClick={onClick}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{`.a { fill: ${color} !important; }`}</style>
      </defs>
      <rect fill={color} height="18" id="Canvas" opacity="0" width="18" />
      <rect fill="#ff13dc" height="18" id="Canvas" opacity="0" width="18" />
      <path
        className="a"
        d="M13.2425,3.343,9,7.586,4.7575,3.343a.5.5,0,0,0-.707,0L3.343,4.05a.5.5,0,0,0,0,.707L7.586,9,3.343,13.2425a.5.5,0,0,0,0,.707l.707.7075a.5.5,0,0,0,.707,0L9,10.414l4.2425,4.243a.5.5,0,0,0,.707,0l.7075-.707a.5.5,0,0,0,0-.707L10.414,9l4.243-4.2425a.5.5,0,0,0,0-.707L13.95,3.343a.5.5,0,0,0-.70711-.00039Z"
      />
    </svg>
  );
};

CloseIcon.defaultProps = {
  color: ColorClasses.white,
  height: 12,
  width: 12,
};

CloseIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.number,
};

export default CloseIcon;
