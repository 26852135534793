/* global __DEV__ */
import { addLocaleData, IntlProvider } from 'react-intl';

import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ja';
import 'moment/locale/pt';
import _moment from 'moment';

import _momenttz from 'moment-timezone';
import $ from 'jquery';
import { Urls } from 'web/libs/routes';
import camelCase from 'lodash/camelCase';
import difference from 'lodash/difference';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';

//I18n - Loading the react-intl internal framework resource
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import de from 'react-intl/locale-data/de';
import ja from 'react-intl/locale-data/ja';
import pt from 'react-intl/locale-data/pt';

if (__DEV__) {
  /* eslint-disable global-require */
  require('moment/locale/af');
  require('moment/locale/hu');
  const af = require('react-intl/locale-data/af');
  const hu = require('react-intl/locale-data/hu');
  /* eslint-enable global-require */

  addLocaleData([...es, ...fr, ...de, ...ja, ...pt, ...af, ...hu]);
} else {
  addLocaleData([...es, ...fr, ...de, ...ja, ...pt]);
}
const UNAUTHORIZED = 'Unauthorized';
const ERROR = 'error';

// eslint-disable-next-line func-names
(function() {
  if ((Urls && Urls.hasOwnProperty('userSession')) && (!window.toutAppBootstrap || !window.toutAppBootstrap.accountData)) {
    if (!window.toutAppBootstrap) {
      window.toutAppBootstrap = {};
    }
    $.when(
      $.ajax({
        async: false,
        contentType: 'application/json',
        timeout: 6000,
        type: 'GET',
        url: Urls.userSession
      })
    ).then(function( user ) {
      if (user && !window.toutAppBootstrap.accountData) {
        if (user.statusText !== undefined) {
          window.toutAppBootstrap.accountData = user.statusText;
        } else {
          window.toutAppBootstrap.accountData = user;
        }
      }
    });
  }
})();

export default class I18N {
  // See: https://momentjs.com/docs/#/displaying/format/
  static DateFormats = {
    DATETIME_LONG: 'LLL', // September 4, 1986 8:30 PM
    DATETIME_SHORT: 'lll', // Sep 4, 1986 8:30 PM
    DATETIME_SLASH: 'L LT', // 09/04/1986 8:30 PM
    DATE_LONG: 'LL', // September 4, 1986
    DATE_SHORT: 'll', // Sep 4, 1986
    DATE_SLASH: 'L', // 09/04/1986
    DAY_DATETIME_LONG: 'LLLL', //Monday, September 9 2014 9:32 PM
    DAY_DATETIME_SHORT: 'llll', //Mon, Sep 4, 1986 8:30 PM
    DEFAULT_DATE: 'MM-DD-YYYY', // 09-04-2018
    DEFAULT_DATETIME_12HRS: 'MM-DD-YYYY h:mm A', // 09-04-2018 07:25 AM
    DEFAULT_DATETIME_24HRS: 'MM-DD-YYYY HH:mm', // 09-04-2018 18:30
    TIME_12AND24_HRS: ['h:mm A', 'HH:mm'], // [08:30 PM, 20:30]
    TIME_AMPM: 'h:mm A', // 08:30 PM
    TIME_SHORT: 'LT', // 8:30 PM
  };

  static CURRENCY_SYMBOL = '$';

  static NUMBER_FORMAT = '0,0.00';

  static FRACTION_LENGTH = 2;

  static defaultDateTimeFormats = [
    I18N.DateFormats.DEFAULT_DATETIME_12HRS,
    I18N.DateFormats.DEFAULT_DATETIME_24HRS,
  ];

  static resource = I18N.loadResource();

  static Languages = I18N.getLanguages();

  static defaultLanguage = I18N.Languages.en;

  static defaultLocale = 'en-US';

  static currLocale;

  static currLanguage;

  static currMessages;

  static fileName = 'common';

  static defaultTimeZone = 'America/Los_Angeles';

  static currUserLocale;

  static reactIntl;

  static loadResource() {
    /* eslint-disable global-require */
    /* New language to be added in "langResource" with language code and resource file */
    const langResource = {
      de: require('languages/translations/de_common.json'),
      en: require('languages/translations/en-us_common.json'),
      es: require('languages/translations/es_common.json'),
      fr: require('languages/translations/fr_common.json'),
      ja: require('languages/translations/ja_common.json'),
      pt: require('languages/translations/pt_common.json'),
    };
    let qaResource = {};
    if (__DEV__) {
      qaResource = {
        af: require('languages/translations/af_common.json'),
        hu: require('languages/translations/hu_common.json'),
      };
    }
    /* eslint-enable global-require */
    return {
      ...langResource,
      ...qaResource,
    };
  }

  static getSettingsLocale() {
    const user =
      window.toutAppBootstrap !== undefined
        ? window.toutAppBootstrap.accountData
        : '';
    const locale = user && user.locale !== undefined ? user.locale : '';
    I18N.currUserLocale = locale;
    if (locale) {
      const langCode = locale.substr(0, 2);
      if (
        Object.values(I18N.Languages).indexOf(langCode) !== -1 ||
        langCode === I18N.defaultLanguage
      ) {
        return locale;
      } else {
        return null;
      }
    }
    return locale;
  }

  static setReactIntl(locale, messages) {
    const { intl } = new IntlProvider(
      { locale, messages },
      {}
    ).getChildContext();
    I18N.reactIntl = intl;
  }

  static init() {
    const I18N_COOKIE = 'MKT_LANG';
    let locale = I18N.defaultLocale;
    if (
      I18N.i18nEnabled() ||
      window.toutAppBootstrap.accountData === undefined ||
      window.toutAppBootstrap.accountData === UNAUTHORIZED ||
      window.toutAppBootstrap.accountData === ERROR
    ) {
      const requestedLocale = I18N.getSettingsLocale();
      const cookieLocale = I18N.getCookie(I18N_COOKIE);
      const browserLocale = I18N.getBrowserLocale();
      const urlParam = queryString.parse(window.location.search);
      const urlLocale = urlParam && urlParam.locale || '';
      const hashParam = queryString.parse(window.location.hash.substring(window.location.hash.indexOf('?')));
      const hashLocale = hashParam && hashParam.locale || '';
      locale = urlLocale || hashLocale || requestedLocale || cookieLocale || browserLocale || locale;
      if (
        !locale.startsWith(I18N.defaultLanguage) &&
        Object.values(I18N.Languages).indexOf(locale.substr(0, 2)) === -1
      ) {
        locale = I18N.defaultLocale;
      }
      if (cookieLocale !== locale) {
        I18N.setCookie(I18N_COOKIE, locale);
      }
    }
    I18N.currLocale = locale;
    I18N.currLanguage = locale.startsWith(I18N.defaultLanguage)
      ? I18N.defaultLocale
      : I18N.currLocale.substr(0, 2);

    I18N.setCurrentLanguageResource(I18N.currLanguage.substr(0, 2));
    I18N.setReactIntl(locale, I18N.currMessages);
    return {
      locale: I18N.currLanguage,
      messages: I18N.currMessages,
    };
  }

  static setCurrentLanguageResource(language = 'en') {
    const defaultMessages = I18N.resource[I18N.defaultLanguage].messages;
    if (Object.keys(I18N.resource).indexOf(language) !== -1) {
      let localeMessages = I18N.resource[language].messages;
      const diffStrings =  difference(Object.keys(defaultMessages), Object.keys(localeMessages));
      const missingStrings = pickBy(defaultMessages, function(value, key) {
        return (diffStrings.includes(key));
      });
      localeMessages = Object.assign(localeMessages, missingStrings);
      I18N.currMessages = localeMessages;
    } else {
      I18N.currMessages = defaultMessages;
    }
  }

  static getLocale() {
    if (!I18N.currLocale) {
      I18N.init(null);
    }
    return I18N.currLocale;
  }

  static getLanguage() {
    if (!I18N.currLanguage) {
      I18N.init(null);
    }
    return I18N.currLanguage;
  }

  static getMessages() {
    if (!I18N.currMessages) {
      I18N.init(null);
    }
    return I18N.currMessages;
  }

  static getCookie(name) {
    //Get the mkto user locale from the cookie or null
    const pattern = new RegExp(`${name}=.[^;]*`);
    const matched = document.cookie.match(pattern);
    if (matched) {
      const cookie = matched[0].split('=');
      return cookie[1];
    }
    return false;
  }

  static getBrowserLocale() {
    if (typeof navigator === 'undefined') {
      return '';
    }
    return navigator.language || navigator.languages[0];
  }

  static getLocalizedDateFormat(pattern = I18N.DateFormats.DATE_SHORT) {
    return _moment()
      .localeData()
      .longDateFormat(pattern);
  }

  static getLocalizedDate(date = '', pattern = I18N.DateFormats.DATE_SHORT) {
    if (date) {
      return _moment(date).format(I18N.getLocalizedDateFormat(pattern));
    } else {
      return date;
    }
  }

  static getLocalizedDateTime(
    datetime = '',
    pattern = I18N.DateFormats.DATETIME_SHORT
  ) {
    if (datetime) {
      return _moment(datetime).format(pattern);
    } else {
      return datetime;
    }
  }

  static getLocalizedDateMonth(date = '') {
    if (date) {
      return _moment(date).format(
        I18N.getStr('web.customDatePattern.dateMonth')
      );
    }
    return null;
  }

  static getLocalizedMonthYear(date = '') {
    if (date) {
      return _moment(date).format(
        I18N.getStr('web.customDatePattern.monthYear')
      );
    }
    return null;
  }

  static getLocalizedFullDatePattern() {
    const dateFormat = I18N.getLocalizedDateFormat(
      I18N.DateFormats.DAY_DATETIME_SHORT
    );
    const timeFormat = I18N.getLocalizedDateFormat(I18N.DateFormats.TIME_SHORT);
    const fullDatePattern = dateFormat.replace(
      new RegExp(`\\s(${timeFormat})`, 'g'),
      ''
    );
    return fullDatePattern;
  }

  static getLocalizedTime(time = '', pattern = I18N.DateFormats.TIME_SHORT) {
    if (time) {
      const today = _moment().format(I18N.DateFormats.DEFAULT_DATE);
      return _moment(`${today} ${time}`, I18N.defaultDateTimeFormats).format(
        pattern
      );
    } else {
      return time;
    }
  }

  static getLocalizedFullDate(dateTime = '') {
    if (dateTime) {
      return _moment(dateTime).format(I18N.getLocalizedFullDatePattern());
    } else {
      return dateTime;
    }
  }

  static getLocalizedFullDateTime(dateTime = '') {
    if (dateTime) {
      return _moment(dateTime).format(I18N.DateFormats.DAY_DATETIME_SHORT);
    } else {
      return dateTime;
    }
  }

  static getLocalizedDateTimeWithTZ(
    dateTime = '',
    timeZone = null,
    includeAt = null
  ) {
    const dateTimeValue = dateTime.value || dateTime;
    if (dateTimeValue) {
      const date = I18N.getLocalizedFullDate(dateTimeValue);
      const time = _moment(dateTimeValue).format(I18N.DateFormats.TIME_SHORT);
      const tz = timeZone
        ? _moment.tz(timeZone).zoneAbbr()
        : dateTimeValue.tz(I18N.defaultTimeZone).format('z');
      return includeAt
        ? I18N.getStr('common.dateAtTimeTimeZone', { date, time, tz })
        : I18N.getLocalizedFullDateTime(dateTimeValue) + tz;
    } else {
      return dateTimeValue;
    }
  }

  static setCookie(cname, cvalue, min) {
    const date = new Date();
    let minutes = min;
    if (minutes === null) {
      minutes = 30;
    }
    date.setTime(date.getTime() + minutes * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${cname}=${cvalue}; ${expires}`;
  }

  static getStr(id, values) {
    return I18N.reactIntl.formatMessage({ id }, values);
  }

  static getConstStr(name, id, ...args) {
    if (name && id) {
      const saveId = id;
      const stringId = camelCase(id);
      const temp = I18N.getStr(`${name}.${stringId}`, args);
      if (temp.indexOf(`${name}.`) !== 0) {
        return temp;
      } else {
        return saveId;
      }
    }
    return id;
  }

  static i18nEnabled() {
    const subscription = (window.toutAppBootstrap &&
      window.toutAppBootstrap.accountData &&
      window.toutAppBootstrap.accountData.subscription) || {
      features: { i18n_enabled: false },
    };
    return subscription.features.i18n_enabled;
  }

  static isQALangauge(language = '') {
    if (language) {
      return language === I18N.Languages.af || language === I18N.Languages.hu;
    }
    return false;
  }

  static isStartsWithYear(pattern = '') {
    return pattern ? pattern.startsWith('YYYY') : false;
  }

  static setUnitTestResource(language = I18N.defaultLanguage) {
    I18N.currLocale = language;
    I18N.currLanguage = language;
    I18N.setCurrentLanguageResource(I18N.currLanguage.substr(0, 2));
    I18N.setReactIntl(language, I18N.currMessages);
  }

  static setLanguageCookie(
    language = I18N.defaultLanguage,
    locale = { value: I18N.defaultLocale }
  ) {
    const newLocale = I18N.isQALangauge(language) ? language : locale[0].value;
    if (I18N.currLocale !== newLocale) {
      I18N.setCookie('MKT_LANG', newLocale);
      I18N.setCookie('i18nLangUpdated', true);
    }
  }

  static getLocalizedCurrency(
    value = '',
    symbolAfter = false,
    fractionLength = I18N.FRACTION_LENGTH
  ) {
    if (value) {
      if (isNaN(parseFloat(value))) {
        value = Number(value.replace(/[^0-9.-]+/g, ''));
      }
      const fmtValue = I18N.reactIntl.formatNumber(parseFloat(value || 0), {
        maximumFractionDigits: fractionLength,
      });
      let fmtCurrency = `${I18N.CURRENCY_SYMBOL}${fmtValue}`;
      if (symbolAfter) {
        fmtCurrency = `${fmtValue}${I18N.CURRENCY_SYMBOL}`;
      }
      return fmtCurrency;
    }
    return (symbolAfter) ? `${value}${I18N.CURRENCY_SYMBOL}` : `${I18N.CURRENCY_SYMBOL}${value}`;
  }

  static getLocalizedNumber(number, numDigits = 0) {
    if(number != null && !isNaN(parseFloat(number)) && isFinite(number)) {
      return I18N.reactIntl.formatNumber(parseFloat(number || 0), { maximumFractionDigits: numDigits });
    }
    return number;
  }

  //Remove format of number to get whole number to save in Database
  static getWholeNumber(number) {
    return number.replace(/^[, ]+|[, ]+$|[, ]+/g, '').trim();
  }

  static getLanguages() {
    const language = {};
    const languageList = Object.keys(I18N.resource);
    for (let i = 0; i < languageList.length; i++) {
      const lang = languageList[i];
      language[lang] = lang;
    }
    return language;
  }
}

_moment.locale(I18N.getLocale());
export const moment = _moment;

_momenttz.locale(I18N.getLocale());
export const momentTZ = _momenttz;
