import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './twoPaneLayout.scss';

export default class TwoPaneLayout extends Component {
  render() {
    const { children } = this.props;

    const {
      borderRight,
      height,
      sidebarWidth,
      contentWidth,
    } = this._getStyles();

    return (
      <div className="two-pane-layout" style={{ height }}>
        <div
          className={classNames('two-pane-child', borderRight)}
          style={{ width: sidebarWidth }}
        >
          {children[0]}
        </div>
        <div className="two-pane-child" style={{ width: contentWidth }}>
          {children[1]}
        </div>
      </div>
    );
  }

  _getStyles = () => {
    const { borderColor, heightAdjustment, sidebarWidth } = this.props;
    let sidebar = sidebarWidth;
    if (typeof sidebarWidth === 'number') {
      sidebar = `${sidebarWidth}px`;
    }

    return {
      borderRight: (borderColor && `border-right-${borderColor}`) || '',
      contentWidth: `calc(100% - ${sidebar})`,
      height:
        (heightAdjustment && `calc(100% - ${heightAdjustment}px)`) || '100%',
      sidebarWidth: sidebar,
    };
  };
}

export const BorderColors = {
  grayLight: 'gray-light',
  none: '',
};

TwoPaneLayout.propTypes = {
  borderColor: PropTypes.oneOf(Object.values(BorderColors)),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  heightAdjustment: PropTypes.number,
  sidebarWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // String for %, number for px
};

TwoPaneLayout.defaultProps = {
  borderColor: BorderColors.none,
  heightAdjustment: 0,
  sidebarWidth: '16.6667%',
};
