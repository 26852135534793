import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Switch from 'components/buttons/switch';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';

class TemplateBypassUnsubscribe extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      intl: { formatMessage },
      userCanControlBypassUnsubscribe,
    } = this.props;

    return (
      <div className="template-bypass-unsubscribe">
        <div className="bypass-description margin-bottom-half">
          <FormattedMessage id="web.editTemplateSlideOut.templateBypassUnsubscribe.description" />
        </div>
        <div className="bypass-action">
          <div className="bypass-action-switch">
            <HoverTooltip
              delayHide={0}
              place={HoverTooltipPlace.right}
              textValue={formatMessage({
                id:
                  'web.editTemplateSlideOut.templateBypassUnsubscribe.switch.tooltip',
              })}
              tooltipId="template-bypass-unsubscribe"
              multiline
            >
              <Switch
                checked={this.props.template.bypass_unsubscribe}
                disabled={!userCanControlBypassUnsubscribe}
                label={this.getSwitchLabel()}
                name="template-unsubscribe-bypass"
                onChange={this._onChange}
                rightLabel
              />
            </HoverTooltip>
          </div>
        </div>
      </div>
    );
  }

  getSwitchLabel = () => {
    const {
      intl: { formatMessage },
      userCanControlBypassUnsubscribe,
    } = this.props;

    if (userCanControlBypassUnsubscribe) {
      return formatMessage({
        id:
          'web.editTemplateSlideOut.templateBypassUnsubscribe.switch.enabled.label',
      });
    } else {
      return formatMessage({
        id:
          'web.editTemplateSlideOut.templateBypassUnsubscribe.switch.disabled.label',
      });
    }
  };

  _onChange = (e) => {
    const {
      onBypassUnsubscribeChange,
      template,
      template: { bypass_unsubscribe: unsubscribeBypass },
    } = this.props;
    onBypassUnsubscribeChange(template.id, !unsubscribeBypass);
  };
}

TemplateBypassUnsubscribe.propTypes = {
  intl: intlShape.isRequired,
  onBypassUnsubscribeChange: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  userCanControlBypassUnsubscribe: PropTypes.bool.isRequired,
};

export default injectIntl(TemplateBypassUnsubscribe);
