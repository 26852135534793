import {
  minLength as minLengthFactory,
  regExpTest as regExpTestFactory,
  required as requiredValidatorFactory,
  sameAs as sameAsValidatorFactory,
} from 'web/form/helpers/validators';

export const minPasswordLength = 13;
const passwordRequirementsValidatorMessageId =
  'createAccount.form.validate.passwordRequirements';
const passwordDontMatchValidatorMessageId =
  'login.setPassword.error.passwordDontMatch';
const passwordRequirementsRegularExpressions = {
  number: /[0-9]/,
  symbols: /[!@#$%^&*?_~-]/,
  uppercaseLowercase: /([a-z].*[A-Z])|([A-Z].*[a-z])/,
};

const passwordRequiredValidator = requiredValidatorFactory(
  'createAccount.form.validate.passwordRequired'
);

export const passwordMinLengthValidator = minLengthFactory(
  minPasswordLength,
  passwordRequirementsValidatorMessageId
);

export const passwordContainsNumberValidator = regExpTestFactory(
  passwordRequirementsValidatorMessageId,
  passwordRequirementsRegularExpressions.number
);
export const passwordContainsSpecialSymbolValidator = regExpTestFactory(
  passwordRequirementsValidatorMessageId,
  passwordRequirementsRegularExpressions.symbols
);
export const passwordContainslowerUpperCaseValidator = regExpTestFactory(
  passwordRequirementsValidatorMessageId,
  passwordRequirementsRegularExpressions.uppercaseLowercase
);

export const allPasswordValidators = [
  passwordRequiredValidator,
  passwordMinLengthValidator,
  passwordContainsNumberValidator,
  passwordContainsSpecialSymbolValidator,
  passwordContainslowerUpperCaseValidator,
];

/* Confirm password */
export const confirmPasswordSameAsPasswordValidator = sameAsValidatorFactory(
  passwordDontMatchValidatorMessageId,
  'password'
);
const confirmPasswordRequiredValidator = requiredValidatorFactory(
  'createAccount.form.validate.confirmPasswordRequired'
);

export const allConfirmPasswordValidators = [
  confirmPasswordRequiredValidator,
  confirmPasswordSameAsPasswordValidator,
];
