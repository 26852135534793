import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import PersonDetailsActionTypes from 'web/person/libs/personDetailsActionTypes';
import {
  PEOPLE_SMART_FILTER_VALUES,
  InitialPeopleSortParams,
} from 'web/people/libs/peopleConstants';
import { DEFAULT_PAGE_SIZE } from 'web/elasticSearch/libs/elasticSearchConstants';

export const peopleLoading = (state = false, { type, isLoading = false }) => {
  switch (type) {
    case PeopleActionTypes.people.loading:
      return isLoading;
    default:
      return state;
  }
};

export const peopleTotal = (state = 0, { type, data }) => {
  switch (type) {
    case PeopleActionTypes.people.set:
      return data ? data.total : 0;
    default:
      return state;
  }
};

export const peoplePage = (state = 1, { type, page = 1 }) => {
  switch (type) {
    case PeopleActionTypes.table.page:
      return page;
    default:
      return state;
  }
};

export const peoplePerPage = (
  state = DEFAULT_PAGE_SIZE,
  { type, perPage = 1 }
) => {
  switch (type) {
    case PeopleActionTypes.table.perPage:
      return perPage;
    default:
      return state;
  }
};

const defaultData = [];
export const peopleGridData = (
  state = [],
  { type, data = defaultData, person = {} }
) => {
  switch (type) {
    case PeopleActionTypes.people.set:
      return data && data.results ? data.results : defaultData;
    case PersonDetailsActionTypes.setPerson: {
      const index = state.findIndex(({ id }) => id === person.id);
      if (index > -1) {
        const updatedPerson = { ...state[index], ...person };
        return [
          ...state.slice(0, index),
          updatedPerson,
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export const defaultSmartValue = PEOPLE_SMART_FILTER_VALUES.mine;
export const peopleSmartFilterValue = (
  state = defaultSmartValue,
  { type, value = defaultSmartValue }
) => {
  switch (type) {
    case PeopleActionTypes.smartFilters.set:
      return value;
    case PeopleActionTypes.table.reset:
      return defaultSmartValue;
    default:
      return state;
  }
};

export const initPeopleSort = InitialPeopleSortParams;
export const peopleSort = (
  state = initPeopleSort,
  { type, column = '', direction = '' }
) => {
  switch (type) {
    case PeopleActionTypes.table.sortInfo:
      return { column, direction };
    case PeopleActionTypes.table.reset:
      return initPeopleSort;
    default:
      return state;
  }
};

export const peopleSelectAllRequestParams = (
  state = null,
  { type, requestParams = null }
) => {
  switch (type) {
    case PeopleActionTypes.people.setSelectAllRequestParams:
      return requestParams;
    default:
      return state;
  }
};
