let tableExternals = {};

const get = () => tableExternals;
const set = (externals) => (tableExternals = { ...externals });
const reset = () => (tableExternals = {});

export default {
  get,
  set,
  reset,
};
