import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

const CloneAdvancedSearchConfirmation = ({ intl: { formatMessage } }) => (
  <div className="clone-advanced-search-confirmation text-white">
    <h3 className="text-medium margin-bottom-full text-white">
      {formatMessage({
        id: 'web.commandCenter.advancedSearch.cloneModalTitle',
      })}
    </h3>
    <div>
      {formatMessage({ id: 'web.commandCenter.advancedSearch.cloneModalBody' })}
    </div>
  </div>
);

CloneAdvancedSearchConfirmation.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CloneAdvancedSearchConfirmation);
