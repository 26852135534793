import  { postMessageToParentWindow } from 'web/services/windowService';
import { WindowEventNames } from 'libs/constantsShared';

export function openModalAddToCampaign(
  selectedIds,
  campaignId,
  mode,
  sourceOpener
) {
  window.toutReact.addToCampaignModal(
    selectedIds,
    campaignId,
    mode,
    sourceOpener
  );
}

export function closeModalAddToCampaign() {
  postMessageToParentWindow({action: WindowEventNames.close});
  window.toutReact.unmountNode('root-modal');
}

export function postSuccessAddToCampaign() {
  postMessageToParentWindow({action: WindowEventNames.addToCampaign});
}
