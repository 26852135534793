import PeopleSourceActionTypes from 'web/modals/peopleSource/libs/peopleSourceActionTypes';
import { savePeopleSource as savePeopleSourceCall } from 'web/modals/peopleSource/services/peopleSourceService';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import {
  ModalComponentIds,
  PEOPLE_SOURCE_POPUP_ID,
} from 'web/compliance/libs/complianceConstants';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { getPeople } from 'web/people/actionCreators/peopleFetchActionCreators';
import {
  setPopupAlert,
  setPopupLoading,
  closePopup,
  openPopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { isOtherSourceType } from 'web/compliance/helpers/complianceHelpers';
import PeoplePopupIds from 'web/people/libs/peoplePopupIds';

export const peopleSourceStartUp = (selectedIds) => (dispatch) => {
  dispatch({
    type: PeopleSourceActionTypes.modal.set,
    modal: ModalComponentIds.peopleSourceModal,
  });
  dispatch(openPopup(PEOPLE_SOURCE_POPUP_ID, selectedIds));
};

export const changePeopleSource = (source) => ({
  type: PeopleSourceActionTypes.selectPeopleSource,
  source,
});

export const closeSourceModal = () => (dispatch) => {
  dispatch(closePopup());
  dispatch({ type: PeopleSourceActionTypes.reset });
};

export const resetSource = () => ({
  type: PeopleSourceActionTypes.reset,
});

const setModalSuccess = (modal) => ({
  type: PeopleSourceActionTypes.modal.set,
  modal,
});

export const savePeoplesSource = () => (dispatch, getState) => {
  const {
    selectedPeopleSource: { sourceType, otherSourceValue } = {},
    popupData: { peopleIds, callBack: onSuccess },
  } = getState();
  const peopleSource = sourceType || null;

  const source = isOtherSourceType(peopleSource)
    ? otherSourceValue.trim()
    : peopleSource;
  dispatch(setPopupLoading(true));

  savePeopleSourceCall(peopleIds, source)
    .then(() => {
      dispatch(setModalSuccess(ModalComponentIds.peopleSourceModalSuccess));
      dispatch(
        openPopup(PeoplePopupIds.sourcePeopleSuccess, {
          peopleCount: peopleIds.length,
        })
      );
      dispatch(resetSource());
      toutBackboneHelper.refreshPeopleTable();
      onSuccess();
      dispatch(getPeople());
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.peopleCompliance.saveFail));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};
