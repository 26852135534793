export const TabValues = {
  campaigns: 'campaigns',
  templates: 'templates',
};

export const SharingSettingsPopupIds = {
  addTeams: 'SHARING_SETTINGS_POPUP_ADD_TEAMS',
  removeTeams: 'SHARING_SETTINGS_POPUP_REMOVE_TEAMS',
};

export const SharingSettingsAlertIds = {
  genericIssue: 'SHARING_SETTINGS_ALERT_GENERIC_ISSUE',
  getTeamsIssue: 'SHARING_SETTINGS_ALERT_GET_TEAMS_ISSUE',
  renameCategoryIssue: 'SHARING_SETTINGS_ALERT_RENAME_CATEGORY_ISSUE',
  settingSharingIssue: 'SHARING_SETTINGS_ALERT_SETTING_SHARING_ISSUE',
};
