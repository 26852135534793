import merge from 'lodash/merge';
import * as addToCampaignModalState from './addToCampaign/addToCampaignModalStateReducer';
import taskModal from 'web/modals/task/reducers/taskModalRootReducer';
import recordingNoticeModalRootReducer from 'web/modals/manageRecordingNotice/reducers/recordingNoticeModalRootReducer';
import dynamicFieldsModal from 'web/modals/dynamicFields/reducers/dynamicFieldsModalRootReducer';
import * as imageInsertModalState from './imageInsert/imageInsertModalStateReducer';
import * as updateCampaignTemplatesModalState from './campaignTemplates/updateCampaignTemplatesModalStateReducer';
import salesforceCustomizationRootReducer from 'web/modals/salesforceCustomization/reducers/salesforceCustomizationRootReducer';
import addToCampaignRootReducer from 'web/modals/addToCampaign/reducers/addToCampaignRootReducer';

const modalsRoot = merge(
  {},
  addToCampaignModalState,
  addToCampaignRootReducer,
  taskModal,
  recordingNoticeModalRootReducer,
  dynamicFieldsModal,
  imageInsertModalState,
  salesforceCustomizationRootReducer,
  updateCampaignTemplatesModalState
);

export default modalsRoot;
