import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import {
  addObjectToMapOfArrays,
  addObjectsToMapOfArrays,
  deleteObjectFromMapOfArrays,
  removeMapOfArrays,
  updateObjectInMapOfArrays,
} from '../helpers/emailComposeBodyStateHelpers';

export function emailComposeInfoBoxState(state = {}, action) {
  let temp = {};
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.infoBox:
      return { ...state, [action.id]: action.data }; // needed b/c use empty string for no state
    case ActionTypes.emailComposeBody.state.editEmailDestroy:
      temp = { ...state };
      Reflect.deleteProperty(temp, action.id);
      return temp;
    default:
      return state;
  }
}

export function emailComposeEditors(state = {}, action) {
  let temp = {};
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.ckEditorCreate:
      return { ...state, [action.id]: action.instance };
    case ActionTypes.emailComposeBody.state.ckEditorDestroy:
      temp = { ...state };
      Reflect.deleteProperty(temp, action.id);
      return temp;
    default:
      return state;
  }
}

export function emailComposeCkeBodies(state = {}, action) {
  let temp = {};
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.ckEditorCreate:
      return { ...state, [action.id]: '' };
    case ActionTypes.emailComposeBody.state.ckEditorBodyUpdate:
      return { ...state, [action.id]: action.body };
    case ActionTypes.emailComposeBody.state.ckEditorDestroy:
      temp = { ...state };
      Reflect.deleteProperty(temp, action.id);
      return temp;
    default:
      return state;
  }
}

export function dynamicFieldsActiveCategory(state = {}, action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.setDynamicFieldsActiveCategory:
      return { ...state, [action.editorId]: action.defaultCategory };
    default:
      return state;
  }
}

export function emailComposeAttachmentsMap(state = {}, action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.attachmentUploadStarting:
      return addObjectToMapOfArrays(state, action);
    case ActionTypes.emailComposeBody.state.attachmentUploadSuccess:
    case ActionTypes.emailComposeBody.state.attachmentUploadProgressUpdate:
      return updateObjectInMapOfArrays(
        state,
        action,
        (item) => item.reference === action.data.reference
      );
    case ActionTypes.emailComposeBody.state.templateAttachmentsAdd:
      return addObjectsToMapOfArrays(state, action, true);
    case ActionTypes.emailComposeBody.state.editEmailDestroy:
      return removeMapOfArrays(state, action);
    case ActionTypes.emailComposeBody.state.attachmentDelete:
      return deleteObjectFromMapOfArrays(state, action, action.comparator);
    default:
      return state;
  }
}

export function emailComposeContentUploadsMap(state = {}, action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.contentUploadStarting:
      return addObjectToMapOfArrays(state, action);
    case ActionTypes.emailComposeBody.state.contentUploadProgressUpdate:
      return updateObjectInMapOfArrays(
        state,
        action,
        (item) => item.reference === action.data.reference
      );
    case ActionTypes.emailComposeBody.state.editEmailDestroy:
      return removeMapOfArrays(state, action);
    case ActionTypes.emailComposeBody.state.contentUploadSuccess:
      return deleteObjectFromMapOfArrays(state, action, 'reference');
    default:
      return state;
  }
}

export function emailComposeLastTemplateCategory(state = '', action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.lastTemplateCategory:
      return action.data;
    default:
      return state;
  }
}

export function emailComposePopupLoading(state = false, action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.popupLoading:
      return action.data;
    case ActionTypes.emailComposeBody.state.popupClose:
      return false;
    default:
      return state;
  }
}

export function emailComposeImageInsertFile(state = null, action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.imageInsertUploadStarting:
      return action.data;
    case ActionTypes.emailComposeBody.state.imageInsertUploadProgressUpdate:
    case ActionTypes.emailComposeBody.state.imageInsertUploadSuccess:
      return { ...state, ...action.data };
    case ActionTypes.emailComposeBody.state.popupClose:
      return null;
    default:
      return state;
  }
}

export const initEditState = {
  height: '0',
  link: '',
  name: '',
  width: '0',
  url: '',
};
export function emailComposeImageInsertEditState(state = null, action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.imageInsertInitEditState:
      return { ...initEditState, ...action.data };
    case ActionTypes.emailComposeBody.state.imageInsertChange:
      return { ...state, ...action.data };
    case ActionTypes.emailComposeBody.state.popupClose:
      return null;
    default:
      return state;
  }
}

export function emailComposePopup(state = {}, action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.popupOpen:
      return { id: action.id, data: action.data, editorId: action.editorId };
    case ActionTypes.emailComposeBody.state.popupClose:
    case PopupActionTypes.close:
    case ActionTypes.modal.state.close:
      return {};
    default:
      return state;
  }
}

export function emailComposeAlert(state = {}, action) {
  switch (action.type) {
    case ActionTypes.emailComposeBody.state.alertSet:
      return { id: action.id, data: action.data };
    case ActionTypes.emailComposeBody.state.alertClear:
    case PopupActionTypes.close:
    case ActionTypes.modal.state.close:
      return {};
    default:
      return state;
  }
}
