export default {
  availableVersions: {
    set: 'SALESFORCE_CUSTOMIZATION_AVAILABLE_VERSIONS_SET',
    clear: 'SALESFORCE_CUSTOMIZATION_AVAILABLE_VERSIONS_CLEAR',
  },
  installedVersions: {
    set: 'SALESFORCE_CUSTOMIZATION_INSTALLED_VERSIONS_SET',
    clear: 'SALESFORCE_CUSTOMIZATION_INSTALLED_VERSIONS_CLEAR',
  },
  completedVersions: {
    add: 'SALESFORCE_CUSTOMIZATION_COMPLETED_VERSIONS_ADD',
    clear: 'SALESFORCE_CUSTOMIZATION_COMPLETED_VERSIONS_CLEAR',
  },
  versions: {
    clear: 'SALESFORCE_CUSTOMIZATION_VERSIONS_CLEAR',
  },
  progress: {
    set: 'SALESFORCE_CUSTOMIZATION_PROGRESS_SET',
    clear: 'SALESFORCE_CUSTOMIZATION_PROGRESS_CLEAR',
  },
  profiles: {
    availableUsersSet: 'SALESFORCE_CUSTOMIZATION_PROFILE_AVAILABLE_USERS_SET',
    availableUsersUpdate:
      'SALESFORCE_CUSTOMIZATION_PROFILE_AVAILABLE_USERS_UPDATE',
    userSet: 'SALESFORCE_CUSTOMIZATION_PROFILE_USER_SET',
  },
  errors: {
    set: 'SALESFORCE_CUSTOMIZATION_ERRORS_SET',
    clear: 'SALESFORCE_CUSTOMIZATION_ERRORS_CLEAR',
  },
  status: {
    set: 'SALESFORCE_CUSTOMIZATION_STATUS_SET',
    clear: 'SALESFORCE_CUSTOMIZATION_STATUS_CLEAR',
  },
  setWizardStep: 'SALESFORCE_CUSTOMIZATION_SET_WIZARD_STEP',
};
