import { stopCampaignForContacts } from 'web/people/services/peopleService';
import {
  closePopup,
  setPopupLoading,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { getPeople } from './peopleFetchActionCreators';

export const removePeopleFromCampaign = (peopleIds, isAdmin, onSuccess) => (
  dispatch
) => {
  dispatch(setPopupLoading(true));
  return stopCampaignForContacts(peopleIds, isAdmin)
    .then(() => {
      dispatch(getPeople());
      dispatch(closePopup());
      dispatch(openViewAlert(PeopleAlertIds.campaignRemovedNotification));
      onSuccess();
      dispatch(setPopupLoading(false));
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.genericApiError));
      dispatch(setPopupLoading(false));
    });
};
