/**
 *
 * Search
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SearchMultiple from 'components/inputs/searchMultiple';
import './search.scss';

class Search extends PureComponent {
  render() {
    const {
      noDropdown,
      onClear,
      onPrefixRemove,
      onResultSelected,
      placeholderText,
      prefix,
      results,
      value,
    } = this.props;

    return (
      <div className="tout-search">
        <SearchMultiple
          className="tout-search-search-multiple"
          clearSearch={onClear}
          noDropdown={noDropdown}
          onChange={this.onChange}
          onPrefixRemove={onPrefixRemove}
          onResultSelected={onResultSelected}
          placeholder={placeholderText}
          prefix={prefix}
          results={results}
          value={value}
          fullHeight
        />
      </div>
    );
  }

  onChange = (...args) => {
    this.props.onChange(...args);
    this.props.scrollTop();
  };
}

Search.propTypes = {
  noDropdown: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onPrefixRemove: PropTypes.func,
  onResultSelected: PropTypes.func,
  placeholderText: PropTypes.string,
  prefix: PropTypes.string,
  results: PropTypes.array,
  scrollTop: PropTypes.func,
  value: PropTypes.string,
};

Search.defaultProps = {
  noDropdown: false,
  onClear: undefined,
  onPrefixRemove: undefined,
  onResultSelected: undefined,
  placeholderText: 'Search',
  prefix: '',
  results: [],
  scrollTop: undefined,
  value: '',
};

export default Search;
