import map from 'lodash/map';
import { createSelector } from 'reselect';
import {
  getFetching as getTeamsFetching,
  getFetched as getTeamsFetched,
  getMasterTeamToAdmin,
} from 'web/teams/selectors/teamsSelectors';
import {
  getMasterTeamMembersFetching,
  getMasterTeamMembersFetched,
} from 'web/teamMembers/selectors/teamMembersSelectors';

export const getFetching = createSelector(
  [
    getTeamsFetching,
    getTeamsFetched,
    getMasterTeamMembersFetching,
    getMasterTeamMembersFetched,
  ],
  (
    teamsFetching,
    teamsFetched,
    masterTeamMembersFetching,
    masterTeamMembersFetched
  ) =>
    teamsFetching ||
    !teamsFetched ||
    masterTeamMembersFetching ||
    !masterTeamMembersFetched
);

export const getDeleteUserLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.users.deleteUserLoading;

export const getDeleteUsersLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.users.deleteUsersLoading;

export const getChangeRoleLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.users.changeRoleLoading;

export const getLoading = createSelector(
  [getChangeRoleLoading],
  (changeRoleLoading) => changeRoleLoading
);

export const getMasterTeamAdminIds = createSelector(
  [getMasterTeamToAdmin],
  (masterTeamToAdmin) => map(masterTeamToAdmin, 'adminId')
);
