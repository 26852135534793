import {
  CommandCenterEmailsTabsEnum,
  EmailStates,
} from 'web/commandCenter/libs/commandCenterConstants';
import { getActiveEmailTableIdByTabs } from 'web/commandCenter/helpers/emailHelpers';
import { navigateToEmailsSubTab } from 'web/services/routerService';

export const getAdvancedSearchTableId = () =>
  getActiveEmailTableIdByTabs(
    CommandCenterEmailsTabsEnum.search.value,
    CommandCenterEmailsTabsEnum.search.subTabs.advanced.value
  );

export const getGroupEmailTableId = () =>
  getActiveEmailTableIdByTabs(
    CommandCenterEmailsTabsEnum.search.value,
    CommandCenterEmailsTabsEnum.search.subTabs.groupEmail.value
  );

export const getSavedSearchTableId = () =>
  getActiveEmailTableIdByTabs(
    CommandCenterEmailsTabsEnum.search.value,
    CommandCenterEmailsTabsEnum.search.subTabs.saved.value
  );

export const navigateToAdvancedSearch = () =>
  navigateToEmailsSubTab(
    CommandCenterEmailsTabsEnum.search.value,
    CommandCenterEmailsTabsEnum.search.subTabs.advanced.value
  );

export const navigateToSavedSearch = () =>
  navigateToEmailsSubTab(
    CommandCenterEmailsTabsEnum.search.value,
    CommandCenterEmailsTabsEnum.search.subTabs.saved.value
  );

export const navigateToEmailGroupSearch = (parentPitchId) =>
  navigateToEmailsSubTab(
    CommandCenterEmailsTabsEnum.search.value,
    CommandCenterEmailsTabsEnum.search.subTabs.groupEmail.value,
    parentPitchId
  );

export const validateSelectedSubStatuses = (subTabIds) =>
  !!(subTabIds && subTabIds.length);

export const getCommandCenterEmailsTabForEmail = (email) => {
  const { archived, state } = email;

  const commandCenterTab = {
    subTab: CommandCenterEmailsTabsEnum.sent.subTabs.delivered.value,
    tab: CommandCenterEmailsTabsEnum.sent.value,
  };

  switch (state) {
    case EmailStates.sent:
    case EmailStates.clicked:
    case EmailStates.replied:
    case EmailStates.successful:
    case EmailStates.viewed:
      if (archived) {
        commandCenterTab.subTab =
          CommandCenterEmailsTabsEnum.sent.subTabs.archived.value;
      }
      break;
    case EmailStates.scheduledForDelivery:
      commandCenterTab.tab = CommandCenterEmailsTabsEnum.pending.value;
      commandCenterTab.subTab =
        CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value;
      break;
    case EmailStates.draft:
      commandCenterTab.tab = CommandCenterEmailsTabsEnum.pending.value;
      commandCenterTab.subTab =
        CommandCenterEmailsTabsEnum.pending.subTabs.drafts.value;
      break;
    case EmailStates.deliveryInProgress:
      commandCenterTab.tab = CommandCenterEmailsTabsEnum.pending.value;
      commandCenterTab.subTab =
        CommandCenterEmailsTabsEnum.pending.subTabs.inprogress.value;
      break;
    case EmailStates.failedDelivery:
      commandCenterTab.tab = CommandCenterEmailsTabsEnum.undelivered.value;
      commandCenterTab.subTab =
        CommandCenterEmailsTabsEnum.undelivered.subTabs.failed.value;
      break;
    case EmailStates.bounced:
      commandCenterTab.tab = CommandCenterEmailsTabsEnum.undelivered.value;
      commandCenterTab.subTab =
        CommandCenterEmailsTabsEnum.undelivered.subTabs.bounced.value;
      break;
    case EmailStates.spam:
      commandCenterTab.tab = CommandCenterEmailsTabsEnum.undelivered.value;
      commandCenterTab.subTab =
        CommandCenterEmailsTabsEnum.undelivered.subTabs.spam.value;
      break;
  }

  return commandCenterTab;
};
