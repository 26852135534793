import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UsersPopupIds } from '../../../libs/usersConstants';
import DeleteUserConfirmationPopup from '../deleteUserConfirmationPopup/deleteUserConfirmationPopup';
import DeleteUsersConfirmationPopup from '../deleteUsersConfirmationPopup/deleteUsersConfirmationPopup';
import popupWithErrors from 'components/hocs/popupWithErrors';

class UsersPopup extends Component {
  render() {
    const { popup } = this.props;

    switch (popup) {
      case UsersPopupIds.deleteUserConfirmation:
        return <DeleteUserConfirmationPopup {...this.props} />;
      case UsersPopupIds.deleteUsersConfirmation:
        return <DeleteUsersConfirmationPopup {...this.props} />;
      default:
        return null;
    }
  }
}

UsersPopup.propTypes = {
  popup: PropTypes.any,
};

UsersPopup.defaultProps = {
  popup: null,
};

export default popupWithErrors(UsersPopup);
