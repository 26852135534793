import get from 'lodash/get';
import { createSelector } from 'reselect';
import {
  percentCalculation,
  findContactIntegrationForSalesforce,
  getConnectLink,
} from '../helpers/salesforceHelpers';
import { SalesforceType } from '../libs/salesforceConstants';
import { isAdmin, getSubscription } from 'web/user/selectors/userSelectors';
import {
  isSalesforceBrokenForCurrentAccount,
  isSalesforceSetUpForCurrentAccount,
} from 'web/settings/myProfile/components/yourIntegrations/selectors/yourIntegrationsSelectors';
import { selectBrokenSfdcConnectionBannerDisplayedAt } from 'web/user/selectors/userSelectors';
import { Urls } from 'web/libs/routes';

// Api Calls
export const getApiCallsStatsFetched = (state) =>
  state.entities.salesforce.apiCalls.fetched;
export const getApiCallsStatsFetching = (state) =>
  state.entities.salesforce.apiCalls.fetching;
export const getApiCallsStats = (state) =>
  state.entities.salesforce.apiCalls.stats;

export const shouldInvalidateApiCallsStats = createSelector(
  [getApiCallsStatsFetched, getApiCallsStatsFetching],
  (fetched, fetching) => !fetched && !fetching
);

// Account
export const getAccountFetched = (state) =>
  state.entities.salesforce.account.fetched;
export const getAccountFetching = (state) =>
  state.entities.salesforce.account.fetching;
export const getAccount = (state) => state.entities.salesforce.account.item;

export const shouldInvalidateAccount = createSelector(
  [getAccountFetched, getAccountFetching],
  (fetched, fetching) => !fetched && !fetching
);

export const getAccountInstanceType = createSelector([getAccount], (account) =>
  get(account, 'instance_type', SalesforceType.salesforce)
);

// Integration
export const getIntegrationFetched = (state) =>
  state.entities.salesforce.integration.fetched;
export const getIntegrationFetching = (state) =>
  state.entities.salesforce.integration.fetching;
export const getIntegration = (state) =>
  state.entities.salesforce.integration.item;

// Contact Integrations
const getContactIntegrations = (state) =>
  state.entities.contactIntegrations.items;

export const shouldInvalidateIntegration = createSelector(
  [getIntegrationFetched, getIntegrationFetching],
  (fetched, fetching) => !fetched && !fetching
);

export const isIntegrationConnected = createSelector(
  [getIntegration],
  (integration) => !!integration['valid_credentials?']
);

export const isSyncViaApi = createSelector(
  [getIntegration],
  (integration) => !!integration.push_pitches
);

export const getStatsOccupancyPercent = createSelector(
  [getApiCallsStats],
  percentCalculation
);

export const getContactIntegrationForSalesforce = createSelector(
  [getContactIntegrations],
  findContactIntegrationForSalesforce
);

export const getSalesforceConnectLink = createSelector(
  [isAdmin],
  getConnectLink
);

export const isApplyTeamSettingsChecked = createSelector(
  [getSubscription],
  (subscription) =>
    !!get(subscription, 'admin_settings.salesforce.apply_team_settings', false)
);

export const shouldDisplayBrokenSfdcConnectionBanner = (state) => {
  const displayedAt = selectBrokenSfdcConnectionBannerDisplayedAt(state);
  if (
    !window.location.hash.includes(
      Urls.salesforceAccount().replace(/\/$/, '')
    ) &&
    !window.location.hash.includes(Urls.salesforceAdmin().replace(/\/$/, '')) &&
    isSalesforceSetUpForCurrentAccount(state) &&
    isSalesforceBrokenForCurrentAccount(state) &&
    (!displayedAt ||
      new Date().getTime() - new Date(displayedAt).getTime() >= 86400000)
  ) {
    return true;
  }
  return false;
};
