import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatBestBets } from '../selectors/pipelineSelectors';
import Pipeline from '../components/pipeline';
import * as pipelineActionCreators from 'web/liveFeed/pipeline/actionCreators/pipelineActionCreators';
import {
  getMessageId,
  setEngageAuthenthication,
  openEmail,
  openAddToCampaign,
  openCall,
  openSalesforceContact,
  openLinkedInSearch,
  openPdvForMarketoLead,
} from 'web/liveFeed/actionCreators/liveFeedActionCreators';
import { getSalesforceConnectLink } from 'web/salesforce/selectors/salesforceSelectors';

const PipelineContainer = (props) => (
  <div className="pipeline-container">
    <Pipeline {...props} />
  </div>
);

PipelineContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bestBets: formatBestBets(state),
  isLoading: state.liveFeedIsLoading,
  salesforceInstanceUrl: state.liveFeedSalesforceInstanceUrl,
  openChildrenLoadingIds: state.liveFeedPipelineOpenChildrenLoadingIds,
  errorMessageId: state.pipelineErrorMessageId,
  engageFeatureEnabled: state.liveFeedEngageUserAuthentication,
  saleforceConnectLink: getSalesforceConnectLink(state),
});

const mapDispatchToProps = {
  ...pipelineActionCreators,
  getMessageId,
  setEngageAuthenthication,
  openEmail,
  openAddToCampaign,
  openCall,
  openSalesforceContact,
  openLinkedInSearch,
  openPdvForMarketoLead,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PipelineContainer);
