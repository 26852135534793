import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DateAgo from 'components/dates/dateAgo';
import classNames from 'classnames';
import I18N, { moment } from 'languages';

const EmailsSentLastEvent = ({
  className,
  sendAt,
  deliveredAt,
  lastEventAt,
}) => (
  <div
    className={classNames(
      'emails-sent-last-event flex--horz-spread',
      className
    )}
  >
    {sendAt ? (
      I18N.getLocalizedFullDateTime(sendAt)
    ) : (
      <DateAgo
        className="text-gray"
        datetime={deliveredAt}
        textId="web.emails.emailsSentLastEvent.sent"
      />
    )}

    {(lastEventAt && (
      <DateAgo
        className="text-gray"
        datetime={lastEventAt}
        textId="web.emails.emailsSentLastEvent.lastEvent"
      />
    )) || (
      <span className="text-gray">
        <FormattedMessage id="web.emails.emailsSentLastEvent.lastEventEmpty" />
      </span>
    )}
  </div>
);

EmailsSentLastEvent.propTypes = {
  className: PropTypes.string,
  deliveredAt: PropTypes.string,
  lastEventAt: PropTypes.string,
  sendAt: PropTypes.string,
};

EmailsSentLastEvent.defaultProps = {
  className: '',
  deliveredAt: '',
  lastEventAt: '',
  sendAt: '',
};

export default EmailsSentLastEvent;
