import { createAction } from '@reduxjs/toolkit';
import {
  setSmartFilter,
  removeSmartFilter,
} from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import {
  PEOPLE_GROUP_FILTER_ID,
  peopleGroupSmartFilterLabel,
} from 'web/commandCenter/modules/whoSmartFilter/libs/whoSmartFilterConstants';

export const changePeopleGroupSmartFilterInputValue = createAction(
  'CHANGE_PEOPLE_GROUP_SMART_FILTER_INPUT'
);
export const setPeopleGroupFilterValue = ({ value, label: valueLabel }) => (
  dispatch
) => {
  dispatch(
    setSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: PEOPLE_GROUP_FILTER_ID,
      payload: {
        filterLabel: peopleGroupSmartFilterLabel,
        filterValues: [{ value, valueLabel }],
      },
    })
  );

  dispatch(
    changePeopleGroupSmartFilterInputValue({
      filterInputValue: {
        inputMode: false,
        inputValue: valueLabel,
      },
    })
  );
};

export const clearPeopleGroupFilterValue = () => (dispatch) => {
  dispatch(
    removeSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: PEOPLE_GROUP_FILTER_ID,
    })
  );

  dispatch(
    changePeopleGroupSmartFilterInputValue({
      filterInputValue: {
        inputMode: false,
      },
    })
  );
};

export const changePeopleGroupFilterInputValue = (inputValue) => (dispatch) => {
  dispatch(
    changePeopleGroupSmartFilterInputValue({
      filterInputValue: {
        inputValue,
      },
    })
  );
};
