/*
 *   DateAgo shows full date if more than 24 hours, otherwise, it uses moment's 'fromNow'
 *     to show ago time. It sets an interval of an hour if more than an hour old. If less
 *     than an hour, it updates every minute.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import DateAgoIntervalHandler from './dateAgoIntervalHandler';
import DateAgoComponent from './dateAgoComponent';
import I18N from 'languages';

const SECONDS_IN_DAY = 86400; // 24 * 60 * 60
const SECONDS_IN_HOUR = 3600; // 60 * 60
const MILLISECONDS_IN_HOUR = 3600000;
const MILLISECONDS_IN_MINUTE = 60000;

class DateAgo extends Component {
  state = {
    interval: 0,
  };

  componentWillMount() {
    this._setInterval(this.props.datetime);
  }

  componentWillReceiveProps({ datetime }) {
    this._setInterval(datetime);
  }

  render() {
    const { className, datetime, textId } = this.props;
    const { interval } = this.state;

    if (!datetime) {
      return <FormattedMessage id="dateAgo.unknownDate" />;
    }
    if (interval) {
      return <DateAgoIntervalHandler interval={interval} {...this.props} />;
    }
    return (
      <DateAgoComponent
        className={className}
        datetime={this._getDatetime()}
        textId={textId}
      />
    );
  }

  _setInterval = (datetime) => {
    const diff = moment().diff(datetime, 's');
    let interval = 0;

    if (diff < SECONDS_IN_HOUR) {
      interval = MILLISECONDS_IN_MINUTE;
    } else if (diff < SECONDS_IN_DAY) {
      interval = MILLISECONDS_IN_HOUR;
    }

    this.setState({ interval });
  };

  _getDatetime = () => {
    const { datetime, fullFormat, sliceYear } = this.props;
    const dateMoment = moment(datetime);
    const dateStr = dateMoment.format(fullFormat);

    if (sliceYear && moment().isSame(dateMoment, 'y')) {
      return dateMoment.format(
        I18N.getStr('web.customDatePattern.dateTimeShortWithoutYear')
      );
    }
    return dateStr;
  };
}

DateAgo.propTypes = {
  className: PropTypes.string,
  datetime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fullFormat: PropTypes.string,
  showNowTime: PropTypes.number,
  sliceYear: PropTypes.bool,
  textId: PropTypes.string,
};

DateAgo.defaultProps = {
  className: '',
  datetime: '',
  fullFormat: 'lll', // see moment docs
  showNowTime: 0,
  sliceYear: true,
  textId: '',
};

export default DateAgo;
