import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserUnsubscribesMessaging from '../components/userUnsubscribesMessaging';
import UserUnsubscribesNotAllow from '../components/userUnsubscribesNotAllow';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import Cards, { Direction } from 'components/layouts/cards';
import { CardsWidth } from '../libs/userUnsubscribesConstants';

class UserUnsubscribesMessagingContainer extends Component {
  getCards() {
    const { show, unsubscribeText } = this.props;

    return [
      {
        children: show ? (
          <UserUnsubscribesMessaging />
        ) : (
          <UserUnsubscribesNotAllow unsubscribeText={unsubscribeText} />
        ),
        id: 'unsubscribe',
        width: CardsWidth.messaging,
      },
    ];
  }

  render() {
    return (
      <Cards
        backgroundColor={BackgroundColors.grayExtraLight}
        className="admin-unsubscribes-cards-container"
        cards={this.getCards()}
        direction={Direction.vertical}
        marginAround={FULL}
        marginBetween={FULL}
      />
    );
  }
}

UserUnsubscribesMessagingContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  unsubscribeText: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  show: state.settingsUnsubscribes.allow_override_unsubscribe_text,
  unsubscribeText: state.settingsUnsubscribes.block_unsubscribe_text,
});

export default connect(mapStateToProps)(UserUnsubscribesMessagingContainer);
