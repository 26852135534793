import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StatShape } from '../statsShapes';
import Line, { BarChartLineColors } from './line';
import './barChart.scss';

class BarChart extends Component {
  render() {
    const { showLabels, lengthBars, lengthLabels } = this.props;
    const containerStyles = this._getContainerStyles();

    return (
      <div className="bar-chart" style={containerStyles}>
        {showLabels && (
          <div
            className="bar-chart-labels"
            style={{
              width: `${lengthLabels}px`,
              height: containerStyles.height,
            }}
          >
            {this._getBarLabels()}
          </div>
        )}
        <div
          className="bar-chart-lines"
          style={{ width: `${lengthBars}px`, height: containerStyles.height }}
        >
          {this._getBars()}
        </div>
      </div>
    );
  }

  _getContainerStyles = () => {
    const { height, lengthBars, lengthLabels } = this.props;
    return {
      height: `${height}px`,
      width: `${lengthLabels + lengthBars}px`,
    };
  };

  _getMaxStat = () =>
    this.props.stats.reduce((accum, { count, percent }) => {
      const value = count || percent || 0;
      return (value > accum && value) || accum;
    }, 0);

  _getBarLabels = () =>
    this.props.stats.map(({ label }, index) => {
      const barLabel = label || index;
      return (
        <div className="bar-chart-label" key={`bar-chart-label-${barLabel}`}>
          {barLabel}
        </div>
      );
    });

  _getBars = () => {
    const { showCount, stats, thickness, total, lengthBars } = this.props;
    const maxStat = this._getMaxStat();

    return stats.map((stat, index) => (
      <Line
        key={`bar-chart-line-${stat.label || index}-${stat.color}`}
        length={lengthBars}
        maxStat={maxStat}
        showCount={showCount}
        thickness={thickness}
        total={total}
        {...stat}
      />
    ));
  };
}

export const BarChartColors = { ...BarChartLineColors };

BarChart.propTypes = {
  height: PropTypes.number,
  lengthBars: PropTypes.number,
  lengthLabels: PropTypes.number,
  showCount: PropTypes.bool,
  showLabels: PropTypes.bool,
  stats: PropTypes.arrayOf(StatShape).isRequired,
  thickness: PropTypes.number,
  total: PropTypes.number,
};

BarChart.defaultProps = {
  height: 200,
  lengthBars: 200,
  lengthLabels: 100,
  showCount: false,
  showLabels: false,
  thickness: 20,
  total: 1,
};

export default BarChart;
