import CommandCenterActionTypes from 'web/commandCenter/libs/commandCenterActionTypes';
import { setSmartFilter } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';
import { VIEW_AS_FILTER_ID } from 'web/commandCenter/modules/whoSmartFilter/libs/whoSmartFilterConstants';

export const selectCommandCenterMenuItem = (itemIndex = 0) => ({
  itemIndex,
  type: CommandCenterActionTypes.sidebarMenu.selectItem,
});

export const setViewAsToEmailGridAdvancedSearchFilter = () => (
  dispatch,
  getState
) => {
  const state = getState();

  dispatch(
    setSmartFilter({
      collectionId: getActiveEmailTableId(state),
      filterId: VIEW_AS_FILTER_ID,
      payload: { viewAsViewer: state.sidebarViewer },
    })
  );
};
