import ActionTypes from 'table/modules/actionTypes';
import { SearchMinimum, Pagination } from 'table/modules/constants';

export function pagination(state = false, { type = '', set }) {
  switch (type) {
    case ActionTypes.paginationSet:
      return typeof set === 'boolean' ? set : state;
    default:
      return state;
  }
}

export function paginationClearSelected(
  state = false,
  { type = '', clearSelected = false }
) {
  switch (type) {
    case ActionTypes.paginationSetClearSelected:
      return clearSelected;
    default:
      return state;
  }
}

export const MIN_PAGE = 1;
export function paginationCurrentPage(
  state = MIN_PAGE,
  {
    type = '',
    items = [],
    page = MIN_PAGE,
    pagination: paginationProps = {},
    value = [],
  }
) {
  switch (type) {
    case ActionTypes.paginationSetCurrentPage:
      return page;
    case ActionTypes.searchSetValue:
      if (value.length >= SearchMinimum) {
        return MIN_PAGE;
      }
      return state;
    case ActionTypes.smartFilterSetSelectedValue:
      return MIN_PAGE;
    case ActionTypes.tableSetItems:
      if (
        paginationProps.perPage &&
        items.length &&
        (state - 1) * paginationProps.perPage >= items.length
      ) {
        return Math.ceil(items.length / paginationProps.perPage) || MIN_PAGE;
      }
      return state;
    default:
      return state;
  }
}

export const paginationPerPage = (
  state = Pagination.perPageValues[0],
  { type, perPage }
) => {
  switch (type) {
    case ActionTypes.paginationSetPerPage:
      return perPage;
    default:
      return state;
  }
};

const initItems = [];
export function paginationItems(state = initItems, { type = '', items = [] }) {
  switch (type) {
    case ActionTypes.paginationSetItems:
      return items;
    default:
      return state;
  }
}
