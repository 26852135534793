import I18N from 'languages';

export const UnsubscribeShowForUserItems = [
  {
    value: 0,
    label: I18N.getStr('web.settings.unsubscribe.showForUserItems.notAllow'),
  },
  {
    value: 1,
    label: I18N.getStr('web.settings.unsubscribe.showForUserItems.allow'),
  },
];

export const SyncToSalesforceItems = [
  {
    value: 'HasOptedOutOfEmail',
    label: I18N.getStr(
      'web.settings.unsubscribe.syncToSalesforceItems.hasOptedOutOfEmail'
    ),
  },
  {
    value: 'MSE_Opt_Out__c',
    label: I18N.getStr(
      'web.settings.unsubscribe.syncToSalesforceItems.toutOptOutC'
    ),
  },
];

export const HAS_OPTED_OUT_OF_EMAIL_KEY = 0;

export const TOUT_OPT_OUT__C_KEY = 1;

export const HAS_OPTED_OUT_OF_EMAIL_VALUE = 'HasOptedOutOfEmail';

export const TOUT_OPT_OUT__C_VALUE = 'MSE_Opt_Out__c';

export const AdminUnsubscribeTabs = {
  messaging: 'messaging',
  salesforce: 'salesforce',
};

export const EnableSyncSalesforceLabels = {
  enabled: I18N.getStr('common.enabled'),
  disabled: I18N.getStr('common.disabled'),
};

export const AdminUnsubscribeCardsWidth = {
  messaging: 600,
  salesforce: 750,
};

export const ADMIN_UNSUBSCRIBE_TAB_WIDTH = 200;
