import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import EmailServerForm from '../emailServerForm';
import 'styles/assets/_spacing.scss';

const defaultModel = {
  connection_type: 'plain',
  port: '25',
};

class EmailServerFormCard extends Component {
  render() {
    const {
      model,
      formName,
      descriptionMessageId,
      additionalServerValidators,
    } = this.props;

    const { onSave, onDelete, onError } = this.props.actionCreators;

    return (
      <div>
        <h3>
          <FormattedMessage id="web.settings.emailServer.formTitle" />
        </h3>
        <div className="margin-top-half">
          <FormattedMessage id={descriptionMessageId} />
        </div>
        <EmailServerForm
          form={formName}
          initialValues={this.initialModel(model)}
          isNew={!model}
          onSubmit={onSave}
          onDelete={onDelete}
          onError={onError}
          additionalServerValidators={additionalServerValidators}
          onChange={this.validateServer}
        />
      </div>
    );
  }

  initialModel(model) {
    return model || defaultModel;
  }

  validateServer = (values) => {
    const { additionalServerValidators } = this.props;

    const { onError } = this.props.actionCreators;

    for (const validator of additionalServerValidators) {
      const validationError = validator(values.server);
      if (validationError) {
        onError({ server: validationError });
        return;
      }
    }
  };
}

EmailServerFormCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  model: PropTypes.object,
  formName: PropTypes.string.isRequired,
  descriptionMessageId: PropTypes.string,
  additionalServerValidators: PropTypes.array,
};

EmailServerFormCard.defaultProps = {
  model: null,
  descriptionMessageId: 'web.settings.emailServer.formDescription',
  additionalServerValidators: [],
};

export default injectIntl(EmailServerFormCard);
