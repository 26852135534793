import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import I18N from 'languages';
import Switch from 'components/buttons/switch';
import Radio from 'components/buttons/radio';
import Icon from 'components/buttons/icon';
import {
  SALES_PHONE_CALL_RECORDING,
  SALES_PHONE_FORCE_CALL_RECORDING,
  SALES_PHONE_CALL_RECORDING_NOTICE,
} from 'web/settings/adminSettings/general/libs/generalPageConstants';
import { connect } from 'react-redux';
import { getRecordingNotice } from 'web/modals/manageRecordingNotice/actionCreators/recordingNoticeActionCreators';
import ManageRecordingPlayer from 'web/manageRecordingNotice/components/player';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import {
  isCallRecordingChecked,
  isCallRecordingNoticeChecked,
  isForceCallRecordingChecked,
} from 'web/settings/adminSettings/general/selectors/generalPageSelectors';
import { showSettingsAlert } from 'web/settings/adminSettings/dialer/actionCreators/dialerSettingsActionCreators';
import { DialerSettingsAlertIds } from 'web/settings/adminSettings/dialer/libs/dialerConstants';
import './salesEngagePhone.scss';

class SalesEngagePhone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordingsRemoved: [],
    };

    const {
      actionCreators: { getActiveRecordingNotice },
      intl: { formatMessage },
      isSalesPhoneConsentAndRecordingEnabled,
    } = props;

    this.phoneSwitchLabel = isSalesPhoneConsentAndRecordingEnabled
      ? formatMessage({
          id:
            'web.settings.adminSettings.general.salesEngagePhoneCard.allowRecordCalls',
        })
      : formatMessage({
          id:
            'web.settings.adminSettings.general.salesEngagePhoneCard.allowRecordCallsOld',
        });
    if (isSalesPhoneConsentAndRecordingEnabled) {
      getActiveRecordingNotice();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isCallRecordingNoticeChecked,
      actionCreators: { getActiveRecordingNotice },
    } = this.props;
    if (
      prevProps.isCallRecordingNoticeChecked !== isCallRecordingNoticeChecked
    ) {
      getActiveRecordingNotice();
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      getRecordingNotice: () => dispatch(getRecordingNotice()),
    };
  }

  componentDidMount() {
    this.actionCreators.getRecordingNotice();
  }

  onPhoneSettingsUpdate = async (isChecked, name) => {
    const {
      isSalesPhoneConsentAndRecordingEnabled,
      actionCreators: { updatePhoneSettings },
    } = this.props;
    const params = { [name]: isChecked };
    let payload = {};
    if (name === SALES_PHONE_CALL_RECORDING) {
      payload = {
        [SALES_PHONE_FORCE_CALL_RECORDING]: false,
        ...params,
      };
      await this.autoUpdateConsentMessageSettings(isChecked);
    } else {
      payload = params;
    }
    updatePhoneSettings(payload, isSalesPhoneConsentAndRecordingEnabled);
  };

  onConsentMessageSettingsUpdate = (isChecked) => {
    const {
      isCallRecordingChecked,
      isForceCallRecordingChecked,
      isSalesPhoneConsentAndRecordingEnabled,
      actionCreators: { updatePhoneSettings },
      dispatch,
    } = this.props;
    const params = {
      [SALES_PHONE_CALL_RECORDING_NOTICE]: isChecked,
    };
    const isCheckedCallRecording =
      isCallRecordingChecked || isForceCallRecordingChecked;

    if (!isCheckedCallRecording) {
      dispatch(showSettingsAlert(DialerSettingsAlertIds.enableCallRecording));
    } else {
      updatePhoneSettings(params, isSalesPhoneConsentAndRecordingEnabled);
    }
  };

  autoUpdateConsentMessageSettings = (isCallRecordingEnabled) => {
    const {
      isCallRecordingNoticeChecked,
      isSalesPhoneConsentAndRecordingEnabled,
      actionCreators: { updatePhoneSettings },
    } = this.props;
    // double check isCallRecordingNoticeChecked to avoid extra request to backend
    if (!isCallRecordingEnabled && isCallRecordingNoticeChecked) {
      // turn off call recording notice if call recording was disabled
      return updatePhoneSettings(
        { [SALES_PHONE_CALL_RECORDING_NOTICE]: false },
        isSalesPhoneConsentAndRecordingEnabled
      );
    } else if (isCallRecordingEnabled && !isCallRecordingNoticeChecked) {
      // else turn on call recording notice
      return updatePhoneSettings(
        { [SALES_PHONE_CALL_RECORDING_NOTICE]: true },
        isSalesPhoneConsentAndRecordingEnabled
      );
    }
  };

  openManageRecordingNotice(event) {
    event.preventDefault();

    const {
      actionCreators: { openManageRecordingNotice },
    } = this.props;
    openManageRecordingNotice();
  }

  getManageRecordingNotice = () => (
    <div className="call-recording-manage right">
      <a
        className="isEnableCallRecordingNotice"
        href="#"
        onClick={(event) => this.openManageRecordingNotice(event)}
      >
        <FormattedMessage id="web.settings.adminSettings.general.salesEngagePhoneCard.manageRecordingNotice" />
      </a>
    </div>
  );

  getActiveFileNameLabel = () => {
    const { activeRecordingNotice } = this.props;
    return (
      <div className="flex">
        <ManageRecordingPlayer
          playingAudio={activeRecordingNotice.audioData.resources.page.original}
        />
        <span className="active-recording-notice">
          {`${activeRecordingNotice.file_attachment.file_file_name}`}
          <FormattedMessage id="web.settings.adminSettings.general.salesEngagePhoneCard.selectedLabel" />
        </span>
        {this.getManageRecordingNotice()}
      </div>
    );
  };

  getDefaultFileNameLabel = () => (
    <div className="default-message-block">
      <span className="active-recording-notice">
        <FormattedMessage id="web.settings.adminSettings.general.salesEngagePhoneCard.defaultSelected" />
        <FormattedMessage id="web.settings.adminSettings.general.salesEngagePhoneCard.selectedLabel" />
      </span>
      <HoverTooltip
        getContent={() =>
          I18N.getStr(
            'web.settings.adminSettings.general.salesEngagePhoneCard.defaultSelectedHint'
          )
        }
        place={HoverTooltipPlace.bottom}
      >
        <Icon classes="tout-icon-warning" />
      </HoverTooltip>
      {this.getManageRecordingNotice()}
    </div>
  );

  getSelectedFileNameLabel() {
    const { activeRecordingNotice, isCallRecordingNoticeChecked } = this.props;
    if (activeRecordingNotice) {
      return this.getActiveFileNameLabel();
    }
    if (isCallRecordingNoticeChecked) {
      return this.getDefaultFileNameLabel();
    }
    return (
      <FormattedMessage id="web.settings.adminSettings.general.salesEngagePhoneCard.noneSelected" />
    );
  }

  // when switch is off - both flags zero, radio buttons disabled
  // switch enabled - call_recording_enabled = 1, force_call_recording = 0
  render() {
    const {
      intl: { formatMessage },
      isCallRecordingChecked,
      isCallRecordingNoticeChecked,
      isForceCallRecordingChecked,
      isSalesPhoneConsentAndRecordingEnabled,
    } = this.props;

    const isRadioButtonsDisabled =
      !isCallRecordingChecked && isSalesPhoneConsentAndRecordingEnabled;

    return (
      <div>
        <h3>
          <FormattedMessage id="web.settings.adminSettings.general.salesEngagePhoneCard.title" />
        </h3>
        <div className="margin-top-20">
          <Switch
            checked={isCallRecordingChecked}
            classes="padding-bottom-15"
            label={this.phoneSwitchLabel}
            name="call_recording_enabled"
            onChange={(isChecked) =>
              this.onPhoneSettingsUpdate(isChecked, SALES_PHONE_CALL_RECORDING)
            }
            rightLabel
          />
        </div>
        {isSalesPhoneConsentAndRecordingEnabled ? (
          <div className="call-recording-options">
            <Radio
              checked={!isForceCallRecordingChecked}
              disabled={isRadioButtonsDisabled}
              label={formatMessage({
                id:
                  'web.settings.adminSettings.general.salesEngagePhoneCard.allowRecordCallsOptional',
              })}
              name="call-recording-selection"
              onChange={() =>
                this.onPhoneSettingsUpdate(
                  false,
                  SALES_PHONE_FORCE_CALL_RECORDING
                )
              }
            />
            <Radio
              checked={isForceCallRecordingChecked}
              disabled={isRadioButtonsDisabled}
              label={formatMessage({
                id:
                  'web.settings.adminSettings.general.salesEngagePhoneCard.allowRecordCallsRequired',
              })}
              name="call-recording-selection"
              onChange={() =>
                this.onPhoneSettingsUpdate(
                  true,
                  SALES_PHONE_FORCE_CALL_RECORDING
                )
              }
            />
          </div>
        ) : null}
        {isSalesPhoneConsentAndRecordingEnabled ? (
          <div>
            <div className="margin-top-20">
              <Switch
                checked={isCallRecordingNoticeChecked}
                classes="padding-bottom-15"
                label={formatMessage({
                  id:
                    'web.settings.adminSettings.general.salesEngagePhoneCard.enableCallRecordingNotice',
                })}
                name="call_recording_enabled_notice"
                onChange={() =>
                  this.onConsentMessageSettingsUpdate(
                    !isCallRecordingNoticeChecked
                  )
                }
                rightLabel
              />
            </div>
            <div className="call-recording-options">
              {this.getSelectedFileNameLabel()}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

SalesEngagePhone.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  activeRecordingNotice: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isCallRecordingChecked: PropTypes.bool,
  isCallRecordingNoticeChecked: PropTypes.bool,
  isForceCallRecordingChecked: PropTypes.bool,
  isSalesPhoneConsentAndRecordingEnabled: PropTypes.bool.isRequired,
};

SalesEngagePhone.defaultProps = {
  activeRecordingNotice: null,
  isCallRecordingChecked: false,
  isCallRecordingNoticeChecked: false,
  isForceCallRecordingChecked: false,
};

const mapStateToProps = (state) => ({
  activeRecordingNotice: state.activeRecordingNotice,
  deleteRecordingNotice: state.deleteRecordingNotice,
  isCallRecordingChecked: isCallRecordingChecked(state),
  isCallRecordingNoticeChecked: isCallRecordingNoticeChecked(state),
  isForceCallRecordingChecked: isForceCallRecordingChecked(state),
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(SalesEngagePhone);
