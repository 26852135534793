import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  openPersonDetails,
  openUnsubscribePersonDetails,
  openGroupPersonDetails,
} from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import { onTabSelect } from 'web/person/actionCreators/personDetailsActionCreators';
import PeopleTable from '../components/peopleTable/peopleTable';
import UnsubscribedPeopleTable from '../components/unsubscribedPeopleTable/unsubscribedPeopleTable';
import { peopleSmartFilterChange } from 'web/people/actionCreators/peopleSmartFilterActionCreators';
import {
  getGroup,
  getPeopleGridData,
  isCurrentGroupUnsubscribed,
} from 'web/people/selectors/peopleSelectors';
import {
  getAllPeopleIds,
  sortPeople,
  paginatePeople,
  paginatePeoplePerPage,
} from 'web/people/actionCreators/peopleFetchActionCreators';
import {
  peopleResetSearch,
  peopleSearch,
} from 'web/people/actionCreators/peopleSearchActionCreators';
import {
  openAddGroupToCampaign,
  openDeletePeoplePopup,
  openSourcePeoplePopup,
  openAddPeopleToGroup,
  openUnsubscribePeoplePopup,
  openDeleteFromCampaignPeoplePopup,
  openAuthorizationPeoplePopup,
  openImportPeopleStepOne,
  openPersonGroupsModal,
  openRemoveFromGroupPeoplePopup,
} from 'web/people/actionCreators/peoplePopupActionCreators';
import { changeColumnsSettings } from 'web/user/actionCreators/userActionCreators';
import { openCampaign } from 'web/tables/tasks/actionCreators/tablesTasksActionActionCreators';
import { emailSelectedPeople } from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import { getUserColumnSettingsForTable } from 'web/user/selectors/userSelectors';
import { COLUMN_SETTINGS_PEOPLE_TABLE_ID } from 'web/people/libs/peopleConstants';

class PeopleTableContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;
    this.actionCreators = {
      ...bindActionCreators(
        {
          emailSelectedPeople,
          getAllPeopleIds,
          onTabSelect,
          openAddGroupToCampaign,
          openAddPeopleToGroup,
          openAuthorizationPeoplePopup,
          openCampaign,
          openDeleteFromCampaignPeoplePopup,
          openDeletePeoplePopup,
          openGroupPersonDetails,
          openImportPeopleStepOne,
          openPersonDetails,
          openPersonGroupsModal,
          openRemoveFromGroupPeoplePopup,
          openSourcePeoplePopup,
          openUnsubscribePeoplePopup,
          openUnsubscribePersonDetails,
          paginatePeople,
          paginatePeoplePerPage,
          peopleResetSearch,
          peopleSearch,
          peopleSmartFilterChange,
          sortPeople,
        },
        dispatch
      ),
      changeColumnsSettings: (columnsSettings) =>
        dispatch(
          changeColumnsSettings(
            COLUMN_SETTINGS_PEOPLE_TABLE_ID,
            columnsSettings
          )
        ),
    };
  }

  render() {
    const { isCurrentGroupUnsubscribedValue } = this.props;

    return isCurrentGroupUnsubscribedValue ? (
      <UnsubscribedPeopleTable
        actionCreators={this.actionCreators}
        {...this.props}
      />
    ) : (
      <PeopleTable actionCreators={this.actionCreators} {...this.props} />
    );
  }
}

PeopleTableContainer.propTypes = {
  account: PropTypes.object.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  isCurrentGroupUnsubscribedValue: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  people: PropTypes.array.isRequired,
  peopleSearchString: PropTypes.string.isRequired,
  peopleSmartFilterValue: PropTypes.string.isRequired,
  selectAllRequestParams: PropTypes.object,
  totalCount: PropTypes.number.isRequired,
};

PeopleTableContainer.defaultProps = {
  columnSettings: [],
  selectAllRequestParams: undefined,
};

const mapStateToProps = (state) => ({
  account: state.user,
  columnSettings: getUserColumnSettingsForTable(
    state,
    COLUMN_SETTINGS_PEOPLE_TABLE_ID
  ),
  currentGroupId: state.peopleCurrentGroupId,
  currentUserGroup: getGroup(state.peopleCurrentGroupId)(state),
  isCurrentGroupUnsubscribedValue: isCurrentGroupUnsubscribed(state),
  loading: state.peopleLoading,
  page: state.peoplePage,
  people: getPeopleGridData(state),
  peopleSearchString: state.peopleSearchString,
  peopleSmartFilterValue: state.peopleSmartFilterValue,
  perPage: state.peoplePerPage,
  selectAllRequestParams: state.peopleSelectAllRequestParams,
  totalCount: state.peopleTotal,
});

export default connect(mapStateToProps)(PeopleTableContainer);
