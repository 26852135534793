import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { setSmartFilter } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import {
  MAX_AUTOCOMPLETE_RESULTS,
  PERSON_FILTER_ID,
} from 'web/commandCenter/modules/whoSmartFilter/libs/whoSmartFilterConstants';
import { getAutocompleteContacts } from 'web/people/peopleSearchAdd/actionCreators/autocompleteActionCreators';
import { getSelectedPersonFilter } from 'web/commandCenter/modules/whoSmartFilter/selectors/peopleFilterSelectors';

export function onPeopleAutocompleteChange(value = '') {
  return (dispatch) => {
    dispatch({ data: value, type: ActionTypes.autocomplete.state.value });
    dispatch(
      setSmartFilter({
        collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
        filterId: PERSON_FILTER_ID,
        payload: { person: null },
      })
    );
    dispatch(getAutocompleteContacts(value, MAX_AUTOCOMPLETE_RESULTS));
  };
}

export function onPersonSelect(person) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.autocomplete.data.clear });
    const data =
      person.firstName && person.lastName
        ? `${person.firstName} ${person.lastName}`
        : person.address;
    dispatch({
      data,
      type: ActionTypes.autocomplete.state.value,
    });
    dispatch(
      setSmartFilter({
        collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
        filterId: PERSON_FILTER_ID,
        payload: { person },
      })
    );
  };
}

export function onAutocompleteLeaving() {
  return (dispatch, getState) => {
    const state = getState();
    const person = getSelectedPersonFilter(state, {
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
    });
    if (!Object.keys(person).length) {
      dispatch({
        data: '',
        type: ActionTypes.autocomplete.state.value,
      });
    }
    dispatch({ type: ActionTypes.autocomplete.data.clear });
  };
}
