import ContactIntegrationsActionTypes from '../libs/contactIntegrationsActionTypes';
import { combineReducers } from 'redux';

export const items = (state = [], { type = '', integrations = [] }) => {
  switch (type) {
    case ContactIntegrationsActionTypes.set:
      return [...integrations];
    default:
      return state;
  }
};

export const fetching = (state = false, action) => {
  switch (action.type) {
    case ContactIntegrationsActionTypes.setFetching:
      return action.fetching;
    default:
      return state;
  }
};

export const fetched = (state = false, action) => {
  switch (action.type) {
    case ContactIntegrationsActionTypes.setFetched:
      return action.fetched;
    default:
      return state;
  }
};

export default combineReducers({ items, fetching, fetched });
