import { createAction } from '@reduxjs/toolkit';
import { SubmissionError } from 'redux-form';
import { requestLoginMethod } from 'web/login/services/loginService';
import {
  convertErrorsForReduxForm,
  defaultErrorMessage,
} from 'web/shared/helpers/parser';

export const setLoginStep = createAction('SET_LOGIN_STEP');
export const setSFSandboxAuthenticationEnabled = createAction(
  'SET_SF_SANDBOX_AUTHENTICATION_ENABLED'
);
export const setLoginMethodsLoading = createAction('SET_LOGIN_METHODS_LOADING');
export const setUnauthorizedHeaderKey = createAction(
  'SET_UNAUTHORIZED_HEADER_KEY'
);
export const setLoginFormUserName = createAction('SET_LOGIN_FORM_USER_NAME');
export const showMsiActionLoginError = createAction(
  'SHOW_MSI_ACTION_LOGIN_ERROR'
);
export const hideMsiActionLoginError = createAction(
  'HIDE_MSI_ACTION_LOGIN_ERROR'
);

export const setLoginMethods = (email) => (dispatch) => {
  dispatch(setLoginMethodsLoading(true));
  return requestLoginMethod(email)
    .then(({ login_methods, sf_sandbox_authentication_enabled }) => {
      dispatch(setLoginMethodsLoading(false));
      dispatch(setLoginFormUserName(email));
      dispatch(setLoginStep(login_methods));
      dispatch(
        setSFSandboxAuthenticationEnabled(sf_sandbox_authentication_enabled)
      );
      dispatch(hideMsiActionLoginError());
    })
    .catch((err) => {
      dispatch(setLoginMethodsLoading(false));
      let errorMessages = {};
      if (err.status === 401) {
        try {
          const responseText = JSON.parse(err.response.text);
          errorMessages = convertErrorsForReduxForm(responseText.errors);
        } catch (error) {
          errorMessages = defaultErrorMessage;
        }
      } else {
        errorMessages = defaultErrorMessage;
      }

      throw new SubmissionError(errorMessages);
    });
};
