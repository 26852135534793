import { Urls } from 'web/libs/routes';
import { isAdmin } from 'web/user/selectors/userSelectors';
import { addAlert } from 'web/globalBannerAlerts/actionCreators/globalBannerAlertsActionCreators';
import GlobalBannerAlertsConstants from 'web/globalBannerAlerts/libs/globalBannerAlertsConstants';
import { updateUserSettings } from 'web/user/actionCreators/userActionCreators';

export * from './apiCallsAC';
export * from './integrationAC';
export * from './accountAC';
export * from './popupsAC';
export * from './linksAC';

export const displayBrokenSfdcConnectionBanner = () => (dispatch, getState) => {
  dispatch(
    addAlert({
      id: GlobalBannerAlertsConstants.salesforce.brokenConnection,
      textValues: {
        url: isAdmin(getState())
          ? Urls.salesforceAdmin().replace(/\/$/, '')
          : Urls.salesforceAccount().replace(/\/$/, ''),
      },
    })
  );
  dispatch(
    updateUserSettings({ brokenSfdcConnectionBannerDisplayedAt: new Date() })
  );
};
