import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl, ModalPopupIds } from 'web/libs/constants';
import { DynamicFieldsOverlayIds } from '../libs/dynamicFieldsModalConstants';
import Popup, { PopupBackgroundColors } from 'components/popups';
import DynamicFields, {
  DYNAMIC_FIELDS_POPUP_WIDTH,
} from 'web/dynamicFields/components/dynamicFields';
import { dynamicFieldsStartup } from '../actionCreators/dynamicFieldsModalStartupActionCreators';
import { getDynamicFields } from 'web/dynamicFields/actionCreators/dynamicFieldsActionCreators';
import {
  closeDynamicFields,
  selectedDynamicFields,
} from '../actionCreators/dynamicFieldsModalActionCreators';
import { getFilteredDynamicFields } from '../selectors/dynamicFieldsModalSelectors';

class DynamicFieldsContainer extends Component {
  componentWillMount() {
    const { dispatch, onClose, onSelected } = this.props;
    dispatch(dynamicFieldsStartup());

    this.actionCreators = {
      closeModal: () => dispatch(closeDynamicFields(onClose)),
      dynamicFieldsSelected: (field) =>
        dispatch(selectedDynamicFields(field, onSelected)),
      retryGet: () => dispatch(getDynamicFields(true)),
    };
  }

  render() {
    const { loading, modal } = this.props;

    return (
      modal.id === ModalPopupIds.dynamicFields && (
        <Popup
          getImageUrl={getImageUrl}
          loading={loading}
          {...this._getGlobalPopupObject()}
          {...this._getOverlay()}
        />
      )
    );
  }

  _getGlobalPopupObject = () => {
    const { dynamicFields, headerTextId } = this.props;
    return {
      children: (
        <DynamicFields
          fields={dynamicFields}
          onSelected={this.actionCreators.dynamicFieldsSelected}
        />
      ),
      header: {
        onClose: this.actionCreators.closeModal,
        textId: headerTextId,
      },
      onOutsideClick: this.actionCreators.closeModal,
      size: DYNAMIC_FIELDS_POPUP_WIDTH,
    };
  };

  _getOverlay = () => {
    const { overlay } = this.props;

    switch (overlay) {
      case DynamicFieldsOverlayIds.getFailure:
        return {
          overlay: {
            color: PopupBackgroundColors.red,
            footer: {
              primaryTextId: 'common.retry',
              onPrimary: this.actionCreators.retryGet,
              secondaryTextId: 'common.cancel',
              onSecondary: this.actionCreators.closeModal,
            },
            header: {
              onClose: this.actionCreators.closeModal,
            },
            textId: 'web.dynamicFields.getFailure',
          },
          onOutsideClick: this.actionCreators.closeModal,
        };
      default:
        return null;
    }
  };
}

DynamicFieldsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dynamicFields: PropTypes.array.isRequired,
  excludeFields: PropTypes.array, // passed in as option
  headerTextId: PropTypes.string, // passed in as option
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  modal: PropTypes.object.isRequired,
  onClose: PropTypes.func, // passed in as option
  onSelected: PropTypes.func, // passed in as option
  overlay: PropTypes.string.isRequired,
};

DynamicFieldsContainer.defaultProps = {
  excludeFields: [],
  headerTextId: 'web.dynamicFields.title',
};

function mapStateToProps(state, ownProps) {
  return {
    dynamicFields: getFilteredDynamicFields(state, ownProps),
    loading: state.popupLoading,
    modal: state.modal,
    overlay: state.dynamicFieldsModalOverlay,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(DynamicFieldsContainer);
