import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Part from './part';
import Logo from 'components/logo';
import './navBar.scss';

const CLASS_CONTAINER = classnames(['tout-ui-styles', 'top-navbar-container']);

export const NavBar = (props) => {
  const { children, showLogo } = props;

  return (
    <nav className={CLASS_CONTAINER}>
      {showLogo && <Logo className="top-navbar-logo" />}
      <div className="top-navbar-name">
        <FormattedMessage id="common.marketoSales" />
      </div>
      {children}
    </nav>
  );
};

NavBar.propTypes = {
  showLogo: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.instanceOf(Part),
  ]),
};

NavBar.defaultProps = {
  showLogo: false,
  children: null,
};

export default NavBar;
