import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import './actionButton.scss';

const ActionButton = ({ onClick, textId, disabled }) =>
  onClick && textId ? (
    <Button
      color={ButtonColors.blue}
      onClick={onClick}
      disabled={disabled}
      classes="sidebar-action-button"
    >
      {textId}
    </Button>
  ) : null;

ActionButton.propTypes = {
  onClick: PropTypes.func,
  textId: PropTypes.string,
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  onClick: undefined,
  textId: '',
  disabled: null,
};

ActionButton.defaultProps = {
  disabled: false,
};

export default ActionButton;
