export default {
  addIdentity: 'USER_ADD_IDENTITY',
  removeIdentity: 'USER_REMOVE_IDENTITY',
  setAdminEmailInvitation: 'ADMIN_SET_EMAIL_INVITATION',
  setAdminSettingsFetching: 'USER_SET_ADMIN_SETTINGS_FETCHING',
  setDefaultIdentity: 'USER_SET_DEFAULT_IDENTITY',
  setIdentities: 'USER_SET_IDENTITIES',
  setIdentitiesFetched: 'USER_SET_IDENTITIES_FETCHED',
  setIdentitiesFetching: 'USER_SET_IDENTITIES_FETCHING',
  setTimezone: 'USER_SET_TIMEZONE',
  setUser: 'USER_SET_USER',
  setUserMetric: 'USER_SET_USER_METRIC',
  setUserSettings: 'USER_SET_SETTINGS',
  setUserSubscription: 'USER_SET_USER_SUBSCRIPTION',
  setUserSubscriptionFetching: 'USER_SET_USER_SUBSCRIPTION_FETCHING',
  updateIdentity: 'USER_UPDATE_IDENTITY',
};
