import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import DetailListItem from '../detailListItem/detailListItem';
import ZeroState from 'table/modules/zeroState';
import { CampaignState, getImageUrl } from 'web/libs/constants';
import './detailListView.scss';

class DetailListView extends Component {
  render() {
    const {
      account,
      currentCategory,
      listData,
      intl: { formatMessage },
    } = this.props;
    return (
      <div
        className={classNames('detail-list-view', {
          'tout-table': !listData.length,
        })}
      >
        {listData.length ? (
          listData.map((elem) => (
            <DetailListItem
              name={elem.name}
              listIterable={elem.metrics}
              status={this._getStatus(elem.activeCount)}
              account={account}
              shared={elem.shared}
              unshared={elem.unshared}
              user={elem.user}
              createdAt={elem.createdAt}
              link={elem.link}
              key={`detail-list-item-${elem.name}-${elem.id}`}
            />
          ))
        ) : (
          <ZeroState
            bodyText={formatMessage({
              id:
                currentCategory < 0
                  ? 'web.campaigns.zeroState.noCampaignsAllBody'
                  : 'web.campaigns.zeroState.noCampaignsCategoryBody',
            })}
            imageUrl={getImageUrl('rocket-ship', 'svg')}
            imageWidth={450}
            titleText={formatMessage({
              id:
                currentCategory < 0
                  ? 'web.campaigns.zeroState.noCampaignsAllTitle'
                  : 'web.campaigns.zeroState.noCampaignsCategoryTitle',
            })}
          />
        )}
      </div>
    );
  }

  _getStatus = (activeCount) =>
    activeCount ? CampaignState.inProgress : CampaignState.unstarted;
}

DetailListView.propTypes = {
  account: PropTypes.object.isRequired,
  currentCategory: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  listData: PropTypes.array.isRequired,
};

export default injectIntl(DetailListView);
