import SalesforceActionTypes from '../libs/salesforceActionTypes';
import { SalesforceTabs } from '../libs/salesforceConstants';

export const activeTab = (
  state = SalesforceTabs.connectionsCustomizations,
  { type, tab }
) => {
  switch (type) {
    case SalesforceActionTypes.setTab:
      return tab;
    default:
      return state;
  }
};

export const activityDetailsCustomization = (
  state = '',
  { type, activityDetailsCustomization }
) => {
  switch (type) {
    case SalesforceActionTypes.setActivityDetailsCustomization:
      return activityDetailsCustomization;
    default:
      return state;
  }
};
