import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tasks from 'web/task/components/tasks/tasks';
import {
  todoFilteredSortedTasks,
  overdueFilteredSortedTasks,
} from 'web/liveFeed/tasks/selectors/tasksSelectors';
import {
  openAddTask,
  openEditTask,
  openEmail,
  taskTypeAction,
  openPdv,
  changeOverdueSelectedDateFilter,
  changeTodoSelectedDateFilter,
  getTasksUncompleted,
} from 'web/liveFeed/tasks/actionCreators/tasksActionCreators';
import {
  taskDone,
  taskDelete,
} from 'web/tasks/actionCreators/tasksActionCreators';

class TasksContainer extends Component {
  constructor(props) {
    super(props);

    this.actionCreators = {
      ...bindActionCreators(
        {
          getTasksUncompleted,
          taskDone,
          taskDelete,
          changeOverdueSelectedDateFilter,
          changeTodoSelectedDateFilter,
        },
        this.props.dispatch
      ),
      openAddTask,
      openEditTask,
      openEmail,
      taskTypeAction,
      openPdv,
    };
  }

  componentWillMount() {
    this.actionCreators.getTasksUncompleted();
  }

  render() {
    return (
      <Tasks actionCreators={this.actionCreators} overdue {...this.props} />
    );
  }
}

TasksContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selectedOverdueDateFilter: PropTypes.string.isRequired,
  selectedTodoDateFilter: PropTypes.string.isRequired,
  todoFilteredSortedTasks: PropTypes.array.isRequired,
  overdueFilteredSortedTasks: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  doneFetchingTasks: state.doneFetchingTasks,
  selectedOverdueDateFilter: state.selectedOverdueDateFilter,
  selectedTodoDateFilter: state.selectedTodoDateFilter,
  todoFilteredSortedTasks: todoFilteredSortedTasks(state),
  overdueFilteredSortedTasks: overdueFilteredSortedTasks(state),
});

export default connect(mapStateToProps)(TasksContainer);
