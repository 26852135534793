import React from 'react';
import { Field, Form } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import Label from 'components/label';
import { LABEL_WIDTH } from '../libs/accountDetailsConstants';
import LabeledInputReduxForm from 'components/labeledInputReduxForm';
import './accountDetailsContainer.scss';
import {
  accountDetailsPropTypes,
  accountDetailsDefaultProps,
} from './accountDetailsPropTypes';
import Text from 'web/form/components/fields/text';
import passwordWithTooltip from 'web/form/hoc/passwordWithTooltip';
import {
  accountDetailsPasswordValidators,
  accountDetailsConfirmPasswordValidators,
  accountDetailsFirstNameValidators,
  accountDetailsLastNameValidators,
} from '../libs/accountDetailsValidators';
import Checkbox from 'components/buttons/checkbox';
import PreventBrowserInputAutofill from 'components/inputs/preventBrowserInputAutofill/preventBrowserInputAutofill';

const POSITION_RIGHT = 'right';

const passwordFiledWithTooltip = passwordWithTooltip({
  place: POSITION_RIGHT,
})(LabeledInputReduxForm);

const AccountDetails = (props) => {
  const {
    intl: { formatMessage },
    onFormSubmit,
    pristine,
    pending,
    showPasswords,
    setShowPasswords,
    resetForm,
    invalid,
  } = props;

  const changePasswordLabel = formatMessage({ id: 'common.changePassword' });

  const disableSaveBtn = pristine || pending || invalid;

  return (
    <Form onSubmit={onFormSubmit}>
      <PreventBrowserInputAutofill />
      <Label
        classChildren="account-details-name"
        className="account-details-name-label"
        id="account-details-name"
        messageKey="common.name"
        width={LABEL_WIDTH}
        sideLabel
      >
        <Field
          autoFocus={false}
          className="form-cell"
          component={LabeledInputReduxForm}
          id="account-details-firstName"
          name="first_name"
          type="text"
          validate={accountDetailsFirstNameValidators}
        />
        <Field
          autoFocus={false}
          className="form-cell account-details-lastName"
          component={LabeledInputReduxForm}
          id="account-details-lastName"
          name="last_name"
          type="text"
          validate={accountDetailsLastNameValidators}
        />
      </Label>
      <Label
        className="account-details-name-label"
        classWrapper="margin-top-most"
        id="account-details-email"
        messageKey="common.email"
        width={LABEL_WIDTH}
        sideLabel
      >
        <Field
          autoFocus={false}
          className="form-cell"
          component={Text}
          id="accountDetails-email"
          name="email"
          type="text"
          disabled
        />
      </Label>
      <Checkbox
        checked={showPasswords}
        className="account-details-change-password"
        label={changePasswordLabel}
        name="show-passwords"
        onChange={() => setShowPasswords(!showPasswords)}
      />
      {showPasswords ? (
        <React.Fragment>
          {/*
            All browsers in password field inserts autocomplete dropdown
            with passwords suggestions that user previously saved to browser,
            and when user choose one of them, browser pasts not only password but email too,
            it can change the data that user already typed.
            This behaviour can't be changed, but we can prevent
            pasting with password an email to other fields, to fix this we need to insert
            before password field some hidden text field.
            For more information visit
https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
          */}
          <PreventBrowserInputAutofill />
          <Label
            className="account-details-name-label"
            classWrapper="margin-top-most"
            id="account-details-current-password"
            messageKey="common.currentPassword"
            width={LABEL_WIDTH}
            sideLabel
          >
            <Field
              autoFocus={false}
              className="form-cell"
              component={Text}
              id="account-details-currentPassword"
              name="current_password"
              type="password"
            />
          </Label>
          <Label
            className="account-details-name-label"
            classWrapper="margin-top-most"
            id="account-details-new-password"
            messageKey="common.newPassword"
            width={LABEL_WIDTH}
            sideLabel
          >
            <Field
              autoFocus={false}
              className="form-cell"
              component={passwordFiledWithTooltip}
              id="account-details-newPassword"
              name="password"
              type="password"
              validate={accountDetailsPasswordValidators}
            />
          </Label>
          <Label
            className="account-details-name-label"
            classWrapper="margin-top-most"
            id="account-details-confirm-password"
            messageKey="common.retypeNewPassword"
            width={LABEL_WIDTH}
            sideLabel
          >
            <Field
              autoFocus={false}
              className="form-cell"
              component={LabeledInputReduxForm}
              id="account-details-confirmPassword"
              name="password_confirmation"
              type="password"
              validate={accountDetailsConfirmPasswordValidators}
            />
          </Label>
        </React.Fragment>
      ) : null}
      <div className="account-details-form-actions">
        {!pristine || showPasswords ? (
          <Button
            className="flex-right"
            color={ButtonColors.gray}
            disabled={pending}
            onClick={resetForm}
            size={ButtonSizes.small}
            type="button"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
        ) : null}
        <Button
          className="flex-right"
          color={ButtonColors.blue}
          disabled={disableSaveBtn}
          size={ButtonSizes.small}
          type="submit"
        >
          <FormattedMessage id="common.save" />
        </Button>
      </div>
    </Form>
  );
};

AccountDetails.propTypes = accountDetailsPropTypes;
AccountDetails.defaultProps = accountDetailsDefaultProps;

export default AccountDetails;
