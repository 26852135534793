import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { closeModal } from './dynamicFieldsModalPopupActionCreators';

export const closeDynamicFields = (onClose) => (dispatch) => {
  if (onClose) {
    onClose();
  } else {
    toutBackboneHelper.dynamicFieldsSelected('');
  }

  dispatch(closeModal());
};

export const selectedDynamicFields = (field, onSelected) => (dispatch) => {
  if (onSelected) {
    onSelected(field);
  } else {
    toutBackboneHelper.dynamicFieldsSelected(field);
  }

  dispatch(closeModal());
};
