import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chevron, {
  ChevronColors,
  ChevronDirections,
} from 'components/buttons/chevron';

const AccordionItemHeader = ({
  children,
  onToggle,
  isOpen,
  className,
  disabledChevron,
}) => {
  const classes = classNames('tout-accordion-header', className);

  return (
    <div className={classes}>
      {children}
      <Chevron
        className="accordion-chevron"
        color={ChevronColors.blue}
        direction={isOpen ? ChevronDirections.bottom : ChevronDirections.right}
        onClick={onToggle}
        disabled={disabledChevron}
      />
    </div>
  );
};

AccordionItemHeader.defaultProps = {
  className: '',
  disabledChevron: false,
  onToggle: () => {},
  isOpen: false,
};

AccordionItemHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabledChevron: PropTypes.bool,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default AccordionItemHeader;
