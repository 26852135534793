import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  findDeliveryChannelByTeamAndProviderSmtp,
  findDeliveryChannelByProviderGoogle,
  findDeliveryChannelByProviderOutlook,
} from 'web/deliveryChannels/selectors/deliveryChannelsSelectors';
import { getDeliveryChannels as getDeliveryChannelsAC } from 'web/deliveryChannels/actionCreators/deliveryChannelsActionCreators';
import { getIdentities as getIdentitiesAC } from 'web/user/actionCreators/identitiesActionCreators';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import EmailServerAlertContainer from 'web/settings/emailServer/containers/emailServerAlertContainer';
import SmtpServerAlert from '../components/smtpServerAlert';

class SmtpServerAlertContainer extends Component {
  componentDidMount() {
    const {
      deliveryChannelsFetched,
      deliveryChannelsFetching,
      getDeliveryChannels,
      identitiesFetched,
      identitiesFetching,
      getIdentities,
    } = this.props;

    if (!deliveryChannelsFetched && !deliveryChannelsFetching) {
      getDeliveryChannels();
    }

    if (!identitiesFetched && !identitiesFetching) {
      getIdentities();
    }
  }

  render() {
    return [
      <EmailServerAlertContainer key="EmailServerAlertContainer" />,
      <SmtpServerAlert {...this.props} key="SmtpServerAlert" />,
    ];
  }
}

SmtpServerAlertContainer.propTypes = {
  alert: PropTypes.object.isRequired,
  deliveryChannelsFetched: PropTypes.bool.isRequired,
  deliveryChannelsFetching: PropTypes.bool.isRequired,
  isTeamSmtpDeliveryChannelExists: PropTypes.bool.isRequired,
  identitiesFetched: PropTypes.bool.isRequired,
  identitiesFetching: PropTypes.bool.isRequired,
  identities: PropTypes.array.isRequired,
  isGmailDeliveryChannelExists: PropTypes.bool.isRequired,
  isOutlookDeliveryChannelExists: PropTypes.bool.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
  getDeliveryChannels: PropTypes.func.isRequired,
  getIdentities: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.viewAlert,
  deliveryChannelsFetched: state.deliveryChannelsFetched,
  deliveryChannelsFetching: state.deliveryChannelsFetching,
  isTeamSmtpDeliveryChannelExists: !!findDeliveryChannelByTeamAndProviderSmtp(
    state
  ),
  identitiesFetched: state.identitiesFetched,
  identitiesFetching: state.identitiesFetching,
  identities: state.identities,
  isGmailDeliveryChannelExists: !!findDeliveryChannelByProviderGoogle(state),
  isOutlookDeliveryChannelExists: !!findDeliveryChannelByProviderOutlook(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeViewAlert,
      getDeliveryChannels: getDeliveryChannelsAC,
      getIdentities: getIdentitiesAC,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmtpServerAlertContainer);
