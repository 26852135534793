import { baseGet, basePut, basePost, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { SMTP_DELIVERY_CHANNEL } from '../libs/deliveryChannelsConstants';

export const getSubscriptionDeliveryChannel = (id = SMTP_DELIVERY_CHANNEL) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.subscriptionDeliveryChannels(id), null, resolve, reject);
  });

export const createSubscriptionDeliveryChannel = (authentication) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.subscriptionDeliveryChannels(),
      authentication,
      resolve,
      reject
    );
  });

export const updateSubscriptionDeliveryChannel = (
  authentication,
  id = SMTP_DELIVERY_CHANNEL
) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.subscriptionDeliveryChannels(id),
      authentication,
      resolve,
      reject
    );
  });

export const deleteSubscriptionDeliveryChannel = (id = SMTP_DELIVERY_CHANNEL) =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.subscriptionDeliveryChannels(id), {}, resolve, reject);
  });
