import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import {
  DEFAULT_PAGE_SIZE,
  ElasticSearchMode,
} from 'web/elasticSearch/libs/elasticSearchConstants';
import { TEMPLATE_SMART_FILTER_VALUES } from 'web/templates/libs/templatesConstants';

export const templatesTotal = (state = 0, { type, total = 0 }) => {
  switch (type) {
    case TemplatesActionTypes.templates.set:
      return total;
    default:
      return state;
  }
};

export const templatesPage = (state = 1, { type, page = 1 }) => {
  switch (type) {
    case TemplatesActionTypes.table.page:
      return page;
    default:
      return state;
  }
};

export const templatesPerPage = (
  state = DEFAULT_PAGE_SIZE,
  { type, perPage = 1 }
) => {
  switch (type) {
    case TemplatesActionTypes.table.perPage:
      return perPage;
    default:
      return state;
  }
};

export const templatesLoading = (
  state = false,
  { type, isLoading = false }
) => {
  switch (type) {
    case TemplatesActionTypes.templates.loading:
      return isLoading;
    default:
      return state;
  }
};

export const initTemplatesSort = { column: '', direction: '' };
export const templatesSort = (
  state = initTemplatesSort,
  { type, column = '', direction = '' }
) => {
  switch (type) {
    case TemplatesActionTypes.table.sortInfo:
      return { column, direction };
    case TemplatesActionTypes.table.reset:
      return initTemplatesSort;
    default:
      return state;
  }
};

export const defaultFilters = {
  archived: false,
  workflow_pitch_template: false,
};
export const templatesFilter = (
  state = defaultFilters,
  { type, filters = {} }
) => {
  switch (type) {
    case TemplatesActionTypes.table.filter:
      return { ...filters, ...defaultFilters };
    case TemplatesActionTypes.table.reset:
      return defaultFilters;
    default:
      return state;
  }
};

export const templatesSmartFilter = (state = {}, { type, filters = {} }) => {
  switch (type) {
    case TemplatesActionTypes.smartFilters.set:
      return filters;
    case TemplatesActionTypes.table.reset:
      return {};
    default:
      return state;
  }
};

export const templatesTags = (state = [], { type, tags = [] }) => {
  switch (type) {
    case TemplatesActionTypes.tags.set:
      return tags;
    case TemplatesActionTypes.table.reset:
      return [];
    default:
      return state;
  }
};

export const templatesSmartFilterValue = (
  state = TEMPLATE_SMART_FILTER_VALUES.all,
  { type, label = TEMPLATE_SMART_FILTER_VALUES.all }
) => {
  switch (type) {
    case TemplatesActionTypes.smartFilters.set:
      return label;
    case TemplatesActionTypes.table.reset:
      return TEMPLATE_SMART_FILTER_VALUES.all;
    default:
      return state;
  }
};

export const templatesMode = (
  state = ElasticSearchMode.all,
  { type, mode = ElasticSearchMode.all }
) => {
  switch (type) {
    case TemplatesActionTypes.mode.set:
      return mode;
    case TemplatesActionTypes.table.reset:
      return ElasticSearchMode.all;
    default:
      return state;
  }
};

export const initViewer = {};
export const templatesViewer = (
  state = initViewer,
  { type, viewer = initViewer }
) => {
  switch (type) {
    case TemplatesActionTypes.viewer.set:
      return viewer;
    default:
      return state;
  }
};
