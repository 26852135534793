import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import TrackingPopups from '../components/popups/popups';
import { deleteCustomDomain } from '../actionCreators/trackingActionCreators';

const TrackingPopupContainer = (props) => <TrackingPopups {...props} />;

const mapStateToProps = (state) => ({
  popup: state.popup,
  popupData: state.popupData,
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      closePopup,
      deleteCustomDomain,
    },
    dispatch
  ),
});

TrackingPopupContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object,
};

TrackingPopupContainer.defaultProps = {
  popupData: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackingPopupContainer);
