import React from 'react';
import PropTypes from 'prop-types';
import Alert, { AlertColors } from 'components/alert';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';
import { AlertIds } from 'web/composeWindow/libs/composeWindowConstants';

class ComposeAlert extends React.Component {
  render() {
    return <div>{this.getAlertComponent()}</div>;
  }

  getAlertComponent = () => {
    const { onClose, alert } = this.props;

    const defaultProperties = {
      delay: 8000,
      location: 'top',
      onClose,
      outsideClickDisabled: true,
    };

    const successProperties = {
      color: AlertColors.greenDark,
      ...defaultProperties,
    };
    const warningProperties = {
      color: AlertColors.yellow,
      ...defaultProperties,
    };
    const errorProperties = {
      color: AlertColors.redDark,
      ...defaultProperties,
    };
    const infoProperties = {
      color: AlertColors.blue,
      ...defaultProperties,
    };

    switch (alert.id) {
      case AlertIds.pinnedSaveSuccess:
        return (
          <Alert
            {...successProperties}
            textId="web.composeWindow.pinnedCategories.saveSuccess"
          />
        );
      case AlertIds.pinnedSaveError:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.pinnedCategories.saveError"
          />
        );
      case AlertIds.fetchPinnedError:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.pinnedCategories.fetchError"
          />
        );
      case AlertIds.missingFieldSubject:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.addressBar.missingSubjectError"
          />
        );
      case AlertIds.missingFieldRecipient:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.addressBar.missingRecipientError"
          />
        );
      case AlertIds.missingFieldBody:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.addressBar.missingBodyError"
          />
        );
      case AlertIds.singleFlowUnsubscribes:
        return (
          <Alert
            {...warningProperties}
            textId="web.composeWindow.addressBar.unsubscribedContactError"
          />
        );
      case AlertIds.singleFlowInvalidEmail:
        return (
          <Alert
            {...warningProperties}
            textId="web.composeWindow.addressBar.invalidContactError"
          />
        );
      case AlertIds.singleFlowDomainBlock:
        return (
          <Alert
            {...warningProperties}
            textId="web.composeWindow.addressBar.domainBlockContactError"
          />
        );
      case TemplateAlertIds.fetchError:
        return <Alert {...errorProperties} textId="web.templates.fetchError" />;
      case TemplateAlertIds.getCategoriesError:
        return (
          <Alert
            {...errorProperties}
            textId="web.templates.getCategoriesError"
          />
        );
      case AlertIds.missingDynamicFields:
        return (
          <Alert
            {...warningProperties}
            textId="web.composeWindow.alert.missingDynamicFields"
          />
        );
      case AlertIds.filledTemplateFetchError:
        return (
          <Alert
            {...warningProperties}
            textId="web.composeWindow.alert.filledTemplateFetchError"
          />
        );
      case AlertIds.setRecipientsError:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.alert.setRecipientsError"
          />
        );
      case AlertIds.fetchGroupError:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.alert.fetchGroupError"
          />
        );
      case AlertIds.fetchDynamicFieldError:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.alert.fetchDynamicFieldError"
          />
        );
      case AlertIds.composeLoadError:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.alert.loadError"
          />
        );
      case AlertIds.emailLimit:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.alert.emailLimit"
            textValues={alert.textValues}
          />
        );
      case AlertIds.emailLimitSingle:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.alert.emailLimitSingle"
          />
        );
      case AlertIds.addNewPeopleDisabled:
        return <Alert {...infoProperties} textId="people.addNewDisabled" />;
      case AlertIds.templateFetchError:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.alert.templateFetchError"
          />
        );
      case AlertIds.unfilledPromptError:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.addressBar.unfilledPromptError"
          />
        );
      // For multiple recipients
      case AlertIds.unfilledPromptErrorInGroupSetting:
        return (
          <Alert
            {...errorProperties}
            textId="web.composeWindow.addressBar.unfilledPromptErrorInGroupSetting"
          />
        );
      default:
        return null;
    }
  };
}

ComposeAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

ComposeAlert.defaultProps = {
  onClose: () => {},
};

export default ComposeAlert;
