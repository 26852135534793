import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SalesforceCustomizationVersionsIds } from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';

class SalesforceCustomizationVersionItem extends PureComponent {
  render() {
    const { version } = this.props;
    const isCompleted = version[SalesforceCustomizationVersionsIds.completed];

    return (
      <li className="version-item">
        <span>{version.name}</span>
        {isCompleted ? <span className="icon success-icon" /> : null}
      </li>
    );
  }
}

SalesforceCustomizationVersionItem.propTypes = {
  version: PropTypes.object.isRequired,
};

export default SalesforceCustomizationVersionItem;
