import UserActionTypes from '../libs/userActionTypes';
import { DefaultTimezone } from 'web/timezones/libs/timezonesConstants';

export const user = (state = {}, { type = '', user: userData = {} }) => {
  switch (type) {
    case UserActionTypes.setUser:
      return userData;
    default:
      return state;
  }
};

export const isUserSubscriptionFetching = (
  state = false,
  { type = '', isFetching = false }
) => {
  switch (type) {
    case UserActionTypes.setUserSubscriptionFetching:
      return isFetching;
    default:
      return state;
  }
};

export const isAdminSettingsFetching = (
  state = false,
  { type = '', isFetching = false }
) => {
  switch (type) {
    case UserActionTypes.setAdminSettingsFetching:
      return isFetching;
    default:
      return state;
  }
};

export function userSubscriptionUsers(
  state = {},
  { type = '', user: userData = {} }
) {
  switch (type) {
    case UserActionTypes.setUser: {
      const mapUsers = {};
      if (userData.subscription && userData.subscription.users) {
        userData.subscription.users.forEach((subscriptionUser) => {
          mapUsers[subscriptionUser.id] = subscriptionUser;
        });
      }
      return mapUsers;
    }
    default:
      return state;
  }
}

export const userMetric = (
  state = {},
  { type = '', userMetric: userMetricData = {} }
) => {
  switch (type) {
    case UserActionTypes.setUserMetric:
      return userMetricData;
    default:
      return state;
  }
};

export const userTimezone = (
  state = DefaultTimezone,
  { type = '', timezone = DefaultTimezone }
) => {
  switch (type) {
    case UserActionTypes.setTimezone:
      return timezone;
    default:
      return state;
  }
};

export const userSettings = (state = {}, { type = '', userSettings = {} }) => {
  switch (type) {
    case UserActionTypes.setUserSettings:
      return userSettings;
    default:
      return state;
  }
};
