import React from 'react';
import PropTypes from 'prop-types';
import EmailBoxComponent from 'components/emailBox';

const EmailBox = ({ input, placeholder, className }) => (
  <EmailBoxComponent
    {...input}
    placeholder={placeholder}
    className={className}
  />
);

EmailBox.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

EmailBox.defaultProps = {
  placeholder: '',
  className: '',
};

export default EmailBox;
