import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import Cards, { Direction } from 'components/layouts/cards';
import CampaignsCard from '../campaignsCard';
import MarketoCampaignsCard from '../marketoCampaignsCard';
import EmailsCard from '../emailsCard';
import CallsCard from '../callsCard';

const MARKETO_CAMPAIGN_CARD_POSITION = 1; // 0 indexed

class HistoryView extends Component {
  render() {
    return (
      <div className="person-details-history-view flex--full" ref={this._shit}>
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={this._getCards()}
          direction={Direction.vertical}
          marginAround={FULL}
          marginBetween={FULL}
          maxWidth={0}
          minWidth={0}
        />
      </div>
    );
  }

  _getCards = () => {
    const {
      actionCreators,
      callRecordingEnabled,
      dispatch,
      emailsState,
      person,
      user,
    } = this.props;

    let cards = [
      {
        children: (
          <CampaignsCard actionCreators={actionCreators} person={person} />
        ),
        id: PersonDetailsCards.campaigns,
      },
      {
        children: (
          <EmailsCard
            actionCreators={actionCreators}
            user={user}
            {...emailsState}
          />
        ),
        id: PersonDetailsCards.emails,
      },
      {
        children: (
          <CallsCard
            actionCreators={actionCreators}
            callRecordingEnabled={callRecordingEnabled}
            calls={person.callsParsed}
            dispatch={dispatch}
          />
        ),
        id: PersonDetailsCards.calls,
      },
    ];

    cards = [
      ...cards.slice(0, MARKETO_CAMPAIGN_CARD_POSITION),
      {
        children: (
          <MarketoCampaignsCard
            actionCreators={actionCreators}
            person={person}
          />
        ),
        id: PersonDetailsCards.marketoCampaignsCard,
      },
      ...cards.slice(MARKETO_CAMPAIGN_CARD_POSITION),
    ];

    return cards;
  };
}

HistoryView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  callRecordingEnabled: PropTypes.bool.isRequired,
  emailsState: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  setScrollTop: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

HistoryView.defaultProps = {
  emailId: 0,
};

export default HistoryView;
