import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import CustomDomainForm from '../form/customDomainForm';
import { TRACKING_CUSTOM_DOMAIN_FORM_ID } from '../../../libs/trackingConstants';
import './customDomainCard.scss';

class CustomDomainCard extends Component {
  get customDomainFormModel() {
    return {
      custom_tracking_domain: this.props.customDomain,
    };
  }

  render() {
    const {
      actionCreators: { saveCustomDomain, openDeleteCustomDomainConfirmation },
      customDomain,
    } = this.props;
    return (
      <div className="tracking-custom-domain">
        <h3>
          <FormattedMessage id="web.settings.adminSettings.tracking.customDomainTracking.title" />
        </h3>
        <div className="margin-top-20">
          <FormattedMessage id="web.settings.adminSettings.tracking.customDomainTracking.description" />

          <ul className="description-list margin-top-10">
            <li>
              <FormattedHTMLMessage id="web.settings.adminSettings.tracking.customDomainTracking.requestAdmin" />
            </li>
            <li>
              <FormattedMessage id="web.settings.adminSettings.tracking.customDomainTracking.enterDomain" />
            </li>
          </ul>

          <CustomDomainForm
            form={TRACKING_CUSTOM_DOMAIN_FORM_ID}
            onSubmit={saveCustomDomain}
            openDeleteCustomDomainConfirmation={
              openDeleteCustomDomainConfirmation
            }
            initialValues={this.customDomainFormModel}
            customDomain={customDomain}
          />
        </div>
      </div>
    );
  }
}

CustomDomainCard.propTypes = {
  customDomain: PropTypes.string,
  actionCreators: PropTypes.object.isRequired,
};

CustomDomainCard.defaultProps = {
  customDomain: null,
};

export default CustomDomainCard;
