export default {
  archiveEmailError: 'ARCHIVE_EMAIL_ERROR',
  bulkArchiveEmailError: 'BULK_ARCHIVE_EMAIL_ERROR',
  bulkUnarchiveEmailError: 'BULK_UNARCHIVE_EMAIL_ERROR',
  cloneSavedSearchError: 'CLONE_SAVED_SEARCH_ERROR',
  createSavedSearchNameEmpty: 'CREATE_SAVED_SEARCH_NAME_EMPTY_ERROR',
  createSavedSearchNameExists: 'CREATE_SAVED_SEARCH_NAME_EXISTS_ERROR',
  deleteEmailError: 'DELETE_EMAIL_ERROR',
  deleteSavedSearchError: 'DELETE_SAVED_SEARCH_ERROR',
  fetchError: 'EMAIL_FETCH_ERROR',
  markSuccessError: 'EMAIL_MARK_SUCCESS_ERROR',
  missingSubStatusesError: 'EMAIL_MISSING_SUB_STATUSES_ERROR',
  personForEmailNotFoundError: 'PERSON_FOR_EMAIL_NOT_FOUND_ERROR',
  renameSavedSearchError: 'RENAME_SAVED_SEARCH_ERROR',
  retrySendError: 'EMAIL_RETRY_SEND_ERROR',
  savedSearchNameEmpty: 'SAVED_SEARCH_NAME_EMPTY_ERROR',
  savedSearchNameExists: 'SAVED_SEARCH_NAME_EXISTS_ERROR',
  unArchiveEmailError: 'UNARCHIVE_EMAIL_ERROR',
  updateSavedSearchError: 'UPDATE_SAVED_SEARCH_ERROR',
};
