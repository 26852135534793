import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import SlideOutsActionTypes from 'web/slideOuts/libs/slideOutsActionTypes';
import {
  EditTemplateEmailsStateSelect,
  EditTemplateEmailsTeamsSelect,
} from '../libs/editTemplateSlideOutConstants';

export function editTemplateSlideOutEmails(
  state = [],
  { type = '', email = {}, emails = [] }
) {
  switch (type) {
    case EditTemplateActionTypes.setEmails:
      return emails;
    case EditTemplateActionTypes.setEmail: {
      const index = state.findIndex(({ id }) => id === email.id);
      if (index > -1) {
        return [
          ...state.slice(0, index),
          { ...state[index], ...email },
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    }
    case EditTemplateActionTypes.close:
    case SlideOutsActionTypes.close:
      return [];
    default:
      return state;
  }
}

export const initEmailsViewState = {
  stateSelect: EditTemplateEmailsStateSelect.recentEmails,
  teamsSelect: EditTemplateEmailsTeamsSelect.team,
};
export function editTemplateSlideOutEmailsViewState(
  state = initEmailsViewState,
  {
    type = '',
    stateSelect = EditTemplateEmailsStateSelect.recentEmails,
    teamsSelect = EditTemplateEmailsTeamsSelect.team,
  }
) {
  switch (type) {
    case EditTemplateActionTypes.setEmailsStateSelect:
      return { ...state, stateSelect };
    case EditTemplateActionTypes.setEmailsTeamsSelect:
      return { ...state, teamsSelect };
    case EditTemplateActionTypes.initEmailsView:
    case EditTemplateActionTypes.close:
    case SlideOutsActionTypes.close:
      return initEmailsViewState;
    default:
      return state;
  }
}
