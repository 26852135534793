import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { GeneralPageAlertIds } from '../libs/generalPageConstants';
import { setContentPartners } from 'web/modals/addContent/actionCreators/contentActionCreators';
import { updateAdminSettings } from 'web/user/actionCreators/userActionCreators';

export const updateContentPartners = (isChecked = false, name = '') => (
  dispatch
) => {
  const params = isChecked ? { content_partners: [name] } : {};
  return dispatch(setContentPartners(params))
    .then(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesSaved));
    })
    .catch(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesNotSaved));
    });
};

export const updateBoxIntegrationEnabled = (isChecked) => (dispatch) => {
  const adminSettings = {
    admin_settings: {
      box_integration_enabled: isChecked,
    },
  };

  return dispatch(updateAdminSettingsAndShowAlert(adminSettings));
};

export const updateSlackIntegrationEnabled = (isChecked) => (dispatch) => {
  const adminSettings = {
    admin_settings: {
      slack_integration_enabled: isChecked,
    },
  };

  return dispatch(updateAdminSettingsAndShowAlert(adminSettings));
};

export const updateAdminSettingsAndShowAlert = (adminSettings) => (dispatch) =>
  dispatch(updateAdminSettings(adminSettings))
    .then(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesSaved));
    })
    .catch(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesNotSaved));
    });
