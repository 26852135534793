import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import {
  getTemplateDetails as getTemplateDetailsCall,
  updateTemplateDetails as updateTemplateDetailsCall,
} from '../services/templateDetailsService';

export const getTemplateDetails = (id) => (dispatch) =>
  getTemplateDetailsCall(id).then((template) => {
    dispatch({
      type: TemplatesActionTypes.templates.update,
      template,
    });
    return template;
  });

export const updateTemplateDetails = (id, templateDetails, attachments) => (
  dispatch
) =>
  updateTemplateDetailsCall(id, templateDetails, attachments).then(
    (template) => {
      dispatch({
        type: TemplatesActionTypes.templates.update,
        template,
      });
      return template;
    }
  );
