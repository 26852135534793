import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { ApiErrors } from 'web/libs/constants';
import { ModalsTaskAlertIds } from 'web/modals/task/libs/taskConstants';
import {
  deleteTask,
  saveTask,
} from 'web/task/actionCreators/externalTaskActionCreators';
import { closeModal } from './popupActionCreators';
import {
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { deleteTaskWindow, saveTaskWindow, closeTaskWindow } from '../services/taskWindowHelper';

export function closeModalTask() {
  return (dispatch, getState) => {
    const { modalsTaskSourceOpener } = getState();
    closeTaskWindow(modalsTaskSourceOpener);
    dispatch(closeModal());
    dispatch({ type: ActionTypes.autocomplete.state.clear });
  };
}

export function taskSaveEditing() {
  return (dispatch, getState) => {
    dispatch(setPopupLoading(true));
    dispatch(saveTask())
      .then((data = {}) => {
        const { modalsTaskSourceOpener, modalsTaskWindowMode } = getState();
        if (modalsTaskWindowMode) {
          saveTaskWindow(data, modalsTaskSourceOpener);
        } else {
          toutBackboneHelper.taskSaved(data);
        }

        dispatch(closeModalTask());
      })
      .catch(() => {
        dispatch(setPopupLoading(false));
        dispatch(setPopupAlert(ModalsTaskAlertIds.saveTaskFailed));
      });
  };
}

export function taskDeleteEditing() {
  return (dispatch, getState) => {
    dispatch(setPopupLoading(true));
    dispatch(deleteTask())
      .then((data = {}) => {
        const { modalsTaskSourceOpener, modalsTaskWindowMode } = getState();
        if (modalsTaskWindowMode) {
          deleteTaskWindow(modalsTaskSourceOpener);
        } else {
          toutBackboneHelper.taskDeleted(data.id);
        }
        dispatch(closeModalTask());
      })
      .catch(() => {
        dispatch(setPopupLoading(false));
        dispatch(setPopupAlert(ModalsTaskAlertIds.deleteTaskFailed));
      });
  };
}

export function handleError(err = {}) {
  return (dispatch, getState) => {
    const { taskUserAutocomplete } = getState();
    const textValues = { assignTaskUserName: taskUserAutocomplete };

    if (err.rawResponse === ApiErrors.unverified) {
      dispatch(setPopupAlert(ModalsTaskAlertIds.unverifiedUser));
    } else if (err.statusCode === 401) {
      dispatch(setPopupAlert(ModalsTaskAlertIds.notLoggedIn));
    } else if (err.statusCode === 404) {
      dispatch(
        setPopupAlert(ModalsTaskAlertIds.unprocessableEntityServerError)
      );
    } else if (err.noContactFound) {
      dispatch(setPopupAlert(ModalsTaskAlertIds.noContactFound));
    } else if (err.notSharedContact) {
      dispatch(
        setPopupAlert(ModalsTaskAlertIds.notSharedContactError, textValues)
      );
    } else {
      dispatch(setPopupAlert(ModalsTaskAlertIds.saveTaskFailed));
    }

    dispatch(setPopupLoading(false));
  };
}
