import queue from 'async/queue';
import { getUserChannel } from 'web/services/pusherService';
import { PusherEvents } from 'web/libs/constants';
import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';
import { getPersonById } from './personDetailsActionCreators';

export const updatePersonPitch = ({ person_id: personId } = {}) => (
  dispatch,
  getState
) => {
  if (personId === getState().personDetailsSlideOutPersonId) {
    dispatch(getPersonById(personId));
  }
};

const queuedInstanceUpdatePitchCall = queue(({ data, dispatch }, cb) => {
  dispatch(updatePersonPitch(data));
  cb();
}, 100);

export const updateCurrentPerson = (personId) => (dispatch, getState) => {
  if (personId === getState().personDetailsSlideOutPersonId) {
    dispatch(getPersonById(personId));
  }
};

const queuedInstanceUpdateCampaignCall = queue(({ data, dispatch }, cb) => {
  dispatch(updateCurrentPerson(data.person_id));
  cb();
}, 100);

export const bindPusher = () => (dispatch, getState) => {
  const { personDetailsInit } = getState();

  if (!personDetailsInit) {
    getUserChannel().bind(PusherEvents.workflowInstanceUpdate, (data) =>
      queuedInstanceUpdateCampaignCall.push({ data, dispatch })
    );
    getUserChannel().bind(PusherEvents.pitch, (data) =>
      queuedInstanceUpdatePitchCall.push({ data, dispatch })
    );
    dispatch({ type: PersonDetailsActionTypes.init });
  }
};

export const unbindPusher = () => () => {
  getUserChannel().unbind(PusherEvents.workflowInstanceUpdate);
};
