import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert, { AlertColors } from 'components/alert';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import SettingsUnsubscribesConstants from '../libs/settingsUnsubscribesConstants';

const SettingsUnsubscribesAlertContainer = ({ alert: { id }, closeAlert }) => {
  const defaultSettings = {
    color: AlertColors.redDark,
    location: 'top',
    onClose: closeAlert,
  };

  switch (id) {
    case SettingsUnsubscribesConstants.alertIds.saveUnsubscribeMessagingSuccess:
      return (
        <Alert
          {...defaultSettings}
          color={AlertColors.green}
          textId="web.settings.unsubscribe.saveUnsubscribeMessagingSuccess"
        />
      );
    case SettingsUnsubscribesConstants.alertIds
      .saveUnsubscribeIntegrationsSuccess:
      return (
        <Alert
          {...defaultSettings}
          color={AlertColors.green}
          textId="web.settings.unsubscribe.saveUnsubscribeIntegrationsSuccess"
        />
      );
    case SettingsUnsubscribesConstants.alertIds.noValidateUnsubscribeText:
      return (
        <Alert
          {...defaultSettings}
          textId="web.settings.unsubscribe.noValidateUnsubscribeText"
        />
      );
    case SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed:
      return (
        <Alert {...defaultSettings} textId="web.common.alert.genericIssue" />
      );
    case SettingsUnsubscribesConstants.alertIds.fetchingAdminSettingsError:
      return (
        <Alert
          {...defaultSettings}
          textId="web.settings.unsubscribe.fetchingIntegrationsError"
        />
      );
    case SettingsUnsubscribesConstants.alertIds.invalidMarketoStandardPremium:
      return (
        <Alert
          {...defaultSettings}
          textId="web.settings.unsubscribe.invalidMarketoConnection.standardOrPremium"
          html
        />
      );
    case SettingsUnsubscribesConstants.alertIds.invalidMarketoOtherPlans:
      return (
        <Alert
          {...defaultSettings}
          textId="web.settings.unsubscribe.invalidMarketoConnection.otherPlans"
        />
      );
    default:
      return '';
  }
};

SettingsUnsubscribesAlertContainer.propTypes = {
  alert: PropTypes.object.isRequired,
  closeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.viewAlert,
});

const mapDispatchToProps = {
  closeAlert: closeViewAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsUnsubscribesAlertContainer);
