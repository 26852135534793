import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';

const NavigateConfirmationPopup = (props) => {
  const { closePopup, navigateToService, descriptionTextId, provider } = props;

  return (
    <Popup
      footer={{
        onPrimary: () => {
          navigateToService();
          closePopup();
        },
        onSecondary: closePopup,
        primaryTextId: 'common.ok',
        secondaryTextId: 'common.back',
      }}
      header={{
        onClose: closePopup,
        textId: 'web.settings.accountSettings.integrations.redirect.title',
        textValues: { provider },
      }}
      onOutsideClick={closePopup}
      size={PopupSize.small}
    >
      <p>
        <FormattedHTMLMessage id={descriptionTextId} />
      </p>
      <p className="text-medium">
        <FormattedHTMLMessage
          id="web.settings.accountSettings.integrations.redirect.navigateToAuth"
          values={{ provider }}
        />
      </p>
    </Popup>
  );
};

NavigateConfirmationPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  navigateToService: PropTypes.func.isRequired,
  descriptionTextId: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
};

export default NavigateConfirmationPopup;
