import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import {
  EMAIL_CONNECTION_POPUP_ID,
  EmailConnectionModalNavigation,
  EmailProviders,
  ModalComponentIds,
  EMAIL_CONNECTIONS,
  EmailConnectionAlertIds,
} from 'web/settings/emailConnection/lib/emailConnectionConstants';
import EmailConnectionActionTypes from 'web/settings/emailConnection/lib/emailConnectionActionTypes';
import {
  fetchEmailConnection,
  updateIntegration,
  disconnectIntegration,
} from 'web/settings/emailConnection/services/emailConnectionService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  hasTriedToConnect,
  removeConnectEmailLocalStorageKey,
} from 'web/settings/emailConnection/helpers/emailConnectionHelpers';
import { toCamelCase, toSnakeCase } from 'web/services/apiHelper';

export const emailConnectionFetching = (loading) => ({
  loading: {
    emailConnectionFetching: loading,
  },
  type: EmailConnectionActionTypes.fetching,
});

export const emailConnectionFetched = (fetched) => ({
  fetched,
  type: EmailConnectionActionTypes.fetched,
});

export const savingEmailChanges = (loading) => ({
  loading: {
    isSavingChanges: loading,
  },
  type: EmailConnectionActionTypes.savingChanges,
});

export const connectionIsDisconnecting = (loading) => ({
  loading: {
    isDisconnecting: loading,
  },
  type: EmailConnectionActionTypes.isDisconnecting,
});

export const emailConnectionStartup = () => (dispatch) => {
  dispatch(openPopup(EMAIL_CONNECTION_POPUP_ID));
  dispatch({
    modal: ModalComponentIds.selection,
    type: EmailConnectionActionTypes.modal.set,
  });
};

export const outlookEmailConnectionStartup = () => (dispatch) => {
  dispatch(openPopup(EMAIL_CONNECTION_POPUP_ID));
  dispatch({
    modal: ModalComponentIds.outlookSelection,
    type: EmailConnectionActionTypes.modal.set,
  });
  dispatch(setEmailProvider(EmailProviders.office365));
};

export const openDisconnectModal = () => (dispatch) => {
  dispatch(openPopup(ModalComponentIds.disconnect));
};

export const closePopup = () => (dispatch) => {
  dispatch({ type: PopupActionTypes.close });
  dispatch({ type: EmailConnectionActionTypes.modal.clear });
  dispatch({ type: EmailConnectionActionTypes.provider.reset });
};

export const setEmailProvider = (provider) => (dispatch) => {
  dispatch({
    provider,
    type: EmailConnectionActionTypes.provider.set,
  });
};

export const flowControl = (action) => (dispatch, getState) => {
  const { emailConnectionModal, emailConnectionProvider } = getState();

  const modals = {
    [ModalComponentIds.selection]: {
      next:
        emailConnectionProvider === EmailProviders.gmail
          ? ModalComponentIds.gmailSelection
          : ModalComponentIds.outlookSelection,
      prev: null,
    },
    [ModalComponentIds.outlookSelection]: {
      next:
        emailConnectionProvider === EmailProviders.office365
          ? ModalComponentIds.office365Selected
          : ModalComponentIds.onPremiseSelected,
      prev: ModalComponentIds.selection,
    },
    [ModalComponentIds.gmailSelection]: {
      prev: ModalComponentIds.selection,
    },
    [ModalComponentIds.office365Selected]: {
      prev: ModalComponentIds.outlookSelection,
    },
    [ModalComponentIds.onPremiseSelected]: {
      prev: ModalComponentIds.outlookSelection,
    },
  };

  const targetModal = modals[emailConnectionModal][action];

  if (action === EmailConnectionModalNavigation.prev) {
    dispatch({ type: EmailConnectionActionTypes.provider.reset });
  }

  if (targetModal === ModalComponentIds.outlookSelection) {
    dispatch(setEmailProvider(EmailProviders.office365));
  }

  dispatch({
    modal: modals[emailConnectionModal][action],
    type: EmailConnectionActionTypes.modal.set,
  });

  if (!targetModal) {
    dispatch(closePopup());
  }
};

const hasJustSignedIn = ({ hasValidCredentials = false }) => (dispatch) => {
  if (hasTriedToConnect()) {
    hasValidCredentials
      ? dispatch(
          openViewAlert(EmailConnectionAlertIds.emailConnectionConnected)
        )
      : dispatch(openViewAlert(EmailConnectionAlertIds.emailConnectionFailure));
  }

  removeConnectEmailLocalStorageKey();
};

export const getAuthIntegrations = () => (dispatch) => {
  dispatch(emailConnectionFetching(true));

  return fetchEmailConnection(EMAIL_CONNECTIONS)
    .then(toCamelCase)
    .then((response) => {
      dispatch(emailConnectionFetched(true));
      dispatch({
        auth: response,
        type: EmailConnectionActionTypes.auth.set,
      });
      dispatch(hasJustSignedIn(response));
      return response;
    })
    .catch(() => {
      dispatch(
        openViewAlert(EmailConnectionAlertIds.emailConnectionFetchError)
      );
    })
    .finally((response) => {
      dispatch(emailConnectionFetching(false));
      return response;
    });
};

export const disconnectEmailConnection = () => (dispatch, getState) => {
  const { id } = getState().emailConnectionAuth;
  dispatch(connectionIsDisconnecting(true));
  return disconnectIntegration(id)
    .then(() => {
      dispatch(closePopup());
      dispatch(
        openViewAlert(EmailConnectionAlertIds.emailConnectionDisconnected)
      );
      dispatch(getAuthIntegrations());
    })
    .catch(() => {
      dispatch(
        openViewAlert(EmailConnectionAlertIds.emailConnectionNotDisconnected)
      );
    })
    .finally(() => {
      dispatch(connectionIsDisconnecting(false));
    });
};

export const saveChanges = (data = {}) => (dispatch, getState) => {
  const { id } = getState().emailConnectionAuth;
  dispatch(savingEmailChanges(true));
  const newData = {
    contact_integration: { ...toSnakeCase(data) },
  };

  return updateIntegration(id, newData)
    .then(toCamelCase)
    .then((response = {}) => {
      dispatch({
        auth: response,
        type: EmailConnectionActionTypes.auth.set,
      });
      dispatch(openViewAlert(EmailConnectionAlertIds.changesSaved));
    })
    .catch(() => {
      dispatch(openViewAlert(EmailConnectionAlertIds.changesNotSaved));
    })
    .finally(() => {
      dispatch(savingEmailChanges(false));
    });
};
