export function addObjectToMapOfArrays(state, action, addToFront) {
  const array = state[action.id] || [];

  return {
    ...state,
    [action.id]: addToFront ? [action.data, ...array] : [...array, action.data],
  };
}

export function addObjectsToMapOfArrays(state, action, addToFront) {
  const array = state[action.id] || [];

  return {
    ...state,
    [action.id]: addToFront
      ? [...action.data, ...array]
      : [...array, ...action.data],
  };
}

export function updateObjectInMapOfArrays(state, action, predicate) {
  const array = state[action.id] || [];
  const index = array.findIndex(predicate);
  if (!array || index < 0) {
    return state;
  } else {
    return {
      ...state,
      [action.id]: [
        ...array.slice(0, index),
        action.data,
        ...array.slice(index + 1),
      ],
    };
  }
}

export function removeMapOfArrays(state, action) {
  const temp = { ...state };
  Reflect.deleteProperty(temp, action.id);
  return temp;
}

// By data ID
export function deleteObjectFromMapOfArrays(state, action, comparator) {
  const temp = state[action.id] || [];
  const property = comparator || 'id';
  const index = temp.findIndex(
    (element) => element[property] === +action.data[property]
  );

  if (!temp.length || index < 0) {
    return state;
  }

  return {
    ...state,
    [action.id]: [...temp.slice(0, index), ...temp.slice(index + 1)],
  };
}

// By IDs
export function deleteObjectsFromMapOfArrays(state, action) {
  let temp = state[action.id];

  if (!temp) {
    return state;
  }

  action.ids.forEach((id) => {
    const index = temp.findIndex((element) => element.id === +id);
    temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
  });

  return {
    ...state,
    [action.id]: temp,
  };
}
