import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function setLoading(isLoading) {
  return {
    type: ActionTypes.modal.state.loading,
    isLoading,
  };
}

// common to other modals?
export function openModal(id, data) {
  return {
    type: ActionTypes.modal.state.open,
    id,
    data,
  };
}

export function closeModal() {
  return {
    type: ActionTypes.modal.state.close,
  };
}
