import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withReadMore from 'components/hocs/withReadMore';
import CatchATagClicks from 'web/emails/components/catchATagClicks';
import TextButton from 'components/buttons/text';
import AttachmentsCount from 'components/attachmentsCount';
import './templateDetails.scss';

class TemplateDetails extends PureComponent {
  render() {
    const { attachmentsCount, body, edit, subject } = this.props;

    return (
      <div className="edit-template-template-details">
        <div className="template-details-header-row">
          <h4 className="template-details-subject text-medium text-overflow">
            {subject}
          </h4>
          {edit && (
            <TextButton onClick={edit} underline>
              <FormattedMessage id="common.edit" />
            </TextButton>
          )}
        </div>
        <div className="template-details-body">
          <CatchATagClicks text={body} />
        </div>
        <AttachmentsCount count={attachmentsCount} />
      </div>
    );
  }
}

TemplateDetails.propTypes = {
  attachmentsCount: PropTypes.number,
  body: PropTypes.string,
  edit: PropTypes.func,
  subject: PropTypes.string,
};

TemplateDetails.defaultProps = {
  attachmentsCount: 0,
  body: '',
  edit: null,
  subject: '',
};

export default withReadMore()(TemplateDetails);
