import ActionTypes from 'table/modules/actionTypes';
import { SortingDirection as Direction } from 'table/modules/constants';

// export const INIT_SORTING_COLUMN = -1;
export function sortingColumn(state = '', { type = '', id = '' }) {
  switch (type) {
    case ActionTypes.sortingSetColumn:
      return id || state;
    default:
      return state;
  }
}

export function sortingDirection(
  state = Direction.ASC,
  { type = '', direction = null }
) {
  switch (type) {
    case ActionTypes.sortingSetDirection:
      return direction || state;
    default:
      return state;
  }
}
