import React from 'react';
import { bool, string, array, object, number } from 'prop-types';
import AdminSetup from '../adminSetup';
import AdminTeamAccess from '../adminTeamAccess';
import { TabValues } from '../../libs/marketoSettingsConstants';
import './marketoAdminTabView.scss';

export default function MarketoAdminTabView({
  actionCreators,
  connectionInfo,
  connectionState,
  gettingUsers,
  page,
  selectedTabValue,
  teamAccessSmartFilter,
  users,
  saleforceConnectLink,
}) {
  switch (selectedTabValue) {
    case TabValues.setup:
      return (
        <AdminSetup
          connectionInfo={connectionInfo}
          connectionState={connectionState}
          getMarketoIntegration={actionCreators.getMarketoIntegration}
          handleIntegrationConnection={
            actionCreators.handleIntegrationConnection
          }
          saleforceConnectLink={saleforceConnectLink}
        />
      );
    case TabValues.userAccess:
      return (
        <AdminTeamAccess
          actionCreators={actionCreators}
          connectionState={connectionState}
          gettingUsers={gettingUsers}
          page={page}
          teamAccessSmartFilter={teamAccessSmartFilter}
          users={users}
        />
      );
    default:
      return null;
  }
}

MarketoAdminTabView.propTypes = {
  actionCreators: object.isRequired,
  connectionInfo: object.isRequired,
  connectionState: string.isRequired,
  gettingUsers: bool.isRequired,
  page: number.isRequired,
  saleforceConnectLink: string.isRequired,
  selectedTabValue: string.isRequired,
  teamAccessSmartFilter: string.isRequired,
  users: array.isRequired,
};
