import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import Label from 'components/label';
import LiveFeedEvent from 'web/liveFeed/components/liveFeedEvent/liveFeedEvent';
import { DETAILS_EVENTS_LIMIT } from 'web/composeWindow/libs/composeWindowConstants';
import LoadingSpinner from 'components/loadingSpinner';
import { FormattedMessage } from 'react-intl';
import './miniPersonDetailView.scss';
import outsideClickHandler from 'components/hocs/outsideClickHandler';

class MiniPersonDetailView extends React.Component {
  renderActivities(events) {
    if (events) {
      return events.slice(0, DETAILS_EVENTS_LIMIT).map((event) => {
        const { id: eventId, ...eventAttrs } = event;
        return <LiveFeedEvent eventId={eventId} key={eventId} {...event} />;
      });
    } else {
      return null;
    }
  }

  renderDetail(id, title, detail = ' ') {
    return (
      <Label
        className="bold-label"
        id={id}
        messageKey={title}
        sideLabel
        width={60}
      >
        {detail}
      </Label>
    );
  }

  renderSFLink(link) {
    return (
      <div>
        <a href={link}>
          <FormattedMessage id="web.composeEmail.personDetails.salesforceLink" />
        </a>
      </div>
    );
  }

  render() {
    let { errored, loading, person } = this.props;
    const { about = {}, events } = person;
    const { name, role, company, link } = about;

    const shouldNotRenderDetails = loading || errored;

    const zeroStateActivities = (
      <FormattedMessage
        className="no-events"
        id="web.composeWindow.personDetailsView.noEvents"
      />
    );

    return (
      <Card className="single-view-address-detail">
        {shouldNotRenderDetails ? (
          <LoadingSpinner size={60} />
        ) : (
          <React.Fragment>
            <div className="address-overview">
              <h4>
                <FormattedMessage id="web.composeEmail.personDetails.overview" />
              </h4>
              {this.renderDetail('mini-PDV-name', 'common.name', name)}
              {this.renderDetail('mini-PDV-title', 'common.title', role)}
              {this.renderDetail('mini-PDV-company', 'common.company', company)}
              {link && this.renderSFLink(link)}
            </div>
            <div className="address-events">
              <h4>
                <FormattedMessage id="web.composeEmail.personDetails.recentActivity" />
              </h4>
              {events && events.length
                ? this.renderActivities(events)
                : zeroStateActivities}
            </div>
          </React.Fragment>
        )}
      </Card>
    );
  }
}

MiniPersonDetailView.propTypes = {
  closeDetails: PropTypes.func.isRequired,
  errored: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  person: PropTypes.object.isRequired,
};

MiniPersonDetailView.defaultProps = {
  events: [],
};

export default outsideClickHandler(MiniPersonDetailView, {
  onOutsideClick: 'closeDetails',
});
