import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './confirmDelete.scss';
import { injectIntl, intlShape } from 'react-intl';

class ConfirmDelete extends Component {
  componentWillMount() {
    this.topText = this._getMessageId();
    this.you = this.props.intl.formatMessage({ id: 'common.you' });
  }

  render() {
    const { teamMembers, userActives } = this.props;

    return (
      <div className="confirmDelete">
        <div>{this.topText}</div>
        {teamMembers.length ? (
          <ul className="team-members-list margin-top-full">
            {userActives ? (
              <li className="team-member text-medium text-overflow">
                {this.you}
              </li>
            ) : null}
            {teamMembers.map((member, index) => (
              <li
                key={`confirm-delete-${index}`} /* eslint-disable-line react/no-array-index-key */
                className="team-member text-medium text-overflow"
              >
                {member}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }

  _getMessageId = () => {
    const {
      admin,
      intl: { formatMessage },
      teamMembers,
      userActives,
    } = this.props;

    if (admin && teamMembers.length) {
      return formatMessage({
        id: 'web.campaigns.confirmDelete.adminTeamUsing',
      });
    } else if (userActives) {
      return formatMessage(
        { id: 'web.campaigns.confirmDelete.userUsingOnly' },
        { people: userActives }
      );
    } else {
      return formatMessage({ id: 'web.campaigns.confirmDelete.noActives' });
    }
  };
}

ConfirmDelete.propTypes = {
  admin: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  teamMembers: PropTypes.array.isRequired,
  userActives: PropTypes.number.isRequired,
};

export default injectIntl(ConfirmDelete);
