import unauthorizedPage from 'components/hocs/unauthorizedPage';
import OutlookDescription from 'web/login/components/outlookDescription';
import './outlookDescriptionContainer.scss';

export default unauthorizedPage(OutlookDescription, {
  backgroundLayoutMode: false,
  parentClassName: 'outlook-description-container',
  showLogo: false,
  titleKey: 'common.login',
});
