import React from 'react';
import PropTypes from 'prop-types';
import { isSharedContact } from 'web/people/helpers/peopleHelpers';
import classNames from 'classnames';

import './sharedCell.scss';

const SharedCell = ({ rowData }) => {
  const className = classNames({
    'people-table-shared': isSharedContact(rowData),
  });

  return <div className={className} />;
};

SharedCell.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default SharedCell;
