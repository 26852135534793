import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import EmailConnectionFeaturesList from 'web/settings/emailConnection/components/emailConnectionFeatureList';
import Button, { ButtonColors } from 'components/buttons/action';
import './emailConnectionNotConnected.scss';

class EmailConnectionNotConnected extends Component {
  render() {
    const {
      actionCreators: { emailConnectionStartup },
    } = this.props;

    const actionableFeaturesTexts = {
      sendComplianceText: (
        <FormattedMessage
          id="web.settings.emailConnection.featureList.sendCompliance"
          values={{ provider: null }}
        />
      ),
    };

    return (
      <div className="email-connection-not-connected">
        <h3 className="email-connection-not-connected-header">
          <FormattedMessage id="web.settings.emailConnection.featureList.setup" />
        </h3>
        <p className="email-connection-description">
          <FormattedMessage id="web.settings.emailConnection.featureList.info" />
        </p>
        <EmailConnectionFeaturesList
          actionableFeaturesTexts={actionableFeaturesTexts}
        />
        <div className="get-started">
          <Button
            size="medium"
            className="get-started-button"
            color={ButtonColors.blue}
            onClick={emailConnectionStartup}
          >
            <FormattedMessage id="common.getStarted" />
          </Button>
        </div>
      </div>
    );
  }

  itemRenderer = (item) => (
    <li className="email-connection-features">
      <i className={classNames(item.icon, 'email-connection-icons')} />
      <span className="features-description">{item.value}</span>
    </li>
  );

  static propTypes = {
    actionCreators: PropTypes.object.isRequired,
  };
}

export default EmailConnectionNotConnected;
