import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Action, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { Urls } from 'web/libs/routes';
import './setPasswordSuccess.scss';

const SetPasswordSuccess = () => (
  <div className="set-password-success">
    <h2 className="set-password-success-title">
      <FormattedMessage id="login.setPassword.success.title" />
    </h2>
    <div className="button-panel">
      <Link to={Urls.loginPage}>
        <Action color={ButtonColors.blue} size={ButtonSizes.medium}>
          <FormattedMessage id="forgotPassword.loginBack" />
        </Action>
      </Link>
    </div>
  </div>
);

export default injectIntl(SetPasswordSuccess);
