import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './chevron.scss';

const Chevron = ({ color, direction, disabled, onClick }) => (
  <div
    className={classNames('tout-chevron', {
      [color]: color,
      [direction]: true,
      disabled,
    })}
    onClick={(!disabled && onClick) || null}
  />
);

export const ChevronDirections = {
  top: 'top',
  bottom: 'bottom',
  right: 'right',
  left: 'left',
};

export const ChevronColors = {
  blue: 'blue',
  white: 'white',
  gray: 'gray',
};

Chevron.propTypes = {
  color: PropTypes.oneOf(Object.values(ChevronColors)),
  direction: PropTypes.oneOf(Object.values(ChevronDirections)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Chevron.defaultProps = {
  color: ChevronColors.gray,
  direction: ChevronDirections.bottom,
  disabled: false,
  onClick: null,
};

export default Chevron;
