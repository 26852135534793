import queryString from 'query-string';
import UnsubscribeFormActionTypes from '../libs/unsubscribeFormActionTypes';
import { unsubscribeManual as unsubscribeManualCall } from 'web/people/services/peopleService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { UnsubscribeFormAlertIds } from '../libs/unsubscribeFormConstants';
import { getUnsubscribeReasonId } from '../selectors/unsubscribeFormSelectors';

export const unsubscribeManualLoading = (loading) => ({
  type: UnsubscribeFormActionTypes.unsubscribeManualLoading,
  loading,
});

export const unsubscribeManual = () => (dispatch, getState) => {
  const state = getState();

  const {
    unsubscribeFormBlockTypeSelected,
    unsubscribeFormOtherReason,
  } = state;

  const query = queryString.parse(window.location.search);
  const path = window.location.pathname.split('/');

  const data = {
    id: path[2],
    email: path[4],
    user_id: query.user_id,
    unsubscribe: {
      block_type: unsubscribeFormBlockTypeSelected,
      unsubscribe_reason_id: getUnsubscribeReasonId(state),
    },
  };

  if (unsubscribeFormOtherReason) {
    data.unsubscribe.reason_description = unsubscribeFormOtherReason;
  }

  dispatch(unsubscribeManualLoading(true));

  return unsubscribeManualCall(data)
    .then(() => {
      dispatch(unsubscribeManualLoading(false));
      dispatch({ type: UnsubscribeFormActionTypes.unsubscribeManualSuccess });
    })
    .catch(() => {
      dispatch(unsubscribeManualLoading(false));
      dispatch(openViewAlert(UnsubscribeFormAlertIds.unsubscribeManualError));
    });
};

export const changeBlockTypeSelected = (selected) => ({
  type: UnsubscribeFormActionTypes.selectBlockType,
  selected,
});

export const changeReasonSelected = (selected) => ({
  type: UnsubscribeFormActionTypes.selectReason,
  selected,
});

export const changeOtherReason = (value) => ({
  type: UnsubscribeFormActionTypes.changeOtherReason,
  value,
});
