import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageHeader from 'components/layouts/page/header';
import { getUserCanEditTemplates } from 'web/templates/selectors/templatesSelectors';

class TemplatesPageHeader extends Component {
  constructor(props) {
    super(props);
    const { actionCreators, buttonsDisabled: disabled } = props;

    this.state = {
      headerButton: {
        disabled,
        textId: 'web.templates.createTemplate',
        onClick: actionCreators.openCreateTemplatePopup,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { buttonsDisabled } = this.props;
    const { buttonsDisabled: prevButtonsDisabled } = prevProps;

    if (buttonsDisabled !== prevButtonsDisabled) {
      const { headerButton } = this.state;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(() => ({
        headerButton: { ...headerButton, disabled: buttonsDisabled },
      }));
    }
  }

  render() {
    const { headerButton } = this.state;
    const { title, userCanEditTemplates } = this.props;
    const headerButtons = userCanEditTemplates ? [headerButton] : [];

    return <PageHeader buttons={headerButtons} text={title} fillHeight />;
  }
}

TemplatesPageHeader.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  userCanEditTemplates: PropTypes.bool.isRequired,
  buttonsDisabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

TemplatesPageHeader.defaultProps = {
  buttonsDisabled: false,
};

function mapStateToProps(state) {
  return {
    userCanEditTemplates: getUserCanEditTemplates(state),
  };
}

export default connect(mapStateToProps)(TemplatesPageHeader);
