import TeamsActionTypes from '../libs/teamsActionTypes';

export const selectedTeamId = (state = null, { type, id }) => {
  switch (type) {
    case TeamsActionTypes.setSelectedTeamId:
      return id;
    default:
      return state;
  }
};

export const editingTeamId = (state = null, { type, id }) => {
  switch (type) {
    case TeamsActionTypes.setEditingTeamId:
      return id;
    default:
      return state;
  }
};

export const createTeamLoading = (state = false, { type, loading }) => {
  switch (type) {
    case TeamsActionTypes.setCreateTeamLoading:
      return loading;
    default:
      return state;
  }
};

export const updateTeamLoading = (state = false, { type, loading }) => {
  switch (type) {
    case TeamsActionTypes.setUpdateTeamLoading:
      return loading;
    default:
      return state;
  }
};

export const deleteTeamLoading = (state = false, { type, loading }) => {
  switch (type) {
    case TeamsActionTypes.setDeleteTeamLoading:
      return loading;
    default:
      return state;
  }
};
