import I18N from 'languages';

export const getObjectTypeString = (type) => {
  switch (type) {
    case 'Task':
      return I18N.getStr('common.task');
    default:
      return type;
  }
};

export const getFailureErrorMessage = (type) => {
  switch (type) {
    case 'INVALID_FIELD':
      return I18N.getStr('web.diagnostics.noSuchColumn');
    case 'INVALID_TYPE':
      return I18N.getStr('web.diagnostics.taskNotFound');
    case 'MISSING_AUTHENTICATION':
      return I18N.getStr('web.diagnostics.missingAuthentication');
    default:
      return type;
  }
};
