import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import IdentitiesPopup from '../components/identitiesPopup';
import {
  closePopup,
  hidePopupOverlay,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  sendVerifyEmail,
  removeItem,
} from '../actionCreators/identitiesTableActionCreators';

class IdentitiesPopupContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = bindActionCreators(
      {
        closePopup,
        hidePopupOverlay,
        removeItem,
        sendVerifyEmail,
      },
      dispatch
    );
  }

  render() {
    return (
      <IdentitiesPopup {...this.props} actionCreators={this.actionCreators} />
    );
  }
}

IdentitiesPopupContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  popup: PropTypes.string,
  popupData: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    popup: state.popup,
    popupData: state.popupData,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(IdentitiesPopupContainer);
