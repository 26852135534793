export const FORM_ID = 'emailServer';
export const LABEL_WIDTH = 160;
export const GMAIL_SMTP_SERVERS = [
  'smtp.gmail.com',
  'aspmx.l.google.com',
  'smtp-relay.gmail.com',
];
export const OFFICE365_SMTP_SERVERS = [
  'smtp.office365.com',
  'smtp-mail.outlook.com',
  'smtp.live.com',
];

export const PORT_OPTIONS = [
  { label: 'web.settings.emailServer.portOptionLabel.25', value: '25' },
  { label: 'web.settings.emailServer.portOptionLabel.467', value: '467' },
  { label: 'web.settings.emailServer.portOptionLabel.587', value: '587' },
];

export const CONNECTION_TYPE_OPTIONS = [
  {
    label: 'web.settings.emailServer.connectionTypeOption.plain',
    value: 'plain',
  },
  {
    label: 'web.settings.emailServer.connectionTypeOption.plain_secured',
    value: 'plain_secured',
  },
  {
    label: 'web.settings.emailServer.connectionTypeOption.login',
    value: 'login',
  },
  {
    label: 'web.settings.emailServer.connectionTypeOption.login_secured',
    value: 'login_secured',
  },
  {
    label: 'web.settings.emailServer.connectionTypeOption.ntlm',
    value: 'ntlm',
  },
];

export const EmailServerAlertIds = {
  connected: 'EMAIL_SERVER_CONNECTED',
  disconnected: 'EMAIL_SERVER_DISCONNECTED',
  error: 'EMAIL_SERVER_ERROR',
  validationError: 'EMAIL_SERVER_VALIDATION_ERROR',
};

export const EmailServerPopupIds = {
  removeConfirmation: 'EMAIL_SERVER_REMOVE_CONFIRMATION_POPUP',
};

export const EmailServerValidatorTypes = {
  notGmailSmtp: 'NOT_GMAIL_SMTP',
  notOffice365Smtp: 'NOT_OFFICE_365_SMTP',
};

export const EmailServerValidationErrorMessageIds = {
  gmailSmtp: 'web.settings.emailServer.alert.gmailSmtp',
  office365Smtp: 'web.settings.emailServer.alert.office365Smtp',
};
