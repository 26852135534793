import ForgotPasswordActionTypes from '../libs/forgotPasswordActionTypes';

export const forgotPasswordIsRequestSent = (state = false, { type }) => {
  switch (type) {
    case ForgotPasswordActionTypes.initialize:
      return false;
    case ForgotPasswordActionTypes.requestSent:
      return true;
    default:
      return state;
  }
};

export const forgotPasswordRequestLoading = (
  state = false,
  { type, loading = false }
) => {
  switch (type) {
    case ForgotPasswordActionTypes.setLoading:
      return loading;
    default:
      return state;
  }
};

export const forgotPasswordSentEmail = (state = null, { type, email }) => {
  switch (type) {
    case ForgotPasswordActionTypes.requestSent:
      return email;
    case ForgotPasswordActionTypes.initialize:
      return null;
    default:
      return state;
  }
};
