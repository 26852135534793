import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ProgressBar from 'components/progressBar';
import './salesforceProgress.scss';

class SalesforceProgress extends Component {
  render() {
    const { progress } = this.props;
    return (
      <div className="salesforce-progress">
        <h4 className="salesforce-progress-message">
          <FormattedMessage id="web.addToCampaign.transitioningToSalesforce" />
        </h4>
        <ProgressBar animate progress={progress || 5} />
      </div>
    );
  }
}

SalesforceProgress.propTypes = {
  progress: PropTypes.number,
};

export default SalesforceProgress;
