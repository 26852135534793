import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import Search from 'table/modules/search';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import TextButton from 'components/buttons/text';
import './emailGridAdvancedSearch.scss';

import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { EMAILS_TABLE_DATE_RANGES_KEY } from 'web/commandCenter/libs/commandCenterConstants';
import SmartFilter from 'table/modules/smartFilter';
import StatusSmartFilterContainer from 'web/commandCenter/modules/statusSmartFilter/statusSmartFilterContainer';
import dateRangeSmartFilter from 'web/tables/dateRangeSmartFilter';
import { getEmailsGridDateRangesByStatus } from 'web/commandCenter/helpers/emailHelpers';
import FilterBlockContainer from 'web/commandCenter/modules/filterBlock/containers/filterBlockContainer';
import CampaignSmartFilterContainer from 'web/commandCenter/modules/campaignSmartFilter/containers/campaignSmartFilterContainer';
import WhoSmartFilterContainer from 'web/commandCenter/modules/whoSmartFilter/containers/whoSmartFilterContainer';
import WhenSmartFilterContainer from 'web/commandCenter/modules/whenSmartFilter/containers/whenSmartFilterContainer';

const DateRangeSmartFilter = dateRangeSmartFilter(SmartFilter);

class EmailGridAdvancedSearch extends Component {
  render() {
    const {
      searchValue,
      setAdvancedSearchValue,
      closePopup,
      clearAdvancedSearchValue,
      intl: { formatMessage },
    } = this.props;

    const SMART_FILTER_HEIGHT = 44;
    return (
      <div className="email-grid-advanced-search">
        <div className="header-filters flex--horz-spread">
          <Search
            onChange={setAdvancedSearchValue}
            onClear={clearAdvancedSearchValue}
            placeholderText={formatMessage({
              id: 'web.commandCenter.emails.searchPlaceholder',
            })}
            value={searchValue}
            noDropdown
          />
          <DateRangeSmartFilter
            dateRangeFilters={this._getDateRangeList()}
            dateRangeSyncKey={EMAILS_TABLE_DATE_RANGES_KEY}
            height={SMART_FILTER_HEIGHT}
            tableId={ADVANCED_SEARCH_FILTERS_COLLECTION}
          />
        </div>
        <div className="filter-blocks">
          <FilterBlockContainer
            collectionId={ADVANCED_SEARCH_FILTERS_COLLECTION}
          />
        </div>
        <div className="flex advanced-search-body">
          <div className="flex---column--full">
            <WhoSmartFilterContainer />
            <WhenSmartFilterContainer />
          </div>
          <div className="flex---column--full">
            <CampaignSmartFilterContainer />
          </div>
          <div className="flex---column--full">
            <StatusSmartFilterContainer />
          </div>
        </div>
        <div className="flex--horz-spread">
          <div className="flex--center-vert">
            <TextButton onClick={this.clickClear} size={ButtonSizes.medium}>
              <FormattedMessage id="web.commandCenter.advancedSearch.clearFilters" />
            </TextButton>
          </div>
          <div>
            {this.getSaveButton()}
            <Button
              className="margin-left-full"
              color={ButtonColors.gray}
              onClick={closePopup}
              size={ButtonSizes.medium}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              className="margin-left-full"
              onClick={this.clickSearch}
              size={ButtonSizes.medium}
            >
              <FormattedMessage id="common.search" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  getSaveButton = () => {
    const { isSavedAdvancesSearch, isSavedAdvancesSearchChanged } = this.props;
    if (isSavedAdvancesSearch)
      return (
        <TextButton
          disabled={isSavedAdvancesSearch && !isSavedAdvancesSearchChanged}
          onClick={this.clickSaveSearch}
        >
          <FormattedMessage id="common.saveChanges" />
        </TextButton>
      );
    return (
      <TextButton onClick={this.clickSaveAsSearch}>
        <FormattedMessage id="web.commandCenter.advancedSearch.saveFiltersAs" />
      </TextButton>
    );
  };

  _getDateRangeList() {
    const { isScheduleStatus } = this.props;
    return getEmailsGridDateRangesByStatus(isScheduleStatus);
  }

  clickSearch = () => {
    const { searchEmailAdvancedSearch } = this.props;

    searchEmailAdvancedSearch();
  };

  clickSaveAsSearch = () => {
    const { openSaveSearchPopup } = this.props;
    openSaveSearchPopup();
  };

  clickSaveSearch = () => {
    const {
      saveAdvancedSearchChanges,
      isSavedAdvancesSearchChanged,
    } = this.props;
    if (isSavedAdvancesSearchChanged) saveAdvancedSearchChanges();
  };

  clickClear = () => {
    const { clearAdvancedSearchFilters } = this.props;
    clearAdvancedSearchFilters();
  };
}

EmailGridAdvancedSearch.propTypes = {
  clearAdvancedSearchFilters: PropTypes.func.isRequired,
  clearAdvancedSearchValue: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isSavedAdvancesSearch: PropTypes.bool.isRequired,
  isSavedAdvancesSearchChanged: PropTypes.bool.isRequired,
  isScheduleStatus: PropTypes.bool.isRequired,
  openSaveSearchPopup: PropTypes.func.isRequired,
  saveAdvancedSearchChanges: PropTypes.func.isRequired,
  searchEmailAdvancedSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setAdvancedSearchValue: PropTypes.func.isRequired,
};

export default injectIntl(EmailGridAdvancedSearch);
