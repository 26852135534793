import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import StatsLine from 'components/stats/line';
import './apiCallsCard.scss';
import I18N from 'languages';

const ApiCallsCard = (props) => {
  let {
    statsOccupancyPercent,
    stats: { total = 0, used = 0 },
  } = props;
  used = I18N.getLocalizedNumber(used);
  total = I18N.getLocalizedNumber(total);
  return (
    <div className="salesforce-api-calls-card">
      <h3>
        <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.apiCalls.title" />
      </h3>
      <div className="stats-wrapper">
        <StatsLine percent={statsOccupancyPercent} />
      </div>
      <FormattedHTMLMessage
        id="web.settings.accountSettings.salesforce.syncSettings.apiCalls.callsRemaining"
        values={{ used, total }}
      />
    </div>
  );
};

ApiCallsCard.propTypes = {
  statsOccupancyPercent: PropTypes.number,
  stats: PropTypes.object.isRequired,
};

ApiCallsCard.defaultProps = {
  statsOccupancyPercent: null,
};

export default ApiCallsCard;
