import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Popup, { PopupButtonColors } from 'components/popups';

class EmailServerRemoveConfirmationPopup extends Component {
  render() {
    const { removeEmailServer, closePopup } = this.props.actionCreators;

    return (
      <Popup
        footer={{
          onPrimary: removeEmailServer,
          onSecondary: closePopup,
          primaryTextId: 'common.disconnect',
          secondaryTextId: 'common.cancel',
          primaryColor: PopupButtonColors.red,
        }}
        header={{
          onClose: closePopup,
          textId: 'web.settings.emailServer.removeConfirmation.title',
        }}
        onOutsideClick={closePopup}
      >
        <FormattedMessage id="web.settings.emailServer.removeConfirmation.text" />
      </Popup>
    );
  }
}

EmailServerRemoveConfirmationPopup.propTypes = {
  intl: intlShape.isRequired,
  actionCreators: PropTypes.object.isRequired,
};

export default injectIntl(EmailServerRemoveConfirmationPopup);
