import React from 'react';
import PropTypes from 'prop-types';
import Icon, { IconColors } from 'components/buttons/icon';
import { FormattedMessage } from 'react-intl';
import ViewMore from 'components/viewMore';
import './marketoCampaignsHistory.scss';

const SHOWN_COUNT = 2;

const itemRenderer = ({
  primaryAttributeValueId,
  primaryAttributeValue,
  activityDate,
}) => (
  //eslint-disable-line
  <div
    className="marketo-campaigns-history-row"
    key={`marketo-campaign-history-${primaryAttributeValueId}`}
  >
    <div className="row-date">
      <FormattedMessage
        id="common.dateFormatShort"
        values={{ date: new Date(activityDate) }}
      />
    </div>
    <div className="text-overflow row-name">
      <p className="marketo-campaigns-name">
        <Icon color={IconColors.purple} className="marketo-icon" />
        <div className="text-overflow">{primaryAttributeValue}</div>
      </p>
    </div>
  </div>
);

const MarketoCampaignsHistory = ({ marketoCampaigns }) =>
  marketoCampaigns.length === 0 ? (
    <div className="person-details-marketo-campaigns-history">
      <p className="margin-bottom-half">
        <FormattedMessage id="web.person.personDetails.marketoCampaign.noMarketoCampaigns" />
      </p>
    </div>
  ) : (
    <div className="person-details-marketo-campaigns-history">
      <div className="marketo-campaigns-history-header">
        <div className="row-date">
          <FormattedMessage id="web.person.personDetails.marketoCampaign.addedOn" />
        </div>
        <div className="text-overflow row-name">
          <FormattedMessage id="common.name" />
        </div>
      </div>
      <ViewMore
        className="marketo-campaigns-history-data"
        items={marketoCampaigns}
        itemRenderer={itemRenderer}
        showCount
        shownCount={SHOWN_COUNT}
      />
    </div>
  );

MarketoCampaignsHistory.propTypes = {
  marketoCampaigns: PropTypes.array.isRequired,
};

MarketoCampaignsHistory.defaultProps = {};

export default MarketoCampaignsHistory;
