import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import TemplateBypassUnsubscribe from '../templateBypassUnsubscribe';

class TemplateSettingsCard extends PureComponent {
  render() {
    const {
      onBypassUnsubscribeChange,
      template,
      userCanControlBypassUnsubscribe,
    } = this.props;

    return (
      <div className="template-settings-card">
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.editTemplateSlideOut.templateSettings.title" />
        </h3>
        <TemplateBypassUnsubscribe
          onBypassUnsubscribeChange={onBypassUnsubscribeChange}
          template={template}
          userCanControlBypassUnsubscribe={userCanControlBypassUnsubscribe}
        />
      </div>
    );
  }
}

TemplateSettingsCard.propTypes = {
  onBypassUnsubscribeChange: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  userCanControlBypassUnsubscribe: PropTypes.bool.isRequired,
};

export default injectIntl(TemplateSettingsCard);
