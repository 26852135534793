import { Urls, PusherEnvironment, PusherKey } from 'web/libs/constants';

if (!window.Pusher) {
  require('pusher-js'); // eslint-disable-line global-require
}

let instance = null;

export function initPusherService(userId, subscriptionId) {
  if (!instance) {
    instance = {};
    instance.pusher = new window.Pusher(PusherKey, {
      encrypted: true,
      authEndpoint: Urls.pusherAuth,
    });
    instance.privateUserChannel = instance.pusher.subscribe(
      `private-${PusherEnvironment}-user-${userId}`
    );
    instance.presenceTeamChannel = instance.pusher.subscribe(
      `presence-${PusherEnvironment}-subscription-${subscriptionId}`
    );
  }

  return instance;
}

export function getPusher() {
  return instance.pusher;
}

export function getUserChannel() {
  return instance.privateUserChannel;
}

export function getTeamChannel() {
  return instance.presenceTeamChannel;
}

export function setCustomChannel(channelName, channel) {
  instance[channelName] = instance.pusher.subscribe(channel);
}

export function getCustomChannel(channelName) {
  return instance[channelName];
}
