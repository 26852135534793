import differenceBy from 'lodash/differenceBy';
import SharingSettingsActionTypes from '../libs/sharingSettingsActionTypes';
import {
  TabValues,
  SharingSettingsPopupIds,
} from '../libs/sharingSettingsConstants';
import { getCategoriesTemplatesPermissioned as getCategoriesTemplates } from 'web/categories/services/categoriesTemplatesService';
import { getCategoriesCampaignsPermissioned as getCategoriesCampaigns } from 'web/categories/services/categoriesCampaignsService';
import { getTeams } from 'web/user/services/teamsService';
import { getTeams as getTeamsSelector } from '../selectors/sharingSettingsSelectors';
import {
  onCategorySelect,
  setSidebarLoading,
} from './sharingSettingsSidebarActionCreators';
import {
  setTableFetching,
  getPermissionsEnabled,
} from './sharingSettingsTableActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';

export const initSharingSettings = () => (dispatch, getState) => {
  dispatch(commonStartup());
  dispatch(getPermissionsEnabled());
  if (!getState().sharingSettingsInitStore) {
    setTimeout(() => {
      dispatch({ type: SharingSettingsActionTypes.initStore });
    });
  }
};

export const unmount = () => ({ type: SharingSettingsActionTypes.unmount });

export const getCategoriesByTab = (tab = TabValues.templates) => (dispatch) => {
  dispatch(setSidebarLoading(true));
  dispatch(setTableFetching(true));

  const isTemplates = tab === TabValues.templates;
  const getCategories =
    (isTemplates && getCategoriesTemplates) || getCategoriesCampaigns;
  getCategories()
    .then((categories = []) => {
      dispatch({
        categories,
        type: SharingSettingsActionTypes.categories,
      });

      if (categories.length) {
        dispatch(onCategorySelect(categories[0], 0));
      } else {
        dispatch(setTableFetching(false));
      }

      dispatch(setSidebarLoading(false));
    })
    .catch(() => {
      dispatch(setSidebarLoading(false));
      dispatch(setTableFetching(false));
    });
};

export const onTabSelect = (newTab) => (dispatch) => {
  dispatch({
    tab: newTab,
    type: SharingSettingsActionTypes.setTab,
  });
  dispatch(getCategoriesByTab(newTab));
};

export const openAddTeams = () => (dispatch, getState) => {
  const state = getState();
  dispatch(
    openPopup(SharingSettingsPopupIds.addTeams, {
      teams: differenceBy(getTeams(state.user), getTeamsSelector(state), 'id'),
    })
  );
};

export const setSharingSettingsAlert = (alert) => ({
  alert,
  type: SharingSettingsActionTypes.setAlert,
});
