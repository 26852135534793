export const TEAM_NAMES = {
  everyone: 'Everyone',
};

export const SUBSCRIPTION_PLAN_SLUG = {
  free: 'free',
  msiActions: 'msi_actions',
  premium: 'premium',
  solo: 'g3solo',
  standard: 'standard',
  vip: 'vip',
};
