import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditableHeader from 'components/editableHeader';
import Button from 'components/buttons/action';
import {
  canEditCampaign,
  canShareCampaign,
} from 'web/campaigns/campaignsTab/services/campaignService';
import SlideOutMenu from 'components/slideOutMenu';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import './campaignDetailsHeaderTitle.scss';

class CampaignDetailsHeaderTitle extends Component {
  render() {
    const {
      actionCreators: { setSlideOutMenuOpen },
      settings: { slideOutMenuOpen },
      selectedCampaign,
      userCanEditCampaigns,
    } = this.props;

    const isEditable = canEditCampaign(selectedCampaign);

    return (
      <div className="campaigns-header-title-row">
        <div className="campaign-header-name">
          <EditableHeader
            onSave={this.saveName}
            value={selectedCampaign.name}
            placeHolderTextId="web.campaigns.enterCampaignName"
            isEditable={isEditable && userCanEditCampaigns}
          />
          {userCanEditCampaigns ? (
            <div className="campaign-header-dota-menu">
              <SlideOutMenu
                setSlideOutMenuOpen={setSlideOutMenuOpen}
                slideOutMenuOpen={slideOutMenuOpen}
                slideOutMenuItems={this.getSlideOutMenuItems()}
              />
            </div>
          ) : null}
        </div>
        <div className="campaign-header-actions">
          <div className="campaign-header-actions-add">
            <Button
              color="blue"
              size="medium"
              onClick={this.addPeople}
              disabled={selectedCampaign.steps.length === 0}
            >
              <FormattedMessage id="common.addPeople" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  getSlideOutMenuItems = () => {
    const {
      selectedCampaign,
      actionCreators: {
        updateCategory,
        cloneCampaign,
        shareCampaign,
        deleteCampaignConfirm,
      },
      intl: { formatMessage },
    } = this.props;

    const canShare = canShareCampaign(selectedCampaign);
    const canEdit = canEditCampaign(selectedCampaign);

    return [
      {
        action: updateCategory,
        icon: 'tout-icon-move',
        className: 'tout-icon-move',
        disabled: !canEdit,
        title: !canEdit
          ? formatMessage({ id: 'web.campaigns.editingDisabled' })
          : formatMessage({ id: 'web.campaigns.changeCategory' }),
      },
      {
        action: () => cloneCampaign(selectedCampaign.id),
        icon: 'tout-icon-clone',
        className: 'tout-icon-clone',
        disabled: false, // anyone can clone a campaign
        title: formatMessage({ id: 'common.clone' }),
      },
      {
        action: shareCampaign,
        icon: 'tout-icon-share',
        className: 'tout-icon-share',
        disabled: !canShare,
        title: !canShare
          ? formatMessage({ id: 'web.campaigns.sharingDisabled' })
          : formatMessage({ id: 'common.share' }),
      },
      {
        action: deleteCampaignConfirm,
        icon: 'tout-icon-delete',
        className: 'tout-icon-delete',
        disabled: !canEdit,
        title: !canEdit
          ? formatMessage({ id: 'web.campaigns.editingDisabled' })
          : formatMessage({ id: 'common.delete' }),
      },
    ];
  };

  addPeople = () => {
    const {
      actionCreators: { openAddToCampaign },
      selectedCampaign: { id },
    } = this.props;
    openAddToCampaign(id);
  };

  saveName = (name) => {
    const { selectedCampaign, updateCampaign } = this.props;
    const campaign = {
      ...selectedCampaign,
      name,
    };

    updateCampaign(campaign, true);
  };
}

CampaignDetailsHeaderTitle.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignDetailsHeaderTitle.defaultProps = {
  userCanEditCampaigns: true,
};

export default injectIntl(CampaignDetailsHeaderTitle);
