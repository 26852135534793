import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Select from 'components/select';
import isFunction from 'lodash/isFunction';
import {
  ADD_NEW_FIELD,
  ENTER_KEY_CHARCODE,
} from 'web/people/libs/peopleConstants';
import { Regex } from 'web/libs/constants';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';

import './mapFields.scss';

class MapFieldsCell extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
    } = this.props;

    this.inputPlaceHolder = formatMessage({
      id: 'web.people.typeNewCustomField',
    });

    this.state = {
      inputValue: '',
      showInput: false,
    };
  }

  _saveNewFieldValue() {
    const { inputValue } = this.state;

    if (!this._validateCustomField(inputValue)) {
      return;
    }

    const { setSalesConnectFieldList, onChange, rowData, items } = this.props;
    const newField = {
      label: inputValue,
      value: inputValue,
    };

    const valueNotExists =
      items.findIndex(({ value }) => value === inputValue) === -1;

    if (valueNotExists) {
      const posAddNewField = items.findIndex(
        ({ value }) => value === ADD_NEW_FIELD
      );
      const fields = [
        ...items.slice(0, posAddNewField + 1),
        newField,
        ...items.slice(posAddNewField + 1),
      ];

      setSalesConnectFieldList(fields);
    }

    onChange(inputValue, rowData);

    this.setState({
      inputValue: '',
      showInput: false,
    });
  }

  _keyPressed = (event) => {
    if (event.charCode === ENTER_KEY_CHARCODE) {
      event.preventDefault();
      this._saveNewFieldValue();
    }
  };

  _onInputBlure = () => {
    this._saveNewFieldValue();
  };

  _validateCustomField = (name) => {
    const { setPopupAlert } = this.props;

    if (!name.trim().match(Regex.customField)) {
      setPopupAlert(PeopleAlertIds.invalidCustomFieldNameError, { name });
      return false;
    }

    return true;
  };

  _onChangeInput = (event) => {
    const { value } = event.target;

    this.setState({
      inputValue: value,
    });
  };

  onChange = (value) => {
    const selectedValue = this.getSelectedValue();
    const { onChange, rowData } = this.props;

    value === ADD_NEW_FIELD
      ? this.setState({ showInput: true })
      : this.setState({ showInput: false });

    if (selectedValue !== value) {
      isFunction(onChange) && onChange(value, rowData);
    }
  };

  getSelectedValue() {
    const { rowData, propertyName } = this.props;

    return rowData[propertyName];
  }

  render() {
    const { inputValue, showInput } = this.state;
    const { items } = this.props;

    const selected = this.getSelectedValue();

    return (
      <div>
        <Select
          className="cell-select"
          items={items}
          selected={selected}
          onChange={this.onChange}
          rowsShown={8}
          isFixedPosition
        />

        {showInput && (
          <input
            name="new-field-input"
            type="text"
            className="new-field-input"
            onChange={this._onChangeInput}
            onKeyPress={this._keyPressed}
            onBlur={this._onInputBlure}
            value={inputValue}
            placeholder={this.inputPlaceHolder}
            autoFocus
          />
        )}
      </div>
    );
  }
}

MapFieldsCell.propTypes = {
  intl: intlShape.isRequired,
  rowData: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  propertyName: PropTypes.string.isRequired,
  setSalesConnectFieldList: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setPopupAlert: PropTypes.func.isRequired,
};

export default injectIntl(MapFieldsCell);
