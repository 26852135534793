import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import Action, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { Urls } from 'web/libs/routes';
import FormLayout from 'components/layouts/formLayout';
import FormTitle from 'components/layouts/formLayout/formTitle';
import FormButtonsArea from 'components/layouts/formLayout/formButtonsArea';
import './passwordRequestReceived.scss';

class PasswordRequestReceived extends Component {
  render() {
    const { forgotPasswordSentEmail } = this.props;

    return (
      <FormLayout className="password-request-received">
        <FormTitle id="login.forgotPassword.passwordRequestReceived.title" />
        <div className="message-panel">
          <FormattedHTMLMessage
            id="login.forgotPassword.passwordRequestReceived.body"
            values={{ forgotPasswordSentEmail }}
          />
        </div>
        <FormButtonsArea align="right" className="buttons-panel">
          <Link to={Urls.loginPage}>
            <Action color={ButtonColors.blue} size={ButtonSizes.medium}>
              <FormattedMessage id="forgotPassword.loginBack" />
            </Action>
          </Link>
        </FormButtonsArea>
      </FormLayout>
    );
  }
}

PasswordRequestReceived.propTypes = {
  forgotPasswordSentEmail: PropTypes.string.isRequired,
};

export default injectIntl(PasswordRequestReceived);
