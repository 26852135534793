import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CampaignTypes } from 'libs/constantsShared';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import './previewTaskMessage.scss';

class PreviewTaskMessage extends Component {
  render() {
    const instructionMessage = this.getInstructionMessage();
    const startMessage = this.getStartMessage();

    return (
      <div>
        {instructionMessage}
        <div className="margin-top-full text-overflow">
          <FormattedMessage id="campaigns.previewTask.hitStart" />
          {startMessage}
        </div>
      </div>
    );
  }

  getInstructionMessage = () => {
    const { campaignType } = this.props;

    switch (campaignType) {
      case CampaignTypes.marketo:
        return (
          <FormattedHTMLMessage id="campaigns.previewTask.marketoInstructions" />
        );
      case CampaignTypes.tout:
      default:
        return <FormattedHTMLMessage id="campaigns.previewTask.instructions" />;
    }
  };

  getStartMessage = () => {
    const {
      campaign: { name: campaignName },
    } = this.props;
    const startMessageClasses = classNames('preview-task-logo', {
      'preview-task-tout-logo': this.isToutCampaign(),
      'preview-task-marketo-logo': this.isMarketoCampaign(),
    });

    return <span className={startMessageClasses}>{campaignName}</span>;
  };

  isToutCampaign = () => this.props.campaignType === CampaignTypes.tout;

  isMarketoCampaign = () => this.props.campaignType === CampaignTypes.marketo;
}

PreviewTaskMessage.propTypes = {
  campaign: PropTypes.object,
  campaignType: PropTypes.string,
};

PreviewTaskMessage.defaultProps = {
  campaign: {},
  campaignType: CampaignTypes.tout,
};

export default PreviewTaskMessage;
