import React, { Fragment } from 'react';
import SalesforcePopupContainer from 'web/settings/accountSettings/salesforce/containers/salesforcePopupContainer';
import AlertContainer from 'web/view/containers/alertContainer';
import SalesforceAlerts from 'web/salesforce/components/salesforceAlerts';

const SalesforcePopupAlert = () => (
  <Fragment>
    <SalesforcePopupContainer />
    <AlertContainer>
      <SalesforceAlerts />
    </AlertContainer>
  </Fragment>
);

export default SalesforcePopupAlert;
