/*eslint no-use-before-define: 0 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import Card from 'components/card';
import './cards.scss';

class Cards extends PureComponent {
  render() {
    const {
      backgroundColor,
      cards,
      className,
      direction,
      fullWidth,
    } = this.props;
    const isHorizontal = direction === Direction.horizontal;
    const containerClassName = classNames('cards', className, {
      [`background-${backgroundColor}`]: backgroundColor,
    });
    const wrapperClassName = classNames('cards-wrapper', {
      'cards-horizontal': isHorizontal,
    });

    return (
      <div className={containerClassName}>
        <div className={wrapperClassName} style={this._getWrapperStyles()}>
          {cards
            .filter(({ children }) => children)
            .map(
              (
                { children, className: cardClassName, id, other = {}, style },
                index
              ) => {
                const currentCardClassName = classNames(
                  'cards-card',
                  cardClassName,
                  {
                    'cards-card-horizontal': isHorizontal,
                    'cards-card-full-width': fullWidth,
                  }
                );
                return (
                  <Card
                    className={currentCardClassName}
                    key={`cards-card-${id}`}
                    style={this._getCardStyles(
                      style,
                      index === cards.length - 1
                    )}
                    {...other}
                  >
                    {children}
                  </Card>
                );
              }
            )}
        </div>
      </div>
    );
  }

  _getWrapperStyles = () => {
    const { marginAround } = this.props;
    const styles = {
      padding:
        (typeof marginAround === 'string' && marginAround) ||
        `${marginAround}px`,
    };

    return styles;
  };

  _getCardStyles = (cardStyle = {}, isLast = false) => {
    const { direction, marginBetween, maxWidth, minWidth } = this.props;
    const styles = { ...cardStyle };

    if (!isLast) {
      styles[
        `margin${(direction === Direction.vertical && 'Bottom') || 'Right'}`
      ] = `${marginBetween}px`;
    }

    if (maxWidth) {
      styles.maxWidth = `${maxWidth}px`;
    }
    if (minWidth) {
      styles.minWidth = `${minWidth}px`;
    }

    return styles;
  };
}

export const Direction = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};

export const CardShape = PropTypes.shape({
  children: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  other: PropTypes.object,
});

Cards.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  cards: PropTypes.array,
  className: PropTypes.string,
  direction: PropTypes.oneOf(Object.values(Direction)),
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  marginAround: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBetween: PropTypes.number,
};

Cards.defaultProps = {
  backgroundColor: BackgroundColors.grayLight,
  cards: [],
  className: '',
  direction: Direction.vertical,
  fullWidth: true,
  maxWidth: 0,
  minWidth: 0,
  marginAround: 30, // could be number or '20px 10px'
  marginBetween: 20,
};
export default Cards;
