import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withReadMore from 'components/hocs/withReadMore';
import CatchATagClicks from '../catchATagClicks';
import './emailsReadMoreBody.scss';

class EmailsReadMoreBody extends PureComponent {
  render() {
    const { body, className } = this.props;

    return (
      <div className={classNames('emails-read-more-body', className)}>
        <CatchATagClicks text={body} />
      </div>
    );
  }
}

EmailsReadMoreBody.propTypes = {
  body: PropTypes.string,
  className: PropTypes.string,
};

EmailsReadMoreBody.defaultProps = {
  body: '',
  className: '',
};

export default withReadMore()(EmailsReadMoreBody);
