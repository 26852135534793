import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { moment } from 'languages';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import SlideOutPaneHeader from 'components/layouts/slideOutPane/header';
import PageTabNavBar from 'components/layouts/page/tabNavBar';
import PageTabNav from 'components/layouts/page/tabNav';
import PageView from 'components/layouts/page/view';
import { BackgroundColors } from 'libs/constantsStyles';
import { EditIdentityTabs } from '../../libs/editIdentitySlideOutConstants';
import EditIdentityAboutViewContainer from '../../containers/editIdentityAboutViewContainer';
import { getCreatedBy } from '../../helpers/editIdentityHelpers';
import './editIdentityTab.scss';

class EditIdentityTab extends Component {
  render() {
    const {
      onTabSelect,
      intl: { formatMessage },
      tab,
      identity,
    } = this.props;

    const tabList = [
      {
        title: formatMessage({ id: 'common.about' }),
        value: EditIdentityTabs.about,
      },
    ];

    return (
      <Page>
        <SlideOutPaneHeader
          className="identity-title"
          leftChildren={this.getIdentityTitle()}
          rightChildren={this.getIdentityInfo()}
          text={
            identity
              ? identity.name
              : formatMessage({
                  id: 'web.identities.editIdentityHeaderCreateNew',
                })
          }
        />
        <PageTabNavBar border>
          <PageTabNav
            list={tabList}
            onTabSelect={onTabSelect}
            selectedTabValue={tab}
          />
        </PageTabNavBar>
        <PageView
          backgroundColor={BackgroundColors.grayExtraLight}
          className="edit-identity-slide-out-page-view"
        >
          {this.getTabPageView()}
        </PageView>
      </Page>
    );
  }

  getIdentityInfo = () => {
    const {
      identity,
      user,
      intl: { formatMessage },
    } = this.props;

    if (!identity) {
      return null;
    }

    const createdBy = getCreatedBy(user, identity, formatMessage);

    return (
      <FormattedHTMLMessage
        id="web.identities.editIdentityHeaderInfoRight"
        values={{
          createdBy,
          createdFromNow: moment(identity.created_at).fromNow(),
        }}
      />
    );
  };

  getIdentityTitle = () => {
    const { identity } = this.props;
    return !identity ? null : <h5>{identity.title}</h5>;
  };

  getTabPageView = () => {
    const { tab } = this.props;

    switch (tab) {
      case EditIdentityTabs.about:
        return <EditIdentityAboutViewContainer />;
      default:
        return null;
    }
  };
}

EditIdentityTab.propTypes = {
  identity: PropTypes.object,
  intl: intlShape.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

EditIdentityTab.defaultProps = {
  identity: null,
};

export default injectIntl(EditIdentityTab);
