/*eslint id-length: 0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import SalesforceCustomizationWizardCommonStep from '../commonStep';
import {
  CUSTOMIZATION_WIZARD_ACCESS_TABLE_HEIGHT,
  CUSTOMIZATION_WIZARD_TABLE_ROW_HEIGHT,
  CUSTOMIZATION_WIZARD_TABLE_CELL_LINES,
  USERS_ADMINS_VALUE,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import Table from 'table/modules/table';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import SwitchCell from 'table/modules/cells/switchCell';
import ProfileAccessSwitchAll from '../profileAccessSwitchAll';
import { getImageUrl } from 'web/libs/constants';

class SalesforceCustomizationWizardProfileAccess extends Component {
  render() {
    const {
      className,
      intl: { formatMessage },
      profiles,
      loading,
      switchAllProfileAvailableUsers,
    } = this.props;
    return (
      <SalesforceCustomizationWizardCommonStep className={className}>
        <h3 className="margin-bottom-full">
          <FormattedMessage id="web.salesforceCustomization.wizard.items.profile.access.title" />
        </h3>

        <ProfileAccessSwitchAll
          profiles={profiles}
          switchAllProfileAvailableUsers={switchAllProfileAvailableUsers}
        />

        <Table
          maxHeight={CUSTOMIZATION_WIZARD_ACCESS_TABLE_HEIGHT}
          items={profiles.availableUsers}
          loading={loading}
          loadingGetUrl={getImageUrl}
          row={{
            height: CUSTOMIZATION_WIZARD_TABLE_ROW_HEIGHT,
          }}
          columns={[
            {
              id: 'title',
              name: formatMessage({
                id:
                  'web.salesforceCustomization.wizard.items.profile.access.table.profile',
              }),
              rowCell: {
                component: TextCustomCell,
                metadata: {
                  property: 'title',
                  className: 'no-word-break',
                  wrapLines: CUSTOMIZATION_WIZARD_TABLE_CELL_LINES,
                  formatText: ({ title }) => title,
                },
              },
            },
            {
              id: 'access',
              name: formatMessage({
                id:
                  'web.salesforceCustomization.wizard.items.profile.access.table.accessLevel',
              }),
              rowCell: {
                component: this.adminProfileAccessLevelCell(),
                metadata: {
                  property: 'isChecked',
                  className: 'profile-access-switch-cell',
                  wrapLines: CUSTOMIZATION_WIZARD_TABLE_CELL_LINES,
                  onChange: this._onSwitchToggle,
                },
              },
            },
          ]}
        />
      </SalesforceCustomizationWizardCommonStep>
    );
  }

  adminProfileAccessLevelCell = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (props) => {
      if (USERS_ADMINS_VALUE === props.rowData.title) {
        return (
          <TextCustomCell
            className="no-word-break"
            wrapLines={3}
            rowData={{
              id:
                'web.salesforceCustomization.wizard.items.profile.access.table.adminFullAccess',
            }}
            formatText={formatMessage}
          />
        );
      } else {
        return <SwitchCell {...props} />;
      }
    };
  };

  _onSwitchToggle = (rowData, value) => {
    const { updateProfileAvailableUsers } = this.props;

    updateProfileAvailableUsers(rowData, value);
  };
}

SalesforceCustomizationWizardProfileAccess.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  intl: intlShape.isRequired,
  profiles: PropTypes.object.isRequired,
  updateProfileAvailableUsers: PropTypes.func.isRequired,
  switchAllProfileAvailableUsers: PropTypes.func.isRequired,
};

SalesforceCustomizationWizardProfileAccess.defaultProps = {
  className: '',
  loading: true,
};

export default injectIntl(SalesforceCustomizationWizardProfileAccess);
