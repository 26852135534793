import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { initSidebarViewer } from 'web/sidebar/viewers/actionCreators/viewersActionCreators';
import { getEmails } from 'web/commandCenter/actionCreators/commandCenterEmailsFetchActionCreators';
import {
  setTab,
  setSubTab,
} from 'web/commandCenter/actionCreators/commandCenterEmailsActionCreators';
import {
  selectCommandCenterMenuItem,
  setViewAsToEmailGridAdvancedSearchFilter,
} from 'web/commandCenter/actionCreators/commandCenterSidebarActionCreators';
import { getGroups } from 'web/groups/actionCreators/groupsActionCreators';
import {
  CommandCenterEmailsTabsEnum,
  SidebarMenuItems,
} from 'web/commandCenter/libs/commandCenterConstants';
import { setTablePerPage } from 'web/commandCenter/actionCreators/commandCenterTableActionCreators';
import { tablePerPageFromStorage } from 'web/services/storageService';
import {
  resetEmailSelectedSavedAdvancedSearchId,
  lockResetEmailSelectedSavedAdvancedSearchId,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/openAdvancedSearchActionCreators';
import { initDateRangeSmartFilters } from 'web/tables/dateRangeSmartFilter/actionCreators/dateRangeSmartFilterActionCreators';
import { paginateEmailsPerPage } from 'web/commandCenter/actionCreators/commandCenterEmailsFetchActionCreators';
import {
  goToEmailGroup,
  setSelectedEmailId,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/emailGridAdvancedSearchActionCreators';
import { setGroupEmailsAsSingleRow } from 'web/commandCenter/actionCreators/commandCenterEmailsFetchActionCreators';
import { closePersonDetailsSlideOut } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';

export const emailsStartup = ({
  tab = CommandCenterEmailsTabsEnum.sent.value,
  subTab = Object.keys(CommandCenterEmailsTabsEnum[tab].subTabs || {})[0],
  param,
}) => (dispatch, getState) => {
  const { isFilterChanged, emailsSortByGroups } = getState();
  dispatch(closePersonDetailsSlideOut());
  dispatch(commonStartup());

  if (!isFilterChanged) {
    dispatch(initSidebarViewer());
  }

  if (
    subTab === CommandCenterEmailsTabsEnum.search.subTabs.selectedEmail.value
  ) {
    dispatch(setSelectedEmailId(param));
  }

  if (subTab !== CommandCenterEmailsTabsEnum.search.subTabs.saved.value) {
    dispatch(resetEmailSelectedSavedAdvancedSearchId());
  }

  if (subTab === CommandCenterEmailsTabsEnum.search.subTabs.groupEmail.value) {
    dispatch(setGroupEmailsAsSingleRow({ checked: false, disable: true }));
    dispatch(goToEmailGroup(param));
  } else {
    dispatch(
      setGroupEmailsAsSingleRow({
        checked: emailsSortByGroups.checked,
        disable: false,
      })
    );
  }

  dispatch(setTab(tab));
  dispatch(setSubTab(subTab));
  dispatch(setPerPageFromLocalStorage());
  dispatch(selectCommandCenterMenuItem(SidebarMenuItems.emails.id));
  dispatch(initDateRangeSmartFilters(tab, subTab));
  dispatch(getEmails());
  dispatch(getGroups());
  dispatch(lockResetEmailSelectedSavedAdvancedSearchId());
  dispatch(setViewAsToEmailGridAdvancedSearchFilter());
};

export const setPerPageFromLocalStorage = () => (dispatch) => {
  const perPageFromStorage = tablePerPageFromStorage({ withDefault: false });

  if (perPageFromStorage) {
    dispatch(paginateEmailsPerPage(perPageFromStorage));
    dispatch(setTablePerPage(perPageFromStorage));
  }
};
