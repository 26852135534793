import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './action.scss';

class Action extends Component {
  render() {
    const {
      active,
      children,
      classes,
      className,
      color,
      size,
      disabled,
      onClick: ignored1,
      ...other
    } = this.props;

    const componentClasses = classNames(
      classes,
      'tout-action',
      className,
      color,
      size,
      { active }
    );

    return (
      <button
        type="submit"
        {...other}
        className={componentClasses}
        onClick={this.onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }

  onClick = (e) => {
    e.target.blur();
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  };
}

export const ButtonColors = {
  blue: 'blue',
  purple: 'purple',
  default: '',
  gray: 'gray',
  green: 'green',
  red: 'red',
  white: 'white',
  whiteBlue: 'white-blue',
  whiteGreen: 'white-green',
};

export const ButtonSizes = {
  default: '',
  input: 'input',
  medium: 'medium',
  standard: 'standard',
};

Action.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  classes: PropTypes.string, // legacy
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(ButtonColors)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(ButtonSizes)),
};

Action.defaultProps = {
  active: false,
  classes: '',
  className: '',
  color: ButtonColors.blue,
  disabled: false,
  size: ButtonSizes.standard,
  onClick: null,
};

export default Action;
