import UnsubscribeOptionsActionTypes from '../libs/unsubscribeOptionsActionTypes';
import { UNSUBSCRIBE_REASONS_LANG_IDS } from '../libs/unsubscribeOptionsConstants';
import I18N from 'languages';

const initialState = [
  {
    show_to_user: true,
    identifier: 'default',
    title: I18N.getStr(UNSUBSCRIBE_REASONS_LANG_IDS.default),
  },
];

export const unsubscribeReasons = (
  state = initialState,
  { type = '', data = initialState }
) => {
  switch (type) {
    case UnsubscribeOptionsActionTypes.setUnsubscribeOptions:
      return [...state, ...data.unsubscribe_reasons];

    default:
      return state;
  }
};
