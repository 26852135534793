import {
  addPeopleAddGroup,
  addPeopleChangeGroup,
} from 'web/people/peopleSearchAdd/helpers/stateHelper';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import ActionTypes from 'web/libs/actionTypes/actionTypes';

export const initPeopleSearchAddState = {
  people: [],
  groupIndex: {},
  identity: 0,
  selectedIndex: {},
};
export function peopleSearchAddState(state = initPeopleSearchAddState, action) {
  const {
    checked,
    data = {},
    id: updatedIndexId,
    identity,
    people,
    type,
  } = action;
  const { id: dataId } = data || {};

  switch (type) {
    case PeopleActionTypes.peopleSearchAdd.setIdentity:
      return {
        ...state,
        identity,
      };
    case PeopleActionTypes.peopleSearchAdd.addPerson:
      return {
        ...state,
        people: [data, ...state.people],
        groupIndex: state.groupIndex,
        selectedIndex: { [dataId]: true, ...state.selectedIndex },
      };
    case PeopleActionTypes.peopleSearchAdd.addPeople: {
      const selectedPeopleIds = people.reduce(
        (accumulator, { id }) => ({ ...accumulator, [id]: true }),
        {}
      );

      return {
        ...state,
        people: [...state.people, ...people],
        selectedIndex: { ...state.selectedIndex, ...selectedPeopleIds },
      };
    }
    case PeopleActionTypes.peopleSearchAdd.addGroup:
      return addPeopleAddGroup(state, action);
    case PeopleActionTypes.peopleSearchAdd.updatePerson: {
      const newIndex = { ...state.selectedIndex };
      if (checked) {
        newIndex[updatedIndexId] = true;
      } else {
        Reflect.deleteProperty(newIndex, updatedIndexId);
      }
      return {
        ...state,
        people: state.people,
        groupIndex: state.groupIndex,
        selectedIndex: newIndex,
      };
    }
    case PeopleActionTypes.peopleSearchAdd.updateGroup:
      return addPeopleChangeGroup(state, action);
    case PopupActionTypes.close:
    case ActionTypes.modal.state.close:
      return initPeopleSearchAddState;
    default:
      return state;
  }
}
