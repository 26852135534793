/* eslint-disable camelcase */
import {
  saveAdminSettingsUnsubscribes,
  getAdminSettings as getAdminSettingsCall,
  saveUnsubscribeCustomDomain,
  savePluginUnsubscribe,
  saveAdminSettingsUnsubscribeAutoAppend,
} from 'web/settings/unsubscribes/services/settingsUnsubscribesService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import AdminUnsubscribesActionTypes from '../libs/adminUnsubscribesActionTypes';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import SettingsUnsubscribesConstants from 'web/settings/unsubscribes/libs/settingsUnsubscribesConstants';
import { getIntegration as getMarketoConnectionCall } from 'web/marketoSettings/services/integrationConnection';
import { SubscriptionPlans } from 'libs/constantsShared';
import { buildSettingsUnsubscribePayload } from '../helpers/adminUnsubscribeHelpers';

export const saveAdminUnsubscribes = (successAlertId) => (
  dispatch,
  getState
) => {
  const data = buildSettingsUnsubscribePayload(getState());

  toutBackboneHelper.updateAdminUnsubscribes(data);

  return saveAdminSettingsUnsubscribes(data)
    .then(() => {
      dispatch(openViewAlert(successAlertId));
    })
    .catch(() => {
      dispatch(
        openViewAlert(
          SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed
        )
      );
    });
};

export const saveAdminUnsubscribeAutoAppend = (successAlertId) => (
  dispatch,
  getState
) => {
  const data = buildSettingsUnsubscribePayload(getState());

  toutBackboneHelper.updateAdminUnsubscribeAutoAppend(data);

  return saveAdminSettingsUnsubscribeAutoAppend(data)
    .then(() => {
      dispatch(openViewAlert(successAlertId));
    })
    .catch(() => {
      dispatch(
        openViewAlert(
          SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed
        )
      );
    });
};

export const loadingAdminSettings = (loading) => ({
  type: AdminUnsubscribesActionTypes.loadAdminSettings,
  loading,
});

export const marketoCheckEnabled = (enabled) => ({
  type: AdminUnsubscribesActionTypes.updateMarketoCheck,
  enabled,
});

export const getUnsubscribeSettings = () => async (dispatch) => {
  try {
    dispatch(loadingUnsubscribeSettings(true));
    dispatch(loadingPluginUnsubscribe(true));
    const adminSettings = await getAdminSettingsCall();
    const unsubscribeDomain =
      adminSettings.data.unsubscribe_custom_domain || false;
    const unsubscribePluginValue =
      adminSettings.data.plugin_unsubscribe || false;
    dispatch(showUnsubscribeView(unsubscribeDomain));
    dispatch(showPluginUnsubscribe(unsubscribePluginValue));
  } catch {
    dispatch(showUnsubscribeView(false));
    dispatch(
      openViewAlert(
        SettingsUnsubscribesConstants.alertIds.fetchingAdminSettingsError
      )
    );
  }
  dispatch(loadingUnsubscribeSettings(false));
  dispatch(loadingPluginUnsubscribe(false));
};

export const loadingPluginUnsubscribe = (loading) => ({
  type: AdminUnsubscribesActionTypes.loadPluginSettings,
  loading,
});

export const showPluginUnsubscribe = (showPluginUnsubscribeMessage) => ({
  type: AdminUnsubscribesActionTypes.togglePluginUnsubscribe,
  showPluginUnsubscribeMessage,
});

export const updatePluginUnsubscribe = (showPluginUnsubscribeMessage) => async (
  dispatch
) => {
  const params = {
    data: { plugin_unsubscribe: showPluginUnsubscribeMessage },
  };

  try {
    dispatch(loadingPluginUnsubscribe(true));
    const response = await savePluginUnsubscribe(params);
    if (response !== SettingsUnsubscribesConstants.errors.unprocessableEntity) {
      dispatch(showPluginUnsubscribe(showPluginUnsubscribeMessage));
    } else {
      dispatch(
        openViewAlert(
          SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed
        )
      );
    }
  } catch {
    dispatch(
      openViewAlert(
        SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed
      )
    );
  } finally {
    dispatch(loadingPluginUnsubscribe(false));
  }
};

export const getAdminSettings = () => async (dispatch) => {
  try {
    dispatch(loadingAdminSettings(true));
    const adminSettings = await getAdminSettingsCall();
    dispatch(loadingAdminSettings(false));
    const {
      marketo_unsubscribe_check_enabled: marketoUnsubscribeCheckEnabled,
    } = adminSettings;
    dispatch(marketoCheckEnabled(marketoUnsubscribeCheckEnabled));
  } catch {
    dispatch(
      openViewAlert(
        SettingsUnsubscribesConstants.alertIds.fetchingAdminSettingsError
      )
    );
    dispatch(loadingAdminSettings(false));
  }
};

export const saveMarketoCheck = (enabled) => async (dispatch, getState) => {
  const {
    marketoCheckState,
    user: { subscription },
  } = getState();
  const { subscription_plan_slug: subscriptionPlan } = subscription;
  const params = { marketo_unsubscribe_check_enabled: enabled };

  try {
    dispatch(loadingAdminSettings(true));
    const marketoConnection = await getMarketoConnectionCall();
    if (marketoConnection && marketoConnection.length) {
      await saveAdminSettingsUnsubscribes(params);
      dispatch(marketoCheckEnabled(!marketoCheckState));
      dispatch(
        openViewAlert(
          SettingsUnsubscribesConstants.alertIds
            .saveUnsubscribeIntegrationsSuccess
        )
      );
    } else {
      switch (subscriptionPlan) {
        case SubscriptionPlans.premium:
        case SubscriptionPlans.standard:
          dispatch(
            openViewAlert(
              SettingsUnsubscribesConstants.alertIds
                .invalidMarketoStandardPremium
            )
          );
          break;
        default:
          dispatch(
            openViewAlert(
              SettingsUnsubscribesConstants.alertIds.invalidMarketoOtherPlans
            )
          );
      }
    }
    dispatch(loadingAdminSettings(false));
  } catch {
    dispatch(loadingAdminSettings(false));
    dispatch(marketoCheckEnabled(marketoCheckState));
    dispatch(
      openViewAlert(
        SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed
      )
    );
  }
};

export const changeTab = (tab) => ({
  type: AdminUnsubscribesActionTypes.changeTab,
  tab,
});

export const loadingUnsubscribeSettings = (loading) => ({
  type: AdminUnsubscribesActionTypes.loadDomainSettings,
  loading,
});

export const showUnsubscribeView = (showPage) => ({
  type: AdminUnsubscribesActionTypes.showUnsubscribeView,
  showPage,
});

export const changeShowUnsubscribe = (showPage) => async (dispatch) => {
  const params = {
    data: { unsubscribe_custom_domain: showPage },
  };
  try {
    dispatch(loadingUnsubscribeSettings(true));
    const response = await saveUnsubscribeCustomDomain(params);
    if (response !== SettingsUnsubscribesConstants.errors.unprocessableEntity) {
      dispatch(showUnsubscribeView(showPage));
    } else {
      dispatch(
        openViewAlert(
          SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed
        )
      );
    }
  } catch {
    dispatch(
      openViewAlert(
        SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed
      )
    );
  } finally {
    dispatch(loadingUnsubscribeSettings(false));
  }
};
