import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Label from 'components/label';

export default class SubjectField extends React.Component {
  saveSubject = (subject) => {
    this.props.updateSubject(subject);
  };

  handleChange = (e) => {
    this.saveSubject(e.target.value);
  };

  handleClick = () => {
    this.input.focus();
  };

  setRef = (input) => (this.input = input);

  render() {
    const labelClasses = classNames('compose-label', {
      error: this.props.errored,
    });

    return (
      <div className="compose-field" onClick={this.handleClick}>
        <Label
          className={labelClasses}
          id="label-text"
          messageKey="common.subject"
          sideLabel
        >
          <input
            disabled={this.props.disabled}
            onChange={this.handleChange}
            ref={this.setRef}
            type="text"
            value={this.props.subject}
          />
        </Label>
      </div>
    );
  }
}

SubjectField.propTypes = {
  disabled: PropTypes.bool,
  errored: PropTypes.bool,
  subject: PropTypes.string,
  updateSubject: PropTypes.func.isRequired,
};

SubjectField.defaultProps = {
  disabled: false,
  errored: false,
  subject: '',
};
