import ActionTypes from 'web/libs/actionTypes/actionTypes';
import apiHelper from 'web/services/apiHelper';
import { initPusherService } from 'web/services/pusherService';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  getUserSettings,
  setUser,
  setUserMetric,
} from 'web/user/actionCreators/userActionCreators';
import { initializeHealthStatuses } from 'web/settings/myProfile/components/yourIntegrations/actionCreators/yourIntegrationsActionCreators';
import { displayBrokenSfdcConnectionBanner } from 'web/salesforce/actionCreators/salesforceActionCreators';
import { shouldDisplayBrokenSfdcConnectionBanner } from 'web/salesforce/selectors/salesforceSelectors';

// still need bootstrapping as opposed to service b/c need user id
function setUserData() {
  return async (dispatch) => {
    // Bootstrap data
    if (window.toutAppBootstrap) {
      if (window.toutAppBootstrap.userMetricsData) {
        dispatch(setUserMetric(window.toutAppBootstrap.userMetricsData));
      }
      if (window.toutAppBootstrap.accountData) {
        dispatch(setUser(window.toutAppBootstrap.accountData));
        await dispatch(getUserSettings());
      }
    }
  };
}

function setCsrfToken() {
  return (dispatch, getState) => {
    if (
      !getState().initStore &&
      document.querySelector('meta[name="csrf-token"]')
    ) {
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');
      if (csrfToken) {
        apiHelper.setCsrfToken(csrfToken);
      }
    }
  };
}

export function commonStartup() {
  return async (dispatch, getState) => {
    // reset any previous states
    dispatch(closePopup());
    dispatch({ type: ActionTypes.modal.state.close });

    if (!getState().initStore) {
      dispatch(setCsrfToken());
      if (
        window.toutAppBootstrap &&
        window.toutAppBootstrap.accountData &&
        window.toutAppBootstrap.accountData.subscription
      ) {
        initPusherService(
          window.toutAppBootstrap.accountData.id,
          window.toutAppBootstrap.accountData.subscription.id
        );
      }
      await Promise.all([
        dispatch(setUserData()),
        dispatch(initializeHealthStatuses()),
      ]);
      dispatch({ type: ActionTypes.common.state.initStore });

      if (shouldDisplayBrokenSfdcConnectionBanner(getState())) {
        dispatch(displayBrokenSfdcConnectionBanner());
      }
    }
  };
}
