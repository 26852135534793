import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NonHoverTooltip from '../components/nonHoverTooltip';
import { connect } from 'react-redux';
import { toggleVisibility } from '../actionCreators/actionCreators';

class NonHoverTooltipContainer extends Component {
  constructor(props) {
    super(props);
    this.actionCreators = {
      toggleVisibility: (visible) => props.dispatch(toggleVisibility(visible)),
    };
  }

  render() {
    const { position, text, shown } = this.props;
    if (!shown) {
      return null;
    }
    return (
      <NonHoverTooltip
        position={position}
        text={text}
        actionCreators={this.actionCreators}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  position: state.nonHoverTooltipPosition,
  shown: state.nonHoverTooltipShown,
  text: state.nonHoverTooltipText,
});

NonHoverTooltipContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  position: PropTypes.object.isRequired,
  shown: PropTypes.bool.isRequired,
  text: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(NonHoverTooltipContainer);
