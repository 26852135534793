import React, { Component } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Popup from 'components/popups/popup';
import Checkbox from 'components/buttons/checkbox';
import { PopupSize } from 'libs/constantsShared';

class SalesforceCustomizationPreUninstallPopup extends Component {
  constructor() {
    super();

    this.state = {
      isUninstallApproved: false,
    };
  }

  componentWillMount() {
    const { actionCreators } = this.props;

    actionCreators.setDefaultProcessValues();
  }

  render() {
    const {
      actionCreators,
      intl: { formatMessage },
    } = this.props;
    const { isUninstallApproved } = this.state;

    return (
      <Popup
        className="salesforce-customization-popup"
        footer={{
          onPrimary: actionCreators.uninstall,
          onSecondary: actionCreators.closePopup,
          primaryDisabled: !isUninstallApproved,
          primaryTextId: 'common.uninstall',
          secondaryTextId: 'common.cancel',
        }}
        header={{
          onClose: actionCreators.closePopup,
          textId: 'web.salesforceCustomization.popup.uninstallation.title',
        }}
        onOutsideClick={actionCreators.closePopup}
        size={PopupSize.small}
      >
        <div className="margin-bottom-full">
          <div className="tout-icon-warning" />
          <FormattedHTMLMessage id="web.salesforceCustomization.popup.preUninstall.content" />
          <Checkbox
            checked={isUninstallApproved}
            classes="uninstallation-approve-checkbox"
            onChange={this.uninstallApprovedToggle}
            label={formatMessage({
              id: 'web.salesforceCustomization.popup.preUninstall.checkboxText',
            })}
          />
        </div>
      </Popup>
    );
  }

  uninstallApprovedToggle = (isChecked) => {
    this.setState({
      isUninstallApproved: isChecked,
    });
  };
}

SalesforceCustomizationPreUninstallPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceCustomizationPreUninstallPopup);
