import React from 'react';
import Checkbox from 'components/buttons/checkbox';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import './clearEditsModal.scss';

const ClearEditsModal = ({
  intl: { formatMessage },
  isDisableChecked,
  setDisableChecked,
  messageId,
}) => (
  <div className="clear-edits-modal">
    <div className="clear-edits-message">
      <div className="tout-icon-warning" />
      <FormattedMessage id={messageId} />
    </div>
    <Checkbox
      checked={isDisableChecked}
      label={formatMessage({
        id: 'web.composeWindow.clearEditsModal.dontShow',
      })}
      onChange={setDisableChecked}
    />
  </div>
);

ClearEditsModal.propTypes = {
  intl: intlShape.isRequired,
  isDisableChecked: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  setDisableChecked: PropTypes.func.isRequired,
};

export default injectIntl(ClearEditsModal);
