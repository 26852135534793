/* eslint-disable no-return-assign, react/no-array-index-key, react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import './select.scss';

class Select extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      open: false,
      highlightIndex: -1,
      selected: props.selected,
      propItems: props.items,
      dropdownContainerTop: undefined,
      dropdownContainerWidth: undefined,
    };

    this._selectRef = null;
    this._selectULRef = null;
    this._selectLIRefs = {};
  }

  componentDidMount() {
    const parsedItems = Select.parseItems(this.props.items);
    this.setState({
      highlightIndex: Number.isInteger(this.props.selected)
        ? parsedItems.findIndex((item) => item.value === this.props.selected)
        : -1,
      items: parsedItems,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this._handleOutsideClick, false);
    window.removeEventListener('keydown', this._handleKeyDown, false);
    window.removeEventListener('wheel', this._handleWheel, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { selected, propItems: items } = prevState;
    if (!isEqual(nextProps.items, items)) {
      const nextItems = Select.parseItems(nextProps.items);
      return {
        propItems: nextProps.items,
        items: nextItems,
        highlightIndex:
          Number.isInteger(nextProps.selected) > -1
            ? nextItems.findIndex((item) => item.value === nextProps.selected)
            : -1,
      };
    } else if (selected !== nextProps.selected) {
      return {
        selected: nextProps.selected,
        highlightIndex: Number.isInteger(nextProps.selected)
          ? this.state.items.findIndex(
              (item) => item.value === nextProps.selected
            )
          : -1,
      };
    }

    return null;
  }

  isSelectBold = () => {
    const { selected } = this.props;
    const { items, open } = this.state;

    if (items && selected && !open) {
      const selectedItem = items.find((it) => it.value === selected);
      return selectedItem && selectedItem.boldSelect;
    }
    return false;
  };

  render() {
    const { className, disabled, full, selected, isFixedPosition } = this.props;
    const { items, open } = this.state;

    const selectClass = classNames(
      'tout-select',
      className,
      { full },
      { 'tout-select-bold': this.isSelectBold() }
    );

    const dropdownClass = classNames('tout-select-dropdown', {
      'tout-select-fixed': isFixedPosition,
      'tout-select-relative': !isFixedPosition,
    });

    const chevronClass = classNames('chevron', { bottom: !open });
    const displayClass = classNames('tout-select-display', {
      disabled,
      open,
    });
    const selectedObject = items.find((item) => item.value === selected) ||
      items[0] || { label: '' };
    return (
      <div className={selectClass} ref={this._setSelectRef}>
        <div className={displayClass} onClick={this._toggleOpen}>
          <span className="tout-select-text">{selectedObject.label}</span>
          <span className={chevronClass} />
        </div>
        {open ? (
          <div className={dropdownClass} style={this._getDropdownStyle()}>
            <ul className="items-list" ref={this._setSelectULRef}>
              {this._renderItems(items)}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }

  _setSelectRef = (div) => (this._selectRef = div);

  _setSelectULRef = (div) => (this._selectULRef = div);

  _setSelectLIRef = (div) => {
    if (div) {
      this._selectLIRefs[div.dataset.index] = div;
    }
  };

  static parseItems(items = []) {
    if (Array.isArray(items[0])) {
      let complete = [];
      items.forEach((array, index) => {
        if (index > 0) {
          complete.push({ separator: true });
        }
        complete = complete.concat(array);
      });
      return complete;
    }
    return items;
  }

  _renderItems = (array) => {
    const { highlightIndex } = this.state;
    return array.map(
      ({ disabled, label, separator, value, className }, index) => {
        const allClassNames = classNames(
          'item',
          'text-overflow',
          'dropdown-selector-item',
          {
            highlight: index === highlightIndex,
            disabled,
          },
          className
        );
        if (separator) {
          return <li key={`separator-${index}`} className="separator" />;
        }
        return (
          <li
            key={`dropdown-item-${index}`}
            className={allClassNames}
            data-value={value}
            data-index={index}
            ref={this._setSelectLIRef}
            onMouseEnter={!disabled ? this._handleMouseEnter : undefined}
            onClick={!disabled ? this._handleItemClick : undefined}
          >
            {label}
          </li>
        );
      }
    );
  };

  _getDropdownStyle() {
    const { rowsShown, isFixedPosition } = this.props;
    const { items, dropdownContainerTop, dropdownContainerWidth } = this.state;
    const rowsHeight = 24 * rowsShown + 13; // (l-h * rows) + (l-h / 2 + border-top); line-height: 20px + 2px + 2px = 24; half added to show extra is scrollable
    const itemsHeight = 24 * items.length + 1;

    return {
      height: `${Math.min(rowsHeight, itemsHeight)}px`,
      ...(isFixedPosition
        ? {
            top: dropdownContainerTop,
            width: dropdownContainerWidth,
          }
        : {}),
    };
  }

  _toggleOpen = () => {
    //todo search highlight current? or blank?
    if (!this.props.disabled) {
      this.setState(
        (prevState) => ({
          open: !prevState.open,
          dropdownContainerTop: this._getDropdownContainerTop(),
          dropdownContainerWidth: this._getDropdownContainerWidth(),
        }),
        this._onOpenStateUpdated
      );
    }
  };

  _onOpenStateUpdated = () => {
    this._eventListeners();
    this._scrollToSelected();
  };

  _getDropdownContainerTop = () => {
    if (this._selectRef) {
      const rect = this._selectRef.getClientRects();

      return `${parseInt(rect[0].bottom, 10)}px`;
    }

    return 'auto';
  };

  _getDropdownContainerWidth = () => {
    if (this._selectRef) {
      const computedStyle = getComputedStyle(this._selectRef);
      const width =
        this._selectRef.offsetWidth -
        parseFloat(computedStyle.paddingLeft) -
        parseFloat(computedStyle.paddingRight);

      return `${parseInt(width, 10)}px`;
    }

    return 'auto';
  };

  _scrollToSelected = () => {
    // scroll to selected on opening list
    const { open, highlightIndex } = this.state;

    if (open && highlightIndex > -1 && this._selectULRef) {
      this._selectULRef.scrollTop = this._selectLIRefs[
        highlightIndex
      ].offsetTop;
    }
  };

  _eventListeners = () => {
    const { open } = this.state;
    const { isFixedPosition } = this.props;

    if (open) {
      window.addEventListener('mousedown', this._handleOutsideClick, false);
      window.addEventListener('keydown', this._handleKeyDown, false);
      isFixedPosition &&
        window.addEventListener('wheel', this._handleWheel, { passive: false });
    } else {
      window.removeEventListener('mousedown', this._handleOutsideClick, false);
      window.removeEventListener('keydown', this._handleKeyDown, false);
      window.removeEventListener('wheel', this._handleWheel);
    }
  };

  _handleWheel = (e) => {
    if (!e.target.className.includes('dropdown-selector-item')) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      e.returnValue = false;
    }
  };

  _handleOutsideClick = (e) => {
    if (this._selectRef && !this._selectRef.contains(e.target)) {
      this._toggleOpen();
    }
  };

  _handleItemClick = (e) => {
    // set search field
    this.props.onChange(e.target.dataset.value);
    this._toggleOpen();
  };

  _handleMouseEnter = (e) => {
    this.setState({
      highlightIndex: parseInt(e.target.dataset.index, 10),
    });
  };

  _handleKeyDown = (e) => {
    const { onChange } = this.props;
    const { highlightIndex, items } = this.state;

    e.preventDefault();

    if (e.keyCode === 38) {
      // up
      this._increment(false, highlightIndex, items.length);
    } else if (e.keyCode === 40) {
      // down
      this._increment(true, highlightIndex, items.length);
    } else if (e.keyCode === 9 || e.keyCode === 13) {
      // tab or return
      const value = items[highlightIndex] ? items[highlightIndex].value : '';
      onChange(value);
      this._toggleOpen();
    }
  };

  _increment = (down, index, length) => {
    const direction = down ? 1 : -1;
    let next = index + direction;
    if (next >= length) {
      next = 0;
    } else if (next < 0) {
      next = length - 1;
    }

    this.setState({
      highlightIndex: next,
    });
  };
}

Select.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  full: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  rowsShown: PropTypes.number,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFixedPosition: PropTypes.bool,
};

Select.defaultProps = {
  className: '',
  disabled: false,
  full: false,
  rowsShown: 4,
  selected: 0,
  isFixedPosition: false,
};

export default Select;
