import LiveFeedActionTypes from '../libs/liveFeedActionTypes';
import {
  formatToutAnalytics,
  parseEvent,
  parsePitch,
} from 'web/liveFeed/helpers/liveFeedParsers';
import { getLiveFeedAnalytics as getLiveFeedAnalyticsCall } from 'web/liveFeed/services/liveFeedService';
import {
  DEAL_ALERT_TIMEOUT,
  ToutEvents,
} from 'web/liveFeed/libs/liveFeedConstants';

export const updateChartEvent = (event) => ({
  type: LiveFeedActionTypes.chartEvent,
  event,
});

export const getLiveFeedAnalytics = () => (dispatch) => {
  getLiveFeedAnalyticsCall()
    .then((analytics) => {
      dispatch({
        type: LiveFeedActionTypes.analytics,
        analytics: formatToutAnalytics(analytics),
      });
      dispatch(updateChartEvent(ToutEvents.open));
    })
    .catch(() => {
      dispatch({
        type: LiveFeedActionTypes.analytics,
      });
    });
};

export const updateEventsAnalytics = (event) => (dispatch) => {
  dispatch({
    type: LiveFeedActionTypes.updateEventsAnalytics,
    analytics: parseEvent(event),
  });
};

export const updatePitchAnalytics = (pitch) => (dispatch) => {
  dispatch({
    type: LiveFeedActionTypes.updatePitchAnalytics,
    analytics: parsePitch(pitch),
  });
};

export const updateDealAlert = ({ meta }) => (dispatch) => {
  dispatch({
    type: LiveFeedActionTypes.updateDealAlert,
    dealInformation: meta,
  });

  setTimeout(() => {
    dispatch({
      type: LiveFeedActionTypes.updateDealAlert,
      dealInformation: {},
    });
  }, DEAL_ALERT_TIMEOUT);
};
