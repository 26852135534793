import { BackgroundColors } from 'libs/constantsStyles';
import { GeneralPageAlertIds } from 'web/settings/adminSettings/general/libs/generalPageConstants';

export function getAlert({ alert }, { clearAlert }) {
  const defaultAlert = {
    color: BackgroundColors.red,
    onClose: clearAlert,
  };

  switch (alert) {
    case GeneralPageAlertIds.fileUploadError:
      return {
        ...defaultAlert,
        textId: 'web.recordingNotice.fileUploadError',
      };
    case GeneralPageAlertIds.fileUploadBigSizeError:
      return {
        ...defaultAlert,
        textId: 'web.recordingNotice.fileUploadBigSizeError',
      };
    case GeneralPageAlertIds.disableConsentMessageError:
      return {
        ...defaultAlert,
        textId: 'web.recordingNotice.disableConsentMessageError',
      };
    case GeneralPageAlertIds.enableConsentMessageError:
      return {
        ...defaultAlert,
        textId: 'web.recordingNotice.enableConsentMessageError',
      };
    default:
      return null;
  }
}
