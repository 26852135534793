import React from 'react';
import { connect } from 'react-redux';
import SyncSettings from '../components/syncSettings';
import {
  getApiCallsStatsFetching,
  getIntegrationFetching,
  getStatsOccupancyPercent,
  getApiCallsStats,
  getIntegration,
  getContactIntegrationForSalesforce,
  isSyncViaApi,
  isApplyTeamSettingsChecked,
} from 'web/salesforce/selectors/salesforceSelectors';
import {
  saveSyncTasksForm,
  saveApiForm,
  saveBccForm,
  openActivateApiConfirmationPopup,
  openActivateBccConfirmationPopup,
} from 'web/salesforce/actionCreators/salesforceActionCreators';
import { bindActionCreators } from 'redux';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { getLoading } from '../selectors/syncSettingsSelectors';
import { isDirty, isPristine } from 'redux-form';
import {
  LOG_EMAIL_ACTIVITY_FORM_ADMIN_ID,
  LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID,
  SYNC_TASKS_FORM_ADMIN_ID,
  SYNC_TASKS_FORM_NON_ADMIN_ID,
} from 'web/salesforce/libs/salesforceConstants';

const SyncSettingsContainer = (props) => <SyncSettings {...props} />;

const mapStateToProps = (state) => ({
  statsOccupancyPercent: getStatsOccupancyPercent(state),
  isApiCallsStatsFetching: getApiCallsStatsFetching(state),
  isIntegrationFetching: getIntegrationFetching(state),
  stats: getApiCallsStats(state),
  salesforceIntegration: getIntegration(state),
  contactIntegrationForSalesforce: getContactIntegrationForSalesforce(state),
  isApplyTeamSettings: isApplyTeamSettingsChecked(state),
  isSafeToChangeRoute:
    isPristine(LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID)(state) &&
    isPristine(SYNC_TASKS_FORM_NON_ADMIN_ID)(state),
  isDirtyLogEmailFormNonAdmin: isDirty(LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID)(
    state
  ),
  isDirtySyncTasksFormNonAdmin: isDirty(SYNC_TASKS_FORM_NON_ADMIN_ID)(state),
  isDirtyLogEmailFormAdmin: isDirty(LOG_EMAIL_ACTIVITY_FORM_ADMIN_ID)(state),
  isDirtySyncTasksFormAdmin: isDirty(SYNC_TASKS_FORM_ADMIN_ID)(state),
  loading: getLoading(state),
  isSyncViaApi: isSyncViaApi(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      saveBccForm,
      openPopup,
      saveSyncTasksForm,
      saveApiForm,
      openActivateApiConfirmationPopup,
      openActivateBccConfirmationPopup,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncSettingsContainer);
