import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextCell from 'table/modules/cells/textCell';
import bulkHoverTooltip from 'web/composeWindow/hoc/bulkHoverTooltip';
import { hasUndeliverableStates } from 'web/composeWindow/helpers/composeWindowHelpers';
import './emailTextCell.scss';

const EmailTextCell = (props) => {
  const {
    rowData: { state },
  } = props;

  const classes = classNames('email-text-cell', {
    error: hasUndeliverableStates(state),
  });

  return (
    <div className={classes}>
      <TextCell {...props} />
    </div>
  );
};

EmailTextCell.propTypes = {
  state: PropTypes.string,
};

EmailTextCell.defaultProps = {
  state: '',
};

export default bulkHoverTooltip(EmailTextCell);
