import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { getPersonName } from '../helpers/personHelpers';
import { updatePersonUnsubscribeReason } from '../actionCreators/personDetailsPopupActionCreators';
import PersonDetailsUnsubscribeReason from '../components/personDetailsUnsubscribeReason';

const PersonDetailsUnsubscribePopupContainer = ({ onChange, person, user }) => (
  <div className="person-details-unsubscribe-popup">
    <FormattedHTMLMessage
      id="web.person.personDetails.popup.unsubscribe.body"
      values={{ name: getPersonName(person) }}
    />
    <PersonDetailsUnsubscribeReason onChange={onChange} />
  </div>
);

PersonDetailsUnsubscribePopupContainer.propTypes = {
  onChange: PropTypes.func,
  person: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  onChange: updatePersonUnsubscribeReason,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonDetailsUnsubscribePopupContainer);
