import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Popup from 'components/popups';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { clearPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import * as PeopleSourceActionCreators from 'web/modals/peopleSource/actionCreators/peopleSourceActionCreators';
import PeopleSourceModal from 'web/modals/peopleSource/components/peopleSourceModal/peopleSourceModal';
import { getPopupAlert } from 'web/modals/peopleSource/helpers/peopleSourceHelper';
import {
  ModalComponentIds,
  PEOPLE_SOURCE_POPUP_ID,
} from 'web/compliance/libs/complianceConstants';
import ComplianceSuccess from 'web/compliance/components/complianceSuccess/complianceSuccess';
import { isOtherSourceType } from 'web/compliance/helpers/complianceHelpers';

class PeopleSourceContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          ...PeopleSourceActionCreators,
          clearPopupAlert,
        },
        dispatch
      ),
    };
  }

  render() {
    const { popup } = this.props;
    const { children, ...other } = this._getMainComponent();

    return (
      <div>
        {popup === PEOPLE_SOURCE_POPUP_ID && (
          <Popup {...other}>{children}</Popup>
        )}
      </div>
    );
  }

  _getMainComponent = () => {
    const {
      alert,
      intl: { formatMessage },
      modalState,
      popupData: { peopleIds = [] },
      popupLoading,
    } = this.props;

    switch (modalState) {
      case ModalComponentIds.peopleSourceModal: {
        return {
          children: (
            <PeopleSourceModal
              actionCreators={this.actionCreators}
              {...this.props}
            />
          ),
          header: this._getMainHeader(peopleIds.length),
          footer: this._getMainFooter(),
          alert: getPopupAlert(alert, this.actionCreators.clearPopupAlert),
          onOutsideClick: this.actionCreators.closeSourceModal,
          getImageUrl,
          loading: popupLoading,
        };
      }
      case ModalComponentIds.peopleSourceModalSuccess: {
        return {
          children: (
            <ComplianceSuccess
              count={peopleIds.length}
              typeText={formatMessage({ id: 'common.source' })}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.closeSourceModal,
            primaryTextId: 'common.ok',
          },
          header: {
            textId: 'web.people.compliance.modal.sourceSuccessful',
          },
          onOutsideClick: this.actionCreators.closeSourceModal,
        };
      }
      default:
        return {};
    }
  };

  _getMainHeader = (count) => ({
    onClose: this.actionCreators.closeSourceModal,
    textId: 'web.people.compliance.modal.sourceHeader',
    textValues: { count },
  });

  _getMainFooter = () => ({
    onPrimary: this.actionCreators.savePeoplesSource,
    onSecondary: this.actionCreators.closeSourceModal,
    primaryDisabled: this._isDisabled(),
    primaryTextId: 'common.save',
    secondaryTextId: 'common.cancel',
  });

  _isDisabled = () => {
    const {
      selectedPeopleSource: { otherSourceValue, sourceType } = {},
    } = this.props;
    if (isOtherSourceType(sourceType)) {
      return !otherSourceValue.trim();
    } else {
      return false;
    }
  };
}

PeopleSourceContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  modalState: PropTypes.string,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object.isRequired,
  peopleIds: PropTypes.array,
  popupLoading: PropTypes.bool,
  selectedPeopleSource: PropTypes.object,
};

PeopleSourceContainer.defaultProps = {
  peopleIds: [],
  popupLoading: false,
};

const mapStateToProps = (state) => ({
  alert: state.popupAlert,
  popup: state.popup,
  popupLoading: state.popupLoading,
  popupData: state.popupData,
  selectedPeopleSource: state.selectedPeopleSource,
  modalState: state.peopleSourceModalState,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(PeopleSourceContainer);
