import SharingSettingsActionTypes from '../libs/sharingSettingsActionTypes';
import { TabValues } from '../libs/sharingSettingsConstants';
import uniqBy from 'lodash/uniqBy';

export function sharingSettingsInitStore(state = false, { type = '' }) {
  switch (type) {
    case SharingSettingsActionTypes.initStore:
      return true;
    default:
      return state;
  }
}

export function settingsSharingAlert(state = '', { type = '', alert = '' }) {
  switch (type) {
    case SharingSettingsActionTypes.setAlert:
      return alert;
    case SharingSettingsActionTypes.unmount:
      return '';
    default:
      return state;
  }
}

const initCategories = [];
export function settingsSharingCategories(
  state = initCategories,
  { type = '', categories = [] }
) {
  switch (type) {
    case SharingSettingsActionTypes.categories:
      return categories;
    case SharingSettingsActionTypes.unmount:
      return initCategories;
    default:
      return state;
  }
}

const defaultCategoryIndex = -1;
export function settingsSharingSelectedCategoryIndex(
  state = defaultCategoryIndex,
  { type = '', index = defaultCategoryIndex }
) {
  switch (type) {
    case SharingSettingsActionTypes.setSelectedCategory:
      return index;
    case SharingSettingsActionTypes.unmount:
      return defaultCategoryIndex;
    default:
      return state;
  }
}

export const defaultTab = TabValues.templates;
export function settingsSharingSelectedTabValue(
  state = defaultTab,
  { type = '', tab = defaultTab }
) {
  switch (type) {
    case SharingSettingsActionTypes.setTab:
      return tab;
    case SharingSettingsActionTypes.unmount:
      return defaultTab;
    default:
      return state;
  }
}

export function settingsSharingSidebarLoading(
  state = false,
  { type = '', loading = false }
) {
  switch (type) {
    case SharingSettingsActionTypes.setSidebarLoading:
      return loading;
    case SharingSettingsActionTypes.unmount:
      return false;
    default:
      return state;
  }
}

export function settingsSharingTableLoading(
  state = false,
  { type = '', loading = false }
) {
  switch (type) {
    case SharingSettingsActionTypes.setTableLoading:
      return loading;
    case SharingSettingsActionTypes.unmount:
      return false;
    default:
      return state;
  }
}

export function settingsSharingTableFetching(
  state = false,
  { type = '', fetching = false }
) {
  switch (type) {
    case SharingSettingsActionTypes.setTableFetching:
      return fetching;
    case SharingSettingsActionTypes.unmount:
      return false;
    default:
      return state;
  }
}

const initTeams = [];
export function settingsSharingTeams(
  state = initTeams,
  { type = '', teams = [], teamIds = [] }
) {
  switch (type) {
    case SharingSettingsActionTypes.teams:
      return teams;
    case SharingSettingsActionTypes.addTeams:
      return state.concat(teams);
    case SharingSettingsActionTypes.removeTeams: {
      const removedIds = {};
      teamIds.forEach((id) => (removedIds[id] = true));
      return state.filter(({ who: { id } }) => !removedIds[id]);
    }
    case SharingSettingsActionTypes.updateTeams:
      return uniqBy(teams.concat(state), ({ who: { id } }) => id);
    case SharingSettingsActionTypes.unmount:
      return initTeams;
    default:
      return state;
  }
}

export function settingsSharingPermissionsEnabled(
  state = false,
  { type = '', enabled = true }
) {
  switch (type) {
    case SharingSettingsActionTypes.setPermissionsEnabled:
      return enabled;
    default:
      return state;
  }
}
