export default {
  auth: {
    clear: 'EMAIL_CONNECTION_AUTH_CLEAR',
    set: 'EMAIL_CONNECTION_AUTH_SET',
  },
  fetched: 'EMAIL_CONNECTION_FETCHED',
  fetching: 'EMAIL_CONNECTION_FETCHING',
  modal: {
    clear: 'EMAIL_CONNECTION_MODAL_CLEAR',
    set: 'EMAIL_CONNECTION_MODAL_SET',
  },
  onpremForm: {
    set: 'EMAIL_CONNECTION_ONPREM_FORM_SET',
    reset: 'EMAIL_CONNECTION_ONPREM_FORM_RESET',
  },
  provider: {
    reset: 'EMAIL_CONNECTION_PROVIDER_RESET',
    set: 'EMAIL_CONNECTION_PROVIDER_SET',
  },
  savingChanges: 'EMAIL_CONNECTION_SAVING_CHANGES',
  isDisconnecting: 'EMAIL_CONNECTION_IS_DISCONNECTING',
};
