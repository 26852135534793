import { createAction } from '@reduxjs/toolkit';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { GeneralPageAlertIds } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import { updateTeamSettings } from 'web/teamSettings/actionCreators/teamSettingsActionCreators';

import { EMAIL_THROTTLING_SOURCE_TYPES } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import {
  getEmailThrottlingSettings as getEmailThrottlingSettingsCall,
  updateEmailThrottlingSettings as updateEmailThrottlingSettingsCall,
  createEmailThrottlingSettings as createEmailThrottlingSettingsCall,
} from 'web/settings/adminSettings/general/services/emailThrottlingSettingsService';

export const updateTeamEmailLimit = (teamSettings) => (dispatch) =>
  dispatch(updateTeamSettings(teamSettings))
    .then(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesSaved));
    })
    .catch(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesNotSaved));
    });

export const setEmailThrottlingLimits = createAction(
  'SET_EMAIL_THROTTLING_LIMITS'
);

export const updateEmailThrottlingSettings = (params) => (
  dispatch,
  getState
) => {
  const {
    user: {
      subscription: { id: subscriptionId },
    },
  } = getState();
  const updateCall = params._id
    ? updateEmailThrottlingSettingsCall
    : createEmailThrottlingSettingsCall;
  return updateCall({
    ...params,
    source: params.isAutoUpdate
      ? EMAIL_THROTTLING_SOURCE_TYPES.auto
      : EMAIL_THROTTLING_SOURCE_TYPES.manual,
    subscriptionId,
  })
    .then(({ response }) => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesSaved));
      dispatch(setEmailThrottlingLimits(response));
    })
    .catch(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesNotSaved));
    });
};

export const getEmailThrottlingSettings = () => (dispatch, getState) => {
  const {
    user: {
      subscription: { id: subscriptionId },
    },
  } = getState();
  return getEmailThrottlingSettingsCall({ subscriptionId }).then(
    ({ response }) => {
      dispatch(setEmailThrottlingLimits(response));
    }
  );
};
