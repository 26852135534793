import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextColors } from 'libs/constantsStyles';
import './commandCenterStat.scss';

class CommandCenterStat extends PureComponent {
  render() {
    const { color, count, textId, width } = this.props;
    const textColor = `text-${color}`;

    return (
      <div className="command-center-stat" style={{ width }}>
        <div>
          <h1 className={textColor}>{count}</h1>
          <FormattedMessage id={textId} />
        </div>
      </div>
    );
  }
}

CommandCenterStat.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
};

CommandCenterStat.defaultProps = {
  color: TextColors.blue,
};

export default CommandCenterStat;
