import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
  intlShape,
  FormattedHTMLMessage,
  FormattedMessage,
} from 'react-intl';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import Select from 'components/selects/base';
import Text from 'components/inputs/text';
import { Selects } from 'libs/constantsShared';
import {
  localizeDynamicFieldsMap,
  localizeCategoryMap,
} from 'web/dynamicFields/helpers/dynamicFieldsParsers';
import './dynamicFields.scss';
import I18N from 'languages';

class DynamicFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: this._pluckCategories(props.fields),
      category: props.selectedDynamicFieldsCategory,
      searchText: '',
    };
  }

  render() {
    const {
      fields,
      intl: { formatMessage },
    } = this.props;
    const { categories, category, searchText } = this.state;

    const filteredFields = this._filterFields(fields, category, searchText);

    return (
      <div className="dynamicFields">
        <div className="explanation margin-bottom-full">
          <FormattedHTMLMessage id="dynamicFields.explanation" />
        </div>
        <div className="dynamic-fields-filters margin-bottom-full">
          <Select
            className="filters-select"
            items={categories}
            onChange={this.handleSelect}
            selected={category}
          />
          <Text
            autoComplete="off"
            autoFocus={false}
            className="dynamic-fields-filter"
            id="search"
            onChange={this.handleSearch}
            placeholder={formatMessage({
              id: 'dynamicFields.searchPlaceholder',
            })}
            type="text"
            value={searchText}
          />
        </div>
        <ul className="fields-list">
          {this._getFieldsListItems(filteredFields)}
        </ul>
      </div>
    );
  }

  _getFieldsListItems(filteredFields) {
    return filteredFields.length ? (
      this.getFieldsWithoutDuplicates(filteredFields).map((field, index) => {
        const dynamicFieldIndex = `dynamic-fields-${index}`;
        const localizedLabel =
          localizeDynamicFieldsMap(field.category) && field.label
            ? I18N.getConstStr('dynamicFields', field.label)
            : field.label;
        return (
          <li
            className="fields-item"
            data-value={field.value}
            key={`${dynamicFieldIndex}-${field.value}`}
            onClick={this._handleClick}
          >
            <span className="fields-label text-overflow">{localizedLabel}</span>
            <span className="fields-value text-overflow">{field.value}</span>
          </li>
        );
      })
    ) : (
      <li className="fields-item empty-state">
        <FormattedMessage id="dynamicFields.emptyState" />
      </li>
    );
  }

  getFieldsWithoutDuplicates = (fields) =>
    fields.filter(
      (value, index, self) =>
        self.findIndex(
          (element) =>
            element.label === value.label && element.value === value.value
        ) === index
    );

  _pluckCategories(fields) {
    let categories = fields.map((field) => field.category);
    categories = sortBy(uniq(categories));
    categories.unshift(Selects.all);
    return categories.map((category) => ({
      label: localizeCategoryMap(category)
        ? I18N.getConstStr('baseSelect', category)
        : category,
      value: category,
    }));
  }

  _filterFields(fields, category, searchText) {
    if (searchText.length) {
      const text = searchText.toLowerCase();
      return fields.filter(
        (field) =>
          field.label.toLowerCase().search(text) > -1 ||
          field.value.toLowerCase().search(text) > -1
      );
    } else if (category === Selects.all) {
      return sortBy(uniqBy(fields, (field) => field.value));
    } else {
      return fields.filter((field) => field.category === category);
    }
  }

  handleSelect = (value) => this.setState({ category: value, searchText: '' });

  handleSearch = (value) =>
    this.setState({ category: Selects.all, searchText: value });

  _handleClick = (e) => this.props.onSelected(e.currentTarget.dataset.value);
}

DynamicFields.propTypes = {
  fields: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  onSelected: PropTypes.func.isRequired,
  selectedDynamicFieldsCategory: PropTypes.string,
};

DynamicFields.defaultProps = {
  selectedDynamicFieldsCategory: Selects.all,
};

export const DYNAMIC_FIELDS_POPUP_WIDTH = 710;
export default injectIntl(DynamicFields);
