import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EditTemplatePopupIds } from '../libs/editTemplateSlideOutConstants';
import EditTemplateSlideOut from '../components/editTemplateSlideOut';
import EditTemplateSlideOutPopupContainer from './editTemplateSlideOutPopupContainer';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  addRecommendedRow,
  archiveEmail,
  clearTabAlert,
  closeCardEditing,
  initAnalyticsView,
  initEmailsView,
  onBack,
  onTabSelect,
  openAddAsTemplate,
  openCardEditing,
  openEvents,
  openFollowUp,
  removeRecommendedRow,
  setAnalyticsDatesSelect,
  setAnalyticsTeamsSelect,
  setEmailsStateSelect,
  setEmailsTeamsSelect,
  unarchiveEmail,
  updateRecommendEditState,
  updateTemplateEditState,
} from '../actionCreators/editTemplateActionCreators';
import {
  onOpenCloneTemplate,
  onOpenCompose,
  onOpenPopup,
  onOpenSharing,
  openDynamicFields,
  openDynamicFieldsRecommendCard,
} from '../actionCreators/editTemplatePopupActionCreators';
import {
  saveName,
  saveNote,
  setFavorite,
  saveTemplate,
  updateBypassUnsubscribe,
} from '../actionCreators/editTemplateTemplateActionCreators';
import { getEmails, getTemplate } from '../selectors/editTemplateSelectors';
import {
  getArchivedCategoryId,
  getUserCanEditTemplates,
} from 'web/templates/selectors/templatesSelectors';

class EditTemplateSlideOutContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;

    this.actionCreators = {
      addRecommendedRow: () => dispatch(addRecommendedRow()),
      archiveEmail: (emailId) => dispatch(archiveEmail(emailId)),
      archiveTemplate: () =>
        dispatch(onOpenPopup(EditTemplatePopupIds.archive)),
      clearTabAlert: () => dispatch(clearTabAlert()),
      cloneTemplate: () => dispatch(onOpenCloneTemplate()),
      closeCardEditing: (cardId) => dispatch(closeCardEditing(cardId)),
      deleteTemplate: () => dispatch(openPopup(EditTemplatePopupIds.delete)),
      initAnalyticsView: () => dispatch(initAnalyticsView()),
      initEmailsView: () => dispatch(initEmailsView()),
      moveTemplate: () => dispatch(onOpenPopup(EditTemplatePopupIds.move)),
      onBack: () => dispatch(onBack()),
      onCommentDelete: (id) =>
        dispatch(openPopup(EditTemplatePopupIds.deleteNote, { id })),
      onCommentDone: (content, id) => dispatch(saveNote(content, id)),
      onTabSelect: (tab) => dispatch(onTabSelect(tab)),
      openAddAsTemplate: () => dispatch(openAddAsTemplate()),
      openCardEditing: (cardId) => dispatch(openCardEditing(cardId)),
      openDynamicFields: (index, options) =>
        dispatch(openDynamicFields(index, options)),
      openDynamicFieldsRecommendCard: (index) =>
        dispatch(openDynamicFieldsRecommendCard(index)),
      openEvents: (emailId) => dispatch(openEvents(emailId)),
      openFollowUp: (emailId) => dispatch(openFollowUp(emailId)),
      removeRecommendedRow: (index) => dispatch(removeRecommendedRow(index)),
      saveName: (value) => dispatch(saveName(value)),
      saveTemplate: (cardId) => dispatch(saveTemplate(cardId)),
      setAnalyticsDatesSelect: (value) =>
        dispatch(setAnalyticsDatesSelect(value)),
      setAnalyticsTeamsSelect: (value) =>
        dispatch(setAnalyticsTeamsSelect(value)),
      setEmailsStateSelect: (value) => dispatch(setEmailsStateSelect(value)),
      setEmailsTeamsSelect: (value) => dispatch(setEmailsTeamsSelect(value)),
      setFavorite: (favorite) => dispatch(setFavorite(favorite)),
      shareTemplate: () => dispatch(onOpenSharing()),
      unarchiveEmail: (emailId) => dispatch(unarchiveEmail(emailId)),
      updateBypassUnsubscribe: (id, bypassUnsubscribe) => dispatch(updateBypassUnsubscribe(id, bypassUnsubscribe)),
      updateRecommendEditState: (edits, index) =>
        dispatch(updateRecommendEditState(edits, index)),
      updateTemplateEditState: (editorId, edits) =>
        dispatch(updateTemplateEditState(editorId, edits)),
      useTemplate: () => dispatch(onOpenCompose()),
    };
  }

  render() {
    return (
      <EditTemplateSlideOutPopupContainer>
        <EditTemplateSlideOut
          actionCreators={this.actionCreators}
          {...this.props}
        />
      </EditTemplateSlideOutPopupContainer>
    );
  }
}

EditTemplateSlideOutContainer.propTypes = {
  archivedCategoryId: PropTypes.number.isRequired,
  analytics: PropTypes.object.isRequired,
  analyticsViewState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  editing: PropTypes.object.isRequired,
  emails: PropTypes.array.isRequired,
  emailsViewState: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  recommendEditState: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  tabAlert: PropTypes.object.isRequired,
  tabLoading: PropTypes.bool.isRequired,
  template: PropTypes.object.isRequired,
  templateEditState: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  viewer: PropTypes.object,
  userCanEditTemplates: PropTypes.bool,
};

EditTemplateSlideOutContainer.defaultProps = {
  viewer: {},
  userCanEditTemplates: true,
};

function mapStateToProps(state) {
  return {
    archivedCategoryId: getArchivedCategoryId(state),
    analytics: state.editTemplateSlideOutAnalytics,
    analyticsViewState: state.editTemplateSlideOutAnalyticsViewState,
    header: state.editTemplateSlideOutHeader,
    editing: state.editTemplateSlideOutEditing,
    emails: getEmails(state),
    emailsViewState: state.editTemplateSlideOutEmailsViewState,
    recommendEditState: state.editTemplateSlideOutRecommendEditState,
    tab: state.editTemplateSlideOutTab,
    tabAlert: state.editTemplateSlideOutTabAlert,
    tabLoading: state.editTemplateSlideOutTabLoading,
    template: getTemplate(state),
    templateEditState: state.editTemplateSlideOutTemplateEditState,
    user: state.user,
    viewer: state.templatesViewer,
    userCanEditTemplates: getUserCanEditTemplates(state),
  };
}

export default connect(mapStateToProps)(EditTemplateSlideOutContainer);
