import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import {
  getTemplatesAll,
  getTemplatesFavorites,
} from 'web/templates/services/templatesDataService';

export const getAllTemplates = () => (dispatch) => {
  getTemplatesAll()
    .then((templates) => {
      dispatch({
        type: TemplatesActionTypes.allTemplates.set,
        templates,
      });
    })
    .catch(() => {
      dispatch({ type: TemplatesActionTypes.allTemplates.set });
    });
};

export const getFavoriteTemplates = () => (dispatch, getState) => {
  const { templatesFavorites } = getState();

  if (templatesFavorites.length === 0) {
    getTemplatesFavorites()
      .then((templates) => {
        dispatch({
          type: TemplatesActionTypes.favorites.set,
          templates,
        });
      })
      .catch(() => {
        dispatch({ type: TemplatesActionTypes.favorites.set });
      });
  }
};
