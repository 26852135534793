import { Urls } from 'web/libs/constants';
import { basePost, baseGet } from 'web/services/api';

export const getEmailFetch = (params) =>
  new Promise((resolve, reject) => {
    basePost(Urls.esFetch, params, resolve, reject);
  });

export const getReplyEmailDraft = (pitchId) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.getReplyEmailDraft, { pitch_id: pitchId }, resolve, reject);
  });

export const getUnreadEmailsCount = (states = [], userId) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.emailsUnreadCounter,
      { state: states, user_id: userId },
      resolve,
      reject
    );
  });

export const markUnreadEmailsAsRead = (pitchIds = [], userId) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.emailsMarkAsRead,
      { pitch_ids: pitchIds, user_id: userId },
      resolve,
      reject
    );
  });
