import PipelineActionTypes from '../libs/pipelineActionTypes';
import {
  updateBestBetState,
  markEventAsSeen,
} from '../helpers/pipelineBestBetHelper';

const initBestBet = [];

export function liveFeedBestBets(
  state = initBestBet,
  { type = '', bestBets = [] }
) {
  switch (type) {
    case PipelineActionTypes.bestBets:
      return bestBets;
    case PipelineActionTypes.updateBestBets:
      return updateBestBetState(state, bestBets);
    case PipelineActionTypes.clearBestBets:
      return initBestBet;
    case PipelineActionTypes.markEventAsSeen:
      return markEventAsSeen(state, bestBets);
    default:
      return state;
  }
}

export function liveFeedBestBetsExtraData(
  state = [],
  { type = '', extraData = {} }
) {
  switch (type) {
    case PipelineActionTypes.bestBetExtraData: {
      const index = state.findIndex(
        (bestBet) => bestBet.id === extraData.bestBetId
      );
      if (index > -1) {
        const bestBetsExtraData = [...state];
        bestBetsExtraData[index] = extraData;
        return bestBetsExtraData;
      }
      return state.concat([extraData]);
    }
    default:
      return state;
  }
}

export function liveFeedPipelineOpenChildrenLoadingIds(
  state = [],
  { type = '', id }
) {
  switch (type) {
    case PipelineActionTypes.removeOpenChildrenId: {
      const index = state.indexOf(id);
      if (index === -1) {
        return state;
      }
      const loadingIds = [...state];
      loadingIds.splice(index, 1);
      return loadingIds;
    }
    case PipelineActionTypes.addOpenChildrenId:
      return state.concat([id]);
    default:
      return state;
  }
}

export function liveFeedPipelineVisible(
  state = false,
  { type = '', visible = false }
) {
  switch (type) {
    case PipelineActionTypes.visible:
      return visible;
    default:
      return state;
  }
}

const initErrorMessageId = null;

export function pipelineErrorMessageId(
  state = initErrorMessageId,
  { type = '', messageId }
) {
  switch (type) {
    case PipelineActionTypes.pipelineErrorMessageId:
      return messageId;
    case PipelineActionTypes.clearPipelineErrorMessageId:
      return initErrorMessageId;
    default:
      return state;
  }
}
