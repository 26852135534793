import isFunction from 'lodash/isFunction';

export default class TableExternals {
  constructor(externals = {}) {
    this.externals = externals;
  }

  set(externals = {}) {
    this.externals = externals;
  }

  get() {
    return this.externals;
  }

  unset() {
    this.externals = {};
  }

  clearSelectedIds() {
    const { clearSelectedIds } = this.externals;
    if (isFunction(clearSelectedIds)) {
      clearSelectedIds();
    }
  }
}
