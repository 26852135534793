import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import IntegrationsAlert from '../components/alerts';

const IntegrationsAlertsContainer = (props) => <IntegrationsAlert {...props} />;

IntegrationsAlertsContainer.propTypes = {
  viewAlert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  viewAlert: state.viewAlert,
});

const mapDispatchToProps = {
  closeViewAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationsAlertsContainer);
