import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import {
  EditTemplateCards,
  EditTemplateMappingsValues,
} from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import {
  recommendedCardFieldsMissing,
  recommendCardHasChanges,
} from 'web/slideOuts/editTemplate/helpers/editTemplateHasChangesHelper';
import Checkbox from 'components/buttons/checkbox';
import Radio from 'components/buttons/radio';
import RecommendEditingTable from './recommendEditingTable';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import './recommendEditing.scss';

const RADIO_NAME = 'recommend-editing-radio';

class RecommendEditing extends PureComponent {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.titleText = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editing.title',
    });
    this.enableLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editing.enableLabel',
    });
    this.criteriaStartLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editing.suggestCriteraStart',
    });
    this.criteriaEndLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editing.suggestCriteraEnd',
    });
    this.anyLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editing.anyLabel',
    });
    this.allLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editing.allLabel',
    });
  }

  render() {
    const {
      addRecommendedRow,
      openDynamicFields,
      removeRecommendedRow,
      recommendEditState,
      template,
      updateEditState,
    } = this.props;
    const {
      enableMappingsValue,
      enableMappingsChecked,
      mappings,
    } = recommendEditState;

    return (
      <div className="edit-template-recommend-editing">
        <h3 className="margin-bottom-half">{this.titleText}</h3>
        <div className="recommend-editing-description">
          <FormattedHTMLMessage id="web.editTemplateSlideOut.recommendCard.editing.description" />
        </div>
        <Checkbox
          checked={enableMappingsChecked}
          className="margin-top-full"
          classNameLabel="text-medium"
          label={this.enableLabel}
          name="enableMappings"
          onChange={this.onCheckboxChange}
        />
        <div className="recommend-editing-settings">
          <div className="margin-top-half">
            {this.criteriaStartLabel}
            <Radio
              checked={enableMappingsValue === EditTemplateMappingsValues.any}
              className="margin-left-half margin-right-half text-medium"
              disabled={!enableMappingsChecked}
              inline
              label={this.anyLabel}
              name={RADIO_NAME}
              onChange={this.onRadioChange}
              value={EditTemplateMappingsValues.any}
            />
            <Radio
              checked={enableMappingsValue === EditTemplateMappingsValues.all}
              className="margin-left-half margin-right-half text-medium"
              disabled={!enableMappingsChecked}
              inline
              label={this.allLabel}
              name={RADIO_NAME}
              onChange={this.onRadioChange}
              value={EditTemplateMappingsValues.all}
            />
            {this.criteriaEndLabel}
          </div>
          <RecommendEditingTable
            addRecommendedRow={addRecommendedRow}
            disabled={!enableMappingsChecked}
            mappings={mappings}
            openDynamicFields={openDynamicFields}
            updateEditState={updateEditState}
            removeRecommendedRow={removeRecommendedRow}
          />
        </div>
        <div className="recommend-editing-button-row margin-top-half">
          <Button
            color={ButtonColors.gray}
            onClick={this.cancel}
            size={ButtonSizes.standard}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className="margin-left-full"
            color={ButtonColors.blue}
            disabled={this._saveDisabled(template, recommendEditState)}
            onClick={this.save}
            size={ButtonSizes.standard}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </div>
    );
  }

  _saveDisabled(template, recommendEditState) {
    return (
      recommendedCardFieldsMissing(recommendEditState) ||
      !recommendCardHasChanges(template, recommendEditState)
    );
  }

  onCheckboxChange = (checked) =>
    this.props.updateEditState({ enableMappingsChecked: checked });

  onRadioChange = (value) =>
    this.props.updateEditState({ enableMappingsValue: value });

  save = () => this.props.save(EditTemplateCards.recommend);

  cancel = () => this.props.cancel(EditTemplateCards.recommend);
}

RecommendEditing.propTypes = {
  addRecommendedRow: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  openDynamicFields: PropTypes.func.isRequired,
  removeRecommendedRow: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  recommendEditState: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  updateEditState: PropTypes.func.isRequired,
};

RecommendEditing.defaultProps = {};

export default injectIntl(RecommendEditing);
