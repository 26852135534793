import I18N from 'languages';

export const TablesFiltersConstants = {
  custom: 'CUSTOM',
  last30Days: 'LAST_30_DAYS',
  lastSeven: 'LAST_SEVEN',
  lastSixMonths: 'LAST_SIX_MONTHS',
  lastThreeMonths: 'LAST_THREE_MONTHS',
  nextSeven: 'NEXT_SEVEN',
  nextSixMonths: 'NEXT_SIX_MONTHS',
  overdue: 'OVERDUE',
  past: 'PAST',
  thisMonth: 'THIS_MONTH',
  thisWeek: 'THIS_WEEK',
  toTheEndThisMonth: 'TO_THE_END_THIS_MONTH',
  toTheEndThisWeek: 'TO_THE_END_THIS_WEEK',
  today: 'TODAY',
  todayOverdue: 'TODAY_OVERDUE',
  todayPast: 'TODAY_PAST',
  tomorrow: 'TOMORROW',
  yesterday: 'YESTERDAY',
};

export const DateRangesOptions = {
  future: 'DATE_RANGES_FUTURE',
  past: 'DATE_RANGES_PAST',
};

export const DateRanges = {
  [DateRangesOptions.past]: [
    {
      label: I18N.getStr('common.today'),
      value: TablesFiltersConstants.today,
    },
    {
      label: I18N.getStr('web.filters.thisWeek'),
      value: TablesFiltersConstants.thisWeek,
    },
    {
      label: I18N.getStr('web.filters.lastSeven'),
      value: TablesFiltersConstants.lastSeven,
    },
    {
      label: I18N.getStr('web.filters.thisMonth'),
      value: TablesFiltersConstants.thisMonth,
    },
    {
      label: I18N.getStr('web.filters.last30Days'),
      value: TablesFiltersConstants.last30Days,
    },
    {
      label: I18N.getStr('web.filters.lastThreeMonths'),
      value: TablesFiltersConstants.lastThreeMonths,
    },
    {
      label: I18N.getStr('web.filters.lastSixMonths'),
      value: TablesFiltersConstants.lastSixMonths,
    },
    {
      className: 'tout-icon-smartfilter-calendar',
      label: I18N.getStr('common.custom'),
      value: TablesFiltersConstants.custom,
    },
  ],
  [DateRangesOptions.future]: [
    {
      label: I18N.getStr('common.today'),
      value: TablesFiltersConstants.today,
    },
    {
      label: I18N.getStr('web.filters.toTheEndThisWeek'),
      value: TablesFiltersConstants.toTheEndThisWeek,
    },
    {
      label: I18N.getStr('web.filters.nextSeven'),
      value: TablesFiltersConstants.nextSeven,
    },
    {
      label: I18N.getStr('web.filters.toTheEndThisMonth'),
      value: TablesFiltersConstants.toTheEndThisMonth,
    },
    {
      label: I18N.getStr('web.filters.nextSixMonths'),
      value: TablesFiltersConstants.nextSixMonths,
    },
    {
      className: 'tout-icon-smartfilter-calendar',
      label: I18N.getStr('common.custom'),
      value: TablesFiltersConstants.custom,
    },
  ],
};
