import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './switch.scss';

export default class Switch extends Component {
  render() {
    const {
      checked,
      classes,
      className,
      color,
      disabled,
      label,
      name,
      rightLabel,
    } = this.props;

    return (
      <label
        className={classNames('tout-switch', classes, className, {
          'right-label': rightLabel,
        })}
      >
        {(label && <div className="tout-switch-label">{label}</div>) || null}
        <div className="tout-slider-container">
          <input
            checked={checked}
            data-checked={checked}
            disabled={disabled}
            name={name}
            onChange={this.onSwitch}
            type="checkbox"
          />
          <div className={classNames('tout-slider', color, { disabled })} />
        </div>
      </label>
    );
  }

  onSwitch = (e) => {
    const { name, onChange } = this.props;
    onChange(e.currentTarget.checked, name);
    e.currentTarget.blur();
  };
}

export const SwitchColors = {
  blue: 'blue',
  green: 'green',
};

Switch.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.string, //legacy
  color: PropTypes.oneOf(Object.values(SwitchColors)),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rightLabel: PropTypes.bool,
};

Switch.defaultProps = {
  checked: false,
  className: '',
  classes: '',
  color: SwitchColors.blue,
  disabled: false,
  label: '',
  rightLabel: false,
};
