import { SubmissionError } from 'redux-form';
import { getLoginSso } from '../services/loginSsoService';
import { navigateToUrl } from 'web/services/routerService';
import {
  convertErrorsForReduxForm,
  defaultErrorMessage,
} from 'web/shared/helpers/parser';

const SSO_NOT_ACTIVATED =
  'Sorry, SSO has not been activated for your domain yet.';

export const doLoginSso = ({ email }) =>
  getLoginSso(email)
    .then((response) => {
      navigateToUrl(response.redirect_to);
    })
    .catch((err) => {
      let errorMessages = {};

      if (err.status === 422) {
        try {
          errorMessages = convertErrorsForReduxForm(
            JSON.parse(err.response.text).errors
          );
          if (errorMessages._error === SSO_NOT_ACTIVATED) {
            errorMessages = {
              _error: 'loginSso.domainNotActivated',
            };
          }
        } catch (error) {
          errorMessages = defaultErrorMessage;
        }
      } else {
        errorMessages = defaultErrorMessage;
      }

      throw new SubmissionError(errorMessages);
    });
