import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { ColorClasses } from 'libs/constantsStyles';
import TextButton from 'components/buttons/text';
import Pill from 'components/pill';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';

import './groupsDetails.scss';

class GroupsDetails extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.spacingText = this.props.intl.formatMessage({
      id: 'common.commaSpaced',
    });
  }

  render() {
    const { edit, emailsCount, groupsByAddressId, isUnsubscribed } = this.props;
    const isEditable =
      Object.keys(groupsByAddressId).length > 0 || emailsCount > 0;

    return (
      <div className="person-details-groups-details text-wrap">
        <div className="group-details-header-row margin-bottom-half">
          <h3>
            <FormattedMessage id="common.groups" />
          </h3>
          {edit &&
            !isUnsubscribed &&
            isEditable && (
              <TextButton onClick={edit} underline>
                <FormattedMessage id="common.edit" />
              </TextButton>
            )}
        </div>
        {this._getGroupsElements()}
      </div>
    );
  }

  _getGroupsListedByAddress = () => {
    const { emailsCount, groupsByAddressId } = this.props;
    const addressIds = Object.keys(groupsByAddressId);

    return addressIds.reduce((accumulator, addressId) => {
      const { addressLabel, addressValue, groupsList } = groupsByAddressId[
        addressId
      ];
      const groupsElement = this._getGroups(groupsList, addressValue);
      if (addressIds.length > 1 || emailsCount > 1) {
        accumulator.push(
          <div
            className="groups-details-groups-address"
            key={`groups-details-groups-address-${addressValue}`}
          >
            <h5>{addressLabel}</h5>
            <div>{groupsElement}</div>
          </div>
        );
      } else {
        accumulator.push(groupsElement);
      }

      return accumulator;
    }, []);
  };

  _getGroups = (groupsList, addressValue) => (
    <div key={`groups-details-groups-list-${addressValue}`}>
      {groupsList.map(({ label }, index) => (
        <Pill
          className="margin-right-half margin-bottom-quarter margin-top-quarter"
          color={ColorClasses.grayExtraLight}
          id={index}
          key={`groups-details-${addressValue}-${label}`}
          onClose={this.onRemove}
          text={getLocalizedDateFromGroupName(label)}
          textColor={ColorClasses.grayDark}
        />
      ))}
    </div>
  );

  _getGroupsElements = () => {
    const { emailsCount } = this.props;
    const addressesList = this._getGroupsListedByAddress();

    if (addressesList.length) {
      return <div className="group-details-groups">{addressesList}</div>;
    } else if (emailsCount > 0) {
      return <FormattedMessage id="web.person.personDetails.groups.noGroups" />;
    } else {
      return (
        <FormattedMessage id="web.person.personDetails.groups.noEmailAddresses" />
      );
    }
  };
}

GroupsDetails.propTypes = {
  edit: PropTypes.func,
  emailsCount: PropTypes.number.isRequired,
  groupsByAddressId: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isUnsubscribed: PropTypes.bool,
};

GroupsDetails.defaultProps = {
  edit: null,
  isUnsubscribed: false,
};

export default injectIntl(GroupsDetails);
