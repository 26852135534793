import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Text from 'components/inputs/text';
import './search.scss';
import ReactDOM from 'react-dom';
import isFunction from 'lodash/isFunction';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = { focus: false };
    this.searchRef = null;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    if (this.props.prefix) {
      this._manageEventListener(true);
    }
    if (this.props.autoFocus) {
      this.focus();
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.prefix && !this.props.prefix) {
      this._manageEventListener(true);
    } else if (this.props.prefix && !nextProps.prefix) {
      this._manageEventListener(false);
    }
  }

  componentWillUnmount() {
    this._manageEventListener(false);
  }

  render() {
    const {
      classIcon,
      classInput,
      className,
      clearSearch,
      fullHeight,
      prefix,
      onPrefixRemove,
      value,
      ...other
    } = this.props;
    const div = classNames('input-search', className, {
      focus: this.state.focus,
      'full-height': fullHeight,
    });
    const input = classNames('input-search-text text-overflow', classInput);

    return (
      <div className={div}>
        <div className={classNames('tout-icon-search', classIcon)} />
        {prefix.length > 0 && (
          <div className="input-search-prefix" onClick={onPrefixRemove}>
            <FormattedMessage id="search.prefix" values={{ prefix }} />
          </div>
        )}
        <Text
          {...other}
          className={input}
          onBlur={this.blur}
          onFocus={this.focus}
          ref={this._searchRef}
          type="text"
          value={value}
        />
        {value.length > 0 &&
          clearSearch && (
            <div
              className={classNames('tout-icon-close', classIcon)}
              onClick={this._clearSearch}
            />
          )}
      </div>
    );
  }

  blur = () => {
    this.setState({ focus: false });
    this._manageEventListener(false);
    this.props.onBlur();
  };

  focus = () => {
    this.setState({ focus: true });
    this._manageEventListener(true);
    this.props.onFocus();
  };

  _manageEventListener(add = false) {
    //eslint-disable-next-line react/no-find-dom-node
    const input = ReactDOM.findDOMNode(this.searchRef) || window;
    if (add) {
      input.addEventListener('keydown', this._handleKeyDown, false);
    } else {
      input.removeEventListener('keydown', this._handleKeyDown, false);
    }
  }

  _handleKeyDown = (e) => {
    const { prefix, onPrefixRemove, value } = this.props;

    if (prefix.length > 0 && value.length === 0 && e.keyCode === 8) {
      // delete
      onPrefixRemove();
    }
  };

  _searchRef = (div) => {
    this.searchRef = div;
  };

  _clearSearch = () => {
    const { clearSearch, onChange } = this.props;

    onChange('');
    this.searchRef.inputRef.focus(); // hooks into Text's ref

    if (isFunction(clearSearch)) {
      clearSearch();
    }
  };
}

Search.propTypes = {
  autoFocus: PropTypes.bool,
  classIcon: PropTypes.string,
  classInput: PropTypes.string,
  className: PropTypes.string,
  clearSearch: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  fullHeight: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onPrefixRemove: PropTypes.func,
  prefix: PropTypes.string,
  value: PropTypes.string.isRequired,
};

Search.defaultProps = {
  autoFocus: false,
  classIcon: '',
  classInput: '',
  className: '',
  clearSearch: undefined,
  fullHeight: false,
  onBlur: () => {},
  onFocus: () => {},
  onPrefixRemove: () => {},
  prefix: '',
};

export default Search;
