export const EditIdentitySlideOutWidth = 720;
export const EditIdentityTabs = {
  about: 'edit_identity_tabs_about',
};

export const LABEL_WIDTH = 80;
export const IDENTITY_FORM_ID = 'identity';
export const DELIVERY_CHANNEL_FORM_ID = 'delivery-channel';

export const EditIdentityCards = {
  deliveryChannel: 'delivery-channel-card',
  identity: 'identity-card',
  signature: 'signature-card',
};

export const EditIdentityPopupIds = {
  hasChanges: 'EDIT_IDENTITY_POPUP_HAS_CHANGES',
};

export const SaveIdentityErrorKeys = {
  badEmail: 'web.identities.form.saving.emailError',
  couldNotSave: 'web.identities.form.saving.error',
  invalidEmail: 'web.identities.form.saving.invalidEmail',
  invalidMobileNumber: 'web.identities.form.saving.invalidMobileNumber',
  invalidName: 'web.identities.form.saving.invalidName',
  invalidWebsite: 'web.identity.alert.invalidWebsite',
  invalidWorkNumber: 'web.identities.form.saving.invalidWorkNumber',
};

export const SaveIdentityErrorCodeKeys = {
  hasAlreadyTaken: 'has already been taken',
};

export const SaveIdentitySuccessKey = 'web.identities.form.saving.success';

export const EditIdentityEditorId = 'edit-identity-signature';

export const DynamicFieldsSenderCategory = 'Sender Information';

export const AutomaticallyFilledSignature =
  '<div>{{my_name}}</div>' +
  '<div>{{my_title}}</div>' +
  '<div>{{my_company}}</div>' +
  '<div>{{my_email}}</div>' +
  '<div>{{my_website}}</div>' +
  '<div>{{my_address}}</div>' +
  '<div>{{my_mobile_phone}}</div>' +
  '<div>{{my_work_phone}}</div>';
