import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { parseAutocompleteContacts } from 'web/services/parsers';
import {
  ALL_PERSON_QUERY_FIELDS,
  COLUMN_PITCHES_SENT,
  MAX_AUTOCOMPLETE_RESULTS,
  PEOPLE_SEARCH_RESULT_FIELDS,
} from 'web/people/peopleSearchAdd/libs/peopleSearchAddConstants';
import { esFetch } from 'web/elasticSearch/services/esFetch';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';
import { SortDirections } from 'libs/constantsShared';

function autocompleteCall() {
  return (dispatch, getState) => {
    const value = getState().autocompleteValue;

    dispatch({ type: ActionTypes.api.autocomplete.request });

    esFetch({
      column: COLUMN_PITCHES_SENT,
      direction: SortDirections.desc,
      includeFields: PEOPLE_SEARCH_RESULT_FIELDS,
      perPage: MAX_AUTOCOMPLETE_RESULTS,
      textSearch: {
        fields: ALL_PERSON_QUERY_FIELDS,
        term: value,
      },
      type: ElasticSearchTypes.people,
    })
      .then((autoCompleteResults) => {
        let data = [];
        if (autoCompleteResults && autoCompleteResults.results) {
          data = parseAutocompleteContacts(autoCompleteResults.results);
        }
        dispatch({
          data,
          type: ActionTypes.api.autocomplete.success,
        });
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.api.autocomplete.failure,
        });
      });
  };
}

let autocompleteTimeout;
export function getAutocompleteContacts(value = '', minLength = 3) {
  return (dispatch) => {
    if (autocompleteTimeout || value.length < minLength) {
      clearTimeout(autocompleteTimeout);
      dispatch({ type: ActionTypes.autocomplete.data.clear });
    }

    if (value.length >= minLength) {
      autocompleteTimeout = setTimeout(() => dispatch(autocompleteCall()), 300);
    }
  };
}
