import TeamSmtpDeliveryChannelActionTypes from '../libs/teamSmtpDeliveryChannelActionTypes';

export function teamSmtpDeliveryChannelFetched(state = false, action) {
  switch (action.type) {
    case TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannel:
      return true;
    default:
      return state;
  }
}

export function teamSmtpDeliveryChannelFetching(state = false, action) {
  switch (action.type) {
    case TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannel:
    case TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannelError:
      return false;
    case TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannelFetching:
      return true;
    default:
      return state;
  }
}

export function teamSmtpDeliveryChannel(state = null, action) {
  switch (action.type) {
    case TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannel:
      return action.deliveryChannel;
    default:
      return state;
  }
}
