import { GmailPluginContainerId } from 'web/settings/emailPlugins/libs/emailPluginsConstants';

export const actualizeGmailPluginInstalledState = (
  gmailChromeExtensionInstalled,
  updateStateAction
) => {
  const extensionElement = document.getElementById(GmailPluginContainerId);
  if (extensionElement != null && !gmailChromeExtensionInstalled) {
    updateStateAction(true);
  }
};
