import React from 'react';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import Switch from 'components/buttons/switch';
import Radio from 'components/buttons/radio';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { alertEmptyRequiredCallOutcomeOption } from 'web/settings/adminSettings/dialer/actionCreators/callOutcomeActionCreators';
import PropTypes from 'prop-types';
import './dialerSettingsCard.scss';

const CallOutcomeSettingsCard = ({
  actionCreators: { updateDialerSettings },
  callOutcomeEnabled,
  dispatch,
  forceCallOutcome,
  intl: { formatMessage },
  totalCallOutcomeOptions,
}) => {
  const handleSettingsChange = async ({
    call_outcome_enabled,
    force_call_outcome,
  }) => {
    await updateDialerSettings({
      call_outcome_enabled,
      force_call_outcome,
    });
    dispatch(
      alertEmptyRequiredCallOutcomeOption({
        isEnabled: call_outcome_enabled,
        isRequired: force_call_outcome,
      })
    );
  };

  return (
    <React.Fragment>
      <h3 className="my-profile-account-details-title">
        <FormattedMessage id="web.settings.adminSettings.dialerSettings.callOutcome" />
      </h3>
      <div className="dialer-settings-card-notice margin-top-20">
        <FormattedMessage id="web.settings.adminSettings.dialerSettings.callOutcomeSettingsCardNotice" />
      </div>
      <div className="margin-top-20">
        <Switch
          checked={callOutcomeEnabled}
          classes="padding-bottom-15"
          label={formatMessage({
            id: 'web.settings.adminSettings.dialerSettings.enableCallOutcomes',
          })}
          name="call_outcome_enabled"
          onChange={(isChecked) =>
            handleSettingsChange({ call_outcome_enabled: isChecked })
          }
          rightLabel
        />
      </div>
      <div className="dialer-settings-card-options">
        <Radio
          checked={!forceCallOutcome}
          disabled={!callOutcomeEnabled}
          label={formatMessage({
            id: 'web.settings.adminSettings.dialerSettings.allowCallOutcomes',
          })}
          name="call-outcome-selection"
          onChange={() => handleSettingsChange({ force_call_outcome: false })}
        />
        <Radio
          checked={forceCallOutcome}
          disabled={!callOutcomeEnabled}
          label={formatMessage({
            id: 'web.settings.adminSettings.dialerSettings.requireCallOutcomes',
          })}
          name="call-outcome-selection"
          onChange={() => handleSettingsChange({ force_call_outcome: true })}
        />
      </div>
      <div className="dialer-settings-card-wrapper margin-top-20">
        <FormattedHTMLMessage
          id="web.settings.adminSettings.dialerSettings.currentTotalCallOutcomes"
          values={{ count: totalCallOutcomeOptions }}
        />
        <div className="dialer-settings-card-manage right">
          <a
            className="dialer-settings-card-manage-link"
            onClick={() => window.toutReact.manageCallOutcomesModal()}
          >
            <FormattedMessage id="web.settings.adminSettings.dialerSettings.manageCallOutcomes" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  callOutcomeEnabled:
    get(
      state,
      'user.subscription.admin_settings.call_outcome_enabled',
      false
    ).toString() === 'true',
  forceCallOutcome:
    get(
      state,
      'user.subscription.admin_settings.force_call_outcome',
      false
    ).toString() === 'true',
  totalCallOutcomeOptions:
    (state.callOutcomeOptions && state.callOutcomeOptions.length) || 0,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(CallOutcomeSettingsCard);

CallOutcomeSettingsCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  callOutcomeEnabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  forceCallOutcome: PropTypes.bool,
  intl: intlShape.isRequired,
  totalCallOutcomeOptions: PropTypes.number,
};

CallOutcomeSettingsCard.defaultProps = {
  callOutcomeEnabled: false,
  forceCallOutcome: false,
  totalCallOutcomeOptions: 0,
};
