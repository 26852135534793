import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import closest from 'closest';
import withEventListener from 'components/hocs/withEventListener';
import { openNewTab } from 'web/services/routerService';
import './catchATagClicks.scss';

const CLASSNAME = 'catch-a-tag-clicks';

class CatchATagClicks extends PureComponent {
  render() {
    const { text } = this.props;

    /*eslint react/no-danger: 0*/
    return (
      <div
        className={`${CLASSNAME} text-wrap`}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  }
}

CatchATagClicks.propTypes = {
  text: PropTypes.string,
};

CatchATagClicks.defaultProps = {
  text: '',
};

const onEvent = (e) => {
  const aTag = closest(e.target, `.${CLASSNAME} a`, true);
  if (aTag && aTag.href) {
    e.stopPropagation();
    e.preventDefault();

    openNewTab(e.target.href);
  }
};

const withEventListenerOptions = {
  eventType: 'click',
  onEvent,
};

export default withEventListener(withEventListenerOptions)(CatchATagClicks);
