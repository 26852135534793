import React from 'react';
import PropTypes from 'prop-types';

const AbleListItem = ({ children }) => (
  <li>
    <span className="icon-success" />
    {children}
  </li>
);

AbleListItem.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AbleListItem;
