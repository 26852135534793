export const MAX_AUTOCOMPLETE_RESULTS = 3;

export const SalesforceObjectTypes = {
  contact: 'Contact',
  lead: 'Lead',
};

export const COLUMN_PITCHES_SENT = 'pitches_sent';

export const ALL_PERSON_QUERY_FIELDS = [
  'first_name',
  'last_name',
  'addresses',
  'email_addresses',
  'title',
  'company',
];

export const PEOPLE_SEARCH_RESULT_FIELDS = 'people_search_result_fields';
