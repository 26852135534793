export default {
  bulkActionSelected: 'BULK_ACTION_SELECTED',
  columnFilterChange: 'COLUMN_FILTER_CHANGE',
  columnFilterChangeAllChecked: 'COLUMN_FILTER_CHANGE_ALL_CHECKED',
  columnFilterChangeAllUnchecked: 'COLUMN_FILTER_CHANGE_ALL_UNCHECKED',
  columnSorted: 'COLUMN_SORTED',
  paginationSelected: 'PAGINATION_SELECTED',
  searchChange: 'SEARCH_VALUE_CHANGE',
  searchMultipleSelected: 'SEARCH_MULTIPLE_SELECTED',
  selectableAllSelected: 'ALL_SELECTED',
  smartFilterSelected: 'SMART_FILTER_SELECTED',
};
