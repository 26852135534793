import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { steps } from 'web/onboarding/lib/stepsRouting';
import { setOnboardingActiveStep } from 'web/onboarding/actionCreators/onboardingActionCreators';
import { defineActiveStep } from 'web/onboarding/helpers/onboardingHelpers';
import OnboardingStepTitle from 'web/onboarding/components/onboardingStepTitle/onboardingStepTitle';

import './stepsContainer.scss';

const StepsContainer = ({
  url,
  activeStep,
  setOnboardingActiveStep,
  setShowProgress,
}) => {
  useEffect(
    () => {
      setOnboardingActiveStep(defineActiveStep(url));
    },
    { url }
  );

  const renderStep = steps[activeStep - 1];

  if (activeStep > 0) setShowProgress(true);
  else setShowProgress(false);

  return renderStep ? (
    <React.Fragment>
      <OnboardingStepTitle titleId={renderStep.title} />
      <renderStep.component />
    </React.Fragment>
  ) : null;
};

const mapStateToProps = (state) => ({
  activeStep: state.onboarding.onboardingActiveStep,
});

const mapDispatchToProps = {
  setOnboardingActiveStep,
};

StepsContainer.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setOnboardingActiveStep: PropTypes.func.isRequired,
  setShowProgress: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepsContainer);
