import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/navBar/item';

import Dropdown from '../dropdown/dropdown';
import { getNavBarItems } from '../../helpers/settingsHelper';
import withDropdownToggler from '../../hocs/dropdownToggleHoc';

const SettingsIcon = (props) => {
  const navBarItems = getNavBarItems(props);
  const { toggleDropdown, showDropdown, hideDropdown } = props;

  return (
    <Item onClick={toggleDropdown} classNames="dropdown-toggle">
      <Dropdown
        icon="icon-gear"
        items={navBarItems}
        show={showDropdown}
        disableClick={!showDropdown}
        onOutsideClick={hideDropdown}
      />
    </Item>
  );
};

SettingsIcon.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
  hideDropdown: PropTypes.func.isRequired,
  showDropdown: PropTypes.bool.isRequired,
};

export default withDropdownToggler(SettingsIcon);
