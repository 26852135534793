import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Integrations from '../components/integrations';
import {
  isIntegrationsSafeToChangeRoute,
  isIntegrationsLoading,
  canShowHighspotCard,
} from '../selectors/integrationsSelectors';
import {
  isBoxIntegrationEnabled,
  isSlackIntegrationEnabled,
} from 'web/settings/adminSettings/general/selectors/generalPageSelectors';
import { isIntegrationContentSharingEnabled } from 'web/user/selectors/userSelectors';

const IntegrationsContainer = (props) => <Integrations {...props} />;

const mapStateToProps = (state) => ({
  loading: isIntegrationsLoading(state),
  isSafeToChangeRoute: isIntegrationsSafeToChangeRoute(state),
  isIntegrationContentSharingEnabled: isIntegrationContentSharingEnabled(state),
  canShowHighspotCard: canShowHighspotCard(state),
  isBoxIntegrationEnabled: isBoxIntegrationEnabled(state),
  isSlackIntegrationEnabled: isSlackIntegrationEnabled(state),
});

IntegrationsContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  isSafeToChangeRoute: PropTypes.bool.isRequired,
  isIntegrationContentSharingEnabled: PropTypes.bool.isRequired,
  canShowHighspotCard: PropTypes.bool.isRequired,
  isBoxIntegrationEnabled: PropTypes.bool.isRequired,
  isSlackIntegrationEnabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(IntegrationsContainer);
