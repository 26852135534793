import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getDynamicFields() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.dynamicFields, {}, resolve, reject);
  });
}

export const getActivityDynamicFieldsSalesforce = () => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.activityDynamicFieldsSalesforce, {}, resolve, reject);
  });
};
