import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import StatusSmartFilter from 'web/commandCenter/modules/statusSmartFilter/components/statusSmartFilter';
import {
  addSentSubStatusToFilter,
  deleteSentSubStatusInFilter,
  replaceSentSubStatusInFilter,
  setAdvancedSearchStatusValue,
  clearAdvancedSearchStatusValue,
} from 'web/commandCenter/modules/statusSmartFilter/actionCreators/statusSmartFilterActionCreators';
import { getAdvancedSearchStatus } from 'web/commandCenter/modules/statusSmartFilter/selectors/statusSmartFilterSelectors';
import {
  ADVANCED_SEARCH_FILTERS_COLLECTION,
  FILTER_SEARCH_STATUS_ID,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import {
  getCommandCenterEmailsTab,
  getCommandCenterEmailsSubTab,
} from 'web/commandCenter/selectors/commandCenterSelectors';

const StatusSmartFilterContainer = (props) => <StatusSmartFilter {...props} />;

StatusSmartFilterContainer.propTypes = {
  searchStatus: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addSentSubStatusToFilter,
      clearAdvancedSearchStatusValue,
      deleteSentSubStatusInFilter,
      replaceSentSubStatusInFilter,
      setAdvancedSearchStatusValue,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  searchStatus: getAdvancedSearchStatus(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
    filterId: FILTER_SEARCH_STATUS_ID,
  }),
  selectedSubTabValue: getCommandCenterEmailsSubTab(state),
  selectedTabValue: getCommandCenterEmailsTab(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusSmartFilterContainer);
