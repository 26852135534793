import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Urls } from 'web/libs/routes';

const UnderCardSection = () => (
  <div className="not-a-customer-block">
    <FormattedMessage id="web.unauthorized.login.notCustomer" />
    <a
      className="schedule-demo-link"
      href={Urls.common.demo}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="web.unauthorized.login.scheduleDemo" />
    </a>
  </div>
);

export default UnderCardSection;
