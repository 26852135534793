import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import Cards, { Direction } from 'components/layouts/cards';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import EmailServerFormCard from 'web/settings/emailServer/components/emailServerFormCard';
import SmtpSetupByAdminCard from '../smtpSetupByAdminCard';
import 'web/settings/emailServer/components/emailServerPage/emailServerPage.scss';

class SmtpServerPage extends Component {
  render() {
    return (
      <Page className="email-server-page">
        <PageView
          className="email-server-page-view"
          backgroundColor={BackgroundColors.white}
        >
          <Cards
            backgroundColor={BackgroundColors.grayExtraLight}
            cards={this.getCards()}
            direction={Direction.vertical}
            marginAround={FULL}
            marginBetween={FULL}
          />
        </PageView>
      </Page>
    );
  }

  getCards = () => {
    const {
      actionCreators,
      model,
      formName,
      additionalServerValidators,
      isTeamSmtpDeliveryChannelExists,
    } = this.props;

    const cards = [];
    if (isTeamSmtpDeliveryChannelExists) {
      cards.push({
        id: 'smtp-setup-by-admin-card',
        children: React.createElement(SmtpSetupByAdminCard),
        className: 'email-server-card',
      });
    }
    cards.push({
      id: 'email-server-form-card',
      children: React.createElement(EmailServerFormCard, {
        actionCreators,
        model,
        formName,
        additionalServerValidators,
        descriptionMessageId: 'web.settings.smtpServer.formDescription',
      }),
      className: 'email-server-card',
    });

    return cards;
  };
}

SmtpServerPage.propTypes = {
  intl: intlShape.isRequired,
  actionCreators: PropTypes.object.isRequired,
  model: PropTypes.object,
  formName: PropTypes.string.isRequired,
  additionalServerValidators: PropTypes.array,
  isTeamSmtpDeliveryChannelExists: PropTypes.bool.isRequired,
};

SmtpServerPage.defaultProps = {
  model: null,
  additionalServerValidators: [],
  isTeamSmtpDeliveryChannelExists: false,
};

export default injectIntl(SmtpServerPage);
