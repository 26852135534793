import { LocalStorageKeys } from '../libs/constants';
import { Pagination } from '../../table/modules/constants';
import { DEFAULT_PAGE_SIZE } from 'web/elasticSearch/libs/elasticSearchConstants';

export default class StorageService {
  constructor(storage = window.localStorage) {
    this.storage = storage;
  }

  setItem = (name = '', value = '') => {
    try {
      this.storage.setItem(name, value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('StorageService issue', e);
    }
  };

  getItem = (name = '') => {
    try {
      return this.storage.getItem(name);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('StorageService issue', e);
      return null;
    }
  };
}

export const localStorageInstance = new StorageService(window.localStorage);

export const tablePerPageFromStorage = ({ withDefault = true } = {}) => {
  const savedPerPageValue = localStorageInstance.getItem(
    LocalStorageKeys.tables.perPage
  );
  const value = Number(savedPerPageValue);
  return Pagination.perPageValues.includes(value)
    ? value
    : withDefault && DEFAULT_PAGE_SIZE;
};
