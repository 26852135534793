/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { userViewersFilterItems } from 'web/sidebar/viewers/selectors/userSelectors';
import {
  getPeopleGroups,
  getEmailGridPeopleGroupFilterValue,
  getPeopleGroupSmartFilterInputValue,
} from 'web/commandCenter/modules/whoSmartFilter/selectors/peopleGroupFilterSelectors';
import ViewAsSmartFilter from 'web/commandCenter/modules/whoSmartFilter/components/viewAsSmartFilter/viewAsSmartFilter';
import PeopleGroupSmartFilter from 'web/commandCenter/modules/whoSmartFilter/components/peopleGroupSmartFilter/peopleGroupSmartFilter';
import PeopleSmartFilter from 'web/commandCenter/modules/whoSmartFilter/components/peopleSmartFilter';
import { setSidebarFilterInput } from 'web/sidebar/viewers/actionCreators/viewersActionCreators';
import {
  clearPeopleGroupFilterValue,
  setPeopleGroupFilterValue,
  changePeopleGroupFilterInputValue,
} from 'web/commandCenter/modules/whoSmartFilter/actionCreators/peopleGroupFilterActionCreators';
import { onCommandCenterSidebarFilterSelect } from 'web/commandCenter/actionCreators/commandCenterViewerActionCreators';
import { getSelectedUserForViewAsFilter } from 'web/commandCenter/modules/whoSmartFilter/selectors/viewAsFilterSelectors';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import {
  setViewAsFilterValue,
  changeViewAsFilterInputValue,
} from 'web/commandCenter/modules/whoSmartFilter/actionCreators/viewAsFilterActionCreators';
import {
  onAutocompleteLeaving,
  onPeopleAutocompleteChange,
  onPersonSelect,
} from 'web/commandCenter/modules/whoSmartFilter/actionCreators/peopleFilterActionCreators';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';

class WhoSmartFilterContainer extends Component {
  render() {
    const {
      autocompleteLoading,
      autocompleteResults,
      autocompleteValue,
      onAutocompleteLeaving,
      onPeopleAutocompleteChange,
      onPersonSelect,
      user: { admin },
      clearPeopleGroupFilterValue,
      peopleGroupFilterValue,
      peopleGroupItems,
      setPeopleGroupFilterValue,
      changePeopleGroupFilterInputValue,
      peopleGroupSmartFilterInputValue,
    } = this.props;

    const viewAsFilter = this.getViewAsFilter();
    const actionCreators = {
      onAutocompleteLeaving,
      onPeopleAutocompleteChange,
      onPersonSelect,
    };
    const formattedPeopleGroupItems = peopleGroupItems.map(
      (peopleGroupItem) => ({
        ...peopleGroupItem,
        label: getLocalizedDateFromGroupName(peopleGroupItem.label),
      })
    );

    return (
      <div className="margin-bottom-full">
        <h3>
          <FormattedMessage id="common.who" />
        </h3>

        {admin && (
          <div className="margin-top-most">
            <ViewAsSmartFilter viewAsFilter={viewAsFilter} />
          </div>
        )}
        <div className="margin-top-most">
          <PeopleGroupSmartFilter
            clearFilterAction={clearPeopleGroupFilterValue}
            inputValue={peopleGroupSmartFilterInputValue}
            items={formattedPeopleGroupItems}
            onChange={changePeopleGroupFilterInputValue}
            onReset={clearPeopleGroupFilterValue}
            selectFilterValueAction={setPeopleGroupFilterValue}
            selectedItemValue={peopleGroupFilterValue}
          />
        </div>
        <div className="margin-top-most">
          <PeopleSmartFilter
            actionCreators={actionCreators}
            autocompleteLoading={autocompleteLoading}
            autocompleteResults={autocompleteResults}
            autocompleteValue={autocompleteValue}
          />
        </div>
      </div>
    );
  }

  getViewAsFilter = () => {
    const {
      viewAsViewer: { id, name = '' },
      filterItems,
      setViewAsFilterValue,
      changeViewAsFilterInputValue,
    } = this.props;

    return {
      inputValue: name,
      items: filterItems,
      onChange: changeViewAsFilterInputValue,
      onSelect: setViewAsFilterValue,
      selectedItem: id,
    };
  };
}

WhoSmartFilterContainer.propTypes = {
  autocompleteLoading: PropTypes.bool.isRequired,
  autocompleteResults: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string.isRequired,
  changePeopleGroupFilterInputValue: PropTypes.func.isRequired,
  changeViewAsFilterInputValue: PropTypes.func.isRequired,
  clearPeopleGroupFilterValue: PropTypes.func.isRequired,
  filterItems: PropTypes.array.isRequired,
  onAutocompleteLeaving: PropTypes.func.isRequired,
  onPeopleAutocompleteChange: PropTypes.func.isRequired,
  onPersonSelect: PropTypes.func.isRequired,
  peopleGroupFilterValue: PropTypes.number.isRequired,
  peopleGroupItems: PropTypes.array.isRequired,
  peopleGroupSmartFilterInputValue: PropTypes.string.isRequired,
  setPeopleGroupFilterValue: PropTypes.func.isRequired,
  setViewAsFilterValue: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  viewAsViewer: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePeopleGroupFilterInputValue,
      changeViewAsFilterInputValue,
      clearPeopleGroupFilterValue,
      onAutocompleteLeaving,
      onCommandCenterSidebarFilterSelect,
      onPeopleAutocompleteChange,
      onPersonSelect,
      setPeopleGroupFilterValue,
      setSidebarFilterInput,
      setViewAsFilterValue,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  autocompleteLoading: state.autocompleteLoading,
  autocompleteResults: state.autocompleteResults,
  autocompleteValue: state.autocompleteValue,
  filterItems: userViewersFilterItems(state),
  peopleGroupFilterValue: getEmailGridPeopleGroupFilterValue(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  }),
  peopleGroupItems: getPeopleGroups(state),
  peopleGroupSmartFilterInputValue: getPeopleGroupSmartFilterInputValue(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  }),
  user: state.user,
  viewAsViewer: getSelectedUserForViewAsFilter(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhoSmartFilterContainer);
