import * as popupActionCreators from './popupActionCreators';
import * as opportunitiesActionCreators from './opportunitiesActionCreators';
import merge from 'lodash/merge';

const gongActionCreators = merge(
  {},
  popupActionCreators,
  opportunitiesActionCreators
);

export default gongActionCreators;
