import DomainBlocksActionTypes from '../libs/domainBlocksActionTypes';

export function blockedDomains(state = [], { type, data }) {
  switch (type) {
    case DomainBlocksActionTypes.addAll:
      return data;
    case DomainBlocksActionTypes.addOne:
      return [...state, data];
    case DomainBlocksActionTypes.removeOne:
      return state.filter((domainBlock) => domainBlock.id !== data);
    default:
      return state;
  }
}

export function loadingBlockedDomains(state = false, { type }) {
  switch (type) {
    case DomainBlocksActionTypes.showLoading:
      return true;
    case DomainBlocksActionTypes.hideLoading:
      return false;
    default:
      return state;
  }
}

export function newDomainBlock(state = '', { type, data }) {
  switch (type) {
    case DomainBlocksActionTypes.setNewDomainBlock:
      return data;
    case DomainBlocksActionTypes.clearNewDomainBlock:
      return '';
    default:
      return state;
  }
}

export function newDomainBlockError(
  state = { available: false },
  { type, data }
) {
  switch (type) {
    case DomainBlocksActionTypes.showError:
      return { available: true, data };
    case DomainBlocksActionTypes.hideError:
      return { available: false };
    default:
      return state;
  }
}

export function domainSearchString(state = '', { type, value }) {
  switch (type) {
    case DomainBlocksActionTypes.domainStateSearchChange:
      return value;
    case DomainBlocksActionTypes.clearSearchDomainBlock:
      return '';
    default:
      return state;
  }
}
