import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ToutTable from 'table/modules/toutTable';
import { getImageUrl } from 'web/libs/constants';
import moment from 'moment';
import { tracker } from 'web/tables/tasks/services/tasksTableMixpanel';
import {
  TasksTableColumns,
  TasksTableStatusFilterValues,
  TasksTableDatesFilterValues,
} from '../libs/tablesTasksConstants';
import { getColumns } from './tasksTableColumnsHelper';
import DateRangePicker from 'components/dates/dateRangePicker';
import { tablePerPageFromStorage } from 'web/services/storageService';
import './tasksTable.scss';

const CURRENT_PAGE = 1;
const ROW_HEIGHT = 70;
const IMAGE_WIDTH = 210;
const NAME_SEARCH_INDEX = 0; // index of the 'Name' field from searchers, see _getSearchers
const NAME_ID = 'common.name';

class TasksTable extends Component {
  constructor(props) {
    super(props);
    const {
      actionCreators,
      intl: { formatMessage },
      isCampaign,
    } = this.props;

    this.state = { openDateRangePicker: false, startDateValueChanged: false };
    this.initColumns();

    this.paginationObject = {
      currentPage: CURRENT_PAGE,
      perPage: tablePerPageFromStorage(),
    };
    this.rowObject = {
      height: ROW_HEIGHT,
      onClick: (data) =>
        data.isSelectedDisabled ? null : actionCreators.openTask(data),
    };
    this.searchObject = {
      placeholderText: formatMessage({
        id: 'web.tables.tasks.searchPlaceholder',
      }),
      searchers: this._getSearchers(formatMessage),
      showClear: false,
    };
    const zeroState = this._getZeroState(isCampaign);
    this.zeroState = {
      bodyText: formatMessage({ id: zeroState.body }),
      imageUrl: getImageUrl('lonely-planet', 'svg'),
      imageWidth: IMAGE_WIDTH,
      titleText: formatMessage({ id: zeroState.title }),
    };
  }

  initColumns() {
    const {
      actionCreators,
      columns,
      intl: { formatMessage },
      isActionsDisabled,
      user,
    } = this.props;
    this.columns = getColumns(
      columns,
      actionCreators,
      formatMessage,
      isActionsDisabled,
      user
    );
  }

  componentDidUpdate(prevProps) {
    const { animateRowColor, actionCreators, isActionsDisabled } = this.props;
    if (animateRowColor) {
      actionCreators.onTableRowAnimated();
    }

    if (prevProps.isActionsDisabled != isActionsDisabled) {
      this.initColumns();
      this.rowObject.onClick = (data) =>
        data.isSelectedDisabled ? null : actionCreators.openTask(data);
    }
  }

  updateState = () => {
    this.setState({
      openDateRangePicker: false,
    });
  };

  render() {
    const {
      actionCreators,
      animateRowColor,
      columnFilter,
      columnSettings,
      dates,
      fetching,
      intl: { formatMessage },
      isActionsDisabled,
      loading,
      pagination,
      statusSelectedValue,
      tableId,
      tasks,
      user,
    } = this.props;
    const { openDateRangePicker } = this.state;
    const selectable =
      statusSelectedValue === TasksTableStatusFilterValues.uncompleted;
    const startDateValue = this.state.startDateValueChanged
      ? dates.start
      : moment();
    const items = tasks.map((task) => {
      task['isSelectedDisabled'] = user && task.user_id !== user.id;
      return task;
    });
    return (
      <div className="tasks-table">
        <ToutTable
          animateRow={animateRowColor.length > 0}
          animationColor={animateRowColor}
          bulkActions={{
            actions: this._getBulkActions(
              isActionsDisabled,
              selectable,
              actionCreators,
              formatMessage
            ),
            itemsLabel: formatMessage({ id: 'common.tasks' }),
          }}
          changeColumnsSettingsHandler={actionCreators.changeColumnsSettings}
          columnFilter={columnFilter}
          columnSettings={columnSettings}
          columns={this.columns}
          defaultSortingColumn={TasksTableColumns.due}
          fetching={fetching}
          initialState={this._getInitialState()}
          items={items}
          loading={loading}
          loadingGetUrl={getImageUrl}
          pagination={pagination && this.paginationObject}
          row={this.rowObject}
          search={this.searchObject}
          selectable={selectable}
          smartFilters={this._getSmartFilters()}
          tableId={tableId}
          tracker={tracker}
          zeroState={this.zeroState}
          allItems
          isResize
          reorderColumnsEnable
        />
        {openDateRangePicker && (
          <DateRangePicker
            className="hidden"
            endDate={dates.end}
            onDatesChange={this.onCustomDatesChange}
            startDate={startDateValue}
            updateStartDate={this.updateStartDate}
            updateState={this.updateState}
            openOnMount
            withPortal
          />
        )}
      </div>
    );
  }

  _getBulkActions(
    isActionsDisabled,
    selectable,
    actionCreators,
    formatMessage
  ) {
    if (!selectable) {
      return [];
    }

    const actions = [
      {
        disabled: isActionsDisabled,
        label: formatMessage({ id: 'web.campaigns.tasks.markDone' }),
        onClick: actionCreators.tasksConfirmationDone,
      },
      {
        disabled: isActionsDisabled,
        label: formatMessage({ id: 'common.markSuccess' }),
        onClick: actionCreators.tasksConfirmationSuccess,
      },
      {
        disabled: isActionsDisabled,
        label: formatMessage({ id: 'common.skip' }),
        onClick: actionCreators.tasksConfirmationSkip,
      },
    ];
    // if (!isCampaign) { //todo commented out until bulk tasks done on backend
    //   actions.push({ label: formatMessage({ id: 'common.delete' }), onClick: actionCreators.tasksConfirmationDelete });
    // }
    return actions;
  }

  _getSmartFilters() {
    const {
      actionCreators,
      datesSelectedValue,
      intl: { formatMessage },
      showDateFilter,
      statusSelectedValue,
    } = this.props;

    const smartFilters = [
      {
        filters: [
          {
            label: formatMessage({ id: 'common.open' }),
            value: TasksTableStatusFilterValues.uncompleted,
          },
          {
            label: formatMessage({ id: 'common.completed' }),
            value: TasksTableStatusFilterValues.completed,
          },
        ],
        label: formatMessage({ id: 'selects.smartFilter.label' }),
        onChange: actionCreators.onTasksStatusSmartFilterChange,
        selectedValue: statusSelectedValue,
        width: 260,
      },
    ];

    if (showDateFilter) {
      smartFilters.unshift({
        filters: this._getDatesSmartFilters(statusSelectedValue, formatMessage),
        label: formatMessage({ id: 'common.dateLabel' }),
        onChange: this.onDateSmartFilterChange,
        selectedValue: datesSelectedValue,
        width: 300,
      });
    }

    return smartFilters;
  }

  updateStartDate = () => {
    this.setState({ startDateValueChanged: true });
  };

  _getDatesSmartFilters(status, formatMessage) {
    const isUncompleted = status === TasksTableStatusFilterValues.uncompleted;
    return [
      {
        label: formatMessage({ id: 'common.today' }),
        value: TasksTableDatesFilterValues.today,
      },
      {
        label: formatMessage({
          id: `web.filters.${(isUncompleted && 'overdue') || 'past'}`,
        }),
        value: TasksTableDatesFilterValues.overdue,
      }, // 'web.filters.overdue' 'web.filters.past'
      {
        label: formatMessage({
          id: `web.filters.${(isUncompleted && 'todayOverdue') || 'todayPast'}`,
        }),
        value: TasksTableDatesFilterValues.todayOverdue,
      }, // 'web.filters.todayOverdue' 'web.filters.todayPast'
      {
        label: formatMessage({ id: 'common.yesterday' }),
        value: TasksTableDatesFilterValues.yesterday,
      },
      {
        label: formatMessage({ id: 'common.tomorrow' }),
        value: TasksTableDatesFilterValues.tomorrow,
      },
      {
        label: formatMessage({ id: 'web.filters.nextSeven' }),
        value: TasksTableDatesFilterValues.nextSeven,
      },
      {
        className: 'tout-icon-smartfilter-calendar',
        label: formatMessage({ id: 'common.custom' }),
        value: TasksTableDatesFilterValues.custom,
      },
    ];
  }

  _getZeroState(isCampaign) {
    if (isCampaign) {
      return {
        body: 'web.campaigns.zeroState.noCampaignTasksBody',
        title: 'web.campaigns.zeroState.noCampaignTasksTitle',
      };
    } else {
      return {
        body: 'web.campaigns.zeroState.noTasksBody',
        title: 'web.campaigns.zeroState.noTasksTitle',
      };
    }
  }

  onDateSmartFilterChange = (index, value) => {
    const {
      actionCreators: { onTasksDatesSmartFilterChange },
    } = this.props;

    if (value !== TasksTableDatesFilterValues.custom) {
      onTasksDatesSmartFilterChange(value);
      this.setState({ openDateRangePicker: false });
    } else {
      this.setState({ openDateRangePicker: true });
    }
  };

  onCustomDatesChange = (start, end) => {
    const {
      actionCreators: { onTasksDatesSmartFilterChange },
    } = this.props;
    if (start && end) {
      onTasksDatesSmartFilterChange(
        TasksTableDatesFilterValues.custom,
        start,
        end
      );
    }
  };

  _getSearchers(formatMessage) {
    return [
      {
        label: formatMessage({ id: NAME_ID }),
        searching: (item) => item.parent_name,
      },
      {
        label: formatMessage({ id: 'web.campaigns.tasks.email' }),
        searching: (item) => item.parent_email,
      },
      {
        label: formatMessage({ id: 'web.campaigns.tasks.company' }),
        searching: (item) => item.company,
      },
      {
        label: formatMessage({ id: 'web.campaigns.tasks.subject' }),
        searching: (item) => item.subject,
      },
    ];
  }

  _getInitialState() {
    const {
      intl: { formatMessage },
      peopleSearchString,
    } = this.props;

    if (peopleSearchString) {
      return {
        searchPrefix: formatMessage({ id: NAME_ID }),
        searchSelectedSearcherIndex: NAME_SEARCH_INDEX,
        searchValue: peopleSearchString,
      };
    } else {
      return null;
    }
  }
}

TasksTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  animateRowColor: PropTypes.string,
  columnFilter: PropTypes.bool,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.array.isRequired,
  dates: PropTypes.object,
  datesSelectedValue: PropTypes.string,
  fetching: PropTypes.bool,
  intl: intlShape.isRequired,
  isActionsDisabled: PropTypes.bool,
  isCampaign: PropTypes.bool,
  loading: PropTypes.bool,
  pagination: PropTypes.bool,
  peopleSearchString: PropTypes.string,
  showDateFilter: PropTypes.bool,
  statusSelectedValue: PropTypes.string,
  tableId: PropTypes.string,
  tasks: PropTypes.array,
  user: PropTypes.object,
};

TasksTable.defaultProps = {
  animateRowColor: '',
  columnFilter: true,
  columnSettings: [],
  dates: {},
  datesSelectedValue: '',
  fetching: false,
  isActionsDisabled: false,
  isCampaign: false,
  loading: false,
  pagination: true,
  peopleSearchString: null,
  showDateFilter: true,
  statusSelectedValue: '',
  tableId: '',
  tasks: [],
};

export default injectIntl(TasksTable);
