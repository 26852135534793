import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CatchATagClicks from 'web/emails/components/catchATagClicks';
import CardHighlight from 'components/cardHighlight/cardHighlight';
import './details.scss';

class Details extends Component {
  render() {
    const { signaturePreview } = this.props;

    return (
      <div className="identity-details">
        <div className="identity-section">
          <h4 className="identity-section-title">
            <FormattedMessage id="common.signature" />
          </h4>
          <div className="identity-field-item">
            {signaturePreview ? (
              <CatchATagClicks text={signaturePreview} />
            ) : (
              <div>
                <h3 className="text-center text-blue">
                  <FormattedMessage id="web.identities.editIdentitySignature.zeroState.title" />
                </h3>
                <h4 className="text-center text-gray margin-top-most">
                  <FormattedMessage id="web.identities.editIdentitySignature.zeroState.body" />
                </h4>
              </div>
            )}
          </div>
          <CardHighlight
            className="marketo-card-warning"
            textId="web.identities.details.signatureAddressTip"
          />
        </div>
      </div>
    );
  }
}

Details.propTypes = {
  signaturePreview: PropTypes.string.isRequired,
};

Details.defaultProps = {
  signaturePreview: '',
};

export default Details;
