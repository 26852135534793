import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SharingSettingsPopupContainer from './sharingSettingsPopupContainer';
import SharingSettings from '../components/sharingSettings';
import { SharingSettingsPopupIds } from '../libs/sharingSettingsConstants';
import { getCategories, getTeams } from '../selectors/sharingSettingsSelectors';
import {
  initSharingSettings,
  getCategoriesByTab,
  onTabSelect,
  openAddTeams,
  unmount,
} from '../actionCreators/sharingSettingsActionCreators';
import { onCategorySelect } from '../actionCreators/sharingSettingsSidebarActionCreators';
import {
  setSharing,
  setPermissionsEnabled,
} from '../actionCreators/sharingSettingsTableActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import './sharingSettingsContainer.scss';

class SharingSettingsContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    this.actionCreators = {
      onCategorySelect: (item, index) =>
        dispatch(onCategorySelect(item, index)),
      onTabSelect: (newTab) => dispatch(onTabSelect(newTab)),
      openAddTeams: () => dispatch(openAddTeams()),
      removeTeams: (ids, callback) =>
        dispatch(
          openPopup(SharingSettingsPopupIds.removeTeams, { callback, ids })
        ),
      setPermissionsEnabled: (enabled) =>
        dispatch(setPermissionsEnabled(enabled)),
      setSharing: (id, isSharing) => dispatch(setSharing(id, isSharing)),
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.props.dispatch(initSharingSettings());
    this.props.dispatch(getCategoriesByTab());
  }

  componentWillUnmount() {
    this.props.dispatch(unmount());
  }

  render() {
    return (
      <div className="sharing-settings-container">
        <SharingSettingsPopupContainer />
        <SharingSettings actionCreators={this.actionCreators} {...this.props} />
      </div>
    );
  }
}

SharingSettingsContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  permissionsEnabled: PropTypes.bool.isRequired,
  selectedCategoryIndex: PropTypes.number.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
  sidebarLoading: PropTypes.bool.isRequired,
  tableFetching: PropTypes.bool.isRequired,
  tableLoading: PropTypes.bool.isRequired,
  teams: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    alert: state.settingsSharingAlert,
    categories: getCategories(state),
    permissionsEnabled: state.settingsSharingPermissionsEnabled,
    selectedCategoryIndex: state.settingsSharingSelectedCategoryIndex,
    selectedTabValue: state.settingsSharingSelectedTabValue,
    sidebarLoading: state.settingsSharingSidebarLoading,
    tableFetching: state.settingsSharingTableFetching,
    tableLoading: state.settingsSharingTableLoading,
    teams: getTeams(state),
  };
}

export default connect(mapStateToProps)(SharingSettingsContainer);
