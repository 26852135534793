import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';

// stores all templates while templatesGridData is used only for Templates table
export function templatesAll(
  state = [],
  { type = '', template = {}, templates = [] }
) {
  switch (type) {
    case TemplatesActionTypes.allTemplates.set:
      return templates;
    case TemplatesActionTypes.allTemplates.add:
      return (template.id && [...state, template]) || state;
    default:
      return state;
  }
}

// eventually should refactor to be based off some common state - can be derived
// from templates tags and should be based off of that as source of truth
export function templatesFavorites(
  state = [],
  { type = '', setting = false, template = {}, templates = [] }
) {
  switch (type) {
    case TemplatesActionTypes.favorites.set:
      return templates;
    case TemplatesActionTypes.favorites.setSingle: {
      if (setting && template.id) {
        return [template, ...state];
      } else {
        const index = state.findIndex(({ id }) => id === template.id);
        if (index > -1) {
          return [...state.slice(0, index), ...state.slice(index + 1)];
        } else {
          return state;
        }
      }
    }
    default:
      return state;
  }
}
