import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { getComposeEmailPopupId } from '../helpers/composeEmailHelpers';

export const openComposeEmailPopup = (content) => (dispatch) => {
  const popupId = getComposeEmailPopupId(content);

  if (popupId) {
    dispatch(openPopup(popupId));
  }
};
