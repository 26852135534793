import get from 'lodash/get';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormSyncErrors, isDirty } from 'redux-form';
import { bindActionCreators } from 'redux';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { findDeliveryChannelByTeamAndProviderSmtp } from 'web/deliveryChannels/selectors/deliveryChannelsSelectors';
import { getDeliveryChannels } from 'web/deliveryChannels/actionCreators/deliveryChannelsActionCreators';
import { showValidationErrorMessage } from 'web/settings/emailServer/actionCreators/emailServerActionCreators';
import { EmailServerValidatorTypes } from 'web/settings/emailServer/libs/emailServerConstants';
import {
  notGmailSmtp as notGmailSmtpValidatorFactory,
  notOffice365Smtp as notOfficeSmtpValidatorFactory,
} from 'web/settings/emailServer/helpers/emailServerValidators';
import {
  saveSmtpServer,
  openRemoveConfirmationPopup,
  getSmtpServer,
  showValidationGmailSmtpErrorMessage,
  showValidationOutlookSmtpErrorMessage,
} from '../actionCreators/smtpServerActionCreators';
import { FORM_ID } from '../libs/smtpServerConstants';
import SmtpServer from '../components/smtpServer';

class SmtpServerContainer extends Component {
  constructor(props) {
    super(props);

    this.notGmailSmtpValidator = notGmailSmtpValidatorFactory();
    this.notOffice365Smtp = notOfficeSmtpValidatorFactory();

    const { dispatch } = props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          closeViewAlert,
          getDeliveryChannels,
          getSmtpServer,
          onDelete: openRemoveConfirmationPopup,
          onSave: saveSmtpServer,
        },
        dispatch
      ),
      onError: (validationErrors) => {
        const errors =
          validationErrors || this.props.smtpDeliveryChannelFormErrors;
        if (
          get(errors, ['server', 'validatorType']) ===
          EmailServerValidatorTypes.notGmailSmtp
        ) {
          dispatch(showValidationGmailSmtpErrorMessage());
        } else if (
          get(errors, ['server', 'validatorType']) ===
          EmailServerValidatorTypes.notOffice365Smtp
        ) {
          dispatch(showValidationOutlookSmtpErrorMessage());
        } else {
          dispatch(showValidationErrorMessage(errors));
        }
      },
    };
  }

  componentDidMount() {
    const {
      smtpDeliveryChannelFetched,
      smtpDeliveryChannelFetching,
      deliveryChannelsFetched,
      deliveryChannelsFetching,
    } = this.props;

    if (!smtpDeliveryChannelFetched && !smtpDeliveryChannelFetching) {
      this.actionCreators.getSmtpServer();
    }

    if (!deliveryChannelsFetched && !deliveryChannelsFetching) {
      this.actionCreators.getDeliveryChannels();
    }
  }

  render() {
    const {
      smtpDeliveryChannelFetching,
      deliveryChannelsFetching,
      smtpDeliveryChannel,
      smtpDeliveryChannelFormDirty,
      teamSmtpDeliveryChannel,
      office365Enabled,
    } = this.props;

    const additionalServerValidators = [this.notGmailSmtpValidator];

    if (office365Enabled) {
      additionalServerValidators.push(this.notOffice365Smtp);
    }

    return (
      <SmtpServer
        actionCreators={this.actionCreators}
        additionalServerValidators={additionalServerValidators}
        formName={FORM_ID}
        isSafeToChangeRoute={!smtpDeliveryChannelFormDirty}
        isTeamSmtpDeliveryChannelExists={!!teamSmtpDeliveryChannel}
        loading={smtpDeliveryChannelFetching || deliveryChannelsFetching}
        model={smtpDeliveryChannel}
      />
    );
  }
}

SmtpServerContainer.propTypes = {
  deliveryChannelsFetched: PropTypes.bool.isRequired,
  deliveryChannelsFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  office365Enabled: PropTypes.bool,
  smtpDeliveryChannel: PropTypes.object,
  smtpDeliveryChannelFetched: PropTypes.bool.isRequired,
  smtpDeliveryChannelFetching: PropTypes.bool.isRequired,
  smtpDeliveryChannelFormDirty: PropTypes.bool.isRequired,
  smtpDeliveryChannelFormErrors: PropTypes.object,
  teamSmtpDeliveryChannel: PropTypes.object,
};

SmtpServerContainer.defaultProps = {
  office365Enabled: false,
  smtpDeliveryChannel: null,
  smtpDeliveryChannelFormErrors: null,
  teamSmtpDeliveryChannel: null,
};

const mapStateToProps = (state) => ({
  deliveryChannelsFetched: state.deliveryChannelsFetched,
  deliveryChannelsFetching: state.deliveryChannelsFetching,
  smtpDeliveryChannel: state.smtpDeliveryChannel,
  smtpDeliveryChannelFetched: state.smtpDeliveryChannelFetched,
  smtpDeliveryChannelFetching: state.smtpDeliveryChannelFetching,
  smtpDeliveryChannelFormDirty: isDirty(FORM_ID)(state),
  smtpDeliveryChannelFormErrors: getFormSyncErrors(FORM_ID)(state),
  teamSmtpDeliveryChannel: findDeliveryChannelByTeamAndProviderSmtp(state),
});

export default connect(mapStateToProps)(SmtpServerContainer);
