import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { GongProperties, SourceProperties } from 'web/libs/mixpanelEvents';
import { getSource, track } from 'web/services/mixpanelService';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { ModalPopupIds } from 'web/libs/constants';

export function showLoading() {
  return {
    type: ActionTypes.gong.showLoading,
  };
}

export function hideLoading() {
  return {
    type: ActionTypes.gong.hideLoading,
  };
}

export function showGongWindowMode() {
  return {
    type: ActionTypes.gong.windowMode,
  };
}

export function setSource(source, isWindowMode) {
  let parsedSource = '';
  if (isWindowMode && !source) {
    parsedSource = SourceProperties.salesforce;
  } else {
    parsedSource = getSource(source);
  }

  return {
    type: ActionTypes.gong.setSource,
    source: parsedSource,
  };
}

export function showConfirmation() {
  return {
    type: ActionTypes.gong.displayConfirmation,
    data: true,
  };
}

export function hideConfirmation() {
  return {
    type: ActionTypes.gong.displayConfirmation,
    data: false,
  };
}

export const trackEvent = (event) => (dispatch, getState) => {
  const { gongSource } = getState();

  track(event, {
    [GongProperties.source]: gongSource,
  });
};

export const openGongPopup = () => (dispatch) => {
  dispatch(openPopup(ModalPopupIds.gong));
};
