import { getMarketoCampaigns } from 'web/marketo/campaigns/actionCreators/marketoCampaignsActionCreators';
import { getMarketoWorkspaces } from 'web/marketo/workspaces/actionCreators/marketoWorkspacesActionCreators';
import { initCurrentWorkspace } from 'web/modals/addToCampaign/actionCreators/marketoWorkspacesActionCreators';
import { marketoCampaignParams } from 'web/modals/addToCampaign/helpers/marketoHelpers';
import {
  campaignForFilter,
  setCampaign,
} from 'web/modals/addToCampaign/actionCreators/campaignActionCreators';
import { getFilteredMarketoCampaigns } from 'web/modals/addToCampaign/selectors/addToCampaignSelectors';

export const initMarketoCampaignsAndWorkspaces = () => (dispatch) =>
  dispatch(getMarketoWorkspaces()).then(() => dispatch(initCurrentWorkspace()));

export const setSelectedMarketoCampaign = (campaign) => (
  dispatch,
  getState
) => {
  const filteredMarketoCampaigns = getFilteredMarketoCampaigns(getState());
  dispatch(setCampaign(campaignForFilter(filteredMarketoCampaigns, campaign)));
};

export const getMarketoCampaignsForWorkspace = (workspaceName, campaign) => (
  dispatch,
  getState
) =>
  dispatch(getMarketoCampaigns(marketoCampaignParams({ workspaceName }))).then(
    () => {
      dispatch(setSelectedMarketoCampaign(campaign));
    }
  );
