tinymce.addI18n('ja', {
  Redo: '\u3084\u308a\u76f4\u3057',
  Undo: '\u5143\u306b\u623b\u3059',
  Cut: '\u5207\u308a\u53d6\u308a',
  Copy: '\u30b3\u30d4\u30fc',
  Paste: '\u8cbc\u308a\u4ed8\u3051',
  'Select all': '\u3059\u3079\u3066\u9078\u629e',
  'New document': '\u65b0\u898f\u30c9\u30ad\u30e5\u30e1\u30f3\u30c8',
  Ok: 'OK',
  Cancel: '\u53d6\u6d88',
  'Visual aids': '\u8868\u306e\u67a0\u7dda\u3092\u70b9\u7dda\u3067\u8868\u793a',
  Bold: '\u592a\u5b57',
  Italic: '\u659c\u4f53',
  Underline: '\u4e0b\u7dda',
  Strikethrough: '\u53d6\u6d88\u7dda',
  Superscript: '\u4e0a\u4ed8\u304d',
  Subscript: '\u4e0b\u4ed8\u304d',
  'Clear formatting': '\u66f8\u5f0f\u3092\u30af\u30ea\u30a2',
  'Align left': '\u5de6\u63c3\u3048',
  'Align center': '\u4e2d\u592e\u63c3\u3048',
  'Align right': '\u53f3\u63c3\u3048',
  Justify: '\u4e21\u7aef\u63c3\u3048',
  'Bullet list': '\u7b87\u6761\u66f8\u304d',
  'Numbered list': '\u756a\u53f7\u4ed8\u304d\u7b87\u6761\u66f8\u304d',
  'Decrease indent': '\u30a4\u30f3\u30c7\u30f3\u30c8\u3092\u6e1b\u3089\u3059',
  'Increase indent': '\u30a4\u30f3\u30c7\u30f3\u30c8\u3092\u5897\u3084\u3059',
  Close: '\u9589\u3058\u308b',
  Formats: '\u66f8\u5f0f',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    '\u304a\u4f7f\u3044\u306e\u30d6\u30e9\u30a6\u30b6\u3067\u306f\u30af\u30ea\u30c3\u30d7\u30dc\u30fc\u30c9\u6a5f\u80fd\u3092\u5229\u7528\u3059\u308b\u3053\u3068\u304c\u3067\u304d\u307e\u305b\u3093\u3002\u30ad\u30fc\u30dc\u30fc\u30c9\u306e\u30b7\u30e7\u30fc\u30c8\u30ab\u30c3\u30c8\uff08Ctrl+X, Ctrl+C, Ctrl+V\uff09\u3092\u4f7f\u7528\u3057\u3066\u304f\u3060\u3055\u3044\u3002',
  Headers: '\u30d8\u30c3\u30c0\u30fc',
  'Header 1': '\u30d8\u30c3\u30c0\u30fc 1',
  'Header 2': '\u30d8\u30c3\u30c0\u30fc 2',
  'Header 3': '\u30d8\u30c3\u30c0\u30fc 3',
  'Header 4': '\u30d8\u30c3\u30c0\u30fc 4',
  'Header 5': '\u30d8\u30c3\u30c0\u30fc 5',
  'Header 6': '\u30d8\u30c3\u30c0\u30fc 6',
  Headings: '\u898b\u51fa\u3057',
  'Heading 1': '\u898b\u51fa\u30571',
  'Heading 2': '\u898b\u51fa\u30572',
  'Heading 3': '\u898b\u51fa\u30573',
  'Heading 4': '\u898b\u51fa\u30574',
  'Heading 5': '\u898b\u51fa\u30575',
  'Heading 6': '\u898b\u51fa\u30576',
  Preformatted: '\u66f8\u5f0f\u8a2d\u5b9a\u6e08\u307f',
  Div: 'Div',
  Pre: 'Pre',
  Code: '\u30b3\u30fc\u30c9',
  Paragraph: '\u6bb5\u843d',
  Blockquote: 'Blockquote',
  Inline: '\u30a4\u30f3\u30e9\u30a4\u30f3',
  Blocks: '\u30d6\u30ed\u30c3\u30af',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    '\u8cbc\u308a\u4ed8\u3051\u306f\u73fe\u5728\u30d7\u30ec\u30fc\u30f3\u30c6\u30ad\u30b9\u30c8\u30e2\u30fc\u30c9\u3067\u3059\u3002\u3053\u306e\u30aa\u30d7\u30b7\u30e7\u30f3\u3092\u30aa\u30d5\u306b\u3057\u306a\u3044\u9650\u308a\u5185\u5bb9\u306f\u30d7\u30ec\u30fc\u30f3\u30c6\u30ad\u30b9\u30c8\u3068\u3057\u3066\u8cbc\u308a\u4ed8\u3051\u3089\u308c\u307e\u3059\u3002',
  Fonts: '\u30d5\u30a9\u30f3\u30c8',
  'Font Sizes': '\u30d5\u30a9\u30f3\u30c8\u30b5\u30a4\u30ba',
  Class: '\u30af\u30e9\u30b9',
  'Browse for an image': '\u753b\u50cf\u3092\u53c2\u7167',
  OR: 'OR',
  'Drop an image here':
    '\u3053\u3053\u306b\u753b\u50cf\u3092\u30c9\u30ed\u30c3\u30d7',
  Upload: '\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9',
  Block: '\u30d6\u30ed\u30c3\u30af',
  Align: '\u914d\u7f6e',
  Default: '\u30c7\u30d5\u30a9\u30eb\u30c8',
  Circle: '\u5186',
  Disc: '\u70b9',
  Square: '\u56db\u89d2',
  'Lower Alpha':
    '\u5c0f\u6587\u5b57\u306e\u30a2\u30eb\u30d5\u30a1\u30d9\u30c3\u30c8',
  'Lower Greek': '\u5c0f\u6587\u5b57\u306e\u30ae\u30ea\u30b7\u30e3\u6587\u5b57',
  'Lower Roman': '\u5c0f\u6587\u5b57\u306e\u30ed\u30fc\u30de\u6570\u5b57',
  'Upper Alpha':
    '\u5927\u6587\u5b57\u306e\u30a2\u30eb\u30d5\u30a1\u30d9\u30c3\u30c8',
  'Upper Roman': '\u5927\u6587\u5b57\u306e\u30ed\u30fc\u30de\u6570\u5b57',
  'Anchor...': '\u30a2\u30f3\u30ab\u30fc...',
  Name: '\u30a2\u30f3\u30ab\u30fc\u540d',
  Id: 'Id',
  'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'ID\u306f\u6587\u5b57\u3067\u59cb\u307e\u308a\u3001\u6587\u5b57\u3001\u6570\u5b57\u3001\u30c0\u30c3\u30b7\u30e5\u3001\u30c9\u30c3\u30c8\u3001\u30b3\u30ed\u30f3\u307e\u305f\u306f\u30a2\u30f3\u30c0\u30fc\u30b9\u30b3\u30a2\u3067\u59cb\u307e\u308b\u5fc5\u8981\u304c\u3042\u308a\u307e\u3059\u3002',
  'You have unsaved changes are you sure you want to navigate away?':
    '\u307e\u3060\u4fdd\u5b58\u3057\u3066\u3044\u306a\u3044\u5909\u66f4\u304c\u3042\u308a\u307e\u3059\u304c\u3001\u672c\u5f53\u306b\u3053\u306e\u30da\u30fc\u30b8\u3092\u96e2\u308c\u307e\u3059\u304b\uff1f',
  'Restore last draft':
    '\u524d\u56de\u306e\u4e0b\u66f8\u304d\u3092\u5fa9\u6d3b\u3055\u305b\u308b',
  'Special character...': '\u7279\u6b8a\u6587\u5b57...',
  'Source code': '\u30bd\u30fc\u30b9\u30b3\u30fc\u30c9',
  'Insert/Edit code sample':
    '\u30b3\u30fc\u30c9\u30b5\u30f3\u30d7\u30eb\u306e\u633f\u5165\u30fb\u7de8\u96c6',
  Language: '\u8a00\u8a9e',
  'Code sample...': '\u30b3\u30fc\u30c9\u306e\u30b5\u30f3\u30d7\u30eb...',
  'Color Picker': '\u30ab\u30e9\u30fc\u30d4\u30c3\u30ab\u30fc',
  R: 'R',
  G: 'G',
  B: 'B',
  'Left to right': '\u5de6\u304b\u3089\u53f3',
  'Right to left': '\u53f3\u304b\u3089\u5de6',
  'Emoticons...': '\u7d75\u6587\u5b57...',
  'Metadata and Document Properties':
    '\u30e1\u30bf\u30c7\u30fc\u30bf\u3068\u30c9\u30ad\u30e5\u30e1\u30f3\u30c8\u30d7\u30ed\u30d1\u30c6\u30a3',
  Title: '\u30bf\u30a4\u30c8\u30eb',
  Keywords: '\u30ad\u30fc\u30ef\u30fc\u30c9',
  Description: '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u306e\u5185\u5bb9',
  Robots: '\u30ed\u30dc\u30c3\u30c4',
  Author: '\u8457\u8005',
  Encoding: '\u30a8\u30f3\u30b3\u30fc\u30c7\u30a3\u30f3\u30b0',
  Fullscreen: '\u5168\u753b\u9762\u8868\u793a',
  Action: '\u30a2\u30af\u30b7\u30e7\u30f3',
  Shortcut: '\u30b7\u30e7\u30fc\u30c8\u30ab\u30c3\u30c8',
  Help: '\u30d8\u30eb\u30d7',
  Address: '\u30a2\u30c9\u30ec\u30b9',
  'Focus to menubar':
    '\u30e1\u30cb\u30e5\u30fc\u30d0\u30fc\u306b\u30d5\u30a9\u30fc\u30ab\u30b9',
  'Focus to toolbar':
    '\u30c4\u30fc\u30eb\u30d0\u30fc\u306b\u30d5\u30a9\u30fc\u30ab\u30b9',
  'Focus to element path':
    '\u8981\u7d20\u30d1\u30b9\u306b\u30d5\u30a9\u30fc\u30ab\u30b9',
  'Focus to contextual toolbar':
    '\u30b3\u30f3\u30c6\u30ad\u30b9\u30c8\u30c4\u30fc\u30eb\u30d0\u30fc\u306b\u30d5\u30a9\u30fc\u30ab\u30b9',
  'Insert link (if link plugin activated)':
    '\u30ea\u30f3\u30af\u3092\u633f\u5165 (\u30ea\u30f3\u30af\u30d7\u30e9\u30b0\u30a4\u30f3\u6709\u52b9\u6642)',
  'Save (if save plugin activated)':
    '\u4fdd\u5b58 (\u4fdd\u5b58\u30d7\u30e9\u30b0\u30a4\u30f3\u6709\u52b9\u6642)',
  'Find (if searchreplace plugin activated)':
    '\u691c\u7d22(\u7f6e\u63db\u30d7\u30e9\u30b0\u30a4\u30f3\u6709\u52b9\u6642)',
  'Plugins installed ({0}):':
    '\u30a4\u30f3\u30b9\u30c8\u30fc\u30eb\u6e08\u30d7\u30e9\u30b0\u30a4\u30f3 ({0}):',
  'Premium plugins:':
    '\u30d7\u30ec\u30df\u30a2\u30e0\u30d7\u30e9\u30b0\u30a4\u30f3:',
  'Learn more...': '\u8a73\u7d30...',
  'You are using {0}': '\u3042\u306a\u305f\u306f {0} \u4f7f\u7528\u4e2d',
  Plugins: '\u30d7\u30e9\u30b0\u30a4\u30f3',
  'Handy Shortcuts':
    '\u4fbf\u5229\u306a\u30b7\u30e7\u30fc\u30c8\u30ab\u30c3\u30c8',
  'Horizontal line': '\u6c34\u5e73\u7f6b\u7dda',
  'Insert/edit image': '\u753b\u50cf\u306e\u633f\u5165\u30fb\u7de8\u96c6',
  'Image description': '\u753b\u50cf\u306e\u8aac\u660e\u6587',
  Source: '\u753b\u50cf\u306e\u30bd\u30fc\u30b9',
  Dimensions: '\u753b\u50cf\u30b5\u30a4\u30ba\uff08\u6a2a\u30fb\u7e26\uff09',
  'Constrain proportions': '\u7e26\u6a2a\u6bd4\u3092\u4fdd\u6301\u3059\u308b',
  General: '\u4e00\u822c',
  Advanced: '\u8a73\u7d30\u8a2d\u5b9a',
  Style: '\u30b9\u30bf\u30a4\u30eb',
  'Vertical space': '\u7e26\u65b9\u5411\u306e\u4f59\u767d',
  'Horizontal space': '\u6a2a\u65b9\u5411\u306e\u4f59\u767d',
  Border: '\u67a0\u7dda',
  'Insert image': '\u753b\u50cf\u306e\u633f\u5165',
  'Image...': '\u753b\u50cf..',
  'Image list': '\u753b\u50cf\u4e00\u89a7',
  'Rotate counterclockwise': '\u53cd\u6642\u8a08\u56de\u308a\u306b\u56de\u8ee2',
  'Rotate clockwise': '\u6642\u8a08\u56de\u308a\u306b\u56de\u8ee2',
  'Flip vertically': '\u4e0a\u4e0b\u306b\u53cd\u8ee2',
  'Flip horizontally': '\u6c34\u5e73\u306b\u53cd\u8ee2',
  'Edit image': '\u753b\u50cf\u306e\u7de8\u96c6',
  'Image options': '\u753b\u50cf\u30aa\u30d7\u30b7\u30e7\u30f3',
  'Zoom in': '\u30ba\u30fc\u30e0\u30a4\u30f3',
  'Zoom out': '\u30ba\u30fc\u30e0\u30a2\u30a6\u30c8',
  Crop: '\u30af\u30ed\u30c3\u30d7',
  Resize: '\u30ea\u30b5\u30a4\u30ba',
  Orientation: '\u5411\u304d',
  Brightness: '\u660e\u308b\u3055',
  Sharpen: '\u30b7\u30e3\u30fc\u30d7\u5316',
  Contrast: '\u30b3\u30f3\u30c8\u30e9\u30b9\u30c8',
  'Color levels': '\u30ab\u30e9\u30fc\u30ec\u30d9\u30eb',
  Gamma: '\u30ac\u30f3\u30de',
  Invert: '\u53cd\u8ee2',
  Apply: '\u9069\u7528',
  Back: '\u623b\u308b',
  'Insert date/time': '\u65e5\u4ed8\u30fb\u6642\u523b',
  'Date/time': '\u65e5\u4ed8\u30fb\u6642\u523b',
  'Insert/Edit Link': '\u30ea\u30f3\u30af\u306e\u633f\u5165/\u7de8\u96c6',
  'Insert/edit link': '\u30ea\u30f3\u30af\u306e\u633f\u5165\u30fb\u7de8\u96c6',
  'Text to display': '\u30ea\u30f3\u30af\u5143\u30c6\u30ad\u30b9\u30c8',
  Url: '\u30ea\u30f3\u30af\u5148URL',
  'Open link in...': '\u30ea\u30f3\u30af\u306e\u958b\u304d\u65b9...',
  'Current window': '\u540c\u3058\u30a6\u30a3\u30f3\u30c9\u30a6',
  None: '\u306a\u3057',
  'New window': '\u65b0\u898f\u30a6\u30a3\u30f3\u30c9\u30a6',
  'Remove link': '\u30ea\u30f3\u30af\u306e\u524a\u9664',
  Anchors:
    '\u30a2\u30f3\u30ab\u30fc\uff08\u30ea\u30f3\u30af\u306e\u5230\u9054\u70b9\uff09',
  'Link...': '\u30ea\u30f3\u30af...',
  'Paste or type a link':
    '\u30ea\u30f3\u30af\u3092\u30da\u30fc\u30b9\u30c8\u307e\u305f\u306f\u5165\u529b',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    '\u5165\u529b\u3055\u308c\u305fURL\u306f\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u306e\u3088\u3046\u3067\u3059\u3002\u300cmailto:\u300d\u30d7\u30ec\u30d5\u30a3\u30c3\u30af\u30b9\u3092\u8ffd\u52a0\u3057\u307e\u3059\u304b\uff1f',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    '\u5165\u529b\u3055\u308c\u305fURL\u306f\u5916\u90e8\u30ea\u30f3\u30af\u306e\u3088\u3046\u3067\u3059\u3002\u300chttp://\u300d\u30d7\u30ec\u30d5\u30a3\u30c3\u30af\u30b9\u3092\u8ffd\u52a0\u3057\u307e\u3059\u304b\uff1f',
  'Link list': '\u30ea\u30f3\u30af\u4e00\u89a7',
  'Insert video': '\u52d5\u753b',
  'Insert/edit video': '\u52d5\u753b\u306e\u633f\u5165\u30fb\u7de8\u96c6',
  'Insert/edit media':
    '\u30e1\u30c7\u30a3\u30a2\u306e\u633f\u5165\u30fb\u7de8\u96c6',
  'Alternative source': '\u4ee3\u66ff\u52d5\u753b\u306e\u5834\u6240',
  'Alternative source URL': '\u4ee3\u66ff\u30bd\u30fc\u30b9URL',
  'Media poster (Image URL)':
    '\u30e1\u30c7\u30a3\u30a2\u30dd\u30b9\u30bf\u30fc (\u753b\u50cfURL)',
  'Paste your embed code below:':
    '\u57cb\u3081\u8fbc\u307f\u7528\u30b3\u30fc\u30c9\u3092\u4e0b\u8a18\u306b\u8cbc\u308a\u4ed8\u3051\u3066\u304f\u3060\u3055\u3044\u3002',
  Embed: '\u57cb\u3081\u8fbc\u307f',
  'Media...': '\u30e1\u30c7\u30a3\u30a2\u2026',
  'Nonbreaking space': '\u56fa\u5b9a\u30b9\u30da\u30fc\u30b9\uff08&nbsp;\uff09',
  'Page break': '\u30da\u30fc\u30b8\u533a\u5207\u308a',
  'Paste as text':
    '\u30c6\u30ad\u30b9\u30c8\u3068\u3057\u3066\u8cbc\u308a\u4ed8\u3051',
  Preview: '\u30d7\u30ec\u30d3\u30e5\u30fc',
  'Print...': '\u5370\u5237...',
  Save: '\u4fdd\u5b58',
  Find: '\u691c\u7d22',
  'Replace with': '\u7f6e\u304d\u63db\u3048\u308b\u6587\u5b57',
  Replace: '\u7f6e\u304d\u63db\u3048',
  'Replace all': '\u5168\u3066\u3092\u7f6e\u304d\u63db\u3048\u308b',
  Previous: '\u524d\u3078',
  Next: '\u6b21',
  'Find and replace...': '\u7f6e\u63db...',
  'Could not find the specified string.':
    '\u304a\u63a2\u3057\u306e\u6587\u5b57\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f\u3002',
  'Match case':
    '\u5927\u6587\u5b57\u30fb\u5c0f\u6587\u5b57\u3092\u533a\u5225\u3059\u308b',
  'Find whole words only':
    '\u8a9e\u5168\u4f53\u3092\u542b\u3080\u3082\u306e\u306e\u307f\u691c\u7d22',
  'Spell check': '\u30b9\u30da\u30eb\u30c1\u30a7\u30c3\u30af',
  Ignore: '\u7121\u8996',
  'Ignore all': '\u5168\u3066\u3092\u7121\u8996',
  Finish: '\u7d42\u4e86',
  'Add to Dictionary': '\u8f9e\u66f8\u306b\u8ffd\u52a0',
  'Insert table': '\u8868\u306e\u633f\u5165',
  'Table properties': '\u8868\u306e\u8a73\u7d30\u8a2d\u5b9a',
  'Delete table': '\u8868\u306e\u524a\u9664',
  Cell: '\u30bb\u30eb',
  Row: '\u884c',
  Column: '\u5217',
  'Cell properties': '\u30bb\u30eb\u306e\u8a73\u7d30\u8a2d\u5b9a',
  'Merge cells': '\u30bb\u30eb\u306e\u7d50\u5408',
  'Split cell': '\u30bb\u30eb\u306e\u5206\u5272',
  'Insert row before': '\u4e0a\u5074\u306b\u884c\u3092\u633f\u5165',
  'Insert row after': '\u4e0b\u5074\u306b\u884c\u3092\u633f\u5165',
  'Delete row': '\u884c\u306e\u524a\u9664',
  'Row properties': '\u884c\u306e\u8a73\u7d30\u8a2d\u5b9a',
  'Cut row': '\u884c\u306e\u5207\u308a\u53d6\u308a',
  'Copy row': '\u884c\u306e\u30b3\u30d4\u30fc',
  'Paste row before': '\u4e0a\u5074\u306b\u884c\u3092\u8cbc\u308a\u4ed8\u3051',
  'Paste row after': '\u4e0b\u5074\u306b\u884c\u3092\u8cbc\u308a\u4ed8\u3051',
  'Insert column before': '\u5de6\u5074\u306b\u5217\u3092\u633f\u5165',
  'Insert column after': '\u53f3\u5074\u306b\u5217\u3092\u633f\u5165',
  'Delete column': '\u5217\u306e\u524a\u9664',
  Cols: '\u5217\u6570',
  Rows: '\u884c\u6570',
  Width: '\u5e45',
  Height: '\u9ad8\u3055',
  'Cell spacing': '\u30bb\u30eb\u306e\u9593\u9694',
  'Cell padding':
    '\u30bb\u30eb\u5185\u4f59\u767d\uff08\u30d1\u30c7\u30a3\u30f3\u30b0\uff09',
  'Show caption': '\u30ad\u30e3\u30d7\u30b7\u30e7\u30f3\u306e\u8868\u793a',
  Left: '\u5de6\u5bc4\u305b',
  Center: '\u4e2d\u592e\u63c3\u3048',
  Right: '\u53f3\u5bc4\u305b',
  'Cell type': '\u30bb\u30eb\u30bf\u30a4\u30d7',
  Scope: '\u30b9\u30b3\u30fc\u30d7',
  Alignment: '\u914d\u7f6e',
  'H Align': '\u6c34\u5e73\u65b9\u5411\u306e\u914d\u7f6e',
  'V Align': '\u5782\u76f4\u65b9\u5411\u306e\u914d\u7f6e',
  Top: '\u4e0a',
  Middle: '\u4e2d\u592e',
  Bottom: '\u4e0b',
  'Header cell': '\u30d8\u30c3\u30c0\u30fc\u30bb\u30eb',
  'Row group': '\u884c\u30b0\u30eb\u30fc\u30d7',
  'Column group': '\u5217\u30b0\u30eb\u30fc\u30d7',
  'Row type': '\u884c\u30bf\u30a4\u30d7',
  Header: '\u30d8\u30c3\u30c0\u30fc',
  Body: '\u30dc\u30c7\u30a3\u30fc',
  Footer: '\u30d5\u30c3\u30bf\u30fc',
  'Border color': '\u67a0\u7dda\u306e\u8272',
  'Insert template...':
    '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u306e\u633f\u5165..',
  Templates: '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u540d',
  Template: '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8',
  'Text color': '\u30c6\u30ad\u30b9\u30c8\u306e\u8272',
  'Background color': '\u80cc\u666f\u8272',
  'Custom...': '\u30ab\u30b9\u30bf\u30e0...',
  'Custom color': '\u30ab\u30b9\u30bf\u30e0\u30ab\u30e9\u30fc',
  'No color': '\u30ab\u30e9\u30fc\u306a\u3057',
  'Remove color': '\u8272\u8a2d\u5b9a\u3092\u89e3\u9664',
  'Table of Contents': '\u76ee\u6b21',
  'Show blocks':
    '\u6587\u7ae0\u306e\u533a\u5207\u308a\u3092\u70b9\u7dda\u3067\u8868\u793a',
  'Show invisible characters':
    '\u4e0d\u53ef\u8996\u6587\u5b57\u3092\u8868\u793a',
  'Word count': '\u6587\u5b57\u6570\u30ab\u30a6\u30f3\u30c8',
  Count: '\u30ab\u30a6\u30f3\u30c8',
  Document: '\u30c9\u30ad\u30e5\u30e1\u30f3\u30c8',
  Selection: '\u9078\u629e',
  Words: '\u5358\u8a9e\u6570',
  'Words: {0}': '\u5358\u8a9e\u6570: {0}',
  '{0} words': '{0} \u30ef\u30fc\u30c9',
  File: '\u30d5\u30a1\u30a4\u30eb',
  Edit: '\u7de8\u96c6',
  Insert: '\u633f\u5165',
  View: '\u8868\u793a',
  Format: '\u66f8\u5f0f',
  Table: '\u8868',
  Tools: '\u30c4\u30fc\u30eb',
  'Powered by {0}': 'Powered by {0}',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    '\u66f8\u5f0f\u4ed8\u304d\u30c6\u30ad\u30b9\u30c8\u306e\u7de8\u96c6\u753b\u9762\u3002ALT-F9\u3067\u30e1\u30cb\u30e5\u30fc\u3001ALT-F10\u3067\u30c4\u30fc\u30eb\u30d0\u30fc\u3001ALT-0\u3067\u30d8\u30eb\u30d7\u304c\u8868\u793a\u3055\u308c\u307e\u3059\u3002',
  'Image title': '\u753b\u50cf\u30bf\u30a4\u30c8\u30eb',
  'Border width': '\u67a0\u7dda\u5e45',
  'Border style': '\u67a0\u7dda\u30b9\u30bf\u30a4\u30eb',
  Error: '\u30a8\u30e9\u30fc',
  Warn: '\u8b66\u544a',
  Valid: '\u6709\u52b9',
  'To open the popup, press Shift+Enter':
    '\u30dd\u30c3\u30d7\u30a2\u30c3\u30d7\u3092\u958b\u304f\u306b\u306f\u3001Shift+Enter\u3092\u62bc\u3057\u3066\u304f\u3060\u3055\u3044',
  'Rich Text Area. Press ALT-0 for help.':
    '\u30ea\u30c3\u30c1\u30c6\u30ad\u30b9\u30c8\u30a8\u30ea\u30a2\u3002Alt-0\u3067\u30d8\u30eb\u30d7\u304c\u8868\u793a\u3055\u308c\u307e\u3059\u3002',
  'System Font': '\u30b7\u30b9\u30c6\u30e0\u30d5\u30a9\u30f3\u30c8',
  'Failed to upload image: {0}':
    '\u753b\u50cf{0}\u3092\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f',
  'Failed to load plugin: {0} from url {1}':
    'URL{1}\u304b\u3089\u306e\u30d7\u30e9\u30b0\u30a4\u30f3{0}\u306e\u8aad\u307f\u8fbc\u307f\u306b\u5931\u6557\u3057\u307e\u3057\u305f',
  'Failed to load plugin url: {0}':
    '\u30d7\u30e9\u30b0\u30a4\u30f3\u306eURL{0}\u3092\u8aad\u307f\u8fbc\u3081\u307e\u305b\u3093\u3067\u3057\u305f',
  'Failed to initialize plugin: {0}':
    '\u30d7\u30e9\u30b0\u30a4\u30f3{0}\u306e\u521d\u671f\u5316\u306b\u5931\u6557\u3057\u307e\u3057\u305f',
  example: '\u4f8b',
  Search: '\u691c\u7d22',
  All: '\u3059\u3079\u3066',
  Currency: '\u901a\u8ca8',
  Text: '\u30c6\u30ad\u30b9\u30c8',
  Quotations: '\u5f15\u7528',
  Mathematical: '\u6570\u5b66\u8a18\u53f7',
  'Extended Latin': '\u30e9\u30c6\u30f3\u6587\u5b57\u62e1\u5f35',
  Symbols: '\u8a18\u53f7',
  Arrows: '\u77e2\u5370',
  'User Defined': '\u30e6\u30fc\u30b6\u30fc\u5b9a\u7fa9',
  'dollar sign': '\u30c9\u30eb\u8a18\u53f7',
  'currency sign': '\u901a\u8ca8\u8a18\u53f7',
  'euro-currency sign': '\u30e6\u30fc\u30ed\u8a18\u53f7',
  'colon sign': '\u30b3\u30ed\u30f3\u8a18\u53f7',
  'cruzeiro sign': '\u30af\u30eb\u30bc\u30a4\u30ed\u8a18\u53f7',
  'french franc sign': '\u30d5\u30e9\u30f3\u30b9\u30d5\u30e9\u30f3\u8a18\u53f7',
  'lira sign': '\u30ea\u30e9\u8a18\u53f7',
  'mill sign': '\u30df\u30eb\u8a18\u53f7',
  'naira sign': '\u30ca\u30a4\u30e9\u8a18\u53f7',
  'peseta sign': '\u30da\u30bb\u30bf\u8a18\u53f7',
  'rupee sign': '\u30eb\u30d4\u30fc\u8a18\u53f7',
  'won sign': '\u30a6\u30a9\u30f3\u8a18\u53f7',
  'new sheqel sign': '\u65b0\u30b7\u30a7\u30b1\u30eb\u8a18\u53f7',
  'dong sign': '\u30c9\u30f3\u8a18\u53f7',
  'kip sign': '\u30ad\u30fc\u30d7\u8a18\u53f7',
  'tugrik sign': '\u30c8\u30a5\u30b0\u30eb\u30b0\u8a18\u53f7',
  'drachma sign': '\u30c9\u30e9\u30af\u30de\u8a18\u53f7',
  'german penny symbol': '\u30c9\u30a4\u30c4\u30da\u30cb\u30fc\u8a18\u53f7',
  'peso sign': '\u30da\u30bd\u8a18\u53f7',
  'guarani sign': '\u30ac\u30e9\u30cb\u8a18\u53f7',
  'austral sign': '\u30a2\u30a6\u30b9\u30c8\u30e9\u30eb\u8a18\u53f7',
  'hryvnia sign': '\u30d5\u30ea\u30f4\u30cb\u30e3\u8a18\u53f7',
  'cedi sign': '\u30bb\u30c7\u30a3\u8a18\u53f7',
  'livre tournois sign':
    '\u30c8\u30a5\u30fc\u30eb\u30dd\u30f3\u30c9\u8a18\u53f7',
  'spesmilo sign': '\u30b9\u30da\u30b9\u30df\u30fc\u30ed\u8a18\u53f7',
  'tenge sign': '\u30c6\u30f3\u30b2\u8a18\u53f7',
  'indian rupee sign': '\u30a4\u30f3\u30c9\u30eb\u30d4\u30fc\u8a18\u53f7',
  'turkish lira sign': '\u30c8\u30eb\u30b3\u30ea\u30e9\u8a18\u53f7',
  'nordic mark sign': '\u5317\u6b27\u30de\u30eb\u30af\u8a18\u53f7',
  'manat sign': '\u30de\u30ca\u30c8\u8a18\u53f7',
  'ruble sign': '\u30eb\u30fc\u30d6\u30eb\u8a18\u53f7',
  'yen character': '\u5186\u8a18\u53f7',
  'yuan character': '\u4eba\u6c11\u5143\u8a18\u53f7',
  'yuan character, in hong kong and taiwan':
    '\u9999\u6e2f\u304a\u3088\u3073\u53f0\u6e7e\u306b\u304a\u3051\u308b\u5143\u8a18\u53f7',
  'yen/yuan character variant one':
    '\u5186/\u5143\u8a18\u53f7\u306e\u30d0\u30ea\u30a8\u30fc\u30b7\u30e7\u30f3',
  'Loading emoticons...':
    '\u7d75\u6587\u5b57\u3092\u8aad\u307f\u8fbc\u3093\u3067\u3044\u307e\u3059...',
  'Could not load emoticons':
    '\u7d75\u6587\u5b57\u304c\u8aad\u307f\u8fbc\u3081\u307e\u305b\u3093\u3067\u3057\u305f\u3002',
  People: '\u4eba',
  'Animals and Nature': '\u52d5\u7269\u3068\u81ea\u7136',
  'Food and Drink': '\u98df\u3079\u7269\u3068\u98f2\u307f\u7269',
  Activity: '\u884c\u52d5',
  'Travel and Places': '\u65c5\u884c\u3068\u5834\u6240',
  Objects: '\u7269',
  Flags: '\u65d7',
  Characters: '\u6587\u5b57\u6570',
  'Characters (no spaces)':
    '\u6587\u5b57\u6570 (\u30b9\u30da\u30fc\u30b9\u306a\u3057)',
  '{0} characters': '{0}\u6587\u5b57',
  'Error: Form submit field collision.':
    '\u30a8\u30e9\u30fc\uff1a\u30d5\u30a9\u30fc\u30e0\u9001\u4fe1\u30d5\u30a3\u30fc\u30eb\u30c9\u304c\u7af6\u5408\u3057\u3066\u3044\u307e\u3059\u3002',
  'Error: No form element found.':
    '\u30a8\u30e9\u30fc\uff1a\u30d5\u30a9\u30fc\u30e0\u8981\u7d20\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f\u3002',
  Update: '\u66f4\u65b0',
  'Color swatch': '\u8272\u306e\u898b\u672c',
  Turquoise: '\u30bf\u30fc\u30b3\u30a4\u30ba',
  Green: '\u30b0\u30ea\u30fc\u30f3',
  Blue: '\u30d6\u30eb\u30fc',
  Purple: '\u30d1\u30fc\u30d7\u30eb',
  'Navy Blue': '\u30cd\u30a4\u30d3\u30fc',
  'Dark Turquoise': '\u30c0\u30fc\u30af\u30bf\u30fc\u30b3\u30a4\u30ba',
  'Dark Green': '\u30c0\u30fc\u30af\u30b0\u30ea\u30fc\u30f3',
  'Medium Blue': '\u30e1\u30c7\u30a3\u30a2\u30e0\u30d6\u30eb\u30fc',
  'Medium Purple': '\u30df\u30c7\u30a3\u30a2\u30e0\u30d1\u30fc\u30d7\u30eb',
  'Midnight Blue': '\u30df\u30c3\u30c9\u30ca\u30a4\u30c8\u30d6\u30eb\u30fc',
  Yellow: '\u30a4\u30a8\u30ed\u30fc',
  Orange: '\u30aa\u30ec\u30f3\u30b8',
  Red: '\u30ec\u30c3\u30c9',
  'Light Gray': '\u30e9\u30a4\u30c8\u30b0\u30ec\u30fc',
  Gray: '\u30b0\u30ec\u30fc',
  'Dark Yellow': '\u30c0\u30fc\u30af\u30a4\u30a8\u30ed\u30fc',
  'Dark Orange': '\u30c0\u30fc\u30af\u30aa\u30ec\u30f3\u30b8',
  'Dark Red': '\u30c0\u30fc\u30af\u30ec\u30c3\u30c9',
  'Medium Gray': '\u30df\u30c7\u30a3\u30a2\u30e0\u30b0\u30ec\u30fc',
  'Dark Gray': '\u30c0\u30fc\u30af\u30b0\u30ec\u30fc',
  'Light Green': '\u30e9\u30a4\u30c8\u30b0\u30ea\u30fc\u30f3',
  'Light Yellow': '\u30e9\u30a4\u30c8\u30a4\u30a8\u30ed\u30fc',
  'Light Red': '\u30e9\u30a4\u30c8\u30ec\u30c3\u30c9',
  'Light Purple': '\u30e9\u30a4\u30c8\u30d1\u30fc\u30d7\u30eb',
  'Light Blue': '\u30e9\u30a4\u30c8\u30d6\u30eb\u30fc',
  'Dark Purple': '\u30c0\u30fc\u30af\u30d1\u30fc\u30d7\u30eb',
  'Dark Blue': '\u30c0\u30fc\u30af\u30d6\u30eb\u30fc',
  Black: '\u30d6\u30e9\u30c3\u30af',
  White: '\u30db\u30ef\u30a4\u30c8',
  'Switch to or from fullscreen mode':
    '\u30d5\u30eb\u30b9\u30af\u30ea\u30fc\u30f3\u30e2\u30fc\u30c9\u5207\u66ff',
  'Open help dialog':
    '\u30d8\u30eb\u30d7\u30c0\u30a4\u30a2\u30ed\u30b0\u3092\u958b\u304f',
  history: '\u5c65\u6b74',
  styles: '\u30b9\u30bf\u30a4\u30eb',
  formatting: '\u66f8\u5f0f',
  alignment: '\u914d\u7f6e',
  indentation: '\u30a4\u30f3\u30c7\u30f3\u30c8',
  'permanent pen': '\u86cd\u5149\u30da\u30f3',
  comments: '\u30b3\u30e1\u30f3\u30c8',
  'Format Painter':
    '\u66f8\u5f0f\u306e\u30b3\u30d4\u30fc/\u8cbc\u308a\u4ed8\u3051',
  'Insert/edit iframe': 'iframe\u306e\u633f\u5165/\u7de8\u96c6',
  Capitalization: '\u5927\u6587\u5b57\u5316',
  lowercase: '\u5c0f\u6587\u5b57',
  UPPERCASE: '\u5927\u6587\u5b57',
  'Title Case': '\u30bf\u30a4\u30c8\u30eb\u6587\u5b57',
  'Permanent Pen Properties':
    '\u86cd\u5149\u30da\u30f3\u306e\u30d7\u30ed\u30d1\u30c6\u30a3',
  'Permanent pen properties...':
    '\u86cd\u5149\u30da\u30f3\u306e\u30d7\u30ed\u30d1\u30c6\u30a3...',
  Font: '\u30d5\u30a9\u30f3\u30c8',
  Size: '\u30b5\u30a4\u30ba',
  'More...': '\u8a73\u7d30...',
  'Spellcheck Language':
    '\u8a00\u8a9e\u306e\u30b9\u30da\u30eb\u30c1\u30a7\u30c3\u30af',
  'Select...': '\u9078\u629e...',
  Preferences: '\u30d7\u30ea\u30d5\u30a1\u30ec\u30f3\u30b9',
  Yes: '\u306f\u3044',
  No: '\u3044\u3044\u3048',
  'Keyboard Navigation':
    '\u30ad\u30fc\u30dc\u30fc\u30c9\u30ca\u30d3\u30b2\u30fc\u30b7\u30e7\u30f3',
  Version: '\u30d0\u30fc\u30b8\u30e7\u30f3',
  Anchor:
    '\u30a2\u30f3\u30ab\u30fc\uff08\u30ea\u30f3\u30af\u306e\u5230\u9054\u70b9\uff09',
  'Special character': '\u7279\u6b8a\u6587\u5b57',
  'Code sample': '\u30b3\u30fc\u30c9\u30b5\u30f3\u30d7\u30eb',
  Color: '\u30ab\u30e9\u30fc',
  Emoticons: '\u7d75\u6587\u5b57',
  'Document properties':
    '\u30c9\u30ad\u30e5\u30e1\u30f3\u30c8\u306e\u30d7\u30ed\u30d1\u30c6\u30a3',
  Image: '\u753b\u50cf',
  'Insert link': '\u30ea\u30f3\u30af',
  Target: '\u30bf\u30fc\u30b2\u30c3\u30c8\u5c5e\u6027',
  Link: '\u30ea\u30f3\u30af',
  Poster: '\u4ee3\u66ff\u753b\u50cf\u306e\u5834\u6240',
  Media: '\u30e1\u30c7\u30a3\u30a2',
  Print: '\u5370\u5237',
  Prev: '\u524d',
  'Find and replace': '\u691c\u7d22\u3068\u7f6e\u304d\u63db\u3048',
  'Whole words': '\u5358\u8a9e\u5358\u4f4d\u3067\u691c\u7d22\u3059\u308b',
  Spellcheck: '\u30b9\u30da\u30eb\u30c1\u30a7\u30c3\u30af',
  Caption: '\u8868\u984c',
  'Insert template': '\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u306e\u633f\u5165',
  'Open link': '\u30ea\u30f3\u30af\u3092\u958b\u304f',
  	    Solid: 'Solid',
  Dotted: 'Dotted',
  Dashed: 'Dashed',
  Double: '\u4e8c\u91cd',
  Groove: 'Groove',
  Ridge: 'Ridge',
  Inset: 'Inset',
  Outset: 'Outset',
  Hidden: '\u975e\u8868\u793a',
});
