import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Button, { ButtonSizes } from 'components/buttons/action';
import { productTourSteps } from 'web/productTour/containers/productTourStepsContainer/lib/productTourConstants';
import './step3.scss';

const Step3 = (props) => {
  const { changeTab, productTourLoadedImages } = props;
  const imgURL = productTourLoadedImages.length
    ? `url(${productTourLoadedImages[2]})`
    : `url(${getImageUrl('productTour/1920x1080/step3', 'png')})`;

  return (
    <div className="productTour-step3-rightColumn-wrapper">
      <div className="productTour-step3-content">
        <h1>
          <FormattedHTMLMessage id={'web.productTour.salesLiveFeedTitle'} />
        </h1>
        <br />
        <div>
          <FormattedHTMLMessage id={'web.productTour.salesLiveFeedInfo'} />
        </div>
        <Button
          onClick={() => changeTab(productTourSteps.step4)}
          size={ButtonSizes.medium}
        >
          <FormattedMessage id="common.next" />
        </Button>
      </div>
      <div
        className="productTour-step3-screen"
        style={{
          backgroundImage: imgURL,
        }}
      />
    </div>
  );
};

Step3.defaultProps = {
  changeTab: () => {},
};

Step3.propTypes = {
  changeTab: PropTypes.func,
  productTourLoadedImages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  productTourLoadedImages: state.productTourLoadedImages,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3);
