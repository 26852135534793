import { ValidationError } from 'web/form/helpers/validators';
import {
  EmailServerValidatorTypes,
  EmailServerValidationErrorMessageIds,
} from '../libs/emailServerConstants';
import {
  GMAIL_SMTP_SERVERS,
  OFFICE365_SMTP_SERVERS,
} from '../libs/emailServerConstants';

export const notGmailSmtp = (
  messageId = EmailServerValidationErrorMessageIds.gmailSmtp
) => (value) =>
  value && GMAIL_SMTP_SERVERS.includes(value.trim())
    ? new ValidationError(EmailServerValidatorTypes.notGmailSmtp, messageId, {
        value,
      })
    : undefined;

export const notOffice365Smtp = (
  messageId = EmailServerValidationErrorMessageIds.office365Smtp
) => (value) =>
  value && OFFICE365_SMTP_SERVERS.includes(value.trim())
    ? new ValidationError(
        EmailServerValidatorTypes.notOffice365Smtp,
        messageId,
        { value }
      )
    : undefined;
