import { basePost } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const updateGroupSharedWith = (id, data) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.groupUpdateSharedWith.replace(':#id', id),
      data,
      resolve,
      reject
    );
  });
