import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeHealthStatuses } from '../actionCreators/yourIntegrationsActionCreators';
import {
  getIntegrationsHealthStatuses,
  getIntegrationsHealthStatusesFetching,
} from '../selectors/yourIntegrationsSelectors';
import YourIntegrations from '../components/yourIntegrations';
import {
  isAdmin,
  isAdminMarketoAuthEnabled,
  isNewUser,
} from 'web/user/selectors/userSelectors';
import { getSalesforceConnectLink } from 'web/salesforce/selectors/salesforceSelectors';
import {
  getDeliveryChannels as getDeliveryChannelsSelector,
  getDeliveryChannelOfDefaultIdentity,
} from 'web/deliveryChannels/selectors/deliveryChannelsSelectors';
import { getDefaultIdentity } from 'web/user/selectors/identitiesSelectors';
import { getIdentities } from 'web/user/actionCreators/identitiesActionCreators';
import { getDeliveryChannels } from 'web/deliveryChannels/actionCreators/deliveryChannelsActionCreators';

const YourIntegrationsContainer = (props) => <YourIntegrations {...props} />;

YourIntegrationsContainer.propTypes = {
  currentDeliveryChannel: PropTypes.object,
  defaultIdentity: PropTypes.object,
  deliveryChannels: PropTypes.array,
  fetching: PropTypes.bool,
  getDeliveryChannels: PropTypes.func.isRequired,
  getIdentities: PropTypes.func.isRequired,
  initializeHealthStatuses: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAdminMarketoAuthEnabled: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool.isRequired,
  salesforceConnectLink: PropTypes.string.isRequired,
  statuses: PropTypes.object,
};

YourIntegrationsContainer.defaultProps = {
  currentDeliveryChannel: null,
  defaultIdentity: {},
  deliveryChannels: [],
  fetching: false,
  statuses: {},
};

const mapStateToProps = (state) => ({
  currentDeliveryChannel: getDeliveryChannelOfDefaultIdentity(state),
  defaultIdentity: getDefaultIdentity(state),
  deliveryChannels: getDeliveryChannelsSelector(state),
  fetching:
    state.deliveryChannelsFetching ||
    state.identitiesFetching ||
    getIntegrationsHealthStatusesFetching(state),
  isAdmin: isAdmin(state),
  isAdminMarketoAuthEnabled: isAdminMarketoAuthEnabled(state),
  isNewUser: isNewUser(state),
  salesforceConnectLink: getSalesforceConnectLink(state),
  statuses: getIntegrationsHealthStatuses(state),
});

const mapDispatchToProps = {
  getDeliveryChannels,
  getIdentities,
  initializeHealthStatuses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourIntegrationsContainer);
