import { baseDelete, baseGet, basePost } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getGroups() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.groups, {}, resolve, reject);
  });
}

export function getGroup(id) {
  return new Promise((resolve, reject) => {
    baseGet(Urls.groupId(id), {}, resolve, reject);
  });
}

export function getPeopleByGroup(id, limit = 1000, offset = 0) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.groupPeople.replace(':#id', id),
      { limit, offset },
      resolve,
      reject
    );
  });
}

export function addToGroup(groupId, addressId) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.groupMembers.replace(':#id', groupId),
      {
        group_member: {
          active: true,
          address_id: addressId,
          group_id: groupId,
        },
      },
      resolve,
      reject
    );
  });
}

export function removeFromGroup(groupId, groupMemberId) {
  return new Promise((resolve, reject) => {
    baseDelete(
      `${Urls.groupMembers.replace(':#id', groupId)}/${groupMemberId}`,
      {},
      resolve,
      reject
    );
  });
}

export function bulkAddToGroups(groupMembers) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.groupMembersBulk,
      { group_members: groupMembers },
      resolve,
      reject
    );
  });
}

export function bulkRemoveFromGroups(groupMembers) {
  return new Promise((resolve, reject) => {
    baseDelete(
      Urls.groupMembersBulk,
      { group_members: groupMembers },
      resolve,
      reject
    );
  });
}
