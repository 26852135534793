import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import BarChart, { BarChartColors } from 'components/stats/barChart';

const LENGTH_BARS = 400;
const LENGTH_LABELS = 175;

class AnalyticsEngagementCard extends Component {
  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.noEngagementLabel = formatMessage({
      id: 'web.editTemplateSlideOut.analyticsEngagementCard.noEngagement',
    });
    this.viewsLabel = formatMessage({ id: 'common.views' });
    this.clicksLabel = formatMessage({ id: 'common.clicks' });
    this.repliesLabel = formatMessage({ id: 'common.replies' });
    this.successfulLabel = formatMessage({ id: 'common.successful' });
  }

  render() {
    const { stats, total } = this._formatData();

    return (
      <div className="edit-template-analytics-engagement-card">
        <h4 className="email-card-subject text-medium text-overflow margin-bottom-full">
          <FormattedMessage id="web.editTemplateSlideOut.analyticsEngagementCard.header" />
        </h4>
        <div className="analytics-engagement-stats">
          <BarChart
            lengthBars={LENGTH_BARS}
            lengthLabels={LENGTH_LABELS}
            showCount
            showLabels
            stats={stats}
            total={total}
          />
        </div>
      </div>
    );
  }

  _formatData = () => {
    const {
      clicked,
      noEngagement,
      replied,
      successful,
      totalEngagement,
      viewed,
    } = this.props;

    return {
      stats: [
        {
          color: BarChartColors.red,
          count: noEngagement,
          label: this.noEngagementLabel,
        },
        {
          color: BarChartColors.blueLight,
          count: viewed,
          label: this.viewsLabel,
        },
        {
          color: BarChartColors.tourquoise,
          count: clicked,
          label: this.clicksLabel,
        },
        {
          color: BarChartColors.pink,
          count: replied,
          label: this.repliesLabel,
        },
        {
          color: BarChartColors.green,
          count: successful,
          label: this.successfulLabel,
        },
      ],
      total: totalEngagement,
    };
  };
}

AnalyticsEngagementCard.propTypes = {
  clicked: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  noEngagement: PropTypes.number.isRequired,
  replied: PropTypes.number.isRequired,
  successful: PropTypes.number.isRequired,
  totalEngagement: PropTypes.number.isRequired,
  viewed: PropTypes.number.isRequired,
};

AnalyticsEngagementCard.defaultProps = {};

export default injectIntl(AnalyticsEngagementCard);
