import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';

const DeleteCustomDomainConfirmationPopup = (props) => {
  const {
    actionCreators: { closePopup, deleteCustomDomain },
  } = props;

  return (
    <Popup
      footer={{
        onPrimary: () => {
          deleteCustomDomain();
          closePopup();
        },
        onSecondary: closePopup,
        primaryTextId: 'common.ok',
        secondaryTextId: 'common.cancel',
      }}
      header={{
        onClose: closePopup,
        textId:
          'web.settings.adminSettings.tracking.customDomainTracking.title',
      }}
      onOutsideClick={closePopup}
      size={PopupSize.small}
    >
      <FormattedMessage id="web.settings.adminSettings.tracking.customDomainTracking.form.customDomain.delete.areYouSure" />
    </Popup>
  );
};

DeleteCustomDomainConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

export default DeleteCustomDomainConfirmationPopup;
