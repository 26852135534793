import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Radio from 'components/buttons/radio';
import { EmailProviders } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import './emailConnectionSelectionBody.scss';

class EmailConnectionSelectionBody extends Component {
  render() {
    const {
      emailConnectionProvider,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="email-connection-selection-body">
        <h5 className="email-selection-subtitle">
          <FormattedMessage id="web.settings.emailConnection.modal.askEmail" />
        </h5>
        <Radio
          name="email-connection-selection"
          className="email-connection-selection-radio"
          label={formatMessage({
            id: 'web.settings.emailConnection.modal.useGmail',
          })}
          value={EmailProviders.gmail}
          onChange={this.onChange}
          checked={emailConnectionProvider === EmailProviders.gmail}
        />
        <Radio
          name="email-connection-selection"
          className="email-connection-selection-radio"
          label={formatMessage({
            id: 'web.settings.emailConnection.modal.useOutlook',
          })}
          value={EmailProviders.outlook}
          onChange={this.onChange}
          checked={emailConnectionProvider === EmailProviders.outlook}
        />
      </div>
    );
  }

  onChange = (currentSelection) => {
    const { setEmailProvider } = this.props;

    setEmailProvider(currentSelection);
  };

  static propTypes = {
    emailConnectionProvider: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    setEmailProvider: PropTypes.func.isRequired,
  };
}

export default injectIntl(EmailConnectionSelectionBody);
