import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedHTMLMessage } from 'react-intl';
import Page from 'components/layouts/page/page';
import PageHeader from 'components/layouts/page/header';
import PageView from 'components/layouts/page/view';
import {
  removeItems,
  retryItems,
  getItems,
  clearAlert,
} from '../actionCreators/diagnosticsActionCreators';
import {
  onDatesSmartFilterChange,
  onTableMount,
  onStatusSmartFilterChange,
} from '../actionCreators/diagnosticsStateActionCreators';
import DiagnosticsTable from '../components/diagnosticsTable';
import {
  TABLE_ID,
  COLUMN_SETTINGS_DIAGNOSTICS_TABLE_ID,
} from '../libs/diagnosticsTableConstants';
import {
  getTableStatusSmartFilter,
  getFilteredItems,
  getTableSelectedDates,
} from '../selectors/diagnosticsSelectors';
import Alert, { AlertColors } from 'components/alert';
import { getSubscriptionUsers } from 'web/task/selectors/taskSelectors';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import { getUserColumnSettingsForTable } from 'web/user/selectors/userSelectors';
import { changeColumnsSettings } from 'web/user/actionCreators/userActionCreators';
import './diagnosticsContainer.scss';

class DiagnosticsContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = {
      changeColumnsSettings: (columnsSettings) =>
        dispatch(
          changeColumnsSettings(
            COLUMN_SETTINGS_DIAGNOSTICS_TABLE_ID,
            columnsSettings
          )
        ),
      clearAlert: () => dispatch(clearAlert()),
      getItems: () => dispatch(getItems()),
      onItemsDatesSmartFilterChange: (value, start, end) =>
        dispatch(onDatesSmartFilterChange(TABLE_ID, value, start, end)),
      onItemsStatusSmartFilterChange: (index, value) =>
        dispatch(onStatusSmartFilterChange(TABLE_ID, value)),
      onTableMount: () => dispatch(onTableMount(TABLE_ID)),
      removeItems: (ids, callback) => dispatch(removeItems(ids, callback)),
      retryItems: (ids, callback) => dispatch(retryItems(ids, callback)),
    };
  }

  componentDidMount() {
    this.props.dispatch(getItems());
  }

  render() {
    const {
      columnSettings,
      dates,
      datesSelectedValue,
      fetching,
      itemsDiagnostics,
      loading,
      error,
      statusSelectedValue,
      user,
      usersMasterTeamSubscription,
    } = this.props;

    const alertProperties = {
      color: AlertColors.redDark,
      location: 'top',
      onClose: this.actionCreators.clearAlert,
    };

    return (
      <div className="tout-ui-styles diagnostics-container">
        {error && (
          <Alert
            {...alertProperties}
            className="diagnostic-alert"
            textId="web.settings.sharing.alert.genericIssue"
          />
        )}
        <Page>
          <PageHeader
            rightChildren={
              <p className="diagnostics-warning-message">
                <FormattedHTMLMessage id="web.diagnostics.warning" />
              </p>
            }
            textId="common.diagnostics"
            fillHeight
          />
          {!fetching ? (
            <PageView>
              <DiagnosticsTable
                actionCreators={this.actionCreators}
                columnSettings={columnSettings}
                dates={dates}
                datesSelectedValue={datesSelectedValue}
                fetching={fetching}
                items={itemsDiagnostics}
                loading={loading}
                statusSelectedValue={statusSelectedValue}
                user={user}
                usersMasterTeamSubscription={usersMasterTeamSubscription}
              />
            </PageView>
          ) : (
            <LoadingSpinner imageUrl={getImageUrl} />
          )}
        </Page>
      </div>
    );
  }
}

DiagnosticsContainer.propTypes = {
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  dates: PropTypes.object.isRequired,
  datesSelectedValue: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.bool,
  fetching: PropTypes.bool,
  items: PropTypes.array,
  itemsDiagnostics: PropTypes.array,
  loading: PropTypes.bool,
  statusSelectedValue: PropTypes.string.isRequired,
  usersMasterTeamSubscription: PropTypes.array,
};

DiagnosticsContainer.defaultProps = {
  columnSettings: [],
  error: null,
  fetching: false,
  items: [],
  itemsDiagnostics: [],
  loading: false,
  usersMasterTeamSubscription: [],
};

function mapStateToProps(state, ownProps) {
  return {
    columnSettings: getUserColumnSettingsForTable(
      state,
      COLUMN_SETTINGS_DIAGNOSTICS_TABLE_ID
    ),
    dates: getTableSelectedDates(state, ownProps),
    datesSelectedValue: getTableSelectedDates(state, ownProps),
    error: state.diagnostics.error,
    fetching: state.diagnostics.fetching,
    items: getFilteredItems(state),
    itemsDiagnostics: state.diagnostics.items,
    loading: state.diagnostics.loading,
    statusSelectedValue: getTableStatusSmartFilter(state),
    user: state.user,
    usersMasterTeamSubscription: getSubscriptionUsers(state),
  };
}

export default connect(mapStateToProps)(DiagnosticsContainer);
