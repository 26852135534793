import React from 'react';
import PropTypes from 'prop-types';
import LiveFeedStats from 'web/liveFeed/components/liveFeedHeader/liveFeedStats';
import LiveFeedCelebration from 'web/liveFeed/components/liveFeedHeader/liveFeedCelebration';
import { displayLiveFeedCelebration } from 'web/liveFeed/helpers/liveFeedParsers';

const LiveFeedHeader = ({
  actionCreators: { updateChartEvent },
  analytics,
  chartEvent,
  dealInformation: { amount, rep },
}) =>
  (displayLiveFeedCelebration(amount))? (
    <LiveFeedCelebration amount={amount} rep={rep} />
  ) : (
    <LiveFeedStats
      analytics={analytics}
      chartEvent={chartEvent}
      updateChartEvent={updateChartEvent}
    />
  );

LiveFeedHeader.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  chartEvent: PropTypes.string.isRequired,
  dealInformation: PropTypes.object.isRequired,
};

export default LiveFeedHeader;
