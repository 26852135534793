import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import { Origins } from 'web/liveFeed/engage/libs/engageConstants';
import LoadingSpinner from 'components/loadingSpinner';
import MarketoCard from 'web/liveFeed/components/marketoCard';
import ToutCard from 'web/liveFeed/components/toutCard';
import DynamicChatNotificationCard from 'web/liveFeed/components/dynamicChatNotificationCard';
import classnames from 'classnames';
import EngageZeroState from 'web/liveFeed/engage/components/engageZeroState/engageZeroState';
import './engage.scss';

const _displayCard = (contact, props) => {
  const {
    actionCreators,
    openChildrenLoadingIds,
    salesforceInstanceUrl,
    showMarketoBorder,
    showToutBorder,
  } = props;

  const commonProps = {
    actionCreators,
    salesforceInstanceUrl,
    events: contact.events,
    key: contact.id,
    isLoading: openChildrenLoadingIds.includes(contact.id),
  };

  switch (contact.origin) {
    case Origins.marketo:
      return (
        <MarketoCard
          bestBet={contact}
          showMarketoBorder={showMarketoBorder}
          {...commonProps}
        />
      );
    case Origins.tout:
      return (
        <ToutCard
          pitch={contact}
          {...commonProps}
          showToutBorder={showToutBorder}
        />
      );
    case Origins.dynamicChat:
      return (
        <DynamicChatNotificationCard
          contact={contact}
          actionCreators={actionCreators}
          key={contact.id}
        />
      );
  }
};

const _checkForZeroState = (events, props) =>
  events.length ? (
    events.map((contact) => _displayCard(contact, props))
  ) : (
    <EngageZeroState />
  );

export default function Engage(props) {
  const { events, initialLoading, isLoading } = props;
  return (
    <div
      className={classnames('engage', {
        'background-gray-extra-light': events.length,
      })}
    >
      {initialLoading && isLoading ? (
        <LoadingSpinner imageUrl={getImageUrl} />
      ) : (
        _checkForZeroState(events, props)
      )}
    </div>
  );
}

_displayCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string.isRequired,
  openChildrenLoadingIds: PropTypes.array.isRequired,
  showMarketoBorder: PropTypes.bool.isRequired,
  showToutBorder: PropTypes.bool.isRequired,
};

Engage.propTypes = {
  events: PropTypes.array.isRequired,
  initialLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
