/**
 *
 * TableRadio
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Radio from 'components/buttons/radio';

class TableRadio extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tout-table-radio">
        <Radio
          className="tout-table-radio-classes"
          name="tout-table-radio"
          {...this.props}
        />
      </div>
    );
  }
}

TableRadio.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

TableRadio.defaultProps = {
  checked: false,
  disabled: false,
  onChange: () => {},
};

export default TableRadio;
