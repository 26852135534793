import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';
import { REFERENCE_DATES } from 'web/commandCenter/modules/whenSmartFilter/libs/referenceDateSmartFilterConstants';

export const getReferenceDatesValues = (referenceDatesParams) => {
  return Object.keys(referenceDatesParams)
    .map((referenceDateParamKey) => ({
      ...REFERENCE_DATES[referenceDateParamKey],
      disabled: referenceDatesParams[referenceDateParamKey],
      disabledStyle: referenceDatesParams[referenceDateParamKey],
    }))
    .sort((a, b) => a.disabled - b.disabled);
};

const updateUndeliveredReferenceDateValues = (setReferenceDates) => {
  const referenceDates = getReferenceDatesValues({
    created_at: false,
    delivered_at: true,
    failed_at: false,
    send_at: true,
  });
  setReferenceDates(referenceDates);
};

const updateSentReferenceDateValues = (setReferenceDates) => {
  const referenceDates = getReferenceDatesValues({
    created_at: false,
    delivered_at: false,
    failed_at: true,
    send_at: true,
  });
  setReferenceDates(referenceDates);
};

const updatePendingReferenceDateValues = (setReferenceDates, subTabIds) => {
  const isOnlyScheduledSelected =
    subTabIds.length === 1 &&
    subTabIds.includes(
      CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value
    );
  const referenceDates = getReferenceDatesValues({
    created_at: false,
    delivered_at: true,
    failed_at: true,
    send_at: subTabIds.length && !isOnlyScheduledSelected,
  });
  setReferenceDates(referenceDates);
};

export const updateReferenceDateValues = (options, actions) => {
  const { tabId, subTabIds } = options;
  const { setReferenceDates } = actions;
  switch (tabId) {
    case CommandCenterEmailsTabsEnum.undelivered.value:
      updateUndeliveredReferenceDateValues(setReferenceDates);
      break;
    case CommandCenterEmailsTabsEnum.sent.value:
      updateSentReferenceDateValues(setReferenceDates);
      break;
    case CommandCenterEmailsTabsEnum.pending.value:
      updatePendingReferenceDateValues(setReferenceDates, subTabIds);
      break;
  }
};
