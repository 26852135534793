import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import unauthorizedAdobePage from 'components/hocs/unauthorizedAdobePage';
import {
  isInvitationValid,
  email,
} from 'web/createAccount/helpers/createAccountHelper';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { resendInvitationByEmail } from 'web/invitations/actionCreators/invitationsActionCreators';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import FillCheckIcon from 'components/icons/fillCheckIcon';
import {
  getResendInvitationLoading,
  getResendInvitationResult,
} from 'web/invitations/selectors/invitationsSelectors';
import { resendInvitationResponse } from 'web/invitations/libs/invitationsConstants';
import './resendInvitationContainer.scss';

const ResendInvitationContainer = (props) => {
  const {
    actionCreators: { resendInvitationByEmail },
    loading,
    resendInvitationResult,
  } = props;

  const buttonPanel = () => {
    const isResendError =
      resendInvitationResult === resendInvitationResponse.error;

    return resendInvitationResult === resendInvitationResponse.success ? (
      <div className="success-resend">
        <FillCheckIcon className="resend-success-icon" />
      </div>
    ) : (
      <div
        className={classNames('margin-top-full button-panel', {
          'flex--horz-end': !isResendError,
          'flex--horz-spread': isResendError,
        })}
      >
        {isResendError && (
          <div className="error-message">
            <FormattedMessage id={'web.invitations.resendInvitation.error'} />
          </div>
        )}
        <Button
          color={ButtonColors.blue}
          disabled={!isInvitationValid()}
          onClick={() => {
            resendInvitationByEmail(email);
          }}
          size={ButtonSizes.medium}
        >
          <FormattedMessage id={'common.resend'} />
        </Button>
      </div>
    );
  };

  return (
    <div className="resend-invitation-card flex--column">
      {loading && <LoadingSpinner imageUrl={getImageUrl} />}
      <h1 className="title">
        <FormattedMessage id={'web.invitations.resendInvitationTitle'} />
      </h1>
      <FormattedMessage
        id={'web.invitations.resendInvitationHaveOpenInviteText'}
      />
      <FormattedHTMLMessage
        id={'web.invitations.resendInvitationCheckEmailInboxText'}
        values={{ email }}
      />
      {buttonPanel()}
    </div>
  );
};

ResendInvitationContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  resendInvitationResult: PropTypes.string,
};

ResendInvitationContainer.defaultProps = {
  loading: false,
  resendInvitationResult: '',
};

const mapStateToProps = (state) => ({
  loading: getResendInvitationLoading(state),
  resendInvitationResult: getResendInvitationResult(state),
});

const mapDispatchToProps = { resendInvitationByEmail };

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  (component) =>
    unauthorizedAdobePage(component, {
      cardWidth: 550,
      hideTitle: true,
      titleKey: 'common.signup',
    })
)(ResendInvitationContainer);
