import React, { Component } from 'react';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import Cards, { Direction } from 'components/layouts/cards';
import CustomDomainCard from '../card';
import { getCards } from 'web/shared/helpers/cards';

class CustomDomainPage extends Component {
  render() {
    return (
      <Page className="settings-admin-settings-tracking-page">
        <PageView>
          <Cards
            className="admin-tracking-page-cards-wrapper"
            cards={this.cards}
            backgroundColor={BackgroundColors.grayExtraLight}
            direction={Direction.vertical}
            marginAround={FULL}
            marginBetween={FULL}
          />
        </PageView>
      </Page>
    );
  }

  get cards() {
    return getCards([CustomDomainCard], this.props);
  }
}

export default CustomDomainPage;
