import UnsubscribeOptionsActionTypes from '../libs/unsubscribeOptionsActionTypes';

export const unsubscribeOptionsLoading = (
  state = false,
  { type = '', loading = false }
) => {
  switch (type) {
    case UnsubscribeOptionsActionTypes.loadingUnsubscribeOptions:
      return loading;

    default:
      return state;
  }
};
