import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { MARKETO_EVENTS_LIMIT } from 'web/marketo/interestingMoments/libs/interestingMomentsConstants';

export const getInterestingMomentsByPerson = (
  personId,
  start,
  end,
  limit = MARKETO_EVENTS_LIMIT
) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.marketoInterestingMoments,
      { person_id: personId, start_time: start, end_time: end, limit },
      resolve,
      reject
    );
  });
