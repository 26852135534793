/*eslint new-cap:0, complexity:0 */
import TablesTasksActionTypes from 'web/tables/tasks/libs/tablesTasksActionTypes';
import TasksActionTypes from 'web/tasks/libs/tasksActionTypes';
import moment from 'moment';
import { fromJS, Map } from 'immutable';
import { Colors } from 'libs/constantsShared';
import {
  TasksTableStatusFilterValues,
  TasksTableDatesFilterValues,
} from 'web/tables/tasks/libs/tablesTasksConstants';
import {
  MIN_TASK_DATE_RANGE,
  OVERDUE_TASK_DATE_RANGE,
} from 'web/tasks/libs/tasksConstants';

/* Used in picker to show current dates as could be between the dates stored below if the user is jumping around. */
export const initDates = {
  end: moment()
    .add(MIN_TASK_DATE_RANGE, 'd')
    .startOf('d'),
  start: moment()
    .subtract(OVERDUE_TASK_DATE_RANGE, 'd')
    .startOf('d'),
};
export function tablesTasksSelectedDates(
  state = Map({}),
  { type = '', id, start, end }
) {
  if (!id) {
    return state;
  }
  switch (type) {
    case TablesTasksActionTypes.tableInit:
      if (state.has(id)) {
        return state;
      } else {
        return state.set(id, fromJS(initDates));
      }
    case TablesTasksActionTypes.datesShown: {
      const fallback = state.has(id) ? state.get(id).toJS() : initDates;
      return state.update(id, () =>
        fromJS({ end: end || fallback.end, start: start || fallback.start })
      );
    }
    case TablesTasksActionTypes.datesSmartFilterReset:
      return state.update(id, () => fromJS(initDates));
    default:
      return state;
  }
}

export const initDatesFilter = TasksTableDatesFilterValues.todayOverdue;
export function tablesTasksDatesSmartFilterValue(
  state = Map({}),
  { type = '', id, value }
) {
  if (!id) {
    return state;
  }
  switch (type) {
    case TablesTasksActionTypes.tableInit:
      if (state.has(id)) {
        return state;
      } else {
        return state.set(id, initDatesFilter);
      }
    case TablesTasksActionTypes.datesSmartFilter:
      return state.update(id, () => value);
    default:
      return state;
  }
}

export const initStatusFilter = TasksTableStatusFilterValues.uncompleted;
export function tablesTasksStatusSmartFilterValue(
  state = Map({}),
  { type = '', id, value }
) {
  if (!id) {
    return state;
  }
  switch (type) {
    case TablesTasksActionTypes.tableInit:
      if (state.has(id)) {
        return state;
      } else {
        return state.set(id, TasksTableStatusFilterValues.uncompleted);
      }
    case TablesTasksActionTypes.statusSmartFilter:
      return state.update(id, () => value);
    default:
      return state;
  }
}

/* Decided not to use id as should never have two task tables showing */
export function tablesTasksAnimateRowColor(state = '', { type = '' }) {
  switch (type) {
    case TasksActionTypes.update:
      return Colors.white;
    case TasksActionTypes.completing:
    case TasksActionTypes.completingBulk:
      return Colors.green;
    case TasksActionTypes.uncompleting:
      return Colors.blue;
    case TasksActionTypes.destroy:
    case TasksActionTypes.destroyBulk:
      return Colors.red;
    case TablesTasksActionTypes.animateRowOff:
    case TablesTasksActionTypes.tableInit:
    case TablesTasksActionTypes.tableReset:
      return '';
    default:
      return state;
  }
}

export function tablesTasksLoading(
  state = false,
  { type = '', loading = false }
) {
  switch (type) {
    case TablesTasksActionTypes.setLoading:
      return loading;
    case TablesTasksActionTypes.tableInit:
    case TablesTasksActionTypes.tableReset:
      return false;
    default:
      return state;
  }
}
