import I18N from 'languages';

export const ALL_MEMBERS_NAME = I18N.getStr('common.allMembers');

export const TeamsPopupIds = {
  deleteTeamConfirmation: 'TEAMS_DELETE_TEAM_CONFIRMATION',
  createTeam: 'TEAMS_CREATE_TEAM',
  needSubscriptionUpgrade: 'TEAMS_NEED_SUBSCRIPTION_UPGRADE',
};

export const TEAM_CREATE_FORM_ID = 'TEAM_MANAGEMENT_TEAM_CREATE_FORM';
