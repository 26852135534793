import AccordionItemHeader from './header';
import AccordionItemBody from './body';

export const childrenProptype = (props, propName, componentName) => {
  const children = props[propName];
  if (
    children[0].type !== AccordionItemHeader ||
    children[1].type !== AccordionItemBody
  ) {
    return new Error(
      `Invalid ${propName}: Expecting AccordionItemHeader and AccordionItemBody as a children for component ${componentName}`
    );
  }
  return null;
};
