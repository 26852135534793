import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import Popup from 'components/popups/popup';
import { AlertColors } from 'components/alert';
import { getImageUrl } from 'web/libs/constants';
import { clearPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import { PEOPLE_AUTHORIZATION_POPUP_ID } from 'web/compliance/libs/complianceConstants';
import {
  closeAuthorizationPopup,
  savePeopleAuthorization,
  updateAuthorization,
} from 'web/people/actionCreators/peopleAuthorizationActionCreators';
import { isValidAuthorizationInput } from 'web/people/helpers/peopleAuthorizationHelpers';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { ModalComponentIds } from 'web/compliance/libs/complianceConstants';
import AuthorizationPopupBody from 'web/compliance/components/authorizationPopupBody';
import ComplianceSuccess from 'web/compliance/components/complianceSuccess';
import './peopleAuthorizationContainer.scss';

class PeopleAuthorizationContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    this.actionCreators = bindActionCreators(
      {
        clearPopupAlert,
        closeAuthorizationPopup,
        savePeopleAuthorization,
        updateAuthorization,
      },
      dispatch
    );
  }

  render() {
    const { popup } = this.props;
    const { children, ...other } = this._getMainComponent();

    return (
      <div className="authorization-modal">
        {popup === PEOPLE_AUTHORIZATION_POPUP_ID && (
          <Popup {...other}>{children}</Popup>
        )}
      </div>
    );
  }

  _getMainComponent = () => {
    const {
      alert,
      authorizationMainModal,
      intl: { formatMessage },
      complianceEditState,
      isValidInput,
      loading,
      popupData: { peopleIds },
    } = this.props;

    switch (authorizationMainModal) {
      case ModalComponentIds.authorizationEdit:
        return {
          children: (
            <AuthorizationPopupBody
              complianceEditState={complianceEditState}
              updateAuthorization={this.actionCreators.updateAuthorization}
            />
          ),
          footer: this._getMainFooter(!isValidInput),
          header: this._getMainHeader(peopleIds.length),
          alert: this._getPopupAlert(alert, this.actionCreators),
          onOutsideClick: this.actionCreators.closeAuthorizationPopup,
          getImageUrl,
          loading,
        };
      case ModalComponentIds.authorizationEditingSuccess:
        return {
          children: (
            <ComplianceSuccess
              count={peopleIds.length}
              typeText={formatMessage({ id: 'common.authorization' })}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.closeAuthorizationPopup,
            primaryTextId: 'common.ok',
          },
          header: {
            textId: 'web.people.compliance.modal.authorizationSuccess',
          },
          onOutsideClick: this.actionCreators.closeAuthorizationPopup,
        };
      default:
        return {};
    }
  };

  _getMainHeader = (count) => ({
    textId: 'web.people.compliance.modal.authorizationHeader',
    textValues: { count },
    onClose: this.actionCreators.closeAuthorizationPopup,
  });

  _getMainFooter = (primaryDisabled) => ({
    onPrimary: this.actionCreators.savePeopleAuthorization,
    onSecondary: this.actionCreators.closeAuthorizationPopup,
    primaryDisabled,
    primaryTextId: 'common.save',
    secondaryTextId: 'common.cancel',
  });

  _getPopupAlert = (id) => {
    const defaultAlert = {
      color: AlertColors.red,
      onClose: this.actionCreators.clearPopupAlert,
    };

    switch (id) {
      case PeopleAlertIds.peopleAuthorization.saveFail:
        return {
          ...defaultAlert,
          textId: 'web.people.compliance.modal.saveFail',
        };
      default:
        return null;
    }
  };
}

PeopleAuthorizationContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  authorizationMainModal: PropTypes.string.isRequired,
  complianceEditState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isValidInput: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.popupAlert,
  authorizationMainModal: state.authorizationMainModal,
  complianceEditState: state.peopleAuthorization,
  isValidInput: isValidAuthorizationInput(state),
  loading: state.popupLoading,
  popup: state.popup,
  popupData: state.popupData,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(PeopleAuthorizationContainer);
