import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import SubjectStats from 'web/emails/components/subjectStats';
import Info from 'web/emails/components/info';
import CatchATagClicks from 'web/emails/components/catchATagClicks';
import SentLastEvent from 'web/emails/components/sentLastEvent';
import './personDetailsEmail.scss';

class PersonDetailsEmail extends Component {
  constructor(props) {
    super(props);

    const isOpen = props.scrollToOnChange;
    this.state = { isOpen };
  }

  componentDidMount() {
    if (this.props.scrollToOnChange) {
      this._scroll();
    }
  }

  /* eslint-disable react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (!this.props.scrollToOnChange && nextProps.scrollToOnChange) {
      this.setState({ isOpen: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.isOpen && !prevState.isOpen) ||
      (!prevProps.scrollToOnChange && this.props.scrollToOnChange)
    ) {
      this._scroll();
    }
  }

  render() {
    const {
      addAsTemplate,
      archived,
      archiveEmail,
      bcc,
      body,
      campaign,
      cc,
      className,
      clicks,
      deliveredAt,
      id,
      identityEmail,
      identityName,
      lastEventAt,
      openEvents,
      openFollowUp,
      pitchState,
      reason,
      replies,
      sendAt,
      subject,
      success,
      unarchiveEmail,
      user,
      userId,
      views,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={classNames('person-details-email', className)}
        onClick={this._toggleOpen}
        ref={this._setRef}
      >
        <SubjectStats
          addAsTemplate={addAsTemplate}
          archiveEmail={archiveEmail}
          archived={archived}
          clicks={clicks}
          id={id}
          openEvents={openEvents}
          openFollowUp={openFollowUp}
          pitchState={pitchState}
          replies={replies}
          subject={subject}
          succeeded={success}
          unarchiveEmail={unarchiveEmail}
          user={user}
          userId={userId}
          views={views}
        />
        {isOpen && [
          <Info
            bcc={bcc}
            body={body}
            campaign={campaign}
            cc={cc}
            id={id}
            identityEmail={identityEmail}
            identityName={identityName}
            key="person-details-email-info"
            reason={reason}
          />,
          <div className="margin-top-half" key="person-details-email-body">
            <CatchATagClicks text={body} />
          </div>,
        ]}
        <SentLastEvent
          deliveredAt={deliveredAt}
          lastEventAt={lastEventAt}
          sendAt={sendAt}
        />
      </div>
    );
  }

  _setRef = (div) => (this._ref = div);

  _toggleOpen = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });

    if (!isOpen) {
      // only on open
      this.props.openItemClick(PersonDetailsCards.emails);
    }
  };

  _scroll = () =>
    this._ref.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
}

PersonDetailsEmail.propTypes = {
  addAsTemplate: PropTypes.func,
  archiveEmail: PropTypes.func,
  archived: PropTypes.bool,
  bcc: PropTypes.string,
  body: PropTypes.string,
  campaign: PropTypes.object,
  cc: PropTypes.string,
  className: PropTypes.string,
  clicks: PropTypes.number,
  deliveredAt: PropTypes.string,
  id: PropTypes.number.isRequired,
  identityEmail: PropTypes.string,
  identityName: PropTypes.string,
  lastEventAt: PropTypes.string,
  openEvents: PropTypes.func,
  openFollowUp: PropTypes.func,
  openItemClick: PropTypes.func.isRequired,
  pitchState: PropTypes.string,
  reason: PropTypes.string,
  replies: PropTypes.number,
  scrollToOnChange: PropTypes.bool,
  sendAt: PropTypes.string,
  subject: PropTypes.string,
  success: PropTypes.bool,
  unarchiveEmail: PropTypes.func,
  user: PropTypes.object.isRequired,
  userId: PropTypes.number,
  views: PropTypes.number,
};

PersonDetailsEmail.defaultProps = {
  addAsTemplate: null,
  archiveEmail: null,
  archived: false,
  bcc: '',
  body: '',
  campaign: null,
  cc: '',
  className: '',
  clicks: 0,
  deliveredAt: '',
  identityEmail: '',
  identityName: '',
  lastEventAt: '',
  openEvents: null,
  openFollowUp: null,
  pitchState: '',
  reason: '',
  replies: 0,
  scrollToOnChange: false,
  sendAt: '',
  subject: '',
  success: false,
  unarchiveEmail: null,
  userId: 0,
  views: 0,
};

export default PersonDetailsEmail;
