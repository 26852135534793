import { createSelector } from 'reselect';

const getMarketoIntegrationFetched = (state) => state.marketoIntegrationFetched;
const getMarketoIntegrationFetching = (state) =>
  state.marketoIntegrationFetching;

const getMarketoWorkspacesFetched = (state) => state.marketoWorkspacesFetched;
const getMarketoWorkspacesFetching = (state) => state.marketoWorkspacesFetching;

export const shouldInvalidateMarketoIntegration = createSelector(
  [getMarketoIntegrationFetched, getMarketoIntegrationFetching],
  (fetched, fetching) => !fetched && !fetching
);

export const shouldInvalidateWorkspaces = createSelector(
  [getMarketoWorkspacesFetched, getMarketoWorkspacesFetching],
  (fetched, fetching) => !fetched && !fetching
);

export const isMarketoAdmin = (state) =>
  !!state.marketoAdminConnectionInfo.engageUsers.engageAdminCount;
