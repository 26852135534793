import React, { Fragment } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import Action, { ButtonColors } from 'components/buttons/action';
import { FormattedMessage } from 'react-intl';
import { EmailStates } from 'web/commandCenter/libs/commandCenterConstants';
import { PitchEvents } from 'web/emails/libs/emailsConstants';
import CircleEvent from 'web/commandCenter/components/commandCenterEmailsTable/circleEvent/circleEvent';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';

import './emailStatusCell.scss';

const EmailStatusCell = (props) => {
  const {
    intl: { formatMessage },
    rowData,
    onClick,
  } = props;

  const handleClick = (e) => {
    onClick(rowData, e);
  };

  const _getAction = (
    color,
    { messageId, messageValues = {} },
    disabled = false
  ) => (
    <Action color={color} disabled={disabled} onClick={handleClick}>
      <FormattedMessage id={messageId} values={messageValues} />
    </Action>
  );

  const _getBounceReason = () => {
    const { tracking_events: trackingEvents } = rowData;

    const bounceEvent = trackingEvents.find((event) => {
      return event.event === PitchEvents.bounce;
    });

    return bounceEvent && bounceEvent.reason
      ? formatMessage(
          { id: 'web.commandCenter.emails.state.bounce.snippet' },
          { snippet: bounceEvent.reason }
        )
      : formatMessage({
          id: 'web.commandCenter.emails.state.bounce.reasonNotFound',
        });
  };

  const getStatusButtons = () => {
    const { state, send_at: sendAt, id, circleEvents } = rowData;

    switch (state) {
      case EmailStates.draft: {
        return _getAction(ButtonColors.default, {
          messageId: 'web.emails.draft',
        });
      }
      case EmailStates.scheduledForDelivery: {
        const sendAtFormatted = sendAt ? moment(sendAt).fromNow() : '';

        return _getAction(ButtonColors.green, {
          messageId: 'web.emails.status.scheduledToSendAt',
          messageValues: { sendAt: sendAtFormatted },
        });
      }
      case EmailStates.enqueuedForDelivery:
      case EmailStates.deliveryInProgress: {
        return _getAction(ButtonColors.default, {
          messageId: 'web.emails.status.deliveryInProgress',
        });
      }
      case EmailStates.bounced: {
        return (
          <HoverTooltip
            className="email-status-tooltip-bounce"
            delayHide={0}
            place={HoverTooltipPlace.top}
            textValue={_getBounceReason()}
            tooltipId={`email-status-bounce-${id}`}
            multiline
          >
            {_getAction(ButtonColors.red, {
              messageId: 'web.emails.status.bounced',
            })}
          </HoverTooltip>
        );
      }
      case EmailStates.spam: {
        return _getAction(ButtonColors.green, {
          messageId: 'web.emails.status.spam',
        });
      }
      case EmailStates.failedDelivery: {
        return _getAction(ButtonColors.red, {
          messageId: 'web.emails.status.failedDelivery',
        });
      }
      case EmailStates.outOfOffice: {
        return _getAction(ButtonColors.green, {
          messageId: 'web.emails.status.outOfOffice',
        });
      }
      default: {
        return (
          <Fragment>
            <CircleEvent
              eventCount={circleEvents.open.eventCount}
              iconClass="icon-view"
              lastEventDate={circleEvents.open.lastEventDate}
              lastEventTooltipTextId="web.emails.status.lastViewed"
              tooltipId={id.toString()}
            />
            <CircleEvent
              eventCount={circleEvents.click.eventCount}
              iconClass="icon-click"
              lastEventDate={circleEvents.click.lastEventDate}
              lastEventTooltipTextId="web.emails.status.lastClicked"
              tooltipId={id.toString()}
            />
            <CircleEvent
              eventCount={circleEvents.reply.eventCount}
              iconClass="icon-reply"
              lastEventDate={circleEvents.reply.lastEventDate}
              lastEventTooltipTextId="web.emails.status.lastReplied"
              tooltipId={id.toString()}
            />
            <CircleEvent
              eventCount={circleEvents.success.eventCount}
              iconClass="icon-success"
            />
          </Fragment>
        );
      }
    }
  };

  return (
    <div className="flex flex--align-middle">
      <div className="email-status-cell">{getStatusButtons()}</div>
    </div>
  );
};

EmailStatusCell.propTypes = {
  intl: intlShape.isRequired,
  onClick: PropTypes.func,
  rowData: PropTypes.object.isRequired,
};

EmailStatusCell.defaultProps = {
  onClick: () => {},
};

export default injectIntl(EmailStatusCell);
