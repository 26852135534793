import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import './campaignLaunched.scss';

class CampaignLaunched extends Component {
  constructor(props) {
    super(props);

    if (props.peopleAdded) {
      this.titleText = props.intl.formatMessage(
        { id: 'campaigns.campaignLaunched.titleInProgress' },
        { people: props.peopleAdded }
      );
    } else {
      this.titleText = props.intl.formatMessage({
        id: 'campaigns.campaignLaunched.title',
      });
    }
    this.bodyText = props.intl.formatMessage({
      id: 'campaigns.campaignLaunched.body',
    });

    this.marketingWarningNote = props.intl.formatMessage({
      id: 'campaigns.campaignLaunched.marketingDelayNote',
    });
  }

  render() {
    return (
      <div className="campaignLaunched tout-icon-rocketship text-white">
        <div className="launch-text">
          <div className="text-medium margin-bottom-full">{this.titleText}</div>
          <div className="margin-bottom-full">{this.bodyText}</div>
          <div>{this.marketingWarningNote}</div>
        </div>
      </div>
    );
  }
}

CampaignLaunched.propTypes = {
  intl: intlShape.isRequired,
  peopleAdded: PropTypes.number,
};

export default injectIntl(CampaignLaunched);
