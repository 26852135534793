/**
 *
 * TextCell
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './textCell.scss';

class TextCell extends PureComponent {
  render() {
    const {
      className,
      onClick,
      property,
      rowData,
      style,
      wrapLines,
      showTitle,
    } = this.props;

    const useTextOverflow = !wrapLines || wrapLines === 1;
    const containerClasses = classNames(className, 'cell-text', {
      'text-overflow': useTextOverflow,
      clickable: onClick,
    });
    const textClasses = classNames({
      [`text-overflow-${wrapLines}`]: wrapLines > 1 && wrapLines <= 5,
      'text-overflow': useTextOverflow,
    });
    const data = rowData[property];

    return (
      (typeof data !== 'object' || data === null) && (
        <div
          className={containerClasses}
          onClick={this.onCellClick}
          style={style}
        >
          <span className={textClasses} title={showTitle ? data : null}>
            {data}
          </span>
        </div>
      )
    );
  }

  onCellClick = (e) => {
    const { onClick, rowData } = this.props;
    if (onClick) {
      this.props.onClick(rowData, e);
    }
  };
}

TextCell.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  property: PropTypes.string,
  rowData: PropTypes.object.isRequired,
  style: PropTypes.object,
  wrapLines: PropTypes.number,
  showTitle: PropTypes.bool,
};

TextCell.defaultProps = {
  className: '',
  property: 'id',
  style: {},
  wrapLines: 1,
  onClick: () => {},
  showTitle: false,
};

export default TextCell;
