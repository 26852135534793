import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  PersonDetailsCards,
  SalesforceTypes,
} from 'web/person/libs/personDetailsConstants';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import Radio from 'components/buttons/radio';

const ContactRadioName = 'person-details-add-contact';
const LeadRadioName = 'person-details-add-lead';

class AccountInfoEditing extends Component {
  render() {
    const { actionCreators, accountInfoEditState } = this.props;
    return (
      <div className="person-details-account-info-editing">
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.person.personDetails.accountInfo.title" />
        </h3>
        <div>
          <div className="flex">
            <Radio
              checked={
                accountInfoEditState.salesforceType === SalesforceTypes.lead
              }
              className="margin-right-full"
              id={LeadRadioName}
              name={LeadRadioName}
              onChange={this.setRadio}
              value={LeadRadioName}
              label={<FormattedMessage id="common.lead" />}
            />
            <Radio
              checked={
                accountInfoEditState.salesforceType === SalesforceTypes.contact
              }
              className="margin-right-full"
              id={ContactRadioName}
              name={ContactRadioName}
              onChange={this.setRadio}
              value={ContactRadioName}
              label={<FormattedMessage id="common.person" />}
            />
          </div>
          <div className="flex--horz-end margin-top-most">
            <Button
              color={ButtonColors.gray}
              onClick={this.cancel}
              size={ButtonSizes.standard}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              className="margin-left-full"
              color={ButtonColors.blue}
              disabled={this.isSaveDisabled()}
              onClick={actionCreators.addToSalesforce}
              size={ButtonSizes.standard}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  cancel = () =>
    this.props.actionCreators.closeCardEditing(PersonDetailsCards.accountInfo);

  isSaveDisabled = () => {
    const {
      requiredFields,
      accountInfoEditState: { salesforceType },
    } = this.props;
    return !salesforceType || Object.keys(requiredFields).length > 0;
  };

  setRadio = (value) => {
    const salesforceType =
      value === LeadRadioName ? SalesforceTypes.lead : SalesforceTypes.contact;
    this.props.actionCreators.updateSalesforceType(salesforceType);
  };
}

AccountInfoEditing.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  accountInfoEditState: PropTypes.object.isRequired,
  requiredFields: PropTypes.object.isRequired,
};

export default AccountInfoEditing;
