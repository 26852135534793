import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import LogEmailActivityForm from 'web/salesforce/components/logEmailActivityForm';
import { LOG_EMAIL_ACTIVITY_FORM_ADMIN_ID } from 'web/salesforce//libs/salesforceConstants';
import Switch from 'components/buttons/switch';

class LogEmailActivityCard extends Component {
  render() {
    const {
      actionCreators: { updateApiSyncSettings, logActivityViaApi },
      isActivityViaApiChecked,
      intl: { formatMessage },
    } = this.props;

    return (
      <Fragment>
        <Switch
          label={formatMessage({
            id:
              'web.settings.adminSettings.salesforce.sync.teamWideSyncSettings.accordion.logEmail.configure',
          })}
          rightLabel
          className="padding-bottom-25"
          checked={isActivityViaApiChecked}
          onChange={logActivityViaApi}
          name="activity_via_api"
        />
        <LogEmailActivityForm
          form={LOG_EMAIL_ACTIVITY_FORM_ADMIN_ID}
          onSubmit={updateApiSyncSettings}
          initialValues={this.getLogEmailActivityModel}
          disabled={!isActivityViaApiChecked}
        />
      </Fragment>
    );
  }

  get getLogEmailActivityModel() {
    const {
      salesforceOptions: {
        api_settings: {
          bcc_opportunities_enabled: bccOpportunitiesEnabled = false,
          bcc_create_unassigned_task: bccCreateUnassignedTask = false,
          bcc_leads_enabled: bccLeadsEnabled = false,
          bcc_contacts_enabled: bccContactsEnabled = false,
          bcc_duplicate_action: bccDuplicateAction = null,
          push_replies: pushReplies = false,
          rollup_opportunities: rollupOpportunities = false,
          rollup_leads: rollupLeads = false,
          rollup_contacts: rollupContacts = false,
          rollup_accounts: rollupAccounts = false,
        },
      },
    } = this.props;
    return {
      bcc_opportunities_enabled: bccOpportunitiesEnabled,
      bcc_create_unassigned_task: bccCreateUnassignedTask,
      bcc_leads_enabled: bccLeadsEnabled,
      bcc_contacts_enabled: bccContactsEnabled,
      bcc_duplicate_action: bccDuplicateAction,
      push_replies: pushReplies,
      rollup_opportunities: rollupOpportunities,
      rollup_leads: rollupLeads,
      rollup_contacts: rollupContacts,
      rollup_accounts: rollupAccounts,
    };
  }
}

LogEmailActivityCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  salesforceOptions: PropTypes.object.isRequired,
  isActivityViaApiChecked: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(LogEmailActivityCard);
