export default {
  emails: {
    deleteEmail: 'EMAILS_DELETE_EMAIL',
    deleteEmails: 'EMAILS_DELETE_EMAILS',
    loading: 'EMAILS_LOADING',
    set: 'EMAILS_SET',
    setEmail: 'EMAILS_SET_EMAIL',
  },
  search: {
    reset: 'COMMAND_CENTER_EMAIL_SEARCH_RESET',
    set: 'COMMAND_CENTER_EMAIL_SEARCH_SET',
  },
  setSubTab: 'COMMAND_CENTER_EMAILS_SET_SUB_TAB',
  setTab: 'COMMAND_CENTER_EMAILS_SET_TAB',
  table: {
    page: 'EMAIL_TABLE_SET_PAGE',
    perPage: 'EMAIL_TABLE_PER_PAGE',
    reset: 'EMAIL_TABLE_RESET',
    sortInfo: 'EMAIL_TABLE_SET_SORTINFO',
  },
};
