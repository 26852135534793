import {
  navigateToTemplateCategory,
  navigateToTemplatesTab,
} from 'web/services/routerService';
import {
  getCategoriesTemplates as getTemplateCategoriesCall,
  getCategoriesTemplatesSharable as getTemplateSharableCategoriesCall,
  createCategoryTemplate as createTemplateCategoryCall,
  deleteCategoryTemplate as deleteTemplateCategoryCall,
  updateCategoryTemplate as updateTemplateCategoryCall,
} from 'web/categories/services/categoriesTemplatesService';
import {
  filterTemplates,
  setTemplatesLoading,
} from 'web/templates/actionCreators/templatesFetchActionCreators';
import {
  addTemplate,
  resetMostRecentlyCreatedTemplate,
} from 'web/templates/actionCreators/templatesCreateActionCreators';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { clearSelectedIds } from 'web/templates/actionCreators/templatesTableActionCreators';
import {
  DEFAULT_PAGE,
  TEMPLATE_SPECIAL_CATEGORY,
  TEMPLATE_TAG_TYPES,
  SPECIAL_FILTER_CATEGORIES,
} from 'web/templates/libs/templatesConstants';
import TemplatePopupIds from 'web/templates/libs/templatePopupIds';
import {
  closePopup,
  openPopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import {
  commonUserAdminTeamPermission,
  isAdmin,
  isMemberOfTeams,
} from 'web/shared/services/accountService';
import {
  getTemplateViewerForApi,
  getViewableCategories,
} from 'web/templates/selectors/templatesSelectors';
import { track } from 'web/services/mixpanelService';
import {
  CategoriesEvents,
  CategoriesTypes,
  SourceProperties,
  CategoriesUpdateEventTypes,
} from 'web/libs/mixpanelEvents';
import { esFetch as esFetchApi } from 'web/elasticSearch/services/esFetch';
import { templatesResetSearch } from 'web/templates/actionCreators/templatesSearchActionCreators';
import { templatesSmartFilterReset } from 'web/templates/actionCreators/templatesSmartFilterActionCreators';
import trim from 'lodash/trim';
import find from 'lodash/find';
import templateAlertIds from 'web/templates/libs/templateAlertIds';
import get from 'lodash/get';
import { ERRORS } from 'web/categories/services/categoryServiceConstants.js';

export const setTemplateCategoriesLoading = (isLoading) => ({
  type: TemplatesActionTypes.categories.loading,
  isLoading,
});

export const setTemplateCategories = (categories) => ({
  type: TemplatesActionTypes.categories.set,
  categories,
});

export const setTemplateSharableCategories = (categories) => ({
  type: TemplatesActionTypes.sharableCategories.set,
  categories,
});

export const setTemplateSelectedCategory = (id) => ({
  type: TemplatesActionTypes.categories.select,
  id,
});

export const setTemplatePage = (page) => ({
  type: TemplatesActionTypes.table.page,
  page,
});

export const setTemplateNewCategoryOnChange = (name) => ({
  type: TemplatesActionTypes.categories.newOnChange,
  name,
});

export const setTemplateAddNewCategory = (category) => ({
  type: TemplatesActionTypes.categories.add,
  category,
});

export const setTemplateEditCategory = (id) => ({
  type: TemplatesActionTypes.categories.edit,
  id,
});

export const setPopupSelectedCategory = (selectedCategory) => ({
  type: TemplatesActionTypes.categories.updateSelectedCategory,
  selectedCategory,
});

export const setTemplateUpdatedCategory = (updatedCategory) => ({
  type: TemplatesActionTypes.categories.update,
  updatedCategory,
});

export const setTemplateRemoveCategory = (id) => ({
  type: TemplatesActionTypes.categories.delete,
  removedCategoryId: id,
});

export const templatesCategoryFilter = (categoryId) =>
  categoryId &&
  ![
    TEMPLATE_SPECIAL_CATEGORY.all.id,
    TEMPLATE_SPECIAL_CATEGORY.favorites.id,
    TEMPLATE_SPECIAL_CATEGORY.archived.id,
  ].includes(categoryId)
    ? { category_id: categoryId }
    : {};

export const updateCategoryFromPusher = (category) => (dispatch, getState) => {
  const { id, team_ids: updateTeamIds } = category;
  const { templatesCategories } = getState();

  if (isMemberOfTeams(updateTeamIds)) {
    if (templatesCategories.find((cat) => cat.id === id)) {
      dispatch(setTemplateUpdatedCategory(category));
    } else {
      dispatch(setTemplateAddNewCategory(category));
    }
  }
};

export const getCategoriesTemplates = (categoriesViewer = {}) => (dispatch) => {
  dispatch(setTemplateCategoriesLoading(true));
  const viewer =
    categoriesViewer.viewer_id === SPECIAL_FILTER_CATEGORIES.ALL_USERS
      ? {
          ...categoriesViewer,
          viewer_type: 'subscription',
        }
      : categoriesViewer;
  return getTemplateCategoriesCall(viewer)
    .then((categories = []) => {
      dispatch(setTemplateCategoriesLoading(false));
      dispatch(setTemplateCategories(categories));
    })
    .catch(() => {
      dispatch(setTemplateCategoriesLoading(false));
      dispatch(openViewAlert(TemplateAlertIds.getCategoriesError));
    });
};

const setTagsForSpecialCategories = (categoryId) => (dispatch) => {
  switch (categoryId) {
    case TEMPLATE_SPECIAL_CATEGORY.favorites.id:
      dispatch({
        type: TemplatesActionTypes.specialCategoryTags.set,
        tags: [TEMPLATE_TAG_TYPES.favorite],
      });
      return;
    case TEMPLATE_SPECIAL_CATEGORY.archived.id:
      dispatch({
        type: TemplatesActionTypes.specialCategoryTags.set,
        tags: [TEMPLATE_TAG_TYPES.archived],
      });
      return;
    default:
      dispatch({ type: TemplatesActionTypes.specialCategoryTags.reset });
  }
};

const setTableStateOnFilter = (categoryId) => (dispatch) => {
  clearSelectedIds();
  dispatch(setTemplatePage(DEFAULT_PAGE));
  dispatch(setTemplateSelectedCategory(categoryId));
};

const optimisticallyAddNewTemplate = (currentCategoryId) => (
  dispatch,
  getState
) => {
  const { mostRecentlyCreatedTemplate } = getState();
  const {
    id,
    category_id: mostRecentlyCreatedTemplateCategoryId,
  } = mostRecentlyCreatedTemplate;

  /*
   * if filtering after creating a new template, optimistically add the template after
   * fetching new templates. may not be in response due latency in es indexing.
   */

  if (id && mostRecentlyCreatedTemplateCategoryId === currentCategoryId) {
    dispatch(addTemplate(mostRecentlyCreatedTemplate));
  }

  dispatch(resetMostRecentlyCreatedTemplate());
};

export const filterByCategory = (categoryId) => (dispatch, getState) => {
  const {
    templatesSelectedTemplateCategoryId,
    templatesCategories,
  } = getState();
  const id = getViewableCategories(getState()).some(
    (category) => category.id === categoryId
  )
    ? categoryId
    : TEMPLATE_SPECIAL_CATEGORY.all.id;

  if (templatesCategories.length) {
    if (id !== templatesSelectedTemplateCategoryId) {
      dispatch(setTableStateOnFilter(id));
      dispatch(setTagsForSpecialCategories(id));
      dispatch(templatesResetSearch());
      dispatch(templatesSmartFilterReset());
      dispatch(filterTemplates(templatesCategoryFilter(id))).then(() => {
        dispatch(optimisticallyAddNewTemplate(id));
      });
    } else {
      dispatch(optimisticallyAddNewTemplate(id));
    }
  }
};

export const initTemplateCategories = (categoryId) => (dispatch, getState) => {
  /*
    Need to set loading on table to true since we can't make the call to fetch the table data
    until the cateogires call returns.
  */
  const viewer = getTemplateViewerForApi(getState());
  dispatch(setTemplatesLoading(true));
  dispatch(getCategoriesTemplates(viewer)).then(() => {
    const { templatesCategories } = getState();
    if (templatesCategories.length) {
      dispatch(setTemplateSelectedCategory(categoryId));
      dispatch(setTagsForSpecialCategories(categoryId));
      dispatch(filterTemplates(templatesCategoryFilter(categoryId)));
    } else {
      dispatch(setTemplatesLoading(false));
    }
  });
};

export const createNewCategory = () => (dispatch, getState) => {
  const { templatesNewCategoryName, templatesCategories } = getState();
  const existingCategory = templatesCategories.find(
    (category) => category.name === templatesNewCategoryName
  );
  if (existingCategory) {
    dispatch(setPopupAlert(TemplateAlertIds.createDuplicateCategoryError));
  } else {
    dispatch(setPopupLoading(true));
    createTemplateCategoryCall(trim(templatesNewCategoryName))
      .then((newCat) => {
        dispatch(setPopupLoading(false));
        dispatch(setTemplateAddNewCategory(newCat));
        navigateToTemplateCategory(newCat.id);
        dispatch(closePopup());

        track(CategoriesEvents.create, {
          type: CategoriesTypes.template,
          source: SourceProperties.templates,
        });
      })
      .catch(() => {
        dispatch(setPopupLoading(false));
        dispatch(setPopupAlert(TemplateAlertIds.createCategoryError));
      });
  }
};

export const isRenameCategoryDisabled = (id) => (dispatch, getState) => {
  const {
    user: { id: userId },
    templatesCategories,
  } = getState();
  const currentCategory = find(templatesCategories, { id }) || {};
  return (
    !commonUserAdminTeamPermission(userId) ||
    (!isAdmin() && currentCategory.user_id !== userId)
  );
};

export const renameCategoryConfirm = (id, name) => (dispatch, getState) => {
  const { templatesCategories } = getState();
  const originalCategory =
    templatesCategories.find((category) => category.id === id) || {};
  const newCategory = { id, name };
  const existingCategory = templatesCategories.find(
    (category) => category.name === name
  );
  if (existingCategory) {
    dispatch(openViewAlert(TemplateAlertIds.renameDuplicateCategoryError));
  } else {
    dispatch(setTemplateEditCategory());
    dispatch(setTemplateUpdatedCategory(newCategory)); // Intermediate data, will not have ("mergeable") set yet
    updateTemplateCategoryCall(id, newCategory)
      .then((updatedCategory) => {
        dispatch(setTemplateUpdatedCategory(updatedCategory)); // Set full data once we have it
        track(CategoriesEvents.update, {
          type: CategoriesUpdateEventTypes.rename,
          source: SourceProperties.templates,
        });
      })
      .catch(() => {
        if (originalCategory.name) {
          dispatch(setTemplateUpdatedCategory(originalCategory));
        }
        dispatch(openViewAlert(TemplateAlertIds.renameCategoryError));
      });
  }
};

const optimisticallyRemoveCategory = (id, remoteCallFn, errorAlertId) => (
  dispatch,
  getState
) => {
  const {
    templatesSelectedTemplateCategoryId,
    templatesCategories,
  } = getState();
  const originalCategory =
    templatesCategories.find((category) => category.id === id) || {};
  dispatch(closePopup());
  dispatch(setTemplateRemoveCategory(id));
  if (templatesSelectedTemplateCategoryId === id) {
    dispatch(setTemplatesLoading(true));
  }
  remoteCallFn(id)
    .then((data) => {
      const categoryIdToSelect = data && data.category_id;
      if (categoryIdToSelect) {
        const categoryData = {
          name: originalCategory.name,
          id: categoryIdToSelect,
        };
        dispatch(setTemplateAddNewCategory(categoryData));
        navigateToTemplateCategory(categoryIdToSelect);
      } else if (templatesSelectedTemplateCategoryId !== id) {
        navigateToTemplateCategory(templatesSelectedTemplateCategoryId);
      } else {
        navigateToTemplatesTab();
      }
    })
    .catch((err = {}) => {
      dispatch(setTemplateAddNewCategory(originalCategory));
      if (
        err.status === 422 &&
        get(err, 'response.body.errors[0]') === ERRORS.CATEGORY_NOT_EMPTY
      ) {
        dispatch(openViewAlert(templateAlertIds.deleteCategoryNotEmptyError));
      } else {
        dispatch(openViewAlert(errorAlertId));
      }
      dispatch(setTemplatesLoading(false));
    });
};

export const deleteCategory = (id) => (dispatch, getState) => {
  const {
    templatesTotal,
    templatesSelectedTemplateCategoryId,
    templatesViewer: viewer,
    templatesFilter,
  } = getState();

  if (templatesSelectedTemplateCategoryId === id) {
    dispatch(
      openPopup(TemplatePopupIds.deleteCategory, {
        categoryId: id,
        templatesCount: templatesTotal,
      })
    );
  } else {
    dispatch(
      openPopup(TemplatePopupIds.deleteCategory, {
        categoryId: id,
        templatesCount: 0,
      })
    );
    dispatch(setPopupLoading(true));

    esFetchApi({
      filters: { ...templatesCategoryFilter(id), ...templatesFilter },
      viewer,
    })
      .then(({ total: templatesCount = 0 }) => {
        dispatch(
          openPopup(TemplatePopupIds.deleteCategory, {
            categoryId: id,
            templatesCount,
          })
        );
        dispatch(setPopupLoading(false)); // loading set to false by openPopup, just here for safety
      })
      .catch(() => {
        dispatch(setPopupLoading(false));
        dispatch(openViewAlert(TemplateAlertIds.fetchError));
      });
  }
};

export const deleteCategoryConfirm = (id) => (dispatch) => {
  dispatch(
    optimisticallyRemoveCategory(
      id,
      deleteTemplateCategoryCall,
      TemplateAlertIds.deleteCategoryError
    )
  );
  track(CategoriesEvents.update, {
    type: CategoriesUpdateEventTypes.delete,
    source: SourceProperties.templates,
  });
};

export const getSharableCategories = (viewer = {}) => (dispatch) => {
  getTemplateSharableCategoriesCall(viewer)
    .then((categories = []) => {
      dispatch(setTemplateSharableCategories(categories));
    })
    .catch(() => {
      dispatch(openViewAlert(TemplateAlertIds.fetchError));
    });
};

export const getCategoriesForViewer = () => (dispatch, getState) => {
  const viewer = getTemplateViewerForApi(getState());
  dispatch(setTableStateOnFilter());
  dispatch(getSharableCategories(viewer));
  dispatch(getCategoriesTemplates(viewer));
};
