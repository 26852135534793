import React, { useState } from 'react';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Text from 'components/inputs/text';
import Popup from 'components/popups';
import { DYNAMIC_FIELDS_POPUP_WIDTH } from 'web/dynamicFields/components/dynamicFields';
import I18N from 'languages';
import PropTypes from 'prop-types';
import { ConfigureSalesforceActivityDetailsLink } from 'web/settings/adminSettings/salesforce/libs/salesforceConstants';

const ActivityDynamicFields = ({
  actionCreators: { closePopup },
  activityDynamicFieldsSalesforce,
  intl: { formatMessage },
  popupData: { handleFieldSelect },
}) => {
  const [searchText, setSearchText] = useState('');
  const handleSearch = (value) => setSearchText(value);
  const filterFields = (fields, searchText) => {
    const text = searchText.toLowerCase();
    return fields.filter(
      (field) =>
        field.label.toLowerCase().search(text) > -1 ||
        field.value.toLowerCase().search(text) > -1
    );
  };
  const getFieldsListItems = (fields) => {
    return fields.length ? (
      fields.map((field, index) => {
        return (
          <li
            className="fields-item"
            data-value={field.value}
            key={`dynamic-fields-${index}-${field.value}`}
            onClick={handleFieldSelect(field.value)}
          >
            <span className="fields-label text-overflow">
              {I18N.getConstStr('activityDynamicFields', field.label)}
            </span>
            <span className="fields-value text-overflow">{field.value}</span>
          </li>
        );
      })
    ) : (
      <li className="fields-item empty-state">
        <FormattedMessage id="dynamicFields.emptyState" />
      </li>
    );
  };
  const filteredList = getFieldsListItems(
    filterFields(activityDynamicFieldsSalesforce, searchText)
  );

  return (
    <Popup
      header={{
        onClose: closePopup,
        textId: 'web.campaignsTab.insertDynamicField.title',
      }}
      onOutsideClick={closePopup}
      size={DYNAMIC_FIELDS_POPUP_WIDTH}
    >
      <div className="dynamicFields">
        <div className="explanation margin-bottom-full">
          <FormattedHTMLMessage id="activityDynamicFields.explanationOne" />
        </div>
        <div className="explanation margin-bottom-full">
          <FormattedHTMLMessage
            id="activityDynamicFields.explanationTwo"
            values={{ link: ConfigureSalesforceActivityDetailsLink(I18N.getLocale()) }}
          />
        </div>
        <div className="margin-bottom-full">
          <Text
            autoComplete="off"
            autoFocus={false}
            className="dynamic-fields-filter"
            id="search"
            onChange={handleSearch}
            placeholder={formatMessage({
              id: 'dynamicFields.searchPlaceholder',
            })}
            type="text"
          />
        </div>
        <ul className="fields-list">{filteredList}</ul>
      </div>
    </Popup>
  );
};

ActivityDynamicFields.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  activityDynamicFieldsSalesforce: PropTypes.array,
  intl: PropTypes.object.isRequired,
  popupData: PropTypes.object,
};

ActivityDynamicFields.defaultProps = {
  activityDynamicFieldsSalesforce: [],
};

export default injectIntl(ActivityDynamicFields);
