import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { navigateToUrl } from 'web/services/routerService';
import { SafelyChangeRoutePopupIds } from '../libs/safelyChangeRouteConstants';
import UnsavedChangesPopup from '../components/unsavedChangesPopup';

class SafelyChangeRoutePopupContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = {
      onStay: () => dispatch(closePopup()),
      onDiscard: () => {
        dispatch(closePopup());
        navigateToUrl(this.props.url);
      },
    };
  }

  render() {
    const { popup } = this.props;
    const { onStay, onDiscard } = this.actionCreators;

    switch (popup) {
      case SafelyChangeRoutePopupIds.unsavedChanges:
        return <UnsavedChangesPopup onStay={onStay} onDiscard={onDiscard} />;
      default:
        return null;
    }
  }
}

SafelyChangeRoutePopupContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  popup: PropTypes.string.isRequired,
  url: PropTypes.string,
};

SafelyChangeRoutePopupContainer.defaultProps = {
  url: '',
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  url: state.popupData.url,
});

export default connect(mapStateToProps)(SafelyChangeRoutePopupContainer);
