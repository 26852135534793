import ModalsTaskActionTypes from '../libs/modalsTaskActionTypes';
import { BrowserMode, ModalPopupIds } from 'web/libs/constants';
import {
  closeModal,
  openModal,
  setModalComponent,
} from './popupActionCreators';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { TaskModalComponentIds } from 'web/modals/task/libs/taskConstants';

export function taskStartup(mode, sourceOpener) {
  return (dispatch, getState) => {
    dispatch(closeModal()); // reset any previous states
    dispatch(closePopup()); // reset any previous states
    dispatch(commonStartup());

    dispatch(openModal(ModalPopupIds.task));
    dispatch(setModalComponent(TaskModalComponentIds.task));

    switch (mode) {
      case BrowserMode.window:
        dispatch({
          type: ModalsTaskActionTypes.setWindowMode,
        });
        break;
      default:
        break;
    }

    if (sourceOpener) {
      // todo hook up
      dispatch({ type: ModalsTaskActionTypes.setSourceOpener, sourceOpener });
    }

    if (!getState().modalsTaskInitStore) {
      setTimeout(() => {
        dispatch({ type: ModalsTaskActionTypes.initStore });
      });
    }
  };
}
