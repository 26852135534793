import TeamSettingsActionTypes from '../libs/teamSettingsActionTypes';
import {
  getTeamSettings as getTeamSettingsCall,
  updateTeamSettings as updateTeamSettingsCall,
} from '../services/teamSettingsService';

export const setTeamSettingsFetched = (fetched) => ({
  type: TeamSettingsActionTypes.setTeamSettingsFetched,
  fetched,
});

export const setTeamSettingsFetching = () => ({
  type: TeamSettingsActionTypes.setTeamSettingsFetching,
});

export const setTeamSettings = (teamSettings) => ({
  type: TeamSettingsActionTypes.setTeamSettings,
  teamSettings,
});

export const setTeamSettingsError = () => ({
  type: TeamSettingsActionTypes.setTeamSettingsError,
});

export const getTeamSettings = () => (dispatch) => {
  dispatch(setTeamSettingsFetching());

  return getTeamSettingsCall()
    .then((teamSettings) => {
      dispatch(setTeamSettings(teamSettings));
      return teamSettings;
    })
    .catch((error) => {
      dispatch(setTeamSettingsError());
      return Promise.reject(error);
    });
};

export const updateTeamSettings = (updatedTeamSettings) => (dispatch) => {
  dispatch(setTeamSettingsFetching());

  return updateTeamSettingsCall(updatedTeamSettings)
    .then((teamSettings) => {
      dispatch(setTeamSettings(teamSettings));
      return teamSettings;
    })
    .catch((error) => {
      dispatch(setTeamSettingsError());
      return Promise.reject(error);
    });
};
