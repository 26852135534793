export const DiagnosticsTableColumns = {
  actions: 'ACTIONS',
  createdAt: 'CREATED_AT',
  errorCode: 'ERROR_CODE',
  recipient: 'RECIPIENT',
  source: 'SOURCE',
  subject: 'SUBJECT',
  type: 'TYPE',
  user: 'USER',
};

export const TABLE_ID = 'diagnostics-table';
export const CURRENT_PAGE = 1;
export const PER_PAGE = 10;
export const ROW_HEIGHT = 70;
export const COLUMN_SETTINGS_DIAGNOSTICS_TABLE_ID =
  'columns_settings_diagnostics_table_id';
