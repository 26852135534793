import accountDetailsActionTypes from '../libs/accountDetailsActionTypes';

const initialState = {
  pending: false,
};

export function accountDetails(state = initialState, action) {
  switch (action.type) {
    case accountDetailsActionTypes.setLoading: {
      const { pending } = action;
      return {
        ...state,
        pending,
      };
    }
    default:
      return state;
  }
}
