import { getPeopleBySalesforceIds as importPeopleBySalesforceIdsCall } from '../services/peopleService';
import {
  bindPeopleSalesforceImport,
  unbindPeopleSalesforceImport,
} from './peoplePusherActionCreators';
import isFunction from 'lodash/isFunction';
import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import {
  uploadFile as uploadFileCall,
  getAvailableMapFields as getAvailableMapFieldsCall,
  updateCsvMappings as updateCsvMappingsCall,
} from 'web/people/services/peopleImportService';
import {
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import {
  openImportPeopleStepTwo,
  openImportPeopleStepThree,
} from 'web/people/actionCreators/peoplePopupActionCreators';
import {
  getRowFields,
  mapFields,
  getSalesConnectFieldList,
  getEmailIdsFromRowFields,
  filterInviteUsersCsvFields,
  filterInviteUsersSalesConnectFields,
} from 'web/people/helpers/peopleImportHelpers';
import {
  getUpdateMappingData,
  getIsIncludesEmailField,
} from 'web/people/selectors/peopleSelectors';
import Papa from 'papaparse';
import { updateInviteesTableFromCsv } from 'web/settings/adminSettings/userManagement/actionCreators/inviteesActionCreators';

export const setLocalFile = (file) => ({
  type: PeopleActionTypes.import.setLocalFile,
  file,
});

export const setAttachmentFileId = (fileId) => ({
  type: PeopleActionTypes.import.setAttachmentFileId,
  fileId,
});

export const onSalesConnectFieldChange = (value, { id }) => (dispatch) => {
  dispatch({
    type: PeopleActionTypes.import.setImportFieldMap,
    field: {
      id,
      salesConnectField: value,
    },
  });
};

export const setImportFieldMaps = (fields) => ({
  type: PeopleActionTypes.import.setImportFieldMaps,
  fields,
});

export const setSalesConnectFieldList = (fields) => ({
  type: PeopleActionTypes.import.setSalesConnectFieldList,
  fields,
});

export const setFromUserManagementPage = (fromUserManagementPage) => ({
  type: PeopleActionTypes.import.setFromUserManagementPage,
  fromUserManagementPage,
});

export const resetImport = () => ({
  type: PeopleActionTypes.import.reset,
});

export const parseFileHeaders = (file, fromUserManagementPage = false) => (
  dispatch
) => {
  const showFileError = (errorId) => {
    dispatch(resetImport());
    dispatch(setPopupAlert(errorId));
  };

  // We need to parse only first two rows (csv headers and first row)
  const NUMBER_ROWS_PARSE = 2;
  const allowedExtension = new RegExp('.csv$', 'i');

  if (!file || (file && !allowedExtension.test(file.name))) {
    showFileError(PeopleAlertIds.fileTypeError);
    return;
  }

  Papa.parse(file, {
    complete: ({ data }) => {
      const fields = getRowFields(data);

      if (fields) {
        if (fromUserManagementPage) {
          const inviteCsvFields = filterInviteUsersCsvFields(fields);
          dispatch(setImportFieldMaps(inviteCsvFields));
          dispatch(setFromUserManagementPage(fromUserManagementPage));
        } else {
          dispatch(setImportFieldMaps(fields));
          dispatch(setFromUserManagementPage(fromUserManagementPage));
        }
        dispatch(setLocalFile(file));
      } else {
        showFileError(PeopleAlertIds.fileFormatError);
      }
    },
    skipEmptyLines: true,
    preview: NUMBER_ROWS_PARSE,
    error: () => showFileError(PeopleAlertIds.fileFormatError),
  });
};

export const uploadFile = () => (dispatch, getState) => {
  const { importLocalFile } = getState();

  dispatch(setPopupLoading(true));

  return uploadFileCall(importLocalFile)
    .then((data) => {
      dispatch(setPopupLoading(false));
      dispatch(setAttachmentFileId(data.id));
      dispatch(openImportPeopleStepTwo());
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(PeopleAlertIds.fileUploadError));
    });
};

export const loadAvailableMapFields = () => (dispatch, getState) => {
  const {
    attachmentFileId,
    importFieldMaps,
    fromUserManagementPage,
  } = getState();

  dispatch(setPopupLoading(true));

  return getAvailableMapFieldsCall(attachmentFileId)
    .then((availableFields) => {
      if (fromUserManagementPage) {
        //overwrite availableFields.to array
        availableFields.to = filterInviteUsersSalesConnectFields(
          availableFields.to
        );
      }
      const mappedFields = mapFields(availableFields, importFieldMaps);
      const salesConnectFieldList = getSalesConnectFieldList(
        availableFields.to,
        availableFields.existing_custom_fields,
        fromUserManagementPage
      );
      dispatch(setImportFieldMaps(mappedFields));
      dispatch(setSalesConnectFieldList(salesConnectFieldList));
      dispatch(setPopupLoading(false));
    })
    .catch(() => {
      dispatch(setImportFieldMaps([]));
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(PeopleAlertIds.fileUploadError));
    });
};

export const updateInviteesTable = () => (dispatch, getState) => {
  const showFileError = (errorId) => {
    dispatch(resetImport());
    dispatch(setPopupAlert(errorId));
  };
  const state = getState();
  const { importLocalFile } = getState();

  if (!getIsIncludesEmailField(state)) {
    return dispatch(setPopupAlert(PeopleAlertIds.emailFieldMissingError));
  }
  dispatch(setImportFieldMaps([]));
  Papa.parse(importLocalFile, {
    complete: ({ data }) => {
      const emailIds = getEmailIdsFromRowFields(data);
      dispatch(updateInviteesTableFromCsv(emailIds));
    },
    error: () => showFileError(PeopleAlertIds.fileFormatError),
    skipEmptyLines: true,
  });
};

export const updateCsvMappings = () => (dispatch, getState) => {
  const state = getState();
  const data = getUpdateMappingData(state);
  const { attachmentFileId } = getState();

  if (!getIsIncludesEmailField(state)) {
    return dispatch(setPopupAlert(PeopleAlertIds.emailFieldMissingError));
  }

  dispatch(setPopupLoading(true));

  return updateCsvMappingsCall(attachmentFileId, data)
    .then(() => {
      dispatch(setPopupLoading(false));
      dispatch(openImportPeopleStepThree());
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(PeopleAlertIds.fileUploadError));
    });
};

export const importPeopleBySalesforceIds = (multipleRecipients, callback) => (
  dispatch
) =>
  importPeopleBySalesforceIdsCall(multipleRecipients).then(
    ({ job_id: jobId }) => {
      dispatch(bindPeopleSalesforceImport(jobId, callback));
    }
  );

export const salesforceImportComplete = (peopleIds = [], callback) => () => {
  unbindPeopleSalesforceImport();

  if (peopleIds.length) {
    if (isFunction(callback)) {
      callback(peopleIds);
    }
  }
};

export const salesforceImportProgress = () => () => {};
export const salesforceImportFailed = () => () => {};
