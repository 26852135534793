/**
 *
 * BulkActions
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Action from 'components/buttons/action';
import Shapes from 'table/modules/shapes';
import './bulkActions.scss';
import OptionDotaInfobox from 'components/optionDotas/infobox';
import HoverTooltip from 'components/hoverTooltip';

class BulkActions extends PureComponent {
  render() {
    const {
      actions,
      itemsLabel,
      onClearAllClick,
      onSelectAllClick,
      selectAll,
      selectedCount,
      totalCount,
      selectAllDisabled,
    } = this.props;
    const count = selectedCount === totalCount;
    const options = this.getOptions();

    return (
      <div className="tout-bulk-actions">
        <div className="tout-bulk-actions-left text-overflow">
          <span>
            <FormattedMessage
              id="toutTable.bulkActions.selectionLabel"
              values={{ count, itemsLabel, selectedCount }}
            />
          </span>
          {totalCount &&
          selectAll &&
          selectedCount !== totalCount &&
          !selectAllDisabled ? (
            <a
              className="divider"
              href="javascript:void(0)" // eslint-disable-line no-script-url
              onClick={() => {
                onSelectAllClick(totalCount);
              }}
            >
              <FormattedMessage
                id="toutTable.bulkActions.selectAll"
                values={{ itemsLabel, totalCount }}
              />
            </a>
          ) : null}
          <a
            className="divider"
            href="javascript:void(0)" // eslint-disable-line no-script-url
            onClick={onClearAllClick}
          >
            <FormattedMessage id="toutTable.bulkActions.clearSelection" />
          </a>
        </div>
        <div className="tout-bulk-actions-right">
          {this.getActionButtons(actions)}
        </div>
        {!!options.length && <OptionDotaInfobox options={options} />}
      </div>
    );
  }

  getActionButtons = (actions) =>
    actions.map((action, index) => {
      const { isHidden, tooltipText, disabledTooltipText, disabled } = action;
      if (isHidden) return null;
      const shouldCreateTooltipActionButton =
        (!disabled && !!tooltipText) || (disabled && !!disabledTooltipText);

      if (shouldCreateTooltipActionButton) {
        return this.createTooltipActionButton(action, index);
      }
      return this.createActionButton(action, index);
    });

  createTooltipActionButton = (action, index) => {
    const { tooltipText, disabledTooltipText, tooltipPlace } = action;
    const key = `bulk-action-button-tooltip-${index}`;

    return (
      <HoverTooltip
        className="tout-action"
        getContent={() => (
          <FormattedHTMLMessage id={tooltipText || disabledTooltipText} />
        )}
        key={key}
        place={tooltipPlace}
        tooltipId={key}
      >
        {this.createActionButton(action, index)}
      </HoverTooltip>
    );
  };

  createActionButton = (action, index) => {
    const { color, disabled } = action;
    const key = `bulk-action-button-${index}`;

    return (
      <Action
        classes="margin-left-half"
        color={color}
        data-index={index}
        disabled={disabled}
        key={key}
        onClick={this.onClick}
      >
        {this.getBulkActionLabel(action)}
      </Action>
    );
  };

  getBulkActionLabel = ({
    label,
    hideCount,
    getCount = () => this.props.selectedCount,
  }) => `${label}${hideCount ? '' : ` (${getCount(this.props)})`}`;

  getOptions = () => {
    const { dropdownActions, onActionClick } = this.props;
    return dropdownActions.map((action, index) => ({
      label: this.getBulkActionLabel(action),
      onClick: () => {
        onActionClick(action);
      },
      value: `action-${index}`,
    }));
  };

  onClick = (e) => {
    const { actions, onActionClick } = this.props;
    onActionClick(actions[e.currentTarget.dataset.index]);
  };
}

BulkActions.propTypes = {
  actions: PropTypes.arrayOf(Shapes.BulkActionsAction),
  dropdownActions: PropTypes.arrayOf(Shapes.BulkActionsAction),
  itemsLabel: PropTypes.string,
  onActionClick: PropTypes.func.isRequired,
  onClearAllClick: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  selectAll: PropTypes.bool,
  selectAllDisabled: PropTypes.bool,
  selectedCount: PropTypes.number,
  totalCount: PropTypes.number,
};

BulkActions.defaultProps = {
  actions: [],
  dropdownActions: [],
  itemsLabel: '',
  selectAll: true,
  selectAllDisabled: false,
  selectedCount: 0,
  totalCount: 0,
};

export default BulkActions;
