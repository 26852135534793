import ViewersActionTypes from 'web/sidebar/viewers/libs/viewersActionTypes';
import ViewersConstants from 'web/sidebar/viewers/libs/viewersConstants';
import { userViewersFilterItems } from 'web/sidebar/viewers/selectors/userSelectors';

const getViewer = ({ id, name } = {}) => {
  const { user } = ViewersConstants.sidebarViewer.viewer.types;

  return { id, name, type: user };
};

export const setSidebarFilterInput = (inputValue = '') => ({
  type: ViewersActionTypes.sidebarViewer.sidebarFilterInput.set,
  inputValue,
});

const setSidebarViewer = (selectedViewer = {}) => (dispatch) => {
  const { name } = selectedViewer;
  const viewer = getViewer(selectedViewer);

  dispatch(setSidebarFilterInput(name));
  dispatch({ type: ViewersActionTypes.sidebarViewer.viewer.set, viewer });
};

export const onSidebarFilterSelect = ({ label: name, value: id } = {}) => (
  dispatch
) => {
  dispatch(setSidebarViewer({ id, name }));
};

export const initSidebarViewer = () => (dispatch, getState) => {
  const state = getState();
  const { user } = state;
  const { admin, id } = user;

  if (admin) {
    const filterItems = userViewersFilterItems(state);
    const { label, value } =
      filterItems.find(({ value: filterValue }) => filterValue === id) || {};
    dispatch(setSidebarViewer({ id: value, name: label }));
  } else {
    dispatch(setSidebarViewer(user));
  }
};
