import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl, ModalPopupIds } from 'web/libs/constants';
import Popup from 'components/popups';
import {
  closePopup,
  clearPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import { ButtonColors } from 'components/buttons/action';
import PeopleDelete from 'web/people/components/peopleDelete';
import { isAdmin } from 'web/shared/services/accountService';
import {
  getDeletablePeopleIds,
  getPeople,
  getUnsubscribedPeopleCount,
} from 'web/people/selectors/peopleDeleteSelector';
import { getAlert } from 'web/people/services/peoplePopupAlertService';
import { deletePeople } from 'web/modals/peopleDelete/actionCreators/peopleDeleteActionCreators';

const POPUP_SIZE = 535;

class PeopleDeleteContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      clearAlert: () => dispatch(clearPopupAlert()),
      closePopup: () => dispatch(closePopup()),
    };
  }

  render() {
    const {
      alert,
      deletableIds,
      people,
      popup,
      loading,
      unsubscribedCount,
    } = this.props;

    return (
      (popup === ModalPopupIds.peopleDelete && (
        <Popup
          getImageUrl={getImageUrl}
          loading={loading}
          alert={getAlert(this.actionCreators, alert)}
          {...this._getGlobalPopupObject()}
        >
          <PeopleDelete
            people={people}
            deletableCount={deletableIds.length}
            unsubscribedCount={unsubscribedCount}
            isAdmin={isAdmin()}
          />
        </Popup>
      )) ||
      null
    );
  }

  _getGlobalPopupObject = () => {
    const { dispatch, people, deletableIds } = this.props;
    const deletableCount = deletableIds.length;

    return {
      footer: {
        onPrimary: () => dispatch(deletePeople(deletableIds)),
        onSecondary: this.actionCreators.closePopup,
        primaryColor: ButtonColors.red,
        primaryText: this.props.intl.formatMessage(
          { id: 'web.peopleDelete.deleteButton' },
          { count: deletableCount }
        ),
        primaryDisabled: !deletableCount,
        secondaryTextId: 'common.cancel',
      },
      header: {
        onClose: this.actionCreators.closePopup,
        text: this.props.intl.formatMessage(
          { id: 'web.peopleDelete.header' },
          { count: people.length }
        ),
      },
      onOutsideClick: this.actionCreators.closePopup,
      size: POPUP_SIZE,
    };
  };
}

PeopleDeleteContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  popup: PropTypes.string.isRequired,
  alert: PropTypes.string.isRequired,
  alertTextValues: PropTypes.object.isRequired,
  people: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  deletableIds: PropTypes.array.isRequired,
  unsubscribedCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  alert: state.popupAlert,
  alertTextValues: state.popupAlertTextValues,
  currentUserId: state.user.id,
  loading: state.popupLoading,
  people: getPeople(state),
  deletableIds: getDeletablePeopleIds(state),
  unsubscribedCount: getUnsubscribedPeopleCount(state),
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(PeopleDeleteContainer);
