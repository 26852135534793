import { saveExchangeOnpremAuth } from 'web/settings/emailConnection/services/emailConnectionService';
import {
  mapToBackendKeys,
  validateFields,
} from 'web/settings/emailConnection/helpers/emailConnectionHelpers';
import { toCamelCase } from 'web/services/apiHelper';
import {
  setPopupLoading,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { EmailConnectionAlertIds } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import EmailConnectionActionTypes from 'web/settings/emailConnection/lib/emailConnectionActionTypes';

export const updateExchangeOnpremFormState = (formValues) => (dispatch) => {
  dispatch({ formValues, type: EmailConnectionActionTypes.onpremForm.set });
};

export const connectToExchange = () => (dispatch, getState) => {
  const { onpremForm: formData } = getState();
  const formattedRequestData = mapToBackendKeys(formData);

  const error = validateFields(formData);

  if (!error) {
    dispatch(setPopupLoading(true));

    saveExchangeOnpremAuth(formattedRequestData)
      .then(toCamelCase)
      .then((auth) => {
        dispatch(closePopup());
        dispatch({
          auth,
          type: EmailConnectionActionTypes.auth.set,
        });
        dispatch({ type: EmailConnectionActionTypes.onpremForm.reset });
        dispatch(
          openViewAlert(EmailConnectionAlertIds.emailConnectionConnected)
        );
      })
      .catch((err) => {
        dispatch(setPopupLoading(false));
        err.status === 401
          ? dispatch(
              openViewAlert(EmailConnectionAlertIds.form.invalidCredentials)
            )
          : dispatch(
              openViewAlert(EmailConnectionAlertIds.form.emailConnectionFailure)
            );
      });
  } else {
    dispatch(openViewAlert(error));
  }
};

export const onboardingConnectToExchange = () => (dispatch, getState) => {
  const { onpremForm: formData } = getState();
  const formattedRequestData = mapToBackendKeys(formData);

  const error = validateFields(formData);

  if (!error) {
    dispatch(setPopupLoading(true));

    saveExchangeOnpremAuth(formattedRequestData)
      .then(toCamelCase)
      .then((auth) => {
        dispatch(closePopup());
        dispatch({
          auth,
          type: EmailConnectionActionTypes.auth.set,
        });
        dispatch({ type: EmailConnectionActionTypes.onpremForm.reset });
        window.localStorage.setItem('onboardingEmailAuthSuccess', true);
      })
      .catch((err) => {
        dispatch(setPopupLoading(false));
        dispatch(closePopup());

        // remove when check it on staging
        window.localStorage.setItem('onboardingEmailAuthSuccess', false);

        err.status === 401
          ? dispatch(
              openViewAlert(EmailConnectionAlertIds.form.invalidCredentials)
            )
          : dispatch(
              openViewAlert(EmailConnectionAlertIds.form.emailConnectionFailure)
            );
      });
  } else {
    dispatch(openViewAlert(error));
  }
};
