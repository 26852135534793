import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  CampaignsTasksTableId,
  GlobalAlertIds,
  GlobalPopupIds,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import CampaignsTabPopupContainer from 'web/campaigns/campaignsTab/campaignsTabPopupContainer';
import PersonDetailsSlideOut from 'web/slideOuts/personDetails';
import Sidebar from 'web/shared/components/sidebar/sidebar';
import TwoPaneLayout from 'web/shared/components/twoPaneLayout/twoPaneLayout';
import { campaignsSidebarSearchResultsSelected as sidebarSearchResultSelected } from './actionCreators/campaignsSidebarSearchActionCreators';
import {
  currentUsers,
  deleteCampaignConfirm,
  editCampaignName as editCampaignNameAction,
  selectCampaignDetailsTab,
  notEditingCampaignName,
  updateCampaign,
} from './actionCreators/campaignDetailsActionCreators';
import {
  setCampaignSetupView,
  setSlideOutMenuOpen,
} from './actionCreators/campaignsTabSettingsActionCreators';
import { cloneCampaign } from './actionCreators/campaignsCloneActionCreators';
import {
  addEmailStep,
  addingNewDay,
  addStep,
  deleteCampaignStep,
  destroyCampaignEditState,
  initCampaignEditEmailState,
  initCampaignEditReminderState,
  notAddingNewDay,
  openEditWarningAlert,
  openEditWarningPopup,
  optimisticUpdateCampaignStep,
  setNewDayNumber,
  updateCampaignStep,
  updateCampaignEditState,
  updateEmailStep,
  updateStep,
} from './actionCreators/campaignDayActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { openCreateCampaign } from 'web/campaigns/campaignsTab/actionCreators/campaignsActionCreators';
import {
  closeCampaignAlert,
  openCampaignAlert,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsAlertActionCreators';
import { openAddToCampaign } from 'web/modals/addToCampaign/actionCreators/popupActionCreators';
import CampaignContentView from './components/campaignContentView/campaignContentView';
import { getImageUrl } from 'web/libs/constants';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import * as router from 'web/services/routerService';
import {
  deleteCategory,
  setEditCategory,
  renameCategoryConfirm,
  isRenameCategoryDisabled,
  openUpdateCategoryPopup,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsCategoriesActionCreators';
import {
  getCampaignsPeople,
  getCampaignsPeoplePagination,
  getFilteredCampaigns,
  getTasksDueIndexedByCampaign,
  getIsDisabledForViewer,
  getViewAsFilterItems,
  getCampaignViewer,
  getSelectedCampaign,
  getUserCanEditCampaigns,
} from 'web/reducers/campaigns/campaignSelectors';
import {
  getCampaignsTabViewFetching,
  getFilteredTasks,
} from 'web/campaigns/campaignsTab/selectors/tablesTasksSelectors';
import { getCampaignsSidebarSearchResults } from 'web/campaigns/campaignsTab/selectors/campaignsTabSelectors';
import {
  peopleFilterChange,
  peopleViewAsFilterChange,
  setPeopleViewAsFilter,
  getCampaignPeople,
  peoplePaginationClick,
  resetCampaignPeople,
} from 'web/campaigns/people/actionCreators/campaignsPeopleActionCreators';
import {
  resetCampaignPeopleSearch,
  searchCampaignPeopleChange,
} from 'web/campaigns/people/actionCreators/campaignsPeopleSearchActionCreators';
import { filterCampaignsList } from 'web/campaigns/campaignsTab/actionCreators/campaignsFilterActionCreators';
import { updateSettings } from 'web/campaigns/settings/actionCreators/campaignsSettingsActionCreators';
import { openPersonDetails } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import { CampaignActionEvents } from 'web/libs/mixpanelEvents';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import { openModalTask } from 'web/modals/task';
import {
  onCampaignViewAsFilterSelect,
  setCampaignViewAsFilterInput,
} from 'web/campaigns/campaignsTab/actionCreators/campaignViewAsActionCreators';
import { DragDropContextWrapper } from 'web/shared/components/dragDropContext/dragDropContext';
import { setSearchCampaignPeople } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabStartupActionCreators';

class CampaignTabContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    this.actionCreators = {
      addEmailStep: (editorId, day) => dispatch(addEmailStep(editorId, day)),
      addNewCategory: () =>
        dispatch(openPopup(GlobalPopupIds.createNewCategory)),
      addStep: (editorId, day, action) =>
        dispatch(addStep(editorId, day, action)),
      addingNewDay: () => dispatch(addingNewDay()),
      cloneCampaign: (campaign) => dispatch(cloneCampaign(campaign)),
      closeCampaignAlert: () => dispatch(closeCampaignAlert()),
      currentUsers: (id, teams) => dispatch(currentUsers(id, teams)),
      deleteCampaignConfirm: () => dispatch(deleteCampaignConfirm()),
      deleteCampaignStep: (step) => dispatch(deleteCampaignStep(step)),
      deleteCategory: (id) => dispatch(deleteCategory(id)),
      destroyCampaignEditState: (editorId, step) =>
        dispatch(destroyCampaignEditState(editorId, step)),
      editCampaignName: () => dispatch(editCampaignNameAction()),
      filterCampaignsList: (filter) => dispatch(filterCampaignsList(filter)),
      getCampaignPeople: (id, filter) =>
        dispatch(getCampaignPeople(id, filter)),
      imageUrl: getImageUrl,
      initCampaignEditEmailState: (id, step) =>
        dispatch(initCampaignEditEmailState(id, step)),
      initCampaignEditReminderState: (editorId, action, step) =>
        dispatch(initCampaignEditReminderState(editorId, action, step)),
      isRenameCategoryDisabled: (id) => dispatch(isRenameCategoryDisabled(id)),
      notAddingNewDay: () => dispatch(notAddingNewDay()),
      notEditingCampaignName: () => dispatch(notEditingCampaignName()),
      onCampaignViewAsFilterSelect: (filter) =>
        dispatch(onCampaignViewAsFilterSelect(filter)),
      openAddToCampaign: (campaignId) =>
        dispatch(openAddToCampaign(campaignId)),
      openCampaignDayLimitAlert: () =>
        dispatch(openCampaignAlert(GlobalAlertIds.campaignDayLimit)),
      openCreateCampaign: () => dispatch(openCreateCampaign()),
      openEditWarningAlert: () => dispatch(openEditWarningAlert()),
      openEditWarningPopup: () => dispatch(openEditWarningPopup()),
      openPersonDetails: (id) => dispatch(openPersonDetails(id)),
      openTask: (task) => openModalTask(task),
      openToutPhone: (data) => toutBackboneHelper.openToutPhone(data),
      optimisticUpdateCampaignStep: (step) =>
        dispatch(optimisticUpdateCampaignStep(step)),
      peopleConfirmationComplete: (ids, markSuccess, callback) =>
        dispatch(
          openPopup(GlobalPopupIds.removeConfirmation, {
            callback,
            ids,
            markSuccess,
          })
        ),
      peopleFilterChange: (index, value) => dispatch(peopleFilterChange(value)),
      peoplePaginationClick: (newPage) =>
        dispatch(peoplePaginationClick(newPage)),
      peopleViewAsFilterChange: (index, value) =>
        dispatch(peopleViewAsFilterChange(value)),
      renameCategoryConfirm: (id, name) =>
        dispatch(renameCategoryConfirm(id, name)),
      resetCampaignPeople: () => dispatch(resetCampaignPeople()),
      resetCampaignPeopleSearch: () => dispatch(resetCampaignPeopleSearch()),
      router,
      searchCampaignPeopleChange: (id, value) =>
        dispatch(searchCampaignPeopleChange(id, value)),
      selectCampaignDetailsTab: (data) =>
        dispatch(selectCampaignDetailsTab(data)),
      setCampaignSetupView: (horizontal) =>
        dispatch(setCampaignSetupView(horizontal)),
      setCampaignViewAsFilterInput: (filter) =>
        dispatch(setCampaignViewAsFilterInput(filter)),
      setEditCategory: (id) => dispatch(setEditCategory(id)),
      setNewDayNumber: (data) => dispatch(setNewDayNumber(data)),
      setPeopleViewAsFilter: (value) => dispatch(setPeopleViewAsFilter(value)),
      setSearchCampaignPeople: (value) =>
        dispatch(setSearchCampaignPeople(value)),
      setSlideOutMenuOpen: (open) => dispatch(setSlideOutMenuOpen(open)),
      shareCampaign: () => dispatch(openPopup(GlobalPopupIds.shareCampaign)),
      sidebarSearchResultSelected: (data) =>
        dispatch(sidebarSearchResultSelected(data)),
      updateCampaign: (data, save) => dispatch(updateCampaign(data, save)),
      updateCampaignEditState: (editorId, data) =>
        dispatch(updateCampaignEditState(editorId, data)),
      updateCampaignStep: (step, save) =>
        dispatch(updateCampaignStep(step, save)),
      updateCategory: () => dispatch(openUpdateCategoryPopup()),
      updateEmailStep: (editorId, step) =>
        dispatch(updateEmailStep(editorId, step)),
      updateSettings: (selectedCampaign, data) =>
        dispatch(updateSettings(selectedCampaign, data)),
      updateStep: (editorId, step) => dispatch(updateStep(editorId, step)),
    };
  }

  getViewAsFilter = () => {
    const {
      account: { admin },
      viewAsFilterItems,
      campaignsViewAsFilterInput,
      campaignViewer: { id },
      intl: { formatMessage },
    } = this.props;

    if (!admin) {
      return {};
    }

    return {
      inputValue: campaignsViewAsFilterInput,
      items: viewAsFilterItems,
      label: formatMessage({ id: 'web.templates.viewAs' }),
      onChange: this.actionCreators.setCampaignViewAsFilterInput,
      onSelect: this.actionCreators.onCampaignViewAsFilterSelect,
      selectedItem: id,
    };
  };

  render() {
    const {
      account,
      addingNewCampaignDay,
      attachments,
      campaignsPeople,
      campaignsPeoplePagination,
      campaignAlert,
      campaignCategories,
      campaignsListFilter,
      campaignDayEditing,
      campaignDayLoading,
      campaignEditStates,
      campaignTemplates,
      campaignTemplatesLoading,
      campaignsLoading,
      campaignsPeopleFilter,
      campaignsPeopleViewAsFilter,
      campaignsTabViewFetching,
      campaignsTabViewLoading,
      campaignsPeopleSearchString,
      categoriesLoading,
      ckeBodies,
      currentCategory,
      editingCampaignCategory,
      editCampaignName,
      editingSteps,
      filteredCampaigns,
      intl: { formatMessage },
      newDayNumber,
      selectedCampaign,
      selectedDetailsTab,
      settings,
      sidebarSearchResults,
      tasks,
      tasksDueIndexedByCampaign,
      timezone,
      timezones,
      campaignsPeopleLength,
      isDisabledForViewer,
      userCanEditCampaigns,
    } = this.props;

    return [
      <CampaignsTabPopupContainer key="campaigns-tab-popup-container" />,
      <PersonDetailsSlideOut key="campaigns-tab-person-details-slide-out" />,
      <TwoPaneLayout key="campaign-tab-container">
        <Sidebar
          actionButton={
            userCanEditCampaigns
              ? {
                  disabled: isDisabledForViewer,
                  onClick: this.actionCreators.openCreateCampaign,
                  textId: formatMessage({
                    id: 'web.campaigns.createNewCampaign',
                  }),
                }
              : null
          }
          filter={this.getViewAsFilter()}
          list={{
            editingItemId: editingCampaignCategory,
            isItemAddHidden: !userCanEditCampaigns,
            itemOptions: userCanEditCampaigns
              ? [
                  {
                    action: this.actionCreators.setEditCategory,
                    disabled: this.actionCreators.isRenameCategoryDisabled,
                    label: formatMessage({ id: 'common.rename' }),
                  },
                  {
                    action: this.actionCreators.deleteCategory,
                    label: formatMessage({ id: 'common.delete' }),
                  },
                ]
              : null,
            listData: campaignCategories,
            listLoading: categoriesLoading,
            listName: formatMessage({ id: 'common.categories' }),
            onEdit: this.actionCreators.renameCategoryConfirm,
            onEditCancel: this.actionCreators.setEditCategory,
            onItemAddClick: this.actionCreators.addNewCategory,
            onItemAddDisabled: isDisabledForViewer,
            onSelect: router.navigateToCampaignCategory,
            selectedListItem: currentCategory,
          }}
          multiSearch={{
            mixpanelEvent: CampaignActionEvents.searchCampaign,
            onSearchResultClick: this.actionCreators
              .sidebarSearchResultSelected,
            searchResults: sidebarSearchResults,
          }}
        />
        <CampaignContentView
          account={account}
          actionCreators={this.actionCreators}
          addingNewCampaignDay={addingNewCampaignDay}
          attachments={attachments}
          campaignAlert={campaignAlert}
          campaignDayEditing={campaignDayEditing}
          campaignDayLoading={campaignDayLoading}
          campaignEditStates={campaignEditStates}
          campaignTemplates={campaignTemplates}
          campaignTemplatesLoading={campaignTemplatesLoading}
          campaigns={filteredCampaigns}
          campaignsListFilter={campaignsListFilter}
          campaignsLoading={campaignsLoading}
          campaignsPeople={campaignsPeople}
          campaignsPeopleFilter={campaignsPeopleFilter}
          campaignsPeopleLength={campaignsPeopleLength}
          campaignsPeoplePagination={campaignsPeoplePagination}
          campaignsPeopleSearchString={campaignsPeopleSearchString}
          campaignsPeopleViewAsFilter={campaignsPeopleViewAsFilter}
          campaignsTabViewFetching={campaignsTabViewFetching}
          campaignsTabViewLoading={campaignsTabViewLoading}
          ckeBodies={ckeBodies}
          currentCategory={currentCategory}
          editingSteps={editingSteps}
          isEditingName={editCampaignName}
          newDayNumber={newDayNumber}
          selectedCampaign={selectedCampaign}
          selectedDetailsTab={selectedDetailsTab}
          settings={settings}
          tasks={tasks}
          tasksDueIndexedByCampaign={tasksDueIndexedByCampaign}
          timezone={timezone}
          timezones={timezones}
          userCanEditCampaigns={userCanEditCampaigns}
        />
      </TwoPaneLayout>,
    ];
  }
}

CampaignTabContainer.propTypes = {
  account: PropTypes.object.isRequired,
  addingNewCampaignDay: PropTypes.bool,
  attachments: PropTypes.object.isRequired,
  campaignAlert: PropTypes.object.isRequired,
  campaignCategories: PropTypes.array.isRequired,
  campaignDayEditing: PropTypes.object.isRequired,
  campaignDayLoading: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  campaignTemplatesLoading: PropTypes.bool.isRequired,
  campaignViewer: PropTypes.object.isRequired,
  campaignsListFilter: PropTypes.string.isRequired,
  campaignsLoading: PropTypes.bool,
  campaignsPeople: PropTypes.array.isRequired,
  campaignsPeopleFilter: PropTypes.string.isRequired,
  campaignsPeopleLength: PropTypes.number.isRequired,
  campaignsPeoplePagination: PropTypes.number.isRequired,
  campaignsPeopleSearchString: PropTypes.string.isRequired,
  campaignsPeopleViewAsFilter: PropTypes.number.isRequired,
  campaignsTabViewFetching: PropTypes.bool,
  campaignsTabViewLoading: PropTypes.bool,
  campaignsViewAsFilterInput: PropTypes.string.isRequired,
  categoriesLoading: PropTypes.bool,
  ckeBodies: PropTypes.object.isRequired,
  currentCategory: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  editCampaignName: PropTypes.bool.isRequired,
  editingCampaignCategory: PropTypes.number,
  editingSteps: PropTypes.object,
  filteredCampaigns: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  isDisabledForViewer: PropTypes.bool.isRequired,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedCampaign: PropTypes.object,
  selectedDetailsTab: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  sidebarSearchResults: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  tasksDueIndexedByCampaign: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  userCanEditCampaigns: PropTypes.bool,
  viewAsFilterItems: PropTypes.array.isRequired,
};

CampaignTabContainer.defaultProps = {
  addingNewCampaignDay: false,
  campaignsLoading: false,
  campaignsTabViewFetching: false,
  campaignsTabViewLoading: false,
  categoriesLoading: false,
  editingCampaignCategory: 0,
  editingSteps: [],
  newDayNumber: '',
  selectedCampaign: {},
  userCanEditCampaigns: true,
};

function mapStateToProps(state) {
  return {
    account: state.user, //todo migrate all account refs
    addingNewCampaignDay: state.addingNewCampaignDay,
    attachments: state.emailComposeAttachmentsMap,
    campaignAlert: state.campaignsTabCampaignAlert,
    campaignCategories: state.campaignCategories,
    campaignDayEditing: state.campaignsTabDayEditing,
    campaignDayLoading: state.campaignsTabCampaignDayLoading,
    campaignEditStates: state.campaignsTabEditStates,
    campaignTemplates: state.campaignsTabCampaignTemplates,
    campaignTemplatesLoading: state.campaignTemplatesLoading,
    campaignViewer: getCampaignViewer(state),
    campaignsListFilter: state.campaignsListFilter,
    campaignsLoading: state.campaignsTabContentViewLoading,
    campaignsPeople: getCampaignsPeople(state),
    campaignsPeopleFilter: state.campaignsPeopleFilter,
    campaignsPeopleLength: getCampaignsPeople(state).length,
    campaignsPeoplePagination: getCampaignsPeoplePagination(state),
    campaignsPeopleSearchString: state.campaignsPeopleSearchString,
    campaignsPeopleViewAsFilter: state.campaignsPeopleViewAsFilter,
    campaignsTabViewFetching: getCampaignsTabViewFetching(state),
    campaignsTabViewLoading: state.campaignsTabViewLoading,
    campaignsViewAsFilterInput: state.campaignViewAsFilterInput,
    categoriesLoading: state.campaignCategoriesLoading,
    ckeBodies: state.emailComposeCkeBodies,
    currentCategory: state.currentCategory,
    editCampaignName: state.editCampaignName,
    editingCampaignCategory: state.editingCampaignCategory,
    editingSteps: state.campaignsTabEditingSteps,
    filteredCampaigns: getFilteredCampaigns(state),
    isDisabledForViewer: getIsDisabledForViewer(state),
    newDayNumber: state.campaignsTabNewDayNumber,
    selectedCampaign: getSelectedCampaign(state),
    selectedDetailsTab: state.campaignsTabSelectedDetailsTab,
    settings: state.campaignsTabSettings,
    sidebarSearchResults: getCampaignsSidebarSearchResults(state),
    tasks: getFilteredTasks(state, { tableId: CampaignsTasksTableId }), // need tableId prop passed in for ToutTable selectors
    tasksDueIndexedByCampaign: getTasksDueIndexedByCampaign(state),
    timezone: state.userTimezone,
    timezones: state.timezones,
    userCanEditCampaigns: getUserCanEditCampaigns(state),
    viewAsFilterItems: getViewAsFilterItems(state),
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl,
  withCommonModals,
  DragDropContextWrapper
)(CampaignTabContainer);
