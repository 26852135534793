import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './gongBanner.scss';

export default class GongBanner extends Component {
  render() {
    const { title, subTitle } = this.props;

    return (
      <div className="gong-banner-container">
        <div className="gong-banner">
          <div className="gong-banner-icon">
            <span />
          </div>
          <span className="gong-banner-title">{title}</span>
          <span className="gong-banner-subtitle">{subTitle}</span>
        </div>
      </div>
    );
  }
}

GongBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};
