let csrfToken = '';

export default {
  getCsrfToken: () => csrfToken,
  setCsrfToken: (token) => {
    csrfToken = token;
  },
  getCsrfTokenFromDocument: () => {
    let token = null;
    if (document.querySelector('meta[name="csrf-token"]')) {
      token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');
    }
    return token;
  },
};

export const toCamelCase = (obj = {}) => {
  const camelCaseObj = {};

  for (const snakeCasedKey in obj) {
    const camelCasedKey = snakeCasedKey.replace(/(_\w)/g, (substr) =>
      substr[1].toUpperCase()
    );
    const value = obj[snakeCasedKey];
    if (value && !Array.isArray(value) && typeof value === 'object') {
      camelCaseObj[camelCasedKey] = toCamelCase(value);
    } else {
      camelCaseObj[camelCasedKey] = value;
    }
  }

  return camelCaseObj;
};

export const toSnakeCase = (obj = {}) => {
  const snakeCaseObj = {};

  for (const camelCasedKey in obj) {
    const snakeCasedKey = camelCasedKey.replace(
      /([A-Z])/g,
      (char) => `_${char.toLowerCase()}`
    );
    const value = obj[camelCasedKey];
    if (value && !Array.isArray(value) && typeof value === 'object') {
      snakeCaseObj[snakeCasedKey] = toSnakeCase(value);
    } else {
      snakeCaseObj[snakeCasedKey] = value;
    }
  }

  return snakeCaseObj;
};
