import {
  CAMPAIGN_FIRST_DAY,
  CAMPAIGN_FIRST_ORDER,
  CampaignStepActions,
  CategoriesViewerTypes,
  CampaignsSpecialUsers,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';

const TWELVE_HOURS = 12;

export const isFirstDay = (day) => day === CAMPAIGN_FIRST_DAY;
export const isFirstOrder = (order) => order === CAMPAIGN_FIRST_ORDER;

export const isCallStep = ({ action }) => action === CampaignStepActions.call;
export const isEmailStep = ({ action }) => action === CampaignStepActions.email;
export const isEmailTaskStep = ({ action }) =>
  action === CampaignStepActions.emailTask;
export const isInmailStep = ({ action }) =>
  action === CampaignStepActions.inmail;

export const getFirstStep = ({ steps = [] }) =>
  steps.find(({ day, order }) => isFirstDay(day) && isFirstOrder(order)) || {};

export const startsWithEmail = (campaign) => {
  const firstStep = getFirstStep(campaign);
  return isEmailStep(firstStep);
};

export const startsWithNonEmail = (campaign) => !startsWithEmail(campaign);

export const addTwelveHours = (time) => parseInt(time, 10) + TWELVE_HOURS;

export const removeTwelveHours = (time) => parseInt(time, 10) - TWELVE_HOURS;

export const hasSteps = ({ steps = [] }) => !!steps.length;
export const hasNoSteps = (campaign) => !hasSteps(campaign);

export const getViewer = (id, name) => {
  const { user, subscription } = CategoriesViewerTypes;
  const type = id === CampaignsSpecialUsers.all.id ? subscription : user;

  return { id, name, type };
};

export const isSharedCampaign = (campaign) =>
  Array.isArray(campaign.team_ids) && campaign.team_ids.length > 0;

export const isUnsharedCampaign = (campaign) =>
  Array.isArray(campaign.team_ids) && campaign.team_ids.length === 0;

export const isUnsharedCampaignForAdmin = (campaign, user) =>
  user.admin && isUnsharedCampaign(campaign);

export const getCampaignsSidebarSearchResults = (searchData, query) => {
  let filteredResults = [];
  if (query) {
    filteredResults = searchData.reduce((acc, searchItem) => {
      const collection = searchItem.collection.filter(
        ({ name }) => name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );

      return collection.length
        ? acc.concat([
            {
              name: searchItem.name,
              type: searchItem.type,
              onSelected: searchItem.onSelected,
              collection,
            },
          ])
        : acc;
    }, []);
  }
  return filteredResults;
};
