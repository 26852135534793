import { createSelector } from 'reselect';
import {
  isUnsubscribed,
  isShared,
  getPersonName,
} from 'web/person/helpers/personHelpers';
import get from 'lodash/get';

const getPopupPeople = (state) => state.peopleForDelete;
const getUserId = (state) => state.user.id;

export const getPeople = createSelector(
  [getPopupPeople, getUserId],
  (people = [], userId) =>
    people.map((item) => {
      const person = Object.assign({}, item);

      if (isUnsubscribed(person)) {
        person._isDeletable = false;
        person._isUnsubscribed = true;
      } else if (isShared(person, userId)) {
        person._isDeletable = false;
        person._isShared = true;
      } else {
        person._isDeletable = true;
      }

      person.name = getPersonName(person) || '';
      person.companyName = get(person, 'company.name', '');
      person.title = person.title || '';
      person.blockedReason = !person._isDeletable;

      return person;
    })
);

export const getUnsubscribedPeopleCount = createSelector(
  [getPeople],
  (people) => people.filter((person) => person._isUnsubscribed).length
);

export const getDeletablePeople = createSelector([getPeople], (people) =>
  people.filter((person) => person._isDeletable)
);

export const getDeletablePeopleIds = createSelector(
  [getDeletablePeople],
  (deletablePeople) => deletablePeople.map((person) => person.id)
);
