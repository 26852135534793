import MarketoSettingsActionTypes from '../libs/marketoSettingsActionTypes';
import {
  getIntegration,
  connectIntegration,
  disconnectIntegration,
} from '../services/integrationConnection';
import { getConnectionInfo, getEAU, getESU } from '../services/authHelpers';
import {
  ConnectionStates,
  ErrorMessages,
} from '../libs/marketoSettingsConstants';

export const selectTab = (tabName) => ({
  type: MarketoSettingsActionTypes.tabSelected[tabName],
});

export const getMarketoIntegration = () => (dispatch, getState) => {
  dispatch({ type: MarketoSettingsActionTypes.getConnection });
  return getIntegration()
    .then((auths = []) => {
      // TODO: Phase 2: Backend will always return an object so we're not checking props of an array.
      if (!auths.length && auths.salesforce_connection === false) {
        dispatch({ type: MarketoSettingsActionTypes.noSalesforceConnection });
      } else if (auths.length && getEAU(auths, getState().user.id)) {
        dispatch({ type: MarketoSettingsActionTypes.setup.connectSuccess });
        dispatch({
          connectionInfo: getConnectionInfo(auths),
          type: MarketoSettingsActionTypes.connectionInfo,
        });
        dispatch({
          type: MarketoSettingsActionTypes.getConnectionSuccess,
          userIds: auths.map((auth) => auth.user_id),
        });
        dispatch({
          auths,
          type:
            MarketoSettingsActionTypes.userAccess.getMarketoAuthenticationUsers,
        });
      } else if (auths.length && getESU(auths, getState().user.id)) {
        dispatch({ type: MarketoSettingsActionTypes.hasConnection });
        dispatch({
          connectionInfo: getConnectionInfo(auths),
          type: MarketoSettingsActionTypes.connectionInfo,
        });
      } else {
        dispatch({ type: MarketoSettingsActionTypes.getConnectionNull });
        dispatch({
          connectionInfo: getConnectionInfo(auths),
          type: MarketoSettingsActionTypes.connectionInfo,
        });
      }
      dispatch({
        fetched: true,
        type: MarketoSettingsActionTypes.getConnectionFetched,
      });
    })
    .catch(() =>
      dispatch({ type: MarketoSettingsActionTypes.getConnectionFailed })
    )
    .finally(() => {
      dispatch({
        fetching: false,
        type: MarketoSettingsActionTypes.getConnectionFetching,
      });
    });
};

const connectMarketoIntegration = (params) => (dispatch, getState) => {
  dispatch({ type: MarketoSettingsActionTypes.setup.connect });
  connectIntegration({
    currentUserId: getState().user.id,
    ...params,
  })
    .then(() => {
      dispatch({ type: MarketoSettingsActionTypes.setup.connectSuccess });
      dispatch(getMarketoIntegration());
    })
    .catch((err) => {
      const {
        response: { body = {} },
      } = err;

      if (body.error === ErrorMessages.alreadyExists) {
        dispatch({ type: MarketoSettingsActionTypes.setup.alreadyExists });
      } else {
        dispatch({ type: MarketoSettingsActionTypes.getConnectionFailed });
      }
    });
};

const disconnectMarketoIntegration = () => (dispatch, getState) => {
  const { id } = getState().user;
  dispatch({ type: MarketoSettingsActionTypes.setup.disconnect });
  disconnectIntegration(id)
    .then(() => {
      dispatch({ type: MarketoSettingsActionTypes.setup.disconnectSuccess });
      dispatch(getMarketoIntegration());
    })
    .catch(() => {
      dispatch({ type: MarketoSettingsActionTypes.setup.disconnectFailed });
    });
};

export const handleIntegrationConnection = (params) => (dispatch, getState) => {
  const connectionState = getState().marketoAdminConnectionState;
  if (connectionState === ConnectionStates.disconnected) {
    dispatch(connectMarketoIntegration(params));
  } else if (connectionState === ConnectionStates.connected) {
    dispatch(disconnectMarketoIntegration());
  }
};
