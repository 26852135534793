/*global $*/
import { getCampaigns } from 'web/campaigns/campaignsTab/actionCreators/campaignsActionCreators';
import { getAllTemplates } from 'web/templates/actionCreators/templatesDataActionCreators';
import { getCampaignTemplates } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabDataActionCreators';
import { filterTemplates as filterTemplatesEs } from 'web/templates/actionCreators/templatesFetchActionCreators';
import { templatesCategoryFilter } from 'web/templates/actionCreators/templateCategoriesActionCreators';
import { updateTemplate } from 'web/templates/actionCreators/templatesUpdateActionCreators';
import { redirectOnCreate } from 'web/templates/actionCreators/templatesCreateActionCreators';
import { closeSlideOuts } from 'web/slideOuts/actionCreators/slideOutsActionCreators';
import { getUser } from 'web/user/actionCreators/userActionCreators';
import {
  getGroups,
  removeGroup,
  updateGroup,
} from 'web/groups/actionCreators/groupsActionCreators';
import { setNextPersonDetails } from 'web/person/actionCreators/personDetailsNextActionCreators';
import { updateCurrentPerson } from 'web/person/actionCreators/personDetailsPusherActionCreators';
import { setContactIntegrations } from 'web/contactIntegrations/actionCreators/contactIntegrationsActionCreators';
import {
  groupComposeInit,
  setRecipients,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import CkEditor from 'web/ckEditor/services/ckEditorService';
import { importPeopleBySalesforceIds } from 'web/people/actionCreators/peopleImportActionCreators';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { paginatePeople } from 'web/people/actionCreators/peopleFetchActionCreators';

// todo remove template ones when have react compose

export default {
  // init any subscribes from backbone app -> react
  campaignDelete: (campaignId) => {
    if (window.nextEnvironment) {
      $.publish('campaign:delete', campaignId);
    }
  },
  campaignUpdate: (campaign) => {
    if (window.nextEnvironment) {
      $.publish('campaign:update', campaign);
    }
  },
  closePersoneDetailSlideOut: (groupId) => {
    if (window.nextEnvironment) {
      $.publish('toutapp.closePersoneDetailSlideOut', groupId);
    }
  },
  composeOverlayWithReply: (draftEmailId, onComposeLoaded) => {
    const params = { draftEmailId, onComposeLoaded };
    $.publish('toutapp.composeOverlayWithReply', params);
  },
  dynamicFieldsSelected: (data) => {
    if (window.nextEnvironment) {
      $.publish('dynamic-fields-selected', data);
    }
  },
  emailPeopleGroup: (groupId) => {
    $.publish('toutapp.emailPeopleGroup', groupId);
  },
  emailSelectedPeople: (ids) => {
    $.publish('toutapp.emailSelectedPeople', ids);
  },
  imageInsert: (data) => {
    if (window.nextEnvironment) {
      $.publish('image-insert', data);
    }
  },
  imageUpdate: (data) => {
    if (window.nextEnvironment) {
      $.publish('image-update', data);
    }
  },
  init: (store) => {
    if (window.nextEnvironment) {
      $.subscribe('campaigns:update', () => {
        store.dispatch(getCampaigns());
        store.dispatch(getCampaignTemplates());
      });
      $.subscribe('templates:update', (topic, template) => {
        store.dispatch(getAllTemplates());
        if (template) {
          store.dispatch(updateTemplate(template));
        }
      });

      $.subscribe('templates:create', (topic, template) => {
        store.dispatch(getAllTemplates());
        store.dispatch(redirectOnCreate(template));
      });

      $.subscribe('templates:destroy', (topic, template) => {
        store.dispatch(getAllTemplates());
        const filters = templatesCategoryFilter(template.category_id);
        const notFilters = { id: template.id };
        store.dispatch(filterTemplatesEs(filters, { notFilters }));
      });

      $.subscribe('groups:update', (topic, group) => {
        if (group) {
          store.dispatch(updateGroup(group));
        } else {
          store.dispatch(getGroups(true));
        }
      });

      $.subscribe('groups:destroy', (topic, id) => {
        store.dispatch(removeGroup(id));
      });

      $.subscribe('person-details:update', (topic, { id, options }) => {
        store.dispatch(setNextPersonDetails(parseInt(id, 10), options));
      });

      $.subscribe('toutapp.emailCreated', (topic, { person }) => {
        if (person) {
          //When we select group person id will be nil
          store.dispatch(updateCurrentPerson(person.id));
        }
      });

      $.subscribe('call-saved', (topic, backboneObj) => {
        const personId =
          backboneObj &&
          backboneObj.toJSON &&
          backboneObj.toJSON().call.person_id;
        store.dispatch(updateCurrentPerson(personId));
      });

      $.subscribe('user:update', () => {
        store.dispatch(getUser());
      });

      $.subscribe('react:clean-up', () => {
        CkEditor.cleanUpPopups();
        store.dispatch(closeSlideOuts());
      });

      $.subscribe('contact-integrations:update', (topic, integrations) => {
        store.dispatch(setContactIntegrations(integrations));
      });

      $.subscribe('pitches:bulk-create', (topic, recipients) => {
        store.dispatch(setRecipients(recipients));
      });

      $.subscribe('pitches:group-create', (topic, group) => {
        store.dispatch(groupComposeInit(group));
      });

      $.subscribe('people:fetch', () => {
        store.dispatch(paginatePeople());
      });

      // TODO possible dead code -> remove
      $.subscribe('compose:import-people', (topic, data = {}) => {
        store.dispatch(commonStartup());
        store.dispatch(
          importPeopleBySalesforceIds(data.ids, data.type, (peopleIds) =>
            $.publish('compose:import-people-complete', peopleIds)
          )
        );
      });
    }
  },
  insertContentPartnerLinks: (data) => {
    if (window.nextEnvironment) {
      $.publish('insert-content-partner-links', data);
    }
  },
  liveFeedEventsCountUpdate: (count) => {
    if (window.nextEnvironment) {
      $.publish('live-feed-events-count:update', count);
    }
  },
  onReactAlertsInitialized: (hideAlerts) => {
    $.publish('reactAlerts:initialized');
    $.subscribe('reactAlerts:hide', hideAlerts);
  },
  openCallModal: (event) => {
    if (window.nextEnvironment) {
      $.publish('toutapp.emailCallClicked', event);
    }
  },
  openComposeFollowUp: (emailId, onSuccess, onFailure) => {
    if (window.nextEnvironment) {
      $.publish('compose:follow-up', emailId);

      $.unsubscribe('compose:follow-up-complete');
      $.subscribe('compose:follow-up-complete', (topic, isSuccessful) => {
        isSuccessful ? onSuccess() : onFailure();
      });
    }
  },
  openContent: () => {
    if (window.nextEnvironment) {
      $.publish('open-content-modal');
    }
  },
  openToutPhone: (data) => {
    if (window.nextEnvironment) {
      $.publish('tout-phone:open', data);
    }
  },
  personDelete: (id) => {
    if (window.nextEnvironment) {
      $.publish('person:deleted', id);
    }
  },
  personDetailsClose: () => {
    if (window.nextEnvironment) {
      $.publish('person-details:close');
    }
  },
  personRefetch: (id) => {
    if (window.nextEnvironment) {
      $.publish('person:refetch', id);
    }
  },
  personRemoveFromGroup: (groupMember) => {
    if (window.nextEnvironment) {
      $.publish('person:remove-from-group', groupMember);
    }
  },
  personUpdate: (person) => {
    if (window.nextEnvironment) {
      $.publish('person:update', person);
    }
  },
  refreshPeopleTable: () => {
    if (window.nextEnvironment) {
      $.publish('toutapp.relationships.clearBulkSelect');
      $.publish('toutapp.relationships.refreshPage');
    }
  },
  stepUpdate: (step) => {
    if (window.nextEnvironment) {
      $.publish('step:update', step);
    }
  },
  taskDeleted: (id) => {
    if (window.nextEnvironment) {
      $.publish('reminder:deleted', id);
    }
  },
  taskSaved: (task) => {
    if (window.nextEnvironment) {
      $.publish('reminder:saved', task);
    }
  },
  teamSettingsRefetch: () => {
    if (window.nextEnvironment) {
      $.publish('team-settings:refetch');
    }
  },
  templateDelete: (id) => {
    if (window.nextEnvironment) {
      $.publish('template:deleted', id);
    }
  },
  templateRefetch: () => {
    if (window.nextEnvironment) {
      $.publish('template:refetch');
    }
  },
  templateUpdate: (template) => {
    if (window.nextEnvironment) {
      $.publish('template:update', template);
    }
  },
  updateAccount: (data) => {
    if (window.nextEnvironment) {
      $.publish('account:update', data);
    }
  },
  updateAdminUnsubscribes: (data) => {
    if (window.nextEnvironment) {
      $.publish('adminUnsubscribes:update', data);
    }
  },
  updateAdminUnsubscribeAutoAppend: (data) => {
    if (window.nextEnvironment) {
      $.publish('adminUnsubscribes:update', data);
    }
  },
  updateCampaignTemplatesDone: () => {
    if (window.nextEnvironment) {
      $.publish('update-campaign-templates:done');
    }
  },
  updateContactIntegrations: (integrations) => {
    if (window.nextEnvironment) {
      $.publish('contactIntegrations:update', integrations);
    }
  },
  updateIdentities: (identities) => {
    if (window.nextEnvironment) {
      $.publish('identities:update', identities);
    }
  },
  updateUserUnsubscribes: (data) => {
    if (window.nextEnvironment) {
      $.publish('userUnsubscribes:update', data);
    }
  },
  viewStatus: (
    data = {
      complete: false,
      pane: null,
    }
  ) => {
    if (window.nextEnvironment) {
      $.publish('view-status', data);
    }
  },
};
