import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import TextArea from 'components/inputs/textarea';
import Button, { ButtonColors } from 'components/buttons/action';
import './commentPost.scss';

class CommentPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.defaultText,
    };
  }

  componentWillMount() {
    this.placeholder = this.props.intl.formatMessage({
      id: 'comment.post.placeholder',
    });
  }

  render() {
    const { autoFocus, isEditing } = this.props;
    const { text } = this.state;

    return (
      <div className="comment-post">
        <TextArea
          autoFocus={autoFocus}
          onChange={this.onChange}
          placeholder={this.placeholder}
          rows={2}
          value={text}
        />
        <div className="comment-post-done">
          <Button
            className="margin-right-half"
            color={ButtonColors.gray}
            onClick={this.buttonCancel}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button onClick={this.onDoneEditing}>
            <FormattedMessage
              id={(isEditing && 'common.save') || 'common.add'}
            />
          </Button>
        </div>
      </div>
    );
  }

  onChange = (text) => this.setState(() => ({ text }));

  buttonCancel = () => {
    this.setState({ text: '' });
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  };

  onDoneEditing = () => {
    const { onDone } = this.props;
    const { text } = this.state;
    this.onChange('');
    onDone(text, null);
  };
}

CommentPost.propTypes = {
  autoFocus: PropTypes.bool,
  defaultText: PropTypes.string,
  intl: intlShape.isRequired,
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  onDone: PropTypes.func.isRequired,
};

CommentPost.defaultProps = {
  autoFocus: false,
  defaultText: '',
  isEditing: false,
  onCancel: null,
};

export default injectIntl(CommentPost);

//eslint-disable-next-line import/no-mutable-exports
export let UnwrappedCommentPost;
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedCommentPost = CommentPost;
}
