import { Urls } from 'web/libs/routes';
import { PopupSize } from 'web/liveFeed/libs/liveFeedConstants';
import {
  openAddToCampaign as openAddToCampaignCall,
  openNewTab,
  openNewTabWithSize,
} from 'web/services/routerService';

export const openEmail = (personId, addressId) => {
  openNewTabWithSize(
    Urls.composeWindowPersonModal
      .replace(':#personId', personId)
      .replace(':#addressId', addressId),
    725,
    800
  );
};

export const openAddToCampaign = (personId) => {
  openAddToCampaignCall({ people_ids: [personId] });
};

export const openCall = (personId, taskId = '') => {
  const person = (personId && `/person/${personId}`) || '';
  const task = (taskId && `/reminder/${taskId}`) || '';
  const {
    liveFeedCall: { width, height },
  } = PopupSize;
  openNewTabWithSize(`${Urls.callBase}${person}${task}`, width, height);
};

export const openSalesforceContact = (salesforceInstanceUrl, salesforceId) => {
  openNewTab(salesforceInstanceUrl + (salesforceId ? `/${salesforceId}` : ''));
};

export const openLinkedInSearch = (name) => {
  const nameValue = name || '';
  openNewTab(Urls.inMailBase.concat(nameValue));
};
