import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BackgroundColors } from 'libs/constantsStyles';
import SalesforceSyncCard from 'web/settings/adminSettings/unsubscribes/components/salesforceSyncCard/salesforceSyncCard';
import MarketoCheckCard from 'web/settings/adminSettings/unsubscribes/components/marketoCheckCard/marketoCheckCard';
import {
  saveMarketoCheck,
  getAdminSettings,
} from 'web/settings/adminSettings/unsubscribes/actionCreators/adminUnsubscribesIntegrationsActionCreators';
import './adminUnsubscribesIntegrationsContainer.scss';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';
import Cards, { Direction } from 'components/layouts/cards';

class AdminUnsubscribesIntegrationsContainer extends Component {
  componentDidMount() {
    const { getAdminSettingsCall, user } = this.props;

    getAdminSettingsCall();
  }

  getCards = () => {
    const {
      enableUnsubscribeSync,
      salesforceSyncField,
      user,
      marketoCheckOnSave,
      marketoCheckState,
    } = this.props;

    const cards = [
      {
        children: (
          <SalesforceSyncCard
            enableUnsubscribeSync={enableUnsubscribeSync}
            salesforceSyncField={salesforceSyncField}
          />
        ),
        id: 'salesforce-sync',
      },
    ];

    const marketoCard = {
      children: (
        <MarketoCheckCard
          onSave={marketoCheckOnSave}
          marketoCheckState={marketoCheckState}
        />
      ),
      id: 'marketo-check',
      className: 'marketo-unsubscribes-check-card',
    };

    cards.unshift(marketoCard);

    return cards;
  };

  render() {
    const { unsubscribesIntegrationLoading } = this.props;

    return (
      <Fragment>
        {unsubscribesIntegrationLoading ? (
          <LoadingSpinner imageUrl={getImageUrl} />
        ) : null}
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          className="admin-unsubscribes-cards-container"
          cards={this.getCards()}
          direction={Direction.vertical}
          marginAround={0}
          marginBetween={0}
        />
      </Fragment>
    );
  }
}

AdminUnsubscribesIntegrationsContainer.propTypes = {
  enableUnsubscribeSync: PropTypes.bool.isRequired,
  salesforceSyncField: PropTypes.string.isRequired,
  getAdminSettingsCall: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  unsubscribesIntegrationLoading: PropTypes.bool.isRequired,
  marketoCheckOnSave: PropTypes.func.isRequired,
  marketoCheckState: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  enableUnsubscribeSync: state.settingsUnsubscribes.enable_unsubscribe_sync,
  marketoCheckState: state.marketoCheckState,
  salesforceSyncField: state.settingsUnsubscribes.salesforce_sync_field,
  unsubscribesIntegrationLoading: state.unsubscribesIntegrationLoading,
  user: state.user,
});

const mapDispatchToProps = {
  marketoCheckOnSave: saveMarketoCheck,
  getAdminSettingsCall: getAdminSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUnsubscribesIntegrationsContainer);
