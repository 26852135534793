/**
 *
 * ColumnFilter
 *
 */

import React, { PureComponent } from 'react';
import sortBy from 'lodash/sortBy';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import Checkbox from 'components/buttons/checkbox';
import InfoBox from 'components/infoBox';
import Search from 'components/inputs/search';
import Switch from 'components/buttons/switch';
import RadioGroup from 'components/buttons/radioGroup';
import { Pagination } from 'table/modules/constants';
import SettingsIcon from 'components/icons/settingsIcon';
import './columnFilter.scss';

class ColumnFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search: '',
    };
    this.triggerRef = null;
  }

  render() {
    const {
      alignRight,
      intl: { formatMessage },
      columnFilterPanel,
    } = this.props;
    const { open, search } = this.state;

    return (
      <div className="tout-column-filter">
        <div
          className={classNames('tout-column-filter-trigger', { open })}
          onClick={this._toggleOpen}
          ref={this._setTriggerRef}
        >
          <SettingsIcon height={24} open={open} width={24} />
        </div>
        {open ? (
          <InfoBox
            align="right"
            className={classNames('tout-column-filter-info-box', {
              'tout-column-filter-info-box-right': !alignRight,
            })}
            outsideClickHandler={this.outsideClickHandler}
            triggerRef={this.triggerRef}
          >
            <div className="tout-column-filter-children">
              {this._getRows()}
              {columnFilterPanel}
              <div className="margin-bottom-half">
                <FormattedMessage id="toutTable.columnFilter.columns" />
              </div>
              <div className="margin-bottom-10">
                <Search
                  onChange={(search) => this.setState({ search })}
                  placeholder={formatMessage({
                    id: 'toutTable.columnFilter.searchColumns',
                  })}
                  value={search}
                  autoFocus
                  clearSearch
                />
              </div>
              {this._getSelectAllSwitch()}
              {this._getColumns()}
            </div>
          </InfoBox>
        ) : null}
      </div>
    );
  }

  _setTriggerRef = (div) => {
    this.triggerRef = div;
  };

  _toggleOpen = () => {
    this.setState((state) => ({ open: !state.open, search: '' }));
  };

  outsideClickHandler = () => {
    this._toggleOpen();
  };

  _getColumns = () => {
    const { checked, onChange } = this.props;
    return this.columnsAfterSearchFilter.reduce(
      (accumulator, { id, name, filterTooltip }) => {
        if (name) {
          accumulator.push(
            <div
              className="tout-column-filter-block"
              key={`column-filter-${id}`}
            >
              <Checkbox
                checked={checked[id]}
                className="tout-column-filter-checkbox margin-bottom-quarter"
                label={name}
                name={id}
                onChange={onChange}
              />
              {filterTooltip && (
                <HoverTooltip
                  className="margin-left-quarter"
                  place={HoverTooltipPlace.right}
                  textId={filterTooltip}
                  tooltipId={`row-tooltip-${id}`}
                >
                  <i className="tout-icon-warning" />
                </HoverTooltip>
              )}
            </div>
          );
        }
        return accumulator;
      },
      []
    );
  };

  _getRows = () => {
    const {
      allItems,
      changeRowsPerPage,
      paginationPerPage,
      pagination: { perPageClick: webPerPageHandler },
    } = this.props;

    if (!webPerPageHandler && !allItems) return null;

    const items = Pagination.perPageValues.map((value) => ({
      label: value,
      value,
    }));
    return (
      <>
        <div className="margin-bottom-half">
          <FormattedMessage id="toutTable.columnFilter.rows" />
        </div>
        <div className="margin-bottom-most">
          <RadioGroup
            className="column-filter-rows-selection"
            items={items}
            onChange={changeRowsPerPage}
            selected={paginationPerPage}
          />
        </div>
      </>
    );
  };

  get columnsAfterSearchFilter() {
    const { search } = this.state;
    const { columns } = this.props;
    const resultColumns = columns.filter(
      ({ name }) =>
        name &&
        (!search || name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    );
    return sortBy(resultColumns, (column) => column.name);
  }

  _getSelectAllSwitch = () => {
    const {
      checked,
      selectOrDeselectAll,
      intl: { formatMessage },
    } = this.props;
    const { columnsAfterSearchFilter } = this;
    const isSelected = (isAll = true) =>
      !!columnsAfterSearchFilter.length &&
      !columnsAfterSearchFilter.filter(
        (column) =>
          column.name && (isAll ? !checked[column.id] : checked[column.id])
      ).length;
    const isAllSelected = isSelected(true);

    return (
      <Switch
        checked={isAllSelected}
        className="select-all-switch"
        label={formatMessage({ id: 'common.selectAll' })}
        name="select_all_columns"
        onChange={() =>
          selectOrDeselectAll(columnsAfterSearchFilter, !isAllSelected)
        }
      />
    );
  };
}

ColumnFilter.propTypes = {
  alignRight: PropTypes.bool,
  allItems: PropTypes.bool.isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  checked: PropTypes.objectOf(PropTypes.bool),
  columnFilterPanel: PropTypes.node,
  columns: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  paginationPerPage: PropTypes.number.isRequired,
  selectOrDeselectAll: PropTypes.func.isRequired,
};

ColumnFilter.defaultProps = {
  alignRight: false,
  checked: {},
  columnFilterPanel: undefined,
};

export default injectIntl(ColumnFilter);
