export default {
  setWithdrawInviteLoading: 'INVITEES_SET_WITHDRAW_INVITE_LOADING',
  setWithdrawInvitesLoading: 'INVITEES_SET_WITHDRAW_INVITES_LOADING',
  setResendInviteLoading: 'INVITEES_SET_RESEND_INVITE_LOADING',
  setResendInvitesLoading: 'INVITEES_SET_RESEND_INVITES_LOADING',
  setInviteUsersLoading: 'INVITEES_SET_INVITE_USERS_LOADING',
  setMarketoWorkspacesLoading: 'INVITEES_SET_WORKSPACES_LOADING',
  withdrawInvite: 'INVITEES_WITHDRAW_INVITE',
  withdrawInvites: 'INVITEES_WITHDRAW_INVITES',
  resendInvite: 'INVITEES_RESEND_INVITE',
  resendInvites: 'INVITEES_RESEND_INVITES',
  inviteUsers: 'INVITEES_INVITE_USERS',
};
