import sanitizeHtml from 'sanitize-html';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import { CampaignDetailsEnum } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { Urls, Regex } from 'web/libs/constants';
import {
  isSharedCampaign,
  isUnsharedCampaignForAdmin,
} from 'web/campaigns/campaignsTab/helpers/campaignsHelpers';

export function campaignsSearchParser(type, collection) {
  const campaignCategoryCollection = { type, collection: [] };
  campaignCategoryCollection.collection = map(
    collection,
    ({ id, name = '' }) => ({ id, name })
  );

  return campaignCategoryCollection;
}

export function detailListParser(listData, user, tab, indexedTasksDue) {
  const selectedTab = CampaignDetailsEnum[tab].value;

  // to do - intl
  const data = listData.map((item) => {
    let activeCount = 0;

    if (
      item.currently_active_count &&
      typeof item.currently_active_count === 'object'
    ) {
      activeCount = item.currently_active_count[user.id] || 0;
    }
    const tasksDue = indexedTasksDue[item.id] || 0;
    const aggregateMetrics = item.aggregate_metrics || {};
    const {
      emails_bounced: emailsBounced,
      emails_clicked: emailsClicked,
      emails_replied: emailsReplied,
      emails_sent: emailsSent,
      emails_viewed: emailsViewed,
    } = aggregateMetrics;
    const connectionRate = aggregateMetrics.num_calls
      ? (
          (aggregateMetrics.num_calls_connected / aggregateMetrics.num_calls) *
          100
        ).toFixed()
      : 0;
    const obj = {
      id: item.id,
      activeCount,
      name: item.name,
      state: item.state,
      userId: item.user_id,
      createdAt: item.created_at,
      shared: isSharedCampaign(item),
      unshared: isUnsharedCampaignForAdmin(item, user),
      user: item.user,
      metrics: {
        user: [
          {
            label: 'web.campaigns.metrics.tasksDue',
            value: tasksDue,
            percent: false,
          },
          {
            label: 'web.campaigns.metrics.activePeople',
            value: activeCount,
            percent: false,
          },
        ],
        team: [
          {
            label: 'common.engagement.emailsSent',
            value: aggregateMetrics.emails_sent,
            percent: false, //true
          },
          {
            value: {
              emailsBounced,
              emailsClicked,
              emailsReplied,
              emailsSent,
              emailsViewed,
            },
            percent: true,
          },
          {
            label: 'common.engagement.callsCompleted',
            value: aggregateMetrics.num_calls,
            percent: false,
          },
          {
            label: 'common.engagement.connectionRate',
            value: connectionRate,
            percent: true,
          },
        ],
      },
      link: Urls.campaignDetailsTab
        .replace(':categoryId', item.category_id)
        .replace(':campaignId', item.id)
        .replace(':tab', selectedTab),
    };
    return obj;
  });
  return data;
}

function parseList(list) {
  if (list.length === 0) {
    return;
  }

  for (let i = 0; i < list.length; i++) {
    const ordered = list[i].tagName.toLowerCase() === 'ol';
    const listItems = list[i].children;
    for (let j = 0; j < listItems.length; j++) {
      parseList(listItems[j].querySelectorAll('ol'));
      parseList(listItems[j].querySelectorAll('ul'));
      const prepend = ordered ? `${j + 1}. ` : '-';
      listItems[j].textContent = `\n${prepend}${listItems[j].textContent}`;
    }
  }
}

function parseLists(html) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = sanitizeHtml(html);
  parseList(tmp.querySelectorAll('ol'));
  parseList(tmp.querySelectorAll('ul'));
  return tmp.innerHTML;
}

export function parseTask(task) {
  return parseLists(task)
    .replace(/<\/(div|ol|ul)>/g, '\n')
    .replace(/<[^>]*>/g, '')
    .replace(/\u00a0/g, ' ');
}

export function parseTeamUsingCampaign(
  activePeople = [],
  user,
  userSubscriptionUsers
) {
  const activePeopleUserIds = activePeople.map((person) => person.user_id);
  const userActives = activePeopleUserIds.filter((id) => id === user.id).length;
  let teamMembers = uniq(activePeopleUserIds);
  const currentUserIndex = teamMembers.findIndex((value) => value === user.id);
  if (currentUserIndex > -1) {
    teamMembers = [
      ...teamMembers.slice(0, currentUserIndex),
      ...teamMembers.slice(currentUserIndex + 1),
    ];
  }

  teamMembers = sortBy(
    teamMembers.map((id) => userSubscriptionUsers[id].name),
    (member) => member.toLowerCase()
  );

  return { userActives, teamMembers };
}

export function parseDynamicFieldsValidation(body = '', subject = '') {
  return {
    bodyError: !!body
      .replace(Regex.dynamicFieldsPrompt, '')
      .replace(Regex.dynamicField, '')
      .replace(Regex.dynamicFieldWithDefaultValue)
      .match(Regex.dynamicFieldsBracketsLeftover),
    subjectError: !!subject
      .replace(Regex.dynamicFieldsPrompt, '')
      .replace(Regex.dynamicField, '')
      .replace(Regex.dynamicFieldWithDefaultValue)
      .match(Regex.dynamicFieldsBracketsLeftover),
  };
}

function validEmailAddress(address = '') {
  return Array.isArray(address.match(Regex.email));
}

export function parseInvalidEmails(emails = '') {
  return emails.split(',').reduce((accumulator, current) => {
    const email = current.trim();
    if (email && !validEmailAddress(email)) {
      accumulator.push(email);
    }
    return accumulator;
  }, []);
}
