import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl, ModalPopupIds } from 'web/libs/constants';
import { PopupSize, ProgressStatus } from 'libs/constantsShared';
import { AlertIds } from 'web/modals/imageInsert/libs/imageInsertConstants';
import Popup, { PopupBackgroundColors } from 'components/popups';
import ImageInsert from 'components/imageInsert';
import {
  clearPopupAlert,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  insert,
  onChange,
  update,
  uploadFiles,
} from 'web/modals/imageInsert/actionCreators/imageInsertActionCreators';
import './imageInsertContainer.scss';

class ImageInsertContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      clearAlert: () => dispatch(clearPopupAlert()),
      closePopup: () => dispatch(closePopup()),
      insert: () => dispatch(insert()),
      onChange: (value) => dispatch(onChange(value)),
      update: () => dispatch(update()),
      uploadFiles: (files) => dispatch(uploadFiles(files)),
    };
  }

  render() {
    const { alert, editState, file, popup } = this.props;

    return (
      (popup === ModalPopupIds.imageInsert && (
        <Popup
          alert={this._getAlert(alert)}
          getImageUrl={getImageUrl}
          loading={false}
          {...this._getGlobalPopupObject()}
        >
          <ImageInsert
            editState={editState}
            file={file}
            onChange={this.actionCreators.onChange}
            uploadFiles={this.actionCreators.uploadFiles}
          />
        </Popup>
      )) ||
      null
    );
  }

  _getGlobalPopupObject = () => {
    const { file, editState } = this.props;

    if (editState) {
      return {
        footer: {
          onPrimary: this.actionCreators.update,
          onSecondary: this.actionCreators.closePopup,
          primaryTextId: 'common.update',
          secondaryTextId: 'common.cancel',
        },
        header: {
          onClose: this.actionCreators.closePopup,
          textId: 'web.imageInsert.titleUpdate',
        },
        onOutsideClick: this.actionCreators.closePopup,
        size: PopupSize.small,
      };
    } else {
      return {
        footer: {
          onPrimary:
            file && file.status === ProgressStatus.finished
              ? this.actionCreators.insert
              : null,
          onSecondary: this.actionCreators.closePopup,
          primaryTextId: 'common.insert',
          secondaryTextId: 'common.cancel',
        },
        header: {
          onClose: this.actionCreators.closePopup,
          textId: 'web.imageInsert.title',
        },
        onOutsideClick: file ? null : this.actionCreators.closePopup, // disable during upload
        size: PopupSize.small,
      };
    }
  };

  _getAlert = () => {
    const { alert } = this.props;
    const defaultAlert = {
      color: PopupBackgroundColors.red,
      onClose: this.actionCreators.clearAlert,
      textId: 'web.imageInsert.alert.issue',
    };

    switch (alert) {
      case AlertIds.issue:
        return {
          ...defaultAlert,
          textId: 'web.imageInsert.alert.issue',
        };
      case AlertIds.fileTypeInvalid:
        return {
          ...defaultAlert,
          textId: 'web.imageInsert.alert.fileTypeInvalid',
        };
      case AlertIds.fileTooLarge:
        return {
          ...defaultAlert,
          textId: 'web.imageInsert.alert.fileTooLarge',
        };
      default:
        return null;
    }
  };
}

ImageInsertContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  editState: PropTypes.object,
  file: PropTypes.object,
  intl: intlShape.isRequired,
  popup: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    alert: state.popupAlert,
    editState: state.imageInsertEditState,
    file: state.imageInsertFile,
    popup: state.popup,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(ImageInsertContainer);
