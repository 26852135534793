import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import { getImageUrl } from 'web/libs/constants';
import LogoAdobe from 'components/logoAdobe/logoAdobe';
import './onboardingPage.scss';

const onboardingPage = (
  WrappedComponent,
  onboardingPageParams = { shiftedBackground: false, titleKey: '' }
) => {
  const { shiftedBackground, titleKey } = onboardingPageParams;
  const componentName = WrappedComponent.displayName || WrappedComponent.name;
  class OnboardingPage extends Component {
    static displayName = `OnboardingPage${componentName}`;

    render() {
      return (
        <Page
          className={classNames('onboarding-page', {
            'shifted-background': shiftedBackground,
          })}
        >
          <PageView className="onboarding-page-view">
            <LogoAdobe className="logo-adobe" displayText />
            {shiftedBackground ? (
              <div className="onboarding-page-left">
                {titleKey && (
                  <h1 className="onboarding-page-title">
                    <FormattedHTMLMessage id={titleKey} />
                  </h1>
                )}
                {this.getBackgroundElement()}
              </div>
            ) : (
              this.getBackgroundElement()
            )}
            <div className="onboarding-content">
              <WrappedComponent {...this.props} />
            </div>
          </PageView>
        </Page>
      );
    }

    getBackgroundElement = () => {
      return (
        <div
          className="onboarding-background-image"
          style={{
            backgroundImage: `url(${getImageUrl(
              'unauthorized-page-background-dark',
              'png'
            )})`,
          }}
        />
      );
    };
  }

  return OnboardingPage;
};

export default onboardingPage;
