import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isPristine, submit } from 'redux-form';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { TEAM_CREATE_FORM_ID } from '../libs/teamsConstants';
import { createTeam, deleteTeam } from '../actionCreators/teamsActionCreators';
import {
  getCreateTeamLoading,
  getDeleteTeamLoading,
} from '../selectors/teamsSelector';
import TeamsPopup from '../components/popups/teamsPopup';

class TeamsPopupContainer extends Component {
  render() {
    return <TeamsPopup {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  popup: state.popup,
  popupData: state.popupData,
  createTeamLoading: getCreateTeamLoading(state),
  isPristineCreateTeamForm: isPristine(TEAM_CREATE_FORM_ID)(state),
  deleteTeamLoading: getDeleteTeamLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        closePopup,
        submitForm: submit,
        createTeam,
        deleteTeam,
      },
      dispatch
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamsPopupContainer);
