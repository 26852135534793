import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  wrappedUpdateIdentity as updateIdentity,
  wrappedUpdateSubject as updateSubject,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import SubjectField from './subjectField/subjectField';
import FromDropDown from './fromDropDown/fromDropDown';
import CcBccDisplay from './ccBcc/ccBccDisplay';
import ToField from './toField';
import CcField from './ccBcc/ccField';
import BccField from './ccBcc/bccField';
import {
  getDefaultIdentity,
  getIdentities,
  getValidationFailures,
} from 'web/composeWindow/selectors/composeWindowSelectors';
import './addressBar.scss';

class AddressBar extends React.Component {
  componentDidMount() {
    window.addEventListener('mousedown', this._outsideClickHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this._outsideClickHandler, true);
  }

  setNodeRef = (node) => (this.node = node);

  setButtonRef = (button) => (this.ccBccButton = button);

  _outsideClickHandler = (e) => {
    if (!this.node.contains(e.target)) {
      this.props.minimizeCcBcc();
    }
  };

  renderSlideDown() {
    const { isCcShowing, isBccShowing, disabled } = this.props;

    return isCcShowing || isBccShowing ? (
      <React.Fragment>
        {isCcShowing && <CcField disabled={disabled} />}
        {isBccShowing && <BccField disabled={disabled} />}
      </React.Fragment>
    ) : null;
  }

  render() {
    const {
      updateIdentity,
      identities,
      identity,
      defaultIdentity,
      disabled,
      hideGroupResults,
      updateSubject,
      subject,
      bccCount,
      ccCount,
      isCcShowing,
      isBccShowing,
      setCcShowing,
      setBccShowing,
      subjectErrored,
    } = this.props;

    return (
      <div className="compose-address-bar" ref={this.setNodeRef}>
        <FromDropDown
          defaultIdentity={defaultIdentity}
          disabled={disabled}
          identities={identities}
          identity={identity}
          updateIdentity={updateIdentity}
        />
        <div className="to-cc-bcc-display-container">
          <div className="to-field">
            <ToField disabled={disabled} hideGroupResults={hideGroupResults} />
          </div>
          <div className="cc-bcc-display">
            <CcBccDisplay
              bccCount={bccCount}
              ccCount={ccCount}
              disabled={disabled}
              isBccShowing={isBccShowing}
              isCcShowing={isCcShowing}
              ref={this.setButtonRef}
              setBccShowing={setBccShowing}
              setCcShowing={setCcShowing}
            />
          </div>
        </div>
        {this.renderSlideDown()}
        <SubjectField
          disabled={disabled}
          errored={subjectErrored}
          subject={subject}
          updateSubject={updateSubject}
        />
      </div>
    );
  }
}

AddressBar.propTypes = {
  bccCount: PropTypes.number.isRequired,
  ccCount: PropTypes.number.isRequired,
  defaultIdentity: PropTypes.object,
  disabled: PropTypes.bool,
  hideGroupResults: PropTypes.bool.isRequired,
  identities: PropTypes.array.isRequired,
  identity: PropTypes.object.isRequired,
  isBccShowing: PropTypes.bool.isRequired,
  isCcShowing: PropTypes.bool.isRequired,
  minimizeCcBcc: PropTypes.func.isRequired,
  setBccShowing: PropTypes.func.isRequired,
  setCcShowing: PropTypes.func.isRequired,
  subject: PropTypes.string,
  subjectErrored: PropTypes.bool.isRequired,
  updateIdentity: PropTypes.func.isRequired,
  updateSubject: PropTypes.func.isRequired,
};

AddressBar.defaultProps = {
  defaultIdentity: {},
  disabled: false,
  subject: '',
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        updateIdentity,
        updateSubject,
      },
      dispatch
    ),
  };
}

const mapStateToProps = (state) => ({
  bccCount: state.composeAddresses.bcc.length,
  ccCount: state.composeAddresses.cc.length,
  defaultIdentity: getDefaultIdentity(state),
  identities: getIdentities(state),
  identity: state.composeFrom.identity,
  subject: state.composeSubject.subject,
  subjectErrored: !!getValidationFailures(state).subject.length,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressBar);
