import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import { intlShape, injectIntl } from 'react-intl';
import { PopupSize } from 'libs/constantsShared';
import Table from 'table/modules/table';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import { InviteesResultMessage } from '../../../libs/inviteesConstants';
import get from 'lodash/get';
import './inviteUsersResultPopup.scss';

class InviteUsersResult extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      intl: { formatMessage },
    } = this.props;
    const inviteUsersResult = this.getFormattedUsersResult();
    const successCount = inviteUsersResult.filter(({ success }) => success)
      .length;
    const invitesCount = inviteUsersResult.length;
    return (
      <Popup
        className="invite-users-result-popup"
        footer={{
          onPrimary: closePopup,
          primaryTextId: 'common.ok',
        }}
        header={{
          onClose: closePopup,
          textId:
            invitesCount === successCount
              ? 'web.settings.adminSettings.userManagement.invitees.inviteUsers.result.title.full'
              : 'web.settings.adminSettings.userManagement.invitees.inviteUsers.result.title.partial',
          textValues: { successCount, invitesCount },
        }}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <Table
          maxHeight={300}
          items={inviteUsersResult}
          columns={[
            {
              id: 'email',
              name: formatMessage({ id: 'common.email' }),
              rowCell: {
                component: TextCustomCell,
                metadata: {
                  formatText: ({ user: { email } }) => email,
                },
              },
            },
            {
              id: 'status',
              name: formatMessage({ id: 'common.status' }),
              rowCell: {
                component: TextCustomCell,
                metadata: {
                  formatText: this.formatStatusMessage,
                },
              },
            },
          ]}
        />
      </Popup>
    );
  }

  getFormattedUsersResult = () => {
    const {
      popupData: { result = [] },
    } = this.props;
    return result.map((item) => ({
      ...item,
      id: get(item, 'user.email'),
    }));
  };

  formatStatusMessage = ({ success, msg }) => {
    const messageId = InviteesResultMessage[msg];
    const {
      intl: { formatMessage },
    } = this.props;
    const formattedMessage = messageId ? formatMessage({ id: messageId }) : msg;
    return success ? (
      <span className="tout-icon-tick">{formattedMessage}</span>
    ) : (
      <span className="tout-icon-close">{formattedMessage}</span>
    );
  };
}
InviteUsersResult.propTypes = {
  intl: intlShape.isRequired,
  actionCreators: PropTypes.object.isRequired,
  popupData: PropTypes.object.isRequired,
};
export default injectIntl(InviteUsersResult);
