import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ReferenceDateSmartFilter from 'web/commandCenter/modules/whenSmartFilter/components/referenceDateSmartFilter';
import './whenSmartFilterContainer.scss';
import {
  ADVANCED_SEARCH_FILTERS_COLLECTION,
  FILTER_SEARCH_STATUS_ID,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { getSelectedReferenceDate } from 'web/commandCenter/modules/whenSmartFilter/selectors/referenceDateSmartFilterSelectors';
import { setReferenceDate } from 'web/commandCenter/modules/whenSmartFilter/actionCreators/referenceDateSmartFilterActiionCreators';
import { getAdvancedSearchStatus } from 'web/commandCenter/modules/statusSmartFilter/selectors/statusSmartFilterSelectors';
import { REFERENCE_DATES } from 'web/commandCenter/modules/whenSmartFilter/libs/referenceDateSmartFilterConstants';
import { updateReferenceDateValues } from 'web/commandCenter/modules/whenSmartFilter/helpers/whenSmartFilterHelpers';

const WhenSmartFilterContainer = ({
  searchStatus: { subTabIds, tabId },
  selectedReferenceDate,
  setReferenceDate,
}) => {
  const [referenceDates, setReferenceDates] = useState(
    Object.values(REFERENCE_DATES).map((referenceDate) => ({
      ...referenceDate,
      disabled: false,
      disabledStyle: false,
    }))
  );

  useEffect(
    () => {
      const options = { subTabIds, tabId };
      const actions = { setReferenceDates };
      updateReferenceDateValues(options, actions);
    },
    [tabId, subTabIds]
  );
  return (
    <div className="when-smart-filter-container">
      <h3>
        <FormattedMessage id="common.when" />
      </h3>
      <ReferenceDateSmartFilter
        items={referenceDates}
        onChange={setReferenceDate}
        selected={selectedReferenceDate}
      />
    </div>
  );
};

WhenSmartFilterContainer.propTypes = {
  searchStatus: PropTypes.object.isRequired,
  selectedReferenceDate: PropTypes.string.isRequired,
  setReferenceDate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setReferenceDate,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  searchStatus: getAdvancedSearchStatus(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
    filterId: FILTER_SEARCH_STATUS_ID,
  }),
  selectedReferenceDate: getSelectedReferenceDate(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhenSmartFilterContainer);
