import { createSelector } from 'reselect';

export const getFetched = (state) => state.entities.invitations.fetched;
export const getFetching = (state) => state.entities.invitations.fetching;
export const getAllIds = (state) => state.entities.invitations.allIds;
export const getById = (state) => state.entities.invitations.byId;
export const getResendInvitationLoading = (state) =>
  state.entities.invitations.resendInvitationLoading;

export const getResendInvitationResult = (state) =>
  state.entities.invitations.resendInvitationResult;

export const getInvitations = createSelector(
  [getAllIds, getById],
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const shouldInvalidate = createSelector(
  [getFetched, getFetching],
  (fetched, fetching) => !fetched && !fetching
);
