import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import CustomFieldsEditingRow from './customFieldsEditingRow';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import './customFieldsCardEditing.scss';

class CustomFieldsCardEditing extends Component {
  render() {
    const {
      addRow,
      editState,
      removeRow,
      updateEditState,
      validateCustomField,
    } = this.props;

    return (
      <div className="person-details-custom-fields-editing">
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.person.personDetails.customFields.title" />
        </h3>
        <div className="">
          {editState.map((customField, index) => (
            <CustomFieldsEditingRow
              addRow={addRow}
              index={index}
              key={`custom-field-editing-row-${customField.id}`}
              last={index === editState.length - 1}
              removeRow={removeRow}
              updateEditState={updateEditState}
              validateCustomField={validateCustomField}
              {...customField}
            />
          ))}
        </div>
        {this.renderButtonsBlock()}
      </div>
    );
  }

  save = () => this.props.save(PersonDetailsCards.customFields);

  cancel = () => this.props.cancel(PersonDetailsCards.customFields);

  renderButtonsBlock = () => (
    <div className="custom-fields-editing-button-row margin-top-most">
      <Button
        color={ButtonColors.gray}
        onClick={this.cancel}
        size={ButtonSizes.standard}
      >
        <FormattedMessage id="common.cancel" />
      </Button>
      {!this.props.hiddenButtons && (
        <Button
          className="margin-left-full"
          color={ButtonColors.blue}
          disabled={!this.props.hasChanges}
          onClick={this.save}
          size={ButtonSizes.standard}
        >
          <FormattedMessage id="common.save" />
        </Button>
      )}
    </div>
  );
}

CustomFieldsCardEditing.propTypes = {
  addRow: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  hiddenButtons: PropTypes.bool.isRequired,
  editState: PropTypes.array.isRequired,
  removeRow: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  updateEditState: PropTypes.func.isRequired,
  validateCustomField: PropTypes.func.isRequired,
};

CustomFieldsCardEditing.defaultProps = {};

export default CustomFieldsCardEditing;
