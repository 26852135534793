/*
 *  Notes:
 *    Uses ReactDOM to get height as inherit doesn't work for height and
 *      setting large max-height causes inconsitent animation transition.
 *    Lower performance than Collapse when lots of AnimateHeights if using
 *.     0/all since it renders child components initially instead of on mounting
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './animateHeight.scss';

export const ScrollToNode = {
  none: 'NONE',
  parent: 'PARENT',
  self: 'SELF',
};

class AnimateHeight extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = null;
    this.nodeHeight = 0;
  }

  componentWillUpdate({ all }) {
    const childHeight =
      (this.wrapperRef.children &&
        this.wrapperRef.children.length &&
        this.wrapperRef.children[0].clientHeight) ||
      0;

    if (childHeight !== this.nodeHeight) {
      this.nodeHeight = childHeight;
    }

    if (all && !this.props.all) {
      this._scrollToNode();
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div
        className="animate-height"
        style={this._getStyles()}
        ref={this._setRef}
      >
        {children}
      </div>
    );
  }

  _setRef = (node) => {
    this.wrapperRef = node;
  };

  _getStyles = () => {
    const { all, height, maxHeight, transitionDuration } = this.props;
    const styles = {
      transition: `max-height ${transitionDuration}ms ease-in-out`,
    };

    if (maxHeight) {
      styles.maxHeight = `${maxHeight}px`;
      styles.overflow = 'auto';
    } else if (all) {
      styles.maxHeight = `${this.nodeHeight}px`;
    } else {
      styles.maxHeight = `${height}px`;
    }

    return styles;
  };

  _scrollToNode = () => {
    const { scrollOnOpenNode } = this.props;
    let node;

    if (scrollOnOpenNode === ScrollToNode.parent) {
      node = this.wrapperRef.parentNode;
    } else if (scrollOnOpenNode === ScrollToNode.self) {
      node = this.wrapperRef;
    }

    if (node) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };
}

AnimateHeight.propTypes = {
  all: PropTypes.bool,
  children: PropTypes.node,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  scrollOnOpenNode: PropTypes.oneOf([...Object.values(ScrollToNode)]),
  transitionDuration: PropTypes.number,
};

AnimateHeight.defaultProps = {
  all: false,
  children: null,
  height: 200,
  maxHeight: 0,
  scrollOnOpenNode: ScrollToNode.none,
  transitionDuration: 250,
};

export default AnimateHeight;
