import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import I18N from 'languages';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import './circleEvent.scss';

const CircleEvent = (props) => {
  const {
    eventCount,
    iconClass,
    intl: { formatMessage },
    lastEventDate,
    lastEventTooltipTextId,
    tooltipId,
  } = props;

  const getCircleElement = (iconClass = '', eventCount = 0) => (
    <div
      className={classNames('circle-event', iconClass, {
        disabled: eventCount === 0,
      })}
    >
      {eventCount > 1 && <div className="action-count">{eventCount}</div>}
    </div>
  );

  if (eventCount && lastEventDate && tooltipId) {
    const tooltip = `${formatMessage({
      id: lastEventTooltipTextId,
    })} ${I18N.getLocalizedDate(
      lastEventDate,
      I18N.DateFormats.DATETIME_LONG
    )}`;

    return (
      <HoverTooltip
        className="tracking-events-tooltip"
        place={HoverTooltipPlace.right}
        textValue={tooltip}
        tooltipId={`email-tracking-events-table-tooltip-${tooltipId}-${iconClass}`}
      >
        {getCircleElement(iconClass, eventCount)}
      </HoverTooltip>
    );
  }

  return getCircleElement(iconClass, eventCount);
};

CircleEvent.propTypes = {
  eventCount: PropTypes.number.isRequired,
  iconClass: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  lastEventDate: PropTypes.object,
  lastEventTooltipTextId: PropTypes.string,
  tooltipId: PropTypes.string,
};

CircleEvent.defaultProps = {
  lastEventDate: null,
  lastEventTooltipTextId: '',
  tooltipId: '',
};

export default injectIntl(CircleEvent);
