import { getUser } from 'web/user/actionCreators/userActionCreators';
import {
  updateAutoAppendContent as updateAutoAppendContentCall,
  deleteAutoAppendContent as deleteAutoAppendContentCall,
} from '../services/autoAppendService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import AutoAppendActionTypes from '../libs/autoAppendActionTypes';
import {
  GeneralPageAlertIds,
  AUTO_APPEND_FIELD_ID,
  AUTO_APPEND_POPUP_ID,
} from '../libs/generalPageConstants';
import {
  openPopup,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';

export const setFetching = (fetching) => ({
  type: AutoAppendActionTypes.setFetching,
  fetching,
});

export const updateCustomAppendContent = (data) => (dispatch) => {
  dispatch(closePopup());
  dispatch(setFetching(true));
  const content = data[AUTO_APPEND_FIELD_ID];
  const apiCall = content
    ? updateAutoAppendContentCall
    : deleteAutoAppendContentCall;
  return apiCall(data)
    .then(async () => {
      await dispatch(getUser());
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesSaved));
    })
    .catch(() => {
      dispatch(openViewAlert(GeneralPageAlertIds.formChangesNotSaved));
    })
    .finally(() => {
      dispatch(setFetching(false));
    });
};

export const openAutoAppendConfirmationPopup = (data) => (dispatch) => {
  dispatch(openPopup(AUTO_APPEND_POPUP_ID, data, {}));
};
