/**
 *
 * Table
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import isEqual from 'lodash/isEqual';
import createDetectElementResize from 'table/modules/vendor/javascript-detect-element-resize';
import Shapes from 'table/modules/shapes';
import { SelectableWidth } from 'table/modules/constants';
import HeaderRow from 'table/modules/headerRow';
import Row, { DraggableRow } from 'table/modules/row';
import ZeroState from 'table/modules/zeroState';
import LoadingSpinner from 'components/loadingSpinner';
import 'table/modules/styles/styles.scss';
import './table.scss';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResizingTable: false,
      isStopping: false,
      resizingElement: null,
      width: 0,
    };

    this.resizeTableLine = React.createRef();

    this._detectElementResize = null;
    this.containerRef = null;
    this.memoizeColumns = null;
    this.memoizeColumnsWidth = 0;
    this.leftPanelWidth = 0;
    this.resizeTable = null;
    this.resizeTableBody = null;
    this.resizeElementsTable = null;
  }

  componentDidMount() {
    this._detectElementResize = createDetectElementResize();
    this._detectElementResize.addResizeListener(
      this.containerRef,
      this._onResize
    );

    this._onResize();

    this.resizeTable = document.querySelector('.tout-header-resize-row');
    this.resizeTableBody = document.querySelector('.table-body');
    this.resizeElementsTable = document.querySelectorAll(
      '.tout-header-resize-cell'
    );

    const leftPanelElement = document.querySelector('.tout-ui-sidebar');
    const settingsSidebarElement = document.querySelector('.settings-sidebar');
    const leftPanelWidth = 230;
    const leftFilterWidth = 290;

    this.leftPanelWidth =
      settingsSidebarElement && leftPanelElement
        ? leftPanelWidth + leftFilterWidth
        : leftPanelElement
          ? leftPanelWidth
          : leftFilterWidth;
  }

  componentWillUnmount() {
    if (this._detectElementResize) {
      this._detectElementResize.removeResizeListener(
        this.containerRef,
        this._onResize
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.columns.length !== prevProps.columns.length ||
      this.columnsWidthWasChanged(prevProps)
    ) {
      this._onResize();
    }

    for (const elem of this.resizeElementsTable) {
      this.hideSortIcons(elem);
      this.changeIconPosition(elem);
    }
  }

  columnsWidthWasChanged = (prevProps) => {
    const newColumnsWidth = this.props.columns.map((x) => x.width);
    const prevColumnsWidth = prevProps.columns.map((x) => x.width);
    return !isEqual(newColumnsWidth, prevColumnsWidth);
  };

  changeIconPosition = (elem) => {
    const sortChevronElement = elem.querySelector('.sort-chevron');

    if (sortChevronElement) {
      const iconContainerElement = elem.querySelector('.sorting-container');
      const iconContainerWidth = iconContainerElement.offsetWidth;
      const sortUpElement = elem.querySelector('.sort-chevron-up');
      const sortDownElement = elem.querySelector('.sort-chevron-down');

      sortChevronElement.classList.add('resize');
      sortUpElement.classList.add('resize');
      sortDownElement.classList.add('resize');

      sortUpElement.style.left = `${iconContainerWidth}px`;
      sortDownElement.style.left = `${iconContainerWidth}px`;
    }
  };

  showResizeLine = (event) => {
    const { isStopping } = this.state;
    const line = this.resizeTableLine.current;

    if (!event || isStopping) {
      line.classList.remove('active');
    } else {
      const { clientX } = event;
      const coordsScrollTableByX = this.resizeTableBody.getBoundingClientRect()
        .x;

      line.classList.add('active');
      line.style.left = `${clientX - coordsScrollTableByX}px`;
    }
  };

  hideSortIcons = (currentElement) => {
    const marginRight = 40;
    const currentWidth = currentElement.offsetWidth;
    const sortingContainerWidth = currentElement.querySelector(
      '.sorting-container'
    ).offsetWidth;
    const maxWidthContainer = sortingContainerWidth + marginRight;
    const iconsContainer = currentElement.querySelector('.sort-chevron');

    if (iconsContainer) {
      if (maxWidthContainer > currentWidth) {
        this.changeIconPosition(currentElement);
        iconsContainer.classList.add('hidden');
      } else {
        iconsContainer.classList.remove('hidden');
      }
    }
  };

  resizeMoveHandler = (currentElement) => {
    const isResizing = currentElement.classList.contains(
      'tout-header-resize-cell'
    );

    if (isResizing) {
      this.hideSortIcons(currentElement);

      const resizableElemId = currentElement.firstChild.className
        .split('resize-header-id-')[1]
        .split(' ')[0];

      const resizableElemsById = currentElement
        .closest('.tout-table')
        .querySelectorAll(`.resize-body-id-${resizableElemId}`);

      for (const elem of resizableElemsById) {
        elem.parentElement.style.width = `${currentElement.offsetWidth}px`;
      }
    }
  };

  resizeUpHandler = () => {
    const { changeColumnWidthHandler } = this.props;
    const { columnId, resizingElement } = this.state;
    this.setState({
      isResizingTable: false,
    });

    this.showResizeLine(null);
    this.clearMouseMoveUpEvents();

    changeColumnWidthHandler(
      columnId,
      resizingElement.parentElement.offsetWidth
    );
    this._onResize();
  };

  clearMouseMoveUpEvents = () => {
    document.removeEventListener('mousemove', this.resizeAt);
    document.removeEventListener('mouseup', this.resizeUpHandler);
  };

  resizeAt = (event) => {
    const { resizingElement } = this.state;

    this.showResizeLine(event);
    this.resizeMoveHandler(resizingElement);
  };

  resizeTableHandler = (event, columnId) => {
    const { target: currentElement } = event;
    const { isResize } = this.props;

    if (!isResize) return;

    const isResizeElement = currentElement.classList.contains(
      'tout-header-resize-cell'
    );

    if (isResizeElement) {
      this.showResizeLine(event);

      this.setState({
        columnId,
        isResizingTable: true,
        resizingElement: currentElement,
      });

      document.addEventListener('mousemove', this.resizeAt);
      document.addEventListener('mouseup', this.resizeUpHandler);
    }
  };

  render() {
    const {
      changeColumnOrderHandler,
      columns,
      draggable,
      header,
      maxHeight,
      radio,
      reorderColumnsEnable,
      resizeTable,
      selectable,
      sortingColumn,
      sortingDirection,
      isResize,
    } = this.props;
    const { width } = this.state;
    const containerStyles = {};
    const bodyStyles = {};

    containerStyles.maxHeight = maxHeight ? `${maxHeight}px` : '100%';

    if (width) {
      bodyStyles.width = `${width}px`;
    }

    return (
      <div
        className="tout-table tout-table-component"
        ref={this._setContainerRef}
        style={containerStyles}
      >
        <div className="tout-table-resize-line" ref={this.resizeTableLine} />
        <HeaderRow
          changeColumnOrderHandler={changeColumnOrderHandler}
          columns={columns}
          draggable={draggable}
          isResize={isResize}
          parentTable={this.containerRef}
          radio={radio}
          reorderColumnsEnable={reorderColumnsEnable}
          resizeTable={resizeTable}
          resizeTableHandler={this.resizeTableHandler}
          selectable={selectable}
          sortingColumn={sortingColumn}
          sortingDirection={sortingDirection}
          width={width}
          {...header}
        />
        <div className="table-body" ref={this._setBodyRef} style={bodyStyles}>
          {this._getBody()}
        </div>
      </div>
    );
  }

  _setContainerRef = (div) => {
    this.containerRef = div;
  };

  _setBodyRef = (div) => {
    this.props.setBodyRef(div);
  };

  _getBody = () => {
    const {
      animateRow,
      columns,
      highlightOnClick,
      items,
      loading,
      loadingGetUrl,
      radio,
      row,
      selectable,
      zeroState,
      draggable,
      isResize,
    } = this.props;

    const RowItem = draggable ? DraggableRow : Row;

    if (loading && loadingGetUrl) {
      return <LoadingSpinner imageUrl={loadingGetUrl} />;
    } else if (loading) {
      /* Note: When you want to rely on parent component's loading spinner */
      return null;
    } else {
      return (
        <span className="flex---column--full">
          <TransitionGroup className="table-transition-group">
            {items.map((item) => (
              <RowItem
                {...row}
                animate={animateRow}
                columns={columns}
                data={item}
                disableDrag={item.isSelectedDisabled}
                draggable={draggable}
                getAnimation={this.getRowAnimation}
                highlightOnClick={highlightOnClick}
                isResize={isResize}
                isSelected={this._isSelected(item.id)}
                key={`tout-table-row-${item.id}`}
                radio={radio}
                selectable={selectable}
              />
            ))}
          </TransitionGroup>
          {items.length ? null : <ZeroState {...zeroState} />}
        </span>
      );
    }
  };

  _isSelectable = () => {
    const { selectable, radio, highlightOnClick } = this.props;
    return selectable || radio || highlightOnClick;
  };

  _isSelected = (id) => {
    const { selectedIds } = this.props;
    return (this._isSelectable() && selectedIds[`${id}`] && true) || false;
  };

  _onResize = () => {
    const { columns, selectable } = this.props;
    const boundingRect = this.containerRef.getBoundingClientRect();
    let width = boundingRect.width || 0;

    const style = window.getComputedStyle(this.containerRef) || {};
    const paddingLeft = parseInt(style.paddingLeft, 10) || 0;
    const paddingRight = parseInt(style.paddingRight, 10) || 0;

    width = width - paddingLeft - paddingRight;

    // don't set width if table columns width less than container so cells' flex can take over or be smaller
    if (width === 0) {
      this.setState(() => ({ width: 0 }));
    } else {
      const columnsWidth =
        this._getColumnsWidth(columns) + ((selectable && SelectableWidth) || 0);

      this.setState(() => ({
        width: (width < columnsWidth && columnsWidth) || 0,
      }));
    }
  };

  _getColumnsWidth(columns) {
    if (columns !== this.memoizeColumns) {
      this.memoizeColumns = columns;
      this.memoizeColumnsWidth = columns.reduce(
        (accumulator, current) => accumulator + (current.width || 0),
        0
      );
    }

    return this.memoizeColumnsWidth;
  }

  getRowAnimation = () => ({
    animate: this.props.animateRow,
    color: this.props.animationColor,
  });
}

Table.propTypes = {
  animateRow: PropTypes.bool,
  animationColor: PropTypes.string,
  changeColumnOrderHandler: PropTypes.func,
  changeColumnWidthHandler: PropTypes.func,
  columns: PropTypes.arrayOf(Shapes.Column),
  draggable: PropTypes.bool,
  header: Shapes.Header,
  highlightOnClick: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  loadingGetUrl: PropTypes.func,
  maxHeight: PropTypes.number,
  openAdvancedSearch: PropTypes.func,
  radio: PropTypes.bool,
  reorderColumnsEnable: PropTypes.bool,
  row: Shapes.Row,
  selectable: PropTypes.bool,
  selectedIds: PropTypes.objectOf(PropTypes.bool.isRequired),
  setBodyRef: PropTypes.func,
  sortingColumn: PropTypes.string,
  sortingDirection: Shapes.SortingDirection,
  zeroState: Shapes.ZeroState,
};

Table.defaultProps = {
  animateRow: false,
  animationColor: '',
  changeColumnOrderHandler: () => {},
  changeColumnWidthHandler: () => {},
  columns: [],
  draggable: false,
  header: undefined,
  highlightOnClick: false,
  items: [],
  loading: false,
  loadingGetUrl: undefined,
  maxHeight: undefined,
  openAdvancedSearch: undefined,
  radio: false,
  reorderColumnsEnable: false,
  row: undefined,
  selectable: false,
  selectedIds: {},
  setBodyRef: () => {},
  sortingColumn: '',
  sortingDirection: undefined,
  zeroState: undefined,
};

export default Table;
