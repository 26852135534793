import { getUserChannel } from 'web/services/pusherService';
import { handleLiquifyProgress } from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import ComposeWindowActionTypes from 'web/composeWindow/libs/composeWindowActionTypes';
import { PusherIdentifier } from 'web/composeWindow/libs/composeWindowConstants';

export const composeWindowInitPusher = () => (dispatch, getState) => {
  const { liquifyPitchPusherInitialized } = getState();

  // TL: This is a hack because getUserChannel().unbind(..) doesn't f**king work
  if (!liquifyPitchPusherInitialized) {
    getUserChannel().bind(PusherIdentifier.liquifyPitch, (data) => {
      const {
        person_id: recipientId,
        subject,
        body,
        identifier: jobIdentifier,
      } = data;

      const {
        liquifyProgress: { identifier },
      } = getState();

      if (identifier === jobIdentifier) {
        dispatch(handleLiquifyProgress({ body, recipientId, subject }));
      }
    });

    dispatch({
      isInitialized: true,
      type: ComposeWindowActionTypes.setLiquifyPusherInitialized,
    });
  }
};
