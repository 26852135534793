import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateAgoComponent from './dateAgoComponent';
import intervalHandler from 'components/hocs/intervalHandler';

const DateAgoIntervalHandler = ({
  className,
  datetime,
  showNowTime,
  textId,
}) => (
  <DateAgoComponent
    className={className}
    datetime={moment(datetime).fromNow()}
    showNow={showNowTime > moment().diff(datetime, 's')}
    textId={textId}
  />
);

DateAgoIntervalHandler.propTypes = {
  className: PropTypes.string,
  datetime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  interval: PropTypes.number /* used by IntervalHandler */, //eslint-disable-line
  showNowTime: PropTypes.number,
  textId: PropTypes.string,
};

DateAgoIntervalHandler.defaultProps = {
  className: '',
  datetime: '',
  interval: 60000,
  showNowTime: 0,
  textId: '',
};

export default intervalHandler(DateAgoIntervalHandler);
