export default {
  allTemplates: {
    add: 'TEMPLATES_ALL_TEMPLATES_ADD',
    set: 'TEMPLATES_ALL_TEMPLATES_SET',
  },
  categories: {
    add: 'TEMPLATES_CATEGORIES_ADD',
    create: 'TEMPLATES_CATEGORIES_CREATE',
    delete: 'TEMPLATES_CATEGORIES_DELETE',
    edit: 'TEMPLATES_CATEGORIES_EDIT',
    loading: 'TEMPLATE_CATEGORIES_LOADING',
    newOnChange: 'TEMPLATES_CATEGORIES_NEW_ON_CHANGE',
    select: 'TEMPLATES_CATEGORIES_SELECT',
    set: 'TEMPLATES_CATEGORIES_SET',
    update: 'TEMPLATES_CATEGORIES_UPDATE',
    updateSelectedCategory: 'TEMPLATES_CATEGORIES_UPDATE_SELECTED_CATEGORY',
  },
  createTemplate: {
    update: 'TEMPLATES_CREATE_TEMPLATE_UPDATE',
  },
  favorites: {
    set: 'TEMPLATES_FAVORITES_SET',
    setSingle: 'TEMPLATES_FAVORITES_SET_SINGLE',
  },
  mode: {
    set: 'TEMPLATES_MODE_SET',
  },
  mostRecentlyCreated: {
    reset: 'TEMPLATES_MOST_RECENTLY_CREATED_RESET',
    set: 'TEMPLATES_MOST_RECENTLY_CREATED_SET',
  },
  search: {
    reset: 'TEMPLATES_SEARCH_RESET',
    set: 'TEMPLATES_SEARCH_SET',
    setFilter: 'TEMPLATES_SEARCH_SET_FILTER',
  },
  sharableCategories: {
    set: 'TEMPLATES_SHARABLE_CATEGORIES_SET',
  },
  sidebarFilterInput: {
    set: 'TEMPLATES_SIDEBAR_FILTER_INPUT_SET',
  },
  smartFilters: {
    set: 'TEMPLATES_SMART_FILTERS_SET',
  },
  specialCategoryTags: {
    reset: 'TEMPLATES_SPECIAL_CATEGORIES_TAGS_RESET',
    set: 'TEMPLATES_SPECIAL_CATEGORIES_TAGS_SET',
  },
  store: {
    init: 'TEMPLATES_STORE_INIT',
  },
  table: {
    filter: 'TEMPLATES_TABLE_FILTER',
    page: 'TEMPLATES_TABLE_PAGE',
    perPage: 'TEMPLATES_TABLE_PER_PAGE',
    reset: 'TEMPLATES_TABLE_RESET',
    sortInfo: 'TEMPLATES_TABLE_SORT_INFO',
  },
  tags: {
    set: 'TEMPLATES_TAGS_SET',
  },
  templates: {
    add: 'TEMPLATES_ADD',
    destroy: 'TEMPLATES_DESTROY',
    loading: 'TEMPLATES_LOADING',
    resetMovedTemplates: 'TEMPLATES_RESET_MOVED_TEMPLATES',
    search: 'TEMPLATES_SEARCH',
    searchLoading: 'TEMPLATES_SEARCH_LOADING',
    set: 'TEMPLATES_SET',
    setMovedTemplates: 'TEMPLATES_SET_MOVED_TEMPLATES',
    update: 'TEMPLATES_UPDATE',
  },
  viewer: {
    set: 'TEMPLATES_VIEWER_SET',
  },
};
