import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import './metric.scss';

const Metric = ({ color, label, value, size, intl: { formatMessage } }) => {
  const textColor = color ? `text-${color}` : '';
  return (
    <div className="campaign-analytics-metric">
      <div
        className={classNames(
          `campaign-analytics-metric-${size}`,
          `${textColor}`
        )}
      >
        {value}
      </div>
      <div className="campaign-analytics-metric-label">
        {formatMessage({ id: label })}
      </div>
    </div>
  );
};

Metric.propTypes = {
  color: PropTypes.string,
  intl: intlShape.isRequired,
  size: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

Metric.defaultProps = {
  color: undefined,
};

export default injectIntl(Metric);
