import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';
import PipelineMessage from 'web/liveFeed/pipeline/components/pipelineMessage';
import MarketoCard from 'web/liveFeed/components/marketoCard';
import classnames from 'classnames';
import './pipeline.scss';

const ErrorMessagesWithSalesforceLink = [
  'web.liveFeed.target.noSalesforceIntegration',
  'web.liveFeed.target.noMarketoAndNotAdmin',
  'web.liveFeed.target.noIntegrationAndNotAdmin',
  'web.liveFeed.pipeline.noIntegration',
];

export default function Pipeline(props) {
  const {
    actionCreators,
    bestBets,
    errorMessageId,
    isLoading,
    openChildrenLoadingIds,
    salesforceInstanceUrl,
    saleforceConnectLink,
  } = props;

  let zeroStateMessage = null;
  let pipelineValues = null;
  const filteredBestBets = bestBets.filter((bestBet) => bestBet.leadScore > 0);

  if (isLoading) {
    return (
      <div className="pipeline">
        <LoadingSpinner className="pipeline" imageUrl={getImageUrl} />
      </div>
    );
  }
  if (errorMessageId === null && filteredBestBets.length === 0) {
    zeroStateMessage = 'web.liveFeed.pipeline.noLeads';
  }
  if (ErrorMessagesWithSalesforceLink.includes(errorMessageId)) {
    pipelineValues = { link: saleforceConnectLink };
  }

  return (
    <div>
      <PipelineMessage
        message={errorMessageId || zeroStateMessage}
        values={{ ...pipelineValues }}
      />
      <div
        className={classnames('pipeline', {
          'background-gray-extra-light': filteredBestBets.length,
        })}
      >
        {filteredBestBets
          .sort((first, second) => second.leadScore - first.leadScore)
          .map((bestBet) => (
            <MarketoCard
              actionCreators={actionCreators}
              bestBet={bestBet}
              salesforceInstanceUrl={salesforceInstanceUrl}
              key={bestBet.id}
              events={bestBet.events}
              isLoading={openChildrenLoadingIds.includes(bestBet.id)}
              showMarketoBorder
              targetTab
            />
          ))}
      </div>
    </div>
  );
}

Pipeline.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  bestBets: PropTypes.array.isRequired,
  errorMessageId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  salesforceInstanceUrl: PropTypes.string.isRequired,
  openChildrenLoadingIds: PropTypes.array.isRequired,
  saleforceConnectLink: PropTypes.string.isRequired,
};

Pipeline.defaultProps = {
  errorMessageId: '',
};
