import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { closeModalTask } from 'web/modals/task';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';

// common to other modals
export function openModal(id, data) {
  return {
    type: ActionTypes.modal.state.open,
    id,
    data,
  };
}

export function setModalComponent(component, data) {
  return {
    type: ActionTypes.modal.state.setComponent,
    component,
    data,
  };
}

export function closeModal() {
  return (dispatch, getState) => {
    const {
      modalsTaskInitStore: inited,
      modalsTaskWindowMode: windowMode,
    } = getState();
    dispatch({ type: ActionTypes.modal.state.close });
    dispatch(closePopup());

    if (windowMode && inited) {
      closeModalTask();
      window.close();
    } else if (inited) {
      closeModalTask();
    }
  };
}
