import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getImageUrl, ModalPopupIds } from 'web/libs/constants';
import { SourceOpener, PopupSize } from 'libs/constantsShared';
import Popup from 'components/popups';
import { uploadFile } from 'web/settings/adminSettings/general/actionCreators/salesEnagePhoneActionCreators';
import { recordingManagerStartup } from '../actionCreators/startup';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  changeEnableStateOfConsentMessage,
  deleteRecordingAudio,
  getRecordingNotice,
} from 'web/modals/manageRecordingNotice/actionCreators/recordingNoticeActionCreators';
import { SUPPORTED_AUDIO_FORMATS } from 'web/modals/manageRecordingNotice/libs/constants';
import { openModalManageRecordingNotice } from 'web/modals/manageRecordingNotice';
import Action, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import ManageRecordingNoticeColumns from 'web/manageRecordingNotice/containers';
import { getAlert } from 'web/modals/manageRecordingNotice/helpers/recordingNoticePopupAlertsHelper';
import {
  clearPopupAlert as clearAlert,
  openPopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { closeModal } from 'web/modals/manageRecordingNotice/actionCreators/popupActionCreators';
import ImageInsert from 'components/imageInsert';
import HoverTooltip from 'components/hoverTooltip';
import './recordingNoticeContainer.scss';

const POPUP_SIZE = 1000;
const MAX_UPLOAD_AUDIO = 10;

class RecordingNoticeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dotaId: 0,
      isShowDeletePopup: false,
      isShowUploadPopup: false,
      recordings: [],
      recordingsRemoved: [],
      removeTrackName: '',
    };
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(recordingManagerStartup());

    this.actionCreators = {
      changeEnableStateOfConsentMessage: (id, active) =>
        dispatch(changeEnableStateOfConsentMessage(id, active)),
      clearAlert: () => dispatch(clearAlert()),
      closeModal: () => dispatch(closeModal()),
      closeModalDelete: () => {
        openModalManageRecordingNotice();
      },
      deleteRecordingAudio: () => {
        const { dotaId, recordingsRemoved } = this.state;
        const removedIds = recordingsRemoved.concat(dotaId);

        dispatch(deleteRecordingAudio(dotaId));

        this.setState({
          isShowDeletePopup: false,
          recordings: this.state.recordings.filter(
            (record) => record.id !== dotaId
          ),
          recordingsRemoved: removedIds,
        });

        dispatch({
          data: removedIds,
          type: ActionTypes.api.manageRecordingNoticeDeleteAudio.success,
        });
      },
      getRecordingNotice: () => dispatch(getRecordingNotice()),
      openPopup: () => dispatch(openPopup(ModalPopupIds.imageInsert)),
      uploadFile: (file) => dispatch(uploadFile(file)),
    };
  }

  uploadFile = async (files) => {
    const { getRecordingNotice, uploadFile } = this.actionCreators;
    await uploadFile(files[0]);
    getRecordingNotice();
    this.setState({ isShowUploadPopup: false });
  };

  componentDidMount() {
    const { deleteRecordingNotice, recordingNotice } = this.props;

    this.setState({
      recordings: recordingNotice,
    });

    this.setState({
      recordings: deleteRecordingNotice['deletedRecordingsIds']
        ? recordingNotice.filter(
            (recordData) =>
              !deleteRecordingNotice['deletedRecordingsIds'].includes(
                recordData.id
              )
          )
        : recordingNotice,
    });
  }

  componentDidUpdate(prevProps) {
    const { recordingNotice } = this.props;
    if (prevProps.recordingNotice !== recordingNotice) {
      this.setState({ recordings: recordingNotice });
    }
  }

  render() {
    const { loading, modal, sourceOpener } = this.props;

    return modal.id === ModalPopupIds.recordingNotice ? (
      <Popup
        className="manageRecordingNotice-popup"
        disableClick={sourceOpener === SourceOpener.liveFeed}
        getImageUrl={getImageUrl}
        loading={loading}
        {...this._getGlobalPopupObject()}
      />
    ) : null;
  }

  getShowDeletePopup = (isShowDeletePopup, id, title) => {
    this.setState({
      dotaId: id,
      isShowDeletePopup: isShowDeletePopup,
      removeTrackName: title,
    });
  };

  _getGlobalPopupObject = () => {
    const {
      fileProgress,
      intl: { formatMessage },
    } = this.props;
    const { clearAlert } = this.actionCreators;

    const { removeTrackName } = this.state;

    const onClose = this.actionCreators.closeModal;
    const onCloseDeleteModal = this.actionCreators.closeModalDelete;
    const onDeleteRecordingNotice = this.actionCreators.deleteRecordingAudio;

    const getDeleteRecordingNoticePopup = () => {
      return {
        children: (
          <div className="page-header delete-notice-popup">
            <FormattedMessage
              id="web.deleteRecordingNotice.popup.text"
              values={{ removeTrackName: removeTrackName }}
            />
            <br />
            <FormattedMessage id="web.templates.cofirmationPopupMessage" />
          </div>
        ),
        footer: {
          onPrimary: onDeleteRecordingNotice,
          onSecondary: onCloseDeleteModal,
          primaryColor: ButtonColors.red,
          primaryTextId: 'common.delete',
          secondaryTextId: 'common.cancel',
        },
        header: {
          onClose: onCloseDeleteModal,
          textId: 'web.deleteRecordingNotice.popup.title',
        },
        size: PopupSize.small,
      };
    };

    const getUploadFilePopup = () => {
      return {
        children: (
          <div className="page-header delete-notice-popup">
            <ImageInsert
              file={fileProgress}
              isTipVisible={false}
              supportedFormats={SUPPORTED_AUDIO_FORMATS}
              uploadFiles={this.uploadFile}
            />
          </div>
        ),
        onOutsideClick: () => this.setState({ isShowUploadPopup: false }),
        size: PopupSize.small,
      };
    };

    const getImportRecordingButton = () => {
      const isImportDisabled = this.state.recordings.length >= MAX_UPLOAD_AUDIO;
      const actionButton = (
        <Action
          className="recording-notice-table-button"
          disabled={isImportDisabled}
          onClick={() => this.setState({ isShowUploadPopup: true })}
          size={ButtonSizes.medium}
        >
          <FormattedMessage id="web.settings.adminSettings.general.salesEngagePhoneCard.importRecordingButton" />
        </Action>
      );
      return isImportDisabled ? (
        <HoverTooltip
          place="bottom"
          stayOpen={false}
          textId="web.settings.adminSettings.general.salesEngagePhoneCard.importRecordingButton.disabledHint"
          tooltipId="disabled-recording-notice-import-tooltip"
        >
          {actionButton}
        </HoverTooltip>
      ) : (
        actionButton
      );
    };

    const obj = {
      alert: getAlert(this.props, { clearAlert }),
      children: (
        <React.Fragment>
          {this.state.isShowDeletePopup ? (
            <div key="advanced-search-popups">
              <Popup {...getDeleteRecordingNoticePopup()} />
            </div>
          ) : null}
          {this.state.isShowUploadPopup ? (
            <div key="advanced-search-popups-1">
              <Popup {...getUploadFilePopup()} />
            </div>
          ) : null}
          <div className="page-header recording-notice-table-title">
            <div className="page-header-row">
              <div className="recording-notice-table-label">
                <FormattedMessage id="web.settings.adminSettings.general.salesEngagePhoneCard.title.tableRecordingNotice" />
              </div>
              <div className="align-right">{getImportRecordingButton()}</div>
            </div>
          </div>
          <ManageRecordingNoticeColumns
            actionCreators={this.actionCreators}
            formatMessage={formatMessage}
            getShowDeletePopup={this.getShowDeletePopup}
            recordingNoticeData={this.state.recordings}
          />
          <div className="recording-notice-footer">
            <Action
              className="recording-notice-table-button"
              onClick={onClose}
              size={ButtonSizes.medium}
            >
              <FormattedMessage id="common.ok" />
            </Action>
          </div>
        </React.Fragment>
      ),
      footer: {},
      header: {
        onClose,
      },
      size: POPUP_SIZE,
    };

    obj.header.textId = 'web.recordingNotice.title.details';

    return obj;
  };
}

RecordingNoticeContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  editState: PropTypes.object.isRequired,
  fileProgress: PropTypes.object,
  groups: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  modal: PropTypes.object.isRequired,
  mode: PropTypes.string, // passed in
  options: PropTypes.object, // passed in
  recordingNotice: PropTypes.arrayOf(PropTypes.object),
  recordingNoticeCurrentAudio: PropTypes.object,
  sourceOpener: PropTypes.string, // passed in
  task: PropTypes.object, // passed in
  windowMode: PropTypes.bool.isRequired,
};

RecordingNoticeContainer.defaultProps = {
  fileProgress: null,
  mode: '',
  options: {},
  recordingNotice: [],
  recordingNoticeCurrentAudio: null,
  sourceOpener: '',
  task: {},
};

function mapStateToProps(state) {
  return {
    account: state.user,
    alert: state.popupAlert,
    deleteRecordingNotice: state.deleteRecordingNotice,
    editState: state.taskEditState,
    fileProgress: state.imageInsertFile,
    groups: state.groups,
    loading: state.popupLoading,
    modal: state.modal,
    person: state.personDetailsPerson,
    recordingNotice: state.manageRecordingNotice.recordingNotice,
    recordingNoticeCurrentAudio:
      state.manageRecordingNoticeCurrentAudio.recordingNoticeCurrentAudio,
    windowMode: state.modalsTaskWindowMode,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(RecordingNoticeContainer);
