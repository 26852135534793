import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Checkbox from 'components/buttons/checkbox';
import { canEditCampaign } from 'web/campaigns/campaignsTab/services/campaignService';
import './campaignSettingsReply.scss';

class CampaignSettingsReply extends Component {
  render() {
    const {
      classes,
      intl: { formatMessage },
      selectedCampaign,
    } = this.props;
    return (
      <div className={classes}>
        <Checkbox
          classes="margin-top-half"
          checked={selectedCampaign.workflow_setting.stop_on_reply}
          label={formatMessage({ id: 'web.campaigns.stopCampaignIndividual' })}
          disabled={!canEditCampaign(selectedCampaign)}
          name="stop_on_reply"
          onChange={this.onCheckboxChange}
        />
        <Checkbox
          classes="margin-top-half"
          checked={selectedCampaign.workflow_setting.success_on_reply}
          label={formatMessage({
            id: 'web.campaigns.automaticallyMarkSuccess',
          })}
          disabled={!canEditCampaign(selectedCampaign)}
          name="success_on_reply"
          onChange={this.onCheckboxChange}
        />
        <div className="margin-top-full">
          {formatMessage({ id: 'web.campaigns.replyOOO' })}
        </div>
      </div>
    );
  }

  onCheckboxChange = (checked, name) => {
    const { selectedCampaign, actionCreators } = this.props;
    actionCreators.updateSettings(selectedCampaign, { [name]: checked });
  };
}

CampaignSettingsReply.propTypes = {
  classes: PropTypes.string,
  intl: intlShape.isRequired,
  actionCreators: PropTypes.object.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
};

CampaignSettingsReply.defaultProps = {
  classes: undefined,
};

export default injectIntl(CampaignSettingsReply);
