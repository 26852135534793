import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './filesChooser.scss';
import { injectIntl, intlShape } from 'react-intl';
import TextCell from 'table/modules/cells/textCell';
import ToutTable from 'table/modules/toutTable';
import { alphabetical } from 'table/modules/sorting';
import FileProgressCell from 'table/modules/cells/fileProgressCell';
import FileIconCell from 'table/modules/cells/fileIconCell';
import moment from 'moment';

function FilesChooser({
  bulkDeleteFiles,
  disableDelete,
  files,
  grabSelectedIds,
  handleRowClick,
  intl: { formatMessage },
  tableRef,
}) {
  const [searchValue, setSearchValue] = useState('');

  function getColumns() {
    return [
      {
        id: '1',
        name: formatMessage({
          id: 'common.type',
        }),
        rowCell: {
          component: FileIconCell,
          metadata: { property: 'file_name' },
        },
        width: 80,
      },
      {
        flex: true,
        id: '2',
        name: formatMessage({
          id: 'common.name',
        }),
        rowCell: {
          component: TextCell,
          metadata: { property: 'file_name' },
        },
        sorting: alphabetical('file_name'),
        width: 300,
      },
      {
        id: '3',
        rowCell: {
          component: FileProgressCell,
          metadata: { property: 'progress', secondaryProperty: 'status' },
        },
        width: 100,
      },
      {
        align: 'right',
        flex: true,
        id: '4',
        name: formatMessage({
          id: 'common.dateUploaded',
        }),
        rowCell: {
          component: TextCell,
          metadata: { property: 'created_at' },
        },
        sorting: (rowData) => {
          const date = new Date(rowData.created_at);
          return -date.getTime();
        },
        width: 120,
      },
    ];
  }

  const searchObject = {
    noDropdown: true,
    // had to do this the table passes down another arg and it results in a console warning
    // React hook useState only allows one arg
    onChange: (value) => setSearchValue(value),
    placeholderText: formatMessage({
      id: 'common.search',
    }),
    showClear: false,
    value: searchValue,
  };

  const dateFormattedFiles = files.map((file) => ({
    ...file,
    created_at: moment(file.created_at).format('LL'),
    file_name: file.name || file.file_file_name,
  }));

  function _grabSelectedIds(data) {
    if (data) {
      const ids = Object.keys(data);
      grabSelectedIds(ids);
    }
  }

  function _getBulkActions() {
    if (disableDelete) return [];
    const actions = [
      {
        color: 'red',
        label: formatMessage({ id: 'common.delete' }),
        onClick: bulkDeleteFiles,
      },
    ];
    return actions;
  }

  const filteredItems = dateFormattedFiles.filter(({ file_name: fileName }) =>
    fileName.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="files-chooser-container">
      <ToutTable
        bulkActions={{
          actions: _getBulkActions(),
          itemsLabel: formatMessage({ id: 'common.files' }),
        }}
        columns={getColumns()}
        grabSelectedIds={_grabSelectedIds}
        items={filteredItems}
        maxHeight={350}
        pagination={{
          currentPage: 1,
          perPage: 10,
        }}
        row={{
          height: 50,
          onClick: handleRowClick,
        }}
        search={searchObject}
        tableId="file-choosers-table"
        tableRef={tableRef}
        zeroState={{
          bodyText: null,
          titleText: formatMessage({ id: 'web.filesChooser.zeroState.title' }),
        }}
        allItems
        selectable
      />
    </div>
  );
}

FilesChooser.propTypes = {
  bulkDeleteFiles: PropTypes.func.isRequired,
  disableDelete: PropTypes.bool,
  files: PropTypes.array.isRequired,
  grabSelectedIds: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  tableRef: PropTypes.object.isRequired,
  handleRowClick: PropTypes.func,
  disableDelete: PropTypes.bool,
  intl: intlShape.isRequired,
};

FilesChooser.defaultProps = {
  handleRowClick: () => {},
  disableDelete: false,
};

FilesChooser.defaultProps = {
  disableDelete: false,
};

export default injectIntl(FilesChooser);
