import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import { addTemplate } from 'web/templates/actionCreators/templatesCreateActionCreators';
import { isMemberOfTeams } from 'web/shared/services/accountService';

export const updateTemplate = (template) => ({
  type: TemplatesActionTypes.templates.update,
  template,
});

export const destroyTemplate = (templateId) => ({
  type: TemplatesActionTypes.templates.destroy,
  templateId,
});

export const updateTemplateFromPusher = (template) => (dispatch, getState) => {
  const { id, category_id: categoryId, team_ids: updateTeamIds } = template;
  const { templatesSelectedTemplateCategoryId, templatesGridData } = getState();

  if (templatesSelectedTemplateCategoryId === categoryId) {
    if (isMemberOfTeams(updateTeamIds)) {
      if (templatesGridData.find((templat) => templat.id === id)) {
        dispatch(updateTemplate(template));
      } else {
        dispatch(addTemplate(template));
      }
    } else {
      // Unshared
      dispatch(destroyTemplate(template.id));
    }
  }
};
