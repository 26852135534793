import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select2 from 'components/selects/select2';
import Table from 'table/modules/table';
import SelectCell from 'table/modules/cells/selectCell';
import TextCell from 'table/modules/cells/textCell';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  onSelectedEmailChange,
  setSelectedAddPeopleToGroupGroupId,
} from 'web/people/actionCreators/peopleGroupActionCreators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ContactStatusCell from 'web/people/components/contactStatusCell';
import { getFormattedGroupsSelectOptions } from '../selectors/peopleSelectors';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';
import './addPeopleToGroup.scss';

const TABLE_ID = 'ADD_PEOPLE_TO_GROUP';
const TABLE_MAX_HEIGHT = 350;

class AddPeopleToGroup extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
      actionCreators,
    } = props;

    this.groupSelectPlaceholder = formatMessage({
      id: 'web.people.searchGroup',
    });
    this.columns = [
      {
        id: 'name',
        name: formatMessage({ id: 'common.name' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'name',
            wrapLines: 2,
            classNames: this.disabledCellClasses,
          },
        },
        flex: true,
        width: 175,
      },
      {
        id: 'email',
        name: formatMessage({ id: 'common.primaryEmail' }),
        rowCell: {
          component: SelectCell,
          metadata: {
            selectItems: (rowData) => rowData.addresses,
            propertyName: 'emailId',
            onChange: actionCreators.onSelectedEmailChange,
            disabled: (rowData) => rowData.isUnsubscribed,
            full: true,
            classNames: this.disabledCellClasses,
          },
        },
        flex: true,
        initHide: false,
        width: 250,
      },
      {
        id: 'status',
        name: formatMessage({ id: 'common.status' }),
        rowCell: {
          component: ContactStatusCell,
        },
        flex: true,
        width: 100,
      },
    ];
  }

  disabledCellClasses = (rowData) =>
    rowData.isUnsubscribed ? 'disabled-cell' : '';

  render() {
    const {
      people,
      groups,
      actionCreators: { setSelectedAddPeopleToGroupGroupId },
      selectedAddToGroupGroupId,
    } = this.props;
    const formattedGroupName = groups.map((group) => ({
      ...group,
      label: getLocalizedDateFromGroupName(group.label),
    }));

    return (
      <div className="add-people-to-group">
        <div className="group-select-container">
          <FormattedMessage id="web.people.selectGroupToAddTo" />
          <Select2
            className="select-group-to-add"
            options={formattedGroupName}
            onChange={setSelectedAddPeopleToGroupGroupId}
            value={selectedAddToGroupGroupId}
            placeholder={this.groupSelectPlaceholder}
            searchable
            clearable={false}
          />
        </div>
        <div className="add-people-to-group-table tout-table">
          <Table
            tableId={TABLE_ID}
            columns={this.columns}
            items={people}
            maxHeight={TABLE_MAX_HEIGHT}
          />
        </div>
      </div>
    );
  }
}

AddPeopleToGroup.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
  actionCreators: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  selectedAddToGroupGroupId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => ({
  groups: getFormattedGroupsSelectOptions(state),
  people: state.selectedAddPeopleToGroupPeople,
  selectedAddToGroupGroupId: state.selectedAddToGroupGroupId,
});

const mapDispatchToProps = {
  onSelectedEmailChange,
  setSelectedAddPeopleToGroupGroupId,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  injectIntl
)(AddPeopleToGroup);
