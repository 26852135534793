import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/selects/base';
import './defaultSelect.scss';

export default class DefaultSelect extends PureComponent {
  render() {
    const {
      className,
      items,
      propertyLabel: ignored1,
      propertyValue: ignored2,
      ...other
    } = this.props;

    return (
      <Select
        className={`default-select ${className}`}
        items={this._parseItems(items)}
        {...other}
      />
    );
  }

  _parseItems = (listItems) => {
    const { propertyLabel, propertyValue } = this.props;

    return listItems.map((item) => ({
      label: item[propertyLabel || 'name'],
      value: item[propertyValue || 'id'],
      disabled: item.disabled,
      disabledStyle: item.disabledStyle,
    }));
  };
}

DefaultSelect.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  propertyLabel: PropTypes.string,
  propertyValue: PropTypes.string,
};

DefaultSelect.defaultProps = {
  className: '',
  propertyLabel: '',
  propertyValue: '',
};
