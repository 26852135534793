import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Item from 'components/navBar/item';
import AlertList from './AlertList';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import withDropdownToggler from '../../hocs/dropdownToggleHoc';

const ICON = 'icon-alert';

class AlertItem extends React.Component {
  state = {
    badgeCounter: 0,
  };

  constructor(props) {
    super(props);

    const { integrateOnCounterUpdate } = this.props;
    integrateOnCounterUpdate(this.updateCounterNearIcon);
  }

  componentDidMount() {
    toutBackboneHelper.onReactAlertsInitialized(this.hideDropdown);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.hash !== prevProps.location.hash) {
      this.hideDropdown();
    }
  }

  render() {
    const { titleClassName, showDropdown } = this.props;
    const { badgeCounter } = this.state;
    const badge = badgeCounter || null;

    return (
      <React.Fragment>
        <Item
          titleId="web.topnavbar.alerts"
          titleClassName={titleClassName}
          icon={ICON}
          isActive={showDropdown}
          onClick={this.toggleDropdown}
          classNames="dropdown-toggle"
          badge={badge}
        >
          <AlertList
            isOpened={showDropdown}
            disableClick={!showDropdown}
            onOutsideClick={this.hideDropdown}
          />
        </Item>
      </React.Fragment>
    );
  }

  hideDropdown = () => {
    const { closeNotifications, toggleDropdown, showDropdown } = this.props;
    if (!showDropdown) {
      return;
    }
    closeNotifications();
    toggleDropdown();
  };

  toggleDropdown = () => {
    const {
      openNotifications,
      closeNotifications,
      showDropdown,
      toggleDropdown,
    } = this.props;
    const { badgeCounter } = this.state;

    this.updateCounterNearIcon(!showDropdown ? 0 : badgeCounter);
    toggleDropdown();

    if (!showDropdown) {
      openNotifications();
    } else {
      closeNotifications();
    }
  };

  updateCounterNearIcon = (badgeCounter) => {
    this.setState({ badgeCounter });
  };
}

AlertItem.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  integrateOnCounterUpdate: PropTypes.func.isRequired,
  openNotifications: PropTypes.func.isRequired,
  closeNotifications: PropTypes.func.isRequired,
  titleClassName: PropTypes.string,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
};

AlertItem.defaultProps = {
  titleClassName: null,
};

export default compose(
  withRouter,
  withDropdownToggler
)(AlertItem);
