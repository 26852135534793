import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import PropTypes from 'prop-types';
import CustomizationGuideLinks from './customizationGuideLinks';

const SalesforceCustomizationStatusNeed = React.memo((props) => {
  const {
    intl: { formatMessage },
    actionCreators: { customizationStatusCall },
  } = props;
  const buttonText = formatMessage({
    id: 'web.salesforceCustomization.checkStatus',
  });

  return (
    <Fragment>
      <div className="margin-top-20">
        <FormattedMessage id="web.salesforceCustomization.connect.prelude.increasePipeline" />
      </div>
      <div className="margin-top-20">
        <FormattedMessage id="web.salesforceCustomization.connect.prelude.robustIntegration" />
      </div>
      <CustomizationGuideLinks />
      <div className="margin-top-20 right">
        <Button
          color={ButtonColors.blue}
          size={ButtonSizes.standard}
          onClick={customizationStatusCall}
          title={buttonText}
        >
          <span className="tout-icon-refresh" />
          {buttonText}
        </Button>
      </div>
    </Fragment>
  );
});

SalesforceCustomizationStatusNeed.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceCustomizationStatusNeed);
