import {
  getSlackIntegration,
  deleteSlackIntegration,
} from 'web/integrations/slack/actionCreators/slackActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  IntegrationsAlertIds,
  IntegrationsPopupIds,
} from '../libs/integrationConstants';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';

export const getIntegration = () => (dispatch) =>
  dispatch(getSlackIntegration());

export const deleteIntegration = () => (dispatch) =>
  dispatch(deleteSlackIntegration())
    .then(() => {
      dispatch(openViewAlert(IntegrationsAlertIds.success));
    })
    .catch(() => {
      dispatch(openViewAlert(IntegrationsAlertIds.failure));
    });

export const openDeleteSlackConfirmation = () => (dispatch) =>
  dispatch(openPopup(IntegrationsPopupIds.deleteSlack));

export const openNavigateToSlackConfirmation = () => (dispatch) =>
  dispatch(openPopup(IntegrationsPopupIds.navigateToSlack));
