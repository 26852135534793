import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SalesforceCustomizationWizardCommonStep from '../commonStep';

class SalesforceCustomizationWizardStepSummary extends Component {
  render() {
    const { className } = this.props;

    return (
      <SalesforceCustomizationWizardCommonStep className={className}>
        <h3 className="margin-bottom-full">
          <FormattedMessage id="web.salesforceCustomization.wizard.step.summary.title" />
        </h3>
        <div>
          <FormattedHTMLMessage id="web.salesforceCustomization.wizard.step.summary.text" />
        </div>
      </SalesforceCustomizationWizardCommonStep>
    );
  }
}

SalesforceCustomizationWizardStepSummary.propTypes = {
  className: PropTypes.string,
};

SalesforceCustomizationWizardStepSummary.defaultProps = {
  className: '',
};

export default SalesforceCustomizationWizardStepSummary;
