import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getGroupSidebarList,
  getSidebarSearchResults,
} from '../selectors/peopleSelectors';
import {
  openCreateGroupPopup,
  openDeleteGroupPopup,
  openShareGroupPopup,
  openEditGroupPopup,
} from 'web/people/actionCreators/peoplePopupActionCreators';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import SidebarListItem from 'web/shared/components/sidebar/sidebarList/sidebarListItem/sidebarListItem';
import { getImageUrl } from 'web/libs/constants';
import { changed } from 'web/services/utils/changed';
import classNames from 'classnames';
import './peopleSidebarContainer.scss';
import Icon, {
  IconColors,
  IconBackgroundColors,
} from 'components/buttons/icon';
import { onSidebarItemClick } from 'web/people/actionCreators/peopleGroupActionCreators';
import { exportPeopleFromGroup } from 'web/people/actionCreators/peopleExportActionCreators';
import { injectIntl, intlShape } from 'react-intl';
import MultiSearch from 'web/shared/components/sidebar/multiSearch/multiSearch';
import {
  isUnsubscribedGroup,
  isGeneralPeopleGroup,
  getLocalizedDateFromGroupName,
} from 'web/people/helpers/peopleHelpers';

class PeopleSidebarContainer extends Component {
  constructor(props) {
    super(props);

    const {
      actionCreators: {
        exportPeopleFromGroup,
        openDeleteGroupPopup,
        openEditGroupPopup,
        openShareGroupPopup,
      },
      intl: { formatMessage },
    } = props;
    this._getGroupAdd = this._getGroupAdd.bind(this);
    this.updateProps = [
      'sideBarGroupList',
      'listLoading',
      'selectedListItem',
      'sidebarSearchResults',
      'peopleCurrentGroupId',
    ];
    this.sidebarListRef = null;

    this.groupContextButton = {
      edit: {
        action: openEditGroupPopup,
        label: formatMessage({ id: 'common.edit' }),
      },
      exportPeople: {
        action: exportPeopleFromGroup,
        label: formatMessage({ id: 'common.export' }),
      },
      remove: {
        action: openDeleteGroupPopup,
        label: formatMessage({ id: 'common.delete' }),
      },
      share: {
        action: openShareGroupPopup,
        label: formatMessage({ id: 'common.share' }),
      },
    };
  }

  shouldComponentUpdate(nextProps) {
    return changed(this.updateProps, this.props, nextProps);
  }

  render() {
    const { listLoading } = this.props;

    return (
      <div className="tout-ui-sidebar">
        {listLoading ? (
          <LoadingSpinner imageUrl={getImageUrl} />
        ) : (
          this.getSidebar()
        )}
      </div>
    );
  }

  getSidebar() {
    const {
      sideBarGroupList,
      sidebarSearchResults,
      actionCreators: { onSidebarItemClick },
    } = this.props;
    const listNames = Object.keys(sideBarGroupList);

    return (
      <>
        <MultiSearch
          onSearchResultClick={({ id }) => onSidebarItemClick(id)}
          searchResults={sidebarSearchResults}
          isCustomClickHandler
        />
        {listNames.map((listName, listKey) => {
          const { title, list } = sideBarGroupList[listName];
          return (
            <div
              className="sidebar-list margin-bottom-full"
              key={`sidebar-list-${listName}`}
            >
              <div className="tout-ui-sidebar-category-header people-sidebar-list-header">
                <h5 className="sidebar-list-header">{title.toUpperCase()}</h5>
                {listKey === 0 && this._getGroupAdd()}
              </div>
              <div className="tout-ui-sidebar-list-wrapper">
                <div className="tout-ui-sidebar-list">
                  {list.map((item) => this._getSideBarListItem(item))}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  _getSideBarListItem = ({ id, name, isEditable, num_members: numMembers }) => {
    const { peopleCurrentGroupId, actionCreators } = this.props;

    const componentClasses = classNames('tout-ui-sidebar-list-item', {
      active: peopleCurrentGroupId === id.toString(),
    });

    const isGroupExported =
      !isGeneralPeopleGroup(id) || isUnsubscribedGroup(id);

    return (
      <SidebarListItem
        classes={componentClasses}
        elementId={id}
        iconOptions={{ disabled: true }}
        itemOptions={this.getSidebarOptions(isEditable, isGroupExported)}
        key={`sidebar-list-item-${id}`}
        label={getLocalizedDateFromGroupName(name)}
        onSelect={actionCreators.onSidebarItemClick}
        scrollRef={this.sidebarListRef}
        showTooltip={!isGeneralPeopleGroup(id)}
        totalNumber={numMembers}
      />
    );
  };

  getSidebarOptions = (isEditable, isExported) => {
    const options = [];
    if (isEditable) {
      options.push(
        this.groupContextButton.share,
        this.groupContextButton.edit,
        this.groupContextButton.remove
      );
    }

    if (isExported) {
      options.length > 0
        ? options.splice(
            options.length - 1,
            0,
            this.groupContextButton.exportPeople
          )
        : options.push(this.groupContextButton.exportPeople);
    }
    return options;
  };

  setSidebarRef = (div) => {
    this.sidebarListRef = div;
  };

  _getGroupAdd() {
    const { actionCreators } = this.props;

    return (
      <Icon
        backgroundColor={IconBackgroundColors.blue}
        className="sidebar-list-add-btn"
        color={IconColors.white}
        onClick={actionCreators.openCreateGroupPopup}
      />
    );
  }
}

PeopleSidebarContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  listLoading: PropTypes.bool.isRequired,
  peopleCurrentGroupId: PropTypes.string.isRequired,
  sideBarGroupList: PropTypes.object.isRequired,
  sidebarSearchResults: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  listLoading: state.groupsFetching,
  peopleCurrentGroupId: state.peopleCurrentGroupId,
  sideBarGroupList: getGroupSidebarList(state),
  sidebarSearchResults: getSidebarSearchResults(state),
});

const mapDispatchToProps = {
  exportPeopleFromGroup,
  onSidebarItemClick,
  openCreateGroupPopup,
  openDeleteGroupPopup,
  openEditGroupPopup,
  openShareGroupPopup,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(PeopleSidebarContainer)
);
