export const getIntegrationsHealthStatuses = (state) =>
  state.yourIntegrations && state.yourIntegrations.statuses;

export const getIntegrationsHealthStatusesFetching = (state) =>
  state.yourIntegrations && state.yourIntegrations.fetching;

export const isSalesforceSetUpForCurrentAccount = (state) =>
  getIntegrationsHealthStatuses(state).isSalesforceSetUpForCurrentAccount;

export const isSalesforceBrokenForCurrentAccount = (state) =>
  getIntegrationsHealthStatuses(state).isSalesforceBrokenForCurrentAccount;
