import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CommentUser from './user';
import CommentBody from './body';
import './comment.scss';

class Comment extends Component {
  state = {
    isEditing: false,
  };

  render() {
    const {
      allowDelete,
      allowEdit,
      className,
      comment,
      datetime,
      initHeight,
      userEmail,
      userName,
    } = this.props;
    const { isEditing } = this.state;

    return (
      <div className={classNames('comment', className)}>
        <CommentUser
          allowDelete={allowDelete}
          allowEdit={allowEdit}
          datetime={datetime}
          onDelete={this.onDelete}
          onEdit={this.toggleEdit}
          userName={userName || userEmail}
        />
        <CommentBody
          comment={comment}
          initHeight={initHeight}
          isEditing={isEditing}
          onCancel={this.toggleEdit}
          onDone={this.onDone}
        />
      </div>
    );
  }

  onDone = (text) => {
    const { onDone, id } = this.props;
    onDone(text, id);
    this.toggleEdit();
  };

  toggleEdit = () =>
    this.setState(({ isEditing }) => ({ isEditing: !isEditing }));

  onDelete = () => this.props.onDelete(this.props.id);
}

Comment.propTypes = {
  allowDelete: PropTypes.bool,
  allowEdit: PropTypes.bool,
  className: PropTypes.string,
  comment: PropTypes.string,
  datetime: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  id: PropTypes.number.isRequired,
  initHeight: PropTypes.number,
  onDelete: PropTypes.func,
  onDone: PropTypes.func,
  userEmail: PropTypes.string,
  userName: PropTypes.string,
};

Comment.defaultProps = {
  allowEdit: false,
  allowDelete: false,
  className: '',
  comment: '',
  initHeight: 0,
  onDelete: () => {},
  onDone: null,
  userEmail: '',
  userName: '',
};

export default Comment;
