import { basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export const saveTimezoneSettings = (data) =>
  new Promise((resolve, reject) => {
    basePut(Urls.settings.timezoneSettings, data, resolve, reject);
  });

export const saveLocaleSettings = (data) =>
  new Promise((resolve, reject) => {
    basePut(Urls.settings.localeSettings, data, resolve, reject);
  });
