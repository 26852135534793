import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const RETURN_KEY_CODE = 13;

class DeleteAdvancedSearchComponent extends Component {
  render() {
    return (
      <div className="margin-top-full">
        <FormattedMessage id="web.commandCenter.advancedSearch.deleteText" />
      </div>
    );
  }

  onKeyDown = (e) => {
    if (e.keyCode === RETURN_KEY_CODE) {
      this.props.onDelete();
    }
  };
}

DeleteAdvancedSearchComponent.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default DeleteAdvancedSearchComponent;
