import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import DropdownItem from './dropdownItem';
import outsideClickHandler from 'components/hocs/outsideClickHandler/outsideClickHandler';

import './dropdown.scss';

const CLASS_TITLE = 'top-navbar-item-title';

const Dropdown = ({ icon, titleId, items, show }) => {
  const dropdownWrapperClass = classnames('navbar-dropdown dropdown', {
    open: show,
  });

  return (
    <div className={dropdownWrapperClass}>
      <span
        aria-expanded={show}
        aria-haspopup="true"
        className={icon}
        id="dropdownMenuButton"
      >
        {titleId && (
          <span className={CLASS_TITLE}>
            <FormattedMessage id={titleId} />
          </span>
        )}
      </span>

      <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu">
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <DropdownItem {...item} />
            {item.isDivide ? <li className="divider" /> : null}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  icon: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isAlignCenterLink: PropTypes.bool,
      isDivide: PropTypes.bool,
      isOpenNewTab: PropTypes.bool,
      link: PropTypes.string,
      titleId: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  show: PropTypes.bool,
  titleId: PropTypes.string,
};

Dropdown.defaultProps = {
  icon: null,
  show: false,
  titleId: null,
};

export default outsideClickHandler(injectIntl(Dropdown));
