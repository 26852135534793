import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import ViewMore from 'components/viewMore';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import './campaignsHistory.scss';

const SHOWN_COUNT = 2;

class CampaignsHistory extends Component {
  getHoverTipContent(user = {}) {
    const { name } = user;
    return () => {
      if (name) {
        return (
          <FormattedMessage
            id="web.person.personDetails.campaignsCard.campaignInfo.addedBy"
            values={{ name }}
          />
        );
      } else {
        return (
          <FormattedMessage id="web.person.personDetails.campaignsCard.campaignsHistory.deletedUser" />
        );
      }
    };
  }

  render() {
    const { campaigns, viewMoreOnClick } = this.props;

    return (
      <div className="person-details-campaigns-history">
        <h3 className="margin-top-full margin-bottom-half">
          <FormattedMessage id="common.history" />
        </h3>
        {campaigns.length > 0 ? (
          <div className="campaigns-history-display-table">
            <div className="campaigns-history-row text-medium background-gray-extra-light">
              <div className="row-data margin-left-quarter">
                <FormattedMessage id="web.person.personDetails.campaignsCard.campaignsHistory.endedOn" />
              </div>
              <div className="row-name">
                <FormattedMessage id="common.name" />
              </div>
              <div className="row-data">
                <FormattedMessage id="common.status" />
              </div>
            </div>
            <ViewMore
              className="campaign-history-rows"
              itemRenderer={this.itemRenderer}
              items={campaigns}
              shownCount={SHOWN_COUNT}
              viewMoreOnClick={viewMoreOnClick}
              showCount
            />
          </div>
        ) : (
          <FormattedMessage id="web.person.personDetails.campaignsCard.campaignsHistory.noHistory" />
        )}
      </div>
    );
  }

  itemRenderer = ({
    endedAt,
    id,
    name,
    startedBy,
    success,
    url,
    workflowInstanceId,
  }) => (
    <div
      className="campaigns-history-row"
      key={`campaign-history-${id}-${endedAt}`}
    >
      <div className="row-data margin-left-quarter">
        <HoverTooltip
          delayHide={0}
          delayShow={0}
          place={HoverTooltipPlace.right}
          stayOpen={false}
          tooltipId={`campaign-started-by-tooltip-${workflowInstanceId}`}
          getContent={this.getHoverTipContent(startedBy)}
        >
          <FormattedMessage
            id="common.dateFormatShort"
            values={{ date: endedAt }}
          />
        </HoverTooltip>
      </div>
      <div className="text-overflow row-name">
        <a
          className="text-underline"
          href={url}
          onClick={(event) => this.openItemClick(id, event)}
        >
          {name}
        </a>
      </div>
      <div
        className={classNames('row-data', {
          'text-green': success,
        })}
      >
        <FormattedMessage
          id={(success && 'common.success') || 'common.completed'}
        />
      </div>
    </div>
  );

  viewMoreOnClick = () =>
    this.props.viewMoreOnClick(PersonDetailsCards.campaigns);

  openItemClick = (workFlowId, e) => {
    e.preventDefault();
    const { campaigns, handleNavigateToCampaign } = this.props;
    const previousCampaign = campaigns.find(
      (campaign) => campaign.id === workFlowId
    );
    handleNavigateToCampaign(previousCampaign);
  };
}

CampaignsHistory.propTypes = {
  campaigns: PropTypes.array.isRequired,
  handleNavigateToCampaign: PropTypes.func.isRequired,
  openItemClick: PropTypes.func.isRequired,
  viewMoreOnClick: PropTypes.func.isRequired,
};

CampaignsHistory.defaultProps = {};

export default CampaignsHistory;
