import { BlockedReason } from 'libs/constantsShared';
import { parseIndividualPersonsToMap } from 'web/people/peopleSearchAdd/helpers/parsers';
import { isMarketoLead } from 'web/people/peopleSearchAdd/helpers/peopleHelpers';

function mapSimpleArray(array) {
  const mapping = {};
  array.forEach((id) => {
    mapping[id] = { id };
  });
  return mapping;
}

function parseActivePeopleMap(activePeople, campaigns, user) {
  const activeMap = {};
  activePeople.forEach((person) => {
    const subscriptionUser = user.subscription.users.find(
      (obj) => person.user_id === obj.id
    );
    const campaign = campaigns.find((obj) => person.workflow_id === obj.id);
    activeMap[person.person_id] = {
      activeCampaignBy:
        subscriptionUser &&
        subscriptionUser.name &&
        subscriptionUser.id !== user.id
          ? subscriptionUser.name
          : '', // only include team members', not user's
      activeCampaignName: campaign && campaign.name ? campaign.name : '',
      id: person.person_id,
      max_campaign_limit: person.num_active_workflows_allowed,
      state: person.state,
    };
  });
  return activeMap;
}

export const parseMarketoPeople = (marketoPeople = [], selectedIndex = {}) => {
  const people = parseIndividualPersonsToMap(marketoPeople);

  const notMarketoLeads = [];
  Object.keys(selectedIndex).forEach((id) => {
    if (!isMarketoLead(people[id])) {
      notMarketoLeads.push(id);
    }
  });

  return { not_marketo_leads: notMarketoLeads };
};

export function parsePeopleDataMaps(
  {
    active_people: activePeople = [],
    block_unsubscribed: blockUnsubscribed = [],
    invalid_emails: invalidEmails = [],
    missing_emails: missingEmails = [],
    not_marketo_leads: notMarketoLeads = [],
    unsubscribed = [],
    marketo_unsubscribed: marketoUnsubscribed = [],
    domain_blocked: domainBlocked = [],
    active_people_on_workflow: activePeopleOnWorkflow = [],
  },
  campaigns = [],
  user = {
    subscription: {
      users: [],
    },
  }
) {
  return {
    active: parseActivePeopleMap(activePeople, campaigns, user),
    blockUnsubscribed: mapSimpleArray(blockUnsubscribed),
    notMarketoLeads: mapSimpleArray(notMarketoLeads),
    unsubscribed: mapSimpleArray(unsubscribed),
    invalidEmails: mapSimpleArray(invalidEmails),
    missingEmails: mapSimpleArray(missingEmails),
    marketoUnsubscribed: mapSimpleArray(marketoUnsubscribed),
    domainBlocked: mapSimpleArray(domainBlocked),
    activePeopleOnWorkflow: parseActivePeopleMap(
      activePeopleOnWorkflow,
      campaigns,
      user
    ),
  };
}

export function parseCheckPeople(display = [], selectedIndex = {}, data = {}) {
  //todo tests
  const validIds = [];
  const invalidIds = [];
  const people = parseIndividualPersonsToMap(display);
  Object.keys(selectedIndex).forEach((id) => {
    const commonData = {
      externalReferences: people[id].externalReferences,
      leadFields: people[id].leadFields,
      name: people[id].name,
      companyName: people[id].companyName,
      title: people[id].title,
    };
    if (data.activePeopleOnWorkflow[id]) {
      invalidIds.push({
        ...data.activePeopleOnWorkflow[id],
        ...commonData,
        blockedReason: BlockedReason.alreadyActive,
      });
    } else if (data.active[id]) {
      invalidIds.push({
        ...data.active[id],
        ...commonData,
        blockedReason: BlockedReason.maxCampaignLimitReached,
      });
    } else if (data.blockUnsubscribed[id]) {
      invalidIds.push({
        ...data.blockUnsubscribed[id],
        ...commonData,
        blockedReason: BlockedReason.blockUnsubscribed,
      });
    } else if (data.unsubscribed[id] && data.marketoUnsubscribed[id]) {
      invalidIds.push({
        ...data.unsubscribed[id],
        ...commonData,
        blockedReason: BlockedReason.bothUnsubscribed,
      });
    } else if (data.marketoUnsubscribed[id]) {
      invalidIds.push({
        ...data.marketoUnsubscribed[id],
        ...commonData,
        blockedReason: BlockedReason.marketoUnsubscribed,
      });
    } else if (data.domainBlocked[id]) {
      invalidIds.push({
        ...data.domainBlocked[id],
        ...commonData,
        blockedReason: BlockedReason.domainBlocked,
      });
    } else if (data.unsubscribed[id]) {
      invalidIds.push({
        ...data.unsubscribed[id],
        ...commonData,
        blockedReason: BlockedReason.unsubscribed,
      });
    } else if (data.invalidEmails[id]) {
      invalidIds.push({
        ...data.invalidEmails[id],
        ...commonData,
        blockedReason: BlockedReason.invalidEmails,
      });
    } else if (data.missingEmails[id]) {
      invalidIds.push({
        ...data.missingEmails[id],
        ...commonData,
        blockedReason: BlockedReason.missingEmails,
      });
    } else if (data.notMarketoLeads[id]) {
      invalidIds.push({
        ...data.notMarketoLeads[id],
        ...commonData,
        blockedReason: BlockedReason.notMarketoLeads,
      });
    } else {
      validIds.push({ ...people[id] });
    }
  });

  return validIds.concat(invalidIds);
}
