import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import unauthorizedAdobePage from 'components/hocs/unauthorizedAdobePage';
import Login from '../components/login';
import { doLogin } from '../helpers/loginHandler';
import { track } from 'web/services/mixpanelService';
import {
  LoginPageEvents,
  LoginPageProperties,
  LoginPagePropertyTypes,
} from 'web/libs/mixpanelEvents';
import { LOGIN_STEPS } from 'web/login/libs/loginConstants';
import FirstStepLogin from '../components/firstStepLogin/firstStepLogin';
import SalesforceStepLogin from 'web/login/components/salesforceStepLogin/salesforceStepLogin';
import {
  setLoginMethods,
  setLoginStep,
  setUnauthorizedHeaderKey,
  showMsiActionLoginError,
} from 'web/login/actionCreators/loginActionCreators';
import Chevron, { ChevronDirections } from 'components/buttons/chevron';
import {
  isMsiActionLoginError,
  getMsiActionUserName,
} from 'web/login/helpers/loginHelper';
import Icon, { IconColors } from '../../../components/buttons/icon';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    const {
      actionCreators: { showMsiActionLoginError },
    } = props;
    if (isMsiActionLoginError()) {
      showMsiActionLoginError();
    }
  }

  state = {
    loading: false,
  };

  onSubmit = (values) => {
    track(LoginPageEvents.clickButton, {
      [LoginPageProperties.source]: LoginPagePropertyTypes.webAppBrowser,
    });
    const { isOwaMode, isGmailMode } = this.props;
    this.setState({ loading: true });
    return doLogin(values, isOwaMode, isGmailMode).catch((error) => {
      this.setState({ loading: false });
      throw error;
    });
  };

  getBackLoginButton = (onClickAction) => (
    <div
      className="back-login-button"
      onClick={() => onClickAction(LOGIN_STEPS.firstStep)}
    >
      <Chevron direction={ChevronDirections.left} />
      <FormattedMessage id="common.back" />
    </div>
  );

  getNoMatchingAccountFoundPanel = (displayMsiActionLoginError) =>
    displayMsiActionLoginError && (
      <div className="msi-action-login-error-panel">
        <Icon className="info-icon" color={IconColors.white} />
        <FormattedHTMLMessage
          id="web.unauthorized.noMatchingAccountFound"
          values={{ userId: getMsiActionUserName() }}
        />
      </div>
    );

  render() {
    const {
      currentLoginStep,
      displayMsiActionLoginError,
      actionCreators: {
        setLoginMethods,
        setLoginStep,
        setUnauthorizedHeaderKey,
      },
      loginMethodsLoading,
      loginFormUserName,
    } = this.props;

    switch (currentLoginStep) {
      case LOGIN_STEPS.allMethodsStep:
        return (
          <Fragment>
            {this.getBackLoginButton(setLoginStep)}
            <Login
              initialValues={{
                username: loginFormUserName,
              }}
              loading={this.state.loading}
              onSubmit={this.onSubmit}
              {...this.props}
            />
          </Fragment>
        );
      case LOGIN_STEPS.salesforceStep:
        return (
          <Fragment>
            {this.getBackLoginButton(setLoginStep)}
            <SalesforceStepLogin {...this.props} />
          </Fragment>
        );
      default:
        return (
          <Fragment>
            {this.getNoMatchingAccountFoundPanel(displayMsiActionLoginError)}
            <FirstStepLogin
              loading={loginMethodsLoading}
              onSubmit={setLoginMethods}
              setUnauthorizedHeaderKey={setUnauthorizedHeaderKey}
              {...this.props}
            />
          </Fragment>
        );
    }
  }
}

const mapStateToProps = (state) => ({
  account: state.user,
  currentLoginStep: state.currentLoginStep,
  displayMsiActionLoginError: state.displayMsiActionLoginError,
  isSFSandboxAuthenticationEnabled:
    state.loginStepSFSandboxAuthenticationEnabled,
  loginFormUserName: state.loginFormUserName,
  loginMethodsLoading: state.loginMethodsLoading,
  unauthorizedHeaderKey: state.unauthorizedHeaderKey,
});

const mapDispatchToProps = {
  setLoginMethods,
  setLoginStep,
  setUnauthorizedHeaderKey,
  showMsiActionLoginError,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  (component) =>
    unauthorizedAdobePage(component, {
      parentClassName: 'user-login-container',
      titleKey: 'common.login',
      useUnauthorizedStateHeader: true,
    })
)(LoginContainer);
