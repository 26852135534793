export default {
  addPeopleToGroup: 'PEOPLE_ADD_PEOPLE_TO_GROUP',
  authorizationPeople: 'PEOPLE_AUTHORIZATION',
  authorizationPeopleSuccess: 'PEOPLE_AUTHORIZATION_SUCCESS',
  createGroup: 'PEOPLE_CREATE_GROUP',
  deleteGroup: 'PEOPLE_DELETE_GROUP',
  deletePeople: 'PEOPLE_DELETE',
  editDescription: 'PEOPLE_EDIT_DESCRIPTION',
  editGroup: 'PEOPLE_EDIT_GROUP',
  importPeopleStepOne: 'PEOPLE_IMPORT_PEOPLE_STEP_ONE',
  importPeopleStepThree: 'PEOPLE_IMPORT_PEOPLE_STEP_THREE',
  importPeopleStepTwo: 'PEOPLE_IMPORT_PEOPLE_STEP_TWO',
  personGroupsModal: 'PEOPLE_PERSON_GROUPS_MODAL',
  removePeopleFromGroup: 'PEOPLE_REMOVE_FROM_GROUP_MODAL',
  shareGroup: 'PEOPLE_SHARE_GROUP',
  sourcePeople: 'PEOPLE_SOURCE',
  sourcePeopleSuccess: 'PEOPLE_SOURCE_SUCCESS',
  unsubscribePeople: 'PEOPLE_UNSUBSCRIBE',
};
