/* eslint-disable react/jsx-indent, sort-keys */
import React from 'react';
import moment from 'moment';
import I18N from 'languages';
import remove from 'lodash/remove';
import { FormattedMessage } from 'react-intl';
import TextCell from 'table/modules/cells/textCell';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import DateAgoCell from 'table/modules/cells/dateAgoCell';
import LinkCell from 'table/modules/cells/linkCell';
import EmailStatusCell from 'web/commandCenter/components/commandCenterEmailsTable/emailStatusCell';
import DeliveryChannelCell from 'web/commandCenter/components/commandCenterEmailsTable/deliveryChannelCell';
import CheckmarkCell from 'table/modules/cells/checkmarkCell';
import EmailQuickActionsCell from 'web/commandCenter/components/commandCenterEmailsTable/emailQuickActionsCell/emailQuickActionsCell';
import get from 'lodash/get';
import ActionEmailCell from 'web/commandCenter/components/commandCenterEmailsTable/actionEmailCell/actionEmailCell';
import TypeCell from 'web/tables/tasks/components/cells/typeCell';
import PersonGroupsCell from 'web/people/components/peopleTable/personGroupsCell';
import { Regex } from 'web/libs/constants';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';
import PersonCell from 'table/modules/cells/personCell';
import {
  navigateToComposeWindow,
  navigateToCampaignDetailsTab,
} from 'web/services/routerService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import caseFunctional from 'web/commandCenter/helpers/caseFunctional';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';
import { CampaignsSpecialCategories } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';

const handleNavigateToCampaignSetupTab = (rowData, event) => {
  const { workflow_id: workflowId } = rowData;

  navigateToCampaignDetailsTab(CampaignsSpecialCategories.all.id, workflowId);

  if (event) {
    event.stopPropagation();
  }
};

const _getTaskDueDateCellComponent = (rowData) => {
  const { task_due_date: taskDueDate } = rowData;

  if (taskDueDate) {
    const dueDate = moment(taskDueDate);
    const currentDate = moment().startOf('d');

    return dueDate.isSame(currentDate, 'd') ? (
      <FormattedMessage id="common.today" />
    ) : (
      dueDate.format(I18N.DateFormats.DATE_SLASH)
    );
  }
  return null;
};

const taskTypeProxyComponent = (props) => {
  const {
    rowData,
    rowData: { task_type: taskType },
  } = props;
  const proxyProps = {
    ...props,
    rowData: { ...rowData, remind_type: taskType },
  };
  return taskType ? <TypeCell {...proxyProps} /> : null;
};

const groupEmailProxyComponent = (params) => {
  const {
    rowData: { parent_pitch_id: parentPitchId },
  } = params;
  return <CheckmarkCell checked={!!parentPitchId} />;
};

const personProxyComponent = (params) => {
  const {
    className,
    openPersonDetails,
    rowData: { email, name, person_id: personId, id: emailId },
  } = params;
  return (
    <PersonCell
      className={className}
      emailId={emailId}
      id={personId}
      openPersonDetails={openPersonDetails}
      subTitle={email}
      title={getLocalizedDateFromGroupName(name)}
    />
  );
};

const nameProxyComponent = (params) => {
  const {
    rowData: { name },
  } = params;
  return (
    <div className="text-overflow">{getLocalizedDateFromGroupName(name)}</div>
  );
};

const emailStatusComponent = (params) => {
  const { rowData } = params;
  const { email } = rowData;
  const isNonGroupPitch = email.match(Regex.email);

  return !isNonGroupPitch ? (
    <h4 className="cell-person-title text-overflow flex flex--align-middle">
      <FormattedMessage id="web.emails.groupEmail" />
    </h4>
  ) : (
    <EmailStatusCell rowData={rowData} />
  );
};

const getEmailViews = (rowData) => {
  const { circleEvents } = rowData;
  return circleEvents.open.eventCount;
};

const getEmailClicks = (rowData) => {
  const { circleEvents } = rowData;
  return circleEvents.click.eventCount;
};

const getEmailReplies = (rowData) => {
  const { circleEvents } = rowData;
  return circleEvents.reply.eventCount;
};

class EmailGridColumnFactory {
  constructor(
    sortColumn,
    sortColumnOpposite,
    formatMessage,
    actionCreators,
    subTab
  ) {
    this.baseColumns = [
      {
        flex: true,
        id: 'person',
        name: formatMessage({ id: 'common.person' }),
        rowCell: {
          component: personProxyComponent,
          metadata: {
            openPersonDetails: actionCreators.openPersonDetails,
          },
        },
        width: 175,
      },
      {
        flex: true,
        id: 'name',
        name: formatMessage({ id: 'common.name' }),
        rowCell: {
          component: nameProxyComponent,
          metadata: {
            property: 'name',
            wrapLines: 2,
          },
        },
        sorting: sortColumn.bind(this, 'name'),
        width: 175,
      },
      {
        flex: true,
        id: 'address',
        initHide: false,
        name: formatMessage({ id: 'common.address' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'email',
            wrapLines: 2,
          },
        },
        sorting: sortColumn.bind(this, 'addresses'),
        width: 200,
      },
      {
        flex: true,
        id: 'campaign',
        initHide: false,
        name: formatMessage({ id: 'common.campaign' }),
        rowCell: {
          component: LinkCell,
          metadata: {
            formatText: (data) => get(data, 'workflow_name', ''),
            onClick: handleNavigateToCampaignSetupTab,
          },
        },
        width: 200,
        sorting: sortColumn.bind(this, 'workflow_name'),
      },
      {
        flex: true,
        id: 'template',
        initHide: true,
        name: formatMessage({ id: 'common.template' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'template_name',
          },
        },
        sorting: sortColumn.bind(this, 'template_name'),
        width: 200,
      },
      {
        flex: true,
        id: 'subject',
        initHide: false,
        name: formatMessage({ id: 'common.subject' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'subject',
            wrapLines: 2,
          },
        },
        width: 200,
      },
      {
        flex: true,
        id: 'groups',
        initHide: false,
        name: formatMessage({ id: 'common.groups' }),
        rowCell: {
          component: PersonGroupsCell,
          metadata: {
            idProperty: 'person.id',
            openPersonGroupsModal: actionCreators.openPersonGroupsModal,
            wrapLines: 2,
          },
        },
        width: 350,
      },
      {
        flex: true,
        id: 'job-title',
        initHide: false,
        name: formatMessage({ id: 'createAccount.form.label.jobTitle' }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) => get(data, 'person.title'),
          },
        },
        width: 200,
      },
      {
        flex: true,
        id: 'company',
        initHide: false,
        name: formatMessage({ id: 'dynamicFields.company' }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) => get(data, 'person.company'),
          },
        },
        width: 200,
      },
      {
        flex: true,
        id: 'state-buttons',
        initHide: false,
        name: formatMessage({ id: 'web.emails.emailStatus' }),
        rowCell: {
          component: emailStatusComponent,
          metadata: {
            property: 'state',
            wrapLines: 2,
          },
        },
        width: 270,
      },
      {
        flex: true,
        id: 'date-created',
        initHide: false,
        name: formatMessage({ id: 'web.emails.dateCreated' }),
        rowCell: {
          component: DateAgoCell,
          metadata: {
            property: 'created_at',
            wrapLines: 2,
          },
        },
        sorting: sortColumn.bind(this, 'created_at'),
        width: 200,
      },
      {
        flex: true,
        id: 'event-views-count',
        initHide: true,
        name: formatMessage({ id: 'web.emails.viewCount' }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (rowData) => getEmailViews(rowData),
          },
        },
        width: 120,
      },
      {
        flex: true,
        id: 'event-clicks-count',
        initHide: true,
        name: formatMessage({ id: 'web.emails.clickCount' }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (rowData) => getEmailClicks(rowData),
          },
        },
        width: 120,
      },
      {
        flex: true,
        id: 'event-replies-count',
        initHide: true,
        name: formatMessage({ id: 'web.emails.replyCount' }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (rowData) => getEmailReplies(rowData),
          },
        },
        width: 120,
      },
      {
        flex: true,
        id: 'last-updated',
        initHide: false,
        name: formatMessage({ id: 'web.templates.lastUpdated' }),
        rowCell: {
          component: DateAgoCell,
          metadata: {
            property: 'last_updated',
            wrapLines: 2,
          },
        },
        sorting: sortColumn.bind(this, 'last_updated'),
        width: 200,
      },
      {
        flex: true,
        id: 'delivery-channel',
        initHide: false,
        name: formatMessage({ id: 'common.deliveryChannel' }),
        rowCell: {
          component: DeliveryChannelCell,
        },
        width: 200,
      },
      {
        flex: true,
        id: 'latest-activity',
        initHide: false,
        name: formatMessage({ id: 'web.emails.latestActivity' }),
        rowCell: {
          component: DateAgoCell,
          metadata: {
            property: 'last_event_at',
            wrapLines: 2,
          },
        },
        sorting: sortColumn.bind(this, 'last_event_at'),
        width: 200,
      },
      {
        flex: true,
        id: 'date-sent',
        initHide: false,
        name: formatMessage({ id: 'common.dateSent' }),
        rowCell: {
          component: DateAgoCell,
          metadata: {
            property: 'delivered_at',
            wrapLines: 2,
          },
        },
        sorting: sortColumn.bind(this, 'delivered_at'),
        width: 200,
      },
      {
        flex: true,
        id: 'followup-actions',
        initHide: false,
        name: formatMessage({ id: 'web.emails.followUpActions' }),
        rowCell: {
          component: EmailQuickActionsCell,
          metadata: {
            actionCreators,
          },
        },
        width: 150,
      },
      {
        flex: true,
        id: 'group-email',
        initHide: true,
        name: formatMessage({ id: 'web.emails.groupEmail' }),
        rowCell: {
          component: groupEmailProxyComponent,
        },
        width: 150,
      },
      {
        flex: true,
        id: 'task-due-date',
        initHide: true,
        name: formatMessage({ id: 'task.taskDueDate' }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (rowData) => _getTaskDueDateCellComponent(rowData),
          },
        },
        width: 150,
      },
      {
        flex: true,
        id: 'email-action',
        initHide: false,
        name: formatMessage({ id: 'common.emailsAction' }),
        rowCell: {
          component: ActionEmailCell,
          metadata: {
            actionCreators,
            commandCenterEmailsSubTab: subTab,
          },
        },
        width: 220,
      },
      {
        flex: true,
        id: 'task_type',
        initHide: true,
        name: formatMessage({ id: 'task.taskType' }),
        rowCell: {
          component: taskTypeProxyComponent,
          metadata: {
            onClick: (rowData) => {
              const { person_id: personId, task_type: taskType } = rowData;

              const getTask = caseFunctional({
                call: () => toutBackboneHelper.openCallModal(rowData),
                email: () => navigateToComposeWindow(personId),
                inmail: () => actionCreators.navigateToLinkedIn(rowData),
              })(actionCreators.openAddTask);

              getTask(taskType)();
            },
          },
        },
        width: 150,
      },
    ];
  }

  getColumnsForSubTab(subTab, defaultColumnsIdList = []) {
    return this.baseColumns.map((x) => ({
      ...x,
      id: `${subTab}_${x.id}`,
      initHide: !defaultColumnsIdList.includes(x.id),
    }));
  }
}

export default (
  sortColumn,
  sortColumnOpposite,
  formatMessage,
  actionCreators,
  subTab
) => {
  const columnFactory = new EmailGridColumnFactory(
    sortColumn,
    sortColumnOpposite,
    formatMessage,
    actionCreators,
    subTab
  );

  switch (subTab) {
    case CommandCenterEmailsTabsEnum.sent.subTabs.archived.value: {
      const defaultColumnsId = [
        'person',
        'campaign',
        'subject',
        'state-buttons',
        'event-clicks-count',
        'event-replies-count',
        'event-views-count',
        'date-sent',
        'followup-actions',
      ];
      const columns = columnFactory.getColumnsForSubTab(
        subTab,
        defaultColumnsId
      );
      remove(columns, (x) => x.id === 'email-action');
      return columns;
    }
    case CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value: {
      const columns = columnFactory.getColumnsForSubTab(subTab, [
        'person',
        'campaign',
        'subject',
        'state-buttons',
        'date-created',
      ]);
      return [
        ...columns,
        {
          flex: true,
          id: 'date-scheduled',
          initHide: false,
          name: formatMessage({ id: 'web.emails.scheduledFor' }),
          rowCell: {
            component: DateAgoCell,
            metadata: {
              property: 'send_at',
              wrapLines: 2,
            },
          },
          sorting: sortColumn.bind(this, 'send_at'),
          width: 200,
        },
      ];
    }
    case CommandCenterEmailsTabsEnum.pending.subTabs.drafts.value:
    case CommandCenterEmailsTabsEnum.pending.subTabs.inprogress.value:
      return columnFactory.getColumnsForSubTab(subTab, [
        'person',
        'campaign',
        'subject',
        'state-buttons',
        'date-created',
      ]);
    case CommandCenterEmailsTabsEnum.undelivered.subTabs.failed.value:
    case CommandCenterEmailsTabsEnum.undelivered.subTabs.spam.value:
    case CommandCenterEmailsTabsEnum.undelivered.subTabs.bounced.value: {
      const columns = columnFactory.getColumnsForSubTab(subTab, [
        'person',
        'campaign',
        'subject',
        'state-buttons',
        'date-failed',
        'date-created',
      ]);
      return [
        ...columns,
        {
          flex: true,
          id: 'date-failed',
          initHide: false,
          name: formatMessage({
            id: 'web.commandCenter.advancedSearches.referenceDate.failedAt',
          }),
          rowCell: {
            component: DateAgoCell,
            metadata: {
              property: 'undelivered_at',
              wrapLines: 2,
            },
          },
          sorting: sortColumn.bind(this, 'undelivered_at'),
          width: 200,
        },
      ];
    }
    case CommandCenterEmailsTabsEnum.sent.subTabs.delivered.value:
    default:
      return columnFactory.getColumnsForSubTab(subTab, [
        'person',
        'campaign',
        'subject',
        'state-buttons',
        'event-clicks-count',
        'event-replies-count',
        'event-views-count',
        'date-sent',
        'followup-actions',
      ]);
  }
};
