import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Switch from 'components/buttons/switch';

const Templates = (props) => {
  const {
    intl: { formatMessage },
    actionCreators: { updateTemplatesEditingDisabled },
    isTemplatesEditingDisabled,
  } = props;

  const switchLabel = formatMessage({
    id: 'web.settings.adminSettings.general.contentLockDown.templates.feature',
  });

  return (
    <div className="margin-top-20">
      <Switch
        checked={isTemplatesEditingDisabled}
        onChange={updateTemplatesEditingDisabled}
        name="disable_templates_editing"
        label={switchLabel}
        rightLabel
      />
    </div>
  );
};

Templates.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isTemplatesEditingDisabled: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(Templates);
