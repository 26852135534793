import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CampaignDayStep from '../campaignDayStep';
import LoadingSpinner from 'components/loadingSpinner';
import CampaignDayCardNewStep from './campaignDayCardNewStep';
import CampaignDayCardHeader from './campaignDayCardHeader';
import CampaignStepDropZone from 'web/campaigns/campaignsTab/components/campaignStepDropZone/campaignStepDropZone';
import { injectIntl, intlShape } from 'react-intl';
import './campaignDayCard.scss';

class CampaignDayCard extends Component {
  state = { openNewStep: false };

  setNewStep = (value) => {
    this.setState({ openNewStep: value });
  };

  _getCardStyle = () => {
    const { dayEditingCount, wide } = this.props;
    const { openNewStep } = this.state;
    if (!wide && (dayEditingCount || openNewStep)) {
      const NARROW_WIDTH = '790px';
      return {
        minWidth: NARROW_WIDTH,
        width: NARROW_WIDTH,
      };
    } else {
      return {
        minWidth: '',
        width: '',
      };
    }
  };

  getNoop = (steps, index) => {
    if (index === steps.length - 1) {
      return [steps[index].order, steps[index].order + 1];
    }
    return [steps[index].order, steps[index + 1].order];
  };

  render() {
    const {
      attachments,
      actionCreators,
      campaignEditStates,
      campaignTemplates,
      ckeBodies,
      day,
      editingSteps,
      firstEmailStepId,
      id,
      loading,
      newDayNumber,
      selectedCampaign,
      steps,
      timezone,
      timezones,
      type,
      wide,
      userCanEditCampaigns,
    } = this.props;
    let currentOrder = -1;

    return (
      <div
        className={classNames('campaign-day-card', { wide })}
        style={this._getCardStyle()}
        id={id || ''}
      >
        {loading ? <LoadingSpinner imageUrl={actionCreators.imageUrl} /> : null}
        <div
          className={classNames('campaign-day-card-header-container', { wide })}
        >
          <CampaignDayCardHeader
            day={day}
            actionCreators={actionCreators}
            type={type}
            newDayNumber={newDayNumber}
            selectedCampaign={selectedCampaign}
            steps={steps}
            wide={wide}
          />
        </div>
        <div
          className={classNames('campaign-day-card-body-container', { wide })}
        >
          {steps.map((element, index) => {
            currentOrder = element.order;
            return (
              <span key={`campaign-day-step-${element.id}`}>
                <CampaignStepDropZone
                  actionCreators={actionCreators}
                  day={day || newDayNumber}
                  hideNoDrop={!wide || index === 0}
                  order={currentOrder}
                />
                <CampaignDayStep
                  attachments={attachments}
                  actionCreators={actionCreators}
                  campaignEditStates={campaignEditStates}
                  campaignTemplates={campaignTemplates}
                  ckeBodies={ckeBodies}
                  editingSteps={editingSteps}
                  isFirstEmailStep={
                    element.id === firstEmailStepId && element.day === 1
                  }
                  step={element}
                  timezone={timezone}
                  timezones={timezones}
                  selectedCampaign={selectedCampaign}
                  noop={this.getNoop(steps, index)}
                  wide={wide}
                  userCanEditCampaigns={userCanEditCampaigns}
                />
              </span>
            );
          })}
          <CampaignStepDropZone
            actionCreators={actionCreators}
            day={day || newDayNumber}
            hideNoDrop={!wide || steps.length === 0}
            order={currentOrder + 1}
          />
          {userCanEditCampaigns ? (
            <CampaignDayCardNewStep
              attachments={attachments}
              actionCreators={actionCreators}
              campaignEditStates={campaignEditStates}
              campaignTemplates={campaignTemplates}
              ckeBodies={ckeBodies}
              day={day}
              isFirstEmailStep={!firstEmailStepId && newDayNumber === 1}
              newDayNumber={newDayNumber}
              selectedCampaign={selectedCampaign}
              setNewStep={this.setNewStep}
              steps={steps}
              timezone={timezone}
              timezones={timezones}
              wide={wide}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

CampaignDayCard.propTypes = {
  campaignEditStates: PropTypes.object.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  attachments: PropTypes.object.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  day: PropTypes.number,
  dayEditingCount: PropTypes.number,
  editingSteps: PropTypes.object,
  firstEmailStepId: PropTypes.number,
  id: PropTypes.string,
  loading: PropTypes.bool,
  editingDay: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  intl: intlShape, // eslint-disable-line react/no-unused-prop-types
  wide: PropTypes.bool,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignDayCard.defaultProps = {
  day: undefined,
  dayEditingCount: undefined,
  editingSteps: undefined,
  firstEmailStepId: undefined,
  id: undefined,
  loading: undefined,
  editingDay: undefined,
  newDayNumber: undefined,
  wide: undefined,
  intl: undefined,
  userCanEditCampaigns: true,
};

export default injectIntl(CampaignDayCard);
