/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { SubmissionError } from 'redux-form';
import { Regex } from 'web/libs/constants';
import ForgotPassword from '../components/forgotPassword';
import PasswordRequestReceived from '../components/passwordRequestReceived';
import unauthorizedAdobePage from 'components/hocs/unauthorizedAdobePage';
import {
  sendResetPasswordRequest,
  initializeForgotPasswordPanel,
} from 'web/forgotPassword/actionCreators/forgotPasswordActionCreators';
import './forgotPasswordContainer.scss';

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    props.actionCreators.initializeForgotPasswordPanel();
  }

  render() {
    const { requestSent, forgotPasswordSentEmail, actionCreators } = this.props;

    return (
      <div className="forgot-password-panel">
        {!requestSent ? (
          <ForgotPassword
            onSubmit={this.onSubmitResetPassword}
            {...this.props}
          />
        ) : (
          <PasswordRequestReceived
            actionCreators={actionCreators}
            forgotPasswordSentEmail={forgotPasswordSentEmail}
          />
        )}
      </div>
    );
  }

  onSubmitResetPassword = ({ email }) => {
    if (isEmpty(email)) {
      throw new SubmissionError({
        email: 'login.forgotPassword.error.emptyResetPasswordEmail',
      });
    } else if (!email.match(Regex.email)) {
      throw new SubmissionError({
        email: 'common.invalidEmail',
      });
    } else {
      return this.props.actionCreators.sendResetPasswordRequest(email);
    }
  };
}

ForgotPasswordContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  alertTextId: PropTypes.string,
  forgotPasswordSentEmail: PropTypes.string,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  requestSent: PropTypes.bool.isRequired,
};

ForgotPasswordContainer.defaultProps = {
  alertTextId: '',
  forgotPasswordSentEmail: '',
};

const mapStateToProps = (state) => ({
  alertTextId: state.forgotPasswordAlertTextId,
  forgotPasswordSentEmail: state.forgotPasswordSentEmail,
  loading: state.forgotPasswordRequestLoading,
  requestSent: state.forgotPasswordIsRequestSent,
});

const mapDispatchToProps = {
  initializeForgotPasswordPanel,
  sendResetPasswordRequest,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  (component) =>
    unauthorizedAdobePage(component, {
      parentClassName: 'forgot-password-container',
      titleKey: 'login.forgotPassword.documentTitle',
    }),
  injectIntl
)(ForgotPasswordContainer);
