import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PureList from 'components/pureList';
import ActionableFeature from 'web/settings/emailConnection/components/actionableFeature/actionableFeature';
import './emailConnectionFeatureList.scss';

class EmailConnectionFeaturesList extends Component {
  constructor(props) {
    super(props);

    this.items = [
      {
        text: 'web.settings.emailConnection.featureList.deliveryChannel',
        icon: 'tout-icon-envelope',
      },
      {
        text: 'web.settings.emailConnection.featureList.bulkEmails',
        icon: 'tout-icon-double-email',
      },
      {
        text: 'web.settings.emailConnection.featureList.trackReplies',
        icon: 'tout-icon-replyrate',
      },
      {
        text: 'web.settings.emailConnection.featureList.scheduleEmails',
        icon: 'tout-icon-schedule',
      },
    ];
  }

  render() {
    const {
      isConnected,
      actions,
      actionableFeatures,
      actionableFeaturesTexts,
    } = this.props;
    const { sendCompliance } = actionableFeatures;
    const { toggleSendCompliance } = actions;
    const { sendComplianceText } = actionableFeaturesTexts;

    return (
      <div>
        <PureList items={this.items} itemRenderer={this.itemRenderer} />
        <ActionableFeature
          action={toggleSendCompliance}
          iconClass="tout-icon-doc"
          isConnected={isConnected}
          actionableFeature={sendCompliance}
          textNode={sendComplianceText}
        />
      </div>
    );
  }

  itemRenderer = (item, index) => {
    const { isConnected } = this.props;
    const colorClass = isConnected ? 'email-connected' : 'email-not-connected';

    return (
      <li
        className="email-connection-features"
        key={`email-connection-features-list-${index}`}
      >
        <i
          className={classNames(
            item.icon,
            colorClass,
            'email-connection-icons'
          )}
        />
        <span className="features-description">
          <FormattedMessage id={item.text} />
        </span>
      </li>
    );
  };
}

EmailConnectionFeaturesList.propTypes = {
  actionableFeatures: PropTypes.object,
  actionableFeaturesTexts: PropTypes.object,
  actions: PropTypes.object,
  isConnected: PropTypes.bool,
};

EmailConnectionFeaturesList.defaultProps = {
  actionableFeatures: {},
  actionableFeaturesTexts: {},
  actions: {},
  isConnected: false,
};

export default EmailConnectionFeaturesList;
