import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from 'components/selects/default';

const OptInReasons = ({ items, selected, onChange }) => (
  <div className="opt-in-reasons">
    <div className="text-medium margin-bottom-quarter">
      <FormattedMessage id="web.person.unsubscribe.optInReasons.title" />
    </div>
    <Select
      items={items}
      onChange={onChange}
      selected={selected}
      propertyLabel="label"
    />
  </div>
);

OptInReasons.defaultProps = {
  selected: null,
};

OptInReasons.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

export default OptInReasons;
