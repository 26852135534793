import {
  EmailStates,
  CommandCenterEmailsTabsEnum,
  STATUS_REQUEST_MAP,
} from 'web/commandCenter/libs/commandCenterConstants';
import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import {
  DateRanges,
  DateRangesOptions,
} from 'web/tables/libs/tablesFiltersConstants';
import { PitchStates } from 'web/emails/libs/emailsConstants';

export const getUniqPeopleIdsFromEmails = (
  emails = [],
  selectedEmailIds = []
) =>
  emails.reduce((accumulator, email = {}) => {
    if (
      selectedEmailIds.includes(String(email.id)) &&
      !accumulator.includes(email.person_id)
    ) {
      accumulator.push(email.person_id);
    }
    return accumulator;
  }, []);

export const canBeMarkedSuccessful = (state) => {
  const MARK_SUCCESS_ACCESSIBLE_STATES = [
    EmailStates.sent,
    EmailStates.viewed,
    EmailStates.replied,
    EmailStates.clicked,
  ];
  return MARK_SUCCESS_ACCESSIBLE_STATES.some(
    (accessibleState) => accessibleState === state
  );
};

export const getLastEventDate = (groupEvents) => {
  if (groupEvents && groupEvents.length) {
    const lastDate = maxBy(
      groupEvents.map((x) => new Date(x.created_at)),
      (date) => date
    );
    return lastDate;
  }

  return null;
};

export const getEventGroups = (trackingEvents) =>
  groupBy(trackingEvents.filter((e) => !e.my_event), (e) => e.event);

const getEmailsGridDateRanges = (dateRangeOption = DateRangesOptions.past) =>
  DateRanges[dateRangeOption];

export const getEmailsGridDateRangesByStatus = (isScheduleStatus = false) => {
  const dateRangeOption = isScheduleStatus
    ? DateRangesOptions.future
    : DateRangesOptions.past;

  return getEmailsGridDateRanges(dateRangeOption);
};

export const getActiveEmailTableIdByTabs = (tab, subTab) =>
  `${tab}_${subTab}_table_id`;

export const getColumnsSettingsTableId = (tableId) =>
  `columns_settings_table_${tableId}`;

export const getSentStatusFilterFromSubTabs = (tabId, subTabIds) => {
  if (tabId === CommandCenterEmailsTabsEnum.sent.value) {
    const disabledPrefix = 'not';
    const filters = {};
    const notFilters = {};
    const stateFilters = [
      EmailStates.sent,
      EmailStates.clicked,
      EmailStates.replied,
      EmailStates.successful,
      EmailStates.viewed,
    ];
    if (subTabIds.length === 0) {
      return {
        filters: {
          state: stateFilters,
        },
        notFilters,
      };
    }
    subTabIds.forEach((subTabId) => {
      const key = subTabId.toLowerCase().replace(disabledPrefix, '');
      const value = subTabId.includes(disabledPrefix);
      if (value) {
        notFilters[key] = true;
      } else {
        filters[key] = true;
      }
    });
    return {
      filters: {
        state: stateFilters,
        ...filters,
      },
      notFilters: {
        ...notFilters,
      },
    };
  }
  return {
    filters: {
      state: subTabIds.map((subTabId) => STATUS_REQUEST_MAP[subTabId]),
    },
    notFilters: {},
  };
};

export const isSavedSearchNameEmpty = (savedSearchName) =>
  !savedSearchName || savedSearchName.length === 0;

export const isSavedSearchExists = (newSavedSearchName, savedSearches) =>
  Object.values(savedSearches)
    .map(({ name }) => name)
    .includes(newSavedSearchName);

export const parseUnreadEmailsCounters = (emailsCounters) => {
  const totalUndelivered =
    emailsCounters[EmailStates.bounced] +
    emailsCounters[PitchStates.failedDelivery] +
    emailsCounters[EmailStates.spam];

  const totalPending =
    emailsCounters[EmailStates.draft] +
    emailsCounters[EmailStates.deliveryInProgress] +
    emailsCounters[EmailStates.scheduledForDelivery];

  const totalSent =
    emailsCounters[EmailStates.sent] +
    emailsCounters[EmailStates.viewed] +
    emailsCounters[EmailStates.replied] +
    emailsCounters[EmailStates.successful] +
    emailsCounters[EmailStates.clicked];

  return {
    ...emailsCounters,
    delivered: totalSent,
    pending: totalPending,
    sent: totalSent,
    undelivered: totalUndelivered,
  };
};
