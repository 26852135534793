import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TeamMembersPopupIds } from '../../../libs/teamMembersConstants';
import DeleteTeamMembersConfirmationPopup from '../deleteTeamMembersConfirmationPopup';
import AddMembersToTeamsPopup from '../addMembersToTeamsPopup';
import AddMembersToTeamPopup from '../addMembersToTeamPopup';
import EditTeamsPopup from '../editTeamsPopup';
import popupWithErrors from 'components/hocs/popupWithErrors';

class TeamMembersPopup extends Component {
  render() {
    const { popup } = this.props;

    switch (popup) {
      case TeamMembersPopupIds.deleteTeamMembersConfirmation:
        return <DeleteTeamMembersConfirmationPopup {...this.props} />;
      case TeamMembersPopupIds.addTeamMembersToTeams:
        return <AddMembersToTeamsPopup {...this.props} />;
      case TeamMembersPopupIds.addTeamMembersToTeam:
        return <AddMembersToTeamPopup {...this.props} />;
      case TeamMembersPopupIds.editTeams:
        return <EditTeamsPopup {...this.props} />;
      default:
        return null;
    }
  }
}

TeamMembersPopup.propTypes = {
  popup: PropTypes.any,
};

TeamMembersPopup.defaultProps = {
  popup: null,
};

export default popupWithErrors(TeamMembersPopup);
