import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Form, Field, reduxForm } from 'redux-form';
import Action, { ButtonColors } from 'components/buttons/action';
import { email as emailValidatorFactory } from 'web/form/helpers/validators';
import Text from 'web/form/components/fields/textWithErrors';

class AutoBccForm extends Component {
  constructor(props) {
    super(props);
    this.emailValidator = emailValidatorFactory();
  }

  render() {
    const {
      intl: { formatMessage },
      pristine,
      submitting,
      invalid,
      handleSubmit,
      bccIntegration,
      isHideConnectBtn,
      openDeleteBccConfirmation,
    } = this.props;
    const isSubmitDisabled = pristine || submitting || invalid;

    return (
      <Form
        className="auto-bcc-form margin-0"
        onSubmit={handleSubmit}
        noValidate
      >
        <Field
          className="bcc-form-field margin-top-20"
          component={Text}
          name="bcc"
          placeholder={formatMessage({
            id:
              'web.settings.accountSettings.integrations.bcc.form.placeholder',
          })}
          validate={[this.emailValidator]}
        />
        {!isHideConnectBtn ? (
          <div className="card-buttons margin-top-20 right">
            {bccIntegration.bcc && (
              <Action
                color={ButtonColors.red}
                disabled={submitting}
                onClick={openDeleteBccConfirmation}
                type="button"
              >
                <FormattedMessage id="common.disconnect" />
              </Action>
            )}
            {bccIntegration.bcc &&
              !pristine && (
                <Action
                  className="margin-left-15"
                  disabled={isSubmitDisabled}
                  type="submit"
                >
                  <FormattedMessage id="common.update" />
                </Action>
              )}
            {!bccIntegration.bcc && (
              <Action disabled={isSubmitDisabled} type="submit">
                <FormattedMessage id="common.connect" />
              </Action>
            )}
          </div>
        ) : null}
      </Form>
    );
  }
}

AutoBccForm.propTypes = {
  bccIntegration: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  invalid: PropTypes.bool.isRequired,
  isHideConnectBtn: PropTypes.bool,
  openDeleteBccConfirmation: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

AutoBccForm.defaultProps = {
  isHideConnectBtn: false,
};

export default compose(
  reduxForm({
    enableReinitialize: true,
  }),
  injectIntl
)(AutoBccForm);
