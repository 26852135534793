import { baseGet, basePut } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const getMarketoCampaigns = (campaignParams = {}) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.marketoCampaigns, campaignParams, resolve, reject);
  });

const buildLaunchPayload = ({ leadIds = [] } = {}) => {
  const leads = leadIds.map((id) => ({ id }));

  return {
    input: {
      leads,
    },
  };
};

export const launchMarketoCampaign = (campaignParams = {}) => {
  const { campaignId } = campaignParams;

  return new Promise((resolve, reject) => {
    const payload = buildLaunchPayload(campaignParams);
    basePut(
      Urls.marketoCampaignsLaunch.replace(':#id', campaignId),
      payload,
      resolve,
      reject
    );
  });
};
