import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './favoriteCell.scss';

class FavoriteCell extends Component {
  render() {
    const { rowData, property, onClick } = this.props;
    const favorite = rowData[property];
    return (
      <div
        onClick={onClick && this.favoriteClick}
        className={
          (favorite && 'templates-table-favorite-filled') ||
          'templates-table-favorite-unfilled'
        }
      />
    );
  }

  favoriteClick = (e) => {
    const { rowData, property, onClick } = this.props;
    const favorite = rowData[property];
    e.stopPropagation();
    e.preventDefault();
    onClick(!favorite, rowData);
  };
}

FavoriteCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

FavoriteCell.defaultProps = {
  onClick: () => {},
};

export default FavoriteCell;
