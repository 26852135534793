import {
  getBccIntegration,
  updateBccIntegration,
  deleteBccIntegration,
} from 'web/integrations/bcc/actionCreators/bccActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  IntegrationsAlertIds,
  IntegrationsPopupIds,
} from '../libs/integrationConstants';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';

export const getIntegration = () => (dispatch) => dispatch(getBccIntegration());
export const updateIntegration = (data) => (dispatch) =>
  dispatch(updateBccIntegration(data))
    .then(() => {
      dispatch(openViewAlert(IntegrationsAlertIds.success));
    })
    .catch(() => {
      dispatch(openViewAlert(IntegrationsAlertIds.failure));
    });

export const deleteIntegration = () => (dispatch) =>
  dispatch(deleteBccIntegration())
    .then(() => {
      dispatch(openViewAlert(IntegrationsAlertIds.success));
    })
    .catch(() => {
      dispatch(openViewAlert(IntegrationsAlertIds.failure));
    });

export const openDeleteBccConfirmation = () => (dispatch) =>
  dispatch(openPopup(IntegrationsPopupIds.deleteBcc));
