/*eslint complexity: [1, 14]*/
export function parseIdentity(identity) {
  return {
    ...identity,
    isDefault: identity.default_identity || false,
    name: identity.name || '',
    unverified: !identity.verified,
  };
}

export function parseIdentities(data = []) {
  const parsedData = [];

  data.forEach((identity) => {
    const parsed = parseIdentity(identity);
    if (parsed.isDefault) {
      parsedData.unshift(parsed);
    } else {
      parsedData.push(parsed);
    }
  });

  return parsedData;
}
