import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { CampaignListFilters } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import Select from 'components/select';
import './campaignContentHeader.scss';

const CampaignContentHeader = (props) => {
  const {
    titleOne,
    titleTwo,
    filter,
    onFilter,
    intl: { formatMessage },
    account: { admin },
  } = props;
  const filterOptions = [
    {
      value: CampaignListFilters.all,
      label: formatMessage({ id: 'common.all' }),
    },
    {
      value: CampaignListFilters.active,
      label: formatMessage({ id: 'common.active' }),
    },
    {
      value: CampaignListFilters.inactive,
      label: formatMessage({ id: 'common.inactive' }),
    },
    {
      value: CampaignListFilters.mine,
      label: formatMessage({ id: 'common.mine' }),
    },
    {
      value: CampaignListFilters.shared,
      label: formatMessage({ id: 'common.shared' }),
    },
    ...(admin
      ? [
          {
            value: CampaignListFilters.unshared,
            label: formatMessage({ id: 'common.unshared' }),
          },
        ]
      : []),
  ];

  return (
    <div className="campaign-content-header">
      <div className="cch-select">
        <div className="cch-select-label">
          {formatMessage({
            id: 'web.campaigns.campaignContentHeader.filter',
          })}
        </div>
        <Select
          items={filterOptions}
          onChange={onFilter}
          selected={filter}
          rowsShown={filterOptions.length}
        />
      </div>
      <div className="cch-title-one">
        <span className="cch-title-padding">{titleOne}</span>
      </div>
      <div className="cch-title-two">
        <span className="cch-title-padding">{titleTwo}</span>
      </div>
    </div>
  );
};

CampaignContentHeader.propTypes = {
  intl: intlShape.isRequired,
  onFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  titleOne: PropTypes.string,
  titleTwo: PropTypes.string,
  account: PropTypes.object.isRequired,
};

CampaignContentHeader.defaultProps = {
  titleOne: '',
  titleTwo: '',
};

export default injectIntl(CampaignContentHeader);
