import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { getCampaigns } from 'web/campaigns/campaignsTab/actionCreators/campaignsActionCreators';
import {
  getCampaignsCategories,
  selectCurrentCategory,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsCategoriesActionCreators';
import { getDynamicFields } from 'web/dynamicFields/actionCreators/dynamicFieldsActionCreators';
import { getGroups } from 'web/groups/actionCreators/groupsActionCreators';
import {
  getCampaignTemplates,
  getActiveCampaigns,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsTabDataActionCreators';
import { getIdentities } from 'web/user/actionCreators/identitiesActionCreators';
import { getTimezones } from 'web/timezones/actionCreators/timezonesActionCreators';
import { getInitTasks } from 'web/campaigns/tasks/actionCreators/tasksActionCreators';
import { LocalStorageKeys } from 'web/libs/constants';
import {
  selectCampaign,
  updateCampaignDetailsTab,
} from './campaignDetailsActionCreators';
import { getCampaignsTabSettings } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabSettingsActionCreators';
import { initPusher } from './campaignsPusherActionCreator';
import { CampaignDetailsEnum } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import {
  setViewer,
  setCampaignViewAsFilterInput,
} from 'web/campaigns/campaignsTab/actionCreators/campaignViewAsActionCreators';
import { getViewer } from 'web/campaigns/campaignsTab/helpers/campaignsHelpers';

export function setCurrentCategory(categoryId) {
  return (dispatch) => {
    if (categoryId) {
      dispatch(selectCurrentCategory(parseInt(categoryId, 10)));
    } else {
      const savedCategory = window.localStorage.getItem(
        LocalStorageKeys.campaigns.lastCategoryTab
      );
      const currentCategory = parseInt(savedCategory, 10)
        ? parseInt(savedCategory, 10)
        : -1;
      dispatch(selectCurrentCategory(currentCategory));
    }
  };
}

const initCampaignsViewer = () => (dispatch, getState) => {
  const {
    user: { id, name },
  } = getState();
  const viewer = getViewer(id, name);
  dispatch(setCampaignViewAsFilterInput(name));
  dispatch(setViewer(viewer));
};

export function setOrFetchCampaigns(campaignId, forcedFetch = false) {
  return async (dispatch, getState) => {
    const campaignIdInt = parseInt(campaignId, 10) || null;
    if (!getState().campaigns.length || forcedFetch) {
      await dispatch(getCampaigns(campaignIdInt));
    }
    if (getState().campaigns.length) {
      dispatch(selectCampaign(campaignIdInt));
    } else {
      dispatch(initCampaignsViewer());
    }
  };
}

export function setCampaignDetailsTab(tab) {
  return (dispatch) => {
    if (tab && Object.prototype.hasOwnProperty.call(CampaignDetailsEnum, tab)) {
      dispatch(updateCampaignDetailsTab(CampaignDetailsEnum[tab].value));
    }
  };
}

export const setSearchCampaignPeople = (value) => (dispatch) => {
  if (value) {
    dispatch({
      data: value,
      type: ActionTypes.campaignPeople.state.searchChange,
    });
  }
};

export function campaignsTabStartup(
  categoryId,
  campaignId,
  tab,
  selectedPerson
) {
  return (dispatch, getState) => {
    const { campaignsTabSelectedCampaign } = getState();

    if (
      campaignId &&
      parseInt(campaignId, 10) !== campaignsTabSelectedCampaign
    ) {
      dispatch(getCampaignTemplates(campaignId));
    }
    dispatch(commonStartup());

    dispatch(setCurrentCategory(categoryId));
    dispatch(setOrFetchCampaigns(campaignId));
    dispatch(setCampaignDetailsTab(tab));
    dispatch(setSearchCampaignPeople(selectedPerson));

    // Data that isn't immediately needed
    if (!getState().campaignsTabInitStore) {
      dispatch(initCampaignsViewer());
      dispatch(getCampaignsTabSettings());
      dispatch(getCampaignsCategories());
      setTimeout(() => {
        dispatch(initPusher());
        dispatch(getInitTasks());
        dispatch(getTimezones());
        dispatch(getIdentities());
        dispatch(getDynamicFields());
        dispatch(getActiveCampaigns());
        dispatch(getGroups());
        dispatch({ type: ActionTypes.campaignsTab.state.initStore });
      }, 0);
    }
  };
}
