import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from '../buttons/icon';
import Chevron, { ChevronColors } from 'components/buttons/chevron';
import { AlertIconTypes } from 'components/alert';
import './alertsCollapsible.scss';
import AlertTriangleFilled from '../icons/alertTriangleFilled';

const AlertsCollapsible = (props) => {
  const { textId, onClose, manual, delay, children, countErrors } = props;
  const [isOpen, setOpen] = useState(false);
  const timeout = !manual ? setTimeout(onClose, delay) : null;

  useEffect(
    () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      return () => clearTimeout(timeout);
    },
    [onClose]
  );

  return (
    <main className="alerts-collapsible">
      <input id="toggle" type="checkbox" />
      <label htmlFor="toggle" onClick={() => setOpen(!isOpen)}>
        <span className="toggle-wrapper">
          <i className="tout-alert-icon">
            <AlertTriangleFilled />
          </i>
          <FormattedMessage id={textId} />
        </span>
        <span className="toggle-wrapper">
          <span className="alerts-collapsible-count">
            <FormattedMessage
              id="web.composeWindow.pinnedCategories.errorsCount"
              values={{ countErrors }}
            />
          </span>
          <Chevron
            color={ChevronColors.white}
            direction={isOpen ? 'bottom' : 'right'}
          />
          <Icon classes="tout-icon-close" color="white" onClick={onClose} />
        </span>
      </label>
      <div id="expand">{children}</div>
    </main>
  );
};

AlertsCollapsible.propTypes = {
  children: PropTypes.element,
  countErrors: PropTypes.number,
  delay: PropTypes.number,
  icon: PropTypes.oneOf(['', ...Object.values(AlertIconTypes)]),
  manual: PropTypes.bool,
  onClose: PropTypes.func,
  textId: PropTypes.string.isRequired,
};

AlertsCollapsible.defaultProps = {
  countErrors: 0,
  delay: 8000,
  icon: '',
  manual: false,
  onClose: () => {},
};

export default AlertsCollapsible;
