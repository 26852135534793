import React from 'react';
import { FormattedMessage } from 'react-intl';
import './marketoSalesOptOutWarning.scss';

const MarketoSalesOptOutWarning = () => (
  <div className="marketo-sales-opt-out-warning">
    <FormattedMessage id="web.settings.unsubscribe.salesforce.marketoSalesOptOut.warning" />
  </div>
);

export default MarketoSalesOptOutWarning;
