import {
  UserManagementEvents,
  UserManagementActions,
} from 'web/libs/mixpanelEvents';
import {
  getInvitations,
  deleteInvitation,
  batchDestroyInvitations,
  resendInvitation,
  batchResendInvitations,
  inviteManyUsers,
} from 'web/invitations/actionCreators/invitationsActionCreators';
import { setInvitationsPusherCallbacks } from 'web/invitations/actionCreators/invitationsPusherActionCreators';
import { shouldInvalidate } from 'web/invitations/selectors/invitationsSelectors';
import {
  closePopup,
  openPopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { UserManagementAlertIds } from '../libs/userManagementConstants';
import { InviteesPopupIds } from '../libs/inviteesConstants';
import InviteesActionTypes from '../libs/inviteesActionTypes';
import { reCalculateOpenSeatsCount } from './usersActionCreators';
import { openSeatsCount as openSeatsCountSelector } from 'web/user/selectors/userSelectors';
import { inviteesTableExternals } from '../helpers/inviteesHelpers';
import { getWorkspaces } from 'web/marketoSettings/actionCreators/adminPopupActionCreators';
import { getMarketoIntegration } from 'web/marketoSettings/actionCreators/adminSetupActionCreators';
import {
  shouldInvalidateMarketoIntegration,
  shouldInvalidateWorkspaces,
  isMarketoAdmin,
} from 'web/marketoSettings/selectors/adminSetupSelectors';
import map from 'lodash/map';
import { getTeams } from 'web/teams/actionCreators/teamsActionCreators';
import { shouldInvalidate as shouldInvalidateTeams } from 'web/teams/selectors/teamsSelectors';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { openImportPeopleStepOne } from 'web/people/actionCreators/peoplePopupActionCreators';

export const setWithdrawInviteLoading = (loading = true) => ({
  type: InviteesActionTypes.setWithdrawInviteLoading,
  loading,
});

export const setWithdrawInvitesLoading = (loading = true) => ({
  type: InviteesActionTypes.setWithdrawInvitesLoading,
  loading,
});

export const setResendInviteLoading = (loading = true) => ({
  type: InviteesActionTypes.setResendInviteLoading,
  loading,
});

export const setResendInvitesLoading = (loading = true) => ({
  type: InviteesActionTypes.setResendInvitesLoading,
  loading,
});

export const setInviteUsersLoading = (loading = true) => ({
  type: InviteesActionTypes.setInviteUsersLoading,
  loading,
});

export const setMarketoWorkspacesLoading = (loading = true) => ({
  type: InviteesActionTypes.setMarketoWorkspacesLoading,
  loading,
});

export const updateInviteesTableFromCsv = (emails) => (dispatch, getState) => {
  const openSeatsCount = openSeatsCountSelector(getState());

  if (emails.length > openSeatsCount) {
    const seatsCount = Math.abs(emails.length - openSeatsCount);
    return dispatch(
      setPopupAlert(PeopleAlertIds.userInviteNeedMoreSeats, { seatsCount })
    );
  }

  const data = { emails, fromCsv: true };
  dispatch(inviteUsers(data));
};

export const inviteesStartup = () => (dispatch, getState) => {
  if (shouldInvalidate(getState())) {
    dispatch(getInvitations());
    dispatch(setInvitationsPusherCallbacks());
  }
  if (shouldInvalidateTeams(getState())) {
    dispatch(getTeams());
  }
};

export const openWithdrawInviteConfirmationPopup = ({ id }) => (dispatch) => {
  dispatch(
    openPopup(
      InviteesPopupIds.withdrawInviteConfirmation,
      { id },
      {
        analytics: {
          event: UserManagementEvents.userManagement,
          properties: {
            'Action Type':
              UserManagementActions.withdrawInviteConfirmationPopup,
          },
        },
      }
    )
  );
};

export const withdrawInvite = (id) => (dispatch) => {
  dispatch({
    type: InviteesActionTypes.withdrawInvite,
    payload: { id },
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.withdrawInvite,
        },
      },
    },
  });
  dispatch(setWithdrawInviteLoading());
  dispatch(deleteInvitation(id))
    .then(() => {
      inviteesTableExternals.clearSelectedIds();
      dispatch(closePopup());
      dispatch(reCalculateOpenSeatsCount());
    })
    .catch((errors) => {
      dispatch(
        openPopup(InviteesPopupIds.withdrawInviteConfirmation, {
          errors,
          id,
        })
      );
    })
    .finally(() => {
      dispatch(setWithdrawInviteLoading(false));
    });
};

export const openWithdrawInvitesConfirmationPopup = (ids) => (dispatch) => {
  dispatch(
    openPopup(
      InviteesPopupIds.withdrawInvitesConfirmation,
      {
        ids: map(ids, Number),
      },
      {
        analytics: {
          event: UserManagementEvents.userManagement,
          properties: {
            'Action Type':
              UserManagementActions.withdrawInvitesConfirmationPopup,
          },
        },
      }
    )
  );
};

export const withdrawInvites = (ids) => (dispatch) => {
  dispatch({
    type: InviteesActionTypes.withdrawInvites,
    payload: { ids },
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.withdrawInvites,
          Amount: ids.length,
        },
      },
    },
  });
  dispatch(setWithdrawInvitesLoading());
  dispatch(batchDestroyInvitations(ids))
    .then(() => {
      inviteesTableExternals.clearSelectedIds();
      dispatch(closePopup());
      dispatch(reCalculateOpenSeatsCount());
    })
    .catch((errors) => {
      dispatch(
        openPopup(InviteesPopupIds.withdrawInvitesConfirmation, {
          errors,
          ids,
        })
      );
    })
    .finally(() => {
      dispatch(setWithdrawInvitesLoading(false));
    });
};

export const resendInvite = (invitation) => (dispatch) => {
  dispatch({
    type: InviteesActionTypes.resendInvite,
    payload: { invitation },
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.resendInvite,
        },
      },
    },
  });
  dispatch(setResendInviteLoading());
  dispatch(resendInvitation(invitation.id))
    .then(() => {
      dispatch(
        openViewAlert(UserManagementAlertIds.resendInviteesSuccess, {
          invitationsCount: 1,
        })
      );
    })
    .catch(() => {
      dispatch(openViewAlert(UserManagementAlertIds.genericIssue));
    })
    .finally(() => {
      dispatch(setResendInviteLoading(false));
    });
};

export const resendInvites = (ids) => (dispatch) => {
  dispatch({
    type: InviteesActionTypes.resendInvites,
    payload: { ids },
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.resendInvites,
          Amount: ids.length,
        },
      },
    },
  });
  dispatch(setResendInvitesLoading());
  dispatch(batchResendInvitations(ids))
    .then(() => {
      inviteesTableExternals.clearSelectedIds();
      dispatch(
        openViewAlert(UserManagementAlertIds.resendInviteesSuccess, {
          invitationsCount: ids.length,
        })
      );
    })
    .catch(() => {
      dispatch(openViewAlert(UserManagementAlertIds.genericIssue));
    })
    .finally(() => {
      dispatch(setResendInvitesLoading(false));
    });
};

export const openInviteUsersPopup = () => (dispatch, getState) => {
  const openSeatsCount = openSeatsCountSelector(getState());
  if (openSeatsCount > 0) {
    dispatch(
      openPopup(
        InviteesPopupIds.inviteUsers,
        {},
        {
          analytics: {
            event: UserManagementEvents.userManagement,
            properties: {
              'Action Type': UserManagementActions.inviteUsersPopup,
            },
          },
        }
      )
    );
  } else {
    dispatch(openViewAlert(UserManagementAlertIds.notEnoughOpenSeats));
  }
};

export const openImportCsv = () => (dispatch, getState) => {
  const openSeatsCount = openSeatsCountSelector(getState());
  if (openSeatsCount > 0) {
    dispatch(openImportPeopleStepOne());
  } else {
    dispatch(openViewAlert(UserManagementAlertIds.notEnoughOpenSeats));
  }
};

export const openMoreSeatsPopup = () => (dispatch) => {
  dispatch(
    openPopup(
      InviteesPopupIds.needMoresSeats,
      {},
      {
        analytics: {
          event: UserManagementEvents.userManagement,
          properties: {
            'Action Type': UserManagementActions.needMoresSeatsPopup,
          },
        },
      }
    )
  );
};

export const inviteUsers = ({
  emails = [],
  workspaces = {},
  teamIds = [],
  fromCsv = false,
}) => (dispatch) => {
  dispatch({
    type: InviteesActionTypes.inviteUsers,
    payload: { emails, workspaces, teamIds },
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.inviteUsers,
          Amount: emails.length,
        },
      },
    },
  });
  dispatch(setInviteUsersLoading());
  if (fromCsv) {
    dispatch(setPopupLoading(true));
  }
  dispatch(inviteManyUsers({ emails, workspaces, teamIds }))
    .then((result = []) => {
      const successCount = result.filter(({ success }) => success).length;
      dispatch(openPopup(InviteesPopupIds.inviteUsersResult, { result }));
      if (successCount) {
        dispatch(reCalculateOpenSeatsCount());
      }
    })
    .catch((errors) => {
      dispatch(
        openPopup(InviteesPopupIds.inviteUsers, {
          errors,
        })
      );
    })
    .finally(() => {
      if (fromCsv) {
        dispatch(setPopupLoading(false));
      }
      dispatch(setInviteUsersLoading(false));
    });
};

export const getMarketoWorkspaces = () => async (dispatch, getState) => {
  try {
    dispatch(setMarketoWorkspacesLoading(true));

    if (shouldInvalidateMarketoIntegration(getState())) {
      await dispatch(getMarketoIntegration());
    }
    if (isMarketoAdmin(getState())) {
      if (shouldInvalidateWorkspaces(getState())) {
        await dispatch(getWorkspaces());
      }
    }
  } finally {
    dispatch(setMarketoWorkspacesLoading(false));
  }
};
