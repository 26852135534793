import React, { Component } from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import LabeledInputReduxForm from 'components/labeledInputReduxForm';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { JOB_TITLES, COUNTRIES } from '../../libs/createAccountConstants';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl, Urls } from 'web/libs/constants';
import Card from 'components/card';
import FormLayout from 'components/layouts/formLayout';
import FormTitle from 'components/layouts/formLayout/formTitle';
import FormError from 'components/layouts/formLayout/formError';
import FormButtonsArea from 'components/layouts/formLayout/formButtonsArea';
import passwordWithTooltip from 'web/form/hoc/passwordWithTooltip';
import { required as requiredValidatorFactory } from 'web/form/helpers/validators';
import { hasOnboardingFeatureFlag } from 'web/shared/helpers/featureFlagHelper';
import { navigateToUrl } from 'web/services/routerService';
import {
  allPasswordValidators,
  allConfirmPasswordValidators,
} from 'web/form/libs/passwordValidators';
import './createAccountOnboarding.scss';

class CreateAccountOnboarding extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
      match: {
        params: { invitation_code: invitationCode },
      },
    } = this.props;

    document.title = `${formatMessage({ id: 'common.marketoSales' })}`;

    this.isActiveMode = !invitationCode;

    const POSITION_LEFT = 'left';

    this.jobTitleItems = JOB_TITLES.map((titleId) => {
      const title = formatMessage({ id: titleId });
      return { label: title, value: title };
    });

    this.countryItems = COUNTRIES.map((countryId) => {
      const countryName = formatMessage({ id: countryId });
      return { label: countryName, value: countryName };
    });

    this.passwordFiledWithTooltip = passwordWithTooltip({
      place: POSITION_LEFT,
    })(LabeledInputReduxForm);

    this.firstNameRequiresValidator = requiredValidatorFactory(
      'createAccount.form.validate.firstNameRequired'
    );
    this.lastNameRequiredValidator = requiredValidatorFactory(
      'createAccount.form.validate.lastNameRequired'
    );
    this.jobTitleRequiredValidator = requiredValidatorFactory(
      'createAccount.form.validate.jobTitleRequired'
    );
    this.countryRequiredValidator = requiredValidatorFactory(
      'createAccount.form.validate.countryRequired'
    );
  }

  render() {
    const { error, loading, handleSubmit } = this.props;

    return (
      <div className="create-account-onboarding-form">
        <h1 className="create-account-onboarding-title">
          <FormattedMessage id="createAccountOnboarding.title" />
        </h1>
        <Card className="onboarding-create-account-card" width={300}>
          <FormLayout className="create-account-form-wrapper">
            <FormTitle id="createAccountOnboarding.form.title" />
            {loading && <LoadingSpinner imageUrl={getImageUrl} />}
            <Form onSubmit={handleSubmit(this.onSubmitCreateAccount)}>
              <div className="form-row">
                <Field
                  autoComplete="email"
                  autoFocus={false}
                  className="form-cell"
                  component={LabeledInputReduxForm}
                  id="user_email"
                  label="createAccountOnboarding.form.label.loginEmail"
                  name="email"
                  type="text"
                  disabled
                />
              </div>
              <div className="form-row">
                <Field
                  autoComplete="password"
                  autoFocus={false}
                  className="form-cell"
                  component={this.passwordFiledWithTooltip}
                  id="user_password"
                  label="common.password"
                  name="password"
                  type="password"
                  validate={allPasswordValidators}
                />
              </div>
              <div className="form-row password-fields">
                <Field
                  autoComplete="confirm_password"
                  autoFocus={false}
                  className="form-cell"
                  component={LabeledInputReduxForm}
                  id="user_confirm_password"
                  label="login.setPassword.confirmPassword"
                  name="confirm_password"
                  type="password"
                  validate={allConfirmPasswordValidators}
                />
              </div>
              <FormError>{error && <FormattedMessage id={error} />}</FormError>
              <FormButtonsArea align="right">
                <Button
                  className="create-account-button"
                  color={ButtonColors.blue}
                  disabled={loading}
                  size={ButtonSizes.medium}
                  type="submit"
                >
                  <FormattedMessage id="common.activate" />
                </Button>
              </FormButtonsArea>
            </Form>
          </FormLayout>
        </Card>
      </div>
    );
  }

  onSubmitCreateAccount = (values) => {
    const {
      actionCreators: { createAccountRequest, createAccountActivationRequest },
      match: {
        params: {
          invitation_code: invitationCode,
          activation_code: activationCode,
        },
      },
    } = this.props;

    return this.isActiveMode
      ? createAccountActivationRequest(values, activationCode)
      : createAccountRequest(values, invitationCode).then((userInfo) => {
          const {
            history,
            actionCreators: { setLoading },
          } = this.props;

          if (hasOnboardingFeatureFlag(userInfo)) {
            navigateToUrl(Urls.onboarding.welcome);
          } else {
            history.push(Urls.getStarted);
            setLoading(false);
          }
        });
  };
}

CreateAccountOnboarding.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      activation_code: PropTypes.string,
      invitation_code: PropTypes.string,
    }),
  }).isRequired,
};

CreateAccountOnboarding.defaultProps = {
  error: '',
  history: {},
};

export default reduxForm({
  form: 'create-account-onboarding-form',
})(injectIntl(CreateAccountOnboarding));
