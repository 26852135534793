import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OnboardingActionButtons from 'components/popups/popupFooter/popupFooter';
import { navigateToUrl } from 'web/services/routerService';
import { steps, stepsUrls } from 'web/onboarding/lib/stepsRouting';
import { setCompletedStep } from 'web/onboarding/actionCreators/onboardingActionCreators';

import './onboardingStepSuccess.scss';

const OnboardingStepSuccess = ({
  activeStep,
  successStepSetupTextId,
  setCompletedStep,
}) => {
  const [showSecondState, setShowSecondState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSecondState(true);
    }, 2000);
  }, []);

  const onPrimaryAction = () => {
    setCompletedStep(activeStep);
    const navigateUrl =
      activeStep === steps.length ? stepsUrls.finish : steps[activeStep].url;
    navigateToUrl(navigateUrl);
  };

  return (
    <React.Fragment>
      {showSecondState ? (
        <React.Fragment>
          <div className="onboarding-success-list-item">
            <span className="onboarding-success-list-item-icon" />
            <span>
              <FormattedMessage id={successStepSetupTextId} />
            </span>
          </div>
          <OnboardingActionButtons
            onPrimary={onPrimaryAction}
            primaryTextId={'common.next'}
            full
          />
        </React.Fragment>
      ) : (
        <div className="onboarding-success">
          <span className="onboarding-success-icon" />
          <FormattedMessage id={'web.onboarding.title.success'} />
        </div>
      )}
    </React.Fragment>
  );
};

OnboardingStepSuccess.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setCompletedStep: PropTypes.func.isRequired,
  successStepSetupTextId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  activeStep: state.onboarding.onboardingActiveStep,
});

const mapDispatchToProps = {
  setCompletedStep,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingStepSuccess);
