import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Table from 'table/modules/table';
import ActionsCell from 'table/modules/cells/actionsCell';
import TextCell from 'table/modules/cells/textCell';
import { TABLE_MAP_IMPORT_FIELDS_MAX_HEIGHT } from 'web/people/libs/peopleConstants';
import { navigateToGroupByGroup } from 'web/services/routerService';

const TABLE_PERSON_GROUPS = 'TABLE_PERSON_GROUPS';

export const PersonGroups = ({ intl: { formatMessage }, personGroupsList }) => {
  const getColumns = () => {
    const actions = [
      {
        label: formatMessage({ id: 'web.people.goToGroup' }),
        onClick: navigateToGroupByGroup,
      },
    ];

    return [
      {
        id: 'groupName',
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'name',
            wrapLines: 2,
          },
        },
        flex: true,
        width: 175,
      },
      {
        id: 'redirectLink',
        rowCell: {
          component: ActionsCell,
          metadata: {
            actions,
          },
        },
        flex: true,
        initHide: false,
        width: 200,
      },
    ];
  };

  return (
    <div className="table-person-groups">
      <Table
        tableId={TABLE_PERSON_GROUPS}
        columns={getColumns()}
        header={{ isVisible: false }}
        isHeader={false}
        items={personGroupsList}
        maxHeight={TABLE_MAP_IMPORT_FIELDS_MAX_HEIGHT}
      />
    </div>
  );
};
PersonGroups.propTypes = {
  intl: intlShape.isRequired,
  personGroupsList: PropTypes.array,
};

PersonGroups.defaultProps = {
  personGroupsList: undefined,
};

export default injectIntl(PersonGroups);
