// Enhances WrappedComponent with common modals that are used across the app
import React, { Component } from 'react';
import BulkUpdatePopupContainer from 'web/bulkUpdate/container/bulkUpdatePopupContainer';
import ReleaseNoteAnchorBoxContainer from 'web/releaseNotes/container/releaseNoteAnchorBoxContainer';
import EmailComposePopupContainer from 'web/emailComposeBody/emailComposePopupContainer';

export default (WrappedComponent) =>
  class extends Component {
    render() {
      return (
        <div className="with-common-modals">
          <BulkUpdatePopupContainer />
          <ReleaseNoteAnchorBoxContainer />
          <EmailComposePopupContainer />
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
