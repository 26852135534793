import DeliveryChannelsActionTypes from '../libs/deliveryChannelsActionTypes';

export function deliveryChannelsFetched(state = false, action) {
  switch (action.type) {
    case DeliveryChannelsActionTypes.setDeliveryChannels:
      return true;
    case DeliveryChannelsActionTypes.setDeliveryChannelsFetched:
      return action.fetched;
    default:
      return state;
  }
}

export function deliveryChannelsFetching(state = false, action) {
  switch (action.type) {
    case DeliveryChannelsActionTypes.setDeliveryChannels:
    case DeliveryChannelsActionTypes.setDeliveryChannelsError:
      return false;
    case DeliveryChannelsActionTypes.setDeliveryChannelsFetching:
      return true;
    default:
      return state;
  }
}

export function deliveryChannels(state = [], action) {
  switch (action.type) {
    case DeliveryChannelsActionTypes.setDeliveryChannels:
      return action.items;
    default:
      return state;
  }
}
