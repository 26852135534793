import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import apiHelper from 'web/services/apiHelper';
import { ModalPopupIds } from 'web/libs/constants';
import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function addContentStartup(windowMode) {
  return (dispatch) => {
    dispatch(openPopup(ModalPopupIds.addContent));

    if (document.querySelector('meta[name="csrf-token"]')) {
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');
      if (csrfToken) {
        apiHelper.setCsrfToken(csrfToken);
      }
    }

    if (windowMode) {
      dispatch({
        type: ActionTypes.addContent.state.windowMode,
        data: true,
      });
    }
  };
}
