import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import {
  createEditState,
  destroyEditState,
  onAutocompleteLeaving,
  onAutocompleteChange,
  onAutocompleteSelection,
  onUserAutocompleteChange,
  onUserAutocompleteSelection,
  onChange,
} from 'web/task/actionCreators/taskActionCreators';
import TaskComponent from 'web/task/components/task';
import { getSubscriptionUsers } from 'web/task/selectors/taskSelectors';

class Task extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    this.state = {
      optionsDisableAll: false,
      optionsDisableAutocomplete: false,
      optionsMarkComplete: false,
      optionsShowContact: false,
    };

    this.actionCreators = {
      createEditState: (task, currentUser) =>
        dispatch(createEditState(task, currentUser)),
      destroyEditState: () => dispatch(destroyEditState()),
      onAutocompleteChange: (value) => dispatch(onAutocompleteChange(value)),
      onAutocompleteLeaving: (value) =>
        dispatch(onAutocompleteLeaving(value, props.handleError)),
      onAutocompleteSelection: (obj) =>
        dispatch(onAutocompleteSelection(obj, props.handleError)),
      onChange: (data) => dispatch(onChange(data)),
      onUserAutocompleteChange: (value) =>
        dispatch(onUserAutocompleteChange(value)),
      onUserAutocompleteSelection: (obj) =>
        dispatch(onUserAutocompleteSelection(obj, props.handleError)),
    };
  }

  componentDidMount() {
    const { editState, task } = this.props;
    if (!editState.id) {
      this.actionCreators.createEditState(task, this.getCurrentUserForAssign());
    }
    this._setOptions();
  }

  render() {
    const {
      autocompleteLoading,
      autocompleteResults,
      autocompleteValue,
      editState,
      taskUserAutocomplete,
      user,
      windowMode,
    } = this.props;

    return (
      <TaskComponent
        actionCreators={this.actionCreators}
        autocompleteLoading={autocompleteLoading}
        autocompleteResults={autocompleteResults}
        autocompleteValue={autocompleteValue}
        editState={editState}
        user={user}
        userAutocompleteValue={taskUserAutocomplete}
        users={this.getUserListForAssign()}
        windowMode={windowMode}
        {...this.state}
      />
    );
  }

  getCurrentUserForAssign = () => {
    const {
      intl: { formatMessage },
      user,
    } = this.props;

    return { ...user, name: formatMessage({ id: 'common.myself' }) };
  };

  getUserListForAssign = () => {
    const { users } = this.props;
    const currentUser = this.getCurrentUserForAssign();

    return Object.values(users).map(
      (x) => (x.id === currentUser.id ? currentUser : x)
    );
  };

  _setOptions = () => {
    const {
      disableAll,
      disableAutocomplete,
      disableType,
      showContact,
      showMarkComplete,
      task,
      userMetric,
    } = this.props;

    this.setState({
      optionsDisableAll: disableAll || (task && task.done) || false,
      optionsDisableAutocomplete: disableAutocomplete || false,
      optionsDisableType: disableType || false,
      optionsMarkComplete: !!(
        (showMarkComplete || (task && task.pitch_id)) &&
        userMetric.uses_reply_tracking
      ),
      optionsShowContact: showContact || false,
    });
  };
}

Task.propTypes = {
  autocompleteLoading: PropTypes.bool,
  autocompleteResults: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string.isRequired,
  disableAll: PropTypes.bool, // passed in
  disableAutocomplete: PropTypes.bool, // passed in
  disableType: PropTypes.bool, // passed in
  dispatch: PropTypes.func.isRequired,
  editState: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  handleError: PropTypes.func.isRequired, //passed in
  showContact: PropTypes.bool, // passed in
  showMarkComplete: PropTypes.bool, // passed in
  task: PropTypes.object.isRequired, // passed in
  user: PropTypes.object.isRequired,
  userMetric: PropTypes.object.isRequired,
  windowMode: PropTypes.bool,
};

Task.defaultProps = {
  autocompleteLoading: false,
  disableAll: false,
  disableAutocomplete: false,
  disableType: false,
  showContact: false,
  showMarkComplete: false,
  windowMode: false,
};

function mapStateToProps(state) {
  return {
    autocompleteLoading: state.autocompleteLoading,
    autocompleteResults: state.autocompleteResults,
    autocompleteValue: state.autocompleteValue,
    editState: state.taskEditState,
    groups: state.groups,
    intl: intlShape.isRequired,
    person: state.personDetailsPerson,
    taskUserAutocomplete: state.taskUserAutocomplete,
    user: state.user,
    userMetric: state.userMetric,
    users: getSubscriptionUsers(state),
  };
}

export default connect(mapStateToProps)(injectIntl(Task));
