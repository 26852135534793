import React from 'react';
import PropTypes from 'prop-types';
import Alert, { AlertColors, AlertLocations } from 'components/alert';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';

const PeopleAlert = (props) => {
  const getAlertComponent = () => {
    const {
      actionCreators,
      peopleAlert: { id, values },
    } = props;

    const { greenDark, redDark, blueDark, yellow } = AlertColors;

    const successProperties = {
      color: greenDark,
      location: AlertLocations.top,
      onClose: actionCreators.closeViewAlert,
    };
    const doNothingProperties = {
      color: yellow,
      location: AlertLocations.top,
      onClose: actionCreators.closeViewAlert,
    };
    const defaultProperties = {
      color: redDark,
      location: AlertLocations.top,
      onClose: actionCreators.closeViewAlert,
    };

    switch (id) {
      case PeopleAlertIds.fetchError:
        return (
          <Alert
            {...defaultProperties}
            textId="common.errors.unexpected.body"
          />
        );
      case PeopleAlertIds.exportPeopleError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.unsubscribes.export.error.body"
          />
        );
      case PeopleAlertIds.exportPeopleSuccess:
        return (
          <Alert
            {...successProperties}
            textId="web.unsubscribes.export.success.body"
          />
        );
      case PeopleAlertIds.exportPeopleNothingToExport:
        return (
          <Alert
            {...doNothingProperties}
            textId="web.people.exportGroup.doNothing"
          />
        );
      case PeopleAlertIds.groupDuplicateNameError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.people.groupDuplicateNameError"
          />
        );
      case PeopleAlertIds.groupMinLengthNameError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.people.groupMinLengthNameError"
            textValues={values}
          />
        );
      case PeopleAlertIds.campaignUpdatedNotification:
        return (
          <Alert
            html
            {...defaultProperties}
            color={blueDark}
            textId="web.people.campaignUpdatedNotification"
          />
        );
      case PeopleAlertIds.campaignRemovedNotification:
        return (
          <Alert
            html
            {...defaultProperties}
            color={blueDark}
            textId="web.people.campaignRemovedNotification"
          />
        );
      case PeopleAlertIds.addPeopleToGroupSuccess:
        return (
          <Alert
            html
            {...defaultProperties}
            color={blueDark}
            textId="web.people.addPeopleToGroupSuccessAlert"
          />
        );
      case PeopleAlertIds.addPersonSuccess:
        return (
          <Alert
            html
            {...defaultProperties}
            color={blueDark}
            textId="web.people.addPersonSuccessAlert"
            manual
          />
        );
      case PeopleAlertIds.importPeopleSuccess:
        return (
          <Alert
            html
            {...defaultProperties}
            color={blueDark}
            textId="web.people.importPeopleSuccessAlert"
            manual
          />
        );
      case PeopleAlertIds.noAccessToCampaign:
        return (
          <Alert
            {...defaultProperties}
            color={blueDark}
            textId="web.people.noAccessToCampaign"
            html
          />
        );
      case PeopleAlertIds.removePeopleFromGroupSuccess:
        return (
          <Alert
            html
            {...defaultProperties}
            color={blueDark}
            textId="web.people.removePeopleFromGroupSuccessAlert"
          />
        );
      default:
        return null;
    }
  };

  return <>{getAlertComponent()}</>;
};

PeopleAlert.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  peopleAlert: PropTypes.object.isRequired,
};

export default PeopleAlert;
