import { TasksTablePopupIds } from '../libs/tablesTasksConstants';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  taskDelete as taskDeleteCommon,
  taskDone as taskDoneCommon,
  taskSkip as taskSkipCommon,
  taskSuccess as taskSuccessCommon,
  taskUndone as taskUndoneCommon,
} from 'web/tasks/actionCreators/tasksActionCreators';
import { isCampaignTask } from '../helpers/tablesTasksHelpers';
import { TaskProperties } from 'web/libs/mixpanelEvents';
import { trackComplete } from 'web/tables/tasks/services/tasksTableMixpanel';

export function taskDelete(tableId, task) {
  return (dispatch) => {
    trackComplete(
      tableId,
      TaskProperties.actionType.delete,
      false,
      isCampaignTask(task),
      task.remind_type
    );
    dispatch(taskDeleteCommon(task));
  };
}

export function taskDone(tableId, task) {
  return (dispatch) => {
    trackComplete(
      tableId,
      TaskProperties.actionType.complete,
      false,
      isCampaignTask(task),
      task.remind_type
    );
    dispatch(taskDoneCommon(task));
  };
}

export function taskSkip(tableId, task) {
  return (dispatch) => {
    trackComplete(
      tableId,
      TaskProperties.actionType.skip,
      false,
      isCampaignTask(task),
      task.remind_type
    );
    dispatch(taskSkipCommon(task));
  };
}

export function taskSuccess(tableId, task) {
  return (dispatch) => {
    trackComplete(
      tableId,
      TaskProperties.actionType.success,
      false,
      isCampaignTask(task),
      task.remind_type
    );
    dispatch(taskSuccessCommon(task));
  };
}

export function taskUndone(tableId, task) {
  return (dispatch) => {
    if (isCampaignTask(task)) {
      dispatch(openPopup(TasksTablePopupIds.campaignTaskUndone));
    } else {
      trackComplete(
        tableId,
        TaskProperties.actionType.undone,
        false,
        isCampaignTask(task),
        task.remind_type
      );
      dispatch(taskUndoneCommon(task));
    }
  };
}
