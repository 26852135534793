import PersonDetailsSlideOutActionTypes from '../libs/personDetailsSlideOutActionTypes';
import SlideOutsActionTypes from 'web/slideOuts/libs/slideOutsActionTypes';

const EMPTY_OBJECT = {};

export const personDetailsSlideOutPersonId = (
  state = 0,
  { type = '', id = 0 }
) => {
  switch (type) {
    case PersonDetailsSlideOutActionTypes.open:
      return id;
    case PersonDetailsSlideOutActionTypes.close:
    case SlideOutsActionTypes.close:
      return 0;
    default:
      return state;
  }
};

export const personDetailsSlideOutPersonOptions = (
  state = EMPTY_OBJECT,
  { type = '', options = EMPTY_OBJECT }
) => {
  switch (type) {
    case PersonDetailsSlideOutActionTypes.open:
      return options;
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
};
