import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './group.scss';

class Group extends Component {
  render() {
    const { className, items, onClick } = this.props;

    return (
      <div className={classNames('tout-group', className)}>
        {items.map(
          ({ className: itemClassName, color, label, value }, index) => {
            const key = `group-item-${value}`;
            const itemClassNames = classNames('group-item', itemClassName, {
              [color]: color,
              'view-only': !onClick,
            });
            return (
              <div
                className={itemClassNames}
                data-index={index}
                key={key}
                onClick={this._onClick}
              >
                {label}
              </div>
            );
          }
        )}
      </div>
    );
  }

  _onClick = (e) => {
    const { items, onClick } = this.props;
    if (onClick) {
      const index = e.currentTarget.dataset.index || e.target.dataset.index;
      onClick(items[index].value, index);
    }
  };
}

export const GroupColors = {
  blue: 'blue',
  gray: 'gray',
  grayLight: 'gray-light',
  green: 'green',
  white: 'white',
};

export const GroupItem = PropTypes.shape({
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(GroupColors)).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

Group.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(GroupItem).isRequired,
  onClick: PropTypes.func,
};

Group.defaultProps = {
  className: '',
  onClick: null,
};

export default Group;
