import {
  ACCESS_ROLE,
  BULK_ACTIONS,
  Roles,
} from '../libs/marketoSettingsConstants';

export const getEAU = (auths, userId) =>
  auths.find(
    (auth) => auth.access_role === Roles.engageAdmin && auth.user_id === userId
  );

export const getESU = (auths, userId) =>
  auths.find(
    (auth) => auth.access_role === Roles.salesUser && auth.user_id === userId
  );

export const toNumArray = (arr) => arr.map((string) => parseInt(string, 10));

export const getIdsToActOn = (action, authUserIds, selectedIds) =>
  action === BULK_ACTIONS.create
    ? toNumArray(selectedIds).filter((id) => !authUserIds.includes(id))
    : toNumArray(selectedIds).filter((id) => authUserIds.includes(id));

const getEAUCount = (auths) =>
  auths.filter((auth) => auth[ACCESS_ROLE] === Roles.engageAdmin);
const getESUCount = (auths) =>
  auths.filter((auth) => auth[ACCESS_ROLE] === Roles.salesUser);

export const getConnectionInfo = (auths) => ({
  munchkin: (auths.length && auths[0].munchkin_id) || '',
  engageUsers: {
    engageAdminCount: getEAUCount(auths).length,
    engageSalesCount: getESUCount(auths).length,
  },
});
