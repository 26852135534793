import SlackActionTypes from '../libs/slackActionTypes';
import { getSlackIntegration as getSlackIntegrationCall } from '../services/slackService';
import { deleteContactIntegration as deleteContactIntegrationCall } from 'web/contactIntegrations/services/contactIntegrationsService';
import { getSlackIntegration as getSlackIntegrationSelector } from '../selectors/slackSelector';

export const setIntegration = (item = {}) => ({
  type: SlackActionTypes.setIntegration,
  item,
});

export const setIntegrationFetching = (fetching = true) => ({
  type: SlackActionTypes.setIntegrationFetching,
  fetching,
});

export const setIntegrationFetched = (fetched = true) => ({
  type: SlackActionTypes.setIntegrationFetched,
  fetched,
});

export const getSlackIntegration = () => (dispatch) => {
  dispatch(setIntegrationFetching());
  return getSlackIntegrationCall()
    .then((integration) => {
      dispatch(setIntegration(integration));
      dispatch(setIntegrationFetched());
      return integration;
    })
    .finally(() => {
      dispatch(setIntegrationFetching(false));
    });
};

export const deleteSlackIntegration = () => (dispatch, getState) => {
  const { id } = getSlackIntegrationSelector(getState());
  dispatch(setIntegrationFetching());
  return deleteContactIntegrationCall(id)
    .then(() => dispatch(getSlackIntegration()))
    .then((integration) => {
      dispatch(setIntegration(integration));
      return integration;
    })
    .finally(() => {
      dispatch(setIntegrationFetching(false));
    });
};
