import {
  AddressLocations,
  AddressTypes,
  ApiKeys,
  AddressTypesMultiple,
  AddressTypesSingle,
  PersonDetailsCards,
} from 'web/person/libs/personDetailsConstants';
import { invalidComplianceAuthorization } from 'web/people/helpers/peopleAuthorizationHelpers';
import { isOtherSourceType } from 'web/compliance/helpers/complianceHelpers';
import { getPerson } from 'web/person/selectors/personDetailsSelectors';
import { getSocialMediaUrl } from './personSocialMediaHelpers';
import { getMarketoLeadIdFromLeadFields } from './personHelpers';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';

const getInfoEditState = (person) => {
  const {
    addressesSorted = [],
    external_references: externalReferences = [],
    lead_fields: leadFields = [],
    company = { name: '' },
    first_name: firstName,
    last_name: lastName,
    salesforce_contact_id: salesforceContactId,
    salesforce_lead_id: salesforceLeadId,
    social: {
      facebook = { value: '' },
      linkedin = { value: '' },
      twitter = { value: '' },
    } = {},
    title = '',
  } = person;
  const init = { location: AddressLocations.work, value: '' };

  const marketoId = getMarketoLeadIdFromLeadFields(leadFields);
  // first, last, & title can be set to null
  const editState = {
    [AddressTypes.facebook]: { ...facebook },
    [AddressTypes.linkedin]: { ...linkedin },
    [AddressTypes.twitter]: { ...twitter },
    companyName: company.name,
    externalReferences: externalReferences || [
      { service: ApiKeys.marketo, service_entity_id: '' },
      { service: ApiKeys.salesforce, service_entity_id: '' },
    ],
    firstName: firstName || '',
    lastName: lastName || '',
    leadFields,
    marketoId,
    salesforceId: salesforceContactId || salesforceLeadId,
    title: title || '',
  };

  for (const item of AddressTypesMultiple) {
    const addresses = [
      ...flatten(addressesSorted[item]),
      { ...init, type: item },
    ];
    const primaryIndex = addresses.findIndex(({ isPrimary }) => isPrimary);
    editState[item] = {
      addresses,
      primaryIndex: primaryIndex > -1 ? primaryIndex : 0,
    };
  }
  return editState;
};

const parseGroupsEmailAddresses = (addresses) =>
  addresses.reduce(
    (accumulator, { address, address_type: addressType, id }) => {
      if (addressType === AddressTypes.email) {
        accumulator.push({ label: address, value: id });
      }
      return accumulator;
    },
    []
  );

/* Find all addresses and parse into objects with label/value and create invalid address type map */
const parseInvalidAddressObjects = (groupsMapClone) => {
  const invalidAddressesMap = Object.keys(groupsMapClone).reduce(
    (accumulator, addressId) => {
      if (!groupsMapClone[addressId].addressValidType) {
        accumulator[addressId] = groupsMapClone[addressId];
      }
      return accumulator;
    },
    {}
  );
  const invalidAddresses = Object.keys(invalidAddressesMap).map(
    (addressId) => ({
      label: groupsMapClone[addressId].addressLabel,
      value: groupsMapClone[addressId].addressValue,
    })
  );

  return { invalidAddresses, invalidAddressesMap };
};

/* Create map of all addresses with original groups */
const parseGroupsByAddressId = (groupsMapClone, combinedAddresses) =>
  combinedAddresses.reduce(
    (accumulator, address) => {
      if (groupsMapClone[address.value]) {
        accumulator[address.value] = groupsMapClone[address.value].groupsList;
      } else {
        accumulator[address.value] = [];
      }
      return accumulator;
    },
    { ...groupsMapClone }
  );

const getGroupsEditState = ({
  addresses = [],
  groupsByAddressId = {},
} = {}) => {
  const groupsMapClone = cloneDeep(groupsByAddressId);
  const emails = parseGroupsEmailAddresses(addresses);
  const { invalidAddresses, invalidAddressesMap } = parseInvalidAddressObjects(
    groupsMapClone
  );
  const combinedAddresses = emails.concat(invalidAddresses);
  const allAddressesGroupsMap = parseGroupsByAddressId(
    groupsMapClone,
    combinedAddresses
  );

  return {
    addresses: combinedAddresses,
    groupsByAddressId: allAddressesGroupsMap,
    invalidAddressesMap,
    selectedEmailValue: combinedAddresses.length
      ? combinedAddresses[0].value
      : '',
  };
};

const getCustomFieldsEditState = ({ customFields = [] }) => [
  ...cloneDeep(customFields),
  { id: Object.keys(customFields).length, name: '', value: '' },
];

const getComplianceEditState = ({ complianceDetails = {} }) =>
  complianceDetails;

export const validateComplianceFields = (state) => {
  const {
    personDetailsComplianceEditState,
    personDetailsComplianceEditState: { otherSourceValue, sourceType },
  } = state;

  return !(
    (isOtherSourceType(sourceType) && !otherSourceValue.trim()) ||
    invalidComplianceAuthorization(personDetailsComplianceEditState)
  );
};

export const getEditStateByCard = (cardId, state = {}) => {
  const person = getPerson(state);
  switch (cardId) {
    case PersonDetailsCards.info:
      return getInfoEditState(person);
    case PersonDetailsCards.groups:
      return getGroupsEditState(person);
    case PersonDetailsCards.customFields:
      return getCustomFieldsEditState(person);
    case PersonDetailsCards.compliance:
      return getComplianceEditState(person);
    default:
      return {};
  }
};

const parseAddress = ({ id, isPrimary = false, location, type, value }) => ({
  address: value,
  address_type: type,
  id,
  is_primary: isPrimary,
  location,
});

const parseMultipleAddresses = (addresses = [], primaryIndex = 0) =>
  cloneDeep(addresses).reduce((accumulator, address, index) => {
    if (!address.value) {
      // remove empty addresses
      return accumulator;
    }

    return accumulator.concat(
      parseAddress({ ...address, isPrimary: primaryIndex === index })
    );
  }, []);

const parseSingleAddress = (type, address = { value: '' }) => {
  const value = address.value && getSocialMediaUrl(type, address.value);

  if (value) {
    return parseAddress({ ...address, value });
  }
  return [];
};

const getAddressesFromEditState = (editState) => {
  let addresses = [];

  for (const multipleType of AddressTypesMultiple) {
    addresses = addresses.concat(
      parseMultipleAddresses(
        editState[multipleType].addresses,
        editState[multipleType].primaryIndex
      )
    );
  }

  for (const singleType of AddressTypesSingle) {
    addresses = addresses.concat(
      parseSingleAddress(singleType, editState[singleType])
    );
  }

  return addresses;
};

export const getInfoFromEditState = (state = {}) => {
  const {
    personDetailsPerson: { id, company = {} } = {},
    personDetailsInfoEditState: editState = {
      [AddressTypes.email]: {},
      [AddressTypes.phone]: {},
      [AddressTypes.website]: {},
      [AddressTypes.other]: {},
      [ApiKeys.externalReferences]: {},
    },
  } = state;

  return {
    addresses: getAddressesFromEditState(editState),
    company: { id: company.id, name: editState.companyName },
    externalReferences: editState.externalReferences,
    leadFields: editState.leadFields || [],
    first_name: editState.firstName,
    id,
    last_name: editState.lastName,
    title: editState.title,
  };
};

export const getGroupsFromEditState = (groupsByAddressId = {}) =>
  Object.keys(groupsByAddressId).reduce(
    (accumulator, addressId) => [
      ...accumulator,
      ...groupsByAddressId[addressId].map(({ value }) => ({
        address_id: addressId,
        group_id: value,
      })),
    ],
    []
  );

export const getCustomFieldsFromEditState = (state = {}) => {
  const {
    personDetailsPerson: { id } = {},
    personDetailsCustomFieldsEditState: customFields = [],
  } = state;

  return {
    custom_fields: customFields.reduce((accumulator, { name = '', value }) => {
      if (!name.trim()) {
        return accumulator;
      }
      return { ...accumulator, [name.trim()]: value };
    }, {}),
    id,
  };
};
