import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeedbackButton from 'web/view/components/feedbackButton/feedbackButton';
import Page from 'components/layouts/page/page';
import PageView from 'components/layouts/page/view';
import TemplatesAlert from 'web/templates/components/templatesAlert/templatesAlert';
import TemplatesPopupContainer from 'web/templates/containers/templatesPopupContainer';
import TemplatesSidebarContainer from 'web/templates/containers/templatesSidebarContainer';
import TemplatesPageHeader from 'web/templates/components/templatesPageHeader/templatesPageHeader';
import TemplatesTableContainer from 'web/templates/containers/templatesTableContainer';
import EditTemplateSlideOut from 'web/slideOuts/editTemplate';
import TwoPaneLayout from 'web/shared/components/twoPaneLayout/twoPaneLayout';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import { TEMPLATES_FEEDBACK_URL } from 'web/templates/libs/templatesConstants';
import { bindActionCreators, compose } from 'redux';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { connect } from 'react-redux';
import {
  getCurrentCategoryName,
  isDisabledForViewer as isDisabledForViewerSelector,
} from 'web/templates/selectors/templatesSelectors';
import { injectIntl, intlShape } from 'react-intl';
import { openCreateTemplatePopup } from 'web/templates/actionCreators/templatesPopupActionCreators';
import { undoBulkMoveTemplate } from 'web/templates/actionCreators/templatesBulkActionCreators';
import withDndContext from 'table/modules/withDnd/withDndContext';

class TemplatesTab extends Component {
  constructor(props) {
    super();

    const {
      dispatch,
      intl: { formatMessage },
    } = props;
    this.actionCreators = bindActionCreators(
      {
        closeViewAlert,
        openCreateTemplatePopup,
        undoBulkMoveTemplate,
      },
      dispatch
    );

    this.templatesTitle = formatMessage({ id: 'common.templates' });
  }

  render() {
    const { currentCategoryName, isDisabledForViewer, viewAlert } = this.props;
    const title = currentCategoryName || this.templatesTitle;

    return [
      <TemplatesPopupContainer key="templates-popup-container" />,
      <div key="templates-tab-container">
        <EditTemplateSlideOut />
        <TemplatesAlert
          templatesAlert={viewAlert}
          actionCreators={this.actionCreators}
        />
        <TwoPaneLayout>
          <TemplatesSidebarContainer />
          <Page>
            <TemplatesPageHeader
              actionCreators={this.actionCreators}
              buttonsDisabled={isDisabledForViewer}
              title={title}
            />
            <PageView>
              <TemplatesTableContainer />
            </PageView>
          </Page>
        </TwoPaneLayout>
        <FeedbackButton feedbackURL={TEMPLATES_FEEDBACK_URL} hidden />
      </div>,
    ];
  }
}
TemplatesTab.propTypes = {
  currentCategoryName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isDisabledForViewer: PropTypes.bool.isRequired,
  viewAlert: PropTypes.object,
};

TemplatesTab.defaultProps = {
  viewAlert: {},
};

const mapStateToProps = (state) => ({
  currentCategoryName: getCurrentCategoryName(state),
  isDisabledForViewer: isDisabledForViewerSelector(state),
  viewAlert: state.viewAlert,
});

export default compose(
  withDndContext,
  withCommonModals,
  connect(mapStateToProps),
  injectIntl
)(TemplatesTab);
