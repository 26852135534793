import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { SALESFORCE_SOURCE } from '../libs/salesforceConstants';

export const getSalesforceStats = (userId) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.diagnostics.getStats(userId, SALESFORCE_SOURCE),
      {},
      resolve,
      reject
    );
  });

export const getSalesforceIntegration = () =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.contactIntegrations.byProvider(SALESFORCE_SOURCE),
      {},
      resolve,
      reject
    );
  });

export const getSalesforceAccount = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.salesforceAccountEntity, {}, resolve, reject);
  });
