import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getImageUrl } from 'web/libs/constants';
import { Urls } from 'web/libs/routes';
import './logo.scss';

const ADOBE_LOGO = getImageUrl('logo-adobe', 'svg');

const Logo = (props) => {
  const { width, height, className } = props;
  const classNames = classnames('main-logo-link', className);
  return (
    <a
      className={classNames}
      href={Urls.common.main}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="main-logo-img"
        src={ADOBE_LOGO}
        width={width}
        height={height}
        alt=""
      />
    </a>
  );
};

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

Logo.defaultProps = {
  width: 30,
  height: 30,
  className: '',
};

export default Logo;
