import React, { Component } from 'react';
import { string, number, object, node, arrayOf } from 'prop-types';
import Select2 from 'components/selects/select2';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './marketoAdminWorkspacesModal.scss';

class MarketoAdminWorkspacesModal extends Component {
  componentWillMount() {
    this.props.actionCreators.getWorkspaces();
  }

  render() {
    const {
      actionCreators,
      selectedWorkspaces,
      workspaces,
      count,
      intl: { formatMessage },
    } = this.props;
    return (
      <div className="marketo-admin-workspaces-modal">
        <FormattedMessage
          id="web.marketoSettings.workspaces.assignUsers"
          values={{ count }}
        />
        <Select2
          className="workspaces-modal-select"
          options={this._getNames(workspaces)}
          placeholder={formatMessage({
            id: 'web.marketoSettings.workspaces.search',
          })}
          multi
          noResultsText={formatMessage({
            id: 'web.marketoSettings.workspaces.noResults',
          })}
          onChange={actionCreators.updateWorkspaces}
          value={selectedWorkspaces}
        />
      </div>
    );
  }

  _getNames = (workspaces) => workspaces.map((workspace) => workspace.name);
}

MarketoAdminWorkspacesModal.propTypes = {
  actionCreators: object.isRequired,
  children: node,
  intl: intlShape.isRequired,
  selectedWorkspaces: arrayOf(string).isRequired,
  count: number,
  workspaces: arrayOf(object).isRequired,
};

export default injectIntl(MarketoAdminWorkspacesModal);
