import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToutTable from 'table/modules/toutTable';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import { default as I18N, moment } from 'languages';
import DotaCell from 'web/settings/adminSettings/general/components/dotaCell';
import ConsentMessageNameCell from 'web/manageRecordingNotice/components/consentMessageNameCell';
import { sortColumn } from 'web/commandCenter/helpers/sorting';
import './manageRecordingNotice.columns.scss';

const ManageRecordingNoticeColumns = (props) => {
  const {
    actionCreators,
    formatMessage,
    recordingNoticeData,
    getShowDeletePopup,
  } = props;

  const columnsTitle = [
    {
      flex: false,
      id: 'name',
      name: formatMessage({
        id: 'common.name',
      }),
      rowCell: {
        component: ConsentMessageNameCell,
      },
      sorting: (name, dir) => {
        setColumnsData(
          sortColumn(columnsData, ['file_attachment', 'file_file_name'], dir)
        );
      },
      width: 500,
    },
    {
      flex: true,
      id: 'length',
      name: formatMessage({
        id: 'common.duration',
      }),
      rowCell: {
        component: TextCustomCell,
        metadata: {
          formatText: (data) => moment.utc(data.length * 1000).format('mm:ss'),
        },
      },
      sorting: (name, dir) => {
        setColumnsData(sortColumn(columnsData, ['length'], dir));
      },
    },
    {
      flex: true,
      id: 'updated_at',
      name: formatMessage({
        id: 'common.dateUploaded',
      }),
      rowCell: {
        component: TextCustomCell,
        metadata: {
          formatText: (data) => moment(data.updated_at).format(I18N.DateFormats.DATE_LONG),
        },
      },
      sorting: (name, dir) => {
        setColumnsData(sortColumn(columnsData, ['updated_at'], dir));
      },
    },
    {
      align: 'right',
      flex: false,
      id: 'drop_down_menu',
      name: '',
      rowCell: {
        component: DotaCell,
        metadata: {
          options: [
            {
              label: ({ active }) => {
                const labelId = active
                  ? 'web.settings.adminSettings.general.salesEngagePhoneCard.manageRecordingNotice.disable'
                  : 'web.settings.adminSettings.general.salesEngagePhoneCard.manageRecordingNotice.enable';
                return formatMessage({
                  id: labelId,
                });
              },
              onClick: ({ active, id }) => {
                actionCreators.changeEnableStateOfConsentMessage(id, !active);
              },
              value: 'enable',
            },
            {
              disabled: ({ active }) => active,
              disabledStyle: ({ active }) => active,
              label: formatMessage({
                id: 'common.delete',
              }),
              onClick: openDeletePopup,
              value: 'delete',
            },
          ],
          property: 'drop_down_menu',
        },
      },
      width: 50,
    },
  ];

  const [columnsData, setColumnsData] = useState(recordingNoticeData);

  useEffect(
    () => {
      setColumnsData(recordingNoticeData);
    },
    [recordingNoticeData, setColumnsData]
  );

  function openDeletePopup(dataRow) {
    const {
      id,
      audioData: { title },
    } = dataRow;
    getShowDeletePopup(true, id, title);
  }

  return (
    <div className="manageRecordingNotice-table-wrapper">
      <ToutTable
        columns={columnsTitle}
        items={columnsData}
        loading={true}
        pagination={false}
        tableId="manageRecordingNotice-table"
      />
    </div>
  );
};

ManageRecordingNoticeColumns.defaultProps = {
  currentAudioData: null,
};

ManageRecordingNoticeColumns.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  currentAudioData: PropTypes.object,
  formatMessage: PropTypes.func.isRequired,
  getShowDeletePopup: PropTypes.func.isRequired,
  recordingNoticeData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ManageRecordingNoticeColumns;
