import myProfileActionTypes from '../libs/myProfileActionTypes';

const initialState = {
  alertOptions: null,
};

export function myProfileAlertReducer(state = initialState, action) {
  switch (action.type) {
    case myProfileActionTypes.showAlert: {
      const { alertOptions } = action;
      return {
        alertOptions,
      };
    }
    case myProfileActionTypes.clearAlert: {
      return {
        alertOptions: null,
      };
    }
    default:
      return state;
  }
}
