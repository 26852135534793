import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { KeyCodes } from 'web/libs/constants';
import './chipEdit.scss';

import AutosizeInput from 'react-input-autosize';

class ChipEdit extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { value };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value) {
      this.setState({
        value: newProps.value,
      });
    }
  }

  componentDidMount() {
    this.input.focus();
  }

  render() {
    const { className, placeholder, inputRef } = this.props;
    const { value } = this.state;

    return (
      <div className={classNames('tout-chip-edit', className)}>
        <AutosizeInput
          className="tout-chip-edit-wrapper"
          autoFocus
          inputRef={(input) => {
            this.input = input;
            inputRef && inputRef(input);
          }}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          extraWidth={18}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    );
  }

  handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {
      target: { value },
    } = event;
    const { onChange, stopPattern } = this.props;
    if (stopPattern.test(value)) {
      this.setState({ value: '' }, () => onChange(value));
    } else {
      this.setState({ value });
    }
  };

  handleBlur = (event) => {
    const { onChange } = this.props;
    event.preventDefault();
    event.stopPropagation();
    const { value } = this.state;
    this.setState({ value: '' }, onChange(value));
  };

  handleKeyDown = (event) => {
    if ([KeyCodes.enter, KeyCodes.tab].includes(event.keyCode)) {
      event.preventDefault();
      event.stopPropagation();
      const { onChange } = this.props;
      const { value } = this.state;
      this.setState({ value: '' }, onChange(value));
    } else if (
      KeyCodes.backspace === event.keyCode &&
      event.target.selectionStart === 0 &&
      event.target.selectionEnd === 0
    ) {
      event.preventDefault();
      event.stopPropagation();
      const { onRemovePrevious } = this.props;
      onRemovePrevious();
    }
  };
}

ChipEdit.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onRemovePrevious: PropTypes.func,
  stopPattern: PropTypes.objectOf(RegExp),
  inputRef: PropTypes.func,
};

ChipEdit.defaultProps = {
  className: '',
  value: '',
  placeholder: '',
  onChange: () => {},
  onRemovePrevious: () => {},
  stopPattern: null,
  inputRef: () => {},
};

export default ChipEdit;
