import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { BackgroundColors, TextColors } from 'libs/constantsStyles';
import './pill.scss';

class Pill extends Component {
  render() {
    const {
      onClick,
      className,
      color,
      insideInput,
      onClose,
      text,
      textColor,
      textId,
      textValues,
    } = this.props;

    const colorClass = `text-${textColor}`;
    const pillClassName = classNames('tout-pill', className, colorClass, {
      [`background-${color}`]: color,
      'inside-input': insideInput,
    });

    return (
      <div className={pillClassName}>
        <span onClick={onClick}>
          {(textId && <FormattedMessage id={textId} values={textValues} />) ||
            text}
        </span>
        {onClose && (
          <div
            className={classNames('tout-icon-close', colorClass)}
            onMouseDown={this.onClose}
          />
        )}
      </div>
    );
  }

  onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { id, onClose } = this.props;
    onClose(id);
  };
}

Pill.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(BackgroundColors)),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  insideInput: PropTypes.bool,
  onClose: PropTypes.func,
  text: PropTypes.string,
  textId: PropTypes.string,
  textColor: PropTypes.oneOf(Object.values(TextColors)),
  textValues: PropTypes.object,
};

Pill.defaultProps = {
  className: '',
  color: BackgroundColors.blue,
  insideInput: false,
  onClose: null,
  text: '',
  textId: '',
  textColor: TextColors.white,
  textValues: {},
};

export default Pill;
