import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { PreviewViewTypes } from 'libs/constantsShared';
import './personItem.scss';

class PersonItem extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
    } = this.props;
    this.commaSpaced = formatMessage({ id: 'common.commaSpaced' });
    this.noName = formatMessage({ id: 'common.noName' });
  }

  render() {
    const {
      className,
      currentPersonId,
      onClick,
      person: { blockedReason, companyName, id, name, title },
    } = this.props;
    const classes = classNames('person-item', className, {
      active: id === currentPersonId && onClick,
      clickable: onClick,
    });
    const invalidPerson = blockedReason ? ' invalid-person-item' : '';
    const personName = name || this.noName;

    return (
      <div className={classes} onClick={this._onClick} data-person-id={id}>
        <div className={`text-medium${invalidPerson}`}>{personName}</div>
        <div className={`job${invalidPerson}`}>
          {this._formatJob(title, companyName)}
        </div>
      </div>
    );
  }

  _formatJob = (title, company) => {
    const spacing = title && company ? this.commaSpaced : '';
    return `${title}${spacing}${company}`;
  };

  _onClick = (e) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(
        parseInt(
          e.currentTarget.dataset.personId || e.target.dataset.personId,
          10
        ),
        PreviewViewTypes.person
      );
    }
  };
}

PersonItem.defaultProps = {
  className: '',
  currentPersonId: null,
  onClick: null,
};

PersonItem.propTypes = {
  className: PropTypes.string,
  currentPersonId: PropTypes.number,
  intl: intlShape.isRequired,
  onClick: PropTypes.func,
  person: PropTypes.object.isRequired,
};

export default injectIntl(PersonItem);
