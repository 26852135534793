import { refreshEmails } from 'web/commandCenter/actionCreators/commandCenterEmailsFetchActionCreators';
import debounce from 'lodash/debounce';
import { setSmartFilter } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { FILTER_SEARCH_ID } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';

export const SEARCH_DEBOUNCE = 600;

export const debouncePaginateEmails = debounce((dispatch) => {
  dispatch(refreshEmails());
}, SEARCH_DEBOUNCE);

export const emailResetSearch = () => (dispatch) => {
  dispatch(setEmailSearchValue(''));
};

export const emailSearch = (value) => (dispatch) => {
  dispatch(setEmailSearchValue(value));
  debouncePaginateEmails(dispatch);
};

const setEmailSearchValue = (value) => (dispatch, getState) => {
  const tableId = getActiveEmailTableId(getState());
  dispatch(
    setSmartFilter({
      collectionId: tableId,
      filterId: FILTER_SEARCH_ID,
      payload: { searchValue: value },
    })
  );
};
