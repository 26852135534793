import { Urls } from 'web/libs/routes';
import I18N from 'languages';

export const getNavBarItems = () => {
  const { feedback, gettingStarted, knowledgeBase, getHelp } = Urls.topNavbar;

  return [
    {
      id: 1,
      isOpenNewTab: true,
      link: feedback,
      titleId: 'common.feedback',
    },
    {
      id: 2,
      isOpenNewTab: true,
      link: gettingStarted(I18N.getLocale()),
      titleId: 'web.navbar.gettingStarted',
    },
    {
      id: 3,
      isOpenNewTab: true,
      link: knowledgeBase(I18N.getLocale()),
      titleId: 'web.navbar.knowledgeBase',
    },
    {
      id: 4,
      isOpenNewTab: true,
      link: getHelp,
      titleId: 'common.getHelp',
    },
  ];
};
