import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import { injectIntl, FormattedMessage } from 'react-intl';
import Switch from 'components/buttons/switch';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';

const UnsubscribeCustomDomain = (props) => {
  const { showUnsubscribePage, onChange, loading } = props;
  return (
    <div className="unsubscribe">
      {loading ? <LoadingSpinner imageUrl={getImageUrl} /> : null}
      <Card width={500} className="salesforce-sync-card">
        <h3 className="sky-design-h3">
          <FormattedMessage id="web.settings.unsubscribe.title" />
        </h3>
        <div className="margin-top-20">
          <Switch
            label={
              <FormattedMessage id="web.settings.unsubscribe.switch.label" />
            }
            checked={showUnsubscribePage}
            onChange={onChange}
            name="apply-team-settings"
          />
        </div>
        <div className="margin-top-20">
          <p>
            <FormattedMessage id="web.settings.unsubscribe.content" />
          </p>
        </div>
      </Card>
    </div>
  );
};

UnsubscribeCustomDomain.propTypes = {
  showUnsubscribePage: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default injectIntl(UnsubscribeCustomDomain);
