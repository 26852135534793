export default {
  analytics: 'LIVE_FEED_ANALYTICS',
  chartEvent: 'LIVE_FEED_CHART_EVENT',
  engageUserAuthentication: 'ENGAGE_USER_AUTHENTICATION',
  initStore: 'LIVE_FEED_INIT_STORE',
  setLoading: 'LIVE_FEED_SET_LOADING',
  tabSelected: {
    engagement: 'LIVE_FEED_ENGAGEMENT_TAB_SELECTED',
    tasks: 'LIVE_FEED_TASKS_TAB_SELECTED',
    target: 'LIVE_FEED_TARGET_TAB_SELECTED',
  },
  updateDealAlert: 'LIVE_FEED_DEAL_ALERT',
  updateEventsAnalytics: 'LIVE_FEED_UPDATE_EVENTS_ANALYTICS',
  updatePitchAnalytics: 'LIVE_FEED_UPDATE_PITCH_ANALYTICS',
  updateSalesforceInstanceUrl: 'LIVE_FEED_UPDATE_SALESFORCE_INSTANCE_URL',
};
