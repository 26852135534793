import PeopleSourceActionTypes from 'web/modals/peopleSource/libs/peopleSourceActionTypes';
import { SourceTypes } from 'web/compliance/libs/complianceConstants';

export function peopleSourceModalState(state = '', { type, modal }) {
  switch (type) {
    case PeopleSourceActionTypes.modal.set:
      return modal;
    default:
      return state;
  }
}

const initialSelectedSource = SourceTypes.crmSync;
const initialOtherSource = '';

const initialPeopleSource = {
  sourceType: initialSelectedSource,
  otherSourceValue: '',
};

export const selectedPeopleSource = (
  state = initialPeopleSource,
  { type, source }
) => {
  switch (type) {
    case PeopleSourceActionTypes.selectPeopleSource:
      return { ...state, ...source };
    case PeopleSourceActionTypes.reset:
      return initialPeopleSource;
    default:
      return state;
  }
};

export function otherPeopleSource(
  state = initialOtherSource,
  { type, source }
) {
  switch (type) {
    case PeopleSourceActionTypes.otherPeopleSource:
      return source;
    case PeopleSourceActionTypes.reset:
      return initialOtherSource;
    default:
      return state;
  }
}
