import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getEditIdentitySelector } from '../selectors/editIdentitySelectors';
import EditIdentityTab from '../components/editIdentityTab';
import { EditIdentityTabs } from '../libs/editIdentitySlideOutConstants';

class EditIdentityTabContainer extends Component {
  render() {
    return <EditIdentityTab {...this.props} onTabSelect={this.onTabSelect} />;
  }

  onTabSelect = (tab) => {
    // there is only one tab for now. implement this method if there are more tabs
  };
}

EditIdentityTabContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  tab: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  // there is only one tab for now. implement reducer if there are more tabs
  return {
    tab: EditIdentityTabs.about,
    identity: getEditIdentitySelector(state),
    user: state.user,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(EditIdentityTabContainer);
