import { basePost, baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getActivities(userId) {
  return new Promise((resolve, reject) => {
    baseGet(Urls.diagnostics.getActivities(userId), {}, resolve, reject);
  });
}

export function retryActivity(userId, ids) {
  const data = {
    ids,
    source: 'salesforce',
  };
  return new Promise((resolve, reject) => {
    basePost(Urls.diagnostics.retryActivity(userId), data, resolve, reject);
  });
}

export function removeActivity(userId, ids) {
  const data = {
    ids,
    source: 'salesforce',
  };
  return new Promise((resolve, reject) => {
    basePost(Urls.diagnostics.destroyActivity(userId), data, resolve, reject);
  });
}
