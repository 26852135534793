import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/buttons/checkbox';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';
import { convertSubTabsToArray } from 'web/commandCenter/modules/statusSmartFilter/helpers/statusSmartFilterHelpers';

class CheckboxSubTypesBlock extends Component {
  render() {
    const { subTabIds, tabId } = this.props;
    const subTabs = convertSubTabsToArray(
      CommandCenterEmailsTabsEnum[tabId].subTabs
    );

    return (
      <div>
        {subTabs.map((subTab) => (
          <Checkbox
            checked={subTabIds.some((subTabId) => subTabId === subTab.value)}
            key={`content-${subTab.value}`}
            label={<FormattedMessage id={subTab.label} />}
            name={`${subTab.value}`}
            onChange={this.handleClick}
          />
        ))}
      </div>
    );
  }

  handleClick = (state, subTabId) => {
    const { setStatusValue, subTabIds, tabId } = this.props;
    // add subItem to selected list if true, else - remove
    state
      ? setStatusValue({
          subTabIds: [...subTabIds, subTabId],
          tabId,
        })
      : setStatusValue({
          subTabIds: [
            ...subTabIds.slice(0, subTabIds.indexOf(subTabId)),
            ...subTabIds.slice(subTabIds.indexOf(subTabId) + 1),
          ],
          tabId,
        });
  };
}

CheckboxSubTypesBlock.propTypes = {
  setStatusValue: PropTypes.func.isRequired,
  subTabIds: PropTypes.array.isRequired,
  tabId: PropTypes.string.isRequired,
};

export default injectIntl(CheckboxSubTypesBlock);
