import React, { Component } from 'react';
import { intlShape, injectIntl, FormattedHTMLMessage } from 'react-intl';
import './smtpSetupByAdminCard.scss';

// eslint-disable-next-line react/prefer-stateless-function
class SmtpSetupByAdminCard extends Component {
  render() {
    return (
      <div className="smtp-server-setup-by-admin-card">
        <div className="tout-icon-iconography tout-icon-tick" />
        <p>
          <FormattedHTMLMessage id="web.settings.smtpServer.smtpSetupByAdminCard.description" />
        </p>
      </div>
    );
  }
}

SmtpSetupByAdminCard.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  intl: intlShape.isRequired,
};

export default injectIntl(SmtpSetupByAdminCard);
