import { combineReducers } from 'redux';
import SlackActionTypes from '../libs/slackActionTypes';

export const item = (state = {}, action = {}) => {
  switch (action.type) {
    case SlackActionTypes.setIntegration:
      return action.item;
    default:
      return state;
  }
};

export const fetching = (state = false, action = {}) => {
  switch (action.type) {
    case SlackActionTypes.setIntegrationFetching:
      return action.fetching;
    default:
      return state;
  }
};

export const fetched = (state = false, action = {}) => {
  switch (action.type) {
    case SlackActionTypes.setIntegrationFetched:
      return action.fetched;
    default:
      return state;
  }
};

export default combineReducers({
  item,
  fetching,
  fetched,
});
