import { combineReducers } from 'redux';
import * as teamManagementReducers from './teamManagementReducers';
import teamMembersRootReducer from './teamMembersRootReducer';
import teamsRootReducer from './teamsRootReducer';

export default combineReducers({
  ...teamManagementReducers,
  teamMembers: teamMembersRootReducer,
  teams: teamsRootReducer,
});
