import SalesforceActionTypes from '../libs/salesforceActionTypes';
import { getSalesforceIntegration as getSalesforceIntegrationCall } from '../services/salesforceService';
import {
  getContactIntegrations,
  setFetching as setContactIntegrationsFetching,
} from 'web/contactIntegrations/actionCreators/contactIntegrationsActionCreators';
import {
  deleteContactIntegration as deleteContactIntegrationCall,
  updateContactIntegration as updateContactIntegrationCall,
} from 'web/contactIntegrations/services/contactIntegrationsService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { SalesforceAlertIds } from 'web/salesforce/libs/salesforceConstants';

export const setIntegration = (integration = {}) => ({
  type: SalesforceActionTypes.setIntegration,
  integration,
});

export const setIntegrationFetching = (fetching = true) => ({
  type: SalesforceActionTypes.setIntegrationFetching,
  fetching,
});

export const setIntegrationFetched = (fetched = true) => ({
  type: SalesforceActionTypes.setIntegrationFetched,
  fetched,
});

export const getSalesforceIntegration = () => (dispatch) => {
  dispatch(setIntegrationFetching());
  return getSalesforceIntegrationCall()
    .then((integration) => {
      dispatch(setIntegration(integration));
      return integration;
    })
    .catch(() => {
      dispatch(openViewAlert(SalesforceAlertIds.somethingWentWrong));
    })
    .finally(() => {
      dispatch(setIntegrationFetching(false));
      dispatch(setIntegrationFetched());
    });
};

export const updateSalesforceContactIntegration = (
  data = {},
  fetch = false
) => (dispatch, getState) => {
  const { item } = getState().entities.salesforce.integration;
  const { service, url, token } = item;
  const newIntegration = { service, url, token, ...data };
  return updateContactIntegrationCall(item.id, newIntegration, fetch).then(
    (integration) => {
      dispatch(setIntegration(integration));
      return integration;
    }
  );
};

export const deleteSalesforceIntegration = () => (dispatch, getState) => {
  const { item } = getState().entities.salesforce.integration;
  return deleteContactIntegrationCall(item.id)
    .then((result) => {
      dispatch({
        type: SalesforceActionTypes.deleteIntegration,
        id: item.id,
      });
      return result;
    })
    .catch(() => {
      dispatch(openViewAlert(SalesforceAlertIds.formChangesNotSaved));
    });
};

export const updateSalesforceIntegration = (data = {}, fetch = false) => (
  dispatch
) => {
  dispatch(setContactIntegrationsFetching(true));
  return dispatch(updateSalesforceContactIntegration(data, fetch))
    .then(async (integration) => {
      await dispatch(getContactIntegrations());
      return integration;
    })
    .catch((error) => {
      dispatch(openViewAlert(SalesforceAlertIds.formChangesNotSaved));
      throw error;
    });
};

export const saveBccForm = (checked = false) => (dispatch, getState) => {
  const { bcc } = getState().entities.salesforce.integration.item;
  const params = { bcc: checked ? bcc || null : null, push_pitches: !checked };
  const fetch = !bcc;
  return dispatch(updateSalesforceIntegration(params, fetch)).then(
    ({ push_pitches: pushPitches, bcc }) => {
      if (!pushPitches && !bcc) {
        dispatch(openViewAlert(SalesforceAlertIds.bccAddressNotProvided));
      } else {
        dispatch(openViewAlert(SalesforceAlertIds.formChangesSaved));
      }
    }
  );
};

export const saveApiForm = (data = {}) => (dispatch) => {
  const params = { ...data, push_pitches: true };
  return dispatch(updateSalesforceIntegration(params)).then(() =>
    dispatch(openViewAlert(SalesforceAlertIds.formChangesSaved))
  );
};

export const saveSyncTasksForm = (data = {}) => (dispatch) =>
  dispatch(updateSalesforceIntegration(data)).then(() =>
    dispatch(openViewAlert(SalesforceAlertIds.formChangesSaved))
  );
