import React from 'react';
import PropTypes from 'prop-types';
import { ColorClasses } from 'libs/constantsStyles';

const AlertCircleFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      height={height}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{`.a { fill: ${color} !important; }`}</style>
      </defs>
      <rect fill={color} height="18" id="Canvas" opacity="0" width="18" />
      <rect fill="#ff13dc" height="18" id="Canvas" opacity="0" width="18" />
      <path
        className="a"
        d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM7.7,3.3875A.35552.35552,0,0,1,7.8685,3.05,3.12313,3.12313,0,0,1,8.981,2.821a3.4307,3.4307,0,0,1,1.116.172.38849.38849,0,0,1,.2.3435v1.225c0,1.4425-.2885,5.4455-.3415,5.9735a.26351.26351,0,0,1-.26528.26171L9.662,10.795H8.3a.284.284,0,0,1-.289-.2365c-.05-.682-.311-4.55-.311-5.9455ZM9,14.425a1.287,1.287,0,0,1-1.4-1.3155,1.33,1.33,0,0,1,1.308-1.35167q.046-.00076.092.00167a1.316,1.316,0,0,1,1.3978,1.22877q.00389.06054.0022.12123A1.287,1.287,0,0,1,9,14.425Z"
      />
    </svg>
  );
};

AlertCircleFilled.defaultProps = {
  color: ColorClasses.white,
  height: 18,
  width: 18,
};

AlertCircleFilled.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default AlertCircleFilled;
