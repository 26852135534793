import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  getCampaignsCategories as getCampaignsCategoriesCall,
  createCampaignsCategory as createCampaignsCategoryCall,
  deleteCampaignCategory as deleteCampaignCategoryCall,
  updateCampaignsCategory as updateCampaignsCategoryCall,
} from '../services/campaignsCategoriesService';
import { LocalStorageKeys } from 'web/libs/constants';
import { openCampaignAlert } from './campaignsAlertActionCreators';
import {
  navigateToCampaignCategory,
  navigateToCampaignDetailsTab,
} from 'web/services/routerService';
import {
  CampaignsTabErrors,
  GlobalPopupIds,
  GlobalAlertIds,
  CampaignManagementActions,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { updateCampaign } from './campaignDetailsActionCreators';
import { updateCampaign as updateCampaignCall } from '../services/campaignService';
import {
  closePopup,
  openPopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { openCreateCampaign } from 'web/campaigns/campaignsTab/actionCreators/campaignsActionCreators';
import {
  commonUserAdminTeamPermission,
  isAdmin,
} from 'web/shared/services/accountService';
import { track } from 'web/services/mixpanelService';
import {
  CampaignActionEvents,
  CategoriesEvents,
  CategoriesTypes,
} from 'web/libs/mixpanelEvents';
import sortBy from 'lodash/sortBy';
import trim from 'lodash/trim';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import languages from 'languages';
import { getCampaignCategoriesViewer } from 'web/reducers/campaigns/campaignSelectors';

export function campaignCategoriesLoading() {
  return {
    type: ActionTypes.state.categoriesLoading,
  };
}

export function campaignCategoriesLoaded() {
  return {
    type: ActionTypes.state.categoriesLoaded,
  };
}

export function campaignCategoriesData(categories) {
  return {
    type: ActionTypes.data.categories,
    categories,
  };
}

export function removeCampaignCategory(data) {
  return {
    type: ActionTypes.campaignsTab.data.removeCategory,
    data,
  };
}

export function selectCurrentCategory(data) {
  if (data) {
    window.localStorage.setItem(
      LocalStorageKeys.campaigns.lastCategoryTab,
      data
    );
  }

  return (dispatch) => {
    dispatch({ type: ActionTypes.category.data.current, data });
  };
}

export function getCampaignsCategories() {
  return (dispatch, getState) => {
    const state = getState();
    const params = getCampaignCategoriesViewer(state);
    const { currentCategory } = state;

    dispatch(campaignCategoriesLoading());
    getCampaignsCategoriesCall(params).then((data) => {
      dispatch(campaignCategoriesLoaded());
      let sortedData = [{ id: -1, name: languages.getStr('common.all') }];
      const categoriesData = sortBy(data, (cat) => cat.name.toLowerCase());
      sortedData = sortedData.concat(categoriesData);

      dispatch(campaignCategoriesData(sortedData));

      if (!currentCategory && sortedData.length > 0) {
        dispatch(selectCurrentCategory(parseInt(sortedData[0].id, 10)));
      } else {
        dispatch(selectCurrentCategory(parseInt(currentCategory, 10)));
      }
    });
  };
}

export function createNewCategory() {
  return (dispatch, getState) => {
    const { campaignsNewCategoryState } = getState();

    dispatch(setPopupLoading(true));
    createCampaignsCategoryCall(trim(campaignsNewCategoryState.name))
      .then((data) => {
        dispatch(setPopupLoading(false));
        dispatch({
          type: ActionTypes.campaignsTab.data.addedCategory,
          data,
        });
        navigateToCampaignCategory(data.id);
        dispatch(selectCurrentCategory(parseInt(data.id, 10)));

        if (campaignsNewCategoryState.isNewCampaign) {
          dispatch(openCreateCampaign());
        } else {
          dispatch(closePopup());
        }

        track(CategoriesEvents.create, { type: CategoriesTypes.campaign });
      })
      .catch((err = { response: { body: { errors: [] } } }) => {
        dispatch(setPopupLoading(false));
        dispatch(
          setPopupAlert(GlobalAlertIds.createNewCampaignCategory, {
            name:
              (err.response.body &&
                err.response.body.errors.length &&
                err.response.body.errors.includes(
                  CampaignsTabErrors.categoryName
                )) ||
              false,
          })
        );
      });
  };
}

export function createNewCategoryOnChange(cat) {
  return {
    type: ActionTypes.campaignsTab.data.newCategoryChange,
    data: {
      name: cat,
    },
  };
}

export function setIsNewCampaign() {
  return {
    type: ActionTypes.campaignsTab.data.newCategoryChange,
    data: {
      isNewCampaign: true,
    },
  };
}

// used a temporary store for an updated campaign category
export function updateCategoryInput(data) {
  return {
    type: ActionTypes.campaignsTab.state.campaignCategoryChange,
    data,
  };
}

export function updateCategory(campaign) {
  return (dispatch, getState) => {
    const {
      campaignsUpdatedCategory,
      campaignsTabSelectedDetailsTab: selectedTab,
    } = getState();
    const updatedCampaign = cloneDeep(campaign);
    updatedCampaign.category_id = campaignsUpdatedCategory.value;
    updatedCampaign.category = campaignsUpdatedCategory.label;

    dispatch(setPopupLoading(true));
    updateCampaignCall(updatedCampaign)
      .then((data) => {
        dispatch(setPopupLoading(false));
        dispatch(updateCampaign(data));
        navigateToCampaignDetailsTab(data.category_id, data.id, selectedTab);
        dispatch(openCampaignAlert(GlobalAlertIds.moveCampaignSuccess));
        dispatch(closePopup());
      })
      .catch(() => {
        dispatch(setPopupLoading(false));
        dispatch(closePopup());
        // error
      });
  };
}

export function deleteCategory(id) {
  return (dispatch, getState) => {
    const campaignsCount = getState().campaigns.filter(
      (campaign) => campaign.category_id === id
    ).length;
    dispatch(
      openPopup(GlobalPopupIds.deleteCampaignCategory, {
        categoryId: id,
        campaignsCount,
      })
    );
  };
}

export function deleteCategoryConfirm(id) {
  return (dispatch, getState) => {
    dispatch(campaignCategoriesLoading());
    dispatch(closePopup());
    deleteCampaignCategoryCall(id)
      .then(() => {
        dispatch(campaignCategoriesLoaded());
        dispatch(removeCampaignCategory(id));
        if (getState().currentCategory === id) {
          navigateToCampaignCategory(-1);
        }
      })
      .catch(() => {
        dispatch(campaignCategoriesLoaded());
      });
  };
}

export function setEditCategory(id) {
  return { type: ActionTypes.campaignsTab.state.editingCategory, id };
}

export function updateCategoryName(id, name) {
  return { type: ActionTypes.campaignsTab.data.updateCategory, id, name };
}

export function renameCategoryConfirm(id, name) {
  return (dispatch, getState) => {
    const originalCategory =
      getState().campaignCategories.find((category) => category.id === id) ||
      {};
    dispatch(setEditCategory());
    dispatch(updateCategoryName(id, name));
    updateCampaignsCategoryCall(id, name).catch(
      (err = { response: { body: { errors: [] } } }) => {
        if (originalCategory.name) {
          dispatch(updateCategoryName(id, originalCategory.name));
        }
        dispatch(
          openCampaignAlert(GlobalAlertIds.renameCategoryError, {
            name:
              (err.response.body &&
                err.response.body.errors.length &&
                err.response.body.errors.includes(
                  CampaignsTabErrors.categoryName
                )) ||
              false,
          })
        );
      }
    );
  };
}

export function isRenameCategoryDisabled(id) {
  return (dispatch, getState) => {
    const {
      user: { id: userId },
      campaignCategories,
    } = getState();
    const currentCategory = find(campaignCategories, { id }) || {};
    return (
      !commonUserAdminTeamPermission(userId) ||
      (!isAdmin() && currentCategory.user_id !== userId)
    );
  };
}

export function openUpdateCategoryPopup() {
  return (dispatch) => {
    dispatch(openPopup(GlobalPopupIds.updateCampaignCategory));
    track(CampaignActionEvents.manageCampaign, {
      'Action Type': CampaignManagementActions.move,
    });
  };
}
