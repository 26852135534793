import React from 'react';
import { connect } from 'react-redux';
import Slack from '../components/slack';
import { hasValidSlackCredentials } from 'web/integrations/slack/selectors/slackSelector';
import {
  getIntegration,
  deleteIntegration,
  openDeleteSlackConfirmation,
  openNavigateToSlackConfirmation,
} from '../actionCreators/slackActionCreators';

const SlackContainer = (props) => <Slack {...props} />;

const mapStateToProps = (state) => ({
  hasValidSlackCredentials: hasValidSlackCredentials(state),
});

const mapDispatchToProps = {
  getIntegration,
  deleteIntegration,
  openDeleteSlackConfirmation,
  openNavigateToSlackConfirmation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlackContainer);
