import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import { isTemplateFavorite } from 'web/templates/services/templateService';
import {
  EditTemplateEmailsTeamsSelect,
  EditTemplateEmailsStateSelect,
} from '../libs/editTemplateSlideOutConstants';
import {
  getNotesFromTemplate,
  getTemplateMappingsFromTemplate,
  parseEmail,
} from '../helpers/editTemplateParsers';

const getDynamicFieldsMap = (state) => state.dynamicFieldsMap;
const getEditTemplate = (state) => state.editTemplateSlideOutTemplate;
const getEditTemplateEmails = (state) => state.editTemplateSlideOutEmails;
const getEditTemplateEmailsStateSelect = (state) =>
  state.editTemplateSlideOutEmailsViewState.stateSelect;
const getEditTemplateEmailsTeamsSelect = (state) =>
  state.editTemplateSlideOutEmailsViewState.teamsSelect;
const getUserSubscriptionUsers = (state) => state.userSubscriptionUsers;
const getViewer = (state) => state.templatesViewer;
const getCurrentUser = (state) => state.user;

export const getTemplate = createSelector(
  [getEditTemplate, getUserSubscriptionUsers, getDynamicFieldsMap, getViewer],
  (editTemplate, userSubscriptionUsers, dynamicFieldsMap, viewer) => {
    const parsedTemplate = cloneDeep(editTemplate);

    if (editTemplate.notes) {
      parsedTemplate.notes = sortBy(
        getNotesFromTemplate(parsedTemplate.notes, userSubscriptionUsers),
        'createdAt'
      ).reverse();
    }

    if (
      editTemplate.enable_mappings > 0 &&
      editTemplate.template_mappings &&
      editTemplate.template_mappings.length
    ) {
      parsedTemplate.enableMappings = editTemplate.enable_mappings;
      parsedTemplate.templateMappings = getTemplateMappingsFromTemplate(
        parsedTemplate.template_mappings,
        dynamicFieldsMap
      );
    } else {
      parsedTemplate.enableMappings = 0;
    }

    parsedTemplate.fileAttachments = editTemplate.file_attachments || [];
    parsedTemplate.userId = editTemplate.user_id || 0;
    parsedTemplate.favorite = isTemplateFavorite(editTemplate, viewer);
    parsedTemplate.teamIds = editTemplate.team_ids || [];

    return parsedTemplate;
  }
);

const getEmailsReduce = (stateSelect) => {
  switch (stateSelect) {
    case EditTemplateEmailsStateSelect.recentEngagement:
      return ({ lastEventAt }) => lastEventAt;
    case EditTemplateEmailsStateSelect.noEngagement:
      return ({ lastEventAt }) => !lastEventAt;
    case EditTemplateEmailsStateSelect.viewed:
      return ({ viewed }) => viewed;
    case EditTemplateEmailsStateSelect.clicked:
      return ({ viewed, clickthrough }) => viewed && clickthrough;
    case EditTemplateEmailsStateSelect.replied:
      return ({ responded, succeeded }) => responded && !succeeded;
    case EditTemplateEmailsStateSelect.success:
      return ({ succeeded }) => succeeded;
    default:
      return () => true;
  }
};

export const getEmails = createSelector(
  [
    getEditTemplateEmails,
    getEditTemplateEmailsTeamsSelect,
    getEditTemplateEmailsStateSelect,
    getViewer,
  ],
  (emails, teamsSelect, stateSelect, user) => {
    let sortedEmails = [...emails.map(parseEmail)];
    if (teamsSelect === EditTemplateEmailsTeamsSelect.me) {
      sortedEmails = sortedEmails.filter((email) => email.userId === user.id);
    }

    if (stateSelect === EditTemplateEmailsStateSelect.recentEmails) {
      return sortBy(sortedEmails, 'deliveredAt', 'lastEventAt').reverse();
    } else {
      const reduce = getEmailsReduce(stateSelect);
      sortedEmails = sortedEmails.reduce((accum, curr) => {
        if (reduce(curr)) {
          accum.push(curr);
        }
        return accum;
      }, []);
      return sortBy(sortedEmails, 'lastEventAt', 'deliveredAt').reverse();
    }
  }
);

export const getUserCanControlBypassUnsubscribe = createSelector(
  [getCurrentUser, getTemplate],
  (user, template) => {
    return user.admin || template.user_id === user.id;
  }
);
