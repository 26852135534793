import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/buttons/action';
import './dynamicChatNotificationCard.scss';

const DynamicChatNotificationCard = (props) => {
  // MT: We know that the `events` array has only 1 event,
  // so we destructure and assign `event` to the first one
  const {
    contact,
    contact: {
      events: [event],
    },
  } = props;
  const {
    action_link: actionLink,
    unseen,
  } = event;
  const { company, name, title } = contact;

  return (
    <div className="livefeed-dynamic-chat-notification-card">
      <div className="contact-info">
        <div className="primary-info">
          <div className="contact-primary-info">{name}</div>
        </div>
        <div className="contact-secondary-info">
          {title} | {company}
        </div>
      </div>

      <hr className="contact-info-separator" />
      <div className="action-info">
        <div className="action-text-holder">
          <div className="tout-icon-conversation" />
          <div className="action-text">
            <FormattedMessage id="web.liveFeed.marketoEvents.notification.message" />
          </div>
        </div>
        <div className="action-link">
          <Button
            onClick={() => {
              window.open(actionLink, '_blank').focus();
            }}
          >
            <FormattedMessage id="web.liveFeed.marketoEvents.notification.actionText" />
          </Button>
        </div>
      </div>
    </div>
  );
};

DynamicChatNotificationCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
};

DynamicChatNotificationCard.defaultProps = {};

export default DynamicChatNotificationCard;
