import { createSelector } from 'reselect';
import moment from 'moment';

const getLiveFeedAnalytics = (state) => state.liveFeedAnalytics;

export const getAnalytics = createSelector(
  getLiveFeedAnalytics,
  (analytics) => {
    const pitchEventsGroups = analytics.events.reduce(
      (groups, { event, pitchId }) => {
        const _groups = groups;
        if (!groups[pitchId]) {
          _groups[pitchId] = { open: 0, click: 0, reply: 0 };
        }

        _groups[pitchId][event] += 1;
        return _groups;
      },
      {}
    );

    return analytics.pitches.reduce((pitchGroups, { deliveredAt, id }) => {
      const date = moment(deliveredAt)
        .startOf('d')
        .toDate();
      const eventsExist = pitchEventsGroups[id];
      const _pitchGroups = pitchGroups;

      if (_pitchGroups[date]) {
        _pitchGroups[date].count += 1;
      } else {
        _pitchGroups[date] = { count: 1, open: 0, click: 0, reply: 0 };
      }

      if (eventsExist) {
        for (const key in eventsExist) {
          if ({}.hasOwnProperty.call(eventsExist, key)) {
            _pitchGroups[date][key] += eventsExist[key];
          }
        }
      }
      return _pitchGroups;
    }, {});
  }
);
