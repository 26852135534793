import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { Field, Form, reduxForm } from 'redux-form';
import EmailBox from 'web/form/components/fields/emailBox';
import Select from 'web/form/components/fields/select';
import Label from 'components/label';
import { formatInviteUsersRequestParams } from '../../../helpers/inviteesHelpers';
import compact from 'lodash/compact';
import './inviteUsersForm.scss';
import I18N from 'languages';

class InviteUsersForm extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
      openSeatsCount,
    } = this.props;
    this.inviteUsersPlaceholder = formatMessage({
      id: 'web.settings.adminSettings.userManagement.invitees.enterEmails',
    });
    this.workspacesPlaceholder = formatMessage({
      id: 'web.marketoSettings.workspaces.search',
    });
    this.workspacesNoResults = formatMessage({
      id: 'web.marketoSettings.workspaces.noResults',
    });
    this.teamsPlaceholder = formatMessage({
      id: 'web.settings.adminSettings.teamManagement.search',
    });
    this.teamsNoResults = formatMessage({
      id: 'web.settings.adminSettings.teamManagement.noResults',
    });

    this.state = {
      seatsCount: openSeatsCount,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    const { seatsCount } = this.state;

    return (
      <Form
        className="invite-users-form"
        onSubmit={handleSubmit(this.onSubmit)}
        noValidate
      >
        {this.getDescription(seatsCount)}
        <Field
          className="invite-users-creatable-area margin-top-10 margin-bottom-10"
          component={EmailBox}
          disabled={!seatsCount}
          name="emails"
          onChange={this.onChange}
          placeholder={this.inviteUsersPlaceholder}
          validate={[this.availableSeatsValidator]}
        />
        {this.getSelectTeams()}
        {this.getSelectWorkspaces()}
      </Form>
    );
  }

  getDescription = (seatsCount) => {
    let descriptionProps;
    let descriptionClass = null;

    if (seatsCount > 0) {
      descriptionProps = {
        id:
          'web.settings.adminSettings.userManagement.invitees.inviteUsers.title',
        values: { seatsCount },
      };
    } else if (seatsCount === 0) {
      descriptionProps = {
        id:
          'web.settings.adminSettings.userManagement.invitees.inviteUsers.zeroOpenSeats',
      };
    } else {
      descriptionClass = 'invite-users-purchase-more-seats';
      descriptionProps = {
        id:
          'web.settings.adminSettings.userManagement.invitees.inviteUsers.purchaseExtraSeats',
        values: { seatsCount: Math.abs(seatsCount) },
      };
    }

    return (
      <div className={descriptionClass}>
        <FormattedHTMLMessage
          className={descriptionClass}
          {...descriptionProps}
        />
      </div>
    );
  };

  availableSeatsValidator = (emails) => {
    const { openSeatsCount } = this.props;
    return openSeatsCount - emails.length < 0 ? true : undefined;
  };

  getSelectTeams = () =>
    this.props.teamsSelectOptions.length ? (
      <Label
        className="invite-users-select-workspace-label padding-top-15"
        id="invite-users-select-teams"
        messageKey="web.settings.adminSettings.teamManagement.teamMembers.assignNewUsers"
      >
        <Field
          clearAllText={I18N.getStr('common.clearAll')}
          component={Select}
          name="teams"
          noResultsText={this.teamsNoResults}
          options={this.props.teamsSelectOptions}
          placeholder={this.teamsPlaceholder}
          multi
        />
      </Label>
    ) : null;

  getSelectWorkspaces = () =>
    this.props.shouldShowWorkspaces ? (
      <Label
        className="invite-users-select-workspace-label padding-top-15"
        id="invite-users-select-workspace"
        messageKey="web.marketoSettings.workspaces.assignNewUsers"
      >
        <Field
          component={Select}
          name="workspaces"
          noResultsText={this.workspacesNoResults}
          options={this.props.workspacesSelectOptions}
          placeholder={this.workspacesPlaceholder}
          multi
        />
      </Label>
    ) : null;

  onChange = (e, emails) => {
    const { openSeatsCount } = this.props;
    this.setState({
      seatsCount: openSeatsCount - emails.length,
    });
  };

  onSubmit = ({ emails = [], workspaces = [], teams = [] }) => {
    const {
      actionCreators: { inviteUsers },
    } = this.props;

    inviteUsers(
      formatInviteUsersRequestParams(emails, compact(workspaces), teams)
    );
  };
}
InviteUsersForm.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  openSeatsCount: PropTypes.number.isRequired,
  shouldShowWorkspaces: PropTypes.bool.isRequired,
  teamsSelectOptions: PropTypes.array.isRequired,
  workspacesSelectOptions: PropTypes.array.isRequired,
};
export default injectIntl(reduxForm()(InviteUsersForm));
