import { createReducer } from '@reduxjs/toolkit';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { setActiveRecordingNotice } from 'web/modals/manageRecordingNotice/actionCreators/recordingNoticeActionCreators';

export function manageRecordingNotice(state = {}, action) {
  switch (action.type) {
    case ActionTypes.api.manageRecordingNotice.success:
      return {
        ...state,
        recordingNotice: action.data.sort((a, b) => b.active - a.active),
      };
    default:
      return state;
  }
}

export const activeRecordingNotice = createReducer(null, {
  [setActiveRecordingNotice.type]: (state, action) => action.payload,
});

export function manageRecordingNoticeCurrentAudio(state = {}, action) {
  switch (action.type) {
    case ActionTypes.api.manageRecordingNoticeCurrentAudio.success:
      return {
        ...state,
        recordingNoticeCurrentAudio: action.data,
      };
    default:
      return state;
  }
}
