import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BlockedReason } from 'libs/constantsShared';
import './blockedPreview.scss';

class BlockedPreview extends Component {
  render() {
    const {
      blockedReason,
      campaignBy,
      campaignName,
      name,
      maxCampaignLimit,
    } = this.props;
    const values = {
      campaignBy,
      campaignByExists: campaignBy && campaignBy.length ? 'true' : 'false',
      campaignName,
      campaignNameExists:
        campaignName && campaignName.length ? 'true' : 'false',
      maxCampaignLimit,
      name,
    };

    return (
      <div className="blockedPreview">
        <div className="tout-icon-warning" />
        <div className="text-medium wording">
          <FormattedMessage
            id={this._getWording(blockedReason)}
            values={values}
          />
        </div>
      </div>
    );
  }

  _getWording(reason) {
    switch (reason) {
      case BlockedReason.alreadyActive:
        return 'campaigns.blockedPreview.alreadyActive';
      case BlockedReason.blockUnsubscribed:
        return 'campaigns.blockedPreview.blockUnsubscribed';
      case BlockedReason.unsubscribed:
        return 'campaigns.blockedPreview.unsubscribed';
      case BlockedReason.domainBlocked:
        return 'campaigns.blockedPreview.domainBlocked';
      case BlockedReason.marketoUnsubscribed:
        return 'campaigns.blockedPreview.marketoUnsubscribed';
      case BlockedReason.bothUnsubscribed:
        return 'campaigns.blockedPreview.bothUnsubscribed';
      case BlockedReason.invalidEmails:
        return 'campaigns.blockedPreview.invalidEmails';
      case BlockedReason.missingEmails:
        return 'campaigns.blockedPreview.missingEmails';
      case BlockedReason.maxCampaignLimitReached:
        return 'campaigns.blockedPreview.maxCampaignLimitReached';
      default:
        return '';
    }
  }
}

BlockedPreview.propTypes = {
  blockedReason: PropTypes.string,
  campaignBy: PropTypes.string,
  campaignName: PropTypes.string,
  name: PropTypes.string.isRequired,
};

BlockedPreview.defaultProps = {
  blockedReason: '',
  campaignBy: '',
  campaignName: '',
};

export default BlockedPreview;
