import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ViewMore from 'components/viewMore';
import LiveFeedEvent from '../liveFeedEvent';
import './liveFeedEvents.scss';

class LiveFeedEvents extends Component {
  render() {
    const {
      events,
      intl: { formatMessage },
      shownCount,
    } = this.props;

    return (
      <ViewMore
        className="live-feed-events background-gray-extra-light"
        items={events}
        itemRenderer={this.itemRenderer}
        showCount
        showLessTextId="web.liveFeed.showLessText"
        showMoreTextId="web.liveFeed.showMoreText"
        itemName={formatMessage(
          { id: 'web.liveFeed.marketoEvents.itemName' },
          { events: events.length - shownCount }
        )}
        shownCount={shownCount}
      />
    );
  }

  itemRenderer = (event, index) => {
    const { id: eventId, ...eventAttrs } = event;
    const key = `live-feed-event-${eventId}-${index}`;
    const { markEventAsSeen } = this.props;

    return (
      <LiveFeedEvent
        key={key}
        markEventAsSeen={markEventAsSeen}
        eventId={eventId}
        {...eventAttrs}
      />
    );
  };
}

LiveFeedEvents.propTypes = {
  events: PropTypes.array.isRequired,
  markEventAsSeen: PropTypes.func,
  intl: intlShape.isRequired,
  shownCount: PropTypes.number,
};

LiveFeedEvents.defaultProps = {
  shownCount: 2,
  markEventAsSeen: () => {},
};

export default injectIntl(LiveFeedEvents);
