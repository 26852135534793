import React from 'react';
import PropTypes from 'prop-types';
import CampaignAnalyticsList from '../campaignAnalyticsList/campaignAnalyticsList';
import {
  parseEmailAnalytics,
  parseCallAnalytics,
  parseTaskAnalytics,
  parseGeneralAnalytics,
} from 'web/campaigns/analytics/libs/campaignAnalyticsParser';
import { injectIntl, intlShape } from 'react-intl';
import './campaignAnalytics.scss';

const CampaignAnalytics = ({ analytics, intl: { formatMessage } }) => (
  <div className="campaign-analytics-ie">
    <div className="campaign-analytics">
      <CampaignAnalyticsList
        analytics={parseGeneralAnalytics(analytics)}
        cardLabel={formatMessage({ id: 'common.campaign' })}
      />
      <CampaignAnalyticsList
        analytics={parseEmailAnalytics(analytics)}
        cardLabel={formatMessage({ id: 'common.emails' })}
      />
      <CampaignAnalyticsList
        analytics={parseCallAnalytics(analytics)}
        cardLabel={formatMessage({ id: 'common.calls' })}
      />
      <CampaignAnalyticsList
        analytics={parseTaskAnalytics(analytics)}
        cardLabel={formatMessage({ id: 'common.tasks' })}
      />
    </div>
  </div>
);

CampaignAnalytics.propTypes = {
  analytics: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CampaignAnalytics);
