export default {
  accountInfo: 'PERSON_DETAILS_ACCOUNT_INFO',
  accountInfoLoading: 'PERSON_DETAILS_ACCOUNT_INFO_LOADING',
  addCustomFieldsRow: 'PERSON_DETAILS_ADD_CUSTOM_FIELDS_ROW',
  addInfoRow: 'PERSON_DETAILS_ADD_INFO_ROW',
  clearTabAlert: 'PERSON_DETAILS_CLEAR_TAB_ALERT',
  close: 'PERSON_DETAILS_CLOSE',
  closeCardEditing: 'PERSON_DETAILS_CLOSE_CARD_EDITING',
  closeCardEditingAll: 'PERSON_DETAILS_CLOSE_CARD_EDITING_ALL',
  deleteCall: 'PERSON_DETAILS_DELETE_CALL',
  deleteNote: 'PERSON_DETAILS_DELETE_NOTE',
  export: 'PERSON_DETAILS_EXPORT',
  init: 'PERSON_DETAILS_INIT',
  open: 'PERSON_DETAILS_OPEN',
  openCardEditing: 'PERSON_DETAILS_OPEN_CARD_EDITING',
  removeCustomFieldsRow: 'PERSON_DETAILS_REMOVE_CUSTOM_FIELDS_ROW',
  removeInfoRow: 'PERSON_DETAILS_REMOVE_INFO_ROW',
  salesforceConnectionData: 'PERSON_DETAILS_SALESFORCE_CONNECTION_DATA',
  setAddressesEditState: 'PERSON_DETAILS_SET_ADDRESSES_EDIT_STATE',
  setCompliance: 'PERSON_DETAILS_SET_COMPLIANCE',
  setEmail: 'PERSON_DETAILS_SET_EMAIL',
  setEmails: 'PERSON_DETAILS_SET_EMAILS',
  setEmailsId: 'PERSON_DETAILS_SET_EMAILS_ID',
  setEmailsLoading: 'PERSON_DETAILS_SET_EMAILS_LOADING',
  setGroupEditState: 'PERSON_DETAILS_SET_GROUP_EDIT_STATE',
  setMarketoCampaign: 'PERSON_DETAILS_SET_MARKETO_CAMPAIGN',
  setMarketoEvents: 'PERSON_DETAILS_SET_MARKETO_EVENTS',
  setMarketoEventsLoading: 'PERSON_DETAILS_SET_MARKETO_EVENTS_LOADING',
  setNextPersonDetails: 'PERSON_DETAILS_SET_NEXT_PERSON_DETAILS',
  setPerson: 'PERSON_DETAILS_SET_PERSON',
  setPersonProperties: 'PERSON_DETAILS_SET_PERSON_PROPERTIES',
  setPersonSalesforceId: 'PERSON_DETAILS_SET_PERSON_SALESFORCE_ID',
  setSalesforceRequiredFields: 'PERSON_DETAILS_SET_SALESFORCE_REQUIRED_FIELDS',
  setTab: 'PERSON_DETAILS_SET_TAB',
  setTabAlert: 'PERSON_DETAILS_SET_TAB_ALERT',
  setTabLoading: 'PERSON_DETAILS_SET_TAB_LOADING',
  setUnsubscribeHistory: 'PERSON_DETAILS_SET_UNSUBSCRIBE_HISTORY',
  unmountNext: 'PERSON_DETAILS_UNMOUNT_NEXT',
  updateAddresses: 'PERSON_DETAILS_UPDATE_ADDRESSES',
  updateAuthorizationComplianceEditState:
    'PERSON_DETAILS_UPDATE_AUTHORIZATION_EDIT_STATE',
  updateCompliance: 'PERSON_DETAILS_UPDATE_COMPLIANCE',
  updateComplianceEditState: 'PERSON_DETAILS_UPDATE_COMPLIANCE_EDIT_STATE',
  updateCustomFieldsEditState: 'PERSON_DETAILS_UPDATE_CUSTOM_FIELDS_EDIT_STATE',
  updateGroups: 'PERSON_DETAILS_UPDATE_GROUPS',
  updateGroupsEditState: 'PERSON_DETAILS_UPDATE_GROUPS_EDIT_STATE',
  updateGroupsEditStateAdd: 'PERSON_DETAILS_UPDATE_GROUPS_EDIT_STATE_ADD',
  updateGroupsEditStateRemove: 'PERSON_DETAILS_UPDATE_GROUPS_EDIT_STATE_REMOVE',
  updateGroupsEditStateRemoveAll:
    'PERSON_DETAILS_UPDATE_GROUPS_EDIT_STATE_REMOVE_ALL',
  updateInfoEditApiKeys: 'PERSON_DETAILS_UPDATE_INFO_API_KEYS',
  updateInfoEditStateAddresses:
    'PERSON_DETAILS_UPDATE_INFO_EDIT_STATE_ADDRESSES',
  updateInfoEditStateContact: 'PERSON_DETAILS_UPDATE_INFO_EDIT_STATE_CONTACT',
  updateInfoEditStateSetPrimary:
    'PERSON_DETAILS_UPDATE_INFO_EDIT_STATE_SET_PRIMARY',
  updateInfoEditStateSocial: 'PERSON_DETAILS_UPDATE_INFO_EDIT_STATE_SOCIAL',
  updateNote: 'PERSON_DETAILS_UPDATE_NOTE',
  updatePitch: 'PERSON_DETAILS_UPDATE_PITCH',
  updateUnsubscribeReason: 'PERSON_DETAILS_UPDATE_UNSUBSCRIBE_REASON',
  updatesalesforceType: 'PERSON_DETAILS_UPDATE_LEAD_OR_CONTACT_TYPE',
};
