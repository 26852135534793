export const DateLabels = {
  today: 'common.today',
  tomorrow: 'common.tomorrow',
  yesterday: 'common.yesterday',
  twoDaysAgo: 'web.liveFeed.tasks.twoDaysAgo',
  threeDaysAgo: 'web.liveFeed.tasks.threeDaysAgo',
  fourDaysAgo: 'web.liveFeed.tasks.fourDaysAgo',
  fiveDaysAgo: 'web.liveFeed.tasks.fiveDaysAgo',
};

export const DateTypes = {
  today: 'today',
  tomorrow: 'tomorrow',
  nextSevenDays: 'nextSevenDays',
  todayAndTomorrow: 'todayAndTomorrow',
  lastThreeDays: 'lastThreeDays',
  yesterday: 'yesterday',
  lastSevenDays: 'lastSevenDays',
};

export const TaskType = {
  call: 'call',
  email: 'email',
  inmail: 'inmail',
  inMail: 'inMail',
};

export const DEFAULT_SEVEN_DAYS = 7;
