import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Label from 'components/label';
import TextButton from 'components/buttons/text';

const ExternalReferenceIdField = ({
  id,
  labelKey,
  labelWidth,
  openExternalReference,
  externalReferenceId,
}) => {
  const getText = (link, text) =>
    link ? (
      <TextButton onClick={link} text={text} />
    ) : (
      <Fragment>{text}</Fragment>
    );

  return (
    <Label
      classWrapper="margin-bottom-half"
      id={id}
      messageKey={labelKey}
      width={labelWidth}
      sideLabel
    >
      {externalReferenceId ? (
        getText(openExternalReference, externalReferenceId)
      ) : (
        <FormattedMessage id="common.noInfo" />
      )}
    </Label>
  );
};

ExternalReferenceIdField.propTypes = {
  externalReferenceId: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  labelWidth: PropTypes.number.isRequired,
  openExternalReference: PropTypes.func,
};

ExternalReferenceIdField.defaultProps = {
  externalReferenceId: null,
  openExternalReference: null,
};

export default ExternalReferenceIdField;
