import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import PropTypes from 'prop-types';
import './feedbackButton.scss';

class FeedbackButton extends Component {
  render() {
    const { hidden } = this.props;

    if (hidden) {
      return null;
    }

    return (
      <Button
        color={ButtonColors.green}
        size={ButtonSizes.medium}
        className="feedback-btn"
        onClick={this.openFeedback}
      >
        <FormattedHTMLMessage id="web.campaigns.feedbackButton" />
      </Button>
    );
  }

  openFeedback = () => {
    const { feedbackURL } = this.props;
    window.open(feedbackURL, '_blank');
  };
}

FeedbackButton.propTypes = {
  feedbackURL: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};

FeedbackButton.defaultProps = {
  hidden: false,
};

export default FeedbackButton;
