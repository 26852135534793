import CommandCenterEmailsActionTypes from 'web/commandCenter/libs/commandCenterEmailsActionTypes';
import { createAction } from '@reduxjs/toolkit';
import {
  navigateToEmailsTab,
  navigateToEmailsSubTab,
} from 'web/services/routerService';
import { stopCampaignForContacts } from 'web/people/services/peopleService';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  emailsBulk,
  archiveEmail as archiveEmailCall,
  unarchiveEmail as unarchiveEmailCall,
  retrySendEmail as retrySendEmailCall,
  markSuccessEmail,
  removeEmail,
} from 'web/emails/services/emailsServices';
import {
  setEmail,
  deleteEmail,
  deleteEmails,
  getEmails,
  setEmailsLoading,
} from 'web/commandCenter/actionCreators/commandCenterEmailsFetchActionCreators';
import {
  EmailStates,
  ARCHIVE_ACTION,
  UNARCHIVE_ACTION,
} from 'web/commandCenter/libs/commandCenterConstants';
import { copySmartFilters } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import CCActionTypes from 'web/commandCenter/libs/commandCenterActionTypes';
import { getUser } from 'web/user/selectors/userSelectors';
import { markUnreadEmailsAsRead as markUnreadEmailsAsReadCall } from 'web/commandCenter/services/emailService';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { CommandCenterPopupIds } from 'web/commandCenter/libs/commandCenterConstants';
import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';
import { setCurrentSavedAdvancedSearchFilters } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/saveAdvancedSearchActionCreators';
import { getSmartFiltersCollection } from 'web/tables/smartFilters/selectors/smartFiltersSelectors';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { resetSelectedEmailId } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/emailGridAdvancedSearchActionCreators';
import { PersonDetailsTabs } from 'web/person/libs/personDetailsConstants';
import { openPersonDetails } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import { getCommandCenterEmailsTabForEmail } from 'web/commandCenter/modules/emailGridAdvancedSearch/helpers/advancedSearchHelpers';

export function setTab(tab = '') {
  return {
    tab,
    type: CommandCenterEmailsActionTypes.setTab,
  };
}

export function setSubTab(subTab = '') {
  return {
    subTab,
    type: CommandCenterEmailsActionTypes.setSubTab,
  };
}

export const onEmailsTabClick = (tab) => () => {
  navigateToEmailsTab(tab);
};

export const onEmailsSubTabClick = (tab, subTab) => () => {
  navigateToEmailsSubTab(tab, subTab);
};

export const bulkMarkSuccess = (ids) => (dispatch) => {
  const params = {
    perform_action: 'success',
    pitch_id: ids,
  };
  dispatch(setEmailsLoading(true));

  return emailsBulk(params)
    .then(() => dispatch(getEmails()))
    .catch(() => {
      dispatch(setEmailsLoading(false));
      dispatch(openViewAlert(EmailAlertIds.fetchError));
    });
};

export const bulkArchive = (ids, isArchive, clearSelectedIds = () => {}) => (
  dispatch
) => {
  const params = {
    perform_action: isArchive ? ARCHIVE_ACTION : UNARCHIVE_ACTION,
    pitch_ids: ids,
  };
  dispatch(setEmailsLoading(true));
  return emailsBulk(params)
    .then(() => dispatch(deleteEmails(ids)))
    .catch(() => {
      const errorId = isArchive
        ? EmailAlertIds.bulkArchiveEmailError
        : EmailAlertIds.bulkUnarchiveEmailError;
      dispatch(openViewAlert(errorId));
    })
    .finally(() => {
      dispatch(setEmailsLoading(false));
      clearSelectedIds();
    });
};

export const bulkRetrySendEmail = (ids) => (dispatch) => {
  const params = {
    perform_action: 'resend',
    pitch_id: ids,
  };
  dispatch(setEmailsLoading(true));

  return emailsBulk(params)
    .then(() => ids.forEach((emailId) => dispatch(deleteEmail(+emailId))))
    .catch(() => dispatch(openViewAlert(EmailAlertIds.retrySendError)))
    .finally(() => dispatch(setEmailsLoading(false)));
};

export const archiveEmail = (id) => (dispatch) => {
  dispatch(setEmailsLoading(true));

  return archiveEmailCall(id)
    .then(() => dispatch(getEmails()))
    .catch(() => {
      dispatch(setEmailsLoading(false));
      dispatch(openViewAlert(EmailAlertIds.archiveEmailError));
    });
};

export const unarchiveEmail = (id) => (dispatch) => {
  dispatch(setEmailsLoading(true));

  return unarchiveEmailCall(id)
    .then(() => {
      dispatch(setEmailsLoading(false));
      dispatch(deleteEmail(id));
    })
    .catch(() => {
      dispatch(setEmailsLoading(false));
      dispatch(openViewAlert(EmailAlertIds.unArchiveEmailError));
    });
};

export const markSuccess = (emailId) => (dispatch) => {
  dispatch(setEmailsLoading(true));

  return markSuccessEmail(emailId)
    .then(() => {
      dispatch(setEmailsLoading(false));
      dispatch(
        setEmail({ id: emailId, state: EmailStates.successful, success: true })
      );
    })
    .catch(() => {
      dispatch(setEmailsLoading(false));
      dispatch(openViewAlert(EmailAlertIds.markSuccessError));
    });
};

export const retrySend = (emailId) => (dispatch) => {
  dispatch(setEmailsLoading(true));

  return retrySendEmailCall(emailId)
    .then(() => dispatch(deleteEmail(emailId)))
    .catch(() => dispatch(openViewAlert(EmailAlertIds.retrySendError)))
    .finally(() => dispatch(setEmailsLoading(false)));
};

export const resetSidebarViewAsFilter = () => ({
  isChanged: false,
  type: CCActionTypes.state.changeViewAsFilter,
});

export const setUnreadEmailsCount = createAction('SET_UNREAD_EMAILS_COUNT');

export const markUnreadEmailsAsRead = () => (dispatch, getState) => {
  const state = getState();
  const { id: userId } = getUser(state);
  const { emailGridData } = state;
  const emailIds = emailGridData
    .filter((email) => email.unread)
    .map(({ id }) => id);
  if (!emailIds.length) {
    // if there is no unread emails, we don't want to send request, but it's not an error case
    return Promise.resolve();
  }
  return markUnreadEmailsAsReadCall(emailIds, userId).catch(() => {});
};

export const removePitch = (emailId) => (dispatch) => {
  dispatch(setEmailsLoading(true));

  return removeEmail(emailId)
    .then(() => {
      dispatch(setEmailsLoading(false));
      dispatch(deleteEmail(emailId));
    })
    .catch(() => {
      dispatch(setEmailsLoading(false));
      dispatch(openViewAlert(EmailAlertIds.deleteEmailError));
    });
};

export const stopWorkflowForContacts = (
  emailId,
  peopleIds,
  success = false
) => (dispatch) => {
  dispatch(closePopup());
  dispatch(setEmailsLoading(true));
  return stopCampaignForContacts(peopleIds, success)
    .then(() => {
      dispatch(setEmailsLoading(false));
      dispatch(deleteEmail(emailId));
    })
    .catch(() => {
      dispatch(setEmailsLoading(false));
      dispatch(openViewAlert(EmailAlertIds.deleteEmailError));
    });
};

export const openAdvancedSearchFormPopup = () => (dispatch, getState) => {
  dispatch(
    copySmartFilters({
      collectionFromId: getActiveEmailTableId(getState()),
      collectionToId: ADVANCED_SEARCH_FILTERS_COLLECTION,
    })
  );
  dispatch(openPopup(CommandCenterPopupIds.advancedSearch));

  const smartFiltersCollection = getSmartFiltersCollection(getState(), {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  });

  dispatch(
    setCurrentSavedAdvancedSearchFilters({ filters: smartFiltersCollection })
  );
};

export const handleSelectedEmail = (emails) => (dispatch, getState) => {
  const { emailGridSelectedEmailId } = getState();

  if (emailGridSelectedEmailId && emails.length) {
    const selectedEmail = emails.find((x) => x.id == emailGridSelectedEmailId);
    if (selectedEmail) {
      const { person_id: personId, id: emailId } = selectedEmail;
      dispatch(
        openPersonDetails(personId, { emailId, tab: PersonDetailsTabs.history })
      );

      const emailTab = getCommandCenterEmailsTabForEmail(selectedEmail);
      dispatch(setTab(emailTab.tab));
      dispatch(setSubTab(emailTab.subTab));
    }
  }

  dispatch(resetSelectedEmailId());
};
