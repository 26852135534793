import { getCategoriesTemplates } from 'web/categories/services/categoriesTemplatesService';
import { setTemplateCategories } from 'web/templates/actionCreators/templateCategoriesActionCreators';

export const getTemplateCategories = (force = false) => (
  dispatch,
  getState
) => {
  const { templatesCategories } = getState();

  if (force || templatesCategories.length === 0) {
    return getCategoriesTemplates().then((categories) => {
      dispatch(setTemplateCategories(categories));
    });
  } else {
    return Promise.resolve();
  }
};
