import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { closeModalDynamicFields } from '..';

// common to other modals
export const openModal = (id, data) => ({
  type: ActionTypes.modal.state.open,
  id,
  data,
});

export const closeModal = (isInitialClear = false) => (dispatch) => {
  dispatch({ type: ActionTypes.modal.state.close });
  dispatch(closePopup());

  if (!isInitialClear) {
    closeModalDynamicFields();
  }
};
