import ActionTypes from 'web/libs/actionTypes/actionTypes';
import uniq from 'lodash/uniq';
import {
  AddToCampaignErrorMessages,
  ModalOverlayIds,
} from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import {
  hidePopupOverlay,
  setPopupOverlay,
} from 'web/popup/actionCreators/popupActionCreators';
import { bindSalesforceImport } from 'web/modals/addToCampaign/actionCreators/pusherActionCreators';
import {
  parseAndAddPeople,
  addPersonObject,
} from 'web/people/peopleSearchAdd/actionCreators/peopleActionCreators';
import { addGroupData } from 'web/people/peopleSearchAdd/actionCreators/groupsActionCreators';
import {
  getPeopleByGroup as getPeopleByGroupCall,
  getPeopleByIds as getPeopleByIdsCall,
  getPeopleBySalesforceIds as getPeopleBySalesforceIdsCall,
  getPeopleBySalesforceIdsWithoutImport as getPeopleBySalesforceIdsWithoutImportCall,
  searchPeople as searchPeopleCall,
  findOrCreateContact,
} from 'web/people/services/peopleService';
import { SourceOpenerIds } from 'libs/constantsShared';
import { parseAutocompleteContacts } from 'web/services/parsers';
import { Regex } from 'web/libs/constants';

const tryAddPeople = (people = []) => (dispatch) => {
  if (!people.length) {
    throw new Error(AddToCampaignErrorMessages.notAToutContact);
  }

  dispatch(parseAndAddPeople(people));
};

export const getPeopleDataByGroup = (groupId) => (dispatch) => {
  const intId = parseInt(groupId, 10);

  return getPeopleByGroupCall(intId).then(({ group, people } = {}) => {
    dispatch(addGroupData(group, people));
  });
};

export const getPeopleDataByIds = (ids) => (dispatch) =>
  getPeopleByIdsCall(ids).then((people) => {
    dispatch(tryAddPeople(people));
  });

export const tryAddOrCreatePeople = (people, email) => (dispatch) => {
  if (!people.length) {
    if (!email.match(Regex.email)) {
      return dispatch(
        setPopupOverlay(ModalOverlayIds.notValidEmail, {
          textId: 'web.campaigns.addPeopleNeedsToBeEmail',
        })
      );
    } else {
      return findOrCreateContact(email).then((data = {}) => {
        const parsedData = parseAutocompleteContacts([data]);
        if (parsedData.length) {
          dispatch(addPersonObject(parsedData[0]));
        } else {
          throw new Error(
            "Can't parse contact object returned by 'findOrCreateContact' service"
          );
        }
      });
    }
  } else {
    return dispatch(tryAddPeople(people));
  }
};

export const getPeopleDataByEmail = (email) => (dispatch, getState) => {
  const { addToCampaignSourceOpener: sourceOpener } = getState();
  const isOWASourceOpener =
    sourceOpener &&
    sourceOpener.toLowerCase() === SourceOpenerIds.owa.toLowerCase();

  if (isOWASourceOpener) {
    const newEmail = email[0];
    return searchPeopleCall(newEmail).then((people) =>
      dispatch(tryAddOrCreatePeople(people, newEmail))
    );
  } else {
    return searchPeopleCall(email).then((people) => {
      dispatch(tryAddPeople(people));
    });
  }
};

export function getPeopleDataBySalesforceIdsWithoutImport(
  salesforceMultipleRecipients
) {
  return (dispatch) =>
    getPeopleBySalesforceIdsWithoutImportCall(salesforceMultipleRecipients)
      .then((people) => {
        dispatch(tryAddPeople(people));
      })
      .catch(() => {
        dispatch(
          setPopupOverlay(ModalOverlayIds.salesConnectError, {
            textId: 'web.addToCampaign.salesConnectError',
          })
        );
      });
}

export function getPeopleDataBySalesforceIds(salesforceMultipleRecipients) {
  return (dispatch) =>
    getPeopleBySalesforceIdsCall(salesforceMultipleRecipients)
      .then(({ job_id: jobId }) => {
        dispatch(bindSalesforceImport(jobId));
      })
      .catch((err = { response: { body: '' } }) => {
        dispatch(
          setPopupOverlay(ModalOverlayIds.salesforceError, {
            text: err.response.body,
          })
        );
      });
}

export function retrySalesforceCall() {
  return (dispatch, getState) => {
    const {
      addToCampaignModalPreselectedIds: { salesforce },
    } = getState();
    dispatch(hidePopupOverlay());
    dispatch(getPeopleDataBySalesforceIds(salesforce));
  };
}

export function getNonSalesforcePeopleData(preselectedIds) {
  return (dispatch) => {
    const promises = [];
    if (preselectedIds.group.length) {
      uniq(preselectedIds.group).forEach((groupId) => {
        promises.push(dispatch(getPeopleDataByGroup(groupId)));
      });
    }

    if (preselectedIds.people.length) {
      promises.push(dispatch(getPeopleDataByIds(uniq(preselectedIds.people))));
    } else if (preselectedIds.email.length) {
      promises.push(dispatch(getPeopleDataByEmail(uniq(preselectedIds.email))));
    }

    return Promise.all(promises);
  };
}

export const setPreselectedIds = (preselectedIds) => ({
  type: ActionTypes.addToCampaignModal.state.preselectedIds,
  preselectedIds,
});
