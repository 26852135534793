import { setSmartFilterCollection } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { markEmailSelectedSavedAdvancedSearchAsChanged } from './openAdvancedSearchActionCreators';
import { canResetSavedAdvabcedSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';

export const clearAdvancedSearchFilters = () => (dispatch, getState) => {
  dispatch(
    setSmartFilterCollection({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      samrtFiltersCollection: {},
    })
  );

  if (canResetSavedAdvabcedSearch(getState())) {
    dispatch(markEmailSelectedSavedAdvancedSearchAsChanged());
  }
};
