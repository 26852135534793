import {
  AddToCampaignErrors,
  SalesforceFailureKeys,
} from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';

// Failure - we processed everything OK internally, but our business logic determined that these
// people can't go on campaigns (400 error-ish)
export function parseSalesforceFailures(failures = {}) {
  if (failures[SalesforceFailureKeys.emailAndPhone]) {
    return 'web.addToCampaign.emailAndPhoneFailure';
  } else if (failures[SalesforceFailureKeys.userOptdOut]) {
    return 'web.addToCampaign.allUnsubscribes';
  } else {
    return 'web.addToCampaign.allFailures';
  }
}

// Error - something broke internally, we weren't able to follow our internal business logic
// because of an error communicating with salesforce (500 error-ish)
export function parseSalesforceErrors(error = '') {
  if (error === AddToCampaignErrors.salesforce.notConnected) {
    return 'web.addToCampaign.salesforceNotConnectedError';
  } else {
    return 'web.addToCampaign.salesforceError';
  }
}
