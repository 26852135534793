import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { AddressTypes } from 'web/person/libs/personDetailsConstants';
import Label from 'components/label';
import Text from 'components/inputs/text';
import ExternalReferenceIdField from 'web/person/components/personDetails/infoCard/editing/externalReferenceIdField/externalReferenceIdField';

class InfoCardEditingSocial extends Component {
  constructor(props) {
    super(props);
    this.placeholderText = this.props.intl.formatMessage({
      id: 'web.person.personDetails.infoCard.socialPlaceholder',
    });
  }

  render() {
    const {
      editState: { linkedin, salesforceId, marketoId },
      isNewContact,
      labelWidth,
      openInMarketo,
      openInSalesforce,
    } = this.props;

    return (
      <div className="person-details-info-card-editing-contact">
        <h4 className="margin-bottom-half">
          <FormattedMessage id="web.person.personDetails.contact.socialMedia" />
        </h4>
        {!isNewContact && (
          <Fragment>
            <ExternalReferenceIdField
              externalReferenceId={salesforceId}
              id="salesforce-id-field"
              labelKey="web.person.personDetails.contact.salesforce"
              labelWidth={labelWidth}
              openExternalReference={openInSalesforce}
            />
            <ExternalReferenceIdField
              externalReferenceId={marketoId}
              id="marketo-id-field"
              labelKey="web.person.personDetails.contact.marketo"
              labelWidth={labelWidth}
              openExternalReference={openInMarketo}
            />
          </Fragment>
        )}
        <Label
          classWrapper="margin-bottom-half"
          id="person-details-linkedin"
          messageKey="web.person.personDetails.contact.linkedin"
          width={labelWidth}
          sideLabel
        >
          <Text
            autoFocus={false}
            id="person-details-linkedin"
            onBlur={this.onBlurLinkedin}
            onChange={this.updateLinkedin}
            placeholder={this.placeholderText}
            type="text"
            value={linkedin.value}
          />
        </Label>
      </div>
    );
  }

  updateLinkedin = (linkedin) =>
    this.props.updateSocial(AddressTypes.linkedin, linkedin);

  onBlurLinkedin = () =>
    this.props.validateAddress(
      AddressTypes.linkedin,
      this.props.editState.linkedin.value
    );
}

InfoCardEditingSocial.propTypes = {
  editState: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isNewContact: PropTypes.bool,
  labelWidth: PropTypes.number.isRequired,
  openInMarketo: PropTypes.func.isRequired,
  openInSalesforce: PropTypes.func.isRequired,
  updateSocial: PropTypes.func.isRequired,
  validateAddress: PropTypes.func.isRequired,
};

InfoCardEditingSocial.defaultProps = {
  isNewContact: false,
};

export default injectIntl(InfoCardEditingSocial);
