import { BaseUrl } from './environment';
import isEmpty from 'lodash/isEmpty';

export const getMetaTagContentValue = (name) => {
  const metaSelector = `meta[name="${name}"]`;
  let metaValue = null;
  if (document.querySelector(metaSelector)) {
    metaValue = document.querySelector(metaSelector).getAttribute('content');
  }
  return metaValue;
};

export const isLoadedInIframe = (windowName = '') => {
  if (!isEmpty(windowName)) {
    return window.self !== window.top && window.name.includes(windowName);
  } else {
    return window.self !== window.top;
  }
};

export const sendMessageToWindow = ({
  dest,
  msg,
  target = null,
  destWinName = null,
  afterHandler = null,
}) => {
  if (
    dest &&
    msg &&
    (!destWinName ||
      (destWinName && dest.name && dest.name.includes(destWinName)))
  ) {
    dest.postMessage(msg, target || BaseUrl);
    afterHandler && afterHandler();
  }
};
