import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget as dropTarget } from 'react-dnd';
import { DragTypes } from 'web/libs/constants';
import './campaignStepDropZone.scss';
import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

const stepTarget = {
  canDrop(props) {
    return !!props.day;
  },
  drop(props, monitor) {
    const item = monitor.getItem();
    if (props.day !== item.step.day || item.noop.indexOf(props.order) === -1) {
      const newStep = cloneDeep(item.step);
      newStep.day = props.day;
      newStep.order = props.order;
      props.actionCreators.optimisticUpdateCampaignStep(newStep);
    }
  },
};

function collect(connect, monitor) {
  const item = monitor.getItem();
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    isDragging: !!item,
  };
}

class CampaignStepDropZone extends Component {
  constructor() {
    super();
    this.state = { showTransparentDropzone: false };
    this._toggleTransparentDropzone = this._toggleTransparentDropzone.bind(
      this
    );
  }

  render() {
    const { canDrop, connectDropTarget, hideNoDrop, isOver } = this.props;
    this._toggleTransparentDropzone();

    return connectDropTarget(
      <div className="campaign-step-drop-zone">
        <div
          className={classnames({
            'blue-drop': isOver && canDrop,
            'gray-drop': !isOver && canDrop,
            'no-drop': !canDrop && !hideNoDrop,
          })}
        />
        {this.state.showTransparentDropzone && (
          <div className={classnames('transparent-dropzone')} />
        )}
      </div>
    );
  }

  _toggleTransparentDropzone() {
    const { isDragging } = this.props;
    const { showTransparentDropzone } = this.state;
    if (isDragging !== showTransparentDropzone) {
      setTimeout(() => {
        this.setState({ showTransparentDropzone: isDragging });
      }, 0);
    }
  }
}

CampaignStepDropZone.propTypes = {
  hideNoDrop: PropTypes.bool,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // eslint-disable-line react/no-unused-prop-types
  isDragging: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
  actionCreators: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
};

CampaignStepDropZone.defaultProps = {
  hideNoDrop: undefined,
  day: undefined,
};

export default dropTarget(DragTypes.step, stepTarget, collect)(
  CampaignStepDropZone
);
