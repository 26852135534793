import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { deleteFiles as deleteFilesCall } from '../services/attachmentsService';

// Optimistically delete files - done in this file b/c specific to emailComposeBody (w/ matching func)
export function deleteFiles(fileIds = []) {
  return (dispatch) => {
    let ids = fileIds;
    if (typeof fileIds === 'number') {
      ids = [fileIds];
    }

    dispatch({ type: ActionTypes.common.data.contentDelete, ids });
    deleteFilesCall(ids); // used to do getContent() call
  };
}
