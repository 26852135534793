/**
 *
 * TableCheckbox
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/buttons/checkbox';
import './tableCheckbox.scss';

class TableCheckbox extends PureComponent {
  render() {
    const { checked, disabled, name, onChange } = this.props;

    return (
      <div className="tout-table-checkbox">
        <Checkbox
          checked={checked}
          classes="tout-table-checkbox-classes"
          classesLabel="tout-table-checkbox-classes-label"
          disabled={disabled}
          name={name}
          onChange={onChange}
        />
      </div>
    );
  }
}

TableCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TableCheckbox.defaultProps = {
  disabled: false,
};

export default TableCheckbox;
