import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import fieldWithTooltip from 'web/form/hoc/fieldWithTooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './passwordWithTooltip.scss';
import {
  minLength as minLengthFactory,
  regExpTest as regExpTestFactory,
} from 'web/form/helpers/validators';
import { ValidationErrorMessageIds } from 'web/form/libs/validatorsConstants';
import {
  minPasswordLength,
  passwordMinLengthValidator,
  passwordContainsNumberValidator,
  passwordContainsSpecialSymbolValidator,
  passwordContainslowerUpperCaseValidator,
} from 'web/form/libs/passwordValidators';

const PasswordWithTooltip = (propsHOC) => (WrappedComponent) => {
  class PasswordWithTooltipHOC extends Component {
    WrappedComponentWithTooltip = fieldWithTooltip(WrappedComponent);

    getTooltipContent = () => {
      const {
        input: { value },
      } = this.props;

      const passwordRequirementItems = [
        {
          messageId: ValidationErrorMessageIds.minLength,
          isMatch: value && !passwordMinLengthValidator(value),
        },
        {
          messageId:
            'createAccount.form.passwordTooltip.containsLowerUpperCase',
          isMatch: value && !passwordContainslowerUpperCaseValidator(value),
        },
        {
          messageId: 'createAccount.form.passwordTooltip.containsNumber',
          isMatch: value && !passwordContainsNumberValidator(value),
        },
        {
          messageId: 'createAccount.form.passwordTooltip.containsSpecialSymbol',
          isMatch: value && !passwordContainsSpecialSymbolValidator(value),
        },
      ];

      return (
        <div className="password-field-tooltip">
          <p>
            <FormattedMessage id="form.password.tooltip.header" />
          </p>
          <ul>
            {passwordRequirementItems.map((item) => (
              <li
                key={item.messageId}
                className={classNames('tout-icon-tick', {
                  green: item.isMatch,
                })}
              >
                <FormattedMessage
                  id={item.messageId}
                  values={{ min: minPasswordLength }}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    };

    render() {
      const {
        meta: { invalid, active, touched },
      } = this.props;

      const touchedProps = {
        getTooltipContent: this.getTooltipContent,
        showTooltip: active || (touched && invalid),
        ...propsHOC,
      };

      return (
        <this.WrappedComponentWithTooltip {...this.props} {...touchedProps} />
      );
    }
  }

  PasswordWithTooltipHOC.propTypes = {
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      active: PropTypes.bool.isRequired,
    }).isRequired,
  };

  PasswordWithTooltipHOC.defaultProps = {};
  return PasswordWithTooltipHOC;
};
export default PasswordWithTooltip;
