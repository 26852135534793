export default {
  sidebarViewer: {
    sidebarFilterInput: {
      set: 'SIDEBAR_FILTER_INPUT_SET',
    },
    viewer: {
      set: 'VIEWER_SET',
    },
  },
};
