import { TEMPLATE_SPECIAL_CATEGORY } from 'web/templates/libs/templatesConstants';

export const TEMPLATES_CATEGORY_MAP = Object.keys(
  TEMPLATE_SPECIAL_CATEGORY
).reduce((acc, categoryKey) => {
  const category = TEMPLATE_SPECIAL_CATEGORY[categoryKey];
  return category.id
    ? {
        ...acc,
        [category.id]: category.apiName.toLowerCase(),
      }
    : acc;
}, {});

export const templatesCategoryMap = (categoryId) =>
  TEMPLATES_CATEGORY_MAP[categoryId] || categoryId;

export const isSpecialCategory = (cat) => {
  if (cat && typeof cat === 'object') {
    const { name } = cat;
    return (
      name === TEMPLATE_SPECIAL_CATEGORY.general.apiName ||
      name === TEMPLATE_SPECIAL_CATEGORY.archived.apiName ||
      name === TEMPLATE_SPECIAL_CATEGORY.all.apiName ||
      name === TEMPLATE_SPECIAL_CATEGORY.favorites.apiName
    );
  } else if (cat && typeof cat === 'string') {
    return (
      cat === TEMPLATE_SPECIAL_CATEGORY.general.apiName ||
      cat === TEMPLATE_SPECIAL_CATEGORY.archived.apiName ||
      cat === TEMPLATE_SPECIAL_CATEGORY.all.apiName ||
      cat === TEMPLATE_SPECIAL_CATEGORY.favorites.apiName
    );
  } else {
    return false;
  }
};

export const isSharedTemplate = (template) => {
  const teamIds = template.team_ids || template.teamIds;

  return Array.isArray(teamIds) && teamIds.length > 0;
};

export const isUnsharedTemplate = (template) => {
  const teamIds = template.team_ids || template.teamIds;

  return Array.isArray(teamIds) && teamIds.length === 0;
};

export const isUnsharedTemplateForAdmin = (template, user) =>
  user.admin && isUnsharedTemplate(template);
