import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  shareCampaign as shareCampaignCall,
  getActiveCountForTeams,
} from '../services/campaignService';
import { openCampaignAlert } from 'web/campaigns/campaignsTab/actionCreators/campaignsAlertActionCreators';
import {
  closePopup,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { track } from 'web/services/mixpanelService';
import { CampaignActionEvents } from 'web/libs/mixpanelEvents';
import {
  CampaignManagementActions,
  GlobalAlertIds,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';

export function shareCampaignWarning(data = {}) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.campaignsTab.state.unshareCampaignWarning,
      data,
    });
  };
}

export function updateCampaignSharedWith(ids) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.campaignsTab.data.newSharedWith,
      data: ids,
    });
  };
}

export function campaignShareComplete(id) {
  return (dispatch) => {
    dispatch(openCampaignAlert(id));
    dispatch(closePopup());
    dispatch(setPopupLoading(false));
  };
}

export function confirmShareCampaign(campaign) {
  return (dispatch, getState) => {
    dispatch(shareCampaignWarning());
    const updatedCampaign = cloneDeep(campaign);

    updatedCampaign.team_ids = getState().campaignsNewSharedWith;

    dispatch({
      type: ActionTypes.common.data.campaignUpdate,
      data: updatedCampaign,
    });

    dispatch(setPopupLoading(true));
    shareCampaignCall(updatedCampaign.id, {
      team_ids: updatedCampaign.team_ids,
    })
      .then(() => {
        track(CampaignActionEvents.manageCampaign, {
          'Action Type': CampaignManagementActions.share,
        });
        dispatch(campaignShareComplete(GlobalAlertIds.shareCampaignSuccess));
      })
      .catch(() => {
        dispatch(campaignShareComplete(GlobalAlertIds.shareCampaignError));
      });
  };
}

export function shareCampaign(campaign) {
  return (dispatch, getState) => {
    const updatedCampaign = cloneDeep(campaign);
    const { campaignsNewSharedWith } = getState();
    const unsharedTeams = difference(
      updatedCampaign.team_ids,
      campaignsNewSharedWith
    );
    const activeCount = getActiveCountForTeams(updatedCampaign, unsharedTeams);
    if (unsharedTeams.length && activeCount) {
      dispatch(shareCampaignWarning({ activeCount }));
    } else {
      dispatch(confirmShareCampaign(campaign));
    }
  };
}
