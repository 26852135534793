import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { injectIntl, intlShape } from 'react-intl';
import { Align } from 'table/modules/constants';
import ToutTable from 'table/modules/toutTable';
import TextCell from 'table/modules/cells/textCell';
import { getImageUrl } from 'web/libs/constants';
import { alphabetical } from 'table/modules/sorting';
import { getDeliveryChannelLabel } from 'web/deliveryChannels/helpers/identitiesHelper';
import LinkOrTextCell from './cells/linkOrTextCell';
import './identitiesTable.scss';

import {
  IdentitiesTableColumns,
  TABLE_ID,
  CURRENT_PAGE,
  PER_PAGE,
  ROW_HEIGHT,
} from '../libs/identitiesTableConstants';

class IdentitiesTable extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
    } = props;

    this.columnNameLabel = formatMessage({ id: 'common.name' });
    this.columnEmailLabel = formatMessage({ id: 'common.email' });
    this.columnVerifiedLabel = formatMessage({ id: 'common.verified' });
    this.columnDefaultLabel = formatMessage({ id: 'common.default' });
    this.columnDeliveryChannelLabel = formatMessage({
      id: 'common.deliveryChannels',
    });
    this.columnActionsLabel = formatMessage({ id: 'common.actions' });

    this.resendLabel = formatMessage({ id: 'web.identities.resendEmail' });
    this.verifiedLabel = formatMessage({ id: 'web.identities.verifiedEmail' });
    this.defaultLabel = formatMessage({ id: 'common.default' });
    this.makeDefaultLabel = formatMessage({ id: 'web.identities.makeDefault' });
    this.delete = formatMessage({ id: 'common.delete' });
  }

  render() {
    const columns = this.getColumns();

    const { onSelectIdentity, identities, fetching, loading } = this.props;

    return (
      <div className="identities-table-wrapper">
        <ToutTable
          allItems
          columns={columns}
          items={identities}
          fetching={fetching}
          loading={loading}
          loadingGetUrl={getImageUrl}
          defaultSortingColumn={IdentitiesTableColumns.name}
          pagination={{
            currentPage: CURRENT_PAGE,
            perPage: PER_PAGE,
          }}
          row={{
            onClick: onSelectIdentity,
            height: ROW_HEIGHT,
          }}
          tableId={TABLE_ID}
        />
      </div>
    );
  }

  onMakeDefault = (identity) => {
    const { actionCreators } = this.props;
    return identity.unverified
      ? actionCreators.openIdentityMakeDefaultErrorPopup()
      : actionCreators.makeDefault(identity);
  };

  getVerifiedLabel = (identity) =>
    identity.unverified ? this.resendLabel : this.verifiedLabel;

  getDefaultLabel = (identity) =>
    identity.isDefault ? this.defaultLabel : this.makeDefaultLabel;

  getActionsLabel = (identity) => (identity.isDefault ? '' : this.delete);

  getColumns() {
    const {
      actionCreators: {
        openResendConfirmationPopup,
        navigateToDeliverabilitySection,
        navigateToEmailConnection,
        openIdentityRemovePopup,
      },
      deliveryChannels,
      intl: { formatMessage },
      shouldHideDeliveryChannels,
    } = this.props;

    const columns = [
      {
        align: Align.LEFT,
        id: IdentitiesTableColumns.name,
        name: this.columnNameLabel,
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'name',
            wrapLines: 2,
          },
        },
        sorting: alphabetical('name'),
        maxWidth: 350,
      },
      {
        align: Align.LEFT,
        id: IdentitiesTableColumns.email,
        name: this.columnEmailLabel,
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'email',
            wrapLines: 2,
          },
        },
        sorting: alphabetical('email'),
        maxWidth: 320,
      },
      {
        align: Align.LEFT,
        id: IdentitiesTableColumns.verified,
        name: this.columnVerifiedLabel,
        rowCell: {
          component: LinkOrTextCell,
          metadata: {
            formatText: this.getVerifiedLabel,
            onClick: openResendConfirmationPopup,
            property: 'unverified',
            showLink: (identity) => identity.unverified,
          },
        },
        width: 200,
      },
      {
        align: Align.LEFT,
        id: IdentitiesTableColumns.defaultIdentity,
        name: this.columnDefaultLabel,
        rowCell: {
          component: LinkOrTextCell,
          metadata: {
            formatText: this.getDefaultLabel,
            onClick: this.onMakeDefault,
            property: 'isDefault',
            showLink: (identity) => !identity.isDefault,
          },
        },
        width: 160,
      },
      {
        align: Align.LEFT,
        id: IdentitiesTableColumns.deliveryChannel,
        name: this.columnDeliveryChannelLabel,
        rowCell: {
          component: LinkOrTextCell,
          metadata: {
            formatText: (identity) =>
              getDeliveryChannelLabel({
                deliveryChannels,
                deliveryChannel: deliveryChannels.find(
                  (channel) => channel.id === identity.authentication_id
                ),
                formatMessage,
                shouldHideDeliveryChannels,
              }),
            onClick: deliveryChannels.length
              ? navigateToDeliverabilitySection
              : navigateToEmailConnection,
            property: 'authentication_id',
            showLink: (identity) =>
              shouldHideDeliveryChannels && !identity.authentication_id,
          },
        },
        width: 300,
      },
      {
        align: Align.LEFT,
        id: IdentitiesTableColumns.actions,
        name: this.columnActionsLabel,
        rowCell: {
          component: LinkOrTextCell,
          metadata: {
            formatText: this.getActionsLabel,
            onClick: openIdentityRemovePopup,
            showLink: (identity) => !identity.isDefault,
          },
        },
        width: 120,
      },
    ];

    return columns;
  }
}

IdentitiesTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  shouldHideDeliveryChannels: PropTypes.bool.isRequired,
  identities: PropTypes.array.isRequired,
  onSelectIdentity: PropTypes.func.isRequired,
  deliveryChannels: PropTypes.array.isRequired,
  fetching: PropTypes.bool,
  loading: PropTypes.bool,
};

IdentitiesTable.defaultValues = {
  fetching: false,
  loading: false,
};

export default injectIntl(IdentitiesTable);
