import { baseGet, basePost, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getDomainBlocks(data) {
  return new Promise((resolve, reject) => {
    baseGet(Urls.admin.domainBlocks.get, data, resolve, reject);
  });
}

export function postDomainBlock(data) {
  return new Promise((resolve, reject) => {
    basePost(Urls.admin.domainBlocks.post, data, resolve, reject);
  });
}

export function destroyDomainBlock(data) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.admin.domainBlocks.destroy(data.id), data, resolve, reject);
  });
}
