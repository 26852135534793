import InviteesActionTypes from '../libs/inviteesActionTypes';

export const withdrawInviteLoading = (state = false, { type, loading }) => {
  switch (type) {
    case InviteesActionTypes.setWithdrawInviteLoading:
      return loading;
    default:
      return state;
  }
};

export const withdrawInvitesLoading = (state = false, { type, loading }) => {
  switch (type) {
    case InviteesActionTypes.setWithdrawInvitesLoading:
      return loading;
    default:
      return state;
  }
};

export const resendInviteLoading = (state = false, { type, loading }) => {
  switch (type) {
    case InviteesActionTypes.setResendInviteLoading:
      return loading;
    default:
      return state;
  }
};

export const resendInvitesLoading = (state = false, { type, loading }) => {
  switch (type) {
    case InviteesActionTypes.setResendInvitesLoading:
      return loading;
    default:
      return state;
  }
};

export const sendInvitesLoading = (state = false, { type, loading }) => {
  switch (type) {
    case InviteesActionTypes.setInviteUsersLoading:
      return loading;
    default:
      return state;
  }
};

export const marketoWorkspacesLoading = (state = false, { type, loading }) => {
  switch (type) {
    case InviteesActionTypes.setMarketoWorkspacesLoading:
      return loading;
    default:
      return state;
  }
};
