import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openEmailComposePopup } from 'web/emailComposeBody/actionCreators/popupActionCreators';
import { GlobalPopupIds } from 'web/emailComposeBody/libs/emailComposeBodyConstants';
import CampaignEditEmailTemplate from 'web/campaigns/campaignsTab/components/campaignEditEmailTemplate/campaignEditEmailTemplate';
import { getDynamicFieldsAsArray } from 'web/shared/helpers/dynamicFieldsHelper';

const CampaignEditEmailTemplateContainer = (props) => (
  <CampaignEditEmailTemplate {...props} />
);

CampaignEditEmailTemplateContainer.propTypes = {
  dynamicFields: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  dynamicFields: getDynamicFieldsAsArray(state.dynamicFields),
});

const mapDispatchToProps = (dispatch) => ({
  openIncorrectDynamicFieldsPopup: (incorrectDynamicFields) => {
    dispatch(
      openEmailComposePopup(GlobalPopupIds.dynamicFieldsIncorrect, {
        incorrectDynamicFields,
      })
    );
  },
  openUnfilledPromptsInAutomaticEmailPopup: (notFilledPrompts) => {
    dispatch(
      openEmailComposePopup(GlobalPopupIds.unfilledPromptInAutomaticEmail, {
        notFilledPrompts,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignEditEmailTemplateContainer);
