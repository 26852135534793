/* global Promise */
import { basePost } from 'web/services/api';
import { Urls } from 'web/libs/constants';
import {
  ElasticSearchMode,
  DEFAULT_PAGE_SIZE,
} from 'web/elasticSearch/libs/elasticSearchConstants';

export function buildEsFetchRequestParams({
  type = null,
  mode = ElasticSearchMode.all,
  page = 1,
  perPage = DEFAULT_PAGE_SIZE,
  column = '',
  direction = '',
  filters = {},
  blank = '',
  textSearch = {},
  notFilters = {},
  dateRange = {},
  tags = [],
  viewer = {},
  includeFields = '',
}) {
  return {
    blank,
    date_range: dateRange,
    filters,
    include_fields: includeFields,
    mode,
    not_filters: notFilters,
    page,
    per_page: perPage,
    sort_filters: { column, direction },
    tags,
    text_search: textSearch,
    type,
    viewer,
  };
}

export function esFetch({
  type,
  mode,
  page,
  perPage,
  column,
  direction,
  filters,
  blank,
  textSearch,
  notFilters,
  dateRange,
  tags,
  viewer,
  includeFields,
}) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.esFetch,
      buildEsFetchRequestParams({
        blank,
        column,
        dateRange,
        direction,
        filters,
        includeFields,
        mode,
        notFilters,
        page,
        perPage,
        tags,
        textSearch,
        type,
        viewer,
      }),
      resolve,
      reject
    );
  });
}
