import PropTypes from 'prop-types';

export const timezoneSettingsPropTypes = {
  i18nFeatureFlagEnadled: PropTypes.bool.isRequired,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  languagePlaceholder: PropTypes.string,
  localeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  localePlaceholder: PropTypes.string,
  onFormSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  yourTimezoneOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  yourTimezonePlaceholder: PropTypes.string,
};

export const timezoneSettingsDefaultProps = {
  languageOptions: [],
  languagePlaceholder: '',
  localeOptions: [],
  localePlaceholder: '',
  yourTimezoneOptions: [],
  yourTimezonePlaceholder: '',
};
