import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ToutTable from 'table/modules/toutTable';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import ActionsCell from 'table/modules/cells/actionsCell';
import SharingSettingsAccessCell from '../sharingSettingsAccessCell';
import { alphabetical } from 'table/modules/sorting';
import { Align } from 'table/modules/constants';
import { getImageUrl } from 'web/libs/constants';
import './sharingSettingsTable.scss';
import I18N from 'languages';
import { TEAM_NAMES } from 'web/user/libs/userConstants';

const CURRENT_PAGE = 1;
const PER_PAGE = 10;
const ROW_HEIGHT = 70;
const IMAGE_WIDTH = 210;
const TEAM = 'team';

class SharingSettingsTable extends Component {
  constructor() {
    super();

    this.id = 'sharing-settings-table';
  }

  componentWillMount() {
    this.bulkActions = this._getBulkActions();
    this.columns = this._getColumns();
    this.pagination = { currentPage: CURRENT_PAGE, perPage: PER_PAGE };
    this.row = { height: ROW_HEIGHT };
    this.search = this._getSearch();
    this.zeroState = this._getZeroState();
  }

  render() {
    const { fetching, loading, teams } = this.props;

    return (
      <div className="sharing-settings-table">
        <ToutTable
          allItems
          bulkActions={this.bulkActions}
          columns={this.columns}
          items={teams}
          defaultSortingColumn={TEAM}
          fetching={fetching}
          loading={loading}
          loadingGetUrl={getImageUrl}
          pagination={this.pagination}
          row={this.row}
          search={this.search}
          selectable
          tableId={this.id}
          zeroState={this.zeroState}
        />
      </div>
    );
  }

  _getBulkActions = () => {
    const {
      actionCreators,
      intl: { formatMessage },
    } = this.props;
    return {
      actions: [
        {
          label: formatMessage({ id: 'common.remove' }),
          onClick: actionCreators.removeTeams,
        },
      ],
      itemsLabel: formatMessage({ id: 'common.teams' }),
    };
  };

  emailTeam = (team) => this.props.actionCreators.emailTeam(team.id);

  removeTeam = (team) => this.props.actionCreators.removeTeams([team.id]);

  _getColumns = () => {
    const {
      actionCreators: { setSharing },
      intl: { formatMessage },
    } = this.props;
    return [
      {
        id: TEAM,
        name: formatMessage({ id: 'common.team' }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) =>
              TEAM_NAMES.everyone === data.name
                ? I18N.getStr('common.everyone')
                : data.name,
          },
        },
        sorting: alphabetical('name'),
        width: 150,
      },
      {
        align: Align.CENTER,
        id: 'action',
        name: formatMessage({ id: 'common.action' }),
        rowCell: {
          component: ActionsCell,
          metadata: {
            actions: [
              {
                label: formatMessage({ id: 'common.remove' }),
                onClick: this.removeTeam,
              },
            ],
          },
        },
        width: 200,
      },
      {
        id: 'sharing-access',
        name: formatMessage({ id: 'web.settings.sharing.table.sharingAccess' }),
        rowCell: {
          component: SharingSettingsAccessCell,
          metadata: {
            onChange: setSharing,
          },
        },
        width: 200,
      },
    ];
  };

  _getSearch = () => {
    const { formatMessage } = this.props.intl;
    return {
      placeholderText: formatMessage({
        id: 'web.settings.sharing.table.searchPlaceholder',
      }),
      searching: (item) => item.name,
      showClear: false,
    };
  };

  _getZeroState = () => {
    const {
      intl: { formatMessage },
      isTemplates,
    } = this.props;
    return {
      bodyText: formatMessage({
        id: 'web.settings.sharing.table.zeroStateBody',
      }),
      imageUrl: getImageUrl('lonely-planet', 'svg'),
      imageWidth: IMAGE_WIDTH,
      titleText: formatMessage(
        { id: 'web.settings.sharing.table.zeroStateTitle' },
        { isTemplates }
      ),
    };
  };
}

SharingSettingsTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  fetching: PropTypes.bool.isRequired,
  isTemplates: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  teams: PropTypes.array.isRequired,
};

export default injectIntl(SharingSettingsTable);
