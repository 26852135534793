import moment from 'moment-timezone';
import find from 'lodash/find';
import {
  DefaultTimezone,
  TimezonesCategories,
  TimezonesCategoriesOrderedEnum,
} from 'web/timezones/libs/timezonesConstants';

function getFirstStepDateTime(time, timezone) {
  const now = moment.tz(timezone);
  return moment.tz(
    `${now.format('MM-DD-YYYY')} ${time}`,
    'MM-DD-YYYY h:m a',
    timezone
  );
}

export function getScheduledDateTime(
  firstStep = { action_params: {} },
  timezones = [[], []],
  accountTimezone = ''
) {
  const timezone = find(
    timezones[
      TimezonesCategoriesOrderedEnum[TimezonesCategories.international]
    ],
    { displayName: firstStep.action_params.timezone || accountTimezone }
  ) || { value: DefaultTimezone };

  let datetime = null;
  if (firstStep.action_params.time) {
    datetime = getFirstStepDateTime(
      firstStep.action_params.time,
      timezone.value
    );
  }

  return { datetime, timezone: timezone.value };
}

export function getFirstStep(campaign = {}) {
  return (
    (campaign.steps &&
      campaign.steps.find((step) => step.day === 1 && step.order === 0)) ||
    {}
  );
}
