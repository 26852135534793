import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import sortBy from 'lodash/sortBy';

export const editTemplateSlideOutPopupMove = (
  state = {},
  { type = '', category = {} }
) => {
  switch (type) {
    case EditTemplateActionTypes.updateMoveCategory:
      return category;
    case PopupActionTypes.close:
      return {};
    default:
      return state;
  }
};

export const initShareState = {
  initCategoryId: 0,
  selectedCategory: {},
  teamIds: [],
  sharableCategories: [],
};
export const editTemplateSlideOutPopupShare = (
  state = initShareState,
  {
    type = '',
    initCategoryId = 0,
    selectedCategory = {},
    sharableCategories = [],
    teamIds = [],
  }
) => {
  switch (type) {
    case EditTemplateActionTypes.setSharingState:
      return {
        ...state,
        initCategoryId,
        selectedCategory,
        sharableCategories,
        teamIds,
      };
    case EditTemplateActionTypes.setSharableCategories:
      return {
        ...state,
        sharableCategories: sortBy(
          sharableCategories,
          (category) => category.name && category.name.toLowerCase()
        ),
      };
    case EditTemplateActionTypes.updateSharedTeamIds:
      return { ...state, teamIds };
    case EditTemplateActionTypes.updateSharedCategory:
      return { ...state, selectedCategory };
    case PopupActionTypes.close:
      return initShareState;
    default:
      return state;
  }
};
