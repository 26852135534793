import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import EditableHeader from 'components/editableHeader';
import Icon from 'components/buttons/icon';
import classNames from 'classnames';
import './savedAdvancedSearchHeader.scss';

const SavedAdvancedSearchHeader = ({
  searchName,
  onClone,
  onSave,
  intl: { formatMessage },
}) => {
  return (
    <div className="advanced-search-header-container flex--center-vert">
      <div className="advanced-search-header-label">
        {searchName ? (
          <FormattedMessage id="web.commandCenter.advancedSearchSaved.title" />
        ) : (
          <FormattedMessage id="web.commandCenter.advancedSearch.title" />
        )}
      </div>
      {searchName && (
        <Fragment>
          <EditableHeader
            onSave={onSave}
            placeHolderTextId="web.commandCenter.advancedSearch.enterSearchName"
            value={searchName}
            isEditable
          />
          <Icon
            classes={classNames('tout-icon-menu', 'tout-icon-clone')}
            color="blue"
            onClick={onClone}
            title={formatMessage({ id: 'common.clone' })}
          />
        </Fragment>
      )}
    </div>
  );
};

SavedAdvancedSearchHeader.propTypes = {
  intl: intlShape.isRequired,
  onClone: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  searchName: PropTypes.string,
};

SavedAdvancedSearchHeader.defaultProps = {
  searchName: '',
};

export default injectIntl(SavedAdvancedSearchHeader);
