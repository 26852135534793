export default {
  addPeopleToGroupError: 'PEOPLE_ADD_PEOPLE_TO_GROUP_ERROR',
  addPeopleToGroupSuccess: 'PEOPLE_ADD_PEOPLE_TO_GROUP',
  addPersonSuccess: 'PERSON_ADD_SUCCESS',
  campaignRemovedNotification: 'PEOPLE_CAMPAIGN_REMOVED_NOTIFICATION',
  campaignUpdatedNotification: 'PEOPLE_CAMPAIGN_UPDATED_NOTIFICATION',
  createGroupError: 'PEOPLE_CREATE_GROUP_ERROR',
  deleteGroupError: 'PEOPLE_DELETE_GROUP_ERROR',
  editGroupError: 'PEOPLE_EDIT_GROUP_ERROR',
  emailFieldMissingError: 'PEOPLE_EMAIL_FIELD_MISSING_ERROR',
  exportPeopleError: 'EXPORT_PEOPLE_ERROR',
  exportPeopleNothingToExport: 'EXPORT_PEOPLE_NOTHING_TO_EXPORT',
  exportPeopleSuccess: 'EXPORT_PEOPLE_SUCCESS',
  fetchError: 'PEOPLE_FETCH_ERROR',
  fileFormatError: 'PEOPLE_FILE_FORMAT_ERROR',
  fileTypeError: 'PEOPLE_FILE_TYPE_ERROR',
  fileUploadError: 'PEOPLE_FILE_UPLOAD_ERROR',
  genericApiError: 'DELETE_PEOPLE_MODAL_GENERIC_API_ISSUE',
  groupDuplicateNameError: 'PEOPLE_GROUP_DUPLICATE_GROUP_ERROR',
  groupMinLengthNameError: 'PEOPLE_GROUP_MIN_LENGTH_NAME_ERROR',
  importPeopleSuccess: 'IMPORT_PEOPLE_SUCCESS',
  invalidCustomFieldNameError: 'PEOPLE_INVALID_CUSTOM_FIELD_NAME_ERROR',
  loadContactsError: 'PEOPLE_LOAD_CONTACTS_ERROR',
  noAccessToCampaign: 'PEOPLE_USER_HAS_NO_ACCESS_TO_CAMPAIGN',
  peopleAuthorization: { saveFail: 'PEOPLE_PEOPLE_AUTHORIZATION_SAVE_FAIL' },
  peopleCompliance: { saveFail: 'PEOPLE_PEOPLE_COMPLIANCE_SAVE_FAIL' },
  peopleSearchAdd: {
    addNewPeopleDisabled: 'PEOPLE_PEOPLE_SEARCH_ADD_ADD_NEW_PEOPLE_DISABLED',
    getPeopleInGroupFail: 'PEOPLE_PEOPLE_SEARCH_ADD_GET_PEOPLE_IN_GROUP_FAIL',
    groupAlreadyIncluded: 'PEOPLE_PEOPLE_SEARCH_ADD_GROUP_ALREADY_INCLUDED',
    miscAlreadyIncluded: 'PEOPLE_PEOPLE_SEARCH_ADD_MISC_ALREADY_INCLUDED',
    needsToBeEmail: 'PEOPLE_PEOPLE_SEARCH_ADD_NEEDS_TO_BE_EMAIL',
    personAlreadyIncluded: 'PEOPLE_PEOPLE_SEARCH_ADD_PERSON_ALREADY_INCLUDED',
  },
  peopleUnsubscribe: {
    peopleRemoveUnsubscribeWarning: 'PEOPLE_REMOVE_UNSUBSCRIBE_WARNING',
  },
  removePeopleFromGroupError: 'PEOPLE_REMOVE_PEOPLE_FROM_GROUP_ERROR',
  removePeopleFromGroupSuccess: 'PEOPLE_REMOVE_PEOPLE_FROM_GROUP',
  shareGroupError: 'PEOPLE_SHARE_GROUP_ERROR',
  userInviteNeedMoreSeats: 'INVITE_MORE_SEATS_NEEDED',
};
