import { createReducer } from '@reduxjs/toolkit';
import { changePeopleGroupSmartFilterInputValue } from 'web/commandCenter/modules/whoSmartFilter/actionCreators/peopleGroupFilterActionCreators';

export const peopleGroupSmartFilterInputValue = createReducer(
  { inputMode: false, inputValue: '' },
  {
    [changePeopleGroupSmartFilterInputValue.type]: (state, action) => {
      const { inputMode = true, inputValue } = action.payload.filterInputValue;

      return {
        ...state,
        inputMode,
        inputValue,
      };
    },
  }
);
