import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openGongPopup } from 'web/modals/gong/actionCreators/popupActionCreators';
import { isGongPopup } from '../../selectors/gongSelectors';

import Item from 'components/navBar/item';
import { GongModal } from './gongModal';

const GongIcon = (props) => (
  <React.Fragment>
    <Item
      titleId="common.gong"
      titleClassName={props.titleClassName}
      icon="icon-cup"
      onClick={props.onOpenGongPopup}
    />
    {props.isOpenGongPopup ? <GongModal /> : null}
  </React.Fragment>
);

GongIcon.propTypes = {
  onOpenGongPopup: PropTypes.func.isRequired,
  isOpenGongPopup: PropTypes.bool.isRequired,
  titleClassName: PropTypes.string,
};

GongIcon.defaultProps = {
  titleClassName: null,
};

const mapDispatchToProps = {
  onOpenGongPopup: openGongPopup,
};

const mapStateToProps = (state) => ({
  isOpenGongPopup: isGongPopup(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GongIcon);
