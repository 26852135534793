import { createReducer } from '@reduxjs/toolkit';
import {
  CommandCenterEmailsTabsEnum,
  initialEmailSortParams,
} from 'web/commandCenter/libs/commandCenterConstants';
import CommandCenterEmailsActionTypes from 'web/commandCenter/libs/commandCenterEmailsActionTypes';
import EmailGroupsActionType from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGroupsActionType';
import { DEFAULT_PAGE_SIZE } from 'web/elasticSearch/libs/elasticSearchConstants';
import {
  lockResetEmailSelectedSavedAdvancedSearchId,
  unlockResetEmailSelectedSavedAdvancedSearchId,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/openAdvancedSearchActionCreators';
import { setUnreadEmailsCount } from 'web/commandCenter/actionCreators/commandCenterEmailsActionCreators';

const initTab = CommandCenterEmailsTabsEnum.sent.value;
export const commandCenterEmailsTab = (
  state = initTab,
  { type = '', tab = initTab }
) => {
  switch (type) {
    case CommandCenterEmailsActionTypes.setTab:
      return tab;
    default:
      return state;
  }
};

const initSubTab = CommandCenterEmailsTabsEnum.sent.subTabs.delivered.value;
export const commandCenterEmailsSubTab = (
  state = initSubTab,
  { type = '', subTab = initSubTab }
) => {
  switch (type) {
    case CommandCenterEmailsActionTypes.setSubTab:
      return subTab;
    default:
      return state;
  }
};

export const emailLoading = (state = false, { type, isLoading = false }) => {
  switch (type) {
    case CommandCenterEmailsActionTypes.emails.loading:
      return isLoading;
    default:
      return state;
  }
};

export const emailTotal = (state = 0, { type, data }) => {
  switch (type) {
    case CommandCenterEmailsActionTypes.emails.set:
      return (data && data.total) || 0;
    default:
      return state;
  }
};

export const emailPage = (state = 1, { type, page = 1 }) => {
  switch (type) {
    case CommandCenterEmailsActionTypes.table.page:
      return page;
    default:
      return state;
  }
};

export const emailsPerPage = (
  state = DEFAULT_PAGE_SIZE,
  { type, perPage = DEFAULT_PAGE_SIZE }
) => {
  switch (type) {
    case CommandCenterEmailsActionTypes.table.perPage:
      return perPage;
    default:
      return state;
  }
};

const defaultData = [];
export const emailGridData = (
  state = [],
  { type, data = defaultData, email = {}, emailIds = [] }
) => {
  switch (type) {
    case CommandCenterEmailsActionTypes.emails.set:
      return data && data.results ? data.results : defaultData;
    case CommandCenterEmailsActionTypes.emails.setEmail: {
      const index = state.findIndex(({ id }) => id === email.id);
      if (index > -1) {
        return [
          ...state.slice(0, index),
          { ...state[index], ...email },
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    }
    case CommandCenterEmailsActionTypes.emails.deleteEmail: {
      const index = state.findIndex(({ id }) => id === email.id);
      if (index > -1) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      } else {
        return state;
      }
    }
    case CommandCenterEmailsActionTypes.emails.deleteEmails:
      return state.filter(({ id }) => !emailIds.includes(id.toString()));
    default:
      return state;
  }
};

export const initEmailSort = initialEmailSortParams;
export const emailsSort = (
  state = initEmailSort,
  { type, column = '', direction = '' }
) => {
  switch (type) {
    case CommandCenterEmailsActionTypes.table.sortInfo:
      return { column, direction };
    case CommandCenterEmailsActionTypes.table.reset:
      return initEmailSort;
    default:
      return state;
  }
};

export const emailsSortByGroups = (
  state = { checked: false, disable: false },
  action
) => {
  const { type, payload } = action;

  return type === EmailGroupsActionType.singleRowEmails.singleRowGroups
    ? {
        checked: payload.checked,
        disable: !!payload.disable,
      }
    : state;
};

export const canResetEmailSelectedSavedAdvancedSearchId = createReducer(true, {
  [lockResetEmailSelectedSavedAdvancedSearchId.type]: () => false,
  [unlockResetEmailSelectedSavedAdvancedSearchId.type]: () => true,
});

export const unreadEmailsCounters = createReducer(
  {},
  {
    [setUnreadEmailsCount.type]: (state, action) => {
      const { payload: unreadEmailsCounters } = action;
      return {
        ...unreadEmailsCounters,
      };
    },
  }
);
