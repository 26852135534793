import { setSmartFilter } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import {
  REFERENCE_DATE_FILTER_ID,
  REFERENCE_DATES,
  DEFAULT_REFERENCE_DATE,
} from 'web/commandCenter/modules/whenSmartFilter/libs/referenceDateSmartFilterConstants';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';

export const setReferenceDate = (referenceDate) => (dispatch) => {
  dispatch(
    setSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: REFERENCE_DATE_FILTER_ID,
      payload: { referenceDate },
    })
  );
};

export const setReferenceDateBasedOnStatus = (subTabIds, tabId) => (
  dispatch
) => {
  switch (tabId) {
    case CommandCenterEmailsTabsEnum.undelivered.value:
      dispatch(setReferenceDate(REFERENCE_DATES.failed_at.id));
      break;
    case CommandCenterEmailsTabsEnum.sent.value:
      dispatch(setReferenceDate(REFERENCE_DATES.delivered_at.id));
      break;
    case CommandCenterEmailsTabsEnum.pending.value: {
      const isOnlyScheduledSelected =
        subTabIds.length === 1 &&
        subTabIds.includes(
          CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value
        );
      isOnlyScheduledSelected
        ? dispatch(setReferenceDate(REFERENCE_DATES.send_at.id))
        : dispatch(setReferenceDate(REFERENCE_DATES.created_at.id));
      break;
    }
    default:
      dispatch(setReferenceDate(DEFAULT_REFERENCE_DATE));
  }
};
