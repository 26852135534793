import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import GongBanner from 'web/modals/gong/components/gongBanner';
import DealSize from 'web/modals/gong/components/dealSize';
import gongActionCreators from 'web/modals/gong/actionCreators/gongActionCreators';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { ModalPopupIds, Currency, getImageUrl } from 'web/libs/constants';
import { buildSelectOptionsForOpportunities } from 'web/modals/gong/lib/gongOpportunities';
import Popup, { PopupBackgroundColors } from 'components/popups';
import Select2 from 'components/selects/select2';
import LabeledInput from 'components/labeledInput';
import Checkbox from 'components/buttons/checkbox';
import GongCelebration from 'web/modals/gong/components/gongCelebration';
import { format as currencyFormat } from 'web/shared/helpers/currency';
import './gongContainer.scss';
import I18N from 'languages';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';

const POPUP_SIZE = 545;

class GongContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;
    this.boundActionCreators = bindActionCreators(gongActionCreators, dispatch);
    this.actionCreators = {
      closePopup: () => dispatch(closePopup()),
    };
    this.celebrationTimeout = null;
  }

  close = () => {
    const { windowMode } = this.props;

    this.boundActionCreators.clearError();

    if (windowMode) {
      window.close();
    } else {
      this.actionCreators.closePopup();
    }
  };

  handleCancelConfirmation = () => {
    this.boundActionCreators.hideConfirmation();
  };

  handleAffirmConfirmation = () => {
    // Hide confirmation
    this.boundActionCreators.hideConfirmation();

    // Uncheck the checkbox
    this.boundActionCreators.closeOpportunityInSalesforce(false);

    // Submit the form
    const { selectedOpportunity, accountName, dealSize } = this.props;

    const params = {
      deal: {
        customer_name: accountName,
        deal_size: dealSize,
        name: selectedOpportunity.label,
        additional_data: {},
      },
      close_opportunity_in_salesforce: false,
    };

    this.boundActionCreators.postOpportunity(params);
  };

  buildGlobalPopupProps() {
    const { celebrate, windowMode, confirmation } = this.props;

    if (celebrate) {
      return {
        className: 'custom-gong-modal',
        full: true,
        onOutsideClick: this.close,
        size: POPUP_SIZE,
      };
    } else if (confirmation) {
      return {
        children: (
          <div>
            <FormattedHTMLMessage id="gong.warnPushingNewDealsToSalesforce" />
          </div>
        ),
        backgroundColor: PopupBackgroundColors.yellow,
        footer: {
          onPrimary: this.handleAffirmConfirmation,
          onSecondary: this.handleCancelConfirmation,
          primaryTextId: 'common.ok',
          secondaryTextId: 'common.cancel',
        },
        header: {
          onClose: !windowMode && this.handleCancelConfirmation,
          textId: !windowMode && 'gong.gongTitle',
        },
        onOutsideClick: this.close,
        overlay: {},
        size: POPUP_SIZE,
      };
    } else {
      return {
        footer: {
          onPrimary: this.handleSubmit,
          onSecondary: this.close,
          primaryIcon: 'trophy',
          primaryTextId: 'gong.ringTheGong',
          secondaryTextId: 'common.cancel',
        },
        header: {
          onClose: windowMode ? null : this.close,
          textId: !windowMode && 'gong.gongTitle',
        },
        onOutsideClick: this.close,
        size: POPUP_SIZE,
      };
    }
  }

  handleSubmit = () => {
    this.boundActionCreators.clearError();

    const {
      closeOpportunityInSalesforce,
      selectedOpportunity,
      accountName,
      dealSize,
      opportunities,
    } = this.props;
    const opportunityId = selectedOpportunity.value;

    const params = {
      deal: {
        customer_name: accountName,
        deal_size: String(I18N.getWholeNumber(dealSize)),
        name: selectedOpportunity.label,
        additional_data: {},
      },
      close_opportunity_in_salesforce: closeOpportunityInSalesforce,
    };

    if (opportunityId) {
      const opportunity = find(opportunities, { id: opportunityId });
      params.deal.id = opportunityId;
      params.deal.additional_data.salesforce_created_at =
        opportunity.salesforce_created_at;

      this.boundActionCreators.putOpportunity(params);
    } else if (closeOpportunityInSalesforce) {
      // Attempt to create a new Opportunity in Salesforce
      this.boundActionCreators.showConfirmation();
    } else {
      // Creating a new Opportunity locally (in ToutApp)
      this.boundActionCreators.postOpportunity(params);
    }
  };

  handleBlurOpportunityDeal = (e) => {
    this.boundActionCreators.clearError();

    const { value: opportunityId } = this.refs.opportunityDeal.props;
    const { value: opportunityName } = e.target;
    const isSalesforceEntry = opportunityId && !opportunityName;

    if (!isSalesforceEntry) {
      this.boundActionCreators.changeSelectedOpportunity({
        value: null,
        label: opportunityName,
      });
    }
  };

  handleChangeOpportunityDeal = (changedOpportunity) => {
    this.boundActionCreators.clearError();

    // MT: This is to prevent an array being passed as changedOpportunity,
    // since, `react-select` emits a changed event when the delete key is
    // hit when the select menu is open. Probably a `react-select` bug.
    if (Array.isArray(changedOpportunity)) {
      changedOpportunity = { label: '', value: null };
    }

    const { opportunities } = this.props;
    const opportunity = find(opportunities, { id: changedOpportunity.value });
    this.boundActionCreators.changeSelectedOpportunity(changedOpportunity);

    if (opportunity) {
      const dealSize = currencyFormat(String(opportunity.deal_size));
      this.boundActionCreators.changeAccountName(opportunity.customer_name);
      this.boundActionCreators.changeDealSize(dealSize);
    } else {
      this.boundActionCreators.changeAccountName('');
      this.boundActionCreators.changeDealSize(Currency.gong.defaultDealSize);
    }
  };

  handleChangeAccountName = (e) => {
    this.boundActionCreators.changeAccountName(e.currentTarget.value);
  };

  renderGongBanner() {
    const {
      intl: { formatMessage },
    } = this.props;

    const gongBannerProps = {
      title: formatMessage({ id: 'gong.congratulations' }),
      subTitle: formatMessage({ id: 'gong.shareWithTeam' }),
    };

    return <GongBanner {...gongBannerProps} />;
  }

  renderHelpText() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <span className="gong-top-help-text">
        {formatMessage({ id: 'gong.gongInfo' })}
      </span>
    );
  }

  renderOpportunityDeal() {
    const {
      intl: { formatMessage },
      opportunities,
      selectedOpportunity,
      error,
      loading,
    } = this.props;
    const selectOptionsForOpportunities = buildSelectOptionsForOpportunities(
      opportunities
    );
    let errorClass = '';
    let errorMessage = '';

    if (!isEmpty(error) && error.name) {
      errorClass = ' error';
      errorMessage = error.name
        ? I18N.getConstStr('web.gong', error.name)
        : error.name;
    }

    const className = `oppportunity-deal-input-group${errorClass}`;

    return (
      <div>
        {loading ? <LoadingSpinner imageUrl={getImageUrl} /> : null}
        <div className={className}>
          <div className="opportunity-deal-label text-medium">
            {formatMessage({ id: 'gong.opportunityDeal' })}
          </div>
          <Select2
            ref="opportunityDeal"
            noResultsText={formatMessage({ id: 'gong.noOpportunitiesFound' })}
            onBlurResetsInput={false}
            options={selectOptionsForOpportunities}
            placeholder={formatMessage({
              id: 'gong.enterNameOfSearchOpportunities',
            })}
            value={selectedOpportunity.value}
            clearable={false}
            searchable
            name="selected-gong-opportunity"
            id="selected-gong-opportunity"
            onChange={this.handleChangeOpportunityDeal}
            onBlur={this.handleBlurOpportunityDeal}
          />
        </div>
        {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
      </div>
    );
  }

  renderAccountName() {
    const {
      intl: { formatMessage },
      accountName,
    } = this.props;

    return (
      <div className="account-name-input-group">
        <LabeledInput
          classesInput="account-name-input"
          classesLabel="account-name-label"
          autoFocus={false}
          id="gong-account-name"
          onChange={this.handleChangeAccountName}
          messageKey="gong.accountName"
          placeholder={formatMessage({ id: 'gong.accountName' })}
          ref="accountName"
          sideLabel
          toutUiStyle
          type="text"
          value={accountName}
        />
      </div>
    );
  }

  renderDealSize() {
    const {
      intl: { formatMessage },
      dealSize,
      error,
    } = this.props;
    const dealSizeProps = {
      placeholder: formatMessage({ id: 'gong.dealSize' }),
      changeDealSize: this.boundActionCreators.changeDealSize,
      clearError: this.boundActionCreators.clearError,
      dealSize,
      error,
    };

    return <DealSize {...dealSizeProps} />;
  }

  renderOptionToCloseDealInSalesforce() {
    const {
      intl: { formatMessage },
      closeOpportunityInSalesforce,
      usesSalesforce,
    } = this.props;

    if (usesSalesforce) {
      return (
        <div className="close-deal-in-sf-input-group">
          <Checkbox
            checked={closeOpportunityInSalesforce}
            classesLabel="close-deal-in-sf-label"
            label={formatMessage({ id: 'gong.closeDealInSalesforce' })}
            onChange={this.boundActionCreators.closeOpportunityInSalesforce}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderGongCelebration() {
    const { dealSize } = this.props;

    return <GongCelebration dealSize={I18N.getWholeNumber(dealSize)} />;
  }

  componentWillMount() {
    this.boundActionCreators.getOpportunities();
  }

  componentDidUpdate(prevProps) {
    const { opportunities } = this.props;
    if (!prevProps.popup && opportunities.length === 0) {
      this.boundActionCreators.getOpportunities();
    }
  }

  unmount() {
    this.close();
    ReactDOM.unmountComponentAtNode(document.querySelector('#root-modal'));
  }

  componentWillUnmount() {
    this.boundActionCreators.stopCelebration();
    this.boundActionCreators.changeSelectedOpportunity({
      label: '',
      value: null,
    });
    this.boundActionCreators.changeAccountName('');
    this.boundActionCreators.changeDealSize(Currency.gong.defaultDealSize);

    if (this.celebrationTimout) {
      clearTimeout(this.celebrationTimeout);
    }
  }

  render() {
    const { popup, celebrate } = this.props;

    if (celebrate) {
      const globalPopupProps = this.buildGlobalPopupProps();

      this.celebrationTimeout = window.setTimeout(() => this.unmount(), 15e3);

      return (
        <div className="gong-container">
          <Popup {...globalPopupProps}>{this.renderGongCelebration()}</Popup>
        </div>
      );
    } else if (popup === ModalPopupIds.gong) {
      const globalPopupProps = this.buildGlobalPopupProps();

      return (
        <div className="gong-container">
          <Popup {...globalPopupProps}>
            {this.renderGongBanner()}
            {this.renderHelpText()}
            {this.renderOpportunityDeal()}
            {this.renderAccountName()}
            {this.renderDealSize()}
            {this.renderOptionToCloseDealInSalesforce()}
          </Popup>
        </div>
      );
    } else {
      return <div className="gong-container" />;
    }
  }
}

GongContainer.propTypes = {
  accountName: PropTypes.string.isRequired,
  celebrate: PropTypes.bool.isRequired,
  closeOpportunityInSalesforce: PropTypes.bool,
  confirmation: PropTypes.bool,
  dealSize: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.object,
  intl: intlShape.isRequired,
  loading: PropTypes.bool,
  opportunities: PropTypes.array.isRequired,
  popup: PropTypes.string,
  selectedOpportunity: PropTypes.object,
  usesSalesforce: PropTypes.bool.isRequired,
  windowMode: PropTypes.bool.isRequired,
};

GongContainer.defaultProps = {
  selectedOpportunity: {
    value: null,
    label: '',
  },
  error: {},
  closeOpportunityInSalesforce: false,
};

function mapStateToProps(state) {
  return {
    accountName: state.gongAccountName,
    celebrate: state.gongCelebrate,
    closeOpportunityInSalesforce: state.gongCloseOpportunityInSalesforce,
    confirmation: state.gongConfirmation,
    dealSize: state.gongDealSize,
    error: state.gongError,
    loading: state.gongLoading,
    opportunities: state.gongOpportunities,
    popup: state.popup,
    selectedOpportunity: state.gongSelectedOpportunity,
    usesSalesforce: state.userMetric.uses_salesforce_api,
    windowMode: state.gongWindowMode,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(GongContainer);
