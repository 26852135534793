import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import I18N, { moment } from 'languages';
import { getTeamsByOnlyIds } from 'web/user/services/teamsService';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import {
  isSharedTemplate,
  isUnsharedTemplateForAdmin,
} from 'web/templates/helpers/templatesHelper';
import './editTemplateHeaderInfo.scss';
import { TEAM_NAMES } from 'web/user/libs/userConstants';

class EditTemplateHeaderInfo extends Component {
  render() {
    const { template, user } = this.props;

    return (
      <div className="edit-template-header-info">
        <div className="edit-template-header-info-row">
          <FormattedHTMLMessage
            id="web.editTemplateSlideOut.editTemplateHeaderInfo.createdBy"
            values={{
              name: this._getAuthor(),
              fromNow: moment(template.created_at).fromNow(),
            }}
          />
        </div>
        {isSharedTemplate(template) && (
          <div className="edit-template-header-info-row">
            <HoverTooltip
              getContent={this.getTip}
              place={HoverTooltipPlace.left}
              tooltipId="edit-template-header-info-shared-tooltip"
            >
              <div className="tout-icon-shared text-blue">
                <FormattedMessage
                  id="web.editTemplateSlideOut.editTemplateHeaderInfo.shared"
                  values={{ teams: template.teamIds.length }}
                />
              </div>
            </HoverTooltip>
          </div>
        )}
        {isUnsharedTemplateForAdmin(template, user) && (
          <div className="edit-template-header-info-row">
            <div className="tout-icon-unshared text-blue" />
          </div>
        )}
      </div>
    );
  }

  getTip = () => {
    const {
      template: { teamIds },
      user,
    } = this.props;
    const teams = getTeamsByOnlyIds(user, teamIds).map(
      (team) =>
        TEAM_NAMES.everyone === team.name
          ? I18N.getStr('common.everyone')
          : team.name
    );
    return (
      <div className="edit-template-header-info-toolip">
        {teams.map((team) => (
          <div key={`team-${team}`}>{team}</div>
        ))}
      </div>
    );
  };

  _getAuthor = () => {
    const {
      user: { id: userId, subscription },
      template: { user_id: templateUserId },
      viewer: { id: viewerId } = {},
    } = this.props;
    if (userId === viewerId && userId === templateUserId) {
      return this.props.intl.formatMessage({ id: 'common.youU' });
    }

    const teamMember = subscription.users.find(
      (user) => user.id === templateUserId
    );
    return teamMember && teamMember.name
      ? teamMember.name
      : this.props.intl.formatMessage({
          id: 'web.editTemplateSlideOut.editTemplateHeaderInfo.aTeamMember',
        });
  };
}

EditTemplateHeaderInfo.propTypes = {
  intl: intlShape.isRequired,
  template: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  viewer: PropTypes.object,
};

EditTemplateHeaderInfo.defaultProps = {};

export default injectIntl(EditTemplateHeaderInfo);
