import uniq from 'lodash/uniq';
import moment from 'moment';
import { baseDelete, baseGet, basePost, basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';
import { BASE_START, BASE_END } from './tasksHelpers';

// Single tasks
export function createTask(reminder) {
  return new Promise((resolve, reject) => {
    if (reminder) {
      basePost(Urls.tasks, { reminder }, resolve, reject);
    } else {
      reject();
    }
  });
}

export function updateTask(reminder = {}) {
  return new Promise((resolve, reject) => {
    if (reminder.id) {
      basePut(
        Urls.tasksUpdate.replace(':#id', reminder.id || ''),
        { reminder },
        resolve,
        reject
      );
    } else {
      reject();
    }
  });
}

export function deleteTask(taskId) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.tasksUpdate.replace(':#id', taskId), {}, resolve, reject);
  });
}

// Bulk tasks
export function getTasksUncompleted(fromMoment, toMoment, userId) {
  return new Promise((resolve, reject) => {
    const newFrom = moment(fromMoment || BASE_START);
    const newTo = moment(toMoment || BASE_END);
    baseGet(
      Urls.tasksUncompleted,
      {
        from: newFrom.toISOString(),
        to: newTo.add(1, 'd').toISOString(),
        user_id: userId,
      },
      resolve,
      reject
    );
  });
}

export function getTasksCompleted(fromMoment, toMoment, userId) {
  return new Promise((resolve, reject) => {
    const newFrom = moment(fromMoment || BASE_START);
    const newTo = moment(toMoment || BASE_END);
    baseGet(
      Urls.tasksCompleted,
      {
        from: newFrom.toISOString(),
        to: newTo.add(1, 'd').toISOString(),
        user_id: userId,
      },
      resolve,
      reject
    );
  });
}

export function bulkUpdateSync(ids = [], updating = {}) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.tasksBulk,
      {
        done: updating.done || false,
        reminder_ids: uniq(ids),
        skip: updating.skip || false,
        success: updating.success || false,
      },
      resolve,
      reject
    );
  });
}

export function bulkUpdateAsync(ids = [], updating = {}) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.tasksBulkAsync,
      {
        done: updating.done || false,
        reminder_ids: uniq(ids),
        skip: updating.skip || false,
        success: updating.success || false,
      },
      resolve,
      reject
    );
  });
}
