import UserActionTypes from 'web/user/libs/userActionTypes';
import {
  getIdentities as getIdentitiesCall,
  removeIdentity as removeIdentityCall,
  createIdentity as createIdentityCall,
  updateIdentity as updateIdentityCall,
} from 'web/user/services/identitiesService';
import { parseIdentities, parseIdentity } from 'web/user/helpers/parsers';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export const setIdentities = (identitiesData) => ({
  type: UserActionTypes.setIdentities,
  identitiesData,
});

export const setDefaultIdentity = (identitiesData) => (dispatch) => {
  if (identitiesData.length) {
    dispatch({
      type: UserActionTypes.setDefaultIdentity,
      defaultIdentityData: identitiesData[0].id,
    });
  }
};

export const setIdentitiesFetched = (fetched) => (dispatch) => {
  dispatch({
    type: UserActionTypes.setIdentitiesFetched,
    fetched,
  });
};

export const setIdentitiesFetching = () => (dispatch) =>
  dispatch({
    type: UserActionTypes.setIdentitiesFetching,
  });

export const getIdentities = () => (dispatch) => {
  dispatch(setIdentitiesFetching());
  return getIdentitiesCall().then((identities) => {
    const parsedIdentities = parseIdentities(identities);
    dispatch(setIdentities(parsedIdentities));
    dispatch(setDefaultIdentity(parsedIdentities));
    toutBackboneHelper.updateIdentities(identities);
  });
};

export const removeIdentity = (id) => (dispatch, getState) =>
  removeIdentityCall(id).then(() => {
    dispatch({
      type: UserActionTypes.removeIdentity,
      id,
    });
    toutBackboneHelper.updateIdentities(getState().identities);
  });

export const createIdentity = (identity) => (dispatch, getState) => {
  const data = {
    ...identity,
    signature: '',
    generate_signature: true,
  };

  return createIdentityCall(data).then((newData) => {
    const newIdentity = parseIdentity(newData);
    dispatch({
      type: UserActionTypes.addIdentity,
      identity: newIdentity,
    });
    toutBackboneHelper.updateIdentities(getState().identities);
    return newIdentity;
  });
};

export const updateIdentity = (identity) => (dispatch, getState) => {
  const data = {
    ...identity,
    generate_signature: false,
  };

  return updateIdentityCall(data).then((updatedData) => {
    const updatedIdentity = parseIdentity(updatedData);
    dispatch({
      type: UserActionTypes.updateIdentity,
      identity: parseIdentity(updatedIdentity),
    });
    toutBackboneHelper.updateIdentities(getState().identities);
    return updatedIdentity;
  });
};
