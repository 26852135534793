import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import { injectIntl, FormattedMessage } from 'react-intl';
import Switch from 'components/buttons/switch';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';

const PluginUnsubscribeMessage = (props) => {
  const {
    showPluginUnsubscribeMessage,
    onChange,
    loading,
    intl: { formatMessage },
  } = props;
  const pluginUnsubscribeLabel = formatMessage({
    id: 'web.settings.unsubscribe.plugin.switch.label',
  });
  return (
    <div className="unsubscribe">
      {loading && <LoadingSpinner imageUrl={getImageUrl} />}
      <Card width={500} className="salesforce-sync-card">
        <h3 className="sky-design-h3">
          <FormattedMessage id="web.settings.unsubscribe.plugin.title" />
        </h3>
        <div className="margin-top-20">
          <Switch
            label={pluginUnsubscribeLabel}
            checked={showPluginUnsubscribeMessage}
            onChange={onChange}
            name="apply-team-settings"
          />
        </div>
        <div className="margin-top-20">
          <FormattedMessage id="web.settings.unsubscribe.plugin.content" />
        </div>
      </Card>
    </div>
  );
};

export default injectIntl(PluginUnsubscribeMessage);
