import { createAction } from '@reduxjs/toolkit';
import OnboardingActionTypes from 'web/onboarding/lib/onboardingActionTypes';
import Logger from 'owa/libs/logger';
import {
  identityEmailErrorCatching,
  setLoading,
} from '../../slideOuts/editIdentity/actionCreators/editIdentityActionCreators';
import { updateIdentity as updateIdentityActionCreator } from '../../user/actionCreators/identitiesActionCreators';
import {
  makeUserNew,
  getDeliveryChannels,
  getOnboardingAuthentications,
} from '../services/onboardingService';
import { navigateToUrl } from 'web/services/routerService';
import { Urls } from 'web/libs/constants';
import { productTourSteps } from 'web/productTour/containers/productTourStepsContainer/lib/productTourConstants';

export const setOnboardingActiveStep = (activeStep) => ({
  activeStep,
  type: OnboardingActionTypes.setActiveStep,
});

export const setSkippedStep = (skippedStep) => ({
  skippedStep,
  type: OnboardingActionTypes.setSkippedStep,
});

export const removeSkippedStep = (unskippedStep) => ({
  type: OnboardingActionTypes.removeSkippedStep,
  unskippedStep,
});

export const setCompletedStep = (completedStep) => ({
  completedStep,
  type: OnboardingActionTypes.setCompletedStep,
});

export const updateIdentityEmailSignature = (identity) => (dispatch) =>
  dispatch(updateIdentityActionCreator(identity)).catch(({ response = {} }) => {
    dispatch(identityEmailErrorCatching(response));
  });

export const finishOnboardingAction = () => (dispatch) => {
  dispatch(setLoading());
  makeUserNew()
    .then(() => {
      dispatch(setLoading(false));
      localStorage.removeItem('onboardingEmailAuthSuccess');
      localStorage.removeItem('onboardingCrmAuthSuccess');
      navigateToUrl(Urls.productTour.welcome);
    })
    .catch((error) => {
      Logger.error(
        '/users/change_user_status - Change user status error',
        error
      );
      dispatch(setLoading(false));
    });
};

export const markUserAsNotNew = (remind) => (dispatch) => {
  dispatch(setLoading());
  makeUserNew(remind)
    .then(() => {
      dispatch(setLoading(false));
      localStorage.removeItem('onboardingEmailAuthSuccess');
      localStorage.removeItem('onboardingCrmAuthSuccess');
    })
    .catch((error) => {
      Logger.error(
        '/users/change_user_status - Change user status error',
        error
      );
      dispatch(setLoading(false));
    });

  dispatch(goToProductTourStep(productTourSteps.step5));
};

export const getDeliveryChannelsAc = () => (dispatch) => {
  dispatch(setLoading());
  return getDeliveryChannels()
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      Logger.error('delivery_channels.json - Change user status error', error);
      dispatch(setLoading(false));
    });
};

export const getOnboardingAuthenticationsAc = () => (dispatch) => {
  dispatch(setLoading());

  return getOnboardingAuthentications()
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        onboardingAuthentications: res,
        type: OnboardingActionTypes.getOnboardingAuthentications,
      });
    })
    .catch((error) => {
      Logger.error('authentications.json - Get authentications error', error);
      dispatch(setLoading(false));
    });
};

export const goToProductTourStep = (stepName = '') => (dispatch) => {
  dispatch(setProductTourActiveTabName(stepName));
  navigateToUrl(Urls.productTour.steps);
};

export const setRemindCompleteOnboarding = createAction(
  'SET_REMIND_COMPLETE_ONBOARDING'
);
export const setProductTourActiveTabName = createAction(
  'SET_PRODUCT_TOUR_ACTIVE_TAB_NAME'
);
