import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'web/topNavbar/components/dropdown/dropdown';
import withDropdownToggler from 'web/topNavbar/hocs/dropdownToggleHoc';

import './dropdownButtonMenu.scss';

const DropdownButtonMenu = (props) => {
  const { toggleDropdown, showDropdown, hideDropdown, items, titleId } = props;

  return (
    <div className="group-actions-dropdown-toggle" onClick={toggleDropdown}>
      <Dropdown
        disableClick={!showDropdown}
        items={items}
        onOutsideClick={hideDropdown}
        show={showDropdown}
        titleId={titleId}
      />
    </div>
  );
};

DropdownButtonMenu.propTypes = {
  hideDropdown: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  titleId: PropTypes.string.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
};

export default withDropdownToggler(DropdownButtonMenu);
