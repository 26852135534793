import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import SmartSelect from 'components/selects/smartSelect/smartSelect';
import './peopleGroupSmartFilter.scss';

const ROWS_SHOWN = 6;
class PeopleGroupSmartFilter extends Component {
  render() {
    return (
      <div className="people-group-smart-filter">
        <div className="people-group-filter-label">
          <FormattedMessage id="common.byGroup" />
        </div>
        <SmartSelect
          className="people-group-filter-combo-box"
          exceptionSelectors={[]}
          onSelect={this.selectItem}
          rowsShown={ROWS_SHOWN}
          {...this.props}
        />
      </div>
    );
  }

  selectItem = (selectedValue) => {
    const { selectFilterValueAction, clearFilterAction } = this.props;
    const { value } = selectedValue;
    value >= 0 ? selectFilterValueAction(selectedValue) : clearFilterAction();
  };
}

PeopleGroupSmartFilter.propTypes = {
  clearFilterAction: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  items: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  selectFilterValueAction: PropTypes.func.isRequired,
  selectedItemValue: PropTypes.number,
};

PeopleGroupSmartFilter.defaultProps = {
  items: [],
  selectedItemValue: -1,
};

export default PeopleGroupSmartFilter;
