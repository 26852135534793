import { Urls } from 'web/libs/routes';
import { openNewTabWithSize, openNewTab } from 'web/services/routerService';
import { SourceOpener } from 'libs/constantsShared';

export const openAddTask = () => {
  openNewTabWithSize(Urls.createNewTask(SourceOpener.liveFeed), 680, 716);
};

export const openEditTask = (id) => {
  openNewTabWithSize(Urls.tasksEditWindow(id, SourceOpener.liveFeed), 680, 716);
};

export const openPdv = (personId) => {
  openNewTab(Urls.contact(personId));
};
