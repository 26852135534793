export const TaskModalComponentIds = {
  task: 'TASK_MODAL_COMPONENT_IDS',
};

export const AlertIds = {
  noContactFound: 'TASK_MODAL_ALERT_NO_CONTACT_FOUND',
  notLoggedIn: 'TASK_MODAL_ALERT_NOT_LOGGED_IN',
  saveFailed: 'TASK_MODAL_ALERT_SAVE_FAILED',
  unverifiedUser: 'TASK_MODAL_ALERT_UNVERIFIED_USER',
};

export const ModalsTaskAlertIds = {
  deleteTaskFailed: 'MODALS_TASK_ALERT_DELETE_TASK_FAILED',
  noContactFound: 'MODALS_TASK_ALERT_NO_CONTACT_FOUND',
  notLoggedIn: 'MODALS_TASK_ALERT_NOT_LOGGED_IN',
  notSharedContactError: 'MODALS_TASK_ALERT_NOT_SHARED_CONTACT_ERROR',
  saveTaskFailed: 'MODALS_TASK_ALERT_SAVE_TASK_FAILED',
  unprocessableEntityServerError: 'UNPROCESSABLE_ENTITY_SERVER_ERROR',
  unverifiedUser: 'MODALS_TASK_ALERT_UNVERIFIED_USER',
};
