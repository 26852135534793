export default {
  appendAddress: 'COMPOSE_WINDOW_APPEND_ADDRESS',
  loadingAutocomplete: 'COMPOSE_WINDOW_LOADING_AUTOCOMPLETE',
  loadingDynamicFields: 'COMPOSE_WINDOW_LOADING_DYNAMIC_FIELDS',
  populateAutocomplete: 'COMPOSE_WINDOW_POPULATE_AUTOCOMPLETE',
  removeAddressByIndex: 'COMPOSE_WINDOW_REMOVE_ADDRESS_BY_INDEX',
  setAddresses: 'COMPOSE_WINDOW_SET_ADDRESSES',
  updateAddressByEmail: 'COMPOSE_WINDOW_UPDATE_ADDRESS_BY_EMAIL',
  updateDynamicFields: 'COMPOSE_WINDOW_UPDATE_DYNAMIC_FIELDS',
  updateIdentity: 'COMPOSE_WINDOW_UPDATE_IDENTITY',
  updateSubject: 'COMPOSE_WINDOW_UPDATE_SUBJECT',
};
