import { combineReducers } from 'redux';
import { onboardingActiveStep } from './onboardingActiveStep';
import * as skippedSteps from './skippedStepsReducer';
import {
  completedStepsReducer as completedSteps,
  onboardingAuthentications,
} from './completedStepsReducer';

export default {
  onboarding: combineReducers({
    completedSteps,
    onboardingActiveStep,
    onboardingAuthentications,
    ...skippedSteps,
  }),
};
