import ActionTypes from 'web/libs/actionTypes/actionTypes';
import sortBy from 'lodash/sortBy';
import { getCampaignsCategories as getCampaignsCategoriesCall } from 'web/modals/addToCampaign/services/api';
import { LocalStorageKeys } from 'web/libs/constants';
import { CampaignsSpecialCategories } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { onFilterChange } from 'web/modals/addToCampaign/actionCreators/filterActionCreators';
import { campaignForFilter } from 'web/modals/addToCampaign/actionCreators/campaignActionCreators';
import { getFilteredCampaigns } from 'web/modals/addToCampaign/selectors/addToCampaignSelectors';
import { setCampaign } from 'web/modals/addToCampaign/actionCreators/campaignActionCreators';
import { track } from 'web/services/mixpanelService';
import {
  CampaignActionEvents,
  CampaignProperties,
} from 'web/libs/mixpanelEvents';
import I18N from 'languages';

const {
  all: { id: ALL_CATEGORY_ID },
} = CampaignsSpecialCategories;

export const setCategories = (categories) => ({
  type: ActionTypes.api.categories.success,
  categories,
});

export const selectCurrentCategory = (categoryId, campaign) => (
  dispatch,
  getState
) => {
  track(CampaignActionEvents.addToCampaignAction, {
    'Action Type':
      CampaignProperties.addToCampaignActionTypes.selectToutCategory,
  });

  dispatch(
    onFilterChange(categoryId, LocalStorageKeys.campaigns.lastCategoryModal)
  );

  const filteredCampaigns = getFilteredCampaigns(getState());
  dispatch(setCampaign(campaignForFilter(filteredCampaigns, campaign)));
};

export function getCampaignsCategories() {
  return (dispatch, getState) =>
    getCampaignsCategoriesCall()
      .then((data) => {
        // TODO: move to selector
        const sortedData = sortBy(data, (cat) => cat.name.toLowerCase());
        sortedData.unshift({ id: -1, name: I18N.getStr('common.all') });

        dispatch(setCategories(sortedData));
      })
      .catch(() => dispatch({ type: ActionTypes.api.categories.failure }));
}

export const initCurrentCategory = () => (dispatch, getState) => {
  const { addToCampaignModalSelectedCampaign: selectedCampaign } = getState();
  const { categoryId: selectedCategoryId } = selectedCampaign;

  if (selectedCategoryId) {
    dispatch(selectCurrentCategory(selectedCategoryId, selectedCampaign));
  } else {
    const lastUsedCategory = window.localStorage.getItem(
      LocalStorageKeys.campaigns.lastCategoryModal
    );
    const currentCategory = parseInt(lastUsedCategory, 10) || ALL_CATEGORY_ID;
    dispatch(selectCurrentCategory(currentCategory));
  }
};
