import {
  ADVANCED_SEARCH_FILTERS_COLLECTION,
  FILTER_SEARCH_STATUS_ID,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { setSmartFilter } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';
import { getFilterValues } from 'web/commandCenter/modules/statusSmartFilter/helpers/statusSmartFilterHelpers';
import I18N from 'languages';
import { getAdvancedSearchStatus } from 'web/commandCenter/modules/statusSmartFilter/selectors/statusSmartFilterSelectors';
import { setReferenceDateBasedOnStatus } from 'web/commandCenter/modules/whenSmartFilter/actionCreators/referenceDateSmartFilterActiionCreators';

export const setAdvancedSearchStatusValue = ({ subTabIds, tabId }) => (
  dispatch
) => {
  const filterValues = getFilterValues(subTabIds, tabId);

  dispatch(
    setSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: FILTER_SEARCH_STATUS_ID,
      payload: {
        filterLabel: I18N.getStr('common.status'),
        filterPrefix: I18N.getStr(CommandCenterEmailsTabsEnum[tabId].label),
        filterValues,
        subTabIds,
        tabId,
      },
    })
  );

  dispatch(setReferenceDateBasedOnStatus(subTabIds, tabId));
};

export const clearAdvancedSearchStatusValue = () => (dispatch) => {
  dispatch(
    setAdvancedSearchStatusValue({
      subTabIds: [],
      tabId: CommandCenterEmailsTabsEnum.sent.value,
    })
  );
};

export const addSentSubStatusToFilter = (subTabId) => (dispatch, getState) => {
  const { subTabIds } = getAdvancedSearchStatus(getState(), {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
    filterId: FILTER_SEARCH_STATUS_ID,
  });
  dispatch(
    setAdvancedSearchStatusValue({
      subTabIds: [...subTabIds, subTabId],
      tabId: CommandCenterEmailsTabsEnum.sent.value,
    })
  );
};

export const replaceSentSubStatusInFilter = (
  activitySelectedIndex,
  subTabId
) => (dispatch, getState) => {
  const { subTabIds } = getAdvancedSearchStatus(getState(), {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
    filterId: FILTER_SEARCH_STATUS_ID,
  });
  dispatch(
    setAdvancedSearchStatusValue({
      subTabIds: [
        ...subTabIds.slice(0, activitySelectedIndex),
        subTabId,
        ...subTabIds.slice(activitySelectedIndex + 1),
      ],
      tabId: CommandCenterEmailsTabsEnum.sent.value,
    })
  );
};

export const deleteSentSubStatusInFilter = (activitySelectedIndex) => (
  dispatch,
  getState
) => {
  const { subTabIds } = getAdvancedSearchStatus(getState(), {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
    filterId: FILTER_SEARCH_STATUS_ID,
  });

  dispatch(
    setAdvancedSearchStatusValue({
      subTabIds: [
        ...subTabIds.slice(0, activitySelectedIndex),
        ...subTabIds.slice(activitySelectedIndex + 1),
      ],
      tabId: CommandCenterEmailsTabsEnum.sent.value,
    })
  );
};
