import EmailSettingsActionTypes from '../libs/emailSettingsActionTypes';
import { EmailSettingsTabs } from '../libs/emailSettingsConstants';

export const emailSettingsTab = (
  state = EmailSettingsTabs.identities,
  { type, tab }
) => {
  switch (type) {
    case EmailSettingsActionTypes.setTab:
      return tab;
    default:
      return state;
  }
};
