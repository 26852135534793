import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import { paginatePeople } from 'web/people/actionCreators/peopleFetchActionCreators';
import debounce from 'lodash/debounce';
import { track } from 'web/services/mixpanelService';
import {
  UnsubscribeEvents,
  UnsubscribeProperties,
  UnsubscribePropertyTypes,
} from 'web/libs/mixpanelEvents';

export const SEARCH_DEBOUNCE = 600;

const debouncePaginatePeople = debounce((dispatch) => {
  dispatch(paginatePeople());
  track(UnsubscribeEvents.search, {
    [UnsubscribeProperties.actionType]: UnsubscribePropertyTypes.search,
  });
}, SEARCH_DEBOUNCE);

export function peopleResetSearch() {
  return { type: PeopleActionTypes.search.reset };
}

export function peopleSearch(value) {
  return (dispatch, getState) => {
    dispatch({ type: PeopleActionTypes.search.set, string: value });
    debouncePaginatePeople(dispatch);
  };
}
