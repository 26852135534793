import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  AddressLocations,
  AddressTypes,
} from 'web/person/libs/personDetailsConstants';
import { TextWeights } from 'libs/constantsStyles';
import TextButton from 'components/buttons/text';
import './infoCardDetailsAddresses.scss';

class InfoCardDetailsAddresses extends Component {
  render() {
    const { className, disabled } = this.props;
    const listItems = this._getListItems();

    if (listItems.length) {
      return (
        <div
          className={classNames(
            'person-details-info-card-details-addresses text-overflow',
            className
          )}
        >
          <h5 className="person-details-field-header">
            <FormattedMessage id={this._getHeaderTextId()} />
          </h5>
          <ul>
            {listItems.map(({ id, value }, index) => (
              <li
                className="text-overflow"
                key={`info-card-details-address-${id}`}
              >
                <TextButton
                  className="text-overflow cross-browser-width"
                  data-index={index}
                  disabled={disabled}
                  onClick={this._onClick}
                  weight={TextWeights.light}
                >
                  {value}
                </TextButton>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }

  _getHeaderTextId = () => {
    const { headerText, location, primary, type } = this.props;

    if (headerText) return headerText;
    if (primary && type === AddressTypes.email) {
      return 'common.email';
    } else if (primary && type === AddressTypes.phone) {
      return 'common.phone';
    } else {
      switch (location) {
        case AddressLocations.work:
          return 'common.work';
        case AddressLocations.personal:
          return 'common.personal';
        case AddressLocations.mobile:
          return 'common.mobile';
        case AddressLocations.other:
        default:
          return 'common.other';
      }
    }
  };

  _getListItems = () => {
    const { addresses, primary, type } = this.props;
    if (type === AddressTypes.email || type === AddressTypes.phone) {
      return addresses.filter((address) => address.isPrimary === primary);
    } else {
      return addresses;
    }
  };

  _onClick = (e) => {
    const { addresses, disabled, onClick } = this.props;
    if (disabled) return;

    /* Don't fire onClick if highlighting address */
    if (!window.getSelection().toString()) {
      onClick(addresses[+e.target.dataset.index]);
    }
  };
}

InfoCardDetailsAddresses.propTypes = {
  addresses: PropTypes.array.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  headerText: PropTypes.string,
  location: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  type: PropTypes.string,
};

InfoCardDetailsAddresses.defaultProps = {
  className: '',
  disabled: false,
  headerText: '',
  primary: false,
  type: '',
};

export default InfoCardDetailsAddresses;
