import { default as I18N, moment } from 'languages';
import sortBy from 'lodash/sortBy';

const CREATED_AT_FORMATTING = 'll';
function parseCampaignTemplate(data, user = { subscription: { users: [] } }) {
  const subscriptionUser =
    user.subscription.users.find((obj) => data.user_id === obj.id) || {};
  return {
    id: data.id,
    campaignId: data.workflow.id,
    campaignName: data.workflow.name || '',
    activePeople: data.workflow.total_currently_active_count || 0,
    createdAt: data.workflow.created_at
      ? I18N.getLocalizedDate(
          data.workflow.created_at,
          I18N.DateFormats.DATE_SHORT
        )
      : '',
    usedBy:
      subscriptionUser.name ||
      subscriptionUser.email ||
      I18N.getStr('common.unknown'),
  };
}

export function parseActiveCampaignTemplates(data = [], user) {
  const parsedData = data.reduce((accumulator, campaignTemplate) => {
    if (campaignTemplate.workflow) {
      accumulator.push(parseCampaignTemplate(campaignTemplate, user));
    }
    return accumulator;
  }, []);
  return sortBy(parsedData, [
    'activePeople',
    (obj) => moment(obj.createdAt, CREATED_AT_FORMATTING).valueOf(),
  ]).reverse();
}
