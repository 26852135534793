import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from 'components/collapse';

const AccordionItemBody = ({
  children,
  isOpen,
  className,
  collapseOptions,
}) => {
  const classes = classNames('tout-accordion-body', className);

  return (
    <div className={classes}>
      <Collapse
        className="tout-accordion-collapse"
        in={isOpen}
        {...collapseOptions}
      >
        {children}
      </Collapse>
    </div>
  );
};

AccordionItemBody.defaultProps = {
  className: '',
  collapseOptions: {},
  isOpen: false,
};

AccordionItemBody.propTypes = {
  children: PropTypes.node.isRequired,
  collapseOptions: PropTypes.object,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default AccordionItemBody;
