import { createAction } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';
import { setSmartFilterCollection } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import {
  getSavedSearchTableId,
  navigateToSavedSearch,
  getAdvancedSearchTableId,
  navigateToAdvancedSearch,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/helpers/advancedSearchHelpers';
import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';
import { debouncePaginateEmails } from 'web/commandCenter/actionCreators/commandCenterEmailsSearchActionCreators';
import { SidebarMenuItems } from 'web/commandCenter/libs/commandCenterConstants';
import {
  ADVANCED_SEARCH_FILTERS_COLLECTION,
  FILTER_SEARCH_ID,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { copySmartFilters } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { canResetSavedAdvabcedSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';

export const setEmailSelectedSavedAdvancedSearchId = createAction(
  'SET_EMAIL_SELECTED_ADVANCED_SEARCH_ID'
);

export const resetEmailSelectedSavedAdvancedSearchId = createAction(
  'RESET_EMAIL_SELECTED_ADVANCED_SEARCH_ID'
);

export const lockResetEmailSelectedSavedAdvancedSearchId = createAction(
  'LOCK_RESET_EMAIL_SELECTED_SAVED_ADVANCED_SEARCH_ID'
);

export const unlockResetEmailSelectedSavedAdvancedSearchId = createAction(
  'UNLOCK_RESET_EMAIL_SELECTED_SAVED_ADVANCED_SEARCH_ID'
);

export const markEmailSelectedSavedAdvancedSearchAsChanged = createAction(
  'MARK_EMAIL_SELECTED_ADVANCED_SEARCH_AS_CHANGED'
);

export const markEmailSelectedSavedAdvancedSearchAsNotChanged = createAction(
  'MARK_EMAIL_SELECTED_ADVANCED_SEARCH_AS_NOT_CHANGED'
);

export const beforeChangeSmartFilterAdvancedSearchAction = (filterParams) => (
  dispatch,
  getState
) => {
  if (
    canResetSavedAdvabcedSearch(getState()) &&
    filterParams.collectionId === ADVANCED_SEARCH_FILTERS_COLLECTION
  ) {
    dispatch(markEmailSelectedSavedAdvancedSearchAsChanged());
  }
};

export const afterChangeSmartFilterAdvancedSearchAction = (filterParams) => (
  dispatch,
  getState
) => {
  const canResetSavedSearch = canResetSavedAdvabcedSearch(getState());

  if (
    canResetSavedSearch &&
    filterParams.collectionId !== ADVANCED_SEARCH_FILTERS_COLLECTION
  ) {
    const advancedSearchTableId = getAdvancedSearchTableId();
    const savedSearchTableId = getSavedSearchTableId();

    dispatch(
      copySmartFilters({
        collectionFromId: savedSearchTableId,
        collectionToId: advancedSearchTableId,
      })
    );

    if (filterParams.filterId === FILTER_SEARCH_ID) {
      debounceNavigateToAdvancedSearch();
    } else {
      navigateToAdvancedSearch();
    }
  }

  if (!canResetSavedSearch) {
    dispatch(unlockResetEmailSelectedSavedAdvancedSearchId());
  }
};

const SEARCH_DEBOUNCE = 600;
const debounceNavigateToAdvancedSearch = debounce(() => {
  navigateToAdvancedSearch();
}, SEARCH_DEBOUNCE);

export const openSavedAdvancedSearch = (savedSearchId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentTableId = getActiveEmailTableId(state);
  const savedSearchTableId = getSavedSearchTableId();
  const selectedSidebarItem = state.sidebarSelectedItem;

  const savedSearch = state.emailSavedAdvancedSearches[savedSearchId];

  dispatch(setEmailSelectedSavedAdvancedSearchId({ id: savedSearchId }));

  dispatch(
    setSmartFilterCollection({
      collectionId: savedSearchTableId,
      samrtFiltersCollection: savedSearch.specs.smartFiltersCollection,
    })
  );

  selectedSidebarItem === SidebarMenuItems.emails.id && //open emails in sidebar
  currentTableId === savedSearchTableId //open saved search table
    ? debouncePaginateEmails(dispatch)
    : navigateToSavedSearch();
};
