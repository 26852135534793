import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { GlobalAlertIds } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { updateSettings as updateSettingsCall } from 'web/campaigns/settings/services/campaignsSettingsService';
import { openCampaignAlert } from 'web/campaigns/campaignsTab/actionCreators/campaignsAlertActionCreators';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { track } from 'web/services/mixpanelService';
import { CampaignActionEvents } from 'web/libs/mixpanelEvents';

const trackUpdateSettings = (updatedSettings) => {
  const keys = Object.keys(updatedSettings);
  if (!keys.length) {
    return;
  }
  track(CampaignActionEvents.updateCampaignSettings, {
    'Setting Type': keys[0],
    'Setting Value': updatedSettings[keys[0]],
  });
};

export const updateSettings = (campaign, updatedSettings) => {
  let workflowSetting;

  return (dispatch) => {
    workflowSetting = { ...campaign.workflow_setting, ...updatedSettings };

    dispatch({
      type: ActionTypes.common.data.campaignUpdateByProperty,
      id: campaign.id,
      newProperties: { workflow_setting: workflowSetting },
    });

    updateSettingsCall(campaign.id, updatedSettings)
      .then((data) => {
        toutBackboneHelper.campaignUpdate(data);
        dispatch(openCampaignAlert(GlobalAlertIds.settingsSuccess));
        trackUpdateSettings(updatedSettings);
      })
      .catch(() => {
        workflowSetting = { ...campaign.workflow_setting };

        dispatch({
          type: ActionTypes.common.data.campaignUpdateByProperty,
          id: campaign.id,
          newProperties: { workflow_setting: workflowSetting },
        });

        dispatch(openCampaignAlert(GlobalAlertIds.settingsError));
      });
  };
};
