import debounce from 'lodash/debounce';
import ActionTypes from 'table/modules/actionTypes';
import trackerEvents from 'table/modules/tracker/trackerEvents';
import track from 'table/modules/tracker';

const trackSearchChange = (length, category) => {
  track(trackerEvents.searchChange, { length, category });
};
const debouncedTrack = debounce(trackSearchChange, 500);

export function onSearchChange(value = '') {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.searchSetValue,
      value,
    });

    debouncedTrack(value.length, getState().searchPrefix);
  };
}

export function onSearchResultSelected(selected = { category: {}, value: {} }) {
  track(trackerEvents.searchMultipleSelected, {
    category: selected.category.data,
  });
  return {
    type: ActionTypes.searchSetPrefix,
    prefix: selected.category.data,
    searchIndex: selected.category.index,
    value: selected.value.data,
  };
}

export function onSearchPrefixRemove() {
  return {
    type: ActionTypes.searchClearPrefix,
  };
}
