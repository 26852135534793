import { Origins } from 'web/liveFeed/engage/libs/engageConstants';
import { isNullOrWhiteSpace } from 'web/shared/helpers/commonHelper';
import {
  MarketoEvents,
  MarketoActivityTypeMap,
  DYNAMIC_CHAT_ACTIVITY,
} from 'web/liveFeed/libs/liveFeedConstants';
import isEmpty from 'lodash/isEmpty';

const individualEvent = (toutEvent = {}) => ({
  id: toutEvent.id,
  type: toutEvent.event,
  description: toutEvent.event_description,
  date: toutEvent.created_at,
  city: toutEvent.city,
  state: toutEvent.state,
  url: toutEvent.url,
  title: toutEvent.title,
});

const parseToutEvent = (toutEvent = { pitch: {} }) => ({
  email: toutEvent.pitch.email,
  name: isNullOrWhiteSpace(toutEvent.pitch.name)
    ? toutEvent.pitch.email
    : toutEvent.pitch.name,
  title: toutEvent.title || toutEvent.pitch.subject,
  id: toutEvent.pitch_id,
  pitch_id: toutEvent.pitch_id,
  addressId: toutEvent.pitch.address_id,
  personId: toutEvent.pitch.person_id,
  origin: Origins.tout,
  latestEventTimestamp: toutEvent.created_at,
  events: [individualEvent(toutEvent)],
});

const parseAttributes = (attributes = []) =>
  attributes.reduce((accumulator, item) => {
    accumulator[item.name.toLowerCase()] = item.value;
    return accumulator;
  }, {});

const parseMarketoEvents = (marketoEvents = []) => {
  const parsedEvents = [];
  marketoEvents.forEach((event) => {
    const parsedAttributes = parseAttributes(event.attributes);
    const { activityDate, activityTypeId, marketoGUID } = event;

    parsedEvents.push({
      activity: {
        typeId: activityTypeId,
        attributes: parsedAttributes,
      },
      type: getEventType(parsedAttributes.type, activityTypeId),
      description: parsedAttributes.description,
      date: activityDate,
      id: marketoGUID,
      origin: Origins.marketo,
    });
  });

  return parsedEvents;
};

const getEventType = (type, activityTypeId) => {
  // MT: This can fail if the type if blank and is not a DC
  // activity type. The `type` will then be blank and that's intentional
  if (
    isEmpty(type) &&
    MarketoActivityTypeMap[activityTypeId] === DYNAMIC_CHAT_ACTIVITY
  ) {
    type = MarketoEvents.dynamicChat;
  }

  return type;
};

const parseMarketoLead = (lead = {}) => {
  let activities = [];

  if (isEmpty(lead.dynamic_chat_activities)) {
    activities = lead.interesting_moments;
  } else {
    activities = lead.dynamic_chat_activities;
  }

  const numOfActivities = activities.length;

  return {
    email: lead.email,
    company: lead.company,
    title: lead.title,
    firstName: lead.firstName,
    lastName: lead.lastName,
    id: lead.id,
    origin: Origins.marketo,
    leadScore: lead.leadScore,
    latestEventTimestamp: numOfActivities
      ? activities[numOfActivities - 1].activityDate
      : null, // need to fix this to find IM event date
    events: parseMarketoEventsFlat(activities),
    length: numOfActivities,
    unsubscribed: lead.unsubscribed,
  };
};

export const formatInitialToutEvents = (toutEvents = []) => {
  const parsedEvents = [];
  toutEvents.forEach((toutEvent) => {
    const eventExists = parsedEvents.find(
      ({ pitch_id: pitchId }) => toutEvent.pitch_id === pitchId
    );
    const event = individualEvent(toutEvent);

    if (eventExists) {
      eventExists.events.push(event);
    } else {
      parsedEvents.push(parseToutEvent(toutEvent));
    }
  });

  return parsedEvents;
};

export const formatToutEvent = (event) => parseToutEvent(event);

export const formatMarketoLeads = (marketoLeads = []) =>
  marketoLeads.map(parseMarketoLead);

export const mergeMarketoToutEvents = (marketoEvents = [], toutEvents = []) => {
  const mergedLeads = toutEvents.concat(marketoEvents);
  return mergedLeads.sort(
    (first, second) =>
      new Date(second.latestEventTimestamp) -
      new Date(first.latestEventTimestamp)
  );
};

// MT: LiveFeed operates on the concept of showing live data
// in the context of a lead. A notification conforms to that
// design, but doesn't really have all the necessary attributes
// since the leads wouldn't necessarily be part of MLM yet.
// Hence, we format the notification to set the context to its
// lead
export const formatNotification = (notification = {}) => {
  const {
    lead: { title, company, name },
  } = notification;

  delete notification.lead;

  return {
    company,
    title,
    id: crypto.randomUUID(),
    name,
    origin: Origins.dynamicChat,
    latestEventTimestamp: +new Date(),
    events: [notification],
  };
};

export const parseToutEventFromEmail = (toutEvent = {}) => ({
  ...individualEvent(toutEvent),
  emailId: toutEvent.pitch_id,
  emailSubject: toutEvent.subject,
  origin: Origins.tout,
});

export const sortParsedEvents = (first, second) =>
  (first.date >= second.date && -1) || 1;

// const parseMarketoEventFlat = (marketoEvent = {}) => ({
//   origin: Origins.marketo,
// });

export const parseMarketoEventsFlat = (events = []) =>
  parseMarketoEvents(events).sort(sortParsedEvents);
