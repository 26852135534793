import SalesforceActionTypes from '../libs/salesforceActionTypes';
import { getSalesforceAccount } from '../services/salesforceService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { SalesforceAlertIds } from 'web/salesforce/libs/salesforceConstants';

export const setAccount = (account = {}) => ({
  type: SalesforceActionTypes.setAccount,
  account,
});

export const setAccountFetching = (fetching = true) => ({
  type: SalesforceActionTypes.setAccountFetching,
  fetching,
});

export const setAccountFetched = (fetched = true) => ({
  type: SalesforceActionTypes.setAccountFetched,
  fetched,
});

export const getAccount = () => (dispatch) => {
  dispatch(setAccountFetching());
  return getSalesforceAccount()
    .then((account) => {
      if (account) {
        dispatch(setAccount(account));
      } else {
        dispatch(openViewAlert(SalesforceAlertIds.somethingWentWrong));
      }
      return account;
    })
    .catch(() => {
      dispatch(openViewAlert(SalesforceAlertIds.somethingWentWrong));
    })
    .finally(() => {
      dispatch(setAccountFetching(false));
      dispatch(setAccountFetched());
    });
};
