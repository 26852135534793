import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PeopleSearchAdd from 'web/people/peopleSearchAdd/components/peopleSearchAdd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getGroups } from 'web/campaigns/campaignsTab/selectors/campaignsTabGroupsSelectors';
import { addPeopleGroup } from 'web/people/peopleSearchAdd/actionCreators/groupsActionCreators';
import { setAddPeopleIdentity } from 'web/people/peopleSearchAdd/actionCreators/identitiesActionCreators';
import {
  addPeopleAutocompleteChange,
  addPeopleLeaveAutocomplete,
} from 'web/people/peopleSearchAdd/actionCreators/autocompleteActionCreators';
import {
  addPeoplePerson,
  addPeopleOnChange,
} from 'web/people/peopleSearchAdd/actionCreators/peopleActionCreators';

class PeopleSearchAddContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;
    this.actionCreators = bindActionCreators(
      {
        addPeopleAutocompleteChange,
        addPeopleGroup,
        addPeopleLeaveAutocomplete,
        addPeopleOnChange,
        addPeoplePerson,
        setAddPeopleIdentity,
      },
      dispatch
    );
  }

  render() {
    const {
      autocompleteLoading,
      autocompleteResults,
      autocompleteValue,
      groups,
      identities,
      peopleSearchAddState: { people, identity, selectedIndex },
    } = this.props;
    const {
      addPeopleAutocompleteChange: addPeopleAutocompleteChangeAC,
      addPeopleGroup: addPeopleGroupAC,
      addPeopleLeaveAutocomplete: addPeopleLeaveAutocompleteAC,
      addPeopleOnChange: addPeopleOnChangeAC,
      addPeoplePerson: addPeoplePersonAC,
      setAddPeopleIdentity: setAddPeopleIdentityAC,
    } = this.actionCreators;

    return (
      <PeopleSearchAdd
        autocompleteLoading={autocompleteLoading}
        autocompleteOnChange={addPeopleAutocompleteChangeAC}
        autocompleteOnLeaving={addPeopleLeaveAutocompleteAC}
        autocompleteResults={autocompleteResults}
        autocompleteValue={autocompleteValue}
        groups={groups}
        identities={identities}
        onGroupSelect={addPeopleGroupAC}
        onPersonCheck={addPeopleOnChangeAC}
        onPersonSelect={addPeoplePersonAC}
        people={people}
        selectedIdentity={identity}
        selectedIndex={selectedIndex}
        setIdentity={setAddPeopleIdentityAC}
      />
    );
  }
}

PeopleSearchAddContainer.propTypes = {
  autocompleteLoading: PropTypes.bool.isRequired,
  autocompleteResults: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  identities: PropTypes.array.isRequired,
  peopleSearchAddState: PropTypes.object.isRequired,
  selectedIds: PropTypes.object,
};

const mapStateToProps = (state) => ({
  autocompleteLoading: state.autocompleteLoading,
  autocompleteResults: state.autocompleteResults,
  autocompleteValue: state.autocompleteValue,
  groups: getGroups(state),
  identities: state.identities,
  peopleSearchAddState: state.peopleSearchAddState,
});

PeopleSearchAddContainer.defaultProps = {
  selectedIds: {},
};

export default connect(mapStateToProps)(PeopleSearchAddContainer);
