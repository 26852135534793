import React from 'react';
import { func, number, string } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Label from 'components/label';
import Text from 'components/inputs/text';
import './marketoAdminSetupForm.scss';
import classNames from 'classnames';
import {
  MASKED_BULLET_POINTS,
  DEFAULT_REPEATED_AMOUNT,
} from 'web/marketoSettings/libs/marketoSettingsConstants';

const labelWidth = 90;

const MarketoAdminSetupForm = ({
  clientIdOnChange,
  clientSecretOnChange,
  munchkinIdOnChange,
  engageAdminUsersCount,
  munchkin,
}) => {
  const disableText = engageAdminUsersCount > 0;

  return (
    <div className="marketo-admin-setup-form-container">
      {disableText && (
        <div className="setup-exists">
          <p>
            <FormattedHTMLMessage id="web.marketoSettings.teamAlreadyConnected" />
          </p>
          <p>
            <FormattedHTMLMessage id="web.marketoSettings.clickToConnect" />
          </p>
        </div>
      )}
      <div className="marketo-admin-setup-label-container">
        <Label
          className="setup-label"
          id="marketo-settings-munchkin-id"
          messageKey="web.marketoSettings.munchkinId"
          sideLabel
          width={labelWidth}
        >
          <Text
            className={classNames('setup-label-text', {
              'customized-disabled-text': disableText,
            })}
            disabled={disableText}
            onChange={munchkinIdOnChange}
            placeholder={(disableText && munchkin) || undefined}
          />
        </Label>
        <Label
          className="setup-label"
          id="marketo-settings-client-id"
          messageKey="web.marketoSettings.clientId"
          sideLabel
          width={labelWidth}
        >
          <Text
            autoFocus={false}
            className={classNames('setup-label-text', {
              'customized-disabled-text': disableText,
            })}
            disabled={disableText}
            onChange={clientIdOnChange}
            placeholder={
              (disableText &&
                MASKED_BULLET_POINTS.repeat(DEFAULT_REPEATED_AMOUNT)) ||
              undefined
            }
          />
        </Label>
        <Label
          className="setup-label"
          id="marketo-settings-client-secret"
          messageKey="web.marketoSettings.clientSecret"
          sideLabel
          width={labelWidth}
        >
          <Text
            autoFocus={false}
            className={classNames('setup-label-text', {
              'customized-disabled-text': disableText,
            })}
            disabled={disableText}
            onChange={clientSecretOnChange}
            placeholder={
              (disableText &&
                MASKED_BULLET_POINTS.repeat(DEFAULT_REPEATED_AMOUNT)) ||
              undefined
            }
            type="password"
          />
        </Label>
      </div>
    </div>
  );
};

MarketoAdminSetupForm.propTypes = {
  clientIdOnChange: func.isRequired,
  clientSecretOnChange: func.isRequired,
  munchkinIdOnChange: func.isRequired,
  engageAdminUsersCount: number.isRequired,
  munchkin: string.isRequired,
};

export default MarketoAdminSetupForm;
