import React from 'react';
import { FormattedMessage } from 'react-intl';

const HighspotCard = () => (
  <div className="integrations-highspot">
    <h3>
      <FormattedMessage id="web.settings.accountSettings.integrations.highspot.title" />
    </h3>
    <div className="margin-top-20">
      <p>
        <FormattedMessage id="web.settings.accountSettings.integrations.highspot.description.adminEnabledForYou" />
      </p>
      <p>
        <FormattedMessage id="web.settings.accountSettings.integrations.highspot.description.navigateToCompose" />
      </p>
    </div>
  </div>
);

export default HighspotCard;
