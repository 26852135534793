import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SettingsUnsubscribes from 'web/settings/unsubscribes/components/settingsUnsubscribes/settingsUnsubscribes';
import { injectIntl, intlShape } from 'react-intl';
import {
  changeTab,
  changeShowUnsubscribe,
  updatePluginUnsubscribe,
} from '../actionCreators/adminUnsubscribesIntegrationsActionCreators';
import {
  AdminUnsubscribeTabs,
  ADMIN_UNSUBSCRIBE_TAB_WIDTH,
} from '../libs/adminUnsubscribesConstants';
import AdminUnsubscribesTabContent from '../components/adminUnsubscribesTabContent';
import UnsubscribeCustomDomain from 'web/settings/unsubscribes/components/settingsUnsubscribes/unsubscribeCustomDomain';
import {
  isUnsubscribeCustomDomainEnabled,
  isPluginUnsubscribeEnabled,
} from 'web/settings/adminSettings/general/selectors/generalPageSelectors';
import PluginUnsubscribeMessage from 'web/settings/unsubscribes/components/settingsUnsubscribes/pluginUnsubscribeMessage';

class AdminUnsubscribesContainer extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
    } = this.props;

    this.tabList = [
      {
        title: formatMessage({
          id: 'web.settings.unsubscribe.admin.tabs.messaging',
        }),
        value: AdminUnsubscribeTabs.messaging,
      },
      {
        title: formatMessage({
          id: 'web.settings.unsubscribe.admin.tabs.syncingToSalesforce',
        }),
        value: AdminUnsubscribeTabs.salesforce,
        width: 280,
      },
    ];
  }

  componentWillUnmount() {
    const { changeActiveTab } = this.props;

    changeActiveTab(AdminUnsubscribeTabs.messaging);
  }

  render() {
    const {
      activeTab,
      changeActiveTab,
      showUnsubscribePage,
      changeUnsubscribe,
      unsubscribeLoading,
      isUnsubscribeCustomDomainEnabled,
      showPluginUnsubscribe,
      changePublicUnsubscribeMessage,
      isPluginUnsubscribeEnabled,
      pluginMessageLoading,
    } = this.props;

    return (
      <SettingsUnsubscribes
        tabs={{
          itemWidth: ADMIN_UNSUBSCRIBE_TAB_WIDTH,
          list: this.tabList,
          onTabSelect: changeActiveTab,
          selectedTabValue: activeTab,
        }}
      >
        <AdminUnsubscribesTabContent tab={activeTab} />
        <div className="unsubscribe-window">
          {isUnsubscribeCustomDomainEnabled && (
            <UnsubscribeCustomDomain
              showUnsubscribePage={showUnsubscribePage}
              onChange={changeUnsubscribe}
              loading={unsubscribeLoading}
            />
          )}
          {isPluginUnsubscribeEnabled && (
            <PluginUnsubscribeMessage
              showPluginUnsubscribeMessage={showPluginUnsubscribe}
              onChange={changePublicUnsubscribeMessage}
              loading={pluginMessageLoading}
            />
          )}
        </div>
      </SettingsUnsubscribes>
    );
  }
}

AdminUnsubscribesContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  showUnsubscribePage: PropTypes.bool.isRequired,
  changeUnsubscribe: PropTypes.func.isRequired,
  unsubscribeLoading: PropTypes.bool.isRequired,
  isUnsubscribeCustomDomainEnabled: PropTypes.bool.isRequired,
  isPluginUnsubscribeEnabled: PropTypes.bool.isRequired,
  pluginMessageLoading: PropTypes.bool.isRequired,
  showPluginUnsubscribe: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  activeTab: state.adminUnsubscribesActiveTab,
  showUnsubscribePage: state.showUnsubscribePage,
  unsubscribeLoading: state.unsubscribeDomainLoading,
  isUnsubscribeCustomDomainEnabled: isUnsubscribeCustomDomainEnabled(state),
  isPluginUnsubscribeEnabled: isPluginUnsubscribeEnabled(state),
  pluginMessageLoading: state.pluginMessageLoading,
  showPluginUnsubscribe: state.pluginUnsubscribe,
});

const mapDispatchToProps = {
  changeActiveTab: changeTab,
  changeUnsubscribe: changeShowUnsubscribe,
  changePublicUnsubscribeMessage: updatePluginUnsubscribe,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AdminUnsubscribesContainer);
