import { ModalPopupIds } from 'web/libs/constants';
import { closeModal, openModal } from './dynamicFieldsModalPopupActionCreators';
import { getDynamicFields } from 'web/dynamicFields/actionCreators/dynamicFieldsActionCreators';

export const dynamicFieldsStartup = () => (dispatch) => {
  dispatch(closeModal(true)); // reset any previous states

  dispatch(openModal(ModalPopupIds.dynamicFields));
  dispatch(getDynamicFields());
};
