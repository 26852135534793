import PipelineActionTypes from '../libs/pipelineActionTypes';
import { findOrCreatePerson as findOrCreatePersonCall } from 'web/people/services/peopleService';
import { checkLeadInSalesforce as checkLeadInSalesforceCall } from 'web/liveFeed/services/liveFeedService';
import { openLearnMore as openLearnMoreCall } from 'web/liveFeed/pipeline/services/pipelineWindowService';

export const setContact = (contact) => ({
  type: PipelineActionTypes.setContact,
  contact,
});

export const cardToggleOpenChecks = (bestBet) => (dispatch) => {
  if (bestBet.salesforceId || bestBet.personId) {
    return;
  }
  const { id, email } = bestBet;
  dispatch({ type: PipelineActionTypes.addOpenChildrenId, id });

  const promises = [
    checkLeadInSalesforceCall(email),
    findOrCreatePersonCall(bestBet),
  ].map((promise) =>
    promise
      .then((result) => ({ success: true, result }))
      .catch((error) => ({ success: false, error }))
  );

  Promise.all(promises)
    .then((responses) => {
      const extraData = {
        bestBetId: id,
        addressId: '',
        personId: '',
        salesforceId: '',
      };

      const [checkLeadResponse, personResponse] = responses;

      // found lead in salesforce
      if (checkLeadResponse.success) {
        const { sf_object_id: salesforceId } = checkLeadResponse.result;

        extraData.salesforceId = salesforceId;
      }

      // found or created person
      if (personResponse.success) {
        const {
          person: {
            primary_address: { id: addressId, addressable_id: personId },
          },
        } = personResponse.result;

        extraData.addressId = addressId;
        extraData.personId = personId;
      }

      dispatch({ type: PipelineActionTypes.bestBetExtraData, extraData });
      dispatch({ type: PipelineActionTypes.removeOpenChildrenId, id });
    })
    .catch(() => {
      dispatch({ type: PipelineActionTypes.removeOpenChildrenId, id });
    });
};

export const setPipelineVisiblity = (visible) => ({
  type: PipelineActionTypes.visible,
  visible,
});

const initStore = () => ({
  type: PipelineActionTypes.initStore,
});

export const startupPipeline = () => (dispatch, getState) => {
  const { pipelineInitStore } = getState();

  if (!pipelineInitStore) {
    dispatch(initStore());
  }
};

export const openLearnMore = () => () => {
  openLearnMoreCall();
};

export const markEventAsSeen = (eventId) => {
  const bestBets = [{ events: [{ id: eventId }] }];

  return {
    type: PipelineActionTypes.markEventAsSeen,
    bestBets,
  };
};
