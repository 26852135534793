import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import last from 'lodash/last';
import { AttachmentTypes } from 'libs/constantsShared';
import './templateAttachment.scss';
import Icon from 'components/buttons/icon';

class TemplateAttachment extends Component {
  render() {
    const { attachments, removeAttachment } = this.props;

    return (
      <div className="templateAttachment">
        {attachments.map(
          (
            {
              id,
              file_file_name: fileName,
              document_url: documentUrl,
              file_file_size: fileSize,
            },
            index
          ) => (
            <div
              className={`text-overflow ${this._getTypeClasses(fileName)}`}
              key={`${id}-${index}`}
            >
              <span className="descriptor">
                {index + 1}
                <FormattedMessage id="templateAttachment.of" />
                {attachments.length}
                <FormattedMessage id="templateAttachment.separator" />
              </span>
              <a
                className="title text-overflow"
                href={documentUrl}
                rel="noreferrer noopener"
                target="_blank"
              >
                {fileName}
              </a>
              <span>{` ${this._getSize(fileSize)}`}</span>
              {removeAttachment && (
                <Icon
                  classes="tout-icon-close remove-button"
                  onClick={() => removeAttachment(id)}
                />
              )}
            </div>
          )
        )}
      </div>
    );
  }

  _getSize = (size) => {
    const { formatMessage } = this.props.intl;
    let unit = '';
    let sized = '';
    if (size < 1000) {
      unit = formatMessage({ id: 'common.sizeByte' });
      sized = size;
    } else if (size < 1000000) {
      unit =
        formatMessage({ id: 'common.sizeK' }) +
        formatMessage({ id: 'common.sizeByte' });
      sized = Math.round(size / 1000);
    } else if (size < 1000000000) {
      unit =
        formatMessage({ id: 'common.sizeM' }) +
        formatMessage({ id: 'common.sizeByte' });
      sized = Math.round(size / 1000000);
    } else if (size < 1000000000000) {
      unit =
        formatMessage({ id: 'common.sizeG' }) +
        formatMessage({ id: 'common.sizeByte' });
      sized = Math.round(size / 1000000000);
    }

    return !sized
      ? ''
      : `${formatMessage({
          id: 'common.separatorParLeft',
        })}${sized}${unit}${formatMessage({ id: 'common.separatorParRight' })}`;
  };

  /*eslint complexity: [1, 12]*/
  _getTypeClasses = (name) => {
    const type = last(name.split('.'));

    switch (type) {
      case AttachmentTypes.doc:
      case AttachmentTypes.docx:
        return 'tout-icon-doc';
      case AttachmentTypes.ppt:
      case AttachmentTypes.pptx:
        return 'tout-icon-ppt';
      case AttachmentTypes.jpg:
      case AttachmentTypes.png:
      case AttachmentTypes.gif:
        return 'tout-icon-img';
      case AttachmentTypes.pdf:
        return 'tout-icon-pdf';
      case AttachmentTypes.xls:
      case AttachmentTypes.xlsx:
        return 'tout-icon-excel';
      default:
        return 'tout-icon-other';
    }
  };
}

TemplateAttachment.propTypes = {
  attachments: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  removeAttachment: PropTypes.func,
};

TemplateAttachment.defaultProps = {
  removeAttachment: null,
};

export default injectIntl(TemplateAttachment);
