import { SortDirections, SortTypes } from 'libs/constantsShared';

export const sortingMap = {
  clicks: {
    primaryProperty: 'clickthrough_rate',
    primaryDefaultDirection: SortDirections.desc,
    primaryType: SortTypes.number,
    secondaryProperty: 'name',
    secondaryDefaultDirection: SortDirections.desc,
    secondaryType: SortTypes.text,
  },
  name: {
    primaryProperty: 'name',
    primaryDefaultDirection: SortDirections.asc,
    primaryType: SortTypes.text,
    secondaryProperty: 'num_sent',
    secondaryDefaultDirection: SortDirections.desc,
    secondaryType: SortTypes.number,
  },
  replies: {
    primaryProperty: 'reply_rate',
    primaryDefaultDirection: SortDirections.desc,
    primaryType: SortTypes.number,
    secondaryProperty: 'name',
    secondaryDefaultDirection: SortDirections.asc,
    secondaryType: SortTypes.text,
  },
  sent: {
    primaryProperty: 'num_sent',
    primaryDefaultDirection: SortDirections.desc,
    primaryType: SortTypes.number,
    secondaryProperty: 'name',
    secondaryDefaultDirection: SortDirections.asc,
    secondaryType: SortTypes.text,
  },
  views: {
    primaryProperty: 'open_rate',
    primaryDefaultDirection: SortDirections.desc,
    primaryType: SortTypes.number,
    secondaryProperty: 'name',
    secondaryDefaultDirection: SortDirections.asc,
    secondaryType: SortTypes.text,
  },
};

export function prettifyNumber(number) {
  if (!number) {
    return 0;
  }
  if (number < 1000) {
    return number;
  }
  if (number < 10000) {
    return `${Math.round(number / 100) / 10}K`;
  }
  return `${Math.round(number / 1000)}K`;
}
