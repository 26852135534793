import React from 'react';
import PropTypes from 'prop-types';
import Area from 'components/inputs/textarea';

const TextArea = ({ input, ...other }) => <Area {...input} {...other} />;

TextArea.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default TextArea;
