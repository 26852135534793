import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  INSTALLATION_GUIDE_CLASSIC_LINK,
  INSTALLATION_GUIDE_LIGHTNING_LINK,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';

const CustomizationGuideLinks = () => (
  <div className="margin-top-20">
    <FormattedMessage id="web.salesforceCustomization.connect.prelude.checkOut" />
    <a
      href={INSTALLATION_GUIDE_CLASSIC_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="web.salesforceCustomization.connect.prelude.guideClassicLink" />
    </a>
    <FormattedMessage id="common.orSpaced" />
    <a
      href={INSTALLATION_GUIDE_LIGHTNING_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="web.salesforceCustomization.connect.prelude.guideLightningLink" />
    </a>
    .
  </div>
);

export default CustomizationGuideLinks;
