import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon, {
  IconColors,
  IconBackgroundColors,
} from 'components/buttons/icon';
import Collapse from 'react-collapse'; //temp
import classNames from 'classnames';
import CampaignEditEmailTemplateContainer from 'web/campaigns/campaignsTab/containers/campaignEditEmailTemplate/campaignEditEmailTemplateContainer';
import CampaignEditReminder from '../../campaignEditReminder';
import CampaignStepIcon from '../../campaignStepIcon';
import { CampaignStepActions } from 'web/libs/constants';
import { injectIntl, intlShape } from 'react-intl';
import { canEditCampaign } from 'web/campaigns/campaignsTab/services/campaignService';
import './campaignDayCardNewStep.scss';

class CampaignDayCardNewStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAddStepType: CampaignStepActions.none,
      shouldShowStepTypes: false,
    };

    this.id = `new-step-${Date.now()}`;
    this.SMOOTH_SCROLL_SIZE = 10;
    this.CAMPAIGN_DAY_CARD_WIDTH = 590;
  }

  componentWillReceiveProps(nextProps) {
    // Assume successful task created by increased step count
    if (nextProps.steps.length > this.props.steps.length) {
      this.setState({
        selectedAddStepType: CampaignStepActions.none,
        shouldShowStepTypes: false,
      });
      this.props.setNewStep(false);
    }
  }

  toggleShowStepTypes = () => {
    this.setState((prevState) => ({
      selectedAddStepType: CampaignStepActions.none,
      shouldShowStepTypes: !prevState.shouldShowStepTypes,
    }));

    this.props.setNewStep(false);
  };

  _onIconClick = (e) => {
    const { setNewStep } = this.props;
    const { selectedAddStepType } = this.state;

    if (e.target.dataset.stepType === selectedAddStepType) {
      this.clearStepType();
    } else {
      this.setState({
        selectedAddStepType: e.target.dataset.stepType,
      });

      setNewStep(true);
      this._smoothScroll();
    }
  };

  clearStepType = () => {
    this.setState({
      selectedAddStepType: CampaignStepActions.none,
    });
    this.props.setNewStep(false);
  };

  onAddEmailStep = (componentId) => {
    const { newDayNumber, actionCreators } = this.props;
    const day = this.props.day || newDayNumber;
    actionCreators.addEmailStep(componentId, day);
  };

  onAddStep = (componentId) => {
    const { newDayNumber, actionCreators } = this.props;
    const day = this.props.day || newDayNumber;
    actionCreators.addStep(componentId, day, this.state.selectedAddStepType);
  };

  render() {
    const {
      attachments,
      actionCreators,
      campaignEditStates,
      campaignTemplates,
      ckeBodies,
      day,
      isFirstEmailStep,
      intl: { formatMessage },
      selectedCampaign,
      timezone,
      timezones,
      wide,
    } = this.props;
    const { selectedAddStepType, shouldShowStepTypes } = this.state;
    const canEdit = canEditCampaign(selectedCampaign);

    return (
      <div
        className={classNames('campaign-day-card-new-step', { wide })}
        ref={this.id}
      >
        <div>
          <Icon
            backgroundColor={IconBackgroundColors.blue}
            classes={classNames('add-step-btn', {
              'rotate-step-btn': shouldShowStepTypes,
            })}
            color={IconColors.white}
            disabled={!canEdit}
            onClick={this.toggleShowStepTypes}
          />
          <span
            className={classNames('add-step-msg', { disabled: !canEdit })}
            onClick={canEdit ? this.toggleShowStepTypes : null}
          >
            {formatMessage({ id: 'web.campaigns.addStep' })}
          </span>
        </div>
        <div>
          <Collapse isOpened={shouldShowStepTypes}>
            <div className="collapse-open-row">
              <CampaignStepIcon
                action={CampaignStepActions.email}
                active={selectedAddStepType === CampaignStepActions.email}
                className="margin-right-half"
                data-step-type={CampaignStepActions.email}
                onClick={this._onIconClick}
                outline
              />
              <CampaignStepIcon
                action={CampaignStepActions.call}
                active={selectedAddStepType === CampaignStepActions.call}
                className="margin-right-half"
                data-step-type={CampaignStepActions.call}
                onClick={this._onIconClick}
                outline
              />
              <CampaignStepIcon
                action={CampaignStepActions.inmail}
                active={selectedAddStepType === CampaignStepActions.inmail}
                className="margin-right-half"
                data-step-type={CampaignStepActions.inmail}
                onClick={this._onIconClick}
                outline
              />
              <CampaignStepIcon
                action={CampaignStepActions.custom}
                active={selectedAddStepType === CampaignStepActions.custom}
                data-step-type={CampaignStepActions.custom}
                onClick={this._onIconClick}
                outline
              />
            </div>
          </Collapse>
        </div>
        {selectedAddStepType === CampaignStepActions.email ? (
          <CampaignEditEmailTemplateContainer
            attachments={attachments}
            campaignEditStates={campaignEditStates}
            campaignTemplates={campaignTemplates}
            ckeBodies={ckeBodies}
            className="campaign-edit-padding"
            day={day}
            destroyState={actionCreators.destroyCampaignEditState}
            isFirstEmailStep={isFirstEmailStep}
            initState={actionCreators.initCampaignEditEmailState}
            onAction={this.onAddEmailStep}
            onCancel={this.toggleShowStepTypes}
            selectedCampaign={selectedCampaign}
            timezone={timezone}
            timezones={timezones}
            updateCampaignEditState={actionCreators.updateCampaignEditState}
            wide={wide}
          />
        ) : null}
        {selectedAddStepType === CampaignStepActions.call ||
        selectedAddStepType === CampaignStepActions.inmail ||
        selectedAddStepType === CampaignStepActions.custom ? (
          <CampaignEditReminder
            action={selectedAddStepType}
            campaignEditStates={campaignEditStates}
            className="campaign-edit-padding"
            createState={actionCreators.initCampaignEditReminderState}
            day={day}
            destroyState={actionCreators.destroyCampaignEditState}
            onAction={this.onAddStep}
            onCancel={this.toggleShowStepTypes}
            updateCampaignEditState={actionCreators.updateCampaignEditState}
          />
        ) : null}
      </div>
    );
  }

  // Same as CampaignDayStep scrolling
  _smoothScrollUp = (div, offset, initOffset) => {
    const offsetNormalized =
      offset > this.SMOOTH_SCROLL_SIZE ? this.SMOOTH_SCROLL_SIZE : offset;

    if (offsetNormalized > 0 && div.scrollTop < initOffset) {
      div.scrollTop += offsetNormalized; // eslint-disable-line no-param-reassign

      setTimeout(
        window.requestAnimationFrame(() =>
          this._smoothScrollUp(
            div,
            offset - this.SMOOTH_SCROLL_SIZE,
            initOffset
          )
        ),
        this.SMOOTH_SCROLL_SIZE / 2
      );
    }
  };

  _setLeft(scrollingDiv, stepDiv) {
    const offsetRight =
      stepDiv.offsetParent.offsetLeft + this.CAMPAIGN_DAY_CARD_WIDTH;

    if (offsetRight > scrollingDiv.offsetWidth) {
      const newLeft =
        offsetRight - scrollingDiv.offsetWidth + scrollingDiv.scrollLeft;

      window.requestAnimationFrame(() => {
        scrollingDiv.scrollLeft = newLeft; // eslint-disable-line no-param-reassign
      });
    }
  }

  _smoothScroll = () => {
    const scrollingDiv = document.getElementsByClassName(
      'campaign-tab-view'
    )[0];
    const stepDiv = this.refs[this.id]; // eslint-disable-line react/no-string-refs
    const offset =
      stepDiv.offsetTop +
      stepDiv.offsetParent.offsetTop -
      scrollingDiv.scrollTop;

    this._smoothScrollUp(scrollingDiv, offset, offset);

    if (!this.props.wide) {
      this._setLeft(scrollingDiv, stepDiv);
    }
  };
}

CampaignDayCardNewStep.propTypes = {
  campaignEditStates: PropTypes.object.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  attachments: PropTypes.object.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  setNewStep: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  wide: PropTypes.bool,
  day: PropTypes.number,
  isFirstEmailStep: PropTypes.bool,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CampaignDayCardNewStep.defaultProps = {
  isFirstEmailStep: undefined,
  newDayNumber: undefined,
  wide: undefined,
  day: undefined,
};

export default injectIntl(CampaignDayCardNewStep);
