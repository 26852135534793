import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import UserUnsubscribesEditor from '../containers/userUnsubscribesEditorContainer';

const UserUnsubscribesMessaging = () => (
  <>
    <h2>
      <FormattedMessage id="web.settings.unsubscribe.user.title" />
    </h2>
    <p>
      <FormattedHTMLMessage id="web.settings.unsubscribe.description" />
    </p>
    <UserUnsubscribesEditor />
  </>
);

export default UserUnsubscribesMessaging;
