import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PersonDetailsTabAlertIds } from 'web/person/libs/personDetailsConstants';
import Alert, { AlertColors } from 'components/alert';

class PersonDetailsTabAlert extends PureComponent {
  render() {
    const alert = this._getAlert();

    if (alert) {
      return <Alert className="person-details-tab-alert" {...alert} />;
    } else {
      return null;
    }
  }

  _getAlert = () => {
    //eslint-disable-line complexity
    const {
      actionCreators,
      alert: { id, textValues },
      adminData,
    } = this.props;
    const { redDark, yellow, greenDark, blueDark } = AlertColors;

    const defaultAlert = {
      color: redDark,
      onClose: actionCreators.clearTabAlert,
      textValues,
    };
    switch (id) {
      case PersonDetailsTabAlertIds.addToSalesforceFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.addToSalesforceFail',
        };
      case PersonDetailsTabAlertIds.addToSalesforceOptOutFieldError:
        return {
          ...defaultAlert,
          textId:
            'web.person.personDetails.alert.addToSalesforceOptOutFieldError',
        };
      case PersonDetailsTabAlertIds.addToSalesforceNoOwnership:
        return {
          ...defaultAlert,
          color: blueDark,
          textId: 'web.person.personDetails.alert.addToSalesforceNoOwnership',
        };
      case PersonDetailsTabAlertIds.addToSalesforceDuplicatedFieldError:
        return {
          ...defaultAlert,
          textId:
            'web.person.personDetails.alert.addToSalesforceDuplicatedFieldError',
        };
      case PersonDetailsTabAlertIds.getAccountInfoFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.getAccountInfoFail',
        };
      case PersonDetailsTabAlertIds.getEmailsFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.getEmailsFail',
        };
      case PersonDetailsTabAlertIds.getPersonById:
        return { ...defaultAlert, textId: 'web.common.alert.genericIssue' };
      case PersonDetailsTabAlertIds.deleteUserPrevent:
        return {
          ...defaultAlert,
          color: yellow,
          html: true,
          textId: 'web.person.personDetails.alert.deleteUserPrevent',
          textValues: { adminEmail: adminData.email },
        };
      case PersonDetailsTabAlertIds.deleteUserPreventAdmin:
        return {
          ...defaultAlert,
          color: yellow,
          textId: 'web.person.personDetails.alert.deleteUserPreventAdmin',
        };
      case PersonDetailsTabAlertIds.saveGroupsFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.saveGroupsFail',
        };
      case PersonDetailsTabAlertIds.savePersonFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.savePersonFail',
        };
      case PersonDetailsTabAlertIds.createPersonFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.createPersonFail',
        };
      case PersonDetailsTabAlertIds.saveNoteFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.saveNoteFail',
        };
      case PersonDetailsTabAlertIds.saveComplianceFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.saveComplianceFail',
        };
      case PersonDetailsTabAlertIds.missingRequiredFields:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.missingRequiredFields',
        };
      case PersonDetailsTabAlertIds.getMarketoEventsFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.getMarketoEventsFail',
        };
      case PersonDetailsTabAlertIds.missingRequiredSalesforceFields:
        return {
          ...defaultAlert,
          textId:
            'web.person.personDetails.alert.missingRequiredSalesforceFields',
        };
      case PersonDetailsTabAlertIds.invalidEmail:
        return {
          ...defaultAlert,
          color: yellow,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.invalidEmail',
        };
      case PersonDetailsTabAlertIds.duplicateContact:
        return {
          ...defaultAlert,
          color: yellow,
          textId: 'web.people.createDuplicateContactError',
        };
      case PersonDetailsTabAlertIds.invalidMarketoId:
        return {
          ...defaultAlert,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.invalidMarketoId',
        };
      case PersonDetailsTabAlertIds.invalidSalesforceId:
        return {
          ...defaultAlert,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.invalidSalesforceId',
        };
      case PersonDetailsTabAlertIds.specialCharsInSalesforce:
        return {
          ...defaultAlert,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.specialCharsInSalesforce',
        };
      case PersonDetailsTabAlertIds.invalidPhone:
        return {
          ...defaultAlert,
          color: yellow,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.invalidPhone',
        };
      case PersonDetailsTabAlertIds.uniquePhone:
        return {
          ...defaultAlert,
          color: yellow,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.uniquePhone',
        };
      case PersonDetailsTabAlertIds.invalidUsername:
        return {
          ...defaultAlert,
          color: yellow,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.invalidUsername',
        };
      case PersonDetailsTabAlertIds.invalidWebsite:
        return {
          ...defaultAlert,
          color: yellow,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.invalidWebsite',
        };
      case PersonDetailsTabAlertIds.removeFromCampaignFail:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.removeFromCampaignFail',
        };
      case PersonDetailsTabAlertIds.removeFromCampaignSuccess:
        return {
          ...defaultAlert,
          color: greenDark,
          textId: 'web.person.personDetails.alert.removeFromCampaignSuccess',
        };
      case PersonDetailsTabAlertIds.removeFromCampaignNoAccess:
        return {
          ...defaultAlert,
          textId: 'web.person.personDetails.alert.removeFromCampaignNoAccess',
        };
      case PersonDetailsTabAlertIds.invalidCustomFieldBadName:
        return {
          ...defaultAlert,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.invalidCustomFieldBadName',
        };
      case PersonDetailsTabAlertIds.invalidCustomFieldNoName:
        return {
          ...defaultAlert,
          eventType: 'blur',
          textId: 'web.person.personDetails.alert.invalidCustomFieldNoName',
        };
      case PersonDetailsTabAlertIds.invalidCustomFieldSameName:
        return {
          ...defaultAlert,
          color: yellow,
          eventType: 'blur',
          html: true,
          textId: 'web.person.personDetails.alert.invalidCustomFieldSameName',
        };
      case PersonDetailsTabAlertIds.followUpFail:
        return { ...defaultAlert, textId: 'web.emails.alert.followUpFail' };
      case PersonDetailsTabAlertIds.removeUnsubscribeWarning:
        return {
          ...defaultAlert,
          color: yellow,
          html: true,
          textId: 'web.person.personDetails.removeUnsubscribe.noAdminWarning',
          textValues: { adminEmail: adminData.email },
        };
      case PersonDetailsTabAlertIds.getTemplateCategoriesFail:
        return {
          ...defaultAlert,
          textId: 'web.emails.alert.getTemplateCategoriesFail',
        };
      case PersonDetailsTabAlertIds.archivingEmail:
        return { ...defaultAlert, textId: 'web.emails.alert.archivingEmail' };
      case PersonDetailsTabAlertIds.unarchivingEmail:
        return { ...defaultAlert, textId: 'web.emails.alert.unarchivingEmail' };
      case PersonDetailsTabAlertIds.noAccessToCampaign:
        return {
          ...defaultAlert,
          color: blueDark,
          textId: 'web.person.personDetails.alert.noAccessToCampaign',
        };
      default:
        return null;
    }
  };
}

PersonDetailsTabAlert.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  adminData: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
};

export default PersonDetailsTabAlert;
