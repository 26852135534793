import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header';
import PageTabNavBar from 'components/layouts/page/tabNavBar';
import PageTabNav from 'components/layouts/page/tabNav';
import PageView from 'components/layouts/page/view';
import { BackgroundColors } from 'libs/constantsStyles';
import { UserManagementTabs } from '../../libs/userManagementConstants';
import UsersContainer from '../../containers/usersContainer';
import InviteesContainer from '../../containers/inviteesContainer';
import './userManagementPage.scss';
import DropdownButtonMenu from 'web/people/components/dropdownButtonMenu/dropdownButtonMenu';
import Button, { ButtonSizes } from 'components/buttons/action';
import { FormattedMessage } from 'react-intl';
import PeoplePopupContainer from 'web/people/containers/peoplePopupContainer';
class UserManagementPage extends Component {
  constructor(props) {
    super(props);

    this.tabList = [
      {
        title: props.intl.formatMessage({
          id: 'web.settings.adminSettings.userManagement.tabs.users',
        }),
        value: UserManagementTabs.users,
      },
      {
        title: props.intl.formatMessage({
          id: 'web.settings.adminSettings.userManagement.tabs.invitees',
        }),
        value: UserManagementTabs.invitees,
      },
    ];
  }

  render() {
    const { onTabSelect, activeTab } = this.props;

    return [
      <PeoplePopupContainer key="people-popup-container" />,
      <Page className="user-management-page">
        <PageHeader
          rightChildren={this.getHeaderButtons()}
          textId="web.settings.adminSettings.userManagement.title"
        />
        <PageTabNavBar border>
          <PageTabNav
            list={this.tabList}
            onTabSelect={onTabSelect}
            selectedTabValue={activeTab}
          />
        </PageTabNavBar>
        <PageView backgroundColor={BackgroundColors.grayExtraLight}>
          {this.getTabPageView()}
        </PageView>
      </Page>,
    ];
  }

  getHeaderButtons = () => {
    const {
      actionCreators: { openMoreSeatsPopup },
    } = this.props;
    return (
      <div>
        <DropdownButtonMenu
          items={this.getInviteUserButtons()}
          size={ButtonSizes.medium}
          titleId="common.actions"
        />
        <Button
          className="need-more-seats"
          onClick={openMoreSeatsPopup}
          size={ButtonSizes.medium}
        >
          <FormattedMessage id="web.settings.adminSettings.userManagement.invitees.needMoreSeats" />
        </Button>
      </div>
    );
  };

  getInviteUserButtons = () => {
    const {
      actionCreators: { openImportCsv, openInviteUsersPopup },
    } = this.props;

    const inviteUserButtons = [];
    let i = 0;

    inviteUserButtons.push({
      id: (i += 1),
      onLinkCallback: openInviteUsersPopup,
      titleId: 'web.settings.adminSettings.userManagement.invitees.inviteUsers',
    });
    inviteUserButtons.push({
      id: (i += 1),
      onLinkCallback: openImportCsv,
      titleId:
        'web.settings.adminSettings.userManagement.invitees.inviteUsers.importCSV',
    });

    return inviteUserButtons;
  };

  getTabPageView = () => {
    const { activeTab } = this.props;

    switch (activeTab) {
      case UserManagementTabs.users:
        return <UsersContainer />;
      case UserManagementTabs.invitees:
        return <InviteesContainer />;
      default:
        return null;
    }
  };
}

UserManagementPage.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(UserManagementPage);
