import merge from 'lodash/merge';
import * as domainBlocksReducer from './domainBlocksReducer';
import * as autoAppendReducer from './autoAppendReducers';
import * as deleteRecordingNotice from './salesRecordingReducers';
import * as generalReducers from './generalReducers';

export default merge(
  {},
  domainBlocksReducer,
  autoAppendReducer,
  deleteRecordingNotice,
  generalReducers
);
