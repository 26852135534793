import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import {
  IntegrationsPopupIds,
  IntegrationsProviders,
} from '../../libs/integrationConstants';
import DeleteConfirmationPopup from './deleteConfirmationPopup';
import NavigateConfirmationPopup from './navigateConfirmationPopup';

const Popups = (props) => {
  const { popup, intl: { formatMessage } } = props;

  switch (popup) {
    case IntegrationsPopupIds.deleteSlack:
      return (
        <DeleteConfirmationPopup
          {...props}
          headerTextId="web.settings.accountSettings.integrations.slack.title"
          provider={formatMessage({ id: 'web.settings.accountSettings.integrations.providers.slack' })}
        />
      );
    case IntegrationsPopupIds.deleteBox:
      return (
        <DeleteConfirmationPopup
          {...props}
          headerTextId="web.settings.accountSettings.integrations.box.title"
          provider={formatMessage({ id: 'web.settings.accountSettings.integrations.providers.box' })}
        />
      );
    case IntegrationsPopupIds.deleteBcc:
      return (
        <DeleteConfirmationPopup
          {...props}
          headerTextId="web.settings.accountSettings.integrations.bcc.title"
          provider={formatMessage({ id: 'web.settings.accountSettings.integrations.providers.bcc' })}
        />
      );
    case IntegrationsPopupIds.navigateToSlack:
      return (
        <NavigateConfirmationPopup
          {...props}
          descriptionTextId="web.settings.accountSettings.integrations.slack.navigate.willBeNavigated"
          provider={formatMessage({ id: 'web.settings.accountSettings.integrations.providers.slack' })}
        />
      );
    case IntegrationsPopupIds.navigateToBox:
      return (
        <NavigateConfirmationPopup
          {...props}
          descriptionTextId="web.settings.accountSettings.integrations.box.navigate.willBeNavigated"
          provider={formatMessage({ id: 'web.settings.accountSettings.integrations.providers.box' })}
        />
      );
    default:
      return null;
  }
};

Popups.propTypes = {
  intl: intlShape.isRequired,
  popup: PropTypes.any,
  popupData: PropTypes.any,
};

Popups.defaultProps = {
  popup: null,
  popupData: {},
};

export default injectIntl(Popups);
