import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Popup, { PopupButtonColors } from 'components/popups';

class GmailIntegrationDisconnectConfirmationPopup extends Component {
  render() {
    const { disconnectGoogle, closePopup } = this.props.actionCreators;

    return (
      <Popup
        footer={{
          onPrimary: disconnectGoogle,
          onSecondary: closePopup,
          primaryTextId: 'common.disconnect',
          secondaryTextId: 'common.cancel',
          primaryColor: PopupButtonColors.red,
        }}
        header={{
          onClose: closePopup,
          textId: 'web.emailIntegration.gmail.disconnectConfirmation.title',
        }}
        onOutsideClick={closePopup}
      >
        <FormattedMessage id="web.emailIntegration.gmail.disconnectConfirmation.text" />
      </Popup>
    );
  }
}

GmailIntegrationDisconnectConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

export default injectIntl(GmailIntegrationDisconnectConfirmationPopup);
