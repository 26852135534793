import map from 'lodash/map';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { FormattedMessage } from 'react-intl';
import { EDIT_TEAMS_FORM_ID } from '../../../libs/teamMembersConstants';
import EditTeamsForm from './editTeamsForm';

class EditTeamsPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      editTeamsLoading,
      isPristineEditTeamsForm,
      popupTeamMemberTeamIds,
      defaultAlert,
      teamsSelectOptionsWithoutSelected,
    } = this.props;

    const editableTeamIds = map(teamsSelectOptionsWithoutSelected, 'value');
    const teamIds = intersection(popupTeamMemberTeamIds, editableTeamIds);

    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.submitForm,
          onSecondary: closePopup,
          primaryTextId: 'common.save',
          secondaryTextId: 'common.cancel',
          primaryDisabled: isPristineEditTeamsForm,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.teamManagement.teamMembers.editTeams',
        }}
        getImageUrl={getImageUrl}
        loading={editTeamsLoading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedMessage id="web.settings.adminSettings.teamManagement.teamMembers.editTeams.title" />
        <EditTeamsForm
          form={EDIT_TEAMS_FORM_ID}
          {...this.props}
          onEditTeamsFormSubmit={this.onEditTeamsFormSubmit}
          initialValues={{ teams: teamIds }}
        />
      </Popup>
    );
  }

  onEditTeamsFormSubmit = ({ teams: selectedTeams }) => {
    const {
      actionCreators: { editTeams },
      popupTeamMemberTeamIds,
      teamsSelectOptionsWithoutSelected,
      popupData: { teamMember },
    } = this.props;

    const editableTeamIds = map(teamsSelectOptionsWithoutSelected, 'value');
    const notEditableTeamIds = difference(
      popupTeamMemberTeamIds,
      editableTeamIds
    );

    notEditableTeamIds.push(...selectedTeams);

    editTeams(teamMember, notEditableTeamIds);
  };

  submitForm = () => {
    const {
      actionCreators: { submitForm },
    } = this.props;
    submitForm(EDIT_TEAMS_FORM_ID);
  };
}

EditTeamsPopup.propTypes = {
  popupData: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  editTeamsLoading: PropTypes.bool.isRequired,
  isPristineEditTeamsForm: PropTypes.bool.isRequired,
  popupTeamMemberTeamIds: PropTypes.array.isRequired,
  shouldShowAlert: PropTypes.bool.isRequired,
  defaultAlert: PropTypes.object,
  teamsSelectOptionsWithoutSelected: PropTypes.array.isRequired,
};

EditTeamsPopup.defaultProps = {
  defaultAlert: null,
};

export default EditTeamsPopup;
