export const ColorClasses = {
  black: 'black',
  blue: 'blue',
  blueDark: 'blue-dark',
  blueGrayLight: 'blue-gray-light',
  blueLight: 'blue-light',
  blurple: 'blurple', // gradient of purple to blue
  darkGrayLight: 'dark-gray-light',
  default: '',
  gray: 'gray',
  grayDark: 'gray-dark',
  grayExtraLight: 'gray-extra-light',
  grayLight: 'gray-light',
  green: 'green',
  greenDark: 'green-dark',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  red: 'red',
  redDark: 'red-dark',
  tourquoise: 'tourquoise', //todo refactor to teal b/c i don't know how to spell turquoise apparently
  white: 'white',
  yellow: 'yellow',
};

// We need these for Victory charts because colors are set in JS. Wherever
// possible, use _colors.scss. Do not change these except to keep them in sync.
export const ColorHexCodes = {
  toutBlue: '#1473E6',
  toutDarkGrayLight: '#747474',
  toutGrayExtraLight: '#F2F4F7',
  toutGrayLight: '#D3D3D3',
  toutPinkLight: '#EA72AC',
  toutPurple: '#715EBD',
};

/* These colors are legacy when they were not synced */
export const BackgroundColors = { ...ColorClasses };

export const StrokeColors = { ...ColorClasses };

export const TextColors = { ...ColorClasses };

export const TextWeights = {
  bold: 'bold',
  defaut: '',
  light: 'light',
  medium: 'medium',
};

export const Icons = {
  circleCheck: 'circle-check',
  gong: 'gong',
  lightBulb: 'light-bulb',
  lightningBolt: 'lightning-bolt',
  trophyBox: 'trophy-box',
};

export const FULL = 20;
export const MOST = 15;
export const HALF = 10;
export const QUARTER = 5;
