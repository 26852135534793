import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import './fieldWithTooltip.scss';

const fieldWithTooltip = (WrappedComponent) => {
  class FieldWithTooltipHOC extends Component {
    input = null;

    componentDidMount() {
      this.initReactTooltip();
    }

    componentDidUpdate() {
      const { showTooltip } = this.props;

      showTooltip
        ? ReactTooltip.show(findDOMNode(this.input))
        : ReactTooltip.hide(findDOMNode(this.input));
    }

    setRef = (input) => {
      this.input = input;

      const { setRef } = this.props;
      if (setRef) {
        setRef(input);
      }
    };

    initReactTooltip() {
      const { id } = this.props;

      this.input.dataset.tip = '';
      this.input.dataset.for = `field-tooltip-${id}`;
    }

    render() {
      const { id } = this.props;
      const {
        getTooltipContent,
        place,
        effect,
        showTooltip,
        ...componentProps
      } = this.props;

      const touchedProps = {
        setRef: this.setRef,
      };

      return (
        <React.Fragment>
          <WrappedComponent {...componentProps} {...touchedProps} />
          <ReactTooltip
            className="tooltip-field"
            place={place}
            effect={effect}
            type="light"
            id={`field-tooltip-${id}`}
            getContent={getTooltipContent}
          />
        </React.Fragment>
      );
    }
  }

  FieldWithTooltipHOC.propTypes = {
    id: PropTypes.string.isRequired,
    showTooltip: PropTypes.bool,
    setRef: PropTypes.func,
    getTooltipContent: PropTypes.func.isRequired,
    place: PropTypes.string,
    effect: PropTypes.string,
  };

  FieldWithTooltipHOC.defaultProps = {
    showTooltip: false,
    setRef: null,
    place: 'left',
    effect: 'solid',
  };

  return FieldWithTooltipHOC;
};

export default fieldWithTooltip;
