import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './formLayout.scss';

const FormLayout = ({ children, className }) => (
  <div className={classNames('form-layout', className)}>{children}</div>
);

FormLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

FormLayout.defaultProps = {
  className: '',
  children: null,
};

export default FormLayout;
