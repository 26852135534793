import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import './collapse.scss';

const { ENTERED, ENTERING, EXITED, EXITING } = Transition;

export const Timeouts = {
  fiveHundred: 'five-hundred',
  threeHundred: 'three-hundred',
  twoFifty: 'two-fifty',
};

const TimeoutsValues = {
  [Timeouts.twoFifty]: 250,
  [Timeouts.threeHundred]: 300,
  [Timeouts.fiveHundred]: 500,
};

const collapseStyles = {
  [ENTERED]: 'collapse in',
  [ENTERING]: 'collapsing',
  [EXITED]: 'collapse',
  [EXITING]: 'collapsing',
};

const emptyFunc = () => {};

class Collapse extends Component {
  render() {
    const {
      appear,
      className,
      children,
      disableAnimation,
      onExited,
      timeoutEnter,
      timeoutExit,
      ...props
    } = this.props;

    return (
      <Transition
        {...props}
        appear={appear}
        enter={!disableAnimation}
        exit={!disableAnimation}
        onEnter={this.handleEnter}
        onEntered={this.handleEntered}
        onEntering={this.handleEntering}
        onExit={this.handleExit}
        onExited={onExited}
        onExiting={this.handleExiting}
        timeout={{
          enter: TimeoutsValues[timeoutEnter],
          exit: TimeoutsValues[timeoutExit],
        }}
        mountOnEnter
        unmountOnExit
      >
        {(state, innerProps) => {
          const timeoutClass = (timeoutEnter === timeoutExit &&
            timeoutEnter) || {
            [timeoutEnter]: state === ENTERING,
            [timeoutExit]: state === EXITING,
          };
          return React.cloneElement(children, {
            ...innerProps,
            className: classNames(
              'tout-collapse',
              className,
              children.props.className,
              collapseStyles[state],
              timeoutClass
            ),
          });
        }}
      </Transition>
    );
  }

  /* -- Expanding -- */
  handleEnter = (elem, isAppearing) => {
    const { onEnter } = this.props;
    elem.style.height = '0'; //eslint-disable-line no-param-reassign
    onEnter(elem, isAppearing);
  };

  handleEntering = (elem, isAppearing) => {
    const { onEntering } = this.props;
    elem.style.height = '100%'; //eslint-disable-line no-param-reassign
    onEntering(elem, isAppearing);
  };

  handleEntered = (elem, isAppearing) => {
    const { onEntered } = this.props;
    onEntered(elem, isAppearing);
  };

  /* -- Collapsing -- */
  handleExit = (elem) => {
    const { onExit } = this.props;
    elem.style.height = `${elem.offsetHeight}px`; //eslint-disable-line no-param-reassign
    this._removeStyle(elem, 'margin-top');
    this._removeStyle(elem, 'margin-bottom');
    this._triggerBrowserReflow(elem);
    onExit(elem);
  };

  handleExiting = (elem) => {
    const { onExiting } = this.props;
    elem.style.height = '0'; //eslint-disable-line no-param-reassign
    onExiting(elem);
  };

  _removeStyle(node, key) {
    return 'removeProperty' in node.style
      ? node.style.removeProperty(key)
      : node.style.removeAttribute(key);
  }

  // reading a dimension prop will cause the browser to recalculate which will let animations work
  _triggerBrowserReflow(node) {
    node.offsetHeight; // eslint-disable-line no-unused-expressions
  }
}

Collapse.propTypes = {
  appear: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disableAnimation: PropTypes.bool,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onEntered: PropTypes.func,
  onEntering: PropTypes.func,
  onExit: PropTypes.func,
  onExited: PropTypes.func,
  onExiting: PropTypes.func,
  timeoutEnter: PropTypes.oneOf(Object.values(Timeouts)),
  timeoutExit: PropTypes.oneOf(Object.values(Timeouts)),
};

Collapse.defaultProps = {
  appear: false,
  className: '',
  disableAnimation: false,
  in: false,
  onEnter: emptyFunc,
  onEntered: emptyFunc,
  onEntering: emptyFunc,
  onExit: emptyFunc,
  onExited: emptyFunc,
  onExiting: emptyFunc,
  timeoutEnter: Timeouts.twoFifty,
  timeoutExit: Timeouts.twoFifty,
};

export default Collapse;
