import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CommandCenterEmailsHeader from 'web/commandCenter/components/commandCenterEmailsHeader';
import CommandCenterPopupContainer from 'web/commandCenter/containers/commandCenterPopupContainer';
import PersonDetailsSlideOut from 'web/slideOuts/personDetails';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import CommandCenterEmailsTable from 'web/commandCenter/components/commandCenterEmailsTable';
import {
  onEmailsTabClick,
  bulkArchive,
  bulkMarkSuccess,
  bulkRetrySendEmail,
  onEmailsSubTabClick,
  markSuccess,
  archiveEmail,
  unarchiveEmail,
  resetSidebarViewAsFilter,
  retrySend,
  openAdvancedSearchFormPopup,
} from 'web/commandCenter/actionCreators/commandCenterEmailsActionCreators';
import {
  emailSelected,
  removeSelectedFromCampaign,
  addSelectedToCampaign,
  openPersonGroupsModal,
} from 'web/commandCenter/actionCreators/commandCenterTableActionCreators';
import {
  sortEmails,
  refreshEmails,
  getEmails as getEmailsAC,
  paginateEmailsPerPage,
  replyToEmail,
  emailGroupSearch,
  getUnreadEmailsCount,
} from 'web/commandCenter/actionCreators/commandCenterEmailsFetchActionCreators';
import {
  emailResetSearch,
  emailSearch,
} from 'web/commandCenter/actionCreators/commandCenterEmailsSearchActionCreators';
import { openCampaign } from 'web/tables/tasks/actionCreators/tablesTasksActionActionCreators';
import {
  openAddPeopleToGroup,
  openUnsubscribePeoplePopup,
} from 'web/people/actionCreators/peoplePopupActionCreators';
import { openPersonDetails } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import {
  getEmails,
  getActiveEmailTableId,
  getCommandCenterEmailsTab,
  getCommandCenterEmailsSubTab,
} from 'web/commandCenter/selectors/commandCenterSelectors';
import { getUserColumnSettingsForTable } from 'web/user/selectors/userSelectors';

import {
  getAdvancedSearchString,
  getSelectedGroupEmailName,
  getSelectedSavedSearchName,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { FILTER_SEARCH_ID } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { getIsScheduleStatus } from 'web/commandCenter/modules/statusSmartFilter/selectors/statusSmartFilterSelectors';
import { openAddTask } from 'web/commandCenter/actionCreators/commandCenterActionCreators';
import { navigateToLinkedIn } from 'web/services/routerService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import './commandCenterEmailsContainer.scss';
import { getEmailLimits } from 'web/campaigns/campaignsTab/services/campaignService';
import { changeColumnsSettings } from 'web/user/actionCreators/userActionCreators';
import { getColumnsSettingsTableId } from '../helpers/emailHelpers';

const CommandCenterEmailsContainer = (props) => {
  const [totalLimit, setTotalLimit] = useState(null);
  const [currentLimit, setCurrentLimit] = useState(null);

  const {
    actionCreators: { getUnreadEmailsCount },
    columnSettings,
    commandCenterEmailsSubTab,
    commandCenterEmailsTab,
    userMetric,
    actionCreators,
    emails,
    enabledSingleRowGroups,
    page,
    perPage,
    totalCount,
    emailSearchString,
    loading,
    tableId,
    selectedSaveSearchName,
    selectedGroupEmailName,
    isScheduleStatus,
    unreadEmailsCounters,
  } = props;

  useEffect(() => {
    const GET_UNREAD_EMAILS_COUNTER_FREQUENCY = 5000;
    const getEmailsCounterTimerId = setInterval(() => {
      getUnreadEmailsCount();
      getEmailLimits([userMetric.id]).then(({ total_limit, current_limit }) => {
        setTotalLimit(total_limit);
        setCurrentLimit(total_limit - current_limit);
      });
    }, GET_UNREAD_EMAILS_COUNTER_FREQUENCY);
    return () => {
      clearInterval(getEmailsCounterTimerId);
    };
  }, []);

  return (
    <React.Fragment>
      <CommandCenterPopupContainer key="command-center-popup-container" />
      <PersonDetailsSlideOut key="command-center-person-details-slide-out" />
      <div
        className={classNames(
          'command-center command-center-emails with-top-nav',
          {
            'walkthrough-banner': userMetric.nux,
          }
        )}
        key="command-center-container"
      >
        <CommandCenterEmailsHeader
          actionCreators={actionCreators}
          currentLimit={currentLimit}
          selectedGroupEmailName={selectedGroupEmailName}
          selectedSaveSearchName={selectedSaveSearchName}
          selectedSubTabValue={commandCenterEmailsSubTab}
          selectedTabValue={commandCenterEmailsTab}
          tableId={tableId}
          totalLimit={totalLimit}
          unreadEmailsCounters={unreadEmailsCounters}
        />
        <CommandCenterEmailsTable
          actionCreators={actionCreators}
          columnSettings={columnSettings}
          commandCenterEmailsSubTab={commandCenterEmailsSubTab}
          commandCenterEmailsTab={commandCenterEmailsTab}
          emailSearchString={emailSearchString}
          emails={emails}
          enabledSingleRowGroups={enabledSingleRowGroups}
          isScheduleStatus={isScheduleStatus}
          loading={loading}
          page={page}
          perPage={perPage}
          tableId={tableId}
          totalCount={totalCount}
        />
      </div>
    </React.Fragment>
  );
};

CommandCenterEmailsContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  commandCenterEmailsSubTab: PropTypes.string.isRequired,
  commandCenterEmailsTab: PropTypes.string.isRequired,
  emailSearchString: PropTypes.string.isRequired,
  emails: PropTypes.array.isRequired,
  enabledSingleRowGroups: PropTypes.objectOf(PropTypes.bool).isRequired,
  isScheduleStatus: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  selectedGroupEmailName: PropTypes.string.isRequired,
  selectedSaveSearchName: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  unreadEmailsCounters: PropTypes.object.isRequired,
  userMetric: PropTypes.object.isRequired,
};

CommandCenterEmailsContainer.defaultProps = { columnSettings: [] };

const mapStateToProps = (state) => ({
  columnSettings: getUserColumnSettingsForTable(
    state,
    getColumnsSettingsTableId(getActiveEmailTableId(state))
  ),
  commandCenterEmailsSubTab: getCommandCenterEmailsSubTab(state),
  commandCenterEmailsTab: getCommandCenterEmailsTab(state),
  emailSearchString: getAdvancedSearchString(state, {
    collectionId: getActiveEmailTableId(state),
    filterId: FILTER_SEARCH_ID,
  }),
  emails: getEmails(state),
  enabledSingleRowGroups: state.emailsSortByGroups,
  isScheduleStatus: getIsScheduleStatus(state),
  loading: state.emailLoading,
  page: state.emailPage,
  perPage: state.emailsPerPage,
  selectedGroupEmailName: getSelectedGroupEmailName(state),
  selectedSaveSearchName: getSelectedSavedSearchName(state),
  tableId: getActiveEmailTableId(state),
  totalCount: state.emailTotal,
  unreadEmailsCounters: state.unreadEmailsCounters,
  userMetric: state.userMetric,
});

const mapDispatchToProps = {
  addSelectedToCampaign,
  archiveEmail,
  bulkArchive,
  bulkMarkSuccess,
  bulkRetrySendEmail,
  changeColumnsSettings,
  emailGroupSearch,
  emailResetSearch,
  emailSearch,
  emailSelected,
  getEmailsAC,
  getUnreadEmailsCount,
  markSuccess,
  onEmailsSubTabClick,
  onEmailsTabClick,
  openAddPeopleToGroup,
  openAdvancedSearchFormPopup,
  openCampaign,
  openPersonDetails,
  openPersonGroupsModal,
  openPopup,
  openUnsubscribePeoplePopup,
  openViewAlert,
  paginateEmailsPerPage,
  refreshEmails,
  removeSelectedFromCampaign,
  replyToEmail,
  resetSidebarViewAsFilter,
  retrySend,
  sortEmails,
  unarchiveEmail,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: {
    ...dispatchProps,
    navigateToLinkedIn,
    openAddTask,
    openCallModal: (data) => toutBackboneHelper.openCallModal(data),
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  withCommonModals
)(CommandCenterEmailsContainer);
