import { baseGet, basePut, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getCategoriesCampaigns() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.campaignsCategories, {}, resolve, reject);
  });
}

export const getCategoriesCampaignsPermissioned = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.permissionsCampaignsCategories, {}, resolve, reject);
  });

export function updateCategoryCampaign(id, category) {
  return new Promise((resolve, reject) => {
    basePut(`${Urls.campaignsCategories}/${id}`, { category }, resolve, reject);
  });
}

export function deleteCategoryCampaign(id) {
  return new Promise((resolve, reject) => {
    baseDelete(`${Urls.campaignsCategories}/${id}`, {}, resolve, reject);
  });
}
