import ActionTypes from 'table/modules/actionTypes';
import { keys } from 'table/modules/constants/localStorageKeys';
import { updateLocalStorage } from 'table/modules/localStorage/localStorageActionCreators';
import trackerEvents from 'table/modules/tracker/trackerEvents';
import track from 'table/modules/tracker';

export function initColumnFilter(columnFilter, columns = []) {
  return (dispatch, getState) => {
    if (columnFilter) {
      const { tableLocalStorage } = getState();
      dispatch({
        columns,
        stored: tableLocalStorage[keys.columnFilterSettings] || {},
        type: ActionTypes.columnFilterSetColumn,
      });
    }
  };
}

export function onColumnFilterChange(checked, id) {
  return (dispatch) => {
    dispatch({
      checked,
      id,
      type: ActionTypes.columnFilterChangeColumn,
    });

    dispatch(updateLocalStorage(keys.columnFilterSettings, { [id]: checked }));

    track(trackerEvents.columnFilterChange, { checked, id }); // temp solution until ColumnFilter's onChange passes id
  };
}

export const selectOrDeselectAllColumns = (columns = [], checked = false) => (
  dispatch
) => {
  const ids = columns.map(({ id }) => id);
  const checkedIds = ids.reduce((acc, id) => ({ ...acc, [id]: checked }), {});

  dispatch({
    checkedIds,
    type: ActionTypes.columnFilterChangeColumns,
  });
  dispatch(updateLocalStorage(keys.columnFilterSettings, checkedIds));

  track(
    checked
      ? trackerEvents.columnFilterChangeAllChecked
      : trackerEvents.columnFilterChangeAllUnchecked
  );
};
