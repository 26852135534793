import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Popup from 'components/popups/popup';
import EmailConnectionSelectionBody from 'web/settings/emailConnection/components/emailConnectionSelectionBody';
import OutlookSelectionBody from 'web/settings/emailConnection/components/outlookSelectionBody';
import EmailConnectionFlowAlert from 'web/settings/emailConnection/components/emailConnectionFlowAlert';
import ExchangeOnpremSelected from 'web/settings/emailConnection/components/exchangeOnpremSelected';
import EmailRedirect from 'web/settings/emailConnection/components/emailRedirect/emailRedirect';
import {
  closePopup,
  setEmailProvider,
  flowControl,
} from 'web/settings/emailConnection/actionCreators/emailConnectionActionCreators';
import {
  connectToExchange,
  updateExchangeOnpremFormState,
} from 'web/settings/emailConnection/actionCreators/exchangeOnpremActionCreators';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  EMAIL_CONNECTION_POPUP_ID,
  EmailConnectionModalNavigation,
  EmailProviders,
  EmailProvidersText,
  ModalComponentIds,
} from 'web/settings/emailConnection/lib/emailConnectionConstants';
import { connectEmail } from 'web/settings/emailConnection/helpers/emailConnectionHelpers';
import { isOwaPluginMode } from 'web/services/windowService';

class EmailConnectionFlowContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          closePopup,
          closeViewAlert,
          connectToExchange,
          setEmailProvider,
          updateExchangeOnpremFormState,
        },
        dispatch
      ),
      nextControl: () =>
        dispatch(flowControl(EmailConnectionModalNavigation.next)),
      prevControl: () =>
        dispatch(flowControl(EmailConnectionModalNavigation.prev)),
    };
  }

  render() {
    const { popup } = this.props;
    const { children, ...other } = this._getMainComponent();

    return (
      <div className="email-connection-flow">
        {popup === EMAIL_CONNECTION_POPUP_ID && (
          <Popup {...other}>
            <EmailConnectionFlowAlert
              actionCreators={this.actionCreators}
              {...this.props}
            />
            {children}
          </Popup>
        )}
      </div>
    );
  }

  _getMainComponent = () => {
    const {
      emailConnectionModal,
      emailConnectionProvider,
      loading,
    } = this.props;

    switch (emailConnectionModal) {
      case ModalComponentIds.selection:
        return {
          children: (
            <EmailConnectionSelectionBody
              emailConnectionProvider={emailConnectionProvider}
              setEmailProvider={this.actionCreators.setEmailProvider}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.nextControl,
            onSecondary: this.actionCreators.prevControl,
            primaryTextId: 'common.next',
            secondaryTextId: 'common.cancel',
          },
          header: {
            className: 'email-connection-flow-header',
            onClose: this.actionCreators.closePopup,
            textId: 'web.settings.emailConnection.modal.getStarted',
          },
        };
      case ModalComponentIds.outlookSelection:
        return {
          children: (
            <OutlookSelectionBody
              emailConnectionProvider={emailConnectionProvider}
              setEmailProvider={this.actionCreators.setEmailProvider}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.nextControl,
            onSecondary: !isOwaPluginMode()
              ? this.actionCreators.prevControl
              : undefined,
            primaryTextId: 'common.next',
            secondaryTextId: !isOwaPluginMode() ? 'common.back' : undefined,
          },
          header: {
            className: 'email-connection-flow-header',
            onClose: !isOwaPluginMode()
              ? this.actionCreators.closePopup
              : undefined,
            textId: 'web.settings.emailConnection.modal.getStartedOutlook',
          },
        };
      case ModalComponentIds.office365Selected:
        return {
          children: (
            <EmailRedirect
              emailConnectionProvider={EmailProvidersText.office365}
            />
          ),
          footer: {
            onPrimary: this.connectOffice365,
            onSecondary: this.actionCreators.prevControl,
            primaryTextId: 'common.ok',
            secondaryTextId: 'common.back',
          },
          header: {
            className: 'email-connection-flow-header',
            onClose: this.actionCreators.closePopup,
            textId: 'web.settings.emailConnection.modal.getStartedOutlook',
          },
        };
      case ModalComponentIds.onPremiseSelected:
        return {
          children: (
            <ExchangeOnpremSelected
              actionCreators={this.actionCreators}
              {...this.props}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.connectToExchange,
            onSecondary: this.actionCreators.prevControl,
            primaryTextId: 'common.connect',
            secondaryTextId: 'common.back',
          },
          header: {
            className: 'email-connection-flow-header',
            onClose: this.actionCreators.closePopup,
            textId: 'web.settings.emailConnection.modal.onPremise.header',
          },
          loading,
          size: 637,
        };
      case ModalComponentIds.gmailSelection: {
        return {
          children: (
            <EmailRedirect emailConnectionProvider={EmailProvidersText.gmail} />
          ),
          footer: {
            onPrimary: this.connectGmail,
            onSecondary: this.actionCreators.prevControl,
            primaryTextId: 'common.ok',
            secondaryTextId: 'common.back',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.settings.emailConnection.modal.getStartedGmail',
          },
        };
      }
      default:
        return {};
    }
  };

  connectGmail = () => connectEmail(EmailProviders.gmail);

  connectOffice365 = () => connectEmail(EmailProviders.office365);

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    emailConnectionModal: PropTypes.string,
    emailConnectionProvider: PropTypes.string,
    popup: PropTypes.string.isRequired,
  };

  static defaultProps = {
    emailConnectionModal: '',
    emailConnectionProvider: '',
  };
}

const mapStateToProps = (state) => ({
  alert: state.viewAlert,
  emailConnectionModal: state.emailConnectionModal,
  emailConnectionProvider: state.emailConnectionProvider,
  loading: state.popupLoading,
  onpremForm: state.onpremForm,
  popup: state.popup,
});

export default connect(mapStateToProps)(EmailConnectionFlowContainer);
