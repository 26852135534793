import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';
import { updateUnsubscribeSettings } from 'web/settings/unsubscribes/actionCreators/settingsUnsubscribesActionCreators';
import { saveAdminUnsubscribes } from '../actionCreators/adminUnsubscribesIntegrationsActionCreators';
import { UnsubscribeShowForUserItems } from '../libs/adminUnsubscribesConstants';
import Radio from 'components/buttons/radio';
import SettingsUnsubscribesConstants from 'web/settings/unsubscribes/libs/settingsUnsubscribesConstants';

class UnsubscribesShowForUserContainer extends Component {
  render() {
    const { selected } = this.props;

    return UnsubscribeShowForUserItems.map((item, index) => {
      const { label, value } = item;

      return (
        <Radio
          key={index}
          label={label}
          value={value}
          name="unsubscribe-show-for-user"
          onChange={this.onChange}
          checked={!!value === selected}
        />
      );
    });
  }

  onChange = (value) => {
    const { updateSettings, save } = this.props;

    updateSettings({
      allow_override_unsubscribe_text: !!value,
    });

    save(
      SettingsUnsubscribesConstants.alertIds.saveUnsubscribeMessagingSuccess
    );
  };
}

UnsubscribesShowForUserContainer.propTypes = {
  save: PropsTypes.func.isRequired,
  selected: PropsTypes.bool.isRequired,
  updateSettings: PropsTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selected: state.settingsUnsubscribes.allow_override_unsubscribe_text,
});

const mapDispatchToProps = {
  save: saveAdminUnsubscribes,
  updateSettings: updateUnsubscribeSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribesShowForUserContainer);
