/* eslint-disable react/jsx-indent */
import React from 'react';
import moment from 'moment';
import I18N from 'languages';
import TextCell from 'table/modules/cells/textCell';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import DateAgoCell from 'table/modules/cells/dateAgoCell';
import PersonGroupsCell from 'web/people/components/peopleTable/personGroupsCell';
import CheckmarkCell from 'table/modules/cells/checkmarkCell';
import intersection from 'lodash/intersection';
import { formatSourceTypeText } from 'web/person/helpers/personHelpers';
import { FormattedMessage } from 'react-intl';
import { PersonDetailsTabs } from 'web/person/libs/personDetailsConstants';
import SharedCell from 'web/people/components/peopleTable/sharedCell';
import AuthorizationTypeText from 'web/person/components/personDetails/authorizationTypeText';

const getCampaignCellComponent = (rowData, options, actionCreators) => {
  const { team_ids: teamIds = [], id: personId, primary_addresses } = rowData;
  const workflowDetails = rowData.workflow_details || [];
  const { account = {} } = options;
  const hasAccessToPerson = intersection(account.team_ids, teamIds).length > 0;

  const handleNavigateToHistoryTab = (e) => {
    e.stopPropagation();
    e.preventDefault();
    actionCreators.openPersonDetails(personId, {
      emailId: primary_addresses.email,
      tab: PersonDetailsTabs.history,
    });
  };

  if (workflowDetails.length > 1) {
    return (
      <a onClick={handleNavigateToHistoryTab}>
        <FormattedMessage id="web.people.multipleCampaigns" />
      </a>
    );
  } else if (workflowDetails.length === 1) {
    const workflow = workflowDetails[0];

    if (account.admin || account.id === workflow.userId || hasAccessToPerson) {
      return (
        <a
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            actionCreators.openCampaign(workflow);
          }}
        >
          {workflow.name}
        </a>
      );
    } else {
      return workflow.name;
    }
  } else {
    return '';
  }
};

const getOpenTasksCellComponent = (rowData, options, actionCreators) => {
  const { open_reminders_count: openTasks = 0, id: personId } = rowData;

  return openTasks > 0 ? (
    // eslint-disable-next-line react/jsx-indent
    <a
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        actionCreators.openPersonDetails(personId);
        actionCreators.onTabSelect(PersonDetailsTabs.tasks);
      }}
    >
      {openTasks}
    </a>
  ) : (
    openTasks
  );
};

const getConsentCellComponent = (rowData) => {
  const { compliance } = rowData;
  return compliance && compliance.authorization ? (
    <AuthorizationTypeText authorizationType={compliance.authorization} />
  ) : null;
};

const getTaskDueDateCellComponent = (rowData) => {
  const { task_due_date: taskDueDate } = rowData;

  if (taskDueDate) {
    const dueDate = moment(taskDueDate);
    const currentDate = moment().startOf('d');

    return dueDate.isSame(currentDate, 'd') ? (
      <FormattedMessage id="common.today" />
    ) : (
      dueDate.format(I18N.DateFormats.DATE_SLASH)
    );
  }
  return null;
};

const getSourceCellComponent = (rowData = {}) => {
  const compliance = rowData.compliance || {};
  const complianceDetails = rowData.complianceDetails || {};
  const source = complianceDetails.source || compliance.source;
  const textId = formatSourceTypeText[source];
  return textId ? <FormattedMessage id={textId} /> : source;
};

const unsubscribedProxyComponent = (params) => {
  const {
    rowData: { unsubscribed },
  } = params;
  return <CheckmarkCell checked={!!unsubscribed} />;
};

export default (
  sortColumn,
  sortColumnOpposite,
  formatMessage,
  actionCreators,
  options = {}
) => [
  {
    id: 'shared',
    name: '',
    rowCell: {
      component: SharedCell,
    },
    width: 30,
  },
  {
    flex: true,
    id: 'first-name',
    name: formatMessage({ id: 'common.firstName' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'first_name',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'first_name.sortable'),
    width: 175,
  },
  {
    flex: true,
    id: 'last-name',
    name: formatMessage({ id: 'common.lastName' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'last_name',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'last_name.sortable'),
    width: 175,
  },
  {
    flex: true,
    id: 'email',
    initHide: false,
    name: formatMessage({ id: 'common.email' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'email',
        wrapLines: 2,
      },
    },
    width: 200,
  },
  {
    flex: true,
    id: 'phone',
    initHide: true,
    name: formatMessage({ id: 'common.phone' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'phone',
        wrapLines: 2,
      },
    },
    width: 200,
  },
  {
    flex: true,
    id: 'title',
    initHide: false,
    name: formatMessage({ id: 'common.title' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'title',
        wrapLines: 2,
      },
    },
    width: 150,
  },
  {
    flex: true,
    id: 'company-name',
    initHide: false,
    name: formatMessage({ id: 'common.company' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'company_name',
        wrapLines: 2,
      },
    },
    width: 150,
  },
  {
    flex: true,
    id: 'campaign-name',
    initHide: false,
    name: formatMessage({ id: 'common.campaign' }),
    rowCell: {
      component: TextCustomCell,
      metadata: {
        formatText: (rowData) =>
          getCampaignCellComponent(rowData, options, actionCreators),
      },
    },
    width: 150,
  },
  {
    filterTooltip: 'web.tables.people.countCampaignsTooltip',
    flex: true,
    id: 'campaigns-count',
    initHide: true,
    name: formatMessage({ id: 'common.campaignsCount' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'campaigns_count',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'campaigns_count'),
    width: 160,
  },
  {
    filterTooltip: 'web.tables.people.countCallsTooltip',
    flex: true,
    id: 'calls-count',
    initHide: true,
    name: formatMessage({ id: 'common.callsCount' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'calls_count',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'calls_count'),
    width: 120,
  },
  {
    flex: true,
    id: 'emails-count',
    initHide: true,
    name: formatMessage({ id: 'common.emails_count' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'pitches_sent',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'pitches_sent'),
    width: 120,
  },
  {
    filterTooltip: 'web.tables.people.taskDueDateTooltip',
    flex: true,
    id: 'task-due-date',
    initHide: true,
    name: formatMessage({ id: 'task.taskDueDate' }),
    rowCell: {
      component: TextCustomCell,
      metadata: {
        formatText: (rowData) => getTaskDueDateCellComponent(rowData),
      },
    },
    width: 150,
  },
  {
    filterTooltip: 'web.tables.people.countEventTooltip',
    flex: true,
    id: 'event-count',
    initHide: false,
    name: formatMessage({ id: 'common.eventCount' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'event_count',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'event_count'),
    width: 150,
  },
  {
    filterTooltip: 'web.tables.people.countActivitiesTooltip',
    flex: true,
    id: 'activities-count',
    initHide: false,
    name: formatMessage({ id: 'common.activities_count' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'done_reminders_count',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'done_reminders_count'),
    width: 150,
  },
  {
    flex: true,
    id: 'consent',
    initHide: true,
    name: formatMessage({ id: 'web.people.compliance.consent' }),
    rowCell: {
      component: TextCustomCell,
      metadata: {
        formatText: (rowData) => getConsentCellComponent(rowData),
      },
    },
    width: 150,
  },
  {
    flex: true,
    id: 'open-tasks',
    initHide: true,
    name: formatMessage({ id: 'common.openTasks' }),
    rowCell: {
      component: TextCustomCell,
      metadata: {
        formatText: (rowData) =>
          getOpenTasksCellComponent(rowData, options, actionCreators),
      },
    },
    width: 135,
  },
  {
    flex: true,
    id: 'event-views-count',
    initHide: true,
    name: formatMessage({ id: 'web.tables.people.columnTitle.CountView' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'event_views_count',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'event_views_count'),
    width: 120,
  },
  {
    flex: true,
    id: 'event-clicks-count',
    initHide: true,
    name: formatMessage({ id: 'web.tables.people.columnTitle.CountClicks' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'event_clicks_count',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'event_clicks_count'),
    width: 120,
  },
  {
    flex: true,
    id: 'event-replies-count',
    initHide: true,
    name: formatMessage({ id: 'web.tables.people.columnTitle.CountReplies' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'event_replies_count',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'event_replies_count'),
    width: 120,
  },
  {
    id: 'last-updated',
    initHide: true,
    name: formatMessage({ id: 'web.templates.lastUpdated' }),
    rowCell: {
      component: DateAgoCell,
      metadata: {
        property: 'updated_at',
      },
    },
    sorting: sortColumn.bind(this, 'updated_at'),
    width: 135,
  },
  {
    flex: true,
    id: 'creator',
    initHide: true,
    name: formatMessage({ id: 'common.creator' }),
    rowCell: {
      component: TextCustomCell,
      metadata: {
        formatText: (rowData) => rowData.creator && rowData.creator.name,
        wrapLines: 2,
      },
    },
    width: 150,
  },
  {
    flex: true,
    id: 'source',
    initHide: true,
    name: formatMessage({ id: 'common.source' }),
    rowCell: {
      component: TextCustomCell,
      metadata: {
        formatText: getSourceCellComponent,
        wrapLines: 2,
      },
    },
    width: 120,
  },
  {
    flex: true,
    id: 'groups',
    initHide: false,
    name: formatMessage({ id: 'common.groups' }),
    rowCell: {
      component: PersonGroupsCell,
      metadata: {
        openPersonGroupsModal: actionCreators.openPersonGroupsModal,
        wrapLines: 2,
      },
    },
    width: 250,
  },
  {
    flex: true,
    id: 'unsubscribed',
    initHide: true,
    name: formatMessage({ id: 'common.unsubscribed' }),
    rowCell: {
      component: unsubscribedProxyComponent,
    },
    sorting: sortColumn.bind(this, 'unsubscribed'),
    width: 150,
  },
];
