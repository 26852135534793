import { baseGet, basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';
import { buildEsFetchRequestParams } from 'web/elasticSearch/services/esFetch';
import { ALL_PERSON_QUERY_FIELDS } from 'web/people/peopleSearchAdd/libs/peopleSearchAddConstants';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';

export const PEOPLE_LIMIT = 10; // todo for testing purposes; release will be 50

export function getCampaignPeople(
  id,
  filter = {},
  offset = 0,
  limit = PEOPLE_LIMIT,
  str = ''
) {
  const { state = '', userId } = filter;
  return searchCampaignPeople(id, state, str, offset, limit, userId);
}

export function searchCampaignPeople(
  id,
  campaignState = '',
  str = '',
  offset = 0,
  limit = PEOPLE_LIMIT,
  viewAsUserId = 0
) {
  const es_params = buildEsFetchRequestParams({
    perPage: PEOPLE_LIMIT,
    textSearch: {
      fields: ALL_PERSON_QUERY_FIELDS,
      term: str,
    },
    type: ElasticSearchTypes.people,
    viewer: {
      id: viewAsUserId,
      type: 'user',
    },
  });

  return new Promise((resolve, reject) => {
    baseGet(
      Urls.campaignInstances.replace(':#id', id),
      {
        es_params,
        limit,
        offset,
        person_search: str,
        state: campaignState,
        user_id: viewAsUserId,
      },
      resolve,
      reject
    );
  });
}

export function removePeople(
  workflowInstanceIds = [],
  workflowId,
  { admin = false, success = false } = {}
) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.campaignsStop.replace(':#id', workflowId),
      { admin, success, workflow_instance_ids: workflowInstanceIds },
      resolve,
      reject
    );
  });
}

export function removePeopleAsync(
  workflowInstanceIds = [],
  workflowId,
  { success = false } = {}
) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.campaignsStopAsync.replace(':#id', workflowId),
      { success, workflow_instance_ids: workflowInstanceIds },
      resolve,
      reject
    );
  });
}
