tinymce.addI18n('de', {
  Redo: 'Wiederholen',
  Undo: 'R\u00fcckg\u00e4ngig machen',
  Cut: 'Ausschneiden',
  Copy: 'Kopieren',
  Paste: 'Einf\u00fcgen',
  'Select all': 'Alles ausw\u00e4hlen',
  'New document': 'Neues Dokument',
  Ok: 'Ok',
  Cancel: 'Abbrechen',
  'Visual aids': 'Visuelle Hilfen',
  Bold: 'Fett',
  Italic: 'Kursiv',
  Underline: 'Unterstrichen',
  Strikethrough: 'Durchgestrichen',
  Superscript: 'Hochgestellt',
  Subscript: 'Tiefgestellt',
  'Clear formatting': 'Formatierung entfernen',
  'Align left': 'Linksb\u00fcndig ausrichten',
  'Align center': 'Zentrieren',
  'Align right': 'Rechtsb\u00fcndig ausrichten',
  Justify: 'Blocksatz',
  'Bullet list': 'Aufz\u00e4hlung',
  'Numbered list': 'Nummerierte Liste',
  'Decrease indent': 'Einzug verkleinern',
  'Increase indent': 'Einzug vergr\u00f6\u00dfern',
  Close: 'Schlie\u00dfen',
  Formats: 'Formate',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    'Ihr Browser unterst\u00fctzt leider keinen direkten Zugriff auf die Zwischenablage. Bitte benutzen Sie die Tastenkombinationen Strg+X/C/V.',
  Headers: '\u00dcberschriften',
  'Header 1': '\u00dcberschrift 1',
  'Header 2': '\u00dcberschrift 2',
  'Header 3': '\u00dcberschrift 3',
  'Header 4': '\u00dcberschrift 4',
  'Header 5': '\u00dcberschrift 5',
  'Header 6': '\u00dcberschrift 6',
  Headings: '\u00dcberschriften',
  'Heading 1': 'Kopfzeile 1',
  'Heading 2': 'Kopfzeile 2',
  'Heading 3': 'Kopfzeile 3',
  'Heading 4': 'Kopfzeile 4',
  'Heading 5': 'Kopfzeile 5',
  'Heading 6': 'Kopfzeile 6',
  Preformatted: 'Vorformatiert',
  Div: 'Div',
  Pre: 'Pre',
  Code: 'Code',
  Paragraph: 'Absatz',
  Blockquote: 'Blockquote',
  Inline: 'Zeichenformate',
  Blocks: 'Bl\u00f6cke',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    'Einf\u00fcgen ist nun im einfachen Textmodus. Inhalte werden ab jetzt als unformatierter Text eingef\u00fcgt, bis Sie diese Einstellung wieder ausschalten!',
  Fonts: 'Schriftarten',
  'Font Sizes': 'Schriftgr\u00f6\u00dfe',
  Class: 'Klasse',
  'Browse for an image': 'Bild...',
  OR: 'ODER',
  'Drop an image here': 'Bild hier ablegen',
  Upload: 'Hochladen',
  Block: 'Blocksatz',
  Align: 'Ausrichten',
  Default: 'Standard',
  Circle: 'Kreis',
  Disc: 'Punkt',
  Square: 'Quadrat',
  'Lower Alpha': 'Kleinbuchstaben',
  'Lower Greek': 'Griechische Kleinbuchstaben',
  'Lower Roman': 'R\u00f6mische Zahlen (Kleinbuchstaben)',
  'Upper Alpha': 'Gro\u00dfbuchstaben',
  'Upper Roman': 'R\u00f6mische Zahlen (Gro\u00dfbuchstaben)',
  'Anchor...': 'Textmarke',
  Name: 'Name',
  Id: 'Kennung',
  'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'Die Kennung sollte mit einem Buchstaben anfangen. Nachfolgend nur Buchstaben, Zahlen, Striche (Minus), Punkte, Kommas und Unterstriche.',
  'You have unsaved changes are you sure you want to navigate away?':
    'Die \u00c4nderungen wurden noch nicht gespeichert, sind Sie sicher, dass Sie diese Seite verlassen wollen?',
  'Restore last draft': 'Letzten Entwurf wiederherstellen',
  'Special character...': 'Sonderzeichen...',
  'Source code': 'Quelltext',
  'Insert/Edit code sample': 'Codebeispiel einf\u00fcgen/bearbeiten',
  Language: 'Sprache',
  'Code sample...': 'Codebeispiel...',
  'Color Picker': 'Farbwahl',
  R: 'R',
  G: 'G',
  B: 'B',
  'Left to right': 'Von links nach rechts',
  'Right to left': 'Von rechts nach links',
  'Emoticons...': 'Emoticons...',
  'Metadata and Document Properties': 'Dokument-Eigenschaften und -Metadaten',
  Title: 'Titel',
  Keywords: 'Sch\u00fcsselw\u00f6rter',
  Description: 'Beschreibung',
  Robots: 'Robots',
  Author: 'Verfasser',
  Encoding: 'Zeichenkodierung',
  Fullscreen: 'Vollbild',
  Action: 'Aktion',
  Shortcut: 'Shortcut',
  Help: 'Hilfe',
  Address: 'Adresse',
  'Focus to menubar': 'Fokus auf Men\u00fcleiste',
  'Focus to toolbar': 'Fokus auf Werkzeugleiste',
  'Focus to element path': 'Fokus auf Elementpfad',
  'Focus to contextual toolbar': 'Fokus auf kontextbezogene Werkzeugleiste',
  'Insert link (if link plugin activated)':
    'Link einf\u00fcgen (wenn Link-Plugin aktiviert ist)',
  'Save (if save plugin activated)':
    'Speichern (wenn Save-Plugin aktiviert ist)',
  'Find (if searchreplace plugin activated)':
    'Suchen einf\u00fcgen (wenn Suchen/Ersetzen-Plugin aktiviert ist)',
  'Plugins installed ({0}):': 'installierte Plugins ({0}):',
  'Premium plugins:': 'Premium Plugins:',
  'Learn more...': 'Erfahren Sie mehr dazu...',
  'You are using {0}': 'Sie verwenden {0}',
  Plugins: 'Plugins',
  'Handy Shortcuts': 'Praktische Tastenkombinationen',
  'Horizontal line': 'Horizontale Linie',
  'Insert/edit image': 'Bild einf\u00fcgen/bearbeiten',
  'Image description': 'Bildbeschreibung',
  Source: 'Quelle',
  Dimensions: 'Abmessungen',
  'Constrain proportions': 'Seitenverh\u00e4ltnis beibehalten',
  General: 'Allgemein',
  Advanced: 'Erweitert',
  Style: 'Stil',
  'Vertical space': 'Vertikaler Abstand',
  'Horizontal space': 'Horizontaler Abstand',
  Border: 'Rahmen',
  'Insert image': 'Bild einf\u00fcgen',
  'Image...': 'Bild...',
  'Image list': 'Bildliste',
  'Rotate counterclockwise': 'Gegen den Uhrzeigersinn drehen',
  'Rotate clockwise': 'Im Uhrzeigersinn drehen',
  'Flip vertically': 'Vertikal spiegeln',
  'Flip horizontally': 'Horizontal spiegeln',
  'Edit image': 'Bild bearbeiten',
  'Image options': 'Bildeigenschaften',
  'Zoom in': 'Ansicht vergr\u00f6\u00dfern',
  'Zoom out': 'Ansicht verkleinern',
  Crop: 'Bescheiden',
  Resize: 'Skalieren',
  Orientation: 'Ausrichtung',
  Brightness: 'Helligkeit',
  Sharpen: 'Sch\u00e4rfen',
  Contrast: 'Kontrast',
  'Color levels': 'Farbwerte',
  Gamma: 'Gamma',
  Invert: 'Invertieren',
  Apply: 'Anwenden',
  Back: 'Zur\u00fcck',
  'Insert date/time': 'Datum/Uhrzeit einf\u00fcgen ',
  'Date/time': 'Datum/Uhrzeit',
  'Insert/Edit Link': 'Link einf\u00fcgen/bearbeiten',
  'Insert/edit link': 'Link einf\u00fcgen/bearbeiten',
  'Text to display': 'Anzuzeigender Text',
  Url: 'URL',
  'Open link in...': 'Link \u00f6ffnen in...',
  'Current window': 'Aktuelles Fenster',
  None: 'Keine',
  'New window': 'Neues Fenster',
  'Remove link': 'Link entfernen',
  Anchors: 'Textmarken',
  'Link...': 'Link...',
  'Paste or type a link': 'Link einf\u00fcgen oder eintippen',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    'Diese Adresse scheint eine E-Mail-Adresse zu sein. M\u00f6chten Sie das dazu ben\u00f6tigte "mailto:" voranstellen?',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    'Diese Adresse scheint ein externer Link zu sein. M\u00f6chten Sie das dazu ben\u00f6tigte "http://" voranstellen?',
  'Link list': 'Linkliste',
  'Insert video': 'Video einf\u00fcgen',
  'Insert/edit video': 'Video einf\u00fcgen/bearbeiten',
  'Insert/edit media': 'Medien einf\u00fcgen/bearbeiten',
  'Alternative source': 'Alternative Quelle',
  'Alternative source URL': 'URL der alternativen Quelle',
  'Media poster (Image URL)': 'Medienposter (Bild-URL)',
  'Paste your embed code below:':
    'F\u00fcgen Sie Ihren Einbettungscode hier ein:',
  Embed: 'Einbetten',
  'Media...': 'Medien...',
  'Nonbreaking space': 'Gesch\u00fctztes Leerzeichen',
  'Page break': 'Seitenumbruch',
  'Paste as text': 'Als Text einf\u00fcgen',
  Preview: 'Vorschau',
  'Print...': 'Drucken...',
  Save: 'Speichern',
  Find: 'Suchen',
  'Replace with': 'Ersetzen durch',
  Replace: 'Ersetzen',
  'Replace all': 'Alles ersetzen',
  Previous: 'Vorherige',
  Next: 'Weiter',
  'Find and replace...': 'Suchen und ersetzen...',
  'Could not find the specified string.':
    'Die Zeichenfolge wurde nicht gefunden.',
  'Match case': 'Gro\u00df-/Kleinschreibung beachten',
  'Find whole words only': 'Nur ganze W\u00f6rter suchen',
  'Spell check': 'Rechschreibpr\u00fcfung',
  Ignore: 'Ignorieren',
  'Ignore all': 'Alles Ignorieren',
  Finish: 'Ende',
  'Add to Dictionary': 'Zum W\u00f6rterbuch hinzuf\u00fcgen',
  'Insert table': 'Tabelle einf\u00fcgen',
  'Table properties': 'Tabelleneigenschaften',
  'Delete table': 'Tabelle l\u00f6schen',
  Cell: 'Zelle',
  Row: 'Zeile',
  Column: 'Spalte',
  'Cell properties': 'Zelleneigenschaften',
  'Merge cells': 'Zellen verbinden',
  'Split cell': 'Zelle aufteilen',
  'Insert row before': 'Neue Zeile davor einf\u00fcgen ',
  'Insert row after': 'Neue Zeile danach einf\u00fcgen',
  'Delete row': 'Zeile l\u00f6schen',
  'Row properties': 'Zeileneigenschaften',
  'Cut row': 'Zeile ausschneiden',
  'Copy row': 'Zeile kopieren',
  'Paste row before': 'Zeile davor einf\u00fcgen',
  'Paste row after': 'Zeile danach einf\u00fcgen',
  'Insert column before': 'Neue Spalte davor einf\u00fcgen',
  'Insert column after': 'Neue Spalte danach einf\u00fcgen',
  'Delete column': 'Spalte l\u00f6schen',
  Cols: 'Spalten',
  Rows: 'Zeilen',
  Width: 'Breite',
  Height: 'H\u00f6he',
  'Cell spacing': 'Zellenabstand',
  'Cell padding': 'Zelleninnenabstand',
  'Show caption': 'Beschriftung anzeigen',
  Left: 'Linksb\u00fcndig',
  Center: 'Zentriert',
  Right: 'Rechtsb\u00fcndig',
  'Cell type': 'Zellentyp',
  Scope: 'G\u00fcltigkeitsbereich',
  Alignment: 'Ausrichtung',
  'H Align': 'Horizontale Ausrichtung',
  'V Align': 'Vertikale Ausrichtung',
  Top: 'Oben',
  Middle: 'Mitte',
  Bottom: 'Unten',
  'Header cell': 'Kopfzelle',
  'Row group': 'Zeilengruppe',
  'Column group': 'Spaltengruppe',
  'Row type': 'Zeilentyp',
  Header: 'Kopfzeile',
  Body: 'Inhalt',
  Footer: 'Fu\u00dfzeile',
  'Border color': 'Rahmenfarbe',
  'Insert template...': 'Vorlage einf\u00fcgen...',
  Templates: 'Vorlagen',
  Template: 'Vorlage',
  'Text color': 'Textfarbe',
  'Background color': 'Hintergrundfarbe',
  'Custom...': 'Benutzerdefiniert...',
  'Custom color': 'Benutzerdefinierte Farbe',
  'No color': 'Keine Farbe',
  'Remove color': 'Farbauswahl aufheben',
  'Table of Contents': 'Inhaltsverzeichnis',
  'Show blocks': 'Bl\u00f6cke anzeigen',
  'Show invisible characters': 'Unsichtbare Zeichen anzeigen',
  'Word count': 'Anzahl der W\u00f6rter',
  Count: 'Anzahl',
  Document: 'Dokument',
  Selection: 'Auswahl',
  Words: 'W\u00f6rter',
  'Words: {0}': 'W\u00f6rter: {0}',
  '{0} words': '{0} W\u00f6rter',
  File: 'Datei',
  Edit: 'Bearbeiten',
  Insert: 'Einf\u00fcgen',
  View: 'Ansicht',
  Format: 'Format',
  Table: 'Tabelle',
  Tools: 'Werkzeuge',
  'Powered by {0}': 'Betrieben von {0}',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    'Rich-Text- Area. Dr\u00fccken Sie ALT-F9 f\u00fcr das Men\u00fc. Dr\u00fccken Sie ALT-F10 f\u00fcr Symbolleiste. Dr\u00fccken Sie ALT-0 f\u00fcr Hilfe',
  'Image title': 'Bildtitel',
  'Border width': 'Rahmenbreite',
  'Border style': 'Rahmenstil',
  Error: 'Fehler',
  Warn: 'Warnung',
  Valid: 'G\u00fcltig',
  'To open the popup, press Shift+Enter':
    'Dr\u00fccken Sie Umschalt+Eingabe, um das Popup-Fenster zu \u00f6ffnen.',
  'Rich Text Area. Press ALT-0 for help.':
    'Rich-Text-Bereich. Dr\u00fccken Sie Alt+0 f\u00fcr Hilfe.',
  'System Font': 'Betriebssystemschriftart',
  'Failed to upload image: {0}': 'Bild konnte nicht hochgeladen werden: {0}',
  'Failed to load plugin: {0} from url {1}':
    'Plugin konnte nicht geladen werden: {0} von URL {1}',
  'Failed to load plugin url: {0}':
    'Plugin-URL konnte nicht geladen werden: {0}',
  'Failed to initialize plugin: {0}':
    'Plugin konnte nicht initialisiert werden: {0}',
  example: 'Beispiel',
  Search: 'Suchen',
  All: 'Alles',
  Currency: 'W\u00e4hrung',
  Text: 'Text',
  Quotations: 'Anf\u00fchrungszeichen',
  Mathematical: 'Mathematisch',
  'Extended Latin': 'Erweitertes Latein',
  Symbols: 'Symbole',
  Arrows: 'Pfeile',
  'User Defined': 'Benutzerdefiniert',
  'dollar sign': 'Dollarzeichen',
  'currency sign': 'W\u00e4hrungssymbol',
  'euro-currency sign': 'Eurozeichen',
  'colon sign': 'Doppelpunkt',
  'cruzeiro sign': 'Cruzeirozeichen',
  'french franc sign': 'Franczeichen',
  'lira sign': 'Lirezeichen',
  'mill sign': 'Millzeichen',
  'naira sign': 'Nairazeichen',
  'peseta sign': 'Pesetazeichen',
  'rupee sign': 'Rupiezeichen',
  'won sign': 'Wonzeichen',
  'new sheqel sign': 'Schekelzeichen',
  'dong sign': 'Dongzeichen',
  'kip sign': 'Kipzeichen',
  'tugrik sign': 'Tugrikzeichen',
  'drachma sign': 'Drachmezeichen',
  'german penny symbol': 'Pfennigzeichen',
  'peso sign': 'Pesozeichen',
  'guarani sign': 'Guaranizeichen',
  'austral sign': 'Australzeichen',
  'hryvnia sign': 'Hrywnjazeichen',
  'cedi sign': 'Cedizeichen',
  'livre tournois sign': 'Livrezeichen',
  'spesmilo sign': 'Spesmilozeichen',
  'tenge sign': 'Tengezeichen',
  'indian rupee sign': 'Indisches Rupiezeichen',
  'turkish lira sign': 'T\u00fcrkisches Lirazeichen',
  'nordic mark sign': 'Zeichen nordische Mark',
  'manat sign': 'Manatzeichen',
  'ruble sign': 'Rubelzeichen',
  'yen character': 'Yenzeichen',
  'yuan character': 'Yuanzeichen',
  'yuan character, in hong kong and taiwan':
    'Yuanzeichen in Hongkong und Taiwan',
  'yen/yuan character variant one': 'Yen-/Yuanzeichen Variante 1',
  'Loading emoticons...': 'Emoticons werden geladen...',
  'Could not load emoticons': 'Emoticons konnten nicht geladen werden',
  People: 'Menschen',
  'Animals and Nature': 'Tiere und Natur',
  'Food and Drink': 'Essen und Trinken',
  Activity: 'Aktivit\u00e4t',
  'Travel and Places': 'Reisen und Orte',
  Objects: 'Objekte',
  Flags: 'Flaggen',
  Characters: 'Zeichen',
  'Characters (no spaces)': 'Zeichen (ohne Leerzeichen)',
  '{0} characters': '{0}\u00a0Zeichen',
  'Error: Form submit field collision.':
    'Fehler: Kollision der Formularbest\u00e4tigungsfelder.',
  'Error: No form element found.': 'Fehler: Kein Formularelement gefunden.',
  Update: 'Aktualisieren',
  'Color swatch': 'Farbpalette',
  Turquoise: 'T\u00fcrkis',
  Green: 'Gr\u00fcn',
  Blue: 'Blau',
  Purple: 'Violett',
  'Navy Blue': 'Marineblau',
  'Dark Turquoise': 'Dunkelt\u00fcrkis',
  'Dark Green': 'Dunkelgr\u00fcn',
  'Medium Blue': 'Mittleres Blau',
  'Medium Purple': 'Mittelviolett',
  'Midnight Blue': 'Mitternachtsblau',
  Yellow: 'Gelb',
  Orange: 'Orange',
  Red: 'Rot',
  'Light Gray': 'Hellgrau',
  Gray: 'Grau',
  'Dark Yellow': 'Dunkelgelb',
  'Dark Orange': 'Dunkelorange',
  'Dark Red': 'Dunkelrot',
  'Medium Gray': 'Mittelgrau',
  'Dark Gray': 'Dunkelgrau',
  'Light Green': 'Hellgr\u00fcn',
  'Light Yellow': 'Hellgelb',
  'Light Red': 'Hellrot',
  'Light Purple': 'Helllila',
  'Light Blue': 'Hellblau',
  'Dark Purple': 'Dunkellila',
  'Dark Blue': 'Dunkelblau',
  Black: 'Schwarz',
  White: 'Wei\u00df',
  'Switch to or from fullscreen mode': 'Vollbildmodus umschalten',
  'Open help dialog': 'Hilfe-Dialog \u00f6ffnen',
  history: 'Historie',
  styles: 'Stile',
  formatting: 'Formatierung',
  alignment: 'Ausrichtung',
  indentation: 'Einr\u00fcckungen',
  'permanent pen': 'Textmarker',
  comments: 'Anmerkungen',
  'Format Painter': 'Format-Painter',
  'Insert/edit iframe': 'iframe einf\u00fcgen/bearbeiten',
  Capitalization: 'Gro\u00dfschreibung',
  lowercase: 'Kleinbuchstaben',
  UPPERCASE: 'Gro\u00dfbuchstaben',
  'Title Case': 'Gro\u00df-/Kleinschreibung des Titels',
  'Permanent Pen Properties': 'Eigenschaften von Permanent Pen',
  'Permanent pen properties...': 'Eigenschaften von Permanent Pen...',
  Font: 'Schriftart',
  Size: 'Schriftgr\u00f6\u00dfe',
  'More...': 'Mehr...',
  'Spellcheck Language': 'Sprache f\u00fcr die Rechtschreibpr\u00fcfung',
  'Select...': 'Auswahl...',
  Preferences: 'Einstellungen',
  Yes: 'Ja',
  No: 'Nein',
  'Keyboard Navigation': 'Tastaturnavigation',
  Version: 'Version',
  Anchor: 'Textmarke',
  'Special character': 'Sonderzeichen',
  'Code sample': 'Codebeispiel',
  Color: 'Farbe',
  Emoticons: 'Emoticons',
  'Document properties': 'Dokumenteigenschaften',
  Image: 'Bild',
  'Insert link': 'Link einf\u00fcgen',
  Target: 'Ziel',
  Link: 'Link',
  Poster: 'Poster',
  Media: 'Medium',
  Print: 'Drucken',
  Prev: 'Zur\u00fcck',
  'Find and replace': 'Suchen und ersetzen',
  'Whole words': 'Nur ganze W\u00f6rter',
  Spellcheck: 'Rechtschreibpr\u00fcfung',
  Caption: 'Beschriftung',
  'Insert template': 'Vorlage einf\u00fcgen ',
  'Open link': 'Verbindung \u00f6ffnen',
  Solid: 'Solid',
  Dotted: 'Dotted',
  Dashed: 'Dashed',
  Double: 'Doppelt',
  Groove: 'Groove',
  Ridge: 'Ridge',
  Inset: 'Inset',
  Outset: 'Outset',
  Hidden: 'Ausgeblendet',
});
