export const PersonDetailsTabs = {
  about: 'ABOUT',
  engagement: 'ENGAGEMENT',
  history: 'HISTORY',
  linkedinSalesNavigotor: 'LINKEDIN_SALES_NAVIGATOR',
  tasks: 'TASKS',
};

export const PersonDetailsCards = {
  accountInfo: 'ACCOUNT_INFO',
  calls: 'CALLS',
  campaigns: 'CAMPAIGNS',
  compliance: 'COMPLIANCE',
  customFields: 'CUSTOM_FIELDS',
  emails: 'EMAILS',
  groups: 'GROUPS',
  info: 'INFO',
  marketoCampaignsCard: 'MARKETO_CAMPAIGNS_CARD',
  marketoEvents: 'MARKETO_EVENTS',
  notes: 'NOTES',
  toutEvents: 'TOUT_EVENTS',
  unsubscribeHistory: 'UNSUBSCRIBE_HISTORY',
};

export const PersonDetailsPopupIds = {
  addAsTemplate: 'PERSON_DETAILS_POPUP_ADD_AS_TEMPLATE',
  delete: 'PERSON_DETAILS_POPUP_DELETE',
  deleteCall: 'PERSON_DETAILS_POPUP_DELETE_CALL',
  deleteNote: 'PERSON_DETAILS_POPUP_DELETE_NOTE',
  events: 'PERSON_DETAILS_POPUP_EVENTS',
  hasChanges: 'PERSON_DETAILS_POPUP_HAS_CHANGES',
  removeUnsubscribe: 'PERSON_DETAILS_POPUP_REMOVE_UNSUBSCRIBE',
  unsubscribe: 'PERSON_DETAILS_POPUP_UNSUBSCRIBE',
};

export const PersonDetailsPopupAlertIds = {
  generic: 'PERSON_DETAILS_POPUP_ALERT_GENERIC',
  invalidOptInReasonSelected: 'INVALID_OPT_IN_REASON_SELECTED_VALUE_ALERT',
};

export const PersonDetailsTabAlertIds = {
  addToSalesforceDuplicatedFieldError:
    'PERSON_DETAILS_TAB_DUPLICATED_FIELD_ERROR',
  addToSalesforceFail: 'PERSON_DETAILS_TAB_ALERT_ADD_TO_SALESFORCE_FAIL',
  addToSalesforceNoOwnership: 'PERSON_DETAILS_TAB_NO_OWNERSHIP',
  addToSalesforceOptOutFieldError:
    'PERSON_DETAILS_TAB_ALERT_OPT_OUT_FIELD_ERROR',
  archivingEmail: 'PERSON_DETAILS_TAB_ALERT_ARCHIVING_EMAIL',
  createPersonFail: 'PERSON_DETAILS_TAB_ALERT_CREATE_PERSON_FAIL',
  deleteUserPrevent: 'PERSON_DETAILS_PREVENT_USER_DELETION',
  deleteUserPreventAdmin: 'PERSON_DETAILS_PREVENT_ADMIN_USER_DELETION',
  duplicateContact: 'PERSON_DETAILS_TAB_ALERT_DUPLICATE_CONTACT',
  followUpFail: 'PERSON_DETAILS_TAB_ALERT_FOLLOW_UP_FAIL',
  getAccountInfoFail: 'PERSON_DETAILS_TAB_ALERT_GET_ACCOUNT_INFO_FAIL',
  getEmailsFail: 'PERSON_DETAILS_TAB_ALERT_GET_EMAILS_FAIL',
  getMarketoEventsFail: 'PERSON_DETAILS_TAB_ALERT_GET_MARKETO_EVENTS_FAIL',
  getPersonById: 'PERSON_DETAILS_TAB_ALERT_GET_PERSON_BY_ID',
  getTemplateCategoriesFail:
    'PERSON_DETAILS_TAB_ALERT_GET_TEMPLATE_CATEGORIES_FAIL',
  groupsFail: 'PERSON_DETAILS_TAB_ALERT_GROUPS_FAIL',
  invalidCustomFieldBadName:
    'PERSON_DETAILS_TAB_ALERT_INVALID_CUSTOM_FIELD_BAD_NAME',
  invalidCustomFieldNoName:
    'PERSON_DETAILS_TAB_ALERT_INVALID_CUSTOM_FIELD_NO_NAME',
  invalidCustomFieldSameName:
    'PERSON_DETAILS_TAB_ALERT_INVALID_CUSTOM_FIELD_SAME_NAME',
  invalidEmail: 'PERSON_DETAILS_TAB_ALERT_INVALID_EMAIL',
  invalidMarketoId: 'PERSON_DETAILS_TAB_ALERT_INVALID_MARKETO_ID',
  invalidPhone: 'PERSON_DETAILS_TAB_ALERT_INVALID_PHONE',
  invalidSalesforceId: 'PERSON_DETAILS_TAB_ALERT_INVALID_SALESFORCE_ID',
  invalidUsername: 'PERSON_DETAILS_TAB_ALERT_INVALID_USERNAME',
  invalidWebsite: 'PERSON_DETAILS_TAB_ALERT_INVALID_WEBSITE',
  missingRequiredFields: 'PERSON_DETAILS_TAB_ALERT_MISSING_REQUIRED_FIELDS',
  missingRequiredSalesforceFields:
    'PERSON_DETAILS_TAB_ALERT_MISSING_REQUIRED_SALESFORCE_FIELDS',
  noAccessToCampaign: 'PERSON_DETAILS_TAB_ALERT_USER_HAS_NO_ACCESS_TO_CAMPAIGN',
  removeFromCampaignFail: 'PERSON_DETAILS_TAB_ALERT_REMOVE_FROM_CAMPAIGN_FAIL',
  removeFromCampaignNoAccess: 'PERSON_DETAILS_TAB_ALERT_NO_ACCESS_TO_CAMPAIGN',
  removeFromCampaignSuccess:
    'PERSON_DETAILS_TAB_ALERT_REMOVE_FROM_CAMPAIGN_SUCCESS',
  removeUnsubscribeWarning:
    'PERSON_DETAILS_TAB_ALERT_REMOVE_UNSUBSCRIBE_WARNING',
  saveComplianceFail: 'PERSON_DETAILS_TAB_ALERT_SAVE_COMPLIANCE_FAIL',
  saveNoteFail: 'PERSON_DETAILS_TAB_ALERT_SAVE_NOTE_FAIL',
  savePersonFail: 'PERSON_DETAILS_TAB_ALERT_SAVE_PERSON_FAIL',
  specialCharsInSalesforce:
    'PERSON_DETAILS_TAB_ALERT_INVALID_SALESFORCE_ID_WITH_SPECIAL_CHARS',
  unarchivingEmail: 'PERSON_DETAILS_TAB_ALERT_UNARCHIVING_EMAIL',
  uniquePhone: 'PERSON_DETAILS_TAB_ALERT_UNIQUE_PHONE_NUMBER',
};

export const AddressTypes = {
  email: 'email',
  facebook: 'facebook',
  im: 'im',
  linkedin: 'linkedin',
  marketo: 'marketo',
  other: 'other',
  phone: 'phone',
  salesforce: 'salesforce',
  text: 'text',
  twitter: 'twitter',
  url: 'url',
  website: 'website',
};

export const LeadFields = {
  marketoLeadFieldName: 'marketo_lead_id',
};

export const ApiKeys = {
  externalReferences: 'externalReferences',
  marketo: 'marketo',
  marketoIndex: 0,
  salesforce: 'salesforce',
  salesforceIndex: 1,
  service: 'service',
  serviceEntityId: 'service_entity_id',
};

export const AddressLocations = {
  mobile: 'mobile',
  other: 'other',
  personal: 'personal',
  work: 'work',
};

export const AddressTypesMultiple = new Set([
  AddressTypes.email,
  AddressTypes.phone,
  AddressTypes.website,
  AddressTypes.other,
]);

export const AddressTypesSingle = new Set([
  AddressTypes.facebook,
  AddressTypes.linkedin,
  AddressTypes.twitter,
]);

export const AddressLocationsOrderedEnum = {
  [AddressLocations.work]: 0,
  [AddressLocations.personal]: 1,
  [AddressLocations.mobile]: 2,
  [AddressLocations.other]: 3,
};

export const CallOutcomes = {
  call: 'call',
  connected: 'connect',
  noVoicemail: 'no-voicemail',
  none: '',
  success: 'success',
  voicemail: 'voicemail',
};

export const PersonDetailsTasksTableId = 'person-details-tasks-table';

export const SalesforceTypes = {
  contact: 'contact',
  lead: 'lead',
};

export const BlockWay = {
  email: 'email',
};

export const UNSUBSCRIBE_HISTORY_ITEM_TYPE = {
  created: 'created',
  deleted: 'deleted',
};

export const UNSUBSCRIBE_SOURCE = {
  manual: 'Manual',
};
