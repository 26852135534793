import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import { isUnsubscribed } from 'web/person/helpers/personHelpers';
import GroupsEditing from './editing';
import GroupsDetails from './details';

class GroupsCard extends Component {
  render() {
    const {
      actionCreators: {
        closeCardEditing,
        saveGroups,
        updateGroupsEditState,
        updateGroupsEditStateAdd,
        updateGroupsEditStateRemove,
      },
      groupsAddable,
      groupsCardHasChanges,
      groupsEditState,
      hiddenButtons,
      isNewContact,
      isEditing,
      person,
    } = this.props;

    if (isEditing) {
      return (
        <GroupsEditing
          addGroup={updateGroupsEditStateAdd}
          cancel={closeCardEditing}
          editState={groupsEditState}
          groupsAddable={groupsAddable}
          groupsCardHasChanges={groupsCardHasChanges}
          hiddenButtons={hiddenButtons}
          removeGroup={updateGroupsEditStateRemove}
          save={saveGroups}
          updateEditState={updateGroupsEditState}
          isNewContact={isNewContact}
        />
      );
    } else {
      return (
        <GroupsDetails
          edit={this.edit}
          groupsByAddressId={person.groupsByAddressId}
          emailsCount={person.groupsEmailsCount}
          isUnsubscribed={isUnsubscribed(person)}
          isNewContact={isNewContact}
        />
      );
    }
  }

  edit = () =>
    this.props.actionCreators.openCardEditing(PersonDetailsCards.groups);
}

GroupsCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  groupsAddable: PropTypes.array.isRequired,
  groupsCardHasChanges: PropTypes.bool.isRequired,
  groupsEditState: PropTypes.object.isRequired,
  hiddenButtons: PropTypes.bool,
  isEditing: PropTypes.bool,
  isNewContact: PropTypes.bool,
  person: PropTypes.object.isRequired,
};

GroupsCard.defaultProps = {
  hiddenButtons: false,
  isEditing: false,
  isNewContact: false,
};

export default GroupsCard;
