import {
  CUSTOMIZATION_OPTIONS_FIELDS,
  CUSTOMIZATION_OPTIONS_BUTTONS,
  CUSTOMIZATION_OPTIONS_OTHER,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';

export const salesforceCustomizationItems = {
  [CUSTOMIZATION_OPTIONS_FIELDS]: [
    {
      category:
        'web.salesforceCustomization.wizard.items.fields.category.accountFields',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.accountFields.toutCurrentCampaign.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.accountFields.toutCurrentCampaign.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.accountFields.toutLastActivity.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.accountFields.toutLastActivity.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.accountFields.toutLastEngagement.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.accountFields.toutLastEngagement.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.accountFields.toutLastReplied.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.accountFields.toutLastReplied.description',
        },
      ],
    },
    {
      category:
        'web.salesforceCustomization.wizard.items.fields.category.activityFields',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCallAnsweredBy.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCallAnsweredBy.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCallLocalPresenceID.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCallLocalPresenceID.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCallRecordingDuration.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCallRecordingDuration.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCallRecordingURL.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCallRecordingURL.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCalledBy.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCalledBy.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCampaignDetailsLink.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCampaignDetailsLink.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCampaign.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCampaign.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutClicked.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutClicked.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCurrentCampaignStep.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutCurrentCampaignStep.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutDetails.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutDetails.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutEmailStatus.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutEmailStatus.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutEventsTracked.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutEventsTracked.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutLastEventAt.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutLastEventAt.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutPresentationViewed.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutPresentationViewed.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutRepliedAt.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutRepliedAt.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutReplied.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutReplied.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutSentBy.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutSentBy.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutTemplateDetails.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutTemplateDetails.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutTemplateUsed.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutTemplateUsed.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutTemplate.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutTemplate.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutViewed.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.activityFields.toutViewed.description',
        },
      ],
    },
    {
      category:
        'web.salesforceCustomization.wizard.items.fields.category.contactFields',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutCurrentCampaign.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutCurrentCampaign.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutLastActivity.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutLastActivity.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutLastEngagement.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutLastEngagement.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutLastReplied.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutLastReplied.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutOptOut.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.contactFields.toutOptOut.description',
        },
      ],
    },
    {
      category:
        'web.salesforceCustomization.wizard.items.fields.category.leadFields',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutCurrentCampaign.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutCurrentCampaign.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutLastActivity.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutLastActivity.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutLastEngagement.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutLastEngagement.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutLastReplied.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutLastReplied.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutOptOut.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.leadFields.toutOptOut.description',
        },
      ],
    },
    {
      category:
        'web.salesforceCustomization.wizard.items.fields.category.opportunityFields',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.opportunityFields.toutCurrentCampaign.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.opportunityFields.toutCurrentCampaign.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.opportunityFields.toutLastActivity.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.opportunityFields.toutLastActivity.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.opportunityFields.toutLastEngagement.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.opportunityFields.toutLastEngagement.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.fields.category.opportunityFields.toutLastReplied.name',
          description:
            'web.salesforceCustomization.wizard.items.fields.category.opportunityFields.toutLastReplied.description',
        },
      ],
    },
  ],
  [CUSTOMIZATION_OPTIONS_BUTTONS]: [
    {
      category:
        'web.salesforceCustomization.wizard.items.buttons.category.pageLayouts',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.buttons.category.pageLayouts.emailWithTout.name',
          description:
            'web.salesforceCustomization.wizard.items.buttons.category.pageLayouts.emailWithTout.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.buttons.category.pageLayouts.callWithTout.name',
          description:
            'web.salesforceCustomization.wizard.items.buttons.category.pageLayouts.callWithTout.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.buttons.category.pageLayouts.addToToutCampaign.name',
          description:
            'web.salesforceCustomization.wizard.items.buttons.category.pageLayouts.addToToutCampaign.description',
        },
      ],
    },
    {
      category:
        'web.salesforceCustomization.wizard.items.buttons.category.listViews',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.buttons.category.listViews.pushToTout.name',
          description:
            'web.salesforceCustomization.wizard.items.buttons.category.listViews.pushToTout.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.buttons.category.listViews.addToToutCampaign.name',
          description:
            'web.salesforceCustomization.wizard.items.buttons.category.listViews.addToToutCampaign.description',
        },
      ],
    },
  ],
  [CUSTOMIZATION_OPTIONS_OTHER]: [
    {
      category:
        'web.salesforceCustomization.wizard.items.other.category.reports',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.callAndEmailActivityAEs.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.callAndEmailActivityAEs.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.callAndEmailActivitySDR.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.callAndEmailActivitySDR.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.callsMade.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.callsMade.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.closedBusiness.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.closedBusiness.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.closedWonOpportunitiesThisQuarter.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.closedWonOpportunitiesThisQuarter.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailActivityAndTopOpportunities.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailActivityAndTopOpportunities.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailActivityWithClosedWonOpptys.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailActivityWithClosedWonOpptys.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailActivityWithOpenOpportunities.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailActivityWithOpenOpportunities.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailPerformanceMetricsAEs.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailPerformanceMetricsAEs.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailPerformanceMetricsSDRs.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.emailPerformanceMetricsSDRs.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.openOpportunitiesByStage.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.openOpportunitiesByStage.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.opportunitiesInThePipeline.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.opportunitiesInThePipeline.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.staleOpportunities.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.staleOpportunities.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.templatePerformance.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.templatePerformance.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.templatePerformanceAEs.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.templatePerformanceAEs.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.templatePerformanceSDRs.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.templatePerformanceSDRs.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.totalPipelineForCurrentQuarter.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.totalPipelineForCurrentQuarter.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.totalPipelineForNextQuarter.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.totalPipelineForNextQuarter.description',
        },
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.reports.toutCampaignPerformance.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.reports.toutCampaignPerformance.description',
        },
      ],
    },
    {
      category: 'web.salesforceCustomization.wizard.items.other.category.tabs',
      items: [
        {
          label:
            'web.salesforceCustomization.wizard.items.other.category.tabs.toutOutbox.name',
          description:
            'web.salesforceCustomization.wizard.items.other.category.tabs.toutOutbox.description',
        },
      ],
    },
  ],
};
