// Use API for response data
// Namespace state action types to module
// Namespace any other common data types to feature
// Name events the same as the chain of namespace to prevent name clashes

/* Please keep alphabetical order for simplicity */

export default {
  data: {
    activeCampaigns: 'COMMAND_CENTER_DATA_ACTIVE_CAMPAIGNS',
  },
  popup: {
    dataFetching: 'COMMAND_CENTER_POPUP_DATA_FETCHING',
  },
  sidebarMenu: {
    selectItem: 'SIDEBAR_MENU_SELECT_ITEM',
  },
  state: {
    changeViewAsFilter: 'COMMAND_CENTER_CHANGE_VIEW_AS_FILTER',
    initStore: 'COMMAND_CENTER_STATE_INIT_STORE',
    loading: 'COMMAND_CENTER_STATE_LOADING',
  },
};
