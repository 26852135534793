import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { deleteUser, deleteUsers } from '../actionCreators/usersActionCreators';
import {
  getDeleteUserLoading,
  getDeleteUsersLoading,
} from '../selectors/usersSelectors';
import UsersPopup from '../components/popups/usersPopup';
import { isAdmin, isMsiActions } from 'web/user/selectors/userSelectors';

class UsersPopupContainer extends Component {
  render() {
    return <UsersPopup {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  popup: state.popup,
  popupData: state.popupData,
  deleteUserLoading: getDeleteUserLoading(state),
  deleteUsersLoading: getDeleteUsersLoading(state),
  currentUserId: state.user.id,
  isAdmin: isAdmin(state),
  isMsiActions: isMsiActions(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      closePopup,
      deleteUser,
      deleteUsers,
      submitForm: submit,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPopupContainer);
