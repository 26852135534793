import { basePut, baseGet } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function makeUserNew(remind = false) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.onboarding.makeUserNew,
      { user: { new_user: true, remind } },
      resolve,
      reject
    );
  });
}

export function getDeliveryChannels() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.onboardingDeliveryChannels, {}, resolve, reject);
  });
}

export function getOnboardingAuthentications() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.onboardingAuthentications, {}, resolve, reject);
  });
}
