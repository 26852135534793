import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Appears } from 'libs/constantsShared';
import './infoBox.scss';

export default class InfoBox extends Component {
  constructor(props) {
    super(props);

    this.infoBoxRef = null;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    window.addEventListener('mousedown', this._handleOutsideClick, false);
    window.addEventListener('blur', this._handleIFrameClick);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this._handleOutsideClick, false);
    window.removeEventListener('blur', this._handleIFrameClick);
  }

  componentDidMount() {
    window.focus();
  }

  render() {
    const {
      align,
      appears,
      children,
      className,
      color,
      rounded,
      outsideClickHandler: ignored1,
      triggerRef: ignored2,
      closeAnywhere: ignored3,
      ...other
    } = this.props;

    const classes = classNames(
      'infoBox',
      appears || Appears.below,
      { [`align-${align}`]: align },
      className,
      color,
      rounded ? 'rounded' : null
    );

    return (
      <div className={classes} ref={this._setRef} {...other}>
        {children}
      </div>
    );
  }

  _setRef = (div) => {
    this.infoBoxRef = div;
  };

  _handleOutsideClick = (e) => {
    const { outsideClickHandler, triggerRef, closeAnywhere } = this.props;
    if (
      closeAnywhere ||
      (!this.infoBoxRef.contains(e.target) &&
        (!triggerRef || !triggerRef.contains || !triggerRef.contains(e.target)))
    ) {
      e.stopPropagation();
      outsideClickHandler(e.target);
    }
  };

  // CKEditor swallows clicks
  _handleIFrameClick = () => {
    const { outsideClickHandler } = this.props;
    if (document.activeElement.tagName === 'IFRAME') {
      outsideClickHandler();
    }
  };
}

InfoBox.propTypes = {
  outsideClickHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  closeAnywhere: PropTypes.bool,
  triggerRef: PropTypes.object,
  className: PropTypes.string,
  appears: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  rounded: PropTypes.bool,
};

InfoBox.defaultProps = {
  closeAnywhere: false,
  rounded: false,
  triggerRef: {},
  className: '',
  appears: '',
  align: '',
  color: '',
};
