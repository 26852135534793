import toPairs from 'lodash/toPairs';
import head from 'lodash/head';
import { setIdentitiesFetched } from 'web/user/actionCreators/identitiesActionCreators';
import { setDeliveryChannelsFetched } from 'web/deliveryChannels/actionCreators/deliveryChannelsActionCreators';
import {
  EmailServerPopupIds,
  EmailServerAlertIds,
} from '../libs/emailServerConstants';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  getTeamSmtpDeliveryChannel,
  deleteTeamSmtpDeliveryChannel,
  createTeamSmtpDeliveryChannel,
  updateTeamSmtpDeliveryChannel,
} from 'web/deliveryChannels/actionCreators/teamSmtpDeliveryChannelActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { getErrorMessage } from '../helpers/emailServerHelpers';

export const commonEmailServerSuccess = () => (dispatch) => {
  dispatch(setIdentitiesFetched(false));
  dispatch(setDeliveryChannelsFetched(false));
};

export const saveEmailServer = (model) => (dispatch, getState) => {
  const promise = getState().teamSmtpDeliveryChannel
    ? dispatch(updateTeamSmtpDeliveryChannel(model))
    : dispatch(createTeamSmtpDeliveryChannel(model));

  promise
    .then(() => {
      dispatch(commonEmailServerSuccess());
      dispatch(openViewAlert(EmailServerAlertIds.connected));
    })
    .catch((error) =>
      dispatch(openViewAlert(EmailServerAlertIds.error, getErrorMessage(error)))
    );
};

export const removeEmailServer = () => (dispatch) => {
  dispatch(deleteTeamSmtpDeliveryChannel())
    .then(() => {
      dispatch(commonEmailServerSuccess());
      dispatch(openViewAlert(EmailServerAlertIds.disconnected));
    })
    .catch((error) =>
      dispatch(openViewAlert(EmailServerAlertIds.error, getErrorMessage(error)))
    );
};

export const getEmailServer = () => (dispatch) => {
  dispatch(getTeamSmtpDeliveryChannel()).catch((error) =>
    dispatch(openViewAlert(EmailServerAlertIds.error, getErrorMessage(error)))
  );
};

export const showValidationErrorMessage = (errors) => (dispatch) => {
  const [field, validationError] = head(toPairs(errors));
  dispatch(
    openViewAlert(EmailServerAlertIds.validationError, {
      field,
      validationError,
    })
  );
};

export const openRemoveConfirmationPopup = () => (dispatch) => {
  dispatch(openPopup(EmailServerPopupIds.removeConfirmation));
};
