import { createSelector } from 'reselect';
import { CampaignsSpecialCategories } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { ConnectionStates } from 'web/marketoSettings/libs/marketoSettingsConstants';
import sortBy from 'lodash/sortBy';

const {
  all: { id: ALL_CATEGORY_ID },
} = CampaignsSpecialCategories;
const getToutCampaigns = (state) => state.campaigns;
const getCurrentCategoryId = (state) => state.addToCampaignModalCategory;
const getMarketoCampaigns = (state) => state.marketoCampaigns;
const getMarketoWorkspaces = (state) => state.marketoWorkspaces;

export const getSelectedWorkspace = createSelector(
  [getMarketoWorkspaces, getCurrentCategoryId],
  (marketoWorkspaces, selectedWorkspaceId) =>
    marketoWorkspaces.find(({ id }) => id === selectedWorkspaceId) || {}
);

export const getFilteredCampaigns = createSelector(
  [getToutCampaigns, getCurrentCategoryId],
  (campaigns, currentCategoryId) => {
    const filteredCampaigns = campaigns.filter((campaign) => {
      if (currentCategoryId === ALL_CATEGORY_ID || !currentCategoryId) {
        return campaign;
      } else {
        return campaign.category_id === currentCategoryId;
      }
    });

    return sortBy(filteredCampaigns, ({ name = '' }) => name.toLowerCase());
  }
);

export const getFilteredMarketoCampaigns = createSelector(
  [getMarketoCampaigns, getSelectedWorkspace],
  (marketoCampaigns, selectedWorkspace) => {
    const { name: workspaceName } = selectedWorkspace;
    const filteredCampaigns = marketoCampaigns[workspaceName] || [];

    return sortBy(filteredCampaigns, ({ name = '' }) => name.toLowerCase());
  }
);

export const isMarketoCampaignDisabled = (state) => {
  const { marketoAdminConnectionState } = state;
  return [
    ConnectionStates.noSalesforceConnection,
    ConnectionStates.disconnected,
  ].includes(marketoAdminConnectionState);
};
