import I18N from './i18N';

export default class L10nStyle {
  static init() {
    // Loading l10n style sheet for language specific
    if (I18N.currLanguage !== I18N.defaultLocale) {
      const lang = I18N.currLanguage.toLowerCase();
      require(`../styles/${lang}.scss`);
    }
  }
}

L10nStyle.init();
