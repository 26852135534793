/**
 *
 * FileIconCell
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AttachmentTypes } from 'libs/constantsShared';

import './fileIconCell.scss';

function FileIconCell({ className, onClick, property, rowData, style }) {
  const data = rowData[property];

  const containerClasses = classNames(className, 'cell-file-icon', {
    clickable: onClick,
  });

  function getTypeClasses(name) {
    const type = name.match(/\.([^.]+)$/);
    if (!type || !type.length) {
      return 'tout-icon-other';
    }

    switch (type[1]) {
      case AttachmentTypes.doc:
      case AttachmentTypes.docx:
        return 'tout-icon-doc';
      case AttachmentTypes.ppt:
      case AttachmentTypes.pptx:
        return 'tout-icon-ppt';
      case AttachmentTypes.jpg:
      case AttachmentTypes.png:
        return 'tout-icon-img';
      case AttachmentTypes.pdf:
        return 'tout-icon-pdf';
      case AttachmentTypes.xls:
        return 'tout-icon-excel';
      default:
        return 'tout-icon-other';
    }
  }

  return (
    <div className={containerClasses} style={style}>
      <div className={getTypeClasses(data)} />
    </div>
  );
}

FileIconCell.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  property: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  style: PropTypes.object,
};

FileIconCell.defaultProps = {
  onClick: null,
  className: '',
  style: {},
};

export default FileIconCell;
