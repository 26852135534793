import DynamicFieldsActionTypes from '../libs/dynamicFieldsActionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function dynamicFields(
  state = [],
  { type = '', dynamicFields: fields = [] }
) {
  switch (type) {
    case DynamicFieldsActionTypes.set:
      return fields;
    default:
      return state;
  }
}

export function dynamicFieldsMap(
  state = {},
  { type = '', dynamicFields: fields = [] }
) {
  switch (type) {
    case DynamicFieldsActionTypes.set: {
      const map = {};
      // {{xxx}} uses object as the key so have to strip brackets
      fields.forEach((field) => {
        const stripped = field.value.replace(/[{}]/g, '');
        map[stripped] = field;
      });
      return map;
    }
    default:
      return state;
  }
}

export function activityDynamicFieldsSalesforce(
  state = [],
  { type = '', dynamicFields = [] }
) {
  switch (type) {
    case DynamicFieldsActionTypes.setSalesforceActivityDynamicFields:
      return dynamicFields;
    default:
      return state;
  }
}

export function dynamicFieldsFetching(state = false, { type = '' }) {
  switch (type) {
    case DynamicFieldsActionTypes.fetching:
      return true;
    case DynamicFieldsActionTypes.fetchingDone:
      return false;
    default:
      return state;
  }
}

export const initDaysFromNow = '';
export const dynamicFieldsDaysFromNow = (
  state = initDaysFromNow,
  { type = '', days = initDaysFromNow }
) => {
  switch (type) {
    case DynamicFieldsActionTypes.setDaysFromNow:
      return days;
    case ActionTypes.emailComposeBody.state.popupClose:
    case PopupActionTypes.close:
      return initDaysFromNow;
    default:
      return state;
  }
};
