import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SyncSettingsAccordion from '../syncSettingsAccordion';
import './yourSyncSettingsCard.scss';

const YourSyncSettingsCard = (props) => {
  const { isApplyTeamSettings } = props;
  return (
    <div className="salesforce-your-sync-settings-card">
      <h3>
        <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.yourSettings.title" />
      </h3>
      {isApplyTeamSettings && (
        <div className="margin-top-20">
          <i className="tout-icon-tick" />
          <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.yourSettings.configuredByAdmin" />
        </div>
      )}
      <SyncSettingsAccordion {...props} />
    </div>
  );
};

YourSyncSettingsCard.propTypes = {
  isApplyTeamSettings: PropTypes.bool.isRequired,
};

export default YourSyncSettingsCard;
