import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Page from 'components/layouts/page/page';
import PageHeader from 'components/layouts/page/header';
import PageView from 'components/layouts/page/view';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import Cards from 'components/layouts/cards';
import { getCards } from 'web/shared/helpers/cards';
import BccContainer from '../containers/bccContainer';
import SlackContainer from '../containers/slackContainer';
import BoxContainer from '../containers/boxContainer';
import HighspotCard from './highspot';
import compact from 'lodash/compact';
import './integrationsPage.scss';

const IntegrationsPage = (props) => {
  const {
    isIntegrationContentSharingEnabled,
    canShowHighspotCard,
    isBoxIntegrationEnabled,
    isSlackIntegrationEnabled,
  } = props;
  const boxCard =
    isIntegrationContentSharingEnabled &&
    isBoxIntegrationEnabled &&
    BoxContainer;
  const highSpotCard = canShowHighspotCard && HighspotCard;
  const slackCard =
    isIntegrationContentSharingEnabled &&
    isSlackIntegrationEnabled &&
    SlackContainer;
  const cards = getCards(
    compact([boxCard, highSpotCard, slackCard, BccContainer]),
    props
  );
  return (
    <Page className="account-settings-user-integrations-page">
      <PageHeader textId="web.settings.integrations.title">
        <FormattedMessage id="web.settings.integrations.description" />
      </PageHeader>
      <PageView>
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={cards}
          className="admin-tracking-page-cards-wrapper"
          marginAround={FULL}
          marginBetween={FULL}
        />
      </PageView>
    </Page>
  );
};

IntegrationsPage.propTypes = {
  canShowHighspotCard: PropTypes.bool.isRequired,
  isBoxIntegrationEnabled: PropTypes.bool.isRequired,
  isIntegrationContentSharingEnabled: PropTypes.bool.isRequired,
  isSlackIntegrationEnabled: PropTypes.bool.isRequired,
};

export default IntegrationsPage;
