import React from 'react';
import PropTypes from 'prop-types';
import TasksTable from 'web/tables/tasks';
import { PersonDetailsTasksTableId } from 'web/person/libs/personDetailsConstants';
import { TasksTableColumns } from 'web/tables/tasks/libs/tablesTasksConstants';

const columns = [
  TasksTableColumns.markDone,
  TasksTableColumns.subject,
  TasksTableColumns.owner,
  TasksTableColumns.type,
  TasksTableColumns.due,
  TasksTableColumns.actions,
];

const TasksView = (props) => (
  <TasksTable
    {...props}
    columnFilter={false}
    columns={columns}
    pagination={false}
    showDateFilter={false}
    tableId={PersonDetailsTasksTableId}
  />
);

TasksView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isActionsDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  tasks: PropTypes.array.isRequired,
};

TasksView.defaultProps = {
  isActionsDisabled: false,
  loading: false,
};

export default TasksView;
