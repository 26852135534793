import OptInReasonsActionTypes from '../libs/optInReasonsActionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';

const initialState = null;

export const optInReasonsSelected = (
  state = initialState,
  { type = '', payload = initialState }
) => {
  switch (type) {
    case OptInReasonsActionTypes.changeOptInReason:
      return payload;

    case PopupActionTypes.close:
      return initialState;

    default:
      return state;
  }
};
