import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Tracking from '../components/tracking';
import { getActiveTab } from '../selectors/trackingSelectors';
import { navigateToAdminTrackingTab } from 'web/services/routerService';
import withAdminCheck from 'components/hocs/withAdminCheck';

const TrackingContainer = (props) => (
  <Tracking {...props} onTabSelect={navigateToAdminTrackingTab} />
);

const mapStateToProps = (state) => ({
  tab: getActiveTab(state),
  user: state.user,
});

export default compose(
  withAdminCheck,
  connect(mapStateToProps)
)(TrackingContainer);
