import React, { Component } from 'react';
import PeopleImportStepper from 'web/people/components/peopleImportStepper';
import './importPeopleStepOne.scss';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';

class ImportPeopleStepOne extends Component {
  containerRef = null;

  processSelectedFile = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const {
      target: {
        files: [file],
      },
    } = event;

    const { parseFileHeaders, isUserManagementPage } = this.props;
    parseFileHeaders(file, isUserManagementPage);
  };

  onFileDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const {
      dataTransfer: {
        files: [file],
      },
    } = event;

    this.props.parseFileHeaders(file);
    this.removeClassFromContainer();
  };

  onFileDragOver = (event) => {
    event.preventDefault();
    this.addClassToContainer();
  };

  addClassToContainer = () => {
    this.containerRef.classList.add('dragOver');
  };

  removeClassFromContainer = () => {
    this.containerRef.classList.remove('dragOver');
  };

  setContainerRef = (div) => {
    this.containerRef = div;
  };

  render() {
    const {
      intl: { formatMessage },
      importLocalFile,
    } = this.props;
    const fileName = importLocalFile && importLocalFile.name;

    return (
      <div className="step-one">
        <PeopleImportStepper activeStep={1} />
        <div
          className="dndContainer"
          ref={this.setContainerRef}
          onDrop={this.onFileDrop}
          onDragEnter={this.addClassToContainer}
          onDragOver={this.onFileDragOver}
          onDragExit={this.removeClassFromContainer}
          onDragLeave={this.removeClassFromContainer}
          onDragEnd={this.removeClassFromContainer}
        >
          <h2 className="file-upload-label">
            {formatMessage({ id: 'common.file' })}
          </h2>
          <div
            className="file-upload-wrapper"
            data-file-name={fileName}
            data-button-label={formatMessage({ id: 'common.browse' })}
          >
            <input
              name="file-upload-field"
              type="file"
              className="file-upload-field"
              onChange={this.processSelectedFile}
              accept=".csv"
            />
          </div>
          <p>
            <FormattedHTMLMessage id="web.people.importer.chooseFile" />
            <FormattedHTMLMessage id="web.people.importer.csvExampleNotice" />
          </p>
        </div>
      </div>
    );
  }
}

ImportPeopleStepOne.propTypes = {
  importLocalFile: PropTypes.object,
  intl: intlShape.isRequired,
  isUserManagementPage: PropTypes.bool,
  parseFileHeaders: PropTypes.func.isRequired,
};

ImportPeopleStepOne.defaultProps = {
  importLocalFile: null,
};

export default injectIntl(ImportPeopleStepOne);
