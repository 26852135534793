import AutoAppendActionTypes from '../libs/autoAppendActionTypes';

export const isAutoAppendContentFetching = (
  state = false,
  { type, fetching }
) => {
  switch (type) {
    case AutoAppendActionTypes.setFetching:
      return fetching;
    default:
      return state;
  }
};
