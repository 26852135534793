import moment from 'moment-timezone';
import {
  TimezonesCategories,
  TimezonesCategoriesOrderedEnum,
} from '../libs/timezonesConstants';
import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getAbbr(name = '', timezones = []) {
  let timezone;
  if (timezones.length >= 2) {
    timezone = timezones[
      TimezonesCategoriesOrderedEnum[TimezonesCategories.international]
    ].find((tz) => tz.displayName === name);
  }

  if (timezone) {
    return moment()
      .tz(timezone.value)
      .zoneAbbr();
  } else {
    return '';
  }
}

export const getTimezones = (id) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.timezones, {}, resolve, reject);
  });
