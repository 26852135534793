/* eslint-disable camelcase */
import startCase from 'lodash/startCase';
import { AddressTypes } from '../libs/personDetailsConstants';
import I18N from 'languages';
import { getInvalidCustomFieldAlert } from './personDetailsValidations';
import intersection from 'lodash/intersection';
import { SourceTypes } from 'web/compliance/libs/complianceConstants';
import { SUBSCRIPTION_PLAN_SLUG } from 'web/user/libs/userConstants';

export const getPrimaryAddress = (
  addresses = [],
  type = AddressTypes.email
) => {
  if (!addresses.length) {
    return {};
  }

  let primaryAddress;
  for (let i = 0; i < addresses.length; i++) {
    const address = addresses[i];

    if (address.address_type === type && address.is_primary) {
      primaryAddress = address;
      break;
    } else if (!primaryAddress && address.address_type === type) {
      primaryAddress = address;
    } else if (!primaryAddress && typeof address === 'string') {
      // Results from new Elasticsearch returns an array of strings
      if (address.includes('@')) {
        primaryAddress = { address };
      }
    }
  }

  return primaryAddress || {};
};

export function getApiKey(reference, type) {
  const marketoRefs = reference.find((ref) => ref.service === type);
  if (marketoRefs) {
    return marketoRefs.service_entity_id;
  }
  return null;
}

export function getPrimaryEmailAddress(person = {}) {
  return getPrimaryAddress(person.addresses, AddressTypes.email);
}

export function getPrimaryPhoneAddress(person = {}) {
  return getPrimaryAddress(person.addresses, AddressTypes.phone);
}

export function getPrimaryWebsiteAddress(person = {}) {
  return getPrimaryAddress(person.addresses, AddressTypes.website);
}

export function getPrimaryOtherAddress(person = {}) {
  return getPrimaryAddress(person.addresses, AddressTypes.other);
}

export function getFullName(first, last) {
  const given = first || ''; // handles both null & undefined
  const surname = last || '';
  const spacing = given && surname ? ' ' : '';

  return `${given}${spacing}${surname}`;
}

// TODO (MT): This will be removed in the next iteration in
// favor of a similar function in peopleHelpers.js
export function getMarketoLeadIdFromLeadFields(leadFields = []) {
  const marketoIdLeadField = leadFields.find(
    (leadField) => leadField.name == 'marketo_lead_id'
  );
  const marketoLeadId =
    marketoIdLeadField !== undefined ? marketoIdLeadField.value : null;

  return marketoLeadId;
}

export function formatName(first, last, email) {
  return (
    getFullName(first, last) ||
    email ||
    I18N.getStr('web.person.personDetails.noName')
  );
}

export function getPersonName(person = {}) {
  const firstName = person.first_name
    ? person.first_name
    : person.parent_name || '';
  const lastName = person.last_name ? person.last_name : '';
  const email = getPrimaryEmailAddress(person);
  return formatName(firstName, lastName, email.address);
}

export const getSalesforceId = (person = {}) => {
  const {
    salesforce_contact_id: contact_id,
    salesforce_lead_id: lead_id,
  } = person;
  return (
    (contact_id && { contact_id }) ||
    (lead_id && { lead_id }) || {
      email: getPrimaryEmailAddress(person).address,
    }
  );
};

export const isUnsubscribed = ({ unsubscribes = [] } = {}) =>
  unsubscribes.length > 0;
export const getUnsubscribe = ({ unsubscribes = [] } = {}) =>
  unsubscribes[0] || {};
export const isShared = ({ user_id = 0 }, currentUserId) =>
  user_id !== currentUserId;

export const validateCustomFields = (customFields) => {
  const alerts = [];

  customFields.forEach((field) => {
    const { name, value } = field;
    if (name || value) {
      const alert = getInvalidCustomFieldAlert(name, value, customFields);
      alert && alerts.push(alert);
    }
  });

  return alerts[0];
};

export const getUnsubscribeTypeString = (type) => {
  const unsubscribeType = startCase(type);
  switch (unsubscribeType) {
    case 'Email':
      return I18N.getStr('common.email');
    case 'Manual':
      return I18N.getStr('common.manual');
    default:
      return unsubscribeType;
  }
};

const isMsiActionsSubscription = (user) => {
  if (user) {
    return (
      user.subscription.subscription_plan_slug ===
      SUBSCRIPTION_PLAN_SLUG.msiActions
    );
  } else {
    return false;
  }
};

export const isTaskActionsDisabled = ({ groups, person, user }) => {
  const userGroupIds = groups.map((group) => group.id);
  let personGroupIds = [];
  if (person && person.group_members) {
    personGroupIds = person.group_members.map((group) => group.group_id);
  }
  const isSharedTeams = intersection(userGroupIds, personGroupIds).length > 0;

  if (isMsiActionsSubscription(user)) {
    return false;
  } else {
    return (
      user &&
      person &&
      person.user_id &&
      user.id !== person.user_id &&
      !isSharedTeams
    );
  }
};

export const formatSourceTypeText = {
  [SourceTypes.crmSync]: 'web.people.compliance.crmSync',
  [SourceTypes.import]: 'web.people.compliance.import',
  [SourceTypes.manualUpload]: 'web.people.compliance.manualUpload',
  [SourceTypes.chromeExtension]: 'web.people.compliance.chromeExtension',
  [SourceTypes.salesforce]: 'common.salesforce',
  [SourceTypes.marketing]: 'web.people.compliance.marketing',
  [SourceTypes.gmail]: 'common.gmail',
  [SourceTypes.o365]: 'web.people.compliance.o365',
  [SourceTypes.sales]: 'web.people.compliance.sales',
  [SourceTypes.other]: 'common.other',
};

export const getPrimaryEmailAddressDomain = (person = {}) => {
  if (person.addresses) {
    const { address } = getPrimaryEmailAddress(person);
    return address && address.split('@')[1];
  } else {
    return (
      person.primary_addresses &&
      person.primary_addresses.email &&
      person.primary_addresses.email.split('@')[1]
    );
  }
};
