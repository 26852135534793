import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IntegrationsAlertIds } from '../../libs/integrationConstants';
import Alert, { AlertColors } from 'components/alert';
import './integrationsAlerts.scss';

class IntegrationsAlerts extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      viewAlert: { id: alertId },
      closeViewAlert,
    } = this.props;

    const defaultAlert = {
      classes: 'integrations-alert',
      color: AlertColors.redDark,
      onClose: closeViewAlert,
    };

    switch (alertId) {
      case IntegrationsAlertIds.success:
        return {
          ...defaultAlert,
          color: AlertColors.greenDark,
          textId: 'common.alert.success',
        };
      case IntegrationsAlertIds.failure:
        return {
          ...defaultAlert,
          color: AlertColors.redDark,
          textId: 'common.alert.failure',
        };
      default:
        return null;
    }
  };
}

IntegrationsAlerts.propTypes = {
  closeViewAlert: PropTypes.func.isRequired,
  viewAlert: PropTypes.object.isRequired,
};

export default IntegrationsAlerts;
