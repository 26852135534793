import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { navigateToCommandCenterEmails } from 'web/services/routerService';
import Square from 'components/buttons/square';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';

const GroupEmailsHeader = ({ selectedGroupEmailName }) => (
  <span className="emails-advanced-search-title">
    <Square
      className="margin-left-half margin-right-half chevron-square-btn chevron-square-btn-left emails-advanced-search-title-backbtn"
      onClick={navigateToCommandCenterEmails}
    />
    <FormattedMessage id="web.commandCenter.advancedGroupEmails.title" />
    <span className="margin-left-half emails-advanced-search-title-name">
      {getLocalizedDateFromGroupName(selectedGroupEmailName)}
    </span>
  </span>
);

export default {
  Title: GroupEmailsHeader,
};

GroupEmailsHeader.propTypes = {
  selectedGroupEmailName: PropTypes.string.isRequired,
};
