import React, { Component } from 'react';
import Alert, { AlertColors } from 'components/alert';
import { EmailConnectionAlertIds } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import './emailConnectionFlowAlert.scss';

export default class EmailConnectionFlowAlert extends Component {
  render() {
    const alert = this._getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  _getAlert = () => {
    const {
      alert: { id },
      actionCreators: { closeViewAlert },
    } = this.props;

    const defaultAlert = {
      classes: 'email-connection-flow-alert',
      color: AlertColors.red,
      onClose: closeViewAlert,
    };

    switch (id) {
      case EmailConnectionAlertIds.form.missingEmail:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.form.missingEmail',
        };
      case EmailConnectionAlertIds.form.missingPassword:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.form.missingPassword',
        };
      case EmailConnectionAlertIds.form.missingUrl:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.form.missingUrl',
        };
      case EmailConnectionAlertIds.form.emailConnectionFailure:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.genericError',
        };
      case EmailConnectionAlertIds.form.invalidCredentials:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.form.invalidCredentials',
        };
      case EmailConnectionAlertIds.form.invalidUrlFormat:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailConnection.alert.form.invalidUrlFormat',
        };
      default:
        return null;
    }
  };
}
