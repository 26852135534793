import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BulkEmails } from 'web/composeWindow/libs/composeWindowConstants';
import BulkEmailsIconCell from 'web/composeWindow/components/bulkEmailsIconCell';
import PreviewDynamicFields from 'web/composeWindow/components/previewDynamicFields';
import EmailTextCell from 'web/composeWindow/components/emailTextCell';
import StatusCell from 'web/composeWindow/components/statusCell';
import ToutTable from 'table/modules/toutTable';
import throttle from 'lodash/throttle';
import './bulkEmailsTable.scss';

const ROW_HEIGHT = 44;

const BulkEmailsTable = (props) => {
  const {
    currentlySelectedRecipient,
    endingWindow,
    group,
    handleSelectAllRecipients,
    intl: { formatMessage },
    liquifyDynamicFields,
    recipients,
    removeAllRecipients,
    removeRecipient,
    resetSlidingWindow,
    selectCurrentRecipient,
    setSlidingWindow,
    sortRecipients,
    startingWindow,
  } = props;

  const [filteredRecipients, setFilteredRecipients] = useState(recipients);
  const [filterValue, setFilterValue] = useState(BulkEmails.filterValues.all);

  const tableRef = useRef(null);

  useEffect(
    () => {
      if (filterValue !== BulkEmails.filterValues.all) {
        setFilteredRecipients(
          recipients.filter((recipient) => {
            if (filterValue === BulkEmails.stateReason.unsubscribed) {
              return (
                recipient.state === BulkEmails.stateReason.unsubscribed ||
                recipient.state === BulkEmails.stateReason.blocked
              );
            } else if (
              filterValue === BulkEmails.stateReason.invalidDynamicFields
            ) {
              return (
                recipient.state === BulkEmails.stateReason.invalidDynamicFields
              );
            } else {
              return true;
            }
          })
        );
        resetSlidingWindow();
      } else {
        setFilteredRecipients(recipients);
      }
    },
    [recipients, filterValue]
  );

  const zeroState = {
    bodyText: formatMessage({
      id: 'web.composeWindow.bulkEmails.zeroStateBody',
    }),
    titleText: formatMessage({
      id: 'web.composeWindow.bulkEmails.zeroStateTitle',
    }),
  };

  const columns = [
    {
      id: 'warning-icon',
      rowCell: {
        component: BulkEmailsIconCell,
        metadata: {
          iconType: BulkEmails.iconTypes.warning,
          property: 'state',
          tooltipId: BulkEmails.tooltipIds.warning,
        },
      },
      width: 25,
    },
    {
      id: 'email_to',
      name: formatMessage({ id: 'web.composeWindow.bulkEmails.table.emailTo' }),
      rowCell: { component: EmailTextCell, metadata: { property: 'name' } },
      width: 185,
    },
    {
      id: 'state',
      name: formatMessage({ id: 'common.status' }),
      rowCell: {
        component: StatusCell,
        metadata: { property: 'state', tooltipId: BulkEmails.tooltipIds.state },
      },
      sorting: (columnId, direction) => {
        sortRecipients(direction);
      },
      width: 120,
    },
    {
      id: 'delete',
      rowCell: {
        component: BulkEmailsIconCell,
        metadata: {
          iconType: group ? null : BulkEmails.iconTypes.delete,
          removeRecipient,
          tooltipId: BulkEmails.tooltipIds.delete,
        },
      },
    },
  ];

  const smartFilters = [
    {
      label: formatMessage({
        id: 'web.composeWindow.bulkEmails.filter.allRecipients',
      }),
      value: BulkEmails.filterValues.all,
    },
    {
      label: formatMessage({
        id: 'web.composeWindow.bulkEmails.filter.unsubscribed',
      }),
      value: BulkEmails.stateReason.unsubscribed,
    },
    {
      label: formatMessage({
        id: 'web.composeWindow.bulkEmails.filter.errors',
      }),
      value: BulkEmails.stateReason.invalidDynamicFields,
    },
  ];

  const classes = classNames('bulk-emails-table', {
    'all-selected': !currentlySelectedRecipient,
    'bulk-emails-zero-state': recipients.length === 0,
  });

  const staticRowClasses = classNames('bulk-emails-table-row-static', {
    'all-selected': !currentlySelectedRecipient,
  });

  const throttleTime = 1000;
  const throttledHandleScroll = throttle(handleScroll, throttleTime, {
    trailing: false,
  });

  return (
    <div className={classes} onWheel={throttledHandleScroll}>
      {recipients.length > 0 && (
        <div className={staticRowClasses} onClick={handleSelectAllRecipients}>
          <div className="group-icon-cell">
            {group && <i className="group-icon" />}
          </div>
          <div className="email-to-header">
            {group ? (
              <div className="group-overflow">{getGroupHeader(group)}</div>
            ) : (
              <FormattedMessage id="web.composeWindow.bulkEmails.filter.allRecipients" />
            )}
          </div>
          <div className="state-header">
            <FormattedMessage
              id="web.composeWindow.bulkEmails.undeliverable"
              values={{ count: recipientErrorsCount(recipients) }}
            />
          </div>
          <div className="icon-header" onClick={onRemoveAllClick}>
            <i className="tout-icon-close" />
          </div>
        </div>
      )}
      <ToutTable
        columns={columns}
        items={recipients}
        pagination={{ perPage: recipients.length }}
        row={{ onClick: selectCurrentRecipient }}
        smartFilters={getSmartFilters()}
        tableId="compose-bulk"
        tableRef={tableRef}
        zeroState={zeroState}
        highlightOnClick
        left
      />
      <PreviewDynamicFields
        disabled={recipients.length === 0}
        onClick={liquifyDynamicFields}
      />
    </div>
  );

  function onRemoveAllClick(e) {
    e.stopPropagation();
    e.preventDefault();

    removeAllRecipients();
  }

  function getSmartFilters() {
    return {
      filters: smartFilters,
      label: formatMessage({ id: 'web.composeWindow.bulkEmails.filter.label' }),
      onChange: onSmartFilterChange,
      selectedValue: filterValue,
      width: 250,
    };
  }

  function onSmartFilterChange(index, smartFilterValue) {
    setFilterValue(smartFilterValue);
  }

  function recipientErrorsCount(recipients) {
    const errors = recipients.filter(
      (recipient) =>
        recipient.state && recipient.state !== BulkEmails.stateReason.success
    );
    return errors.length;
  }

  function getGroupHeader(group) {
    const groupText = `${group.name} (${group.people_ids.length})`;
    return <span title={groupText}>{groupText}</span>;
  }

  function handleScroll(e) {
    const scrollDirection = e.deltaY;
    const tableBody = tableRef && tableRef.current.wrappedInstance;
    const {
      tableBodyRef: { scrollTop, scrollHeight, clientHeight },
    } = tableBody;

    if (filteredRecipients.length < BulkEmails.slidingWindow.max) {
      return;
    }

    if (scrollTop + clientHeight >= scrollHeight - ROW_HEIGHT) {
      if (scrollDirection > 0) {
        slideViewDown(tableBody);
      }
    } else if (scrollTop === 0) {
      if (scrollDirection < 0) {
        slideViewUp(tableBody);
      }
    }
  }

  function slideViewUp(tableBody) {
    const {
      tableBodyRef: { scrollTop },
    } = tableBody;

    if (startingWindow - BulkEmails.slidingWindow.shift < 0) {
      resetSlidingWindow();
    } else {
      tableBody.tableBodyRef.scrollTop =
        scrollTop + ROW_HEIGHT * BulkEmails.slidingWindow.shift;
      setSlidingWindow({
        endingWindow: endingWindow - BulkEmails.slidingWindow.shift,
        startingWindow: startingWindow - BulkEmails.slidingWindow.shift,
      });
    }
  }

  function slideViewDown(tableBody) {
    const {
      tableBodyRef: { scrollTop },
    } = tableBody;

    if (
      endingWindow + BulkEmails.slidingWindow.shift <
      filteredRecipients.length
    ) {
      setSlidingWindow({
        endingWindow: endingWindow + BulkEmails.slidingWindow.shift,
        startingWindow: startingWindow + BulkEmails.slidingWindow.shift,
      });
      tableBody.tableBodyRef.scrollTop =
        scrollTop - ROW_HEIGHT * BulkEmails.slidingWindow.shift;
    } else {
      setSlidingWindow({
        endingWindow: filteredRecipients.length,
        startingWindow:
          filteredRecipients.length - BulkEmails.slidingWindow.max,
      });
      const additional = filteredRecipients.length - endingWindow;
      tableBody.tableBodyRef.scrollTop = scrollTop - ROW_HEIGHT * additional;
    }
  }
};

BulkEmailsTable.propTypes = {
  currentlySelectedRecipient: PropTypes.object,
  endingWindow: PropTypes.number.isRequired,
  group: PropTypes.object,
  handleSelectAllRecipients: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  liquifyDynamicFields: PropTypes.func.isRequired,
  recipients: PropTypes.array,
  removeAllRecipients: PropTypes.func.isRequired,
  removeRecipient: PropTypes.func.isRequired,
  resetSlidingWindow: PropTypes.func.isRequired,
  selectCurrentRecipient: PropTypes.func.isRequired,
  setSlidingWindow: PropTypes.func.isRequired,
  sortRecipients: PropTypes.func.isRequired,
  startingWindow: PropTypes.number.isRequired,
};

BulkEmailsTable.defaultProps = {
  currentlySelectedRecipient: undefined,
  group: undefined,
  recipients: [],
};

export default injectIntl(BulkEmailsTable);
