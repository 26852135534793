import EmailConnectionActionTypes from 'web/settings/emailConnection/lib/emailConnectionActionTypes';
import {
  EmailProviders,
  ExchangeOnpremFormDefault,
} from 'web/settings/emailConnection/lib/emailConnectionConstants';

export const emailConnectionModal = (state = '', { type, modal = '' }) => {
  switch (type) {
    case EmailConnectionActionTypes.modal.set:
      return modal;
    case EmailConnectionActionTypes.modal.clear:
      return '';
    default:
      return state;
  }
};

export const emailConnectionProvider = (
  state = EmailProviders.default,
  { type, provider = EmailProviders.default }
) => {
  switch (type) {
    case EmailConnectionActionTypes.provider.set:
      return provider;
    case EmailConnectionActionTypes.provider.reset:
      return EmailProviders.default;
    default:
      return state;
  }
};

export const emailConnectionAuthFetched = (
  state = false,
  { type, fetched = false }
) => {
  switch (type) {
    case EmailConnectionActionTypes.fetched:
      return fetched;
    default:
      return state;
  }
};

export const emailConnectionAuth = (state = {}, { type, auth = {} }) => {
  switch (type) {
    case EmailConnectionActionTypes.auth.set:
      return auth;
    case EmailConnectionActionTypes.auth.clear:
      return {};
    default:
      return state;
  }
};

const initialLoadingStates = {
  emailConnectionFetching: false,
  isSavingChanges: false,
  isDisconnecting: false,
};

export const emailLoadingStates = (
  state = initialLoadingStates,
  { type, loading }
) => {
  switch (type) {
    case EmailConnectionActionTypes.fetching:
    case EmailConnectionActionTypes.isDisconnecting:
    case EmailConnectionActionTypes.savingChanges: {
      return {
        ...state,
        ...loading,
      };
    }
    default:
      return state;
  }
};

export const onpremForm = (
  state = ExchangeOnpremFormDefault,
  { type = '', formValues }
) => {
  switch (type) {
    case EmailConnectionActionTypes.onpremForm.set:
      return { ...state, ...formValues };
    case EmailConnectionActionTypes.onpremForm.reset:
      return ExchangeOnpremFormDefault;
    default:
      return state;
  }
};
