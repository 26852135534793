import { updateIdentity as updateIdentityCall } from 'web/user/services/identitiesService';
import { createVerification as createVerificationCall } from 'web/user/services/verificationsService';
import {
  openPopup,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  getIdentities,
  removeIdentity,
} from 'web/user/actionCreators/identitiesActionCreators';
import IdentitiesTableActionTypes from '../libs/identitiesTableActionTypes';
import { IdentitiesPopupIds } from '../libs/identitiesTableConstants';
import GmailIntegrationActionTypes from 'web/emailIntegration/gmail/libs/gmailIntegrationActionTypes';
import {
  navigateToUrl,
  navigateToEditIdentityDeliverability,
} from 'web/services/routerService';
import { Urls } from 'web/libs/routes';

export const setLoading = (loading = true) => ({
  type: IdentitiesTableActionTypes.setLoading,
  loading,
});

export const showAlert = () => ({
  type: IdentitiesTableActionTypes.showAlert,
});

export const clearAlert = () => ({
  type: IdentitiesTableActionTypes.clearAlert,
});

export const openIdentityResendCompletePopup = () => (dispatch) => {
  dispatch(openPopup(IdentitiesPopupIds.identityResendComplete));
};

export const openResendConfirmationPopup = (identity) => (dispatch) => {
  dispatch(openPopup(IdentitiesPopupIds.identityResendConfirmation, identity));
};

export const openIdentityMakeDefaultErrorPopup = () => (dispatch) => {
  dispatch(openPopup(IdentitiesPopupIds.identityMakeDefaultError));
};

export const openIdentityRemovePopup = (identity) => (dispatch) => {
  dispatch(openPopup(IdentitiesPopupIds.identityDelete, identity));
};

export const navigateToSmtpServer = () => {
  navigateToUrl(Urls.smtpServerSettingsTab);
};

export const navigateToEmailConnection = () => {
  navigateToUrl(Urls.emailConnectionTab);
};

export const navigateToDeliverabilitySection = ({ id }) => {
  navigateToEditIdentityDeliverability(id);
};

export const sendVerifyEmail = (indetity) => (dispatch) => {
  dispatch(closePopup());
  dispatch(setLoading());
  createVerificationCall(indetity.id)
    .then(() => {
      dispatch(setLoading(false));
      dispatch(openIdentityResendCompletePopup());
    })
    .catch(() => {
      dispatch(showAlert());
    });
};

export const makeDefault = (identity) => (dispatch) => {
  dispatch(setLoading());
  updateIdentityCall(identity, true)
    .then(() => dispatch(getIdentities()))
    .then(() => {
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(showAlert());
    });
};

export const removeItem = (identity) => (dispatch) => {
  dispatch(closePopup());
  dispatch(setLoading());
  dispatch(removeIdentity(identity.id))
    .then(() => {
      dispatch(setLoading(false));
      dispatch({
        // after removing identity with gmail channel - integration could be affected and need to re-fetch data
        type: GmailIntegrationActionTypes.set,
        fetched: false,
      });
    })
    .catch(() => {
      dispatch(showAlert());
    });
};
