import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import SidebarList from 'web/shared/components/sidebar/sidebarList/sidebarList';
import { initSavedAdvancedSearches } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/saveAdvancedSearchActionCreators';
import { openSavedAdvancedSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/openAdvancedSearchActionCreators';
import { openDeleteSearchPopup } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/deleteAdvancedSearchActionCreators';
import { openRenameSearchPopup } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/renameAdvancedSearchActionCreators';
import { getSavedAdvancedSearchesSidebarList } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';

class CommandCenterSidebarSaveSearchesContainer extends Component {
  constructor(props) {
    super(props);

    const { initSavedAdvancedSearches } = this.props.actionCreators;
    initSavedAdvancedSearches();
  }

  render() {
    const {
      actionCreators: { openDeleteSearchPopup, openRenameSearchPopup },
      savedAdvancedSearchesSidebarList,
      emailSelectedSavedAdvancedSearchId,
      intl: { formatMessage },
    } = this.props;

    const list = {
      editingItemId: -1,
      isItemAddHidden: true,
      itemOptions: [
        {
          action: openDeleteSearchPopup,
          label: formatMessage({ id: 'common.delete' }),
        },
        {
          action: openRenameSearchPopup,
          label: formatMessage({ id: 'common.rename' }),
        },
      ],
      listData: savedAdvancedSearchesSidebarList,
      listLoading: false,
      listName: formatMessage({
        id: 'web.commandCenter.advancedSearchesSaved',
      }),
      onSelect: this.onSideBarItemSelect,
      selectedListItem: emailSelectedSavedAdvancedSearchId,
    };

    return (
      savedAdvancedSearchesSidebarList.length > 0 && <SidebarList {...list} />
    );
  }

  onSideBarItemSelect = (selectedId) => {
    const { actionCreators } = this.props;
    actionCreators.openSavedAdvancedSearch(selectedId);
  };
}

CommandCenterSidebarSaveSearchesContainer.propTypes = {
  emailSelectedSavedAdvancedSearchId: PropTypes.number,
  savedAdvancedSearchesSidebarList: PropTypes.array,
};

CommandCenterSidebarSaveSearchesContainer.defaultProps = {
  savedAdvancedSearchesSidebarList: [],
};

const mapStateToProps = (state) => ({
  emailSelectedSavedAdvancedSearchId: state.emailSelectedSavedAdvancedSearchId,
  savedAdvancedSearchesSidebarList: getSavedAdvancedSearchesSidebarList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      initSavedAdvancedSearches,
      openDeleteSearchPopup,
      openRenameSearchPopup,
      openSavedAdvancedSearch,
    },
    dispatch
  );

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  injectIntl
)(CommandCenterSidebarSaveSearchesContainer);
