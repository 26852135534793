import sortBy from 'lodash/sortBy';

export const sortGroups = (groups = []) =>
  sortBy(groups, ({ name = '' }) => name.toLowerCase());

export const getGroupsWithMembers = (groups = []) =>
  groups.reduce((prev, curr) => {
    if (curr.num_members > 0) {
      prev.push(curr);
    }
    return prev;
  }, []);
