import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SyncSettingsPage from '../syncSettingsPage';
import LoadingSpinner from 'components/loadingSpinner';
import SafelyChangeRouteContainer from 'web/safelyChangeRoute/containers/safelyChangeRouteContainer';
import { getImageUrl } from 'web/libs/constants';
import './syncSettings.scss';

const SyncSettings = (props) => {
  const { loading, isSafeToChangeRoute } = props;

  return (
    <Fragment>
      {loading && <LoadingSpinner imageUrl={getImageUrl} />}
      <SyncSettingsPage {...props} />
      <SafelyChangeRouteContainer isSafeToChangeRoute={isSafeToChangeRoute} />
    </Fragment>
  );
};

SyncSettings.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isSafeToChangeRoute: PropTypes.bool.isRequired,
};

export default SyncSettings;
