export default {
  // Grouped Events
  api: {
    autocompleteFailure: 'AUTOCOMPLETE_API_FAILURE',
    autocompleteRequest: 'AUTOCOMPLETE_API_REQUEST',
    autocompleteSuccess: 'AUTOCOMPLETE_API_SUCCESS',
    campaignMarkSuccessSuccess: 'CAMPAIGN_MARK_SUCCESS_API_SUCCESS',
    campaignRemoveSuccess: 'CAMPAIGN_REMOVE_API_SUCCESS',
    campaignUserDetailsFailure: 'CAMPAIGN_USER_DETAILS_API_FAILURE',
    campaignUserDetailsSuccess: 'CAMPAIGN_USER_DETAILS_API_SUCCESS',
    campaignsFailure: 'CAMPAIGNS_API_FAILURE',
    campaignsSuccess: 'CAMPAIGNS_API_SUCCESS',
    contentDeleteSuccess: 'CONTENT_COLLECTION_DELETE_API_SUCCESS',
    contentFailure: 'CONTENT_API_FAILURE',
    contentSuccess: 'CONTENT_API_SUCCESS',
    engConnectionSuccess: 'ENG_CONNECTION_API_SUCCESS',
    getCalendarEventsSuccess: 'GET_CALENDAR_EVENTS_API_SUCCESS',
    linkifyFailure: 'LINKIFY_API_FAILURE',
    linkifyRequest: 'LINKIFY_API_REQUEST',
    linkifySuccess: 'LINKIFY_API_SUCCESS',
    salesforceCheckFailure: 'SALESFORCE_CHECK_API_FAILURE',
    salesforceCheckRequest: 'SALESFORCE_CHECK_API_REQUEST',
    salesforceCheckSuccess: 'SALESFORCE_CHECK_API_SUCCESS',
    salesforceLogFailure: 'SALESFORCE_LOG_API_FAILURE',
    salesforceLogRequest: 'SALESFORCE_LOG_API_REQUEST',
    salesforceLogSuccess: 'SALESFORCE_LOG_API_SUCCESS',
    saveAsTemplateChange: 'SAVE_AS_TEMPLATE_CHANGE',
    saveAsTemplateFailure: 'SAVE_AS_TEMPLATE_API_FAILURE',
    saveAsTemplateRequest: 'SAVE_AS_TEMPLATE_API_REQUEST',
    saveAsTemplateSuccess: 'SAVE_AS_TEMPLATE_API_SUCCESS',
    taskDestroyFailure: 'TASK_DESTROY_API_FAILURE',
    taskDestroySuccess: 'TASK_DESTROY_API_SUCCESS',
    taskUpdateFailure: 'TASK_UPDATE_API_FAILURE',
    taskUpdateSuccess: 'TASK_UPDATE_API_SUCCESS',
    tasksCompletedSuccess: 'TASKS_COMPLETED_API_SUCCESS',
    tasksOverdueSuccess: 'TASKS_OVERDUE_API_SUCCESS',
    tasksUncompletedSuccess: 'TASKS_UNCOMPLETED_API_SUCCESS',
    templateCategoriesFailure: 'TEMPLATE_CATEGORIES_FAILURE',
    templateCategoriesSuccess: 'TEMPLATE_CATEGORIES_SUCCESS',
    templateInfoFailure: 'TEMPLATE_INFO_API_FAILURE',
    templateInfoSuccess: 'TEMPLATE_INFO_API_SUCCESS',
    templatesFailure: 'TEMPLATES_API_FAILURE',
    templatesFavoritesFailure: 'TEMPLATES_FAVORITES_API_FAILURE',
    templatesFavoritesSuccess: 'TEMPLATES_FAVORITES_API_SUCCESS',
    templatesLoading: 'TEMPLATES_LOADING',
    templatesSuccess: 'TEMPLATES_API_SUCCESS',
    timezonesFailure: 'TIMEZONES_API_FAILURE',
    timezonesSuccess: 'TIMEZONES_API_SUCCESS',
    // toutItFailure: 'TOUTIT_API_FAILURE',  // use for stage Tout It
    // toutItRequest: 'TOUTIT_API_REQUEST',
    // toutItSuccess: 'TOUTIT_API_SUCCESS',
    userAccountSuccess: 'USER_ACCOUNT_API_SUCCESS',
    userMetricSuccess: 'USER_METRIC_API_SUCCESS',
    userStatusSuccess: 'USER_STATUS_API_SUCCESS',
  },
  appSettings: {
    set: 'APP_SETTINGS_SET',
  },
  autocomplete: {
    clear: 'AUTOCOMPLETE_CLEAR',
  },
  composeState: {
    set: 'COMPOSE_STATE_SET',
  },
  error: {
    getCampaignInfo: 'ERROR_GET_CAMPAIGN_INFO',
  },
  mode: {
    compose: 'MODE_COMPOSE',
    read: 'MODE_READ',
  },
  popup: {
    addToCompaignClose: 'ADD_TO_COMPAIGN_CLOSE',
    addToCompaignOpen: 'ADD_TO_COMPAIGN_OPEN',
    close: 'POPUP_CLOSE',
    open: 'POPUP_OPEN',
  },
  readState: {
    set: 'READ_STATE_SET',
  },
  task: {
    completedDateChange: 'TASK_COMPLETED_DATE_CHANGE',
    destroy: 'TASK_DESTROY',
    loading: 'TASK_LOADING',
    overdueDateChange: 'TASK_OVERDUE_DATE_CHANGE',
    uncompletedDateChange: 'TASK_UNCOMPLETED_DATE_CHANGE',
    update: 'TASK_UPDATE',
  },
  toutIt: {
    campaignId: 'TOUTIT_CAMPAIGN_ID',
    contentDelete: 'TOUTIT_CONTENT_DELETE',
    contentSelected: 'TOUTIT_CONTENT_SELECTED',
    contentSelectedAll: 'TOUTIT_CONTENT_SELECTED_ALL',
    contentSelectedWipe: 'TOUTIT_CONTENT_SELECTED_WIPE',
    failure: 'TOUTIT_FAILURE',
    request: 'TOUTIT_REQUEST',
    scheduleLaterSelected: 'TOUTIT_SCHEDULE_LATER_SELECTED',
    success: 'TOUTIT_SUCCESS',
    updateRecommendedEmail: 'TOUTIT_UPDATE_RECOMMENDED_EMAIL',
    updateRecommendedTemplates: 'TOUTIT_UPDATE_RECOMMENDED_TEMPLATES',
  },
  upload: {
    progressUpdate: 'UPLOAD_PROGRESS_UPDATE',
    starting: 'UPLOAD_STARTING',
    success: 'UPLOAD_SUCCESS',
  },
  user: {
    csrfTokenRegistered: 'USER_CSRF_TOKEN_REGISTERED',
    gmailNotConnected: 'USER_GMAIL_NOT_CONNECTED',
  },
};
