import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ComposeEmailPopup from '../components/composeEmailPopup';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { resendVerificationMail } from '../actionCreators/composeEmailActionCreators';
import { navigateToSmtpServer } from 'web/settings/identitiesTable/actionCreators/identitiesTableActionCreators';

class ComposeEmailContainer extends Component {
  render() {
    return <ComposeEmailPopup {...this.props} />;
  }
}

ComposeEmailContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  popup: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  popup: state.popup,
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        closePopup,
        resendVerificationMail,
      },
      dispatch
    ),
    navigateToSmtpServer,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeEmailContainer);
