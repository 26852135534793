import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AuthorizationEditing from 'web/person/components/personDetails/complianceCard/editing/authorizationEditing';
import './authorizationPopupBody.scss';

class AuthorizationPopupBody extends Component {
  constructor() {
    super();
    this.labelClassName = 'authorization-modal-field';
  }

  render() {
    const { complianceEditState, updateAuthorization } = this.props;

    return (
      <div className="authorization-edit">
        <p>
          <FormattedMessage id="web.people.compliance.modal.authorizationPrompt" />
        </p>
        <AuthorizationEditing
          complianceEditState={complianceEditState}
          labelClassName={this.labelClassName}
          updateCompliance={updateAuthorization}
          sideLabel={false}
        />
      </div>
    );
  }
}

AuthorizationPopupBody.propTypes = {
  complianceEditState: PropTypes.object.isRequired,
  updateAuthorization: PropTypes.func.isRequired,
};

export default AuthorizationPopupBody;
