import { deletePerson } from 'web/people/services/peopleService';
import {
  closePopup,
  setPopupLoading,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export const deletePeople = (peopleIds) => (dispatch) => {
  dispatch(setPopupLoading(true));

  const promises = peopleIds.map((id) => deletePerson(id));

  Promise.all(promises)
    .then(() => {
      dispatch(closePopup());
      toutBackboneHelper.refreshPeopleTable();
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.genericApiError));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};
