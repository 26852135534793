import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';

export function imageInsertEditorName(state = '', action) {
  switch (action.type) {
    case ActionTypes.imageInsert.state.editorName:
      return action.data;
    case PopupActionTypes.close:
      return '';
    default:
      return state;
  }
}

export function imageInsertFile(state = null, action) {
  switch (action.type) {
    case ActionTypes.imageInsert.state.uploadStarting:
      return action.data;
    case ActionTypes.imageInsert.state.uploadProgressUpdate:
    case ActionTypes.imageInsert.state.uploadSuccess:
      return { ...state, ...action.data };
    case PopupActionTypes.close:
      return null;
    default:
      return state;
  }
}

export const initEditState = {
  height: '0',
  link: '',
  name: '',
  width: '0',
  url: '',
};
export function imageInsertEditState(state = null, action) {
  switch (action.type) {
    case ActionTypes.imageInsert.state.initEditState:
      return { ...initEditState, ...action.data };
    case ActionTypes.imageInsert.state.change:
      return { ...state, ...action.data };
    case PopupActionTypes.close:
      return null;
    default:
      return state;
  }
}
