import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './sidebarList.scss';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import Icon, {
  IconColors,
  IconBackgroundColors,
} from 'components/buttons/icon';
import SidebarListItem, { DndSidebarListItem } from './sidebarListItem';
import Shapes from 'web/shared/components/sidebar/sidebarShapes';
import { getImageUrl } from 'web/libs/constants';
import { changed } from 'web/services/utils/changed';
import classNames from 'classnames';

class SidebarList extends Component {
  constructor() {
    super();
    this.updateProps = [
      'listData',
      'listLoading',
      'selectedListItem',
      'editingItemId',
    ];
    this.sidebarListRef = null;
  }

  shouldComponentUpdate(nextProps) {
    return changed(this.updateProps, this.props, nextProps);
  }

  render() {
    const {
      listData,
      editingItemId,
      itemOptions,
      listLoading,
      onEdit,
      onEditCancel,
      onSelect,
      selectedListItem,
      iconOptions,
      draggable,
    } = this.props;
    const categoryName = this._getCategoryName();
    const categoryAdd = this._getCategoryAdd();
    const ListItem = draggable ? DndSidebarListItem : SidebarListItem;

    return (
      <div className="tout-ui-sidebar-list-container">
        <div className="tout-ui-sidebar-category-header">
          {categoryName}
          {categoryAdd}
        </div>
        <div className="tout-ui-sidebar-list-wrapper" ref={this.setSidebarRef}>
          <div className="tout-ui-sidebar-list">
            {listLoading ? (
              <LoadingSpinner imageUrl={getImageUrl} />
            ) : (
              listData.map(({ id, name, enabledDotsMenu = true }) => {
                const componentClasses = classNames(
                  'tout-ui-sidebar-list-item',
                  {
                    active: selectedListItem === id,
                  }
                );
                const componentIconOptions = {
                  ...iconOptions,
                  disabled: !iconOptions.disabled || iconOptions.disabled(id),
                };
                return (
                  <ListItem
                    classes={componentClasses}
                    elementId={id}
                    iconOptions={componentIconOptions}
                    isEditing={editingItemId === id}
                    itemOptions={id > 0 && enabledDotsMenu ? itemOptions : null}
                    key={`sidebar-list-item-${id}`}
                    label={name}
                    onEdit={onEdit}
                    onEditCancel={onEditCancel}
                    onSelect={onSelect}
                    scrollRef={this.sidebarListRef}
                    showTooltip
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  }

  setSidebarRef = (div) => {
    this.sidebarListRef = div;
  };

  _getCategoryName = () => {
    const { listName } = this.props;
    return (
      listName && (
        <h5 className="sidebar-list-header">{listName.toUpperCase()}</h5>
      )
    );
  };

  _getCategoryAdd = () => {
    const {
      listName,
      onItemAddClick,
      onItemAddDisabled,
      isItemAddHidden,
    } = this.props;

    if (listName && onItemAddClick && !isItemAddHidden) {
      return (
        <Icon
          backgroundColor={IconBackgroundColors.blue}
          className="sidebar-list-add-btn"
          color={IconColors.white}
          disabled={onItemAddDisabled}
          onClick={onItemAddClick}
        />
      );
    }

    return null;
  };
}

SidebarList.propTypes = {
  draggable: PropTypes.bool,
  editingItemId: PropTypes.number,
  iconOptions: Shapes.IconOptions,
  isItemAddHidden: PropTypes.bool,
  itemOptions: Shapes.ItemOptions,
  listData: Shapes.ListData,
  listLoading: PropTypes.bool.isRequired,
  listName: PropTypes.string,
  onEdit: PropTypes.func,
  onEditCancel: PropTypes.func,
  onItemAddClick: PropTypes.func,
  onItemAddDisabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  selectedListItem: PropTypes.number,
};

SidebarList.defaultProps = {
  draggable: false,
  editingItemId: 0,
  iconOptions: {},
  isItemAddHidden: false,
  itemOptions: {},
  listData: {},
  listName: '',
  onEdit: () => {},
  onEditCancel: () => {},
  onItemAddClick: () => {},
  onItemAddDisabled: false,
  selectedListItem: null,
};

export default SidebarList;
