import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Switch from 'components/buttons/switch';

const Slack = (props) => {
  const {
    intl: { formatMessage },
    actionCreators: { updateSlackIntegrationEnabled },
    isSlackIntegrationEnabled,
  } = props;

  const switchLabel = formatMessage({
    id: 'web.settings.adminSettings.general.contentPartners.slack.feature',
  });

  return (
    <div className="margin-top-20">
      <Switch
        checked={isSlackIntegrationEnabled}
        onChange={updateSlackIntegrationEnabled}
        name="sales_navigator_enabled"
        label={switchLabel}
        rightLabel
      />
      <div className="feature-description">
        <p className="text-gray">
          <FormattedMessage id="web.settings.adminSettings.general.contentPartners.slack.benefits" />
        </p>
      </div>
    </div>
  );
};

Slack.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isSlackIntegrationEnabled: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(Slack);
