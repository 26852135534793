import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IdentitiesPopupIds } from '../libs/identitiesTableConstants';
import IdentityResendCompletePopup from './popups/identityResendCompletePopup';
import IdentityDeletePopup from './popups/identityDeletePopup';
import IdentityMakeDefaultErrorPopup from './popups/identityMakeDefaultErrorPopup';
import IdentityResendConfirmationPopup from './popups/identityResendConfirmationPopup';

class IdentitiesPopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>{this.getPopupComponent()}</div>;
  }

  getPopupComponent() {
    const { popup, ...others } = this.props;

    switch (popup) {
      case IdentitiesPopupIds.identityResendComplete:
        return <IdentityResendCompletePopup popupId={popup} {...others} />;
      case IdentitiesPopupIds.identityResendConfirmation:
        return <IdentityResendConfirmationPopup popupId={popup} {...others} />;
      case IdentitiesPopupIds.identityMakeDefaultError:
        return <IdentityMakeDefaultErrorPopup popupId={popup} {...others} />;
      case IdentitiesPopupIds.identityDelete:
        return <IdentityDeletePopup popupId={popup} {...others} />;
      default:
        return null;
    }
  }
}

IdentitiesPopup.propTypes = {
  popup: PropTypes.string.isRequired,
};

export default IdentitiesPopup;
