import { combineReducers } from 'redux';
import BccActionTypes from '../libs/bccActionTypes';

export const item = (state = {}, action = {}) => {
  switch (action.type) {
    case BccActionTypes.setIntegration:
      return action.item;
    default:
      return state;
  }
};

export const fetching = (state = false, action = {}) => {
  switch (action.type) {
    case BccActionTypes.setIntegrationFetching:
      return action.fetching;
    default:
      return state;
  }
};

export const fetched = (state = false, action = {}) => {
  switch (action.type) {
    case BccActionTypes.setIntegrationFetched:
      return action.fetched;
    default:
      return state;
  }
};

export default combineReducers({
  item,
  fetching,
  fetched,
});
