/*eslint react/require-default-props: 0*/
import React from 'react';
import PropTypes from 'prop-types';
import { ToutEvents } from 'web/liveFeed/libs/liveFeedConstants';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  TOOLTIP_TRANSLATE_X,
  TOOLTIP_TRANSLATE_Y,
} from 'components/chart/libs/chartConstants';
import './graphTooltip.scss';

class GraphTooltip extends React.Component {
  _getStatClasses = (event) =>
    classNames(`icon-${event}rate`, 'tooltip-event-icon');

  _getEventName = (event) => {
    switch (event) {
      case ToutEvents.open:
        return <FormattedMessage id="graphTooltip.views" />;
      case ToutEvents.click:
        return <FormattedMessage id="graphTooltip.clicks" />;
      case ToutEvents.reply:
        return <FormattedMessage id="graphTooltip.replies" />;
      default:
        return <FormattedMessage id="graphTooltip.emails" />;
    }
  };

  render() {
    const {
      datum: {
        x: dateLabel,
        y: eventCount,
        event: eventType,
        overlap,
        countEvent,
      },
      x: positionX,
      y: positionY,
    } = this.props;

    return (
      <g
        transform={`translate(${TOOLTIP_TRANSLATE_X},${TOOLTIP_TRANSLATE_Y})`}
        style={{ pointerEvents: 'none' }}
      >
        <foreignObject x={positionX} y={positionY} width="150" height="100">
          <div className="graph-tooltip">
            <div className="tooltip margin-bottom-quarter">{dateLabel}</div>
            {overlap && (
              <div>
                <span
                  className={classNames(
                    { 'tooltip-count-event': countEvent === ToutEvents.count },
                    this._getStatClasses(countEvent),
                    'tooltip tooltip-event-name'
                  )}
                >
                  {this._getEventName(countEvent)}
                  {': '}
                </span>
                <span className="text-light">{eventCount}</span>
              </div>
            )}
            <span
              className={classNames(
                { 'tooltip-count-event': eventType === ToutEvents.count },
                this._getStatClasses(eventType),
                'tooltip tooltip-event-name'
              )}
            >
              {this._getEventName(eventType)}
              {': '}
            </span>
            <span className="text-light">{eventCount}</span>
          </div>
        </foreignObject>
      </g>
    );
  }
}

GraphTooltip.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  datum: PropTypes.object,
};

export default GraphTooltip;
