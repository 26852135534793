export const ElasticSearchMode = {
  all: 'all',
  shared: 'shared',
  unshared: 'unshared',
  mine: 'mine',
};

export const ElasticSearchViewerType = {
  user: 'user',
  team: 'team',
  subscription: 'subscription',
};

export const ElasticSearchTypes = {
  groups: 'group',
  people: 'person',
  pitchTemplates: 'pitch_template',
};

export const ElasticSearchFalbackType = {
  autocomplete: 'autocomplete',
  paged: 'paged',
};

export const ElasticSearchObject = {
  person: 'Person',
};

export const DEFAULT_PAGE_SIZE = 25;
