import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import TableExternals from 'web/templates/libs/templatesTableExternals';

export const setTableSort = (column, direction) => ({
  column,
  direction,
  type: PeopleActionTypes.table.sortInfo,
});

export const setTablePage = (page) => ({
  page,
  type: PeopleActionTypes.table.page,
});

export const setTablePerPage = (perPage) => ({
  perPage,
  type: PeopleActionTypes.table.perPage,
});

export const resetTable = () => ({
  type: PeopleActionTypes.table.reset,
});

export const clearSelectedIds = () => {
  const templatesTableExternals = TableExternals.get();
  if (templatesTableExternals.clearSelectedIds) {
    templatesTableExternals.clearSelectedIds();
  }
};
