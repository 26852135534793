import AddContactSlideOutActionTypes from 'web/slideOuts/addContact/libs/addContactSlideOutActionTypes';

export const openAddContact = () => (dispatch) => {
  dispatch({
    type: AddContactSlideOutActionTypes.open,
  });
};

export const closeAddContact = () => (dispatch) => {
  dispatch({
    type: AddContactSlideOutActionTypes.close,
  });
};
