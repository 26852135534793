import { Urls } from 'web/libs/routes';
import {
  LIVE_FEED_POPUP_WIDTH,
  LIVE_FEED_POPUP_HEIGHT_FOR_EDGE,
} from 'web/topNavbar/libs/topNavbarConstants';

export const openLiveFeedPopup = () => {
  const windowName = 'toutactivitypane';
  const { liveFeed } = Urls.topNavbar;
  let { height } = window.screen;

  if (window.navigator.userAgent.includes('Edge')) {
    height = LIVE_FEED_POPUP_HEIGHT_FOR_EDGE;
  }

  const options = `width=${LIVE_FEED_POPUP_WIDTH},height=${height},screenX=0,left=0,screenY=0,top=0,status=0,menubar=0`;
  const newWindow = window.open(liveFeed, windowName, options);
  newWindow.focus();
};
