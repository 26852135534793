import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoAppendForm from '../autoAppendForm';
import { AUTO_APPEND_FORM_ID } from '../../libs/generalPageConstants';
import PropTypes from 'prop-types';

class AutoAppendCard extends Component {
  render() {
    const {
      actionCreators: { openAutoAppendConfirmationPopup },
      customAppendContent,
      onAutoAppendFormError,
    } = this.props;

    return (
      <Fragment>
        <h3>
          <FormattedMessage id="web.settings.adminSettings.general.autoAppend.title" />
        </h3>
        <div className="margin-top-20">
          <FormattedMessage id="web.settings.adminSettings.general.autoAppend.description" />
        </div>
        <AutoAppendForm
          customAppendContent={customAppendContent}
          form={AUTO_APPEND_FORM_ID}
          initialValues={this.getCustomAppendContentModel()}
          onError={onAutoAppendFormError}
          onSubmit={openAutoAppendConfirmationPopup}
        />
      </Fragment>
    );
  }

  getCustomAppendContentModel = () => {
    const { customAppendContent } = this.props;
    return { auto_append_content: customAppendContent };
  };
}

AutoAppendCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  customAppendContent: PropTypes.string,
  onAutoAppendFormError: PropTypes.func,
};

AutoAppendCard.defaultProps = {
  customAppendContent: '',
  onAutoAppendFormError: () => {},
};

export default AutoAppendCard;
