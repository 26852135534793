import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BackgroundColors } from 'libs/constantsStyles';
import AdminUnsubscribesMessaging from '../components/adminUnsubscribesMessaging';
import AdminUnsubscribesAppendSetting from '../components/adminUnsubscribesAppendSetting';
import { isUnsubscribeMessageAppendEnhancementsEnabled } from 'web/shared/helpers/featureFlagHelper';
import { saveAdminUnsubscribeAutoAppend } from '../actionCreators/adminUnsubscribesIntegrationsActionCreators';
import { updateUnsubscribeSettings } from '../../../unsubscribes/actionCreators/settingsUnsubscribesActionCreators';
import './adminUnsubscribesIntegrationsContainer.scss';
import Cards, { Direction } from 'components/layouts/cards';

class AdminUnsubscribesMessagingSettingsContainer extends Component {
  getCards = () => {
    const { user, isEnabled, save, updateSettings } = this.props;
    const cards = [
      {
        children: <AdminUnsubscribesMessaging />,
        className: 'marketo-unsubscribes-check-card',
        id: 'customize-unsubscribe-message',
      },
    ];
    if (isUnsubscribeMessageAppendEnhancementsEnabled(user)) {
      cards.unshift({
        children: (
          <AdminUnsubscribesAppendSetting
            isEnabled={isEnabled}
            save={save}
            updateSettings={updateSettings}
          />
        ),
        className: 'marketo-unsubscribes-switch',
        id: 'unsubscribe-auto-append',
      });
    }

    return cards;
  };

  render() {
    return (
      <Fragment>
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={this.getCards()}
          className="admin-unsubscribes-cards-container"
          direction={Direction.vertical}
          marginAround={0}
          marginBetween={10}
        />
      </Fragment>
    );
  }
}

AdminUnsubscribesMessagingSettingsContainer.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isEnabled: state.settingsUnsubscribes.unsubscribe_auto_append,
  user: state.user,
});

const mapDispatchToProps = {
  save: saveAdminUnsubscribeAutoAppend,
  updateSettings: updateUnsubscribeSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUnsubscribesMessagingSettingsContainer);
