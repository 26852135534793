import { isCurrentUser } from './userService';
import intersection from 'lodash/intersection';

/* These services accept user object so that components can use them without dispatching action creators */

const mapIdList = (ids) => {
  const map = {};
  ids.forEach((id) => {
    map[id] = true;
  });
  return map;
};

const mapSubscriptionTeams = (subscriptionTeams = []) => {
  const map = {};
  subscriptionTeams.forEach((team) => {
    map[team.id] = team;
  });
  return map;
};

export const getTeams = ({ admin, subscription, team_ids: teamIds }, ids) => {
  const idList = ids ? mapIdList(ids) : mapIdList(teamIds);
  return subscription.teams.filter((elem) => admin || idList[elem.id]);
};

export const getTeamsByOnlyIds = ({ subscription = {} }, ids = []) => {
  const subscriptionTeams = mapSubscriptionTeams(subscription.teams);
  return ids.reduce((teams, currentId) => {
    if (subscriptionTeams[currentId]) {
      teams.push(subscriptionTeams[currentId]);
    }
    return teams;
  }, []);
};

export const getAdminTeams = (user) =>
  getTeams(user).filter((elem) => elem.admin_ids.indexOf(user.id) > -1);

export const getUserFromSubscriptionTeams = (userId, usersMap = {}) => {
  const user = usersMap[userId] || { name: '', email: '', id: 0 };
  return {
    userName: user.name,
    userEmail: user.email,
    userId: user.id,
  };
};

export const commonUserAdminTeamPermission = (user, userId) =>
  isCurrentUser(user, userId) ||
  user.admin === true ||
  intersection(getAdminTeams(user).map((team) => team.id), user.team_ids)
    .length > 0;

export const isMemberOfTeams = (
  { team_ids: teamIds },
  teamIdsToCheckInclusionIn
) => intersection(teamIdsToCheckInclusionIn, teamIds).length > 0;
