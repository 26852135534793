import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import SalesforcePage from '../salesforcePage';
import SalesforcePopupContainer from '../../containers/salesforcePopupContainer';
import AlertContainer from 'web/view/containers/alertContainer';
import SalesforceAlerts from 'web/salesforce/components/salesforceAlerts';

const Salesforce = (props) => (
  <Fragment>
    <SalesforcePage {...props} />
    <SalesforcePopupContainer />
    <AlertContainer>
      <SalesforceAlerts />
    </AlertContainer>
  </Fragment>
);

Salesforce.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(Salesforce);
