import PeopleActionTypes from '../libs/peopleActionTypes';

export const peopleExportLoading = (
  state = false,
  { type, isLoading = false }
) => {
  switch (type) {
    case PeopleActionTypes.export.loading:
      return isLoading;

    default:
      return state;
  }
};
