import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/buttons/checkbox';
import Person from '../person';
import './group.scss';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';

class Group extends Component {
  render() {
    const { group } = this.props;
    const { isGroupChecked, persons } = this._generatePersonComponents();
    return (
      <div className="group">
        <Checkbox
          checked={isGroupChecked}
          label={getLocalizedDateFromGroupName(group.name)}
          name={group.name}
          onChange={this.onGroupCheckChange}
        />
        {persons}
      </div>
    );
  }

  _generatePersonComponents = () => {
    const { group, onPersonCheck, selectedIndex } = this.props;
    let isGroupChecked = false;

    const persons = group.members.map((member) => {
      if (!isGroupChecked && selectedIndex[member.id]) {
        // enables/disables group checked status
        isGroupChecked = true;
      }
      return (
        <Person
          groupName={group.name}
          isChecked={selectedIndex[member.id]}
          key={`person-search-add-${member.id}`}
          onCheckChange={onPersonCheck}
          person={member}
        />
      );
    });

    return { isGroupChecked, persons };
  };

  onGroupCheckChange = (checked) => {
    const { group, onPersonCheck } = this.props;
    onPersonCheck(checked, group.id, true);
  };
}

Group.propTypes = {
  group: PropTypes.object.isRequired,
  onPersonCheck: PropTypes.func.isRequired,
  selectedIndex: PropTypes.object.isRequired,
};

export default Group;
