import React from 'react';
import PropTypes from 'prop-types';
import './analyticsCard.scss';

const AnalyticsCard = (props, Component) => (
  <div className="campaign-analytics-card">
    <div className="campaign-analytics-card-label">{props.cardLabel}</div>
    <Component {...props} />
  </div>
);

AnalyticsCard.propTypes = {
  cardLabel: PropTypes.string.isRequired,
};

export default (Analytics) => (props) => AnalyticsCard(props, Analytics);
