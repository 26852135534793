export const FORM_ID = 'smtpServer';

export const SmtpServerAlertIds = {
  connected: 'SMTP_SERVER_CONNECTED',
  serverValidationGmailSmtpErrorMessage:
    'SMTP_SERVER_SERVER_VALIDATION_GMAIL_ERROR_MESSAGE',
  serverValidationOutlookSmtpErrorMessage:
    'SMTP_SERVER_SERVER_VALIDATION_OUTLOOK_ERROR_MESSAGE',
};

export const SmtpServerPopupIds = {
  removeConfirmation: 'SMTP_SERVER_REMOVE_CONFIRMATION_POPUP',
};
