import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Group, { GroupColors } from '../group';

class RadioGroup extends Component {
  render() {
    const { classes, className, disabled, onChange } = this.props;

    return (
      <Group
        className={classNames('tout-radio-group', classes, className)}
        items={this._getItems()}
        onClick={(!disabled && onChange) || undefined}
      />
    );
  }

  _getItems = () => {
    const { items } = this.props;

    return items.map(({ classes, className, label, value }) =>
      // legacy
      ({
        className: classNames('radio-group-item', classes, className),
        color: this._getColor(value),
        label,
        value,
      })
    );
  };

  _getColor = (value) => {
    const {
      colorDisabled,
      colorSelected,
      colorUnselected,
      disabled,
      selected,
    } = this.props;
    if (selected === value) {
      return colorSelected;
    }
    if (disabled) {
      return colorDisabled;
    }
    return colorUnselected;
  };
}

export const RadioGroupColors = { ...GroupColors };

export const RadioGroupItem = PropTypes.shape({
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

RadioGroup.propTypes = {
  classes: PropTypes.string, // legacy
  className: PropTypes.string,
  colorDisabled: PropTypes.oneOf(Object.values(RadioGroupColors)),
  colorSelected: PropTypes.oneOf(Object.values(RadioGroupColors)),
  colorUnselected: PropTypes.oneOf(Object.values(RadioGroupColors)),
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(RadioGroupItem).isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RadioGroup.defaultProps = {
  classes: '',
  className: '',
  colorDisabled: RadioGroupColors.grayLight,
  colorSelected: RadioGroupColors.blue,
  colorUnselected: RadioGroupColors.white,
  disabled: false,
  onChange: null,
  selected: null,
};

export default RadioGroup;
