import { AlertColors } from 'components/alert';
import { GlobalAlertIds } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';

export function getAlert(id, textValues, actionCreators) {
  /*eslint complexity: [1, 100]*/
  const defaultAlert = {
    color: AlertColors.red,
    onClose: actionCreators.hideAlert,
    textId: 'web.campaigns.genericApiError',
  };
  switch (id) {
    case GlobalAlertIds.createNewCampaign:
      return {
        ...defaultAlert,
        textId: 'web.campaignsTab.createNewCampaign.failed',
      };
    case GlobalAlertIds.createNewCampaignCategory:
      return {
        ...defaultAlert,
        textId: 'web.campaignsTab.createNewCampaignCategory.failed',
        textValues,
      };
    case GlobalAlertIds.createNewTemplate:
      return {
        ...defaultAlert,
        textId: 'web.campaignsTab.saveAsTemplate.failed',
      };
    case GlobalAlertIds.launchCampaignIssue:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.launchCampaignIssue',
      };
    case GlobalAlertIds.genericApiError:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.genericApiError',
      };
    case GlobalAlertIds.unverifiedUser:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.unverifiedUser',
      };
    case GlobalAlertIds.invalidPreviewInput:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.invalidPreviewInput',
        textValues,
      };
    case GlobalAlertIds.invalidPreviewDynamicFields:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.invalidPreviewDynamicFields',
        textValues,
      };
    case GlobalAlertIds.invalidPreviewPromptFields:
      return {
        ...defaultAlert,
        textId: 'web.campaigns.invalidPreviewPromptFields',
        textValues,
      };
    default:
      return null;
  }
}
