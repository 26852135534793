import { combineReducers } from 'redux';
import BoxActionTypes from '../libs/boxActionTypes';

export const fetching = (state = false, action = {}) => {
  switch (action.type) {
    case BoxActionTypes.setIntegrationFetching:
      return action.fetching;
    default:
      return state;
  }
};

export const fetched = (state = false, action = {}) => {
  switch (action.type) {
    case BoxActionTypes.setIntegrationFetched:
      return action.fetched;
    default:
      return state;
  }
};

export default combineReducers({
  fetching,
  fetched,
});
