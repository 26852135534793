import {
  TeamManagementEvents,
  TeamManagementActions,
} from 'web/libs/mixpanelEvents';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import TeamManagementActionTypes from '../libs/teamManagementActionTypes';
import { TeamManagementTabs } from '../libs/teamManagementConstants';
import { teamsStartup } from './teamsActionCreators';
import { teamMembersStartup } from './teamMembersActionCreators';

export const setTab = (tab) => ({
  type: TeamManagementActionTypes.setTab,
  tab,
  meta: {
    analytics: {
      event: TeamManagementEvents.teamManagement,
      properties: {
        'Action Type': TeamManagementActions.pageOpen,
        Tab: tab,
      },
    },
  },
});

export const teamManagementStartup = ({ tab, options }) => async (dispatch) => {
  const activeTab = Object.values(TeamManagementTabs).includes(tab)
    ? tab
    : TeamManagementTabs.teamMembers;
  dispatch(commonStartup());
  dispatch(setTab(activeTab));

  if (activeTab === TeamManagementTabs.teamMembers) {
    await dispatch(teamsStartup(options));
    await dispatch(teamMembersStartup());
  }
};
