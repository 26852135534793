import CommandCenterEmailsActionTypes from 'web/commandCenter/libs/commandCenterEmailsActionTypes';
import { getUniqPeopleIdsFromEmails } from '../helpers/emailHelpers';
import {
  openAddGroupToCampaign,
  openDeleteFromCampaignPeoplePopup,
} from 'web/people/actionCreators/peoplePopupActionCreators';
import { emailSelectedPeople } from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import PeoplePopupIds from 'web/people/libs/peoplePopupIds';

export const setTableSort = (column, direction) => ({
  column,
  direction,
  type: CommandCenterEmailsActionTypes.table.sortInfo,
});

export const setTablePage = (page) => ({
  page,
  type: CommandCenterEmailsActionTypes.table.page,
});

export const setTablePerPage = (perPage) => ({
  perPage,
  type: CommandCenterEmailsActionTypes.table.perPage,
});

export const resetTable = () => ({
  type: CommandCenterEmailsActionTypes.table.reset,
});

export const emailSelected = (emailIds = [], emails = []) => (dispatch) => {
  const uniquePeopleIds = getUniqPeopleIdsFromEmails(emails, emailIds);
  dispatch(emailSelectedPeople(uniquePeopleIds));
};

export const addSelectedToCampaign = (emailIds = [], emails = []) => (
  dispatch
) => {
  const personIds = getUniqPeopleIdsFromEmails(emails, emailIds);

  dispatch(openAddGroupToCampaign(personIds));
};

export const removeSelectedFromCampaign = (emailIds = []) => (
  dispatch,
  getState
) => {
  const personIds = getUniqPeopleIdsFromEmails(
    getState().emailGridData,
    emailIds
  );

  dispatch(openDeleteFromCampaignPeoplePopup(personIds));
};

export const openPersonGroupsModal = (id, rawData) => (dispatch) => {
  const { name, groups } = rawData;
  const transformGroups = groups.map(({ group_id: id, group_name: name }) => ({
    id,
    name,
  }));

  dispatch(
    openPopup(PeoplePopupIds.personGroupsModal, {
      groups: transformGroups,
      name,
    })
  );
};
