import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './icon.scss';

class Icon extends Component {
  render() {
    const {
      backgroundColor,
      children,
      classes,
      className,
      color,
      ...other
    } = this.props;

    const componentClasses = classNames('icon', classes, className, color, {
      background: backgroundColor,
      [`${backgroundColor}-background`]: backgroundColor,
    });

    return (
      <button className={componentClasses} {...other} type="submit">
        {children}
      </button>
    );
  }
}

export const IconColors = {
  blue: 'blue',
  blueGray: 'blue-gray',
  blueLight: 'blue-light',
  default: '',
  darkGray: 'dark-gray',
  darkGrayLight: 'dark-gray-light',
  green: 'green',
  purple: 'purple',
  tourquoiseLight: 'tourquoise-light',
  white: 'white',
  yellow: 'yellow',
  gray: 'gray',
};

export const IconBackgroundColors = {
  blue: 'blue',
  default: '',
  green: 'green',
  white: 'white',
  purple: 'purple',
};

Icon.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(IconBackgroundColors)),
  children: PropTypes.node,
  classes: PropTypes.string, //legacy
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(IconColors)),
  disabled: PropTypes.bool,
};

Icon.defaultProps = {
  backgroundColor: '',
  children: null,
  classes: '',
  className: '',
  color: IconColors.blue,
  disabled: false,
};

export default Icon;
