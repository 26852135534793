import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Urls } from 'web/libs/routes';
import './bottomMenu.scss';

const BottomMenu = () => (
  <nav className="bottom-menu-wrapper">
    <ul>
      <li>
        <a href={Urls.common.main} target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="common.about" />
        </a>
      </li>
      <li>
        <a href={Urls.common.privacy} target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="common.privacy" />
        </a>
      </li>
      <li>
        <a
          href={Urls.common.contactUs}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="common.contact" />
        </a>
      </li>
    </ul>
  </nav>
);

export default BottomMenu;
