import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function nonHoverTooltipPosition(state = {}, action) {
  switch (action.type) {
    case ActionTypes.nonHoverTooltip.position: {
      // action.data props are not enumerable and therefore cannot be cloned
      const { bottom, left, width, height } = action.data;
      return { bottom, left, width, height };
    }
    default:
      return state;
  }
}

export function nonHoverTooltipShown(state = false, action) {
  switch (action.type) {
    case ActionTypes.nonHoverTooltip.shown:
      return action.data;
    default:
      return state;
  }
}

export function nonHoverTooltipText(state = {}, action) {
  switch (action.type) {
    case ActionTypes.nonHoverTooltip.text:
      return { ...action.data };
    default:
      return state;
  }
}
