import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeViewAlert as closeViewAlertAC } from 'web/view/actionCreators/alertActionCreators';
import GeneralPageAlert from '../components/generalPageAlerts';

class GeneralPageAlertsContainer extends PureComponent {
  render() {
    return <GeneralPageAlert {...this.props} />;
  }
}

GeneralPageAlertsContainer.propTypes = {
  viewAlert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  viewAlert: state.viewAlert,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeViewAlert: closeViewAlertAC,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralPageAlertsContainer);
