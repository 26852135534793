import React, { Component } from 'react';
import { string, func, object } from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import Card from 'components/card';
import Action, { ButtonColors } from 'components/buttons/action';
import {
  ConnectionStates,
  MASKED_BULLET_POINTS,
  DEFAULT_REPEATED_AMOUNT,
} from '../../libs/marketoSettingsConstants';
import MarketoAdminSetupForm from '../adminSetupForm';
import HoverTooltip from 'components/hoverTooltip';
import './marketoAdminSetup.scss';

export default class MarketoAdminSetup extends Component {
  constructor(props) {
    super(props);
    props.getMarketoIntegration();
  }

  state = {};

  render() {
    const {
      connectionInfo: {
        engageUsers: { engageAdminCount, engageSalesCount },
      },
      connectionState,
    } = this.props;
    const { clientId, clientSecret, munchkinId } = this.state;
    const disableConnect = !(
      (clientId && clientSecret && munchkinId) ||
      engageAdminCount > 0
    );
    const disableDisconnect = engageAdminCount === 1 && engageSalesCount > 0;
    const disableButton =
      connectionState === ConnectionStates.connected
        ? disableDisconnect
        : disableConnect;

    return (
      <Card className="marketo-admin-setup-card">
        <h3>
          <FormattedMessage id="web.marketoSettings.setPermissions" />
        </h3>
        {this._getFormArea()}
        {connectionState !== ConnectionStates.gettingIntegration &&
          connectionState !== ConnectionStates.hasConnection &&
          connectionState !== ConnectionStates.noSalesforceConnection &&
          (disableDisconnect &&
          connectionState === ConnectionStates.connected ? (
            <HoverTooltip
              className="disable-disconnect"
              place="right"
              textId="web.marketoSettings.button.tooltip"
            >
              {this._getButtton(disableButton)}
            </HoverTooltip>
          ) : (
            this._getButtton(disableButton)
          ))}
      </Card>
    );
  }

  _getButtton = (disableButton) => {
    const { connectionState } = this.props;

    return (
      <Action
        className="setup-connect"
        color={this._buttonColor(connectionState)}
        onClick={this._handleIntegrationConnection}
        disabled={disableButton}
      >
        <FormattedMessage id={this._buttonTextId(connectionState)} />
      </Action>
    );
  };

  clientIdOnChange = (text) => this.setState({ clientId: text });

  clientSecretOnChange = (text) => this.setState({ clientSecret: text });

  munchkinIdOnChange = (text) => this.setState({ munchkinId: text });

  _getFormArea = () => {
    const {
      connectionInfo: {
        engageUsers: { engageAdminCount },
        munchkin,
      },
      connectionState,
      saleforceConnectLink,
    } = this.props;

    switch (connectionState) {
      case ConnectionStates.gettingIntegration:
        return (
          <div className="setup-form-area">
            <LoadingSpinner imageUrl={getImageUrl} />
          </div>
        );
      case ConnectionStates.connecting:
      case ConnectionStates.disconnected:
        return (
          <MarketoAdminSetupForm
            munchkin={munchkin}
            clientIdOnChange={this.clientIdOnChange}
            clientSecretOnChange={this.clientSecretOnChange}
            munchkinIdOnChange={this.munchkinIdOnChange}
            engageAdminUsersCount={engageAdminCount}
          />
        );
      case ConnectionStates.disconnecting:
      case ConnectionStates.connected:
        return (
          <div className="setup-form-area setup-connected">
            <p className="margin-top-full">
              <FormattedMessage id="web.marketoSettings.teamAlreadyConnected" />
            </p>
            <div className="margin-top-full">
              <span className="text-medium admin-credentials-label">
                <FormattedMessage id="web.marketoSettings.munchkinId" />
              </span>
              <span className="input-text admin-credentials-disabled">
                {munchkin}
              </span>
            </div>
            <div className="margin-top-full">
              <span className="text-medium admin-credentials-label">
                <FormattedMessage id="web.marketoSettings.clientId" />
              </span>
              <span className="input-text admin-credentials-disabled">
                {MASKED_BULLET_POINTS.repeat(DEFAULT_REPEATED_AMOUNT)}
              </span>
            </div>
            <div className="margin-top-full">
              <span className="text-medium admin-credentials-label">
                <FormattedMessage id="web.marketoSettings.clientSecret" />
              </span>
              <span className="input-text admin-credentials-disabled">
                {MASKED_BULLET_POINTS.repeat(DEFAULT_REPEATED_AMOUNT)}
              </span>
            </div>
          </div>
        );
      case ConnectionStates.hasConnection:
        return (
          <div className="setup-form-area has-connection">
            <FormattedMessage id="web.marketoSettings.hasConnection" />
          </div>
        );
      case ConnectionStates.noSalesforceConnection:
        return (
          <div className="setup-form-area no-salesforce-connection">
            <FormattedHTMLMessage
              id="web.marketoSettings.noSalesforceConnection"
              values={{ link: saleforceConnectLink }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  _buttonTextId(connectionState) {
    switch (connectionState) {
      case ConnectionStates.disconnected:
        return 'common.connect';
      case ConnectionStates.connecting:
        return 'web.marketoSettings.connecting';
      case ConnectionStates.connected:
        return 'common.disconnect';
      case ConnectionStates.disconnecting:
        return 'web.marketoSettings.disconnecting';
      default:
        return null;
    }
  }

  _buttonColor(connectionState) {
    switch (connectionState) {
      case ConnectionStates.connected:
      case ConnectionStates.disconnecting:
        return ButtonColors.red;
      default:
        return ButtonColors.blue;
    }
  }

  _handleIntegrationConnection = () => {
    if (this.state.munchkinId) {
      this.props.handleIntegrationConnection(this.state);
    } else {
      this.props.handleIntegrationConnection({
        ...this.state,
        munchkinId: this.props.connectionInfo.munchkin,
      });
    }
  };
}

MarketoAdminSetup.propTypes = {
  connectionInfo: object.isRequired,
  connectionState: string.isRequired,
  getMarketoIntegration: func.isRequired,
  handleIntegrationConnection: func.isRequired,
  saleforceConnectLink: string.isRequired,
};
