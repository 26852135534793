import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { prettifyNumber } from '../templateChooserHelper';
import './templateCard.scss';
import { DefaultTemplateCard } from 'libs/constantsShared';
import I18N from 'languages';

export default class TemplateCard extends Component {
  render() {
    const { className, selected, template } = this.props;
    const highlightClass = selected ? 'highlighted' : '';
    const templateName =
      DefaultTemplateCard.name === template.name
        ? I18N.getConstStr('templateChooser', template.name)
        : template.name;
    const templateSubject =
      DefaultTemplateCard.subject === template.subject
        ? I18N.getConstStr('templateChooser', template.subject)
        : template.subject;

    return (
      <div className={`templateCard ${className}`} onClick={this._onSelected}>
        <div className={highlightClass} />
        <div className="info">
          <div className="text-medium text-overflow">{templateName}</div>
          <div className="template-subject">{templateSubject}</div>
        </div>
        <div className={`stats ${highlightClass}`}>
          <div className="stats-item">
            <div>
              <div className="stats-item-title">
                <FormattedMessage id="templateChooser.sent" />
              </div>
              <div>{prettifyNumber(template.num_sent)}</div>
            </div>
          </div>
          <div className="stats-item">
            <div>
              <div className="stats-item-title">
                <FormattedMessage id="templateChooser.views" />
              </div>
              <div>{`${template.open_rate}%`}</div>
            </div>
          </div>
          <div className="stats-item">
            <div>
              <div className="stats-item-title">
                <FormattedMessage id="templateChooser.clicks" />
              </div>
              <div>{`${template.clickthrough_rate}%`}</div>
            </div>
          </div>
          <div className="stats-item">
            <div>
              <div className="stats-item-title">
                <FormattedMessage id="templateChooser.replies" />
              </div>
              <div>{`${template.reply_rate || 0}%`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _onSelected = () => {
    this.props.onSelected(this.props.template);
  };
}

TemplateCard.propTypes = {
  className: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  template: PropTypes.object.isRequired,
};

TemplateCard.defaultProps = {
  className: '',
  selected: false,
};
