import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { DefaultTimezone } from 'web/timezones/libs/timezonesConstants';
import { ScheduleLaunchWhen, PreviewViewTypes } from 'libs/constantsShared';
import {
  createMapAndIndex,
  initMapIndex,
} from 'web/reducers/helpers/mapAndIndexHelper';

export const initPreviews = {
  attachmentsCount: 0,
  campaignId: 0,
  currentId: 0,
  viewType: PreviewViewTypes.person,
  editorId: '',
  identity: 0,
  isEmail: false,
  loading: false,
  previewsMap: {},
  recipients: { ...initMapIndex },
};

/*eslint complexity: [1, 12]*/
export function previewEmailsPreviews(state = initPreviews, action) {
  switch (action.type) {
    case ActionTypes.previewEmails.state.init:
      return {
        ...initPreviews,
        campaignId: action.campaignId,
        currentId: action.currentId || initPreviews.currentId,
        viewType: action.viewType || initPreviews.viewType,
        editorId: action.editorId || initPreviews.editorId,
        identity: action.identity || initPreviews.identity,
        isEmail: action.isEmail || initPreviews.isEmail,
        attachmentsCount:
          action.attachmentsCount || initPreviews.attachmentsCount,
        recipients: createMapAndIndex(action.recipients),
      };
    case ActionTypes.previewEmails.state.addPreview:
      return {
        ...state,
        previewsMap: {
          ...state.previewsMap,
          [action.id]: action.preview,
        },
      };
    case ActionTypes.previewEmails.state.updatePreviewsState:
      return {
        ...state,
        ...action.data,
      };
    case ActionTypes.previewEmails.state.updatePreview:
      return {
        ...state,
        previewsMap: {
          ...state.previewsMap,
          [state.currentId]: {
            ...state.previewsMap[state.currentId],
            ...action.data,
          },
        },
      };
    case ActionTypes.modal.state.close:
      return initPreviews;
    default:
      return state;
  }
}

export const initScheduled = {
  datetime: null, // store as moment
  defaultDatetime: null,
  defaultTz: DefaultTimezone,
  timezone: DefaultTimezone,
  when: ScheduleLaunchWhen.scheduled,
};

export function previewEmailsScheduled(state = initScheduled, action) {
  switch (action.type) {
    case ActionTypes.previewEmails.state.init:
      return {
        datetime: action.datetime,
        defaultDatetime: action.defaultDatetime,
        defaultTz: action.defaultTz,
        timezone: action.timezone,
        when: action.when,
      };
    case ActionTypes.previewEmails.state.updateScheduled:
      return {
        ...state,
        ...action.data,
      };
    case ActionTypes.modal.state.close:
      return initScheduled;
    default:
      return state;
  }
}
