import TextCell from 'table/modules/cells/textCell';
import DateAgoCell from 'table/modules/cells/dateAgoCell';
import { Align } from 'table/modules/constants';
import PercentageCell from '../percentageCell/percentageCell';
import CreatorCell from '../creatorCell/creatorCell';
import SharedCell from '../sharedCell/sharedCell';
import FavoriteCell from '../favoriteCell/favoriteCell';

export default (
  user,
  sortColumn,
  sortColumnOpposite,
  formatMessage,
  actionCreators
) => [
  {
    id: 'favorite',
    name: '',
    rowCell: {
      component: FavoriteCell,
      metadata: {
        onClick: actionCreators.setFavorite,
        property: 'favorite',
      },
    },
    width: 29,
  },
  {
    flex: true,
    id: 'template',
    name: formatMessage({ id: 'common.template' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'name',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'name.sortable'),
    width: 175,
  },
  {
    flex: true,
    id: 'subject',
    initHide: true,
    name: formatMessage({ id: 'common.subject' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'subject',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'subject.sortable'),
    width: 350,
  },
  {
    id: 'shared',
    name: '',
    rowCell: {
      component: SharedCell,
      metadata: {
        user,
      },
    },
    width: 60,
  },
  {
    align: Align.CENTER,
    id: 'emails-sent',
    name: formatMessage({ id: 'web.templates.emailsSent' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'num_sent',
        wrapLines: 2,
      },
    },
    sorting: sortColumnOpposite.bind(this, 'num_sent'),
    width: 125,
  },
  {
    align: Align.CENTER,
    flex: true,
    id: 'team-view-rate',
    name: formatMessage({ id: 'web.templates.teamViewRate' }),
    rowCell: {
      component: PercentageCell,
      metadata: {
        percentageProperty: 'open_rate',
      },
    },
    sorting: sortColumnOpposite.bind(this, 'open_rate'),
    width: 75,
  },
  {
    align: Align.CENTER,
    flex: true,
    id: 'team-click-rate',
    name: formatMessage({ id: 'web.templates.teamClickRate' }),
    rowCell: {
      component: PercentageCell,
      metadata: {
        percentageProperty: 'clickthrough_rate',
      },
    },
    sorting: sortColumnOpposite.bind(this, 'clickthrough_rate'),
    width: 75,
  },
  {
    align: Align.CENTER,
    flex: true,
    id: 'team-reply-rate',
    name: formatMessage({ id: 'web.templates.teamReplyRate' }),
    rowCell: {
      component: PercentageCell,
      metadata: {
        percentageProperty: 'reply_rate',
      },
    },
    sorting: sortColumnOpposite.bind(this, 'reply_rate'),
    width: 75,
  },
  {
    id: 'last-used',
    initHide: true,
    name: formatMessage({ id: 'web.templates.lastUsed' }),
    rowCell: {
      component: DateAgoCell,
      metadata: {
        property: 'last_used',
      },
    },
    sorting: sortColumn.bind(this, 'last_used'),
    width: 135,
  },
  {
    id: 'last-updated',
    name: formatMessage({ id: 'web.templates.lastUpdated' }),
    rowCell: {
      component: DateAgoCell,
      metadata: {
        property: 'updated_at',
      },
    },
    sorting: sortColumn.bind(this, 'updated_at'),
    width: 135,
  },
  {
    flex: true,
    id: 'creator',
    name: formatMessage({ id: 'common.creator' }),
    rowCell: {
      component: CreatorCell,
    },
    sorting: sortColumn.bind(this, 'user.name.sortable'),
    width: 100,
  },
];
