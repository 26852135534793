import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { GeneralPageAlertIds } from '../../libs/generalPageConstants';
import Alert, { AlertColors } from 'components/alert';

class GeneralPageAlert extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      intl: { formatMessage },
      viewAlert: { id: alertId, values },
      closeViewAlert,
    } = this.props;

    const defaultAlert = {
      classes: 'general-settings-alert',
      color: AlertColors.red,
      onClose: closeViewAlert,
    };

    switch (alertId) {
      case GeneralPageAlertIds.formChangesSaved:
        return {
          ...defaultAlert,
          color: AlertColors.green,
          textId: 'common.alert.success',
        };
      case GeneralPageAlertIds.formChangesNotSaved:
        return {
          ...defaultAlert,
          color: AlertColors.red,
          textId: 'common.alert.failure',
        };
      case GeneralPageAlertIds.validationError: {
        const {
          field,
          validationError: { messageId, validatorParams },
        } = values;
        const fieldLabel = formatMessage({
          id: `web.settings.adminSettings.general.forms.fieldLabel.${field}`,
        });
        const errorMessage = formatMessage(
          { id: messageId },
          { ...validatorParams }
        );

        return {
          ...defaultAlert,
          html: true,
          textId: 'common.validation.alert.formFieldErrorMessage',
          textValues: { errorMessage, fieldLabel },
        };
      }
      default:
        return null;
    }
  };
}

GeneralPageAlert.propTypes = {
  closeViewAlert: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  viewAlert: PropTypes.object.isRequired,
};

export default injectIntl(GeneralPageAlert);
