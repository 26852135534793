import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { UserManagementAlertIds } from '../../libs/userManagementConstants';
import Alert, { AlertColors } from 'components/alert';
import './userManagementAlerts.scss';

class UserManagementAlerts extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      viewAlert: {
        id: alertId,
        values: { invitationsCount },
      },
      closeViewAlert,
    } = this.props;
    const defaultAlert = {
      classes: 'user-management-alert',
      color: AlertColors.red,
      onClose: closeViewAlert,
    };

    switch (alertId) {
      case UserManagementAlertIds.genericIssue:
        return { ...defaultAlert, textId: 'web.common.alert.genericIssue' };
      case UserManagementAlertIds.resendInviteesSuccess:
        return {
          ...defaultAlert,
          color: AlertColors.green,
          textId:
            'web.settings.adminSettings.userManagement.invitees.resend.success',
          textValues: { invitationsCount },
        };
      case UserManagementAlertIds.notEnoughOpenSeats:
        return {
          ...defaultAlert,
          color: AlertColors.red,
          manual: true,
          html: true,
          textId:
            'web.settings.adminSettings.userManagement.invitees.notEnoughSeats',
        };
      default:
        return null;
    }
  };
}

UserManagementAlerts.defaultProps = {
  viewAlert: {},
  closeViewAlert: () => {},
};

UserManagementAlerts.propTypes = {
  viewAlert: PropTypes.object,
  closeViewAlert: PropTypes.func,
};

export default UserManagementAlerts;
