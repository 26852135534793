import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function openCampaignAlert(id, values) {
  return {
    type: ActionTypes.campaignsTab.state.campaignAlertOpen,
    id,
    values,
  };
}

export function closeCampaignAlert() {
  return {
    type: ActionTypes.campaignsTab.state.campaignAlertClose,
  };
}
