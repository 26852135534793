import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Select2 from 'components/selects/select2';
import './createNewCampaign.scss';
import Label from 'components/label';
import Text from 'components/inputs/text';

class CreateNewCampaign extends Component {
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;
    this.categoryPlaceholder = formatMessage({
      id: 'web.campaignsTab.common.categoriesPlaceholder',
    });
    this.categoryNoResultsText = formatMessage({
      id: 'web.campaignsTab.common.categoriesNoResults',
    });
    this.selectPlaceholderText = formatMessage({
      id: 'web.templates.createTemplate.selectPlaceholder',
    });
    this.textPlaceholderText = formatMessage({
      id: 'web.campaigns.enterCampaignName',
    });
  }

  render() {
    const { categories, state } = this.props;
    const names = categories.map((category) => ({
      value: category.id,
      label: category.name,
    }));
    names.shift(); // remove all category

    return (
      <div className="createNewCampaign">
        <Label
          id="create-campaign-name"
          messageKey="common.name"
          requiredAsterisk
        >
          <Text
            onChange={this.updateName}
            placeholder={this.textPlaceholderText}
            value={state.name}
          />
        </Label>
        <div className="margin-top-full margin-bottom-full required">
          <b>
            <FormattedMessage id="web.campaignsTab.createNewCampaign.body" />
          </b>
        </div>
        <Select2
          classes="margin-bottom-full"
          clearable={false}
          noResultsText={this.categoryNoResultsText}
          onChange={this._onChange}
          options={names}
          placeholder={this.categoryPlaceholder}
          value={state.category}
          searchable
        />
      </div>
    );
  }

  updateName = (name) => {
    this.props.updateState({ name });
  };

  // todo - is this actually private? since it's passed to select2
  _onChange = (data) => {
    const { onChange } = this.props;
    onChange(data.value);
  };
}

CreateNewCampaign.propTypes = {
  categories: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default injectIntl(CreateNewCampaign);
