import React from 'react';
import PropTypes from 'prop-types';
import RadioComponent from 'components/buttons/radio';

const Radio = ({ input, ...other }) => <RadioComponent {...input} {...other} />;

Radio.propTypes = {
  input: PropTypes.object.isRequired,
};

export default Radio;
