import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { PeopleFilters } from 'web/campaigns/people/libs/campaignsPeopleConstants';
import './outcomeCell.scss';

class OutcomeCell extends Component {
  render() {
    const outcomeComponent = this._getOutcomeComponent();
    return (
      <div className="campaigns-outcome-container">
        <span className="campaigns-outcome-row">{outcomeComponent}</span>
      </div>
    );
  }

  _getOutcomeComponent = () => {
    const {
      rowData,
      intl: { formatMessage },
    } = this.props;
    if (rowData.state === PeopleFilters.completed && rowData.success) {
      return (
        <span className="text-green">
          {formatMessage({ id: 'common.markedSuccess' })}
        </span>
      );
    } else if (rowData.state === PeopleFilters.completed) {
      return (
        <span className="text-gray">
          {formatMessage({ id: 'common.completed' })}
        </span>
      );
    } else {
      return (
        <span>
          <a href="javascript:void(0)" onClick={this._markComplete}>
            {formatMessage({ id: 'common.remove' })}
          </a>
          <span className="campaigns-outcome-row-divider">|</span>
          <a href="javascript:void(0)" onClick={this._markSuccess}>
            {formatMessage({ id: 'common.success' })}
          </a>
        </span>
      );
    }
  };

  _isDisabled = () => {
    const {
      rowData: { state },
    } = this.props;
    return state === PeopleFilters.completed;
  };

  _markComplete = (e) => {
    e.stopPropagation();
    const { peopleConfirmationComplete, rowData } = this.props;
    peopleConfirmationComplete([`${rowData.id}`], false);
  };

  _markSuccess = (e) => {
    e.stopPropagation();
    const { peopleConfirmationComplete, rowData } = this.props;
    peopleConfirmationComplete([`${rowData.id}`], true);
  };
}

OutcomeCell.propTypes = {
  intl: intlShape.isRequired,
  peopleConfirmationComplete: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default injectIntl(OutcomeCell);
