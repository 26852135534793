import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getTemplates,
  sortTemplates,
  paginateTemplates,
  paginateTemplatesPerPage,
} from 'web/templates/actionCreators/templatesFetchActionCreators';
import {
  initTemplateCategories,
  filterByCategory,
} from 'web/templates/actionCreators/templateCategoriesActionCreators';
import {
  openMoveTemplatePopup,
  openArchiveTemplatePopup,
  openDeleteTemplatePopup,
} from 'web/templates/actionCreators/templatesPopupActionCreators';
import { openTemplateDetails } from 'web/templates/actionCreators/templatesTableActionCreators';
import { templatesSmartFilterChange } from 'web/templates/actionCreators/templatesSmartFilterActionCreators';
import {
  templatesResetSearch,
  templatesSearch,
} from 'web/templates/actionCreators/templatesSearchActionCreators';
import {
  getTemplatesGridData,
  getUserCanEditTemplates,
} from 'web/templates/selectors/templatesSelectors';
import { setFavorite } from 'web/slideOuts/editTemplate/actionCreators/editTemplateTemplateActionCreators';
import { bindActionCreators } from 'redux';
import TemplatesTable from '../components/templatesTable/templatesTable';
import { getUserColumnSettingsForTable } from 'web/user/selectors/userSelectors';
import { changeColumnsSettings } from 'web/user/actionCreators/userActionCreators';
import { COLUMN_SETTINGS_TEMPLATES_TABLE_ID } from 'web/templates/libs/templatesConstants';
import './templatesTableContainer.scss';

class TemplatesTableContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;
    this.actionCreators = {
      ...bindActionCreators(
        {
          filterByCategory,
          getTemplates,
          initTemplateCategories,
          openArchiveTemplatePopup,
          openDeleteTemplatePopup,
          openMoveTemplatePopup,
          openTemplateDetails,
          paginateTemplates,
          paginateTemplatesPerPage,
          setFavorite,
          sortTemplates,
          templatesResetSearch,
          templatesSearch,
          templatesSmartFilterChange,
        },
        dispatch
      ),
      changeColumnsSettings: (columnsSettings) => {
        dispatch(
          changeColumnsSettings(
            COLUMN_SETTINGS_TEMPLATES_TABLE_ID,
            columnsSettings
          )
        );
      },
    };
  }

  render() {
    return (
      <div className="templates-table-container">
        <TemplatesTable
          actionCreators={this.actionCreators}
          resizeTable
          {...this.props}
        />
      </div>
    );
  }
}

TemplatesTableContainer.propTypes = {
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  selectedTemplateCategoryId: PropTypes.number.isRequired,
  templates: PropTypes.array.isRequired,
  templatesSearchString: PropTypes.string.isRequired,
  templatesSmartFilterValue: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

TemplatesTableContainer.defaultProps = {
  columnSettings: [],
};

const mapStateToProps = (state) => ({
  columnSettings: getUserColumnSettingsForTable(
    state,
    COLUMN_SETTINGS_TEMPLATES_TABLE_ID
  ),
  loading: state.templatesLoading,
  page: state.templatesPage,
  perPage: state.templatesPerPage,
  selectedTemplateCategoryId: state.templatesSelectedTemplateCategoryId,
  templates: getTemplatesGridData(state),
  templatesSearchFilter: state.templatesSearchFilter,
  templatesSearchString: state.templatesSearchString,
  templatesSmartFilterValue: state.templatesSmartFilterValue,
  templatesViewerId: state.templatesViewer.id,
  total: state.templatesTotal,
  user: state.user,
  userCanEditTemplates: getUserCanEditTemplates(state),
});

export default connect(mapStateToProps)(TemplatesTableContainer);
