import { createSelector } from 'reselect';

const getBestBets = (state) => state.liveFeedBestBets;
const getBestBetsExtraData = (state) => state.liveFeedBestBetsExtraData;

export const formatBestBets = createSelector(
  getBestBets,
  getBestBetsExtraData,
  (bestBets, bestBetsExtraData) =>
    bestBets.map((bestBet) => {
      const extraData = bestBetsExtraData.find(
        ({ bestBetId }) => bestBetId === bestBet.id
      );
      return { ...bestBet, ...extraData };
    })
);
