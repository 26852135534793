import PeopleActionTypes from 'web/people/libs/peopleActionTypes';

export const peopleForDelete = (state = [], { type, people = [] }) => {
  switch (type) {
    case PeopleActionTypes.people.setPeopleForDelete:
      return people;
    default:
      return state;
  }
};
