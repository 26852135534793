import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header';
import PageTabNavBar from 'components/layouts/page/tabNavBar';
import PageTabNav from 'components/layouts/page/tabNav';
import PageView from 'components/layouts/page/view';
import { BackgroundColors } from 'libs/constantsStyles';
import { SalesforceTabs } from '../../libs/salesforceConstants';
import {
  MARKETO_SALES_OUTBOX,
  MESSAGE_TYPE,
} from 'web/salesforce/libs/salesforceConstants';
import ConnectionsCustomizationsContainer from '../../containers/connectionsCustomizationsContainer';
import SyncSettingsContainer from '../../containers/syncSettingsContainer';
import { handleMessageEvents } from 'web/salesforce/helpers/salesforceHelpers.js';
import { isLoadedInIframe, sendMessageToWindow } from 'web/libs/commonHelper';
import './salesforcePage.scss';

class SalesforcePage extends PureComponent {
  componentDidMount() {
    if (isLoadedInIframe(MARKETO_SALES_OUTBOX)) {
      window.addEventListener('message', handleMessageEvents);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', handleMessageEvents);
  }

  render() {
    const { onTabSelect, tab } = this.props;

    // Reload Marketo Sales Outbox and close current window when a user connect to
    // SFDC from Marketo Sales Outbox that loads MSC through an iframe on SFDC
    sendMessageToWindow({
      afterHandler: () => {
        window.opener = null;
        window.close();
      },
      dest: window.opener,
      destWinName: MARKETO_SALES_OUTBOX,
      msg: { type: MESSAGE_TYPE.RELOAD_MARKETO_SALES_OUTBOX_IN_SFDC },
    });

    return (
      <Page className="salesforce-account-page">
        <PageHeader textId="web.settings.accountSettings.salesforce.title" />
        <PageTabNavBar border>
          <PageTabNav
            list={this.tabList}
            onTabSelect={onTabSelect}
            selectedTabValue={tab}
          />
        </PageTabNavBar>
        <PageView backgroundColor={BackgroundColors.grayExtraLight}>
          {this.getTabPageView()}
        </PageView>
      </Page>
    );
  }

  get tabList() {
    const {
      intl: { formatMessage },
      isSalesforceConnected,
    } = this.props;

    return [
      {
        title: formatMessage({
          id:
            'web.settings.accountSettings.salesforce.tabs.connectionsCustomizations',
        }),
        value: SalesforceTabs.connectionsCustomizations,
        width: 340,
      },
      {
        title: formatMessage({
          id: 'web.settings.accountSettings.salesforce.tabs.syncSettings',
        }),
        value: SalesforceTabs.syncSettings,
        width: 220,
        disabled: !isSalesforceConnected,
      },
    ];
  }

  getTabPageView = () => {
    const { tab } = this.props;

    switch (tab) {
      case SalesforceTabs.syncSettings:
        return <SyncSettingsContainer />;
      case SalesforceTabs.connectionsCustomizations:
        return <ConnectionsCustomizationsContainer />;
      default:
        return null;
    }
  };
}

SalesforcePage.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  isSalesforceConnected: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforcePage);
