export const UserManagementTabs = {
  users: 'users',
  invitees: 'invitees',
};

export const UserManagementAlertIds = {
  genericIssue: 'TEAM_MANAGEMENT_GENERIC_ISSUE',
  resendInviteesSuccess: 'TEAM_MANAGEMENT_RESEND_INVITEES_SUCCESS',
  notEnoughOpenSeats: 'TEAM_MANAGEMENT_INVITEES_NOT_ENOUGH_OPEN_SEATS',
};
