import { track } from 'web/services/mixpanelService';
// import TableTrackerEvents from 'table/modules/tracker/trackerEvents'; //todo CAN'T REFERENCE - NEED TO UPDATE TABLE TO BUILD AND EXPORT FROM REPO SO CAN TEST ON CODESHIP
import {
  CampaignTasksTabEvents,
  CommandCenterTaskEvents,
  SourceProperties,
  TablesEvents,
  TablesProperties,
  TablesSources,
  TaskEvents,
  TasksProperties,
} from 'web/libs/mixpanelEvents';
import { CommandCenterTasksTableId } from 'web/commandCenter/libs/commandCenterConstants';
import { CampaignsTasksTableId } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';

export const UNKNOWN = 'Unknown';

export function getTableSource(id) {
  switch (id) {
    case CommandCenterTasksTableId:
      return TablesSources.commandCenterTasks;
    case CampaignsTasksTableId:
      return TablesSources.campaignsTasks;
    default:
      return UNKNOWN;
  }
}

const getEventSource = (id) => {
  switch (id) {
    case CommandCenterTasksTableId:
      return SourceProperties.commandCenter;
    case CampaignsTasksTableId:
      return SourceProperties.campaigns;
    default:
      return UNKNOWN;
  }
};

const getTaskEventsOpen = (id) => {
  switch (id) {
    case CommandCenterTasksTableId:
      return CommandCenterTaskEvents.open;
    case CampaignsTasksTableId:
      return CampaignTasksTabEvents.open;
    default:
      return UNKNOWN;
  }
};

function mapTableToTasksTable(event, data) {
  const source = getTableSource(data.tableId);
  switch (event) {
    case 'COLUMN_FILTER_CHANGE': //TableTrackerEvents.columnFilterChange:
      return {
        name: TablesEvents.columnFilterChange,
        data: {
          [TablesProperties.source]: source,
          [TablesProperties.columnSelected]: data.name,
          [TablesProperties.checked]: data.checked,
        },
      };
    case 'SEARCH_VALUE_CHANGE': //TableTrackerEvents.searchChange:
      return {
        name: TablesEvents.gridSearch,
        data: {
          [TablesProperties.source]: source,
          [TablesProperties.columnSearched]: data.category,
          [TablesProperties.length]: data.length,
          [TablesProperties.secondarySearch]: data.category.length > 0,
        },
      };
    case 'SEARCH_MULTIPLE_SELECTED': //TableTrackerEvents.searchMultipleSelected:
      return {
        name: TablesEvents.gridSearchSelected,
        data: {
          [TablesProperties.source]: source,
          [TablesProperties.columnSelected]: data.category,
        },
      };
    default:
      return null;
  }
}

export function tracker(event = '', data = {}) {
  const mixpanelEvent = mapTableToTasksTable(event, data);
  if (mixpanelEvent) {
    track(mixpanelEvent.name, mixpanelEvent.data);
  }
}

export function trackComplete(
  tableId,
  actionType,
  isBulk,
  isCampaign,
  taskType
) {
  let mixpanelObj = {
    [TasksProperties.source]: getEventSource(tableId),
    [TasksProperties.actionType]: actionType,
    [TasksProperties.bulkActionEnabled]: isBulk,
  };

  if (!isBulk) {
    mixpanelObj = {
      ...mixpanelObj,
      [TasksProperties.campaignTask]: isCampaign,
      [TasksProperties.taskType]: taskType,
    };
  }

  track(TaskEvents.complete, mixpanelObj);
}

export function trackBulkAction(tableId, type, count) {
  track(TablesEvents.bulkAction, {
    [TablesProperties.source]: getTableSource(tableId),
    [TablesProperties.type]: type,
    count,
  });
}

export function trackTaskAction(id, type) {
  track(getTaskEventsOpen(id), { type });
}
