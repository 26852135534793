import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { FormattedHTMLMessage } from 'react-intl';
import { ADD_MEMBERS_TO_TEAMS_FORM_ID } from '../../../libs/teamMembersConstants';
import AddMembersToTeamsForm from './addMembersToTeamsForm';

class AddMembersToTeamsPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      addMembersToTeamsLoading,
      defaultAlert,
      isInvalidAddMembersToTeamsForm,
      popupData: { userIds },
    } = this.props;

    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.submitForm,
          onSecondary: closePopup,
          primaryTextId: 'common.add',
          secondaryTextId: 'common.cancel',
          primaryDisabled: isInvalidAddMembersToTeamsForm,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.teamManagement.teamMembers.addToTeams',
        }}
        getImageUrl={getImageUrl}
        loading={addMembersToTeamsLoading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedHTMLMessage
          id="web.settings.adminSettings.teamManagement.teamMembers.addMembersToTeams.title"
          values={{ count: userIds.length }}
        />
        <AddMembersToTeamsForm
          form={ADD_MEMBERS_TO_TEAMS_FORM_ID}
          onAddMembersToTeamsFormSubmit={this.onAddMembersToTeamsFormSubmit}
          {...this.props}
        />
      </Popup>
    );
  }

  onAddMembersToTeamsFormSubmit = ({ teams: teamIds }) => {
    const {
      actionCreators: { addTeamMembersToTeams },
      popupData: { userIds },
    } = this.props;

    addTeamMembersToTeams(userIds, teamIds);
  };

  submitForm = () => {
    const {
      actionCreators: { submitForm },
    } = this.props;
    submitForm(ADD_MEMBERS_TO_TEAMS_FORM_ID);
  };
}

AddMembersToTeamsPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popupData: PropTypes.object,
  addMembersToTeamsLoading: PropTypes.bool.isRequired,
  isInvalidAddMembersToTeamsForm: PropTypes.bool.isRequired,
  defaultAlert: PropTypes.object,
};

AddMembersToTeamsPopup.defaultProps = {
  popupData: {},
  defaultAlert: null,
};

export default AddMembersToTeamsPopup;
