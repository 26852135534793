import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import GmailIntegrationAlert from '../components/gmailIntegrationAlert';

const GmailIntegrationAlertContainer = (props) => (
  <GmailIntegrationAlert {...props} />
);

GmailIntegrationAlertContainer.propTypes = {
  alert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.viewAlert,
});

const mapDispatchToProps = {
  closeViewAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GmailIntegrationAlertContainer);
