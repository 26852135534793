export function getDraggedItems(draggedItem, selectedItems) {
  if (selectedItems.includes(draggedItem)) {
    return selectedItems;
  }
  return [draggedItem];
}

export function getSelectedIdsFromStore({ selectableSelectedItems = {} }) {
  return Object.keys(selectableSelectedItems)
    .filter((id) => Number(id))
    .map((id) => Number(id));
}
