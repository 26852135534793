import { createSelector } from 'reselect';
import { getCampaignSearchData } from 'web/reducers/campaigns/campaignSelectors';
import { getCampaignsSidebarSearchResults as getCampaignsSidebarSearchResultsHelper } from '../helpers/campaignsHelpers';

const getSidebarSearchQuery = (state) => state.sidebarSearchQuery;

export const getCampaignsSidebarSearchResults = createSelector(
  [getCampaignSearchData, getSidebarSearchQuery],
  getCampaignsSidebarSearchResultsHelper
);
