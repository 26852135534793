import React from 'react';
import PropTypes from 'prop-types';
import './sharedCell.scss';
import {
  isSharedTemplate,
  isUnsharedTemplateForAdmin,
} from 'web/templates/helpers/templatesHelper';
import classNames from 'classnames';

const SharedCell = ({ rowData, user }) => {
  const className = classNames({
    'templates-table-shared': isSharedTemplate(rowData),
    'templates-table-unshared': isUnsharedTemplateForAdmin(rowData, user),
  });

  return <div className={className} />;
};

SharedCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default SharedCell;
