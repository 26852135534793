import { isPristine } from 'redux-form';
import { BCC_FORM_ID } from '../libs/integrationConstants';
import { createSelector } from 'reselect';
import { getBccIntegrationFetching } from 'web/integrations/bcc/selectors/bccSelector';
import { getSlackIntegrationFetching } from 'web/integrations/slack/selectors/slackSelector';
import { getContactIntegrationFetching } from 'web/contactIntegrations/selectors/contactIntegrationsSelectors';
import { getBoxIntegrationFetching } from 'web/integrations/box/selectors/boxSelectors';
import { isHighSpotChecked as isHighspotAllowedToUser } from 'web/settings/adminSettings/general/selectors/generalPageSelectors';

export const isIntegrationsSafeToChangeRoute = (state) =>
  isPristine(BCC_FORM_ID)(state);

export const isIntegrationsLoading = (state) =>
  getBccIntegrationFetching(state) ||
  getSlackIntegrationFetching(state) ||
  getBoxIntegrationFetching(state) ||
  isContentPartnersLoading(state) ||
  getContactIntegrationFetching(state);

export const canShowHighspotCard = createSelector(
  [isHighspotAllowedToUser],
  (isAllowed) => isAllowed
);

export const isContentPartnersLoading = (state) =>
  state.contentPartners.loading;
export const isContentPartnersLoaded = (state) => state.contentPartners.loaded;
