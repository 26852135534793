import { EMAIL_THROTTLING_TIME_UNITS } from 'web/settings/adminSettings/general/libs/generalPageConstants';

export const SMTP_DELIVERY_CHANNEL = 'smtp';
export const DEFAULT_DELIVERY_CHANNEL = null;

export const DeliveryChannelProviders = {
  exchangeOnPrem: 'exchange_onprem',
  gmail: 'google_oauth2',
  office365: 'office365',
  smtp: 'smtp',
};

const [{ value: minute }, { value: second }] = EMAIL_THROTTLING_TIME_UNITS;
export const DeliveryChannelRecommendedSettings = {
  [DeliveryChannelProviders.gmail]: {
    emailsAmount: '2',
    timeAmount: '1',
    timeUnit: second,
  },
  [DeliveryChannelProviders.office365]: {
    emailsAmount: '30',
    timeAmount: '1',
    timeUnit: minute,
  },
};
