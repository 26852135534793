import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Label from 'components/label';
import { LABEL_WIDTH } from '../../../libs/editIdentitySlideOutConstants';

function Details(props) {
  const { identityModel } = props;

  const sections = [
    {
      message: 'common.identity',
      labels: [
        {
          message: 'common.name',
          value: identityModel.name,
          isShown: true,
        },
        {
          message: 'common.title',
          value: identityModel.title,
          isShown: identityModel.title,
        },
        {
          message: 'common.company',
          value: identityModel.company,
          isShown: identityModel.company,
        },
      ],
    },
    {
      message: 'common.address',
      labels: [
        {
          message: 'common.email',
          value: identityModel.email,
          isShown: true,
        },
        {
          message: 'common.website',
          value: identityModel.website,
          isShown: identityModel.website,
        },
        {
          message: 'common.mailing',
          value: identityModel.address,
          isShown: identityModel.address,
        },
        {
          message: 'common.mobile',
          value: identityModel.mobile_phone,
          isShown: identityModel.mobile_phone,
        },
        {
          message: 'common.workPhone',
          value: identityModel.work_phone,
          isShown: identityModel.work_phone,
        },
      ],
    },
  ];

  return (
    <div className="identity-details">
      {sections.map((section) => (
        <div key={section.message} className="identity-section">
          <h4 className="identity-section-title">
            <FormattedMessage id={section.message} />
          </h4>
          {section.labels.map(
            (label) =>
              label.isShown && (
                <div className="identity-field-item" key={label.message}>
                  <Label
                    id={label.message}
                    className="identity-field-label"
                    classWrapper="identity-field"
                    classChildren="identity-field-label-child"
                    messageKey={label.message}
                    sideLabel
                    width={LABEL_WIDTH}
                  >
                    <span>{label.value}</span>
                  </Label>
                </div>
              )
          )}
          <br className="clear" />
        </div>
      ))}
    </div>
  );
}

Details.propTypes = {
  intl: intlShape.isRequired,
  identityModel: PropTypes.object,
};

export default injectIntl(Details);
