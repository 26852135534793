import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Icon from 'components/buttons/icon';
import HoverTooltip from 'components/hoverTooltip';
import { canBeMarkedSuccessful } from 'web/commandCenter/helpers/emailHelpers';
import { PitchStates } from 'web/emails/libs/emailsConstants';
import './actionEmailCell.scss';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';
import { CommandCenterPopupIds } from 'web/commandCenter/libs/commandCenterConstants';
import { navigateToComposeDraft } from 'web/services/routerService';

const ActionEmailCell = ({
  intl: { formatMessage },
  actionCreators: {
    archiveEmail,
    markSuccess,
    openPopup,
    retrySend,
    unarchiveEmail,
  },
  commandCenterEmailsSubTab,
  rowData,
}) => {
  const clickArchiveEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { id } = rowData;

    archiveEmail(id);
  };

  const clickUnarchiveEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { id } = rowData;

    unarchiveEmail(id);
  };

  const clickEditEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { id } = rowData;

    navigateToComposeDraft(id);
  };

  const isArchiveSubTab =
    commandCenterEmailsSubTab ===
    CommandCenterEmailsTabsEnum.sent.subTabs.archived.value;

  const clickMarkSuccess = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { id, state, success } = rowData;

    if (canBeMarkedSuccessful(state) && !success) {
      markSuccess(id);
    }
  };

  const clickDeleteEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { id, email, name, subject, workflow_id, person_id } = rowData;

    if (!workflow_id) {
      openPopup(CommandCenterPopupIds.emailDeleteConfirmation, {
        email,
        emailId: id,
        personName: name,
        subject,
      });
    } else {
      openPopup(CommandCenterPopupIds.workflowEmailDeleteConfirmation, {
        emailId: id,
        person_id,
      });
    }
  };

  const undeliveredSubTabs = [
    CommandCenterEmailsTabsEnum.undelivered.subTabs.failed.value,
    CommandCenterEmailsTabsEnum.undelivered.subTabs.bounced.value,
    CommandCenterEmailsTabsEnum.undelivered.subTabs.spam.value,
  ];

  const subTabsWithEditAction = [
    CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value,
    CommandCenterEmailsTabsEnum.pending.subTabs.drafts.value,
  ];

  const subTabsWithDeleteAction = [
    ...undeliveredSubTabs,
    CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value,
    CommandCenterEmailsTabsEnum.pending.subTabs.drafts.value,
    CommandCenterEmailsTabsEnum.sent.subTabs.archived.value,
  ];

  const showDeleteButton = subTabsWithDeleteAction.includes(
    commandCenterEmailsSubTab
  );

  const showEditButton = subTabsWithEditAction.includes(
    commandCenterEmailsSubTab
  );

  const disableDeleteButton =
    undeliveredSubTabs.includes(commandCenterEmailsSubTab) &&
    !!rowData.workflow_id;

  const clickResendEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { id } = rowData;

    if (id) {
      retrySend(id);
    }
  };

  const resendStates = [
    PitchStates.bounced,
    PitchStates.failedDelivery,
    PitchStates.spam,
  ];
  const canResend = resendStates.some(
    (resendState) => resendState === rowData.state
  );

  const getDeleteButton = () =>
    disableDeleteButton ? (
      <HoverTooltip
        className="disabled-tooltip"
        delayHide={0}
        textId="web.emails.removeDisabledTooltip"
      >
        <Icon
          classes="pitch-icon pitch-icon-delete"
          color="gray"
          onClick={clickDeleteEmail}
          disabled
        />
      </HoverTooltip>
    ) : (
      <Icon
        classes="pitch-icon pitch-icon-delete"
        color="gray"
        onClick={clickDeleteEmail}
        title={formatMessage({ id: 'common.delete' })}
      />
    );

  return (
    <div className="action-email-cell">
      {isArchiveSubTab && (
        <Icon
          classes="pitch-icon pitch-icon-unarchive"
          color="gray"
          onClick={clickUnarchiveEmail}
          title={formatMessage({ id: 'common.unarchive' })}
        />
      )}
      {showEditButton && (
        <Icon
          classes="pitch-icon pitch-icon-edit"
          color="gray"
          onClick={clickEditEmail}
          title={formatMessage({ id: 'common.edit' })}
        />
      )}
      {showDeleteButton ? (
        getDeleteButton()
      ) : (
        <Fragment>
          <Icon
            classes="pitch-icon pitch-icon-success"
            color="gray"
            onClick={clickMarkSuccess}
            title={formatMessage({ id: 'common.successful' })}
          />
          <Icon
            classes="pitch-icon pitch-icon-archive"
            color="gray"
            onClick={clickArchiveEmail}
            title={formatMessage({ id: 'common.archive' })}
          />
        </Fragment>
      )}
      {canResend && (
        <Icon
          classes="pitch-icon pitch-icon-resend"
          color="gray"
          onClick={clickResendEmail}
          title={formatMessage({ id: 'common.resend' })}
        />
      )}
    </div>
  );
};

ActionEmailCell.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  commandCenterEmailsSubTab: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default injectIntl(ActionEmailCell);
