import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { getOnboardingStatus } from '../helpers/onboardingIntegrationsHelpers';
import './onboardingIntegrations.scss';

export default function OnboardingIntegrations(props) {
  const { userSettings } = props;

  return (
    <div className="my-profile-your-integrations">
      <h3 className="my-profile-your-integrations-title">
        <FormattedMessage id="web.settings.myProfile.yourOnboardingIntegrations.header" />
      </h3>
      <ul className="my-profile-your-integrations-list">
        <li>{getOnboardingData(userSettings)}</li>
      </ul>
    </div>
  );
}

function getOnboardingData(result) {
  if (!result) return null;

  const { icon, message, ...values } = getOnboardingStatus(result);

  return (
    <>
      <i className={`integration-icon integration-${icon}`} />
      <span className="integration-text">
        <FormattedHTMLMessage id={message} values={values} />
      </span>
    </>
  );
}

OnboardingIntegrations.defaultProps = {
  userSettings: {},
};

OnboardingIntegrations.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
  userSettings: PropTypes.object,
};
