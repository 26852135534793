import React from 'react';
import PropTypes from 'prop-types';
import Action, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { FormattedMessage } from 'react-intl';
import { navigateToGroup } from 'web/services/routerService';
import get from 'lodash/get';

import './personGroupsCell.scss';

const GROUP_DISPLAY = 2;

const PersonGroupsCell = ({ idProperty, openPersonGroupsModal, rowData }) => {
  const { groups = [] } = rowData;

  const onClickShowAll = (event) => {
    const id = get(rowData, idProperty);

    event.stopPropagation();
    event.preventDefault();
    openPersonGroupsModal(id, rowData);
  };

  const getGroupLinks = () =>
    groups
      .slice(0, GROUP_DISPLAY)
      .map(({ group_id: groupId, group_name: name }) => {
        const maxGroupChars = 5;
        const linkText =
          name.length > maxGroupChars
            ? `${name.slice(0, maxGroupChars)}...`
            : name;

        const onClickLink = (event) => {
          event.stopPropagation();
          event.preventDefault();
          navigateToGroup(groupId);
        };

        return (
          <a className="group-link" key={groupId} onClick={onClickLink}>
            {linkText}
          </a>
        );
      })
      .reduce(
        (prev, curr) => (prev.length > 0 ? [prev, ', ', curr] : [curr]),
        []
      );

  const getModalLink = () => {
    const groupLength = groups.length;

    if (groupLength > GROUP_DISPLAY) {
      return (
        <span>
          <FormattedMessage id="common.andOxfordComma" />
          <a onClick={onClickShowAll}>
            <FormattedMessage
              id="common.numMore"
              values={{ num: groupLength - GROUP_DISPLAY }}
            />
          </a>
        </span>
      );
    } else if (groupLength > 0 && groupLength <= GROUP_DISPLAY) {
      return (
        <Action
          className="show-btn"
          color={ButtonColors.gray}
          onClick={onClickShowAll}
          size={ButtonSizes.small}
        >
          <FormattedMessage id="common.show" />
        </Action>
      );
    }

    return null;
  };

  return (
    <div className="flex flex--align-middle">
      <div className="person-groups-cell">
        {getGroupLinks()}
        {getModalLink()}
      </div>
    </div>
  );
};

PersonGroupsCell.propTypes = {
  idProperty: PropTypes.string,
  openPersonGroupsModal: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};

PersonGroupsCell.defaultProps = {
  idProperty: 'id',
};

export default PersonGroupsCell;
