import React from 'react';
import PropTypes from 'prop-types';
import './campaignDetailsHeader.scss';
import CampaignDetailsHeaderTitle from '../campaignDetailsHeaderTitle/campaignDetailsHeaderTitle';
import CampaignDetailsHeaderNav from '../campaignDetailsHeaderNav/campaignDetailsHeaderNav';

const CampaignDetailsHeader = ({
  account,
  actionCreators,
  editName,
  isEditingName,
  onCampaignTabSelected,
  selectedCampaign,
  selectedDetailsTab,
  settings,
  tasksCount,
  updateCampaign,
  campaignsPeopleLength,
  userCanEditCampaigns,
}) => (
  <div className="campaign-details-header">
    <CampaignDetailsHeaderTitle
      actionCreators={actionCreators}
      editName={editName}
      isEditingName={isEditingName}
      selectedCampaign={selectedCampaign}
      selectedDetailsTab={selectedDetailsTab}
      settings={settings}
      updateCampaign={updateCampaign}
      userCanEditCampaigns={userCanEditCampaigns}
    />
    <CampaignDetailsHeaderNav
      account={account}
      onCampaignTabSelected={onCampaignTabSelected}
      selectedCampaign={selectedCampaign}
      selectedDetailsTab={selectedDetailsTab}
      tasksCount={tasksCount}
      campaignsPeopleLength={campaignsPeopleLength}
    />
  </div>
);

CampaignDetailsHeader.propTypes = {
  campaignsPeopleLength: PropTypes.number.isRequired,
  onCampaignTabSelected: PropTypes.func.isRequired,
  selectedDetailsTab: PropTypes.string.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  isEditingName: PropTypes.bool.isRequired,
  tasksCount: PropTypes.number.isRequired,
  settings: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  editName: PropTypes.func.isRequired,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignDetailsHeader.defaultProps = {
  userCanEditCampaigns: true,
};

export default CampaignDetailsHeader;
