import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AddContactSlideOutWidth } from '../libs/addContactSlideOutConstants';
import { closeAddContact } from '../actionCreators/addContactSlideOutActionCreators';
import SlideOutPane, {
  PaneBackgroundColors,
} from 'components/layouts/slideOutPane';
import AddContactContainer from 'web/person/containers/addContactContainer';

class AddContactSlideOutContainer extends Component {
  componentWillMount() {
    this.actionCreators = bindActionCreators(
      {
        closeAddContact,
      },
      this.props.dispatch
    );
  }

  componentWillUnmount() {
    this.actionCreators.closeAddContact();
  }

  onBack = () => {
    this.actionCreators.closeAddContact();
  };

  render() {
    const { open } = this.props;

    return (
      <SlideOutPane
        backgroundColor={PaneBackgroundColors.white}
        className="add-contact-slide-out"
        open={open}
        width={AddContactSlideOutWidth}
      >
        <AddContactContainer onBack={this.onBack} {...this.props} />
      </SlideOutPane>
    );
  }
}

AddContactSlideOutContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    open: state.addContactSlideOutIsOpen,
  };
}

export default connect(mapStateToProps)(AddContactSlideOutContainer);
