import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import TextCell from 'table/modules/cells/textCell';
import DotaCell from '../dotaCell';
import { TableProperties } from '../../libs/domainBlocksConstants';
import InputText from 'components/inputText';
import ToutTable from 'table/modules/toutTable';
import { alphabetical } from 'table/modules/sorting';
import Action, { ButtonSizes } from 'components/buttons/action';
import { getImageUrl } from 'web/libs/constants';
import './domainBlocks.scss';

class DomainBlocks extends Component {
  handleInputChange = (e) => {
    const {
      actionCreators: { setNewDomainBlock, hideErrorMessage },
    } = this.props;
    hideErrorMessage();
    setNewDomainBlock(e.target.value);
  };

  deleteDomainBlock = (domainBlock) => {
    const {
      actionCreators: { destroyDomainBlock },
    } = this.props;

    destroyDomainBlock(domainBlock);
  };

  handleSubmit = () => {
    const {
      actionCreators: { postDomainBlock },
      newDomainBlock,
    } = this.props;
    if (newDomainBlock) postDomainBlock();
  };

  dateFormattedBlockedDomains = () => {
    const { blockedDomains } = this.props;

    return blockedDomains.map((blockedDomain) => ({
      ...blockedDomain,
      date_added: moment(blockedDomain.date_added).format('LL'),
    }));
  };

  getColumns = () => {
    const {
      intl: { formatMessage },
    } = this.props;

    return [
      {
        flex: true,
        id: '1',
        name: formatMessage({
          id: 'web.settings.adminSettings.general.domainBlocks.domain',
        }),
        rowCell: {
          component: TextCell,
          metadata: { property: 'domain' },
        },
        sorting: alphabetical('domain'),
        width: TableProperties.standardColumnWidth,
      },
      {
        flex: true,
        id: '2',
        name: formatMessage({
          id: 'web.settings.adminSettings.general.domainBlocks.addedBy',
        }),
        rowCell: {
          component: TextCell,
          metadata: { property: 'added_by' },
        },
        sorting: alphabetical('added_by'),
        width: TableProperties.standardColumnWidth,
      },
      {
        flex: true,
        id: '3',
        name: formatMessage({
          id: 'web.settings.adminSettings.general.domainBlocks.dateAdded',
        }),
        rowCell: {
          component: TextCell,
          metadata: { property: 'date_added' },
        },
        sorting: (rowData) => rowData.date_added,
        width: TableProperties.standardColumnWidth,
      },
      {
        flex: true,
        id: '4',
        name: formatMessage({ id: 'common.actions' }),
        rowCell: {
          component: DotaCell,
          metadata: {
            disabled: false,
            options: [
              {
                label: formatMessage({ id: 'common.delete' }),
                onClick: this.deleteDomainBlock,
                value: 'delete',
              },
            ],
            property: 'id',
          },
        },
        width: TableProperties.lastColumnWidth,
      },
    ];
  };

  getZeroStateAttributes = () => {
    const {
      intl: { formatMessage },
    } = this.props;

    return {
      bodyText: formatMessage({
        id: 'web.settings.adminSettings.general.domainBlocks.zeroState.body',
      }),
      titleText: formatMessage({
        id: 'web.settings.adminSettings.general.domainBlocks.zeroState.title',
      }),
    };
  };

  getDomainBlockError = () => {
    const {
      newDomainBlockError: { data, available },
    } = this.props;
    let error = '';

    if (available) {
      if (data === TableProperties.domainErrorSpecialChars) {
        error = (
          <FormattedMessage id="web.settings.adminSettings.general.domainBlocks.specialChars" />
        );
      } else if (data === TableProperties.domainError) {
        error = (
          <FormattedMessage id="web.settings.adminSettings.general.domainBlocks.invalidDomain" />
        );
      } else if (data[TableProperties.domainErrorKey]) {
        error = (
          <FormattedMessage id="web.settings.adminSettings.general.domainBlocks.errorText" />
        );
      } else {
        // eslint-disable-next-line prefer-destructuring
        error = Object.values(data)[0];
      }
    }
    return error;
  };

  componentWillUnmount() {
    const {
      actionCreators: { hideErrorMessage, clearNewDomainBlock },
    } = this.props;
    hideErrorMessage();
    clearNewDomainBlock();
  }

  render() {
    const {
      newDomainBlock,
      newDomainBlockError,
      loadingBlockedDomains,
      intl: { formatMessage },
    } = this.props;
    const items = this.dateFormattedBlockedDomains();
    const hasErrorClass = classNames({
      'has-error': newDomainBlockError.available,
    });
    const inputClasses = classNames(hasErrorClass, 'domain-block-input');

    return (
      <div>
        <h3>
          <FormattedMessage id="web.settings.adminSettings.general.domainBlocks.title" />
        </h3>

        <div className="margin-top-20">
          <span>
            <FormattedMessage id="web.settings.adminSettings.general.domainBlocks.notice" />
          </span>
        </div>

        <div className="margin-top-20" id="domain-blocks">
          <ToutTable
            actionHeaderHeight={40}
            columns={this.getColumns()}
            items={items}
            loading={loadingBlockedDomains}
            loadingGetUrl={getImageUrl}
            maxHeight={TableProperties.maxRowHeight}
            pagination={{
              currentPage: 1,
              perPage: 10,
            }}
            row={{
              height: TableProperties.standardRowHeight,
              onClick: TableProperties.noOp,
            }}
            search={this._getSearch()}
            tableId={TableProperties.tableId}
            zeroState={this.getZeroStateAttributes()}
            allItems
          />
        </div>

        <div className="domain-blocks-submission-form">
          <div className="domain-to-be-blocked-wrapper">
            <InputText
              autoComplete="off"
              className={inputClasses}
              id="domain-to-be-blocked"
              onChange={this.handleInputChange}
              placeholder={formatMessage({
                id: 'web.settings.adminSettings.general.domainBlocks.example',
              })}
              type="text"
              value={newDomainBlock}
              toutUiStyle
            />

            <span className="domain-block-error">
              {this.getDomainBlockError()}
            </span>
          </div>

          <div className="submit-wrapper">
            <Action onClick={this.handleSubmit} size={ButtonSizes.input}>
              <FormattedMessage id="web.settings.adminSettings.general.domainBlocks.actionText" />
            </Action>
          </div>
        </div>
      </div>
    );
  }

  searchOnChange = (value) => {
    const {
      actionCreators: { handleSearchDomainChange },
    } = this.props;

    handleSearchDomainChange(value);
  };

  _getSearch = () => {
    const {
      actionCreators: { resetDomainBlocksSearch },
      domainSearch,
      intl: { formatMessage },
    } = this.props;

    return {
      noDropdown: true,
      onChange: this.searchOnChange,
      onClear: resetDomainBlocksSearch,
      placeholderText: formatMessage({
        id: 'web.tables.domain.searchPlaceholder',
      }),
      value: domainSearch,
    };
  };
}

DomainBlocks.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  blockedDomains: PropTypes.array,
  domainSearch: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  loadingBlockedDomains: PropTypes.bool,
  newDomainBlock: PropTypes.string,
  newDomainBlockError: PropTypes.object,
};

DomainBlocks.defaultProps = {
  blockedDomains: [],
  loadingBlockedDomains: false,
  newDomainBlock: '',
  newDomainBlockError: { available: false },
};

export default injectIntl(DomainBlocks);
