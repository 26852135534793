import React from 'react';
import PropTypes from 'prop-types';
import { ColorClasses } from 'libs/constantsStyles';

const Success = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      height={height}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{`.a { fill: ${color} !important; }`}</style>
      </defs>
      <rect fill={color} height="18" id="Canvas" opacity="0" width="18" />
      <rect fill="#ff13dc" height="18" id="Canvas" opacity="0" width="18" />
      <path
        className="a"
        d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1Zm5.333,4.54L8.009,13.6705a.603.603,0,0,1-.4375.2305H7.535a.6.6,0,0,1-.4245-.1755L3.218,9.829a.6.6,0,0,1-.00147-.84853L3.218,8.979l.663-.6625A.6.6,0,0,1,4.72953,8.315L4.731,8.3165,7.4,10.991l5.257-6.7545a.6.6,0,0,1,.8419-.10586L13.5,4.1315l.7275.5685A.6.6,0,0,1,14.333,5.54Z"
      />
    </svg>
  );
};

Success.defaultProps = {
  color: ColorClasses.white,
  height: 18,
  width: 18,
};

Success.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Success;
