import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import CommandCenterContainer from 'web/commandCenter/containers/commandCenterContainer';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import './commandCenterTasksGrigContainer.scss';

const TasksGrid = () => (
  <div key="tasks-grid-container" className="tasks-grid-container">
    <CommandCenterContainer
      withTopNav={false}
      titleId="tasksList.helperText"
    />
  </div>
);

export default compose(
  withCommonModals,
  connect(null),
  injectIntl
)(TasksGrid);
