import ViewersActionTypes from 'web/sidebar/viewers/libs/viewersActionTypes';

const initFilterInput = '';
export const sidebarViewerFilterInput = (
  state = initFilterInput,
  { type = '', inputValue = initFilterInput }
) => {
  switch (type) {
    case ViewersActionTypes.sidebarViewer.sidebarFilterInput.set:
      return inputValue;
    default:
      return state;
  }
};
