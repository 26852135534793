import React from 'react';
import PropTypes from 'prop-types';
import BaseText from 'components/inputs/text';
import { FormattedMessage } from 'react-intl';
import './textWithErrors.scss';

const Text = ({ input, meta, ...other }) => (
  <div className="form-text-with-errors-wrapper">
    <BaseText {...input} {...other} error={!!meta.error} />
    {meta.dirty &&
      meta.error && (
        <div className="error-text">
          <FormattedMessage id={meta.error.messageId} />
        </div>
      )}
  </div>
);

Text.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

Text.defaultProps = {
  input: {},
  meta: {},
};

export default Text;
