import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './marketoCampaignNameCell.scss';

class MarketoCampaignNameCell extends Component {
  render() {
    const {
      rowData: { name: campaignName },
    } = this.props;

    return (
      <div className="marketo-campaign-name-cell">
        <div className="marketo-campaign-name-cell-icon" />
        <div className="text-overflow">{campaignName}</div>
      </div>
    );
  }
}

MarketoCampaignNameCell.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default MarketoCampaignNameCell;
