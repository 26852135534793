import TimezonesActionTypes from '../libs/timezonesActionTypes';

const EMPTY_ARRAY = [];

export const timezones = (
  state = EMPTY_ARRAY,
  { type = '', timezones: tzs = [] }
) => {
  switch (type) {
    case TimezonesActionTypes.setTimezones:
      return tzs;
    default:
      return state;
  }
};
