import { showLoading, hideLoading, trackEvent } from './popupActionCreators';
import {
  getOpportunities as getOpportunitiesCall,
  postOpportunity as postOpportunityCall,
  putOpportunity as putOpportunityCall,
} from '../services/api';
import {
  prepareParams,
  parseErrors,
} from 'web/modals/gong/lib/gongOpportunities';
import { GongEvents } from 'web/libs/mixpanelEvents';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { resizeToGongCelebration } from 'web/services/windowService';

export function changeAccountName(accountName) {
  return {
    type: ActionTypes.gong.changeAccountName,
    data: accountName,
  };
}

export function changeDealSize(dealSize) {
  return {
    type: ActionTypes.gong.changeDealSize,
    data: dealSize,
  };
}

export function receiveOpportunities(opportunities) {
  return {
    type: ActionTypes.gong.receiveOpportunities,
    data: opportunities,
  };
}

export function successResponse(response) {
  return {
    type: ActionTypes.gong.successResponse,
    data: response,
  };
}

export function failureResponse(response) {
  return {
    type: ActionTypes.gong.failureResponse,
    data: response,
  };
}

export function clearError() {
  return {
    type: ActionTypes.gong.clearError,
  };
}

export function startCelebration() {
  return (dispatch, getState) => {
    if (getState().gongWindowMode) {
      resizeToGongCelebration();
    }

    dispatch({
      type: ActionTypes.gong.celebration,
      data: true,
    });
  };
}

export function stopCelebration() {
  return {
    type: ActionTypes.gong.celebration,
    data: false,
  };
}

export function changeSelectedOpportunity(opportunity = {}) {
  return {
    type: ActionTypes.gong.changeSelectedOpportunity,
    data: opportunity,
  };
}

export function closeOpportunityInSalesforce(value) {
  return {
    type: ActionTypes.gong.closeOpportunityInSalesforce,
    data: value,
  };
}

export function getOpportunities() {
  return (dispatch) => {
    dispatch(showLoading());

    getOpportunitiesCall()
      .then((data) => {
        dispatch(hideLoading());
        dispatch(receiveOpportunities(data));
      })
      .catch(() => {
        dispatch(hideLoading());
        dispatch(receiveOpportunities([]));
      });
  };
}

export function postOpportunity(params) {
  return (dispatch, getState) => {
    dispatch(showLoading());

    params = prepareParams(params, getState());
    postOpportunityCall(params)
      .then(() => {
        dispatch(hideLoading());
        dispatch(startCelebration());
        dispatch(trackEvent(GongEvents.ring));
      })
      .catch((err) => {
        dispatch(hideLoading());
        const errors = parseErrors(err);
        dispatch(failureResponse(errors));
      });
  };
}

export function putOpportunity(params) {
  return (dispatch, getState) => {
    dispatch(showLoading());

    params = prepareParams(params, getState());
    putOpportunityCall(params)
      .then(() => {
        dispatch(hideLoading());
        dispatch(startCelebration());
        dispatch(trackEvent(GongEvents.ring));
      })
      .catch((err) => {
        dispatch(hideLoading());
        const errors = parseErrors(err);
        dispatch(failureResponse(errors));
      });
  };
}
