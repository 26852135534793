import React from 'react';
import PropTypes from 'prop-types';
import './taskEventAction.scss';

const TaskEventAction = (props) => {
  const { completeTask } = props;
  return (
    <div className="task-event-action">
      <div className="tout-icon-check" onClick={completeTask} />
    </div>
  );
};

TaskEventAction.propTypes = {
  completeTask: PropTypes.func,
};

export default TaskEventAction;
