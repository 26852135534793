import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DefaultSelect from 'components/selects/default/defaultSelect';
import './referenceDateSmartFilter.scss';

const ReferenceDateSmartFilter = ({
  onChange,
  selected,
  isDisabled,
  items,
}) => (
  <div className="reference-date-smart-filter">
    <div className="reference-date-smart-filter-label">
      <FormattedMessage id="web.commandCenter.advancedSearches.referenceDate" />
    </div>
    <DefaultSelect
      className="reference-date-smart-filter-combo-box"
      disabled={isDisabled}
      items={items}
      onChange={onChange}
      selected={selected}
    />
  </div>
);

ReferenceDateSmartFilter.propTypes = {
  isDisabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

ReferenceDateSmartFilter.defaultProps = {
  isDisabled: false,
};

export default ReferenceDateSmartFilter;
