import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import './multiSearchResults.scss';

class MultiSearchResults extends Component {
  render() {
    const formattedResults = this._getFormattedResults();
    return (
      <div className="multi-search-result-container">
        {formattedResults.map(({ data, name, index, isLoading }) => (
          <div key={`multi-search-results-${index}`}>
            <div className="multi-search-category">{name}</div>
            {!isLoading && <ul className="multi-search-results">{data}</ul>}
            {isLoading && (
              <div className="multi-search-result-loader">
                <LoadingSpinner imageUrl={getImageUrl} size={50} />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  // todo: move to selector
  _getFormattedResults = () => {
    const {
      searchResults,
      isCustomClickHandler,
      onSearchResultClick,
    } = this.props;
    const totalResults = [];

    for (let i = 0; i < searchResults.length; i++) {
      if (!isEmpty(searchResults[i].collection) || searchResults[i].isLoading) {
        const currentSearchResults = searchResults[i];
        const searchResultElements = [];
        for (let j = 0; j < size(currentSearchResults.collection); j++) {
          const data = currentSearchResults.collection[j];
          searchResultElements.push(
            <li
              className="multi-search-list-item"
              data-search-results-index={i}
              data-current-category-results-index={j}
              onClick={
                isCustomClickHandler
                  ? () => onSearchResultClick(data)
                  : this._onListItemClick
              }
              key={`search-list-${j}`}
            >
              {data.name}
            </li>
          );
        }
        totalResults.push({
          index: i,
          name: currentSearchResults.name.toUpperCase(),
          data: searchResultElements,
          isLoading: currentSearchResults.isLoading,
        });
      }
    }
    return totalResults;
  };

  _onListItemClick = (e) => {
    const { searchResults, onSearchResultClick } = this.props;
    const currentCategoryResults =
      searchResults[
        e.currentTarget.dataset.searchResultsIndex ||
          e.target.dataset.searchResultsIndex
      ];

    if (currentCategoryResults && currentCategoryResults.collection) {
      const data =
        currentCategoryResults.collection[
          e.currentTarget.dataset.currentCategoryResultsIndex ||
            e.target.dataset.currentCategoryResultsIndex
        ];
      onSearchResultClick({ type: currentCategoryResults.type, item: data });
    }
  };
}

MultiSearchResults.propTypes = {
  onSearchResultClick: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  isCustomClickHandler: PropTypes.bool,
};

MultiSearchResults.defaultProps = {
  isCustomClickHandler: false,
};

export default MultiSearchResults;
