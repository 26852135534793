import BulkActionTypes from 'web/bulkUpdate/libs/bulkUpdateActionTypes';
import queue from 'async/queue';
import { getUserChannel } from 'web/services/pusherService';
import { openBulkUpdateProgressPopup } from 'web/bulkUpdate/actionCreators/bulkUpdatePopupActionCreators';
import { PusherEvents } from 'web/libs/constants';

const queuedActionCreator = queue(
  ({ bulkAction, successes, errors, dispatch }, cb) => {
    if (bulkAction) {
      dispatch(bulkAction(successes, errors));
    }
    cb();
  },
  100
);

export const handleBulkUpdateEvent = (
  data,
  jobId,
  bulkAction,
  bulkActionComplete
) => (dispatch) => {
  const {
    job_id: eventJobId,
    percent_done: percentComplete,
    batch: { successes, errors, error_types: errorTypes },
  } = data;

  if (jobId === eventJobId) {
    queuedActionCreator.push({
      bulkAction,
      successes,
      errors,
      dispatch,
      bulkActionComplete,
    });
    dispatch({
      type: BulkActionTypes.progress.set,
      percentComplete,
    });
    dispatch({
      type: BulkActionTypes.results.set,
      successes,
      errors,
      errorTypes,
    });
  }
};

export const bindBulkUpdatePusherEvents = (
  jobId,
  bulkAction,
  bulkActionComplete
) => (dispatch) => {
  const channel = getUserChannel();

  const bulkUpdateProgressCallback = (data) => {
    dispatch(
      handleBulkUpdateEvent(data, jobId, bulkAction, bulkActionComplete)
    );
  };

  const bulkUpdateCompleteCallback = () => {
    dispatch({
      type: BulkActionTypes.complete.set,
      isComplete: true,
    });

    dispatch(bulkActionComplete());
    channel.unbind(PusherEvents.bulkUpdateComplete, this);
    channel.unbind(PusherEvents.bulkUpdateProgress, bulkUpdateProgressCallback);
  };

  channel.bind(PusherEvents.bulkUpdateProgress, bulkUpdateProgressCallback);
  channel.bind(PusherEvents.bulkUpdateComplete, bulkUpdateCompleteCallback);
};

export const initializeBulkUpdate = (
  jobId,
  bulkAction,
  modalTitle = 'common.update',
  bulkActionComplete = () => {}
) => (dispatch) => {
  dispatch(openBulkUpdateProgressPopup(modalTitle));
  dispatch(bindBulkUpdatePusherEvents(jobId, bulkAction, bulkActionComplete));
};
