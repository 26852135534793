import { createSelector } from 'reselect';
import { getSmartFilterById } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';
import {
  PEOPLE_GROUP_FILTER_ID,
  emailGridPeopleGroupFilterAnyValue,
} from 'web/commandCenter/modules/whoSmartFilter/libs/whoSmartFilterConstants';

const getUserGroups = (state) => state.groups;
const getGroupFilterInputValue = (state) =>
  state.peopleGroupSmartFilterInputValue;

export const getPeopleGroups = createSelector([getUserGroups], (groups) => {
  const { value, valueLabel: label } = emailGridPeopleGroupFilterAnyValue;
  return [
    { label, value },
    ...groups.map(({ id, name }) => ({ label: name, value: id })),
  ];
});

export const getEmailGridPeopleGroupFilterValue = createSelector(
  [getSmartFilterById(PEOPLE_GROUP_FILTER_ID)],
  (filter) =>
    (filter &&
      filter.filterValues &&
      filter.filterValues.length &&
      filter.filterValues[0].value) ||
    emailGridPeopleGroupFilterAnyValue.value
);

export const getPeopleGroupSmartFilterInputValue = createSelector(
  [getSmartFilterById(PEOPLE_GROUP_FILTER_ID), getGroupFilterInputValue],
  (filter, { inputMode, inputValue }) => {
    const filterValue =
      filter &&
      filter.filterValues &&
      filter.filterValues.length &&
      filter.filterValues[0];

    return inputMode
      ? inputValue
      : (filterValue && filterValue.valueLabel) ||
          emailGridPeopleGroupFilterAnyValue.valueLabel;
  }
);
