import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { setDefaultProcessValues } from 'web/modals/salesforceCustomization/actionCreators/salesforceCustomizationActionCreators';
import {
  wizardGoStart,
  wizardGoNext,
  wizardGoBack,
  wizardInstall,
  setProfileUser,
  updateProfileAvailableUsers,
  switchAllProfileAvailableUsers,
} from 'web/modals/salesforceCustomization/actionCreators/salesforceCustomizationWizardActionCreators';
import {
  isFirstStep,
  isLastStep,
  getCustomizationItems,
} from 'web/modals/salesforceCustomization/selectors/salesforceCustomizationWizardSelectors';
import { getProfiles } from 'web/modals/salesforceCustomization/selectors/salesforceCustomizationSelectors';
import SalesforceCustomizationWizard from 'web/modals/salesforceCustomization/components/wizard';

class SalesforceCustomizationWizardContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = bindActionCreators(
      {
        onStart: wizardGoStart,
        onNext: wizardGoNext,
        onBack: wizardGoBack,
        onInstall: wizardInstall,
        onClose: closePopup,
        resetProcessValues: setDefaultProcessValues,
        setProfileUser,
        updateProfileAvailableUsers,
        switchAllProfileAvailableUsers,
      },
      dispatch
    );
  }

  componentWillMount() {
    const { onStart, resetProcessValues } = this.actionCreators;

    onStart();
    resetProcessValues();
  }

  render() {
    return (
      <SalesforceCustomizationWizard
        {...this.props}
        actionCreators={this.actionCreators}
      />
    );
  }
}

SalesforceCustomizationWizardContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentStep: state.salesforceCustomization.wizardStep,
  isFirstStep: isFirstStep(state),
  isLastStep: isLastStep(state),
  items: getCustomizationItems(state),
  profiles: getProfiles(state),
  loading: state.salesforceCustomizationProfiles.loading,
});

export default connect(mapStateToProps)(SalesforceCustomizationWizardContainer);
