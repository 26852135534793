import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import EmailConnectionFeatureList from 'web/settings/emailConnection/components/emailConnectionFeatureList/emailConnectionFeatureList';
import Button, { ButtonColors } from 'components/buttons/action';
import LoadingSpinner from 'components/loadingSpinner';
import HoverTooltip from 'components/hoverTooltip';
import { getImageUrl } from 'web/libs/constants';
import { determineProviderText } from 'web/settings/emailConnection/helpers/emailConnectionHelpers';
import { EmailProviders } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import ExchangeOnpremConnected from 'web/settings/emailConnection/components/exchangeOnpremConnected';
import './emailConnectionConnected.scss';

class EmailConnectionConnected extends Component {
  static propTypes = {
    emailConnectionAuth: PropTypes.object.isRequired,
    actionCreators: PropTypes.object.isRequired,
    emailLoadingStates: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { emailConnectionAuth } = props;

    this.state = {
      emailConnectionAuth,
    };
  }

  toggleSendCompliance = () => {
    this.setState((prevState) => ({
      emailConnectionAuth: {
        ...prevState.emailConnectionAuth,
        sendCompliance: !prevState.emailConnectionAuth.sendCompliance,
      },
    }));
  };

  saveAuthChanges = () => {
    const {
      actionCreators: { saveChanges },
    } = this.props;
    const { emailConnectionAuth } = this.state;

    saveChanges(emailConnectionAuth);
  };

  disableSaveChanges = () => {
    const {
      emailConnectionAuth: { sendCompliance: sendComplianceNew },
    } = this.state;

    const {
      emailConnectionAuth: { sendCompliance: sendComplianceOld },
    } = this.props;

    return sendComplianceNew === sendComplianceOld;
  };

  render() {
    const {
      emailConnectionAuth: {
        sendCompliance,
        service,
        addedAuthenticationAttributes,
        url,
      },
    } = this.state;

    const {
      emailLoadingStates: { isSavingChanges },
      actionCreators: { openDisconnectModal },
    } = this.props;
    const actionableFeatures = { sendCompliance };
    const actions = { toggleSendCompliance: this.toggleSendCompliance };
    const actionableFeaturesTexts = {
      sendComplianceText: (
        <FormattedMessage
          id="web.settings.emailConnection.featureList.sendCompliance"
          values={{ provider: determineProviderText(service) }}
        />
      ),
    };

    return (
      <div className="email-connection-connected">
        {isSavingChanges && (
          <LoadingSpinner imageUrl={getImageUrl} size={100} />
        )}

        <h3 className="email-connection-connected-header">
          <FormattedMessage id="web.settings.emailConnection.featureList.setup" />
        </h3>
        <div className="margin-bottom-full margin-top-full">
          <i className="tout-icon-tick connected-icon" />
          <span>
            <FormattedHTMLMessage
              id="web.settings.emailConnection.modal.connected.successfulInfo"
              values={{ provider: determineProviderText(service) }}
            />
          </span>
        </div>
        <p>
          <FormattedMessage id="web.settings.emailConnection.modal.connected.ableTo" />
        </p>
        <EmailConnectionFeatureList
          actions={actions}
          actionableFeatures={actionableFeatures}
          toggleSendCompliance={this.toggleSendCompliance}
          actionableFeaturesTexts={actionableFeaturesTexts}
          isConnected
        />

        {service === EmailProviders.onPremise && (
          <ExchangeOnpremConnected
            addedAuthenticationAttributes={addedAuthenticationAttributes}
            url={url}
          />
        )}

        <div className="action-buttons">
          <Button
            className="button"
            color={ButtonColors.blue}
            onClick={this.saveAuthChanges}
            disabled={this.disableSaveChanges()}
          >
            <FormattedMessage id="common.saveChanges" />
          </Button>
          <HoverTooltip
            className="email-disconnect-tooltip"
            delayHide={0}
            textId="web.settings.emailConnection.disconnect.tooltip"
          >
            <Button
              className="button"
              color={ButtonColors.red}
              onClick={openDisconnectModal}
            >
              <FormattedMessage id="common.disconnect" />
            </Button>
          </HoverTooltip>
        </div>
      </div>
    );
  }
}

export default EmailConnectionConnected;
