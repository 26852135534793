import React from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

const PeopleUnsubscribe = () => (
  <div className="people-delete-modal">
    <FormattedHTMLMessage id="web.peopleUnsubscribe.body" />
  </div>
);

export default injectIntl(PeopleUnsubscribe);
