import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18N from 'languages';
import './releaseNote.scss';

class ReleaseNote extends PureComponent {
  render() {
    const { url } = this.props;

    const releaseNoteBody = this._getReleaseNoteBody();

    return (
      <div className="release-note">
        {url ? (
          <a className="release-note-link" href={url} target="_blank">
            {releaseNoteBody}
          </a>
        ) : (
          <span className="release-note-nolink">{releaseNoteBody}</span>
        )}
      </div>
    );
  }

  _getReleaseNoteBody() {
    const {
      description,
      release_datetime: releaseDatetime,
      title,
    } = this.props;

    return (
      <div className="release-note-body">
        <h4>{title}</h4>
        <p>{description}</p>
        <span className="text-gray-dark">
          {moment(releaseDatetime).format(I18N.DateFormats.DATETIME_SHORT)}
        </span>
      </div>
    );
  }
}

ReleaseNote.propTypes = {
  description: PropTypes.string,
  release_datetime: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
};

ReleaseNote.defaultProps = {
  url: '',
};

export default ReleaseNote;
