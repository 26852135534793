import {
  getSalesforceApiCallsStats,
  getSalesforceIntegration,
} from 'web/salesforce/actionCreators/salesforceActionCreators';
import {
  shouldInvalidateApiCallsStats,
  shouldInvalidateIntegration,
} from 'web/salesforce/selectors/salesforceSelectors';

export const syncSettingsStartup = () => (dispatch, getState) => {
  if (shouldInvalidateApiCallsStats(getState())) {
    dispatch(getSalesforceApiCallsStats());
  }
  if (shouldInvalidateIntegration(getState())) {
    dispatch(getSalesforceIntegration());
  }
};
