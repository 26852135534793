/*  To use, wrap your component:
 *    export default withEventListener(options)(Component);
 *
 *  API:
 *    Options - Props passed specifically to EventListener (see component)
 *.     - useParentProps - allows the parent of the wrapped component to dynamically
 *.       pass down props that are then merged with the options and defaultProps.
 *    Component - React Component - component to be wrapped
 */

import React from 'react';
import PropTypes from 'prop-types';
import EventListener from './eventListener';

export default function withEventListener(eventListenerOptions = {}) {
  return function WithEventListener(WrappedComponent) {
    const componentName =
      WrappedComponent.displayName || WrappedComponent.name || 'NoName';
    EventListener.displayName = `EventListener${componentName}`;

    const Component = (props) => {
      let options = eventListenerOptions;
      if (props.useParentProps) {
        options = { ...eventListenerOptions, ...props };
      }
      return (
        <EventListener
          {...options}
          WrappedComponent={WrappedComponent}
          wrappedProps={props}
        />
      );
    };

    Component.displayName = `WithEventListener${componentName}`;
    Component.propTypes = {
      useParentProps: PropTypes.bool,
    };
    Component.defaultProps = {
      useParentProps: false,
    };

    return Component;
  };
}
