import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Group from '../group';
import Person from '../person';
import './peopleList.scss';

class PeopleList extends Component {
  render() {
    const peopleList = this.getPeopleListOrEmptyState();

    return <div className="peopleList">{peopleList}</div>;
  }

  getPeopleListOrEmptyState = () => {
    const { people } = this.props;
    if (people.length) {
      return this.getPeopleList();
    } else {
      return this.getZeroState();
    }
  };

  getPeopleList = () => {
    const { onPersonCheck, people, selectedIndex } = this.props;

    return people.map((person) => {
      if (person.members) {
        return (
          <Group
            group={person}
            key={`group-search-add-${person.id}`}
            onPersonCheck={onPersonCheck}
            selectedIndex={selectedIndex}
          />
        );
      } else {
        return (
          <Person
            isChecked={selectedIndex[person.id]}
            key={`person-search-add-${person.id}`}
            onCheckChange={onPersonCheck}
            person={person}
          />
        );
      }
    });
  };

  getZeroState = () => (
    <div className="people-list-empty-state">
      <h3 className="text-center text-blue">
        <FormattedMessage id="common.addPeople" />
      </h3>
      <h4 className="text-center text-gray margin-top-most">
        <FormattedMessage id="web.addToCampaign.emptyStateSubtitle" />
      </h4>
    </div>
  );
}

PeopleList.propTypes = {
  onPersonCheck: PropTypes.func.isRequired,
  people: PropTypes.array.isRequired,
  selectedIndex: PropTypes.object.isRequired,
};

export default PeopleList;
