import merge from 'lodash/merge';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import {
  CampaignListFilters,
  CampaignDetailsEnum,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { initFilterInput } from '../../templates/reducers/templatesSidebarFilterReducer';

export function campaignsTabInitStore(state = false, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.initStore:
      return true;
    default:
      return state;
  }
}

export function campaignsTabCampaignDayLoading(state = {}, action) {
  let temp = {};
  switch (action.type) {
    case ActionTypes.campaignsTab.state.campaignDayLoadStart:
      temp = merge({}, state, { [action.id]: true });
      return temp;
    case ActionTypes.campaignsTab.state.campaignDayLoadEnd:
      temp = merge({}, state);
      Reflect.deleteProperty(temp, action.id);
      return temp;
    default:
      return state;
  }
}

export function campaignsTabContentViewLoading(state = false, action) {
  switch (action.type) {
    case ActionTypes.api.cloneCampaign.request:
      return true;
    case ActionTypes.api.cloneCampaign.success:
    case ActionTypes.api.cloneCampaign.failure:
      return false;
    case ActionTypes.campaignsTab.state.contentViewLoading:
      return action.loading;
    default:
      return state;
  }
}

export function campaignsTabViewLoading(state = false, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.selectedDetailsTab:
      return false;
    case ActionTypes.campaignPeople.state.loading:
    case ActionTypes.campaignsTasks.state.loading:
      return action.data;
    default:
      return state;
  }
}

export function editCampaignName(state = false, action) {
  switch (action.type) {
    case ActionTypes.state.editCampaignName:
      return true;
    case ActionTypes.state.notEditingCampaignName:
      return false;
    default:
      return state;
  }
}

export function addingNewCampaignDay(state = false, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.addingNewDay:
      return true;
    case ActionTypes.campaignsTab.state.notAddingNewDay:
      return false;
    default:
      return state;
  }
}

const initCreateNewCampaignState = {
  category: '',
  name: '',
  skipWeekends: true,
  stopOnBounce: true,
  stopOnReply: true,
  successOnReply: false,
};

export function campaignsTabCreateNewCampaignState(
  state = initCreateNewCampaignState,
  action
) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.createNewCampaignChange:
      return { ...state, ...action.data };
    case ActionTypes.campaignsTab.state.createCampaign:
      return { ...state, ...action.update };
    case PopupActionTypes.close:
      return initCreateNewCampaignState;
    case ActionTypes.campaignsTab.state.campaignAlertOpen:
      return { id: action.id, values: action.values };
    case ActionTypes.campaignsTab.state.campaignAlertClose:
      return false;
    default:
      return state;
  }
}

export const initDeleteState = {
  admin: false,
  teamMembers: [],
  userActives: 0,
};
export function campaignsTabDeleteCampaignState(
  state = initDeleteState,
  action
) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.initDeleteCampaignState:
      return action.data;
    case PopupActionTypes.close:
      return initDeleteState;
    default:
      return state;
  }
}

export function campaignsTabSelectedDetailsTab(
  state = CampaignDetailsEnum.people.value,
  action
) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.selectedDetailsTab:
      return action.data || state;
    default:
      return state;
  }
}

export function campaignsTabEditingSteps(state = {}, action) {
  let temp = {};
  switch (action.type) {
    case ActionTypes.campaignsTab.state.campaignEditStateInit:
      return { ...state, [action.step.id || action.id]: action.step };
    case ActionTypes.campaignsTab.state.campaignEditStateDestroy:
      temp = { ...state };
      Reflect.deleteProperty(temp, action.stepId);
      return temp;
    default:
      return state;
  }
}

export function campaignsTabDayEditing(state = {}, action) {
  let currentCount = 0;
  switch (action.type) {
    case ActionTypes.campaignsTab.state.campaignEditStateInit:
      currentCount = state[action.step.day] || 0;
      return { ...state, [action.step.day]: currentCount + 1 };
    case ActionTypes.campaignsTab.state.campaignEditStateDestroy:
      currentCount = state[action.day] || 1;
      return { ...state, [action.day]: currentCount - 1 };
    default:
      return state;
  }
}

export function campaignsTabEditStates(state = {}, action) {
  let temp = {};
  switch (action.type) {
    case ActionTypes.campaignsTab.state.campaignEditStateInit:
      return { ...state, [action.id]: action.data };
    case ActionTypes.campaignsTab.state.campaignEditStateUpdate:
      return { ...state, [action.id]: { ...state[action.id], ...action.data } };
    case ActionTypes.campaignsTab.state.campaignEditStateDestroy:
      temp = { ...state };
      Reflect.deleteProperty(temp, action.id);
      return temp;
    default:
      return state;
  }
}

export function campaignsListFilter(state = CampaignListFilters.all, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.campaignsListFilter:
      return action.filter;
    default:
      return state;
  }
}

const initSettings = { setSlideOutMenuOpen: true, setupHorizontal: false };
export function campaignsTabSettings(state = initSettings, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.settings:
      return { ...state, ...action.state };
    case ActionTypes.campaignsTab.state.settingsSetupHorizontal:
      return { ...state, setupHorizontal: action.state };
    case ActionTypes.campaignsTab.state.settingsSlideOutMenuOpen:
      return { ...state, slideOutMenuOpen: action.state };
    default:
      return state;
  }
}

export function campaignsTabViewer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.setViewer:
      return action.viewer;
    default:
      return state;
  }
}

export const campaignViewAsFilterInput = (
  state = '',
  { type = '', inputValue = initFilterInput }
) => {
  switch (type) {
    case ActionTypes.campaignsTab.state.setViewAsFilterInput:
      return inputValue;
    default:
      return state;
  }
};
