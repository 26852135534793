import { basePost, basePut, baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { getNewAccountData } from '../helpers/createAccountHelper';

export const sendNewAccountInfo = (formValues, invitation_code) => {
  const newAccountData = getNewAccountData(formValues, invitation_code);

  return new Promise((resolve, reject) => {
    basePost(Urls.createNewAccount, newAccountData, resolve, reject);
  });
};

export const sendAccountActivation = (formValues, code) => {
  const formData = getNewAccountData(formValues, code);

  return new Promise((resolve, reject) => {
    basePut(Urls.setNewPassword(code), formData, resolve, reject);
  });
};

export const sendEmailAdminInvitation = (token) => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.invitationsEmail(token), {}, resolve, reject);
  });
};

export const checkActivationCode = (code) => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.validatePasswordReset(code), {}, resolve, reject);
  });
};
