import { STATS_TEAM } from 'web/templates/libs/templatesConstants';
import { DYNAMIC_FIELDS } from 'web/dynamicFields/libs/dynamicFieldsConstants';

const {
  BUSINESS_DAYS_FROM_NOW,
  DAYS_FROM_NOW,
  TODAY,
  TOMORROW,
} = DYNAMIC_FIELDS;

export const EditTemplatePopupIds = {
  addAsTemplate: 'EDIT_TEMPLATE_POPUP_ADD_AS_TEMPLATE',
  archive: 'EDIT_TEMPLATE_POPUP_ARCHIVE',
  clone: 'EDIT_TEMPLATE_POPUP_CLONE',
  delete: 'EDIT_TEMPLATE_POPUP_DELETE',
  deleteNote: 'EDIT_TEMPLATE_POPUP_DELETE_NOTE',
  events: 'EDIT_TEMPLATE_POPUP_EVENTS',
  hasChanges: 'EDIT_TEMPLATE_POPUP_HAS_CHANGES',
  leaveEditing: 'EDIT_TEMPLATE_POPUP_LEAVE_EDITING',
  move: 'EDIT_TEMPLATE_POPUP_MOVE',
  share: 'EDIT_TEMPLATE_POPUP_SHARE',
};

export const EditTemplatePopupAlertIds = {
  archivedCategoryMissing:
    'EDIT_TEMPLATE_POPUP_ALERT_ARCHIVED_CATEGORY_MISSING',
  archiveTemplate: 'EDIT_TEMPLATE_POPUP_ALERT_ARCHIVE_TEMPLATE',
  cloneTemplate: 'EDIT_TEMPLATE_POPUP_ALERT_CLONE_TEMPLATE',
  createTemplate: 'EDIT_TEMPLATE_POPUP_ALERT_CREATE_TEMPLATE',
  deleteNote: 'EDIT_TEMPLATE_POPUP_ALERT_DELETE_NOTE',
  deleteTemplate: 'EDIT_TEMPLATE_POPUP_ALERT_DELETE_TEMPLATE',
  moveTemplate: 'EDIT_TEMPLATE_POPUP_ALERT_MOVE_TEMPLATE',
  shareTemplate: 'EDIT_TEMPLATE_POPUP_ALERT_SHARE_TEMPLATE',
};

export const EditTemplateTabAlertIds = {
  archivingEmail: 'EDIT_TEMPLATE_TAB_ALERT_ARCHIVING_EMAIL',
  createNote: 'EDIT_TEMPLATE_TAB_ALERT_CREATE_NOTE',
  followUpFail: 'EDIT_TEMPLATE_TAB_ALERT_FOLLOW_UP_FAIL',
  getTemplateAnalytics: 'EDIT_TEMPLATE_TAB_ALERT_GET_TEMPLATE_ANALYTICS',
  getTemplateCategoriesFail:
    'EDIT_TEMPLATE_TAB_ALERT_GET_TEMPLATE_CATEGORIES_FAIL',
  getTemplateDetails: 'EDIT_TEMPLATE_TAB_ALERT_GET_TEMPLATE_DETAILS',
  getTemplateEmails: 'EDIT_TEMPLATE_TAB_ALERT_GET_TEMPLATE_EMAILS',
  missingSubject: 'EDIT_TEMPLATE_TAB_ALERT_MISSING_SUBJECT',
  saveName: 'EDIT_TEMPLATE_TAB_ALERT_SAVE_NAME',
  saveTemplate: 'EDIT_TEMPLATE_TAB_ALERT_SAVE_TEMPLATE',
  dynamicFields: 'ERROR_DYNAMIC_FIELDS',
  setFavorite: 'EDIT_TEMPLATE_TAB_ALERT_SET_FAVORITE',
  unarchivingEmail: 'EDIT_TEMPLATE_TAB_ALERT_UNARCHIVING_EMAIL',
  updateNote: 'EDIT_TEMPLATE_TAB_ALERT_UPDATE_NOTE',
};

export const EditTemplateTabs = {
  analytics: 'analytics',
  campaigns: 'campaigns',
  emails: 'emails',
  template: 'template',
};

export const EditTemplateCards = {
  analyticsEngagement: 'analytics-engagement-card',
  analyticsSent: 'analytics-sent-card',
  comment: 'comment-card',
  email: 'email-card',
  post: 'post-card',
  recommend: 'recommend-card',
  settings: 'settings-card',
  template: 'template-card',
};

export const EditTemplateMappingsValues = {
  disabled: 0,
  all: 1,
  any: 2,
};

export const EditTemplateCondtions = {
  contains: 'Contains',
  doesNotContain: 'Does Not Contain',
  equals: 'Equals',
  notEqual: 'Not Equal',
};

export const EditTemplateMaxMappings = 3;
export const EditTemplateSlideOutWidth = 720;

export const EditTemplateAnalyticsDatesSelect = {
  lastTwelveWeeks: 'last-twelve-weeks',
  lastThirtyDays: 'last-thirty-days',
  lastSevenDays: 'last-seven-days',
};

export const EditTemplateAnalyticsTeamsSelect = {
  ...STATS_TEAM,
};

export const EditTemplateEmailsTeamsSelect = {
  me: 'me',
  team: 'team',
};

export const EditTemplateDynamicFields = [
  'Company',
  'Company friendly',
  'First name',
  'Home phone',
  'Last email body',
  'Last name',
  'Mobile phone',
  'My address',
  'My company',
  'My email',
  'My first name',
  'My mobile phone',
  'My name',
  'My signature',
  'My title',
  'My website',
  'My work phone',
  'Personal email',
  'Title',
  'Team unsubscribe',
  'Work email',
  'Work fax',
  'Work phone',
  'Work website',
  'Friendly block unsubscribe',
  'Friendly unsubscribe',
  'Today',
  'Tomorrow',
  'Unsubscribe link',
  'Enter search text',
  '# business days from now',
  '# days from now',
  'Block unsubscribe link',
];

export const EditTemplateEmailsStateSelect = {
  clicked: 'clicked',
  recentEmails: 'recent-emails',
  noEngagement: 'no-engagement',
  recentEngagement: 'recent-engagement',
  replied: 'replied',
  success: 'success',
  viewed: 'viewed',
};

export const EditTemplateRecommendedFieldsExclude = [
  BUSINESS_DAYS_FROM_NOW,
  DAYS_FROM_NOW,
  TODAY,
  TOMORROW,
];
