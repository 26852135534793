import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import ViewMore from 'components/viewMore';
import Call from './call';
import { removeCallPopup } from 'web/person/actionCreators/personDetailsActionsActionCreators';
import './personDetailsCallsCard.scss';
import { connect } from 'react-redux';

const SHOWN_COUNT = 2;

class PersonDetailsCallsCard extends Component {
  state = {
    callsData: [],
    deletedCallsIds: [],
  };

  componentDidMount() {
    const { calls } = this.props;

    this.setState({
      callsData: calls,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { deletedCallsIds } = state;
    const { personDetailsPerson } = props;
    const isCallsIdsUpdate =
      personDetailsPerson.deletedCallId &&
      !deletedCallsIds.includes(personDetailsPerson.deletedCallId);

    if (isCallsIdsUpdate) {
      return {
        deletedCallsIds: state.deletedCallsIds.concat(
          personDetailsPerson.deletedCallId
        ),
      };
    }

    return false;
  }

  render() {
    const { callRecordingEnabled, dispatch } = this.props;
    const { callsData, deletedCallsIds } = this.state;
    const filterDataCalls = (callsData) =>
      callsData
        .filter((callData) => !deletedCallsIds.includes(callData.id))
        .map((callData) => ({
          ...callData,
          removeCallPopup: (id) => dispatch(removeCallPopup(id)),
        }));

    return (
      <div className="person-details-calls-card">
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.person.personDetails.callsCard.title" />
        </h3>
        {(callsData.length && (
          <div>
            <div className="flex text-medium">
              <div className="column-datetime">
                <FormattedMessage id="web.person.personDetails.callsCard.datetime" />
              </div>
              <div className="column-name">
                <FormattedMessage id="common.name" />
              </div>
              <div className="column-notes">
                <FormattedMessage id="common.notes" />
              </div>
              {callRecordingEnabled && (
                <div className="column-recording">
                  <FormattedMessage id="common.recording" />
                </div>
              )}
              <div className="column-duration text-right">
                <FormattedMessage id="common.duration" />
              </div>
              <div className="column-outcome text-right">
                <FormattedMessage id="common.outcome" />
              </div>
              <div className="column-action" />
            </div>
            <ViewMore
              itemRenderer={this.itemRenderer}
              items={filterDataCalls(callsData)}
              shownCount={SHOWN_COUNT}
              viewMoreOnClick={this.viewMoreOnClick}
            />
          </div>
        )) || (
          <FormattedMessage id="web.person.personDetails.callsCard.noCalls" />
        )}
      </div>
    );
  }

  itemRenderer = ({ id, ...other }) => {
    const {
      actionCreators: { openItemClick },
      callRecordingEnabled,
    } = this.props;

    return (
      <Call
        {...other}
        callRecordingEnabled={callRecordingEnabled}
        id={id}
        key={`calls-card-${id}`}
        openItemClick={openItemClick}
      />
    );
  };

  viewMoreOnClick = () =>
    this.props.actionCreators.viewMoreOnClick(PersonDetailsCards.calls);
}

PersonDetailsCallsCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  callRecordingEnabled: PropTypes.bool,
  calls: PropTypes.array.isRequired,
};

PersonDetailsCallsCard.defaultProps = {
  callRecordingEnabled: false,
};

export default connect((state) => ({
  personDetailsPerson: state.personDetailsPerson,
}))(PersonDetailsCallsCard);
