import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { moment } from 'languages';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import './dueDateCell.scss';
import { default as I18N } from 'languages';

class DueDateCell extends Component {
  render() {
    const { classes, text } = this._getData();
    const className = classNames(
      this.props.className,
      'cell-due-date text-overflow',
      classes
    );

    return (
      <div className={className}>
        <span
          className={classNames('text-medium', {
            'text-italic': this.props.rowData.done,
          })}
        >
          {text}
        </span>
      </div>
    );
  }

  _getData = () => {
    const {
      intl: { formatMessage },
      rowData: { done, remind_at, skipped },
    } = this.props;
    const dueDate = moment(remind_at).startOf('d');
    const currentDate = moment().startOf('d');
    const notDone = !done && !skipped;
    const text = dueDate.format(I18N.DateFormats.DATE_SLASH);

    if (notDone && dueDate.isBefore(currentDate)) {
      return { classes: 'text-red', text };
    } else if (notDone && dueDate.isSame(currentDate, 'd')) {
      return {
        classes: 'text-blue',
        text: formatMessage({ id: 'common.today' }),
      };
    } else if (notDone && dueDate.isSame(currentDate.add(1, 'd'), 'd')) {
      return { classes: 'text-blue-light', text };
    } else {
      return { classes: 'text-gray', text };
    }
  };
}

DueDateCell.propTypes = {
  className: PropTypes.string,
  intl: intlShape.isRequired,
  rowData: PropTypes.object.isRequired,
};

DueDateCell.defaultProps = {
  className: '',
};

export default injectIntl(DueDateCell);
