import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import onboardingPopupIds from 'web/onboarding/lib/onboardingPopupIds';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import {
  closePopup,
  openPopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { navigateToUrl } from 'web/services/routerService';
import { BCC_FORM_ID } from 'web/settings/accountSettings/integrations/libs/integrationConstants';
import AutoBccForm from '../../components/autoBccForm/bccForm';

import {
  updateIntegration,
  openDeleteBccConfirmation,
} from 'web/settings/accountSettings/integrations/actionCreators/bccActionCreators';
import { getBccIntegration } from 'web/integrations/bcc/selectors/bccSelector';
import { connectSalesforceInPopupWindow } from 'web/settings/adminSettings/salesforce/actionCreators/connectionsCustomizationsActionCreators';
import { SalesforceType } from 'web/salesforce/libs/salesforceConstants';
import { steps } from 'web/onboarding/lib/stepsRouting';
import {
  setSkippedStep,
  markUserAsNotNew,
  setRemindCompleteOnboarding,
} from 'web/onboarding/actionCreators/onboardingActionCreators';
import ExchangeConnectionForm from 'web/settings/emailConnection/components/exchangeConnectionForm';
import {
  onboardingConnectToExchange,
  updateExchangeOnpremFormState,
} from 'web/settings/emailConnection/actionCreators/exchangeOnpremActionCreators';
import './onboardingPopupContainer.scss';
import Checkbox from 'components/buttons/checkbox';

const EMPTY_POPUP = {};

class OnboardingPopupContainer extends React.Component {
  render() {
    const { popup } = this.props;
    let object = EMPTY_POPUP;
    if (popup) {
      object = this._getGlobalPopupObject();
    }

    return <div>{object.children ? <Popup {...object} /> : null}</div>;
  }

  _getGlobalPopupObject() {
    const { popupLoading } = this.props;
    return {
      getImageUrl,
      loading: popupLoading,
      ...this._getMainComponent(),
    };
  }

  _getMainComponent() {
    const {
      activeStep,
      bccIntegration,
      closePopup,
      connectSalesforceInPopupWindow,
      intl: { formatMessage },
      markUserAsNotNew,
      openDeleteBccConfirmation,
      popup,
      remindCompleteOnboarding,
      setRemindCompleteOnboarding,
      updateIntegration,
    } = this.props;

    switch (popup) {
      case onboardingPopupIds.skipSetup: {
        return {
          children: (
            <div className="skip-step-container">
              <p>
                <FormattedMessage
                  id={'web.onboarding.popup.skipSetup.textPrimary'}
                />
              </p>
              <p>
                <FormattedMessage
                  id={'web.onboarding.popup.skipSetup.textSecondary'}
                />
              </p>
              <Checkbox
                checked={remindCompleteOnboarding}
                label={formatMessage({
                  id:
                    'web.onboarding.popup.skipSetup.remindCompleteSetupLetter',
                })}
                name="remind-complete-onboarding"
                onChange={() =>
                  setRemindCompleteOnboarding(!remindCompleteOnboarding)
                }
              />
            </div>
          ),
          footer: {
            onPrimary: () => markUserAsNotNew(remindCompleteOnboarding),
            onSecondary: closePopup,
            primaryTextId: 'web.onboarding.popup.skipSetup.primaryButton',
            secondaryTextId: 'web.onboarding.popup.skipSetup.secondaryButton',
          },
          header: {
            className: 'onboarding-popup-header',
            textId: 'web.onboarding.popup.skipSetup.title',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case onboardingPopupIds.skipStep: {
        const {
          popupData: { primaryTextID, secondaryTextId, redirectUrl },
          setSkippedStep,
        } = this.props;
        const onPrimaryAction = () => {
          setSkippedStep(activeStep);
          navigateToUrl(redirectUrl || steps[activeStep].url);
        };

        return {
          children: (
            <React.Fragment>
              <p>
                <FormattedMessage id={primaryTextID} />
              </p>
              <p>
                <FormattedMessage id={secondaryTextId} />
              </p>
            </React.Fragment>
          ),
          footer: {
            onPrimary: onPrimaryAction,
            onSecondary: closePopup,
            primaryTextId: 'web.onboarding.popup.skipSetup.primaryButton',
            secondaryTextId: 'web.onboarding.popup.skipSetup.secondaryButton',
          },
          header: {
            className: 'onboarding-popup-header',
            textId: 'web.onboarding.popup.skipSetup.title',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case onboardingPopupIds.autoBCC: {
        const {
          popupData: { successCallback },
        } = this.props;
        const bccFormModel = {
          bcc: bccIntegration.bcc,
        };
        const onSubmit = (data) => {
          closePopup();
          updateIntegration(data).then(() => {
            successCallback(true);
          });
        };

        return {
          children: (
            <React.Fragment>
              <p>
                <FormattedMessage id="web.onboarding.crm.bcc.popup.description" />
              </p>
              <AutoBccForm
                bccIntegration={bccIntegration}
                form={BCC_FORM_ID}
                initialValues={bccFormModel}
                onClose={closePopup}
                onSubmit={onSubmit}
                openDeleteBccConfirmation={openDeleteBccConfirmation}
              />
            </React.Fragment>
          ),
          header: {
            onClose: closePopup,
            textId: 'web.onboarding.connect.crm.not.use.salesforce',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      case onboardingPopupIds.onPremiseConnect: {
        const {
          onpremForm,
          updateExchangeOnpremFormState,
          onboardingConnectToExchange,
        } = this.props;
        return {
          children: (
            <React.Fragment>
              <p className="exchange-popup-title">
                <FormattedMessage
                  id={'web.onboarding.emailConnectionPage.exchangePopupTitle'}
                />
              </p>
              <ExchangeConnectionForm
                onpremForm={onpremForm}
                updateExchangeOnpremFormState={updateExchangeOnpremFormState}
              />
            </React.Fragment>
          ),
          footer: {
            onPrimary: () => onboardingConnectToExchange(),
            onSecondary: closePopup,
            primaryTextId: 'common.connect',
            secondaryTextId: 'common.close',
          },
          header: {
            onClose: closePopup,
            textId: 'web.onboarding.emailConnectionPage.exchangePopupHeader',
          },
          onOutsideClick: closePopup,
          size: PopupSize.large,
        };
      }
      case onboardingPopupIds.saleforceConnect: {
        return {
          children: (
            <React.Fragment>
              <p>
                <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.inOrderToProvide" />
              </p>
              <div>
                <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.thesePermissions" />
                <ul className="permissions-list">
                  <li>
                    <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.permissionList.accessToBasicInfo" />
                  </li>
                  <li>
                    <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.permissionList.manageChatter" />
                  </li>
                  <li>
                    <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.permissionList.dataViaWeb" />
                  </li>
                  <li>
                    <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.permissionList.performRequests" />
                  </li>
                </ul>
              </div>
              <p>
                <FormattedMessage id="web.salesforce.connectionCard.connectConfirmation.currentlyLoggedIn" />
              </p>
              <p>
                <FormattedHTMLMessage id="web.salesforce.connectionCard.connectConfirmation.clickOk" />
              </p>
            </React.Fragment>
          ),
          footer: {
            onPrimary: () => {
              const { connectHandler } = this.props;

              closePopup();
              connectHandler(true);

              connectSalesforceInPopupWindow(
                SalesforceType.salesforce,
                formatMessage({ id: 'web.onboarding.title.connectCRM' }),
                600,
                800
              );
            },
            onSecondary: closePopup,
            primaryTextId: 'common.ok',
            secondaryTextId: 'common.back',
          },
          header: {
            onClose: closePopup,
            textId: 'web.salesforce.connectionCard.connectConfirmation.title',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      default:
        return EMPTY_POPUP;
    }
  }
}

OnboardingPopupContainer.propTypes = {
  activeStep: PropTypes.number.isRequired,
  bccIntegration: PropTypes.object.isRequired,
  children: PropTypes.node,
  closePopup: PropTypes.func.isRequired,
  connectHandler: PropTypes.func.isRequired,
  connectSalesforceInPopupWindow: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  markUserAsNotNew: PropTypes.func.isRequired,
  onboardingConnectToExchange: PropTypes.func.isRequired,
  onpremForm: PropTypes.object,
  openDeleteBccConfirmation: PropTypes.func.isRequired,
  popup: PropTypes.string.isRequired,
  popupAlert: PropTypes.string.isRequired,
  popupData: PropTypes.object.isRequired,
  popupLoading: PropTypes.bool.isRequired,
  setRemindCompleteOnboarding: PropTypes.func.isRequired,
  setSkippedStep: PropTypes.func.isRequired,
  updateExchangeOnpremFormState: PropTypes.func.isRequired,
  updateIntegration: PropTypes.func.isRequired,
};

OnboardingPopupContainer.defaultProps = {
  children: null,
  onpremForm: {},
};

const mapStateToProps = (state) => ({
  activeStep: state.onboarding.onboardingActiveStep,
  bccIntegration: getBccIntegration(state),
  onpremForm: state.onpremForm,
  popup: state.popup,
  popupAlert: state.popupAlert,
  popupData: state.popupData,
  popupLoading: state.popupLoading,
  remindCompleteOnboarding: state.onboarding.remindCompleteOnboarding,
});

const mapDispatchToProps = {
  closePopup,
  connectSalesforceInPopupWindow,
  markUserAsNotNew,
  onboardingConnectToExchange,
  openDeleteBccConfirmation,
  openPopup,
  setRemindCompleteOnboarding,
  setSkippedStep,
  updateExchangeOnpremFormState,
  updateIntegration,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(OnboardingPopupContainer);
