import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import './dateAgoCell.scss';

class DateAgoCell extends PureComponent {
  render() {
    const { className } = this.props;
    const containerClasses = classNames(className, 'cell-date-ago', {});
    const date = this.getDate();

    return (
      <div className={containerClasses}>
        <span className="text-overflow">{date}</span>
      </div>
    );
  }

  getDate = () => {
    const { property, rowData } = this.props;
    const rowDate = rowData[property];
    return (rowDate && moment(rowDate).fromNow()) || '';
  };
}

DateAgoCell.propTypes = {
  className: PropTypes.string,
  property: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
};

DateAgoCell.defaultProps = {
  className: '',
  property: 'date',
  rowData: {},
};

export default DateAgoCell;
