import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import UnsubscribeHistoryTable from './unsubscribeHistoryTable/unsubscribeHistoryTable';

function UnsubscribeHistory({
  formatMessage,
  items,
  userSubscriptionUsers,
  unsubscribeOptInReasons,
  unsubscribeReasons,
}) {
  return (
    <div className="person-details-unsubscribe-history">
      <h4 className="margin-bottom-half">
        <FormattedMessage id="web.person.personDetails.unsubscribeHistory" />
      </h4>
      <UnsubscribeHistoryTable
        formatMessage={formatMessage}
        items={items}
        userSubscriptionUsers={userSubscriptionUsers}
        unsubscribeOptInReasons={unsubscribeOptInReasons}
        unsubscribeReasons={unsubscribeReasons}
      />
    </div>
  );
}

UnsubscribeHistory.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  unsubscribeOptInReasons: PropTypes.object.isRequired,
  unsubscribeReasons: PropTypes.object.isRequired,
  userSubscriptionUsers: PropTypes.object.isRequired,
};

export default UnsubscribeHistory;
