import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SmtpServerPopupIds } from '../libs/smtpServerConstants';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { removeSmtpServer } from '../actionCreators/smtpServerActionCreators';
import EmailServerRemoveConfirmationPopup from 'web/settings/emailServer/components/emailServerRemoveConfirmationPopup';

class SmtpServerPopupContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          closePopup,
        },
        dispatch
      ),
      removeEmailServer: () => {
        dispatch(closePopup());
        dispatch(removeSmtpServer());
      },
    };
  }

  render() {
    const { popup } = this.props;

    switch (popup) {
      case SmtpServerPopupIds.removeConfirmation:
        return (
          <EmailServerRemoveConfirmationPopup
            actionCreators={this.actionCreators}
          />
        );

      default:
        return null;
    }
  }
}

SmtpServerPopupContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  popup: PropTypes.string.isRequired,
  url: PropTypes.string,
};

SmtpServerPopupContainer.defaultProps = {
  url: '',
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  url: state.popupData.url,
});

export default connect(mapStateToProps)(SmtpServerPopupContainer);
