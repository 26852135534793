import { Urls } from 'web/libs/routes';
import { isAdmin } from 'web/shared/services/accountService';
import { doLogout } from '../../login/services/loginService';

export const getNavBarItems = () => {
  const { loginPage } = Urls;
  const { myProfile } = Urls.topNavbar;
  const isAccountAdmin = isAdmin();

  return [
    {
      id: 1,
      isAlignCenterLink: true,
      isDivide: !isAccountAdmin,
      isVisible: true,
      link: myProfile,
      titleId: 'common.settings',
    },
    {
      id: 2,
      isAlignCenterLink: true,
      isVisible: true,
      link: loginPage,
      onLinkCallback: doLogout,
      titleId: 'common.logout',
    },
  ].filter((item) => item.isVisible);
};
