import CCActionTypes from 'web/commandCenter/libs/commandCenterActionTypes';

export function commandCenterInitStore(state = false, { type = '' }) {
  switch (type) {
    case CCActionTypes.state.initStore:
      return true;
    default:
      return state;
  }
}

export function commandCenterLoading(
  state = false,
  { type = '', loading = false }
) {
  switch (type) {
    case CCActionTypes.state.loading:
      return loading;
    default:
      return state;
  }
}

export function commandCenterPopupFetching(
  state = false,
  { type = '', fetching = false }
) {
  switch (type) {
    case CCActionTypes.popup.dataFetching:
      return fetching;
    default:
      return state;
  }
}

export function commandCenterActiveCampaigns(
  state = [],
  { type = '', data = [] }
) {
  switch (type) {
    case CCActionTypes.data.activeCampaigns:
      return data;
    default:
      return state;
  }
}

export const isFilterChanged = (
  state = false,
  { type = '', isChanged = true }
) => {
  switch (type) {
    case CCActionTypes.state.changeViewAsFilter:
      return isChanged;
    default:
      return state;
  }
};
