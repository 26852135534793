export function formatName(first, last, email) {
  const given = first || ''; // handles both null & undefined
  const surname = last || '';
  const spacing = given && surname ? ' ' : '';
  return `${given}${spacing}${surname}` || email || 'No name'; //todo intl
}

export function formatTitle(title, company) {
  const personTitle = title || '';
  const personCompany = company || '';
  const comma = personTitle && personCompany ? ', ' : '';
  return `${personTitle}${comma}${personCompany}`;
}
