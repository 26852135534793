import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentLogo from 'web/modals/addContent/components/contentLogo/contentLogo';
import { defaultPartnerName } from 'web/contentPartners/libs/contentPartnersConstants';
import './addContentPartners.scss';

export default class AddContentPartners extends Component {
  render() {
    const { imageUrl, setSelectedPartner, contentPartners } = this.props;

    return (
      <div className="content-partners-flex-container">
        {contentPartners.partners.map((partner) => (
          <ContentLogo
            key={`content-partners-image-${partner.name}`}
            classes={this._contentPartnerClasses(partner)}
            partner={partner}
            setSelectedPartner={setSelectedPartner}
            imageUrl={imageUrl}
          />
        ))}
      </div>
    );
  }

  _contentPartnerClasses = (partner = {}) => {
    const { contentPartners } = this.props;

    return partner.name === defaultPartnerName &&
      contentPartners.partners.length % 2 !== 0
      ? 'solo-partner'
      : '';
  };
}

AddContentPartners.propTypes = {
  imageUrl: PropTypes.func.isRequired,
  contentPartners: PropTypes.object.isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
};
