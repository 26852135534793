/* global __DEV__ */
/* eslint no-useless-escape: 0 */
import I18N from 'languages';
export { Urls } from './routes'; // Temp while services still reference this file for routes
let imageUrl =
  'https://d2owagxlf083ds.cloudfront.net/tout-react/production/images';
let customFontsUrls =
  'https://d2owagxlf083ds.cloudfront.net/tout-react/production/styles/custom-fonts';
let pusherEnvironment = 'production';
let pusherKey = '3479fa99c49db0822997';

if (__DEV__) {
  const environment = require('./environment'); // eslint-disable-line global-require
  imageUrl = environment.BaseUrlImages;
  customFontsUrls = environment.BaseUrlCustomFonts;
  pusherEnvironment = environment.PusherEnvironment;
  pusherKey = environment.PusherKey;
}

export const BaseUrlImages = imageUrl;
export const BaseUrlCustomFonts = customFontsUrls;
export const NextUrlImages = 'https://d2owagxlf083ds.cloudfront.net/next';
export const PusherEnvironment = pusherEnvironment;
export const PusherKey = pusherKey;
export const LiveFeedPusherChannel = 'liveFeedChannel';
export const EmailRFC2822Pattern =
  '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])';

export const StatusPageData = {
  page: '4hn52xdymxd2',
};

export const Currency = {
  gong: { defaultDealSize: '0.00' },
};

export const PusherEvents = {
  alerts: 'Alert',
  bestBets: 'BestBets:available',
  bulkUpdateComplete: 'BulkUpdateComplete',
  bulkUpdateProgress: 'BulkUpdate',
  contactImportComplete: 'ContactImportComplete',
  contactImportFailed: 'ContactImportFailed',
  contactImportProgress: 'ContactImportProgress',
  dynamicChatActivities: 'DynamicChatActivities:available',
  interestingMoments: 'InterestingMoments:available',
  invitationUpdate: 'invitations:updateInvite',
  liveFeedEvents: 'Event',
  notifications: {
    dynamicChat: {
      leadOpenedChat: 'DynamicChat:leadOpenedChat',
    },
  },
  pitch: 'Pitch',
  salesforceCustomizationError: 'SalesforceCustomizations.Error',
  salesforceCustomizationProgressChange:
    'SalesforceCustomizations.ProgressChange',
  salesforceCustomizationStatusChanged: 'SalesforceCustomizations.StatusChange',
  taskDestroy: 'Reminder:destroy',
  taskUpdate: 'Reminder:update',
  templateCategoryUpdate: 'TemplateCategoryUpdated',
  templateUpdate: 'TemplateUpdated',
  userSession: 'UserSession',
  workflowInstanceUpdate: 'WorkflowInstanceUpdated',
  workflowMetricUpdate: 'WorkflowMetricUpdated',
  workflowUpdate: 'WorkflowUpdated',
};

//https://github.com/moment/moment-timezone/issues/331, time zones for moment reffered from this link.
export const TimeZoneMap = {
  'Abu Dhabi': 'Asia/Dubai',
  Adelaide: 'Australia/Adelaide',
  Alaska: 'US/Alaska',
  Almaty: 'Asia/Almaty',
  Amsterdam: 'Europe/Amsterdam',
  Arizona: 'US/Arizona',
  Astana: 'Asia/Dhaka',
  Athens: 'Europe/Athens',
  'Atlantic Time (Canada)': 'Canada/Atlantic',
  Auckland: 'Pacific/Auckland',
  Azores: 'Atlantic/Azores',
  Baghdad: 'Asia/Baghdad',
  Baku: 'Asia/Baku',
  Bangkok: 'Asia/Bangkok',
  Beijing: 'Asia/Hong_Kong',
  Belgrade: 'Europe/Belgrade',
  Berlin: 'Europe/Berlin',
  Bern: 'Europe/Amsterdam',
  Bogota: 'America/Bogota',
  Brasilia: 'America/Sao_Paulo',
  Bratislava: 'Europe/Bratislava',
  Brisbane: 'Australia/Brisbane',
  Brussels: 'Europe/Brussels',
  Bucharest: 'Europe/Bucharest',
  Budapest: 'Europe/Budapest',
  'Buenos Aires': 'America/Argentina/Buenos_Aires',
  Cairo: 'Africa/Cairo',
  Canberra: 'Australia/Canberra',
  'Cape Verde Is.': 'Atlantic/Cape_Verde',
  Caracas: 'America/Caracas',
  Casablanca: 'Africa/Casablanca',
  'Central America': 'US/Central',
  'Central Time (US & Canada)': 'US/Central',
  Chennai: 'Asia/Kolkata',
  Chihuahua: 'America/Chihuahua',
  Chongqing: 'Asia/Chongqing',
  Copenhagen: 'Europe/Copenhagen',
  Darwin: 'Australia/Darwin',
  Dhaka: 'Asia/Dhaka',
  Dublin: 'Europe/Dublin',
  'Eastern Time (US & Canada)': 'US/Eastern',
  Edinburgh: 'Europe/London',
  Ekaterinburg: 'Asia/Yekaterinburg',
  Fiji: 'Pacific/Fiji',
  Georgetown: 'America/La_Paz',
  Greenland: 'America/Godthab',
  Guadalajara: 'Pacific/Guadalcanal',
  Guam: 'Pacific/Guam',
  Hanoi: 'Asia/Bangkok',
  Harare: 'Africa/Harare',
  Hawaii: 'US/Hawaii',
  Helsinki: 'Europe/Helsinki',
  Hobart: 'Australia/Hobart',
  'Hong Kong': 'Hongkong',
  'Indiana (East)': 'US/East-Indiana',
  'International Date Line West': 'Pacific/Wallis',
  Irkutsk: 'Asia/Irkutsk',
  Islamabad: 'Asia/Karachi',
  Istanbul: 'Europe/Istanbul',
  Jakarta: 'Asia/Jakarta',
  Jerusalem: 'Asia/Jerusalem',
  Kabul: 'Asia/Kabul',
  Kamchatka: 'Asia/Kamchatka',
  Karachi: 'Asia/Karachi',
  Kathmandu: 'Asia/Kathmandu',
  Kolkata: 'Asia/Kolkata',
  Krasnoyarsk: 'Asia/Krasnoyarsk',
  'Kuala Lumpur': 'Asia/Kuala_Lumpur',
  Kuwait: 'Asia/Kuwait',
  Kyev: 'Europe/Kiev',
  'La Paz': 'America/La_Paz',
  Lima: 'America/Lima',
  Lisbon: 'Europe/Lisbon',
  Ljubljana: 'Europe/Ljubljana',
  London: 'Europe/London',
  Madrid: 'Europe/Madrid',
  Magadan: 'Asia/Magadan',
  'Marshall Is.': 'Pacific/Wake',
  Mazatlan: 'America/Mazatlan',
  Melbourne: 'Australia/Melbourne',
  'Mexico City': 'America/Mexico_City',
  'Mid-Atlantic': 'America/Noronha',
  'Midway Island': 'Pacific/Midway',
  Minsk: 'Europe/Minsk',
  Monrovia: 'Africa/Monrovia',
  Monterrey: 'America/Monterrey',
  Moscow: 'Europe/Moscow',
  'Mountain Time (US & Canada)': 'US/Mountain',
  Mumbai: 'Asia/Kolkata',
  Muscat: 'Asia/Muscat',
  Nairobi: 'Africa/Nairobi',
  'New Caledonia': 'Pacific/Guadalcanal',
  'New Delhi': 'Asia/Kolkata',
  Newfoundland: 'Canada/Newfoundland',
  Novosibirsk: 'Asia/Novosibirsk',
  "Nuku'alofa": 'Pacific/Tongatapu',
  Osaka: 'Asia/Tokyo',
  'Pacific Time (US & Canada)': 'US/Pacific',
  Paris: 'Europe/Paris',
  Perth: 'Australia/Perth',
  'Port Moresby': 'Pacific/Port_Moresby',
  Prague: 'Europe/Prague',
  Pretoria: 'Africa/Maseru',
  Quito: 'America/Lima',
  Rangoon: 'Asia/Rangoon',
  Riga: 'Europe/Riga',
  Riyadh: 'Asia/Riyadh',
  Rome: 'Europe/Rome',
  Santiago: 'America/Santiago',
  Sapporo: 'Asia/Tokyo',
  Sarajevo: 'Europe/Sarajevo',
  Saskatchewan: 'Canada/Saskatchewan',
  Seoul: 'Asia/Seoul',
  Singapore: 'Singapore',
  Skopje: 'Europe/Skopje',
  Sofia: 'Europe/Sofia',
  'Solomon Is.': 'Pacific/Guadalcanal',
  'Sri Jayawardenepura': 'Asia/Calcutta',
  'St. Petersburg': 'America/Indiana/Petersburg',
  Stockholm: 'Europe/Stockholm',
  Sydney: 'Australia/Sydney',
  Taipei: 'Asia/Taipei',
  Tallinn: 'Europe/Tallinn',
  Tashkent: 'Asia/Tashkent',
  Tbilisi: 'Asia/Tbilisi',
  Tehran: 'Asia/Tehran',
  Tijuana: 'America/Tijuana',
  Tokyo: 'Asia/Tokyo',
  UTC: 'UTC',
  'Ulaan Bataar': 'Asia/Ulaanbaatar',
  Urumqi: 'Asia/Urumqi',
  Vienna: 'Europe/Vienna',
  Vilnius: 'Europe/Vilnius',
  Vladivostok: 'Asia/Vladivostok',
  Volgograd: 'Europe/Volgograd',
  Warsaw: 'Europe/Warsaw',
  Wellington: 'Pacific/Auckland',
  'West Central Africa': 'Africa/Lagos',
  Yakutsk: 'Asia/Yakutsk',
  Yerevan: 'Asia/Yerevan',
  Zagreb: 'Europe/Zagreb',
};

export const Regex = {
  aTagLink: /<a.*href=.*>.*<\/a>/,
  alphabetize: /[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}/,
  csvControlCharacters: /[",]/g,
  customField: /^[a-z0-9_]+$/i,
  domainBlock: /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
  domainName: /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\.]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/,
  dynamicField: /{{\w*}}/gi,
  dynamicFieldWithDefaultValue: /{{\s*\w+\s*\|\s*default\s*:\s*.*\s*}}/gi,
  dynamicFieldsBracketsLeftover: /{{|}}/g,
  dynamicFieldsPattern: new RegExp(
    `({{[0-9A-Za-z_]*?}}|{{[0-9A-Za-z_]*?}|{[0-9A-Za-z_]*?}})`,
    'gi'
  ),
  dynamicFieldsPrompt: /{{!\s*\S.*}}/gi,
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  // /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|care|com|coop|edu|email|gov|info|int|mil|museum|name|net|org|pro|rocks|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i, // http://emailregex.com/
  emailContains: new RegExp(EmailRFC2822Pattern, 'i'),
  emailRFC2822: new RegExp(`^${EmailRFC2822Pattern}$`, 'i'),
  emailStopPattern: new RegExp(
    `(?:,|;|(?:.*<${EmailRFC2822Pattern}>\\s?)|(?:${EmailRFC2822Pattern}\\s))$`,
    'i'
  ),
  emailWithName: new RegExp(`^(?:.*<(${EmailRFC2822Pattern})>)$`, 'i'),
  endingSlash: /\/$/,
  integer: /^[-]?\d+$/,
  numbers: /^\d+$/,
  partnerUrl: '[sS]',
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  phoneChars: /[^+x\d]/,
  specialChars: /[!@#$%^&*(),.?":{}|<>]/,
  spreadsheetFormulaCharacters: /^[\=\+\-\@]/,
  time: /^(?:(([0-9]|0[0-9]|1[0-2]):([0-5][0-9])\s?(A|AM|P|PM)?|(1[3-9]|2[0-3]):([0-5][0-9])))$/i,
  timeMilitary: /^(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/i,
  timeStandard: /^([0-9]|0[0-9]|1[0-2]):([0-5][0-9])\s?(A|AM|P|PM)?$/i,
  usernameFacebook: /^([a-z0-9-\.]+)$/i,
  usernameLinkedin: /^([a-z0-9\-]+)$/i,
  usernameTwitter: /^(@?)([a-z0-9\_]+)$/i,
  website: /^(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2}))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?\/?$/i,
  websiteHttp: /^https?:\/\//,
};

export function getImageUrl(image, extension = 'png') {
  return `${imageUrl}/${image}.${extension}`;
}

export function getI18nImageUrl(image, extension = 'png', folder = '') {
  if (I18N.currLanguage !== I18N.defaultLocale) {
    const i18nImageUrl = `${imageUrl}/locale/${I18N.currLanguage}`;
    return `${i18nImageUrl}/${image}.${extension}`;
  }
  if (folder) {
    return `${imageUrl}/${folder}/${image}.${extension}`;
  }
  return `${imageUrl}/${image}.${extension}`;
}

export const ModalPopupIds = {
  addContent: 'ADD_CONTENT',
  addToCampaign: 'ADD_TO_CAMPAIGN',
  deleteParentTemplate: 'DELETE_PARENT_TEMPLATE',
  deleteRecordingNotice: 'MODAL_POPUP_DELETE_RECORDING_NOTICE',
  dynamicFields: 'DYNAMIC_FIELDS',
  dynamicFieldsIncorrect: 'DYNAMIC_FIELDS_INCORRECT',
  gong: 'GONG',
  imageInsert: 'IMAGE_INSERT',
  peopleDelete: 'MODAL_PEOPLE_DELETE',
  recordingNotice: 'MODAL_POPUP_RECORDING_NOTICE',
  task: 'MODAL_POPUP_TASK',
  updateCampaignTemplates: 'UPDATE_CAMPAIGN_TEMPLATES',
};

export const DayCardTypes = {
  defaultDayOneCard: 'DEFAULT_DAY_CARD',
  newDayCard: 'NEW_DAY_CARD',
  standardCard: 'STANDARD_DAY_CARD',
};

export const DragTypes = {
  step: 'CAMPAIGN_DAY_STEP',
};

export const LocalStorageKeys = {
  /*eslint no-process-env: 0*/
  campaigns: {
    lastCategoryModal: `toutLastCategoryModal-${process.env.TOUT_ENV}`,
    lastCategoryTab: `toutlastCategoryTab-${process.env.TOUT_ENV}`,
    lastWorkspaceModal: `toutLastWorkspaceModal-${process.env.TOUT_ENV}`,
    setupHorizontal: `setupHorizontal-${process.env.TOUT_ENV}`,
    slideOutMenuOpen: `dotaMenuOpen-${process.env.TOUT_ENV}`,
  },
  commandCenter: {
    lastOpened: `toutCommandCenterLastOpened-${process.env.TOUT_ENV}`,
  },
  emailComposeBody: {
    lastTemplateCategory: `toutlastTemplateCategory-${process.env.TOUT_ENV}`,
  },
  emailConnection: {
    connecting: `connectingEmail-${process.env.TOUT_ENV}`,
  },
  googleIntegration: {
    connecting: `connectingGoogle-${process.env.TOUT_ENV}`,
  },
  office365Integration: {
    connecting: `connectingOffice365-${process.env.TOUT_ENV}`,
  },
  people: {
    slideOutMenuOpen: `peopleSlideOutMenuOpen-${process.env.TOUT_ENV}`,
  },
  tables: {
    perPage: `tablePerPage-${process.env.TOUT_ENV}`,
  },
};

export const CampaignState = {
  inProgress: 'in_progress',
  paused: 'paused',
  unstarted: 'unstarted',
};

export const CampaignStepActions = {
  call: 'make_call',
  custom: 'custom_step',
  email: 'send_email',
  emailTask: 'email_reminder',
  inmail: 'send_inmail',
  none: 'none', // not valid on backend
};

export const ApiErrors = {
  addNewPeopleDisabled:
    'Creating people has been disabled for your subscription',
  unverified: 'unverified_user',
};

export const getHelpSteps = {
  form: 'CURRENT_STEP_FORM',
  level1: 'CURRENT_STEP_LEVEL_1',
  level2: 'CURRENT_STEP_LEVEL_2',
  nailedIt: 'CURRENT_STEP_NAILED_IT',
  popAnswer: 'CURRENT_STEP_POP_ANSWER',
  popAnswerTitles: 'CURRENT_STEP_POP_ANSWER_TITLES',
  thanks: 'CURRENT_STEP_THANKS',
};

export const BrowserMode = {
  iframe: 'iframe',
  window: 'window',
};

export const CelebrationVideo = {
  source: {
    mp4:
      'https://tout-user-store.s3.amazonaws.com/assets/static/videos/fireworks/fireworks.mp4',
    ogg:
      'https://tout-user-store.s3.amazonaws.com/assets/static/videos/fireworks/fireworks.ogg',
    webm:
      'https://tout-user-store.s3.amazonaws.com/assets/static/videos/fireworks/fireworks.webm',
  },
  type: {
    mp4: 'video/mp4',
    ogg: 'video/ogg',
    webm: 'video/webm',
  },
};

export const KeyCodes = {
  backspace: 8,
  comma: 188,
  delete: 46,
  enter: 13,
  semicolon: 186,
  space: 32,
  tab: 9,
};

export const UnauthorizedTruncatedWindowSize = {
  height: 500,
  width: 850,
};

export const TinymceDefaultFonts =
  'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; ook Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;';

export const TinymceCustomFonts =
  'Kosugi=kosugi,kosugi maru; MPlus 1p=mplus1p; MS Gothic=msgothic; MS Mincho=msmincho; Noto=notosans,notoserif; Sawarabi=sawarabi-gothic,sawarabi-mincho;';

export const TinymceDefaultStyle = 'body { font-family: Arial; }';
