import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Engage from 'web/liveFeed/engage/components/engage';
import { formatEngageEvents } from 'web/liveFeed/engage/selectors/engageSelectors';
import * as enagageActionCreators from 'web/liveFeed/engage/actionCreators/engageActionCreators';
import * as liveFeedActionCreators from 'web/liveFeed/actionCreators/liveFeedActionCreators';
import { openPdv as openPdvForPerson } from 'web/liveFeed/tasks/actionCreators/tasksActionCreators';

const EngageContainer = (props) => (
  <div className="engage-container">
    <Engage showMarketoBorder showToutBorder {...props} />
  </div>
);

EngageContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  events: formatEngageEvents(state),
  isLoading: state.liveFeedIsLoading,
  initialLoading: state.engageInitialLoading,
  salesforceInstanceUrl: state.liveFeedSalesforceInstanceUrl,
  openChildrenLoadingIds: state.engageOpenChildrenLoadingIds,
});

const mapDispatchToProps = {
  ...enagageActionCreators,
  ...liveFeedActionCreators,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: { ...dispatchProps, openPdvForPerson },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EngageContainer);
