import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { EditTemplateTabAlertIds } from '../../libs/editTemplateSlideOutConstants';
import Alert, { AlertColors } from 'components/alert';

class TabAlert extends PureComponent {
  render() {
    const alert = this._getAlert();

    if (alert) {
      return <Alert className="edit-template-tab-alert" {...alert} />;
    } else {
      return null;
    }
  }

  _getAlert = () => {
    const {
      actionCreators,
      alert: { id, textValues },
    } = this.props;
    const defaultAlert = {
      color: AlertColors.redDark,
      onClose: actionCreators.clearTabAlert,
      textValues,
    };

    switch (id) {
      case EditTemplateTabAlertIds.createNote:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.createNote',
        };
      case EditTemplateTabAlertIds.getTemplateDetails:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.getTemplateDetails',
        };
      case EditTemplateTabAlertIds.getTemplateAnalytics:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.getTemplateAnalytics',
        };
      case EditTemplateTabAlertIds.getTemplateEmails:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.getTemplateEmails',
        };
      case EditTemplateTabAlertIds.missingSubject:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.missingSubject',
        };
      case EditTemplateTabAlertIds.saveName:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.saveName',
        };
      case EditTemplateTabAlertIds.saveTemplate:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.saveTemplate',
        };
      case EditTemplateTabAlertIds.dynamicFields:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.dynamicFields',
        };
      case EditTemplateTabAlertIds.setFavorite:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.setFavorite',
        };
      case EditTemplateTabAlertIds.updateNote:
        return {
          ...defaultAlert,
          textId: 'web.editTemplateSlideOut.tabAlert.updateNote',
        };
      case EditTemplateTabAlertIds.followUpFail:
        return { ...defaultAlert, textId: 'web.emails.alert.followUpFail' };
      case EditTemplateTabAlertIds.getTemplateCategoriesFail:
        return {
          ...defaultAlert,
          textId: 'web.emails.alert.getTemplateCategoriesFail',
        };
      case EditTemplateTabAlertIds.archivingEmail:
        return { ...defaultAlert, textId: 'web.emails.alert.archivingEmail' };
      case EditTemplateTabAlertIds.unarchivingEmail:
        return { ...defaultAlert, textId: 'web.emails.alert.unarchivingEmail' };
      default:
        return null;
    }
  };
}

TabAlert.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
};

export default TabAlert;
