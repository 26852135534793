import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { unmountNext } from '../actionCreators/personDetailsNextActionCreators';
import PersonDetailsSlideOut from 'web/slideOuts/personDetails';
import './personDetailsNextContainer.scss';

class PersonDetailsNextContainer extends Component {
  componentWillMount() {
    this.props.dispatch(commonStartup());
  }

  componentWillUnmount() {
    this.props.dispatch(unmountNext());
  }

  render() {
    return (
      <div className="tout-ui-styles person-details-next-container">
        {!!this.props.id && <PersonDetailsSlideOut {...this.props} />}
      </div>
    );
  }
}

PersonDetailsNextContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.number,
};

const mapStateToProps = (state) => ({
  id: state.personDetailsNextId,
});

export default connect(mapStateToProps)(PersonDetailsNextContainer);
