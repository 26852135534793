import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CampaignStepActions } from 'web/libs/constants';
import Metric from 'web/shared/components/metric/metric';
import EngagementMetric from 'web/shared/components/engagementMetric/engagementMetric';
import find from 'lodash/find';
import './campaignStepMetrics.scss';

class CampaignStepMetrics extends Component {
  render() {
    const { selectedCampaign, step } = this.props;
    const stepMetric =
      find(selectedCampaign.workflow_metrics, { step_id: step.id }) || {};

    switch (step.action) {
      case CampaignStepActions.email:
      case CampaignStepActions.emailTask:
        return this.renderEmailStepMetrics(stepMetric);
      case CampaignStepActions.call:
        return this.renderCallStepMetrics(stepMetric);
      default:
        return this.renderTaskStepMetrics(stepMetric);
      // default to "custom step" - task metrics
    }
  }

  renderEmailStepMetrics = (stepMetric) => {
    const {
      emails_bounced: emailsBounced,
      emails_clicked: emailsClicked,
      emails_replied: emailsReplied,
      emails_sent: emailsSent,
      emails_viewed: emailsViewed,
    } = stepMetric;
    return (
      <div className="campaign-day-step-metric">
        <div className="metric">
          <Metric value={stepMetric.emails_sent} percent={false} />
          <FormattedMessage id="common.engagement.emailsSent" />
        </div>
        <EngagementMetric
          metric={{
            emailsBounced,
            emailsClicked,
            emailsReplied,
            emailsSent,
            emailsViewed,
          }}
        />
      </div>
    );
  };

  renderCallStepMetrics = (stepMetric) => {
    const connectionRate = stepMetric.num_calls
      ? (
          (stepMetric.num_calls_connected / stepMetric.num_calls) *
          100
        ).toFixed()
      : 0;
    return (
      <div className="campaign-day-step-metric">
        <div className="metric">
          <Metric value={stepMetric.num_calls} />
          <FormattedMessage id="common.engagement.callsCompleted" />
        </div>
        <div className="metric">
          <Metric value={connectionRate} percent />
          <FormattedMessage id="common.engagement.connectionRate" />
        </div>
      </div>
    );
  };

  renderTaskStepMetrics = (stepMetric) => (
    <div className="campaign-day-step-metric">
      <div className="metric">
        <Metric value={stepMetric.num_tasks} percent={false} />
        <FormattedMessage id="common.engagement.tasksCompleted" />
      </div>
    </div>
  );
}

CampaignStepMetrics.propTypes = {
  selectedCampaign: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
};

export default injectIntl(CampaignStepMetrics);
