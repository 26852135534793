import { createSelector } from 'reselect';
import * as wizardHelpers from 'web/modals/salesforceCustomization/helpers/salesforceCustomizationWizardHelpers';
import { salesforceCustomizationItems } from 'web/modals/salesforceCustomization/libs/salesforceCustomizationItems';

const getWizardStep = (state) => state.salesforceCustomization.wizardStep;
const getProfileUser = (state) =>
  state.salesforceCustomizationProfiles.currentUser;
const getSalesforceCustomizationItems = () => salesforceCustomizationItems;

export const getCustomizationItems = createSelector(
  [getWizardStep, getSalesforceCustomizationItems],
  wizardHelpers.getCustomizationItems
);

export const isFirstStep = createSelector(
  [getWizardStep],
  wizardHelpers.isFirstStep
);

export const isLastStep = createSelector(
  [getWizardStep],
  wizardHelpers.isLastStep
);

export const getWizardSteps = createSelector(
  [getProfileUser],
  wizardHelpers.getWizardSteps
);
