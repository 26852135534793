/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FilterBlock from 'web/commandCenter/modules/filterBlock/components/filterBlock/filterBlock';
import { getActiveFilters } from 'web/commandCenter/modules/filterBlock/selectors/filterBlockSelectors';
import { removeFilterBlock } from 'web/commandCenter/modules/filterBlock/actionCreators/filterBlockActionCreators';
import { getAdvancedSearchTableId } from 'web/commandCenter/modules/emailGridAdvancedSearch/helpers/advancedSearchHelpers';
import './filterBlockContainer.scss';

class FilterBlockContainer extends Component {
  render() {
    const { activeFilters } = this.props;

    return (
      <div className="filter-block-panel">
        {activeFilters.map((filter, index) => {
          const blockKey = `${filter.filterId}_${index}`;
          return (
            <FilterBlock
              filterId={filter.filterId}
              filterLabel={filter.filterLabel}
              filterPrefix={filter.filterPrefix}
              filterValues={filter.filterValues}
              key={blockKey}
              removeFilter={this.removeFilterBlock}
            />
          );
        })}
      </div>
    );
  }

  removeFilterBlock = (filterId) => {
    const { removeFilterBlock, collectionId, onRemoveFilterBlock } = this.props;
    removeFilterBlock(collectionId, filterId);
    onRemoveFilterBlock();
  };
}

FilterBlockContainer.propTypes = {
  activeFilters: PropTypes.array,
  collectionId: PropTypes.string,
  onRemoveFilterBlock: PropTypes.func,
  removeFilterBlock: PropTypes.func.isRequired,
};

FilterBlockContainer.defaultProps = {
  activeFilters: [],
  collectionId: getAdvancedSearchTableId(),
  onRemoveFilterBlock: () => {},
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeFilterBlock,
    },
    dispatch
  );
const mapStateToProps = (state, ownProps) => ({
  activeFilters: getActiveFilters(state, ownProps),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterBlockContainer);
