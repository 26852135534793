import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './tasks.scss';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { DateTypes } from 'web/liveFeed/tasks/libs/tasksConstants';
import { getImageUrl } from 'web/libs/constants';
import Button from 'components/buttons/action';
import TaskGroup from 'web/task/components/taskGroup/taskGroup';
import LoadingSpinner from 'components/loadingSpinner';
import { ColorClasses } from 'libs/constantsStyles';

class Tasks extends Component {
  constructor(props) {
    super(props);

    const { formatMessage } = this.props.intl;

    this.dateFilters = {
      todo: [
        {
          id: DateTypes.todayAndTomorrow,
          name: formatMessage({ id: 'web.filters.todayTomorrow' }),
        },
        {
          id: DateTypes.today,
          name: formatMessage({ id: 'common.today' }),
        },
        {
          id: DateTypes.tomorrow,
          name: formatMessage({ id: 'common.tomorrow' }),
        },
        {
          id: DateTypes.nextSevenDays,
          name: formatMessage({ id: 'web.filters.nextSeven' }),
        },
      ],
      overdue: [
        {
          id: DateTypes.lastThreeDays,
          name: formatMessage({ id: 'web.filters.lastThree' }),
        },
        {
          id: DateTypes.yesterday,
          name: formatMessage({ id: 'common.yesterday' }),
        },
        {
          id: DateTypes.lastSevenDays,
          name: formatMessage({ id: 'web.filters.lastSeven' }),
        },
      ],
    };
  }

  _getTodoDateFilter = () => {
    const { selectedTodoDateFilter } = this.props;
    return selectedTodoDateFilter || DateTypes.todayAndTomorrow;
  };

  _getOverdueDateFilter = () => {
    const { selectedOverdueDateFilter } = this.props;
    return selectedOverdueDateFilter || DateTypes.lastThreeDays;
  };

  render() {
    const {
      actionCreators,
      actionCreators: {
        openAddTask,
        changeOverdueSelectedDateFilter,
        changeTodoSelectedDateFilter,
      },
      doneFetchingTasks,
      overdue,
      todoFilteredSortedTasks,
      overdueFilteredSortedTasks,
    } = this.props;

    return (
      <div className="tasks">
        {!doneFetchingTasks && <LoadingSpinner imageUrl={getImageUrl} />}
        <div className="create-task-wrapper">
          <Button color={ColorClasses.blue} size="medium" onClick={openAddTask}>
            <FormattedHTMLMessage id="web.liveFeed.tasks.createNewTask" />
          </Button>
        </div>
        <div className="task-list">
          <TaskGroup
            actionCreators={actionCreators}
            title="web.liveFeed.tasks.todoHeader"
            dateFilters={this.dateFilters.todo}
            tasks={todoFilteredSortedTasks}
            selectedDateFilter={this._getTodoDateFilter()}
            onDateFilterChange={changeTodoSelectedDateFilter}
          />
          <TaskGroup
            actionCreators={actionCreators}
            title="web.liveFeed.tasks.overdueHeader"
            dateFilters={this.dateFilters.overdue}
            tasks={overdueFilteredSortedTasks}
            selectedDateFilter={this._getOverdueDateFilter()}
            onDateFilterChange={changeOverdueSelectedDateFilter}
            overdue={overdue}
          />
        </div>
      </div>
    );
  }
}

Tasks.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  overdue: PropTypes.bool,
  todoFilteredSortedTasks: PropTypes.array,
  overdueFilteredSortedTasks: PropTypes.array,
  selectedOverdueDateFilter: PropTypes.string.isRequired,
  selectedTodoDateFilter: PropTypes.string.isRequired,
  doneFetchingTasks: PropTypes.bool.isRequired,
};

Tasks.defaultProps = {
  overdueFilteredSortedTasks: [],
  todoFilteredSortedTasks: [],
  overdue: false,
};

export default injectIntl(Tasks);
