import { ALL_MEMBERS_NAME } from '../libs/teamsConstants';
import TableExternals from 'web/libs/tableExternals';

export const overrideTeams = (teams = [], isAdmin = false) =>
  teams.map((team = {}) => {
    if (team.master) {
      return {
        ...team,
        enabledDotsMenu: false,
        name: ALL_MEMBERS_NAME,
      };
    }
    if (!isAdmin) {
      return {
        ...team,
        enabledDotsMenu: false,
      };
    }
    return team;
  });

export const teamMembersTableExternals = new TableExternals();
export const allMembersTableExternals = new TableExternals();
