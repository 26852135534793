import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GmailIntegrationAlertIds } from '../../libs/gmailIntegrationConstants';
import Alert, { AlertColors } from 'components/alert';
import './gmailIntegrationAlert.scss';

class GmailIntegrationAlert extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      alert: { id },
      closeViewAlert,
    } = this.props;
    const defaultAlert = {
      classes: 'gmail-integration-alert',
      color: AlertColors.redDark,
      onClose: closeViewAlert,
    };

    switch (id) {
      case GmailIntegrationAlertIds.integrationUpdated:
        return {
          ...defaultAlert,
          color: AlertColors.greenDark,
          textId: 'web.emailIntegration.gmail.alert.integrationUpdated',
        };
      case GmailIntegrationAlertIds.googleDisconnected:
        return {
          ...defaultAlert,
          color: AlertColors.greenDark,
          textId: 'web.emailIntegration.gmail.alert.googleDisconnected',
        };
      case GmailIntegrationAlertIds.googleConnected:
        return {
          ...defaultAlert,
          color: AlertColors.greenDark,
          textId: 'web.emailIntegration.gmail.alert.googleConnected',
        };
      case GmailIntegrationAlertIds.integrationNotUpdated:
        return {
          ...defaultAlert,
          textId: 'web.emailIntegration.gmail.alert.integrationNotUpdated',
        };
      case GmailIntegrationAlertIds.googleNotConnected:
        return {
          ...defaultAlert,
          textId: 'web.emailIntegration.gmail.alert.googleNotConnected',
        };
      case GmailIntegrationAlertIds.googleNotDisconnected:
        return {
          ...defaultAlert,
          textId: 'web.emailIntegration.gmail.alert.googleNotDisconnected',
        };
      case GmailIntegrationAlertIds.integrationsFetchError:
        return {
          ...defaultAlert,
          textId: 'web.emailIntegration.gmail.alert.integrationsFetchError',
        };
      default:
        return null;
    }
  };
}

GmailIntegrationAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

export default GmailIntegrationAlert;
