import { basePost, baseGet, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function salesforceCustomizationInstall(params = {}) {
  return new Promise((resolve, reject) => {
    basePost(Urls.salesforceCustomizationApi, params, resolve, reject);
  });
}

export function salesforceCustomizationUninstall() {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.salesforceCustomizationApi, {}, resolve, reject);
  });
}

export function salesforceCustomizationStatus() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.salesforceCustomizationApi, {}, resolve, reject);
  });
}

export function salesforceCustomizationProfiles() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.salesforceCustomizationProfiles, {}, resolve, reject);
  });
}
