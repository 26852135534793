import React from 'react';
import PropTypes from 'prop-types';
import TeamManagementPage from '../teamManagementPage';
import AlertContainer from 'web/view/containers/alertContainer';
import TeamManagementAlerts from '../teamManagementAlerts';
import TeamManagementPopups from '../popups/teamManagementPopups';
import './teamManagement.scss';

const TeamManagement = (props) => (
  <div className="team-management-main-component">
    <TeamManagementPage {...props} />
    <AlertContainer>
      <TeamManagementAlerts />
    </AlertContainer>
    <TeamManagementPopups />
  </div>
);

TeamManagement.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default TeamManagement;
