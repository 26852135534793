import CommandCenterActionTypes from 'web/commandCenter/libs/commandCenterActionTypes';

export const sidebarSelectedItem = (state = 0, { type, itemIndex = 0 }) => {
  switch (type) {
    case CommandCenterActionTypes.sidebarMenu.selectItem:
      return itemIndex;

    default:
      return state;
  }
};
