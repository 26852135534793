import { deletePerson } from 'web/people/services/peopleService';
import {
  closePopup,
  setPopupLoading,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import { getGroups } from 'web/groups/actionCreators/groupsActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import { getPeople } from './peopleFetchActionCreators';

export const deletePeople = (peopleIds, onSuccess) => (dispatch) => {
  dispatch(setPopupLoading(true));

  const promises = peopleIds.map((id) => deletePerson(id));

  Promise.all(promises)
    .then(() => {
      dispatch(getPeople({ id: peopleIds }));
      dispatch(getGroups(true));
      dispatch(closePopup());
      onSuccess();
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.genericApiError));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};

export const setPeopleForDeletePopup = (people) => ({
  type: PeopleActionTypes.people.setPeopleForDelete,
  people,
});

export const clearPeopleForDeletePopup = () => ({
  type: PeopleActionTypes.people.setPeopleForDelete,
});
