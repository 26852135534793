export default {
  addAll: 'DOMAIN_BLOCK_ADD_ALL',
  addOne: 'DOMAIN_BLOCK_ADD_ONE',
  removeOne: 'DOMAIN_BLOCK_REMOVE_ONE',
  showLoading: 'DOMAIN_BLOCK_SHOW_LOADING',
  hideLoading: 'DOMAIN_BLOCK_HIDE_LOADING',
  showError: 'DOMAIN_BLOCK_SHOW_ERROR',
  hideError: 'DOMAIN_BLOCK_HIDE_ERROR',
  setNewDomainBlock: 'DOMAIN_BLOCK_SET_NEW',
  clearNewDomainBlock: 'DOMAIN_BLOCK_CLEAR_NEW',
  domainStateSearchChange: 'DOMAIN_BLOCK_SEARCH_CHANGE',
  clearSearchDomainBlock: 'DOMAIN_BLOCK_CLEAR_SEARCH',
};
