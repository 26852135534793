import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import { Currency } from 'web/libs/constants';

export function gongOpportunities(state = [], action) {
  switch (action.type) {
    case ActionTypes.gong.receiveOpportunities:
      return action.data;
    case PopupActionTypes.close:
      return [];
    default:
      return state;
  }
}

export function gongSelectedOpportunity(state = {}, action) {
  switch (action.type) {
    case ActionTypes.gong.changeSelectedOpportunity:
      return action.data;
    case PopupActionTypes.close:
      return {};
    default:
      return state;
  }
}

export function gongCloseOpportunityInSalesforce(state = false, action) {
  switch (action.type) {
    case ActionTypes.gong.closeOpportunityInSalesforce:
      return action.data;
    case PopupActionTypes.close:
      return false;
    default:
      return state;
  }
}

export function gongAccountName(state = '', action) {
  switch (action.type) {
    case ActionTypes.gong.changeAccountName:
      return action.data;
    case PopupActionTypes.close:
      return '';
    default:
      return state;
  }
}

export function gongDealSize(state = Currency.gong.defaultDealSize, action) {
  switch (action.type) {
    case ActionTypes.gong.changeDealSize:
      return action.data;
    case PopupActionTypes.close:
      return Currency.gong.defaultDealSize;
    default:
      return state;
  }
}

export function gongCelebrate(state = false, action) {
  switch (action.type) {
    case ActionTypes.gong.celebration:
      return action.data;
    case PopupActionTypes.close:
      return false;
    default:
      return state;
  }
}
