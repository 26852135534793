import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import GeneralActionTypes from '../libs/generalActionTypes';
import { GeneralTabs, GeneralPageAlertIds } from '../libs/generalPageConstants';
import toPairs from 'lodash/toPairs';
import head from 'lodash/head';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';

export const showValidationErrorMessage = (errors) => (dispatch) => {
  const [field, validationError] = head(toPairs(errors));
  dispatch(
    openViewAlert(GeneralPageAlertIds.validationError, {
      field,
      validationError,
    })
  );
};

export const setTab = (tab) => ({
  type: GeneralActionTypes.setTab,
  tab,
});

export const adminGeneralStartup = ({ tab }) => (dispatch) => {
  const activeTab = Object.values(GeneralTabs).includes(tab)
    ? tab
    : GeneralTabs.connectionsCustomizations;
  dispatch(commonStartup());
  dispatch(setTab(activeTab));
};
