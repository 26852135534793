export const SalesforceTabs = {
  connectionsCustomizations: 'connections-customizations',
  syncSettings: 'sync-settings',
};

export const SalesforceAlertIds = {
  formChangesNotSaved: 'SALESFORCE_DATA_NOT_SAVED',
  formChangesSaved: 'SALESFORCE_DATA_SAVED_SUCCESSFULLY',
};

export const ConfigureSalesforceActivityDetailsLink = (locale) =>
  `https://experienceleague.adobe.com/docs/marketo/using/product-docs/marketo-sales-connect/crm/salesforce-integration/configure-salesforce-activity-detail-customization.html?lang=${locale}`;
