import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IntegrationsPage from './integrationsPage';
import LoadingSpinner from 'components/loadingSpinner';
import SafelyChangeRouteContainer from 'web/safelyChangeRoute/containers/safelyChangeRouteContainer';
import { getImageUrl } from 'web/libs/constants';
import IntegrationsAlerts from '../containers/integrationsAlertsContainer';
import IntegrationsPopups from '../containers/integrationsPopupContainer';

const Integrations = (props) => {
  const { loading, isSafeToChangeRoute } = props;
  return (
    <Fragment>
      {loading && <LoadingSpinner imageUrl={getImageUrl} />}
      <IntegrationsPage {...props} />
      <SafelyChangeRouteContainer isSafeToChangeRoute={isSafeToChangeRoute} />
      <IntegrationsAlerts />
      <IntegrationsPopups />
    </Fragment>
  );
};

Integrations.propTypes = {
  loading: PropTypes.bool.isRequired,
  isSafeToChangeRoute: PropTypes.bool.isRequired,
  isIntegrationContentSharingEnabled: PropTypes.bool.isRequired,
  canShowHighspotCard: PropTypes.bool.isRequired,
};

export default Integrations;
