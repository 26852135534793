import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './square.scss';

const Square = ({
  active,
  children,
  classes,
  className,
  color,
  size,
  ...other
}) => {
  const componentClasses = classNames(
    classes,
    className,
    'tout-square',
    color,
    size,
    { active }
  );

  return (
    <button className={componentClasses} {...other} type="submit">
      {children}
    </button>
  );
};

export const SquareColors = {
  blue: 'blue',
  default: '',
  gray: 'gray',
  grayDark: 'dark-gray',
  green: 'green',
  tourquoiseLight: 'tourquoise-light',
  transparent: 'transparent',
  white: 'white',
};

export const SquareSizes = {
  default: '',
  medium: 'medium',
  standard: 'standard',
};

Square.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.string, //legacy
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(SquareColors)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
};

Square.defaultProps = {
  active: false,
  children: null,
  classes: '',
  className: '',
  color: SquareColors.blue,
  disabled: false,
  size: SquareSizes.standard,
};

export default Square;
