import EditIdentityActionTypes from '../libs/editIdentitySlideOutActionTypes';
import SlideOutsActionTypes from 'web/slideOuts/libs/slideOutsActionTypes';

export const editIdentitySlideOutIsOpen = (state = false, { type }) => {
  switch (type) {
    case EditIdentityActionTypes.open:
      return true;
    case EditIdentityActionTypes.close:
    case SlideOutsActionTypes.close:
      return false;
    default:
      return state;
  }
};

export const editIdentityId = (state = null, { type, id }) => {
  switch (type) {
    case EditIdentityActionTypes.setEditIdentityId:
      return id;
    default:
      return state;
  }
};

export const editIdentityInfoInstance = (
  state = null,
  { type, editingIdentity }
) => {
  switch (type) {
    case EditIdentityActionTypes.setEditIdentityInfoInstance:
      return editingIdentity ? { ...editingIdentity } : null;
    default:
      return state;
  }
};

export const editIdentitySignatureInstance = (
  state = '',
  { type, editingIdentitySignature }
) => {
  switch (type) {
    case EditIdentityActionTypes.setEditIdentitySignatureInstance:
      return editingIdentitySignature;
    default:
      return state;
  }
};

export const editIdentityDeliveryChannelInstance = (
  state = null,
  { type, editingIdentityDeliveryChannel }
) => {
  switch (type) {
    case EditIdentityActionTypes.setEditIdentityDeliveryChannelInstance:
      return editingIdentityDeliveryChannel;
    default:
      return state;
  }
};

export const displayIdentityCardInEditMode = (
  state = false,
  { type, editMode }
) => {
  switch (type) {
    case EditIdentityActionTypes.setDisplayIdentityCardEditMode:
      return editMode;
    default:
      return state;
  }
};

export const identityCardEditMode = (state = false, { type, editMode }) => {
  switch (type) {
    case EditIdentityActionTypes.setIdentityCardEditMode:
      return editMode;
    default:
      return state;
  }
};

export const signatureCardEditMode = (state = false, { type, editMode }) => {
  switch (type) {
    case EditIdentityActionTypes.setSignatureCardEditMode:
      return editMode;
    default:
      return state;
  }
};

export const deliveryChannelCardEditMode = (
  state = false,
  { type, editMode }
) => {
  switch (type) {
    case EditIdentityActionTypes.setDeliveryChannelCardEditMode:
      return editMode;
    default:
      return state;
  }
};

export const editIdentityLoading = (state = false, { type, loading }) => {
  switch (type) {
    case EditIdentityActionTypes.setLoading:
      return loading;
    default:
      return state;
  }
};

export const editIdentityError = (state = false, { type }) => {
  switch (type) {
    case EditIdentityActionTypes.showAlert:
      return true;
    case EditIdentityActionTypes.clearAlert:
      return false;
    default:
      return state;
  }
};

export const editIdentityErrorMessageKey = (
  state = null,
  { type, messageKey }
) => {
  switch (type) {
    case EditIdentityActionTypes.showAlert:
      return messageKey;
    case EditIdentityActionTypes.clearAlert:
      return null;
    default:
      return state;
  }
};

export const editIdentityWarning = (state = false, { type, messageKey }) => {
  switch (type) {
    case EditIdentityActionTypes.showWarning:
      return true;
    case EditIdentityActionTypes.clearWarning:
      return false;
    default:
      return state;
  }
};

export const editIdentitySuccessBar = (state = false, { type }) => {
  switch (type) {
    case EditIdentityActionTypes.showSuccessBar:
      return true;
    case EditIdentityActionTypes.clearSuccessBar:
      return false;
    default:
      return state;
  }
};

export const editIdentityValidationBarMessageKey = (
  state = null,
  { type, messageKey }
) => {
  switch (type) {
    case EditIdentityActionTypes.showWarning:
      return messageKey;
    case EditIdentityActionTypes.clearWarning:
      return null;
    default:
      return state;
  }
};

export const editIdentitySuccessBarMessageKey = (
  state = null,
  { type, messageKey }
) => {
  switch (type) {
    case EditIdentityActionTypes.showSuccessBar:
      return messageKey;
    case EditIdentityActionTypes.clearSuccessBar:
      return null;
    default:
      return state;
  }
};
