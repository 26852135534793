/*eslint new-cap:0, complexity:0 */
import { Map, OrderedMap } from 'immutable';
import TasksActionTypes from '../libs/tasksActionTypes';
import { taskIsBetweenDates } from '../services/tasksHelpers';

/* Tasks completed only utilize one range of dates */

const initTasks = OrderedMap({});
export function tasksCompleted(
  state = initTasks,
  {
    type = '',
    id = '',
    ids = [],
    data = [],
    completedDates = {},
    changes = {},
    task = {},
  }
) {
  switch (type) {
    case TasksActionTypes.completedSuccess:
      return state.withMutations((map) => {
        data.forEach((item) => {
          map.set(item.id, item);
        });
      });
    case TasksActionTypes.update:
      if (!task.id) {
        return state;
      } else if (
        (!task.done && !task.skipped) ||
        !task.remind_at ||
        !taskIsBetweenDates(task, completedDates.start, completedDates.end)
      ) {
        return state.delete(task.id);
      } else {
        return state.update(task.id, () => task);
      }
    case TasksActionTypes.completing:
      return (task.id && state.set(task.id, task)) || state;
    case TasksActionTypes.completingBulk:
      return state.withMutations((map) => {
        ids.forEach((item) => {
          map.update(
            parseInt(item, 10),
            (elem) => (elem && { ...elem, ...changes }) || undefined
          );
        });
      });
    case TasksActionTypes.uncompleting:
      return state.delete(task.id);
    case TasksActionTypes.destroy:
      return state.delete(parseInt(id, 10));
    case TasksActionTypes.destroyBulk:
      return state.withMutations((map) => {
        ids.forEach((item) => {
          map.delete(parseInt(item, 10));
        });
      });
    case TasksActionTypes.customReset:
    case TasksActionTypes.completedReset:
      return state.clear();
    default:
      return state;
  }
}

/* Used to keep track of what custom tasks have been received as assume the base date range is always stored. */
export function tasksCompletedDates(
  state = Map({}),
  { type = '', start, end }
) {
  switch (type) {
    case TasksActionTypes.completedSuccess:
      return (
        (start &&
          end &&
          state.withMutations((map) => {
            const currentStart = map.get('start');
            if (!currentStart || start < currentStart) {
              map.set('start', start);
            }
            const currentEnd = map.get('end');
            if (!currentEnd || end > currentEnd) {
              map.set('end', end);
            }
          })) ||
        state
      );
    case TasksActionTypes.customReset:
    case TasksActionTypes.completedReset:
      return state.clear();
    default:
      return state;
  }
}
