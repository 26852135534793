import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SalesforceIcon from 'components/icons/salesforceIcon';
import './salesforceStepLogin.scss';
import FormTitle from 'components/layouts/formLayout/formTitle';
import FormLayout from 'components/layouts/formLayout';
import { getLoginSalesforceLink } from 'web/login/helpers/loginHelper';

const SalesforceStepLogin = (props) => {
  const { isSFSandboxAuthenticationEnabled } = props;
  const salesforceLoginLabelKey = isSFSandboxAuthenticationEnabled
    ? 'loginWithSalesforce.signInWithSFSandbox'
    : 'loginWithSalesforce.signInWithSF';

  return (
    <FormLayout className="salesforce-step-login-form">
      <FormTitle id="loginWithSalesforce.title" />
      <a className="sign-in-sf-button" href={getLoginSalesforceLink(props)}>
        <SalesforceIcon />
        <FormattedMessage id={salesforceLoginLabelKey} />
      </a>
    </FormLayout>
  );
};

SalesforceStepLogin.defaultProps = {
  isGmailMode: false,
  isOwaMode: false,
  isSFSandboxAuthenticationEnabled: false,
};

SalesforceStepLogin.propTypes = {
  isGmailMode: PropTypes.bool,
  isOwaMode: PropTypes.bool,
  isSFSandboxAuthenticationEnabled: PropTypes.bool,
};

export default SalesforceStepLogin;
