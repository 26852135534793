import React from 'react';
import PropTypes from 'prop-types';
import { TEAM_EMAIL_THROTTLING_LIMIT_FORM_ID } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import EmailThrottlingLimitForm from 'web/settings/adminSettings/general/components/emailThrottlingLimitForm';

const EmailThrottlingLimit = (props) => {
  const {
    defaultDeliveryChannel,
    emailThrottlingLimits,
    onTeamEmailThrottlingLimitFormError,
    actionCreators: { updateEmailThrottlingSettings },
  } = props;

  return (
    <EmailThrottlingLimitForm
      defaultDeliveryChannel={defaultDeliveryChannel}
      form={TEAM_EMAIL_THROTTLING_LIMIT_FORM_ID}
      initialValues={emailThrottlingLimits}
      onError={onTeamEmailThrottlingLimitFormError}
      onSubmit={updateEmailThrottlingSettings}
      updateEmailThrottlingSettings={updateEmailThrottlingSettings}
    />
  );
};

EmailThrottlingLimit.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  defaultDeliveryChannel: PropTypes.object,
  emailThrottlingLimits: PropTypes.object.isRequired,
  onTeamEmailThrottlingLimitFormError: PropTypes.func.isRequired,
};

export default EmailThrottlingLimit;
