import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PersonItem from 'components/peopleSimpleGrid/personItem';
import FailedContactImportItem from 'components/failedContactImportItem';
import isEmpty from 'lodash/isEmpty';
import './previewRecipients.scss';

class PreviewRecipients extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      changePreview,
      currentPersonId,
      recipients,
      failedImports,
    } = this.props;

    return (
      <div className="previewRecipients">
        {!isEmpty(failedImports) && (
          <div className="failed-contact-import-container">
            <FailedContactImportItem
              onSelect={changePreview}
              key="importFailures"
              className="failed-contact-import-item"
              failures={failedImports}
              selected={currentPersonId === 0}
            />
          </div>
        )}
        <div className="list-recipients">
          {recipients.ids.map((id) => (
            <PersonItem
              onClick={changePreview}
              currentPersonId={currentPersonId}
              key={`preview-recipient-${id}`}
              person={recipients.map[id]}
            />
          ))}
        </div>
      </div>
    );
  }
}

PreviewRecipients.propTypes = {
  changePreview: PropTypes.func,
  currentPersonId: PropTypes.number,
  recipients: PropTypes.object.isRequired,
  failedImports: PropTypes.object,
};

export default PreviewRecipients;
