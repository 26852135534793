import I18N from 'languages';

export const MAX_AUTOCOMPLETE_RESULTS = 3;
export const PERSON_FILTER_ID = 'PERSON_FILTER_ID';
export const VIEW_AS_FILTER_ID = 'VIEW_AS_FILTER_ID';
export const PEOPLE_GROUP_FILTER_ID = 'PEOPLE_GROUP_FILTER_ID';
export const emailGridPeopleGroupFilterAnyValue = {
  value: -1,
  valueLabel: I18N.getStr(
    'web.commandCenter.advancedSearch.groupFilter.noFilterLabel'
  ),
};
export const peopleGroupSmartFilterLabel = I18N.getStr('common.group');
