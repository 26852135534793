import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ViewMore from 'components/viewMore';
import ReleaseNote from '../releaseNote';

class ReleaseNotes extends Component {
  render() {
    const {
      intl: { formatMessage },
      releaseNotes,
      shownCount,
    } = this.props;

    return (
      <ViewMore
        buttonRight
        className="release-notes"
        itemName={formatMessage(
          { id: 'web.releaseNotes.viewMoreItems' },
          { count: releaseNotes.length - shownCount }
        )}
        itemRenderer={this.itemRenderer}
        items={releaseNotes}
        showCount
        shownCount={shownCount}
      />
    );
  }

  itemRenderer = (releaseNote) => (
    <ReleaseNote key={`release-note-card-${releaseNote.id}`} {...releaseNote} />
  );
}

ReleaseNotes.propTypes = {
  intl: intlShape.isRequired,
  releaseNotes: PropTypes.array.isRequired,
  shownCount: PropTypes.number,
};

ReleaseNotes.defaultProps = {
  shownCount: 2,
};

export default injectIntl(ReleaseNotes);
