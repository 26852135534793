import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { bool, func, array } from 'prop-types';
import * as releaseNoteAnchorBoxActionCreators from '../actionCreators/releaseNoteAnchorBoxActionCreators';
import * as releaseNotesStartupActionCreators from 'web/releaseNotes/actionCreators/releaseNotesStartupActionCreators';
import ReleaseNoteAnchorBox from 'web/releaseNotes/components/releaseNoteAnchorBox/releaseNoteAnchorBox';

class ReleaseNoteAnchorBoxContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = bindActionCreators(
      {
        ...releaseNoteAnchorBoxActionCreators,
        ...releaseNotesStartupActionCreators,
      },
      dispatch
    );
  }

  componentWillMount() {
    this.actionCreators.releaseNotesStartup();
  }

  render() {
    const {
      releaseNotesIsOpen,
      releaseNotesIsVisible,
      releaseNotes,
    } = this.props;

    return (
      releaseNotes.length !== 0 && (
        <ReleaseNoteAnchorBox
          actionCreators={this.actionCreators}
          open={releaseNotesIsOpen}
          visible={releaseNotesIsVisible}
          releaseNotes={releaseNotes}
        />
      )
    );
  }
}

ReleaseNoteAnchorBoxContainer.propTypes = {
  dispatch: func.isRequired,
  releaseNotesIsOpen: bool.isRequired,
  releaseNotesIsVisible: bool.isRequired,
  releaseNotes: array.isRequired,
};

const mapStateToProps = (state) => ({
  releaseNotesIsVisible: state.releaseNotesIsVisible,
  releaseNotesIsOpen: state.releaseNotesIsOpen,
  releaseNotes: state.releaseNotesData,
});

export default connect(mapStateToProps)(ReleaseNoteAnchorBoxContainer);
