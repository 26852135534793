import omit from 'lodash/omit';
import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import {
  ElasticSearchTypes,
  ElasticSearchMode,
} from 'web/elasticSearch/libs/elasticSearchConstants';
import { esFetch as esFetchApi } from 'web/elasticSearch/services/esFetch';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  SORT_DIRECTION,
  DEFAULT_PAGE,
  SPECIAL_FILTER_CATEGORIES,
} from 'web/templates/libs/templatesConstants';
import {
  setTableFilter,
  setTableSort,
  setTablePage,
  setTablePerPage,
} from 'web/templates/actionCreators/templatesTableActionCreators';

export const setTemplatesLoading = (isLoading) => ({
  type: TemplatesActionTypes.templates.loading,
  isLoading,
});

const setTemplatesSearchLoading = (isLoading) => ({
  type: TemplatesActionTypes.templates.searchLoading,
  isLoading,
});

/*
  notFilters used to to exclude recently removed items from table
  and avoid them returning in fetch because the updates haven't been indexed
  yet due to elasticsearch's near real time nature
*/
export const getTemplates = ({ notFilters = {} } = {}) => (
  dispatch,
  getState
) => {
  dispatch(setTemplatesLoading(true));
  const {
    templatesPage: page,
    templatesPerPage: perPage,
    templatesSort: { column, direction },
    templatesFilter: filters,
    templatesSmartFilter: { dateRange },
    templatesSearchFilter: textSearch,
    templatesMode: mode,
    templatesTags: tableTags,
    templatesSpecialCategoryTags: specialCategoryTags,
    templatesViewer = {},
  } = getState();
  const viewer =
    templatesViewer.id === SPECIAL_FILTER_CATEGORIES.ALL_USERS
      ? {
          ...templatesViewer,
          type: 'subscription',
        }
      : templatesViewer;
  const tags = tableTags.concat(specialCategoryTags);

  return esFetchApi({
    type: ElasticSearchTypes.pitchTemplates,
    mode,
    page,
    perPage,
    column,
    direction,
    filters,
    dateRange,
    textSearch,
    notFilters,
    tags,
    viewer,
  })
    .then(({ results = [], total = 0 }) => {
      dispatch({ type: TemplatesActionTypes.templates.set, results, total });
      dispatch(setTemplatesLoading(false));
    })
    .catch(() => {
      dispatch(setTemplatesLoading(false));
      dispatch(openViewAlert(TemplateAlertIds.fetchError));
    });
};

export const searchTemplates = (query) => (dispatch, getState) => {
  dispatch(setTemplatesSearchLoading(true));
  const { templatesFilter: filters, templatesViewer = {} } = getState();
  const viewer =
    templatesViewer.id === SPECIAL_FILTER_CATEGORIES.ALL_USERS
      ? {
          ...templatesViewer,
          type: 'subscription',
        }
      : templatesViewer;
  return esFetchApi({
    type: ElasticSearchTypes.pitchTemplates,
    mode: ElasticSearchMode.all,
    filters: omit(filters, 'category_id'),
    textSearch: {
      fields: ['name'],
      term: query,
    },
    viewer,
  })
    .then(({ results = [] }) => {
      dispatch({ type: TemplatesActionTypes.templates.search, results });
      dispatch(setTemplatesSearchLoading(false));
    })
    .catch(() => {
      dispatch({ type: TemplatesActionTypes.templates.search, results: [] });
      dispatch(setTemplatesSearchLoading(false));
    });
};

export const sortTemplates = (columnToSort = '', directionToSort = '') => (
  dispatch
) => {
  const direction = SORT_DIRECTION[directionToSort.toLowerCase()] || '';
  const column = columnToSort.toLowerCase();
  dispatch(setTableSort(column, direction));
  return dispatch(getTemplates());
};

export const paginateTemplates = (page = DEFAULT_PAGE) => (dispatch) => {
  dispatch(setTablePage(page));
  return dispatch(getTemplates());
};

export const paginateTemplatesPerPage = (perPage) => (dispatch) => {
  dispatch(setTablePerPage(perPage));
  return dispatch(paginateTemplates(1));
};

export const filterTemplates = (filters = {}, notFilters = {}) => (
  dispatch
) => {
  dispatch(setTableFilter(filters));
  return dispatch(getTemplates(notFilters));
};
