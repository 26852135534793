export const EmailComposePopupIds = {
  actionsRequired: 'COMPOSE_EMAIL_ACTION_REQUIRED_POPUP',
  emailVerificationRequired: 'COMPOSE_EMAIL_EMAIL_VERIFICATION_REQUIRED_POPUP',
  notVerifiedSmtpNotVerifiedEmail:
    'COMPOSE_EMAIL_NOT_VERIFIED_SMTP_NOT_VERIFIED_EMAIL_POPUP',
  verifiedSmtpNotVerifiedEmail:
    'COMPOSE_EMAIL_VERIFIED_SMTP_NOT_VERIFIED_EMAIL_POPUP',
  smtpRequired: 'COMPOSE_EMAIL_SMTP_REQUIRED_POPUP',
};

export const ErrorMessagesTemplates = {
  emailVerificationRequired: 'need to verify the email address',
  smtpRequired: 'need to set up an SMTP Server',
};
