import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { TEMPLATE_TAG_TYPES } from 'web/templates/libs/templatesConstants';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';

export const getTemplatesAll = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.templates, { clientv3: true }, resolve, reject);
  });

export const getTemplatesFavorites = () =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.tags,
      {
        tag: TEMPLATE_TAG_TYPES.favorite,
        type: ElasticSearchTypes.pitchTemplates,
      },
      resolve,
      reject
    );
  });
