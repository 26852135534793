/*eslint complexity:0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import bind from 'memoize-bind';
import { getImageUrl } from 'web/libs/constants';
import Popup from 'components/popups';
import { SharingSettingsPopupIds } from '../libs/sharingSettingsConstants';
import {
  clearPopupAlert,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  addTeams,
  addTeamsOnChange,
  removeTeams,
} from '../actionCreators/sharingSettingsPopupActionCreators';
import SharingSettingsAddTeams from '../components/sharingSettingsAddTeams';
import { getAlert } from './sharingSettingsPopupHelper';

const EMPTY_POPUP = {};
const POPUP_SIZE = 545;

class SharingSettingsPopupContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      addTeams: () => dispatch(addTeams()),
      addTeamsOnChange: (values) => dispatch(addTeamsOnChange(values)),
      clearAlert: () => dispatch(clearPopupAlert()),
      closePopup: () => dispatch(closePopup()),
      removeTeams: (ids, callback) => dispatch(removeTeams(ids, callback)),
    };
  }

  render() {
    const { children, popup } = this.props;
    let object = EMPTY_POPUP;
    if (popup) {
      object = this._getGlobalPopupObject();
    }

    /* className used for exceptionSelectors for outside clicks in parent popup */
    return (
      <div className="sharing-settings-popup-container">
        {object.children ? <Popup {...object} /> : null}
        {children}
      </div>
    );
  }

  _getGlobalPopupObject() {
    const { loading } = this.props;
    return {
      loading,
      getImageUrl,
      ...this._getMainComponent(),
      alert: getAlert(this.props, this.actionCreators),
    };
  }

  _getMainComponent() {
    const { loading, popup } = this.props;

    switch (popup) {
      case SharingSettingsPopupIds.addTeams: {
        const {
          addTeamsSelectedValues,
          popupData: { teams },
        } = this.props;
        return {
          children: (
            <SharingSettingsAddTeams
              onChange={this.actionCreators.addTeamsOnChange}
              selectedValues={addTeamsSelectedValues}
              teams={teams}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.addTeams,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled: addTeamsSelectedValues.length === 0,
            primaryTextId: 'web.settings.sharing.addTeams',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.settings.sharing.addTeamsTitle',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      case SharingSettingsPopupIds.removeTeams: {
        const {
          popupData: { callback: onSuccess, ids },
        } = this.props;
        return {
          children: (
            <FormattedMessage
              id="web.settings.sharing.removeTeamsBody"
              values={{ count: ids.length }}
            />
          ),
          footer: {
            onPrimary: bind(
              this.actionCreators.removeTeams,
              this,
              ids,
              onSuccess
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.remove',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.settings.sharing.removeTeamsTitle',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      default:
        return EMPTY_POPUP;
    }
  }
}

SharingSettingsPopupContainer.propTypes = {
  addTeamsSelectedValues: PropTypes.array.isRequired,
  alert: PropTypes.string.isRequired,
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    addTeamsSelectedValues: state.sharingSettingsAddTeamsSelectedValues,
    alert: state.popupAlert,
    loading: state.popupLoading,
    popup: state.popup,
    popupData: state.popupData,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(SharingSettingsPopupContainer);
