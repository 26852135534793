/**
 *
 * FileProgressCell
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProgressBar from 'components/progressBar/progressBar';
import './fileProgressCell.scss';

const FINISHED = 'finished';

function FileProgressCell({
  className,
  onClick,
  property,
  rowData,
  style,
  secondaryProperty,
}) {
  const data = rowData[property];
  const status = rowData[secondaryProperty];

  function renderCell() {
    if (data && status !== FINISHED) {
      return <ProgressBar animate progress={data} />;
    } else {
      return null;
    }
  }
  const containerClasses = classNames(className, 'cell-text', {
    clickable: onClick,
  });

  return (
    <div className={containerClasses} style={style}>
      {renderCell()}
    </div>
  );
}

FileProgressCell.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  property: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  secondaryProperty: PropTypes.string,
  style: PropTypes.object,
};

FileProgressCell.defaultProps = {
  onClick: null,
  className: '',
  style: {},
  secondaryProperty: '',
};

export default FileProgressCell;
