import React, { Component } from 'react';
import { bool, string, array, object, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import ToutTable from 'table/modules/toutTable';
import TextCell from 'table/modules/cells/textCell';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import { alphabetical } from 'table/modules/sorting';
import { getImageUrl } from 'web/libs/constants';
import {
  ConnectionStates,
  TeamAccessSmartFilterValues,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from '../../libs/marketoSettingsConstants';
import './marketoAdminTeamAccess.scss';

const TABLE_ID = 'marketo-admin-team-access-table';
const NAME = 'name';

class MarketoAdminTeamAccess extends Component {
  componentWillMount() {
    const {
      actionCreators,
      intl: { formatMessage },
    } = this.props;
    this.columns = this._getColumns(formatMessage);
    this.actions = [
      {
        label: formatMessage({ id: 'common.connect' }),
        onClick: actionCreators.openGrantAccessPopup,
      },
      {
        label: formatMessage({ id: 'common.disconnect' }),
        onClick: actionCreators.openRevokeAccessPopup,
      },
    ];

    this.search = {
      placeholderText: formatMessage({ id: 'common.search' }),
      searchers: [
        {
          label: formatMessage({ id: 'common.name' }),
          searching: (item) => item.name,
        },
        {
          label: formatMessage({ id: 'common.title' }),
          searching: (item) => item.title,
        },
      ],
      onClear: () => {},
    };
  }

  render() {
    const { users, gettingUsers, connectionState } = this.props;
    return (
      <div className={TABLE_ID}>
        <ToutTable
          allItems
          bulkActions={{ actions: this.actions, selectAll: false }}
          columns={this.columns}
          columnFilter
          defaultSortingColumn={NAME}
          items={users}
          loading={
            gettingUsers ||
            connectionState === ConnectionStates.gettingIntegration
          }
          loadingGetUrl={getImageUrl}
          pagination={{
            currentPage: DEFAULT_PAGE,
            perPage: DEFAULT_PAGE_SIZE,
          }}
          search={this.search}
          selectable
          smartFilters={this._getSmartFilters()}
          tableId={TABLE_ID}
        />
      </div>
    );
  }

  _getColumns = (formatMessage) => [
    {
      id: NAME,
      name: formatMessage({ id: 'common.name' }),
      sorting: alphabetical('name'),
      rowCell: {
        component: TextCell,
        metadata: { property: 'name' },
      },
    },
    {
      id: 'title',
      name: formatMessage({ id: 'common.title' }),
      sorting: alphabetical('title'),
      rowCell: {
        component: TextCell,
        metadata: { property: 'title', wrapLines: 2 },
      },
    },
    {
      id: 'access',
      name: formatMessage({ id: 'web.marketoSettings.access' }),
      rowCell: {
        component: TextCustomCell,
        metadata: { formatText: this._getOutcome },
      },
    },
  ];

  _getSmartFilters = () => {
    const {
      actionCreators,
      teamAccessSmartFilter,
      intl: { formatMessage },
    } = this.props;

    return [
      {
        filters: [
          {
            label: formatMessage({ id: 'common.all' }),
            value: TeamAccessSmartFilterValues.all,
          },
          {
            label: formatMessage({ id: 'common.connected' }),
            value: TeamAccessSmartFilterValues.granted,
          },
          {
            label: formatMessage({ id: 'web.marketoSettings.notGranted' }),
            value: TeamAccessSmartFilterValues.notGranted,
          },
        ],
        onChange: actionCreators.onMarketoTeamAccessSmartFilterChange,
        selectedValue: teamAccessSmartFilter,
        width: 250,
      },
    ];
  };

  _getOutcome = ({ hasAuth, id, isBeingToggled, isSelectedDisabled }) => {
    if (isBeingToggled) {
      hasAuth = !hasAuth;
    }

    return (
      <div
        className={classNames(
          'team-access-outcome',
          { 'has-auth': hasAuth },
          { disabled: isBeingToggled || isSelectedDisabled }
        )}
        data-id={id}
        data-has-auth={hasAuth}
        onClick={!isBeingToggled && !isSelectedDisabled && this._outcomeClick}
      >
        <span className={classNames('team-access-hover-disconnect hide')}>
          <FormattedMessage id="common.disconnect" />
        </span>
        <span className={classNames('team-access-state')}>
          <FormattedMessage
            id={this._getAccessOutcome(hasAuth, isSelectedDisabled)}
          />
        </span>
      </div>
    );
  };

  _getAccessOutcome = (hasAuth, isSelectedDisabled) => {
    if (isSelectedDisabled) {
      return 'web.marketoSettings.adminUser';
    } else {
      return hasAuth ? 'web.marketoSettings.engageSalesUser' : 'common.connect';
    }
  };

  _outcomeClick = ({ currentTarget: { dataset } }) => {
    const {
      actionCreators: { openGrantAccessPopup, openRevokeAccessPopup },
    } = this.props;
    const ids = [dataset.id];
    dataset.hasAuth === 'true'
      ? openRevokeAccessPopup(ids)
      : openGrantAccessPopup(ids);
  };
}

MarketoAdminTeamAccess.propTypes = {
  actionCreators: object.isRequired,
  connectionState: string.isRequired,
  gettingUsers: bool.isRequired,
  teamAccessSmartFilter: string.isRequired,
  users: array.isRequired,
  intl: intlShape.isRequired,
  page: number.isRequired,
};

export default injectIntl(MarketoAdminTeamAccess);
