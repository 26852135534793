import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Popup from 'components/popups';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ToutTable from 'table/modules/toutTable';
import LinkCell from 'table/modules/cells/linkCell/linkCell';
import { PopupSize } from 'libs/constantsShared';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Action, { ButtonSizes } from 'components/buttons/action';
import Text from 'components/inputs/text';
import DefaultSelect from 'components/selects/default';
import {
  bulkCallOutcomeOptions,
  alertEmptyRequiredCallOutcomeOption,
} from '../../actionCreators/callOutcomeActionCreators';
import { compose } from 'redux';
import './manageDialerSettingsModal.scss';
import I18N from 'languages';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';

const OUTCOME_LIMIT = 15;
const CHAR_LIMIT = 50;

class ManageCallOutcomesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callOutcomeOptions: this.props.callOutcomeOptions,
      create: [],
      ids_to_delete: [],
      newName: '',
      newType: this.props.callOutcomeValidTypes[0],
      tempId: 0,
    };
  }

  addOptionHandler = () => {
    const { tempId: id, newName: name, newType: type_of } = this.state;
    const newOption = { id, name, type_of };
    this.setState(({ callOutcomeOptions, create, tempId }) => ({
      callOutcomeOptions: [...callOutcomeOptions, newOption],
      create: [...create, newOption],
      newName: '',
      tempId: tempId - 1,
    }));
  };

  deleteOptionHandler = (rowData, event) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState(({ callOutcomeOptions, ids_to_delete }) => {
      const newState = {
        callOutcomeOptions: callOutcomeOptions.filter(
          (item) => item.id !== rowData.id
        ),
      };
      if (rowData.id > 0) {
        newState.ids_to_delete = [...ids_to_delete, rowData.id];
      }
      return newState;
    });
  };

  getHoverTooltipWrappedText = (text) => (
    <HoverTooltip
      place={HoverTooltipPlace.bottom}
      textValue={text}
      tooltipId={`outcome-tooltip-${text}`}
    >
      <div className="text-overflow">{text}</div>
    </HoverTooltip>
  );

  getColumns = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return [
      {
        id: 'name',
        name: formatMessage({
          id: 'web.settings.adminSettings.dialerSettings.outcome',
        }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) => this.getHoverTooltipWrappedText(data.name),
            property: 'name',
          },
        },
        width: 300,
      },
      {
        id: 'type_of',
        name: formatMessage({
          id: 'web.settings.adminSettings.dialerSettings.outcomeType',
        }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) =>
              I18N.getConstStr(
                'web.settings.adminSettings.dialerSettings.outcomeType',
                data.type_of
              ),
            property: 'type_of',
          },
        },
        width: 150,
      },
      {
        id: 'action',
        name: formatMessage({
          id: 'web.settings.adminSettings.dialerSettings.action',
        }),
        rowCell: {
          component: LinkCell,
          metadata: {
            formatText: () => I18N.getStr('common.delete'),
            onClick: this.deleteOptionHandler,
          },
        },
        width: 100,
      },
    ];
  };

  onClose = async () => {
    const { dispatch } = this.props;
    const { create, ids_to_delete } = this.state;
    ReactDOM.unmountComponentAtNode(document.getElementById('root-modal'));
    if (create.length || ids_to_delete.length) {
      await dispatch(bulkCallOutcomeOptions({ create, ids_to_delete }));
    }
    dispatch(alertEmptyRequiredCallOutcomeOption({ loadOptions: false }));
  };

  getTitle = (limitReached) => {
    const {
      intl: { formatMessage },
    } = this.props;
    return limitReached
      ? formatMessage(
          {
            id:
              'web.settings.adminSettings.dialerSettings.manageCallOutcomesModalAddTooltip',
          },
          { count: OUTCOME_LIMIT }
        )
      : '';
  };

  render() {
    const {
      callOutcomeValidTypes,
      intl: { formatMessage },
    } = this.props;
    const { callOutcomeOptions, newName, newType } = this.state;
    const limitReached = callOutcomeOptions.length >= OUTCOME_LIMIT;
    const charLimitReached = newName.length > CHAR_LIMIT;
    return (
      <Popup
        header={{
          onClose: this.onClose,
          textId:
            'web.settings.adminSettings.dialerSettings.manageCallOutcomes',
        }}
        size={PopupSize.large}
      >
        <div className="margin-top-20 margin-bottom-20">
          <FormattedHTMLMessage
            id="web.settings.adminSettings.dialerSettings.manageCallOutcomesModalNotice"
            values={{ count: callOutcomeOptions.length }}
          />
        </div>
        <div className="manage-dialer-settings-table">
          <ToutTable
            actionHeaderHeight={0}
            columns={this.getColumns()}
            items={callOutcomeOptions}
            maxHeight={500}
            minHeight={500}
            allItems
          />
        </div>
        <div className="manage-dialer-settings-modal-footer margin-top-20">
          <Text
            onChange={(value) => this.setState({ newName: value })}
            placeholder={formatMessage({
              id: 'web.settings.adminSettings.dialerSettings.enterOutcomeName',
            })}
            value={newName}
          />
          <DefaultSelect
            items={callOutcomeValidTypes.map((type) => ({
              id: type,
              name: I18N.getConstStr(
                'web.settings.adminSettings.dialerSettings.outcomeType',
                type
              ),
            }))}
            onChange={(value) => this.setState({ newType: value })}
            selected={newType}
            width={150}
          />
          <Action
            disabled={!newName.trim() || limitReached || charLimitReached}
            onClick={this.addOptionHandler}
            size={ButtonSizes.medium}
            title={this.getTitle(limitReached)}
          >
            <FormattedMessage id="common.add" />
          </Action>
        </div>
        <div className="manage-dialer-settings-outcome-char-counter">
          {newName.length}
        </div>
        <div className="manage-dialer-settings-char-lim-error-message">
          {charLimitReached ? (
            <FormattedHTMLMessage id="web.settings.adminSettings.dialerSettings.charOutcomeLimit" />
          ) : null}
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => ({
  callOutcomeOptions: state.callOutcomeOptions,
  callOutcomeValidTypes: state.callOutcomeValidTypes,
  popup: state.popup,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(ManageCallOutcomesModal);

ManageCallOutcomesModal.propTypes = {
  callOutcomeOptions: PropTypes.array,
  callOutcomeValidTypes: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  popup: PropTypes.string,
};

ManageCallOutcomesModal.defaultProps = {
  callOutcomeOptions: [],
  callOutcomeValidTypes: [],
  popup: false,
};
