import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import LabeledInputReduxForm from 'components/labeledInputReduxForm';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import FormLayout from 'components/layouts/formLayout';
import FormTitle from 'components/layouts/formLayout/formTitle';
import FormButtonsArea from 'components/layouts/formLayout/formButtonsArea';
import passwordWithTooltip from 'web/form/hoc/passwordWithTooltip';
import {
  allPasswordValidators,
  allConfirmPasswordValidators,
} from 'web/form/libs/passwordValidators';
import './setPassword.scss';

class SetPassword extends Component {
  constructor(props) {
    super(props);

    const POSITION_LEFT = 'left';

    this.passwordFiledWithTooltip = passwordWithTooltip({
      place: POSITION_LEFT,
    })(LabeledInputReduxForm);
  }

  render() {
    const { loading, handleSubmit } = this.props;

    return (
      <div className="set-password-form">
        <FormLayout className="set-password-form-wrapper">
          <FormTitle id="login.setPassword.title" />
          {loading && <LoadingSpinner imageUrl={getImageUrl} />}

          <Form
            className="set-password-form"
            onSubmit={handleSubmit(this.onSubmitSetPassword)}
          >
            <div className="form-row margin-bottom-full">
              <Field
                name="password"
                autoFocus={false}
                id="user_password"
                label="common.password"
                type="password"
                component={this.passwordFiledWithTooltip}
                validate={allPasswordValidators}
              />
            </div>
            <div className="form-row">
              <Field
                name="confirm_password"
                autoFocus={false}
                id="user_confirm_password"
                label="login.setPassword.confirmPassword"
                type="password"
                component={LabeledInputReduxForm}
                validate={allConfirmPasswordValidators}
              />
            </div>
            <FormButtonsArea align="right">
              <Button
                className="send-set-password-button"
                disabled={loading}
                size={ButtonSizes.medium}
                color={ButtonColors.blue}
                type="submit"
              >
                <FormattedMessage id="login.setPassword.title" />
              </Button>
            </FormButtonsArea>
          </Form>
        </FormLayout>
      </div>
    );
  }

  onSubmitSetPassword = (values) => {
    const {
      actionCreators: { sendSetPasswordRequest },
      match: {
        params: { id },
      },
    } = this.props;
    return sendSetPasswordRequest(values.password, id);
  };
}

SetPassword.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default reduxForm({ form: 'set-password-form' })(SetPassword);
