import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getImageUrl } from 'web/libs/constants';
import { AddressTypes } from 'web/person/libs/personDetailsConstants';
import './infoCardDetailsSocialMedia.scss';

class InfoCardDetailsSocialMedia extends Component {
  render() {
    const { className, linkedin, salesforce, marketo } = this.props;
    return (
      <div
        className={classNames(
          'person-details-info-card-details-social-media',
          className
        )}
      >
        {this._getSvgElement(
          AddressTypes.salesforce,
          salesforce,
          this._onClickSalesforce
        )}
        {this._getSvgElement(
          AddressTypes.linkedin,
          linkedin,
          this._onClickLinkedin
        )}
        {this._getSvgElement(
          AddressTypes.marketo,
          marketo,
          this._onClickMarketo
        )}
      </div>
    );
  }

  _getPngElement = (type, { value }, onClick) => {
    if (value) {
      return (
        <a href={value} target="_blank" onClick={onClick}>
          <div
            className={classNames('info-card-details-social-media-png', type)}
          >
            <img src={getImageUrl(type)} className="img-svg" />
          </div>
        </a>
      );
    } else {
      return (
        <div
          className={classNames(
            'info-card-details-social-media-png gray-out',
            type
          )}
        >
          <img src={getImageUrl(type)} className="img-svg" />
        </div>
      );
    }
  };

  _getSvgElement = (type, { value }, onClick) => {
    if (value) {
      return (
        <a href={value} target="_blank" onClick={onClick}>
          <div
            style={{ backgroundImage: `url(${getImageUrl(type, 'svg')}` }}
            className={classNames('info-card-details-social-media-svg', type)}
          />
        </a>
      );
    } else {
      return (
        <div
          style={{ backgroundImage: `url(${getImageUrl(type, 'svg')}` }}
          className={classNames(
            'info-card-details-social-media-svg gray-out',
            type
          )}
        />
      );
    }
  };

  _onClickSalesforce = () => this.props.onClick(this.props.salesforce)

  _onClickMarketo = () => this.props.onClick(this.props.marketo);

  _onClickLinkedin = () => this.props.onClick(this.props.linkedin);
}

InfoCardDetailsSocialMedia.propTypes = {
  className: PropTypes.string,
  linkedin: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  salesforce: PropTypes.object,
  marketo: PropTypes.object,
};

InfoCardDetailsSocialMedia.defaultProps = {
  className: '',
  linkedin: {},
  salesforce: {},
  marketo: {}
};

export default InfoCardDetailsSocialMedia;
