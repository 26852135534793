export const JOB_TITLES = [
  'createAccount.jobTitle.marketingPractitioner',
  'createAccount.jobTitle.marketingMgr',
  'createAccount.jobTitle.marketingDirVP',
  'createAccount.jobTitle.marketingCMO',
  'createAccount.jobTitle.salesProfessional',
  'createAccount.jobTitle.salesMgrDir',
  'createAccount.jobTitle.salesVPExec',
  'createAccount.jobTitle.ITProfessionalMgr',
  'createAccount.jobTitle.ITDirVPCIO',
  'createAccount.jobTitle.financeProfessional',
  'createAccount.jobTitle.financeMgmtExec',
  'createAccount.jobTitle.CEOPresident',
  'createAccount.jobTitle.consultantAgency',
  'createAccount.jobTitle.jobSeekerStudent',
];

export const COUNTRIES = [
  'countries.Afghanistan',
  'countries.AlandIslands',
  'countries.Albania',
  'countries.Algeria',
  'countries.AmericanSamoa',
  'countries.Andorra',
  'countries.Angola',
  'countries.Anguilla',
  'countries.Antarctica',
  'countries.AntiguaAndBarbuda',
  'countries.Argentina',
  'countries.Armenia',
  'countries.Aruba',
  'countries.Australia',
  'countries.Austria',
  'countries.Azerbaijan',
  'countries.Bahamas',
  'countries.Bahrain',
  'countries.Bangladesh',
  'countries.Barbados',
  'countries.Belarus',
  'countries.Belgium',
  'countries.Belize',
  'countries.Benin',
  'countries.Bermuda',
  'countries.Bhutan',
  'countries.Bolivia',
  'countries.BosniaAndHerzegovina',
  'countries.Botswana',
  'countries.BouvetIsland',
  'countries.Brazil',
  'countries.BritishIndianOceanTerritory',
  'countries.BruneiDarussalam',
  'countries.Bulgaria',
  'countries.BurkinaFaso',
  'countries.Burundi',
  'countries.Cambodia',
  'countries.Cameroon',
  'countries.Canada',
  'countries.CapeVerde',
  'countries.CaymanIslands',
  'countries.CentralAfricanRepublic',
  'countries.Chad',
  'countries.Chile',
  'countries.China',
  'countries.ChristmasIsland',
  'countries.CocosKeelingIslands',
  'countries.Colombia',
  'countries.Comoros',
  'countries.Congo',
  'countries.CongoTheDemocraticRepublicOfThe',
  'countries.CookIslands',
  'countries.CostaRica',
  'countries.CoteDIvoire',
  'countries.Croatia',
  'countries.Cuba',
  'countries.Cyprus',
  'countries.CzechRepublic',
  'countries.Denmark',
  'countries.Djibouti',
  'countries.Dominica',
  'countries.DominicanRepublic',
  'countries.Ecuador',
  'countries.Egypt',
  'countries.ElSalvador',
  'countries.EquatorialGuinea',
  'countries.Eritrea',
  'countries.Estonia',
  'countries.Ethiopia',
  'countries.FalklandIslandsMalvinas',
  'countries.FaroeIslands',
  'countries.Fiji',
  'countries.Finland',
  'countries.France',
  'countries.FrenchGuiana',
  'countries.FrenchPolynesia',
  'countries.FrenchSouthernTerritories',
  'countries.Gabon',
  'countries.Gambia',
  'countries.Georgia',
  'countries.Germany',
  'countries.Ghana',
  'countries.Gibraltar',
  'countries.Greece',
  'countries.Greenland',
  'countries.Grenada',
  'countries.Guadeloupe',
  'countries.Guam',
  'countries.Guatemala',
  'countries.Guernsey',
  'countries.Guinea',
  'countries.GuineaBissau',
  'countries.Guyana',
  'countries.Haiti',
  'countries.HeardIslandAndMcdonaldIslands',
  'countries.HolySeeVaticanCityState',
  'countries.Honduras',
  'countries.HongKong',
  'countries.Hungary',
  'countries.Iceland',
  'countries.India',
  'countries.Indonesia',
  'countries.IranIslamicRepublicOf',
  'countries.Iraq',
  'countries.Ireland',
  'countries.IsleOfMan',
  'countries.Israel',
  'countries.Italy',
  'countries.Jamaica',
  'countries.Japan',
  'countries.Jersey',
  'countries.Jordan',
  'countries.Kazakhstan',
  'countries.Kenya',
  'countries.Kiribati',
  'countries.KoreaDemocraticPeoplesRepublicOf',
  'countries.KoreaRepublicOf',
  'countries.Kuwait',
  'countries.Kyrgyzstan',
  'countries.LaoPeoplesDemocraticRepublic',
  'countries.Latvia',
  'countries.Lebanon',
  'countries.Lesotho',
  'countries.Liberia',
  'countries.Libya',
  'countries.Liechtenstein',
  'countries.Lithuania',
  'countries.Luxembourg',
  'countries.Macao',
  'countries.MacedoniaTheFormerYugoslavRepublicOf',
  'countries.Madagascar',
  'countries.Malawi',
  'countries.Malaysia',
  'countries.Maldives',
  'countries.Mali',
  'countries.Malta',
  'countries.MarshallIslands',
  'countries.Martinique',
  'countries.Mauritania',
  'countries.Mauritius',
  'countries.Mayotte',
  'countries.Mexico',
  'countries.MicronesiaFederatedStatesOf',
  'countries.MoldovaRepublicOf',
  'countries.Monaco',
  'countries.Mongolia',
  'countries.Montserrat',
  'countries.Morocco',
  'countries.Mozambique',
  'countries.Myanmar',
  'countries.Namibia',
  'countries.Nauru',
  'countries.Nepal',
  'countries.Netherlands',
  'countries.NetherlandsAntilles',
  'countries.NewCaledonia',
  'countries.NewZealand',
  'countries.Nicaragua',
  'countries.Niger',
  'countries.Nigeria',
  'countries.Niue',
  'countries.NorfolkIsland',
  'countries.NorthernMarianaIslands',
  'countries.Norway',
  'countries.Oman',
  'countries.Pakistan',
  'countries.Palau',
  'countries.PalestinianTerritoryOccupied',
  'countries.Panama',
  'countries.PapuaNewGuinea',
  'countries.Paraguay',
  'countries.Peru',
  'countries.Philippines',
  'countries.Pitcairn',
  'countries.Poland',
  'countries.Portugal',
  'countries.PuertoRico',
  'countries.Qatar',
  'countries.Reunion',
  'countries.Romania',
  'countries.RussianFederation',
  'countries.Rwanda',
  'countries.SaintHelena',
  'countries.SaintKittsAndNevis',
  'countries.SaintLucia',
  'countries.SaintPierreAndMiquelon',
  'countries.SaintVincentAndTheGrenadines',
  'countries.Samoa',
  'countries.SanMarino',
  'countries.SaoTomeAndPrincipe',
  'countries.SaudiArabia',
  'countries.Senegal',
  'countries.SerbiaAndMontenegro',
  'countries.Seychelles',
  'countries.SierraLeone',
  'countries.Singapore',
  'countries.Slovakia',
  'countries.Slovenia',
  'countries.SolomonIslands',
  'countries.Somalia',
  'countries.SouthAfrica',
  'countries.SouthGeorgiaAndTheSouthSandwichIslands',
  'countries.Spain',
  'countries.SriLanka',
  'countries.Sudan',
  'countries.Suriname',
  'countries.SvalbardAndJanMayen',
  'countries.Swaziland',
  'countries.Sweden',
  'countries.Switzerland',
  'countries.SyrianArabRepublic',
  'countries.Taiwan',
  'countries.Tajikistan',
  'countries.TanzaniaUnitedRepublicOf',
  'countries.Thailand',
  'countries.TimorLeste',
  'countries.Togo',
  'countries.Tokelau',
  'countries.Tonga',
  'countries.TrinidadAndTobago',
  'countries.Tunisia',
  'countries.Turkey',
  'countries.Turkmenistan',
  'countries.TurksAndCaicosIslands',
  'countries.Tuvalu',
  'countries.Uganda',
  'countries.Ukraine',
  'countries.UnitedArabEmirates',
  'countries.UnitedKingdom',
  'countries.UnitedStates',
  'countries.UnitedStatesMinorOutlyingIslands',
  'countries.Uruguay',
  'countries.Uzbekistan',
  'countries.Vanuatu',
  'countries.Venezuela',
  'countries.Vietnam',
  'countries.VirginIslandsBritish',
  'countries.VirginIslandsUS',
  'countries.WallisAndFutuna',
  'countries.WesternSahara',
  'countries.Yemen',
  'countries.Zambia',
  'countries.Zimbabwe',
];

export const errorMessage = {
  invitationExpired: 'createAccount.form.errors.invitationExpired',
};

export const CodeTypes = {
  activation: 'activation',
  invitation: 'invitation',
};
