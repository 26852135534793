import {
  closePopup as closePopupCall,
  openPopup,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import MarketoAdminPopupIds from 'web/marketoSettings/libs/marketoAdminPopupIds';
import MarketoSettingsActionTypes from '../libs/marketoSettingsActionTypes';
import {
  BULK_ACTIONS,
  WORKSPACES_ERROR,
} from '../libs/marketoSettingsConstants';
import { getIdsToActOn } from '../services/authHelpers';
import { getWorkspaces as getWorkspacesCall } from '../services/integrationConnection';

export const openGrantAccessPopup = (ids, callback) => (dispatch, getState) => {
  const authUserIds = getState().marketoAuthenticationUserIds;
  const idsToActOn = getIdsToActOn(BULK_ACTIONS.create, authUserIds, ids);
  if (!idsToActOn.length) {
    dispatch({
      type: MarketoSettingsActionTypes.userAccess.allSelectedUsersConnected,
    });
    return;
  }
  const users = getState().marketoAdminSubscriptionUsers.filter((user) =>
    idsToActOn.includes(user.id)
  );
  dispatch(openPopup(MarketoAdminPopupIds.grantAccess, { callback, users }));
};

export const openRevokeAccessPopup = (ids, callback) => (
  dispatch,
  getState
) => {
  const authUserIds = getState().marketoAuthenticationUserIds;
  const idsToActOn = getIdsToActOn(BULK_ACTIONS.destroy, authUserIds, ids);
  if (!idsToActOn.length) {
    dispatch({
      type: MarketoSettingsActionTypes.userAccess.allSelectedUsersDisconnected,
    });
    return;
  }
  dispatch(openPopup(MarketoAdminPopupIds.revokeAccess, { callback, ids }));
};

export const updateWorkspaces = (selected) => (dispatch, getState) => {
  const { marketoAdminWorkspaces: workspaces } = getState();
  const selectedWorkspaces =
    selected &&
    workspaces
      .filter((workspace) =>
        selected.find(
          (selectedWorkspace) => selectedWorkspace.label === workspace.name
        )
      )
      .map((workspace) => workspace.name);
  dispatch({
    type: MarketoSettingsActionTypes.workspacesModal.updateWorkspaces,
    workspaces: selectedWorkspaces,
  });
};

export const closePopup = () => (dispatch) => {
  dispatch(closePopupCall());
  dispatch(updateWorkspaces());
};

export const getWorkspaces = () => (dispatch) => {
  dispatch({ type: MarketoSettingsActionTypes.workspacesModal.getWorkspaces });
  dispatch(setPopupLoading(true));
  return getWorkspacesCall()
    .then(({ workspaces }) => {
      if (workspaces === true || workspaces.status >= 400) {
        throw WORKSPACES_ERROR;
      }
      dispatch({
        type: MarketoSettingsActionTypes.workspacesModal.getWorkspacesSuccess,
        workspaces,
      });
      dispatch(setPopupLoading(false));
      dispatch({
        fetched: true,
        type: MarketoSettingsActionTypes.workspacesModal.getWorkspacesFetched,
      });
    })
    .catch(() => {
      dispatch({
        type: MarketoSettingsActionTypes.workspacesModal.getWorkspacesFailed,
      });
      dispatch(setPopupLoading(false));
      dispatch(closePopup());
    })
    .finally(() => {
      dispatch({
        fetching: false,
        type: MarketoSettingsActionTypes.workspacesModal.getWorkspacesFetching,
      });
    });
};
