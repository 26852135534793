import ActionTypes from 'web/libs/actionTypes/actionTypes';

const initAddToCampaignCategoryListState = [];
export const addToCampaignCategoryList = (
  state = initAddToCampaignCategoryListState,
  { categories = initAddToCampaignCategoryListState, type }
) => {
  switch (type) {
    case ActionTypes.api.categories.success:
      return categories;
    default:
      return state;
  }
};
