import cloneDeep from 'lodash/cloneDeep';
import EngageActionTypes from '../libs/engageActionTypes';
import {
  addUpdateState,
  createMapAndIndex,
  initMapIndex,
  removeFromState,
} from 'web/reducers/helpers/mapAndIndexHelper';

export function engageEvents(state = initMapIndex, { type = '', leads = [] }) {
  switch (type) {
    case EngageActionTypes.events:
      return createMapAndIndex(leads);
    case EngageActionTypes.updateEngageEvents: {
      let updatedEvents = cloneDeep(state);
      leads.forEach((lead) => {
        const leadId = lead.id;
        const leadExists = updatedEvents.map[leadId];
        lead.events.forEach((event) => {
          event.unseen = true; // eslint-disable-line
        });

        if (leadExists) {
          leadExists.events = lead.events.concat(
            updatedEvents.map[leadId].events
          );
          updatedEvents = removeFromState(updatedEvents, leadId);
          updatedEvents = addUpdateState(updatedEvents, leadExists, 'id', true);
        } else {
          updatedEvents = addUpdateState(updatedEvents, lead, 'id', true);
        }
      });

      return updatedEvents;
    }
    case EngageActionTypes.markEventAsSeen: {
      const updatedEvents = cloneDeep(state);
      const eventId = leads[0].events[0].id;

      for (const updatedEvent of Object.values(updatedEvents.map)) {
        const eventIndex = updatedEvent.events.findIndex(
          (event) => event.id === eventId
        );

        if (eventIndex > -1) {
          updatedEvent.events[eventIndex].unseen = false;
          break;
        }
      }

      return updatedEvents;
    }
    default:
      return state;
  }
}

export function engageEventsExtraData(
  state = [],
  { type = '', extraData = {} }
) {
  switch (type) {
    case EngageActionTypes.engageEventsExtraData: {
      const index = state.findIndex(
        (event) => event.pitchId === extraData.pitchId
      );
      if (index > -1) {
        const eventsExtraData = [...state];
        eventsExtraData[index] = extraData;
        return eventsExtraData;
      }
      return state.concat([extraData]);
    }
    default:
      return state;
  }
}

export function engageInitialLoading(
  state = true,
  { type = '', loading = false }
) {
  switch (type) {
    case EngageActionTypes.initialLoading:
      return loading;
    default:
      return state;
  }
}

export function engageOpenChildrenLoadingIds(state = [], { type = '', id }) {
  switch (type) {
    case EngageActionTypes.removeOpenChildrenId: {
      const index = state.indexOf(id);
      if (index === -1) {
        return state;
      }
      const loadingIds = [...state];
      loadingIds.splice(index, 1);
      return loadingIds;
    }
    case EngageActionTypes.addOpenChildrenId:
      return state.concat([id]);
    default:
      return state;
  }
}
