import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import './salesforceActions.scss';
import { nameFormatter } from 'web/liveFeed/engage/helpers/engageHelper';

const AddClick = {
  contact: 'contact',
  lead: 'lead',
  linkedin: 'linkedin',
  open: 'open',
};

class SalesforceActions extends PureComponent {
  render() {
    const { isLoading, salesforceContactIsLead, salesforceId } = this.props;
    let actionElements = null;

    if (isLoading) {
      actionElements = <LoadingSpinner imageUrl={getImageUrl} />;
    } else {
      actionElements = (
        <div className="salesforce-actions">
          <div className="salesforce-actions-column">
            {salesforceId ? (
              <div className="salesforce-actions-column-add">
                <span className="margin-bottom-half">
                  <FormattedMessage
                    id="web.liveFeed.marketoCard.marketoConvertActions.salesforceContact"
                    values={{ isLead: salesforceContactIsLead }}
                  />
                </span>
                <span onClick={this._onAddClick}>
                  <FormattedHTMLMessage
                    id="web.liveFeed.marketoCard.marketoConvertActions.openSalesforceContact"
                    values={{ open: AddClick.open }}
                  />
                </span>
              </div>
            ) : (
              <div className="salesforce-actions-column-add">
                <span className="margin-bottom-half">
                  <FormattedMessage id="web.liveFeed.marketoCard.marketoConvertActions.noSalesforce" />
                </span>
                <span onClick={this._onAddClick}>
                  <FormattedHTMLMessage
                    id="web.liveFeed.marketoCard.marketoConvertActions.addSalesforceContact"
                    values={{
                      contact: AddClick.contact,
                      lead: AddClick.lead,
                      linkedin: AddClick.linkedin,
                    }}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    return <div className="salesforce-actions-wrapper">{actionElements}</div>;
  }

  _onAddClick = (e) => {
    const {
      bestBet,
      openLinkedInSearch,
      openSalesforceContact,
      salesforceInstanceUrl,
      salesforceId,
    } = this.props;
    switch (e.target.dataset.clickId) {
      case AddClick.linkedin:
        openLinkedInSearch(nameFormatter(bestBet));
        break;
      case AddClick.lead:
      case AddClick.contact:
        openSalesforceContact(
          salesforceInstanceUrl,
          undefined,
          bestBet.personId
        );
        break;
      case AddClick.open:
        openSalesforceContact(salesforceInstanceUrl, salesforceId);
        break;
      default:
        break;
    }
  };
}

SalesforceActions.propTypes = {
  bestBet: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  openLinkedInSearch: PropTypes.func.isRequired,
  openSalesforceContact: PropTypes.func.isRequired,
  salesforceContactIsLead: PropTypes.bool,
  salesforceId: PropTypes.string,
  salesforceInstanceUrl: PropTypes.string.isRequired,
};

SalesforceActions.defaultProps = {
  isLoading: false,
  salesforceId: '',
  salesforceContactIsLead: false,
};

export default SalesforceActions;
