import logger from './rollbar';

class Logger {
  static initialize() {
    logger.init();
    logger.setOfficeDiagnosticInfo();
  }

  static setUser(account) {
    logger.setUser(account);
  }

  static error(message, ...data) {
    logger.error(message, data);
  }
}

export default Logger;
