import { Urls } from 'web/libs/routes';
import { Regex } from 'web/libs/constants';
import { CampaignDetailsEnum } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { templatesCategoryMap } from 'web/templates/helpers/templatesHelper';
import defer from 'lodash/defer';
import { WindowsOwaMode } from 'owa/libs/constants';
import { isWindowsOS, isOwaPluginMode } from 'web/services/windowService';
import { SidebarMenuItems } from 'web/commandCenter/libs/commandCenterConstants';

// Campaigns
export function navigateToCampaignDetailsTab(
  category,
  campaign,
  tab = CampaignDetailsEnum.setup.value,
  selectedPerson = null
) {
  const checkId = category === -1 ? 'all' : category;
  let campaignUrl = Urls.campaignDetailsTab
    .replace(':categoryId', checkId)
    .replace(':campaignId', campaign)
    .replace(':tab', tab);

  if (selectedPerson) {
    campaignUrl += `?selectedPerson=${selectedPerson}`;
  }

  window.location.href = campaignUrl;
}

export function navigateToCampaignCategory(category) {
  const checkId = category === -1 ? 'all' : category;
  window.location.href = Urls.campaignCategoryView.replace(
    ':categoryId',
    checkId
  );
}

export function navigateToCommandCenterMenuItem(selectedMenuItem = 'tasks') {
  window.location.href = Urls.commandCenterSelectedMenuItem.replace(
    ':selectedMenuItem',
    selectedMenuItem
  );
}

export function navigateToCommandCenterEmails() {
  navigateToCommandCenterMenuItem(SidebarMenuItems.emails.urlHash);
}

export function navigateToCampaignsTab() {
  window.location.href = Urls.campaignsTab;
}

export function navigateToEmailsTab(tab) {
  window.location.href = Urls.emailsTab.replace(':tab', tab);
}

export function navigateToEmailsSubTab(tab, subTab, param) {
  window.location.href = Urls.emailsSubTab(tab, subTab, param);
}

export function setSelectedEmailURL(emailId) {
  if (!window.app) return;
  const { app } = window;
  app.router.navigate(Urls.selectedEmail(emailId), { trigger: false });
}

export function changeToLastUrl() {
  if (!window.app) return;
  const { app } = window;

  if (app.router.lastUrl) {
    app.router.navigate(app.router.lastUrl, { trigger: false });
  }
}

// Compose
export function navigateToComposeWindow(
  personId,
  addressId,
  templateId,
  taskId,
  campaignId
) {
  const task = (taskId && `/reminder/${taskId}`) || '';
  const template = (templateId && `/template/${templateId}`) || '';
  const person =
    (personId && addressId && `/person/${personId}/address/${addressId}`) || '';
  const campaign =
    (campaignId && task && template && person && `/campaign/${campaignId}`) ||
    '';

  window.location.href =
    Urls.composeWindow + person + template + task + campaign;
}

export function navigateToComposeDraft(draftId) {
  window.location.href = Urls.composeWindowDraft(draftId);
}

// How+to+Share+a+Group
export function navigateToHowToShareGroup() {
  window.open(
    `${Urls.common.howToShareGroupLearnMore}`,
    '_blank',
    'noopener noreferrer'
  );
}

// LinkedIn
export function navigateToLinkedIn(firstName, lastName) {
  const plus = firstName && lastName ? '+' : '';
  window.open(
    `${Urls.inMailBase}${firstName || ''}${plus}${lastName || ''}`,
    '_blank',
    'noopener noreferrer'
  );
}

// Templates
export function navigateToTemplatesTab(
  template,
  categoryId,
  isEditing = false
) {
  const edit = isEditing ? '/edit' : '';

  if (template) {
    const { category_id: templateCategoryId, id: templateId } = template;
    const routeCategoryId = categoryId || templateCategoryId;

    if (routeCategoryId) {
      window.location.href = `${Urls.templateCategoryAndTemplate
        .replace(':category_id', routeCategoryId)
        .replace(':template_id', templateId)}${edit}`;
    } else {
      window.location.href = `${Urls.templateDetails.replace(
        ':templateId',
        templateId
      )}${edit}`;
    }
  } else {
    window.location.href = `${Urls.templatesTab}`;
  }
}

export function navigateToTemplateCategory(categoryId) {
  const checkId = templatesCategoryMap(categoryId);
  if (checkId) {
    window.location.href = Urls.templateCategory.replace(
      ':category_id',
      checkId
    );
  } else {
    window.location.href = Urls.templatesTab;
  }
}

export function navigateToUrl(url) {
  window.location.href = url;
}

function tryToChangeUrlHashForWindowsOWA(url) {
  return isOwaPluginMode() && isWindowsOS()
    ? url.replace('#', `/${WindowsOwaMode}?`)
    : url;
}

export const openNewTab = (
  href = '',
  checkHasProtocol = false,
  hasOpener = false
) => {
  let url = href;
  if (checkHasProtocol && !href.match(Regex.websiteHttp)) {
    url = `${'http://'}${url}`;
  }
  url = tryToChangeUrlHashForWindowsOWA(url);

  const newTab = window.open(url, '_blank');
  if (!hasOpener) {
    newTab.opener = null;
  }

  return newTab;
};

export function openPopupWindow(url = '', title = '', width = 800, height) {
  const windowHeight = height || window.screen.height * 0.7;
  const hCenter = window.screen.width / 2 - width / 2;
  const vCenter = window.screen.height / 2 - windowHeight / 2;

  return window.open(
    url,
    title,
    `menubar=no,location=no,resizable=yes,scrollbars=no,status=no,width=${width},height=${windowHeight},top=${vCenter},left=${hCenter}`
  );
}

export const openOAuthPopupWindow = (popupKey, { url, title, width, height }) =>
  new Promise((resolve, reject) => {
    openPopupWindow(url, title, width, height);

    try {
      const delay = 1500;
      const timerID = setInterval(() => {
        if (localStorage.getItem(popupKey)) {
          clearTimeout(timerID);
          localStorage.removeItem(popupKey);
          resolve();
        }
      }, delay);
    } catch (error) {
      reject(error);
    }
  });

function sendFormDataToPopupWindow(url, data) {
  const form = document.createElement('FORM');
  form.method = 'POST';
  form.action = url;
  form.target = 'newWindow';
  openPopupWindow('', 'newWindow');
  Object.keys(data).forEach((key) => {
    const element = document.createElement('input');
    element.name = key + (Array.isArray(data[key]) ? '[]' : '');
    element.value = data[key];
    form.appendChild(element);
  });

  document.body.appendChild(form);
  form.submit();
}

export const openAddToCampaign = (data) => {
  if (isOwaPluginMode()) {
    let addToCampaignUrl = `${Urls.addToCampaign}?`;
    addToCampaignUrl += data.people_ids
      .map((id, index) => `${index === 0 ? '' : '&'}people_ids[]=${id}`)
      .join('');
    openNewTab(addToCampaignUrl);
  } else {
    sendFormDataToPopupWindow(Urls.addToCampaign, data);
  }
};

export function navigateToGroupByGroup({ id }) {
  const hash = Urls.peopleGroup.replace(':group_id', id);

  window.location.hash = hash;
}

export function navigateToGroup(groupId) {
  const hash = Urls.peopleGroup.replace(':group_id', groupId);

  window.location.hash = hash;
}

export function navigateToEmailSettingsTab(tab) {
  // simulate browser link click
  defer(() => {
    window.location.hash = Urls.emailSettings.replace(':tab', tab);
  });
}

export function navigateToNewIdentitySlideout() {
  window.location.hash = Urls.emailSettingsNewIdentitySlideout;
}

export function navigateToEditIdentitySlideout(id) {
  window.location.hash = Urls.emailSettingsEditIdentitySlideout.replace(
    ':id',
    id
  );
}

export function navigateToSalesforceTab(tab) {
  // simulate browser link click
  defer(() => {
    window.location.hash = Urls.salesforce(tab);
  });
}

export function navigateToAccountSalesforceTab(tab) {
  defer(() => {
    window.location.hash = Urls.salesforceAccount(tab);
  });
}

export function navigateToAdminSalesforceTab(tab) {
  defer(() => {
    window.location.hash = Urls.salesforceAdmin(tab);
  });
}

export function navigateToAdminTrackingTab(tab) {
  defer(() => {
    window.location.hash = Urls.trackingAdmin(tab);
  });
}

export function navigateToAdminGeneralTab(tab) {
  defer(() => {
    window.location.hash = Urls.settingsGeneral(tab);
  });
}

export function navigateToUserManagementTab(tab) {
  defer(() => {
    window.location.hash = Urls.userManagement(tab);
  });
}

export function navigateToTeamManagementTab(tab) {
  defer(() => {
    window.location.hash = Urls.teamManagement(tab);
  });
}

export function navigateToTeamMembers(teamId) {
  defer(() => {
    window.location.hash = Urls.teamMembers(teamId);
  });
}

export function navigateToEditIdentityDeliverability(id) {
  window.location.hash = Urls.emailSettingsEditIdentityDeliverability.replace(
    ':id',
    id
  );
}

export function navigateToEditUnsubscribedPeopleInGroup(id) {
  window.location.hash = Urls.personDetailsUnsubscribed(id);
}

export function navigateToEditPeopleInGroup(groupId, id) {
  window.location.hash = Urls.personDetailsGroup
    .replace(':group_id', groupId)
    .replace(':person_id', id);
}

export const openNewTabWithSize = (
  href = '',
  width,
  height,
  checkHasProtocol = false
) => {
  let url = href;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;

  if (checkHasProtocol && !href.match(Regex.websiteHttp)) {
    url = `${'http://'}${url}`;
  }
  url = tryToChangeUrlHashForWindowsOWA(url);

  const newTab = window.open(
    url,
    '_blank',
    `width=${width},height=${height},top=${top},left=${left}`
  );
  newTab.opener = null;
  return newTab;
};

export const navigateToLastUrl = () => {
  // added so it will play nicely with the old Backbone routing
  if (!window.app) return;
  const { app } = window;

  const trigger =
    app.router.lastUrl.indexOf(Urls.templatesHashRoute) > -1 ||
    app.router.lastUrl.indexOf(Urls.templateCategoriesHashRoute) > -1 ||
    app.router.lastUrl.indexOf(Urls.emailSettingsHashRoute) > -1 ||
    app.router.lastUrl.indexOf(Urls.commandCenterHashRoute) > -1;

  if (window.location.hash.indexOf(Urls.composeWindowHashRoute) === -1) return;

  if (
    !app.router.lastUrl ||
    app.router.lastUrl === Urls.composeWindowHashRoute
  ) {
    app.router.lastUrl = Urls.commandCenterHashRoute;
  }
  app.router.navigate(app.router.lastUrl, { trigger });
};
