import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Popup from 'components/popups/popup';
import { PopupSize } from 'libs/constantsShared';
import ProgressBar from 'components/progressBar';
import {
  SalesforceCustomizationPopupIds,
  PROGRESS_BAR_HEIGHT,
  CUSTOMIZATION_INSTALLATION_FAILED,
  CUSTOMIZATION_UNINSTALLATION_FAILED,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';

class SalesforceCustomizationPostUpdatePopup extends Component {
  render() {
    const { actionCreators, errors, progress } = this.props;

    const popupObject = {
      children: (
        <div>
          <div className="margin-bottom-full">
            {errors.hasErrors && errors.description ? (
              <FormattedMessage id={this._getErrorMessage()} />
            ) : (
              <FormattedMessage id={this._getContentMessage()} />
            )}
          </div>
          {!errors.hasErrors ? (
            <ProgressBar
              animate={false}
              height={PROGRESS_BAR_HEIGHT}
              progress={progress}
            />
          ) : null}
        </div>
      ),
      footer: {
        onPrimary: actionCreators.closePopup,
        primaryTextId: 'common.ok',
      },
      header: {
        onClose: actionCreators.closePopup,
        textId: this._getTitleMessage(),
      },
      onOutsideClick: actionCreators.closePopup,
      size: PopupSize.small,
    };

    return (
      <Popup {...popupObject} className="salesforce-customization-popup" />
    );
  }

  _getContentMessage() {
    const { errors, popupId } = this.props;
    const isPopupIdUninstall =
      popupId ===
      SalesforceCustomizationPopupIds.salesforceCustomizationPostUninstall;

    let contentMessage = '';

    if (errors.hasErrors) {
      contentMessage =
        'web.salesforceCustomization.popup.postUpdate.error.content';
    } else {
      contentMessage = isPopupIdUninstall
        ? 'web.salesforceCustomization.popup.postUninstall.success.content'
        : 'web.salesforceCustomization.popup.postInstall.success.content';
    }

    return contentMessage;
  }

  _getErrorMessage() {
    const { errors } = this.props;
    let contentMessage = '';

    if (errors.hasErrors && errors.description) {
      if (errors.description === CUSTOMIZATION_INSTALLATION_FAILED) {
        contentMessage =
          'web.salesforceCustomization.popup.postUpdate.error.content';
      } else if (errors.description === CUSTOMIZATION_UNINSTALLATION_FAILED) {
        contentMessage =
          'web.salesforceCustomization.popup.postUninstall.error.content';
      } else {
        contentMessage = 'web.salesforceCustomization.defaultError.api';
      }
    }

    return contentMessage;
  }

  _getTitleMessage() {
    const { popupId } = this.props;

    switch (popupId) {
      case SalesforceCustomizationPopupIds.salesforceCustomizationPostUninstall:
        return 'web.salesforceCustomization.popup.uninstallation.title';
      case SalesforceCustomizationPopupIds.salesforceCustomizationPostInstall:
      default:
        return 'web.salesforceCustomization.popup.installation.title';
    }
  }
}

SalesforceCustomizationPostUpdatePopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  popupId: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};

export default SalesforceCustomizationPostUpdatePopup;
