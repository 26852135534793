import React from 'react';
import { FormattedMessage } from 'react-intl';
import './addContactSlideOutHeader.scss';

export default () => (
  <div className="person-add-header text-eclipse-overflow">
    <h3 className="text-overflow margin-bottom-quarter">
      <FormattedMessage id="web.people.createContact.header" />
    </h3>
  </div>
);
