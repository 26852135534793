import React from 'react';
import PropTypes from 'prop-types';
import PageTabNav from 'components/layouts/page/tabNav';
import Icon, { IconColors } from 'components/buttons/icon/icon';
import './composeTemplatesPinnedTabs.scss';

const ComposeTemplatesPinnedTabs = ({
  pinnedTabs,
  onTabSelect,
  selectedTabValue,
  openEditModal,
}) => (
  <div className="compose-pinned-tabs">
    <PageTabNav
      list={pinnedTabs}
      onTabSelect={onTabSelect}
      selectedTabValue={`${selectedTabValue}`}
    />
    <Icon
      className="tout-icon-plus"
      color={IconColors.default}
      onClick={openEditModal}
    />
  </div>
);

ComposeTemplatesPinnedTabs.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  pinnedTabs: PropTypes.array.isRequired,
  selectedTabValue: PropTypes.number,
};

ComposeTemplatesPinnedTabs.defaultProps = {
  selectedTabValue: null,
};

export default ComposeTemplatesPinnedTabs;
