import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import OnboardingStepSuccess from 'web/onboarding/components/onboardingStepSuccess/onboardingStepSuccess';
import EmailConnectionStep from './emailConnectionStep';
import { getAuthIntegrations } from 'web/settings/emailConnection/actionCreators/emailConnectionActionCreators';
import { hasTriedToConnect } from 'web/settings/emailConnection/helpers/emailConnectionHelpers';
import { getIntegrationsHealthStatuses } from 'web/settings/myProfile/components/yourIntegrations/selectors/yourIntegrationsSelectors';
import { getSuccessStepSetupTextId } from 'web/onboarding/helpers/onboardingHelpers';
import { updateOnboardingSettings } from 'web/user/actionCreators/userActionCreators';
import { stepsNames } from 'web/onboarding/lib/stepsRouting';
import './emailConnection.scss';

const EmailConnection = ({
  loading,
  getAuthIntegrations,
  statuses,
  updateOnboardingSettings,
}) => {
  const [successSetup, setSuccessSetup] = useState(false);
  useEffect(() => {
    if (!hasTriedToConnect()) {
      getAuthIntegrations().then((response) => {
        if (response.hasValidCredentials) {
          updateOnboardingSettings(stepsNames.emailConnection);
          setSuccessSetup(true);
        }
      });
    }
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <div className="onboarding-spinner-wrapper">
          <LoadingSpinner imageUrl={getImageUrl} size={130} />
        </div>
      ) : null}
      {!loading && successSetup ? (
        <OnboardingStepSuccess
          successStepSetupTextId={getSuccessStepSetupTextId(statuses)}
        />
      ) : null}
      {!loading && !successSetup ? (
        <div className="onboarding-email-connection">
          <EmailConnectionStep />
        </div>
      ) : null}
    </React.Fragment>
  );
};

EmailConnection.propTypes = {
  getAuthIntegrations: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  statuses: PropTypes.object,
  updateOnboardingSettings: PropTypes.func.isRequired,
};

EmailConnection.defaultProps = {
  loading: false,
  statuses: {},
};

const mapStateToProps = (state) => ({
  activeStep: state.onboarding.onboardingActiveStep,
  loading:
    state.emailLoadingStates &&
    state.emailLoadingStates.emailConnectionFetching &&
    !state.emailConnectionAuthFetched,
  statuses: getIntegrationsHealthStatuses(state),
});

const mapDispatchToProps = {
  getAuthIntegrations,
  openPopup,
  updateOnboardingSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConnection);
