import { createReducer } from '@reduxjs/toolkit';
import ActionTypes from 'web/onboarding/lib/onboardingActionTypes';
import {
  setRemindCompleteOnboarding,
  setProductTourActiveTabName,
} from 'web/onboarding/actionCreators/onboardingActionCreators';

export const skippedStepsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.setSkippedStep:
      return [...state, action.skippedStep];
    case ActionTypes.removeSkippedStep:
      return state.filter(
        (skippedStep) => skippedStep !== action.unskippedStep
      );
    default:
      return state;
  }
};

export const remindCompleteOnboarding = createReducer(true, {
  [setRemindCompleteOnboarding.type]: (state, action) => action.payload,
});

export const productTourActiveTabName = createReducer('', {
  [setProductTourActiveTabName.type]: (state, action) => action.payload,
});
