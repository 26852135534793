import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import EmailConnectionNotConnected from 'web/settings/emailConnection/components/emailConnectionNotConnected/emailConnectionNotConnected';
import EmailConnectionConnected from 'web/settings/emailConnection/components/emailConnectionConnected/emailConnectionConnected';
import './emailConnectionCard.scss';

class EmailConnectionCard extends Component {
  render() {
    const {
      emailLoadingStates: { emailConnectionFetching },
    } = this.props;

    return (
      <div className="email-connection-card">
        {emailConnectionFetching ? (
          <div className="card-spinner">
            <LoadingSpinner imageUrl={getImageUrl} size={100} />
          </div>
        ) : (
          this.displayCard()
        )}
      </div>
    );
  }

  displayCard = () => {
    const {
      emailConnectionAuth: { 'validCredentials?': validCredentials },
    } = this.props;

    return validCredentials ? (
      <EmailConnectionConnected {...this.props} />
    ) : (
      <EmailConnectionNotConnected {...this.props} />
    );
  };

  static propTypes = {
    emailConnectionAuth: PropTypes.object.isRequired,
    emailLoadingStates: PropTypes.object.isRequired,
  };
}

export default EmailConnectionCard;
