import React from 'react';
import PropTypes from 'prop-types';
import CommentPost from '../post';
import CommentContent from '../content';

const CommentBody = ({ comment, initHeight, isEditing, onCancel, onDone }) => {
  if (isEditing) {
    return (
      <CommentPost
        autoFocus
        defaultText={comment}
        isEditing={isEditing}
        onCancel={onCancel}
        onDone={onDone}
      />
    );
  }
  return (
    <CommentContent content={comment} height={initHeight} useParentProps />
  );
};

CommentBody.propTypes = {
  comment: PropTypes.string,
  initHeight: PropTypes.number,
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

CommentBody.defaultProps = {
  comment: '',
  initHeight: undefined,
  isEditing: false,
};

export default CommentBody;
