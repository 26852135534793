import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import {
  CallOutcomes,
  PersonDetailsCards,
} from 'web/person/libs/personDetailsConstants';
import Collapse, { Timeouts } from 'components/collapse';
import OptionDotaInfobox from 'components/optionDotas/infobox';
import { isAdmin } from 'web/shared/services/accountService';
import './personDetailsCall.scss';

class PersonDetailsCall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callsData: null,
      isOpen: false,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.closedNotesTitle = formatMessage({
      id: 'web.person.personDetails.callsCard.call.closedNotesTitle',
    });
    this.openNotesTitle = formatMessage({
      id: 'web.person.personDetails.callsCard.call.openNotesTitle',
    });
  }

  render() {
    const {
      createdAt,
      notes,
      userEmail,
      userName,
      id,
      removeCallPopup,
      intl: { formatMessage },
    } = this.props;
    const { isOpen } = this.state;
    const hasNotes = notes.length > 0;
    const clickAttr = hasNotes
      ? {
          onClick: this._toggleOpen,
        }
      : {};

    return (
      <React.Fragment>
        <div className="person-details-call">
          <div
            className={classNames('person-details-call-data flex flex--full', {
              'has-notes': hasNotes,
            })}
            {...clickAttr}
          >
            <div className="column-datetime">
              <FormattedMessage
                id="common.datetimeFormatShort"
                values={{ datetime: createdAt }}
              />
            </div>
            <div className="column-name text-overflow">
              {userName ||
                userEmail || <FormattedMessage id="common.unknown" />}
            </div>
            <div
              className={classNames('column-notes tout-icon-notes', {
                filled: hasNotes,
              })}
              title={(isOpen && this.openNotesTitle) || this.closedNotesTitle}
            />
            {this._getRecordingUrl()}
            <div className="column-duration text-right">
              <FormattedMessage
                id="web.person.personDetails.callsCard.call.duration"
                values={this._getDurationValues()}
              />
            </div>
            <div className="column-outcome text-overflow text-right">
              {this._getOutcomeText()}
            </div>
            <div className="column-call-action">
              {isAdmin() && (
                <OptionDotaInfobox
                  options={[
                    {
                      id: id,
                      label: formatMessage({
                        id: 'common.remove',
                      }),
                      onClick: (id) => removeCallPopup(id),
                      value: id,
                    },
                  ]}
                />
              )}
            </div>
          </div>
          <Collapse
            in={isOpen}
            timeoutEnter={Timeouts.twoFifty}
            timeoutExit={Timeouts.twoFifty}
          >
            <div
              className="text-wrap call-notes margin-bottom-half"
              {...clickAttr}
            >
              {notes}
            </div>
          </Collapse>
        </div>
      </React.Fragment>
    );
  }

  _getOutcomeText = () => {
    const {
      outcome,
      intl: { formatMessage },
    } = this.props;

    const callOutcome = outcome.toLowerCase();

    switch (callOutcome) {
      case CallOutcomes.success:
        return (
          <span
            className="text-green"
            title={formatMessage({
              id: 'common.success',
            })}
          >
            <FormattedMessage id="common.success" />
          </span>
        );
      case CallOutcomes.voicemail:
        return (
          <span
            title={formatMessage({
              id: 'common.voicemail',
            })}
          >
            <FormattedMessage id="common.voicemail" />
          </span>
        );
      case CallOutcomes.noVoicemail:
        return (
          <span
            title={formatMessage({
              id: 'web.person.personDetails.callsCard.call.noVoicemail',
            })}
          >
            <FormattedMessage id="web.person.personDetails.callsCard.call.noVoicemail" />
          </span>
        );
      case CallOutcomes.connected:
        return (
          <span
            title={formatMessage({
              id: 'common.connected',
            })}
          >
            <FormattedMessage id="common.connected" />
          </span>
        );
      case CallOutcomes.none:
        return (
          <span
            title={formatMessage({
              id: 'common.none',
            })}
          >
            <FormattedMessage id="common.none" />
          </span>
        );
      case CallOutcomes.call:
        return (
          <span
            title={formatMessage({
              id: 'common.call',
            })}
          >
            <FormattedMessage id="common.call" />
          </span>
        );
      default:
        return (
          <span title={outcome}>{outcome}</span> // SF passes through outcomes
        );
    }
  };

  _getRecordingUrl = () => {
    const {
      callRecordingEnabled,
      recordingUrl,
      intl: { formatMessage },
    } = this.props;

    if (callRecordingEnabled && recordingUrl) {
      return (
        <a
          className="column-recording tout-icon-phone filled"
          href={recordingUrl}
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank"
          title={formatMessage({
            id: 'web.person.personDetails.callsCard.call.closedCallRecording',
          })}
        />
      );
    } else if (callRecordingEnabled) {
      return (
        <div
          className="column-recording tout-icon-phone"
          title={formatMessage({
            id: 'web.person.personDetails.callsCard.call.closedCallRecording',
          })}
        />
      );
    } else {
      return null;
    }
  };

  _getDurationValues = () => {
    const { duration } = this.props;
    return {
      minutes: `${Math.floor(duration / 60)}`.padStart(2, '0'),
      seconds: `${duration % 60}`.padStart(2, '0'),
    };
  };

  _toggleOpen = (e) => {
    if (e.target.tagName !== 'A') {
      e.preventDefault();
      e.stopPropagation();

      const { isOpen } = this.state;

      this.setState({ isOpen: !isOpen });

      if (!isOpen) {
        // only on open
        this.props.openItemClick(PersonDetailsCards.calls);
      }
    }
  };
}

PersonDetailsCall.propTypes = {
  callRecordingEnabled: PropTypes.bool,
  createdAt: PropTypes.object.isRequired,
  duration: PropTypes.number,
  id: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  notes: PropTypes.string,
  openItemClick: PropTypes.func.isRequired,
  outcome: PropTypes.string,
  recordingUrl: PropTypes.string,
  userEmail: PropTypes.string,
  userName: PropTypes.string,
};

PersonDetailsCall.defaultProps = {
  callRecordingEnabled: false,
  duration: 0,
  notes: '',
  outcome: CallOutcomes.none,
  recordingUrl: '',
  userEmail: '',
  userName: '',
};

export default injectIntl(PersonDetailsCall);
