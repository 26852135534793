import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';
import SlideOutsActionTypes from 'web/slideOuts/libs/slideOutsActionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import {
  AddressLocations,
  AddressTypes,
  PersonDetailsCards,
  ApiKeys,
} from '../libs/personDetailsConstants';
import AddContactSlideOutActionTypes from 'web/slideOuts/addContact/libs/addContactSlideOutActionTypes';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const initAddress = {
  location: AddressLocations.work,
  type: AddressTypes.email,
  value: '',
};
export function personDetailsInfoEditState(
  state = EMPTY_OBJECT,
  {
    /*eslint complexity:0*/
    type = '',
    addressType = AddressTypes.email,
    keyType = '',
    cardId = '',
    edits = EMPTY_OBJECT,
    editState = EMPTY_OBJECT,
    index = -1,
    primaryIndex = -1,
    value = '',
  }
) {
  switch (type) {
    case PersonDetailsActionTypes.openCardEditing:
      return (cardId === PersonDetailsCards.info && editState) || state;
    case PersonDetailsActionTypes.updateInfoEditStateAddresses: {
      const address = state[addressType].addresses[index];
      const addresses = [
        ...state[addressType].addresses.slice(0, index),
        {
          ...address,
          location: edits.location || address.location,
          value: typeof edits.value === 'string' ? edits.value : address.value,
        },
        ...state[addressType].addresses.slice(index + 1),
      ];
      return { ...state, [addressType]: { ...state[addressType], addresses } };
    }
    case PersonDetailsActionTypes.updateInfoEditStateContact: {
      return { ...state, ...edits };
    }
    case PersonDetailsActionTypes.updateInfoEditStateSetPrimary: {
      return {
        ...state,
        [addressType]: { ...state[addressType], primaryIndex },
      };
    }
    case PersonDetailsActionTypes.updateInfoEditStateSocial: {
      return { ...state, [addressType]: { ...state[addressType], value } };
    }
    case PersonDetailsActionTypes.updateInfoEditApiKeys: {
      const externalReference = state[ApiKeys.externalReferences][index];

      const externalReferences = [
        ...state[ApiKeys.externalReferences].slice(0, index),
        {
          ...externalReference,
          service_entity_id: value,
          service: keyType,
        },
        ...state[ApiKeys.externalReferences].slice(index + 1),
      ];
      return { ...state, [ApiKeys.externalReferences]: externalReferences };
    }
    case PersonDetailsActionTypes.addInfoRow:
      return {
        ...state,
        [addressType]: {
          ...state[addressType],
          addresses: state[addressType].addresses.concat({
            ...initAddress,
            type: addressType,
          }),
        },
      };
    case PersonDetailsActionTypes.removeInfoRow: {
      const { addresses } = state[addressType];
      return {
        ...state,
        [addressType]: {
          ...state[addressType],
          addresses: [
            ...addresses.slice(0, index),
            ...addresses.slice(index + 1),
          ],
        },
      };
    }
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.close:
    case PersonDetailsActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
}

export function personDetailsSalesforceRequiredFields(
  state = EMPTY_OBJECT,
  { type = '', requiredFields = EMPTY_OBJECT }
) {
  switch (type) {
    case PersonDetailsActionTypes.setSalesforceRequiredFields:
      return requiredFields;
    default:
      return state;
  }
}

export function personDetailsGroupsEditState(
  state = EMPTY_OBJECT,
  {
    addresses = EMPTY_ARRAY,
    type = '',
    cardId = '',
    edits = EMPTY_OBJECT,
    editState = EMPTY_OBJECT,
    group = EMPTY_OBJECT,
    index = -1,
    selectedEmailValue = '',
  }
) {
  switch (type) {
    case PersonDetailsActionTypes.openCardEditing:
      return (cardId === PersonDetailsCards.groups && editState) || state;
    case PersonDetailsActionTypes.setGroupEditState:
      return {
        ...state,
        groupsByAddressId: {
          ...state.groupsByAddressId,
          [selectedEmailValue]: [group],
        },
      };
    case PersonDetailsActionTypes.updateGroupsEditState:
      return { ...state, ...edits };
    case PersonDetailsActionTypes.setAddressesEditState:
      return { ...state, addresses };
    case PersonDetailsActionTypes.updateGroupsEditStateAdd: {
      const selectedEmail = selectedEmailValue || 'new';
      const groupsBySelectedEmail =
        state.groupsByAddressId[selectedEmail] || [];
      return {
        ...state,
        selectedEmailValue: selectedEmail,
        groupsByAddressId: {
          ...state.groupsByAddressId,
          [selectedEmail]: [...groupsBySelectedEmail, group],
        },
      };
    }
    case PersonDetailsActionTypes.updateGroupsEditStateRemove: {
      const selectedGroups = state.groupsByAddressId[selectedEmailValue];
      return {
        ...state,
        groupsByAddressId: {
          ...state.groupsByAddressId,
          [selectedEmailValue]: [
            ...selectedGroups.slice(0, index),
            ...selectedGroups.slice(index + 1),
          ],
        },
      };
    }
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.close:
    case PersonDetailsActionTypes.setTab:
    case SlideOutsActionTypes.close:
    case PersonDetailsActionTypes.updateGroupsEditStateRemoveAll:
      return EMPTY_OBJECT;
    case PersonDetailsActionTypes.closeCardEditing:
      return cardId === PersonDetailsCards.groups ? EMPTY_OBJECT : state;
    default:
      return state;
  }
}

export function personDetailsAccountInfoEditState(
  state = EMPTY_OBJECT,
  { type = '', cardId = '', salesforceType = '' }
) {
  switch (type) {
    case PersonDetailsActionTypes.openCardEditing:
      return (
        (cardId === PersonDetailsCards.accountInfo && { salesforceType }) ||
        state
      );
    case PersonDetailsActionTypes.updatesalesforceType:
      return { salesforceType };
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.close:
    case PersonDetailsActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
}

export const initCustomField = { name: '', value: '' };
export function personDetailsCustomFieldsEditState(
  state = EMPTY_ARRAY,
  {
    type = '',
    cardId = '',
    edits = EMPTY_OBJECT,
    editState = EMPTY_OBJECT,
    index = -1,
  }
) {
  switch (type) {
    case PersonDetailsActionTypes.openCardEditing:
      return (cardId === PersonDetailsCards.customFields && editState) || state;
    case PersonDetailsActionTypes.updateCustomFieldsEditState:
      return [
        ...state.slice(0, index),
        {
          name: typeof edits.name === 'string' ? edits.name : state[index].name,
          value:
            typeof edits.value === 'string' ? edits.value : state[index].value,
          id: edits.id || state[index].id,
        },
        ...state.slice(index + 1),
      ];
    case PersonDetailsActionTypes.addCustomFieldsRow:
      return [...state, { ...initCustomField, id: state.length }];
    case PersonDetailsActionTypes.removeCustomFieldsRow: {
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.close:
    case PersonDetailsActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return EMPTY_ARRAY;
    case PersonDetailsActionTypes.closeCardEditing:
      return cardId === PersonDetailsCards.customFields ? EMPTY_ARRAY : state;
    default:
      return state;
  }
}

export function personDetailsEditing(
  state = EMPTY_OBJECT,
  { type = '', cardId = '' }
) {
  switch (type) {
    case PersonDetailsActionTypes.openCardEditing:
      return (cardId && { ...state, [cardId]: true }) || state;
    case PersonDetailsActionTypes.closeCardEditing: {
      const newState = { ...state };
      Reflect.deleteProperty(newState, cardId);
      return newState;
    }
    case PersonDetailsActionTypes.closeCardEditingAll:
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.close:
    case AddContactSlideOutActionTypes.close:
    case PersonDetailsActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
}

export function personDetailsOriginalEditState(
  state = EMPTY_OBJECT,
  { type = '', cardId = '', editState = EMPTY_OBJECT }
) {
  switch (type) {
    case PersonDetailsActionTypes.openCardEditing:
      return (cardId && { ...state, [cardId]: editState }) || state;
    case PersonDetailsActionTypes.closeCardEditing: {
      const copy = { ...state };
      Reflect.deleteProperty(copy, cardId);
      return copy;
    }
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.close:
    case PersonDetailsActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
}

export function personDetailsUnsubscribeReason(state = '', { type, payload }) {
  switch (type) {
    case PersonDetailsActionTypes.updateUnsubscribeReason:
      return payload;

    case PopupActionTypes.close:
      return '';

    default:
      return state;
  }
}

export const personDetailsComplianceEditState = (
  state = EMPTY_OBJECT,
  {
    type = '',
    cardId = '',
    edits = EMPTY_OBJECT,
    editState = EMPTY_OBJECT,
    consentReason = '',
  }
) => {
  switch (type) {
    case PersonDetailsActionTypes.openCardEditing:
      return (cardId === PersonDetailsCards.compliance && editState) || state;
    case PersonDetailsActionTypes.updateAuthorizationComplianceEditState:
      return { ...state, consentReason };
    case PersonDetailsActionTypes.updateComplianceEditState:
      return { ...state, ...edits };
    default:
      return state;
  }
};
