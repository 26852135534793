import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from 'components/buttons/checkbox';
import './person.scss';

class Person extends Component {
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;
    this.noName = formatMessage({ id: 'common.noName' });
  }

  render() {
    const {
      groupName,
      isChecked,
      onCheckChange,
      person: { id, name },
    } = this.props;
    const personName = name || this.noName;

    return (
      <Checkbox
        checked={isChecked}
        classes={groupName ? 'person nested' : 'person'}
        key={`person-search-add-${groupName || ''}-${id}`}
        label={personName}
        name={id}
        onChange={onCheckChange}
      />
    );
  }
}

Person.propTypes = {
  groupName: PropTypes.string,
  intl: intlShape.isRequired,
  isChecked: PropTypes.bool,
  onCheckChange: PropTypes.func.isRequired,
  person: PropTypes.object.isRequired,
};

export default injectIntl(Person);
