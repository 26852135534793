import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import UnsubscribeForm from '../unsubscribeForm';
import UnsubscribeFormSuccessMessage from '../unsubscribeFormSuccessMessage';
import UnsubscribeFormAlert from '../../containers/unsubscribeFormAlertContainer';
import LoadingSpinner from 'components/loadingSpinner';
import './unsubscribeFormHolder.scss';

function UnsubscribeFormHolder({
  isHiddenForm,
  fetchingUnsubscribeOptions,
  unsubscribedFrom,
  ...others
}) {
  return (
    <div className="unsubscribe-form-holder">
      {fetchingUnsubscribeOptions && <LoadingSpinner imageUrl={getImageUrl} />}
      <UnsubscribeFormAlert />
      {!isHiddenForm ? (
        <UnsubscribeForm {...others} />
      ) : (
        <UnsubscribeFormSuccessMessage unsubscribedFrom={unsubscribedFrom} />
      )}
    </div>
  );
}

UnsubscribeFormHolder.defaultProps = {
  unsubscribedFrom: null,
  unsubscribeOtherReason: '',
  unsubscribeReasonsSelected: 'default',
};

UnsubscribeFormHolder.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchingUnsubscribeOptions: PropTypes.bool.isRequired,
  isHiddenForm: PropTypes.bool.isRequired,
  unsubscribedFrom: PropTypes.string,
  unsubscribeOtherReason: PropTypes.string,
  unsubscribeReasons: PropTypes.array.isRequired,
  unsubscribeReasonsSelected: PropTypes.string,
};

export default UnsubscribeFormHolder;
