import { createSelector } from 'reselect';
import differenceBy from 'lodash/differenceBy';
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import I18N from 'languages';
import { isTemplateFavorite } from 'web/templates/services/templateService';
import {
  TEMPLATE_SPECIAL_CATEGORY,
  TEMPLATE_TOP_VIEWABLE_CATEGORIES,
  TEMPLATE_NON_UPDATABLE_CATEGORIES,
  SPECIAL_FILTER_CATEGORIES,
  TemplatesSearchDataTypes,
} from 'web/templates/libs/templatesConstants';
import { TEMPLATES_CATEGORY_MAP } from 'web/templates/helpers/templatesHelper';
import { isTemplatesEditingDisabled } from 'web/settings/adminSettings/general/selectors/generalPageSelectors';
import { isAdmin } from 'web/user/selectors/userSelectors';

const getSelectedTemplateCategoryId = (state) =>
  state.templatesSelectedTemplateCategoryId;
const getSharableCategories = (state) => state.templatesSharableCategories;
const getTemplatesCategories = (state) => state.templatesCategories;
const getTemplatesData = (state) => state.templatesGridData;
const getTemplatesSidebarSearchData = (state) => state.templatesSearchData;
const getTemplatesSearchLoading = (state) => state.templatesSearchLoading;
const getTemplatesViewer = (state) => state.templatesViewer;
const getUser = (state) => state.user;
const getViewer = (state) => state.templatesViewer;

const getSortedCategories = createSelector(
  [getTemplatesCategories],
  (templatesCategories = []) =>
    sortBy(
      templatesCategories,
      (category) => category.name && category.name.toLowerCase()
    )
);

export const getTemplatesSearchData = createSelector(
  [
    getSortedCategories,
    getTemplatesSidebarSearchData,
    getTemplatesSearchLoading,
  ],
  (templatesCategories, templatesSearchData, templatesSearchLoading) => [
    {
      collection: templatesCategories,
      name: TemplatesSearchDataTypes.categories.name,
      type: TemplatesSearchDataTypes.categories.type,
    },
    {
      collection: templatesSearchData,
      isLoading: templatesSearchLoading,
      name: TemplatesSearchDataTypes.templates.name,
      type: TemplatesSearchDataTypes.templates.type,
    },
  ]
);

const getSidebarSearchQuery = (state) => state.sidebarSearchQuery;

export const templatesSidebarSearchResults = (searchData, query) => {
  let filteredResults = [];
  if (query) {
    filteredResults = searchData.reduce((acc, searchItem) => {
      const collection = searchItem.collection.filter(
        ({ name }) => name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );

      return collection.length || searchItem.isLoading
        ? acc.concat([
            {
              collection,
              isLoading: searchItem.isLoading,
              name: searchItem.name,
              type: searchItem.type,
            },
          ])
        : acc;
    }, []);
  }
  return filteredResults;
};

export const getTemplatesSidebarSearchResults = createSelector(
  [getTemplatesSearchData, getSidebarSearchQuery],
  templatesSidebarSearchResults
);

export const getViewableCategories = createSelector(
  [getSortedCategories],
  (sortedCategories = []) => [
    ...TEMPLATE_TOP_VIEWABLE_CATEGORIES,
    ...sortedCategories,
  ]
);

const getUnsortedUpdatableCategories = createSelector(
  [getTemplatesCategories, getSharableCategories],
  (sortedCategories, sharableCategories) => {
    const allCategories = unionBy(sortedCategories, sharableCategories, 'id');
    const exludedCategories = TEMPLATE_NON_UPDATABLE_CATEGORIES.map((cat) => ({
      name: cat.apiName,
    }));
    return differenceBy(allCategories, exludedCategories, 'name');
  }
);

export const getUpdatableCategories = createSelector(
  [getUnsortedUpdatableCategories],
  (unsortedCategories = []) =>
    sortBy(
      unsortedCategories,
      (category) => category.name && category.name.toLowerCase()
    )
);

export const getUpdatableCategoriesForUser = createSelector(
  [getUser, getUpdatableCategories],
  (user, updatebleCategories = []) =>
    sortBy(
      updatebleCategories,
      (updatebleCategory) =>
        updatebleCategory.name && updatebleCategory.name.toLowerCase()
    )
);

const isActionDisabled = (template, { admin, id }) =>
  !admin && template.user_id !== id;

export const getTemplatesGridData = createSelector(
  [getTemplatesData, getUser, getViewer],
  (templatesData, user, viewer) =>
    templatesData.map((template) => ({
      ...template,
      favorite: isTemplateFavorite(template, viewer),
      isSelectedDisabled: isActionDisabled(template, user),
    }))
);

export const getCurrentCategoryName = createSelector(
  [getSortedCategories, getSelectedTemplateCategoryId],
  (sortedCategories, templatesSelectedTemplateCategoryId) => {
    const specialCategoryName =
      TEMPLATES_CATEGORY_MAP[templatesSelectedTemplateCategoryId];
    if (specialCategoryName) {
      return TEMPLATE_SPECIAL_CATEGORY[specialCategoryName].name;
    }

    const { name = '' } =
      sortedCategories.find(
        ({ id }) => id === templatesSelectedTemplateCategoryId
      ) || {};
    return name;
  }
);

export const getFormattedFilterItems = createSelector(
  [getUser],
  ({ id, admin, subscription: { users = [] } = {} } = {}) => {
    const userIndex = users.findIndex(
      ({ id: currentUserId }) => currentUserId === id
    );
    const currentUser = users[userIndex];
    const sortableUsers = [
      ...users.slice(0, userIndex),
      ...users.slice(userIndex + 1),
    ];

    const sortedUsers = sortBy(
      sortableUsers,
      ({ name }) => name && name.toLowerCase()
    );

    const allUsers = currentUser ? [currentUser, ...sortedUsers] : sortedUsers;

    if (admin) {
      allUsers.unshift({
        id: SPECIAL_FILTER_CATEGORIES.ALL_USERS,
        name: I18N.getStr('web.templates.allUsersFilterLabel'),
      });
    }

    return allUsers.map(({ id: value, name: label, email }) => ({
      label: label || email,
      value,
    }));
  }
);

export const getTemplateViewerForApi = createSelector(
  [getTemplatesViewer],
  ({ id, type } = {}) => ({
    viewer_id: id,
    viewer_type: type,
  })
);

export const isDisabledForViewer = createSelector(
  [getTemplatesViewer, getUser],
  ({ id: viewerId } = {}, { id: userId } = {}) =>
    viewerId != null && viewerId !== userId
);

export const getArchivedCategoryId = createSelector(
  [getTemplatesCategories],
  (categories) => {
    const category = categories.find(
      (cat) => cat.name === TEMPLATE_SPECIAL_CATEGORY.archived.apiName
    ) || { id: TEMPLATE_SPECIAL_CATEGORY.archived.id };
    return category.id;
  }
);

export const getUserCanEditTemplates = createSelector(
  [isTemplatesEditingDisabled, isAdmin],
  (templatesEditingDisabled, isAdmin) => isAdmin || !templatesEditingDisabled
);
