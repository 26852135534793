/* global Promise */
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { getCampaigns as getCampaignsCall } from 'web/modals/addToCampaign/services/api';
import { getCampaignsCategories } from './campaignCategoryActionCreators';
import {
  getFilteredMarketoCampaigns,
  getFilteredCampaigns,
} from 'web/modals/addToCampaign/selectors/addToCampaignSelectors';
import { track } from 'web/services/mixpanelService';
import {
  CampaignActionEvents,
  CampaignProperties,
} from 'web/libs/mixpanelEvents';

export const setCampaign = (campaign) => ({
  campaign,
  type: ActionTypes.addToCampaignModal.state.campaign,
});

export function setCampaignById(id, campaigns = []) {
  return (dispatch) => {
    const intId = parseInt(id, 10);
    const campaign = campaigns.find(
      ({ id: campaignId }) => campaignId === intId
    );

    if (campaign) {
      dispatch(setCampaign(campaign));
    }
  };
}

export const setMarketoCampaignById = (id) => (dispatch, getState) => {
  track(CampaignActionEvents.addToCampaignAction, {
    'Action Type':
      CampaignProperties.addToCampaignActionTypes.selectMarketoCampaign,
  });
  const marketoCampaigns = getFilteredMarketoCampaigns(getState());
  dispatch(setCampaignById(id, marketoCampaigns));
};

export const setToutCampaignById = (id) => (dispatch, getState) => {
  track(CampaignActionEvents.addToCampaignAction, {
    'Action Type':
      CampaignProperties.addToCampaignActionTypes.selectToutCampaign,
  });

  const toutCampaigns = getFilteredCampaigns(getState());
  dispatch(setCampaignById(id, toutCampaigns));
};

export const setCampaigns = (data) => ({
  data,
  type: ActionTypes.api.campaigns.success,
});

export const campaignForFilter = (campaigns = [], campaign) => {
  if (campaign) {
    return campaign;
  } else if (campaigns.length) {
    return campaigns[0];
  } else {
    return {};
  }
};

export const setCampaignsFailure = () => ({
  type: ActionTypes.api.campaigns.failure,
});

export function getCampaigns() {
  return (dispatch) =>
    getCampaignsCall()
      .then((data) => {
        dispatch(setCampaigns(data));
      })
      .catch(() => {
        dispatch(setCampaignsFailure());
      });
}

export const initCampaignsAndCategories = (initCampaignId) => (dispatch) =>
  Promise.all([dispatch(getCampaigns()), dispatch(getCampaignsCategories())])
    .then(() => {
      if (initCampaignId) {
        dispatch(setToutCampaignById(initCampaignId));
      }
    })
    .catch(() => {
      dispatch(setCampaignsFailure());
    });
