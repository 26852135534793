import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import MarketoAdminPopupIds from 'web/marketoSettings/libs/marketoAdminPopupIds';
import { PopupSize } from 'libs/constantsShared';
import {
  bulkGrant,
  bulkRevoke,
} from 'web/marketoSettings/actionCreators/adminTeamAccessActionCreators';
import { getImageUrl } from 'web/libs/constants';
import { clearPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import { connect } from 'react-redux';
import { getAlert } from 'web/templates/services/templatePopupAlertService';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import bind from 'memoize-bind';
import * as adminPopupActionCreators from '../actionCreators/adminPopupActionCreators';
import AdminWorkspacesModal from '../components/adminWorkspacesModal';

const EMPTY_POPUP = {};

class MarketoAdminPopupContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;
    this.actionCreators = bindActionCreators(
      {
        bulkGrant,
        clearPopupAlert,
        bulkRevoke,
        ...adminPopupActionCreators,
      },
      dispatch
    );
  }

  render() {
    const { children, popup } = this.props;
    let object = EMPTY_POPUP;
    if (popup) {
      object = this._getGlobalPopupObject();
    }

    return (
      <div>
        {object.children ? <Popup {...object} /> : null}
        {children}
      </div>
    );
  }

  _getGlobalPopupObject() {
    const { popupLoading } = this.props;
    return {
      loading: popupLoading,
      getImageUrl,
      ...this._getMainComponent(),
      alert: getAlert(this.props, this.actionCreators),
    };
  }

  _getMainComponent() {
    const { popup, selectedWorkspaces, workspaces } = this.props;
    switch (popup) {
      case MarketoAdminPopupIds.grantAccess: {
        const {
          popupData: { users, callback: onSuccess },
        } = this.props;
        const userIds = users.map((user) => user.id);
        return {
          children: (
            <AdminWorkspacesModal
              actionCreators={this.actionCreators}
              selectedWorkspaces={selectedWorkspaces}
              workspaces={workspaces}
              count={users.length || 1}
            />
          ),
          footer: {
            onPrimary: bind(
              this.actionCreators.bulkGrant,
              this,
              userIds,
              onSuccess
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.connect',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'common.connect',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case MarketoAdminPopupIds.revokeAccess: {
        const {
          popupData: { ids, callback: onSuccess },
        } = this.props;
        return {
          children: (
            <FormattedMessage id="web.marketoSettings.revokeAccessConfirmation" />
          ),
          footer: {
            onPrimary: bind(
              this.actionCreators.bulkRevoke,
              this,
              ids,
              onSuccess
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.disconnect',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'common.disconnect',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      default:
        return EMPTY_POPUP;
    }
  }
}

MarketoAdminPopupContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  popup: PropTypes.string.isRequired,
  popupAlert: PropTypes.string.isRequired,
  popupData: PropTypes.object,
  popupLoading: PropTypes.bool.isRequired,
  workspaces: PropTypes.arrayOf(PropTypes.object),
  selectedWorkspaces: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  popupAlert: state.popupAlert,
  popupData: state.popupData,
  popupLoading: state.popupLoading,
  workspaces: state.marketoAdminWorkspaces,
  selectedWorkspaces: state.marketoAdminSelectedWorkspaces,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(MarketoAdminPopupContainer);
