import {
  SpecialCategoryIds,
  TEMPLATE_SPECIAL_CATEGORY,
} from 'web/templates/libs/templatesConstants';
import I18N from 'languages';

export const ComposeTemplatesConstants = {
  categoriesType: 'categories',
  categoryCardType: 'composeTemplatesCategoryCard',
  favoriteableType: 'Category',
};

export const FavoritesCategory = {
  icon: 'tout-icon-favorite-filled',
  id: TEMPLATE_SPECIAL_CATEGORY.favorites.id,
  name: TEMPLATE_SPECIAL_CATEGORY.favorites.name,
  tags: ['favorite'],
  title: TEMPLATE_SPECIAL_CATEGORY.favorites.name,
  value: TEMPLATE_SPECIAL_CATEGORY.favorites.id,
};

export const ArchivesCategory = {
  icon: 'tout-icon-archived-filled',
  id: TEMPLATE_SPECIAL_CATEGORY.archived.id,
  name: TEMPLATE_SPECIAL_CATEGORY.archived.name,
  tags: ['archived'],
  title: TEMPLATE_SPECIAL_CATEGORY.archived.name,
  value: TEMPLATE_SPECIAL_CATEGORY.archived.id,
};

export const RecommendedCategory = {
  icon: 'tout-icon-lightbulb',
  id: SpecialCategoryIds.recommended,
  name: I18N.getStr('common.recommended'),
  place: 'left',
  textId: 'web.composeWindow.templates.recommendedTooltip',
  tooltipId: 'template-recommended',
};

export const AllCategory = {
  id: TEMPLATE_SPECIAL_CATEGORY.all.id,
  name: TEMPLATE_SPECIAL_CATEGORY.all.name,
  title: TEMPLATE_SPECIAL_CATEGORY.all.name,
  value: TEMPLATE_SPECIAL_CATEGORY.all.id,
};

export const TEMPLATE_SEARCH_FIELDS = ['name', 'subject', 'body'];

export const DEFAULT_TEMPLATE_CATEGORIES = [AllCategory, FavoritesCategory];

export const COMPOSE_TEMPLATES_PAGE_SIZE = 24;

export const RECENTLY_USED_TEMPLATES_LOCAL_STORAGE_KEY = 'recentlyUsed';

export const RECENTLY_USED_TEMPLATES_COUNT = 8;

export const RecentlyUsedCategory = {
  id: SpecialCategoryIds.recentlyUsed,
  name: I18N.getStr('templateChooser.recentlyUsed'),
};
