import { createSelector } from 'reselect';
import { filterItemsByDate } from 'web/tables/helpers/itemsTableFilters';
import { TABLE_ID } from '../libs/diagnosticsTableConstants';
import { TablesFiltersConstants } from 'web/tables/libs/tablesFiltersConstants';
import {
  getObjectTypeString,
  getFailureErrorMessage,
} from 'web/diagnostics/helpers/diagnosticsHelpers';

const getDatesSmartFilter = (state) =>
  state.tablesDiagnosticsDatesSmartFilterValue;
const getSelectedDates = (state) => state.tablesDiagnosticsSelectedDates;
const getAllDiagnostics = (state) => state.diagnostics.allItems;

export const getTableDatesSmartFilter = createSelector(
  [getDatesSmartFilter],
  (datesFilter) => datesFilter.get(TABLE_ID) || TablesFiltersConstants.lastSeven
);

export const getTableStatusSmartFilter = createSelector(
  [getDatesSmartFilter],
  (statusFilter) =>
    statusFilter.get(TABLE_ID) || TablesFiltersConstants.lastSeven
);

export const getTableSelectedDates = createSelector(
  [getSelectedDates],
  (dates) => (dates.has(TABLE_ID) ? dates.get(TABLE_ID).toJS() : {})
);

const formatItems = (items) => {
  const rows = [];
  if (Array.isArray(items)) {
    items.forEach((item) => {
      rows.push({
        createdAt: item.created_at,
        errorCode: getFailureErrorMessage(item.failure_type),
        id: item.id,
        pitchId: item.request.pitch_id,
        recipient: item.request.email,
        source: item.service,
        subject: item.request.subject,
        type: getObjectTypeString(item.crm_object_type),
      });
    });
  }
  return rows;
};

export const getFormattedItems = createSelector(
  [getAllDiagnostics],
  (allItems) => formatItems(allItems)
);

export const getFilteredItems = createSelector(
  [
    getFormattedItems,
    getTableStatusSmartFilter,
    getTableDatesSmartFilter,
    getTableSelectedDates,
  ],
  (items, statusValue, datesValue, dates) => {
    const dateField = 'createdAt';
    return filterItemsByDate(items, datesValue, dates, dateField);
  }
);
