export default {
  fetching: 'GMAIL_INTEGRATION_FETCHING',
  disconnecting: 'GMAIL_INTEGRATION_DISCONNECTING',
  set: 'GMAIL_INTEGRATION_SET',
  error: 'GMAIL_INTEGRATION_ERROR',
  savingChanges: 'GMAIL_INTEGRATION_SAVING_CHANGES',
  clearStateValues: 'GMAIL_INTEGRATION_CLEAR_STATE_VALUES',
};

export const GmailIntegrationPopupIds = {
  disconnectConfirmation: 'GMAIL_INTEGRATION_DISCONNECT_CONFIRMATION',
};
