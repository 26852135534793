import { Regex } from 'web/libs/constants';

export const ValidatorTypes = {
  domainName: 'DOMAIN_NAME',
  email: 'EMAIL',
  integer: 'INTEGER',
  maxValue: 'MAX_VALUE',
  minLength: 'MIN_LENGTH',
  minValue: 'MIN_VALUE',
  numbers: 'NUMBERS',
  regExp: 'REG_EXP',
  required: 'REQUIRED',
  sameAs: 'SAME_AS',
};

export const ValidationErrorMessageIds = {
  domainName: 'common.validation.error.domainName',
  email: 'common.validation.error.email',
  integer: 'common.validation.error.integer',
  maxValue: 'common.validation.error.maxValue',
  minLength: 'common.validation.error.minLength',
  minValue: 'common.validation.error.minValue',
  numbers: 'common.validation.error.numbers',
  regExpTest: 'common.validation.error.regExpDontMatch',
  required: 'common.validation.error.required',
  sameAs: 'common.validation.error.sameAs',
};

export const ValidationRegularExpressions = { ...Regex };
