export const BCC_FORM_ID = 'integration-autobcc-form-id';

export const IntegrationsAlertIds = {
  success: 'integrations-alerts-success',
  failure: 'integrations-alerts-failure',
};

export const IntegrationsPopupIds = {
  deleteBcc: 'integrations-popups-delete-bcc',
  deleteSlack: 'integrations-popups-delete-slack',
  deleteBox: 'integrations-popups-delete-box',
  navigateToSlack: 'integrations-popups-navigate-slack',
  navigateToBox: 'integrations-popups-navigate-box',
};

export const IntegrationsProviders = {
  Slack: 'Slack',
  Box: 'Box',
  BCC: 'BCC',
};
