import DynamicFieldsActionTypes from 'web/dynamicFields/libs/dynamicFieldsActionTypes';
import { DynamicFieldsOverlayIds } from '../libs/dynamicFieldsModalConstants';

export function dynamicFieldsModalOverlay(state = '', { type = '' }) {
  switch (type) {
    case DynamicFieldsActionTypes.getFailure:
      return DynamicFieldsOverlayIds.getFailure;
    default:
      return state;
  }
}
