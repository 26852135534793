import merge from 'lodash/merge';
import * as gongPopupReducers from './gongPopupReducers';
import * as gongErrorReducers from './gongErrorReducers';
import * as gongOpportunitiesReducers from './gongOpportunitiesReducers';

const gongRoot = merge(
  {},
  gongPopupReducers,
  gongErrorReducers,
  gongOpportunitiesReducers
);

export default gongRoot;
