import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  bulkViewChangeHandoff,
  createNewAddress,
  groupComposeInit,
  fetchSearchResults,
  toggleBulkView,
  wrappedAppendAddress as appendAddress,
  wrappedRemoveAddressByIndex as removeAddressByIndex,
  wrappedSetAddresses as setAddresses,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import {
  getTemplateBypassUnsubscribe,
  getToAddresses,
  getValidationFailures,
  shouldBulkToggleBeDisabled,
} from 'web/composeWindow/selectors/composeWindowSelectors';
import AddressField from '../addressField/addressField';
import DoubleEmailChevron from 'web/composeWindow/components/doubleEmailChevron';
import {
  AddressFieldLimits,
  AddressTypes,
} from 'web/composeWindow/libs/composeWindowConstants';

const ToField = (props) => {
  const {
    addresses,
    appendAddress,
    bulkViewChangeHandoff,
    bulkViewOpened,
    composeBulkViewOpened,
    createNewAddress,
    disabled,
    hideGroupResults,
    errors,
    fetchSearchResults,
    groupComposeInit,
    isBulkDisabled,
    isBypassUnsubscribe,
    removeAddressByIndex,
    searchResults,
    setAddresses,
    toggleBulkView,
  } = props;

  return (
    <AddressField
      addresses={addresses}
      appendAddress={appendAddress}
      createNewAddress={createNewAddress}
      disabled={disabled}
      errors={errors}
      fetchSearchResults={fetchSearchResults}
      groupComposeInit={groupComposeInit}
      hideGroupResults={hideGroupResults}
      isBypassUnsubscribe={isBypassUnsubscribe}
      label="common.to"
      limit={AddressFieldLimits.to}
      readOnlyAddress={composeBulkViewOpened}
      removeAddressByIndex={removeAddressByIndex}
      searchResults={searchResults}
      setAddresses={setAddresses}
    >
      <DoubleEmailChevron
        bulkViewChangeHandoff={bulkViewChangeHandoff}
        bulkViewOpened={bulkViewOpened}
        isBulkDisabled={isBulkDisabled}
        toggleBulkView={toggleBulkView}
      />
    </AddressField>
  );
};

ToField.propTypes = {
  addresses: PropTypes.array,
  appendAddress: PropTypes.func.isRequired,
  bulkViewChangeHandoff: PropTypes.func.isRequired,
  bulkViewOpened: PropTypes.bool,
  composeBulkViewOpened: PropTypes.bool,
  createNewAddress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.array.isRequired,
  fetchSearchResults: PropTypes.func.isRequired,
  groupComposeInit: PropTypes.func.isRequired,
  hideGroupResults: PropTypes.bool.isRequired,
  isBulkDisabled: PropTypes.bool,
  isBypassUnsubscribe: PropTypes.bool,
  removeAddressByIndex: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  setAddresses: PropTypes.func.isRequired,
  toggleBulkView: PropTypes.func.isRequired,
};

ToField.defaultProps = {
  addresses: [],
  bulkViewOpened: false,
  composeBulkViewOpened: false,
  disabled: false,
  isBulkDisabled: false,
  isBypassUnsubscribe: false,
};

function mapDispatchToProps(dispatch) {
  return {
    appendAddress: (address) =>
      dispatch(appendAddress({ address, key: AddressTypes.to })),
    bulkViewChangeHandoff: () => dispatch(bulkViewChangeHandoff()),
    createNewAddress: (address) =>
      dispatch(createNewAddress({ address, key: AddressTypes.to })),
    fetchSearchResults: (query, type) =>
      dispatch(fetchSearchResults({ key: AddressTypes.to, query, type })),
    groupComposeInit: (groupId) => dispatch(groupComposeInit(groupId)),
    removeAddressByIndex: (index) =>
      dispatch(removeAddressByIndex({ index, key: AddressTypes.to })),
    setAddresses: (addresses) =>
      dispatch(setAddresses({ addresses, key: AddressTypes.to })),
    toggleBulkView: (bulkViewOpened) =>
      dispatch(toggleBulkView(bulkViewOpened)),
  };
}

const mapStateToProps = (state) => ({
  addresses: getToAddresses(state),
  bulkViewOpened: state.composeWindowState.bulkViewOpened,
  composeBulkViewOpened: state.composeWindowState.bulkViewOpened,
  errors: getValidationFailures(state).to,
  isBulkDisabled: shouldBulkToggleBeDisabled(state),
  isBypassUnsubscribe: getTemplateBypassUnsubscribe(state),
  searchResults: state.composeAddresses.searchResults.to,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToField);
