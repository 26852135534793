import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isGeneralPeopleGroup,
  isUnsubscribedGroup,
  getLocalizedDateFromGroupName,
} from 'web/people/helpers/peopleHelpers';
import EditableHeader from 'components/editableHeader';
import DropdownButtonMenu from 'web/people/components/dropdownButtonMenu/dropdownButtonMenu';
import HoverTooltip from 'components/hoverTooltip';
import { MAX_GROUP_DESCRIPTION_LENGTH } from 'web/people/libs/peopleConstants';
import GroupDescription from './groupDescription';
import Icon from 'components/buttons/icon';
import { FormattedHTMLMessage } from 'react-intl';
import { getTeams } from 'web/shared/services/accountService';
import moment from 'moment';
import { isMsiActions } from 'web/user/selectors/userSelectors';
import './peoplePageHeader.scss';

const HEADER_MAX_CHARACTERS_SHOW = 65;

class PeoplePageHeader extends Component {
  _getHeaderButtons = () => {
    const {
      currentGroupId,
      buttonsDisabled,
      actionCreators,
      isImportButtonVisible,
      isDeleteIconDisabled,
      isShareIconDisabled,
      isMsiActions,
    } = this.props;
    const headerButtons = [];
    const addDivideToDropdown = () => {
      const lastIndex = headerButtons.length - 1;
      const isLastButtonNotDivided =
        headerButtons[lastIndex] && !headerButtons[lastIndex].isDivide;

      if (isLastButtonNotDivided) {
        headerButtons[lastIndex].isDivide = true;
      }
    };
    const isVisible = !buttonsDisabled;
    const isGeneralGroup = isGeneralPeopleGroup(currentGroupId);
    let i = 0;

    if (!isGeneralGroup) {
      headerButtons.push(
        {
          id: (i += 1),
          isVisible,
          onLinkCallback: () => actionCreators.emailGroup(currentGroupId),
          titleId: 'web.people.emailGroup',
        },
        {
          id: (i += 1),
          isVisible,
          onLinkCallback: () =>
            actionCreators.openAddGroupToCampaignHandler(currentGroupId),
          titleId: 'web.people.addGroupToCampaign',
        }
      );

      addDivideToDropdown();
    }

    if (!isMsiActions && !isUnsubscribedGroup(currentGroupId)) {
      headerButtons.push({
        id: (i += 1),
        isVisible,
        onLinkCallback: actionCreators.openCreateContactPopup,
        titleId: isGeneralGroup
          ? 'web.people.createContact'
          : 'web.people.createContactAddToGroup',
      });
    }

    if (!isMsiActions && isImportButtonVisible) {
      headerButtons.push({
        id: (i += 1),
        isVisible,
        onLinkCallback: actionCreators.openImportPeopleStepOne,
        titleId: isGeneralGroup
          ? 'common.importFromCSV'
          : 'common.importCSVIntoGroup',
      });
    }

    if (!isGeneralGroup) {
      headerButtons.push({
        id: (i += 1),
        isVisible,
        onLinkCallback: () =>
          actionCreators.exportPeopleFromGroup(currentGroupId),
        titleId: 'common.export',
      });
    }

    addDivideToDropdown();

    if (!isShareIconDisabled) {
      headerButtons.push({
        id: (i += 1),
        isVisible,
        onLinkCallback: () =>
          actionCreators.openShareGroupPopup(currentGroupId),
        titleId: 'web.people.shareGroup',
      });
    }

    if (!isDeleteIconDisabled) {
      headerButtons.push({
        className: 'dropdown-item-red',
        id: (i += 1),
        isVisible,
        onLinkCallback: () =>
          actionCreators.openDeleteGroupPopup(currentGroupId),
        titleId: 'web.people.deleteGroup',
      });
    }

    return headerButtons;
  };

  _getCreatedAtBlock = () => {
    const { groups, currentGroupId } = this.props;
    const currentGroupData = groups.find(
      (group) => +group.id === +currentGroupId
    );

    return currentGroupData && currentGroupData['user'] ? (
      <div className="align-right">
        <FormattedHTMLMessage
          id="web.editTemplateSlideOut.editTemplateHeaderInfo.createdBy"
          values={{
            fromNow: moment(currentGroupData['created_at']).fromNow(),
            name: currentGroupData['user'].name,
          }}
        />
      </div>
    ) : null;
  };

  _getDescriptionBlock = () => {
    const { onGroupDescriptionChange, selectedGroup } = this.props;
    if (!selectedGroup) {
      return null;
    }
    const { truncatedDescription, description } = selectedGroup;
    const { currentGroupId } = this.props;

    const getGroupDescription = () => (
      <GroupDescription
        description={truncatedDescription}
        groupId={currentGroupId}
        onGroupDescriptionChange={(groupId, description) =>
          onGroupDescriptionChange(groupId, description)
        }
      />
    );

    return (
      <div className="page-header-row">
        {description && description.length > MAX_GROUP_DESCRIPTION_LENGTH ? (
          <HoverTooltip
            stayOpen={false}
            textValue={description}
            tooltipId="page-header-description"
          >
            {getGroupDescription()}
          </HoverTooltip>
        ) : (
          getGroupDescription()
        )}
      </div>
    );
  };

  getGroupSharedTeams(selectedGroup) {
    return selectedGroup && selectedGroup['team_ids']
      ? getTeams(selectedGroup['team_ids'])
          .filter((shareTeam) =>
            selectedGroup['team_ids'].includes(shareTeam.id)
          )
          .map((x) => x.name)
          .join(', ')
      : [];
  }

  render() {
    const { selectedGroup, currentGroupId, groups, isMsiActions } = this.props;

    const titleId = isGeneralPeopleGroup(currentGroupId)
      ? 'common.actions'
      : 'web.people.groupActions';

    const isSharedTeam =
      selectedGroup &&
      selectedGroup['team_ids'] &&
      selectedGroup['team_ids'].length;

    let showActionMenu = true;

    if (isUnsubscribedGroup(currentGroupId)) {
      showActionMenu = false;
    } else {
      if (isMsiActions) {
        // MT: This is because the `Actions` button only has the
        // following two actions in the dropdown:
        // 1. Create Contact
        // 2. Import CSV
        // Both of the above are expected to be disabled for
        // MSI Actions subscription plan type
        if (titleId === 'common.actions') {
          showActionMenu = false;
        } else {
          // This is the case of the "Group Actions" button. Here,
          // we want to display the button, but we don't want to
          // display the following options for the selected group
          // 1. Create Contact
          // 2. Import CSV
          // So, we display the group actions and remove those
          // from the dropdown - this is handled in `this._getHeaderButtons`
          showActionMenu = true;
        }
      } else {
        showActionMenu = true;
      }
    }

    return (
      <div className="page-header fill-height">
        <div className="page-header-row">
          {selectedGroup && (
            <EditableHeader
              isEditable={false}
              maxCharactersShow={HEADER_MAX_CHARACTERS_SHOW}
              onSave={() => {}}
              placeHolderTextId="web.people.enterGroupName"
              value={getLocalizedDateFromGroupName(selectedGroup.name)}
            />
          )}
          <div className="align-right">
            {showActionMenu && (
              <DropdownButtonMenu
                items={this._getHeaderButtons()}
                titleId={titleId}
              />
            )}
          </div>
        </div>
        <div className="people-icon-wrapper">
          {isSharedTeam ? (
            <Icon
              classes="people-icon people-icon-group"
              color="blue"
              title={this.getGroupSharedTeams(selectedGroup)}
            />
          ) : null}
          {this._getDescriptionBlock()}
        </div>
        {!!groups.length && (
          <div className="page-header-row">{this._getCreatedAtBlock()}</div>
        )}
      </div>
    );
  }
}

PeoplePageHeader.defaultProps = {
  buttonsDisabled: false,
  selectedGroup: null,
  user: {},
};

PeoplePageHeader.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  buttonsDisabled: PropTypes.bool,
  currentGroupId: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
  isDeleteIconDisabled: PropTypes.bool.isRequired,
  isImportButtonVisible: PropTypes.bool.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  isShareIconDisabled: PropTypes.bool.isRequired,
  onGroupDescriptionChange: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isMsiActions: isMsiActions(state),
});

export default connect(
  mapStateToProps,
  {}
)(PeoplePageHeader);
