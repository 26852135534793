import { getUserChannel } from '../../services/pusherService';
import { PusherEvents } from '../../libs/constants';
import {
  salesforceImportComplete,
  salesforceImportFailed,
  salesforceImportProgress,
} from './peopleImportActionCreators';

export const bindPeopleSalesforceImport = (jobId, callback) => (dispatch) => {
  const channel = getUserChannel();

  channel.bind(PusherEvents.contactImportComplete, (data = {}) => {
    if (jobId === data.job_id) {
      dispatch(salesforceImportComplete(data.people_ids, callback));
    }
  });

  channel.bind(PusherEvents.contactImportProgress, (data = {}) => {
    if (jobId === data.job_id) {
      dispatch(salesforceImportProgress(data.percent_complete));
    }
  });

  channel.bind(PusherEvents.contactImportFailed, (data = {}) => {
    if (jobId === data.job_id) {
      dispatch(salesforceImportFailed(data.error));
    }
  });
};

export const unbindPeopleSalesforceImport = () => {
  const channel = getUserChannel();
  channel.unbind(PusherEvents.contactImportComplete);
  channel.unbind(PusherEvents.contactImportProgress);
  channel.unbind(PusherEvents.contactImportFailed);
};
