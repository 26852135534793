import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { navigateToCommandCenterEmails } from 'web/services/routerService';
import FilterBlockContainer from 'web/commandCenter/modules/filterBlock/containers/filterBlockContainer';
import Square from 'components/buttons/square';

const AdvancedSearchEmailsTitle = ({
  actionCreators: { resetSidebarViewAsFilter },
}) => {
  const closeAdvSearchHandler = () => {
    resetSidebarViewAsFilter();
    navigateToCommandCenterEmails();
  };

  return (
    <span className="emails-advanced-search-title">
      <Square
        className="margin-left-half margin-right-half chevron-square-btn chevron-square-btn-left emails-advanced-search-title-backbtn"
        onClick={closeAdvSearchHandler}
      />
      <FormattedMessage id="web.commandCenter.advancedSearch.title" />
    </span>
  );
};

AdvancedSearchEmailsTitle.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

const AdvancedSearchEmailsBody = ({ actionCreators: { refreshEmails } }) => (
  <div className="emails-filter-boxes">
    <FilterBlockContainer onRemoveFilterBlock={refreshEmails} />
  </div>
);

AdvancedSearchEmailsBody.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

export default {
  Body: AdvancedSearchEmailsBody,
  Title: AdvancedSearchEmailsTitle,
};
