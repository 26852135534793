import MarketoWorkspacesActionTypes from 'web/marketo/workspaces/libs/marketoWorkspacesActionTypes';

export const initMarketoWorkspacesState = [];
export const marketoWorkspaces = (
  state = initMarketoWorkspacesState,
  { type, marketoWorkspaces: workspaces = initMarketoWorkspacesState }
) => {
  switch (type) {
    case MarketoWorkspacesActionTypes.workspaces.set:
      return workspaces;
    default:
      return state;
  }
};
