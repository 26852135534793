import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';

const SalesforceNotConnected = (props) => {
  const {
    intl: { formatMessage },
  } = props;
  const buttonText = formatMessage({
    id: 'web.salesforceCustomization.install.button',
  });

  return (
    <Fragment>
      <div className="margin-top-20">
        <FormattedMessage id="web.salesforceCustomization.notConnected" />
      </div>
      <div className="margin-top-20 right">
        <Button
          color={ButtonColors.blue}
          size={ButtonSizes.standard}
          title={buttonText}
          disabled
        >
          {buttonText}
        </Button>
      </div>
    </Fragment>
  );
};

SalesforceNotConnected.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceNotConnected);
