import React from 'react';
import SyncTasksForm from 'web/salesforce/components/syncTasksForm';
import { SYNC_TASKS_FORM_NON_ADMIN_ID } from 'web/salesforce//libs/salesforceConstants';
import PropTypes from 'prop-types';

const SyncTasksCard = (props) => {
  const {
    actionCreators: { saveSyncTasksForm },
    isApplyTeamSettings,
    salesforceIntegration: { sync_tasks: syncTasks },
  } = props;
  const getSyncTasksModel = () => {
    const {
      contactIntegrationForSalesforce: {
        skipped_task_action: skippedTasksValue = false,
      },
    } = props;

    return {
      skipped_task_action: syncTasks ? skippedTasksValue : null,
      sync_tasks: syncTasks,
    };
  };
  const syncTasksModel = getSyncTasksModel();
  return (
    <SyncTasksForm
      form={SYNC_TASKS_FORM_NON_ADMIN_ID}
      onSubmit={saveSyncTasksForm}
      initialValues={syncTasksModel}
      enableReinitialize={isApplyTeamSettings}
      disabled={isApplyTeamSettings}
    />
  );
};

SyncTasksCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  contactIntegrationForSalesforce: PropTypes.object,
  salesforceIntegration: PropTypes.object,
  isApplyTeamSettings: PropTypes.bool.isRequired,
};

SyncTasksCard.defaultProps = {
  contactIntegrationForSalesforce: {},
  salesforceIntegration: {},
};

export default SyncTasksCard;
