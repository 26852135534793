import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function paste(position, text) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.nonHoverTooltip.position, data: position });
    dispatch({ type: ActionTypes.nonHoverTooltip.text, data: text });
    dispatch({ type: ActionTypes.nonHoverTooltip.shown, data: true });
  };
}

export function toggleVisibility(visible) {
  return { type: ActionTypes.nonHoverTooltip.shown, data: visible };
}
