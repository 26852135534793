import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './textarea.scss';

class Textarea extends Component {
  render() {
    const { className, customRef, rows, ...other } = this.props;
    const classes = classNames('input-textarea', className);

    return (
      <textarea
        className={classes}
        ref={customRef}
        rows={rows}
        style={this._calcTextareaHeight(rows)}
        type="textarea"
        {...other}
        onChange={this.onChange}
      />
    );
  }

  _calcTextareaHeight(rows) {
    const LINE_HEIGHT = 25;
    const PADDING = 4;
    const BORDER = 1;
    return {
      height: `${rows * LINE_HEIGHT +
        PADDING * 2 +
        BORDER * 2 +
        LINE_HEIGHT / 2}px`,
    }; // half added to show extra is scrollable
  }

  onChange = (e) => {
    this.props.onChange(e.currentTarget.value || e.target.value);
  };
}

Textarea.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
};

Textarea.defaultProps = {
  autoFocus: true,
  className: '',
  rows: 4,
};

export default Textarea;
