import React from 'react';
import PropTypes from 'prop-types';
import SmartSelect, {
  SmartSelectThemes,
} from 'components/selects/smartSelect/smartSelect';
import Shapes from 'web/shared/components/sidebar/sidebarShapes';
import './sidebarFilter.scss';

const ROWS_SHOWN = 6;

const SidebarFilter = ({
  inputValue,
  items,
  label,
  onChange,
  onSelect,
  selectedItem,
}) =>
  items && onChange && onSelect ? (
    <SmartSelect
      className="tout-ui-sidebar-filter"
      inputValue={inputValue}
      items={items}
      label={label}
      onChange={onChange}
      onSelect={onSelect}
      rowsShown={ROWS_SHOWN}
      selectedItem={selectedItem}
      theme={SmartSelectThemes.dark}
    />
  ) : null;

SidebarFilter.propTypes = {
  inputValue: PropTypes.string,
  items: Shapes.Users,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.number,
};

SidebarFilter.defaultProps = {
  selectedItem: -1,
  inputValue: '',
  items: [],
  label: '',
  onChange: undefined,
  onSelect: undefined,
};

export default SidebarFilter;
