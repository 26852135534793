import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Button, { ButtonSizes } from 'components/buttons/action';
import { productTourSteps } from 'web/productTour/containers/productTourStepsContainer/lib/productTourConstants';
import './step1.scss';

const Step1 = (props) => {
  const { changeTab, productTourLoadedImages } = props;
  const getImgURL = (folder) =>
    productTourLoadedImages.length
      ? `${productTourLoadedImages[0]}`
      : `${getImageUrl(`productTour/${folder}/step1`, 'png')}`;

  return (
    <div className="productTour-step1-rightColumn-wrapper">
      <picture>
        <source media="(min-width: 1920px)" srcSet={getImgURL('1920x1080')} />
        <source media="(max-width: 1536px)" srcSet={getImgURL('1536x864')} />
        <source media="(max-width: 1440px)" srcSet={getImgURL('1440x900')} />
        <img alt="step 1" src={getImgURL('1920x1080')} />
      </picture>
      <div className="productTour-step1-content">
        <h1>
          <FormattedHTMLMessage id={'web.productTour.newMSITitle'} />
        </h1>
        <div>
          <FormattedHTMLMessage id={'web.productTour.newMSIInfo'} />
          <Button
            onClick={() => changeTab(productTourSteps.step2)}
            size={ButtonSizes.medium}
          >
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productTourLoadedImages: state.productTourLoadedImages,
});

const mapDispatchToProps = {};

Step1.defaultProps = {
  changeTab: () => {},
};

Step1.propTypes = {
  changeTab: PropTypes.func,
  productTourLoadedImages: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1);
