import {
  PitchCategoryToIgnore,
  PitchEvents,
  PitchStates,
  FailedDeliveryReason,
} from 'web/emails/libs/emailsConstants';
import { Urls } from 'web/libs/routes';
import {
  parseToutEventFromEmail,
  sortParsedEvents,
} from 'web/liveFeed/engage/helpers/engageParsers';
import { Regex } from 'web/libs/constants';
import I18N from 'languages';

export const countEmailEvents = (events = []) =>
  events.reduce(
    (counts, { event }) => {
      const _counts = counts;

      switch (event) {
        case PitchEvents.click:
          _counts.clicks += 1;
          break;
        case PitchEvents.open:
          _counts.views += 1;
          break;
        case PitchEvents.reply:
          _counts.replies += 1;
          break;
        default:
          break;
      }
      return _counts;
    },
    { clicks: 0, replies: 0, views: 0 }
  );

const getReason = (state, category = '', events = []) => {
  if (state === PitchStates.bounced) {
    const bouncedEvent = events.find(
      ({ event }) => event === PitchEvents.bounce
    );
    if (bouncedEvent && bouncedEvent.reason) {
      return bouncedEvent.reason;
    } else {
      return '';
    }
  } else if (category !== PitchCategoryToIgnore) {
    return category;
  } else {
    return '';
  }
};

const getCampaign = ({ day, name, workflow_id: id } = {}) =>
  id
    ? {
        day,
        name,
        url: Urls.campaignTab.replace(':#id', id).replace(':#tab', 'setup'),
      }
    : null;

const parseEvents = (events, subject) =>
  events.map((event) => parseToutEventFromEmail({ ...event, subject }));

export const parseEmail = ({
  additional_to: tos = '',
  archived = false,
  bcc = '',
  body = '',
  category,
  cc = '',
  delivered_at: deliveredAt = null,
  email = '',
  events = [],
  file_attachments: fileAttachments = [],
  id = 0,
  identity: { email: identityEmail = '', name: identityName = '' } = {},
  last_event_at: lastEventAt = null,
  send_at: sendAt = null,
  state = '',
  subject = '',
  success = null,
  user: { email: userEmail = '', name: userName = '' } = {},
  user_id: userId = 0,
  workflow_details: workflowDetails,
} = {}) => ({
  ...countEmailEvents(events),
  archived,
  bcc,
  body,
  campaign: getCampaign(workflowDetails),
  cc,
  deliveredAt,
  email,
  events: parseEvents(events, subject),
  fileAttachments,
  id,
  identityEmail: identityEmail || userEmail,
  identityName: identityName || userName,
  lastEventAt,
  pitchState: state,
  reason: getReason(state, category, events),
  sendAt,
  subject,
  success: success != null,
  tos,
  userId,
});

const sortEmails = (first, second) =>
  first.deliveredAt >= second.deliveredAt ? -1 : 1;

export const parseEmails = (emails = []) =>
  emails.map(parseEmail).sort(sortEmails);

export const parseEventsFromEmail = ({ events, subject }) =>
  parseEvents(events, subject).sort(sortParsedEvents);

export const parseEventsFromEmails = (emails = []) =>
  emails
    .reduce(
      (accumulator, { events = [], subject = '' }) =>
        accumulator.concat(parseEvents(events, subject)),
      []
    )
    .sort(sortParsedEvents);

export const failedDeliveryReasonBackendStrings = (reason) => {
  let textId = '';
  let argument = {};
  let matches;
  let value;

  switch (reason) {
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_COULD_NOT_POPULATE_ALL_FIELDS:
      textId = 'web.emails.failedDeliveryReason.couldNotPopulateAllFields';
      break;
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_CONTACT_PART_OF_CAMPAIGN:
      textId = 'web.emails.failedDeliveryReason.contactPartOfCampaign';
      break;
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_RECIPIENT_UNSUBSCRIBED:
      textId = 'web.emails.failedDeliveryReason.recipientUnsubscribed';
      break;
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_ACCOUNT_SUSPENDED:
      textId = 'web.emails.failedDeliveryReason.accountSuspended';
      break;
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_EMAIL_ADDRESS_UNSUBSCRIBED:
    case reason.match(Regex.email):
      matches = reason.match(Regex.email);
      value = matches && matches.length ? matches[0] : '';
      textId = 'web.emails.failedDeliveryReason.emailAddressUnsubscribed';
      argument = { email: value };
      break;
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_REACHED_DAILY_EMAIL_LIMIT:
    case reason.match(/(\d+)/):
      matches = reason.match(/(\d+)/);
      value = matches && matches.length ? matches[0] : '';
      textId = 'web.emails.failedDeliveryReason.reachedDailyEmailLimit';
      argument = { daylimit: value };
      break;
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_CAMPAIGN_FINISHED:
      textId = 'web.emails.failedDeliveryReason.campaignFinished';
      break;
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_GENERAL_REASON:
      textId = 'web.emails.failedDeliveryReason.generalReason';
      break;
    case FailedDeliveryReason.FAILED_DELIVERY_REASON_DELIVERY_FAILED:
      textId = 'web.emails.failedDeliveryReason.deliveryFailed';
      break;
    default:
      return reason;
  }
  return I18N.getStr(textId, argument);
};
