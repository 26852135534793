import { combineReducers } from 'redux';
import bcc from '../bcc/reducers/bccReducer';
import slack from '../slack/reducers/slackReducer';
import box from '../box/reducers/boxReducer';

export default combineReducers({
  bcc,
  slack,
  box,
});
