import memoize from 'lodash/memoize';

export const getTabsWithSubtabs = (
  tabsConfig = {},
  formatMessage = () => {}
) => {
  const tabs = [];
  const subTabs = {};

  Object.values(tabsConfig).forEach((tab) => {
    if (tab.hidden) return;
    const tabValue = tab.value;
    tabs.push({
      title: formatMessage({ id: tab.label }),
      value: tabValue,
    });
    if (tab.subTabs) {
      if (!subTabs[tabValue]) {
        subTabs[tabValue] = [];
      }
      Object.values(tab.subTabs).forEach((subTab) => {
        subTabs[tabValue].push({
          title: formatMessage({ id: subTab.label }),
          value: subTab.value,
        });
      });
    }
  });

  return {
    subTabs,
    tabs,
  };
};

export const memoizedGetTabsWithSubtabs = memoize(getTabsWithSubtabs);
