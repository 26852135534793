import { createAction } from '@reduxjs/toolkit';
import { deleteAdvancedSearch as deleteAdvancedSearchService } from 'web/commandCenter/modules/emailGridAdvancedSearch/services/advancedSearchService';
import { CommandCenterPopupIds } from 'web/commandCenter/libs/commandCenterConstants';
import {
  openPopup,
  closePopup,
  setPopupLoading,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import { navigateToCommandCenterEmails } from 'web/services/routerService';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';

export const deleteSavedEmailAdvancedSearch = createAction(
  'DELETE_EMAIL_ADVANCED_SEARCH_SAVE'
);

export const openDeleteSearchPopup = (savedSearchId) => (dispatch) =>
  dispatch(
    openPopup(CommandCenterPopupIds.advancedSearchDelete, { savedSearchId })
  );

export const deleteAdvancedSearch = () => async (dispatch, getState) => {
  const state = getState();
  const {
    emailSelectedSavedAdvancedSearchId,
    popupData: { savedSearchId },
  } = state;
  dispatch(setPopupLoading(true));
  try {
    await deleteAdvancedSearchService(savedSearchId);
    dispatch(setPopupLoading(false));
    dispatch(closePopup());
    dispatch(deleteSavedEmailAdvancedSearch({ savedSearchId }));
    if (savedSearchId === emailSelectedSavedAdvancedSearchId) {
      // redirect to email tab
      navigateToCommandCenterEmails();
    }
  } catch (error) {
    dispatch(setPopupLoading(false));
    dispatch(setPopupAlert(EmailAlertIds.deleteSavedSearchError));
  }
};
