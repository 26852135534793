import { createAction } from '@reduxjs/toolkit';
import {
  ADVANCED_SEARCH_FILTERS_COLLECTION,
  FILTER_SEARCH_ID,
  FILTER_SEARCH_STATUS_ID,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import {
  copySmartFilters,
  setSmartFilter,
} from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { debouncePaginateEmails } from 'web/commandCenter/actionCreators/commandCenterEmailsSearchActionCreators';
import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';
import {
  getAdvancedSearchTableId,
  navigateToAdvancedSearch,
  validateSelectedSubStatuses,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/helpers/advancedSearchHelpers';
import EmailGroupsActionType from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGroupsActionType';
import { getAdvancedSearchStatus } from 'web/commandCenter/modules/statusSmartFilter/selectors/statusSmartFilterSelectors';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';
import {
  setPopupAlert,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';
import {
  isSavedAdvancesSearchChanged,
  isSavedAdvancesSearch,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';

export const searchEmailAdvancedSearch = () => (dispatch, getState) => {
  const state = getState();
  const currentTableId = getActiveEmailTableId(state);

  const advancedSearchTableId = getAdvancedSearchTableId();

  const { tabId, subTabIds } = getAdvancedSearchStatus(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
    filterId: FILTER_SEARCH_STATUS_ID,
  });
  if (tabId && tabId !== CommandCenterEmailsTabsEnum.sent.value) {
    const isValidSubStatuses = validateSelectedSubStatuses(subTabIds);
    if (!isValidSubStatuses) {
      dispatch(setPopupAlert(EmailAlertIds.missingSubStatusesError));
      return;
    }
  }

  dispatch(closePopup());

  if (!isSavedAdvancesSearch(state) || isSavedAdvancesSearchChanged(state)) {
    dispatch(
      copySmartFilters({
        collectionFromId: ADVANCED_SEARCH_FILTERS_COLLECTION,
        collectionToId: advancedSearchTableId,
      })
    );
    currentTableId === advancedSearchTableId
      ? debouncePaginateEmails(dispatch)
      : navigateToAdvancedSearch();
  }
};

export const setAdvancedSearchValue = (value) => (dispatch) => {
  dispatch(
    setSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: FILTER_SEARCH_ID,
      payload: { searchValue: value },
    })
  );
};

export const goToEmailGroup = (emailsGroupId) => (dispatch) => {
  dispatch({
    payload: emailsGroupId,
    type: EmailGroupsActionType.singleRowEmails.setCurrentEmailsGroupId,
  });
};

export const clearAdvancedSearchValue = () => (dispatch) => {
  dispatch(setAdvancedSearchValue(''));
};

export const setSelectedEmailId = createAction('SET_SELECTED_EMAIL_ID');
export const resetSelectedEmailId = createAction('RESET_SELECTED_EMAIL_ID');
