import { baseDelete, basePost, basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';
import {
  TEMPLATE_TAG_TYPES,
  SPECIAL_FILTER_CATEGORIES,
  BULK_ACTIONS,
} from 'web/templates/libs/templatesConstants';
import I18N from 'languages';

const isFavoriteForCurrentUser = (template, user) =>
  (template.tag_user_ids &&
    template.tag_user_ids[TEMPLATE_TAG_TYPES.favorite] &&
    template.tag_user_ids[TEMPLATE_TAG_TYPES.favorite].includes(user.id)) ||
  false;
const isFavoriteForSubUsers = (template, user) =>
  (user &&
    user.id === SPECIAL_FILTER_CATEGORIES.ALL_USERS &&
    template.tag_user_ids &&
    template.tag_user_ids[TEMPLATE_TAG_TYPES.favorite] &&
    template.tag_user_ids[TEMPLATE_TAG_TYPES.favorite].length > 0) ||
  false;

export const isTemplateFavorite = (template, user) =>
  isFavoriteForCurrentUser(template, user) ||
  isFavoriteForSubUsers(template, user);

const cleanTemplateMappings = (mappings = []) =>
  mappings.map((mapping) => {
    const updated = { ...mapping };
    Reflect.deleteProperty(updated, 'id');
    return updated;
  });

export function createTemplate({
  attachments = [],
  bcc = '',
  body = '',
  categoryId = 0,
  cc = '',
  name = '',
  subject = '',
} = {}) {
  return new Promise((resolve, reject) => {
    const pitchData = {
      bcc,
      body: body || I18N.getStr('web.templates.createTemplate.defaultBody'), // current logic, but do we still want to do this?
      category_id: categoryId,
      cc,
      name: name.trim(),
      subject,
    };
    const postData = { attachment_id: attachments, pitch_template: pitchData };
    basePost(Urls.templates, postData, resolve, reject);
  });
}

export const cloneTemplate = (name, categoryId, template) =>
  new Promise((resolve, reject) => {
    const {
      id,
      body,
      cc,
      bcc,
      subject,
      enable_mappings: enableMappings,
      template_mappings: templateMappings,
      file_attachments: fileAttachments,
    } = template;
    const postData = {
      prototype_template_id: id,
      attachment_id: fileAttachments.map((file) => file.id),
      pitch_template: {
        bcc,
        body,
        category_id: categoryId,
        cc,
        enable_mappings: enableMappings,
        name: name.trim(),
        subject,
        template_mappings: cleanTemplateMappings(templateMappings),
      },
    };
    basePost(Urls.templates, postData, resolve, reject);
  });

export function moveTemplate(id, categoryId) {
  const data = {
    pitch_template: {
      category_id: categoryId,
    },
  };
  return new Promise((resolve, reject) => {
    basePut(Urls.templatesId.replace(':#id', id), data, resolve, reject);
  });
}

export function moveTemplateToArchive(id) {
  const data = {
    template_ids: [id],
    bulk_action: BULK_ACTIONS.archive,
  };

  return new Promise((resolve, reject) => {
    basePut(
      Urls.templatesUpdateSync,
      { pitch_template: data },
      resolve,
      reject
    );
  });
}

export const deleteTemplate = (id) =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.templatesId.replace(':#id', id), {}, resolve, reject);
  });
