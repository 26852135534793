import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './accordion.scss';

const Accordion = ({ children, className }) => {
  const classes = classNames('tout-accordion', className);

  return <div className={classes}>{children}</div>;
};

Accordion.defaultProps = {
  className: '',
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Accordion;

export let UnwrappedAccordion; //eslint-disable-line import/no-mutable-exports
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedAccordion = Accordion;
}
