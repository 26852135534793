import { setSmartFilter } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { dateRangeSmartFilterID } from 'web/tables/dateRangeSmartFilter/libs/dateRangeSmartFilterConstants';

import { getFilterDatesRequired } from 'web/tables/helpers/itemsTableFilters';

import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';

import { getSelectedValue } from 'web/tables/dateRangeSmartFilter/selectors/dateRangeSmartFilterSelectors';

import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';

import { createAction } from '@reduxjs/toolkit';

export const setIsCustomDateChanged = createAction(
  'DATE_RANGE_SMART_FILTERS_SET_IS_CUSTOM_DATE_CHANGED'
);

export const setDateRangeSmartFilter = ({
  collectionId,
  selectedValue,
  start,
  end,
}) => (dispatch) => {
  const dates = getFilterDatesRequired(selectedValue, start, end);

  dispatch(
    setSmartFilter({
      collectionId,
      filterId: dateRangeSmartFilterID,
      payload: {
        end: dates.end,
        selectedValue,
        start: dates.start,
      },
    })
  );
};

export const initDateRangeSmartFilters = (tab, subTab) => (
  dispatch,
  getState
) => {
  const state = getState();
  const tableId = getActiveEmailTableId(state);

  const currentDateRange = getSelectedValue(state);

  if (!currentDateRange) {
    const { selectedValue } = CommandCenterEmailsTabsEnum[tab].subTabs[
      subTab
    ].smartFilters[dateRangeSmartFilterID];
    dispatch(
      setDateRangeSmartFilter({
        collectionId: tableId,
        selectedValue,
      })
    );
  }
};
