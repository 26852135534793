import React, { Component, Fragment } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { AdminUnsubscribeCardsWidth } from '../libs/adminUnsubscribesConstants';
import I18N from 'languages';
import Switch from 'components/buttons/switch';
import SettingsUnsubscribesConstants from 'web/settings/unsubscribes/libs/settingsUnsubscribesConstants';

class AdminUnsubscribesAppendSetting extends Component {
  render() {
    const { isEnabled } = this.props;
    const switchLabel = isEnabled
      ? I18N.getStr(
          'web.settings.unsubscribe.admin.appendUnsubscribeSetting.toggle.enabled'
        )
      : I18N.getStr(
          'web.settings.unsubscribe.admin.appendUnsubscribeSetting.toggle.disabled'
        );

    return (
      <Fragment width={AdminUnsubscribeCardsWidth.messaging}>
        <h3>
          <FormattedMessage id="web.settings.unsubscribe.admin.appendUnsubscribeSetting" />
        </h3>
        <p className="margin-top-full">
          <FormattedHTMLMessage id="web.settings.unsubscribe.admin.appendUnsubscribeSetting.body" />
        </p>
        <Switch
          checked={isEnabled}
          classes="padding-top-25"
          label={switchLabel}
          name="call_recording_enabled"
          onChange={this.toggleUnsubscribeSettings}
          rightLabel
        />
        <div className="margin-top-full" />
      </Fragment>
    );
  }

  toggleUnsubscribeSettings = (isEnabled) => {
    const { updateSettings, save } = this.props;

    updateSettings({
      unsubscribe_auto_append: !!isEnabled,
    });

    save(
      SettingsUnsubscribesConstants.alertIds.saveUnsubscribeMessagingSuccess
    );
  };
}

AdminUnsubscribesAppendSetting.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
};

export default AdminUnsubscribesAppendSetting;
