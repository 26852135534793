import UnsubscribeFormActionTypes from '../libs/unsubscribeFormActionTypes';

export const unsubscribeFormBlockTypeSelected = (
  state = null,
  { type = '', selected = null }
) => {
  switch (type) {
    case UnsubscribeFormActionTypes.selectBlockType:
      return selected;

    default:
      return state;
  }
};

export const unsubscribeFormIsHidden = (state = false, { type = '' }) => {
  switch (type) {
    case UnsubscribeFormActionTypes.unsubscribeManualSuccess:
      return true;

    default:
      return state;
  }
};

export const unsubscribeFormOtherReason = (
  state = '',
  { type = '', value = '' }
) => {
  switch (type) {
    case UnsubscribeFormActionTypes.changeOtherReason:
      return value;

    case UnsubscribeFormActionTypes.selectReason:
      return '';

    default:
      return state;
  }
};

export const unsubscribeFormReasonSelected = (
  state = 'default',
  { type = '', selected = 'default' }
) => {
  switch (type) {
    case UnsubscribeFormActionTypes.selectReason:
      return selected;

    default:
      return state;
  }
};

export const unsubscribeManualLoading = (
  state = false,
  { type = '', loading = false }
) => {
  switch (type) {
    case UnsubscribeFormActionTypes.unsubscribeManualLoading:
      return loading;

    default:
      return state;
  }
};
