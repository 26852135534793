import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OnboardingStepTitle from 'web/onboarding/components/onboardingStepTitle/onboardingStepTitle';
import OnboardingActionButtons from 'components/popups/popupFooter/popupFooter';
import { navigateToUrl } from 'web/services/routerService';
import { steps } from 'web/onboarding/lib/stepsRouting';
import { getImageUrl } from 'web/libs/constants';
import './onboardingFinish.scss';

const trophyIcon = getImageUrl('trophy-icon-outline', 'png');

const OnboardingFinish = (props) => (
  <div className="onboarding-finish">
    <OnboardingStepTitle titleId={'web.onboarding.finishPage.title'} />
    <img alt="trophy" className="onboarding-trophy-cup" src={trophyIcon} />
    <p className="onboarding-finish-main">
      <FormattedMessage id={'web.onboarding.finishPage.mainText'} />
    </p>
    <OnboardingActionButtons
      onPrimary={props.finishAction}
      onSecondary={() => navigateToUrl(steps[steps.length - 1].url)}
      primaryTextId={'web.onboarding.actionButtons.finish'}
      secondaryTextId={'common.back'}
      full
    />
  </div>
);

OnboardingFinish.propTypes = {
  finishAction: PropTypes.func.isRequired,
};

export default OnboardingFinish;
