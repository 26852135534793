/**
 *
 * Popup
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors, TextColors } from 'libs/constantsStyles';
import { ButtonColors } from 'components/buttons/action';
import PopupComponent from './popupComponent';

const Popup = ({ inner, popUpClassName, ...other }) => (
  <div
    className={classNames('modal-background', {
      'modal-background-inner': inner,
      [popUpClassName]: popUpClassName,
    })}
  >
    <PopupComponent {...other} />
  </div>
);

Popup.propTypes = {
  inner: PropTypes.bool,
  popUpClassName: PropTypes.string,
};

Popup.defaultProps = {
  inner: false,
  popUpClassName: '',
};

export const PopupAlignContentClasses = {
  left: 'left-aligned',
};
export const PopupBackgroundColors = BackgroundColors;
export const PopupButtonColors = ButtonColors;
export const PopupTextColors = TextColors;

export default Popup;
