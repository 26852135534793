import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import LogEmailActivityForm from 'web/salesforce/components/logEmailActivityForm';
import { LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID } from 'web/salesforce//libs/salesforceConstants';
import { SalesforceSyncType } from '../../libs/salesforceConstants';
import RadioGroup from 'components/buttons/radioGroup';
import BccForm from '../bccForm';
import Action, { ButtonColors } from 'components/buttons/action';
import HoverTooltip from 'components/hoverTooltip';

class LogEmailActivityCard extends Component {
  constructor(props) {
    super(props);

    const {
      salesforceIntegration: { push_pitches: pushPitches },
      intl: { formatMessage },
    } = props;
    this.state = {
      syncType: pushPitches ? SalesforceSyncType.api : SalesforceSyncType.bcc,
    };
    this.syncTypeLabel = {
      api: formatMessage({
        id:
          'web.settings.accountSettings.salesforce.syncSettings.accordion.logEmailActivity.api',
      }),
      bcc: formatMessage({
        id:
          'web.settings.accountSettings.salesforce.syncSettings.accordion.logEmailActivity.bcc',
      }),
    };
  }

  componentDidUpdate(prevProps) {
    const {
      salesforceIntegration: { push_pitches: pushPitches },
    } = this.props;
    if (prevProps.salesforceIntegration.push_pitches !== pushPitches) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        syncType: pushPitches ? SalesforceSyncType.api : SalesforceSyncType.bcc,
      });
    }
  }

  getActivityFormModel = () => {
    const {
      contactIntegrationForSalesforce: {
        bcc_opportunities_enabled: bccOpportunitiesEnabled = false,
        bcc_create_unassigned_task: bccCreateUnassignedTask = false,
        bcc_leads_enabled: bccLeadsEnabled = false,
        bcc_contacts_enabled: bccContactsEnabled = false,
        bcc_duplicate_action: bccDuplicateAction = null,
        push_replies: pushReplies = false,
        rollup_opportunities: rollupOpportunities = false,
        rollup_leads: rollupLeads = false,
        rollup_contacts: rollupContacts = false,
        rollup_accounts: rollupAccounts = false,
      },
    } = this.props;
    return {
      bcc_opportunities_enabled: bccOpportunitiesEnabled,
      bcc_create_unassigned_task: bccCreateUnassignedTask,
      bcc_leads_enabled: bccLeadsEnabled,
      bcc_contacts_enabled: bccContactsEnabled,
      bcc_duplicate_action: bccDuplicateAction,
      push_replies: pushReplies,
      rollup_opportunities: rollupOpportunities,
      rollup_leads: rollupLeads,
      rollup_contacts: rollupContacts,
      rollup_accounts: rollupAccounts,
    };
  };

  setSyncType = (type) => this.setState({ syncType: type });

  render() {
    const {
      actionCreators: {
        saveBccForm,
        saveApiForm,
        openActivateApiConfirmationPopup,
        openActivateBccConfirmationPopup,
      },
      salesforceIntegration,
      isApplyTeamSettings,
      isSyncViaApi,
    } = this.props;
    const { syncType } = this.state;
    const isControlsDisabled = isApplyTeamSettings;

    return (
      <div className="log-email-activity-card">
        {!isControlsDisabled && (
          <div className="margin-top-15">
            <i className="tout-icon-tick" />
            <FormattedHTMLMessage
              id="web.settings.accountSettings.salesforce.syncSettings.yourSettings.logEmailSync"
              values={{
                syncType: isSyncViaApi
                  ? this.syncTypeLabel.api
                  : this.syncTypeLabel.bcc,
              }}
            />
          </div>
        )}
        <div className="sync-via-wrapper">
          <RadioGroup
            className="salesforce-sync-type"
            disabled={isControlsDisabled}
            items={[
              {
                label: this.syncTypeLabel.api,
                value: SalesforceSyncType.api,
              },
              {
                label: this.syncTypeLabel.bcc,
                value: SalesforceSyncType.bcc,
              },
            ]}
            onChange={this.setSyncType}
            selected={syncType}
          />
          <HoverTooltip
            place="right"
            textId="web.settings.accountSettings.salesforce.syncSettings.accordion.logEmailActivity.description"
            multiline
          >
            <i className="tout-icon-warning" />
          </HoverTooltip>
        </div>
        {syncType === SalesforceSyncType.bcc && (
          <BccForm
            saveBccForm={saveBccForm}
            openActivateBccConfirmationPopup={openActivateBccConfirmationPopup}
            integration={salesforceIntegration}
            disabled={isControlsDisabled}
            isSyncViaApi={isSyncViaApi}
          />
        )}
        {syncType === SalesforceSyncType.api && (
          <Fragment>
            <LogEmailActivityForm
              form={LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID}
              onSubmit={saveApiForm}
              initialValues={this.getActivityFormModel()}
              disabled={isControlsDisabled || !isSyncViaApi}
            />
            {!isControlsDisabled &&
              !isSyncViaApi && (
                <div className="right">
                  <Action
                    color={ButtonColors.green}
                    type="button"
                    onClick={openActivateApiConfirmationPopup}
                  >
                    <FormattedMessage id="common.activate" />
                  </Action>
                </div>
              )}
          </Fragment>
        )}
      </div>
    );
  }
}

LogEmailActivityCard.propTypes = {
  contactIntegrationForSalesforce: PropTypes.object,
  intl: intlShape.isRequired,
  salesforceIntegration: PropTypes.object,
  actionCreators: PropTypes.object.isRequired,
  isApplyTeamSettings: PropTypes.bool.isRequired,
  isSyncViaApi: PropTypes.bool.isRequired,
};

LogEmailActivityCard.defaultProps = {
  contactIntegrationForSalesforce: {},
  salesforceIntegration: {},
};

export default injectIntl(LogEmailActivityCard);
