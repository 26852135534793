import { Urls } from 'web/libs/constants';
import { baseDelete, basePost, basePut } from 'web/services/api';

export const saveAdvancedSearch = (params) =>
  new Promise((resolve, reject) => {
    basePost(Urls.conversation_filters, params, resolve, reject);
  });

export const updateAdvancedSearch = (params) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.conversation_filter.replace(':#id', params.savedSearchId),
      params,
      resolve,
      reject
    );
  });

export const deleteAdvancedSearch = (savedSearchId) =>
  new Promise((resolve, reject) => {
    baseDelete(
      Urls.conversation_filter.replace(':#id', savedSearchId),
      {},
      resolve,
      reject
    );
  });
