import { createSelector } from 'reselect';
import { formValueSelector as reduxFormValueSelector } from 'redux-form';

const userTimezone = (state) => state.userTimezone;
const timezones = (state) => state.timezones;
export const formValuesSelector = reduxFormValueSelector('timezoneForm');

export const getFormValues = (state) => ({
  language: formValuesSelector(state, 'language'),
  locale: formValuesSelector(state, 'locale'),
  timezone: formValuesSelector(state, 'timezone'),
});

export const getUserTimezoneSelectOption = createSelector(
  [userTimezone, timezones],
  (userTimezone, timezones) =>
    timezones
      .reduce((acc, timezoneGroup) => [...acc, ...timezoneGroup], [])
      .find(
        (timezone) =>
          timezone.value === userTimezone ||
          timezone.displayName === userTimezone
      )
);
