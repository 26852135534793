import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/navBar/item';

import Dropdown from 'web/topNavbar/components/dropdown/dropdown';
import { getNavBarItems } from 'web/topNavbar/helpers/questionMarkHelper';
import withDropdownToggler from 'web/topNavbar/hocs/dropdownToggleHoc';

const QuestionMarkIcon = (props) => {
  const navBarItems = getNavBarItems();
  const { toggleDropdown, showDropdown, hideDropdown } = props;

  return (
    <Item classNames="dropdown-toggle" onClick={toggleDropdown}>
      <Dropdown
        disableClick={!showDropdown}
        icon="icon-question"
        items={navBarItems}
        onOutsideClick={hideDropdown}
        show={showDropdown}
      />
    </Item>
  );
};

QuestionMarkIcon.propTypes = {
  hideDropdown: PropTypes.func.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
};

export default withDropdownToggler(QuestionMarkIcon);
