import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import TextButton from 'components/buttons/text';
import AnimateHeight from 'components/animateHeight';
import CommentPost from 'components/comment/post';
import ViewMore from 'components/viewMore';
import Comment from 'components/comment';
import './notesCard.scss';

const TWO_LINES = 44;

class NotesCard extends Component {
  constructor() {
    super();
    this.wrapperRef = null;
  }

  state = {
    addingNote: false,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps({ notes }) {
    if (this.state.addingNote && notes.length === this.props.notes.length + 1) {
      this._toggleNote();
    }
  }

  render() {
    const { notes } = this.props;
    const { addingNote } = this.state;

    return (
      <div className="notes-card">
        <div className="flex--horz-spread margin-bottom-half">
          <h3>
            <FormattedMessage id="common.notes" />
          </h3>
          {!addingNote && (
            <TextButton onClick={this._toggleNote} underline>
              <FormattedMessage id="common.add" />
            </TextButton>
          )}
        </div>
        <AnimateHeight all={addingNote} height={0}>
          <CommentPost onCancel={this._toggleNote} onDone={this._createNote} />
        </AnimateHeight>
        <span ref={this._setRef}>
          {notes.length > 0 && (
            <ViewMore
              className="notes-card-notes"
              itemRenderer={this.renderNote}
              items={notes}
              showCount
              showLessTextId="web.notes.showLess"
              showMoreTextId="web.notes.showMore"
            />
          )}
          {!notes.length &&
            !addingNote && <FormattedMessage id="web.notes.noNotes" />}
        </span>
      </div>
    );
  }

  _setRef = (node) => (this.wrapperRef = node);

  _toggleNote = () =>
    this.setState(({ addingNote }) => ({ addingNote: !addingNote }));

  renderNote = ({ content, id, updatedAt, userName }, index) => {
    const { deleteNote, editNote } = this.props;

    return (
      <Comment
        allowDelete
        allowEdit
        className={classNames('notes-card-note', {
          'margin-top-most': index > 0,
        })}
        comment={content}
        datetime={updatedAt}
        id={id}
        initHeight={TWO_LINES}
        key={`notes-card-note-${id}`}
        onDelete={deleteNote}
        onDone={editNote}
        userName={userName}
      />
    );
  };

  _createNote = (...args) => {
    const { createNote } = this.props;
    const scrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    };

    if (this.wrapperRef) {
      this.wrapperRef.scrollIntoView(scrollIntoViewOptions);
    }

    createNote(...args);
  };
}

NotesCard.propTypes = {
  createNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  editNote: PropTypes.func.isRequired,
  notes: PropTypes.array.isRequired,
};

NotesCard.defaultProps = {};

export default NotesCard;
