import ActionTypes from 'web/people/libs/peopleActionTypes';
import { LocalStorageKeys } from 'web/libs/constants';

export const getPeopleTabSettings = () => (dispatch) => {
  const slideOutMenuOpen = ['true', null].includes(
    window.localStorage.getItem(LocalStorageKeys.people.slideOutMenuOpen)
  );
  dispatch({
    type: ActionTypes.peopleTabSettings.setSlideOutMenuOpen,
    state: slideOutMenuOpen,
  });
};

export const setSlideOutMenuOpen = (open = true) => (dispatch) => {
  window.localStorage.setItem(LocalStorageKeys.people.slideOutMenuOpen, open);

  dispatch({
    type: ActionTypes.peopleTabSettings.setSlideOutMenuOpen,
    state: open,
  });
};
