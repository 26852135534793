export const TeamMembersRoles = {
  admin: 'ADMIN_TEAM_MEMBER_ROLE',
  user: 'USER_TEAM_MEMBER_ROLE',
};

export const TeamMembersTableColumns = {
  actions: 'ACTIONS',
  createdAt: 'CREATED_AT',
  email: 'EMAIL',
  name: 'NAME',
  role: 'ROLE',
};

export const TeamMembersPopupIds = {
  addTeamMembersToTeam: 'TEAM_MEMBERS_ADD_TEAM_MEMBERS_TO_TEAM',
  addTeamMembersToTeams: 'TEAM_MEMBERS_ADD_TEAM_MEMBERS_TO_TEAMS',
  deleteTeamMembersConfirmation:
    'TEAM_MEMBERS_DELETE_TEAM_MEMBERS_CONFIRMATION',
  editTeams: 'TEAM_MEMBERS_EDIT_TEAMS',
};

export const ADD_MEMBERS_TO_TEAMS_FORM_ID =
  'TEAM_MANAGEMENT_ADD_MEMBERS_TO_TEAMS_FORM';
export const ADD_MEMBERS_TO_TEAM_FORM_ID =
  'TEAM_MANAGEMENT_ADD_MEMBERS_TO_TEAM_FORM';
export const EDIT_TEAMS_FORM_ID = 'TEAM_MANAGEMENT_EDIT_TEAMS_FORM';
export const COLUMN_SETTINGS_TEAM_MEMBERS_TABLE_ID =
  'columns_settings_team_members_table_id';
export const COLUMN_SETTINGS_ALL_MEMBERS_TABLE_ID =
  'columns_settings_all_members_table_id';
