import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { PopupSize, ProgressStatus } from 'libs/constantsShared';
import Popup, { PopupBackgroundColors } from 'components/popups';
import DynamicFields, {
  DYNAMIC_FIELDS_POPUP_WIDTH,
} from 'web/dynamicFields/components/dynamicFields';
import DynamicFieldsIncorrect, {
  DYNAMIC_FIELDS_INCORRECT_POPUP_WIDTH,
} from 'web/dynamicFields/components/dynamicFieldsIncorrect';
import ImageInsert from 'components/imageInsert';
import CreateTemplate from 'web/templates/components/createTemplate';
import DynamicFieldNumberChooser from 'web/dynamicFields/components/dynamicFieldNumberChooser';
import {
  GlobalAlertIds,
  GlobalPopupIds,
} from 'web/emailComposeBody/libs/emailComposeBodyConstants';
import {
  clearEmailComposeAlert,
  closeEmailComposePopup,
} from 'web/emailComposeBody/actionCreators/popupActionCreators';
import { createTemplate } from 'web/emailComposeBody/actionCreators/saveAsTemplateActionCreators';
import {
  dynamicFieldsSelected,
  insertDaysFromNowDynamicField,
  setDynamicFieldsDaysFromNow,
} from 'web/dynamicFields/actionCreators/dynamicFieldsActionCreators';
import {
  imageInsertInsert,
  imageInsertOnChange,
  imageInsertUpdate,
  imageInsertUploadFiles,
} from 'web/emailComposeBody/actionCreators/imageInsertActionCreators';
import { getDefaultDynamicFieldsActiveCategory } from './selectors/emailComposeBodySelectors';
import { updateCreateTemplateState } from 'web/templates/actionCreators/templatesCreateActionCreators';
import { getUpdatableCategories } from 'web/templates/selectors/templatesSelectors';
import { ModalComponentIds } from 'web/composeWindow/libs/composeWindowConstants';
import {
  uploadFiles,
  grabSelectedIds,
  toggleTrackedContent,
  bulkDeleteFiles,
  closeFileAttachmentModal,
  addAttachmentToEmail,
} from 'web/composeWindow/actionCreators/fileActionCreators';
import { getEditorInstances } from 'web/composeWindow/selectors/composeWindowSelectors';
import { attachFilesAndContentDetails } from 'web/tinyMceEditor/components/tinyMceEditorPopups';
import { boxName } from 'web/integrations/box/libs/boxConstants';
import { getContentPartnersMetadata } from 'web/modals/addContent/actionCreators/contentActionCreators';
import HighSpotContentWindow from 'web/tinyMceEditor/components/HighSpotContentWindow';
import { defaultPartnerName } from 'web/contentPartners/libs/contentPartnersConstants';
import UnfilledPromptInAutomaticEmail, {
  UNFILLED_PROMPT_IN_AUTOMATIC_EMAIL_POPUP_WIDTH,
} from '../dynamicFields/components/unfilledPromptInAutomaticEmail/unfilledPromptInAutomaticEmail';

class EmailComposePopupContainer extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      addAttachmentToEmail: (files, id) =>
        dispatch(addAttachmentToEmail(files, id)),
      bulkDeleteFiles: (files, clearDeletedTableRows) =>
        dispatch(bulkDeleteFiles(files, clearDeletedTableRows)),
      closeFileAttachmentModal: () => dispatch(closeFileAttachmentModal()),
      closePopup: () => dispatch(closeEmailComposePopup()),
      createTemplate: () => dispatch(createTemplate()),
      dynamicFieldsSelected: (tag) => dispatch(dynamicFieldsSelected(tag)),
      getContentPartnersMetadata: (url) =>
        dispatch(getContentPartnersMetadata(url)),
      grabSelectedIds: (ids) => dispatch(grabSelectedIds(ids)),
      hideAlert: () => dispatch(clearEmailComposeAlert()),
      imageInsertInsert: () => dispatch(imageInsertInsert()),
      imageInsertOnChange: (data) => dispatch(imageInsertOnChange(data)),
      imageInsertUpdate: () => dispatch(imageInsertUpdate()),
      imageInsertUploadFiles: (files) =>
        dispatch(imageInsertUploadFiles(files)),
      insertDaysFromNowDynamicField: (days, tag) =>
        dispatch(insertDaysFromNowDynamicField(days, tag)),
      setDynamicFieldsDaysFromNow: (days) =>
        dispatch(setDynamicFieldsDaysFromNow(days)),
      toggleTrackedContent: (tracked) =>
        dispatch(toggleTrackedContent(tracked)),
      updateCreateTemplateState: (data) =>
        dispatch(updateCreateTemplateState(data)),
      uploadFiles: (files, tableRef) => dispatch(uploadFiles(files, tableRef)),
    };
  }

  render() {
    const { children } = this.props;
    const object = this._getGlobalPopupObject();

    return (
      <div className="emailComposePopupContainer">
        {object.children ? <Popup inner {...object} /> : null}
        {children}
      </div>
    );
  }

  _getGlobalPopupObject() {
    return {
      ...this._getMainComponent(),
      alert: this._getAlert(),
      getImageUrl,
      loading: this.props.loading,
    };
  }

  _getAlert() {
    const { alert } = this.props;
    const defaultAlert = {
      color: PopupBackgroundColors.red,
      onClose: this.actionCreators.hideAlert,
    };

    switch (alert.id) {
      case GlobalAlertIds.createNewTemplate:
        return {
          ...defaultAlert,
          textId: 'web.campaignsTab.saveAsTemplate.failed',
        };
      case GlobalAlertIds.openSaveAsTemplate:
        return {
          ...defaultAlert,
          textId: 'web.emails.alert.getTemplateCategoriesFail',
        };
      case GlobalAlertIds.imageInsertFileTooLarge:
        return {
          ...defaultAlert,
          textId: 'web.imageInsert.alert.fileTooLarge',
        };
      case GlobalAlertIds.imageInsertFileTypeInvalid:
        return {
          ...defaultAlert,
          textId: 'web.imageInsert.alert.fileTypeInvalid',
        };
      case GlobalAlertIds.imageInsertIssue:
        return { ...defaultAlert, textId: 'web.imageInsert.alert.issue' };
      default:
        return null;
    }
  }

  _getMainComponent() {
    const { popup, selectedDynamicFieldsCategory } = this.props;

    switch (popup.id) {
      case GlobalPopupIds.dynamicFields:
        return {
          children: (
            <DynamicFields
              fields={this.props.dynamicFields}
              onSelected={this.actionCreators.dynamicFieldsSelected}
              selectedDynamicFieldsCategory={selectedDynamicFieldsCategory}
            />
          ),
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaignsTab.insertDynamicField.title',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: DYNAMIC_FIELDS_POPUP_WIDTH,
        };
      case GlobalPopupIds.dynamicFieldsIncorrect:
        return {
          children: (
            <DynamicFieldsIncorrect
              fieldsIncorrect={popup.data.incorrectDynamicFields}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.closePopup,
            primaryTextId: 'common.ok',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaignsTab.insertDynamicFieldsIncorrect.title',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: DYNAMIC_FIELDS_INCORRECT_POPUP_WIDTH,
        };
      case GlobalPopupIds.imageInsert: {
        const { imageInsertEditState, imageInsertFile, showHint } = this.props;
        let onPrimary;
        if (imageInsertEditState) {
          onPrimary = this.actionCreators.imageInsertUpdate;
        } else if (
          imageInsertFile &&
          imageInsertFile.status === ProgressStatus.finished
        ) {
          onPrimary = this.actionCreators.imageInsertInsert;
        }
        return {
          children: (
            <ImageInsert
              editState={imageInsertEditState}
              file={imageInsertFile}
              isTipVisible={showHint}
              onChange={this.actionCreators.imageInsertOnChange}
              uploadFiles={this.actionCreators.imageInsertUploadFiles}
            />
          ),
          footer: {
            onPrimary,
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: imageInsertEditState
              ? 'common.update'
              : 'common.insert',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId:
              (imageInsertEditState && 'web.imageInsert.titleUpdate') ||
              'web.imageInsert.title',
          },
          onOutsideClick:
            (!imageInsertFile && this.actionCreators.closePopup) || null, // disable during upload
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.saveAsTemplate: {
        const { createTemplateState, templatesCategories } = this.props;

        return {
          children: (
            <CreateTemplate
              categories={templatesCategories}
              createTemplateState={createTemplateState}
              updateState={this.actionCreators.updateCreateTemplateState}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.createTemplate,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled:
              createTemplateState.name.trim().length <= 0 ||
              createTemplateState.categoryId <= 0,
            primaryTextId: 'common.save',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'common.saveAsTemplate',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.dynamicFieldsDaysFromNow: {
        const { dynamicFieldsDaysFromNow } = this.props;
        const {
          data: { field, messageId },
        } = popup;

        return {
          children: (
            <DynamicFieldNumberChooser
              dynamicField={field}
              messageId={messageId}
              onChange={this.actionCreators.setDynamicFieldsDaysFromNow}
              onSubmit={this.actionCreators.insertDaysFromNowDynamicField}
              value={dynamicFieldsDaysFromNow}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.insertDaysFromNowDynamicField.bind(
              this,
              dynamicFieldsDaysFromNow,
              field
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled: !dynamicFieldsDaysFromNow,
            primaryTextId: 'common.insert',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'dynamicFields.chooseNumberOfDaysTitle',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case GlobalPopupIds.unfilledPromptInAutomaticEmail:
        return {
          children: (
            <UnfilledPromptInAutomaticEmail
              notFilledPrompts={popup.data.notFilledPrompts}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.closePopup,
            primaryTextId: 'common.ok',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaignsTab.notFilledPrompt.title',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: UNFILLED_PROMPT_IN_AUTOMATIC_EMAIL_POPUP_WIDTH,
        };
      case ModalComponentIds.attachFilesAndContent: {
        const {
          editorInstances,
          popup: { editorId },
        } = this.props;
        const editorInstance = editorInstances[editorId];

        return attachFilesAndContentDetails({
          ...this.props,
          ...this.actionCreators,
          contentProvider: defaultPartnerName,
          editorId,
          editorInstance,
        });
      }
      case ModalComponentIds.attachBoxContents: {
        const {
          editorInstances,
          popup: { editorId },
        } = this.props;
        const editorInstance = editorInstances[editorId];

        return attachFilesAndContentDetails({
          ...this.props,
          ...this.actionCreators,
          contentProvider: boxName,
          editorId,
          editorInstance,
        });
      }
      case ModalComponentIds.highSpotContent: {
        const { popup, editorInstances } = this.props;

        return {
          children: HighSpotContentWindow({
            ...this.actionCreators,
            ...this.props,
            editorInstance: editorInstances[popup.editorId],
          }),
        };
      }
      default:
        return {};
    }
  }
}

EmailComposePopupContainer.defaultProps = {
  showHint: true,
};

EmailComposePopupContainer.propTypes = {
  alert: PropTypes.object.isRequired,
  children: PropTypes.node,
  createTemplateState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  dynamicFields: PropTypes.array,
  dynamicFieldsDaysFromNow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  imageInsertEditState: PropTypes.object,
  imageInsertFile: PropTypes.object,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  popup: PropTypes.object.isRequired,
  selectedDynamicFieldsCategory: PropTypes.string,
  showHint: PropTypes.bool,
  templatesCategories: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    alert: state.emailComposeAlert,
    boxContentFiles: state.boxContentFiles,
    content: state.content,
    contentPartners: state.contentPartners,
    createNewCampaignState: state.campaignsTabCreateNewCampaignState,
    createTemplateState: state.templatesCreateTemplateState,
    dynamicFields: state.dynamicFields,
    dynamicFieldsDaysFromNow: state.dynamicFieldsDaysFromNow,
    editorInstances: getEditorInstances(state),
    imageInsertEditState: state.emailComposeImageInsertEditState,
    imageInsertFile: state.emailComposeImageInsertFile,
    loading: state.emailComposePopupLoading,
    partnerMetadata: state.partnerMetadata,
    popup: state.emailComposePopup,
    selectedComposeFiles: state.selectedComposeFiles,
    selectedDynamicFieldsCategory: getDefaultDynamicFieldsActiveCategory(state),
    templatesCategories: getUpdatableCategories(state),
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(EmailComposePopupContainer);
