import { createSelector } from 'reselect';

const getEngageEvents = (state) => {
  const events = state.engageEvents;
  const orderedEvents = [];

  for (const id of events.ids) {
    orderedEvents.push(events.map[id]);
  }
  return orderedEvents;
};

const getEngageEventsExtraData = (state) => state.engageEventsExtraData;

export const formatEngageEvents = createSelector(
  getEngageEvents,
  getEngageEventsExtraData,
  (events, eventsExtraData) =>
    events.map((event) => {
      const extraData = eventsExtraData.find(
        ({ pitchId }) => pitchId === event.pitch_id
      );
      return { ...event, ...extraData };
    })
);
