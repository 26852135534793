/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import { BackgroundColors } from 'libs/constantsStyles';

export const i18nLanguagesOptions = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Deutsch' },
  { value: 'es', label: 'Espa\u00f1ol' },
  { value: 'fr', label: 'Fran\u00e7ais' },
  { value: 'pt', label: 'Portugu\u00eas' },
  { value: 'ja', label: '\u65e5\u672c\u8a9e' },
];

export const qaLanguagesOptions = [
  { value: 'hu', label: 'QA Language 1' },
  { value: 'af', label: 'QA Language 2' },
];

export const languagesOptions =
  process.env.TOUT_ENV !== 'production'
    ? i18nLanguagesOptions.concat(qaLanguagesOptions)
    : i18nLanguagesOptions;

export const localeOptions = [
  { value: 'en-AU', label: 'English (Australia)' },
  { value: 'en-CA', label: 'English (Canada)' },
  { value: 'en-IN', label: 'English (India)' },
  { value: 'en-IE', label: 'English (Ireland)' },
  { value: 'en-NZ', label: 'English (New Zealand)' },
  { value: 'en-PK', label: 'English (Pakistan)' },
  { value: 'en-PH', label: 'English (Philippines)' },
  { value: 'en-SG', label: 'English (Singapore)' },
  { value: 'en-SA', label: 'English (South Africa)' },
  { value: 'en-UK', label: 'English (United Kingdom)' },
  { value: 'en-US', label: 'English (United States)' },
  { value: 'de-DE', label: 'Deutsch (Deutschland)' },
  { value: 'de-LU', label: 'Deutsch (Luxemburg)' },
  { value: 'de-CH', label: 'Deutsch (Schweiz)' },
  { value: 'de-AT', label: 'Deutsch (\u00d6sterreich)' },
  { value: 'es-AR', label: 'espa\u00f1ol (Argentina)' },
  { value: 'es-BO', label: 'espa\u00f1ol (Bolivia)' },
  { value: 'es-CL', label: 'espa\u00f1ol (Chile)' },
  { value: 'es-CO', label: 'espa\u00f1ol (Colombia)' },
  { value: 'es-CR', label: 'espa\u00f1ol (Costa Rica)' },
  { value: 'es-EC', label: 'espa\u00f1ol (Ecuador)' },
  { value: 'es-SV', label: 'espa\u00f1ol (El Salvador)' },
  { value: 'es-ES', label: 'espa\u00f1ol (Espa\u00f1a)' },
  { value: 'es-US', label: 'espa\u00f1ol (Estados Unidos)' },
  { value: 'es-GT', label: 'espa\u00f1ol (Guatemala)' },
  { value: 'es-HN', label: 'espa\u00f1ol (Honduras)' },
  { value: 'es-MX', label: 'espa\u00f1ol (M\u00e9xico)' },
  { value: 'es-NI', label: 'espa\u00f1ol (Nicaragua)' },
  { value: 'es-PA', label: 'espa\u00f1ol (Panam\u00e1)' },
  { value: 'es-PY', label: 'espa\u00f1ol (Paraguay)' },
  { value: 'es-PE', label: 'espa\u00f1ol (Per\u00fa)' },
  { value: 'es-PR', label: 'espa\u00f1ol (Puerto Rico)' },
  { value: 'es-DO', label: 'espa\u00f1ol (Rep\u00fablica Dominicana)' },
  { value: 'es-UY', label: 'espa\u00f1ol (Uruguay)' },
  { value: 'es-VE', label: 'espa\u00f1ol (Venezuela)' },
  { value: 'fr-BE', label: 'fran\u00e7ais (Belgique)' },
  { value: 'fr-CA', label: 'fran\u00e7ais (Canada)' },
  { value: 'fr-FR', label: 'fran\u00e7ais (France)' },
  { value: 'fr-LU', label: 'fran\u00e7ais (Luxembourg)' },
  { value: 'fr-CH', label: 'fran\u00e7ais (Suisse)' },
  { value: 'pt-BR', label: 'portugu\u00eas (Brasil)' },
  { value: 'pt-PT', label: 'portugu\u00eas (Portugal)' },
  { value: 'ja-JP', label: '\u65e5\u672c\u8a9e (\u65e5\u672c)' },
];

export const defaultLocaleOptions = [
  { value: 'en-US', label: 'English (United States)' },
  { value: 'de-DE', label: 'Deutsch (Deutschland)' },
  { value: 'es-ES', label: 'espa\u00f1ol (Espa\u00f1a)' },
  { value: 'fr-FR', label: 'fran\u00e7ais (France)' },
  { value: 'pt-BR', label: 'portugu\u00eas (Brasil)' },
  { value: 'ja-JP', label: '\u65e5\u672c\u8a9e (\u65e5\u672c)' },
];

export const LABEL_WIDTH = 150;

export const successAlertOptions = {
  textId: 'web.settings.myProfile.alert.changesSaved',
  color: BackgroundColors.green,
  location: 'top',
  manual: true,
};

export const errorAlertOptions = {
  textId: 'web.settings.myProfile.alert.genericIssue',
  color: BackgroundColors.red,
  location: 'top',
  manual: true,
};
