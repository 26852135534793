import * as templateCategoriesReducer from './templateCategoriesReducer';
import * as templatesReducer from './templatesReducer';
import * as templatesSearchReducer from './templatesSearchReducer';
import * as templatesSidebarFilterReducer from './templatesSidebarFilterReducer';
import * as templatesTableReducer from './templatesTableReducer';
import * as templatesDataReducer from './templatesDataReducers';

const templatesRoot = Object.assign(
  {},
  templateCategoriesReducer,
  templatesDataReducer,
  templatesReducer,
  templatesSearchReducer,
  templatesSidebarFilterReducer,
  templatesTableReducer
);

export default templatesRoot;
