import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextArea from 'components/inputs/textarea';

class PersonDetailsUnsubscribeReason extends Component {
  render() {
    return (
      <div className="person-details-unsubscribe-reason">
        <FormattedMessage id="web.settings.unsubscribe.detailsInfo.reasonDescription" />
        <TextArea onChange={this.onChange} rows={3} />
      </div>
    );
  }

  onChange = (val) => {
    const { onChange } = this.props;
    const value = val.trim(); // remove spaces

    onChange(value);
  };
}

PersonDetailsUnsubscribeReason.propTypes = {
  onChange: PropTypes.func,
};

PersonDetailsUnsubscribeReason.defaultProps = {
  onChange: () => {},
};

export default PersonDetailsUnsubscribeReason;
