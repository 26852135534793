import GlobalBannerAlertsActionTypes from '../actionCreators/globalBannerAlertsActionTypes';

export const globalBannerAlertsState = (
  state = [],
  { type, alert, id, alerts }
) => {
  switch (type) {
    case GlobalBannerAlertsActionTypes.addAlert:
      return [...state, alert];
    case GlobalBannerAlertsActionTypes.closeAlertById: {
      const newState = state.filter((alert) => alert.id !== id);
      return newState;
    }
    case GlobalBannerAlertsActionTypes.setAlerts:
      return alerts;
    case GlobalBannerAlertsActionTypes.clearAlerts:
      return [];
    default:
      return state;
  }
};
