import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CampaignDayCard from '../campaignDayCard';
import Button from 'components/buttons/action';
import { CampaignStepActions, DayCardTypes } from 'web/libs/constants';
import './campaignSetupVertical.scss';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

class CampaignSetupVertical extends Component {
  constructor() {
    super();

    this.newCampaignDayCardId = 'newCampaignDayCard';
    this.CAMPAIGN_DAY_CARD_NEW_WIDTH = 300;
    this.SMOOTH_SCROLL_SIZE = 10;
  }

  componentDidUpdate({ addingNewCampaignDay }) {
    if (
      this.props.addingNewCampaignDay === true &&
      addingNewCampaignDay === false
    ) {
      this._smoothScroll();
    }
  }

  render() {
    const {
      addingNewCampaignDay,
      attachments,
      actionCreators,
      campaignDayEditing,
      campaignDayLoading,
      campaignEditStates,
      campaignTemplates,
      ckeBodies,
      editingSteps,
      newDayNumber,
      selectedCampaign,
      timezone,
      timezones,
      userCanEditCampaigns,
    } = this.props;
    const { dayStepInfo, firstEmailStepId } = this._getDayStepInfo();

    return (
      <div className="campaignSetupVertical">
        {dayStepInfo.map((element) => (
          <CampaignDayCard
            attachments={attachments}
            actionCreators={actionCreators}
            campaignEditStates={campaignEditStates}
            campaignTemplates={campaignTemplates}
            ckeBodies={ckeBodies}
            type={DayCardTypes.standardCard}
            day={element.day}
            dayEditingCount={campaignDayEditing[element.day] || 0}
            editingSteps={editingSteps}
            firstEmailStepId={firstEmailStepId}
            key={`campaign-steps-${element.day}`}
            loading={!!campaignDayLoading[element.day]}
            newDayNumber={newDayNumber}
            selectedCampaign={selectedCampaign}
            steps={element.steps}
            timezone={timezone}
            timezones={timezones}
            wide
            userCanEditCampaigns={userCanEditCampaigns}
          />
        ))}
        {addingNewCampaignDay || dayStepInfo.length === 0 ? (
          <CampaignDayCard
            type={
              dayStepInfo.length === 0
                ? DayCardTypes.defaultDayOneCard
                : DayCardTypes.newDayCard
            }
            actionCreators={actionCreators}
            campaignEditStates={campaignEditStates}
            campaignTemplates={campaignTemplates}
            id={this.newCampaignDayCardId}
            loading={!!campaignDayLoading[newDayNumber]}
            steps={[]}
            attachments={attachments}
            ckeBodies={ckeBodies}
            editingSteps={editingSteps}
            newDayNumber={newDayNumber}
            selectedCampaign={selectedCampaign}
            timezone={timezone}
            timezones={timezones}
            wide
            userCanEditCampaigns={userCanEditCampaigns}
          />
        ) : null}
        <div className="add-day-btn-container">
          {userCanEditCampaigns ? (
            <Button
              color="blue"
              size="medium"
              onClick={actionCreators.addingNewDay}
              classes="add-day-btn"
              disabled={addingNewCampaignDay || dayStepInfo.length === 0}
            >
              <FormattedMessage id="web.campaigns.addADay" />
            </Button>
          ) : null}
        </div>
      </div>
    );
  }

  _getDayStepInfo = () => {
    const { selectedCampaign } = this.props;
    const dayStepInfo = [];

    const orderedSteps = sortBy(selectedCampaign.steps, ['day', 'order']);
    const days = uniq(orderedSteps.map((step) => step.day));
    const firstEmailStep =
      orderedSteps.find(
        (step) =>
          step.action === CampaignStepActions.email ||
          step.action === CampaignStepActions.emailTask
      ) || {};

    for (const day of days) {
      const dayStep = {};
      dayStep.day = day;
      dayStep.steps = orderedSteps.filter((step) => step.day === day);
      dayStepInfo.push(dayStep);
    }
    return { dayStepInfo, firstEmailStepId: firstEmailStep.id };
  };

  _smoothScrollUp = (div, offset, initOffset) => {
    const offsetNormalized =
      offset > this.SMOOTH_SCROLL_SIZE ? this.SMOOTH_SCROLL_SIZE : offset;

    if (offsetNormalized > 0 && div.scrollTop < initOffset) {
      div.scrollTop += offsetNormalized; // eslint-disable-line no-param-reassign

      setTimeout(
        window.requestAnimationFrame(() =>
          this._smoothScrollUp(
            div,
            offset - this.SMOOTH_SCROLL_SIZE,
            initOffset
          )
        ),
        this.SMOOTH_SCROLL_SIZE / 2
      );
    }
  };

  _smoothScroll = () => {
    const scrollingDiv = document.getElementsByClassName(
      'campaign-tab-view'
    )[0];
    const stepDiv = document.getElementById(this.newCampaignDayCardId);
    const offset =
      stepDiv.offsetTop + stepDiv.offsetTop - scrollingDiv.scrollTop;

    this._smoothScrollUp(scrollingDiv, offset, offset);
  };
}

CampaignSetupVertical.propTypes = {
  campaignDayEditing: PropTypes.object.isRequired,
  campaignDayLoading: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  actionCreators: PropTypes.object.isRequired,
  attachments: PropTypes.object.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  addingNewCampaignDay: PropTypes.bool,
  editingSteps: PropTypes.object,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedCampaign: PropTypes.object,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignSetupVertical.defaultProps = {
  addingNewCampaignDay: undefined,
  editingSteps: undefined,
  newDayNumber: undefined,
  selectedCampaign: undefined,
  userCanEditCampaigns: true,
};

export default CampaignSetupVertical;
