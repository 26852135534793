import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import I18N from 'languages';
import './userInfo.scss';

const UserInfo = ({
  account,
  className,
  intl: { formatMessage },
  objectCreatedAt,
  user,
}) => {
  const classes = classNames('campaign-user-info', className);
  const createName =
    account.id === user.id ? formatMessage({ id: 'common.you' }) : user.name;
  const displayDate = I18N.getLocalizedDate(
    objectCreatedAt,
    I18N.DateFormats.DATE_LONG
  );

  return (
    <div className={classes}>
      <div>
        <FormattedHTMLMessage
          id="web.campaigns.userInfo.createdBy"
          values={{ createName, displayDate }}
        />
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  account: PropTypes.object.isRequired,
  className: PropTypes.string,
  intl: intlShape.isRequired,
  objectCreatedAt: PropTypes.string,
  user: PropTypes.object,
};

UserInfo.defaultProps = {
  className: '',
  objectCreatedAt: '',
  user: {},
};

export default injectIntl(UserInfo);
