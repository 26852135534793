import React from 'react';
import { FormattedMessage } from 'react-intl';
import './nonAdminCredentials.scss';
import PropTypes from 'prop-types';

const NonAdminCredentials = (props) => {
  const { munchkin } = props;

  return (
    <div>
      <h3>
        <FormattedMessage id="web.marketoSettings.yourMunchkinId" />
      </h3>
      <p className="margin-top-half">
        <FormattedMessage id="web.marketoSettings.nonAdminConnectedMessage" />
      </p>
      <div className="munchkin-content">
        <span className="text-medium">
          <FormattedMessage id="web.marketoSettings.munchkinId" />
        </span>
        <span className="munchkin-id-label input-text">{munchkin}</span>
      </div>
    </div>
  );
};

NonAdminCredentials.propTypes = {
  munchkin: PropTypes.string.isRequired,
};

export default NonAdminCredentials;
