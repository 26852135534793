import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddToCampaignSelectedPeople from './addToCampaignSelectedPeople';
import AddToCampaignFilter from './addToCampaignFilter';
import AddToCampaignTable from './addToCampaignTable';

class AddToCampaign extends Component {
  render() {
    const {
      campaigns,
      campaignsColumns,
      campaignsSelectedId,
      campaignsOnSelect,
      filterItems,
      filterMessage,
      filterMessageKey,
      filterOnChange,
      filterSelectedId,
      selectedMessageKey,
      selectedMessageValues,
    } = this.props;

    return (
      <div>
        <AddToCampaignSelectedPeople
          selectedMessageKey={selectedMessageKey}
          selectedMessageValues={selectedMessageValues}
        />
        <AddToCampaignFilter
          items={filterItems}
          message={filterMessage}
          messageKey={filterMessageKey}
          onChange={filterOnChange}
          selectedId={filterSelectedId}
        />
        <AddToCampaignTable
          columns={campaignsColumns}
          campaigns={campaigns}
          onSelect={campaignsOnSelect}
          selectedId={campaignsSelectedId}
        />
      </div>
    );
  }
}

AddToCampaign.propTypes = {
  campaigns: PropTypes.array.isRequired,
  campaignsColumns: PropTypes.array.isRequired,
  campaignsSelectedId: PropTypes.number,
  campaignsOnSelect: PropTypes.func.isRequired,
  selectedMessageKey: PropTypes.string.isRequired,
  selectedMessageValues: PropTypes.object,
  filterItems: PropTypes.array.isRequired,
  filterMessage: PropTypes.string,
  filterMessageKey: PropTypes.string,
  filterOnChange: PropTypes.func.isRequired,
  filterSelectedId: PropTypes.number.isRequired,
};

AddToCampaign.defaultProps = {
  selectedMessageValues: {},
};

export default AddToCampaign;
