import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { getCampaignTemplates as getCampaignTemplatesCall } from '../services/templateService';
import { getActiveCampaigns as getActiveCampaignsCall } from '../services/campaignService';

export const getCampaignTemplates = (campaignId) => (dispatch) => {
  dispatch({ type: ActionTypes.campaignsTab.data.campaignTemplatesLoading });
  getCampaignTemplatesCall(campaignId).then((data) => {
    dispatch({
      type: ActionTypes.campaignsTab.data.campaignTemplates,
      data,
    });
    dispatch({ type: ActionTypes.campaignsTab.data.campaignTemplatesLoaded });
  });
};

export function getActiveCampaigns() {
  return (dispatch) => {
    getActiveCampaignsCall().then((data) => {
      dispatch({
        type: ActionTypes.api.activeCampaigns.success,
        data,
      });
    });
  };
}
