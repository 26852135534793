import { baseGet, basePut } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const releaseNotesLimit = 5;

export const getReleaseNotes = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.releaseNotes, { limit: releaseNotesLimit }, resolve, reject);
  });

export const setReleaseNotesRead = (userId) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.userCache.replace(':#userId', userId),
      { cache_type: 'release_notes' },
      resolve,
      reject
    );
  });
