import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import CommandCenterEmailsContainer from 'web/commandCenter/containers/commandCenterEmailsContainer';
import TwoPaneLayout from 'web/shared/components/twoPaneLayout/twoPaneLayout';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import CommandCenterSidebarContainer from 'web/commandCenter/containers/commandCenterSidebarContainer';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import EmailsAlert from 'web/commandCenter/components/commandCenterEmailsAlert';

const EmailsTab = ({ viewAlert, actionCreators }) => (
  <div key="emails-tab-container">
    <EmailsAlert actionCreators={actionCreators} alert={viewAlert} />
    <TwoPaneLayout>
      <CommandCenterSidebarContainer />
      <CommandCenterEmailsContainer />
    </TwoPaneLayout>
  </div>
);

EmailsTab.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  viewAlert: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    viewAlert: state.viewAlert,
  };
}

const mapDispatchToProps = {
  closeViewAlert,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  withCommonModals,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  injectIntl
)(EmailsTab);
