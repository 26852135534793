import React from 'react';
import PropTypes from 'prop-types';
import './taskEventType.scss';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

const TaskEventType = (props) => {
  const { event, taskTypeAction } = props;
  const { taskType, taskAction } = taskTypeAction(event);

  return (
    <div className="task-event-icon">
      <div
        className={classNames(
          { [`${taskType}-icon`]: taskType },
          { 'event-icon-wrapper': taskType }
        )}
        onClick={taskAction}
      />
      {taskType && <FormattedHTMLMessage id={`common.${taskType}`} />}
    </div>
  );
};

TaskEventType.propTypes = {
  event: PropTypes.object,
  eventType: PropTypes.string,
  taskTypeAction: PropTypes.func,
  eventAction: PropTypes.func,
};

export default TaskEventType;
