import apiHelper from 'web/services/apiHelper';
import {
  setSource,
  showGongWindowMode,
  trackEvent,
  openGongPopup,
} from './popupActionCreators';
import { GongEvents } from 'web/libs/mixpanelEvents';
import {
  setUser,
  setUserMetric,
} from 'web/user/actionCreators/userActionCreators';

export function gongStartup(windowMode, userMetric, user, sourceOpener) {
  return (dispatch) => {
    userMetric = windowMode
      ? userMetric
      : window.toutAppBootstrap.userMetricsData;
    user = windowMode ? user : window.toutAppBootstrap.accountData;

    dispatch(setUser(user));
    dispatch(setUserMetric(userMetric));
    dispatch(setSource(sourceOpener, windowMode));

    if (document.querySelector('meta[name="csrf-token"]')) {
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');

      if (csrfToken) {
        apiHelper.setCsrfToken(csrfToken);
      }
    }

    dispatch(trackEvent(GongEvents.open));
    dispatch(openGongPopup());

    if (windowMode) {
      dispatch(showGongWindowMode());
    }
  };
}
