import merge from 'lodash/merge';
import * as campaignsAlerts from './campaignsAlertsReducer';
import * as campaignsPeopleData from './campaignsPeopleReducer';
import * as campaignsPeopleSearchData from './campaignsPeopleSearchReducer';
import * as campaignsCategoriesDataReducer from './campaignsCategoriesDataReducer';
import * as campaignsTabData from './campaignsTabDataReducer';
import * as campaignsTabState from './campaignsTabStateReducer';

const campaignsRoot = merge(
  {},
  campaignsAlerts,
  campaignsPeopleData,
  campaignsPeopleSearchData,
  campaignsTabData,
  campaignsTabState,
  campaignsCategoriesDataReducer
);

export default campaignsRoot;
