import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Popup from 'components/popups';
import { getImageUrl } from 'web/libs/constants';
import { PopupSize } from 'libs/constantsShared';
import {
  closeSaveSearchPopup,
  changeAdvancedSearchSavedName,
  saveAdvancedSearch,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/saveAdvancedSearchActionCreators';

import SaveAdvancedSearchComponent from 'web/commandCenter/modules/emailGridAdvancedSearch/components/emailGridAdvancedSearch/saveSearchPopUpComponent';
import { CommandCenterPopupIds } from 'web/commandCenter/libs/commandCenterConstants';
import { clearPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import { getAlert } from 'web/commandCenter/modules/emailGridAdvancedSearch/containers/advancedSearchPopupContainerHelper';
import { PopupBackgroundColors } from 'components/popups';
import CloneAdvancedSearchConfirmation from 'web/commandCenter/modules/emailGridAdvancedSearch/components/emailGridAdvancedSearch/cloneAdvancedSearchConfirmation';

class AdvancedSearchPopupContainer extends Component {
  render() {
    const popupObject = this.getPopupObject();

    return [
      <div key="advanced-search-popups">
        {popupObject && popupObject.children ? (
          <Popup {...popupObject} refName="AdvancedSearchPopup" />
        ) : null}
      </div>,
    ];
  }

  getPopupObject = () => {
    const { actionCreators } = this.props;
    return {
      ...this.getPopupComponent(),
      alert: getAlert(this.props, actionCreators),
      getImageUrl,
      loading: this.props.popupLoading,
    };
  };

  getPopupComponent = () => {
    const { popup } = this.props;

    switch (popup) {
      case CommandCenterPopupIds.advancedSearchSave: {
        return {
          children: (
            <SaveAdvancedSearchComponent
              onSave={this.saveAdvancedSearch}
              onSearcNameChange={this.onChangeAdvancedSearchSavedName}
            />
          ),
          footer: {
            onPrimary: this.saveAdvancedSearch,
            onSecondary: this.closePopup,
            primaryTextId: 'common.save',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.closePopup,
            textId: 'web.commandCenter.advancedSearch.saveAs',
          },
          onOutsideClick: this.closePopup,
          size: PopupSize.small,
        };
      }
      case CommandCenterPopupIds.advancedSearchClone: {
        const backgroundColor = PopupBackgroundColors.blue;
        return {
          backgroundColor,
          children: <CloneAdvancedSearchConfirmation />,
          footer: {
            onPrimary: this.closePopup,
            primaryColor: `white-${backgroundColor}`,
            primaryTextId: 'common.ok',
          },
          onOutsideClick: this.closePopup,
          size: PopupSize.large,
        };
      }
      default:
        return null;
    }
  };

  closePopup = () => {
    const { closeSaveSearchPopup } = this.props.actionCreators;
    closeSaveSearchPopup();
  };

  onChangeAdvancedSearchSavedName = (name) => {
    const { changeAdvancedSearchSavedName } = this.props.actionCreators;
    changeAdvancedSearchSavedName({ name });
  };

  saveAdvancedSearch = () => {
    const { saveAdvancedSearch } = this.props.actionCreators;
    saveAdvancedSearch();
  };
}

AdvancedSearchPopupContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  popup: PropTypes.string.isRequired,
  popupLoading: PropTypes.bool.isRequired,
};

AdvancedSearchPopupContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  alert: state.popupAlert,
  popup: state.popup,
  popupLoading: state.popupLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeAdvancedSearchSavedName,
      clearPopupAlert,
      closeSaveSearchPopup,
      saveAdvancedSearch,
    },
    dispatch
  );

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  injectIntl
)(AdvancedSearchPopupContainer);
