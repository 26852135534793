import { baseGet, basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export const saveAdminSettingsUnsubscribes = (data) =>
  new Promise((resolve, reject) => {
    basePut(Urls.adminSettings, { admin_settings: data }, resolve, reject);
  });

export const saveUserSettingsUnsubscribes = (data) =>
  new Promise((resolve, reject) => {
    basePut(Urls.updateUnsubscribe, data, resolve, reject);
  });

export const getAdminSettings = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.adminSettings, {}, resolve, reject);
  });

export const saveUnsubscribeCustomDomain = (data) =>
  new Promise((resolve, reject) => {
    basePut(Urls.adminSettings, { admin_settings: data }, resolve, reject);
  });

export const savePluginUnsubscribe = (data) =>
  new Promise((resolve, reject) => {
    basePut(Urls.adminSettings, { admin_settings: data }, resolve, reject);
  });

export const saveAdminSettingsUnsubscribeAutoAppend = (data) =>
  new Promise((resolve, reject) => {
    basePut(Urls.adminSettings, { admin_settings: data }, resolve, reject);
  });
