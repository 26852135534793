import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  EditTemplateCondtions,
  EditTemplateMappingsValues,
  EditTemplateDynamicFields,
} from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import TextButton from 'components/buttons/text';
import './recommendDetails.scss';
import I18N from 'languages';

class RecommendDetails extends PureComponent {
  render() {
    const { edit, enableMappings, mappings } = this.props;

    return (
      <div className="edit-template-recommend-details">
        <div className="recommend-details-header-row">
          <h3 className="margin-bottom-half">
            <FormattedMessage id="web.editTemplateSlideOut.recommendCard.details.title" />
          </h3>
          {edit && (
            <TextButton onClick={edit} underline>
              <FormattedMessage id="common.edit" />
            </TextButton>
          )}
        </div>
        {(mappings.length > 0 && (
          <div>
            <div>
              <FormattedMessage id="web.editTemplateSlideOut.recommendCard.details.description" />
            </div>
            <div className="margin-top-half margin-bottom-half">
              <FormattedHTMLMessage
                id="web.editTemplateSlideOut.recommendCard.details.listHeader"
                values={{
                  all: enableMappings === EditTemplateMappingsValues.all,
                }}
              />
            </div>
            <ul>{this._getMappings(mappings)}</ul>
          </div>
        )) || (
          <FormattedMessage id="web.editTemplateSlideOut.recommendCard.details.noMappings" />
        )}
      </div>
    );
  }

  /* react-intl can not handle values with spaces */
  _getCondition(condition) {
    switch (condition) {
      case EditTemplateCondtions.notEqual:
        return 'notEquals';
      case EditTemplateCondtions.contains:
        return 'contains';
      case EditTemplateCondtions.doesNotContain:
        return 'notContain';
      case EditTemplateCondtions.equals:
      default:
        return 'equals';
    }
  }

  _getMappings(mappings) {
    return mappings.map(({ id, label, condition, value }, index) => {
      const localizedlabel =
        label && EditTemplateDynamicFields.indexOf(label) !== -1
          ? I18N.getConstStr('dynamicFields', label)
          : label;

      return (
        <li key={`recommend-details-${id || index}`}>
          <FormattedHTMLMessage
            id="web.editTemplateSlideOut.recommendCard.details.mapping"
            values={{
              condition: this._getCondition(condition),
              label: localizedlabel,
              value,
            }}
          />
        </li>
      );
    });
  }
}

RecommendDetails.propTypes = {
  edit: PropTypes.func,
  enableMappings: PropTypes.number.isRequired,
  mappings: PropTypes.array,
};

RecommendDetails.defaultProps = {
  edit: null,
  mappings: [],
};

export default RecommendDetails;
