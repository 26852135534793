import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';
import {
  closePersonDetails,
  openPersonDetails,
} from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';

const setNextPersonDetailsId = (id) => ({
  type: PersonDetailsActionTypes.setNextPersonDetails,
  id,
});

export const setNextPersonDetails = (id, options) => (dispatch, getState) => {
  if (id && id > 0) {
    dispatch(openPersonDetails(id, options));
  } else {
    dispatch(closePersonDetails());
  }

  dispatch(setNextPersonDetailsId(id));
};

export const unmountNext = () => ({
  type: PersonDetailsActionTypes.unmountNext,
});
