import React from 'react';
import { getImageUrl } from 'web/libs/constants';
import './templatesZeroState.scss';

const TemplatesZeroState = () => (
  <div className="templates-zero-state">
    <img src={getImageUrl('rocket-blueprint', 'svg')} alt="rocket blueprint" />
  </div>
);

export default TemplatesZeroState;
