import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoBox from 'components/infoBox';
import './nonHoverTooltip.scss';

class NonHoverTooltip extends Component {
  componentWillMount() {
    window.addEventListener('resize', this._hide, { once: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._hide);
  }

  render() {
    const {
      text: { titleId, bodyId },
    } = this.props;
    return (
      <div className="non-hover-tooltip">
        <InfoBox
          className="non-hover-tooltip-infobox"
          outsideClickHandler={this._hide}
          color="blue"
          style={this._getStyle()}
          rounded
          closeAnywhere
        >
          <div className="non-hover-tooltip-lead">
            <FormattedMessage id={titleId} />
          </div>
          <div className="non-hover-tooltip-body">
            <FormattedMessage id={bodyId} />
          </div>
        </InfoBox>
      </div>
    );
  }

  _getStyle() {
    const { position } = this.props;
    return {
      top: `${position.bottom}px`,
      left: `${position.left + position.width / 2}px`,
    };
  }

  _hide = () => this.props.actionCreators.toggleVisibility(false);
}

NonHoverTooltip.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired,
};

export default injectIntl(NonHoverTooltip);
