import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { openNewTab } from 'web/services/routerService';
import { Urls } from 'web/libs/routes';

class UpgradeSubscriptionPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
    } = this.props;

    return (
      <Popup
        footer={{
          onPrimary: this.upgrade,
          onSecondary: closePopup,
          primaryTextId: 'common.upgrade',
          secondaryTextId: 'common.cancel',
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.teamManagement.teamMembers.upgradeSubscription.title',
        }}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedMessage id="web.settings.adminSettings.teamManagement.teamMembers.upgradeSubscription.description" />
      </Popup>
    );
  }

  upgrade = () => {
    openNewTab(Urls.billingEdit);
  };
}

UpgradeSubscriptionPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

export default UpgradeSubscriptionPopup;
