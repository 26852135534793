import GroupActionTypes from '../libs/groupsActionTypes';
import { sortGroups } from '../helpers/groupsParsers';
import uniqBy from 'lodash/uniqBy';

export const groups = (
  state = [],
  { type = '', group = {}, groups: groupsData = [], id }
) => {
  switch (type) {
    case GroupActionTypes.set:
      return groupsData;
    case GroupActionTypes.update: {
      const index = state.findIndex(({ id: groupId }) => groupId === group.id);
      if (index > -1) {
        return sortGroups([
          ...state.slice(0, index),
          group,
          ...state.slice(index + 1),
        ]);
      } else {
        return sortGroups([...state, group]);
      }
    }
    case GroupActionTypes.updateBulk:
      return sortGroups(uniqBy([...groupsData, ...state], 'id'));
    case GroupActionTypes.remove: {
      const index = state.findIndex(({ id: groupId }) => groupId === id);
      return (
        (index > -1 && [...state.slice(0, index), ...state.slice(index + 1)]) ||
        state
      );
    }
    default:
      return state;
  }
};

export function groupsFetching(state = false, { type = '' }) {
  switch (type) {
    case GroupActionTypes.fetching:
      return true;
    case GroupActionTypes.fetchingDone:
      return false;
    default:
      return state;
  }
}

export function groupsInit(state = false, { type = '' }) {
  switch (type) {
    case GroupActionTypes.set:
      return true;
    default:
      return state;
  }
}
