import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import I18N from 'languages';

import {
  ValidationErrorMessageIds,
  ValidatorTypes,
  ValidationRegularExpressions,
} from '../libs/validatorsConstants';

export class ValidationError {
  constructor(validatorType, messageId, validatorParams = {}) {
    this.validatorType = validatorType;
    this.messageId = messageId;
    this.validatorParams = validatorParams;
  }
}

// Validators should return undefined for valid values
// https://redux-form.com/6.6.2/docs/api/field.md/

export const required = (messageId = ValidationErrorMessageIds.required) => (
  value
) =>
  value && value.trim()
    ? undefined
    : new ValidationError(ValidatorTypes.required, messageId);

export const requiredSelect = (
  messageId = ValidationErrorMessageIds.required
) => (value) =>
  (isObject(value) && !isEmpty(value)) || (isString(value) && value.trim())
    ? undefined
    : new ValidationError(ValidatorTypes.required, messageId);

export const requiredMultiSelect = (
  messageId = ValidationErrorMessageIds.required
) => (value) =>
  Array.isArray(value) && value.length
    ? undefined
    : new ValidationError(ValidatorTypes.required, messageId);

export const domainName = (
  messageId = ValidationErrorMessageIds.domainName
) => (value) =>
  value && !ValidationRegularExpressions.domainName.test(value)
    ? new ValidationError(ValidatorTypes.domainName, messageId, { value })
    : undefined;

export const integer = (messageId = ValidationErrorMessageIds.integer) => (
  value
) =>
  value && !ValidationRegularExpressions.integer.test(value)
    ? new ValidationError(ValidatorTypes.integer, messageId, { value })
    : undefined;

export const numbers = (messageId = ValidationErrorMessageIds.numbers) => (
  value
) =>
  value && !ValidationRegularExpressions.numbers.test(value)
    ? new ValidationError(ValidatorTypes.numbers, messageId, { value })
    : undefined;

export const minValue = (
  min,
  messageId = ValidationErrorMessageIds.minValue
) => (value) =>
  value && value < min
    ? new ValidationError(ValidatorTypes.minValue, messageId, {
        min: I18N.getLocalizedNumber(min),
        value,
      })
    : undefined;

export const maxValue = (
  max,
  messageId = ValidationErrorMessageIds.maxValue
) => (value) =>
  value && value > max
    ? new ValidationError(ValidatorTypes.maxValue, messageId, {
        max: I18N.getLocalizedNumber(max),
        value,
      })
    : undefined;

export const minLength = (
  min,
  messageId = ValidationErrorMessageIds.minLength
) => (value) =>
  value && value.length < min
    ? new ValidationError(ValidatorTypes.minLength, messageId, { min, value })
    : undefined;

export const regExpTest = (
  messageId = ValidationErrorMessageIds.regExpTest,
  regExp
) => (value) =>
  value && !regExp.test(value)
    ? new ValidationError(ValidatorTypes.regExp, messageId)
    : undefined;

export const email = (messageId = ValidationErrorMessageIds.email) => (value) =>
  value && !ValidationRegularExpressions.emailRFC2822.test(value.toLowerCase())
    ? new ValidationError(ValidatorTypes.email, messageId)
    : undefined;

export const sameAs = (
  messageId = ValidationErrorMessageIds.sameAs,
  sameAsFieldName
) => (value, fields) =>
  value !== fields[sameAsFieldName]
    ? new ValidationError(ValidatorTypes.sameAs, messageId, {
        sameAsFieldName,
        value,
      })
    : undefined;
