export const TasksTableDatesFilterValues = {
  custom: 'CUSTOM',
  nextSeven: 'NEXT_SEVEN',
  overdue: 'OVERDUE',
  today: 'TODAY',
  todayOverdue: 'TODAY_OVERDUE',
  tomorrow: 'TOMORROW',
  yesterday: 'YESTERDAY',
};

export const TasksTableStatusFilterValues = {
  completed: 'COMPLETED',
  uncompleted: 'UNCOMPLETED',
};

export const TasksTableColumns = {
  actions: 'ACTIONS',
  campaign: 'CAMPAIGN',
  company: 'COMPANY',
  due: 'DUE',
  markDone: 'MARK_DONE',
  owner: 'OWNER',
  person: 'PERSON',
  priority: 'PRIORITY',
  subject: 'SUBJECT',
  type: 'TYPE',
};

export const TasksTablePopupIds = {
  campaignTaskUndone: 'TASKS_TABLE_POPUP_CAMPAIGN_TASK_UNDONE',
  tasksConfirmationDelete: 'TASKS_TABLE_POPUP_TASK_CONFIRMATION_DELETE',
  tasksConfirmationDone: 'TASKS_TABLE_POPUP_TASK_CONFIRMATION_DONE',
  tasksConfirmationSkip: 'TASKS_TABLE_POPUP_TASK_CONFIRMATION_SKIP',
  tasksConfirmationSuccess: 'TASKS_TABLE_POPUP_TASK_CONFIRMATION_SUCCESS',
};

export const TasksTableAlertIds = {
  bulkFail: 'TASKS_TABLE_ALERT_BULK_FAIL',
};
