import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from 'components/layouts/page';
import Cards, { Direction } from 'components/layouts/cards';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import PageView from 'components/layouts/page/view';
import SalesforceConnectionCard from 'web/salesforce/components/salesforceConnectionCard';
import SalesforceMsiPackageInstallationCard from 'web/salesforce/components/salesforceMsiPackageInstallationCard';
import { isMsiActions } from 'web/user/selectors/userSelectors';
import './connectionsCustomizations.scss';

import SalesforceCustomizationContainer from 'web/modals/salesforceCustomization/container/salesforceCustomizationContainer';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';

class ConnectionsCustomizations extends Component {
  constructor(props) {
    super(props);

    const {
      actionCreators: { getSalesforceIntegration },
    } = props;
    getSalesforceIntegration();
  }

  getCards = () => {
    const {
      isMsiActions,
      actionCreators: {
        openConnectConfirmationPopup: onConnectSalesforce,
        openDisconnectConfirmationPopup: onDisconnectSalesforce,
      },
      ...other
    } = this.props;

    const getSalesforceCustomizationCard = () => {
      return (
        !isMsiActions && {
          children: React.createElement(SalesforceCustomizationContainer, {
            ...other,
            onConnectSalesforce,
            onDisconnectSalesforce,
            version: 2,
          }),
          id: 'SalesforceCustomizationContainer',
        }
      );
    };
    const getMsiPackageInstallationCard = () =>
      isMsiActions && {
        children: React.createElement(SalesforceMsiPackageInstallationCard, {}),
        id: 'SalesforceMsiPackageInstallation',
      };

    return [
      {
        children: React.createElement(SalesforceConnectionCard, {
          ...other,
          onConnectSalesforce,
          onDisconnectSalesforce,
        }),
        id: 'SalesforceConnectionCard',
      },
      getSalesforceCustomizationCard(),
      getMsiPackageInstallationCard(),
    ];
  };

  render() {
    const { fetching, loading } = this.props;
    return fetching ? (
      <LoadingSpinner imageUrl={getImageUrl} key="loading-spinner" />
    ) : (
      [
        <Page
          className="admin-settings-salesforce-connections-customizations"
          key="page-view"
        >
          <PageView className="admin-settings-salesforce-connections-customizations-page-view">
            <Cards
              backgroundColor={BackgroundColors.grayExtraLight}
              cards={this.getCards()}
              className="admin-settings-salesforce-connections-customizations-cards-container"
              direction={Direction.vertical}
              marginAround={FULL}
              marginBetween={FULL}
            />
          </PageView>
        </Page>,
        loading && (
          <LoadingSpinner imageUrl={getImageUrl} key="loading-spinner" />
        ),
      ]
    );
  }
}

ConnectionsCustomizations.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMsiActions: isMsiActions(state),
});

export default connect(
  mapStateToProps,
  {}
)(ConnectionsCustomizations);
