import merge from 'lodash/merge';
import ActionTypes from 'table/modules/actionTypes';
import { getTableKey } from 'table/modules/constants/localStorageKeys';

export function initLocalStorage(id) {
  return (dispatch) => {
    try {
      const stored = JSON.parse(window.localStorage.getItem(getTableKey(id)));
      dispatch({ type: ActionTypes.tableSetLocalStorage, stored });
    } catch (e) {
      dispatch({ type: ActionTypes.tableSetLocalStorage, stored: {} });
    }
  };
}

export function updateLocalStorage(key, updatedObj) {
  return (dispatch, getState) => {
    const { tableId, tableLocalStorage } = getState();
    try {
      const merged = merge({}, tableLocalStorage, { [key]: updatedObj });
      window.localStorage.setItem(getTableKey(tableId), JSON.stringify(merged));
      dispatch({ type: ActionTypes.tableSetLocalStorage, stored: merged });
    } catch (e) {
      // do nothing
    }
  };
}
