import { reset } from 'redux-form';
import EditIdentityActionTypes from '../libs/editIdentitySlideOutActionTypes';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  createIdentity as createIdentityActionCreator,
  updateIdentity as updateIdentityActionCreator,
} from 'web/user/actionCreators/identitiesActionCreators';
import {
  navigateToUrl,
  navigateToEditIdentitySlideout,
} from 'web/services/routerService';
import {
  EditIdentityPopupIds,
  IDENTITY_FORM_ID,
  SaveIdentityErrorKeys,
  SaveIdentitySuccessKey,
  SaveIdentityErrorCodeKeys,
} from '../libs/editIdentitySlideOutConstants';

export const closeEditIdentitySlideOut = () => (dispatch) =>
  dispatch({
    type: EditIdentityActionTypes.close,
  });

export const openEditIdentitySlideOut = () => (dispatch) =>
  dispatch({
    type: EditIdentityActionTypes.open,
  });

export const setIdentityCardEditMode = (editMode) => (dispatch) =>
  dispatch({
    editMode,
    type: EditIdentityActionTypes.setIdentityCardEditMode,
  });

export const setSignatureCardEditMode = (editMode) => (dispatch) =>
  dispatch({
    editMode,
    type: EditIdentityActionTypes.setSignatureCardEditMode,
  });

export const setDeliveryChannelCardEditMode = (editMode = false) => (
  dispatch
) =>
  dispatch({
    editMode,
    type: EditIdentityActionTypes.setDeliveryChannelCardEditMode,
  });

export const setDisplayIdentityCardEditMode = (editMode = false) => (
  dispatch
) =>
  dispatch({
    editMode,
    type: EditIdentityActionTypes.setDisplayIdentityCardEditMode,
  });

export const setEditIdentityId = (id) => (dispatch) =>
  dispatch({
    id,
    type: EditIdentityActionTypes.setEditIdentityId,
  });

export const setEditIdentityInfoInstance = (editingIdentity) => (dispatch) =>
  dispatch({
    editingIdentity,
    type: EditIdentityActionTypes.setEditIdentityInfoInstance,
  });

export const setEditIdentitySignatureInstance = (editingIdentitySignature) => (
  dispatch
) =>
  dispatch({
    editingIdentitySignature,
    type: EditIdentityActionTypes.setEditIdentitySignatureInstance,
  });

export const setEditIdentityDeliveryChannelInstance = (
  editingIdentityDeliveryChannel
) => (dispatch) =>
  dispatch({
    editingIdentityDeliveryChannel,
    type: EditIdentityActionTypes.setEditIdentityDeliveryChannelInstance,
  });

export const setLoading = (loading = true) => (dispatch) =>
  dispatch({
    loading,
    type: EditIdentityActionTypes.setLoading,
  });

export const showAlert = (messageKey = SaveIdentityErrorKeys.couldNotSave) => (
  dispatch
) =>
  dispatch({
    messageKey,
    type: EditIdentityActionTypes.showAlert,
  });

export const showWarning = (
  messageKey = SaveIdentityErrorKeys.invalidMobileNumber
) => (dispatch) =>
  dispatch({
    messageKey,
    type: EditIdentityActionTypes.showWarning,
  });

export const clearWarning = () => (dispatch) =>
  dispatch({
    type: EditIdentityActionTypes.clearWarning,
  });

export const clearAlert = () => (dispatch) =>
  dispatch({
    type: EditIdentityActionTypes.clearAlert,
  });

export const showSuccessBar = (messageKey = SaveIdentitySuccessKey) => (
  dispatch
) =>
  dispatch({
    messageKey,
    type: EditIdentityActionTypes.showSuccessBar,
  });

export const clearSuccessBar = () => (dispatch) =>
  dispatch({
    type: EditIdentityActionTypes.clearSuccessBar,
  });

export const clearWarningBar = () => (dispatch) => {
  dispatch({
    type: EditIdentityActionTypes.clearWarningBar,
  });
};

export const identityEmailErrorCatching = (response = {}) => (dispatch) => {
  if (response.status === 422 && response.body && response.body.email) {
    const errorText =
      response.body.email[0] === SaveIdentityErrorCodeKeys.hasAlreadyTaken
        ? SaveIdentityErrorKeys.badEmail
        : SaveIdentityErrorKeys.invalidEmail;
    dispatch(showAlert(errorText));
  } else {
    dispatch(showAlert());
  }
};

export const addIdentity = (identity) => (dispatch) => {
  dispatch(setLoading());
  dispatch(createIdentityActionCreator(identity))
    .then((newIdentity) => {
      dispatch(setLoading(false));
      dispatch(reset(IDENTITY_FORM_ID));
      dispatch(showSuccessBar());
      navigateToEditIdentitySlideout(newIdentity.id);
    })
    .catch(({ response = {} }) => {
      dispatch(setLoading(false));
      dispatch(identityEmailErrorCatching(response));
    });
};

export const updateIdentity = (identity) => (dispatch) => {
  dispatch(setLoading());
  dispatch(updateIdentityActionCreator(identity))
    .then((updatedIdentity) => {
      dispatch(setLoading(false));
      dispatch(showSuccessBar());
      navigateToEditIdentitySlideout(updatedIdentity.id);
    })
    .catch(({ response = {} }) => {
      dispatch(setLoading(false));
      dispatch(identityEmailErrorCatching(response));
      dispatch(setIdentityCardEditMode(true));
    });
};

export const openHasChangesPopup = (url) => (dispatch) => {
  dispatch(openPopup(EditIdentityPopupIds.hasChanges, { url }));
};

export const discardFormChanges = (url) => () => {
  navigateToUrl(url);
};
