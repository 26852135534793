import { setIdentitiesFetched } from 'web/user/actionCreators/identitiesActionCreators';
import { setDeliveryChannelsFetched } from 'web/deliveryChannels/actionCreators/deliveryChannelsActionCreators';
import { fetchGoogleIntegrations } from '../services/gmailIntegrationService';
import {
  deleteContactIntegration,
  updateContactIntegration,
} from 'web/contactIntegrations/services/contactIntegrationsService';
import GmailIntegrationActionTypes from '../libs/gmailIntegrationActionTypes';
import {
  GmailIntegrationAlertIds,
  GmailIntegrationLocalStorageKeys,
} from '../libs/gmailIntegrationConstants';
import { navigateToUrl } from 'web/services/routerService';
import { Urls, LocalStorageKeys } from 'web/libs/constants';
import {
  openPopup,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { GmailIntegrationPopupIds } from '../libs/gmailIntegrationActionTypes';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';

export const showConnectAlert = ({
  'valid_credentials?': isConnected = false,
}) => (dispatch) => {
  const isTriedToConnect =
    window.localStorage.getItem(
      LocalStorageKeys.googleIntegration.connecting
    ) === GmailIntegrationLocalStorageKeys.connecting;

  if (isTriedToConnect) {
    isConnected
      ? dispatch(openViewAlert(GmailIntegrationAlertIds.googleConnected))
      : dispatch(openViewAlert(GmailIntegrationAlertIds.googleNotConnected));

    window.localStorage.removeItem(
      LocalStorageKeys.googleIntegration.connecting
    );
  }
};

export const getGoogleIntegrations = () => (dispatch) => {
  dispatch({ type: GmailIntegrationActionTypes.fetching });

  return fetchGoogleIntegrations()
    .then((response = {}) => {
      dispatch({
        type: GmailIntegrationActionTypes.set,
        ...response,
        fetched: true,
      });
      dispatch(showConnectAlert(response));
    })
    .catch(() => {
      dispatch({ type: GmailIntegrationActionTypes.error });
      dispatch(openViewAlert(GmailIntegrationAlertIds.integrationsFetchError));
    });
};

export const connectGoogle = () => {
  window.localStorage.setItem(
    LocalStorageKeys.googleIntegration.connecting,
    GmailIntegrationLocalStorageKeys.connecting
  );
  navigateToUrl(Urls.googleAuth);
};

export const disconnectGoogle = () => (dispatch, getState) => {
  const { id } = getState().gmailIntegration;

  dispatch({ type: GmailIntegrationActionTypes.disconnecting });
  dispatch(closePopup(GmailIntegrationPopupIds.disconnectConfirmation));

  return deleteContactIntegration(id)
    .then(() => {
      dispatch({
        type: GmailIntegrationActionTypes.clearStateValues,
      });
      dispatch(openViewAlert(GmailIntegrationAlertIds.googleDisconnected));
      dispatch(setIdentitiesFetched(false));
      dispatch(setDeliveryChannelsFetched(false));
    })
    .catch(() => {
      dispatch({ type: GmailIntegrationActionTypes.error });
      dispatch(openViewAlert(GmailIntegrationAlertIds.googleNotDisconnected));
    });
};

export const saveChanges = (data = {}) => (dispatch, getState) => {
  const { id } = getState().gmailIntegration;

  dispatch({ type: GmailIntegrationActionTypes.savingChanges });

  return updateContactIntegration(id, data)
    .then((response = {}) => {
      dispatch({
        type: GmailIntegrationActionTypes.set,
        ...response,
      });
      dispatch(openViewAlert(GmailIntegrationAlertIds.integrationUpdated));
    })
    .catch(() => {
      dispatch({ type: GmailIntegrationActionTypes.error });
      dispatch(openViewAlert(GmailIntegrationAlertIds.integrationNotUpdated));
    });
};

export const clearStateValues = () => (dispatch) => {
  dispatch({ type: GmailIntegrationActionTypes.clearStateValues });
};

export const openDisconnectConfirmationPopup = () => (dispatch) => {
  dispatch(openPopup(GmailIntegrationPopupIds.disconnectConfirmation));
};
