export const ICONS = {
  NEGATIVE: 'icon-broken',
  NEUTRAL: 'icon-not-set',
  POSITIVE: 'icon-success',
};

export const MESSAGES = {
  ONBOARDING_STATUS_FINISHED:
    'web.settings.myProfile.yourOnboardingIntegrations.finished',
};
