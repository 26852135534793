import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  CampaignsTasksTableId,
  GlobalAlertIds,
  GlobalPopupIds,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import CampaignsTabPopupContainer from 'web/campaigns/campaignsTab/campaignsTabPopupContainer';
import PersonDetailsSlideOut from 'web/slideOuts/personDetails';
import { campaignsSidebarSearchResultsSelected as sidebarSearchResultSelected } from 'web/campaigns/campaignsTab/actionCreators/campaignsSidebarSearchActionCreators';
import {
  currentUsers,
  deleteCampaignConfirm,
  editCampaignName as editCampaignNameAction,
  selectCampaignDetailsTab,
  notEditingCampaignName,
  updateCampaign,
} from 'web/campaigns/campaignsTab/actionCreators/campaignDetailsActionCreators';
import {
  setCampaignSetupView,
  setSlideOutMenuOpen,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsTabSettingsActionCreators';
import { cloneCampaign } from 'web/campaigns/campaignsTab/actionCreators/campaignsCloneActionCreators';
import {
  addEmailStep,
  addingNewDay,
  addStep,
  deleteCampaignStep,
  destroyCampaignEditState,
  initCampaignEditEmailState,
  initCampaignEditReminderState,
  notAddingNewDay,
  openEditWarningAlert,
  openEditWarningPopup,
  optimisticUpdateCampaignStep,
  setNewDayNumber,
  updateCampaignStep,
  updateCampaignEditState,
  updateEmailStep,
  updateStep,
} from 'web/campaigns/campaignsTab/actionCreators/campaignDayActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { openCreateCampaign } from 'web/campaigns/campaignsTab/actionCreators/campaignsActionCreators';
import {
  closeCampaignAlert,
  openCampaignAlert,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsAlertActionCreators';
import { openAddToCampaign } from 'web/modals/addToCampaign/actionCreators/popupActionCreators';
import CampaignContentView from 'web/campaigns/campaignsTab/components/campaignContentView/campaignContentView';
import { getImageUrl } from 'web/libs/constants';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import * as router from 'web/services/routerService';
import {
  deleteCategory,
  setEditCategory,
  renameCategoryConfirm,
  isRenameCategoryDisabled,
  openUpdateCategoryPopup,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsCategoriesActionCreators';
import {
  getCampaignsPeople,
  getCampaignsPeoplePagination,
  getFilteredCampaigns,
  getTasksDueIndexedByCampaign,
  getViewAsFilterItems,
  getCampaignViewer,
  getSelectedCampaign,
  getUserCanEditCampaigns,
} from 'web/reducers/campaigns/campaignSelectors';
import {
  getCampaignsTabViewFetching,
  getFilteredTasks,
} from 'web/campaigns/campaignsTab/selectors/tablesTasksSelectors';
import {
  peopleFilterChange,
  peopleViewAsFilterChange,
  setPeopleViewAsFilter,
  getCampaignPeople,
  peoplePaginationClick,
  resetCampaignPeople,
} from 'web/campaigns/people/actionCreators/campaignsPeopleActionCreators';
import {
  resetCampaignPeopleSearch,
  searchCampaignPeopleChange,
} from 'web/campaigns/people/actionCreators/campaignsPeopleSearchActionCreators';
import { filterCampaignsList } from 'web/campaigns/campaignsTab/actionCreators/campaignsFilterActionCreators';
import { updateSettings } from 'web/campaigns/settings/actionCreators/campaignsSettingsActionCreators';
import { openPersonDetails } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import { openModalTask } from 'web/modals/task';
import {
  onCampaignViewAsFilterSelect,
  setCampaignViewAsFilterInput,
} from 'web/campaigns/campaignsTab/actionCreators/campaignViewAsActionCreators';
import { DragDropContextWrapper } from 'web/shared/components/dragDropContext/dragDropContext';
import { setSearchCampaignPeople } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabStartupActionCreators';

class CampaignContentContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    this.actionCreators = {
      addEmailStep: (editorId, day) => dispatch(addEmailStep(editorId, day)),
      addNewCategory: () =>
        dispatch(openPopup(GlobalPopupIds.createNewCategory)),
      addStep: (editorId, day, action) =>
        dispatch(addStep(editorId, day, action)),
      addingNewDay: () => dispatch(addingNewDay()),
      cloneCampaign: (campaign) => dispatch(cloneCampaign(campaign)),
      closeCampaignAlert: () => dispatch(closeCampaignAlert()),
      currentUsers: (id, teams) => dispatch(currentUsers(id, teams)),
      deleteCampaignConfirm: () => dispatch(deleteCampaignConfirm()),
      deleteCampaignStep: (step) => dispatch(deleteCampaignStep(step)),
      deleteCategory: (id) => dispatch(deleteCategory(id)),
      destroyCampaignEditState: (editorId, step) =>
        dispatch(destroyCampaignEditState(editorId, step)),
      editCampaignName: () => dispatch(editCampaignNameAction()),
      filterCampaignsList: (filter) => dispatch(filterCampaignsList(filter)),
      getCampaignPeople: (id, filter) =>
        dispatch(getCampaignPeople(id, filter)),
      imageUrl: getImageUrl,
      initCampaignEditEmailState: (id, step) =>
        dispatch(initCampaignEditEmailState(id, step)),
      initCampaignEditReminderState: (editorId, action, step) =>
        dispatch(initCampaignEditReminderState(editorId, action, step)),
      isRenameCategoryDisabled: (id) => dispatch(isRenameCategoryDisabled(id)),
      notAddingNewDay: () => dispatch(notAddingNewDay()),
      notEditingCampaignName: () => dispatch(notEditingCampaignName()),
      onCampaignViewAsFilterSelect: (filter) =>
        dispatch(onCampaignViewAsFilterSelect(filter)),
      openAddToCampaign: (campaignId) =>
        dispatch(openAddToCampaign(campaignId)),
      openCampaignDayLimitAlert: () =>
        dispatch(openCampaignAlert(GlobalAlertIds.campaignDayLimit)),
      openCreateCampaign: () => dispatch(openCreateCampaign()),
      openEditWarningAlert: () => dispatch(openEditWarningAlert()),
      openEditWarningPopup: () => dispatch(openEditWarningPopup()),
      openPersonDetails: (id) => dispatch(openPersonDetails(id)),
      openTask: (task) => openModalTask(task),
      openToutPhone: (data) => toutBackboneHelper.openToutPhone(data),
      optimisticUpdateCampaignStep: (step) =>
        dispatch(optimisticUpdateCampaignStep(step)),
      peopleConfirmationComplete: (ids, markSuccess, callback) =>
        dispatch(
          openPopup(GlobalPopupIds.removeConfirmation, {
            callback,
            ids,
            markSuccess,
          })
        ),
      peopleFilterChange: (index, value) => dispatch(peopleFilterChange(value)),
      peoplePaginationClick: (newPage) =>
        dispatch(peoplePaginationClick(newPage)),
      peopleViewAsFilterChange: (index, value) =>
        dispatch(peopleViewAsFilterChange(value)),
      renameCategoryConfirm: (id, name) =>
        dispatch(renameCategoryConfirm(id, name)),
      resetCampaignPeople: () => dispatch(resetCampaignPeople()),
      resetCampaignPeopleSearch: () => dispatch(resetCampaignPeopleSearch()),
      router,
      searchCampaignPeopleChange: (id, value) =>
        dispatch(searchCampaignPeopleChange(id, value)),
      selectCampaignDetailsTab: (data) =>
        dispatch(selectCampaignDetailsTab(data)),
      setCampaignSetupView: (horizontal) =>
        dispatch(setCampaignSetupView(horizontal)),
      setCampaignViewAsFilterInput: (filter) =>
        dispatch(setCampaignViewAsFilterInput(filter)),
      setEditCategory: (id) => dispatch(setEditCategory(id)),
      setNewDayNumber: (data) => dispatch(setNewDayNumber(data)),
      setPeopleViewAsFilter: (value) => dispatch(setPeopleViewAsFilter(value)),
      setSearchCampaignPeople: (value) =>
        dispatch(setSearchCampaignPeople(value)),
      setSlideOutMenuOpen: (open) => dispatch(setSlideOutMenuOpen(open)),
      shareCampaign: () => dispatch(openPopup(GlobalPopupIds.shareCampaign)),
      sidebarSearchResultSelected: (data) =>
        dispatch(sidebarSearchResultSelected(data)),
      updateCampaign: (data, save) => dispatch(updateCampaign(data, save)),
      updateCampaignEditState: (editorId, data) =>
        dispatch(updateCampaignEditState(editorId, data)),
      updateCampaignStep: (step, save) =>
        dispatch(updateCampaignStep(step, save)),
      updateCategory: () => dispatch(openUpdateCategoryPopup()),
      updateEmailStep: (editorId, step) =>
        dispatch(updateEmailStep(editorId, step)),
      updateSettings: (selectedCampaign, data) =>
        dispatch(updateSettings(selectedCampaign, data)),
      updateStep: (editorId, step) => dispatch(updateStep(editorId, step)),
    };
  }

  render() {
    const {
      account,
      addingNewCampaignDay,
      attachments,
      campaignsPeople,
      campaignsPeoplePagination,
      campaignAlert,
      campaignsListFilter,
      campaignDayEditing,
      campaignDayLoading,
      campaignEditStates,
      campaignTemplates,
      campaignTemplatesLoading,
      campaignsLoading,
      campaignsPeopleFilter,
      campaignsPeopleViewAsFilter,
      campaignsTabViewFetching,
      campaignsTabViewLoading,
      campaignsPeopleSearchString,
      ckeBodies,
      currentCategory,
      editCampaignName,
      editingSteps,
      filteredCampaigns,
      newDayNumber,
      selectedCampaign,
      selectedDetailsTab,
      settings,
      tasks,
      tasksDueIndexedByCampaign,
      timezone,
      timezones,
      campaignsPeopleLength,
      userCanEditCampaigns,
    } = this.props;

    return [
      <CampaignsTabPopupContainer key="campaigns-tab-popup-container" />,
      <PersonDetailsSlideOut key="campaigns-tab-person-details-slide-out" />,
      <CampaignContentView
        account={account}
        actionCreators={this.actionCreators}
        addingNewCampaignDay={addingNewCampaignDay}
        attachments={attachments}
        campaignAlert={campaignAlert}
        campaignDayEditing={campaignDayEditing}
        campaignDayLoading={campaignDayLoading}
        campaignEditStates={campaignEditStates}
        campaignTemplates={campaignTemplates}
        campaignTemplatesLoading={campaignTemplatesLoading}
        campaigns={filteredCampaigns}
        campaignsListFilter={campaignsListFilter}
        campaignsLoading={campaignsLoading}
        campaignsPeople={campaignsPeople}
        campaignsPeopleFilter={campaignsPeopleFilter}
        campaignsPeopleLength={campaignsPeopleLength}
        campaignsPeoplePagination={campaignsPeoplePagination}
        campaignsPeopleSearchString={campaignsPeopleSearchString}
        campaignsPeopleViewAsFilter={campaignsPeopleViewAsFilter}
        campaignsTabViewFetching={campaignsTabViewFetching}
        campaignsTabViewLoading={campaignsTabViewLoading}
        ckeBodies={ckeBodies}
        currentCategory={currentCategory}
        editingSteps={editingSteps}
        isEditingName={editCampaignName}
        newDayNumber={newDayNumber}
        selectedCampaign={selectedCampaign}
        selectedDetailsTab={selectedDetailsTab}
        settings={settings}
        tasks={tasks}
        tasksDueIndexedByCampaign={tasksDueIndexedByCampaign}
        timezone={timezone}
        timezones={timezones}
        userCanEditCampaigns={userCanEditCampaigns}
      />,
    ];
  }
}

CampaignContentContainer.propTypes = {
  account: PropTypes.object.isRequired,
  addingNewCampaignDay: PropTypes.bool,
  attachments: PropTypes.object.isRequired,
  campaignAlert: PropTypes.object.isRequired,
  campaignDayEditing: PropTypes.object.isRequired,
  campaignDayLoading: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaignsListFilter: PropTypes.string.isRequired,
  campaignsLoading: PropTypes.bool,
  campaignsPeople: PropTypes.array.isRequired,
  campaignsPeopleLength: PropTypes.number.isRequired,
  campaignsPeopleFilter: PropTypes.string.isRequired,
  campaignsPeopleViewAsFilter: PropTypes.number.isRequired,
  campaignsPeoplePagination: PropTypes.number.isRequired,
  campaignsPeopleSearchString: PropTypes.string.isRequired,
  campaignsViewAsFilterInput: PropTypes.string.isRequired,
  campaignsTabViewFetching: PropTypes.bool,
  campaignsTabViewLoading: PropTypes.bool,
  campaignTemplates: PropTypes.array.isRequired,
  campaignTemplatesLoading: PropTypes.bool.isRequired,
  campaignViewer: PropTypes.object.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  currentCategory: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  editCampaignName: PropTypes.bool.isRequired,
  editingSteps: PropTypes.object,
  filteredCampaigns: PropTypes.array.isRequired,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedCampaign: PropTypes.object,
  selectedDetailsTab: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  tasksDueIndexedByCampaign: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  viewAsFilterItems: PropTypes.array.isRequired,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignContentContainer.defaultProps = {
  addingNewCampaignDay: false,
  campaignsLoading: false,
  campaignsTabViewFetching: false,
  campaignsTabViewLoading: false,
  editingSteps: [],
  newDayNumber: '',
  selectedCampaign: {},
  userCanEditCampaigns: true,
};

function mapStateToProps(state) {
  return {
    account: state.user, //todo migrate all account refs
    addingNewCampaignDay: state.addingNewCampaignDay,
    attachments: state.emailComposeAttachmentsMap,
    campaignAlert: state.campaignsTabCampaignAlert,
    campaignDayEditing: state.campaignsTabDayEditing,
    campaignDayLoading: state.campaignsTabCampaignDayLoading,
    campaignEditStates: state.campaignsTabEditStates,
    campaignsListFilter: state.campaignsListFilter,
    campaignsLoading: state.campaignsTabContentViewLoading,
    campaignsPeople: getCampaignsPeople(state),
    campaignsPeopleLength: getCampaignsPeople(state).length,
    campaignsPeopleFilter: state.campaignsPeopleFilter,
    campaignsPeopleViewAsFilter: state.campaignsPeopleViewAsFilter,
    campaignsPeoplePagination: getCampaignsPeoplePagination(state),
    campaignsPeopleSearchString: state.campaignsPeopleSearchString,
    campaignsTabViewFetching: getCampaignsTabViewFetching(state),
    campaignsTabViewLoading: state.campaignsTabViewLoading,
    campaignTemplates: state.campaignsTabCampaignTemplates,
    campaignTemplatesLoading: state.campaignTemplatesLoading,
    ckeBodies: state.emailComposeCkeBodies,
    currentCategory: state.currentCategory,
    editCampaignName: state.editCampaignName,
    editingSteps: state.campaignsTabEditingSteps,
    filteredCampaigns: getFilteredCampaigns(state),
    newDayNumber: state.campaignsTabNewDayNumber,
    selectedCampaign: getSelectedCampaign(state),
    selectedDetailsTab: state.campaignsTabSelectedDetailsTab,
    settings: state.campaignsTabSettings,
    tasks: getFilteredTasks(state, { tableId: CampaignsTasksTableId }),
    tasksDueIndexedByCampaign: getTasksDueIndexedByCampaign(state),
    timezone: state.userTimezone,
    timezones: state.timezones,
    campaignsViewAsFilterInput: state.campaignViewAsFilterInput,
    viewAsFilterItems: getViewAsFilterItems(state),
    campaignViewer: getCampaignViewer(state),
    userCanEditCampaigns: getUserCanEditCampaigns(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withCommonModals,
  DragDropContextWrapper
)(CampaignContentContainer);
