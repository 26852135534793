import { BackgroundColors } from 'libs/constantsStyles';
import GlobalBannerAlertsConstants from '../libs/globalBannerAlertsConstants';
import { Urls } from 'web/libs/constants';

export function getAlertProps(closeAlertById, alertId, textValues) {
  const warningAlert = {
    color: BackgroundColors.yellow,
    onClose: () => closeAlertById(alertId),
  };

  const successAlert = {
    color: BackgroundColors.greenDark,
    onClose: () => closeAlertById(alertId),
  };

  const errorAlert = {
    color: BackgroundColors.redDark,
    onClose: () => closeAlertById(alertId),
  };

  switch (alertId) {
    case GlobalBannerAlertsConstants.composeWindow.draftSavedOnClose:
      return {
        ...successAlert,
        html: true,
        outsideClickDisabled: true,
        textId: 'web.composeWindow.alert.draftSavedAfterClose',
        textValues: { url: Urls.drafts },
      };
    case GlobalBannerAlertsConstants.composeWindow.emailSent:
      return {
        ...successAlert,
        outsideClickDisabled: true,
        textId: 'web.composeWindow.alert.afterSend',
        textValues,
      };
    case GlobalBannerAlertsConstants.composeWindow.scheduledEmailSent:
      return {
        ...successAlert,
        outsideClickDisabled: true,
        textId: 'web.composeWindow.alert.afterScheduledSent',
        textValues,
      };
    case GlobalBannerAlertsConstants.composeWindow.bulkFail:
      return {
        ...errorAlert,
        outsideClickDisabled: true,
        textId: 'web.composeWindow.alert.bulkFail',
        textValues,
      };
    case GlobalBannerAlertsConstants.composeWindow.bulkSuccess:
      return {
        ...successAlert,
        outsideClickDisabled: true,
        textId: 'web.composeWindow.alert.bulkSuccess',
        textValues,
      };
    case GlobalBannerAlertsConstants.composeWindow.someBulkFail:
      return {
        ...warningAlert,
        outsideClickDisabled: true,
        textId: 'web.composeWindow.alert.someBulkFail',
        textValues,
      };
    case GlobalBannerAlertsConstants.salesforce.brokenConnection:
      return {
        ...errorAlert,
        html: true,
        manual: true,
        outsideClickDisabled: true,
        textId: 'web.salesforce.alert.broken',
        textValues,
      };
    default:
      return null;
  }
}
