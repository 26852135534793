import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './detailListItem.scss';
import MetricList from '../metricList/metricList';
import DetailListTitle from '../detailListTitle/detailListTitle';
import Metric from '../metric/metric';
import EngagementMetric from 'web/shared/components/engagementMetric/engagementMetric';
import { FormattedMessage } from 'react-intl';

class DetailListItem extends Component {
  render() {
    const {
      name,
      link,
      status,
      user,
      listIterable,
      createdAt,
      account,
      shared,
      unshared,
    } = this.props;

    return (
      <a className="detail-list-item-link text-gray-dark" href={link}>
        <div className="detail-list-item-name detail-list-divider">
          <DetailListTitle
            className="detail-list-item-padding"
            name={name}
            status={status}
            shared={shared}
            unshared={unshared}
            account={account}
            createdAt={createdAt}
            user={user}
          />
        </div>
        <div className="detail-list-item-campaign detail-list-divider">
          <div className="detail-list-item-padding text-gray-dark">
            <MetricList className="">
              {this.renderMetric(listIterable.user[0])}
              {this.renderMetric(listIterable.user[1])}
            </MetricList>
          </div>
        </div>
        <div className="detail-list-item-team">
          <div className="detail-list-item-padding detail-list-item-team-sub text-gray-dark">
            <MetricList>
              {this.renderMetric(listIterable.team[0])}
              <EngagementMetric metric={listIterable.team[1].value} />
            </MetricList>
            <MetricList>
              {this.renderMetric(listIterable.team[2])}
              {this.renderMetric(listIterable.team[3])}
            </MetricList>
          </div>
        </div>
      </a>
    );
  }

  renderMetric = (metric) => (
    <div className="metric">
      <Metric value={metric.value} percent={metric.percent} />
      {metric.label && (
        <div className="row">
          <FormattedMessage id={metric.label} />
        </div>
      )}
    </div>
  );
}

DetailListItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  user: PropTypes.object.isRequired,
  listIterable: PropTypes.object,
  account: PropTypes.object.isRequired,
  shared: PropTypes.bool.isRequired,
  unshared: PropTypes.bool.isRequired,
};

DetailListItem.defaultProps = {
  status: null,
  createdAt: null,
  listIterable: {},
};

export default DetailListItem;
