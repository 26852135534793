import { saveUserSettingsUnsubscribes } from 'web/settings/unsubscribes/services/settingsUnsubscribesService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import SettingsUnsubscribesConstants from 'web/settings/unsubscribes/libs/settingsUnsubscribesConstants';

export const saveUserUnsubscribes = (successAlertId) => (
  dispatch,
  getState
) => {
  const {
    settingsUnsubscribes: { block_unsubscribe },
  } = getState();
  const data = { block_unsubscribe };

  return saveUserSettingsUnsubscribes(data)
    .then(() => {
      dispatch(openViewAlert(successAlertId));
      toutBackboneHelper.updateUserUnsubscribes(data);
    })
    .catch(() => {
      dispatch(
        openViewAlert(
          SettingsUnsubscribesConstants.alertIds.saveUnsubscribeFailed
        )
      );
    });
};
