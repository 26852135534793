import { getTeamChannel, initPusherService } from 'web/services/pusherService';
import { updateCategoryFromPusher } from 'web/templates/actionCreators/templateCategoriesActionCreators';
import { updateTemplateFromPusher } from 'web/templates/actionCreators/templatesUpdateActionCreators';
import { PusherEvents } from 'web/libs/constants';
import queue from 'async/queue';

const queuedCategoryUpdateCall = queue(({ data, dispatch }, cb) => {
  dispatch(updateCategoryFromPusher(data));
  cb();
}, 100);

const queuedTemplateUpdateCall = queue(({ data, dispatch }, cb) => {
  dispatch(updateTemplateFromPusher(data));
  cb();
}, 100);

export function initPusher() {
  return (dispatch, getState) => {
    const { user } = getState();
    initPusherService(user.id, user.subscription.id);
    getTeamChannel().bind(PusherEvents.templateCategoryUpdate, (data) =>
      queuedCategoryUpdateCall.push({ data, dispatch })
    );
    getTeamChannel().bind(PusherEvents.templateUpdate, (data) =>
      queuedTemplateUpdateCall.push({ data, dispatch })
    );
  };
}
