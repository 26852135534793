import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ColorClasses } from 'libs/constantsStyles';
import './legend.scss';

class Legend extends Component {
  render() {
    const {
      showCount,
      showPercent,
      stats,
      total,
      width,
      precision,
    } = this.props;

    return (
      <div
        className="legend"
        style={{ width: (width && `${width}px`) || '100%' }}
      >
        {stats.map(({ color, count, label, percent }) => {
          const circleClasses = classNames('legend-label-circle', {
            [`background-${color}`]: color,
          });
          return (
            <div className="legend-block" key={`legend-block-${label}`}>
              <div className="legend-label">
                <span className={circleClasses} />
                {label}
              </div>
              {showPercent && (
                <div className="legend-data">
                  {this._getPercent(count, percent, total, precision)}
                </div>
              )}
              {showCount && <div className="legend-data">{count}</div>}
            </div>
          );
        })}
      </div>
    );
  }

  _getPercent(count, percent, total, precision) {
    if (total <= 0 || percent <= 0 || count <= 0) {
      return '0%';
    }
    if (percent > 0) {
      return `${Number.parseFloat(percent).toFixed(precision)}%`;
    }
    return `${Number.parseFloat(((count || 0) / total) * 100).toFixed(
      precision
    )}%`;
  }
}

export const LegendColors = { ...ColorClasses };

Legend.propTypes = {
  precision: PropTypes.number,
  showCount: PropTypes.bool,
  showPercent: PropTypes.bool,
  stats: PropTypes.array.isRequired,
  total: PropTypes.number,
  width: PropTypes.number,
};

Legend.defaultProps = {
  precision: 2,
  showCount: false,
  showPercent: false,
  total: 0,
  width: null,
};

export default Legend;
