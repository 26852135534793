import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import FilesChooser from 'components/filesChooser';
import Button from 'components/buttons/action';
import Switch, { SwitchColors } from 'components/buttons/switch';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import LoadingSpinner from 'components/loadingSpinner';
import './filesAttachment.scss';
import { boxName } from 'web/integrations/box/libs/boxConstants';
import {
  getBoxContent,
  cleanupBoxContent,
} from 'web/composeWindow/actionCreators/fileActionCreators';

function FilesAttachment({
  files,
  uploadFiles,
  isTracked,
  grabSelectedIds,
  bulkDeleteFiles,
  toggleTrackedContent,
  intl: { formatMessage },
  fileAttachmentsLoading,
  getBoxContent,
  contentProvider,
  cleanupBoxContent,
}) {
  const inputEl = useRef(null);
  const tableEl = useRef(null);
  const [boxHistory, setBoxHistory] = useState([]);
  const isBoxProvider = contentProvider === boxName;
  useEffect(
    () => {
      const folderId = boxHistory.length
        ? boxHistory[boxHistory.length - 1].id
        : '';
      if (isBoxProvider) {
        toggleTrackedContent(true);
        getBoxContent(folderId);
      }

      return function cleanup() {
        if (isBoxProvider) {
          cleanupBoxContent();
        }
      };
    },
    [boxHistory]
  );

  function handleFileUpload(e) {
    e.preventDefault();
    const { files } = e.target;
    uploadFiles(files, tableEl);
  }

  function openFileInput() {
    inputEl.current.click();
  }

  function handleSwitch() {
    toggleTrackedContent(!isTracked);
  }

  function handleBack() {
    setBoxHistory((prevHistory) => prevHistory.slice(0, -1));
  }

  function handleRowClick(row) {
    const isFolder = !row.document_url;
    if (isFolder) {
      setBoxHistory((prevHistory) => [...prevHistory, row]);
    }
  }

  const folder =
    boxHistory.length > 0 ? boxHistory[boxHistory.length - 1] : null;

  const trackedMessageId = isTracked
    ? 'web.composeWindow.contentModal.trackedContent'
    : 'web.composeWindow.contentModal.toggleTrackedLabel';

  return (
    <div className="files-attachment-container">
      {fileAttachmentsLoading && <LoadingSpinner />}
      {isBoxProvider &&
        folder && (
          <Button color="blue" onClick={handleBack}>
            <FormattedMessage
              id="web.composeWindow.contentModal.backToFolder"
              values={{ folder: folder.file_name }}
            />
          </Button>
        )}
      {!isBoxProvider && (
        <div>
          <input
            id="file-uploader"
            name="file-uploader"
            onChange={handleFileUpload}
            ref={inputEl}
            style={{
              display: 'none',
            }}
            type="file"
            multiple
          />
          <Button
            className="upload-content-button"
            color="blue"
            onClick={openFileInput}
          >
            <FormattedMessage id="web.composeWindow.contentModal.uploadContent" />
          </Button>
        </div>
      )}
      <div className="content-container">
        <FilesChooser
          bulkDeleteFiles={bulkDeleteFiles}
          disableDelete={isBoxProvider}
          files={files}
          grabSelectedIds={grabSelectedIds}
          handleRowClick={handleRowClick}
          tableRef={tableEl}
        />
      </div>
      <Switch
        checked={isTracked}
        color={SwitchColors.blue}
        disabled={isBoxProvider}
        label={formatMessage({
          id: trackedMessageId,
        })}
        name="toggleTrackedAttachment"
        onChange={handleSwitch}
        rightLabel
      />
    </div>
  );
}

FilesAttachment.propTypes = {
  bulkDeleteFiles: PropTypes.func.isRequired,
  cleanupBoxContent: PropTypes.func.isRequired,
  contentProvider: PropTypes.string.isRequired,
  fileAttachmentsLoading: PropTypes.bool.isRequired,
  files: PropTypes.array,
  getBoxContent: PropTypes.func.isRequired,
  grabSelectedIds: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isTracked: PropTypes.bool.isRequired,
  toggleTrackedContent: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

FilesAttachment.defaultProps = {
  files: [],
};

const mapStateToProps = (state) => ({
  fileAttachmentsLoading: state.composeFileAttachmentsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  cleanupBoxContent: () => dispatch(cleanupBoxContent()),
  getBoxContent: (...args) => dispatch(getBoxContent(...args)),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilesAttachment)
);
