import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ExchangeConnectionForm from 'web/settings/emailConnection/components/exchangeConnectionForm';
import './exchangeOnpremSelected.scss';

class ExchangeOnpremSelected extends Component {
  render() {
    const {
      onpremForm,
      actionCreators: { updateExchangeOnpremFormState },
    } = this.props;

    return (
      <div className="exchange-onprem-selected">
        <h5 className="exchange-on-prem-selected-header">
          <FormattedMessage id="web.settings.emailConnection.modal.onPremise.prompt" />
        </h5>
        <ExchangeConnectionForm
          onpremForm={onpremForm}
          updateExchangeOnpremFormState={updateExchangeOnpremFormState}
        />
      </div>
    );
  }

  static propTypes = {
    actionCreators: PropTypes.object.isRequired,
    onpremForm: PropTypes.object.isRequired,
  };
}

export default ExchangeOnpremSelected;
