import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import Cards, { Direction } from 'components/layouts/cards';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import EmailServerFormCard from '../emailServerFormCard';
import './emailServerPage.scss';

class EmailServerPage extends Component {
  render() {
    return (
      <Page className="email-server-page">
        <PageView
          className="email-server-page-view"
          backgroundColor={BackgroundColors.white}
        >
          <Cards
            backgroundColor={BackgroundColors.grayExtraLight}
            cards={this.getCards()}
            direction={Direction.vertical}
            marginAround={FULL}
            marginBetween={FULL}
          />
        </PageView>
      </Page>
    );
  }

  getCards = () => {
    const {
      actionCreators,
      model,
      formName,
      additionalServerValidators,
    } = this.props;

    return [
      {
        id: 'email-server-form-card',
        children: React.createElement(EmailServerFormCard, {
          actionCreators,
          model,
          formName,
          additionalServerValidators,
        }),
        className: 'email-server-card',
      },
    ];
  };
}

EmailServerPage.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  model: PropTypes.object,
  formName: PropTypes.string.isRequired,
  additionalServerValidators: PropTypes.array,
};

EmailServerPage.defaultProps = {
  model: null,
  additionalServerValidators: [],
};

export default EmailServerPage;
