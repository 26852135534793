import { createReducer } from '@reduxjs/toolkit';

import { setIsCustomDateChanged } from 'web/tables/dateRangeSmartFilter/actionCreators/dateRangeSmartFilterActionCreators';

/* eslint-disable no-param-reassign */
export const dateRangeSmartFilters = createReducer(
  {},
  {
    [setIsCustomDateChanged.type]: (state, action) => {
      const { syncKey } = action.payload;
      if (!state[syncKey]) {
        state[syncKey] = {};
      }
      state[syncKey].isCustomDateChanged = true;
    },
  }
);
