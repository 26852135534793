import React from 'react';
import { FormattedMessage } from 'react-intl';
import Shapes from 'web/shared/components/sidebar/sidebarShapes';
import SmartSelect from 'components/selects/smartSelect/smartSelect';
import './viewAsSmartFilter.scss';

const ROWS_SHOWN = 6;
const ViewAsSmartFilter = ({ viewAsFilter }) => (
  <div className="view-as-smart-filter">
    <div className="view-as-filter-label">
      <FormattedMessage id="common.viewAs" />
    </div>
    <SmartSelect
      className="view-as-filter-combo-box"
      exceptionSelectors={[]}
      rowsShown={ROWS_SHOWN}
      {...viewAsFilter}
    />
  </div>
);

ViewAsSmartFilter.propTypes = {
  viewAsFilter: Shapes.Filter.isRequired,
};

export default ViewAsSmartFilter;
