/*eslint complexity:0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { PopupSize } from 'libs/constantsShared';
import Popup, { PopupButtonColors } from 'components/popups';
import { PersonDetailsPopupIds } from '../libs/personDetailsConstants';
import { getPerson } from '../selectors/personDetailsSelectors';
import { getUpdatableCategories } from 'web/templates/selectors/templatesSelectors';
import {
  getPersonName,
  getPrimaryEmailAddress,
} from '../helpers/personHelpers';
import * as popupACs from '../actionCreators/personDetailsPopupActionCreators';
import {
  clearPopupAlert,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { updateCreateTemplateState } from 'web/templates/actionCreators/templatesCreateActionCreators';
import { getAlert } from './personDetailsPopupContainerHelper';
import EmailEvents from 'web/emails/components/emailEvents';
import CreateTemplate from 'web/templates/components/createTemplate';
import PersonDetailsUnsubscribePopup from './personDetailsUnsubscribePopupContainer';
import PersonDetailsRemoveUnsubscribe from '../components/personDetails/removeUnsubscribe/personDetailsRemoveUnsubscribe';
import { ButtonColors } from 'components/buttons/action';

const EMPTY_POPUP = {};

class PersonDetailsPopupContainer extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { dispatch, onClose } = this.props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          ...popupACs,
          clearAlert: clearPopupAlert,
          closePopup,
          updateCreateTemplateState,
        },
        dispatch
      ),
      deletePerson: () => dispatch(popupACs.deletePerson(onClose)),
    };
  }

  render() {
    const { children, popup } = this.props;
    let object = EMPTY_POPUP;
    if (popup) {
      object = this._getGlobalPopupObject();
    }

    /* className used for exceptionSelectors for outside clicks in parent popup */
    return (
      <div className="people-details-popup-container">
        {object.children ? <Popup {...object} /> : null}
        {children}
      </div>
    );
  }

  _getGlobalPopupObject() {
    const { loading } = this.props;
    return {
      alert: getAlert(this.props, this.actionCreators),
      getImageUrl,
      loading,
      ...this._getMainComponent(),
    };
  }

  _getMainComponent() {
    const { loading, onClose, person, popup, popupData, user } = this.props;

    switch (popup) {
      case PersonDetailsPopupIds.unsubscribe:
        return {
          children: <PersonDetailsUnsubscribePopup person={person} />,
          footer: {
            onPrimary: this.actionCreators.unsubscribePerson,
            onSecondary: this.actionCreators.closePopup,
            primaryColor: PopupButtonColors.red,
            primaryTextId: 'common.unsubscribe',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.person.personDetails.popup.unsubscribe.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case PersonDetailsPopupIds.removeUnsubscribe:
        return {
          children: (
            <PersonDetailsRemoveUnsubscribe
              personEmail={getPrimaryEmailAddress(person).address}
              userName={user.name}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.removePersonUnsubscribe,
            onSecondary: this.actionCreators.closePopup,
            primaryColor: PopupButtonColors.red,
            primaryTextId: 'common.removeUnsubscribe',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'common.removeUnsubscribe',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case PersonDetailsPopupIds.deleteCall:
        return {
          children: (
            <div className="page-header delete-notice-popup">
              <FormattedMessage id="web.person.personDetails.callsCard.call.removeCallPopup.question" />
            </div>
          ),
          footer: {
            onPrimary: this.actionCreators.removeCall,
            onSecondary: this.actionCreators.closePopup,
            primaryColor: ButtonColors.red,
            primaryTextId: 'common.delete',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId:
              'web.person.personDetails.callsCard.call.removeCallPopup.title',
          },
          size: PopupSize.small,
        };
      case PersonDetailsPopupIds.delete: {
        return {
          children: (
            <FormattedHTMLMessage
              id={'web.person.personDetails.popup.delete.bodyIssue'}
              values={{ name: getPersonName(person) }}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.deletePerson.bind(null, onClose),
            onSecondary: this.actionCreators.closePopup,
            primaryColor: PopupButtonColors.red,
            primaryTextId: 'common.delete',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.person.personDetails.popup.delete.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case PersonDetailsPopupIds.hasChanges:
        return {
          children: (
            <FormattedMessage id="web.editTemplateSlideOut.popup.hasChanges.body" />
          ),
          footer: {
            onPrimary: this.actionCreators.closePopup,
            onSecondary: this.actionCreators.hasChangesCallback,
            primaryTextId: 'common.back',
            secondaryTextId: 'common.discard',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.editTemplateSlideOut.popup.hasChanges.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case PersonDetailsPopupIds.deleteNote:
        return {
          children: (
            <FormattedHTMLMessage
              id="web.notes.deletePopup.body"
              values={{ name: getPersonName(person) }}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.deleteNote,
            onSecondary: this.actionCreators.closePopup,
            primaryColor: PopupButtonColors.red,
            primaryTextId: 'common.delete',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.notes.deletePopup.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case PersonDetailsPopupIds.events:
        return {
          children: (
            <EmailEvents
              events={popupData.events}
              openEmailInfo={this.actionCreators.openEmailInfo}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.closePopup,
            primaryTextId: 'common.close',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.emails.popup.events.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case PersonDetailsPopupIds.addAsTemplate: {
        const { createTemplateState, templatesCategories } = this.props;

        return {
          children: (
            <CreateTemplate
              categories={templatesCategories}
              createTemplateState={createTemplateState}
              updateState={this.actionCreators.updateCreateTemplateState}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.addAsTemplate,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled:
              createTemplateState.name.length <= 0 ||
              createTemplateState.categoryId <= 0,
            primaryTextId: 'common.add',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'common.addAsTemplate',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      default:
        return EMPTY_POPUP;
    }
  }
}

PersonDetailsPopupContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  children: PropTypes.node,
  createTemplateState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func, // passed in
  person: PropTypes.object.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object.isRequired,
  templatesCategories: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    alert: state.popupAlert,
    createTemplateState: state.templatesCreateTemplateState,
    loading: state.popupLoading,
    person: getPerson(state),
    popup: state.popup,
    popupData: state.popupData,
    templatesCategories: getUpdatableCategories(state),
    user: state.user,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(PersonDetailsPopupContainer);
