import React from 'react';
import { bool, string, array, object, number } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import Header from 'components/layouts/page/header';
import TabNavBar from 'components/layouts/page/tabNavBar';
import TabNav from 'components/layouts/page/tabNav';
import View from 'components/layouts/page/view';
import AdminAlert from '../adminAlert';
import AdminTabView from '../adminTabView';
import {
  ConnectionStates,
  TabValues,
} from '../../libs/marketoSettingsConstants';

import './marketoAdminSettingsContent.scss';

function getTabs(connectionState, formatMessage) {
  const disabled = connectionState !== ConnectionStates.connected;
  const tabs = [
    {
      title: formatMessage({ id: 'common.setup' }),
      value: TabValues.setup,
    },
    {
      disabled,
      title: formatMessage({ id: 'web.marketoSettings.userAccess' }),
      value: TabValues.userAccess,
    },
  ];
  return tabs;
}

const MarketoAdminSettingsContent = ({
  actionCreators,
  alertId,
  connectionInfo,
  connectionState,
  gettingUsers,
  intl: { formatMessage },
  page,
  selectedTabValue,
  teamAccessSmartFilter,
  users,
  saleforceConnectLink,
}) => (
  <Page className="marketo-admin-settings">
    <AdminAlert alertId={alertId} closeAlert={actionCreators.closeAlert} />
    <div className="marketo-settings-header">
      <Header textId="web.marketoSettings.marketoAdminSettings" />
      <TabNavBar border>
        <TabNav
          itemWidth={160}
          list={getTabs(connectionState, formatMessage)}
          onTabSelect={actionCreators.selectTab}
          selectedTabValue={selectedTabValue}
        />
      </TabNavBar>
    </div>
    <View className="marketo-admin-settings-page-view">
      <AdminTabView
        actionCreators={actionCreators}
        connectionInfo={connectionInfo}
        connectionState={connectionState}
        gettingUsers={gettingUsers}
        page={page}
        saleforceConnectLink={saleforceConnectLink}
        selectedTabValue={selectedTabValue}
        teamAccessSmartFilter={teamAccessSmartFilter}
        users={users}
      />
    </View>
  </Page>
);

MarketoAdminSettingsContent.propTypes = {
  actionCreators: object.isRequired,
  alertId: string,
  connectionInfo: object.isRequired,
  connectionState: string.isRequired,
  gettingUsers: bool.isRequired,
  intl: intlShape.isRequired,
  page: number.isRequired,
  saleforceConnectLink: string.isRequired,
  selectedTabValue: string.isRequired,
  teamAccessSmartFilter: string.isRequired,
  users: array.isRequired,
};

MarketoAdminSettingsContent.defaultProps = {
  alertId: '',
};

export default injectIntl(MarketoAdminSettingsContent);
