/*eslint eqeqeq:0*/
import { createSelector } from 'reselect';
import { EditIdentityEditorId } from '../libs/editIdentitySlideOutConstants';

const getIdentities = (state) => state.identities;
const getEditIdentityId = (state) => state.editIdentityId;
const getEditIdentityEditorContent = (state) =>
  state.emailComposeEditors[EditIdentityEditorId]
    ? state.emailComposeEditors[EditIdentityEditorId].getContent()
    : null;

const findIdentity = (identities, editIdentityId) =>
  editIdentityId &&
  identities.find((identity) => identity.id == editIdentityId);

export const getEditIdentitySelector = createSelector(
  [getIdentities, getEditIdentityId],
  findIdentity
);

export const isSignatureFormDirty = createSelector(
  [getEditIdentitySelector, getEditIdentityEditorContent],
  (identity, content) => {
    if (!identity || content === null) {
      return false;
    }
    return identity.signature !== content;
  }
);

export const getEditIdentityForNewUser = createSelector(
  [getIdentities],
  (identities) => (identities && Array.isArray(identities) ? identities[0] : {})
);
