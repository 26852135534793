import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { EditTemplateCards } from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import { canDeleteNote, canEditNote } from 'web/notes/services/notesService';
import { canEditRecommendedTemplate } from 'web/templates/services/templateSharingService';
import { commonUserAdminTeamPermission } from 'web/shared/services/accountService';
import LoadingSpinner from 'components/loadingSpinner';
import TemplateCard from '../templateCard';
import RecommendCard from '../recommendCard';
import TemplateSettingsCard from '../templateSettingsCard';
import Cards, { Direction } from 'components/layouts/cards';
import Comment from 'components/comment';
import CommentPost from 'components/comment/post';
import { getUserCanEditTemplates } from 'web/templates/selectors/templatesSelectors';
import { getUserCanControlBypassUnsubscribe } from 'web/slideOuts/editTemplate/selectors/editTemplateSelectors';
import { isUnsubscribeMessageAppendEnhancementsEnabled } from 'web/shared/helpers/featureFlagHelper';
import './templateView.scss';

const SCROLL_HEIGHT = 200;

class TemplateView extends Component {
  render() {
    const { loading } = this.props;

    return (
      <div className="edit-template-template-view">
        {loading && <LoadingSpinner imageUrl={getImageUrl} />}
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={this._getCards()}
          direction={Direction.vertical}
          maxWidth={0}
          minWidth={0}
          marginAround={FULL}
          marginBetween={FULL}
        />
      </div>
    );
  }

  _getCards = () => {
    const {
      actionCreators,
      editing,
      recommendEditState,
      template,
      templateEditState,
      user,
      userCanControlBypassUnsubscribe,
      userCanEditTemplates,
    } = this.props;

    const disableRecommendTemplateEditing =
      !canEditRecommendedTemplate(template) || !userCanEditTemplates;
    const disableEditing =
      !commonUserAdminTeamPermission(template.user_id) || !userCanEditTemplates;
    const cards = [
      {
        children: (
          <TemplateCard
            actionCreators={actionCreators}
            disableEditing={disableEditing}
            isEditing={editing[EditTemplateCards.template]}
            template={template}
            templateEditState={templateEditState}
          />
        ),
        id: EditTemplateCards.template,
      },
      {
        children: (
          <RecommendCard
            actionCreators={actionCreators}
            disableEditing={disableRecommendTemplateEditing}
            isEditing={editing[EditTemplateCards.recommend]}
            recommendEditState={recommendEditState}
            template={template}
          />
        ),
        id: EditTemplateCards.recommend,
      },
    ];

    if (isUnsubscribeMessageAppendEnhancementsEnabled(user)) {
      cards.push({
        children: (
          <TemplateSettingsCard
            actionCreators={actionCreators}
            onBypassUnsubscribeChange={actionCreators.updateBypassUnsubscribe}
            template={template}
            userCanControlBypassUnsubscribe={userCanControlBypassUnsubscribe}
          />
        ),
        id: EditTemplateCards.settings,
      });
    }

    return [
      ...cards,
      {
        children: <CommentPost onDone={this.scrollToNewPost} />,
        id: EditTemplateCards.post,
      },
      ...this._getComments(),
    ];
  };

  _getComments = () => {
    const {
      actionCreators,
      template: { notes, userId },
    } = this.props;
    if (!notes) {
      return [];
    } else {
      return notes.map(({ content, createdAt, id, userName }) => ({
        children: (
          <Comment
            allowDelete={canDeleteNote(userId)}
            allowEdit={canEditNote(userId)}
            comment={content}
            datetime={createdAt}
            id={id}
            key={`comment-${id}`}
            onDelete={actionCreators.onCommentDelete}
            onDone={actionCreators.onCommentDone}
            userName={userName}
          />
        ),
        id: `${EditTemplateCards.comment}-${id}`,
      }));
    }
  };

  scrollToNewPost = (...args) => {
    const {
      actionCreators: { onCommentDone },
      setScrollTop,
    } = this.props;

    setScrollTop(SCROLL_HEIGHT);
    onCommentDone(...args);
  };
}

TemplateView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  editing: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  recommendEditState: PropTypes.object.isRequired,
  setScrollTop: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  templateEditState: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userCanControlBypassUnsubscribe: PropTypes.bool.isRequired,
  userCanEditTemplates: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  userCanControlBypassUnsubscribe: getUserCanControlBypassUnsubscribe(state),
  userCanEditTemplates: getUserCanEditTemplates(state),
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(TemplateView);
