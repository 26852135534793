import React, { PureComponent } from 'react';
import Notification from 'components/notification';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import PropTypes from 'prop-types';
import { SalesforceCustomizationPopupIds } from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import CustomizationGuideLinks from './customizationGuideLinks';

class SalesforceCustomizationUpdate extends PureComponent {
  render() {
    const {
      isProcessing,
      intl: { formatMessage },
    } = this.props;
    const buttonText = isProcessing
      ? formatMessage({
          id: 'web.salesforceCustomization.installingUpdates.button',
        })
      : formatMessage({
          id: 'web.salesforceCustomization.installUpdates.button',
        });

    return (
      <div className="salesforce-customization-update">
        <div className="update-steps-content">
          <div className="margin-top-20">
            <FormattedMessage id="web.salesforceCustomization.connect.prelude.robustIntegration" />
          </div>
          <CustomizationGuideLinks />
          <div className="margin-top-20">
            <FormattedHTMLMessage id="web.salesforceCustomization.installUpdates" />
            <ol className="update-steps">
              <li>
                <FormattedHTMLMessage id="web.salesforceCustomization.installUpdates.clickButtonBelow" />
              </li>
              <li>
                <FormattedHTMLMessage id="web.salesforceCustomization.installUpdates.weWillInstallCustomizations" />
              </li>
              <li>
                <FormattedHTMLMessage id="web.salesforceCustomization.installUpdates.onceInstallationComplete" />
              </li>
              <li>
                <FormattedHTMLMessage id="web.salesforceCustomization.installUpdates.uninstallPackage" />
              </li>
              <li>
                <FormattedHTMLMessage id="web.salesforceCustomization.installUpdates.setToUse" />
              </li>
            </ol>
          </div>
        </div>
        <div className="margin-top-20">
          <Notification messageId="web.salesforceCustomization.installUpdates.warning" />
        </div>
        <div className="margin-top-20 right">
          <Button
            color={ButtonColors.blue}
            disabled={isProcessing}
            size={ButtonSizes.standard}
            onClick={this.handleClick}
            title={buttonText}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    );
  }

  handleClick = () => {
    const { actionCreators } = this.props;

    actionCreators.openPopup(
      SalesforceCustomizationPopupIds.salesforceCustomizationPreInstall
    );
  };
}

SalesforceCustomizationUpdate.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceCustomizationUpdate);
