import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from 'components/alert';
import TimezoneSettingsContainer from '../components/timezoneSettings/components/timezoneSettingsContainer';
import AccountDetailsContainer from '../components/accountDetails/components/accountDetailsContainer';
import Cards from '../../../../components/layouts/cards';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header/pageHeader';
import PageView from 'components/layouts/page/view';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import YourIntegrationsContainer from '../components/yourIntegrations/containers/yourIntegrationsContainer';
import OnboardingIntegrationsContainer from '../components/onboardingIntegrations/containers/onboardingIntegrationsContainer';
import { clearAlert } from '../actionCreators/myProfileActionCreators';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import './profileContainer.scss';
import { isNewUser } from 'web/user/selectors/userSelectors';

const CARD_ID_INTEGRATIONS = 'your-integrations';
const CARD_ID_TIMEZONE = 'timezone';
const CARD_ID_ACCOUNT_DETAILS = 'accountDetails';
const CARD_ID_ONBOARDING = 'your-onboarding';

function getCards(isNewUser) {
  return [
    {
      children: <AccountDetailsContainer />,
      className: 'my-profile-card',
      id: CARD_ID_ACCOUNT_DETAILS,
    },
    {
      children: <YourIntegrationsContainer />,
      className: 'my-profile-card',
      id: CARD_ID_INTEGRATIONS,
    },
    {
      children: <TimezoneSettingsContainer />,
      className: 'my-profile-card',
      id: CARD_ID_TIMEZONE,
    },
    {
      children: isNewUser ? <OnboardingIntegrationsContainer /> : null,
      className: 'my-profile-card onboarding-integration-card',
      id: CARD_ID_ONBOARDING,
    },
  ];
}

const ProfileContainer = ({ alertOptions, clearAlert, pending, isNewUser }) => (
  <div className="tout-ui-styles profile-container">
    {alertOptions && (
      <Alert
        {...alertOptions}
        className="profile-container-alert"
        onClose={clearAlert}
      />
    )}
    {pending && <LoadingSpinner imageUrl={getImageUrl} />}
    <Page>
      <PageHeader textId="web.settings.myProfile.title" />
      <PageView>
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={getCards(isNewUser)}
          marginAround={FULL}
          marginBetween={FULL}
          minWidth={400}
        />
      </PageView>
    </Page>
  </div>
);

ProfileContainer.defaultProps = {
  alertOptions: null,
  clearAlert: () => {},
  pending: false,
};

ProfileContainer.propTypes = {
  alertOptions: PropTypes.object,
  clearAlert: PropTypes.func,
  isNewUser: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  alertOptions: state.myProfileAlertReducer.alertOptions,
  isNewUser: isNewUser(state),
  pending: state.timezoneSettingsReducer.pending,
});

const mapDispatchToProps = {
  clearAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer);
