import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigateToTeamManagementTab } from 'web/services/routerService';
import TeamManagement from '../components/teamManagement';
import { getActiveTab } from '../selectors/teamManagementSelectors';
import { isAddTeamMembersToTeamEnabled } from '../selectors/teamMembersSelector';
import { bindActionCreators } from 'redux';
import { getSelectedTeam } from '../selectors/teamsSelector';
import { openAddTeamMembersToTeamPopup } from '../actionCreators/teamMembersActionCreators';

class TeamManagementContainer extends Component {
  render() {
    return <TeamManagement {...this.props} onTabSelect={this.onTabSelect} />;
  }

  onTabSelect = (tab) => {
    navigateToTeamManagementTab(tab);
  };
}

const mapStateToProps = (state) => ({
  activeTab: getActiveTab(state),
  selectedTeam: getSelectedTeam(state),
  isAddTeamMembersToTeamEnabled: isAddTeamMembersToTeamEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        openAddTeamMembersToTeamPopup,
      },
      dispatch
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamManagementContainer);
