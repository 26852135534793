import { baseDelete, basePut, baseGet, basePost } from 'web/services/api';
import { Urls } from 'web/libs/constants';

// TODO: get rid of this functions as it duplicates the same from contactIntegrationsService
export function disconnectIntegration(id) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.contactIntegrations.byId(id), {}, resolve, reject);
  });
}

// TODO: get rid of this functions as it duplicates the same from contactIntegrationsService
export function updateIntegration(id, data = {}) {
  return new Promise((resolve, reject) => {
    basePut(Urls.contactIntegrations.byId(id), data, resolve, reject);
  });
}

// TODO: get rid of this functions as it duplicates the same from contactIntegrationsService
export function fetchEmailConnection(provider) {
  return new Promise((resolve, reject) => {
    baseGet(Urls.contactIntegrations.byProvider(provider), {}, resolve, reject);
  });
}

export const saveExchangeOnpremAuth = (body = {}) =>
  new Promise((resolve, reject) => {
    basePost(Urls.exchangeOnprem, body, resolve, reject);
  });
