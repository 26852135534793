import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditTemplateCards } from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import TemplateEditing from './editing';
import TemplateDetails from './details';
import './templateCard.scss';

class TemplateCard extends Component {
  render() {
    const {
      actionCreators,
      disableEditing,
      isEditing,
      template: { body, fileAttachments, subject },
      templateEditState,
    } = this.props;

    if (isEditing) {
      return (
        <TemplateEditing
          attachments={fileAttachments}
          cancel={actionCreators.closeCardEditing}
          save={actionCreators.saveTemplate}
          templateEditState={templateEditState}
          updateEditState={actionCreators.updateTemplateEditState}
        />
      );
    } else {
      return (
        <TemplateDetails
          attachmentsCount={fileAttachments.length}
          body={body}
          edit={(!disableEditing && this.edit) || undefined}
          subject={subject}
        />
      );
    }
  }

  edit = () =>
    this.props.actionCreators.openCardEditing(EditTemplateCards.template);
}

TemplateCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  disableEditing: PropTypes.bool,
  isEditing: PropTypes.bool,
  template: PropTypes.object.isRequired,
  templateEditState: PropTypes.object.isRequired,
};

TemplateCard.defaultProps = {
  disableEditing: false,
  isEditing: false,
};

export default TemplateCard;
