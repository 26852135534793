import React from 'react';
import TinyMceEditor from './tinyMceEditor';

const TinyMceEditorField = ({
  body,
  input: { onChange, ...inputProps },
  ...rest
}) => (
  <TinyMceEditor
    handleEditorChange={onChange}
    value={body}
    {...rest}
    {...inputProps}
  />
);

export default TinyMceEditorField;
