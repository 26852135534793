import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import DateAgo from 'components/dates/dateAgo';
import OptionDotaInfobox from 'components/optionDotas/infobox';

class CommentUser extends PureComponent {
  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.editLabel = formatMessage({ id: 'common.edit' });
    this.deleteLabel = formatMessage({ id: 'common.delete' });
  }

  render() {
    const { datetime, userName } = this.props;
    const options = this._getOptions();

    return (
      <div className="comment-user flex--horz-spread">
        <div>
          <div className="text-medium">
            {userName || <FormattedMessage id="common.unknown" />}
          </div>
          <DateAgo className="text-gray" datetime={datetime} />
        </div>
        {(options.length && <OptionDotaInfobox options={options} />) || null}
      </div>
    );
  }

  _getOptions = () => {
    //todo if your comment, allow edit & delete; if not but admin, allow delete
    const { allowEdit, allowDelete, onDelete, onEdit } = this.props;

    if (!allowEdit && !allowDelete) {
      return [];
    }
    return [
      {
        disabled: !allowEdit,
        disabledStyle: !allowEdit,
        label: this.editLabel,
        onClick: onEdit,
        value: 'edit',
      },
      {
        disabled: !allowDelete,
        disabledStyle: !allowDelete,
        label: this.deleteLabel,
        onClick: onDelete,
        value: 'delete',
      },
    ];
  };
}

CommentUser.propTypes = {
  allowDelete: PropTypes.bool,
  allowEdit: PropTypes.bool,
  datetime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  intl: intlShape.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userName: PropTypes.string,
};

CommentUser.defaultProps = {
  allowDelete: false,
  allowEdit: false,
  datetime: '',
  userName: '',
};

export default injectIntl(CommentUser);
