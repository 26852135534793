import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import Cards, { Direction } from 'components/layouts/cards';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import { EditIdentityCards } from '../../libs/editIdentitySlideOutConstants';
import IdentityCard from '../identityCard';
import SignatureCard from '../signatureCard';
import DeliveryChannelCard from '../deliveryChannelCard';
import Alert from 'components/alert';
import './editIdentityAboutView.scss';

class EditIdentityAboutView extends Component {
  popstateEventHandler = () => {
    const {
      isDiscardChangesPopupOpen,
      isIdentityFormDirty,
      isSignatureFormDirty,
      isDeliveryChannelFormDirty,
      actionCreators: { openHasChangesPopup },
    } = this.props;

    if (isDiscardChangesPopupOpen) {
      return;
    }

    if (
      this.currentUrl !== window.location.href &&
      (isIdentityFormDirty ||
        isSignatureFormDirty ||
        isDeliveryChannelFormDirty)
    ) {
      openHasChangesPopup(window.location.href);
      window.location.href = this.currentUrl;
    }
  };

  componentDidMount() {
    this.currentUrl = window.location.href;
    window.addEventListener('popstate', this.popstateEventHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popstateEventHandler);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.identity !== this.props.identity) {
      this.currentUrl = window.location.href;
    }
  }

  render() {
    const {
      actionCreators: { clearAlert, clearSuccessBar, clearWarning },
      loading,
      error,
      errorMessageKey,
      successBar,
      successBarMessageKey,
      validationBarMessageKey,
      // eslint-disable-next-line react/prop-types
      validation,
    } = this.props;

    return (
      <div className="edit-identity-view">
        {loading && <LoadingSpinner imageUrl={getImageUrl} />}

        {error && (
          <Alert
            className="identity-form-error-alert"
            color={BackgroundColors.redDark}
            onClose={clearAlert}
            textId={errorMessageKey}
            manual
          />
        )}

        {successBar && (
          <Alert
            className="identity-form-success-alert"
            color={BackgroundColors.greenDark}
            onClose={clearSuccessBar}
            textId={successBarMessageKey}
          />
        )}

        {validation && (
          <Alert
            className="identity-form-success-alert"
            color={BackgroundColors.yellow}
            onClose={clearWarning}
            textId={validationBarMessageKey}
          />
        )}
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={this.getCards()}
          direction={Direction.vertical}
          marginAround={FULL}
          marginBetween={FULL}
          maxWidth={0}
          minWidth={0}
        />
      </div>
    );
  }

  onIdentityCardChangeEditing = () => {
    const {
      identityCardEditMode,
      actionCreators: { setIdentityCardEditMode },
    } = this.props;
    setIdentityCardEditMode(!identityCardEditMode);
  };

  onSignatureCardChangeEditing = () => {
    const {
      signatureCardEditMode,
      actionCreators: { setSignatureCardEditMode },
    } = this.props;
    setSignatureCardEditMode(!signatureCardEditMode);
  };

  onDeliveryChannelCardChangeEditing = () => {
    const {
      deliveryChannelCardEditMode,
      actionCreators: { setDeliveryChannelCardEditMode },
    } = this.props;
    setDeliveryChannelCardEditMode(!deliveryChannelCardEditMode);
  };

  getCards = () => {
    const {
      identity,
      editIdentityInfoInstance,
      editIdentitySignatureInstance,
      editIdentityDeliveryChannelInstance,
      identityCardEditMode,
      signatureCardEditMode,
      deliveryChannelCardEditMode,
      deliveryChannels,
      composedDeliveryChannels,
      canChangeDefaultIdentityDeliveryChannel,
      isCreateNewIdentityMode,
      shouldHideDeliveryChannels,
      actionCreators,
      emailComposeEditors,
      isIdentityFormDirty,
      isSignatureFormDirty,
    } = this.props;

    return [
      {
        children: (
          <IdentityCard
            actionCreators={actionCreators}
            editIdentityInfoInstance={editIdentityInfoInstance}
            identity={identity}
            isCreateNewIdentityMode={isCreateNewIdentityMode}
            isEditing={identityCardEditMode}
            isIdentityFormDirty={isIdentityFormDirty}
            onChangeEditing={this.onIdentityCardChangeEditing}
          />
        ),
        className: 'identity-form-card',
        id: EditIdentityCards.identity,
      },
      {
        children: !isCreateNewIdentityMode && (
          <SignatureCard
            actionCreators={actionCreators}
            editIdentitySignatureInstance={editIdentitySignatureInstance}
            emailComposeEditors={emailComposeEditors}
            identity={identity}
            isEditing={signatureCardEditMode}
            isSignatureFormDirty={isSignatureFormDirty}
            onChangeEditing={this.onSignatureCardChangeEditing}
          />
        ),
        className: 'identity-signature-form-card',
        id: EditIdentityCards.signature,
      },
      {
        children: !isCreateNewIdentityMode && (
          <DeliveryChannelCard
            actionCreators={actionCreators}
            canChangeDefaultIdentityDeliveryChannel={
              canChangeDefaultIdentityDeliveryChannel
            }
            composedDeliveryChannels={composedDeliveryChannels}
            deliveryChannels={deliveryChannels}
            editIdentityDeliveryChannelInstance={
              editIdentityDeliveryChannelInstance
            }
            identity={identity}
            isEditing={deliveryChannelCardEditMode}
            onChangeEditing={this.onDeliveryChannelCardChangeEditing}
            shouldHideDeliveryChannels={shouldHideDeliveryChannels}
          />
        ),
        className: 'identity-delivery-channel-card',
        id: EditIdentityCards.deliveryChannel,
      },
    ];
  };
}

EditIdentityAboutView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  canChangeDefaultIdentityDeliveryChannel: PropTypes.bool.isRequired,
  composedDeliveryChannels: PropTypes.array.isRequired,
  deliveryChannelCardEditMode: PropTypes.bool.isRequired,
  deliveryChannels: PropTypes.array.isRequired,
  displayIdentityCardInEditMode: PropTypes.bool.isRequired,
  editIdentityDeliveryChannelInstance: PropTypes.object,
  editIdentityInfoInstance: PropTypes.object,
  editIdentitySignatureInstance: PropTypes.string,
  emailComposeEditors: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessageKey: PropTypes.string,
  identity: PropTypes.object,
  identityCardEditMode: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  isCreateNewIdentityMode: PropTypes.bool.isRequired,
  isDeliveryChannelFormDirty: PropTypes.bool.isRequired,
  isDiscardChangesPopupOpen: PropTypes.bool.isRequired,
  isIdentityFormDirty: PropTypes.bool.isRequired,
  isSignatureFormDirty: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  shouldHideDeliveryChannels: PropTypes.bool.isRequired,
  signatureCardEditMode: PropTypes.bool.isRequired,
  successBar: PropTypes.bool.isRequired,
  successBarMessageKey: PropTypes.string,
  validationBarMessageKey: PropTypes.string,
};

export default injectIntl(EditIdentityAboutView);
