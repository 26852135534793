export default {
  setSelectedTeamId: 'TEAMS_SET_SELECTED_TEAM_ID',
  setEditingTeamId: 'TEAMS_SET_EDITING_TEAM_ID',
  setUpdateTeamLoading: 'TEAMS_SET_UPDATE_TEAM_LOADING',
  setCreateTeamLoading: 'TEAMS_SET_CREATE_TEAM_LOADING',
  setDeleteTeamLoading: 'TEAMS_SET_DELETE_TEAM_LOADING',
  updateTeam: 'TEAMS_UI_UPDATE_TEAM',
  deleteTeam: 'TEAMS_UI_DELETE_TEAM',
  createTeam: 'TEAMS_UI_CREATE_TEAM',
};
