import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function deleteRecordingNotice(state = {}, action) {
  switch (action.type) {
    case ActionTypes.api.manageRecordingNoticeDeleteAudio.success:
      return {
        ...state,
        deletedRecordingsIds: action.data,
      };
    default:
      return state;
  }
}
