import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from 'components/layouts/page';
import Cards, { Direction } from 'components/layouts/cards';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import PageView from 'components/layouts/page/view';
import GmailPluginCard from 'web/settings/emailPlugins/components/gmailPluginCard';
import OutlookMacWebCard from 'web/settings/emailPlugins/components/outlookMacWebCard';
import { isEmailPluginsDisabled } from 'web/shared/helpers/featureFlagHelper';
import './emailPlugins.scss';

class EmailPlugins extends Component {
  constructor() {
    super();

    this.cards = [GmailPluginCard, OutlookMacWebCard];
  }

  render() {
    const { user } = this.props;
    const hidePlugins = isEmailPluginsDisabled(user);

    return hidePlugins ? null : (
      <Page className="email-plugins">
        <PageView className="email-plugins-page-view">
          <Cards
            backgroundColor={BackgroundColors.grayExtraLight}
            cards={this.getCards()}
            className="plugin-cards-container"
            direction={Direction.horizontal}
            marginAround={FULL}
            marginBetween={FULL}
          />
        </PageView>
      </Page>
    );
  }

  getCards = () =>
    this.cards.map((cardComponent, index) => ({
      children: React.createElement(cardComponent, { ...this.props }),
      className: 'email-plugin-card',
      id: index,
    }));
}

EmailPlugins.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  gmailChromeExtensionInstalled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

EmailPlugins.defaultProps = {};

export default connect(
  mapStateToProps,
  {}
)(EmailPlugins);
