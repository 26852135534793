import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Popup from 'components/popups/popup';
import { PopupSize } from 'libs/constantsShared';

class SmtpRequiredPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup, navigateToSmtpServer },
    } = this.props;

    return (
      <Popup
        className="compose-email-popup"
        footer={{
          onPrimary: navigateToSmtpServer,
          onSecondary: closePopup,
          primaryTextId: 'web.composeEmail.popup.smtpServerRequired.button',
          secondaryTextId: 'common.cancel',
        }}
        header={{
          onClose: closePopup,
          textId: 'web.composeEmail.popup.smtpServerRequired.header',
        }}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedMessage id="web.composeEmail.popup.smtpServerRequired.body" />
      </Popup>
    );
  }
}

SmtpRequiredPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SmtpRequiredPopup);
