import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import PropTypes from 'prop-types';
import CustomizationGuideLinks from './customizationGuideLinks';

class SalesforceCustomizationStatusNeed extends Component {
  render() {
    const {
      intl: { formatMessage },
      actionCreators: { customizationStatusCall },
    } = this.props;
    const buttonText = formatMessage({
      id: 'web.salesforceCustomization.checkStatus',
    });

    return (
      <div className="salesforce-customization-status-need">
        <p>
          <FormattedMessage id="web.salesforceCustomization.connect.prelude.robustIntegration" />
        </p>
        <CustomizationGuideLinks />
        <Button
          color={ButtonColors.blue}
          size={ButtonSizes.standard}
          onClick={customizationStatusCall}
          title={buttonText}
        >
          <span className="tout-icon-refresh" />
          {buttonText}
        </Button>
      </div>
    );
  }
}

SalesforceCustomizationStatusNeed.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceCustomizationStatusNeed);
