import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import './complianceSuccess.scss';

class ComplianceSuccess extends Component {
  render() {
    const { count, typeText } = this.props;

    return (
      <div className="compliance-success">
        <i className="tout-icon-tick" />
        <FormattedHTMLMessage
          id="web.people.compliance.modal.saveSuccess"
          values={{ typeText, count }}
        />
      </div>
    );
  }
}

ComplianceSuccess.propTypes = {
  count: PropTypes.number.isRequired,
  typeText: PropTypes.string.isRequired,
};

export default ComplianceSuccess;
