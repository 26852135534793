import TextCell from 'table/modules/cells/textCell';
import CampaignStepCell from 'web/modals/addToCampaign/components/campaignStepCell';
import MarketoCampaignNameCell from 'web/modals/addToCampaign/components/marketoCampaignNameCell';
import { AddToCampaignColumns } from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';

const getColumn = (column, actionCreators, formatMessage) => {
  switch (column) {
    case AddToCampaignColumns.toutCampaignName:
      return {
        id: AddToCampaignColumns.toutCampaignName,
        name: formatMessage({ id: 'campaigns.campaignName' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'name',
          },
        },
        flex: true,
      };
    case AddToCampaignColumns.marketoCampaignName:
      return {
        id: AddToCampaignColumns.marketoCampaignName,
        name: formatMessage({ id: 'campaigns.campaignName' }),
        rowCell: {
          component: MarketoCampaignNameCell,
        },
        flex: true,
      };
    case AddToCampaignColumns.firstStep:
      return {
        id: AddToCampaignColumns.firstStep,
        name: formatMessage({ id: 'campaigns.firstStep' }),
        rowCell: {
          component: CampaignStepCell,
        },
        flex: true,
      };
    default:
      return null;
  }
};

export const getColumns = (columns, actionCreators, formatMessage) =>
  columns.map((column) => getColumn(column, actionCreators, formatMessage));
