import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import { pdvValidation } from 'web/person/helpers/personDetailsValidations';
import InfoCardEditingContact from './infoCardEditingContact';
import InfoCardEditingTable from './infoCardEditingTable';
import InfoCardEditingSocial from './infoCardEditingSocial';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import './infoEditing.scss';

const LABEL_WIDTH = 70;

class InfoEditing extends Component {
  checkForValidEmail = () => {
    const { save, editState, displayAlertInvalidMessage } = this.props;
    const invalidEmail = pdvValidation(editState);
    if (invalidEmail.length > 0) {
      displayAlertInvalidMessage(invalidEmail);
    }
    if (invalidEmail.length === 0) {
      save(PersonDetailsCards.info);
    }
  };

  render() {
    const {
      addInfoRow,
      editState,
      onAddressTypeChange,
      openInMarketo,
      openInSalesforce,
      requiredFields,
      removeInfoRow,
      setPrimary,
      updateAddresses,
      updateContact,
      updateSocial,
      validateAddress,
      isNewContact,
    } = this.props;

    return (
      <div className="person-details-info-editing">
        <h3 className="margin-bottom-half">
          <FormattedMessage id="common.contact" />
        </h3>
        <div className="info-editing-row-contact">
          <InfoCardEditingContact
            editState={editState}
            isNewContact={isNewContact}
            labelWidth={LABEL_WIDTH}
            requiredFields={requiredFields}
            updateContact={updateContact}
          />
        </div>
        <div className="info-editing-row-addresses">
          <InfoCardEditingTable
            addInfoRow={addInfoRow}
            editState={editState}
            labelWidth={LABEL_WIDTH}
            onAddressTypeChange={onAddressTypeChange}
            removeInfoRow={removeInfoRow}
            setPrimary={setPrimary}
            updateAddresses={updateAddresses}
            validateAddress={validateAddress}
          />
        </div>
        <div className="margin-bottom-half info-editing-row-social">
          <InfoCardEditingSocial
            editState={editState}
            isNewContact={isNewContact}
            labelWidth={LABEL_WIDTH}
            openInMarketo={openInMarketo}
            openInSalesforce={openInSalesforce}
            updateSocial={updateSocial}
            validateAddress={validateAddress}
          />
        </div>
        {this.renderButtonsBlock()}
      </div>
    );
  }

  cancel = () => this.props.cancel(PersonDetailsCards.info);

  renderButtonsBlock = () =>
    !this.props.hiddenButtons && (
      <div className="info-editing-button-row margin-top-most">
        <Button
          color={ButtonColors.gray}
          onClick={this.cancel}
          size={ButtonSizes.standard}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          className="margin-left-full"
          color={ButtonColors.blue}
          disabled={!this.props.infoCardHasChanges}
          onClick={this.checkForValidEmail}
          size={ButtonSizes.standard}
        >
          <FormattedMessage id="common.save" />
        </Button>
      </div>
    );
}

InfoEditing.propTypes = {
  addInfoRow: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  displayAlertInvalidMessage: PropTypes.func.isRequired,
  editState: PropTypes.object.isRequired,
  hiddenButtons: PropTypes.bool.isRequired,
  infoCardHasChanges: PropTypes.bool.isRequired,
  isNewContact: PropTypes.bool,
  onAddressTypeChange: PropTypes.func.isRequired,
  openInMarketo: PropTypes.func.isRequired,
  openInSalesforce: PropTypes.func,
  removeInfoRow: PropTypes.func.isRequired,
  requiredFields: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  setPrimary: PropTypes.func.isRequired,
  updateAddresses: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  updateSocial: PropTypes.func.isRequired,
  validateAddress: PropTypes.func.isRequired,
};

InfoEditing.defaultProps = {
  isNewContact: false,
  openInSalesforce: null,
};

export default InfoEditing;
