import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import PropTypes from 'prop-types';
import { SalesforceCustomizationPopupIds } from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import CustomizationGuideLinks from './customizationGuideLinks';

class SalesforceCustomizationInstall extends Component {
  render() {
    const {
      isProcessing,
      intl: { formatMessage },
    } = this.props;
    const buttonText = isProcessing
      ? formatMessage({ id: 'web.salesforceCustomization.installing.button' })
      : formatMessage({ id: 'web.salesforceCustomization.install.button' });

    return (
      <Fragment>
        <div className="margin-top-20">
          <FormattedMessage id="web.salesforceCustomization.connect.prelude.increasePipeline" />
        </div>
        <div className="margin-top-20">
          <FormattedMessage id="web.salesforceCustomization.connect.prelude.robustIntegration" />
        </div>
        <CustomizationGuideLinks />
        <div className="margin-top-20 right">
          <Button
            color={ButtonColors.blue}
            disabled={isProcessing}
            size={ButtonSizes.standard}
            onClick={this.handleClick}
            title={buttonText}
          >
            {buttonText}
          </Button>
        </div>
      </Fragment>
    );
  }

  handleClick = () => {
    const { actionCreators } = this.props;

    actionCreators.openPopup(
      SalesforceCustomizationPopupIds.salesforceCustomizationPreInstall
    );
  };
}

SalesforceCustomizationInstall.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceCustomizationInstall);
