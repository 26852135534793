import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { parseAndFormatPeopleForAddPeople } from 'web/people/peopleSearchAdd/helpers/parsers';
import {
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { getPeopleByGroup } from 'web/people/services/peopleService';
import { handlePeopleSearchAddResize } from 'web/people/peopleSearchAdd/actionCreators/popupActionCreators';

export const addGroupData = (group, people) => (dispatch, getState) => {
  const {
    peopleSearchAddState: { people: peopleState },
  } = getState();
  const { hasDupe, members } = parseAndFormatPeopleForAddPeople(
    people,
    peopleState
  );

  if (hasDupe) {
    dispatch(setPopupAlert(PeopleAlertIds.peopleSearchAdd.miscAlreadyIncluded));
  }

  if (members.length) {
    const { id, name } = group;
    dispatch({
      type: PeopleActionTypes.peopleSearchAdd.addGroup,
      data: { id, name, members },
    });

    dispatch(handlePeopleSearchAddResize());
  } else {
    dispatch(
      setPopupAlert(PeopleAlertIds.peopleSearchAdd.getPeopleInGroupFail)
    );
  }
};

export function addPeopleGroup(groupOption) {
  return (dispatch, getState) => {
    const {
      peopleSearchAddState: { groupIndex },
    } = getState();
    if (groupIndex[groupOption.value]) {
      dispatch(
        setPopupAlert(PeopleAlertIds.peopleSearchAdd.groupAlreadyIncluded)
      );
    } else {
      const { value: id, label: name } = groupOption;
      dispatch(setPopupLoading(true));
      getPeopleByGroup(groupOption.value)
        .then(({ people } = {}) => {
          dispatch(addGroupData({ id, name }, people));
          dispatch(setPopupLoading(false));
        })
        .catch(() => {
          dispatch(setPopupLoading(false));
          dispatch(
            setPopupAlert(PeopleAlertIds.peopleSearchAdd.getPeopleInGroupFail)
          );
        });
    }
  };
}
