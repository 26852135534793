import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getInvitations } from 'web/invitations/selectors/invitationsSelectors';
import { getFetching, getLoading } from '../selectors/inviteesSelectors';
import {
  openWithdrawInviteConfirmationPopup,
  openWithdrawInvitesConfirmationPopup,
  resendInvite,
  resendInvites,
  openInviteUsersPopup,
} from '../actionCreators/inviteesActionCreators';
import Invitees from '../components/invitees';

class InviteesContainer extends Component {
  render() {
    return <Invitees {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  invitations: getInvitations(state),
  fetching: getFetching(state),
  loading: getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      openWithdrawInviteConfirmationPopup,
      openWithdrawInvitesConfirmationPopup,
      resendInvite,
      resendInvites,
      openInviteUsersPopup,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteesContainer);
