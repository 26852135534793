import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GmailIntegration from '../components/gmailIntegration';
import {
  getGoogleIntegrations,
  connectGoogle,
  clearStateValues,
  saveChanges,
  openDisconnectConfirmationPopup,
} from '../actionCreators/gmailIntegrationActionCreators';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import GmailIntegrationPopupContainer from './gmailIntegrationPopupContainer';

class GmailIntegrationContainer extends Component {
  componentDidMount() {
    const { actionCreators, gmailIntegration } = this.props;

    if (!gmailIntegration.fetched) {
      actionCreators.getGoogleIntegrations();
    }
  }

  render() {
    return (
      <div className="gmail-integration-container">
        <GmailIntegration {...this.props} />
        <GmailIntegrationPopupContainer />
      </div>
    );
  }
}

GmailIntegrationContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  gmailIntegration: PropTypes.object.isRequired,
  viewAlert: PropTypes.object.isRequired,
};

const mapStateToProps = ({ gmailIntegration, viewAlert }) => ({
  gmailIntegration,
  viewAlert,
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        clearStateValues,
        closeViewAlert,
        openDisconnectConfirmationPopup,
        getGoogleIntegrations,
        saveChanges,
      },
      dispatch
    ),
    connectGoogle,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GmailIntegrationContainer);
