import { MarketoWorkspaces } from 'web/marketo/workspaces/libs/marketoWorkspacesConstants';

export const getDefaultWorkspace = (workspaces = []) =>
  workspaces.find(({ name }) => name === MarketoWorkspaces.default) || {};

export const getDefaultWorkspaceId = (workspaces = []) =>
  getDefaultWorkspace(workspaces).id;

export const getFirstWorkspaceId = (workspaces = []) => {
  const { id } = workspaces[0] || {};
  return id;
};

export const didFetchWorkspace = (workspace, campaigns) =>
  campaigns.hasOwnProperty(workspace);
