/*eslint react/no-array-index-key: "off"*/
/**
 *
 * ActionHeader
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Shapes from 'table/modules/shapes';
import BulkActions from 'table/modules/bulkActions';
import Search from 'table/modules/search';
import Pagination from 'table/modules/pagination';
import SmartFilter from 'table/modules/smartFilter';
import ColumnFilter from 'table/modules/columnFilter';
import TextButton from 'components/buttons/text';
import { FormattedMessage } from 'react-intl';
import './actionHeader.scss';
import ApiShapes from '../shapes/apiShapes';

export const ACTION_HEADER_DEFAULT_HEIGHT = 44;

class ActionHeader extends Component {
  render() {
    const { height, ...others } = this.props;
    const styles = {};
    if (height) {
      styles.height = `${height}px`;
    }

    return (
      <div className="tout-action-header" style={styles}>
        {this._getComponent(others)}
      </div>
    );
  }

  _getComponent({
    bulkActions,
    columnFilter,
    openAdvancedSearch,
    pagination,
    search,
    scrollTop,
    smartFilters,
    left,
  }) {
    const noItemsSelectedComponents =
      !(smartFilters && smartFilters.length) &&
      !columnFilter &&
      !pagination &&
      !search;

    const position = left ? 'left' : 'right';
    if (bulkActions && bulkActions.selectedCount) {
      return (
        <div className="tout-action-header-data-filters">
          <BulkActions {...bulkActions} />
        </div>
      );
    } else if (noItemsSelectedComponents && !bulkActions) {
      return null;
    } else if (noItemsSelectedComponents) {
      return <div className="text-gray">No items selected</div>; //intl
    } else {
      return (
        <div className="tout-action-header-data-filters">
          {search && <Search scrollTop={scrollTop} {...search} />}
          {!!openAdvancedSearch && (
            <TextButton
              className="margin-right-half"
              onClick={this.handleAdvancedSearchOpen}
            >
              <FormattedMessage id="common.advancedSearch" />
            </TextButton>
          )}
          <div
            className={`tout-action-header-data-filters-${position} flex--full`}
          >
            {this._getSmartFilters(smartFilters)}
            {columnFilter && (
              <ColumnFilter
                alignRight={!pagination && true}
                {...columnFilter}
              />
            )}
          </div>
        </div>
      );
    }
  }

  handleAdvancedSearchOpen = () => {
    const { openAdvancedSearch, search } = this.props;
    openAdvancedSearch(search);
  };

  _getSmartFilters = (smartFilters = []) => {
    const { height, scrollTop } = this.props;
    return smartFilters.map((filter, index) => (
      <SmartFilter
        height={height}
        key={`smart-filter-key-${index}`}
        scrollTop={scrollTop}
        {...filter}
      />
    ));
  };
}

ActionHeader.propTypes = {
  bulkActions: PropTypes.oneOfType([Shapes.BulkActions, PropTypes.bool]), //PropTypes.shape(BulkActions.propTypes),
  columnFilter: PropTypes.oneOfType([Shapes.ColumnFilter, PropTypes.bool]), //PropTypes.shape(ColumnFilter.propTypes),
  columnFilterPanel: PropTypes.node,
  height: PropTypes.number,
  left: PropTypes.bool,
  openAdvancedSearch: PropTypes.func,
  pagination: PropTypes.oneOfType([Shapes.Pagination, PropTypes.bool]), //PropTypes.shape(Pagination.propTypes),
  scrollTop: PropTypes.func,
  search: PropTypes.oneOfType([ApiShapes.Search, PropTypes.bool]),
  smartFilters: Shapes.SmartFilters, //PropTypes.shape(SmartFilter.propTypes),
};

ActionHeader.defaultProps = {
  bulkActions: false,
  columnFilter: false,
  columnFilterPanel: null,
  height: ACTION_HEADER_DEFAULT_HEIGHT,
  left: false,
  openAdvancedSearch: undefined,
  pagination: false,
  scrollTop: () => {},
  search: false,
  smartFilters: [],
};

export default ActionHeader;
