import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import Label from 'components/label';
import { getOptionMapper } from 'web/form/helpers/formHelpers';
import { DUPLICATES_OPTIONS } from '../../libs/salesforceConstants';
import Checkbox from 'web/form/components/fields/checkbox';
import Select from 'web/form/components/fields/select';
import Action from 'components/buttons/action';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import './logEmailActivityForm.scss';

class LogEmailActivityForm extends Component {
  render() {
    const {
      intl: { formatMessage },
      handleSubmit,
      pristine,
      submitting,
      disabled,
    } = this.props;

    return (
      <Form
        className="salesforce-log-email-activity-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="log-email-activity-note">
          <FormattedMessage id="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.sentToLabel" />
        </div>
        <div className="flex flex--align-middle">
          <Label
            id="log-email-activity-contacts"
            className="auto-width-label"
            classWrapper="log-email-activity-field"
            messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.contacts"
            sideLabel
          >
            <Field
              name="bcc_contacts_enabled"
              component={Checkbox}
              disabled={disabled}
            />
          </Label>
          <Label
            id="log-email-activity-leads"
            className="auto-width-label"
            classWrapper="log-email-activity-field"
            messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.leads"
            sideLabel
          >
            <Field
              name="bcc_leads_enabled"
              component={Checkbox}
              disabled={disabled}
            />
          </Label>
          <div className="flex flex--align-middle">
            <Label
              id="log-email-activity-opportunities"
              className="auto-width-label"
              classWrapper="log-email-activity-field"
              messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.opportunities"
              sideLabel
            >
              <Field
                name="bcc_opportunities_enabled"
                component={Checkbox}
                disabled={disabled}
              />
            </Label>
            <HoverTooltip
              place={HoverTooltipPlace.right}
              textId="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.opportunitiesNote"
              tooltipId="opportunities-tooltip"
            >
              <i className="icon-warning" />
            </HoverTooltip>
          </div>
        </div>
        <div className="log-email-activity-note">
          <FormattedMessage id="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.duplicates" />
        </div>
        <Field
          name="bcc_duplicate_action"
          className="duplicate-bcc-field"
          component={Select}
          options={this.duplicatesOptions}
          noResultsText=""
          placeholder={formatMessage({ id: 'common.noneSelected' })}
          multi={false}
          clearable={false}
          disabled={disabled}
          backspaceRemoves={false}
          deleteRemoves={false}
        />
        <div className="log-email-activity-note">
          <FormattedMessage id="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.createUnassignedTaskLabel" />
        </div>
        <div className="flex flex--align-middle">
          <Label
            id="log-email-activity-create-unassigned-task"
            className="auto-width-label no-wrap-text"
            classWrapper="log-email-activity-field"
            messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.createUnassignedTask"
            sideLabel
          >
            <Field
              name="bcc_create_unassigned_task"
              component={Checkbox}
              disabled={disabled}
            />
          </Label>
          <HoverTooltip
            place={HoverTooltipPlace.right}
            textId="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.createUnassignedTaskNote"
            tooltipId="create-unassigned-task-tooltip"
          >
            <i className="icon-warning" />
          </HoverTooltip>
        </div>
        <div className="flex flex--align-middle margin-top-20">
          <FormattedMessage id="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.displayToLabel" />
          <HoverTooltip
            place={HoverTooltipPlace.right}
            className="margin-left-15 activity-for-tooltip"
            textId="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.displayToNote"
            tooltipId="send-options-tooltip"
          >
            <i className="icon-warning" />
          </HoverTooltip>
        </div>

        <div className="flex flex--align-middle margin-top-20 sales-activity-for-wrapper">
          <Label
            id="log-email-activity-contacts"
            className="auto-width-label"
            classWrapper="log-email-activity-field"
            messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.contacts"
            sideLabel
          >
            <Field
              name="rollup_contacts"
              component={Checkbox}
              disabled={disabled}
            />
          </Label>
          <Label
            id="log-email-activity-leads"
            className="auto-width-label"
            classWrapper="log-email-activity-field"
            messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.leads"
            sideLabel
          >
            <Field
              name="rollup_leads"
              component={Checkbox}
              disabled={disabled}
            />
          </Label>
          <Label
            id="log-email-activity-opportunities"
            className="auto-width-label"
            classWrapper="log-email-activity-field"
            messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.opportunities"
            sideLabel
          >
            <Field
              name="rollup_opportunities"
              component={Checkbox}
              disabled={disabled}
            />
          </Label>
          <Label
            id="log-email-activity-accounts"
            className="auto-width-label"
            classWrapper="log-email-activity-field"
            messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.accounts"
            sideLabel
          >
            <Field
              name="rollup_accounts"
              component={Checkbox}
              disabled={disabled}
            />
          </Label>
        </div>
        <div className="log-email-activity-note">
          <FormattedMessage id="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.enableLoggingLabel" />
        </div>
        <Label
          id="log-email-activity-enable-logging"
          className="auto-width-label"
          classWrapper="log-email-activity-field log-email-activity-enable-logging"
          messageKey="web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.enableLogging"
        >
          <Field
            name="push_replies"
            component={Checkbox}
            autoFocus={false}
            disabled={disabled}
          />
        </Label>
        {!disabled && (
          <div className="margin-top-20 right">
            <Action disabled={pristine || submitting} type="submit">
              <FormattedMessage id="common.save" />
            </Action>
          </div>
        )}
      </Form>
    );
  }

  get duplicatesOptions() {
    const {
      intl: { formatMessage },
    } = this.props;

    if (!this._duplicatesOptions) {
      this._duplicatesOptions = DUPLICATES_OPTIONS.map(
        getOptionMapper(formatMessage)
      );
    }

    return this._duplicatesOptions;
  }
}

LogEmailActivityForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

LogEmailActivityForm.defaultProps = {
  disabled: false,
};

export default injectIntl(
  reduxForm({
    enableReinitialize: true,
  })(LogEmailActivityForm)
);
