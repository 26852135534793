const FEATURE_FLAG = {
  emailPluginsDisabled: 'email_plugins_disabled',
  marketoUnsubscribeCheck: 'marketo_unsubscribe_check_enabled',
  newComposeWindow: 'new_compose_window_enabled',
  onboarding: 'onboarding_enabled',
  owaTwoStepsSending: 'owa_two_steps_sending_enabled',
  peopleManagementDisabled: 'people_management_disabled',
  unsubscribeMessageAppendEnhancementsEnabled:
    'unsubscribe_message_append_enhancements_enabled',
  websiteTrackingDisabled: 'website_tracking_disabled',
};

const getFeatureFlag = (user = { subscription: { features: {} } }, name) =>
  (user.subscription && user.subscription.features[name]) || false;

export const hasOWATwoStepsSendingFlag = (user) =>
  getFeatureFlag(user, FEATURE_FLAG.owaTwoStepsSending);

export const hasOnboardingFeatureFlag = (user) =>
  getFeatureFlag(user, FEATURE_FLAG.onboarding);

export const isWebsiteTrackingDisabled = (user) =>
  getFeatureFlag(user, FEATURE_FLAG.websiteTrackingDisabled);

export const isPeopleManagementDisabledFlag = (user) =>
  getFeatureFlag(user, FEATURE_FLAG.peopleManagementDisabled);

export const isEmailPluginsDisabled = (user) =>
  getFeatureFlag(user, FEATURE_FLAG.emailPluginsDisabled);

export const isUnsubscribeMessageAppendEnhancementsEnabled = (user) =>
  getFeatureFlag(
    user,
    FEATURE_FLAG.unsubscribeMessageAppendEnhancementsEnabled
  );
