import { createSelector } from 'reselect';
import { getUnsubscribeOptionsLabel } from '../helpers/unsubscribeOptionsHelpers';
import { REASON_TYPE } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';

const getUnsubscribeReasons = (state, reasonType = REASON_TYPE.unsubscribe) =>
  state[reasonType];

export const getActiveUnsubscribeOptions = createSelector(
  [getUnsubscribeReasons],
  (items) => items.filter((item) => item.show_to_user || item.is_active)
);

export const getUnsubscribeOptionsItems = createSelector(
  [getActiveUnsubscribeOptions],
  (items) =>
    items.map((item) => ({
      id: item.id,
      identifier: item.identifier,
      label: getUnsubscribeOptionsLabel(item.identifier, item.title),
    }))
);

export const getReasonLabelsGroupById = createSelector(
  [getUnsubscribeReasons],
  (items) =>
    items.reduce((obj, item) => {
      obj[item.id] = getUnsubscribeOptionsLabel(item.identifier, item.title);

      return obj;
    }, {})
);
