import PropTypes from 'prop-types';

const ActionButton = PropTypes.shape({
  onClick: PropTypes.func,
  textId: PropTypes.string,
});

const Users = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })
);

const Filter = PropTypes.shape({
  inputValue: PropTypes.string,
  items: Users,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.number,
});

const ItemOptions = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func.isRequired,
    disabled: PropTypes.func,
  })
);

const IconOptions = PropTypes.shape({
  disabled: PropTypes.func,
  title: PropTypes.string,
});

const ListData = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })
);

const List = PropTypes.shape({
  editingItemId: PropTypes.number,
  itemOptions: ItemOptions, // options for dropdown menu action
  listData: ListData,
  listLoading: PropTypes.bool,
  listName: PropTypes.string,
  onEdit: PropTypes.func,
  onEditCancel: PropTypes.func,
  onItemAddClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  selectedListItem: PropTypes.number,
});

const MultiSearch = PropTypes.shape({
  onSearch: PropTypes.func,
  mixpanelEvent: PropTypes.string,
  searchResults: PropTypes.array,
  onSearchResultClick: PropTypes.func,
});

export default {
  ActionButton,
  Filter,
  ItemOptions,
  IconOptions,
  List,
  ListData,
  MultiSearch,
  Users,
};
