import React from 'react';
import PropTypes from 'prop-types';
import './emailsCounter.scss';

const EmailsCounter = ({ counter }) => {
  return (
    <span className="emails-counter">
      <span className="counter">{counter}</span>
    </span>
  );
};

EmailsCounter.propTypes = {
  counter: PropTypes.number,
};

EmailsCounter.defaultProps = {
  counter: 0,
};

export default EmailsCounter;
