import React, { Component } from 'react';
import Page from 'components/layouts/page';
import Cards, { Direction } from 'components/layouts/cards';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import PageView from 'components/layouts/page/view';
import ApiCallsCard from '../apiCallsCard';
import YourSyncSettingsCard from '../yourSyncSettingsCard';

class SyncSettingsPage extends Component {
  cards = [YourSyncSettingsCard, ApiCallsCard];

  getCards = () =>
    this.cards.map((cardComponent, index) => ({
      id: index,
      children: React.createElement(cardComponent, { ...this.props }),
    }));

  render() {
    return (
      <Page className="salesforce-sync-settings">
        <PageView className="salesforce-sync-settings-page-view">
          <Cards
            backgroundColor={BackgroundColors.grayExtraLight}
            className="salesforce-sync-settings-cards-container"
            cards={this.getCards()}
            direction={Direction.vertical}
            marginAround={FULL}
            marginBetween={FULL}
          />
        </PageView>
      </Page>
    );
  }
}

export default SyncSettingsPage;
