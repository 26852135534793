import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToutTable from 'table/modules/toutTable';
import getColumns from './unsubscribedPeopleTable.columns';
import TableExternals from 'web/people/libs/peopleTableExternals';
import { SortingDirection } from 'table/modules/constants';
import { getImageUrl } from 'web/libs/constants';
import {
  PEOPLE_SMART_FILTER_VALUES,
  UNSUBSCRIBED_PEOPLE_TABLE_ID,
} from 'web/people/libs/peopleConstants';
import { injectIntl, intlShape } from 'react-intl';
import { isAdmin } from 'web/shared/services/accountService';

const SMART_FILTER_WIDTH = 270;

class UnsubscribedPeopleTable extends Component {
  constructor(props) {
    super(props);
    const {
      actionCreators,
      intl: { formatMessage },
    } = props;
    this.actions = [
      {
        label: formatMessage({ id: 'common.source' }),
        onClick: actionCreators.openSourcePeoplePopup,
      },
      {
        label: formatMessage({ id: 'common.authorizationCapitalized' }),
        onClick: actionCreators.openAuthorizationPeoplePopup,
      },
    ];
    this.search = {
      noDropdown: true,
      onChange: actionCreators.peopleSearch,
      onClear: actionCreators.peopleResetSearch,
      placeholderText: formatMessage({ id: 'common.search' }),
    };

    this.smartFilters = [
      {
        label: formatMessage({ id: 'web.person.filter.myUnsubscribes' }),
        value: PEOPLE_SMART_FILTER_VALUES.mine,
      },
      {
        label: formatMessage({ id: 'web.person.filter.accountUnsubscribes' }),
        value: PEOPLE_SMART_FILTER_VALUES.account,
      },
    ];

    this.columns = getColumns(
      this.sortColumn,
      this.sortColumnOpposite,
      formatMessage
    );
  }

  componentWillUnmount() {
    TableExternals.reset();
  }

  render() {
    const { people, loading, totalCount } = this.props;

    return (
      <ToutTable
        bulkActions={{ actions: this.actions, totalCount }}
        columns={this.columns}
        items={people}
        loading={loading}
        loadingGetUrl={getImageUrl}
        pagination={this.getPagination()}
        row={{ onClick: this.openPersonDetails }}
        search={this.getSearch()}
        smartFilters={this.getSmartFilter()}
        tableId={UNSUBSCRIBED_PEOPLE_TABLE_ID}
        columnFilter
        selectable
      />
    );
  }

  sortColumn = (property, index, direction) => {
    this.props.actionCreators.sortPeople(property, direction);
  };

  sortColumnOpposite = (property, index, direction) => {
    const oppositeDirection =
      direction === SortingDirection.ASC
        ? SortingDirection.DESC
        : SortingDirection.ASC;
    this.props.actionCreators.sortPeople(property, oppositeDirection);
  };

  openPersonDetails = (person) => {
    const { actionCreators } = this.props;
    actionCreators.openUnsubscribePersonDetails(person.id);
  };

  setTableExternals = (obj) => TableExternals.set(obj);

  getSmartFilter = () => {
    if (isAdmin()) {
      const {
        actionCreators,
        intl: { formatMessage },
        peopleSmartFilterValue,
      } = this.props;
      return {
        filters: this.smartFilters,
        label: formatMessage({ id: 'selects.smartFilter.label' }),
        onChange: actionCreators.peopleSmartFilterChange,
        selectedValue: peopleSmartFilterValue,
        width: SMART_FILTER_WIDTH,
      };
    }

    return null;
  };

  getPagination = () => {
    const {
      actionCreators: { paginatePeople, paginatePeoplePerPage },
      page,
      perPage,
      totalCount,
    } = this.props;

    return {
      currentPage: page,
      onClick: paginatePeople,
      perPage,
      perPageClick: paginatePeoplePerPage,
      total: Math.ceil(totalCount / perPage),
    };
  };

  getSearch = () => {
    const { peopleSearchString } = this.props;
    return {
      value: peopleSearchString,
      ...this.search,
    };
  };
}

UnsubscribedPeopleTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  people: PropTypes.array.isRequired,
  peopleSearchString: PropTypes.string.isRequired,
  peopleSmartFilterValue: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default injectIntl(UnsubscribedPeopleTable);
