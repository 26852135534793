import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Icons } from 'libs/constantsStyles';

const PersonDeleteStatus = ({ person }) => {
  if (person._isUnsubscribed) {
    return <FormattedMessage id="web.peopleDelete.unsubscribeContact" />;
  } else if (person._isShared) {
    return <FormattedMessage id="web.peopleDelete.sharedContact" />;
  } else {
    return (
      <div>
        <div className={Icons.circleCheck} />
        <FormattedMessage id="web.peopleDelete.willBeDeleted" />
      </div>
    );
  }
};

PersonDeleteStatus.defaultProps = {
  person: {},
};

PersonDeleteStatus.propTypes = {
  person: PropTypes.object,
};

export default PersonDeleteStatus;
