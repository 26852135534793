import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';

export function gongError(state = {}, action) {
  switch (action.type) {
    case ActionTypes.gong.failureResponse:
      return action.data;
    case ActionTypes.gong.clearError:
    case PopupActionTypes.close:
      return {};
    default:
      return state;
  }
}
