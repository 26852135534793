export const UserRoles = {
  admin: 'ADMIN_USER_ROLE',
  user: 'USER_USER_ROLE',
};

export const UsersTableColumns = {
  actions: 'ACTIONS',
  createdAt: 'CREATED_AT',
  crm: 'CRM',
  email: 'EMAIL',
  emailConnection: 'EMAIL_CONNECTION',
  name: 'NAME',
  role: 'ROLE',
};

export const UsersPopupIds = {
  deleteUserConfirmation: 'USERS_DELETE_USER_CONFIRMATION',
  deleteUsersConfirmation: 'USERS_DELETE_USERS_CONFIRMATION',
};
export const COLUMN_SETTINGS_USERS_TABLE_ID = 'columns_settings_users_table_id';
export const EMAIL_CONNECTION_VALUE_LABEL_MAP = {
  exchange_office365: 'Outlook Online',
  exchange_onprem: 'Exchange on Prem',
  gmail: 'Gmail',
};
