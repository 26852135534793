import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  ModalComponentIds,
  ModalOverlayIds,
} from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import {
  parseSalesforceErrors,
  parseSalesforceFailures,
} from 'web/modals/addToCampaign/helpers/errorParsers';
import { unbindSalesforceImport } from 'web/modals/addToCampaign/actionCreators/pusherActionCreators';
import { setPopupOverlay } from 'web/popup/actionCreators/popupActionCreators';
import { getPeopleDataByIds } from './peopleActionCreators';
import { openAddPeople } from 'web/modals/addToCampaign/actionCreators/popupActionCreators';
import isEmpty from 'lodash/isEmpty';

export function showFailedImports(failures) {
  return {
    type: ActionTypes.addToCampaignModal.state.salesforceImportFailures,
    data: failures,
  };
}

// ATC-transition: Transitions from SalesforceProgress to AddPeople or show failed imports if none successful
export function salesforceImportComplete(peopleIds, failures) {
  return (dispatch, getState) => {
    dispatch(unbindSalesforceImport());
    if (peopleIds.length) {
      dispatch(getPeopleDataByIds(peopleIds))
        .then(() => {
          dispatch({
            type:
              ActionTypes.addToCampaignModal.state.salesforcePeopleDataSuccess,
          });
          if (
            getState().modal.component ===
            ModalComponentIds.transitioningToSalesforce
          ) {
            dispatch(openAddPeople());
          }
          if (!isEmpty(failures)) {
            dispatch(showFailedImports(failures));
          }
        })
        .catch(() => {
          dispatch(setPopupOverlay(ModalOverlayIds.retryPeopleData));
        });
    } else if (failures) {
      dispatch({
        type: ActionTypes.addToCampaignModal.state.salesforcePeopleDataFailure,
      });
      dispatch(
        setPopupOverlay(ModalOverlayIds.salesforceError, {
          textId: parseSalesforceFailures(failures),
        })
      );
    }
  };
}

export function salesforceImportFailed(error) {
  return (dispatch) => {
    dispatch(unbindSalesforceImport());
    dispatch({
      type: ActionTypes.addToCampaignModal.state.salesforcePeopleDataFailure,
    });
    dispatch(
      setPopupOverlay(ModalOverlayIds.salesforceError, {
        textId: parseSalesforceErrors(error),
      })
    );
  };
}

export function salesforceImportProgress(percentComplete = 0) {
  return {
    type: ActionTypes.addToCampaignModal.state.salesforceImportProgress,
    percentComplete,
  };
}
