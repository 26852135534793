import { SortDirections, SortTypes } from 'libs/constantsShared';

export const sortingMap = {
  date: {
    primaryProperty: 'file_updated_at',
    primaryDefaultDirection: SortDirections.desc,
    primaryType: SortTypes.date,
    secondaryProperty: 'file_file_name',
    secondaryDefaultDirection: SortDirections.asc,
    secondaryType: SortTypes.text,
  },
  name: {
    primaryProperty: 'file_file_name',
    primaryDefaultDirection: SortDirections.asc,
    primaryType: SortTypes.text,
    secondaryProperty: 'file_updated_at',
    secondaryDefaultDirection: SortDirections.desc,
    secondaryType: SortTypes.date,
  },
};
