import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { EditTemplateCards } from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import RecommendDetails from './details';
import RecommendEditing from './editing';
import './recommendCard.scss';

class RecommendCard extends PureComponent {
  render() {
    const {
      actionCreators,
      disableEditing,
      isEditing,
      recommendEditState,
      template,
    } = this.props;

    if (isEditing) {
      return (
        <RecommendEditing
          addRecommendedRow={actionCreators.addRecommendedRow}
          cancel={actionCreators.closeCardEditing}
          openDynamicFields={actionCreators.openDynamicFieldsRecommendCard}
          recommendEditState={recommendEditState}
          removeRecommendedRow={actionCreators.removeRecommendedRow}
          save={actionCreators.saveTemplate}
          template={template}
          updateEditState={actionCreators.updateRecommendEditState}
        />
      );
    } else {
      return (
        <RecommendDetails
          edit={(!disableEditing && this.edit) || undefined}
          enableMappings={template.enableMappings}
          mappings={template.templateMappings}
        />
      );
    }
  }

  edit = () =>
    this.props.actionCreators.openCardEditing(EditTemplateCards.recommend);
}

RecommendCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  disableEditing: PropTypes.bool,
  isEditing: PropTypes.bool,
  recommendEditState: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};

RecommendCard.defaultProps = {
  disableEditing: false,
  isEditing: false,
};

export default RecommendCard;
