import {
  DEFAULT_ERROR_TEXT_ID,
  PASSWORD_ERRORS_MAP,
} from '../libs/accountDetailsConstants';

export const parseFormValues = (values) =>
  Object.keys(values).reduce((acc, key) => {
    if (!values[key]) return acc;

    switch (key) {
      case 'email':
        return acc;
      case 'first_name':
      case 'last_name':
        return { ...acc, [key]: values[key].trim() };
      default:
        return { ...acc, [key]: values[key] };
    }
  }, {});

// if user don't have a first_name and not change it yet we need to set it from name
export const setFirstNameFromName = (formValues, name) =>
  !formValues.first_name ? { ...formValues, first_name: name } : formValues;

export const getChangePaswordErrorText = (error) => {
  const { body, clientError } = error.response;

  if (!body || !body.password || !clientError) {
    return DEFAULT_ERROR_TEXT_ID;
  }

  const errorText = Array.isArray(body.password)
    ? body.password[0]
    : body.password;

  for (const item of PASSWORD_ERRORS_MAP) {
    if (errorText.includes(item.message)) {
      return item.textId;
    }
  }

  return DEFAULT_ERROR_TEXT_ID;
};
