import {
  passwordMinLengthValidator,
  passwordContainsNumberValidator,
  passwordContainsSpecialSymbolValidator,
  passwordContainslowerUpperCaseValidator,
  confirmPasswordSameAsPasswordValidator,
} from 'web/form/libs/passwordValidators';
import { required as requiredValidatorFactory } from 'web/form/helpers/validators';

const firstNameRequiredValidator = requiredValidatorFactory(
  'createAccount.form.validate.firstNameRequired'
);

const lastNameRequiredValidator = requiredValidatorFactory(
  'createAccount.form.validate.lastNameRequired'
);

export const accountDetailsPasswordValidators = [
  passwordMinLengthValidator,
  passwordContainsNumberValidator,
  passwordContainsSpecialSymbolValidator,
  passwordContainslowerUpperCaseValidator,
];

export const accountDetailsConfirmPasswordValidators = [
  confirmPasswordSameAsPasswordValidator,
];

export const accountDetailsFirstNameValidators = [firstNameRequiredValidator];

export const accountDetailsLastNameValidators = [lastNameRequiredValidator];
