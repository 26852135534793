export default {
  addAlert: 'ADD_COMPOSE_WINDOW_ALERT',
  addAttachmentToEmail: 'ADD_ATTACHMENT_TO_EMAIL',
  addContentFiles: 'ADD_CONENT_FILES',
  bulkView: {
    addAttachmentIdsToMasterEmail:
      'COMPOSE_WINDOW_BULK_VIEW_ADD_ATTACHMENT_IDS_TO_MASTER_EMAIL',
    addAttachmentIdsToSingleEmail:
      'COMPOSE_WINDOW_BULK_VIEW_ADD_ATTACHMENT_IDS_TO_SINGLE_EMAIL',
    addCcAndBccToAllDrafts:
      'COMPOSE_WINDOW_BULK_VIEW_ADD_CC_AND_BCC_TO_ALL_DRAFTS',
    addCcAndBccToMasterEmail:
      'COMPOSE_WINDOW_BULK_VIEW_ADD_CC_AND_BCC_TO_MASTER_EMAIL',
    addCcAndBccToSingleEmail:
      'COMPOSE_WINDOW_BULK_VIEW_ADD_CC_AND_BCC_TO_SINGLE_EMAIL',
    addDraft: 'COMPOSE_WINDOW_BULK_VIEW_ADD_DRAFT',
    addRecipient: 'COMPOSE_WINDOW_BULK_VIEW_ADD_RECIPIENT',
    clearAllRecipientFieldState:
      'COMPOSE_WINDOW_BULK_VIEW_CLEAR_ALL_RECIPIENT_FIELD_STATE',
    clearRecipients: 'COMPOSE_WINDOW_BULK_VIEW_CLEAR_RECIPIENTS',
    clearSingleRecipientFieldState:
      'COMPOSE_WINDOW_BULK_VIEW_CLEAR_SINGLE_RECIPIENT_FIELD_STATE',
    removeAttachmentIdFromAllDrafts:
      'COMPOSE_WINDOW_BULK_VIEW_REMOVE_ATTACHMENT_ID_FROM_ALL_DRAFTS',
    removeAttachmentIdFromMasterEmail:
      'COMPOSE_WINDOW_BULK_VIEW_REMOVE_ATTACHMENT_ID_FROM_MASTER_EMAIL',
    removeAttachmentIdFromSingleEmail:
      'COMPOSE_WINDOW_BULK_VIEW_REMOVE_ATTACHMENT_ID_FROM_SINGLE_EMAIL',
    removeCcAndBccFromAllDrafts:
      'COMPOSE_WINDOW_BULK_VIEW_REMOVE_CC_AND_BCC_FROM_ALL_DRAFTS',
    removeCcAndBccFromMasterEmail:
      'COMPOSE_WINDOW_BULK_VIEW_REMOVE_CC_AND_BCC_FROM_MASTER_EMAIL',
    removeCcAndBccFromSingleEmail:
      'COMPOSE_WINDOW_BULK_VIEW_REMOVE_CC_AND_BCC_FROM_SINGLE_EMAIL',
    removeRecipient: 'COMPOSE_WINDOW_BULK_VIEW_REMOVE_RECIPIENT',
    resetBulkView: 'COMPOSE_WINDOW_BULK_VIEW_RESET_BULK_VIEW',
    resetSlidingWindow: 'COMPOSE_WINDOW_BULK_VIEW_RESET_SLIDING_WINDOW',
    searchPeople: 'COMPOSE_WINDOW_BULK_VIEW_SEARCH_PEOPLE',
    searchResultClear: 'COMPOSE_WINDOW_BULK_VIEW_SEARCH_RESULTS_CLEAR',
    selectCurrentRecipient: 'COMPOSE_WINDOW_BULK_VIEW_SELECT_CURRENT_RECIPIENT',
    setAllDraftsAttachmentIds:
      'COMPOSE_WINDOW_BULK_VIEW_SET_ALL_DRAFTS_ATTACHMENT_IDS',
    setDrafts: 'COMPOSE_WINDOW_BULK_VIEW_SET_DRAFTS',
    setGroup: 'COMPOSE_WINDOW_BULK_VIEW_SET_GROUP',
    setLoading: 'COMPOSE_WINDOW_BULK_VIEW_SET_LOADING',
    setMasterEmail: 'COMPOSE_WINDOW_BULK_VIEW_SET_MASTER_EMAIL',
    setMasterEmailAttachmentIds:
      'COMPOSE_WINDOW_BULK_VIEW_SET_MASTER_EMAIL_ATTACHMENT_IDS',
    setRecipients: 'COMPOSE_WINDOW_BULK_VIEW_SET_RECIPIENTS',
    setSearchValue: 'COMPOSE_WINDOW_BULK_VIEW_SET_SEARCH_VALUE',
    setSingleDraftAttachmentIds:
      'COMPOSE_WINDOW_BULK_VIEW_SET_SINGLE_DRAFT_ATTACHMENT_IDS',
    setSlidingWindow: 'COMPOSE_WINDOW_BULK_VIEW_SET_SLIDING_WINDOW',
    sortRecipients: 'COMPOSE_WINDOW_BULK_VIEW_SORT_RECIPIENTS',
    updateAllDraftAttachments:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_ALL_DRAFT_ATTACHMENTS',
    updateAllDraftBodies: 'COMPOSE_WINDOW_BULK_VIEW_UPDATE_ALL_DRAFT_BODIES',
    updateAllDraftIdentities:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_ALL_DRAFT_IDENTITIES',
    updateAllDraftSchedules:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_ALL_DRAFT_SCHEDULES',
    updateAllDraftSubjects:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_ALL_DRAFT_SUBJECTS',
    updateAllDraftTemplateIds:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_ALL_DRAFT_TEMPLATE_IDS',
    updateMasterEmailBody: 'COMPOSE_WINDOW_BULK_VIEW_UPDATE_MASTER_EMAIL_BODY',
    updateMasterEmailIdentity:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_MASTER_EMAIL_IDENTITY',
    updateMasterEmailSchedule:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_MASTER_EMAIL_SCHEDULE',
    updateMasterEmailSubject:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_MASTER_EMAIL_SUBJECT',
    updateMasterEmailTemplateId:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_MASTER_EMAIL_TEMPLATE_ID',
    updateRecipientState: 'COMPOSE_WINDOW_BULK_VIEW_UPDATE_RECIPIENT_STATE',
    updateSingleDraftBody: 'COMPOSE_WINDOW_BULK_VIEW_UPDATE_SINGLE_DRAFT_BODY',
    updateSingleDraftIdentity:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_SINGLE_DRAFT_IDENTITY',
    updateSingleDraftSubject:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_SINGLE_DRAFT_SUBJECT',
    updateSingleDraftTemplateId:
      'COMPOSE_WINDOW_BULK_VIEW_UPDATE_SINGLE_DRAFT_TEMPLATE_ID',
  },
  clearAlerts: 'CLEAR_COMPOSE_WINDOW_ALERTS',
  clearComposeAttachments: 'CLEAR_COMPOSE_ATTACHMENTS',
  closeAlertById: 'DELETE_COMPOSE_WINDOW_ALERT_BY_ID',
  closeComposeWindowView: 'CLOSE_COMPOSE_WINDOW_VIEW',
  deselectTemplate: 'DESELECT_TEMPLATE',
  errorPersonDetails: 'COMPOSE_WINDOW_ERROR_PERSON_DETAILS',
  fetchPersonDetails: 'COMPOSE_WINDOW_FETCH_PERSON_DETAILS',
  grabSelectedIds: 'GRAB_SELECTED_IDS',
  handleAddressesValueChanges: 'HANDLE_ADDRESSES_VALUE_CHANGE',
  handleEditorChange: 'HANDLE_EDITOR_CHANGE',
  incrementLiquifyProgress: 'COMPOSE_WINDOW_INCREMENT_LIQUIFY_PROGRESS',
  initAttachmentUploadRequest: 'INIT_ATTACHMENT_UPLOAD_REQUEST',
  initAttachmentUploadSuccess: 'INIT_ATTACHMENT_UPLOAD_SUCCESS',
  initAttachmentUploadUpdating: 'INIT_ATTACHMENT_UPLOAD_UPDATING',
  initComposeWindowRequest: 'INIT_COMPOSE_WINDOW_REQUEST',
  initComposeWindowSuccess: 'INIT_COMPOSE_WINDOW_SUCCESS',
  initImageUploadRequest: 'INIT_IMAGE_UPLOAD_REQUEST',
  initImageUploadSuccess: 'INIT_IMAGE_UPLOAD_SUCCESS',
  initImageUploadUpdating: 'INIT_IMAGE_UPLOAD_UPDATING',
  liquifyInit: 'COMPOSE_WINDOW_LIQUIFY_INIT',
  openComposeWindowView: 'OPEN_COMPOSE_WINDOW_VIEW',
  populatePersonDetails: 'POPULATEPERSONDETAILS',
  removeAttachment: 'REMOVE_ATTACHTMENT',
  replaceWithTemplate: 'REPLACE_WITH_TEMPLATE',
  reset: 'COMPOSE_WINDOW_RESET',
  resetAddresses: 'COMPOSE_WINDOW_RESET_ADDRESSES',
  resetEditorValue: 'RESET_EDITOR_VALUE',
  resetEmailEditorImages: 'RESET_EMAIL_EDITOR_IMAGES',
  resetLiquifyProgress: 'COMPOSE_WINDOW_RESET_LIQUIFY_PROGRESS',
  resetSelectedFiles: 'RESET_SELECTED_FILES',
  saveOrUpdatePitchRequest: 'SAVE_OR_UPDATE_PITCH_REQUEST',
  savePitchSuccess: 'SAVE_PITCH_SUCCESS',
  setAlert: 'SET_COMPOSE_WINDOW_ALERT',
  setAlerts: 'SET_COMPOSE_WINDOW_ALERTS',
  setAsModalMode: 'COMPOSE_WINDOW_SET_AS_MODAL_MODE',
  setAttachments: 'COMPOSE_WINDOW_SET_ATTACHMENTS',
  setBoxContentFiles: 'SET_BOX_CONTENT_FILES',
  setComposeReadOnly: 'COMPOSE_WINDOW_SET_READ_ONLY',
  setEditorFocused: 'COMPOSE_WINDOW_SET_EDITOR_FOCUSED',
  setFileAttachmentsLoading: 'SET_FILE_ATTACHMENTS_LOADING',
  setLiquifyPusherInitialized: 'COMPOSE_WINDOW_SET_LIQUIFY_PUSHER_INITIALIZED',
  setLoading: 'COMPOSE_WINDOW_SET_LOADING',
  setMinimizeView: 'SET_MINIMIZE_VIEW',
  setPitchId: 'COMPOSE_WINDOW_SET_PITCH_ID',
  setPitchInfo: 'COMPOSE_WINDOW_SET_PITCH_INFO',
  setPitchSendAt: 'COMPOSE_WINDOW_SET_PITCH_SEND_AT',
  setPitchState: 'COMPOSE_WINDOW_SET_PITCH_STATE',
  setRecentlyUsedTemplates: 'SET_RECENTLY_USED_TEMPLATES',
  setRecipients: 'SET_RECIPIENTS',
  setSave: 'COMPOSE_WINDOW_UPDATE_SAVE_FLAG',
  setSearchResults: 'COMPOSE_WINDOW_SET_SINGLE_SEARCH_RESULTS',
  setSelectedTemplate: 'SET_SELECTED_TEMPLATE',
  setSend: 'COMPOSE_WINDOW_UPDATE_SEND_FLAG',
  storeEditorInstance: 'STORE_EDITOR_INSTANCE',
  toggleBulkEmailView: 'TOGGLE_BULK_EMAIL_VIEW',
  toggleMinimizeView: 'TOGGLE_MINIMIZE_VIEW',
  toggleTrackedContent: 'TOGGLE_TRACKED_CONTENT',
  updatePitchSuccess: 'UPDATE_PITCH_SUCCESS',
  updateValidationErrors: 'COMPOSE_WINDOW_UPDATE_VALIDATION_ERRORS',
};
