import ActionTypes from 'web/libs/actionTypes/actionTypes'; //todo merge into one actionType top-level file
import TaskActionTypes from 'web/task/libs/taskActionTypes';
import I18N from 'languages';
import { Regex } from 'web/libs/constants';
import { setPopupLoading } from 'web/popup/actionCreators/popupActionCreators';
import { getAutocompleteContacts } from 'web/task/actionCreators/peopleActionCreators';
import { getPrimaryEmail } from 'web/shared/services/peopleService';
import {
  findOrCreateContact,
  checkContactSharedWithUser,
} from 'web/task/services/peopleService';
import { SourceTypes } from 'web/compliance/libs/complianceConstants';
import { clearPopupAlert } from 'web/popup/actionCreators/popupActionCreators';

const emptyFunc = () => {};

export function onChange(data) {
  return {
    data,
    type: TaskActionTypes.task.state.updateEdit,
  };
}

export function updateContact(person = {}, handleError = emptyFunc) {
  return (dispatch) => {
    const email = getPrimaryEmail(person);
    if (person.id && email) {
      dispatch(onChange({ contactEmail: email.address, contactId: person.id }));
      dispatch({
        data: email.address,
        type: ActionTypes.autocomplete.state.value,
      });
    } else {
      handleError({ noContactFound: true });
    }
  };
}

export function createEditState(task = {}, currentUser) {
  return (dispatch, getState) => {
    const { user } = getState();
    const userObj = currentUser ? currentUser : user;

    const { id: userId, name, email } = userObj;
    dispatch({
      data: {
        contactEmail: task.parent_email,
        contactId: task.person_id,
        date: I18N.getLocalizedDate(
          task.remind_at || new Date(),
          I18N.DateFormats.DATE_SLASH
        ),
        id: task.id,
        markComplete: task.unless === 'replied',
        notes: task.script || task.content,
        priority: task.priority,
        title: task.subject,
        type: task.remind_type,
        userId,
      },
      task,
      type: TaskActionTypes.task.state.createEdit,
    });

    dispatch({
      type: TaskActionTypes.userAutocomplete.value,
      value: `${name || email} (${email})`,
    });
    const value = task.parent_email || task.parent_name;
    if (value) {
      dispatch({ data: value, type: ActionTypes.autocomplete.state.value });
    } else if (task.person) {
      dispatch(updateContact(task.person));
    }
  };
}

export function destroyEditState() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.autocomplete.state.clear });
    dispatch({ type: TaskActionTypes.task.state.destroyEdit });
  };
}

export function onAutocompleteLeaving(value = '', handleError = emptyFunc) {
  return (dispatch, getState) => {
    const { taskEditState } = getState();
    const validEmail = value.length && value.match(Regex.email);

    if (value !== taskEditState.contactEmail && validEmail) {
      dispatch(setPopupLoading(true));
      findOrCreateContact(value, SourceTypes.marketing)
        .then((data = {}) => {
          dispatch(setPopupLoading(false));
          dispatch(updateContact(data, handleError));
        })
        .catch((err) => {
          dispatch(setPopupLoading(false));
          handleError(err);
        });
    } else if (
      (!taskEditState.contactId && value) ||
      (value && taskEditState.contactEmail !== value)
    ) {
      handleError({ noContactFound: true });
      dispatch(onChange({ contactEmail: '', contactId: 0 }));
      dispatch({ type: ActionTypes.autocomplete.state.clear });
    }

    dispatch({ type: ActionTypes.autocomplete.data.clear });
  };
}

const MAX_AUTOCOMPLETE_RESULTS = 3;
export function onAutocompleteChange(value = '') {
  return (dispatch) => {
    dispatch({ data: value, type: ActionTypes.autocomplete.state.value });
    dispatch(getAutocompleteContacts(value, MAX_AUTOCOMPLETE_RESULTS));

    if (!value) {
      dispatch(taskEditStateClearContact());
    }
  };
}

export const onUserAutocompleteChange = (value = '') => (dispatch) =>
  dispatch({ type: TaskActionTypes.userAutocomplete.value, value });

export function onAutocompleteSelection(contact = {}, handleError = emptyFunc) {
  return (dispatch) => {
    if (contact.address) {
      dispatch(
        onChange({ contactEmail: contact.address, contactId: contact.id })
      );
      dispatch({
        data: contact.address,
        type: ActionTypes.autocomplete.state.value,
      });
      dispatch(validateContactSharedWithUser(handleError));
    } else {
      //todo Alert?
    }
    dispatch({ type: ActionTypes.autocomplete.data.clear });
  };
}

export function onUserAutocompleteSelection(
  { label, value: userId } = {},
  handleError = emptyFunc
) {
  return (dispatch) => {
    dispatch(onChange({ userId }));
    dispatch(onUserAutocompleteChange(label));
    dispatch(validateContactSharedWithUser(handleError));
  };
}

const validateContactSharedWithUser = (handleError) => (dispatch, getState) => {
  const {
    taskEditState: { contactId, userId },
  } = getState();

  if (!!contactId && contactId > 0 && !!userId) {
    dispatch(setPopupLoading(true));
    checkContactSharedWithUser(contactId, userId)
      .then((data = {}) => {
        dispatch(setPopupLoading(false));
        const { is_shared: isShared } = data;
        dispatch(setCanUserHandleContactState(isShared, handleError));
      })
      .catch((err) => {
        dispatch(setPopupLoading(false));
        handleError({ statusCode: err.status });
      });
  }
};

const taskEditStateClearContact = () => (dispatch) => {
  dispatch({ type: TaskActionTypes.task.state.clearContact });
  dispatch(setCanUserHandleContactState());
};

const setCanUserHandleContactState = (
  isShared = true,
  handleError = emptyFunc
) => (dispatch) => {
  dispatch({
    isShared,
    type: TaskActionTypes.task.state.setCanUserHandleContact,
  });
  isShared
    ? dispatch(clearPopupAlert())
    : handleError({ notSharedContact: true });
};
