import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import LabeledInputReduxForm from 'components/labeledInputReduxForm';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import { BackgroundColors } from 'libs/constantsStyles';
import { validate } from '../../helpers/loginSsoValidation';
import Action from 'components/buttons/action';
import FormLayout from 'components/layouts/formLayout';
import FormTitle from 'components/layouts/formLayout/formTitle';
import FormError from 'components/layouts/formLayout/formError';
import FormButtonsArea from 'components/layouts/formLayout/formButtonsArea';
import './loginSso.scss';

const LoginSso = ({ handleSubmit, onSubmit, error, loading }) => (
  <div className="login-sso">
    {loading && <LoadingSpinner imageUrl={getImageUrl} />}
    <FormLayout>
      <FormTitle id="loginSso.title" subTitleId="loginSso.subtitle" />
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
        <div className="form-row">
          <Field
            name="email"
            id="email"
            label="common.email"
            component={LabeledInputReduxForm}
            type="text"
            autoFocus
            autoComplete="email"
            className="form-cell"
          />
        </div>
        <FormError>{error && <FormattedMessage id={error} />}</FormError>

        <FormButtonsArea align="right">
          <Action
            className="medium"
            color={BackgroundColors.blue}
            type="submit"
          >
            <FormattedMessage id="common.login" />
          </Action>
        </FormButtonsArea>
      </Form>
    </FormLayout>
  </div>
);

LoginSso.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

LoginSso.defaultProps = {
  error: '',
  loading: false,
};

export default reduxForm({
  form: 'loginSso',
  validate,
  touchOnBlur: false,
  touchOnChange: false,
})(LoginSso);
