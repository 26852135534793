import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/select';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import isFunction from 'lodash/isFunction';
import './selectCell.scss';
import classNames from 'classnames';

class SelectCell extends PureComponent {
  render() {
    const { disabledTooltipTextId, className, rowsShown } = this.props;

    const items = this.getItems();
    const disabled = this.getDisabled();
    const selected = this.getSelectedValue();

    const select = (
      <Select
        className={classNames(className, 'cell-select')}
        items={items}
        selected={selected}
        onChange={this.onChange}
        disabled={disabled}
        rowsShown={rowsShown}
        isFixedPosition
      />
    );

    return disabled ? (
      <HoverTooltip
        place={HoverTooltipPlace.right}
        textId={disabledTooltipTextId}
        tooltipId="cell-select-tooltip"
      >
        {select}
      </HoverTooltip>
    ) : (
      select
    );
  }

  onChange = (value) => {
    if (this.getSelectedValue() !== value) {
      this.props.onChange(value, this.props.rowData);
    }
  };

  getItems() {
    const { rowData, selectItems } = this.props;

    return isFunction(selectItems) ? selectItems(rowData) : selectItems;
  }

  getDisabled() {
    const { rowData, disabled } = this.props;

    return isFunction(disabled) ? disabled(rowData) : disabled;
  }

  getSelectedValue() {
    const { rowData, propertyName, selectedValue } = this.props;

    if (propertyName) {
      return rowData[propertyName];
    }

    return isFunction(selectedValue) ? selectedValue(rowData) : selectedValue;
  }
}

SelectCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  selectItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      })
    ),
    PropTypes.func,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabledTooltipTextId: PropTypes.string,
  // If propertyName specified, it will get selected value from rowData[propertyName]
  // Or leave propertyName blanck and specify value via selectedValue prop directly
  propertyName: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.any, PropTypes.func]),
  className: PropTypes.string,
  rowsShown: PropTypes.number,
};

SelectCell.defaultProps = {
  disabled: false,
  disabledTooltipTextId: '',
  propertyName: null,
  selectedValue: null,
  className: '',
  rowsShown: 4,
};

export default SelectCell;
