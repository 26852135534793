import request from 'superagent';
import apiHelper from './apiHelper';
import param from 'jquery-param';

function defaultSuccess() {}

function defaultError() {}

export function baseGet(
  url = '',
  data = {},
  success = defaultSuccess,
  error = defaultError
) {
  request
    .get(url)
    .accept('application/json')
    .type('json')
    .query(param(data))
    .end((err, res) => {
      if (err) {
        error(err);
      } else {
        success(res.body);
      }
    });
}

export function basePost(
  url = '',
  data = {},
  success = defaultSuccess,
  error = defaultError
) {
  request
    .post(url)
    .accept('application/json')
    .type('application/json; charset=UTF-8')
    .set('X-CSRF-Token', apiHelper.getCsrfToken())
    .send(data)
    .end((err, res) => {
      if (err) {
        error(err);
      } else {
        success(res.body);
      }
    });
}

export function basePut(
  url = '',
  data = {},
  success = defaultSuccess,
  error = defaultError
) {
  request
    .put(url)
    .accept('application/json')
    .type('application/json; charset=UTF-8')
    .set('X-CSRF-Token', apiHelper.getCsrfToken())
    .send(data)
    .end((err, res) => {
      if (err) {
        error(err);
      } else {
        success(res.body);
      }
    });
}

export function baseDelete(
  url = '',
  data = {},
  success = defaultSuccess,
  error = defaultError
) {
  request
    .del(url)
    .accept('application/json')
    .type('application/json; charset=UTF-8')
    .set('X-CSRF-Token', apiHelper.getCsrfToken())
    .send(data)
    .end((err, res) => {
      if (err) {
        return error(err);
      }
      return success(res.body);
    });
}

export function postFile(
  url = '',
  file = {},
  progress = () => {},
  success = defaultSuccess,
  error = defaultError
) {
  const formData = new FormData();
  formData.append('attachment[file]', file);

  request
    .post(url)
    .send(formData)
    .set('X-CSRF-Token', apiHelper.getCsrfToken())
    .on('progress', (e) => {
      progress(e);
    })
    .end((err, res) => {
      if (err) {
        error(err);
      } else {
        success(res.body);
      }
    });
}
