import { basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function updateSettings(id, data) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.campaignSettings.replace(':#id', id),
      { workflow: data },
      resolve,
      reject
    );
  });
}
