import SalesforceCustomizationActionTypes from 'web/modals/salesforceCustomization/libs/salesforceCustomizationActionTypes';
import { openSalesforcePostUpdatePopup } from './salesforceCustomizationPopupActionCreators';
import {
  SalesforceCustomizationPopupIds,
  SalesforceCustomizationErrorsIds,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import { getUserChannel } from 'web/services/pusherService';
import { PusherEvents } from 'web/libs/constants';
import {
  isInstalledStatus,
  isRunningInstallStatus,
} from 'web/modals/salesforceCustomization/helpers/salesforceCustomizationHelpers';
import isObject from 'lodash/isObject';
import { track } from 'web/services/mixpanelService';
import {
  MetadataApiEvents,
  MetadataApiProperties,
  MetadataApiPropertyTypes,
} from 'web/libs/mixpanelEvents';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export let unbindSalesforceCustomizationPE = () => {};

const trackResult = (propertyType, hasErrors) => {
  if (!hasErrors) {
    track(MetadataApiEvents.metadataApi, {
      [MetadataApiProperties.action]: propertyType,
    });
  }
};

export const salesforceCustomizationResultAction = (
  result = { hasErrors: false }
) => (dispatch, getState) => {
  const { status } = getState().salesforceCustomization;
  const { hasErrors } = result;

  if (isInstalledStatus(status) || isRunningInstallStatus(status)) {
    dispatch(
      openSalesforcePostUpdatePopup(
        SalesforceCustomizationPopupIds.salesforceCustomizationPostInstall
      )
    );
    trackResult(MetadataApiPropertyTypes.installed, hasErrors);
  } else {
    dispatch(
      openSalesforcePostUpdatePopup(
        SalesforceCustomizationPopupIds.salesforceCustomizationPostUninstall
      )
    );
    trackResult(MetadataApiPropertyTypes.uninstalled, hasErrors);
  }

  toutBackboneHelper.viewStatus({ complete: true });
};

export const salesforceCustomizationProgressCallback = (dispatch) => (
  data = {}
) => {
  const { progress, complete, version } = data;

  dispatch({
    type: SalesforceCustomizationActionTypes.progress.set,
    progress,
  });

  if (complete) {
    dispatch({
      type: SalesforceCustomizationActionTypes.completedVersions.add,
      version,
    });
  }
};

export const salesforceCustomizationErrorCallback = (dispatch) => (data) => {
  if (isObject(data)) {
    dispatch({
      type: SalesforceCustomizationActionTypes.errors.set,
      ...data,
    });
  } else {
    dispatch({
      type: SalesforceCustomizationActionTypes.errors.set,
      description: SalesforceCustomizationErrorsIds.pusherError,
    });
  }

  dispatch(salesforceCustomizationResultAction({ hasErrors: true }));
};

export const salesforceCustomizationStatusCallback = (dispatch) => (
  data = {}
) => {
  const { status, success, available_new_versions, installed_versions } = data;

  dispatch({
    type: SalesforceCustomizationActionTypes.status.set,
    status,
  });

  if (available_new_versions && installed_versions) {
    dispatch({
      type: SalesforceCustomizationActionTypes.versions.clear,
    });
    dispatch({
      type: SalesforceCustomizationActionTypes.availableVersions.set,
      availableVersions: available_new_versions,
    });
    dispatch({
      type: SalesforceCustomizationActionTypes.installedVersions.set,
      installedVersions: installed_versions,
    });
  }

  if (success) {
    dispatch(salesforceCustomizationResultAction());
  }
};

export const bindSalesforceCustomizationPusherEvents = () => (dispatch) => {
  const channel = getUserChannel();
  const errorCallback = salesforceCustomizationErrorCallback(dispatch);
  const progressCallback = salesforceCustomizationProgressCallback(dispatch);
  const statusChangedCallback = salesforceCustomizationStatusCallback(dispatch);

  unbindSalesforceCustomizationPE = () => {
    channel.unbind(
      PusherEvents.salesforceCustomizationProgressChange,
      progressCallback
    );
    channel.unbind(PusherEvents.salesforceCustomizationError, errorCallback);
    channel.unbind(
      PusherEvents.salesforceCustomizationStatusChanged,
      statusChangedCallback
    );
  };

  channel.bind(
    PusherEvents.salesforceCustomizationProgressChange,
    progressCallback
  );
  channel.bind(PusherEvents.salesforceCustomizationError, errorCallback);
  channel.bind(
    PusherEvents.salesforceCustomizationStatusChanged,
    statusChangedCallback
  );
};
