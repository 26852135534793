/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';
import EmailsCounter from 'web/commandCenter/components/commandCenterEmailsHeader/emailsCounter';
import {
  CommandCenterEmailsTabsEnum,
  COUNTERS_TABS_MAP,
} from 'web/commandCenter/libs/commandCenterConstants';
import './tabNav.scss';

class TabNav extends Component {
  _getSliderStyle = (selectedTabIndex) => {
    const { itemWidth } = this.props;
    const sliderStyle = {
      marginLeft: selectedTabIndex * itemWidth,
      width: itemWidth,
    };
    return sliderStyle;
  };

  _getItemStyle = (isSelected) => {
    const { itemWidth } = this.props;
    const itemStyle = {
      width: itemWidth,
    };

    if (isSelected) {
      itemStyle.color = '#1473E6';
    }
    return itemStyle;
  };

  _getCounterValue = (listItemValue) => {
    const { unreadEmailsCounters } = this.props;
    if (!unreadEmailsCounters) return 0;

    const mainTabs = [
      CommandCenterEmailsTabsEnum.undelivered.value,
      CommandCenterEmailsTabsEnum.pending.value,
      CommandCenterEmailsTabsEnum.sent.value,
    ];

    const counterIndex = mainTabs.includes(listItemValue)
      ? listItemValue
      : COUNTERS_TABS_MAP[listItemValue];

    return unreadEmailsCounters[counterIndex];
  };

  render() {
    const { list, selectedTabValue } = this.props;
    const selectedTabIndex = findIndex(
      list,
      (listItem) => listItem.value === selectedTabValue
    );

    return (
      <div className="tab-nav">
        <ul className="tab-nav-list">
          {list.map((listItem, index) => {
            const counter = this._getCounterValue(listItem.value);
            return (
              <li
                className="tab-nav-item"
                data-value={listItem.value}
                key={`tab-list-${index}`}
                onClick={this._onClick}
              >
                <div
                  className="tab-nav-link"
                  href="#"
                  style={this._getItemStyle(index === selectedTabIndex)}
                >
                  <span>{listItem.title}</span>
                  {counter > 0 && (
                    <EmailsCounter
                      counter={this._getCounterValue(listItem.value)}
                    />
                  )}
                </div>
                {listItem.alert && listItem.alert.count ? (
                  <div
                    className={classNames('tab-nav-alert', {
                      'blue-nav-alert': listItem.alert.color === 'blue',
                    })}
                  >
                    {listItem.alert.count}
                  </div>
                ) : null}
              </li>
            );
          })}
          <div
            className={`tab-nav-underline active-tab-${selectedTabIndex}`}
            style={this._getSliderStyle(selectedTabIndex)}
          />
        </ul>
      </div>
    );
  }

  _onClick = (e) => {
    this.props.onTabSelect(
      e.currentTarget.dataset.value || e.target.dataset.value
    );
  };
}

TabNav.propTypes = {
  itemWidth: PropTypes.number.isRequired,
  list: PropTypes.array,
  onTabSelect: PropTypes.func.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
  unreadEmailsCounters: PropTypes.object,
};

TabNav.defaultProps = {
  list: [],
  unreadEmailsCounters: null,
};

export default TabNav;
