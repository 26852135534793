import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';
import CommandCenterStat from '../commandCenterStat';
import { TextColors } from 'libs/constantsStyles';
import './commandCenterWelcome.scss';

const BUSY_LIMIT = 10;
const width = '33%';

class CommandCenterWelcome extends PureComponent {
  render() {
    const { loading, overdue, runningCampaigns, today } = this.props;
    const todayCount = today.emails + today.calls + today.additional;
    const overdueCount = overdue.emails + overdue.calls + overdue.additional;
    const noStats = todayCount === 0 && overdueCount === 0;

    return (
      <div className="command-center-welcome">
        {!noStats &&
          !loading && (
            <div className="command-center-welcome-stats">
              <div className="command-center-welcome-stats-stack">
                {todayCount > 0 && (
                  <div className="command-center-welcome-stats-margin command-center-welcome-today">
                    <div className="command-center-welcome-stats-text">
                      <FormattedHTMLMessage
                        id="web.commandCenter.welcome.plan"
                        values={{
                          busy: todayCount + overdueCount > BUSY_LIMIT,
                        }}
                      />
                    </div>
                    <div className="command-center-welcome-stats-group margin-top-half">
                      <CommandCenterStat
                        count={today.emails}
                        textId="web.commandCenter.welcome.emails"
                        width={width}
                      />
                      <CommandCenterStat
                        count={today.calls}
                        textId="web.commandCenter.welcome.calls"
                        width={width}
                      />
                      <CommandCenterStat
                        count={today.additional}
                        textId="web.commandCenter.welcome.tasks"
                        width={width}
                      />
                    </div>
                  </div>
                )}
                {overdueCount > 0 && (
                  <div className="command-center-welcome-overdue">
                    <div className="command-center-welcome-stats-text">
                      <FormattedHTMLMessage id="web.commandCenter.welcome.overdue" />
                    </div>
                    <div className="command-center-welcome-stats-group  margin-top-half">
                      <CommandCenterStat
                        color={TextColors.red}
                        count={overdue.emails}
                        textId="web.commandCenter.welcome.emails"
                        width={width}
                      />
                      <CommandCenterStat
                        color={TextColors.red}
                        count={overdue.calls}
                        textId="web.commandCenter.welcome.calls"
                        width={width}
                      />
                      <CommandCenterStat
                        color={TextColors.red}
                        count={overdue.additional}
                        textId="web.commandCenter.welcome.tasks"
                        width={width}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="command-center-welcome-stat-running">
                <CommandCenterStat
                  count={runningCampaigns}
                  flexBasis="100%"
                  textId="web.commandCenter.welcome.runningCampaigns"
                />
              </div>
            </div>
          )}
      </div>
    );
  }

  static getPopupTitle() {
    const hours = moment().hours();
    if (hours < 12) {
      return 'morning';
    } else if (hours < 17) {
      return 'afternoon';
    } else {
      return 'evening';
    }
  }
}

CommandCenterWelcome.propTypes = {
  loading: PropTypes.bool,
  overdue: PropTypes.object,
  runningCampaigns: PropTypes.number,
  today: PropTypes.object,
};

CommandCenterWelcome.defaultProps = {
  loading: false,
  overdue: {},
  runningCampaigns: 0,
  today: {},
};

export default CommandCenterWelcome;
