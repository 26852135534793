import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import PageView from 'components/layouts/page/view';
import Page from 'components/layouts/page';
import { isAdmin } from 'web/user/selectors/userSelectors';

import './withAdminCheck.scss';

export default function withAdminCheck(WrappedComponent) {
  function WithAdminCheck(props) {
    if (props.isAdmin) {
      return <WrappedComponent {...props} />;
    }

    return (
      <Page>
        <PageView className="with-admin-check">
          <h2>
            <FormattedHTMLMessage id="web.settings.adminSettings.accessDenied" />
          </h2>
        </PageView>
      </Page>
    );
  }

  WithAdminCheck.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
  };

  return compose(
    connect((state) => ({
      isAdmin: isAdmin(state),
    })),
    injectIntl
  )(WithAdminCheck);
}
