import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import castArray from 'lodash/castArray';
import { Regex } from 'web/libs/constants';
import Chips from '../chips';
import Chip from '../chip';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import compact from 'lodash/compact';
import { email as emailValidatorFactory } from 'web/form/helpers/validators';
import './emailBox.scss';

const HIGHER_LEVEL_SEPARATOR = /[,;]/;
const LOWER_LEVEL_SEPARATOR = /[\s]/;

class EmailBox extends Component {
  constructor(props) {
    super(props);
    this.emailValidator = emailValidatorFactory();
    this.newInputRef = null;
  }

  render() {
    const { className, value, placeholder } = this.props;
    const boxClassName = classNames('tout-email-box', className);
    const items = value.map((item) => ({
      id: item,
      value: item,
    }));

    return (
      <div className={boxClassName} onClick={this.handleAreaClicked}>
        <Chips
          items={items}
          onUpdate={this.handleUpdate}
          onDelete={this.handleDelete}
          stopPattern={Regex.emailStopPattern}
          validator={this.emailValidator}
        >
          <Chip
            ref={(el) => (this.newInputRef = el)}
            editing
            value=""
            onChange={this.handleNewChange}
            onRemovePrevious={this.handleNewRemovePreviouse}
            stopPattern={Regex.emailStopPattern}
            placeholder={placeholder}
          />
        </Chips>
      </div>
    );
  }

  handleAreaClicked = () => {
    if (this.newInputRef) {
      this.newInputRef.focus();
    }
  };

  handleNewChange = (itemValue) => {
    const { value, onChange } = this.props;
    const items = this.parseItems(itemValue);
    const newValue = uniq([...value, ...items]);
    onChange(newValue);
  };

  handleNewRemovePreviouse = () => {
    const { value, onChange } = this.props;
    const newValue = value.slice(0, -1);
    onChange(newValue);
  };

  handleUpdate = (id, itemValue) => {
    const { value, onChange } = this.props;
    const newValue = [...value];
    const items = this.parseItems(itemValue);
    const currentIndex = value.findIndex((item) => item === id);

    newValue.splice(currentIndex, 1, ...items);
    onChange(uniq(newValue));
    if (this.newInputRef) {
      this.newInputRef.focus();
    }
  };

  handleDelete = (id) => {
    const { value, onChange } = this.props;
    const ids = castArray(id);
    const newValue = value.filter((item) => !ids.includes(item));
    onChange(newValue);
  };

  findEmailsInString = (rawValue) => {
    if (Regex.emailWithName.test(rawValue)) {
      const result = Regex.emailWithName.exec(rawValue);
      return [result[1]];
    } else if (Regex.emailContains.test(rawValue)) {
      return rawValue
        .split(LOWER_LEVEL_SEPARATOR)
        .map((item) => item.trim())
        .map((item) => {
          if (Regex.emailWithName.test(item)) {
            const result = Regex.emailWithName.exec(item);
            return result[1];
          }
          return item;
        })
        .filter((item) => item);
    }
    return [rawValue];
  };

  parseItems(rawValue) {
    return compact(
      flatMap(
        rawValue
          .trim()
          .split(HIGHER_LEVEL_SEPARATOR)
          .map((item) => item.trim()),
        (item) => this.findEmailsInString(item)
      )
    );
  }
}

EmailBox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

EmailBox.defaultProps = {
  className: '',
  value: [],
  placeholder: '',
  onChange: () => {},
};

export default EmailBox;
