import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import CustomFieldsEditing from './editing';
import CustomFieldsDetails from './details';

class CustomFieldsCard extends Component {
  render() {
    const {
      actionCreators: {
        addCustomFieldsRow,
        closeCardEditing,
        removeCustomFieldsRow,
        saveCustomFields,
        updateCustomFieldsEditState,
        validateCustomField,
      },
      customFieldsCardHasChanges,
      customFieldsEditState,
      hiddenButtons,
      isEditing,
      person,
    } = this.props;

    if (isEditing) {
      return (
        <CustomFieldsEditing
          addRow={addCustomFieldsRow}
          cancel={closeCardEditing}
          editState={customFieldsEditState}
          hasChanges={customFieldsCardHasChanges}
          removeRow={removeCustomFieldsRow}
          save={saveCustomFields}
          updateEditState={updateCustomFieldsEditState}
          validateCustomField={validateCustomField}
          hiddenButtons={hiddenButtons}
        />
      );
    } else {
      return (
        <CustomFieldsDetails
          edit={this.edit}
          customFields={person.customFields}
        />
      );
    }
  }

  edit = () =>
    this.props.actionCreators.openCardEditing(PersonDetailsCards.customFields);
}

CustomFieldsCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  customFieldsCardHasChanges: PropTypes.bool.isRequired,
  customFieldsEditState: PropTypes.array.isRequired,
  hiddenButtons: PropTypes.bool,
  isEditing: PropTypes.bool,
  person: PropTypes.object.isRequired,
};

CustomFieldsCard.defaultProps = {
  hiddenButtons: false,
  isEditing: false,
};

export default CustomFieldsCard;
