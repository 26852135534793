/**
 *
 * TextCustomCell
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './textCustomCell.scss';

class TextCustomCell extends PureComponent {
  render() {
    const { className, formatText, rowData, style, wrapLines } = this.props;
    const useTextOverflow = !wrapLines || wrapLines === 1;
    const containerClasses = classNames(className, 'cell-text-custom', {
      'text-overflow': useTextOverflow,
    });
    const textClasses = classNames({
      [`text-overflow-${wrapLines}`]: wrapLines > 1 && wrapLines <= 5,
      'text-overflow': useTextOverflow,
    });
    const data = formatText(rowData);

    return (
      <div className={containerClasses} style={style}>
        <span className={textClasses}>{data}</span>
      </div>
    );
  }
}

TextCustomCell.propTypes = {
  className: PropTypes.string,
  formatText: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  style: PropTypes.object,
  wrapLines: PropTypes.number,
};

TextCustomCell.defaultProps = {
  className: '',
  formatText: () => {},
  rowData: {},
  style: {},
  wrapLines: 1,
};

export default TextCustomCell;
