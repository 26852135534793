import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  EditTemplateTabs,
  EditTemplateSlideOutWidth,
} from '../../libs/editTemplateSlideOutConstants';
import SlideOutPane, {
  PaneBackgroundColors,
} from 'components/layouts/slideOutPane';
import EditTemplateActionBar from '../editTemplateActionBar';
import SlideOutPaneHeader from 'components/layouts/slideOutPane/header';
import Page from 'components/layouts/page';
import TabNavBar from 'components/layouts/page/tabNavBar';
import TabNav from 'components/layouts/page/tabNav';
import View from 'components/layouts/page/view';
import TabAlert from '../tabAlert';
import EditTemplateHeaderInfo from '../editTemplateHeaderInfo';
import TemplateView from '../templateView';
import AnalyticsView from '../analyticsView';
import EmailsView from '../emailsView';
import CampaignsView from '../campaignsView';
import Icon, { IconColors } from 'components/buttons/icon';
import bind from 'memoize-bind';
import './editTemplateSlideOut.scss';

class EditTemplateSlideOut extends Component {
  constructor(props) {
    super(props);

    this.scrollRef = null;

    const {
      intl: { formatMessage },
    } = this.props;

    this.tabs = [
      {
        title: formatMessage({ id: 'common.template' }),
        value: EditTemplateTabs.template,
        width: 135,
      },
      {
        title: formatMessage({ id: 'common.analytics' }),
        value: EditTemplateTabs.analytics,
        width: 140,
      },
      {
        title: formatMessage({ id: 'common.emails' }),
        value: EditTemplateTabs.emails,
        width: 185,
      },
      {
        title: formatMessage({ id: 'common.campaigns' }),
        value: EditTemplateTabs.campaigns,
        width: 150,
      },
    ];
  }

  render() {
    const {
      actionCreators,
      archivedCategoryId,
      header,
      tab,
      tabAlert,
      tabLoading,
      template,
      user,
      viewer,
      userCanEditTemplates,
    } = this.props;

    return (
      <SlideOutPane
        backgroundColor={PaneBackgroundColors.white}
        className="edit-template-slide-out"
        open={tab.length > 0}
        transitionDuration={0.25}
        width={EditTemplateSlideOutWidth}
      >
        <EditTemplateActionBar
          actionCreators={actionCreators}
          archivedCategoryId={archivedCategoryId}
          tabLoading={tabLoading}
          template={template}
          user={user}
          viewer={viewer}
        />
        <Page>
          <SlideOutPaneHeader
            rightChildren={
              <EditTemplateHeaderInfo
                template={template}
                user={user}
                viewer={viewer}
              />
            }
            leftChildren={this._getFavoriteIcon()}
            saveEdit={
              userCanEditTemplates ? actionCreators.saveName : undefined
            }
            text={header}
          />
          <TabNavBar border>
            <TabNav
              list={this.tabs}
              onTabSelect={actionCreators.onTabSelect}
              selectedTabValue={tab}
            />
          </TabNavBar>
          <View
            ref={this._setScrollRef}
            className="edit-template-slide-out-page-view"
          >
            <TabAlert actionCreators={actionCreators} alert={tabAlert} />
            {this._getTabView()}
          </View>
        </Page>
      </SlideOutPane>
    );
  }

  _setScrollRef = (node) => {
    this.scrollRef = node;
  };

  setScrollTop = (pixels) => {
    // eslint-disable-next-line react/no-find-dom-node
    const ref = ReactDOM.findDOMNode(this.scrollRef);
    if (ref) {
      ref.scrollTop += pixels;
    }
  };

  _getTabView = () => {
    const {
      actionCreators,
      analytics,
      analyticsViewState,
      editing,
      emails,
      emailsViewState,
      recommendEditState,
      tab,
      tabLoading,
      template,
      templateEditState,
      user,
      viewer,
    } = this.props;

    switch (tab) {
      case EditTemplateTabs.template:
      default:
        return (
          <TemplateView
            actionCreators={actionCreators}
            editing={editing}
            loading={tabLoading}
            recommendEditState={recommendEditState}
            setScrollTop={this.setScrollTop}
            template={template}
            templateEditState={templateEditState}
          />
        );
      case EditTemplateTabs.analytics:
        return (
          <AnalyticsView
            actionCreators={actionCreators}
            analytics={analytics}
            loading={tabLoading}
            {...analyticsViewState}
          />
        );
      case EditTemplateTabs.emails:
        return (
          <EmailsView
            actionCreators={actionCreators}
            emails={emails}
            loading={tabLoading}
            user={user}
            viewer={viewer}
            {...emailsViewState}
          />
        );
      case EditTemplateTabs.campaigns:
        return <CampaignsView templateId={template.id} />;
    }
  };

  _getFavoriteIcon = () => {
    const {
      template: { favorite },
      tabLoading,
      actionCreators: { setFavorite },
    } = this.props;
    return !tabLoading ? (
      <Icon
        color={IconColors.yellow}
        className={
          (favorite && 'slideout-favorite-icon-filled') ||
          'slideout-favorite-icon-unfilled'
        }
        onClick={bind(setFavorite, this, !favorite)}
      />
    ) : null;
  };
}

EditTemplateSlideOut.propTypes = {
  analytics: PropTypes.object.isRequired,
  analyticsViewState: PropTypes.object.isRequired,
  archivedCategoryId: PropTypes.number.isRequired,
  actionCreators: PropTypes.object.isRequired,
  editing: PropTypes.object.isRequired,
  emails: PropTypes.array.isRequired,
  emailsViewState: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  recommendEditState: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  tabAlert: PropTypes.object.isRequired,
  tabLoading: PropTypes.bool.isRequired,
  template: PropTypes.object.isRequired,
  templateEditState: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
  userCanEditTemplates: PropTypes.bool,
};

EditTemplateSlideOut.defaultProps = {
  userCanEditTemplates: true,
};

export default injectIntl(EditTemplateSlideOut);
