import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import './composeTemplatesTemplateCard.scss';
import Highlighter from 'components/highlighter';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { removeHtml } from 'web/shared/helpers/commonHelper';

const getHighlightedBody = (body, highlightTerm) => {
  const removedHtmlBody = removeHtml(body);
  if (!highlightTerm) return removedHtmlBody;

  const highlightTermIndex = removedHtmlBody.search(
    new RegExp(highlightTerm, 'i')
  );
  return highlightTermIndex > 0
    ? `...${removedHtmlBody.slice(highlightTermIndex)}`
    : removedHtmlBody;
};

const renderCardData = (text, highlightTerm) =>
  highlightTerm.length ? (
    <Highlighter text={text} highlightTerm={highlightTerm} mark />
  ) : (
    text
  );

const ComposeTemplatesTemplateCard = (props) => {
  const {
    template,
    handleClick,
    highlightTerm,
    isSelected,
    intl: { formatMessage },
  } = props;

  const {
    name,
    reply_rate: replyRate,
    open_rate: openRate,
    clickthrough_rate: clickthroughRate,
    body,
    subject,
  } = template;

  return (
    <Card
      className={classNames('compose-templates-card', {
        selectedTemplateCard: isSelected,
      })}
      onClick={() => handleClick(template)}
    >
      <div className="compose-templates-card-header">
        <div className="compose-templates-card-name">
          <h5 className="template-name text-overflow" title={name}>
            {renderCardData(name, highlightTerm)}
          </h5>
        </div>
        <div className="compose-templates-card-metrics">
          <span>
            <i className="tout-icon-viewrate" /> {openRate}%
          </span>
          <span>
            <i className="tout-icon-clickrate" /> {clickthroughRate}%
          </span>
          <span>
            <i className="tout-icon-replyrate" /> {replyRate}%
          </span>
        </div>
      </div>
      <div className="compose-templates-card-subject text-overflow">
        {`${formatMessage({ id: 'common.subject' })}: `}
        {renderCardData(subject, highlightTerm)}
      </div>
      <div className="compose-templates-card-body text-overflow">
        {renderCardData(getHighlightedBody(body, highlightTerm), highlightTerm)}
      </div>
    </Card>
  );
};

ComposeTemplatesTemplateCard.propTypes = {
  template: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  highlightTerm: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  intl: intlShape.isRequired,
};

ComposeTemplatesTemplateCard.defaultProps = {
  isSelected: false,
};

export default injectIntl(ComposeTemplatesTemplateCard);
