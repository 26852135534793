import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { SingleDatePicker, toMomentObject } from 'react-dates';
import { ANCHOR_RIGHT } from 'react-dates/constants';
import { default as I18N, moment } from 'languages'; //eslint-disable-line import/no-named-default

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.placeholderText = props.intl.formatMessage({
      id: 'common.selectDate',
    });

    this.state = {
      focused: false,
    };
  }

  render() {
    const {
      className,
      date,
      error,
      onDateChange,
      intl: ignored1,
      rightAlign,
      withPortal,
      ...other
    } = this.props;
    const { focused } = this.state;
    return (
      <div className={classNames('datePicker', className, { error })}>
        <SingleDatePicker
          {...other}
          anchorDirection={rightAlign ? ANCHOR_RIGHT : undefined}
          date={this._momentizeDate(date)}
          focused={focused}
          numberOfMonths={1}
          onDateChange={onDateChange}
          onFocusChange={this.onFocusChange}
          placeholder={this.placeholderText}
          renderMonth={I18N.getLocalizedMonthYear}
          withPortal={withPortal}
          hideKeyboardShortcutsPanel
        />
      </div>
    );
  }

  _momentizeDate(date) {
    const dateObj = moment.isMoment(date) ? date : toMomentObject(date);
    if (!dateObj) {
      return null;
    }
    return dateObj;
  }

  onFocusChange = ({ focused }) => {
    this.setState(() => ({ focused }));
  };
}

DatePicker.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // either date object, moment().format('L'), or 'YYYY-MM-DD'
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  intl: intlShape.isRequired,
  onDateChange: PropTypes.func.isRequired, // must handle moment arguments,
  rightAlign: PropTypes.bool,
  withPortal: PropTypes.bool,
};

DatePicker.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  rightAlign: false,
  withPortal: false,
};

export default injectIntl(DatePicker);
