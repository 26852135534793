import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './shareCampaign.scss';
import Select2 from 'components/selects/select2';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import { injectIntl, intlShape } from 'react-intl';
import { getTeams } from 'web/shared/services/accountService';
import { canUnshareCampaign } from 'web/campaigns/campaignsTab/services/campaignService';

class ShareCampaign extends Component {
  componentWillMount() {
    const { updateCampaignSharedWith, selectedCampaign } = this.props;
    updateCampaignSharedWith(selectedCampaign.team_ids);
  }

  updateShared = (val) => {
    const { updateCampaignSharedWith } = this.props;
    updateCampaignSharedWith(map(val, 'value'));
  };

  _canClear = (id) => {
    const { selectedCampaign } = this.props;
    return canUnshareCampaign(selectedCampaign, id);
  };

  _generateOptions = () => {
    const { selectedCampaign } = this.props;
    const usersTeams = getTeams().map((element) => ({
      value: element.id,
      label: element.name,
      clearableValue: this._canClear(element.id),
    }));
    const campaignSharedTeams = getTeams(selectedCampaign.team_ids).map(
      (element) => ({
        value: element.id,
        label: element.name,
        clearableValue: this._canClear(element.id),
      })
    );
    return unionBy(usersTeams, campaignSharedTeams, 'value');
  };

  render() {
    const {
      intl: { formatMessage },
      newSharedWith,
    } = this.props;
    return (
      <div className="share-campaign">
        <label className="share-campaign-label">
          {formatMessage({ id: 'web.campaigns.shareWith' })}
        </label>
        <Select2
          noResultsText={formatMessage({ id: 'web.campaigns.noGroups' })}
          onChange={this.updateShared}
          options={this._generateOptions()}
          value={newSharedWith}
          placeholder={formatMessage({ id: 'web.campaigns.chooseTeams' })}
          disabled={false}
          clearable={false}
          multi
        />
      </div>
    );
  }
}

ShareCampaign.propTypes = {
  intl: intlShape.isRequired,
  newSharedWith: PropTypes.array.isRequired,
  updateCampaignSharedWith: PropTypes.func.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
};

export default injectIntl(ShareCampaign);
