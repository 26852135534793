import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeViewAlert as closeViewAlertAC } from 'web/view/actionCreators/alertActionCreators';
import TrackingAlert from '../components/alerts';

const TrackingAlertsContainer = (props) => <TrackingAlert {...props} />;

TrackingAlertsContainer.propTypes = {
  viewAlert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  viewAlert: state.viewAlert,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeViewAlert: closeViewAlertAC,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackingAlertsContainer);
