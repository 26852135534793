import TimezonesActionTypes from '../libs/timezonesActionTypes';
import UserActionTypes from 'web/user/libs/userActionTypes';
import { TimezonesCategoriesOrderedEnum } from '../libs/timezonesConstants';
import { getTimezones as getTimezonesCall } from '../services/timezonesService';
import { parseTimezones } from '../helpers/timezonesParsers';

/* Timezones don't change often so only are loaded once */
export const getTimezones = (force = false) => (dispatch, getState) => {
  const { timezones } = getState();

  if (force || timezones.length === 0) {
    dispatch({ type: TimezonesActionTypes.fetching });
    getTimezonesCall()
      .then((data) => {
        const parsedData = parseTimezones(data);
        dispatch({
          type: TimezonesActionTypes.setTimezones,
          timezones: parsedData,
        });

        /* User data does not use standard timezone name so update */
        if (parsedData.length >= 2) {
          const userTimezone = getState().user.time_zone;
          const timezone = parsedData[
            TimezonesCategoriesOrderedEnum.international
          ].find((tz) => tz.displayName === userTimezone);

          if (timezone && timezone.value) {
            dispatch({
              type: UserActionTypes.setTimezone,
              timezone: timezone && timezone.value,
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: TimezonesActionTypes.getFailure, error });
      })
      .finally(() => {
        dispatch({ type: TimezonesActionTypes.fetchingDone });
      });
  }
};
