import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import closest from 'closest';
import './player.scss';

const ManageRecordingPlayer = (props) => {
  const { playingAudio } = props;
  const playerSize = 20;
  const playerRef = useRef(null);

  useEffect(
    () => {
      const id =
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9);

      initPlayer(playerRef.current, `audio${id}`);
    },
    [playerRef]
  );

  function initPlayer(elem, id) {
    const audio = elem.querySelector('audio');
    audio.setAttribute('id', id);

    const playerSVG = elem.querySelector('svg');
    playerSVG.setAttribute('id', `playable${id}`);

    const defaults = {
      audioObj: 'audio',
      controlsObj: '.controls',
      pc: 298.1371428256714,
      playObj: 'playable',
      precache: '.precache-bar',
      progress: '.progress-bar',
      pt: playerSVG.createSVGPoint(),
      thisSelector: '.recordingNoticePlayer',
    };

    const settings = Object.assign({}, defaults);

    const stopAllSounds = () => {
      document.addEventListener(
        'play',
        function(event) {
          const audios = elem.getElementsByTagName('audio');

          for (let i = 0; i < audios.length; i++) {
            const audio = audios[i];

            if (audio !== event.target) {
              audio.pause();

              if (audio.parentElement.querySelector('.playing')) {
                audio.parentElement
                  .querySelector('.playing')
                  .setAttribute('class', 'paused');
              }
            }
          }
        },
        true
      );
    };

    const setGraphValue = (obj, val, elem) => {
      const audioObj = elem.querySelector(settings.audioObj);
      const pc = settings.pc;

      obj.style.strokeDashoffset =
        pc - parseFloat((val / audioObj.duration) * pc, 10);

      if (val === 0) {
        obj.classList.add(obj, 'done');
      }

      if (val === audioObj.duration) {
        elem.querySelector('svg').setAttribute('class', 'paused');
      }
    };

    const reportPosition = (elem, audioId) => {
      setGraphValue(
        elem.querySelector(settings.progress),
        audioId.currentTime,
        elem
      );
    };

    elem.querySelector(settings.controlsObj).addEventListener(
      'click',
      function(event) {
        const clickedElem = closest(event.target, settings.thisSelector);
        const clickedElemData = {
          activeAudio: clickedElem.querySelector(settings.audioObj),
          el: clickedElem,
          playData: clickedElem.querySelector('[data-play]'),
          precache: clickedElem.querySelector(settings.precache),
        };

        clickedElemData.class = clickedElemData.playData.getAttribute('class');

        const clickedState = clickedElemData.playData
          .getAttribute('class')
          .replace('playable', '')
          .trim();

        switch (clickedState) {
          case 'not-started':
            stopAllSounds();
            clickedElemData.activeAudio.play();

            // eslint-disable-next-line no-case-declarations
            const audioElem = document.getElementById(
              clickedElem.querySelector(settings.audioObj).getAttribute('id')
            );

            audioElem.addEventListener('timeupdate', function() {
              reportPosition(clickedElem, audioElem);
            });

            clickedElemData.playData.setAttribute('class', 'playing');
            break;

          case 'playing':
            clickedElemData.playData.setAttribute('class', 'playable paused');
            clickedElemData.activeAudio.pause();
            break;

          case 'paused':
            clickedElemData.playData.setAttribute('class', 'playable playing');
            clickedElemData.activeAudio.play();
            break;

          case 'ended':
            clickedElemData.activeAudio.setAttribute(
              'class',
              'not-started playable'
            );
            break;
        }
      },
      false
    );
  }

  return (
    <i className="recordingNoticePlayer" ref={playerRef}>
      <audio
        className="recordingNoticePlayer-listen"
        data-size={playerSize}
        preload="none"
        src={playingAudio}
      />
      <svg
        className="not-started playable"
        data-play="playable"
        height="30"
        id="playable"
        viewBox="0 0 100 100"
        width="30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="shape">
          <circle
            className="progress-track"
            cx="50"
            cy="50"
            fill="none"
            r="47.45"
            stroke="#becce1"
            strokeLinecap="round"
            strokeOpacity="0.25"
            strokeWidth="5"
          />
          <circle
            className="precache-bar"
            cx="50"
            cy="50"
            fill="none"
            r="47.45"
            stroke="#302F32"
            strokeLinecap="round"
            strokeOpacity="0.25"
            strokeWidth="5"
            transrm="rotate(-90 50 50)"
          />
          <circle
            className="progress-bar"
            cx="50"
            cy="50"
            fill="none"
            r="47.45"
            stroke="#009EF8"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="5"
            transform="rotate(-90 50 50)"
          />
        </g>
        <circle
          className="controls"
          cx="50"
          cy="50"
          fill="#000000"
          opacity="0.0"
          pointerEvents="all"
          r="45"
          stroke="none"
        />
        <g className="control pause">
          <line
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeWidth="8"
            x1="40"
            x2="40"
            y1="35"
            y2="65"
          />
          <line
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeWidth="8"
            x1="60"
            x2="60"
            y1="35"
            y2="65"
          />
        </g>
        <g className="control play">
          <polygon fill="#009EF8" points="40,30 70,50 40,70" strokeWidth="0" />
        </g>
        <g className="control stop">
          <rect
            fill="none"
            height="30"
            stroke="#000000"
            strokeWidth="1"
            width="30"
            x="35"
            y="35"
          />
        </g>
      </svg>
    </i>
  );
};

ManageRecordingPlayer.propTypes = {
  playingAudio: PropTypes.string.isRequired,
};

export default ManageRecordingPlayer;
