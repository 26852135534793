import ActionTypes from 'table/modules/actionTypes';

export function columnFilterChecked(
  state = null,
  {
    type = '',
    checked = false,
    columns = [],
    id = '',
    checkedIds = {},
    stored = {},
  }
) {
  switch (type) {
    case ActionTypes.columnFilterSetColumn: {
      const temp = {};
      columns.forEach((column) => {
        if (typeof stored[column.id] === 'boolean') {
          temp[column.id] = stored[column.id];
        } else {
          temp[column.id] = !column.initHide;
        }
      });
      return temp;
    }
    case ActionTypes.columnFilterChangeColumn:
      if (id.length) {
        return { ...state, [id]: checked };
      } else {
        return state;
      }
    case ActionTypes.columnFilterChangeColumns:
      if (checkedIds) {
        return {
          ...state,
          ...checkedIds,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}
