import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import TextButton from 'components/buttons/text';
import Details from './details';
import Editing from './editing';

class SignatureCard extends Component {
  saveSignature = (editorId) => {
    const {
      identity,
      actionCreators: { updateIdentity, setSignatureCardEditMode },
      emailComposeEditors,
    } = this.props;

    const editorInstance = emailComposeEditors[editorId];
    const newIdentity = {
      ...identity,
      signature: editorInstance.getContent(),
    };
    updateIdentity(newIdentity);
    setSignatureCardEditMode(false);
  };

  render() {
    const {
      isEditing,
      onChangeEditing,
      identity,
      editIdentitySignatureInstance,
      actionCreators,
      isSignatureFormDirty,
    } = this.props;

    return (
      <div className="edit-identity-card signature-card">
        {!isEditing && (
          <div className="edit-button-panel">
            <TextButton onClick={onChangeEditing} underline>
              <FormattedMessage id="common.edit" />
            </TextButton>
          </div>
        )}
        {isEditing
          ? identity && (
              <Editing
                actionCreators={actionCreators}
                isSignatureFormDirty={isSignatureFormDirty}
                onCancel={onChangeEditing}
                onSave={this.saveSignature}
                signature={editIdentitySignatureInstance}
              />
            )
          : identity && (
              <Details signaturePreview={identity.preview_signature} />
            )}
      </div>
    );
  }
}

SignatureCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  editIdentitySignatureInstance: PropTypes.string,
  emailComposeEditors: PropTypes.object.isRequired,
  identity: PropTypes.object,
  intl: intlShape.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSignatureFormDirty: PropTypes.bool.isRequired,
  onChangeEditing: PropTypes.func.isRequired,
};

export default injectIntl(SignatureCard);
