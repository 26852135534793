import SharingSettingsActionTypes from '../libs/sharingSettingsActionTypes';
import {
  TabValues,
  SharingSettingsAlertIds,
} from '../libs/sharingSettingsConstants';
import { getCategories } from '../selectors/sharingSettingsSelectors';
import {
  updateCategoryPermissionCampaign,
  updateCategoryPermissionTemplate,
  updateSharingPermissionsEnabled,
  getSharingPermissionsEnabled,
} from 'web/categories/services/permissionsCategoriesService';
import { setSharingSettingsAlert } from './sharingSettingsActionCreators';

export const setTableLoading = (loading) => ({
  loading,
  type: SharingSettingsActionTypes.setTableLoading,
});

export const setTableFetching = (fetching) => ({
  fetching,
  type: SharingSettingsActionTypes.setTableFetching,
});

export const setSharingPermissionsEnabled = (enabled) => ({
  enabled,
  type: SharingSettingsActionTypes.setPermissionsEnabled,
});

export const setSharing = (teamId, isSharing) => (dispatch, getState) => {
  const state = getState();
  const {
    settingsSharingSelectedCategoryIndex: categoryIndex,
    settingsSharingSelectedTabValue: tab,
  } = state;
  const category = getCategories(state)[categoryIndex];

  if (!category || !category.id) {
    return;
  }

  dispatch(setTableLoading(true));

  const update =
    (tab === TabValues.templates && updateCategoryPermissionTemplate) ||
    updateCategoryPermissionCampaign;
  update(category.id, [teamId], isSharing)
    .then((teams = []) => {
      dispatch({
        teams,
        type: SharingSettingsActionTypes.updateTeams,
      });
      dispatch(setTableLoading(false));
    })
    .catch(() => {
      dispatch(setTableLoading(false));
      dispatch(
        setSharingSettingsAlert(SharingSettingsAlertIds.settingSharingIssue)
      );
    });
};

export const getPermissionsEnabled = () => (dispatch) => {
  getSharingPermissionsEnabled().then(({ enabled }) => {
    dispatch(setSharingPermissionsEnabled(enabled));
  });
};

export const setPermissionsEnabled = (enabled) => (dispatch, getState) => {
  const {
    settingsSharingPermissionsEnabled: permissionsEnabledOldValue,
  } = getState();
  dispatch(setSharingPermissionsEnabled(enabled));
  updateSharingPermissionsEnabled(enabled)
    .then(() => {})
    .catch(() => {
      dispatch(setSharingPermissionsEnabled(permissionsEnabledOldValue));
    });
};
