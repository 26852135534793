export const updatePersonState = (object, prop = '', state = {}) => {
  if (!state[prop] || !object) {
    return state;
  }

  let array = [];
  const index = state[prop].findIndex(({ id }) => id === object.id);
  if (index > -1) {
    array = [
      ...state[prop].slice(0, index),
      object,
      ...state[prop].slice(index + 1),
    ];
  } else {
    array = [...state[prop], object];
  }
  return { ...state, [prop]: array };
};

export const removeFromPersonState = (id, prop = '', state = {}) => {
  if (!state[prop] || !id) {
    return state;
  }

  const index = state[prop].findIndex(({ id: objectId }) => objectId === id);
  if (index > -1) {
    return {
      ...state,
      [prop]: [...state[prop].slice(0, index), ...state[prop].slice(index + 1)],
    };
  } else {
    return state;
  }
};
