import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './dynamicFieldsIncorrect.scss';

class DynamicFieldsIncorrect extends Component {
  render() {
    const { fieldsIncorrect } = this.props;

    const incorrectItems = fieldsIncorrect.map((field, i) => (
      <li key={`dynamic-fields-incorrect-${field || i}`}>{field}</li>
    ));

    return (
      <div className="dynamic-fields-incorrect">
        <div className="explanation margin-bottom-full">
          <FormattedMessage id="dynamicFieldsIncorrect.explanation" />
        </div>
        <div className="fields-list">
          <ul>{incorrectItems}</ul>
        </div>
      </div>
    );
  }
}

DynamicFieldsIncorrect.propTypes = {
  fieldsIncorrect: PropTypes.array.isRequired,
};

export const DYNAMIC_FIELDS_INCORRECT_POPUP_WIDTH = 510;
export default DynamicFieldsIncorrect;
