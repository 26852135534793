import { getFilterDatesRequired } from 'web/tables/helpers/itemsTableFilters';
import { track } from 'web/services/mixpanelService';
import {
  TablesEvents,
  TablesProperties,
  TablesSources,
} from 'web/libs/mixpanelEvents';
import DiagnosticsActionTypes from '../libs/diagnosticsActionTypes';
import { TablesFiltersConstants } from 'web/tables/libs/tablesFiltersConstants';

export const setDatesShown = (id, dates) => ({
  id,
  type: DiagnosticsActionTypes.datesShown,
  ...dates,
});

/* Initial call will get base range that will always be stored in state. Custom selections will
 * add to the collection but will be cleared when leaving custom selection.
*/
export function onDatesSmartFilterChange(id, value, start, end) {
  return (dispatch) => {
    dispatch({ id, type: DiagnosticsActionTypes.datesSmartFilter, value });

    const dates = getFilterDatesRequired(value, start, end);

    dispatch({ id, type: DiagnosticsActionTypes.datesShown, ...dates });
    dispatch({ type: DiagnosticsActionTypes.filterItems, ...dates });

    track(TablesEvents.dateChange, {
      [TablesProperties.source]: TablesSources.diagnostics,
      [TablesProperties.datesRange]: value,
    });
  };
}

export function onStatusSmartFilterChange(id, value) {
  return (dispatch, getState) => {
    dispatch({ id, type: DiagnosticsActionTypes.statusSmartFilter, value });

    const {
      tablesTasksDatesSmartFilterValue: datesValueMap,
      tablesTasksSelectedDates: selectedDatesMap,
    } = getState();
    const datesValue = datesValueMap.get(id);
    const isCustom = datesValue === TablesFiltersConstants.custom;
    if (isCustom) {
      dispatch({
        id,
        type: DiagnosticsActionTypes.datesSmartFilterReset,
        value: datesValue,
      });
    }

    const selectedDates = selectedDatesMap.get(id).toJS();
    const dates = getFilterDatesRequired(
      datesValue,
      selectedDates.start,
      selectedDates.end
    );
    dispatch(setDatesShown(id, dates));

    track(TablesEvents.viewChange, {
      [TablesProperties.source]: TablesSources.diagnostics,
      [TablesProperties.view]: value,
      [TablesProperties.datesRange]: datesValue,
    });
  };
}

export function onTableMount(id) {
  return {
    id,
    type: DiagnosticsActionTypes.tableInit,
  };
}
