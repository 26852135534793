import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import HighSpot from './highSpot';
import Box from './box';
import Slack from './slack';
import './contentPartners.scss';

const ContentPartners = (props) => {
  const { isHighSpotEnabled } = props;

  return (
    <div className="admin-settings-general-content-partners">
      <h3>
        <FormattedMessage id="web.settings.adminSettings.general.contentPartners.title" />
      </h3>
      {isHighSpotEnabled && <HighSpot {...props} />}
      <Box {...props} />
      <Slack {...props} />
    </div>
  );
};

ContentPartners.propTypes = {
  isHighSpotEnabled: PropTypes.bool.isRequired,
};

export default ContentPartners;
