import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigateToAdminGeneralTab } from 'web/services/routerService';
import { getActiveTab } from '../selectors/generalPageSelectors';
import General from '../components/general';
import withAdminCheck from 'components/hocs/withAdminCheck';
import { compose } from 'redux';

const GeneralContainer = (props) => (
  <General {...props} onTabSelect={navigateToAdminGeneralTab} />
);

GeneralContainer.propTypes = {
  tab: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  tab: getActiveTab(state),
});

export default compose(
  withAdminCheck,
  connect(mapStateToProps)
)(GeneralContainer);
