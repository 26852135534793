import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './radio.scss';

export default class Radio extends Component {
  render() {
    const {
      checked,
      classes,
      className,
      disabled,
      inline,
      label,
      name,
    } = this.props;

    const labelClassName = classNames('tout-radio', classes, className, {
      inline,
    });

    return (
      <label className={labelClassName}>
        <input
          checked={checked || false}
          data-checked={checked || false}
          disabled={disabled || false}
          name={name}
          onChange={this._onChange}
          onClick={this._onClickStop}
          type="radio"
        />
        {label}
        <div className="indicator" onClick={this._onClickStop} />
      </label>
    );
  }

  _onClickStop(e) {
    e.stopPropagation();
  }

  _onChange = () => {
    const { onChange, value } = this.props;
    onChange(value);
  };
}

Radio.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClickEvent: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

Radio.defaultProps = {
  checked: false,
  className: '',
  classes: '',
  disabled: false,
  inline: false,
  label: '',
  onChange: () => {},
  onClickEvent: () => {},
  value: null,
};
