import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TrackingAlertIds } from '../../libs/trackingConstants';
import Alert, { AlertColors } from 'components/alert';
import './trackingAlert.scss';

class TrackingAlert extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      viewAlert: { id: alertId },
      closeViewAlert,
    } = this.props;

    const defaultAlert = {
      classes: 'tracking-alert',
      color: AlertColors.red,
      onClose: closeViewAlert,
    };

    switch (alertId) {
      case TrackingAlertIds.success:
        return {
          ...defaultAlert,
          color: AlertColors.green,
          textId: 'common.alert.success',
        };
      case TrackingAlertIds.failure:
        return {
          ...defaultAlert,
          textId: 'common.alert.failure',
        };
      case TrackingAlertIds.cnameNotValid:
        return {
          ...defaultAlert,
          textId:
            'web.settings.adminSettings.tracking.customDomainTracking.form.customDomain.cnameNotValid',
          manual: true,
          html: true,
        };
      case TrackingAlertIds.unableToGetWebsiteTrackingEvents:
        return {
          ...defaultAlert,
          textId:
            'web.settings.adminSettings.tracking.websiteTracking.recentEvents.fetchingError',
        };
      default:
        return null;
    }
  };
}

TrackingAlert.propTypes = {
  viewAlert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

export default TrackingAlert;
