import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getTemplateInfo(id = '', name = '', email = '') {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.templatesId.replace(':#id', id),
      {
        name,
        email,
        include_signature: false,
      },
      resolve,
      reject
    );
  });
}
