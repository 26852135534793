import { baseGet, basePut } from 'web/services/api';
import { Urls } from 'web/libs/routes';

/* These services accept user object so that components can use them without dispatching action creators */

export const isAdmin = ({ admin }) => admin || false;

export const isCurrentUser = ({ id }, userId) => userId === id;

export const isSameUser = ({ id: userOneId } = {}, { id: userTwoId } = {}) =>
  (userOneId && userTwoId && userOneId === userTwoId) || false;

export const isAdminOrSameUser = (user, viewer) =>
  user.admin || user.id === viewer.id;

export const getUser = (id) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.user.replace(':#id', id), {}, resolve, reject);
  });

export const getUserMetric = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.userMetric, {}, resolve, reject);
  });

export const updateSubscriptionSettings = (params = {}) =>
  new Promise((resolve, reject) => {
    basePut(Urls.subscriptionSettings, params, resolve, reject);
  });

export const getAdminSettings = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.adminSettings, {}, resolve, reject);
  });

export const updateAdminSettings = (params = { admin_settings: {} }) =>
  new Promise((resolve, reject) => {
    basePut(Urls.adminSettings, params, resolve, reject);
  });

export const updateUserSettings = (settings) =>
  new Promise((resolve, reject) => {
    basePut(Urls.userSettings, { settings }, resolve, reject);
  });

export const getUserSettings = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.userSettings, {}, resolve, reject);
  });
