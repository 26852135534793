import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { ModalPopupIds } from 'web/libs/constants';
import { GlobalPopupIds } from 'web/emailComposeBody/libs/emailComposeBodyConstants';
import { initImageInsertEditState } from './imageInsertActionCreators';
import { ModalComponentIds } from 'web/composeWindow/libs/composeWindowConstants';

export function openEmailComposePopup(id, data, editorId) {
  return {
    type: ActionTypes.emailComposeBody.state.popupOpen,
    id,
    data,
    editorId,
  };
}

export function closeEmailComposePopup() {
  return {
    type: ActionTypes.emailComposeBody.state.popupClose,
  };
}

export function setEmailComposeAlert(id) {
  return {
    type: ActionTypes.emailComposeBody.state.alertSet,
    id,
  };
}

export function clearEmailComposeAlert() {
  return {
    type: ActionTypes.emailComposeBody.state.alertClear,
  };
}

export function setLoading(loading) {
  return {
    type: ActionTypes.emailComposeBody.state.popupLoading,
    data: loading,
  };
}

export function setInfoBoxState(componentId, data) {
  return {
    type: ActionTypes.emailComposeBody.state.infoBox,
    id: componentId,
    data,
  };
}

export function showCkEditorModal(modalId, editorId, passedData) {
  return (dispatch) => {
    let popupId;
    switch (modalId) {
      case ModalPopupIds.dynamicFields:
        popupId = GlobalPopupIds.dynamicFields;
        break;
      case ModalPopupIds.dynamicFieldsIncorrect:
        popupId = GlobalPopupIds.dynamicFieldsIncorrect;
        break;
      case ModalPopupIds.imageInsert:
        popupId = GlobalPopupIds.imageInsert;
        if (passedData) {
          dispatch(initImageInsertEditState(passedData));
        }
        break;
      case ModalComponentIds.attachFilesAndContent:
      case ModalComponentIds.attachBoxContents:
      case ModalComponentIds.highSpotContent:
        popupId = modalId;
        break;
      default:
        return;
    }
    dispatch(openEmailComposePopup(popupId, { ckeId: editorId }, editorId));
  };
}
