import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import closest from 'closest';
import Alert from 'components/alert';
import ModalOverlay from 'components/modalOverlay';
import Button, { ButtonSizes } from 'components/buttons/action';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import './globalPopup.scss';

export default class GlobalPopup extends Component {
  _popupRef = null;

  componentDidMount() {
    window.addEventListener('mousedown', this._outsideClickHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this._outsideClickHandler, true);
  }

  _outsideClickHandler = (e) => {
    const { exceptionSelectors, outsideElemClick } = this.props;

    let exceptionNode = false;
    if (exceptionSelectors) {
      exceptionNode = exceptionSelectors.some((selector) =>
        closest(e.target, selector)
      );
    }

    if (
      outsideElemClick &&
      !this._popupRef.contains(e.target) &&
      !exceptionNode
    ) {
      e.stopPropagation();
      e.preventDefault();
      outsideElemClick();
    }
  };

  _getModalOverlayColor(overlay) {
    if (overlay) {
      return classNames({
        'modal-overlay-success': overlay.type === 'success' || !overlay.type,
        'modal-overlay-error': overlay.type === 'error',
        'modal-overlay-info': overlay.type === 'info',
      });
    }
    return '';
  }

  _getSizeClass = (size) => {
    switch (size) {
      case 'small':
        return 'modal-sm';
      case 'large':
        return 'modal-lg';
      default:
        return '';
    }
  };

  /*eslint complexity: [1, 13]*/
  render() {
    const {
      affirmDisabled,
      affirmColor,
      affirmIcon,
      affirmTextId,
      alert,
      backgroundColor,
      cancelColor,
      cancelTextId,
      children,
      classes,
      full,
      getImageUrl,
      inner,
      loading,
      onAffirm,
      onCancel,
      onClose,
      overlay,
      size,
      titleId,
      windowMode,
    } = this.props;
    const backgroundColorClass = this._getModalOverlayColor(
      overlay,
      backgroundColor
    );
    const sizeClass = this._getSizeClass(size);
    const globalPopupClasses = classNames('globalPopup', 'modal-background', {
      'modal-background-inner': inner,
    });
    const popupWindowClasses = classNames('popup-window', sizeClass, classes, {
      [`background-${backgroundColor}`]: backgroundColor,
      'full-child': full,
      'window-mode': windowMode,
    });
    const bodyClasses = classNames('body', backgroundColorClass, {
      'margin-bottom-most': !onAffirm && !onCancel,
    });

    return (
      <div className={globalPopupClasses}>
        <div className={popupWindowClasses} ref={this._setPopupRef}>
          {loading ? <LoadingSpinner imageUrl={getImageUrl} /> : null}
          {titleId ? (
            <div className="header">
              <FormattedMessage id={titleId} />
              {onClose ? (
                <div className="close-thin" onClick={onClose} />
              ) : null}
            </div>
          ) : null}
          {alert ? <Alert {...alert} /> : null}
          <div className={bodyClasses}>
            {overlay ? (
              <ModalOverlay
                {...overlay}
                backgroundColorClass={backgroundColorClass}
              />
            ) : null}
            {children}
          </div>
          {onAffirm || onCancel ? (
            <div className={`footer ${backgroundColorClass}`}>
              {onCancel ? (
                <Button
                  color={cancelColor}
                  size={ButtonSizes.medium}
                  onClick={onCancel}
                  classes=""
                >
                  <FormattedMessage id={cancelTextId} />
                </Button>
              ) : null}
              {onAffirm ? (
                <Button
                  disabled={affirmDisabled}
                  color={affirmColor}
                  size={ButtonSizes.medium}
                  onClick={onAffirm}
                  classes={`margin-left-full${
                    affirmIcon ? ` tout-icon-${affirmIcon}` : ''
                  }`}
                >
                  <FormattedMessage id={affirmTextId} />
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  _setPopupRef = (div) => (this._popupRef = div);
}

GlobalPopup.propTypes = {
  affirmDisabled: PropTypes.bool,
  affirmColor: PropTypes.string,
  affirmIcon: PropTypes.string,
  affirmTextId: PropTypes.string,
  alert: PropTypes.object,
  backgroundColor: PropTypes.string,
  cancelColor: PropTypes.string,
  cancelTextId: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  exceptionSelectors: PropTypes.array,
  full: PropTypes.bool,
  getImageUrl: PropTypes.func,
  inner: PropTypes.bool,
  loading: PropTypes.bool,
  onAffirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  outsideElemClick: PropTypes.func,
  overlay: PropTypes.object,
  size: PropTypes.string,
  titleId: PropTypes.string,
  windowMode: PropTypes.bool,
};

GlobalPopup.defaultProps = {
  affirmDisabled: false,
  affirmColor: '',
  affirmIcon: '',
  affirmTextId: '',
  alert: null,
  backgroundColor: '',
  cancelColor: '',
  cancelTextId: '',
  classes: '',
  exceptionSelectors: null,
  full: false,
  getImageUrl: () => {},
  inner: false,
  loading: false,
  onAffirm: null,
  onCancel: null,
  onClose: null,
  outsideElemClick: null,
  overlay: null,
  size: '',
  titleId: '',
  windowMode: false,
};
