import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Urls, getImageUrl } from 'web/libs/constants';
import { getLoginSalesforceLink } from 'web/login/helpers/loginHelper';
import {
  LoginPageEvents,
  LoginPageProperties,
  LoginPagePropertyTypes,
} from 'web/libs/mixpanelEvents';
import { track } from 'web/services/mixpanelService';
import './oneClickButtons.scss';
import PropTypes from 'prop-types';

const gmailIcon = {
  background: `url(${getImageUrl('login-icons', 'png')}) no-repeat 0 1px`,
};
const ssoIcon = {
  background: `url(${getImageUrl('login-icons', 'png')}) no-repeat 0 -25px`,
};
const salesforceIcon = {
  background: `url(${getImageUrl('login-icons', 'png')}) no-repeat 0 -51px`,
};

const trackButtonsClick = (propertyType) => {
  track(LoginPageEvents.clickButton, {
    [LoginPageProperties.actionType]: propertyType,
  });
};

const getLoginGoogleLink = ({ isGmailMode, isOwaMode }) => {
  if (isOwaMode) {
    return Urls.owaConnectAdminToGoogleNewUI;
  }
  if (isGmailMode) {
    return Urls.gmailConnectToGoogleUI;
  }
  return Urls.loginGoogle;
};

const getLoginSSOLink = (isOwaMode) => {
  if (isOwaMode) {
    return Urls.owaConnectAdminToSSONewUI;
  }
  return Urls.loginSso;
};

const getSalesforceSignInLabelKey = (isSFSandboxAuthenticationEnabled) =>
  isSFSandboxAuthenticationEnabled
    ? 'login.form.oneClick.salesforceSandbox'
    : 'login.form.oneClick.salesforce';

const OneClickButtons = ({
  intl: { formatMessage },
  isOwaMode,
  isGmailMode,
  isSFSandboxAuthenticationEnabled,
}) => (
  <div className="one-click-buttons">
    <ul className="one-click-buttons-list">
      <li>
        <a
          className="one-click-buttons-button"
          href={getLoginSalesforceLink({
            isGmailMode,
            isOwaMode,
            isSFSandboxAuthenticationEnabled,
          })}
          onClick={() =>
            trackButtonsClick(LoginPagePropertyTypes.salesforceButton)
          }
          title={formatMessage({ id: 'common.salesforce' })}
        >
          <i className="icon" style={salesforceIcon} />
          <FormattedMessage
            id={getSalesforceSignInLabelKey(isSFSandboxAuthenticationEnabled)}
          />
        </a>
      </li>
      <li>
        <a
          className="one-click-buttons-button"
          href={getLoginSSOLink(isOwaMode)}
          onClick={() =>
            trackButtonsClick(LoginPagePropertyTypes.singleSignOnButton)
          }
          title={formatMessage({ id: 'login.form.oneClick.sso' })}
        >
          <i className="icon" style={ssoIcon} />
          <FormattedMessage id="login.form.oneClick.sso" />
        </a>
      </li>
      <li>
        <a
          className="one-click-buttons-button"
          href={getLoginGoogleLink({ isGmailMode, isOwaMode })}
          onClick={() => trackButtonsClick(LoginPagePropertyTypes.gmailButton)}
          title={formatMessage({ id: 'common.gmail' })}
        >
          <i className="icon" style={gmailIcon} />
          <FormattedMessage id="login.form.oneClick.google" />
        </a>
      </li>
    </ul>
  </div>
);

OneClickButtons.defaultProps = {
  isGmailMode: false,
  isOwaMode: false,
  isSFSandboxAuthenticationEnabled: false,
};

OneClickButtons.propTypes = {
  intl: intlShape.isRequired,
  isGmailMode: PropTypes.bool,
  isOwaMode: PropTypes.bool,
  isSFSandboxAuthenticationEnabled: PropTypes.bool,
};

export default injectIntl(OneClickButtons);
