import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';
import {
  closePopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { PersonDetailsPopupAlertIds } from '../libs/personDetailsConstants';
import {
  closeCardEditingAll,
  exitPersonDetails,
  getPersonById,
  initPersonDetails,
  onTabSelect,
  setEmailId,
} from './personDetailsActionCreators';
import {
  deletePerson as deletePersonCall,
  unsubscribePerson as unsubscribePersonCall,
  removePersonUnsubscribe as removePersonUnsubscribeCall,
} from 'web/people/services/peopleService';
import { removeCallService } from 'web/emails/services/callsServices';
import { deleteNote as deleteNoteCall } from 'web/notes/services/notesService';
import { createTemplate } from 'web/templates/services/templateService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { navigateToTemplatesTab } from 'web/services/routerService';
import {
  PersonDetailsEvents,
  PersonDetailsProperties,
  PersonDetailsPropertyTypes,
} from 'web/libs/mixpanelEvents';
import { track } from 'web/services/mixpanelService';
import { getPeople } from 'web/people/actionCreators/peopleFetchActionCreators';
import { getGroups } from 'web/groups/actionCreators/groupsActionCreators';
import { PEOPLE_GENERAL_GROUPS } from 'web/people/libs/peopleConstants';

export const deletePerson = (onClose = () => {}) => (dispatch, getState) => {
  const { id } = getState().personDetailsPerson;
  dispatch(setPopupLoading(true));
  deletePersonCall(id)
    .then(() => {
      // todo update tables as well when start integrating usage throughout app
      track(PersonDetailsEvents.action, {
        [PersonDetailsProperties.type]: PersonDetailsPropertyTypes.delete,
      });
      toutBackboneHelper.personDelete(id);
      dispatch(closePopup());
      onClose();
    })
    .catch(() => {
      dispatch(setPopupAlert(PersonDetailsPopupAlertIds.generic));
      dispatch(setPopupLoading(false));
    });
};

export const unsubscribePerson = () => (dispatch, getState) => {
  const {
    personDetailsPerson: { id },
    personDetailsUnsubscribeReason,
  } = getState();
  dispatch(setPopupLoading(true));
  unsubscribePersonCall(id, personDetailsUnsubscribeReason)
    .then(() => {
      dispatch(getPersonById(id)); //temp have backend return person object
      track(PersonDetailsEvents.action, {
        [PersonDetailsProperties.type]: PersonDetailsPropertyTypes.unsubscribe,
      });
      toutBackboneHelper.personRefetch(id); //temp switch to personUpdate when get person object from backend
      dispatch(getGroups(true));
      dispatch(closePopup());
    })
    .catch(() => {
      dispatch(setPopupAlert(PersonDetailsPopupAlertIds.generic));
      dispatch(setPopupLoading(false));
    });
};

export const removeCall = () => (dispatch, getState) => {
  const {
    popupData: { id },
  } = getState();

  removeCallService(id)
    .then((data) => data)
    .catch((err) => err);

  dispatch({
    deletedCallId: id,
    type: PersonDetailsActionTypes.deleteCall,
  });

  dispatch(closePopup());
};

export const removePersonUnsubscribe = () => (dispatch, getState) => {
  const {
    personDetailsPerson: { id },
    optInReasonsSelected,
    peopleCurrentGroupId,
  } = getState();

  if (optInReasonsSelected === null) {
    dispatch(
      setPopupAlert(PersonDetailsPopupAlertIds.invalidOptInReasonSelected)
    );
  } else {
    dispatch(setPopupLoading(true));

    removePersonUnsubscribeCall(id, optInReasonsSelected)
      .then(() => {
        const notFilters =
          peopleCurrentGroupId === PEOPLE_GENERAL_GROUPS.unsubscribed.id
            ? { id }
            : {};
        dispatch(getPersonById(id)); //temp have backend return person object
        dispatch(getPeople(notFilters));
        dispatch(closePopup());
      })
      .catch(() => {
        dispatch(setPopupAlert(PersonDetailsPopupAlertIds.generic));
        dispatch(setPopupLoading(false));
      });
  }
};

export const deleteNote = () => (dispatch, getState) => {
  const {
    popupData: { id },
  } = getState();
  dispatch(setPopupLoading(true));

  deleteNoteCall(id)
    .then(() => {
      dispatch({
        id,
        type: PersonDetailsActionTypes.deleteNote,
      });
      track(PersonDetailsEvents.notes, {
        [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.remove,
      });
      dispatch(closePopup());
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(PersonDetailsPopupAlertIds.generic));
    });
};

// Not using constants as names map to the function they call
export const hasChangesCallback = () => (dispatch, getState) => {
  const {
    popupData: { args, callback },
  } = getState();
  dispatch(closeCardEditingAll());
  dispatch(closePopup());

  switch (callback) {
    case 'exitPersonDetails':
      dispatch(exitPersonDetails(...args));
      break;
    case 'initPersonDetails':
      dispatch(initPersonDetails(...args));
      break;
    case 'onTabSelect':
      dispatch(onTabSelect(...args));
      break;
    default:
      break;
  }
};

export const openEmailInfo = (emailId) => (dispatch) => {
  dispatch(closePopup());
  dispatch(setEmailId(emailId));
};

export const updatePersonUnsubscribeReason = (reason) => ({
  payload: reason,
  type: PersonDetailsActionTypes.updateUnsubscribeReason,
});

export const addAsTemplate = () => (dispatch, getState) => {
  const {
    personDetailsEmailsState: { emails },
    popupData: { emailId },
    templatesCreateTemplateState: { categoryId, name },
  } = getState();
  dispatch(setPopupLoading(true));

  const { file_attachments: fileAttachments = [], body, subject } =
    emails.find(({ id }) => id === emailId) || {};

  createTemplate({
    attachments: fileAttachments.map(({ id }) => id),
    body,
    categoryId,
    name,
    subject,
  })
    .then((template) => {
      track(PersonDetailsEvents.salesEmails, {
        [PersonDetailsProperties.actionType]:
          PersonDetailsPropertyTypes.addAsTemplate,
      });
      navigateToTemplatesTab(template, categoryId, true);
    })
    .catch(() => {
      dispatch(setPopupAlert(PersonDetailsPopupAlertIds.generic));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};
