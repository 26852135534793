import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import './contentLogo.scss';

export default class ContentLogo extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div
        className={`content-partner-block ${classes}`}
        onClick={this._onClick}
      >
        {this.partnerContent()}
      </div>
    );
  }

  partnerContent = () => {
    const { partner, imageUrl } = this.props;
    const imgClassname = `partner-logo ${
      partner.name
    }-partner-logo tout-partner-icon`;

    return (
      <div className="tout-content-image-container">
        <img
          className={imgClassname}
          src={imageUrl(`contentPartners/${partner.name}`, 'svg')}
          alt=""
        />
        <p className="content-partner-title">
          <FormattedHTMLMessage
            id={`contentPartners.modal.partnerName.${partner.name}`}
          />
        </p>
      </div>
    );
  };

  _onClick = () => {
    const { partner, setSelectedPartner } = this.props;
    setSelectedPartner(partner);
  };
}

ContentLogo.propTypes = {
  classes: PropTypes.string,
  partner: PropTypes.object.isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
  imageUrl: PropTypes.func.isRequired,
};

ContentLogo.defaultProps = {
  classes: '',
};
