import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import TextCell from 'table/modules/cells/textCell';
import { WebsiteTrackingEventsTableProperties } from '../../../libs/trackingConstants';
import ToutTable from 'table/modules/toutTable';
import { alphabetical } from 'table/modules/sorting';
import { getImageUrl } from 'web/libs/constants';
import './websiteTrackingEvents.scss';
import Action from 'components/buttons/action';

const WebsiteTrackingEvents = (props) => {
  const {
    events,
    loading,
    getRecentWebsiteTrackingEvents,
    intl: { formatMessage },
  } = props;

  const items = events.map((event) => ({
    ...event,
    created_at: moment(event.created_at).format('LL'),
  }));

  const zeroStateAttributes = {
    titleText: formatMessage({
      id:
        'web.settings.adminSettings.tracking.websiteTracking.recentEvents.zeroState.title',
    }),
    bodyText: formatMessage({
      id:
        'web.settings.adminSettings.tracking.websiteTracking.recentEvents.zeroState.body',
    }),
  };

  const columns = [
    {
      flex: true,
      id: '1',
      name: formatMessage({
        id:
          'web.settings.adminSettings.tracking.websiteTracking.recentEvents.columns.pageTitle',
      }),
      rowCell: {
        component: TextCell,
        metadata: {
          property: 'title',
          showTitle: true,
        },
      },
      sorting: alphabetical('title'),
      width: WebsiteTrackingEventsTableProperties.standardColumnWidth,
    },
    {
      flex: true,
      id: '2',
      name: formatMessage({
        id:
          'web.settings.adminSettings.tracking.websiteTracking.recentEvents.columns.url',
      }),
      rowCell: {
        component: TextCell,
        metadata: {
          property: 'url',
          showTitle: true,
        },
      },
      sorting: alphabetical('url'),
      width: WebsiteTrackingEventsTableProperties.standardColumnWidth,
    },
    {
      flex: true,
      id: '3',
      name: formatMessage({
        id:
          'web.settings.adminSettings.tracking.websiteTracking.recentEvents.columns.timeStamp',
      }),
      rowCell: {
        component: TextCell,
        metadata: { property: 'created_at' },
      },
      sorting: (rowData) => rowData.created_at,
      width: WebsiteTrackingEventsTableProperties.lastColumnWidth,
    },
  ];

  return (
    <>
      <div className="website-tacking-events">
        <ToutTable
          tableId={WebsiteTrackingEventsTableProperties.tableId}
          allItems
          row={{
            height: WebsiteTrackingEventsTableProperties.standardRowHeight,
            onClick: WebsiteTrackingEventsTableProperties.onClick,
          }}
          loading={loading}
          loadingGetUrl={getImageUrl}
          maxHeight={WebsiteTrackingEventsTableProperties.maxHeight}
          columns={columns}
          items={items}
          zeroState={zeroStateAttributes}
          actionHeaderHeight={0}
        />
      </div>
      <div className="margin-top-full right">
        <Action onClick={getRecentWebsiteTrackingEvents}>
          <FormattedMessage id="common.refresh" />
        </Action>
      </div>
    </>
  );
};

WebsiteTrackingEvents.propTypes = {
  events: PropTypes.array,
  loading: PropTypes.bool,
  intl: intlShape.isRequired,
  getRecentWebsiteTrackingEvents: PropTypes.func.isRequired,
};

WebsiteTrackingEvents.defaultProps = {
  events: [],
  loading: false,
};

export default injectIntl(WebsiteTrackingEvents);
