import { createSelector } from 'reselect';
import {
  isHighSpotInContentPartners,
  getCallRecordingFlag,
  getCallRecordingNoticeEnabledFlag,
  getForceCallRecordingFlag,
  getContentPartnersFlag,
  getSubscriptionEmailLimit,
  getSubscriptionCampaignLimit,
  getBoxIntegrationEnabledFlag,
  getSlackIntegrationEnabledFlag,
  getUnsubscribeCustomDomain,
  getPluginUnsubscribeFlag,
  getTemplatesEditingDisabledFlag,
  getCampaignsEditingDisabledFlag,
  getLoginMethod,
} from '../helpers/generalPageHelpers';

const isUserSubscriptionFetching = (state) => state.isUserSubscriptionFetching;
const isAdminSettingsFetching = (state) => state.isAdminSettingsFetching;
const isContentPartnersFetching = (state) => state.contentModalLoading;
const getTeamSettingsFetching = (state) => state.teamSettingsFetching;
const contentPartners = (state) => state.contentPartners;
const userSubscription = (state) => state.user.subscription;
const isAutoAppendContentFetching = (state) =>
  state.isAutoAppendContentFetching;

export const emailThrottlingLimits = (state) => state.emailThrottlingLimits;
export const getActiveTab = (state) =>
  state.ui.settings.adminSettings.general.activeTab;

export const isGeneralPageFetching = createSelector(
  [
    isUserSubscriptionFetching,
    isAdminSettingsFetching,
    isContentPartnersFetching,
    getTeamSettingsFetching,
    isAutoAppendContentFetching,
  ],
  (
    isSubscriptionFetching,
    isSettingsFetching,
    isPartnersFetching,
    isTeamSettingsFetching,
    isAutoAppendContentFetching
  ) =>
    isSubscriptionFetching ||
    isSettingsFetching ||
    isPartnersFetching ||
    isTeamSettingsFetching ||
    isAutoAppendContentFetching
);

export const isHighSpotEnabled = createSelector(
  [userSubscription],
  getContentPartnersFlag
);

export const isHighSpotChecked = createSelector(
  [contentPartners],
  isHighSpotInContentPartners
);

export const isBoxIntegrationEnabled = createSelector(
  [userSubscription],
  getBoxIntegrationEnabledFlag
);

export const isSlackIntegrationEnabled = createSelector(
  [userSubscription],
  getSlackIntegrationEnabledFlag
);

export const isTemplatesEditingDisabled = createSelector(
  [userSubscription],
  getTemplatesEditingDisabledFlag
);

export const isCampaignsEditingDisabled = createSelector(
  [userSubscription],
  getCampaignsEditingDisabledFlag
);

export const isCallRecordingChecked = createSelector(
  [userSubscription],
  getCallRecordingFlag
);

export const isForceCallRecordingChecked = createSelector(
  [userSubscription],
  getForceCallRecordingFlag
);

export const isCallRecordingNoticeChecked = createSelector(
  [userSubscription],
  getCallRecordingNoticeEnabledFlag
);

export const subscriptionEmailLimit = createSelector(
  [userSubscription],
  getSubscriptionEmailLimit
);

export const subscriptionCampaignLimit = createSelector(
  [userSubscription],
  getSubscriptionCampaignLimit
);

export const isUnsubscribeCustomDomainEnabled = createSelector(
  [userSubscription],
  getUnsubscribeCustomDomain
);

export const isPluginUnsubscribeEnabled = createSelector(
  [userSubscription],
  getPluginUnsubscribeFlag
);

export const loginMethod = createSelector([userSubscription], getLoginMethod);
