export const AddressLabels = {
  from: 'from',
  subject: 'subject',
  to: 'to',
};

export const ModalComponentIds = {
  attachBoxContents: 'OPEN_BOX_CONTENT',
  attachFilesAndContent: 'ATTACH_FILES_AND_CONTENT',
  clearEditsModal: 'CLEAR_EDITS_MODAL',
  editPinnedTemplateCategories: 'EDIT_PINNED_TEMPLATE_CATEGORIES',
  highSpotContent: 'OPEN_HIGHSPOT_CONTENT',
  incorrectDynamicFields: 'INCORRECT_DYNAMIC_FIELDS',
  insertDynamicFields: 'INSERT_DYNAMIC_FIELDS',
  insertImageDragAndDrop: 'INSERT_IMAGE_DRAG_AND_DROP',
};

export const AlertIds = {
  addNewPeopleDisabled: 'COMPOSE_ADD_NEW_PEOPLE_DISABLED',
  composeLoadError: 'COMPOSE_ERROR_LOADING',
  duplicateTemplateCategory: 'CANNOT_ADD_DUPLICATE_TEMPLATE_CATEGORY',
  emailLimit: 'COMPOSE_EMAIL_LIMIT',
  emailLimitSingle: 'COMPOSE_EMAIL_LIMIT_SINGLE',
  fetchDynamicFieldError: 'COMPOSE_FETCH_DYNAMIC_FIELD_ERROR',
  fetchGroupError: 'COMPOSE_FETCH_GROUP_ERROR',
  fetchPinnedError: 'COMPOSE_PINNED_TEMPLATES_FETCH_ERROR',
  filledTemplateFetchError: 'DYNAMIC_FIELDS_FILLED_TEMPLATE_FETCH_ERROR',
  imageInsertFileTooLarge: 'IMAGE_LARGE',
  imageInsertFileTypeInvalid: 'IMAGE_INVALID',
  imageInsertIssue: 'IMAGE_INSERT',
  missingDynamicFields: 'COMPOSE_MISSING_DYNAMIC_FIELDS',
  missingFieldBody: 'COMPOSE_MISSING_FIELD_BODY',
  missingFieldRecipient: 'COMPOSE_MISSING_FIELD_RECIPIENT',
  missingFieldSubject: 'COMPOSE_MISSING_FIELD_SUBJECT',
  pinnedSaveError: 'COMPOSE_PINNED_TEMPLATES_SAVE_ERROR',
  pinnedSaveSuccess: 'COMPOSE_PINNED_TEMPLATES_SAVE_SUCCESS',
  setRecipientsError: 'COMPOSE_SET_RECIPIENTS_ERROR',
  singleFlowDomainBlock: 'COMPOSE_SINGLE_DOMAIN_BLOCK',
  singleFlowInvalidEmail: 'COMPOSE_SINGLE_INVALID_EMAIL',
  singleFlowUnsubscribes: 'COMPOSE_SINGLE_UNSUBSCRIBES',
  templateFetchError: 'COMPOSE_TEMPLATE_FETCH_ERROR',
  unfilledPromptError: 'UNFILLED_PROMPT_ERROR',
  unfilledPromptErrorInGroupSetting: 'UNFILLED_PROMPT_IN_GROUP_SETTING',
};

export const LabelErrorIds = {
  error: 'ERROR',
  warning: 'WARNING',
};

export const PitchStates = {
  creating: 'creating',
  errored: 'errored',
  new: 'new',
  saved: 'saved',
  saving: 'saving',
  sending: 'sending',
  sent: 'sent',
  unsaved: 'unsaved',
};

export const PitchTransitions = {
  creating: ['errored', 'saved', 'sent'],
  errored: ['unsaved', 'creating', 'errored', 'saving', 'sending'],
  new: ['unsaved', 'creating', 'errored'],
  saved: ['errored', 'saving', 'sending', 'unsaved'],
  saving: ['errored', 'saving', 'saved', 'unsaved'],
  sending: ['sent'],
  sent: [],
  unsaved: ['creating', 'errored', 'unsaved', 'saving', 'sending', 'sent'],
};

export const PitchAPICommits = {
  send: 'Send',
};

export const AddressTypes = {
  bcc: 'bcc',
  cc: 'cc',
  to: 'to',
};

export const AddressFieldLimits = {
  bcc: 50,
  cc: 50,
  to: 1,
};

export const AddressableTypes = {
  group: 'group',
};

export const BulkEmails = {
  allRecipientPill: {
    email: '',
    replacementTextId: 'web.composeWindow.bulkEmails.toLabel.allRecipients',
    validated: true,
  },
  attachmentAdd: 'attachment_add',
  attachmentRemove: 'attachment_remove',
  body: 'body',
  ccAndBccAdd: 'cc_and_bcc_add',
  ccAndBccRemove: 'cc_and_bcc_remove',
  filterValues: {
    all: 'all',
  },
  iconTypes: {
    delete: 'delete',
    warning: 'warning',
  },
  identity: 'identity',
  recipientLimit: 200,
  schedule: 'schedule',
  slidingWindow: {
    max: 50,
    shift: 25,
  },
  sortWeight: {
    blocked: -5,
    default: 0,
    dynamicField: -10,
    success: 5,
    unsubscribed: -5,
  },
  stateReason: {
    blocked: 'blocked',
    invalidDynamicFields: 'invalid_dynamic_fields',
    success: 'dynamic_fields_success',
    unsubscribed: 'unsubscribed',
  },
  subject: 'subject',
  template: 'template',
  tooltipIds: {
    delete: 'delete-icon',
    state: 'state',
    warning: 'warning-icon',
  },
};

export const PusherIdentifier = {
  liquifyPitch: 'LiquifyPitch',
};

export const PitchActiveRecordError = {
  limit: 'Sorry, you cannot send more than',
  unsubscribed: [
    'email address is blocked from receiving',
    'email addresses are blocked from receiving',
  ],
};

//limit for events shown in person detail view
export const DETAILS_EVENTS_LIMIT = 3;

export const SAVING_DEBOUNCE_MS = 2000;

export const COMPOSE_EDITOR_ID = 'compose-window-editor';

export const COMPOSE_DOM_TARGET_ID = 'anchor-popout';

export const END_SIG_TAG = '<!--endSig-->';
