import React, { PureComponent, Fragment } from 'react';
import Notification from 'components/notification';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import PropTypes from 'prop-types';
import {
  SalesforceCustomizationPopupIds,
  VERSION_DETAILS_LINK,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import CustomizationGuideLinks from './customizationGuideLinks';

class SalesforceCustomizationUninstall extends PureComponent {
  render() {
    const { availableVersions, isProcessing } = this.props;
    const isNewVersionAvailable = !!availableVersions.length;
    const uninstallButtonText = this._getUninstallButtonText();
    const upgradeButtonText = this._getUpgradeButtonText();

    return (
      <Fragment>
        <div className="margin-top-20">
          {isNewVersionAvailable ? (
            <Notification messageId="web.salesforceCustomization.upgrade.newVersionAvailable" />
          ) : (
            <Fragment>
              <i className="tout-icon-tick" />
              <FormattedMessage id="web.salesforceCustomization.upgrade.customizationUpToDate" />
            </Fragment>
          )}
        </div>
        <CustomizationGuideLinks />
        <div className="margin-top-20">
          <FormattedHTMLMessage id="web.salesforceCustomization.learnAboutLastVersion" />
          <a
            href={VERSION_DETAILS_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="common.here" />
          </a>
        </div>
        <div className="margin-top-20 right">
          <Button
            color={ButtonColors.red}
            disabled={isProcessing}
            size={ButtonSizes.standard}
            onClick={this._uninstall}
            title={uninstallButtonText}
          >
            {uninstallButtonText}
          </Button>
          {isNewVersionAvailable ? (
            <Button
              classes="margin-left-full"
              color={ButtonColors.blue}
              disabled={isProcessing}
              size={ButtonSizes.standard}
              onClick={this._upgrade}
              title={upgradeButtonText}
            >
              {upgradeButtonText}
            </Button>
          ) : null}
        </div>
      </Fragment>
    );
  }

  _uninstall = () => {
    const { actionCreators } = this.props;

    actionCreators.openPopup(
      SalesforceCustomizationPopupIds.salesforceCustomizationPreUninstall
    );
  };

  _upgrade = () => {
    const { actionCreators } = this.props;

    actionCreators.openPopup(
      SalesforceCustomizationPopupIds.salesforceCustomizationPreUpgrade
    );
  };

  _getUninstallButtonText = () => {
    const {
      isRunningCustomizationUninstall,
      intl: { formatMessage },
    } = this.props;

    return isRunningCustomizationUninstall
      ? formatMessage({ id: 'web.salesforceCustomization.uninstalling.button' })
      : formatMessage({ id: 'web.salesforceCustomization.uninstall.button' });
  };

  _getUpgradeButtonText = () => {
    const {
      isRunningCustomizationInstall,
      intl: { formatMessage },
    } = this.props;

    return isRunningCustomizationInstall
      ? formatMessage({
          id: 'web.salesforceCustomization.installingUpgrades.button',
        })
      : formatMessage({
          id: 'web.salesforceCustomization.installUpgrades.button',
        });
  };
}

SalesforceCustomizationUninstall.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isRunningCustomizationInstall: PropTypes.bool.isRequired,
  isRunningCustomizationUninstall: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  availableVersions: PropTypes.array.isRequired,
};

export default injectIntl(SalesforceCustomizationUninstall);
