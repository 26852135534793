import { ModalPopupIds } from 'web/libs/constants';
import {
  closePopup,
  openPopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { setPeopleForDeletePopup } from 'web/people/actionCreators/peopleDeleteActionCreators';

export function peopleDeleteStartup(people) {
  return (dispatch) => {
    dispatch(setPeopleForDeletePopup(people));
    dispatch(closePopup());
    dispatch(openPopup(ModalPopupIds.peopleDelete));
  };
}
