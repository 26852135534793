import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HoverTooltip from 'components/hoverTooltip';
import './pageTabNav.scss';

// it needs for list items that appears in DOM
const RENDER_DELAY = 10;
const ACTIVE_TAB_CLASS = 'page-tab-nav-item--active';

class PageTabNav extends Component {
  static displayName = 'PageTabNav';

  $slider = null;

  $list = null;

  componentDidMount() {
    this._setActiveTabSlider();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTabValue !== this.props.selectedTabValue) {
      this._setActiveTabSlider();
    }
  }

  render() {
    const { list, selectedTabValue } = this.props;

    return (
      <div className="page-tab-nav">
        <ul className="page-tab-nav-list" ref={this.setRefList}>
          {list.map(
            ({
              disabled,
              value,
              title,
              alertCount,
              icon,
              hideTitle,
              iconTooltip,
            }) => (
              <li
                className={classNames('page-tab-nav-item', {
                  [ACTIVE_TAB_CLASS]: value === selectedTabValue,
                  disabled,
                })}
                data-value={value}
                key={`page-tab-list-${value}`}
                onClick={(e) => this._onClick(e, value, disabled)}
              >
                <div
                  className={classNames('page-tab-nav-link', {
                    disabled,
                  })}
                  title={title}
                >
                  {this._getIcon(icon, iconTooltip)}
                  {!hideTitle && title}
                </div>
                {alertCount > 0 && (
                  <div className="page-tab-nav-alert">{alertCount}</div>
                )}
              </li>
            )
          )}
          <div className="page-tab-nav-underline" ref={this.setRefSlider} />
        </ul>
      </div>
    );
  }

  _setSliderStyle = ({ width = 0, offset = 0 }) => {
    if (this.$slider) {
      this.$slider.style.cssText = `left: ${offset}px; width: ${width}px`;
    }
  };

  _onClick = (e, value, disabled) => {
    if (!disabled) {
      this._setSliderStyle({
        offset: e.currentTarget.offsetLeft,
        width: e.currentTarget.clientWidth,
      });
      this.props.onTabSelect(value);
    }
  };

  setRefSlider = (node) => {
    this.$slider = node;
  };

  setRefList = (node) => {
    this.$list = node;
  };

  _setActiveTabSlider = () => {
    const activeTab = [].find.call(this.$list.children, (i) =>
      i.classList.contains(ACTIVE_TAB_CLASS)
    );

    if (activeTab) {
      setTimeout(() => {
        this._setSliderStyle({
          offset: activeTab.offsetLeft,
          width: activeTab.clientWidth,
        });
      }, RENDER_DELAY);
    } else {
      this._setSliderStyle({ offset: 0, width: 0 });
    }
  };

  _getIcon = (icon, iconTooltip) => {
    if (icon) {
      const iconElement = <i className={icon} />;
      if (iconTooltip) {
        return <HoverTooltip {...iconTooltip}>{iconElement}</HoverTooltip>;
      } else {
        return iconElement;
      }
    }
  };
}

export const ListItem = PropTypes.shape({
  alertCount: PropTypes.number,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

PageTabNav.propTypes = {
  list: PropTypes.arrayOf(ListItem),
  onTabSelect: PropTypes.func.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
};

PageTabNav.defaultProps = {
  list: [],
};

export default PageTabNav;
