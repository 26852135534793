import { createSelector } from 'reselect';
import I18N from 'languages';
import { getSmartFilter } from 'web/tables/smartFilters/selectors/smartFiltersSelectors';
import {
  CommandCenterPopupIds,
  CommandCenterEmailsTabsEnum,
} from 'web/commandCenter/libs/commandCenterConstants';
import {
  getActiveEmailTableId,
  getCommandCenterEmailsSubTab,
} from 'web/commandCenter/selectors/commandCenterSelectors';
import { FILTER_SEARCH_STATUS_ID } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';

export const getAdvancedSearchStatus = createSelector(
  [getSmartFilter],
  (filter) => {
    const tabId =
      (filter && filter.tabId) || CommandCenterEmailsTabsEnum.sent.value;
    const subTabIds = (filter && filter.subTabIds) || [];
    return {
      filterLabel: I18N.getStr('common.status'),
      filterPrefix: tabId,
      filterValues: subTabIds,
      subTabIds,
      tabId,
    };
  }
);

const getStatusFilter = (state, props) => {
  const collectionId =
    (props && props.collectionId) || getActiveEmailTableId(state);
  return getSmartFilter(state, {
    collectionId,
    filterId: FILTER_SEARCH_STATUS_ID,
  });
};

const isAdvancedSearchPopUpIsOpen = (state) =>
  state.popup === CommandCenterPopupIds.advancedSearchSave ||
  state.popup === CommandCenterPopupIds.advancedSearch;

export const getIsScheduleStatus = createSelector(
  [getCommandCenterEmailsSubTab, getStatusFilter, isAdvancedSearchPopUpIsOpen],
  (subTab, statusFilter, isAdvancedSearchPopUpIsOpen) => {
    const isScheduleOn =
      statusFilter &&
      statusFilter.subTabIds &&
      statusFilter.subTabIds.includes(
        CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value
      );

    const isScheduleTab =
      subTab === CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value;

    return isScheduleOn || (!isAdvancedSearchPopUpIsOpen && isScheduleTab);
  }
);
