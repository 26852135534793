import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarketoAdminPopupContainer from 'web/marketoSettings/containers/marketoAdminPopupContainer';
import BulkUpdatePopupContainer from 'web/bulkUpdate/container/bulkUpdatePopupContainer';
import MarketoAdminSettingsContent from '../components/adminContent';
import * as adminSettingsActionCreators from '../actionCreators/adminSettingsActionCreators';
import * as adminSetupActionCreators from '../actionCreators/adminSetupActionCreators';
import * as adminTeamAccessActionCreators from '../actionCreators/adminTeamAccessActionCreators';
import * as adminPopupActionCreators from '../actionCreators/adminPopupActionCreators';
import { getFormattedUsers } from '../selectors/usersSelectors';
import { getSalesforceConnectLink } from 'web/salesforce/selectors/salesforceSelectors';

class MarketoAdminSettingsContainer extends Component {
  componentDidMount() {
    this.props.actionCreators.getUsers();
  }

  render() {
    return (
      <MarketoAdminPopupContainer>
        <BulkUpdatePopupContainer />
        <MarketoAdminSettingsContent {...this.props} />
      </MarketoAdminPopupContainer>
    );
  }
}

MarketoAdminSettingsContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alertId: state.marketoAdminAlertId,
  connectionInfo: state.marketoAdminConnectionInfo,
  connectionState: state.marketoAdminConnectionState,
  gettingUsers: state.marketoAdminTeamAccessGettingUsers,
  page: state.marketoAdminPage,
  selectedTabValue: state.marketoAdminSelectedTabValue,
  teamAccessSmartFilter: state.marketoAdminTeamAccessSmartFilter,
  users: getFormattedUsers(state),
  saleforceConnectLink: getSalesforceConnectLink(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        ...adminSettingsActionCreators,
        ...adminSetupActionCreators,
        ...adminTeamAccessActionCreators,
        ...adminPopupActionCreators,
      },
      dispatch
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketoAdminSettingsContainer);
