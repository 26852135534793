import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors } from 'components/buttons/action';
import Switch from 'components/buttons/switch';
import './gmailConnected.scss';

class GmailConnected extends Component {
  constructor(props) {
    super(props);

    this.state = {
      integration: {
        ...props.gmailIntegration,
      },
    };
  }

  render() {
    const {
      gmailIntegration: { disconnecting, savingChanges },
      intl: { formatMessage },
      actionCreators: { openDisconnectConfirmationPopup },
    } = this.props;
    const {
      integration: { send_compliance },
    } = this.state;

    return (
      <div className="gmail-connected">
        <div className="options-wrapper">
          <Switch
            checked={send_compliance}
            classes="gmail-settings-checkbox"
            onChange={this.onChange}
            name="send_compliance"
            label={formatMessage({
              id: 'web.emailIntegration.gmail.settings.sendCopy',
            })}
            rightLabel
          />
        </div>

        <div className="button-panel">
          <Button
            className="save-btn"
            color={ButtonColors.green}
            disabled={savingChanges}
            onClick={this.saveChanges}
          >
            <FormattedMessage id="common.saveChanges" />
          </Button>
          <Button
            color={ButtonColors.red}
            disabled={disconnecting}
            onClick={openDisconnectConfirmationPopup}
          >
            <FormattedMessage id="web.emailIntegration.gmail.disconnect" />
          </Button>
        </div>
      </div>
    );
  }

  onChange = (isChecked, name) => {
    this.setState({
      integration: {
        ...this.state.integration,
        [name]: isChecked,
      },
    });
  };

  saveChanges = () => {
    const {
      actionCreators: { saveChanges },
    } = this.props;
    const { integration } = this.state;
    const data = {
      contact_integration: integration,
    };

    saveChanges(data);
  };
}

GmailConnected.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  gmailIntegration: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(GmailConnected);
