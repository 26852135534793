import React from 'react';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import Switch from 'components/buttons/switch';
import Radio from 'components/buttons/radio';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { alertEmptyRequiredCallReasonOption } from 'web/settings/adminSettings/dialer/actionCreators/callReasonActionCreators';
import PropTypes from 'prop-types';
import './dialerSettingsCard.scss';

const CallReasonSettingsCard = ({
  actionCreators: { updateDialerSettings },
  callReasonEnabled,
  dispatch,
  forceCallReason,
  intl: { formatMessage },
  totalCallReasonOptions,
}) => {
  const handleSettingChange = async ({
    call_reason_enabled,
    force_call_reason,
  }) => {
    await updateDialerSettings({
      call_reason_enabled,
      force_call_reason,
    });
    dispatch(
      alertEmptyRequiredCallReasonOption({
        isEnabled: call_reason_enabled,
        isRequired: force_call_reason,
      })
    );
  };

  return (
    <React.Fragment>
      <h3 className="my-profile-account-details-title">
        <FormattedMessage id="web.settings.adminSettings.dialerSettings.callReason" />
      </h3>
      <div className="dialer-settings-card-notice margin-top-20">
        <FormattedMessage id="web.settings.adminSettings.dialerSettings.callReasonSettingsCardNotice" />
      </div>
      <div className="margin-top-20">
        <Switch
          checked={callReasonEnabled}
          classes="padding-bottom-15"
          label={formatMessage({
            id: 'web.settings.adminSettings.dialerSettings.enableCallReasons',
          })}
          name="call_reason_enabled"
          onChange={(isChecked) =>
            handleSettingChange({ call_reason_enabled: isChecked })
          }
          rightLabel
        />
      </div>
      <div className="dialer-settings-card-options">
        <Radio
          checked={!forceCallReason}
          disabled={!callReasonEnabled}
          label={formatMessage({
            id: 'web.settings.adminSettings.dialerSettings.allowCallReasons',
          })}
          name="call-reason-selection"
          onChange={() => handleSettingChange({ force_call_reason: false })}
        />
        <Radio
          checked={forceCallReason}
          disabled={!callReasonEnabled}
          label={formatMessage({
            id: 'web.settings.adminSettings.dialerSettings.requireCallReasons',
          })}
          name="call-reason-selection"
          onChange={() => handleSettingChange({ force_call_reason: true })}
        />
      </div>
      <div className="dialer-settings-card-wrapper margin-top-20">
        <FormattedHTMLMessage
          id="web.settings.adminSettings.dialerSettings.currentTotalCallReasons"
          values={{ count: totalCallReasonOptions }}
        />
        <div className="dialer-settings-card-manage right">
          <a
            className="dialer-settings-card-manage-link"
            onClick={() => window.toutReact.manageCallReasonsModal()}
          >
            <FormattedMessage id="web.settings.adminSettings.dialerSettings.manageCallReasons" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  callReasonEnabled:
    get(
      state,
      'user.subscription.admin_settings.call_reason_enabled',
      false
    ).toString() === 'true',
  forceCallReason:
    get(
      state,
      'user.subscription.admin_settings.force_call_reason',
      false
    ).toString() === 'true',
  totalCallReasonOptions:
    (state.callReasonOptions && state.callReasonOptions.length) || 0,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(CallReasonSettingsCard);

CallReasonSettingsCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  callReasonEnabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  forceCallReason: PropTypes.bool,
  intl: intlShape.isRequired,
  totalCallReasonOptions: PropTypes.number,
};

CallReasonSettingsCard.defaultProps = {
  callReasonEnabled: false,
  forceCallReason: false,
  totalCallReasonOptions: 0,
};
