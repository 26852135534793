import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getInstancePreviewByPId(
  id = '',
  personId = '',
  identiyId = ''
) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.campaignInstancePreviewByPId
        .replace(':#id', id)
        .replace(':#personId', personId),
      { identity_id: identiyId },
      resolve,
      reject
    );
  });
}
