import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PersonCell from 'table/modules/cells/personCell';
import ToutTable from 'table/modules/toutTable';
import { Align } from 'table/modules/constants';
import CompletionCell from 'web/campaigns/people/components/cells/completionCell';
import OutcomeCell from 'web/campaigns/people/components/cells/outcomeCell';
import QuickActionsCell from 'web/campaigns/people/components/cells/quickActionsCell';
import TextCustomCell from 'table/modules/cells/textCustomCell/textCustomCell';
import { getImageUrl } from 'web/libs/constants';
import { PeopleFilters } from 'web/campaigns/people/libs/campaignsPeopleConstants';
import { isAdmin } from 'web/shared/services/accountService';
import { injectIntl, intlShape } from 'react-intl';
import { tablePerPageFromStorage } from 'web/services/storageService';
import { getPersonName, getPersonTitle } from 'table/modules/toutHelpers';
import './campaignPeople.scss';
import debounce from 'lodash/debounce';

const ROW_HEIGHT = 70;
const IMAGE_WIDTH = 210;
const MIN_SEARCH_CHAR_LENGTH = 3
const SMART_FILTER_WIDTH = 300;

class CampaignPeople extends Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: props.peopleSearchString };
    this.debouncedSearch = debounce(this.search, 500);
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const {
      account,
      actionCreators,
      peopleSearchString,
      selectedCampaign: { id },
    } = this.props;
    actionCreators.setPeopleViewAsFilter(account.id);
    actionCreators.getCampaignPeople(id, {
      state: PeopleFilters.inProgress,
      userId: account.id,
    });
    if (peopleSearchString) {
      actionCreators.searchCampaignPeopleChange(id, peopleSearchString);
    }
  }

  componentWillUnmount() {
    this.props.actionCreators.resetCampaignPeople();
  }

  render() {
    const {
      intl: { formatMessage },
      loading,
      people,
    } = this.props;
    const columns = this._getColumns();

    return (
      <div className="campaign-people">
        <ToutTable
          bulkActions={this._getBulkActions(formatMessage)}
          columns={columns}
          items={people}
          loading={loading}
          loadingGetUrl={getImageUrl}
          pagination={this._getPagination()}
          row={{
            height: ROW_HEIGHT,
          }}
          search={this._getSearch()}
          smartFilters={this._getSmartFilters()}
          tableId="people-campaigns"
          zeroState={this._getZeroState(formatMessage)}
          allItems
          columnFilter
          selectable
          unknownTotal
        />
      </div>
    );
  }

  _getColumns = () => {
    const {
      actionCreators,
      intl: { formatMessage },
      selectedCampaign,
    } = this.props;
    return [
      {
        id: 'contact',
        name: formatMessage({ id: 'web.campaigns.people.contact' }),
        rowCell: {
          component: this._personProxyComponent,
          metadata: {
            openPersonDetails: actionCreators.openPersonDetails,
          },
        },
      },
      ...(isAdmin()
        ? [
            {
              id: 'user',
              name: formatMessage({ id: 'common.user' }),
              rowCell: {
                component: TextCustomCell,
                metadata: {
                  formatText: (rawData) =>
                    rawData.person.user ? rawData.person.user.name : '',
                },
              },
            },
          ]
        : []),
      {
        align: Align.CENTER,
        id: 'completion',
        name: formatMessage({ id: 'web.campaigns.people.completion' }),
        rowCell: {
          component: CompletionCell,
          metadata: {
            selectedCampaign,
          },
        },
      },
      {
        align: Align.CENTER,
        id: 'outcome',
        name: formatMessage({ id: 'web.campaigns.people.outcome' }),
        rowCell: {
          component: OutcomeCell,
          metadata: {
            peopleConfirmationComplete:
              actionCreators.peopleConfirmationComplete,
          },
        },
      },
      {
        align: Align.CENTER,
        id: 'quick-actions',
        name: formatMessage({ id: 'common.quickActions' }),
        rowCell: {
          component: QuickActionsCell,
          metadata: {
            actionCreators,
          },
        },
      },
    ];
  };

  _personProxyComponent = (params) => {
    const {
      className,
      openPersonDetails,
      rowData: { person },
    } = params;
    return (
      <PersonCell
        className={className}
        id={person.id}
        openPersonDetails={openPersonDetails}
        subTitle={getPersonTitle(person)}
        title={getPersonName(person)}
      />
    );
  };

  _getBulkActions(formatMessage) {
    return {
      actions: [
        {
          label: formatMessage({ id: 'common.remove' }),
          onClick: this.onClickRemove,
        },
        {
          label: formatMessage({ id: 'common.success' }),
          onClick: this.onClickSuccess,
        },
      ],
    };
  }

  _getPagination = () => {
    const { actionCreators, peoplePagination } = this.props;
    return {
      currentPage: peoplePagination,
      onClick: actionCreators.peoplePaginationClick,
      perPage: tablePerPageFromStorage(),
    };
  };

  search = () => {
    const { actionCreators, selectedCampaign } = this.props;
    const value = this.state.searchValue;
    if (value.length >= MIN_SEARCH_CHAR_LENGTH) {
      actionCreators.searchCampaignPeopleChange(selectedCampaign.id, value);
    }
  };
  
  searchOnChange = (value) => {
    const { actionCreators } = this.props;
    actionCreators.setSearchCampaignPeople(value);
    this.setState({ searchValue: value }, this.debouncedSearch);
  };

  _getSearch = () => {
    const {
      actionCreators,
      intl: { formatMessage },
    } = this.props;
    const { searchValue } = this.state;
    return {
      noDropdown: true,
      onChange: this.searchOnChange,
      onClear: actionCreators.resetCampaignPeopleSearch,
      placeholderText: formatMessage({
        id: 'web.tables.people.searchPlaceholder',
      }),
      value: searchValue,
    };
  };

  _getSmartFilters = () => {
    const {
      account,
      actionCreators,
      filter,
      viewAsFilter,
      intl: { formatMessage },
      selectedCampaign,
    } = this.props;
    let currentUsersFilterItems = [];
    if (selectedCampaign.current_users) {
      currentUsersFilterItems = selectedCampaign.current_users.map((user) => ({
        label: user.name,
        value: user.id,
      }));
    }
    const smartFilters = [
      {
        filters: [
          {
            label: formatMessage({ id: 'web.campaigns.queued' }),
            value: PeopleFilters.unstarted,
          },
          {
            label: formatMessage({ id: 'web.campaigns.inProgress' }),
            value: PeopleFilters.inProgress,
          },
          {
            label: formatMessage({ id: 'web.campaigns.completed' }),
            value: PeopleFilters.completed,
          },
        ],
        label: formatMessage({ id: 'selects.smartFilter.label' }),
        onChange: actionCreators.peopleFilterChange,
        selectedValue: filter,
        width: SMART_FILTER_WIDTH,
      },
    ];
    if (account.admin) {
      smartFilters.unshift({
        filters: [
          {
            label: formatMessage({ id: 'common.all' }),
            value: PeopleFilters.all,
          },
          {
            label: account.name,
            value: account.id,
          },
          ...currentUsersFilterItems,
        ],
        label: formatMessage({ id: 'web.templates.viewAs' }),
        onChange: actionCreators.peopleViewAsFilterChange,
        selectedValue: viewAsFilter,
        width: SMART_FILTER_WIDTH,
      });
    }
    return smartFilters;
  };

  _getZeroState(formatMessage) {
    return {
      bodyText: formatMessage({ id: 'web.campaigns.zeroState.noPeopleBody' }),
      imageUrl: getImageUrl('lonely-planet', 'svg'),
      imageWidth: IMAGE_WIDTH,
      titleText: formatMessage({ id: 'web.campaigns.zeroState.noPeopleTitle' }),
    };
  }

  onClickRemove = (ids, callback) => {
    this.props.actionCreators.peopleConfirmationComplete(ids, false, callback);
  };

  onClickSuccess = (ids, callback) => {
    this.props.actionCreators.peopleConfirmationComplete(ids, true, callback);
  };
}

CampaignPeople.propTypes = {
  account: PropTypes.object.isRequired,
  actionCreators: PropTypes.object,
  filter: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool,
  people: PropTypes.array,
  peoplePagination: PropTypes.number.isRequired,
  peopleSearchString: PropTypes.string.isRequired,
  selectedCampaign: PropTypes.object,
  viewAsFilter: PropTypes.number.isRequired,
};

CampaignPeople.defaultProps = {
  actionCreators: {},
  loading: false,
  people: [],
  selectedCampaign: {},
};

export default injectIntl(CampaignPeople);
