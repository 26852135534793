import SalesforceCustomizationActionTypes from 'web/modals/salesforceCustomization/libs/salesforceCustomizationActionTypes';

const initialState = {
  hasValidCredentials: false,
  progress: 0,
  status: null,
  wizardStep: null,
};

export const salesforceCustomization = (state = initialState, payload = {}) => {
  const { type, ...data } = payload;

  switch (type) {
    case SalesforceCustomizationActionTypes.progress.set:
      return {
        ...state,
        progress: data.progress,
      };
    case SalesforceCustomizationActionTypes.progress.clear:
      return {
        ...state,
        progress: initialState.progress,
      };

    case SalesforceCustomizationActionTypes.status.set:
      return {
        ...state,
        status: data.status,
      };
    case SalesforceCustomizationActionTypes.status.clear:
      return {
        ...state,
        status: initialState.status,
      };
    case SalesforceCustomizationActionTypes.setWizardStep:
      return {
        ...state,
        wizardStep: data.wizardStep,
      };

    default:
      return state;
  }
};
