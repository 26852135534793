import TeamsActionTypes from '../libs/teamsActionTypes';
import {
  getTeams as getTeamsCall,
  createTeam as createTeamCall,
  deleteTeam as deleteTeamCall,
  updateTeam as updateTeamCall,
  revokeTeamAdmin as revokeTeamAdminCall,
  grantTeamAdmin as grantTeamAdminCall,
} from '../services/teamsService';

export const setTeamsFetched = (fetched = true) => ({
  type: TeamsActionTypes.setTeamsFetched,
  fetched,
});

export const setTeamsFetching = (fetching = true) => ({
  type: TeamsActionTypes.setTeamsFetching,
  fetching,
});

export const setTeams = (teams) => ({
  type: TeamsActionTypes.setTeams,
  teams,
});

export const getTeams = () => (dispatch) => {
  dispatch(setTeamsFetching());
  return getTeamsCall()
    .then((teams) => {
      dispatch(setTeams(teams));
      return teams;
    })
    .finally(() => {
      dispatch(setTeamsFetching(false));
      dispatch(setTeamsFetched());
    });
};

export const createTeam = (newTeam) => (dispatch) =>
  createTeamCall(newTeam).then((team) => {
    dispatch({
      type: TeamsActionTypes.createTeam,
      team,
    });
    return team;
  });

export const updateTeam = (updatedTeam) => (dispatch) =>
  updateTeamCall(updatedTeam).then((team) => {
    dispatch({
      type: TeamsActionTypes.updateTeam,
      team,
    });
    return team;
  });

export const deleteTeam = (id) => (dispatch) =>
  deleteTeamCall(id).then((result) => {
    dispatch({
      type: TeamsActionTypes.deleteTeam,
      id,
    });
    return result;
  });

export const grantTeamAdmin = (teamId, userId) => (dispatch) =>
  grantTeamAdminCall(teamId, userId).then((team) => {
    dispatch({
      type: TeamsActionTypes.grantTeamAdmin,
      team,
    });
    return team;
  });

export const revokeTeamAdmin = (teamId, userId) => (dispatch) =>
  revokeTeamAdminCall(teamId, userId).then((team) => {
    dispatch({
      type: TeamsActionTypes.revokeTeamAdmin,
      team,
    });
    return team;
  });
