import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import RadioGroup from 'components/buttons/radioGroup';
import HoverTooltip from 'components/hoverTooltip';
import Action, { ButtonColors } from 'components/buttons/action';
import { SalesforceType } from '../../libs/salesforceConstants';
import './salesforceConnectionCard.scss';
import { isOwaPluginMode } from 'web/services/windowService';

class SalesforceConnectionCard extends Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (props.defaultSelectedType !== state.prevDefaultSelectedType) {
      return {
        prevDefaultSelectedType: props.defaultSelectedType,
        selectedType: props.defaultSelectedType,
      };
    }
    return null;
  }

  render() {
    const {
      intl: { formatMessage },
      setup,
    } = this.props;
    const { selectedType } = this.state;

    return (
      <div className="salesforce-connection-card">
        <h3>
          <FormattedMessage id="web.salesforce.connectionCard.title" />
        </h3>
        <div className="margin-top-20">{this.getMessage()}</div>
        <div className="margin-top-20">
          <RadioGroup
            className="production-sandbox-choice"
            disabled={setup}
            items={[
              {
                label: formatMessage({ id: 'common.salesforce' }),
                value: SalesforceType.salesforce,
              },
              {
                label: formatMessage({ id: 'common.sandbox' }),
                value: SalesforceType.salesforceSandbox,
              },
            ]}
            onChange={this.setSelectedType}
            selected={selectedType}
          />
          <HoverTooltip
            className="tooltip-button"
            delayHide={0}
            place="right"
            textId={this.getTooltipTextId()}
            multiline
          >
            <i className="tout-icon-warning" />
          </HoverTooltip>
        </div>
        <div className="margin-top-20 right">{this.getActions()}</div>
      </div>
    );
  }

  handleConnect = () => {
    const { onConnectSalesforce } = this.props;
    const { selectedType } = this.state;
    onConnectSalesforce(selectedType);
  };

  handleDisconnect = () => {
    const { onDisconnectSalesforce } = this.props;
    const { selectedType } = this.state;
    onDisconnectSalesforce(selectedType);
  };

  handleReconnect = () => {
    const { onConnectSalesforce } = this.props;
    const { selectedType } = this.state;
    onConnectSalesforce(selectedType, true);
  };

  setSelectedType = (type) => this.setState({ selectedType: type });

  getTooltipTextId = () => {
    const { setup } = this.props;
    const { selectedType } = this.state;
    if (!setup) return 'web.salesforce.connectionCard.tooltip.notConnected';
    return selectedType === SalesforceType.salesforce
      ? 'web.salesforce.connectionCard.tooltip.connectedToProd'
      : 'web.salesforce.connectionCard.tooltip.connectedToSandbox';
  };

  getMessage = () => {
    const {
      account: { username },
      broken,
      setup,
    } = this.props;
    if (!setup) {
      return (
        <FormattedMessage id="web.salesforce.connectionCard.notConnected" />
      );
    }
    if (broken || !username) {
      return (
        <Fragment>
          <i className="tout-icon-close" />
          <FormattedHTMLMessage id="web.salesforce.connectionCard.broken" />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <i className="tout-icon-tick" />
        <FormattedHTMLMessage
          id="web.salesforce.connectionCard.connected"
          values={{
            name: username,
          }}
        />
      </Fragment>
    );
  };

  getActions = () => {
    const {
      account: { username },
      broken,
      setup,
    } = this.props;
    const disconnectAction = (
      <Action
        color={ButtonColors.red}
        onClick={this.handleDisconnect}
        type="button"
      >
        <FormattedMessage id="common.disconnect" />
      </Action>
    );
    if (!setup) {
      return (
        <Action onClick={this.handleConnect} type="button">
          <FormattedMessage
            id={
              isOwaPluginMode()
                ? 'conversationToolbar.connectToSalesforce'
                : 'common.connect'
            }
          />
        </Action>
      );
    }
    if (broken || !username) {
      return (
        <Fragment>
          {disconnectAction}
          <Action
            className="margin-left-10"
            onClick={this.handleReconnect}
            type="button"
          >
            <FormattedMessage id="common.reconnect" />
          </Action>
        </Fragment>
      );
    }
    return disconnectAction;
  };
}

SalesforceConnectionCard.propTypes = {
  account: PropTypes.object,
  broken: PropTypes.bool,
  defaultSelectedType: PropTypes.oneOf([
    SalesforceType.salesforce,
    SalesforceType.salesforceSandbox,
  ]),
  intl: intlShape.isRequired,
  onConnectSalesforce: PropTypes.func.isRequired,
  onDisconnectSalesforce: PropTypes.func.isRequired,
  setup: PropTypes.bool,
};

SalesforceConnectionCard.defaultProps = {
  account: {},
  broken: false,
  connected: false,
  defaultSelectedType: SalesforceType.salesforce,
  setup: false,
};

export default injectIntl(SalesforceConnectionCard);
