import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TabNav from 'components/tabNav/tabNav';
import LiveFeedTabView from 'web/liveFeed/components/liveFeedTabView';
import { LiveFeedTabsEnum } from 'web/liveFeed/libs/liveFeedConstants';
import './liveFeedTabs.scss';

const LiveFeedTabs = (props) => {
  const tabList = [
    {
      value: LiveFeedTabsEnum.engagement.value,
      title: <FormattedMessage id={LiveFeedTabsEnum.engagement.label} />,
    },
    {
      value: LiveFeedTabsEnum.tasks.value,
      title: <FormattedMessage id={LiveFeedTabsEnum.tasks.label} />,
    },
    {
      value: LiveFeedTabsEnum.target.value,
      title: <FormattedMessage id={LiveFeedTabsEnum.target.label} />,
    },
  ];

  const itemWidth = parseInt(window.innerWidth / tabList.length, 10);

  return (
    <div className="live-feed-tabs-nav">
      <TabNav
        itemWidth={itemWidth}
        list={tabList}
        onTabSelect={props.actionCreators.selectTab}
        selectedTabValue={props.selectedTabValue}
      />
      <div className="live-feed-tabs-border" />
      <LiveFeedTabView {...props} />
    </div>
  );
};

LiveFeedTabs.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  engageFeatureEnabled: PropTypes.bool.isRequired,
  selectedTabValue: PropTypes.string,
};

LiveFeedTabs.defaultProps = {
  selectedTabValue: LiveFeedTabsEnum.engagement.value,
};

export default LiveFeedTabs;
