import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { getPeopleRequestParamsFetch } from 'web/people/services/peopleRequestParams';
import { SORT_DIRECTION, DEFAULT_PAGE } from 'web/people/libs/peopleConstants';
import {
  setTableSort,
  setTablePage,
  setTablePerPage,
} from 'web/people/actionCreators/peopleTableActionCreators';
import {
  getPeopleFetch,
  getPeopleIdsByGroup as getPeopleIdsByGroupCall,
} from 'web/people/services/peopleService';
import { getUnsubscribeOptions } from 'web/unsubscribe/unsubscribeOptions/actionCreators/unsubscribeOptionsActionCreators';
import { isUnsubscribeReasonsFull } from 'web/unsubscribe/unsubscribeOptions/helpers/unsubscribeOptionsHelpers';
import { isCurrentGroupUnsubscribed } from 'web/people/selectors/peopleSelectors';
import { transformPeopleData } from 'web/people/helpers/peopleHelpers';
import { getAllItemsIdsFetch } from 'web/services/elasticSearch';

export const setPeopleLoading = (isLoading) => ({
  isLoading,
  type: PeopleActionTypes.people.loading,
});

export const setPeopleSelectAllRequestParams = (requestParams) => ({
  requestParams,
  type: PeopleActionTypes.people.setSelectAllRequestParams,
});

export const getPeople = (notFilters = {}) => (dispatch, getState) => {
  const state = getState();
  const { unsubscribeReasons } = state;
  const isUnsubscribedGroup = isCurrentGroupUnsubscribed(state);

  const getPeopleRequestParams = getPeopleRequestParamsFetch;
  const getPeoplePromise = getPeopleFetch;

  dispatch(setPeopleLoading(true));

  getPeopleRequestParams(state).then(
    (params) => {
      const promises = [];

      if (params) {
        dispatch(setPeopleSelectAllRequestParams(params));
        promises.push(getPeoplePromise({ ...params, not_filters: notFilters }));
      }

      if (
        isUnsubscribedGroup &&
        !isUnsubscribeReasonsFull(unsubscribeReasons)
      ) {
        promises.push(dispatch(getUnsubscribeOptions()));
      }

      Promise.all(promises)
        .then(([data]) => {
          dispatch(setPeopleLoading(false));
          dispatch({
            data: transformPeopleData(data),
            type: PeopleActionTypes.people.set,
          });
        })
        .catch(() => {
          dispatch(setPeopleLoading(false));
          dispatch(openViewAlert(PeopleAlertIds.fetchError));
        });
    },
    () => {
      dispatch(setPeopleLoading(false));
      dispatch(openViewAlert(PeopleAlertIds.fetchError));
    }
  );
};

export const sortPeople = (columnToSort = '', directionToSort = '') => (
  dispatch
) => {
  const direction = SORT_DIRECTION[directionToSort.toLowerCase()] || '';
  const column = columnToSort.toLowerCase();
  dispatch(setTableSort(column, direction));
  return dispatch(getPeople());
};

export const paginatePeople = (page = DEFAULT_PAGE) => (dispatch) => {
  dispatch(setTablePage(page));
  return dispatch(getPeople());
};

export const paginatePeoplePerPage = (perPage) => (dispatch) => {
  dispatch(setTablePerPage(perPage));
  return dispatch(paginatePeople(1));
};

export const getAllPeopleIds = (params) => (dispatch) => {
  dispatch(setPeopleLoading(true));

  return getAllItemsIdsFetch(params)
    .then((data) => {
      dispatch(setPeopleLoading(false));
      return data;
    })
    .catch(() => {
      dispatch(setPeopleLoading(false));
      dispatch(openViewAlert(PeopleAlertIds.fetchError));
    });
};

export const getPeopleIdsByGroup = (groupId) => (dispatch) => {
  dispatch(setPeopleLoading(true));
  return getPeopleIdsByGroupCall(groupId)
    .then((data) => {
      dispatch(setPeopleLoading(false));
      return data;
    })
    .catch(() => {
      dispatch(setPeopleLoading(false));
      dispatch(openViewAlert(PeopleAlertIds.fetchError));
    });
};
