import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import { TEMPLATE_SPECIAL_CATEGORY } from 'web/templates/libs/templatesConstants';

export const templatesCategories = (
  state = [],
  { type, categories = [], category, updatedCategory, removedCategoryId }
) => {
  let temp = [];
  let updatedIndex = 0;
  switch (type) {
    case TemplatesActionTypes.categories.set:
      return categories;
    case TemplatesActionTypes.categories.add:
      return state.some((cat) => cat.id === category.id)
        ? state
        : [...state, category];
    case TemplatesActionTypes.categories.update:
      temp = [...state];
      updatedIndex = temp.findIndex((cat) => cat.id === updatedCategory.id);
      temp[updatedIndex] = {
        ...temp[updatedIndex],
        name: updatedCategory.name,
      };
      return temp;
    case TemplatesActionTypes.categories.delete:
      temp = [...state];
      return temp.filter((cat) => cat.id !== removedCategoryId);
    default:
      return state;
  }
};

export const templatesSharableCategories = (
  state = [],
  { type, categories = [] }
) => {
  switch (type) {
    case TemplatesActionTypes.sharableCategories.set:
      return categories;
    default:
      return state;
  }
};

export const templatesSelectedTemplateCategoryId = (
  state = TEMPLATE_SPECIAL_CATEGORY.all.id,
  { type, id }
) => {
  switch (type) {
    case TemplatesActionTypes.categories.select:
      return id || TEMPLATE_SPECIAL_CATEGORY.all.id;
    default:
      return state;
  }
};

export const templatesPopupSelectedCategory = (
  state = {},
  { type = '', selectedCategory = {} }
) => {
  switch (type) {
    case TemplatesActionTypes.categories.updateSelectedCategory:
      return selectedCategory;
    case PopupActionTypes.close:
      return {};
    default:
      return state;
  }
};

export const templatesNewCategoryName = (state = '', { type, name = '' }) => {
  switch (type) {
    case TemplatesActionTypes.categories.newOnChange:
      return name;
    case PopupActionTypes.close:
      return '';
    default:
      return state;
  }
};

export const templatesCategoriesLoading = (
  state = false,
  { type, isLoading = false }
) => {
  switch (type) {
    case TemplatesActionTypes.categories.loading:
      return isLoading;
    default:
      return state;
  }
};

export function templatesEditingCategory(state = null, { type, id = null }) {
  switch (type) {
    case TemplatesActionTypes.categories.edit:
      return id;
    default:
      return state;
  }
}

export const templatesSpecialCategoryTags = (
  state = [],
  { type, tags = [] }
) => {
  switch (type) {
    case TemplatesActionTypes.specialCategoryTags.set:
      return tags;
    case TemplatesActionTypes.specialCategoryTags.reset:
      return [];
    default:
      return state;
  }
};
