import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { TabValues } from '../../libs/sharingSettingsConstants';
import { getAlert } from './sharingSettingsHelper';
import Page from 'components/layouts/page';
import Alert from 'components/alert';
import PageHeader from 'components/layouts/page/header';
import PageTabNavBar from 'components/layouts/page/tabNavBar';
import PageTabNav from 'components/layouts/page/tabNav';
import PageView from 'components/layouts/page/view';
import TwoPaneLayout, { BorderColors } from 'components/layouts/twoPane';
import SharingSettingsSidebar from '../sharingSettingsSidebar';
import SharingSettingsTable from '../sharingSettingsTable';
import Switch, { SwitchColors } from 'components/buttons/switch';
import HoverTooltip from 'components/hoverTooltip';
import './sharingSettings.scss';

const SIDEBAR_WIDTH = 230;

class SharingSettings extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const {
      actionCreators,
      intl: { formatMessage },
    } = this.props;
    this.headerButtons = [
      {
        onClick: actionCreators.openAddTeams,
        textId: 'web.settings.sharing.addTeams',
      },
    ];
    this.tabNavList = [
      {
        title: formatMessage({ id: 'common.templates' }),
        value: TabValues.templates,
      },
    ];
  }

  render() {
    const {
      actionCreators,
      alert,
      categories,
      intl,
      permissionsEnabled,
      selectedCategoryIndex,
      selectedTabValue,
      sidebarLoading,
      tableFetching,
      tableLoading,
      teams,
    } = this.props;

    return (
      <Page className="sharing-settings">
        {alert ? <Alert {...getAlert(alert, actionCreators)} /> : null}
        {permissionsEnabled ? null : (
          <div className="sharing-settings-permissions-disabled" />
        )}
        <PageHeader
          buttons={this.headerButtons}
          textId="web.settings.sharing.title"
        />
        <PageTabNavBar border>
          <PageTabNav
            list={this.tabNavList}
            onTabSelect={actionCreators.onTabSelect}
            selectedTabValue={selectedTabValue}
          />
          <HoverTooltip
            className="sharing-settings-not-disableable"
            textId="web.settings.sharing.help"
          >
            <Switch
              checked={permissionsEnabled}
              className="sharing-settings-not-disableable"
              color={SwitchColors.green}
              label={intl.formatMessage({ id: 'web.settings.sharing.enabled' })}
              name="togglePermissionsEnabled"
              onChange={actionCreators.setPermissionsEnabled}
            />
          </HoverTooltip>
        </PageTabNavBar>
        <PageView>
          <TwoPaneLayout
            borderColor={BorderColors.grayLight}
            sidebarWidth={SIDEBAR_WIDTH}
          >
            <SharingSettingsSidebar
              actionCreators={actionCreators}
              categories={categories}
              loading={sidebarLoading}
              selectedCategoryIndex={selectedCategoryIndex}
              selectedTabValue={selectedTabValue}
            />
            <SharingSettingsTable
              actionCreators={actionCreators}
              fetching={tableFetching}
              isTemplates={selectedTabValue === TabValues.templates}
              loading={tableLoading}
              teams={teams}
            />
          </TwoPaneLayout>
        </PageView>
      </Page>
    );
  }
}

SharingSettings.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  alert: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  permissionsEnabled: PropTypes.bool.isRequired,
  selectedCategoryIndex: PropTypes.number.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
  sidebarLoading: PropTypes.bool.isRequired,
  tableFetching: PropTypes.bool.isRequired,
  tableLoading: PropTypes.bool.isRequired,
  teams: PropTypes.array.isRequired,
};

export default injectIntl(SharingSettings);
