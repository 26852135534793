import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';
import ViewMore from 'components/viewMore';
import LiveFeedEvent from 'web/liveFeed/components/liveFeedEvent';
import Button from 'components/buttons/action';
import I18N from 'languages';

const SHOWN_COUNT = 10;

const itemRenderer = (
  { id, ...event } //eslint-disable-line react/prop-types
) => (
  <LiveFeedEvent
    {...event}
    eventId={id}
    key={`person-details-marketo-event-${id}`}
    staticView
  />
);

const PersonDetailsMarketoEventsCard = ({
  events,
  getMoreEvents,
  loading,
  startDate,
}) => (
  <div className="person-details-marketo-events-card">
    {loading && <LoadingSpinner imageUrl={getImageUrl} />}
    <h3 className="margin-bottom-half">
      <FormattedMessage id="web.person.personDetails.marketoEventsCard.title" />
    </h3>
    {(events.length > 0 && (
      <ViewMore
        itemRenderer={itemRenderer}
        items={events}
        showCount
        shownCount={SHOWN_COUNT}
      />
    )) || (
      <FormattedMessage id="web.person.personDetails.marketoEventsCard.noEvents" />
    )}
    <div className="flex--horz-spread margin-top-half">
      <FormattedMessage
        id="web.person.personDetails.marketoEventsCard.activitiesDate"
        values={{
          date: moment(startDate).format(I18N.DateFormats.DATE_SHORT),
        }}
      />
      <Button onClick={getMoreEvents}>
        <FormattedMessage id="web.person.personDetails.marketoEventsCard.getMore" />
      </Button>
    </div>
  </div>
);

PersonDetailsMarketoEventsCard.propTypes = {
  getMoreEvents: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  startDate: PropTypes.string,
};

PersonDetailsMarketoEventsCard.defaultProps = {
  startDate: undefined,
};

export default PersonDetailsMarketoEventsCard;
