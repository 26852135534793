import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  AllowedImageFileTypes,
  GlobalAlertIds,
} from 'web/emailComposeBody/libs/emailComposeBodyConstants';
import {
  closeEmailComposePopup,
  setEmailComposeAlert,
} from './popupActionCreators';
import { imageInsertUploadFile } from './fileActionCreators';
import { insertHtml } from './emailComposeActionCreators';

export function initImageInsertEditState(data) {
  return {
    type: ActionTypes.emailComposeBody.state.imageInsertInitEditState,
    data: {
      height: `${data.height}`,
      link: data.link || '',
      name: data.name || '',
      width: `${data.width}`,
      url: data.url,
    },
  };
}

export function imageInsertInsert() {
  return (dispatch, getState) => {
    const { emailComposeImageInsertFile: file, emailComposePopup } = getState();
    dispatch(
      insertHtml(
        emailComposePopup.editorId,
        `<img alt='${file.name}' src='${file.public_url || ''}' />`
      )
    );
    dispatch(closeEmailComposePopup());
  };
}

export function imageInsertUpdate() {
  return (dispatch, getState) => {
    const {
      emailComposeImageInsertEditState: editState,
      emailComposePopup,
    } = getState();
    const img = `<img alt='${editState.name}' src='${editState.url ||
      ''}' style='height: ${editState.height}px; width: ${
      editState.width
    }px;' />`;

    if (editState.link) {
      dispatch(
        insertHtml(
          emailComposePopup.editorId,
          `<a href='${editState.link}' target='_blank'>${img}</a>`
        )
      );
    } else {
      dispatch(insertHtml(emailComposePopup.editorId, img));
    }

    dispatch(closeEmailComposePopup());
  };
}

export function imageInsertOnChange(data) {
  return {
    type: ActionTypes.emailComposeBody.state.imageInsertChange,
    data,
  };
}

function checkForAlert(files) {
  if (!files || !files.length) {
    return GlobalAlertIds.imageInsertIssue;
  } else if (!AllowedImageFileTypes[files[0].type]) {
    return GlobalAlertIds.imageInsertFileTypeInvalid;
  } else if (files[0].size > 10000000) {
    return GlobalAlertIds.imageInsertFileTooLarge;
  } else {
    return null;
  }
}

export function imageInsertUploadFiles(files) {
  return (dispatch) => {
    const alert = checkForAlert(files);
    if (alert) {
      dispatch(setEmailComposeAlert(alert));
    } else {
      dispatch(imageInsertUploadFile(files[0]));
    }
  };
}
