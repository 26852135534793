import { baseGet, basePost, basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getOpportunities() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.gong.getOpportunities, {}, resolve, reject);
  });
}

export function postOpportunity(data) {
  return new Promise((resolve, reject) => {
    basePost(Urls.gong.postOpportunities, data, resolve, reject);
  });
}

export function putOpportunity(data) {
  return new Promise((resolve, reject) => {
    basePut(Urls.gong.putOpportunities(data.deal.id), data, resolve, reject);
  });
}
