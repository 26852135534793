import React from 'react';
import { Field, Form } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import Label from 'components/label';
import Select from 'web/form/components/fields/select';
import { LABEL_WIDTH } from '../libs/timezoneSettingsConstants';
import { requiredSelect as requiredSelectFactory } from 'web/form/helpers/validators';
import './timezoneSettingsContainer.scss';
import {
  timezoneSettingsPropTypes,
  timezoneSettingsDefaultProps,
} from './timezoneSettingsPropTypes';

const requiredSelectValidator = requiredSelectFactory();

const TimezoneSettings = (props) => {
  const {
    languagePlaceholder,
    languageOptions,
    localeOptions,
    localePlaceholder,
    yourTimezoneOptions,
    yourTimezonePlaceholder,
    onFormSubmit,
    pristine,
    isFormChanged,
    pending,
    reset,
    i18nFeatureFlagEnadled,
    invalid,
  } = props;

  return (
    <Form onSubmit={onFormSubmit} noValidate>
      {i18nFeatureFlagEnadled ? (
        <React.Fragment>
          <Label
            className="timezone-settings-name-label"
            id="timezone-languages"
            messageKey="web.settings.myProfile.language"
            width={LABEL_WIDTH}
            sideLabel
          >
            <Field
              clearable={false}
              component={Select}
              name="language"
              options={languageOptions}
              placeholder={languagePlaceholder}
              validate={requiredSelectValidator}
              searchable
            />
          </Label>
          <Label
            className="timezone-settings-name-label"
            classWrapper="margin-top-most"
            id="timezone-locale"
            messageKey="web.settings.myProfile.locale"
            width={LABEL_WIDTH}
            sideLabel
          >
            <Field
              clearable={false}
              component={Select}
              name="locale"
              options={localeOptions}
              placeholder={localePlaceholder}
              validate={requiredSelectValidator}
              searchable
            />
          </Label>
        </React.Fragment>
      ) : null}
      <Label
        className="timezone-settings-name-label"
        classWrapper="margin-top-most"
        id="timezone-yourTimezone"
        messageKey="web.settings.myProfile.youTimeZone"
        width={LABEL_WIDTH}
        sideLabel
      >
        <Field
          clearable={false}
          component={Select}
          name="timezone"
          options={yourTimezoneOptions}
          placeholder={yourTimezonePlaceholder}
          validate={requiredSelectValidator}
          searchable
        />
      </Label>
      <div className="timezone-settings-form-actions">
        {!pristine && (
          <Button
            className="flex-right"
            color={ButtonColors.gray}
            disabled={pending}
            onClick={reset}
            size={ButtonSizes.small}
            type="button"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
        )}
        <Button
          className="flex-right"
          color={ButtonColors.blue}
          disabled={!isFormChanged || pristine || pending || invalid}
          size={ButtonSizes.small}
          type="submit"
        >
          <FormattedMessage id="common.save" />
        </Button>
      </div>
    </Form>
  );
};

TimezoneSettings.propTypes = timezoneSettingsPropTypes;
TimezoneSettings.defaultProps = timezoneSettingsDefaultProps;

export default TimezoneSettings;
