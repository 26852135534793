import TablesTasksActionTypes from 'web/tables/tasks/libs/tablesTasksActionTypes';
import TasksActionTypes from 'web/tasks/libs/tasksActionTypes';
import {
  TasksTableStatusFilterValues,
  TasksTableDatesFilterValues,
} from 'web/tables/tasks/libs/tablesTasksConstants';
import { getFilterDatesRequired } from 'web/tables/helpers/itemsTableFilters';
import { getTasksCompleted } from 'web/tasks/actionCreators/tasksCompletedActionCreators';
import { getTasksUncompleted } from 'web/tasks/actionCreators/tasksUncompletedActionCreators';
import { track } from 'web/services/mixpanelService';
import { TablesEvents, TablesProperties } from 'web/libs/mixpanelEvents';
import { getTableSource } from '../services/tasksTableMixpanel';

export const setDatesShown = (id, dates) => ({
  id,
  type: TablesTasksActionTypes.datesShown,
  ...dates,
});

function datesSelected(id, value, start, end, isCustom, allowViewAsFilter) {
  return (dispatch, getState) => {
    const {
      tablesTasksStatusSmartFilterValue: statusValue,
      sidebarViewer,
    } = getState();

    const dates = getFilterDatesRequired(value, start, end);
    dispatch(setDatesShown(id, dates));

    const userId = allowViewAsFilter && sidebarViewer ? sidebarViewer.id : null;
    if (statusValue.get(id) === TasksTableStatusFilterValues.uncompleted) {
      dispatch(getTasksUncompleted(dates, isCustom, userId));
    } else {
      dispatch(getTasksCompleted(dates, userId));
    }
  };
}

/* Initial call will get base range that will always be stored in state. Custom selections will
 * add to the collection but will be cleared when leaving custom selection.
*/
export function onDatesSmartFilterChange(
  id,
  value,
  start,
  end,
  allowViewAsFilter
) {
  return (dispatch, getState) => {
    const {
      tablesTasksDatesSmartFilterValue: oldDatesValue,
      tablesTasksStatusSmartFilterValue: statusValue,
    } = getState();
    dispatch({
      id,
      type: TablesTasksActionTypes.datesSmartFilter,
      value,
    });

    if (oldDatesValue.get(id) === TasksTableDatesFilterValues.custom) {
      dispatch({ type: TasksActionTypes.customReset });
    }
    const isCustom = value === TasksTableDatesFilterValues.custom;

    dispatch(datesSelected(id, value, start, end, isCustom, allowViewAsFilter));

    track(TablesEvents.dateChange, {
      [TablesProperties.source]: getTableSource(id),
      [TablesProperties.datesRange]: value,
      [TablesProperties.view]: statusValue.get(id),
    });
  };
}

export function onStatusSmartFilterChange(id, value, allowViewAsFilter) {
  return (dispatch, getState) => {
    const {
      tablesTasksDatesSmartFilterValue: datesValueMap,
      tablesTasksSelectedDates: selectedDatesMap,
    } = getState();
    dispatch({
      id,
      type: TablesTasksActionTypes.statusSmartFilter,
      value,
    });

    const datesValue = datesValueMap.get(id);
    const isCustom = datesValue === TasksTableDatesFilterValues.custom;
    if (isCustom) {
      dispatch({
        id,
        type: TablesTasksActionTypes.datesSmartFilterReset,
        value: datesValue,
      });
    }

    const selectedDates = selectedDatesMap.get(id).toJS();
    dispatch(
      datesSelected(
        id,
        datesValue,
        selectedDates.start,
        selectedDates.end,
        isCustom,
        allowViewAsFilter
      )
    );

    track(TablesEvents.viewChange, {
      [TablesProperties.source]: getTableSource(id),
      [TablesProperties.view]: value,
      [TablesProperties.datesRange]: datesValue,
    });
  };
}

export const fetchTasksBySmartFilter = (id, allowViewAsFilter) => (
  dispatch,
  getState
) => {
  const {
    tablesTasksDatesSmartFilterValue: datesValueMap,
    tablesTasksSelectedDates: selectedDatesMap,
  } = getState();

  const datesValue = datesValueMap.get(id);
  const isCustom = datesValue === TasksTableDatesFilterValues.custom;
  const selectedDates = selectedDatesMap.get(id).toJS();

  dispatch(
    datesSelected(
      id,
      datesValue,
      selectedDates.start,
      selectedDates.end,
      isCustom,
      allowViewAsFilter
    )
  );
};

export function onRowAnimated(id) {
  return {
    id,
    type: TablesTasksActionTypes.animateRowOff,
  };
}

export function onTableMount(id) {
  return {
    id,
    type: TablesTasksActionTypes.tableInit,
  };
}

export function onTableUnmount(id) {
  return {
    id,
    type: TablesTasksActionTypes.tableReset,
  };
}

export function setTableLoading(id, loading) {
  return {
    id,
    loading,
    type: TablesTasksActionTypes.setLoading,
  };
}
