import {
  isOtherAuthorizationType,
  isConsentAuthorizationType,
} from 'web/compliance/helpers/complianceHelpers';

export const invalidComplianceAuthorization = (state) => {
  const {
    authorizationType,
    consentDate,
    consentPurposeValue,
    otherAuthorizationValue,
  } = state;

  return (
    (isOtherAuthorizationType(authorizationType) &&
      !otherAuthorizationValue.trim()) ||
    (isConsentAuthorizationType(authorizationType) && !consentDate) ||
    (isConsentAuthorizationType(authorizationType) &&
      !consentPurposeValue.trim())
  );
};

export const isValidAuthorizationInput = (state) =>
  !invalidComplianceAuthorization(state.peopleAuthorization);

export const formatPeopleComplianceAuthorization = (complianceData) => {
  const { otherAuthorizationValue } = complianceData;

  let {
    authorizationType: authorization,
    consentNotesValue: notes,
    consentPurposeValue: purpose,
    consentDate: consented_at,
  } = complianceData;

  if (!isConsentAuthorizationType(authorization)) {
    notes = null;
    purpose = null;
    consented_at = null;
  } else {
    notes = notes.trim() || null;
    purpose = purpose.trim();
  }

  if (!authorization) {
    authorization = null;
  } else if (isOtherAuthorizationType(authorization)) {
    authorization = otherAuthorizationValue.trim();
  }

  return { authorization, consented_at, notes, purpose };
};
