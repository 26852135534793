import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import Cards, { Direction } from 'components/layouts/cards';
import {
  PersonDetailsCards,
  PersonDetailsTabs,
} from 'web/person/libs/personDetailsConstants';
import { hasMarketo } from 'web/shared/helpers/subscriptionHelper';
import ToutEventsCard from '../toutEventsCard';
import MarketoEventsCard from '../marketoEventsCard';
import './engagementView.scss';

const _getCards = ({
  actionCreators: { getMarketoEvents, openEmailInfo, viewMoreOnClick },
  marketoEventsState,
  toutEventsState,
  user,
}) => {
  const toutEvents = {
    children: (
      <ToutEventsCard
        openEmailInfo={openEmailInfo}
        viewMoreOnClick={viewMoreOnClick}
        {...toutEventsState}
      />
    ),
    id: PersonDetailsCards.campaigns,
  };

  const warning = {
    children: (
      <div className="text-left tout-icon-warning">
        <FormattedMessage id="web.person.personDetails.engagementView.warning" />
      </div>
    ),
    id: `${PersonDetailsTabs.engagement}-warning`,
  };

  if (hasMarketo(user)) {
    return [
      toutEvents,
      {
        children: (
          <MarketoEventsCard
            getMoreEvents={getMarketoEvents}
            {...marketoEventsState}
          />
        ),
        id: PersonDetailsCards.emails,
      },
      warning,
    ];
  } else {
    return [toutEvents, warning];
  }
};

const EngagementView = (props) => (
  <div className="person-details-engagement-view flex--full">
    <Cards
      backgroundColor={BackgroundColors.grayExtraLight}
      cards={_getCards(props)}
      direction={Direction.vertical}
      maxWidth={0}
      minWidth={0}
      marginAround={FULL}
      marginBetween={FULL}
    />
  </div>
);

EngagementView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  marketoEventsState: PropTypes.object.isRequired,
  toutEventsState: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

EngagementView.defaultProps = {};

export default EngagementView;
