import { ColorClasses } from 'libs/constantsStyles';

export const SalesforceCustomizationPopupIds = {
  salesforceCustomizationPreInstall: 'SALESFORCE_CUSTOMIZATION_PRE_INSTALL',
  salesforceCustomizationInstallation: 'SALESFORCE_CUSTOMIZATION_INSTALLATION',
  salesforceCustomizationPostInstall: 'SALESFORCE_CUSTOMIZATION_POST_INSTALL',
  salesforceCustomizationPreUninstall: 'SALESFORCE_CUSTOMIZATION_PRE_UNINSTALL',
  salesforceCustomizationPreUpgrade: 'SALESFORCE_CUSTOMIZATION_PRE_UPGRADE',
  salesforceCustomizationUninstallation:
    'SALESFORCE_CUSTOMIZATION_UNINSTALLATION',
  salesforceCustomizationPostUninstall:
    'SALESFORCE_CUSTOMIZATION_POST_UNINSTALL',
};

export const SalesforceCustomizationOverlayIds = {
  salesforceCustomizationExitWarning:
    'SALESFORCE_CUSTOMIZATION_EXIT_WARNING_OVERLAY_ID',
};

export const SalesforceCustomizationStatusIds = {
  installed: 'installed',
  notInstalled: 'not-installed',
  packageInstalled: 'package-installed',
  runningInstall: 'running-install',
  runningUninstall: 'running-uninstall',
  notConnected: 'not-connected',
  needStatusInfo: 'need-status-info',
};

export const SalesforceCustomizationErrorsIds = {
  apiError: 'web.salesforceCustomization.defaultError.api',
  pusherError: 'web.salesforceCustomization.defaultError.pusher',
};

export const SalesforceCustomizationVersionsIds = {
  available: 'available_new_versions',
  completed: 'completed_versions',
  installed: 'installed_versions',
};

export const CUSTOMIZATION_STATUS_NOT_CONNECTED = 406;
export const PROGRESS_BAR_HEIGHT = 25;
export const CONNECT_URL =
  '/auth/salesforce?redirect_path=%23settings%2Fadmin%2Fsalesforce%2Fmarketo-se-customizations';
export const INSTALLATION_GUIDE_CLASSIC_LINK =
  'https://s3.amazonaws.com/tout-user-store/salesforce/assets/Marketo+Sales+Engage+For+Salesforce_+Installation+and+Success+Guide.pdf';
export const INSTALLATION_GUIDE_LIGHTNING_LINK =
  'https://s3.amazonaws.com/tout-user-store/salesforce/assets/SF+Guide+for+Lightning.pdf';
export const VERSION_DETAILS_LINK =
  'https://s3.amazonaws.com/tout-user-store/salesforce/assets/Salesforce_Versions.pdf';

export const CUSTOMIZATION_WIZARD_LEFT_PANE_WIDTH = '250px';
export const CUSTOMIZATION_WIZARD_LEFT_PANE_BORDER = ColorClasses.grayLight;
export const CUSTOMIZATION_WIZARD_ACCESS_TABLE_HEIGHT = 320;
export const CUSTOMIZATION_WIZARD_TABLE_HEIGHT = 344;
export const CUSTOMIZATION_WIZARD_TABLE_ROW_HEIGHT = 66;
export const CUSTOMIZATION_WIZARD_TABLE_CELL_LINES = 3;
export const CUSTOMIZATION_OPTIONS_FIELDS = 'CUSTOMIZATION_OPTIONS_FIELDS';
export const CUSTOMIZATION_OPTIONS_BUTTONS = 'CUSTOMIZATION_OPTIONS_BUTTONS';
export const CUSTOMIZATION_OPTIONS_OTHER = 'CUSTOMIZATION_OPTIONS_OTHER';
export const CUSTOMIZATION_OPTIONS_PROFILE_USERS =
  'CUSTOMIZATION_OPTIONS_PROFILE_USERS';
export const CUSTOMIZATION_OPTIONS_PROFILE_ACCESS =
  'CUSTOMIZATION_OPTIONS_PROFILE_ACCESS';
export const CUSTOMIZATION_SUMMARY = 'CUSTOMIZATION_SUMMARY';
export const CUSTOMIZATION_WIZARD = [
  CUSTOMIZATION_OPTIONS_FIELDS,
  CUSTOMIZATION_OPTIONS_BUTTONS,
  CUSTOMIZATION_OPTIONS_OTHER,
  CUSTOMIZATION_OPTIONS_PROFILE_USERS,
  CUSTOMIZATION_OPTIONS_PROFILE_ACCESS,
  CUSTOMIZATION_SUMMARY,
];

export const USERS_ADMINS = 'PROFILE_USERS_ADMINS';
export const USERS_ALL = 'PROFILE_USERS_ALL';
export const USERS_SPECIFIC = 'PROFILE_USERS_SPECIFIC';
export const PROFILE_TYPES = {
  [USERS_ADMINS]: 'admin',
  [USERS_ALL]: 'all',
  [USERS_SPECIFIC]: 'custom',
};
export const PROFILE_USERS = [USERS_ADMINS, USERS_ALL, USERS_SPECIFIC];
export const USERS_ADMINS_VALUE = 'System Administrator';

export const PROFILE_USERS_SPECIFIC_STEP_INDEX = 3;
export const CUSTOMIZATION_INSTALLATION_FAILED =
  'Marketo Sales Customization Installation Failed during deploy customization package.';
export const CUSTOMIZATION_UNINSTALLATION_FAILED =
  'Marketo Sales customizations uninstallation failed.';
