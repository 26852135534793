import { PopupBackgroundColors } from 'components/popups';
import { SharingSettingsAlertIds } from '../libs/sharingSettingsConstants';

const getDefaultAlert = (clearAlert) => ({
  onClose: clearAlert,
  color: PopupBackgroundColors.red,
});

export const getAlert = ({ alert }, { clearAlert }) => {
  switch (alert) {
    case SharingSettingsAlertIds.genericIssue:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.settings.sharing.alert.genericIssue',
      };
    default:
      return null;
  }
};
