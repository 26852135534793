import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileDropZone from 'components/fileDropZone';
import ImageUpload from './imageUpload';
import ImageEditor from './imageEditor';
import './imageInsert.scss';

class ImageInsert extends Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`imageInsert ${className || ''}`}>
        {this._getComponent()}
      </div>
    );
  }

  _getComponent = () => {
    const {
      editState,
      file,
      files,
      isTipVisible,
      onChange,
      supportedFormats,
      uploadFiles,
      multiple,
    } = this.props;
    if (file || (files && files.length)) {
      return (
        <ImageUpload file={file} files={files} isTipVisible={isTipVisible} />
      );
    }
    if (editState) {
      return <ImageEditor editState={editState} onChange={onChange} />;
    }
    return (
      <FileDropZone
        className="file-drop-zone-size"
        multiple={multiple}
        supportedFormats={supportedFormats}
        uploadFiles={uploadFiles}
        empty
      />
    );
  };
}

ImageInsert.propTypes = {
  className: PropTypes.string,
  editState: PropTypes.object,
  file: PropTypes.object,
  files: PropTypes.array,
  isTipVisible: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  supportedFormats: PropTypes.string,
  uploadFiles: PropTypes.func.isRequired,
};

ImageInsert.defaultProps = {
  className: '',
  editState: null,
  file: null,
  files: [],
  isTipVisible: true,
  multiple: false,
  onChange: () => {},
  supportedFormats: '*',
};

export default ImageInsert;
