import React from 'react';
import Action from 'components/buttons/action';
import PropTypes from 'prop-types';
import './ccBccDisplay.scss';
import { FormattedMessage } from 'react-intl';
import { KeyCodes } from 'web/libs/constants';
import { BackgroundColors, TextWeights } from 'libs/constantsStyles';

class CcBccDisplay extends React.Component {
  handleCcKeyDown = (e) => {
    if (e.keyCode === KeyCodes.enter) this.props.setCcShowing(true);
  };

  handleCcOnClick = () => {
    this.props.setCcShowing(true);
  };

  handleBccKeyDown = (e) => {
    if (e.keyCode === KeyCodes.enter) this.props.setBccShowing(true);
  };

  handleBccOnClick = () => {
    this.props.setBccShowing(true);
  };

  handleSingleButtonKeyDown = (e) => {
    if (e.keyCode === KeyCodes.enter) this._singleButtonBehavior();
  };

  handleSingleButtonClick = () => {
    this._singleButtonBehavior();
  };

  setRef = (parent) => (this.parent = parent);

  renderSeparateButtons() {
    const { isBccShowing, isCcShowing } = this.props;

    return (
      <React.Fragment>
        <Action
          tabIndex="0"
          onKeyDown={this.handleCcKeyDown}
          className="cc-display"
          onClick={this.handleCcOnClick}
          disabled={isCcShowing}
          size={TextWeights.medium}
          color={BackgroundColors.white}
        >
          <FormattedMessage id="common.ccCapitalized" />
        </Action>
        <Action
          tabIndex="0"
          onKeyDown={this.handleBccKeyDown}
          className="bcc-display"
          onClick={this.handleBccOnClick}
          disabled={isBccShowing}
          size={TextWeights.medium}
          color={BackgroundColors.white}
        >
          <FormattedMessage id="common.bccCapitalized" />
        </Action>
      </React.Fragment>
    );
  }

  renderSingleButton() {
    const { ccCount, bccCount } = this.props;

    return (
      <Action
        tabIndex="0"
        onKeyDown={this.handleSingleButtonKeyDown}
        className="ccbcc-display"
        onClick={this.handleSingleButtonClick}
        color={BackgroundColors.white}
        size={TextWeights.medium}
      >
        <FormattedMessage
          id="web.emailComposeWindow.ccBccMoreEntries"
          values={{ total: ccCount + bccCount, ccCount, bccCount }}
        />
      </Action>
    );
  }

  _singleButtonBehavior() {
    const { setCcShowing, setBccShowing, ccCount, bccCount } = this.props;

    if (ccCount) {
      setCcShowing(true);
    }
    if (bccCount) {
      setBccShowing(true);
    }
  }

  render() {
    const { isBccShowing, isCcShowing, ccCount, bccCount } = this.props;
    const separateCcBccButtons =
      isCcShowing || isBccShowing || ccCount + bccCount === 0;

    return (
      <div ref={this._setRef} className="ccBcc-wrapper">
        {separateCcBccButtons
          ? this.renderSeparateButtons()
          : this.renderSingleButton()}
      </div>
    );
  }
}

CcBccDisplay.propTypes = {
  ccCount: PropTypes.number,
  bccCount: PropTypes.number,
  isCcShowing: PropTypes.bool.isRequired,
  isBccShowing: PropTypes.bool.isRequired,
  setCcShowing: PropTypes.func.isRequired,
  setBccShowing: PropTypes.func.isRequired,
};

CcBccDisplay.defaultProps = {
  ccCount: 0,
  bccCount: 0,
};

export default CcBccDisplay;
