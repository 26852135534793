/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
export const PopupWindowName = 'toutManage';
export const DontBugMe = 'dont-bug-me';

export const Regex = {
  time: /^(?:(([0-9]|0[0-9]|1[0-2]):([0-5][0-9])\s?(A|AM|P|PM)?|(1[3-9]|2[0-3]):([0-5][0-9])))$/i,
  toutappOrigin: /(\/|\.)toutapp\.com$/,
};

export const TaskType = {
  email: 'email',
  call: 'call',
  inMail: 'inmail',
  other: 'other',
};

export const TaskPriority = {
  normal: 'normal',
  high: 'high',
  low: 'low',
};

export const TaskCategory = {
  completed: 'Completed',
  empty: 'Empty',
  overdue: 'Overdue',
  uncompleted: 'Uncompleted',
};

export const TaskKeyEvent = {
  campaignSuccess: 'campaignSuccess',
  destroy: 'destroy',
  edit: 'edit',
  skip: 'skip',
  taskComplete: 'taskComplete',
  taskUncomplete: 'taskUncomplete',
};

export const Selects = {
  all: 'All',
  favorites: 'Favorites',
  general: 'General',
  none: 'None',
  recommended: 'Recommended',
  recent: 'Recent',
};

/* going off of file name's extension */
export const AttachmentTypes = {
  doc: 'doc',
  docx: 'docx',
  gif: 'gif',
  jpg: 'jpg',
  pdf: 'pdf',
  png: 'png',
  ppt: 'ppt',
  pptx: 'pptx',
  xls: 'xls',
  xlsx: 'xlsx',
};

export const AttachmentViews = {
  empty: 'empty',
  files: 'files',
  uploading: 'uploading',
};

export const ButtonStatus = {
  disabled: 'disabled',
  enabled: 'enabled',
  hidden: 'hidden',
  inactive: 'inactive',
};

export const Appears = {
  above: 'above',
  below: 'below',
  left: 'left',
  right: 'right',
};

export const Aligns = {
  center: 'center',
  left: 'left',
  right: 'right',
};

export const ContentSorts = {
  date: 'date',
  name: 'name',
};

export const TemplateSorts = {
  click: 'click',
  sent: 'sent',
  name: 'name',
  reply: 'reply',
  view: 'view',
  campaign: 'campaign',
};

export const SortDirections = {
  asc: 'asc',
  desc: 'desc',
};

export const SortTypes = {
  date: 'date',
  number: 'number',
  text: 'text',
};

export const ComposePopupStates = {
  calendar: 'calendar',
  content: 'content',
  empty: 'empty',
  schedule: 'schedule',
  tasks: 'tasks',
  templates: 'templates',
};

export const ProgressStatus = {
  uploading: 'uploading',
  issue: 'issue',
  finished: 'finished',
  error: 'error',
};

export const ScheduleIds = {
  now: 'now',
  oneHour: 'oneHour',
  threeHours: 'threeHours',
  oneDay: 'oneDay',
  customize: 'customize',
};

export const ButtonIds = {
  actions: 'actions',
  addToCampaign: 'addToCampaign',
  addToSalesforce: 'addToSalesforce',
  actionCall: 'call',
  actionEmail: 'email',
  actionInMail: 'inMail',
  actionOther: 'other',
  clientDisabled: 'clientDisabled',
  composeCalendar: 'composeCalendar',
  composeContent: 'composeContent',
  composeSaveAsTemplate: 'composeSaveAsTemplate',
  composeSchedule: 'composeSchedule',
  composeTasks: 'composeTasks',
  composeTemplates: 'composeTemplates',
  connectToSalesforce: 'connectToSalesforce',
  contentUpgrade: 'contentUpgrade',
  convoSaveAsTemplate: 'convoSaveAsTemplate',
  engConnect: 'engConnect',
  getHelp: 'Get Help',
  goToMarketoUsers: 'Go to Marketo Sales Users',
  knowledgeBase: 'knowledgeBase',
  linkAnalytics: 'linkAnalytics',
  linkCall: 'linkCall',
  linkConversations: 'conversationsLink',
  linkGong: 'linkGong',
  linkInMail: 'linkInMail',
  linkLiveFeed: 'linkLiveFeed',
  linkTemplates: 'linkTemplates',
  linkToutApp: 'linkToutApp',
  logout: 'logout',
  options: 'options',
  reauthorize: 'reauthorize,',
  task: 'task',
  tasks: 'tasks',
  nuxCreateTemplate: 'nuxCreateTemplate',
  toutHelp: 'toutHelp',
  signup: 'toutSignup',
  skip: 'skip',
  login: 'toutLogin',
  forgotPassword: 'toutForgotPassword',
  notification: 'toutNotification', // Probably not a real button, but used as a key for opening the notification popup
  notificationCloseEmail: 'toutNotificationCloseEmail',
  confirmationPopup: 'toutConfirmationPopup',
  billing: 'toutBilling',
  payment: 'toutPayment',
  findVerification: 'toutVerification',
  gmail: 'openGmail',
  attachmentsDelete: 'attachmentsDelete',
};

export const BannerState = {
  hidden: 'HIDDEN',
  welcome: 'WELCOME',
  authorize: 'AUTHORIZE_TOUTAPP',
  nux: 'NUX',
};

export const PusherState = {
  loaded: 'LOADED',
  subscribed: 'SUBSCRIBED',
  uninitialized: 'UNINITIALIZED',
};

export const LiveFeedEvents = {
  action: 'action',
  autoReply: 'auto_reply',
  click: 'click',
  open: 'open',
  reply: 'reply',
  visit: 'visit',
};

export const ChromeMode = {
  gmail: 'gmail',
  niko: 'niko',
};

export const OwaMode = {
  calendarPopup: 'calendarPopup',
  compose: 'compose',
  read: 'read',
  saveAsTemplate: 'saveAsTemplate',
  addToCompaign: 'addToCompaign',
};

export const ConfirmationPopups = {
  attachmentsDelete: {
    id: ButtonIds.confirmationPopup,
    messageKeys: {
      title: 'attachments.confirm.delete.title',
      body: 'attachments.confirm.delete.body',
      button: 'common.yes',
    },
    confirmButtonId: ButtonIds.attachmentsDelete,
  },
};

export const OwaComposeMode = {
  blank: 'blank',
  calendar: 'calendar',
  campaigns: 'campaigns',
  content: 'content',
  schedule: 'schedule',
  task: 'task',
  templates: 'templates',
};

export const OwaReadMode = {
  actions: 'actions',
  liveFeed: 'liveFeed',
  logout: 'logout',
  task: 'task',
  tasks: 'tasks',
  toutapp: 'toutapp',
};

export const WindowEventNames = {
  close: 'msc-window-closed',
  deleteTask: 'msc-window-success-delete-task',
  saveTask: 'msc-window-success-save-task',
  addToCampaign: 'msc-window-success-add-to-campaign',
  sendEmail: 'msc-window-success-send-email',
};

export const WindowListenerNames = {
  login: 'loginListener',
};

export const WindowMessageActions = {
  login: 'login',
};

export const ScheduleLaunchWhen = {
  scheduled: 1,
  now: 2,
  different: 3,
};

export const PopupSize = {
  small: 500,
  large: 650,
  big: 1000,
  massive: 1200,
};

export const BlockedReason = {
  alreadyActive: 'ALREADY_ACTIVE',
  maxCampaignLimitReached: 'MAX_CAMPAIGN_LIMIT_REACHED',
  blockUnsubscribed: 'BLOCK_UNSUBSCRIBED',
  unsubscribed: 'UNSUBSCRIBED',
  marketoUnsubscribed: 'MARKETO_UNSUBSCRIBED',
  domainBlocked: 'DOMAIN_BLOCKED',
  bothUnsubscribed: 'MARKETO_AND_SALES_UNSUBSCRIBED',
  invalidEmails: 'INVALID_EMAILS',
  missingEmails: 'MISSING_EMAILS',
  notMarketoLeads: 'NOT_MARKETO_LEADS',
};

export const PreviewViewTypes = {
  person: 'person',
  failedImports: 'importFailures',
};

export const reactPortalDatePickerException = 'SingleDatePicker__picker';

export const SourceOpenerIds = {
  analytics: 'Analytics Tab',
  campaigns: 'Campaigns Tab',
  commandCenter: 'Command Center',
  gmail: 'Gmail',
  liveFeed: 'Live Feed',
  outlook: 'Outlook',
  owa: 'OWA',
  peopleTab: 'People Tab',
  salesBeat: 'Sales Beat Tab',
  salesforce: 'Salesforce',
  sentFolder: 'Sent Folder',
  templates: 'Templates Tab',
};

export const Colors = {
  blue: 'blue',
  green: 'green',
  red: 'red',
  white: 'white',
};

export const CampaignTypes = {
  marketo: 'CAMPAIGN_TYPES_MARKETO',
  tout: 'CAMPAIGN_TYPES_TOUT',
};

export const SourceOpener = {
  liveFeed: 'liveFeed',
  salesforce: 'salesforce',
};

export const DefaultTemplateCard = {
  name: 'Contact Info',
  subject: 'Here is my Contact Information',
};

export const DYNAMIC_FIELDS_SPECIAL_CATEGORY = {
  basicInformation: 'Basic Information',
  dateInformation: 'Date Information',
  otherContactInformation: 'Other Contact Information',
  senderInformation: 'Sender Information',
  systemFields: 'System Fields',
  workContactInformation: 'Work Contact Information',
};

export const DYNAMIC_FIELDS_CATEGORY = {
  all: 'All',
  basicInformation: 'Basic Information',
  customFields: 'Custom Fields',
  dateInformation: 'Date Information',
  otherContactInformation: 'Other Contact Information',
  salesforceContact: 'Salesforce Contact',
  salesforceContactsAccount: "Salesforce Contact's Account",
  salesforceContactsOpportunity: "Salesforce Contact's Opportunity",
  salesforceLead: 'Salesforce Lead',
  senderInformation: 'Sender Information',
  systemFields: 'System Fields',
  workContactInformation: 'Work Contact Information',
};

export const FileFormats = {
  excel: 'xlsx',
  csv: 'csv',
};

export const INVALID_DATE = 'Invalid date';
export const DEFAULT_SCHEDULE_TIME = '09:30';

export const SubscriptionPlans = {
  enterprise: 'enterprise',
  standard: 'standard',
  premium: 'premium',
};
