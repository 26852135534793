/*global __DEV__*/

/* Production settings */
let backendUrl = 'https://toutapp.com';
let pusherKey = '3479fa99c49db0822997';
let pusherEnvironment = 'production';
let mixpanelKey = 'bddeb22e7f9c4aa15e14cb950b06ecea';

/* istanbul ignore else */
if (__DEV__) {
  const environment = require('./environment'); // eslint-disable-line global-require
  backendUrl = environment.BaseUrl;
  pusherEnvironment = environment.PusherEnvironment;
  mixpanelKey = '82f22312605b93e535fd8e6fe2d6f55a';
  pusherKey = '3b8099fe8829227fabc2';
}

export const BaseUrl = backendUrl;
export const PusherEnvironment = pusherEnvironment;
export const PusherKey = pusherKey;
export const MixpanelKey = mixpanelKey;
export const ExtensionType = 'Chrome';

export const Urls = {
  addToCampaign: `${BaseUrl}/components/add_to_campaign?mode=iframe&sourceOpener=gmail`,
  addToCampaignWindow: `${BaseUrl}/components/add_to_campaign?sourceOpener=gmail`,
  analyticsPage: `${BaseUrl}/next#analytics`,
  attachments: `${BaseUrl}/attachments`,
  attachmentsCollection: `${BaseUrl}/file_attachments/collection`,
  authenticate: `${BaseUrl}/user_session.json`,
  billing: `${BaseUrl}/billing/edit`,
  blank: 'about:blank',
  calendar: `${BaseUrl}/calendar`,
  callBase: `${BaseUrl}/modal#modal/call`,
  campaignDetails: `${BaseUrl}/workflows/for_emails`,
  campaigns: `${BaseUrl}/workflows.json`,
  campaignsStop: `${BaseUrl}/workflows/stop`,
  connectToSalesforcePage: `${BaseUrl}/next#settings/crm/salesforce/about`,
  conversation: `${BaseUrl}/google/thread/:#id`, // can't use ${} due to dynamically defined
  conversationsPage: `${BaseUrl}/next#command_center/emails`,
  draftId: (id) => `${BaseUrl}/google/draft/${id}`,
  eventsCounts: `${BaseUrl}/events/count.json`,
  fetch: `${BaseUrl}/fetch.json`,
  fileUpload: `${BaseUrl}/attachments.json`,
  gmail: 'https://mail.google.com',
  gmailHelpPage:
    'https://docs.marketo.com/display/public/DOCS/Marketo+Sales+Engage',
  gmailSearch: '#search/:#email',
  gmailVerificationSearch:
    '#advanced-search/from=team%40toutapp.com&subject=verify&subset=ast',
  gongPage: `${BaseUrl}/components/gong?source_opener=gmail`,
  googleAuth: `${BaseUrl}/auth/google_oauth2?gmail_login=true&utm_source=Gmail%20Toolbar&utm_medium=Chrome%20Extension&utm_campaign=Chrome`,
  inmailBase: 'https://www.linkedin.com/search/results/index/?keywords=',
  liveFeedPage: `${BaseUrl}/live`,
  loginUrl: `${BaseUrl}/login`,
  newSession: `${BaseUrl}/user_session/new.json?gmail=true`,
  passwordReset: `${BaseUrl}/password_reset`,
  payment: `${BaseUrl}/payment/edit`,
  peopleLookup: `${BaseUrl}/people/lookup.json`,
  personDetailsBase: `${BaseUrl}/next#people/groups/everyone`,
  personHistory: (personId) => `${BaseUrl}/next#people/${personId}/history`,
  pusherAuth: `${BaseUrl}/pusher/auth`,
  reliquify: `${BaseUrl}/pitch_templates/reliquify/:#id`,
  salesforceCheck: `${BaseUrl}/salesforce/check`,
  salesforceLog: `${BaseUrl}/salesforce/log`,
  tags: `${BaseUrl}/tags/of_tag`,
  tasks: `${BaseUrl}/reminders`,
  tasksCompleted: `${BaseUrl}/reminders/completed.json`,
  tasksUncompleted: `${BaseUrl}/reminders/uncompleted.json`,
  tasksUpdate: `${BaseUrl}/reminders/:#id`, // can't use ${} due to dynamically defined
  templateCategories: `${BaseUrl}/template_categories`,
  templates: `${BaseUrl}/pitch_templates`,
  templatesId: `${BaseUrl}/pitch_templates/:#id`, // can't use ${} due to dynamically defined
  templatesPage: `${BaseUrl}/next#templates`,
  templatesRecommended: `${BaseUrl}/pitch_templates/recommended.json`,
  timezones: `${BaseUrl}/timezones.json`,
  toutIt: `${BaseUrl}/google/stage_api.json`,
  toutItServer: `${BaseUrl}/google/gmail`,
  unsubscribesCheck: `${BaseUrl}/unsubscribes/check`,
  upsell: `${BaseUrl}/google/upsell`,
  userAccount: `${BaseUrl}/users/:#id.json`,
  userMetric: `${BaseUrl}/user_metric.json`,
  userMetricDone: `${BaseUrl}/user_metric/done.json`,
};

export const CheckDraftValidityTimeout = 8e3;

export const UrlMatching = {
  gmail: 'mail.google.com',
};

export const Regex = {
  dynamicFields: /{{\w*}}/g,
  dynamicFieldPrompts: /{{!\s*\S.*}}/g,
  email: /([+a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g,
  googleProxyStrip: /#(https?:\/\/.*?\/[a-z0-9]+)$/,
  mismatchedDynamicFields: /{{\w*|\w*}}/g,
  nbsp: /(&nbsp;|&nbsp)$/,
  salesforceBcc: /emailtosalesforce@/,
};

export const Linkified = {
  custom: 'custom_content',
  tracker: 'view_tracker',
};

export const Subscriptions = {
  appSumo: 'appsumo',
  enterprise: 'enterprise',
  free: 'free',
  g1Lite: 'g1lite',
  g1MidSize: 'g1midsize',
  g1SmallTeam: 'g1smallteam',
  g1Solo: 'g1solo',
  g1StartUp: 'g1startup',
  g3SmallTeam: 'g3smallteam',
  g3Solo: 'g3solo',
  g3StartUp: 'g3startup',
  g4SmallTeam: 'g4smallteam',
  grandfatheredPremium: 'grandfatheredpremium',
  largeTeam: 'largeteam',
  medTeam: 'medteam',
  mobile: 'mobile',
  smallTeam: 'smallteam',
  solo: 'solo',
  starter: 'starter',
  vip: 'vip',
};

export const LiveFeedEventTypes = [
  'click',
  'reply',
  'action',
  'open',
  'visit',
  'auto_reply',
];

export const TokenId = 'nex43t';
export const TokenSeed = 'toutnes43tit';

export const RecentlyUsed = {
  campaigns: 'campaigns',
  templates: 'templates',
};

export const GmailMode = {
  contacts: 'contacts',
  preview: 'preview',
  standard: 'standard',
};

export const PrepopulateDataState = {
  firstEmail: 'FIRST_EMAIL',
  task: 'TASK',
};

export const ErrorPopups = {
  attachmentsLoadError: {
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'attachments.errors.unexpected.title',
    },
    showHelp: true,
  },
  attachmentuploadError: {
    messageKeys: {
      body: 'gmail.upload.attachment.error',
      button: 'common.close',
      title: 'attachments.errors.unexpected.title',
    },
    showHelp: false,
  },
  campaignAlreadyOnOne: {
    messageKeys: {
      body: 'campaigns.errors.alreadyOnOne.body',
      button: 'common.close',
      title: 'campaigns.errors.alreadyOnOne.title',
    },
  },
  campaignMarkSuccess: {
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'campaigns.errors.markSuccess.title',
    },
    showHelp: true,
  },
  campaignRemove: {
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'campaigns.errors.remove.title',
    },
    showHelp: true,
  },
  missingDynamicField: 'missingDynamicField',
  salesforceIssue: {
    // id: ButtonIds.notification,
    messageKeys: {
      body: 'salesforce.errors.log.body',
      button: 'common.close',
      title: 'salesforce.errors.log.title',
    },
    showHelp: false,
  },
  taskDeleteError: {
    messageKeys: {
      body: 'tasks.errors.delete.body',
      button: 'common.close',
      title: 'tasks.errors.delete.title',
    },
    showHelp: true,
  },
  taskUpdateError: {
    messageKeys: {
      body: 'tasks.errors.update.body',
      button: 'common.close',
      title: 'tasks.errors.update.title',
    },
    showHelp: true,
  },
  templateInfoError: {
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'templates.errors.templateInfo.title',
    },
  },
  templateLoadError: {
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'templates.errors.unexpected.title',
    },
    showHelp: true,
  },
  templateSaveAsInfoError: {
    messageKeys: {
      body: 'templates.errors.saveAsInfo.body',
      button: 'common.close',
      title: 'templates.errors.saveAsInfo.title',
    },
    showHelp: false,
  },
  templateSaveError: {
    messageKeys: {
      body: 'templates.errors.save.body',
      button: 'common.close',
      title: 'templates.errors.save.title',
    },
    showHelp: true,
  },
  unfilledPromptFields: 'unfilledPromptFields',
  unsubscribesCheck: 'unsubscribesCheck',
};

export const ARCHIVED = 'Archived';

export const COLUMN_PITCHES_SENT = 'pitches_sent';

export const ALL_PERSON_QUERY_FIELDS = [
  'first_name',
  'last_name',
  'addresses',
  'email_addresses',
  'title',
  'company',
];

export const PEOPLE_SEARCH_RESULT_FIELDS = 'people_search_result_fields';

export const MAX_AUTOCOMPLETE_RESULTS = 3;

export const ElasticSearchTypes = {
  people: 'person',
};
