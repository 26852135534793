import TrackingActionTypes from 'web/tracking/libs/trackingActionTypes';
import { combineReducers } from 'redux';

export const fetching = (state = false, { type, fetching }) => {
  switch (type) {
    case TrackingActionTypes.setCustomDomainFetching:
      return fetching;
    default:
      return state;
  }
};

export default combineReducers({ fetching });
