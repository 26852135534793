import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import './formTitle.scss';

const FormTitle = ({ id, className, subTitleId, subTitleClasses }) => (
  <div className="form-title">
    <h3 className={classNames('form-main-title', className)}>
      <FormattedMessage id={id} />
    </h3>

    {subTitleId && (
      <div className={classNames('form-sub-title', subTitleClasses)}>
        <FormattedHTMLMessage id={subTitleId} />
      </div>
    )}
  </div>
);

FormTitle.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  subTitleClasses: PropTypes.string,
  subTitleId: PropTypes.string,
};

FormTitle.defaultProps = {
  className: '',
  id: '',
  subTitleClasses: '',
  subTitleId: '',
};

export default FormTitle;
