import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import OnboardingActionButtons from 'components/popups/popupFooter/popupFooter';
import { navigateToUrl } from 'web/services/routerService';
import { steps } from 'web/onboarding/lib/stepsRouting';
import onboardingPopupIds from 'web/onboarding/lib/onboardingPopupIds';
import OnboardingStepTitle from 'web/onboarding/components/onboardingStepTitle/onboardingStepTitle';
import { initializeHealthStatuses } from 'web/settings/myProfile/components/yourIntegrations/actionCreators/yourIntegrationsActionCreators';
import {
  getIntegrationsHealthStatuses,
  getIntegrationsHealthStatusesFetching,
} from 'web/settings/myProfile/components/yourIntegrations/selectors/yourIntegrationsSelectors';
import { isOnboardingStepPassed } from 'web/user/selectors/userSelectors';
import { stepsNames } from 'web/onboarding/lib/stepsRouting';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import CheckIcon from 'components/icons/checkIcon';
import classNames from 'classnames';
import './onboardingWelcome.scss';

const OnboardingWelcome = ({
  openPopup,
  statuses,
  fetching,
  isEmailStepPassed,
  isSignatureStepPassed,
  isStepCRMPassed,
}) => {
  useEffect(() => {
    localStorage.removeItem('onboardingEmailAuthSuccess');
    localStorage.removeItem('onboardingCrmAuthSuccess');
  });

  const isConnectedToCRM =
    statuses &&
    statuses.isSalesforceSetUpForCurrentAccount &&
    !statuses.isSalesforceBrokenForCurrentAccount;

  const connectToCRMTextKeyId = 'web.onboarding.welcomePage.connectToCRM';

  const mainTextKeyId = isConnectedToCRM
    ? 'web.onboarding.welcomePage.mainTextSFConnected'
    : 'web.onboarding.welcomePage.mainText';

  const welcomePageTitleKeyId = isConnectedToCRM
    ? 'web.onboarding.welcomePage.titleSFConnectedDefault'
    : 'web.onboarding.welcomePage.title';

  const nextStepUrl = steps[0].url;

  return (
    <div className={classNames('welcome-wrapper', { 'empty-card': fetching })}>
      {fetching && <LoadingSpinner imageUrl={getImageUrl} />}
      {!fetching && (
        <React.Fragment>
          <OnboardingStepTitle titleId={welcomePageTitleKeyId} />
          <p className="welcome-main">
            <FormattedHTMLMessage id={mainTextKeyId} />
          </p>
          <p>
            <FormattedMessage id={'web.onboarding.welcomePage.secondaryText'} />
          </p>
          <div className="welcome-list-item">
            <CheckIcon
              className={classNames('welcome-list-item-icon', {
                complete: isStepCRMPassed,
              })}
            />
            <span>
              <FormattedHTMLMessage id={connectToCRMTextKeyId} />
            </span>
          </div>
          <div className="welcome-list-item">
            <CheckIcon
              className={classNames('welcome-list-item-icon', {
                complete: isEmailStepPassed,
              })}
            />
            <span>
              <FormattedHTMLMessage
                id={'web.onboarding.welcomePage.setupEmailConnection'}
              />
            </span>
          </div>
          <div className="welcome-list-item">
            <CheckIcon
              className={classNames('welcome-list-item-icon', {
                complete: isSignatureStepPassed,
              })}
            />
            <span>
              <FormattedHTMLMessage
                id={'web.onboarding.welcomePage.customizeEmailSignature'}
              />
            </span>
          </div>
          <OnboardingActionButtons
            onPrimary={() => navigateToUrl(nextStepUrl)}
            onSecondary={() => {
              openPopup(onboardingPopupIds.skipSetup);
            }}
            primaryTextId={'common.start'}
            secondaryTextId={'web.onboarding.actionButtons.notNow'}
            full
          />
        </React.Fragment>
      )}
    </div>
  );
};

OnboardingWelcome.propTypes = {
  fetching: PropTypes.bool,
  initializeHealthStatuses: PropTypes.func.isRequired,
  isEmailStepPassed: PropTypes.bool,
  isSignatureStepPassed: PropTypes.bool,
  isStepCRMPassed: PropTypes.bool,
  openPopup: PropTypes.func.isRequired,
  statuses: PropTypes.object,
};

OnboardingWelcome.defaultProps = {
  fetching: false,
  isEmailStepPassed: undefined,
  isSignatureStepPassed: undefined,
  isStepCRMPassed: undefined,
  statuses: {},
};

const mapStateToProps = (state) => ({
  fetching: getIntegrationsHealthStatusesFetching(state),
  isEmailStepPassed: isOnboardingStepPassed(state, stepsNames.emailConnection),
  isSignatureStepPassed: isOnboardingStepPassed(
    state,
    stepsNames.emailSignature
  ),
  isStepCRMPassed: isOnboardingStepPassed(state, stepsNames.connectCRM),
  statuses: getIntegrationsHealthStatuses(state),
});

const mapDispatchToProps = {
  initializeHealthStatuses,
  openPopup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingWelcome);
