/*eslint no-param-reassign: ["error", { "props": false }]*/

/*global Reflect*/
import ActionTypes from 'table/modules/actionTypes';

export const lastCheckedId = Symbol('last-checked-id');
/* Used for getting ids */
const initItems = [];
const initSelectedIds = {
  [lastCheckedId]: null,
};

export function selectableItems(
  state = initItems,
  { type = '', items = null }
) {
  switch (type) {
    case ActionTypes.tableSetItems:
      return items || state;
    default:
      return state;
  }
}

const addItem = (state, item) => {
  const { id } = item;
  state[id] = Object.assign({}, item);
};

const deleteItem = (state, { id }) => {
  Reflect.deleteProperty(state, id);
};

const setSelectedItems = (state, checked, items) => {
  const temp = { ...state };
  const func = checked ? addItem : deleteItem;

  items.filter((item) => item.person_id !== null).forEach((item) => {
    if (item.id) {
      temp[lastCheckedId] = item.id;
    }
    func(temp, item);
  });
  return temp;
};

const rangeSelection = (state, checked, item, items) => {
  const newState = { ...state };
  const toggleItem = checked ? addItem : deleteItem;
  const itemsIds = items.map(({ id }) => id);

  if (item && item.id) {
    if (!newState[lastCheckedId]) {
      newState[lastCheckedId] = item.id;
      toggleItem(newState, item);
    } else {
      const from = itemsIds.indexOf(newState[lastCheckedId]);
      const to = itemsIds.indexOf(item.id);

      const indexFrom = from < to ? from : to;
      const indexTo = from < to ? to + 1 : from + 1;

      items
        .slice(indexFrom, indexTo)
        .filter(({ isSelectedDisabled }) => !isSelectedDisabled)
        .forEach((item) => toggleItem(newState, item));

      newState[lastCheckedId] = item.id;
    }
  }

  return newState;
};

export function selectableSelectedItems(
  state = initSelectedIds,
  { type = '', checked = false, item = '', items = [] }
) {
  switch (type) {
    case ActionTypes.selectableSetSelectedItem:
      return setSelectedItems(state, checked, [item]);
    case ActionTypes.selectableSelectAllItems:
      return setSelectedItems(state, checked, items);
    case ActionTypes.selectableSetOneSelectedItem:
      return setSelectedItems([], true, [item]);
    case ActionTypes.selectableClearSelectedItems:
      return initSelectedIds;
    case ActionTypes.selectableRangeSelection:
      return rangeSelection(state, checked, item, items);
    default:
      return state;
  }
}
