import React from 'react';
import PropTypes from 'prop-types';
import { ColorClasses } from 'libs/constantsStyles';

const InfoIcon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      height={height}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{`.a { fill: ${color} !important; }`}</style>
      </defs>
      <rect fill={color} height="18" id="Canvas" opacity="0" width="18" />
      <path
        className="a"
        d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM8.85,3.15a1.359,1.359,0,0,1,1.43109,1.28286q.00352.06452.00091.12914A1.332,1.332,0,0,1,8.85,5.9935a1.3525,1.3525,0,0,1-1.432-1.432A1.3585,1.3585,0,0,1,8.72033,3.14907Q8.78516,3.14643,8.85,3.15ZM11,13.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H8V9H7.5A.5.5,0,0,1,7,8.5v-1A.5.5,0,0,1,7.5,7h2a.5.5,0,0,1,.5.5V12h.5a.5.5,0,0,1,.5.5Z"
      />
    </svg>
  );
};

InfoIcon.defaultProps = {
  color: ColorClasses.white,
  height: 18,
  width: 18,
};

InfoIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default InfoIcon;
