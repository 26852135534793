import moment from 'moment';
import { AuthorizationTypes } from 'web/compliance/libs/complianceConstants';
import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import ComplianceActionTypes from 'web/compliance/libs/complianceActionTypes';

const defaultAuthorizationValues = {
  authorizationType: AuthorizationTypes.legitimateInterest,
  consentDate: moment().startOf('day'),
  consentNotesValue: '',
  consentPurposeValue: '',
  otherAuthorizationValue: '',
};

export const peopleAuthorization = (
  state = { ...defaultAuthorizationValues },
  { type, edits = {} }
) => {
  switch (type) {
    case PeopleActionTypes.peopleAuthorization.set:
      return { ...state, ...edits };
    case PeopleActionTypes.peopleAuthorization.reset:
      return { ...defaultAuthorizationValues };
    default:
      return state;
  }
};

export const authorizationMainModal = (state = '', { type, modal = '' }) => {
  switch (type) {
    case ComplianceActionTypes.modal.set:
      return modal;
    case ComplianceActionTypes.modal.clear:
      return '';
    default:
      return state;
  }
};
