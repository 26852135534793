import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import TextButton from 'components/buttons/text';
import Details from './details';
import Editing from './editing';
import { getUrlQueryStringParams } from 'web/services/windowService';

class DeliveryChannelCard extends Component {
  constructor(props) {
    super(props);

    const { editIdentityDeliveryChannelInstance } = props;

    this.state = {
      identityModel: { ...editIdentityDeliveryChannelInstance },
    };

    this.channelCardEl = null;
  }

  componentDidMount() {
    const cardQueryParam = 'deliverability';
    const queryParams = getUrlQueryStringParams();

    if (queryParams.scrollTo && queryParams.scrollTo === cardQueryParam) {
      this.channelCardEl.scrollIntoView();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.editIdentityDeliveryChannelInstance !==
        this.props.editIdentityDeliveryChannelInstance ||
      nextProps.deliveryChannels !== this.props.deliveryChannels
    ) {
      this.setState({
        identityModel: { ...nextProps.editIdentityDeliveryChannelInstance },
      });
    }
  }

  onSubmitIdentity = (submittedIdentity) => {
    const {
      actionCreators: { updateIdentity, setDeliveryChannelCardEditMode },
      identity,
    } = this.props;
    const newIdentity = {
      ...identity,
      authentication_id: submittedIdentity.authentication_id,
    };
    updateIdentity(newIdentity);
    setDeliveryChannelCardEditMode(false);
  };

  setRef = (el) => {
    this.channelCardEl = el;
  };

  render() {
    const {
      isEditing,
      shouldHideDeliveryChannels,
      actionCreators,
      onChangeEditing,
      deliveryChannels,
      composedDeliveryChannels,
      canChangeDefaultIdentityDeliveryChannel,
    } = this.props;

    const { identityModel } = this.state;

    return (
      <div
        className="edit-identity-card delivery-channel-card"
        ref={this.setRef}
      >
        {this.shouldShowEditButtonPanel() && (
          <div className="edit-button-panel">
            <TextButton onClick={onChangeEditing} underline>
              <FormattedMessage id="common.edit" />
            </TextButton>
          </div>
        )}
        {isEditing ? (
          <Editing
            composedDeliveryChannels={composedDeliveryChannels}
            initialValues={identityModel}
            onSubmit={this.onSubmitIdentity}
            onCancel={onChangeEditing}
            canChangeDeliveryChannel={
              canChangeDefaultIdentityDeliveryChannel &&
              identityModel.default_identity
            }
            actionCreators={actionCreators}
            enableReinitialize
          />
        ) : (
          <Details
            actionCreators={actionCreators}
            identityModel={identityModel}
            deliveryChannels={deliveryChannels}
            shouldHideDeliveryChannels={shouldHideDeliveryChannels}
          />
        )}
      </div>
    );
  }

  shouldShowEditButtonPanel = () => {
    const {
      isEditing,
      shouldHideDeliveryChannels,
      deliveryChannels,
    } = this.props;

    return (
      !isEditing &&
      (!shouldHideDeliveryChannels ||
        (shouldHideDeliveryChannels && !!deliveryChannels.length))
    );
  };
}

DeliveryChannelCard.propTypes = {
  intl: intlShape.isRequired,
  shouldHideDeliveryChannels: PropTypes.bool.isRequired,
  canChangeDefaultIdentityDeliveryChannel: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onChangeEditing: PropTypes.func.isRequired,
  identity: PropTypes.object,
  editIdentityDeliveryChannelInstance: PropTypes.object,
  composedDeliveryChannels: PropTypes.array.isRequired,
  deliveryChannels: PropTypes.array.isRequired,
  actionCreators: PropTypes.object.isRequired,
};

export default injectIntl(DeliveryChannelCard);
