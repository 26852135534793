import { createReducer } from '@reduxjs/toolkit';
import {
  changeAdvancedSearchSavedName,
  addSavedEmailAdvancedSearch,
  initEmailAdvancedSearchSave,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/saveAdvancedSearchActionCreators';
import { setCurrentSavedAdvancedSearchFilters } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/saveAdvancedSearchActionCreators';
import {
  setEmailSelectedSavedAdvancedSearchId,
  resetEmailSelectedSavedAdvancedSearchId,
  markEmailSelectedSavedAdvancedSearchAsChanged,
  markEmailSelectedSavedAdvancedSearchAsNotChanged,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/openAdvancedSearchActionCreators';
import { deleteSavedEmailAdvancedSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/deleteAdvancedSearchActionCreators';
import { renameSavedEmailAdvancedSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/renameAdvancedSearchActionCreators';
import EmailGroupsActionType from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGroupsActionType';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import {
  setSelectedEmailId,
  resetSelectedEmailId,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/emailGridAdvancedSearchActionCreators';

export const advancedSearchSavedName = createReducer('', {
  [changeAdvancedSearchSavedName.type]: (state, action) => action.payload.name,
});

export const emailSavedAdvancedSearchesIsInit = createReducer(false, {
  [initEmailAdvancedSearchSave.type]: () => true,
});

export const emailSelectedSavedAdvancedSearchId = createReducer(null, {
  [setEmailSelectedSavedAdvancedSearchId.type]: (state, action) =>
    action.payload.id,
  [resetEmailSelectedSavedAdvancedSearchId.type]: () => null,
});

/* eslint-disable no-param-reassign */
export const emailSavedAdvancedSearches = createReducer(
  {},
  {
    [addSavedEmailAdvancedSearch.type]: (state, action) => {
      const savedSearchesAsArray = Array.isArray(action.payload.search)
        ? action.payload.search
        : [action.payload.search];

      savedSearchesAsArray.map(
        (savedSearch) => (state[savedSearch.id] = savedSearch)
      );
    },
    [deleteSavedEmailAdvancedSearch.type]: (state, action) => {
      const { savedSearchId } = action.payload;
      delete state[savedSearchId];
    },
    [renameSavedEmailAdvancedSearch.type]: (state, action) => {
      const { name, savedSearchId } = action.payload;
      state[savedSearchId].name = name;
    },
  }
);

export const searchGroupEmailId = (state = null, action) => {
  const { type, payload } = action;

  return type === EmailGroupsActionType.singleRowEmails.setCurrentEmailsGroupId
    ? payload
    : state;
};

export const isEmailSelectedSavedAdvancedSearchChanged = createReducer(false, {
  [markEmailSelectedSavedAdvancedSearchAsChanged.type]: () => true,
  [markEmailSelectedSavedAdvancedSearchAsNotChanged.type]: () => false,
  [PopupActionTypes.close]: () => false,
  [resetEmailSelectedSavedAdvancedSearchId.type]: () => false,
});

export const currentSavedAdvancedSerchFilters = createReducer(null, {
  [setCurrentSavedAdvancedSearchFilters.type]: (state, action) =>
    action.payload.filters,
});

export const emailGridSelectedEmailId = createReducer(null, {
  [setSelectedEmailId.type]: (state, action) => action.payload,
  [resetSelectedEmailId.type]: () => null,
});
