import ActionTypes from 'table/modules/actionTypes';

export const setColumnOrder = (columnsIdList) => (dispatch) => {
  dispatch({
    columnsIdList,
    type: ActionTypes.columnOrderSetOrder,
  });
};

export const changeColumnOrder = (movingColumnId, beforeColumnId) => (
  dispatch,
  getState
) => {
  const { columnOrder } = getState();
  if (columnOrder.length > 0) {
    if (movingColumnId != beforeColumnId) {
      let newColumnOrder = [...columnOrder];

      const movingColumnIndex = newColumnOrder.indexOf(movingColumnId);
      newColumnOrder.splice(movingColumnIndex, 1);

      let insertIndex = newColumnOrder.length;
      if (beforeColumnId) {
        const beforeColumnIndex = newColumnOrder.indexOf(beforeColumnId);
        insertIndex = beforeColumnIndex > 0 ? beforeColumnIndex : 0;
      }

      newColumnOrder.splice(insertIndex, 0, movingColumnId);
      dispatch(setColumnOrder(newColumnOrder));
    }
  }
};
