import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Button, { ButtonColors } from 'components/buttons/action';
import EmailComposeInfoBox from 'web/emailComposeBody/components/emailComposeInfoBox';
import './toolbar.scss';

class Toolbar extends Component {
  render() {
    const {
      actionCreators,
      className,
      content,
      contentUploads,
      disableSaveAsTemplate,
      hideInsertTemplate,
      hideSaveAsTemplate,
      infoBoxState,
      lastTemplateCategory,
      selectedTemplateId,
      templates,
      templatesFavorites,
    } = this.props;

    return (
      <div className={classNames('toolbar', className)}>
        <div className="toolbar-button-row">
          {!hideInsertTemplate && (
            <Button
              active={infoBoxState === 'template-chooser'}
              className="toolbar-button"
              color={ButtonColors.gray}
              data-info-type="template-chooser"
              onClick={this.handleToolbarClick}
            >
              <FormattedMessage id="web.emailComposeBody.toolbar.insertATemplate" />
            </Button>
          )}
          {!hideSaveAsTemplate && (
            <Button
              active={infoBoxState === 'template-save'}
              className="toolbar-button"
              color={ButtonColors.gray}
              data-info-type="template-save"
              disabled={disableSaveAsTemplate}
              onClick={this.handleSaveTemplate}
            >
              <FormattedMessage id="web.emailComposeBody.toolbar.saveAsTemplate" />
            </Button>
          )}
        </div>
        <EmailComposeInfoBox
          content={content}
          contentUploads={contentUploads}
          deleteContent={actionCreators.deleteContent}
          insertContent={actionCreators.insertContent}
          insertTemplate={this.handleInsertTemplate}
          insertTemplateHidden={hideInsertTemplate}
          lastTemplateCategory={lastTemplateCategory}
          outsideClickHandler={this.handleOutsideInfoBoxClick}
          selectedTemplateId={selectedTemplateId}
          templates={templates}
          templatesFavorites={templatesFavorites}
          type={infoBoxState}
          uploadFiles={this.uploadFiles}
        />
      </div>
    );
  }

  handleToolbarClick = (e) => {
    const { actionCreators, infoBoxState } = this.props;
    if (e.currentTarget.dataset.infoType === infoBoxState) {
      actionCreators.setInfoBoxState('');
    } else {
      actionCreators.setInfoBoxState(e.currentTarget.dataset.infoType);
    }
  };

  handleOutsideInfoBoxClick = (target) => {
    //todo hack fix to avoid handleToolbarClick from re-opening info box
    if (
      !target ||
      !target.parentNode ||
      target.parentNode.className.search('toolbar-button') === -1
    ) {
      this.props.actionCreators.setInfoBoxState('');
    }
  };

  handleInsertTemplate = (template) => {
    this.props.updateState({ template });
    this.props.actionCreators.insertTemplate(template);
  };

  handleSaveTemplate = () => {
    const { actionCreators } = this.props;
    actionCreators.openSaveAsTemplate();
  };

  handleAttachFilesClick = () => {
    this.refs.fileInput.click();
  };

  uploadFiles = (e) => {
    const { actionCreators } = this.props;
    e.stopPropagation();
    e.preventDefault();

    actionCreators.uploadFiles(e.target.files, e.target.id || 'content');
  };
}

Toolbar.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  className: PropTypes.string,
  content: PropTypes.array.isRequired,
  contentUploads: PropTypes.array.isRequired,
  disableSaveAsTemplate: PropTypes.bool,
  hideInsertTemplate: PropTypes.bool,
  hideSaveAsTemplate: PropTypes.bool,
  infoBoxState: PropTypes.string.isRequired,
  lastTemplateCategory: PropTypes.string,
  selectedTemplateId: PropTypes.number,
  templates: PropTypes.array.isRequired,
  templatesFavorites: PropTypes.array.isRequired,
  updateState: PropTypes.func.isRequired,
};

Toolbar.defaultProps = {
  className: '',
  disableSaveAsTemplate: false,
  hideInsertTemplate: false,
  hideSaveAsTemplate: false,
  selectedTemplateId: 0,
};

export default Toolbar;
