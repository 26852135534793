export const parseCategories = (categories = []) => {
  categories = categories.sort((a, b) => a.name.localeCompare(b.name));
  return categories.map(({ id, name }) => ({
    id,
    label: name,
    value: `${id}`,
  }));
};

export const parseTeams = (teams = []) =>
  teams.map(({ share_everyone, who: { id, name } }) => ({
    id,
    name,
    shareEveryone: share_everyone,
  }));
