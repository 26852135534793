import TasksActionTypes from '../libs/tasksActionTypes';
import { TASKS_UNCOMPLETED } from '../libs/tasksConstants';
import { getDatesForCalls } from '../services/tasksHelpers';
import { getTasksUncompleted as getTasksUncompletedCall } from '../services/tasksService';

/* should only use if you want to overwrite current stored uncompleted tasks range */
export function getTasksUncompleted(
  { start, end },
  isCustom = false,
  userId = null
) {
  return (dispatch) => {
    dispatch({
      id: TASKS_UNCOMPLETED,
      type: TasksActionTypes.fetching,
    });
    getTasksUncompletedCall(start, end, userId)
      .then((data) => {
        dispatch({
          data,
          end,
          isCustom,
          start,
          type: TasksActionTypes.uncompletedSuccess,
        });
        dispatch({ type: TasksActionTypes.fetchingDone });
      })
      .catch((error) => {
        dispatch({
          error,
          type: TasksActionTypes.uncompletedFailure,
        });
        dispatch({ type: TasksActionTypes.fetchingDone });
      });
  };
}

/* Used for default ranges so that it only gets the dates it doesn't have. */
export function getTasksUncompletedRange({ start, end }, isCustom = false) {
  return (dispatch, getState) => {
    const { tasksUncompletedDates: storedDates } = getState();
    const callsArray = getDatesForCalls(
      storedDates.get('start'),
      storedDates.get('end'),
      start,
      end
    );

    for (let i = 0; i < Math.min(callsArray.length, 2); i++) {
      dispatch(getTasksUncompleted(callsArray[i], isCustom));
    }
  };
}
