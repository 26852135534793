import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import TextButton from 'components/buttons/text';
import Details from './details';
import Editing from './editing';
import { SaveIdentityErrorKeys } from '../../libs/editIdentitySlideOutConstants';
import {
  required,
  email,
  PhoneNumber,
  Website,
} from '../../helpers/editIdentityValidators';

class IdentityCard extends Component {
  static createIdentityModel(identity) {
    if (!identity) {
      identity = {
        name: '',
        default_identity: false,
      };
    }

    return { ...identity };
  }

  constructor(props) {
    super(props);

    const { editIdentityInfoInstance } = props;

    this.state = {
      identityModel: IdentityCard.createIdentityModel(editIdentityInfoInstance),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.editIdentityInfoInstance !== this.props.editIdentityInfoInstance
    ) {
      this.setState({
        identityModel: IdentityCard.createIdentityModel(
          nextProps.editIdentityInfoInstance
        ),
      });
    }
  }

  getError = (submittedIdentity) => {
    let error;

    if (required(submittedIdentity.name)) {
      return SaveIdentityErrorKeys.invalidName;
    }

    if (required(submittedIdentity.email) || email(submittedIdentity.email)) {
      return SaveIdentityErrorKeys.invalidEmail;
    }

    return error;
  };

  validatePhoneFields = (submittedIdentity) => {
    const {
      actionCreators: { showAlert, showWarning },
    } = this.props;
    const fieldValue = submittedIdentity.currentTarget.value;
    const fieldName = submittedIdentity.currentTarget.name;
    const invalidFieldName = 'work_phone';
    const invalidWebsite = 'website';
    if (!fieldValue) {
      return;
    }
    if (!PhoneNumber(fieldValue) && fieldName !== invalidWebsite) {
      if (fieldName === invalidFieldName) {
        showWarning(SaveIdentityErrorKeys.invalidWorkNumber);
      } else {
        showWarning(SaveIdentityErrorKeys.invalidMobileNumber);
      }
    }
    if (fieldName === invalidWebsite && !Website(fieldValue)) {
      showWarning(SaveIdentityErrorKeys.invalidWebsite);
    }
  };

  onSubmitIdentity = (submittedIdentity) => {
    const {
      actionCreators: {
        addIdentity,
        updateIdentity,
        setIdentityCardEditMode,
        showAlert,
      },
      isCreateNewIdentityMode,
      identity,
    } = this.props;

    const error = this.getError(submittedIdentity);

    if (error) {
      showAlert(error);
    }

    if (isCreateNewIdentityMode) {
      addIdentity(submittedIdentity);
    } else {
      const newIdentity = {
        ...identity,
        name: submittedIdentity.name,
        title: submittedIdentity.title,
        company: submittedIdentity.company,
        email: submittedIdentity.email,
        website: submittedIdentity.website,
        address: submittedIdentity.address,
        mobile_phone: submittedIdentity.mobile_phone,
        work_phone: submittedIdentity.work_phone,
      };
      updateIdentity(newIdentity);
      setIdentityCardEditMode(false);
    }
  };

  render() {
    const {
      isEditing,
      actionCreators,
      onChangeEditing,
      isCreateNewIdentityMode,
      isIdentityFormDirty,
    } = this.props;

    const { identityModel } = this.state;

    return (
      <div className="edit-identity-card">
        {!isEditing && (
          <div className="edit-button-panel">
            <TextButton onClick={onChangeEditing} underline>
              <FormattedMessage id="common.edit" />
            </TextButton>
          </div>
        )}
        {isEditing ? (
          <Editing
            initialValues={identityModel}
            isCreateNewIdentityMode={isCreateNewIdentityMode}
            onSubmit={this.onSubmitIdentity}
            onCancel={onChangeEditing}
            onValidate={this.validatePhoneFields}
            isIdentityFormDirty={isIdentityFormDirty}
            actionCreators={actionCreators}
            enableReinitialize
          />
        ) : (
          <Details identityModel={identityModel} />
        )}
      </div>
    );
  }
}

IdentityCard.propTypes = {
  intl: intlShape.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onChangeEditing: PropTypes.func.isRequired,
  identity: PropTypes.object,
  editIdentityInfoInstance: PropTypes.object,
  isCreateNewIdentityMode: PropTypes.bool.isRequired,
  actionCreators: PropTypes.object.isRequired,
  isIdentityFormDirty: PropTypes.bool.isRequired,
};

export default injectIntl(IdentityCard);
