import isEqual from 'lodash/isEqual';
import { PersonDetailsCards } from '../libs/personDetailsConstants';

export const hasChanges = (original = {}, current = {}) =>
  !isEqual(original, current);

export const groupsCardHasChanges = (
  { groupsByAddressId = {} } = {},
  { groupsByAddressId: currentGroupsByAddressId = {} } = {}
) => !isEqual(groupsByAddressId, currentGroupsByAddressId);

export const hasAnyChanges = (state = {}) => {
  const {
    personDetailsEditing: editing,
    personDetailsOriginalEditState: originals,
    personDetailsCustomFieldsEditState: customFields,
    personDetailsGroupsEditState: groups,
    personDetailsInfoEditState: info,
  } = state;

  return (
    (editing[PersonDetailsCards.info] &&
      hasChanges(originals[PersonDetailsCards.info], info)) ||
    (editing[PersonDetailsCards.groups] &&
      groupsCardHasChanges(originals[PersonDetailsCards.groups], groups)) ||
    (editing[PersonDetailsCards.customFields] &&
      hasChanges(originals[PersonDetailsCards.customFields], customFields)) ||
    false
  );
};
