import EmailSignature from 'web/onboarding/containers/emailSignature/emailSignature';
import EmailConnection from 'web/onboarding/containers/emailConnection/emailConnection';
import ConnectCRM from 'web/onboarding/containers/connectCRM/connectCRM';

export const stepsNames = {
  connectCRM: 'connectCRM',
  emailConnection: 'emailConnection',
  emailSignature: 'emailSignature',
  finish: 'finish',
  welcome: 'welcome',
};

export const stepsTitles = {
  [stepsNames.connectCRM]: 'web.onboarding.title.connectCRM',
  [stepsNames.emailConnection]: 'web.onboarding.title.emailConnection',
  [stepsNames.emailSignature]: 'web.onboarding.title.emailSignature',
};

export const progressBarTitles = {
  [stepsNames.connectCRM]: 'web.onboarding.progressBar.title.connectCRM',
  [stepsNames.emailConnection]:
    'web.onboarding.progressBar.title.emailConnection',
  [stepsNames.emailSignature]:
    'web.onboarding.progressBar.title.emailSignature',
};

export const stepsUrls = {
  [stepsNames.welcome]: '#onboarding/welcome',
  [stepsNames.finish]: '#onboarding/finish',
  [stepsNames.connectCRM]: '#onboarding/crm',
  [stepsNames.emailConnection]: '#onboarding/email_connection',
  [stepsNames.emailSignature]: '#onboarding/email_signature',
};

export const steps = [
  {
    component: ConnectCRM,
    progressBarTitle: progressBarTitles.connectCRM,
    title: stepsTitles.connectCRM,
    url: stepsUrls[stepsNames.connectCRM],
  },
  {
    component: EmailConnection,
    progressBarTitle: progressBarTitles.emailConnection,
    title: stepsTitles.emailConnection,
    url: stepsUrls[stepsNames.emailConnection],
  },
  {
    component: EmailSignature,
    progressBarTitle: progressBarTitles.emailSignature,
    title: stepsTitles.emailSignature,
    url: stepsUrls[stepsNames.emailSignature],
  },
];
