import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { CampaignState } from 'web/libs/constants';
import { CampaignStateLabelMap } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import UserInfo from '../userInfo/userInfo';
import './detailListTitle.scss';

const DetailListTitle = ({
  className,
  name,
  status,
  shared,
  unshared,
  account,
  user,
  createdAt,
  intl: { formatMessage },
}) => {
  const classes = classNames('detail-list-title', className);
  const statusClass = classNames('status', {
    'text-gray': status === CampaignState.unstarted,
    'text-green': status === CampaignState.inProgress,
    'text-yellow': status === CampaignState.paused,
  });

  const iconClass = classNames({
    'detail-list-icon-shared': shared,
    'detail-list-icon-unshared': unshared,
    'detail-list-shared-placeholder': !shared && !unshared,
  });

  return (
    <div className={classes}>
      <div className="name-flex-container">
        <div className="name-container">
          <div className={iconClass} />
          <div className="detail-list-title-name-text">
            <div className="detail-list-title-name-upper">
              <h3 className="detail-list-title-name">{name}</h3>
              <h5 className={statusClass}>
                {formatMessage({ id: CampaignStateLabelMap[status] })}
              </h5>
            </div>
            <UserInfo
              account={account}
              objectCreatedAt={createdAt}
              user={user}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DetailListTitle.propTypes = {
  className: PropTypes.string,
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  shared: PropTypes.bool,
  unshared: PropTypes.bool,
  createdAt: PropTypes.string,
  user: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  status: PropTypes.string,
};

DetailListTitle.defaultProps = {
  className: '',
  shared: false,
  unshared: false,
  createdAt: null,
  status: null,
};

export default injectIntl(DetailListTitle);
