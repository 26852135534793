import merge from 'lodash/merge';
import { moment } from 'languages';

export function parseEditStateToApi(newTask = {}, original = {}) {
  const merged = merge({}, original, {
    person_id: newTask.contactId,
    priority: newTask.priority,
    remind_at: newTask.date
      ? moment(newTask.date, 'l').toISOString()
      : undefined,
    remind_type: newTask.type,
    subject: (newTask.title && newTask.title.trim()) || '',
    unless: newTask.markComplete ? 'replied' : '',
    user_id: newTask.userId,
  });

  if (
    original &&
    original.workflow_details &&
    original.workflow_details.workflow_id
  ) {
    merged.script = newTask.notes || '';
  } else {
    merged.content = newTask.notes || '';
  }

  return merged;
}
