import ActionTypes from 'table/modules/actionTypes';

const initSelectedValues = [];
export function smartFilterSelectedValues(
  state = initSelectedValues,
  { type = '', index, selectedValue = null }
) {
  switch (type) {
    case ActionTypes.smartFilterSetSelectedValue:
      if (index >= 0 && selectedValue) {
        return [
          ...state.slice(0, index),
          selectedValue,
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    default:
      return state;
  }
}

const initSelectedIndexes = [];
export function smartFilterSelectedIndexes(
  state = initSelectedIndexes,
  { type = '', index, selectedIndex = 0 }
) {
  switch (type) {
    case ActionTypes.smartFilterSetSelectedValue:
      if (index >= 0 && selectedIndex >= 0) {
        return [
          ...state.slice(0, index),
          selectedIndex,
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    default:
      return state;
  }
}
