import CCActionTypes from 'web/commandCenter/libs/commandCenterActionTypes';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';

export function commandCenterStartup() {
  return (dispatch, getState) => {
    dispatch(commonStartup());

    if (!getState().commandCenterInitStore) {
      setTimeout(() => {
        dispatch({ type: CCActionTypes.state.initStore });
      });
    }
  };
}
