import React from 'react';
import PropTypes from 'prop-types';
import Label from 'components/label';
import Textarea from 'components/inputs/textarea';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import moment from 'moment/moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Select2 from 'components/selects/select2';
import unionBy from 'lodash/unionBy';
import {
  updateGroupShareTeamIds,
  updateShareGroupComment,
} from 'web/people/actionCreators/peopleGroupActionCreators';
import {
  commonUserAdminTeamPermission,
  getTeams,
} from 'web/shared/services/accountService';
import map from 'lodash/map';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';

const INPUT_WIDTH = 135;
const TEXTAREA_ROWS = 3;

const ShareGroup = ({
  shareGroup,
  shareGroupComment,
  shareGroupTeamIds,
  actionCreators: { updateGroupShareTeamIds, updateShareGroupComment },
  intl: { formatMessage },
}) => {
  const noResultsTeamText = formatMessage({
    id: 'web.templates.shareTemplate.noResultsTeams',
  });
  const placeholderTeams = formatMessage({
    id: 'web.templates.shareTemplate.placeholderTeams',
  });

  const generateOptions = () => {
    const { group_id: groupId, team_ids: teamIds } = shareGroup;
    const usersTeams = getTeams().map((element) => ({
      value: element.id,
      label: element.name,
      clearableValue: commonUserAdminTeamPermission(groupId),
    }));
    const groupSharedTeams = getTeams(teamIds).map((element) => ({
      value: element.id,
      label: element.name,
      clearableValue: commonUserAdminTeamPermission(groupId),
    }));
    return unionBy(usersTeams, groupSharedTeams, 'value');
  };

  const updateSharedTeams = (val) => updateGroupShareTeamIds(map(val, 'value'));

  return (
    <div className="share-group">
      <h1 className="text-overflow">
        {getLocalizedDateFromGroupName(shareGroup.name)}
      </h1>
      <div>
        <FormattedHTMLMessage
          id="common.createdByPerson"
          values={{
            name: shareGroup.creator && shareGroup.creator.name,
          }}
        />
      </div>
      <div>
        <FormattedHTMLMessage
          id="common.lastUpdatedDate"
          values={{
            date:
              (shareGroup.updated_at &&
                moment(shareGroup.updated_at).fromNow()) ||
              '',
          }}
        />
      </div>
      <div className="margin-top-full">
        <Label
          classWrapper="margin-bottom-full"
          id="share-group-label"
          messageKey="web.templates.shareTemplate.teamLabel"
        >
          <Select2
            clearable={false}
            disabled={false}
            multi
            noResultsText={noResultsTeamText}
            onChange={updateSharedTeams}
            options={generateOptions()}
            placeholder={placeholderTeams}
            value={shareGroupTeamIds}
          />
        </Label>
        <Label
          classWrapper="margin-top-full"
          id="share-group-comment-label"
          messageKey="web.people.shareGroupComment"
          width={INPUT_WIDTH}
        >
          <Textarea
            autoFocus={false}
            id="share-group-comment-textarea"
            onChange={updateShareGroupComment}
            rows={TEXTAREA_ROWS}
            value={shareGroupComment}
          />
        </Label>
      </div>
    </div>
  );
};

ShareGroup.propTypes = {
  shareGroup: PropTypes.object.isRequired,
  shareGroupComment: PropTypes.string.isRequired,
  shareGroupTeamIds: PropTypes.array.isRequired,
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => ({
  shareGroup: state.shareGroup,
  shareGroupComment: state.shareGroupComment,
  shareGroupTeamIds: state.shareGroupTeamIds,
});

const mapDispatchToProps = {
  updateShareGroupComment,
  updateGroupShareTeamIds,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  injectIntl
)(ShareGroup);
