import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import SlideOutsActionTypes from 'web/slideOuts/libs/slideOutsActionTypes';
import {
  EditTemplateCards,
  EditTemplateCondtions,
} from '../libs/editTemplateSlideOutConstants';

const EMPTY_OBJECT = {};

export function editTemplateSlideOutTemplateEditState(
  state = EMPTY_OBJECT,
  { type = '', cardId = '', edits = EMPTY_OBJECT, editState = EMPTY_OBJECT }
) {
  switch (type) {
    case EditTemplateActionTypes.openCardEditing:
      return (cardId === EditTemplateCards.template && editState) || state;
    case EditTemplateActionTypes.updateTemplateEditState:
      return { ...state, ...edits };
    case EditTemplateActionTypes.open:
    case EditTemplateActionTypes.close:
    case EditTemplateActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
}

export const defaultMapping = {
  field: '',
  label: '',
  condition: EditTemplateCondtions.equals,
  value: '',
};
export function editTemplateSlideOutRecommendEditState(
  state = EMPTY_OBJECT,
  {
    type = '',
    cardId = '',
    edits = EMPTY_OBJECT,
    index = -1,
    editState = EMPTY_OBJECT,
  }
) {
  switch (type) {
    case EditTemplateActionTypes.openCardEditing:
      return (cardId === EditTemplateCards.recommend && editState) || state;
    case EditTemplateActionTypes.updateRecommendEditState:
      if (index >= 0) {
        return {
          ...state,
          mappings: [
            ...state.mappings.slice(0, index),
            { ...state.mappings[index], ...edits },
            ...state.mappings.slice(index + 1),
          ],
        };
      } else {
        return { ...state, ...edits };
      }
    case EditTemplateActionTypes.addRecommendedRow:
      return {
        ...state,
        mappings: [...state.mappings, defaultMapping],
      };
    case EditTemplateActionTypes.removeRecommendedRow:
      if (index >= 0 && index < state.mappings.length) {
        return {
          ...state,
          mappings: [
            ...state.mappings.slice(0, index),
            ...state.mappings.slice(index + 1),
          ],
        };
      } else {
        return state;
      }
    case EditTemplateActionTypes.open:
    case EditTemplateActionTypes.close:
    case EditTemplateActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
}

export function editTemplateSlideOutEditing(
  state = EMPTY_OBJECT,
  { type = '', cardId = '' }
) {
  switch (type) {
    case EditTemplateActionTypes.openCardEditing:
      return (cardId && { ...state, [cardId]: true }) || state;
    case EditTemplateActionTypes.closeCardEditing: {
      const newState = { ...state };
      Reflect.deleteProperty(newState, cardId);
      return newState;
    }
    case EditTemplateActionTypes.closeCardEditingAll:
    case EditTemplateActionTypes.open:
    case EditTemplateActionTypes.close:
    case EditTemplateActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
}
