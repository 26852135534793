import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import {
  SearchGroupMaximum,
  SearchMinimum,
  SortingDirection,
} from 'table/modules/constants';

const getColumnOrder = (state) => state.columnOrder;
const getTableColumnsWidth = (state) => state.tableColumnsWidth;
const getPropsColumns = (state, props) => props.columns;
const getPropsItems = (state, props) => props.items;
const getPropsPagination = (state, props) => props.pagination;
const getPaginationPerPage = (state) => state.paginationPerPage;
const getAllItems = (state, props) => props.allItems;
const getPropsSearch = (state, props) => props.search;
const getPropsSmartFilters = (state, props) => props.smartFilters;
const getColumnFilterChecked = (state) => state.columnFilterChecked;
const getPaginationCurrentPage = (state) => state.paginationCurrentPage;
const getSearchPrefix = (state) => state.searchPrefix;
const getSearchSelectedSearcherIndex = (state) =>
  state.searchSelectedSearcherIndex;
const getSearchValue = (state) => state.searchValue;
const getSortingColumn = (state) => state.sortingColumn;
const getSortingDirection = (state) => state.sortingDirection;
const getSmartFilterSelectedIndexes = (state) =>
  state.smartFilterSelectedIndexes;
const getTableColumns = (state) => state.tableColumns;
const getTableAllItems = (state) => state.tableAllItems;

function filterSmartFilters(items, smartFilters, selectedIndexes) {
  let sorted = items;
  if (smartFilters) {
    // check for all items? smartFilters.length?
    const arraySmartFilters = (Array.isArray(smartFilters) && smartFilters) || [
      smartFilters,
    ];
    for (let i = 0; i < arraySmartFilters.length; i++) {
      const filter = arraySmartFilters[i];
      if (
        filter.filters &&
        filter.filters[selectedIndexes[i]] &&
        filter.filters[selectedIndexes[i]].filtering &&
        typeof filter.filters[selectedIndexes[i]].filtering === 'function'
      ) {
        sorted = sorted.filter(filter.filters[selectedIndexes[i]].filtering);
      }
    }
  }
  return sorted;
}

function searchItems(items, value, searching) {
  const valueLower = value.toLowerCase();
  return items.filter((element, index, array) => {
    const itemValueToCheck = `${searching(element, index, array)}` || ''; // enforce returning string?
    return itemValueToCheck.toLowerCase().includes(valueLower);
  });
}

function filterSearch(
  items = [],
  value = '',
  selectedSearcherIndex,
  search = {}
) {
  let searched = items;
  if (
    value.length >= SearchMinimum &&
    search.searchers &&
    search.searchers[selectedSearcherIndex] &&
    search.searchers[selectedSearcherIndex].searching &&
    typeof search.searchers[selectedSearcherIndex].searching === 'function'
  ) {
    searched = searchItems(
      items,
      value,
      search.searchers[selectedSearcherIndex].searching
    );
  } else if (
    value.length >= SearchMinimum &&
    search.searching &&
    typeof search.searching === 'function'
  ) {
    searched = searchItems(items, value, search.searching);
  }
  return searched;
}

export const getItems = createSelector(
  [
    getPropsItems,
    getTableColumns,
    getSortingColumn,
    getSortingDirection,
    getSmartFilterSelectedIndexes,
    getPropsSmartFilters,
    getSearchValue,
    getSearchSelectedSearcherIndex,
    getPropsSearch,
    getTableAllItems,
  ],
  (
    items,
    columns,
    sortingColumn,
    direction,
    smartFilterSelectedIndexes,
    smartFilters,
    searchValue,
    searcherIndex,
    search,
    tableAllItems
  ) => {
    let sorted = items;
    if (items.length === 0) {
      return [];
    } else if (!tableAllItems) {
      return sorted;
    } else if (sortingColumn.length && columns.map[sortingColumn].sorting) {
      sorted = sortBy(items, columns.map[sortingColumn].sorting);
    }

    if (direction === SortingDirection.DESC) {
      sorted = sorted.reverse();
    }

    sorted = filterSmartFilters(
      sorted,
      smartFilters,
      smartFilterSelectedIndexes
    );
    sorted = filterSearch(sorted, searchValue, searcherIndex, search);

    return sorted;
  }
);

// Has to be kept here as it relies on getItems and there are order of instantiation issues
export const getPaginationItems = createSelector(
  [
    getItems,
    getPaginationCurrentPage,
    getPropsPagination,
    getPaginationPerPage,
    getAllItems,
  ],
  (items, paginationCurrentPage, pagination, paginationPerPage, allItems) => {
    const perPage = allItems ? paginationPerPage : pagination.perPage;
    if (perPage) {
      const start = (paginationCurrentPage - 1) * perPage;
      return items.slice(start, start + perPage);
    } else {
      return items;
    }
  }
);

export const getFilteredColumns = createSelector(
  [
    getPropsColumns,
    getColumnFilterChecked,
    getColumnOrder,
    getTableColumnsWidth,
  ],
  (columns, columnFilterChecked, columnOrder, tableColumnsWidth) => {
    let currentColumns = columns;
    if (columnFilterChecked) {
      currentColumns = columns.filter((elem) => columnFilterChecked[elem.id]);
    }

    if (columnOrder && columnOrder.length > 0) {
      const orderedColumns = currentColumns.sort(
        (columnId1, columnId2) =>
          columnOrder.indexOf(columnId1.id) - columnOrder.indexOf(columnId2.id)
      );
      currentColumns = orderedColumns;
    }

    if (tableColumnsWidth && tableColumnsWidth.length > 0) {
      currentColumns = currentColumns.map((column) => {
        const currentColumn = tableColumnsWidth.find(
          (x) => x.columnId === column.id
        );
        if (currentColumn) {
          column.width = currentColumn.width;
        }
        return column;
      });
    }

    return currentColumns;
  }
);

function searchDataMulitple(data, value, searching, limit) {
  const valueLower = value.toLowerCase();
  const found = [];
  let index = 0;

  while (found.length < limit && data[index]) {
    const itemValueToCheck = `${searching(data[index], index, data)}` || '';
    if (itemValueToCheck.toLowerCase().includes(valueLower)) {
      // todo, check if already a value in there?
      found.push(itemValueToCheck); // data[index]
    }
    index += 1;
  }

  return found;
}

const initSearchResults = [];
export const getSearchResults = createSelector(
  [getPropsItems, getSearchValue, getSearchPrefix, getPropsSearch],
  (items, value, prefix, search = {}) => {
    if (
      value.length >= SearchMinimum &&
      !prefix &&
      Array.isArray(search.searchers)
    ) {
      return search.searchers.reduce((accumulator, searcher) => {
        if (typeof searcher.searching === 'function') {
          const values = searchDataMulitple(
            items,
            value,
            searcher.searching,
            SearchGroupMaximum + 1
          ); // use '+ 1' to be able to se overflow property
          accumulator.push({
            category: searcher.label,
            overflow: values.length > SearchGroupMaximum,
            values,
          });
        }

        return accumulator;
      }, []);
    } else {
      return initSearchResults;
    }
  }
);
