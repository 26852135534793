import merge from 'lodash/merge';
import * as commonData from './commonDataReducer';
import * as commonState from './commonStateReducer';
import * as emailComposeBodyState from '../modules/emailComposeBodyStateReducer';
import * as previewEmailsState from '../modules/previewEmailsStateReducer';
import * as campaignsUsingTemplateState from '../modules/campaignsUsingTemplateStateReducer';

const commonRoot = merge(
  {},
  commonData,
  commonState,
  emailComposeBodyState,
  previewEmailsState,
  campaignsUsingTemplateState
);

export default commonRoot;
