import get from 'lodash/get';

export const isSalesPhoneConsentAndRecordingEnabled = (store) =>
  !!get(
    store,
    'user.subscription.features.sales_phone_consent_and_recording_enabled'
  );

export const isCampaignEnabled = (store) =>
  !!get(store, 'model.attributes.subscription.campaign_enabled');

export const getSubscriptionId = (store) =>
  get(store, 'model.attributes.subscription.id');

export const isUserManagementConnectionStatusDisabled = (store) =>
  !!get(
    store,
    'user.subscription.features.user_management_connection_status_disabled'
  );

export const isMSIppSfSignInAndActivationEnabled = (store) =>
  !!get(store, 'user.subscription.features.msipp_sf_sign_in_and_activation');
