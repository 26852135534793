import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampaignTypeChooser from 'web/modals/addToCampaign/components/campaignTypeChooser';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { CampaignState, getImageUrl, ModalPopupIds } from 'web/libs/constants';
import { SourceTypes } from 'web/compliance/libs/complianceConstants';
import {
  CampaignTypes,
  PopupSize,
  reactPortalDatePickerException,
  ScheduleLaunchWhen,
} from 'libs/constantsShared';
import {
  AddToCampaignColumns,
  AlertIds,
  ModalComponentIds,
} from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import CkEditor from 'web/ckEditor/services/ckEditorService';
import Popup, {
  PopupBackgroundColors,
  PopupButtonColors,
  PopupAlignContentClasses,
} from 'components/popups';
import EmailComposePopupContainer from 'web/emailComposeBody/emailComposePopupContainer';
import AddToCampaign from 'web/modals/addToCampaign/components/addToCampaign';
import SalesforceProgress from 'web/modals/addToCampaign/components/salesforceProgress/salesforceProgress';
import PreviewEmail from 'web/modals/addToCampaign/components/previewEmail';
import PreviewTask from 'web/modals/addToCampaign/components/previewTask';
import ScheduleLaunch from 'web/modals/addToCampaign/components/scheduleLaunch';
import CampaignLaunched from 'web/modals/addToCampaign/components/campaignLaunched';
import PeopleSearchAddContainer from 'web/people/peopleSearchAdd/containers/peopleSearchAddContainer';
import {
  getPopupAlert,
  getPopupOverlay,
} from 'web/modals/addToCampaign/helpers/addToCampaignHelper';
import {
  closeAddToCampaign,
  defaultCatchAlert,
  goToCampaigns,
  hideProcessingAlert,
  iframeMessage,
  initOpenMarketoCampaignChooser,
  initOpenToutCampaignChooser,
  openLaunched,
  openPreviews,
  openSchedule,
  openSchedulePast,
  warnDataLoss,
} from 'web/modals/addToCampaign/actionCreators/popupActionCreators';
import { postSuccessAddToCampaign } from 'web/modals/addToCampaign';
import {
  clearPopupAlert,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  setMarketoCampaignById,
  setToutCampaignById,
} from 'web/modals/addToCampaign/actionCreators/campaignActionCreators';
import {
  addPeopleToCampaign,
  checkMarketoPeopleToAdd,
  validateBeforePreview,
} from 'web/modals/addToCampaign/actionCreators/addPeopleActionCreators';
import { selectCurrentCategory } from 'web/modals/addToCampaign/actionCreators/campaignCategoryActionCreators';
import { retrySalesforceCall } from 'web/modals/addToCampaign/actionCreators/peopleActionCreators';
import { updatePreview } from 'web/previewEmails/actionCreators/previewEmailsActionCreators';
import {
  updateScheduledLaunch,
  updateWhen,
} from 'web/previewEmails/actionCreators/scheduledLaunchActionCreators';
import {
  addAndLaunchCampaignEmail,
  addAndLaunchCampaignTask,
  addAndLaunchMarketoCampaign,
  backToPreview,
  checkTimeNotPast,
  donePreviews,
  saveAndChangePreview,
} from 'web/previewEmails/actionCreators/previewEmailsExternalActionCreators';
import {
  getFilteredCampaigns,
  getFilteredMarketoCampaigns,
  isMarketoCampaignDisabled,
} from 'web/modals/addToCampaign/selectors/addToCampaignSelectors';
import { getColumns } from 'web/modals/addToCampaign/helpers/columnsHelpers';
import { selectCurrentWorkspace } from 'web/modals/addToCampaign/actionCreators/marketoWorkspacesActionCreators';
import { getPeople } from 'web/people/actionCreators/peopleFetchActionCreators';
import './addToCampaignContainer.scss';
import { getSalesforceConnectLink } from 'web/salesforce/selectors/salesforceSelectors';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { getMarketoIntegration } from 'web/marketoSettings/actionCreators/adminSetupActionCreators';

const EMPTY_POPUP = {};

class AddToCampaignContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    const previewEmailsActionCreators = {
      defaultCatch: (err) =>
        defaultCatchAlert(err, AlertIds.launchCampaignIssue),
      getPeople: () => getPeople(),
      invalidDynamicFields: (invalid) =>
        setPopupAlert(AlertIds.invalidPreviewDynamicFields, invalid),
      invalidInput: (invalid) =>
        setPopupAlert(AlertIds.invalidPreviewInput, invalid),
      invalidPromptFields: (invalid) =>
        setPopupAlert(AlertIds.invalidPreviewPromptFields, invalid),
      launched: (data) => openLaunched(data),
      loading: (state) => setPopupLoading(state),
      openPreviews: () => openPreviews(),
      openSchedule: () => openSchedule(),
      openSchedulePast: () => openSchedulePast(),
      warnDataLoss: (state) => warnDataLoss(state),
    };

    this.actionCreators = {
      addPeopleToCampaign: () =>
        dispatch(addPeopleToCampaign(previewEmailsActionCreators)),
      backToPreview: () => dispatch(backToPreview(previewEmailsActionCreators)),
      backToScheduleLaunch: () => dispatch(openSchedule()),
      categorySelected: (val) =>
        dispatch(selectCurrentCategory(parseInt(val, 10))),
      changePreview: (personId, viewType) =>
        dispatch(
          saveAndChangePreview(personId, viewType, previewEmailsActionCreators)
        ),
      checkMarketoPeopleToAdd: () => dispatch(checkMarketoPeopleToAdd()),
      checkTimeNotPast: () =>
        dispatch(checkTimeNotPast(previewEmailsActionCreators)),
      clearAlert: () => dispatch(clearPopupAlert()),
      closeAddToCampaign: () => dispatch(closeAddToCampaign()),
      donePreviews: () => dispatch(donePreviews(previewEmailsActionCreators)),
      getMarketoIntegration: () => dispatch(getMarketoIntegration()),
      goToCampaigns: () => dispatch(goToCampaigns()),
      hideProcessingAlert: () => dispatch(hideProcessingAlert()),
      initOpenMarketoCampaignChooser: () =>
        dispatch(initOpenMarketoCampaignChooser()),
      initOpenToutCampaignChooser: () =>
        dispatch(initOpenToutCampaignChooser()),
      launchCampaignEmail: () =>
        dispatch(addAndLaunchCampaignEmail(previewEmailsActionCreators)),
      launchCampaignTask: () =>
        dispatch(addAndLaunchCampaignTask(previewEmailsActionCreators)),
      launchMarketoCampaign: () =>
        dispatch(addAndLaunchMarketoCampaign(previewEmailsActionCreators)),
      openViewAlert: (alertId) => dispatch(openViewAlert(alertId)),
      retryPeopleData: () => dispatch(addPeopleToCampaign()),
      retrySalesforceCall: () => dispatch(retrySalesforceCall()),
      selectCurrentWorkspace: (val) =>
        dispatch(selectCurrentWorkspace(parseInt(val, 10))),
      setMarketoCampaignById: (id) => dispatch(setMarketoCampaignById(id)),
      setToutCampaignById: (id) => dispatch(setToutCampaignById(id)),
      updatePreview: (id, data) => dispatch(updatePreview(data)),
      updateScheduledLaunch: (data) => dispatch(updateScheduledLaunch(data)),
      updateWhen: (when) => dispatch(updateWhen(when)),
      validateBeforePreview: () => dispatch(validateBeforePreview()),
      warnDataLoss: () => dispatch(warnDataLoss()),
      warnDataLossHide: () => dispatch(warnDataLoss(true)),
    };
  }

  componentDidMount() {
    if (this.props.iframeMode) {
      this.props.dispatch(iframeMessage(true));
    }
    this.actionCreators.getMarketoIntegration();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.iframeMode && !nextProps.modal.component) {
      this.props.dispatch(iframeMessage(false));
    }
  }

  render() {
    const { modal } = this.props;
    let object = EMPTY_POPUP;
    if (modal.id === ModalPopupIds.addToCampaign && modal.component) {
      object = this.getPopupObject();
    }

    const { children, ...other } = object;
    return (
      <div className="">
        {children && (
          <Popup {...other}>
            {children}
            <EmailComposePopupContainer />
          </Popup>
        )}
      </div>
    );
  }

  getPopupObject = () => {
    const {
      alert,
      alertTextValues,
      loading,
      windowMode,
      sourceOpener,
    } = this.props;

    return {
      ...this._getMainComponent(),
      alert: getPopupAlert(alert, alertTextValues, this.actionCreators),
      getImageUrl,
      loading,
      overlay: getPopupOverlay(this.props, this.actionCreators),
      popUpClassName:
        sourceOpener === SourceTypes.salesforce
          ? PopupAlignContentClasses.left
          : '',
      windowMode,
    };
  };

  /* Note all components are large except Preview (which is gigantic) to keep consistency */
  /* eslint-disable complexity */
  _getMainComponent = () => {
    const {
      modal: { component, data: modalData },
      peopleSearchAddState: { selectedIndex },
      windowMode,
    } = this.props;
    const defaultOnClose =
      (!windowMode && this.actionCreators.closeAddToCampaign) || null;

    switch (component) {
      case ModalComponentIds.addPeople:
        return {
          children: <PeopleSearchAddContainer />,
          footer: {
            onPrimary: this.actionCreators.addPeopleToCampaign,
            onSecondary: this.actionCreators.closeAddToCampaign,
            primaryDisabled: !Object.keys(selectedIndex).length,
            primaryTextId: 'common.next',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closeAddToCampaign,
            textId: 'web.campaignsTab.addPeople.title',
          },
          size: PopupSize.large,
        };
      case ModalComponentIds.error:
        return {
          children: (
            <h4 className="text-gray-dark">
              <FormattedMessage id="web.addToCampaign.error.body" />
            </h4>
          ),
          footer: {
            onPrimary: this.actionCreators.closeAddToCampaign, //todo close or re-show?
            primaryTextId: 'common.ok',
          },
          header: {
            onClose: defaultOnClose,
            textId: 'web.addToCampaign.error.title',
          },
          size: PopupSize.large,
        };
      case ModalComponentIds.addToMarketoCampaign: {
        const {
          currentCategory,
          filteredMarketoCampaigns,
          intl: { formatMessage },
          marketoWorkspaces,
          selectedCampaign: { id: campaignsSelectedId },
        } = this.props;
        const {
          selectCurrentWorkspace: selectCurrentWorkspaceAC,
          setMarketoCampaignById: setCampaignByIdAC,
        } = this.actionCreators;
        const selectedPeopleCount = Object.keys(selectedIndex).length;
        const columns = getColumns(
          [AddToCampaignColumns.marketoCampaignName],
          this.actionCreators,
          formatMessage
        );
        const disabled = this.addToCampaignDisabled(filteredMarketoCampaigns);

        return {
          children: (
            <AddToCampaign
              campaigns={filteredMarketoCampaigns}
              campaignsColumns={columns}
              campaignsOnSelect={setCampaignByIdAC}
              campaignsSelectedId={campaignsSelectedId}
              filterItems={marketoWorkspaces}
              filterMessageKey="common.workspaces"
              filterOnChange={selectCurrentWorkspaceAC}
              filterSelectedId={currentCategory}
              selectedMessageKey="web.addToCampaign.youveSelectedMarketo"
              selectedMessageValues={{ selectedPeopleCount }}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.checkMarketoPeopleToAdd,
            onSecondary: this.actionCreators.closeAddToCampaign,
            primaryDisabled: disabled,
            primaryTextId: 'common.next',
            secondaryTextId: 'common.close',
          },
          header: {
            onClose: defaultOnClose,
            textId: 'web.addToCampaign.addToMarketingCampaign',
          },
          size: PopupSize.large,
        };
      }
      case ModalComponentIds.addToCampaign: {
        const {
          categories,
          currentCategory,
          filteredCampaigns,
          intl: { formatMessage },
          selectedCampaign: { id: campaignsSelectedId },
        } = this.props;
        const {
          categorySelected,
          setToutCampaignById: setCampaignByIdAC,
        } = this.actionCreators;
        const selectedPeopleCount = Object.keys(selectedIndex).length;
        const columns = getColumns(
          [
            AddToCampaignColumns.toutCampaignName,
            AddToCampaignColumns.firstStep,
          ],
          this.actionCreators,
          formatMessage
        );
        const disabled = this.addToCampaignDisabled(filteredCampaigns);

        return {
          children: (
            <AddToCampaign
              campaigns={filteredCampaigns}
              campaignsColumns={columns}
              campaignsOnSelect={setCampaignByIdAC}
              campaignsSelectedId={campaignsSelectedId}
              filterItems={categories}
              filterMessageKey="common.categories"
              filterOnChange={categorySelected}
              filterSelectedId={currentCategory}
              selectedMessageKey="web.addToCampaign.youveSelectedTout"
              selectedMessageValues={{ selectedPeopleCount }}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.validateBeforePreview,
            onSecondary: this.actionCreators.closeAddToCampaign,
            primaryDisabled: disabled,
            primaryTextId: 'common.next',
            secondaryTextId: 'common.close',
          },
          header: {
            onClose: defaultOnClose,
            textId: 'web.addToCampaign.addToSalesCampaign',
          },
          size: PopupSize.large,
        };
      }
      case ModalComponentIds.previewEmail: {
        const { previewEmailsPreviews, salesforceImportFailures } = this.props;
        const atLeastOneValid = previewEmailsPreviews.recipients.ids.find(
          (id) => !previewEmailsPreviews.recipients.map[id].blockedReason
        );
        const exitFunc = atLeastOneValid
          ? this.actionCreators.warnDataLoss
          : this.actionCreators.closeAddToCampaign;

        return {
          children: (
            <PreviewEmail
              attachmentsCount={previewEmailsPreviews.attachmentsCount}
              changePreview={this.actionCreators.changePreview}
              currentId={previewEmailsPreviews.currentId}
              editorId={previewEmailsPreviews.editorId}
              failedImports={salesforceImportFailures}
              getImageUrl={getImageUrl}
              loading={previewEmailsPreviews.loading}
              preview={
                previewEmailsPreviews.previewsMap[
                  previewEmailsPreviews.currentId
                ] || {}
              }
              recipients={previewEmailsPreviews.recipients}
              updatePreview={this.actionCreators.updatePreview}
              viewType={previewEmailsPreviews.viewType}
            />
          ),
          exceptionSelectors: [
            CkEditor.getPopupExceptionSelector(previewEmailsPreviews.editorId),
            '.emailComposePopupContainer',
          ],
          footer: {
            onPrimary: this.actionCreators.donePreviews,
            onSecondary: exitFunc,
            primaryDisabled: !atLeastOneValid,
            primaryTextId: 'common.next',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: windowMode ? null : exitFunc,
            textId: 'web.addToCampaign.addToSalesCampaign',
          },
          size: PopupSize.big,
        };
      }
      case ModalComponentIds.previewMarketo: {
        const { previewEmailsPreviews, selectedCampaign } = this.props;
        const inProgress = selectedCampaign.state !== CampaignState.unstarted;
        const atLeastOneValid = previewEmailsPreviews.recipients.ids.find(
          (id) => !previewEmailsPreviews.recipients.map[id].blockedReason
        );

        return {
          children: (
            <PreviewTask
              campaign={selectedCampaign}
              campaignType={CampaignTypes.marketo}
              isRunning={inProgress}
              recipients={previewEmailsPreviews.recipients}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.launchMarketoCampaign,
            onSecondary: this.actionCreators.closeAddToCampaign,
            primaryColor: PopupButtonColors.green,
            primaryDisabled: !atLeastOneValid,
            primaryIcon: inProgress ? '' : 'rocket',
            primaryTextId: 'common.start',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: defaultOnClose,
            textId: 'web.addToCampaign.addToMarketingCampaign',
          },
          size: PopupSize.large,
        };
      }
      case ModalComponentIds.previewTask: {
        const {
          previewEmailsPreviews,
          selectedCampaign,
          salesforceImportFailures,
        } = this.props;
        const inProgress = selectedCampaign.state !== CampaignState.unstarted;
        const atLeastOneValid = previewEmailsPreviews.recipients.ids.find(
          (id) => !previewEmailsPreviews.recipients.map[id].blockedReason
        );

        return {
          children: (
            <PreviewTask
              campaign={selectedCampaign}
              campaignType={CampaignTypes.tout}
              failedImports={salesforceImportFailures}
              isRunning={inProgress}
              recipients={previewEmailsPreviews.recipients}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.launchCampaignTask,
            onSecondary: defaultOnClose,
            primaryColor: PopupButtonColors.green,
            primaryDisabled: !atLeastOneValid,
            primaryIcon: inProgress ? '' : 'rocket',
            primaryTextId: 'common.start',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: defaultOnClose,
            textId: 'web.addToCampaign.addToSalesCampaign',
          },
          size: PopupSize.large,
        };
      }
      case ModalComponentIds.scheduleLaunch: {
        const {
          previewEmailsScheduled,
          selectedCampaign,
          timezones,
        } = this.props;

        return {
          children: (
            <ScheduleLaunch
              datetime={previewEmailsScheduled.datetime}
              defaultDatetime={previewEmailsScheduled.defaultDatetime}
              defaultTz={previewEmailsScheduled.defaultTz}
              skipWeekends={selectedCampaign.skip_weekends}
              timezone={previewEmailsScheduled.timezone}
              timezones={timezones}
              updateScheduledLaunch={this.actionCreators.updateScheduledLaunch}
              updateWhen={this.actionCreators.updateWhen}
              when={previewEmailsScheduled.when}
              calendarPortal
            />
          ),
          exceptionSelectors: [`.${reactPortalDatePickerException}`],
          footer: {
            onPrimary: this.actionCreators.checkTimeNotPast,
            onSecondary: this.actionCreators.backToPreview,
            primaryColor: PopupButtonColors.green,
            primaryDisabled:
              previewEmailsScheduled.when === ScheduleLaunchWhen.different &&
              previewEmailsScheduled.datetime <=
                moment.tz(previewEmailsScheduled.timezone),
            primaryIcon: 'rocket',
            primaryTextId: 'common.start',
            secondaryTextId: 'common.back',
          },
          header: {
            onClose: windowMode ? null : this.actionCreators.warnDataLoss,
            textId: 'web.campaigns.scheduleLaunch.title',
          },
          size: PopupSize.large,
        };
      }
      case ModalComponentIds.scheduleLaunchPast: {
        return {
          children: (
            <div>
              <FormattedMessage id="web.campaigns.scheduleLaunchPast.body" />
            </div>
          ),
          footer: {
            onPrimary: this.actionCreators.launchCampaignEmail,
            onSecondary: this.actionCreators.backToScheduleLaunch,
            primaryColor: PopupButtonColors.green,
            primaryIcon: 'rocket',
            primaryTextId: 'common.start',
            secondaryTextId: 'common.back',
          },
          header: {
            onClose: windowMode ? null : this.actionCreators.warnDataLoss,
            textId: 'web.campaigns.scheduleLaunchPast.title',
          },
          size: PopupSize.large,
        };
      }
      case ModalComponentIds.campaignLaunched: {
        const backgroundColor = PopupBackgroundColors.green;
        const onClose = () => {
          const { closeAddToCampaign, openViewAlert } = this.actionCreators;
          postSuccessAddToCampaign();
          closeAddToCampaign();
          openViewAlert(PeopleAlertIds.campaignUpdatedNotification);
        };
        return {
          backgroundColor,
          children: <CampaignLaunched peopleAdded={modalData.peopleAdded} />,
          footer: {
            onPrimary: onClose,
            primaryColor: `white-${backgroundColor}`,
            primaryTextId: 'common.ok',
          },
          fullPadding: true,
          size: PopupSize.large,
        };
      }
      case ModalComponentIds.transitioningToSalesforce: {
        const { salesforceProgress } = this.props;
        return {
          children: <SalesforceProgress progress={salesforceProgress} />,
          header: {
            textId: 'common.addToCampaign',
          },
          size: PopupSize.large,
        };
      }
      case ModalComponentIds.chooseCampaignType: {
        const { marketoCardDisabled } = this.props;
        const {
          modal: { data },
        } = this.props;
        const {
          activeWorkFlowInstances,
          canBeAddedToCampaign,
        } = this.getSalesCardDetail(data);

        return {
          children: (
            <CampaignTypeChooser
              actionCreators={this.actionCreators}
              activeWorkFlowInstances={activeWorkFlowInstances}
              canBeAddedToCampaign={canBeAddedToCampaign}
              marketoCardDisabled={marketoCardDisabled}
            />
          ),
          header: {
            onClose: windowMode ? null : this.actionCreators.closeAddToCampaign,
            textId: 'web.addToCampaign.chooseACampaign',
          },
          size: PopupSize.large,
        };
      }
      default:
        return null;
    }
    /* eslint-disable complexity */
  };

  getSalesCardDetail = ({ person } = {}) => {
    if (person) {
      return {
        activeWorkFlowInstances: person.active_workflow_instances.length,
        canBeAddedToCampaign: person.can_be_added_to_campaigns,
      };
    } else {
      return {
        activeWorkFlowInstances: 0,
        canBeAddedToCampaign: true,
      };
    }
  };

  addToCampaignDisabled = (campaigns) => {
    const {
      peopleSearchAddState: { selectedIndex },
      selectedCampaign: { id: campaignsSelectedId },
    } = this.props;
    const selectedPeopleCount = Object.keys(selectedIndex).length;

    return (
      campaigns.length === 0 || !campaignsSelectedId || !selectedPeopleCount
    );
  };
}

AddToCampaignContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  alertTextValues: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  currentCategory: PropTypes.number.isRequired,
  dataLossTextId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  filteredCampaigns: PropTypes.array.isRequired,
  filteredMarketoCampaigns: PropTypes.array.isRequired,
  iframeMode: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  marketoCardDisabled: PropTypes.bool.isRequired,
  marketoWorkspaces: PropTypes.array.isRequired,
  modal: PropTypes.object.isRequired,
  peopleSearchAddState: PropTypes.object.isRequired,
  popupOverlay: PropTypes.string.isRequired,
  popupOverlayTextValues: PropTypes.object.isRequired,
  previewEmailsPreviews: PropTypes.object.isRequired,
  previewEmailsScheduled: PropTypes.object.isRequired,
  salesforceImportFailures: PropTypes.object.isRequired,
  salesforceProgress: PropTypes.number.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  sourceOpener: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  windowMode: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    alert: state.popupAlert,
    alertTextValues: state.popupAlertTextValues,
    categories: state.addToCampaignCategoryList,
    currentCategory: state.addToCampaignModalCategory,
    dataLossTextId: state.addToCampaignModalDataLossTextId,
    filteredCampaigns: getFilteredCampaigns(state),
    filteredMarketoCampaigns: getFilteredMarketoCampaigns(state),
    iframeMode: state.addToCampaignModalIframeMode,
    loading: state.addToCampaignModalLoading || state.popupLoading,
    marketoCardDisabled: isMarketoCampaignDisabled(state),
    marketoWorkspaces: state.marketoWorkspaces,
    modal: state.modal,
    peopleSearchAddState: state.peopleSearchAddState,
    popupOverlay: state.popupOverlay,
    popupOverlayTextValues: state.popupOverlayTextValues,
    previewEmailsPreviews: state.previewEmailsPreviews,
    previewEmailsScheduled: state.previewEmailsScheduled,
    saleforceConnectLink: getSalesforceConnectLink(state),
    salesforceImportFailures: state.addToCampaignSalesforceImportFailures,
    salesforceProgress: state.addToCampaignSalesforceProgress,
    selectedCampaign: state.addToCampaignModalSelectedCampaign,
    sourceOpener: state.addToCampaignSourceOpener,
    timezones: state.timezones,
    windowMode: state.addToCampaignModalWindowMode,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(AddToCampaignContainer);
