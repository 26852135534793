import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ComposeTemplatesCategoryCard from 'web/composeWindow/components/composeTemplatesCategoryCard/composeTemplatesCategoryCard';
import Action from 'components/buttons/action';
import './composeTemplatesEditPinnedCategories.scss';
import {
  setEditPinnedCategories,
  clearEditPinnedCategories,
  sendDuplicateCategoryAlert,
} from 'web/composeWindow/actionCreators/composeTemplatesActionCreators';
import { FavoritesCategory } from '../../libs/composeTemplatesConstants';
import throttle from 'lodash/throttle';
import ComposeTemplatesCategorySelector from 'web/composeWindow/components/composeTemplatesCategorySelector/composeTemplatesCategorySelector';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { DragDropContextWrapper } from 'web/shared/components/dragDropContext/dragDropContext';

const MAX_NUM_PINNED_CATEGORIES = 5;
const MOVE_CARD_THROTTLE_TIME = 20;

class ComposeTemplatesEditPinnedCategories extends React.Component {
  constructor(props) {
    super(props);
    this.throttledMoveCard = throttle(this.moveCard, MOVE_CARD_THROTTLE_TIME);
  }

  componentDidMount() {
    const { setEditPinnedCategories, pinnedCategories } = this.props;
    const clonedPinnedCategories = pinnedCategories.map((category) => ({
      ...category,
    }));
    setEditPinnedCategories(clonedPinnedCategories);
  }

  componentWillUnmount() {
    this.props.clearEditPinnedCategories();
  }

  moveCard = (card, index) => {
    const { setEditPinnedCategories, editPinnedCategories } = this.props;
    const category = editPinnedCategories.find(({ id }) => id === card.id);
    const newPinnedCategoriesOrder = editPinnedCategories.filter(
      ({ id }) => id !== card.id
    );
    newPinnedCategoriesOrder.splice(index, 0, category);
    setEditPinnedCategories(newPinnedCategoriesOrder);
  };

  removePinned = (categoryId) => {
    const { editPinnedCategories } = this.props;
    const { setEditPinnedCategories } = this.props;
    const newEditPinnedCategories = editPinnedCategories.filter(
      ({ id }) => id !== categoryId
    );
    setEditPinnedCategories(newEditPinnedCategories);
  };

  handleSelect = (selectedCategory) => {
    const {
      setEditPinnedCategories,
      sendDuplicateCategoryAlert,
      editPinnedCategories,
    } = this.props;
    const cannotAddPin =
      editPinnedCategories.length >= MAX_NUM_PINNED_CATEGORIES;

    if (!selectedCategory || cannotAddPin) return;

    if (editPinnedCategories.find((el) => el.id === selectedCategory.id)) {
      sendDuplicateCategoryAlert();
      return;
    }

    const newEditPinnedCategories = [...editPinnedCategories];
    newEditPinnedCategories.push(selectedCategory);
    setEditPinnedCategories(newEditPinnedCategories);
  };

  render() {
    const { templateCategories, editPinnedCategories } = this.props;
    const categories = [FavoritesCategory, ...templateCategories];

    return (
      <div className="compose-templates-edit-pinned">
        <FormattedMessage id="web.composeWindow.pinnedCategories.addPinned" />
        <div className="compose-templates-add-category">
          <ComposeTemplatesCategorySelector
            templateCategories={categories}
            onSelect={this.handleSelect}
          />
        </div>
        <FormattedMessage id="web.composeWindow.pinnedCategories.currentlyPinned" />
        <div>
          {editPinnedCategories.map(({ name, id }, idx) => (
            <ComposeTemplatesCategoryCard
              id={id}
              key={id}
              index={idx}
              removePinned={this.removePinned}
              category={name}
              moveCard={this.throttledMoveCard}
              isReady
            />
          ))}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        setEditPinnedCategories,
        clearEditPinnedCategories,
        sendDuplicateCategoryAlert,
      },
      dispatch
    ),
  };
}

const mapStateToProps = (state) => ({
  templateCategories: state.composeTemplatesCategories,
  pinnedCategories: state.composeTemplatesPinnedCategories,
  editPinnedCategories: state.composeTemplatesEditPinnedCategories,
});

ComposeTemplatesEditPinnedCategories.propTypes = {
  templateCategories: PropTypes.array.isRequired,
  pinnedCategories: PropTypes.array.isRequired,
  editPinnedCategories: PropTypes.array.isRequired,
  clearEditPinnedCategories: PropTypes.func.isRequired,
  sendDuplicateCategoryAlert: PropTypes.func.isRequired,
  setEditPinnedCategories: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DragDropContextWrapper(ComposeTemplatesEditPinnedCategories))
);
