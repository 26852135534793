import ContactIntegrationsActionTypes from '../libs/contactIntegrationsActionTypes';
import { getContactIntegrations as getContactIntegrationsCall } from '../services/contactIntegrationsService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export const setContactIntegrations = (integrations = []) => ({
  type: ContactIntegrationsActionTypes.set,
  integrations,
});

export const setFetching = (fetching) => ({
  type: ContactIntegrationsActionTypes.setFetching,
  fetching,
});

export const setFetched = (fetched) => ({
  type: ContactIntegrationsActionTypes.setFetched,
  fetched,
});

export const getContactIntegrations = () => (dispatch) => {
  dispatch(setFetching(true));
  return getContactIntegrationsCall()
    .then((integrations) => {
      dispatch(setContactIntegrations(integrations));
      toutBackboneHelper.updateContactIntegrations(integrations);
      return integrations;
    })
    .finally(() => {
      dispatch(setFetching(false));
      dispatch(setFetched(true));
    });
};
