import CreateAccountActionTypes from '../libs/createAccountActionTypes';

export const createAccountRequestLoading = (
  state = false,
  { type, loading = false }
) => {
  switch (type) {
    case CreateAccountActionTypes.setLoading:
      return loading;
    default:
      return state;
  }
};

export const isAccountCreatedFromSF = (
  state = false,
  { type, created = false }
) => {
  switch (type) {
    case CreateAccountActionTypes.setAccountCreatedFromSF:
      return created;
    default:
      return state;
  }
};
