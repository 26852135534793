import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { AddressTypes } from 'web/person/libs/personDetailsConstants';
import Label from 'components/label';
import Select from 'components/selects/default';
import InfoCardEditingTableRow from '../infoCardEditingTableRow';
import './infoCardEditingTable.scss';

class InfoCardEditingTable extends Component {
  state = {
    type: AddressTypes.email,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.typesItems = [
      {
        id: AddressTypes.email,
        name: this._getTypeText(AddressTypes.email),
      },
      {
        id: AddressTypes.phone,
        name: this._getTypeText(AddressTypes.phone),
      },
      {
        id: AddressTypes.website,
        name: this._getTypeText(AddressTypes.website),
      },
      {
        id: AddressTypes.other,
        name: this._getTypeText(AddressTypes.other),
      },
    ];
  }

  render() {
    const {
      addInfoRow,
      editState,
      labelWidth,
      removeInfoRow,
      setPrimary,
      updateAddresses,
      validateAddress,
    } = this.props;
    const { type } = this.state;

    return (
      <div className="person-details-info-card-editing-table">
        <h4 className="margin-bottom-half">
          <FormattedMessage id="web.person.personDetails.contact.addresses" />
        </h4>
        <Label
          id="person-details-address-types"
          messageKey="common.type"
          sideLabel
          width={labelWidth}
        >
          <Select
            classNameWrapper="info-editing-table-select margin-bottom-full"
            items={this.typesItems}
            onChange={this.setType}
            selected={type}
          />
        </Label>
        <div className="info-editing-table-header">
          <div className="info-editing-table-header-cell info-editing-table-header-cell-primary">
            <FormattedMessage id="common.primary" />
          </div>
          <div className="info-editing-table-header-cell info-editing-table-header-cell-value">
            <span>{this._getTypeText(type)}</span>
          </div>
          <div className="info-editing-table-header-cell info-editing-table-header-cell-location">
            <FormattedMessage id="common.location" />
          </div>
        </div>
        {editState[type].addresses.map(
          ({ isPrimary: ignoreOriginalIsPrimary, ...address }, index) => (
            <InfoCardEditingTableRow
              add={addInfoRow}
              index={index}
              isPrimary={editState[type].primaryIndex === index}
              key={`info-editing-table-row-key-${address.type ||
                index}-${address.id || index}`}
              last={index === editState[type].addresses.length - 1}
              remove={removeInfoRow}
              setPrimary={setPrimary}
              updateAddresses={updateAddresses}
              validateAddress={validateAddress}
              phoneDetails={editState.phone}
              {...address}
            />
          )
        )}
      </div>
    );
  }

  _getTypeText = (type) => {
    const { formatMessage } = this.props.intl;

    switch (type) {
      case AddressTypes.email:
        return formatMessage({ id: 'common.email' });
      case AddressTypes.phone:
        return formatMessage({ id: 'common.phone' });
      case AddressTypes.website:
        return formatMessage({ id: 'common.website' });
      case AddressTypes.other:
      default:
        return formatMessage({ id: 'common.other' });
    }
  };

  setType = (type) => {
    this.setState({ type });
    this.props.onAddressTypeChange(type);
  };
}

InfoCardEditingTable.propTypes = {
  addInfoRow: PropTypes.func.isRequired,
  editState: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  labelWidth: PropTypes.number.isRequired,
  onAddressTypeChange: PropTypes.func.isRequired,
  removeInfoRow: PropTypes.func.isRequired,
  setPrimary: PropTypes.func.isRequired,
  updateAddresses: PropTypes.func.isRequired,
  validateAddress: PropTypes.func.isRequired,
};

InfoCardEditingTable.defaultProps = {};

export default injectIntl(InfoCardEditingTable);
