import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Text from 'components/inputs/text';
import DefaultSelect from 'components/selects/default';
import Label from 'components/label';
import './exchangeConnectionForm.scss';

class ExchangeConnectionForm extends Component {
  constructor(props) {
    super(props);
    this.labelWidth = 150;
  }

  render() {
    const {
      disabled,
      intl: { formatMessage },
      onpremForm: { email, password, version, url },
    } = this.props;

    return (
      <div className="exchange-connection-form">
        <Label
          className="exchange-connection-form-label"
          classChildren="exchange-connection-form-children"
          id="service-account-email"
          messageKey="common.email"
          width={this.labelWidth}
          sideLabel
        >
          <Text
            className="exchange-connection-form-text"
            onChange={this.onEmailChange}
            placeholder={formatMessage({ id: 'common.email' })}
            type="text"
            value={email}
            disabled={disabled}
          />
        </Label>
        <Label
          className="exchange-connection-form-label"
          classChildren="exchange-connection-form-children"
          id="exchange-password"
          messageKey="common.password"
          width={this.labelWidth}
          sideLabel
        >
          <Text
            className="exchange-connection-form-text"
            onChange={this.onPasswordChange}
            placeholder={formatMessage({ id: 'common.password' })}
            type="password"
            value={password}
            disabled={disabled}
          />
        </Label>
        <Label
          className="exchange-connection-form-label"
          classChildren="exchange-connection-form-children"
          id="exchange-url"
          messageKey="web.settings.emailConnection.modal.onPremise.version"
          width={this.labelWidth}
          sideLabel
        >
          <DefaultSelect
            className="exchange-connection-form-select"
            items={this.exchangeVersions()}
            onChange={this.onVersionChange}
            type="text"
            selected={version}
            disabled={disabled}
            menuStyle={{ 'text-align': 'left' }}
          />
        </Label>
        <Label
          className="exchange-connection-form-label"
          classChildren="exchange-connection-form-children"
          id="exchange-domain"
          messageKey="web.settings.emailConnection.modal.onPremise.url"
          width={this.labelWidth}
          sideLabel
        >
          <Text
            className={classNames(
              'exchange-connection-form-text',
              'exchange-connection-form-text-last'
            )}
            onChange={this.onUrlChange}
            placeholder="https://"
            type="text"
            value={url}
            disabled={disabled}
          />
          {!disabled && (
            <div className="exchange-connection-form-url-tip">
              <FormattedMessage id="web.settings.emailConnection.modal.onPremise.url.tip" />
            </div>
          )}
        </Label>
      </div>
    );
  }

  exchangeVersions = () => [
    { id: '', name: 'Autodiscover' },
    { id: 'Exchange2016', name: 'Exchange 2016' },
    { id: 'Exchange2013_SP1', name: 'Exchange 2013 Service Pack 1' },
    { id: 'Exchange2013', name: 'Exchange 2013' },
    { id: 'Exchange2010_SP2', name: 'Exchange 2010 Service Pack 2' },
    { id: 'Exchange2010_SP1', name: 'Exchange 2010 Service Pack 1' },
    { id: 'Exchange2010', name: 'Exchange 2010' },
  ];

  onEmailChange = (email) =>
    this.props.updateExchangeOnpremFormState({ email });

  onPasswordChange = (password) =>
    this.props.updateExchangeOnpremFormState({ password });

  onVersionChange = (version) =>
    this.props.updateExchangeOnpremFormState({ version });

  onUrlChange = (url) => this.props.updateExchangeOnpremFormState({ url });

  static propTypes = {
    disabled: PropTypes.bool,
    intl: intlShape.isRequired,
    onpremForm: PropTypes.object.isRequired,
    updateExchangeOnpremFormState: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    updateExchangeOnpremFormState: () => {},
  };
}

export default injectIntl(ExchangeConnectionForm);
