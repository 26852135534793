import React from 'react';
import PropTypes from 'prop-types';
import escapeRegExp from 'lodash/escapeRegExp';

// eslint-disable-next-line react/prop-types
const getHighlightedElement = ({ term, mark, idx }) =>
  mark ? (
    <mark className="background-orange" key={idx}>
      {term}
    </mark>
  ) : (
    <b key={idx}>{term}</b>
  );

const Highlighter = ({ text, highlightTerm, mark = false }) => {
  const terms = text.split(
    new RegExp(`(${escapeRegExp(highlightTerm)})`, 'gi')
  );
  return (
    <span>
      {terms.map(
        (term, idx) =>
          term.toLowerCase() === highlightTerm.toLowerCase()
            ? getHighlightedElement({ idx, mark, term })
            : term
      )}
    </span>
  );
};

Highlighter.propTypes = {
  highlightTerm: PropTypes.string.isRequired,
  mark: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

Highlighter.defaultProps = {
  mark: false,
};

export default Highlighter;
