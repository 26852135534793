import React from 'react';
import { Urls } from 'web/libs/routes';
import './engageZeroState.scss';
import { FormattedHTMLMessage } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';

const EngageZeroState = () => (
  <div className="engage-zero-state">
    <img
      className="zero-state-image"
      src={getImageUrl('engagement-zero-state', 'svg')}
      alt="engage zero"
    />
    <div className="send-email-track">
      <FormattedHTMLMessage id="web.liveFeed.engagement.sendEmailTrack" />
    </div>
    <div className="zero-state-description">
      <FormattedHTMLMessage id="web.liveFeed.engagement.zeroStateDescription" />
    </div>
    <div className="compose-first-email">
      <FormattedHTMLMessage
        id="web.liveFeed.engagement.composeFirstEmail"
        values={{ url: Urls.composeWindow }}
      />
    </div>
  </div>
);

export default EngageZeroState;
