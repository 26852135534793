import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import {
  addRecipient,
  handleSelectAllRecipients,
  liquifyDynamicFields,
  removeAllRecipients,
  removeRecipient,
  resetSlidingWindow,
  searchPeople,
  searchPeopleLeave,
  selectCurrentRecipient,
  setSlidingWindow,
  sortRecipients,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import { getFilteredBulkSearchResults } from 'web/composeWindow/selectors/composeWindowSelectors';
import { BulkEmails } from 'web/composeWindow/libs/composeWindowConstants';
import BulkEmailsTable from 'web/composeWindow/components/bulkEmailsTable';
import ComposeHeaderBar from 'web/composeWindow/components/composeHeaderBar';
import BulkEmailsSearch from 'web/composeWindow/components/bulkEmailsSearch/bulkEmailsSearch';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import './composeBulkEmailsContainer.scss';

const ComposeBulkEmailsContainer = (props) => {
  const {
    addRecipient,
    bulkComposeLoading,
    composeBulkViewOpened,
    currentlySelectedRecipient,
    endingWindow,
    group,
    handleSelectAllRecipients,
    liquifyDynamicFields,
    recipients,
    removeAllRecipients,
    removeRecipient,
    resetSlidingWindow,
    searchPeople,
    searchPeopleLeave,
    searchResults,
    searchValue,
    selectCurrentRecipient,
    setSlidingWindow,
    sortRecipients,
    startingWindow,
  } = props;
  const [isTooltipHovered, toggleIsTooltipHovered] = useState(false);

  const bulkEmailsClasses = classNames('bulk-emails-wrapper', {
    open: composeBulkViewOpened,
    'tooltip-hovered': isTooltipHovered,
  });

  return (
    <div className={bulkEmailsClasses}>
      <ComposeHeaderBar titleId="web.composeWindow.bulkEmails.headerTitle" />
      {bulkComposeLoading && (
        <LoadingSpinner imageUrl={getImageUrl} size={50} />
      )}
      <BulkEmailsSearch
        addRecipient={addRecipient}
        group={group}
        searchDisabled={isSearchDisabled()}
        searchPeople={searchPeople}
        searchPeopleLeave={searchPeopleLeave}
        searchResults={searchResults}
        searchValue={searchValue}
        toggleIsTooltipHovered={toggleIsTooltipHovered}
      />
      <BulkEmailsTable
        currentlySelectedRecipient={currentlySelectedRecipient}
        endingWindow={endingWindow}
        group={group}
        handleSelectAllRecipients={handleSelectAllRecipients}
        liquifyDynamicFields={liquifyDynamicFields}
        recipients={recipients}
        removeAllRecipients={removeAllRecipients}
        removeRecipient={removeRecipient}
        resetSlidingWindow={resetSlidingWindow}
        selectCurrentRecipient={selectCurrentRecipient}
        setSlidingWindow={setSlidingWindow}
        sortRecipients={sortRecipients}
        startingWindow={startingWindow}
      />
    </div>
  );

  function isSearchDisabled() {
    return recipients.length >= BulkEmails.recipientLimit || !!group;
  }
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        addRecipient,
        handleSelectAllRecipients,
        liquifyDynamicFields,
        removeAllRecipients,
        removeRecipient,
        resetSlidingWindow,
        searchPeople,
        searchPeopleLeave,
        selectCurrentRecipient,
        setSlidingWindow,
        sortRecipients,
      },
      dispatch
    ),
  };
}

ComposeBulkEmailsContainer.propTypes = {
  addRecipient: PropTypes.func.isRequired,
  bulkComposeLoading: PropTypes.bool,
  composeBulkViewOpened: PropTypes.bool,
  currentlySelectedRecipient: PropTypes.object,
  endingWindow: PropTypes.number.isRequired,
  group: PropTypes.object,
  handleSelectAllRecipients: PropTypes.func.isRequired,
  liquifyDynamicFields: PropTypes.func.isRequired,
  recipients: PropTypes.array,
  removeAllRecipients: PropTypes.func.isRequired,
  removeRecipient: PropTypes.func.isRequired,
  resetSlidingWindow: PropTypes.func.isRequired,
  searchPeople: PropTypes.func.isRequired,
  searchPeopleLeave: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  searchValue: PropTypes.string,
  selectCurrentRecipient: PropTypes.func.isRequired,
  setSlidingWindow: PropTypes.func.isRequired,
  sortRecipients: PropTypes.func.isRequired,
  startingWindow: PropTypes.number.isRequired,
};

ComposeBulkEmailsContainer.defaultProps = {
  bulkComposeLoading: false,
  composeBulkViewOpened: false,
  currentlySelectedRecipient: undefined,
  group: undefined,
  recipients: [],
  searchResults: [],
  searchValue: '',
};

const mapStateToProps = (state) => ({
  bulkComposeLoading: state.composeBulkEmails.bulkComposeLoading,
  composeWindowOpened: state.composeWindowState.opened,
  currentlySelectedRecipient:
    state.composeBulkEmails.currentlySelectedRecipient,
  endingWindow: state.composeBulkSlidingWindow.endingWindow,
  group: state.composeBulkEmails.group,
  recipients: state.composeBulkEmails.recipients,
  searchResults: getFilteredBulkSearchResults(state),
  searchValue: state.composeBulkEmails.searchValue,
  startingWindow: state.composeBulkSlidingWindow.startingWindow,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeBulkEmailsContainer);
