import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SalesforceAlertIds } from '../../libs/salesforceConstants';
import Alert, { AlertColors } from 'components/alert';
import './salesforceAlerts.scss';

class SalesforceAlerts extends PureComponent {
  render() {
    const alert = this.getAlert();
    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      viewAlert: { id: alertId },
      closeViewAlert,
    } = this.props;
    const defaultAlert = {
      classes: 'salesforce-alert',
      color: AlertColors.redDark,
      onClose: closeViewAlert,
    };

    switch (alertId) {
      case SalesforceAlertIds.formChangesSaved:
        return {
          ...defaultAlert,
          color: AlertColors.greenDark,
          textId: 'common.alert.success',
        };
      case SalesforceAlertIds.formChangesNotSaved:
        return { ...defaultAlert, textId: 'common.alert.failure' };
      case SalesforceAlertIds.somethingWentWrong:
        return { ...defaultAlert, textId: 'common.alert.somethingWentWrong' };
      case SalesforceAlertIds.bccAddressNotProvided:
        return {
          ...defaultAlert,
          manual: true,
          textId: 'web.salesforce.syncSettings.bcc.alert.addressNotProvided',
        };
      default:
        return null;
    }
  };
}

SalesforceAlerts.defaultProps = {
  closeViewAlert: () => {},
  viewAlert: {},
};

SalesforceAlerts.propTypes = {
  closeViewAlert: PropTypes.func,
  viewAlert: PropTypes.object,
};

export default SalesforceAlerts;
