import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextButton from 'components/buttons/text';
import './accountInfoDetails.scss';

class AccountInfoDetails extends Component {
  render() {
    const { actionCreators, accountInfo } = this.props;
    return (
      <div className="person-details-account-info-details">
        <div className="flex--horz-spread margin-bottom-half">
          <h3 className="margin-bottom-half">
            <FormattedMessage id="web.person.personDetails.accountInfo.title" />
          </h3>
          <TextButton onClick={actionCreators.openInSalesforce} underline>
            <FormattedMessage id="web.person.personDetails.accountInfo.editInSalesforce" />
          </TextButton>
        </div>
        <div className="person-details-account-info-fields">
          {accountInfo.map(this.renderDetailsField)}
        </div>
      </div>
    );
  }

  renderDetailsField = ({ textId, value }) => (
    <div
      className="margin-bottom-half person-details-account-info-field"
      key={`person-details-salesforce-field-${textId}`}
    >
      <div className="account-info-detail-name margin-bottom-quarter text-gray">
        <FormattedMessage id={textId} />
      </div>
      <div className="account-info-detail-type">{value}</div>
    </div>
  );
}

AccountInfoDetails.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  accountInfo: PropTypes.array.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  person: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  salesforceInstanceUrl: PropTypes.string.isRequired,
};

export default AccountInfoDetails;
