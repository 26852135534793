import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DO_NOT_ADD_CONTACT_ID } from 'web/people/libs/peopleConstants';
import PropTypes from 'prop-types';
import { Icons } from 'libs/constantsStyles';

import './contactStatusCell.scss';

const ContactStatusCell = ({ rowData: { emailId, isUnsubscribed } }) => {
  const getStatus = (emailId, isUnsubscribed) => {
    if (isUnsubscribed) {
      return (
        <div className="unsubscribed-contact disabled-cell">
          <FormattedMessage id="common.unsubscribed" />
        </div>
      );
    } else if (emailId === DO_NOT_ADD_CONTACT_ID) {
      return null;
    } else {
      return (
        <div className="added-contact">
          <div className={Icons.circleCheck} />
          <FormattedMessage id="web.people.willBeAdded" />
        </div>
      );
    }
  };

  return (
    <div className="contact-status-cell">
      {getStatus(emailId, isUnsubscribed)}
    </div>
  );
};

ContactStatusCell.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default ContactStatusCell;
