import merge from 'lodash/merge';
import languages, { moment } from 'languages';
import { basePost, basePut, baseDelete } from 'web/services/api';
import { CampaignStepActions, Urls } from 'web/libs/constants';
import { getValidTime } from 'web/campaigns/campaignsTab/helpers/formatting';

function getPayload(data, step = {}) {
  const req = { step };
  const name = data.name || data.subject;
  data.time = data.time ? getValidTime(data.time) : data.time; // eslint-disable-line no-param-reassign

  merge(req, {
    step: {
      action: data.action,
      action_params: {
        template_id: data.templateId,
        timezone: data.timezone,
        time: data.time
          ? moment(
              data.time,
              languages.DateFormats.TIME_12AND24_HRS,
              'en'
            ).format(languages.DateFormats.TIME_AMPM)
          : null,
      },
      threaded: data.threadMessages,
      day: data.day,
      name,
      workflow_id: data.workflowId,
    },
  });

  if (data.action === CampaignStepActions.custom) {
    req.step.action_params.custom_step = data.notes;
  } else {
    req.step.action_params.script = data.notes;
  }

  return req;
}

export function createStep(data) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.campaignStep.replace(':#id', data.workflowId),
      getPayload(data),
      resolve,
      reject
    );
  });
}

export function updateStep(data, step) {
  return new Promise((resolve, reject) => {
    basePut(
      `${Urls.campaignStep.replace(':#id', step.workflow_id)}/${step.id}`,
      getPayload(data, step),
      resolve,
      reject
    );
  });
}

export function updateCampaignStep(data) {
  return new Promise((resolve, reject) => {
    //why is getPayload being used here? we already have the actual step
    basePut(
      `${Urls.campaignStep.replace(':#id', data.workflow_id)}/${data.id}`,
      { step: data },
      resolve,
      reject
    );
  });
}

export function deleteStep(data) {
  return new Promise((resolve, reject) => {
    baseDelete(
      `${Urls.campaignStep.replace(':#id', data.workflow_id)}/${data.id}`,
      {},
      resolve,
      reject
    );
  });
}
