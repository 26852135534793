import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';
import {
  AddressTypes,
  PersonDetailsPopupIds,
  PersonDetailsTabAlertIds,
} from '../libs/personDetailsConstants';
import {
  getPrimaryEmailAddress,
  getPrimaryPhoneAddress,
  isUnsubscribed,
} from '../helpers/personHelpers';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import {
  navigateToComposeWindow,
  openNewTab,
} from 'web/services/routerService';
import { isAdmin, isCurrentUser } from 'web/user/services/userService';
import { removePeople } from 'web/campaigns/people/services/campaignsPeopleService';
import { getTemplateCategories } from 'web/categories/actionCreators/categoriesTemplatesActionCreators';
import {
  archiveEmail as archiveEmailCall,
  unarchiveEmail as unarchiveEmailCall,
} from 'web/emails/services/emailsServices';
import { openFollowUpEmail } from 'web/emails/services/composeServices';
import { openModalAddToCampaign } from 'web/modals/addToCampaign';
import { openModalTask } from 'web/modals/task';
import { getEmailsState, getPerson } from '../selectors/personDetailsSelectors';
import { setTabAlert, setTabLoading } from './personDetailsActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  PersonDetailsEvents,
  PersonDetailsProperties,
  PersonDetailsPropertyTypes,
} from 'web/libs/mixpanelEvents';
import { track } from 'web/services/mixpanelService';
import { getCampaign } from 'web/campaigns/campaignsTab/services/campaignService';
import { navigateToCampaignDetailsTab } from 'web/services/routerService';
import { CampaignsSpecialCategories } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { getMarketoLeadIdFromLeadFields } from 'web/person/helpers/personHelpers.js';

export const openAddToCampaign = () => (dispatch, getState) => {
  track(PersonDetailsEvents.action, {
    [PersonDetailsProperties.type]: PersonDetailsPropertyTypes.campaign,
  });
  openModalAddToCampaign({ peopleIds: [getState().personDetailsPerson.id] });
};

export const openCall = ({ address = '' } = {}) => (dispatch, getState) => {
  const { personDetailsPerson: person } = getState();
  let number = address;

  if (!number) {
    number = getPrimaryPhoneAddress(person).address;
  }

  track(PersonDetailsEvents.action, {
    [PersonDetailsProperties.type]: PersonDetailsPropertyTypes.call,
  });
  toutBackboneHelper.openToutPhone({ address: number, person });
};

export const openEmail = ({ id = '' } = {}) => (dispatch, getState) => {
  const { personDetailsPerson: person } = getState();
  let addressId = id;

  if (!id) {
    addressId = getPrimaryEmailAddress(person).id;
  }

  track(PersonDetailsEvents.action, {
    [PersonDetailsProperties.type]: PersonDetailsPropertyTypes.email,
  });

  navigateToComposeWindow(person.id, addressId);
};

export const openTask = (task) => (dispatch, getState) => {
  const person = getState().personDetailsPerson;
  const passedTask = task || {
    parent_email: getPrimaryEmailAddress(person).address,
    parent_name: `${person.first_name} ${person.last_name}`,
    person_id: person.id,
  };
  track(PersonDetailsEvents.action, {
    [PersonDetailsProperties.type]: PersonDetailsPropertyTypes.task,
  });
  openModalTask(passedTask, { disableAutocomplete: true });
};

export const onAddressClick = (address = {}) => (dispatch) => {
  let addressType;
  switch (address.type) {
    case AddressTypes.email:
      dispatch(openEmail(address));
      addressType = PersonDetailsPropertyTypes.email;
      break;
    case AddressTypes.phone:
      dispatch(openCall(address));
      addressType = PersonDetailsPropertyTypes.phone;
      break;
    case AddressTypes.website:
      openNewTab(address.address, true);
      addressType = PersonDetailsPropertyTypes.website;
      break;
    case AddressTypes.other:
      addressType = PersonDetailsPropertyTypes.other;
      break;
    case AddressTypes.facebook:
      addressType = PersonDetailsPropertyTypes.facebook;
      break;
    case AddressTypes.twitter:
      addressType = PersonDetailsPropertyTypes.twitter;
      break;
    case AddressTypes.linkedin:
      addressType = PersonDetailsPropertyTypes.linkedin;
      break;
    default:
      break;
  }

  if (addressType) {
    track(PersonDetailsEvents.contact, {
      [PersonDetailsProperties.actionType]:
        PersonDetailsPropertyTypes.addressClicked,
      [PersonDetailsProperties.addressType]: addressType,
    });
  }
};

const removePersonFromCampaign = (workflowInstanceId, success) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { activeCampaigns } = getPerson(state);
  const activeCampaign = activeCampaigns.find(
    (activeCampaign) => activeCampaign.workflowInstanceId == workflowInstanceId
  );
  const { id: campaignId, userCanUpdate, userId } = activeCampaign;
  const apiData = {
    admin: !isCurrentUser(state.user, userId) && userCanUpdate,
    success,
  };

  dispatch(setTabLoading(true));

  removePeople([workflowInstanceId], campaignId, apiData)
    .then((removed = []) => {
      if (removed.length !== 1) {
        return Promise.reject();
      } else {
        dispatch(
          setTabAlert(PersonDetailsTabAlertIds.removeFromCampaignSuccess, {
            success,
          })
        );
        const action = success
          ? PersonDetailsPropertyTypes.markSuccess
          : PersonDetailsPropertyTypes.remove;
        track(PersonDetailsEvents.salesCampaigns, {
          [PersonDetailsProperties.actionType]: action,
        });

        return Promise.resolve();
      }
    })
    .catch(() => {
      dispatch(
        setTabAlert(PersonDetailsTabAlertIds.removeFromCampaignNoAccess, {
          success,
        })
      );
    })
    .then(() => {
      dispatch(setTabLoading(false));
    });
};

export const markSuccess = (workflowInstanceId) => (dispatch) => {
  dispatch(removePersonFromCampaign(workflowInstanceId, true));
};

export const handleNavigateToCampaign = (workFlow) => (dispatch, getState) => {
  dispatch(setTabLoading(true));
  const { user } = getState();
  const { id, userCanUpdate } = workFlow;
  if (isAdmin(user) || userCanUpdate) {
    navigateToCampaignDetailsTab(CampaignsSpecialCategories.all.id, id);
  } else {
    getCampaign(id)
      .then(() => {
        navigateToCampaignDetailsTab(CampaignsSpecialCategories.all.id, id);
      })
      .catch(() => {
        dispatch(setTabAlert(PersonDetailsTabAlertIds.noAccessToCampaign));
      })
      .finally(() => {
        dispatch(setTabLoading(false));
      });
  }
};

export const removeFromCampaign = (workflowInstanceId) => (dispatch) => {
  dispatch(removePersonFromCampaign(workflowInstanceId, false));
};

export const updatePersonUnsubscribeReason = (reason) => ({
  payload: reason,
  type: PersonDetailsActionTypes.updateUnsubscribeReason,
});

export const openInSalesforce = () => (dispatch, getState) => {
  const state = getState();
  const { salesforce_contact_id, salesforce_lead_id } = getPerson(state);
  const { instanceUrl } = state.personDetailsSalesforceConnectionData;

  track(PersonDetailsEvents.accountInfo, {
    [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.editInSF,
  });

  // WM: not using routerService#openInNewTab because the noopener attribute
  // causes it to open in a new window, which isn't a good UX.
  window.open(
    `${instanceUrl}/${salesforce_contact_id || salesforce_lead_id}`,
    '_blank'
  );
};

export const openInMarketo = () => (dispatch, getState) => {
  const state = getState();
  const {
    marketoSubDetails: { podName },
  } = state;
  const { lead_fields: leadFields } = getPerson(state);
  const marketoLeadId = getMarketoLeadIdFromLeadFields(leadFields);
  if (podName && marketoLeadId) {
    window.open(
      `https://app-${podName}.marketo.com/leadDatabase/loadLeadDetail?leadId=${marketoLeadId}`,
      '_blank'
    );
  }
};

const getOnSuccessFollowUp = (dispatch) => () => dispatch(setTabLoading(false));

const getOnFailureFollowUp = (dispatch) => () => {
  dispatch(setTabAlert(PersonDetailsTabAlertIds.followUpFail));
  dispatch(setTabLoading(false));
};

export const openFollowUp = (emailId) => (dispatch) => {
  dispatch(setTabLoading(true));
  openFollowUpEmail(
    emailId,
    getOnSuccessFollowUp(dispatch),
    getOnFailureFollowUp(dispatch)
  );
  track(PersonDetailsEvents.salesEmails, {
    [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.followUp,
  });
};

export const openAddAsTemplate = (emailId) => (dispatch) => {
  dispatch(setTabLoading(true));
  dispatch(getTemplateCategories())
    .then(() => {
      dispatch(openPopup(PersonDetailsPopupIds.addAsTemplate, { emailId }));
    })
    .catch(() => {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.getTemplateCategoriesFail));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
};

export const archiveEmail = (emailId) => (dispatch) => {
  dispatch(setTabLoading(true));
  archiveEmailCall(emailId)
    .then(() => {
      dispatch({
        email: { archived: true, id: emailId },
        type: PersonDetailsActionTypes.setEmail,
      });
    })
    .catch(() => {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.archivingEmail));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
  track(PersonDetailsEvents.salesEmails, {
    [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.archive,
  });
};

export const unarchiveEmail = (emailId) => (dispatch) => {
  dispatch(setTabLoading(true));
  unarchiveEmailCall(emailId)
    .then(() => {
      dispatch({
        email: { archived: false, id: emailId },
        type: PersonDetailsActionTypes.setEmail,
      });
    })
    .catch(() => {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.unarchivingEmail));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
  track(PersonDetailsEvents.salesEmails, {
    [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.unarchive,
  });
};

export const openEvents = (emailId) => (dispatch, getState) => {
  const email = getEmailsState(getState()).emails.find(
    ({ id }) => id === emailId
  );
  const events = (email && email.events) || [];

  dispatch(openPopup(PersonDetailsPopupIds.events, { events }));
  track(PersonDetailsEvents.salesEmails, {
    [PersonDetailsProperties.actionType]:
      PersonDetailsPropertyTypes.openSalesEvents,
  });
};

export const exportPerson = (exportFile) => (dispatch) => {
  if (exportFile) {
    track(PersonDetailsEvents.salesEmails, {
      [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.export,
    });
  }
  dispatch({ exportFile, type: PersonDetailsActionTypes.export });
};

export const deleteUser = () => (dispatch, getState) => {
  const state = getState();
  const person = getPerson(state);
  const user = state.user;

  if (isUnsubscribed(person)) {
    if (isAdmin(user)) {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.deleteUserPreventAdmin));
    } else {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.deleteUserPrevent));
    }
  } else {
    dispatch(openPopup(PersonDetailsPopupIds.delete));
  }
};

export const removeCallPopup = (id) => (dispatch) => {
  dispatch(openPopup(PersonDetailsPopupIds.deleteCall, { id }));
};
