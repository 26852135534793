// Use API for response data
// Namespace state action types to module
// Namespace any other common data types to feature
// Name events the same as the chain of namespace to prevent name clashes

/* Please keep alphabetical order for simplicity */

export default {
  addContent: {
    data: {
      selectedPartner: 'SELECTED_CONTENT_PARTNER',
    },
    state: {
      windowMode: 'ADD_CONTENT_MODAL_STATE_WINDOW_MODE',
    },
  },
  addToCampaignModal: {
    state: {
      addToPeopleData: 'ADD_TO_CAMPAIGNS_MODAL_STATE_ADD_TO_PEOPLE_DATA',
      addToPeopleDataOverwrite:
        'ADD_TO_CAMPAIGNS_MODAL_STATE_ADD_TO_PEOPLE_DATA_OVERWRITE',
      allDupes: 'ADD_TO_CAMPAIGNS_MODAL_STATE_ALL_DUPES',
      autocomplete: 'ADD_TO_CAMPAIGNS_MODAL_STATE_AUTOCOMPLETE',
      campaign: 'ADD_TO_CAMPAIGNS_MODAL_STATE_CAMPAIGN',
      category: 'ADD_TO_CAMPAIGNS_MODAL_STATE_CATEGORY',
      filteredCampaigns: 'ADD_TO_CAMPAIGNS_MODAL_STATE_FILTERED_CAMPAIGNS',
      identity: 'ADD_TO_CAMPAIGNS_MODAL_STATE_IDENTITY',
      iframeMode: 'ADD_TO_CAMPAIGNS_MODAL_STATE_IFRAME_MODE',
      loaded: 'ADD_TO_CAMPAIGNS_MODAL_STATE_LOADED',
      loading: 'ADD_TO_CAMPAIGNS_MODAL_STATE_LOADING',
      preselectedCampaign: 'ADD_TO_CAMPAIGNS_MODAL_STATE_PRESELECTED_CAMPAIGN',
      preselectedIds: 'ADD_TO_CAMPAIGNS_MODAL_STATE_PRESELECTED_IDS',
      processingClear: 'ADD_TO_CAMPAIGNS_MODAL_STATE_PROCESSING_CLEAR',
      reset: 'ADD_TO_CAMPAIGNS_MODAL_STATE_RESET',
      salesforceImportFailures:
        'ADD_TO_CAMPAIGNS_MODAL_STATE_SALESFORCE_IMPORT_FAILURES',
      salesforceImportProgress:
        'ADD_TO_CAMPAIGNS_MODAL_STATE_SALESFORCE_IMPORT_PROGRESS',
      salesforcePeopleDataFailure:
        'ADD_TO_CAMPAIGNS_MODAL_STATE_SALESFORCE_PEOPLE_DATA_FAILURE',
      salesforcePeopleDataRequest:
        'ADD_TO_CAMPAIGNS_MODAL_STATE_SALESFORCE_PEOPLE_DATA_REQUEST',
      salesforcePeopleDataSuccess:
        'ADD_TO_CAMPAIGNS_MODAL_STATE_SALESFORCE_PEOPLE_DATA_SUCCESS',
      sourceOpener: 'ADD_TO_CAMPAIGNS_MODAL_STATE_SOURCE_OPENER',
      warnLosingData: 'ADD_TO_CAMPAIGNS_MODAL_STATE_WARN_LOSING_DATA',
      windowMode: 'ADD_TO_CAMPAIGNS_MODAL_STATE_WINDOW_MODE',
    },
  },
  api: {
    activeCampaigns: {
      request: 'API_ACTIVE_CAMPAIGNS_REQUEST',
      success: 'API_ACTIVE_CAMPAIGNS_SUCCESS',
    },
    autocomplete: {
      failure: 'API_AUTOCOMPLETE_FAILURE',
      request: 'API_AUTOCOMPLETE_REQUEST',
      success: 'API_AUTOCOMPLETE_SUCCESS',
    },
    campaigns: {
      failure: 'API_CAMPAIGNS_FAILURE',
      request: 'API_CAMPAIGNS_REQUEST',
      success: 'API_CAMPAIGNS_SUCCESS',
    },
    categories: {
      failure: 'API_CATEGORIES_FAILURE',
      request: 'API_CATEGORIES_REQUEST',
      success: 'API_CATEGORIES_SUCCESS',
    },
    cloneCampaign: {
      failure: 'API_CLONE_CAMPAIGN_FAILURE',
      request: 'API_CLONE_CAMPAIGN_REQUEST',
      success: 'API_CLONE_CAMPAIGN_SUCCESS',
    },
    contentPartners: {
      failure: 'API_CONTENTPARTNERS_FAILURE',
      request: 'API_CONTENTPARTNERS_REQUEST',
      success: 'API_CONTENTPARTNERS_SUCCESS',
    },
    contentPartnersMetadata: {
      failure: 'API_CONTENTPARTNERS_METADATA_FAILURE',
      request: 'API_CONTENTPARTNERS_METADATA_REQUEST',
      success: 'API_CONTENTPARTNERS_METADATA_SUCCESS',
    },
    manageRecordingNotice: {
      setActive: 'API_MANAGERECORDINGNOTICE_SET_ACTIVE',
      success: 'API_MANAGERECORDINGNOTICE_METADATA_SUCCESS',
    },
    manageRecordingNoticeCurrentAudio: {
      success: 'API_MANAGERECORDINGNOTICECURRENTAUDIO_METADATA_SUCCESS',
    },
    manageRecordingNoticeDeleteAudio: {
      success: 'API_MANAGE_DELETE_RECORDING_NOTICE_METADATA_SUCCESS',
    },
  },
  autocomplete: {
    data: {
      clear: 'AUTOCOMPLETE_DATA_CLEAR',
    },
    state: {
      clear: 'AUTOCOMPLETE_STATE_CLEAR',
      value: 'AUTOCOMPLETE_STATE_VALUE',
    },
  },
  campaignPeople: {
    data: {
      addPeople: 'CAMPAIGN_PEOPLE_ADD_PEOPLE',
      people: 'CAMPAIGN_PEOPLE_DATA_PEOPLE',
      peopleSearch: 'CAMPAIGN_PEOPLE_DATA_PEOPLE_SEARCH',
      removeInstancesBulk: 'CAMPAIGN_PEOPLE_DATA_REMOVE_INSTANCES_BULK',
      removePeople: 'CAMPAIGN_PEOPLE_DATA_REMOVE_PEOPLE',
      removePeopleBulk: 'CAMPAIGN_PEOPLE_DATA_REMOVE_PEOPLE_BULK',
      setViewAsFilter: 'CAMPAIGN_PEOPLE_SET_VIEW_AS_FILTER',
      updatePeople: 'CAMPAIGN_PEOPLE_DATA_UPDATE_PEOPLE',
      updatePeopleSearch: 'CAMPAIGN_PEOPLE_DATA_UPDATE_PEOPLE_SEARCH',
    },
    state: {
      filter: 'CAMPAIGN_PEOPLE_STATE_FILTER',
      loading: 'CAMPAIGN_PEOPLE_STATE_LOADING',
      pagination: 'CAMPAIGN_PEOPLE_STATE_PAGINATION',
      paginationSearch: 'CAMPAIGN_PEOPLE_STATE_PAGINATION_SEARCH',
      reset: 'CAMPAIGN_PEOPLE_STATE_RESET',
      resetSearch: 'CAMPAIGNS_PEOPLE_STATE_RESET_SEARCH',
      resetViewAs: 'CAMPAIGN_PEOPLE_STATE_RESET_VIEW_AS',
      searchChange: 'CAMPAIGNS_PEOPLE_STATE_SEARCH_CHANGE',
      setSearching: 'CAMPAIGNS_PEOPLE_STATE_SEARCH_PEOPLE_SET_SEARCHING',
      viewAsFilter: 'CAMPAIGN_PEOPLE_VIEW_AS_STATE_FILTER',
    },
  },
  campaignsTab: {
    data: {
      addedCategory: 'CAMPAIGNS_ADDED_CATEGORY',
      campaignSelected: 'CAMPAIGNS_TAB_DATA_CAMPAIGN_SELECTED',
      campaignTemplateAdd: 'CAMPAIGNS_TAB_CAMPAIGN_TEMPLATE_ADD',
      campaignTemplateUpdate: 'CAMPAIGNS_TAB_CAMPAIGN_TEMPLATE_UPDATE',
      campaignTemplates: 'CAMPAIGNS_TAB_CAMPAIGN_TEMPLATES',
      campaignTemplatesLoaded: 'CAMPAIGNS_TAB_CAMPAIGN_TEMPLATES_LOADED',
      campaignTemplatesLoading: 'CAMPAIGNS_TAB_CAMPAIGN_TEMPLATES_LOADING',
      currentUsers: 'CAMPAIGN_USERS',
      filteredCampaigns: 'CAMPAIGNS_TAB_DATA_FILTERED_CAMPAIGNS',
      newCategoryChange: 'CAMPAIGNS_NEW_CATEGORY_CHANGE',
      newDayNumber: 'CAMPAIGNS_NEW_DAY_NUMBER',
      newSharedWith: 'CAMPAIGNS_NEW_SHARED_WITH',
      removeCategory: 'CAMPAIGNS_REMOVE_CATEGORY',
      updateCategory: 'CAMPAIGNS_TAB_DATA_UPDATE_CATEGORY',
    },
    state: {
      addPeople: 'CAMPAIGNS_TAB_STATE_ADD_PEOPLE',
      addPeopleAddGroup: 'CAMPAIGNS_TAB_STATE_ADD_PEOPLE_ADD_GROUP',
      addPeopleAddPerson: 'CAMPAIGNS_TAB_STATE_ADD_PEOPLE_ADD_PERSON',
      addPeopleChangeGroup: 'CAMPAIGNS_TAB_STATE_ADD_PEOPLE_CHANGE_GROUP',
      addPeopleChangePerson: 'CAMPAIGNS_TAB_STATE_ADD_PEOPLE_CHANGE_PERSON',
      addPeopleDefaultIdenitity:
        'CAMPAIGNS_TAB_STATE_ADD_PEOPLE_DEFAULT_IDENTITY',
      addPeopleIdentity: 'CAMPAIGNS_TAB_STATE_ADD_PEOPLE_IDENTITY',
      addingNewDay: 'CAMPAIGNS_TAB_STATE_ADDING_NEW_DAY',
      alertCampaignMessage: 'ALERT_CAMPAIGN_MESSAGE',
      animateRowOff: 'CAMPAIGNS_TASKS_STATE_ANIMATE_ROW_OFF',
      attachmentDelete: 'CAMPAIGNS_TAB_STATE_ATTACHMENT_DELETE',
      attachmentUploadProgressUpdate:
        'CAMPAIGNS_TAB_STATE_ATTACHMENT_UPLOAD_PROGRESS_UPDATE',
      attachmentUploadStarting:
        'CAMPAIGNS_TAB_STATE_ATTACHMENT_UPLOAD_STARTING',
      attachmentUploadSuccess: 'CAMPAIGNS_TAB_STATE_ATTACHMENT_UPLOAD_SUCCESS',
      campaignAlertClose: 'CAMPAIGNS_TAB_STATE_CAMPAIGN_ALERT_CLOSE',
      campaignAlertOpen: 'CAMPAIGNS_TAB_STATE_CAMPAIGN_ALERT_OPEN',
      campaignCategoryChange: 'CAMPAIGNS_TAB_STATE_CATEGORY_CHANGE',
      campaignDayLoadEnd: 'CAMPAIGNS_TAB_STATE_LOAD_END',
      campaignDayLoadStart: 'CAMPAIGNS_TAB_STATE_LOAD_START',
      campaignEditStateDestroy:
        'CAMPAIGNS_TAB_STATE_CAMPAIGN_EDIT_STATE_DESTROY',
      campaignEditStateInit: 'CAMPAIGNS_TAB_STATE_CAMPAIGN_EDIT_STATE_INIT',
      campaignEditStateUpdate: 'CAMPAIGNS_TAB_STATE_CAMPAIGN_EDIT_STATE_UPDATE',
      campaignsListFilter: 'CAMPAIGNS_TAB_STATE_CAMPAIGNS_LIST_FILTER',
      contentViewLoading: 'CAMPAIGNS_TAB_STATE_CONTENT_VIEW_LOADING',
      createCampaign: 'CAMPAIGN_CREATE',
      createNewCampaignChange: 'CAMPAIGNS_TAB_STATE_CREATE_NEW_CAMPAIGN_CHANGE',
      editEmailDestroy: 'CAMPAIGNS_TAB_STATE_EDIT_EMAIL_DESTROY',
      editingCategory: 'CAMPAIGNS_TAB_STATE_EDITING_CATEGORY',
      initDeleteCampaignState: 'CAMPAIGNS_TAB_STATE_INIT_DELETE_CAMPAIGN_STATE',
      initStore: 'CAMPAIGNS_TAB_STATE_INIT_STORE',
      loading: 'CAMPAIGNS_TAB_STATE_LOADING',
      notAddingNewDay: 'CAMPAIGNS_TAB_STATE_NOT_ADDING_NEW_DAY',
      selectedDetailsTab: 'CAMPAIGNS_TAB_STATE_SELECTED_DETAILS_TAB',
      setViewAsFilterInput: 'CAMPAIGNS_TAB_STATE_SET_VIEW_AS_FILTER_INPUT',
      setViewer: 'CAMPAIGNS_TAB_STATE_SET_VIEWER',
      settings: 'CAMPAIGNS_TAB_STATE_SETTINGS',
      settingsSetupHorizontal: 'CAMPAIGNS_TAB_STATE_SETTINGS_SETUP_HORIZONTAL',
      settingsSlideOutMenuOpen:
        'CAMPAIGNS_TAB_STATE_SETTINGS_SLIDE_OUT_MENU_OPEN',
      unshareCampaignWarning: 'CAMPAIGNS_UNSHARE_WARNING',
      warnLosingData: 'CAMPAIGNS_TAB_STATE_WARN_LOSING_DATA',
    },
  },
  campaignsTasks: {
    state: {
      datesShown: 'CAMPAIGNS_TASKS_STATE_DATES_SHOWN',
      datesSmartFilter: 'CAMPAIGNS_TASKS_STATE_DATES_SMART_FILTER',
      datesSmartFilterReset: 'CAMPAIGNS_TASKS_STATE_DATES_SMART_FILTER_RESET',
      loading: 'CAMPAIGNS_TASKS_STATE_LOADING',
      statusSmartFilter: 'CAMPAIGNS_TASKS_STATE_STATUS_SMART_FILTER',
    },
  },
  campaignsUsingTemplate: {
    state: {
      loading: 'CAMPAGINS_USING_TEMPLATE_STATE_LOADING',
      update: 'CAMPAIGNS_USING_TEMPLATE_STATE_UPDATE',
    },
  },
  category: {
    data: {
      current: 'CATEGORY_DATA_CURRENT', //,  //legacy
      // success: 'CATEGORY_DATA_SUCCESS'  //legacy
    },
  },
  common: {
    data: {
      campaignAdd: 'COMMON_DATA_CAMPAIGN_ADD',
      campaignAddStep: 'COMMON_DATA_CAMPAIGN_ADD_STEP',
      campaignDelete: 'COMMON_DATA_CAMPAIGN_DELETE',
      campaignDeleteStep: 'COMMON_DATA_CAMPAIGN_DELETE_STEP',
      campaignMetricUpdate: 'COMMON_DATA_CAMPAIGN_METRIC_UPDATE',
      campaignUpdate: 'COMMON_DATA_CAMPAIGN_UPDATE',
      campaignUpdateByProperty: 'COMMON_DATA_CAMPAIGN_UPDATE_BY_PROPERTY',
      campaignUpdateStep: 'COMMON_DATA_CAMPIAGN_UPDATE_STEP',
      contentDelete: 'COMMON_DATA_CONTENT_DELETE',
      groups: 'COMMON_DATA_GROUPS',
      templateAdd: 'COMMON_DATA_TEMPLATE_ADD',
      templates: 'COMMON_DATA_TEMPLATES',
    },
    state: {
      initStore: 'COMMON_STATE_INIT_STORE',
    },
  },
  data: {
    categories: 'CAMPAIGNS_CATEGORIES_DATA', //legacy
    sidebarSearchCollection: 'CAMPAIGNS_SIDEBAR_SEARCH_COLLECTION', //legacy,
    sidebarSearchResults: 'CAMPAIGNS_SIDEBAR_SEARCH_DATA', //legacy
    success: 'CAMPAIGNS_DATA', //legacy
  },
  emailComposeBody: {
    state: {
      alertClear: 'EMAIL_COMPOSE_BODY_STATE_ALERT_CLEAR',
      alertSet: 'EMAIL_COMPOSE_BODY_STATE_ALERT_SET',
      attachmentDelete: 'EMAIL_COMPOSE_BODY_STATE_ATTACHMENT_DELETE',
      attachmentUploadProgressUpdate:
        'EMAIL_COMPOSE_BODY_STATE_ATTACHMENT_UPLOAD_PROGRESS_UPDATE',
      attachmentUploadStarting:
        'EMAIL_COMPOSE_BODY_STATE_ATTACHMENT_UPLOAD_STARTING',
      attachmentUploadSuccess:
        'EMAIL_COMPOSE_BODY_STATE_ATTACHMENT_UPLOAD_SUCCESS',
      ckEditorBodyUpdate: 'EMAIL_COMPOSE_BODY_STATE_CKEDITOR_BODY_UPDATE',
      ckEditorCreate: 'EMAIL_COMPOSE_BODY_STATE_CKEDITOR_CREATE',
      ckEditorDestroy: 'EMAIL_COMPOSE_BODY_STATE_CKEDITOR_DESTROY',
      contentUploadProgressUpdate:
        'EMAIL_COMPOSE_BODY_STATE_CONTENT_UPLOAD_PROGRESS_UPDATE',
      contentUploadStarting: 'EMAIL_COMPOSE_BODY_STATE_CONTENT_UPLOAD_STARTING',
      contentUploadSuccess: 'EMAIL_COMPOSE_BODY_STATE_CONTENT_UPLOAD_SUCCESS',
      editEmailDestroy: 'EMAIL_COMPOSE_STATE_EDIT_EMAIL_DESTROY',
      imageInsertChange: 'EMAIL_COMPOSE_STATE_IMAGE_INSERT_CHANGE',
      imageInsertInitEditState:
        'EMAIL_COMPOSE_STATE_IMAGE_INSERT_INIT_EDIT_STATE',
      imageInsertUploadProgressUpdate:
        'EMAIL_COMPOSE_STATE_IMAGE_INSERT_UPLOAD_PROGRESS_UPDATE',
      imageInsertUploadStarting:
        'EMAIL_COMPOSE_STATE_IMAGE_INSERT_UPLOAD_STARTING',
      imageInsertUploadSuccess:
        'EMAIL_COMPOSE_STATE_IMAGE_INSERT_UPLOAD_SUCCESS',
      infoBox: 'EMAIL_COMPOSE_BODY_STATE_INFO_BOX',
      lastTemplateCategory: 'EMAIL_COMPOSE_BODY_STATE_LAST_TEMPLATE_CATEGORY',
      popupClose: 'EMAIL_COMPOSE_BODY_STATE_POPUP_CLOSE',
      popupLoading: 'EMAIL_COMPOSE_BODY_STATE_POPUP_LOADING',
      popupOpen: 'EMAIL_COMPOSE_BODY_STATE_POPUP_OPEN',
      saveAsTemplateChange: 'EMAIL_COMPOSE_BODY_STATE_SAVE_AS_TEMPLATE_CHANGE',
      setDynamicFieldsActiveCategory: 'SET_DYNAMIC_FIELDS_ACTIVE_CATRGORY',
      templateAttachmentsAdd:
        'EMAIL_COMPOSE_BODY_STATE_TEMPLATE_ATTACHMENTS_ADD',
    },
  },
  gong: {
    celebration: 'GONG_CELEBRATION',
    changeAccountName: 'GONG_CHANGE_ACCOUNT_NAME',
    changeDealSize: 'GONG_CHANGE_DEAL_SIZE',
    changeSelectedOpportunity: 'GONG_CHANGE_SELECTED_OPPORTUNITY',
    clearError: 'GONG_CLEAR_FAILURE_RESPONSE',
    closeOpportunityInSalesforce: 'GONG_CLOSE_OPPORTUNITY_IN_SALESFORCE',
    displayConfirmation: 'GONG_DISPLAY_CONFIRMATION',
    failureResponse: 'GONG_FAILURE_RESPONSE',
    hideLoading: 'GONG_HIDE_LOADING',
    receiveOpportunities: 'GONG_RECEIVE_OPPORTUNITIES',
    setSource: 'GONG_SET_SOURCE',
    showLoading: 'GONG_SHOW_LOADING',
    successResponse: 'GONG_SUCCESS_RESPONSE',
    windowMode: 'GONG_WINDOW_MODE',
  },
  imageInsert: {
    state: {
      change: 'IMAGE_INSERT_MODAL_STATE_CHANGE',
      editorName: 'IMAGE_INSERT_MODAL_STATE_EDITOR_NAME',
      initEditState: 'IMAGE_INSERT_MODAL_STATE_INIT_EDIT_STATE',
      uploadProgressUpdate: 'IMAGE_INSERT_MODAL_STATE_UPLOAD_PROGRESS_UPDATE',
      uploadStarting: 'IMAGE_INSERT_MODAL_STATE_UPLOAD_STARTING',
      uploadSuccess: 'IMAGE_INSERT_MODAL_STATE_UPLOAD_SUCCESS',
    },
  },
  modal: {
    state: {
      close: 'MODAL_STATE_CLOSE',
      loading: 'MODAL_STATE_LOADING',
      open: 'MODAL_STATE_OPEN',
      setComponent: 'MODAL_STATE_SET_COMPONENT',
    },
  },
  nonHoverTooltip: {
    position: 'NONHOVERTOOLTIP_POSITION',
    shown: 'NONHOVERTOOLTIP_SHOWN',
    text: 'NONHOVERTOOLTIP_TEXT',
  },
  previewEmails: {
    state: {
      addPreview: 'PREVIEW_EMAILS_STATE_ADD_PREVIEW',
      init: 'PREVIEW_EMAILS_STATE_INIT',
      updatePreview: 'PREVIEW_EMAILS_STATE_UPDATE_PREVIEW',
      updatePreviewsState: 'PREVIEW_EMAILS_STATE_UPDATE_PREVIEWS_STATE',
      updateScheduled: 'UPDATE_SCHEDULED',
    },
  },
  sidebar: {
    searchQuery: 'SIDEBAR_SEARCH_QUERY',
  },
  state: {
    categoriesLoaded: 'CAMPAIGNS_CATEGORIES_LOADED', //legacy
    categoriesLoading: 'CAMPAIGNS_CATEGORIES_LOADING', //legacy
    editCampaignName: 'STATE_EDIT_CAMPAIGN_NAME', //legacy
    error: 'CAMPAIGNS_ERROR', //legacy
    notEditingCampaignName: 'STATE_NOT_EDITING_CAMPAIGN_NAME', //legacy
    processing: 'CAMPAIGN_PROCESSING_PEOPLE', //legacy
    //campaignsTabActionTypes
  },
  taskModal: {
    state: {
      isEdit: 'TASK_MODAL_STATE_IS_EDIT',
      loading: 'TASK_MODAL_STATE_LOADING',
      windowMode: 'TASK_MODAL_STATE_WINDOW_MODE',
    },
  },
  updateCampaignTemplates: {
    state: {
      loading: 'UPDATE_CAMPAIGN_TEMPLATES_STATE_LOADING',
      selectedIds: 'UPDATE_CAMPAIGN_TEMPLATES_STATE_SELECTED_IDS',
    },
  },
};
