import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors, TextColors } from 'libs/constantsStyles';
import Alert from 'components/alert';
import PopupOverlay from '../popupOverlay';
import './popupBody.scss';

const PopupBody = ({
  alert,
  backgroundColor,
  children,
  className,
  full,
  overlay,
  textColor,
}) => {
  const bodyClassName = classNames('popup-body', className, {
    [`text-${textColor}`]: textColor,
    [`background-${backgroundColor}`]: backgroundColor,
    'popup-body-full': full,
  });
  const childrenClassName = classNames({ 'popup-body-padding': !full });

  return (
    <div className={bodyClassName}>
      {alert && (
        <Alert
          {...alert}
          className={classNames('popup-body-alert', alert.className)}
        />
      )}
      {overlay && <PopupOverlay {...overlay} />}
      <div className={childrenClassName}>{children}</div>
    </div>
  );
};

PopupBody.propTypes = {
  alert: PropTypes.object,
  backgroundColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  children: PropTypes.node,
  className: PropTypes.string,
  full: PropTypes.bool,
  overlay: PropTypes.object,
  textColor: PropTypes.oneOf(Object.values(TextColors)),
};

PopupBody.defaultProps = {
  alert: undefined,
  backgroundColor: BackgroundColors.white,
  children: <span />,
  className: '',
  full: false,
  overlay: undefined,
  textColor: '',
};

export default PopupBody;
