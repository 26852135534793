export const EMAIL_CONNECTION_POPUP_ID = 'EMAIL_CONNECTION';

export const ModalComponentIds = {
  gmailSelection: 'MODAL_COMPONENT_EMAIL_CONNECTION_GMAIL_SELECTION',
  office365Selected: 'MODAL_COMPONENT_EMAIL_CONNECTION_OFFICE365_SELECTED',
  onPremiseSelected: 'MODAL_COMPONENENT_EMAIL_CONNECTION_ONPREMISE_SELECTED',
  outlookSelection: 'MODAL_COMPONENT_EMAIL_CONNECTION_OUTLOOK_SELECTION',
  selection: 'MODAL_COMPONENT_EMAIL_CONNECTION_SELECTION',
  disconnect: 'MODAL_COMPONENT_EMAIL_CONNECTION_DISCONNECT',
  gmailRedirect: 'MODAL_COMPONENT_EMAIL_CONNECTION_GMAIL_REDIRECT',
};

export const EmailProviders = {
  default: 'gmail',
  gmail: 'gmail',
  none: 'none',
  office365: 'office365',
  outlook: 'outlook',
  onPremise: 'exchange_onprem',
};

export const EmailProvidersText = {
  onPremise: 'Exchange On-premise',
  gmail: 'Gmail',
  office365: 'Outlook Online',
};

export const EmailConnectionModalNavigation = {
  prev: 'prev',
  next: 'next',
};

export const EmailConnectionLocalStorageKeys = {
  office365: 'EMAIL_CONNECTION_OFFICE365_LOCAL_STORAGE_KEYS',
  connecting: 'connectingEmail',
};

export const EmailProviderBackendKeys = {
  gmail: 'googleoauth2',
};

export const EMAIL_CONNECTIONS = 'email_connections';

export const EmailConnectionAlertIds = {
  changesNotSaved: 'EMAIL_CONNECTION_CHANGES_NOT_SAVED',
  changesSaved: 'EMAIL_CONNECTION_CHANGES_SAVED',
  emailConnectionDisconnected: 'EMAIL_CONNECTION_DISCONNECTED',
  emailConnectionFailure: 'EMAIL_CONNECTION_FAILURE',
  emailConnectionFetchError: 'EMAIL_CONNECTION_FETCH_ERROR',
  emailConnectionNotConnected: 'EMAIL_CONNECTION_NOT_CONNECTED',
  emailConnectionNotDisconnected: 'EMAIL_CONNECTION_NOT_DISCONNECTED',
  emailConnectionConnected: 'EMAIL_CONNECTION_CONNECTED',
  form: {
    emailConnectionFailure: 'EMAIL_CONNECTION_FORM_EMAIL_CONNECTION_FAILURE',
    invalidCredentials: 'EMAIL_CONNECTION_FORM_INVALID_CREDENTIALS',
    invalidUrlFormat: 'EMAIL_CONNECTION_FORM_INVALID_URL_FORMAT',
    missingEmail: 'EMAIL_CONNECTION_FORM_MISSING_EMAIL',
    missingPassword: 'EMAIL_CONNECTION_FORM_MISSING_PASSWORD',
    missingUrl: 'EMAIL_CONNECTION_FORM_MISSING_URL',
  },
};

export const ExchangeOnpremFormDefault = {
  email: '',
  password: '',
  url: '',
  version: '',
};
