import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import LoadingSpinner from 'components/loadingSpinner';
import Label from 'components/label';
import Text from 'components/inputs/text';
import EmailComposeBody from 'web/emailComposeBody/emailComposeBodyContainer';
import AttachmentsCount from 'components/attachmentsCount';
import './previewCompose.scss';

class PreviewCompose extends Component {
  constructor(props) {
    super(props);

    this.ckEditorOptions = { disableDynamicFields: true };
  }

  componentWillMount() {
    this.namePlaceholder = this.props.intl.formatMessage({
      id: 'campaigns.previewEmail.namePlaceholder',
    });
  }

  render() {
    const {
      attachmentsCount,
      editorId,
      getImageUrl,
      loading,
      preview,
      update,
    } = this.props;

    return (
      <div className="previewCompose">
        {loading ? <LoadingSpinner imageUrl={getImageUrl} /> : null}
        <Label
          classWrapper="margin-bottom-full"
          id="preview"
          messageKey="common.name"
          sideLabel
        >
          <span>
            {
              preview.email
                ? `${preview.name ? `${preview.name} <` : ''}${preview.email}${
                  preview.name ? '>' : ''
                  }`
                : this.namePlaceholder
            }
          </span>
        </Label>
        <EmailComposeBody
          ckeOptions={this.ckEditorOptions}
          defaultText={preview.body}
          disableCCs
          hideToolbar
          id={editorId}
          subject={preview.subject}
          updateState={update}
        />
        <AttachmentsCount count={attachmentsCount} pastTense />
      </div>
    );
  }

  updateName = () => {
    // placeholder for blank
  };
}

PreviewCompose.propTypes = {
  attachmentsCount: PropTypes.number.isRequired,
  editorId: PropTypes.string.isRequired,
  getImageUrl: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool,
  preview: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
};

export default injectIntl(PreviewCompose);
