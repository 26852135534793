export const LABEL_WIDTH = 150;

export const DEFAULT_ERROR_TEXT_ID =
  'web.settings.myProfile.alert.genericIssue';

export const accountDetailsFormName = 'accountDetailsForm';

export const PASSWORD_ERRORS_MAP = [
  {
    message: 'password is invalid',
    textId: 'web.settings.myProfile.alert.password.wrongCurrent',
  },
  {
    message: 'alphanumeric password',
    textId: 'web.settings.myProfile.alert.password.lackOfComplexity',
  },
  {
    message: 'previously used',
    textId: 'web.settings.myProfile.alert.password.theSameAsPrevious',
  },
  {
    message: 'not the same',
    textId: 'web.settings.myProfile.alert.password.failedConfirmation',
  },
  {
    message: 'already changed',
    textId: 'web.settings.myProfile.alert.password.tooOftenChange',
  },
];
