import React, { Component } from 'react';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import Cards, { Direction } from 'components/layouts/cards';
import TeamWideSyncSettingsCard from '../teamWideSyncSettingsCard';
import YourSyncSettingsCard from 'web/settings/accountSettings/salesforce/components/yourSyncSettingsCard';
import ApiCallsCard from 'web/settings/accountSettings/salesforce/components/apiCallsCard';
import ActivityDetailsCustomizationCard from '../activityDetailsCustomizationCard/activityDetailsCustomizationCard';

class SyncSettingsPage extends Component {
  leftCards = [YourSyncSettingsCard, TeamWideSyncSettingsCard];

  rightCards = [ApiCallsCard, ActivityDetailsCustomizationCard];

  getCards = (cards) =>
    cards.map((cardComponent, index) => ({
      children: React.createElement(cardComponent, { ...this.props }),
      id: index,
    }));

  render() {
    return (
      <Page className="salesforce-sync-settings-admin">
        <PageView>
          <div className="salesforce-sync-settings-page-wrapper">
            <Cards
              backgroundColor={BackgroundColors.grayExtraLight}
              cards={this.getCards(this.leftCards)}
              className="salesforce-sync-settings-cards-container"
              direction={Direction.vertical}
              marginAround={FULL}
              marginBetween={FULL}
            />
            <Cards
              backgroundColor={BackgroundColors.grayExtraLight}
              cards={this.getCards(this.rightCards)}
              className="salesforce-sync-settings-cards-container"
              direction={Direction.vertical}
              marginAround={FULL}
              marginBetween={FULL}
            />
          </div>
        </PageView>
      </Page>
    );
  }
}

export default SyncSettingsPage;
