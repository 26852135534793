import React from 'react';
import './campaignActiveWarning.scss';
import { FormattedMessage } from 'react-intl';

const CampaignActiveWarning = () => (
  <div className="campaign-active-warning">
    <div className="tout-icon-warning" />
    <div className="margin-left-half">
      <FormattedMessage id="web.campaigns.campaignActiveWarningDescription" />
    </div>
  </div>
);

export default CampaignActiveWarning;
