import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TextColors as TextColorsStyles,
  TextWeights as TextWeightsStyles,
} from 'libs/constantsStyles';
import './text.scss';

class Text extends PureComponent {
  render() {
    const {
      children,
      className,
      color,
      disabled,
      onClick,
      text,
      underline,
      weight,
      ...other
    } = this.props;
    const classes = classNames('tout-text-button', className, {
      [`text-${color}`]: color,
      [`text-${weight}`]: weight,
      'text-underline': underline,
      disabled,
    });

    return (
      <div className={classes} onClick={onClick} {...other}>
        {children || text}
      </div>
    );
  }
}

export const TextColors = TextColorsStyles;
export const TextWeights = TextWeightsStyles;

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(TextColors)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  underline: PropTypes.bool,
  weight: PropTypes.oneOf(Object.values(TextWeights)),
};

Text.defaultProps = {
  children: null,
  className: '',
  color: TextColors.blue,
  disabled: false,
  onClick: () => {},
  text: '',
  underline: false,
  weight: TextWeights.medium,
};

export default Text;
