import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LiveFeedEvent from 'web/liveFeed/components/liveFeedEvent';
import './emailEvents.scss';

const EmailEvents = ({ events, openEmailInfo }) => (
  <div className="email-events">
    {events.length ? (
      events.map(({ id, ...event }) => (
        <LiveFeedEvent
          {...event}
          eventId={id}
          key={`emails-tout-event-${id}`}
          openEmailInfo={openEmailInfo}
          showEmailLink={false}
          staticView
        />
      ))
    ) : (
      <FormattedMessage id="web.emails.popup.events.empty" />
    )}
  </div>
);

EmailEvents.propTypes = {
  events: PropTypes.array,
  openEmailInfo: PropTypes.func,
};

EmailEvents.defaultProps = {
  events: [],
  openEmailInfo: null,
};

export default EmailEvents;
