import findIndex from 'lodash/findIndex';

function reorderOriginalDay(updatedCampaign, updatedStep, originalStep) {
  updatedCampaign.steps.forEach((step, stepIndex) => {
    const currentStep = updatedCampaign.steps[stepIndex];
    if (currentStep.id !== updatedStep.id) {
      if (
        currentStep.day === originalStep.day &&
        currentStep.order > originalStep.order
      ) {
        updatedCampaign.steps[stepIndex].order--;
      }
    }
  });
}

function reorderNewDay(updatedCampaign, updatedStep) {
  updatedCampaign.steps.forEach((step, stepIndex) => {
    const currentStep = updatedCampaign.steps[stepIndex];
    if (currentStep.id !== updatedStep.id) {
      if (
        currentStep.day === updatedStep.day &&
        currentStep.order >= updatedStep.order
      ) {
        updatedCampaign.steps[stepIndex].order++;
      }
    }
  });
}

function reorderLastStep(updatedCampaign, updatedStep, originalStep) {
  if (originalStep.day === updatedStep.day && originalStep.order !== 0) {
    let maxOrder = Number.MIN_SAFE_INTEGER;
    updatedCampaign.steps.forEach((step) => {
      if (
        step.id !== updatedStep.id &&
        step.day === updatedStep.day &&
        step.order > maxOrder
      ) {
        maxOrder = step.order;
      }
    });
    if (updatedStep.order > maxOrder) {
      updatedStep.order--;
    }
  }
}

function reorderFirstStep(updatedCampaign, updatedStep, originalStep) {
  if (originalStep.day === updatedStep.day && originalStep.order === 0) {
    updatedStep.order--;
  }
}

function adjustDays(updatedCampaign) {
  let minDay = Number.MAX_SAFE_INTEGER;
  let hasDayOne = false;

  updatedCampaign.steps.forEach((step) => {
    if (step.day === 1) {
      hasDayOne = true;
    }
    if (step.day < minDay) {
      minDay = step.day;
    }
  });

  if (!hasDayOne) {
    updatedCampaign.steps.forEach((step, stepIndex) => {
      updatedCampaign.steps[stepIndex].day -= minDay - 1;
    });
  }
}

export function reorderCampaignSteps(
  updatedCampaigns,
  originalStep,
  updatedStep
) {
  if (
    originalStep &&
    (originalStep.order !== updatedStep.order ||
      originalStep.day !== updatedStep.day)
  ) {
    const updatedCampaignIndex = findIndex(updatedCampaigns, {
      id: updatedStep.workflow_id,
    });
    const updatedCampaign = updatedCampaigns[updatedCampaignIndex];

    reorderFirstStep(updatedCampaign, updatedStep, originalStep);
    reorderOriginalDay(updatedCampaign, updatedStep, originalStep);
    reorderNewDay(updatedCampaign, updatedStep);
    reorderLastStep(updatedCampaign, updatedStep, originalStep);
    adjustDays(updatedCampaign);

    updatedCampaigns[updatedCampaignIndex] = updatedCampaign;
  }
}
