import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './pageTabNavBar.scss';

const TAB_NAV = 'PageTabNav';

export default class PageTabNavBar extends Component {
  render() {
    const { border, children } = this.props;

    return this._parseChildren(children, border);
  }

  _parseChildren(children = { type: { name: '' } }, border) {
    const { handleClick } = this.props;
    const className = classNames('page-tab-nav-bar', {
      'bottom-border': border,
    });
    if (Array.isArray(children)) {
      return (
        <div className={className} onClick={handleClick}>
          <div className="nav-bar-left">{children[0] || null}</div>
          <div className="nav-bar-right">{children[1] || null}</div>
        </div>
      );
    }
    if (children.type.displayName === TAB_NAV) {
      return <div className={className}>{children}</div>;
    }
    return (
      <div className={className}>
        <div className="nav-bar-right">{children}</div>
      </div>
    );
  }
}

PageTabNavBar.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  handleClick: PropTypes.func,
};

PageTabNavBar.defaultProps = {
  border: false,
  children: [],
  handleClick: () => {},
};
