import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'components/progressBar';
import { PeopleFilters } from 'web/campaigns/people/libs/campaignsPeopleConstants';
import { injectIntl, intlShape } from 'react-intl';
import './completionCell.scss';

class CompletionCell extends Component {
  render() {
    const progress = this._getProgress();
    const progressInfo = this._getProgressInfo();

    return (
      <div className="campaign-completion-container">
        <span className="campaign-completion-row">
          <ProgressBar progress={progress} height={15} animate={false} />
          <div className="campaign-day-step-info">{progressInfo}</div>
        </span>
      </div>
    );
  }

  _doneSteps = (steps, state) => steps.filter((step) => step.done === state);

  _getProgress = () => {
    const {
      selectedCampaign,
      rowData: { workflow_instance_steps: completedSteps, state },
    } = this.props;
    const doneSteps = this._doneSteps(completedSteps, true);
    let progress = (doneSteps.length / selectedCampaign.steps.length) * 100;
    progress =
      progress >= 100 && state === PeopleFilters.inProgress ? 95 : progress;
    return progress;
  };

  _getProgressInfo = () => {
    const {
      selectedCampaign,
      rowData: { workflow_instance_steps: completedSteps, state },
      intl: { formatMessage },
    } = this.props;

    const pendingSteps = this._doneSteps(completedSteps, false);
    const currentStep = pendingSteps[0];
    const currentDay = currentStep && currentStep.day ? currentStep.day : null;
    const order =
      selectedCampaign.steps.length && currentStep
        ? selectedCampaign.steps.filter(
            (step) => step.id === currentStep.step_id
          )
        : [];
    const currentOrder =
      order.length && order[0].order != null ? order[0].order + 1 : null;

    if (currentDay && currentOrder) {
      return `${formatMessage({
        id: 'common.day',
      })} ${currentDay}, ${formatMessage({
        id: 'web.campaigns.step',
      })} ${currentOrder}`;
    } else if (state === PeopleFilters.completed) {
      return formatMessage({ id: 'common.removed' });
    } else if (state === PeopleFilters.unstarted) {
      return formatMessage({ id: 'web.campaigns.queued' });
    }
    return '';
  };
}

CompletionCell.propTypes = {
  intl: intlShape.isRequired,
  rowData: PropTypes.object.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
};

export default injectIntl(CompletionCell);
