import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import AuthorizationEditing from 'web/person/components/personDetails/complianceCard/editing/authorizationEditing';
import SourceEditing from 'web/person/components/personDetails/complianceCard/editing/sourceEditing';
import './complianceEditing.scss';

class ComplianceEditing extends Component {
  constructor() {
    super();
    this.labelClassName = 'compliance-field';
  }

  render() {
    const {
      complianceCardCanSave,
      complianceEditState,
      updateCompliance,
    } = this.props;

    return (
      <div className="person-details-compliance-editing">
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.people.compliance.header" />
        </h3>
        <AuthorizationEditing
          complianceEditState={complianceEditState}
          labelClassName={this.labelClassName}
          labelWidth={this.labelWidth}
          sideLabel
          updateCompliance={updateCompliance}
        />

        <SourceEditing
          complianceEditState={complianceEditState}
          labelClassName={this.labelClassName}
          labelWidth={this.labelWidth}
          sideLabel
          updateCompliance={updateCompliance}
        />

        <div className="compliance-editing-button-row margin-top-half">
          <Button
            color={ButtonColors.gray}
            onClick={this._cancel}
            size={ButtonSizes.standard}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className="margin-left-full"
            color={ButtonColors.blue}
            disabled={!complianceCardCanSave}
            onClick={this._save}
            size={ButtonSizes.standard}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </div>
    );
  }

  _cancel = () => {
    this.props.cancel(PersonDetailsCards.compliance);
  };

  _save = () => {
    this.props.save();
  };
}

ComplianceEditing.propTypes = {
  cancel: PropTypes.func.isRequired,
  complianceCardCanSave: PropTypes.bool,
  complianceEditState: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  updateCompliance: PropTypes.func.isRequired,
};

ComplianceEditing.defaultProps = {
  complianceCardCanSave: false,
};

export default injectIntl(ComplianceEditing);
