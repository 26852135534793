import TimezoneSettingsActionTypes from '../libs/timezoneSettingsActionTypes';
import {
  saveTimezoneSettings,
  saveLocaleSettings,
} from '../services/timezoneSettingsServices';
import {
  successAlertOptions,
  errorAlertOptions,
} from '../libs/timezoneSettingsConstants';
import userActionTypes from '../../../../../user/libs/userActionTypes';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { showAlert } from '../../../actionCreators/myProfileActionCreators';
import { parseObjectValues } from 'web/settings/myProfile/components/timezoneSettings/helpers/timezoneSettingsHelper';
import I18N from 'languages';

export const setLoading = (pending = true) => ({
  type: TimezoneSettingsActionTypes.setLoading,
  pending,
});

export const setTimezone = (timezone) => ({
  type: userActionTypes.setTimezone,
  timezone,
});

export const changeTimezoneSettings = (newSettings) => (dispatch) => {
  dispatch(setLoading());
  return saveTimezoneSettings(newSettings)
    .then(() => {
      dispatch(showAlert(successAlertOptions));
      window.location.reload(true);
    })
    .catch(() => {
      dispatch(showAlert(errorAlertOptions));
    })
    .finally(() => dispatch(setLoading(false)));
};

export const profileContainerStartup = () => (dispatch, getState) => {
  dispatch(commonStartup());

  const userData = getState().user;
  const userTimezone = (userData && userData.time_zone) || '';
  dispatch(setTimezone(userTimezone));
};

export const updateLanguageSettings = () => {
  const cookieLocale = I18N.getCookie('MKT_LANG');
  const newSettings = {
    language: cookieLocale.substr(0, 2),
    locale: cookieLocale,
  };
  return saveLocaleSettings(parseObjectValues(newSettings));
};
