import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getActiveCampaignTemplates(id) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.campaignsUsingTemplate.replace(':#id', id),
      {},
      resolve,
      reject
    );
  });
}
