import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  getContentPartners as getContentPartnersCall,
  getContentPartnersMetadata as getContentPartnersMetadataCall,
  setContentPartners as setContentPartnersCall,
} from '../services/api';
import { closeModal } from './popupActionCreators';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export const contentPartnersSuccess = (data) => ({
  type: ActionTypes.api.contentPartners.success,
  data,
});

export const contentPartnersFailure = (data) => ({
  type: ActionTypes.api.contentPartners.failure,
  data,
});

export function getContentPartners() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.api.contentPartners.request });
    getContentPartnersCall()
      .then((data) => {
        dispatch(contentPartnersSuccess(data));
      })
      .catch((err) => {
        if (err.status === 401) {
          // content partners not enabled for this subscription plan,
          // so content partners should be in a loaded state
          dispatch(contentPartnersSuccess([]));
        } else {
          dispatch(contentPartnersFailure(err));
        }
      });
  };
}

export function setContentPartners(params = {}) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.api.contentPartners.request });
    return setContentPartnersCall(params)
      .then((data) => {
        dispatch(contentPartnersSuccess(data));
      })
      .catch((err) => {
        dispatch(contentPartnersFailure(err));
      });
  };
}

export function setSelectedPartner(selectedPartner) {
  return (dispatch, getState) => {
    const windowMode = getState().addContentModalWindowMode;
    if (selectedPartner.name === 'tout') {
      if (windowMode) {
        window.sendOutlookPopup('tout');
      } else {
        toutBackboneHelper.openContent();
      }
      dispatch(closeModal());
    } else {
      dispatch({
        type: ActionTypes.addContent.data.selectedPartner,
        data: selectedPartner,
      });
    }
  };
}

export function getContentPartnersMetadata(pickerUrl) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.api.contentPartnersMetadata.request });
    getContentPartnersMetadataCall(pickerUrl)
      .then((data) => {
        dispatch({
          type: ActionTypes.api.contentPartnersMetadata.success,
          data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.api.contentPartnersMetadata.failure,
          data: err,
        });
      });
  };
}
