function getIndividualPersonIdMap(people) {
  return people.reduce((prev, curr, index) => {
    if (curr.members) {
      return prev;
    } else {
      prev[curr.id] = index;
      return prev;
    }
  }, {});
}

export function addPeopleAddGroup(state, action) {
  const newIndex = { ...state.selectedIndex };
  const newPeople = [action.data, ...state.people];
  const individualPersonIds = getIndividualPersonIdMap(newPeople);
  action.data.members.forEach((person) => {
    if (individualPersonIds[person.id] >= 0) {
      newPeople.splice(individualPersonIds[person.id], 1);
    }
    newIndex[person.id] = true;
  });
  return {
    ...state,
    people: newPeople,
    groupIndex: { [action.data.id]: true, ...state.groupIndex },
    selectedIndex: newIndex,
  };
}

export function addPeopleChangeGroup(state, action) {
  const groupIndex = state.people.findIndex(
    (obj) => obj.id === action.id && obj.members
  );
  const newIndex = { ...state.selectedIndex };
  if (action.checked) {
    if (state.people[groupIndex] && state.people[groupIndex].members) {
      state.people[groupIndex].members.forEach((person) => {
        newIndex[person.id] = true;
      });
    } else {
      newIndex[action.id] = true;
    }
  } else if (state.people[groupIndex] && state.people[groupIndex].members) {
    state.people[groupIndex].members.forEach((person) =>
      Reflect.deleteProperty(newIndex, person.id)
    );
  } else {
    Reflect.deleteProperty(newIndex, action.id);
  }
  return {
    ...state,
    people: state.people,
    groupIndex: state.groupIndex,
    selectedIndex: newIndex,
  };
}
