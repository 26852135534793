import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import './textBoxWithCopyToClipboard.scss';
import copy from 'copy-to-clipboard';

const TextBoxWithCopyToClipboard = (props) => {
  const contentAreaRef = useRef(null);

  const [editable, setEditable] = useState(false);

  const {
    contentEditable,
    value,
    intl: { formatMessage },
    textBoxClass,
    buttonClass,
  } = props;

  const handleButtonClick = () => {
    copy(contentAreaRef.current.textContent);
    contentAreaRef.current.blur();
  };

  const handleFocus = () => contentEditable && setEditable(true);

  const handleBlur = () => contentEditable && setEditable(false);

  const textBoxClasses = classNames(
    'box-with-copy-to-clipboard',
    textBoxClass || 'box-defaults',
    { editable }
  );

  const buttonClasses = classNames(
    'copy-button',
    buttonClass || 'button-defaults'
  );

  return (
    <div className={textBoxClasses} onFocus={handleFocus} onBlur={handleBlur}>
      <span
        contentEditable={contentEditable}
        suppressContentEditableWarning
        spellCheck={false}
        ref={contentAreaRef}
      >
        {value}
      </span>
      <i
        className={buttonClasses}
        title={formatMessage({ id: 'common.copyAllToClipboard' })}
        onClick={handleButtonClick}
      />
    </div>
  );
};

TextBoxWithCopyToClipboard.propTypes = {
  value: PropTypes.string,
  contentEditable: PropTypes.bool,
  intl: intlShape.isRequired,
  textBoxClass: PropTypes.string,
  buttonClass: PropTypes.string,
};

TextBoxWithCopyToClipboard.defaultProps = {
  value: null,
  contentEditable: false,
  textBoxClass: null,
  buttonClass: null,
};

export default injectIntl(TextBoxWithCopyToClipboard);
