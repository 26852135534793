import { isCurrentUser } from 'web/user/services/userService';

export const getCreatedBy = (user, identity, formatMessage) => {
  if (isCurrentUser(user, identity.user_id)) {
    return formatMessage({ id: 'common.you' });
  } else {
    const teamMember = user.subscription.users.find(
      (currentUser) => currentUser.id === identity.user_id
    );
    if (teamMember && teamMember.name) {
      return teamMember.name;
    }
  }
  return null;
};
