import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import SettingsUnsubscribesEditor from 'web/settings/unsubscribes/components/settingsUnsubscribesEditor';
import { saveAdminUnsubscribes } from '../actionCreators/adminUnsubscribesIntegrationsActionCreators';
import { updateUnsubscribeSettings } from 'web/settings/unsubscribes/actionCreators/settingsUnsubscribesActionCreators';

class AdminUnsubscribeEditorContainer extends Component {
  render() {
    const { defaultBody, save } = this.props;

    return (
      <SettingsUnsubscribesEditor
        save={save}
        updateEditorBody={this.updateEditorBody}
        id="admin-unsubscribe-settings"
        defaultBody={defaultBody}
        options={{
          addDefaultLink: true,
          disableAddContent: true,
          disableDynamicFields: true,
        }}
      />
    );
  }

  updateEditorBody = (body) => {
    const { updateSettings } = this.props;

    updateSettings({
      block_unsubscribe_text: body,
    });
  };
}

AdminUnsubscribeEditorContainer.propTypes = {
  defaultBody: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  defaultBody: state.settingsUnsubscribes.block_unsubscribe_text,
});

const mapDispatchToProps = {
  save: saveAdminUnsubscribes,
  updateSettings: updateUnsubscribeSettings,
};

export default compose(
  withCommonModals,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdminUnsubscribeEditorContainer);
