import get from 'lodash/get';
import {
  TeamManagementEvents,
  TeamManagementActions,
} from 'web/libs/mixpanelEvents';
import { navigateToTeamMembers } from 'web/services/routerService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  openPopup,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  getTeams as getTeamsEntities,
  updateTeam as updateTeamEntity,
  deleteTeam as deleteTeamEntity,
  createTeam as createTeamEntity,
} from 'web/teams/actionCreators/teamsActionCreators';
import {
  shouldInvalidate as shouldInvalidateTeams,
  getMasterTeam,
  getTeams as getTeamsSelector,
} from 'web/teams/selectors/teamsSelectors';
import { isAdminCreateTeamsEnabled } from 'web/user/selectors/userSelectors';
import { TeamManagementAlertIds } from '../libs/teamManagementConstants';
import { TeamsPopupIds } from '../libs/teamsConstants';
import TeamsActionTypes from '../libs/teamsActionTypes';
import { getSelectedTeamId } from '../selectors/teamsSelector';
import { teamMembersTableExternals } from '../helpers/teamMembersHelpers';

export const setSelectedTeamId = (id = null) => ({
  type: TeamsActionTypes.setSelectedTeamId,
  id,
});

export const setEditingTeamId = (id = null) => ({
  type: TeamsActionTypes.setEditingTeamId,
  id,
});

export const setCreateTeamLoading = (loading = true) => ({
  type: TeamsActionTypes.setCreateTeamLoading,
  loading,
});

export const setUpdateTeamLoading = (loading = true) => ({
  type: TeamsActionTypes.setUpdateTeamLoading,
  loading,
});

export const setDeleteTeamLoading = (loading = true) => ({
  type: TeamsActionTypes.setDeleteTeamLoading,
  loading,
});

export const teamsStartup = (options = {}) => async (dispatch, getState) => {
  if (shouldInvalidateTeams(getState())) {
    await dispatch(getTeamsEntities());
  }

  const selectedTeam =
    getTeamsSelector(getState()).find((team) => team.id === +options.teamId) ||
    getMasterTeam(getState());

  dispatch(setSelectedTeamId(selectedTeam.id));
};

export const updateTeam = (id, name) => (dispatch) => {
  dispatch({
    type: TeamsActionTypes.updateTeam,
    payload: { id, name },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.updateTeam,
        },
      },
    },
  });
  dispatch(setUpdateTeamLoading());
  dispatch(updateTeamEntity({ id, name }))
    .catch((error) => {
      dispatch(
        openViewAlert(TeamManagementAlertIds.dataChangesNotSaved, {
          errors: get(error, 'response.body', {}),
        })
      );
    })
    .finally(() => {
      dispatch(setEditingTeamId());
      dispatch(setUpdateTeamLoading(false));
    });
};

export const openCreateTeamPopup = () => (dispatch, getState) => {
  isAdminCreateTeamsEnabled(getState())
    ? dispatch(
        openPopup(
          TeamsPopupIds.createTeam,
          {},
          {
            analytics: {
              event: TeamManagementEvents.teamManagement,
              properties: {
                'Action Type': TeamManagementActions.createTeamPopup,
              },
            },
          }
        )
      )
    : dispatch(openPopup(TeamsPopupIds.needSubscriptionUpgrade));
};

export const openDeleteTeamConfirmationPopup = (teamId) => (dispatch) => {
  dispatch(
    openPopup(
      TeamsPopupIds.deleteTeamConfirmation,
      { teamId },
      {
        analytics: {
          event: TeamManagementEvents.teamManagement,
          properties: {
            'Action Type': TeamManagementActions.deleteTeamConfirmationPopup,
          },
        },
      }
    )
  );
};

export const deleteTeam = (teamId) => (dispatch, getState) => {
  dispatch({
    type: TeamsActionTypes.deleteTeam,
    payload: { id: teamId },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.deleteTeam,
        },
      },
    },
  });
  dispatch(setDeleteTeamLoading());
  dispatch(deleteTeamEntity(teamId))
    .then(() => {
      if (getSelectedTeamId(getState()) === teamId) {
        teamMembersTableExternals.clearSelectedIds();
        navigateToTeamMembers();
      }
      dispatch(closePopup());
    })
    .catch((errors) => {
      dispatch(
        openPopup(TeamsPopupIds.deleteTeamConfirmation, { teamId, errors })
      );
    })
    .finally(() => {
      dispatch(setDeleteTeamLoading(false));
    });
};

export const createTeam = ({ teamName }) => (dispatch) => {
  dispatch({
    type: TeamsActionTypes.createTeam,
    payload: { name: teamName },
    meta: {
      analytics: {
        event: TeamManagementEvents.teamManagement,
        properties: {
          'Action Type': TeamManagementActions.createTeam,
        },
      },
    },
  });
  dispatch(setCreateTeamLoading());
  dispatch(createTeamEntity({ name: teamName }))
    .then(() => {
      dispatch(closePopup());
    })
    .catch((error) => {
      dispatch(
        openPopup(TeamsPopupIds.createTeam, {
          errors: get(error, 'response.body', {}),
        })
      );
    })
    .finally(() => {
      dispatch(setCreateTeamLoading(false));
    });
};
