import GroupsActionTypes from '../libs/groupsActionTypes';
import { sortGroups } from '../helpers/groupsParsers';
import { getGroups as getGroupsCall } from 'web/groups/services/groupsService';

export const getGroups = (force = false) => (dispatch, getState) => {
  const { groupsInit } = getState();

  if (force || !groupsInit) {
    dispatch({ type: GroupsActionTypes.fetching });
    return getGroupsCall()
      .then((groups) => {
        dispatch({
          groups: sortGroups(groups),
          type: GroupsActionTypes.set,
        });
      })
      .catch((error) => {
        dispatch({ error, type: GroupsActionTypes.getFailure });
      })
      .then(() => {
        dispatch({ type: GroupsActionTypes.fetchingDone });
      });
  } else {
    return Promise.resolve();
  }
};

export const updateGroup = (group) => ({
  group,
  type: GroupsActionTypes.update,
});

export const updateGroups = (groups) => ({
  groups,
  type: GroupsActionTypes.updateBulk,
});

export const removeGroup = (id) => ({
  id,
  type: GroupsActionTypes.remove,
});
