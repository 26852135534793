import ContentActionTypes from '../libs/contentActionTypes';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import ComposeWindowActionTypes from 'web/composeWindow/libs/composeWindowActionTypes';

export function content(
  state = [],
  { type = '', content: contentData = [], data = {}, ids = [], payload = {} }
) {
  let temp = [];
  switch (type) {
    case ContentActionTypes.set:
      return contentData;
    case ContentActionTypes.delete:
      temp = [...state];
      ids.forEach((id) => {
        const index = temp.findIndex((element) => element.id === +id);
        if (index > -1) {
          temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
        }
      });
      return temp;
    case ActionTypes.emailComposeBody.state.attachmentUploadSuccess:
    case ActionTypes.emailComposeBody.state.contentUploadSuccess:
      return [data, ...state];
    case ComposeWindowActionTypes.initAttachmentUploadRequest:
      return [payload, ...state];
    case ComposeWindowActionTypes.initAttachmentUploadSuccess:
    case ComposeWindowActionTypes.initAttachmentUploadUpdating: {
      const newFilesState = [...state];
      const { reference } = payload;
      const foundIndex = newFilesState.findIndex(
        (attachment) => attachment.reference === reference
      );
      newFilesState[foundIndex] = { ...newFilesState[foundIndex], ...payload };
      return newFilesState;
    }
    default:
      return state;
  }
}
