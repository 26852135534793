export const TableProperties = {
  tableId: 'domain-block-table',
  standardColumnWidth: 200,
  lastColumnWidth: 10,
  standardRowHeight: 50,
  maxRowHeight: 320,
  noOp: () => {},
  domainErrorKey: 'domain',
  domainErrorSpecialChars: 'specialChars',
  domainError: 'domainError',
};
