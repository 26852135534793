export const SALESFORCE_SOURCE = 'salesforce';

export const MARKETO_SALES_OUTBOX = 'marketoSalesOutbox';

export const LOG_EMAIL_ACTIVITY_FORM_ADMIN_ID =
  'log-email-activity-form-admin-id';

export const LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID =
  'log-email-activity-form-non-admin-id';

export const SYNC_TASKS_FORM_ADMIN_ID = 'sync-tasks-form-admin';

export const SYNC_TASKS_FORM_NON_ADMIN_ID = 'sync-task-form-non-admin';

export const SalesforcePopupIds = {
  activateApi: 'SALESFORCE_ACTIVATE_API',
  activateBcc: 'SALESFORCE_ACTIVATE_BCC',
  activityCustomizationDynamicFields:
    'SALESFORCE_ACTIVITY_CUSTOMIZATION_DYNAMIC_FIELDS',
  connectConfirmation: 'SALESFORCE_CONNECT_CONFIRMATION',
  disconnectConfirmation: 'SALESFORCE_DISCONNECT_CONFIRMATION',
  unsavedSyncFormAdmin: 'SALESFORCE_SYNC_SETTINGS_FORM_UNSAVED_ADMIN',
  unsavedSyncFormNonAdmin: 'SALESFORCE_SYNC_SETTINGS_FORM_UNSAVED_NON_ADMIN',
};

export const DUPLICATES_OPTIONS = [
  {
    label:
      'web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.duplicatesOption.allRecords',
    value: 0,
  },
  {
    label:
      'web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.duplicatesOption.oldestRecords',
    value: 1,
  },
  {
    label:
      'web.settings.adminSettings.salesforce.syncSettings.logEmailActivity.duplicatesOption.mostActivity',
    value: 2,
  },
];

export const SYNC_TASKS_OPTIONS = [
  {
    label: 'web.settings.salesforce.syncTasksForm.label.complete',
    value: true,
  },
  {
    label: 'web.settings.salesforce.syncTasksForm.label.delete',
    value: false,
  },
];

export const DONT_SYNC_TASKS_OPTIONS = [
  {
    label: 'web.settings.salesforce.syncTasksForm.label.dontSync',
    value: false,
  },
];

export const SalesforceAlertIds = {
  bccAddressNotProvided: 'SALESFORCE_BCC_ADDRESS_NOT_PROVIDED',
  formChangesNotSaved: 'SALESFORCE_DATA_NOT_SAVED',
  formChangesSaved: 'SALESFORCE_DATA_SAVED_SUCCESSFULLY',
  somethingWentWrong: 'SALESFORCE_SOMETHING_WENT_WRONG',
};

export const SalesforceType = {
  salesforce: 'salesforce',
  salesforceSandbox: 'salesforce_sandbox',
};

export const MESSAGE_TYPE = {
  RELOAD_MARKETO_SALES_OUTBOX_IN_SFDC: 'reload_marketo_sales_outbox_in_sfdc',
};
