import { basePost, basePut, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export const createPeopleGroup = (name, description) => {
  const payload = {
    group: {
      description,
      name,
      team_ids: [],
    },
  };

  return new Promise((resolve, reject) => {
    basePost(Urls.groups, payload, resolve, reject);
  });
};

export const updatePeopleGroup = (id, group) =>
  new Promise((resolve, reject) => {
    basePut(Urls.groupId(id), { group }, resolve, reject);
  });

export const bulkAddPeopleToGroup = (groupId, peopleIds) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.groupMembers.replace(':#id', groupId),
      { address_ids: peopleIds, group_id: groupId },
      resolve,
      reject
    );
  });

export const deletePeopleGroup = (id) =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.groupId(id), {}, resolve, reject);
  });
