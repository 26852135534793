import { Regex } from 'web/libs/constants';

export const parseUrls = (releaseNotes = []) =>
  releaseNotes.map((note) => {
    let url;
    if (note.url) {
      url = Regex.websiteHttp.test(note.url) ? note.url : `https://${note.url}`;
    }

    return { ...note, url };
  });
