/* eslint-disable sort-keys */
import { retrieveHealthStatuses } from '../services/yourIntegrationsService';
import {
  ACTION_INITIALIZE_HEALTH_STATUSES,
  ACTION_HEALTH_STATUSES_LOAD_FAILED,
  ACTION_HEALTH_STATUSES_LOADED,
  SET_FETCHING,
} from '../libs/yourIntegrationsConstants';

const setFetching = (fetching) => ({
  type: SET_FETCHING,
  fetching,
});

function initStatusesLoading() {
  return { type: ACTION_INITIALIZE_HEALTH_STATUSES };
}

function setLoadedStatuses(payload) {
  return { payload, type: ACTION_HEALTH_STATUSES_LOADED };
}

function healthStatusesLoadFailed() {
  return { type: ACTION_HEALTH_STATUSES_LOAD_FAILED };
}

export function initializeHealthStatuses() {
  return (dispatch) => {
    dispatch(setFetching(true));
    dispatch(initStatusesLoading());
    return retrieveHealthStatuses()
      .then((statuses) => {
        dispatch(setFetching(false));
        dispatch(setLoadedStatuses(statuses));
        return statuses;
      })
      .catch(() => dispatch(healthStatusesLoadFailed()));
  };
}
