import MarketoSettingsActionTypes from '../libs/marketoSettingsActionTypes';
import { ConnectionStates } from '../libs/marketoSettingsConstants';

export function marketoAdminConnectionState(
  state = ConnectionStates.disconnected,
  { type = '' }
) {
  switch (type) {
    case MarketoSettingsActionTypes.getConnection:
      return ConnectionStates.gettingIntegration;
    case MarketoSettingsActionTypes.setup.connect:
      return ConnectionStates.connecting;
    case MarketoSettingsActionTypes.setup.disconnectFailed:
    case MarketoSettingsActionTypes.setup.connectSuccess:
      return ConnectionStates.connected;
    case MarketoSettingsActionTypes.setup.disconnect:
      return ConnectionStates.disconnecting;
    case MarketoSettingsActionTypes.getConnectionNull:
    case MarketoSettingsActionTypes.getConnectionFailed:
    case MarketoSettingsActionTypes.setup.alreadyExists:
    case MarketoSettingsActionTypes.setup.disconnectSuccess:
      return ConnectionStates.disconnected;
    case MarketoSettingsActionTypes.hasConnection:
      return ConnectionStates.hasConnection;
    case MarketoSettingsActionTypes.noSalesforceConnection:
      return ConnectionStates.noSalesforceConnection;
    default:
      return state;
  }
}

const connectionInfoInitialState = {
  engageUsers: { engageAdminCount: 0, engageSalesCount: 0 },
  munchkin: '',
};

export function marketoAdminConnectionInfo(
  state = connectionInfoInitialState,
  { type = '', connectionInfo = {} }
) {
  switch (type) {
    case MarketoSettingsActionTypes.connectionInfo:
      return connectionInfo;
    default:
      return state;
  }
}

export function marketoIntegrationFetched(
  state = false,
  { type = '', fetched = false }
) {
  switch (type) {
    case MarketoSettingsActionTypes.getConnectionFetched:
      return fetched;
    default:
      return state;
  }
}

export function marketoIntegrationFetching(
  state = false,
  { type = '', fetching = false }
) {
  switch (type) {
    case MarketoSettingsActionTypes.getConnection:
      return true;
    case MarketoSettingsActionTypes.getConnectionFetching:
      return fetching;
    default:
      return state;
  }
}

export function marketoWorkspacesFetched(
  state = false,
  { type = '', fetched = false }
) {
  switch (type) {
    case MarketoSettingsActionTypes.workspacesModal.getWorkspacesFetched:
      return fetched;
    default:
      return state;
  }
}

export function marketoWorkspacesFetching(
  state = false,
  { type = '', fetching = false }
) {
  switch (type) {
    case MarketoSettingsActionTypes.workspacesModal.getWorkspaces:
      return true;
    case MarketoSettingsActionTypes.workspacesModal.getWorkspacesFetching:
      return fetching;
    default:
      return state;
  }
}
