import SalesforceActionTypes from '../libs/salesforceActionTypes';
import {
  SalesforceTabs,
  SalesforceAlertIds,
} from '../libs/salesforceConstants';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { connectionsCustomizationsStartup } from './connectionsCustomizationsActionCreators';
import { syncSettingsStartup } from 'web/settings/accountSettings/salesforce/actionCreators/syncSettingsActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  getAdminSettings,
  updateAdminSettings,
} from 'web/user/actionCreators/userActionCreators';
import { getSalesforceOptions } from '../selectors/syncSettingsSelectors';
import { getSalesforceIntegration } from 'web/salesforce/actionCreators/salesforceActionCreators';
import isBoolean from 'lodash/isBoolean';
import { getContactIntegrations } from 'web/contactIntegrations/actionCreators/contactIntegrationsActionCreators';
import {
  getActivityDynamicFieldsSalesforce,
  getDynamicFields,
} from 'web/dynamicFields/actionCreators/dynamicFieldsActionCreators';
import { clearAlerts } from 'web/globalBannerAlerts/actionCreators/globalBannerAlertsActionCreators';
import { initializeHealthStatuses } from 'web/settings/myProfile/components/yourIntegrations/actionCreators/yourIntegrationsActionCreators';

export const setTab = (tab) => ({
  tab,
  type: SalesforceActionTypes.setTab,
});

export const setActivityDetailsCustomization = (
  activityDetailsCustomization
) => ({
  activityDetailsCustomization,
  type: SalesforceActionTypes.setActivityDetailsCustomization,
});

export const updateActivityCustomizationDetails = (
  salesforce_activity_details_customization
) => (dispatch) => {
  return dispatch(
    updateAdminSettings({
      admin_settings: {
        salesforce_activity_details_customization,
      },
    })
  )
    .then(() => dispatch(openViewAlert(SalesforceAlertIds.formChangesSaved)))
    .catch(() =>
      dispatch(openViewAlert(SalesforceAlertIds.formChangesNotSaved))
    );
};

export const salesforceStartup = ({ tab }) => (dispatch) => {
  const activeTab = Object.values(SalesforceTabs).includes(tab)
    ? tab
    : SalesforceTabs.connectionsCustomizations;
  dispatch(commonStartup());
  dispatch(setTab(activeTab));
  dispatch(clearAlerts());

  if (activeTab === SalesforceTabs.connectionsCustomizations) {
    dispatch(connectionsCustomizationsStartup());
    dispatch(initializeHealthStatuses());
  } else if (activeTab === SalesforceTabs.syncSettings) {
    dispatch(getDynamicFields());
    dispatch(getActivityDynamicFieldsSalesforce());
    dispatch(getAdminSettings());
    dispatch(syncSettingsStartup());
  }
};

export const updateSyncSettings = (params = {}) => (dispatch, getState) => {
  const previousParams = getSalesforceOptions(getState()); // backend workaround
  const updatedParams = {
    admin_settings: {
      salesforce: {
        ...previousParams,
        ...params,
      },
    },
  };
  return dispatch(updateAdminSettings(updatedParams))
    .then((result) => {
      dispatch(openViewAlert(SalesforceAlertIds.formChangesSaved));
      return result;
    })
    .catch((errors) => {
      dispatch(openViewAlert(SalesforceAlertIds.formChangesNotSaved));
      return errors;
    });
};

export const updateApiSyncSettings = (params = {}) => (dispatch, getState) => {
  const { api_settings: previousApiParams } = getSalesforceOptions(getState()); // backend workaround
  const { sync_tasks: syncTasks, ...otherNewParams } = params;
  const updatedParams = {
    api_settings: {
      ...previousApiParams,
      ...otherNewParams,
    },
  };
  if (isBoolean(syncTasks)) {
    updatedParams.sync_tasks = syncTasks;
  }
  return dispatch(updateSyncSettings(updatedParams)).then((result) => {
    dispatch(getContactIntegrations());
    dispatch(getSalesforceIntegration());
    return result;
  });
};

export const updateSyncSettingsByCheckbox = (isChecked = false, name = '') => (
  dispatch
) => dispatch(updateSyncSettings({ [name]: isChecked }));

export const updateApiSyncSettingsByCheckbox = (
  isChecked = false,
  name = ''
) => (dispatch) => dispatch(updateApiSyncSettings({ [name]: isChecked }));

export const logActivityViaApi = (isChecked = false, name = '') => (
  dispatch
) => {
  dispatch(updateApiSyncSettingsByCheckbox(isChecked, name)).then(() => {
    dispatch(getSalesforceIntegration());
  });
};

export const applyTeamSettings = (isChecked = false, name = '') => (
  dispatch
) => {
  dispatch(updateSyncSettingsByCheckbox(isChecked, name)).then(() => {
    dispatch(getContactIntegrations());
    dispatch(getSalesforceIntegration());
  });
};
