import BulkPopupIds from 'web/bulkUpdate/libs/popupIds';
import BulkUpdateOverlayIds from 'web/bulkUpdate/libs/popupOverlayIds';
import {
  openPopup,
  closePopup,
  setPopupOverlay,
} from 'web/popup/actionCreators/popupActionCreators';

export const openBulkUpdateProgressPopup = (modalTitle) => (dispatch) => {
  dispatch(openPopup(BulkPopupIds.progress, { modalTitle }));
};

export const showExitWarningOverlay = () => (dispatch) => {
  dispatch(setPopupOverlay(BulkUpdateOverlayIds.exitWarning));
};

export const closeOrShowOverlay = () => (dispatch, getState) => {
  const { bulkUpdateIsComplete } = getState();
  if (bulkUpdateIsComplete) {
    dispatch(closePopup());
  } else {
    dispatch(showExitWarningOverlay());
  }
};
