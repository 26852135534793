import cloneDeep from 'lodash/cloneDeep';

export const initMapIndex = { ids: [], map: {} };

export function resetMapAndIndex() {
  return cloneDeep(initMapIndex);
}

export function createMapAndIndex(array = [], propertyToIndex = 'id') {
  const mapIndex = { ids: [], map: {} };
  array.forEach((obj) => {
    mapIndex.ids.push(obj[propertyToIndex]);
    mapIndex.map[obj[propertyToIndex]] = obj;
  });
  return mapIndex;
}

export function bulkAddUpdateState(
  state = resetMapAndIndex(),
  array = [],
  propertyToIndex = 'id'
) {
  const newState = cloneDeep(state);

  array.forEach((obj) => {
    if (!newState.map.hasOwnProperty(obj[propertyToIndex])) {
      newState.ids.push(obj[propertyToIndex]);
    }
    newState.map[obj[propertyToIndex]] = obj;
  });
  return newState;
}

export function addUpdateState(
  state,
  data,
  propertyToIndex = 'id',
  unshift = false
) {
  const newState = cloneDeep(state);

  if (!newState.map.hasOwnProperty(data[propertyToIndex])) {
    if (unshift) {
      const newIds = [data[propertyToIndex]];
      newState.ids = newState.ids ? [...newIds, ...newState.ids] : newIds;
    } else {
      newState.ids.push(data[propertyToIndex]);
    }
  }
  newState.map[data[propertyToIndex]] = data;

  return newState;
}

export function bulkRemoveFromState(state = resetMapAndIndex(), array = []) {
  const newState = cloneDeep(state);

  array.forEach((index) => {
    if (newState.map.hasOwnProperty(index)) {
      Reflect.deleteProperty(newState.map, index);

      const indexFound = newState.ids.findIndex(
        (element) => `${element}` === `${index}`
      );
      if (indexFound > -1) {
        newState.ids = [
          ...newState.ids.slice(0, indexFound),
          ...newState.ids.slice(indexFound + 1),
        ];
      }
    }
  });
  return newState;
}

export function removeFromState(state, index) {
  const newState = cloneDeep(state);
  const indexFound = newState.ids.findIndex(
    (element) => `${element}` === `${index}`
  );
  if (indexFound > -1) {
    newState.ids = [
      ...newState.ids.slice(0, indexFound),
      ...newState.ids.slice(indexFound + 1),
    ];
    Reflect.deleteProperty(newState.map, index);
  }

  return newState;
}
