import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CampaignDayCard from '../campaignDayCard/campaignDayCard';
import Button from 'components/buttons/action';
import Icon from 'components/buttons/icon';
import { CampaignStepActions, DayCardTypes } from 'web/libs/constants';
import './campaignSetup.scss';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

class CampaignSetup extends Component {
  constructor() {
    super();
    this.state = { canScroll: false };
    this._debouncedPagination = debounce(this._togglePagination, 500);

    this.newCampaignDayCardId = 'newCampaignDayCard';
    this.CAMPAIGN_DAY_CARD_NEW_WIDTH = 300;
    this.PAGINATE_SIZE = 150;
    this.SMOOTH_SCROLL_SIZE = 10;
    this.LEFT_KEY_CODE = 37;
    this.RIGHT_KEY_CODE = 39;
  }

  componentDidMount() {
    this._togglePagination();
  }

  componentWillUnmount() {
    this._removePaginationEvents();
  }

  componentDidUpdate({ addingNewCampaignDay }) {
    if (this.props.addingNewCampaignDay !== addingNewCampaignDay) {
      this._togglePagination();
      if (this.props.addingNewCampaignDay) {
        this._setLeft();
      }
    }
  }

  render() {
    const {
      addingNewCampaignDay,
      attachments,
      actionCreators,
      campaignDayEditing,
      campaignDayLoading,
      campaignEditStates,
      campaignTemplates,
      ckeBodies,
      editingSteps,
      newDayNumber,
      selectedCampaign,
      timezone,
      timezones,
      userCanEditCampaigns,
    } = this.props;
    const { dayStepInfo, firstEmailStepId } = this._getDayStepInfo();

    return (
      <div className="campaignSetup">
        {dayStepInfo.map((element) => (
          <CampaignDayCard
            attachments={attachments}
            actionCreators={actionCreators}
            campaignEditStates={campaignEditStates}
            campaignTemplates={campaignTemplates}
            ckeBodies={ckeBodies}
            type={DayCardTypes.standardCard}
            day={element.day}
            dayEditingCount={campaignDayEditing[element.day] || 0}
            editingSteps={editingSteps}
            firstEmailStepId={firstEmailStepId}
            key={`campaign-steps-${element.day}`}
            loading={!!campaignDayLoading[element.day]}
            newDayNumber={newDayNumber}
            selectedCampaign={selectedCampaign}
            steps={element.steps}
            timezone={timezone}
            timezones={timezones}
            userCanEditCampaigns={userCanEditCampaigns}
          />
        ))}
        {addingNewCampaignDay || dayStepInfo.length === 0 ? (
          <CampaignDayCard
            type={
              dayStepInfo.length === 0
                ? DayCardTypes.defaultDayOneCard
                : DayCardTypes.newDayCard
            }
            actionCreators={actionCreators}
            campaignEditStates={campaignEditStates}
            campaignTemplates={campaignTemplates}
            id={this.newCampaignDayCardId}
            loading={!!campaignDayLoading[newDayNumber]}
            steps={[]}
            attachments={attachments}
            ckeBodies={ckeBodies}
            editingSteps={editingSteps}
            newDayNumber={newDayNumber}
            selectedCampaign={selectedCampaign}
            timezone={timezone}
            timezones={timezones}
            userCanEditCampaigns={userCanEditCampaigns}
          />
        ) : null}
        {userCanEditCampaigns ? (
          <Button
            color="blue"
            size="medium"
            onClick={actionCreators.addingNewDay}
            classes="add-day-btn"
            disabled={addingNewCampaignDay || dayStepInfo.length === 0}
          >
            <FormattedMessage id="web.campaigns.addADay" />
          </Button>
        ) : null}
        {this.state.canScroll ? (
          <Icon
            color="blue"
            classes="scroll-right-btn"
            onClick={this._onPaginate}
          />
        ) : null}
      </div>
    );
  }

  _getDayStepInfo = () => {
    const { selectedCampaign } = this.props;
    const dayStepInfo = [];

    const orderedSteps = sortBy(selectedCampaign.steps, ['day', 'order']);
    const days = uniq(orderedSteps.map((step) => step.day));
    const firstEmailStep =
      orderedSteps.find(
        (step) =>
          step.action === CampaignStepActions.email ||
          step.action === CampaignStepActions.emailTask
      ) || {};

    for (const day of days) {
      const dayStep = {};
      dayStep.day = day;
      dayStep.steps = orderedSteps.filter((step) => step.day === day);
      dayStepInfo.push(dayStep);
    }
    return { dayStepInfo, firstEmailStepId: firstEmailStep.id };
  };

  _smoothScrollLeft = (view, offset) => {
    if (offset < 0) {
      view.scrollLeft -= this.SMOOTH_SCROLL_SIZE; // eslint-disable-line no-param-reassign

      setTimeout(
        window.requestAnimationFrame(() =>
          this._smoothScrollLeft(view, offset + this.SMOOTH_SCROLL_SIZE)
        ),
        this.SMOOTH_SCROLL_SIZE / 2
      );
    }
  };

  _smoothScrollRight = (view, offset) => {
    if (offset > 0) {
      view.scrollLeft += this.SMOOTH_SCROLL_SIZE; // eslint-disable-line no-param-reassign

      setTimeout(
        window.requestAnimationFrame(() =>
          this._smoothScrollRight(view, offset - this.SMOOTH_SCROLL_SIZE)
        ),
        this.SMOOTH_SCROLL_SIZE / 2
      );
    }
  };

  _smoothScroll = (view, offset) => {
    offset < 0
      ? this._smoothScrollLeft(view, offset)
      : this._smoothScrollRight(view, offset);
  };

  _setLeft = () => {
    const scrollingDiv = document.getElementsByClassName(
      'campaign-tab-view'
    )[0];
    const newDayDiv = document.getElementById(this.newCampaignDayCardId);
    const offsetRight = newDayDiv.offsetLeft + this.CAMPAIGN_DAY_CARD_NEW_WIDTH;
    if (offsetRight > scrollingDiv.offsetWidth) {
      const newLeft =
        offsetRight - scrollingDiv.offsetWidth + scrollingDiv.scrollLeft;
      window.requestAnimationFrame(() => {
        scrollingDiv.scrollLeft = newLeft;
      });
    }
  };

  _handlePagination = (e) => {
    const view = document.getElementsByClassName('campaign-tab-view')[0];
    const tagName = e.target.tagName.toUpperCase();
    if (tagName !== 'INPUT' && tagName !== 'TEXTAREA') {
      if (e.keyCode === this.LEFT_KEY_CODE) {
        e.preventDefault();
        window.requestAnimationFrame(() =>
          this._smoothScroll(view, -this.PAGINATE_SIZE)
        );
      } else if (e.keyCode === this.RIGHT_KEY_CODE) {
        e.preventDefault();
        window.requestAnimationFrame(() =>
          this._smoothScroll(view, this.PAGINATE_SIZE)
        );
      }
    }
  };

  _addPaginationEvents = () => {
    this.setState({ canScroll: true });
    document.addEventListener('keydown', this._handlePagination);
    window.addEventListener('resize', this._debouncedPagination);
  };

  _removePaginationEvents = () => {
    this.setState({ canScroll: false });
    document.removeEventListener('keydown', this._handlePagination);
    window.removeEventListener('resize', this._debouncedPagination);
  };

  _togglePagination = () => {
    const tabView = document.getElementsByClassName('campaign-tab-view')[0];
    if (tabView) {
      const { scrollWidth, offsetWidth } = tabView;

      if (scrollWidth > offsetWidth) {
        this._addPaginationEvents();
      } else {
        this._removePaginationEvents();
      }
    }
  };

  _onPaginate = () => {
    this._smoothScroll(
      document.getElementsByClassName('campaign-tab-view')[0],
      this.PAGINATE_SIZE
    );
  };
}

CampaignSetup.propTypes = {
  addingNewCampaignDay: PropTypes.bool,
  attachments: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  campaignDayEditing: PropTypes.object.isRequired,
  campaignDayLoading: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  editingSteps: PropTypes.object,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedCampaign: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignSetup.defaultProps = {
  addingNewCampaignDay: undefined,
  editingSteps: undefined,
  newDayNumber: undefined,
  selectedCampaign: undefined,
  userCanEditCampaigns: true,
};

export default CampaignSetup;
