/*global Reflect*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';
import Sorter from 'libs/sortingUiComponents';
import File from 'components/file';
import InputText from 'components/inputText';
import Button from 'components/buttons/action';
import Checkbox from 'components/buttons/checkbox';
import Icon from 'components/buttons/icon';
import GlobalPopup from 'components/globalPopup';
import { sortingMap } from './contentChooserHelper';
import './contentChooser.scss';

class ContentChooser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedAll: false,
      contentOrganized: props.content,
      searchText: '',
      selectedContent: {},
      showDeleteModal: false,
      validButtons: false,
    };

    this._fileInputRef = null;
    this.nameHeader = props.intl.formatMessage({ id: 'common.name' });
    this.dateHeader = props.intl.formatMessage({ id: 'common.date' });
    this.sorter = new Sorter(sortingMap, 'date');
  }

  componentWillMount() {
    if (this.state.contentOrganized.length) {
      // keep from initial sorting if empty
      this.setState((prevState) => ({
        contentOrganized: this.sorter.sort(prevState.contentOrganized, 'date'),
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.content.length !== nextProps.content.length) {
      this.setState({
        contentOrganized: this.sorter.sortCurrentSettings(nextProps.content),
      });
    }

    if (this.props.uploads.length !== nextProps.uploads.length) {
      if (this.props.uploads.length > nextProps.uploads.length) {
        const selected = { ...this.state.selectedContent };
        const differences = difference(this.props.uploads, nextProps.uploads);
        differences.forEach((diff) => {
          const file = nextProps.content.find(
            (element) => element.reference === diff.reference
          );
          if (file) {
            selected[file.id] = true;
            this.setState({ selectedContent: selected });
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.uploads.length !== prevProps.uploads.length ||
      this.props.content.length !== prevProps.content.length
    ) {
      this._isValid();
    }
  }

  render() {
    const {
      intl: { formatMessage },
      uploadFiles,
      uploads,
    } = this.props;
    const {
      checkedAll,
      contentOrganized,
      searchText,
      selectedContent,
      showDeleteModal,
      validButtons,
    } = this.state;

    return (
      <div className="contentChooser">
        {showDeleteModal ? this._getPopup() : null}
        <div className="content-chooser-header">
          <InputText
            autoComplete="off"
            className="filters-search"
            id="content-search"
            onChange={this.handleSearch}
            placeholder={formatMessage({
              id: 'contentChooser.searchPlaceholder',
            })}
            toutUiStyle
            type="text"
            value={searchText}
          />
          <Icon
            classes="tout-icon-add"
            color="white"
            onClick={this.handleUploadFilesClick}
            title={formatMessage({ id: 'contentChooser.uploadFiles' })}
          >
            <input
              type="file"
              multiple
              className="hidden"
              ref={this._setFileInputRef}
              id="content"
              onChange={uploadFiles}
            />
          </Icon>
        </div>
        <div className="content-table">
          <div className="content-header">
            <Checkbox
              checked={checkedAll}
              name="all"
              onChange={this.checkAll}
            />
            <div className="content-titles">
              <div
                onClick={this._onHeaderClick}
                data-column="name"
                className={this.sorter.getArrowClass('name')}
              >
                {this.nameHeader}
              </div>
              <div
                className={`content-titles-date ${this.sorter.getArrowClass(
                  'date'
                )}`}
                onClick={this._onHeaderClick}
                data-column="date"
              >
                {this.dateHeader}
              </div>
            </div>
          </div>
          <div className="content-container">
            {uploads.map((file) => (
              <Checkbox
                checked
                classes="file-row"
                classesLabel="flex-fill"
                disabled
                key={`content-${file.reference}`}
                label={<File file={file} />}
                name={`${file.id}`}
                onChange={this.check}
              />
            ))}
            {contentOrganized.map((file) => (
              <Checkbox
                checked={selectedContent[file.id]}
                classes="file-row"
                classesLabel="flex-fill"
                key={`content-${file.id || file.reference}`}
                label={<File file={file} showDate />}
                name={`${file.id}`}
                onChange={this.check}
              />
            ))}
          </div>
        </div>
        <div className="footer">
          <Button
            color="gray"
            disabled={!validButtons}
            onClick={this.toggleDeleteModal}
          >
            <FormattedMessage id="common.delete" />
          </Button>
          <Button
            color="blue"
            disabled={!validButtons}
            onClick={this._onInsert}
          >
            <FormattedMessage id="common.insert" />
          </Button>
        </div>
      </div>
    );
  }

  _setFileInputRef = (input) => (this._fileInputRef = input);

  _filterContent(files, searchText) {
    if (searchText.length) {
      const text = searchText.toLowerCase();
      return files.filter(
        (file) => file.file_file_name.toLowerCase().search(text) > -1
      );
    }
    return files;
  }

  _onHeaderClick = (e) => {
    const { column } = e.target.dataset;
    this.setState((prevState) => ({
      contentOrganized: this.sorter.sort(prevState.contentOrganized, column),
    }));
  };

  checkAll = (checked) => {
    let nextState = {};
    const ids = this.state.contentOrganized.map((file) => `${file.id}`);
    if (checked) {
      ids.forEach((id) => {
        nextState[id] = true;
      });
    } else {
      nextState = { ...this.state.selectedContent };
      ids.forEach((id) => {
        Reflect.deleteProperty(nextState, id);
      });
    }

    this.setState(
      { checkedAll: checked, selectedContent: nextState },
      this._isValid
    );
  };

  check = (checked, id) => {
    const { selectedContent } = this.state;
    const nextState = { ...selectedContent };
    if (checked) {
      nextState[id] = true;
    } else {
      Reflect.deleteProperty(nextState, id);
    }

    this.setState(
      { checkedAll: false, selectedContent: nextState },
      this._isValid
    );
  };

  _onInsert = () => {
    const selectedArray = Object.keys(this.state.selectedContent);
    const indexedContent = keyBy(this.props.content, 'id');
    const files = selectedArray.map((id) => indexedContent[id]);

    this.props.contentInsert(files);
  };

  onDelete = () => {
    this.props.contentDelete(Object.keys(this.state.selectedContent));
    this.setState({ selectedContent: {} });
    this.toggleDeleteModal();
  };

  toggleDeleteModal = () => {
    const { showDeleteModal } = this.state;
    this.setState({ showDeleteModal: !showDeleteModal });
  };

  handleSearch = (e) => {
    const searchText = e.currentTarget.value || '';
    this.setState({
      contentOrganized: this._filterContent(this.props.content, searchText),
      searchText,
    });
  };

  _getPopup = () => (
    <GlobalPopup
      affirmTextId="common.yes"
      cancelTextId="common.cancel"
      onAffirm={this.onDelete}
      onCancel={this.toggleDeleteModal}
      onClose={this.toggleDeleteModal}
      outsideElemClick={this.toggleDeleteModal}
      refName="contentChooserGlobalPopup"
      titleId="contentChooser.deleteTitle"
      //eslint-disable-next-line react/no-children-prop
      children={<FormattedHTMLMessage id="contentChooser.deleteBody" />}
      inner
    />
  );

  handleUploadFilesClick = () => {
    this._fileInputRef.click();
  };

  _isValid() {
    const { uploads } = this.props;
    const { selectedContent } = this.state;

    this.setState({
      validButtons: Object.keys(selectedContent).length && uploads.length === 0,
    });
  }
}

ContentChooser.propTypes = {
  content: PropTypes.array.isRequired,
  contentDelete: PropTypes.func.isRequired,
  contentInsert: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  uploads: PropTypes.array.isRequired,
};

export default injectIntl(ContentChooser);
