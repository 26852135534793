import { BackgroundColors } from 'libs/constantsStyles';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';

export function getAlert(
  actionCreators,
  templatesAlert,
  templatesAlertValues = {}
) {
  const defaultAlert = {
    color: BackgroundColors.red,
    onClose: actionCreators.clearPopupAlert,
    textId: 'web.campaigns.genericApiError',
  };

  switch (templatesAlert) {
    case TemplateAlertIds.createTemplateError:
      return {
        ...defaultAlert,
        textId: 'web.templates.createTemplateError',
      };
    case TemplateAlertIds.asyncUpdateError:
      return {
        ...defaultAlert,
        textId: 'web.templates.updateTemplateError',
      };
    case TemplateAlertIds.createCategoryError:
      return {
        ...defaultAlert,
        textId: 'web.templates.createTemplateCategoryError',
      };
    case TemplateAlertIds.createDuplicateCategoryError:
      return {
        ...defaultAlert,
        textId: 'web.templates.createDuplicateTemplateCategoryError',
      };
    case TemplateAlertIds.templateLimitExceededError:
      return {
        ...defaultAlert,
        textId: 'web.templates.templateLimitExceededError',
        textValues: templatesAlertValues,
      };
    default:
      return null;
  }
}
