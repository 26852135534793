import React from 'react';
import { TasksTableColumns } from '../libs/tablesTasksConstants';
import PersonCell from 'table/modules/cells/personCell';
import TextCell from 'table/modules/cells/textCell';
import LinkCell from 'table/modules/cells/linkCell';
import { alphabetical } from 'table/modules/sorting';
import { Align } from 'table/modules/constants';
import { TaskType, TaskPriority } from 'libs/constantsShared';
import MarkDoneCell from 'web/tables/tasks/components/cells/markDoneCell';
import TypeCell from 'web/tables/tasks/components/cells/typeCell';
import PriorityCell from 'web/tables/tasks/components/cells/priorityCell';
import DueDateCell from 'web/tables/tasks/components/cells/dueDateCell';
import ActionsCell from 'web/tables/tasks/components/cells/actionsCell';
import { CampaignDetailsEnum } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';

const personSortArray = () => [
  alphabetical('parent_name'),
  alphabetical('title'),
  alphabetical('parent_email'),
  'id',
];

const companySortArray = () => [
  alphabetical('company'),
  alphabetical('parent_name'),
  alphabetical('parent_email'),
];

const parseTypes = (type) => {
  switch (type) {
    case TaskType.call:
      return 1;
    case TaskType.other:
      return 2;
    case TaskType.email:
      return 3;
    case TaskType.inMail:
      return 4;
    default:
      return 5;
  }
};

const typeSortArray = () => [
  (elem) => parseTypes(elem.remind_type),
  alphabetical('subject'),
];

const parsePriorities = (priority = '') => {
  const pri = priority.toLowerCase();
  switch (pri) {
    case TaskPriority.high:
      return 1;
    case TaskPriority.normal:
      return 2;
    case TaskPriority.low:
      return 3;
    default:
      return 4;
  }
};

const prioritySortArray = () => [
  (elem) => parsePriorities(elem.priority),
  alphabetical('subject'),
];

const dueDateSort = (rowData) => {
  const date = new Date(rowData.remind_at);
  return -date.getTime();
};

const personProxyComponent = (params) => {
  const {
    className,
    openPersonDetails,
    rowData: {
      person_id: personId,
      parent_name: parentName,
      parent_email: parentEmail,
      title: personSubTitle,
    },
  } = params;
  const personTitle = parentName || parentEmail;
  return (
    <PersonCell
      className={className}
      id={personId}
      openPersonDetails={openPersonDetails}
      subTitle={personSubTitle}
      title={personTitle}
    />
  );
};

const getColumn = (
  column,
  actionCreators,
  formatMessage,
  isDisabled,
  currentUser
) => {
  const isActionsDisabled = (rowData) =>
    isDisabled || (!!currentUser && rowData.user_id !== currentUser.id);
  switch (column) {
    case TasksTableColumns.markDone:
      return {
        align: Align.CENTER,
        id: TasksTableColumns.markDone,
        name: '',
        rowCell: {
          component: MarkDoneCell,
          metadata: {
            isActionsDisabled,
            markDone: actionCreators.taskDone,
            markUndone: actionCreators.taskUndone,
          },
        },
        width: 64,
      };
    case TasksTableColumns.person:
      return {
        id: TasksTableColumns.person,
        name: formatMessage({ id: 'common.name' }),
        rowCell: {
          component: personProxyComponent,
          metadata: {
            openPersonDetails: actionCreators.openPersonDetails,
          },
        },
        sorting: personSortArray(),
        width: 160,
      };
    case TasksTableColumns.company:
      return {
        id: TasksTableColumns.company,
        name: formatMessage({ id: 'web.campaigns.tasks.company' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'company',
          },
        },
        sorting: companySortArray(),
        width: 125,
      };
    case TasksTableColumns.subject:
      return {
        flex: true,
        id: TasksTableColumns.subject,
        name: formatMessage({ id: 'web.campaigns.tasks.subject' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'subject',
            wrapLines: 2,
          },
        },
        sorting: alphabetical('subject'),
        width: 125,
      };
    case TasksTableColumns.owner:
      return {
        flex: true,
        id: TasksTableColumns.owner,
        name: formatMessage({ id: 'web.campaigns.tasks.owner' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'owner',
          },
        },
        width: 125,
      };
    case TasksTableColumns.type:
      return {
        id: TasksTableColumns.type,
        name: formatMessage({ id: 'web.campaigns.tasks.type' }),
        rowCell: {
          component: TypeCell,
          metadata: {
            isActionsDisabled,
            onClick: actionCreators.taskAction,
          },
        },
        sorting: typeSortArray(),
        width: 225,
      };
    case TasksTableColumns.priority:
      return {
        id: TasksTableColumns.priority,
        initHide: true,
        name: formatMessage({ id: 'web.campaigns.tasks.priority' }),
        rowCell: {
          component: PriorityCell,
          metadata: {},
        },
        sorting: prioritySortArray(),
        width: 106,
      };
    case TasksTableColumns.due:
      return {
        id: TasksTableColumns.due,
        name: formatMessage({ id: 'web.campaigns.tasks.due' }),
        rowCell: {
          component: DueDateCell,
          metadata: {},
        },
        sorting: dueDateSort,
        width: 105,
      };
    case TasksTableColumns.actions:
      return {
        align: Align.CENTER,
        id: TasksTableColumns.actions,
        name: formatMessage({ id: 'common.actions' }),
        rowCell: {
          component: ActionsCell,
          metadata: {
            deleteTask: actionCreators.taskDelete,
            isActionsDisabled,
            markSuccess: actionCreators.taskSuccess,
            openEdit: actionCreators.openTask,
            skip: actionCreators.taskSkip,
          },
        },
        width: 150,
      };
    case TasksTableColumns.campaign:
      return {
        id: TasksTableColumns.campaign,
        name: formatMessage({ id: 'web.campaigns.tasks.campaign' }),
        rowCell: {
          component: LinkCell,
          metadata: {
            formatText: (data) =>
              (data.workflow_details && data.workflow_details.name) || '',
            onClick: (data) =>
              actionCreators.openCampaign(
                data.workflow_details,
                CampaignDetailsEnum.tasks.value,
                data.parent_name
              ),
          },
        },
        width: 160,
      };
    default:
      return null;
  }
};

export const getColumns = (
  columns,
  actionCreators,
  formatMessage,
  isActionsDisabled,
  currentUser
) =>
  columns.map((column) =>
    getColumn(
      column,
      actionCreators,
      formatMessage,
      isActionsDisabled,
      currentUser
    )
  );
