import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { FormattedMessage } from 'react-intl';
import { ADD_MEMBERS_TO_TEAM_FORM_ID } from '../../../libs/teamMembersConstants';
import AddMembersToTeamForm from './addMembersToTeamForm';

class AddMembersToTeamPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      addMembersToTeamLoading,
      defaultAlert,
      isInvalidAddMembersToTeamForm,
    } = this.props;

    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.submitForm,
          onSecondary: closePopup,
          primaryTextId: 'common.add',
          secondaryTextId: 'common.cancel',
          primaryDisabled: isInvalidAddMembersToTeamForm,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.teamManagement.teamMembers.addToTeam',
        }}
        getImageUrl={getImageUrl}
        loading={addMembersToTeamLoading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedMessage id="web.settings.adminSettings.teamManagement.teamMembers.addMembersToTeam.title" />
        <AddMembersToTeamForm
          form={ADD_MEMBERS_TO_TEAM_FORM_ID}
          addTeamMembersToTeam={this.addTeamMembersToTeam}
          {...this.props}
        />
      </Popup>
    );
  }

  addTeamMembersToTeam = ({ teamMembers }) => {
    const {
      actionCreators: { addTeamMembersToTeam },
      popupData: { teamId },
    } = this.props;

    addTeamMembersToTeam(teamId, teamMembers);
  };

  submitForm = () => {
    const {
      actionCreators: { submitForm },
    } = this.props;
    submitForm(ADD_MEMBERS_TO_TEAM_FORM_ID);
  };
}

AddMembersToTeamPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  defaultAlert: PropTypes.object,
  addMembersToTeamLoading: PropTypes.bool.isRequired,
  isInvalidAddMembersToTeamForm: PropTypes.bool.isRequired,
};

AddMembersToTeamPopup.defaultProps = {
  defaultAlert: null,
};

export default AddMembersToTeamPopup;
