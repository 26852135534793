import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import SlideOutPane, {
  PaneBackgroundColors,
} from 'components/layouts/slideOutPane';
import SlideOutPaneActionBar from 'components/layouts/slideOutPane/actionBar';
import { EditIdentitySlideOutWidth } from '../../libs/editIdentitySlideOutConstants';
import EditIdentityTabContainer from '../../containers/editIdentityTabContainer';
import './editIdentitySlideOut.scss';

class EditIdentitySlideOut extends Component {
  render() {
    const { isOpen, onBack } = this.props;

    return (
      <SlideOutPane
        backgroundColor={PaneBackgroundColors.white}
        className="edit-identity-slide-out"
        open={isOpen}
        width={EditIdentitySlideOutWidth}
      >
        <SlideOutPaneActionBar onBack={onBack} />
        <EditIdentityTabContainer />
      </SlideOutPane>
    );
  }
}

EditIdentitySlideOut.propTypes = {
  intl: intlShape.isRequired,
  onBack: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default injectIntl(EditIdentitySlideOut);
