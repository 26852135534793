/**
 *
 * Pagination
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Square, { SquareColors } from 'components/buttons/square';
import './pagination.scss';

class Pagination extends PureComponent {
  render() {
    const {
      currentPage,
      itemsLength,
      perPage,
      unknownTotal,
      width,
    } = this.props;
    const total = this.getTotal();
    const unknownTotalEnd = unknownTotal && itemsLength < perPage;

    const styles = {};
    if (width) {
      styles.width = `${width}px`;
    }
    //intl
    return (
      <div className="tout-pagination" style={styles}>
        <Square
          className="pagination-btn pagination-btn-start"
          color={SquareColors.transparent}
          disabled={currentPage === 1}
          onClick={this.onClickStart}
        />
        <Square
          className="margin-right-half pagination-btn pagination-btn-left"
          color={SquareColors.transparent}
          disabled={currentPage === 1}
          onClick={this.onClickPrev}
        />
        <FormattedHTMLMessage
          id="toutTable.pagination.pageText"
          values={{ currentPage, total }}
        />
        <Square
          className="margin-left-half pagination-btn pagination-btn-right"
          color={SquareColors.transparent}
          disabled={unknownTotalEnd || currentPage === total}
          onClick={this.onClickNext}
        />
        <Square
          className="margin-left-half pagination-btn pagination-btn-end"
          color={SquareColors.transparent}
          disabled={unknownTotalEnd || currentPage === total}
          onClick={this.onClickEnd}
        />
      </div>
    );
  }

  getTotal() {
    const { unknownTotal, totalPages } = this.props;
    return unknownTotal ? 0 : totalPages;
  }

  onClickStart = () => {
    const { onClick, scrollTop } = this.props;
    onClick(1);
    scrollTop();
  };

  onClickEnd = () => {
    const { onClick, scrollTop } = this.props;
    onClick(this.getTotal());
    scrollTop();
  };

  onClickPrev = () => {
    const { currentPage, onClick, scrollTop } = this.props;
    onClick(currentPage - 1);
    scrollTop();
  };

  onClickNext = () => {
    const { currentPage, onClick, scrollTop } = this.props;
    onClick(currentPage + 1);
    scrollTop();
  };
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  itemsLength: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  scrollTop: PropTypes.func,
  totalPages: PropTypes.number,
  unknownTotal: PropTypes.bool,
  width: PropTypes.number,
};

Pagination.defaultProps = {
  currentPage: 0,
  itemsLength: 0,
  scrollTop: () => {},
  totalPages: 0,
  unknownTotal: false,
};

export default Pagination;
