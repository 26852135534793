import { createSelector } from '@reduxjs/toolkit';

export const getSmartFiltersValues = (state) => state.smartFilters;

const getPropsCollectionId = (state, props) => props.collectionId;
const getPropsFilterId = (state, props) => props.filterId;

export const getSmartFiltersCollection = createSelector(
  [getSmartFiltersValues, getPropsCollectionId],
  (smartFilters, collectionId) =>
    (smartFilters && smartFilters[collectionId]) || undefined
);

export const getSmartFilter = createSelector(
  [getSmartFiltersCollection, getPropsFilterId],
  (collection, filterId) => (collection && collection[filterId]) || undefined
);
