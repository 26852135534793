import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './percentageCell.scss';

class PercentageCell extends PureComponent {
  render() {
    const percentage = this.getPercentage();

    return <div className="percentage-cell">{`${percentage}%`}</div>;
  }

  getPercentage = () => {
    const { rowData, percentageProperty } = this.props;
    const percentage = rowData[percentageProperty] || 0;
    return Math.min(percentage, 100);
  };
}

PercentageCell.propTypes = {
  percentageProperty: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default PercentageCell;
