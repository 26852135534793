import {
  navigateToComposeWindow,
  navigateToLinkedIn,
} from 'web/services/routerService';
import { getPersonById } from 'web/people/services/peopleService';
import { getPrimaryPhone } from 'web/shared/services/peopleService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { PRIMARY_EMAIL_ARGUMENT } from 'web/tasks/libs/tasksConstants';

const defaultSetLoadingAC = () => ({ type: '' });

export function openEmail(task = {}) {
  return () => {
    let templateId = '';
    let campaignId = '';
    if (
      task.workflow_details &&
      task.workflow_details.step &&
      task.workflow_details.step.action_params &&
      task.workflow_details.step.action_params.template_id
    ) {
      templateId = task.workflow_details.step.action_params.template_id;
      campaignId = task.workflow_details.workflow_id;
    }
    navigateToComposeWindow(
      task.person_id,
      PRIMARY_EMAIL_ARGUMENT,
      templateId,
      task.id,
      campaignId
    );
  };
}

export function openCall(task = {}, setLoadingAC = defaultSetLoadingAC) {
  return (dispatch) => {
    dispatch(setLoadingAC(true));
    getPersonById(task.person_id)
      .then((person) => {
        const phone = getPrimaryPhone(person) || {};
        toutBackboneHelper.openToutPhone({
          person,
          number: phone.address,
          reminderId: task.id,
        });
        dispatch(setLoadingAC(false));
      })
      .catch(() => {
        toutBackboneHelper.openToutPhone({ reminderId: task.id });
        dispatch(setLoadingAC(false));
      });
  };
}

export function openInMail(task = {}) {
  return () => {
    const name = task.parent_name || '';
    getPersonById(task.person_id).then((value) => {
      if (!value.social || !value.social.linkedin) {
        navigateToLinkedIn(name.replace(' ', '+'));
      } else {
        window.open(value.social.linkedin);
      }
    });
  };
}
