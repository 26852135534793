import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert, { AlertColors } from 'components/alert';
import { DialerSettingsAlertIds } from '../../libs/dialerConstants';
import { hideSettingsAlert } from '../../actionCreators/dialerSettingsActionCreators';

class DialerSettingsAlert extends PureComponent {
  render() {
    const alert = this.getAlert();
    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const { alertId } = this.props;
    switch (alertId) {
      case DialerSettingsAlertIds.changesSaved:
        return {
          color: AlertColors.greenDark,
          onClose: this.onClose,
          textId: 'common.alert.success',
        };
      case DialerSettingsAlertIds.changesNotSaved:
        return {
          color: AlertColors.red,
          onClose: this.onClose,
          textId: 'common.alert.failure',
        };
      case DialerSettingsAlertIds.enableCallRecording:
        return {
          color: AlertColors.blue,
          onClose: this.onClose,
          textId:
            'web.settings.adminSettings.dialerSettings.enableCallRecording',
        };
      case DialerSettingsAlertIds.noCallOutcomesConfigured:
        return {
          color: AlertColors.red,
          onClose: this.onClose,
          textId:
            'web.settings.adminSettings.dialerSettings.noCallOutcomesConfigured',
        };
      case DialerSettingsAlertIds.noCallReasonsConfigured:
        return {
          color: AlertColors.red,
          onClose: this.onClose,
          textId:
            'web.settings.adminSettings.dialerSettings.noCallReasonsConfigured',
        };
      case DialerSettingsAlertIds.somethingWentWrong:
        return {
          color: AlertColors.red,
          onClose: this.onClose,
          textId: 'common.alert.somethingWentWrong',
        };
      default:
        return null;
    }
  };

  onClose = () => {
    this.props.dispatch(hideSettingsAlert());
  };
}

const mapStateToProps = (state) => ({
  alertId: state.dialerSettingsAlertId,
});

export default connect(mapStateToProps)(DialerSettingsAlert);

DialerSettingsAlertIds.propTypes = {
  alertId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};
