import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from 'components/layouts/page';
import Cards, { Direction } from 'components/layouts/cards';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import PageView from 'components/layouts/page/view';
import EmailConnectionCard from 'web/settings/emailConnection/components/emailConnectionCard';
import GmailIntegrationCard from 'web/settings/emailConnection/components/gmailIntegrationCard';
import './emailConnection.scss';

class EmailConnection extends Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.cards = [EmailConnectionCard];
  }

  getCards = () =>
    this.cards.map((cardComponent, index) => ({
      id: index,
      children: React.createElement(cardComponent, { ...this.props }),
    }));

  render() {
    return (
      <Page className="email-connection">
        <PageView className="email-connection-page-view">
          <Cards
            backgroundColor={BackgroundColors.grayExtraLight}
            className="email-connection-cards-container"
            cards={this.getCards()}
            direction={Direction.horizontal}
            marginAround={FULL}
            marginBetween={FULL}
          />
        </PageView>
      </Page>
    );
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
  };
}

export default EmailConnection;
