import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { open as openEditTemplate } from 'web/slideOuts/editTemplate/actionCreators/editTemplateExternalActionCreators';
import { initPusher } from './templatesPusherActionCreators';
import { openCreateTemplatePopup } from 'web/templates/actionCreators/templatesPopupActionCreators';
import { EditTemplateTabs } from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import { getFormattedFilterItems } from 'web/templates/selectors/templatesSelectors';
import { setTemplatesViewer } from 'web/templates/actionCreators/templatesViewerActionCreators';
import {
  filterByCategory,
  getSharableCategories,
  initTemplateCategories,
} from 'web/templates/actionCreators/templateCategoriesActionCreators';
import { setTablePerPage } from './templatesTableActionCreators';
import { tablePerPageFromStorage } from 'web/services/storageService';

function showTemplate(id, isEditing) {
  return (dispatch) => {
    if (id != null) {
      dispatch(openEditTemplate({ id }, EditTemplateTabs.template, isEditing));
    }
  };
}

const initTemplatesViewer = () => (dispatch, getState) => {
  const state = getState();
  const { user } = state;
  const { admin, id } = user;

  if (admin) {
    const filterItems = getFormattedFilterItems(state);
    const { label, value } =
      filterItems.find(({ value: filterValue }) => filterValue === id) || {};
    dispatch(setTemplatesViewer({ id: value, name: label }));
  } else {
    dispatch(setTemplatesViewer(user));
  }
};

const initSharableCategories = () => (dispatch) => {
  dispatch(getSharableCategories());
};

const showCreateTemplate = (isCreating) => (dispatch) => {
  if (isCreating) {
    dispatch(openCreateTemplatePopup());
  }
};

const initStore = () => ({
  type: TemplatesActionTypes.store.init,
});

export function templatesStartup(
  categoryId,
  templateId,
  isEditing,
  isCreating
) {
  return (dispatch, getState) => {
    dispatch(commonStartup());
    dispatch(showCreateTemplate(isCreating));
    dispatch(showTemplate(templateId, isEditing));
    dispatch(setPerPageFromLocalStorage());

    if (!getState().templatesInitStore) {
      dispatch(initPusher());
      dispatch(initTemplatesViewer());

      // Data that isn't immediately needed
      setTimeout(() => {
        dispatch(initTemplateCategories(categoryId));
        dispatch(initSharableCategories());
        dispatch(initStore());
      }, 0);
    } else {
      dispatch(filterByCategory(categoryId));
    }
  };
}

const setPerPageFromLocalStorage = () => (dispatch) => {
  const perPageFromStorage = tablePerPageFromStorage({ withDefault: false });
  if (perPageFromStorage) {
    dispatch(setTablePerPage(perPageFromStorage));
  }
};
