import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampaignsUsingTemplateTable from 'web/campaignsUsingTemplate/components/campaignsUsingTemplateTable';
import { injectIntl, intlShape } from 'react-intl';

class DeleteParentTemplate extends Component {
  render() {
    const {
      intl: { formatMessage },
      actionCreators,
      activeCampaignTemplates,
    } = this.props;
    return (
      <div>
        <div className="margin-top-half">
          {formatMessage({
            id: 'web.campaigns.deleteParentTemplateDescription',
          })}
        </div>
        <div className="margin-top-full margin-bottom-full">
          {formatMessage({ id: 'web.campaignsUsingTemplate.instructions' })}
        </div>
        <CampaignsUsingTemplateTable
          actionCreators={actionCreators}
          activeCampaignTemplates={activeCampaignTemplates}
          isPopup
        />
      </div>
    );
  }
}

DeleteParentTemplate.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  activeCampaignTemplates: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(DeleteParentTemplate);
