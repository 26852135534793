import React, { useEffect, useState } from 'react';
import Tab from '../tab';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './tabs.scss';

const Tabs = (props) => {
  const { children, changeTab, active, setActive } = props;
  const [tabHeader, setTabHeader] = useState([]);
  const [childContent, setChildContent] = useState({});

  useEffect(() => {
    const headers = [];
    const childCnt = {};

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;

      const { name } = element.props;
      headers.push(name);
      childCnt[name] = element.props.children;
    });

    setTabHeader(headers);
    if (!active) {
      setActive(headers[0]);
    }
    setChildContent({ ...childCnt });
  }, []);

  return (
    <div className="productTour-tabs">
      <div className="productTour-tab-content">
        {Object.keys(childContent).map((key) => {
          if (key === active) {
            return <div className="tab-child">{childContent[key]}</div>;
          } else {
            return null;
          }
        })}
      </div>
      <div className="productTour-tab-header">
        {tabHeader.map((item, i) => (
          <span
            className={classNames('productTour-tab-icon', {
              active: item === active,
            })}
            key={`${item}_${i}`}
            onClick={() => changeTab(item)}
          />
        ))}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  active: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  children: (props, propName, componentName) => {
    const prop = props[propName];

    let error = null;
    React.Children.forEach(prop, (child) => {
      if (child.type !== Tab) {
        error = new Error(`${componentName} children should be of type Tab`);
      }
    });
    return error;
  },
  setActive: PropTypes.func.isRequired,
};

export default Tabs;
