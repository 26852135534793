import UserActionTypes from '../libs/userActionTypes';
import {
  getUser as getUserCall,
  getUserMetric as getUserMetricCall,
  updateSubscriptionSettings as updateSubscriptionSettingsCall,
  getAdminSettings as getAdminSettingsCall,
  updateAdminSettings as updateAdminSettingsCall,
  getUserSettings as getUserSettingsService,
  updateUserSettings as updateUserSettingsService,
} from '../services/userService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { setActivityDetailsCustomization } from 'web/settings/adminSettings/salesforce/actionCreators/salesforceActionCreators';

export const setUser = (user) => ({
  type: UserActionTypes.setUser,
  user,
});

export const setAdminEmailInvitation = (email) => ({
  email,
  type: UserActionTypes.setAdminEmailInvitation,
});

export const setUserSubscriptionFetching = (isFetching = false) => ({
  isFetching,
  type: UserActionTypes.setUserSubscriptionFetching,
});

export const setAdminSettingsFetching = (isFetching = false) => ({
  isFetching,
  type: UserActionTypes.setAdminSettingsFetching,
});

export const getUser = (id) => (dispatch, getState) => {
  const userId = id || getState().user.id;
  return getUserCall(userId).then((user) => {
    dispatch(setUser(user));
    toutBackboneHelper.updateAccount(user);
  });
};

export const updateSubscriptionSettings = (params = {}) => (dispatch) => {
  dispatch(setUserSubscriptionFetching(true));
  return updateSubscriptionSettingsCall(params)
    .then(() => dispatch(getUser()))
    .finally(() => {
      dispatch(setUserSubscriptionFetching(false));
    });
};

export const getAdminSettings = () => async (dispatch) => {
  dispatch(setAdminSettingsFetching(true));
  try {
    const {
      salesforce_activity_details_customization,
    } = await getAdminSettingsCall();
    dispatch(
      setActivityDetailsCustomization(salesforce_activity_details_customization)
    );
  } finally {
    dispatch(setAdminSettingsFetching(false));
  }
};

export const updateAdminSettings = (params = {}) => (dispatch) => {
  dispatch(setAdminSettingsFetching(true));
  return updateAdminSettingsCall(params)
    .then(({ salesforce_activity_details_customization }) => {
      dispatch(
        setActivityDetailsCustomization(
          salesforce_activity_details_customization
        )
      );
      dispatch(getUser());
    })
    .finally(() => {
      dispatch(setAdminSettingsFetching(false));
    });
};

export const setUserMetric = (userMetric) => ({
  type: UserActionTypes.setUserMetric,
  userMetric,
});

export const getUserMetric = () => (dispatch) =>
  getUserMetricCall().then((userMetric) => {
    dispatch(setUserMetric(userMetric));
  });

export const setUserSettings = (userSettings) => ({
  type: UserActionTypes.setUserSettings,
  userSettings,
});

export const getUserSettings = () => (dispatch) =>
  getUserSettingsService().then((userSettings) =>
    dispatch(setUserSettings(userSettings))
  );

export const updateUserSettings = (settings) => (dispatch) => {
  updateUserSettingsService(settings).then((userSettings) =>
    dispatch(setUserSettings(userSettings))
  );
};

export const updateOnboardingSettings = (stepName) => (dispatch, getState) => {
  const userSettings = getState().userSettings;
  const { passedOnboardingSteps } = userSettings;

  dispatch(
    updateUserSettings({
      ...userSettings,
      passedOnboardingSteps: passedOnboardingSteps
        ? [...new Set(passedOnboardingSteps.concat(stepName))]
        : [stepName],
    })
  );
};

export const changeColumnsSettings = (
  columnsSettingsTableId,
  columnsSettings
) => (dispatch) => {
  dispatch(updateUserSettings({ [columnsSettingsTableId]: columnsSettings }));
};
