import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon, { IconColors } from 'components/buttons/icon';
import Chevron, {
  ChevronColors,
  ChevronDirections,
} from 'components/buttons/chevron';
import './anchorBoxHeaderButtons.scss';

class AnchorBoxHeaderButtons extends Component {
  render() {
    const { close } = this.props;

    return (
      <div className="anchor-box-header-buttons">
        {this._getChevron()}
        <Icon
          color={IconColors.white}
          className={classNames('anchor-box-header-buttons-close-icon')}
          onClick={close}
        />
        <div
          className={classNames(
            'tout-icon-close',
            'anchor-box-header-buttons-close'
          )}
          onClick={close}
        />
      </div>
    );
  }

  _getChevron = () => {
    const { chevronColor, contract, expand, open } = this.props;

    const { top, bottom } = ChevronDirections;
    const direction = open ? bottom : top;
    const chevronAction = open ? contract : expand;
    return (
      <Chevron
        color={chevronColor}
        direction={direction}
        onClick={chevronAction}
      />
    );
  };
}

AnchorBoxHeaderButtons.propTypes = {
  chevronColor: PropTypes.oneOf(Object.values(ChevronColors)),
  close: PropTypes.func.isRequired,
  contract: PropTypes.func.isRequired,
  expand: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

AnchorBoxHeaderButtons.defaultProps = {
  chevronColor: ChevronColors.white,
  open: false,
};

export default AnchorBoxHeaderButtons;
