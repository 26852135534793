import { createSelector } from '@reduxjs/toolkit';

import { getSmartFilter } from 'web/tables/smartFilters/selectors/smartFiltersSelectors';
import { dateRangeSmartFilterID } from 'web/tables/dateRangeSmartFilter/libs/dateRangeSmartFilterConstants';

import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';

const dateRangeSmartFilters = (state) => state.dateRangeSmartFilters;
const getSyncKey = (state, props) => props.syncKey;

const getDateRangeSmartFilter = (state, props) => {
  const collectionId =
    (props && props.collectionId) || getActiveEmailTableId(state);
  return getSmartFilter(state, {
    collectionId,
    filterId: dateRangeSmartFilterID,
  });
};

export const getDateRange = createSelector(
  [getDateRangeSmartFilter],
  (dateRangeSmartFilter) =>
    (dateRangeSmartFilter && {
      end: dateRangeSmartFilter.end,
      start: dateRangeSmartFilter.start,
    }) ||
    {}
);

export const getSelectedValue = createSelector(
  [getDateRangeSmartFilter],
  (dateRangeSmartFilter) =>
    (dateRangeSmartFilter && dateRangeSmartFilter.selectedValue) || ''
);

export const getIsCustomDateChanged = createSelector(
  [dateRangeSmartFilters, getSyncKey],
  (dateRangeSmartFilters, syncKey) =>
    (dateRangeSmartFilters &&
      syncKey &&
      dateRangeSmartFilters[syncKey] &&
      dateRangeSmartFilters[syncKey].isCustomDateChanged) ||
    false
);
