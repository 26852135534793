import CampaignsActionTypes from 'web/marketo/campaigns/libs/marketoCampaignsActionTypes';

export const initMarketoCampaignsState = {};
export const marketoCampaigns = (
  state = initMarketoCampaignsState,
  { type = '', marketoCampaigns: campaigns = [], marketoWorkspaceName }
) => {
  switch (type) {
    case CampaignsActionTypes.campaigns.set:
      if (marketoWorkspaceName) {
        return {
          [marketoWorkspaceName]: campaigns,
          ...state,
        };
      }

      return state;
    default:
      return state;
  }
};
