import SalesforceCustomizationActionTypes from 'web/modals/salesforceCustomization/libs/salesforceCustomizationActionTypes';
import {
  openSalesforceProgressPopup,
  openSalesforcePostUpdatePopup,
  closeSalesforcePopup,
} from './salesforceCustomizationPopupActionCreators';
import {
  SalesforceCustomizationPopupIds,
  SalesforceCustomizationStatusIds,
  SalesforceCustomizationErrorsIds,
  CUSTOMIZATION_STATUS_NOT_CONNECTED,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import {
  salesforceCustomizationInstall,
  salesforceCustomizationUninstall,
  salesforceCustomizationStatus,
  salesforceCustomizationProfiles,
} from 'web/modals/salesforceCustomization/services/salesforceCustomizationService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import get from 'lodash/get';

export function customizationCommonErrorHandler(err = {}) {
  const errorMessage = get(
    err,
    'response.body.description',
    SalesforceCustomizationErrorsIds.apiError
  );

  return {
    type: SalesforceCustomizationActionTypes.errors.set,
    description: errorMessage,
  };
}

export function customizationProfilesCall() {
  return (dispatch) =>
    salesforceCustomizationProfiles()
      .then(({ profiles = [] }) => {
        dispatch({
          type: SalesforceCustomizationActionTypes.profiles.availableUsersSet,
          availableUsers: profiles,
        });
      })
      .catch((err = {}) => {
        dispatch(customizationCommonErrorHandler(err));
        dispatch(closeSalesforcePopup());
      });
}

export function customizationApiCall({ apiCall, installation, params }) {
  return (dispatch) =>
    apiCall(params).catch((err = {}) => {
      dispatch(customizationCommonErrorHandler(err));

      if (installation) {
        dispatch({
          type: SalesforceCustomizationActionTypes.status.set,
          status: SalesforceCustomizationStatusIds.notInstalled,
        });
        dispatch(
          openSalesforcePostUpdatePopup(
            SalesforceCustomizationPopupIds.salesforceCustomizationPostInstall
          )
        );
      } else {
        dispatch({
          type: SalesforceCustomizationActionTypes.status.set,
          status: SalesforceCustomizationStatusIds.installed,
        });
        dispatch(
          openSalesforcePostUpdatePopup(
            SalesforceCustomizationPopupIds.salesforceCustomizationPostUninstall
          )
        );
      }
    });
}

export function customizationStatusCall() {
  return (dispatch) => {
    dispatch({
      type: SalesforceCustomizationActionTypes.status.clear,
    });
    dispatch({
      type: SalesforceCustomizationActionTypes.errors.clear,
    });

    return salesforceCustomizationStatus()
      .then(({ details = {} }) => {
        const { status, available_new_versions, installed_versions } = details;

        dispatch({
          type: SalesforceCustomizationActionTypes.status.set,
          status,
        });

        dispatch({
          type: SalesforceCustomizationActionTypes.versions.clear,
        });
        dispatch({
          type: SalesforceCustomizationActionTypes.availableVersions.set,
          availableVersions: available_new_versions,
        });
        dispatch({
          type: SalesforceCustomizationActionTypes.installedVersions.set,
          installedVersions: installed_versions,
        });

        toutBackboneHelper.viewStatus({ complete: true });
      })
      .catch(({ response: { statusCode } }) => {
        if (statusCode === CUSTOMIZATION_STATUS_NOT_CONNECTED) {
          dispatch({
            type: SalesforceCustomizationActionTypes.status.set,
            status: SalesforceCustomizationStatusIds.notConnected,
          });
        } else {
          dispatch({
            type: SalesforceCustomizationActionTypes.status.set,
            status: SalesforceCustomizationStatusIds.needStatusInfo,
          });
          dispatch(customizationCommonErrorHandler());
        }

        toutBackboneHelper.viewStatus({ complete: true });
      });
  };
}

export function managePackage(installation = true, params = {}) {
  return (dispatch) => {
    const popupId = installation
      ? SalesforceCustomizationPopupIds.salesforceCustomizationInstallation
      : SalesforceCustomizationPopupIds.salesforceCustomizationUninstallation;

    dispatch(openSalesforceProgressPopup(popupId));

    if (installation) {
      dispatch({
        type: SalesforceCustomizationActionTypes.status.set,
        status: SalesforceCustomizationStatusIds.runningInstall,
      });
      dispatch(
        customizationApiCall({
          apiCall: salesforceCustomizationInstall,
          installation,
          params,
        })
      );
    } else {
      dispatch({
        type: SalesforceCustomizationActionTypes.status.set,
        status: SalesforceCustomizationStatusIds.runningUninstall,
      });
      dispatch(
        customizationApiCall({
          apiCall: salesforceCustomizationUninstall,
          installation,
        })
      );
    }
  };
}

export function setDefaultProcessValues() {
  return (dispatch) => {
    dispatch({
      type: SalesforceCustomizationActionTypes.progress.clear,
    });
    dispatch({
      type: SalesforceCustomizationActionTypes.errors.clear,
    });
    dispatch({
      type: SalesforceCustomizationActionTypes.completedVersions.clear,
    });
  };
}
