import { SalesforceCustomizationStatusIds } from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import {
  USERS_SPECIFIC,
  USERS_ADMINS,
  USERS_ADMINS_VALUE,
} from '../libs/salesforceCustomizationConstants';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';

export function isInstalledStatus(status = '') {
  return status === SalesforceCustomizationStatusIds.installed;
}

export function isNotInstalledStatus(status = '') {
  return status === SalesforceCustomizationStatusIds.notInstalled;
}

export function isPackageInstalledStatus(status = '') {
  return status === SalesforceCustomizationStatusIds.packageInstalled;
}

export function isRunningInstallStatus(status = '') {
  return status === SalesforceCustomizationStatusIds.runningInstall;
}

export function isRunningUninstallStatus(status = '') {
  return status === SalesforceCustomizationStatusIds.runningUninstall;
}

export function isProcessingStatus(status = '') {
  return (
    status === SalesforceCustomizationStatusIds.runningInstall ||
    status === SalesforceCustomizationStatusIds.runningUninstall
  );
}

export function isSalesforceNotConnected(status = '') {
  return status === SalesforceCustomizationStatusIds.notConnected;
}

export function isStatusNeedInfo(status = '') {
  return status === SalesforceCustomizationStatusIds.needStatusInfo;
}

export function getCustomizationProfilesIds({
  availableUsers = [],
  selectedProfileType = '',
}) {
  switch (selectedProfileType) {
    case USERS_SPECIFIC:
      return availableUsers
        .filter((user) => user.isChecked)
        .map((user) => user.id);

    case USERS_ADMINS:
    default:
      return [];
  }
}

export function updateProfileUsers({
  availableUsers = [],
  user = {},
  isChecked = false,
}) {
  return availableUsers.map((availableUser) => {
    if (availableUser.id === user.id) {
      return {
        ...availableUser,
        isChecked,
      };
    } else {
      return availableUser;
    }
  });
}

export function getSortedProfileUsers(availableUsers = []) {
  if (isEmpty(availableUsers)) {
    return availableUsers;
  }

  const users = availableUsers.map((user) => ({
    id: user.id,
    title: user.title,
    isChecked: user.isChecked || USERS_ADMINS_VALUE === user.title,
  }));
  const adminItem = remove(users, (item) => item.title === USERS_ADMINS_VALUE);
  const sortedUsers = sortBy(users, 'title');
  sortedUsers.unshift(adminItem[0]);
  return sortedUsers;
}

export function isCheckedAllProfileUsers(users = []) {
  return users.every(({ isChecked }) => isChecked);
}

export function switchAllProfileUsers({ availableUsers, isCheckedAll }) {
  return availableUsers.map((user) => ({
    ...user,
    isChecked: isCheckedAll || user.id === USERS_ADMINS_VALUE,
  }));
}
