import cloneDeep from 'lodash/cloneDeep';

export const markEventAsSeen = (oldBestBets = [], newBestBets = []) => {
  const updatedBestBets = cloneDeep(oldBestBets);
  const eventId = newBestBets[0].events[0].id;

  for (const bestBet of updatedBestBets) {
    const eventIndex = bestBet.events.findIndex(
      (event) => event.id === eventId
    );

    if (eventIndex > -1) {
      bestBet.events[eventIndex].unseen = false;
      break;
    }
  }

  return updatedBestBets;
};

export const updateBestBetState = (oldBestBets = [], newBestBets = []) => {
  const updatedBestBets = cloneDeep(oldBestBets);
  const eventsGuid = [];
  updatedBestBets.forEach((updatedBestBet) => {
    updatedBestBet.events.forEach((event) => {
      eventsGuid.push(event.id);
    });
  });

  let shouldUpdate = false;

  newBestBets.forEach((bestBet) => {
    const bestBetIndex = updatedBestBets.findIndex(
      ({ id }) => id === bestBet.id
    );

    if (bestBetIndex > -1) {
      if (bestBet.events.length > 0) {
        bestBet.events.forEach((event) => {
          if (!eventsGuid.includes(event.id)) {
            updatedBestBets[bestBetIndex].events.unshift(event);
            event.unseen = true; // eslint-disable-line
          }
        });

        shouldUpdate = true;
      }
      if (updatedBestBets[bestBetIndex].leadScore !== bestBet.leadScore) {
        updatedBestBets[bestBetIndex].leadScore = bestBet.leadScore;
        shouldUpdate = true;
      }
    } else {
      bestBet.events.forEach((event) => {
        event.unseen = true; // eslint-disable-line
      });

      updatedBestBets.push(bestBet);
      shouldUpdate = true;
    }
  });

  if (shouldUpdate) {
    return updatedBestBets;
  } else {
    return oldBestBets;
  }
};
