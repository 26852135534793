import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import { getMasterTeam } from 'web/teams/selectors/teamsSelectors';

export const getFetched = (state) => state.entities.teamMembers.fetched;
export const getFetching = (state) => state.entities.teamMembers.fetching;
export const getAllIds = (state) => state.entities.teamMembers.allIds;
export const getById = (state) => state.entities.teamMembers.byId;
export const getTeamMemberToTeam = (state) =>
  state.entities.teamMembers.teamMemberToTeam;

export const getTeamMembers = createSelector(
  [getAllIds, getById],
  (allIds, byId) => allIds.map((id) => byId[id])
);

export const getTeamMemberToMasterTeam = createSelector(
  [getMasterTeam, getTeamMemberToTeam],
  (masterTeam, teamMemberToTeam) => {
    if (!masterTeam) {
      return [];
    }
    return filter(teamMemberToTeam, ['teamId', masterTeam.id]);
  }
);

export const getMasterTeamMembers = createSelector(
  [getTeamMemberToMasterTeam, getById],
  (teamMemberToMasterTeam, byId) =>
    teamMemberToMasterTeam.map(({ teamMemberId }) => byId[teamMemberId])
);

export const getMasterTeamMembersFetched = createSelector(
  [getMasterTeam, getFetched],
  (masterTeam, fetched) => {
    if (!masterTeam) {
      return false;
    }
    return !!fetched[masterTeam.id];
  }
);

export const getMasterTeamMembersFetching = createSelector(
  [getMasterTeam, getFetching],
  (masterTeam, fetching) => {
    if (!masterTeam) {
      return false;
    }
    return !!fetching[masterTeam.id];
  }
);

export const shouldInvalidateMasterTeamMembers = createSelector(
  [getMasterTeamMembersFetched, getMasterTeamMembersFetching],
  (fetched, fetching) => !fetched && !fetching
);
