import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CampaignInfo from './campaignInfo';
import CampaignsHistory from './campaignsHistory';

const CampaignsCard = ({
  actionCreators: {
    markSuccess,
    handleNavigateToCampaign,
    removeFromCampaign,
    openItemClick,
    viewMoreOnClick,
  },
  person: { activeCampaign, activeCampaigns, campaigns },
}) => (
  <div className="person-details-campaigns-card">
    <h3 className="margin-bottom-half">
      <FormattedMessage id="web.person.personDetails.campaignsCard.title" />
    </h3>
    <CampaignInfo
      active={activeCampaign}
      activeCampaigns={activeCampaigns}
      handleNavigateToCampaign={handleNavigateToCampaign}
      markSuccess={markSuccess}
      openItemClick={openItemClick}
      remove={removeFromCampaign}
      viewMoreOnClick={viewMoreOnClick}
    />
    <CampaignsHistory
      campaigns={campaigns}
      handleNavigateToCampaign={handleNavigateToCampaign}
      openItemClick={openItemClick}
      viewMoreOnClick={viewMoreOnClick}
    />
  </div>
);

CampaignsCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
};

CampaignsCard.defaultProps = {};

export default CampaignsCard;
