import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import { getTemplates } from './templatesFetchActionCreators';
import {
  DEFAULT_PAGE,
  TEMPLATE_SEARCH_FIELDS,
  TEMPLATE_SEARCH_AUTOCOMPLETE_INPUT_MIN,
} from '../libs/templatesConstants';
import { setTablePage } from 'web/templates/actionCreators/templatesTableActionCreators';
import { track } from 'web/services/mixpanelService';
import {
  TablesEvents,
  TablesProperties,
  TablesSources,
} from 'web/libs/mixpanelEvents';

/* getTemplates done when search string set to '' */
export function templatesResetSearch() {
  return { type: TemplatesActionTypes.search.reset };
}

export function templatesSearch(value) {
  return (dispatch, getState) => {
    dispatch({ type: TemplatesActionTypes.search.set, string: value });
    dispatch(setTablePage(DEFAULT_PAGE));

    if (value.length >= TEMPLATE_SEARCH_AUTOCOMPLETE_INPUT_MIN) {
      const newFilter = { term: value, fields: TEMPLATE_SEARCH_FIELDS };
      dispatch({
        type: TemplatesActionTypes.search.setFilter,
        filter: newFilter,
      });
      dispatch(getTemplates());
      track(TablesEvents.gridSearch, {
        [TablesProperties.source]: TablesSources.templates,
      });
    } else {
      const { templatesSearchFilter } = getState();
      if (Object.keys(templatesSearchFilter).length !== 0) {
        dispatch({ type: TemplatesActionTypes.search.setFilter, filter: {} });
        dispatch(getTemplates());
      }
    }
  };
}
