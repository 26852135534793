import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TeamManagementAlertIds } from '../../libs/teamManagementConstants';
import Alert, { AlertColors } from 'components/alert';
import { getTeamChangeErrorTextId } from '../../helpers/teamManagementHelpers';
import './teamManagementAlerts.scss';

class TeamManagementAlerts extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      viewAlert: {
        id: alertId,
        values: { errors, membersCount },
      },
      closeViewAlert,
    } = this.props;
    const defaultAlert = {
      classes: 'team-management-alert',
      color: AlertColors.red,
      onClose: closeViewAlert,
    };

    switch (alertId) {
      case TeamManagementAlertIds.dataChangesSaved:
        return {
          ...defaultAlert,
          color: AlertColors.green,
          textId: 'common.alert.success',
        };
      case TeamManagementAlertIds.dataChangesNotSaved:
        return { ...defaultAlert, textId: getTeamChangeErrorTextId(errors) };
      case TeamManagementAlertIds.addToTeamsSuccess:
        return {
          ...defaultAlert,
          color: AlertColors.green,
          textId:
            'web.settings.adminSettings.teamManagement.teamMembers.addToTeamsSuccess',
          textValues: { membersCount },
        };
      default:
        return null;
    }
  };
}

TeamManagementAlerts.defaultProps = {
  viewAlert: {},
  closeViewAlert: () => {},
};

TeamManagementAlerts.propTypes = {
  viewAlert: PropTypes.object,
  closeViewAlert: PropTypes.func,
};

export default TeamManagementAlerts;
