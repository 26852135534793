/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import { TEAM_NAMES, SUBSCRIPTION_PLAN_SLUG } from '../libs/userConstants';
import { SourceTypes } from 'web/compliance/libs/complianceConstants';
import get from 'lodash/get';

export const getUserFirstName = (state) => state.user && state.user.first_name;
export const getUserLastName = (state) => state.user && state.user.last_name;
export const getUserName = (state) => state.user && state.user.name;
export const getUserEmail = (state) => state.user && state.user.email;
export const getUserTeams = (state) =>
  state.user && state.user.subscription.teams;
export const getSubscription = (state) => state.user.subscription;
export const getFeatures = (state) => state.user.subscription.features;
export const getUser = (state) => state.user;
export const isNewUser = (state) => state.user.new_user;

export const getAdminIds = createSelector([getUserTeams], (teams) => {
  const team = teams.find(({ name }) => name === TEAM_NAMES.everyone);

  return team ? team.admin_ids : [];
});

export const getOldestAdminId = createSelector([getAdminIds], (adminIds) =>
  Math.min(...adminIds)
);

export const isFreeOrSolo = createSelector(
  [getSubscription],
  (subscription) =>
    subscription.subscription_plan_slug === SUBSCRIPTION_PLAN_SLUG.free ||
    subscription.subscription_plan_slug === SUBSCRIPTION_PLAN_SLUG.solo
);

export const isMsiActions = createSelector(
  [getSubscription],
  (subscription) =>
    subscription.subscription_plan_slug === SUBSCRIPTION_PLAN_SLUG.msiActions
);

export const isStandard = createSelector(
  [getSubscription],
  (subscription) =>
    subscription.subscription_plan_slug === SUBSCRIPTION_PLAN_SLUG.standard
);

export const isStandardOrPremium = createSelector(
  [getSubscription],
  (subscription) =>
    subscription.subscription_plan_slug === SUBSCRIPTION_PLAN_SLUG.standard ||
    subscription.subscription_plan_slug === SUBSCRIPTION_PLAN_SLUG.premium
);

export const isVip = createSelector(
  [getSubscription],
  (subscription) =>
    subscription.subscription_plan_slug === SUBSCRIPTION_PLAN_SLUG.vip
);

export const isEnterprise = createSelector([getSubscription], (subscription) =>
  get(subscription, 'is_enterprise', false)
);

export const isAdminCreateTeamsEnabled = createSelector(
  [getSubscription],
  (subscription) => get(subscription, 'admin_create_teams_enabled', false)
);

export const isIntegrationContentSharingEnabled = createSelector(
  [getSubscription],
  (subscription) =>
    get(subscription, 'integration_content_sharing_enabled', false)
);

export const isI18NFeatureFlagEnabled = createSelector(
  [getFeatures],
  (features) => get(features, 'i18n_enabled', false)
);

export const isAdmin = createSelector([getUser], (user) =>
  get(user, 'admin', false)
);

export const allowedSeatsCount = createSelector(
  [getSubscription],
  ({ num_team_members = null }) => num_team_members
);

export const usedSeatsCount = createSelector(
  [getSubscription],
  ({ team_members_count = null }) => team_members_count
);

export const openSeatsCount = createSelector(
  [allowedSeatsCount, usedSeatsCount],
  (allowedSeats, usedSeats) => allowedSeats - usedSeats
);

export const isUserLoggedIn = (store) => !!(store.user && store.user.id);

export const getCustomAppendContent = createSelector(
  [getSubscription],
  ({ custom_append_content = null }) => custom_append_content
);

export const isAdminAutoAppendEnabled = createSelector(
  [getSubscription],
  ({ admin_auto_append_enabled }) => admin_auto_append_enabled
);

export const isAdminMarketoAuthEnabled = createSelector(
  [getSubscription],
  ({ admin_marketo_auth_enabled = false }) => admin_marketo_auth_enabled
);

export const getUserColumnSettingsForTable = (
  state,
  columnsSettingsTableId
) => {
  return state.userSettings && state.userSettings[columnsSettingsTableId];
};

export const isOnboardingStepPassed = (state, stepsNames) => {
  const { passedOnboardingSteps } = state.userSettings;

  return passedOnboardingSteps && passedOnboardingSteps.includes(stepsNames);
};

export const getOnboardingAuthentications = (state) => {
  const {
    onboarding: { onboardingAuthentications },
  } = state;

  const getCrmDataProvider = onboardingAuthentications.find(
    (data) => data.provider === SourceTypes.salesforce
  );

  return getCrmDataProvider ? getCrmDataProvider.username : '';
};

export const selectBrokenSfdcConnectionBannerDisplayedAt = (state) =>
  state.userSettings.brokenSfdcConnectionBannerDisplayedAt;
