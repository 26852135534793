import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { Urls } from 'web/libs/constants';
import { navigateToUrl } from 'web/services/routerService';
import { getImageUrl } from 'web/libs/constants';
import { markUserAsNotNew } from 'web/onboarding/actionCreators/onboardingActionCreators';
import { productTourLoaderSource } from 'web/productTour/actionCreators/productTourActionCreator';
import onboardingPage from 'components/hocs/onboardingPage';
import LoadingSpinner from 'components/loadingSpinner';
import { preloadImages } from '../../helpers';
import './productTourContainer.scss';

const ProductTourContainer = (props) => {
  const [isLoadImages, setLoadImages] = useState(false);
  const { markUserAsNotNew, productTourLoaderSource } = props;

  useEffect(() => {
    const loadedImages = preloadImages(stepsImages, () => setLoadImages(true));
    productTourLoaderSource(loadedImages);
  }, []);

  const stepsImages = [
    `${getImageUrl('productTour/1920x1080/step1', 'png')}`,
    `${getImageUrl('productTour/1920x1080/step2', 'png')}`,
    `${getImageUrl('productTour/1920x1080/step3', 'png')}`,
    `${getImageUrl('productTour/1920x1080/step4', 'png')}`,
  ];

  return isLoadImages ? (
    <div className="productTour-content">
      <div className="productTour-content-welcome">
        <h2 className="text-bold first-page-title">
          <FormattedHTMLMessage
            id={'web.productTour.content.firstPage.title'}
          />
        </h2>
        <FormattedHTMLMessage id={'web.productTour.content.firstPage'} />

        <div className="productTour-buttons-group">
          <Button
            color={ButtonColors.gray}
            onClick={markUserAsNotNew}
            size={ButtonSizes.medium}
          >
            <FormattedMessage id="web.onboarding.actionButtons.notNow" />
          </Button>
          <Button
            onClick={() => navigateToUrl(Urls.productTour.steps)}
            size={ButtonSizes.medium}
          >
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner imageUrl={getImageUrl} size={130} />
  );
};

ProductTourContainer.propTypes = {
  markUserAsNotNew: PropTypes.func.isRequired,
  productTourLoaderSource: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  productTourLoadedImages: state.productTourLoadedImages,
});

const mapDispatchToProps = {
  markUserAsNotNew,
  productTourLoaderSource,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  (component) =>
    onboardingPage(component, {
      shiftedBackground: true,
      titleKey: 'web.productTour.welcomeText',
    })
)(ProductTourContainer);
