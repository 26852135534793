import React, { Component } from 'react';
import TwoPaneLayout, { BorderColors } from 'components/layouts/twoPane';
import TeamsContainer from '../../containers/teamsContainer';
import TeamMembersTable from '../teamMembersTable';
import AllMembersTable from '../allMembersTable';

const SIDEBAR_WIDTH = 230;

class TeamMembers extends Component {
  render() {
    return (
      <TwoPaneLayout
        borderColor={BorderColors.grayLight}
        sidebarWidth={SIDEBAR_WIDTH}
      >
        <TeamsContainer />
        {this.getTable()}
      </TwoPaneLayout>
    );
  }

  getTable = () => {
    const { masterTeam, selectedTeam } = this.props;

    if (masterTeam && masterTeam === selectedTeam) {
      return <AllMembersTable {...this.props} />;
    } else {
      return <TeamMembersTable {...this.props} />;
    }
  };
}

export default TeamMembers;
