import React from 'react';
import PropTypes from 'prop-types';
import DefaultSelect from 'components/selects/default/defaultSelect';
import { getDefaultLocaleFromLanguage } from 'web/settings/myProfile/components/timezoneSettings/helpers/timezoneSettingsHelper';
import I18N from 'languages';
import './language.scss';

class Language extends React.Component {
  handleLanguageChange = (language) => {
    const locale = getDefaultLocaleFromLanguage(language);
    I18N.setLanguageCookie(language, locale);
    window.location.reload(true);
  };

  render() {
    const { items } = this.props;
    return (
      <div className="login-language-list">
        <DefaultSelect
          items={items}
          onChange={this.handleLanguageChange}
          selected={I18N.currLanguage.substr(0, 2)}
          width={150}
          noBorder
        />
      </div>
    );
  }
}

Language.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Language;
