export function getPrimaryEmail(person = {}) {
  if (!person.addresses) {
    return null;
  }

  const emails = person.addresses.filter(
    (address) => address.address_type === 'email'
  );
  if (emails.length) {
    const workEmail = emails.filter((email) => email.location === 'work');
    return workEmail.length ? workEmail[0] : emails[0];
  }
  return null;
}

export function getPrimaryPhone(person = {}) {
  if (!person.addresses) {
    return null;
  }

  const phones = person.addresses.filter(
    (address) => address.address_type === 'phone'
  );
  return phones.length ? phones[0] : null;
}

export function formatName(first, last, email) {
  const given = first || ''; // handles both null & undefined
  const surname = last || '';
  const spacing = given && surname ? ' ' : '';
  return `${given}${spacing}${surname}` || email || 'No name'; //todo intl
}

export function formatTitle(title, company) {
  const personTitle = title || '';
  const personCompany = company || '';
  const comma = personTitle && personCompany ? ', ' : '';
  return `${personTitle}${comma}${personCompany}`;
}

export function getPersonName(person = {}) {
  const firstName = person.first_name
    ? person.first_name
    : person.parent_name || '';
  const lastName = person.last_name ? person.last_name : '';
  const email = getPrimaryEmail(person);
  return formatName(firstName, lastName, email && email.address);
}

export function getPersonTitle(person = {}) {
  const companyName =
    person.company && person.company.name ? person.company.name : '';
  const title = person.title ? person.title : '';
  return formatTitle(title, companyName);
}

// export default toutHelpers;
