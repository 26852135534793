import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import './pipelineMessage.scss';

const PipelineMessage = (props) => {
  if (!props.message) {
    return null;
  }
  return (
    <div className="pipeline-message">
      <FormattedHTMLMessage id={props.message} values={props.values} />
    </div>
  );
};

PipelineMessage.propTypes = {
  message: PropTypes.string,
  values: PropTypes.object,
};

PipelineMessage.defaultProps = {
  message: '',
  values: {},
};

export default PipelineMessage;
