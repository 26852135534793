import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUnsubscribeOptionsItems } from '../../unsubscribeOptions/selectors/unsubscribeOptionsSelectors';
import { changeOptInReason } from '../actionCreators/optInReasonsActionCreators';
import OptInReasons from '../components/optInReasons';
import { REASON_TYPE } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';

const OptInReasonsContainer = ({
  changeOptInReasonAction,
  items,
  selected,
}) => (
  <OptInReasons
    items={items}
    selected={selected}
    onChange={changeOptInReasonAction}
  />
);

OptInReasonsContainer.propTypes = {
  changeOptInReasonAction: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selected: PropTypes.number,
};

const mapStateToProps = (state) => ({
  items: getUnsubscribeOptionsItems(state, REASON_TYPE.optIn),
  selected: state.optInReasonsSelected,
});

const mapDispatchToProps = {
  changeOptInReasonAction: changeOptInReason,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptInReasonsContainer);
