import { createSelector } from 'reselect';
import { TasksTableStatusFilterValues } from 'web/tables/tasks/libs/tablesTasksConstants';
import {
  getDatesSmartFilter,
  getSelectedDates,
  getStatusSmartFilter,
} from 'web/tables/tasks/selectors/tablesTasksSelectors';
import { filterItemsByDate } from 'web/tables/helpers/itemsTableFilters';
import {
  getLastEventDate,
  getEventGroups,
  getActiveEmailTableIdByTabs,
} from 'web/commandCenter/helpers/emailHelpers';
import {
  PitchTrackingEvents,
  EmailStates,
} from 'web/commandCenter/libs/commandCenterConstants';
import { getUser } from 'web/user/selectors/userSelectors';

const getTasksFetching = (state) => state.tasksFetching;
const getTasksCompleted = (state) => state.tasksCompleted;
const getTasksUncompleted = (state) => state.tasksUncompleted;
const getEmailGridData = (state) => state.emailGridData;
const getSidebarSelectedItem = (state) => state.sidebarSelectedItem;
const emailSavedAdvancedSearchId = (state) =>
  state.emailSelectedSavedAdvancedSearchId;
const getSidebarViewer = (state) => state.sidebarViewer;

export const getCommandCenterEmailsTab = (state) =>
  state.commandCenterEmailsTab;
export const getCommandCenterEmailsSubTab = (state) =>
  state.commandCenterEmailsSubTab;

export const getTasks = createSelector(
  [
    getStatusSmartFilter,
    getDatesSmartFilter,
    getTasksUncompleted,
    getTasksCompleted,
    getSelectedDates,
    getSidebarViewer,
  ],
  (statusValue, datesValue, uncompleted, completed, dates, sidebarViewer) => {
    const { id: userId } = sidebarViewer;
    if (statusValue === TasksTableStatusFilterValues.completed) {
      let completedTasks = completed.valueSeq().toArray();
      if (userId) {
        completedTasks = completedTasks.filter((x) => x.user_id === userId);
      }
      return filterItemsByDate(completedTasks, datesValue, dates);
    } else {
      let uncompletedTasks = uncompleted.valueSeq().toArray();
      if (userId) {
        uncompletedTasks = uncompletedTasks.filter((x) => x.user_id === userId);
      }
      return filterItemsByDate(uncompletedTasks, datesValue, dates);
    }
  }
);

export const isSubscriptionsUserTask = createSelector(
  [getSidebarViewer, getUser],
  (sidebarViewer, currentUser) => {
    const { id: selectedUserId } = sidebarViewer;
    return currentUser.admin && currentUser.id !== selectedUserId;
  }
);

export const getFetching = createSelector(
  [getTasksFetching],
  (tasksFetching) => tasksFetching.length > 0
);

export const getOpenEvents = createSelector([getEmailGridData], (emails = []) =>
  emails.map((email) => {
    const inputEmail = email;
    const { responded, tracking_events: trackingEvents = [], clicked } = email;

    const openEvents = getEventGroups(trackingEvents)[PitchTrackingEvents.open];
    inputEmail.circleEvents = {
      [PitchTrackingEvents.open]: {
        // If the email has clicked or replies, but no opens, pretend there was an open
        eventCount:
          openEvents && openEvents.length
            ? openEvents.length
            : Number(responded || clicked),
        lastEventDate: getLastEventDate(openEvents),
      },
    };
    return inputEmail;
  })
);

const getClickEvents = createSelector([getOpenEvents], (emails = []) =>
  emails.map((email) => {
    const inputEmail = email;
    const { tracking_events: trackingEvents = [] } = email;

    const clickEvents = getEventGroups(trackingEvents)[
      PitchTrackingEvents.click
    ];
    inputEmail.circleEvents[PitchTrackingEvents.click] = {
      eventCount: clickEvents && clickEvents.length ? clickEvents.length : 0,
      lastEventDate: getLastEventDate(clickEvents),
    };
    return inputEmail;
  })
);

const getReplyEvents = createSelector([getClickEvents], (emails = []) =>
  emails.map((email) => {
    const inputEmail = email;
    const { responded, tracking_events: trackingEvents = [], state } = email;

    const replyEvents = getEventGroups(trackingEvents)[
      PitchTrackingEvents.reply
    ];
    inputEmail.circleEvents[PitchTrackingEvents.reply] = {
      eventCount:
        replyEvents && replyEvents.length
          ? replyEvents.length
          : Number(responded || state === EmailStates.replied),
      lastEventDate: getLastEventDate(replyEvents),
    };
    return inputEmail;
  })
);

const getSuccessEvents = createSelector([getReplyEvents], (emails = []) =>
  emails.map((email) => {
    const inputEmail = email;
    const { success, workflow_details: workflowDetails } = email;

    inputEmail.circleEvents[PitchTrackingEvents.success] = {
      // If the email has no any replies event, but it's responded and state is replied, pretend it is replied
      eventCount: Number(
        (workflowDetails && workflowDetails.step_success) || success
      ),
      lastEventDate: null,
    };
    return inputEmail;
  })
);

export const getEmails = createSelector(
  [getSuccessEvents],
  (emails = []) => emails
);

export const getActiveEmailTableId = createSelector(
  [getCommandCenterEmailsTab, getCommandCenterEmailsSubTab],
  (tab, subTab) => getActiveEmailTableIdByTabs(tab, subTab)
);

export const getSidebarSelectedItemId = createSelector(
  [getSidebarSelectedItem, emailSavedAdvancedSearchId],
  (sidebarSelectedItem, selectedSavedAdvancedSearchId) =>
    !selectedSavedAdvancedSearchId ? sidebarSelectedItem : null
);
