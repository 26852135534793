import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import EditableHeader from 'components/editableHeader';

const GroupDescription = ({
  description,
  groupId,
  onGroupDescriptionChange,
}) => {
  const [newDescription, setNewDescription] = useState(description);
  useEffect(() => setNewDescription(description), [description]);

  const isEmptyDescription = !description || !description.length;
  const headerClassName = classNames('people-page-header-description', {
    'description-span': isEmptyDescription,
  });

  const savePeopleDescription = (value) => {
    setNewDescription(value);
    onGroupDescriptionChange(groupId, value);
  };

  return (
    <EditableHeader
      className={headerClassName}
      onSave={(value) => savePeopleDescription(value)}
      placeHolderTextId="web.commandCenter.advancedSearch.enterPeopleDescription"
      value={newDescription}
      isEditable
    />
  );
};

GroupDescription.propTypes = {
  description: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  onGroupDescriptionChange: PropTypes.func,
};

GroupDescription.defaultProps = {
  description: '',
  onGroupDescriptionChange: () => {},
};

export default GroupDescription;
