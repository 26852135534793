import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import classnames from 'classnames';
import HeaderColumn from './headerColumn';
import { ColumnOrderDragType } from 'table/modules/constants';

const headerColumnSource = {
  beginDrag(props) {
    return { columnId: props.column.id };
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class DraggableHeaderColumn extends HeaderColumn {
  render() {
    const { connectDragSource, isDragging } = this.props;
    return connectDragSource(
      <div
        className={classnames('header-column-dragging-container', {
          dragging: isDragging,
        })}
      >
        {super.render()}
      </div>
    );
  }
}

DraggableHeaderColumn.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
};

DraggableHeaderColumn.defaultProps = {};

export default DragSource(ColumnOrderDragType, headerColumnSource, collect)(
  DraggableHeaderColumn
);
