import { createSelector } from 'reselect';
import { getSmartFilterById } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';

import {
  REFERENCE_DATE_FILTER_ID,
  DEFAULT_REFERENCE_DATE,
} from 'web/commandCenter/modules/whenSmartFilter/libs/referenceDateSmartFilterConstants';

export const getSelectedReferenceDate = createSelector(
  [getSmartFilterById(REFERENCE_DATE_FILTER_ID)],
  (filter) => (filter && filter.referenceDate) || DEFAULT_REFERENCE_DATE
);
