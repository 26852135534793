import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from 'components/loadingSpinner';
import Header from './header';
import List from 'components/list';
import ListItem, { listItemHeight } from 'components/list/listItem';
import './sidebarWhite.scss';

class SidebarWhite extends Component {
  render() {
    const {
      className,
      getImageUrl,
      handleSelected,
      headerIconOnClick,
      headerTextId,
      itemRenderer,
      items,
      loading,
      rowHeight,
      selectedIndex,
      zeroStateTextId,
      zeroStateTextValues,
    } = this.props;

    return (
      <div className={classNames('sidebar-white', className)}>
        {headerTextId && (
          <Header textId={headerTextId} iconOnClick={headerIconOnClick} />
        )}
        {loading && <LoadingSpinner imageUrl={getImageUrl} />}
        {((items.length > 0 || loading) && (
          <div className="sidebar-white-list-container">
            <List
              className="sidebar-white-list"
              handleSelected={handleSelected}
              itemRenderer={itemRenderer || this.itemRenderer}
              items={items}
              rowHeight={rowHeight}
              selected={selectedIndex}
            />
          </div>
        )) || (
          <div className="sidebar-white-zero-state">
            <FormattedMessage
              id={zeroStateTextId}
              values={zeroStateTextValues}
            />
          </div>
        )}
      </div>
    );
  }

  itemRenderer = (item, index, array, listRef) => {
    const { itemOnDelete, itemOnRename } = this.props;
    return (
      <ListItem
        deleteCheckDisabled={this.deleteCheckDisabled}
        label={item.label}
        listScrollRef={listRef}
        onDelete={itemOnDelete}
        onRename={itemOnRename}
        renameCheckDisabled={this.renameCheckDisabled}
        showDelete={(itemOnDelete && true) || false}
        showRename={(itemOnRename && true) || false}
        value={item.value}
      />
    );
  };

  deleteCheckDisabled = () => !this.props.itemOnDelete;

  renameCheckDisabled = () => !this.props.itemOnRename;
}

SidebarWhite.propTypes = {
  className: PropTypes.string,
  getImageUrl: PropTypes.func,
  handleSelected: PropTypes.func,
  headerIconOnClick: PropTypes.func,
  headerTextId: PropTypes.string,
  itemOnDelete: PropTypes.func,
  itemOnRename: PropTypes.func,
  itemRenderer: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  rowHeight: PropTypes.number,
  selectedIndex: PropTypes.number,
  zeroStateTextId: PropTypes.string.isRequired,
  zeroStateTextValues: PropTypes.object,
};

SidebarWhite.defaultProps = {
  className: '',
  getImageUrl: undefined,
  handleSelected: () => {},
  headerIconOnClick: undefined,
  headerTextId: '',
  itemOnDelete: undefined,
  itemOnRename: undefined,
  itemRenderer: undefined,
  items: [],
  loading: false,
  rowHeight: listItemHeight,
  selectedIndex: -1,
  zeroStateTextValues: {},
};

export default SidebarWhite;
