import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getCampaignFilterSelectedCampaign,
  getCampaignsForCampaignFilter,
  getEmailGridCampaingFilterValue,
} from 'web/commandCenter/modules/campaignSmartFilter/selectors/campaignSmartFilterSelectors';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import {
  clearCampaignFilterValue,
  setCampaignFilterValue,
} from 'web/commandCenter/modules/campaignSmartFilter/actionCreators/campaignSmartFilterActionCreators';
import { setOrFetchCampaigns } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabStartupActionCreators';
import CampaignSmartFilter from 'web/commandCenter/modules/campaignSmartFilter/components/campaignSmartFilter/campaignSmartFilter';

class CampaignSmartFilterContainer extends Component {
  constructor(props) {
    super(props);
    const { setOrFetchCampaigns } = props;

    setOrFetchCampaigns();
  }

  render() {
    return <CampaignSmartFilter {...this.props} />;
  }
}

CampaignSmartFilterContainer.propTypes = {
  campaignFilterLoading: PropTypes.bool.isRequired,
  campaignFilterSelectedCampaign: PropTypes.object,
  campaignFilterValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

CampaignSmartFilterContainer.defaultProps = {
  campaignFilterSelectedCampaign: null,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearCampaignFilterValue,
      setCampaignFilterValue,
      setOrFetchCampaigns,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  campaignFilterLoading: state.campaignFilterLoading,
  campaignFilterSelectedCampaign: getCampaignFilterSelectedCampaign(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  }),
  campaignFilterValue: getEmailGridCampaingFilterValue(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  }),
  campaigns: getCampaignsForCampaignFilter(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignSmartFilterContainer);
