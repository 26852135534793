export const GlobalPopupIds = {
  dynamicFields: 'EMAIL_COMPOSE_BODY_DYNAMIC_FIELDS',
  dynamicFieldsIncorrect: 'EMAIL_COMPOSE_BODY_DYNAMIC_FIELDS_INCORRECT',
  imageInsert: 'EMAIL_COMPOSE_BODY_IMAGE_INSERT',
  saveAsTemplate: 'EMAIL_COMPOSE_BODY_SAVE_AS_TEMPLATE',
  dynamicFieldsDaysFromNow: 'EMAIL_COMPOSE_BODY_DYNAMIC_FIELDS_DAYS_FROM_NOW',
  unfilledPromptInAutomaticEmail: 'UNFILLED_PROMPT_IN_AUTOMATIC_EMAIL',
};

export const GlobalAlertIds = {
  createNewTemplate: 'EMAIL_COMPOSE_BODY_CREATE_NEW_TEMPLATE',
  imageInsertFileTooLarge: 'EMAIL_COMPOSE_BODY_IMAGE_INSERT_FILE_TOO_LARGE',
  imageInsertFileTypeInvalid:
    'EMAIL_COMPOSE_BODY_IMAGE_INSERT_FILE_TYPE_INVALID',
  imageInsertIssue: 'EMAIL_COMPOSE_BODY_IMAGE_INSERT_ISSUE',
  openSaveAsTemplate: 'EMAIL_COMPOSE_BODY_OPEN_SAVE_AS_TEMPLATE',
};

// Mirred in imageInsert Modal as AllowedFileTypes
export const AllowedImageFileTypes = {
  'image/bmp': 'BMP',
  'image/gif': 'GIF',
  'image/x-icon': 'ICO',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/svg+xml': 'SVG',
};
