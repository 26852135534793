import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextButton from 'components/buttons/text';

import './customFieldsCardDetails.scss';

class CustomFieldsCardDetails extends Component {
  render() {
    const { customFields, edit } = this.props;

    return (
      <div className="person-details-custom-fields-details">
        <div className="custom-fields-details-header-row margin-bottom-half">
          <h3>
            <FormattedMessage id="web.person.personDetails.customFields.title" />
          </h3>
          {edit && (
            <TextButton onClick={edit} underline>
              <FormattedMessage id="common.edit" />
            </TextButton>
          )}
        </div>
        {(customFields.length && (
          <div className="custom-fields-details-list">
            {this._getCustomFields()}
          </div>
        )) || (
          <FormattedMessage id="web.person.personDetails.customFields.noCustomFields" />
        )}
      </div>
    );
  }

  _getCustomFields = () => {
    const { customFields } = this.props;

    return customFields.map(({ id, name, value }) => {
      const key = `custom-fields-details-field-${id}`;
      return (
        <div className="custom-fields-details-field" key={key} id={key}>
          <div className="text-gray margin-right-full custom-field-name">
            {name}
          </div>
          <div className="custom-field-name">{value}</div>
        </div>
      );
    });
  };
}

CustomFieldsCardDetails.propTypes = {
  customFields: PropTypes.array.isRequired,
  edit: PropTypes.func,
};

CustomFieldsCardDetails.defaultProps = {
  edit: null,
};

CustomFieldsCardDetails.defaultProps = {};

export default CustomFieldsCardDetails;
