export const AlertIds = {
  fileTooLarge: 'FILE_TOO_LARGE',
  fileTypeInvalid: 'FILE_TYPE_INVALID',
  issue: 'ISSUE',
};

export const AllowedFileTypes = {
  'image/bmp': 'BMP',
  'image/gif': 'GIF',
  'image/x-icon': 'ICO',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/svg+xml': 'SVG',
};
