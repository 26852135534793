import { unsubscribePeople as unsubscribePeopleCall } from 'web/people/services/peopleService';
import {
  closePopup,
  setPopupLoading,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { getPeople } from './peopleFetchActionCreators';

export const unsubscribePeople = (peopleIds, onSuccess) => (dispatch) => {
  dispatch(setPopupLoading(true));
  unsubscribePeopleCall(peopleIds)
    .then(() => {
      dispatch(getPeople());
      dispatch(closePopup());
      onSuccess();
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.genericApiError));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};
