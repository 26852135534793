import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BccForm from './bccForm';
import { BCC_FORM_ID } from '../../libs/integrationConstants';
import './bcc.scss';
import HoverTooltip from 'components/hoverTooltip';

const BccCard = ({
  updateIntegration,
  bccIntegration,
  openDeleteBccConfirmation,
}) => {
  const bccFormModel = {
    bcc: bccIntegration.bcc,
  };
  return (
    <div className="integrations-bcc">
      <h3>
        <FormattedMessage id="web.settings.accountSettings.integrations.bcc.title" />
      </h3>
      <div className="margin-top-20">
        <div className="flex">
          <FormattedMessage id="web.settings.accountSettings.integrations.bcc.description" />
          <HoverTooltip
            place="right"
            textId="web.settings.accountSettings.integrations.bcc.tooltip"
            multiline
          >
            <i className="tout-icon-warning" />
          </HoverTooltip>
        </div>
        <BccForm
          form={BCC_FORM_ID}
          onSubmit={updateIntegration}
          openDeleteBccConfirmation={openDeleteBccConfirmation}
          initialValues={bccFormModel}
          bccIntegration={bccIntegration}
        />
      </div>
    </div>
  );
};

BccCard.propTypes = {
  updateIntegration: PropTypes.func.isRequired,
  openDeleteBccConfirmation: PropTypes.func.isRequired,
  bccIntegration: PropTypes.object.isRequired,
};

export default BccCard;
