import { Urls } from 'web/libs/routes';
import { basePut } from 'web/services/api';

export const bulkUpdateAsync = (updateAttributes) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.templatesUpdateAsync,
      { pitch_template: updateAttributes },
      resolve,
      reject
    );
  });

export const bulkUpdateSync = (updateAttributes) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.templatesUpdateSync,
      { pitch_template: updateAttributes },
      resolve,
      reject
    );
  });
