import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import TimeInput from 'components/inputs/timeInput';
import Select from 'components/selects/multiArrays';
import DatePicker from 'components/dates/datePicker';
import './dateTimeZone.scss';

class DateTimeZone extends Component {
  constructor(props) {
    super(props);

    this.timeFormat = 'LT';
    this.dateFormat = 'L';

    this.state = {
      date: props.datetime,
      errorTime: false,
      time: props.datetime.format(this.timeFormat),
    };
  }

  render() {
    const {
      calendarPortal,
      classes,
      datePickerOpenDirection,
      disabled,
      timezone,
      timezones,
      isInvalidTime,
    } = this.props;
    const { date, errorTime, time } = this.state;

    return (
      <div className={`dateTimeZone${classes ? ` ${classes}` : ''}`}>
        <DatePicker
          className="day-picker-width"
          date={date}
          disabled={disabled}
          onDateChange={this.onDateChange}
          openDirection={datePickerOpenDirection}
          withPortal={calendarPortal}
        />
        <TimeInput
          className="margin-left-half"
          disabled={disabled}
          error={errorTime || isInvalidTime}
          onChange={this.onTimeChange}
          value={time}
        />
        <Select
          className="margin-left-half date-timezone-select"
          disabled={disabled}
          items={timezones}
          onChange={this.onTimezoneChange}
          selected={timezone}
          full
        />
      </div>
    );
  }

  isValid(datetime, timezone, error) {
    const current = moment.tz(timezone);
    if (datetime <= current || error) {
      this.setState({ errorTime: true });
    } else {
      this.setState({ errorTime: false });
    }
  }

  onDateChange = (valueMoment, error) => {
    this.setState({ date: valueMoment });

    const { timezone, update } = this.props;
    const { time } = this.state;
    const datetimeMoment = moment.tz(
      `${valueMoment.format(this.dateFormat)} ${time}`,
      `${this.dateFormat} ${this.timeFormat}`,
      timezone
    );
    this.isValid(datetimeMoment, timezone, error);
    update({ datetime: datetimeMoment });
  };

  onTimeChange = (value, error) => {
    this.setState({ errorTime: error, time: value });

    const { timezone, update } = this.props;
    const { date } = this.state;
    const datetimeMoment = moment.tz(
      `${date.format(this.dateFormat)} ${value.toLowerCase()}`,
      `${this.dateFormat} ${this.timeFormat}`,
      timezone
    );
    this.isValid(datetimeMoment, timezone, error);
    update({ datetime: datetimeMoment });
  };

  onTimezoneChange = (value) => {
    const { update } = this.props;
    const { date, time } = this.state;
    const datetimeMoment = moment.tz(
      `${date.format(this.dateFormat)} ${time}`,
      `${this.dateFormat} ${this.timeFormat}`,
      value
    );
    this.isValid(datetimeMoment, value);
    update({ datetime: datetimeMoment, timezone: value });
  };
}

DateTimeZone.propTypes = {
  calendarPortal: PropTypes.bool,
  classes: PropTypes.string,
  datePickerOpenDirection: PropTypes.string,
  datetime: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isInvalidTime: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  update: PropTypes.func.isRequired,
};

DateTimeZone.defaultProps = {
  calendarPortal: false,
  classes: '',
  datePickerOpenDirection: undefined,
  disabled: false,
  isInvalidTime: false,
};

export default DateTimeZone;
