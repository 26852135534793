import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { defaultPartnerName } from 'web/contentPartners/libs/contentPartnersConstants';

const initConstantContentPartners = [
  {
    name: defaultPartnerName,
  },
];

const initContentPartnersState = {
  loading: false,
  loaded: false,
  partners: initConstantContentPartners,
};

export function contentPartners(state = initContentPartnersState, action = {}) {
  switch (action.type) {
    case ActionTypes.api.contentPartners.request:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.api.contentPartners.success:
      return {
        ...state,
        partners: [...initConstantContentPartners, ...action.data],
        loaded: true,
        loading: false,
      };
    case ActionTypes.api.contentPartners.failure:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function contentModalLoading(state = true, action = {}) {
  switch (action.type) {
    case ActionTypes.api.contentPartners.request:
      return true;
    case ActionTypes.api.contentPartners.success:
    case ActionTypes.api.contentPartners.failure:
      return false;
    default:
      return state;
  }
}

export function selectedPartner(state = {}, action = {}) {
  switch (action.type) {
    case ActionTypes.addContent.data.selectedPartner:
      return action.data;
    default:
      return state;
  }
}

export function addContentModalWindowMode(state = false, action) {
  switch (action.type) {
    case ActionTypes.addContent.state.windowMode:
      return action.data;
    default:
      return state;
  }
}

// We only have one contentPartner for now which is Highspot and
// the call to fetch the metadata just fetches these dimensions for the iframe.
// Unfortunately, this metadata fetch doesn't work in dev/qa/staging
// due to CORS issues so this will have to do.

const defaultIframeDimenions = {
  height: '574px',
  width: '602px',
};

export function partnerMetadata(state = defaultIframeDimenions, action = {}) {
  switch (action.type) {
    case ActionTypes.api.contentPartnersMetadata.success:
      return action.data;
    default:
      return state;
  }
}
