import {
  openPopup,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { SalesforcePopupIds } from '../libs/salesforceConstants';
import {
  saveApiForm,
  saveBccForm,
} from 'web/salesforce/actionCreators/salesforceActionCreators';

export const openConnectConfirmationPopup = (type, disconnect) => (
  dispatch
) => {
  dispatch(
    openPopup(SalesforcePopupIds.connectConfirmation, { disconnect, type })
  );
};

export const openDisconnectConfirmationPopup = (type) => (dispatch) => {
  dispatch(openPopup(SalesforcePopupIds.disconnectConfirmation, { type }));
};

export const openActivateApiConfirmationPopup = () => (dispatch) => {
  dispatch(openPopup(SalesforcePopupIds.activateApi));
};

export const openActivateBccConfirmationPopup = () => (dispatch) => {
  dispatch(openPopup(SalesforcePopupIds.activateBcc));
};

export const activateApi = () => (dispatch) => {
  dispatch(saveApiForm({}));
  dispatch(closePopup());
};

export const activateBcc = () => (dispatch) => {
  dispatch(saveBccForm(true));
  dispatch(closePopup());
};
