import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from 'web/shared/components/sidebar/actionButton/actionButton';
import MultiSearch from './multiSearch/multiSearch';
import Shapes from 'web/shared/components/sidebar/sidebarShapes';
import SidebarFilter from './sidebarFilter/sidebarFilter';
import SidebarList from './sidebarList/sidebarList';
import './sidebar.scss';

const Sidebar = ({
  actionButton,
  classes,
  filter,
  list,
  multiSearch,
  children,
}) => (
  <div className={`tout-ui-sidebar ${classes}`}>
    <SidebarFilter {...filter} />
    <div className="tout-ui-sidebar-search">
      <MultiSearch {...multiSearch} />
    </div>
    <ActionButton {...actionButton} />
    <SidebarList {...list} />
    {children}
  </div>
);

Sidebar.propTypes = {
  actionButton: Shapes.ActionButton,
  children: PropTypes.element.isRequired,
  filter: Shapes.Filter,
  list: Shapes.List,
  multiSearch: Shapes.MultiSearch,
};

Sidebar.defaultProps = {
  actionButton: null,
  filter: null,
  list: null,
  multiSearch: null,
};

export default Sidebar;
