/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import PersonDetailsSlideOut from 'web/slideOuts/personDetails';
import PeopleAlert from 'web/people/components/peopleAlert/peopleAlert';
import PeoplePageHeader from '../components/peoplePageHeader';
import PeopleTableContainer from './peopleTableContainer';
import Page from 'components/layouts/page/page';
import PageView from 'components/layouts/page/view';
import AddContactSlideOut from 'web/slideOuts/addContact';
import {
  peopleExport,
  exportPeopleFromGroup,
} from 'web/people/actionCreators/peopleExportActionCreators';
import './peopleTabContainer.scss';
import {
  getSelectedGroup,
  getIsImportButtonVisible,
  getIsDeleteIconDisabled,
  getIsShareIconDisabled,
} from 'web/people/selectors/peopleSelectors';
import { openAddContact } from 'web/slideOuts/addContact/actionCreators/addContactSlideOutActionCreators';
import TwoPaneLayout from 'web/shared/components/twoPaneLayout/twoPaneLayout';
import PeopleSidebarContainer from './peopleSidebarContainer';
import PeoplePopupContainer from 'web/people/containers/peoplePopupContainer';
import {
  openImportPeopleStepOne,
  openDeleteGroupPopup,
  openShareGroupPopup,
} from 'web/people/actionCreators/peoplePopupActionCreators';
import { openAddGroupToCampaignHandler } from 'web/people/actionCreators/peopleGroupActionCreators';
import { setSlideOutMenuOpen } from 'web/people/actionCreators/peopleTabSettingsActionCreators';
import { emailGroup } from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import { setPeopleGroupDescription } from 'web/people/actionCreators/peopleGroupActionCreators';

class PeopleTabContainer extends Component {
  componentWillUnmount() {
    // Hacky way of cleaning up this DOM element created in tout-next
    $('#people-wrapper') && $('#people-wrapper').remove();
  }

  render() {
    const {
      exportFetching,
      viewAlert,
      user,
      selectedGroup,
      currentGroupId,
      actionCreators,
      isImportButtonVisible,
      isDeleteIconDisabled,
      isShareIconDisabled,
      slideOutMenuOpen,
      groups,
    } = this.props;
    const { peopleExportAction, setPeopleGroupDescription } = actionCreators;

    return [
      <PeoplePopupContainer key="people-popup-container" />,
      <PersonDetailsSlideOut key="people-tab-person-details-slide-out" />,
      <AddContactSlideOut key="people-tab-add-contact-slide-out" />,
      <div key="people-tab-container">
        <PeopleAlert actionCreators={actionCreators} peopleAlert={viewAlert} />
        <TwoPaneLayout>
          <PeopleSidebarContainer />
          <Page>
            <PeoplePageHeader
              actionCreators={actionCreators}
              currentGroupId={currentGroupId}
              exportFetching={exportFetching}
              groups={groups}
              isDeleteIconDisabled={isDeleteIconDisabled}
              isImportButtonVisible={isImportButtonVisible}
              isShareIconDisabled={isShareIconDisabled}
              onGroupDescriptionChange={setPeopleGroupDescription}
              peopleExport={peopleExportAction}
              selectedGroup={selectedGroup}
              slideOutMenuOpen={slideOutMenuOpen}
              user={user}
            />
            <PageView>
              <PeopleTableContainer />
            </PageView>
          </Page>
        </TwoPaneLayout>
      </div>,
    ];
  }
}

PeopleTabContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  currentGroupId: PropTypes.string.isRequired,
  exportFetching: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
  isDeleteIconDisabled: PropTypes.bool.isRequired,
  isImportButtonVisible: PropTypes.bool.isRequired,
  isShareIconDisabled: PropTypes.bool.isRequired,
  peopleSearchString: PropTypes.string.isRequired,
  selectedGroup: PropTypes.object,
  slideOutMenuOpen: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  viewAlert: PropTypes.object.isRequired,
};

PeopleTabContainer.defaultProps = {
  groups: [],
  selectedGroup: null,
};

const mapStateToProps = (state) => ({
  currentGroupId: state.peopleCurrentGroupId,
  exportFetching: state.peopleExportLoading,
  groups: state.groups,
  isDeleteIconDisabled: getIsDeleteIconDisabled(state),
  isImportButtonVisible: getIsImportButtonVisible(state),
  isShareIconDisabled: getIsShareIconDisabled(state),
  peopleSearchString: state.peopleSearchString,
  selectedGroup: getSelectedGroup(state),
  slideOutMenuOpen: state.slideOutMenuOpen,
  user: state.user,
  viewAlert: state.viewAlert,
});

const mapDispatchToProps = {
  closeViewAlert,
  emailGroup,
  exportPeopleFromGroup,
  openAddGroupToCampaignHandler,
  openCreateContactPopup: openAddContact,
  openDeleteGroupPopup,
  openImportPeopleStepOne,
  openShareGroupPopup,
  peopleExportAction: peopleExport,
  setPeopleGroupDescription,
  setSlideOutMenuOpen,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PeopleTabContainer);
