import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/buttons/icon';
import './composeHeaderBar.scss';

function ComposeHeaderBar(props) {
  const {
    className,
    titleId,
    children,
    handleMinimizeChange,
    minimizable,
    closable,
    closeWindow,
    minOrMaxClass,
  } = props;

  function handleHeaderBarClick(e) {
    if (
      e.target.className &&
      e.target.className.includes('compose-window-headerbar') &&
      minimizable
    ) {
      handleMinimizeChange(e);
    }
  }

  return (
    <div
      className={classNames('compose-window-headerbar', className)}
      onClick={handleHeaderBarClick}
    >
      {titleId && (
        <span className="title">
          <FormattedMessage id={titleId} />
        </span>
      )}
      {children}
      <div className="right-side-items">
        {minimizable && (
          <div className="min-max" onClick={handleMinimizeChange}>
            <div className={`toggle-line ${minOrMaxClass}`} />
          </div>
        )}
        {closable && (
          <Icon
            classes="tout-icon-close exit-button"
            color="gray"
            onClick={closeWindow}
          />
        )}
      </div>
    </div>
  );
}

ComposeHeaderBar.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  closable: PropTypes.bool,
  closeWindow: PropTypes.func,
  handleMinimizeChange: PropTypes.func,
  minOrMaxClass: PropTypes.string,
  minimizable: PropTypes.bool,
  titleId: PropTypes.string,
};

ComposeHeaderBar.defaultProps = {
  children: undefined,
  className: '',
  closable: false,
  closeWindow: () => {},
  handleMinimizeChange: () => {},
  minOrMaxClass: '',
  minimizable: false,
  titleId: '',
};

export default ComposeHeaderBar;
