import React from 'react';
import PropTypes from 'prop-types';
import ComposeAlert from 'web/composeWindow/components/composeAlert/composeAlert';
import { AlertIds } from 'web/composeWindow/libs/composeWindowConstants';
import './composeWindowAlerts.scss';

function ComposeWindowAlerts({ alerts, closeAlertById }) {
  const priority = {
    [AlertIds.emailLimitSingle]: 2,
    [AlertIds.pinnedSaveError]: 2,
    [AlertIds.fetchPinnedError]: 2,
    [AlertIds.missingFieldSubject]: 2,
    [AlertIds.missingFieldRecipient]: 2,
    [AlertIds.missingFieldBody]: 2,
    [AlertIds.singleFlowUnsubscribes]: 2,
    [AlertIds.setRecipientsError]: 2,
    [AlertIds.fetchGroupError]: 2,
    [AlertIds.composeLoadError]: 2,
    [AlertIds.emailLimit]: 2,
    [AlertIds.fetchDynamicFieldError]: 2,
    [AlertIds.singleFlowUnsubscribes]: 1,
    [AlertIds.singleFlowInvalidEmail]: 1,
    [AlertIds.singleFlowDomainBlock]: 1,
    [AlertIds.missingDynamicFields]: 1,
    [AlertIds.filledTemplateFetchError]: 1,
    [AlertIds.unfilledPromptError]: 2,
  };

  const _renderItems = (alerts) =>
    alerts
      .map((alert) => (
        <div className="compose-alert" key={alert.id}>
          <ComposeAlert
            alert={alert}
            onClose={() => closeAlertById(alert.id)}
          />
        </div>
      ))
      .sort((a, b) => priority[b.key] - priority[a.key]);

  return <div className="compose-alerts-container">{_renderItems(alerts)}</div>;
}

export default ComposeWindowAlerts;

ComposeWindowAlerts.propTypes = {
  alerts: PropTypes.array,
  closeAlertById: PropTypes.func.isRequired,
};

ComposeWindowAlerts.defaultProps = {
  alerts: [],
};
