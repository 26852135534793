import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';

// todo - make this represent sharing modal state? i.e. 'Unshare', 'Error', etc
export function unshareCampaignWarning(state = {}, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.unshareCampaignWarning:
      return action.data;
    case PopupActionTypes.close:
      return {};
    default:
      return state;
  }
}

export const initCampaignAlert = { id: '', values: {} };

export function campaignsTabCampaignAlert(state = initCampaignAlert, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.campaignAlertOpen:
      return { id: action.id, values: action.values };
    case ActionTypes.campaignsTab.state.campaignAlertClose:
      return initCampaignAlert;
    default:
      return state;
  }
}

export function campaignsTabGlobalDataLossTextId(state = '', action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.warnLosingData:
      return action.textId;
    case PopupActionTypes.close:
      return '';
    default:
      return state;
  }
}
