import React from 'react';
import PropTypes from 'prop-types';
import { ColorClasses } from 'libs/constantsStyles';

const AlertTriangleFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      height={height}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{`.a { fill: ${color} !important; }`}</style>
      </defs>
      <rect fill={color} height="18" id="Canvas" opacity="0" width="18" />
      <rect fill="#ff13dc" height="18" id="Canvas" opacity="0" width="18" />
      <path
        className="a"
        d="M8.5635,1.2895.2,16.256A.5.5,0,0,0,.636,17H17.364a.5.5,0,0,0,.436-.744L9.4365,1.2895a.5.5,0,0,0-.873,0ZM10,14.75a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,14.75v-1.5A.25.25,0,0,1,8.25,13h1.5a.25.25,0,0,1,.25.25Zm0-3a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,11.75v-6a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25Z"
      />
    </svg>
  );
};

AlertTriangleFilled.defaultProps = {
  color: ColorClasses.white,
  height: 18,
  width: 18,
};

AlertTriangleFilled.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default AlertTriangleFilled;
