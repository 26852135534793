import React from 'react';
import PropTypes from 'prop-types';
import TaskShared from 'components/task2';
import './task.scss';

const Task = ({ actionCreators, editState, windowMode, ...others }) => (
  <TaskShared
    onAutocompleteChange={actionCreators.onAutocompleteChange}
    onAutocompleteLeaving={actionCreators.onAutocompleteLeaving}
    onAutocompleteSelection={actionCreators.onAutocompleteSelection}
    onChange={actionCreators.onChange}
    onUserAutocompleteChange={actionCreators.onUserAutocompleteChange}
    onUserAutocompleteSelection={actionCreators.onUserAutocompleteSelection}
    {...others}
    task={editState}
    windowMode={windowMode}
  />
);

Task.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  autocompleteLoading: PropTypes.bool,
  autocompleteResults: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string.isRequired,
  editState: PropTypes.object,
  optionsDisableAll: PropTypes.bool,
  optionsDisableAutocomplete: PropTypes.bool,
  optionsDisableType: PropTypes.bool,
  optionsMarkComplete: PropTypes.bool,
  optionsShowContact: PropTypes.bool,
  windowMode: PropTypes.bool,
};

Task.defaultProps = {
  autocompleteLoading: false,
  editState: {},
  optionsDisableAll: false,
  optionsDisableAutocomplete: false,
  optionsDisableType: false,
  optionsMarkComplete: true,
  optionsShowContact: true,
  windowMode: false,
};

export default Task;
