import { createAction } from '@reduxjs/toolkit';
import languages from 'languages';
import { getSmartFiltersCollection } from 'web/tables/smartFilters/selectors/smartFiltersSelectors';
import { getSelectedSavedSearchName } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { saveAdvancedSearch as saveAdvancedSearchService } from 'web/commandCenter/modules/emailGridAdvancedSearch/services/advancedSearchService';
import { CommandCenterPopupIds } from 'web/commandCenter/libs/commandCenterConstants';
import {
  openPopup,
  closePopup,
  setPopupLoading,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import cloneDeep from 'lodash/cloneDeep';
import {
  isSavedSearchNameEmpty,
  isSavedSearchExists,
} from 'web/commandCenter/helpers/emailHelpers';
import {
  openSavedAdvancedSearch,
  markEmailSelectedSavedAdvancedSearchAsNotChanged,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/openAdvancedSearchActionCreators';
import { openAdvancedSearchFormPopup } from 'web/commandCenter/actionCreators/commandCenterEmailsActionCreators';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';
import { updateAdvancedSearch as updateAdvancedSearchService } from 'web/commandCenter/modules/emailGridAdvancedSearch/services/advancedSearchService';

export const addSavedEmailAdvancedSearch = createAction(
  'ADD_EMAIL_ADVANCED_SEARCH_SAVE'
);

export const initEmailAdvancedSearchSave = createAction(
  'INIT_EMAIL_ADVANCED_SEARCH_SAVE'
);

export const openSaveSearchPopup = () => (dispatch) =>
  dispatch(openPopup(CommandCenterPopupIds.advancedSearchSave));

export const closeSaveSearchPopup = () => (dispatch) =>
  dispatch(openPopup(CommandCenterPopupIds.advancedSearch));

export const changeAdvancedSearchSavedName = createAction(
  'CHANGE_ADVANCED_SEARCH_SAVED_NAME'
);

export const updateCurrentSavedSearch = (updatedSavedSearch) => (dispatch) => {
  dispatch(addSavedEmailAdvancedSearch({ search: updatedSavedSearch }));
  dispatch(openSavedAdvancedSearch(updatedSavedSearch.id));
};

export const cloneCurrentSavedAdvancedSearch = () => (dispatch, getState) => {
  const state = getState();
  const { currentSavedAdvancedSerchFilters } = state;
  const GROUP_NAME = 'Custom';
  const selectedSavedSearchName = getSelectedSavedSearchName(state);

  const params = {
    conversation_filter: {
      group: GROUP_NAME,
      id: null,
      name: languages.getStr('web.campaigns.cloneName', {
        name: selectedSavedSearchName,
      }),
      specs: {
        //here we can store other table settings in feature
        smartFiltersCollection: currentSavedAdvancedSerchFilters,
      },
    },
  };

  dispatch(setPopupLoading(true));
  saveAdvancedSearchService(params)
    .then((savedSearch) => {
      dispatch(setPopupLoading(false));
      dispatch(updateCurrentSavedSearch(savedSearch));
      dispatch(openAdvancedSearchFormPopup());
      dispatch(openPopup(CommandCenterPopupIds.advancedSearchClone));
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(EmailAlertIds.cloneSavedSearchError));
    });
};

export const saveAdvancedSearch = () => (dispatch, getState) => {
  const state = getState();
  const GROUP_NAME = 'Custom';

  const searchName = state.advancedSearchSavedName;
  const smartFiltersCollection = getSmartFiltersCollection(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  });

  if (isSavedSearchNameEmpty(searchName)) {
    dispatch(setPopupAlert(EmailAlertIds.createSavedSearchNameEmpty));
    return;
  }

  if (isSavedSearchExists(searchName, state.emailSavedAdvancedSearches)) {
    dispatch(setPopupAlert(EmailAlertIds.createSavedSearchNameExists));
    return;
  }

  const params = {
    conversation_filter: {
      group: GROUP_NAME,
      id: null,
      name: searchName,
      specs: {
        //here we can store other table settings in feature
        smartFiltersCollection,
      },
    },
  };

  dispatch(setPopupLoading(true));
  saveAdvancedSearchService(params).then((savedSearch) => {
    dispatch(setPopupLoading(false));
    dispatch(closePopup());
    dispatch(updateCurrentSavedSearch(savedSearch));
  });
};

export const saveAdvancedSearchChanges = () => (dispatch, getState) => {
  const { emailSelectedSavedAdvancedSearchId } = getState();

  const smartFiltersCollection = getSmartFiltersCollection(getState(), {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  });

  const params = {
    conversation_filter: {
      specs: {
        //here we can store other table settings in feature
        smartFiltersCollection,
      },
    },
    savedSearchId: emailSelectedSavedAdvancedSearchId,
  };

  dispatch(setPopupLoading(true));
  updateAdvancedSearchService(params)
    .then((savedSearch) => {
      dispatch(setPopupLoading(false));
      dispatch(updateCurrentSavedSearch(savedSearch));
      dispatch(openAdvancedSearchFormPopup());
      dispatch(markEmailSelectedSavedAdvancedSearchAsNotChanged());
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(EmailAlertIds.updateSavedSearchError));
    });
};

export const initSavedAdvancedSearches = () => (dispatch, getState) => {
  const state = getState();
  if (!state.emailSavedAdvancedSearchesIsInit) {
    const advancedSearches = cloneDeep(
      window.toutAppBootstrap.conversationfiltersData
    );
    dispatch(initEmailAdvancedSearchSave());
    dispatch(addSavedEmailAdvancedSearch({ search: advancedSearches }));
  }
};

export const setCurrentSavedAdvancedSearchFilters = createAction(
  'SET_CURRENT_SAVED_ADVANCED_SEARCH_FILTERS'
);
