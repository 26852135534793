import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EmailComposePopupIds } from '../libs/composeEmailPopupConstants';
import ActionsRequiredPopup from './popups/actionsRequiredPopup';
import EmailVerificationRequiredPopup from './popups/emailVerificationRequiredPopup';
import NotVerifiedSmtpNotVerifiedEmail from './popups/notVerifiedSmtpNotVerifiedEmail';
import SmtpRequiredPopup from './popups/smtpRequiredPopup';
import VerifiedSmtpNotVerifiedEmail from './popups/verifiedSmtpNotVerifiedEmail';

class ComposeEmailPopup extends Component {
  render() {
    return this.getPopup();
  }

  getPopup = () => {
    const { popup } = this.props;

    switch (popup) {
      case EmailComposePopupIds.actionsRequired:
        return <ActionsRequiredPopup {...this.props} />;
      case EmailComposePopupIds.emailVerificationRequired:
        return <EmailVerificationRequiredPopup {...this.props} />;
      case EmailComposePopupIds.notVerifiedSmtpNotVerifiedEmail:
        return <NotVerifiedSmtpNotVerifiedEmail {...this.props} />;
      case EmailComposePopupIds.smtpRequired:
        return <SmtpRequiredPopup {...this.props} />;
      case EmailComposePopupIds.verifiedSmtpNotVerifiedEmail:
        return <VerifiedSmtpNotVerifiedEmail {...this.props} />;
      default:
        return null;
    }
  };
}

ComposeEmailPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  popup: PropTypes.string.isRequired,
};

export default ComposeEmailPopup;
