import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { SlackName } from '../libs/slackConstants';

export const getSlackIntegration = () =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.contactIntegrations.byProvider(SlackName),
      {},
      resolve,
      reject
    );
  });
