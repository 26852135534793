/*  To use, wrap your component:
 *    export default keyDownHandler(Component);
 *
 *  API:
 *    WrappedComponent - React Component - component to be wrapped
 *    WrappedComponent.keyDownMap - { [keyName]: String, [keyName]: String, ... }
 *      - class variable that maps calls to specific class functions in wrapped component
 *        i.e., your component wants to map 'up' to WrappedComponent.handleUp and 'down' to
 *          WrappedComponent.handleDown. Function will then be called when key code matches.
 *    WrappedComponent.keyDownName - String - class variable to let wrapped component determine
 *       action. The calledfunction would then check or switch statement the keyCode when invoked.
 */

import React, { Component } from 'react';

export const keyNames = {
  delete: 'delete',
  tab: 'tab',
  return: 'return',
  escape: 'escape',
  left: 'left',
  up: 'up',
  right: 'right',
  down: 'down',
};

export const keyCodesToNames = {
  8: keyNames.delete,
  9: keyNames.tab,
  13: keyNames.return,
  27: keyNames.escape,
  37: keyNames.left,
  38: keyNames.up,
  39: keyNames.right,
  40: keyNames.down,
};

export const keyNamesToCodes = {
  [keyNames.delete]: 8,
  [keyNames.tab]: 9,
  [keyNames.return]: 13,
  [keyNames.escape]: 27,
  [keyNames.left]: 37,
  [keyNames.up]: 38,
  [keyNames.right]: 39,
  [keyNames.down]: 40,
};

export default function onKeyDownHandler(WrappedComponent) {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  return class keyDownHandler extends Component {
    constructor() {
      super();
      this.wrappedComponentRef = null;
    }

    static displayName = `KeyDown${componentName}`;

    componentDidMount() {
      window.addEventListener('keydown', this._handleKeyDown, false);
    }

    componentWillUnmount() {
      window.removeEventListener('keydown', this._handleKeyDown, false);
    }

    render() {
      return <WrappedComponent {...this.props} ref={this._setRef} />;
    }

    _setRef = (node) => {
      this.wrappedComponentRef = node || null;
      this.keyDownMap = (node && node.keyDownMap) || {};
      this.keyDownAction = node && node.keyDownAction;
    };

    _handleKeyDown = (e) => {
      const keyProp = keyCodesToNames[e.keyCode];

      /* If passed key map handles, call it.
         Else if there is a catch all action, call it. */
      if (this.keyDownMap[keyProp]) {
        this.keyDownMap[keyProp](e.keyCode);
      } else if (this.keyDownAction) {
        this.keyDownAction(e.keyCode);
      }
    };
  };
}
