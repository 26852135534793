export default {
  appendTemplates: 'COMPOSE_TEMPLATES_APPEND',
  clearEditPinnedCategories: 'COMPOSE_EDIT_PINNED_TEMPLATE_CATEGORIES_CLEAR',
  hideRecommendedTemplates: 'COMPOSE_TEMPLATES_HIDE_RECOMMENDED_TEMPLATES',
  resetTemplates: 'COMPOSE_TEMPLATES_RESET_TEMPLATES',
  setCategories: 'COMPOSE_TEMPLATES_CATEGORIES_SET',
  setEditPinnedCategories: 'COMPOSE_EDIT_PINNED_TEMPLATE_CATEGORIES_SET',
  setLoading: 'COMPOSE_TEMPLATES_LOADING',
  setPinnedCategoriesLoading: 'COMPOSE_PINNED_CATEGORIES_LOADING',
  setPinnedTemplateCategories: 'COMPOSE_PINNED_TEMPLATE_CATEGORIES_SET',
  setTemplates: 'COMPOSE_TEMPLATES_SET',
  setTemplatesMinimized: 'COMPOSE_TEMPLATES_MINIMIZED',
  showRecommendedTemplates: 'COMPOSE_TEMPLATES_SHOW_RECOMMENDED_TEMPLATES',
};
