/* Warning: prod settings are set in constants.js. This is done to avoid including all our environments in prod build. */

const BackendUrls = {
  local: 'https://localhost:3010',
  dev: 'https://dev.toutapp.com',
  qa1: 'https://qa1.toutapp.com',
  qa2: 'https://qa2.toutapp.com',
  staging: 'https://staging.toutapp.com',
  production: 'https://toutapp.com',
};

const ImagesUrls = {
  local: 'https://dev.toutapp.com/owa/images', // 'https://localhost:3010/images',
  dev: 'https://dev.toutapp.com/owa/images',
  qa1: 'https://d2owagxlf083ds.cloudfront.net/owa-qa1/images',
  qa2: 'https://d2owagxlf083ds.cloudfront.net/owa-qa2/images',
  staging: 'https://d2owagxlf083ds.cloudfront.net/owa-staging/images',
  production: 'https://d2owagxlf083ds.cloudfront.net/owa/images',
};

const PusherEnvs = {
  local: 'owadev',
  dev: 'owadev',
  qa1: 'owaqa1',
  qa2: 'owaqa2',
  staging: 'owastaging',
  production: 'owaproduction',
};

const PusherKeys = {
  local: '3479fa99c49db0822997',
  dev: '3b8099fe8829227fabc2',
  qa1: '3479fa99c49db0822997',
  qa2: '3479fa99c49db0822997',
  staging: '3b8099fe8829227fabc2',
  production: '3479fa99c49db0822997',
};

const RollbarEnvs = {
  dev: 'development',
  staging: 'staging',
  production: 'production',
};

// const ToutEnv = {
//   local: 'local',
//   dev: 'dev',
//   qa1: 'qa1',
//   qa2: 'qa2',
//   staging: 'staging',
//   stagingBeta: 'staging-beta',
//   beta: 'beta',
//   production: 'production'
// };

/* eslint no-process-env: 0 */
export const BaseUrl = BackendUrls[process.env.TOUT_ENV] || BackendUrls.staging;
export const BaseUrlImages =
  ImagesUrls[process.env.TOUT_ENV] || ImagesUrls.staging;
export const PusherEnvironment =
  PusherEnvs[process.env.TOUT_ENV] || PusherEnvs.staging;
export const PusherKey = PusherKeys[process.env.TOUT_ENV] || PusherKeys.staging;
export const RollbarEnvironment =
  RollbarEnvs[process.env.TOUT_ENV] || RollbarEnvs.staging;
