import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './chipView.scss';

class ChipView extends Component {
  render() {
    const { value, className, selected, valid } = this.props;

    const chipClassNames = classNames('tout-chip-view', className, {
      selected,
      valid,
    });

    return (
      <div
        className={chipClassNames}
        onClick={this.handleItemClick}
        onDoubleClick={this.handleItemDoubleClick}
        unselectable="on"
      >
        {value}
        <div
          className={classNames('tout-icon-close')}
          onClick={this.handleCloseClick}
        />
      </div>
    );
  }

  handleItemClick = (event) => {
    const { ctrlKey, shiftKey } = event;
    const { valid, onSelect, onEdit } = this.props;
    event.preventDefault();
    event.stopPropagation();

    if (ctrlKey || shiftKey || valid) {
      onSelect(ctrlKey, shiftKey);
    } else {
      onEdit();
    }
  };

  handleItemDoubleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { onEdit } = this.props;
    onEdit();
  };

  handleCloseClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { onRemove } = this.props;
    onRemove();
  };
}

ChipView.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  valid: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

ChipView.defaultProps = {
  className: '',
  value: '',
  selected: false,
  valid: true,
  onSelect: () => {},
  onEdit: () => {},
  onRemove: () => {},
};

export default ChipView;
