import {
  openCall as openCallCommon,
  openEmail as openEmailCommon,
  openInMail as openInMailCommon,
} from 'web/tasks/actionCreators/tasksActionsActionCreators';
import { setTableLoading } from './tablesTasksStateActionCreators';
import { TaskType } from 'libs/constantsShared';
import { TaskProperties } from 'web/libs/mixpanelEvents';
import { TasksTablePopupIds } from '../libs/tablesTasksConstants';
import { trackTaskAction } from 'web/tables/tasks/services/tasksTableMixpanel';
import { openModalTask } from 'web/modals/task';
import { navigateToCampaignDetailsTab } from 'web/services/routerService';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { isAdmin } from 'web/shared/services/accountService.js';
import { getCampaign } from 'web/campaigns/campaignsTab/services/campaignService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { CampaignsSpecialCategories } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';

/* Mixpanel done in tasksActionCreators' taskAction which dispatches all of these ACs */
export function openEmail(task = {}) {
  return (dispatch) => {
    dispatch(openEmailCommon(task, setTableLoading));
  };
}

export function openCall(task = {}) {
  return (dispatch) => {
    dispatch(openCallCommon(task, setTableLoading));
  };
}

export function openInMail(task = {}) {
  return (dispatch) => {
    dispatch(openInMailCommon(task));
  };
}

export function taskAction(tableId, task = {}) {
  return (dispatch) => {
    let type = '';
    switch (task.remind_type) {
      case TaskType.email:
        dispatch(openEmail(task));
        type = TaskProperties.type.email;
        break;
      case TaskType.call:
        dispatch(openCall(task));
        type = TaskProperties.type.call;
        break;
      case TaskType.inMail:
        dispatch(openInMail(task));
        type = TaskProperties.type.inMail;
        break;
      case TaskType.other:
        openModalTask(task);
        type = TaskProperties.type.custom;
        break;
      default:
        break;
    }
    trackTaskAction(tableId, type);
  };
}

export function openTasksDelete(tasks = [], ids = [], callback = () => {}) {
  return (dispatch) => {
    dispatch(setTableLoading(true));

    const validIds = ids.filter((id) => {
      const task = tasks.find((obj) => obj.id === parseInt(id, 10));
      if (task && task.workflow_details && task.workflow_details.workflow_id) {
        return false;
      } else {
        return true;
      }
    });

    dispatch(setTableLoading(false));
    dispatch(
      openPopup(TasksTablePopupIds.tasksConfirmationDelete, {
        callback,
        campaignTasksCount: ids.length - validIds.length,
        ids: validIds,
      })
    );
  };
}

export function openCampaign(workflow, tab = null, personName = null) {
  return (dispatch) => {
    const { workflow_id: workflowId } = workflow;
    dispatch(setTableLoading(true));
    if (isAdmin()) {
      navigateToCampaignDetailsTab(
        CampaignsSpecialCategories.all.id,
        workflowId,
        tab,
        personName
      );
    } else {
      getCampaign(workflowId)
        .then(() => {
          navigateToCampaignDetailsTab(
            CampaignsSpecialCategories.all.id,
            workflowId,
            tab,
            personName
          );
        })
        .catch(() => {
          dispatch(openViewAlert(PeopleAlertIds.noAccessToCampaign));
        })
        .finally(() => {
          dispatch(setTableLoading(false));
        });
    }
  };
}
