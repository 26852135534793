import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { SourceTypes } from 'web/compliance/libs/complianceConstants';
import Label from 'components/label';
import Select2 from 'components/selects/select2/select2';
import Text from 'components/inputs/text';

class SourceEditing extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
    } = props;

    this.sourceItems = [
      {
        value: SourceTypes.crmSync,
        label: formatMessage({ id: 'web.people.compliance.crmSync' }),
      },
      {
        value: SourceTypes.import,
        label: formatMessage({ id: 'web.people.compliance.import' }),
      },
      {
        value: SourceTypes.manualUpload,
        label: formatMessage({ id: 'web.people.compliance.manualUpload' }),
      },
      {
        value: SourceTypes.chromeExtension,
        label: formatMessage({ id: 'web.people.compliance.chromeExtension' }),
      },
      {
        value: SourceTypes.salesforce,
        label: formatMessage({ id: 'common.salesforce' }),
      },
      {
        value: SourceTypes.marketing,
        label: formatMessage({ id: 'web.people.compliance.marketing' }),
      },
      {
        value: SourceTypes.gmail,
        label: formatMessage({ id: 'common.gmail' }),
      },
      {
        value: SourceTypes.o365,
        label: formatMessage({ id: 'web.people.compliance.o365' }),
      },
      {
        value: SourceTypes.sales,
        label: formatMessage({ id: 'web.people.compliance.sales' }),
      },
      {
        value: SourceTypes.other,
        label: formatMessage({ id: 'common.other' }),
      },
    ];
    this.noneSelectedPlaceholder = formatMessage({ id: 'common.noneSelected' });
    this.enterSourcePlaceholder = formatMessage({
      id: 'web.people.compliance.enterSource',
    });
  }

  render() {
    const {
      complianceEditState: { otherSourceValue, sourceType },
      labelClassName,
      labelWidth,
      sideLabel,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="compliance-editing-row compliance-editing-row-source">
        <Label
          id="person-details-source-types"
          classWrapper={labelClassName}
          messageKey="web.people.compliance.sourceType"
          sideLabel={sideLabel}
          width={labelWidth}
        >
          <Select2
            classes="compliance-select"
            options={this.sourceItems}
            onChange={this._onSourceChange}
            placeholder={this.noneSelectedPlaceholder}
            searchable={false}
            value={sourceType}
            clearValueText={formatMessage({
              id: 'campaigns.peopleSearchAdd.clearValue',
            })}
          />
        </Label>
        {sourceType === SourceTypes.other && (
          <Label
            id="person-details-source-other"
            className="hidden"
            classWrapper={labelClassName}
            messageKey="web.people.compliance.enterSource"
            width={labelWidth}
            sideLabel={sideLabel}
          >
            <Text
              className="other-source-input"
              onChange={this._onSourceValueChange}
              placeholder={this.enterSourcePlaceholder}
              type="text"
              value={otherSourceValue}
            />
          </Label>
        )}
      </div>
    );
  }

  _onSourceChange = (source) => {
    const sourceType = source ? source.value : '';

    this._setSourceType(sourceType);
  };

  _setSourceType = (sourceType) => this.props.updateCompliance({ sourceType });

  _onSourceValueChange = (value) =>
    this.props.updateCompliance({ otherSourceValue: value });
}

SourceEditing.propTypes = {
  complianceEditState: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  labelClassName: PropTypes.string.isRequired,
  labelWidth: PropTypes.number,
  sideLabel: PropTypes.bool,
  updateCompliance: PropTypes.func.isRequired,
};

SourceEditing.defaultProps = {
  labelWidth: 175,
  sideLabel: false,
};

export default injectIntl(SourceEditing);
