import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup, { PopupButtonColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { FormattedMessage } from 'react-intl';

class DeleteTeamConfirmationPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      defaultAlert,
      deleteTeamLoading,
    } = this.props;

    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.onDeleteTeam,
          onSecondary: closePopup,
          primaryTextId: 'common.delete',
          secondaryTextId: 'common.cancel',
          primaryColor: PopupButtonColors.red,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.teamManagement.teamMembers.deleteTeam',
        }}
        getImageUrl={getImageUrl}
        loading={deleteTeamLoading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedMessage id="web.settings.adminSettings.teamManagement.teamMembers.deleteTeam.areYouSure" />
        <div className="team-management-popup-warning">
          <div className="tout-icon-warning" />
          <FormattedMessage id="web.settings.adminSettings.teamManagement.teamMembers.deleteTeam.ifYouDelete" />
        </div>
      </Popup>
    );
  }

  onDeleteTeam = () => {
    const {
      actionCreators: { deleteTeam },
      popupData: { teamId },
    } = this.props;

    deleteTeam(teamId);
  };
}

DeleteTeamConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popupData: PropTypes.object,
  deleteTeamLoading: PropTypes.bool.isRequired,
  defaultAlert: PropTypes.object,
};

DeleteTeamConfirmationPopup.defaultProps = {
  popupData: {},
  defaultAlert: null,
};

export default DeleteTeamConfirmationPopup;
