import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { SalesforceCustomizationPopupIds } from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import SalesforceCustomizationVersionItem from './salesforceCustomizationVersionItem';

class SalesforceCustomizationVersions extends Component {
  render() {
    const versions = this._getAppropriateVersions();

    return versions.length ? (
      <div className="salesforce-customization-versions">
        <FormattedMessage id="web.salesforceCustomization.versions.title" />
        <ul className="versions-list">
          {versions.map((version) => (
            <SalesforceCustomizationVersionItem
              key={version.name}
              version={version}
            />
          ))}
        </ul>
      </div>
    ) : null;
  }

  _getAppropriateVersions = () => {
    const { popupId, availableVersions, installedVersions } = this.props;

    switch (popupId) {
      case SalesforceCustomizationPopupIds.salesforceCustomizationUninstallation:
        return installedVersions;
      case SalesforceCustomizationPopupIds.salesforceCustomizationInstallation:
        return availableVersions;
      default:
        return null;
    }
  };
}

SalesforceCustomizationVersions.propTypes = {
  availableVersions: PropTypes.array.isRequired,
  popupId: PropTypes.string.isRequired,
  installedVersions: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceCustomizationVersions);
