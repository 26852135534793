/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import GetStarted from '../components/getStarted';
import unauthorizedPage from 'components/hocs/unauthorizedPage';
import { track } from 'web/services/mixpanelService';
import { GetStartedEvents } from 'web/libs/mixpanelEvents';
import { isAdmin } from 'web/user/selectors/userSelectors';

class GetStartedContainer extends Component {
  componentDidMount() {
    track(GetStartedEvents.completed_survey, { valid: true });
  }

  render() {
    return <GetStarted {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  isAdmin: isAdmin(state),
});

export default compose(
  connect(mapStateToProps),
  (component) =>
    unauthorizedPage(component, {
      cardWidth: 800,
    })
)(GetStartedContainer);
