import { NextUrlImages } from 'web/libs/constants';

// attachments should all be finished uploading
export function parseIds(attachments) {
  attachments = attachments || [];
  return attachments.reduce((accum, curr) => {
    if (curr.id) {
      accum.push(curr.id);
    }
    return accum;
  }, []);
}

export function createContentHtml(content = []) {
  let html = '';
  for (let i = 0; i < content.length; i++) {
    //width: 396px; no border-collapse or margin
    html += `<table class="tout_linked_attachment" style="height: 18px; max-height: 18px; background-color: rgb(245, 245, 245); padding: 5px; color: rgb(34, 34, 34); font-family: arial; font-style: normal; font-weight: bold; font-size: 13px; cursor: default; border: 1px solid rgb(221, 221, 221); border-collapse: separate; margin: 5px 0;"><tr><td>
      <a href="${
        content[i].document_url
      }" target="_blank" style=" display:inline-block; max-width: 366px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; text-decoration: none; cursor: pointer; padding: 1px 0; border: none; "><img style="vertical-align: bottom; border: none;height:14px" height="14" src="${NextUrlImages}/images/shared-document-download.png">&nbsp;&nbsp;<span dir="ltr" style="color: rgb(17, 85, 204); text-decoration: initial; vertical-align: bottom;">${
      content[i].file_file_name
    }</span></a>
      </td></tr></table>`;
  }
  return html;
}
