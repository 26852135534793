import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { EditTemplateCards } from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import EmailComposeBody from 'web/emailComposeBody/emailComposeBodyContainer';
import './templateEditing.scss';

class TemplateEditing extends PureComponent {
  constructor() {
    super();

    this.id = `edit-template-${Date.now()}`;
  }

  componentWillMount() {
    this.subjectPlaceholder = this.props.intl.formatMessage({
      id: 'web.editTemplateSlideOut.templateCard.editing.subjectPlaceholder',
    });
    this.props.updateEditState(this.id, { editorId: this.id });
  }

  render() {
    const { attachments, templateEditState, updateEditState } = this.props;

    return (
      <div className="edit-template-template-editing">
        <EmailComposeBody
          bcc={templateEditState.bcc}
          cc={templateEditState.cc}
          defaultAttachments={attachments}
          defaultText={templateEditState.body}
          hideInsertTemplate
          hideSaveAsTemplate
          id={this.id}
          subject={templateEditState.subject}
          updateState={updateEditState}
        />
        <div className="template-editing-button-row margin-top-half">
          <Button
            color={ButtonColors.gray}
            onClick={this.cancel}
            size={ButtonSizes.standard}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className="margin-left-full"
            color={ButtonColors.blue}
            onClick={this.save}
            size={ButtonSizes.standard}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </div>
    );
  }

  save = () => this.props.save(EditTemplateCards.template);

  cancel = () => this.props.cancel(EditTemplateCards.template);
}

TemplateEditing.propTypes = {
  attachments: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  save: PropTypes.func.isRequired,
  templateEditState: PropTypes.object.isRequired,
  updateEditState: PropTypes.func.isRequired,
};

TemplateEditing.defaultProps = {};

export default injectIntl(TemplateEditing);
