import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/layouts/page/header';
import Card from 'components/card';
import './marketoNonAdminPage.scss';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import { ConnectionStates } from 'web/marketoSettings/libs/marketoSettingsConstants';
import { FormattedHTMLMessage } from 'react-intl';
import NonAdminCredentials from 'web/marketoNonAdmin/components/nonAdminCrendentials/nonAdminCrendentials';

class MarketoNonAdminPage extends Component {
  constructor(props) {
    super(props);
    props.getMarketoIntegration();
  }

  render() {
    return (
      <div className="marketo-settings-non-admin-page">
        <Header
          className="non-admin-settings-header"
          textId="web.marketoSettings.marketoConnection"
        />
        <div className="page-view background-gray-extra-light">
          <Card className="marketo-non-admin-info-card">
            {this._getFormArea()}
          </Card>
        </div>
      </div>
    );
  }

  _getFormArea = () => {
    const {
      connectionInfo: { munchkin },
      connectionState,
      saleforceConnectLink,
    } = this.props;

    switch (connectionState) {
      case ConnectionStates.gettingIntegration:
        return (
          <div className="card-loading-state">
            <LoadingSpinner imageUrl={getImageUrl} />
          </div>
        );
      case ConnectionStates.hasConnection:
        return (
          <NonAdminCredentials
            munchkin={munchkin}
            connectionState={connectionState}
          />
        );
      case ConnectionStates.noSalesforceConnection:
        return (
          <div>
            <FormattedHTMLMessage
              id="web.marketoSettings.noSalesforceConnection"
              values={{ link: saleforceConnectLink }}
            />
          </div>
        );
      default:
        return (
          <p>
            <FormattedHTMLMessage id="web.marketoSettings.contactSalesAdmin" />
          </p>
        );
    }
  };
}

MarketoNonAdminPage.propTypes = {
  getMarketoIntegration: PropTypes.func.isRequired,
  connectionState: PropTypes.string.isRequired,
  connectionInfo: PropTypes.object.isRequired,
  saleforceConnectLink: PropTypes.string.isRequired,
};

export default MarketoNonAdminPage;
