export const InviteesTableColumns = {
  email: 'EMAIL',
  sentAt: 'SENT_AT',
  expiresOn: 'EXPIRES_ON',
  actions: 'ACTIONS',
};

export const InviteesPopupIds = {
  withdrawInviteConfirmation: 'INVITEES_WITHDRAW_INVITE_CONFIRMATION',
  withdrawInvitesConfirmation: 'INVITEES_WITHDRAW_INVITES_CONFIRMATION',
  inviteUsers: 'INVITEES_INVITE_USERS',
  inviteUsersResult: 'INVITEES_INVITE_USERS_RESULT',
  needMoresSeats: 'INVITEES_NEED_MORE_SEATS',
};

export const INVITE_USERS_FORM_ID = 'INVITEES_INVITE_USERS_FORM';

export const InviteesResultMessage = {
  'Exists. Contact support':
    'web.settings.adminSettings.userManagement.invitees.inviteUsers.result.message.exists',
  'Invitation not sent':
    'web.settings.adminSettings.userManagement.invitees.inviteUsers.result.message.invitationNotSent',
  'Invitation Sent':
    'web.settings.adminSettings.userManagement.invitees.inviteUsers.result.message.invitationSent',
  'Invitation resent':
    'web.settings.adminSettings.userManagement.invitees.inviteUsers.result.message.invitationResent',
  'Added to team(s)':
    'web.settings.adminSettings.userManagement.invitees.inviteUsers.result.message.addedToTeams',
};
