/**
 *
 * Cell
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Shapes from 'table/modules/shapes';
import { WidthMode } from 'table/modules/constants';
import './cell.scss';

class Cell extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, isHeader, cssClasses, resizeTableHandler } = this.props;
    const cellStyles = this._getCellStyles(this.props);

    const className = classNames(cssClasses, {
      'tout-cell': !isHeader,
      'tout-cell-header': isHeader,
    });

    return (
      <div
        className={className}
        onMouseDown={resizeTableHandler}
        style={cellStyles}
      >
        {children}
      </div>
    );
  }

  _getCellStyles({ flex, height, width, widthMode, isResize }) {
    const cellStyles = { height: `${height}px` };

    if (isResize) {
      cellStyles.width = `${width}px`;
      cellStyles.flex = '';
    } else if (flex || !width) {
      cellStyles.flex = '1';
      cellStyles.minWidth = `${width}px`;
    } else if (widthMode === WidthMode.PERCENT) {
      cellStyles.flex = '';
      cellStyles.width = `${width}%`;
    } else {
      cellStyles.flex = '';
      cellStyles.width = `${width}px`;
    }

    return cellStyles;
  }
}

Cell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  cssClasses: PropTypes.string,
  flex: PropTypes.bool,
  height: PropTypes.number.isRequired,
  isHeader: PropTypes.bool,
  isResize: PropTypes.bool,
  resizeTableHandler: PropTypes.func,
  width: PropTypes.number.isRequired,
  widthMode: PropTypes.string,
};

Cell.defaultProps = {
  cssClasses: '',
  flex: false,
  height: 44,
  isHeader: false,
  isResize: false,
  resizeTableHandler: () => {},
  width: 0,
  widthMode: WidthMode.PIXEL,
};

export default Cell;
