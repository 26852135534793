import TrackingActionTypes from '../libs/trackingActionTypes';
import { TrackingTabs } from '../libs/trackingConstants';

export const activeTab = (
  state = TrackingTabs.customDomainTracking,
  { type, tab }
) => {
  switch (type) {
    case TrackingActionTypes.setTab:
      return tab;
    default:
      return state;
  }
};
