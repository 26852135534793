import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { campaignsUsingTemplateStartup } from '../actionCreators/campaignsUsingTemplateStartup';
import CampaignsUsingTemplate from 'web/campaignsUsingTemplate/components/campaignsUsingTemplate';
import './campaignsUsingTemplateContainer.scss';

class CampaignsUsingTemplateContainer extends Component {
  componentWillMount() {
    const { dispatch, templateId } = this.props;
    dispatch(campaignsUsingTemplateStartup(templateId));
  }

  render() {
    const { activeCampaignTemplates, className, loading } = this.props;

    return (
      <div
        className={classNames('campaigns-using-template-container', className)}
      >
        <CampaignsUsingTemplate
          activeCampaignTemplates={activeCampaignTemplates}
          loading={loading}
        />
      </div>
    );
  }
}

CampaignsUsingTemplateContainer.propTypes = {
  activeCampaignTemplates: PropTypes.array.isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  templateId: PropTypes.number.isRequired,
};

CampaignsUsingTemplateContainer.defaultProps = {
  className: '',
};

function mapStateToProps(state) {
  return {
    activeCampaignTemplates:
      state.campaignsUsingTemplateState.campaignTemplates,
    loading: state.campaignsUsingTemplateState.loading,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(CampaignsUsingTemplateContainer);
