import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import outsideClickHandler from 'components/hocs/outsideClickHandler';
import List from 'components/list';
import { itemShape } from 'components/list/listShapes';
import './menu.scss';

class Menu extends PureComponent {
  render() {
    const { className, ...other } = this.props;
    return <List className={classNames('tout-menu', className)} {...other} />;
  }
}

export const menuItemShape = itemShape;

Menu.propTypes = {
  className: PropTypes.string,
  exceptionSelectors: PropTypes.array, // outsideClickHandler
  handleSelected: PropTypes.func.isRequired,
  highlightColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  itemRenderer: PropTypes.func,
  items: PropTypes.arrayOf(itemShape).isRequired,
  maxWidth: PropTypes.number,
  onOutsideClick: PropTypes.func, // outsideClickHandler
  rowHeight: PropTypes.number,
  rowsShown: PropTypes.number,
  selected: PropTypes.number,
  separateItems: PropTypes.bool,
  shownHeight: PropTypes.number,
  style: PropTypes.object,
};

Menu.defaultProps = {
  className: '',
  exceptionSelectors: [],
  highlightColor: BackgroundColors.grayExtraLight,
  itemRenderer: undefined, // undefined so that the default renderer of <List> is used
  maxWidth: 0,
  onOutsideClick: () => {},
  rowHeight: 24,
  rowsShown: 4,
  selected: -1,
  separateItems: false,
  shownHeight: 0,
  style: {},
};

export default outsideClickHandler(Menu);

export let UnwrappedMenu; //eslint-disable-line import/no-mutable-exports
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedMenu = Menu;
}
