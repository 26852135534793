import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PersonDetailsPopupIds } from '../libs/personDetailsConstants';
import PersonDetails from '../components/personDetails';
import PersonDetailsPopupContainer from './personDetailsPopupContainer';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  clearTabAlert,
  closeCardEditing,
  closePersonDetails,
  getMarketoEvents,
  onTabSelect,
  openCardEditing,
  openEmailInHistory,
  openPersonDetails,
  setEmailId,
  setTabAlert,
} from '../actionCreators/personDetailsActionCreators';
import {
  openInMarketo,
  openInSalesforce,
} from 'web/person/actionCreators/personDetailsActionsActionCreators';
import * as infoACs from '../actionCreators/personDetailsInfoActionCreators';
import * as actionACs from '../actionCreators/personDetailsActionsActionCreators';
import {
  onInfoEditAddressTypeChange,
  openItemClick,
  viewMoreOnClick,
} from '../helpers/personDetailsMixpanel';
import {
  getCallRecordingEnabled,
  getComplianceCardHasChanges,
  getCustomFieldsCardHasChanges,
  getEmailsState,
  getExportPerson,
  getGroupsAddable,
  getGroupsCardHasChanges,
  getInfoCardHasChanges,
  getMarketoEventsState,
  getPerson,
  getTasksUncompletedCount,
  getToutEventsState,
  sortUnsubscribeHistoryByDate,
  getUnsubscribedFrom,
  getPersonDetailsLoading,
} from '../selectors/personDetailsSelectors';
import {
  getOldestAdminId,
  isMsiActions,
} from 'web/user/selectors/userSelectors';
import { getReasonLabelsGroupById as unsubscribeOptionsGroupById } from 'web/unsubscribe/unsubscribeOptions/selectors/unsubscribeOptionsSelectors';
import { REASON_TYPE } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';
import { validateComplianceFields } from 'web/person/helpers/personDetailsEditStateParsers';

class PersonDetailsContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          clearTabAlert,
          closeCardEditing,
          getMarketoEvents,
          onTabSelect,
          openCardEditing,
          openEmailInfo: openEmailInHistory,
          openInMarketo,
          openInSalesforce,
          setTabAlert,
          ...infoACs,
          ...actionACs,
        },
        dispatch
      ),
      onClose: () => dispatch(closePersonDetails(this.props.onClose)),
      onInfoEditAddressTypeChange,
      openDeleteNote: (noteId) =>
        dispatch(openPopup(PersonDetailsPopupIds.deleteNote, { id: noteId })),
      openItemClick,
      openRemoveUnsubscribe: () =>
        dispatch(openPopup(PersonDetailsPopupIds.removeUnsubscribe)),
      openUnsubscribe: () =>
        dispatch(openPopup(PersonDetailsPopupIds.unsubscribe)),
      viewMoreOnClick,
    };
  }

  componentDidMount() {
    const { dispatch, emailId, id, tab } = this.props;

    dispatch(openPersonDetails(id, { emailId, tab }));
  }

  componentDidUpdate({ emailId: prevEmailId, id: prevId }) {
    const { dispatch, emailId, id } = this.props;
    if (id !== prevId && id > 0) {
      dispatch(openPersonDetails(id));
    }
    if (emailId > 0 && emailId !== prevEmailId) {
      dispatch(setEmailId(emailId));
    }
  }

  render() {
    const { dispatch: ignored1, ...other } = this.props;
    return [
      <PersonDetailsPopupContainer
        key="person-details-popup-container"
        onClose={this.actionCreators.onClose}
      />,
      <PersonDetails
        actionCreators={this.actionCreators}
        dispatch={ignored1}
        key="person-details"
        {...other}
      />,
    ];
  }
}

PersonDetailsContainer.propTypes = {
  accountInfoEditState: PropTypes.object.isRequired,
  callRecordingEnabled: PropTypes.bool.isRequired,
  complianceCardCanSave: PropTypes.bool.isRequired,
  complianceEditState: PropTypes.object.isRequired,
  customFieldsCardHasChanges: PropTypes.bool.isRequired,
  customFieldsEditState: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  editing: PropTypes.object.isRequired,
  emailId: PropTypes.number, // passed in and stored
  emailsState: PropTypes.object.isRequired,
  exportData: PropTypes.object.isRequired,
  exporting: PropTypes.bool.isRequired,
  groupsAddable: PropTypes.array.isRequired,
  groupsCardHasChanges: PropTypes.bool.isRequired,
  groupsEditState: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired, // passed in
  infoCardHasChanges: PropTypes.bool.isRequired,
  infoEditState: PropTypes.object.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  marketoEventsState: PropTypes.object.isRequired,
  oldestAdminId: PropTypes.number,
  onClose: PropTypes.func.isRequired, // passed in
  person: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string,
  salesforceRequiredFields: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  tabAlert: PropTypes.object.isRequired,
  tabLoading: PropTypes.bool.isRequired,
  tasksUncompletedCount: PropTypes.number.isRequired,
  toutEventsState: PropTypes.object.isRequired,
  unsubscribeOptInReasons: PropTypes.object,
  unsubscribeReasons: PropTypes.object,
  unsubscribedFrom: PropTypes.string,
  user: PropTypes.object.isRequired,
  userSubscriptionUsers: PropTypes.object,
};

PersonDetailsContainer.defaultProps = {
  emailId: null,
  oldestAdminId: null,
  salesforceInstanceUrl: '',
  unsubscribeOptInReasons: {},
  unsubscribeReasons: {},
  unsubscribedFrom: '',
  userSubscriptionUsers: {},
};

function mapStateToProps(state) {
  return {
    accountInfo: state.personDetailsAccountInfo,
    accountInfoEditState: state.personDetailsAccountInfoEditState,
    accountInfoLoading: state.personDetailsAccountInfoLoading,
    callRecordingEnabled: getCallRecordingEnabled(state),
    complianceCardCanSave:
      validateComplianceFields(state) && getComplianceCardHasChanges(state),
    complianceEditState: state.personDetailsComplianceEditState,
    customFieldsCardHasChanges: getCustomFieldsCardHasChanges(state),
    customFieldsEditState: state.personDetailsCustomFieldsEditState,
    editing: state.personDetailsEditing,
    emailsState: getEmailsState(state),
    exportData: getExportPerson(state),
    exporting: state.personDetailsExporting,
    groups: state.groups,
    groupsAddable: getGroupsAddable(state),
    groupsCardHasChanges: getGroupsCardHasChanges(state),
    groupsEditState: state.personDetailsGroupsEditState,
    infoCardHasChanges: getInfoCardHasChanges(state),
    infoEditState: state.personDetailsInfoEditState,
    isMsiActions: isMsiActions(state),
    marketoEventsState: getMarketoEventsState(state),
    oldestAdminId: getOldestAdminId(state),
    person: getPerson(state),
    salesforceConnectionData: state.personDetailsSalesforceConnectionData,
    salesforceRequiredFields: state.personDetailsSalesforceRequiredFields,
    tab: state.personDetailsTab,
    tabAlert: state.personDetailsTabAlert,
    tabLoading: getPersonDetailsLoading(state),
    tasksUncompletedCount: getTasksUncompletedCount(state),
    toutEventsState: getToutEventsState(state),
    unsubscribeHistory: sortUnsubscribeHistoryByDate(state),
    unsubscribeOptInReasons: unsubscribeOptionsGroupById(
      state,
      REASON_TYPE.optIn
    ),
    unsubscribeReasons: unsubscribeOptionsGroupById(
      state,
      REASON_TYPE.unsubscribe
    ),
    unsubscribedFrom: getUnsubscribedFrom(state),
    user: state.user,
    userSubscriptionUsers: state.userSubscriptionUsers,
  };
}

export default connect(mapStateToProps)(PersonDetailsContainer);
