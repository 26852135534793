import UnsubscribeOptionsActionTypes from '../libs/unsubscribeOptionsActionTypes';
import { getUnsubscribeOptions as getUnsubscribeOptionsCall } from 'web/people/services/peopleService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { UnsubscribeOptionsAlertIds } from '../libs/unsubscribeOptionsConstants';

export const loadingUnsubscribeOptions = (loading) => ({
  type: UnsubscribeOptionsActionTypes.loadingUnsubscribeOptions,
  loading,
});

export const getUnsubscribeOptions = () => (dispatch) => {
  dispatch(loadingUnsubscribeOptions(true));

  return getUnsubscribeOptionsCall()
    .then((data) => {
      dispatch(loadingUnsubscribeOptions(false));
      dispatch({
        type: UnsubscribeOptionsActionTypes.setUnsubscribeOptions,
        data,
      });
    })
    .catch(() => {
      dispatch(loadingUnsubscribeOptions(false));
      dispatch(openViewAlert(UnsubscribeOptionsAlertIds.getError));
    });
};
