import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import Card from 'components/card';
import { getImageUrl } from 'web/libs/constants';
import LogoAdobe from 'components/logoAdobe/logoAdobe';
import Language from 'web/login/components/language';
import UnderCardSection from './underCardSection';
import BottomMenu from './bottomMenu';
import './unauthorizedPage.scss';
import { languagesOptions } from 'web/settings/myProfile/components/timezoneSettings/libs/timezoneSettingsConstants';
import { parseLanguageItems } from 'web/settings/myProfile/components/timezoneSettings/helpers/timezoneSettingsHelper';
import I18N from 'languages';

export default function unauthorizedPage(
  WrappedComponent,
  {
    backgroundLayoutMode = true,
    cardWidth,
    titleKey = '',
    parentClassName = '',
    showLogo = true,
  }
) {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  class UnauthorizedPage extends Component {
    constructor(props) {
      super(props);

      const {
        intl: { formatMessage },
      } = props;

      document.title = 'Marketo Sales';
      if (titleKey) {
        document.title = `${formatMessage({ id: titleKey })} | ${
          document.title
        }`;
      }
      if (!I18N.getCookie('i18nLangUpdated')) {
        I18N.setCookie('i18nLangUpdated', false);
      }
    }

    static displayName = `UnauthorizedPage${componentName}`;

    render() {
      return (
        <Page className="unauthorized-adobe-page">
          <PageView
            className={classNames('unauthorized-page-page-view', {
              'no-background': !backgroundLayoutMode,
            })}
          >
            <div
              className="unauthorized-background-image"
              style={{
                backgroundImage: `url(${getImageUrl(
                  'unauthorized-page-background-dark',
                  'png'
                )})`,
              }}
            />
            {showLogo && <LogoAdobe className="logo-adobe" displayText />}
            {I18N.getCookie('enableLoginLanguage') === 'true' && (
              <Language items={parseLanguageItems(languagesOptions)} />
            )}
            <div className={classNames('content-part', parentClassName)}>
              {backgroundLayoutMode && (
                <h1 className="unauthorized-title">
                  <FormattedMessage id="web.unauthorized.title" />
                </h1>
              )}
              <Card width={cardWidth}>
                <WrappedComponent {...this.props} />
              </Card>
              {backgroundLayoutMode && this.underCardBlock}
            </div>
            {backgroundLayoutMode && <BottomMenu />}
          </PageView>
        </Page>
      );
    }

    get underCardBlock() {
      return componentName === 'LoginContainer' ? <UnderCardSection /> : null;
    }

    static propTypes = {
      intl: intlShape.isRequired,
    };
  }

  return injectIntl(UnauthorizedPage);
}
