import { baseDelete, basePost, basePut } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { htmlToText } from 'web/services/parsers';
import {
  commonUserAdminTeamPermission,
  isCurrentUser,
} from 'web/shared/services/accountService';

export function createNote(content, notableId, notableType) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.notes,
      {
        note: {
          content: htmlToText(content),
          notable_id: notableId,
          notable_type: notableType,
        },
      },
      resolve,
      reject
    );
  });
}

export function updateNote(id, data = {}) {
  return new Promise((resolve, reject) => {
    const note = { ...data, content: htmlToText(data.content) };
    basePut(Urls.notesId.replace(':#id', id), { note }, resolve, reject);
  });
}

export function deleteNote(id) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.notesId.replace(':#id', id), {}, resolve, reject);
  });
}

export function canEditNote(userId) {
  return isCurrentUser(userId);
}

export function canDeleteNote(userId) {
  return commonUserAdminTeamPermission(userId);
}
