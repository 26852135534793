import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Label from 'components/label';
import RadioGroup from 'components/buttons/radioGroup';
import SelectIdentities from 'components/selects/identities';
import Select2 from 'components/selects/select2';
import Autocomplete from 'components/inputs/autocomplete';
import PeopleList from './peopleList';
import './peopleSearchAdd.scss';
import { getLocalizedDateFromGroupName } from 'web/people/helpers/peopleHelpers';

class PeopleSearchAdd extends Component {
  state = {
    groupOptions: this._parseGroups(this.props.groups),
    searchGroups: true,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.groups.length !== this.props.groups.length) {
      this.setState({ groupOptions: this._parseGroups(nextProps.groups) });
    }
  }

  render() {
    const {
      autocompleteLoading,
      autocompleteOnChange,
      autocompleteOnLeaving,
      autocompleteResults,
      autocompleteValue,
      identities,
      intl: { formatMessage },
      onGroupSelect,
      onPersonCheck,
      onPersonSelect,
      people,
      selectedIdentity,
      selectedIndex,
      setIdentity,
      groups,
    } = this.props;
    const { groupOptions, searchGroups } = this.state;
    const formattedGroupName = groups.map((group) => ({
      value: group.id,
      label: getLocalizedDateFromGroupName(group.name),
    }));

    return (
      <div className="peopleSearchAdd">
        <div className="margin-bottom-full">
          {formatMessage({ id: 'campaigns.peopleSearchAdd.instructions' })}
        </div>
        <div className="margin-bottom-full">
          <RadioGroup
            className="group-individuals-choice"
            items={[
              {
                label: formatMessage({ id: 'common.groups' }),
                value: true,
              },
              {
                label: formatMessage({ id: 'common.individuals' }),
                value: false,
              },
            ]}
            onChange={this.onRadioChange}
            selected={searchGroups}
          />

        </div>
        <div className="margin-bottom-full">
          {searchGroups ? (
            <Select2
              noResultsText={formatMessage({
                id: 'campaigns.peopleSearchAdd.noGroups',
              })}
              onChange={onGroupSelect}
              options={formattedGroupName}
              placeholder={formatMessage({
                id: 'campaigns.peopleSearchAdd.placeholderGroups',
              })}
              searchable
              clearValueText={formatMessage({
                id: 'campaigns.peopleSearchAdd.clearValue',
              })}
            />
          ) : (
            <Autocomplete
              className="campaign-people-search-input"
              dropdownScrollable
              loading={autocompleteLoading}
              onChange={autocompleteOnChange}
              onLeaving={autocompleteOnLeaving}
              onSelection={onPersonSelect}
              placeholder={formatMessage({
                id: 'campaigns.peopleSearchAdd.placeholderIndividuals',
              })}
              results={autocompleteResults}
              value={autocompleteValue}
            />
          )}
        </div>
        <div className="margin-bottom-full">
          <PeopleList
            onPersonCheck={onPersonCheck}
            people={people}
            selectedIndex={selectedIndex}
          />
        </div>
        <Label
          id="select-identity"
          messageKey="campaigns.peopleSearchAdd.from"
          sideLabel
          width={35}
        >
          <SelectIdentities
            full
            items={identities}
            onChange={setIdentity}
            selected={selectedIdentity}
          />
        </Label>
      </div>
    );
  }

  onRadioChange = (value) => {
    this.setState({ searchGroups: value });
  };

  _parseGroups(groups) {
    return groups.map((group) => ({ label: group.name, value: group.id }));
  }
}

PeopleSearchAdd.propTypes = {
  autocompleteLoading: PropTypes.bool,
  autocompleteOnChange: PropTypes.func.isRequired,
  autocompleteOnLeaving: PropTypes.func.isRequired,
  autocompleteResults: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  identities: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
  onPersonCheck: PropTypes.func.isRequired,
  onPersonSelect: PropTypes.func.isRequired,
  people: PropTypes.array.isRequired,
  selectedIdentity: PropTypes.number,
  selectedIndex: PropTypes.object.isRequired,
  setIdentity: PropTypes.func.isRequired,
};

export default injectIntl(PeopleSearchAdd);
