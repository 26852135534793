import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import { getDynamicFields } from 'web/dynamicFields/actionCreators/dynamicFieldsActionCreators';
import { getTemplateDetails } from './editTemplateTemplateActionCreators';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { navigateToTemplateCategory } from 'web/services/routerService';
import { templatesCategoryMap } from 'web/templates/helpers/templatesHelper';

export const open = (template = {}, tab, isEditing = false) => (dispatch) => {
  dispatch(getTemplateDetails(template.id, isEditing));
  dispatch(getDynamicFields()); // only goes to server once so safe to call
  dispatch({
    type: EditTemplateActionTypes.open,
    tab,
    template,
  });
};

export const close = (categoryId) => (dispatch, getState) => {
  if (categoryId && typeof categoryId === 'number') {
    navigateToTemplateCategory(categoryId);
  } else if (categoryId) {
    const catToNavTo = templatesCategoryMap(
      getState().templatesSelectedTemplateCategoryId
    );
    navigateToTemplateCategory(catToNavTo);
  }

  dispatch({
    type: EditTemplateActionTypes.close,
  });
  dispatch(closePopup());
};
