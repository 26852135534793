import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import {
  isIntegrationConnected,
  getAccount,
  getAccountInstanceType,
  getIntegration,
} from 'web/salesforce/selectors/salesforceSelectors';
import ConnectionsCustomizations from '../components/connectionsCustomizations';
import {
  getSalesforceIntegration,
  openConnectConfirmationPopup,
  openDisconnectConfirmationPopup,
} from 'web/salesforce/actionCreators/salesforceActionCreators';
import {
  getFetching,
  getLoading,
} from '../selectors/connectionsCustomizationsSelectors';
import { getStatusConditions } from 'web/modals/salesforceCustomization/selectors/salesforceCustomizationSelectors';
import SalesforcePopupAlert from 'web/settings/accountSettings/salesforce/components/salesforcePopupAlert/salesforcePopupAlert';
import { isOwaPluginMode } from 'web/services/windowService';
import { getImageUrl } from 'web/libs/constants';
import './connectionsCustomizationsContainer.scss';
import { getIntegrationsHealthStatuses } from 'web/settings/myProfile/components/yourIntegrations/selectors/yourIntegrationsSelectors';
import isEmpty from 'lodash/isEmpty';

const ConnectionsCustomizationsContainer = (props) => (
  <Fragment>
    <ConnectionsCustomizations {...props} />
    {isOwaPluginMode() && (
      <Fragment>
        <SalesforcePopupAlert />
        <div
          className="salesforce-connect-owa-plugin-background"
          style={{
            backgroundImage: `url(${getImageUrl(
              'unauthorized-page-background',
              'png'
            )})`,
          }}
        />
      </Fragment>
    )}
  </Fragment>
);

ConnectionsCustomizationsContainer.propTypes = {
  intl: intlShape.isRequired,
};

function mapStateToProps(state) {
  return {
    account: getAccount(state),
    broken: getIntegrationsHealthStatuses(state)
      .isSalesforceBrokenForCurrentAccount,
    connected: isIntegrationConnected(state),
    defaultSelectedType: getAccountInstanceType(state),
    fetching: getFetching(state),
    loading: getLoading(state),
    seInstalled: getStatusConditions(state).isCustomizationInstalled,
    setup:
      getIntegrationsHealthStatuses(state).isSalesforceSetUpForCurrentAccount &&
      !isEmpty(getIntegration(state)),
  };
}

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        getSalesforceIntegration,
        openConnectConfirmationPopup,
        openDisconnectConfirmationPopup,
      },
      dispatch
    ),
  },
});

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ConnectionsCustomizationsContainer);
