import { createSelector } from 'reselect';
import {
  PersonDetailsCards,
  PersonDetailsTasksTableId,
} from '../libs/personDetailsConstants';
import {
  parseEmails,
  parseEventsFromEmails,
} from 'web/emails/helpers/emailsParsers';
import { parseNotes } from 'web/notes/helpers/notesParsers';
import { parseMarketoEventsFlat } from 'web/liveFeed/engage/helpers/engageParsers';
import {
  parseAddresses,
  parseCalls,
  parseCampaign,
  parseCampaigns,
  parseCustomFields,
  parseGroupsFromGroupMembers,
  parseTasksUncompletedCount,
  parseTasksFromAllReminders,
  parseComplianceInfo,
} from 'web/person/helpers/personDetailsParsers';
import { getExportData } from '../helpers/personDetailsExportHelper';
import {
  groupsCardHasChanges,
  hasChanges,
} from '../helpers/personDetailsHasChangesHelper';
import orderBy from 'lodash/orderBy';
import { BlockTypeItems } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';

const getPersonDetailsPerson = (state) => state.personDetailsPerson;
const getPersonDetailsPersonAddresses = (state) =>
  state.personDetailsPerson.addresses;
const getPersonDetailsPersonCustomFields = (state) =>
  state.personDetailsPerson.custom_fields;
const getPersonDetailsPersonGroupMembers = (state) =>
  state.personDetailsPerson.group_members;
const getPersonDetailsPersonAllReminders = (state) =>
  state.personDetailsPerson.all_reminders;
const getPersonDetailsPersonCalls = (state) => state.personDetailsPerson.calls;
const getPersonDetailsPersonNotes = (state) => state.personDetailsPerson.notes;
const getPersonDetailsCanBeAddedToCampaigns = (state) =>
  state.personDetailsPerson.can_be_added_to_campaigns;
const getPersonDetailsPersonActiveCampaign = (state) =>
  state.personDetailsPerson.workflow_details;
const getPersonDetailsPersonActiveCampaigns = (state) =>
  state.personDetailsPerson.workflow_details;
const getPersonDetailsPersonCampaigns = (state) =>
  state.personDetailsPerson.inactive_workflow_instances;
const getPersonDetailsCompliance = (state) =>
  state.personDetailsPerson.complianceDetails;
const getOriginalEditState = (state) => state.personDetailsOriginalEditState;
const getInfoEditState = (state) => state.personDetailsInfoEditState;
const getGroupsEditState = (state) => state.personDetailsGroupsEditState;
const getCustomFieldsEditState = (state) =>
  state.personDetailsCustomFieldsEditState;
const getTasksStatus = (state) => state.tablesTasksStatusSmartFilterValue;
const getGroupsState = (state) => state.groups;
const getUser = (state) => state.user;
const getSubscriptionUsers = (state) => state.userSubscriptionUsers;
const getPersonMarketoEventsState = (state) =>
  state.personDetailsMarketoEventsState;
const getPersonEmailsState = (state) => state.personDetailsEmailsState;
const getUnsubscribeHistory = (state) => state.personDetailsUnsubscribeHistory;
const getComplianceEditState = (state) =>
  state.personDetailsComplianceEditState;
const getPersonDetailsTabLoading = (state) => state.personDetailsTabLoading;
const getPersonDetailsAccountInfoLoading = (state) =>
  state.personDetailsAccountInfoLoading;

/* parseAddresses returns addressesSorted and social objects */
const getParsedAddresses = createSelector(
  [getPersonDetailsPersonAddresses],
  (addresses) => parseAddresses(addresses)
);

const getGroups = createSelector(
  [getPersonDetailsPersonGroupMembers, getPersonDetailsPersonAddresses],
  (groupMembers, addresses) => ({
    ...parseGroupsFromGroupMembers(groupMembers, addresses),
  })
);

const getCustomFields = createSelector(
  [getPersonDetailsPersonCustomFields],
  (customFields) => parseCustomFields(customFields)
);

const getActiveCampaign = createSelector(
  [getPersonDetailsPersonActiveCampaign, getUser, getSubscriptionUsers],
  (campaign, user, subscriptionUsers) =>
    campaign && parseCampaign(campaign, user, subscriptionUsers)
);

const getActiveCampaigns = createSelector(
  [getPersonDetailsPersonActiveCampaigns, getUser, getSubscriptionUsers],
  (campaigns, user, subscriptionUsers) =>
    parseCampaigns(campaigns, user, subscriptionUsers)
);

const canBeAddedToCampaigns = createSelector(
  [getPersonDetailsCanBeAddedToCampaigns],
  (canBeAddedToCampaigns) => canBeAddedToCampaigns
);

const getCampaigns = createSelector(
  [getPersonDetailsPersonCampaigns, getUser, getSubscriptionUsers],
  (campaigns, user, subscriptionUsers) =>
    parseCampaigns(campaigns, user, subscriptionUsers)
);

const getCalls = createSelector(
  [getPersonDetailsPersonCalls, getSubscriptionUsers],
  (emails, subscriptionUsers) => parseCalls(emails, subscriptionUsers)
);

const getNotes = createSelector(
  [getPersonDetailsPersonNotes, getSubscriptionUsers],
  (notes, subscriptionUsers) => parseNotes(notes, subscriptionUsers)
);

const getTasks = createSelector(
  [getPersonDetailsPersonAllReminders, getTasksStatus],
  (allReminders, status) =>
    parseTasksFromAllReminders(
      allReminders,
      status.get(PersonDetailsTasksTableId)
    )
);

const getComplianceDetails = createSelector(
  [getPersonDetailsCompliance],
  (compliance) => parseComplianceInfo(compliance) || {}
);

export const getPerson = createSelector(
  [
    canBeAddedToCampaigns,
    getPersonDetailsPerson,
    getParsedAddresses,
    getGroups,
    getTasks,
    getCustomFields,
    getCampaigns,
    getCalls,
    getNotes,
    getActiveCampaign,
    getActiveCampaigns,
    getComplianceDetails,
  ],
  (
    canBeAddedToCampaigns,
    person,
    parsedAddresses,
    groups,
    tasks,
    customFields,
    campaigns,
    callsParsed,
    notesParsed,
    activeCampaign,
    activeCampaigns,
    complianceDetails
  ) => ({
    ...person,
    ...parsedAddresses,
    ...groups,
    tasks,
    customFields,
    campaigns,
    callsParsed,
    notesParsed,
    canBeAddedToCampaigns,
    activeCampaign,
    activeCampaigns,
    complianceDetails,
  })
);

export const getInfoCardHasChanges = createSelector(
  [getOriginalEditState, getInfoEditState],
  (originalEditStates, current) =>
    hasChanges(originalEditStates[PersonDetailsCards.info], current)
);

export const getGroupsCardHasChanges = createSelector(
  [getOriginalEditState, getGroupsEditState],
  (originalEditStates, current) =>
    groupsCardHasChanges(originalEditStates[PersonDetailsCards.groups], current)
);

export const getComplianceCardHasChanges = createSelector(
  [getOriginalEditState, getComplianceEditState],
  (originalEditStates, current) =>
    hasChanges(originalEditStates[PersonDetailsCards.compliance], current)
);

export const getCustomFieldsCardHasChanges = createSelector(
  [getOriginalEditState, getCustomFieldsEditState],
  (originalEditStates, current) =>
    hasChanges(originalEditStates[PersonDetailsCards.customFields], current)
);

export const getGroupsAddable = createSelector(
  [getGroupsState, getGroupsEditState],
  (groups, editState) => {
    if (!editState.selectedEmailValue) {
      return groups.map(({ id, name }) => ({ label: name, value: id }));
    }

    let editingGroupsMap = [];
    if (editState.selectedEmailValue) {
      editingGroupsMap = editState.groupsByAddressId[
        editState.selectedEmailValue
      ].reduce(
        (accumulator, group) => ({
          ...accumulator,
          [group.value]: true,
        }),
        {}
      );
    }

    return groups.reduce((accumulator, { id, name }) => {
      if (!editingGroupsMap[id]) {
        accumulator.push({ label: name, value: id });
      }
      return accumulator;
    }, []);
  }
);

export const getTasksUncompletedCount = createSelector(
  [getPersonDetailsPersonAllReminders],
  (allReminders) => parseTasksUncompletedCount(allReminders)
);

export const getCallRecordingEnabled = createSelector(
  [getUser],
  (user) =>
    (user.subscription && user.subscription.call_recording_enabled) || false
);

export const getEmailsState = createSelector(
  [getPersonEmailsState],
  ({ emails, ...other }) => ({
    ...other,
    emails: parseEmails(emails),
  })
);

export const getToutEventsState = createSelector(
  [getPersonEmailsState],
  ({ emails, ...other }) => ({
    ...other,
    events: parseEventsFromEmails(emails),
  })
);

export const sortUnsubscribeHistoryByDate = createSelector(
  [getUnsubscribeHistory],
  (items = []) => orderBy(items, 'created_at', 'desc')
);

export const getMarketoEventsState = createSelector(
  [getPersonMarketoEventsState],
  ({ events, ...other }) => ({
    ...other,
    events: parseMarketoEventsFlat(events),
  })
);

export const getUnsubscribedFrom = createSelector(
  [getPersonDetailsPerson],
  ({ unsubscribes = [] }) => {
    const blockTypeItem =
      unsubscribes[0] &&
      BlockTypeItems.find((item) => item.value === unsubscribes[0].block_type);

    return blockTypeItem ? blockTypeItem.label : '';
  }
);

export const getExportPerson = createSelector([getPerson], (person) =>
  getExportData(person)
);

export const getPersonDetailsLoading = createSelector(
  [getPersonDetailsTabLoading, getPersonDetailsAccountInfoLoading],
  (tabLoading, accountInfoLoading) => tabLoading || accountInfoLoading
);
