import {
  deleteFiles,
  getBoxContent as getBoxContentService,
  uploadFile,
} from 'web/content/services/contentService';
import { ProgressStatus } from 'libs/constantsShared';
import ComposeWindowActionTypes from '../libs/composeWindowActionTypes';
import {
  BulkEmails,
  COMPOSE_EDITOR_ID,
} from 'web/composeWindow/libs/composeWindowConstants';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { bulkUpdateDrafts } from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import { handleBoxContent } from 'web/composeWindow/helpers/composeWindowHelpers';
import { attachFiles } from 'web/emailComposeBody/actionCreators/fileActionCreators';
import ContentActionTypes from 'web/content/libs/contentActionTypes';

const uploadProgress = (dispatch, name, reference) => (event) => {
  let progress;
  let status;

  if (event.lengthComputable) {
    progress = Math.round((event.loaded * 100) / event.total);
    status = ProgressStatus.uploading;
  } else {
    progress = null;
    status = ProgressStatus.issue;
  }
  dispatch({
    type: ComposeWindowActionTypes.initAttachmentUploadUpdating,
    payload: {
      name,
      progress,
      reference,
      status,
    },
  });
};

export const uploadFiles = (files, tableRef) => (dispatch) => {
  let onCheckClickRow = null;

  if (tableRef.current.getWrappedInstance().actionCreators != null) {
    onCheckClickRow = tableRef.current.getWrappedInstance().actionCreators.onCheckClickRow;
  } else {
    onCheckClickRow = tableRef.current.getWrappedInstance().child.actionCreators.onCheckClickRow;
  }

  for (const file of files) {
    const reference = `${file.name}-${Date.now()}`;
    dispatch({
      type: ComposeWindowActionTypes.initAttachmentUploadRequest,
      payload: {
        progress: 0,
        status: ProgressStatus.uploading,
        name: file.name,
        id: null,
        reference,
      },
    });

    uploadFile(file, uploadProgress(dispatch, file.name, reference)).then(
      (data) => {
        dispatch({
          type: ComposeWindowActionTypes.initAttachmentUploadSuccess,
          payload: {
            progress: 100,
            status: ProgressStatus.finished,
            reference,
            ...data,
          },
        });
        onCheckClickRow(true, data);
      }
    );
  }
};

function imageInsertUploadProgress(dispatch, name, reference) {
  return (e) => {
    let progress;
    let status;

    if (e.lengthComputable) {
      progress = Math.round((e.loaded * 100) / e.total);
      status = ProgressStatus.uploading;
    } else {
      progress = null;
      status = ProgressStatus.issue;
    }
    dispatch({
      type: ComposeWindowActionTypes.initImageUploadUpdating,
      payload: {
        name,
        progress,
        reference,
        status,
      },
    });
  };
}

export const imageInsertUploadFiles = (files) => (dispatch) => {
  for (const file of files) {
    const reference = `${file.name}-${Date.now()}-editor-image`;
    dispatch({
      type: ComposeWindowActionTypes.initImageUploadRequest,
      payload: {
        progress: 0,
        status: ProgressStatus.uploading,
        name: file.name,
        reference,
      },
    });

    uploadFile(
      file,
      imageInsertUploadProgress(dispatch, file.name, reference)
    ).then((data) => {
      dispatch({
        type: ComposeWindowActionTypes.initImageUploadSuccess,
        payload: {
          progress: 100,
          status: ProgressStatus.finished,
          reference,
          ...data,
        },
      });
    });
  }
};

export const closeImageInsertModal = () => (dispatch) => {
  dispatch({ type: ComposeWindowActionTypes.resetEmailEditorImages });
  dispatch(closePopup());
};

export const closeFileAttachmentModal = () => (dispatch) => {
  dispatch({ type: ComposeWindowActionTypes.resetSelectedFiles });
  dispatch(closePopup());
};

export const addAttachmentToEmail = (selectedItems = [], editorId) => (
  dispatch
) => {
  if (editorId === COMPOSE_EDITOR_ID) {
    dispatch({
      type: ComposeWindowActionTypes.addAttachmentToEmail,
      payload: selectedItems,
    });
    const attachmentIds = selectedItems.map((item) => item.id);
    dispatch(bulkUpdateDrafts(BulkEmails.attachmentAdd, attachmentIds));
  } else {
    dispatch(attachFiles(selectedItems, editorId));
  }
};

export const grabSelectedIds = (ids) => ({
  type: ComposeWindowActionTypes.grabSelectedIds,
  payload: ids,
});

export const toggleTrackedContent = (payload) => ({
  type: ComposeWindowActionTypes.toggleTrackedContent,
  payload,
});

export const removeAttachment = (id) => (dispatch) => {
  dispatch({ type: ComposeWindowActionTypes.removeAttachment, payload: id });
  dispatch(bulkUpdateDrafts(BulkEmails.attachmentRemove, id));
};

export const bulkDeleteFiles = (ids, clearDeletedTableRows) => (dispatch) => {
  const integerIds = ids.map(Number);

  // optimistically delete
  dispatch({ type: ContentActionTypes.delete, ids: integerIds });
  dispatch({ type: ComposeWindowActionTypes.resetSelectedFiles });
  deleteFiles(ids);
  clearDeletedTableRows();
};

export const clearEmailAttachments = () => ({
  type: ComposeWindowActionTypes.clearComposeAttachments,
});

export const setFileAttachmentsLoading = (loading) => ({
  type: ComposeWindowActionTypes.setFileAttachmentsLoading,
  loading,
});

export const setBoxContent = (boxContentFiles) => ({
  type: ComposeWindowActionTypes.setBoxContentFiles,
  boxContentFiles,
});

export const getBoxContent = (folderId) => async (dispatch) => {
  try {
    dispatch(setFileAttachmentsLoading(true));
    const query = folderId ? `?folder_id=${folderId}` : '';
    const boxContent = await getBoxContentService(query);
    const boxContentFiles = handleBoxContent(boxContent);
    dispatch(setBoxContent(boxContentFiles));
  } catch {
  } finally {
    dispatch(setFileAttachmentsLoading(false));
  }
};

export const cleanupBoxContent = () => (dispatch) => {
  dispatch(setBoxContent([]));
  dispatch(toggleTrackedContent(false));
};
