import ReleaseNotesActionTypes from '../libs/releaseNotesActionTypes';
import {
  getReleaseNotes as getReleaseNotesCall,
  setReleaseNotesRead,
} from '../services/releaseNotesService';
import { parseUrls } from 'web/releaseNotes/helpers/parsers';

export const expandAnchorBox = () => ({
  type: ReleaseNotesActionTypes.setOpen,
  open: true,
});

export const contractAnchorBox = () => ({
  type: ReleaseNotesActionTypes.setOpen,
  open: false,
});

export const showAnchorBox = () => ({
  type: ReleaseNotesActionTypes.setVisible,
  visible: true,
});

export const hideAnchorBox = () => (dispatch, getState) => {
  const { id } = getState().user;
  setReleaseNotesRead(id);
  dispatch({
    type: ReleaseNotesActionTypes.setVisible,
    visible: false,
  });
};

export const getReleaseNotes = () => (dispatch, getState) => {
  getReleaseNotesCall().then((releaseNotes) => {
    dispatch({
      type: ReleaseNotesActionTypes.setNotes,
      releaseNotes: parseUrls(releaseNotes),
    });
  });
};
