import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';
import { updateUnsubscribeSettings } from 'web/settings/unsubscribes/actionCreators/settingsUnsubscribesActionCreators';
import { saveAdminUnsubscribes } from '../actionCreators/adminUnsubscribesIntegrationsActionCreators';
import { SyncToSalesforceItems } from '../libs/adminUnsubscribesConstants';
import SettingsUnsubscribesConstants from 'web/settings/unsubscribes/libs/settingsUnsubscribesConstants';
import { track } from 'web/services/mixpanelService';
import { getSyncTrackPropertyType } from '../helpers/adminUnsubscribeHelpers';
import {
  UnsubscribeEvents,
  UnsubscribeProperties,
} from 'web/libs/mixpanelEvents';
import UnsubscribesSyncToSalesforce from '../components/unsubscribesSyncToSalesforce/unsubscribesSyncToSalesforce';

class UnsubscribesSyncToSalesforceContainer extends Component {
  render() {
    const { selected } = this.props;

    return (
      <UnsubscribesSyncToSalesforce
        items={SyncToSalesforceItems}
        onChange={this.onChange}
        selected={selected}
      />
    );
  }

  onChange = (value) => {
    const { updateSettings, save } = this.props;

    updateSettings({
      salesforce_sync_field: value,
    });

    save(
      SettingsUnsubscribesConstants.alertIds.saveUnsubscribeIntegrationsSuccess
    );

    track(UnsubscribeEvents.sync, {
      [UnsubscribeProperties.settingsType]: getSyncTrackPropertyType(value),
    });
  };
}

UnsubscribesSyncToSalesforceContainer.propTypes = {
  save: PropsTypes.func.isRequired,
  selected: PropsTypes.string.isRequired,
  updateSettings: PropsTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selected: state.settingsUnsubscribes.salesforce_sync_field,
});

const mapDispatchToProps = {
  save: saveAdminUnsubscribes,
  updateSettings: updateUnsubscribeSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribesSyncToSalesforceContainer);
