import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PopupSize } from 'libs/constantsShared';
import { BackgroundColors } from 'libs/constantsStyles';
import outsideClickHandler from 'components/hocs/outsideClickHandler';
import PopupHeader from '../popupHeader';
import PopupBody from '../popupBody';
import PopupFooter from '../popupFooter';
import LoadingSpinner from 'components/loadingSpinner';
import './popupComponent.scss';

class PopupComponent extends Component {
  render() {
    //eslint-disable-line complexity
    const {
      alert,
      backgroundColor,
      body,
      children,
      className,
      footer,
      full,
      fullPadding,
      getImageUrl,
      header,
      loading,
      overlay,
      size,
      windowMode,
      showHeader,
    } = this.props;

    const noBorderRadius = (windowMode && 'popup-no-border-radius') || '';
    const isFull = full || fullPadding;
    const classes = classNames('popup', noBorderRadius, className, {
      [`background-${backgroundColor}`]: backgroundColor,
      'popup-full': isFull,
      'popup-full-padding': fullPadding,
    });
    const styles = { width: `${size}px` };

    return (
      <div className={classes} style={styles}>
        {loading && <LoadingSpinner imageUrl={getImageUrl} />}
        { showHeader && (
          <div>
            <PopupHeader
              {...this._getHeader()}
              backgroundColor={backgroundColor || header.backgroundColor}
              className={classNames(header.className, noBorderRadius)}
              onClose={
                (!windowMode &&
                  ((overlay && overlay.header && overlay.header.onClose) ||
                    header.onClose)) ||
                undefined
              }
            />
            <div className="divider-box">
              <div className="divider-bar" />
            </div>
          </div>
        )}
        <PopupBody
          {...body}
          alert={alert}
          backgroundColor={backgroundColor || body.backgroundColor}
          className={classNames({
            'popup-no-border-radius': footer.onPrimary || footer.onSecondary,
            'popup-no-header': !showHeader,
          })}
          full={isFull}
          overlay={overlay}
        >
          {body.component || children}
        </PopupBody>
        <PopupFooter
          {...(overlay && overlay.footer) || footer}
          backgroundColor={
            (overlay && overlay.color) ||
            backgroundColor ||
            footer.backgroundColor
          }
          className={classNames(footer.className, noBorderRadius)}
          full={isFull}
        />
      </div>
    );
  }

  _getHeader = () => {
    const { overlay, header } = this.props;
    const overlayHeader = (overlay && overlay.header) || {};
    return {
      ...header,
      ...overlayHeader,
    };
  };
}

PopupComponent.propTypes = {
  alert: PropTypes.object,
  backgroundColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  body: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  exceptionSelectors: PropTypes.array, //eslint-disable-line react/no-unused-prop-types
  footer: PropTypes.object,
  full: PropTypes.bool,
  fullPadding: PropTypes.bool,
  getImageUrl: PropTypes.func,
  header: PropTypes.object,
  loading: PropTypes.bool,
  onOutsideClick: PropTypes.func, //eslint-disable-line react/no-unused-prop-types
  overlay: PropTypes.object,
  size: PropTypes.number,
  windowMode: PropTypes.bool,
  showHeader: PropTypes.bool,
};

PopupComponent.defaultProps = {
  alert: undefined,
  backgroundColor: '',
  body: {},
  children: <span />,
  className: '',
  exceptionSelectors: undefined,
  footer: {},
  full: false,
  fullPadding: false,
  getImageUrl: () => {},
  header: {},
  loading: false,
  onOutsideClick: () => {},
  overlay: undefined,
  size: PopupSize.small,
  windowMode: false,
  showHeader: true,
};

export default outsideClickHandler(PopupComponent);

export let UnwrappedPopupComponent; //eslint-disable-line import/no-mutable-exports
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedPopupComponent = PopupComponent;
}
