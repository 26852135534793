import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToutTable from 'table/modules/toutTable';
import getColumns from './peopleTable.columns';
import TableExternals from 'web/templates/libs/templatesTableExternals';
import { SortingDirection } from 'table/modules/constants';
import { getImageUrl } from 'web/libs/constants';
import { connect } from 'react-redux';
import { isMsiActions } from 'web/user/selectors/userSelectors';
import {
  PEOPLE_TABLE_ID,
  MAX_SELECTED_ITEMS_FOR_EMAIL_BULK_ACTION,
} from 'web/people/libs/peopleConstants';
import { injectIntl, intlShape } from 'react-intl';
import {
  isGeneralPeopleGroup,
  isSelectAllDisabled,
} from 'web/people/helpers/peopleHelpers';
import { HoverTooltipPlace } from 'components/hoverTooltip';
import { isAdmin } from 'web/shared/services/accountService';

class PeopleTable extends Component {
  constructor(props) {
    super(props);

    const {
      actionCreators,
      intl: { formatMessage },
    } = props;
    this.changeSelectedItemsCount = this.changeSelectedItemsCount.bind(this);

    this.actions = [
      {
        disabledTooltipText: formatMessage({
          id: 'common.people.emailSelected.bulkActionDisabledTooltip',
        }),
        label: formatMessage({ id: 'common.people.emailSelected' }),
        onClick: actionCreators.emailSelectedPeople,
        tooltipPlace: HoverTooltipPlace.top,
      },
      {
        label: formatMessage({ id: 'web.people.addSelectedToCampaign' }),
        onClick: actionCreators.openAddGroupToCampaign,
      },
    ];

    this.search = {
      noDropdown: true,
      onChange: actionCreators.peopleSearch,
      onClear: actionCreators.peopleResetSearch,
      placeholderText: formatMessage({
        id: 'web.people.searchPlaceholderText',
      }),
    };

    this.columns = getColumns(
      this.sortColumn,
      this.sortColumnOpposite,
      formatMessage,
      actionCreators,
      {
        account: this.props.account,
      }
    );
  }

  componentWillUnmount() {
    TableExternals.reset();
  }

  render() {
    const {
      actionCreators,
      columnSettings,
      people,
      loading,
      totalCount,
      currentGroupId,
    } = this.props;

    return (
      <ToutTable
        bulkActions={{
          actions: this.actions,
          dropdownActions: this._getDropdownActions(),
          selectAllRequest: this.getSelectAllRequest(),
          totalCount,
        }}
        changeColumnsSettingsHandler={actionCreators.changeColumnsSettings}
        changeSelectedItemsCountHandler={this.changeSelectedItemsCount}
        columnSettings={columnSettings}
        columns={this.columns}
        items={people}
        loading={loading}
        loadingGetUrl={getImageUrl}
        pagination={this.getPagination()}
        row={{ onClick: this.openPersonDetails }}
        search={this.getSearch()}
        selectAllDisabled={isSelectAllDisabled(currentGroupId)}
        setTableExternals={this.setTableExternals}
        tableId={PEOPLE_TABLE_ID}
        columnFilter
        isResize
        reorderColumnsEnable
        selectable
      />
    );
  }

  changeSelectedItemsCount = (selectedCount) => {
    const emailPeopleBulkAction = this.actions[0];
    emailPeopleBulkAction.disabled =
      selectedCount > MAX_SELECTED_ITEMS_FOR_EMAIL_BULK_ACTION;
  };

  sortColumn = (property, index, direction) => {
    this.props.actionCreators.sortPeople(property, direction);
  };

  sortColumnOpposite = (property, index, direction) => {
    const oppositeDirection =
      direction === SortingDirection.ASC
        ? SortingDirection.DESC
        : SortingDirection.ASC;
    this.props.actionCreators.sortPeople(property, oppositeDirection);
  };

  openPersonDetails = (person) => {
    const { actionCreators, currentGroupId } = this.props;
    actionCreators.openGroupPersonDetails(currentGroupId, person.id);
  };

  setTableExternals = (obj) => TableExternals.set(obj);

  getPagination = () => {
    const {
      actionCreators: { paginatePeople, paginatePeoplePerPage },
      page,
      perPage,
      totalCount,
    } = this.props;

    return {
      currentPage: page,
      onClick: paginatePeople,
      perPage,
      perPageClick: paginatePeoplePerPage,
      total: Math.ceil(totalCount / perPage),
    };
  };

  getSelectAllRequest = () => {
    const {
      actionCreators: { getAllPeopleIds },
      selectAllRequestParams,
    } = this.props;

    return {
      requestParams: selectAllRequestParams,
      selectAllHandler: getAllPeopleIds,
    };
  };

  getSearch = () => {
    const { peopleSearchString } = this.props;
    return {
      value: peopleSearchString,
      ...this.search,
    };
  };

  _getDropdownActions = () => {
    const {
      actionCreators: {
        openAddPeopleToGroup,
        openSourcePeoplePopup,
        openAuthorizationPeoplePopup,
        openDeleteFromCampaignPeoplePopup,
        openUnsubscribePeoplePopup,
        openDeletePeoplePopup,
      },
      currentGroupId,
      intl: { formatMessage },
      isMsiActions,
    } = this.props;

    const actions = [
      {
        label: isGeneralPeopleGroup(currentGroupId)
          ? formatMessage({ id: 'web.people.addPeopleToGroup' })
          : formatMessage({ id: 'web.people.addSelectedToAnotherGroup' }),
        onClick: openAddPeopleToGroup,
      },
      {
        label: formatMessage({ id: 'common.source' }),
        onClick: openSourcePeoplePopup,
      },
      {
        label: formatMessage({ id: 'common.authorizationCapitalized' }),
        onClick: openAuthorizationPeoplePopup,
      },
      {
        label: formatMessage({ id: 'conversationCampaign.remove' }),
        onClick: openDeleteFromCampaignPeoplePopup,
      },
      ...this._getBulkRemoveFromGroupAction(),
      {
        label: formatMessage({ id: 'common.unsubscribe' }),
        onClick: openUnsubscribePeoplePopup,
      },
    ];

    if (!isMsiActions) {
      actions.push({
        color: 'red',
        label: formatMessage({ id: 'common.delete' }),
        onClick: openDeletePeoplePopup,
      });
    }

    return actions;
  };

  _getBulkRemoveFromGroupAction = () => {
    const {
      account,
      actionCreators: { openRemoveFromGroupPeoplePopup },
      currentGroupId,
      currentUserGroup,
      intl: { formatMessage },
    } = this.props;
    // group is not general AND user is either admin OR owner of the group
    const isCurrentUserAdminOrGroupCreator =
      isAdmin() ||
      (currentUserGroup && currentUserGroup.user_id === account.id);
    const isBulkRemoveAvailable =
      !isGeneralPeopleGroup(currentGroupId) && isCurrentUserAdminOrGroupCreator;
    if (isBulkRemoveAvailable) {
      return [
        {
          label: formatMessage({ id: 'web.people.removeFromGroup' }),
          onClick: openRemoveFromGroupPeoplePopup,
        },
      ];
    }
    return [];
  };
}

PeopleTable.propTypes = {
  account: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  currentGroupId: PropTypes.string.isRequired,
  currentUserGroup: PropTypes.object,
  intl: intlShape.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  people: PropTypes.array.isRequired,
  peopleSearchString: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  selectAllRequestParams: PropTypes.object,
  totalCount: PropTypes.number.isRequired,
};

PeopleTable.defaultProps = {
  columnSettings: [],
  currentUserGroup: null,
  selectAllRequestParams: undefined,
};

const mapStateToProps = (state) => ({
  isMsiActions: isMsiActions(state),
});

export default connect(mapStateToProps)(injectIntl(PeopleTable));
