import { Regex } from 'web/libs/constants';
import { INVALID_DATE, DEFAULT_SCHEDULE_TIME } from 'libs/constantsShared';
import {
  addTwelveHours,
  removeTwelveHours,
} from 'web/campaigns/campaignsTab/helpers/campaignsHelpers';
import I18N, { moment } from 'languages';

//Convert to 24 hrs format to support localization
export function standardizeTime(time = '') {
  const standard = time.match(Regex.timeStandard);
  const military = time.match(Regex.timeMilitary);

  if (military) {
    return `${military[1]}:${military[2]}`;
  } else if (standard) {
    const meridian = standard[3].length === 1 ? `${standard[3]}m` : standard[3];
    let hours = parseInt(standard[1], 10);
    const minutes = parseInt(standard[2], 10);
    const ampm = meridian.toLowerCase();
    if (ampm === 'pm' && hours < 12) {
      hours = addTwelveHours(hours);
    } else if (ampm === 'am' && hours === 12) {
      hours = removeTwelveHours(hours);
    }
    return `${hours}:${minutes}`;
  } else {
    return time;
  }
}

export function isValidTime(time = '') {
  const today = moment().format(I18N.DateFormats.DEFAULT_DATE);
  const isValidFormat = moment(
    `${today} ${time}`,
    I18N.defaultDateTimeFormats
  ).isValid();
  const isValidTime = moment(
    time,
    I18N.DateFormats.TIME_12AND24_HRS,
    'en'
  ).format(I18N.DateFormats.TIME_AMPM);
  return isValidFormat && isValidTime !== INVALID_DATE;
}

export function getValidTime(time = '') {
  if (time === '' || (time && !isValidTime(time))) {
    return DEFAULT_SCHEDULE_TIME;
  }
  return standardizeTime(time);
}
