import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SalesforceCustomizationUpdate from 'web/modals/salesforceCustomization/components/salesforceCustomizationUpdate';
import SalesforceCustomizationInstall from 'web/modals/salesforceCustomization/components/salesforceCustomizationInstall';
import SalesforceCustomizationUninstall from 'web/modals/salesforceCustomization/components/salesforceCustomizationUninstall';
import SalesforceCustomizationErrors from 'web/modals/salesforceCustomization/components/salesforceCustomizationErrors';
import SalesforceNotConnected from 'web/modals/salesforceCustomization/components/salesforceNotConnected';
import SalesforceCustomizationStatusNeed from 'web/modals/salesforceCustomization/components/SalesforceCustomizationStatusNeed';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';

class SalesforceCustomizationMain extends Component {
  render() {
    const { errors } = this.props;

    return (
      <div className="salesforce-customization-main">
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.salesforceCustomization.connect.title" />
        </h3>
        {this._getMainComponent()}
        <SalesforceCustomizationErrors errors={errors} />
      </div>
    );
  }

  _getMainComponent() {
    const {
      actionCreators,
      hasValidCredentials,
      isCustomizationInstalled,
      isCustomizationPackageInstalled,
      isCustomizationStatusNeed,
      isRunningCustomizationUninstall,
      isSalesforceNotConnected,
      status,
    } = this.props;

    if (!status) {
      return <LoadingSpinner imageUrl={getImageUrl} />;
    } else if (!hasValidCredentials || isSalesforceNotConnected) {
      return <SalesforceNotConnected {...this.props} />;
    } else if (isCustomizationStatusNeed) {
      return (
        <SalesforceCustomizationStatusNeed actionCreators={actionCreators} />
      );
    } else if (isCustomizationPackageInstalled) {
      return <SalesforceCustomizationUpdate {...this.props} />;
    } else if (isCustomizationInstalled || isRunningCustomizationUninstall) {
      return <SalesforceCustomizationUninstall {...this.props} />;
    } else {
      return <SalesforceCustomizationInstall {...this.props} />;
    }
  }
}

SalesforceCustomizationMain.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hasValidCredentials: PropTypes.bool.isRequired,
  isCustomizationInstalled: PropTypes.bool.isRequired,
  isCustomizationPackageInstalled: PropTypes.bool.isRequired,
  isCustomizationStatusNeed: PropTypes.bool.isRequired,
  isRunningCustomizationUninstall: PropTypes.bool.isRequired,
  isRunningCustomizationInstall: PropTypes.bool.isRequired,
  isSalesforceNotConnected: PropTypes.bool.isRequired,
  status: PropTypes.string,
};

SalesforceCustomizationMain.defaultProps = {
  status: null,
};

export default SalesforceCustomizationMain;
