import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dropdownHOC from 'components/dropdown';
import Icon from 'components/buttons/icon';
import classNames from 'classnames';
import './dropdownIcon.scss';

class DropdownIcon extends Component {
  render() {
    const { active, color, className } = this.props;

    return (
      <Icon
        color={color}
        className={classNames(className, { 'dropdown-active': active })}
      />
    );
  }
}

DropdownIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  //eslint-disable-next-line react/no-unused-prop-types
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  color: PropTypes.string,
  childHeight: PropTypes.number.isRequired, //eslint-disable-line react/no-unused-prop-types
};

DropdownIcon.defaultProps = {
  children: null,
  className: '',
  color: '',
};

export default dropdownHOC(DropdownIcon);
