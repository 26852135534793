import {
  AddressTypes,
  PersonDetailsCards,
  PersonDetailsTabs,
} from '../libs/personDetailsConstants';
import {
  PersonDetailsEvents,
  PersonDetailsProperties,
  PersonDetailsPropertyTypes,
} from 'web/libs/mixpanelEvents';
import { track } from 'web/services/mixpanelService';

export const getTabSource = (tab) => {
  switch (tab) {
    case PersonDetailsTabs.about:
      return PersonDetailsPropertyTypes.about;
    case PersonDetailsTabs.engagement:
      return PersonDetailsPropertyTypes.engagement;
    case PersonDetailsTabs.history:
      return PersonDetailsPropertyTypes.history;
    case PersonDetailsTabs.tasks:
      return PersonDetailsPropertyTypes.tasks;
    case PersonDetailsTabs.linkedinSalesNavigotor:
      return PersonDetailsPropertyTypes.lsn;
    default:
      return '';
  }
};

export const onInfoEditAddressTypeChange = (type) => {
  let addressType;
  switch (type) {
    case AddressTypes.phone:
      addressType = PersonDetailsPropertyTypes.phone;
      break;
    case AddressTypes.website:
      addressType = PersonDetailsPropertyTypes.website;
      break;
    case AddressTypes.other:
      addressType = PersonDetailsPropertyTypes.other;
      break;
    case AddressTypes.email:
    default:
      addressType = PersonDetailsPropertyTypes.email;
      break;
  }

  track(PersonDetailsEvents.contact, {
    [PersonDetailsProperties.actionType]:
      PersonDetailsPropertyTypes.addressTypeChanged,
    [PersonDetailsProperties.addressType]: addressType,
  });
};

const getEvent = (cardId) => {
  switch (cardId) {
    case PersonDetailsCards.calls:
      return PersonDetailsEvents.salesCalls;
    case PersonDetailsCards.emails:
      return PersonDetailsEvents.salesEmails;
    case PersonDetailsCards.campaigns:
      return PersonDetailsEvents.salesCampaigns;
    case PersonDetailsCards.toutEvents:
      return PersonDetailsEvents.salesEngagement;
    case PersonDetailsCards.marketoEvents:
      return PersonDetailsEvents.marketoEngagement;
    default:
      return null;
  }
};

export const openItemClick = (cardId) => {
  const event = getEvent(cardId);

  if (event) {
    track(event, {
      [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.clicked,
    });
  }
};

export const viewMoreOnClick = (cardId, isMore = false) => {
  const event = getEvent(cardId);

  if (event) {
    const action = isMore
      ? PersonDetailsPropertyTypes.showMore
      : PersonDetailsPropertyTypes.showLess;
    track(event, { [PersonDetailsProperties.actionType]: action });
  }
};
