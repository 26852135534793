import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { isDirty } from 'redux-form';
import {
  getEditIdentitySelector,
  isSignatureFormDirty,
} from '../selectors/editIdentitySelectors';
import EditIdentityAboutView from '../components/editIdentityAboutView';
import {
  addIdentity,
  updateIdentity,
  openHasChangesPopup,
  setEditIdentityInfoInstance,
  setEditIdentitySignatureInstance,
  setEditIdentityDeliveryChannelInstance,
  setIdentityCardEditMode,
  setSignatureCardEditMode,
  setDeliveryChannelCardEditMode,
  setDisplayIdentityCardEditMode,
  clearAlert,
  showAlert,
  clearWarning,
  showWarning,
  clearSuccessBar,
} from '../actionCreators/editIdentityActionCreators';
import { navigateToSmtpServer } from 'web/settings/identitiesTable/actionCreators/identitiesTableActionCreators';
import { getDynamicFields } from 'web/dynamicFields/actionCreators/dynamicFieldsActionCreators';
import {
  updateEditorBody,
  removeEditorInstance,
  updateEditorBodies,
  storeEditorInstance,
} from 'web/emailComposeBody/actionCreators/emailComposeActionCreators';
import {
  IDENTITY_FORM_ID,
  DELIVERY_CHANNEL_FORM_ID,
  EditIdentityPopupIds,
} from '../libs/editIdentitySlideOutConstants';
import {
  shouldHideDeliveryChannels as shouldHideDeliveryChannelsSelector,
  composeDeliveryChannelsWithDefaultOne as composeDeliveryChannelsWithDefaultOneSelector,
  findDeliveryChannelByTeamAndProviderSmtp,
} from 'web/deliveryChannels/selectors/deliveryChannelsSelectors';

class EditIdentityAboutViewContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    this.actionCreators = {
      ...bindActionCreators(
        {
          addIdentity,
          clearAlert,
          clearSuccessBar,
          clearWarning,
          getDynamicFields,
          openHasChangesPopup,
          removeEditorInstance,
          setDeliveryChannelCardEditMode,
          setDisplayIdentityCardEditMode,
          setEditIdentityDeliveryChannelInstance,
          setEditIdentityInfoInstance,
          setEditIdentitySignatureInstance,
          setIdentityCardEditMode,
          setSignatureCardEditMode,
          showAlert,
          showWarning,
          storeEditorInstance,
          updateEditorBodies,
          updateEditorBody,
          updateIdentity,
        },
        dispatch
      ),
      navigateToSmtpServer,
    };

    dispatch(getDynamicFields());
  }

  componentWillMount() {
    const { identity } = this.props;

    if (identity) {
      this.actionCreators.setEditIdentityInfoInstance(identity);
      this.actionCreators.setEditIdentitySignatureInstance(identity.signature);
      this.actionCreators.setEditIdentityDeliveryChannelInstance(identity);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.identity !== this.props.identity) {
      if (!nextProps.identityCardEditMode) {
        this.actionCreators.setEditIdentityInfoInstance(nextProps.identity);

        if (nextProps.displayIdentityCardInEditMode) {
          this.actionCreators.setIdentityCardEditMode(
            nextProps.displayIdentityCardInEditMode
          );
          this.actionCreators.setDisplayIdentityCardEditMode();
        }
      }
      if (!nextProps.signatureCardEditMode) {
        this.actionCreators.setEditIdentitySignatureInstance(
          nextProps.identity.signature
        );
      }
      if (!nextProps.deliveryChannelCardEditMode) {
        this.actionCreators.setEditIdentityDeliveryChannelInstance(
          nextProps.identity
        );
      }
    }
  }

  render() {
    return (
      <EditIdentityAboutView
        {...this.props}
        actionCreators={this.actionCreators}
      />
    );
  }
}

EditIdentityAboutViewContainer.propTypes = {
  canChangeDefaultIdentityDeliveryChannel: PropTypes.bool.isRequired,
  composedDeliveryChannels: PropTypes.array.isRequired,
  deliveryChannelCardEditMode: PropTypes.bool.isRequired,
  deliveryChannels: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  displayIdentityCardInEditMode: PropTypes.bool.isRequired,
  editIdentityDeliveryChannelInstance: PropTypes.object,
  editIdentityInfoInstance: PropTypes.object,
  editIdentitySignatureInstance: PropTypes.string,
  emailComposeEditors: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessageKey: PropTypes.string,
  identity: PropTypes.object,
  identityCardEditMode: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  isCreateNewIdentityMode: PropTypes.bool.isRequired,
  isDeliveryChannelFormDirty: PropTypes.bool.isRequired,
  isDiscardChangesPopupOpen: PropTypes.bool.isRequired,
  isIdentityFormDirty: PropTypes.bool.isRequired,
  isSignatureFormDirty: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  shouldHideDeliveryChannels: PropTypes.bool.isRequired,
  signatureCardEditMode: PropTypes.bool.isRequired,
  successBar: PropTypes.bool.isRequired,
  successBarMessageKey: PropTypes.string,
  validation: PropTypes.bool.isRequired,
  validationBarMessageKey: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    canChangeDefaultIdentityDeliveryChannel: !!findDeliveryChannelByTeamAndProviderSmtp(
      state
    ),
    composedDeliveryChannels: composeDeliveryChannelsWithDefaultOneSelector(
      state
    ),
    deliveryChannelCardEditMode: state.deliveryChannelCardEditMode,
    deliveryChannels: state.deliveryChannels,
    displayIdentityCardInEditMode: state.displayIdentityCardInEditMode,
    editIdentityDeliveryChannelInstance:
      state.editIdentityDeliveryChannelInstance,
    editIdentityInfoInstance: state.editIdentityInfoInstance,
    editIdentitySignatureInstance: state.editIdentitySignatureInstance,
    emailComposeEditors: state.emailComposeEditors,
    error: state.editIdentityError,
    errorMessageKey: state.editIdentityErrorMessageKey,
    identity: getEditIdentitySelector(state),
    identityCardEditMode: state.identityCardEditMode,
    isCreateNewIdentityMode: !state.editIdentityId,
    isDeliveryChannelFormDirty: isDirty(DELIVERY_CHANNEL_FORM_ID)(state),
    isDiscardChangesPopupOpen: state.popup === EditIdentityPopupIds.hasChanges,
    isIdentityFormDirty: isDirty(IDENTITY_FORM_ID)(state),
    isSignatureFormDirty: isSignatureFormDirty(state),
    loading:
      state.deliveryChannelsFetching ||
      state.identitiesFetching ||
      state.editIdentityLoading,
    shouldHideDeliveryChannels: shouldHideDeliveryChannelsSelector(state),
    signatureCardEditMode: state.signatureCardEditMode,
    successBar: state.editIdentitySuccessBar,
    successBarMessageKey: state.editIdentitySuccessBarMessageKey,
    validation: state.editIdentityWarning,
    validationBarMessageKey: state.editIdentityValidationBarMessageKey,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(EditIdentityAboutViewContainer);
