import { BackgroundColors } from 'libs/constantsStyles';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { MIN_CREATE_GROUP_NAME_LENGTH } from '../libs/peopleConstants';

export function getAlert(actionCreators, alert, textValues) {
  const defaultAlert = {
    color: BackgroundColors.redDark,
    onClose: actionCreators.clearPopupAlert,
    textId: 'web.campaigns.genericApiError',
  };

  switch (alert) {
    case PeopleAlertIds.createGroupError:
      return {
        ...defaultAlert,
        textId: 'web.people.addPeopleToGroupError',
      };
    case PeopleAlertIds.editGroupError:
      return {
        ...defaultAlert,
        textId: 'web.people.editGroupError',
      };
    case PeopleAlertIds.loadContactsError:
      return {
        ...defaultAlert,
        textId: 'web.people.loadContactsError',
      };
    case PeopleAlertIds.addPeopleToGroupError:
      return {
        ...defaultAlert,
        textId: 'web.people.addPeopleToGroupError',
      };
    case PeopleAlertIds.groupDuplicateNameError:
      return {
        ...defaultAlert,
        textId: 'web.people.groupDuplicateNameError',
      };
    case PeopleAlertIds.groupMinLengthNameError:
      return {
        ...defaultAlert,
        textId: 'web.people.groupMinLengthNameError',
        textValues: {
          min: MIN_CREATE_GROUP_NAME_LENGTH,
        },
      };
    case PeopleAlertIds.deleteGroupError:
      return {
        ...defaultAlert,
        textId: 'web.people.deleteGroupError',
      };
    case PeopleAlertIds.fileUploadError:
      return {
        ...defaultAlert,
        textId: 'web.people.importer.fileUploadError',
      };
    case PeopleAlertIds.fileFormatError:
      return {
        ...defaultAlert,
        textId: 'web.people.importer.fileFormatError',
      };
    case PeopleAlertIds.fileTypeError:
      return {
        ...defaultAlert,
        textId: 'web.people.importer.fileTypeError',
      };
    case PeopleAlertIds.emailFieldMissingError:
      return {
        ...defaultAlert,
        textId: 'web.people.importer.emailFieldMissingError',
      };
    case PeopleAlertIds.shareGroupError:
      return {
        ...defaultAlert,
        textId: 'web.people.shareGroupError',
      };
    case PeopleAlertIds.invalidCustomFieldNameError:
      return {
        ...defaultAlert,
        delay: 4000,
        disableClick: true,
        html: true,
        textId: 'web.person.personDetails.alert.invalidCustomFieldBadName',
        textValues: {
          name: textValues.name,
        },
      };
    case PeopleAlertIds.removePeopleFromGroupError:
      return {
        ...defaultAlert,
        textId: 'web.people.removePeopleFromGroupError',
      };
    case PeopleAlertIds.userInviteNeedMoreSeats:
      return {
        ...defaultAlert,
        textId:
          'web.settings.adminSettings.userManagement.invitees.inviteUsers.fromCsv.title',
        textValues: { seatsCount: textValues.seatsCount },
      };
    case PeopleAlertIds.genericApiError:
      return defaultAlert;
    default:
      return null;
  }
}
