import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Label from 'components/label';
import Text from 'components/inputs/text';
import TextButton from 'components/buttons/text';
import './subjectCCs.scss';

class SubjectCCs extends Component {
  state = {
    showCCs: false,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.subjectPlaceholder = formatMessage({
      id: 'web.emailComposeBody.subjectCCs.subjectPlaceholder',
    });
    this.ccPlaceholder = formatMessage({
      id: 'web.emailComposeBody.subjectCCs.ccPlaceholder',
    });
    this.bccPlaceholder = formatMessage({
      id: 'web.emailComposeBody.subjectCCs.ccPlaceholder',
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    // still necessary?
    if (!this.state.showCCs && (nextProps.cc || nextProps.bcc)) {
      this.showCCs();
    }
  }

  render() {
    const {
      bcc,
      cc,
      disableCCs,
      disabled,
      subject,
      onFocus,
      onBlur,
    } = this.props;

    const { showCCs } = this.state;

    return (
      <div className="subject-ccs">
        <Label
          classChildren="subject-label"
          id="subject-ccs-subject"
          messageKey="common.subject"
          sideLabel
        >
          <Text
            autoComplete="on"
            disabled={disabled}
            id="subject-ccs-subject"
            name="subject-ccs-subject"
            onBlur={onBlur}
            onChange={this.onSubjectChange}
            onFocus={onFocus}
            placeholder={this.subjectPlaceholder}
            type="text"
            value={subject}
            autoFocus
          />
          {!disableCCs &&
            !showCCs && (
              <TextButton
                className="margin-left-half"
                onClick={this.showCCs}
                underline
              >
                <FormattedMessage id="web.emailComposeBody.subjectCCs.showCCs" />
              </TextButton>
            )}
        </Label>
        {showCCs && (
          <Label
            classWrapper="margin-top-half"
            id="subject-ccs-ccs"
            messageKey="common.cc"
            sideLabel
          >
            <Text
              disabled={disabled}
              id="subject-ccs-ccs"
              onChange={this.onCcChange}
              placeholder={this.ccPlaceholder}
              type="text"
              value={cc}
              autoFocus
            />
          </Label>
        )}
        {showCCs && (
          <Label
            classWrapper="margin-top-half"
            id="subject-ccs-bccs"
            messageKey="common.bcc"
            sideLabel
          >
            <Text
              autoFocus={false}
              disabled={disabled}
              id="subject-ccs-bccs"
              onChange={this.onBccChange}
              placeholder={this.bccPlaceholder}
              type="text"
              value={bcc}
            />
          </Label>
        )}
      </div>
    );
  }

  showCCs = () => this.setState(() => ({ showCCs: true }));

  onSubjectChange = (subject) => this.props.updateState({ subject });

  onCcChange = (cc) => this.props.updateState({ cc });

  onBccChange = (bcc) => this.props.updateState({ bcc });
}

SubjectCCs.propTypes = {
  bcc: PropTypes.string,
  cc: PropTypes.string,
  disableCCs: PropTypes.bool,
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  subject: PropTypes.string,
  updateState: PropTypes.func.isRequired,
};

SubjectCCs.defaultTypes = {
  bcc: '',
  cc: '',
  disableCCs: false,
  disabled: false,
  onBlur: () => {},
  onFocus: () => {},
  subject: '',
};

export default injectIntl(SubjectCCs);
