tinymce.addI18n('en-US', {
  Redo: 'Redo',
  Undo: 'Undo',
  Cut: 'Cut',
  Copy: 'Copy',
  Paste: 'Paste',
  'Select all': 'Select all',
  'New document': 'New document',
  Ok: 'Ok',
  Cancel: 'Cancel',
  'Visual aids': 'Visual aids',
  Bold: 'Bold',
  Italic: 'Italic',
  Underline: 'Underline',
  Strikethrough: 'Strikethrough',
  Superscript: 'Superscript',
  Subscript: 'Subscript',
  'Clear formatting': 'Clear formatting',
  'Align left': 'Align left',
  'Align center': 'Align center',
  'Align right': 'Align right',
  Justify: 'Justify',
  'Bullet list': 'Bullet list',
  'Numbered list': 'Numbered list',
  'Decrease indent': 'Decrease indent',
  'Increase indent': 'Increase indent',
  Close: 'Close',
  Formats: 'Formats',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.",
  Headers: 'Headers',
  'Header 1': 'Header 1',
  'Header 2': 'Header 2',
  'Header 3': 'Header 3',
  'Header 4': 'Header 4',
  'Header 5': 'Header 5',
  'Header 6': 'Header 6',
  Headings: 'Headings',
  'Heading 1': 'Heading 1',
  'Heading 2': 'Heading 2',
  'Heading 3': 'Heading 3',
  'Heading 4': 'Heading 4',
  'Heading 5': 'Heading 5',
  'Heading 6': 'Heading 6',
  Preformatted: 'Preformatted',
  Div: 'Div',
  Pre: 'Pre',
  Code: 'Code',
  Paragraph: 'Paragraph',
  Blockquote: 'Blockquote',
  Inline: 'Inline',
  Blocks: 'Blocks',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.',
  Fonts: 'Fonts',
  'Font Sizes': 'Font Sizes',
  Class: 'Class',
  'Browse for an image': 'Browse for an image',
  OR: 'OR',
  'Drop an image here': 'Drop an image here',
  Upload: 'Upload',
  Block: 'Block',
  Align: 'Align',
  Default: 'Default',
  Circle: 'Circle',
  Disc: 'Disc',
  Square: 'Square',
  'Lower Alpha': 'Lower Alpha',
  'Lower Greek': 'Lower Greek',
  'Lower Roman': 'Lower Roman',
  'Upper Alpha': 'Upper Alpha',
  'Upper Roman': 'Upper Roman',
  'Anchor...': 'Anchor...',
  Name: 'Name',
  Id: 'Id',
  'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.',
  'You have unsaved changes are you sure you want to navigate away?':
    'You have unsaved changes are you sure you want to navigate away?',
  'Restore last draft': 'Restaurar o \u00faltimo rascunho',
  'Special character...': 'Special character...',
  'Source code': 'Source code',
  'Insert/Edit code sample': 'Insert/Edit code sample',
  Language: 'Language',
  'Code sample...': 'Code sample...',
  'Color Picker': 'Color Picker',
  R: 'R',
  G: 'G',
  B: 'B',
  'Left to right': 'Left to right',
  'Right to left': 'Right to left',
  'Emoticons...': 'Emoticons...',
  'Metadata and Document Properties': 'Metadata and Document Properties',
  Title: 'Title',
  Keywords: 'Keywords',
  Description: 'Description',
  Robots: 'Robots',
  Author: 'Author',
  Encoding: 'Encoding',
  Fullscreen: 'Fullscreen',
  Action: 'Action',
  Shortcut: 'Shortcut',
  Help: 'Help',
  Address: 'Address',
  'Focus to menubar': 'Focus to menubar',
  'Focus to toolbar': 'Focus to toolbar',
  'Focus to element path': 'Focus to element path',
  'Focus to contextual toolbar': 'Focus to contextual toolbar',
  'Insert link (if link plugin activated)':
    'Insert link (if link plugin activated)',
  'Save (if save plugin activated)': 'Save (if save plugin activated)',
  'Find (if searchreplace plugin activated)':
    'Find (if searchreplace plugin activated)',
  'Plugins installed ({0}):': 'Plugins installed ({0}):',
  'Premium plugins:': 'Premium plugins:',
  'Learn more...': 'Learn more...',
  'You are using {0}': 'You are using {0}',
  Plugins: 'Plugins',
  'Handy Shortcuts': 'Handy Shortcuts',
  'Horizontal line': 'Horizontal line',
  'Insert/edit image': 'Insert/edit image',
  'Image description': 'Image description',
  Source: 'Source',
  Dimensions: 'Dimensions',
  'Constrain proportions': 'Constrain proportions',
  General: 'General',
  Advanced: 'Advanced',
  Style: 'Style',
  'Vertical space': 'Vertical space',
  'Horizontal space': 'Horizontal space',
  Border: 'Border',
  'Insert image': 'Insert image',
  'Image...': 'Image...',
  'Image list': 'Image list',
  'Rotate counterclockwise': 'Rotate counterclockwise',
  'Rotate clockwise': 'Rotate clockwise',
  'Flip vertically': 'Flip vertically',
  'Flip horizontally': 'Flip horizontally',
  'Edit image': 'Edit image',
  'Image options': 'Image options',
  'Zoom in': 'Zoom in',
  'Zoom out': 'Zoom out',
  Crop: 'Crop',
  Resize: 'Resize',
  Orientation: 'Orientation',
  Brightness: 'Brightness',
  Sharpen: 'Sharpen',
  Contrast: 'Contrast',
  'Color levels': 'Color levels',
  Gamma: 'Gamma',
  Invert: 'Invert',
  Apply: 'Apply',
  Back: 'Back',
  'Insert date/time': 'Insert date/time',
  'Date/time': 'Date/time',
  'Insert/Edit Link': 'Insert/Edit Link',
  'Insert/edit link': 'Insert/edit link',
  'Text to display': 'Text to display',
  Url: 'Url',
  'Open link in...': 'Open link in...',
  'Current window': 'Current window',
  None: 'None',
  'New window': 'New window',
  'Remove link': 'Remove link',
  Anchors: 'Anchors',
  'Link...': 'Link...',
  'Paste or type a link': 'Paste or type a link',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?',
  'Link list': 'Link list',
  'Insert video': 'Insert video',
  'Insert/edit video': 'Insert/edit video',
  'Insert/edit media': 'Insert/edit media',
  'Alternative source': 'Alternative source',
  'Alternative source URL': 'Alternative source URL',
  'Media poster (Image URL)': 'Media poster (Image URL)',
  'Paste your embed code below:': 'Paste your embed code below:',
  Embed: 'Embed',
  'Media...': 'Media...',
  'Nonbreaking space': 'Nonbreaking space',
  'Page break': 'Page break',
  'Paste as text': 'Paste as text',
  Preview: 'Preview',
  'Print...': 'Print...',
  Save: 'Save',
  Find: 'Find',
  'Replace with': 'Replace with',
  Replace: 'Replace',
  'Replace all': 'Replace all',
  Previous: 'Previous',
  Next: 'Next',
  'Find and replace...': 'Find and replace...',
  'Could not find the specified string.':
    'Could not find the specified string.',
  'Match case': 'Match case',
  'Find whole words only': 'Find whole words only',
  'Spell check': 'Spell check',
  Ignore: 'Ignore',
  'Ignore all': 'Ignore all',
  Finish: 'Finish',
  'Add to Dictionary': 'Add to Dictionary',
  'Insert table': 'Insert table',
  'Table properties': 'Table properties',
  'Delete table': 'Delete table',
  Cell: 'Cell',
  Row: 'Row',
  Column: 'Column',
  'Cell properties': 'Cell properties',
  'Merge cells': 'Merge cells',
  'Split cell': 'Split cell',
  'Insert row before': 'Insert row before',
  'Insert row after': 'Insert row after',
  'Delete row': 'Delete row',
  'Row properties': 'Row properties',
  'Cut row': 'Cut row',
  'Copy row': 'Copy row',
  'Paste row before': 'Paste row before',
  'Paste row after': 'Paste row after',
  'Insert column before': 'Insert column before',
  'Insert column after': 'Insert column after',
  'Delete column': 'Delete column',
  Cols: 'Cols',
  Rows: 'Rows',
  Width: 'Width',
  Height: 'Height',
  'Cell spacing': 'Cell spacing',
  'Cell padding': 'Cell padding',
  'Show caption': 'Show caption',
  Left: 'Left',
  Center: 'Center',
  Right: 'Right',
  'Cell type': 'Cell type',
  Scope: 'Scope',
  Alignment: 'Alignment',
  'H Align': 'H Align',
  'V Align': 'V Align',
  Top: 'Top',
  Middle: 'Middle',
  Bottom: 'Bottom',
  'Header cell': 'Header cell',
  'Row group': 'Row group',
  'Column group': 'Column group',
  'Row type': 'Row type',
  Header: 'Header',
  Body: 'Body',
  Footer: 'Footer',
  'Border color': 'Border color',
  'Insert template...': 'Insert template...',
  Templates: 'Templates',
  Template: 'Template',
  'Text color': 'Text color',
  'Background color': 'Background color',
  'Custom...': 'Custom...',
  'Custom color': 'Custom color',
  'No color': 'No color',
  'Remove color': 'Remove color',
  'Table of Contents': 'Table of Contents',
  'Show blocks': 'Show blocks',
  'Show invisible characters': 'Show invisible characters',
  'Word count': 'Word count',
  Count: 'Count',
  Document: 'Document',
  Selection: 'Selection',
  Words: 'Words',
  'Words: {0}': 'Words: {0}',
  '{0} words': '{0} words',
  File: 'File',
  Edit: 'Edit',
  Insert: 'Insert',
  View: 'View',
  Format: 'Format',
  Table: 'Table',
  Tools: 'Tools',
  'Powered by {0}': 'Powered by {0}',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help',
  'Image title': 'Image title',
  'Border width': 'Border width',
  'Border style': 'Border style',
  Error: 'Error',
  Warn: 'Warn',
  Valid: 'Valid',
  'To open the popup, press Shift+Enter':
    'To open the popup, press Shift+Enter',
  'Rich Text Area. Press ALT-0 for help.':
    'Rich Text Area. Press ALT-0 for help.',
  'System Font': 'System Font',
  'Failed to upload image: {0}': 'Failed to upload image: {0}',
  'Failed to load plugin: {0} from url {1}':
    'Failed to load plugin: {0} from url {1}',
  'Failed to load plugin url: {0}': 'Failed to load plugin url: {0}',
  'Failed to initialize plugin: {0}': 'Failed to initialize plugin: {0}',
  example: 'example',
  Search: 'Search',
  All: 'All',
  Currency: 'Currency',
  Text: 'Text',
  Quotations: 'Quotations',
  Mathematical: 'Mathematical',
  'Extended Latin': 'Extended Latin',
  Symbols: 'Symbols',
  Arrows: 'Arrows',
  'User Defined': 'User Defined',
  'dollar sign': 'dollar sign',
  'currency sign': 'currency sign',
  'euro-currency sign': 'euro-currency sign',
  'colon sign': 'colon sign',
  'cruzeiro sign': 'cruzeiro sign',
  'french franc sign': 'french franc sign',
  'lira sign': 'lira sign',
  'mill sign': 'mill sign',
  'naira sign': 'naira sign',
  'peseta sign': 'peseta sign',
  'rupee sign': 'rupee sign',
  'won sign': 'won sign',
  'new sheqel sign': 'new sheqel sign',
  'dong sign': 'dong sign',
  'kip sign': 'kip sign',
  'tugrik sign': 'tugrik sign',
  'drachma sign': 'drachma sign',
  'german penny symbol': 'german penny symbol',
  'peso sign': 'peso sign',
  'guarani sign': 'guarani sign',
  'austral sign': 'austral sign',
  'hryvnia sign': 'hryvnia sign',
  'cedi sign': 'cedi sign',
  'livre tournois sign': 'livre tournois sign',
  'spesmilo sign': 'spesmilo sign',
  'tenge sign': 'tenge sign',
  'indian rupee sign': 'indian rupee sign',
  'turkish lira sign': 'turkish lira sign',
  'nordic mark sign': 'nordic mark sign',
  'manat sign': 'manat sign',
  'ruble sign': 'ruble sign',
  'yen character': 'yen character',
  'yuan character': 'yuan character',
  'yuan character, in hong kong and taiwan':
    'yuan character, in hong kong and taiwan',
  'yen/yuan character variant one': 'yen/yuan character variant one',
  'Loading emoticons...': 'Loading emoticons...',
  'Could not load emoticons': 'Could not load emoticons',
  People: 'People',
  'Animals and Nature': 'Animals and Nature',
  'Food and Drink': 'Food and Drink',
  Activity: 'Activity',
  'Travel and Places': 'Travel and Places',
  Objects: 'Objects',
  Flags: 'Flags',
  Characters: 'Characters',
  'Characters (no spaces)': 'Characters (no spaces)',
  '{0} characters': '{0} characters',
  'Error: Form submit field collision.': 'Error: Form submit field collision.',
  'Error: No form element found.': 'Error: No form element found.',
  Update: 'Update',
  'Color swatch': 'Color swatch',
  Turquoise: 'Turquoise',
  Green: 'Green',
  Blue: 'Blue',
  Purple: 'Purple',
  'Navy Blue': 'Navy Blue',
  'Dark Turquoise': 'Dark Turquoise',
  'Dark Green': 'Dark Green',
  'Medium Blue': 'Medium Blue',
  'Medium Purple': 'Medium Purple',
  'Midnight Blue': 'Midnight Blue',
  Yellow: 'Yellow',
  Orange: 'Orange',
  Red: 'Red',
  'Light Gray': 'Light Gray',
  Gray: 'Gray',
  'Dark Yellow': 'Dark Yellow',
  'Dark Orange': 'Dark Orange',
  'Dark Red': 'Dark Red',
  'Medium Gray': 'Medium Gray',
  'Dark Gray': 'Dark Gray',
  'Light Green': 'Light Green',
  'Light Yellow': 'Light Yellow',
  'Light Red': 'Light Red',
  'Light Purple': 'Light Purple',
  'Light Blue': 'Light Blue',
  'Dark Purple': 'Dark Purple',
  'Dark Blue': 'Dark Blue',
  Black: 'Black',
  White: 'White',
  'Switch to or from fullscreen mode': 'Switch to or from fullscreen mode',
  'Open help dialog': 'Open help dialog',
  history: 'history',
  styles: 'styles',
  formatting: 'formatting',
  alignment: 'alignment',
  indentation: 'indentation',
  'permanent pen': 'permanent pen',
  comments: 'comments',
  'Format Painter': 'Format Painter',
  'Insert/edit iframe': 'Insert/edit iframe',
  Capitalization: 'Capitalization',
  lowercase: 'lowercase',
  UPPERCASE: 'UPPERCASE',
  'Title Case': 'Title Case',
  'Permanent Pen Properties': 'Permanent Pen Properties',
  'Permanent pen properties...': 'Permanent pen properties...',
  Font: 'Font',
  Size: 'Size',
  'More...': 'More...',
  'Spellcheck Language': 'Spellcheck Language',
  'Select...': 'Select...',
  Preferences: 'Preferences',
  Yes: 'Yes',
  No: 'No',
  'Keyboard Navigation': 'Keyboard Navigation',
  Version: 'Version',
  Anchor: 'Anchor',
  'Special character': 'Special character',
  'Code sample': 'Code sample',
  Color: 'Color',
  Emoticons: 'Emoticons',
  'Document properties': 'Document properties',
  Image: 'Image',
  'Insert link': 'Insert link',
  Target: 'Target',
  Link: 'Link',
  Poster: 'Poster',
  Media: 'Media',
  Print: 'Print',
  Prev: 'Prev',
  'Find and replace': 'Find and replace',
  'Whole words': 'Whole words',
  Spellcheck: 'Spellcheck',
  Caption: 'Caption',
  'Insert template': 'Insert template',
  'Open link': 'Open link',
  Solid: 'Solid',
  Dotted: 'Dotted',
  Dashed: 'Dashed',
  Double: 'Double',
  Groove: 'Groove',
  Ridge: 'Ridge',
  Inset: 'Inset',
  Outset: 'Outset',
  Hidden: 'Hidden',
});
