import React from 'react';
import PropTypes from 'prop-types';
import AnchorBoxHeaderButtons from './buttons';
import './anchorBoxHeader.scss';

const AnchorBoxHeader = ({ close, contract, expand, leftChildren, open }) => (
  <div className="anchor-box-header">
    {leftChildren}
    <AnchorBoxHeaderButtons
      close={close}
      contract={contract}
      expand={expand}
      open={open}
    />
  </div>
);

AnchorBoxHeader.propTypes = {
  close: PropTypes.func,
  contract: PropTypes.func,
  expand: PropTypes.func,
  leftChildren: PropTypes.node,
  open: PropTypes.bool,
};

AnchorBoxHeader.defaultProps = {
  close: undefined,
  contract: undefined,
  expand: undefined,
  leftChildren: <div />,
  open: false,
};

export default AnchorBoxHeader;
