import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeViewAlert as closeViewAlertAC } from 'web/view/actionCreators/alertActionCreators';
import EmailConnectionAlert from 'web/settings/emailConnection/components/emailConnectionAlert/emailConnectionAlert';

class EmailConnectionAlertContainer extends Component {
  render() {
    return <EmailConnectionAlert {...this.props} />;
  }
}

EmailConnectionAlertContainer.propTypes = {
  alert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.viewAlert,
  emailConnectionProvider: state.emailConnectionProvider,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeViewAlert: closeViewAlertAC,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConnectionAlertContainer);
