import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import './loadingSpinner.scss';

const LoadingSpinner = ({
  backgroundColor,
  children,
  imageUrl,
  size,
  intl: { formatMessage },
}) => {
  const spinnerSize = size;
  const topOffset = spinnerSize * 0.4;
  const centerSize = spinnerSize * 0.6;

  const centerStyle = {
    height: centerSize,
    marginTop: `-${centerSize / 2.0}px`,
    width: centerSize,
  };

  const loaderStyle = {
    height: spinnerSize,
    width: spinnerSize,
  };

  const svgStyle = {
    top: `-${topOffset}px`,
  };

  const loadingSpinnerBackdropClassName = classNames(
    'loading-spinner-backdrop',
    {
      [`background-${backgroundColor}`]: backgroundColor,
    }
  );

  return (
    <div className="loadingSpinner">
      <div className={loadingSpinnerBackdropClassName} />
      <div className="vertical-centering-container">
        <div className="loader" style={loaderStyle}>
          <svg className="circular" style={svgStyle} viewBox="25 25 50 50">
            <circle
              className="path"
              cx="50"
              cy="50"
              fill="none"
              r="20"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </svg>
          {(imageUrl && (
            <img
              alt={formatMessage({ id: 'common.loading' })}
              className="loading-plane"
              src={imageUrl('loading-plane')}
              style={centerStyle}
            />
          )) || <div style={centerStyle} />}
        </div>
        {children}
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  children: PropTypes.node,
  imageUrl: PropTypes.func,
  intl: intlShape.isRequired,
  size: PropTypes.number,
};

LoadingSpinner.defaultProps = {
  backgroundColor: BackgroundColors.white,
  children: undefined,
  imageUrl: undefined, // used as truthy whether to show image or not,
  size: 100,
};

export default injectIntl(LoadingSpinner);
