import {
  getSalesforceIntegration,
  getAccount,
  deleteSalesforceIntegration,
} from 'web/salesforce/actionCreators/salesforceActionCreators';
import {
  shouldInvalidateIntegration,
  shouldInvalidateAccount,
} from 'web/salesforce/selectors/salesforceSelectors';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import ConnectionsCustomizationsActionTypes from '../libs/connectionsCustomizationsActionTypes';
import { isLoadedInIframe } from 'web/libs/commonHelper';
import { Urls } from 'web/libs/routes';
import {
  openNewTab,
  navigateToUrl,
  openPopupWindow,
} from 'web/services/routerService';
import {
  MARKETO_SALES_OUTBOX,
  SalesforceType,
} from 'web/salesforce/libs/salesforceConstants';

export const connectSalesforce = (type, disconnect) => (dispatch) => {
  if (disconnect) {
    dispatch(disconnectSalesforce());
  }

  let isIframe = isLoadedInIframe(MARKETO_SALES_OUTBOX);
  let redirectUrl = Urls.connectAdminToSalesforceNewUI;

  if (window.location.href.includes('#onboarding')) {
    redirectUrl = Urls.connectToSalesforceOnboarding;
    isIframe = false;
  }

  if (type === SalesforceType.salesforce) {
    if (isIframe) {
      dispatch(closePopup());
      openNewTab(redirectUrl, false, true);
    } else {
      navigateToUrl(redirectUrl);
    }
  } else if (type === SalesforceType.salesforceSandbox) {
    if (isIframe) {
      dispatch(closePopup());
      openNewTab(Urls.connectAdminToSalesforceSandboxNewUI, false, true);
    } else {
      navigateToUrl(Urls.connectAdminToSalesforceSandboxNewUI);
    }
  }
};

export const connectSalesforceInPopupWindow = (
  type,
  title,
  width,
  height
) => () => {
  const isOnboardingUrl = window.location.href.includes('#onboarding');
  if (type === SalesforceType.salesforce) {
    const redirectUrl = isOnboardingUrl
      ? Urls.connectToSalesforceOnboarding
      : Urls.connectAdminToSalesforceNewUI;
    openPopupWindow(redirectUrl, title, width, height);
  } else if (type === SalesforceType.salesforceSandbox) {
    const redirectUrl = isOnboardingUrl
      ? Urls.connectAdminToSalesforceOnboarding
      : Urls.connectAdminToSalesforceNewUI;
    openPopupWindow(redirectUrl, title, width, height);
  }
};

export const setDisconnectSalesforceLoading = (loading = true) => ({
  loading,
  type: ConnectionsCustomizationsActionTypes.setDisconnectSalesforceLoading,
});

export const connectionsCustomizationsStartup = () => (dispatch, getState) => {
  if (shouldInvalidateIntegration(getState())) {
    //await
    dispatch(getSalesforceIntegration());
  }
  // if there is a connection?
  if (shouldInvalidateAccount(getState())) {
    //await
    dispatch(getAccount());
  }
};

export const disconnectSalesforce = () => (dispatch) => {
  dispatch(setDisconnectSalesforceLoading());
  dispatch(closePopup());
  dispatch(deleteSalesforceIntegration()).finally(() => {
    dispatch(setDisconnectSalesforceLoading(false));
  });
};
