import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonColors } from 'components/buttons/action/action';
import { ButtonStatus } from 'libs/constantsShared';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import Icon from 'components/buttons/icon';
import outsideClickHandler from 'components/hocs/outsideClickHandler';
import { OPEN_UP } from 'react-dates/constants';
import DateTimeZone from 'components/dates/dateTimeZone';
import { findNextFifteenMinuteIntervalFrom } from 'web/composeWindow/helpers/composeWindowHelpers';
import './schedulerPopUp.scss';

const MINUTES_FORMAT = 'mm';
const HOURS_FORMAT = 'HH';

class SchedulerPopUp extends React.Component {
  constructor(props) {
    super(props);
    const { userTimezone, pitchSendAt } = props;
    const datetime = pitchSendAt
      ? moment.parseZone(pitchSendAt)
      : this.getFirstSelectableDateTime(userTimezone);
    this.state = {
      datetime,
      timezone: userTimezone,
    };
  }

  getUnscheduleButton = () => {
    const { pitchSendAt } = this.props;

    return (
      pitchSendAt && (
        <Button
          className="unschedule-button"
          color={ButtonColors.red}
          onClick={this.handleUnschedule}
        >
          <FormattedMessage id="common.unschedule" />
        </Button>
      )
    );
  };

  handleTimeChange = ({ datetime, timezone }) => {
    this.setState((state) => ({
      datetime,
      timezone: timezone || state.timezone,
    }));
  };

  handleUnschedule = () => {
    const { closePopup, setPitchSendAt } = this.props;
    setPitchSendAt(null);
    closePopup();
  };

  handleSchedule = () => {
    const { setPitchSendAt, closePopup } = this.props;
    const { datetime, timezone } = this.state;
    if (this.isValidScheduledTime(timezone)) {
      setPitchSendAt(datetime.format());
      closePopup();
    }
  };

  getFirstSelectableDateTime = (timezone) => {
    const time = moment.tz(timezone);
    const hours = Number(time.format(HOURS_FORMAT));
    const minutes = Number(time.format(MINUTES_FORMAT));
    const nextInterval = findNextFifteenMinuteIntervalFrom(hours, minutes);
    return time.set(nextInterval);
  };

  isValidScheduledTime = (timezone) => {
    const { datetime } = this.state;
    const now = moment.tz(timezone);
    return datetime > now;
  };

  render() {
    const { timezones, closePopup } = this.props;
    const { timezone, datetime } = this.state;
    const cantScheduleTime = !this.isValidScheduledTime(timezone);
    const saveButtonStatus = cantScheduleTime ? ButtonStatus.disabled : null;

    return (
      <div className="scheduler-popup">
        <div className="scheduler-header">
          <h3>
            <FormattedMessage id="web.composeWindow.schedulerPopUp.header" />
          </h3>
          <Icon className="tout-icon-close" onClick={closePopup} />
        </div>
        <DateTimeZone
          calendarPortal={false}
          classes="scheduler-date-time-selector"
          datePickerOpenDirection={OPEN_UP}
          datetime={datetime}
          isInvalidTime={cantScheduleTime}
          timezone={timezone}
          timezones={timezones}
          update={this.handleTimeChange}
        />
        <div className="scheduler-footer">
          {this.getUnscheduleButton()}
          <Button onClick={this.handleSchedule} status={saveButtonStatus}>
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </div>
    );
  }
}

const outsideClickHandledScheduler = outsideClickHandler(SchedulerPopUp, {
  onOutsideClick: 'closePopup',
});

export default outsideClickHandledScheduler;

SchedulerPopUp.propTypes = {
  closePopup: PropTypes.func.isRequired,
  pitchSendAt: PropTypes.string,
  setPitchSendAt: PropTypes.func.isRequired,
  timezones: PropTypes.array.isRequired,
  userTimezone: PropTypes.string.isRequired,
};

SchedulerPopUp.defaultProps = {
  pitchSendAt: null,
};
