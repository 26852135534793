import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Label from 'components/label';
import Text from 'components/inputs/text';
import Textarea from 'components/inputs/textarea';
import './createNewGroup.scss';

const INPUT_WIDTH = 135;
const RETURN_KEY_CODE = 13;
const TEXTAREA_ROWS = 3;

class CreateNewGroup extends Component {
  render() {
    const { newGroupName, newGroupDescription } = this.props;

    return (
      <div className="create-new-group margin-bottom-full">
        <Label
          classWrapper="margin-top-full"
          id="create-new-group-name-label"
          messageKey="common.name"
          width={INPUT_WIDTH}
          sideLabel
        >
          <Text
            id="create-new-group-name-text"
            onChange={this.onGroupNameChange}
            onKeyDown={this.onKeyDown}
            type="text"
            value={newGroupName}
          />
        </Label>
        <Label
          classWrapper="margin-top-full"
          id="create-new-group-description-label"
          messageKey="common.description"
          width={INPUT_WIDTH}
          sideLabel
        >
          <Textarea
            autoFocus={false}
            id="create-new-group-description-textarea"
            onChange={this.onGroupDescriptionChange}
            rows={TEXTAREA_ROWS}
            value={newGroupDescription}
          />
        </Label>
      </div>
    );
  }

  onGroupNameChange = (value) => {
    this.props.onGroupNameChange(value);
  };

  onGroupDescriptionChange = (value) => {
    this.props.onGroupDescriptionChange(value);
  };

  onKeyDown = (e) => {
    if (e.keyCode === RETURN_KEY_CODE) {
      this.props.onCreate();
    }
  };
}

CreateNewGroup.propTypes = {
  newGroupDescription: PropTypes.string.isRequired,
  newGroupName: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  onGroupDescriptionChange: PropTypes.func.isRequired,
  onGroupNameChange: PropTypes.func.isRequired,
};

export default CreateNewGroup;
