import React from 'react';
import PropTypes from 'prop-types';
import InviteesTable from '../inviteesTable';

const Invitees = (props) => <InviteesTable {...props} />;

Invitees.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  invitations: PropTypes.array.isRequired,
};

export default Invitees;
