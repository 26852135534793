import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Text from 'components/inputs/text';
import Icon from 'components/buttons/icon';
import './customFieldsEditingRow.scss';

class CustomFieldsEditingRow extends Component {
  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.namePlaceholder = formatMessage({
      id: 'web.person.personDetails.customFields.name',
    });
    this.valuePlaceholder = formatMessage({
      id: 'web.person.personDetails.customFields.value',
    });
    this.removeLabel = formatMessage({ id: 'common.remove' });
  }

  render() {
    const { id, name, value } = this.props;
    return (
      <div className="custom-fields-editing-row">
        <Text
          autoFocus={false}
          className="custom-fields-editing-row-input"
          id={`custom-field-editing-name-${id}`}
          onBlur={this.onBlur}
          onChange={this.onNameChange}
          placeholder={this.namePlaceholder}
          type="text"
          value={name}
        />
        <Text
          autoFocus={false}
          className="custom-fields-editing-row-input"
          id={`custom-field-editing-value-${id}`}
          onBlur={this.onBlur}
          onChange={this.onValueChange}
          placeholder={this.valuePlaceholder}
          type="text"
          value={value}
        />
        <Icon
          className="custom-field-editing-row-remove tout-icon-close"
          title={this.removeLabel}
          onClick={this.remove}
        />
      </div>
    );
  }

  onBlur = () => {
    const { name, validateCustomField, value } = this.props;
    validateCustomField(name, value);
  };

  onNameChange = (name) => {
    const {
      addRow,
      index,
      last,
      name: originalName,
      updateEditState,
    } = this.props;
    updateEditState(index, { name });

    if (last && !originalName.length) {
      addRow();
    }
  };

  onValueChange = (value) => {
    const { index, updateEditState } = this.props;

    updateEditState(index, { value });
  };

  remove = () => {
    const { index, last, removeRow, updateEditState } = this.props;

    if (last) {
      updateEditState(index, { name: '', value: '' });
    } else {
      removeRow(index);
    }
  };
}

CustomFieldsEditingRow.propTypes = {
  addRow: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  index: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  last: PropTypes.bool,
  name: PropTypes.string,
  removeRow: PropTypes.func.isRequired,
  updateEditState: PropTypes.func.isRequired,
  validateCustomField: PropTypes.func.isRequired,
  value: PropTypes.string,
};

CustomFieldsEditingRow.defaultProps = {
  last: false,
  name: '',
  value: '',
};

export default injectIntl(CustomFieldsEditingRow);
