import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { TasksTableDatesFilterValues } from 'web/tables/tasks/libs/tablesTasksConstants';
import { CampaignsTasksTableId } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { initPusher } from 'web/tasks/actionCreators/tasksPusherActionCreators';
import { getTasksUncompletedRange } from 'web/tasks/actionCreators/tasksUncompletedActionCreators';
import { setDatesShown } from 'web/tables/tasks/actionCreators/tablesTasksStateActionCreators';
import { getFilterDatesRequired } from 'web/tables/helpers/itemsTableFilters';

export function setLoading(state) {
  return { type: ActionTypes.campaignsTasks.state.loading, data: state };
}

export function getInitTasks() {
  return (dispatch) => {
    const dates = getFilterDatesRequired(
      TasksTableDatesFilterValues.todayOverdue
    );
    dispatch(initPusher());
    dispatch(setDatesShown(CampaignsTasksTableId, dates));
    dispatch(getTasksUncompletedRange(dates));
  };
}
