import {
  UserManagementEvents,
  UserManagementActions,
} from 'web/libs/mixpanelEvents';
import UserManagementActionTypes from '../libs/userManagementActionTypes';
import { UserManagementTabs } from '../libs/userManagementConstants';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { usersStartup } from './usersActionCreators';
import {
  inviteesStartup,
  getMarketoWorkspaces,
} from './inviteesActionCreators';

export const setTab = (tab) => ({
  type: UserManagementActionTypes.setTab,
  tab,
  meta: {
    analytics: {
      event: UserManagementEvents.userManagement,
      properties: {
        'Action Type': UserManagementActions.pageOpen,
        Tab: tab,
      },
    },
  },
});

export const userManagementStartup = ({ tab }) => (dispatch) => {
  const activeTab = Object.values(UserManagementTabs).includes(tab)
    ? tab
    : UserManagementTabs.users;
  dispatch(commonStartup());
  dispatch(setTab(activeTab));
  dispatch(getMarketoWorkspaces());

  if (activeTab === UserManagementTabs.users) {
    dispatch(usersStartup());
  } else if (activeTab === UserManagementTabs.invitees) {
    dispatch(inviteesStartup());
  }
};
