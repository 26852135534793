import TaskActionTypes from 'web/task/libs/taskActionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { moment } from 'languages';
import merge from 'lodash/merge';
import { TaskPriority, TaskType } from 'libs/constantsShared';

export const initEdit = {
  contactEmail: '',
  contactId: 0,
  date: moment().format('L'),
  // done: false.
  id: 0,
  markComplete: false,
  notes: '',
  // pitchId: 0,
  priority: TaskPriority.normal,
  // skipped: false
  // success: false
  title: '',
  type: TaskType.email,
};

export function taskEditState(state = initEdit, action) {
  switch (action.type) {
    case TaskActionTypes.task.state.createEdit:
      return merge({}, initEdit, action.data);
    case TaskActionTypes.task.state.updateEdit:
      return {
        ...state,
        ...action.data,
      };
    case ActionTypes.api.autocomplete.request:
    case TaskActionTypes.task.state.clearContact:
      return {
        ...state,
        contactEmail: initEdit.contactEmail,
        contactId: initEdit.contactId,
      };
    case TaskActionTypes.task.state.destroyEdit:
    case PopupActionTypes.close:
      return initEdit;
    default:
      return state;
  }
}

export function taskOriginal(state = {}, action) {
  switch (action.type) {
    case TaskActionTypes.task.state.createEdit:
      return action.task;
    case TaskActionTypes.task.state.destroyEdit:
    case PopupActionTypes.close:
      return {};
    default:
      return state;
  }
}

export function taskUserAutocomplete(state = '', action) {
  switch (action.type) {
    case TaskActionTypes.userAutocomplete.value:
      return action.value;
    default:
      return state;
  }
}

export function canUserHandleContact(state = true, action) {
  switch (action.type) {
    case TaskActionTypes.task.state.setCanUserHandleContact:
      return action.isShared;
    default:
      return state;
  }
}
