import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import moment from 'moment-timezone';
import { ScheduleLaunchWhen } from 'libs/constantsShared';
import Radio from 'components/buttons/radio';
import DateTimeZone from 'components/dates/dateTimeZone';
import './scheduleLaunch.scss';

class ScheduleLaunch extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
      skipWeekends,
    } = props;

    if (props.defaultDatetime && props.defaultTz && props.timezone) {
      this.textFirstStepId = 'campaigns.scheduleLaunch.firstStep';
      this.textFirstStepValues = this._firstStepValues(
        props.defaultDatetime,
        props.defaultTz,
        props.timezone,
        skipWeekends
      );
    } else {
      this.textFirstStepId = 'campaigns.scheduleLaunch.firstStetpNotScheduled';
      this.textFirstStepValues = {};
    }
    this.textScheduledTime = formatMessage({
      id: 'campaigns.scheduleLaunch.scheduledTime',
    });
    this.textScheduleNow = formatMessage({
      id: 'campaigns.scheduleLaunch.scheduleNow',
    });
    this.textScheduleLaunchTime = formatMessage({
      id: 'campaigns.scheduleLaunch.scheduleLaunchTime',
    });
  }

  render() {
    const {
      calendarPortal,
      datetime,
      timezone,
      timezones,
      updateScheduledLaunch,
      updateWhen,
      when,
    } = this.props;

    return (
      <div className="scheduleLaunch">
        <div className="margin-bottom-full">
          <FormattedHTMLMessage
            id={this.textFirstStepId}
            values={this.textFirstStepValues}
          />
        </div>
        {this.textFirstStepValues.date && (
          <div className="margin-bottom-full">
            <Radio
              checked={when === ScheduleLaunchWhen.scheduled}
              classes="margin-bottom-half"
              label={this.textScheduledTime}
              name="when"
              onChange={updateWhen}
              value={ScheduleLaunchWhen.scheduled}
            />
          </div>
        )}
        <div className="margin-bottom-full">
          <Radio
            checked={when === ScheduleLaunchWhen.now}
            classes="margin-bottom-half"
            label={this.textScheduleNow}
            name="when"
            onChange={updateWhen}
            value={ScheduleLaunchWhen.now}
          />
        </div>
        <Radio
          checked={when === ScheduleLaunchWhen.different}
          label={this.textScheduleLaunchTime}
          name="when"
          onChange={updateWhen}
          value={ScheduleLaunchWhen.different}
        />
        <DateTimeZone
          calendarPortal={calendarPortal}
          classes="date-time-zone"
          datetime={datetime}
          disabled={when !== ScheduleLaunchWhen.different}
          timezone={timezone}
          timezones={timezones}
          update={updateScheduledLaunch}
        />
      </div>
    );
  }

  _firstStepValues(defaultDatetime, defaultTz, timezone, skipWeekends) {
    const now = moment.tz(timezone);
    const step = moment(defaultDatetime);
    const isToday = step >= now;

    if (!isToday) {
      step.add(1, 'd');
    }

    /* if step now falls on the weekend and needs to skip, set to monday */
    const dayOfWeek = step.day();
    if (skipWeekends && dayOfWeek === 0) {
      step.day(1);
    } else if (skipWeekends && dayOfWeek === 6) {
      step.add(1, 'w').day(1);
    }

    return {
      date: step.format('LL'),
      time: `${step.format('LT')} ${defaultTz}`,
    };
  }
}

ScheduleLaunch.propTypes = {
  calendarPortal: PropTypes.bool,
  datetime: PropTypes.object.isRequired,
  defaultDatetime: PropTypes.object,
  defaultTz: PropTypes.string,
  intl: intlShape.isRequired,
  skipWeekends: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  updateScheduledLaunch: PropTypes.func.isRequired,
  updateWhen: PropTypes.func.isRequired,
  when: PropTypes.number.isRequired,
};

export default injectIntl(ScheduleLaunch);
