import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  AlertIds,
  AllowedFileTypes,
} from 'web/modals/imageInsert/libs/imageInsertConstants';
import { ProgressStatus } from 'libs/constantsShared';
import { uploadFile as uploadFileCall } from 'web/modals/imageInsert/services/fileService';
import {
  closePopup,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';

export function insert() {
  return (dispatch, getState) => {
    const { imageInsertEditorName, imageInsertFile } = getState();
    toutBackboneHelper.imageInsert({
      editorName: imageInsertEditorName,
      name: imageInsertFile.name,
      url: imageInsertFile.public_url,
    });
    dispatch(closePopup());
  };
}

export function update() {
  return (dispatch, getState) => {
    const { imageInsertEditorName, imageInsertEditState } = getState();
    toutBackboneHelper.imageUpdate({
      editorName: imageInsertEditorName,
      height: imageInsertEditState.height,
      link: imageInsertEditState.link,
      name: imageInsertEditState.name,
      width: imageInsertEditState.width,
      url: imageInsertEditState.url,
    });
    dispatch(closePopup());
  };
}

export function onChange(data) {
  return {
    type: ActionTypes.imageInsert.state.change,
    data,
  };
}

/* Note: only exposed for testing */
export function uploadProgress(dispatch) {
  return (e = {}) => {
    let progress;
    let status;

    if (e.lengthComputable) {
      progress = Math.round((e.loaded * 100) / e.total);
      status = ProgressStatus.uploading;
    } else {
      progress = null;
      status = ProgressStatus.issue;
    }
    dispatch({
      type: ActionTypes.imageInsert.state.uploadProgressUpdate,
      data: {
        progress,
        status,
      },
    });
  };
}

function checkForAlert(files) {
  if (!files || !files.length) {
    return AlertIds.issue;
  } else if (!AllowedFileTypes[files[0].type]) {
    return AlertIds.fileTypeInvalid;
  } else if (files[0].size > 10000000) {
    return AlertIds.fileTooLarge;
  } else {
    return null;
  }
}

export function uploadFiles(files) {
  return (dispatch) => {
    // mixpanel.track(mixpanel.events.contentUpload, { count: files.length });

    const alert = checkForAlert(files);
    if (alert) {
      dispatch(setPopupAlert(alert));
    } else {
      const file = files[0];

      dispatch({
        type: ActionTypes.imageInsert.state.uploadStarting,
        data: {
          name: file.name,
          progress: 0,
          status: ProgressStatus.uploading,
        },
      });

      uploadFileCall(file, uploadProgress(dispatch, file.name))
        .then((data = {}) => {
          dispatch({
            type: ActionTypes.imageInsert.state.uploadSuccess,
            data: {
              progress: 100,
              status: ProgressStatus.finished,
              ...data,
            },
          });
        })
        .catch(() => {
          dispatch({
            type: ActionTypes.imageInsert.state.uploadProgressUpdate,
            data: {
              status: ProgressStatus.error,
            },
          });
        });
    }
  };
}
