import {
  MIN_CREATE_GROUP_NAME_LENGTH,
  PEOPLE_GENERAL_GROUPS,
  DO_NOT_ADD_CONTACT_ID,
} from 'web/people/libs/peopleConstants';
import isEmpty from 'lodash/isEmpty';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { default as I18N, moment } from 'languages';
import { EmailRFC2822Pattern } from 'web/libs/constants';
import { UNSUBSCRIBED_TAG } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';
import { isUnsubscribed } from 'web/person/helpers/personHelpers';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { INVALID_DATE } from 'libs/constantsShared';

export const isGeneralPeopleGroup = (groupId) =>
  [
    PEOPLE_GENERAL_GROUPS.reminders.id,
    PEOPLE_GENERAL_GROUPS.unsubscribed.id,
    PEOPLE_GENERAL_GROUPS.myContacts.id,
    PEOPLE_GENERAL_GROUPS.everyone.id,
  ].includes(groupId);

export const isUnsubscribedGroup = (groupId) =>
  groupId === PEOPLE_GENERAL_GROUPS.unsubscribed.id;

export const isSelectAllDisabled = (groupId) =>
  [
    PEOPLE_GENERAL_GROUPS.everyone.id,
    PEOPLE_GENERAL_GROUPS.myContacts.id,
  ].includes(groupId);

export const isUnsubscribedPerson = (person) =>
  person.cached_tag_list === UNSUBSCRIBED_TAG ||
  isUnsubscribed(person) ||
  person.tags === UNSUBSCRIBED_TAG;

export const isEmptyGroup = (peopleIds) =>
  Array.isArray(peopleIds) && peopleIds.length === 0;

export const transformPeopleData = (data) => {
  if (isEmpty(data)) {
    return {
      results: [],
      total: 0,
    };
  }

  const { total, results } = data;

  return {
    results: results.map((person) => {
      const { addresses, ...rest } = person;

      return {
        ...(addresses
          ? {
              addresses: addresses.map((address) => ({
                address,
                address_type: 'email',
              })),
            }
          : {}),
        ...rest,
      };
    }),
    total,
  };
};

export const validateGroupName = (name, allPeopleGroups, userId) => {
  const existingGroup = allPeopleGroups.find(
    (group) => group.name === name && userId === group.user_id
  );

  if (existingGroup) {
    return { id: PeopleAlertIds.groupDuplicateNameError };
  }

  if (name.trim().length < MIN_CREATE_GROUP_NAME_LENGTH) {
    return {
      id: PeopleAlertIds.groupMinLengthNameError,
      textValues: { min: MIN_CREATE_GROUP_NAME_LENGTH },
    };
  }

  return false;
};

export const getPeopleForAddPeopleToGroup = (items) =>
  items.reduce((resultItems, person) => {
    const {
      addresses,
      first_name: firstName,
      last_name: lastName,
      id,
    } = person;

    const resultAddresses = addresses.reduce((itemAddresses, address) => {
      if (address.address_type === 'email') {
        return [
          ...itemAddresses,
          {
            label: address.address,
            value: address.id.toString(),
          },
        ];
      }

      return itemAddresses;
    }, []);

    resultAddresses.push({
      label: I18N.getStr('common.doNotAdd'),
      value: DO_NOT_ADD_CONTACT_ID,
    });

    return [
      ...resultItems,
      {
        addresses: resultAddresses,
        emailId: resultAddresses[0].value,
        id,
        isUnsubscribed: isUnsubscribedPerson(person),
        name:
          firstName === null && lastName === null
            ? ''
            : `${firstName} ${lastName}`,
      },
    ];
  }, []);

// This func is used as a fallback for case when primary address wasn't specified
// related to THERD-1514
export const getPrimaryEmailFromAddresses = (addresses) => {
  const emailRegExp = new RegExp(`^${EmailRFC2822Pattern}$`, 'i');
  const { address } =
    (Array.isArray(addresses) &&
      addresses.find((item) => emailRegExp.test(item.address))) ||
    {};

  return address;
};

export const isSharedContact = (contact) =>
  Array.isArray(contact.team_ids) &&
  contact.team_ids.length > 0 &&
  !isUnsubscribedPerson(contact);

export const getPeopleSidebarSearchResults = (search = '', groups = {}) => {
  const result = search
    ? map(groups, ({ list = [], title }) => {
        const collection = list.filter(
          ({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        );
        return collection.length
          ? {
              collection,
              name: title,
              type: title,
            }
          : null;
      })
    : null;
  return compact(result);
};

export const getLocalizedDateFromGroupName = (name = '') => {
  const namePart = name.split(' - ');
  if (namePart.length > 1) {
    const dateString = namePart.pop();
    const groupName = name.replace(dateString, '');
    let localizedDate = moment(dateString).format(
      I18N.getStr('web.customDatePattern.dateTimeShortWithoutYear')
    );
    localizedDate = localizedDate === INVALID_DATE ? dateString : localizedDate;
    return `${groupName} ${localizedDate}`;
  }
  return name;
};
