import { baseGet } from 'web/services/api';
import { basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getContentPartners() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.contentPartners, {}, resolve, reject);
  });
}

export function setContentPartners(params = {}) {
  return new Promise((resolve, reject) => {
    basePut(Urls.contentPartners, params, resolve, reject);
  });
}

export function getContentPartnersMetadata(pickerUrl) {
  return new Promise((resolve, reject) => {
    baseGet(`${pickerUrl}?metadata=true`, {}, resolve, reject);
  });
}
