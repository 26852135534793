import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { parseActiveCampaignTemplates } from 'web/campaignsUsingTemplate/services/parsers';
import { getActiveCampaignTemplates as getActiveCampaignTemplatesCall } from 'web/campaignsUsingTemplate/services/campaignsUsingTemplateService';

export function setLoading(data) {
  return {
    type: ActionTypes.campaignsUsingTemplate.state.loading,
    data,
  };
}

export function getActiveCampaignTemplates(id) {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    getActiveCampaignTemplatesCall(id)
      .then((data) => {
        dispatch({
          type: ActionTypes.campaignsUsingTemplate.state.update,
          data: {
            id,
            campaignTemplates: parseActiveCampaignTemplates(
              data,
              getState().user
            ),
          },
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        //todo error
        dispatch(setLoading(false));
      });
  };
}
