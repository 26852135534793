import sortBy from 'lodash/sortBy';
import moment from 'moment';
import {
  CampaignListFilters,
  CampaignSearchDataTypes,
  CampaignsSpecialUsers,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { createSelector } from 'reselect';
import { PeopleFilters } from 'web/campaigns/people/libs/campaignsPeopleConstants';
import { OVERDUE_TASK_DATE_RANGE } from 'web/tasks/libs/tasksConstants';
import I18N from 'languages';
import { isCampaignsEditingDisabled } from 'web/settings/adminSettings/general/selectors/generalPageSelectors';
import { isAdmin } from 'web/user/selectors/userSelectors';

const getUser = (state) => state.user;
const getCampaigns = (state) => state.campaigns;
const getCampaignCategories = (state) => state.campaignCategories;
const getCurrentCategory = (state) => state.currentCategory;
const getCampaignsListFilter = (state) => state.campaignsListFilter;
const getPeople = (state) => state.campaignsPeople;
const getPeopleSearch = (state) => state.campaignsPeopleSearch;
const getSelectedCampaignId = (state) => state.campaignsTabSelectedCampaign;
const getCampaignsPeopleIsSearching = (state) =>
  state.campaignsPeopleIsSearching;
const getPeoplePagination = (state) => state.campaignsPeoplePagination;
const getPeopleSearchPagination = (state) =>
  state.campaignsPeopleSearchPagination;
const getTasksUncompleted = (state) => state.tasksUncompleted;
export const getCampaignViewer = (state) => state.campaignsTabViewer;

const listFilter = (filteredCampaigns, filter, user, campaignViewer) => {
  switch (filter) {
    case CampaignListFilters.active:
      return filteredCampaigns.filter(
        (campaign) =>
          campaign.currently_active_count &&
          campaign.currently_active_count[user.id]
      );
    case CampaignListFilters.inactive:
      return filteredCampaigns.filter(
        (campaign) =>
          !campaign.currently_active_count ||
          !campaign.currently_active_count[user.id]
      );
    case CampaignListFilters.mine:
      return filteredCampaigns.filter(
        (campaign) => campaign.user_id === campaignViewer.id
      );
    case CampaignListFilters.shared:
      return filteredCampaigns.filter(
        (campaign) => campaign.team_ids && campaign.team_ids.length
      );
    case CampaignListFilters.unshared:
      return filteredCampaigns.filter((campaign) => {
        const isUnshared = campaign.team_ids && campaign.team_ids.length === 0;

        // When the View As: filter is set to "All", the results should show all Templates that are not shared and not owned by the admin
        if (campaignViewer.id === CampaignsSpecialUsers.all.id) {
          return isUnshared && campaign.user_id !== user.id;
        }

        return isUnshared;
      });
    default:
      return filteredCampaigns;
  }
};

export const getCampaignSearchData = createSelector(
  [getCampaigns, getCampaignCategories],
  (campaigns, campaignCategories) => [
    {
      type: CampaignSearchDataTypes.categories.type,
      name: CampaignSearchDataTypes.categories.name,
      collection: sortBy(
        campaignCategories,
        (category) => category && category.name.toLowerCase()
      ),
    },
    {
      type: CampaignSearchDataTypes.campaigns.type,
      name: CampaignSearchDataTypes.campaigns.name,
      collection: sortBy(
        campaigns,
        (campaign) => campaign && campaign.name && campaign.name.toLowerCase()
      ),
    },
  ]
);

export const getFilteredCampaigns = createSelector(
  [
    getUser,
    getCampaigns,
    getCurrentCategory,
    getCampaignsListFilter,
    getCampaignViewer,
  ],
  (
    user,
    campaigns,
    currentCategory,
    campaignsListFilter,
    campaignsTabViewer
  ) => {
    const filteredCampaigns = campaigns.filter((campaign) => {
      if (currentCategory === null || currentCategory === -1) {
        return campaign;
      } else {
        return campaign.category_id === currentCategory;
      }
    });

    return sortBy(
      listFilter(
        filteredCampaigns,
        campaignsListFilter,
        user,
        campaignsTabViewer
      ),
      (campaign) => campaign && campaign.name && campaign.name.toLowerCase()
    );
  }
);

export const getCampaignsPeople = createSelector(
  [getPeople, getPeopleSearch, getCampaignsPeopleIsSearching],
  (campaignsPeople, campaignsPeopleSearch, isSearching) => {
    let data;
    if (isSearching) {
      data = campaignsPeopleSearch;
    } else {
      data = campaignsPeople;
    }
    return data.map((elem) => ({
      ...elem,
      isSelectedDisabled: elem.state === PeopleFilters.completed,
    }));
  }
);

export const getCampaignsPeoplePagination = createSelector(
  [
    getPeoplePagination,
    getPeopleSearchPagination,
    getCampaignsPeopleIsSearching,
  ],
  (page, searchPage, isSearching) => {
    if (isSearching) {
      return searchPage;
    } else {
      return page;
    }
  }
);

export const getTasksDueIndexedByCampaign = createSelector(
  [getTasksUncompleted],
  (tasks) => {
    const today = moment();
    const overdue = moment().subtract(OVERDUE_TASK_DATE_RANGE, 'd');
    const index = {};
    tasks
      .valueSeq()
      .toArray()
      .forEach((task) => {
        if (
          task.workflow_details &&
          task.workflow_details.workflow_id &&
          moment(task.remind_at).isBetween(overdue, today, 'd', '[]')
        ) {
          index[task.workflow_details.workflow_id] =
            (index[task.workflow_details.workflow_id] || 0) + 1;
        }
      }, []);

    return index;
  }
);

/* eslint-disable */
export const getCampaignCategoriesViewer = createSelector(
  [getCampaignViewer],
  ({ id: user_id, type: viewer_type } = {}) =>
    user_id === CampaignsSpecialUsers.all.id
      ? { viewer_type }
      : { user_id, viewer_type }
);
/* eslint-enable */

export const getCampaignsParams = createSelector(
  [getCampaignViewer],
  ({ id } = {}) =>
    id && id !== CampaignsSpecialUsers.all.id ? { user_id: id } : {}
);

export const getViewAsFilterItems = createSelector(
  [getUser],
  ({ id: currentUserId, admin, subscription: { users = [] } = {} } = {}) => {
    let currentUser;
    let i;
    const sortableUsers = [];
    const usersLength = users.length;

    if (!admin) {
      return sortableUsers;
    }

    for (i = 0; i < usersLength; i++) {
      const { id: value, name: label } = users[i];

      if (value === currentUserId) {
        currentUser = { value, label };
      } else {
        sortableUsers.push({ value, label });
      }
    }

    const sortedUsers = sortBy(
      sortableUsers,
      ({ label }) => label && label.toLowerCase()
    );

    if (currentUser) {
      sortedUsers.unshift(currentUser);
    }

    sortedUsers.unshift({
      value: CampaignsSpecialUsers.all.id,
      label: I18N.getStr('web.templates.allUsersFilterLabel'),
    });

    return sortedUsers;
  }
);

export const getIsDisabledForViewer = createSelector(
  [getCampaignViewer, getUser],
  ({ id: viewerId } = {}, { id: userId } = {}) =>
    viewerId != null && viewerId !== userId
);

export const getSelectedCampaign = createSelector(
  [getCampaigns, getSelectedCampaignId],
  (campaigns, selectedCampaignId) =>
    campaigns.find(({ id }) => id === selectedCampaignId)
);

export const getUserCanEditCampaigns = createSelector(
  [isCampaignsEditingDisabled, isAdmin],
  (isCampaignsEditingDisabled, isAdmin) =>
    isAdmin || !isCampaignsEditingDisabled
);
