import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import './statusSmartFilter.scss';
import classNames from 'classnames';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';
import SentActivityBlock from 'web/commandCenter/modules/statusSmartFilter/components/sentActivityBlock';
import CheckboxSubTypesBlock from 'web/commandCenter/modules/statusSmartFilter/components/checkboxSubTypesBlock';

const TABS_ICONS_ENUM = {
  [CommandCenterEmailsTabsEnum.sent.value]: 'sent-status',
  [CommandCenterEmailsTabsEnum.pending.value]: 'scheduled-status',
  [CommandCenterEmailsTabsEnum.undelivered.value]: 'undelivered-status',
};

const SUB_TABS_TITLE_ENUM = {
  [CommandCenterEmailsTabsEnum.sent.value]: 'common.activity',
  [CommandCenterEmailsTabsEnum.pending.value]: 'common.pendingTypes',
  [CommandCenterEmailsTabsEnum.undelivered.value]: 'common.activity',
};

const SUB_TABS_BLOCKS_ENUM = {
  [CommandCenterEmailsTabsEnum.sent.value]: SentActivityBlock,
  [CommandCenterEmailsTabsEnum.pending.value]: CheckboxSubTypesBlock,
  [CommandCenterEmailsTabsEnum.undelivered.value]: CheckboxSubTypesBlock,
};

class StatusSmartFilter extends Component {
  constructor(props) {
    super(props);
    const {
      selectedSubTabValue,
      selectedTabValue,
      setAdvancedSearchStatusValue,
    } = props;
    if (selectedTabValue === CommandCenterEmailsTabsEnum.search.value) {
      return;
    }
    // if not in advanced search - set current tab or/and subtab as initial filter
    if (selectedTabValue === CommandCenterEmailsTabsEnum.sent.value) {
      setAdvancedSearchStatusValue({
        subTabIds: [],
        tabId: selectedTabValue,
      });
    } else {
      setAdvancedSearchStatusValue({
        subTabIds: [selectedSubTabValue],
        tabId: selectedTabValue,
      });
    }
  }

  render() {
    const {
      searchStatus: { tabId },
    } = this.props;
    const tabs = [
      CommandCenterEmailsTabsEnum.sent,
      CommandCenterEmailsTabsEnum.pending,
      CommandCenterEmailsTabsEnum.undelivered,
    ];

    return (
      <div className="advanced-search-status-block">
        <h3>
          <FormattedMessage id="common.status" />
        </h3>
        <div className="flex flex--align-left">
          {tabs.map(({ label, value }) => (
            <div
              className={classNames('status-icon', TABS_ICONS_ENUM[value], {
                enabled: tabId === value,
              })}
              key={value}
              onClick={() => this.handleTabClick(value)}
            >
              <p>
                <FormattedMessage id={label} />
              </p>
            </div>
          ))}
        </div>
        {this._getStatusSubBlock(tabId)}
        <div />
      </div>
    );
  }

  handleTabClick(tabId) {
    const {
      setAdvancedSearchStatusValue,
      searchStatus: { tabId: selectedFilterTab },
    } = this.props;

    if (selectedFilterTab !== tabId) {
      setAdvancedSearchStatusValue({
        subTabIds: [],
        tabId,
      });
    }
  }

  _getStatusSubBlock(currentTabId) {
    const {
      addSentSubStatusToFilter,
      deleteSentSubStatusInFilter,
      replaceSentSubStatusInFilter,
      searchStatus: { subTabIds },
      setAdvancedSearchStatusValue,
    } = this.props;
    const SubStatusBlock = SUB_TABS_BLOCKS_ENUM[currentTabId];
    return (
      SubStatusBlock && (
        <div>
          <h3>
            <FormattedMessage id={SUB_TABS_TITLE_ENUM[currentTabId]} />
          </h3>
          <SubStatusBlock
            addSentStatusValue={addSentSubStatusToFilter}
            deleteSentStatusValue={deleteSentSubStatusInFilter}
            replaceSentStatusValue={replaceSentSubStatusInFilter}
            setStatusValue={setAdvancedSearchStatusValue}
            subTabIds={subTabIds}
            tabId={currentTabId}
          />
        </div>
      )
    );
  }
}

StatusSmartFilter.propTypes = {
  addSentSubStatusToFilter: PropTypes.func.isRequired,
  deleteSentSubStatusInFilter: PropTypes.func.isRequired,
  replaceSentSubStatusInFilter: PropTypes.func.isRequired,
  searchStatus: PropTypes.object.isRequired,
  selectedSubTabValue: PropTypes.string.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
  setAdvancedSearchStatusValue: PropTypes.func.isRequired,
};

export default injectIntl(StatusSmartFilter);
