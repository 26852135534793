import I18N from 'languages';

export const DEFAULT_PAGE = 1;
export const EXPORT_UNSUBSCRIBED_PER_PAGE = 5000;

export const InitialPeopleSortParams = {
  column: 'created_at',
  direction: 'desc',
};
export const ExternalRefServices = {
  marketo: 'marketo',
};

export const ExternalRefServiceEntityTypes = {
  lead: 'lead', // TODO: change this to title case when backend is change
};

export const LeadFieldNames = {
  marketoLeadId: 'marketo_lead_id',
};

export const SORT_DIRECTION = {
  ascending: 'asc',
  descending: 'desc',
};

export const PEOPLE_TABLE_ID = 'people-tab-table';
export const COLUMN_SETTINGS_PEOPLE_TABLE_ID =
  'columns_settings_people_table_id';
export const UNSUBSCRIBED_PEOPLE_TABLE_ID = 'unsubscribed-people-tab-table';

export const PEOPLE_SMART_FILTER_VALUES = {
  account: 'account',
  mine: 'mine',
};

export const MIN_CREATE_GROUP_NAME_LENGTH = 4;

export const PEOPLE_GENERAL_GROUPS = {
  everyone: { id: 'everyone', name: I18N.getStr('web.people.allPeople') },
  myContacts: { id: 'my-contacts', name: I18N.getStr('common.myContacts') },
  reminders: { id: 'reminders', name: I18N.getStr('common.reminders') },
  unsubscribed: {
    id: 'unsubscribed-contacts',
    name: I18N.getStr('common.unsubscribed'),
  },
};

export const PEOPLE_PAGE_FEEDBACK_LINK =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=Wht7-jR7h0OUrtLBeN7O4RxOkDl3hxdHoHRn-dsVTV9UMk4wSzZKUk1HTTlIOURYT0hSMjFZV1JLOS4u';

/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
export const PEOPLE_SIDEBAR_GENERAL_GROUP_LIST = {
  groups: {
    list: [
      {
        id: PEOPLE_GENERAL_GROUPS.everyone.id,
        isEditable: false,
        name: PEOPLE_GENERAL_GROUPS.everyone.name,
      },
    ],
    title: I18N.getStr('common.groups'),
  },
  dynamicGroups: {
    unsubscribed: {
      id: PEOPLE_GENERAL_GROUPS.unsubscribed.id,
      isEditable: false,
      name: PEOPLE_GENERAL_GROUPS.unsubscribed.name,
    },
    myContacts: {
      id: PEOPLE_GENERAL_GROUPS.myContacts.id,
      isEditable: false,
      name: PEOPLE_GENERAL_GROUPS.myContacts.name,
    },
    title: I18N.getStr('common.dynamicGroups'),
  },
};
/* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */

export const PEOPLE_VIEWER_TYPES = {
  subscription: 'subscription',
  user: 'user',
};

export const PEOPLE_FETCH_REQUEST_MODES = {
  all: 'all',
  mine: 'mine',
};

export const PEOPLE_FETCH_REQUEST_SPECIAL_TAGS = {
  unsubscribed: 'unsubscribed',
};

export const PEOPLE_FETCH_REQUEST_SEARCH_COLUMNS = [
  'first_name',
  'last_name',
  'title',
  'company',
  'primary_addresses.email',
];

export const VIEWER_ALL_ID = '-1';

export const FETCH_REQUEST_TYPES = {
  person: 'person',
};

export const EMAIL_FIELDS = ['email', 'work_email', 'home_email'];
export const DO_NOT_ADD_CONTACT_ID = '-1';
export const DO_NOT_IMPORT_FIELD_ID = '-1';
export const ADD_NEW_FIELD = '-2';

export const TABLE_MAP_IMPORT_FIELDS_ID = 'MAP_IMPORT_FIELDS';
export const TABLE_MAP_IMPORT_FIELDS_MAX_HEIGHT = 350;
export const MAX_SELECTED_ITEMS_FOR_EMAIL_BULK_ACTION = 200;
export const ENTER_KEY_CHARCODE = 13;
export const MAX_GROUP_DESCRIPTION_LENGTH = 130;
