import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button, { ButtonColors } from 'components/buttons/action';
import './gmailNotConnected.scss';

class GmailNotConnected extends PureComponent {
  render() {
    const { connectGoogle } = this.props;

    return (
      <div className="gmail-not-connected">
        <Button color={ButtonColors.blue} onClick={connectGoogle}>
          <FormattedMessage id="web.emailIntegration.gmail.connect" />
        </Button>
      </div>
    );
  }
}

GmailNotConnected.propTypes = {
  connectGoogle: PropTypes.func.isRequired,
};

export default GmailNotConnected;
