/*  To use, wrap your component:
 *    export default intervalHandler(Component, Options);
 *  Notes:
 *    Base use case is to rerender 'date ago' components
 *    Options precedence:
 *      1) Props passed in by parent
 *      2) Options set when component wrapped
 *      3) Default options to cause rerender (WILL CAUSE RERENDERS SO BE CAREFUL)
 *
 *  API:
 *    WrappedComponent - React Component - component to be wrapped
 *    Options - Props passed that can be defined by the component when being wrapped.
 *      - interval - sets the interval timing in milliseconds
 *      - intervalCallback - sets function to call on interval
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function intervalHandler(
  WrappedComponent,
  intervalOptions = { interval: 60000 }
) {
  const componentName =
    WrappedComponent.displayName || WrappedComponent.name || 'NoName';
  //eslint-disable-next-line no-param-reassign
  WrappedComponent.displayName = `IntervalHandler${componentName}`;

  return class IntervalHandler extends Component {
    constructor(props) {
      super(props);
      this.interval = null;
    }

    state = {
      renderSwitch: false,
    };

    componentWillReceiveProps(nextProps) {
      const { intervalCallback, interval } = this.props;
      if (
        interval !== nextProps.interval ||
        intervalCallback !== nextProps.intervalCallback
      ) {
        this._setInterval(nextProps);
      }
    }

    componentDidMount() {
      this._setInterval(this.props);
    }

    componentWillUnmount() {
      this._clearInterval();
    }

    render() {
      const {
        interval: ignored1,
        intervalCallback: ignored2,
        ...other
      } = this.props;
      return <WrappedComponent {...other} />;
    }

    _setInterval = ({ interval, intervalCallback }) => {
      this._clearInterval();

      this.interval = setInterval(() => {
        const callback =
          intervalCallback || intervalOptions.callback || this._renderCallback;
        callback();
      }, interval || intervalOptions.interval);
    };

    _clearInterval = () => {
      if (this.interval) {
        clearInterval(this.interval);
      }
    };

    _renderCallback = () =>
      this.setState(({ renderSwitch }) => ({ renderSwitch: !renderSwitch }));

    static displayName = `IntervalRender${componentName}`;

    static propTypes = {
      interval: PropTypes.number,
      intervalCallback: PropTypes.func,
    };

    static defaultProps = {
      interval: 0,
      intervalCallback: null,
    };
  };
}
