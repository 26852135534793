import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { reorderCampaignSteps } from '../helpers/campaignStepHelper';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';

/*eslint complexity: [1, 30]*/
export function campaigns(state = [], action) {
  let newState = [];
  let temp = {};
  switch (action.type) {
    case ActionTypes.api.campaigns.success: {
      const { data } = action;
      return data;
    }
    case ActionTypes.api.cloneCampaign.success:
    case ActionTypes.common.data.campaignAdd:
      return [...state, action.data];
    case ActionTypes.common.data.campaignDelete: {
      const index = state.findIndex((campaign) => campaign.id === action.id);
      if (index > -1) {
        return index > -1
          ? [...state.slice(0, index), ...state.slice(index + 1)]
          : state;
      } else {
        return state;
      }
    }
    case ActionTypes.common.data.campaignAddStep:
      newState = cloneDeep(state);
      temp = remove(newState, (elem) => elem.id === action.id);
      temp = temp[0];
      temp.steps.push(action.step);
      temp.steps = sortBy(temp.steps, ['day', 'order']);
      newState.push(temp);
      return newState;
    case ActionTypes.common.data.campaignUpdate: {
      newState = cloneDeep(state);
      const updatedIndex = findIndex(newState, { id: action.data.id });
      if (updatedIndex > -1) {
        newState[updatedIndex] = action.data;
        return newState;
      } else {
        return state;
      }
    }
    case ActionTypes.common.data.campaignUpdateStep: {
      newState = cloneDeep(state);
      const campaignIndex = findIndex(newState, { id: action.id });
      let stepPredicate = { id: action.step.id };
      const { searchBy } = action.step;
      if (searchBy) {
        stepPredicate = {
          [searchBy]: action.step[searchBy],
        };
      }
      const campaignSteps = newState[campaignIndex].steps;
      const stepIndex = findIndex(campaignSteps, stepPredicate);
      if (campaignIndex > -1 && stepIndex > -1) {
        const originalStep = cloneDeep(campaignSteps[stepIndex]);
        campaignSteps[stepIndex] = {
          ...originalStep,
          ...action.step,
        };
        reorderCampaignSteps(newState, originalStep, campaignSteps[stepIndex]);
        newState.steps = sortBy(campaignSteps, ['day', 'order']);
        return newState;
      } else {
        return state;
      }
    }
    case ActionTypes.campaignsTab.data.currentUsers: {
      newState = cloneDeep(state);
      const campaignIndex = findIndex(newState, { id: action.id });
      if (campaignIndex > -1) {
        newState[campaignIndex].current_users = action.data;
        return newState;
      } else {
        return state;
      }
    }
    case ActionTypes.common.data.campaignUpdateByProperty: {
      const index = findIndex(state, { id: action.id });
      if (index > -1) {
        const updatedCampaign = { ...state[index], ...action.newProperties };
        return [
          ...state.slice(0, index),
          updatedCampaign,
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    }
    case ActionTypes.common.data.campaignMetricUpdate: {
      newState = cloneDeep(state);
      const campaignIndex = findIndex(newState, {
        id: action.updatedCampaignMetric.workflow_id,
      });
      if (!(campaignIndex > -1)) {
        return state;
      }
      const metricIndex = findIndex(newState[campaignIndex].workflow_metrics, {
        id: action.updatedCampaignMetric.id,
      });
      if (campaignIndex > -1 && metricIndex > -1) {
        newState[campaignIndex].workflow_metrics[metricIndex] =
          action.updatedCampaignMetric;
        return newState;
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

export function activeCampaigns(state = [], action) {
  switch (action.type) {
    case ActionTypes.api.activeCampaigns.success:
      return action.data;
    default:
      return state;
  }
}

export function autocompleteResults(state = [], action) {
  switch (action.type) {
    case ActionTypes.api.autocomplete.success:
      return action.data;
    case ActionTypes.api.autocomplete.failure:
    case ActionTypes.autocomplete.data.clear:
    case ActionTypes.addToCampaignModal.state.reset:
      return [];
    default:
      return state;
  }
}
