import React from 'react';
import ProgressItem from '../progressItem';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { steps } from 'web/onboarding/lib/stepsRouting';
import './progressBar.scss';

const ProgressBarContainer = ({ activeStep, skippedSteps, completedSteps }) => {
  const checkSteps = (steps, index) =>
    steps.includes(index + 1) && (index < activeStep - 1 || !activeStep);

  return (
    <div className="progress-bar">
      {steps.map((step, index) => (
        <ProgressItem
          activeStep={activeStep - 1 === index}
          completedStep={checkSteps(completedSteps, index)}
          key={step.url}
          lastStep={index === steps.length - 1}
          skippedStep={checkSteps(skippedSteps, index)}
          titleId={step.progressBarTitle}
        />
      ))}
    </div>
  );
};

ProgressBarContainer.propTypes = {
  activeStep: PropTypes.number.isRequired,
  completedSteps: PropTypes.arrayOf(PropTypes.number),
  skippedSteps: PropTypes.arrayOf(PropTypes.number),
};

ProgressBarContainer.defaultProps = {
  completedSteps: [],
  skippedSteps: [],
};

const mapStateToProps = (state) => ({
  activeStep: state.onboarding.onboardingActiveStep,
  completedSteps: state.onboarding.completedSteps,
  skippedSteps: state.onboarding.skippedStepsReducer,
});

export default connect(mapStateToProps)(ProgressBarContainer);
