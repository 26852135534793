export default {
  setEditTeamsLoading: 'TEAM_MEMBERS_SET_EDIT_TEAMS_LOADING',
  setAddTeamMembersToTeamsLoading:
    'TEAM_MEMBERS_SET_ADD_TEAM_MEMBERS_TO_TEAMS_LOADING',
  setDeleteTeamMembersLoading: 'TEAM_MEMBERS_SET_DELETE_TEAM_MEMBERS_LOADING',
  setChangeRoleLoading: 'TEAM_MEMBERS_SET_CHANGE_ROLE_LOADING',
  setAddTeamMembersToTeamLoading:
    'TEAM_MEMBERS_SET_ADD_TEAM_MEMBERS_TO_TEAM_LOADING',
  editTeams: 'TEAM_MEMBERS_UI_EDIT_TEAMS',
  addTeamMembersToTeams: 'TEAM_MEMBERS_UI_ADD_TEAM_MEMBERS_TO_TEAMS',
  addTeamMembersToTeam: 'TEAM_MEMBERS_UI_ADD_TEAM_MEMBERS_TO_TEAM',
  deleteTeamMembers: 'TEAM_MEMBERS_UI_DELETE_TEAM_MEMBERS',
  grantAdminRole: 'TEAM_MEMBERS_UI_GRANT_ADMIN_ROLE',
  grantUserRole: 'TEAM_MEMBERS_UI_GRANT_USER_ROLE',
};
