import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import I18N from 'languages';

import './zeroState.scss';

class ZeroState extends PureComponent {
  render() {
    const {
      bodyText,
      customImage,
      imageUrl,
      imageWidth,
      titleText,
    } = this.props;

    return (
      <div className="tout-zero-state">
        <div className="zero-state-container">
          <h2 className="text-center text-blue-darker">{titleText}</h2>
          <h4 className="text-center text-dark-gray-light margin-top-most">
            {bodyText}
          </h4>
          {imageUrl && (
            <img
              className="zero-state-image"
              src={imageUrl}
              width={imageWidth ? `${imageWidth}px` : ''}
              alt="Zero state image"
            />
          )}
          {customImage}
        </div>
      </div>
    );
  }
}

ZeroState.propTypes = {
  imageWidth: PropTypes.number,
  titleText: PropTypes.string,
  customImage: PropTypes.node,
  imageUrl: PropTypes.string,
  bodyText: PropTypes.string,
};

ZeroState.defaultProps = {
  imageWidth: null,
  titleText: I18N.getStr('web.zeroState.noData'),
  customImage: null,
  imageUrl: '',
  bodyText: I18N.getStr('web.zeroState.currentSettingsNoDataToDisplay'),
};

export default ZeroState;
