import React from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import {
  getDraggedItems,
  getSelectedIdsFromStore,
} from './withDndSource.helper';
import { onClearAll } from '../selectable/selectableActionCreators';
import { isDetectIE } from 'web/libs/isDetectIE';

import './withDndSource.scss';

export const ItemType = 'BOX';

export default function withDndSource(WrappedComponent) {
  class WithDndSource extends React.Component {
    componentDidMount() {
      const { connectDragPreview } = this.props;
      if (connectDragPreview) {
        // Use empty image as a drag preview so browsers don't draw it
        // and we can draw whatever we want on the custom drag layer instead.
        connectDragPreview(getEmptyImage(), {
          // IE fallback: specify that we'd rather screenshot the node
          // when it already knows it's being dragged so we can hide it with CSS.
          captureDraggingState: true,
        });
      }
    }

    render() {
      const {
        bulkMoveTemplate,
        connectDragPreview,
        connectDropSource,
        disableDrag,
        dispatch,
        isDragging,
        onClearAll,
        selectedIds,
        ...restProps
      } = this.props;

      // Fixed problem in Edge with a custom dragging preview layer
      const opacity = isDragging && isDetectIE() ? 0.5 : 1;

      return disableDrag ? (
        <WrappedComponent {...restProps} />
      ) : (
        connectDropSource(
          <div className="drag_source__wrapper" style={{ opacity }}>
            <div className="drag_source__item" />
            <WrappedComponent {...restProps} />
          </div>
        )
      );
    }
  }

  const mapStateToProps = (state) => ({
    selectedIds: getSelectedIdsFromStore(state),
  });

  const mapDispatchToProps = {
    onClearAll,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    DragSource(
      ItemType,
      {
        beginDrag(props) {
          const {
            data: { id },
            selectedIds,
          } = props;
          const idsToDrag = getDraggedItems(id, selectedIds).map((i) =>
            i.toString()
          );
          return { id: idsToDrag };
        },
        endDrag(props, monitor) {
          if (!monitor.didDrop()) {
            // You can check whether the drop was successful
            // or if the drag ended but nobody handled the drop
            return;
          }

          props.onClearAll();
        },
      },
      (connect, monitor) => ({
        isDragging: monitor.isDragging(),
        connectDropSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
      })
    )(WithDndSource)
  );
}
