import { SubmissionError } from 'redux-form';
import SetPasswordActionTypes from '../libs/setPasswordActionTypes';
import { SET_PASSWORD_SERVER_ERROR_MESSAGE_KEY } from '../libs/setPasswordConstants';
import { sendSetPassword as sendSetPasswordCall } from '../services/setPasswordService';
import { getChangePaswordErrorText } from 'web/settings/myProfile/components/accountDetails/helpers/accountDetailsHelpers';

export const initializeSetPasswordPanel = () => ({
  type: SetPasswordActionTypes.initialize,
});

export const setLoading = (loading = true) => ({
  type: SetPasswordActionTypes.setLoading,
  loading,
});

export const showAlert = (alertId) => ({
  type: SetPasswordActionTypes.showAlert,
  alertId,
});

export const clearAlert = () => ({
  type: SetPasswordActionTypes.clearAlert,
});

export const sendSetPasswordRequest = (newPassword, id) => (dispatch) => {
  dispatch(setLoading());

  return sendSetPasswordCall(newPassword, id)
    .then(() => {
      dispatch({ type: SetPasswordActionTypes.passwordChanged });
    })
    .catch((err = {}) => {
      if (err.status === 422) {
        const message = getChangePaswordErrorText(
          err.response.body.errors // has to handle password error
        );

        if (message) {
          throw new SubmissionError({ password: message });
        }
      } else {
        dispatch(showAlert(SET_PASSWORD_SERVER_ERROR_MESSAGE_KEY));
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
