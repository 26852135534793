import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './gongCelebration.scss';
import I18N from 'languages';

class GongCelebration extends Component {
  playFireworksVideo = (video) => {
    if (video) {
      video.play();
    }
  };

  render() {
    const { dealSize } = this.props;

    return (
      <div className="gong-celebration">
        <p className="deal-details">
          <FormattedMessage
            id="gong.dealWorth"
            values={{ worth: I18N.getLocalizedCurrency(dealSize) }}
          />
        </p>
        <video width="550" ref={this.playFireworksVideo}>
          <source
            src="https://tout-user-store.s3.amazonaws.com/assets/static/videos/fireworks/fireworks.webm"
            type="video/webm"
          />
          <source
            src="https://tout-user-store.s3.amazonaws.com/assets/static/videos/fireworks/fireworks.mp4"
            type="video/mp4"
          />
          <source
            src="https://tout-user-store.s3.amazonaws.com/assets/static/videos/fireworks/fireworks.ogg"
            type="video/ogg"
          />
        </video>
      </div>
    );
  }
}

GongCelebration.propTypes = {
  dealSize: PropTypes.string.isRequired,
};

export default GongCelebration;
