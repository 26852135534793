import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './actionsCell.scss';

class ActionsCell extends Component {
  render() {
    const { rowData } = this.props;
    const messageId = this._getCompletedMessaging(rowData);
    const {
      onPrimaryClick,
      onSecondaryClick,
      primaryTextId,
      secondaryTextId,
    } = this._getActions(rowData);

    return (
      <div className="cell-actions">
        {messageId ? (
          <span className="text-gray text-italic text-medium">
            <FormattedMessage id={messageId} />
          </span>
        ) : (
          <div className="cell-actions-row">
            {this._getActionLink({
              onClick: onPrimaryClick,
              textId: primaryTextId,
            })}
            <span className="cell-actions-row-divider">|</span>
            {this._getActionLink({
              onClick: onSecondaryClick,
              textId: secondaryTextId,
            })}
          </div>
        )}
      </div>
    );
  }

  _isCampaign(rowData) {
    return rowData.workflow_details && rowData.workflow_details.workflow_id;
  }

  _getCompletedMessaging(rowData) {
    if (rowData.workflow_details && rowData.workflow_details.success) {
      return 'common.success';
    } else if (rowData.skipped) {
      return 'common.skipped';
    } else if (rowData.done) {
      return 'common.done';
    } else {
      return null;
    }
  }

  _getActionLink({ onClick, textId }) {
    const { rowData, isActionsDisabled } = this.props;
    return isActionsDisabled(rowData) ? (
      <span className="text-gray text-medium">
        <FormattedMessage id={textId} />
      </span>
    ) : (
      <a href="javascript:void(0)" onClick={onClick}>
        <FormattedMessage id={textId} />
      </a>
    );
  }

  _getActions(rowData) {
    if (this._isCampaign(rowData)) {
      return {
        onPrimaryClick: this._markSuccess,
        onSecondaryClick: this._markSkip,
        primaryTextId: 'common.success',
        secondaryTextId: 'common.skip',
      };
    } else {
      return {
        onPrimaryClick: this._openEdit,
        onSecondaryClick: this._delete,
        primaryTextId: 'common.edit',
        secondaryTextId: 'common.delete',
      };
    }
  }

  _markSuccess = (e) => {
    const { markSuccess, rowData } = this.props;
    e.stopPropagation();
    e.preventDefault();
    markSuccess(rowData);
  };

  _openEdit = (e) => {
    const { openEdit, rowData } = this.props;
    e.stopPropagation();
    e.preventDefault();
    openEdit(rowData);
  };

  _markSkip = (e) => {
    const { skip, rowData } = this.props;
    e.stopPropagation();
    e.preventDefault();
    skip(rowData);
  };

  _delete = (e) => {
    const { deleteTask, rowData } = this.props;
    e.stopPropagation();
    e.preventDefault();
    deleteTask(rowData);
  };
}

ActionsCell.propTypes = {
  deleteTask: PropTypes.func,
  intl: intlShape.isRequired,
  isActionsDisabled: PropTypes.func.isRequired,
  markSuccess: PropTypes.func.isRequired,
  openEdit: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  skip: PropTypes.func.isRequired,
};

export default injectIntl(ActionsCell);
