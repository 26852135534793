export const IdentitiesTableColumns = {
  name: 'NAME',
  email: 'EMAIL',
  verified: 'VERIFIED',
  defaultIdentity: 'DEFAULT_IDENTITY',
  deliveryChannel: 'DELIVERY_CHANNEL',
  actions: 'ACTIONS',
};

export const IdentitiesPopupIds = {
  identityDelete: 'IDENTITY_DELETE',
  identityResendConfirmation: 'IDENTITY_RESEND_CONFIRMATION',
  identityResendComplete: 'IDENTITY_RESEND_COMPLETE',
  identityMakeDefaultError: 'IDENTITY_MAKE_DEFAULT_ERROR',
};

export const TABLE_ID = 'identities-table';
export const CURRENT_PAGE = 1;
export const PER_PAGE = 10;
export const ROW_HEIGHT = 70;
