import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select2 from 'components/selects/select2';

class Select extends Component {
  render() {
    const { input, ...other } = this.props;

    return (
      <Select2
        {...input}
        {...other}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    );
  }

  onChange = (option) => {
    const {
      input: { onChange },
      multi,
    } = this.props;

    if (multi) {
      onChange(option.map((item) => item.value));
    } else {
      onChange(option ? option.value : null);
    }
  };

  onBlur = () => {
    const {
      input: { value, onBlur },
    } = this.props;

    onBlur(value);
  };
}

Select.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  isCreatable: PropTypes.bool,
  meta: PropTypes.object,
  multi: PropTypes.bool,
};

Select.defaultProps = {
  input: {
    value: '',
    onChange: () => {},
    onBlur: () => {},
  },
  isCreatable: false,
  meta: {},
  multi: false,
};

export default Select;
