import * as peopleSearchAddReducers from 'web/people/peopleSearchAdd/reducers/peopleSearchAddReducers';
import * as peopleTableReducers from './peopleTableReducers';
import * as peopleExportReducer from './peopleExportReducer';
import * as peopleSearchReducers from './peopleSearchReducers';
import * as peopleAuthorizationReducer from './peopleAuthorizationReducer';
import * as peopleSidebarReducer from './peopleSidebarReducer';
import * as peopleGroupReducer from './peopleGroupReducer';
import * as peopleImportReducer from './peopleImportReducer';
import * as peopleDeleteReducer from './peopleDeleteReducer';
import * as peopleTabStateReducer from './peopleTabStateReducer';

export default Object.assign(
  {},
  peopleAuthorizationReducer,
  peopleExportReducer,
  peopleSearchAddReducers,
  peopleSearchReducers,
  peopleTableReducers,
  peopleSidebarReducer,
  peopleGroupReducer,
  peopleImportReducer,
  peopleDeleteReducer,
  peopleTabStateReducer
);
