import AdminUnsubscribesActionTypes from '../libs/adminUnsubscribesActionTypes';
import { AdminUnsubscribeTabs } from '../libs/adminUnsubscribesConstants';

const initialState = AdminUnsubscribeTabs.messaging;
const initialMarketoCheckState = false;
const initialLoadingState = false;
const initialShowUnsubscribePage = false;
const initialPluginUnsubscribeState = false;

export const adminUnsubscribesActiveTab = (
  state = initialState,
  { type = '', tab = '' }
) => {
  switch (type) {
    case AdminUnsubscribesActionTypes.changeTab:
      return tab;

    default:
      return state;
  }
};

export const showUnsubscribePage = (
  state = initialShowUnsubscribePage,
  { type = '', showPage = false }
) => {
  switch (type) {
    case AdminUnsubscribesActionTypes.showUnsubscribeView:
      return showPage;

    default:
      return state;
  }
};

export const marketoCheckState = (
  state = initialMarketoCheckState,
  { type = '', enabled = false }
) => {
  switch (type) {
    case AdminUnsubscribesActionTypes.updateMarketoCheck:
      return enabled;
    default:
      return state;
  }
};

export const unsubscribesIntegrationLoading = (
  state = initialLoadingState,
  { type = '', loading }
) => {
  switch (type) {
    case AdminUnsubscribesActionTypes.loadAdminSettings:
      return loading;
    default:
      return state;
  }
};

export const pluginUnsubscribe = (
  state = initialPluginUnsubscribeState,
  { type = '', showPluginUnsubscribeMessage }
) => {
  switch (type) {
    case AdminUnsubscribesActionTypes.togglePluginUnsubscribe:
      return showPluginUnsubscribeMessage;
    default:
      return state;
  }
};

export const pluginMessageLoading = (
  state = initialLoadingState,
  { type = '', loading }
) => {
  switch (type) {
    case AdminUnsubscribesActionTypes.loadPluginSettings:
      return loading;
    default:
      return state;
  }
};

export const unsubscribeDomainLoading = (
  state = initialLoadingState,
  { type = '', loading }
) => {
  switch (type) {
    case AdminUnsubscribesActionTypes.loadDomainSettings:
      return loading;
    default:
      return state;
  }
};
