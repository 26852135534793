/**
 *
 * SubjectCell
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextCell from 'table/modules/cells/textCell';
import LinkCell from 'table/modules/cells/linkCell';
import { Urls } from 'web/libs/routes';

class SubjectCell extends PureComponent {
  render() {
    return this.getCellComponent();
  }

  getComposeUrl = (rowData = {}) =>
    Urls.diagnostics.getActivityEmail(rowData.pitchId);

  getCellComponent = () => {
    const { rowData, property, wrapLines } = this.props;

    return rowData.pitchId ? (
      <LinkCell
        openBlank
        rowData={rowData}
        property={property}
        link={this.getComposeUrl}
      />
    ) : (
      <TextCell property={property} rowData={rowData} wrapLines={wrapLines} />
    );
  };
}

SubjectCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  wrapLines: PropTypes.number,
  property: PropTypes.string,
};

SubjectCell.defaultProps = {
  rowData: {},
  wrapLines: 1,
  property: 'subject',
};

export default SubjectCell;
