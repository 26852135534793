import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownIcon from 'components/dropdownIcon';
import { IconColors } from 'components/buttons/icon';
import DropdownMenu from 'components/dropdownMenu';
import './listItemOptions.scss';

const ROW_HEIGHT = 30;

export default class ListItemOptions extends PureComponent {
  render() {
    const {
      className,
      classNameDota,
      height,
      id,
      listScrollRef,
      options,
    } = this.props;

    const checkedOptions = options.map((option) => ({
      ...option,
      disabled: option.checkDisabled(id),
      id,
    }));

    return (
      <div
        className={classNames('list-item-options', className)}
        style={{ height: `${height}px` }}
      >
        <DropdownIcon
          className={classNames('list-item-options-dota', classNameDota)}
          classNameDropdown="list-items-options-menu"
          color={IconColors.blue}
          childHeight={ROW_HEIGHT * options.length}
          scrollRef={listScrollRef}
        >
          <DropdownMenu items={checkedOptions} />
        </DropdownIcon>
      </div>
    );
  }
}

ListItemOptions.propTypes = {
  className: PropTypes.string,
  classNameDota: PropTypes.string.isRequired,
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  listScrollRef: PropTypes.object,
  options: PropTypes.array.isRequired,
};

ListItemOptions.defaultProps = {
  className: '',
  height: 30,
  listScrollRef: {},
};
