import { Urls } from 'web/libs/routes';
import { baseGet, basePost, baseDelete, basePut } from 'web/services/api';

export const getTeams = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.teams(), {}, resolve, reject);
  });

export const createTeam = (team = {}) =>
  new Promise((resolve, reject) => {
    basePost(Urls.teams(), { team }, resolve, reject);
  });

export const deleteTeam = (teamId) =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.teams(teamId), {}, resolve, reject);
  });

export const updateTeam = (team = {}) =>
  new Promise((resolve, reject) => {
    basePut(Urls.teams(team.id), { team }, resolve, reject);
  });

export const grantTeamAdmin = (teamId, userId) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.teamsGrantAdmin(teamId),
      { user_id: userId },
      resolve,
      reject
    );
  });

export const revokeTeamAdmin = (teamId, userId) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.teamsRevokeAdmin(teamId),
      { user_id: userId },
      resolve,
      reject
    );
  });
