import MarketoSubscriptionsActionTypes from 'web/marketo/subscriptions/libs/marketoSubscriptionsActionTypes.js';

export const marketoSubDetails = (state = {}, { type, details }) => {
  switch (type) {
    case MarketoSubscriptionsActionTypes.setMarketoSubDetails:
      return details;
    default:
      return state;
  }
};
