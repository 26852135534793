import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './seCustomizationsCard.scss';
import PropTypes from 'prop-types';

const SeCustomizationsCard = ({ connected, seInstalled }) => (
  <div className="se-customizations-card">
    <h3>
      <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.customization.title" />
    </h3>
    <div className="margin-top-20">
      <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.description.seInstructions" />
    </div>
    {!connected && (
      <Fragment>
        <div className="margin-top-20">
          <i className="not-checked" />
          <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.notConnectedCheck" />
        </div>
        <div className="margin-top-20">
          <i className="not-checked" />
          <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.adminNeedsToInstallMse" />
        </div>
      </Fragment>
    )}
    {connected &&
      !seInstalled && (
        <Fragment>
          <div className="margin-top-20">
            <i className="tout-icon-tick checked" />
            <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.connectedCheck" />
          </div>
          <div className="margin-top-20">
            <i className="not-checked" />
            <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.seNotInstalledCheck" />
          </div>
        </Fragment>
      )}
    {connected &&
      seInstalled && (
        <Fragment>
          <div className="margin-top-20">
            <i className="tout-icon-tick checked" />
            <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.connectedCheck" />
          </div>
          <div className="margin-top-20">
            <i className="tout-icon-tick checked" />
            <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.seInstalledCheck" />
          </div>
        </Fragment>
      )}
  </div>
);

SeCustomizationsCard.propTypes = {
  connected: PropTypes.bool,
  seInstalled: PropTypes.bool,
};

SeCustomizationsCard.defaultProps = {
  connected: false,
  seInstalled: false,
};

export default injectIntl(SeCustomizationsCard);
