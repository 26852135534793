import { basePost, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function updateCampaignTemplates(id, ids = []) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.updateCampaignTemplates.replace(':#id', id),
      { template_ids_to_update: ids },
      resolve,
      reject
    );
  });
}
export function deleteCampaignTemplate(id) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.templatesId.replace(':#id', id), {}, resolve, reject);
  });
}
