import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OptionDotasInfobox from 'components/optionDotas/infobox';
import classNames from 'classnames';
import './dotaCell.scss';

class DotaCell extends PureComponent {
  buildOptions = () => {
    const { options, rowData } = this.props;

    return options.map((option) => {
      const opt = { ...option };
      if (opt.label && typeof opt.label === 'function') {
        opt.label = option.label(rowData);
      }
      if (opt.disabled && typeof opt.disabled === 'function') {
        opt.disabled = option.disabled(rowData);
      }
      if (opt.disabledStyle && typeof opt.disabledStyle === 'function') {
        opt.disabledStyle = option.disabled(rowData);
      }
      opt.onClick = () => option.onClick(rowData);
      return opt;
    });
  };

  render() {
    const { className } = this.props;
    const containerClasses = classNames(className, 'dota-cell', {});

    return (
      <div className={containerClasses}>
        <OptionDotasInfobox options={this.buildOptions()} />
      </div>
    );
  }
}

DotaCell.propTypes = {
  className: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  property: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default DotaCell;
