import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GmailIntegrationPopupIds } from '../libs/gmailIntegrationActionTypes';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { disconnectGoogle } from '../actionCreators/gmailIntegrationActionCreators';
import GmailIntegrationDisconnectConfirmationPopup from '../components/gmailConnected/gmailIntegrationDisconnectConfirmationPopup';

class GmailIntegrationPopupContainer extends Component {
  render() {
    const { popup, actionCreators } = this.props;

    switch (popup) {
      case GmailIntegrationPopupIds.disconnectConfirmation:
        return (
          <GmailIntegrationDisconnectConfirmationPopup
            actionCreators={actionCreators}
          />
        );

      default:
        return null;
    }
  }
}

GmailIntegrationPopupContainer.propTypes = {
  popup: PropTypes.string.isRequired,
  actionCreators: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  popup: state.popup,
});

const mapDispatchToProps = {
  closePopup,
  disconnectGoogle,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GmailIntegrationPopupContainer);
