import React from 'react';
import { FormattedMessage } from 'react-intl';
import './notValidInvitation.scss';
import { getI18nImageUrl } from 'web/libs/constants';

const NotValidInvitation = () => (
  <div className="not-valid-invitation">
    <div className="not-valid-envelope">
      <img
        className="not-valid-envelope-icon"
        src={getI18nImageUrl('notValidInvitation', 'png')}
      />
    </div>
    <div className="not-valid-information">
      <h3 className="title">
        <FormattedMessage id="createAccount.notValidInvitation.title" />
      </h3>
      <div className="info-text">
        <FormattedMessage id="createAccount.notValidInvitation.information" />
      </div>
    </div>
  </div>
);

export default NotValidInvitation;
