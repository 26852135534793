import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import InputText from 'components/inputText/inputText';
import './sidebarEditListItem.scss';

class SidebarEditListItem extends Component {
  constructor(props) {
    super();

    this.sidebarEditListItem = React.createRef();
    this.originalValue = props.value;
    this.state = {
      editListItem: props.value,
      error: false,
    };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this._outsideClickHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this._outsideClickHandler, true);
  }

  render() {
    const { editListItem, error } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="sidebar-edit-list-item" ref={this.sidebarEditListItem}>
        <InputText
          className={`sidebar-edit-list-item-input${error ? ' error' : ''}`}
          onBlur={this._handleUpdate}
          onChange={this._handleInputChange}
          onClick={this._handleOnClick}
          onKeyDown={this._handleKeyDown}
          placeholder={formatMessage({
            id: 'web.camapaigns.editCampaignCategoryPlaceholder',
          })}
          ref={this.sidebarEditListItem}
          type="text"
          value={editListItem}
          autoFocus
        />
        <a
          className="sidebar-edit-list-item-cancel"
          href={null}
          onClick={this._cancelEdit}
        >
          {formatMessage({ id: 'common.cancel' })}
        </a>
      </div>
    );
  }

  _cancelEdit = (e) => {
    e.stopPropagation();
    this.props.onEditCancel();
  };

  _outsideClickHandler = (e) => {
    if (!this.sidebarEditListItem.contains(e.target)) {
      e.stopPropagation();
      this._handleUpdate();
    }
  };

  _handleInputChange = (e) => this.setState({ editListItem: e.target.value });

  _handleKeyDown = (e) => {
    if (e.keyCode === 9 || e.keyCode === 13) {
      this._handleUpdate();
    }
  };

  _handleOnClick = (e) => e.stopPropagation();

  _handleUpdate = () => {
    const { editListItem } = this.state;
    const { onEdit, id, onEditCancel } = this.props;
    if (editListItem && editListItem !== this.originalValue) {
      onEdit(id, editListItem);
    } else {
      onEditCancel();
    }
  };
}

SidebarEditListItem.propTypes = {
  id: PropTypes.number,
  intl: intlShape.isRequired,
  onEdit: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

SidebarEditListItem.defaultProps = {
  id: null,
};

export default injectIntl(SidebarEditListItem);
