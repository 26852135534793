import React, { Fragment } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import TabNav from 'components/tabNav';
import { memoizedGetTabsWithSubtabs } from 'web/shared/helpers/tabs';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';

const TabsEmailsTitle = ({ intl }) => (
  <Fragment>{intl.formatMessage({ id: 'common.emails' })}</Fragment>
);

TabsEmailsTitle.propTypes = {
  intl: intlShape.isRequired,
};

const TabsEmailsBody = ({
  intl,
  actionCreators: { onEmailsTabClick, onEmailsSubTabClick },
  selectedTabValue,
  selectedSubTabValue,
  unreadEmailsCounters,
}) => {
  const tabsLists = memoizedGetTabsWithSubtabs(
    CommandCenterEmailsTabsEnum,
    intl.formatMessage
  );

  const subTabHandler = (subTab) =>
    onEmailsSubTabClick(selectedTabValue, subTab);

  const TAB_WIDTH = 130;
  const SUB_TAB_WITH = 100;

  return (
    <div className="emails-nav">
      <TabNav
        itemWidth={TAB_WIDTH}
        list={tabsLists.tabs}
        onTabSelect={onEmailsTabClick}
        selectedTabValue={selectedTabValue}
        unreadEmailsCounters={unreadEmailsCounters}
      />
      {tabsLists.subTabs[selectedTabValue] && (
        <TabNav
          itemWidth={SUB_TAB_WITH}
          list={tabsLists.subTabs[selectedTabValue]}
          onTabSelect={subTabHandler}
          selectedTabValue={selectedSubTabValue}
          unreadEmailsCounters={unreadEmailsCounters}
        />
      )}
    </div>
  );
};

TabsEmailsBody.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  selectedSubTabValue: PropTypes.string.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
  unreadEmailsCounters: PropTypes.object.isRequired,
};

export default {
  Body: injectIntl(TabsEmailsBody),
  Title: injectIntl(TabsEmailsTitle),
};
