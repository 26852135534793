import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SyncTasksForm from 'web/salesforce/components/syncTasksForm';
import { SYNC_TASKS_FORM_ADMIN_ID } from 'web/salesforce/libs/salesforceConstants';

class SyncTasksCard extends Component {
  render() {
    const {
      actionCreators: { updateApiSyncSettings },
    } = this.props;

    return (
      <SyncTasksForm
        form={SYNC_TASKS_FORM_ADMIN_ID}
        onSubmit={updateApiSyncSettings}
        initialValues={this.syncTasksModel}
        enableReinitialize
      />
    );
  }

  get syncTasksModel() {
    const {
      isSyncTasksChecked,
      salesforceOptions: {
        api_settings: { skipped_task_action: skippedTasksValue = false },
      },
    } = this.props;

    return {
      skipped_task_action: isSyncTasksChecked ? skippedTasksValue : null,
      sync_tasks: isSyncTasksChecked,
    };
  }
}

SyncTasksCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  salesforceOptions: PropTypes.object.isRequired,
  isSyncTasksChecked: PropTypes.bool.isRequired,
};

export default SyncTasksCard;
