import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SafelyChangeRouteContainer from 'web/safelyChangeRoute/containers/safelyChangeRouteContainer';
import GlobalSettingsPage from '../globalSettingsPage';
import AutoAppendPopupContainer from '../../containers/autoAppendPopupContainer';
import EmailComposePopupContainer from 'web/emailComposeBody/emailComposePopupContainer'; // for ckeditor in auto append, should be refactored

const GlobalSettings = (props) => {
  const { loading, isSafeToChangeRoute } = props;

  return (
    <Fragment>
      <SafelyChangeRouteContainer isSafeToChangeRoute={isSafeToChangeRoute} />
      <GlobalSettingsPage {...props} />
      <AutoAppendPopupContainer loading={loading} />
      <EmailComposePopupContainer />
    </Fragment>
  );
};

GlobalSettings.propTypes = {
  isSafeToChangeRoute: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default GlobalSettings;
