import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Action from 'components/buttons/action';
import { actualizeGmailPluginInstalledState } from 'web/settings/emailPlugins/helpers/emailPluginsHelper';
import { isChromeBrowser } from 'web/services/chromeService';
import './gmailPluginCard.scss';

class GmailPluginCard extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const {
      actionCreators: { setGmailChromeExtensionInstalledState },
      gmailChromeExtensionInstalled,
    } = this.props;

    actualizeGmailPluginInstalledState(
      gmailChromeExtensionInstalled,
      setGmailChromeExtensionInstalledState
    );
  }

  render() {
    return (
      <div className="gmail-plugin-card">
        <div>
          <h3>
            <FormattedHTMLMessage id="web.emailPlugins.gmailCard.title" />
          </h3>
          <div className="card-description">
            <FormattedHTMLMessage id="web.emailPlugins.gmailCard.description" />
          </div>
          <div className="card-recommendation">
            <FormattedHTMLMessage id="web.emailPlugins.gmailCard.recommendation" />
          </div>
        </div>
        <div className="card-button-panel">{this.getActionButton()}</div>
      </div>
    );
  }

  getActionButton = () => {
    const {
      gmailChromeExtensionInstalled,
      actionCreators: { switchToEmailSyncTab, installGooglePlugin },
    } = this.props;

    return gmailChromeExtensionInstalled ? (
      <Action onClick={switchToEmailSyncTab}>
        <FormattedHTMLMessage id="common.configure" />
      </Action>
    ) : (
      <Action disabled={!isChromeBrowser} onClick={installGooglePlugin}>
        <FormattedHTMLMessage id="common.install" />
      </Action>
    );
  };
}

GmailPluginCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  gmailChromeExtensionInstalled: PropTypes.bool.isRequired,
};

GmailPluginCard.defaultProps = {};

export default GmailPluginCard;
