import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { paginatePeople } from 'web/people/actionCreators/peopleFetchActionCreators';
import { openPersonDetails } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import { setCurrentGroupId } from 'web/people/actionCreators/peopleGroupActionCreators';
import { getGroups } from 'web/groups/actionCreators/groupsActionCreators';
import { PEOPLE_GENERAL_GROUPS } from 'web/people/libs/peopleConstants';
import {
  resetTable,
  setTablePerPage,
  clearSelectedIds,
} from 'web/people/actionCreators/peopleTableActionCreators';
import isEmpty from 'lodash/isEmpty';
import { getPeopleTabSettings } from 'web/people/actionCreators/peopleTabSettingsActionCreators';
import { getSelectedGroup } from 'web/people/selectors/peopleSelectors';
import { tablePerPageFromStorage } from 'web/services/storageService';
import PersonDetailsActionTypes from 'web/person/libs/personDetailsActionTypes';
import { PersonDetailsTabs } from 'web/person/libs/personDetailsConstants';

export const peopleStartup = ({
  groupId = PEOPLE_GENERAL_GROUPS.everyone.id,
  personId,
  tab = PersonDetailsTabs.about,
}) => (dispatch, getState) => {
  dispatch(setCurrentGroupId(groupId));
  dispatch(commonStartup());
  dispatch(getPeopleTabSettings());

  const state = getState();
  let loadPage = 1;
  const { peopleGridData, peopleCurrentGroupId } = state;
  const forceLoadGroups = !getSelectedGroup(state);

  dispatch(setPerPageFromLocalStorage());
  dispatch(getGroups(forceLoadGroups));
  clearSelectedIds();

  if (isEmpty(peopleGridData) || peopleCurrentGroupId !== groupId) {
    dispatch(resetTable());
  } else {
    loadPage = 1;
  }
  if (personId) {
    dispatch(openPersonDetails(personId));
    dispatch({ tab, type: PersonDetailsActionTypes.setTab });

    if (isEmpty(peopleGridData)) {
      dispatch(paginatePeople(loadPage));
    }
  } else {
    dispatch(paginatePeople(loadPage));
  }
};

const setPerPageFromLocalStorage = () => (dispatch) => {
  const perPageFromStorage = tablePerPageFromStorage({ withDefault: false });
  if (perPageFromStorage) {
    dispatch(setTablePerPage(perPageFromStorage));
  }
};
