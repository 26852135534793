import DiagnosticsActionTypes from '../libs/diagnosticsActionTypes';
import {
  getActivities,
  retryActivity,
  removeActivity,
} from '../services/diagnosticsService';

export const getItems = () => (dispatch, getState) => {
  getActivities(getState().user.id)
    .then((items) => {
      dispatch({
        type: DiagnosticsActionTypes.getItems,
        items,
      });
    })
    .catch(() => {
      dispatch({
        type: DiagnosticsActionTypes.showAlert,
      });
    });
};

export const removeItems = (data, callback = () => {}) => (
  dispatch,
  getState
) => {
  const ids = Array.isArray(data) ? data : [data.id];
  removeActivity(getState().user.id, ids)
    .then(() => {
      dispatch({
        type: DiagnosticsActionTypes.removeItems,
        ids,
      });
      callback();
    })
    .catch(() => {
      dispatch({
        type: DiagnosticsActionTypes.showAlert,
      });
    });
};

export const retryItems = (data, callback = () => {}) => (
  dispatch,
  getState
) => {
  const ids = Array.isArray(data) ? data : [data.id];
  retryActivity(getState().user.id, ids)
    .then(() => {
      dispatch({
        type: DiagnosticsActionTypes.retryItems,
        ids,
      });
      callback();
    })
    .catch(() => {
      dispatch({
        type: DiagnosticsActionTypes.showAlert,
      });
    });
};

export const clearAlert = () => (dispatch) => {
  dispatch({
    type: DiagnosticsActionTypes.clearAlert,
  });
};
