import ActionTypes from 'table/modules/actionTypes';
import { SortingDirection } from 'table/modules/constants';
import trackerEvents from 'table/modules/tracker/trackerEvents';
import track from 'table/modules/tracker';

function getNextDirection(sameColumn, direction) {
  if (sameColumn) {
    return direction === SortingDirection.ASC
      ? SortingDirection.DESC
      : SortingDirection.ASC;
  } else {
    return SortingDirection.ASC;
  }
}

export function onSortClick(nextColumnId) {
  return (dispatch, getState) => {
    const {
      sortingColumn,
      sortingDirection,
      tableAllItems,
      tableColumns,
    } = getState();
    const sameColumn = nextColumnId === sortingColumn;
    const nextDirection = getNextDirection(sameColumn, sortingDirection);
    const externalSort =
      !tableAllItems && tableColumns.map[nextColumnId].sorting;

    dispatch({ type: ActionTypes.sortingSetColumn, id: nextColumnId });
    dispatch({
      type: ActionTypes.sortingSetDirection,
      direction: nextDirection,
    });
    if (externalSort) {
      tableColumns.map[nextColumnId].sorting(nextColumnId, nextDirection); // also pass place in pagination?
    }

    track(trackerEvents.columnSorted, {
      column: nextColumnId,
      direction: nextDirection,
      sameColumn,
    });
  };
}
