import { SubmissionError } from 'redux-form';
import {
  convertErrorsForReduxForm,
  defaultErrorMessage,
} from 'web/shared/helpers/parser';
import ForgotPasswordActionTypes from '../libs/forgotPasswordActionTypes';
import { sendResetPassword as sendResetPasswordCall } from '../services/forgotPasswordService';
import { track } from 'web/services/mixpanelService';
import {
  ResetPasswordEvents,
  ResetPasswordProperties,
  ResetPasswordPropertyTypes,
} from 'web/libs/mixpanelEvents';
import { getChangePaswordErrorText } from 'web/settings/myProfile/components/accountDetails/helpers/accountDetailsHelpers';

export const initializeForgotPasswordPanel = () => ({
  type: ForgotPasswordActionTypes.initialize,
});

export const setLoading = (loading = true) => ({
  type: ForgotPasswordActionTypes.setLoading,
  loading,
});

export const sendResetPasswordRequest = (email) => (dispatch) => {
  dispatch(setLoading());
  track(ResetPasswordEvents.resetPassword, {
    [ResetPasswordProperties.source]: ResetPasswordPropertyTypes.forgotPassword,
  });

  return sendResetPasswordCall(email)
    .then(() => {
      dispatch({
        type: ForgotPasswordActionTypes.requestSent,
        email,
      });
    })
    .catch((err = {}) => {
      let errorMessages = defaultErrorMessage;
      if (err.status === 422) {
        try {
          err.response.body = JSON.parse(err.response.text).errors;
          errorMessages = getChangePaswordErrorText(err);
        } catch (error) {
          errorMessages = defaultErrorMessage;
        }
      } else if (err.status === 401) {
        try {
          errorMessages = convertErrorsForReduxForm({ base: err.rawResponse });
        } catch (error) {
          errorMessages = defaultErrorMessage;
        }
      }

      throw new SubmissionError({ email: errorMessages });
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
