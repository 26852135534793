import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import './dropdown.scss';

export default (DropdownMenuTrigger) =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        bottom: null,
        left: null,
        showDropdown: false,
        stick: false,
        top: null,
      };
      this._dropdownRef = null;
      this._triggerRef = null;
    }

    render() {
      const { showDropdown, bottom, left, stick, top } = this.state;
      const { children, classNameDropdown } = this.props;
      const dropdownStyle = { bottom, left, stick, top };

      return (
        <div
          className={classNames('tout-dropdown', classNameDropdown)}
          onClick={this.toggleDropdown}
          onMouseDown={this._onMouseDown}
          ref={this._setDropdownRef}
        >
          <DropdownMenuTrigger
            {...this.props}
            active={showDropdown}
            ref={this._setTriggerRef}
          />
          <div className="tout-dropdown-content" style={dropdownStyle}>
            {showDropdown &&
              React.cloneElement(children, {
                bottom: dropdownStyle.bottom,
                stickToTop: dropdownStyle.stick,
              })}
          </div>
        </div>
      );
    }

    _setDropdownRef = (input) => (this._dropdownRef = input);

    _setTriggerRef = (input) => (this._triggerRef = input);

    _outsideClickHandler = (e) => {
      if (this.state.showDropdown && !this._dropdownRef.contains(e.target)) {
        e.stopPropagation();
        this.setState({ showDropdown: false });
      }
    };

    _closeDropdown = () => {
      if (this.state.showDropdown) {
        this.setState({ showDropdown: false });
      }
    };

    _scrollHandler = () => {
      if (this.state.showDropdown) {
        setTimeout(() => {
          this.setState({ showDropdown: false });
        }, 500);
      }
    };

    _addScrollListener = () => {
      if (this.props.scrollRef) {
        //eslint-disable-next-line react/no-find-dom-node
        const scrollNode = ReactDOM.findDOMNode(this.props.scrollRef);
        scrollNode.addEventListener('scroll', this._scrollHandler);
      }
    };

    _removeScrollListener = () => {
      if (this.props.scrollRef) {
        //eslint-disable-next-line react/no-find-dom-node
        const scrollNode = ReactDOM.findDOMNode(this.props.scrollRef);
        scrollNode.removeEventListener('scroll', this._scrollHandler);
      }
    };

    _calculatePosition = () => {
      const { childHeight } = this.props;
      /*eslint prefer-const: 0*/
      //eslint-disable-next-line react/no-find-dom-node
      let { bottom, left, top } = ReactDOM.findDOMNode(
        this._triggerRef
      ).getBoundingClientRect();
      let stick = false;
      if (window.innerHeight - bottom < childHeight / 2) {
        top = null;
        bottom = window.innerHeight - bottom;
        stick = false;
      } else {
        bottom = null;
        stick = top <= childHeight / 2;
        top -= top > childHeight / 2 ? childHeight / 2 : 0;
      }
      return { bottom, left, stick, top };
    };

    _addListeners = () => {
      this._addScrollListener();
      window.addEventListener('resize', this._closeDropdown, true);
      window.addEventListener('mousedown', this._outsideClickHandler, true);
    };

    _removeListeners = () => {
      this._removeScrollListener();
      window.removeEventListener('resize', this._closeDropdown, true);
      window.removeEventListener('mousedown', this._outsideClickHandler, true);
    };

    _onMouseDown(e) {
      e.stopPropagation();
    }

    toggleDropdown = (e) => {
      e.stopPropagation();
      let position = {};
      if (!this.state.showDropdown) {
        position = this._calculatePosition();
        this._addListeners();
      } else {
        this._removeListeners();
      }
      this.setState((prevState) => ({
        showDropdown: !prevState.showDropdown,
        ...position,
      }));
    };

    static propTypes = {
      childHeight: PropTypes.number.isRequired, // TODO - make this dynamic
      children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
      ]),
      classNameDropdown: PropTypes.string,
      scrollRef: PropTypes.object,
    };

    static defaultProps = {
      children: null,
      classNameDropdown: '',
      scrollRef: null,
    };
  };
