import React, { Component } from 'react';
import unauthorizedAdobePage from 'components/hocs/unauthorizedAdobePage';
import LoginSso from '../components/loginSso';
import { doLoginSso } from '../helpers/loginSsoHandler';

class LoginSsoContainer extends Component {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({ loading: true });
    return doLoginSso(values).finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <LoginSso
        loading={this.state.loading}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}

export default unauthorizedAdobePage(LoginSsoContainer, {
  cardWidth: 370,
  titleKey: 'common.login',
});
