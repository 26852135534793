import { createSelector } from 'reselect';

const getTasksTableDatesSmartFilter = (state) =>
  state.tablesTasksDatesSmartFilterValue;
const getTasksTableStatusSmartFilter = (state) =>
  state.tablesTasksStatusSmartFilterValue;
const getTasksTableSelectedDates = (state) => state.tablesTasksSelectedDates;
const getTasksTableLoading = (state) => state.tablesTasksLoading;
const getPropsTableId = (state, props) => props.tableId;
const getPropsLoading = (state, props) => props.loading;

export const getDatesSmartFilter = createSelector(
  [getTasksTableDatesSmartFilter, getPropsTableId],
  (datesFilter, id) => datesFilter.get(id) || ''
);

export const getStatusSmartFilter = createSelector(
  [getTasksTableStatusSmartFilter, getPropsTableId],
  (statusFilter, id) => statusFilter.get(id) || ''
);

export const getSelectedDates = createSelector(
  [getTasksTableSelectedDates, getPropsTableId],
  (dates, id) => (dates.has(id) ? dates.get(id).toJS() : {})
);

export const getLoading = createSelector(
  [getTasksTableLoading, getPropsLoading],
  (loading, propsLoading) => loading || propsLoading
);
