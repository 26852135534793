/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PersonDetailsSlideOutWidth } from '../libs/personDetailsSlideOutConstants';
import {
  closePersonDetails,
  clearPersonDetails,
} from '../actionCreators/personDetailsSlideOutActionCreators';
import SlideOutPane, {
  PaneBackgroundColors,
} from 'components/layouts/slideOutPane';
import PersonDetails from 'web/person/containers/personDetailsContainer';

class PersonDetailsSlideOutContainer extends Component {
  componentWillMount() {
    this.actionCreators = bindActionCreators(
      {
        closePersonDetails,
        clearPersonDetails,
      },
      this.props.dispatch
    );
  }

  componentWillUnmount() {
    this.actionCreators.clearPersonDetails();
  }

  render() {
    const { emailId, id } = this.props;

    return (
      <SlideOutPane
        backgroundColor={PaneBackgroundColors.white}
        className="person-details-slide-out"
        open={id > 0}
        width={PersonDetailsSlideOutWidth}
      >
        <PersonDetails
          emailId={emailId}
          id={id}
          onClose={this.actionCreators.closePersonDetails}
        />
      </SlideOutPane>
    );
  }
}

PersonDetailsSlideOutContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  emailId: PropTypes.number,
  id: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    id: state.personDetailsSlideOutPersonId,
    ...state.personDetailsSlideOutPersonOptions,
  };
}

export default connect(mapStateToProps)(PersonDetailsSlideOutContainer);
