import * as reducers from './commandCenterReducers';
import * as emailsReducers from './commandCenterEmailsReducers';
import * as commandCenterSidebarReducers from './commandCenterSidebarReducers';
import * as campaignSmartFilterReducers from 'web/commandCenter/modules/campaignSmartFilter/reducers/campaignSmartFilterReducers';
import * as peopleGroupSmartFilterReducers from 'web/commandCenter/modules/whoSmartFilter/reducers/peopleGroupSmartFilterReducers';

export default Object.assign(
  {},
  reducers,
  emailsReducers,
  commandCenterSidebarReducers,
  campaignSmartFilterReducers,
  peopleGroupSmartFilterReducers
);
