import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import GmailIntegrationContainer from 'web/emailIntegration/gmail/containers/gmailIntegrationContainer';
import './gmailIntegrationCard.scss';

// eslint-disable-next-line react/prefer-stateless-function
class GmailIntegrationCard extends Component {
  render() {
    return (
      <div className="gmail-sync-card">
        <div>
          <h3>
            <FormattedHTMLMessage id="web.emailSync.gmail.title" />
          </h3>
          <div className="card-description">
            <FormattedHTMLMessage id="web.emailSync.gmail.description" />
          </div>
        </div>
        <div>
          <GmailIntegrationContainer />
        </div>
      </div>
    );
  }
}

export default GmailIntegrationCard;
