import languages from 'languages';
import { CampaignState } from 'web/libs/constants';

export const GlobalPopupIds = {
  addPeople: 'CAMPAIGNS_TAB_ADD_PEOPLE',
  campaignActiveWarning: 'CAMPAIGNS_TAB_CAMPAIGN_ACTIVE_WARNING',
  campaignLaunched: 'CAMPAIGNS_TAB_CAMPAIGN_LAUNCHED',
  cloneCampaignConfirmation: 'CAMPAIGNS_TAB_CLONE_CONFIRMATION',
  confirmCampaignDeletion: 'CAMPAIGNS_TAB_COMFIRM_CAMPAIGN_DELETION',
  createNewCampaign: 'CAMPAIGNS_TAB_CREATE_NEW_CAMPAIGN',
  createNewCategory: 'CAMPAIGNS_TAB_CREATE_NEW_CATEGORY',
  deleteCampaignCategory: 'CAMPAIGNS_TAB_DELETE_CAMPAIGN_CATEGORY',
  dynamicFields: 'CAMPAIGNS_TAB_DYNAMIC_FIELDS',
  editLiveCampaignWarning: 'CAMPAIGNS_TAB_EDIT_LIVE_CAMPAIGN_WARNING',
  previewEmail: 'CAMPAIGNS_TAB_PREVIEW_EMAIL',
  previewTask: 'CAMPAIGNS_TAB_PREVIEW_TASK',
  removeConfirmation: 'CAMPAIGNS_TAB_REMOVE_CONFIRMATION',
  saveAsTemplate: 'CAMPAIGNS_TAB_SAVE_AS_TEMPLATE',
  scheduleLaunch: 'CAMPAIGNS_TAB_SCHEDULE_LAUNCH',
  scheduleLaunchPast: 'CAMPAIGNS_TAB_SCHEDULE_LAUNCH_PAST',
  shareCampaign: 'CAMPAIGNS_TAB_SHARE_CAMPAIGN',
  updateCampaignCategory: 'CAMPAIGNS_TAB_MOVE_CAMPAIGN_CATEGORY',
};

export const GlobalAlertIds = {
  addPeopleCallSuccess: 'CAMPAIGNS_TAB_ADD_PEOPLE_CALL_SUCCESS',
  campaignDayLimit: 'CAMPAIGNS_TAB_CAMPAIGN_DAY_LIMIT',
  campaignTemplateErrors: 'CAMPAIGNS_TABE_CAMPAIGN_TEMPLATE_ERRORS',
  campaignTemplateErrorsEmails:
    'CAMPAIGNS_TABE_CAMPAIGN_TEMPLATE_ERRORS_EMAILS',
  cloneCampaignError: 'CAMPAIGNS_TAB_CLONE_CAMPAIGN_ERROR',
  cloneCampaignSuccess: 'CAMPAIGNS_TAB_CLONE_CAMPAIGN_SUCCESS',
  createNewCampaign: 'CAMPAIGNS_TAB_CREATE_NEW_CAMPAIGN',
  createNewCampaignCategory: 'CAMPAIGNS_TAB_CREATE_NEW_CAMPAIGN_CATEGORY',
  createNewTemplate: 'CAMPAIGNS_TAB_CREATE_NEW_TEMPLATE',
  editLiveCampaignWarning: 'CAMPAIGNS_TAB_EDIT_LIVE_CAMPAIGN_WARNING',
  genericApiError: 'CAMPAIGNS_TAB_GENERIC_API_ISSUE',
  invalidPreviewDynamicFields: 'CAMPAIGNS_TAB_INVALID_PREVIEW_DYNAMIC_FIELDS',
  invalidPreviewInput: 'CAMPAIGNS_TAB_INVALID_PREVIEW_INPUT',
  invalidPreviewPromptFields: 'CAMPAIGNS_TAB_INVALID_PREVIEW_PROMPT_FIELDS',
  launchCampaignIssue: 'CAMPAIGNS_TAB_LAUNCH_CAMPAIGN_ISSUE',
  launchCampaignNoPeople: 'CAMPAIGNS_TAB_LAUNCH_CAMPAIGN_NO_PEOPLE',
  launchCampaignNoSteps: 'CAMPAIGNS_TAB_LAUNCH_CAMPAIGN_NO_STEPS',
  campaignNameUniqueAlert: 'CAMPAIGN_NAME_UNIQUE_ALERT',
  moveCampaignSuccess: 'CAMPAIGNS_TAB_MOVE_CAMPAIGN_SUCCESS',
  newDayNumberRequired: 'CAMPAIGNS_TAB_NEW_DAY_NUMBER_REQUIRED',
  removePeopleSuccess: 'CAMPAIGNS_TAB_REMOVE_PEOPLE_SUCCESS',
  removePeopleError: 'CAMPAIGNS_TAB_REMOVE_PEOPLE_ERROR',
  removePeopleFailed: 'CAMPAIGNS_TAB_REMOVE_PEOPLE_FAILED',
  renameCategoryError: 'CAMPAIGNS_TAB_CATEGORY_ERROR',
  settingsError: 'CAMPAIGNS_TAB_SETTINGS_ERROR',
  settingsSuccess: 'CAMPAIGNS_TAB_SETTINGS_SUCCESS',
  shareCampaignSuccess: 'CAMPAIGNS_TAB_SHARE_SUCCESS',
  shareCampaignError: 'CAMPAIGNS_TAB_SHARE_ERROR',
  unverifiedUser: 'CAMPAIGNS_TAB_UNVERIFIED_USER',
};

export const CampaignDetailsEnum = {
  analytics: {
    label: 'common.analytics',
    value: 'analytics',
  },
  people: {
    label: 'common.people',
    value: 'people',
  },
  setup: {
    label: 'common.setup',
    value: 'setup',
  },
  settings: {
    label: 'common.settings',
    value: 'settings',
  },
  tasks: {
    label: 'common.tasks',
    value: 'tasks',
  },
};

export const CampaignListFilters = {
  all: 'ALL',
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  mine: 'MINE',
  shared: 'SHARED',
  unshared: 'UNSHARED',
};

export const CampaignEmailStepTypes = {
  schedule: 'CAMPAIGN_EMAIL_STEP_SCHEDULE',
  previousTime: 'CAMPAIGN_EMAIL_STEP_PREVIOUS_TIME',
  manual: 'CAMPAIGN_EMAIL_STEP_MANUAL',
};

export const CampaignsTabErrors = {
  categoryName: 'Name has already been taken',
};

export const CampaignStateLabelMap = {
  [CampaignState.unstarted]: 'common.inactive',
  [CampaignState.inProgress]: 'common.running',
  [CampaignState.paused]: 'common.paused',
};

export const CampaignManagementActions = {
  move: 'Move',
  clone: 'Clone',
  share: 'Share',
  delete: 'Delete',
};

export const CampaignSearchDataTypes = {
  campaigns: {
    name: languages.getStr('common.campaigns'),
    type: 'CAMPAIGNS',
  },
  categories: {
    name: languages.getStr('common.categories'),
    type: 'CATEGORIES',
  },
};

export const CampaignsTasksTableId = 'campaigns-tasks-table';

export const CAMPAIGN_FIRST_DAY = 1;
export const CAMPAIGN_FIRST_ORDER = 0;

export const CampaignStepActions = {
  call: 'make_call',
  custom: 'custom_step',
  email: 'send_email',
  emailTask: 'email_reminder',
  inmail: 'send_inmail',
  none: 'none', // not valid on backend
};

export const CampaignsSpecialCategories = {
  all: {
    id: -1,
    name: 'All',
  },
};

export const CampaignsSpecialUsers = {
  all: {
    id: -1,
    name: languages.getStr('web.templates.allUsersFilterLabel'),
  },
};

export const CategoriesViewerTypes = {
  user: 'user',
  subscription: 'subscription',
};
