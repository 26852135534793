import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownIcon from 'components/dropdownIcon';
import DropdownMenu from 'components/dropdownMenu';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';
import './menuCell.scss';

const ROW_HEIGHT = 42;

class MenuCell extends Component {
  render() {
    const items = this.getDropdownMenuItems();
    const { className, style } = this.props;
    const containerClasses = classNames(className, 'cell-menu');

    return (
      <div className={containerClasses} style={style}>
        <DropdownIcon className="tout-ui-menu-dota" childHeight={ROW_HEIGHT}>
          <DropdownMenu items={items} />
        </DropdownIcon>
      </div>
    );
  }

  getDropdownMenuItems() {
    const { rowData } = this.props;
    let { menuItems } = this.props;

    menuItems = isFunction(menuItems) ? menuItems(rowData) : menuItems;

    return menuItems.map((menuItem) => ({
      label: menuItem.label,
      disabled: isFunction(menuItem.disabled)
        ? menuItem.disabled(rowData)
        : menuItem.disabled,
      action: () => menuItem.action(rowData),
    }));
  }
}

MenuCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  menuItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      })
    ),
    PropTypes.func,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

MenuCell.defaultProps = {
  className: '',
  style: {},
};

export default MenuCell;
