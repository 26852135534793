import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { CampaignSearchDataTypes } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import {
  navigateToCampaignDetailsTab,
  navigateToCampaignCategory,
  navigateToCampaignsTab,
} from 'web/services/routerService';

export const campaignsSidebarSearchResults = (data) => ({
  type: ActionTypes.data.sidebarSearchResults,
  data,
});

export const campaignsSidebarSearchResultsSelected = ({
  type = '',
  item = {},
}) => (dispatch) => {
  switch (type.toUpperCase()) {
    case CampaignSearchDataTypes.categories.type:
      navigateToCampaignCategory(item.id);
      break;
    case CampaignSearchDataTypes.campaigns.type:
      navigateToCampaignDetailsTab(item.category_id, item.id);
      break;
    default:
      navigateToCampaignsTab();
      break;
  }
  dispatch(campaignsSidebarSearchResults([]));
};
