import I18N from 'languages';
import { dateRangeSmartFilterID } from 'web/tables/dateRangeSmartFilter/libs/dateRangeSmartFilterConstants';
import { TablesFiltersConstants } from 'web/tables/libs/tablesFiltersConstants';

export const CommandCenterPopupIds = {
  advancedSearch: 'COMMAND_CENTER_ADVANCED_SEARCH',
  advancedSearchClone: 'COMMAND_CENTER_ADVANCED_SEARCH_CLONE',
  advancedSearchDelete: 'COMMAND_CENTER_ADVANCED_SEARCH_DELETE',
  advancedSearchRename: 'COMMAND_CENTER_ADVANCED_SEARCH_RENAME',
  advancedSearchSave: 'COMMAND_CENTER_ADVANCED_SEARCH_SAVE',
  emailDeleteConfirmation: 'COMMAND_CENTER_EMAIL_DELETE_CONFIRMATION',
  welcome: 'COMMAND_CENTER_POPUP_WELCOME',
  workflowEmailDeleteConfirmation:
    'COMMAND_CENTER_WORKFLOW_EMAIL_DELETE_CONFIRMATION',
};

export const EmailStates = {
  bounced: 'bounced',
  clicked: 'clicked',
  deliveryInProgress: 'delivery_in_progress',
  draft: 'draft',
  emailScheduled: 'email_scheduled',
  enqueuedForDelivery: 'enqueued_for_delivery',
  failedDelivery: 'failed_delivery',
  outOfOffice: 'out_of_office',
  replied: 'replied',
  scheduledForDelivery: 'scheduled_for_delivery',
  sent: 'sent',
  spam: 'spam',
  successful: 'successful',
  viewed: 'viewed',
};

/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
export const CommandCenterEmailsTabsEnum = {
  sent: {
    label: 'common.sent',
    value: 'sent',
    subTabs: {
      delivered: {
        label: 'common.delivered',
        value: 'delivered',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
      archived: {
        label: 'common.archived',
        value: 'archived',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
    },
  },
  pending: {
    label: 'common.pending',
    value: 'pending',
    subTabs: {
      scheduled: {
        label: 'common.scheduled',
        value: 'scheduled',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.nextSixMonths,
          },
        },
      },
      drafts: {
        label: 'common.drafts',
        value: 'drafts',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
      inprogress: {
        label: 'common.inProgress',
        value: 'inprogress',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.today,
          },
        },
      },
    },
  },
  undelivered: {
    label: 'common.undelivered',
    value: 'undelivered',
    subTabs: {
      failed: {
        label: 'common.failed',
        value: 'failed',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
      bounced: {
        label: 'common.bounced',
        value: 'bounced',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
      spam: {
        label: 'common.spam',
        value: 'spam',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
    },
  },
  search: {
    value: 'search',
    hidden: true,
    subTabs: {
      advanced: {
        value: 'advanced',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
      saved: {
        value: 'saved',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
      groupEmail: {
        value: 'groupEmail',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
      selectedEmail: {
        value: 'selectedEmail',
        smartFilters: {
          [dateRangeSmartFilterID]: {
            selectedValue: TablesFiltersConstants.lastSixMonths,
          },
        },
      },
    },
  },
};

export const EMAILS_TABLE_DATE_RANGES_KEY =
  'EMAIL_TABLE_DATE_RANGE_SMART_FILTER_KEY';

export const SidebarMenuItems = {
  tasks: {
    id: 0,
    name: I18N.getStr('common.tasks'),
    urlHash: 'tasks',
  },
  emails: {
    // sort-keys
    id: 1,
    name: I18N.getStr('common.emails'),
    urlHash: 'emails',
  },
};
/* eslint-enable  sort-keys, sort-keys-fix/sort-keys-fix */

export const SORT_DIRECTION = {
  ascending: 'asc',
  descending: 'desc',
};

export const initialEmailSortParams = {
  column: 'created_at',
  direction: 'desc',
};

export const COMMAND_CENTER_ALERT_LOCATION = {
  top: 'top',
};

export const PER_PAGE = 25;
export const DEFAULT_PAGE = 1;
export const CommandCenterTasksTableId = 'command-center-tasks-table';

export const EMAIL_FETCH_REQUEST_SEARCH_COLUMNS = [
  'name',
  'email',
  'subject',
  'workflow_name',
  'pitch_template.name',
  'person.company',
];

export const DATE_COLUMNS = [
  'last_updated',
  'created_at',
  'delivered_at',
  'last_event_at',
  'send_at',
];

export const PitchTrackingEvents = {
  click: 'click',
  open: 'open',
  reply: 'reply',
  success: 'success',
};

export const STATUS_REQUEST_MAP = {
  [CommandCenterEmailsTabsEnum.pending.subTabs.drafts.value]: EmailStates.draft,
  [CommandCenterEmailsTabsEnum.pending.subTabs.inprogress.value]:
    EmailStates.deliveryInProgress,
  [CommandCenterEmailsTabsEnum.pending.value]: EmailStates.enqueuedForDelivery,
  [CommandCenterEmailsTabsEnum.pending.subTabs.scheduled.value]:
    EmailStates.scheduledForDelivery,
  [CommandCenterEmailsTabsEnum.undelivered.subTabs.bounced.value]:
    EmailStates.bounced,
  [CommandCenterEmailsTabsEnum.undelivered.subTabs.spam.value]:
    EmailStates.spam,
  [CommandCenterEmailsTabsEnum.undelivered.subTabs.failed.value]:
    EmailStates.failedDelivery,
};

export const COUNTERS_TABS_MAP = {
  ...STATUS_REQUEST_MAP,
  [CommandCenterEmailsTabsEnum.sent.subTabs.delivered.value]:
    CommandCenterEmailsTabsEnum.sent.subTabs.delivered.value,
};

export const ROW_HEIGHT = 70;
export const ARCHIVE_ACTION = 'archive';
export const UNARCHIVE_ACTION = 'unarchive';
