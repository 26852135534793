import { createSelector } from 'reselect';
import {
  getFetching as getInvitationsFetching,
  getFetched as getInvitationsFetched,
} from 'web/invitations/selectors/invitationsSelectors';
import { getLoading as getTeamsLoading } from 'web/settings/adminSettings/teamManagement/selectors/teamsSelector';

export const getResendInviteLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.invitees.resendInviteLoading;

export const getResendInvitesLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.invitees.resendInvitesLoading;

export const getWithdrawInviteLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.invitees.withdrawInviteLoading;

export const getWithdrawInvitesLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.invitees
    .withdrawInvitesLoading;

export const getSendInvitesLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.invitees.sendInvitesLoading;

export const getWorkspaces = (state) => state.marketoAdminWorkspaces;

export const getWorkspacesLoading = (state) =>
  state.ui.settings.adminSettings.userManagement.invitees
    .marketoWorkspacesLoading;

export const getFetching = createSelector(
  [getInvitationsFetching, getInvitationsFetched],
  (invitationsFetching, invitationsFetched) =>
    invitationsFetching || !invitationsFetched
);

export const getLoading = createSelector(
  [getResendInviteLoading, getResendInvitesLoading],
  (resendInviteLoading, resendInvitesLoading) =>
    resendInviteLoading || resendInvitesLoading
);

export const getWorkspacesSelectOptions = createSelector(
  [getWorkspaces],
  (workspaces) =>
    workspaces.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }))
);

export const getInviteUsersPopupLoading = createSelector(
  [getSendInvitesLoading, getWorkspacesLoading, getTeamsLoading],
  (sendInvitesLoading, workspacesLoading, teamsLoading) =>
    sendInvitesLoading || workspacesLoading || teamsLoading
);
