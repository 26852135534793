import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Popup, { PopupButtonColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';

const DisconnectConfirmationPopup = (props) => {
  const {
    actionCreators: { closePopup, disconnectSalesforce },
  } = props;

  return (
    <Popup
      footer={{
        onPrimary: () => {
          disconnectSalesforce();
        },
        onSecondary: closePopup,
        primaryTextId: 'common.disconnect',
        secondaryTextId: 'common.cancel',
        primaryColor: PopupButtonColors.red,
      }}
      header={{
        onClose: closePopup,
        textId: 'web.salesforce.connectionCard.disconnectConfirmation.title',
      }}
      onOutsideClick={closePopup}
      size={PopupSize.small}
    >
      <FormattedMessage id="web.salesforce.connectionCard.disconnectConfirmation.text" />
    </Popup>
  );
};

DisconnectConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

export default DisconnectConfirmationPopup;
