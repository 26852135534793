import { createSelector } from 'reselect';
import {
  AddressableTypes,
  AlertIds,
  LabelErrorIds,
  COMPOSE_EDITOR_ID,
  PitchStates,
} from 'web/composeWindow/libs/composeWindowConstants';
import {
  addressIsUndeliverable,
  formatCcBcc,
} from 'web/composeWindow/helpers/composeWindowHelpers';
import { getPersonName, getFullName } from 'web/person/helpers/personHelpers';
import { RecommendedCategory } from 'web/composeWindow/libs/composeTemplatesConstants';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';

export const getToAddresses = (state) => state.composeAddresses.to;
export const getCcAddresses = (state) => state.composeAddresses.cc;
export const getBccAddresses = (state) => state.composeAddresses.bcc;
export const getIdentities = (state) => state.composeUserInfo.identities;
export const getIdentity = (state) => state.composeFrom.identity;
export const getSubject = (state) => state.composeSubject.subject;
export const getBody = (state) => state.composeEditorContent;
export const getSendAt = (state) => state.pitchSendAt;
export const getPitchTemplateId = (state) =>
  state.selectedTemplate ? state.selectedTemplate.id : null;
export const getPitchState = (state) => state.composePitch.pitchState;
export const getPitchId = (state) => state.composePitch.id;
export const userRequestedSend = (state) => state.composePitch.send;
export const retryPostCreate = (state) => state.composePitch.save;
export const isComposeWindowClosed = (state) =>
  !state.composeWindowState.opened;
export const recentlyUsedTemplates = (state) => state.recentlyUsedTemplates;
const isGroupMode = (state) => !!state.composeBulkEmails.group;

export const getAddresses = createSelector(
  [getToAddresses, getCcAddresses, getBccAddresses],
  (to, cc, bcc) => [...to, ...cc, ...bcc]
);

export const alreadySaving = (state) =>
  getPitchState(state) === PitchStates.saving;
export const currentlyCreatingPitch = (state) =>
  getPitchState(state) === PitchStates.creating;
export const alreadySending = (state) =>
  getPitchState(state) === PitchStates.sending;
export const alreadySent = (state) => getPitchState(state) === PitchStates.sent;

export const shouldSendPitch = (state) =>
  userRequestedSend(state) &&
  pitchIsSavable(state) &&
  !currentlyCreatingPitch(state);

export const shouldSavePitch = (state) =>
  isComposeWindowReady(state) && !userRequestedSend(state);

export const shouldCreatePitch = (state) =>
  !(currentlyCreatingPitch(state) || pitchIdExists(state));

export const pitchIdExists = (state) => Number.isInteger(getPitchId(state));

export const getValidationFailures = (state) => {
  const errors = {
    bcc: [],
    body: [],
    cc: [],
    subject: [],
    to: [],
  };

  if (getPitchState(state) === PitchStates.errored) {
    if (getBody(state).length === 0) {
      errors.body.push(LabelErrorIds.error);
    }

    if (getSubject(state).length === 0) {
      errors.subject.push(LabelErrorIds.error);
    }

    if (getToAddresses(state).length === 0) {
      errors.to.push(LabelErrorIds.error);
    }

    if (getBccAddresses(state).some((address) => address.invalid)) {
      errors.bcc.push(LabelErrorIds.error);
    }

    if (
      getBccAddresses(state).some((address) => addressIsUndeliverable(address))
    ) {
      errors.bcc.push(LabelErrorIds.warning);
    }

    if (getCcAddresses(state).some((address) => address.invalid)) {
      errors.cc.push(LabelErrorIds.error);
    }

    if (
      getCcAddresses(state).some((address) => addressIsUndeliverable(address))
    ) {
      errors.cc.push(LabelErrorIds.warning);
    }

    if (getToAddresses(state).some((address) => address.invalid)) {
      errors.to.push(LabelErrorIds.error);
    }

    if (
      getToAddresses(state).some((address) => addressIsUndeliverable(address))
    ) {
      errors.to.push(LabelErrorIds.warning);
    }
  }

  return errors;
};

const addressesChecked = (addresses) => {
  return addresses.every((address) => address.validated);
};

const addressesValid = (addresses) =>
  addresses.every(
    (address) => !(address.invalid || addressIsUndeliverable(address))
  );

export const getPitchAlerts = (state) => {
  const errors = [];

  if (emailFormatError(state)) {
    errors.push({ id: AlertIds.singleFlowInvalidEmail });
  }

  if (
    (salesUnsubscribeError(state) || marketingUnsubscribeError(state)) &&
    !getTemplateBypassUnsubscribe(state)
  ) {
    errors.push({ id: AlertIds.singleFlowUnsubscribes });
  }

  if (domainBlockError(state)) {
    errors.push({ id: AlertIds.singleFlowDomainBlock });
  }

  if (getSubject(state).length === 0) {
    errors.push({ id: AlertIds.missingFieldSubject });
  }

  if (getBody(state).length === 0) {
    errors.push({ id: AlertIds.missingFieldBody });
  }

  if (getToAddresses(state).length === 0) {
    errors.push({ id: AlertIds.missingFieldRecipient });
  }

  if (
    getBody(state).match(/{{!\s*\S.*}}/) ||
    getSubject(state).match(/{{!\s*\S.*}}/)
  ) {
    errors.push({ id: AlertIds.unfilledPromptError });
  }

  return errors;
};

export const isComposeWindowReady = (state) =>
  pitchIsSavable(state) && getPitchAlerts(state).length === 0;

export const pitchIsSavable = createSelector(
  [getIdentity, getSubject, getBody, getToAddresses, isGroupMode],
  (identity, subject, body, to, isGroupMode) =>
    !!to[0] &&
    (!!to[0].email || isGroupMode) &&
    !!body.length &&
    !!subject.length &&
    !!Number.isInteger(identity.id)
);

const emailFormatError = createSelector([getAddresses], (addresses) =>
  addresses.some((address) => address.invalid)
);

const salesUnsubscribeError = createSelector([getAddresses], (addresses) =>
  addresses.some((address) => address.salesUnsubscribe)
);

const marketingUnsubscribeError = createSelector([getAddresses], (addresses) =>
  addresses.some((address) => address.marketingUnsubscribe)
);

const domainBlockError = createSelector([getAddresses], (addresses) =>
  addresses.some((address) => address.domainBlocked)
);

export const getDefaultIdentity = createSelector(
  [getIdentities],
  (identities) => identities.find((identity) => identity.default_identity)
);

export const getIdentityId = createSelector(
  [getIdentity],
  (identity) => identity.id
);

export const getOneOffEmail = createSelector([getToAddresses], (to) => {
  const singleAddress = to[0];
  if (singleAddress) {
    if (singleAddress.type === AddressableTypes.group) {
      return singleAddress.address && singleAddress.address.address;
    } else {
      return singleAddress.email;
    }
  } else {
    return null;
  }
});

export const getOneOffName = createSelector([getToAddresses], (to) => {
  const singleAddress = to[0];
  if (singleAddress && singleAddress.person) {
    const {
      person: { first_name: firstName, last_name: lastName },
    } = singleAddress;

    return getFullName(firstName, lastName) || null;
  } else {
    return null;
  }
});

export const getOneOffPersonId = createSelector([getToAddresses], (to) => {
  const singleAddress = to[0];
  if (singleAddress && singleAddress.type === 'Person') {
    return singleAddress.addressableId;
  } else {
    return null;
  }
});

export const getOneOffAddressId = createSelector([getToAddresses], (to) => {
  const singleAddress = to[0];
  if (singleAddress) {
    return singleAddress.addressId;
  } else {
    return null;
  }
});

export const getOneOffGroup = createSelector([getToAddresses], (to) => {
  const singleAddress = to[0];
  if (singleAddress && singleAddress.type === AddressableTypes.group) {
    return singleAddress;
  } else {
    return null;
  }
});

export const getOneOffGroupId = createSelector([getToAddresses], (to) => {
  const singleAddress = to[0];
  if (singleAddress && singleAddress.type === AddressableTypes.group) {
    return singleAddress.addressableId;
  } else {
    return null;
  }
});

export const getCcEmails = createSelector([getCcAddresses], (cc) =>
  formatCcBcc(cc)
);

export const getBccEmails = createSelector([getBccAddresses], (bcc) =>
  formatCcBcc(bcc)
);

export const isScheduled = (state) => !!getSendAt(state);

export const buildMasterEmail = (state) => {
  const {
    composeEmailAttachments,
    composeAddresses: { bcc, cc },
    composeFrom: { identity },
    composeSubject: { subject },
    composeEditorContent,
    pitchSendAt,
    selectedTemplate,
  } = state;

  return {
    attachmentIds: composeEmailAttachments.map((attachment) => attachment.id),
    bcc,
    body: composeEditorContent,
    cc,
    identity,
    sendAt: pitchSendAt,
    subject,
    templateId: selectedTemplate && selectedTemplate.id,
  };
};

export const getComposeAttachments = (state) => state.composeEmailAttachments;

export const getComposeAttachmentIds = (state) =>
  getComposeAttachments(state).map(({ id }) => id);

export const getLiquifyProgress = (state) => {
  const {
    liquifyProgress: { current, maximum },
  } = state;
  return Math.ceil((current / maximum) * 100);
};

export const isLiquifyComplete = (state) => {
  const {
    liquifyProgress: { current, maximum },
  } = state;
  return current === maximum;
};

const shouldBulkGroupAddBeBlocked = (state) =>
  state.composeBulkEmails.recipients.length > 0;
const getBulkSearchResults = (state) => state.composeBulkEmails.searchResults;

export const getFilteredBulkSearchResults = createSelector(
  [shouldBulkGroupAddBeBlocked, getBulkSearchResults],
  (disableGroups, searchResults) => {
    if (disableGroups) {
      return searchResults.filter(
        (result) => result.es_document_type !== ElasticSearchTypes.groups
      );
    } else {
      return searchResults;
    }
  }
);

export const getEditorInstances = (state) => state.emailComposeEditors;

export const getPopupEditorId = ({ popupData: { editorId = null } }) =>
  editorId;

export const getPopupEditorInstance = (state) => {
  const editorId = getPopupEditorId(state);
  if (!editorId) return null;

  if (editorId === COMPOSE_EDITOR_ID) {
    return state.composeWindowState.editorInstance;
  } else {
    return getEditorInstances(state)[editorId];
  }
};

export const getAboutPersonDetails = (state) => {
  const { personId } = state.composePersonDetails;

  if (personId) {
    const address = getAddresses(state).find(
      (address) =>
        address.addressableId === personId ||
        (address.person && address.person.id === personId)
    );

    if (!address || !address.person) {
      return {};
    }

    const about = {};
    about.name = getPersonName(address.person);
    about.company = address.person.company;
    about.role = address.person.title;

    about.sfId =
      address.person.salesforce_lead_id || address.person.salesforce_contact_id;

    return about;
  } else {
    return {};
  }
};

export const getPersonData = (state) => ({
  about: state.composePersonDetails.about,
  events: state.composePersonDetails.events,
  personId: state.composePersonDetails.personId,
});

export const getSalesforceUrl = (state) =>
  state.personDetailsSalesforceConnectionData
    ? state.personDetailsSalesforceConnectionData.instanceUrl
    : '';

export const getSendLoading = (state) =>
  getPitchState(state) === PitchStates.sending || state.composePitch.send;

export const buildPitchData = (state) => {
  const {
    composeBulkEmails: { group, masterEmail },
  } = state;

  if (group) {
    return {
      attachment_id: getComposeAttachmentIds(state),
      pitch: {
        auto_close_task: true,
        bcc: formatCcBcc(masterEmail.bcc),
        body: masterEmail.body,
        cc: formatCcBcc(masterEmail.cc),
        email: `#${group.name}` || '#Group',
        group_id: group.id,
        id: state.composePitch.id,
        identity_id: getIdentityId(state),
        name: group.name,
        pitch_template_id: getPitchTemplateId(state),
        send_at: getSendAt(state),
        subject: masterEmail.subject,
      },
      schedule: isScheduled(state),
    };
  } else {
    return {
      attachment_id: getComposeAttachmentIds(state),
      pitch: {
        address_id: getOneOffAddressId(state),
        auto_close_task: true,
        bcc: getBccEmails(state),
        body: getBody(state),
        cc: getCcEmails(state),
        email: getOneOffEmail(state),
        group_id: getOneOffGroupId(state),
        id: state.composePitch.id,
        identity_id: getIdentityId(state),
        name: getOneOffName(state),
        person_id: getOneOffPersonId(state),
        pitch_template_id: getPitchTemplateId(state),
        reminder_id: state.composePitch.reminderId,
        send_at: getSendAt(state),
        subject: getSubject(state),
      },
      schedule: isScheduled(state),
      workflow_id: state.composePitch.workflowId,
    };
  }
};

export const isCcBccValid = createSelector(
  [getCcAddresses, getBccAddresses],
  (cc, bcc) =>
    addressesChecked(cc) &&
    addressesChecked(bcc) &&
    addressesValid(cc) &&
    addressesValid(bcc)
);

export const shouldBulkToggleBeDisabled = (state) => {
  const {
    composeBulkEmails: { group, recipients },
  } = state;
  return recipients.length > 1 || !!group;
};

export const getPinnedCategories = (state) => {
  const {
    composeTemplatesHasRecommended,
    composeTemplatesPinnedCategories,
  } = state;

  let pinnedCategoriesWithRecommended = composeTemplatesPinnedCategories;
  if (composeTemplatesHasRecommended) {
    pinnedCategoriesWithRecommended = [
      {
        ...RecommendedCategory,
        hideTitle: true,
        iconTooltip: {
          multiline: true,
          place: RecommendedCategory.place,
          textId: RecommendedCategory.textId,
          tooltipId: RecommendedCategory.tooltipId,
        },
      },
      ...composeTemplatesPinnedCategories,
    ];
  }

  return pinnedCategoriesWithRecommended;
};

export const getTemplateCategories = (state) => {
  const { composeTemplatesCategories, composeTemplatesHasRecommended } = state;

  let categoriesWithRecommended = composeTemplatesCategories;
  if (composeTemplatesHasRecommended) {
    categoriesWithRecommended = [
      RecommendedCategory,
      ...composeTemplatesCategories,
    ];
  }

  return categoriesWithRecommended;
};

export const hasRecentlyUsedTemplates = createSelector(
  [recentlyUsedTemplates],
  (templates) => templates && !!templates.length
);

export const isDirtyChangesForSelectedTemplate = (state) => {
  const {
    selectedTemplate,
    composeWindowState: { editorInstance },
    composeSubject: { subject },
  } = state;
  return (
    selectedTemplate &&
    (editorInstance.isDirty() || subject !== selectedTemplate.subject)
  );
};

export const getTemplateBypassUnsubscribe = (state) => {
  if (state.selectedTemplate && state.selectedTemplate.bypass_unsubscribe) {
    return true;
  } else {
    return false;
  }
};
