import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
if (window.Backbone && window.Backbone.history) {
  history.listen(() => {
    setTimeout(window.Backbone.history.checkUrl, 0);
  });
}

export default function ToutRouter(props) {
  return <Router history={history}>{props.children}</Router>;
}

ToutRouter.propTypes = {
  children: PropTypes.element.isRequired,
};
