import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import {
  TEMPLATES_VIEWER_TYPES,
  SPECIAL_FILTER_CATEGORIES,
} from 'web/templates/libs/templatesConstants';
import { getCategoriesForViewer } from 'web/templates/actionCreators/templateCategoriesActionCreators';
import { getTemplates } from './templatesFetchActionCreators';
import { navigateToTemplateCategory } from 'web/services/routerService';
import { resetTable } from 'web/templates/actionCreators/templatesTableActionCreators';

export const getViewer = ({ id, name } = {}) => {
  const { user, subscription } = TEMPLATES_VIEWER_TYPES;
  const type = id === SPECIAL_FILTER_CATEGORIES.ALL_USERS ? subscription : user;
  return { id, name, type };
};

export const setSidebarFilterInput = (inputValue = '') => ({
  inputValue,
  type: TemplatesActionTypes.sidebarFilterInput.set,
});

export const setTemplatesViewer = (selectedViewer = {}) => (dispatch) => {
  const { name } = selectedViewer;
  const viewer = getViewer(selectedViewer);

  dispatch(setSidebarFilterInput(name));
  dispatch({ type: TemplatesActionTypes.viewer.set, viewer });
};

export const getTemplatesForViewer = () => (dispatch) => {
  dispatch(getCategoriesForViewer());
  navigateToTemplateCategory();
  dispatch(getTemplates());
};

export const onSidebarFilterSelect = ({ label: name, value: id } = {}) => (
  dispatch
) => {
  dispatch(resetTable());
  dispatch(setTemplatesViewer({ id, name }));
  dispatch(getTemplatesForViewer());
};
