import React from 'react';
import PropTypes from 'prop-types';
import PeopleHeader from './peopleHeader';
import PeopleList from './peopleList';
import './peopleSimpleGrid.scss';

const PeopleSimpleGrid = ({ people, status, additionalRow }) => (
  <div className="people-simple-grid">
    <PeopleHeader />
    <PeopleList people={people} status={status} additionalRow={additionalRow} />
  </div>
);

PeopleSimpleGrid.propTypes = {
  people: PropTypes.array,
  status: PropTypes.func.isRequired,
  additionalRow: PropTypes.element,
};

PeopleSimpleGrid.defaultProps = {
  additionalRow: null,
  people: [],
};

export default PeopleSimpleGrid;
