import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import './cloneCampaignConfirmation.scss';

const CloneCampaignConfirmation = ({ intl: { formatMessage } }) => (
  <div className="clone-campaign-confirmation text-white">
    <h3 className="text-medium margin-bottom-full text-white">
      {formatMessage({ id: 'web.campaigns.cloneModalTitle' })}
    </h3>
    <div>{formatMessage({ id: 'web.campaigns.cloneModalBody' })}</div>
  </div>
);

CloneCampaignConfirmation.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CloneCampaignConfirmation);
