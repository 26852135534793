import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ExchangeConnectionForm from 'web/settings/emailConnection/components/exchangeConnectionForm';
import './exchangeOnpremConnected.scss';

export default class ExchangeOnpremConnected extends Component {
  render() {
    return (
      <div className="exchange-onprem-connected">
        <h5 className="exchange-onprem-connected-header">
          <FormattedMessage id="web.settings.emailConnection.onprem.card.header" />
        </h5>
        <ExchangeConnectionForm
          onpremForm={this.mapAttributesForForm()}
          disabled
        />
      </div>
    );
  }

  mapAttributesForForm = () => {
    const {
      addedAuthenticationAttributes: { username, token, location },
      url,
    } = this.props;
    const formAttributes = {};

    formAttributes.email = username;
    formAttributes.password = token;
    formAttributes.version = location;
    formAttributes.url = url;

    return formAttributes;
  };

  static propTypes = {
    addedAuthenticationAttributes: PropTypes.object.isRequired,
    url: PropTypes.string,
  };

  static defaultProps = {
    url: '',
  };
}
