import { Urls } from 'web/libs/routes';
import { baseGet, basePost, baseDelete } from 'web/services/api';

export const getTeamMembers = (teamId) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.teamsTeamMembers(teamId), {}, resolve, reject);
  });

export const removeTeamMembers = (teamId, userIds) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.teamsRemoveTeamMembers(teamId),
      { user_ids: userIds },
      resolve,
      reject
    );
  });

export const deleteUser = (userId) =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.users(userId), {}, resolve, reject);
  });

export const batchDestroyUsers = (userIds) =>
  new Promise((resolve, reject) => {
    basePost(Urls.usersBatchDestroy, { user_ids: userIds }, resolve, reject);
  });

export const updateTeams = (userId, teamIds) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.usersUpdateTeams(userId),
      { team_ids: teamIds },
      resolve,
      reject
    );
  });

export const teamsAddTeamMembers = (userIds, teamIds) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.teamsAddTeamMembers,
      { user_ids: userIds, team_ids: teamIds },
      resolve,
      reject
    );
  });
