import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import PropTypes from 'prop-types';
import { navigateToUrl } from 'web/services/routerService';
import CustomizationGuideLinks from './customizationGuideLinks';

class SalesforceNotConnected extends Component {
  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const buttonText = formatMessage({
      id: 'web.salesforceNotConnected.connect.title',
    });

    return (
      <div className="salesforce-not-connected">
        <p>
          <FormattedMessage id="web.salesforceCustomization.connect.prelude.robustIntegration" />
        </p>
        <CustomizationGuideLinks />
        <Button
          color={ButtonColors.blue}
          size={ButtonSizes.standard}
          onClick={this.handleClick}
          title={buttonText}
        >
          {buttonText}
        </Button>
      </div>
    );
  }

  handleClick = () => {
    navigateToUrl(this.props.connectUrl);
  };
}

SalesforceNotConnected.propTypes = {
  connectUrl: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceNotConnected);
