import find from 'lodash/find';
import get from 'lodash/get';
import { HIGHSPOT_VALUE } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import { Regex } from 'web/libs/constants';
import { TableProperties } from 'web/settings/adminSettings/general/libs/domainBlocksConstants';
import { LOGIN_METHODS } from 'web/settings/adminSettings/general/libs/generalPageConstants';

export const isHighSpotInContentPartners = (contentPartners = {}) => {
  const { partners } = contentPartners;
  return !!find(partners, { name: HIGHSPOT_VALUE });
};

export const getBoxIntegrationEnabledFlag = (subscription) =>
  get(
    subscription,
    'admin_settings.box_integration_enabled',
    false
  ).toString() === 'true'; //could be string or boolean

export const getSlackIntegrationEnabledFlag = (subscription) =>
  get(
    subscription,
    'admin_settings.slack_integration_enabled',
    false
  ).toString() === 'true'; //could be string or boolean

export const getTemplatesEditingDisabledFlag = (subscription) =>
  get(
    subscription,
    'admin_settings.disable_templates_editing',
    false
  ).toString() === 'true'; //could be string or boolean

export const getLoginMethod = (subscription) =>
  get(subscription, 'admin_settings.login_methods', LOGIN_METHODS.all);

export const getCampaignsEditingDisabledFlag = (subscription) =>
  get(
    subscription,
    'admin_settings.disable_campaigns_editing',
    false
  ).toString() === 'true'; //could be string or boolean

export const getCallRecordingFlag = (subscription) =>
  get(
    subscription,
    'admin_settings.call_recording_enabled',
    false
  ).toString() === 'true'; //could be string or boolean

export const getForceCallRecordingFlag = (subscription) =>
  get(subscription, 'admin_settings.force_call_recording', false).toString() ===
  'true'; //could be string or boolean

export const getCallRecordingNoticeEnabledFlag = (subscription) =>
  get(
    subscription,
    'admin_settings.call_recording_notice_enabled',
    false
  ).toString() === 'true'; //could be string or boolean

export const getContentPartnersFlag = (subscription) =>
  get(subscription, 'admin_content_partners_enabled', false);

export const getSubscriptionEmailLimit = (subscription) =>
  get(subscription, 'email_limit');

export const getSubscriptionCampaignLimit = (subscription) =>
  get(subscription, 'campaign_limit');

export const getPluginUnsubscribeFlag = (subscription) =>
  get(subscription, 'features.plugin_unsubscribe_enabled', false);

export const validateDomainBlock = (domainName) => {
  const splittedDomainNames = domainName.split('.');
  if (splittedDomainNames.find((ele) => Regex.specialChars.test(ele))) {
    return { msg: TableProperties.domainErrorSpecialChars };
  } else if (Regex.domainBlock.test(domainName)) {
    return true;
  } else {
    return { msg: TableProperties.domainError };
  }
};

export const getUnsubscribeCustomDomain = (subscription) =>
  get(subscription, 'features.unsubscribe_custom_domain', false);
