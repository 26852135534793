import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Label from 'components/label';
import Text from 'components/inputs/text';
import './infoCardEditingContact.scss';

class InfoCardEditingContact extends Component {
  constructor(props) {
    super(props);
    const { formatMessage } = this.props.intl;
    this.required = formatMessage({ id: 'common.required' });
  }

  render() {
    const {
      editState: { companyName, firstName, lastName, title },
      requiredFields,
      labelWidth,
    } = this.props;

    return (
      <div className="person-details-info-card-editing-contact">
        <div className="info-card-editing-contact-row margin-bottom-half">
          <Label
            classWrapper="margin-right-half"
            id="person-details-first-name-label"
            messageKey="common.firstName"
            width={labelWidth}
            sideLabel
          >
            <Text
              id="person-details-first-name-field"
              onChange={this.updateFirstName}
              placeholder=""
              type="text"
              value={firstName}
              autoFocus
            />
          </Label>
          <Label
            classWrapper="margin-left-half"
            id="person-details-last-name-label"
            messageKey="common.lastName"
            width={labelWidth}
            sideLabel
          >
            <Text
              autoFocus={false}
              id="person-details-last-name-field"
              onChange={this.updateLastName}
              placeholder={(requiredFields.lastName && this.required) || ''}
              type="text"
              value={lastName}
            />
          </Label>
        </div>
        <div className="info-card-editing-contact-row">
          <Label
            classWrapper="margin-right-half"
            id="person-details-title-label"
            messageKey="common.title"
            width={labelWidth}
            sideLabel
          >
            <Text
              autoFocus={false}
              id="person-details-title-field"
              onChange={this.updateTitle}
              placeholder=""
              type="text"
              value={title}
            />
          </Label>
          <Label
            classWrapper="margin-left-half"
            id="person-details-company-label"
            messageKey="common.company"
            width={labelWidth}
            sideLabel
          >
            <Text
              autoFocus={false}
              id="person-details-company-field"
              onChange={this.updateCompanyName}
              placeholder={(requiredFields.company && this.required) || ''}
              type="text"
              value={companyName}
            />
          </Label>
        </div>
      </div>
    );
  }

  updateFirstName = (firstName) => this.props.updateContact({ firstName });

  updateLastName = (lastName) => this.props.updateContact({ lastName });

  updateTitle = (title) => this.props.updateContact({ title });

  updateCompanyName = (companyName) =>
    this.props.updateContact({ companyName });
}

InfoCardEditingContact.propTypes = {
  editState: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  labelWidth: PropTypes.number.isRequired,
  requiredFields: PropTypes.object.isRequired,
  updateContact: PropTypes.func.isRequired,
};

export default injectIntl(InfoCardEditingContact);
