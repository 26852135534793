import { PopupBackgroundColors } from 'components/popups';
import { EditTemplatePopupAlertIds } from '../libs/editTemplateSlideOutConstants';

const getDefaultAlert = (clearAlert) => ({
  onClose: clearAlert,
  color: PopupBackgroundColors.red,
});

export function getAlert({ alert }, { clearAlert }) {
  switch (alert) {
    case EditTemplatePopupAlertIds.shareTemplate:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.editTemplateSlideOut.alert.shareTemplate',
      };
    case EditTemplatePopupAlertIds.cloneTemplate:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.editTemplateSlideOut.alert.cloneTemplate',
      };
    case EditTemplatePopupAlertIds.createTemplate:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.editTemplateSlideOut.alert.createTemplate',
      };
    case EditTemplatePopupAlertIds.archivedCategoryMissing:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.editTemplateSlideOut.alert.archivedCategoryMissing',
      };
    case EditTemplatePopupAlertIds.archiveTemplate:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.editTemplateSlideOut.alert.archiveTemplate',
      };
    case EditTemplatePopupAlertIds.moveTemplate:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.editTemplateSlideOut.alert.moveTemplate',
      };
    case EditTemplatePopupAlertIds.deleteTemplate:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.editTemplateSlideOut.alert.deleteTemplate',
      };
    case EditTemplatePopupAlertIds.deleteNote:
      return {
        ...getDefaultAlert(clearAlert),
        textId: 'web.notes.deletePopup.alert',
      };
    default:
      return null;
  }
}
