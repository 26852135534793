import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  IntegrationsAlertIds,
  IntegrationsPopupIds,
} from '../libs/integrationConstants';
import { deleteBoxIntegration } from 'web/integrations/box/actionCreators/boxActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';

export const openDeleteBoxConfirmation = () => (dispatch) =>
  dispatch(openPopup(IntegrationsPopupIds.deleteBox));

export const openNavigateToBoxConfirmation = () => (dispatch) =>
  dispatch(openPopup(IntegrationsPopupIds.navigateToBox));

export const deleteIntegration = () => (dispatch) =>
  dispatch(deleteBoxIntegration())
    .then(() => {
      dispatch(openViewAlert(IntegrationsAlertIds.success));
    })
    .catch(() => {
      dispatch(openViewAlert(IntegrationsAlertIds.failure));
    });
