import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Select from 'components/selects/base';
import './identitiesSelect.scss';

class IdentitiesSelect extends PureComponent {
  render() {
    const {
      items,
      intl: { formatMessage },
      ...other
    } = this.props;

    return (
      <Select
        className="identities-select"
        items={this._parseIdentities(items, formatMessage)}
        {...other}
      />
    );
  }

  _parseIdentities(items, formatMessage) {
    return items.map(({ email, id, isDefault, name, unverified }) => ({
      label: formatMessage(
        { id: 'selects.identities.identityLabel' },
        { name, email, isDefault, unverified }
      ),
      value: id,
      disabled: unverified,
    }));
  }
}

IdentitiesSelect.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(IdentitiesSelect);
