import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './formButtonsArea.scss';

const FormButtonsArea = ({ children, className, align }) => {
  let alignClassName = '';
  switch (align) {
    case 'left':
      alignClassName = 'form-buttons-align-left';
      break;
    case 'right':
      alignClassName = 'form-buttons-align-right';
      break;
    case 'center':
      alignClassName = 'form-buttons-align-center';
      break;
    case 'spaceBetween':
      alignClassName = 'form-buttons-align-spaceBetween';
      break;
    default:
      alignClassName = 'form-buttons-align-right';
  }
  return (
    <div className={classNames('form-buttons-area', className, alignClassName)}>
      {children}
    </div>
  );
};

FormButtonsArea.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  align: PropTypes.string,
};

FormButtonsArea.defaultProps = {
  children: null,
  className: '',
  align: 'right',
};

export default FormButtonsArea;
