/* Searchable & Clearable are on by default */

import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import Pill from 'components/pill';
import './select2.scss';
import 'react-select/dist/react-select.min.css';

class Select2 extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
      noResultsText,
    } = this.props;
    this.noResultsTextIntl =
      noResultsText || formatMessage({ id: 'common.noResultsFound' });
  }

  render() {
    const {
      classes,
      className,
      multi,
      options,
      isCreatable,
      ...others
    } = this.props;
    const processedOptions = this._processOptions(options);
    const selectProps = {
      ...others,
      multi,
      noResultsText: this.noResultsTextIntl,
    };

    const SelectComponent = isCreatable ? Select.Creatable : Select;

    if (multi) {
      selectProps.valueComponent = this._multiValueComponent;
    }

    return (
      <div className={classNames('select2', className, classes)}>
        <SelectComponent options={processedOptions} {...selectProps} />
      </div>
    );
  }

  _processOptions(options) {
    let processed = options;
    if (typeof options[0] === 'string') {
      processed = options.map((option) => ({ label: option, value: option }));
    }
    return processed;
  }

  _onRemove({ onRemove, value }) {
    onRemove(value);
  }

  _multiValueComponent = (option = {}) => {
    const {
      value: { value, label, disabled, color },
    } = option;
    const onClose = disabled ? null : () => this._onRemove(option);
    return (
      <Pill
        color={color}
        id={value}
        onClose={onClose}
        text={label}
        insideInput
      />
    );
  };
}

Select2.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.string, // legacy
  intl: intlShape.isRequired,
  isCreatable: PropTypes.bool,
  multi: PropTypes.bool,
  noResultsText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]),
};

Select2.defaultProps = {
  className: '',
  classes: '',
  isCreatable: false,
  multi: false,
  noResultsText: '',
  value: '',
};

export default injectIntl(Select2);
