import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Text from 'components/buttons/text';
import './actionsCell.scss';

class ActionsCell extends Component {
  render() {
    const { actions, noActionsTextId, rowData } = this.props;

    return (
      <div className="cell-actions">
        {(noActionsTextId.length > 0 && (
          <span className="text-gray text-italic">
            <FormattedMessage id={noActionsTextId} />
          </span>
        )) || (
          <div className="cell-actions-row">
            {actions.map(({ label, isVisible }, index) => {
              let shouldDisplay = true;
              if (isVisible) {
                shouldDisplay = isVisible(rowData);
              }
              return (
                shouldDisplay && (
                  <div
                    className="cell-actions-action"
                    key={`actions-cell-${rowData.id}-${label}`}
                  >
                    <Text data-index={index} onClick={this.onClick}>
                      {label}
                    </Text>
                  </div>
                )
              );
            })}
          </div>
        )}
      </div>
    );
  }

  onClick = (e) => {
    const { actions, rowData } = this.props;
    e.stopPropagation();
    e.preventDefault();

    const index = parseInt(e.currentTarget.dataset.index, 10);
    actions[index].onClick(rowData);
  };
}

const ActionShape = PropTypes.shape({
  isVisible: PropTypes.func,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
});

ActionShape.defaultProps = {
  isVisible: null,
};

ActionsCell.propTypes = {
  actions: PropTypes.arrayOf(ActionShape),
  noActionsTextId: PropTypes.string,
  rowData: PropTypes.object.isRequired,
};

ActionsCell.defaultProps = {
  actions: [],
  noActionsTextId: '',
};

export default ActionsCell;
