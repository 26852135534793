import ActionTypes from 'owa/libs/actionTypes';

export function openAddToCompaignPopup() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.popup.addToCompaignOpen,
    });
  };
}

export function closeAddToCompaignPopup() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.popup.addToCompaignClose,
    });
  };
}
