import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFormSyncErrors, isDirty } from 'redux-form';
import { getContentPartners as getContentPartnersAC } from 'web/modals/addContent/actionCreators/contentActionCreators';
import { getTeamSettings as getTeamSettingsAC } from 'web/teamSettings/actionCreators/teamSettingsActionCreators';
import {
  shouldLoadTeamSettings as shouldLoadTeamSettingsSelector,
  teamEmailLimit,
  teamEmailLimitSource,
  teamCampaignLimit,
} from 'web/teamSettings/selectors/teamSettingsSelectors';
import {
  updateTeamEmailLimit as updateTeamEmailLimitAC,
  updateEmailThrottlingSettings as updateEmailThrottlingSettingsAC,
  getEmailThrottlingSettings as getEmailThrottlingSettingsAC,
} from 'web/settings/adminSettings/general/actionCreators/teamEmailLimitActionCreators';
import { showValidationErrorMessage as showValidationErrorMessageAC } from 'web/settings/adminSettings/general/actionCreators/generalActionCreators';
import GlobalSettings from 'web/settings/adminSettings/general/components/globalSettings';
import {
  updatePhoneSettings,
  openManageRecordingNotice,
  uploadFile,
} from 'web/settings/adminSettings/general/actionCreators/salesEnagePhoneActionCreators';
import {
  getDomainBlocks,
  postDomainBlock,
  destroyDomainBlock,
  setNewDomainBlock,
  hideErrorMessage,
  handleSearchDomainChange,
  clearNewDomainBlock,
} from 'web/settings/adminSettings/general/actionCreators/domainBlocksActionCreators';
import {
  updateContentPartners,
  updateBoxIntegrationEnabled,
  updateSlackIntegrationEnabled,
} from 'web/settings/adminSettings/general/actionCreators/contentPartnerActionCreators';
import {
  updateTemplatesEditingDisabled,
  updateCampaignsEditingDisabled,
} from 'web/settings/adminSettings/general/actionCreators/contentLockDownActionCreators';
import { updateLoginMethod } from 'web/settings/adminSettings/general/actionCreators/loginManagementActionCreators';
import {
  emailThrottlingLimits,
  isGeneralPageFetching,
  isHighSpotChecked,
  isHighSpotEnabled,
  isBoxIntegrationEnabled,
  isSlackIntegrationEnabled,
  isCallRecordingChecked,
  isCallRecordingNoticeChecked,
  isForceCallRecordingChecked,
  subscriptionEmailLimit,
  subscriptionCampaignLimit,
  isTemplatesEditingDisabled,
  isCampaignsEditingDisabled,
  loginMethod,
} from 'web/settings/adminSettings/general/selectors/generalPageSelectors';
import {
  getCustomAppendContent,
  isFreeOrSolo,
  isStandard,
  isAdminAutoAppendEnabled,
} from 'web/user/selectors/userSelectors';
import {
  TEAM_EMAIL_LIMIT_FORM_ID,
  TEAM_EMAIL_THROTTLING_LIMIT_FORM_ID,
  AUTO_APPEND_FORM_ID,
  TEAM_CAMPAIGN_LIMIT_FORM_ID,
  LOGIN_METHODS,
} from 'web/settings/adminSettings/general/libs/generalPageConstants';
import {
  updateCustomAppendContent,
  openAutoAppendConfirmationPopup,
} from 'web/settings/adminSettings/general/actionCreators/autoAppendActionCreators';
import {
  isSalesPhoneConsentAndRecordingEnabled,
  isMSIppSfSignInAndActivationEnabled,
} from 'web/user/selectors/subscriptionSelectors';
import { getActiveRecordingNotice } from 'web/modals/manageRecordingNotice/actionCreators/recordingNoticeActionCreators';
import { getDeliveryChannelOfDefaultIdentity } from 'web/deliveryChannels/selectors/deliveryChannelsSelectors';
import { getDeliveryChannels } from 'web/deliveryChannels/actionCreators/deliveryChannelsActionCreators';
import { getIdentities } from 'web/user/actionCreators/identitiesActionCreators';

class GlobalSettingsContainer extends Component {
  componentDidMount() {
    const {
      isContentPartnersLoaded,
      shouldLoadTeamSettings,
      actionCreators: {
        getContentPartners,
        getEmailThrottlingSettings,
        getTeamSettings,
        getDomainBlocks,
        getDeliveryChannels,
        getIdentities,
      },
    } = this.props;

    !isContentPartnersLoaded && getContentPartners();
    shouldLoadTeamSettings && getTeamSettings();
    getDomainBlocks();
    getDeliveryChannels();
    getIdentities();
    getEmailThrottlingSettings();
  }

  render() {
    const {
      teamCampaignLimitFormErrors,
      teamEmailLimitFormErrors,
      teamEmailThrottlingLimitFormErrors,
      autoAppendFormErrors,
      isMSIppSfSignInAndActivationEnabled,
    } = this.props;

    return (
      <GlobalSettings
        {...this.props}
        isMSIppSfSignInAndActivationEnabled={
          isMSIppSfSignInAndActivationEnabled
        }
        onAutoAppendFormError={this.onFormError(autoAppendFormErrors)}
        onTeamCampaignLimitFormError={this.onFormError(
          teamCampaignLimitFormErrors
        )}
        onTeamEmailLimitFormError={this.onFormError(teamEmailLimitFormErrors)}
        onTeamEmailThrottlingLimitFormError={this.onFormError(
          teamEmailThrottlingLimitFormErrors
        )}
      />
    );
  }

  onFormError = (errors) => () => {
    const {
      actionCreators: { showValidationErrorMessage },
    } = this.props;
    showValidationErrorMessage(errors);
  };
}

GlobalSettingsContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  customAppendContent: PropTypes.string,
  emailThrottlingLimits: PropTypes.object.isRequired,
  isAutoAppendEnabled: PropTypes.bool,
  isBoxIntegrationEnabled: PropTypes.bool.isRequired,
  isCallRecordingChecked: PropTypes.bool.isRequired,
  isCallRecordingNoticeChecked: PropTypes.bool.isRequired,
  isContentPartnersLoaded: PropTypes.bool.isRequired,
  isForceCallRecordingChecked: PropTypes.bool.isRequired,
  isHighSpotChecked: PropTypes.bool.isRequired,
  isHighSpotEnabled: PropTypes.bool.isRequired,
  isSafeToChangeRoute: PropTypes.bool.isRequired,
  isSalesEngagePhoneEnabled: PropTypes.bool.isRequired,
  isSlackIntegrationEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loginMethod: PropTypes.oneOf(Object.values(LOGIN_METHODS)).isRequired,
  shouldLoadTeamSettings: PropTypes.bool.isRequired,
  subscriptionCampaignLimit: PropTypes.number.isRequired,
  subscriptionEmailLimit: PropTypes.number.isRequired,
  teamCampaignLimit: PropTypes.string,
  teamEmailLimit: PropTypes.string,
  teamEmailLimitFormErrors: PropTypes.object,
  teamEmailThrottlingLimitFormErrors: PropTypes.object,
};

GlobalSettingsContainer.defaultProps = {
  customAppendContent: '',
  isAutoAppendEnabled: false,
  teamCampaignLimit: '',
  teamEmailLimit: '',
  teamEmailLimitFormErrors: {},
  teamEmailThrottlingLimitFormErrors: {},
};

const mapStateToProps = (state) => ({
  activeRecordingNotice: state.activeRecordingNotice,
  autoAppendFormErrors: getFormSyncErrors(AUTO_APPEND_FORM_ID)(state),
  blockedDomains: state.blockedDomains,
  customAppendContent: getCustomAppendContent(state),
  defaultDeliveryChannel: getDeliveryChannelOfDefaultIdentity(state),
  domainSearch: state.domainSearchString,
  emailThrottlingLimits: emailThrottlingLimits(state),
  isAutoAppendEnabled: isAdminAutoAppendEnabled(state),
  isBoxIntegrationEnabled: isBoxIntegrationEnabled(state),
  isCallRecordingChecked: isCallRecordingChecked(state),
  isCallRecordingNoticeChecked: isCallRecordingNoticeChecked(state),
  isCampaignsEditingDisabled: isCampaignsEditingDisabled(state),
  isContentPartnersLoaded: state.contentPartners.loaded,
  isForceCallRecordingChecked: isForceCallRecordingChecked(state),
  isHighSpotChecked: isHighSpotChecked(state),
  isHighSpotEnabled: isHighSpotEnabled(state),
  isMSIppSfSignInAndActivationEnabled: isMSIppSfSignInAndActivationEnabled(
    state
  ),
  isSafeToChangeRoute:
    !isDirty(TEAM_EMAIL_LIMIT_FORM_ID)(state) &&
    !isDirty(AUTO_APPEND_FORM_ID)(state),
  isSalesEngagePhoneEnabled: !isFreeOrSolo(state) && !isStandard(state),
  isSalesPhoneConsentAndRecordingEnabled: isSalesPhoneConsentAndRecordingEnabled(
    state
  ),
  isSlackIntegrationEnabled: isSlackIntegrationEnabled(state),
  isTemplatesEditingDisabled: isTemplatesEditingDisabled(state),
  loading: isGeneralPageFetching(state),
  loadingBlockedDomains: state.loadingBlockedDomains,
  loginMethod: loginMethod(state),
  newDomainBlock: state.newDomainBlock,
  newDomainBlockError: state.newDomainBlockError,
  shouldLoadTeamSettings: shouldLoadTeamSettingsSelector(state),
  subscriptionCampaignLimit: subscriptionCampaignLimit(state),
  subscriptionEmailLimit: subscriptionEmailLimit(state),
  teamCampaignLimit: teamCampaignLimit(state),
  teamCampaignLimitFormErrors: getFormSyncErrors(TEAM_CAMPAIGN_LIMIT_FORM_ID)(
    state
  ),
  teamEmailLimit: teamEmailLimit(state),
  teamEmailLimitFormErrors: getFormSyncErrors(TEAM_EMAIL_LIMIT_FORM_ID)(state),
  teamEmailLimitSource: teamEmailLimitSource(state),
  teamEmailThrottlingLimitFormErrors: getFormSyncErrors(
    TEAM_EMAIL_THROTTLING_LIMIT_FORM_ID
  )(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      clearNewDomainBlock,
      destroyDomainBlock,
      getActiveRecordingNotice,
      getContentPartners: getContentPartnersAC,
      getDeliveryChannels,
      getDomainBlocks,
      getEmailThrottlingSettings: getEmailThrottlingSettingsAC,
      getIdentities,
      getTeamSettings: getTeamSettingsAC,
      handleSearchDomainChange,
      hideErrorMessage,
      openAutoAppendConfirmationPopup,
      openManageRecordingNotice,
      postDomainBlock,
      setNewDomainBlock,
      showValidationErrorMessage: showValidationErrorMessageAC,
      updateBoxIntegrationEnabled,
      updateCampaignsEditingDisabled,
      updateContentPartners,
      updateCustomAppendContent,
      updateEmailThrottlingSettings: updateEmailThrottlingSettingsAC,
      updateLoginMethod,
      updatePhoneSettings,
      updateSlackIntegrationEnabled,
      updateTeamEmailLimit: updateTeamEmailLimitAC,
      updateTemplatesEditingDisabled,
      uploadFile,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSettingsContainer);
