import GlobalBannerAlertsActionTypes from './globalBannerAlertsActionTypes';

export const setAlerts = (alerts) => ({
  type: GlobalBannerAlertsActionTypes.setAlerts,
  alerts,
});

export const addAlert = (alert) => ({
  type: GlobalBannerAlertsActionTypes.addAlert,
  alert,
});

export const closeAlertById = (id) => ({
  type: GlobalBannerAlertsActionTypes.closeAlertById,
  id,
});

export const clearAlerts = () => ({
  type: GlobalBannerAlertsActionTypes.clearAlerts,
});
