import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import './popupFooter.scss';

const PopupFooter = ({
  backgroundColor,
  backgroundImage,
  className,
  full,
  onPrimary,
  onSecondary,
  primaryColor,
  primaryDisabled,
  primaryIcon,
  primaryText,
  primaryTextId,
  secondaryColor,
  secondaryDisabled,
  secondaryIcon,
  secondaryText,
  secondaryTextId,
}) => {
  const classes = classNames('popup-footer', className, {
    'popup-footer-full': full,
    'popup-footer-padding': onPrimary || onSecondary,
    [`background-${backgroundColor}`]: backgroundColor,
  });

  const styles = {};

  if (backgroundImage) {
    styles.backgroundImage = backgroundImage;
  }

  return (
    <div className={classes} style={styles}>
      {onSecondary && (
        <Button
          classes={classNames({ [`${secondaryIcon}`]: secondaryIcon })}
          color={secondaryColor}
          disabled={secondaryDisabled}
          onClick={onSecondary}
          size={ButtonSizes.medium}
        >
          {secondaryText || <FormattedMessage id={secondaryTextId} />}
        </Button>
      )}
      {onPrimary && (
        <Button
          classes={classNames('margin-left-full', {
            [`${primaryIcon}`]: primaryIcon,
          })}
          color={primaryColor}
          disabled={primaryDisabled}
          onClick={onPrimary}
          size={ButtonSizes.medium}
        >
          {primaryText || <FormattedMessage id={primaryTextId} />}
        </Button>
      )}
    </div>
  );
};

PopupFooter.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
  full: PropTypes.bool,
  onPrimary: PropTypes.func,
  onSecondary: PropTypes.func,
  primaryColor: PropTypes.oneOf(Object.values(ButtonColors)),
  primaryDisabled: PropTypes.bool,
  primaryIcon: PropTypes.string,
  primaryText: PropTypes.string,
  primaryTextId: PropTypes.string,
  secondaryColor: PropTypes.oneOf(Object.values(ButtonColors)),
  secondaryDisabled: PropTypes.bool,
  secondaryIcon: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryTextId: PropTypes.string,
};

PopupFooter.defaultProps = {
  backgroundColor: BackgroundColors.white,
  backgroundImage: null,
  className: '',
  full: false,
  onPrimary: undefined,
  onSecondary: undefined,
  primaryColor: ButtonColors.blue,
  primaryDisabled: false,
  primaryIcon: '',
  primaryText: '',
  primaryTextId: '',
  secondaryColor: ButtonColors.gray,
  secondaryDisabled: false,
  secondaryIcon: '',
  secondaryText: '',
  secondaryTextId: '',
};

export default PopupFooter;
