import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import I18N from 'languages';
import classNames from 'classnames';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import Card from 'components/card';
import { getImageUrl } from 'web/libs/constants';
import Language from 'web/login/components/language';
import { languagesOptions } from 'web/settings/myProfile/components/timezoneSettings/libs/timezoneSettingsConstants';
import { parseLanguageItems } from 'web/settings/myProfile/components/timezoneSettings/helpers/timezoneSettingsHelper';
import BottomMenu from '../unauthorizedPage/bottomMenu';
import LogoAdobe from 'components/logoAdobe/logoAdobe';
import './unauthorizedAdobePage.scss';

export default function unauthorizedAdobePage(
  WrappedComponent,
  {
    cardWidth,
    titleKey = '',
    parentClassName = '',
    useUnauthorizedStateHeader = false,
    hideTitle = false,
  }
) {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  class UnauthorizedAdobePage extends Component {
    constructor(props) {
      super(props);

      const {
        intl: { formatMessage },
      } = props;

      document.title = `${formatMessage({ id: 'common.marketoSales' })}`;

      if (titleKey) {
        document.title = `${formatMessage({ id: titleKey })} | ${
          document.title
        }`;
      }
      if (!I18N.getCookie('i18nLangUpdated')) {
        I18N.setCookie('i18nLangUpdated', false);
      }
    }

    static displayName = `UnauthorizedAdobePage${componentName}`;

    render() {
      const { unauthorizedHeaderKey } = this.props;
      const headerKey =
        useUnauthorizedStateHeader && !!unauthorizedHeaderKey
          ? unauthorizedHeaderKey
          : 'web.unauthorized.title';
      return (
        <Page className="unauthorized-adobe-page">
          <PageView className="unauthorized-page-page-view">
            <div
              className="unauthorized-background-image"
              style={{
                backgroundImage: `url(${getImageUrl(
                  'unauthorized-page-background-dark',
                  'png'
                )})`,
              }}
            />
            <LogoAdobe className="logo-adobe" displayText />
            {I18N.getCookie('enableLoginLanguage') === 'true' && (
              <Language items={parseLanguageItems(languagesOptions)} />
            )}
            <div className={classNames('content-part', parentClassName)}>
              {!hideTitle && (
                <h1 className="unauthorized-title">
                  <FormattedMessage id={headerKey} />
                </h1>
              )}
              <Card width={cardWidth}>
                <WrappedComponent {...this.props} />
              </Card>
            </div>
            <BottomMenu />
          </PageView>
        </Page>
      );
    }

    static propTypes = {
      intl: intlShape.isRequired,
    };
  }

  return injectIntl(UnauthorizedAdobePage);
}
