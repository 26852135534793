export default {
  composeWindow: {
    bulkFail: 'COMPOSE_WINDOW_BULK_FAIL',
    bulkSuccess: 'COMPOSE_WINDOW_BULK_SUCCESS',
    draftSavedOnClose: 'COMPOSE_WINDOW_SINGLE_DRAFT_SAVED',
    emailSent: 'COMPOSE_WINDOW_SINGLE_EMAIL_SENT',
    scheduledEmailSent: 'COMPOSE_WINDOW_SINGLE_SCHEDULED_EMAIL_SENT',
    someBulkFail: 'COMPOSE_WINDOW_SOME_BULK_FAIL',
  },
  salesforce: {
    brokenConnection: 'SALESFORCE_BROKEN_CONNECTION',
  },
};
