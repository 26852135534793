import get from 'lodash/get';

export const getSalesforceData = (subscription) =>
  get(subscription, 'admin_settings.salesforce', {});

export const isApplyTeamSettingsFlag = (salesforce) =>
  !!get(salesforce, 'apply_team_settings', false);
export const isActivityViaApiFlag = (salesforce) =>
  !!get(salesforce, 'api_settings.activity_via_api', false);
export const isSyncTasksFlag = (salesforce) =>
  !!get(salesforce, 'sync_tasks', false);
