/**
 *
 * TableContainer
 *
 */
import React, { Component } from 'react';
import intersection from 'lodash/intersection';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getItems,
  getFilteredColumns,
  getPaginationItems,
  getSearchResults,
} from './tableContainerSelectors';
import {
  getHeaderSelected,
  getSelectableIds,
  getSelectableSelectedItems,
} from 'table/modules/selectable/selectableSelectors';
import ActionTypes from 'table/modules/actionTypes';
import ApiShapes from 'table/modules/shapes/apiShapes';
import { DragDropContextWrapper } from 'web/shared/components/dragDropContext/dragDropContext';
import Shapes from 'table/modules/shapes';
import {
  initTable,
  setColumnsSettings,
  changeColumnWidth,
} from 'table/modules/tableContainer/tableContainerActionCreators';
import { onActionClick } from 'table/modules/bulkActions/bulkActionsActionCreators';
import {
  initColumnFilter,
  onColumnFilterChange,
  selectOrDeselectAllColumns,
} from 'table/modules/columnFilter/columnFilterActionCreators';
import {
  onPaginationClick,
  onPaginationClickProp,
  changeRowsPerPage,
} from 'table/modules/pagination/paginationActionCreators';
import {
  onSearchChange,
  onSearchPrefixRemove,
  onSearchResultSelected,
} from 'table/modules/search/searchActionCreators';
import { onSortClick } from 'table/modules/sorting/sortingActionCreators';
import {
  onCheckAll,
  onCheckClickRow,
  onCheckClickWithShift,
  onClearAll,
  onHighlightClickRow,
  selectAllItems,
} from 'table/modules/selectable/selectableActionCreators';
import { onSmartFilterChange } from 'table/modules/smartFilter/smartFilterActionCreators';
import { changeColumnOrder } from 'table/modules/headerRow/headerColumn/columnOrderActionCreator';
import Table from 'table/modules/table';
import ActionHeader, {
  ACTION_HEADER_DEFAULT_HEIGHT,
} from 'table/modules/actionHeader';
import LoadingSpinner from 'components/loadingSpinner';
import 'table/modules/styles/styles.scss';
import Pagination from 'table/modules/pagination';

class TableContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { allowSetColumnsSettings: true };

    const { dispatch } = props;

    dispatch(initTable(props));

    this.actionCreators = {
      changeColumnOrder: (movingColumnId, beforeColumnId) => {
        dispatch(changeColumnOrder(movingColumnId, beforeColumnId));
        this._fireChangeColumnsSettingsEvent();
      },
      changeColumnWidth: (columnId, width) => {
        dispatch(changeColumnWidth(columnId, width));
        this._fireChangeColumnsSettingsEvent();
      },
      changeRowsPerPage: (perPage) =>
        dispatch(changeRowsPerPage(perPage, props.pagination.perPageClick)),
      onActionClick: (action) => dispatch(onActionClick(action)),
      onCheckClickHeader: (checked) => dispatch(onCheckAll(checked)),
      onCheckClickRow: (checked, id) => dispatch(onCheckClickRow(checked, id)),
      onCheckClickWithShift: (checked, id, items) =>
        dispatch(onCheckClickWithShift(checked, id, items)),
      onClearAllClick: () => dispatch(onClearAll()),
      onColumnFilterChange: (checked, id) =>
        dispatch(onColumnFilterChange(checked, id)),
      onHighlightClickRow: (item) => dispatch(onHighlightClickRow(item)),
      onPaginationClick: (nextPage) => dispatch(onPaginationClick(nextPage)),
      onPaginationClickProp: (nextPage) =>
        dispatch(onPaginationClickProp(nextPage, props.pagination.onClick)),
      onSearchChange: (value) => dispatch(onSearchChange(value)),
      onSearchPrefixRemove: () => dispatch(onSearchPrefixRemove()),
      onSearchResultSelected: (data) => dispatch(onSearchResultSelected(data)),
      onSelectAllClick: () => dispatch(onCheckAll(true, true)),
      onSelectAllPagesClick: (selectAllRequest, totalCount) =>
        dispatch(selectAllItems(selectAllRequest, totalCount)),
      onSmartFilterChange: (index, value, filters) =>
        dispatch(onSmartFilterChange(index, value, filters)),
      onSortClick: (columnId) => dispatch(onSortClick(columnId)),
      selectOrDeselectAllColumns: (columns, checked) =>
        dispatch(selectOrDeselectAllColumns(columns, checked)),
      setColumnsSettings: (props) => dispatch(setColumnsSettings(props)),
    };

    this.tableBodyRef = {};
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      // used to keep redux state & selector in sync; memoize on state?
      this.props.dispatch({
        items: nextProps.items,
        pagination: nextProps.pagination,
        type: ActionTypes.tableSetItems,
      });
    }
    if (nextProps.paginationItems !== this.props.paginationItems) {
      // used to keep redux state & selector in sync; memoize on state?
      this.props.dispatch({
        items: nextProps.paginationItems,
        type: ActionTypes.paginationSetItems,
      });
    }

    if (nextProps.columns !== this.props.columns) {
      this.checkColumnSettings(nextProps);
      this.props.dispatch(
        initColumnFilter(nextProps.columnFilter, nextProps.columns)
      );

      this.props.dispatch({
        columns: nextProps.columns,
        type: ActionTypes.tableSetColumns,
      });
    }

    const { allowSetColumnsSettings } = this.state;
    if (
      !this.isColumnSettingsSet() &&
      nextProps.columnSettings &&
      nextProps.columnSettings.length
    ) {
      if (allowSetColumnsSettings) {
        this.actionCreators.setColumnsSettings(nextProps);
      } else {
        this.setState({ allowSetColumnsSettings: true });
      }
    }

    const nextSelectedIdsCount = Object.keys(nextProps.selectedIds).length;
    const currentSelectedIdsCount = Object.keys(this.props.selectedIds).length;
    if (nextSelectedIdsCount !== currentSelectedIdsCount) {
      this.props.grabSelectedIds(nextProps.selectedIds);
      this.props.changeSelectedItemsCountHandler(nextSelectedIdsCount);
    }
  }

  isColumnSettingsSet = () => {
    const { columnSettings } = this.props;
    return columnSettings && columnSettings.length > 0;
  };

  checkColumnSettings = (nextProps) => {
    const { columns } = this.props;
    const { columns: nextColumns } = nextProps;
    const areColumnsChanged = intersection(
      columns.map((column) => column.id),
      nextColumns.map((column) => column.id)
    );

    if (areColumnsChanged.length !== columns.length) {
      this.actionCreators.setColumnsSettings(nextProps);
    }
  };

  render() {
    const {
      actionHeaderHeight,
      animateRow,
      animationColor,
      bulkActions,
      columnFilter,
      columnsWidth,
      fetching,
      filteredColumns,
      highlightOnClick,
      left,
      loading,
      loadingGetUrl,
      header,
      headerSelected,
      maxHeight,
      openAdvancedSearch,
      paginationItems,
      pagination,
      reorderColumnsEnable,
      resizeTable,
      row,
      search,
      selectable,
      selectAllDisabled,
      selectedIds,
      smartFilters,
      sortingColumn,
      sortingDirection,
      zeroState,
      draggable,
      isResize,
    } = this.props;

    const paginationData = (pagination && this._getPagination()) || undefined;

    return (
      <div className="tout-table">
        {(loading || fetching) && loadingGetUrl ? (
          <LoadingSpinner imageUrl={loadingGetUrl} />
        ) : null}
        <ActionHeader
          bulkActions={(bulkActions && this._getBulkActions()) || undefined}
          columnFilter={(columnFilter && this._getColumnFilter()) || undefined}
          height={actionHeaderHeight}
          left={left}
          openAdvancedSearch={openAdvancedSearch}
          pagination={(pagination && this._getPagination()) || undefined}
          scrollTop={this.scrollTop}
          search={(search && this._getSearch()) || undefined}
          smartFilters={(smartFilters && this._getSmartFilters()) || undefined}
        />
        <Table
          animateRow={animateRow}
          animationColor={animationColor}
          changeColumnOrderHandler={this.actionCreators.changeColumnOrder}
          changeColumnWidthHandler={this.actionCreators.changeColumnWidth}
          columns={filteredColumns}
          columnsWidth={columnsWidth}
          draggable={draggable}
          header={{
            ...header,
            isSelected: headerSelected,
            onCheckClick: this.actionCreators.onCheckClickHeader,
            onSortClick: this.actionCreators.onSortClick,
          }}
          highlightOnClick={highlightOnClick}
          isResize={isResize}
          items={paginationItems}
          loading={fetching}
          loadingGetUrl={null}
          maxHeight={maxHeight}
          reorderColumnsEnable={reorderColumnsEnable}
          resizeTable={resizeTable}
          row={{
            ...row,
            onCheckClick: this._onCheckClickRow,
            onHighlightClickRow: this.actionCreators.onHighlightClickRow,
          }}
          selectAllDisabled={selectAllDisabled}
          selectable={selectable}
          selectedIds={selectedIds}
          setBodyRef={this.setBodyRef}
          sortingColumn={sortingColumn}
          sortingDirection={sortingDirection}
          zeroState={zeroState}
        />
        {paginationData && (
          <Pagination scrollTop={this.scrollTop} {...paginationData} />
        )}
      </div>
    );
  }

  _fireChangeColumnsSettingsEvent = () => {
    const {
      changeColumnsSettingsHandler,
      columnOrder,
      columnsWidth,
      reorderColumnsEnable,
      isResize,
    } = this.props;
    if (isResize || reorderColumnsEnable) {
      const newColumnsSettings = columnsWidth.sort(
        (columnId1, columnId2) =>
          columnOrder.indexOf(columnId1.columnId) -
          columnOrder.indexOf(columnId2.columnId)
      );

      if (!this.isColumnSettingsSet()) {
        this.setState({ allowSetColumnsSettings: false });
      }

      changeColumnsSettingsHandler(newColumnsSettings);
    }
  };

  _onCheckClickRow = (isChecked, id, e) => {
    if (e.shiftKey) {
      const { items } = this.props;

      this.actionCreators.onCheckClickWithShift(isChecked, id, items);
    } else {
      this.actionCreators.onCheckClickRow(isChecked, id);
    }
  };

  setBodyRef = (bodyRef) => {
    this.tableBodyRef = bodyRef;
  };

  scrollTop = () => {
    this.tableBodyRef.scrollTop = 0;
  };

  _getBulkActions = () => {
    const {
      bulkActions,
      items,
      pagination,
      selectedIds,
      selectedItems,
      unknownTotal,
      selectAllDisabled,
    } = this.props;

    const selectAllClick = (totalCount) => {
      if (!bulkActions.selectAllRequest) {
        this.actionCreators.onSelectAllClick();
      } else {
        this.actionCreators.onSelectAllPagesClick(
          bulkActions.selectAllRequest,
          totalCount
        );
      }
    };
    const shouldHideSelectAll =
      !!bulkActions.selectAllRequest &&
      pagination.total === 1 &&
      bulkActions.bulkActions !== false;

    return {
      onActionClick: this.actionCreators.onActionClick,
      onClearAllClick: this.actionCreators.onClearAllClick,
      onSelectAllClick: selectAllClick,
      selectAll: shouldHideSelectAll ? false : bulkActions.selectAll,
      selectAllDisabled,
      selectedCount: Object.keys(selectedIds).length,
      selectedItems,
      totalCount:
        unknownTotal || (pagination && pagination.clearSelected)
          ? 0
          : items.filter((elem) => !elem.isSelectedDisabled).length,
      ...bulkActions,
    };
  };

  _getColumnFilter = () => {
    const {
      allItems,
      columnFilterChecked,
      columns,
      paginationPerPage,
      pagination,
      columnFilterPanel,
    } = this.props;

    return {
      allItems,
      changeRowsPerPage: this.actionCreators.changeRowsPerPage,
      checked: columnFilterChecked,
      columnFilterPanel,
      columns,
      onChange: this.actionCreators.onColumnFilterChange,
      pagination,
      paginationPerPage,
      selectOrDeselectAll: this.actionCreators.selectOrDeselectAllColumns,
    };
  };

  _getPagination = () => {
    const {
      items,
      pagination,
      paginationCurrentPage,
      paginationPerPage,
      unknownTotal,
    } = this.props;
    const {
      currentPage: paginationCurrentPageProp,
      onClick: paginationOnClick,
      total: paginationTotalProp,
      ...paginationOther
    } = pagination;

    return {
      currentPage:
        (paginationOnClick && paginationCurrentPageProp) ||
        paginationCurrentPage, //todo only if onClick/allItems?
      itemsLength: items.length,
      onClick:
        (paginationOnClick && this.actionCreators.onPaginationClickProp) ||
        this.actionCreators.onPaginationClick,
      perPage: paginationPerPage,
      totalPages:
        paginationTotalProp || Math.ceil(items.length / paginationPerPage) || 1,
      unknownTotal,
      ...paginationOther,
    };
  };

  _getSearch = () => {
    const {
      allItems,
      search,
      searchResults,
      searchPrefix,
      searchValue,
    } = this.props;
    const {
      noDropdown,
      onChange,
      onClear,
      onPrefixRemove,
      onResultSelected,
      placeholderText,
      prefix,
      searching,
      value,
      ...searchOther
    } = search;

    return {
      noDropdown: noDropdown || (allItems && typeof searching === 'function'),
      onChange: onChange || this.actionCreators.onSearchChange,
      onClear,
      onPrefixRemove:
        onPrefixRemove || this.actionCreators.onSearchPrefixRemove,
      onResultSelected:
        onResultSelected || this.actionCreators.onSearchResultSelected,
      placeholderText: (!prefix && placeholderText) || '',
      prefix: prefix || searchPrefix,
      results: searchResults,
      value: (onChange && value) || searchValue,
      ...searchOther,
    };
  };

  _parseFilter(index, filter, actionCreators, smartFilterSelectedValue) {
    const {
      onChange: smartFilterOnChange,
      selectedValue: smartFilterSelectedValueProp,
      ...smartFilterOther
    } = filter;
    return {
      index,
      onChange: smartFilterOnChange || actionCreators.onSmartFilterChange,
      selectedValue:
        (smartFilterOnChange && smartFilterSelectedValueProp) ||
        smartFilterSelectedValue ||
        smartFilterSelectedValueProp, // if onChange, use passed value, else check if in state, if not fall back on passed in again
      ...smartFilterOther,
    };
  }

  _getSmartFilters = () => {
    const { smartFilters, smartFilterSelectedValues } = this.props;
    let array = [];
    if (Array.isArray(smartFilters)) {
      array = smartFilters.map((filter, index) =>
        this._parseFilter(
          index,
          filter,
          this.actionCreators,
          smartFilterSelectedValues[index]
        )
      );
    } else {
      array.push(
        this._parseFilter(
          0,
          smartFilters,
          this.actionCreators,
          smartFilterSelectedValues[0]
        )
      );
    }

    return array;
  };
}

TableContainer.propTypes = {
  // passed in
  actionHeaderHeight: PropTypes.number,
  allItems: PropTypes.bool,
  animateRow: PropTypes.bool,
  animationColor: PropTypes.string,
  bulkActions: PropTypes.oneOfType([ApiShapes.BulkActions, PropTypes.bool]),
  changeColumnsSettingsHandler: PropTypes.func,
  changeSelectedItemsCountHandler: PropTypes.func,
  columnFilter: PropTypes.oneOfType([ApiShapes.ColumnFilter, PropTypes.bool]),
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  columns: ApiShapes.Columns,
  columnsWidth: PropTypes.arrayOf(PropTypes.object),
  fetching: PropTypes.bool,
  header: ApiShapes.Header,
  highlightOnClick: PropTypes.bool,
  isResize: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  left: PropTypes.bool,
  loading: PropTypes.bool,
  loadingGetUrl: PropTypes.func,
  maxHeight: PropTypes.number,
  pagination: PropTypes.oneOfType([ApiShapes.Pagination, PropTypes.bool]),
  resizeTable: PropTypes.bool,
  row: ApiShapes.Row,
  search: PropTypes.oneOfType([ApiShapes.Search, PropTypes.bool]),
  selectAllDisabled: PropTypes.bool,
  selectable: PropTypes.bool,
  smartFilters: PropTypes.oneOfType([ApiShapes.SmartFilters, PropTypes.bool]),
  unknownTotal: PropTypes.bool,
  zeroState: ApiShapes.ZeroState,

  // state
  columnFilterChecked: PropTypes.objectOf(PropTypes.bool), //eslint-disable-line
  columnFilterPanel: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  filteredColumns: Shapes.Columns,
  grabSelectedIds: PropTypes.func,
  headerSelected: PropTypes.bool.isRequired,
  openAdvancedSearch: PropTypes.func,
  paginationCurrentPage: PropTypes.number.isRequired,
  paginationItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  paginationPerPage: PropTypes.number.isRequired,
  reorderColumnsEnable: PropTypes.bool,
  searchPrefix: PropTypes.string,
  searchResults: PropTypes.array.isRequired,
  searchValue: PropTypes.string.isRequired,
  selectedIds: PropTypes.objectOf(PropTypes.bool).isRequired,
  selectedItems: PropTypes.object.isRequired,
  smartFilterSelectedValues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  sortingColumn: PropTypes.string.isRequired,
  sortingDirection: Shapes.SortingDirection.isRequired,
};

TableContainer.defaultProps = {
  actionHeaderHeight: ACTION_HEADER_DEFAULT_HEIGHT,
  allItems: false,
  animateRow: false,
  animationColor: '',
  bulkActions: false,
  changeColumnsSettingsHandler: () => {},
  changeSelectedItemsCountHandler: () => {},
  columnFilter: false,
  columnFilterPanel: undefined,
  columnOrder: [],
  columnSettings: [],
  columns: undefined,
  columnsWidth: [],
  draggable: undefined,
  fetching: false,
  filteredColumns: [],
  grabSelectedIds: () => {},
  header: undefined,
  highlightOnClick: false,
  isResize: false,
  left: false,
  loading: false,
  loadingGetUrl: undefined,
  maxHeight: undefined,
  openAdvancedSearch: undefined,
  pagination: false,
  reorderColumnsEnable: false,
  resizeTable: false,
  row: undefined,
  search: false,
  searchPrefix: '',
  selectAllDisabled: false,
  selectable: false,
  smartFilters: false,
  unknownTotal: false,
  zeroState: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  columnFilterChecked: state.columnFilterChecked,
  columnOrder: state.columnOrder,
  columnsWidth: state.tableColumnsWidth,
  filteredColumns: getFilteredColumns(state, ownProps),
  headerSelected: getHeaderSelected(state, ownProps),
  items: getItems(state, ownProps),
  paginationCurrentPage: state.paginationCurrentPage,
  paginationItems: getPaginationItems(state, ownProps),
  paginationPerPage: state.paginationPerPage,
  searchPrefix: state.searchPrefix,
  searchResults: getSearchResults(state, ownProps),
  searchValue: state.searchValue,
  selectedIds: getSelectableIds(state),
  selectedItems: getSelectableSelectedItems(state),
  smartFilterSelectedValues: state.smartFilterSelectedValues,
  sortingColumn: state.sortingColumn,
  sortingDirection: state.sortingDirection,
});

export default compose(
  connect(
    mapStateToProps,
    null,
    null,
    { withRef: true }
  ),
  DragDropContextWrapper
)(TableContainer);
export const UnwrappedTableContainer = TableContainer;
