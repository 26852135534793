import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormSyncErrors, isDirty } from 'redux-form';
import { closeViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  notGmailSmtp as notGmailSmtpValidatorFactory,
  notOffice365Smtp as notOffice365SmtpValidatorFactory,
} from '../helpers/emailServerValidators';
import {
  saveEmailServer,
  openRemoveConfirmationPopup,
  getEmailServer,
  showValidationErrorMessage,
} from '../actionCreators/emailServerActionCreators';
import { FORM_ID } from '../libs/emailServerConstants';
import EmailServer from '../components/emailServer';

class EmailServerContainer extends Component {
  notGmailSmtpValidator = notGmailSmtpValidatorFactory();

  notOffice365SmtpValidator = notOffice365SmtpValidatorFactory();

  componentDidMount() {
    const {
      teamSmtpDeliveryChannelFetched,
      teamSmtpDeliveryChannelFetching,
      actionCreators,
    } = this.props;

    if (!teamSmtpDeliveryChannelFetched && !teamSmtpDeliveryChannelFetching) {
      actionCreators.getEmailServer();
    }
  }

  render() {
    const {
      teamSmtpDeliveryChannelFetching,
      teamSmtpDeliveryChannel,
      teamSmtpDeliveryChannelFormDirty,
      actionCreators,
    } = this.props;

    return (
      <EmailServer
        actionCreators={actionCreators}
        loading={teamSmtpDeliveryChannelFetching}
        model={teamSmtpDeliveryChannel}
        formName={FORM_ID}
        isSafeToChangeRoute={!teamSmtpDeliveryChannelFormDirty}
        additionalServerValidators={[
          this.notGmailSmtpValidator,
          this.notOffice365SmtpValidator,
        ]}
      />
    );
  }
}

EmailServerContainer.propTypes = {
  teamSmtpDeliveryChannelFetched: PropTypes.bool.isRequired,
  teamSmtpDeliveryChannelFetching: PropTypes.bool.isRequired,
  teamSmtpDeliveryChannel: PropTypes.object,
  teamSmtpDeliveryChannelErrors: PropTypes.object, //eslint-disable-line react/no-unused-prop-types
  teamSmtpDeliveryChannelFormDirty: PropTypes.bool.isRequired,
  actionCreators: PropTypes.object.isRequired,
};

EmailServerContainer.defaultProps = {
  teamSmtpDeliveryChannel: null,
  teamSmtpDeliveryChannelErrors: null,
};

const mapStateToProps = (state) => ({
  teamSmtpDeliveryChannelFetched: state.teamSmtpDeliveryChannelFetched,
  teamSmtpDeliveryChannelFetching: state.teamSmtpDeliveryChannelFetching,
  teamSmtpDeliveryChannel: state.teamSmtpDeliveryChannel,
  teamSmtpDeliveryChannelErrors: getFormSyncErrors(FORM_ID)(state),
  teamSmtpDeliveryChannelFormDirty: isDirty(FORM_ID)(state),
});

const mapDispatchToProps = {
  closeViewAlert,
  getEmailServer,
  onShowValidationErrorMessage: showValidationErrorMessage,
  onDelete: openRemoveConfirmationPopup,
  onSave: saveEmailServer,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { onShowValidationErrorMessage, ...restActions } = dispatchProps;
  return {
    ...stateProps,
    ...ownProps,
    actionCreators: {
      ...restActions,
      onError: (errors) =>
        onShowValidationErrorMessage(
          errors || stateProps.teamSmtpDeliveryChannelErrors
        ),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EmailServerContainer);
