import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import CommandCenterContainer from 'web/commandCenter/containers/commandCenterContainer';
import TwoPaneLayout from 'web/shared/components/twoPaneLayout/twoPaneLayout';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import CommandCenterSidebarContainer from 'web/commandCenter/containers/commandCenterSidebarContainer';

const TasksTab = () => (
  <div key="tasks-tab-container">
    <TwoPaneLayout>
      <CommandCenterSidebarContainer />
      <CommandCenterContainer />
    </TwoPaneLayout>
  </div>
);

export default compose(
  withCommonModals,
  connect(null),
  injectIntl
)(TasksTab);
