import PopupActionTypes from '../libs/popupActionTypes';

export function openPopup(id, data, meta) {
  return {
    type: PopupActionTypes.open,
    id,
    data,
    ...(meta && { meta }),
  };
}

export function closePopup() {
  return {
    type: PopupActionTypes.close,
  };
}

export function setPopupLoading(isLoading = false) {
  return {
    type: PopupActionTypes.loading,
    isLoading,
  };
}

export function setPopupAlert(id = '', textValues) {
  return {
    type: PopupActionTypes.alert,
    id,
    textValues,
  };
}

export function clearPopupAlert() {
  return {
    type: PopupActionTypes.alert,
    id: '',
  };
}

export function setPopupOverlay(id = '', textValues) {
  return {
    type: PopupActionTypes.overlay,
    id,
    textValues,
  };
}

export function hidePopupOverlay() {
  return {
    type: PopupActionTypes.overlay,
    id: '',
  };
}

export function setPopupData(data) {
  return {
    data,
    type: PopupActionTypes.setData,
  };
}
