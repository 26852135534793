import { createSelector } from 'reselect';
import {
  getApiCallsStatsFetching,
  getIntegrationFetching,
} from 'web/salesforce/selectors/salesforceSelectors';
import { getContactIntegrationFetching } from 'web/contactIntegrations/selectors/contactIntegrationsSelectors';

export const getLoading = createSelector(
  [
    getApiCallsStatsFetching,
    getIntegrationFetching,
    getContactIntegrationFetching,
  ],
  (apiCallsStatsFetching, integrationFetching, contactIntegrationsFetching) =>
    apiCallsStatsFetching || integrationFetching || contactIntegrationsFetching
);
