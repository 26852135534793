import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';

export const peopleNewGroupName = (state = '', { type, name }) => {
  switch (type) {
    case PeopleActionTypes.group.setPeopleAddNewGroupName:
      return name || '';
    case PeopleActionTypes.group.clearAddNewGroupForm:
      return '';
    default:
      return state;
  }
};

export const peopleNewGroupDescription = (
  state = '',
  { type, description }
) => {
  switch (type) {
    case PeopleActionTypes.group.setPeopleAddNewGroupDescription:
      return description || '';
    case PeopleActionTypes.group.clearAddNewGroupForm:
      return '';
    default:
      return state;
  }
};

export const selectedAddPeopleToGroupPeople = (
  state = { loading: false, people: [] },
  action
) => {
  const { people, contactEmailId } = action;
  let contactEmailIdIndex;

  switch (action.type) {
    case PeopleActionTypes.group.setSelectedAddPeopleToGroupPeople:
      return people;
    case PeopleActionTypes.group.setSelectedEmailIdAddPeopleToGroup:
      contactEmailIdIndex = state.findIndex(
        ({ id }) => id === contactEmailId.id
      );

      return contactEmailIdIndex !== -1
        ? [
            ...state.slice(0, contactEmailIdIndex),
            {
              ...state[contactEmailIdIndex],
              emailId: contactEmailId.emailId,
            },
            ...state.slice(contactEmailIdIndex + 1),
          ]
        : state;

    case PopupActionTypes.close:
      return [];
    default:
      return state;
  }
};

export const selectedAddToGroupGroupId = (state = null, action) => {
  switch (action.type) {
    case PeopleActionTypes.group.setSelectedAddPeopleToGroupGroupId:
      return action.id;
    default:
      return state;
  }
};

export const shareGroupTeamIds = (state = [], action) => {
  switch (action.type) {
    case PeopleActionTypes.group.setGroupShareTeamIds:
      return action.ids;
    default:
      return state;
  }
};

export const shareGroupComment = (state = '', action) => {
  switch (action.type) {
    case PeopleActionTypes.group.setShareGroupComment:
      return action.value;
    default:
      return state;
  }
};

export const shareGroup = (state = '', action) => {
  switch (action.type) {
    case PeopleActionTypes.group.setShareGroup:
      return action.group;
    default:
      return state;
  }
};
