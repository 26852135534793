import DynamicFieldsActionTypes from '../libs/dynamicFieldsActionTypes';
import {
  getDynamicFields as getDynamicFieldsCall,
  getActivityDynamicFieldsSalesforce as getActivityDynamicFieldsSalesforceCall,
} from '../services/dynamicFieldsService';
import {
  parseDynamicFields,
  parseDynamicField,
} from '../helpers/dynamicFieldsParsers';
import { insertHtml } from 'web/emailComposeBody/actionCreators/emailComposeActionCreators';
import {
  openEmailComposePopup,
  closeEmailComposePopup,
} from 'web/emailComposeBody/actionCreators/popupActionCreators';
import { GlobalPopupIds } from 'web/emailComposeBody/libs/emailComposeBodyConstants';
import {
  DYNAMIC_FIELDS,
  DYNAMIC_FIELDS_REGEX,
} from 'web/dynamicFields/libs/dynamicFieldsConstants';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { ModalComponentIds } from 'web/composeWindow/libs/composeWindowConstants';
import { isMsiActions } from 'web/user/selectors/userSelectors';
import { DYNAMIC_FIELDS_CATEGORY } from 'libs/constantsShared';

const { DAYS_FROM_NOW, BUSINESS_DAYS_FROM_NOW } = DYNAMIC_FIELDS;

/* Dynamic Fields don't change often so only are loaded once */
export const getDynamicFields = (force = false) => (dispatch, getState) => {
  const { dynamicFields } = getState();
  const msiActionsEnabled = isMsiActions(getState());

  if (force || dynamicFields.length === 0) {
    dispatch({ type: DynamicFieldsActionTypes.fetching });
    getDynamicFieldsCall()
      .then((data) => {
        if (msiActionsEnabled) {
          delete data[DYNAMIC_FIELDS_CATEGORY.customFields];
        }

        dispatch({
          dynamicFields: parseDynamicFields(data),
          type: DynamicFieldsActionTypes.set,
        });
        dispatch({ type: DynamicFieldsActionTypes.fetchingDone });
      })
      .catch((error) => {
        dispatch({ error, type: DynamicFieldsActionTypes.getFailure }); //todo handle error in modal
        dispatch({ type: DynamicFieldsActionTypes.fetchingDone });
      });
  }
};

export const getActivityDynamicFieldsSalesforce = () => async (
  dispatch,
  getState
) => {
  const { activityDynamicFieldsSalesforce } = getState();
  if (activityDynamicFieldsSalesforce.length === 0) {
    dispatch({ type: DynamicFieldsActionTypes.fetching });
    try {
      const dynamicFields = (await getActivityDynamicFieldsSalesforceCall()).map(
        (field) => parseDynamicField(field)
      );
      dispatch({
        dynamicFields,
        type: DynamicFieldsActionTypes.setSalesforceActivityDynamicFields,
      });
    } catch (err) {
      dispatch({ err, type: DynamicFieldsActionTypes.getFailure });
    } finally {
      dispatch({ type: DynamicFieldsActionTypes.fetchingDone });
    }
  }
};

export const isEditableDynamicField = (field = '') =>
  DYNAMIC_FIELDS_REGEX.DAYS_FROM_NOW_DYNAMIC_FIELD.test(field);

export const messageIdForField = (field) => {
  switch (field) {
    case DAYS_FROM_NOW:
      return 'dynamicFields.chooseNumberOfDaysPrompt';
    case BUSINESS_DAYS_FROM_NOW:
      return 'dynamicFields.chooseNumberOfBusinessDaysPrompt';
    default:
      return 'dynamicFields.chooseNumberOfDaysPrompt';
  }
};

export const insertDynamicField = (field = '') => (dispatch, getState) => {
  const {
    emailComposePopup: { editorId },
  } = getState();

  dispatch(insertHtml(editorId, field));
  dispatch(closeEmailComposePopup());
};

export const insertDaysFromNowDynamicField = (days = '', field = '') => (
  dispatch
) => {
  const updatedField = field.replace('#', days);
  dispatch(insertDynamicField(updatedField));
};

export const dynamicFieldsSelected = (field = '') => (dispatch, getState) => {
  const {
    emailComposePopup: { editorId },
  } = getState();

  if (isEditableDynamicField(field)) {
    const messageId = messageIdForField(field);

    dispatch(
      openEmailComposePopup(
        GlobalPopupIds.dynamicFieldsDaysFromNow,
        {
          field,
          messageId,
        },
        editorId
      )
    );
  } else if (field) {
    dispatch(insertDynamicField(field));
  }
};

export const setDynamicFieldsDaysFromNow = (days) => ({
  days,
  type: DynamicFieldsActionTypes.setDaysFromNow,
});

export const openInsertDynamicFieldsPopup = () => (dispatch) => {
  dispatch(openPopup(ModalComponentIds.insertDynamicFields, {}));
};

// repeating code for now, will eventually delete and replace ckEditor
export const dynamicFieldsSelectedTinyMce = (field = '') => (
  dispatch,
  getState
) => {
  const {
    emailComposePopup: { editorId },
  } = getState();

  if (isEditableDynamicField(field)) {
    const messageId = messageIdForField(field);

    dispatch(
      openEmailComposePopup(
        GlobalPopupIds.dynamicFieldsDaysFromNow,
        {
          field,
          messageId,
        },
        editorId
      )
    );
  } else if (field) {
    dispatch(insertDynamicField(field));
  }
};

export const insertDynamicFieldTinyMce = (field = '') => (
  dispatch,
  getState
) => {
  const {
    emailComposePopup: { editorId },
  } = getState();

  dispatch(insertHtml(editorId, field));
  dispatch(closeEmailComposePopup());
};
