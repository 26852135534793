import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import SalesforcePage from '../salesforcePage';
import SalesforcePopupAlert from 'web/settings/accountSettings/salesforce/components/salesforcePopupAlert/salesforcePopupAlert';

const Salesforce = (props) => (
  <Fragment>
    <SalesforcePage {...props} />
    <SalesforcePopupAlert />
  </Fragment>
);

Salesforce.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(Salesforce);
