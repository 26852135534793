import merge from 'lodash/merge';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import {
  GlobalAlertIds,
  GlobalPopupIds,
  CampaignDetailsEnum,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import {
  closePopup,
  openPopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  createCampaign as createCampaignCall,
  getCampaigns as getCampaignsCall,
} from 'web/campaigns/campaignsTab/services/campaignService';
import {
  selectCurrentCategory,
  setIsNewCampaign,
} from 'web/campaigns/campaignsTab/actionCreators/campaignsCategoriesActionCreators';
import {
  editCampaignName,
  selectCampaign,
} from 'web/campaigns/campaignsTab/actionCreators/campaignDetailsActionCreators';
import { navigateToCampaignDetailsTab } from 'web/services/routerService';
import { track } from 'web/services/mixpanelService';
import { CampaignActionEvents } from 'web/libs/mixpanelEvents';
import { getCampaignsParams } from 'web/reducers/campaigns/campaignSelectors';

export function setContentLoading(loading) {
  return {
    loading,
    type: ActionTypes.campaignsTab.state.contentViewLoading,
  };
}

export function createNewCampaignOnChange(data) {
  return {
    data: { category: data },
    type: ActionTypes.campaignsTab.state.createNewCampaignChange,
  };
}

export function updateStateCampaign(update) {
  return {
    type: ActionTypes.campaignsTab.state.createCampaign,
    update,
  };
}

export function openCreateCampaign() {
  return (dispatch, getState) => {
    const { campaignCategories, currentCategory } = getState();
    if (campaignCategories.length > 1) {
      dispatch(createNewCampaignOnChange(currentCategory || ''));
      dispatch(openPopup(GlobalPopupIds.createNewCampaign));
    } else {
      dispatch(setIsNewCampaign());
      dispatch(openPopup(GlobalPopupIds.createNewCategory));
    }
  };
}

export function createCampaignAdd(data) {
  return {
    data,
    type: ActionTypes.common.data.campaignAdd,
  };
}

export function createCampaign() {
  return (dispatch, getState) => {
    const {
      campaignCategories,
      campaignsTabCreateNewCampaignState: createNewCampaignState,
      userMetric,
    } = getState();
    const categoryObj = find(campaignCategories, {
      id: createNewCampaignState.category,
    }); //todo temporary until backend switched from name to id
    const newCampaign = merge({}, createNewCampaignState, {
      category: categoryObj.name,
      stopOnReply: userMetric.uses_reply_tracking,
    });

    dispatch(setPopupLoading(true));
    createCampaignCall(newCampaign)
      .then((data) => {
        dispatch(createCampaignAdd(data));
        dispatch(selectCampaign(data.id));
        dispatch(editCampaignName());
        navigateToCampaignDetailsTab(
          data.category_id,
          data.id,
          CampaignDetailsEnum.setup.value
        );
        dispatch(closePopup());
        toutBackboneHelper.campaignUpdate(data);
        track(CampaignActionEvents.createCampaign);
      })
      .catch(() => {
        dispatch(setPopupLoading(false));
        dispatch(setPopupAlert(GlobalAlertIds.createNewCampaign));
      });
  };
}

export function getCampaigns(initCampaignId) {
  return (dispatch, getState) => {
    dispatch({
      loading: true,
      type: ActionTypes.campaignsTab.state.contentViewLoading,
    });

    const params = getCampaignsParams(getState());

    return getCampaignsCall(params).then(
      (data) => {
        const sortedData = sortBy(data, (i) => i.name.toLowerCase());
        const { currentCategory } = getState();
        if (initCampaignId) {
          dispatch(selectCampaign(initCampaignId));
        }

        dispatch({
          loading: false,
          type: ActionTypes.campaignsTab.state.contentViewLoading,
        });
        dispatch({ data: sortedData, type: ActionTypes.api.campaigns.success });
        dispatch(selectCurrentCategory(currentCategory));
      },
      () => {
        // TODO - error
      }
    );
  };
}

// Only updates data that is known to be in pusher update (e.g., a complete campaign object is not passed)
export function pusherWorkflowUpdated(updatedCampaign) {
  return (dispatch) => {
    dispatch({
      id: updatedCampaign.id,
      newProperties: {
        aggregate_metrics: updatedCampaign.aggregate_metrics,
        currently_active_count: updatedCampaign.currently_active_count,
        state: updatedCampaign.state,
      },
      type: ActionTypes.common.data.campaignUpdateByProperty,
    });
  };
}

export function pusherCampaignMetricsUpdated(updatedCampaignMetric) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.common.data.campaignMetricUpdate,
      updatedCampaignMetric,
    });
  };
}
