import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { default as I18N, moment } from 'languages'; //eslint-disable-line import/no-named-default
import classNames from 'classnames';
import { ANCHOR_RIGHT, START_DATE, END_DATE } from 'react-dates/constants';
import {
  DateRangePicker as DateRangePickerModule,
  toMomentObject,
} from 'react-dates';
import './dateRangePicker.scss';

const CLASS_NAME = 'dateRangePicker';

class DateRangePicker extends Component {
  constructor(props) {
    super(props);

    this.placeholderText = props.intl.formatMessage({
      id: 'common.selectDate',
    });
  }

  state = {
    endDate: this.props.endDate,
    focusedInput: null,
    open: false,
    startDate: this.props.startDate,
  };

  componentDidMount() {
    if (this.props.openOnMount) {
      this._open();
    }
  }

  render() {
    const {
      className,
      iconOnly,
      rightAlign,
      openOnMount: ignored1,
      intl: ignored2,
      updateStartDate,
      updateState,
      ...other
    } = this.props;
    const { endDate, focusedInput, startDate } = this.state;
    const classes = classNames(CLASS_NAME, className, {
      calendarIconButton: iconOnly,
    });

    return (
      <div className={classes} onClick={this._eatAllClicks}>
        {iconOnly ? (
          <div
            className="tout-icon-calendar"
            onClick={this._onFocusIconChange}
          />
        ) : null}
        <DateRangePickerModule
          {...other}
          anchorDirection={rightAlign ? ANCHOR_RIGHT : undefined}
          endDate={this._momentizeDate(endDate)}
          endDatePlaceholderText={this.placeholderText}
          focusedInput={focusedInput}
          isOutsideRange={this.returnsFalse}
          minimumNights={0}
          numberOfMonths={1}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          renderMonth={I18N.getLocalizedMonthYear}
          startDate={this._momentizeDate(startDate)}
          startDatePlaceholderText={this.placeholderText}
          hideKeyboardShortcutsPanel
        />
      </div>
    );
  }

  _momentizeDate(date) {
    const dateObj = moment.isMoment(date) ? date : toMomentObject(date);
    if (!dateObj) {
      return null;
    }
    return dateObj;
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState(({ focusedInput }, props) => {
      let end = endDate;
      if (focusedInput === null) {
        props.updateStartDate();
        props.onDatesChange(startDate, endDate);
      } else if (focusedInput === END_DATE) {
        // clear end date after selecting a start date
        end = null;
      }
      return {
        endDate: end,
        startDate,
      };
    });
  };

  // Calls onDatesChange in case user selects start date but exits picker without end date
  onFocusChange = (focusedInput) => {
    this.setState(
      () => ({ focusedInput }),
      () => {
        if (!focusedInput) {
          this._close();
        }
      }
    );
  };

  _onFocusIconChange = (e) => {
    e.stopPropagation();
    if (this.state.open) {
      this._close(); // focusedInput is set by onFocusChange
    } else {
      this._open();
    }
  };

  _open = () => {
    this.setState(() => ({
      focusedInput: START_DATE,
      open: true,
    }));
  };

  _close = () => {
    this.setState({ open: false });
    this.props.updateState();
    this.props.onClose();
  };

  _eatAllClicks = (e) => {
    e.stopPropagation();
  };

  /* By default the airbnb date picker only accepts future dates. isOutsideRange is only way allow outside dates. By always returning false, it allows any day to be picked. */
  returnsFalse() {
    return false;
  }
}

DateRangePicker.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // either date object, moment().format('L'), or 'YYYY-MM-DD'
  iconOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  onClose: PropTypes.func,
  onDatesChange: PropTypes.func.isRequired, // must handle moment arguments
  openOnMount: PropTypes.bool,
  rightAlign: PropTypes.bool,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired, // either date object, moment().format('L'), or 'YYYY-MM-DD'
  updateStartDate: PropTypes.func,
  updateState: PropTypes.func,
  withPortal: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  className: '',
  disabled: false,
  iconOnly: false,
  onClose: () => {},
  openOnMount: false,
  rightAlign: false,
  updateStartDate: () => {},
  updateState: () => {},
  withPortal: false,
};

export default injectIntl(DateRangePicker);
