/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  setGmailChromeExtensionInstalledState,
  switchToEmailSyncTab,
  installGooglePlugin,
} from '../actionCreators/emailPluginsActionCreators';
import EmailPlugins from 'web/settings/emailPlugins/components/emailPlugins';
import './emailPluginsContainer.scss';

class EmailPluginsContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    this.actionCreators = bindActionCreators(
      {
        setGmailChromeExtensionInstalledState,
        switchToEmailSyncTab,
        installGooglePlugin,
      },
      dispatch
    );
  }

  render() {
    return (
      <div className="email-plugins-container">
        <EmailPlugins actionCreators={this.actionCreators} {...this.props} />
      </div>
    );
  }
}

EmailPluginsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gmailChromeExtensionInstalled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  gmailChromeExtensionInstalled:
    state.emailPlugins.gmailChromeExtensionInstalled,
});

export default connect(mapStateToProps)(EmailPluginsContainer);
