import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Action from 'components/buttons/action';
import { OutlookMacPluginGuideDownloadUrl } from 'web/settings/emailPlugins/libs/emailPluginsConstants';
import './outlookMacWebCard.scss';

class OutlookMacWebCard extends Component {
  render() {
    return (
      <div className="outlook-mac-web-card">
        <div>
          <h3>
            <FormattedHTMLMessage id="web.emailPlugins.outlookForMacWeb.title" />
          </h3>
          <div className="card-description">
            <FormattedHTMLMessage id="web.emailPlugins.outlookForMacWeb.description" />
          </div>
          <div className="card-recommendation">
            <FormattedHTMLMessage id="web.emailPlugins.outlookForMacWeb.recommendation" />
          </div>
        </div>
        <div className="card-button-panel">
          <Action onClick={this.downloadInstruction}>
            <FormattedHTMLMessage id="web.emailPlugins.outlookForMacWeb.instructionsButton" />
          </Action>
        </div>
      </div>
    );
  }

  downloadInstruction = () => {
    window.open(OutlookMacPluginGuideDownloadUrl, '_blank');
  };
}

export default OutlookMacWebCard;
