import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header';
import PageTabNavBar from 'components/layouts/page/tabNavBar';
import PageTabNav from 'components/layouts/page/tabNav';
import PageView from 'components/layouts/page/view';
import { BackgroundColors } from 'libs/constantsStyles';
import { GeneralTabs } from '../../libs/generalPageConstants';
import GlobalSettingsContainer from '../../containers/globalSettingsContainer';
import EmailServerContainer from 'web/settings/emailServer/containers/emailServerContainer';
import './generalPage.scss';

class GeneralPage extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
    } = props;

    this.tabList = [
      {
        title: formatMessage({
          id: 'web.settings.adminSettings.general.tabs.global',
        }),
        value: GeneralTabs.globalSettings,
      },
      {
        title: formatMessage({
          id: 'web.settings.adminSettings.general.tabs.deliveryChannel',
        }),
        value: GeneralTabs.teamDeliveryChannel,
      },
    ];
  }

  render() {
    const { onTabSelect, tab } = this.props;

    return (
      <Page className="general-admin-page">
        <PageHeader textId="web.settings.adminSettings.general.title" />
        <PageTabNavBar border>
          <PageTabNav
            list={this.tabList}
            onTabSelect={onTabSelect}
            selectedTabValue={tab}
          />
        </PageTabNavBar>
        <PageView backgroundColor={BackgroundColors.grayExtraLight}>
          {this.getTabPageView()}
        </PageView>
      </Page>
    );
  }

  getTabPageView = () => {
    const { tab } = this.props;

    switch (tab) {
      case GeneralTabs.globalSettings:
        return <GlobalSettingsContainer />;
      case GeneralTabs.teamDeliveryChannel:
        return <EmailServerContainer />;
      default:
        return null;
    }
  };
}

GeneralPage.propTypes = {
  intl: intlShape.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
};

export default injectIntl(GeneralPage);
