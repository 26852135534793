export const MarketoActivityTypeMap = {
  22: 'BestBet',
  46: 'InterestingMoment',
  158: 'DynamicChatActivity',
  159: 'DynamicChatActivity',
  160: 'DynamicChatActivity',
  161: 'DynamicChatActivity',
  167: 'DynamicChatActivity',
  168: 'DynamicChatActivity',
  169: 'DynamicChatActivity',
  170: 'DynamicChatActivity',
  171: 'DynamicChatActivity',
  172: 'DynamicChatActivity',
  173: 'DynamicChatActivity',
  174: 'DynamicChatActivity',
};

export const DynamicChatActivityTypeEnum = {
  DIALOGUE_ENGAGED: 158,
  DOCUMENT_INTERACTED_WITH: 159,
  DIALOGUE_APPOINTMENT_SCHEDULED: 160,
  DIALOGUE_GOAL_REACHED: 161,
  ENGAGED_WITH_AN_AGENT: 167,
  CLICKED_LINK_IN_CHAT_DIALOGUE: 168,
  ENGAGED_WITH_CONVERSATIONAL_FORM: 169,
  SCHEDULED_MEETING_IN_CONVERSATIONAL_FORM: 170,
  REACHED_CONVERSATIONAL_FORM_GOAL: 171,
  INTERACTED_WITH_DOCUMENT_IN_CONVERSATIONAL_FORM: 172,
  ENGAGED_WITH_AN_AGENT_IN_CONVERSATIONAL_FORM: 173,
  CLICKED_LINK_IN_CONVERSATIONAL_FORM: 174,
};

export const INTERESTING_MOMENT = 'InterestingMoment';
export const BEST_BET = 'BestBet';
export const DYNAMIC_CHAT_ACTIVITY = 'DynamicChatActivity';

export const MarketoEvents = {
  email: 'Email',
  milestone: 'Milestone',
  web: 'Web',
  websiteVisit: 'websiteVisit',
  dynamicChat: 'DynamicChat',
};

export const DynamicChatEventAttributes = {
  agentName: 'agent name',
  conversationalFlowName: 'conversational flow name',
  conversationStatus: 'conversation status',
  dialogueName: 'dialogue name',
  documentDownloaded: 'document downloaded',
  documentName: 'document name',
  documentOpened: 'document opened',
  goalName: 'goal name',
  link: 'link',
  meetingStatus: 'meeting status',
  pageUrl: 'page url',
  scheduledFor: 'scheduled for',
};

export const LiveFeedHeartbeat = {
  interestingMoments: 'interesting_moments',
  liveFeed: 'live_feed',
  liveFeedWeb: 'live_feed_web',
};

export const HEART_BEAT_INTERVAL = 18e4; // 3 minutes
export const ToutEvents = {
  action: 'action',
  autoReply: 'auto-reply',
  bounce: 'bounce',
  click: 'click',
  count: 'count',
  dropped: 'dropped',
  open: 'open',
  reply: 'reply',
  visit: 'visit',
};

export const EventTimes = {
  fewSecondsAgo: 'a few seconds ago',
  timestampFormat: 'MMM DD h:mm A',
};

export const LiveFeedTabsEnum = {
  engagement: {
    label: 'common.engagement',
    value: 'engagement',
  },
  target: {
    label: 'common.target',
    value: 'target',
  },
  tasks: {
    label: 'common.tasks',
    value: 'tasks',
  },
};

export const LiveFeedAnalytics = {
  daysLimit: 5,
  interval: 'day',
  scope: 'me',
};

export const AlertTypes = {
  dealWon: 'deal_won',
};

export const PopupSize = {
  call: {
    height: 330,
    width: 532,
  },
  gong: {
    height: 460,
    width: 544,
  },
  liveFeedCall: {
    height: 520,
    width: 520,
  },
};

export const UNSEEN_EVENT_INDICATOR_REMOVAL_TIME = 7e3; // 7 secs
export const DEAL_ALERT_TIMEOUT = 1e4; // 10 secs

export const ENGAGEMENT_CHART_DAYS = 5;
export const COUNT = 'count';
export const EVENT_ICONS = {
  autoReplied: 'auto_replied',
  bounced: 'bounced',
  clicked: 'clicked',
  interstingMoment: 'interesting_moment',
  replied: 'replied',
  viewed: 'viewed',
  viewedContent: 'viewed_content',
  visited: 'visited',
};
