/*
 *  Usage:
 *    Use withReadMore when you aren't sure of the total height of component being wrapped
 *      but want to initally show X height. Use ViewMore if you want to show Y items initially.
 *
 *  To use, wrap your component:
 *    export default withReadMore(options)(Component);
 *
 *  API:
 *    Options - Props passed specifically to ReadMore (see component)
 *.     - useParentProps - allows the parent of the wrapped component to dynamically
 *.       pass down props that are then merged with the options and defaultProps.
 *    Component - React Component - component to be wrapped
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReadMore from './readMore';

export default function withReadMore(readMoreOptions = {}) {
  return function WithReadMore(WrappedComponent) {
    const componentName =
      WrappedComponent.displayName || WrappedComponent.name || 'NoName';
    ReadMore.displayName = `ReadMore${componentName}`;

    const Component = (props) => {
      let options = readMoreOptions;
      if (props.useParentProps) {
        options = { ...readMoreOptions, ...props };
      }
      return (
        <ReadMore
          {...options}
          WrappedComponent={WrappedComponent}
          wrappedProps={props}
        />
      );
    };

    Component.displayName = `WithReadMore${componentName}`;
    Component.propTypes = {
      useParentProps: PropTypes.bool,
    };
    Component.defaultProps = {
      useParentProps: false,
    };

    return Component;
  };
}
