import UnsubscribeOptionsActionTypes from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsActionTypes';

export const optInReasonsItems = (state = [], { type = '', data = [] }) => {
  switch (type) {
    case UnsubscribeOptionsActionTypes.setUnsubscribeOptions:
      return data.unsubscribe_opt_in_reasons;

    default:
      return state;
  }
};
