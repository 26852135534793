import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Alert, { AlertColors, AlertLocations } from 'components/alert';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';

const CommandCenterEmailsAlert = ({
  actionCreators,
  alert: { id, values = {} },
}) => {
  const getAlertComponent = () => {
    const { redDark, blueDark } = AlertColors;

    const defaultProperties = {
      color: redDark,
      location: AlertLocations.top,
      onClose: actionCreators.closeViewAlert,
      textValues: values,
    };

    switch (id) {
      case PeopleAlertIds.campaignUpdatedNotification:
        return (
          <Alert
            html
            {...defaultProperties}
            color={blueDark}
            textId="web.people.campaignUpdatedNotification"
          />
        );
      case PeopleAlertIds.campaignRemovedNotification:
        return (
          <Alert
            html
            {...defaultProperties}
            color={blueDark}
            textId="web.people.campaignRemovedNotification"
          />
        );
      case EmailAlertIds.fetchError:
        return (
          <Alert
            {...defaultProperties}
            textId="common.errors.unexpected.body"
          />
        );
      case EmailAlertIds.archiveEmailError:
        return (
          <Alert {...defaultProperties} textId="web.email.archive.error.body" />
        );
      case EmailAlertIds.unArchiveEmailError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.email.unarchive.error.body"
          />
        );
      case EmailAlertIds.markSuccessError:
        return (
          <Alert
            html
            {...defaultProperties}
            textId="web.emails.markSuccessError"
          />
        );
      case EmailAlertIds.personForEmailNotFoundError:
        return (
          <Alert
            html
            {...defaultProperties}
            textId="web.emails.personForEmailNotFoundError"
          />
        );
      case EmailAlertIds.deleteEmailError:
        return (
          <Alert
            html
            {...defaultProperties}
            textId="web.emails.deleteEmailError"
          />
        );
      case EmailAlertIds.retrySendError:
        return (
          <Alert
            html
            {...defaultProperties}
            textId="web.emails.retrySendError"
          />
        );
      case EmailAlertIds.bulkArchiveEmailError:
        return (
          <Alert
            html
            {...defaultProperties}
            textId="web.email.bulkArchive.error.body"
          />
        );
      case EmailAlertIds.bulkUnarchiveEmailError:
        return (
          <Alert
            html
            {...defaultProperties}
            textId="web.email.bulkUnarchive.error.body"
          />
        );
      default:
        return null;
    }
  };

  return <Fragment>{getAlertComponent()}</Fragment>;
};

CommandCenterEmailsAlert.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
};

export default CommandCenterEmailsAlert;
