import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { PopupSize } from 'libs/constantsShared';
import Popup from 'components/popups';
import { getImageUrl } from 'web/libs/constants';
import { EditIdentityPopupIds } from '../libs/editIdentitySlideOutConstants';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import { discardFormChanges } from '../actionCreators/editIdentityActionCreators';

const EMPTY_POPUP = {};

class EditIdentitySlideOutPopupContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          closePopup,
        },
        dispatch
      ),
      discardFormChanges: () => dispatch(discardFormChanges(this.props.url)),
    };
  }

  render() {
    const { children, popup } = this.props;
    let object = EMPTY_POPUP;
    if (popup) {
      object = this.getGlobalPopupObject();
    }

    return (
      <div>
        {object.children ? <Popup {...object} /> : null}
        {children}
      </div>
    );
  }

  getGlobalPopupObject() {
    const { loading } = this.props;
    return { loading, getImageUrl, ...this.getMainComponent() };
  }

  getMainComponent() {
    const { loading, popup } = this.props;

    switch (popup) {
      case EditIdentityPopupIds.hasChanges:
        return {
          children: (
            <FormattedMessage id="web.editTemplateSlideOut.popup.hasChanges.body" />
          ),
          footer: {
            onPrimary: this.actionCreators.closePopup,
            onSecondary: this.actionCreators.discardFormChanges,
            primaryTextId: 'common.back',
            secondaryTextId: 'common.discard',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.editTemplateSlideOut.popup.hasChanges.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      default:
        return EMPTY_POPUP;
    }
  }
}

EditIdentitySlideOutPopupContainer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  popup: PropTypes.string.isRequired,
  url: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.popupLoading,
    popup: state.popup,
    url: state.popupData.url,
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(EditIdentitySlideOutPopupContainer);
