import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './metricList.scss';

const MetricList = ({ children, className }) => (
  <div className={classNames('metric-list', className)}>{children}</div>
);

MetricList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
};

MetricList.defaultProps = {
  className: null,
  children: [],
};

export default MetricList;
