import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import { moment } from 'languages';
import TabNav from 'components/tabNav';
import { CampaignState } from 'web/libs/constants';
import {
  CampaignDetailsEnum,
  CampaignStateLabelMap,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import './campaignDetailsHeaderNav.scss';

class CampaignDetailsHeaderNav extends Component {
  render() {
    const {
      selectedCampaign,
      selectedDetailsTab,
      onCampaignTabSelected,
      tasksCount,
      campaignsPeopleLength,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const activeCount =
      selectedCampaign.currently_active_count &&
      selectedCampaign.currently_active_count[selectedCampaign.user_id]
        ? selectedCampaign.currently_active_count[selectedCampaign.user_id]
        : campaignsPeopleLength;
    const tabList = [
      {
        value: CampaignDetailsEnum.tasks.value,
        title: formatMessage({ id: CampaignDetailsEnum.tasks.label }),
        alert: { color: 'blue', count: tasksCount },
      },
      {
        value: CampaignDetailsEnum.people.value,
        title: formatMessage({ id: CampaignDetailsEnum.people.label }),
        alert: { color: 'blue', count: activeCount },
      },
      {
        value: CampaignDetailsEnum.analytics.value,
        title: formatMessage({ id: CampaignDetailsEnum.analytics.label }),
      },
      {
        value: CampaignDetailsEnum.setup.value,
        title: formatMessage({ id: CampaignDetailsEnum.setup.label }),
      },
      {
        value: CampaignDetailsEnum.settings.value,
        title: formatMessage({ id: CampaignDetailsEnum.settings.label }),
      },
    ];

    return (
      <div className="campaigns-header-row">
        <div className="campaigns-nav">
          <TabNav
            itemWidth={130}
            list={tabList}
            onTabSelect={onCampaignTabSelected}
            selectedTabValue={selectedDetailsTab}
          />
        </div>
        <div className="campaigns-nav-info">
          <p className={this._getStatusColor(selectedCampaign.state)}>
            {this._getStatus()}
          </p>
          <p>
            <FormattedHTMLMessage
              id="common.createdBy"
              values={{
                name: this._getAuthor(selectedCampaign.user_id),
                date: moment(selectedCampaign.created_at).fromNow(),
              }}
            />
          </p>
        </div>
      </div>
    );
  }

  _getAuthor = (id) => {
    const { account } = this.props;
    const { formatMessage } = this.props.intl;
    if (account.id === id) {
      return formatMessage({ id: 'common.me' });
    }

    const teamMember = account.subscription.users.find(
      (user) => user.id === id
    );
    return teamMember && teamMember.name
      ? teamMember.name
      : formatMessage({ id: 'web.campaigns.aTeamMember' });
  };

  _getStatusColor(state) {
    switch (state) {
      case CampaignState.inProgress:
        return 'text-green';
      case CampaignState.unstarted:
      case CampaignState.paused:
      default:
        return 'text-gray-light';
    }
  }

  _getStatus = () => {
    const {
      account,
      selectedCampaign,
      intl: { formatMessage },
    } = this.props;
    const id =
      selectedCampaign.currently_active_count &&
      selectedCampaign.currently_active_count[account.id]
        ? CampaignStateLabelMap[CampaignState.inProgress]
        : CampaignStateLabelMap[CampaignState.unstarted];
    return formatMessage({ id });
  };
}

CampaignDetailsHeaderNav.propTypes = {
  account: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  onCampaignTabSelected: PropTypes.func.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  selectedDetailsTab: PropTypes.string.isRequired,
  tasksCount: PropTypes.number.isRequired,
  campaignsPeopleLength: PropTypes.number.isRequired,
};

export default injectIntl(CampaignDetailsHeaderNav);
