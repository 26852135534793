import queryString from 'query-string';
import unescape from 'lodash/unescape';
import { Urls } from 'web/libs/constants';
import { getMetaTagContentValue } from 'web/libs/commonHelper';

export const getLoginSalesforceLink = ({
  isGmailMode,
  isOwaMode,
  isSFSandboxAuthenticationEnabled,
}) => {
  if (isOwaMode) {
    return Urls.owaConnectAdminToSalesforceNewUI;
  }
  if (isGmailMode) {
    return Urls.gmailConnectToSalesforceUI;
  }
  const salesforceBaseUrl = isSFSandboxAuthenticationEnabled
    ? Urls.connectToSalesforceSandbox
    : Urls.loginSalesforce;
  return (
    salesforceBaseUrl +
    (window.originalUrl ? `?redirect_path=${window.originalUrl}` : '')
  );
};

export const isMsiActionLoginError = () =>
  getMetaTagContentValue('msi_action_login_error') === 'true';

export const getMsiActionUserName = () => {
  const unescapeOriginalUrl = unescape(window.originalUrl);
  const parsedUrl = queryString.parseUrl(unescapeOriginalUrl);
  const { user_name } = parsedUrl.query;
  return user_name;
};
