import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Action, { ButtonColors } from 'components/buttons/action';
import Notification from 'components/notification';
import './bccForm.scss';

const BccForm = (props) => {
  const {
    integration: { bcc },
    isSyncViaApi,
    disabled,
    openActivateBccConfirmationPopup,
  } = props;
  const isActivated = !!bcc && !isSyncViaApi;

  return (
    <div className="salesforce-bcc-form">
      <div className="description-block">
        <p>
          <FormattedHTMLMessage id="web.settings.accountSettings.salesforce.syncSettings.bcc.description" />
        </p>
        {!isActivated && (
          <Notification messageId="web.settings.accountSettings.salesforce.syncSettings.bcc.notification" />
        )}
        <p>
          <FormattedHTMLMessage id="web.settings.accountSettings.salesforce.syncSettings.bcc.learnMore" />
        </p>
      </div>
      {!disabled && (
        <div className="right">
          {isActivated ? (
            <Action disabled color={ButtonColors.gray}>
              <FormattedMessage id="common.activated" />
            </Action>
          ) : (
            <Action
              disabled={disabled}
              color={ButtonColors.green}
              onClick={openActivateBccConfirmationPopup}
            >
              <FormattedMessage id="common.activate" />
            </Action>
          )}
        </div>
      )}
    </div>
  );
};

BccForm.propTypes = {
  openActivateBccConfirmationPopup: PropTypes.func.isRequired,
  integration: PropTypes.object,
  isSyncViaApi: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

BccForm.defaultProps = {
  integration: {},
  disabled: false,
};

export default BccForm;
