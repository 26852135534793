/*
 *  Notes:
 *    Use ViewMore when you want to show X amount of items initially. Use withReadMore if you
 *      are wrapping an unknown component and initally showing Y height.
 *    Defaults to use TextButton but you can pass Action or Icon and state is still
 *      maintained. Can also pass a buttonRenderer function but you will want to follow state.
 *    Prop itemRenderer must pass key as prop in rendered component
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import TextButton from 'components/buttons/text';
import Collapse, { Timeouts } from 'components/collapse';
import './viewMore.scss';

class ViewMore extends Component {
  state = {
    showAll: false,
  };

  componentWillMount() {
    if (this.props.showAll) {
      this.setState({ showAll: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showAll !== nextProps.showAll) {
      this.setState({ showAll: nextProps.showAll });
    }

    if (nextProps.items.length > this.props.items.length) {
      this.setState({ showAll: false });
    }
  }

  render() {
    const { buttonRight, className, items, maxHeight, shownCount } = this.props;
    const { showAll } = this.state;

    return (
      <div className={classNames('view-more', className)}>
        <div
          className={classNames({
            'view-more-items-wrapper-scroll': maxHeight > 0,
          })}
          style={this._getStyle()}
        >
          {this._renderItems(items, 0, shownCount)}
          <Collapse
            in={showAll}
            timeoutEnter={Timeouts.twoFifty}
            timeoutExit={Timeouts.twoFifty}
          >
            <div>{this._renderItems(items, shownCount)}</div>
          </Collapse>
        </div>
        {items.length - shownCount > 0 && (
          <div
            className={classNames('view-more-button-wrapper', {
              'button-right': buttonRight,
            })}
          >
            {this._renderButton()}
          </div>
        )}
      </div>
    );
  }

  _getStyle = () => {
    const { maxHeight } = this.props;
    return (maxHeight && { maxHeight: `${maxHeight}px` }) || null;
  };

  _renderItems = (items, start, end) => {
    const { getKey, itemRenderer } = this.props;

    return items.slice(start, end).map((item, index) => {
      if (itemRenderer) {
        return itemRenderer(item, index + start);
      }
      return (
        <span key={`view-more-key-${getKey(item, index + start)}`}>{item}</span>
      );
    });
  };

  _renderButton = () => {
    const {
      ButtonComponent,
      buttonRenderer,
      itemName,
      items,
      showCount,
      showLessTextId,
      showMoreTextId,
      shownCount,
    } = this.props;
    const { showAll } = this.state;

    if (buttonRenderer) {
      return buttonRenderer(showAll);
    }
    const values = {
      count: items.length - shownCount,
      name: itemName,
      showCount,
      showName: itemName.length > 0,
    };

    return (
      <ButtonComponent className="margin-top-half" onClick={this.toggleState}>
        <FormattedHTMLMessage
          id={(showAll && showLessTextId) || showMoreTextId}
          values={values}
        />
      </ButtonComponent>
    );
  };

  toggleState = () => {
    const nextState = !this.state.showAll;
    this.setState(() => ({ showAll: nextState }));
    this.props.viewMoreOnClick(nextState);
  };
}

ViewMore.propTypes = {
  ButtonComponent: PropTypes.func,
  buttonRenderer: PropTypes.func,
  buttonRight: PropTypes.bool,
  className: PropTypes.string,
  getKey: PropTypes.func,
  itemName: PropTypes.string,
  itemRenderer: PropTypes.func,
  items: PropTypes.array.isRequired,
  maxHeight: PropTypes.number,
  showAll: PropTypes.bool,
  showCount: PropTypes.bool,
  showLessTextId: PropTypes.string,
  showMoreTextId: PropTypes.string,
  shownCount: PropTypes.number,
  viewMoreOnClick: PropTypes.func,
};

ViewMore.defaultProps = {
  ButtonComponent: TextButton,
  buttonRenderer: undefined,
  buttonRight: false,
  className: '',
  getKey: ({ id }, index) => `view-more-key-${id}-${index}`,
  itemName: '',
  itemRenderer: null,
  maxHeight: 0,
  showAll: false,
  showCount: true,
  showLessTextId: 'viewMore.showLess',
  showMoreTextId: 'viewMore.showMore',
  shownCount: 2,
  viewMoreOnClick: () => {},
};

export default ViewMore;
