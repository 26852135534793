import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import Checkbox from 'components/buttons/checkbox';
import { canEditCampaign } from 'web/campaigns/campaignsTab/services/campaignService';
import './campaignSettingsGeneral.scss';

class CampaignSettingsGeneral extends Component {
  render() {
    const {
      classes,
      intl: { formatMessage },
      selectedCampaign,
    } = this.props;

    return (
      <div className={`campaignSettingsGeneral${classes ? ` ${classes}` : ''}`}>
        <Checkbox
          checked={selectedCampaign.workflow_setting.skip_weekends}
          classes="margin-top-full"
          disabled={!canEditCampaign(selectedCampaign)}
          label={formatMessage({ id: 'web.campaigns.skipWeekends' })}
          name="skip_weekends"
          onChange={this.onCheckboxChange}
        />
        <div className="bounce-explanation">
          <FormattedHTMLMessage id="web.campaigns.bounceExplanation" />
          <Checkbox
            checked={selectedCampaign.workflow_setting.stop_on_bounce}
            classes="margin-top-full"
            disabled={!canEditCampaign(selectedCampaign)}
            label={formatMessage({ id: 'web.campaigns.removeOnBounce' })}
            name="stop_on_bounce"
            onChange={this.onCheckboxChange}
          />
        </div>
      </div>
    );
  }

  onCheckboxChange = (checked, name) => {
    const { selectedCampaign, actionCreators } = this.props;
    actionCreators.updateSettings(selectedCampaign, { [name]: checked });
  };
}

CampaignSettingsGeneral.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  classes: PropTypes.string,
  intl: intlShape.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
};

CampaignSettingsGeneral.defaultProps = {
  classes: undefined,
};

export default injectIntl(CampaignSettingsGeneral);
