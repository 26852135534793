import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import SlideOutsActionTypes from 'web/slideOuts/libs/slideOutsActionTypes';
import {
  EditTemplateAnalyticsDatesSelect,
  EditTemplateAnalyticsTeamsSelect,
} from '../libs/editTemplateSlideOutConstants';

export function editTemplateSlideOutAnalytics(
  state = {},
  { type = '', analytics = {} }
) {
  switch (type) {
    case EditTemplateActionTypes.setAnalytics:
      return analytics;
    case EditTemplateActionTypes.close:
    case SlideOutsActionTypes.close:
      return {};
    default:
      return state;
  }
}

export const initAnalyticsViewState = {
  datesSelect: EditTemplateAnalyticsDatesSelect.lastTwelveWeeks,
  teamsSelect: EditTemplateAnalyticsTeamsSelect.include,
};
export function editTemplateSlideOutAnalyticsViewState(
  state = initAnalyticsViewState,
  {
    type = '',
    datesSelect = EditTemplateAnalyticsDatesSelect.lastTwelveWeeks,
    teamsSelect = EditTemplateAnalyticsTeamsSelect.team,
  }
) {
  switch (type) {
    case EditTemplateActionTypes.setAnalyticsDatesSelect:
      return { ...state, datesSelect };
    case EditTemplateActionTypes.setAnalyticsTeamsSelect:
      return { ...state, teamsSelect };
    case EditTemplateActionTypes.initAnalyticsView:
    case EditTemplateActionTypes.close:
    case SlideOutsActionTypes.close:
      return initAnalyticsViewState;
    default:
      return state;
  }
}
