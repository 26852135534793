import { LeadFieldNames } from 'web/people/libs/peopleConstants';

export const isMarketoLead = ({ leadFields = [] } = {}) => {
  return leadFields.some(
    (leadField) => leadField.name === LeadFieldNames.marketoLeadId
  );
};

export const getMarketoLeadId = ({ leadFields = [] } = {}) => {
  const marketoIdLeadField = leadFields.find(
    (leadField) => leadField.name === LeadFieldNames.marketoLeadId
  );

  return marketoIdLeadField ? marketoIdLeadField.value : null;
};
