import I18N from 'languages';

export const GeneralPageAlertIds = {
  disableConsentMessageError: 'RECORDING_MANAGER_DISABLE_MESSAGE_ERROR',
  enableCallRecording: 'ENABLE_CALL_RECORDING',
  enableConsentMessageError: 'RECORDING_MANAGER_ENABLE_MESSAGE_ERROR',
  fileUploadBigSizeError: 'GENERAL_PAGE_FILE_UPLOAD_BIG_SIZE_ERROR',
  fileUploadError: 'GENERAL_PAGE_FILE_UPLOAD_ERROR',
  formChangesNotSaved: 'GENERAL_PAGE_FORM_DATA_NOT_SAVED',
  formChangesSaved: 'GENERAL_PAGE_FORM_DATA_SAVED_SUCCESSFULLY',
  validationError: 'GENERAL_PAGE_FORM_VALIDATION_ERROR',
};

export const GeneralTabs = {
  globalSettings: 'global-settings',
  teamDeliveryChannel: 'team-delivery-channel',
};

export const HIGHSPOT_VALUE = 'highspot';

export const TEAM_EMAIL_LIMIT_FORM_ID = 'team-email-limit-form-id';
export const TEAM_CAMPAIGN_LIMIT_FORM_ID = 'team-campaign-limit-form-id';
export const TEAM_EMAIL_THROTTLING_LIMIT_FORM_ID =
  'team-email-throttling-limit-form-id';

export const AUTO_APPEND_FORM_ID = 'auto-append-form-id';
export const AUTO_APPEND_FIELD_ID = 'auto_append_content';
export const AUTO_APPEND_POPUP_ID = 'auto-append-popup-id';

export const SALES_PHONE_CALL_RECORDING = 'call_recording_enabled';
export const SALES_PHONE_FORCE_CALL_RECORDING = 'force_call_recording';
export const SALES_PHONE_CALL_RECORDING_NOTICE =
  'call_recording_notice_enabled';

export const MIN_TEAM_EMAIL_LIMIT = 1;
export const MIN_TEAM_CAMPAIGN_LIMIT = 1;

export const EMAIL_THROTTLING_TIME_UNITS = [
  { label: I18N.getStr('common.minutes'), value: 'min' },
  { label: I18N.getStr('common.seconds'), value: 'sec' },
];

export const EMAIL_THROTTLING_SOURCE_TYPES = {
  auto: 'auto',
  manual: 'manual',
};

export const LOGIN_METHODS = {
  all: 'all',
  salesforceOnly: 'salesforce_only',
};
