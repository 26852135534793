import timezoneSettingsActionTypes from '../libs/timezoneSettingsActionTypes';

const initialState = {
  pending: false,
};

export function timezoneSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case timezoneSettingsActionTypes.setLoading: {
      const { pending } = action;
      return {
        ...state,
        pending,
      };
    }
    default:
      return state;
  }
}
