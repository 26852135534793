import { Urls } from 'web/libs/routes';
import { Roles } from '../libs/marketoSettingsConstants';
import { baseGet, basePost, baseDelete, basePut } from 'web/services/api';
import { getEAU } from './authHelpers';

export const getIntegration = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.marketoIntegrationConnect, {}, resolve, reject);
  });

export const connectIntegration = ({
  currentUserId,
  clientId = '',
  clientSecret = '',
  munchkinId = '',
}) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.marketoIntegrationConnect,
      {
        authentication: {
          client_id: clientId.trim(),
          client_secret: clientSecret.trim(),
          munchkin_id: munchkinId.trim(),
          access_role: Roles.engageAdmin,
          user_id: currentUserId,
        },
      },
      resolve,
      reject
    );
  });

export const disconnectIntegration = (userId) =>
  getIntegration().then((authentications = []) => {
    const eau = getEAU(authentications, userId);
    return new Promise((resolve, reject) => {
      baseDelete(
        Urls.marketoIntegrationConnect,
        { id: eau.id },
        resolve,
        reject
      );
    });
  });

export const getSubscriptionUsers = (everyoneTeamId) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.subscriptionUsers.replace(':#id', everyoneTeamId),
      {},
      resolve,
      reject
    );
  });

export const grantAccess = (userIds, workspaces) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.marketoBulkAction,
      {
        authentication: {
          access_role: Roles.salesUser,
          user_ids: userIds,
          workspaces,
          bulk_action: 'bulk_create',
        },
      },
      resolve,
      reject
    );
  });

export const revokeAccess = (userIds) =>
  new Promise((resolve, reject) => {
    baseDelete(
      Urls.marketoBulkAction,
      {
        authentication: {
          access_role: Roles.salesUser,
          user_ids: userIds,
          bulk_action: 'bulk_destroy',
        },
      },
      resolve,
      reject
    );
  });

export const bulkUpdateAsync = (userIds, workspaces, bulk_action) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.marketoBulkActionAsync,
      {
        authentication: {
          access_role: Roles.salesUser,
          user_ids: userIds,
          workspaces,
          bulk_action,
        },
      },
      resolve,
      reject
    );
  });

export const getWorkspaces = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.marketoWorkspaces, {}, resolve, reject);
  });
