import PeopleActionTypes from '../libs/peopleActionTypes';

export const peopleCurrentGroupId = (state = '', { type, groupId }) => {
  switch (type) {
    case PeopleActionTypes.sidebar.setPeopleCurrentGroup:
      return groupId || '';
    default:
      return state;
  }
};
