import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';
import Switch from 'components/buttons/switch';
import { updateUnsubscribeSettings } from 'web/settings/unsubscribes/actionCreators/settingsUnsubscribesActionCreators';
import { saveAdminUnsubscribes } from '../actionCreators/adminUnsubscribesIntegrationsActionCreators';
import SettingsUnsubscribesConstants from 'web/settings/unsubscribes/libs/settingsUnsubscribesConstants';
import {
  EnableSyncSalesforceLabels,
  SyncToSalesforceItems,
  HAS_OPTED_OUT_OF_EMAIL_KEY,
} from '../libs/adminUnsubscribesConstants';

class EnableSyncSalesforceContainer extends Component {
  render() {
    const { enableUnsubscribeSync } = this.props;
    const label = enableUnsubscribeSync
      ? EnableSyncSalesforceLabels.enabled
      : EnableSyncSalesforceLabels.disabled;

    return (
      <Switch
        label={label}
        checked={enableUnsubscribeSync}
        onChange={this.onChange}
        name="enableSyncSalesforce"
      />
    );
  }

  onChange = (isChecked) => {
    const { onChange, save } = this.props;
    const settings = {
      enable_unsubscribe_sync: isChecked,
    };

    if (!isChecked) {
      settings.salesforce_sync_field =
        SyncToSalesforceItems[HAS_OPTED_OUT_OF_EMAIL_KEY].value;
    }

    onChange(settings);
    save(
      SettingsUnsubscribesConstants.alertIds.saveUnsubscribeIntegrationsSuccess
    );
  };
}

EnableSyncSalesforceContainer.propTypes = {
  enableUnsubscribeSync: PropsTypes.bool.isRequired,
  onChange: PropsTypes.func.isRequired,
  save: PropsTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  enableUnsubscribeSync: state.settingsUnsubscribes.enable_unsubscribe_sync,
});

const mapDispatchToProps = {
  onChange: updateUnsubscribeSettings,
  save: saveAdminUnsubscribes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnableSyncSalesforceContainer);
