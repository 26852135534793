import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'web/liveFeed/components/liveFeedCardHeader';
import Info from 'web/liveFeed/components/contactInfo';
import ConvertActions from '../marketoConvertActions';

class MarketoHeader extends Component {
  render() {
    const {
      actionCreators: {
        openContact,
        openCompany,
        openAddToCampaign,
        openCall,
        openEmail,
        openLinkedInSearch,
        openSalesforceContact,
      },
      bestBet,
      bestBet: {
        company,
        title,
        id,
        firstName,
        lastName,
        salesforceId,
        salesforceContactIsLead,
        leadScore,
        unsubscribed,
      },
      salesforceInstanceUrl,
      isLoading,
      showMarketoBorder,
      targetTab,
    } = this.props;

    return (
      <Header
        bestBet={bestBet}
        company={company}
        cardId={`marketo-card-header-${id}`}
        contactId={id}
        toggleOpenChecks={this.toggleOpenChecks}
        openPdv={this._openPdv}
        infoChildren={
          <Info company={company} title={title} openCompany={openCompany} />
        }
        score={leadScore}
        name={`${firstName} ${lastName}`}
        openChildren={
          <ConvertActions
            bestBet={bestBet}
            isLoading={isLoading}
            openAddToCampaign={openAddToCampaign}
            openCall={openCall}
            openEmail={openEmail}
            openLinkedInSearch={openLinkedInSearch}
            openSalesforceContact={openSalesforceContact}
            salesforceId={salesforceId}
            salesforceContactIsLead={salesforceContactIsLead}
            salesforceInstanceUrl={salesforceInstanceUrl}
          />
        }
        openContact={openContact}
        showMarketoBorder={showMarketoBorder}
        targetTab={targetTab}
        unsubscribed={unsubscribed}
      />
    );
  }

  toggleOpenChecks = () => {
    const {
      bestBet,
      actionCreators: { cardToggleOpenChecks },
    } = this.props;
    cardToggleOpenChecks(bestBet);
  };

  _openPdv = () => {
    const {
      bestBet,
      actionCreators: { openPdvForMarketoLead },
    } = this.props;
    openPdvForMarketoLead(bestBet);
  };
}

MarketoHeader.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  bestBet: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showMarketoBorder: PropTypes.bool,
  targetTab: PropTypes.bool,
};

MarketoHeader.defaultProps = {
  targetTab: false,
  showMarketoBorder: false,
};

export default MarketoHeader;
