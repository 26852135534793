import has from 'lodash/has';
import { track } from 'web/services/mixpanelService';

export const mixpanel = () => (next) => (action) => {
  if (has(action, ['meta', 'analytics'])) {
    const { event, properties } = action.meta.analytics;
    track(event, properties);
  }

  return next(action);
};

export default mixpanel;
