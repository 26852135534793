import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import Collapse, { Timeouts } from 'components/collapse';
import './anchorBox.scss';

class AnchorBox extends Component {
  render() {
    const {
      anchorLocation,
      backgroundColor,
      children,
      className,
      header,
      headerClick,
      headerColor,
      open,
    } = this.props;

    const topClassName = classNames('anchor-box-top', anchorLocation, {
      [`background-${headerColor}`]: headerColor,
      'hover-pointer': headerClick,
    });
    const innerClassName = classNames('anchor-box-inner', anchorLocation, {
      [`background-${backgroundColor}`]: backgroundColor,
    });

    return (
      <div className={classNames('anchor-box', className, anchorLocation)}>
        <div
          className={topClassName}
          onClick={headerClick}
          style={this._getTopStyles()}
        >
          {header}
        </div>
        <Collapse
          in={open}
          timeoutEnter={Timeouts.twoFifty}
          timeoutExit={Timeouts.twoFifty}
          style={this._getInnerStyles()}
          className={classNames('anchor-box', anchorLocation)}
        >
          <div className={innerClassName}>{children}</div>
        </Collapse>
      </div>
    );
  }

  _getTopStyles = () => {
    const { headerHeight, width } = this.props;

    return {
      minHeight: headerHeight,
      width: `${width}px`,
    };
  };

  _getInnerStyles = () => {
    const { width } = this.props;

    return {
      width: `${width}px`,
    };
  };
}

export const BoxAnchorLocations = {
  right: 'anchor-right',
  left: 'anchor-left',
};

export const BoxBackgroundColors = BackgroundColors;

AnchorBox.propTypes = {
  anchorLocation: PropTypes.oneOf(Object.values(BoxAnchorLocations)),
  backgroundColor: PropTypes.oneOf(Object.values(BoxBackgroundColors)),
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.node,
  headerClick: PropTypes.func,
  headerColor: PropTypes.oneOf(Object.values(BoxBackgroundColors)),
  headerHeight: PropTypes.number,
  open: PropTypes.bool,
  width: PropTypes.number,
};

AnchorBox.defaultProps = {
  anchorLocation: BoxAnchorLocations.right,
  backgroundColor: BoxBackgroundColors.white,
  children: <div />,
  className: '',
  headerClick: () => {},
  header: <div />,
  headerColor: BoxBackgroundColors.blue,
  headerHeight: 55,
  open: false,
  width: 500,
};

export default AnchorBox;
