export function buildSelectOptionsForOpportunities(opportunities = []) {
  return opportunities.map((opportunity) => ({
    label: opportunity.name,
    value: opportunity.id,
  }));
}

export function prepareParams(params, currentState) {
  params.deal.user_id = currentState.user.id;
  return params;
}

export function parseErrors(error = { response: {} }) {
  const errors = error.response.body || {};
  return errors;
}
