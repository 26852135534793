import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import {
  navigateToEmailSettingsTab,
  navigateToNewIdentitySlideout,
} from 'web/services/routerService';
// TODO: maybe try move to table
import EditIdentitySlideOutContainer from 'web/slideOuts/editIdentity/containers/editIdentitySlideOutContainer';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import EmailConnectionFlowContainer from 'web/settings/emailConnection/containers/emailConnectionFlowContainer';
import EmailSettings from '../components/emailSettings';
import './emailSettingsContainer.scss';

class EmailSettingsContainer extends Component {
  render() {
    return (
      <div className="no-header email-settings-container">
        <EditIdentitySlideOutContainer />
        <EmailSettings
          {...this.props}
          onTabSelect={this.onTabSelect}
          onAddIdentity={this.onAddIdentity}
        />
        <EmailConnectionFlowContainer />
      </div>
    );
  }

  onTabSelect = (tab) => {
    navigateToEmailSettingsTab(tab);
  };

  onAddIdentity = () => {
    navigateToNewIdentitySlideout();
  };
}

EmailSettingsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  tab: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  tab: state.emailSettingsTab,
});

export default compose(
  injectIntl,
  withCommonModals,
  connect(mapStateToProps)
)(EmailSettingsContainer);
