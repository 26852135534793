import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { isCurrentUser, isSameUser } from 'web/user/services/userService';
import SubjectStats from 'web/emails/components/subjectStats';
import Body from 'web/emails/components/readMoreBody';
import SentLastEvent from 'web/emails/components/sentLastEvent';
import './templateEmailCard.scss';

class TemplateEmailCard extends Component {
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;
    this.groupTitle = formatMessage({
      id: 'web.editTemplateSlideOut.emailCard.groupIcon',
    });
    this.campaignTitle = formatMessage({
      id: 'web.editTemplateSlideOut.emailCard.campaignIcon',
    });
    this.unknownIdentityText = formatMessage({
      id: 'web.editTemplateSlideOut.emailCard.unknownIdentity',
    });
  }

  render() {
    const {
      addAsTemplate,
      archiveEmail,
      email: {
        archived,
        body,
        campaignId,
        clicks,
        deliveredAt,
        id,
        lastEventAt,
        parentPitchId,
        pitchState,
        replies,
        subject,
        succeeded,
        views,
        userId,
      },
      openEvents,
      openFollowUp,
      unarchiveEmail,
      user,
    } = this.props;

    return (
      <div className="template-email-card">
        <SubjectStats
          addAsTemplate={addAsTemplate}
          archiveEmail={archiveEmail}
          archived={archived}
          className="margin-bottom-quarter"
          clicks={clicks}
          id={id}
          openEvents={openEvents}
          openFollowUp={openFollowUp}
          replies={replies}
          pitchState={pitchState}
          subject={subject}
          succeeded={succeeded}
          unarchiveEmail={unarchiveEmail}
          user={user}
          userId={userId}
          views={views}
        />
        <div className="email-card-row margin-bottom-full email-card-addresses">
          {this._getAddresses()}
          {parentPitchId > 0 && (
            <div className="tout-icon-group" title={this.groupTitle} />
          )}
          {campaignId > 0 && (
            <div className="tout-icon-campaign" title={this.campaignTitle} />
          )}
        </div>
        <Body body={body} />
        <SentLastEvent
          className="margin-top-half"
          deliveredAt={deliveredAt}
          lastEventAt={lastEventAt}
        />
      </div>
    );
  }

  _getAddresses = () => {
    const {
      email: {
        email,
        identity: { email: identityEmail, name: identityName } = {
          email: '',
          name: '',
        },
        name,
        userId,
      },
      user,
      viewer,
    } = this.props;
    return (
      <FormattedHTMLMessage
        id="web.editTemplateSlideOut.emailCard.addresses"
        values={{
          email,
          identityEmail,
          identityName:
            identityName || identityEmail || this.unknownIdentityText,
          isUser: isCurrentUser(user, userId) && isSameUser(user, viewer),
          name: name || email,
        }}
      />
    );
  };
}

TemplateEmailCard.propTypes = {
  addAsTemplate: PropTypes.func.isRequired,
  archiveEmail: PropTypes.func.isRequired,
  email: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  openEvents: PropTypes.func.isRequired,
  openFollowUp: PropTypes.func.isRequired,
  unarchiveEmail: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
};

TemplateEmailCard.defaultProps = {};

export default injectIntl(TemplateEmailCard);
