import React from 'react';
import Pill from 'components/pill';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MiniPDVContainer from 'web/composeWindow/containers/miniPDVContainer';
import { BackgroundColors, TextColors } from 'libs/constantsStyles';
import { BulkEmails } from 'web/composeWindow/libs/composeWindowConstants';

export default class AddressFieldItemPill extends React.Component {
  state = {
    showDetails: false,
  };

  closeDetails = () => {
    this.setState({ showDetails: false });
  };

  toggleDetails = () => {
    this.setState((state) => ({
      showDetails: !state.showDetails,
    }));
  };

  handleClick = () => {
    const { address } = this.props;
    if (address.validated && address.person) {
      this.toggleDetails();
    }
  };

  isUnsubscribed = (address) =>
    address.salesUnsubscribe ||
    address.marketingUnsubscribe ||
    (address.state && address.state !== BulkEmails.stateReason.success);

  shouldWarn = () => {
    const { address, isBypassUnsubscribe } = this.props;

    if (address.domainBlocked) {
      return true;
    }

    if (isBypassUnsubscribe) {
      return false;
    } else {
      return this.isUnsubscribed(address);
    }
  };

  render() {
    const { address, loading, className, handleClose } = this.props;
    const classnames = classNames(className, 'pill', {
      errored: address.invalid,
      loading,
      warning: this.shouldWarn(),
    });

    return (
      <React.Fragment>
        <div>
          <Pill
            className={classnames}
            color={BackgroundColors.grayExtraLight}
            id={address.email}
            onClick={this.handleClick}
            onClose={handleClose}
            text={address.email}
            textColor={TextColors.black}
            textId={address.replacementTextId}
          />
          {this.state.showDetails &&
            address && (
              <MiniPDVContainer
                address={address}
                closeDetails={this.closeDetails}
              />
            )}
        </div>
      </React.Fragment>
    );
  }
}

AddressFieldItemPill.propTypes = {
  address: PropTypes.object.isRequired,
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  isBypassUnsubscribe: PropTypes.bool,
  loading: PropTypes.bool,
};

AddressFieldItemPill.defaultProps = {
  className: '',
  isBypassUnsubscribe: false,
  loading: true,
};
