import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { childrenProptype } from './proptypes';

const AccordionItem = ({ children, className, ...otherParams }) => {
  const classes = classNames('tout-accordion-item', className);
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { ...otherParams })
  );

  return <div className={classes}>{childrenWithProps}</div>;
};

AccordionItem.defaultProps = {
  children: [],
  className: '',
  collapseOptions: {},
  disabledChevron: false,
  onToggle: () => {},
  isOpen: false,
};

AccordionItem.propTypes = {
  children: childrenProptype,
  className: PropTypes.string,
  disabledChevron: PropTypes.bool,
  collapseOptions: PropTypes.object,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default AccordionItem;
