import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import AccountDetails from './accountDetails';
import { changeAccountDetails } from '../actionCreators/accountDetailsActionCreators';
import {
  parseFormValues,
  setFirstNameFromName,
} from '../helpers/accountDetailsHelpers';
import {
  getUserFirstName,
  getUserLastName,
  getUserEmail,
  getUserName,
} from 'web/user/selectors/userSelectors';
import { accountDetailsFormName } from '../libs/accountDetailsConstants';

import './accountDetailsContainer.scss';

const AccountDetailsContainer = (props) => {
  const {
    handleSubmit,
    changeAccountDetails,
    pending,
    initialValues,
    ...rest
  } = props;

  const [showPasswords, setShowPasswords] = useState(false);

  const resetForm = () => {
    // reset form fields and uncheck showPasswords checkbox
    rest.reset();
    setShowPasswords(false);
  };

  const onFormSubmit = (values) => {
    changeAccountDetails(
      {
        user: setFirstNameFromName(parseFormValues(values), initialValues.name),
      },
      resetForm
    );
  };

  return (
    <React.Fragment>
      <h3 className="my-profile-account-details-title">
        <FormattedMessage id="web.settings.myProfile.accountDetails.header" />
      </h3>
      <AccountDetails
        {...rest}
        onFormSubmit={handleSubmit(onFormSubmit)}
        pending={pending}
        resetForm={resetForm}
        setShowPasswords={setShowPasswords}
        showPasswords={showPasswords}
      />
    </React.Fragment>
  );
};

AccountDetailsContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: {
    current_password: '',
    email: getUserEmail(state),
    first_name: getUserFirstName(state) || getUserName(state),
    last_name: getUserLastName(state) || '',
    password: '',
    password_confirmation: '',
  },
  pending: state.accountDetails.pending,
});

const mapDispatchToProps = {
  changeAccountDetails,
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({ enableReinitialize: true, form: accountDetailsFormName })
)(AccountDetailsContainer);
