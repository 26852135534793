import { updateAdminSettingsAndShowAlert } from './contentPartnerActionCreators';

export const updateLoginMethod = (loginMethod) => (dispatch) => {
  const adminSettings = {
    admin_settings: {
      data: {
        login_methods: loginMethod,
      },
    },
  };

  return dispatch(updateAdminSettingsAndShowAlert(adminSettings));
};
