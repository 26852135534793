import { moment } from 'languages';
import { SourceOpenerIds, SourceOpener } from 'libs/constantsShared';
import  { postMessageToOpenerWindow, postMessageToParentWindow } from 'web/services/windowService';
import { WindowEventNames } from 'libs/constantsShared';
import I18N from 'languages';

export function saveTaskWindow(reminder, sourceOpener) {

  postMessageToOpenerWindow({
    eventType: 'save',
    modelType: 'reminder',
    reminder,
  });

  if (sourceOpener === SourceOpener.salesforce) {
    postMessageToParentWindow({action: WindowEventNames.saveTask});
  }

  if (
    window.external &&
    window.external.reminderData &&
    sourceOpener === SourceOpenerIds.outlook
  ) {
    window.external.reminderData(
      `${reminder.id}:${moment(reminder.remind_at).format(
        I18N.getStr('web.customDatePattern.dayMonthDate')
      )}`
    );
  }
}

export function deleteTaskWindow(sourceOpener) {
  postMessageToOpenerWindow({
    eventType: 'destroy',
    modelType: 'reminder',
  });

  if (sourceOpener === SourceOpener.salesforce) {
    postMessageToParentWindow({action: WindowEventNames.deleteTask});
  }

  if (
    window.external &&
    window.external.reminderData &&
    sourceOpener === SourceOpenerIds.outlook
  ) {
    window.external.reminderData('');
  }
}

export function closeTaskWindow(sourceOpener) {
  if (sourceOpener === SourceOpener.salesforce) {
    postMessageToParentWindow({action: WindowEventNames.close});
  }
}
