import {
  updateCampaign as updateCampaignCall,
  deleteCampaign as deleteCampaignCall,
  getActivePeople,
  getCampaignCurrentUsers,
} from '../services/campaignService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import {
  navigateToCampaignCategory,
  navigateToCampaignDetailsTab,
} from 'web/services/routerService';
import { track } from 'web/services/mixpanelService';
import { openCampaignAlert } from 'web/campaigns/campaignsTab/actionCreators/campaignsAlertActionCreators';
import {
  CampaignTabEvents,
  CampaignActionEvents,
} from 'web/libs/mixpanelEvents';
import { setContentLoading } from 'web/campaigns/campaignsTab/actionCreators/campaignsActionCreators';
import {
  GlobalAlertIds,
  GlobalPopupIds,
  CampaignDetailsEnum,
  CampaignManagementActions,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import {
  openPopup,
  closePopup,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { parseTeamUsingCampaign } from 'web/campaigns/campaignsTab/helpers/parsers';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import find from 'lodash/find';
import { getSelectedCampaign } from 'web/reducers/campaigns/campaignSelectors';

export function selectCampaign(data = null) {
  return {
    type: ActionTypes.campaignsTab.data.campaignSelected,
    data,
  };
}

export function updateCampaignDetailsTab(data) {
  return {
    type: ActionTypes.campaignsTab.state.selectedDetailsTab,
    data,
  };
}

export function selectCampaignDetailsTab(data) {
  return (dispatch, getState) => {
    const {
      campaignsTabSelectedCampaign: selectedCampaign,
      currentCategory: selectedCategory,
    } = getState();
    const tab = CampaignDetailsEnum[data].value;
    track(
      CampaignTabEvents.clicked(tab.charAt(0).toUpperCase() + tab.slice(1))
    );
    navigateToCampaignDetailsTab(selectedCategory, selectedCampaign, tab);
  };
}

export function editCampaignName() {
  return {
    type: ActionTypes.state.editCampaignName,
  };
}

export function notEditingCampaignName() {
  return {
    type: ActionTypes.state.notEditingCampaignName,
  };
}

export function updateCampaign(updatedCampaign, save) {
  return (dispatch, getState) => {
    const state = getState();
    const prevCampaignData = getSelectedCampaign(state);

    dispatch({
      type: ActionTypes.common.data.campaignUpdate,
      data: updatedCampaign,
    });

    if (save) {
      updateCampaignCall(updatedCampaign)
        .then((campaignData) => {
          toutBackboneHelper.campaignUpdate(campaignData);
        })
        .catch(() => {
          dispatch(openCampaignAlert(GlobalAlertIds.campaignNameUniqueAlert));
          dispatch(editCampaignName());
          dispatch({
            type: ActionTypes.common.data.campaignUpdate,
            data: prevCampaignData,
          });
        });
    }
  };
}

export function deleteCampaign() {
  return (dispatch, getState) => {
    const { campaigns, campaignsTabSelectedCampaign } = getState();
    const selectedCampaign = find(campaigns, {
      id: campaignsTabSelectedCampaign,
    });
    dispatch(setPopupLoading(true));
    deleteCampaignCall(campaignsTabSelectedCampaign)
      .then(() => {
        dispatch({
          type: ActionTypes.common.data.campaignDelete,
          id: campaignsTabSelectedCampaign,
        });
        dispatch(closePopup());
        navigateToCampaignCategory(selectedCampaign.category_id);
        toutBackboneHelper.campaignDelete(selectedCampaign.id);
        track(CampaignActionEvents.manageCampaign, {
          'Action Type': CampaignManagementActions.delete,
        });
      })
      .catch(() => {
        dispatch(openPopup(GlobalPopupIds.campaignActiveWarning));
        dispatch(setPopupLoading(false));
      });
  };
}

export function deleteCampaignConfirm() {
  return (dispatch, getState) => {
    const {
      user,
      userSubscriptionUsers,
      campaignsTabSelectedCampaign,
    } = getState();

    dispatch(setContentLoading(true));
    getActivePeople(campaignsTabSelectedCampaign, true)
      .then((data = {}) => {
        dispatch(setContentLoading(false));
        const parsedData = parseTeamUsingCampaign(
          data,
          user,
          userSubscriptionUsers
        );
        dispatch({
          type: ActionTypes.campaignsTab.state.initDeleteCampaignState,
          data: { ...parsedData, admin: user.admin },
        });
        dispatch(openPopup(GlobalPopupIds.confirmCampaignDeletion));
      })
      .catch(() => {
        //todo - show error
        dispatch(setContentLoading(false));
      });
  };
}

export function currentUsers(id, teams) {
  return (dispatch, getState) => {
    const { user } = getState();
    return getCampaignCurrentUsers(id, teams).then((currentUsersData) => {
      const filteredData = currentUsersData.filter(
        (currentUser) => currentUser.id !== user.id
      );
      dispatch({
        type: ActionTypes.campaignsTab.data.currentUsers,
        id,
        data: filteredData,
      });
    });
  };
}
