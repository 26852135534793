import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './dropdownMenuItem.scss';

class DropdownMenuItem extends Component {
  render() {
    const { label, disabled } = this.props;
    return (
      <div
        className={classNames('tout-dropdown-menu-item', {
          'tout-dropdown-menu-item-disabled': disabled,
        })}
        onClick={this._detectClick}
      >
        {label}
      </div>
    );
  }

  _detectClick = (e) => {
    const { id, onSelect, disabled } = this.props;
    if (!disabled) {
      onSelect(id);
    } else {
      e.stopPropagation();
    }
  };
}

DropdownMenuItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DropdownMenuItem.defaultProps = {
  id: '',
  disabled: false,
};

export default DropdownMenuItem;
