import { resizeToPeopleSearchAdd } from 'web/services/windowService';
import { getPeopleSearchAddCount } from 'web/people/peopleSearchAdd/selectors/peopleSearchAddSelectors';

export const handlePeopleSearchAddResize = () => (dispatch, getState) => {
  const state = getState();
  const {
    addToCampaignModalWindowMode: windowMode, // TODO: generalize window mode
  } = state;

  if (windowMode) {
    resizeToPeopleSearchAdd(getPeopleSearchAddCount(state));
  }
};
