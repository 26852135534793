import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  CampaignPeopleTabEvents,
  CampaignTabProperties,
} from 'web/libs/mixpanelEvents';
import { getCampaignsPeople } from 'web/reducers/campaigns/campaignSelectors';
import { GlobalAlertIds } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { ASYNC_THRESHOLD_PEOPLE } from '../libs/campaignsPeopleConstants';
import {
  closePopup,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { initializeBulkUpdate } from 'web/bulkUpdate/actionCreators/bulkUpdateProgressActionCreators';
import { openCampaignAlert } from 'web/campaigns/campaignsTab/actionCreators/campaignsAlertActionCreators';
import {
  resetCampaignPeopleSearch,
  searchCampaignPeoplePagination,
} from 'web/campaigns/people/actionCreators/campaignsPeopleSearchActionCreators';
import { track } from 'web/services/mixpanelService';
import {
  getCampaignPeople as getCampaignPeopleCall,
  removePeople as removePeopleCall,
  removePeopleAsync as removePeopleAsyncCall,
} from '../services/campaignsPeopleService';
import { tablePerPageFromStorage } from 'web/services/storageService';

const trackRemoveActivePeople = (markSuccess, bulk) => {
  const mixpanelEvent = markSuccess
    ? CampaignPeopleTabEvents.success
    : CampaignPeopleTabEvents.finished;
  track(mixpanelEvent, {
    bulk,
    view: CampaignTabProperties.view,
  });
};

const setCampaignPeopleLoading = (isLoading) => ({
  data: isLoading,
  type: ActionTypes.campaignPeople.state.loading,
});

export function setPeopleViewAsFilter(value) {
  return {
    data: value,
    type: ActionTypes.campaignPeople.state.viewAsFilter,
  };
}

export function getCampaignPeople(id, filter = { state: '' }, page = 1) {
  return (dispatch, getState) => {
    const { campaignsPeopleSearchString: str } = getState();
    dispatch({
      data: true,
      type: ActionTypes.campaignPeople.state.loading,
    });
    if (filter.userId !== undefined) {
      dispatch(setPeopleViewAsFilter(filter.userId));
    }
    const perPage = tablePerPageFromStorage();
    getCampaignPeopleCall(id, filter, (page - 1) * perPage, perPage, str)
      .then((workflowInstances = []) => {
        const data = workflowInstances.filter(
          (wfi) => wfi.person_id && wfi.person
        );
        dispatch({
          data: false,
          type: ActionTypes.campaignPeople.state.loading,
        });
        dispatch({
          data,
          type: ActionTypes.campaignPeople.data.people,
        });
      })
      .catch(() => {
        dispatch({
          data: false,
          type: ActionTypes.campaignPeople.state.loading,
        });
      });
  };
}

export function checkNeedMoreData() {
  return (dispatch, getState) => {
    const state = getState();
    const dataLength = getCampaignsPeople(state).length;
    if (dataLength < tablePerPageFromStorage()) {
      const {
        campaignsPeopleFilter,
        campaignsPeopleIsSearching: isSearching,
        campaignsPeoplePagination: page,
        campaignsPeopleSearchPagination: searchPage,
        campaignsTabSelectedCampaign: id,
        campaignsPeopleViewAsFilter,
      } = state;

      if (isSearching) {
        dispatch(
          searchCampaignPeoplePagination(id, campaignsPeopleFilter, searchPage)
        );
      } else {
        dispatch(
          getCampaignPeople(
            id,
            {
              state: campaignsPeopleFilter,
              userId: campaignsPeopleViewAsFilter,
            },
            page
          )
        );
      }
    }
  };
}

export const bulkUpdateWorkflowInstance = (successes, errors) => (dispatch) => {
  dispatch({
    errors,
    successes,
    type: ActionTypes.campaignPeople.data.removeInstancesBulk,
  });
  dispatch(checkNeedMoreData(successes.length));
};

export const updateWorkflowInstanceAlert = (successes, failures) => (
  dispatch
) => {
  if (failures.length) {
    dispatch(
      openCampaignAlert(GlobalAlertIds.removePeopleError, {
        failures,
        successes,
      })
    );
  } else if (successes.length) {
    dispatch(
      openCampaignAlert(GlobalAlertIds.removePeopleSuccess, { successes })
    );
  }
};

export const handleSearchOnUpdate = (workflowInstance = {}) => (
  dispatch,
  getState
) => {
  const {
    campaignsPeopleIsSearching: isSearching,
    campaignsPeopleSearch,
  } = getState();
  const { id: workflowInstanceId } = workflowInstance;

  if (isSearching) {
    const searchIndex = campaignsPeopleSearch.findIndex(
      (element) => element.id === workflowInstanceId
    );
    if (searchIndex > -1) {
      dispatch({
        data: workflowInstance,
        index: searchIndex,
        type: ActionTypes.campaignPeople.data.updatePeopleSearch,
      });
    }
  }
};

export function updateWorkflowInstance(workflowInstance = {}) {
  return (dispatch, getState) => {
    const {
      campaignsPeople,
      campaignsPeopleFilter,
      campaignsPeoplePagination: page,
      campaignsTabSelectedCampaign,
    } = getState();
    const {
      state: workflowInstanceState,
      workflow_id: workflowId,
    } = workflowInstance;
    const index = campaignsPeople.findIndex(
      (element) => element.id === workflowInstance.id
    );

    if (workflowId !== campaignsTabSelectedCampaign) {
      return;
    } else if (index > -1 && workflowInstanceState !== campaignsPeopleFilter) {
      dispatch({
        data: workflowInstance,
        index,
        type: ActionTypes.campaignPeople.data.removePeople,
      });
    } else if (index > -1) {
      dispatch({
        data: workflowInstance,
        index,
        type: ActionTypes.campaignPeople.data.updatePeople,
      });
    } else if (page === 1 && workflowInstanceState === campaignsPeopleFilter) {
      dispatch({
        data: workflowInstance,
        type: ActionTypes.campaignPeople.data.addPeople,
      });
    }
    dispatch(handleSearchOnUpdate(workflowInstance));
  };
}

export function removeActivePeopleAlert(removedPeople = []) {
  return (dispatch) => {
    const successes = removedPeople.filter((person) => person.success);
    const failures = removedPeople.filter((person) => !person.success);
    dispatch({
      data: successes,
      type: ActionTypes.campaignPeople.data.removePeopleBulk,
    });
    dispatch(updateWorkflowInstanceAlert(successes, failures));
  };
}

const initializeBulkRemove = (markSuccess, jobId, clearSelectedIds) => (
  dispatch
) => {
  const modalTitle = markSuccess
    ? 'common.markSuccess'
    : 'web.campaigns.tasks.markDone';
  dispatch(
    initializeBulkUpdate(
      jobId,
      bulkUpdateWorkflowInstance,
      modalTitle,
      clearSelectedIds
    )
  );
};

const removeActivePeopleAsync = (
  workflowInstanceIds = [],
  markSuccess = false,
  clearSelectedIds = () => {}
) => (dispatch, getState) => {
  const { campaignsTabSelectedCampaign: selectedCampaign } = getState();
  trackRemoveActivePeople(markSuccess, true);
  dispatch(setPopupLoading(true));

  removePeopleAsyncCall(workflowInstanceIds, selectedCampaign, {
    success: markSuccess,
  })
    .then(({ job_id: jobId }) => {
      dispatch(setPopupLoading(false));
      dispatch(initializeBulkRemove(markSuccess, jobId, clearSelectedIds));
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
    });
};

const removeActivePeopleSync = (
  workflowInstanceIds = [],
  markSuccess = false,
  clearSelectedIds = () => {}
) => (dispatch, getState) => {
  const { campaignsTabSelectedCampaign: selectedCampaign } = getState();
  trackRemoveActivePeople(markSuccess, false);
  dispatch(closePopup());
  dispatch(setCampaignPeopleLoading(true));

  removePeopleCall(workflowInstanceIds, selectedCampaign, {
    success: markSuccess,
  })
    .then((removedPeople = []) => {
      dispatch(setCampaignPeopleLoading(false));
      dispatch(removeActivePeopleAlert(removedPeople));
      dispatch(checkNeedMoreData(workflowInstanceIds.length));
      clearSelectedIds();
    })
    .catch(() => {
      dispatch(openCampaignAlert(GlobalAlertIds.removePeopleFailed));
      dispatch(setCampaignPeopleLoading(false));
    });
};

export function removeActivePeople(
  workflowInstanceIds = [],
  markSuccess = false,
  clearSelectedIds = () => {}
) {
  return (dispatch) => {
    if (workflowInstanceIds.length > ASYNC_THRESHOLD_PEOPLE) {
      dispatch(
        removeActivePeopleAsync(
          workflowInstanceIds,
          markSuccess,
          clearSelectedIds
        )
      );
    } else {
      dispatch(
        removeActivePeopleSync(
          workflowInstanceIds,
          markSuccess,
          clearSelectedIds
        )
      );
    }
  };
}

export function resetCampaignPeople() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.campaignPeople.state.reset });
    dispatch(resetCampaignPeopleSearch());
  };
}

export function peoplePaginationClick(newPage) {
  return (dispatch, getState) => {
    const {
      campaignsPeopleFilter,
      campaignsPeopleIsSearching: isSearching,
      campaignsPeoplePagination: page,
      campaignsPeopleSearchPagination: searchPage,
      campaignsTabSelectedCampaign: id,
      campaignsPeopleViewAsFilter,
    } = getState();
    const currentPage = isSearching ? searchPage : page;
    track(CampaignPeopleTabEvents.pagination, {
      increment: newPage > currentPage,
      newPage,
    });

    if (isSearching) {
      dispatch({
        data: newPage,
        type: ActionTypes.campaignPeople.state.paginationSearch,
      });
      dispatch(
        searchCampaignPeoplePagination(id, campaignsPeopleFilter, newPage)
      );
    } else {
      dispatch({
        data: newPage,
        type: ActionTypes.campaignPeople.state.pagination,
      });
      dispatch(
        getCampaignPeople(
          id,
          {
            state: campaignsPeopleFilter,
            userId: campaignsPeopleViewAsFilter,
          },
          newPage
        )
      );
    }
  };
}

export function peopleFilterChange(value) {
  return (dispatch, getState) => {
    const {
      campaignsPeopleIsSearching: isSearching,
      campaignsTabSelectedCampaign: id,
      campaignsPeopleViewAsFilter,
    } = getState();
    track(CampaignPeopleTabEvents.filtered, { filter: value });
    dispatch({
      data: value,
      type: ActionTypes.campaignPeople.state.filter,
    });
    if (isSearching) {
      dispatch(searchCampaignPeoplePagination(id, value));
    } else {
      dispatch(
        getCampaignPeople(id, {
          state: value,
          userId: campaignsPeopleViewAsFilter,
        })
      );
    }
  };
}

export function peopleViewAsFilterChange(userId) {
  return (dispatch, getState) => {
    const {
      campaignsTabSelectedCampaign: campaignId,
      campaignsPeopleFilter,
    } = getState();
    dispatch(
      getCampaignPeople(campaignId, {
        state: campaignsPeopleFilter,
        userId,
      })
    );
  };
}
