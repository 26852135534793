import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { CampaignStepActions } from 'web/libs/constants';
import { TaskType } from 'libs/constantsShared';
import './campaignStepIcon.scss';

class CampaignStepIcon extends Component {
  _getTypeData = () => {
    const {
      action,
      intl: { formatMessage },
    } = this.props;

    switch (action) {
      case CampaignStepActions.emailTask:
        return {
          classes: 'email-task-step',
          title: formatMessage({ id: 'web.campaigns.emailTask' }),
        };
      case CampaignStepActions.call:
      case TaskType.call:
        return {
          classes: 'phone-step',
          title: formatMessage({ id: 'common.call' }),
        };
      case CampaignStepActions.inmail:
      case TaskType.inMail:
        return {
          classes: 'inmail-step',
          title: formatMessage({ id: 'common.inMail' }),
        };
      case CampaignStepActions.custom:
      case TaskType.other:
        return {
          classes: 'custom-step',
          title: formatMessage({ id: 'web.campaigns.customTask' }),
        };
      case CampaignStepActions.email:
      case TaskType.email:
        return {
          classes: 'email-step',
          title: formatMessage({ id: 'common.email' }),
        };
      default:
        return {
          classes: 'empty-step',
          title: formatMessage({ id: 'web.campaigns.emptyStep' }),
        };
    }
  };

  render() {
    const {
      active,
      className,
      disabled,
      intl: ignored,
      outline,
      preventHover,
      ...other
    } = this.props;
    const data = this._getTypeData();
    const classes = classNames('ui-step-icon', data.classes, className, {
      active,
      hover: !preventHover,
      outline,
      disabled,
    });

    return <span className={classes} title={data.title} {...other} />;
  }
}

CampaignStepIcon.propTypes = {
  intl: intlShape.isRequired,
  action: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  preventHover: PropTypes.bool,
};

CampaignStepIcon.defaultProps = {
  action: '',
  className: '',
  disabled: false,
  outline: false,
  preventHover: undefined,
  active: undefined,
};

export default injectIntl(CampaignStepIcon);
