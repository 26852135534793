export const AlertIds = {
  addPeopleCallFail: 'ADD_TO_CAMPAIGN_ADD_PEOPLE_CALL_FAIL',
  addPeopleNeedsToBeEmail: 'ADD_TO_CAMPAIGN_ADD_PEOPLE_NEEDS_TO_BE_EMAIL',
  emailLimitsReachedAddPeople:
    'ADD_TO_CAMPAIGN_EMAIL_LIMITS_REACHED_ADD_PEOPLE',
  genericApiIssue: 'ADD_TO_CAMPAIGN_GENERIC_API_ISSUE',
  invalidPreviewDynamicFields: 'ADD_TO_CAMPAIGN_INVALID_PREVIEW_DYNAMIC_FIELDS',
  invalidPreviewInput: 'ADD_TO_CAMPAIGN_INVALID_PREVIEW_INPUT',
  invalidPreviewPromptFields: 'ADD_TO_CAMPAIGN_INVALID_PREVIEW_PROMPT_FIELDS',
  launchCampaignIssue: 'ADD_TO_CAMPAIGN_LAUNCH_CAMPAIGN_ISSUE',
  marketoWorkspacesError: 'ADD_TO_CAMPAIGN_MARKETO_WORKSPACES_ERROR',
  noSteps: 'ADD_TO_CAMPAIGN_NO_STEPS',
  unverifiedUser: 'ADD_TO_CAMPAIGN_UNVERIFIED_USER',
};

export const ModalComponentIds = {
  addPeople: 'ADD_TO_CAMPAIGN_ADD_PEOPLE',
  addToCampaign: 'ADD_TO_CAMPAIGN_ADD_TO_CAMPAIGN',
  addToMarketoCampaign: 'ADD_TO_CAMPAIGN_ADD_TO_MARKETO_CAMPAIGN',
  campaignLaunched: 'ADD_TO_CAMPAIGN_CAMPAIGN_LAUNCHED',
  chooseCampaignType: 'ADD_TO_CAMPAIGN_CHOOSE_CAMPAIGN_TYPE',
  error: 'ADD_TO_CAMPAIGN_ERROR',
  previewEmail: 'ADD_TO_CAMPAIGN_PREVIEW_EMAIL',
  previewMarketo: 'ADD_TO_CAMPAIGN_PREVIEW_MARKETO',
  previewTask: 'ADD_TO_CAMPAIGN_PREVIEW_TASK',
  scheduleLaunch: 'ADD_TO_CAMPAIGN_SCHEDULE_LAUNCH',
  scheduleLaunchPast: 'ADD_TO_CAMPAIGN_SCHEDULE_LAUNCH_PAST',
  transitioningToSalesforce: 'ADD_TO_CAMPAIGN_TRANSITIONING_TO_SALESFORCE',
};

export const ModalOverlayIds = {
  emailLimits: 'ADD_TO_CAMPAIGN_EMAIL_LIMITS',
  notAToutContact: 'ADD_TO_CAMPAIGN_NOT_A_TOUT_CONTACT',
  notValidEmail: 'ADD_TO_COMPAIGN_NOT_VALID_EMAIL',
  retryPeopleData: 'ADD_TO_CAMPAIGN_RETRY_PEOPLE_DATA',
  salesConnectError: 'ADD_TO_CAMPAIGN_SALES_CONNECT_ERROR',
  salesforceError: 'ADD_TO_CAMPAIGN_SALESFORCE_ERROR',
};

export const AddToCampaignErrorMessages = {
  notAToutContact:
    'The contact that you are trying to add is not a Marketo Sales contact',
};

export const AddToCampaignColumns = {
  marketoCampaignName: 'ADD_TO_CAMPAIGN_MARKETO_CAMPAIGN_NAME',
  toutCampaignName: 'ADD_TO_CAMPAIGN_TOUT_CAMPAIGN_NAME',
  firstStep: 'ADD_TO_CAMPAIGN_FIRST_STEP',
};

export const ADD_TO_CAMPAIGN_TABLE_ID = 'ADD_TO_CAMPAIGN_TABLE';

export const CampaignTypes = {
  tout: 'ADD_TO_CAMPAIGN_CAMPAIGN_TYPE_TOUT',
  marketo: 'ADD_TO_CAMPAIGN_CAMPAIGN_TYPE_MARKETO',
};

export const AddToCampaignErrors = {
  salesforce: {
    notConnected: 'not_connected',
  },
};

export const AddToCampaignCardIds = {
  marketo: 'MARKETO-CARD-ID',
  tout: 'TOUT-CARD-ID',
};

export const SalesforceFailureKeys = {
  emailAndPhone: 'Contact does not have an email address or phone number',
  userOptdOut: 'Contact has opted out of receiving email',
};
