import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import CirclePie, { CirclePieColors } from 'components/stats/circlePie';
import Legend from 'components/stats/legend';
import './analyticsSentCard.scss';

const CIRCLE_PIE_RADIUS = 60;
const LEGEND_WIDTH = 400;

class AnalyticsSentCard extends PureComponent {
  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.circlePieLabel = formatMessage({ id: 'common.sent' });
    this.deliveredLabel = formatMessage({ id: 'common.delivered' });
    this.bouncedLabel = formatMessage({ id: 'common.bounced' });
    // this.spamLabel = formatMessage({ id: 'common.spam' }); //temp as not properly working on backend
    this.failedLabel = formatMessage({
      id: 'web.editTemplateSlideOut.analyticsSentCard.failed',
    });
  }

  render() {
    const { stats, total } = this._formatData();

    return (
      <div className="edit-template-analytics-sent-card">
        <h4 className="email-card-subject text-medium text-overflow margin-bottom-full">
          <FormattedMessage id="web.editTemplateSlideOut.analyticsSentCard.header" />
        </h4>
        <div className="analytics-sent-stats">
          <CirclePie
            label={this.circlePieLabel}
            radius={CIRCLE_PIE_RADIUS}
            stats={stats}
            total={total}
            showCount
          />
          <Legend
            precision={2}
            stats={stats}
            total={total}
            width={LEGEND_WIDTH}
            showPercent
          />
        </div>
      </div>
    );
  }

  _formatData = () => {
    const { deliveredSuccessfully, bounced, failed, totalSent } = this.props;

    return {
      stats: [
        {
          color: CirclePieColors.green,
          count: deliveredSuccessfully,
          label: this.deliveredLabel,
        },
        {
          color: CirclePieColors.yellow,
          count: bounced,
          label: this.bouncedLabel,
        },
        // { color: CirclePieColors.purple, count: spam, label: this.spamLabel }, //temp as not properly working on backend
        { color: CirclePieColors.red, count: failed, label: this.failedLabel },
      ],
      total: totalSent,
    };
  };
}

AnalyticsSentCard.propTypes = {
  bounced: PropTypes.number.isRequired,
  deliveredSuccessfully: PropTypes.number.isRequired,
  failed: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  totalSent: PropTypes.number.isRequired,
};

AnalyticsSentCard.defaultProps = {};

export default injectIntl(AnalyticsSentCard);
