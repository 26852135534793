import ComposeTemplatesActionTypes from 'web/composeWindow/libs/composeTemplatesActionTypes';
import ComposeWindowActionTypes from 'web/composeWindow/libs/composeWindowActionTypes';

export function composeTemplatesCategories(
  state = [],
  { type, templateCategories }
) {
  switch (type) {
    case ComposeTemplatesActionTypes.setCategories:
      return templateCategories;
    default:
      return state;
  }
}

export function composeTemplatesPinnedCategories(
  state = [],
  { type, pinnedCategories }
) {
  switch (type) {
    case ComposeTemplatesActionTypes.setPinnedTemplateCategories:
      return pinnedCategories;
    case ComposeWindowActionTypes.reset:
      return [];
    default:
      return state;
  }
}

const initialTemplatesState = {
  highlightTerm: '',
  page: 1,
  templates: [],
  total: 0,
};

export function composeTemplates(
  state = initialTemplatesState,
  { type, templates = [], term = '', total = 0, page = 1 }
) {
  switch (type) {
    case ComposeTemplatesActionTypes.setTemplates:
      return { highlightTerm: term, page, templates, total };
    case ComposeTemplatesActionTypes.appendTemplates:
      return { ...state, page, templates: [...state.templates, ...templates] };
    case ComposeTemplatesActionTypes.resetTemplates:
      return { ...initialTemplatesState };
    default:
      return state;
  }
}

export function composeTemplatesEditPinnedCategories(
  state = [],
  { type, editPinnedCategories = [] }
) {
  switch (type) {
    case ComposeTemplatesActionTypes.setEditPinnedCategories:
      return editPinnedCategories;
    case ComposeTemplatesActionTypes.clearEditPinnedCategories:
      return [];
    default:
      return state;
  }
}

export function composeTemplateMinimized(state = false, { type, minimized }) {
  switch (type) {
    case ComposeTemplatesActionTypes.setTemplatesMinimized:
      return minimized;
    default:
      return state;
  }
}

export function composeTemplatesLoading(state = false, { type, loading }) {
  switch (type) {
    case ComposeTemplatesActionTypes.setLoading:
      return loading;
    default:
      return state;
  }
}

export function composePinnedCategoriesLoading(
  state = false,
  { type, loading }
) {
  switch (type) {
    case ComposeTemplatesActionTypes.setPinnedCategoriesLoading:
      return loading;
    default:
      return state;
  }
}

export function composeTemplatesHasRecommended(state = false, { type }) {
  switch (type) {
    case ComposeTemplatesActionTypes.showRecommendedTemplates:
      return true;
    case ComposeTemplatesActionTypes.hideRecommendedTemplates:
    case ComposeTemplatesActionTypes.resetTemplates:
      return false;
    default:
      return state;
  }
}
