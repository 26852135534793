import React from 'react';
import PropTypes from 'prop-types';
import Label from 'components/label';
import DefaultSelect from 'components/selects/default/defaultSelect';
import { injectIntl, intlShape } from 'react-intl';
import './fromDropDown.scss';

class FromDropDown extends React.Component {
  buildIdentityLabel = ({
    name,
    email,
    default_identity: defaultIdentity,
    verified,
  }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    let label = `${name} (${email})`;
    const truncateLength = 30;
    if (label.length > truncateLength) {
      label = `${label.slice(0, truncateLength)}...`;
    }
    let details = '';
    if (defaultIdentity) {
      details = formatMessage({ id: 'common.default' });
    } else if (!verified) {
      details = formatMessage({ id: 'web.identities.unverifiedEmail' });
    }
    return details ? `${label} - ${details}` : label;
  };

  handleFromChange = (itemId) => {
    const data = this.props.identities.find(
      (identity) => identity.id === itemId
    );
    this.props.updateIdentity(data);
  };

  render() {
    const { identity, identities, disabled } = this.props;

    const mapIdentities = identities.map((identity) => ({
      id: identity.id,
      name: this.buildIdentityLabel(identity),
      disabled: !identity.verified,
      disabledStyle: !identity.verified,
    }));

    const selectedId = Object.keys(identity).length
      ? identity.id
      : this.props.defaultIdentity.id;

    return (
      <div className="compose-field">
        <Label
          className="compose-label"
          id="label-text"
          messageKey="common.fromCapitalized"
          sideLabel
        >
          <DefaultSelect
            className="new-compose-window"
            items={mapIdentities}
            selected={selectedId}
            onChange={this.handleFromChange}
            disabled={disabled}
            noBorder
          />
        </Label>
      </div>
    );
  }
}

FromDropDown.propTypes = {
  updateIdentity: PropTypes.func.isRequired,
  identities: PropTypes.array.isRequired,
  identity: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  defaultIdentity: PropTypes.object,
  disabled: PropTypes.bool,
};

FromDropDown.defaultProps = {
  defaultIdentity: {},
  disabled: false,
};

export default injectIntl(FromDropDown);
