import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { deleteFiles as commonDeleteFiles } from 'web/content/actionCreators/contentActionCreators';
import { uploadFile } from 'web/content/services/contentService';
import { ProgressStatus } from 'libs/constantsShared';

function uploadProgress(dispatch, componentId, name, reference, type) {
  return (e) => {
    let progress;
    let status;

    if (e.lengthComputable) {
      progress = Math.round((e.loaded * 100) / e.total);
      status = ProgressStatus.uploading;
    } else {
      progress = null;
      status = ProgressStatus.issue;
    }
    dispatch({
      type: ActionTypes.emailComposeBody.state[`${type}UploadProgressUpdate`],
      id: componentId,
      data: {
        name,
        progress,
        reference,
        status,
      },
    });
  };
}

export function uploadFiles(componentId, files, type = 'content') {
  return (dispatch) => {
    // mixpanel.track(mixpanel.events.contentUpload, { count: files.length });
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reference = Date.now();

      dispatch({
        type: ActionTypes.emailComposeBody.state[`${type}UploadStarting`],
        id: componentId,
        data: {
          name: file.name,
          progress: 0,
          reference,
          status: ProgressStatus.uploading,
        },
      });
      uploadFile(
        file,
        uploadProgress(dispatch, componentId, file.name, reference, type)
      )
        .then((data) => {
          dispatch({
            type: ActionTypes.emailComposeBody.state[`${type}UploadSuccess`],
            id: componentId,
            data: {
              name: file.name,
              progress: 100,
              reference,
              status: ProgressStatus.finished,
              ...data,
            },
          });
        })
        .catch(() => {
          dispatch({
            type:
              ActionTypes.emailComposeBody.state[`${type}UploadProgressUpdate`],
            id: componentId,
            data: {
              name: file.name,
              reference,
              status: ProgressStatus.error,
            },
          });
        });
    }
  };
}

export function deleteAttachment(componentId, file = {}) {
  return (dispatch) => {
    const comparator = file.id ? 'id' : 'reference'; // needed in case of error/issue
    dispatch({
      type: ActionTypes.emailComposeBody.state.attachmentDelete,
      id: componentId,
      comparator,
      data: file,
    });

    if (file.id && file.hasOwnProperty('status')) {
      dispatch(commonDeleteFiles(file.id));
    }
  };
}

function imageInsertUploadProgress(dispatch) {
  return (e) => {
    let progress;
    let status;

    if (e.lengthComputable) {
      progress = Math.round((e.loaded * 100) / e.total);
      status = ProgressStatus.uploading;
    } else {
      progress = null;
      status = ProgressStatus.issue;
    }
    dispatch({
      type: ActionTypes.emailComposeBody.state.imageInsertUploadProgressUpdate,
      data: {
        progress,
        status,
      },
    });
  };
}

export function imageInsertUploadFile(file) {
  return (dispatch) => {
    // mixpanel.track(mixpanel.events.contentUpload, { count: files.length });
    dispatch({
      type: ActionTypes.emailComposeBody.state.imageInsertUploadStarting,
      data: {
        name: file.name,
        progress: 0,
        status: ProgressStatus.uploading,
      },
    });

    uploadFile(file, imageInsertUploadProgress(dispatch, file.name))
      .then((data) => {
        dispatch({
          type: ActionTypes.emailComposeBody.state.imageInsertUploadSuccess,
          data: {
            progress: 100,
            status: ProgressStatus.finished,
            ...data,
          },
        });
      })
      .catch(() => {
        dispatch({
          type:
            ActionTypes.emailComposeBody.state.imageInsertUploadProgressUpdate,
          data: {
            status: ProgressStatus.error,
          },
        });
      });
  };
}

export function attachFiles(files, editorId) {
  return {
    data: files,
    id: editorId,
    type: ActionTypes.emailComposeBody.state.templateAttachmentsAdd,
  };
}
