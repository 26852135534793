import { createReducer } from '@reduxjs/toolkit';
import InvitationsActionTypes from 'web/invitations/libs/invitationsActionTypes';
import castArray from 'lodash/castArray';
import assign from 'lodash/assign';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';
import unset from 'lodash/unset';
import union from 'lodash/union';
import map from 'lodash/map';
import {
  setResendInvitationLoading,
  setResendInvitationResponse,
} from 'web/invitations/actionCreators/invitationsActionCreators';

const INVITATIONS_ID_KEY = 'id';

export function byId(state = {}, action) {
  switch (action.type) {
    case InvitationsActionTypes.setInvitations:
      return keyBy(action.invitations, INVITATIONS_ID_KEY);
    case InvitationsActionTypes.resendInvitation: {
      const diff = keyBy(castArray(action.invitation), INVITATIONS_ID_KEY);
      return assign({}, state, diff);
    }
    case InvitationsActionTypes.batchResendInvitations:
    case InvitationsActionTypes.updateInvitations: {
      const diff = keyBy(action.invitations, INVITATIONS_ID_KEY);
      return assign({}, state, diff);
    }
    case InvitationsActionTypes.deleteInvitation: {
      const newState = { ...state };
      unset(newState, action.id);
      return newState;
    }
    case InvitationsActionTypes.batchDestroyInvitations: {
      const newState = { ...state };
      action.ids.forEach((id) => unset(newState, id));
      return newState;
    }
    default:
      return state;
  }
}

export function allIds(state = [], action) {
  switch (action.type) {
    case InvitationsActionTypes.setInvitations:
      return map(action.invitations, INVITATIONS_ID_KEY);
    case InvitationsActionTypes.updateInvitations: {
      const diff = map(action.invitations, INVITATIONS_ID_KEY);
      return union(state, diff);
    }
    case InvitationsActionTypes.deleteInvitation: {
      return difference(state, castArray(action.id));
    }
    case InvitationsActionTypes.batchDestroyInvitations: {
      return difference(state, action.ids);
    }
    default:
      return state;
  }
}

export function fetched(state = false, action) {
  switch (action.type) {
    case InvitationsActionTypes.setInvitationsFetched:
      return action.fetched;
    default:
      return state;
  }
}

export function fetching(state = false, action) {
  switch (action.type) {
    case InvitationsActionTypes.setInvitationsFetching:
      return action.fetching;
    default:
      return state;
  }
}

export const resendInvitationLoading = createReducer(false, {
  [setResendInvitationLoading.type]: (state, action) => {
    return action.payload;
  },
});

export const resendInvitationResult = createReducer('', {
  [setResendInvitationResponse.type]: (state, action) => {
    return action.payload;
  },
});
