import { baseGet, basePost } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getCampaigns() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.campaigns, {}, resolve, reject);
  });
}

export function getCampaignsCategories() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.campaignsCategories, {}, resolve, reject);
  });
}

export function getEmailLimits(ids) {
  let data = ids;
  if (Array.isArray(ids)) {
    data = { person_ids: ids };
  }
  return new Promise((resolve, reject) => {
    basePost(Urls.emailLimits, data, resolve, reject);
  });
}
