import get from 'lodash/get';
import DialerActionTypes from '../libs/dialerActionTypes';
import {
  getCallReasonOptionsRequest,
  bulkCallReasonOptionsRequest,
} from '../services/dialerServices';
import { getUser } from 'web/user/actionCreators/userActionCreators';
import { showSettingsAlert } from './dialerSettingsActionCreators';
import { DialerSettingsAlertIds } from '../libs/dialerConstants';

export const getCallReasonOptionsSuccess = ({ callReasonOptions }) => ({
  callReasonOptions,
  type: DialerActionTypes.getCallReasonOptionsSuccess,
});

export const callReasonOptionsLoading = ({ isLoading }) => ({
  isLoading,
  type: DialerActionTypes.callReasonOptionsLoading,
});

export const getCallReasonOptions = (useLoading = true) => async (dispatch) => {
  if (useLoading) dispatch(callReasonOptionsLoading({ isLoading: true }));
  try {
    const {
      call_reason_options: callReasonOptions,
    } = await getCallReasonOptionsRequest();
    dispatch(
      getCallReasonOptionsSuccess({
        callReasonOptions,
      })
    );
  } catch (err) {
    dispatch(showSettingsAlert(DialerSettingsAlertIds.somethingWentWrong));
  }
  if (useLoading) dispatch(callReasonOptionsLoading({ isLoading: false }));
};

export const bulkCallReasonOptions = ({ create, ids_to_delete }) => async (
  dispatch
) => {
  dispatch(callReasonOptionsLoading({ isLoading: true }));
  try {
    await bulkCallReasonOptionsRequest({
      call_reason_options: { create, ids_to_delete },
    });
    const {
      call_reason_options: callReasonOptions,
    } = await getCallReasonOptionsRequest();
    dispatch(
      getCallReasonOptionsSuccess({
        callReasonOptions,
      })
    );
    dispatch(showSettingsAlert(DialerSettingsAlertIds.changesSaved));
  } catch (err) {
    dispatch(showSettingsAlert(DialerSettingsAlertIds.changesNotSaved));
  }
  dispatch(callReasonOptionsLoading({ isLoading: false }));
};

export const alertEmptyRequiredCallReasonOption = ({
  isEnabled = null,
  isRequired = null,
  loadOptions = true,
}) => async (dispatch, getState) => {
  try {
    if (isEnabled === null || isRequired === null) {
      await dispatch(getUser());
      isEnabled = get(
        getState(),
        'user.subscription.admin_settings.call_reason_enabled',
        false
      );
      isRequired = get(
        getState(),
        'user.subscription.admin_settings.force_call_reason',
        false
      );
    }
    if (isEnabled && isRequired && loadOptions) {
      await dispatch(getCallReasonOptions(false));
    }

    const callReasonOptions = getState().callReasonOptions || [];
    if (isEnabled && isRequired && callReasonOptions.length === 0) {
      dispatch(
        showSettingsAlert(DialerSettingsAlertIds.noCallReasonsConfigured)
      );
    }
  } catch (err) {
    dispatch(showSettingsAlert(DialerSettingsAlertIds.somethingWentWrong));
  }
};
