import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/inputs/text';

const RETURN_KEY_CODE = 13;

class SaveAdvancedSearchComponent extends Component {
  render() {
    return (
      <div className="save-advanced-search">
        <Text
          defaultValue={this.props.defaultValue}
          id="save-advanced-search-text"
          onChange={this.onSearcNameChange}
          onKeyDown={this.onKeyDown}
          type="text"
        />
      </div>
    );
  }

  onSearcNameChange = (value) => {
    this.props.onSearcNameChange(value);
  };

  onKeyDown = (e) => {
    if (e.keyCode === RETURN_KEY_CODE) {
      this.props.onSave();
    }
  };
}

SaveAdvancedSearchComponent.propTypes = {
  defaultValue: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onSearcNameChange: PropTypes.func.isRequired,
};

SaveAdvancedSearchComponent.defaultProps = {
  defaultValue: '',
};

export default SaveAdvancedSearchComponent;
