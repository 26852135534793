import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { BackgroundColors } from 'libs/constantsStyles';
import Label from 'components/label';
import Action from 'components/buttons/action';
import {
  required as requiredValidatorFactory,
  domainName as domainNameValidatorFactory,
} from 'web/form/helpers/validators';
import { getOptionMapper } from 'web/form/helpers/formHelpers';
import {
  LABEL_WIDTH,
  PORT_OPTIONS,
  CONNECTION_TYPE_OPTIONS,
} from '../../libs/emailServerConstants';
import Text from 'web/form/components/fields/text';
import Select from 'web/form/components/fields/select';
import './emailServerForm.scss';

const MAX_TCP_PORT_VALUE = 0xffff;

class EmailServerForm extends Component {
  constructor(props) {
    super(props);
    this.requiredValidator = requiredValidatorFactory();
    this.domainNameValidator = domainNameValidatorFactory();
  }

  render() {
    const {
      onDelete,
      onError,
      isNew,
      handleSubmit,
      valid,
      pristine,
      additionalServerValidators,
    } = this.props;

    const serverValidators = [
      this.requiredValidator,
      this.domainNameValidator,
      ...additionalServerValidators,
    ];
    return (
      <Form
        className="email-server-form"
        onSubmit={
          valid
            ? handleSubmit
            : (e) => {
                onError();
                e.preventDefault();
              }
        }
        autoComplete="false"
      >
        <Label
          id="email-server-setting-server"
          className="email-server-field-label"
          classWrapper="email-server-field"
          messageKey="web.settings.emailServer.fieldLabel.server"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Field
            name="server"
            component={Text}
            validate={serverValidators}
            autoFocus={false}
          />
        </Label>
        <Label
          id="email-server-setting-port"
          className="email-server-field-label"
          classWrapper="email-server-field"
          messageKey="web.settings.emailServer.fieldLabel.port"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Field
            name="port"
            component={Select}
            validate={[this.requiredValidator]}
            options={this.portOptions}
            multi={false}
            clearable={false}
            promptTextCreator={this.portPromptTextCreator}
            noResultsText=""
            placeholder=""
            isValidNewOption={this.isCustomPortValid}
            isCreatable
          />
        </Label>
        <Label
          id="email-server-setting-connection-type"
          className="email-server-field-label"
          classWrapper="email-server-field"
          messageKey="web.settings.emailServer.fieldLabel.connection_type"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Field
            name="connection_type"
            component={Select}
            validate={[this.requiredValidator]}
            options={this.connectionTypeOptions}
            placeholder=""
            noResultsText=""
            multi={false}
            clearable={false}
          />
        </Label>
        <Label
          id="email-server-setting-username"
          className="email-server-field-label"
          classWrapper="email-server-field"
          messageKey="web.settings.emailServer.fieldLabel.username"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Field
            name="username"
            component={Text}
            validate={[this.requiredValidator]}
            autoFocus={false}
          />
        </Label>
        <Label
          id="email-server-setting-password"
          className="email-server-field-label"
          classWrapper="email-server-field"
          messageKey="web.settings.emailServer.fieldLabel.password"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Field
            name="password"
            component={Text}
            validate={[this.requiredValidator]}
            type="password"
            autoFocus={false}
            autoComplete="new-password"
          />
        </Label>
        <Label
          id="email-server-setting-domain"
          className="email-server-field-label"
          classWrapper="email-server-field"
          messageKey="web.settings.emailServer.fieldLabel.domain"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Field
            name="domain"
            component={Text}
            validate={[this.requiredValidator]}
            autoFocus={false}
          />
        </Label>
        <div className="email-server-actions-bar">
          {!isNew && (
            <button
              type="button"
              onClick={onDelete}
              className="tout-action email-server-action red standard"
            >
              <FormattedMessage id="common.disconnect" />
            </button>
          )}
          <Action
            className="email-server-action"
            color={BackgroundColors.blue}
            disabled={pristine}
            type="submit"
          >
            <FormattedMessage id="common.connect" />
          </Action>
        </div>
      </Form>
    );
  }

  get portOptions() {
    const {
      intl: { formatMessage },
      initialValues,
    } = this.props;

    if (!this._portOptions) {
      this._portOptions = PORT_OPTIONS.map(getOptionMapper(formatMessage));
    }

    if (
      initialValues &&
      initialValues.port &&
      !this._portOptions.some(
        (portOption) => portOption.value === initialValues.port
      )
    ) {
      this._portOptions.unshift({
        value: initialValues.port,
        label: initialValues.port,
      });
    }

    return this._portOptions;
  }

  get connectionTypeOptions() {
    const {
      intl: { formatMessage },
    } = this.props;

    if (!this._connectionTypeOptions) {
      this._connectionTypeOptions = CONNECTION_TYPE_OPTIONS.map(
        getOptionMapper(formatMessage)
      );
    }

    return this._connectionTypeOptions;
  }

  portPromptTextCreator = (label) => {
    const {
      intl: { formatMessage },
    } = this.props;

    return formatMessage(
      { id: 'web.settings.emailServer.portOptionLabel.custom' },
      { port: label }
    );
  };

  isCustomPortValid = ({ label }) =>
    !!label &&
    !Number.isNaN(+label) &&
    label > 0 &&
    label <= MAX_TCP_PORT_VALUE;
}

EmailServerForm.propTypes = {
  intl: intlShape.isRequired,
  additionalServerValidators: PropTypes.array,
  initialValues: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

EmailServerForm.defaultProps = {
  additionalServerValidators: [],
};

export default injectIntl(
  reduxForm({
    enableReinitialize: true,
  })(EmailServerForm)
);
