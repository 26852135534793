import ActionTypes from 'table/modules/actionTypes';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import { initSmartFilters } from 'table/modules/smartFilter/smartFilterActionCreators';
import { initPagination } from 'table/modules/pagination/paginationActionCreators';
import { setColumnOrder } from 'table/modules/headerRow/headerColumn/columnOrderActionCreator';
import { initColumnFilter } from 'table/modules/columnFilter/columnFilterActionCreators';
import { initLocalStorage } from 'table/modules/localStorage/localStorageActionCreators';
import getExternals from 'table/modules/externals';
import { setTracker } from 'table/modules/tracker';

export function initTable(props) {
  return (dispatch, getState) => {
    dispatch({ columns: props.columns, type: ActionTypes.tableSetColumns });
    dispatch({ id: props.tableId, type: ActionTypes.tableSetId });

    dispatch(initLocalStorage(props.tableId));

    if (props.setTableExternals) {
      props.setTableExternals(getExternals(dispatch, getState));
    }

    //todo monitor props for changes that affect state so they can be set externally?

    /* Tells table to manage all sorting, pagination, etc */
    if (props.allItems) {
      dispatch({ allItems: true, type: ActionTypes.tableSetAllItems });
      dispatch({ items: props.items, type: ActionTypes.tableSetItems });
    }

    /* Set default sort */
    if (props.defaultSortingColumn) {
      dispatch({
        id: props.defaultSortingColumn,
        type: ActionTypes.sortingSetColumn,
      });
    }

    dispatch(initSmartFilters(props.smartFilters));
    dispatch(initPagination(props.pagination, props.paginationItems));
    dispatch(initColumnFilter(props.columnFilter, props.columns));
    dispatch(setColumnsSettings(props));

    if (props.tracker) {
      setTracker(props.tableId, props.tracker);
    }
  };
}

export function setColumnsSettings(props) {
  return (dispatch) => {
    const { columns, columnSettings } = props;

    let currentColumnSettings = getColumnSettingsFromColumns(columns);

    if (columnSettings && columnSettings.length) {
      const columnsIdListFromColumns = columns.map((column) => column.id);
      const columnsIdListFromSettings = columnSettings.map(
        (column) => column.columnId
      );

      const columnsIdListIntersection = intersection(
        columnsIdListFromColumns,
        columnsIdListFromSettings
      );

      if (columnsIdListIntersection.length) {
        const columnSettingsIntersection = columnSettings.filter(
          (settingsColumn) =>
            columnsIdListIntersection.includes(settingsColumn.columnId)
        );

        currentColumnSettings = columnSettingsIntersection;

        if (columnsIdListIntersection.length < columns.length) {
          const missedColumnIds = difference(
            columnsIdListFromColumns,
            columnsIdListIntersection
          );

          const missedColumnSettings = getColumnSettingsFromColumns(
            columns,
            missedColumnIds
          );
          currentColumnSettings = [
            ...columnSettingsIntersection,
            ...missedColumnSettings,
          ];
        }
      }
    }

    dispatch(setColumnOrder(currentColumnSettings.map((x) => x.columnId)));
    dispatch({
      columnsWidth: currentColumnSettings,
      type: ActionTypes.columnSettingsSetColumnsWidth,
    });
  };
}

const getColumnSettingsFromColumns = (columns, filterIds = []) => {
  if (!columns || !columns.length) {
    return [];
  }
  const filteredColumns = filterIds.length
    ? columns.filter((column) => filterIds.includes(column.id))
    : columns;

  return filteredColumns.map((column) => ({
    columnId: column.id,
    width: column.width,
  }));
};

export function changeColumnWidth(columnId, width) {
  return (dispatch, getState) => {
    const { tableColumnsWidth } = getState();

    const newTableColumnsWidth = [...tableColumnsWidth];
    const changedColumn = newTableColumnsWidth.find(
      (column) => column.columnId === columnId
    );
    if (changedColumn) {
      changedColumn.width = width;
      dispatch({
        columnsWidth: newTableColumnsWidth,
        type: ActionTypes.columnSettingsSetColumnsWidth,
      });
    }
  };
}
