import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getImageUrl } from 'web/libs/constants';
import { cancelLiquifyProgress } from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import { getLiquifyProgress } from 'web/composeWindow/selectors/composeWindowSelectors';
import LoadingSpinner from 'components/loadingSpinner';
import ProgressSpinner from 'web/composeWindow/components/progressSpinner';

const ComposeWindowSpinnerContainer = (props) => {
  const { cancelLiquifyProgress, liquifyLoading, liquifyProgress } = props;
  return liquifyLoading ? (
    <ProgressSpinner
      cancelProgress={cancelLiquifyProgress}
      liquifyProgress={liquifyProgress}
      textId="web.composeWindow.progressSpinner.dynamicFields"
    />
  ) : (
    <LoadingSpinner imageUrl={getImageUrl} size={60} />
  );
};

ComposeWindowSpinnerContainer.propTypes = {
  cancelLiquifyProgress: PropTypes.func.isRequired,
  liquifyLoading: PropTypes.bool,
  liquifyProgress: PropTypes.number,
};

ComposeWindowSpinnerContainer.defaultProps = {
  liquifyLoading: false,
  liquifyProgress: 0,
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        cancelLiquifyProgress,
      },
      dispatch
    ),
  };
}

const mapStateToProps = (state) => ({
  liquifyLoading: state.liquifyProgress.active,
  liquifyProgress: getLiquifyProgress(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeWindowSpinnerContainer);
