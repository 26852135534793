import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EditingInput from '../editingInput';
import Button, { ButtonSizes } from 'components/buttons/action';
import './slideOutPaneHeaderEditing.scss';

const CLASSNAME = 'slide-out-pane-header-editing';

class SlideOutPaneHeaderEditing extends PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = null;

    this.state = {
      name: props.name,
    };
  }

  componentWillUnmount() {
    if (this.state.name.length === 0) {
      this.props.cancel();
    }
  }

  render() {
    const { cancel } = this.props;
    const { name } = this.state;

    return (
      <div className={CLASSNAME}>
        <EditingInput
          exceptionSelectors={[`.${CLASSNAME}`]}
          onChange={this.onChange}
          onOutsideClick={this.onOutsideClick}
          ref={this._setInputRef}
          name={name}
        />
        <Button
          className="slide-out-pane-header-editing-cancel"
          onClick={cancel}
          size={ButtonSizes.medium}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
      </div>
    );
  }

  _setInputRef = (node) => {
    this.inputRef = node;
  };

  onChange = (name = '') => {
    this.setState(() => ({
      name,
    }));
  };

  onOutsideClick = () => {
    const { name } = this.state;

    if (name.length === 0) {
      const node = ReactDOM.findDOMNode(this.inputRef); //eslint-disable-line react/no-find-dom-node
      if (node) {
        node.focus();
      }
    } else {
      this.props.save(name);
    }
  };
}

SlideOutPaneHeaderEditing.propTypes = {
  cancel: PropTypes.func.isRequired,
  name: PropTypes.string,
  save: PropTypes.func.isRequired,
};

SlideOutPaneHeaderEditing.defaultProps = {
  name: '',
};

export default SlideOutPaneHeaderEditing;
