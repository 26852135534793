import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';

export const INIT_ID = 0;

export const personDetailsNextId = (
  state = INIT_ID,
  { type = '', id = INIT_ID }
) => {
  switch (type) {
    case PersonDetailsActionTypes.setNextPersonDetails:
      return id;
    case PersonDetailsActionTypes.unmountNext:
      return INIT_ID;
    default:
      return state;
  }
};
