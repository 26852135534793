import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';
import {
  SORT_DIRECTION,
  DEFAULT_PAGE,
} from 'web/commandCenter/libs/commandCenterConstants';
import {
  setTableSort,
  setTablePage,
  setTablePerPage,
} from 'web/commandCenter/actionCreators/commandCenterTableActionCreators';
import CommandCenterEmailsActionTypes from 'web/commandCenter/libs/commandCenterEmailsActionTypes';
import EmailGroupsActionType from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGroupsActionType';
import {
  getEmailFetch,
  getReplyEmailDraft,
  getUnreadEmailsCount as getUnreadEmailsCountCall,
} from 'web/commandCenter/services/emailService';
import { getEmailFetchRequestParams } from 'web/commandCenter/services/emailFetchRequestParams';
import { setSidebarViewerFromEamilGridAdvancedSearch } from 'web/commandCenter/modules/whoSmartFilter/actionCreators/viewAsFilterActionCreators';
import { createPitch } from 'web/composeWindow/services/composeWindowServices';
import { getUser } from 'web/user/selectors/userSelectors';
import {
  setUnreadEmailsCount,
  markUnreadEmailsAsRead,
  handleSelectedEmail,
} from 'web/commandCenter/actionCreators/commandCenterEmailsActionCreators';
import { parseUnreadEmailsCounters } from 'web/commandCenter/helpers/emailHelpers';
import { PitchStates } from 'web/emails/libs/emailsConstants';
import { COMPOSE_DOM_TARGET_ID } from 'web/composeWindow/libs/composeWindowConstants';

export const setEmailsLoading = (isLoading) => ({
  isLoading,
  type: CommandCenterEmailsActionTypes.emails.loading,
});
export const setEmails = (data) => ({
  data,
  type: CommandCenterEmailsActionTypes.emails.set,
});
export const setEmail = (email = {}) => ({
  email,
  type: CommandCenterEmailsActionTypes.emails.setEmail,
});
export const deleteEmail = (emailId) => ({
  email: { id: emailId },
  type: CommandCenterEmailsActionTypes.emails.deleteEmail,
});
export const deleteEmails = (emailIds) => ({
  emailIds,
  type: CommandCenterEmailsActionTypes.emails.deleteEmails,
});

export const setGroupEmailsAsSingleRow = (payload) => ({
  payload,
  type: EmailGroupsActionType.singleRowEmails.singleRowGroups,
});

export const getEmails = () => (dispatch, getState) => {
  dispatch(setSidebarViewerFromEamilGridAdvancedSearch());
  const state = getState();
  const params = getEmailFetchRequestParams(state);

  dispatch(setEmailsLoading(true));

  return getEmailFetch(params).then(
    ({ results = [], total = 0 }) => {
      dispatch(setEmailsLoading(false));
      dispatch(setEmails({ results, total }));
      dispatch(handleSelectedEmail(results));
      dispatch(markUnreadEmailsAsRead()).then(() =>
        dispatch(getUnreadEmailsCount())
      );
    },
    () => {
      dispatch(setEmailsLoading(false));
      dispatch(openViewAlert(EmailAlertIds.fetchError));
    }
  );
};

export const getUnreadEmailsCount = () => (dispatch, getState) => {
  const states = [
    PitchStates.spam,
    PitchStates.failedDelivery,
    PitchStates.bounced,
    PitchStates.draft,
    PitchStates.deliveryInProgress,
    PitchStates.scheduledForDelivery,
    PitchStates.sent,
    PitchStates.viewed,
    PitchStates.clicked,
    PitchStates.replied,
    PitchStates.successful,
  ];
  const { id: userId } = getUser(getState());
  return getUnreadEmailsCountCall(states, userId)
    .then((response) => {
      const emailCounters = parseUnreadEmailsCounters(response);
      dispatch(setUnreadEmailsCount(emailCounters));
    })
    .catch(() => {});
};

export const emailGroupSearch = (payload) => (dispatch) => {
  dispatch(setGroupEmailsAsSingleRow(payload));
  dispatch(getEmails());
};

export const replyToEmail = (emailId) => (dispatch) => {
  dispatch(setEmailsLoading(true));

  return getReplyEmailDraft(emailId).then(
    ({ result }) => {
      if (result) {
        createPitch({
          pitch: {
            ...result,
            auto_close_task: true,
          },
        }).then(
          (replyEmail) => {
            window.toutReact.composeWindow(COMPOSE_DOM_TARGET_ID, {
              options: { draftId: replyEmail.id },
            });
            dispatch(setEmailsLoading(false));
          },
          () => {
            dispatch(setEmailsLoading(false));
            dispatch(openViewAlert(EmailAlertIds.fetchError));
          }
        );
      }
    },
    () => {
      dispatch(setEmailsLoading(false));
      dispatch(openViewAlert(EmailAlertIds.fetchError));
    }
  );
};

export const sortEmails = (columnToSort = '', directionToSort = '') => (
  dispatch
) => {
  const direction = SORT_DIRECTION[directionToSort.toLowerCase()] || '';
  const column = columnToSort.toLowerCase();
  dispatch(setTableSort(column, direction));
  return dispatch(getEmails());
};

export const refreshEmails = (page = DEFAULT_PAGE) => (dispatch) => {
  dispatch(setTablePage(page));
  return dispatch(getEmails());
};

export const paginateEmailsPerPage = (perPage) => (dispatch) => {
  dispatch(setTablePerPage(perPage));
  return dispatch(refreshEmails(DEFAULT_PAGE));
};
