import React from 'react';
import { connect } from 'react-redux';
import TinyMCE from 'web/shared/components/tinymce/tinymce';
import { ModalComponentIds } from 'web/composeWindow/libs/composeWindowConstants';
import { showCkEditorModal } from 'web/emailComposeBody/actionCreators/popupActionCreators';
import { ModalPopupIds } from 'web/libs/constants';
import './tinyMceEditor.scss';

const TinyMceEditor = ({ id, ...props }) => (
  <div className="tout-ui-styles tiny-mce-editor">
    <TinyMCE editorId={id} {...props} />
  </div>
);

function mapDispatchToProps(dispatch) {
  return {
    openBoxContentPopup: (editorId) => {
      dispatch(
        showCkEditorModal(ModalComponentIds.attachBoxContents, editorId)
      );
    },
    openContentFilesPopup: (editorId) => {
      dispatch(
        showCkEditorModal(ModalComponentIds.attachFilesAndContent, editorId)
      );
    },
    openHighSpotPopup: (editorId) => {
      dispatch(showCkEditorModal(ModalComponentIds.highSpotContent, editorId));
    },
    openImageInsertPopup: (editorId) =>
      dispatch(showCkEditorModal(ModalPopupIds.imageInsert, editorId)),
    openInsertDynamicFieldsPopup: (editorId) =>
      dispatch(showCkEditorModal(ModalPopupIds.dynamicFields, editorId)),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(TinyMceEditor);
