import ChooseMarketoWorkspacesActionTypes from '../libs/chooseMarketoWorkspacesActionTypes';

export const newUserInvitationEmails = (
  state = [],
  { type = '', emails = [] }
) => {
  switch (type) {
    case ChooseMarketoWorkspacesActionTypes.emails:
      return emails;
    default:
      return state;
  }
};
