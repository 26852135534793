import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page/page';
import PageView from 'components/layouts/page/view';
import {
  openIdentityRemovePopup,
  openResendConfirmationPopup,
  openIdentityMakeDefaultErrorPopup,
  clearAlert,
  makeDefault,
  navigateToSmtpServer,
  navigateToEmailConnection,
  navigateToDeliverabilitySection,
} from '../actionCreators/identitiesTableActionCreators';
import IdentitiesTable from '../components/identitiesTable';
import IdentitiesPopupContainer from './identitiesPopupContainer';
import Alert, { AlertColors } from 'components/alert';
import { navigateToEditIdentitySlideout } from 'web/services/routerService';
import { getIdentities } from 'web/user/actionCreators/identitiesActionCreators';
import { getDeliveryChannels } from 'web/deliveryChannels/actionCreators/deliveryChannelsActionCreators';
import './identitiesTableContainer.scss';
import { BackgroundColors } from 'libs/constantsStyles';
import { shouldHideDeliveryChannels as shouldHideDeliveryChannelsSelector } from 'web/deliveryChannels/selectors/deliveryChannelsSelectors';

class IdentititesTableContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          getIdentities,
          getDeliveryChannels,
          clearAlert,
          openIdentityMakeDefaultErrorPopup,
          makeDefault,
          openIdentityRemovePopup,
          openResendConfirmationPopup,
        },
        dispatch
      ),
      navigateToSmtpServer,
      navigateToEmailConnection,
      navigateToDeliverabilitySection,
    };
  }

  componentWillMount() {
    if (this.props.needsFetchIdentities) {
      this.actionCreators.getIdentities();
    }
    if (this.props.needsFetchDeliveryChannels) {
      this.actionCreators.getDeliveryChannels();
    }
  }

  onSelectIdentity = (identity) => {
    navigateToEditIdentitySlideout(identity.id);
  };

  render() {
    const {
      loading,
      fetching,
      error,
      deliveryChannels,
      identities,
      shouldHideDeliveryChannels,
    } = this.props;

    const { actionCreators } = this;

    const alertProperties = {
      color: AlertColors.red,
      location: 'top',
      onClose: actionCreators.clearAlert,
      manual: true,
    };

    return (
      <div className="identities-container">
        <Page>
          <PageView backgroundColor={BackgroundColors.white}>
            {error && (
              <Alert
                {...alertProperties}
                textId="web.settings.sharing.alert.genericIssue"
              />
            )}
            <div className="identities-table-container">
              <IdentitiesTable
                identities={identities}
                shouldHideDeliveryChannels={shouldHideDeliveryChannels}
                deliveryChannels={deliveryChannels}
                fetching={fetching}
                loading={loading}
                onSelectIdentity={this.onSelectIdentity}
                actionCreators={this.actionCreators}
              />
            </div>
          </PageView>
        </Page>
        <IdentitiesPopupContainer />
      </div>
    );
  }
}

IdentititesTableContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  shouldHideDeliveryChannels: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  needsFetchIdentities: PropTypes.bool.isRequired,
  needsFetchDeliveryChannels: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  identities: PropTypes.array.isRequired,
  deliveryChannels: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    loading: state.identitiesTable.loading,
    fetching: state.identitiesFetching || state.deliveryChannelsFetching,
    needsFetchIdentities: !(
      state.identitiesFetching || state.identitiesFetched
    ),
    needsFetchDeliveryChannels: !(
      state.deliveryChannelsFetching || state.deliveryChannelsFetched
    ),
    error: state.identitiesTable.error,
    identities: state.identities,
    deliveryChannels: state.deliveryChannels,
    shouldHideDeliveryChannels: shouldHideDeliveryChannelsSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(IdentititesTableContainer);
