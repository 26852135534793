import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isPristine, isInvalid, submit } from 'redux-form';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  EDIT_TEAMS_FORM_ID,
  ADD_MEMBERS_TO_TEAMS_FORM_ID,
  ADD_MEMBERS_TO_TEAM_FORM_ID,
} from '../libs/teamMembersConstants';
import {
  getSelectedTeam,
  getTeamsSelectOptions,
  getTeamsSelectOptionsWithoutSelected,
} from '../selectors/teamsSelector';
import {
  editTeams,
  deleteTeamMembers,
  addTeamMembersToTeams,
  addTeamMembersToTeam,
} from '../actionCreators/teamMembersActionCreators';
import {
  getEditTeamsLoading,
  getDeleteTeamMembersLoading,
  getAddTeamMembersToTeamsLoading,
  getAddTeamMembersToTeamLoading,
  getPopupTeamMemberTeamIds,
  geUsersSelectOptions,
} from '../selectors/teamMembersSelector';
import { isAdmin } from 'web/user/selectors/userSelectors';
import TeamMembersPopup from '../components/popups/teamMembersPopup';

class TeamMembersPopupContainer extends Component {
  render() {
    return <TeamMembersPopup {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  popup: state.popup,
  popupData: state.popupData,
  selectedTeam: getSelectedTeam(state),
  deleteTeamMembersLoading: getDeleteTeamMembersLoading(state),
  editTeamsLoading: getEditTeamsLoading(state),
  isPristineEditTeamsForm: isPristine(EDIT_TEAMS_FORM_ID)(state),
  popupTeamMemberTeamIds: getPopupTeamMemberTeamIds(state),
  teamsSelectOptions: getTeamsSelectOptions(state),
  teamsSelectOptionsWithoutSelected: getTeamsSelectOptionsWithoutSelected(
    state
  ),
  addMembersToTeamsLoading: getAddTeamMembersToTeamsLoading(state),
  isInvalidAddMembersToTeamsForm: isInvalid(ADD_MEMBERS_TO_TEAMS_FORM_ID)(
    state
  ),
  addMembersToTeamLoading: getAddTeamMembersToTeamLoading(state),
  isInvalidAddMembersToTeamForm: isInvalid(ADD_MEMBERS_TO_TEAM_FORM_ID)(state),
  usersSelectOptions: geUsersSelectOptions(state),
  currentUserId: state.user.id,
  isAdmin: isAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        closePopup,
        submitForm: submit,
        deleteTeamMembers,
        editTeams,
        addTeamMembersToTeams,
        addTeamMembersToTeam,
      },
      dispatch
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMembersPopupContainer);
