import React from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Popup from 'components/popups/popup';
import { PopupSize } from 'libs/constantsShared';

function IdentityMakeDefaultErrorPopup(props) {
  const { actionCreators } = props;

  return (
    <Popup
      className="salesforce-customization-popup"
      footer={{
        onPrimary: actionCreators.closePopup,
        primaryTextId: 'common.cancel',
      }}
      header={{
        onClose: actionCreators.closePopup,
        textId: 'web.identities.makeDefaultError.popup.header',
      }}
      onOutsideClick={actionCreators.closePopup}
      size={PopupSize.small}
    >
      <div className="margin-bottom-full">
        <FormattedHTMLMessage id="web.identities.makeDefaultError.popup.body" />
      </div>
    </Popup>
  );
}

IdentityMakeDefaultErrorPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(IdentityMakeDefaultErrorPopup);
