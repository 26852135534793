let tracker;
let trackerId;

export const setTracker = (tableId, trackerFuction) => {
  tracker = trackerFuction;
  trackerId = tableId;
};

export const track = (event, data) => {
  if (tracker) {
    tracker(event, { tableId: trackerId, ...data });
  }
};

export default track;
