import { selectCommandCenterMenuItem } from 'web/commandCenter/actionCreators/commandCenterSidebarActionCreators';
import { SidebarMenuItems } from 'web/commandCenter/libs/commandCenterConstants';
import { getInitTasks } from 'web/commandCenter/actionCreators/commandCenterTasksActionCreators';
import { initCommandCenter } from 'web/commandCenter/actionCreators/commandCenterActionCreators';
import { resetEmailSelectedSavedAdvancedSearchId } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/openAdvancedSearchActionCreators';
import { initSidebarViewer } from 'web/sidebar/viewers/actionCreators/viewersActionCreators';

export const tasksStartup = () => (dispatch, getState) => {
  const { isFilterChanged } = getState();
  dispatch(initCommandCenter());
  if (!isFilterChanged) {
    dispatch(initSidebarViewer());
  }
  dispatch(getInitTasks());
  dispatch(resetEmailSelectedSavedAdvancedSearchId());
  dispatch(selectCommandCenterMenuItem(SidebarMenuItems.tasks.id));
};
