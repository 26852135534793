import moment from 'moment';
import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getTemplateStats(id, from, to, team, viewer) {
  const fromDate = moment(from).format('YYYY-MM-DD');
  const toDate = moment(to).toISOString();
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.templateStats.replace(':#id', id),
      {
        state: [
          'sent',
          'viewed',
          'clicked',
          'replied',
          'successful',
          'delivered',
          'bounced',
          'spam',
          'failed_delivery',
        ],
        from: fromDate,
        to: toDate,
        team,
        viewer_id: viewer.id,
      },
      resolve,
      reject
    );
  });
}
