import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StatShape } from '../statsShapes';
import { StrokeColors } from 'libs/constantsStyles';
import './circlePie.scss';

const INNER_RADIUS = 0.8;

class CirclePie extends Component {
  render() {
    const {
      fontSizeLabel,
      fontSizeTotal,
      label,
      radius,
      showCount,
      total,
    } = this.props;
    const length = radius * 2;
    const lengthPx = `${length}px`;

    return (
      <div className="circle-pie" style={{ height: lengthPx, width: lengthPx }}>
        <svg className="circle-pie-svg" width={length} height={length}>
          {this._getCircles()}
          <circle
            className="circle-pie-inner-circle"
            r={radius * INNER_RADIUS}
            cx={radius}
            cy={radius}
          />
        </svg>
        {showCount && (
          <div className="circle-pie-text">
            <div
              className="circle-pie-text-total"
              style={{
                fontSize: `${fontSizeTotal}px`,
                lineHeight: `${fontSizeTotal}px`,
              }}
            >
              {total}
            </div>
            {label && (
              <div
                className="circle-pie-text-label"
                style={{ fontSize: `${fontSizeLabel}px` }}
              >
                {label}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  _getCircles = () => {
    const { stats, radius, ringRadius, total } = this.props;
    const circleRadius = radius / 2;
    const strokeDasharrayTotal = Math.ceil(2 * Math.PI * circleRadius);
    let circleRadiusTotal = strokeDasharrayTotal;

    const circles = [...stats]
      .reverse()
      .reduce((accumulator, { color, count, percent }) => {
        const radiusProportion = this._getRadiusProportion(
          percent,
          count,
          total
        );
        if (radiusProportion <= 0) {
          return accumulator;
        }

        const currentStrokeDasharray = circleRadiusTotal;
        circleRadiusTotal -= radiusProportion * strokeDasharrayTotal;
        accumulator.push(
          <circle
            className={classNames('circle-pie-outer-ring', {
              [`stroke-${color}`]: color,
            })}
            cx={radius}
            cy={radius}
            key={`circle-pie-slice-${color}`}
            r={circleRadius}
            strokeWidth={`${radius}px`}
            strokeDasharray={`${currentStrokeDasharray} ${strokeDasharrayTotal}`}
          />
        );
        return accumulator;
      }, []);

    /* Add empty state */
    if (circles.length === 0) {
      circles.push(
        <circle
          className="circle-pie-outer-ring stroke-gray-extra-light"
          cx={radius}
          cy={radius}
          key="circle-pie-empty-state"
          r={circleRadius}
          strokeWidth={`${ringRadius}px`}
          strokeDasharray={`${strokeDasharrayTotal} ${strokeDasharrayTotal}`}
        />
      );
    }

    return circles;
  };

  _getRadiusProportion(percent, count, total) {
    if (percent > -1) {
      return percent / 100;
    }
    if (total <= 0 || !count || count <= 0) {
      return 0;
    }
    return count / total;
  }
}

export const CirclePieColors = { ...StrokeColors };

CirclePie.propTypes = {
  fontSizeLabel: PropTypes.number,
  fontSizeTotal: PropTypes.number,
  label: PropTypes.string,
  radius: PropTypes.number,
  ringRadius: PropTypes.number,
  showCount: PropTypes.bool,
  stats: PropTypes.arrayOf(StatShape).isRequired,
  total: PropTypes.number,
};

CirclePie.defaultProps = {
  fontSizeLabel: 14,
  fontSizeTotal: 36,
  label: '',
  radius: 50,
  ringRadius: 50,
  showCount: true,
  total: 0,
};

export default CirclePie;
