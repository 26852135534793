import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BulkEmails } from 'web/composeWindow/libs/composeWindowConstants';
import { hasUndeliverableStates } from 'web/composeWindow/helpers/composeWindowHelpers';
import bulkHoverTooltip from 'web/composeWindow/hoc/bulkHoverTooltip';
import './bulkEmailsIconCell.scss';

const BulkEmailsIconCell = (props) => {
  const {
    rowData,
    rowData: { state },
    iconType,
    removeRecipient,
  } = props;

  function getIconColor(state) {
    const warnCssClass = 'warn';
    const errorCssClass = 'error';

    switch (state) {
      case BulkEmails.stateReason.blocked:
      case BulkEmails.stateReason.unsubscribed:
        return warnCssClass;
      case BulkEmails.stateReason.invalidDynamicFields:
        return errorCssClass;
      default:
        return null;
    }
  }

  function getIcon(iconType) {
    switch (iconType) {
      case BulkEmails.iconTypes.warning: {
        if (hasUndeliverableStates(state)) {
          return (
            <i
              className={classNames('tout-icon-warning', getIconColor(state))}
            />
          );
        } else if (state === BulkEmails.stateReason.success) {
          return (
            <i className={classNames('tout-icon-tick', getIconColor(state))} />
          );
        }
        break;
      }
      case BulkEmails.iconTypes.delete:
        return <i className="tout-icon-close" onClick={onClick} />;
      default:
        return null;
    }
  }

  function onClick(e) {
    e.preventDefault();
    e.stopPropagation();

    removeRecipient(rowData);
  }

  return <div className="bulk-emails-icon-cell">{getIcon(iconType)}</div>;
};

BulkEmailsIconCell.propTypes = {
  iconType: PropTypes.string,
  removeRecipient: PropTypes.func,
  rowData: PropTypes.object.isRequired,
};

BulkEmailsIconCell.defaultProps = {
  iconType: '',
  removeRecipient: () => {},
};

export default bulkHoverTooltip(BulkEmailsIconCell);
