import React from 'react';
import PropTypes from 'prop-types';
import BaseCheckbox from 'components/buttons/checkbox';

const Checkbox = ({ input, ...other }) => (
  <BaseCheckbox {...input} {...other} checked={input.value} />
);

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Checkbox;
