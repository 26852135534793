import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { ApiErrors, Regex } from 'web/libs/constants';
import { parseAutocompleteContacts } from 'web/services/parsers';
import {
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { addPersonObject } from 'web/people/peopleSearchAdd/actionCreators/peopleActionCreators';
import {
  ALL_PERSON_QUERY_FIELDS,
  COLUMN_PITCHES_SENT,
  MAX_AUTOCOMPLETE_RESULTS,
  PEOPLE_SEARCH_RESULT_FIELDS,
} from 'web/people/peopleSearchAdd/libs/peopleSearchAddConstants';
import { findOrCreateContact as findOrCreateContactCall } from 'web/people/services/peopleService';
import { SourceTypes } from 'web/compliance/libs/complianceConstants';
import { esFetch } from 'web/elasticSearch/services/esFetch';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';
import { SortDirections } from 'libs/constantsShared';

function autocompleteCall() {
  return (dispatch, getState) => {
    const { autocompleteValue } = getState();
    dispatch({ type: ActionTypes.api.autocomplete.request });
    esFetch({
      column: COLUMN_PITCHES_SENT,
      direction: SortDirections.desc,
      includeFields: PEOPLE_SEARCH_RESULT_FIELDS,
      perPage: MAX_AUTOCOMPLETE_RESULTS,
      textSearch: {
        fields: ALL_PERSON_QUERY_FIELDS,
        term: autocompleteValue,
      },
      type: ElasticSearchTypes.people,
    })
      .then((autoCompleteResults) => {
        let data = [];
        if (autoCompleteResults && autoCompleteResults.results) {
          data = parseAutocompleteContacts(autoCompleteResults.results);
        }
        dispatch({
          data,
          type: ActionTypes.api.autocomplete.success,
        });
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.api.autocomplete.failure,
        });
      });
  };
}

let autocompleteTimeout;
export function getAutocompleteContacts(value, minLength = 3) {
  return (dispatch) => {
    if (autocompleteTimeout || value.length < minLength) {
      clearTimeout(autocompleteTimeout);
    }

    if (value.length >= minLength) {
      autocompleteTimeout = setTimeout(() => dispatch(autocompleteCall()), 300);
    }
  };
}

export function addPeopleAutocompleteChange(value = '') {
  return (dispatch) => {
    dispatch({ data: value, type: ActionTypes.autocomplete.state.value });
    dispatch(getAutocompleteContacts(value, MAX_AUTOCOMPLETE_RESULTS));
  };
}

export function findOrCreateContact(value, source = '') {
  return (dispatch) => {
    dispatch(setPopupLoading(true));
    findOrCreateContactCall(value, source)
      .then((data = {}) => {
        const parsedData = parseAutocompleteContacts([data]);
        if (parsedData.length) {
          dispatch(setPopupLoading(false));
          dispatch(addPersonObject(parsedData[0]));
          return data;
        } else {
          return Promise.reject({ response: { body: {} } });
        }
      })
      .catch((ex) => {
        const { error } = ex.response.body;
        dispatch(setPopupLoading(false));
        if (error == ApiErrors.addNewPeopleDisabled) {
          dispatch(
            setPopupAlert(PeopleAlertIds.peopleSearchAdd.addNewPeopleDisabled)
          );
        } else {
          dispatch(
            setPopupAlert(PeopleAlertIds.peopleSearchAdd.needsToBeEmail)
          );
        }
      });
  };
}

export function addPeopleLeaveAutocomplete(value = '') {
  return (dispatch) => {
    const validEmail = value.match(Regex.email);
    if (value.length && !validEmail) {
      dispatch(setPopupAlert(PeopleAlertIds.peopleSearchAdd.needsToBeEmail));
    } else if (value.length) {
      dispatch(findOrCreateContact(value, SourceTypes.sales));
    }
    dispatch({ type: ActionTypes.autocomplete.data.clear });
  };
}
