import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  storeEditorInstance,
  updateEditorBodies,
} from 'web/emailComposeBody/actionCreators/emailComposeActionCreators';
import TinyMceEditor from 'web/tinyMceEditor/components/tinyMceEditor';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { validateUnsubscribeText } from '../helpers/validateUnsubscribeText';
import SettingsUnsubscribesConstants from '../libs/settingsUnsubscribesConstants';
import { isAdmin } from 'web/shared/services/accountService';
import Action from 'components/buttons/action';
import classNames from 'classnames';

class SettingsUnsubscribesEditor extends Component {
  componentDidMount() {
    const { updateEditorBodies, defaultBody, id } = this.props;

    updateEditorBodies(id, defaultBody);
  }

  render() {
    const {
      defaultBody,
      id,
      options,
      selected,
      storeEditorInstance,
      updateEditorBodies,
    } = this.props;

    return (
      <div
        className={classNames('settings-unsubscribes-editor', {
          'settings-unsubscribes-disable': isAdmin() && selected,
        })}
      >
        <div className="margin-bottom-most">
          <TinyMceEditor
            disabled={isAdmin() && selected}
            handleEditorChange={(body) => updateEditorBodies(id, body)}
            id={id}
            options={options}
            storeEditorInstance={(instance) =>
              storeEditorInstance(id, instance)
            }
            value={defaultBody}
          />
        </div>
        <div className="margin-top-full">
          <FormattedHTMLMessage id="web.settings.unsubscribe.descriptionNote" />
        </div>
        <div className="margin-top-full right">
          <Action onClick={this.save}>
            <FormattedMessage id="common.save" />
          </Action>
        </div>
      </div>
    );
  }

  save = () => {
    const { body, openAlert, save, updateEditorBody } = this.props;

    if (validateUnsubscribeText(body)) {
      updateEditorBody(body);

      save(
        SettingsUnsubscribesConstants.alertIds.saveUnsubscribeMessagingSuccess
      );
    } else {
      openAlert(
        SettingsUnsubscribesConstants.alertIds.noValidateUnsubscribeText
      );
    }
  };
}

SettingsUnsubscribesEditor.propTypes = {
  body: PropTypes.string,
  defaultBody: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  openAlert: PropTypes.func.isRequired,
  options: PropTypes.object,
  save: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  storeEditorInstance: PropTypes.func.isRequired,
  updateEditorBodies: PropTypes.func.isRequired,
  updateEditorBody: PropTypes.func.isRequired,
};

SettingsUnsubscribesEditor.defaultProps = {
  body: null,
  options: null,
};

const mapStateToProps = (state, props) => ({
  body: state.emailComposeCkeBodies[props.id],
  selected: state.settingsUnsubscribes.allow_override_unsubscribe_text,
});

const mapDispatchToProps = {
  openAlert: openViewAlert,
  storeEditorInstance,
  updateEditorBodies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsUnsubscribesEditor);
