import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';

export const initFilterInput = '';
export const templatesFilterInput = (
  state = initFilterInput,
  { type = '', inputValue = initFilterInput }
) => {
  switch (type) {
    case TemplatesActionTypes.sidebarFilterInput.set:
      return inputValue;
    default:
      return state;
  }
};
