import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { BackgroundColors, TextColors } from 'libs/constantsStyles';
import './popupHeader.scss';

const PopupHeader = ({
  backgroundColor,
  children,
  className,
  onClose,
  text,
  textColor,
  textId,
  textValues,
}) => {
  const classes = classNames('popup-header', className, {
    [`text-${textColor}`]: textColor,
    [`background-${backgroundColor}`]: backgroundColor,
  });
  const onCloseClasses = classNames('tout-icon-close', {
    [`text-${textColor}`]: textColor,
  });

  return (
    ((children || text || textId) && (
      <h2 className={classes}>
        {children ||
          text || <FormattedMessage id={textId} values={textValues} />}
        {onClose && <div className={onCloseClasses} onClick={onClose} />}
      </h2>
    )) ||
    null
  );
};

PopupHeader.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  text: PropTypes.string,
  textColor: PropTypes.string,
  textId: PropTypes.string,
  textValues: PropTypes.object,
};

PopupHeader.defaultProps = {
  backgroundColor: BackgroundColors.white,
  children: undefined,
  className: '',
  onClose: undefined,
  text: '',
  textColor: TextColors.black,
  textId: '',
  textValues: undefined,
};

export default PopupHeader;
