import PropTypes from 'prop-types';
import React from 'react';
import { getAlertProps } from '../helpers/globalBannerAlertsHelpers';
import Alert from 'components/alert';

export default class GlobalBannerAlerts extends React.Component {
  _buildAlerts() {
    const { closeAlertById, alerts } = this.props;
    return alerts.map((alert) => {
      const alertProps = getAlertProps(
        closeAlertById,
        alert.id,
        alert.textValues
      );
      return (
        !!alertProps && (
          <div className="global-alert-item" key={alert.id}>
            <Alert {...alertProps} />
          </div>
        )
      );
    });
  }

  render() {
    return (
      <div className="tout-ui-styles global-alerts-container">
        {this._buildAlerts()}
      </div>
    );
  }
}

GlobalBannerAlerts.propTypes = {
  alerts: PropTypes.array,
  closeAlertById: PropTypes.func.isRequired,
};

GlobalBannerAlerts.defaultProps = {
  alerts: [],
};
