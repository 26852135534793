import React from 'react';
import PropsTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {
  SyncToSalesforceItems,
  TOUT_OPT_OUT__C_KEY,
} from '../libs/adminUnsubscribesConstants';
import UnsubscribesSyncToSalesforce from '../containers/unsubscribesSyncToSalesforceContainer';
import MarketoSalesOptOutWarning from './marketoSalesOptOutWarning/marketoSalesOptOutWarning';

const SalesforceMarketoSalesOptOut = ({ salesforceSyncField }) => (
  <div className="margin-top-full">
    <h2 className="margin-bottom-most">
      <FormattedMessage id="web.settings.unsubscribe.salesforce.marketoSalesOptOut.title" />
    </h2>
    <p>
      <FormattedMessage id="web.settings.unsubscribe.salesforce.marketoSalesOptOut.desc" />
    </p>
    <div className="margin-bottom-most margin-top-most">
      <UnsubscribesSyncToSalesforce />
    </div>
    <p>
      <FormattedMessage id="web.settings.unsubscribe.salesforce.description.default" />
    </p>
    <FormattedHTMLMessage id="web.settings.unsubscribe.salesforce.instructions" />
    {salesforceSyncField ===
    SyncToSalesforceItems[TOUT_OPT_OUT__C_KEY].value ? (
      <MarketoSalesOptOutWarning />
    ) : null}
  </div>
);

SalesforceMarketoSalesOptOut.propTypes = {
  salesforceSyncField: PropsTypes.string.isRequired,
};

export default SalesforceMarketoSalesOptOut;
