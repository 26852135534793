import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { Urls } from 'web/libs/routes';
import {
  GetStartedEvents,
  GetStartedProperties,
  GetStartedPropertyTypes,
} from 'web/libs/mixpanelEvents';
import { openNewTab } from 'web/services/routerService';
import { track } from 'web/services/mixpanelService';
import { getImageUrl } from 'web/libs/constants';
import { EmailSettingsTabs } from 'web/settings/email/libs/emailSettingsConstants';
import './getStarted.scss';
import { getConnectLink } from 'web/salesforce/helpers/salesforceHelpers';

const gmailIcon = getImageUrl('gmail-icon', 'svg');
const outlookIcon = getImageUrl('outlook-icon', 'svg');

class GetStarted extends Component {
  render() {
    return (
      <div className="get-started-container">
        <h3 className="get-started-title">
          <FormattedMessage id="web.getStarted.title" />
        </h3>
        <div className="get-started-columns">
          <div className="left-column">
            <div className="info-panel first-panel">
              <div className="title">
                <FormattedMessage id="web.getStarted.connectCrm.label" />
              </div>
              <div className="button-item">
                <div className="buttons">
                  <div className="button-item-element">
                    <Button
                      className="medium"
                      color={ButtonColors.blue}
                      onClick={this.setupCRM}
                      size={ButtonSizes.standard}
                    >
                      <FormattedMessage id="web.getStarted.connectCrm.button" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-panel">
              <div className="title">
                <FormattedMessage id="web.getStarted.downloadChrome.label" />
              </div>
              <div className="button-item first-button">
                <div className="item_description">
                  <FormattedMessage id="web.getStarted.downloadChrome.description" />
                </div>
                <div className="buttons">
                  <div className="button-item-element">
                    <Button
                      className="medium"
                      color={ButtonColors.blue}
                      onClick={this.downloadChrome}
                      size={ButtonSizes.standard}
                    >
                      <FormattedMessage id="web.getStarted.downloadChrome.button" />
                    </Button>
                  </div>
                  <img
                    className="button-item-element icon"
                    src={gmailIcon}
                    alt="icon"
                  />
                </div>
              </div>
              <div className="button-item">
                <div className="item_description">
                  <FormattedMessage id="web.getStarted.downloadOutlook.description" />
                </div>
                <div className="buttons">
                  <div className="button-item-element">
                    <Button
                      className="medium"
                      color={ButtonColors.blue}
                      onClick={this.downloadOutlook}
                      size={ButtonSizes.standard}
                    >
                      <FormattedMessage id="web.getStarted.downloadOutlook.button" />
                    </Button>
                  </div>
                  <img
                    className="button-item-element icon"
                    src={outlookIcon}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="right-column">
            <div className="info-panel resources-panel">
              <a href={Urls.commandCenter}>
                <FormattedHTMLMessage id="web.getStarted.continueToMarketoSalesEngage" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  downloadOutlook = () => {
    track(GetStartedEvents.clickButton, {
      [GetStartedProperties.actionType]: GetStartedPropertyTypes.outlook,
    });
    openNewTab(Urls.next.settings_email(EmailSettingsTabs.emailPlugins));
  };

  downloadChrome = () => {
    track(GetStartedEvents.clickButton, {
      [GetStartedProperties.actionType]: GetStartedPropertyTypes.gmail,
    });
    openNewTab(Urls.next.chrome_extension);
  };

  setupCRM = () => {
    const { isAdmin } = this.props;
    track(GetStartedEvents.clickButton, {
      [GetStartedProperties.actionType]: GetStartedPropertyTypes.connectToCRM,
    });
    openNewTab(getConnectLink(isAdmin));
  };
}

GetStarted.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default GetStarted;
