import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import {
  PEOPLE_AUTHORIZATION_POPUP_ID,
  ModalComponentIds,
} from 'web/compliance/libs/complianceConstants';
import ComplianceActionTypes from 'web/compliance/libs/complianceActionTypes';
import {
  openPopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { savePeopleAuthorization as savePeopleAuthorizationCall } from 'web/people/services/peopleService';
import { formatPeopleComplianceAuthorization } from 'web/people/helpers/peopleAuthorizationHelpers';
import { getPeople } from 'web/people/actionCreators/peopleFetchActionCreators';
import PeoplePopupIds from 'web/people/libs/peoplePopupIds';

export const peopleAuthorizationStartup = (peopleIds) => (dispatch) => {
  dispatch(openPopup(PEOPLE_AUTHORIZATION_POPUP_ID, peopleIds));
  dispatch({
    type: ComplianceActionTypes.modal.set,
    modal: ModalComponentIds.authorizationEdit,
  });
};

export const updateAuthorization = (edits) => ({
  type: PeopleActionTypes.peopleAuthorization.set,
  edits,
});

export const resetAuthorizationFields = () => ({
  type: PeopleActionTypes.peopleAuthorization.reset,
});

export const closeAuthorizationPopup = () => (dispatch) => {
  dispatch(resetAuthorizationFields());
  dispatch({ type: ComplianceActionTypes.modal.clear });
  dispatch({ type: PopupActionTypes.close });
};

export const savePeopleAuthorization = () => (dispatch, getState) => {
  const {
    peopleAuthorization: {
      authorizationType,
      consentDate,
      consentNotesValue,
      consentPurposeValue,
      otherAuthorizationValue,
    },
    popupData: { peopleIds, callBack: onSuccess },
  } = getState();

  const postData = formatPeopleComplianceAuthorization({
    authorizationType,
    consentDate,
    consentNotesValue,
    consentPurposeValue,
    otherAuthorizationValue,
  });

  dispatch(setPopupLoading(true));
  savePeopleAuthorizationCall(peopleIds, postData)
    .then(() => {
      dispatch({
        type: ComplianceActionTypes.modal.set,
        modal: ModalComponentIds.authorizationEditingSuccess,
      });
      dispatch(
        openPopup(PeoplePopupIds.authorizationPeopleSuccess, {
          peopleCount: peopleIds.length,
        })
      );
      dispatch(resetAuthorizationFields());
      toutBackboneHelper.refreshPeopleTable();
      onSuccess();
      dispatch(getPeople());
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.peopleAuthorization.saveFail));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};
