import { AlertColors } from 'components/alert';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';

export const getPopupAlert = (id, clearPopupAlert) => {
  const defaultAlert = {
    color: AlertColors.red,
    onClose: clearPopupAlert,
  };

  switch (id) {
    case PeopleAlertIds.peopleCompliance.saveFail:
      return {
        ...defaultAlert,
        textId: 'web.people.compliance.modal.saveFail',
      };
    default:
      return null;
  }
};
