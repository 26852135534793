import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { CommandCenterTasksTableId } from '../libs/commandCenterConstants';
import CommandCenterHeader from '../components/commandCenterHeader';
import CommandCenterTasks from '../components/commandCenterTasks';
import CommandCenterPopupContainer from './commandCenterPopupContainer';
import PersonDetailsSlideOut from 'web/slideOuts/personDetails';
import withCommonModals from 'web/modals/withCommonModals/components/withCommonModals';
import {
  getFetching,
  getTasks,
  isSubscriptionsUserTask,
} from '../selectors/commandCenterSelectors';
import { openAddTask } from 'web/commandCenter/actionCreators/commandCenterActionCreators';
import './commandCenterContainer.scss';

class CommandCenterContainer extends Component {
  constructor() {
    super();

    this.actionCreators = {
      openTask: (task) => openAddTask(task), // overwrite TasksTableContainer,
    };
  }

  render() {
    const {
      fetching,
      isSubscriptionsUserTask,
      loading,
      tasks,
      user,
      userMetric,
      withTopNav,
      titleId,
    } = this.props;
    return (
      <React.Fragment>
        <CommandCenterPopupContainer key="command-center-popup-container" />
        <PersonDetailsSlideOut key="command-center-person-details-slide-out" />
        <div
          className={classNames('command-center', {
            'walkthrough-banner': userMetric.nux,
            'with-top-nav': withTopNav,
          })}
          key="command-center-container"
        >
          <CommandCenterHeader
            disabledAddTask={isSubscriptionsUserTask}
            openTask={this.actionCreators.openTask}
            titleId={titleId}
          />
          <CommandCenterTasks
            actionCreators={this.actionCreators}
            allowViewAsFilter={user.admin}
            fetching={fetching}
            isActionsDisabled={isSubscriptionsUserTask}
            loading={loading}
            tasks={tasks}
          />
        </div>
      </React.Fragment>
    );
  }
}

CommandCenterContainer.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isSubscriptionsUserTask: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  tasks: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userMetric: PropTypes.object.isRequired,
  withTopNav: PropTypes.bool,
  titleId: PropTypes.string,
};

CommandCenterContainer.defaultProps = {
  withTopNav: true,
  titleId: 'common.tasks',
};

function mapStateToProps(state) {
  return {
    fetching: getFetching(state),
    isSubscriptionsUserTask: isSubscriptionsUserTask(state),
    loading: state.commandCenterLoading,
    tasks: getTasks(state, { tableId: CommandCenterTasksTableId }), // need tableId prop passed in for ToutTable selectors
    user: state.user,
    userMetric: state.userMetric,
  };
}

export default compose(
  connect(mapStateToProps),
  withCommonModals
)(CommandCenterContainer);
