import { Urls } from 'web/libs/routes';
import { LocalStorageKeys, Regex } from 'web/libs/constants';
import { isOwaPluginMode } from 'web/services/windowService';
import {
  EmailConnectionAlertIds,
  EmailConnectionLocalStorageKeys,
  EmailProviderBackendKeys,
  EmailProviders,
  EmailProvidersText,
} from 'web/settings/emailConnection/lib/emailConnectionConstants';
import { navigateToUrl } from 'web/services/routerService';

export const localStorageChecker = (key, callback) => {
  const delay = 1500;
  const timerID = setInterval(() => {
    if (localStorage.getItem(key)) {
      callback();
      clearTimeout(timerID);
    }
  }, delay);
};

export const connectEmail = (provider, currentStep) => {
  window.localStorage.setItem(
    LocalStorageKeys.emailConnection.connecting,
    EmailConnectionLocalStorageKeys.connecting
  );

  switch (provider) {
    case EmailProviders.gmail:
      if (currentStep !== undefined) {
        window.open(Urls.onboardingConnectionGmail);
      } else {
        navigateToUrl(Urls.emailAuth.google);
      }
      break;
    case EmailProviders.office365:
      if (currentStep !== undefined) {
        const url = isOwaPluginMode()
          ? Urls.onboardingConnectionOwaOffice365
          : Urls.onboardingConnectionOffice365;

        window.open(url);
      } else {
        navigateToUrl(
          isOwaPluginMode()
            ? Urls.emailAuth.owaOffice365
            : Urls.emailAuth.office365
        );
      }
      break;
    default:
  }
};

export const hasTriedToConnect = () =>
  window.localStorage.getItem(LocalStorageKeys.emailConnection.connecting) ===
  EmailConnectionLocalStorageKeys.connecting;

export const removeConnectEmailLocalStorageKey = () => {
  window.localStorage.removeItem(LocalStorageKeys.emailConnection.connecting);
};

export const determineProviderText = (provider) => {
  switch (provider) {
    case EmailProviderBackendKeys.gmail:
    case EmailProviders.gmail:
      return EmailProvidersText.gmail;
    case EmailProviders.office365:
      return EmailProvidersText.office365;
    case EmailProviders.onPremise:
      return EmailProvidersText.onPremise;
    default:
      return '';
  }
};

export const mapToBackendKeys = (data = {}) => {
  const mappedData = {};

  mappedData.password = data.password;
  mappedData.profile_url = data.url;
  mappedData.location = data.version;
  mappedData.username = data.email;

  return mappedData;
};

const validateFormat = ({ email, url }) => {
  if (!Regex.email.test(email)) {
    return EmailConnectionAlertIds.form.invalidCredentials;
  } else if (url && !Regex.website.test(url)) {
    return EmailConnectionAlertIds.form.invalidUrlFormat;
  }

  return null;
};

export const validateFields = (data) => {
  const { password, email } = data;

  let error;

  if (email === '') {
    error = EmailConnectionAlertIds.form.missingEmail;
  } else if (password === '') {
    error = EmailConnectionAlertIds.form.missingPassword;
  } else {
    error = validateFormat(data);
  }

  return error;
};
