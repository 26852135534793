import { AlertColors } from 'components/alert';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';

export function getAlert({ alert }, { clearPopupAlert }) {
  const defaultAlert = {
    color: AlertColors.red,
    onClose: clearPopupAlert,
  };

  switch (alert) {
    case EmailAlertIds.createSavedSearchNameEmpty:
      return {
        ...defaultAlert,
        textId: 'web.emails.savedSearchNameEmpty',
      };
    case EmailAlertIds.createSavedSearchNameExists:
      return {
        ...defaultAlert,
        textId: 'web.emails.savedSearchNameExists',
      };
    default:
      return null;
  }
}
