import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/fp/uniq';
import map from 'lodash/fp/map';
import identity from 'lodash/fp/identity';
import size from 'lodash/fp/size';
import compose from 'lodash/fp/compose';
import ToutTable from 'table/modules/toutTable';
import getColumns from './commandCenterEmailsTable.columns';
import { SortingDirection } from 'table/modules/constants';
import { getImageUrl, Regex } from 'web/libs/constants';
import {
  CommandCenterEmailsTabsEnum,
  ROW_HEIGHT,
  EMAILS_TABLE_DATE_RANGES_KEY,
} from 'web/commandCenter/libs/commandCenterConstants';
import { injectIntl, intlShape } from 'react-intl';
import { HoverTooltipPlace } from 'components/hoverTooltip';
import TableExternals from 'web/libs/tableExternals';
import dateRangeSmartFilter from 'web/tables/dateRangeSmartFilter';
import {
  getEmailsGridDateRangesByStatus,
  getColumnsSettingsTableId,
} from 'web/commandCenter/helpers/emailHelpers';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';
import Switch from 'components/buttons/switch';
import './commandCenterEmailsTable.scss';
import { navigateToEmailGroupSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/helpers/advancedSearchHelpers';
import { MAX_SELECTED_ITEMS_FOR_EMAIL_BULK_ACTION } from 'web/people/libs/peopleConstants';
import HoverTooltip from 'components/hoverTooltip';
import Icon from 'components/buttons/icon';

const commandCenterEmailsTableExternals = new TableExternals();
const TableWithDateRange = dateRangeSmartFilter(ToutTable);

class CommandCenterEmailsTable extends Component {
  constructor(props) {
    super(props);

    const {
      actionCreators,
      intl: { formatMessage },
    } = props;

    this.actions = [
      {
        disabledTooltipText: formatMessage({
          id: 'web.commandCenter.emailSelected.bulkActionDisabledTooltip',
        }),
        label: formatMessage({ id: 'web.commandCenter.emailSelected' }),
        onClick: (emailIds, callback, emails) =>
          actionCreators.emailSelected(emailIds, emails),
        tooltipPlace: HoverTooltipPlace.top,
      },
      {
        label: formatMessage({ id: 'web.people.addSelectedToCampaign' }),
        onClick: (emailIds, callback, emails) => {
          actionCreators.addSelectedToCampaign(emailIds, emails);
        },
      },
    ];

    this.dropdownActions = this._getDropdownActions();

    this.search = {
      noDropdown: true,
      onChange: actionCreators.emailSearch,
      onClear: actionCreators.emailResetSearch,
      placeholderText: formatMessage({
        id: 'web.commandCenter.emails.searchPlaceholder',
      }),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.commandCenterEmailsSubTab !==
      prevProps.commandCenterEmailsSubTab
    ) {
      commandCenterEmailsTableExternals.clearSelectedIds();
      this.dropdownActions = this._getDropdownActions();
    }
  }

  componentWillUnmount() {
    commandCenterEmailsTableExternals.unset();
  }

  _getDateRangeList() {
    const { isScheduleStatus } = this.props;
    return getEmailsGridDateRangesByStatus(isScheduleStatus);
  }

  render() {
    const {
      actionCreators,
      columnSettings,
      emails,
      intl: { formatMessage },
      loading,
      totalCount,
      commandCenterEmailsSubTab,
      tableId,
    } = this.props;

    const columns = getColumns(
      this.sortColumn,
      this.sortColumnOpposite,
      formatMessage,
      actionCreators,
      commandCenterEmailsSubTab
    );

    return (
      <div className="command-center-emails-table">
        <TableWithDateRange
          bulkActions={{
            actions: this.actions,
            dropdownActions: this.dropdownActions,
            totalCount,
          }}
          changeColumnsSettingsHandler={(columnsSettings) => {
            actionCreators.changeColumnsSettings(
              getColumnsSettingsTableId(tableId),
              columnsSettings
            );
          }}
          changeSelectedItemsCountHandler={this.changeSelectedItemsCount}
          columnFilterPanel={this._getSelectAllGroups()}
          columnSettings={columnSettings}
          columns={columns}
          dateRangeFilters={this._getDateRangeList()}
          dateRangeFiltersChangeHandler={actionCreators.getEmailsAC}
          dateRangeSyncKey={EMAILS_TABLE_DATE_RANGES_KEY}
          items={emails}
          loading={loading}
          loadingGetUrl={getImageUrl}
          openAdvancedSearch={actionCreators.openAdvancedSearchFormPopup}
          pagination={this.getPagination()}
          row={{
            height: ROW_HEIGHT,
            onClick: this.openPersonDetails,
          }}
          search={this.getSearch()}
          selectAllDisabled={true}
          setTableExternals={this.setTableExternals}
          tableId={tableId}
          columnFilter
          isResize
          reorderColumnsEnable
          selectable
        />
      </div>
    );
  }

  changeSelectedItemsCount = (selectedCount) => {
    const emailPeopleBulkAction = this.actions[0];
    emailPeopleBulkAction.disabled =
      selectedCount > MAX_SELECTED_ITEMS_FOR_EMAIL_BULK_ACTION;
  };

  sortColumn = (property, index, direction) => {
    this.props.actionCreators.sortEmails(property, direction);
  };

  sortColumnOpposite = (property, index, direction) => {
    const oppositeDirection =
      direction === SortingDirection.ASC
        ? SortingDirection.DESC
        : SortingDirection.ASC;
    this.props.actionCreators.sortEmails(property, oppositeDirection);
  };

  openPersonDetails = ({ email, id: emailId, person_id: personId }) => {
    const { openViewAlert, openPersonDetails } = this.props.actionCreators;
    const isNonGroupPitch = email.match(Regex.email);
    if (!personId && isNonGroupPitch) {
      openViewAlert(EmailAlertIds.personForEmailNotFoundError);
      return;
    }
    if (isNonGroupPitch) {
      openPersonDetails(personId, { emailId });
    } else {
      navigateToEmailGroupSearch(emailId);
    }
  };

  setTableExternals = (obj) => commandCenterEmailsTableExternals.set(obj);

  getPagination = () => {
    const {
      actionCreators: { refreshEmails, paginateEmailsPerPage },
      page,
      perPage,
      totalCount,
    } = this.props;

    return {
      currentPage: page,
      onClick: refreshEmails,
      perPage,
      perPageClick: paginateEmailsPerPage,
      total: Math.ceil(totalCount / perPage),
    };
  };

  _getSelectAllGroups = () => {
    const {
      actionCreators: { emailGroupSearch },
      enabledSingleRowGroups,
      intl: { formatMessage },
    } = this.props;

    const { checked, disable } = enabledSingleRowGroups;

    return (
      <div className="select-all-switch-container">
        <Switch
          checked={checked}
          className="select-all-switch"
          disabled={disable}
          label={formatMessage({ id: 'common.showAllGroups' })}
          name="select_all_columns"
          onChange={() => {
            emailGroupSearch({ checked: !checked, disable: false });
          }}
          rightLabel
        />
        <HoverTooltip
          place={HoverTooltipPlace.bottom}
          textId="common.showAllGroupsHint"
          tooltipId="read-only-address-tooltip"
        >
          <Icon classes="tout-icon-warning" />
        </HoverTooltip>
      </div>
    );
  };

  _getDropdownActions = () => {
    const {
      actionCreators,
      intl: { formatMessage },
    } = this.props;
    return [
      ...this._getArchivedTabs(),
      ...this._getDeliveredTabs(),
      ...this._getSentTabs(),
      ...this._getFailedTabs(),
      {
        getCount: ({ selectedItems }) =>
          compose(
            size,
            uniq,
            map(identity)
          )(selectedItems),
        label: formatMessage({ id: 'web.people.addPeopleToGroup' }),
        onClick: (ids, callback, people) =>
          actionCreators.openAddPeopleToGroup(
            compose(
              uniq,
              map('person_id')
            )(people)
          ),
      },
      {
        getCount: ({ selectedItems }) =>
          compose(
            size,
            uniq,
            map(identity)
          )(selectedItems),
        label: formatMessage({ id: 'common.unsubscribe' }),
        onClick: (ids, callback, people) =>
          actionCreators.openUnsubscribePeoplePopup(
            compose(
              uniq,
              map('person_id')
            )(people),
            callback
          ),
      },
    ];
  };

  _getSentTabs = () => {
    const {
      actionCreators,
      commandCenterEmailsTab,
      intl: { formatMessage },
    } = this.props;
    if (commandCenterEmailsTab === CommandCenterEmailsTabsEnum.sent.value) {
      return [
        {
          label: formatMessage({
            id: 'web.commandCenter.bulkActions.markSuccess.title',
          }),
          onClick: actionCreators.bulkMarkSuccess,
        },
        {
          label: formatMessage({ id: 'conversationCampaign.remove' }),
          onClick: actionCreators.removeSelectedFromCampaign,
        },
      ];
    }
    return [];
  };

  _getDeliveredTabs = () => {
    const {
      actionCreators,
      commandCenterEmailsSubTab,
      intl: { formatMessage },
    } = this.props;
    if (
      commandCenterEmailsSubTab ===
      CommandCenterEmailsTabsEnum.sent.subTabs.delivered.value
    ) {
      return [
        {
          label: formatMessage({
            id: 'common.archive',
          }),
          onClick: (ids, clearSelectedIds) =>
            actionCreators.bulkArchive(ids, true, clearSelectedIds),
        },
      ];
    }
    return [];
  };

  _getArchivedTabs = () => {
    const {
      actionCreators,
      commandCenterEmailsSubTab,
      intl: { formatMessage },
    } = this.props;
    if (
      commandCenterEmailsSubTab ===
      CommandCenterEmailsTabsEnum.sent.subTabs.archived.value
    ) {
      return [
        {
          label: formatMessage({
            id: 'common.unarchive',
          }),
          onClick: (ids, clearSelectedIds) =>
            actionCreators.bulkArchive(ids, false, clearSelectedIds),
        },
      ];
    }
    return [];
  };

  _getFailedTabs = () => {
    const {
      actionCreators,
      intl: { formatMessage },
      commandCenterEmailsSubTab,
    } = this.props;
    if (
      commandCenterEmailsSubTab ===
        CommandCenterEmailsTabsEnum.undelivered.subTabs.failed.value ||
      commandCenterEmailsSubTab ===
        CommandCenterEmailsTabsEnum.undelivered.subTabs.bounced.value
    ) {
      return [
        {
          autoClear: true,
          label: formatMessage({
            id: 'web.emails.resendFailedDeleveries',
          }),
          onClick: actionCreators.bulkRetrySendEmail,
        },
      ];
    }
    return [];
  };

  getSearch = () => {
    const { emailSearchString } = this.props;
    return {
      value: emailSearchString,
      ...this.search,
    };
  };
}

CommandCenterEmailsTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  commandCenterEmailsSubTab: PropTypes.string.isRequired,
  commandCenterEmailsTab: PropTypes.string.isRequired,
  emailSearchString: PropTypes.string.isRequired,
  emails: PropTypes.array.isRequired,
  enabledSingleRowGroups: PropTypes.objectOf(PropTypes.bool).isRequired,
  intl: intlShape.isRequired,
  isScheduleStatus: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  tableId: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
};

CommandCenterEmailsTable.defaultProps = {
  columnSettings: [],
};

export default injectIntl(CommandCenterEmailsTable);
