import ActionTypes from 'table/modules/actionTypes';

export function searchValue(state = '', { type = '', value = '' }) {
  switch (type) {
    case ActionTypes.searchSetValue:
      return value;
    case ActionTypes.searchSetPrefix:
      return value || state;
    default:
      return state;
  }
}

export function searchSelectedSearcherIndex(
  state = null,
  { type = '', searchIndex = null }
) {
  switch (type) {
    case ActionTypes.searchSetPrefix:
      return searchIndex;
    case ActionTypes.searchClearPrefix:
      return null;
    default:
      return state;
  }
}

export function searchPrefix(state = '', { type = '', prefix = '' }) {
  switch (type) {
    case ActionTypes.searchSetPrefix:
      return prefix;
    case ActionTypes.searchClearPrefix:
      return '';
    default:
      return state;
  }
}
