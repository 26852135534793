/**
 *
 * PersonCell
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Regex } from 'web/libs/constants';
import { navigateToEmailGroupSearch } from 'web/commandCenter/modules/emailGridAdvancedSearch/helpers/advancedSearchHelpers';
import { setSelectedEmailURL } from 'web/services/routerService';
import './personCell.scss';

class PersonCell extends PureComponent {
  render() {
    const { className, id, subTitle, title, emailId } = this.props;
    return (
      <div
        className={classNames(className, 'cell-person')}
        data-index={id}
        onClick={(event) =>
          this._openPersonDetails(event, { emailId, subTitle })
        }
      >
        <div className="cell-person-container">
          <h5 className="cell-person-title text-overflow" title={title}>
            {title}
          </h5>
          {subTitle && (
            <div
              className="cell-person-subTitle text-overflow"
              title={subTitle}
            >
              {subTitle}
            </div>
          )}
        </div>
      </div>
    );
  }

  _openPersonDetails = (e, emailData) => {
    e.stopPropagation();
    e.preventDefault();

    const { emailId, subTitle } = emailData;
    const { openPersonDetails } = this.props;

    // non-email cell (task or campaign person)
    if (!emailId) {
      openPersonDetails(
        parseInt(e.currentTarget.dataset.index || e.target.dataset.index, 10)
      );
      return;
    }
    // email cell
    const isNonGroupPitch = subTitle.match(Regex.email);
    if (isNonGroupPitch) {
      openPersonDetails(
        parseInt(e.currentTarget.dataset.index || e.target.dataset.index, 10)
      );
      setSelectedEmailURL(emailId);
    } else {
      navigateToEmailGroupSearch(emailId);
    }
  };
}

PersonCell.propTypes = {
  className: PropTypes.string,
  emailId: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  openPersonDetails: PropTypes.func.isRequired,
  parentPitchId: PropTypes.number,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

PersonCell.defaultProps = {
  className: '',
  emailId: null,
  parentPitchId: null,
  subTitle: '',
  title: '',
};

export default PersonCell;
