import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Card from 'components/card';
import { getImageUrl } from 'web/libs/constants';
import StepsContainer from '../stepsContainer/stepsContainer';
import ProgressBarContainer from 'web/onboarding/components/progressBar/progressBar';
import OnboardingPopupContainer from 'web/onboarding/containers/onboardingPopupContainer/onboardingPopupContainer';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import onboardingPopupIds from 'web/onboarding/lib/onboardingPopupIds';
import { isAvailable } from 'web/onboarding/helpers/onboardingHelpers';
import { getUser, isNewUser } from 'web/user/selectors/userSelectors';
import { stepsUrls } from 'web/onboarding/lib/stepsRouting';
import OnboardingWelcome from 'web/onboarding/containers/onboardingWelcome/onboardingWelcome';
import OnboardingFinish from 'web/onboarding/components/onboardingFinish/onboardingFinish';
import { initializeHealthStatuses } from 'web/settings/myProfile/components/yourIntegrations/actionCreators/yourIntegrationsActionCreators';
import { updateOnboardingSettings } from 'web/user/actionCreators/userActionCreators';
import {
  setCompletedStep,
  setSkippedStep,
  finishOnboardingAction,
} from 'web/onboarding/actionCreators/onboardingActionCreators';
import { stepsNames } from 'web/onboarding/lib/stepsRouting';
import LoadingSpinner from 'components/loadingSpinner';
import { localStorageChecker } from 'web/settings/emailConnection/helpers/emailConnectionHelpers';
import { FormattedMessage } from 'react-intl';
import { navigateToUrl } from 'web/services/routerService';
import onboardingPage from 'components/hocs/onboardingPage';
import './onboardingContainer.scss';

const OnboardingContainer = ({
  location,
  openPopup,
  isAvailable,
  initializeHealthStatuses,
  finishOnboardingAction,
  setCompletedStep,
  setSkippedStep,
  updateOnboardingSettings,
}) => {
  useEffect(() => {
    initializeHealthStatuses().then((res) => {
      if (res.is_salesforce_integration_setup) {
        setCompletedStep(1);
      } else {
        setSkippedStep(1);
      }
      if (
        res.is_gmail_integration_setup ||
        res.is_outlook_integration_setup ||
        res.is_exchange_onprem_integration_setup
      ) {
        setCompletedStep(2);
      } else {
        setSkippedStep(2);
      }
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const [connectionSuccess, setConnectionSuccess] = useState(false);
  const [isShowProgress, setShowProgress] = useState(false);

  const showWelcomePage = location.hash === stepsUrls.welcome;
  const isFinishPage = location.hash === stepsUrls.finish;
  const isOnboardingPage = location.hash.indexOf('onboarding') !== -1;

  const navbarElem = document.getElementById('navbar');
  const bannerElem = document.getElementById('nux');

  if (isOnboardingPage) {
    navbarElem.style.display = 'none';
    bannerElem.style.display = 'none';
  } else {
    navbarElem.style.display = 'block';
    bannerElem.style.display = 'block';
  }

  const connectOnboarding = () => {
    setLoading(true);

    localStorageChecker('onboardingCrmAuthSuccess', () => {
      setLoading(false);
      updateOnboardingSettings(stepsNames.connectCRM);
      setConnectionSuccess(true);
      setCompletedStep(1);

      setTimeout(() => {
        navigateToUrl('#onboarding/email_connection');
      }, 2000);
    });
  };

  return isAvailable ? (
    <React.Fragment>
      {isShowProgress || isFinishPage ? <ProgressBarContainer /> : null}
      <Card className="onboarding-container-card">
        {!isFinishPage ? (
          <div
            className="tout-icon-close"
            onClick={() => {
              openPopup(onboardingPopupIds.skipSetup);
            }}
          />
        ) : (
          <OnboardingFinish finishAction={finishOnboardingAction} />
        )}
        {showWelcomePage ? <OnboardingWelcome /> : null}
        {!loading && !connectionSuccess ? (
          <StepsContainer
            setShowProgress={setShowProgress}
            url={location.hash}
          />
        ) : null}
        {loading && !connectionSuccess ? (
          <div className="onboarding-spinner-wrapper">
            <LoadingSpinner imageUrl={getImageUrl} size={130} />
          </div>
        ) : null}
        {!loading && connectionSuccess ? (
          <div className="onboarding-success">
            <h1 className="onboarding-success-title">
              <FormattedMessage id={'web.onboarding.connect.crm.title'} />
            </h1>
            <span className="onboarding-success-icon" />
            <FormattedMessage id={'web.onboarding.title.success'} />
          </div>
        ) : null}
      </Card>
      <OnboardingPopupContainer connectHandler={connectOnboarding} />
    </React.Fragment>
  ) : null;
};

OnboardingContainer.propTypes = {
  finishOnboardingAction: PropTypes.func.isRequired,
  initializeHealthStatuses: PropTypes.func.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  openPopup: PropTypes.func.isRequired,
  setCompletedStep: PropTypes.func.isRequired,
  setSkippedStep: PropTypes.func.isRequired,
  updateOnboardingSettings: PropTypes.func.isRequired,
  userSettings: PropTypes.object,
};

OnboardingContainer.defaultProps = {
  userSettings: {},
};

const mapDispatchToProps = {
  finishOnboardingAction,
  initializeHealthStatuses,
  openPopup,
  setCompletedStep,
  setSkippedStep,
  updateOnboardingSettings,
};

const mapStateToProps = (state) => ({
  isNewUser: isNewUser(state),
  user: getUser(state),
  userSettings: state.userSettings,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
  isAvailable: isAvailable({
    ...stateProps,
    route: ownProps.location.hash,
  }),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  (component) => onboardingPage(component)
)(OnboardingContainer);
