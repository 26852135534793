import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getTemplateEmails(id) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.templateEmails.replace(':#id', id),
      {
        limit: 50,
      },
      resolve,
      reject
    );
  });
}
