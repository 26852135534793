import React from 'react';
import PropTypes from 'prop-types';
import BaseText from 'components/inputs/text';

const Text = ({ input, ...other }) => <BaseText {...input} {...other} />;

Text.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default Text;
