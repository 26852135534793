import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import I18N from 'languages';
import Action from 'components/buttons/action';
import {
  numbers as numberValidatorFactory,
  minValue as minValueValidatorFactory,
  maxValue as maxValueValidatorFactory,
} from 'web/form/helpers/validators';
import Text from 'web/form/components/fields/text';
import { MIN_TEAM_EMAIL_LIMIT } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import CardHighlight from 'components/cardHighlight/cardHighlight';
import './emailLimitsForm.scss';

class EmailLimitsForm extends Component {
  constructor(props) {
    super(props);
    const { subscriptionEmailLimit } = props;
    this.numberValidator = numberValidatorFactory();
    this.minValueValidatorForTeamEmailLimit = minValueValidatorFactory(
      MIN_TEAM_EMAIL_LIMIT
    );
    this.maxValueValidatorForTeamEmailLimit = maxValueValidatorFactory(
      subscriptionEmailLimit
    );
  }

  onFormSubmitError = (e) => {
    const { onError } = this.props;
    onError();
    e.preventDefault();
  };

  onFormSubmit = () => {
    const { handleSubmit, valid } = this.props;
    if (valid) {
      return handleSubmit;
    }
    return this.onFormSubmitError;
  };

  render() {
    const { pristine, defaultDeliveryChannel } = this.props;
    const defaultProvider = 'SMTP';

    return (
      <React.Fragment>
        <h3>
          <FormattedMessage id="web.settings.adminSettings.general.communicationLimitsCard.dailyEmailLimit.title" />
        </h3>
        <CardHighlight
          className="throttling-tip"
          iconType={'lightbulb'}
          textId="web.settings.adminSettings.general.forms.tip.DailyEmailLimit"
          values={{
            provider: defaultDeliveryChannel
              ? I18N.getStr(
                  'web.settings.adminSettings.general.deliveryChannelLabelWithoutUsername',
                  defaultDeliveryChannel
                )
              : defaultProvider,
          }}
        />
        <div className="margin-top-20">
          <FormattedMessage id="web.settings.adminSettings.general.communicationLimitsCard.dailyEmailLimit.description" />
        </div>
        <Form
          className="team-email-limit-form margin-0"
          onSubmit={this.onFormSubmit()}
          noValidate
        >
          <div className="margin-top-20 emailLimitsData">
            <Field
              autoFocus={false}
              className="team-email-limit-form-field"
              component={Text}
              name="teamEmailLimit"
              type="text"
              validate={[
                this.numberValidator,
                this.minValueValidatorForTeamEmailLimit,
                this.maxValueValidatorForTeamEmailLimit,
              ]}
            />
            <span className="emailLimitDataTxt">
              <FormattedMessage id="web.settings.adminSettings.general.communicationLimitsCard.dailyEmailLimit.label" />
            </span>
          </div>
          <div className="margin-top-20 right">
            <Action disabled={pristine} type="submit">
              <FormattedMessage id="common.save" />
            </Action>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

EmailLimitsForm.propTypes = {
  defaultDeliveryChannel: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  subscriptionEmailLimit: PropTypes.number,
  teamEmailLimitSource: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
};

EmailLimitsForm.defaultProps = {
  subscriptionEmailLimit: null,
};

export default reduxForm({
  enableReinitialize: true,
})(EmailLimitsForm);
