/* global Office */
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Action from 'components/buttons/action';
import { Urls } from 'web/libs/routes';
import './outlookDescription.scss';
import { LOGIN_OWA_POPUP_SUCCESS_MESSAGE } from 'web/login/libs/loginConstants';

const OutlookDescription = ({ intl: { formatMessage } }) => (
  <div className="outlook-description">
    <p className="outlook-description-header">
      <FormattedMessage id="outlook.description.header" />
    </p>
    <div className="outlook-description-items ">
      <div className="outlook-description-icon icon-doc" />
      <p>
        <FormattedMessage id="outlook.description.templates" />
      </p>
    </div>
    <div className="outlook-description-items ">
      <div className="outlook-description-icon icon-sent" />
      <p>
        <FormattedMessage id="outlook.description.emails" />
      </p>
    </div>
    <div className="outlook-description-items ">
      <div className="outlook-description-icon icon-viewrate" />
      <p>
        <FormattedMessage id="outlook.description.livefeed" />
      </p>
    </div>

    <div className="outlook-description-buttons">
      <div>
        <Action
          className="medium outlook-description-login-button"
          onClick={() => {
            const options = {
              height: 500 / (window.screen.height / 100),
              promptBeforeOpen: false,
              width: 850 / (window.screen.width / 100),
            };

            Office.context.ui.displayDialogAsync(
              Urls.loginOWADirect,
              options,
              (asyncResult) => {
                const dialog = asyncResult.value;
                dialog.addEventHandler(
                  Office.EventType.DialogMessageReceived,
                  (arg) => {
                    if (arg.message === LOGIN_OWA_POPUP_SUCCESS_MESSAGE) {
                      dialog.close();
                      window.location.href = window.originalUrl;
                    }
                  }
                );
              }
            );
          }}
          type="button"
        >
          <FormattedMessage id="banner.welcomeContent.login" />
        </Action>
      </div>
      <a
        className="outlook-description-new-account"
        href={Urls.dontHaveAccount}
        rel="noopener noreferrer"
        target="_blank"
        title={formatMessage({ id: 'outlook.description.createAccount' })}
      >
        <FormattedMessage id="outlook.description.createAccount" />
      </a>
    </div>
  </div>
);

OutlookDescription.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(OutlookDescription);
