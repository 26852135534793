import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup, { PopupButtonColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class WithdrawInvitesConfirmationPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      withdrawInvitesLoading,
      defaultAlert,
      popupData: { ids },
    } = this.props;

    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.onWithdrawInvites,
          onSecondary: closePopup,
          primaryTextId: 'common.withdraw',
          secondaryTextId: 'common.cancel',
          primaryColor: PopupButtonColors.red,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.userManagement.invitees.withdraw.popupHeader',
          textValues: { count: ids.length },
        }}
        getImageUrl={getImageUrl}
        loading={withdrawInvitesLoading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedHTMLMessage
          id="web.settings.adminSettings.userManagement.invitees.withdraw.areYouSure"
          values={{ count: ids.length }}
        />
        <div className="user-management-popup-warning">
          <div className="tout-icon-warning" />
          <FormattedMessage id="web.settings.adminSettings.userManagement.invitees.withdraw.ifYouDelete" />
        </div>
      </Popup>
    );
  }

  onWithdrawInvites = () => {
    const {
      actionCreators: { withdrawInvites },
      popupData: { ids },
    } = this.props;

    withdrawInvites(ids);
  };
}

WithdrawInvitesConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popupData: PropTypes.object,
  withdrawInvitesLoading: PropTypes.bool.isRequired,
  defaultAlert: PropTypes.object,
};

WithdrawInvitesConfirmationPopup.defaultProps = {
  popupData: {},
  defaultAlert: null,
};

export default WithdrawInvitesConfirmationPopup;
