import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { defaultCatchAlert } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabPopupActionCreators';
import { getEmailLimits } from 'web/campaigns/campaignsTab/services/campaignService';
import { getActivePeopleByIds } from 'web/modals/addToCampaign/services/peopleService';
import {
  openPreviewCampaignsWithPId,
  openPreviewMarketoCampaignWithPId,
} from 'web/previewEmails/actionCreators/previewEmailsExternalActionCreators';
import {
  parseCheckPeople,
  parseMarketoPeople,
  parsePeopleDataMaps,
} from 'web/modals/addToCampaign/helpers/peopleParsers';
import { GlobalAlertIds } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import {
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  resizeToPreviews,
  resizeToPreviewTasks,
} from 'web/services/windowService';
import {
  AlertIds,
  ModalComponentIds,
} from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import {
  initOpenToutCampaignChooser,
  openCampaignTypeChooser,
} from 'web/modals/addToCampaign/actionCreators/popupActionCreators';
import {
  hasNoSteps,
  startsWithEmail,
} from 'web/campaigns/campaignsTab/helpers/campaignsHelpers';
import { isMarketoLead } from 'web/people/peopleSearchAdd/helpers/peopleHelpers';

export function setModalComponent(component, data) {
  return {
    type: ActionTypes.modal.state.setComponent,
    component,
    data,
  };
}

export const openPreviewsMarketo = () => (dispatch, getState) => {
  const {
    previewEmailsPreviews: { recipients },
    addToCampaignModalWindowMode: windowMode,
  } = getState();

  if (windowMode) {
    resizeToPreviewTasks(recipients.ids.length);
  }

  dispatch(setPopupLoading(false));
  dispatch(setModalComponent(ModalComponentIds.previewMarketo));
};

export function openPreviews() {
  return (dispatch, getState) => {
    const {
      previewEmailsPreviews: { attachmentsCount, isEmail, recipients },
      addToCampaignModalWindowMode: windowMode,
      addToCampaignSalesforceImportFailures: failedImports,
    } = getState();

    if (isEmail && windowMode) {
      resizeToPreviews(attachmentsCount > 0);
    } else if (windowMode) {
      let failedCount = 0;
      if (failedImports) {
        failedCount = Object.keys(failedImports).length;
      }
      resizeToPreviewTasks(recipients.ids.length + failedCount);
    }

    dispatch(setPopupLoading(false));
    dispatch(
      setModalComponent(
        isEmail ? ModalComponentIds.previewEmail : ModalComponentIds.previewTask
      )
    );
  };
}

export function checkPeopleToAdd(ids = []) {
  return (dispatch, getState) => {
    const {
      user,
      campaigns,
      peopleSearchAddState: { people, identity, selectedIndex },
      addToCampaignModalSelectedCampaign: selectedCampaign,
    } = getState();
    getActivePeopleByIds(ids, selectedCampaign.id)
      .then((data) => {
        const peopleParsed = parseCheckPeople(
          people,
          selectedIndex,
          parsePeopleDataMaps(data, campaigns, user)
        );

        dispatch(
          openPreviewCampaignsWithPId(
            selectedCampaign,
            peopleParsed,
            identity,
            openPreviews
          )
        );
      })
      .catch((err) => {
        dispatch(setPopupLoading(false));
        dispatch(defaultCatchAlert(err, GlobalAlertIds.genericApiIssue));
      });
  };
}

// ATC-transition: Transitions from Marketo Campaign Type Chooser to Marketo Campaign Preview
export const checkMarketoPeopleToAdd = () => (dispatch, getState) => {
  const {
    user,
    marketoCampaigns,
    peopleSearchAddState: { people, identity, selectedIndex },
    addToCampaignModalSelectedCampaign: selectedCampaign,
  } = getState();

  const peopleIds = people.map((person) => person.id);

  getActivePeopleByIds(peopleIds, selectedCampaign.id)
    .then((data) => {
      const parsedMarketoPeople = parseMarketoPeople(people, selectedIndex);
      const allPeople = { ...data, ...parsedMarketoPeople };
      delete allPeople.active_people;

      const peopleParsed = parseCheckPeople(
        people,
        selectedIndex,
        parsePeopleDataMaps(allPeople, marketoCampaigns, user)
      );

      dispatch(
        openPreviewMarketoCampaignWithPId(
          selectedCampaign,
          peopleParsed,
          identity,
          openPreviewsMarketo
        )
      );
    })
    .catch((err) => {
      dispatch(setPopupLoading(false));
      dispatch(defaultCatchAlert(err, GlobalAlertIds.genericApiIssue));
    });
};

function handleEmailLimitsAddPeople(ids, { current, remaining, total }) {
  return (dispatch) => {
    if (remaining >= 0) {
      dispatch(checkPeopleToAdd(ids));
    } else {
      dispatch(setPopupLoading(false));
      const currentOver = current < 0;
      dispatch(
        setPopupAlert(AlertIds.emailLimitsReachedAddPeople, {
          current: Math.abs(current),
          currentOver,
          remaining: Math.abs(remaining),
          total,
        })
      );
    }
  };
}

// ATC-transition: Transitions after 1. Tout Campaign chooser or 2. Add People when Tout Campaign is preselected
// Opens first step preview for email or task
export const validateBeforePreview = () => (dispatch, getState) => {
  const {
    peopleSearchAddState: { selectedIndex },
    addToCampaignModalSelectedCampaign: selectedCampaign,
  } = getState();
  const ids = Object.keys(selectedIndex);

  if (hasNoSteps(selectedCampaign)) {
    dispatch(setPopupAlert(AlertIds.noSteps));
    return;
  }

  dispatch(setPopupLoading(true));

  if (startsWithEmail(selectedCampaign)) {
    getEmailLimits(ids)
      .then(
        ({
          total_limit: total,
          remaining_limit: remaining,
          current_limit: current,
        }) => {
          dispatch(
            handleEmailLimitsAddPeople(ids, { total, remaining, current })
          );
        }
      )
      .catch((e) => {
        dispatch(setPopupLoading(false));
        dispatch(setPopupAlert(AlertIds.addPeopleCallFail));
      });
  } else {
    dispatch(checkPeopleToAdd(ids));
  }
};

const isAddingMarketoLeads = (people = []) => {
  let groupMembers;
  return people.some((person) => {
    groupMembers = person.members;

    if (groupMembers) {
      // is a group
      return groupMembers.some(isMarketoLead);
    }

    return isMarketoLead(person); // is individual person
  });
};

// ATC-transition: Determines transition after Add People
export const addPeopleToCampaign = () => (dispatch, getState) => {
  const {
    addToCampaignModalSelectedCampaign: { id: campaignId },
    peopleSearchAddState: { people },
  } = getState();

  if (campaignId) {
    // Skip campaign chooser if preselected campaign (Campaigns Tab)
    dispatch(validateBeforePreview());
  } else if (isAddingMarketoLeads(people)) {
    dispatch(openCampaignTypeChooser());
  } else {
    dispatch(initOpenToutCampaignChooser());
  }
};
