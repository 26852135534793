import queue from 'async/queue';
import {
  LiveFeedPusherChannel,
  PusherEnvironment,
  PusherEvents,
} from 'web/libs/constants';
import {
  getTeamChannel,
  getUserChannel,
  setCustomChannel,
} from 'web/services/pusherService';
import { AlertTypes } from 'web/liveFeed/libs/liveFeedConstants';
import { initBestBetsPusher } from 'web/liveFeed/pipeline/actionCreators/pipelinePusherActionCreators';
import {
  initToutEventsPusher,
  initInterestingMomentsPusher,
  initDynamicChatActivitiesPusher,
  initNotificationsPusher,
} from 'web/liveFeed/engage/actionCreators/engagePusherActionCreators';
import {
  updateDealAlert,
  updatePitchAnalytics,
} from './liveFeedHeaderActionCreators';
import { heartBeatsService } from 'web/liveFeed/services/liveFeedService';

export function initLiveFeedMarketoPusherService(userId) {
  return (dispatch) => {
    setCustomChannel(
      LiveFeedPusherChannel,
      `live-feed-${PusherEnvironment}-user-${userId}`
    );

    dispatch(initInterestingMomentsPusher());
    dispatch(initBestBetsPusher());
    dispatch(initDynamicChatActivitiesPusher());
    dispatch(initNotificationsPusher());
    heartBeatsService(userId);
  };
}

const queuedToutPitchesLiveFeedCall = queue(({ data = {}, dispatch }, cb) => {
  dispatch(updatePitchAnalytics(data));
  cb();
}, 100);

const queuedAlertsLiveFeedCall = queue(({ data = {}, dispatch }, cb) => {
  dispatch(updateDealAlert(data));
  cb();
}, 100);

const UserSessionsLiveFeedCall = () => {
  setTimeout(() => {
    window.close();
  }, 2000);
};

export function initToutPitchesPusher() {
  return (dispatch) => {
    getUserChannel().bind(PusherEvents.pitch, (data) => {
      queuedToutPitchesLiveFeedCall.push({ data, dispatch });
    });
  };
}

export function initDealAlertPusher() {
  return (dispatch) => {
    getTeamChannel().bind(PusherEvents.alerts, (data) => {
      if (data.alert_type === AlertTypes.dealWon) {
        queuedAlertsLiveFeedCall.push({ data, dispatch });
      }
    });
  };
}

export function initUserSessionPusher() {
  return () => {
    getUserChannel().bind(PusherEvents.userSession, () => {
      UserSessionsLiveFeedCall();
    });
  };
}

export function initLiveFeedToutPusherService() {
  return (dispatch) => {
    dispatch(initToutEventsPusher());
    dispatch(initToutPitchesPusher());
    dispatch(initDealAlertPusher());
    dispatch(initUserSessionPusher());
  };
}
