import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CampaignsUsingTemplateTable from 'web/campaignsUsingTemplate/components/campaignsUsingTemplateTable'; //todo will be moved to ui-components
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import './campaignsUsingTemplate.scss';

// eslint-disable-next-line react/prefer-stateless-function
class CampaignsUsingTemplate extends Component {
  render() {
    const { activeCampaignTemplates, loading } = this.props; //todo add error state?

    return (
      <div className="campaigns-using-template">
        {loading ? <LoadingSpinner imageUrl={getImageUrl} /> : null}
        <h3 className="tout-icon-rocketship margin-bottom-half">
          <FormattedMessage id="web.campaignsUsingTemplate.title" />
        </h3>
        <div className="margin-bottom-full">
          <FormattedMessage id="web.campaignsUsingTemplate.instructions" />
        </div>
        <CampaignsUsingTemplateTable
          actionCreators={{}}
          activeCampaignTemplates={activeCampaignTemplates}
        />
      </div>
    );
  }
}

CampaignsUsingTemplate.propTypes = {
  activeCampaignTemplates: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CampaignsUsingTemplate;
