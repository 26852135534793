import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, node, number, object, string } from 'prop-types';
import Popup, { PopupBackgroundColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import BulkUpdateProgress from 'web/bulkUpdate/components/bulkUpdateProgress/bulkUpdateProgress';
import BulkPopupIds from 'web/bulkUpdate/libs/popupIds';
import BulkUpdateOverlayIds from 'web/bulkUpdate/libs/popupOverlayIds';
import {
  hidePopupOverlay,
  openPopup,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { closeOrShowOverlay } from 'web/bulkUpdate/actionCreators/bulkUpdatePopupActionCreators';
import { undoBulkMoveTemplate } from 'web/templates/actionCreators/templatesBulkActionCreators';

const EMPTY_POPUP = {};

class BulkUpdateContainer extends Component {
  render() {
    const { children, popup } = this.props;
    const popupObject = (popup && this.getPopupObject()) || EMPTY_POPUP;

    return (
      <div>
        {popupObject.children && <Popup {...popupObject} />}
        {children}
      </div>
    );
  }

  getPopupObject = () => ({
    ...this.getPopupComponent(),
    getImageUrl,
    loading: this.props.popupLoading,
    overlay: this.getPopupOverlay(),
  });

  getPopupComponent = () => {
    const { popup, actionCreators } = this.props;

    switch (popup) {
      case BulkPopupIds.progress: {
        const {
          isComplete,
          progress,
          results,
          popupData: { modalTitle },
        } = this.props;

        return {
          children: (
            <BulkUpdateProgress
              isComplete={isComplete}
              progress={progress}
              results={results}
            />
          ),
          footer: {
            onPrimary: actionCreators.closeOrShowOverlay,
            onSecondary: actionCreators.undoBulkMoveTemplate,
            primaryDisabled: !isComplete,
            primaryTextId: 'common.ok',
            secondaryDisabled: !isComplete,
            secondaryTextId: 'common.undo',
          },
          header: {
            onClose: actionCreators.closeOrShowOverlay,
            textId: modalTitle,
          },
          onOutsideClick: actionCreators.closeOrShowOverlay,
          size: PopupSize.small,
        };
      }
      default:
        return EMPTY_POPUP;
    }
  };

  getPopupOverlay = () => {
    const { popupOverlay, actionCreators } = this.props;

    switch (popupOverlay) {
      case BulkUpdateOverlayIds.exitWarning:
        return {
          color: PopupBackgroundColors.white,
          footer: {
            onPrimary: actionCreators.closePopup,
            onSecondary: actionCreators.hidePopupOverlay,
            primaryTextId: 'common.close',
            secondaryTextId: 'common.back',
          },
          header: {
            onClose: actionCreators.closePopup,
          },
          onOutsideClick: actionCreators.closePopup,
          textId: 'web.bulkUpdate.warningMessage',
        };
      default:
        return undefined;
    }
  };
}

BulkUpdateContainer.propTypes = {
  actionCreators: object.isRequired,
  children: node,
  isComplete: bool.isRequired,
  popup: string.isRequired,
  popupData: object.isRequired,
  popupLoading: bool,
  popupOverlay: string,
  progress: number.isRequired,
  results: object.isRequired,
};

BulkUpdateContainer.defaultProps = {
  children: null,
  popupLoading: false,
  popupOverlay: '',
};

const mapStateToProps = (state) => ({
  isComplete: state.bulkUpdateIsComplete,
  popup: state.popup,
  popupData: state.popupData,
  popupLoading: state.popupLoading,
  popupOverlay: state.popupOverlay,
  progress: state.bulkUpdateProgress,
  results: state.bulkUpdateResults,
});

const mapDispatchToProps = {
  closeOrShowOverlay,
  closePopup,
  hidePopupOverlay,
  openPopup,
  undoBulkMoveTemplate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BulkUpdateContainer);
