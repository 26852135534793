/*eslint complexity:0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { PopupSize } from 'libs/constantsShared';
import Popup, { PopupButtonColors } from 'components/popups';
import { EditTemplatePopupIds } from '../libs/editTemplateSlideOutConstants';
import { getTemplate } from '../selectors/editTemplateSelectors';
import { getUpdatableCategoriesForUser } from 'web/templates/selectors/templatesSelectors';
import ShareTemplate from 'web/templates/components/shareTemplate';
import UpdateCategory from 'web/categories/components/updateCategory';
import CreateTemplate from 'web/templates/components/createTemplate';
import EmailEvents from 'web/emails/components/emailEvents';
import {
  addAsTemplate,
  archiveTemplate,
  cloneTemplate,
  deleteNote,
  deleteTemplate,
  hasChangesCallback,
  moveTemplate,
  shareTemplate,
  updateMoveCategory,
  updateSharedCategory,
  updateSharedTeamIds,
} from '../actionCreators/editTemplatePopupActionCreators';
import { updateCreateTemplateState } from 'web/templates/actionCreators/templatesCreateActionCreators';
import {
  clearPopupAlert,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { getAlert } from './editTemplateSlideOutPopupContainerHelper';

const EMPTY_POPUP = {};

class EditTemplateSlideOutPopupContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      addAsTemplate: () => dispatch(addAsTemplate()),
      archive: () => dispatch(archiveTemplate()),
      clearAlert: () => dispatch(clearPopupAlert()),
      cloneTemplate: () => dispatch(cloneTemplate()),
      closePopup: () => dispatch(closePopup()),
      deleteNote: (id) => dispatch(deleteNote(id)),
      deleteTemplate: () => dispatch(deleteTemplate()),
      hasChangesCallback: () => dispatch(hasChangesCallback()),
      leaveEditing: () => dispatch(),
      move: () => dispatch(moveTemplate()),
      share: () => dispatch(shareTemplate()),
      updateCreateTemplateState: (update) =>
        dispatch(updateCreateTemplateState(update)),
      updateMoveCategory: (category) => dispatch(updateMoveCategory(category)),
      updateSharedCategory: (category) =>
        dispatch(updateSharedCategory(category)),
      updateSharedTeamIds: (ids) => dispatch(updateSharedTeamIds(ids)),
    };
  }

  render() {
    const { children, popup } = this.props;
    let object = EMPTY_POPUP;
    if (popup) {
      object = this._getGlobalPopupObject();
    }

    /* className used for exceptionSelectors for outside clicks in parent popup */
    return (
      <div className="edit-template-slide-out-popup-container">
        {object.children ? <Popup {...object} /> : null}
        {children}
      </div>
    );
  }

  _getGlobalPopupObject() {
    const { loading } = this.props;
    return {
      loading,
      getImageUrl,
      ...this._getMainComponent(),
      alert: getAlert(this.props, this.actionCreators),
    };
  }

  _getMainComponent() {
    const {
      createTemplateState,
      categories,
      loading,
      popup,
      popupData,
      template,
      updatedCategory,
      updatedShare,
    } = this.props;

    switch (popup) {
      // editLeaving
      case EditTemplatePopupIds.share:
        return {
          children: (
            <ShareTemplate
              {...updatedShare}
              template={template}
              updateSharedCategory={this.actionCreators.updateSharedCategory}
              updateSharedTeamIds={this.actionCreators.updateSharedTeamIds}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.share,
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.share',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.editTemplateSlideOut.popup.share.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case EditTemplatePopupIds.clone:
        return {
          children: (
            <CreateTemplate
              categories={categories}
              createTemplateState={createTemplateState}
              updateState={this.actionCreators.updateCreateTemplateState}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.cloneTemplate,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled:
              createTemplateState.name.length <= 0 ||
              createTemplateState.categoryId <= 0,
            primaryTextId: 'common.clone',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.editTemplateSlideOut.popup.clone.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case EditTemplatePopupIds.move:
        return {
          children: (
            <UpdateCategory
              categories={categories}
              currentCategoryId={template.category_id}
              selectedCategory={updatedCategory}
              updateCategory={this.actionCreators.updateMoveCategory}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.move,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled:
              updatedCategory.value <= 0 ||
              updatedCategory.value === template.category_id,
            primaryTextId: 'common.move',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.editTemplateSlideOut.popup.move.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case EditTemplatePopupIds.archive:
        return {
          children: (
            <FormattedMessage id="web.editTemplateSlideOut.popup.archive.body" />
          ),
          footer: {
            onPrimary: this.actionCreators.archive,
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.archive',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.editTemplateSlideOut.popup.archive.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case EditTemplatePopupIds.delete:
        return {
          children: (
            <FormattedMessage id="web.editTemplateSlideOut.popup.delete.body" />
          ),
          footer: {
            onPrimary: this.actionCreators.deleteTemplate,
            onSecondary: this.actionCreators.closePopup,
            primaryColor: PopupButtonColors.red,
            primaryTextId: 'common.delete',
            secondaryTextId: 'common.cancel',
          },
          header: {
            textId: 'web.editTemplateSlideOut.popup.delete.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case EditTemplatePopupIds.deleteNote:
        return {
          children: <FormattedMessage id="web.notes.deletePopup.body" />,
          footer: {
            onPrimary: this.actionCreators.deleteNote,
            onSecondary: this.actionCreators.closePopup,
            primaryColor: PopupButtonColors.red,
            primaryTextId: 'common.delete',
            secondaryTextId: 'common.cancel',
          },
          header: {
            textId: 'web.notes.deletePopup.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case EditTemplatePopupIds.hasChanges:
        return {
          children: (
            <FormattedMessage id="web.editTemplateSlideOut.popup.hasChanges.body" />
          ),
          footer: {
            onPrimary: this.actionCreators.closePopup,
            onSecondary: this.actionCreators.hasChangesCallback,
            primaryTextId: 'common.back',
            secondaryTextId: 'common.discard',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.editTemplateSlideOut.popup.hasChanges.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      case EditTemplatePopupIds.addAsTemplate: {
        return {
          children: (
            <CreateTemplate
              categories={categories}
              createTemplateState={createTemplateState}
              updateState={this.actionCreators.updateCreateTemplateState}
            />
          ),
          footer: {
            primaryTextId: 'common.add',
            primaryDisabled:
              createTemplateState.name.length <= 0 ||
              createTemplateState.categoryId <= 0,
            onPrimary: this.actionCreators.addAsTemplate,
            secondaryTextId: 'common.cancel',
            onSecondary: this.actionCreators.closePopup,
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'common.addAsTemplate',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case EditTemplatePopupIds.events:
        return {
          children: <EmailEvents events={popupData.events} />,
          footer: {
            onPrimary: this.actionCreators.closePopup,
            primaryTextId: 'common.close',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.emails.popup.events.header',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      default:
        return EMPTY_POPUP;
    }
  }
}

EditTemplateSlideOutPopupContainer.propTypes = {
  createTemplateState: PropTypes.object.isRequired,
  alert: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object.isRequired,
  updatedShare: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  updatedCategory: PropTypes.object.isRequired,
};

EditTemplateSlideOutPopupContainer.defaultProps = {
  children: null,
};

function mapStateToProps(state) {
  return {
    alert: state.popupAlert,
    categories: getUpdatableCategoriesForUser(state),
    createTemplateState: state.templatesCreateTemplateState,
    loading: state.popupLoading,
    popup: state.popup,
    popupData: state.popupData,
    updatedShare: state.editTemplateSlideOutPopupShare,
    updatedCategory: state.editTemplateSlideOutPopupMove,
    template: getTemplate(state),
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(EditTemplateSlideOutPopupContainer);
