import { SubmissionError } from 'redux-form';
import CreateAccountActionTypes from '../libs/createAccountActionTypes';
import { errorMessage } from '../libs/createAccountConstants';
import {
  checkActivationCode as checkActivationCodeCall,
  sendNewAccountInfo,
  sendAccountActivation,
  sendEmailAdminInvitation,
} from '../services/createAccountService';
import { Urls } from 'web/libs/constants';
import { navigateToUrl } from 'web/services/routerService';
import { track } from 'web/services/mixpanelService';
import {
  SignUpEvents,
  SignUpProperties,
  SignUpPropertyTypes,
} from 'web/libs/mixpanelEvents';
import { defaultErrorMessage } from 'web/shared/helpers/parser';
import { Urls as routesUrls } from 'web/libs/routes';
import { openOAuthPopupWindow } from 'web/services/routerService';
import {
  setUser,
  setAdminEmailInvitation,
} from 'web/user/actionCreators/userActionCreators';

export const setLoading = (loading = true) => ({
  loading,
  type: CreateAccountActionTypes.setLoading,
});

export const createEmailAdminInvitation = (token) => (dispatch) => {
  dispatch(setLoading());

  return sendEmailAdminInvitation(token)
    .then((res) => {
      const { admin_email } = res;
      dispatch(setAdminEmailInvitation(admin_email));
      dispatch(setLoading(false));
      return admin_email;
    })
    .catch((err) => {
      dispatch(setLoading(false));
      let errorMessages = defaultErrorMessage;
      if (err.status === 422) {
        try {
          errorMessages = JSON.parse(err.response.text);
        } catch (error) {
          errorMessages = defaultErrorMessage;
        }
      }
      throw new SubmissionError(errorMessages);
    });
};

export const createAccountRequest = (formValues, invitationCode) => (
  dispatch
) => {
  dispatch(setLoading());
  track(SignUpEvents.clickButton, {
    [SignUpProperties.activityType]: SignUpPropertyTypes.signUp,
  });

  return sendNewAccountInfo(formValues, invitationCode)
    .then((user) => {
      dispatch(setUser(user));
      return user;
    })
    .catch((err) => {
      dispatch(setLoading(false));
      let errorMessages = defaultErrorMessage;

      if (err.status === 422) {
        try {
          const { errors } = JSON.parse(err.response.text);
          if (errors.password) {
            errorMessages._error = errors.password;
          }
          if (errors.invitation_expired) {
            errorMessages._error = errorMessage.invitationExpired;
          }
        } catch (error) {
          errorMessages = defaultErrorMessage;
        }
      }

      throw new SubmissionError(errorMessages);
    });
};

export const createAccountActivationRequest = (formValues, code) => (
  dispatch
) => {
  dispatch(setLoading());

  return sendAccountActivation(formValues, code)
    .then(() => {
      navigateToUrl(Urls.loginPage);
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      let errorMessages = defaultErrorMessage;
      if (err.status === 422) {
        try {
          errorMessages = JSON.parse(err.response.text).errors;
        } catch (error) {
          errorMessages = defaultErrorMessage;
        }
      }
      throw new SubmissionError(errorMessages);
    });
};

export const createSalesforceAuthRequest = (code, type, useSandbox) => (
  dispatch
) => {
  openOAuthPopupWindow('onboardingCrmAuthSuccess', {
    url: routesUrls.loginSalesforceWithInvitation(code, type, useSandbox),
  }).then(() => {
    dispatch({
      created: true,
      type: CreateAccountActionTypes.setAccountCreatedFromSF,
    });

    setTimeout(() => {
      navigateToUrl(Urls.onboarding.welcome);
    }, 1500);
  });
};

export const checkActivationCode = (code) => (dispatch) => {
  dispatch(setLoading(true));

  return checkActivationCodeCall(code)
    .then((response) => {
      return response.valid;
    })
    .catch(() => {
      return false;
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
