import React, { useState } from 'react';

const withDropdownToggler = (Component) => (props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const hideDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <Component
      {...props}
      toggleDropdown={toggleDropdown}
      showDropdown={showDropdown}
      hideDropdown={hideDropdown}
    />
  );
};

export default withDropdownToggler;
