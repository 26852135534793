import { baseDelete, baseGet, postFile } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const getContent = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.content, {}, resolve, reject);
  });

export function uploadFile(file, progress) {
  return new Promise((resolve, reject) => {
    postFile(Urls.fileUpload, file, progress, resolve, reject);
  });
}

export function deleteFiles(ids) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.contentCollection, { ids }, resolve, reject);
  });
}

export const getBoxContent = (query) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.boxContent.replace(':#query', query), {}, resolve, reject);
  });
