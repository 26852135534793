import I18N from 'languages';

export const CAMPAIGN_FILTER_ID = 'CAMPAIGN_FILTER_ID';
export const SORT_ORDER_CONDITION = { HIGH: -1, LOW: 1 };

export const campaignFilterValues = {
  campaignEmails: 'campaignEmails',
  noFilterSelected: 'noFilterSelected',
  nonCampaignEmails: 'nonCampaignEmails',
};

export const campaignFilterOptions = [
  {
    label: I18N.getStr('web.emails.advancedSearchFilter.noFilterSelected'),
    value: campaignFilterValues.noFilterSelected,
  },
  {
    label: I18N.getStr('web.emails.advancedSearchFilter.campaignEmails'),
    value: campaignFilterValues.campaignEmails,
  },
  {
    label: I18N.getStr('web.emails.advancedSearchFilter.nonCampaignEmails'),
    value: campaignFilterValues.nonCampaignEmails,
  },
];
