import myProfileActionTypes from '../libs/myProfileActionTypes';

export const showAlert = (alertOptions) => ({
  type: myProfileActionTypes.showAlert,
  alertOptions,
});

export const clearAlert = () => ({
  type: myProfileActionTypes.clearAlert,
});
