import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import ComplianceDetails from './details';
import ComplianceEditing from './editing';

class ComplianceCard extends Component {
  render() {
    const {
      actionCreators: {
        closeCardEditing,
        saveCompliance,
        updateComplianceEditState,
      },
      complianceCardCanSave,
      complianceEditState,
      isEditing,
      person,
    } = this.props;

    if (isEditing) {
      return (
        <ComplianceEditing
          cancel={closeCardEditing}
          complianceCardCanSave={complianceCardCanSave}
          complianceEditState={complianceEditState}
          person={person}
          save={saveCompliance}
          updateCompliance={updateComplianceEditState}
        />
      );
    } else {
      return <ComplianceDetails edit={this.edit} person={person} />;
    }
  }

  edit = () =>
    this.props.actionCreators.openCardEditing(PersonDetailsCards.compliance);
}

ComplianceCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  complianceCardCanSave: PropTypes.bool,
  complianceEditState: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  person: PropTypes.object.isRequired,
};

ComplianceCard.defaultProps = {
  isEditing: false,
};

export default ComplianceCard;
