import get from 'lodash/get';

export const getSlackIntegration = (state) =>
  get(state, 'entities.integrations.slack.item', {});

export const hasValidSlackCredentials = (state) =>
  get(state, 'entities.integrations.slack.item.valid_credentials?');

export const getSlackIntegrationFetching = (state) =>
  state.entities.integrations.slack.fetching;
