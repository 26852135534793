import AlertActionTypes from 'web/view/libs/alertActionTypes';

export const initViewAlert = { id: '', values: {} };
export const viewAlert = (
  state = initViewAlert,
  { type, id = '', values = {} }
) => {
  switch (type) {
    case AlertActionTypes.view.open:
      return { id, values };
    case AlertActionTypes.view.close:
      return initViewAlert;
    default:
      return state;
  }
};
