import { baseGet, basePost, basePut } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const emailServiceGet = (endpoint, data) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.emailServiceProxy,
      { endpoint, payload: { ...data } },
      resolve,
      reject
    );
  });

export const emailServicePost = (endpoint, data) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.emailServiceProxy,
      { endpoint, payload: { ...data } },
      resolve,
      reject
    );
  });

export const emailServicePut = (endpoint, data) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.emailServiceProxy,
      { endpoint, payload: { ...data } },
      resolve,
      reject
    );
  });
