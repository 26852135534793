import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import DetailListView from 'web/shared/components/detailListView/detailListView';
import { detailListParser } from '../../helpers/parsers';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import CampaignsAlert from 'web/campaigns/campaignsTab/components/campaignsAlert/campaignsAlert';
import CampaignDetailsView from '../campaignDetailsView/campaignDetailsView';
import CampaignContentHeader from '../campaignContentHeader/campaignContentHeader';
import FeedbackButton from 'web/view/components/feedbackButton/feedbackButton';
import './campaignContentView.scss';

const CAMPAIGNS_FEEDBACK_URL =
  'https://docs.google.com/a/toutapp.com/forms/d/17UvgqKuYsl3fOxCZG-AUabzFtMYx4eKWbogskI-dwz8/viewform?c=0&w=1';

class CampaignContentView extends Component {
  render() {
    const { actionCreators, campaignAlert, campaignsLoading } = this.props;
    const contentComponent = this._getContentComponent();

    return (
      <div className="campaigns-content-view">
        <CampaignsAlert
          actionCreators={actionCreators}
          campaignAlert={campaignAlert}
        />
        {campaignsLoading ? <LoadingSpinner imageUrl={getImageUrl} /> : null}
        {contentComponent}
        <FeedbackButton feedbackURL={CAMPAIGNS_FEEDBACK_URL} hidden />
      </div>
    );
  }

  editName = (isEditing) => {
    const { actionCreators } = this.props;
    if (isEditing) {
      actionCreators.editCampaignName();
    } else {
      actionCreators.notEditingCampaignName();
    }
  };

  _getContentComponent = () => {
    const {
      account,
      actionCreators,
      addingNewCampaignDay,
      attachments,
      campaignsPeople,
      campaignDayEditing,
      campaignDayLoading,
      campaigns,
      campaignEditStates,
      campaignTemplates,
      campaignTemplatesLoading,
      ckeBodies,
      campaignsListFilter,
      campaignsPeopleFilter,
      campaignsPeopleViewAsFilter,
      campaignsPeoplePagination,
      campaignsTabViewFetching,
      campaignsTabViewLoading,
      campaignsPeopleSearchString,
      campaignsLoading,
      currentCategory,
      editingSteps,
      isEditingName,
      intl: { formatMessage },
      newDayNumber,
      selectedCampaign,
      selectedDetailsTab,
      settings,
      tasks,
      tasksDueIndexedByCampaign,
      timezone,
      timezones,
      campaignsPeopleLength,
      userCanEditCampaigns,
    } = this.props;

    if (selectedCampaign && selectedCampaign.id) {
      return (
        <CampaignDetailsView
          account={account}
          actionCreators={actionCreators}
          addingNewCampaignDay={addingNewCampaignDay}
          attachments={attachments}
          campaignDayEditing={campaignDayEditing}
          campaignDayLoading={campaignDayLoading}
          campaignEditStates={campaignEditStates}
          campaignsPeople={campaignsPeople}
          campaignsPeopleFilter={campaignsPeopleFilter}
          campaignsPeopleViewAsFilter={campaignsPeopleViewAsFilter}
          campaignsPeoplePagination={campaignsPeoplePagination}
          campaignsTabViewFetching={campaignsTabViewFetching}
          campaignsTabViewLoading={campaignsTabViewLoading}
          campaignsPeopleSearchString={campaignsPeopleSearchString}
          campaignTemplates={campaignTemplates}
          campaignTemplatesLoading={campaignTemplatesLoading}
          ckeBodies={ckeBodies}
          editingSteps={editingSteps}
          editName={this.editName}
          isEditingName={isEditingName}
          newDayNumber={newDayNumber}
          onCampaignTabSelected={actionCreators.selectCampaignDetailsTab}
          selectedCampaign={selectedCampaign}
          campaignsPeopleLength={campaignsPeopleLength}
          selectedDetailsTab={selectedDetailsTab}
          settings={settings}
          tasks={tasks}
          tasksDue={tasksDueIndexedByCampaign[selectedCampaign.id]}
          timezone={timezone}
          timezones={timezones}
          updateCampaign={actionCreators.updateCampaign}
          userCanEditCampaigns={userCanEditCampaigns}
        />
      );
    } else if (!campaignsLoading) {
      return (
        <div className="campaign-list-view-wrapper">
          <CampaignContentHeader
            account={account}
            filter={campaignsListFilter}
            onFilter={actionCreators.filterCampaignsList}
            titleOne={formatMessage({
              id: 'web.campaigns.myCampaignActivities',
            })}
            titleTwo={formatMessage({ id: 'web.campaigns.teamMetrics' })}
          />
          <DetailListView
            account={account}
            currentCategory={currentCategory}
            listData={detailListParser(
              campaigns,
              account,
              selectedDetailsTab,
              tasksDueIndexedByCampaign
            )}
          />
        </div>
      );
    } else {
      return null;
    }
  };
}

CampaignContentView.propTypes = {
  account: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  addingNewCampaignDay: PropTypes.bool.isRequired,
  attachments: PropTypes.object.isRequired,
  campaignAlert: PropTypes.object.isRequired,
  campaignDayEditing: PropTypes.object.isRequired,
  campaignDayLoading: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaigns: PropTypes.array.isRequired,
  campaignsListFilter: PropTypes.string.isRequired,
  campaignsLoading: PropTypes.bool.isRequired,
  campaignsPeople: PropTypes.array.isRequired,
  campaignsPeopleLength: PropTypes.number.isRequired,
  campaignsPeopleFilter: PropTypes.string.isRequired,
  campaignsPeopleViewAsFilter: PropTypes.number.isRequired,
  campaignsPeoplePagination: PropTypes.number.isRequired,
  campaignsPeopleSearchString: PropTypes.string.isRequired,
  campaignsTabViewFetching: PropTypes.bool.isRequired,
  campaignsTabViewLoading: PropTypes.bool.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  campaignTemplatesLoading: PropTypes.bool.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  currentCategory: PropTypes.number.isRequired,
  editingSteps: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isEditingName: PropTypes.bool.isRequired,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  selectedDetailsTab: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  tasksDueIndexedByCampaign: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  userCanEditCampaigns: PropTypes.bool,
};

CampaignContentView.defaultProps = {
  userCanEditCampaigns: true,
};

export default injectIntl(CampaignContentView);
