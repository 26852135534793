export const SENT_ACTIVITIES = {
  /* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
  views: {
    viewed: {
      label: 'common.views',
      value: 'viewed',
    },
    notViewed: {
      label: 'common.noViews',
      value: 'notViewed',
    },
  },
  clicks: {
    clicked: {
      label: 'common.clicks',
      value: 'clicked',
    },
    notClicked: {
      label: 'common.noClicks',
      value: 'notClicked',
    },
  },
  replies: {
    replied: {
      label: 'common.replies',
      value: 'replied',
    },
    notReplied: {
      label: 'common.noReplies',
      value: 'notReplied',
    },
  },
  /* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */
};

export const disabledPrefix = 'not';
