import {
  MESSAGE_TYPE,
  SALESFORCE_SOURCE,
} from 'web/salesforce/libs/salesforceConstants';
import { BaseUrl } from 'web/libs/environment';
import { Urls } from 'web/libs/routes';
import { SalesforceTabs as SalesforceAccountTabs } from 'web/settings/accountSettings/salesforce/libs/salesforceConstants';
import { SalesforceTabs as SalesforceAdminTabs } from 'web/settings/adminSettings/salesforce/libs/salesforceConstants';

export const percentCalculation = (stats = {}) => {
  const { total, used } = stats;
  if (total && used && used > 0) {
    const proportion = (used * 100) / total;
    return proportion <= 100 ? Math.ceil(proportion) : 100;
  } else {
    return null;
  }
};

export const findContactIntegrationForSalesforce = (integrations = []) =>
  integrations.find((it) => it.name === SALESFORCE_SOURCE);

export const getConnectLink = (isAdmin) => {
  if (isAdmin) {
    return (
      Urls.next.hash +
      Urls.salesforceAdmin(SalesforceAdminTabs.connectionsCustomizations)
    );
  } else {
    return (
      Urls.next.hash +
      Urls.salesforceAccount(SalesforceAccountTabs.connectionsCustomizations)
    );
  }
};

export const handleMessageEvents = (event) => {
  if (
    event.data.type === MESSAGE_TYPE.RELOAD_MARKETO_SALES_OUTBOX_IN_SFDC &&
    event.origin === BaseUrl
  ) {
    window.location.reload();
  }
};
