import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import GeneralPage from '../generalPage';
import GeneralAlertsContainer from '../../containers/generalAlertsContainer';

const General = (props) => (
  <Fragment>
    <GeneralPage {...props} />
    <GeneralAlertsContainer />
  </Fragment>
);

General.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
};

export default General;
