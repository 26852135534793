import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { array, bool, func, number, object, string } from 'prop-types';
import Sidebar from 'web/shared/components/sidebar/sidebar';
import {
  deleteCategory,
  filterByCategory,
  isRenameCategoryDisabled,
  renameCategoryConfirm,
  setTemplateEditCategory,
} from 'web/templates/actionCreators/templateCategoriesActionCreators';
import {
  onSidebarFilterSelect,
  setSidebarFilterInput,
} from 'web/templates/actionCreators/templatesViewerActionCreators';
import { searchTemplates } from 'web/templates/actionCreators/templatesFetchActionCreators';

import { openCreateCategoryPopup } from 'web/templates/actionCreators/templatesPopupActionCreators';
import {
  getViewableCategories,
  getFormattedFilterItems,
  isDisabledForViewer as isDisabledForViewerSelector,
  getTemplatesSidebarSearchResults,
  getUserCanEditTemplates,
} from 'web/templates/selectors/templatesSelectors';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { TemplatesEvents } from 'web/libs/mixpanelEvents';
import {
  onCategorySelect,
  templatesSidebarSearchResultsSelected,
} from '../actionCreators/templatesSidebarActionCreators';

export class TemplatesSidebarContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    this.actionCreators = bindActionCreators(
      {
        deleteCategory,
        filterByCategory,
        isRenameCategoryDisabled,
        onCategorySelect,
        onSidebarFilterSelect,
        openCreateCategoryPopup,
        renameCategoryConfirm,
        searchTemplates,
        setSidebarFilterInput,
        setTemplateEditCategory,
        templatesSidebarSearchResultsSelected,
      },
      dispatch
    );
  }

  render() {
    const {
      isDisabledForViewer,
      selectedTemplateCategoryId,
      templatesCategories,
      templatesCategoriesLoading,
      templatesEditingCategory,
      intl: { formatMessage },
      userCanEditTemplates,
      sidebarSearchResults,
    } = this.props;

    return (
      <Sidebar
        filter={this.getFilter()}
        list={{
          draggable: true,
          editingItemId: templatesEditingCategory,
          isItemAddHidden: !userCanEditTemplates,
          itemOptions: userCanEditTemplates
            ? [
                {
                  action: this.actionCreators.setTemplateEditCategory,
                  disabled: this.actionCreators.isRenameCategoryDisabled,
                  label: formatMessage({ id: 'common.rename' }),
                },
                {
                  action: this.actionCreators.deleteCategory,
                  label: formatMessage({ id: 'common.delete' }),
                },
              ]
            : [],
          listData: templatesCategories,
          listLoading: templatesCategoriesLoading,
          listName: formatMessage({ id: 'common.categories' }),
          onEdit: this.actionCreators.renameCategoryConfirm,
          onEditCancel: this.actionCreators.setTemplateEditCategory,
          onItemAddClick: this.actionCreators.openCreateCategoryPopup,
          onItemAddDisabled: isDisabledForViewer,
          onSelect: this.actionCreators.onCategorySelect,
          selectedListItem: selectedTemplateCategoryId,
        }}
        multiSearch={{
          mixpanelEvent: TemplatesEvents.searchTemplate,
          onSearch: this.actionCreators.searchTemplates,
          onSearchResultClick: this.actionCreators
            .templatesSidebarSearchResultsSelected,
          searchResults: sidebarSearchResults,
        }}
      />
    );
  }

  getFilter = () => {
    const {
      user: { admin },
      filterItems,
      templatesFilterInput,
      templatesViewer: { id },
      intl: { formatMessage },
    } = this.props;

    if (!admin) {
      return {};
    }

    return {
      inputValue: templatesFilterInput,
      items: filterItems,
      label: formatMessage({ id: 'web.templates.viewAs' }),
      onChange: this.actionCreators.setSidebarFilterInput,
      onSelect: this.actionCreators.onSidebarFilterSelect,
      selectedItem: id,
    };
  };
}

TemplatesSidebarContainer.propTypes = {
  dispatch: func.isRequired,
  filterItems: array.isRequired,
  intl: intlShape.isRequired,
  isDisabledForViewer: bool.isRequired,
  selectedTemplateCategoryId: number.isRequired,
  sidebarSearchResults: array.isRequired,
  templatesCategories: array.isRequired,
  templatesCategoriesLoading: bool.isRequired,
  templatesEditingCategory: number,
  templatesFilterInput: string.isRequired,
  templatesViewer: object.isRequired,
  user: object.isRequired,
  userCanEditTemplates: bool.isRequired,
};

TemplatesSidebarContainer.defaultProps = {
  templatesEditingCategory: null,
};

const mapStateToProps = (state) => ({
  filterItems: getFormattedFilterItems(state),
  isDisabledForViewer: isDisabledForViewerSelector(state),
  selectedTemplateCategoryId: state.templatesSelectedTemplateCategoryId,
  sidebarSearchResults: getTemplatesSidebarSearchResults(state),
  templatesCategories: getViewableCategories(state),
  templatesCategoriesLoading: state.templatesCategoriesLoading,
  templatesEditingCategory: state.templatesEditingCategory,
  templatesFilterInput: state.templatesFilterInput,
  templatesViewer: state.templatesViewer,
  user: state.user,
  userCanEditTemplates: getUserCanEditTemplates(state),
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(TemplatesSidebarContainer);
