import { closeSlideOuts } from 'web/slideOuts/actionCreators/slideOutsActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { openTemplateDetails } from 'web/templates/actionCreators/templatesTableActionCreators';
import { TemplatesSearchDataTypes } from 'web/templates/libs/templatesConstants';
import { EditTemplatePopupIds } from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import { hasChanges } from 'web/slideOuts/editTemplate/helpers/editTemplateHasChangesHelper';
import { navigateToTemplateCategory } from 'web/services/routerService';

export const changeCategory = (categoryId) => (dispatch) => {
  dispatch(closeSlideOuts());
  navigateToTemplateCategory(categoryId);
};

export const onCategorySelect = (categoryId) => (dispatch, getState) => {
  if (hasChanges(getState())) {
    dispatch(
      openPopup(EditTemplatePopupIds.hasChanges, {
        callback: 'changeCategory',
        args: [categoryId],
      })
    );
  } else {
    dispatch(changeCategory(categoryId));
  }
};

export const templatesSidebarSearchResultsSelected = ({
  type = '',
  item = {},
}) => (dispatch) => {
  // eslint-disable-next-line default-case
  switch (type.toUpperCase()) {
    case TemplatesSearchDataTypes.categories.type:
      dispatch(onCategorySelect(item.id));
      break;
    case TemplatesSearchDataTypes.templates.type:
      dispatch(openTemplateDetails(item));
      break;
  }
};
