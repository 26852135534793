import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import './progressSpinner.scss';

const ProgressSpinner = (props) => {
  const { cancelProgress, liquifyProgress, textId } = props;

  return (
    <div className="progress-spinner">
      <LoadingSpinner imageUrl={getImageUrl} size={60}>
        <div className="progress-description">
          <FormattedMessage id={textId} />
        </div>
        <div className="progress-percentage">
          <FormattedMessage
            id="common.progress"
            values={{ percentage: liquifyProgress }}
          />
        </div>

        <div className="progress-cancel" onClick={cancelProgress}>
          <FormattedMessage id="common.cancel" />
        </div>
      </LoadingSpinner>
    </div>
  );
};

ProgressSpinner.propTypes = {
  cancelProgress: PropTypes.func.isRequired,
  liquifyProgress: PropTypes.number.isRequired,
  textId: PropTypes.string.isRequired,
};

export default ProgressSpinner;
