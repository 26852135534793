import React from 'react';
import PropTypes from 'prop-types';
import CampaignSettingsGeneral from '../campaignSettingsGeneral/campaignSettingsGeneral';
import CampaignSettingsReply from '../campaignSettingsReply/campaignSettingsReply';
import CampaignSettingsTitleCard from '../campaignSettingsTitleCard/campaignSettingsTitleCard';

import './campaignSettings.scss';

const CampaignSettings = ({ selectedCampaign, actionCreators }) => (
  <div className="settings-grid-ie">
    <div className="settings-grid">
      <CampaignSettingsTitleCard
        title="web.campaigns.general"
        description="web.campaigns.generalDescription"
      >
        <CampaignSettingsGeneral
          selectedCampaign={selectedCampaign}
          actionCreators={actionCreators}
        />
      </CampaignSettingsTitleCard>
      <CampaignSettingsTitleCard
        title="web.campaigns.replyOccurs"
        description="web.campaigns.replyOccursDescription"
      >
        <CampaignSettingsReply
          selectedCampaign={selectedCampaign}
          actionCreators={actionCreators}
        />
      </CampaignSettingsTitleCard>
    </div>
  </div>
);

CampaignSettings.propTypes = {
  selectedCampaign: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
};

export default CampaignSettings;
