import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Collapse from 'components/collapse';
import ComposeTemplatesNavBar from 'web/composeWindow/components/composeTemplatesNavBar/composeTemplatesNavBar';
import './composeTemplatesContainer.scss';
import {
  deselectTemplate,
  getTemplates,
  handleTemplateClick,
  openEditPinnedCategoriesPopup,
} from 'web/composeWindow/actionCreators/composeTemplatesActionCreators';
import {
  getPinnedCategories,
  getTemplateCategories,
  hasRecentlyUsedTemplates,
} from 'web/composeWindow/selectors/composeWindowSelectors';
import TemplateCard from 'web/composeWindow/components/composeTemplatesTemplateCard/composeTemplatesTemplateCard';
import ZeroState from 'table/modules/zeroState';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import {
  AllCategory,
  FavoritesCategory,
} from '../libs/composeTemplatesConstants';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { Urls } from 'web/libs/routes';
import { RecommendedCategory } from 'web/composeWindow/libs/composeTemplatesConstants';

const TEMPLATES_FETCH_DEBOUNCE_MS = 500;
const TEMPLATES_SCROLL_DEBOUNCE_MS = 250;
const TEMPLATE_SEARCH_AUTOCOMPLETE_INPUT_MIN = 3;

class ComposeTemplatesContainer extends React.Component {
  constructor(props) {
    const { getTemplates } = props;
    super(props);
    this.state = {
      searchTerm: '',
      selectedCategory: AllCategory,
    };
    this.debouncedGetTemplates = debounce(
      getTemplates,
      TEMPLATES_FETCH_DEBOUNCE_MS
    );
    this.templatesRef = null;
  }

  setTemplatesRef = (ref) => {
    this.removeTemplatesRefListener();
    this.templatesRef = ref;
    if (ref) {
      ref.addEventListener(
        'scroll',
        debounce(this.handleScroll, TEMPLATES_SCROLL_DEBOUNCE_MS)
      );
    }
  };

  componentDidMount() {
    const { pinnedCategories } = this.props;
    if (!pinnedCategories.length) {
      const { selectedCategory } = this.state;
      this.fetchTemplates({ categoryId: selectedCategory.id });
    }
  }

  componentWillUnmount() {
    this.removeTemplatesRefListener();
  }

  removeTemplatesRefListener = () => {
    if (this.templatesRef) {
      this.templatesRef.removeEventListener('scroll', this.handleScroll);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedCategory: { id: prevSelectedCategoryId },
    } = prevState;
    const { pinnedCategories } = this.props;

    let selectedCategory = AllCategory;
    if (pinnedCategories.length) [selectedCategory] = pinnedCategories;
    const { id: selectedCategoryId } = selectedCategory;
    if (
      selectedCategoryId !== prevSelectedCategoryId &&
      !isEqual(prevProps.pinnedCategories, pinnedCategories)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchTerm: '', selectedCategory }, () =>
        this.fetchTemplates({ categoryId: selectedCategory.id })
      );
    }
  }

  toggleMinimize = () => {
    const { isTemplatesMinimized, setTemplatesMinimized } = this.props;
    setTemplatesMinimized(!isTemplatesMinimized);
  };

  fetchTemplates = ({ categoryId, searchTerm = '', page = 1 }) => {
    this.debouncedGetTemplates({ categoryId, page, term: searchTerm });
  };

  handleSelectCategory = (selectedCategory) => {
    this.setState({ searchTerm: '', selectedCategory }, () => {
      if (this.templatesRef) this.templatesRef.scrollTop = 0; // reset scroll position of window to top
      this.fetchTemplates({ categoryId: selectedCategory.id });
    });
  };

  handleSearchTerm = (searchTerm = '') => {
    if (
      searchTerm.length >= TEMPLATE_SEARCH_AUTOCOMPLETE_INPUT_MIN ||
      searchTerm.length === 0
    ) {
      const {
        selectedCategory: { id: categoryId },
      } = this.state;
      this.setState({ searchTerm }, () =>
        this.fetchTemplates({ categoryId, searchTerm })
      );
    } else {
      this.setState({ searchTerm });
    }
  };

  handleTemplateClick = (template) => {
    const {
      deselectTemplate,
      handleTemplateClick,
      selectedTemplate,
    } = this.props;
    const canSetTemplate =
      !selectedTemplate || template.id !== selectedTemplate.id;
    if (canSetTemplate) {
      handleTemplateClick(template);
    } else {
      deselectTemplate();
    }
  };

  handleScroll = () => {
    const {
      composeTemplates: { templates, total, page },
    } = this.props;
    const {
      selectedCategory: { id: categoryId },
      searchTerm,
    } = this.state;

    if (templates.length === total) return;

    const reachedContainerScrollEnd =
      this.templatesRef.scrollHeight <=
      this.templatesRef.scrollTop + this.templatesRef.clientHeight;

    if (reachedContainerScrollEnd) {
      this.fetchTemplates({
        categoryId,
        page: page + 1,
        searchTerm,
      });
    }
  };

  getContent = () => {
    const {
      composeTemplates: { templates, highlightTerm },
      intl: { formatMessage },
      selectedTemplate,
      pinnedCategories,
    } = this.props;

    const { selectedCategory } = this.state;

    if (templates.length) {
      return (
        <React.Fragment>
          {this._getRecommendedBanner()}
          {templates.map((template) => (
            <TemplateCard
              handleClick={this.handleTemplateClick}
              highlightTerm={highlightTerm}
              isSelected={
                selectedTemplate && template.id === selectedTemplate.id
              }
              key={template.id}
              template={template}
            />
          ))}
        </React.Fragment>
      );
    } else {
      let zeroStateTitle =
        'web.composeWindow.pinnedCategories.noPinnedCategoriesTitle';
      let zeroStateBody =
        'web.composeWindow.pinnedCategories.noPinnedCategoriesBody';

      if (pinnedCategories.length) {
        zeroStateTitle =
          selectedCategory.id === FavoritesCategory.id
            ? 'web.composeWindow.pinnedCategories.noFavoritedTitle'
            : 'web.templates.zeroStateTitle';

        zeroStateBody =
          selectedCategory.id === FavoritesCategory.id
            ? 'web.composeWindow.pinnedFavoritedCategories.zeroStateBody'
            : 'web.composeWindow.pinnedCategories.zeroStateBody';
      }
      return (
        <ZeroState
          bodyText={
            <FormattedHTMLMessage
              id={zeroStateBody}
              values={{ url: Urls.templatesTab }}
            />
          }
          titleText={formatMessage({ id: zeroStateTitle })}
        />
      );
    }
  };

  _getRecommendedBanner = () => {
    const { selectedCategory } = this.state;
    return (
      selectedCategory.id === RecommendedCategory.id && (
        <div className="compose-templates-recommended-banner">
          <FormattedHTMLMessage id="web.composeWindow.templates.recommendedBanner" />
        </div>
      )
    );
  };

  _getBypassUnsubscribeBanner = () => {
    const { selectedTemplate } = this.props;

    if (selectedTemplate && selectedTemplate.bypass_unsubscribe) {
      return (
        <div className="compose-templates-bypass-unsubscribe-banner">
          <FormattedMessage id="web.composeWindow.templates.bypassUnsubscribeBanner" />
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { selectedCategory, searchTerm } = this.state;
    const {
      getTemplates,
      hasRecentlyUsedTemplates,
      openEditPinnedCategoriesPopup,
      pinnedCategories,
      templateCategories,
      isTemplatesMinimized,
      selectedTemplate,
    } = this.props;

    return (
      <div className="compose-templates-container">
        <ComposeTemplatesNavBar
          getTemplates={getTemplates}
          handleSearchTerm={this.handleSearchTerm}
          handleSelectCategory={this.handleSelectCategory}
          hasRecentlyUsedTemplates={hasRecentlyUsedTemplates}
          isMinimized={isTemplatesMinimized}
          openEditModal={openEditPinnedCategoriesPopup}
          pinnedCategories={pinnedCategories}
          searchTerm={searchTerm}
          selectedCategory={selectedCategory}
          selectedTemplate={selectedTemplate}
          templateCategories={templateCategories}
          toggleMinimize={this.toggleMinimize}
        />
        {this._getBypassUnsubscribeBanner()}
        <div className="compose-templates-content" ref={this.setTemplatesRef}>
          <Collapse in={!isTemplatesMinimized}>
            <div className="compose-templates-card-container">
              {this.getContent()}
            </div>
          </Collapse>
        </div>
      </div>
    );
  }

  static propTypes = {
    composeTemplates: PropTypes.object.isRequired,
    deselectTemplate: PropTypes.func.isRequired,
    getTemplates: PropTypes.func.isRequired,
    handleTemplateClick: PropTypes.func.isRequired,
    hasRecentlyUsedTemplates: PropTypes.bool,
    intl: intlShape.isRequired,
    isTemplatesMinimized: PropTypes.bool.isRequired,
    openEditPinnedCategoriesPopup: PropTypes.func.isRequired,
    pinnedCategories: PropTypes.array.isRequired,
    selectedTemplate: PropTypes.object,
    setTemplatesMinimized: PropTypes.func.isRequired,
    templateCategories: PropTypes.array.isRequired,
  };

  static defaultProps = {
    hasRecentlyUsedTemplates: false,
    selectedTemplate: null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        deselectTemplate,
        getTemplates,
        handleTemplateClick,
        openEditPinnedCategoriesPopup,
      },
      dispatch
    ),
  };
}

const mapStateToProps = (state) => ({
  composeTemplates: state.composeTemplates,
  hasRecentlyUsedTemplates: hasRecentlyUsedTemplates(state),
  pinnedCategories: getPinnedCategories(state),
  selectedTemplate: state.selectedTemplate,
  templateCategories: getTemplateCategories(state),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComposeTemplatesContainer)
);
