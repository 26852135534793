import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popup from 'components/popups';
import { ModalPopupIds, getImageUrl } from 'web/libs/constants';
import { closeModal } from 'web/modals/addContent/actionCreators/popupActionCreators';
import AddContentPartners from '../components/addContentPartners';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import ContentPartnerIframe from '../components/contentPartnerIframe/contentPartnerIframe';
import './addContentPartnersContainer.scss';
import {
  getContentPartners,
  setSelectedPartner,
  getContentPartnersMetadata,
} from 'web/modals/addContent/actionCreators/contentActionCreators';

class AddContentPartnersContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = {
      closePopup: () => dispatch(closeModal()),
      setSelectedPartner: (data) => dispatch(setSelectedPartner(data)),
      getContentPartners: () => dispatch(getContentPartners()),
      getContentPartnersMetadata: (pickerUrl) =>
        dispatch(getContentPartnersMetadata(pickerUrl)),
    };
  }

  componentWillMount() {
    const { contentPartners } = this.props;
    if (!contentPartners.loaded) {
      this.actionCreators.getContentPartners();
    }
  }

  render() {
    const { popup } = this.props;

    return (
      <div>{popup === ModalPopupIds.addContent && this.popupChildren()}</div>
    );
  }

  popupChildren = () => {
    const { selectedPartner, windowMode, partnerMetadata } = this.props;

    if (Object.keys(selectedPartner).length > 0) {
      return (
        <ContentPartnerIframe
          closePopup={this.actionCreators.closePopup}
          getPartnerMetadata={this.actionCreators.getContentPartnersMetadata}
          partner={selectedPartner}
          partnerMetadata={partnerMetadata}
          windowMode={windowMode}
        />
      );
    } else {
      return (
        <Popup
          className="add-content-partner-selector"
          children={this.popupModalChildren()}
          header={{
            onClose: this.actionCreators.closePopup,
            textId: 'contentPartners.modal.title',
          }}
        />
      );
    }
  };

  popupModalChildren = () => {
    if (this.props.contentModalLoading) {
      return <LoadingSpinner imageUrl={getImageUrl} />;
    } else {
      return (
        <AddContentPartners
          contentPartners={this.props.contentPartners}
          imageUrl={getImageUrl}
          setSelectedPartner={this.actionCreators.setSelectedPartner}
        />
      );
    }
  };
}

AddContentPartnersContainer.propTypes = {
  contentPartners: PropTypes.object.isRequired,
  contentModalLoading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  selectedPartner: PropTypes.object.isRequired,
  popup: PropTypes.string.isRequired,
  windowMode: PropTypes.bool.isRequired,
  partnerMetadata: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    contentPartners: state.contentPartners,
    contentModalLoading: state.contentModalLoading,
    popup: state.popup,
    selectedPartner: state.selectedPartner,
    windowMode: state.addContentModalWindowMode,
    partnerMetadata: state.partnerMetadata,
  };
}

export default connect(mapStateToProps)(AddContentPartnersContainer);
