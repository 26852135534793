import { createSelector } from 'reselect';

export const getSelectableSelectedItems = (state) =>
  state.selectableSelectedItems;

export const getPaginationItems = (state) => state.paginationItems;

export const getHeaderSelected = createSelector(
  [getPaginationItems, getSelectableSelectedItems],
  (paginationItems, selectedIds) => {
    const selectedKeys = Object.keys(selectedIds) || [];

    const selectableEnabledItems = paginationItems
      .filter(
        (item) =>
          Object.prototype.hasOwnProperty.call(item, 'isSelectedDisabled')
            ? !item.isSelectedDisabled
            : item
      )
      .filter((item) => item.person_id !== null);

    if (selectedKeys.length === 0) {
      return false;
    }

    return selectableEnabledItems.every(
      (item) => item.id && selectedKeys.includes(item.id.toString())
    );
  }
);

export const getSelectableIds = createSelector(
  [getSelectableSelectedItems],
  (selectedItems) => {
    const result = {};

    for (const item in selectedItems) {
      if (Object.prototype.hasOwnProperty.call(selectedItems, item)) {
        result[item] = true;
      }
    }

    return result;
  }
);
