import { Regex } from 'web/libs/constants';
import { AddressTypes } from '../libs/personDetailsConstants';

export const PROTOCOL = 'https://';
export const FACEBOOK = 'facebook.com';
export const TWITTER = 'twitter.com';
export const LINKEDIN = 'linkedin.com/in';
export const LINKEDIN_TOUT_EXTRA = 'linkedin://';

export const addProtocol = (site) => `${PROTOCOL}${site}`;

export const getFacebookUrlFromUsername = (address = '') =>
  Regex.usernameFacebook.test(address)
    ? `${addProtocol(FACEBOOK)}/${address}`
    : '';

export const getTwitterUrlFromUsername = (address = '') =>
  Regex.usernameTwitter.test(address)
    ? `${addProtocol(TWITTER)}/${address}`
    : '';

export const getLinkedInUrlFromUsername = (address = '') => {
  const value = address.replace(LINKEDIN_TOUT_EXTRA, ''); // legacy backend adds
  return Regex.usernameLinkedin.test(value)
    ? `${addProtocol(LINKEDIN)}/${value}`
    : '';
};

// If address is username, generate url
export const getSocialMediaUrl = (type, address = '') => {
  const isWebsite = Regex.website.test(address);
  if (isWebsite && Regex.websiteHttp.test(address)) {
    return address.replace(Regex.endingSlash, '');
  } else if (isWebsite) {
    return addProtocol(address);
  }

  switch (type) {
    case AddressTypes.facebook:
      return getFacebookUrlFromUsername(address);
    case AddressTypes.linkedin:
      return getLinkedInUrlFromUsername(address);
    case AddressTypes.twitter:
      return getTwitterUrlFromUsername(address);
    default:
      return '';
  }
};
