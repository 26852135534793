import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'web/liveFeed/components/liveFeedCardHeader';
import Info from 'web/liveFeed/components/contactInfo';
import ConvertActions from 'web/liveFeed/components/marketoCard/marketoConvertActions';

class ToutHeader extends Component {
  render() {
    const {
      actionCreators: {
        openContact,
        openCompany,
        openAddToCampaign,
        openCall,
        openEmail,
        openLinkedInSearch,
        openSalesforceContact,
      },
      pitch,
      pitch: {
        company,
        title,
        id,
        name,
        pitch_id: pitchId,
        salesforceId,
        salesforceContactIsLead,
      },
      salesforceInstanceUrl,
      isLoading,
      showToutBorder,
    } = this.props;
    return (
      <Header
        cardId={`tout-card-header-${id}`}
        contactId={id}
        toggleOpenChecks={this.toggleOpenChecks}
        openPdv={this._openPdv}
        infoChildren={
          <Info
            company={company}
            title={title}
            openCompany={openCompany}
            pitchId={pitchId}
          />
        }
        name={name}
        openChildren={
          <ConvertActions
            bestBet={pitch}
            isLoading={isLoading}
            openAddToCampaign={openAddToCampaign}
            openCall={openCall}
            openEmail={openEmail}
            openLinkedInSearch={openLinkedInSearch}
            openSalesforceContact={openSalesforceContact}
            salesforceId={salesforceId}
            salesforceContactIsLead={salesforceContactIsLead}
            salesforceInstanceUrl={salesforceInstanceUrl}
          />
        }
        openContact={openContact}
        showToutBorder={showToutBorder}
      />
    );
  }

  toggleOpenChecks = () => {
    const {
      pitch,
      actionCreators: { cardToggleOpenChecksPerson },
    } = this.props;
    cardToggleOpenChecksPerson(pitch);
  };

  _openPdv = () => {
    const {
      pitch: { personId },
      actionCreators: { openPdvForPerson },
    } = this.props;
    openPdvForPerson(personId);
  };
}

ToutHeader.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  pitch: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showToutBorder: PropTypes.bool,
};

ToutHeader.defaultProps = {
  showToutBorder: false,
};

export default ToutHeader;
