/*global Reflect*/
import cloneDeep from 'lodash/cloneDeep';

export const initMapIndex = { ids: [], map: {} };

export function resetMapAndIndex() {
  return cloneDeep(initMapIndex);
}

function addUpdate(mutableState, obj, propertyToIndex = 'id') {
  if (!mutableState.map.hasOwnProperty(obj[propertyToIndex])) {
    mutableState.ids.push(obj[propertyToIndex]);
  }
  mutableState.map[obj[propertyToIndex]] = obj;
  return mutableState;
}

export function bulkAddUpdateState(
  state = resetMapAndIndex(),
  array = [],
  propertyToIndex = 'id'
) {
  let mutableState = cloneDeep(state);

  array.forEach((obj) => {
    if (obj[propertyToIndex]) {
      mutableState = addUpdate(mutableState, obj, propertyToIndex);
    }
  });
  return mutableState;
}

export function addUpdateState(state, data, propertyToIndex) {
  const mutableState = cloneDeep(state);
  return addUpdate(mutableState, data, propertyToIndex);
}

export function bulkRemoveFromState(state = resetMapAndIndex(), array = []) {
  const mutableState = cloneDeep(state);

  array.forEach((index) => {
    Reflect.deleteProperty(mutableState.map, index);
  });
  mutableState.ids = mutableState.ids.reduce((accumulator, current) => {
    if (mutableState.map.hasOwnProperty(current)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
  return mutableState;
}

export function removeFromState(state, index) {
  let mutableState;
  if (state.map.hasOwnProperty(index)) {
    mutableState = cloneDeep(state);
    Reflect.deleteProperty(mutableState.map, index);

    const indexFound = mutableState.ids.findIndex(
      (element) => `${element}` === `${index}`
    );
    if (indexFound > -1) {
      mutableState.ids = [
        ...mutableState.ids.slice(0, indexFound),
        ...mutableState.ids.slice(indexFound + 1),
      ];
    }
  }
  return mutableState || state;
}
