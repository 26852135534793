export const PitchStates = {
  bounced: 'bounced', //1
  clicked: 'clicked', // stats
  deliveryInProgress: 'delivery_in_progress', //3
  draft: 'draft', //4
  enqueuedForDelivery: 'enqueued_for_delivery', //5
  failedDelivery: 'failed_delivery', //6
  outOfOffice: 'out_of_office', //7
  replied: 'replied', // stats
  scheduledForDelivery: 'scheduled_for_delivery', //8
  sent: 'sent', //9
  spam: 'spam', // 10
  successful: 'successful', // stats
  viewed: 'viewed', // stats
};

export const PitchEvents = {
  action: 'action',
  autoReply: 'auto_reply',
  bounce: 'bounce',
  click: 'click',
  dropped: 'dropped',
  open: 'open',
  reply: 'reply',
  spamComplaint: 'spam_complaint',
  spamReport: 'spamreport',
  visit: 'visit',
};

export const PitchCategoryToIgnore = 'General';

export const EMAILS_FEEDBACK_LINK =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=Wht7-jR7h0OUrtLBeN7O4RxOkDl3hxdHoHRn-dsVTV9UMjgwOE1NTEJEUUg1Sk5GVVAwNTBUQ0JQRC4u';

export const FailedDeliveryReason = {
  FAILED_DELIVERY_REASON_ACCOUNT_SUSPENDED:
    'Your account has been suspended, this email has been automatically failed',
  FAILED_DELIVERY_REASON_CAMPAIGN_FINISHED: 'Campaign has been finished.',
  FAILED_DELIVERY_REASON_CONTACT_PART_OF_CAMPAIGN:
    'Contact is already part of a campaign',
  FAILED_DELIVERY_REASON_COULD_NOT_POPULATE_ALL_FIELDS:
    "We couldn't populate all the dynamic fields for this email",
  FAILED_DELIVERY_REASON_EMAIL_ADDRESS_UNSUBSCRIBED:
    'The email address #{email} has been block unsubscribed from receiving further emails from your team',
  FAILED_DELIVERY_REASON_GENERAL_REASON: 'General',
  FAILED_DELIVERY_REASON_REACHED_DAILY_EMAIL_LIMIT:
    'You have reached your daily email limit of #{email_limits}',
  FAILED_DELIVERY_REASON_RECIPIENT_UNSUBSCRIBED:
    'Recipient has been block unsubscribed from receiving further emails from your team.',
  FAILED_DELIVERY_REASON_DELIVERY_FAILED: 'Delivery failed.',
};
