import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import moment from 'moment';
import I18N from 'languages';
import ToutTable from 'table/modules/toutTable';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import MenuCell from 'table/modules/cells/menuCell';
import { InviteesTableColumns } from '../../libs/inviteesConstants';
import { Colors, Aligns } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';
import { alphabetical } from 'table/modules/sorting';
import { inviteesTableExternals } from '../../helpers/inviteesHelpers';
import { tablePerPageFromStorage } from 'web/services/storageService';
import './inviteesTable.scss';

const CURRENT_PAGE = 1;
const ROW_HEIGHT = 70;

class InviteesTable extends Component {
  constructor(props) {
    super(props);

    this.id = 'invitees-table';
    this.pagination = {
      currentPage: CURRENT_PAGE,
      perPage: tablePerPageFromStorage(),
    };
    this.row = { height: ROW_HEIGHT };

    this.search = this._getSearch();
    this.columns = this._getColumns();
    this.bulkActions = this._getBulkActions();
    this.zeroState = this._getZeroState();
  }

  render() {
    const { fetching, loading, invitations } = this.props;

    return (
      <div className="invitees-table">
        <ToutTable
          allItems
          bulkActions={this.bulkActions}
          columns={this.columns}
          items={invitations}
          fetching={fetching}
          loading={loading}
          loadingGetUrl={getImageUrl}
          defaultSortingColumn={InviteesTableColumns.sentAt}
          pagination={this.pagination}
          row={this.row}
          setTableExternals={this.setTableExternals}
          search={this.search}
          selectable
          tableId={this.id}
          zeroState={this.zeroState}
          columnFilter
        />
      </div>
    );
  }

  componentWillUnmount() {
    inviteesTableExternals.unset();
  }

  setTableExternals = (externals) => inviteesTableExternals.set(externals);

  _getBulkActions = () => {
    const {
      actionCreators: { resendInvites, openWithdrawInvitesConfirmationPopup },
      intl: { formatMessage },
    } = this.props;

    return {
      actions: [
        {
          label: formatMessage({
            id:
              'web.settings.adminSettings.userManagement.invitees.resendInvites',
          }),
          color: Colors.blue,
          onClick: resendInvites,
        },
        {
          label: formatMessage({
            id:
              'web.settings.adminSettings.userManagement.invitees.withdrawInvites',
          }),
          color: Colors.red,
          onClick: openWithdrawInvitesConfirmationPopup,
        },
      ],
    };
  };

  _getColumns = () => {
    const {
      intl: { formatMessage },
      actionCreators,
    } = this.props;

    return [
      {
        id: InviteesTableColumns.email,
        name: formatMessage({ id: 'common.email' }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) => data.email,
          },
        },
        sorting: alphabetical('email'),
        align: Aligns.left,
      },
      {
        id: InviteesTableColumns.sentAt,
        name: formatMessage({
          id: 'web.settings.adminSettings.userManagement.invitees.sentAt',
        }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (item) =>
              item.sent_at ? (
                moment(item.sent_at).format(I18N.DateFormats.DATE_SHORT)
              ) : (
                <LoadingSpinner imageUrl={getImageUrl} size={50} />
              ),
          },
        },
        sorting: alphabetical('sent_at'),
        align: Aligns.center,
      },
      {
        id: InviteesTableColumns.expiresOn,
        name: formatMessage({
          id: 'web.settings.adminSettings.userManagement.invitees.expiresOn',
        }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            classNames: (item) => item['expired?'] && 'expired',
            formatText: (item) => {
              if (!item.expires_at) {
                return <LoadingSpinner imageUrl={getImageUrl} size={50} />;
              }
              if (item['expired?']) {
                return formatMessage({
                  id:
                    'web.settings.adminSettings.userManagement.invitees.expired',
                });
              }
              return moment(item.expires_at).format(
                I18N.DateFormats.DATE_SHORT
              );
            },
          },
        },
        sorting: alphabetical('expires_at'),
        align: Aligns.center,
      },
      {
        id: InviteesTableColumns.actions,
        name: formatMessage({
          id: 'common.actions',
        }),
        rowCell: {
          component: MenuCell,
          metadata: {
            menuItems: [
              {
                label: formatMessage({
                  id:
                    'web.settings.adminSettings.userManagement.invitees.resend',
                }),
                action: actionCreators.resendInvite,
              },
              {
                label: formatMessage({
                  id:
                    'web.settings.adminSettings.userManagement.invitees.withdraw',
                }),
                action: actionCreators.openWithdrawInviteConfirmationPopup,
              },
            ],
          },
        },
        align: Aligns.center,
      },
    ];
  };

  _getSearch = () => {
    const {
      intl: { formatMessage },
    } = this.props;

    return {
      placeholderText: formatMessage({ id: 'common.search' }),
      searching: (item) => item.email,
      showClear: false,
    };
  };

  _getZeroState = () => {
    const {
      intl: { formatMessage },
    } = this.props;

    return {
      bodyText: formatMessage({
        id: 'web.settings.adminSettings.userManagement.invitees.zeroStateBody',
      }),
      imageUrl: getImageUrl('rocket-blueprint', 'svg'),
      titleText: formatMessage({
        id: 'web.settings.adminSettings.userManagement.invitees.zeroStateTitle',
      }),
    };
  };
}

InviteesTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  invitations: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default injectIntl(InviteesTable);
