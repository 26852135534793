import DeliveryChannelsActionTypes from '../libs/deliveryChannelsActionTypes';
import { getDeliveryChannels as getDeliveryChannelsCall } from '../services/deliveryChannelsService';

export const setDeliveryChannelsFetched = (fetched) => ({
  type: DeliveryChannelsActionTypes.setDeliveryChannelsFetched,
  fetched,
});

export const setDeliveryChannelsFetching = () => ({
  type: DeliveryChannelsActionTypes.setDeliveryChannelsFetching,
});

export const getDeliveryChannels = () => (dispatch) => {
  dispatch(setDeliveryChannelsFetching());

  return getDeliveryChannelsCall()
    .then((items) => {
      dispatch({
        type: DeliveryChannelsActionTypes.setDeliveryChannels,
        items,
      });
      return items;
    })
    .catch((error) => {
      dispatch({
        type: DeliveryChannelsActionTypes.setDeliveryChannelsError,
      });
      return Promise.reject(error);
    });
};
