export const DefaultTimezone = 'America/Los_Angeles';

export const TimezonesCategories = {
  international: 'international',
  us: 'us',
};

export const TimezonesCategoriesOrderedEnum = {
  [TimezonesCategories.us]: 0,
  [TimezonesCategories.international]: 1,
};
