import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import OnboardingActionButtons from 'components/popups/popupFooter/popupFooter';
import onboardingPopupIds from 'web/onboarding/lib/onboardingPopupIds';
import { getIntegration } from 'web/settings/accountSettings/integrations/actionCreators/bccActionCreators';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import {
  isOnboardingStepPassed,
  getOnboardingAuthentications,
} from 'web/user/selectors/userSelectors';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import OnboardingStepSuccess from 'web/onboarding/components/onboardingStepSuccess/onboardingStepSuccess';
import { getBccIntegrationFetching } from 'web/integrations/bcc/selectors/bccSelector';
import { navigateToUrl } from 'web/services/routerService';
import {
  setCompletedStep,
  getOnboardingAuthenticationsAc,
} from 'web/onboarding/actionCreators/onboardingActionCreators';
import { stepsNames } from 'web/onboarding/lib/stepsRouting';
import { updateOnboardingSettings } from 'web/user/actionCreators/userActionCreators';
import classNames from 'classnames';

import './connectCRM.scss';

const skipStepPopupData = {
  primaryTextID: 'web.onboarding.popup.skipCRMStep.textPrimary',
  secondaryTextId: 'web.onboarding.popup.skipCRMStep.textSecondary',
};

const ConnectCRM = ({
  openPopup,
  getIntegration,
  getOnboardingAuthenticationsCrm,
  getOnboardingAuthenticationsAc,
  commonStartup,
  loading,
  location,
  setCompletedStep,
  isStepCRMPassed,
  updateOnboardingSettings,
}) => {
  const [successSetup, setSuccessSetup] = useState(false);

  useEffect(() => {
    commonStartup();
    getIntegration();
    getOnboardingAuthenticationsAc();

    if (location.hash.includes('_salesforce_connected_successfully')) {
      setSuccessSetup(true);
    }
  }, []);

  useEffect(
    () => {
      if (getOnboardingAuthenticationsCrm && !isStepCRMPassed) {
        updateOnboardingSettings(stepsNames.connectCRM);
      }
    },
    [getOnboardingAuthenticationsCrm]
  );

  return (
    <React.Fragment>
      {loading ? (
        <div className="onboarding-spinner-wrapper">
          <LoadingSpinner imageUrl={getImageUrl} size={130} />
        </div>
      ) : null}
      {!loading && successSetup ? (
        <OnboardingStepSuccess
          successStepSetupTextId={'web.onboarding.successStepSetup.crm'}
        />
      ) : null}
      {!loading && !successSetup ? (
        <div
          className={classNames('connect-crm-wrapper', {
            'connected-crm': isStepCRMPassed,
          })}
        >
          <p className="connect-crm-main">
            {isStepCRMPassed ? (
              <FormattedHTMLMessage id="web.onboarding.connected.crm.content" />
            ) : (
              <FormattedMessage id="web.onboarding.connect.crm.content" />
            )}
          </p>
          {isStepCRMPassed && (
            <div className="connected">
              <div className="connected-icon-wrapper">
                <i className="connected-icon" />
              </div>
              <FormattedMessage id="web.onboarding.connected.crm.completed" />
            </div>
          )}
          {isStepCRMPassed ? (
            <OnboardingActionButtons
              onPrimary={() => {
                setCompletedStep(1);
                navigateToUrl('#onboarding/email_connection');
              }}
              primaryTextId={'common.next'}
              full
            />
          ) : (
            <OnboardingActionButtons
              onPrimary={() => openPopup(onboardingPopupIds.saleforceConnect)}
              onSecondary={() =>
                openPopup(onboardingPopupIds.skipStep, skipStepPopupData)
              }
              primaryTextId={'common.connect'}
              secondaryTextId={'common.skip'}
              full
            />
          )}
        </div>
      ) : null}
    </React.Fragment>
  );
};

ConnectCRM.propTypes = {
  commonStartup: PropTypes.func.isRequired,
  getIntegration: PropTypes.func.isRequired,
  getOnboardingAuthenticationsAc: PropTypes.func.isRequired,
  getOnboardingAuthenticationsCrm: PropTypes.string,
  isStepCRMPassed: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  openPopup: PropTypes.func.isRequired,
  setCompletedStep: PropTypes.func.isRequired,
  updateOnboardingSettings: PropTypes.func.isRequired,
  user: {},
  userSettings: PropTypes.object,
};

ConnectCRM.defaultProps = {
  getOnboardingAuthenticationsCrm: '',
  isStepCRMPassed: undefined,
  loading: false,
  userSettings: {},
};

const mapStateToProps = (state) => ({
  getOnboardingAuthenticationsCrm: getOnboardingAuthentications(state),
  isStepCRMPassed: isOnboardingStepPassed(state, stepsNames.connectCRM),
  loading: getBccIntegrationFetching(state) || state.editIdentityLoading,
  userSettings: state.userSettings,
});

const mapDispatchToProps = {
  commonStartup,
  getIntegration,
  getOnboardingAuthenticationsAc,
  openPopup,
  setCompletedStep,
  updateOnboardingSettings,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ConnectCRM);
