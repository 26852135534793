import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import TemplatePopupIds from 'web/templates/libs/templatePopupIds';
import { PopupSize } from 'libs/constantsShared';
import {
  setPopupSelectedCategory,
  deleteCategoryConfirm,
  setTemplateNewCategoryOnChange,
  createNewCategory,
} from 'web/templates/actionCreators/templateCategoriesActionCreators';
import UpdateCategory from 'web/categories/components/updateCategory/updateCategory';
import CreateTemplate from 'web/templates/components/createTemplate';
import CreateNewCategory from 'web/categories/components/createNewCategory/createNewCategory';
import {
  bulkArchiveTemplate,
  bulkDestroyTemplate,
  bulkMoveTemplate,
} from 'web/templates/actionCreators/templatesBulkActionCreators';
import {
  createTemplateAndRedirect,
  updateCreateTemplateState,
} from 'web/templates/actionCreators/templatesCreateActionCreators';
import { getUpdatableCategories } from 'web/templates/selectors/templatesSelectors';
import { getImageUrl } from 'web/libs/constants';
import {
  closePopup,
  clearPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import { connect } from 'react-redux';
import { getAlert } from 'web/templates/services/templatePopupAlertService';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import bind from 'memoize-bind';

class TemplatesPopupContainer extends Component {
  constructor(props) {
    super();

    const { dispatch } = props;
    this.actionCreators = bindActionCreators(
      {
        bulkArchiveTemplate,
        bulkDestroyTemplate,
        bulkMoveTemplate,
        clearPopupAlert,
        closePopup,
        createNewCategory,
        createTemplateAndRedirect,
        deleteCategoryConfirm,
        setPopupSelectedCategory,
        setTemplateNewCategoryOnChange,
        updateCreateTemplateState,
      },
      dispatch
    );
  }

  render() {
    const popupObject = this.getPopupObject();

    return (
      <div>
        {popupObject && popupObject.children ? (
          <Popup {...popupObject} refName="templatesTabPopup" />
        ) : null}
      </div>
    );
  }

  getPopupObject = () => {
    const { popupAlert, popupAlertTextValues } = this.props;
    return {
      ...this.getPopupComponent(),
      alert: getAlert(this.actionCreators, popupAlert, popupAlertTextValues),
      getImageUrl,
      loading: this.props.popupLoading,
    };
  };

  getPopupComponent = () => {
    const {
      popupId,
      intl: { formatMessage },
    } = this.props;

    switch (popupId) {
      case TemplatePopupIds.moveTemplate: {
        const {
          popupData: { ids, callback: onSuccess },
          categories,
          templatesSelectedTemplateCategoryId,
          selectedCategory,
        } = this.props;

        return {
          children: (
            <UpdateCategory
              categories={categories}
              currentCategoryId={templatesSelectedTemplateCategoryId}
              selectedCategory={selectedCategory}
              selectedMessage={formatMessage(
                { id: 'web.templates.selectedTemplates' },
                { count: ids.length }
              )}
              updateCategory={this.actionCreators.setPopupSelectedCategory}
            />
          ),
          footer: {
            onPrimary: bind(
              this.actionCreators.bulkMoveTemplate,
              this,
              ids,
              selectedCategory,
              onSuccess
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.move',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.templates.moveTemplates',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TemplatePopupIds.archiveTemplate: {
        const {
          popupData: { ids, callback: onSuccess },
        } = this.props;
        return {
          children: (
            <FormattedMessage id="web.templates.archiveTemplatesConfirmation" />
          ),
          footer: {
            onPrimary: bind(
              this.actionCreators.bulkArchiveTemplate,
              this,
              ids,
              onSuccess
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.archive',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.templates.archiveTemplate',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TemplatePopupIds.deleteTemplate: {
        const {
          popupData: { ids, callback: onSuccess },
        } = this.props;
        return {
          children: (
            <FormattedMessage id="web.templates.deleteTemplatesConfirmation" />
          ),
          footer: {
            onPrimary: bind(
              this.actionCreators.bulkDestroyTemplate,
              this,
              ids,
              onSuccess
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.delete',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.templates.deleteTemplate',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TemplatePopupIds.createTemplate: {
        const { createTemplateState, categories } = this.props;

        return {
          children: (
            <CreateTemplate
              categories={categories}
              createTemplateState={createTemplateState}
              updateState={this.actionCreators.updateCreateTemplateState}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.createTemplateAndRedirect,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled:
              createTemplateState.name.trim().length <= 0 ||
              createTemplateState.categoryId <= 0,
            primaryTextId: 'common.create',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'banner.nuxContent.createTemplate',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TemplatePopupIds.createCategory: {
        const { newCategoryName } = this.props;
        const {
          setTemplateNewCategoryOnChange: setNewTemplateCategoryAc,
        } = this.actionCreators;
        const createAc = bind(
          this.actionCreators.createNewCategory,
          this,
          newCategoryName
        );
        return {
          children: (
            <CreateNewCategory
              newCategory={newCategoryName}
              onCategoryChange={setNewTemplateCategoryAc}
              onCreate={createAc}
            />
          ),
          footer: {
            onPrimary: createAc,
            onSecondary: this.actionCreators.closePopup,
            primaryDisabled: !newCategoryName,
            primaryTextId: 'common.create',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.createNewCategory',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TemplatePopupIds.deleteCategory: {
        const {
          popupData: { categoryId, templatesCount },
          popupLoading,
        } = this.props;
        let message = '';

        if (!popupLoading) {
          message = templatesCount
            ? formatMessage({ id: 'web.templates.deleteCategoryConfirm' })
            : formatMessage({ id: 'web.templates.deleteCategoryConfirmEmpty' });
        }

        return {
          children: (
            <div className="margin-top-full margin-bottom-full">{message}</div>
          ),
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.templates.deleteTemplateCategory',
          },
          footer: {
            onPrimary: !templatesCount
              ? bind(
                  this.actionCreators.deleteCategoryConfirm,
                  this,
                  categoryId
                )
              : this.actionCreators.closePopup,
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: !templatesCount ? 'common.delete' : 'common.ok',
            secondaryTextId: 'common.cancel',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TemplatePopupIds.confirmDrop: {
        const {
          popupData: { ids, selectedCategory, callback: onSuccess },
        } = this.props;

        return {
          children: (
            <div className="margin-top-full margin-bottom-full">
              {formatMessage({ id: 'web.templates.cofirmationPopupMessage' })}
            </div>
          ),
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.templates.cofirmationPopupTitle',
          },
          footer: {
            onPrimary: bind(
              this.actionCreators.bulkMoveTemplate,
              this,
              ids,
              selectedCategory,
              onSuccess
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.move',
            secondaryTextId: 'common.cancel',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      default:
        return null;
    }
  };
}

TemplatesPopupContainer.propTypes = {
  templatesSelectedTemplateCategoryId: PropTypes.number.isRequired,
  createTemplateState: PropTypes.object.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  popupAlert: PropTypes.string.isRequired,
  popupLoading: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  popupId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  popupAlertTextValues: PropTypes.object,
  newCategoryName: PropTypes.string,
  popupData: PropTypes.object,
};

TemplatesPopupContainer.defaultProps = {
  popupAlertTextValues: {},
  newCategoryName: '',
  popupData: {},
};

const mapStateToProps = (state) => ({
  createTemplateState: state.templatesCreateTemplateState,
  popupId: state.popup,
  popupAlert: state.popupAlert,
  popupAlertTextValues: state.popupAlertTextValues,
  popupData: state.popupData,
  popupLoading: state.popupLoading,
  categories: getUpdatableCategories(state),
  templatesSelectedTemplateCategoryId:
    state.templatesSelectedTemplateCategoryId,
  selectedCategory: state.templatesPopupSelectedCategory,
  newCategoryName: state.templatesNewCategoryName,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(TemplatesPopupContainer);
