import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import { parseCategories, parseTeams } from '../helpers/sharingSettingsParsers';

const getCategoriesState = (state) => state.settingsSharingCategories;
const getTeamsState = (state) => state.settingsSharingTeams;

export const getCategories = createSelector(
  [getCategoriesState],
  (categories) => {
    const parsedCategories = parseCategories(categories);
    return (
      (parsedCategories.length &&
        sortBy(parsedCategories, ({ label }) => label.toLowerCase())) ||
      []
    );
  }
);

export const getTeams = createSelector([getTeamsState], (teams) =>
  parseTeams(teams)
);
