import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BlockTypeItems } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import Radio from 'components/buttons/radio';
import Select from 'components/selects/default';
import TextArea from 'components/inputs/textarea';

class UnsubscribeForm extends Component {
  render() {
    const {
      actionCreators: {
        changeBlockTypeSelected,
        changeReasonSelected,
        changeOtherReason,
        unsubscribeManual,
      },
      fetching,
      unsubscribeBlockTypeSelected,
      unsubscribeReasons,
      unsubscribeReasonsSelected,
    } = this.props;

    const validate = this.validate();
    const saveButtonDisabled = !validate || fetching;

    return (
      <div className="unsubscribe-form">
        <h3>
          <FormattedMessage id="common.unsubscribe" />
        </h3>
        <p>
          <FormattedMessage id="web.settings.unsubscribe.select.email" />
        </p>
        <div className="margin-bottom-full">
          {BlockTypeItems.map((item) => {
            const { label, value } = item;

            return (
              <Radio
                key={value}
                label={label}
                value={value}
                name="unsubscribe-block-type"
                onChange={changeBlockTypeSelected}
                checked={value === unsubscribeBlockTypeSelected}
              />
            );
          })}
        </div>
        <Select
          items={unsubscribeReasons}
          onChange={changeReasonSelected}
          selected={unsubscribeReasonsSelected}
          propertyLabel="label"
          propertyValue="identifier"
          className="margin-bottom-full"
        />
        {unsubscribeReasonsSelected === 'other' && (
          <TextArea onChange={changeOtherReason} />
        )}
        <Button
          color={ButtonColors.blue}
          disabled={saveButtonDisabled}
          onClick={unsubscribeManual}
          size={ButtonSizes.standard}
        >
          <FormattedMessage id="common.save" />
        </Button>
      </div>
    );
  }

  validate() {
    const { unsubscribeBlockTypeSelected } = this.props;

    return unsubscribeBlockTypeSelected !== null && this.validateReason();
  }

  validateReason() {
    const { unsubscribeReasonsSelected, unsubscribeOtherReason } = this.props;

    if (unsubscribeReasonsSelected === 'default') {
      return false;
    } else if (unsubscribeReasonsSelected === 'other') {
      return unsubscribeOtherReason !== '';
    } else {
      return true;
    }
  }
}

UnsubscribeForm.defaultProps = {
  unsubscribeBlockTypeSelected: null,
  unsubscribeOtherReason: '',
  unsubscribeReasonsSelected: 'default',
};

UnsubscribeForm.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  unsubscribeBlockTypeSelected: PropTypes.string,
  unsubscribeOtherReason: PropTypes.string,
  unsubscribeReasons: PropTypes.array.isRequired,
  unsubscribeReasonsSelected: PropTypes.string,
};

export default UnsubscribeForm;
