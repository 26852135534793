import {
  closePopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  bulkDone,
  bulkSkip,
  bulkSuccess /*, completingBulkDelete*/,
  completingBulkDone,
  completingBulkSkip,
  completingBulkSuccess,
} from 'web/tasks/actionCreators/tasksActionCreators';
import { resetCompleted } from 'web/tasks/actionCreators/tasksCompletedActionCreators';
import { initializeBulkUpdate } from 'web/bulkUpdate/actionCreators/bulkUpdateProgressActionCreators';
import {
  trackBulkAction,
  trackComplete,
} from 'web/tables/tasks/services/tasksTableMixpanel';
import { TaskProperties, TablesTasksBulkTypes } from 'web/libs/mixpanelEvents';
import { TasksTableAlertIds } from 'web/tables/tasks/libs/tablesTasksConstants';

/* bulk delete is waiting on backend updates */
// export const bulkUpdateDelete = (successes) => (dispatch) => {
//   dispatch(completingBulkDelete(successes));
//   dispatch(resetCompleted());
// };

export const bulkUpdateDone = (successes) => (dispatch) => {
  dispatch(completingBulkDone(successes));
  dispatch(resetCompleted());
};

export const bulkUpdateSkip = (successes) => (dispatch) => {
  dispatch(completingBulkSkip(successes));
  dispatch(resetCompleted());
};

export const bulkUpdateSuccess = (successes) => (dispatch) => {
  dispatch(completingBulkSuccess(successes));
  dispatch(resetCompleted());
};

const getBulkUpdaterParams = (bulkActionTaken) => {
  switch (bulkActionTaken) {
    // case TablesTasksBulkTypes.delete:
    //   return { modalTitle: 'web.tables.tasks.deletingTitle', updater: bulkUpdateDelete };
    case TablesTasksBulkTypes.done:
      return {
        modalTitle: 'web.tables.tasks.markingDoneTitle',
        updater: bulkUpdateDone,
      };
    case TablesTasksBulkTypes.skip:
      return {
        modalTitle: 'web.tables.tasks.skippingTitle',
        updater: bulkUpdateSkip,
      };
    case TablesTasksBulkTypes.success:
      return {
        modalTitle: 'web.tables.tasks.markingSuccessTitle',
        updater: bulkUpdateSuccess,
      };
    default:
      return { modalTitle: '', updater: () => {} };
  }
};

const initializeBulkUpdater = (bulkActionTaken, jobId, clearSelectedIds) => (
  dispatch
) => {
  const { modalTitle, updater } = getBulkUpdaterParams(bulkActionTaken);
  dispatch(initializeBulkUpdate(jobId, updater, modalTitle, clearSelectedIds));
};

export function handleBulkUpdate(
  { isAsync = false, job_id: jobId },
  bulkActionTaken,
  callback
) {
  return (dispatch) => {
    if (isAsync) {
      dispatch(initializeBulkUpdater(bulkActionTaken, jobId, callback));
    } else {
      callback();
      dispatch(closePopup());
    }
  };
}

const blankFunc = () => {};

// waiting on backend to do bulk deletes
// export function tasksBulkDelete (tableId, ids = [], callback = blankFunc) {
//   return (dispatch) => {
//     if (ids.length) {
//       trackComplete(tableId, TaskProperties.actionType.delete, true);
//       trackBulkAction(tableId, TablesTasksBulkTypes.delete, ids.length);
//       dispatch(setPopupLoading(true));

//       dispatch(bulkDelete(ids))
//         .then(() => {
//           dispatch(closePopup());
//           callback();
//         })
// .catch(() => {
//   dispatch(setAlert(TasksTableAlertIds.bulkFail));
// });
//     }
//   };
// }

export function tasksBulkDone(tableId, ids = [], callback = blankFunc) {
  return (dispatch) => {
    if (ids.length) {
      dispatch(setPopupLoading(true));
      trackComplete(tableId, TaskProperties.actionType.complete, true);
      trackBulkAction(tableId, TablesTasksBulkTypes.done, ids.length);

      dispatch(bulkDone(ids))
        .then((data = {}) => {
          dispatch(handleBulkUpdate(data, TablesTasksBulkTypes.done, callback));
        })
        .catch(() => {
          dispatch(setPopupAlert(TasksTableAlertIds.bulkFail));
        });
    }
  };
}

export function tasksBulkSkip(tableId, ids = [], callback = blankFunc) {
  return (dispatch) => {
    if (ids.length) {
      dispatch(setPopupLoading(true));
      trackComplete(tableId, TaskProperties.actionType.skip, true);
      trackBulkAction(tableId, TablesTasksBulkTypes.skip, ids.length);

      dispatch(bulkSkip(ids))
        .then((data = {}) => {
          dispatch(handleBulkUpdate(data, TablesTasksBulkTypes.skip, callback));
        })
        .catch(() => {
          dispatch(setPopupAlert(TasksTableAlertIds.bulkFail));
        });
    }
  };
}

/* lets pusher update workflow_details */
export function tasksBulkSuccess(tableId, ids = [], callback = blankFunc) {
  return (dispatch) => {
    if (ids.length) {
      dispatch(setPopupLoading(true));
      trackComplete(tableId, TaskProperties.actionType.success, true);
      trackBulkAction(tableId, TablesTasksBulkTypes.success, ids.length);

      dispatch(bulkSuccess(ids))
        .then((data = {}) => {
          dispatch(
            handleBulkUpdate(data, TablesTasksBulkTypes.success, callback)
          );
        })
        .catch(() => {
          dispatch(setPopupAlert(TasksTableAlertIds.bulkFail));
        });
    }
  };
}
