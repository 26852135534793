import React from 'react';
import PropTypes from 'prop-types';
import ComposeHeaderBar from 'web/composeWindow/components/composeHeaderBar';
import HoverTooltip, {
  HoverTooltipPlace,
} from 'components/hoverTooltip/hoverTooltip';
import Icon from 'components/buttons/icon';
import { PitchStates } from 'web/composeWindow/libs/composeWindowConstants';
import { FormattedMessage } from 'react-intl';
import './composeEmailHeaderBar.scss';

function ComposeEmailHeaderBar({
  closeComposeWindow,
  composeBulkViewOpened,
  composeWindowMinimized,
  modalMode,
  pitchState,
  setMinimizeView,
  subject,
}) {
  function handleMinimizeChange() {
    setMinimizeView({
      bulkViewOpened: composeBulkViewOpened,
      minimized: !composeWindowMinimized,
    });
  }

  function getSubjectHeader() {
    if (subject.length > 0) {
      return subject;
    } else {
      return <FormattedMessage id="web.composeWindow.headerTitle" />;
    }
  }

  const minOrMaxClass = composeWindowMinimized ? 'maximize' : 'minimize';
  const closable = !modalMode;
  const minimizable = !modalMode;

  return (
    <ComposeHeaderBar
      className="compose-window-headerbar-wrapper"
      closable={closable}
      closeWindow={closeComposeWindow}
      handleMinimizeChange={handleMinimizeChange}
      minOrMaxClass={minOrMaxClass}
      minimizable={minimizable}
    >
      <div className="state-detail">
        <span className="subject-header">{getSubjectHeader()}</span>
        <span className="state-label">
          <FormattedMessage id={`web.composeWindow.pitch.${pitchState}`} />
          {pitchState === PitchStates.unsaved && (
            <HoverTooltip
              className="state-tooltip"
              place={HoverTooltipPlace.bottom}
              textId="web.composeWindow.pitch.unsaved.tooltip"
            >
              <Icon classes="tout-icon-warning" />
            </HoverTooltip>
          )}
        </span>
      </div>
    </ComposeHeaderBar>
  );
}

ComposeEmailHeaderBar.propTypes = {
  closeComposeWindow: PropTypes.func.isRequired,
  composeBulkViewOpened: PropTypes.bool.isRequired,
  composeWindowMinimized: PropTypes.bool.isRequired,
  modalMode: PropTypes.bool.isRequired,
  pitchState: PropTypes.string.isRequired,
  setMinimizeView: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired,
};

export default ComposeEmailHeaderBar;
