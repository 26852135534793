import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import { FormattedHTMLMessage } from 'react-intl';
import './cardHighlight.scss';

export default class CardHighlight extends Component {
  render() {
    const {
      textId,
      className,
      backgroundColor,
      values,
      iconType,
      preformattedBlock,
    } = this.props;

    const highlightClassName = classNames('card-highlight', className, {
      [`background-${backgroundColor}`]: backgroundColor,
    });

    // To support more icon types, just add an appropriate tout-icon-* class in the cardHighlight.scss
    const iconClassName = classNames('highlight-icon', 'margin-right-full', {
      [`tout-icon-${iconType}`]: iconType,
    });

    return (
      <div className={highlightClassName}>
        <i className={iconClassName} />
        {preformattedBlock || (
          <FormattedHTMLMessage id={textId} values={values} />
        )}
      </div>
    );
  }
}

CardHighlight.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  iconType: PropTypes.string,
  preformattedBlock: PropTypes.object,
  textId: PropTypes.string,
  values: PropTypes.object,
};

CardHighlight.defaultProps = {
  backgroundColor: BackgroundColors.blueGrayLight,
  className: '',
  iconType: 'warning',
  preformattedBlock: null,
  textId: '',
  values: {},
};
