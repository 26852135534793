/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
export const ACTION_INITIALIZE_HEALTH_STATUSES =
  'your-integrations/initialize-health-statuses';
export const ACTION_HEALTH_STATUSES_LOADED =
  'your-integrations/health-statuses-loaded';
export const ACTION_HEALTH_STATUSES_LOAD_FAILED =
  'your-integrations/health-statuses-load-failed';

export const SET_FETCHING = 'SET_YOUR_INTEGRATIONS_STATUSES_FETCHING';

export const ICONS = {
  POSITIVE: 'icon-success',
  NEGATIVE: 'icon-broken',
  NEUTRAL: 'icon-not-set',
};

export const MESSAGES = {
  MARKETO_CONNECTION_INFO_ADMIN:
    'web.settings.myProfile.yourIntegrations.marketoInfoAdmin',
  MARKETO_CONNECTION_INFO_NON_ADMIN:
    'web.settings.myProfile.yourIntegrations.marketoInfoNonAdmin',
  ADMIN_CONNECTED_MARKETO:
    'web.settings.myProfile.yourIntegrations.adminConnectedMarketo',
  ADMIN_GRANTED_MARKETO:
    'web.settings.myProfile.yourIntegrations.adminGrantedMarketo',
  ADMIN_NOT_CONNECTED_MARKETO:
    'web.settings.myProfile.yourIntegrations.adminNotConnectedMarketo',
  ADMIN_FIX_MARKETO: 'web.settings.myProfile.yourIntegrations.adminFixMarketo',
  CONNECTED_MARKETO:
    'web.settings.myProfile.yourIntegrations.connected_marketo',
  FIX_MARKETO: 'web.settings.myProfile.yourIntegrations.fixMarketo',
  NOT_CONNECTED_MARKETO:
    'web.settings.myProfile.yourIntegrations.notConnectedMarketo',
  CONNECTED_SALESFORCE:
    'web.settings.myProfile.yourIntegrations.connected_salesforce',
  NOT_CONNECTED_SALESFORCE:
    'web.settings.myProfile.yourIntegrations.notConnectedSalesforce',
  FIX_SALESFORCE: 'web.settings.myProfile.yourIntegrations.fixSalesforce',
  NOT_SETUP_REPLY_TRACKING:
    'web.settings.myProfile.yourIntegrations.notSetupReplyTracking',
  FIX_REPLY_TRACKING:
    'web.settings.myProfile.yourIntegrations.fixReplyTracking',
  SETUP_GMAIL_REPLY_TRACKING:
    'web.settings.myProfile.yourIntegrations.setupGmailReplyTracking',
  SETUP_OUTLOOK_REPLY_TRACKING:
    'web.settings.myProfile.yourIntegrations.setupOutlookReplyTracking',
  SETUP_EXCHANGE_REPLY_TRACKING:
    'web.settings.myProfile.yourIntegrations.setupExchangeReplyTracking',
  ADMIN_NOT_SELECTED_DELIVERY_CHANNEL:
    'web.settings.myProfile.yourIntegrations.adminNotSelectedDeliveryChannel',
  NOT_SELECTED_DELIVERY_CHANNEL:
    'web.settings.myProfile.yourIntegrations.notSelectedDeliveryChannel',
  FIX_ADMIN_DELIVERY_CHANNEL:
    'web.settings.myProfile.yourIntegrations.fixAdminDeliveryChannel',
  FIX_DELIVERY_CHANNEL:
    'web.settings.myProfile.yourIntegrations.fixDeliveryChannel',
  EMAIL_SENT_VIA_GMAIL:
    'web.settings.myProfile.yourIntegrations.emailSentViaGmail',
  EMAIL_SENT_VIA_OUTLOOK:
    'web.settings.myProfile.yourIntegrations.emailSentViaOutlook',
  EMAIL_SENT_VIA_CUSTOM_DELIVERY_CHANNEL:
    'web.settings.myProfile.yourIntegrations.emailSentViaCustomDeliveryChannel',
  EMAIL_SENT_VIA_TEAM_SMTP_SERVER:
    'web.settings.myProfile.yourIntegrations.emailSentViaTeamSmtpServer',
  EMAIL_SENT_VIA_TOUTAPP_DELIVERY_CHANNEL:
    'web.settings.myProfile.yourIntegrations.emailSentViaToutappDeliveryChannel',
  EMAIL_SENT_VIA_OUTLOOK_ON_PREM_DELIVERY_CHANNEL:
    'web.settings.myProfile.yourIntegrations.emailSentViaOutlookOnPrem',
};
