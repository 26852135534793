tinymce.addI18n('af', {
  Redo: '\u00e0_Redo_\u6d77',
  Undo: '\u00e0_Undo_\u6d77',
  Cut: '\u00e0_Cut_\u6d77',
  Copy: '\u00e0_Copy_\u6d77',
  Paste: '\u00e0_Paste_\u6d77',
  'Select all': '\u00e0_Select all_\u6d77',
  'New document': '\u00e0_New document_\u6d77',
  Ok: '\u00e0_Ok_\u6d77',
  Cancel: '\u00e0_Cancel_\u6d77',
  'Visual aids': '\u00e0_Visual aids_\u6d77',
  Bold: '\u00e0_Bold_\u6d77',
  Italic: '\u00e0_Italic_\u6d77',
  Underline: '\u00e0_Underline_\u6d77',
  Strikethrough: '\u00e0_Strikethrough_\u6d77',
  Superscript: '\u00e0_Superscript_\u6d77',
  Subscript: '\u00e0_Subscript_\u6d77',
  'Clear formatting': '\u00e0_Clear formatting_\u6d77',
  'Align left': '\u00e0_Align left_\u6d77',
  'Align center': '\u00e0_Align center_\u6d77',
  'Align right': '\u00e0_Align right_\u6d77',
  Justify: '\u00e0_Justify_\u6d77',
  'Bullet list': '\u00e0_Bullet list_\u6d77',
  'Numbered list': '\u00e0_Numbered list_\u6d77',
  'Decrease indent': '\u00e0_Decrease indent_\u6d77',
  'Increase indent': '\u00e0_Increase indent_\u6d77',
  Close: '\u00e0_Close_\u6d77',
  Formats: '\u00e0_Formats_\u6d77',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    "\u00e0_Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead._\u6d77",
  Headers: '\u00e0_Headers_\u6d77',
  'Header 1': '\u00e0_Header 1_\u6d77',
  'Header 2': '\u00e0_Header 2_\u6d77',
  'Header 3': '\u00e0_Header 3_\u6d77',
  'Header 4': '\u00e0_Header 4_\u6d77',
  'Header 5': '\u00e0_Header 5_\u6d77',
  'Header 6': '\u00e0_Header 6_\u6d77',
  Headings: '\u00e0_Headings_\u6d77',
  'Heading 1': '\u00e0_Heading 1_\u6d77',
  'Heading 2': '\u00e0_Heading 2_\u6d77',
  'Heading 3': '\u00e0_Heading 3_\u6d77',
  'Heading 4': '\u00e0_Heading 4_\u6d77',
  'Heading 5': '\u00e0_Heading 5_\u6d77',
  'Heading 6': '\u00e0_Heading 6_\u6d77',
  Preformatted: '\u00e0_Preformatted_\u6d77',
  Div: '\u00e0_Div_\u6d77',
  Pre: '\u00e0_Pre_\u6d77',
  Code: '\u00e0_Code_\u6d77',
  Paragraph: '\u00e0_Paragraph_\u6d77',
  Blockquote: '\u00e0_Blockquote_\u6d77',
  Inline: '\u00e0_Inline_\u6d77',
  Blocks: '\u00e0_Blocks_\u6d77',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    '\u00e0_Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off._\u6d77',
  Fonts: '\u00e0_Fonts_\u6d77',
  'Font Sizes': '\u00e0_Font Sizes_\u6d77',
  Class: '\u00e0_Class_\u6d77',
  'Browse for an image': '\u00e0_Browse for an image_\u6d77',
  OR: '\u00e0_OR_\u6d77',
  'Drop an image here': '\u00e0_Drop an image here_\u6d77',
  Upload: '\u00e0_Upload_\u6d77',
  Block: '\u00e0_Block_\u6d77',
  Align: '\u00e0_Align_\u6d77',
  Default: '\u00e0_Default_\u6d77',
  Circle: '\u00e0_Circle_\u6d77',
  Disc: '\u00e0_Disc_\u6d77',
  Square: '\u00e0_Square_\u6d77',
  'Lower Alpha': '\u00e0_Lower Alpha_\u6d77',
  'Lower Greek': '\u00e0_Lower Greek_\u6d77',
  'Lower Roman': '\u00e0_Lower Roman_\u6d77',
  'Upper Alpha': '\u00e0_Upper Alpha_\u6d77',
  'Upper Roman': '\u00e0_Upper Roman_\u6d77',
  'Anchor...': '\u00e0_Anchor..._\u6d77',
  Name: '\u00e0_Name_\u6d77',
  Id: '\u00e0_Id_\u6d77',
  'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    '\u00e0_Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores._\u6d77',
  'You have unsaved changes are you sure you want to navigate away?':
    '\u00e0_You have unsaved changes are you sure you want to navigate away?_\u6d77',
  'Restore last draft': '\u00e0_Restaurar o \u00faltimo rascunho_\u6d77',
  'Special character...': '\u00e0_Special character..._\u6d77',
  'Source code': '\u00e0_Source code_\u6d77',
  'Insert/Edit code sample': '\u00e0_Insert/Edit code sample_\u6d77',
  Language: '\u00e0_Language_\u6d77',
  'Code sample...': '\u00e0_Code sample..._\u6d77',
  'Color Picker': '\u00e0_Color Picker_\u6d77',
  R: '\u00e0_R_\u6d77',
  G: '\u00e0_G_\u6d77',
  B: '\u00e0_B_\u6d77',
  'Left to right': '\u00e0_Left to right_\u6d77',
  'Right to left': '\u00e0_Right to left_\u6d77',
  'Emoticons...': '\u00e0_Emoticons..._\u6d77',
  'Metadata and Document Properties': '\u00e0_Metadata and Document Properties_\u6d77',
  Title: '\u00e0_Title_\u6d77',
  Keywords: '\u00e0_Keywords_\u6d77',
  Description: '\u00e0_Description_\u6d77',
  Robots: '\u00e0_Robots_\u6d77',
  Author: '\u00e0_Author_\u6d77',
  Encoding: '\u00e0_Encoding_\u6d77',
  Fullscreen: '\u00e0_Fullscreen_\u6d77',
  Action: '\u00e0_Action_\u6d77',
  Shortcut: '\u00e0_Shortcut_\u6d77',
  Help: '\u00e0_Help_\u6d77',
  Address: '\u00e0_Address_\u6d77',
  'Focus to menubar': '\u00e0_Focus to menubar_\u6d77',
  'Focus to toolbar': '\u00e0_Focus to toolbar_\u6d77',
  'Focus to element path': '\u00e0_Focus to element path_\u6d77',
  'Focus to contextual toolbar': '\u00e0_Focus to contextual toolbar_\u6d77',
  'Insert link (if link plugin activated)':
    '\u00e0_Insert link (if link plugin activated)_\u6d77',
  'Save (if save plugin activated)': '\u00e0_Save (if save plugin activated)_\u6d77',
  'Find (if searchreplace plugin activated)':
    '\u00e0_Find (if searchreplace plugin activated)_\u6d77',
  'Plugins installed ({0}):': '\u00e0_Plugins installed ({0}):_\u6d77',
  'Premium plugins:': '\u00e0_Premium plugins:_\u6d77',
  'Learn more...': '\u00e0_Learn more..._\u6d77',
  'You are using {0}': '\u00e0_You are using {0}_\u6d77',
  Plugins: '\u00e0_Plugins_\u6d77',
  'Handy Shortcuts': '\u00e0_Handy Shortcuts_\u6d77',
  'Horizontal line': '\u00e0_Horizontal line_\u6d77',
  'Insert/edit image': '\u00e0_Insert/edit image_\u6d77',
  'Image description': '\u00e0_Image description_\u6d77',
  Source: '\u00e0_Source_\u6d77',
  Dimensions: '\u00e0_Dimensions_\u6d77',
  'Constrain proportions': '\u00e0_Constrain proportions_\u6d77',
  General: '\u00e0_General_\u6d77',
  Advanced: '\u00e0_Advanced_\u6d77',
  Style: '\u00e0_Style_\u6d77',
  'Vertical space': '\u00e0_Vertical space_\u6d77',
  'Horizontal space': '\u00e0_Horizontal space_\u6d77',
  Border: '\u00e0_Border_\u6d77',
  'Insert image': '\u00e0_Insert image_\u6d77',
  'Image...': '\u00e0_Image..._\u6d77',
  'Image list': '\u00e0_Image list_\u6d77',
  'Rotate counterclockwise': '\u00e0_Rotate counterclockwise_\u6d77',
  'Rotate clockwise': '\u00e0_Rotate clockwise_\u6d77',
  'Flip vertically': '\u00e0_Flip vertically_\u6d77',
  'Flip horizontally': '\u00e0_Flip horizontally_\u6d77',
  'Edit image': '\u00e0_Edit image_\u6d77',
  'Image options': '\u00e0_Image options_\u6d77',
  'Zoom in': '\u00e0_Zoom in_\u6d77',
  'Zoom out': '\u00e0_Zoom out_\u6d77',
  Crop: '\u00e0_Crop_\u6d77',
  Resize: '\u00e0_Resize_\u6d77',
  Orientation: '\u00e0_Orientation_\u6d77',
  Brightness: '\u00e0_Brightness_\u6d77',
  Sharpen: '\u00e0_Sharpen_\u6d77',
  Contrast: '\u00e0_Contrast_\u6d77',
  'Color levels': '\u00e0_Color levels_\u6d77',
  Gamma: '\u00e0_Gamma_\u6d77',
  Invert: '\u00e0_Invert_\u6d77',
  Apply: '\u00e0_Apply_\u6d77',
  Back: '\u00e0_Back_\u6d77',
  'Insert date/time': '\u00e0_Insert date/time_\u6d77',
  'Date/time': '\u00e0_Date/time_\u6d77',
  'Insert/Edit Link': '\u00e0_Insert/Edit Link_\u6d77',
  'Insert/edit link': '\u00e0_Insert/edit link_\u6d77',
  'Text to display': '\u00e0_Text to display_\u6d77',
  Url: '\u00e0_Url_\u6d77',
  'Open link in...': '\u00e0_Open link in..._\u6d77',
  'Current window': '\u00e0_Current window_\u6d77',
  None: '\u00e0_None_\u6d77',
  'New window': '\u00e0_New window_\u6d77',
  'Remove link': '\u00e0_Remove link_\u6d77',
  Anchors: '\u00e0_Anchors_\u6d77',
  'Link...': '\u00e0_Link..._\u6d77',
  'Paste or type a link': '\u00e0_Paste or type a link_\u6d77',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    '\u00e0_The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?_\u6d77',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    '\u00e0_The URL you entered seems to be an external link. Do you want to add the required http:// prefix?_\u6d77',
  'Link list': '\u00e0_Link list_\u6d77',
  'Insert video': '\u00e0_Insert video_\u6d77',
  'Insert/edit video': '\u00e0_Insert/edit video_\u6d77',
  'Insert/edit media': '\u00e0_Insert/edit media_\u6d77',
  'Alternative source': '\u00e0_Alternative source_\u6d77',
  'Alternative source URL': '\u00e0_Alternative source URL_\u6d77',
  'Media poster (Image URL)': '\u00e0_Media poster (Image URL)_\u6d77',
  'Paste your embed code below:': '\u00e0_Paste your embed code below:_\u6d77',
  Embed: '\u00e0_Embed_\u6d77',
  'Media...': '\u00e0_Media..._\u6d77',
  'Nonbreaking space': '\u00e0_Nonbreaking space_\u6d77',
  'Page break': '\u00e0_Page break_\u6d77',
  'Paste as text': '\u00e0_Paste as text_\u6d77',
  Preview: '\u00e0_Preview_\u6d77',
  'Print...': '\u00e0_Print..._\u6d77',
  Save: '\u00e0_Save_\u6d77',
  Find: '\u00e0_Find_\u6d77',
  'Replace with': '\u00e0_Replace with_\u6d77',
  Replace: '\u00e0_Replace_\u6d77',
  'Replace all': '\u00e0_Replace all_\u6d77',
  Previous: '\u00e0_Previous_\u6d77',
  Next: '\u00e0_Next_\u6d77',
  'Find and replace...': '\u00e0_Find and replace..._\u6d77',
  'Could not find the specified string.':
    '\u00e0_Could not find the specified string._\u6d77',
  'Match case': '\u00e0_Match case_\u6d77',
  'Find whole words only': '\u00e0_Find whole words only_\u6d77',
  'Spell check': '\u00e0_Spell check_\u6d77',
  Ignore: '\u00e0_Ignore_\u6d77',
  'Ignore all': '\u00e0_Ignore all_\u6d77',
  Finish: '\u00e0_Finish_\u6d77',
  'Add to Dictionary': '\u00e0_Add to Dictionary_\u6d77',
  'Insert table': '\u00e0_Insert table_\u6d77',
  'Table properties': '\u00e0_Table properties_\u6d77',
  'Delete table': '\u00e0_Delete table_\u6d77',
  Cell: '\u00e0_Cell_\u6d77',
  Row: '\u00e0_Row_\u6d77',
  Column: '\u00e0_Column_\u6d77',
  'Cell properties': '\u00e0_Cell properties_\u6d77',
  'Merge cells': '\u00e0_Merge cells_\u6d77',
  'Split cell': '\u00e0_Split cell_\u6d77',
  'Insert row before': '\u00e0_Insert row before_\u6d77',
  'Insert row after': '\u00e0_Insert row after_\u6d77',
  'Delete row': '\u00e0_Delete row_\u6d77',
  'Row properties': '\u00e0_Row properties_\u6d77',
  'Cut row': '\u00e0_Cut row_\u6d77',
  'Copy row': '\u00e0_Copy row_\u6d77',
  'Paste row before': '\u00e0_Paste row before_\u6d77',
  'Paste row after': '\u00e0_Paste row after_\u6d77',
  'Insert column before': '\u00e0_Insert column before_\u6d77',
  'Insert column after': '\u00e0_Insert column after_\u6d77',
  'Delete column': '\u00e0_Delete column_\u6d77',
  Cols: '\u00e0_Cols_\u6d77',
  Rows: '\u00e0_Rows_\u6d77',
  Width: '\u00e0_Width_\u6d77',
  Height: '\u00e0_Height_\u6d77',
  'Cell spacing': '\u00e0_Cell spacing_\u6d77',
  'Cell padding': '\u00e0_Cell padding_\u6d77',
  'Show caption': '\u00e0_Show caption_\u6d77',
  Left: '\u00e0_Left_\u6d77',
  Center: '\u00e0_Center_\u6d77',
  Right: '\u00e0_Right_\u6d77',
  'Cell type': '\u00e0_Cell type_\u6d77',
  Scope: '\u00e0_Scope_\u6d77',
  Alignment: '\u00e0_Alignment_\u6d77',
  'H Align': '\u00e0_H Align_\u6d77',
  'V Align': '\u00e0_V Align_\u6d77',
  Top: '\u00e0_Top_\u6d77',
  Middle: '\u00e0_Middle_\u6d77',
  Bottom: '\u00e0_Bottom_\u6d77',
  'Header cell': '\u00e0_Header cell_\u6d77',
  'Row group': '\u00e0_Row group_\u6d77',
  'Column group': '\u00e0_Column group_\u6d77',
  'Row type': '\u00e0_Row type_\u6d77',
  Header: '\u00e0_Header_\u6d77',
  Body: '\u00e0_Body_\u6d77',
  Footer: '\u00e0_Footer_\u6d77',
  'Border color': '\u00e0_Border color_\u6d77',
  'Insert template...': '\u00e0_Insert template..._\u6d77',
  Templates: '\u00e0_Templates_\u6d77',
  Template: '\u00e0_Template_\u6d77',
  'Text color': '\u00e0_Text color_\u6d77',
  'Background color': '\u00e0_Background color_\u6d77',
  'Custom...': '\u00e0_Custom..._\u6d77',
  'Custom color': '\u00e0_Custom color_\u6d77',
  'No color': '\u00e0_No color_\u6d77',
  'Remove color': '\u00e0_Remove color_\u6d77',
  'Table of Contents': '\u00e0_Table of Contents_\u6d77',
  'Show blocks': '\u00e0_Show blocks_\u6d77',
  'Show invisible characters': '\u00e0_Show invisible characters_\u6d77',
  'Word count': '\u00e0_Word count_\u6d77',
  Count: '\u00e0_Count_\u6d77',
  Document: '\u00e0_Document_\u6d77',
  Selection: '\u00e0_Selection_\u6d77',
  Words: '\u00e0_Words_\u6d77',
  'Words: {0}': '\u00e0_Words: {0}_\u6d77',
  '{0} words': '\u00e0_{0} words_\u6d77',
  File: '\u00e0_File_\u6d77',
  Edit: '\u00e0_Edit_\u6d77',
  Insert: '\u00e0_Insert_\u6d77',
  View: '\u00e0_View_\u6d77',
  Format: '\u00e0_Format_\u6d77',
  Table: '\u00e0_Table_\u6d77',
  Tools: '\u00e0_Tools_\u6d77',
  'Powered by {0}': '\u00e0_Powered by {0}_\u6d77',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    '\u00e0_Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help_\u6d77',
  'Image title': '\u00e0_Image title_\u6d77',
  'Border width': '\u00e0_Border width_\u6d77',
  'Border style': '\u00e0_Border style_\u6d77',
  Error: '\u00e0_Error_\u6d77',
  Warn: '\u00e0_Warn_\u6d77',
  Valid: '\u00e0_Valid_\u6d77',
  'To open the popup, press Shift+Enter':
    '\u00e0_To open the popup, press Shift+Enter_\u6d77',
  'Rich Text Area. Press ALT-0 for help.':
    '\u00e0_Rich Text Area. Press ALT-0 for help._\u6d77',
  'System Font': '\u00e0_System Font_\u6d77',
  'Failed to upload image: {0}': '\u00e0_Failed to upload image: {0}_\u6d77',
  'Failed to load plugin: {0} from url {1}':
    '\u00e0_Failed to load plugin: {0} from url {1}_\u6d77',
  'Failed to load plugin url: {0}': '\u00e0_Failed to load plugin url: {0}_\u6d77',
  'Failed to initialize plugin: {0}': '\u00e0_Failed to initialize plugin: {0}_\u6d77',
  example: '\u00e0_example_\u6d77',
  Search: '\u00e0_Search_\u6d77',
  All: '\u00e0_All_\u6d77',
  Currency: '\u00e0_Currency_\u6d77',
  Text: '\u00e0_Text_\u6d77',
  Quotations: '\u00e0_Quotations_\u6d77',
  Mathematical: '\u00e0_Mathematical_\u6d77',
  'Extended Latin': '\u00e0_Extended Latin_\u6d77',
  Symbols: '\u00e0_Symbols_\u6d77',
  Arrows: '\u00e0_Arrows_\u6d77',
  'User Defined': '\u00e0_User Defined_\u6d77',
  'dollar sign': '\u00e0_dollar sign_\u6d77',
  'currency sign': '\u00e0_currency sign_\u6d77',
  'euro-currency sign': '\u00e0_euro-currency sign_\u6d77',
  'colon sign': '\u00e0_colon sign_\u6d77',
  'cruzeiro sign': '\u00e0_cruzeiro sign_\u6d77',
  'french franc sign': '\u00e0_french franc sign_\u6d77',
  'lira sign': '\u00e0_lira sign_\u6d77',
  'mill sign': '\u00e0_mill sign_\u6d77',
  'naira sign': '\u00e0_naira sign_\u6d77',
  'peseta sign': '\u00e0_peseta sign_\u6d77',
  'rupee sign': '\u00e0_rupee sign_\u6d77',
  'won sign': '\u00e0_won sign_\u6d77',
  'new sheqel sign': '\u00e0_new sheqel sign_\u6d77',
  'dong sign': '\u00e0_dong sign_\u6d77',
  'kip sign': '\u00e0_kip sign_\u6d77',
  'tugrik sign': '\u00e0_tugrik sign_\u6d77',
  'drachma sign': '\u00e0_drachma sign_\u6d77',
  'german penny symbol': '\u00e0_german penny symbol_\u6d77',
  'peso sign': '\u00e0_peso sign_\u6d77',
  'guarani sign': '\u00e0_guarani sign_\u6d77',
  'austral sign': '\u00e0_austral sign_\u6d77',
  'hryvnia sign': '\u00e0_hryvnia sign_\u6d77',
  'cedi sign': '\u00e0_cedi sign_\u6d77',
  'livre tournois sign': '\u00e0_livre tournois sign_\u6d77',
  'spesmilo sign': '\u00e0_spesmilo sign_\u6d77',
  'tenge sign': '\u00e0_tenge sign_\u6d77',
  'indian rupee sign': '\u00e0_indian rupee sign_\u6d77',
  'turkish lira sign': '\u00e0_turkish lira sign_\u6d77',
  'nordic mark sign': '\u00e0_nordic mark sign_\u6d77',
  'manat sign': '\u00e0_manat sign_\u6d77',
  'ruble sign': '\u00e0_ruble sign_\u6d77',
  'yen character': '\u00e0_yen character_\u6d77',
  'yuan character': '\u00e0_yuan character_\u6d77',
  'yuan character, in hong kong and taiwan':
    '\u00e0_yuan character, in hong kong and taiwan_\u6d77',
  'yen/yuan character variant one': '\u00e0_yen/yuan character variant one_\u6d77',
  'Loading emoticons...': '\u00e0_Loading emoticons..._\u6d77',
  'Could not load emoticons': '\u00e0_Could not load emoticons_\u6d77',
  People: '\u00e0_People_\u6d77',
  'Animals and Nature': '\u00e0_Animals and Nature_\u6d77',
  'Food and Drink': '\u00e0_Food and Drink_\u6d77',
  Activity: '\u00e0_Activity_\u6d77',
  'Travel and Places': '\u00e0_Travel and Places_\u6d77',
  Objects: '\u00e0_Objects_\u6d77',
  Flags: '\u00e0_Flags_\u6d77',
  Characters: '\u00e0_Characters_\u6d77',
  'Characters (no spaces)': '\u00e0_Characters (no spaces)_\u6d77',
  '{0} characters': '\u00e0_{0} characters_\u6d77',
  'Error: Form submit field collision.':
    '\u00e0_Error: Form submit field collision._\u6d77',
  'Error: No form element found.': '\u00e0_Error: No form element found._\u6d77',
  Update: '\u00e0_Update_\u6d77',
  'Color swatch': '\u00e0_Color swatch_\u6d77',
  Turquoise: '\u00e0_Turquoise_\u6d77',
  Green: '\u00e0_Green_\u6d77',
  Blue: '\u00e0_Blue_\u6d77',
  Purple: '\u00e0_Purple_\u6d77',
  'Navy Blue': '\u00e0_Navy Blue_\u6d77',
  'Dark Turquoise': '\u00e0_Dark Turquoise_\u6d77',
  'Dark Green': '\u00e0_Dark Green_\u6d77',
  'Medium Blue': '\u00e0_Medium Blue_\u6d77',
  'Medium Purple': '\u00e0_Medium Purple_\u6d77',
  'Midnight Blue': '\u00e0_Midnight Blue_\u6d77',
  Yellow: '\u00e0_Yellow_\u6d77',
  Orange: '\u00e0_Orange_\u6d77',
  Red: '\u00e0_Red_\u6d77',
  'Light Gray': '\u00e0_Light Gray_\u6d77',
  Gray: '\u00e0_Gray_\u6d77',
  'Dark Yellow': '\u00e0_Dark Yellow_\u6d77',
  'Dark Orange': '\u00e0_Dark Orange_\u6d77',
  'Dark Red': '\u00e0_Dark Red_\u6d77',
  'Medium Gray': '\u00e0_Medium Gray_\u6d77',
  'Dark Gray': '\u00e0_Dark Gray_\u6d77',
  'Light Green': '\u00e0_Light Green_\u6d77',
  'Light Yellow': '\u00e0_Light Yellow_\u6d77',
  'Light Red': '\u00e0_Light Red_\u6d77',
  'Light Purple': '\u00e0_Light Purple_\u6d77',
  'Light Blue': '\u00e0_Light Blue_\u6d77',
  'Dark Purple': '\u00e0_Dark Purple_\u6d77',
  'Dark Blue': '\u00e0_Dark Blue_\u6d77',
  Black: '\u00e0_Black_\u6d77',
  White: '\u00e0_White_\u6d77',
  'Switch to or from fullscreen mode': '\u00e0_Switch to or from fullscreen mode_\u6d77',
  'Open help dialog': '\u00e0_Open help dialog_\u6d77',
  history: '\u00e0_history_\u6d77',
  styles: '\u00e0_styles_\u6d77',
  formatting: '\u00e0_formatting_\u6d77',
  alignment: '\u00e0_alignment_\u6d77',
  indentation: '\u00e0_indentation_\u6d77',
  'permanent pen': '\u00e0_permanent pen_\u6d77',
  comments: '\u00e0_comments_\u6d77',
  'Format Painter': '\u00e0_Format Painter_\u6d77',
  'Insert/edit iframe': '\u00e0_Insert/edit iframe_\u6d77',
  Capitalization: '\u00e0_Capitalization_\u6d77',
  lowercase: '\u00e0_lowercase_\u6d77',
  UPPERCASE: '\u00e0_UPPERCASE_\u6d77',
  'Title Case': '\u00e0_Title Case_\u6d77',
  'Permanent Pen Properties': '\u00e0_Permanent Pen Properties_\u6d77',
  'Permanent pen properties...': '\u00e0_Permanent pen properties..._\u6d77',
  Font: '\u00e0_Font_\u6d77',
  Size: '\u00e0_Size_\u6d77',
  'More...': '\u00e0_More..._\u6d77',
  'Spellcheck Language': '\u00e0_Spellcheck Language_\u6d77',
  'Select...': '\u00e0_Select..._\u6d77',
  Preferences: '\u00e0_Preferences_\u6d77',
  Yes: '\u00e0_Yes_\u6d77',
  No: '\u00e0_No_\u6d77',
  'Keyboard Navigation': '\u00e0_Keyboard Navigation_\u6d77',
  Version: '\u00e0_Version_\u6d77',
  Anchor: '\u00e0_Anchor_\u6d77',
  'Special character': '\u00e0_Special character_\u6d77',
  'Code sample': '\u00e0_Code sample_\u6d77',
  Color: '\u00e0_Color_\u6d77',
  Emoticons: '\u00e0_Emoticons_\u6d77',
  'Document properties': '\u00e0_Document properties_\u6d77',
  Image: '\u00e0_Image_\u6d77',
  'Insert link': '\u00e0_Insert link_\u6d77',
  Target: '\u00e0_Target_\u6d77',
  Link: '\u00e0_Link_\u6d77',
  Poster: '\u00e0_Poster_\u6d77',
  Media: '\u00e0_Media_\u6d77',
  Print: '\u00e0_Print_\u6d77',
  Prev: '\u00e0_Prev_\u6d77',
  'Find and replace': '\u00e0_Find and replace_\u6d77',
  'Whole words': '\u00e0_Whole words_\u6d77',
  Spellcheck: '\u00e0_Spellcheck_\u6d77',
  Caption: '\u00e0_Caption_\u6d77',
  'Insert template': '\u00e0_Insert template_\u6d77',
  'Open link': '\u00e0_Open link_\u6d77',
  Solid: '\u00e0_Solid_\u6d77',
  Dotted: '\u00e0_Dotted_\u6d77',
  Dashed: '\u00e0_Dashed_\u6d77',
  Double: '\u00e0_Double_\u6d77',
  Groove: '\u00e0_Groove_\u6d77',
  Ridge: '\u00e0_Ridge_\u6d77',
  Inset: '\u00e0_Inset_\u6d77',
  Outset: '\u00e0_Outset_\u6d77',
  Hidden: '\u00e0_Hidden_\u6d77',
});
