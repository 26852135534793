import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AuthorizationTypes } from 'web/compliance/libs/complianceConstants';

const AuthorizationTypeText = ({ authorizationType }) => {
  switch (authorizationType) {
    case AuthorizationTypes.legitimateInterest:
      return <FormattedMessage id="web.people.compliance.legitimateInterest" />;
    case AuthorizationTypes.consent:
      return <FormattedMessage id="web.people.compliance.consent" />;
    case AuthorizationTypes.performanceOfAContract:
      return (
        <FormattedMessage id="web.people.compliance.performanceOfAContract" />
      );
    case AuthorizationTypes.complianceWithLegalObligation:
      return (
        <FormattedMessage id="web.people.compliance.complianceWithLegalObligation" />
      );
    case AuthorizationTypes.protectionOfVitalInterests:
      return (
        <FormattedMessage id="web.people.compliance.protectionOfVitalInterests" />
      );
    case AuthorizationTypes.publicInterest:
      return <FormattedMessage id="web.people.compliance.publicInterest" />;
    case AuthorizationTypes.other:
      return <FormattedMessage id="common.other" />;
    default:
      return authorizationType;
  }
};

AuthorizationTypeText.propTypes = {
  authorizationType: PropTypes.string,
};

AuthorizationTypeText.defaultProps = {
  authorizationType: '',
};

export default AuthorizationTypeText;
