import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import I18N from 'languages';
import moment from 'moment';
import Icon from 'components/buttons/icon';
import { ColorClasses } from 'libs/constantsStyles';
import classNames from 'classnames';
import ToutTable from 'table/modules/toutTable';
import { Colors, Aligns } from 'libs/constantsShared';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import TextCell from 'table/modules/cells/textCell';
import SelectCell from 'table/modules/cells/selectCell';
import MenuCell from 'table/modules/cells/menuCell';
import { alphabetical } from 'table/modules/sorting';
import { getImageUrl } from 'web/libs/constants';
import {
  UsersTableColumns,
  UserRoles,
  EMAIL_CONNECTION_VALUE_LABEL_MAP,
} from 'web/settings/adminSettings/userManagement/libs/usersConstants';
import { usersTableExternals } from '../../helpers/usersHelpers';
import { tablePerPageFromStorage } from 'web/services/storageService';
import './usersTable.scss';

const CURRENT_PAGE = 1;
const ROW_HEIGHT = 70;
const TABLE_ID = 'users-table';

const crmIconCellProxyComponent = (params) => {
  const {
    rowData: { salesforce_connected },
  } = params;

  return (
    <Icon
      classes={classNames('crm-connection-icon', {
        connected: salesforce_connected,
        disconnected: !salesforce_connected,
      })}
      color={ColorClasses.darkGrayLight}
      disabled={!salesforce_connected}
    />
  );
};

const emailСonnectionCellProxyComponent = (params) => {
  const {
    rowData: { email_connection_connected },
  } = params;

  return (
    <div
      className={classNames('email-connection-state', {
        'not-connected': !email_connection_connected,
      })}
    >
      {EMAIL_CONNECTION_VALUE_LABEL_MAP[email_connection_connected] || (
        <FormattedMessage id="web.settings.adminSettings.teamManagement.NotConnected" />
      )}
    </div>
  );
};

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.bulkActions = this.getBulkActions();
    this.pagination = this.getPagination();
    this.search = this.getSearch();
    this.zeroState = this._getZeroState();
  }

  render() {
    const {
      actionCreators,
      fetching,
      loading,
      users,
      columnSettings,
    } = this.props;

    const columns = this.getColumns();

    return (
      <div className="users-table">
        <ToutTable
          animateRow={false}
          bulkActions={this.bulkActions}
          changeColumnsSettingsHandler={actionCreators.changeColumnsSettings}
          columnSettings={columnSettings}
          columns={columns}
          defaultSortingColumn={UsersTableColumns.name}
          fetching={fetching}
          items={users}
          loading={loading}
          loadingGetUrl={getImageUrl}
          pagination={this.pagination}
          row={{ height: ROW_HEIGHT }}
          search={this.search}
          setTableExternals={this.setTableExternals}
          tableId={TABLE_ID}
          zeroState={this.zeroState}
          allItems
          columnFilter
          isResize
          reorderColumnsEnable
          selectable
        />
      </div>
    );
  }

  getBulkActions() {
    const {
      actionCreators: { openDeleteUsersConfirmationPopup },
      intl: { formatMessage },
    } = this.props;

    return {
      actions: [
        {
          color: Colors.red,
          hideCount: true,
          label: formatMessage({
            id:
              'web.settings.adminSettings.userManagement.users.bulksActions.actionDelete',
          }),
          onClick: openDeleteUsersConfirmationPopup,
        },
      ],
    };
  }

  getColumns() {
    const {
      actionCreators: { openDeleteUserConfirmationPopup },
      intl: { formatMessage },
      showConnectionStatusColumns,
    } = this.props;

    let tableColumns = [
      {
        align: Aligns.left,
        id: UsersTableColumns.name,
        name: formatMessage({ id: 'common.name' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'name',
          },
        },
        sorting: alphabetical('name'),
        width: 200,
      },
      {
        align: Aligns.left,
        id: UsersTableColumns.email,
        name: formatMessage({ id: 'common.email' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'email',
          },
        },
        sorting: alphabetical('email'),
        width: 250,
      },
      {
        align: Aligns.center,
        id: UsersTableColumns.role,
        name: formatMessage({
          id: 'common.role',
        }),
        rowCell: {
          component: SelectCell,
          metadata: {
            disabled: this.isChangeRoleDisabled,
            disabledTooltipTextId:
              'web.settings.adminSettings.teamManagement.teamMembers.adminStatusTooltip',
            onChange: this.onChangeRole,
            selectItems: [
              {
                label: formatMessage({
                  id: 'common.admin',
                }),
                value: UserRoles.admin,
              },
              {
                label: formatMessage({
                  id: 'common.user',
                }),
                value: UserRoles.user,
              },
            ],
            selectedValue: this.getRole,
          },
        },
        tooltip:
          'web.settings.adminSettings.userManagement.users.roles.description',
        width: 200,
      },
      {
        align: Aligns.center,
        id: UsersTableColumns.createdAt,
        name: formatMessage({
          id: 'web.settings.adminSettings.teamManagement.createdAt',
        }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) =>
              moment(data.created_at).format(I18N.DateFormats.DATE_SHORT),
          },
        },
        sorting: alphabetical('created_at'),
        width: 200,
      },
      {
        align: Aligns.center,
        id: UsersTableColumns.actions,
        name: formatMessage({
          id: 'common.actions',
        }),
        rowCell: {
          component: MenuCell,
          metadata: {
            menuItems: [
              {
                action: openDeleteUserConfirmationPopup,
                disabled: this.isDeleteUserDisabled,
                label: formatMessage({
                  id:
                    'web.settings.adminSettings.userManagement.users.delete.user',
                }),
              },
            ],
          },
        },
        width: 100,
      },
    ];

    if (showConnectionStatusColumns) {
      tableColumns.splice(
        tableColumns.length - 1,
        0,
        {
          align: Aligns.center,
          id: UsersTableColumns.crm,
          name: formatMessage({
            id: 'web.settings.adminSettings.teamManagement.crm',
          }),
          rowCell: {
            component: crmIconCellProxyComponent,
          },
          sorting: (rowData) => +rowData.salesforce_connected,
          width: 100,
        },
        {
          align: Aligns.center,
          id: UsersTableColumns.emailConnection,
          name: formatMessage({
            id: 'web.settings.adminSettings.teamManagement.emailConnection',
          }),
          rowCell: {
            component: emailСonnectionCellProxyComponent,
          },
          sorting: alphabetical('email_connection_connected'),
          width: 200,
        }
      );
    }

    return tableColumns;
  }

  componentWillUnmount() {
    usersTableExternals.unset();
  }

  onChangeRole = (role, user) => {
    const {
      actionCreators: { grantAdminRole, grantUserRole },
    } = this.props;

    if (UserRoles.admin === role) {
      grantAdminRole(user.id);
    } else if (UserRoles.user === role) {
      grantUserRole(user.id);
    }
  };

  isChangeRoleDisabled = (user) => {
    const { currentUser } = this.props;
    return user.id === currentUser.id;
  };

  getRole = (user) => {
    const { masterTeamAdminIds } = this.props;
    return masterTeamAdminIds.includes(user.id)
      ? UserRoles.admin
      : UserRoles.user;
  };

  isDeleteUserDisabled = (user) => {
    const { currentUser } = this.props;
    return user.id === currentUser.id;
  };

  getPagination() {
    return { currentPage: CURRENT_PAGE, perPage: tablePerPageFromStorage() };
  }

  setTableExternals = (externals) => usersTableExternals.set(externals);

  getSearch() {
    const {
      intl: { formatMessage },
    } = this.props;
    return {
      onClear: () => {},
      placeholderText: formatMessage({
        id: 'common.search',
      }),
      searchers: [
        {
          label: formatMessage({ id: 'common.name' }),
          searching: (item) => item.name,
        },
        {
          label: formatMessage({ id: 'common.email' }),
          searching: (item) => item.email,
        },
      ],
      showClear: false,
    };
  }

  _getZeroState = () => {
    const {
      intl: { formatMessage },
    } = this.props;

    return {
      bodyText: formatMessage({
        id: 'web.settings.adminSettings.userManagement.users.zeroStateBody',
      }),
      imageUrl: getImageUrl('rocket-blueprint', 'svg'),
      titleText: formatMessage({
        id: 'web.settings.adminSettings.userManagement.users.zeroStateTitle',
      }),
    };
  };
}

UsersTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  masterTeamAdminIds: PropTypes.array.isRequired,
  showConnectionStatusColumns: PropTypes.bool,
  users: PropTypes.array.isRequired,
};

UsersTable.defaultProps = {
  columnSettings: [],
  showConnectionStatusColumns: false,
};

export default injectIntl(UsersTable);
