import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampaignTypeCard from 'web/modals/addToCampaign/components/camapignTypeCard';
import Cards, { Direction } from 'components/layouts/cards';
import { BackgroundColors } from 'libs/constantsStyles';
import { getImageUrl } from 'web/libs/constants';
import { AddToCampaignCardIds } from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import HoverTooltip, {
  HoverTooltipEffect,
  HoverTooltipPlace,
} from 'components/hoverTooltip/hoverTooltip';
import './campaignTypeChooser.scss';
import { FormattedMessage } from 'react-intl';

const CARD_WIDTH = 230;
const CARD_HEIGHT = 230;
const CARDS_MARGIN_AROUND = 40;
const CARDS_MARGIN_BETWEEN = 60;
const CARDS_MAX_WIDTH = 0;
const CARDS_MIN_WIDTH = 0;

class CampaignTypeChooser extends Component {
  componentWillMount() {
    this.marketoImgUrl = getImageUrl('name-logo-marketo', 'png');
    this.toutImgUrl = getImageUrl('name-logo-tout', 'png');
    this.cards = this.getCards();
  }

  getHoverTipContent(activeWorkFlowInstances, canBeAddedToCampaign) {
    if (canBeAddedToCampaign) {
      return () => null;
    } else {
      return () => (
        <FormattedMessage
          id="campaignChooser.toutCardDisabled"
          values={{ maximum: activeWorkFlowInstances }}
        />
      );
    }
  }

  render() {
    return (
      <div className="campaign-type-chooser">
        <Cards
          backgroundColor={BackgroundColors.white}
          cards={this.cards}
          direction={Direction.horizontal}
          maxWidth={CARDS_MAX_WIDTH}
          minWidth={CARDS_MIN_WIDTH}
          marginAround={CARDS_MARGIN_AROUND}
          marginBetween={CARDS_MARGIN_BETWEEN}
        />
      </div>
    );
  }

  getCards = () => {
    const {
      activeWorkFlowInstances,
      canBeAddedToCampaign,
      marketoCardDisabled,
      actionCreators: {
        initOpenToutCampaignChooser,
        initOpenMarketoCampaignChooser,
      },
    } = this.props;
    const common = {
      height: CARD_HEIGHT,
      width: CARD_WIDTH,
    };

    const tooltipTextId = marketoCardDisabled
      ? 'campaignChooser.disabledMarketoInfo'
      : '';

    return [
      {
        children: (
          <div className="tooltip-container">
            <HoverTooltip
              effect={HoverTooltipEffect.float}
              getContent={this.getHoverTipContent(
                activeWorkFlowInstances,
                canBeAddedToCampaign
              )}
              place={HoverTooltipPlace.left}
              tooltipId="campaign-chooser-toutcard-disabled"
            >
              <CampaignTypeCard
                iconClassName="campaign-type-chooser-tout-icon"
                textId="common.salesCampaign"
              />
            </HoverTooltip>
          </div>
        ),
        className: 'campaign-type-chooser-card',
        id: AddToCampaignCardIds.tout,
        other: {
          disabled: !canBeAddedToCampaign,
          onClick: initOpenToutCampaignChooser,
          shadow: 2,
          ...common,
        },
      },
      {
        children: (
          <div className="tooltip-container">
            <HoverTooltip
              effect={HoverTooltipEffect.float}
              place={HoverTooltipPlace.left}
              textId={tooltipTextId}
              tooltipId="campaign-chooser-marketo-disabled"
            >
              <CampaignTypeCard
                iconClassName="campaign-type-chooser-marketo-icon"
                textId="common.marketingCampaign"
              />
            </HoverTooltip>
          </div>
        ),
        className: 'campaign-type-chooser-card',
        id: AddToCampaignCardIds.marketo,
        other: {
          disabled: marketoCardDisabled,
          onClick: initOpenMarketoCampaignChooser,
          shadow: 2,
          ...common,
        },
      },
    ];
  };
}

CampaignTypeChooser.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  activeWorkFlowInstances: PropTypes.number.isRequired,
  canBeAddedToCampaign: PropTypes.bool.isRequired,
  marketoCardDisabled: PropTypes.bool.isRequired,
};

export default CampaignTypeChooser;
