import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { ApiErrors, Urls, ModalPopupIds } from 'web/libs/constants';
import { navigateToCampaignsTab } from 'web/services/routerService';
import {
  AddToCampaignErrorMessages,
  AlertIds,
  ModalComponentIds,
} from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import { ModalOverlayIds } from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import {
  setPopupAlert,
  setPopupOverlay,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { initCurrentCategory } from 'web/modals/addToCampaign/actionCreators/campaignCategoryActionCreators';
import {
  openModalAddToCampaign,
  closeModalAddToCampaign,
} from 'web/modals/addToCampaign';
import { initPeopleSearchAddData } from 'web/people/peopleSearchAdd/actionCreators/initActionCreators';
import {
  getPeopleDataBySalesforceIds,
  getPeopleDataBySalesforceIdsWithoutImport,
  getNonSalesforcePeopleData,
} from 'web/modals/addToCampaign/actionCreators/peopleActionCreators';
import { setPreselectedIds } from 'web/modals/addToCampaign/actionCreators/peopleActionCreators';
import { initMarketoCampaignsAndWorkspaces } from 'web/modals/addToCampaign/actionCreators/marketoCampaignsActionCreators';
import { initCampaignsAndCategories } from 'web/modals/addToCampaign/actionCreators/campaignActionCreators';
import { handlePeopleSearchAddResize } from 'web/people/peopleSearchAdd/actionCreators/popupActionCreators';
import { track, getSource } from 'web/services/mixpanelService';
import {
  CampaignActionEvents,
  CampaignProperties,
} from 'web/libs/mixpanelEvents';
import {
  resizeToAddToCampaign,
  resizeToCampaignTypeChooser,
  resizeToLargeMin,
  resizeToLaunched,
  resizeToPreviewTasks,
  resizeToPreviews,
  resizeToSalesforceProgress,
  resizeToSchedule,
} from 'web/services/windowService';
import { closeAddToCompaignPopup as owaCloseAddToCompaignPopup } from 'owa/actionCreators/addToCompaignPopup';
import { getPersonById } from 'web/people/services/peopleService';
import { isMsiActions } from 'web/user/selectors/userSelectors';

export function resetState() {
  return {
    type: ActionTypes.addToCampaignModal.state.reset,
  };
}

// common to other modals
export function openModal(id, data) {
  return {
    type: ActionTypes.modal.state.open,
    id,
    data,
  };
}

export function setModalComponent(component, data) {
  return {
    type: ActionTypes.modal.state.setComponent,
    component,
    data,
  };
}

export function closeModal() {
  return (dispatch, getState) => {
    const { addToCampaignModalWindowMode: windowMode } = getState();
    dispatch({ type: ActionTypes.modal.state.close });
    dispatch(resetState());
    dispatch(owaCloseAddToCompaignPopup());

    if (windowMode) {
      window.close();
    }
  };
}

export function defaultCatchAlert(
  err = {},
  defaultAlert = AlertIds.genericApiError
) {
  return (dispatch) => {
    if (err.rawResponse === ApiErrors.unverified) {
      // response is a string, not valid json
      dispatch(setPopupAlert(AlertIds.unverifiedUser));
    } else {
      dispatch(setPopupAlert(defaultAlert));
    }
  };
}

export function hideProcessingAlert() {
  return (dispatch) => {
    dispatch(setModalComponent(ModalComponentIds.addToCampaign));
    dispatch({ type: ActionTypes.addToCampaignModal.state.processingClear });
  };
}

export function warnDataLoss(shouldClear) {
  return (dispatch, getState) => {
    const { popup } = getState();
    if (shouldClear) {
      dispatch({
        type: ActionTypes.addToCampaignModal.state.warnLosingData,
        textId: '',
      });
    } else {
      let textId = 'web.campaigns.dataLossYourData';
      switch (popup) {
        case ModalComponentIds.previewEmail:
        case ModalComponentIds.scheduleLaunch:
          textId = 'web.campaigns.dataLossPreviews';
          break;
        case ModalComponentIds.transitioningToSalesforce:
          textId = 'web.addToCampaign.dataLossSelections';
          break;
        default:
          textId = 'web.campaigns.dataLossPreviews';
          break;
      }
      dispatch({
        type: ActionTypes.addToCampaignModal.state.warnLosingData,
        textId,
      });
    }
  };
}

export function goToCampaigns() {
  return (dispatch, getState) => {
    const { addToCampaignModalWindowMode: windowMode } = getState();

    if (windowMode) {
      window.open(Urls.campaignsTab);
    } else {
      navigateToCampaignsTab();
    }

    dispatch(closeModal());
  };
}

export function openPreviews() {
  return (dispatch, getState) => {
    const {
      previewEmailsPreviews: { attachmentsCount, isEmail, recipients },
      addToCampaignModalWindowMode: windowMode,
      addToCampaignSalesforceImportFailures: failedImports,
    } = getState();

    if (isEmail && windowMode) {
      resizeToPreviews(attachmentsCount > 0);
    } else if (windowMode) {
      let failedCount = 0;
      if (failedImports) {
        failedCount = Object.keys(failedImports).length;
      }
      resizeToPreviewTasks(recipients.ids.length + failedCount);
    }

    dispatch(setPopupLoading(false));
    dispatch(
      setModalComponent(
        isEmail ? ModalComponentIds.previewEmail : ModalComponentIds.previewTask
      )
    );
  };
}

export function openSchedule(data) {
  return (dispatch, getState) => {
    const { addToCampaignModalWindowMode: windowMode } = getState();

    if (windowMode) {
      resizeToSchedule();
    }

    dispatch(setModalComponent(ModalComponentIds.scheduleLaunch, data));
  };
}

export function openSchedulePast() {
  return (dispatch, getState) => {
    const { addToCampaignModalWindowMode: windowMode } = getState();

    if (windowMode) {
      resizeToLargeMin();
    }

    dispatch(setModalComponent(ModalComponentIds.scheduleLaunchPast));
  };
}

export function openLaunched(data) {
  return (dispatch, getState) => {
    const { addToCampaignModalWindowMode: windowMode } = getState();

    if (windowMode) {
      resizeToLaunched();
    }

    dispatch(setModalComponent(ModalComponentIds.campaignLaunched, data));
  };
}

const trackOpenCampaignChooser = (properties, sourceOpener) => {
  const source = getSource(sourceOpener);

  if (source) {
    track(CampaignActionEvents.addToCampaignAction, {
      ...properties,
      Source: source,
    });
    return;
  }

  track(CampaignActionEvents.addToCampaignAction, properties);
};

export const openToutCampaignChooser = () => (dispatch, getState) => {
  const {
    addToCampaignModalWindowMode: windowMode,
    addToCampaignSourceOpener: sourceOpener,
  } = getState();

  trackOpenCampaignChooser(
    { 'Action Type': CampaignProperties.addToCampaignActionTypes.chooseSales },
    sourceOpener
  );

  if (windowMode) {
    resizeToAddToCampaign();
  }

  dispatch(setModalComponent(ModalComponentIds.addToCampaign));
};

export const openMarketoCampaignChooser = () => (dispatch, getState) => {
  const {
    addToCampaignModalWindowMode: windowMode,
    addToCampaignSourceOpener: sourceOpener,
  } = getState();

  trackOpenCampaignChooser(
    {
      'Action Type':
        CampaignProperties.addToCampaignActionTypes.chooseMarketing,
    },
    sourceOpener
  );

  if (windowMode) {
    resizeToAddToCampaign();
  }

  dispatch(setModalComponent(ModalComponentIds.addToMarketoCampaign));
};

// ATC-transition: Transitions to Marketo Campaign Chooser from Campaign Type Chooser when Marketo Campaign
// type is selected
export const initOpenMarketoCampaignChooser = () => (dispatch, getState) => {
  dispatch(setPopupLoading(true));
  dispatch(initMarketoCampaignsAndWorkspaces())
    .then(() => {
      dispatch(openMarketoCampaignChooser());
    })
    .catch((e) => {
      dispatch(setPopupAlert(AlertIds.marketoWorkspacesError));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};

// ATC-transition: Transitions to Tout Campaign Chooser from Campaign Type Chooser when Tout Campaign
// type is selected or automatically after Add People when there are no Marketo leads detected
export const initOpenToutCampaignChooser = () => (dispatch, getState) => {
  dispatch(initCurrentCategory());
  dispatch(openToutCampaignChooser());
};

// ATC-transition: 1st view when not adding from Salesforce, otherwise transitions after Salesforce Progress
export const openAddPeople = () => (dispatch, getState) => {
  dispatch(handlePeopleSearchAddResize());
  dispatch(setModalComponent(ModalComponentIds.addPeople));
};

// ATC-transition: Transitions after Add People when Marketo Leads are detected, prompts users to choose
// between Marketo and Tout campaign, shows when detects Marketo leads
export const openCampaignTypeChooser = () => (dispatch, getState) => {
  const {
    addToCampaignModalWindowMode: windowMode,
    peopleSearchAddState: { people },
  } = getState();

  if (windowMode) {
    resizeToCampaignTypeChooser();
  }

  if (people.length === 1) {
    dispatch(setPopupLoading(true));
    getPersonById(people[0].id)
      .then((person) => {
        dispatch(
          setModalComponent(ModalComponentIds.chooseCampaignType, { person })
        );
      })
      .catch((error) => {
        dispatch(setPopupOnError(error));
      })
      .finally(() => {
        dispatch(setPopupLoading(false));
      });
  } else {
    dispatch(setModalComponent(ModalComponentIds.chooseCampaignType));
  }
};

// ATC-transition: 1st view when adding from Salesforce
export const openSalesforceProgress = () => (dispatch, getState) => {
  const {
    peopleSearchAddState: { people },
    addToCampaignModalWindowMode: windowMode,
  } = getState();

  if (windowMode) {
    resizeToSalesforceProgress(people.length);
  }

  dispatch(setModalComponent(ModalComponentIds.transitioningToSalesforce));
};

export const openAddToCampaign = (campaignId) => (dispatch, getState) => {
  openModalAddToCampaign(null, campaignId);
};

export const closeAddToCampaign = () => (dispatch) => {
  dispatch(closeModal());
  closeModalAddToCampaign();
};

const setPopupOnError = ({ message } = {}) => (dispatch) => {
  switch (message) {
    case AddToCampaignErrorMessages.notAToutContact:
      dispatch(setPopupOverlay(ModalOverlayIds.notAToutContact));
      break;
    default:
      dispatch(setPopupOverlay(ModalOverlayIds.retryPeopleData));
      break;
  }
};

export const initModalAndDataForIds = (ids = {}, campaignId) => (
  dispatch,
  getState
) => {
  const { count, salesforce } = ids;
  const actions = [
    dispatch(initPeopleSearchAddData(ids)),
    dispatch(initCampaignsAndCategories(campaignId)),
  ];

  dispatch(setPreselectedIds(ids));
  dispatch(openModal(ModalPopupIds.addToCampaign));

  if (salesforce.length) {
    if (isMsiActions(getState())) {
      dispatch(setPopupLoading(true));
      dispatch(openAddPeople());
      actions.push(
        dispatch(getPeopleDataBySalesforceIdsWithoutImport(salesforce))
      );
    } else {
      dispatch(openSalesforceProgress());
      actions.push(dispatch(getPeopleDataBySalesforceIds(salesforce)));
    }
  } else {
    dispatch(setPopupLoading(true));
    dispatch(openAddPeople());

    if (count) {
      actions.push(dispatch(getNonSalesforcePeopleData(ids)));
    }
  }

  Promise.all(actions)
    .catch((error) => {
      dispatch(setPopupOnError(error));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};

export function iframeMessage(initing) {
  return () => {
    if (initing) {
      window.top.postMessage('a2c:loaded', '*');
    } else {
      window.top.postMessage('a2c:close', '*');
    }
  };
}

export const setSourceOpener = (sourceOpener) => ({
  type: ActionTypes.addToCampaignModal.state.sourceOpener,
  sourceOpener,
});
