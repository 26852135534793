import { Field, Form, reduxForm } from 'redux-form';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormLayout from 'components/layouts/formLayout';
import FormTitle from 'components/layouts/formLayout/formTitle';
import LabeledInputReduxForm from 'components/labeledInputReduxForm';
import FormButtonsArea from 'components/layouts/formLayout/formButtonsArea';
import FormError from 'components/layouts/formLayout/formError';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';
import Action from 'components/buttons/action';
import { FormattedMessage } from 'react-intl';
import { BackgroundColors } from 'libs/constantsStyles';
import {
  email as emailValidatorFactory,
  required as requiredFactory,
} from 'web/form/helpers/validators';
import './firstStepLogin.scss';

const usernameValidator = [emailValidatorFactory(), requiredFactory()];
const FirstStepLogin = ({
  loading,
  handleSubmit,
  onSubmit,
  error,
  setUnauthorizedHeaderKey,
}) => {
  useEffect(() => {
    setUnauthorizedHeaderKey('web.unauthorized.firstStep.title');
  }, []);

  const submitFormHandler = ({ username }) => {
    return onSubmit(username).catch((error) => {
      throw error;
    });
  };

  return (
    <div className="first-step-login-form">
      {loading && <LoadingSpinner imageUrl={getImageUrl} />}
      <FormLayout className="first-step-login-form-wrapper">
        <FormTitle id="login.form.title" />
        <Form autoComplete="false" onSubmit={handleSubmit(submitFormHandler)}>
          <div className="form-row">
            <Field
              autoComplete="username"
              className="form-cell"
              component={LabeledInputReduxForm}
              id="username"
              label="common.email"
              name="username"
              type="text"
              validate={usernameValidator}
              autoFocus
            />
          </div>
          <FormError>{error && <FormattedMessage id={error} />}</FormError>
          <FormButtonsArea align="right">
            <Action
              className="medium login-form-login-button"
              color={BackgroundColors.blue}
              type="submit"
            >
              <FormattedMessage id="common.login" />
            </Action>
          </FormButtonsArea>
        </Form>
      </FormLayout>
    </div>
  );
};

FirstStepLogin.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setUnauthorizedHeaderKey: PropTypes.func,
};

FirstStepLogin.defaultProps = {
  error: '',
  loading: false,
  setUnauthorizedHeaderKey: () => {},
};

export default reduxForm({
  form: 'first-step-login',
  touchOnBlur: false,
  touchOnChange: false,
})(FirstStepLogin);
