import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CelebrationVideo } from 'web/libs/constants';
import './liveFeedCelebration.scss';
import I18N from 'languages';

class LiveFeedCelebration extends Component {
  playFireworksVideo(video) {
    if (video) {
      video.play();
    }
  }

  render() {
    const { amount, rep } = this.props;
    const { source, type } = CelebrationVideo;
    /*eslint-disable*/
    return (
      <div className="live-feed-celebration">
        <p className="deal-details">
          <FormattedMessage
            id="web.liveFeed.header.dealDetails"
            values={{ amount, rep }}
          />
        </p>
        <video ref={this.playFireworksVideo}>
          <source src={source.webm} type={type.webm} />
          <source src={source.mp4} type={type.mp4} />
          <source src={source.ogg} type={type.ogg} />
        </video>
      </div>
    );
    /*eslint-enable*/
  }
}

LiveFeedCelebration.propTypes = {
  amount: PropTypes.string.isRequired,
  rep: PropTypes.string.isRequired,
};

export default LiveFeedCelebration;
