// Use API for response data
// Namespace state action types to module
// Namespace any other common data types to feature
// Name events the same as the chain of namespace to prevent name clashes

/* Please keep alphabetical order for simplicity */

export default {
  task: {
    state: {
      clearContact: 'TASK_STATE_CLEAR_CONTACT',
      createEdit: 'TASK_STATE_CREATE_EDIT',
      destroyEdit: 'TASK_STATE_DESTROY_EDIT',
      loading: 'TASK_STATE_LOADING',
      setCanUserHandleContact: 'SET_CAN_USER_HANDLE_CONTACT',
      updateEdit: 'TASK_STATE_UPDATE_EDIT',
    },
  },
  userAutocomplete: {
    value: 'USER_AUTOCOMPLETE_VALUE',
  },
};
