export default {
  //Stats
  setStats: 'SALESFORCE_SET_STATS',
  setStatsFetched: 'SALESFORCE_SET_STATS_FETCHED',
  setStatsFetching: 'SALESFORCE_SET_STATS_FETCHING',
  //Integration
  setIntegration: 'SALESFORCE_SET_INTEGRATION',
  setIntegrationFetched: 'SALESFORCE_SET_INTEGRATION_FETCHED',
  setIntegrationFetching: 'SALESFORCE_SET_INTEGRATION_FETCHING',
  deleteIntegration: 'SALESFORCE_DELETE_INTEGRATION',
  //Account
  setAccount: 'SALESFORCE_SET_ACCOUNT',
  setAccountFetched: 'SALESFORCE_SET_ACCOUNT_FETCHED',
  setAccountFetching: 'SALESFORCE_SET_ACCOUNT_FETCHING',
};
