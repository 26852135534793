import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';

const ActivateApiConfirmationPopup = (props) => {
  const {
    actionCreators: { closePopup, activateApi },
  } = props;

  return (
    <Popup
      footer={{
        onPrimary: activateApi,
        onSecondary: closePopup,
        primaryTextId: 'common.activate',
        secondaryTextId: 'common.cancel',
      }}
      header={{
        onClose: closePopup,
        textId: 'common.areYouSure',
      }}
      onOutsideClick={closePopup}
      size={PopupSize.small}
    >
      <FormattedHTMLMessage id="web.settings.accountSettings.salesforce.syncSettings.api.activate.text" />
    </Popup>
  );
};

ActivateApiConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

export default ActivateApiConfirmationPopup;
