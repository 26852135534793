import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AuthorizationTypes } from 'web/compliance/libs/complianceConstants';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import Label from 'components/label';
import Select2 from 'components/selects/select2/select2';
import Text from 'components/inputs/text';
import DatePicker from 'components/dates/datePicker';
import './authorizationEditing.scss';

class AuthorizationEditing extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
    } = props;
    this.enterPurposePlaceholder = formatMessage({
      id: 'web.people.compliance.enterPurposeHere',
    });
    this.enterNotesPlaceholder = `${formatMessage({
      id: 'web.people.compliance.enterNotesHere',
    })} (${formatMessage({ id: 'common.optional' })})`;
    this.noneSelectedPlaceholder = formatMessage({ id: 'common.noneSelected' });
    this.enterAuthorizationPlaceholder = formatMessage({
      id: 'web.people.compliance.enterAuthorization',
    });
    this.authorizationItems = [
      {
        value: AuthorizationTypes.legitimateInterest,
        label: formatMessage({
          id: 'web.people.compliance.legitimateInterest',
        }),
      },
      {
        value: AuthorizationTypes.consent,
        label: formatMessage({ id: 'web.people.compliance.consent' }),
      },
      {
        value: AuthorizationTypes.performanceOfAContract,
        label: formatMessage({
          id: 'web.people.compliance.performanceOfAContract',
        }),
      },
      {
        value: AuthorizationTypes.complianceWithLegalObligation,
        label: formatMessage({
          id: 'web.people.compliance.complianceWithLegalObligation',
        }),
      },
      {
        value: AuthorizationTypes.protectionOfVitalInterests,
        label: formatMessage({
          id: 'web.people.compliance.protectionOfVitalInterests',
        }),
      },
      {
        value: AuthorizationTypes.publicInterest,
        label: formatMessage({ id: 'web.people.compliance.publicInterest' }),
      },
      {
        value: AuthorizationTypes.other,
        label: formatMessage({ id: 'common.other' }),
      },
    ];
  }

  render() {
    const {
      complianceEditState: {
        authorizationType,
        consentDate,
        consentNotesValue,
        consentPurposeValue,
        otherAuthorizationValue,
      },
      labelClassName,
      labelWidth,
      sideLabel,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="compliance-editing-row compliance-editing-row-authorization">
        <Label
          id="authorization-types"
          classWrapper={labelClassName}
          messageKey="web.people.compliance.authorizationType"
          sideLabel={sideLabel}
          width={labelWidth}
        >
          <Select2
            classes="compliance-select"
            onChange={this._onAuthorizationChange}
            options={this.authorizationItems}
            placeholder={this.noneSelectedPlaceholder}
            searchable={false}
            value={authorizationType}
            clearValueText={formatMessage({
              id: 'web.people.compliance.clearValue',
            })}
          />
        </Label>
        {authorizationType === AuthorizationTypes.other && (
          <Label
            id="authorization-other"
            className="hidden"
            classWrapper={labelClassName}
            messageKey="common.other"
            sideLabel={sideLabel}
            width={labelWidth}
          >
            <Text
              className="other-authorization-input"
              onChange={this._onAuthorizationValueChange}
              placeholder={this.enterAuthorizationPlaceholder}
              type="text"
              value={otherAuthorizationValue}
            />
          </Label>
        )}
        {authorizationType === AuthorizationTypes.consent && (
          <div className="compliance-consent-editing">
            <Label
              id="authorization-consent-date"
              classWrapper={labelClassName}
              messageKey="web.people.compliance.dateOfConsent"
              sideLabel={sideLabel}
              width={labelWidth}
            >
              <DatePicker
                className="authorization-consent-date-picker"
                date={moment(consentDate)}
                isOutsideRange={this._disableOutsideRange}
                onDateChange={this._onDateChange}
                readOnly
              />
            </Label>
            <Label
              id="authorization-consent-purpose"
              classWrapper={labelClassName}
              messageKey="web.people.compliance.purposeOfProcessing"
              sideLabel={sideLabel}
              width={labelWidth}
              requiredAsterisk
            >
              <Text
                autoFocus
                className="consent-authorization-purpose"
                onChange={this._onConsentPurposeChange}
                placeholder={this.enterPurposePlaceholder}
                type="text"
                value={consentPurposeValue}
              />
            </Label>
            <Label
              id="authorization-consent-notes"
              classWrapper={labelClassName}
              messageKey="web.people.compliance.notesOfConsent"
              sideLabel={sideLabel}
              width={labelWidth}
            >
              <Text
                autoFocus={false}
                className="consent-authorization-notes"
                onChange={this._onConsentNotesChange}
                placeholder={this.enterNotesPlaceholder}
                type="text"
                value={consentNotesValue}
              />
            </Label>
          </div>
        )}
      </div>
    );
  }

  _onAuthorizationChange = (authorization) => {
    const authorizationType = authorization ? authorization.value : '';

    this._setAuthorizationType(authorizationType);
  };

  _setAuthorizationType = (authorizationType) =>
    this.props.updateCompliance({ authorizationType });

  _onConsentPurposeChange = (consentPurposeValue) =>
    this.props.updateCompliance({ consentPurposeValue });

  _onConsentNotesChange = (consentNotesValue) =>
    this.props.updateCompliance({ consentNotesValue });

  _onAuthorizationValueChange = (otherAuthorizationValue) =>
    this.props.updateCompliance({ otherAuthorizationValue });

  _onDateChange = (consentDate) =>
    this.props.updateCompliance({
      consentDate: moment(consentDate).startOf('day'),
    });

  _disableOutsideRange = () => false;
}

AuthorizationEditing.propTypes = {
  complianceEditState: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  labelClassName: PropTypes.string,
  labelWidth: PropTypes.number,
  sideLabel: PropTypes.bool,
  updateCompliance: PropTypes.func.isRequired,
};

AuthorizationEditing.defaultProps = {
  labelWidth: 175,
  sideLabel: false,
};

export default injectIntl(AuthorizationEditing);
