import I18N from 'languages';

export const REFERENCE_DATE_FILTER_ID = 'REFERENCE_DATE_FILTER_ID';

export const REFERENCE_DATES = {
  /* eslint-disable  sort-keys, sort-keys-fix/sort-keys-fix */
  delivered_at: {
    id: 'delivered_at',
    name: I18N.getStr(
      'web.commandCenter.advancedSearches.referenceDate.deliveredAt'
    ),
  },
  created_at: {
    id: 'created_at',
    name: I18N.getStr(
      'web.commandCenter.advancedSearches.referenceDate.createdAt'
    ),
  },
  send_at: {
    id: 'send_at',
    name: I18N.getStr(
      'web.commandCenter.advancedSearches.referenceDate.sendAt'
    ),
  },
  failed_at: {
    id: 'failed_at',
    name: I18N.getStr(
      'web.commandCenter.advancedSearches.referenceDate.failedAt'
    ),
  },
  /* eslint-enable  sort-keys, sort-keys-fix/sort-keys-fix */
};

export const DEFAULT_REFERENCE_DATE = REFERENCE_DATES.delivered_at.id;
