import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Switch from 'components/buttons/switch/switch';
import './actionableFeature.scss';

const ActionableFeature = (props) => {
  const {
    action,
    isConnected,
    actionableFeature,
    textNode,
    classes,
    iconClass,
  } = props;

  const actionableClassName = classNames('email-connection-features', classes);
  const iconClassName = classNames('feature-icon', iconClass, {
    on: actionableFeature,
  });

  return (
    <div className={actionableClassName}>
      <i className={iconClassName} />
      <span className="features-description">{textNode}</span>
      {isConnected && (
        <Switch
          checked={actionableFeature}
          className="email-compliance-switch"
          onChange={action}
          name="sendCompliance"
        />
      )}
    </div>
  );
};

ActionableFeature.propTypes = {
  action: PropTypes.func,
  actionableFeature: PropTypes.bool,
  textNode: PropTypes.node.isRequired,
  isConnected: PropTypes.bool,
  iconClass: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

ActionableFeature.defaultProps = {
  action: () => {},
  isConnected: false,
  classes: '',
  actionableFeature: false,
};

export default ActionableFeature;
