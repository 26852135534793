import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Text from 'components/inputs/text';
import TextButton from 'components/buttons/text';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import Select from 'components/selects/default';
import Icon from 'components/buttons/icon';
import {
  EditTemplateCondtions,
  EditTemplateDynamicFields,
} from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import './recommendEditingTableRow.scss';
import I18N from 'languages';

class RecommendEditingTableRow extends PureComponent {
  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.dynamicFieldLabel = formatMessage({
      id:
        'web.editTemplateSlideOut.recommendCard.editingTableRow.selectDynamicField',
    });
    this.conditionItems = [
      {
        id: EditTemplateCondtions.equals,
        name: formatMessage({
          id: 'web.editTemplateSlideOut.recommendCard.editingTableRow.matches',
        }),
      },
      {
        id: EditTemplateCondtions.notEqual,
        name: formatMessage({
          id:
            'web.editTemplateSlideOut.recommendCard.editingTableRow.doesNotMatch',
        }),
      },
      {
        id: EditTemplateCondtions.contains,
        name: formatMessage({
          id: 'web.editTemplateSlideOut.recommendCard.editingTableRow.contains',
        }),
      },
      {
        id: EditTemplateCondtions.doesNotContain,
        name: formatMessage({
          id:
            'web.editTemplateSlideOut.recommendCard.editingTableRow.doesNotContain',
        }),
      },
    ];
    this.valuePlaceholder = formatMessage({
      id:
        'web.editTemplateSlideOut.recommendCard.editingTableRow.dynamicFieldValuePlaceholder',
    });
    this.removeLabel = formatMessage({ id: 'common.remove' });
  }

  render() {
    const { condition, disabled, label, index, value } = this.props;

    const localizedlabel =
      label && EditTemplateDynamicFields.indexOf(label) !== -1
        ? I18N.getConstStr('dynamicFields', label)
        : label;

    return (
      <div className="edit-template-recommend-editing-table-row">
        {(label.length && (
          <TextButton
            className="recommend-editing-table-cell recommend-editing-table-cell-dynamic text-overflow"
            disabled={disabled}
            onClick={this.openDynamicFields}
          >
            {localizedlabel}
          </TextButton>
        )) || (
          <Button
            className="recommend-editing-table-cell recommend-editing-table-cell-dynamic"
            color={ButtonColors.gray}
            disabled={disabled}
            onClick={this.openDynamicFields}
            size={ButtonSizes.input}
          >
            {this.dynamicFieldLabel}
          </Button>
        )}
        <Select
          className="recommend-editing-table-cell-condition"
          classNameWrapper="recommend-editing-table-cell recommend-editing-table-cell-condition-wrapper"
          disabled={disabled}
          items={this.conditionItems}
          onChange={this.onConditionChange}
          selected={condition}
        />
        <Text
          autoFocus={false}
          className="recommend-editing-table-cell recommend-editing-table-cell-value"
          disabled={disabled}
          id={`recommended-mappings-${index}`}
          onChange={this.onValueChange}
          placeholder={this.valuePlaceholder}
          type="text"
          value={value}
        />
        <Icon
          className="recommend-editing-table-cell-remove tout-icon-close"
          disabled={disabled}
          title={this.removeLabel}
          onClick={this.remove}
        />
      </div>
    );
  }

  openDynamicFields = () => {
    const { index, openDynamicFields } = this.props;
    openDynamicFields(index);
  };

  onConditionChange = (value) => {
    const { index, updateEditState } = this.props;
    updateEditState({ condition: value }, index);
  };

  onValueChange = (value) => {
    const { index, updateEditState } = this.props;
    updateEditState({ value }, index);
  };

  remove = () => {
    const { index, remove } = this.props;
    remove(index);
  };
}

RecommendEditingTableRow.propTypes = {
  condition: PropTypes.oneOf([...Object.values(EditTemplateCondtions)]),
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  label: PropTypes.string,
  openDynamicFields: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  updateEditState: PropTypes.func.isRequired,
  value: PropTypes.string,
};

RecommendEditingTableRow.defaultProps = {
  condition: EditTemplateCondtions.equals,
  disabled: false,
  label: '',
  value: '',
};

export default injectIntl(RecommendEditingTableRow);
