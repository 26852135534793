/* eslint-disable no-restricted-syntax */
import ComposeWindowActionTypes from '../libs/composeWindowActionTypes';
import AddressBarActionTypes from 'web/composeWindow/libs/addressBarActionTypes';
import { parseEventsFromEmails } from 'web/emails/helpers/emailsParsers';
import moment from 'moment';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import { MARKETO_EVENTS_DAYS_LIMIT } from 'web/marketo/interestingMoments/libs/interestingMomentsConstants';
import { getSalesforceInstance as getSalesforceInstanceCall } from 'web/liveFeed/services/liveFeedService';
import * as interestingMomentsService from 'web/marketo/interestingMoments/services/interestingMomentsServices';
import {
  autocompleteByFrecency,
  bulkLiquifiedFields,
  bulkSendPitch as bulkSendPitchCall,
  checkBlockedDomains,
  createFindPersonWithAddress,
  createPitch,
  getLiquifiedFields as getLiquifiedFieldsCall,
  getUserIdentities,
  searchPeople as searchPeopleCall,
  sendNewPitch,
  sendPitch,
  updatePitch,
  getPersonEmails,
  fetchPitch,
  fetchPersonById,
} from '../services/composeWindowServices';
import { postMessageToParentWindow } from 'web/services/windowService';
import {
  parseMarketoEventsFlat,
  sortParsedEvents,
} from 'web/liveFeed/engage/helpers/engageParsers';
import {
  getPinnedCategories,
  getRecommendedTemplates,
  getTemplateCategories,
  setComposeTemplatesLoading,
  notifyComposeEdits,
  fetchAndSetByTemplateId,
} from 'web/composeWindow/actionCreators/composeTemplatesActionCreators';
import {
  alreadySending,
  alreadySent,
  buildMasterEmail,
  buildPitchData,
  currentlyCreatingPitch,
  getAboutPersonDetails,
  getDefaultIdentity,
  getIdentity,
  getOneOffAddressId,
  getOneOffPersonId,
  getOneOffEmail,
  getPitchAlerts,
  getPitchId,
  getValidationFailures,
  isComposeWindowClosed,
  isLiquifyComplete,
  pitchIdExists,
  retryPostCreate,
  shouldCreatePitch,
  shouldSavePitch,
  shouldSendPitch,
  userRequestedSend,
  isDirtyChangesForSelectedTemplate,
} from 'web/composeWindow/selectors/composeWindowSelectors';
import { addAlert as addGlobalBannerAlert } from 'web/globalBannerAlerts/actionCreators/globalBannerAlertsActionCreators';
import GlobalBannerAlertsConstants from 'web/globalBannerAlerts/libs/globalBannerAlertsConstants';
import {
  AddressableTypes,
  AddressTypes,
  AlertIds,
  BulkEmails,
  PitchStates,
  SAVING_DEBOUNCE_MS,
  DETAILS_EVENTS_LIMIT,
  COMPOSE_EDITOR_ID,
  END_SIG_TAG,
} from 'web/composeWindow/libs/composeWindowConstants';
import { ApiErrors } from 'web/libs/constants';
import { WindowEventNames } from 'libs/constantsShared';
import { getDynamicFields } from 'web/dynamicFields/actionCreators/dynamicFieldsActionCreators';
import {
  fetchFilledTemplate,
  fetchTemplate,
} from 'web/composeWindow/services/composeTemplatesServices';
import debounce from 'lodash/debounce';
import {
  createDraftsForRecipients,
  formatDraftsForSend,
  formatPersonAddressFromES,
  formatTimeStringScheduledEmails,
  getUnsubscribeState,
  hasBlockedStates,
  hasDynamicFields,
  isLimitError,
  mapIdsToAttachments,
  mapTemplateIdToTemplate,
  isBlockedError,
  getLocalizedTimezone,
} from 'web/composeWindow/helpers/composeWindowHelpers';
import {
  getPersonName,
  getPrimaryEmailAddress,
  getPrimaryEmailAddressDomain,
} from 'web/person/helpers/personHelpers';
import { getTimezones } from 'web/timezones/actionCreators/timezonesActionCreators';
import {
  getUser,
  getUserSettings,
} from 'web/user/actionCreators/userActionCreators';
import {
  appendSignatureToBody,
  markMissingDynamicFields,
  missingDynamicFieldsPresent,
  replaceSignatureInBody,
  hasUnfilledPrompts,
} from 'web/composeWindow/helpers/composeTemplatesHelpers';
import {
  addAttachmentToEmail,
  clearEmailAttachments,
} from 'web/composeWindow/actionCreators/fileActionCreators';
import { getContentPartners } from 'web/modals/addContent/actionCreators/contentActionCreators';
import { getContent as getContentService } from 'web/content/services/contentService';
import { getTemplateDetails } from 'web/templates/services/templateDetailsService';
import {
  getPeopleByGroup,
  getPeopleByIds,
} from 'web/people/services/peopleService';
import ComposeTemplatesActionTypes from 'web/composeWindow/libs/composeTemplatesActionTypes';
import { getGroup } from 'web/groups/services/groupsService';
import ContentActionTypes from 'web/content/libs/contentActionTypes';
import { importPeopleBySalesforceIds } from 'web/people/actionCreators/peopleImportActionCreators';
import { composeWindowInitPusher } from 'web/composeWindow/actionCreators/composeWindowPusherActionCreators';
import { COMPOSE_DOM_TARGET_ID } from 'web/composeWindow/libs/composeWindowConstants';
import { navigateToLastUrl } from 'web/services/routerService';
import {
  ElasticSearchTypes,
  ElasticSearchObject,
} from 'web/elasticSearch/libs/elasticSearchConstants';
import {
  addRecentlyUsedTemplates,
  fetchRecentlyUsedTemplates,
} from 'web/composeWindow/helpers/composeTemplatesHelpers';
import merge from 'lodash/merge';
import { isMsiActions } from 'web/user/selectors/userSelectors';
import { getPeopleBySalesforceIdsWithoutImport as getPeopleBySalesforceIdsWithoutImportCall } from 'web/people/services/peopleService';

const renderComposeWithBulk = () => (dispatch, getState) => {
  window.toutReact.composeWindow(COMPOSE_DOM_TARGET_ID, {
    options: { openCompose: true },
  });
  dispatch(updateMasterEmailIdentity(getState().composeFrom.identity));
  dispatch(toggleBulkView(true));
};

const fetchAndSetRecipients = (ids) => async (dispatch) => {
  try {
    dispatch(setBulkLoading(true));
    const recipients = await getPeopleByIds(ids);
    dispatch(setBulkLoading(false));
    await dispatch(setRecipients(recipients));
  } catch (err) {
    dispatch(setComposeWindowAlerts([{ id: AlertIds.setRecipientsError }]));
  }
};

export const emailSelectedPeople = (ids) => async (dispatch) => {
  dispatch(renderComposeWithBulk());
  await dispatch(fetchAndSetRecipients(ids));
};

export const emailGroup = (groupId) => async (dispatch) => {
  dispatch(renderComposeWithBulk());
  await dispatch(groupComposeInit(groupId));
};

export const setSearchResults = ({ results, key }) => ({
  key,
  results,
  type: ComposeWindowActionTypes.setSearchResults,
});

export const fetchSearchResults = ({ query, key, type }) => (dispatch) => {
  if (query) {
    searchPeopleCall(query, type)
      .then(({ results = [] }) => {
        dispatch(setSearchResults({ key, results }));
      })
      .catch(() => dispatch(setSearchResults({ key, results: [] })));
  } else if (query === '') {
    dispatch(setSearchResults({ key, results: [] }));
  }
};

export const updateSubject = (subject) => (dispatch) => {
  dispatch({ subject, type: AddressBarActionTypes.updateSubject });
  dispatch(bulkUpdateDrafts(BulkEmails.subject, subject));
};

const setIdentity = (identity) => ({
  identity,
  type: AddressBarActionTypes.updateIdentity,
});

const attachFileToEmail = (attachment) => ({
  payload: attachment,
  type: ComposeWindowActionTypes.addAttachmentToEmail,
});

const setSelectedTemplate = (template) => ({
  template,
  type: ComposeWindowActionTypes.setSelectedTemplate,
});

export const setTemplatesMinimized = (minimized) => ({
  minimized,
  type: ComposeTemplatesActionTypes.setTemplatesMinimized,
});

export const updateIdentity = (identity, updateSignature = true) => (
  dispatch
) => {
  dispatch(setIdentity(identity));
  if (updateSignature) {
    dispatch(updateIdentityForComposeInputs());
    dispatch(bulkUpdateDrafts(BulkEmails.identity, identity));
  }
};

const updateIdentityForComposeInputs = () => (dispatch, getState) => {
  const { selectedTemplate } = getState();
  if (selectedTemplate) {
    dispatch(updateTemplate());
  } else {
    dispatch(updateSignature());
  }
};

export const setAddresses = ({ key, addresses }) => ({
  addresses,
  key,
  type: AddressBarActionTypes.setAddresses,
});

export const appendAddress = (
  { key, address },
  shouldUpdateTemplate = true
) => async (dispatch, getState) => {
  let newAddress = { ...address };
  const { person, email } = address;
  const isTemplateDirtyChanges = isDirtyChangesForSelectedTemplate(getState());

  dispatch({
    address: newAddress,
    key,
    type: AddressBarActionTypes.appendAddress,
  });

  if (person && person.es_document_type === ElasticSearchTypes.people) {
    newAddress = formatPersonAddressFromES(email, person);

    try {
      const checkBlockedDomainsPromise = checkBlockedDomains([
        getPrimaryEmailAddressDomain(person),
      ]);
      const createFindPersonWithAddressPromise = createFindPersonWithAddress({
        email_address: email,
      });
      const [domains, { person: checkedPerson }] = await Promise.all([
        checkBlockedDomainsPromise,
        createFindPersonWithAddressPromise,
      ]);

      if (domains.length > 0) {
        newAddress.domainBlocked = true;
        dispatch(
          setComposeWindowAlerts([{ id: AlertIds.singleFlowDomainBlock }])
        );
      }
      newAddress.addressId = checkedPerson.primary_address.id;
    } catch (err) {
      newAddress.invalid = true;
      dispatch(setComposeWindowAlerts([{ id: AlertIds.setRecipientsError }]));
    }

    dispatch(
      updateAddressByEmail({
        data: newAddress,
        email,
        key,
      })
    );
  }

  if (key !== AddressTypes.to) {
    dispatch(bulkUpdateDrafts(BulkEmails.ccAndBccAdd, { address, key }));
  } else {
    dispatch(getRecommendedTemplates());

    if (shouldUpdateTemplate) {
      dispatch(updateTemplate(isTemplateDirtyChanges));
    }
  }
};

export const setSelectedTemplateWithId = (templateId) => async (
  dispatch,
  getState
) => {
  if (!templateId) {
    return;
  }
  const {
    composeTemplates: { templates },
  } = getState();
  let mappedTemplate = mapTemplateIdToTemplate(templateId, templates);
  if (!mappedTemplate) {
    // if template is not in redux store - let's try to fetch it from server
    mappedTemplate = await fetchTemplate(templateId);
  }
  dispatch({
    template: mappedTemplate,
    type: ComposeWindowActionTypes.setSelectedTemplate,
  });
};

export const removeAddressByIndex = ({ key, index }) => (dispatch) => {
  dispatch({
    index,
    key,
    type: AddressBarActionTypes.removeAddressByIndex,
  });

  if (key === AddressTypes.to) {
    dispatch({ type: ComposeTemplatesActionTypes.hideRecommendedTemplates });
  }

  dispatch(bulkUpdateDrafts(BulkEmails.ccAndBccRemove, { index, key }));
};

export const updateAddressByEmail = ({ key, email, data }) => ({
  data,
  email,
  key,
  type: AddressBarActionTypes.updateAddressByEmail,
});

export const setAllAddresses = () => (dispatch, getState) => {
  const {
    composeBulkEmails: { group },
  } = getState();

  let address = BulkEmails.allRecipientPill;

  if (group) {
    address = {
      ...address,
      email: group.address.address,
      replacementTextId: null,
    };
  }

  dispatch(setAddresses({ addresses: [address], key: AddressTypes.to }));
};

export const populateAutocomplete = (contacts) => ({
  contacts,
  type: AddressBarActionTypes.populateAutocomplete,
});

export const loadingAutocomplete = () => ({
  type: AddressBarActionTypes.loadingAutocomplete,
});

export const fetchAutocomplete = (queryString) => (dispatch) => {
  dispatch(loadingAutocomplete());
  return autocompleteByFrecency(queryString)
    .then((response) => {
      if (response.contacts) {
        dispatch(populateAutocomplete(response.contacts));
      }
    })
    .catch(() => {
      dispatch(populateAutocomplete([]));
    });
};

const startFetchPersonDetailsById = (id) => ({
  id,
  type: ComposeWindowActionTypes.fetchPersonDetails,
});

const populatePersonDetails = ({ about, id, events }) => ({
  about,
  events,
  id,
  type: ComposeWindowActionTypes.populatePersonDetails,
});

const errorPersonDetails = (id) => ({
  id,
  type: ComposeWindowActionTypes.errorPersonDetails,
});

export const fetchPersonDetailsById = (id) => async (dispatch, getState) => {
  if (id === null) {
    dispatch(errorPersonDetails(id));
  }

  dispatch(startFetchPersonDetailsById(id));

  const startTime = moment()
    .startOf('d')
    .subtract(MARKETO_EVENTS_DAYS_LIMIT, 'd')
    .toISOString();
  const endTime = moment().toISOString();

  let marketoEvents = await interestingMomentsService.getInterestingMomentsByPerson(
    id,
    startTime,
    endTime,
    DETAILS_EVENTS_LIMIT
  );

  marketoEvents = parseMarketoEventsFlat(marketoEvents);
  const emails = await getPersonEmails(id);
  const salesEvents = parseEventsFromEmails(emails);
  const events = [...salesEvents, ...marketoEvents].sort(sortParsedEvents);

  const about = getAboutPersonDetails(getState());
  let instanceUrl = null;

  if (about.sfId) {
    const response = await getSalesforceInstanceCall();
    instanceUrl = response.instance_url;
  }
  about.link = instanceUrl ? `${instanceUrl}/${about.sfId}` : null;

  dispatch(populatePersonDetails({ about, events, id }));
};

export const createNewAddress = (
  { address, key },
  shouldUpdateTemplate = true
) => (dispatch, getState) => {
  const email = address.email.trim();
  const isTemplateDirtyChanges = isDirtyChangesForSelectedTemplate(getState());
  dispatch(
    appendAddress(
      { address: { ...address, email, validated: false }, key },
      shouldUpdateTemplate && !isTemplateDirtyChanges
    )
  );
  const params = { email_address: email };
  return createFindPersonWithAddress(params)
    .then((response) => {
      if (response.person) {
        const addressWithId = {
          addressId: response.person.primary_address.id,
          addressableId: response.person.primary_address.addressable_id,
          domainBlocked: response.address_status.domain_blocked,
          email,
          invalid: false,
          marketingUnsubscribe: response.address_status.marketo_unsubscribed,
          person: response.person,
          salesUnsubscribe: response.address_status.sales_unsubscribed,
          type: 'Person',
          validated: true,
        };
        dispatch(updateAddressByEmail({ data: addressWithId, email, key }));
        if (key === AddressTypes.to && shouldUpdateTemplate) {
          dispatch(updateTemplate(isTemplateDirtyChanges));
        }
        dispatch(singleDebounceSaveOrSend());

        if (addressWithId.domainBlocked) {
          dispatch(
            setComposeWindowAlerts([{ id: AlertIds.singleFlowDomainBlock }])
          );
        }
      }
    })
    .catch((err) => {
      const { error } = err.response.body;
      const addressWithInvalid = {
        email,
        invalid: true,
        validated: true,
      };
      dispatch(updateAddressByEmail({ data: addressWithInvalid, email, key }));
      if (error === ApiErrors.addNewPeopleDisabled) {
        dispatch(
          setComposeWindowAlerts([{ id: AlertIds.addNewPeopleDisabled }])
        );
      }
    });
};

export const populateDraft = (draftId) => async (dispatch, getState) => {
  const pitch = await fetchPitch(draftId);
  const { identities } = getState().composeUserInfo;
  const identity = identities.find(({ id }) => id === pitch.identity_id);
  dispatch(updateIdentity(identity, false));

  if (pitch.pitch_template_id) {
    dispatch(setTemplatesMinimized(true));
    dispatch(setSelectedTemplate(pitch.pitch_template));
  }
  if (pitch.attachments) {
    dispatch(attachFileToEmail(pitch.file_attachments));
  }
  if (pitch.body) {
    dispatch(handleEditorChange(pitch.body));
  }
  if (pitch.subject) {
    dispatch(updateSubject(pitch.subject));
  }

  if (pitch.id) {
    dispatch(setPitchId(pitch.id));
  }

  if (pitch.send_at) {
    dispatch(setPitchSendAt(pitch.send_at));
  }

  // handle addresses last since it might be a group draft and we need all the draft inputs filled in.
  if (pitch.group_id) {
    dispatch(groupComposeInit(pitch.group_id));
  } else if (pitch.address) {
    dispatch({ type: ComposeWindowActionTypes.resetAddresses });
    dispatch(
      createNewAddress(
        {
          address: { ...pitch.address, email: pitch.address.address },
          key: AddressTypes.to,
        },
        false
      )
    );
  }

  dispatch(populateCCAndBccFields(pitch.cc, pitch.bcc));
};

export const populateCCAndBccFields = (cc, bcc) => async (dispatch) => {
  dispatch(clearCCAndBccFields());
  if (cc) {
    cc.split(',')
      .filter(Boolean)
      .forEach((ccAddress) => {
        dispatch(
          appendAddress({
            address: {
              email: ccAddress.replace(/[<>]/g, ''),
              validated: true,
            },
            key: AddressTypes.cc,
          })
        );
      });
  }
  if (bcc) {
    bcc
      .split(',')
      .filter(Boolean)
      .forEach((bccAddress) => {
        dispatch(
          appendAddress({
            address: {
              email: bccAddress.replace(/[<>]/g, ''),
              validated: true,
            },
            key: AddressTypes.bcc,
            validated: true,
          })
        );
      });
  }
};

export const clearCCAndBccFields = () => async (dispatch) => {
  dispatch(setAddresses({ addresses: [], key: AddressTypes.cc }));
  dispatch(setAddresses({ addresses: [], key: AddressTypes.bcc }));
};

export const initComposeWindowView = (options) => async (dispatch) => {
  dispatch(resetSlidingWindow());
  dispatch(composeWindowInitPusher());
  try {
    dispatch(setMainWindowLoading(true));
    await dispatch(setupCompose());
    await dispatch(handleComposeOptions(options));
  } catch (err) {
    dispatch(setComposeWindowAlerts([{ id: AlertIds.composeLoadError }]));
  } finally {
    dispatch(setMainWindowLoading(false));
  }
};

const setupCompose = () => async (dispatch, getState) => {
  if (isComposeWindowClosed(getState())) {
    dispatch({
      type: ComposeWindowActionTypes.openComposeWindowView,
    });
    dispatch(getRecentlyUsedTemplates());
    dispatch(getDynamicFields());
    dispatch(getPinnedCategories());
    dispatch(getUserSettings());
    dispatch(getTemplateCategories());
    dispatch(getUser());
    dispatch(getContentPartners());
    const promisedValues = await Promise.all([
      getUserIdentities(),
      getContentService(),
    ]);
    dispatch(getTimezones());

    const [identities, contentFiles] = promisedValues;
    dispatch({
      content: contentFiles,
      type: ContentActionTypes.set,
    });
    dispatch({
      payload: {
        identities,
      },
      type: ComposeWindowActionTypes.initComposeWindowSuccess,
    });

    const defaultIdentity = getDefaultIdentity(getState());
    dispatch(updateIdentity(defaultIdentity));
  }
};

export const getRecentlyUsedTemplates = () => (dispatch) => {
  const recent = fetchRecentlyUsedTemplates();
  let recentlyUsed = {
    templates: [],
  };
  if (recent) {
    recentlyUsed = merge(recentlyUsed, JSON.parse(recent));
  }
  dispatch({
    payload: recentlyUsed.templates,
    type: ComposeWindowActionTypes.setRecentlyUsedTemplates,
  });
};

export const handleComposeOptions = ({
  addressId,
  draftId,
  groupId,
  multipleRecipients,
  person,
  personId,
  reminderId,
  reminderWorkflowId,
  emailString,
  templateId,
}) => async (dispatch, getState) => {
  if (person) {
    const emailAddress = getPrimaryEmailAddress(person).address;
    dispatch(
      createNewAddress({
        address: { email: emailAddress },
        key: AddressTypes.to,
      })
    );
  }

  if (draftId) {
    await dispatch(populateDraft(draftId));
  }

  if (emailString) {
    dispatch(
      createNewAddress({
        address: { email: emailString },
        key: AddressTypes.to,
      })
    );
  }

  if (personId) {
    await dispatch(fetchAndSetByPersonId(personId, addressId));
  }

  if (reminderId) {
    dispatch(setPitchInfo({ reminderId: reminderId }));
  }

  if (reminderWorkflowId) {
    dispatch(setPitchInfo({ workflowId: reminderWorkflowId }));
  }

  // set template here before group and multiple recipients so the liquidfy doesn't occur
  if (templateId) {
    await dispatch(fetchAndSetByTemplateId(templateId));
  }

  if (groupId) {
    await dispatch(groupComposeInit(groupId));
  }

  if (multipleRecipients) {
    try {
      if (!isEmpty(compact(multipleRecipients))) {
        dispatch(setBulkLoading(true));
      }
      if (isMsiActions(getState())) {
        getPeopleBySalesforceIdsWithoutImportCall(multipleRecipients).then(
          (recipients) => {
            dispatch(setRecipients(recipients));
          }
        );
      } else {
        await dispatch(
          importPeopleBySalesforceIds(multipleRecipients, (peopleIds) => {
            dispatch(fetchAndSetRecipients(peopleIds));
          })
        );
      }
    } catch {
      dispatch(setBulkLoading(false));
    }
  }
};

export const fetchAndSetByPersonId = (personId, addressId) => async (
  dispatch
) => {
  const person = await fetchPersonById(personId);
  const { addresses } = person;
  let [address] = addresses;

  if (addressId) {
    address = addresses.find(({ id }) => id === addressId) || address;
  }

  const { address: email_address } = address;
  const params = { email_address };

  createFindPersonWithAddress(params).then((response) => {
    if (response.person) {
      dispatch(
        setAddresses({
          addresses: [
            {
              addressId: response.person.primary_address.id,
              addressableId: response.person.primary_address.addressable_id,
              domainBlocked: response.address_status.domain_blocked,
              email: email_address,
              invalid: false,
              marketingUnsubscribe:
                response.address_status.marketo_unsubscribed,
              person: response.person,
              salesUnsubscribe: response.address_status.sales_unsubscribed,
              type: 'Person',
              validated: true,
            },
          ],
          key: AddressTypes.to,
        })
      );
      dispatch(getRecommendedTemplates());
    }
  });
};

export const groupComposeInit = (groupId) => async (dispatch, getState) => {
  try {
    dispatch(toggleBulkView(true));
    dispatch(setBulkLoading(true));
    dispatch(setMainWindowLoading(true));

    const { people } = await getPeopleByGroup(groupId); // New Elasticsearch format
    const peopleEmailDomains = people.map((person) =>
      getPrimaryEmailAddressDomain(person)
    );
    const domains = await checkBlockedDomains(peopleEmailDomains);
    const group = await getGroup(groupId);

    const formattedPeople = people.map((person) => ({
      ...person,
      emailAddress: getPrimaryEmailAddress(person).address,
      name: getPersonName(person),
      state: getUnsubscribeState(person, domains),
      validated: true,
    }));

    dispatch({
      payload: group,
      type: ComposeWindowActionTypes.bulkView.setGroup,
    });
    dispatch(setAllAddresses());

    dispatch({
      payload: formattedPeople,
      type: ComposeWindowActionTypes.bulkView.setRecipients,
    });
    dispatch({
      payload: buildMasterEmail(getState()),
      type: ComposeWindowActionTypes.bulkView.setMasterEmail,
    });
    const drafts = createDraftsForRecipients(
      formattedPeople,
      buildMasterEmail(getState())
    );
    dispatch(setDrafts(drafts));
  } catch (error) {
    dispatch(setComposeWindowAlerts([{ id: AlertIds.fetchGroupError }]));
  }
  dispatch(setBulkLoading(false));
  dispatch(setMainWindowLoading(false));
};

export const closeComposeWindow = () => (dispatch, getState) => {
  const {
    composeWindowState: { modalMode },
  } = getState();

  if (modalMode) {
    postMessageToParentWindow({ action: WindowEventNames.close });
    window.close();
    return;
  }

  dispatch({ type: ComposeWindowActionTypes.closeComposeWindowView });

  if (
    pitchIdExists(getState()) &&
    !(alreadySending(getState()) || alreadySent(getState()))
  ) {
    const draftSavedAlert = {
      id: GlobalBannerAlertsConstants.composeWindow.draftSavedOnClose,
    };
    dispatch(addGlobalBannerAlert(draftSavedAlert));
  }

  dispatch({ type: ComposeWindowActionTypes.resetEditorValue });
  dispatch({ type: ComposeWindowActionTypes.bulkView.resetBulkView });
  dispatch({ type: ComposeTemplatesActionTypes.resetTemplates });
  dispatch(setTemplatesMinimized(false));
  dispatch(resetComposeWindow());

  navigateToLastUrl();
};

const postSuccessSendEmailMessage = () => {
  postMessageToParentWindow({ action: WindowEventNames.sendEmail });
};

const setDrafts = (drafts) => ({
  payload: drafts,
  type: ComposeWindowActionTypes.bulkView.setDrafts,
});

const addDraft = (draft) => ({
  payload: draft,
  type: ComposeWindowActionTypes.bulkView.addDraft,
});

export const resetComposeWindow = () => ({
  type: ComposeWindowActionTypes.reset,
});

export const updateSingleDraftBody = (recipientId, body) => ({
  payload: { body, recipientId },
  type: ComposeWindowActionTypes.bulkView.updateSingleDraftBody,
});

export const updateSingleDraftSubject = (recipientId, subject) => ({
  payload: { recipientId, subject },
  type: ComposeWindowActionTypes.bulkView.updateSingleDraftSubject,
});

const addAttachmentIdsToSingleEmail = (recipientId, attachmentIds) => ({
  payload: { attachmentIds, recipientId },
  type: ComposeWindowActionTypes.bulkView.addAttachmentIdsToSingleEmail,
});

const removeAttachmentIdFromSingleEmail = (recipientId, attachmentId) => ({
  payload: { attachmentId, recipientId },
  type: ComposeWindowActionTypes.bulkView.removeAttachmentIdFromSingleEmail,
});

const addCcAndBccToSingleEmail = (recipientId, { address, key }) => ({
  payload: { address, key, recipientId },
  type: ComposeWindowActionTypes.bulkView.addCcAndBccToSingleEmail,
});

const updateSingleDraftIdentity = (recipientId, identity) => ({
  payload: { identity, recipientId },
  type: ComposeWindowActionTypes.bulkView.updateSingleDraftIdentity,
});

const updateSingleDraftTemplateId = (recipientId, templateId) => ({
  payload: { recipientId, templateId },
  type: ComposeWindowActionTypes.bulkView.updateSingleDraftTemplateId,
});

const removeCcAndBccFromSingleEmail = (recipientId, { index, key }) => ({
  payload: { index, key, recipientId },
  type: ComposeWindowActionTypes.bulkView.removeCcAndBccFromSingleEmail,
});

const updateAllDraftBodies = (body) => ({
  payload: body,
  type: ComposeWindowActionTypes.bulkView.updateAllDraftBodies,
});

const updateMasterEmailBody = (body) => ({
  payload: body,
  type: ComposeWindowActionTypes.bulkView.updateMasterEmailBody,
});

const updateAllDraftSubjects = (subject) => ({
  payload: subject,
  type: ComposeWindowActionTypes.bulkView.updateAllDraftSubjects,
});

const updateMasterEmailSubject = (subject) => ({
  payload: subject,
  type: ComposeWindowActionTypes.bulkView.updateMasterEmailSubject,
});

const addAttachmentIdsToMasterEmail = (attachmentIds) => ({
  payload: attachmentIds,
  type: ComposeWindowActionTypes.bulkView.addAttachmentIdsToMasterEmail,
});

const updateAllDraftAttachments = (attachmentIds) => ({
  payload: attachmentIds,
  type: ComposeWindowActionTypes.bulkView.updateAllDraftAttachments,
});

const removeAttachmentIdFromMasterEmail = (attachmentId) => ({
  payload: attachmentId,
  type: ComposeWindowActionTypes.bulkView.removeAttachmentIdFromMasterEmail,
});

const removeAttachmentIdFromAllDrafts = (attachmentId) => ({
  payload: attachmentId,
  type: ComposeWindowActionTypes.bulkView.removeAttachmentIdFromAllDrafts,
});

const addCcAndBccToAllDrafts = ({ address, key }) => ({
  payload: { address, key },
  type: ComposeWindowActionTypes.bulkView.addCcAndBccToAllDrafts,
});

const addCcAndBccToMasterEmail = ({ address, key }) => ({
  payload: { address, key },
  type: ComposeWindowActionTypes.bulkView.addCcAndBccToMasterEmail,
});

const removeCcAndBccFromAllDrafts = ({ index, key }) => ({
  payload: { index, key },
  type: ComposeWindowActionTypes.bulkView.removeCcAndBccFromAllDrafts,
});

const removeCcAndBccFromMasterEmail = ({ index, key }) => ({
  payload: { index, key },
  type: ComposeWindowActionTypes.bulkView.removeCcAndBccFromMasterEmail,
});

const updateAllDraftIdentities = (identity) => ({
  payload: identity,
  type: ComposeWindowActionTypes.bulkView.updateAllDraftIdentities,
});

const updateMasterEmailIdentity = (identity) => ({
  payload: identity,
  type: ComposeWindowActionTypes.bulkView.updateMasterEmailIdentity,
});

const updateAllDraftTemplateIds = (templateId) => ({
  payload: templateId,
  type: ComposeWindowActionTypes.bulkView.updateAllDraftTemplateIds,
});

const updateMasterEmailTemplateId = (templateId) => ({
  payload: templateId,
  type: ComposeWindowActionTypes.bulkView.updateMasterEmailTemplateId,
});

const setMasterEmailAttachmentIds = (attachmentIds) => ({
  payload: attachmentIds,
  type: ComposeWindowActionTypes.bulkView.setMasterEmailAttachmentIds,
});

const setAllDraftsAttachmentIds = (attachmentIds) => ({
  payload: attachmentIds,
  type: ComposeWindowActionTypes.bulkView.setAllDraftsAttachmentIds,
});

const setSingleDraftAttachmentIds = ({ attachmentIds, recipientId }) => ({
  payload: { attachmentIds, recipientId },
  type: ComposeWindowActionTypes.bulkView.setSingleDraftAttachmentIds,
});

const handleTemplateAttachments = ({ recipientId, templateId }) => async (
  dispatch
) => {
  let fileAttachments = [];
  if (templateId) {
    dispatch(setMainWindowLoading(true));
    dispatch(setBulkLoading(true));
    const response = await getTemplateDetails(templateId);
    dispatch(setMainWindowLoading(false));
    dispatch(setBulkLoading(false));
    fileAttachments = response.file_attachments || [];
  }

  const fileAttachmentIds = fileAttachments.map((file) => file.id);

  dispatch({
    payload: fileAttachments,
    type: ComposeWindowActionTypes.setAttachments,
  });

  if (!recipientId) {
    dispatch(setMasterEmailAttachmentIds(fileAttachmentIds));
    dispatch(setAllDraftsAttachmentIds(fileAttachmentIds));
  } else {
    dispatch(
      setSingleDraftAttachmentIds({
        attachmentIds: fileAttachmentIds,
        recipientId,
      })
    );
  }
};

const updateAllRecipientWithTemplate = (templateId) => async (dispatch) => {
  dispatch(updateAllDraftTemplateIds(templateId));
  dispatch(updateMasterEmailTemplateId(templateId));
  await dispatch(handleTemplateAttachments({ templateId }));
};

const updateMasterEmailSchedule = (sendAt) => ({
  payload: sendAt,
  type: ComposeWindowActionTypes.bulkView.updateMasterEmailSchedule,
});

const updateAllDraftSchedules = (sendAt) => ({
  payload: sendAt,
  type: ComposeWindowActionTypes.bulkView.updateAllDraftSchedules,
});

export const setBulkLoading = (loading) => ({
  payload: loading,
  type: ComposeWindowActionTypes.bulkView.setLoading,
});

export const bulkUpdateDrafts = (attribute, payload) => async (
  dispatch,
  getState
) => {
  const {
    composeBulkEmails: { currentlySelectedRecipient, group },
    composeWindowState: { bulkViewOpened, editorInstance },
  } = getState();

  if (bulkViewOpened) {
    if (currentlySelectedRecipient && !group) {
      const { id: recipientId } = currentlySelectedRecipient;

      switch (attribute) {
        case BulkEmails.body: {
          dispatch(updateSingleDraftBody(recipientId, payload));
          break;
        }
        case BulkEmails.subject: {
          dispatch(updateSingleDraftSubject(recipientId, payload));
          break;
        }
        case BulkEmails.attachmentAdd: {
          dispatch(addAttachmentIdsToSingleEmail(recipientId, payload));
          break;
        }
        case BulkEmails.attachmentRemove: {
          dispatch(removeAttachmentIdFromSingleEmail(recipientId, payload));
          break;
        }
        case BulkEmails.ccAndBccAdd: {
          dispatch(addCcAndBccToSingleEmail(recipientId, payload));
          break;
        }
        case BulkEmails.ccAndBccRemove: {
          dispatch(removeCcAndBccFromSingleEmail(recipientId, payload));
          break;
        }
        case BulkEmails.identity: {
          dispatch(updateSingleDraftIdentity(recipientId, payload));
          break;
        }
        case BulkEmails.template: {
          dispatch(updateSingleDraftTemplateId(recipientId, payload));
          await dispatch(
            handleSingleDraftTemplateAttachment(recipientId, payload)
          );
          dispatch(clearSingleRecipientFieldState(recipientId));
          break;
        }
        case BulkEmails.schedule: {
          dispatch(updateMasterEmailSchedule(payload));
          dispatch(updateAllDraftSchedules(payload));
          break;
        }
        default:
          break;
      }
    } else if (!currentlySelectedRecipient) {
      switch (attribute) {
        case BulkEmails.body: {
          dispatch(updateAllDraftBodies(payload));
          dispatch(updateMasterEmailBody(payload));
          editorInstance && editorInstance.setDirty(false);
          break;
        }
        case BulkEmails.subject: {
          dispatch(updateAllDraftSubjects(payload));
          dispatch(updateMasterEmailSubject(payload));
          break;
        }
        case BulkEmails.attachmentAdd: {
          dispatch(addAttachmentIdsToMasterEmail(payload));
          dispatch(updateAllDraftAttachments(payload));
          break;
        }
        case BulkEmails.attachmentRemove: {
          dispatch({
            payload,
            type:
              ComposeWindowActionTypes.bulkView
                .removeAttachmentIdFromMasterEmail,
          });
          dispatch(removeAttachmentIdFromMasterEmail(payload));
          dispatch(removeAttachmentIdFromAllDrafts(payload));
          break;
        }
        case BulkEmails.ccAndBccAdd: {
          dispatch(addCcAndBccToAllDrafts(payload));
          dispatch(addCcAndBccToMasterEmail(payload));
          break;
        }
        case BulkEmails.ccAndBccRemove: {
          dispatch(removeCcAndBccFromAllDrafts(payload));
          dispatch(removeCcAndBccFromMasterEmail(payload));
          break;
        }
        case BulkEmails.identity: {
          dispatch(updateAllDraftIdentities(payload));
          dispatch(updateMasterEmailIdentity(payload));
          break;
        }
        case BulkEmails.schedule: {
          dispatch(updateMasterEmailSchedule(payload));
          dispatch(updateAllDraftSchedules(payload));
          break;
        }
        default:
          break;
      }
    }

    if (!currentlySelectedRecipient || group) {
      switch (attribute) {
        case BulkEmails.template: {
          await dispatch(updateAllRecipientWithTemplate(payload));
          if (currentlySelectedRecipient) {
            dispatch(selectAllRecipients());
          }
          dispatch(clearAllRecipientFieldState());
          break;
        }
        default:
          break;
      }
    }
  }
};

export const setSlidingWindow = ({ startingWindow, endingWindow }) => ({
  endingWindow,
  startingWindow,
  type: ComposeWindowActionTypes.bulkView.setSlidingWindow,
});

export const resetSlidingWindow = () => ({
  type: ComposeWindowActionTypes.bulkView.resetSlidingWindow,
});

export const sortRecipients = (direction) => ({
  payload: direction,
  type: ComposeWindowActionTypes.bulkView.sortRecipients,
});

export const updateRecipientState = ({
  hasDynamicFieldErrors,
  recipientId,
}) => ({
  payload: { hasDynamicFieldErrors, recipientId },
  type: ComposeWindowActionTypes.bulkView.updateRecipientState,
});

const clearSingleRecipientFieldState = (recipientId) => ({
  payload: recipientId,
  type: ComposeWindowActionTypes.bulkView.clearSingleRecipientFieldState,
});

const clearAllRecipientFieldState = () => ({
  type: ComposeWindowActionTypes.bulkView.clearAllRecipientFieldState,
});

export const handleSingleDraftTemplateAttachment = (
  recipientId,
  templateId
) => async (dispatch) => {
  try {
    await dispatch(handleTemplateAttachments({ recipientId, templateId }));
  } catch (e) {
    dispatch(setComposeWindowAlerts([{ id: AlertIds.fetchDynamicFieldError }]));
  }
};

export const liquifyDynamicFields = () => async (dispatch, getState) => {
  const {
    composeBulkEmails: {
      currentlySelectedRecipient,
      drafts,
      recipients,
      masterEmail,
    },
  } = getState();

  if (currentlySelectedRecipient) {
    const draft = drafts[currentlySelectedRecipient.id];
    let { body, subject } = draft;

    if (hasDynamicFields(body, subject)) {
      dispatch(setMainWindowLoading(true));
      dispatch(setBulkLoading(true));

      try {
        const { liquifiedBody, liquifiedSubject } = await dispatch(
          getLiquifiedFields({
            draft,
            recipient: currentlySelectedRecipient,
          })
        );
        body = liquifiedBody;
        subject = liquifiedSubject;

        const hasDynamicFieldErrors = missingDynamicFieldsPresent({
          body,
          subject,
        });

        dispatch(
          updateRecipientState({
            hasDynamicFieldErrors,
            recipientId: currentlySelectedRecipient.id,
          })
        );

        dispatch(updateSubject(subject));
        dispatch(handleEditorChange(body));
      } catch (err) {
        dispatch(
          setComposeWindowAlerts([{ id: AlertIds.fetchDynamicFieldError }])
        );
      }

      dispatch(setBulkLoading(false));
      dispatch(setMainWindowLoading(false));
    }
  } else {
    if (
      hasUnfilledPrompts(masterEmail.subject) ||
      hasUnfilledPrompts(masterEmail.body)
    ) {
      dispatch(
        setComposeWindowAlerts([
          { id: AlertIds.unfilledPromptErrorInGroupSetting },
        ])
      );
      return;
    }
    const newDrafts = createDraftsForRecipients(recipients, masterEmail);
    dispatch(setBulkLoading(true));
    dispatch(setMainWindowLoading(true));
    const bulkLiquifierIdentifier = Date.now();
    dispatch({
      identifier: bulkLiquifierIdentifier,
      maximum: recipients.length,
      type: ComposeWindowActionTypes.liquifyInit,
    });

    try {
      await bulkLiquifiedFields(newDrafts, bulkLiquifierIdentifier);
    } catch (e) {
      dispatch(
        setComposeWindowAlerts([{ id: AlertIds.fetchDynamicFieldError }])
      );
    }
  }
};

const determineRecipientsState = () => (dispatch, getState) => {
  const {
    composeBulkEmails: { recipients, drafts },
  } = getState();

  const updatedRecipients = recipients.map((recipient) => {
    const { body, subject } = drafts[recipient.id];
    const updatedRecipient = { ...recipient };

    const hasDynamicFieldErrors = missingDynamicFieldsPresent({
      body,
      subject,
    });

    if (hasDynamicFieldErrors && !hasBlockedStates(updatedRecipient.state)) {
      updatedRecipient.state = BulkEmails.stateReason.invalidDynamicFields;
    } else if (
      !hasDynamicFieldErrors &&
      !hasBlockedStates(updatedRecipient.state)
    ) {
      updatedRecipient.state = BulkEmails.stateReason.success;
    }

    return updatedRecipient;
  });

  dispatch({
    payload: updatedRecipients,
    type: ComposeWindowActionTypes.bulkView.setRecipients,
  });
};

export const handleLiquifyProgress = ({ body, recipientId, subject }) => (
  dispatch,
  getState
) => {
  const {
    liquifyProgress: { active },
  } = getState();

  if (active) {
    dispatch(
      updateSingleDraftBody(recipientId, markMissingDynamicFields(body))
    );

    dispatch(updateSingleDraftSubject(recipientId, subject));

    dispatch({
      type: ComposeWindowActionTypes.incrementLiquifyProgress,
    });

    if (isLiquifyComplete(getState())) {
      dispatch(determineRecipientsState());
      dispatch({ type: ComposeWindowActionTypes.resetLiquifyProgress });
      dispatch(setMainWindowLoading(false));
      dispatch(setBulkLoading(false));
    }
  }
};

export const cancelLiquifyProgress = () => (dispatch) => {
  dispatch({ type: ComposeWindowActionTypes.resetLiquifyProgress });
  dispatch(setMainWindowLoading(false));
  dispatch(setBulkLoading(false));
};

export const handleEditorChange = (data) => (dispatch) => {
  dispatch({
    payload: data,
    type: ComposeWindowActionTypes.handleEditorChange,
  });

  dispatch(bulkUpdateDrafts(BulkEmails.body, data));
};

export const storeEditorInstance = (instance) => (dispatch) => {
  dispatch({
    payload: instance,
    type: ComposeWindowActionTypes.storeEditorInstance,
  });
};

export const setEditorFocused = (boolean) => (dispatch) => {
  dispatch({
    payload: boolean,
    type: ComposeWindowActionTypes.setEditorFocused,
  });
};

export const toggleBulkView = (viewState) => (dispatch) => {
  dispatch({
    payload: viewState,
    type: ComposeWindowActionTypes.toggleBulkEmailView,
  });
  dispatch({ type: ComposeTemplatesActionTypes.hideRecommendedTemplates });
};

export const setMainWindowLoading = (isLoading) => ({
  payload: isLoading,
  type: ComposeWindowActionTypes.setLoading,
});

export const bulkViewChangeHandoff = () => async (dispatch, getState) => {
  const {
    composeAddresses,
    composeBulkEmails: { recipients },
    composeWindowState: { bulkViewOpened },
  } = getState();

  if (bulkViewOpened) {
    dispatch({
      payload: buildMasterEmail(getState()),
      type: ComposeWindowActionTypes.bulkView.setMasterEmail,
    });

    dispatch(setAddresses({ addresses: [], key: AddressTypes.to }));

    await dispatch(
      setRecipients(
        composeAddresses.to.map((address) => ({
          ...address.person,
          unsubscribed: address.salesUnsubscribe,
        }))
      )
    );
  } else {
    dispatch(setAddresses({ addresses: [], key: AddressTypes.to }));
    recipients.length &&
      (await dispatch(
        createNewAddress({
          address: {
            addressableId: recipients[0].id,
            email: getPrimaryEmailAddress(recipients[0]).address,
            type: ElasticSearchObject.person,
            validated: true,
          },
          key: AddressTypes.to,
        })
      ));
    dispatch({ type: ComposeWindowActionTypes.bulkView.resetBulkView });
  }
};

export const setMinimizeView = ({ bulkViewOpened, minimized }) => ({
  payload: {
    bulkViewOpened,
    minimized,
  },
  type: ComposeWindowActionTypes.setMinimizeView,
});

export const setRecipients = (recipients) => async (dispatch, getState) => {
  const recipientsEmailDomains = recipients.map((recipient) =>
    getPrimaryEmailAddressDomain(recipient)
  );

  try {
    dispatch(setBulkLoading(true));
    const domains = await checkBlockedDomains(recipientsEmailDomains);
    const mappedRecipients = recipients.map((recipient) => {
      const name = getPersonName(recipient);
      const unsubscribeState = getUnsubscribeState(recipient, domains);

      return {
        ...recipient,
        emailAddress: getPrimaryEmailAddress(recipient).address,
        name,
        state: unsubscribeState,
      };
    });

    dispatch(setAllAddresses());

    dispatch(toggleBulkView(true));
    dispatch({
      payload: mappedRecipients,
      type: ComposeWindowActionTypes.bulkView.setRecipients,
    });

    const drafts = createDraftsForRecipients(
      mappedRecipients,
      buildMasterEmail(getState())
    );
    dispatch(setDrafts(drafts));
  } catch (err) {
    dispatch(setComposeWindowAlerts([{ id: AlertIds.setRecipientsError }]));
  }
  dispatch(setBulkLoading(false));
};

export const setComposeReadOnly = (payload) => ({
  payload,
  type: ComposeWindowActionTypes.setComposeReadOnly,
});

const getLiquifiedFields = ({
  draft: { body, email, identity, subject },
  recipient,
}) => async (dispatch) => {
  const response = await getLiquifiedFieldsCall({
    body,
    email,
    identityId: identity.id,
    recipientId: recipient.id,
    subject,
  });
  const liquifiedBody = markMissingDynamicFields(response.body);
  const liquifiedSubject = response.subject;

  const hasDynamicFieldErrors = missingDynamicFieldsPresent({
    body: liquifiedBody,
    subject: liquifiedSubject,
  });
  dispatch(
    updateRecipientState({
      hasDynamicFieldErrors,
      recipientId: recipient.id,
    })
  );

  return { liquifiedBody, liquifiedSubject };
};

export const selectCurrentRecipient = (recipient) => async (
  dispatch,
  getState
) => {
  const {
    composeBulkEmails: { drafts = {}, group = null },
    content,
  } = getState();
  const draft = drafts[recipient.id];
  const {
    attachmentIds,
    body,
    cc,
    bcc,
    identity,
    sendAt,
    subject,
    templateId,
  } = draft;

  dispatch({
    payload: recipient,
    type: ComposeWindowActionTypes.bulkView.selectCurrentRecipient,
  });

  dispatch({
    payload: mapIdsToAttachments(attachmentIds, content),
    type: ComposeWindowActionTypes.setAttachments,
  });
  dispatch(updateSubject(subject));
  dispatch(handleEditorChange(body));

  dispatch(setAddresses({ addresses: cc, key: AddressTypes.cc }));
  dispatch(setAddresses({ addresses: bcc, key: AddressTypes.bcc }));
  dispatch(
    setAddresses({
      addresses: [
        {
          addressableId: recipient.id,
          email: getPrimaryEmailAddress(recipient).address,
          state: recipient.state,
          validated: true,
        },
      ],
      key: AddressTypes.to,
    })
  );
  dispatch(setPitchSendAt(sendAt));
  dispatch(setIdentity(identity));
  dispatch(setSelectedTemplateWithId(templateId));

  const disableSingleEditsForGroupFlow = recipient && group;

  if (disableSingleEditsForGroupFlow) {
    dispatch(setComposeReadOnly(true));
  }
};

export const handleSelectAllRecipients = () => (dispatch, getState) => {
  const {
    userSettings: { disable_notify_template_edits: disableNotifyTemplateEdits },
    composeWindowState: { editorInstance },
  } = getState();
  const isEdited = editorInstance && editorInstance.isDirty();
  if (!disableNotifyTemplateEdits && isEdited) {
    dispatch(
      notifyComposeEdits('web.composeWindow.clearEditsModal.bulkMessage', () =>
        dispatch(selectAllRecipients())
      )
    );
  } else {
    dispatch(selectAllRecipients());
  }
};

export const selectAllRecipients = () => (dispatch, getState) => {
  const {
    composeBulkEmails: {
      masterEmail: { body, subject, attachmentIds, cc, bcc, identity, sendAt },
    },
    composeWindowState: { editorInstance },
    content,
  } = getState();

  dispatch(setIdentity(identity));
  dispatch({
    payload: null,
    type: ComposeWindowActionTypes.bulkView.selectCurrentRecipient,
  });
  dispatch({
    payload: body,
    type: ComposeWindowActionTypes.handleEditorChange,
  });
  dispatch({
    subject,
    type: AddressBarActionTypes.updateSubject,
  });
  dispatch({
    payload: mapIdsToAttachments(attachmentIds, content),
    type: ComposeWindowActionTypes.setAttachments,
  });
  dispatch(setAddresses({ addresses: cc, key: AddressTypes.cc }));
  dispatch(setAddresses({ addresses: bcc, key: AddressTypes.bcc }));
  dispatch(setAddresses({ addresses: [], key: AddressTypes.to }));
  dispatch(setAllAddresses());
  dispatch(setPitchSendAt(sendAt));
  dispatch(setComposeReadOnly(false));
  editorInstance && editorInstance.setDirty(false);
};

export const removeRecipient = (recipient) => (dispatch, getState) => {
  const {
    composeBulkEmails: { recipients },
  } = getState();

  if (recipients.length > 1) {
    dispatch({
      payload: recipient,
      type: ComposeWindowActionTypes.bulkView.removeRecipient,
    });
  } else {
    dispatch(removeAllRecipients());
  }
};

export const removeAllRecipients = () => (dispatch) => {
  dispatch({ type: ComposeWindowActionTypes.bulkView.clearRecipients });
  dispatch({
    addresses: [],
    key: AddressTypes.to,
    type: AddressBarActionTypes.setAddresses,
  });
  dispatch({ group: null, type: ComposeWindowActionTypes.bulkView.setGroup });
  dispatch(setComposeReadOnly(false));
};

export const addRecipient = (recipient) => async (dispatch, getState) => {
  const {
    composeBulkEmails: { masterEmail, searchValue },
  } = getState();

  dispatch(setMainWindowLoading(true));
  dispatch(setBulkLoading(true));

  if (recipient && recipient.es_document_type === AddressableTypes.group) {
    const groupId = recipient.id;
    await dispatch(groupComposeInit(groupId));
  } else {
    dispatch({
      payload: '',
      type: ComposeWindowActionTypes.bulkView.setSearchValue,
    });

    dispatch({
      type: ComposeWindowActionTypes.bulkView.searchResultClear,
    });

    let formattedRecipient;
    let recipientEmailAddress;
    let isPersonFoundOrCreated = true;
    if (recipient) {
      recipientEmailAddress = recipient.primary_addresses.email;

      formattedRecipient = {
        ...recipient,
        emailAddress: recipientEmailAddress,
        name: getPersonName(recipient),
        state: getUnsubscribeState(recipient),
        validated: true,
      };
    } else {
      try {
        const response = await createFindPersonWithAddress({
          email_address: searchValue,
        });
        formattedRecipient = {
          ...response.person,
          emailAddress: searchValue,
          name: searchValue,
          validated: true,
        };
        recipientEmailAddress = searchValue;
      } catch (e) {
        // Add here next?
        isPersonFoundOrCreated = false;

        const { error } = e.response.body;
        if (error === ApiErrors.addNewPeopleDisabled) {
          dispatch(
            setComposeWindowAlerts([{ id: AlertIds.addNewPeopleDisabled }])
          );
        }
      }
    }

    if (isPersonFoundOrCreated) {
      try {
        const domains = await checkBlockedDomains([
          getPrimaryEmailAddressDomain(formattedRecipient),
        ]);

        if (domains.length > 0) {
          formattedRecipient.state = BulkEmails.stateReason.blocked;
        }
      } catch {
        dispatch(setComposeWindowAlerts([{ id: AlertIds.setRecipientsError }]));
      }

      dispatch({
        payload: formattedRecipient,
        type: ComposeWindowActionTypes.bulkView.addRecipient,
      });

      const draft = {
        [formattedRecipient.id]: {
          ...masterEmail,
          email: recipientEmailAddress,
        },
      };

      dispatch(addDraft(draft));
    }
  }

  dispatch(setBulkLoading(false));
  dispatch(setMainWindowLoading(false));
};

export const searchPeople = (value) => (dispatch) => {
  dispatch({
    payload: value,
    type: ComposeWindowActionTypes.bulkView.setSearchValue,
  });

  if (value) {
    searchPeopleCall(value).then((response) => {
      dispatch({
        payload: response.results,
        type: ComposeWindowActionTypes.bulkView.searchPeople,
      });
    });
  }
};

export const bulkSendPitch = () => (dispatch, getState) => {
  const {
    composeBulkEmails: { drafts },
  } = getState();

  dispatch(setPitchState(PitchStates.sending));

  const formattedDrafts = formatDraftsForSend(drafts);

  bulkSendPitchCall(formattedDrafts)
    .then(() => {
      postSuccessSendEmailMessage();
      dispatch(closeComposeWindow());
      const pitchTemplateId = Object.values(formattedDrafts)[0].templateId;
      if (pitchTemplateId) {
        addRecentlyUsedTemplates(pitchTemplateId);
      }

      dispatch(
        addGlobalBannerAlert({
          id: GlobalBannerAlertsConstants.composeWindow.bulkSuccess,
          textValues: {
            pitchCount: Object.keys(formattedDrafts).length,
          },
        })
      );
    })
    .catch((err) => {
      const { body, statusCode } = err.response;

      dispatch(setSendFlag(false));
      dispatch(setPitchState(PitchStates.unsaved));

      if (statusCode === 403) {
        dispatch(
          setComposeWindowAlerts([
            {
              id: AlertIds.emailLimit,
              textValues: {
                remainingEmailsCount: body.email_limits.current_limit,
              },
            },
          ])
        );
      } else {
        dispatch(
          addGlobalBannerAlert({
            id: GlobalBannerAlertsConstants.composeWindow.bulkFail,
          })
        );
      }
    });
};

export const searchPeopleLeave = () => ({
  type: ComposeWindowActionTypes.bulkView.searchResultClear,
});

export const setPitchSendAt = (sendAt) => (dispatch) => {
  dispatch({
    sendAt,
    type: ComposeWindowActionTypes.setPitchSendAt,
  });

  dispatch(bulkUpdateDrafts(BulkEmails.schedule, sendAt));
};

export const setComposeWindowAlerts = (alerts) => ({
  payload: { alerts },
  type: ComposeWindowActionTypes.setAlerts,
});

export const updateValidationErrors = (errors) => ({
  errors,
  type: ComposeWindowActionTypes.updateValidationErrors,
});

export const addAlert = (id) => ({
  payload: { id },
  type: ComposeWindowActionTypes.addAlert,
});

export const closeAlertById = (id) => ({
  payload: { id },
  type: ComposeWindowActionTypes.closeAlertById,
});

export const clearComposeWindowAlerts = () => ({
  type: ComposeWindowActionTypes.clearAlerts,
});

export const updateTemplate = (isTemplateDirtyChanges = false) => async (
  dispatch,
  getState
) => {
  const {
    selectedTemplate,
    composeWindowState: { editorInstance },
  } = getState();
  if (!selectedTemplate) return;

  if (isTemplateDirtyChanges) {
    dispatch(liquifyTemplateWithDirtyChanges());
    return;
  }

  let template = { ...selectedTemplate };
  const addressId = getOneOffAddressId(getState());
  const { id: identityId, signature } = getIdentity(getState());
  template.body = appendSignatureToBody(template.body, signature);

  if (addressId) {
    try {
      dispatch(setComposeTemplatesLoading(true));
      const query = {
        addressId,
        identityId,
        signature: true,
        templateId: template.id,
      };
      template = await fetchFilledTemplate(query);
      // TL: I'm not exactly sure what the use case of this is.
      // So for now, commenting out as it breaks the unsubscribe message enhancements
      // const existingContent = editorInstance.getContent();
      // if (existingContent) {
      //   const splittedEmailBySigTag = existingContent.split(END_SIG_TAG) || [];
      //   if (splittedEmailBySigTag.length > 1) {
      //     const appendedEmail = splittedEmailBySigTag.slice(1).join(''); // cut off user changes from appended email
      //     template.body =
      //       appendSignatureToBody(template.body, signature) +
      //       END_SIG_TAG +
      //       appendedEmail;
      //   }
      // }

      dispatch(handleMissingDynamicFields(template));
    } catch (err) {
      dispatch(addAlert(AlertIds.filledTemplateFetchError));
    } finally {
      dispatch(setComposeTemplatesLoading(false));
    }
  } else {
    try {
      dispatch(setComposeTemplatesLoading(true));
      const fetchedTemplate = await fetchTemplate(template.id);
      template.file_attachments = fetchedTemplate.file_attachments;
    } catch (err) {
      dispatch(addAlert(AlertIds.templateFetchError));
    } finally {
      dispatch(setComposeTemplatesLoading(false));
    }
  }
  await dispatch(bulkUpdateDrafts(BulkEmails.template, selectedTemplate.id));
  dispatch(updateComposeWithTemplate(template));
};

const liquifyTemplateWithDirtyChanges = () => async (dispatch, getState) => {
  const masterEmail = buildMasterEmail(getState());
  const email = getOneOffEmail(getState());
  const personId = getOneOffPersonId(getState());

  const {
    selectedTemplate,
    composeWindowState: { editorInstance },
  } = getState();

  try {
    dispatch(setComposeTemplatesLoading(true));

    const {
      body,
      subject,
      identity: { id: identityId },
    } = masterEmail;

    const response = await getLiquifiedFieldsCall({
      body,
      email,
      identityId,
      recipientId: personId,
      subject,
    });

    const template = {
      ...selectedTemplate,
      body: response.body,
      subject: response.subject,
    };

    dispatch(updateComposeWithTemplate(template));
  } catch (err) {
    dispatch(addAlert(AlertIds.filledTemplateFetchError));
  }

  editorInstance && editorInstance.setDirty(false);
  dispatch(setComposeTemplatesLoading(false));
};

export const handleMissingDynamicFields = (template) => (dispatch) => {
  if (missingDynamicFieldsPresent(template)) {
    dispatch(addAlert(AlertIds.missingDynamicFields));
    template.body = markMissingDynamicFields(template.body);
  }
};

export const updateSignature = () => (dispatch, getState) => {
  const { composeEditorContent } = getState();
  const { signature } = getIdentity(getState());
  const newBody = replaceSignatureInBody(composeEditorContent, signature);
  dispatch(handleEditorChange(newBody));
};

export const updateComposeWithTemplate = ({
  cc,
  bcc,
  body,
  subject,
  file_attachments: fileAttachments,
}) => (dispatch) => {
  dispatch(populateCCAndBccFields(cc, bcc));
  dispatch(updateSubject(subject));
  dispatch(handleEditorChange(body));
  dispatch(addAttachmentToEmail(fileAttachments, COMPOSE_EDITOR_ID));
};

export const resetComposeContent = () => (dispatch) => {
  dispatch(updateSubject(''));
  dispatch(clearCCAndBccFields());
  dispatch({
    type: ComposeWindowActionTypes.resetEditorValue,
  });
  dispatch(clearEmailAttachments());
  dispatch(updateSignature());
  dispatch(bulkUpdateDrafts(BulkEmails.template, null));
};

// Pitch send and save handling
export const setSendFlag = (boolean) => ({
  boolean,
  type: ComposeWindowActionTypes.setSend,
});

export const setPostCreateRetryFlag = (boolean) => ({
  boolean,
  type: ComposeWindowActionTypes.setSave,
});

export const setPitchId = (id) => ({
  id,
  type: ComposeWindowActionTypes.setPitchId,
});

export const setPitchState = (pitchState) => ({
  pitchState,
  type: ComposeWindowActionTypes.setPitchState,
});

export const setPitchInfo = (pitchInfo) => ({
  pitchInfo,
  type: ComposeWindowActionTypes.setPitchInfo,
});

export const intendSendPitch = () => (dispatch) => {
  dispatch(setSendFlag(true));
  dispatch(singleDebounceSaveOrSend());
};

// Need one instance of the debounced function, and this prevents errors on initial call
export const singleDebounceSaveOrSend = () => saveOrSendPitchFunc;

const saveOrSendPitchFunc = debounce((dispatch, getState) => {
  if (alreadySending(getState()) || alreadySent(getState())) {
    return;
  }

  if (currentlyCreatingPitch(getState())) {
    dispatch(setPostCreateRetryFlag(true));
  } else if (shouldSendPitch(getState())) {
    dispatch(triggerSendPitch());
  } else if (shouldSavePitch(getState())) {
    dispatch(triggerSavePitch());
  } else {
    const pitchErrors = getPitchAlerts(getState());
    if (pitchErrors.length > 0 && userRequestedSend(getState())) {
      dispatch(setComposeWindowAlerts(pitchErrors));
      dispatch(setSendFlag(false));
      dispatch(setPitchState(PitchStates.errored));
      dispatch(updateValidationErrors(getValidationFailures(getState())));
    } else {
      dispatch(setPitchState(PitchStates.unsaved));
    }
  }
}, SAVING_DEBOUNCE_MS);

export const triggerSavePitch = () => (dispatch, getState) => {
  if (shouldCreatePitch(getState())) {
    dispatch(saveInitialPitch());
  } else {
    dispatch(saveUpdatedPitch());
  }
};

export const triggerSendPitch = () => (dispatch, getState) => {
  const pitchErrors = getPitchAlerts(getState());

  if (pitchErrors.length > 0) {
    dispatch(setComposeWindowAlerts(pitchErrors));
    dispatch(setSendFlag(false));
    dispatch(setPitchState(PitchStates.errored));
    dispatch(updateValidationErrors(getValidationFailures(getState())));
    return;
  }

  if (shouldCreatePitch(getState())) {
    dispatch(handleSendNewPitch());
  } else {
    dispatch(handleSendPitchWithId());
  }
};

const handlePitchSendError = (err) => (dispatch) => {
  const {
    body: { base: errors = [] },
  } = err.response;

  if (isLimitError(errors)) {
    dispatch(setComposeWindowAlerts([{ id: AlertIds.emailLimitSingle }]));
  }
  if (isBlockedError(errors)) {
    dispatch(setComposeWindowAlerts([{ id: AlertIds.singleFlowUnsubscribes }]));
  }

  dispatch(setSendFlag(false));
  dispatch(setPitchState(PitchStates.errored));
};

export const handleSendNewPitch = () => (dispatch, getState) => {
  const pitchParams = buildPitchData(getState());
  const timeZone = getLocalizedTimezone(getState());
  dispatch(setPitchState(PitchStates.sending));
  sendNewPitch(pitchParams)
    .then((response) => {
      const pitchTemplateId = pitchParams.pitch.pitch_template_id;
      if (pitchTemplateId) {
        addRecentlyUsedTemplates(pitchTemplateId);
      }
      dispatch(setPitchId(response.id));
      dispatch(setSendFlag(false));
      dispatch(setPitchState(PitchStates.sent));
      postSuccessSendEmailMessage();
      dispatch(closeComposeWindow());

      if (pitchParams.schedule) {
        const postSendScheduledAlert = {
          id: GlobalBannerAlertsConstants.composeWindow.scheduledEmailSent,
          textValues: {
            dateTime: formatTimeStringScheduledEmails(
              pitchParams.send_at,
              timeZone
            ),
          },
        };
        dispatch(addGlobalBannerAlert(postSendScheduledAlert));
      } else {
        const postSendAlert = {
          id: GlobalBannerAlertsConstants.composeWindow.emailSent,
          textValues: { email: response.address.address },
        };
        dispatch(addGlobalBannerAlert(postSendAlert));
      }
    })
    .catch((err) => {
      dispatch(handlePitchSendError(err));
    });
};

export const handleSendPitchWithId = () => (dispatch, getState) => {
  const pitchParams = buildPitchData(getState());
  const timeZone = getLocalizedTimezone(getState());
  dispatch(setPitchState(PitchStates.sending));
  sendPitch(getPitchId(getState()), buildPitchData(getState()))
    .then((response) => {
      const pitchTemplateId = pitchParams.pitch.pitch_template_id;
      if (pitchTemplateId) {
        addRecentlyUsedTemplates(pitchTemplateId);
      }
      dispatch(setPitchState(PitchStates.sent));
      postSuccessSendEmailMessage();
      dispatch(closeComposeWindow());
      if (pitchParams.schedule) {
        const postSendScheduledAlert = {
          id: GlobalBannerAlertsConstants.composeWindow.scheduledEmailSent,
          textValues: {
            dateTime: formatTimeStringScheduledEmails(
              response.send_at,
              timeZone
            ),
          },
        };
        dispatch(addGlobalBannerAlert(postSendScheduledAlert));
      } else {
        const postSendAlert = {
          id: GlobalBannerAlertsConstants.composeWindow.emailSent,
          textValues: { email: response.address.address },
        };
        dispatch(addGlobalBannerAlert(postSendAlert));
      }
    })
    .catch((err) => {
      dispatch(handlePitchSendError(err));
    });
};

export const saveInitialPitch = () => (dispatch, getState) => {
  const pitchParams = buildPitchData(getState());
  dispatch(setPitchState(PitchStates.creating));
  createPitch(pitchParams)
    .then((response) => {
      dispatch(setPitchId(response.id));
      dispatch(setPitchState(PitchStates.saved));
      if (retryPostCreate(getState())) {
        dispatch(setPostCreateRetryFlag(false));
        dispatch(singleDebounceSaveOrSend());
      }
    })
    .catch((err) => {
      dispatch(setPitchState(PitchStates.unsaved));
      dispatch(handlePitchSendError(err));
    });
};

export const saveUpdatedPitch = () => (dispatch, getState) => {
  const pitchParams = buildPitchData(getState());
  dispatch(setPitchState(PitchStates.saving));
  updatePitch(getPitchId(getState()), pitchParams)
    .then(() => {
      dispatch(setPitchState(PitchStates.saved));
    })
    .catch((err) => {
      dispatch(setPitchState(PitchStates.unsaved));
      dispatch(handlePitchSendError(err));
    });
};

export const saveOrSendWrapper = (ac) => (...args) => (dispatch, getState) => {
  const {
    composeBulkEmails: { currentlySelectedRecipient, group },
    composeWindowState: { bulkViewOpened },
  } = getState();
  const isGroupEmail = !!group;

  dispatch(ac(...args));

  if (!bulkViewOpened || (isGroupEmail && !currentlySelectedRecipient)) {
    dispatch(singleDebounceSaveOrSend());
  }
};

export const wrappedHandleEditorChange = saveOrSendWrapper(handleEditorChange);
export const wrappedUpdateSubject = saveOrSendWrapper(updateSubject);
export const wrappedUpdateIdentity = saveOrSendWrapper(updateIdentity);
export const wrappedSetAddresses = saveOrSendWrapper(setAddresses);
export const wrappedAppendAddress = saveOrSendWrapper(appendAddress);
export const wrappedRemoveAddressByIndex = saveOrSendWrapper(
  removeAddressByIndex
);
export const wrappedUpdateAddressByEmail = saveOrSendWrapper(
  updateAddressByEmail
);
export const wrappedSetPitchSendAt = saveOrSendWrapper(setPitchSendAt);

export const setAsModalMode = () => ({
  type: ComposeWindowActionTypes.setAsModalMode,
});
