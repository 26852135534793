import ActionTypes from 'web/libs/actionTypes/actionTypes';
import {
  GlobalAlertIds,
  GlobalPopupIds,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { setPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import { ApiErrors } from 'web/libs/constants';

export function warnDataLoss(shouldClear) {
  return (dispatch, getState) => {
    if (shouldClear) {
      dispatch({
        type: ActionTypes.campaignsTab.state.warnLosingData,
        textId: '',
      });
    } else {
      let textId;
      switch (getState().popup) {
        case GlobalPopupIds.previewEmail:
        case GlobalPopupIds.scheduleLaunch:
          textId = 'web.campaigns.dataLossPreviews';
          break;
        default:
          textId = 'web.campaigns.dataLossYourData';
          break;
      }
      dispatch({ type: ActionTypes.campaignsTab.state.warnLosingData, textId });
    }
  };
}

export function defaultCatchAlert(
  err = {},
  defaultAlert = GlobalAlertIds.genericApiError
) {
  return (dispatch) => {
    if (err.rawResponse === ApiErrors.unverified) {
      // response is a string, not valid json
      dispatch(setPopupAlert(GlobalAlertIds.unverifiedUser));
    } else {
      dispatch(setPopupAlert(defaultAlert));
    }
  };
}
