import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonStatus } from 'libs/constantsShared';
import './button.scss';

export default class Button extends Component {
  render() {
    const {
      type,
      classes,
      active,
      status,
      click,
      children,
      size,
      ...other
    } = this.props;

    const componentClasses = classNames(
      classes,
      'buttonTout',
      type,
      size || '',
      {
        //todo eventually set default
        active,
        disabled: status === ButtonStatus.disabled,
        hidden: status === ButtonStatus.hidden,
        inactive: status === ButtonStatus.inactive,
      }
    );
    //todo turn off :active after click (mouseup) - ie blur

    return (
      <button
        className={componentClasses}
        disabled={status === ButtonStatus.disabled}
        onClick={click || this._defaultClick}
        type="submit"
        {...other}
      >
        {children}
      </button>
    );
  }

  _defaultClick() {}
}

Button.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.string,
  click: PropTypes.func,
  id: PropTypes.string,
  size: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  active: false,
  children: null,
  classes: '',
  click: null,
  id: '',
  size: '',
  status: '',
  type: 'submit',
};
