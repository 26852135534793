import sanitizeHtml from 'sanitize-html';

function getFirstAddress(addresses = []) {
  const supportedAddressTypes = ['email', 'linkedin', 'twitter'];
  for (let i = 0; i < addresses.length; i++) {
    if (
      addresses[i] &&
      supportedAddressTypes.indexOf(addresses[i].address_type) > -1
    ) {
      return addresses[i];
    }
  }
  return null;
}

// Use when you only need the user's person id
export function parseAutocompleteContacts(results = []) {
  const returnResults = [];

  for (let i = 0; i < results.length; i++) {
    const firstAddress = getFirstAddress(results[i].addresses);
    if (firstAddress && results[i].id) {
      returnResults.push({
        id: results[i].id,
        externalReferences: results[i].external_references || [],
        leadFields: results[i].lead_fields || [],
        firstName: results[i].first_name || '',
        lastName: results[i].last_name || '',
        title: results[i].title || '',
        sentTotal: results[i].pitches_sent || 0,
        companyName: results[i].company ? results[i].company.name : '',
        address: firstAddress.address || '',
        addressLocation: firstAddress.location || '',
        addressId: firstAddress.id || '',
      });

      if (returnResults.length >= 3) {
        return returnResults;
      }
    }
  }

  return returnResults;
}

// Use when you need the user to decide the email address, not just people id
export function parseAutocompleteContactsMulti(results = []) {
  const supportedAddressTypes = ['email', 'linkedin', 'twitter'];
  const returnResults = [];

  for (let i = 0; i < results.length; i++) {
    for (let j = 0; j < results[i].addresses.length; j++) {
      if (
        results[i].addresses[j] &&
        supportedAddressTypes.indexOf(results[i].addresses[j].address_type) > -1
      ) {
        returnResults.push({
          id: results[i].id,
          externalReferences: results[i].external_references || [],
          leadFields: results[i].lead_fields || [],
          firstName: results[i].first_name || '',
          lastName: results[i].last_name || '',
          title: results[i].title || '',
          sentTotal: results[i].pitches_sent || 0,
          companyName: results[i].company ? results[i].company.name : '',
          address: results[i].addresses[j].address || '',
          addressLocation: results[i].addresses[j].location || '',
          addressId: results[i].addresses[j].id || '',
        });

        if (returnResults.length >= 3) {
          return returnResults;
        }
      }
    }
  }

  return returnResults;
}

/* Use with sanitized HTML as this is still dangerous */
export const htmlToText = (text = '') => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = sanitizeHtml(text);
  return tmp.textContent || '';
};
