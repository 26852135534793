import { BULK_ACTIONS } from 'web/marketoSettings/libs/marketoSettingsConstants';
import MarketoSettingsActionTypes from '../libs/marketoSettingsActionTypes';
import { EveryoneTeamName } from '../libs/marketoSettingsConstants';
import {
  getSubscriptionUsers,
  grantAccess,
  revokeAccess,
  bulkUpdateAsync as bulkUpdateAsyncCall,
} from '../services/integrationConnection';
import { initializeBulkUpdate } from 'web/bulkUpdate/actionCreators/bulkUpdateProgressActionCreators';
import {
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import MarketoAdminPopupIds from 'web/marketoSettings/libs/marketoAdminPopupIds';
import { closePopup } from './adminPopupActionCreators';
import { getIdsToActOn, toNumArray } from '../services/authHelpers';

export const ASYNC_THRESHOLD = 10;

export const modalTitleIdMap = {
  [BULK_ACTIONS.create]: 'web.marketoSettings.teamAccess.grantAccess',
  [BULK_ACTIONS.destroy]: 'web.marketoSettings.teamAccess.revokeAccess',
};

export const getUsers = () => (dispatch, getState) => {
  dispatch({
    type: MarketoSettingsActionTypes.userAccess.getSubscriptionUsers,
  });
  const everyoneTeamId = getState().user.subscription.teams.find(
    (team) => team.name === EveryoneTeamName
  ).id;
  getSubscriptionUsers(everyoneTeamId).then((users = []) =>
    dispatch({
      type: MarketoSettingsActionTypes.userAccess.getSubscriptionUsersSuccess,
      users,
    })
  );
};

const setInitialStatus = (userIds, action) => (dispatch) => {
  dispatch({
    type:
      action === BULK_ACTIONS.create
        ? MarketoSettingsActionTypes.userAccess.grantAccess
        : MarketoSettingsActionTypes.userAccess.revokeAccess,
    userIds,
  });
};

const setFinalStatus = (userIds, failedUserIds, action) => (dispatch) => {
  dispatch({
    type:
      action === BULK_ACTIONS.create
        ? MarketoSettingsActionTypes.userAccess.grantAccessSuccess
        : MarketoSettingsActionTypes.userAccess.revokeAccessSuccess,
    userIds,
  });

  if (failedUserIds.length) {
    dispatch({
      type:
        action === BULK_ACTIONS.create
          ? MarketoSettingsActionTypes.userAccess.grantAccessFail
          : MarketoSettingsActionTypes.userAccess.revokeAccessFail,
      userIds: failedUserIds,
    });
    if (userIds.length) {
      dispatch({
        type:
          action === BULK_ACTIONS.create
            ? MarketoSettingsActionTypes.userAccess.grantAccessSomeFailed
            : MarketoSettingsActionTypes.userAccess.revokeAccessSomeFailed,
      });
    }
  }
};

export const bulkUpdateCompleteAsync = (
  clearSelectedIds,
  idsToActOn,
  action
) => (dispatch, getState) => {
  const {
    bulkUpdateResults: { successes },
  } = getState();
  if (clearSelectedIds) {
    dispatch(clearSelectedIds());
  }
  const userIds = toNumArray(successes);
  const failedUserIds = idsToActOn.filter((id) => !userIds.includes(id));

  dispatch(setFinalStatus(userIds, failedUserIds, action));
};

const actionBulkUpdateAsync = (
  userIds,
  workspaces,
  action,
  clearSelectedIds
) => (dispatch, getState) => {
  const authUserIds = getState().marketoAuthenticationUserIds;
  const idsToActOn = getIdsToActOn(action, authUserIds, userIds);
  if (!idsToActOn.length) {
    dispatch(closePopup());
    return;
  }

  dispatch(setPopupLoading(true));
  dispatch(setInitialStatus(idsToActOn, action));
  bulkUpdateAsyncCall(userIds, workspaces, action)
    .then(({ job_id: jobId }) => {
      const modalTitleId = modalTitleIdMap[action] || {};

      dispatch(setPopupLoading(false));
      dispatch(
        initializeBulkUpdate(
          jobId,
          null,
          modalTitleId,
          bulkUpdateCompleteAsync.bind(
            this,
            clearSelectedIds,
            idsToActOn,
            action
          )
        )
      );
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(MarketoAdminPopupIds.asyncUpdateError));
    });
};

export const actionBulkUpdateSync = (
  selectedIds,
  workspaces,
  action,
  clearSelectedIds
) => (dispatch, getState) => {
  const authUserIds = getState().marketoAuthenticationUserIds;
  const idsToActOn = getIdsToActOn(action, authUserIds, selectedIds);
  if (!idsToActOn.length) {
    dispatch(closePopup());
    return;
  }

  dispatch(setPopupLoading(true));
  dispatch(setInitialStatus(idsToActOn, action));

  const actionService =
    action === BULK_ACTIONS.create ? grantAccess : revokeAccess;
  actionService(idsToActOn, workspaces)
    .then((data = { errors: [], successes: [] }) => {
      const userIds = toNumArray(data.successes);
      const failedUserIds = toNumArray(data.errors);

      dispatch(setFinalStatus(userIds, failedUserIds, action));
      dispatch(setPopupLoading(false));
      dispatch(closePopup());

      if (clearSelectedIds) {
        dispatch(clearSelectedIds());
      }
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(closePopup());
      dispatch({
        type:
          action === BULK_ACTIONS.create
            ? MarketoSettingsActionTypes.userAccess.grantAccessFail
            : MarketoSettingsActionTypes.userAccess.revokeAccessFail,
        userIds: idsToActOn,
      });
    });
};

const bulkUpdate = (userIds = [], workspaces, action, cb) => (dispatch) => {
  const actionCreator =
    userIds.length < ASYNC_THRESHOLD
      ? actionBulkUpdateSync
      : actionBulkUpdateAsync;
  dispatch(actionCreator(userIds, workspaces, action, cb));
};

export const bulkGrant = (userIds, cb) => (dispatch, getState) => {
  const { marketoAdminSelectedWorkspaces, marketoAdminWorkspaces } = getState();
  const selectedWorkspacesIds = marketoAdminWorkspaces
    .filter((workspace) =>
      marketoAdminSelectedWorkspaces.includes(workspace.name)
    )
    .map((workspace) => workspace.id);
  const workspacesParam = userIds.reduce((acc, id) => {
    acc[id] = selectedWorkspacesIds;
    return acc;
  }, {});
  dispatch(bulkUpdate(userIds, workspacesParam, BULK_ACTIONS.create, cb));
};

export const bulkRevoke = (userIds, cb) => (dispatch) => {
  dispatch(bulkUpdate(userIds, [], BULK_ACTIONS.destroy, cb));
};

export const onMarketoTeamAccessSmartFilterChange = (index, filter) => ({
  filter,
  type: MarketoSettingsActionTypes.userAccess.smartFilter,
});
