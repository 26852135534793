import SettingsUnsubscribesActionTypes from '../libs/settingsUnsubscribesActionTypes';
import {
  SyncToSalesforceItems,
  HAS_OPTED_OUT_OF_EMAIL_KEY,
} from '../../adminSettings/unsubscribes/libs/adminUnsubscribesConstants';
import I18N from 'languages';

const initialState = {
  block_unsubscribe: I18N.getStr('web.settings.unsubscribe.defaultBody'),
  block_unsubscribe_text: I18N.getStr('web.settings.unsubscribe.defaultBody'),
  allow_override_unsubscribe_text: false,
  salesforce_sync_field:
    SyncToSalesforceItems[HAS_OPTED_OUT_OF_EMAIL_KEY].value,
  enable_unsubscribe_sync: false,
};

export const settingsUnsubscribes = (
  state = initialState,
  { type = '', settings = {} }
) => {
  switch (type) {
    case SettingsUnsubscribesActionTypes.updateSettings:
      return {
        ...state,
        ...settings,
      };

    default:
      return state;
  }
};
