import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Sidebar from 'web/shared/components/sidebar/sidebar';
import './teams.scss';

class Teams extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
    } = props;

    this.renameLabel = formatMessage({ id: 'common.rename' });
    this.deleteLabel = formatMessage({ id: 'common.delete' });
    this.listName = formatMessage({ id: 'common.teams' });
  }

  render() {
    const {
      actionCreators: {
        openCreateTeamPopup,
        setEditingTeamId,
        updateTeam,
        openDeleteTeamConfirmationPopup,
      },
      teams,
      teamsLoading,
      isTeamCreateDisabled,
      selectedTeamId,
      editingTeamId,
      selectTeam,
    } = this.props;

    return (
      <div className="team-members-sidebar">
        <Sidebar
          list={{
            listData: teams,
            editingItemId: editingTeamId,
            itemOptions: [
              {
                label: this.renameLabel,
                action: setEditingTeamId,
              },
              {
                label: this.deleteLabel,
                action: openDeleteTeamConfirmationPopup,
              },
            ],
            listName: this.listName,
            onItemAddClick: openCreateTeamPopup,
            isItemAddHidden: isTeamCreateDisabled,
            onEdit: updateTeam,
            onEditCancel: setEditingTeamId,
            onSelect: selectTeam,
            selectedListItem: selectedTeamId,
            listLoading: teamsLoading,
          }}
        />
      </div>
    );
  }
}

Teams.propTypes = {
  intl: intlShape.isRequired,
  actionCreators: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  teamsLoading: PropTypes.bool.isRequired,
  editingTeamId: PropTypes.number,
  selectedTeamId: PropTypes.number,
  isTeamCreateDisabled: PropTypes.bool.isRequired,
  selectTeam: PropTypes.func.isRequired,
};

Teams.defaultProps = {
  editingTeamId: null,
  selectedTeamId: null,
};

export default injectIntl(Teams);
