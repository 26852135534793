import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { getTimezones } from 'web/timezones/actionCreators/timezonesActionCreators';
import { BrowserMode } from 'web/libs/constants';
import { resizeToPeopleSearchAdd } from 'web/services/windowService';
import { parseSelectedIds } from 'web/people/peopleSearchAdd/helpers/parsers';
import {
  initModalAndDataForIds,
  setSourceOpener,
} from 'web/modals/addToCampaign/actionCreators/popupActionCreators';

export const initMode = (mode, idCount) => (dispatch) => {
  switch (mode) {
    case BrowserMode.window:
      resizeToPeopleSearchAdd(idCount);
      dispatch({
        type: ActionTypes.addToCampaignModal.state.windowMode,
        data: true,
      });
      break;
    case BrowserMode.iframe:
      dispatch({
        type: ActionTypes.addToCampaignModal.state.iframeMode,
        data: true,
      });
      break;
    default:
      break;
  }
};

export const initSourceOpener = (sourceOpener) => (dispatch) => {
  if (sourceOpener) {
    dispatch(setSourceOpener(sourceOpener));
  }
};

export const addToCampaignStartup = (
  selectedIds,
  campaignId,
  mode,
  sourceOpener
) => (dispatch) => {
  const parsedIds = parseSelectedIds(selectedIds);
  const { count: idCount } = parsedIds;

  dispatch(commonStartup());
  dispatch(initMode(mode, idCount));
  dispatch(initSourceOpener(sourceOpener));

  dispatch(initModalAndDataForIds(parsedIds, campaignId));

  // Data that isn't immediately needed
  setTimeout(() => {
    dispatch(getTimezones());
  }, 0);
};
