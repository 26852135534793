import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Menu, { menuItemShape } from 'components/menu';
import { BackgroundColors } from 'libs/constantsStyles';
import './baseSelect.scss';

const displayClassSelector = 'base-select-display';
const exceptionSelectors = [displayClassSelector];

export const SelectCustomIcons = {
  calendarLarge: 'calendar-large',
  calendarSmall: 'calendar-small',
  filters: 'filters',
};

export default class BaseSelect extends PureComponent {
  state = {
    open: false,
  };

  render() {
    const {
      className,
      classNameWrapper,
      disabled,
      full,
      fullHeight,
      items,
      highlightColor,
      maxWidth,
      menuStyle,
      noBorder,
      noIcon,
      rowHeight,
      rowsShown,
      selected,
      showAbove,
      shownHeight,
      width,
      customIcon,
    } = this.props;
    const { open } = this.state;

    const selectClass = classNames('base-select', classNameWrapper, {
      'full-height': fullHeight,
    });
    const containerClass = classNames('base-select-container', className, {
      full,
      'full-height': fullHeight,
      'show-above': showAbove,
    });
    const iconClass = classNames({
      'base-select-chevron': !customIcon,
      bottom: !open,
      [`base-select-${customIcon}`]: customIcon,
    });
    const displayClass = classNames(displayClassSelector, {
      disabled,
      'no-border': noBorder,
      open,
      'show-above': showAbove,
    });

    const selectedIndex =
      items.findIndex((item) => item.value === selected) || 0;
    const label = this._selectedLabel(items[selectedIndex], selectedIndex);
    return (
      <div className={selectClass}>
        <div
          className={containerClass}
          onClick={disabled ? undefined : this._toggleOpen}
          ref={this._setTriggerRef}
          style={{ width: `${width}px` }}
        >
          <div className={displayClass} style={{ maxWidth }}>
            <span className="base-select-text">{label}</span>
            {noIcon ? null : <span className={iconClass} />}
          </div>
          {open ? (
            <Menu
              className={classNames('base-select-menu', {
                'show-above': showAbove,
              })}
              exceptionSelectors={exceptionSelectors}
              handleSelected={this._handleSelected}
              highlightColor={highlightColor}
              itemRenderer={this.itemRenderer}
              items={items}
              maxWidth={maxWidth}
              onOutsideClick={this._toggleOpen}
              rowHeight={rowHeight}
              rowsShown={rowsShown}
              selected={selectedIndex}
              shownHeight={shownHeight}
              style={menuStyle}
            />
          ) : null}
        </div>
      </div>
    );
  }

  _toggleOpen = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  _handleSelected = (data) => {
    this.setState({ open: false });
    this.props.onChange(data.value);
  };

  _selectedLabel = (selectedObject = {}, selectedIndex) => {
    const { labelRenderer } = this.props;
    return (
      (labelRenderer && labelRenderer(selectedObject, selectedIndex)) ||
      selectedObject.label ||
      ''
    );
  };

  _defaultItemRenderer(item) {
    return (
      <div className="base-select-item-default text-overflow">{item.label}</div>
    );
  }

  itemRenderer = (item, index, data) => {
    const { itemRenderer } = this.props;
    return (
      <div className="base-select-item">
        {itemRenderer
          ? itemRenderer(item, index, data)
          : this._defaultItemRenderer(item)}
      </div>
    );
  };
}

BaseSelect.propTypes = {
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  disabled: PropTypes.bool,
  full: PropTypes.bool,
  fullHeight: PropTypes.bool,
  itemRenderer: PropTypes.func, // returns div element
  items: PropTypes.arrayOf(menuItemShape).isRequired,
  labelRenderer: PropTypes.func, // returns div or string
  highlightColor: PropTypes.string,
  maxWidth: PropTypes.number,
  menuStyle: PropTypes.object,
  noBorder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  rowHeight: PropTypes.number,
  rowsShown: PropTypes.number,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showAbove: PropTypes.bool,
  shownHeight: PropTypes.number,
  width: PropTypes.number,
  customIcon: PropTypes.string,
};

BaseSelect.defaultProps = {
  className: '',
  classNameWrapper: '',
  disabled: false,
  full: false,
  fullHeight: false,
  rowHeight: 24,
  itemRenderer: null,
  labelRenderer: null,
  highlightColor: BackgroundColors.grayExtraLight,
  maxWidth: undefined,
  menuStyle: {},
  noBorder: false,
  rowsShown: 5,
  selected: 0,
  showAbove: false,
  shownHeight: 0,
  width: undefined,
  customIcon: '',
};
