import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import UnsubscribeHistoryTableitem from './unsubscribeHistoryTableItem';
import ViewMore from 'components/viewMore';
import './unsubscribeHistoryTable.scss';

class UnsubscribeHistoryTable extends Component {
  render() {
    const { items, shownCount } = this.props;

    return (
      <div className="unsubscribe-history-table">
        <div className="unsubscribe-history-table-item unsubscribe-history-table-head">
          <div className="unsubscribe-history-table-block date-cell">
            <FormattedMessage id="common.date" />
          </div>
          <div className="unsubscribe-history-table-block details-cell">
            <FormattedMessage id="common.details" />
          </div>
          <div className="unsubscribe-history-table-block source-cell">
            <FormattedMessage id="common.source" />
          </div>
        </div>
        <ViewMore
          itemRenderer={this.renderItem}
          items={items}
          shownCount={shownCount}
          showLessTextId="web.person.personDetails.unsubscribeHistory.showLess"
          showMoreTextId="web.person.personDetails.unsubscribeHistory.showMore"
        />
      </div>
    );
  }

  renderItem = (item) => {
    const {
      formatMessage,
      unsubscribeOptInReasons,
      unsubscribeReasons,
      userSubscriptionUsers,
    } = this.props;

    return (
      <UnsubscribeHistoryTableitem
        formatMessage={formatMessage}
        key={item.id}
        item={item}
        unsubscribeOptInReasons={unsubscribeOptInReasons}
        unsubscribeReasons={unsubscribeReasons}
        userSubscriptionUsers={userSubscriptionUsers}
      />
    );
  };
}

UnsubscribeHistoryTable.defaultProps = {
  shownCount: 3,
};

UnsubscribeHistoryTable.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  shownCount: PropTypes.number,
  unsubscribeOptInReasons: PropTypes.object.isRequired,
  unsubscribeReasons: PropTypes.object.isRequired,
  userSubscriptionUsers: PropTypes.object.isRequired,
};

export default UnsubscribeHistoryTable;
