import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigateToAdminSalesforceTab } from 'web/services/routerService';
import { getActiveTab } from '../selectors/salesforceSelectors';
import Salesforce from '../components/salesforce/salesforce';
import { isIntegrationConnected } from 'web/salesforce/selectors/salesforceSelectors';

const SalesforceContainer = (props) => {
  const onTabSelect = (tab) => {
    navigateToAdminSalesforceTab(tab);
  };
  return <Salesforce {...props} onTabSelect={onTabSelect} />;
};

SalesforceContainer.propTypes = {
  tab: PropTypes.string.isRequired,
  isSalesforceConnected: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  tab: getActiveTab(state),
  isSalesforceConnected: isIntegrationConnected(state),
});

export default connect(mapStateToProps)(SalesforceContainer);
