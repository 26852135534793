import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import LabeledInputReduxForm from 'components/labeledInputReduxForm';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl, Urls } from 'web/libs/constants';
import FormLayout from 'components/layouts/formLayout';
import FormTitle from 'components/layouts/formLayout/formTitle';
import FormError from 'components/layouts/formLayout/formError';
import FormButtonsArea from 'components/layouts/formLayout/formButtonsArea';
import './forgotPassword.scss';

const ForgotPassword = ({ error, loading, handleSubmit }) => (
  <div className="forgot-password">
    {loading && <LoadingSpinner imageUrl={getImageUrl} />}
    <FormLayout className="forgot-password-form-wrapper">
      <FormTitle
        id="login.forgotPassword.title"
        subTitleId="login.forgotPassword.body"
        subTitleClasses="message-panel"
      />
      <Form className="forgot-password-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <Field
            name="email"
            className="form-cell"
            component={LabeledInputReduxForm}
            autoFocus
            id="useremail"
            label="common.email"
            type="text"
          />
        </div>
        <FormError>{error && <FormattedMessage id={error} />}</FormError>

        <FormButtonsArea align="spaceBetween">
          <Link to={Urls.loginPage} className="forgot-password-form-login-link">
            <FormattedMessage id="forgotPassword.loginBack" />
          </Link>
          <Button
            className="medium forgot-password-form-send-button"
            disabled={loading}
            size={ButtonSizes.medium}
            color={ButtonColors.blue}
            type="submit"
          >
            <FormattedMessage id="forgotPassword.sendResetEmail" />
          </Button>
        </FormButtonsArea>
      </Form>
    </FormLayout>
  </div>
);

ForgotPassword.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

ForgotPassword.defaultProps = {
  error: '',
};

export default reduxForm({ form: 'forgot-password-form' })(
  injectIntl(ForgotPassword)
);
