import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import Icon, {
  IconBackgroundColors,
  IconColors,
} from 'components/buttons/icon';
import './sidebarWhiteHeader.scss';

class SidebarWhiteHeader extends PureComponent {
  render() {
    const { iconOnClick } = this.props;
    const title = this._getTitle();

    return (
      <div className="sidebar-white-header">
        <h5>{title}</h5>
        {iconOnClick && (
          <Icon
            color={IconColors.white}
            backgroundColor={IconBackgroundColors.blue}
            className="tout-icon-sidebar-white-header"
            onClick={iconOnClick}
          />
        )}
      </div>
    );
  }

  _getTitle = () => {
    const {
      textId,
      intl: { formatMessage },
    } = this.props;

    return formatMessage({ id: textId }).toUpperCase();
  };
}

SidebarWhiteHeader.propTypes = {
  iconOnClick: PropTypes.func,
  textId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

SidebarWhiteHeader.defaultProps = {
  iconOnClick: null,
};

export default injectIntl(SidebarWhiteHeader);
