import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import CampaignsUsingTemplateTable from 'web/campaignsUsingTemplate/components/campaignsUsingTemplateTable';
import './updateCampaignTemplates.scss';

class UpdateCampaignTemplates extends PureComponent {
  render() {
    const {
      actionCreators,
      activeCampaignTemplates,
      isHeaderSelected,
      selectedIds,
    } = this.props;

    return (
      <div className="update-campaign-templates">
        <div className="margin-bottom-full">
          <FormattedHTMLMessage id="web.updateCampaignTemplates.instructions" />
        </div>
        <CampaignsUsingTemplateTable
          actionCreators={actionCreators}
          activeCampaignTemplates={activeCampaignTemplates}
          isHeaderSelected={isHeaderSelected}
          isPopup
          selectable
          selectedIds={selectedIds}
        />
      </div>
    );
  }
}

UpdateCampaignTemplates.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  activeCampaignTemplates: PropTypes.array.isRequired,
  isHeaderSelected: PropTypes.bool.isRequired,
  selectedIds: PropTypes.object.isRequired,
};

export default UpdateCampaignTemplates;
