import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LabeledInput from 'components/labeledInput';

const LabeledInputReduxForm = ({
  id,
  input,
  label,
  labelComment,
  className,
  type,
  meta: { touched, error },
  ...other
}) => {
  const defineError = (touchedMetaData, errorMetaData) =>
    touchedMetaData ? errorMetaData : undefined;

  return (
    <LabeledInput
      {...input}
      {...other}
      type={type}
      messageKey={label}
      labelCommentMessageKey={labelComment}
      error={defineError(touched, error)}
      id={id}
      className={classNames('labeled-input-redux-form', className)}
      classesLabel="labeled-input-redux-form-label"
      classesInput="labeled-input-redux-form-input"
    />
  );
};

LabeledInputReduxForm.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelComment: PropTypes.string,
  type: PropTypes.string.isRequired,
};

LabeledInputReduxForm.defaultProps = {
  className: '',
  label: '',
  labelComment: '',
};

export default LabeledInputReduxForm;
