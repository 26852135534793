import ActionTypes from 'table/modules/actionTypes';
import {
  bulkAddUpdateState,
  resetMapAndIndex,
} from 'table/modules/reducers/mapAndIndexHelper';

export function tableId(state = '', { type = '', id = '' }) {
  switch (type) {
    case ActionTypes.tableSetId:
      return id;
    default:
      return state;
  }
}

export function tableAllItems(state = false, { type = '', allItems = false }) {
  switch (type) {
    case ActionTypes.tableSetAllItems:
      return allItems;
    default:
      return state;
  }
}

export function tableColumns(
  state = resetMapAndIndex(),
  { type = '', columns = [] }
) {
  switch (type) {
    case ActionTypes.tableSetColumns:
      return bulkAddUpdateState(state, columns, 'id');
    default:
      return state;
  }
}

export function tableColumnsWidth(
  state = [],
  { type = '', columnsWidth = [] }
) {
  switch (type) {
    case ActionTypes.columnSettingsSetColumnsWidth:
      return columnsWidth;
    default:
      return state;
  }
}
