import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Action from 'components/buttons/action';
import {
  numbers as numberValidatorFactory,
  minValue as minValueValidatorFactory,
  maxValue as maxValueValidatorFactory,
} from 'web/form/helpers/validators';
import Text from 'web/form/components/fields/text';
import { MIN_TEAM_CAMPAIGN_LIMIT } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import './campaignLimitsForm.scss';

class CampaignLimitsForm extends Component {
  constructor(props) {
    super(props);
    const { subscriptionCampaignLimit } = props;
    this.numberValidator = numberValidatorFactory();
    this.minValueValidatorForTeamCampaignLimit = minValueValidatorFactory(
      MIN_TEAM_CAMPAIGN_LIMIT
    );
    this.maxValueValidatorForTeamCampaignLimit = maxValueValidatorFactory(
      subscriptionCampaignLimit
    );
  }

  onFormSubmitError = (e) => {
    const { onError } = this.props;
    onError();
    e.preventDefault();
  };

  onFormSubmit = () => {
    const { handleSubmit, valid } = this.props;
    if (valid) {
      return handleSubmit;
    }
    return this.onFormSubmitError;
  };

  render() {
    const { pristine } = this.props;

    return (
      <React.Fragment>
        <h3>
          <FormattedMessage id="web.settings.adminSettings.general.communicationLimitsCard.campaignLimit.title" />
        </h3>
        <div className="margin-top-20">
          <FormattedMessage id="web.settings.adminSettings.general.CampaignLimit.description" />
        </div>
        <Form
          className="team-campaign-limit-form margin-0"
          onSubmit={this.onFormSubmit()}
          noValidate
        >
          <div className="margin-top-20 campaignLimitsData">
            <Field
              autoFocus={false}
              className="team-campaign-limit-form-field"
              component={Text}
              name="teamCampaignLimit"
              type="text"
              validate={[
                this.numberValidator,
                this.minValueValidatorForTeamCampaignLimit,
                this.maxValueValidatorForTeamCampaignLimit,
              ]}
            />
            <span className="campaignLimitsDataTxt">
              <FormattedMessage id="web.settings.adminSettings.general.CampaignLimit.label" />
            </span>
          </div>
          <div className="margin-top-20 right">
            <Action disabled={pristine} type="submit">
              <FormattedMessage id="common.save" />
            </Action>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

CampaignLimitsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  subscriptionCampaignLimit: PropTypes.number,
  valid: PropTypes.bool.isRequired,
};

CampaignLimitsForm.defaultProps = {
  subscriptionCampaignLimit: null,
};

export default reduxForm({
  enableReinitialize: true,
})(CampaignLimitsForm);
