import merge from 'lodash/merge';
import * as salesforceCustomizationReducer from './salesforceCustomizationReducer';
import * as salesforceCustomizationErrorsReducer from './salesforceCustomizationErrorsReducer';
import * as salesforceCustomizationVersionsReducer from './salesforceCustomizationVersionsReducer';
import * as salesforceCustomizationProfilesReducer from './salesforceCustomizationProfilesReducer';

const salesforceCustomizationRootReducer = merge(
  {},
  salesforceCustomizationReducer,
  salesforceCustomizationErrorsReducer,
  salesforceCustomizationVersionsReducer,
  salesforceCustomizationProfilesReducer
);

export default salesforceCustomizationRootReducer;
