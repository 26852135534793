import SalesforceCustomizationActionTypes from 'web/modals/salesforceCustomization/libs/salesforceCustomizationActionTypes';
import {
  getProfileUser,
  getWizardSteps,
} from '../selectors/salesforceCustomizationWizardSelectors';
import { getProfiles } from '../selectors/salesforceCustomizationSelectors';
import {
  managePackage,
  customizationProfilesCall,
} from './salesforceCustomizationActionCreators';
import {
  getCustomizationProfilesIds,
  updateProfileUsers,
  switchAllProfileUsers,
} from '../helpers/salesforceCustomizationHelpers';
import {
  PROFILE_TYPES,
  USERS_SPECIFIC,
  PROFILE_USERS_SPECIFIC_STEP_INDEX,
} from '../libs/salesforceCustomizationConstants';

export const wizardGoStart = () => (dispatch, getState) => {
  const state = getState();
  const wizardSteps = getWizardSteps(state);

  dispatch({
    type: SalesforceCustomizationActionTypes.setWizardStep,
    wizardStep: wizardSteps[0],
  });
};

export const wizardGoNext = () => (dispatch, getState) => {
  const state = getState();
  const wizardSteps = getWizardSteps(state);
  const {
    salesforceCustomizationProfiles: { currentUser: selectedProfileType },
    salesforceCustomization: { wizardStep },
  } = state;
  const wizardStepIndex = wizardSteps.indexOf(wizardStep);

  if (
    selectedProfileType === USERS_SPECIFIC &&
    wizardStepIndex === PROFILE_USERS_SPECIFIC_STEP_INDEX
  ) {
    dispatch(customizationProfilesCall());
  }

  dispatch({
    type: SalesforceCustomizationActionTypes.setWizardStep,
    wizardStep: wizardSteps[wizardStepIndex + 1],
  });
};

export const wizardGoBack = () => (dispatch, getState) => {
  const state = getState();
  const wizardSteps = getWizardSteps(state);
  const {
    salesforceCustomization: { wizardStep },
  } = state;
  const wizardStepIndex = wizardSteps.indexOf(wizardStep);

  dispatch({
    type: SalesforceCustomizationActionTypes.setWizardStep,
    wizardStep: wizardSteps[wizardStepIndex - 1],
  });
};

export const wizardInstall = () => (dispatch, getState) => {
  const { availableUsers, currentUser: selectedProfileType } = getProfiles(
    getState()
  );

  const profileIds = getCustomizationProfilesIds({
    availableUsers,
    selectedProfileType,
  });
  dispatch(
    managePackage(true, {
      profile_ids: profileIds,
      profile_type: PROFILE_TYPES[selectedProfileType],
    })
  );
};

export const setProfileUser = (currentUser) => (dispatch) => {
  dispatch({
    type: SalesforceCustomizationActionTypes.profiles.userSet,
    currentUser,
  });
};

export const updateProfileAvailableUsers = (user, isChecked) => (
  dispatch,
  getState
) => {
  const { availableUsers } = getState().salesforceCustomizationProfiles;
  const updatedAvailableUsers = updateProfileUsers({
    availableUsers,
    user,
    isChecked,
  });

  dispatch({
    type: SalesforceCustomizationActionTypes.profiles.availableUsersUpdate,
    availableUsers: updatedAvailableUsers,
  });
};

export const switchAllProfileAvailableUsers = (isCheckedAll) => (
  dispatch,
  getState
) => {
  const { availableUsers } = getState().salesforceCustomizationProfiles;
  const updatedUsers = switchAllProfileUsers({ availableUsers, isCheckedAll });

  dispatch({
    type: SalesforceCustomizationActionTypes.profiles.availableUsersUpdate,
    availableUsers: updatedUsers,
  });
};
