import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Action from 'components/buttons/action';
import Radio from 'components/buttons/radio';
import { LOGIN_METHODS } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import './loginManagement.scss';

const salesforceLoginRadioName = 'salesforce-login-radio';
const allMethodsLoginRadioName = 'all-methods-login-radio';

const LoginManagement = (props) => {
  const {
    actionCreators: { updateLoginMethod },
    intl: { formatMessage },
    loginMethod,
  } = props;

  const [activeLoginMethod, setActiveLoginMethod] = useState(loginMethod);

  return (
    <div className="login-management">
      <h3>
        <FormattedMessage id="web.settings.adminSettings.general.loginManagement.title" />
      </h3>
      <div className="margin-top-20">
        <FormattedHTMLMessage id="web.settings.adminSettings.general.loginManagement.description" />
      </div>
      <div className="radio-panel">
        <Radio
          checked={activeLoginMethod === LOGIN_METHODS.salesforceOnly}
          id={salesforceLoginRadioName}
          label={formatMessage({
            id:
              'web.settings.adminSettings.general.loginManagement.radioLabel.salesforceOnlyMethod',
          })}
          name={salesforceLoginRadioName}
          onChange={setActiveLoginMethod}
          value={LOGIN_METHODS.salesforceOnly}
        />
        <div className="salesforce-radio-description">
          <FormattedHTMLMessage id="web.settings.adminSettings.general.loginManagement.salesforceOnlyMethod.description" />
        </div>
        <Radio
          checked={activeLoginMethod === LOGIN_METHODS.all}
          id={allMethodsLoginRadioName}
          label={formatMessage({
            id:
              'web.settings.adminSettings.general.loginManagement.radioLabel.allMethods',
          })}
          name={allMethodsLoginRadioName}
          onChange={setActiveLoginMethod}
          value={LOGIN_METHODS.all}
        />
      </div>
      <div className="margin-top-20 right">
        <Action
          onClick={() => {
            updateLoginMethod(activeLoginMethod);
          }}
        >
          <FormattedMessage id="common.save" />
        </Action>
      </div>
    </div>
  );
};

LoginManagement.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  loginMethod: PropTypes.oneOf(Object.values(LOGIN_METHODS)),
};

LoginManagement.defaultProps = {
  loginMethod: LOGIN_METHODS.all,
};

export default injectIntl(LoginManagement);
