import { baseGet } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function findOrCreateContact(email, source) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.peopleLookup,
      { email, hashed_object_type: 'no_pitches', source },
      resolve,
      reject
    );
  });
}

export function checkContactSharedWithUser(contactId, userId) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.isPersonShared,
      { person_id: contactId, user_id: userId },
      resolve,
      reject
    );
  });
}
