import moment from 'moment';
import { TablesFiltersConstants } from '../libs/tablesFiltersConstants';
import {
  MAX_TASK_DATE_RANGE,
  OVERDUE_TASK_DATE_RANGE,
} from 'web/tasks/libs/tasksConstants';
import { getTimeZone } from 'web/tables/tasks/helpers/timeZoneHelper';

function filterToday(items, dateField) {
  const now = moment();
  return items.filter((item) => moment(item[dateField]).isSame(now, 'd'));
}

function filterOverdue(items, dateField) {
  const now = moment();
  return items.filter((item) => moment(item[dateField]).isBefore(now, 'd'));
}

function filterTodayOverdue(items, dateField) {
  const now = moment();
  return items.filter((item) => {
    const date = moment(item[dateField]);
    return date.isSame(now, 'd', '[]') || date.isBefore(now, 'd');
  });
}

function filterTomorrow(items, dateField) {
  const tomorrow = moment().add(1, 'd');
  return items.filter((item) => moment(item[dateField]).isSame(tomorrow, 'd'));
}

function filterNextSeven(items, dateField) {
  const start = moment();
  const end = moment(start).add(6, 'd');
  return items.filter((item) => {
    const itemDate = moment(item[dateField]);
    return itemDate.isBetween(start, end, 'd', '[]');
  });
}

function filterLastSeven(items, dateField) {
  const end = moment();
  const start = moment(end).subtract(6, 'd');
  return items.filter((item) => {
    const itemDate = moment(item[dateField]);
    return itemDate.isBetween(start, end, 'd', '[]');
  });
}

function filterYesterday(items, dateField) {
  const tomorrow = moment().subtract(1, 'd');
  return items.filter((item) => moment(item[dateField]).isSame(tomorrow, 'd'));
}

function filterCustom(items, dates, dateField) {
  const start = moment(dates.start);
  const end = moment(dates.end);
  return items.filter((item) => {
    const itemDate = moment(item[dateField]);
    return itemDate.isBetween(start, end, 'd', '[]');
  });
}

export function filterItemsByDate(
  items = [],
  value,
  dates = {},
  dateField = 'remind_at'
) {
  switch (value) {
    case TablesFiltersConstants.today:
      return filterToday(items, dateField);
    case TablesFiltersConstants.overdue:
      return filterOverdue(items, dateField);
    case TablesFiltersConstants.todayOverdue:
      return filterTodayOverdue(items, dateField);
    case TablesFiltersConstants.tomorrow:
      return filterTomorrow(items, dateField);
    case TablesFiltersConstants.nextSeven:
      return filterNextSeven(items, dateField);
    case TablesFiltersConstants.lastSeven:
      return filterLastSeven(items, dateField);
    case TablesFiltersConstants.yesterday:
      return filterYesterday(items, dateField);
    case TablesFiltersConstants.custom:
      return filterCustom(items, dates, dateField);
    default:
      return items;
  }
}

export function filterTasksByCampaign(items = [], campaignId) {
  return items.filter(
    (task) =>
      task.workflow_details && task.workflow_details.workflow_id === campaignId
  );
}

/* base range: -20 -> +7; default range: -20 -> 0 */
export function getFilterDatesRequired(value, start, end) {
  const userTimezone = getTimeZone();
  moment.tz.setDefault(userTimezone);
  switch (value) {
    case TablesFiltersConstants.today:
      return {
        end: moment().endOf('day'),
        start: moment().startOf('day'),
      };
    case TablesFiltersConstants.overdue:
      return {
        end: moment()
          .subtract(1, 'd')
          .endOf('d'),
        start: moment()
          .subtract(OVERDUE_TASK_DATE_RANGE, 'd')
          .startOf('d'),
      };
    case TablesFiltersConstants.todayOverdue:
      return {
        end: moment().endOf('d'),
        start: moment()
          .subtract(OVERDUE_TASK_DATE_RANGE, 'd')
          .startOf('d'),
      };
    case TablesFiltersConstants.tomorrow:
      return {
        end: moment()
          .add(1, 'd')
          .endOf('d'),
        start: moment()
          .add(1, 'd')
          .startOf('d'),
      };
    case TablesFiltersConstants.yesterday:
      return {
        end: moment()
          .subtract(1, 'd')
          .endOf('d'),
        start: moment()
          .subtract(1, 'd')
          .startOf('d'),
      };
    case TablesFiltersConstants.nextSeven: {
      return {
        end: moment()
          .add(MAX_TASK_DATE_RANGE, 'd')
          .endOf('d'),
        start: moment().startOf('d'),
      };
    }
    case TablesFiltersConstants.lastSeven: {
      return {
        end: moment().endOf('d'),
        start: moment()
          .subtract(7, 'd')
          .startOf('d'),
      };
    }
    case TablesFiltersConstants.thisWeek: {
      return {
        end: moment().endOf('d'),
        start: moment().startOf('week'),
      };
    }
    case TablesFiltersConstants.last30Days: {
      return {
        end: moment().endOf('d'),
        start: moment()
          .subtract(30, 'd')
          .startOf('d'),
      };
    }
    case TablesFiltersConstants.thisMonth: {
      return {
        end: moment().endOf('d'),
        start: moment().startOf('month'),
      };
    }
    case TablesFiltersConstants.lastThreeMonths: {
      return {
        end: moment().endOf('d'),
        start: moment()
          .subtract(3, 'month')
          .startOf('d'),
      };
    }
    case TablesFiltersConstants.lastSixMonths: {
      return {
        end: moment().endOf('d'),
        start: moment()
          .subtract(6, 'month')
          .startOf('d'),
      };
    }
    case TablesFiltersConstants.toTheEndThisWeek: {
      return {
        end: moment().endOf('week'),
        start: moment().startOf('d'),
      };
    }
    case TablesFiltersConstants.toTheEndThisMonth: {
      return {
        end: moment().endOf('month'),
        start: moment().startOf('d'),
      };
    }
    case TablesFiltersConstants.nextSixMonths: {
      return {
        end: moment()
          .add(6, 'month')
          .endOf('d'),
        start: moment().startOf('d'),
      };
    }
    case TablesFiltersConstants.custom: {
      return {
        end: moment(end).endOf('d'),
        start: moment(start).startOf('d'),
      };
    }
    default:
      return {
        end: moment().endOf('d'),
        start: moment()
          .subtract(OVERDUE_TASK_DATE_RANGE, 'd')
          .startOf('d'),
      };
  }
}
