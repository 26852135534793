import { onSidebarFilterSelect } from 'web/sidebar/viewers/actionCreators/viewersActionCreators';
import { getEmails } from 'web/commandCenter/actionCreators/commandCenterEmailsFetchActionCreators';
import { setViewAsToEmailGridAdvancedSearchFilter } from 'web/commandCenter/actionCreators/commandCenterSidebarActionCreators';
import CCActionTypes from 'web/commandCenter/libs/commandCenterActionTypes';
import ViewersConstants from 'web/sidebar/viewers/libs/viewersConstants';
import { fetchTasksBySmartFilter } from 'web/tables/tasks/actionCreators/tablesTasksStateActionCreators';
import { CommandCenterTasksTableId } from 'web/commandCenter/libs/commandCenterConstants';

export const onCommandCenterSidebarFilterSelect = (selectedItem) => (
  dispatch
) => {
  dispatch(onSidebarFilterSelect(selectedItem));
  dispatch({ type: CCActionTypes.state.changeViewAsFilter });
  dispatch(setViewAsAdvancedSearchTableFilter(selectedItem));
  dispatch(getEmails());
};

const setViewAsAdvancedSearchTableFilter = ({
  label: name,
  value: id,
} = {}) => (dispatch) => {
  const { user } = ViewersConstants.sidebarViewer.viewer.types;
  dispatch(setViewAsToEmailGridAdvancedSearchFilter({ id, name, type: user }));
};

export const onCommandCenterTaskSidebarFilterSelect = (selectedItem) => (
  dispatch
) => {
  dispatch(onSidebarFilterSelect(selectedItem));
  dispatch({ type: CCActionTypes.state.changeViewAsFilter });
  dispatch(fetchTasksBySmartFilter(CommandCenterTasksTableId, true));
};
