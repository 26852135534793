import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import { FileFormats } from 'libs/constantsShared';
import CsvExporter from 'components/export/csvExporter';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

class Exporter extends Component {
  render() {
    const { fileExtension } = this.props;

    return this._getExportFormat(fileExtension);
  }

  _getExportFormat = (format) => {
    const {
      columns,
      data,
      fileExtension,
      filename,
      hideElement,
      sheetName,
    } = this.props;

    const dataSet = [{ columns, data }];

    switch (format) {
      case FileFormats.excel:
        return (
          <ExcelFile
            fileExtension={fileExtension}
            filename={filename}
            hideElement={hideElement}
          >
            <ExcelSheet dataSet={dataSet} name={sheetName} />
          </ExcelFile>
        );
      case FileFormats.csv:
        return (
          <CsvExporter columns={columns} data={data} fileName={filename} />
        );
      default:
        return null;
    }
  };
}

Exporter.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fileExtension: PropTypes.string,
  filename: PropTypes.string,
  hideElement: PropTypes.bool,
  sheetName: PropTypes.string,
};

Exporter.defaultProps = {
  fileExtension: 'xlsx',
  filename: 'download',
  hideElement: true,
  sheetName: 'Sheet1',
};

export default Exporter;
