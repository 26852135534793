import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { INVITE_USERS_FORM_ID } from '../../../libs/inviteesConstants';
import InviteUsersForm from './inviteUsersForm';
import head from 'lodash/head';
import get from 'lodash/get';
import castArray from 'lodash/castArray';

class InviteUsers extends Component {
  getInviteUsersFormModel = () => {
    const { workspacesSelectOptions } = this.props;
    if (workspacesSelectOptions.length) {
      return {
        workspaces: castArray(
          get(head(workspacesSelectOptions), 'value', null)
        ),
        emails: [],
      };
    } else {
      return {
        emails: [],
      };
    }
  };

  render() {
    const {
      defaultAlert,
      actionCreators: { closePopup },
      workspacesLoading,
      teamsLoading,
      inviteUsersPopupLoading,
      isInvalidInviteUsersForm,
    } = this.props;
    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.submitForm,
          onSecondary: closePopup,
          primaryTextId: 'common.invite',
          secondaryTextId: 'common.cancel',
          primaryDisabled: isInvalidInviteUsersForm,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.userManagement.invitees.inviteUsers',
        }}
        getImageUrl={getImageUrl}
        loading={inviteUsersPopupLoading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        {!workspacesLoading &&
          !teamsLoading && (
            <InviteUsersForm
              form={INVITE_USERS_FORM_ID}
              initialValues={this.getInviteUsersFormModel()}
              {...this.props}
            />
          )}
      </Popup>
    );
  }

  submitForm = () => {
    const {
      actionCreators: { submitForm },
    } = this.props;
    submitForm(INVITE_USERS_FORM_ID);
  };
}

InviteUsers.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  teamsLoading: PropTypes.bool.isRequired,
  inviteUsersPopupLoading: PropTypes.bool.isRequired,
  workspacesLoading: PropTypes.bool.isRequired,
  isInvalidInviteUsersForm: PropTypes.bool.isRequired,
  defaultAlert: PropTypes.object,
};

InviteUsers.defaultProps = {
  defaultAlert: null,
};

export default InviteUsers;
