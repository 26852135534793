import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AddressTypes } from 'web/person/libs/personDetailsConstants';
import TextButton from 'components/buttons/text';
import InfoCardDetailsAddresses from './addresses';
import InfoCardDetailsSocialMedia from './socialMedia';
import { isMsiActions } from 'web/user/selectors/userSelectors';
import './infoDetails.scss';
import { getMarketoLeadIdFromLeadFields } from 'web/person/helpers/personHelpers.js';
import { openNewTab } from 'web/services/routerService';
import get from 'lodash/get';
class InfoDetails extends Component {
  render() {
    const {
      edit,
      isMsiActions,
      marketoSubDetails: { podName },
      onAddressClick,
      salesforceInstanceUrl,
      person: {
        lead_fields,
        social,
        salesforce_contact_id,
        salesforce_lead_id,
      },
    } = this.props;

    const showEdit = !isMsiActions;
    const salesforceId = salesforce_contact_id || salesforce_lead_id;
    const salesforceUrl =
      salesforceId && salesforceInstanceUrl
        ? `${salesforceInstanceUrl}/${salesforceId}`
        : null;

    const salesforce = {
      type: AddressTypes.salesforce,
      value: salesforceUrl,
    };

    const marketoLeadId = getMarketoLeadIdFromLeadFields(lead_fields);
    const marketoUrl =
      marketoLeadId &&
      podName &&
      `https://app-${podName}.marketo.com/leadDatabase/loadLeadDetail?leadId=${marketoLeadId}`;
    const marketo = {
      type: AddressTypes.marketo,
      value: marketoUrl,
    };

    return (
      <div className="person-details-info-details">
        <div className="info-details-header-row margin-bottom-half">
          <h3>
            <FormattedMessage id="common.contact" />
            <InfoCardDetailsSocialMedia
              className=" info-details-item-social-media"
              marketo={marketo}
              onClick={onAddressClick}
              salesforce={salesforce}
              {...social}
            />
          </h3>
          {edit &&
            showEdit && (
              <TextButton onClick={edit} underline>
                <FormattedMessage id="common.edit" />
              </TextButton>
            )}
        </div>
        <div className="info-details-body">
          {this._getFirstRow()}
          {this._getRestOfAddresses()}
          {this._getSalesforceAddress(salesforceId, salesforceUrl)}
          {this._getMarketoAddress(marketoLeadId, marketoUrl)}
          {this._getLinkedinAddress()}
        </div>
      </div>
    );
  }

  _getSalesforceAddress = (id, url) => {
    const data = [
      {
        type: AddressTypes.salesforce,
        value: id || <FormattedMessage id="common.noInfo" />,
      },
    ];
    return (
      <InfoCardDetailsAddresses
        addresses={data}
        className="info-details-item"
        disabled={!id || !url}
        headerText="web.person.personDetails.contact.salesforce"
        key="info-card-salesforce-id"
        onClick={() => openNewTab(url, true)}
      />
    );
  };

  _getMarketoAddress = (id, url) => {
    const data = [
      {
        type: AddressTypes.marketo,
        value: id || <FormattedMessage id="common.noInfo" />,
      },
    ];
    return (
      <InfoCardDetailsAddresses
        addresses={data}
        className="info-details-item"
        disabled={!id || !url}
        headerText="web.person.personDetails.contact.marketo"
        key="info-card-marketo-id"
        onClick={() => openNewTab(url, true)}
      />
    );
  };

  _getLinkedinAddress = () => {
    const linkedin = get(this.props, 'person.social.linkedin', {});
    const disabled = !linkedin.value;
    const data = [
      {
        ...linkedin,
        value: disabled ? (
          <FormattedMessage id="common.noInfo" />
        ) : (
          linkedin.value
        ),
      },
    ];
    return (
      <InfoCardDetailsAddresses
        addresses={data}
        className="info-details-item"
        disabled={disabled}
        headerText="web.person.personDetails.contact.linkedin"
        key="info-card-linkedin-id"
        onClick={() => openNewTab(linkedin.value, true)}
      />
    );
  };

  _getPrimary(addressTypeAddress = []) {
    let chooseFirstAsPrimary = {};

    for (const category of addressTypeAddress) {
      const primary = category && category.find((address) => address.isPrimary);
      if (primary) {
        return primary;
      } else if (category.length && !chooseFirstAsPrimary.id) {
        const [firstItem] = category;
        chooseFirstAsPrimary = firstItem;
      }
    }

    return chooseFirstAsPrimary;
  }

  _getFirstRow = () => {
    const {
      onAddressClick,
      person: { addressesSorted },
    } = this.props;
    const email = this._getPrimary(addressesSorted[AddressTypes.email]);
    const phone = this._getPrimary(addressesSorted[AddressTypes.phone]);

    return (
      <div className="info-details-row-primary margin-bottom-half">
        {email.id && (
          <InfoCardDetailsAddresses
            addresses={[email]}
            className="info-details-item"
            key="info-details-primary-email"
            onClick={onAddressClick}
            type={AddressTypes.email}
            primary
          />
        )}
        {phone.id && (
          <InfoCardDetailsAddresses
            addresses={[phone]}
            className="info-details-item"
            key="info-card--primary-phone"
            onClick={onAddressClick}
            type={AddressTypes.phone}
            primary
          />
        )}
      </div>
    );
  };

  _getCategories(category = [], onClick) {
    const categoryComponents = [];
    for (let i = 0; i < category.length; i++) {
      if (category[i].length) {
        const { type, location } = category[i][0];
        categoryComponents.push(
          <InfoCardDetailsAddresses
            addresses={category[i]}
            className="info-details-item"
            key={`info-card-category-${type}-${location}`}
            location={location}
            onClick={onClick}
            type={type}
          />
        );
      }
    }

    return categoryComponents;
  }

  _getRestOfAddresses = () => {
    const {
      onAddressClick,
      person: { addressesSorted },
    } = this.props;
    const components = [
      ...this._getCategories(
        addressesSorted[AddressTypes.email],
        onAddressClick
      ),
      ...this._getCategories(
        addressesSorted[AddressTypes.phone],
        onAddressClick
      ),
      ...this._getCategories(
        addressesSorted[AddressTypes.website],
        onAddressClick
      ),
      ...this._getCategories(
        addressesSorted[AddressTypes.other],
        onAddressClick
      ),
    ];

    return (
      <div className="info-details-row-rest margin-bottom-full">
        {components}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  isMsiActions: isMsiActions(state),
  marketoSubDetails: state.marketoSubDetails,
  user: state.user,
});

InfoDetails.propTypes = {
  edit: PropTypes.func,
  marketoSubDetails: PropTypes.object.isRequired,
  onAddressClick: PropTypes.func.isRequired,
  person: PropTypes.object.isRequired,
};

InfoDetails.defaultProps = {
  edit: null,
};

export default connect(
  mapStateToProps,
  {}
)(InfoDetails);
