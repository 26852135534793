import { Urls } from 'web/libs/routes';
import { baseDelete, baseGet, basePut, basePost } from 'web/services/api';
import { overrideNewIntegration } from '../helpers/contactIntegrationsHelper';

export const getContactIntegrations = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.contactIntegrations.all, {}, resolve, reject);
  });

export const updateContactIntegration = (
  integrationId,
  contactIntegration = {},
  fetch = false
) =>
  new Promise((resolve, reject) => {
    basePut(
      Urls.contactIntegrations.byId(integrationId),
      { contact_integration: contactIntegration, fetch },
      resolve,
      reject
    );
  });

export const createContactIntegration = (
  contactIntegration = {},
  fetch = false
) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.contactIntegrations.all,
      {
        contact_integration: overrideNewIntegration(contactIntegration),
        fetch,
      },
      resolve,
      reject
    );
  });

export const deleteContactIntegration = (integrationId) =>
  new Promise((resolve, reject) => {
    baseDelete(
      Urls.contactIntegrations.byId(integrationId),
      {},
      resolve,
      reject
    );
  });
