import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/label';
import Text from 'components/inputs/text';
import Checkbox from 'components/buttons/checkbox';
import './imageEditor.scss';

class ImageEditor extends Component {
  state = {
    keepRatio: true,
    originalHeightToWidthRatio:
      this.props.editState.height / this.props.editState.width,
  };

  render() {
    const { editState } = this.props;
    const { keepRatio } = this.state;

    return (
      <div className="imageEditor">
        <Label
          classWrapper="margin-bottom-half"
          id="image-editor-name"
          messageKey="common.name"
          sideLabel
        >
          <Text
            autoFocus
            id="image-editor-name"
            max={9999}
            onChange={this.onNameChange}
            type="text"
            value={editState.name}
          />
        </Label>
        <Label
          classWrapper="margin-bottom-half"
          id="image-editor-link"
          messageKey="common.link"
          sideLabel
        >
          <Text
            autoFocus={false}
            id="image-editor-link"
            max={9999}
            onChange={this.onLinkChange}
            type="text"
            value={editState.link}
          />
        </Label>
        <div className="image-editor-size-container">
          <div className="image-editor-sizes">
            <Label
              classWrapper="image-editor-size margin-bottom-half"
              id="image-editor-height"
              messageKey="common.height"
              sideLabel
            >
              <Text
                autoFocus={false}
                id="image-editor-height"
                max={9999}
                onChange={this.onHeightChange}
                type="number"
                value={editState.height}
              />
            </Label>
            <Label
              classWrapper="image-editor-size"
              id="image-editor-width"
              messageKey="common.width"
              sideLabel
            >
              <Text
                autoFocus={false}
                id="image-editor-width"
                max={9999}
                onChange={this.onWidthChange}
                type="number"
                value={editState.width}
              />
            </Label>
          </div>
          <Checkbox
            checked={keepRatio}
            classes="image-editor-ratio"
            label={<FormattedMessage id="imageEditor.keepRatio" />}
            name="keepRatio"
            onChange={this.onKeepRatioChange}
          />
        </div>
      </div>
    );
  }

  onNameChange = (value) => {
    this.props.onChange({ name: value });
  };

  onLinkChange = (value) => {
    this.props.onChange({ link: value });
  };

  _calcRatio(ratio, newValue, wasHeightChanged) {
    if (wasHeightChanged) {
      return `${(newValue / ratio).toFixed()}`; // newWidth = newHeight / (origHeight/origWidth)
    }
    return `${(newValue * ratio).toFixed()}`; // newHeight = newWidth * (origHeight/origWidth)
  }

  onHeightChange = (value) => {
    const { keepRatio, originalHeightToWidthRatio } = this.state;
    if (keepRatio) {
      this.props.onChange({
        height: value,
        width: this._calcRatio(originalHeightToWidthRatio, value, true),
      });
    } else {
      this.props.onChange({ height: value });
    }
  };

  onWidthChange = (value) => {
    const { keepRatio, originalHeightToWidthRatio } = this.state;
    if (keepRatio) {
      this.props.onChange({
        height: this._calcRatio(originalHeightToWidthRatio, value, false),
        width: value,
      });
    } else {
      this.props.onChange({ width: value });
    }
  };

  onKeepRatioChange = (checked) => {
    this.setState({ keepRatio: checked });
  };
}

ImageEditor.propTypes = {
  editState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImageEditor;
