import { createSelector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { composeDeliveryChannels } from '../helpers/identitiesHelper';
import { getDefaultIdentity } from 'web/user/selectors/identitiesSelectors';

const getUser = (state) => state.user;

export const getDeliveryChannels = (state) => state.deliveryChannels;

export const shouldHideDeliveryChannels = createSelector([getUser], (user) =>
  get(
    user,
    'subscription.features.customized_default_delivery_channel_enabled',
    false
  )
);

export const composeDeliveryChannelsWithDefaultOne = createSelector(
  [getDeliveryChannels, shouldHideDeliveryChannels],
  composeDeliveryChannels
);

export const findDeliveryChannelByTeamAndProviderSmtp = createSelector(
  [getDeliveryChannels],
  (deliveryChannels) => find(deliveryChannels, { provider: 'smtp', team: true })
);

export const findDeliveryChannelByProviderGoogle = createSelector(
  [getDeliveryChannels],
  (deliveryChannels) =>
    find(deliveryChannels, (deliveryChannel) =>
      includes(['google_oauth2', 'google'], deliveryChannel.provider)
    )
);

export const findDeliveryChannelByProviderOutlook = createSelector(
  [getDeliveryChannels],
  (deliveryChannels) =>
    find(deliveryChannels, (deliveryChannel) =>
      includes(['office365', 'exchange_onprem'], deliveryChannel.provider)
    )
);

export const getDeliveryChannelOfDefaultIdentity = createSelector(
  [getDeliveryChannels, getDefaultIdentity],
  (deliveryChannels, defaultIdentity) =>
    find(deliveryChannels, [
      'id',
      defaultIdentity && defaultIdentity.authentication_id,
    ])
);
