import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Action, { ButtonColors } from 'components/buttons/action';

const BoxCard = ({
  isConnected,
  openNavigateToBoxConfirmation,
  openDeleteBoxConfirmation,
}) => (
  <div className="integrations-box">
    <h3>
      <FormattedMessage id="web.settings.accountSettings.integrations.box.title" />
    </h3>
    <div className="margin-top-20">
      {isConnected ? (
        <>
          <FormattedMessage id="web.settings.accountSettings.integrations.box.description.connected" />
          <div className="card-buttons margin-top-20 right">
            <Action
              color={ButtonColors.red}
              type="button"
              onClick={openDeleteBoxConfirmation}
            >
              <FormattedMessage id="common.disconnect" />
            </Action>
          </div>
        </>
      ) : (
        <>
          <FormattedMessage id="web.settings.accountSettings.integrations.box.description.notConnected" />
          <div className="card-buttons margin-top-20 right">
            <Action type="button" onClick={openNavigateToBoxConfirmation}>
              <FormattedMessage id="common.connect" />
            </Action>
          </div>
        </>
      )}
    </div>
  </div>
);

BoxCard.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  openNavigateToBoxConfirmation: PropTypes.func.isRequired,
  openDeleteBoxConfirmation: PropTypes.func.isRequired,
};

export default BoxCard;
