import {
  UserManagementEvents,
  UserManagementActions,
} from 'web/libs/mixpanelEvents';
import {
  getTeams,
  grantTeamAdmin,
  revokeTeamAdmin,
} from 'web/teams/actionCreators/teamsActionCreators';
import {
  getTeamMembers,
  deleteUser as deleteUserEntity,
  batchDestroyUsers,
} from 'web/teamMembers/actionCreators/teamMembersActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  closePopup,
  openPopup,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  shouldInvalidate as shouldInvalidateTeams,
  getMasterTeam,
} from 'web/teams/selectors/teamsSelectors';
import { shouldInvalidateMasterTeamMembers } from 'web/teamMembers/selectors/teamMembersSelectors';
import { UserManagementAlertIds } from '../libs/userManagementConstants';
import { UsersPopupIds } from '../libs/usersConstants';
import UsersActionTypes from '../libs/usersActionTypes';
import { usersTableExternals } from '../helpers/usersHelpers';
import map from 'lodash/map';
import { getUser } from 'web/user/actionCreators/userActionCreators';
import { isUserManagementConnectionStatusDisabled } from 'web/user/selectors/subscriptionSelectors';

export const setDeleteUserLoading = (loading = true) => ({
  loading,
  type: UsersActionTypes.setDeleteUserLoading,
});

export const setDeleteUsersLoading = (loading = true) => ({
  loading,
  type: UsersActionTypes.setDeleteUsersLoading,
});

export const setChangeRoleLoading = (loading = true) => ({
  loading,
  type: UsersActionTypes.setChangeRoleLoading,
});

export const usersStartup = () => async (dispatch, getState) => {
  if (
    !isUserManagementConnectionStatusDisabled(getState()) ||
    shouldInvalidateTeams(getState())
  ) {
    await dispatch(getTeams());
  }

  const masterTeam = getMasterTeam(getState());

  if (
    !isUserManagementConnectionStatusDisabled(getState()) ||
    shouldInvalidateMasterTeamMembers(getState())
  ) {
    await dispatch(getTeamMembers(masterTeam.id));
  }
};

export const reCalculateOpenSeatsCount = () => (dispatch) =>
  dispatch(getUser());

export const openDeleteUserConfirmationPopup = ({ id }) => (dispatch) => {
  dispatch(
    openPopup(
      UsersPopupIds.deleteUserConfirmation,
      { id },
      {
        analytics: {
          event: UserManagementEvents.userManagement,
          properties: {
            'Action Type': UserManagementActions.deleteUserConfirmationPopup,
          },
        },
      }
    )
  );
};

export const deleteUser = (id) => (dispatch) => {
  dispatch({
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.deleteUser,
        },
      },
    },
    payload: { id },
    type: UsersActionTypes.deleteUser,
  });
  dispatch(setDeleteUserLoading());
  dispatch(deleteUserEntity(id))
    .then(() => {
      usersTableExternals.clearSelectedIds();
      dispatch(closePopup());
      dispatch(reCalculateOpenSeatsCount());
    })
    .catch((errors) => {
      dispatch(
        openPopup(UsersPopupIds.deleteUserConfirmation, {
          errors,
          id,
        })
      );
    })
    .finally(() => {
      dispatch(setDeleteUserLoading(false));
    });
};

export const openDeleteUsersConfirmationPopup = (ids) => (dispatch) => {
  dispatch(
    openPopup(
      UsersPopupIds.deleteUsersConfirmation,
      {
        ids: map(ids, Number),
      },
      {
        analytics: {
          event: UserManagementEvents.userManagement,
          properties: {
            'Action Type': UserManagementActions.deleteUsersConfirmationPopup,
          },
        },
      }
    )
  );
};

export const deleteUsers = (ids) => (dispatch) => {
  dispatch({
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.deleteUsers,
          amount: ids.length,
        },
      },
    },
    payload: { ids },
    type: UsersActionTypes.deleteUsers,
  });
  dispatch(setDeleteUsersLoading());
  dispatch(batchDestroyUsers(ids))
    .then(() => {
      usersTableExternals.clearSelectedIds();
      dispatch(closePopup());
      dispatch(reCalculateOpenSeatsCount());
    })
    .catch((errors) => {
      dispatch(
        openPopup(UsersPopupIds.deleteUsersConfirmation, {
          errors,
          ids,
        })
      );
    })
    .finally(() => {
      dispatch(setDeleteUsersLoading(false));
    });
};

export const grantAdminRole = (userId) => (dispatch, getState) => {
  dispatch({
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.grantAdminRole,
        },
      },
    },
    payload: { userId },
    type: UsersActionTypes.grantAdminRole,
  });
  const masterTeam = getMasterTeam(getState());
  dispatch(setChangeRoleLoading());
  dispatch(grantTeamAdmin(masterTeam.id, userId))
    .catch(() => {
      dispatch(openViewAlert(UserManagementAlertIds.genericIssue));
    })
    .finally(() => {
      dispatch(setChangeRoleLoading(false));
    });
};

export const grantUserRole = (userId) => (dispatch, getState) => {
  dispatch({
    meta: {
      analytics: {
        event: UserManagementEvents.userManagement,
        properties: {
          'Action Type': UserManagementActions.grantUserRole,
        },
      },
    },
    payload: { userId },
    type: UsersActionTypes.grantUserRole,
  });
  const masterTeam = getMasterTeam(getState());
  dispatch(setChangeRoleLoading());
  dispatch(revokeTeamAdmin(masterTeam.id, userId))
    .catch(() => {
      dispatch(openViewAlert(UserManagementAlertIds.genericIssue));
    })
    .finally(() => {
      dispatch(setChangeRoleLoading(false));
    });
};
