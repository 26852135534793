import { updateAdminSettingsAndShowAlert } from './contentPartnerActionCreators';

export const updateTemplatesEditingDisabled = (isChecked) => (dispatch) => {
  const adminSettings = {
    admin_settings: {
      disable_templates_editing: isChecked,
    },
  };

  return dispatch(updateAdminSettingsAndShowAlert(adminSettings));
};

export const updateCampaignsEditingDisabled = (isChecked) => (dispatch) => {
  const adminSettings = {
    admin_settings: {
      disable_campaigns_editing: isChecked,
    },
  };

  return dispatch(updateAdminSettingsAndShowAlert(adminSettings));
};
