/*
 * Checks for null, empty or whitespaces in a string
 */
export const isNullOrWhiteSpace = (str) => str === null || !/\S/.test(str);

export const removeHtml = (html = '') => {
  const tmp = document.createElement('DIV'); //.replace(/<(?:.|\n)*?>/gm, '');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};
