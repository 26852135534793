import React from 'react';
import PropTypes from 'prop-types';
import PageTabNavBar from 'components/layouts/page/tabNavBar/pageTabNavBar';
import ComposeTemplatesNav from 'web/composeWindow/components/composeTemplatesNav/composeTemplatesNav';
import Chevron, {
  ChevronDirections,
  ChevronColors,
} from 'components/buttons/chevron';
import classNames from 'classnames';
import './composeTemplatesNavBar.scss';

const ComposeTemplatesNavBar = ({ toggleMinimize, isMinimized, ...rest }) => (
  <div
    className={classNames('compose-templates-nav-bar', {
      minimized: isMinimized,
    })}
    onClick={() => (isMinimized ? toggleMinimize(false) : null)}
  >
    <PageTabNavBar>
      <ComposeTemplatesNav isMinimized={isMinimized} {...rest} />
      <Chevron
        color={ChevronColors.gray}
        direction={
          isMinimized ? ChevronDirections.top : ChevronDirections.bottom
        }
        onClick={toggleMinimize}
      />
    </PageTabNavBar>
  </div>
);

ComposeTemplatesNavBar.propTypes = {
  hasRecentlyUsedTemplates: PropTypes.bool,
  isMinimized: PropTypes.bool.isRequired,
  toggleMinimize: PropTypes.func.isRequired,
};

ComposeTemplatesNavBar.defaultProps = {
  hasRecentlyUsedTemplates: false,
};

export default ComposeTemplatesNavBar;
