import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/buttons/icon';
import Chevron, { ChevronDirections } from 'components/buttons/chevron';
import './doubleEmailChevron.scss';

const DoubleEmailChevron = (props) => {
  const {
    bulkViewChangeHandoff,
    bulkViewOpened,
    isBulkDisabled,
    toggleBulkView,
  } = props;

  const classes = classNames('double-envelope-chevron', {
    'bulk-opened': bulkViewOpened,
    disabled: isBulkDisabled,
  });

  return (
    <div className={classes} onClick={handleBulkViewChange}>
      <Chevron color={null} direction={ChevronDirections.left} />
      <Icon className="tout-icon-double-email" color={null} />
    </div>
  );

  function handleBulkViewChange() {
    if (!isBulkDisabled) {
      toggleBulkView(!bulkViewOpened);
      bulkViewChangeHandoff();
    }
  }
};

DoubleEmailChevron.propTypes = {
  bulkViewChangeHandoff: PropTypes.func.isRequired,
  bulkViewOpened: PropTypes.bool,
  isBulkDisabled: PropTypes.bool,
  toggleBulkView: PropTypes.func.isRequired,
};

DoubleEmailChevron.defaultProps = {
  bulkViewOpened: false,
  isBulkDisabled: false,
};

export default DoubleEmailChevron;
