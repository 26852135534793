import PeopleActionTypes from '../libs/peopleActionTypes';

export const importLocalFile = (state = null, { type, file }) => {
  switch (type) {
    case PeopleActionTypes.import.setLocalFile:
      return file;
    case PeopleActionTypes.import.reset:
      return null;
    default:
      return state;
  }
};

export const importFieldMaps = (state = [], { type, fields, field }) => {
  let importFieldMapIndex;

  switch (type) {
    case PeopleActionTypes.import.setImportFieldMaps:
      return fields;
    case PeopleActionTypes.import.setImportFieldMap:
      importFieldMapIndex = state.findIndex(({ id }) => id === field.id);

      return [
        ...state.slice(0, importFieldMapIndex),
        {
          ...state[importFieldMapIndex],
          salesConnectField: field.salesConnectField,
        },
        ...state.slice(importFieldMapIndex + 1),
      ];
    case PeopleActionTypes.import.reset:
      return [];
    default:
      return state;
  }
};

export const salesConnectFieldList = (state = [], { type, fields }) => {
  switch (type) {
    case PeopleActionTypes.import.setSalesConnectFieldList:
      return fields;
    case PeopleActionTypes.import.reset:
      return [];
    default:
      return state;
  }
};

export const fromUserManagementPage = (
  state = [],
  { type, fromUserManagementPage }
) => {
  switch (type) {
    case PeopleActionTypes.import.setFromUserManagementPage:
      return fromUserManagementPage;
    default:
      return state;
  }
};

export const attachmentFileId = (state = null, { type, fileId }) => {
  switch (type) {
    case PeopleActionTypes.import.setAttachmentFileId:
      return fileId;
    case PeopleActionTypes.import.reset:
      return null;
    default:
      return state;
  }
};
