import { baseGet, basePost, basePut, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getCategoriesTemplates(viewer = {}) {
  return new Promise((resolve, reject) => {
    baseGet(Urls.templatesCategories, viewer, resolve, reject);
  });
}

export const getCategoriesTemplatesPermissioned = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.permissionsTemplatesCategories, {}, resolve, reject);
  });

export const getCategoriesTemplatesSharable = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.sharableCategories, {}, resolve, reject);
  });

export function createCategoryTemplate(name) {
  return new Promise((resolve, reject) => {
    basePost(Urls.templateCategories, { category: { name } }, resolve, reject);
  });
}

export function updateCategoryTemplate(id, category) {
  return new Promise((resolve, reject) => {
    basePut(`${Urls.templatesCategories}/${id}`, { category }, resolve, reject);
  });
}

export function deleteCategoryTemplate(id) {
  return new Promise((resolve, reject) => {
    baseDelete(`${Urls.templatesCategories}/${id}`, {}, resolve, reject);
  });
}
