import sortBy from 'lodash/sortBy';
import {
  DYNAMIC_FIELDS_SPECIAL_CATEGORY,
  DYNAMIC_FIELDS_CATEGORY,
} from 'libs/constantsShared';

function formatDynamicFieldLabel(name = '') {
  name = name.replace(/[{}]/g, '').replace(/_/g, ' ');
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function parseDynamicField(field, category) {
  return {
    category,
    label: formatDynamicFieldLabel(field),
    value: field,
  };
}

export function parseDynamicFields(data = {}) {
  let dynamicFields = [];
  Object.keys(data).forEach((key) => {
    const array = data[key].map((field) => parseDynamicField(field, key));
    dynamicFields = dynamicFields.concat(array);
  });
  return sortBy(dynamicFields, 'label');
}

export const DYNAMIC_FIELDS_SPECIAL_CATEGORY_MAP = Object.values(
  DYNAMIC_FIELDS_SPECIAL_CATEGORY
).reduce((map, categoryValue) => {
  map[categoryValue] = categoryValue;
  return map;
}, {});

export const localizeDynamicFieldsMap = (category) =>
  !!DYNAMIC_FIELDS_SPECIAL_CATEGORY_MAP[category];

export const DYNAMIC_CATEGORY_MAP = Object.values(
  DYNAMIC_FIELDS_CATEGORY
).reduce((map, categoryValue) => {
  map[categoryValue] = categoryValue;
  return map;
}, {});

export const localizeCategoryMap = (category) =>
  !!DYNAMIC_CATEGORY_MAP[category];
