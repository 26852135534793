import React from 'react';
import PropTypes from 'prop-types';
import Label from 'components/label';
import Select from 'components/selects/default';

const SIDE_LABEL_WIDTH = 70;

const AddToCampaignFilter = ({
  items,
  message,
  messageKey,
  onChange,
  selectedId,
}) => (
  <div className="margin-bottom-full">
    <Label
      id="select-campaign-category"
      messageKey={messageKey}
      message={message}
      width={SIDE_LABEL_WIDTH}
      sideLabel
    >
      <Select items={items} onChange={onChange} selected={selectedId} full />
    </Label>
  </div>
);

AddToCampaignFilter.propTypes = {
  items: PropTypes.array.isRequired,
  message: PropTypes.string,
  messageKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedId: PropTypes.number.isRequired,
};

AddToCampaignFilter.defaultProps = {
  message: '',
  messageKey: '',
};

export default AddToCampaignFilter;
