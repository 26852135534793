import { DEFAULT_DELIVERY_CHANNEL } from '../libs/deliveryChannelsConstants';

export const getDeliveryChannelLabel = ({
  deliveryChannels = [],
  deliveryChannel,
  formatMessage,
  shouldHideDeliveryChannels,
}) => {
  if (deliveryChannel) {
    return formatMessage(
      { id: 'web.identities.deliveryChannelLabel' },
      deliveryChannel
    );
  }

  if (shouldHideDeliveryChannels) {
    if (deliveryChannels.length) {
      return formatMessage({ id: 'web.identities.chooseDeliveryChannel' });
    } else {
      return formatMessage({ id: 'web.identities.setDeliveryChannel' });
    }
  }

  return formatMessage({ id: 'web.identities.defaultDeliveryChannelLabel' });
};

export const composeDeliveryChannels = (
  deliveryChannels = [],
  shouldHideDeliveryChannels = false
) => {
  const composedDeliveryChannels = [...deliveryChannels];

  if (
    !shouldHideDeliveryChannels ||
    (shouldHideDeliveryChannels && !deliveryChannels.length)
  ) {
    composedDeliveryChannels.unshift(DEFAULT_DELIVERY_CHANNEL);
  }

  return composedDeliveryChannels;
};
