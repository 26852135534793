import {
  EMAIL_FETCH_REQUEST_SEARCH_COLUMNS,
  CommandCenterEmailsTabsEnum,
  EmailStates,
  DATE_COLUMNS,
} from 'web/commandCenter/libs/commandCenterConstants';
import {
  FILTER_SEARCH_ID,
  FILTER_SEARCH_STATUS_ID,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { campaignFilterValues } from 'web/commandCenter/modules/campaignSmartFilter/libs/campaignSmartFilterConstants';
import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';
import { getAdvancedSearchString } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';
import { getAdvancedSearchStatus } from 'web/commandCenter/modules/statusSmartFilter/selectors/statusSmartFilterSelectors';
import {
  getDateRange,
  getSelectedValue,
} from 'web/tables/dateRangeSmartFilter/selectors/dateRangeSmartFilterSelectors';
import { getFilterDatesRequired } from 'web/tables/helpers/itemsTableFilters';
import { getEmailGridCampaingFilterValue } from 'web/commandCenter/modules/campaignSmartFilter/selectors/campaignSmartFilterSelectors';
import { getSentStatusFilterFromSubTabs } from 'web/commandCenter/helpers/emailHelpers';
import { getSelectedUserForViewAsFilter } from 'web/commandCenter/modules/whoSmartFilter/selectors/viewAsFilterSelectors';
import { getSelectedPersonFilter } from 'web/commandCenter/modules/whoSmartFilter/selectors/peopleFilterSelectors';
import { getSelectedReferenceDate } from 'web/commandCenter/modules/whenSmartFilter/selectors/referenceDateSmartFilterSelectors';
import { getEmailGridPeopleGroupFilterValue } from 'web/commandCenter/modules/whoSmartFilter/selectors/peopleGroupFilterSelectors';

export const getEmailFetchRequestParams = (state) => {
  const { commandCenterEmailsSubTab } = state;

  const stateMap = {
    [CommandCenterEmailsTabsEnum.sent.subTabs.delivered
      .value]: getDeliveredRequestParams,
    [CommandCenterEmailsTabsEnum.sent.subTabs.archived
      .value]: getArchivedRequestParams,

    [CommandCenterEmailsTabsEnum.pending.subTabs.scheduled
      .value]: getScheduledRequestParams,
    [CommandCenterEmailsTabsEnum.pending.subTabs.drafts
      .value]: getDraftsRequestParams,
    [CommandCenterEmailsTabsEnum.pending.subTabs.inprogress
      .value]: getInprogressRequestParams,

    [CommandCenterEmailsTabsEnum.undelivered.subTabs.failed
      .value]: getFailedRequestParams,
    [CommandCenterEmailsTabsEnum.undelivered.subTabs.bounced
      .value]: getBouncedRequestParams,
    [CommandCenterEmailsTabsEnum.undelivered.subTabs.spam
      .value]: getSpamRequestParams,
    [CommandCenterEmailsTabsEnum.search.subTabs.advanced
      .value]: getAdvancedSearchRequestParams,
    [CommandCenterEmailsTabsEnum.search.subTabs.saved
      .value]: getAdvancedSearchRequestParams,
    [CommandCenterEmailsTabsEnum.search.subTabs.groupEmail
      .value]: getAdvancedSearchEmailGroupRequestParams,
    [CommandCenterEmailsTabsEnum.search.subTabs.selectedEmail
      .value]: getSelectedEmailRequestParams,
  };

  // eslint-disable-next-line no-restricted-syntax
  return commandCenterEmailsSubTab in stateMap
    ? stateMap[commandCenterEmailsSubTab](state)
    : {};
};

const getRangeDates = (state) => {
  const { end, start } = getDateRange(state);
  const selectedValue = getSelectedValue(state);

  const actualDates = getFilterDatesRequired(selectedValue, start, end);

  const result = {
    end_range: actualDates.end.toISOString(),
    start_range: actualDates.start.toISOString(),
  };

  return result;
};

const applyStatusFilter = (state, params) => {
  const tableId = getActiveEmailTableId(state);

  const { subTabIds, tabId } = getAdvancedSearchStatus(state, {
    collectionId: tableId,
    filterId: FILTER_SEARCH_STATUS_ID,
  });
  const statusFilter = getSentStatusFilterFromSubTabs(tabId, subTabIds);
  return {
    ...params,
    filters: {
      ...params.filters,
      ...statusFilter.filters,
    },
    not_filters: {
      ...params.not_filters,
      ...statusFilter.notFilters,
    },
  };
};

const applyPersonFilter = (state, params) => {
  let resultParams = { ...params };
  const person = getSelectedPersonFilter(state);
  if (person && person.id) {
    resultParams = {
      ...resultParams,
      must: {
        ...resultParams.must,
        person_id: person.id,
      },
    };
  }

  return resultParams;
};

const getBaseRequestParams = (state) => {
  const {
    emailPage,
    sidebarViewer: { id: userId, type: userType },
    emailsPerPage,
    emailsSort: { column, direction },
    emailsSortByGroups,
  } = state;

  const isGroupFetch =
    emailsSortByGroups &&
    emailsSortByGroups.checked &&
    !emailsSortByGroups.disable;

  const emailSearchString = getAdvancedSearchString(state, {
    collectionId: getActiveEmailTableId(state),
    filterId: FILTER_SEARCH_ID,
  });

  const fetchData = {
    ...(emailSearchString
      ? {
          text_search: {
            fields: EMAIL_FETCH_REQUEST_SEARCH_COLUMNS,
            term: emailSearchString,
          },
        }
      : {}),
    filters: {
      archived: false,
    },
    mode: 'all',
    not_filters: {},
    page: emailPage,
    per_page: emailsPerPage,
    sort_filters: {
      column: DATE_COLUMNS.includes(column) ? column : `${column}.sortable`,
      direction,
    },
    type: 'pitch',
    viewer: {
      id: userId,
      type: userType,
    },
  };

  if (isGroupFetch) {
    fetchData.blank = 'parent_pitch_id';
  }

  return fetchData;
};

const getDeliveredRequestParams = (state) => ({
  ...getBaseRequestParams(state),
  date_range: {
    delivered_at: getRangeDates(state),
  },
  must: {
    state: [
      EmailStates.sent,
      EmailStates.clicked,
      EmailStates.replied,
      EmailStates.successful,
      EmailStates.viewed,
    ],
  },
});

const getArchivedRequestParams = (state) => ({
  ...getDeliveredRequestParams(state),
  date_range: {
    delivered_at: getRangeDates(state),
  },
  filters: {
    archived: true,
  },
});

const getScheduledRequestParams = (state) => ({
  ...getBaseRequestParams(state),
  date_range: {
    send_at: getRangeDates(state),
  },
  must: {
    state: EmailStates.scheduledForDelivery,
  },
});

const getDraftsRequestParams = (state) => ({
  ...getBaseRequestParams(state),
  date_range: {
    created_at: getRangeDates(state),
  },
  must: {
    state: EmailStates.draft,
  },
});

const getInprogressRequestParams = (state) => ({
  ...getBaseRequestParams(state),
  date_range: {
    created_at: getRangeDates(state),
  },
  must: {
    state: EmailStates.deliveryInProgress,
  },
});

const getFailedRequestParams = (state) => ({
  ...getBaseRequestParams(state),
  date_range: {
    created_at: getRangeDates(state),
  },
  must: {
    state: EmailStates.failedDelivery,
  },
});

const getBouncedRequestParams = (state) => ({
  ...getBaseRequestParams(state),
  date_range: {
    created_at: getRangeDates(state),
  },
  must: {
    state: EmailStates.bounced,
  },
});

const getSpamRequestParams = (state) => ({
  ...getBaseRequestParams(state),
  date_range: {
    created_at: getRangeDates(state),
  },
  must: {
    state: EmailStates.spam,
  },
});

const getAdvancedSearchRequestParams = (state) => {
  const referenceDate = getSelectedReferenceDate(state);
  let params = {
    ...getBaseRequestParams(state),
    date_range: {
      [referenceDate]: getRangeDates(state),
    },
  };

  // add advanced search filter functions
  params = applyStatusFilter(state, params);
  params = applyCampaignFilter(state, params);
  params = applyViewAsFilter(state, params);
  params = applyPersonFilter(state, params);
  params = applyPeopleGroupFilter(state, params);

  return params;
};

const getAdvancedSearchEmailGroupRequestParams = (state) => {
  const { searchGroupEmailId } = state;
  return {
    ...getBaseRequestParams(state),
    must: {
      parent_pitch_id: searchGroupEmailId,
    },
  };
};

const getSelectedEmailRequestParams = (state) => {
  const { emailGridSelectedEmailId } = state;
  return {
    ...getBaseRequestParams(state),
    must: {
      id: emailGridSelectedEmailId,
    },
  };
};

const applyViewAsFilter = (state, params) => {
  const resultParams = { ...params };
  const viewAsViewer = getSelectedUserForViewAsFilter(state);
  if (viewAsViewer && viewAsViewer.id) {
    resultParams.viewer = viewAsViewer;
  }

  return resultParams;
};

const applyPeopleGroupFilter = (state, params) => {
  const resultParams = { ...params };
  const peopleGroupFilterValue = getEmailGridPeopleGroupFilterValue(state);
  if (peopleGroupFilterValue && peopleGroupFilterValue >= 0) {
    resultParams.must = {
      ...resultParams.must,
      'groups.group_id': peopleGroupFilterValue,
    };
  }

  return resultParams;
};

const applyCampaignFilter = (state, params) => {
  const resultParams = { ...params };
  const campaignFilterValue = getEmailGridCampaingFilterValue(state);

  if (campaignFilterValue) {
    switch (campaignFilterValue) {
      case campaignFilterValues.nonCampaignEmails:
        if (resultParams.blank && resultParams.blank.length) {
          resultParams.blank.push('workflow_id');
        } else {
          resultParams.blank = 'workflow_id';
        }
        break;
      case campaignFilterValues.campaignEmails:
        if (resultParams.not_blank && resultParams.not_blank.length) {
          resultParams.not_blank.push('workflow_id');
        } else {
          resultParams.not_blank = 'workflow_id';
        }
        break;
      default:
        if (typeof campaignFilterValue === 'number') {
          if (resultParams.must) {
            resultParams.must.workflow_id = campaignFilterValue;
          } else {
            resultParams.must = { workflow_id: campaignFilterValue };
          }
        }
    }
  }

  return resultParams;
};
