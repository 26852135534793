import { basePost, basePut, baseGet, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/constants';
import {
  commonUserAdminTeamPermission,
  getAdminTeams,
} from 'web/shared/services/accountService';
import map from 'lodash/map';
import find from 'lodash/find';

export function createCampaign(data) {
  return new Promise((resolve, reject) => {
    basePost(
      `${Urls.campaignsBase}.json`,
      {
        workflow: {
          category: data.category,
          group_email_task_action: 'task_per_person',
          name: data.name.trim(),
          skip_weekends: data.skipWeekends,
          stop_on_bounce: data.stopOnBounce,
          stop_on_reply: data.stopOnReply,
          success_on_reply: data.successOnReply,
        },
      },
      resolve,
      reject
    );
  });
}

export function getCampaign(id) {
  return new Promise((resolve, reject) => {
    baseGet(`${Urls.campaignsBase}/${id}`, {}, resolve, reject);
  });
}

export function getCampaigns(params = {}) {
  return new Promise((resolve, reject) => {
    baseGet(Urls.campaigns, params, resolve, reject);
  });
}

export function updateCampaign(data) {
  return new Promise((resolve, reject) => {
    basePut(
      `${Urls.campaignsBase}/${data.id}`,
      { workflow: data },
      resolve,
      reject
    );
  });
}

// Accepts params[:name]
// params[:clone_steppables]
// params[:category]
export function cloneCampaign(id, data = {}) {
  return new Promise((resolve, reject) => {
    basePost(Urls.cloneCampaign.replace(':#id', id), data, resolve, reject);
  });
}

// Accepts params[:team_ids]
// params[:comment]
export function shareCampaign(id = 1, data = { comment: '', team_ids: [] }) {
  return new Promise((resolve, reject) => {
    basePost(Urls.shareCampaign.replace(':#id', id), data, resolve, reject);
  });
}

// Archive a workflow
export function deleteCampaign(id) {
  return new Promise((resolve, reject) => {
    baseDelete(`${Urls.campaignsBase}/${id}`, {}, resolve, reject);
  });
}

export function getActiveCampaigns() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.campaignsActive, {}, resolve, reject);
  });
}

export function getActivePeople(id, getAll) {
  return new Promise((resolve, reject) => {
    const data = {};
    if (getAll) {
      data.options = { all: true };
    }
    basePost(
      Urls.campaignActivePeople.replace(':#id', id),
      data,
      resolve,
      reject
    );
  });
}

export function getCampaignCurrentUsers(id, teams) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.campaignCurrentUsers.replace(':#id', id),
      teams,
      resolve,
      reject
    );
  });
}

export function getEmailLimits(ids) {
  return new Promise((resolve, reject) => {
    basePost(Urls.emailLimits, { person_ids: ids }, resolve, reject);
  });
}

export function findCampaign(campaigns, id) {
  return find(campaigns, { id }) || {};
}

// Permissions functions
export function canEditCampaign(campaign = {}) {
  return commonUserAdminTeamPermission(campaign.user_id);
}

export function canShareCampaign(campaign = {}) {
  return commonUserAdminTeamPermission(campaign.user_id);
}

export function getActiveCountForTeams(campaign, teams = []) {
  let activeCount = 0;
  if (campaign.current_users) {
    campaign.current_users.forEach((user) => {
      // TODO - intersection
      for (let i = 0; i < teams.length; i++) {
        if (user.team_ids.indexOf(teams[i]) > -1) {
          activeCount += 1;
          break;
        }
      }
    });
  }
  return activeCount;
}

export function canUnshareCampaign(campaign = {}, teamId) {
  const account = window.toutAppBootstrap.accountData;
  // master admin or team admin of unshared team or author and no active people
  return (
    account.admin === true ||
    map(getAdminTeams(), 'id').indexOf(teamId) > -1 ||
    (campaign.user_id === account.id &&
      !getActiveCountForTeams(campaign, [teamId]))
  );
}
