import {
  SalesforceCustomizationPopupIds,
  SalesforceCustomizationOverlayIds,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import {
  openPopup,
  closePopup,
  setPopupOverlay,
} from 'web/popup/actionCreators/popupActionCreators';
import { isProcessingStatus } from 'web/modals/salesforceCustomization/helpers/salesforceCustomizationHelpers';

export const openSalesforceProgressPopup = (popupId) => (dispatch) => {
  dispatch(openPopup(popupId));
};

export const openSalesforcePostUpdatePopup = (popupId) => (
  dispatch,
  getState
) => {
  const { popup } = getState();

  if (
    popup ===
      SalesforceCustomizationPopupIds.salesforceCustomizationInstallation ||
    popup ===
      SalesforceCustomizationPopupIds.salesforceCustomizationUninstallation
  ) {
    dispatch(openPopup(popupId));
  }
};

export const showExitWarningOverlay = () => (dispatch) => {
  dispatch(
    setPopupOverlay(
      SalesforceCustomizationOverlayIds.salesforceCustomizationExitWarning
    )
  );
};

export const closeSalesforcePopup = () => (dispatch) => {
  dispatch(closePopup());
};

export const closeOrShowOverlay = () => (dispatch, getState) => {
  const { status } = getState().salesforceCustomization;

  if (isProcessingStatus(status)) {
    dispatch(showExitWarningOverlay());
  } else {
    dispatch(closeSalesforcePopup());
  }
};
