import TeamSettingsActionTypes from '../libs/teamSettingsActionTypes';

export function teamSettingsFetched(state = false, action) {
  switch (action.type) {
    case TeamSettingsActionTypes.setTeamSettings:
      return true;
    case TeamSettingsActionTypes.setTeamSettingsFetched:
      return action.fetched;
    default:
      return state;
  }
}

export function teamSettingsFetching(state = false, action) {
  switch (action.type) {
    case TeamSettingsActionTypes.setTeamSettings:
    case TeamSettingsActionTypes.setTeamSettingsError:
      return false;
    case TeamSettingsActionTypes.setTeamSettingsFetching:
      return true;
    default:
      return state;
  }
}

export function teamSettings(state = null, action) {
  switch (action.type) {
    case TeamSettingsActionTypes.setTeamSettings:
      return action.teamSettings;
    default:
      return state;
  }
}
