import { createReducer } from '@reduxjs/toolkit';

import {
  copySmartFilters,
  setSmartFilterType,
  removeSmartFilterType,
  setSmartFilterCollection,
} from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';

/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
export const smartFilters = createReducer(
  {},
  {
    [copySmartFilters.type]: (state, action) => {
      const { collectionFromId, collectionToId } = action.payload;
      state[collectionToId] = state[collectionFromId];
    },
    [removeSmartFilterType]: (state, action) => {
      const { collectionId, filterId } = action.payload;
      if (state[collectionId] && state[collectionId][filterId]) {
        const { [filterId]: skip, ...otherFilters } = state[collectionId];
        state[collectionId] = otherFilters;
      }
    },
    [setSmartFilterType]: (state, action) => {
      const { collectionId, filterId, payload: filterValue } = action.payload;
      if (!state[collectionId]) {
        state[collectionId] = {};
      }
      state[collectionId][filterId] = filterValue;
    },
    [setSmartFilterCollection.type]: (state, action) => {
      const { collectionId, samrtFiltersCollection } = action.payload;
      state[collectionId] = samrtFiltersCollection;
    },
  }
);
