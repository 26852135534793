import {
  getCustomFieldsFromEditState,
  getInfoFromEditState,
} from './personDetailsEditStateParsers';
import { validateCustomFields } from './personHelpers';
import {
  getInvalidAddressAlert,
  getMissingSalesforceFieldsFromEditState,
  isMissingRequiredFields,
} from './personDetailsValidations';
import {
  PersonDetailsCards,
  PersonDetailsTabAlertIds,
} from '../libs/personDetailsConstants';
import {
  addPerson as addPersonCall,
  isContactExists as isContactExistsCall,
} from 'web/people/services/peopleService';
import { getGroupsFromEditState } from 'web/person/helpers/personDetailsEditStateParsers';
import { TaskType } from 'libs/constantsShared';

export class ContactDuplicateError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ContactDuplicateError';
  }
}

export class ContactValidationError extends Error {
  constructor(message, alertId = null, textValue = {}) {
    super(message);
    this.name = 'ContactValidationError';
    this.alertId = alertId;
    this.textValue = textValue;
  }
}

export const isContactExists = async (person, userId) => {
  const primaryAddress = person.addresses.find(
    (address) => address.is_primary && address.address_type === TaskType.email
  );
  if (primaryAddress) {
    try {
      await isContactExistsCall(
        primaryAddress.address,
        userId,
        primaryAddress.id
      );
    } catch (e) {
      throw new ContactDuplicateError(
        'Contact with such primary email already exists'
      );
    }
  }
};

export const validateContactEmail = (person) => {
  const alertMessages = [];
  person.addresses
    .filter((address) => address.address_type === TaskType.email)
    .forEach((email) => {
      const invalid = getInvalidAddressAlert(email.address_type, email.address);
      if (invalid && invalid.length > 0) {
        alertMessages.push(invalid);
      }
    });
  if (alertMessages.length > 0) {
    throw new ContactValidationError(
      'Invalid email',
      PersonDetailsTabAlertIds.invalidEmail,
      alertMessages.pop()[1]
    );
  }
};

export const validateAndCreate = async (dispatch, getState) => {
  const {
    personDetailsGroupsEditState: { groupsByAddressId: editStateGroups },
    user,
  } = getState();
  let currentGroupIds = [];
  const groups = getGroupsFromEditState(editStateGroups);
  if (groups) {
    currentGroupIds = groups.map((group) => group.group_id);
  }

  const customFieldsAPI = getCustomFieldsFromEditState(getState());
  const { personDetailsCustomFieldsEditState } = getState();
  const validCustomFieldsAlert = validateCustomFields(
    personDetailsCustomFieldsEditState
  );
  if (validCustomFieldsAlert) {
    throw new ContactValidationError(
      'Invalid custom fields',
      ...validCustomFieldsAlert
    );
  }

  const state = getState();
  if (
    isMissingRequiredFields(
      PersonDetailsCards.info,
      state.personDetailsInfoEditState
    )
  ) {
    throw new ContactValidationError(
      'Missing required fields',
      PersonDetailsTabAlertIds.missingRequiredFields
    );
  }

  const requiredFields = getMissingSalesforceFieldsFromEditState(state);
  if (Object.keys(requiredFields).length) {
    throw new ContactValidationError(
      'Missing required Salesforce fields',
      PersonDetailsTabAlertIds.missingRequiredSalesforceFields
    );
  }

  const personAPI = getInfoFromEditState(state);

  const person = {
    ...personAPI,
    ...customFieldsAPI,
    group_ids: currentGroupIds,
  };
  validateContactEmail(personAPI, dispatch);
  await isContactExists(person, user.id);
  return addPersonCall(person);
};
