import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import OnboardingActionButtons from 'components/popups/popupFooter/popupFooter';
import { DeliveryChannelProviders } from 'web/deliveryChannels/libs/deliveryChannelsConstants';
import onboardingPopupIds from 'web/onboarding/lib/onboardingPopupIds';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import EmailConnectionSelection from 'web/onboarding/components/emailConnectionSelection/emailConnectionSelection';
import OutlookSelection from 'web/onboarding/components/outlookSelection/outlookSelection';
import { isOnboardingStepPassed } from 'web/user/selectors/userSelectors';
import { EmailProviders } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';
import {
  connectEmail,
  localStorageChecker,
} from 'web/settings/emailConnection/helpers/emailConnectionHelpers';
import { navigateToUrl } from 'web/services/routerService';
import { updateOnboardingSettings } from 'web/user/actionCreators/userActionCreators';
import { stepsNames } from 'web/onboarding/lib/stepsRouting';
import { getDeliveryChannelsAc } from 'web/onboarding/actionCreators/onboardingActionCreators';
import { setCompletedStep } from 'web/onboarding/actionCreators/onboardingActionCreators';

const skipStepPopupData = {
  primaryTextID: 'web.onboarding.popup.skipEmailConnectionStep.textPrimary',
  secondaryTextId: 'web.onboarding.popup.skipEmailConnectionStep.textSecondary',
};

const EmailConnectionStep = ({
  getDeliveryChannelsAc,
  isEmailStepPassed,
  openPopup,
  setCompletedStep,
  updateOnboardingSettings,
}) => {
  const [currentProvider, setCurrentProvider] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [connectionSuccess, setConnectionSuccess] = useState(false);
  const [lastStep, setStep] = useState(false);
  const [connectedProvider, setConnectedProvider] = useState('');
  const [connectedEmail, setConnectedEmail] = useState('');

  if (isEmailStepPassed) {
    getDeliveryChannelsAc().then((data) => {
      const currentData = data.find(
        (item) =>
          item.provider.indexOf(DeliveryChannelProviders.gmail) !== -1 ||
          item.provider.indexOf(DeliveryChannelProviders.office365) !== -1 ||
          item.provider.indexOf(DeliveryChannelProviders.exchangeOnPrem) !== -1
      );
      const { provider, username } = currentData;

      const connectedProvider =
        provider.indexOf(DeliveryChannelProviders.gmail) !== -1
          ? 'Gmail'
          : 'Outlook';

      setConnectedProvider(connectedProvider);
      setConnectedEmail(username);
      setLoading(false);
    });
  }

  const connect = (provider) => {
    if (provider == EmailProviders.onPremise) {
      openPopup(onboardingPopupIds.onPremiseConnect, {});
    } else {
      connectEmail(provider, currentStep);
    }
    setLoading(true);
    setStep(true);

    localStorageChecker('onboardingEmailAuthSuccess', () => {
      setLoading(false);
      setConnectionSuccess(true);
      updateOnboardingSettings(stepsNames.emailConnection);
      setCompletedStep(2);

      setTimeout(() => {
        navigateToUrl('#onboarding/email_signature');
      }, 2000);
    });
  };

  const onPrimaryHandler = (emailProvider) => {
    switch (emailProvider) {
      case EmailProviders.gmail:
        connect(EmailProviders.gmail);
        break;
      case EmailProviders.onPremise:
        connect(EmailProviders.onPremise);
        break;
      case EmailProviders.office365:
        connect(EmailProviders.office365);
        break;
      case EmailProviders.outlook:
        setCurrentStep(1);
        break;
      default:
        break;
    }
  };

  const isShow = !lastStep && !loading;
  const connectionSuccessLocal = localStorage.getItem(
    'onboardingEmailAuthSuccess'
  );
  const isConnecting =
    isShow && !connectionSuccessLocal && !currentStep && !currentProvider;

  return (
    <React.Fragment>
      {loading ? (
        <div className="onboarding-spinner-wrapper">
          <LoadingSpinner imageUrl={getImageUrl} size={130} />
        </div>
      ) : null}
      {connectionSuccess || connectionSuccessLocal ? (
        <div className="onboarding-success">
          <span className="onboarding-success-icon" />
          <FormattedMessage id={'web.onboarding.title.success'} />
        </div>
      ) : null}
      {isConnecting ? (
        <React.Fragment>
          {isEmailStepPassed ? (
            <>
              <div className="email-connected-wrapper">
                <FormattedHTMLMessage
                  id={'web.onboarding.emailConnectedPage.setupCDC'}
                  values={{
                    email: connectedEmail,
                    provider: connectedProvider,
                  }}
                />
                <div className="connected">
                  <div className="connected-icon-wrapper">
                    <i className="connected-icon" />
                  </div>
                  <FormattedMessage id="web.onboarding.connected.crm.completed" />
                </div>
              </div>
              <OnboardingActionButtons
                onPrimary={() => {
                  setCompletedStep(2);
                  navigateToUrl('#onboarding/email_signature');
                }}
                primaryTextId={'common.next'}
                full
              />
            </>
          ) : (
            <>
              <div className="email-connection-list-item">
                <p className="onboarding-connection-main-text">
                  <FormattedMessage
                    id={'web.onboarding.emailConnectionPage.setupDefault'}
                  />
                </p>
              </div>
              <p className="email-connection-which-client">
                <FormattedMessage
                  id={'web.onboarding.emailConnectionPage.whichClient'}
                />
              </p>
              <EmailConnectionSelection
                emailConnectionProvider={currentProvider}
                onClickEvent={onPrimaryHandler}
                setEmailProvider={setCurrentProvider}
              />
              <OnboardingActionButtons
                className="onboarding-action-buttons-container"
                onSecondary={() =>
                  openPopup(onboardingPopupIds.skipStep, skipStepPopupData)
                }
                secondaryTextId={'common.skip'}
                full
              />
            </>
          )}
        </React.Fragment>
      ) : null}
      {isShow && !connectionSuccessLocal && !currentStep && currentProvider ? (
        <React.Fragment>
          <div className="email-connection-list-item">
            <p className="onboarding-connection-main-text">
              <FormattedMessage
                id={'web.onboarding.emailConnectionPage.setupDefault'}
              />
            </p>
          </div>
          <p className="email-connection-which-client">
            <FormattedMessage
              id={'web.onboarding.emailConnectionPage.whichClient'}
            />
          </p>
          <EmailConnectionSelection
            emailConnectionProvider={currentProvider}
            onClickEvent={onPrimaryHandler}
            setEmailProvider={setCurrentProvider}
          />
          <OnboardingActionButtons
            onPrimary={onPrimaryHandler}
            primaryTextId={'common.next'}
            full
          />
        </React.Fragment>
      ) : null}
      {isShow && !connectionSuccessLocal && currentStep ? (
        <React.Fragment>
          <OutlookSelection
            emailConnectionProvider={currentProvider}
            onClickEvent={onPrimaryHandler}
            setEmailProvider={setCurrentProvider}
          />
          <OnboardingActionButtons
            onSecondary={() => {
              setCurrentProvider('');
              setCurrentStep(0);
            }}
            secondaryTextId={'common.back'}
            full
          />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

EmailConnectionStep.propTypes = {
  getDeliveryChannelsAc: PropTypes.func.isRequired,
  isEmailStepPassed: PropTypes.bool,
  openPopup: PropTypes.func.isRequired,
  setCompletedStep: PropTypes.func.isRequired,
  updateOnboardingSettings: PropTypes.func.isRequired,
  userSettings: PropTypes.object,
};

EmailConnectionStep.defaultProps = {
  isEmailStepPassed: undefined,
  userSettings: {},
};

const mapStateToProps = (state) => ({
  isEmailStepPassed: isOnboardingStepPassed(state, stepsNames.emailConnection),
  userSettings: state.userSettings,
});

const mapDispatchToProps = {
  getDeliveryChannelsAc,
  openPopup,
  setCompletedStep,
  updateOnboardingSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConnectionStep);
