import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { ContentPartnerActions } from '../../lib/addContentConstants';
import { Regex } from 'web/libs/constants';

export default class ContentPartnerIframe extends Component {
  componentWillMount() {
    const { partner, getPartnerMetadata } = this.props;
    getPartnerMetadata(partner.picker_url);

    if (window.addEventListener) {
      window.addEventListener('message', this.injectContent, false);
    } else {
      window.attachEvent('onmessage', this.injectContent);
    }
  }

  componentWillUnmount() {
    if (window.removeEventListener) {
      window.removeEventListener('message', this.injectContent);
    } else {
      window.detachEvent('message', this.injectContent);
    }
  }

  render() {
    const { partner, partnerMetadata } = this.props;

    return (
      <div className="modal-background">
        <iframe
          frameBorder="0"
          height={partnerMetadata.height}
          src={partner.picker_url}
          width={partnerMetadata.width}
        />
      </div>
    );
  }

  injectContent = (evt) => {
    const { partner, closePopup, windowMode, addContent } = this.props;
    const regex = new RegExp(Regex.partnerUrl + partner.origin);
    if (
      regex.test(evt.origin) &&
      evt.data.action === ContentPartnerActions.insertContent
    ) {
      if (windowMode) {
        window.sendToOutlook(JSON.stringify(evt.data));
      } else if (addContent) {
        addContent(evt.data);
      } else {
        toutBackboneHelper.insertContentPartnerLinks(evt.data);
      }
    }

    closePopup();
  };
}

ContentPartnerIframe.propTypes = {
  addContent: PropTypes.func,
  closePopup: PropTypes.func.isRequired,
  getPartnerMetadata: PropTypes.func.isRequired,
  partner: PropTypes.object.isRequired,
  partnerMetadata: PropTypes.object.isRequired,
  windowMode: PropTypes.bool.isRequired,
};

ContentPartnerIframe.defaultProps = {
  addContent: null,
};
