import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import './slideOutPane.scss';

const SHADOW_PIXELS = 6;

class SlideOutPane extends PureComponent {
  constructor(props) {
    super(props);
    this.leaveAnimationTimeout = null;

    this.state = {
      showChildren: props.open,
    };
  }

  /* Delay is needed to remove children so that children are unmounted but only once slide out animation is done */
  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState(() => ({ showChildren: true }));
    } else if (!nextProps.open && this.props.open) {
      this.leaveAnimationTimeout = setTimeout(() => {
        this.setState(() => ({ showChildren: false }));
      }, nextProps.transitionDuration);
    } else if (nextProps.open && this.leaveAnimationTimeout) {
      clearTimeout(this.leaveAnimationTimeout);
    }
  }

  componentWillUnmount() {
    if (this.leaveAnimationTimeout) {
      clearTimeout(this.leaveAnimationTimeout);
    }
  }

  render() {
    const { backgroundColor, children, className, open } = this.props;
    const innerClassName = classNames('slide-out-pane-inner', {
      open,
      [`background-${backgroundColor}`]: backgroundColor,
    });
    const { showChildren } = this.state;

    return (
      <div
        className={classNames('slide-out-pane', className, { open })}
        style={this._getStylesContainer()}
      >
        <div className={innerClassName} style={this._getStyles()}>
          {showChildren && children}
        </div>
      </div>
    );
  }

  _getStylesContainer = () => {
    const { width } = this.props;

    return {
      width: `${width + SHADOW_PIXELS}px`,
    };
  };

  _getStyles = () => {
    const { open, transitionDuration, width } = this.props;
    const widthPx = `${width}px`;
    let transition = 'none';

    if (transitionDuration) {
      const transitionRight = `right ${transitionDuration}ms ease-in-out`;
      const transitionOpacity =
        (open && 'opacity 0s') || `opacity 0.01s ${transitionDuration}ms ease`;
      transition = `${transitionRight}, ${transitionOpacity}`;
    }

    return {
      right: (!open && `-${widthPx}`) || 0,
      transition,
      width: widthPx,
    };
  };
}

export const PaneBackgroundColors = BackgroundColors;

SlideOutPane.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(PaneBackgroundColors)),
  children: PropTypes.node,
  className: PropTypes.string,
  open: PropTypes.bool,
  transitionDuration: PropTypes.number,
  width: PropTypes.number,
};

SlideOutPane.defaultProps = {
  backgroundColor: PaneBackgroundColors.white,
  children: <div />,
  className: '',
  open: false,
  transitionDuration: 250,
  width: 580,
};

export default SlideOutPane;
