import { createSelector } from 'reselect';
import { BlockTypeItems } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';

const getUnsubscribeFormBlockTypeSelected = (state) =>
  state.unsubscribeFormBlockTypeSelected;
const getUnsubscribeReasons = (state) => state.unsubscribeReasons;
const getUnsubscribeFormReasonSelected = (state) =>
  state.unsubscribeFormReasonSelected;

export const getUnsubscribedFrom = createSelector(
  [getUnsubscribeFormBlockTypeSelected],
  (selected) => {
    const blockTypeItem = BlockTypeItems.find(
      (item) => item.value === selected
    );

    return blockTypeItem ? blockTypeItem.label : '';
  }
);

export const getUnsubscribeReasonId = createSelector(
  [getUnsubscribeReasons, getUnsubscribeFormReasonSelected],
  (reasons, selected) => {
    const reason = reasons.find((item) => item.identifier === selected);

    return reason ? reason.id : null;
  }
);
