export default {
  animateRowOff: 'TABLES_TASKS_ANIMATE_ROW_OFF',
  datesShown: 'TABLES_TASKS_DATES_SHOWN',
  datesSmartFilter: 'TABLES_TASKS_DATES_SMART_FILTER',
  datesSmartFilterReset: 'TABLES_TASKS_DATES_SMART_FILTER_RESET',
  setLoading: 'TABLES_TASKS_SET_LOADING',
  statusSmartFilter: 'TABLES_TASKS_STATUS_SMART_FILTER',
  tableInit: 'TABLES_TASKS_TABLE_INIT',
  tableReset: 'TABLES_TASKS_TABLE_RESET',
};
