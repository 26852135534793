/**
 * Converts a number (in string format) to currency format.
 * Currently, it only supports `en_US` locale. Not sure where
 * else in the app we use something like this but putting it in
 * shared namespace for now.
 *
 * 100 => 100
 * 1000 => 1,000
 * $1230 => $1,230
 * $1 => $1
 * 199.99 => 199.99
 * 1999.99 => 1,999.99
 * $1999.99 => $1,999.99
 *
 * @param  {String} value
 * @return {String}       Formatted string
 */
export function format(value) {
  return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
