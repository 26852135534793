import isString from 'lodash/isString';

export function getErrorMessage(error) {
  if (error && isString(error)) {
    return error;
  }

  try {
    const errors = JSON.parse(error.response.text);

    return errors.base || 'common.errors.unexpected.body';
  } catch (e) {
    return 'common.errors.unexpected.body';
  }
}
