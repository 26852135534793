import TeamSmtpDeliveryChannelActionTypes from '../libs/teamSmtpDeliveryChannelActionTypes';
import {
  getSubscriptionDeliveryChannel as getSubscriptionDeliveryChannelCall,
  deleteSubscriptionDeliveryChannel as deleteSubscriptionDeliveryChannelCall,
  createSubscriptionDeliveryChannel as createSubscriptionDeliveryChannelCall,
  updateSubscriptionDeliveryChannel as updateSubscriptionDeliveryChannelCall,
} from '../services/subscriptionDeliveryChannelsService';
import { SMTP_DELIVERY_CHANNEL } from '../libs/deliveryChannelsConstants';

export const setTeamSmtpDeliveryChannelFetching = () => ({
  type: TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannelFetching,
});

export const getTeamSmtpDeliveryChannel = () => (dispatch) => {
  dispatch(setTeamSmtpDeliveryChannelFetching());

  return getSubscriptionDeliveryChannelCall(SMTP_DELIVERY_CHANNEL)
    .then((deliveryChannel) => {
      dispatch({
        type: TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannel,
        deliveryChannel,
      });
      return deliveryChannel;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannel,
          deliveryChannel: null,
        });
        return null;
      } else {
        dispatch({
          type:
            TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannelError,
        });
        return Promise.reject(error);
      }
    });
};

export const deleteTeamSmtpDeliveryChannel = () => (dispatch) => {
  dispatch(setTeamSmtpDeliveryChannelFetching());

  return deleteSubscriptionDeliveryChannelCall(SMTP_DELIVERY_CHANNEL)
    .then(() => {
      dispatch({
        type: TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannel,
        deliveryChannel: null,
      });
    })
    .catch((error) => {
      dispatch({
        type:
          TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannelError,
      });
      return Promise.reject(error);
    });
};

export const createTeamSmtpDeliveryChannel = (newDeliveryChannel) => (
  dispatch
) => {
  dispatch(setTeamSmtpDeliveryChannelFetching());

  return createSubscriptionDeliveryChannelCall(newDeliveryChannel)
    .then((deliveryChannel) => {
      dispatch({
        type: TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannel,
        deliveryChannel,
      });
      return deliveryChannel;
    })
    .catch((error) => {
      dispatch({
        type:
          TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannelError,
      });
      return Promise.reject(error);
    });
};

export const updateTeamSmtpDeliveryChannel = (updatedDeliveryChannel) => (
  dispatch
) => {
  dispatch(setTeamSmtpDeliveryChannelFetching());

  return updateSubscriptionDeliveryChannelCall(
    updatedDeliveryChannel,
    SMTP_DELIVERY_CHANNEL
  )
    .then((deliveryChannel) => {
      dispatch({
        type: TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannel,
        deliveryChannel,
      });
      return deliveryChannel;
    })
    .catch((error) => {
      dispatch({
        type:
          TeamSmtpDeliveryChannelActionTypes.setTeamSmtpDeliveryChannelError,
      });
      return Promise.reject(error);
    });
};
