import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './liveFeedContactInfo.scss';
import { displayTitle } from 'web/liveFeed/helpers/liveFeedParsers';
import { Urls } from 'web/libs/routes';

const LiveFeedContactInfo = ({ title, company, pitchId }) => (
  <div className="live-feed-contact-info">
    {pitchId ? (
      <span className="job-title">
        <FormattedMessage id="web.liveFeed.email" />
        <a
          href={Urls.liveFeedPitch(pitchId)}
          rel="noreferrer noopener"
          target="_blank"
        >
          {displayTitle(title)}
        </a>
      </span>
    ) : (
      <div className="marketo-card-contact-info">
        <span className="job-title">{title}</span>
        <span className="company">{company}</span>
      </div>
    )}
  </div>
);

LiveFeedContactInfo.propTypes = {
  title: PropTypes.string,
  pitchId: PropTypes.number,
  company: PropTypes.string,
};

LiveFeedContactInfo.defaultProps = {
  title: '',
  pitchId: null,
  company: '',
};

export default LiveFeedContactInfo;
