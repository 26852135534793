/* Used externally to go in and out of PreviewEmails */
import moment from 'moment-timezone';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { CampaignState, CampaignStepActions } from 'web/libs/constants';
import { ScheduleLaunchWhen } from 'libs/constantsShared';
import { createWorkflowInstances } from 'web/previewEmails/services/previewEmailsLaunchService';
import {
  changePreview,
  openPreviewsEmail,
  saveBody,
} from 'web/previewEmails/actionCreators/previewEmailsActionCreators';
import {
  getFirstStep,
  getScheduledDateTime,
} from 'web/previewEmails/helpers/previewEmailsHelper';
import { parsePreviewsForCommit } from '../helpers/parsers';
import { track, getSource } from 'web/services/mixpanelService';
import {
  CampaignActionEvents,
  CampaignProperties,
} from 'web/libs/mixpanelEvents';
import { launchMarketoCampaign as launchMarketoCampaignCall } from 'web/marketo/campaigns/services/marketoCampaignsService';
import { getMarketoLeadId } from 'web/people/peopleSearchAdd/helpers/peopleHelpers';

function getDefaultScheduledTime(firstStep, timezones, timezone) {
  const info = getScheduledDateTime(firstStep, timezones, timezone);
  const abbr = moment()
    .tz(info.timezone)
    .zoneAbbr();
  return { datetime: info.datetime, tz: abbr };
}

const trackUseCampaign = (properties, sourceOpener) => {
  const source = getSource(sourceOpener);
  if (source) {
    track(CampaignActionEvents.useCampaign, { ...properties, Source: source });
    return;
  }
  track(CampaignActionEvents.useCampaign, properties);
};

export function openPreviewCampaignsWithPId(
  campaign,
  people = [],
  identity,
  openPreviewsAC
) {
  return (dispatch, getState) => {
    const { userTimezone, timezones } = getState();
    const currentId = people.length ? people[0].id : '';
    const firstStep = getFirstStep(campaign);
    const defaultScheduled = getDefaultScheduledTime(
      firstStep,
      timezones,
      userTimezone
    );
    const isEmail = firstStep.action === CampaignStepActions.email;

    dispatch({
      type: ActionTypes.previewEmails.state.init,
      campaignId: campaign.id,
      currentId,
      datetime: moment()
        .add(1, 'h')
        .tz(userTimezone),
      defaultDatetime: defaultScheduled.datetime,
      defaultTz: defaultScheduled.tz,
      editorId: `preview-email-${Date.now()}`,
      identity,
      isEmail,
      recipients: people,
      timezone: userTimezone,
      when: defaultScheduled.datetime
        ? ScheduleLaunchWhen.scheduled
        : ScheduleLaunchWhen.now,
    });

    if (isEmail) {
      dispatch(openPreviewsEmail(currentId, openPreviewsAC));
    } else {
      dispatch(openPreviewsAC());
    }
  };
}

export const openPreviewMarketoCampaignWithPId = (
  campaign,
  people = [],
  identity,
  openPreviewsAC
) => (dispatch) => {
  dispatch({
    type: ActionTypes.previewEmails.state.init,
    campaignId: campaign.id,
    identity,
    recipients: people,
  });

  dispatch(openPreviewsAC());
};

export function donePreviews(actionCreators = {}) {
  return (dispatch, getState) => {
    const {
      previewEmailsPreviews: { currentId, recipients },
    } = getState();
    if (recipients.map[currentId].blockedReason) {
      dispatch(actionCreators.openSchedule());
    } else {
      dispatch(saveBody(true))
        .then((invalid = {}) => {
          if (invalid.subject || invalid.body) {
            dispatch(
              actionCreators.invalidInput({
                body: invalid.body,
                subject: invalid.subject,
                done: true,
              })
            );
          } else if (
            invalid.dynamicFieldsSubject ||
            invalid.dynamicFieldsBody
          ) {
            dispatch(
              actionCreators.invalidDynamicFields({
                body: invalid.dynamicFieldsBody,
                subject: invalid.dynamicFieldsSubject,
              })
            );
          } else if (invalid.promptFieldsSubject || invalid.promptFieldsBody) {
            dispatch(
              actionCreators.invalidPromptFields({
                body: invalid.promptFieldsBody,
                subject: invalid.promptFieldsSubject,
              })
            );
          } else {
            dispatch(actionCreators.openSchedule());
          }
        })
        .catch((err) => {
          dispatch(actionCreators.defaultCatch(err));
        });
    }
  };
}

export function backToPreview(actionCreators = {}) {
  return (dispatch, getState) => {
    const { isEmail, recipients } = getState().previewEmailsPreviews;
    dispatch(actionCreators.warnDataLoss(true));
    dispatch(actionCreators.openPreviews(isEmail));
    if (isEmail) {
      dispatch(changePreview(recipients.ids[0]));
    }
  };
}

export function addAndLaunchCampaignEmail(actionCreators = {}) {
  return (dispatch, getState) => {
    const {
      previewEmailsPreviews,
      previewEmailsScheduled,
      addToCampaignSourceOpener,
    } = getState();
    let runAt;
    if (previewEmailsScheduled.when === ScheduleLaunchWhen.now) {
      runAt = moment();
    } else if (previewEmailsScheduled.when === ScheduleLaunchWhen.different) {
      runAt = previewEmailsScheduled.datetime;
    }
    const previews = parsePreviewsForCommit(previewEmailsPreviews.previewsMap);
    const ids = previewEmailsPreviews.recipients.ids.filter(
      (id) => !previewEmailsPreviews.recipients.map[id].blockedReason
    );

    dispatch(actionCreators.loading(true));

    createWorkflowInstances(
      previewEmailsPreviews.campaignId,
      ids,
      true,
      previewEmailsPreviews.identity,
      previews,
      runAt
    )
      .then((data = { people_to_be_added: [] }) => {
        dispatch(actionCreators.loading(false));
        dispatch({
          type: ActionTypes.common.data.campaignUpdateByProperty,
          id: previewEmailsPreviews.campaignId,
          newProperties: { state: CampaignState.inProgress },
        });
        dispatch(
          actionCreators.launched({
            peopleAdded: data.people_to_be_added.length || ids.length,
          })
        );
        if (actionCreators.getPeople) {
          dispatch(actionCreators.getPeople());
        }
        trackUseCampaign(
          {
            Mode: CampaignProperties.mode.a2c,
            'First Step': CampaignProperties.step.email,
            Count: ids.length,
            'Campaign Type': CampaignProperties.type.sales,
          },
          addToCampaignSourceOpener
        );
      })
      .catch((err) => {
        dispatch(actionCreators.loading(false));
        dispatch(actionCreators.defaultCatch(err));
      });
  };
}

export function addAndLaunchCampaignTask(actionCreators = {}) {
  return (dispatch, getState) => {
    const { previewEmailsPreviews, addToCampaignSourceOpener } = getState();
    dispatch(actionCreators.loading(true));

    const ids = previewEmailsPreviews.recipients.ids.filter(
      (id) => !previewEmailsPreviews.recipients.map[id].blockedReason
    );

    createWorkflowInstances(
      previewEmailsPreviews.campaignId,
      ids,
      false,
      previewEmailsPreviews.identity
    )
      .then(() => {
        dispatch(actionCreators.loading(false));
        dispatch({
          type: ActionTypes.common.data.campaignUpdateByProperty,
          id: previewEmailsPreviews.campaignId,
          newProperties: { state: CampaignState.inProgress },
        });
        dispatch(actionCreators.launched({ peopleAdded: ids.length || 0 }));
        if (actionCreators.getPeople) {
          dispatch(actionCreators.getPeople());
        }
        trackUseCampaign(
          {
            Mode: CampaignProperties.mode.a2c,
            'First Step': CampaignProperties.step.task,
            Count: ids.length,
            'Campaign Type': CampaignProperties.type.sales,
          },
          addToCampaignSourceOpener
        );
      })
      .catch((err) => {
        dispatch(actionCreators.loading(false));
        dispatch(actionCreators.defaultCatch(err));
      });
  };
}

export const addAndLaunchMarketoCampaign = (actionCreators) => (
  dispatch,
  getState
) => {
  const {
    addToCampaignSourceOpener,
    previewEmailsPreviews: {
      recipients: { ids: recipientIds, map: recipientMap },
      campaignId,
    },
  } = getState();
  const { defaultCatch, launched, loading } = actionCreators;

  dispatch(loading(true));

  let recipient;
  const leadIds = recipientIds.reduce((accum, id) => {
    recipient = recipientMap[id];
    if (!recipient.blockedReason) {
      accum.push(getMarketoLeadId(recipient));
    }
    return accum;
  }, []);

  launchMarketoCampaignCall({ campaignId, leadIds })
    .then(({ success = true }) => {
      if (!success) {
        dispatch(defaultCatch());
      } else {
        dispatch(launched({ peopleAdded: leadIds.length }));

        trackUseCampaign(
          {
            Mode: CampaignProperties.mode.a2c,
            'First Step': CampaignProperties.step.email,
            Count: leadIds.length,
            'Campaign Type': CampaignProperties.type.marketing,
          },
          addToCampaignSourceOpener
        );
      }
    })
    .catch((err) => {
      dispatch(defaultCatch(err));
    })
    .finally(() => {
      dispatch(loading(false));
    });
};

export function checkTimeNotPast(actionCreators = {}) {
  return (dispatch, getState) => {
    const { previewEmailsScheduled } = getState();

    if (
      previewEmailsScheduled.when === ScheduleLaunchWhen.different &&
      previewEmailsScheduled.datetime <=
        moment.tz(previewEmailsScheduled.timezone)
    ) {
      dispatch(actionCreators.openSchedulePast());
    } else {
      dispatch(addAndLaunchCampaignEmail(actionCreators));
    }
  };
}

export function saveAndChangePreview(personId, viewType, actionCreators) {
  return (dispatch, getState) => {
    const {
      previewEmailsPreviews: { currentId, recipients },
    } = getState();
    if (currentId === 0 || recipients.map[currentId].blockedReason) {
      dispatch(changePreview(personId, viewType));
    } else {
      dispatch(saveBody())
        .then((invalid = {}) => {
          if (invalid.subject || invalid.body) {
            dispatch(
              actionCreators.invalidInput({
                body: invalid.body,
                subject: invalid.subject,
                done: false,
              })
            );
          } else if (
            invalid.dynamicFieldsSubject ||
            invalid.dynamicFieldsBody
          ) {
            dispatch(
              actionCreators.invalidDynamicFields({
                body: invalid.dynamicFieldsBody,
                subject: invalid.dynamicFieldsSubject,
              })
            );
          } else if (invalid.promptFieldsSubject || invalid.promptFieldsBody) {
            dispatch(
              actionCreators.invalidPromptFields({
                body: invalid.promptFieldsBody,
                subject: invalid.promptFieldsSubject,
              })
            );
          } else {
            dispatch(changePreview(personId, viewType));
          }
        })
        .catch((err) => {
          dispatch(actionCreators.defaultCatch(err));
        });
    }
  };
}
