import { Regex } from 'web/libs/constants';

// Done b/c only commit preview with minimal data
export function parsePreviewPitch(pitch = {}) {
  return {
    body: pitch.body,
    email: pitch.email,
    name: pitch.name,
    subject: pitch.subject,
  };
}

export function parsePreviewsForCommit(previewsMap = {}) {
  return Object.keys(previewsMap).map((key) => ({
    person_id: key,
    pitch: previewsMap[key],
    preview_type: 'Pitch',
  }));
}

export function parseDynamicFieldsLeftover(str = '') {
  return !!str
    .replace(Regex.dynamicFieldsPrompt, '')
    .match(Regex.dynamicFieldsBracketsLeftover);
}

export function parsePromptFieldsLeftover(str = '') {
  return !!str.match(Regex.dynamicFieldsPrompt);
}
