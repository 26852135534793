import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import './campaignSettingsTitleCard.scss';

const CampaignSettingsTitleCard = ({ title, description, children }) => (
  <div className="settings-card">
    <h3 className="settings-card-title">
      <FormattedHTMLMessage id={title} />
    </h3>
    <FormattedHTMLMessage id={description} />
    {children}
  </div>
);

CampaignSettingsTitleCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
};

CampaignSettingsTitleCard.defaultProps = {
  description: '',
  children: undefined,
};

export default CampaignSettingsTitleCard;
