import { baseGet, basePost } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const bulkCallOutcomeOptionsRequest = (params = {}) =>
  new Promise((resolve, reject) => {
    basePost(Urls.bulkCallOutcomeOptions, params, resolve, reject);
  });

export const bulkCallReasonOptionsRequest = (params = {}) =>
  new Promise((resolve, reject) => {
    basePost(Urls.bulkCallReasonOptions, params, resolve, reject);
  });

export const getCallOutcomeOptionsRequest = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.callOutcomeOptions, {}, resolve, reject);
  });

export const getCallReasonOptionsRequest = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.callReasonOptions, {}, resolve, reject);
  });
