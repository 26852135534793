import React from 'react';
import PropTypes from 'prop-types';
import './taskEventDota.scss';
import OptionDotaInfobox from 'components/optionDotas/infobox';

const TaskEventDota = (props) => {
  const { dotaOptions } = props;

  return (
    <div className="task-event-dota">
      <OptionDotaInfobox
        classNameInfoBox="event-dota-action"
        options={dotaOptions}
      />
    </div>
  );
};

TaskEventDota.propTypes = {
  dotaOptions: PropTypes.array,
};

TaskEventDota.defaultProps = {
  dotaOptions: [],
};

export default TaskEventDota;
