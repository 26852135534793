import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popup, { PopupButtonColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import head from 'lodash/head';
import './deleteTeamMembersConfirmationPopup.scss';

class DeleteTeamMembersConfirmationPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      deleteTeamMembersLoading,
      defaultAlert,
      popupData: { ids },
      selectedTeam: { name: teamName },
    } = this.props;
    const isTeamAdminSelected = this.isTeamAdminSelected();
    const isYourselfInList = this.isYourselfInList();

    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.onTeamMembersDelete,
          onSecondary: closePopup,
          primaryTextId: 'common.remove',
          secondaryTextId: 'common.cancel',
          primaryColor: PopupButtonColors.red,
          primaryDisabled: isTeamAdminSelected,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.teamManagement.teamMembers.deleteTeamMembers',
        }}
        getImageUrl={getImageUrl}
        loading={deleteTeamMembersLoading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        {isTeamAdminSelected ? (
          <FormattedMessage id="web.settings.adminSettings.teamManagement.teamMembers.deleteTeamMembers.selectedTeamAdmin" />
        ) : (
          <Fragment>
            <FormattedHTMLMessage
              id="web.settings.adminSettings.teamManagement.teamMembers.deleteTeamMembers.areYouSure"
              values={{ teamName, membersCount: ids.length }}
            />
            {isYourselfInList && (
              <div className="delete-team-members-popup-warning">
                <div className="tout-icon-warning" />
                <FormattedMessage id="web.settings.adminSettings.teamManagement.teamMembers.deleteTeamMembers.yourselfInList" />
              </div>
            )}
          </Fragment>
        )}
      </Popup>
    );
  }

  isYourselfInList = () => {
    const {
      popupData: { ids },
      currentUserId,
      isAdmin,
    } = this.props;
    return !isAdmin && ids.length > 1 && ids.includes(currentUserId);
  };

  isTeamAdminSelected = () => {
    const {
      popupData: { ids },
      currentUserId,
      isAdmin,
    } = this.props;
    return !isAdmin && ids.length === 1 && head(ids) === currentUserId;
  };

  onTeamMembersDelete = () => {
    const {
      actionCreators: { deleteTeamMembers },
      popupData: { ids },
    } = this.props;

    deleteTeamMembers(ids);
  };
}

DeleteTeamMembersConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popupData: PropTypes.object,
  deleteTeamMembersLoading: PropTypes.bool.isRequired,
  defaultAlert: PropTypes.object,
  selectedTeam: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

DeleteTeamMembersConfirmationPopup.defaultProps = {
  popupData: {},
  defaultAlert: null,
};

export default DeleteTeamMembersConfirmationPopup;
