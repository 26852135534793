import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import I18N from 'languages';
import { connect } from 'react-redux';
import { isMsiActions } from 'web/user/selectors/userSelectors';
import SlideOutPaneActionBar from 'components/layouts/slideOutPane/actionBar';
import {
  AddressTypes,
  PersonDetailsTabAlertIds,
} from 'web/person/libs/personDetailsConstants';
import { isUnsubscribed } from 'web/person/helpers/personHelpers';
import { isAdmin } from 'web/user/services/userService';
import { FileFormats } from 'libs/constantsShared';
import Exporter from 'components/export';

class PageDetailsActionBar extends Component {
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.unsubscribeText = formatMessage({ id: 'common.unsubscribe' });
    this.removeUnsubscribeText = formatMessage({
      id: 'common.removeUnsubscribe',
    });
    this.deleteText = formatMessage({ id: 'common.delete' });
    this.exportText = formatMessage({ id: 'common.export' });
  }

  componentDidUpdate() {
    const {
      actionCreators: { exportPerson },
    } = this.props;
    exportPerson(false);
  }

  render() {
    const {
      actionCreators: { onClose },
      exporting,
    } = this.props;
    return (
      <div className="action-bar-download">
        <SlideOutPaneActionBar
          buttons={this._getButtons()}
          onBack={onClose}
          options={this._getOptions()}
        />
        {exporting && this.export()}
      </div>
    );
  }

  export = () => {
    const {
      exportData: { columns, data, filename },
    } = this.props;
    const { DateFormats, getLocalizedDateTime, getStr } = I18N;
    const exportedAt = getStr('web.person.personDetails.export.exportedAt');

    if (columns.indexOf(exportedAt) === -1) {
      columns.push(exportedAt);
      data.push(getLocalizedDateTime(new Date(), DateFormats.DATETIME_SLASH));
    }

    return (
      <Exporter
        columns={columns}
        data={[data]}
        fileExtension={FileFormats.csv}
        filename={filename}
        sheetName={filename}
      />
    );
  };

  _hasAddressType = (type) => {
    const { addresses } = this.props.person;
    return (
      addresses &&
      addresses.map((address) => address.address_type).includes(type)
    );
  };

  _hasExternalReference = () => {
    const { person } = this.props;
    const { canBeAddedToCampaigns } = person;
    if (!canBeAddedToCampaigns && person.external_references) {
      return !canBeAddedToCampaigns;
    }
    return canBeAddedToCampaigns;
  };

  _getButtons = () => {
    const { actionCreators, person, tabLoading } = this.props;
    return isUnsubscribed(person)
      ? []
      : [
          {
            disabled: tabLoading || !this._hasAddressType(AddressTypes.email),
            onClick: actionCreators.openEmail,
            textId: 'common.email',
          },
          {
            disabled: tabLoading || !this._hasAddressType(AddressTypes.phone),
            onClick: actionCreators.openCall,
            textId: 'common.call',
          },
          {
            disabled: tabLoading,
            onClick: actionCreators.openTask,
            textId: 'common.task',
          },
          {
            disabled: tabLoading || !this._hasExternalReference(),
            onClick: actionCreators.openAddToCampaign,
            textId: 'common.addToCampaign',
          },
        ];
  };

  _getUnsubscribeOption = () => {
    const {
      actionCreators: { openRemoveUnsubscribe, setTabAlert, openUnsubscribe },
      person,
      tabLoading,
      user,
    } = this.props;

    if (isUnsubscribed(person)) {
      return {
        disabledStyle: tabLoading,
        label: this.removeUnsubscribeText,
        onClick: isAdmin(user)
          ? openRemoveUnsubscribe
          : setTabAlert.bind(
              this,
              PersonDetailsTabAlertIds.removeUnsubscribeWarning,
              {}
            ),
        value: 'removeUnsubscribe',
      };
    }

    return {
      disabledStyle: tabLoading || isUnsubscribed(person),
      label: this.unsubscribeText,
      onClick: openUnsubscribe,
      value: 'unsubscribe',
    };
  };

  _exportButtonClick = () => {
    const {
      actionCreators: { exportPerson },
    } = this.props;
    exportPerson(true);
  };

  _getOptions = () => {
    const { actionCreators, tabLoading, isMsiActions } = this.props;
    const unsubscribeOption = this._getUnsubscribeOption();
    const options = [unsubscribeOption];

    options.push({
      disabledStyle: tabLoading,
      label: this.exportText,
      onClick: this._exportButtonClick,
      value: 'export',
    });

    if (!isMsiActions) {
      options.push({
        disabledStyle: tabLoading,
        label: this.deleteText,
        onClick: actionCreators.deleteUser,
        value: 'delete',
      });
    }

    return options;
  };
}

PageDetailsActionBar.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  exportData: PropTypes.object.isRequired,
  exporting: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  person: PropTypes.object,
  tabLoading: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

PageDetailsActionBar.defaultProps = {
  person: {},
  tabLoading: false,
};

const mapStateToProps = (state) => ({
  isMsiActions: isMsiActions(state),
});

export default connect(mapStateToProps)(injectIntl(PageDetailsActionBar));
