import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class EventListener extends Component {
  constructor(props) {
    super(props);
    this.wrappedComponentRef = null;
  }

  componentDidMount() {
    this.handleListeners(this.props, true);
  }

  componentWillUnmount() {
    this.handleListeners(this.props, false);
  }

  componentWillReceiveProps(nextProps) {
    const { eventType, onEvent, useCapture } = this.props;
    const {
      eventType: nextEventType,
      onEvent: nextOnEvent,
      useCapture: nextUseCapture,
    } = nextProps;

    if (
      eventType !== nextEventType ||
      onEvent !== nextOnEvent ||
      useCapture !== nextUseCapture
    ) {
      this.handleListeners(this.props, false);
      this.handleListeners(nextProps, true);
    }
  }

  render() {
    const { WrappedComponent, wrappedProps } = this.props;

    return <WrappedComponent {...wrappedProps} ref={this._setRef} />;
  }

  _setRef = (node) => {
    this._setWrappedComponentRef = node;
  };

  _getElement = () => {
    const { useDocument } = this.props;
    if (useDocument || !this._setWrappedComponentRef) {
      return document;
    }
    //eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this._setWrappedComponentRef);
  };

  handleListeners = ({ eventType, onEvent, useCapture }, add = false) => {
    const listener = (add && this.addListener) || this.removeListener;
    listener(this._getElement(), eventType, onEvent, useCapture);
  };

  addListener = (element, eventType, onEvent, useCapture) => {
    if (element.addEventListener) {
      // `useCapture` flag is set to true so that a `stopPropagation` in the children will
      // not prevent all handlers from firing
      element.addEventListener(eventType, onEvent, useCapture);
    } else {
      element.attachEvent(`on${eventType}`, onEvent);
    }
  };

  removeListener = (element, eventType, onEvent, useCapture) => {
    if (element.removeEventListener) {
      element.removeEventListener(eventType, onEvent, useCapture);
    } else {
      element.detachEvent(`on${eventType}`, onEvent);
    }
  };
}

EventListener.propTypes = {
  eventType: PropTypes.string,
  onEvent: PropTypes.func.isRequired,
  useCapture: PropTypes.bool,
  useDocument: PropTypes.bool,
  WrappedComponent: PropTypes.func.isRequired,
  wrappedProps: PropTypes.object.isRequired,
};

EventListener.defaultProps = {
  eventType: 'click',
  useCapture: false,
  useDocument: false,
};

export default EventListener;
