import { OrderedMap } from 'immutable';
import TasksActionTypes from '../libs/tasksActionTypes';
import { ASYNC_THRESHOLD_TASKS } from '../libs/tasksConstants';
import {
  bulkUpdateAsync,
  bulkUpdateSync,
  createTask,
  deleteTask,
  updateTask,
} from '../services/tasksService';

function getDates({
  tasksCompletedDates = new OrderedMap(),
  tasksUncompletedDates = new OrderedMap(),
}) {
  //eslint-disable-line new-cap
  return {
    completedDates: tasksCompletedDates.toJS(),
    uncompletedDates: tasksUncompletedDates.toJS(),
  };
}

export function taskUpdateData(task = {}, fromPusher = false) {
  return (dispatch, getState) => {
    const state = getState();
    if (fromPusher && state.user.id !== task.user_id) {
      dispatch(taskDestroyData(task));
    } else {
      dispatch({ task, type: TasksActionTypes.update, ...getDates(state) });
    }
  };
}

export function taskDestroyData({ id }) {
  return { id, type: TasksActionTypes.destroy };
}

/* Let calling functions handle catch */
export function taskCreate(task) {
  return (dispatch) =>
    createTask(task).then((data) => {
      dispatch(taskUpdateData(data));
      return data;
    });
}

export function taskUpdate(task) {
  return (dispatch) =>
    updateTask(task).then((data) => {
      dispatch(taskUpdateData(data));
      return data;
    });
}

export function taskDelete(task = {}) {
  return (dispatch) =>
    deleteTask(task.id).then((data) => {
      dispatch(taskDestroyData(task));
      return data;
    });
}

// Note for done, success, skip: optimisitically remove from list and not handling failure as backend will alert
export function taskDone(task = {}) {
  return (dispatch) => {
    const newTask = { ...task, done: true };
    dispatch({ task: newTask, type: TasksActionTypes.completing });
    return updateTask(newTask).catch((err) => {
      dispatch({ task, type: TasksActionTypes.uncompleting });
      return err;
    });
  };
}

/* lets pusher update workflow_details */
export function taskSuccess(task = {}) {
  return (dispatch) => {
    const newTask = { ...task, done: true, success: true };
    dispatch({ task: newTask, type: TasksActionTypes.completing });
    return updateTask(newTask).catch((err) => {
      dispatch({ task, type: TasksActionTypes.uncompleting });
      return err;
    });
  };
}

export function taskSkip(task = {}) {
  return (dispatch) => {
    const newTask = { ...task, skipped: true };
    dispatch({ task: newTask, type: TasksActionTypes.completing });
    return updateTask(newTask).catch((err) => {
      dispatch({ task, type: TasksActionTypes.uncompleting });
      return err;
    });
  };
}

export function taskUndone(task = {}) {
  return (dispatch) => {
    const newTask = { ...task, done: false };
    dispatch({ task: newTask, type: TasksActionTypes.uncompleting });
    return updateTask(newTask).catch((err) => {
      dispatch({ task, type: TasksActionTypes.completing });
      return err;
    });
  };
}

export function completingBulkDone(ids = []) {
  return {
    changes: { done: true },
    ids,
    type: TasksActionTypes.completingBulk,
  };
}

export function completingBulkSkip(ids = []) {
  return {
    changes: { done: true, skip: true },
    ids,
    type: TasksActionTypes.completingBulk,
  };
}

export function completingBulkSuccess(ids = []) {
  return {
    changes: { done: true, success: true },
    ids,
    type: TasksActionTypes.completingBulk,
  };
}

export function bulkUpdateChooser(ids = [], changes) {
  return (dispatch) => {
    if (ids.length > ASYNC_THRESHOLD_TASKS) {
      return bulkUpdateAsync(ids, changes).then((data = {}) => ({
        isAsync: true,
        ...data,
      }));
    } else {
      return bulkUpdateSync(ids, changes).then((data = {}) => {
        dispatch({ changes, ids, type: TasksActionTypes.completingBulk });
        return { isAsync: false, ...data };
      });
    }
  };
}

export function bulkDone(ids = []) {
  return (dispatch) => dispatch(bulkUpdateChooser(ids, { done: true }));
}

export function bulkSkip(ids = []) {
  return (dispatch) =>
    dispatch(bulkUpdateChooser(ids, { done: true, skip: true }));
}

/* lets pusher update workflow_details */
export function bulkSuccess(ids = []) {
  return (dispatch) =>
    dispatch(bulkUpdateChooser(ids, { done: true, success: true }));
}

export function bulkDelete(/*ids = []*/) {
  return (/*dispatch*/) => {
    // return dispatch(bulkUpdateChooser(ids, { delete? })); //todo needs backend support
  };
}
