import { createVerification as createVerificationCall } from 'web/user/services/verificationsService';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { EmailComposePopupIds } from '../libs/composeEmailPopupConstants';
import { openComposeEmailPopup } from './popupActionCreators';

export const composeEmailStartup = ({ content: { responseText } }) => (
  dispatch
) => {
  dispatch(openComposeEmailPopup(responseText));
};

export const resendVerificationMail = ({ identityId, target }) => (dispatch) =>
  createVerificationCall(identityId).then(() => {
    if (target === EmailComposePopupIds.emailVerificationRequired) {
      dispatch(openPopup(EmailComposePopupIds.verifiedSmtpNotVerifiedEmail));
    }
    if (target === EmailComposePopupIds.actionsRequired) {
      dispatch(openPopup(EmailComposePopupIds.notVerifiedSmtpNotVerifiedEmail));
    }
  });
