import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { prettifyNumber } from '../templateChooserHelper';
import './list.scss';
import { DefaultTemplateCard } from 'libs/constantsShared';
import I18N from 'languages';

class List extends Component {
  constructor(props) {
    super(props);

    this.nameHeader = props.intl.formatMessage({ id: 'templateChooser.name' });
    this.sentHeader = props.intl.formatMessage({ id: 'templateChooser.sent' });
    this.viewHeader = props.intl.formatMessage({ id: 'templateChooser.views' });
    this.clickHeader = props.intl.formatMessage({
      id: 'templateChooser.clicks',
    });
    this.replyHeader = props.intl.formatMessage({
      id: 'templateChooser.replies',
    });
  }

  render() {
    const { getArrowClass, onHeaderClick, selectedId, templates } = this.props;

    return (
      <div className="list">
        <div className="list-header">
          <div
            className={`name ${getArrowClass('name')}`}
            data-column="name"
            onClick={onHeaderClick}
          >
            {this.nameHeader}
          </div>
          <div
            className={`stat ${getArrowClass('sent')}`}
            data-column="sent"
            onClick={onHeaderClick}
          >
            {this.sentHeader}
          </div>
          <div
            className={`stat ${getArrowClass('views')}`}
            data-column="views"
            onClick={onHeaderClick}
          >
            {this.viewHeader}
          </div>
          <div
            className={`stat ${getArrowClass('clicks')}`}
            data-column="clicks"
            onClick={onHeaderClick}
          >
            {this.clickHeader}
          </div>
          <div
            className={`stat ${getArrowClass('replies')}`}
            data-column="replies"
            onClick={onHeaderClick}
          >
            {this.replyHeader}
          </div>
        </div>
        <div className="list-body">
          {templates.map((template, index) => {
            const classes = `list-row${
              selectedId === template.id ? ' highlighted' : ''
            }`;
            const templateName =
              DefaultTemplateCard.name === template.name
                ? I18N.getConstStr('templateChooser', template.name)
                : template.name;
            return (
              <div
                className={classes}
                key={`template-list-${template.id}`}
                data-index={index}
                onClick={this._onSelected}
              >
                <div className="name text-overflow">{templateName}</div>
                <div className="stat">{prettifyNumber(template.num_sent)}</div>
                <div className="stat">{`${template.open_rate}%`}</div>
                <div className="stat">{`${template.clickthrough_rate}%`}</div>
                <div className="stat">{`${template.reply_rate || 0}%`}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  _onSelected = (e) => {
    this.props.onSelected(this.props.templates[e.currentTarget.dataset.index]);
  };
}

List.propTypes = {
  getArrowClass: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  selectedId: PropTypes.number,
  templates: PropTypes.array.isRequired,
};

List.defaultProps = {
  selectedId: 0,
};

export default injectIntl(List);
