import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToutTable from 'table/modules/toutTable';
import getColumns from './templatesTable.columns';
import TableExternals from 'web/templates/libs/templatesTableExternals';
import TemplatesZeroState from 'web/templates/components/templatesZeroState/templatesZeroState';
import {
  TEMPLATE_SMART_FILTER_VALUES,
  TABLE_ID,
  SPECIAL_FILTER_CATEGORIES,
} from 'web/templates/libs/templatesConstants';
import { SortingDirection } from 'table/modules/constants';
import { getImageUrl } from 'web/libs/constants';
import { injectIntl, intlShape } from 'react-intl';
import { tracker } from 'web/templates/services/templatesMixpanel';
import './templatesTable.scss';

const SMART_FILTER_WIDTH = 275;
const ROW_HEIGHT = 60;

class TemplatesTable extends Component {
  constructor(props) {
    super(props);
    const {
      user,
      actionCreators,
      intl: { formatMessage },
      user: { admin },
      userCanEditTemplates,
    } = this.props;
    this.columns = getColumns(
      user,
      this.sortColumn,
      this.sortColumnOpposite,
      this.props.intl.formatMessage,
      actionCreators
    );
    this.smartFilters = [
      {
        label: formatMessage({ id: 'common.all' }),
        value: TEMPLATE_SMART_FILTER_VALUES.all,
      },
      {
        label: formatMessage({ id: 'web.templates.myTemplates' }),
        value: TEMPLATE_SMART_FILTER_VALUES.mine,
      },
      {
        label: formatMessage({ id: 'common.favorites' }),
        value: TEMPLATE_SMART_FILTER_VALUES.favorite,
      },
      {
        label: formatMessage({ id: 'common.shared' }),
        value: TEMPLATE_SMART_FILTER_VALUES.shared,
      },
      ...(admin
        ? [
            {
              label: formatMessage({ id: 'common.unshared' }),
              value: TEMPLATE_SMART_FILTER_VALUES.unshared,
            },
          ]
        : []),
      {
        label: formatMessage({ id: 'common.unused' }),
        value: TEMPLATE_SMART_FILTER_VALUES.unused,
      },
    ];

    this.actions = userCanEditTemplates
      ? [
          {
            label: formatMessage({ id: 'common.move' }),
            onClick: actionCreators.openMoveTemplatePopup,
          },
          {
            label: formatMessage({ id: 'common.archive' }),
            onClick: actionCreators.openArchiveTemplatePopup,
          },
          {
            label: formatMessage({ id: 'common.delete' }),
            onClick: actionCreators.openDeleteTemplatePopup,
          },
        ]
      : [];

    this.search = {
      noDropdown: true,
      onChange: actionCreators.templatesSearch,
      onClear: actionCreators.templatesResetSearch,
      placeholderText: formatMessage({ id: 'common.search' }),
    };

    this.zeroState = {
      bodyText: formatMessage({ id: 'web.templates.zeroStateBody' }),
      customImage: <TemplatesZeroState />,
      titleText: formatMessage({ id: 'web.templates.zeroStateTitle' }),
    };
  }

  removeMyFilterOptionForAllFilterOption = () => {
    if (this.props.templatesViewerId === SPECIAL_FILTER_CATEGORIES.ALL_USERS) {
      return this.smartFilters.filter(
        ({ value }) => value !== TEMPLATE_SMART_FILTER_VALUES.mine
      );
    } else {
      return [...this.smartFilters];
    }
  };

  componentWillUnmount() {
    TableExternals.reset();
  }

  render() {
    const {
      actionCreators,
      columnSettings,
      templates,
      loading,
      total: totalCount,
      resizeTable,
    } = this.props;

    return (
      <div className="templates-table">
        <ToutTable
          bulkActions={{ actions: this.actions, selectAll: false, totalCount }}
          changeColumnsSettingsHandler={actionCreators.changeColumnsSettings}
          columnSettings={columnSettings}
          columns={this.columns}
          items={templates}
          loading={loading}
          loadingGetUrl={getImageUrl}
          pagination={this.getPagination()}
          resizeTable={resizeTable}
          row={{
            height: ROW_HEIGHT,
            onClick: actionCreators.openTemplateDetails,
          }}
          search={this.getSearch()}
          setTableExternals={this.setTableExternals}
          smartFilters={this.getSmartFilters()}
          tableId={TABLE_ID}
          tracker={tracker}
          zeroState={this.zeroState}
          columnFilter
          draggable
          isResize
          reorderColumnsEnable
          selectable
        />
      </div>
    );
  }

  setTableExternals = (obj) => TableExternals.set(obj);

  sortColumn = (property, index, direction) =>
    this.props.actionCreators.sortTemplates(property, direction);

  sortColumnOpposite = (property, index, direction) => {
    const oppositeDirection =
      direction === SortingDirection.ASC
        ? SortingDirection.DESC
        : SortingDirection.ASC;
    this.props.actionCreators.sortTemplates(property, oppositeDirection);
  };

  paginate = (newPage) => newPage;

  getSmartFilters = () => {
    const {
      actionCreators,
      intl: { formatMessage },
      templatesSmartFilterValue,
    } = this.props;
    return {
      filters: this.removeMyFilterOptionForAllFilterOption(),
      label: formatMessage({ id: 'selects.smartFilter.label' }),
      onChange: actionCreators.templatesSmartFilterChange,
      selectedValue: templatesSmartFilterValue,
      width: SMART_FILTER_WIDTH,
    };
  };

  getSearch = () => {
    const { templatesSearchString } = this.props;
    return {
      value: templatesSearchString,
      ...this.search,
    };
  };

  getPagination = () => {
    const {
      actionCreators: { paginateTemplates, paginateTemplatesPerPage },
      page,
      perPage,
      total,
    } = this.props;
    return {
      currentPage: page,
      onClick: paginateTemplates,
      perPage,
      perPageClick: paginateTemplatesPerPage,
      total: Math.ceil(total / perPage),
    };
  };
}

TemplatesTable.defaultProps = {
  columnSettings: [],
  resizeTable: false,
};

TemplatesTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  resizeTable: PropTypes.bool,
  templates: PropTypes.array.isRequired,
  templatesSearchString: PropTypes.string.isRequired,
  templatesSmartFilterValue: PropTypes.string.isRequired,
  templatesViewerId: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  userCanEditTemplates: PropTypes.bool.isRequired,
};

export default injectIntl(TemplatesTable);
