import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TeamsPopupIds } from '../../../libs/teamsConstants';
import CreateTeamPopup from '../createTeamPopup';
import DeleteTeamConfirmationPopup from '../deleteTeamConfirmationPopup';
import UpgradeSubscriptionPopup from '../upgradeSubscriptionPopup';
import popupWithErrors from 'components/hocs/popupWithErrors';

class TeamsPopup extends Component {
  render() {
    const { popup } = this.props;

    switch (popup) {
      case TeamsPopupIds.createTeam:
        return <CreateTeamPopup {...this.props} />;
      case TeamsPopupIds.deleteTeamConfirmation:
        return <DeleteTeamConfirmationPopup {...this.props} />;
      case TeamsPopupIds.needSubscriptionUpgrade:
        return <UpgradeSubscriptionPopup {...this.props} />;
      default:
        return null;
    }
  }
}

TeamsPopup.propTypes = {
  popup: PropTypes.any,
};

TeamsPopup.defaultProps = {
  popup: null,
};

export default popupWithErrors(TeamsPopup);
