import MarketoWorkspacesActionTypes from 'web/marketo/workspaces/libs/marketoWorkspacesActionTypes';
import { MarketoWorkspacesErrors } from 'web/marketo/workspaces/libs/marketoWorkspacesConstants';
import { getMarketoWorkspaces as getMarketoWorkspacesCall } from 'web/marketo/workspaces/services/marketoWorkspacesService';

export const setMarketoWorkspaces = (marketoWorkspaces = []) => ({
  type: MarketoWorkspacesActionTypes.workspaces.set,
  marketoWorkspaces,
});

export const getMarketoWorkspaces = () => (dispatch, getState) =>
  getMarketoWorkspacesCall().then(({ workspaces = {} } = {}) => {
    const { status } = workspaces;

    if (workspaces === true || status >= 400) {
      throw new Error(MarketoWorkspacesErrors.defaultError);
    }

    dispatch(setMarketoWorkspaces(workspaces));
  });
