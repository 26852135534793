import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Icon from 'components/buttons/icon';
import './campaignSetupOptions.scss';

class CampaignSetupOptions extends Component {
  constructor() {
    super();
    this.horizontalId = 'campaign-setup-options-horizontal';
    this.verticalId = 'campaign-setup-options-vertical';
  }

  render() {
    const {
      intl: { formatMessage },
      isHorizontal,
    } = this.props;
    const colorProps = this._getColors(isHorizontal);

    return (
      <div className="campaign-setup-options">
        <Icon
          classes="tout-icon-setup-vertical"
          id={this.verticalId}
          {...colorProps.vertical}
          onClick={this.handleClick}
          title={formatMessage({ id: 'web.campaigns.toggleSetupVerticalView' })}
        />
        <Icon
          classes="tout-icon-setup-horizontal"
          id={this.horizontalId}
          {...colorProps.horizontal}
          onClick={this.handleClick}
          title={formatMessage({
            id: 'web.campaigns.toggleSetupHorizontalView',
          })}
        />
      </div>
    );
  }

  _getColors(isHorizontal) {
    const active = { backgroundColor: 'blue', color: 'white' };
    const inactive = { backgroundColor: 'white', color: 'blue' };

    if (isHorizontal) {
      return {
        horizontal: active,
        vertical: inactive,
      };
    } else {
      return {
        horizontal: inactive,
        vertical: active,
      };
    }
  }

  handleClick = (e) => {
    const { actionCreators, isHorizontal } = this.props;
    const clickedIsHorizontal = e.currentTarget.id === this.horizontalId;

    if (isHorizontal !== clickedIsHorizontal) {
      actionCreators.setCampaignSetupView(clickedIsHorizontal);
    }
  };
}

CampaignSetupOptions.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isHorizontal: PropTypes.bool.isRequired,
};

export default injectIntl(CampaignSetupOptions);
