import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AddressSearchResult from 'web/composeWindow/components/addressSearchResult/addressSearchResult';
import Autocomplete from 'components/inputs/autocomplete';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import './bulkEmailsSearch.scss';

const BulkEmailsSearch = (props) => {
  const {
    addRecipient,
    group,
    intl: { formatMessage },
    searchDisabled,
    searchPeople,
    searchPeopleLeave,
    searchResults,
    searchValue,
    toggleIsTooltipHovered,
  } = props;

  const searchBarHeight = 35;

  return (
    <div className="bulk-emails-search">
      <Autocomplete
        disabled={searchDisabled}
        height={searchBarHeight}
        onChange={searchPeople}
        onEmptyResults={onEmptyResults}
        onLeaving={searchPeopleLeave}
        onSearchClick={searchPeople}
        onSelection={addRecipient}
        placeholder={formatMessage({
          id: 'web.composeWindow.bulkEmails.searchPlaceholder',
        })}
        results={searchResults}
        resultsRenderer={resultsRenderer}
        value={searchValue}
        dropdownScrollable
        searchIcon
      >
        {searchDisabled ? (
          <div
            className="search-disabled-tooltip"
            onMouseEnter={onTooltipEnter}
            onMouseLeave={onTooltipLeave}
          >
            <HoverTooltip
              className="bulk-emails-search-tooltip"
              delayHide={0}
              place={HoverTooltipPlace.right}
              textId={getToolTipText()}
              tooltipId="search-disabled"
            >
              <i className="tout-icon-warning" />
            </HoverTooltip>
          </div>
        ) : (
          <div className="tout-icon-search" onClick={onSearchClick} />
        )}
      </Autocomplete>
    </div>
  );

  function resultsRenderer(result) {
    return <AddressSearchResult result={result} />;
  }

  function onEmptyResults() {
    addRecipient();
  }

  function onSearchClick() {
    searchPeople();
  }

  function onTooltipEnter() {
    toggleIsTooltipHovered(true);
  }

  function onTooltipLeave() {
    toggleIsTooltipHovered(false);
  }

  function getToolTipText() {
    if (group) {
      return 'web.composeWindow.bulkEmails.groupSearchTooltip';
    } else {
      return 'web.composeWindow.bulkEmails.bulkSearchTooltip';
    }
  }
};

BulkEmailsSearch.propTypes = {
  addRecipient: PropTypes.func.isRequired,
  group: PropTypes.object,
  intl: PropTypes.object.isRequired,
  searchDisabled: PropTypes.bool.isRequired,
  searchPeople: PropTypes.func.isRequired,
  searchPeopleLeave: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  searchValue: PropTypes.string.isRequired,
  toggleIsTooltipHovered: PropTypes.func.isRequired,
};

BulkEmailsSearch.defaultProps = {
  group: undefined,
};

export default injectIntl(BulkEmailsSearch);
