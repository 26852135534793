import UserManagementActionTypes from '../libs/userManagementActionTypes';
import { UserManagementTabs } from '../libs/userManagementConstants';

export const activeTab = (state = UserManagementTabs.users, { type, tab }) => {
  switch (type) {
    case UserManagementActionTypes.setTab:
      return tab;
    default:
      return state;
  }
};
