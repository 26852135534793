export const GmailIntegrationAlertIds = {
  integrationUpdated: 'GMAIL_INTEGRATION_ALERT_INTEGRATION_UPDATED',
  integrationNotUpdated: 'GMAIL_INTEGRATION_ALERT_INTEGRATION_NOT_UPDATED',
  googleConnected: 'GMAIL_INTEGRATION_ALERT_CONNECTED',
  googleNotConnected: 'GMAIL_INTEGRATION_ALERT_NOT_CONNECTED',
  googleDisconnected: 'GMAIL_INTEGRATION_ALERT_DISCONNECTED',
  googleNotDisconnected: 'GMAIL_INTEGRATION_ALERT_NOT_DISCONNECTED',
  integrationsFetchError: 'GMAIL_INTEGRATION_ALERT_INTEGRATIONS_FETCH_ERROR',
};

export const GmailIntegrationLocalStorageKeys = {
  connecting: 'connectingGoogle',
};
