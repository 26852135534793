import { baseGet, basePost } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { ComposeTemplatesConstants } from '../libs/composeTemplatesConstants';

export const getPinnedTemplateCategories = () => {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.favorites,
      { type: ComposeTemplatesConstants.categoriesType },
      resolve,
      reject
    );
  });
};

export const savePinnedTemplateCategories = ({
  deletes,
  upserts,
  favoriteOrder,
}) => {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.favoritesBulk,
      {
        favorites: {
          deletes,
          favorite_order: favoriteOrder,
          upserts,
        },
        type: ComposeTemplatesConstants.categoriesType,
      },
      resolve,
      reject
    );
  });
};

export const fetchFilledTemplate = ({
  addressId,
  email,
  identityId,
  signature,
  templateId,
}) => {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.templatesId.replace(':#id', templateId),
      {
        address_id: addressId,
        email,
        identity_id: identityId,
        include_signature: signature,
      },
      resolve,
      reject
    );
  });
};

export const fetchTemplate = (templateId) => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.templatesId.replace(':#id', templateId), {}, resolve, reject);
  });
};

export const getRecommendedTemplates = (personId) => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.templatesRecommended, { id: personId }, resolve, reject);
  });
};
