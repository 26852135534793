import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getSubActivities } from 'web/commandCenter/modules/statusSmartFilter/helpers/statusSmartFilterHelpers';
import DefaultSelect from 'components/selects/default/defaultSelect';
import classNames from 'classnames';
import { disabledPrefix } from 'web/commandCenter/modules/statusSmartFilter/libs/statusSmartFilterConstants';
import {
  getSentFilterInitialActivitiesValues,
  getSubTabIdIndex,
} from 'web/commandCenter/modules/statusSmartFilter/helpers/statusSmartFilterHelpers';
import './sentActivityBlock.scss';

const TABS_ICONS = ['icon-view', 'icon-click', 'icon-reply'];

const SentActivityBlock = (props) => {
  const subActivities = getSubActivities();

  const [activitySelectedOptions, setActivitySelectedOptions] = useState(
    getSentFilterInitialActivitiesValues(props.subTabIds)
  );

  const handleActivitySelect = (
    subTabId,
    activityIndex,
    shouldDeleteActivity
  ) => {
    const {
      addSentStatusValue,
      deleteSentStatusValue,
      replaceSentStatusValue,
      subTabIds,
    } = props;
    const activitySelectedIndex = getSubTabIdIndex(subTabId, subTabIds);
    if (activitySelectedIndex === -1) {
      addSentStatusValue(subTabId);
    } else if (shouldDeleteActivity) {
      deleteSentStatusValue(activitySelectedIndex);
    } else {
      replaceSentStatusValue(activitySelectedIndex, subTabId);
    }
    setActivitySelectedOptions([
      ...activitySelectedOptions.slice(0, activityIndex),
      subTabId,
      ...activitySelectedOptions.slice(activityIndex + 1),
    ]);
  };

  const toggleActivity = (subActivity, activityIndex) => {
    const selectedItem = getSelectedItem(subActivity);
    handleActivitySelect(
      activitySelectedOptions[activityIndex],
      activityIndex,
      !!selectedItem
    );
  };

  const getSelectedItem = (subActivity) => {
    const { subTabIds } = props;
    const value = subTabIds.find((subTabId) =>
      subActivity.some(({ id }) => id === subTabId)
    );
    return value || '';
  };

  const isActiveFilter = (subActivity) => {
    const selectedItem = getSelectedItem(subActivity);
    return selectedItem && !selectedItem.includes(disabledPrefix);
  };

  const isDisabledFilter = (subActivity) => {
    const selectedItem = getSelectedItem(subActivity);
    return selectedItem && selectedItem.includes(disabledPrefix);
  };

  return (
    <div className="flex email-status-filter-activity-container">
      {subActivities.map((subActivity, i) => (
        <div
          className={classNames('status-icon', {
            [TABS_ICONS[i]]: true,
            'blue-icon': isActiveFilter(subActivity),
            'red-icon': isDisabledFilter(subActivity),
          })}
          key={TABS_ICONS[i]}
          onClick={() => toggleActivity(subActivity, i)}
        >
          <DefaultSelect
            className="email-status-filter-activity-select"
            items={subActivity}
            onChange={(value) => handleActivitySelect(value, i)}
            selected={activitySelectedOptions[i]}
            noBorder
          />
        </div>
      ))}
    </div>
  );
};

SentActivityBlock.propTypes = {
  addSentStatusValue: PropTypes.func.isRequired,
  deleteSentStatusValue: PropTypes.func.isRequired,
  replaceSentStatusValue: PropTypes.func.isRequired,
  subTabIds: PropTypes.array.isRequired,
};

export default injectIntl(SentActivityBlock);
