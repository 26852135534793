import { createSelector } from 'reselect';
import { getSmartFilter } from 'web/tables/smartFilters/selectors/smartFiltersSelectors';
import { FILTER_SEARCH_ID } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';

const emailSavedAdvancedSearches = (state) => state.emailSavedAdvancedSearches;
const emailSavedAdvancedSearchId = (state) =>
  state.emailSelectedSavedAdvancedSearchId;
const emailGridData = (state) => state.emailGridData;
const canResetEmailSavedAdvancedSearchId = (state) =>
  state.canResetEmailSelectedSavedAdvancedSearchId;
const isSavedSearchChanged = (state) =>
  state.isEmailSelectedSavedAdvancedSearchChanged;

export const getCollectionId = (state, props) => {
  const collectionId =
    (props && props.collectionId) || getActiveEmailTableId(state);
  return { collectionId, state };
};

export const getSmartFilterById = (filterId) =>
  createSelector([getCollectionId], ({ collectionId, state }) =>
    getSmartFilter(state, {
      collectionId,
      filterId,
    })
  );

export const getAdvancedSearchString = createSelector(
  [getSmartFilterById(FILTER_SEARCH_ID)],
  (filter) => (filter && filter.searchValue) || ''
);

export const getSavedAdvancedSearchesSidebarList = createSelector(
  [emailSavedAdvancedSearches],
  (emailSavedAdvancedSearches) => {
    const savedSearches = Object.values(emailSavedAdvancedSearches)
      .filter((savedSearch) => savedSearch.specs.smartFiltersCollection)
      .map((savedSearch) => ({
        id: savedSearch.id,
        name: savedSearch.name,
        search: savedSearch,
      }));

    return savedSearches;
  }
);

export const getSelectedSavedSearchName = createSelector(
  [emailSavedAdvancedSearches, emailSavedAdvancedSearchId],
  (emailSavedAdvancedSearches, id) =>
    (emailSavedAdvancedSearches[id] && emailSavedAdvancedSearches[id].name) ||
    ''
);

export const getSelectedGroupEmailName = createSelector(
  [emailGridData],
  (emailGridData) =>
    emailGridData.length && emailGridData[0].group
      ? emailGridData[0].group.name
      : ''
);

export const canResetSavedAdvabcedSearch = createSelector(
  [canResetEmailSavedAdvancedSearchId, emailSavedAdvancedSearchId],
  (canResetSavedSearch, savedSearchId) =>
    savedSearchId != null && canResetSavedSearch
);

export const isSavedAdvancesSearchChanged = createSelector(
  [emailSavedAdvancedSearchId, isSavedSearchChanged],
  (savedSearchId, isChanged) => savedSearchId != null && !!isChanged
);

export const isSavedAdvancesSearch = createSelector(
  [emailSavedAdvancedSearchId],
  (savedSearchId) => savedSearchId != null
);
