export default {
  open: 'EDIT_IDENTITY_SLIDE_OUT_OPEN',
  close: 'EDIT_IDENTITY_SLIDE_OUT_CLOSE',
  setEditIdentityId: 'EDIT_IDENTITY_SET_EDIT_IDENTITY_ID',
  setLoading: 'EDIT_IDENTITY_SET_LOADING',
  showAlert: 'EDIT_IDENTITY_SHOW_ALERT',
  showWarning: 'EDIT_IDENTITY_SHOW_WARNING',
  clearWarningBar: 'EDIT_IDENTITY_CLEAR_WARNING',
  clearAlert: 'EDIT_IDENTITY_CLEAR_ALERT',
  clearWarning: 'EDIT_IDENTITY_CLEAR_WARNING',
  showSuccessBar: 'EDIT_IDENTITY_SHOW_SUCCESS_BAR',
  clearSuccessBar: 'EDIT_IDENTITY_CLEAR_SUCCESS_BAR',
  setEditIdentityInfoInstance: 'EDIT_IDENTITY_SET_EDIT_IDENTITY_INFO_INSTANCE',
  setEditIdentitySignatureInstance:
    'EDIT_IDENTITY_SET_EDIT_IDENTITY_SIGNATURE_INSTANCE',
  setEditIdentityDeliveryChannelInstance:
    'EDIT_IDENTITY_SET_EDIT_IDENTITY_DELIVERY_CHANNEL_INSTANCE',
  setIdentityCardEditMode: 'EDIT_IDENTITY_SET_IDENTITY_CARD_EDIT_MODE',
  setSignatureCardEditMode: 'EDIT_IDENTITY_SET_SIGNATURE_CARD_EDIT_MODE',
  setDeliveryChannelCardEditMode:
    'EDIT_IDENTITY_SET_DELIVERY_CHANNEL_CARD_EDIT_MODE',
  setDisplayIdentityCardEditMode:
    'EDIT_IDENTITY_SET_DISPLAY_IDENTITY_CARD_IN_EDIT_MODE',
};
