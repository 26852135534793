import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getUnsubscribeOptions } from 'web/unsubscribe/unsubscribeOptions/actionCreators/unsubscribeOptionsActionCreators';
import {
  changeBlockTypeSelected,
  changeReasonSelected,
  changeOtherReason,
  unsubscribeManual,
} from '../actionCreators/unsubscribeFormActionCreators';
import { getUnsubscribeOptionsItems } from 'web/unsubscribe/unsubscribeOptions/selectors/unsubscribeOptionsSelectors';
import { REASON_TYPE } from 'web/unsubscribe/unsubscribeOptions/libs/unsubscribeOptionsConstants';
import { getUnsubscribedFrom } from '../selectors/unsubscribeFormSelectors';
import UnsubscribeFormHolder from '../components/unsubscribeFormHolder/unsubscribeFormHolder';

class UnsubscribeFormContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          getUnsubscribeOptions,
          unsubscribeManual,
          changeBlockTypeSelected,
          changeReasonSelected,
          changeOtherReason,
        },
        dispatch
      ),
    };

    this.actionCreators.getUnsubscribeOptions();
  }

  render() {
    return (
      <div className="unsubscribe-form-container">
        <UnsubscribeFormHolder
          actionCreators={this.actionCreators}
          {...this.props}
        />
      </div>
    );
  }
}

UnsubscribeFormContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchingUnsubscribeOptions: PropTypes.bool.isRequired,
  isHiddenForm: PropTypes.bool.isRequired,
  unsubscribeReasons: PropTypes.array.isRequired,
  unsubscribeBlockTypeSelected: PropTypes.string,
  unsubscribedFrom: PropTypes.string,
  unsubscribeOtherReason: PropTypes.string,
  unsubscribeReasonsSelected: PropTypes.string,
};

UnsubscribeFormContainer.defaultProps = {
  unsubscribeBlockTypeSelected: '',
  unsubscribedFrom: '',
  unsubscribeOtherReason: '',
  unsubscribeReasonsSelected: '',
};

const mapStateToProps = (state) => ({
  fetching: state.unsubscribeManualLoading,
  fetchingUnsubscribeOptions: state.unsubscribeOptionsLoading,
  isHiddenForm: state.unsubscribeFormIsHidden,
  unsubscribeBlockTypeSelected: state.unsubscribeFormBlockTypeSelected,
  unsubscribedFrom: getUnsubscribedFrom(state),
  unsubscribeOtherReason: state.unsubscribeFormOtherReason,
  unsubscribeReasons: getUnsubscribeOptionsItems(
    state,
    REASON_TYPE.unsubscribe
  ),
  unsubscribeReasonsSelected: state.unsubscribeFormReasonSelected,
});

export default connect(mapStateToProps)(UnsubscribeFormContainer);
