import React from 'react';
import PropTypes from 'prop-types';

const FillCheckIcon = (props) => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{'.cls-check{fill:#00000;}'}</style>
      </defs>
      <g id="Accept">
        <path
          className="cls-check"
          d="M16,4A12,12,0,1,0,28,16,12.0137,12.0137,0,0,0,16,4Zm5.207,9.707-6.8154,6.8155L10.7,15.6a1,1,0,1,1,1.6-1.2l2.3086,3.0776L19.793,12.293a1,1,0,0,1,1.414,1.414Z"
        />
      </g>
    </svg>
  );
};

FillCheckIcon.defaultProps = {
  className: '',
};

FillCheckIcon.propTypes = {
  className: PropTypes.string,
};

export default FillCheckIcon;
