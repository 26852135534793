import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import {
  EditTemplateCards,
  EditTemplateAnalyticsDatesSelect,
  EditTemplateAnalyticsTeamsSelect,
} from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import SmartFilter from 'components/selects/smartFilter';
import { isMyTeamFilterEnabled } from 'web/shared/services/accountService';
import LoadingSpinner from 'components/loadingSpinner';
import Cards, { Direction } from 'components/layouts/cards';
import AnalyticsSentCard from '../analyticsSentCard';
import AnalyticsEngagementCard from '../analyticsEngagementCard';
import './analyticsView.scss';

const DATES_FILTER_WIDTH = 250;
const TEAMS_FILTER_WIDTH = 250;

class AnalyticsView extends Component {
  componentWillMount() {
    const {
      actionCreators: { initAnalyticsView },
      intl: { formatMessage },
    } = this.props;

    initAnalyticsView();

    this.teamsFilters = [
      {
        label: formatMessage({
          id: 'web.editTemplateSlideOut.analyticsView.teamsSelectMe',
        }),
        value: EditTemplateAnalyticsTeamsSelect.exclude,
      },
    ];

    if (isMyTeamFilterEnabled()) {
      this.teamsFilters.push({
        label: formatMessage({
          id: 'web.editTemplateSlideOut.analyticsView.teamsSelectTeam',
        }),
        value: EditTemplateAnalyticsTeamsSelect.include,
      });
    }

    this.datesFilters = [
      {
        label: formatMessage({
          id: 'web.editTemplateSlideOut.analyticsView.lastTwelveWeeks',
        }),
        value: EditTemplateAnalyticsDatesSelect.lastTwelveWeeks,
      },
      {
        label: formatMessage({
          id: 'web.editTemplateSlideOut.analyticsView.lastThirtyDays',
        }),
        value: EditTemplateAnalyticsDatesSelect.lastThirtyDays,
      },
      {
        label: formatMessage({
          id: 'web.editTemplateSlideOut.analyticsView.lastSevenDays',
        }),
        value: EditTemplateAnalyticsDatesSelect.lastSevenDays,
      },
    ];
  }

  render() {
    const {
      actionCreators: { setAnalyticsDatesSelect, setAnalyticsTeamsSelect },
      datesSelect,
      loading,
      teamsSelect,
    } = this.props;

    return (
      <div className="edit-template-analytics-view">
        {loading && <LoadingSpinner imageUrl={getImageUrl} />}
        <div className="analytics-view-filters">
          <SmartFilter
            className="analytics-view-smart-filter"
            filters={this.teamsFilters}
            labelId="web.editTemplateSlideOut.analyticsView.teamsSelectLabel"
            onChange={setAnalyticsTeamsSelect}
            scrollTop={this.scrollTop}
            selectedValue={
              isMyTeamFilterEnabled()
                ? teamsSelect
                : EditTemplateAnalyticsTeamsSelect.exclude
            }
            width={TEAMS_FILTER_WIDTH}
          />
          <SmartFilter
            className="analytics-view-smart-filter"
            filters={this.datesFilters}
            labelId="web.editTemplateSlideOut.analyticsView.datesSelectLabel"
            onChange={setAnalyticsDatesSelect}
            scrollTop={this.scrollTop}
            selectedValue={datesSelect}
            width={DATES_FILTER_WIDTH}
          />
        </div>
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={this._getCards()}
          direction={Direction.vertical}
          marginAround={FULL}
          marginBetween={FULL}
          ref={this.setScrollRef}
        />
      </div>
    );
  }

  setScrollRef = (cards) => {
    this.scrollRef = ReactDOM.findDOMNode(cards);
  };

  scrollTop = () => {
    this.scrollRef.scrollTop = 0;
  };

  _getCards = () => {
    const { analytics, loading } = this.props;

    if (analytics.sent >= 0) {
      return [
        {
          children: <AnalyticsSentCard {...analytics} />,
          id: EditTemplateCards.analyticsSent,
        },
        {
          children: <AnalyticsEngagementCard {...analytics} />,
          id: EditTemplateCards.analyticsEngagement,
        },
        {
          children: (
            <div className="text-center tout-icon-warning">
              <FormattedMessage id="web.editTemplateSlideOut.analyticsView.warning" />
            </div>
          ),
          id: `${EditTemplateCards.analytics}-warning`,
        },
      ];
    } else if (!loading) {
      return [
        {
          children: (
            <div className="text-center">
              <FormattedMessage id="web.editTemplateSlideOut.analyticsView.empty" />
            </div>
          ),
          id: `${EditTemplateCards.analytics}-empty`,
        },
      ];
    } else {
      return [];
    }
  };
}

AnalyticsView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  datesSelect: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  teamsSelect: PropTypes.string.isRequired,
};

AnalyticsView.defaultProps = {};

export default injectIntl(AnalyticsView);
