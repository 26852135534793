import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GlobalBannerAlerts from '../components/globalBannerAlerts';
import { closeAlertById } from 'web/globalBannerAlerts/actionCreators/globalBannerAlertsActionCreators';

const GlobalBannerAlertsContainer = (props) => {
  const { closeAlertById, alerts } = props;
  return (
    <React.Fragment>
      <GlobalBannerAlerts alerts={alerts} closeAlertById={closeAlertById} />
    </React.Fragment>
  );
};

GlobalBannerAlertsContainer.propTypes = {
  alerts: PropTypes.array,
  closeAlertById: PropTypes.func.isRequired,
};

GlobalBannerAlertsContainer.defaultProps = {
  alerts: [],
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        closeAlertById,
      },
      dispatch
    ),
  };
}

const mapStateToProps = (state) => ({
  alerts: state.globalBannerAlertsState,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalBannerAlertsContainer);
