import { createSelector } from 'reselect';
import { getSubscription } from 'web/user/selectors/userSelectors';

export const getCustomDomain = createSelector(
  [getSubscription],
  (subscription) => subscription.linker_hostname
);

export const isCustomDomainFetching = (state) =>
  state.entities.tracking.customDomain.fetching;

export const isCustomDomainPageLoading = createSelector(
  [isCustomDomainFetching],
  (isDomainFetching) => isDomainFetching
);

export const getActiveTab = (state) =>
  state.ui.settings.adminSettings.tracking.activeTab;
