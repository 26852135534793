import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import AutoAppendConfirmationPopup from '../components/autoAppendConfirmationPopup';
import { AUTO_APPEND_POPUP_ID } from '../libs/generalPageConstants';
import { updateCustomAppendContent } from '../actionCreators/autoAppendActionCreators';
import PropTypes from 'prop-types';

const autoAppendPopupContainer = (props) =>
  props.popup === AUTO_APPEND_POPUP_ID ? (
    <AutoAppendConfirmationPopup {...props} />
  ) : null;

const mapStateToProps = (state) => ({
  popup: state.popup,
  popupData: state.popupData,
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      closePopup,
      updateCustomAppendContent,
    },
    dispatch
  ),
});

autoAppendPopupContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

autoAppendPopupContainer.defaultProps = {
  popupData: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(autoAppendPopupContainer);
