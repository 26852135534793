import React from 'react';
import { connect } from 'react-redux';
import SyncSettings from '../components/syncSettings';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { isPristine, isDirty } from 'redux-form';
import {
  updateSyncSettings,
  updateApiSyncSettings,
  updateSyncSettingsByCheckbox,
  logActivityViaApi,
  applyTeamSettings,
} from '../actionCreators/salesforceActionCreators';
import {
  saveSyncTasksForm,
  saveApiForm,
  saveBccForm,
  openActivateApiConfirmationPopup,
  openActivateBccConfirmationPopup,
} from 'web/salesforce/actionCreators/salesforceActionCreators';
import {
  closePopup,
  openPopup,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  isSalesforcePageFetching,
  isApplyTeamSettingsChecked,
  isActivityViaApiChecked,
  isSyncTasksChecked,
  getSalesforceOptions,
} from '../selectors/syncSettingsSelectors';
import {
  LOG_EMAIL_ACTIVITY_FORM_ADMIN_ID,
  SYNC_TASKS_FORM_ADMIN_ID,
  LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID,
  SYNC_TASKS_FORM_NON_ADMIN_ID,
} from 'web/salesforce/libs/salesforceConstants';
import {
  getApiCallsStats,
  getContactIntegrationForSalesforce,
  getIntegration,
  getStatsOccupancyPercent,
  isSyncViaApi,
} from 'web/salesforce/selectors/salesforceSelectors';

const SyncSettingsContainer = (props) => <SyncSettings {...props} />;

SyncSettingsContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isApplyTeamSettings: PropTypes.bool.isRequired,
  isDirtyLogEmailFormAdmin: PropTypes.bool.isRequired,
  isDirtyLogEmailFormNonAdmin: PropTypes.bool.isRequired,
  isDirtySyncTasksFormAdmin: PropTypes.bool.isRequired,
  isDirtySyncTasksFormNonAdmin: PropTypes.bool.isRequired,
  isSafeToChangeRoute: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  activityDynamicFieldsSalesforce: state.activityDynamicFieldsSalesforce,
  contactIntegrationForSalesforce: getContactIntegrationForSalesforce(state),
  isActivityViaApiChecked: isActivityViaApiChecked(state),
  isApplyTeamSettings: isApplyTeamSettingsChecked(state),
  isDirtyLogEmailFormAdmin: isDirty(LOG_EMAIL_ACTIVITY_FORM_ADMIN_ID)(state),
  isDirtyLogEmailFormNonAdmin: isDirty(LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID)(
    state
  ),
  isDirtySyncTasksFormAdmin: isDirty(SYNC_TASKS_FORM_ADMIN_ID)(state),
  isDirtySyncTasksFormNonAdmin: isDirty(SYNC_TASKS_FORM_NON_ADMIN_ID)(state),
  isSafeToChangeRoute:
    isPristine(LOG_EMAIL_ACTIVITY_FORM_ADMIN_ID)(state) &&
    isPristine(SYNC_TASKS_FORM_ADMIN_ID)(state) &&
    isPristine(LOG_EMAIL_ACTIVITY_FORM_NON_ADMIN_ID)(state) &&
    isPristine(SYNC_TASKS_FORM_NON_ADMIN_ID)(state),
  isSyncTasksChecked: isSyncTasksChecked(state),
  isSyncViaApi: isSyncViaApi(state),
  loading: isSalesforcePageFetching(state),
  salesforceIntegration: getIntegration(state),
  salesforceOptions: getSalesforceOptions(state),
  stats: getApiCallsStats(state),
  statsOccupancyPercent: getStatsOccupancyPercent(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      applyTeamSettings,
      closePopup,
      logActivityViaApi,
      openActivateApiConfirmationPopup,
      openActivateBccConfirmationPopup,
      openPopup,
      saveApiForm,
      saveBccForm,
      saveSyncTasksForm,
      updateApiSyncSettings,
      updateSyncSettings,
      updateSyncSettingsByCheckbox,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncSettingsContainer);
