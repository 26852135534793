import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import AccordionItem from 'components/accordion/item';
import AccordionItemHeader from 'components/accordion/item/header';
import AccordionItemBody from 'components/accordion/item/body';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import Card from 'components/card';
import LogEmailActivityCard from '../../../logEmailActivityCard';

const LogEmailActivityItem = (props) => {
  const { accordionProps, isDirtyLogEmailFormAdmin } = props;

  const onToggle = () =>
    accordionProps.onToggle({
      shouldShowUnsavedPopup: isDirtyLogEmailFormAdmin,
    });

  return (
    <AccordionItem {...accordionProps} onToggle={onToggle}>
      <AccordionItemHeader>
        <FormattedHTMLMessage id="web.settings.accountSettings.salesforce.syncSettings.accordion.logEmailActivity.title" />
      </AccordionItemHeader>
      <AccordionItemBody>
        <Page>
          <PageView className="padding-15 log-email-item">
            <Card>
              <LogEmailActivityCard {...props} />
            </Card>
          </PageView>
        </Page>
      </AccordionItemBody>
    </AccordionItem>
  );
};

LogEmailActivityItem.propTypes = {
  accordionProps: PropTypes.object.isRequired,
  isDirtyLogEmailFormAdmin: PropTypes.bool,
};

LogEmailActivityItem.defaultProps = {
  isDirtyLogEmailFormAdmin: false,
};

export default LogEmailActivityItem;
