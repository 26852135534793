import { combineReducers } from 'redux';
import teamManagementRootReducer from '../teamManagement/reducers/teamManagementRootReducer';
import userManagementRootReducer from '../userManagement/reducers/userManagementRootReducer';
import salesforceRootReducer from '../salesforce/reducers/salesforceRootReducer';
import trackingRootReducer from '../tracking/reducers/trackingRootReducer';
import * as generalTabReducer from '../general/reducers/generalReducers';

export default combineReducers({
  teamManagement: teamManagementRootReducer,
  userManagement: userManagementRootReducer,
  salesforce: salesforceRootReducer,
  tracking: trackingRootReducer,
  general: combineReducers(generalTabReducer),
});
