import React from 'react';
import PropTypes from 'prop-types';
import PersonItem from '../personItem';
import PeopleRow from './peopleRow';
import classNames from 'classnames';
import './peopleList.scss';

const PeopleList = ({ people, status: Status, className, additionalRow }) => {
  const classes = classNames('people-simple-grid-list', className);
  return (
    <div className={classes}>
      {additionalRow}
      {people.map((person) => (
        <PeopleRow
          isInvalid={!!person.blockedReason}
          key={`person-row-${person.id}`}
          personCell={
            <PersonItem className="people-list-person-item" person={person} />
          }
          statusCell={<Status person={person} />}
        />
      ))}
    </div>
  );
};

PeopleList.defaultProps = {
  additionalRow: null,
  className: '',
};

PeopleList.propTypes = {
  className: PropTypes.string,
  people: PropTypes.array.isRequired,
  status: PropTypes.func.isRequired,
  additionalRow: PropTypes.element,
};

export default PeopleList;
