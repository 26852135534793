import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import Action from 'components/buttons/action';
import Select from 'web/form/components/fields/select';
import Switch from 'components/buttons/switch';
import I18N from 'languages';
import {
  minValue as minValueValidatorFactory,
  numbers as numberValidatorFactory,
} from 'web/form/helpers/validators';
import Text from 'web/form/components/fields/text';
import {
  EMAIL_THROTTLING_TIME_UNITS,
  EMAIL_THROTTLING_SOURCE_TYPES,
} from 'web/settings/adminSettings/general/libs/generalPageConstants';
import CardHighlight from 'components/cardHighlight/cardHighlight';
import { DeliveryChannelRecommendedSettings } from 'web/deliveryChannels/libs/deliveryChannelsConstants';
import './emailThrottlingLimitForm.scss';

class EmailThrottlingLimitForm extends Component {
  constructor(props) {
    super(props);
    this.textFieldValidators = [
      numberValidatorFactory(),
      minValueValidatorFactory(1),
    ];
    this.lightBulbIcon = 'lightbulb';
  }

  onFormSubmitError = (e) => {
    const { onError } = this.props;
    onError();
    e.preventDefault();
  };

  render() {
    const {
      pristine,
      valid,
      handleSubmit,
      initialValues: { isEnabled },
    } = this.props;
    const switchLabel = isEnabled
      ? I18N.getStr(
          'web.settings.adminSettings.general.communicationLimitsCard.emailThrottlingLimit.enabledSwitchLabel'
        )
      : I18N.getStr(
          'web.settings.adminSettings.general.communicationLimitsCard.emailThrottlingLimit.disabledSwitchLabel'
        );

    return (
      <React.Fragment>
        <h3>
          <FormattedMessage id="web.settings.adminSettings.general.communicationLimitsCard.emailThrottlingLimit.title" />
        </h3>
        {this._getThrottlingTip()}
        {this._getCustomSettingsHelpTip()}
        <div className="margin-top-20">
          <FormattedMessage id="web.settings.adminSettings.general.communicationLimitsCard.emailThrottlingLimit.description" />
          <br />
          <FormattedMessage id="web.settings.adminSettings.general.communicationLimitsCard.emailThrottlingLimit.description.continue" />
        </div>
        <Switch
          checked={isEnabled}
          classes="padding-top-25"
          label={switchLabel}
          name="call_recording_enabled"
          onChange={this.toggleEmailThrottlingSettings}
          rightLabel
        />
        {isEnabled && (
          <Form
            className="team-email-limit-form margin-0"
            onSubmit={valid ? handleSubmit : this.onFormSubmitError}
            noValidate
          >
            <div className="margin-top-10 emailThrottlingLimitData">
              <Field
                autoFocus={false}
                className="team-email-throttling-limit-form-field"
                component={Text}
                name="emailsAmount"
                type="text"
                validate={this.textFieldValidators}
              />
              <span className="emailThrottlingLimitDataTxt">
                <FormattedMessage id="web.settings.adminSettings.general.communicationLimitsCard.emailThrottlingLimit.label" />
              </span>
              <Field
                autoFocus={false}
                className="team-email-throttling-limit-form-field"
                component={Text}
                name="timeAmount"
                type="text"
                validate={this.textFieldValidators}
              />
              <Field
                autoFocus={false}
                className="team-email-throttling-limit-form-field margin-left-half"
                clearable={false}
                component={Select}
                name="timeUnit"
                options={EMAIL_THROTTLING_TIME_UNITS}
                placeholder={I18N.getStr(
                  'web.settings.adminSettings.general.forms.placeholder.emailThrottlingLimitTimeUnit'
                )}
                searchable={false}
                type="text"
              />
            </div>
            <div className="margin-top-20 right">
              <Action disabled={pristine} type="submit">
                <FormattedMessage id="common.save" />
              </Action>
            </div>
          </Form>
        )}
      </React.Fragment>
    );
  }

  _getCustomSettingsHelpTip = () => {
    const {
      initialValues: { source, emailsAmount, isEnabled },
      defaultDeliveryChannel,
    } = this.props;
    const isCustomThrottlingTipVisible =
      source === EMAIL_THROTTLING_SOURCE_TYPES.manual &&
      emailsAmount &&
      isEnabled;
    if (!isCustomThrottlingTipVisible) {
      return;
    }
    if (!defaultDeliveryChannel) {
      // no connection set up
      return (
        <CardHighlight
          className="throttling-tip"
          iconType={this.lightBulbIcon}
          textId="web.settings.adminSettings.general.forms.tip.emailThrottlingCustomLimitNoConnection"
        />
      );
    }
    return this._getRecommendedSettingsHelpTip();
  };

  _getThrottlingTip = () => {
    const {
      initialValues: { source, isEnabled },
      defaultDeliveryChannel,
    } = this.props;
    const isThrottlingTipVisible =
      source === EMAIL_THROTTLING_SOURCE_TYPES.auto &&
      defaultDeliveryChannel &&
      isEnabled;
    return (
      isThrottlingTipVisible && (
        <CardHighlight
          className="throttling-tip"
          iconType={this.lightBulbIcon}
          textId="web.settings.adminSettings.general.forms.tip.emailThrottlingLimit"
          values={{
            provider: I18N.getStr(
              'web.settings.adminSettings.general.deliveryChannelLabelWithoutUsername',
              defaultDeliveryChannel
            ),
          }}
        />
      )
    );
  };

  _getRecommendedSettingsHelpTip = () => {
    const { defaultDeliveryChannel, initialValues } = this.props;
    const recommendedSettings =
      DeliveryChannelRecommendedSettings[defaultDeliveryChannel.provider];
    const isExistSettingsEmailsAmount =
      recommendedSettings && recommendedSettings.emailsAmount;

    const areSettingsCustom =
      initialValues.emailsAmount &&
      isExistSettingsEmailsAmount &&
      (initialValues.emailsAmount !== recommendedSettings.emailsAmount ||
        initialValues.timeAmount !== recommendedSettings.timeAmount ||
        initialValues.timeUnit !== recommendedSettings.timeUnit);
    if (recommendedSettings && areSettingsCustom) {
      const formattedString = I18N.getStr(
        'web.settings.adminSettings.general.forms.tip.emailThrottlingCustomLimit',
        {
          provider: I18N.getStr(
            'web.settings.adminSettings.general.deliveryChannelLabelWithoutUsername',
            defaultDeliveryChannel
          ),
        }
      );
      const preformattedBlock = (
        <div>
          <span>{formattedString}</span>
          <a onClick={this.updateEmailThrottlingSettingsHandler}>
            {I18N.getStr('common.here')}
          </a>
        </div>
      );

      return (
        <CardHighlight
          className="throttling-tip"
          iconType={this.lightBulbIcon}
          preformattedBlock={preformattedBlock}
        />
      );
    }
  };

  updateEmailThrottlingSettingsHandler = () => {
    const {
      initialValues,
      defaultDeliveryChannel,
      updateEmailThrottlingSettings,
    } = this.props;
    let params = {
      ...initialValues,
      isAutoUpdate: true,
      timeAmount: 1,
      ...DeliveryChannelRecommendedSettings[defaultDeliveryChannel.provider],
    };
    updateEmailThrottlingSettings(params);
  };

  toggleEmailThrottlingSettings = (isEnabled) => {
    const { initialValues, updateEmailThrottlingSettings } = this.props;
    const isAutoUpdate =
      initialValues.source === EMAIL_THROTTLING_SOURCE_TYPES.auto;
    updateEmailThrottlingSettings({
      ...initialValues,
      isAutoUpdate,
      isEnabled,
    });
  };
}

EmailThrottlingLimitForm.propTypes = {
  defaultDeliveryChannel: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  updateEmailThrottlingSettings: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default reduxForm({
  enableReinitialize: true,
})(EmailThrottlingLimitForm);
