import { baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function deleteFiles(ids) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.contentCollection, { ids }, resolve, reject);
  });
}

export function parseIdsForNewUploadsOnly(attachments = []) {
  if (attachments.length) {
    return attachments.reduce((prevArray, file) => {
      if (file.id && file.status) {
        prevArray.push(file.id);
      }

      return prevArray;
    }, []);
  } else {
    return [];
  }
}

// attachments should all be finished uploading
export function parseIds(attachments = []) {
  return attachments.reduce((accum, curr) => {
    if (curr.id) {
      accum.push(curr.id);
    }
    return accum;
  }, []);
}
