import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ColorClasses } from 'libs/constantsStyles';
import './barChartLine.scss';

const COUNT_LENGTH = 30;

class BarChartLine extends PureComponent {
  render() {
    const { color, count, percent, showCount } = this.props;
    const normalizedLength = this._getNormalizedLength();
    const barClasses = classNames('bar-chart-line-bar', {
      [`background-${color}`]: color,
    });

    return (
      <div className="bar-chart-line" style={this._getContainerStyles()}>
        <div
          className={barClasses}
          style={this._getBarStyles(normalizedLength)}
        />
        {showCount && (
          <div
            className={classNames('bar-chart-line-count', {
              [`text-${color}`]: color,
            })}
          >
            {count || percent || 0}
          </div>
        )}
      </div>
    );
  }

  _getContainerStyles = () => {
    const { length, thickness } = this.props;
    return {
      height: `${thickness}px`,
      width: `${length}px`,
    };
  };

  _getBarStyles = (normalizedLength) => {
    const { thickness } = this.props;
    return {
      borderTopRightRadius: `${thickness}px`,
      borderBottomRightRadius: `${thickness}px`,
      width: `${normalizedLength}px`,
    };
  };

  _getNormalizedLength = () => {
    const { length, showCount } = this.props;
    const calculatedPercent = this._getCalculatedPercent();
    const calculatedLength = (showCount && length - COUNT_LENGTH) || length;

    if (calculatedPercent < 0) {
      return 0;
    }
    if (calculatedPercent > 1) {
      return calculatedLength;
    }
    return calculatedPercent * calculatedLength;
  };

  /* If maxStat, use as base. Else if count, base count on total. Else, base percent on 100. */
  _getCalculatedPercent = () => {
    const { count, maxStat, percent, total } = this.props;
    if (maxStat) {
      return (count || percent) / maxStat;
    }
    if (count) {
      return count / total;
    }
    return percent / 100;
  };
}

export const BarChartLineColors = { ...ColorClasses };

BarChartLine.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  length: PropTypes.number,
  maxStat: PropTypes.number,
  percent: PropTypes.number,
  showCount: PropTypes.bool,
  thickness: PropTypes.number,
  total: PropTypes.number,
};

BarChartLine.defaultProps = {
  color: '', // tout-blue
  count: 0,
  length: 200,
  maxStat: 0,
  percent: 0,
  showCount: false,
  thickness: 20,
  total: 1,
};

export default BarChartLine;
