import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import { FormattedMessage } from 'react-intl';
import './actionBar.scss';
import { ButtonStatus } from 'libs/constantsShared';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import I18N, { moment } from 'languages';

const ActionBar = ({
  bulkSendPitch,
  composeBulkViewOpened,
  group,
  isSchedulerShowing,
  isSendLoading,
  pitchSendAt,
  sendPitch,
  setSchedulerShowing,
  setShowDeliveryBanner,
  shouldHideDeliveryChannels,
}) => {
  const getUserMessage = () => {
    const formattedTime = moment.parseZone(pitchSendAt);
    const date = I18N.getLocalizedFullDate(formattedTime);
    const hour = I18N.getLocalizedTime(
      formattedTime.format(I18N.DateFormats.TIME_SHORT)
    );
    return (
      <FormattedMessage
        id="web.composeWindow.schedulerPopUp.scheduledAt"
        values={{ date, hour }}
      />
    );
  };

  function onSendClick() {
    if (shouldHideDeliveryChannels) {
      setShowDeliveryBanner(true);
    } else {
      if (composeBulkViewOpened && !group) {
        bulkSendPitch();
      } else {
        sendPitch();
      }
    }
  }

  const buttonStatus = isSendLoading ? ButtonStatus.disabled : '';

  return (
    <div className="compose-window-actionbar-wrapper">
      <div className="compose-window-footer-buttons">
        {pitchSendAt && (
          <div className="schedule-message">{getUserMessage()}</div>
        )}
        <Button
          classes="compose-button schedule-now-button grey"
          onClick={() => setSchedulerShowing(!isSchedulerShowing)}
        >
          <span className="tout-icon-schedule-now" />
          <FormattedMessage id="common.schedule" />
        </Button>
        <Button
          classes="compose-button send blue"
          onClick={onSendClick}
          status={buttonStatus}
        >
          {isSendLoading ? (
            <React.Fragment>
              <div className="send-loading-spinner">
                <LoadingSpinner />
              </div>
              <div className="loading-text">
                <FormattedMessage id="common.send" />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span className="tout-icon-sent" />
              <FormattedMessage
                id={pitchSendAt ? 'common.scheduler.save' : 'common.send'}
              />
            </React.Fragment>
          )}
        </Button>
      </div>
    </div>
  );
};

ActionBar.propTypes = {
  bulkSendPitch: PropTypes.func.isRequired,
  composeBulkViewOpened: PropTypes.bool,
  group: PropTypes.object,
  isSchedulerShowing: PropTypes.bool.isRequired,
  isSendLoading: PropTypes.bool.isRequired,
  pitchSendAt: PropTypes.string,
  sendPitch: PropTypes.func.isRequired,
  setSchedulerShowing: PropTypes.func.isRequired,
  setShowDeliveryBanner: PropTypes.func.isRequired,
  shouldHideDeliveryChannels: PropTypes.bool,
};

ActionBar.defaultProps = {
  composeBulkViewOpened: false,
  group: undefined,
  pitchSendAt: null,
  setShowDeliveryBanner: undefined,
  shouldHideDeliveryChannels: true,
};

export default ActionBar;
