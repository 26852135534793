import { getUserChannel } from 'web/services/pusherService';
import { PusherEvents } from 'web/libs/constants';
import { updateInvitations } from './invitationsActionCreators';

export const setInvitationsPusherCallbacks = () => (dispatch) => {
  const channel = getUserChannel();
  const callback = (invitation = {}) => {
    dispatch(updateInvitations([invitation]));
  };

  channel.bind(PusherEvents.invitationUpdate, callback);
};
