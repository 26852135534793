import SalesforceCustomizationActionTypes from 'web/modals/salesforceCustomization/libs/salesforceCustomizationActionTypes';

const initialState = {
  availableVersions: [], //new versions available for install
  completedVersions: [], //added dynamically from pusher when {complete: true} in progress callback
  installedVersions: [], //already installed, which could be removed
};

export const salesforceCustomizationVersions = (
  state = initialState,
  payload = {}
) => {
  const { type, ...data } = payload;

  switch (type) {
    case SalesforceCustomizationActionTypes.availableVersions.set:
      return {
        ...state,
        availableVersions: data.availableVersions,
      };
    case SalesforceCustomizationActionTypes.availableVersions.clear:
      return {
        ...state,
        availableVersions: initialState.availableVersions,
      };

    case SalesforceCustomizationActionTypes.installedVersions.set:
      return {
        ...state,
        installedVersions: data.installedVersions,
      };
    case SalesforceCustomizationActionTypes.installedVersions.clear:
      return {
        ...state,
        installedVersions: initialState.installedVersions,
      };

    case SalesforceCustomizationActionTypes.completedVersions.add:
      return data.version
        ? {
            ...state,
            completedVersions: [...state.completedVersions, data.version],
          }
        : state;
    case SalesforceCustomizationActionTypes.completedVersions.clear:
      return {
        ...state,
        completedVersions: initialState.completedVersions,
      };

    case SalesforceCustomizationActionTypes.versions.clear:
      return initialState;

    default:
      return state;
  }
};
