import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popup, { PopupButtonColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import { getImageUrl } from 'web/libs/constants';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import DeleteUsersDisclaimer from '../deleteUsersDisclaimer';
import head from 'lodash/head';

class DeleteUsersConfirmationPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      popupData: { ids },
      defaultAlert,
      deleteUsersLoading,
      isMsiActions,
    } = this.props;
    const isAdminSelected = this.isAdminSelected();
    const isYourselfInList = this.isYourselfInList();

    return (
      <Popup
        alert={defaultAlert}
        footer={{
          onPrimary: this.onDeleteUsers,
          onSecondary: closePopup,
          primaryTextId: 'common.delete',
          secondaryTextId: 'common.cancel',
          primaryColor: PopupButtonColors.red,
          primaryDisabled: isAdminSelected,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.userManagement.users.popup.deleteUsers.title',
          textValues: { count: ids.length },
        }}
        loading={deleteUsersLoading}
        getImageUrl={getImageUrl}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        {isAdminSelected ? (
          <FormattedMessage id="web.settings.adminSettings.userManagement.users.popup.deleteUsers.selectedAdmin" />
        ) : (
          <Fragment>
            <FormattedHTMLMessage
              id={
                isMsiActions
                  ? 'web.settings.adminSettings.userManagement.users.salesInsightActionsPlan.popup.deleteUsers.body'
                  : 'web.settings.adminSettings.userManagement.users.popup.deleteUsers.body'
              }
              values={{ count: ids.length }}
            />
            {isYourselfInList && (
              <div className="delete-users-popup-warning">
                <div className="tout-icon-warning" />
                <FormattedMessage id="web.settings.adminSettings.userManagement.users.popup.deleteUsers.yourselfInList" />
              </div>
            )}
            <DeleteUsersDisclaimer isMsiActions={isMsiActions} />
          </Fragment>
        )}
      </Popup>
    );
  }

  isYourselfInList = () => {
    const {
      popupData: { ids },
      currentUserId,
      isAdmin,
    } = this.props;
    return isAdmin && ids.length > 1 && ids.includes(currentUserId);
  };

  isAdminSelected = () => {
    const {
      popupData: { ids },
      currentUserId,
      isAdmin,
    } = this.props;
    return isAdmin && ids.length === 1 && head(ids) === currentUserId;
  };

  onDeleteUsers = () => {
    const {
      actionCreators: { deleteUsers },
      popupData: { ids },
    } = this.props;

    deleteUsers(ids);
  };
}

DeleteUsersConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,
  defaultAlert: PropTypes.object,
  deleteUsersLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  popupData: PropTypes.object,
};

DeleteUsersConfirmationPopup.defaultProps = {
  defaultAlert: null,
  popupData: {},
};

export default DeleteUsersConfirmationPopup;
