import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import { NotableTypes } from 'web/notes/libs/notesConstants';
import {
  EditTemplateCards,
  EditTemplateTabAlertIds,
} from '../libs/editTemplateSlideOutConstants';
import {
  getDatesFromAnalyticsSelect,
  getDetailsByCard,
  parseAnalytics,
} from '../helpers/editTemplateParsers';
import {
  closeCardEditing,
  openCardEditing,
  setTabAlert,
  setTabLoading,
} from './editTemplateActionCreators';
import {
  getTemplateDetails as commonGetTemplateDetails,
  updateTemplateDetails,
} from 'web/templates/actionCreators/templatesTemplateDetailsActionCreators';
import {
  updateTemplateTags as updateTemplateTagsCall,
  updateBypassUnsubscribe as updateBypassUnsubscribeCall,
} from 'web/templates/services/templateDetailsService';
import { updateTemplate as updateGridTemplate } from 'web/templates/actionCreators/templatesUpdateActionCreators';
import { getTemplateStats } from 'web/templates/services/templateStatsService';
import { getTemplateEmails as getTemplateEmailsCall } from 'web/templates/services/templateEmailsService';
import { createNote, updateNote } from 'web/notes/services/notesService';
import { getActiveCampaignTemplates } from 'web/campaignsUsingTemplate/services/campaignsUsingTemplateService';
import { openModalUpdateCampaignTemplates } from 'web/modals/campaignTemplates';
import { TEMPLATE_TAG_TYPES } from 'web/templates/libs/templatesConstants';
import { isSubjectMissing } from '../helpers/editTemplateHasChangesHelper';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import filter from 'lodash/filter';

import { openEmailComposePopup } from 'web/emailComposeBody/actionCreators/popupActionCreators';
import { GlobalPopupIds } from 'web/emailComposeBody/libs/emailComposeBodyConstants';
import {
  getIncorrectDynamicFields,
  getDynamicFieldsAsArray,
} from 'web/shared/helpers/dynamicFieldsHelper';

export const getTemplateDetails = (id, openEditing = false) => (dispatch) => {
  dispatch(setTabLoading(true));
  dispatch(commonGetTemplateDetails(id))
    .then((template) => {
      dispatch({
        type: EditTemplateActionTypes.setTemplate,
        template,
      });

      if (openEditing) {
        dispatch(openCardEditing(EditTemplateCards.template));
      }

      dispatch(setTabLoading(false));
    })
    .catch(() => {
      dispatch(setTabAlert(EditTemplateTabAlertIds.getTemplateDetails));
      dispatch(setTabLoading(false));
    });
};

export const getTemplateAnalytics = () => (dispatch, getState) => {
  const {
    editTemplateSlideOutAnalyticsViewState: { datesSelect, teamsSelect },
    editTemplateSlideOutTemplate: { id },
    templatesViewer,
  } = getState();
  const { from, to } = getDatesFromAnalyticsSelect(datesSelect);

  dispatch(setTabLoading(true));
  getTemplateStats(id, from, to, teamsSelect, templatesViewer)
    .then((analytics = {}) => {
      dispatch({
        type: EditTemplateActionTypes.setAnalytics,
        analytics: parseAnalytics(analytics),
      });
      dispatch(setTabLoading(false));
    })
    .catch(() => {
      dispatch(setTabAlert(EditTemplateTabAlertIds.getTemplateAnalytics));
      dispatch(setTabLoading(false));
    });
};

export const getTemplateEmails = () => (dispatch, getState) => {
  const {
    editTemplateSlideOutTemplate: { id },
  } = getState();

  dispatch(setTabLoading(true));
  getTemplateEmailsCall(id)
    .then((emails = []) => {
      dispatch({
        type: EditTemplateActionTypes.setEmails,
        emails,
      });
      dispatch(setTabLoading(false));
    })
    .catch(() => {
      dispatch(setTabAlert(EditTemplateTabAlertIds.getTemplateEmails));
      dispatch(setTabLoading(false));
    });
};

export const saveNote = (content, id) => (dispatch, getState) => {
  dispatch(setTabLoading(true));
  if (id) {
    updateNote(id, { content })
      .then((note) => {
        dispatch({
          type: EditTemplateActionTypes.updateTemplateNote,
          note,
        });
        dispatch(setTabLoading(false));
      })
      .catch(() => {
        dispatch(setTabAlert(EditTemplateTabAlertIds.updateNote));
        dispatch(setTabLoading(false));
      });
  } else {
    createNote(
      content,
      getState().editTemplateSlideOutTemplate.id,
      NotableTypes.template
    )
      .then((note) => {
        dispatch({
          type: EditTemplateActionTypes.addTemplateNote,
          note,
        });
        dispatch(setTabLoading(false));
      })
      .catch(() => {
        dispatch(setTabAlert(EditTemplateTabAlertIds.createNote));
        dispatch(setTabLoading(false));
      });
  }
};

const checkActiveCampaignTemplates = ({ id } = {}) => () => {
  getActiveCampaignTemplates(id).then((templates = []) => {
    if (templates.length) {
      openModalUpdateCampaignTemplates(id, templates);
    }
  });
};
export const saveTemplate = (cardId) => (dispatch, getState) => {
  const state = getState();
  const { attachments, templateDetails } = getDetailsByCard(cardId, state);

  if (!templateDetails) {
    dispatch(setTabAlert(EditTemplateTabAlertIds.saveTemplate));
    return;
  } else if (isSubjectMissing(cardId, state)) {
    dispatch(setTabAlert(EditTemplateTabAlertIds.missingSubject));
    return;
  }

  const incorrectDynamicFields = getIncorrectDynamicFields(
    templateDetails.body,
    getDynamicFieldsAsArray(state.dynamicFields)
  );

  if (incorrectDynamicFields.length > 0) {
    dispatch(
      openEmailComposePopup(GlobalPopupIds.dynamicFieldsIncorrect, {
        incorrectDynamicFields,
      })
    );
    return;
  }

  dispatch(setTabLoading(true));
  dispatch(
    updateTemplateDetails(templateDetails.id, templateDetails, attachments)
  )
    .then((template) => {
      dispatch({
        type: EditTemplateActionTypes.setTemplate,
        template,
      });
      toutBackboneHelper.templateUpdate(template);
      dispatch(setTabLoading(false));
      dispatch(closeCardEditing(cardId));
      dispatch(checkActiveCampaignTemplates(template));
    })
    .catch((err) => {
      dispatch(setTabLoading(false));
      if (err.status === 422) {
        try {
          const customErrors = JSON.parse(err.response.text);
          if (customErrors.dynamic_fields) {
            dispatch(
              setTabAlert(EditTemplateTabAlertIds.dynamicFields, {
                dynamic_field_format: '{{dynamic_field_name}}',
              })
            );
          } else {
            dispatch(setTabAlert(EditTemplateTabAlertIds.saveTemplate));
          }
        } catch (error) {
          dispatch(setTabAlert(EditTemplateTabAlertIds.saveTemplate));
        }
      }
    });
};

const setTemplateTags = (template = {}, tag_user_ids) => ({
  type: EditTemplateActionTypes.setTemplate,
  template: { ...template, tag_user_ids },
});

const updateTags = (slideOutTemplate, template, tags) => (dispatch) => {
  if (slideOutTemplate && slideOutTemplate.id === template.id) {
    dispatch(setTemplateTags(template, tags));
  }
  dispatch(updateGridTemplate({ ...template, tag_user_ids: tags }));
};

const getNewTagsToSet = (originalTags, userId, settingFavorite) => {
  const originalFavoriteUserIds =
    originalTags[TEMPLATE_TAG_TYPES.favorite] || [];
  let newFavoriteUserIds;
  if (settingFavorite) {
    newFavoriteUserIds = [...originalFavoriteUserIds, userId];
  } else {
    newFavoriteUserIds = filter(originalFavoriteUserIds, (id) => id !== userId);
  }
  return { ...originalTags, favorite: newFavoriteUserIds };
};

export const setFavorite = (settingFavorite = false, templateToSet) => (
  dispatch,
  getState
) => {
  const { editTemplateSlideOutTemplate, templatesViewer: user } = getState();
  const template = templateToSet || editTemplateSlideOutTemplate;
  const { id: userId } = user;
  const { id: templateId, tag_user_ids: originalTags = {} } = template;

  const newTags = getNewTagsToSet(originalTags, userId, settingFavorite);
  dispatch(updateTags(editTemplateSlideOutTemplate, template, newTags));
  updateTemplateTagsCall(
    templateId,
    TEMPLATE_TAG_TYPES.favorite,
    user,
    settingFavorite
  )
    .then(() => {
      // see note in reducer on using this list of favorites
      dispatch({
        type: TemplatesActionTypes.favorites.setSingle,
        template,
        setting: settingFavorite,
      });
    })
    .catch(() => {
      dispatch(
        setTabAlert(EditTemplateTabAlertIds.setFavorite, { settingFavorite })
      );
      dispatch(
        updateTags(editTemplateSlideOutTemplate, template, originalTags)
      );
    });
};

const setTemplateName = (template, name) => ({
  type: EditTemplateActionTypes.setTemplate,
  template: { ...template, name },
});

export const saveName = (name = '') => (dispatch, getState) => {
  const { editTemplateSlideOutTemplate } = getState();
  const originalName = editTemplateSlideOutTemplate.name;
  dispatch(setTemplateName(editTemplateSlideOutTemplate, name));

  dispatch(setTabLoading(true));
  name = name.trim();
  dispatch(updateTemplateDetails(editTemplateSlideOutTemplate.id, { name }))
    .then((template) => {
      dispatch({
        type: EditTemplateActionTypes.setTemplate,
        template,
      });
      toutBackboneHelper.templateUpdate(template);
      dispatch(setTabLoading(false));
    })
    .catch(() => {
      dispatch(setTabAlert(EditTemplateTabAlertIds.saveName));
      dispatch(setTabLoading(false));
      dispatch(setTemplateName(editTemplateSlideOutTemplate, originalName));
    });
};

const setTemplateBypassUnsubscribe = (template, bypassUnsubscribe = false) => ({
  template: { ...template, bypass_unsubscribe: bypassUnsubscribe },
  type: EditTemplateActionTypes.setTemplate,
});

export const updateBypassUnsubscribe = (
  templateId,
  bypassUnsubscribe
) => async (dispatch, getState) => {
  const { editTemplateSlideOutTemplate } = getState();
  dispatch(
    setTemplateBypassUnsubscribe(
      editTemplateSlideOutTemplate,
      bypassUnsubscribe
    )
  );

  try {
    await updateBypassUnsubscribeCall(templateId, bypassUnsubscribe);
  } catch (e) {
    // To do
  }
};
