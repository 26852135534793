import SalesforceActionTypes from '../libs/salesforceActionTypes';
import { SalesforceTabs } from '../libs/salesforceConstants';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { connectionsCustomizationsStartup } from './connectionsCustomizationsActionCreators';
import { syncSettingsStartup } from './syncSettingsActionCreators';
import { initializeHealthStatuses } from 'web/settings/myProfile/components/yourIntegrations/actionCreators/yourIntegrationsActionCreators';
import { clearAlerts } from 'web/globalBannerAlerts/actionCreators/globalBannerAlertsActionCreators';

export const setTab = (tab) => ({
  type: SalesforceActionTypes.setTab,
  tab,
});

export const salesforceStartup = ({ tab }) => (dispatch) => {
  const activeTab = Object.values(SalesforceTabs).includes(tab)
    ? tab
    : SalesforceTabs.connectionsCustomizations;
  dispatch(commonStartup());
  dispatch(setTab(activeTab));
  dispatch(clearAlerts());

  if (activeTab === SalesforceTabs.connectionsCustomizations) {
    dispatch(connectionsCustomizationsStartup());
    dispatch(initializeHealthStatuses());
  } else if (activeTab === SalesforceTabs.syncSettings) {
    dispatch(syncSettingsStartup());
  }
};
