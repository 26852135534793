import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { EditTemplateMaxMappings } from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import RecommendEditingTableRow from '../recommendEditingTableRow';
import Icon, {
  IconColors,
  IconBackgroundColors,
} from 'components/buttons/icon';
import TextButton from 'components/buttons/text';
import './recommendEditingTable.scss';

class RecommendEditingTable extends PureComponent {
  componentWillMount() {
    const {
      addRecommendedRow,
      intl: { formatMessage },
      mappings,
    } = this.props;
    this.dynamicFieldLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editingTable.dynamicField',
    });
    this.conditionLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editingTable.rule',
    });
    this.valueLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editingTable.keyword',
    });
    this.addMoreLabel = formatMessage({
      id: 'web.editTemplateSlideOut.recommendCard.editingTable.addMore',
    });

    if (mappings.length === 0) {
      addRecommendedRow();
    }
  }

  render() {
    const {
      addRecommendedRow,
      disabled,
      mappings,
      openDynamicFields,
      removeRecommendedRow,
      updateEditState,
    } = this.props;
    const disabledAddMore =
      disabled || mappings.length >= EditTemplateMaxMappings;

    return (
      <div className="edit-template-recommend-editing-table margin-top-full">
        <div className="recommend-editing-table-header">
          <div className="recommend-editing-table-header-cell recommend-editing-table-header-cell-dynamic text-medium">
            {this.dynamicFieldLabel}
          </div>
          <div className="recommend-editing-table-header-cell recommend-editing-table-header-cell-condition text-medium">
            {this.conditionLabel}
          </div>
          <div className="recommend-editing-table-header-cell recommend-editing-table-header-cell-value text-medium">
            {this.valueLabel}
          </div>
        </div>
        {mappings.map((mapping, index) => (
          <RecommendEditingTableRow
            disabled={disabled}
            index={index}
            key={`recommended-editing-table-row-key-${mapping.field ||
              index}-${mapping.id || index}`}
            openDynamicFields={openDynamicFields}
            remove={removeRecommendedRow}
            updateEditState={updateEditState}
            {...mapping}
          />
        ))}
        <div className="margin-top-full">
          <Icon
            backgroundColor={IconBackgroundColors.blue}
            className="recommend-editing-add-icon"
            color={IconColors.white}
            disabled={disabledAddMore}
            onClick={addRecommendedRow}
          />
          <TextButton
            className="recommend-editing-add-text margin-left-half"
            disabled={disabledAddMore}
            onClick={disabledAddMore ? undefined : addRecommendedRow}
          >
            {this.addMoreLabel}
          </TextButton>
        </div>
      </div>
    );
  }
}

RecommendEditingTable.propTypes = {
  addRecommendedRow: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
  mappings: PropTypes.array,
  removeRecommendedRow: PropTypes.func.isRequired,
  openDynamicFields: PropTypes.func.isRequired,
  updateEditState: PropTypes.func.isRequired,
};

RecommendEditingTable.defaultProps = {
  disabled: false,
  mappings: [],
};

export default injectIntl(RecommendEditingTable);
