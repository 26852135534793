export function getPrimaryEmail(person = {}) {
  if (!person.addresses) {
    return null;
  }

  const emails = person.addresses.filter(
    (address) => address.address_type === 'email'
  );
  if (emails.length) {
    const workEmail = emails.filter((email) => email.location === 'work');
    return workEmail.length ? workEmail[0] : emails[0];
  }
  return null;
}

export function getPrimaryPhone(person = {}) {
  if (!person.addresses) {
    return null;
  }

  const phones = person.addresses.filter(
    (address) => address.address_type === 'phone'
  );
  return phones.length ? phones[0] : null;
}
