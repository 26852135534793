import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Regex } from 'web/libs/constants';

class CsvExporter extends Component {
  componentDidMount() {
    const { elmId } = this.props;
    document.getElementById(elmId).click();
  }

  render() {
    const { columns, data, elmId, fileName } = this.props;

    return (
      <div className="csv-exporter">
        <a //eslint-disable-line jsx-a11y/anchor-has-content
          download={`${fileName}.csv`}
          href={this._csvBuilder(columns, data)}
          id={elmId}
        />
      </div>
    );
  }

  _csvBuilder = (columns, rows) => {
    let csvText = 'data:text/csv;charset=utf-8';
    columns.forEach((column) => {
      csvText = `${csvText},${column}`;
    });

    csvText = `${csvText}\n`;

    rows.forEach((data) => {
      const escapedData = data.map((value) => {
        return this._escape(value);
      });
      csvText = `${csvText}${escapedData},\n`;
    });

    return encodeURI(csvText);
  };

  _escape = (value) => {
    let escapedValue = value;
    if (typeof value === 'string') {
      escapedValue = escapedValue.replace(
        Regex.spreadsheetFormulaCharacters,
        "'$&"
      );
      if (value.match(Regex.csvControlCharacters)) {
        escapedValue = `"${escapedValue.replace(/"/g, '""')}"`;
      }
    }

    return escapedValue;
  };

  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    elmId: PropTypes.string,
    fileName: PropTypes.string,
  };

  static defaultProps = {
    elmId: 'csv-exporter',
    fileName: 'data',
  };
}

export default CsvExporter;
