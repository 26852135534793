import React from 'react';
import './websiteTrackingPage.scss';
import Card from 'components/card';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import {
  ACTION_TRACKING_SCRIPT,
  VISIT_TRACKING_SCRIPT,
} from '../../../libs/trackingConstants';
import WebsiteTrackingEvents from '../websiteTrackingEvents';
import PropTypes from 'prop-types';
import TextBoxWithCopyToClipboard from 'components/textBoxWithCopyToClipboard';
import { isWebsiteTrackingDisabled } from 'web/shared/helpers/featureFlagHelper';

const WebsiteTrackingPage = (props) => {
  const { siteHash, user, ...restProps } = props;
  const visitTrackingScript = VISIT_TRACKING_SCRIPT.replace(
    '%SITE_HASH%',
    siteHash
  );
  const actionTrackingScript = ACTION_TRACKING_SCRIPT.replace(
    '%SITE_HASH%',
    siteHash
  );

  return isWebsiteTrackingDisabled(user) ? (
    <Card className="website-tracking-page">
      <h3>
        <FormattedMessage id="web.settings.adminSettings.tracking.tabs.websiteTrackingDisabled" />
      </h3>
    </Card>
  ) : (
    <Card className="website-tracking-page">
      <h3>
        <FormattedMessage id="web.settings.adminSettings.tracking.websiteTracking.title" />
      </h3>
      <p>
        <FormattedMessage id="web.settings.adminSettings.tracking.websiteTracking.description" />
      </p>
      <p>
        <FormattedHTMLMessage id="web.settings.adminSettings.tracking.websiteTracking.visitTrackingInstruction" />
      </p>
      <TextBoxWithCopyToClipboard value={visitTrackingScript} contentEditable />
      <p>
        <FormattedMessage id="web.settings.adminSettings.tracking.websiteTracking.actionTrackingInstruction" />
      </p>
      <TextBoxWithCopyToClipboard
        value={actionTrackingScript}
        contentEditable
      />
      <p>
        <FormattedMessage id="web.settings.adminSettings.tracking.websiteTracking.recentEvents.description" />
      </p>
      <WebsiteTrackingEvents {...restProps} />
    </Card>
  );
};

WebsiteTrackingPage.propTypes = {
  siteHash: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default injectIntl(WebsiteTrackingPage);
