import React, { Component } from 'react';
import PropTypes from 'prop-types';

const safelyChangeRoute = (WrappedComponent) => {
  const componentName = WrappedComponent
    ? WrappedComponent.displayName || WrappedComponent.name || 'NoName'
    : 'SafelyChangeRouteDummy';

  return class SafelyChangeRoute extends Component {
    popstateEventHandler = () => {
      const {
        safelyChangeRoute: { onTryChangeRoute, isSafeToChangeRoute },
      } = this.props;

      const prevHref = this.currentHref;
      const prevHash = this.currentHash;

      this.currentHref = window.location.href;
      this.currentHash = window.location.hash;

      // workaround for Compose Slideout
      if (
        prevHash === '#compose_email' ||
        this.currentHash === '#compose_email' ||
        isSafeToChangeRoute
      ) {
        return;
      }

      if (prevHref !== this.currentHref) {
        onTryChangeRoute(this.currentHref);
        window.location.href = prevHref;
      }
    };

    componentDidMount() {
      this.currentHref = window.location.href;
      this.currentHash = window.location.hash;
      window.addEventListener('popstate', this.popstateEventHandler);
    }

    componentWillUnmount() {
      window.removeEventListener('popstate', this.popstateEventHandler);
    }

    render() {
      return WrappedComponent ? <WrappedComponent {...this.props} /> : null;
    }

    static propTypes = {
      safelyChangeRoute: PropTypes.shape({
        onTryChangeRoute: PropTypes.func.isRequired,
        isSafeToChangeRoute: PropTypes.bool.isRequired,
      }),
    };

    static defaultProps = {
      safelyChangeRoute: null,
    };

    static displayName = `safelyChangeRoute(${componentName})`;
  };
};

export const SafelyChangeRouteDummy = safelyChangeRoute(null);

export default safelyChangeRoute;
