import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isAdmin } from 'web/user/selectors/userSelectors';
import { getMasterTeam } from 'web/teams/selectors/teamsSelectors';
import {
  grantAdminRole,
  grantUserRole,
  openEditTeamsPopup,
  openAddTeamMembersToTeamsPopup,
  openDeleteTeamMembersConfirmationPopup,
  openDeleteTeamMemberConfirmationPopup,
} from '../actionCreators/teamMembersActionCreators';
import TeamMembers from '../components/teamMembers';
import {
  getSelectedTeam,
  getSelectedTeamId,
  getSelectedTeamAdminIds,
  getTeamsSelectOptions,
} from '../selectors/teamsSelector';
import {
  getSelectedTeamMembers,
  getFetching,
  getLoading,
  getUserColumnSettingsForTeamMembersTable,
} from '../selectors/teamMembersSelector';
import { changeColumnsSettings } from 'web/user/actionCreators/userActionCreators';
import PropTypes from 'prop-types';

class TeamMembersContainer extends Component {
  render() {
    return <TeamMembers {...this.props} />;
  }
}

TeamMembersContainer.propTypes = {
  columnSettings: PropTypes.arrayOf(PropTypes.object),
};

TeamMembersContainer.defaultProps = {
  columnSettings: [],
};

const mapStateToProps = (state) => ({
  columnSettings: getUserColumnSettingsForTeamMembersTable(state),
  currentUser: state.user,
  fetching: getFetching(state),
  isAdmin: isAdmin(state),
  loading: getLoading(state),
  masterTeam: getMasterTeam(state),
  selectedTeam: getSelectedTeam(state),
  selectedTeamAdminIds: getSelectedTeamAdminIds(state),
  selectedTeamId: getSelectedTeamId(state),
  teamMembers: getSelectedTeamMembers(state),
  teamsSelectOptions: getTeamsSelectOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        changeColumnsSettings,
        grantAdminRole,
        grantUserRole,
        openAddTeamMembersToTeamsPopup,
        openDeleteTeamMemberConfirmationPopup,
        openDeleteTeamMembersConfirmationPopup,
        openEditTeamsPopup,
      },
      dispatch
    ),
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMembersContainer);
