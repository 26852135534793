export const DYNAMIC_FIELDS = {
  BUSINESS_DAYS_FROM_NOW: '{{#_business_days_from_now}}',
  DAYS_FROM_NOW: '{{#_days_from_now}}',
  TODAY: '{{today}}',
  TOMORROW: '{{tomorrow}}',
};

export const DYNAMIC_FIELDS_REGEX = {
  DAYS_FROM_NOW_DYNAMIC_FIELD: /\{\{\#_business_days_from_now\}\}|\{\{\#_days_from_now\}\}/,
};
