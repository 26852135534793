import GmailIntegrationActionTypes from '../libs/gmailIntegrationActionTypes';

const disabledFlags = {
  disconnecting: false,
  fetching: false,
  savingChanges: false,
};
const initialState = {
  ...disabledFlags,
};

export const gmailIntegration = (state = initialState, payload = {}) => {
  const { type, ...data } = payload;

  switch (type) {
    case GmailIntegrationActionTypes.set:
      return {
        ...state,
        ...data,
        ...disabledFlags,
      };
    case GmailIntegrationActionTypes.error:
      return {
        ...state,
        ...disabledFlags,
      };
    case GmailIntegrationActionTypes.fetching:
      return {
        ...state,
        fetching: true,
      };
    case GmailIntegrationActionTypes.disconnecting:
      return {
        ...state,
        disconnecting: true,
      };
    case GmailIntegrationActionTypes.savingChanges:
      return {
        ...state,
        savingChanges: true,
      };

    case GmailIntegrationActionTypes.clearStateValues:
      return initialState;

    default:
      return state;
  }
};
