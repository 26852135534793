import queue from 'async/queue';
import { PusherEvents } from 'web/libs/constants';
import { updateEventsAnalytics } from 'web/liveFeed/actionCreators/liveFeedHeaderActionCreators';
import {
  updateMarketoEvents,
  updateNotifications,
  updateToutEvents,
} from './engageActionCreators';
import { getCustomChannel, getUserChannel } from 'web/services/pusherService';

const queuedNotificationsliveFeedCall = queue(({ data = [], dispatch }, cb) => {
  dispatch(updateNotifications(data));
  cb();
}, 100);

const queuedMarketoEventsliveFeedCall = queue(({ data = [], dispatch }, cb) => {
  dispatch(updateMarketoEvents(data));
  cb();
}, 100);

const queuedToutEventsliveFeedCall = queue(({ data = {}, dispatch }, cb) => {
  dispatch(updateToutEvents(data));
  dispatch(updateEventsAnalytics(data));
  cb();
}, 100);

export function initToutEventsPusher() {
  return (dispatch) => {
    getUserChannel().bind(PusherEvents.liveFeedEvents, (data) => {
      queuedToutEventsliveFeedCall.push({ data, dispatch });
    });
  };
}

export function initInterestingMomentsPusher() {
  return (dispatch) => {
    const pollingChannel = getCustomChannel('liveFeedChannel');

    pollingChannel.bind(PusherEvents.interestingMoments, (data) => {
      queuedMarketoEventsliveFeedCall.push({ data, dispatch });
    });
  };
}

// MT: This is for the dynamic chat activities pushed through
// Activity Pusher on the backend. This uses the same queue
// as the other Marketo activities such as Interesting Moments
// and Best bets, since they're all pushed from Activity Pusher
export function initDynamicChatActivitiesPusher() {
  return (dispatch) => {
    const pollingChannel = getCustomChannel('liveFeedChannel');

    pollingChannel.bind(PusherEvents.dynamicChatActivities, (data) => {
      queuedMarketoEventsliveFeedCall.push({ data, dispatch });
    });
  };
}

// MT: This is for the /api/notifications, a more generic way of pushing
// notifications to LiveFeed
export function initNotificationsPusher() {
  return (dispatch) => {
    const pollingChannel = getCustomChannel('liveFeedChannel');

    pollingChannel.bind(
      PusherEvents.notifications.dynamicChat.leadOpenedChat,
      (data) => {
        queuedNotificationsliveFeedCall.push({ data, dispatch });
      }
    );
  };
}
