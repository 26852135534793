import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import Cards, { Direction } from 'components/layouts/cards';
import isEqual from 'lodash/isEqual';
import InfoCard from '../../personDetails/infoCard';
import GroupsCard from '../../personDetails/groupsCard';
import CustomFieldsCard from '../../personDetails/customFieldsCard';

class FormView extends Component {
  componentDidMount() {
    this.props.actionCreators.openCardEditing(PersonDetailsCards.info);
  }

  componentDidUpdate(prevProps) {
    const { person } = this.props;
    const { person: prevPerson } = prevProps;
    if (
      this.shouldAssignPersonToCurrentGroup(person, prevPerson) &&
      !isEqual(person.addresses, prevPerson.addresses)
    ) {
      this.assignPersonToCurrentGroup();
    } else if (this.shouldDeletePersonFromGroups(person, prevPerson)) {
      this.removePersonFromGroups();
    }
  }

  shouldAssignPersonToCurrentGroup = (person, prevPerson) =>
    !Number.isNaN(+this.props.currentPeopleGroupId) &&
    person &&
    person.addresses &&
    person.addresses.length > 0 &&
    prevPerson &&
    prevPerson.addresses;

  shouldDeletePersonFromGroups = (person, prevPerson) =>
    prevPerson.addresses &&
    person.addresses &&
    prevPerson.addresses.length > 0 &&
    person.addresses.length === 0;

  assignPersonToCurrentGroup = () => {
    const { currentPeopleGroupName, actionCreators, person } = this.props;
    const currentPeopleGroupId = +this.props.currentPeopleGroupId;
    const firstEmailId = person.addresses[0].id;
    const personGroup = {
      address_id: firstEmailId,
      group_id: currentPeopleGroupId,
      group_name: currentPeopleGroupName,
      id: currentPeopleGroupId,
    };
    actionCreators.updatePersonGroups([personGroup]);
    actionCreators.setGroupEditState(firstEmailId, {
      label: currentPeopleGroupName,
      value: currentPeopleGroupId,
    });
  };

  removePersonFromGroups = () => {
    this.props.actionCreators.updateGroupsEditStateRemoveAll();
  };

  render() {
    return (
      <div className="person-details-about-view">
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={this._getCards()}
          direction={Direction.vertical}
          marginAround={FULL}
          marginBetween={FULL}
          maxWidth={0}
          minWidth={0}
        />
      </div>
    );
  }

  _getCards = () => {
    const {
      actionCreators,
      customFieldsEditState,
      customFieldsCardHasChanges,
      editing,
      groupsAddable,
      groupsCardHasChanges,
      groupsEditState,
      infoCardHasChanges,
      salesforceRequiredFields,
      infoEditState,
      person,
      user,
    } = this.props;

    return [
      {
        children: (
          <InfoCard
            actionCreators={actionCreators}
            infoCardHasChanges={infoCardHasChanges}
            infoEditState={infoEditState}
            isEditing={editing[PersonDetailsCards.info]}
            person={person}
            salesforceRequiredFields={salesforceRequiredFields}
            user={user}
            hiddenButtons
            isNewContact
          />
        ),
        id: PersonDetailsCards.info,
      },
      {
        children: (
          <GroupsCard
            actionCreators={actionCreators}
            groupsAddable={groupsAddable}
            groupsCardHasChanges={groupsCardHasChanges}
            groupsEditState={groupsEditState}
            isEditing={editing[PersonDetailsCards.groups]}
            person={person}
            hiddenButtons
            isNewContact
          />
        ),
        id: PersonDetailsCards.groups,
      },
      {
        children: (
          <CustomFieldsCard
            actionCreators={actionCreators}
            customFieldsCardHasChanges={customFieldsCardHasChanges}
            customFieldsEditState={customFieldsEditState}
            isEditing={editing[PersonDetailsCards.customFields]}
            person={person}
            hiddenButtons
          />
        ),
        id: PersonDetailsCards.customFields,
      },
    ];
  };
}

FormView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  currentPeopleGroupId: PropTypes.string.isRequired,
  currentPeopleGroupName: PropTypes.string.isRequired,
  customFieldsCardHasChanges: PropTypes.bool.isRequired,
  customFieldsEditState: PropTypes.array.isRequired,
  editing: PropTypes.object.isRequired,
  groupsAddable: PropTypes.array.isRequired,
  groupsCardHasChanges: PropTypes.bool.isRequired,
  groupsEditState: PropTypes.object.isRequired,
  infoCardHasChanges: PropTypes.bool.isRequired,
  infoEditState: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  salesforceRequiredFields: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default injectIntl(FormView);
