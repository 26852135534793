import {
  EmailComposePopupIds,
  ErrorMessagesTemplates,
} from '../libs/composeEmailPopupConstants';

export const getEmailFromResponse = (responseText = '') => {
  const regexp = new RegExp(/\\u003C([^\\003E].*)\\u003E/, 'i'); // Find email value between "<>", like "Your mail is <example@mail.com>"
  const emailMatch = responseText.match(regexp);

  if (emailMatch && emailMatch.length) {
    return emailMatch[1];
  }

  return null;
};

const isEmailVerificationRequired = (responseText = '') =>
  responseText.indexOf(ErrorMessagesTemplates.emailVerificationRequired) !== -1;

const isSmtpRequired = (responseText = '') =>
  responseText.indexOf(ErrorMessagesTemplates.smtpRequired) !== -1;

const isActionsRequired = (responseText = '') =>
  isEmailVerificationRequired(responseText) && isSmtpRequired(responseText);

export const getComposeEmailPopupId = (responseText = '') => {
  if (isActionsRequired(responseText)) {
    return EmailComposePopupIds.actionsRequired;
  }

  if (isEmailVerificationRequired(responseText)) {
    return EmailComposePopupIds.emailVerificationRequired;
  }

  if (isSmtpRequired(responseText)) {
    return EmailComposePopupIds.smtpRequired;
  }

  return null;
};
