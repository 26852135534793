import { baseGet, basePut, basePost, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { SMTP_DELIVERY_CHANNEL } from '../libs/deliveryChannelsConstants';

export const getDeliveryChannels = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.deliveryChannels(), null, resolve, reject);
  });

export const getDeliveryChannel = (id = SMTP_DELIVERY_CHANNEL) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.deliveryChannels(id), null, resolve, reject);
  });

export const createDeliveryChannel = (authentication) =>
  new Promise((resolve, reject) => {
    basePost(Urls.deliveryChannels(), authentication, resolve, reject);
  });

export const updateDeliveryChannel = (
  authentication,
  id = SMTP_DELIVERY_CHANNEL
) =>
  new Promise((resolve, reject) => {
    basePut(Urls.deliveryChannels(id), authentication, resolve, reject);
  });

export const deleteDeliveryChannel = (id = SMTP_DELIVERY_CHANNEL) =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.deliveryChannels(id), {}, resolve, reject);
  });
