import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import AdobeIcon from 'components/icons/adobeIcon';
import './logoAdobe.scss';

const LogoAdobe = (props) => {
  const { width, height, className, displayText } = props;
  const classNames = classnames('main-logo-adobe-container', className);

  return (
    <div className={classNames}>
      <div className="logo-wrapper">
        <AdobeIcon height={height} width={width} />
      </div>
      {displayText && (
        <div className="text-bold logo-text">
          <FormattedMessage id="common.marketoEngane" />
        </div>
      )}
    </div>
  );
};

LogoAdobe.propTypes = {
  className: PropTypes.string,
  displayText: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

LogoAdobe.defaultProps = {
  className: '',
  displayText: false,
  height: 30,
  width: 30,
};

export default LogoAdobe;
