import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { CampaignStepActions } from 'web/libs/constants';
import { getAbbr } from 'web/timezones/services/timezonesService';
import classNames from 'classnames';
import './campaignStepInfo.scss';
import I18N from 'languages';
import { getValidTime } from 'web/campaigns/campaignsTab/helpers/formatting';

class CampaignStepInfo extends Component {
  _getInfo = () => {
    const {
      intl: { formatMessage },
      step,
      timezones,
    } = this.props;

    switch (step.action) {
      case CampaignStepActions.email:
        if (step.action_params.time && step.action_params.timezone) {
          const abbr = getAbbr(step.action_params.timezone, timezones);
          const localizedTime = I18N.getLocalizedTime(
            getValidTime(step.action_params.time)
          );
          return formatMessage(
            { id: 'web.campaigns.scheduledAt' },
            { time: localizedTime, abbr }
          );
        } else {
          return formatMessage({ id: 'web.campaigns.automatic' });
        }
      case CampaignStepActions.emailTask:
        return formatMessage({ id: 'web.campaigns.manual' });
      default:
        return null;
    }
  };

  _getInfoIcon = () => {
    const {
      step: { action, threaded },
      intl: { formatMessage },
      rightIcon,
    } = this.props;

    if (
      (action === CampaignStepActions.email ||
        action === CampaignStepActions.emailTask) &&
      threaded
    ) {
      return (
        <span
          title={formatMessage({ id: 'web.campaigns.threadedEmail' })}
          className={classNames('tout-icon-threaded', {
            'right-icon': rightIcon,
            'margin-left-half': !rightIcon,
          })}
        />
      );
    }

    return null;
  };

  render() {
    const { className, rightIcon } = this.props;
    const info = this._getInfo();
    const infoIcon = this._getInfoIcon();

    if (rightIcon) {
      return (
        <div className={classNames('campaign-step-info', className)}>
          <div className="campaign-step-info-text">
            {info ? <h5 className="text-blue-light">{info}</h5> : null}
          </div>
          {infoIcon}
        </div>
      );
    } else {
      return (
        <div className="campaign-step-info">
          {info ? <h5 className="text-blue-light">{info}</h5> : null}
          {infoIcon || null}
        </div>
      );
    }
  }
}

CampaignStepInfo.propTypes = {
  timezones: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  className: PropTypes.string,
  rightIcon: PropTypes.bool,
  step: PropTypes.object,
};

CampaignStepInfo.defaultProps = {
  className: undefined,
  rightIcon: undefined,
  step: undefined,
};

export default injectIntl(CampaignStepInfo);
