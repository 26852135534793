import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './text.scss';

class Text extends Component {
  constructor() {
    super();
    this.inputRef = null;
  }

  render() {
    const {
      className,
      error,
      onChange: ignored,
      onFocus,
      setRef: ignored2,
      ...other
    } = this.props;
    const classes = classNames('input-text', className, { error });

    return (
      <input
        {...other}
        className={classes}
        onChange={this.onChange}
        onFocus={onFocus}
        ref={this._inputRef}
      />
    );
  }

  _inputRef = (input) => {
    this.inputRef = input;
    if (this.props.setRef) {
      this.props.setRef(input);
    }
  };

  onChange = (e) => {
    const elem = e.target;
    let { index } = elem.dataset;
    if (index) {
      index = Number.parseInt(index, 10);
    }
    this.props.onChange(elem.value || '', index);
  };
}

Text.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
};

Text.defaultProps = {
  autoFocus: true,
  className: '',
  error: false,
  onChange: () => {},
  onFocus: () => {},
  type: 'text',
};

export default Text;
