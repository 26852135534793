import SetPasswordActionTypes from '../libs/setPasswordActionTypes';

export const setPasswordIsPasswordChanged = (state = false, { type }) => {
  switch (type) {
    case SetPasswordActionTypes.initialize:
      return false;
    case SetPasswordActionTypes.passwordChanged:
      return true;
    default:
      return state;
  }
};

export const setPasswordRequestLoading = (
  state = false,
  { type, loading = false }
) => {
  switch (type) {
    case SetPasswordActionTypes.setLoading:
      return loading;
    default:
      return state;
  }
};

export const setPasswordAlertTextId = (state = null, { type, alertId }) => {
  switch (type) {
    case SetPasswordActionTypes.showAlert:
      return alertId;
    case SetPasswordActionTypes.clearAlert:
      return null;
    default:
      return state;
  }
};
