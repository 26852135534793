import {
  AuthorizationTypes,
  SourceTypes,
} from 'web/compliance/libs/complianceConstants';

export const isOtherSourceType = (source) => source === SourceTypes.other;

export const isCustomSourceType = (source) =>
  !Object.values(SourceTypes).includes(source);

export const isOtherAuthorizationType = (authorization) =>
  authorization === AuthorizationTypes.other;

export const isConsentAuthorizationType = (authorization) =>
  authorization === AuthorizationTypes.consent;

export const isCustomAuthorizationType = (authorization) =>
  !Object.values(AuthorizationTypes).includes(authorization);
