import { Regex } from 'web/libs/constants';
import uniqWith from 'lodash/uniqWith';
import {
  AddressTypes,
  PersonDetailsCards,
  PersonDetailsTabAlertIds,
  SalesforceTypes,
  ApiKeys,
} from '../libs/personDetailsConstants';
import { getSocialMediaUrl } from './personSocialMediaHelpers';

export const isPhonesUnique = ({ addresses = [] }, value) =>
  uniqWith(
    addresses,
    (address1, address2) =>
      address1.value.trim() === address2.value.trim() &&
      address1.value.trim() === value.trim()
  ).length === addresses.length;

export const getInvalidAddressAlert = (type, value = '', phoneDetails = '') => {
  if (value.length === 0) {
    return null;
  }

  switch (type) {
    case AddressTypes.email:
      return Regex.email.test(value)
        ? null
        : [PersonDetailsTabAlertIds.invalidEmail, { value }];
    case AddressTypes.phone:
      if (phoneDetails && !isPhonesUnique(phoneDetails, value)) {
        return [PersonDetailsTabAlertIds.uniquePhone, { value }];
      }
      return Regex.phone.test(value)
        ? null
        : [PersonDetailsTabAlertIds.invalidPhone, { value }];
    case AddressTypes.website:
      return Regex.website.test(value)
        ? null
        : [PersonDetailsTabAlertIds.invalidWebsite, { value }];
    case AddressTypes.facebook:
    case AddressTypes.linkedin:
    case AddressTypes.twitter: {
      if (getSocialMediaUrl(type, value)) {
        return null;
      } else {
        return [PersonDetailsTabAlertIds.invalidUsername, { value }];
      }
    }
    case AddressTypes.other:
    default:
      return null;
  }
};

export const getInvalidApiKeysAlert = (type, value = '') => {
  const salesForceMinCharLength = 15;
  const salesForceMaxCharLength = 18;
  if (value.length === 0) {
    return null;
  }

  switch (type) {
    case ApiKeys.marketo:
      return Regex.numbers.test(value)
        ? null
        : [PersonDetailsTabAlertIds.invalidMarketoId, { value }];
    case ApiKeys.salesforce:
      if (Regex.specialChars.test(value)) {
        return [PersonDetailsTabAlertIds.specialCharsInSalesforce, { value }];
      }
      return Regex.alphabetize.test(value) &&
        (value.length === salesForceMinCharLength ||
          value.length === salesForceMaxCharLength)
        ? null
        : [PersonDetailsTabAlertIds.invalidSalesforceId, { value }];
    default:
      return null;
  }
};

export function pdvValidation(person) {
  const alertMessages = [];
  const types = [AddressTypes.email, AddressTypes.website];
  types.forEach((type) => {
    const data = person.addresses || person[type].addresses;
    const { externalReferences } = person;

    externalReferences.forEach((reference) => {
      const { service_entity_id: serviceEntityId, service } = reference;
      const invalid = getInvalidApiKeysAlert(service, serviceEntityId);
      if (invalid && invalid.length > 0) {
        alertMessages.push(invalid);
      }
    });

    data.forEach((email) => {
      const value = email.value || email.address;
      const fieldType = email.type || email.address_type;
      const invalid = getInvalidAddressAlert(fieldType, value);
      if (invalid && invalid.length > 0) {
        alertMessages.push(invalid);
      }
    });
  });
  const alertMessage =
    alertMessages.length > 0 ? alertMessages.pop() : alertMessages;
  return alertMessage;
}

const hasNameRepeats = (cardState, name) =>
  cardState.reduce(
    (accumulator, next) => (next.name === name ? accumulator + 1 : accumulator),
    0
  ) > 1;

export const getInvalidCustomFieldAlert = (
  name = '',
  value = '',
  cardState = []
) => {
  if (!name && !value) {
    return null;
  } else if (!name.trim()) {
    return [PersonDetailsTabAlertIds.invalidCustomFieldNoName];
  } else if (!name.match(Regex.customField)) {
    return [PersonDetailsTabAlertIds.invalidCustomFieldBadName, { name }];
  } else if (hasNameRepeats(cardState, name)) {
    return [PersonDetailsTabAlertIds.invalidCustomFieldSameName, { name }];
  } else {
    return null;
  }
};

const noAddress = ({ addresses = [] } = {}) =>
  addresses.every((address) => address.value.length === 0);

export const isMissingRequiredFields = (cardId, state = {}) => {
  if (cardId === PersonDetailsCards.info) {
    const { email = {} } = state;
    return noAddress(email);
  }
  return false;
};

export const getMissingSalesforceFields = (
  companyName,
  lastName,
  salesforceType
) => {
  const requiredFields = {};

  if (salesforceType) {
    if (!lastName) {
      requiredFields.lastName = true;
    }
    if (salesforceType === SalesforceTypes.lead && !companyName) {
      requiredFields.company = true;
    }
  }

  return requiredFields;
};

export const getMissingSalesforceFieldsFromEditState = (state = {}) => {
  const {
    personDetailsAccountInfoEditState: { salesforceType },
    personDetailsInfoEditState: { companyName = '', lastName = '' },
  } = state;

  return getMissingSalesforceFields(companyName, lastName, salesforceType);
};
