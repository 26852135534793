import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import outsideClickHandler from 'components/hocs/outsideClickHandler';
import InfoIcon from 'components/icons/infoIcon';
import AlertTriangleFilled from 'components/icons/alertTriangleFilled';
import CloseIcon from 'components/icons/closeIcon';
import Success from 'components/icons/success';
import AlertCircleFilled from 'components/icons/alertCircleFilled';
import './alert.scss';

class Alert extends PureComponent {
  constructor(props) {
    super(props);
    const { manual, delay, onClose, outsideClickDisabled } = props;
    this.state = {
      timeout: !manual ? setTimeout(onClose, delay) : null,
    };
    this.onOutsideClick = outsideClickDisabled ? null : onClose;
  }

  componentWillUnmount() {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  }

  render() {
    const {
      action,
      actionLabel,
      actionLabelText,
      actionLabelValues,
      classes,
      className,
      color,
      html,
      location,
      onClose,
      text,
      textId,
      textValues,
      type,
    } = this.props;

    const alertClasses = classNames('tout-alert', classes, className, {
      [`type-${type}`]: type,
      'location-bottom': location === AlertLocations.bottom,
      [`background-${color}`]: color,
    });

    const getIconForColor = (color) => {
      switch (color) {
        case AlertColors.blueDark:
          return <InfoIcon />;
        case AlertColors.redDark:
          return <AlertTriangleFilled />;
        case AlertColors.greenDark:
          return <Success />;
        case AlertColors.yellow:
          return <AlertCircleFilled />;
        default:
          return null;
      }
    };

    return (
      <div className={alertClasses}>
        <i className="icon-alert-body">{getIconForColor(color)}</i>
        <div className="tout-alert-body">
          {html ? (
            <FormattedHTMLMessage id={textId} values={textValues} />
          ) : (
            text || <FormattedMessage id={textId} values={textValues} />
          )}
          {action &&
            (actionLabelText || actionLabel) && (
              <a
                className="margin-left-half"
                href="javascript:void(0)" //eslint-disable-line no-script-url
                onClick={action}
              >
                {actionLabelText || (
                  <FormattedMessage
                    id={actionLabel}
                    values={actionLabelValues}
                  />
                )}
              </a>
            )}
        </div>
        <i className="close-icon">
          <CloseIcon onClick={onClose} />
        </i>
      </div>
    );
  }
}

export const AlertIconTypes = {
  tick: 'tick',
  warning: 'warning',
};

export const AlertLocations = {
  bottom: 'bottom',
  top: 'top',
};

Alert.propTypes = {
  action: PropTypes.func,
  actionLabel: PropTypes.string,
  actionLabelText: PropTypes.string,
  actionLabelValues: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.string, // legacy
  color: PropTypes.oneOf(Object.values(BackgroundColors)),
  delay: PropTypes.number,
  disableClick: PropTypes.bool, //eslint-disable-line react/no-unused-prop-types
  html: PropTypes.bool,
  location: PropTypes.string,
  manual: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  outsideClickDisabled: PropTypes.bool,
  text: PropTypes.string,
  textId: PropTypes.string.isRequired,
  textValues: PropTypes.object,
  type: PropTypes.string, // legacy
};

Alert.defaultProps = {
  action: undefined,
  actionLabel: '',
  actionLabelText: '',
  actionLabelValues: {},
  className: '',
  classes: '',
  color: 'green',
  delay: 8000,
  disableClick: true,
  html: false,
  location: '',
  manual: false,
  outsideClickDisabled: false,
  text: '',
  textValues: {},
  type: '',
};

export const AlertColors = BackgroundColors;
export default outsideClickHandler(Alert);

export let UnwrappedAlert; //eslint-disable-line import/no-mutable-exports
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedAlert = Alert;
}
