import React from 'react';
import PropTypes from 'prop-types';
import './peopleImportStepper.scss';
import classNames from 'classnames';
import { stepLabels } from './const';

const PeopleImportStepper = ({ activeStep }) => (
  <div className="step-container">
    <ul className="progressbar">
      {stepLabels.map((label, index) => {
        const stepIndex = `label-index-${index}`;
        const nextIndex = index + 1;

        return (
          <li
            key={stepIndex}
            className={classNames({
              active:
                activeStep === nextIndex && stepLabels.length !== activeStep,
              passed:
                activeStep > nextIndex || stepLabels.length === activeStep,
              unpassed: activeStep < nextIndex,
            })}
          >
            {label}
          </li>
        );
      })}
    </ul>
  </div>
);

PeopleImportStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

export default PeopleImportStepper;
