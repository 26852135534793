import {
  TeamManagementDuplicateValueValidationString,
  TeamManagementNameDuplicateErrorTextId,
  TeamManagementCommonErrorTextId,
} from '../libs/teamManagementConstants';

export const getTeamChangeErrorTextId = (errors = {}) => {
  if (
    errors.name &&
    errors.name.includes(TeamManagementDuplicateValueValidationString)
  ) {
    return TeamManagementNameDuplicateErrorTextId;
  }
  return TeamManagementCommonErrorTextId;
};
