import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomDomain from '../components/customDomain';
import {
  getCustomDomain,
  isCustomDomainPageLoading,
} from '../selectors/trackingSelectors';
import {
  saveCustomDomain,
  openDeleteCustomDomainConfirmation,
} from '../actionCreators/trackingActionCreators';
import { isPristine } from 'redux-form';
import { TRACKING_CUSTOM_DOMAIN_FORM_ID } from '../libs/trackingConstants';

const CustomDomainContainer = (props) => <CustomDomain {...props} />;

const mapStateToProps = (state) => ({
  customDomain: getCustomDomain(state),
  loading: isCustomDomainPageLoading(state),
  isSafeToChangeRoute: isPristine(TRACKING_CUSTOM_DOMAIN_FORM_ID)(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: bindActionCreators(
    {
      saveCustomDomain,
      openDeleteCustomDomainConfirmation,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDomainContainer);
