import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { ModalPopupIds } from 'web/libs/constants';
import apiHelper from 'web/services/apiHelper';
import {
  closePopup,
  openPopup,
} from 'web/popup/actionCreators/popupActionCreators';

function initEditState(data) {
  return {
    type: ActionTypes.imageInsert.state.initEditState,
    data: {
      height: `${data.height || ''}`,
      link: data.link || '',
      name: data.name || '',
      width: `${data.width || ''}`,
      url: data.url || '',
    },
  };
}

export function imageInsertStartup(name = '', data) {
  return (dispatch) => {
    if (document.querySelector('meta[name="csrf-token"]')) {
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');
      if (csrfToken) {
        apiHelper.setCsrfToken(csrfToken);
      }
    }
    dispatch(closePopup()); // reset any previous states

    dispatch({ type: ActionTypes.imageInsert.state.editorName, data: name });

    if (data) {
      dispatch(initEditState(data));
    }

    dispatch(openPopup(ModalPopupIds.imageInsert));
  };
}
