import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './formError.scss';

const FormError = ({ children, className }) => (
  <div className={classNames('form-error', className)}>{children}</div>
);

FormError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

FormError.defaultProps = {
  children: null,
  className: '',
};

export default FormError;
