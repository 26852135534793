import { SourceProperties, TaskProperties } from 'web/libs/mixpanelEvents';

export function track(event, properties = {}) {
  if (window.toutUserId && window.toutSubscriptionId) {
    properties.user_id = window.toutUserId;
    properties.subscription_id = window.toutSubscriptionId;
  }

  if (window.mixpanel && window.mixpanel.track) {
    window.mixpanel.track(event, properties);
  }
}

const urlToSource = {
  '#analytics': SourceProperties.analytics,
  '#campaign_categories': SourceProperties.campaigns,
  '#emails': SourceProperties.sentFolder,
  '#emails/command_center': SourceProperties.commandCenter,
  '#emails/command_center_old': SourceProperties.commandCenter, //temp until fully removed
  '#live': SourceProperties.liveFeed,
  '#relationships': SourceProperties.peopleTab,
  '#salesbeat': SourceProperties.salesBeat,
  '#templates': SourceProperties.templates,
};

const getSourceOpener = (sourceOpener) => SourceProperties[sourceOpener] || '';

const getSourceFromHash = () => {
  const hash = window.location.hash;
  if (
    hash === '#emails/command_center' ||
    hash === '#emails/command_center_old'
  ) {
    // emails and commander center have same hash but we want to track them separately
    return urlToSource[hash];
  }
  return urlToSource[hash.split('/')[0]] || 'Unknown Source';
};

export function getSource(sourceOpener) {
  if (sourceOpener) {
    return getSourceOpener(sourceOpener);
  }
  return getSourceFromHash();
}

export function getTaskMode() {
  if (window.location.hash.split('/')[0] === '#compose_email') {
    return TaskProperties.mode.compose;
  } else {
    return TaskProperties.mode.widget;
  }
}
