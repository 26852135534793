import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  AddressLocations,
  AddressTypes,
} from 'web/person/libs/personDetailsConstants';
import Radio from 'components/buttons/radio';
import Text from 'components/inputs/text';
import Select from 'components/selects/default';
import Icon from 'components/buttons/icon';
import './infoCardEditingTableRow.scss';

class InfoCardEditingTableRow extends PureComponent {
  constructor(props) {
    super(props);
    const { formatMessage } = props.intl;
    this.locationItems = [
      {
        id: AddressLocations.work,
        name: formatMessage({ id: 'common.work' }),
      },
      {
        id: AddressLocations.personal,
        name: formatMessage({ id: 'common.personal' }),
      },
      {
        id: AddressLocations.mobile,
        name: formatMessage({ id: 'common.mobile' }),
      },
      {
        id: AddressLocations.other,
        name: formatMessage({ id: 'common.other' }),
      },
    ];
    this.removeLabel = formatMessage({ id: 'common.remove' });
  }

  render() {
    const { id, index, isPrimary, last, location, value, type } = this.props;

    return (
      <div className="person-details-info-card-editing-table-row">
        <div className="info-editing-table-cell info-editing-table-cell-primary">
          <Radio
            checked={isPrimary}
            className=""
            name={`info-editing-${id || index}`}
            onChange={this.onPrimaryChange}
            value={id}
            disabled={!value}
          />
        </div>
        <Text
          autoFocus={false}
          className="info-editing-table-cell info-editing-table-cell-value"
          id={`info-address-${index}`}
          onMouseOut={this.onMouseOut}
          onBlur={this.onMouseOut}
          onChange={this.onValueChange}
          placeholder=""
          type={this.fieldType(type)}
          value={value}
        />
        <Select
          className="info-editing-table-cell-location"
          classNameWrapper="info-editing-table-cell info-editing-table-cell-location-wrapper"
          items={this.locationItems}
          onChange={this.onLocationChange}
          showAbove={last}
          selected={location}
        />
        {!last && (
          <Icon
            className="info-editing-table-cell-remove tout-icon-close"
            title={this.removeLabel}
            onClick={this.remove}
          />
        )}
      </div>
    );
  }

  onPrimaryChange = () => {
    const { index, isPrimary, type, setPrimary } = this.props;

    if (!isPrimary) {
      setPrimary(index, type);
    }
  };

  fieldType = (type) => {
    switch (type) {
      case AddressTypes.email:
        return AddressTypes.email;
      case AddressTypes.website:
        return AddressTypes.url;
      default:
        return AddressTypes.text;
    }
  };

  onValueChange = (value) => {
    const {
      add,
      last,
      index,
      type,
      updateAddresses,
      value: originalValue,
    } = this.props;
    updateAddresses({ value }, type, index);

    if (last && !originalValue.length) {
      add(type);
    }
  };

  onLocationChange = (value) => {
    const { index, type, updateAddresses } = this.props;
    updateAddresses({ location: value }, type, index);
  };

  remove = () => {
    const { index, last, remove, type, updateAddresses } = this.props;

    if (last) {
      updateAddresses(
        { value: '', location: AddressLocations.work },
        type,
        index
      );
    } else {
      remove(type, index);
    }
  };

  onMouseOut = () => {
    const { type, validateAddress, value, phoneDetails } = this.props;
    validateAddress(type, value, phoneDetails);
  };
}

InfoCardEditingTableRow.propTypes = {
  add: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  isPrimary: PropTypes.bool,
  last: PropTypes.bool,
  location: PropTypes.string,
  phoneDetails: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  setPrimary: PropTypes.func.isRequired,
  type: PropTypes.string,
  updateAddresses: PropTypes.func.isRequired,
  validateAddress: PropTypes.func.isRequired,
  value: PropTypes.string,
};

InfoCardEditingTableRow.defaultProps = {
  value: '',
  id: '',
  isPrimary: false,
  last: false,
  location: '',
  type: '',
};

export default injectIntl(InfoCardEditingTableRow);
