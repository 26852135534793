import TextCell from 'table/modules/cells/textCell';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import { getUnsubscribeTypeString } from 'web/person/helpers/personHelpers';

export default (sortColumn, sortColumnOpposite, formatMessage) => [
  {
    id: 'first-name',
    name: formatMessage({ id: 'common.firstName' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'first_name',
        wrapLines: 2,
      },
    },
    flex: true,
    width: 175,
    sorting: sortColumn.bind(this, 'first_name.sortable'),
  },
  {
    id: 'last-name',
    name: formatMessage({ id: 'common.lastName' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'last_name',
        wrapLines: 2,
      },
    },
    flex: true,
    width: 175,
    sorting: sortColumn.bind(this, 'last_name.sortable'),
  },
  {
    id: 'email',
    name: formatMessage({ id: 'common.email' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'unsubscribe_email',
        wrapLines: 2,
      },
    },
    flex: true,
    initHide: false,
    width: 100,
  },
  {
    id: 'title',
    name: formatMessage({ id: 'common.title' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'title',
        wrapLines: 2,
      },
    },
    flex: true,
    sorting: sortColumn.bind(this, 'title.sortable'),
    initHide: false,
    width: 100,
  },
  {
    id: 'company-name',
    name: formatMessage({ id: 'common.company' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'company_name',
        wrapLines: 2,
      },
    },
    sorting: sortColumn.bind(this, 'company.sortable'),
    flex: true,
    initHide: false,
    width: 100,
  },
  {
    id: 'unsubscribe-date',
    name: formatMessage({ id: 'web.person.unsubscribeDate' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'unsubscribe_date',
        wrapLines: 2,
      },
    },
    flex: true,
    initHide: false,
    width: 100,
  },
  {
    id: 'unsubscriber-name',
    name: formatMessage({ id: 'web.person.unsubscriber' }),
    rowCell: {
      component: TextCustomCell,
      metadata: {
        formatText: ({
          unsubscribe_details: { unsubscriber_name: unsubscriberName } = {},
        }) => unsubscriberName,
        wrapLines: 2,
      },
    },
    flex: true,
    initHide: false,
    width: 100,
  },
  {
    id: 'unsubscribe-reason',
    name: formatMessage({ id: 'web.person.reason' }),
    rowCell: {
      component: TextCell,
      metadata: {
        property: 'reason',
        wrapLines: 2,
      },
    },
    flex: true,
    initHide: false,
    width: 100,
  },
  {
    id: 'unsubscribe-type',
    name: formatMessage({ id: 'common.source' }),
    rowCell: {
      component: TextCustomCell,
      metadata: {
        formatText: ({ unsubscribe_details: { block_way: blockWay } = {} }) =>
          getUnsubscribeTypeString(blockWay),
        wrapLines: 2,
      },
    },
    flex: true,
    initHide: false,
    width: 100,
  },
];
