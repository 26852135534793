import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';

export const initState = {
  campaignTemplates: [],
  id: 0,
  loading: false,
};

export function campaignsUsingTemplateState(state = initState, action) {
  switch (action.type) {
    case ActionTypes.campaignsUsingTemplate.state.update:
      return { ...state, ...action.data };
    case ActionTypes.campaignsUsingTemplate.state.loading:
      return { ...state, loading: action.data };
    case PopupActionTypes.close:
      return initState;
    default:
      return state;
  }
}
