import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header';
import PageTabNavBar from 'components/layouts/page/tabNavBar';
import PageTabNav from 'components/layouts/page/tabNav';
import PageView from 'components/layouts/page/view';
import { BackgroundColors } from 'libs/constantsStyles';
import { TeamManagementTabs } from '../../libs/teamManagementConstants';
import TeamMembersContainer from '../../containers/teamMembersContainer';

class TeamManagementPage extends Component {
  constructor(props) {
    super(props);

    this.tabList = [
      {
        title: props.intl.formatMessage({
          id: 'web.settings.adminSettings.teamManagement.tabs.teamMembers',
        }),
        value: TeamManagementTabs.teamMembers,
      },
    ];
  }

  render() {
    const { onTabSelect, activeTab } = this.props;

    return (
      <Page className="team-management-page">
        <PageHeader
          buttons={this.getHeaderButtons()}
          textId="web.settings.adminSettings.teamManagement.title"
        />
        <PageTabNavBar border>
          <PageTabNav
            list={this.tabList}
            onTabSelect={onTabSelect}
            selectedTabValue={activeTab}
          />
        </PageTabNavBar>
        <PageView backgroundColor={BackgroundColors.grayExtraLight}>
          {this.getTabPageView()}
        </PageView>
      </Page>
    );
  }

  getTabPageView = () => {
    const { activeTab } = this.props;

    switch (activeTab) {
      case TeamManagementTabs.teamMembers:
        return <TeamMembersContainer />;
      default:
        return null;
    }
  };

  getHeaderButtons = () => {
    const { isAddTeamMembersToTeamEnabled } = this.props;
    return isAddTeamMembersToTeamEnabled
      ? [
          {
            onClick: this.onOpenAddTeamMembersToTeamPopup,
            textId:
              'web.settings.adminSettings.teamManagement.teamMembers.addToTeam',
          },
        ]
      : undefined;
  };

  onOpenAddTeamMembersToTeamPopup = () => {
    const {
      actionCreators: { openAddTeamMembersToTeamPopup },
      selectedTeam,
    } = this.props;
    openAddTeamMembersToTeamPopup(selectedTeam.id);
  };
}

TeamManagementPage.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isAddTeamMembersToTeamEnabled: PropTypes.bool.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  selectedTeam: PropTypes.object,
};

TeamManagementPage.defaultProps = {
  selectedTeam: null,
};

export default injectIntl(TeamManagementPage);
