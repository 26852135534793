import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import Popup from 'components/popups/popup';
import { PopupSize } from 'libs/constantsShared';
import { getEmailFromResponse } from '../../helpers/composeEmailHelpers';

class VerifiedSmtpNotVerifiedEmail extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      options: {
        content: { responseText },
      },
    } = this.props;

    return (
      <Popup
        className="compose-email-popup"
        footer={{
          onPrimary: closePopup,
          primaryTextId: 'common.ok',
        }}
        header={{
          onClose: closePopup,
          textId: 'web.composeEmail.popup.verifiedSmtpNotVerifiedEmail.header',
        }}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedHTMLMessage
          id="web.composeEmail.popup.verifiedSmtpNotVerifiedEmail.body"
          values={{ email: getEmailFromResponse(responseText) }}
        />
      </Popup>
    );
  }
}

VerifiedSmtpNotVerifiedEmail.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(VerifiedSmtpNotVerifiedEmail);
