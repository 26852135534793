import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './composeTemplatesCategoryCard.scss';
import { ComposeTemplatesConstants } from 'web/composeWindow/libs/composeTemplatesConstants';
import { injectIntl, intlShape } from 'react-intl';
import Icon, { IconColors } from 'components/buttons/icon';
import { localizedCategory } from 'web/composeWindow/helpers/composeTemplatesHelpers';

//boilerplate code for drag and drop setup
const cardSource = {
  canDrag(props) {
    return props.isReady;
  },

  isDragging(props, monitor) {
    return monitor.getItem().id === props.id;
  },

  // eslint-disable-next-line no-unused-vars
  beginDrag(props, monitor, component) {
    const item = { id: props.id, originalIndex: props.index };
    return item;
  },

  // eslint-disable-next-line no-unused-vars
  endDrag(props, monitor, component) {
    const { moveCard } = props;
    if (!monitor.didDrop()) moveCard(props, props.originalIndex);
  },
};

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const cardTarget = {
  canDrop(props) {
    return props.isReady;
  },
  drop(props) {
    return props;
  },
  // eslint-disable-next-line no-unused-vars
  hover(props, monitor, component) {
    const source = monitor.getItem();
    const { moveCard, id, index } = props;
    if (id !== source.id) moveCard(source, index);
  },
};

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

function TemplateCategoryCard(props) {
  const {
    id,
    category,
    isDragging,
    connectDragSource,
    connectDropTarget,
    isOver,
    removePinned,
    isReady,
    intl: { formatMessage },
  } = props;

  const cardClassNames = classNames('compose-template-category-card', {
    'category-card-over': isOver && isReady,
    'category-card-dragging': isDragging,
    'category-card-disabled': !isReady,
  });

  return connectDropTarget(
    connectDragSource(
      <div className={cardClassNames}>
        <div className="text-overflow">{localizedCategory(category)}</div>
        <Icon
          onClick={() => removePinned(id)}
          className="tout-icon-close"
          color={IconColors.darkGray}
        />
      </div>
    )
  );
}

TemplateCategoryCard.propTypes = {
  id: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  removePinned: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(
  DropTarget(
    ComposeTemplatesConstants.categoryCardType,
    cardTarget,
    collectTarget
  )(
    DragSource(
      ComposeTemplatesConstants.categoryCardType,
      cardSource,
      collectSource
    )(TemplateCategoryCard)
  )
);
