import { combineReducers } from 'redux';
import merge from 'lodash/merge';
import * as columnFilter from 'table/modules/columnFilter/columnFilterReducers';
import * as localStorage from 'table/modules/localStorage/localStorageReducers';
import * as pagination from 'table/modules/pagination/paginationReducers';
import * as search from 'table/modules/search/searchReducers';
import * as selectable from 'table/modules/selectable/selectableReducers';
import * as smartFilter from 'table/modules/smartFilter/smartFilterReducers';
import * as sorting from 'table/modules/sorting/sortingReducers';
import * as tableContainer from 'table/modules/tableContainer/tableContainerReducers';
import * as columnOrder from 'table/modules/headerRow/headerColumn/columnOrderReducers';

export default combineReducers(
  merge(
    {},
    columnFilter,
    columnOrder,
    localStorage,
    pagination,
    search,
    selectable,
    smartFilter,
    sorting,
    tableContainer
  )
);
