/*eslint complexity:0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { PopupSize } from 'libs/constantsShared';
import {
  TasksTableAlertIds,
  TasksTablePopupIds,
} from '../libs/tablesTasksConstants';
import Popup, { PopupButtonColors } from 'components/popups';
import { AlertColors } from 'components/alert';
import {
  tasksBulkDelete,
  tasksBulkDone,
  tasksBulkSkip,
  tasksBulkSuccess,
} from '../actionCreators/tablesTasksBulkActionCreators';
import {
  clearPopupAlert,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';

const EMPTY_POPUP = {};

class TasksTablePopupContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    this.actionCreators = {
      clearAlert: () => dispatch(clearPopupAlert()),
      closePopup: () => dispatch(closePopup()),
      tasksBulkDelete: (id, ids, callback) =>
        dispatch(tasksBulkDelete(id, ids, callback)),
      tasksBulkDone: (id, ids, callback) =>
        dispatch(tasksBulkDone(id, ids, callback)),
      tasksBulkSkip: (id, ids, callback) =>
        dispatch(tasksBulkSkip(id, ids, callback)),
      tasksBulkSuccess: (id, ids, callback) =>
        dispatch(tasksBulkSuccess(id, ids, callback)),
    };
  }

  render() {
    const {
      popup,
      popupData: { tableId: popupTableId },
      tableId,
    } = this.props;
    let object = EMPTY_POPUP;
    if (popup && popupTableId === tableId) {
      object = this._getGlobalPopupObject();
    }

    /* className used for exceptionSelectors for outside clicks in parent popup */
    return (
      <div className="tasks-table-popup-container">
        {object.children ? <Popup {...object} /> : null}
      </div>
    );
  }

  _getGlobalPopupObject() {
    const { loading } = this.props;
    return {
      loading,
      getImageUrl,
      ...this._getMainComponent(),
      alert: this._getAlert(),
    };
  }

  _getMainComponent() {
    const { loading, popup, tableId } = this.props;

    switch (popup) {
      case TasksTablePopupIds.tasksConfirmationDelete: {
        const {
          popupData: { ids, campaignTasksCount, callback },
        } = this.props;
        if (ids.length > 0) {
          return {
            children: (
              <FormattedHTMLMessage
                id="web.campaigns.tasksConfirmationDelete"
                values={{
                  count: ids.length || 1,
                  campaignCount: campaignTasksCount,
                }}
              />
            ),
            footer: {
              onPrimary: this.actionCreators.tasksBulkDelete.bind(
                this,
                tableId,
                ids,
                callback
              ),
              onSecondary: this.actionCreators.closePopup,
              primaryColor: PopupButtonColors.red,
              primaryTextId: 'common.delete',
              secondaryTextId: 'common.cancel',
            },
            header: {
              onClose: this.actionCreators.closePopup,
              textId: 'web.campaigns.tasksConfirmationDeleteTitle',
            },
            loading,
            onOutsideClick: this.actionCreators.closePopup,
            size: PopupSize.small,
          };
        } else {
          return {
            children: (
              <FormattedMessage id="web.campaigns.tasksConfirmationDeleteNone" />
            ),
            footer: {
              onPrimary: this.actionCreators.closePopup,
              primaryTextId: 'common.ok',
            },
            header: {
              onClose: this.actionCreators.closePopup,
              textId: 'web.campaigns.tasksConfirmationDeleteTitle',
            },
            onOutsideClick: this.actionCreators.closePopup,
            size: PopupSize.small,
          };
        }
      }
      case TasksTablePopupIds.tasksConfirmationDone: {
        const {
          popupData: { ids, callback },
        } = this.props;
        return {
          children: (
            <FormattedMessage
              id="web.campaigns.tasksConfirmationDone"
              values={{ count: ids.length || 1 }}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.tasksBulkDone.bind(
              this,
              tableId,
              ids,
              callback
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'web.campaigns.tasksConfirmationMarkDone',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.tasksConfirmationDoneTitle',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TasksTablePopupIds.tasksConfirmationSkip: {
        const {
          popupData: { ids, callback },
        } = this.props;
        return {
          children: (
            <FormattedMessage
              id="web.campaigns.tasksConfirmationSkip"
              values={{ count: ids.length || 1 }}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.tasksBulkSkip.bind(
              this,
              tableId,
              ids,
              callback
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.skip',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.tasksConfirmationSkipTitle',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TasksTablePopupIds.tasksConfirmationSuccess: {
        const {
          popupData: { ids, callback },
        } = this.props;
        return {
          children: (
            <FormattedMessage
              id="web.campaigns.tasksConfirmationSuccess"
              values={{ count: ids.length || 1 }}
            />
          ),
          footer: {
            onPrimary: this.actionCreators.tasksBulkSuccess.bind(
              this,
              tableId,
              ids,
              callback
            ),
            onSecondary: this.actionCreators.closePopup,
            primaryTextId: 'common.markSuccess',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: this.actionCreators.closePopup,
            textId: 'web.campaigns.tasksConfirmationSuccessTitle',
          },
          loading,
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case TasksTablePopupIds.campaignTaskUndone:
        return {
          children: <FormattedMessage id="tasks.campaignTaskUndone.body" />,
          footer: {
            onPrimary: this.actionCreators.closePopup,
            primaryTextId: 'common.ok',
          },
          header: {
            textId: 'tasks.campaignTaskUndone.header',
          },
          onOutsideClick: this.actionCreators.closePopup,
          size: PopupSize.small,
        };
      default:
        return EMPTY_POPUP;
    }
  }

  _getAlert = () => {
    const { alert } = this.props;

    switch (alert) {
      case TasksTableAlertIds.bulkFail:
        return {
          color: AlertColors.red,
          onClose: this.actionCreators.clearAlert,
          textId: 'web.tables.tasks.bulkFail',
        };
      default:
        return null;
    }
  };
}

TasksTablePopupContainer.propTypes = {
  alert: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  popup: PropTypes.string.isRequired,
  popupData: PropTypes.object.isRequired,
  tableId: PropTypes.string.isRequired, // passed in
  tasks: PropTypes.array.isRequired, // passed in
};

function mapStateToProps(state) {
  return {
    alert: state.popupAlert,
    loading: state.popupLoading,
    popup: state.popup,
    popupData: state.popupData,
  };
}

export default connect(mapStateToProps)(TasksTablePopupContainer);
