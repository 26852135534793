import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import Select2 from 'components/selects/select2';
import { PITCH_TEMPLATE } from 'web/templates/libs/templatesConstants';
import { isSpecialCategory } from 'web/templates/helpers/templatesHelper';
import I18N from 'languages';

class CategorySelect2 extends Component {
  state = {
    formattedCategories: [],
  };

  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.currentText = formatMessage({
      id: 'web.categories.categorySelect2.current',
    });
    this.noResultsText = formatMessage({
      id: 'web.categories.categorySelect2.categoryNotFound',
    });
    this.placeholderText = formatMessage({
      id: 'web.categories.categorySelect2.chooseCategory',
    });

    this._formatCategories(this.props);
    this._setInitialCategory();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categories !== this.props.categories) {
      this._formatCategories(nextProps);
      this._setInitialCategory();
    }
  }

  render() {
    const { className, selectedCategory, updateCategory } = this.props;
    const { formattedCategories } = this.state;

    return (
      <Select2
        classes={classNames('categories-category-select2', className)}
        clearable={false}
        noResultsText={this.noResultsText}
        onChange={updateCategory}
        options={formattedCategories}
        placeholder={this.placeholderText}
        value={selectedCategory.id}
        searchable
      />
    );
  }

  _setInitialCategory = () => {
    const {
      categories,
      initCategoryId,
      selectedCategory,
      updateCategory,
    } = this.props;

    if (initCategoryId && !selectedCategory.id && categories.length) {
      const initialCategory =
        categories.find((cat) => cat.id === initCategoryId) || categories[0];
      updateCategory({
        ...initialCategory,
        label: initialCategory.name,
        value: initialCategory.id,
      });
    }
  };

  labelInit = (cat) => {
    const categoryName =
      cat.category_type === PITCH_TEMPLATE && isSpecialCategory(cat)
        ? I18N.getStr(`common.${cat.name.toLowerCase()}`)
        : cat.name;
    return cat.id === this.props.initCategoryId
      ? `${categoryName}${this.currentText}`
      : categoryName;
  };

  label(cat) {
    return cat.category_type === PITCH_TEMPLATE && isSpecialCategory(cat)
      ? I18N.getStr(`common.${cat.name.toLowerCase()}`)
      : cat.name;
  }

  _formatCategories({ categories, labelInitCategory }) {
    const label = (labelInitCategory && this.labelInit) || this.label;
    this.setState(() => ({
      formattedCategories: categories.map((cat) => ({
        label: label(cat),
        value: cat.id,
        ...cat,
      })),
    }));
  }
}

CategorySelect2.propTypes = {
  categories: PropTypes.array,
  className: PropTypes.string,
  initCategoryId: PropTypes.number,
  intl: intlShape.isRequired,
  labelInitCategory: PropTypes.bool,
  selectedCategory: PropTypes.object.isRequired,
  updateCategory: PropTypes.func.isRequired,
};

CategorySelect2.defaultProps = {
  categories: [],
  className: '',
  initCategoryId: 0,
  labelInitCategory: false,
};

export default injectIntl(CategorySelect2);
