import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './checkbox.scss';

export default class Checkbox extends Component {
  render() {
    const {
      checked,
      classes,
      classesLabel,
      className,
      classNameLabel,
      disabled,
      label,
      name,
    } = this.props;
    //todo add third state of checkbox for indicator
    return (
      <div className={classNames(classes, className)}>
        <label
          onClick={this._onChange}
          className={classNames('tout-checkbox', classesLabel, classNameLabel)}
        >
          {label ? (
            <span className="checkbox-label-text">{label}</span>
          ) : (
            '\u00a0'
          )}
          <input
            checked={checked}
            disabled={disabled}
            name={name}
            type="checkbox"
            onChange={() => {}}
          />
          <div className="tout-checkbox-indicator" />
        </label>
      </div>
    );
  }

  _onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { name, onChange, checked, disabled } = this.props;

    if (disabled) {
      return;
    }

    onChange(!checked, name, e);
  };
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  classes: PropTypes.string,
  classesLabel: PropTypes.string,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  checked: false,
  classes: '',
  classesLabel: '',
  className: '',
  classNameLabel: '',
  disabled: false,
  label: '',
  name: '',
};
