import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { productTourSteps } from 'web/productTour/containers/productTourStepsContainer/lib/productTourConstants';
import onboardingPage from 'components/hocs/onboardingPage';
import Tabs from './components/tabs';
import Tab from './components/tab';
import Step1 from './components/steps/step1';
import Step2 from './components/steps/step2';
import Step3 from './components/steps/step3';
import Step4 from './components/steps/step4';
import Step5 from './components/steps/step5';
import './productTourStepsContainer.scss';

const ProductTourStepsContainer = ({ activeTabName }) => {
  const [active, setActive] = useState(activeTabName);

  const changeTab = (name) => setActive(name);

  return (
    <div className="productTour-steps-content">
      <Tabs active={active} changeTab={changeTab} setActive={setActive}>
        <Tab key="step_1" name={productTourSteps.step1}>
          <Step1 changeTab={changeTab} />
        </Tab>
        <Tab key="step_2" name={productTourSteps.step2}>
          <Step2 changeTab={changeTab} />
        </Tab>
        <Tab key="step_3" name={productTourSteps.step3}>
          <Step3 changeTab={changeTab} />
        </Tab>
        <Tab key="step_4" name={productTourSteps.step4}>
          <Step4 changeTab={changeTab} />
        </Tab>
        <Tab key="step_5" name={productTourSteps.step5}>
          <Step5 changeTab={changeTab} />
        </Tab>
      </Tabs>
    </div>
  );
};

ProductTourStepsContainer.defaultProps = {
  activeTabName: '',
};

ProductTourStepsContainer.propTypes = {
  activeTabName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  activeTabName: state.onboarding.productTourActiveTabName,
});

export default compose(
  connect(mapStateToProps),
  (component) =>
    onboardingPage(component, {
      shiftedBackground: true,
      titleKey: 'web.productTour.welcomeText',
    })
)(ProductTourStepsContainer);
