import I18N from 'languages';

export const REASON_TYPE = {
  optIn: 'optInReasonsItems',
  unsubscribe: 'unsubscribeReasons',
};

export const OPT_IN_REASONS_IDS = {
  filledOutForm: 'filled_out_form',
  interestedInProduct: 'interested_in_product',
  other: 'other',
  requestedOptIn: 'requested_opt_in',
  expressedInterestInService: 'expressed_interest_in_service',
};

export const OPT_IN_REASONS_LANG_IDS = {
  filledOutForm: 'web.person.unsubscribe.optInReasons.items.filledOutForm',
  interestedInProduct:
    'web.person.unsubscribe.optInReasons.items.interestedInProduct',
  other: 'web.person.unsubscribe.optInReasons.items.other',
  requestedOptIn: 'web.person.unsubscribe.optInReasons.items.requestedOptIn',
  expressedInterestInService:
    'web.person.unsubscribe.optInReasons.items.expressedInterestInService',
};

export const UNSUBSCRIBE_REASONS_IDS = {
  tooManyEmails: 'too_many_emails',
  notInterestedIn: 'not_interested_in',
  didNotSubscribe: 'did_not_subscribe',
  irrelevantContent: 'irrelevant_content',
  other: 'other',
  default: 'default',
};

export const UNSUBSCRIBED_TAG = 'unsubscribed';

export const UNSUBSCRIBE_REASONS_LANG_IDS = {
  tooManyEmails: 'web.person.unsubscribe.reasons.items.tooManyEmails',
  notInterestedIn: 'web.person.unsubscribe.reasons.items.notInterestedIn',
  didNotSubscribe: 'web.person.unsubscribe.reasons.items.didNotSubscribe',
  irrelevantContent: 'web.person.unsubscribe.reasons.items.irrelevantContent',
  other: 'web.person.unsubscribe.reasons.items.other',
  default: 'web.settings.unsubscribe.reason.default',
};

export const BlockTypeItems = [
  {
    value: 'marketing',
    label: I18N.getStr('web.settings.unsubscribe.blockTypeItems.marketing'),
  },
  {
    value: 'sales',
    label: I18N.getStr('web.settings.unsubscribe.blockTypeItems.sales'),
  },
  {
    value: 'all',
    label: I18N.getStr('web.settings.unsubscribe.blockTypeItems.all'),
  },
];

export const UnsubscribeOptionsAlertIds = {
  getError: 'UNSUBSCRIBE_OPTIONS_GET_ERROR',
};
