import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import './taskGroupHeader.scss';
import { ColorClasses } from 'libs/constantsStyles';
import Select from 'components/selects/default';
import { SelectCustomIcons } from 'components/selects/base';

class TaskGroupHeader extends Component {
  render() {
    const {
      chevron,
      title,
      count,
      dateFilters,
      selectedDateFilter,
      onDateFilterChange,
    } = this.props;

    return (
      <div className="task-group-header">
        <span className="header-title">
          <FormattedHTMLMessage id={title} values={{ count }} />
        </span>
        <div className="flex flex-right margin-right-quarter">
          <div className="view-by-wrapper">
            <span className="view-by-text">
              <FormattedHTMLMessage id="web.liveFeed.tasks.viewBy" />
            </span>
            <div className="calendar-picker">
              <Select
                classNameWrapper="reminder-date-select"
                highlightColor={ColorClasses.white}
                items={dateFilters}
                onChange={onDateFilterChange}
                selected={selectedDateFilter}
                customIcon={SelectCustomIcons.calendarSmall}
                backgroundColor={ColorClasses.grayExtraLight}
              />
            </div>
          </div>
          {chevron}
        </div>
      </div>
    );
  }
}

TaskGroupHeader.propTypes = {
  chevron: PropTypes.node,
  count: PropTypes.number,
  selectedDateFilter: PropTypes.string.isRequired,
  title: PropTypes.string,
  dateFilters: PropTypes.array,
  onDateFilterChange: PropTypes.func.isRequired,
};

TaskGroupHeader.defaultProps = {
  count: 0,
  selectedDateFilter: '',
  title: '',
};

export default TaskGroupHeader;
