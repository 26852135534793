import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './taskGroup.scss';
import Collapse, { Timeouts } from 'components/collapse';
import Chevron, {
  ChevronColors,
  ChevronDirections,
} from 'components/buttons/chevron';
import TaskEvent from 'web/task/components/taskEvent/taskEvent';
import TaskGroupHeader from 'web/task/components/taskGroupHeader/taskGroupHeader';
import { FormattedHTMLMessage } from 'react-intl';

class TaskGroup extends Component {
  constructor() {
    super();

    this.state = { open: true };
  }

  render() {
    const {
      title,
      dateFilters,
      selectedDateFilter,
      onDateFilterChange,
      tasks,
    } = this.props;
    const { open } = this.state;

    return (
      <div className="task-group">
        <TaskGroupHeader
          count={tasks.length}
          title={title}
          dateFilters={dateFilters}
          selectedDateFilter={selectedDateFilter}
          onDateFilterChange={onDateFilterChange}
          chevron={
            <Chevron
              color={ChevronColors.blue}
              direction={
                (open && ChevronDirections.bottom) || ChevronDirections.right
              }
              onClick={this._toggleOpen}
            />
          }
        />
        <div className="task-events-container">
          <Collapse
            className="task-group-header-collapse"
            in={open}
            timeoutEnter={Timeouts.twoFifty}
            timeoutExit={Timeouts.twoFifty}
          >
            {this._displayTasks()}
          </Collapse>
        </div>
      </div>
    );
  }

  _displayTasks = () => {
    const {
      actionCreators: {
        openEditTask,
        openCall,
        openEmail,
        openLinkedInSearch,
        getDateLabel,
        taskTypeAction,
        openPdv,
        taskDone,
        taskDelete,
      },
      tasks,
      overdue,
    } = this.props;

    if (tasks.length) {
      return (
        <div className="task-group-header-open-children">
          {tasks.map((event) => (
            <TaskEvent
              overdue={overdue}
              openEditTask={openEditTask}
              taskDelete={taskDelete}
              taskDone={taskDone}
              event={event}
              key={event.id}
              openEmail={openEmail}
              openCall={openCall}
              openInMail={openLinkedInSearch}
              getDateLabel={getDateLabel}
              taskTypeAction={taskTypeAction}
              openPdv={openPdv}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="empty-tasks">
          <FormattedHTMLMessage id="web.liveFeed.tasks.noTasks" />
        </div>
      );
    }
  };

  _toggleOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };
}

TaskGroup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  selectedDateFilter: PropTypes.string.isRequired,
  onDateFilterChange: PropTypes.func.isRequired,
  dateFilters: PropTypes.array.isRequired,
  title: PropTypes.string,
  overdue: PropTypes.bool,
  tasks: PropTypes.array,
};

TaskGroup.defaultProps = {
  tasks: [],
  title: '',
  overdue: false,
};

export default TaskGroup;
