import React, { Component, Fragment } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Accordion from 'components/accordion';
import SyncSettingsAccordionPopupContainer from '../../../containers/syncSettingsAccordionPopupContainer';

const LOG_EMAIL_ITEM = 'LogEmailItem';
const SYNC_TASK_ITEM = 'SyncTasksItem';
const AccordionItems = [LOG_EMAIL_ITEM, SYNC_TASK_ITEM];

class SyncSettingsAccordion extends Component {
  state = {
    accordionItems: {
      [LOG_EMAIL_ITEM]: {
        isOpen: false,
      },
      [SYNC_TASK_ITEM]: {
        isOpen: false,
      },
    },
    unsavedItem: null,
  };

  render() {
    const { items, popupId, ...otherProps } = this.props;
    return (
      <Fragment>
        <SyncSettingsAccordionPopupContainer
          onDiscard={this.onDiscardChanges}
          popupId={popupId}
        />

        <Accordion className="sync-settings-accordion margin-top-20">
          {items.map((item, index) =>
            React.createElement(item, {
              key: AccordionItems[index],
              ...otherProps,
              accordionProps: this.getAccordionProps(AccordionItems[index]),
            })
          )}
        </Accordion>
      </Fragment>
    );
  }

  getAccordionProps = (item) => {
    const { isOpen, disabledChevron } = this.state.accordionItems[item];

    return {
      isOpen,
      disabledChevron,
      onToggle: this.onToggle(item),
      collapseOptions: {
        disableAnimation: true,
      },
    };
  };

  setItemState = (item) => {
    this.setState((prevState) => ({
      accordionItems: {
        ...prevState.accordionItems,
        [item]: {
          isOpen: !prevState.accordionItems[item].isOpen,
        },
      },
    }));
  };

  onDiscardChanges = () => {
    const { unsavedItem } = this.state;
    this.setItemState(unsavedItem);
  };

  setUnsavedItem = (item) => {
    this.setState({ unsavedItem: item });
  };

  onToggle = (item) => ({ shouldShowUnsavedPopup = false }) => {
    const {
      actionCreators: { openPopup },
      popupId,
    } = this.props;

    if (shouldShowUnsavedPopup) {
      this.setUnsavedItem(item);
      openPopup(popupId);
    } else {
      this.setItemState(item);
    }
  };
}

SyncSettingsAccordion.propTypes = {
  intl: intlShape.isRequired,
  items: PropTypes.array.isRequired,
  actionCreators: PropTypes.object.isRequired,
  popupId: PropTypes.string,
};

SyncSettingsAccordion.defaultProps = {
  popupId: '',
};

export default injectIntl(SyncSettingsAccordion);
