import SalesforceActionTypes from '../libs/salesforceActionTypes';
import { combineReducers } from 'redux';

export const item = (state = {}, action = {}) => {
  switch (action.type) {
    case SalesforceActionTypes.setIntegration:
      return action.integration;
    case SalesforceActionTypes.deleteIntegration:
      return {};
    default:
      return state;
  }
};

export const fetched = (state = false, action = {}) => {
  switch (action.type) {
    case SalesforceActionTypes.setIntegrationFetched:
      return action.fetched;
    default:
      return state;
  }
};

export const fetching = (state = false, action = {}) => {
  switch (action.type) {
    case SalesforceActionTypes.setIntegrationFetching:
      return action.fetching;
    default:
      return state;
  }
};

export default combineReducers({ item, fetched, fetching });
