import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CampaignAnalytics from 'web/campaigns/analytics/components/campaignAnalytics/campaignAnalytics';
import TasksTable from 'web/tables/tasks';
import CampaignPeople from 'web/campaigns/people/components/campaignPeople/campaignPeople';
import CampaignSetup from '../campaignSetup/campaignSetup';
import CampaignSetupVertical from '../campaignSetupVertical';
import CampaignSettings from 'web/campaigns/settings/components/campaignSettings/campaignSettings';
import {
  CampaignDetailsEnum,
  CampaignsTasksTableId,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { TasksTableColumns } from 'web/tables/tasks/libs/tablesTasksConstants';
import './campaignTabView.scss';

const tasksTableColumns = [
  TasksTableColumns.markDone,
  TasksTableColumns.person,
  TasksTableColumns.company,
  TasksTableColumns.subject,
  TasksTableColumns.type,
  TasksTableColumns.priority,
  TasksTableColumns.due,
  TasksTableColumns.actions,
];

class CampaignTabView extends Component {
  _getCurrentTab = () => {
    const {
      account,
      addingNewCampaignDay,
      attachments,
      actionCreators,
      campaignsPeople,
      campaignsPeopleFilter,
      campaignsPeopleViewAsFilter,
      campaignsPeoplePagination,
      campaignsPeopleSearchString,
      campaignsTabViewFetching,
      campaignsTabViewLoading,
      campaignDayEditing,
      campaignDayLoading,
      campaignEditStates,
      campaignTemplates,
      ckeBodies,
      editingSteps,
      newDayNumber,
      selectedCampaign,
      selectedDetailsTab,
      settings,
      tasks,
      timezone,
      timezones,
      userCanEditCampaigns,
    } = this.props;

    switch (selectedDetailsTab) {
      case CampaignDetailsEnum.tasks.value:
        return {
          tab: (
            <TasksTable
              actionCreators={actionCreators}
              columns={tasksTableColumns}
              tableId={CampaignsTasksTableId}
              isCampaign
              fetching={campaignsTabViewFetching}
              loading={campaignsTabViewLoading}
              peopleSearchString={campaignsPeopleSearchString}
              tasks={tasks}
            />
          ),
        };
      case CampaignDetailsEnum.people.value:
        return {
          tab: (
            <CampaignPeople
              account={account}
              actionCreators={actionCreators}
              filter={campaignsPeopleFilter}
              viewAsFilter={campaignsPeopleViewAsFilter}
              loading={campaignsTabViewLoading}
              people={campaignsPeople}
              peoplePagination={campaignsPeoplePagination}
              peopleSearchString={campaignsPeopleSearchString}
              selectedCampaign={selectedCampaign}
            />
          ),
        };
      case CampaignDetailsEnum.analytics.value: {
        const {
          aggregate_metrics: aggregateMetrics,
          num_used: numUsed,
          num_success: numSuccess,
        } = selectedCampaign;

        return {
          tab: (
            <CampaignAnalytics
              analytics={{
                ...aggregateMetrics,
                num_used: numUsed,
                num_success: numSuccess,
              }}
            />
          ),
        };
      }
      case CampaignDetailsEnum.setup.value: {
        const setupProps = {
          addingNewCampaignDay,
          attachments,
          actionCreators,
          campaignDayEditing,
          campaignDayLoading,
          campaignEditStates,
          campaignTemplates,
          ckeBodies,
          editingSteps,
          selectedCampaign,
          timezone,
          timezones,
          newDayNumber,
          userCanEditCampaigns,
        };

        if (settings.setupHorizontal) {
          // hack as issue getting flexbox to stretch both height & width; since using absolute right with view switcher, need width full and flex makes that happen
          return {
            tab: <CampaignSetup {...setupProps} />,
            classes: 'position-flex',
          };
        } else {
          return { tab: <CampaignSetupVertical {...setupProps} /> };
        }
      }
      case CampaignDetailsEnum.settings.value:
        return {
          tab: (
            <CampaignSettings
              selectedCampaign={selectedCampaign}
              actionCreators={actionCreators}
            />
          ),
        };
      default:
        return null;
    }
  };

  render() {
    const tab = this._getCurrentTab();

    return (
      <div className={classNames('campaign-tab-view', tab.classes)}>
        {tab.tab}
      </div>
    );
  }
}

CampaignTabView.propTypes = {
  account: PropTypes.object.isRequired,
  addingNewCampaignDay: PropTypes.bool,
  attachments: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  campaignsPeople: PropTypes.array.isRequired,
  campaignsPeopleFilter: PropTypes.string.isRequired,
  campaignsPeopleViewAsFilter: PropTypes.number.isRequired,
  campaignsPeoplePagination: PropTypes.number.isRequired,
  campaignsPeopleSearchString: PropTypes.string.isRequired,
  campaignsTabViewFetching: PropTypes.bool.isRequired,
  campaignsTabViewLoading: PropTypes.bool.isRequired,
  campaignDayEditing: PropTypes.object.isRequired,
  campaignDayLoading: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editingSteps: PropTypes.object,
  selectedDetailsTab: PropTypes.string.isRequired,
  selectedCampaign: PropTypes.object,
  settings: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
};

CampaignTabView.defaultProps = {
  addingNewCampaignDay: false,
  newDayNumber: '',
  editingSteps: {},
  selectedCampaign: {},
};

export default CampaignTabView;
