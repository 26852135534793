import { baseGet, basePut } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export const getTeamSettings = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.teamSettings, null, resolve, reject);
  });

export const updateTeamSettings = (settings) =>
  new Promise((resolve, reject) => {
    basePut(Urls.teamSettings, { settings }, resolve, reject);
  });
