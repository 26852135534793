import isEmpty from 'lodash/isEmpty';
import I18N from 'languages';
import {
  OPT_IN_REASONS_IDS,
  OPT_IN_REASONS_LANG_IDS,
  UNSUBSCRIBE_REASONS_IDS,
  UNSUBSCRIBE_REASONS_LANG_IDS,
} from '../libs/unsubscribeOptionsConstants';

export const getUnsubscribeOptionsLabel = (type, defaultLabel) => {
  switch (type) {
    case OPT_IN_REASONS_IDS.filledOutForm:
      return I18N.getStr(OPT_IN_REASONS_LANG_IDS.filledOutForm);

    case OPT_IN_REASONS_IDS.interestedInProduct:
      return I18N.getStr(OPT_IN_REASONS_LANG_IDS.interestedInProduct);

    case OPT_IN_REASONS_IDS.other:
      return I18N.getStr(OPT_IN_REASONS_LANG_IDS.other);

    case OPT_IN_REASONS_IDS.requestedOptIn:
      return I18N.getStr(OPT_IN_REASONS_LANG_IDS.requestedOptIn);

    case OPT_IN_REASONS_IDS.expressedInterestInService:
      return I18N.getStr(OPT_IN_REASONS_LANG_IDS.expressedInterestInService);

    case UNSUBSCRIBE_REASONS_IDS.tooManyEmails:
      return I18N.getStr(UNSUBSCRIBE_REASONS_LANG_IDS.tooManyEmails);

    case UNSUBSCRIBE_REASONS_IDS.notInterestedIn:
      return I18N.getStr(UNSUBSCRIBE_REASONS_LANG_IDS.notInterestedIn);

    case UNSUBSCRIBE_REASONS_IDS.didNotSubscribe:
      return I18N.getStr(UNSUBSCRIBE_REASONS_LANG_IDS.didNotSubscribe);

    case UNSUBSCRIBE_REASONS_IDS.irrelevantContent:
      return I18N.getStr(UNSUBSCRIBE_REASONS_LANG_IDS.irrelevantContent);

    case UNSUBSCRIBE_REASONS_IDS.other:
      return I18N.getStr(UNSUBSCRIBE_REASONS_LANG_IDS.other);

    case UNSUBSCRIBE_REASONS_IDS.default:
      return I18N.getStr(UNSUBSCRIBE_REASONS_LANG_IDS.default);

    default:
      return defaultLabel;
  }
};

export const isUnsubscribeReasonsFull = (unsubscribeReasons) =>
  !isEmpty(unsubscribeReasons) && unsubscribeReasons.length > 1;
