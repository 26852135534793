import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchInput from '../search';
import Menu, { menuItemShape } from 'components/menu';
import { resultShape } from './searchMultipleShapes';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './searchMultiple.scss';

const classSelector = 'search-multiple';
const exceptionSelectors = [classSelector];

class SearchMultiple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      open: false,
    };
  }

  componentWillMount() {
    this._setItemsState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.results !== nextProps.results) {
      this._setItemsState(nextProps);
    }
    if (
      this.props.value !== nextProps.value ||
      this.props.prefix !== nextProps.prefix
    ) {
      this._setOpen(nextProps);
    }
  }

  render() {
    const {
      className,
      fullHeight,
      intl: { formatMessage },
      items: ignored0,
      groupLengthLimit: ignored1,
      maxWidth,
      noDropdown: ignored5,
      onResultSelected: ignored2,
      placeholder,
      prefix,
      results: ignored3,
      rowsShown,
      shownHeight,
      valueMin: ignored4,
      ...other
    } = this.props;

    const { open } = this.state;
    const searchInputClassName = classNames(
      {
        'search-multiple-input-full-height': fullHeight,
        'search-multiple-input-open': open,
      },
      'search-multiple-input-size'
    );
    const styles = { maxWidth };

    return (
      <div
        className={classNames(
          classSelector,
          className,
          'search-multiple-input-size'
        )}
      >
        <SearchInput
          {...other}
          className={searchInputClassName}
          fullHeight={fullHeight}
          onBlur={this.close}
          placeholder={
            (prefix &&
              formatMessage(
                { id: 'searchMultiple.placeholder' },
                { prefix }
              )) ||
            placeholder
          }
          prefix={prefix}
          style={styles}
        />
        {open ? (
          <Menu
            className="search-multiple-menu"
            exceptionSelectors={exceptionSelectors}
            handleSelected={this.handleSelected}
            itemRenderer={this.itemRenderer}
            items={this._getItems()}
            maxWidth={maxWidth}
            onOutsideClick={this.close}
            rowHeight={29}
            rowsShown={rowsShown}
            shownHeight={shownHeight}
          />
        ) : null}
      </div>
    );
  }

  close = () => {
    this.setState({ open: false });
  };

  _setOpen({ prefix, noDropdown, value, valueMin }) {
    this.setState({ open: !noDropdown && !prefix && value.length >= valueMin });
  }

  handleSelected = (data) => {
    this.close();
    this.props.onResultSelected({
      category: { data: data.label, index: data.categoryIndex },
      value: { data: data.value, index: data.valueIndex },
    });
  };

  _setItemsState = (props) => {
    this.setState({
      items:
        props.items ||
        SearchMultiple.parseResults(props.results, props.groupLengthLimit),
    });
  };

  _getItems = () => {
    const menuItems = this.props.items || this.state.items;
    return menuItems.length ? menuItems : [{ disabled: true, noResults: true }];
  };

  /* Static so that results could be memoized in a selector and passed as items instead of  */
  static parseResults(results = [], groupLengthLimit = 2) {
    const items = [];
    for (let i = 0; i < results.length; i++) {
      if (results[i].values.length) {
        items.push({
          categoryIndex: i,
          disabled: true,
          label: results[i].category,
          value: '',
        });

        const limit = Math.min(results[i].values.length, groupLengthLimit);
        for (let j = 0; j < limit; j++) {
          items.push({
            categoryIndex: i,
            label: results[i].category,
            value: results[i].values[j],
            valueIndex: j,
          });
        }

        if (
          results[i].overflow ||
          results[i].values.length > groupLengthLimit
        ) {
          items.push({
            categoryIndex: i,
            label: results[i].category,
            overflow: true,
            value: '',
          });
        }
      }
    }

    return items;
  }

  itemRenderer(item = {}) {
    const { label, noResults, overflow, value } = item;
    if (overflow) {
      return (
        <div className="search-multiple-item search-multiple-item-overflow text-overflow">
          <FormattedMessage
            id="searchMultiple.item.overflow"
            values={{ category: label }}
          />
        </div>
      );
    }
    if (value) {
      return (
        <div className="search-multiple-item search-multiple-item-value text-overflow">
          {value}
        </div>
      );
    }
    if (noResults) {
      return (
        <div className="search-multiple-item search-multiple-item-value text-overflow">
          <FormattedMessage id="searchMultiple.item.noResults" />
        </div>
      );
    }
    return (
      <div className="search-multiple-item search-multiple-item-name text-overflow">
        {label || ''}
      </div>
    );
  }
}

SearchMultiple.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  clearSearch: PropTypes.func,
  fullHeight: PropTypes.bool,
  groupLengthLimit: PropTypes.number,
  intl: intlShape.isRequired,
  items: PropTypes.arrayOf(menuItemShape),
  maxWidth: PropTypes.number,
  noDropdown: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onPrefixRemove: PropTypes.func,
  onResultSelected: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  results: PropTypes.arrayOf(resultShape),
  rowsShown: PropTypes.number,
  shownHeight: PropTypes.number,
  value: PropTypes.string,
  valueMin: PropTypes.number,
};

SearchMultiple.defaultProps = {
  autoFocus: false,
  className: '',
  clearSearch: undefined,
  fullHeight: false,
  groupLengthLimit: 2,
  items: undefined,
  maxWidth: undefined,
  noDropdown: false,
  onPrefixRemove: undefined,
  prefix: '',
  results: [],
  rowsShown: 10,
  shownHeight: 0,
  value: '',
  valueMin: 3,
};

export default injectIntl(SearchMultiple);
