/* global __DEV__ */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'web/reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import mixpanel from './middlewares/mixpanel';

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    __DEV__
      ? composeWithDevTools(applyMiddleware(thunk, mixpanel))
      : applyMiddleware(thunk, mixpanel)
  );

  return store;
}
