import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BackgroundColors } from 'libs/constantsStyles';
import Card from 'components/card';

const UserUnsubscribesNotAllow = ({ unsubscribeText }) => (
  <div>
    <h3>
      <FormattedMessage id="web.settings.unsubscribe.unsubscribeMessaging" />
    </h3>
    <div className="margin-top-half margin-bottom-full">
      <FormattedMessage id="web.settings.unsubscribe.user.cancelled" />
    </div>
    <Card backgroundColor={BackgroundColors.grayExtraLight}>
      <div dangerouslySetInnerHTML={{ __html: unsubscribeText }} />
    </Card>
  </div>
);

UserUnsubscribesNotAllow.propTypes = {
  unsubscribeText: PropTypes.string,
};

UserUnsubscribesNotAllow.defaultProps = {
  unsubscribeText: '',
};

export default UserUnsubscribesNotAllow;
