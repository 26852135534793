import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import uniq from 'lodash/uniq';
import trim from 'lodash/trim';
import Sorter from 'libs/sortingUiComponents';
import TemplateCard from './templateCard';
import List from './list';
import InputText from 'components/inputText';
import Icon from 'components/buttons/icon';
import Select from 'components/select';
import { Selects } from 'libs/constantsShared';
import { sortingMap } from './templateChooserHelper';
import I18N from 'languages';
import './templateChooserSimple.scss';

class TemplateChooserSimple extends Component {
  constructor(props) {
    super(props);

    this.sorter = new Sorter(sortingMap, 'name');
    const templatesSorted = this.sorter.sort(props.templates, 'name', true);
    const { categories, category } = this._getCategories(
      props.templates,
      props.initCategory
    );

    this.state = {
      category,
      categories,
      searchText: '',
      showListView: true,
      showSearchBar: false,
      templatesInitialSort: templatesSorted, // used instead of referencing props.templates below to refer to all templates
      templatesOrganized: this._filterTemplatesByCategory(
        templatesSorted,
        category
      ),
    };

    this.getArrowClass = this.sorter.getArrowClass.bind(this.sorter); // passed to List
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.templates.length === 0 && nextProps.templates.length) {
      const { categories, category } = this._getCategories(
        nextProps.templates,
        nextProps.initCategory
      );
      const templatesSorted = this.sorter.sort(
        nextProps.templates,
        'name',
        true
      );

      this.setState({
        category,
        categories,
        templatesInitialSort: templatesSorted,
        templatesOrganized: templatesSorted,
      });
    }
  }

  render() {
    const {
      insertTemplate,
      intl: { formatMessage },
      selectedId,
    } = this.props;
    const {
      category,
      categories,
      searchText,
      showListView,
      showSearchBar,
      templatesOrganized,
    } = this.state;

    return (
      <div className="templateChooserSimple">
        <div className="header">
          {showSearchBar ? (
            <InputText
              autoComplete="off"
              className="filters-search"
              id="templates-search"
              onChange={this.handleSearch}
              placeholder={formatMessage({
                id: 'templateChooser.searchPlaceholder',
              })}
              toutUiStyle
              type="text"
              value={searchText}
            />
          ) : (
            <div className="template-select">
              <div className="template-select-category">
                {formatMessage({ id: 'common.category' })}
              </div>
              <Select
                className="template-select-select"
                items={categories}
                onChange={this.categoryChosen}
                rowsShown={6}
                selected={category}
              />
            </div>
          )}
          <Icon
            classes={showSearchBar ? 'tout-icon-back' : 'tout-icon-search'}
            color="white"
            onClick={this.handleSearchClick}
            title={formatMessage({ id: 'templateChooser.toggleSearch' })}
          />
          <Icon
            classes={showListView ? 'tout-icon-cardview' : 'tout-icon-listview'}
            color="white"
            onClick={this.handleViewClick}
            title={formatMessage({ id: 'templateChooser.toggleView' })}
          />
        </div>
        <div className={`cards-container${showListView ? '' : ' cards'}`}>
          {showListView ? (
            <List
              getArrowClass={this.getArrowClass}
              onHeaderClick={this.onHeaderClick}
              onSelected={insertTemplate}
              selectedId={selectedId}
              templates={templatesOrganized}
            />
          ) : (
            templatesOrganized.map((template) => (
              <TemplateCard
                className="cards-item"
                key={`template-card-${template.id}`}
                onSelected={insertTemplate}
                selected={selectedId === template.id}
                template={template}
              />
            ))
          )}
        </div>
      </div>
    );
  }

  categoryChosen = (category) => {
    const filteredTemplates = this._filterTemplatesByCategory(
      this.state.templatesInitialSort,
      category
    );
    this.setState({
      category,
      templatesOrganized: this.sorter.sort(filteredTemplates, 'name', true),
    });
  };

  handleSearchClick = () => {
    const { category, showSearchBar } = this.state;
    this.setState({ showSearchBar: !showSearchBar, searchText: '' }, () =>
      this.categoryChosen(category)
    );
  };

  handleViewClick = () => {
    const { showListView } = this.state;
    this.setState({ showListView: !showListView }); //todo sort by name when moving to card view - also on mount/0->?
  };

  handleSearch = (e) => {
    const searchText = e.currentTarget.value || '';
    const { templatesInitialSort } = this.state;
    this.setState({
      templatesOrganized: this._filterTemplatesByText(
        templatesInitialSort,
        searchText
      ), //global or category-local?
      searchText,
    });
  };

  onHeaderClick = (e) => {
    const { templatesOrganized } = this.state;
    this.setState({
      templatesOrganized: this.sorter.sort(
        templatesOrganized,
        e.target.dataset.column
      ),
    });
  };

  _filterTemplatesByCategory = (templates, category) => {
    const { templatesFavorites } = this.props;

    switch (category) {
      case Selects.all:
        return templates;
      case Selects.favorites:
        return templatesFavorites;
      default:
        return templates.filter((template) => template.category === category);
    }
  };

  _filterTemplatesByText(templates, searchText) {
    if (searchText.length) {
      const text = searchText.toLowerCase();
      return templates.filter(
        (template) => template.name.toLowerCase().search(text) > -1
      );
    }
    return templates;
  }

  _getCategories(templates, initCategory) {
    const categories = this._parseCategories(templates);
    let category = categories.length ? categories[1].value : Selects.all;

    if (initCategory) {
      const found = categories.find((cat) => cat.value === initCategory) || {};
      category = found.value || category;
    }

    return { categories, category };
  }

  _parseCategories = (templates = []) => {
    const {
      intl: { formatMessage },
      templatesFavorites,
    } = this.props;

    let categories = templates.map((template) => trim(template.category));
    categories = uniq(categories).map((category) => {
      const categoryLabel =
        category === Selects.general ? I18N.getStr('common.general') : category;
      return { label: categoryLabel, value: category };
    });

    if (templatesFavorites.length) {
      categories.unshift({
        label: formatMessage({ id: 'common.favorites' }),
        value: Selects.favorites,
      });
    }

    categories.unshift({
      label: formatMessage({ id: 'common.all' }),
      value: Selects.all,
    });

    return categories;
  };
}

TemplateChooserSimple.propTypes = {
  initCategory: PropTypes.string,
  insertTemplate: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  selectedId: PropTypes.number,
  templates: PropTypes.array.isRequired,
  templatesFavorites: PropTypes.array,
};

TemplateChooserSimple.defaultProps = {
  initCategory: '',
  selectedId: 0,
  templatesFavorites: [],
};

export default injectIntl(TemplateChooserSimple);
