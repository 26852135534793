import React from 'react';
import PropTypes from 'prop-types';
import ComposeTemplatesSearch from 'web/composeWindow/components/composeTemplatesSearch/composeTemplatesSearch';
import Icon, { IconColors } from 'components/buttons/icon/icon';
import ComposeTemplatesPinnedTabs from 'web/composeWindow/components/composeTemplatesPinnedTabs/composeTemplatesPinnedTabs';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './composeTemplatesNav.scss';
import { localizedCategory } from 'web/composeWindow/helpers/composeTemplatesHelpers';

class ComposeTemplatesNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySearch: false,
    };
  }

  toggleSearch = () => {
    this.setState(({ displaySearch }) => ({
      displaySearch: !displaySearch,
    }));
  };

  handleSelectTab = (selectedTabValue) => {
    const {
      selectedCategory: prevSelectedCategory,
      handleSelectCategory,
      pinnedCategories,
    } = this.props;
    if (prevSelectedCategory.id === parseInt(selectedTabValue, 10)) return;
    const selectedCategory = pinnedCategories.find(
      ({ id }) => id === parseInt(selectedTabValue, 10)
    );
    handleSelectCategory(selectedCategory);
  };

  getSelectedTemplateBar = () => {
    const {
      intl: { formatMessage },
      selectedTemplate,
    } = this.props;

    return (
      <div className="compose-selected-template text-overflow">
        <span className="text-gray">
          {this.getFormattedMessage()}
        </span>
      </div>
    );
  };

  getPinnedTabs = () => {
    const { pinnedCategories, openEditModal, selectedCategory } = this.props;

    const pinnedTabs = pinnedCategories.map(
      ({ name, id, icon, hideTitle, iconTooltip }) => ({
        hideTitle,
        icon,
        iconTooltip,
        title: localizedCategory(name),
        value: `${id}`,
      })
    );

    return (
      <ComposeTemplatesPinnedTabs
        onTabSelect={this.handleSelectTab}
        openEditModal={openEditModal}
        pinnedTabs={pinnedTabs}
        selectedTabValue={selectedCategory.id}
      />
    );
  };

  getSearch = () => {
    const {
      selectedCategory,
      handleSelectCategory,
      handleSearchTerm,
      searchTerm,
      templateCategories,
      hasRecentlyUsedTemplates,
    } = this.props;

    return (
      <ComposeTemplatesSearch
        handleSearchTerm={handleSearchTerm}
        handleSelectCategory={handleSelectCategory}
        hasRecentlyUsedTemplates={hasRecentlyUsedTemplates}
        searchTerm={searchTerm}
        selectedCategory={selectedCategory}
        templateCategories={templateCategories}
      />
    );
  };

  getNav = () => {
    const { displaySearch } = this.state;

    return (
      <div className="compose-templates-nav">
        <Icon
          className={displaySearch ? 'tout-icon-close' : 'tout-icon-search'}
          color={IconColors.default}
          onClick={this.toggleSearch}
        />
        {displaySearch ? this.getSearch() : this.getPinnedTabs()}
      </div>
    );
  };

  getFormattedMessage = () => {
    const {
      selectedTemplate
    } = this.props;

    if (selectedTemplate && selectedTemplate.bypass_unsubscribe) {
      return (
        <FormattedMessage
          id="web.composeWindow.templates.selected.nonCommercial"
          values={{
            templateName: selectedTemplate.name,
          }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="web.composeWindow.templates.selected"
          values={{
            isTemplateSelected: !!selectedTemplate,
            templateName: selectedTemplate ? selectedTemplate.name : '',
          }}
        />
      )
    }
  }

  render() {
    const { isMinimized } = this.props;

    return (
      <div className="compose-templates-nav-container">
        {isMinimized ? this.getSelectedTemplateBar() : this.getNav()}
      </div>
    );
  }

  static propTypes = {
    handleSearchTerm: PropTypes.func.isRequired,
    handleSelectCategory: PropTypes.func.isRequired,
    hasRecentlyUsedTemplates: PropTypes.bool,
    intl: intlShape.isRequired,
    isMinimized: PropTypes.bool.isRequired,
    openEditModal: PropTypes.func.isRequired,
    pinnedCategories: PropTypes.array.isRequired,
    searchTerm: PropTypes.string.isRequired,
    selectedCategory: PropTypes.object.isRequired,
    selectedTemplate: PropTypes.object,
    templateCategories: PropTypes.array.isRequired,
  };

  static defaultProps = {
    hasRecentlyUsedTemplates: false,
    selectedTemplate: null,
  };
}

export default injectIntl(ComposeTemplatesNav);
