import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { PeopleFilters } from 'web/campaigns/people/libs/campaignsPeopleConstants';
import differenceBy from 'lodash/differenceBy';

/* campaignsPeople are actually workflow instances */
export function campaignsPeople(state = [], action) {
  switch (action.type) {
    case ActionTypes.campaignPeople.data.people:
      return action.data;
    case ActionTypes.campaignPeople.data.updatePeople:
      return [
        ...state.slice(0, action.index),
        action.data,
        ...state.slice(action.index + 1),
      ];
    case ActionTypes.campaignPeople.data.addPeople:
      return [action.data, ...state];
    case ActionTypes.campaignPeople.data.removePeople:
      return [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1),
      ];
    case ActionTypes.campaignPeople.data.removePeopleBulk:
      return differenceBy(state, action.data, 'person_id');
    case ActionTypes.campaignPeople.data.removeInstancesBulk: {
      const ids = action.successes.map((id) => ({ id: parseInt(id, 10) }));
      return differenceBy(state, ids, 'id');
    }
    case ActionTypes.campaignPeople.state.reset:
      return [];
    default:
      return state;
  }
}

export const initPage = 1;
export function campaignsPeoplePagination(state = initPage, action) {
  switch (action.type) {
    case ActionTypes.campaignPeople.state.pagination:
      return action.data;
    case ActionTypes.campaignPeople.state.filter:
    case ActionTypes.campaignPeople.state.reset:
      return initPage;
    default:
      return state;
  }
}

export const initFilter = PeopleFilters.inProgress;
export function campaignsPeopleFilter(state = initFilter, action) {
  switch (action.type) {
    case ActionTypes.campaignPeople.state.filter:
      return action.data;
    case ActionTypes.campaignPeople.state.reset:
      return initFilter;
    default:
      return state;
  }
}

export function campaignsPeopleViewAsFilter(state = PeopleFilters.all, action) {
  switch (action.type) {
    case ActionTypes.campaignPeople.state.viewAsFilter:
      return action.data;
    case ActionTypes.campaignPeople.state.resetViewAs:
      return PeopleFilters.all;
    default:
      return state;
  }
}
