import React from 'react';
import PropTypes from 'prop-types';
import SmartSelect, { SmartSelectThemes } from 'components/selects/smartSelect';
import { mapTemplateCategoryItems, localizedCategory } from 'web/composeWindow/helpers/composeTemplatesHelpers';
import { injectIntl, intlShape } from 'react-intl';
import './composeTemplatesCategorySelector.scss';

const SELECT_ROW_HEIGHT = 30;

class ComposeTemplatesCategorySelector extends React.Component {
  constructor(props) {
    super(props);
    const { selectedCategory } = props;
    this.state = {
      inputValue: selectedCategory.name,
    };
  }

  handleInput = (inputValue) => {
    this.setState({ inputValue });
  };

  clearInput = () => {
    this.setState({ inputValue: '' });
  };

  handleSelect = (selectedCategory) => {
    this.props.onSelect(selectedCategory);
  };

  render() {
    const {
      templateCategories,
      selectedCategory: { id: selectedItemID },
      intl: { formatMessage },
    } = this.props;
    const { inputValue } = this.state;
    const templateCategoryItems = mapTemplateCategoryItems(templateCategories);

    return (
      <div className="compose-template-category-selector">
        <SmartSelect
          items={templateCategoryItems}
          inputValue={localizedCategory(inputValue)}
          onChange={this.handleInput}
          onSelect={this.handleSelect}
          clearInput={this.clearInput}
          theme={SmartSelectThemes.dark}
          selectedItem={selectedItemID}
          rowHeight={SELECT_ROW_HEIGHT}
          highlightInputValue
          placeholder={formatMessage({
            id: 'web.templates.createTemplate.selectPlaceholder',
          })}
        />
      </div>
    );
  }
}

ComposeTemplatesCategorySelector.propTypes = {
  templateCategories: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object,
  intl: intlShape.isRequired,
};

ComposeTemplatesCategorySelector.defaultProps = {
  selectedCategory: { name: '' },
};

export default injectIntl(ComposeTemplatesCategorySelector);
