import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Switch from 'components/buttons/switch';
import TeamWideSyncSettingsAccordion from '../teamWideSyncSettingsAccordion';

const TeamWideSyncSettingsCard = (props) => {
  const {
    actionCreators: { applyTeamSettings },
    intl: { formatMessage },
    isApplyTeamSettings,
  } = props;

  return (
    <div className="salesforce-sync-card">
      <h3>
        <FormattedMessage id="web.settings.adminSettings.salesforce.sync.teamWideSyncSettings.title" />
      </h3>
      <div className="margin-top-20">
        <Switch
          label={formatMessage({
            id: 'web.settings.adminSettings.salesforce.sync.applyTeamSettings',
          })}
          rightLabel
          checked={isApplyTeamSettings}
          onChange={applyTeamSettings}
          name="apply_team_settings"
        />
      </div>
      <div className="margin-top-20">
        <p>
          <FormattedHTMLMessage id="web.settings.adminSettings.salesforce.sync.description" />
        </p>
      </div>
      {isApplyTeamSettings && (
        <div className="margin-top-30">
          <TeamWideSyncSettingsAccordion {...props} />
        </div>
      )}
    </div>
  );
};

TeamWideSyncSettingsCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isApplyTeamSettings: PropTypes.bool.isRequired,
};

export default injectIntl(TeamWideSyncSettingsCard);
