import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { BackgroundColors } from 'libs/constantsStyles';
import './popupOverlay.scss';

const PopupOverlay = ({ className, color, html, text, textId, textValues }) => {
  const overlayClass = classNames('popup-overlay', className, {
    [`background-${color}`]: color,
    'text-white': color !== 'white',
    'text-blue': color === 'white',
  });
  return (
    <div className={overlayClass}>
      {html ? (
        <FormattedHTMLMessage id={textId} values={textValues} />
      ) : (
        text || <FormattedMessage id={textId} values={textValues} />
      )}
    </div>
  );
};

PopupOverlay.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  html: PropTypes.bool,
  text: PropTypes.string,
  textId: PropTypes.string.isRequired,
  textValues: PropTypes.object,
};

PopupOverlay.defaultProps = {
  className: '',
  color: BackgroundColors.green,
  html: false,
  text: '',
  textValues: {},
};

export default PopupOverlay;
