import ActionTypes from 'table/modules/actionTypes';
import trackerEvents from 'table/modules/tracker/trackerEvents';
import track from 'table/modules/tracker';

export function onActionClick(action = {}) {
  return (dispatch, getState) => {
    const { selectableSelectedItems } = getState();

    const ids = Object.keys(selectableSelectedItems);
    const items = Object.values(selectableSelectedItems);

    if (action.onClick && ids.length) {
      action.onClick(
        ids,
        () => dispatch({ type: ActionTypes.selectableClearSelectedItems }),
        items
      );
    }

    if (action.autoClear) {
      dispatch({ type: ActionTypes.selectableClearSelectedItems });
    }

    track(trackerEvents.bulkActionSelected, {
      name: action.id || action.label,
      count: ids.length,
    });
  };
}
