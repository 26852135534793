export default {
  initStore: 'ENGAGE_INIT_STORE',
  addOpenChildrenId: 'ENGAGE_ADD_OPEN_CHILDREN_ID',
  removeOpenChildrenId: 'ENGAGE_REMOVE_OPEN_CHILDREN_ID',
  updateSalesforceInstanceUrl: 'UPDATE_SALESFORCE_INSTANCE_URL',
  events: 'ENGAGE_EVENTS',
  updateEngageEvents: 'UPDATE_ENGAGE_EVENTS',
  initialLoading: 'INITIAL_LOADING',
  engageEventsExtraData: 'ENGAGE_EVENTS_EXTRA_DATA',
  markEventAsSeen: 'ENGAGE_MARK_EVENT_AS_SEEN',
};
