import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import ListItemRename from '../listItemRename';
import ListItemOptions from '../listItemOptions';
import './listItem.scss';
import { isSpecialCategory } from 'web/templates/helpers/templatesHelper';
import I18N from 'languages';

class ListItem extends PureComponent {
  state = {
    isEditing: false,
  };

  render() {
    const {
      className,
      height,
      label,
      listScrollRef,
      onClick,
      value,
    } = this.props;
    const { isEditing } = this.state;
    const options = this._getOptions();
    const labelText = isSpecialCategory(label)
      ? I18N.getStr(`common.${label.toLowerCase()}`)
      : label;

    return (
      <div
        className={classNames('list-item', className)}
        onClick={onClick}
        style={{ height: `${height}px` }}
      >
        {(isEditing && (
          <ListItemRename
            id={value}
            onCancel={this.onRenameCancel}
            onRename={this.onRenameUpdate}
            value={label}
          />
        )) || <div className="text-overflow">{labelText}</div>}
        {(options.length &&
          !isEditing && (
            <ListItemOptions
              classNameDota="list-item-show-dota"
              height={height}
              id={value}
              listScrollRef={listScrollRef}
              options={options}
            />
          )) ||
          null}
      </div>
    );
  }

  onRename = () => {
    this.setState(() => ({ isEditing: true }));
  };

  onRenameUpdate = (id, value) => {
    this.onRenameCancel();
    this.props.onRename(id, value);
  };

  onRenameCancel = () => {
    this.setState(() => ({ isEditing: false }));
  };

  _getOptions = () => {
    const {
      deleteCheckDisabled,
      intl: { formatMessage },
      onDelete,
      onRename,
      renameCheckDisabled,
      showDelete,
      showRename,
    } = this.props;
    const options = [];

    if (showRename && onRename) {
      options.push({
        action: this.onRename,
        checkDisabled: renameCheckDisabled,
        label: formatMessage({ id: 'common.rename' }),
      });
    }

    if (showDelete && onDelete) {
      options.push({
        action: onDelete,
        checkDisabled: deleteCheckDisabled,
        label: formatMessage({ id: 'common.delete' }),
      });
    }

    return options;
  };
}

export const listItemHeight = 40;

ListItem.propTypes = {
  className: PropTypes.string,
  deleteCheckDisabled: PropTypes.func,
  height: PropTypes.number,
  intl: intlShape.isRequired,
  label: PropTypes.string.isRequired,
  listScrollRef: PropTypes.object, // passed by List
  onClick: PropTypes.func, // should usually be handled by List's handleSelected
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  renameCheckDisabled: PropTypes.func,
  showDelete: PropTypes.bool,
  showRename: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

ListItem.defaultProps = {
  className: '',
  deleteCheckDisabled: () => false,
  height: 40,
  listScrollRef: {},
  onClick: () => {},
  onDelete: () => {},
  onRename: () => {},
  renameCheckDisabled: () => false,
  showDelete: false,
  showRename: false,
};

export default injectIntl(ListItem);

export let UnwrappedListItem; //eslint-disable-line import/no-mutable-exports
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedListItem = ListItem;
}
