import dialerActionTypes from '../libs/dialerActionTypes';

export const callReasonOptions = (state = [], { type, callReasonOptions }) => {
  switch (type) {
    case dialerActionTypes.getCallReasonOptionsSuccess:
      return callReasonOptions;
    default:
      return state;
  }
};

export const callReasonOptionsLoading = (
  state = false,
  { type, isLoading }
) => {
  switch (type) {
    case dialerActionTypes.callReasonOptionsLoading:
      return isLoading;
    default:
      return state;
  }
};
