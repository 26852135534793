import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SalesforceCustomizationErrorsIds } from '../libs/salesforceCustomizationConstants';

class SalesforceCustomizationErrors extends Component {
  render() {
    return (
      <div className="salesforce-customization-errors">
        {this.getErrorsComponent()}
      </div>
    );
  }

  getErrorsComponent() {
    const {
      errors: { hasErrors, details, description },
    } = this.props;

    if (!hasErrors) {
      return null;
    }

    return (
      <div className="alert margin-bottom-full">
        {description ? (
          <div>{details || this.getErrorsDescription()}</div>
        ) : (
          <FormattedMessage id="web.salesforceCustomization.popup.postUpdate.error.content" />
        )}
      </div>
    );
  }

  getErrorsDescription() {
    const {
      errors: { description },
    } = this.props;

    if (
      description === SalesforceCustomizationErrorsIds.apiError ||
      description === SalesforceCustomizationErrorsIds.pusherError
    ) {
      return <FormattedMessage id={description} />;
    }

    return description;
  }
}

SalesforceCustomizationErrors.propTypes = {
  errors: PropTypes.object.isRequired,
};

export default SalesforceCustomizationErrors;
