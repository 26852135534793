import React from 'react';
import PropTypes from 'prop-types';
import ToutHeader from 'web/liveFeed/components/toutCard/toutHeader';
import LiveFeedEvents from 'web/liveFeed/components/liveFeedEvents';
import './toutCard.scss';

const ToutCard = ({
  actionCreators,
  pitch,
  salesforceInstanceUrl,
  isLoading,
  events,
  showToutBorder,
}) => (
  <div className="livefeed-tout-card">
    <ToutHeader
      actionCreators={actionCreators}
      pitch={pitch}
      salesforceInstanceUrl={salesforceInstanceUrl}
      isLoading={isLoading}
      showToutBorder={showToutBorder}
    />
    <LiveFeedEvents
      events={events}
      openLink={actionCreators.openLink}
      markEventAsSeen={actionCreators.markEventAsSeen}
    />
  </div>
);

ToutCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  pitch: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  showToutBorder: PropTypes.bool,
};

ToutCard.defaultProps = {
  showToutBorder: false,
};

export default ToutCard;
