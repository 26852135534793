import React from 'react';
import PropTypes from 'prop-types';
import {
  EMAIL_THROTTLING_SOURCE_TYPES,
  TEAM_EMAIL_LIMIT_FORM_ID,
} from 'web/settings/adminSettings/general/libs/generalPageConstants';
import EmailLimitsForm from 'web/settings/adminSettings/general/components/emailLimitsForm';

const EmailLimits = (props) => {
  const {
    defaultDeliveryChannel,
    teamEmailLimit,
    teamEmailLimitSource,
    onTeamEmailLimitFormError,
    subscriptionEmailLimit,
    actionCreators: { updateTeamEmailLimit },
  } = props;

  return (
    <EmailLimitsForm
      defaultDeliveryChannel={defaultDeliveryChannel}
      form={TEAM_EMAIL_LIMIT_FORM_ID}
      initialValues={{ teamEmailLimit }}
      onError={onTeamEmailLimitFormError}
      onSubmit={(params, ...rest) =>
        updateTeamEmailLimit(
          {
            ...params,
            teamEmailLimitSource: EMAIL_THROTTLING_SOURCE_TYPES.manual,
          },
          ...rest
        )
      }
      subscriptionEmailLimit={subscriptionEmailLimit}
      teamEmailLimitSource={teamEmailLimitSource}
    />
  );
};

EmailLimits.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  defaultDeliveryChannel: PropTypes.object,
  onTeamEmailLimitFormError: PropTypes.func.isRequired,
  subscriptionEmailLimit: PropTypes.number.isRequired,
  teamEmailLimit: PropTypes.string.isRequired,
  teamEmailLimitSource: PropTypes.string.isRequired,
};

export default EmailLimits;
