import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';

const initCampaignCategoriesState = [];
export const campaignCategories = (
  state = initCampaignCategoriesState,
  { categories = initCampaignCategoriesState, data, id, name, type }
) => {
  let temp = {};
  switch (type) {
    case ActionTypes.data.categories:
      return categories;
    case ActionTypes.campaignsTab.data.addedCategory: {
      temp = cloneDeep(state);
      const allCategory = temp.shift();
      temp.push(data);
      temp = sortBy(temp, (cat) => cat.name.toLowerCase());
      temp.unshift(allCategory);
      return temp;
    }
    case ActionTypes.campaignsTab.data.removeCategory: {
      temp = [...state];
      return temp.filter((category) => category.id !== data);
    }
    case ActionTypes.campaignsTab.data.updateCategory: {
      temp = [...state];
      const updatedIndex = temp.findIndex((curr) => curr.id === id);
      temp[updatedIndex] = { ...temp[updatedIndex], name };
      return temp;
    }
    default:
      return state;
  }
};

export function campaignCategoriesLoading(state = false, action) {
  switch (action.type) {
    case ActionTypes.state.categoriesLoading:
      return true;
    case ActionTypes.state.categoriesLoaded:
      return false;
    default:
      return state;
  }
}

export function currentCategory(state = -1, action) {
  switch (action.type) {
    case ActionTypes.category.data.current:
      return action.data;
    default:
      return state;
  }
}

const initNewCategoryState = {
  isNewCampaign: false,
  name: '',
};

export function campaignsNewCategoryState(
  state = initNewCategoryState,
  action
) {
  switch (action.type) {
    case ActionTypes.campaignsTab.data.newCategoryChange:
      return { ...state, ...action.data };
    case PopupActionTypes.close:
      return initNewCategoryState;
    default:
      return state;
  }
}

export function campaignsUpdatedCategory(state = {}, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.campaignCategoryChange:
      return action.data;
    case PopupActionTypes.close:
      return {};
    default:
      return state;
  }
}

export function editingCampaignCategory(state = null, action) {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.editingCategory:
      return action.id || null;
    default:
      return state;
  }
}
