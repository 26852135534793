import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackgroundColors } from 'libs/constantsStyles';
import keyDownHandler, { keyNames } from 'components/hocs/keyDownHandler';
import { itemShape } from './listShapes';
import './list.scss';

export const listItemShape = itemShape;

class List extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      highlightIndex: -1, // use highlightIndex to allow up/down to highlight and then select
    };

    this.ulRef = null;
    this.liRefs = [];
  }

  componentDidMount() {
    this._scrollToSelected();
  }

  render() {
    const { className } = this.props;

    return (
      <div
        className={classNames('tout-list', className)}
        style={this._getListStyles()}
      >
        <ul className="tout-list-container" ref={this._setUlRef}>
          {this._renderItems()}
        </ul>
      </div>
    );
  }

  _renderItems = () => {
    const {
      highlightColor,
      items,
      itemRenderer,
      maxWidth,
      selected,
      separateItems,
    } = this.props;
    const { highlightIndex } = this.state;

    return items.map((item, index, data) => {
      const { className, disabled, disabledStyle, separator, value } = item;
      const liClassName = classNames(
        'tout-list-item',
        'text-overflow',
        {
          disabled: disabledStyle,
          [`background-${highlightColor}`]: index === highlightIndex,
          'section-separator': separator,
          selected: index === selected,
        },
        className
      );
      const styles = {};

      if (maxWidth) {
        styles.maxWidth = `${maxWidth}px`;
      }

      const returnElements = [
        <li
          className={liClassName}
          data-index={index}
          key={`tout-list-item-${value}`}
          onClick={
            !(disabled || disabledStyle) ? this._handleItemClick : undefined
          }
          onMouseEnter={
            !(disabled || disabledStyle) ? this._handleMouseEnter : undefined
          }
          onMouseDown={
            !(disabled || disabledStyle) ? this._handleMouseDown : undefined
          }
          ref={this._setLiRef}
          style={styles}
        >
          {(separator && <div />) ||
            itemRenderer(item, index, data, this.ulRef)}
        </li>,
      ];

      if (
        separateItems &&
        !separator &&
        index !== data.length - 1 &&
        !data[index + 1].separator
      ) {
        returnElements.push(<div className="tout-list-item-separator" />);
      }

      return returnElements;
    });
  };

  _getListStyles = () => {
    const {
      maxWidth,
      rowsShown,
      rowHeight,
      shownHeight,
      style,
      items,
    } = this.props;
    const itemsHeight = rowHeight * items.length + 1;
    const styles = { ...style };

    if (maxWidth) {
      styles.maxWidth = `${maxWidth}px`;
    }

    // Had to add min-height for ie11
    if (shownHeight) {
      const height = `${Math.min(shownHeight, itemsHeight)}px`;
      styles.height = height;
      styles.minHeight = height;
    } else if (rowsShown) {
      const rowsHeight = rowHeight * rowsShown + 13; // (l-h * rows) + (l-h / 2 + border-top); line-height: 20px + 2px + 2px = 24; half added to show extra is scrollable
      const height = `${Math.min(rowsHeight, itemsHeight)}px`;
      styles.height = height;
      styles.minHeight = height;
    } else {
      const height = `${itemsHeight}px`;
      styles.height = height;
      styles.minHeight = height;
    }

    return styles;
  };

  _setUlRef = (div) => {
    this.ulRef = div;
  };

  _setLiRef = (li) => {
    this.liRefs.push(li);
  };

  handleUp = () => {
    this._increment(false, this.state.highlightIndex, this.props.items);
  };

  handleDown = () => {
    this._increment(true, this.state.highlightIndex, this.props.items);
  };

  handleSelect = () => {
    const { handleSelected, items } = this.props;
    const data = items[this.state.highlightIndex];
    if (data) {
      handleSelected(data, this.state.highlightIndex);
    }
  };

  _handleItemClick = (e) => {
    const { handleSelected, items } = this.props;
    const index = parseInt(e.currentTarget.dataset.index, 10);
    const data = items[index];
    if (data) {
      e.preventDefault();
      e.stopPropagation();
      handleSelected(data, index);
    }
  };

  _handleMouseEnter = (e) => {
    this.setState({
      highlightIndex: parseInt(e.currentTarget.dataset.index, 10),
    });
  };

  _handleMouseDown = (e) => {
    e.preventDefault();
  };

  _scrollToSelected = () => {
    // scroll to selected on opening list
    const { selected } = this.props;

    if (selected > -1 && this.ulRef) {
      this.ulRef.scrollTop = this.liRefs[selected].offsetTop;
    }
  };

  _increment = (down, index, items) => {
    const direction = down ? 1 : -1;
    let next = index + direction;
    if (next >= items.length) {
      next = 0;
    } else if (next < 0) {
      next = items.length - 1;
    }

    if (
      items[next].disabled ||
      items[next].disabledStyle ||
      items[next].separator
    ) {
      this._increment(down, next, items);
    } else {
      this.setState({ highlightIndex: next });
    }
  };

  static itemRenderer({ label }) {
    return <div className="tout-list-item-default">{label}</div>;
  }

  keyDownMap = {
    [keyNames.up]: this.handleUp,
    [keyNames.down]: this.handleDown,
    [keyNames.tab]: this.handleSelect,
    [keyNames.return]: this.handleSelect,
  };
}

List.propTypes = {
  className: PropTypes.string,
  handleSelected: PropTypes.func.isRequired,
  highlightColor: PropTypes.oneOf(Object.values(BackgroundColors)),
  itemRenderer: PropTypes.func,
  items: PropTypes.arrayOf(itemShape).isRequired,
  maxWidth: PropTypes.number,
  rowHeight: PropTypes.number, // required if passing custom itemRenderer
  rowsShown: PropTypes.number,
  selected: PropTypes.number,
  separateItems: PropTypes.bool,
  shownHeight: PropTypes.number,
  style: PropTypes.object,
};

List.defaultProps = {
  className: '',
  highlightColor: BackgroundColors.grayExtraLight,
  itemRenderer: List.itemRenderer,
  maxWidth: 0,
  rowHeight: 30,
  rowsShown: 0, // was 4
  selected: -1,
  separateItems: false,
  shownHeight: 0,
  style: {},
};

export default keyDownHandler(List);

export let UnwrappedList; //eslint-disable-line import/no-mutable-exports
if (process.env.STORYBOOK) {
  // eslint-disable-line no-process-env
  UnwrappedList = List;
}
