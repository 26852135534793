import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import find from 'lodash/find';
import every from 'lodash/every';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import TimeInput from 'components/inputs/timeInput';
import Radio from 'components/buttons/radio';
import Checkbox from 'components/buttons/checkbox';
import Button from 'components/buttons/action';
import Select from 'components/select';
import EmailComposeBody from 'web/emailComposeBody/emailComposeBodyContainer';
import { ProgressStatus } from 'libs/constantsShared';
import { CampaignEmailStepTypes } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import {
  getIncorrectDynamicFields,
  getNotFilledPrompt,
} from 'web/shared/helpers/dynamicFieldsHelper';
import './campaignEditEmailTemplate.scss';
import I18N from 'languages';
import { getValidTime } from 'web/campaigns/campaignsTab/helpers/formatting';
import { CampaignStepType } from 'web/modals/campaignTemplates/libs/campaignTemplatesConstants';

class CampaignEditEmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.id = `edit-email-${Date.now()}`;

    this.state = {
      errorTime: false,
      timeEdited: false,
      validAdd: false,
    };
    this.ckEditorOptions = { readOnly: props.previewMode };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { day, initState, step } = this.props;
    initState(this.id, step || { day });
  }

  componentWillUnmount() {
    const { day, destroyState, step } = this.props;
    destroyState(this.id, step || { day });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    // These are necessary b/c we can't guarantee that campaignTemplates & timezones are present for componentWillMount
    if (
      this.props.campaignTemplates.length === 0 &&
      nextProps.campaignTemplates.length &&
      nextProps.editingStep
    ) {
      const id = parseInt(nextProps.editingStep.action_params.template_id, 10);
      const template = find(nextProps.campaignTemplates, { id }) || {};
      this.props.updateCampaignEditState(this.id, { template });
    }
    if (
      this.props.timezones.length === 0 &&
      nextProps.timezones.length &&
      nextProps.editingStep
    ) {
      const findTimezone =
        find(nextProps.timezones[1], {
          displayName: nextProps.editingStep.action_params.timezone,
        }) || {};

      this.props.updateCampaignEditState(this.id, {
        timezone: findTimezone.value || nextProps.timezone,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.ckeBodies[this.id] !== this.props.ckeBodies[this.id] ||
      !isEqual(
        prevProps.attachments[this.id],
        this.props.attachments[this.id]
      ) ||
      prevProps.campaignEditStates[this.id] !==
        this.props.campaignEditStates[this.id]
    ) {
      this._isValid();
    }
  }

  render() {
    const {
      campaignEditStates,
      editingStep,
      intl: { formatMessage },
      isFirstEmailStep,
      previewMode,
      timezones,
      updateCampaignEditState,
      wide,
    } = this.props;
    const { validAdd } = this.state;
    const editState = campaignEditStates[this.id] || {};
    const localizedTime = this.state.timeEdited
      ? editState.time
      : I18N.getLocalizedTime(getValidTime(editState.time));

    return (
      <div className="campaignEditEmailTemplate">
        <EmailComposeBody
          bcc={editState.bcc}
          cc={editState.cc}
          ckeOptions={this.ckEditorOptions}
          disableEdit={previewMode}
          hideToolbar={previewMode}
          id={this.id}
          selectedTemplate={editState.emailComposeBodySelectedTemplate}
          subject={editState.subject}
          updateState={updateCampaignEditState}
        />
        <div className="margin-bottom-half margin-top-full">
          <h4>
            {formatMessage({
              id: 'web.campaigns.campaignEditEmailTemplate.sendOptions',
            })}
          </h4>
          <Radio
            checked={editState.previousTime}
            classes="margin-bottom-half margin-top-full"
            disabled={previewMode}
            label={formatMessage({
              id: this._getPreviousMessage(isFirstEmailStep),
            })}
            name={`sendPreviousTime-${this.id}`}
            onChange={this.onRadioChange}
            value={CampaignEmailStepTypes.previousTime}
          />
          <Radio
            checked={editState.autoSend}
            classes="margin-bottom-half edit-template-time-radio"
            disabled={previewMode}
            label={
              <div>
                <span className="schedule">
                  <FormattedMessage id="web.campaigns.campaignEditEmailTemplate.scheduleEmail" />
                </span>
                <TimeInput
                  className="margin-left-half edit-template-time-input"
                  disabled={!editState.autoSend}
                  onChange={this.onTimeChange}
                  value={localizedTime}
                />
                <Select
                  className={classNames(
                    'margin-left-half',
                    'edit-template-select',
                    { narrow: !wide }
                  )}
                  disabled={!editState.autoSend}
                  items={timezones}
                  onChange={this.onTimezoneChange}
                  selected={editState.timezone}
                />
              </div>
            }
            name={`sendPreviousTime-${this.id}`}
            onChange={this.onRadioChange}
            value={CampaignEmailStepTypes.schedule}
          />
          <Radio
            checked={editState.manual}
            disabled={previewMode}
            label={formatMessage({
              id: 'web.campaigns.campaignEditEmailTemplate.createTask',
            })}
            name={`autoSend-${this.id}`}
            onChange={this.onRadioChange}
            value={CampaignEmailStepTypes.manual}
          />
        </div>
        {this._getPreviousStepType() === CampaignStepType.sendEmail && (
          <Checkbox
            checked={editState.threadMessages}
            classes="margin-bottom-full"
            disabled={previewMode}
            label={formatMessage({
              id: 'web.campaigns.campaignEditEmailTemplate.threadMessages',
            })}
            name={`threadMessages-${this.id}`}
            onChange={this.onThreadingCheckboxChange}
          />
        )}
        <div className="footer">
          <Button color="gray" disabled={previewMode} onClick={this._onCancel}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            classes="margin-left-full"
            color="blue"
            disabled={previewMode || !validAdd}
            onClick={this._onAction}
          >
            <FormattedMessage
              id={editingStep ? 'common.update' : 'common.add'}
            />
          </Button>
        </div>
      </div>
    );
  }

  _uploadsFinished(uploads = []) {
    if (uploads.length) {
      return every(
        uploads,
        (upload) =>
          upload.id ||
          upload.status === ProgressStatus.error ||
          upload.status === ProgressStatus.issue
      );
    } else {
      return true;
    }
  }

  _isValid = () => {
    const { errorTime } = this.state;
    this.setState({
      validAdd: this._isValidNameBodyAttachments() && !errorTime, //todo check if diff from editingStep data
    });
  };

  _isValidNameBodyAttachments = () => {
    const { attachments, campaignEditStates, ckeBodies } = this.props;
    const editState = campaignEditStates[this.id] || {};
    return (
      editState.subject &&
      editState.subject.length &&
      ckeBodies[this.id] &&
      ckeBodies[this.id].length &&
      this._uploadsFinished(attachments[this.id])
    );
  };

  onRadioChange = (value) => {
    this.props.updateCampaignEditState(this.id, {
      autoSend: value === CampaignEmailStepTypes.schedule,
      manual: value === CampaignEmailStepTypes.manual,
      previousTime: value === CampaignEmailStepTypes.previousTime,
    });
  };

  onTimeChange = (value, error) => {
    this.setState({ timeEdited: true });
    this.props.updateCampaignEditState(this.id, {
      errorTime: error,
      time: value,
    });
  };

  onTimezoneChange = (value) => {
    this.props.updateCampaignEditState(this.id, { timezone: value });
  };

  onThreadingCheckboxChange = (checked) => {
    this.props.updateCampaignEditState(this.id, { threadMessages: checked });
  };

  _onCancel = () => {
    const { editingStep, onCancel } = this.props;
    onCancel(this.id, editingStep);
  };

  _onAction = () => {
    const {
      campaignEditStates,
      editingStep,
      onAction,
      openIncorrectDynamicFieldsPopup,
      openUnfilledPromptsInAutomaticEmailPopup,
      ckeBodies,
      dynamicFields,
    } = this.props;

    const body = ckeBodies[this.id];
    const currentEditState = campaignEditStates[this.id];

    const incorrectDynamicFields = getIncorrectDynamicFields(
      body,
      dynamicFields
    );

    if (incorrectDynamicFields.length) {
      return openIncorrectDynamicFieldsPopup(incorrectDynamicFields);
    }

    // If email is automatic, check unfilled prompts for subject and body
    if (!currentEditState.manual) {
      const notFilledPrompts = getNotFilledPrompt(
        currentEditState.subject
      ).concat(getNotFilledPrompt(body));
      if (notFilledPrompts.length) {
        return openUnfilledPromptsInAutomaticEmailPopup(notFilledPrompts);
      }
    }

    onAction(this.id, editingStep);
    return undefined;
  };

  _getPreviousStepType = () => {
    const {
      selectedCampaign: { steps },
      editingStep,
    } = this.props;
    const numOfSteps = steps.length;
    if (editingStep) {
      //In editing mode current step will be which we are editing so we are doing -2.
      if (numOfSteps > 1) {
        return steps[numOfSteps - 2].action;
      } else {
        return steps[0].action;
      }
    }
    if (numOfSteps > 0) {
      return steps[numOfSteps - 1].action;
    }
    return null;
  };

  _getPreviousMessage = (isFirst) => {
    const prevStepType = this._getPreviousStepType();
    if (!isFirst && prevStepType === CampaignStepType.sendEmail) {
      return 'web.campaigns.campaignEditEmailTemplate.sendAtPreviousTime';
    } else {
      return 'web.campaigns.campaignEditEmailTemplate.chooseAtLaunch';
    }
  };
}

CampaignEditEmailTemplate.propTypes = {
  attachments: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  day: PropTypes.number,
  destroyState: PropTypes.func.isRequired,
  dynamicFields: PropTypes.array.isRequired,
  editingStep: PropTypes.object,
  initState: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isFirstEmailStep: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  openIncorrectDynamicFieldsPopup: PropTypes.func.isRequired,
  openUnfilledPromptsInAutomaticEmailPopup: PropTypes.func.isRequired,
  previewMode: PropTypes.bool,
  selectedCampaign: PropTypes.object.isRequired,
  step: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  updateCampaignEditState: PropTypes.func.isRequired,
  wide: PropTypes.bool,
};

CampaignEditEmailTemplate.defaultProps = {
  day: undefined,
  editingStep: undefined,
  previewMode: undefined,
  step: undefined,
  wide: undefined,
};

export default injectIntl(CampaignEditEmailTemplate);
