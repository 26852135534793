import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import PropTypes from 'prop-types';
import { SalesforceCustomizationPopupIds } from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import CustomizationGuideLinks from './customizationGuideLinks';

class SalesforceCustomizationInstall extends Component {
  render() {
    const {
      isProcessing,
      intl: { formatMessage },
    } = this.props;
    const buttonText = isProcessing
      ? formatMessage({ id: 'web.salesforceCustomization.installing.button' })
      : formatMessage({ id: 'web.salesforceCustomization.install.button' });

    return (
      <div className="salesforce-customization-install">
        <p>
          <FormattedMessage id="web.salesforceCustomization.connect.prelude.robustIntegration" />
        </p>
        <CustomizationGuideLinks />
        <Button
          color={ButtonColors.blue}
          disabled={isProcessing}
          size={ButtonSizes.standard}
          onClick={this.handleClick}
          title={buttonText}
        >
          {buttonText}
        </Button>
      </div>
    );
  }

  handleClick = () => {
    const { actionCreators } = this.props;

    actionCreators.openPopup(
      SalesforceCustomizationPopupIds.salesforceCustomizationPreInstall
    );
  };
}

SalesforceCustomizationInstall.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SalesforceCustomizationInstall);
