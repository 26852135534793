import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActiveTab } from '../selectors/userManagementSelectors';
import UserManagement from '../components/userManagement';
import { navigateToUserManagementTab } from 'web/services/routerService';
import {
  openInviteUsersPopup,
  openMoreSeatsPopup,
  openImportCsv,
} from '../actionCreators/inviteesActionCreators';

class UserManagementContainer extends Component {
  render() {
    return <UserManagement {...this.props} onTabSelect={this.onTabSelect} />;
  }

  onTabSelect = (tab) => {
    navigateToUserManagementTab(tab);
  };
}

const mapStateToProps = (state) => ({
  activeTab: getActiveTab(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        openImportCsv,
        openInviteUsersPopup,
        openMoreSeatsPopup,
      },
      dispatch
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementContainer);
