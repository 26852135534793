import { exportPerson } from 'web/people/services/peopleService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import { getPeopleRequestParamsFetch } from 'web/people/services/peopleRequestParams';
import {
  PEOPLE_GENERAL_GROUPS,
  EXPORT_UNSUBSCRIBED_PER_PAGE,
  InitialPeopleSortParams,
} from 'web/people/libs/peopleConstants';

export const peopleExport = (query) => (dispatch) => {
  dispatch({
    type: PeopleActionTypes.export.loading,
    isLoading: true,
  });

  exportPerson(query)
    .then(() => {
      dispatch({
        type: PeopleActionTypes.export.loading,
        isLoading: false,
      });
      dispatch(openViewAlert(PeopleAlertIds.exportPeopleSuccess));
    })
    .catch(() => {
      dispatch({
        type: PeopleActionTypes.export.loading,
        isLoading: false,
      });
      dispatch(openViewAlert(PeopleAlertIds.exportPeopleError));
    });
};

export const exportPeopleFromGroup = (groupId) => (dispatch, getState) => {
  const { peopleSmartFilterValue, user } = getState();

  const groupExportState = {
    peoplePage: 1,
    peopleSort: InitialPeopleSortParams,
    peopleCurrentGroupId: groupId,
    peopleSmartFilterValue,
    user,
  };

  getPeopleRequestParamsFetch(groupExportState).then((params) => {
    if (params) {
      const exportPeopleFromGroupParams = {
        ...params,
        per_page:
          groupId !== PEOPLE_GENERAL_GROUPS.unsubscribed.id
            ? params.must.id.length
            : EXPORT_UNSUBSCRIBED_PER_PAGE,
        export_group_id: groupId,
      };
      dispatch(peopleExport(exportPeopleFromGroupParams));
    } else {
      dispatch(openViewAlert(PeopleAlertIds.exportPeopleNothingToExport));
    }
  });
};
