import UserActionTypes from 'web/user/libs/userActionTypes';
import findIndex from 'lodash/findIndex';

export const initDefaultIdentity = 0;
export const defaultIdentity = (
  state = initDefaultIdentity,
  { defaultIdentityData = initDefaultIdentity, type }
) => {
  switch (type) {
    case UserActionTypes.setDefaultIdentity:
      return defaultIdentityData || state;
    default:
      return state;
  }
};

export const initIdentities = [];
export function identities(
  state = initIdentities,
  { type, identitiesData = initIdentities, identity, id }
) {
  switch (type) {
    case UserActionTypes.setIdentities:
      return identitiesData;
    case UserActionTypes.addIdentity:
      return [...state, identity];
    case UserActionTypes.updateIdentity: {
      const index = findIndex(state, (it) => it.id === identity.id);
      const newState = [...state];
      newState.splice(index, 1, identity);
      return newState;
    }
    case UserActionTypes.removeIdentity:
      return state.filter((it) => it.id !== id);
    default:
      return state;
  }
}

export function identitiesFetched(state = false, action) {
  switch (action.type) {
    case UserActionTypes.setIdentities:
      return true;
    case UserActionTypes.setIdentitiesFetched:
      return action.fetched;
    default:
      return state;
  }
}

export function identitiesFetching(state = false, action) {
  switch (action.type) {
    case UserActionTypes.setIdentities:
      return false;
    case UserActionTypes.setIdentitiesFetching:
      return true;
    default:
      return state;
  }
}
