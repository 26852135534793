import { createAction } from '@reduxjs/toolkit';
import {
  disableConsentMessage,
  enableConsentMessage,
} from 'web/manageRecordingNotice/services/manageRecordingNoticeService';
import {
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  manageRecordingNoticeGet,
  recordingNoticeAudioGet,
  recordingNoticeDelete,
} from 'web/manageRecordingNotice/actionCreator/manageRecordingNoticeActionCreator';
import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { GeneralPageAlertIds } from 'web/settings/adminSettings/general/libs/generalPageConstants';

export const getRecordingNoticeSuccess = (data) => {
  return {
    data,
    type: ActionTypes.api.manageRecordingNotice.success,
  };
};

export const setActiveRecordingNotice = createAction(
  ActionTypes.api.manageRecordingNotice.setActive
);

export function getRecordingNotice() {
  return (dispatch) => {
    dispatch(setPopupLoading(true));
    dispatch(manageRecordingNoticeGet())
      .then(async (data) => {
        const dataRecordings = await Promise.all(
          data.map(async (recording) => {
            const {
              file_attachment_id,
              file_attachment: { file_file_name, public_key },
            } = recording;

            recording.audioData = await dispatch(
              recordingNoticeAudioGet(
                file_attachment_id,
                public_key,
                file_file_name
              )
            );

            return recording;
          })
        );

        dispatch(getRecordingNoticeSuccess(dataRecordings));
      })
      .catch(() => {})
      .finally(() => dispatch(setPopupLoading(false)));
  };
}

export function getActiveRecordingNotice() {
  return (dispatch) => {
    return dispatch(manageRecordingNoticeGet(true))
      .then(async (data) => {
        if (data && data.length) {
          const recording = data[0];
          const {
            file_attachment_id,
            file_attachment: { file_file_name, public_key },
          } = recording;
          recording.audioData = await dispatch(
            recordingNoticeAudioGet(
              file_attachment_id,
              public_key,
              file_file_name
            )
          );
          dispatch(setActiveRecordingNotice(recording));
        } else {
          dispatch(setActiveRecordingNotice(null));
        }
      })
      .catch(() => {
        // TODO Handle error case
      })
      .finally(() => {
        dispatch(setPopupLoading(false));
      });
  };
}

export const changeEnableStateOfConsentMessage = (id, active) => {
  return (dispatch) => {
    dispatch(setPopupLoading(true));
    const changeEnableStateOfConsentMessageCall = active
      ? enableConsentMessage
      : disableConsentMessage;
    changeEnableStateOfConsentMessageCall(id, active)
      .then(async () => {
        await Promise.all([
          dispatch(getRecordingNotice()),
          dispatch(getActiveRecordingNotice()),
        ]);
      })
      .catch(() => {
        if (active) {
          dispatch(setPopupAlert(GeneralPageAlertIds.fileUploadBigSizeError));
        } else {
          dispatch(
            setPopupAlert(GeneralPageAlertIds.disableConsentMessageError)
          );
        }
      })
      .finally(() => {
        dispatch(setPopupLoading(false));
      });
  };
};

export function deleteRecordingAudio(id) {
  return (dispatch) => {
    dispatch(setPopupLoading(true));
    dispatch(recordingNoticeDelete(id))
      .catch((err) => err)
      .finally(() => {
        dispatch(setPopupLoading(false));
      });
  };
}
