import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import './yourIntegrations.scss';
import {
  getMarketoIntegrationStatus,
  getSalesforceIntegrationStatus,
  getReplyTrackingStatus,
  getPrimaryDeliveryChannelStatus,
} from '../helpers/yourIntegrationsHelpers';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';

export default function YourIntegrations(props) {
  const {
    currentDeliveryChannel,
    defaultIdentity,
    deliveryChannels,
    fetching,
    statuses,
    isAdmin,
    isAdminMarketoAuthEnabled,
    salesforceConnectLink,
  } = props;

  const data = {
    ...statuses,
    currentDeliveryChannel,
    defaultIdentity,
    deliveryChannels,
    isAdmin,
    isAdminMarketoAuthEnabled,
  };

  useEffect(() => {
    fetchIntegrationsData(props);
  }, []);

  return (
    <div className="my-profile-your-integrations">
      <h3 className="my-profile-your-integrations-title">
        <FormattedMessage id="web.settings.myProfile.yourIntegrations.header" />
      </h3>
      {fetching && <LoadingSpinner imageUrl={getImageUrl} />}
      {statuses && (
        <ul className="my-profile-your-integrations-list">
          <li>{showPrimaryDeliveryChannelHealth(data)}</li>
          <li>{showReplyTrackingStatus(data)}</li>
          <li>{showSalesforceHealth(data, salesforceConnectLink)}</li>
          <li>{showMarketoHealth(data)}</li>
        </ul>
      )}
    </div>
  );
}

// made a separate function to guarantee order of execution inside effect
async function fetchIntegrationsData({
  getDeliveryChannels,
  getIdentities,
  initializeHealthStatuses,
}) {
  await initializeHealthStatuses();
  getDeliveryChannels();
  getIdentities();
}

function showMarketoHealth(data) {
  return renderStatus(getMarketoIntegrationStatus(data));
}

function showSalesforceHealth(data, salesforceConnectLink) {
  return renderStatus(
    getSalesforceIntegrationStatus(data, salesforceConnectLink)
  );
}

function showReplyTrackingStatus(data) {
  return renderStatus(getReplyTrackingStatus(data));
}

function showPrimaryDeliveryChannelHealth(data) {
  return renderStatus(getPrimaryDeliveryChannelStatus(data));
}

function renderStatus(result) {
  if (!result) {
    return null;
  }
  const { icon, message, ...values } = result;
  return (
    <>
      <i className={`integration-icon integration-${icon}`} />
      <span className="integration-text">
        <FormattedHTMLMessage id={message} values={values} />
      </span>
    </>
  );
}

YourIntegrations.propTypes = {
  currentDeliveryChannel: PropTypes.object,
  defaultIdentity: PropTypes.object,
  deliveryChannels: PropTypes.array,
  fetching: PropTypes.bool,
  getDeliveryChannels: PropTypes.func.isRequired,
  getIdentities: PropTypes.func.isRequired,
  initializeHealthStatuses: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAdminMarketoAuthEnabled: PropTypes.bool.isRequired,
  salesforceConnectLink: PropTypes.string.isRequired,
  statuses: PropTypes.object,
};

YourIntegrations.defaultProps = {
  currentDeliveryChannel: null,
  defaultIdentity: {},
  deliveryChannels: [],
  fetching: false,
  statuses: {},
};
