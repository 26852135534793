import { getContactIntegrations } from 'web/contactIntegrations/selectors/contactIntegrationsSelectors';
import { createSelector } from 'reselect';
import { boxName } from '../libs/boxConstants';
import get from 'lodash/get';
import find from 'lodash/find';

export const getBoxContactIntegration = createSelector(
  [getContactIntegrations],
  (contactIntegrations) => find(contactIntegrations, { name: boxName })
);

export const isBoxConnected = createSelector(
  [getBoxContactIntegration],
  (boxContactIntegration) =>
    get(boxContactIntegration, 'valid_credentials?', false)
);

export const getBoxIntegrationFetching = (state) =>
  state.entities.integrations.box.fetching;
