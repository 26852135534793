import { basePost } from 'web/services/api';

export const savePeopleSource = (peopleIds, source) =>
  new Promise((resolve, reject) => {
    basePost(
      `/compliances/bulk_upsert`,
      {
        people_ids: peopleIds,
        compliance: { source },
      },
      resolve,
      reject
    );
  });
