import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import './emailsStats.scss';

const MAX_COUNT = 999;

class EmailsStats extends PureComponent {
  componentWillMount() {
    const { formatMessage } = this.props.intl;
    this.archivedTitle = formatMessage({ id: 'common.archived' });
    this.viewsTitle = formatMessage({ id: 'common.views' });
    this.clicksTitle = formatMessage({ id: 'common.clicks' });
    this.repliesTitle = formatMessage({ id: 'common.replies' });
    this.successTitle = formatMessage({ id: 'common.success' });
  }

  render() {
    const {
      archived,
      clicks,
      isSuccess,
      openEvents,
      replies,
      views,
    } = this.props;
    const disableAll = !(views || replies || clicks || isSuccess);
    const className = classNames('flex', {
      'email-stats-archived': archived,
      'email-stats-clickable': openEvents,
      'tout-icon-grey': disableAll,
    });

    return (
      <div className="emails-stats">
        {archived && (
          <div className="tout-icon-archived" title={this.archivedTitle} />
        )}
        <div className={className} onClick={openEvents && this._openEvents}>
          <div
            className={classNames('tout-icon-views emails-stat', {
              'tout-icon-grey': disableAll,
            })}
            title={this.viewsTitle}
          >
            {this._round(views)}
          </div>
          <div
            className={classNames('tout-icon-clicks emails-stat', {
              'tout-icon-grey': disableAll,
            })}
            title={this.clicksTitle}
          >
            {this._round(clicks)}
          </div>
          <div
            className={classNames('tout-icon-replies emails-stat', {
              'tout-icon-grey': disableAll,
            })}
            title={this.repliesTitle}
          >
            {this._round(replies)}
          </div>
          <div
            className={classNames('tout-icon-success', {
              'tout-icon-grey': disableAll,
            })}
            title={this.successTitle}
          />
        </div>
      </div>
    );
  }

  _round(value) {
    return (value > MAX_COUNT && '999+') || value;
  }

  _openEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { id, openEvents } = this.props;
    openEvents(id);
  };
}

EmailsStats.propTypes = {
  archived: PropTypes.bool,
  clicks: PropTypes.number,
  id: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  isSuccess: PropTypes.bool,
  openEvents: PropTypes.func,
  replies: PropTypes.number,
  views: PropTypes.number,
};

EmailsStats.defaultProps = {
  archived: false,
  clicks: 0,
  isSuccess: false,
  openEvents: null,
  replies: 0,
  views: 0,
};

export default injectIntl(EmailsStats);
