import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { setPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import { handlePeopleSearchAddResize } from 'web/people/peopleSearchAdd/actionCreators/popupActionCreators';
import {
  formatPerson,
  parseIndividualPersonsToMap,
  parseAndFormatPeopleForAddPeople,
} from 'web/people/peopleSearchAdd/helpers/parsers';

export function addPersonObject(person) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.autocomplete.state.value, data: '' });
    dispatch({
      type: PeopleActionTypes.peopleSearchAdd.addPerson,
      data: formatPerson(person),
    });

    dispatch(handlePeopleSearchAddResize());
  };
}

export function addPeoplePerson(person) {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.autocomplete.data.clear });
    if (
      parseIndividualPersonsToMap(getState().peopleSearchAddState.people)[
        person.id
      ]
    ) {
      dispatch(
        setPopupAlert(PeopleAlertIds.peopleSearchAdd.personAlreadyIncluded)
      );
    } else {
      dispatch(addPersonObject(person));
    }
  };
}

export function addPeopleOnChange(checked, id, isGroupClick) {
  return {
    type: isGroupClick
      ? PeopleActionTypes.peopleSearchAdd.updateGroup
      : PeopleActionTypes.peopleSearchAdd.updatePerson,
    id,
    checked,
  };
}

export const parseAndAddPeople = (people) => (dispatch, getState) => {
  const {
    peopleSearchAddState: { people: currentPeople },
  } = getState();
  const { hasDupe, members } = parseAndFormatPeopleForAddPeople(
    people,
    currentPeople
  );

  if (hasDupe) {
    dispatch(setPopupAlert(PeopleAlertIds.peopleSearchAdd.miscAlreadyIncluded));
  }

  dispatch({
    type: PeopleActionTypes.peopleSearchAdd.addPeople,
    people: members,
  });
};
