import React from 'react';
import PropTypes from 'prop-types';

const Tab = (props) => (
  <div className="productTour-tab-panel">{props.children}</div>
);

Tab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string,
};

export default Tab;
