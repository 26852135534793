export default {
  closeAlert: 'MARKETO_SETTINGS_CLOSE_ALERT',
  connectionInfo: 'MARKETO_SETTINGS_GET_CONNECTION_INFO',
  getConnection: 'MARKETO_SETTINGS_GET_CONNECTION',
  getConnectionFailed: 'MARKETO_SETTINGS_GET_CONNECTION_FAILED',
  getConnectionFetched: 'MARKETO_SETTINGS_GET_CONNECTION_FETCHED',
  getConnectionFetching: 'MARKETO_SETTINGS_GET_CONNECTION_FETCHING',
  getConnectionNull: 'MARKETO_SETTINGS_GET_CONNECTION_NULL',
  getConnectionSuccess: 'MARKETO_SETTINGS_GET_CONNECTION_SUCCESS',
  hasConnection: 'MARKETO_SETTINGS_HAS_CONNECTION',
  noSalesforceConnection: 'MARKETO_SETTINGS_NO_SALESFORCE_CONNECTION',
  setup: {
    alreadyExists: 'MARKETO_SETTINGS_ALREADY_EXISTS',
    connect: 'MARKETO_SETTINGS_SETUP_CONNECT',
    connectSuccess: 'MARKETO_SETTINGS_SETUP_CONNECT_SUCCESS',
    disconnect: 'MARKETO_SETTINGS_SETUP_DISCONNECT',
    disconnectFailed: 'MARKETO_SETTINGS_SETUP_DISCONNECT_FAILED',
    disconnectSuccess: 'MARKETO_SETTINGS_SETUP_DISCONNECT_SUCCESS',
  },
  tabSelected: {
    settings: 'MARKETO_SETTINGS_SETTINGS_TAB_SELECTED',
    setup: 'MARKETO_SETTINGS_SETUP_TAB_SELECTED',
    userAccess: 'MARKETO_SETTINGS_TEAM_SETTINGS_TAB_SELECTED',
  },
  userAccess: {
    allSelectedUsersConnected:
      'MARKETO_SETTINGS_TEAM_ACCESS_ALL_SELECTED_USERS_CONNECTED',
    allSelectedUsersDisconnected:
      'MARKETO_SETTINGS_TEAM_ACCESS_ALL_SELECTED_USERS_DISCONNECTED',
    getMarketoAuthenticationUsers: 'MARKETO_GET_AUTHENTICATION_USERS',
    getSubscriptionUsers: 'MARKETO_SETTINGS_TEAM_ACCESS_GET_SUBSCRITPION_USERS',
    getSubscriptionUsersSuccess:
      'MARKETO_SETTINGS_TEAM_ACCESS_GET_SUBSCRITPION_USERS_SUCESS',
    grantAccess: 'MARKETO_SETTINGS_TEAM_ACCESS_GRANT_ACCESS',
    grantAccessFail: 'MARKETO_SETTINGS_TEAM_ACCESS_GRANT_ACCESS_FAIL',
    grantAccessSomeFailed:
      'MARKETO_SETTINGS_TEAM_ACCESS_GRANT_ACCESS_SOME_FAILED',
    grantAccessSuccess: 'MARKETO_SETTINGS_TEAM_ACCESS_GRANT_ACCESS_SUCCESS',
    page: 'MARKETO_SETTINGS_TEAM_ACCESS_PAGE',
    revokeAccess: 'MARKETO_SETTINGS_TEAM_ACCESS_REVOKE_ACCESS',
    revokeAccessFail: 'MARKETO_SETTINGS_TEAM_ACCESS_REVOKE_ACCESS_SUCCESS_FAIL',
    revokeAccessSomeFailed:
      'MARKETO_SETTINGS_TEAM_ACCESS_REVOKE_ACCESS_SUCCESS_SOME_FAILED',
    revokeAccessSuccess: 'MARKETO_SETTINGS_TEAM_ACCESS_REVOKE_ACCESS_SUCCESS',
    smartFilter: 'MARKETO_SETTINGS_TEAM_ACCESS_SMART_FILTER',
  },
  workspacesModal: {
    getWorkspaces: 'MARKETO_SETTINGS_WORKSPACES_MODAL_GET_WORKSPACES',
    getWorkspacesFailed:
      'MARKETO_SETTINGS_WORKSPACES_MODAL_GET_WORKSPACES_FAIL',
    getWorkspacesFetched:
      'MARKETO_SETTINGS_WORKSPACES_MODAL_FETCHED_WORKSPACES',
    getWorkspacesFetching:
      'MARKETO_SETTINGS_WORKSPACES_MODAL_FETCHING_WORKSPACES',
    getWorkspacesSuccess:
      'MARKETO_SETTINGS_WORKSPACES_MODAL_GET_WORKSPACES_SUCCESS',
    updateWorkspaces: 'MARKETO_SETTINGS_WORKSPACES_MODAL_UPDATE_WORKSPACES',
  },
};
