import { combineReducers } from 'redux';
import * as userManagementReducers from './userManagementReducers';
import inviteesRootReducer from './inviteesRootReducer';
import usersRootReducer from './usersRootReducer';

export default combineReducers({
  ...userManagementReducers,
  invitees: inviteesRootReducer,
  users: usersRootReducer,
});
