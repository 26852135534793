import {
  DO_NOT_IMPORT_FIELD_ID,
  ADD_NEW_FIELD,
  PEOPLE_FETCH_REQUEST_SEARCH_COLUMNS,
  EMAIL_FIELDS,
} from 'web/people/libs/peopleConstants';
import I18N from 'languages';

export const transformCsvField = (field) =>
  field
    .toLowerCase()
    .trim()
    .replace(/  +/g, ' ')
    .replace(/ /g, '_');

export const transformFirstRecord = (field) => field.replace(/^"(.*)"$/, '$1');

export const getRowFields = (rows) => {
  const fields = [];

  if (rows.length > 1) {
    const [firstRowData, secondRowData] = rows;

    if (firstRowData.length > 1) {
      for (let i = 0; i < firstRowData.length; i++) {
        const fieldData = {
          csvField: transformCsvField(firstRowData[i]),
          firstRecord: transformFirstRecord(secondRowData[i]),
          salesConnectField: DO_NOT_IMPORT_FIELD_ID,
        };

        fields.push(fieldData);
      }

      return fields;
    }
  }

  return null;
};

const getInviteesCsvColumns = (field) =>
  PEOPLE_FETCH_REQUEST_SEARCH_COLUMNS.includes(field) ||
  EMAIL_FIELDS.includes(field);

export const filterInviteUsersCsvFields = (fields) => {
  return fields.filter(({ csvField }) => getInviteesCsvColumns(csvField));
};

export const filterInviteUsersSalesConnectFields = (fields) => {
  return fields.filter((field) => getInviteesCsvColumns(field));
};

export const getEmailIdsFromRowFields = (rows) => {
  //include invalid emailids
  let emailIds = [];
  for (let i = 0; i < rows.length; i++) {
    Array.isArray(rows[i]) &&
      rows[i].forEach((item) => {
        if (item.match('@')) {
          emailIds.push(item);
        }
      });
  }
  return emailIds;
};

export const mapFields = (
  { to, from, existing_custom_fields: existingCustomFields },
  importFieldMaps
) =>
  importFieldMaps.reduce((result, { csvField, firstRecord }, index) => {
    if (!from.includes(csvField) && !existingCustomFields.includes(csvField)) {
      return result;
    }

    return [
      ...result,
      {
        csvField,
        firstRecord,
        id: index,
        salesConnectField:
          to.includes(csvField) || existingCustomFields.includes(csvField)
            ? csvField
            : DO_NOT_IMPORT_FIELD_ID,
      },
    ];
  }, []);

export const getSalesConnectFieldList = (
  items,
  customItems,
  fromUserManagementPage
) => {
  const salesConnectFieldList = items.map((item) => ({
    label: item,
    value: item,
  }));

  const salesConnectCustomFieldList = customItems.map((item) => ({
    label: item,
    value: item,
  }));

  if (fromUserManagementPage) {
    return [
      ...salesConnectFieldList,
      {
        separator: true,
      },
      {
        className: 'text-bold',
        label: I18N.getStr('web.people.importer.doNotImport'),
        value: DO_NOT_IMPORT_FIELD_ID,
      },
    ];
  }
  return [
    ...salesConnectFieldList,
    {
      separator: true,
    },
    {
      disabled: true,
      label: I18N.getStr('web.people.importer.customFields'),
    },
    {
      boldSelect: true,
      className: 'text-bold',
      label: I18N.getStr('web.people.importer.addNewField'),
      value: ADD_NEW_FIELD,
    },
    ...salesConnectCustomFieldList,
    {
      separator: true,
    },
    {
      className: 'text-bold',
      label: I18N.getStr('web.people.importer.doNotImport'),
      value: DO_NOT_IMPORT_FIELD_ID,
    },
  ];
};
