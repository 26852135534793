import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import {
  EditTemplateCards,
  EditTemplateEmailsStateSelect,
  EditTemplateEmailsTeamsSelect,
} from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import LoadingSpinner from 'components/loadingSpinner';
import SmartFilter from 'components/selects/smartFilter';
import { isMyTeamFilterEnabled } from 'web/shared/services/accountService';
import EmailCard from '../emailCard';
import Cards, { Direction } from 'components/layouts/cards';
import './emailsView.scss';

const STATE_FILTER_WIDTH = 320;
const TEAM_FILTER_WIDTH = 250;

class EmailsView extends Component {
  componentWillMount() {
    const {
      actionCreators: { initEmailsView },
      intl: { formatMessage },
    } = this.props;

    initEmailsView();

    this.teamsFilters = [
      {
        label: formatMessage({ id: 'web.editTemplateSlideOut.emailsView.me' }),
        value: EditTemplateEmailsTeamsSelect.me,
      },
    ];

    if (isMyTeamFilterEnabled()) {
      this.teamsFilters.push({
        label: formatMessage({
          id: 'web.editTemplateSlideOut.emailsView.team',
        }),
        value: EditTemplateEmailsTeamsSelect.team,
      });
    }

    this.stateFilters = [
      {
        label: formatMessage({
          id: 'web.editTemplateSlideOut.emailsView.recentEmails',
        }),
        value: EditTemplateEmailsStateSelect.recentEmails,
      },
      {
        label: formatMessage({
          id: 'web.editTemplateSlideOut.emailsView.recentEngagement',
        }),
        value: EditTemplateEmailsStateSelect.recentEngagement,
      },
      {
        label: formatMessage({
          id: 'web.editTemplateSlideOut.emailsView.noEngagement',
        }),
        value: EditTemplateEmailsStateSelect.noEngagement,
      },
      {
        label: formatMessage({ id: 'common.viewed' }),
        value: EditTemplateEmailsStateSelect.viewed,
      },
      {
        label: formatMessage({ id: 'common.clicked' }),
        value: EditTemplateEmailsStateSelect.clicked,
      },
      {
        label: formatMessage({ id: 'common.replied' }),
        value: EditTemplateEmailsStateSelect.replied,
      },
      {
        label: formatMessage({ id: 'common.success' }),
        value: EditTemplateEmailsStateSelect.success,
      },
    ];
  }

  render() {
    const {
      actionCreators: { setEmailsTeamsSelect, setEmailsStateSelect },
      stateSelect,
      teamsSelect,
      loading,
    } = this.props;

    return (
      <div className="edit-template-emails-view">
        {loading && <LoadingSpinner imageUrl={getImageUrl} />}
        <div className="emails-view-filters">
          <SmartFilter
            className="emails-view-smart-filter"
            filters={this.teamsFilters}
            labelId="web.editTemplateSlideOut.emailsView.teamsSelect"
            onChange={setEmailsTeamsSelect}
            scrollTop={this.scrollTop}
            selectedValue={
              isMyTeamFilterEnabled()
                ? teamsSelect
                : EditTemplateEmailsTeamsSelect.me
            }
            width={TEAM_FILTER_WIDTH}
          />
          <SmartFilter
            className="emails-view-smart-filter"
            filters={this.stateFilters}
            onChange={setEmailsStateSelect}
            scrollTop={this.scrollTop}
            selectedValue={stateSelect}
            width={STATE_FILTER_WIDTH}
          />
        </div>
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={this._getCards()}
          direction={Direction.vertical}
          maxWidth={0}
          minWidth={0}
          marginAround={FULL}
          marginBetween={FULL}
          ref={this.setScrollRef}
        />
      </div>
    );
  }

  setScrollRef = (cards) => {
    this.scrollRef = ReactDOM.findDOMNode(cards);
  };

  scrollTop = () => {
    this.scrollRef.scrollTop = 0;
  };

  _getCards = () => {
    const {
      actionCreators: {
        archiveEmail,
        openAddAsTemplate,
        openEvents,
        openFollowUp,
        unarchiveEmail,
      },
      emails,
      loading,
      user,
      viewer,
    } = this.props;

    if (emails.length) {
      return emails.map((email = {}) => ({
        children: (
          <EmailCard
            addAsTemplate={openAddAsTemplate}
            archiveEmail={archiveEmail}
            email={email}
            openEvents={openEvents}
            openFollowUp={openFollowUp}
            unarchiveEmail={unarchiveEmail}
            user={user}
            viewer={viewer}
          />
        ),
        id: `${EditTemplateCards.email}-${email.id}`,
      }));
    } else if (!loading) {
      return [
        {
          children: (
            <div className="text-center">
              <FormattedMessage id="web.editTemplateSlideOut.emailsView.empty" />
            </div>
          ),
          id: `${EditTemplateCards.email}-empty`,
        },
      ];
    } else {
      return [];
    }
  };
}

EmailsView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  emails: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool.isRequired,
  stateSelect: PropTypes.string.isRequired,
  teamsSelect: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
};

EmailsView.defaultProps = {};

export default injectIntl(EmailsView);
