import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ChipEdit from './chipEdit';
import ChipView from './chipView';
import './chip.scss';

const Chip = React.forwardRef((props, ref) => {
  const { className, editing, ...other } = props;

  return (
    <div className={classNames('tout-chip', className)}>
      <Fragment>
        {editing ? (
          <ChipEdit inputRef={ref} {...other} />
        ) : (
          <ChipView {...other} />
        )}
      </Fragment>
    </div>
  );
});

Chip.propTypes = {
  className: PropTypes.string,
  editing: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onRemovePrevious: PropTypes.func,
  stopPattern: PropTypes.objectOf(RegExp),
};

Chip.defaultProps = {
  className: '',
  editing: false,
  value: '',
  onChange: () => {},
  valid: false,
  selected: false,
  onSelect: () => {},
  onEdit: () => {},
  onRemove: () => {},
  onRemovePrevious: () => {},
  stopPattern: null,
};

export default Chip;
