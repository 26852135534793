import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import Popup from 'components/popups/popup';
import { PopupSize } from 'libs/constantsShared';
import { EmailComposePopupIds } from '../../libs/composeEmailPopupConstants';
import { getEmailFromResponse } from '../../helpers/composeEmailHelpers';

class EmailVerificationRequiredPopup extends Component {
  state = {
    sendingVerification: false,
  };

  render() {
    const {
      actionCreators: { closePopup },
      options: {
        content: { responseText },
      },
    } = this.props;
    const { sendingVerification } = this.state;

    return (
      <Popup
        className="compose-email-popup"
        footer={{
          onPrimary: this.resendVerification,
          onSecondary: closePopup,
          primaryTextId:
            'web.composeEmail.popup.emailVerificationRequired.button',
          secondaryTextId: 'common.cancel',
          primaryDisabled: sendingVerification,
        }}
        header={{
          onClose: closePopup,
          textId: 'web.composeEmail.popup.emailVerificationRequired.header',
        }}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedHTMLMessage
          id="web.composeEmail.popup.emailVerificationRequired.body"
          values={{ email: getEmailFromResponse(responseText) }}
        />
      </Popup>
    );
  }

  resendVerification = () => {
    const {
      options: {
        content: { identityId },
      },
      actionCreators: { resendVerificationMail },
    } = this.props;

    this.setState({ sendingVerification: true });

    resendVerificationMail({
      identityId,
      target: EmailComposePopupIds.emailVerificationRequired,
    });
  };
}

EmailVerificationRequiredPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EmailVerificationRequiredPopup);
