import SalesforceActionTypes from '../libs/salesforceActionTypes';
import { combineReducers } from 'redux';

export const item = (state = {}, action = {}) => {
  switch (action.type) {
    case SalesforceActionTypes.setAccount:
      return action.account || state;
    default:
      return state;
  }
};

export const fetched = (state = false, action = {}) => {
  switch (action.type) {
    case SalesforceActionTypes.setAccountFetched:
      return action.fetched;
    default:
      return state;
  }
};

export const fetching = (state = false, action = {}) => {
  switch (action.type) {
    case SalesforceActionTypes.setAccountFetching:
      return action.fetching;
    default:
      return state;
  }
};

export default combineReducers({ item, fetched, fetching });
