import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';

import AdvancedSearchHeader from './advancedSearchHeader';
import SavedSearchHeader from './savedSearchHeader';
import GroupEmailHeader from './groupEmailHeader';
import TabsHeader from './tabsHeader';

import './commandCenterEmailsHeader.scss';

const CommandCenterEmailsHeader = ({
  actionCreators,
  currentLimit,
  selectedTabValue,
  selectedSubTabValue,
  selectedSaveSearchName,
  selectedGroupEmailName,
  tableId,
  totalLimit,
  unreadEmailsCounters,
}) => {
  let Title;
  let Body;

  switch (selectedSubTabValue) {
    case CommandCenterEmailsTabsEnum.search.subTabs.advanced.value:
      Title = <AdvancedSearchHeader.Title actionCreators={actionCreators} />;
      Body = <AdvancedSearchHeader.Body actionCreators={actionCreators} />;
      break;
    case CommandCenterEmailsTabsEnum.search.subTabs.saved.value:
      Title = (
        <SavedSearchHeader.Title
          selectedSaveSearchName={selectedSaveSearchName}
        />
      );
      Body = (
        <SavedSearchHeader.Body
          actionCreators={actionCreators}
          tableId={tableId}
        />
      );
      break;
    case CommandCenterEmailsTabsEnum.search.subTabs.groupEmail.value:
      Title = (
        <GroupEmailHeader.Title
          selectedGroupEmailName={selectedGroupEmailName}
        />
      );
      break;
    default:
      Title = <TabsHeader.Title />;
      Body = (
        <TabsHeader.Body
          actionCreators={actionCreators}
          selectedSubTabValue={selectedSubTabValue}
          selectedTabValue={selectedTabValue}
          unreadEmailsCounters={unreadEmailsCounters}
        />
      );
  }

  return (
    <div className="command-center-emails-header">
      <div className="command-center-emails-header-title">
        <h1>
          {Title}
          <span className="command-center-emails-limit">
            {currentLimit !== null &&
              totalLimit !== null && (
                <FormattedMessage
                  id="web.emails.limit"
                  values={{
                    current: currentLimit,
                    total: totalLimit,
                  }}
                />
              )}
          </span>
        </h1>
      </div>
      <div className="command-center-emails-header-body">{Body}</div>
    </div>
  );
};

CommandCenterEmailsHeader.defaultProps = {
  currentLimit: null,
  totalLimit: null,
};

CommandCenterEmailsHeader.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  currentLimit: PropTypes.number,
  selectedGroupEmailName: PropTypes.string.isRequired,
  selectedSaveSearchName: PropTypes.string.isRequired,
  selectedSubTabValue: PropTypes.string.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
  totalLimit: PropTypes.number,
  unreadEmailsCounters: PropTypes.object.isRequired,
};

export default CommandCenterEmailsHeader;
