import React from 'react';
import PropTypes from 'prop-types';
import I18N from 'languages';
import {
  DELIVERY_CHANNEL_VALUE_LABEL_MAP,
  DEFAULT_DELIVERY_CHANNEL,
  DEFAULT_SENDGRID_DELIVERY_CHANNEL,
  GMAIL_PLUGIN_PREFIX,
  OUTLOOK_PLUGIN_PREFIX,
} from 'web/commandCenter/components/commandCenterEmailsTable/deliveryChannelCell/deliveryChannelCellConstants';
import './deliveryChannelCell.scss';

const DeliveryChannelCell = ({
  rowData: { delivery_channel: deliveryChannel },
}) => {
  const getDeliveryChannelLabel = () => {
    const deliveryChannelLabel =
      DELIVERY_CHANNEL_VALUE_LABEL_MAP[deliveryChannel];
    if (deliveryChannelLabel) {
      return deliveryChannelLabel;
    }
    if (
      deliveryChannel === DEFAULT_DELIVERY_CHANNEL ||
      deliveryChannel === DEFAULT_SENDGRID_DELIVERY_CHANNEL
    ) {
      return I18N.getStr('common.default');
    }
    if (deliveryChannel.includes(GMAIL_PLUGIN_PREFIX)) {
      return 'Gmail.com';
    }
    if (deliveryChannel.includes(OUTLOOK_PLUGIN_PREFIX)) {
      return 'Outlook';
    }
    return I18N.getStr('common.custom');
  };

  return (
    <div className="flex flex--align-middle">
      <div className="delivery-channel-cell">{getDeliveryChannelLabel()}</div>
    </div>
  );
};

DeliveryChannelCell.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default DeliveryChannelCell;
