import React from 'react';
import { connect } from 'react-redux';
import Box from '../components/box';
import { isBoxConnected } from 'web/integrations/box/selectors/boxSelectors';
import {
  openDeleteBoxConfirmation,
  openNavigateToBoxConfirmation,
} from '../actionCreators/boxActionCreators';

const BoxContainer = (props) => <Box {...props} />;

const mapStateToProps = (state) => ({
  isConnected: isBoxConnected(state),
});

const mapDispatchToProps = {
  openDeleteBoxConfirmation,
  openNavigateToBoxConfirmation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoxContainer);
