import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import Page from 'components/layouts/page';
import View from 'components/layouts/page/view';
import LoadingSpinner from 'components/loadingSpinner';
import FormView from './formView';
import SlideOutPaneActionBar from 'components/layouts/slideOutPane/actionBar';
import AddContactSlideOutHeader from 'web/slideOuts/addContact/components/addContactSlideOutHeader';
import TabAlert from '../personDetails/tabAlert';

class AddContact extends Component {
  render() {
    const {
      actionCreators,
      tabAlert,
      tabLoading,
      oldestAdminId,
      onBack,
      userSubscriptionUsers,
    } = this.props;

    return (
      <Page className="person-details">
        <SlideOutPaneActionBar onBack={onBack} buttons={this._getButtons()} />
        <AddContactSlideOutHeader />
        <View className="person-details-page-view">
          {tabLoading && <LoadingSpinner imageUrl={getImageUrl} />}
          <TabAlert
            actionCreators={actionCreators}
            alert={tabAlert}
            adminData={userSubscriptionUsers[oldestAdminId]}
          />
          {this._getView()}
        </View>
      </Page>
    );
  }

  _getButtons = () => {
    const { tabLoading } = this.props;
    return [
      {
        onClick: this.props.actionCreators.addPerson,
        textId: 'common.create',
        disabled: tabLoading,
      },
      {
        onClick: this.props.actionCreators.createPersonAndAddNew,
        textId: 'web.people.createAndAddNewContact.btn',
        disabled: tabLoading,
      },
    ];
  };

  _getView = () => {
    const {
      actionCreators,
      customFieldsCardHasChanges,
      customFieldsEditState,
      currentPeopleGroupId,
      currentPeopleGroupName,
      editing,
      groupsAddable,
      groupsCardHasChanges,
      groupsEditState,
      infoCardHasChanges,
      infoEditState,
      person,
      user,
      salesforceRequiredFields,
    } = this.props;

    return (
      <FormView
        actionCreators={actionCreators}
        customFieldsCardHasChanges={customFieldsCardHasChanges}
        customFieldsEditState={customFieldsEditState}
        currentPeopleGroupId={currentPeopleGroupId}
        currentPeopleGroupName={currentPeopleGroupName}
        editing={editing}
        groupsAddable={groupsAddable}
        groupsCardHasChanges={groupsCardHasChanges}
        groupsEditState={groupsEditState}
        infoCardHasChanges={infoCardHasChanges}
        infoEditState={infoEditState}
        person={person}
        user={user}
        salesforceRequiredFields={salesforceRequiredFields}
      />
    );
  };
}

AddContact.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  currentPeopleGroupId: PropTypes.string.isRequired,
  currentPeopleGroupName: PropTypes.string.isRequired,
  customFieldsCardHasChanges: PropTypes.bool.isRequired,
  customFieldsEditState: PropTypes.array.isRequired,
  editing: PropTypes.object.isRequired,
  groupsAddable: PropTypes.array.isRequired,
  groupsCardHasChanges: PropTypes.bool.isRequired,
  groupsEditState: PropTypes.object.isRequired,
  infoCardHasChanges: PropTypes.bool.isRequired,
  oldestAdminId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  salesforceRequiredFields: PropTypes.object.isRequired,
  tabAlert: PropTypes.object.isRequired,
  tabLoading: PropTypes.bool.isRequired,
  infoEditState: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userSubscriptionUsers: PropTypes.object.isRequired,
};

export default injectIntl(AddContact);
