import React, { Component } from 'react';
import { bool, object, array } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import AnchorBox from 'components/layouts/anchorBox';
import AnchorBoxHeader from 'components/layouts/anchorBox/header';
import ReleaseNotes from '../releaseNotes';
import './releaseNoteAnchorBox.scss';

class ReleaseNoteAnchorBox extends Component {
  render() {
    const {
      actionCreators: { expandAnchorBox, contractAnchorBox },
      open,
      visible,
      releaseNotes,
    } = this.props;

    const toggleAnchorboxExpansion = open ? contractAnchorBox : expandAnchorBox;

    return (
      visible && (
        <AnchorBox
          header={this._getHeader()}
          headerClick={toggleAnchorboxExpansion}
          headerHeight={45}
          open={open}
        >
          <ReleaseNotes releaseNotes={releaseNotes} />
        </AnchorBox>
      )
    );
  }

  _getHeader = () => {
    const {
      actionCreators: { hideAnchorBox, expandAnchorBox, contractAnchorBox },
      open,
    } = this.props;

    return [
      <div
        className="release-note-header-title"
        key="release-note-header-title"
      >
        <FormattedMessage id="web.releaseNotes.header" />
      </div>,
      <AnchorBoxHeader
        key="release-note-header-content"
        close={hideAnchorBox}
        contract={contractAnchorBox}
        expand={expandAnchorBox}
        open={open}
      />,
    ];
  };
}

ReleaseNoteAnchorBox.propTypes = {
  actionCreators: object.isRequired,
  intl: intlShape.isRequired,
  open: bool,
  visible: bool,
  releaseNotes: array.isRequired,
};

ReleaseNoteAnchorBox.defaultProps = {
  open: false,
  visible: false,
  releaseNotes: [],
};

export default injectIntl(ReleaseNoteAnchorBox);
