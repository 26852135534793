/* Data IDs */
export const TASKS_COMPLETED = 'TASKS_COMPLETED';
export const TASKS_UNCOMPLETED = 'TASKS_UNCOMPLETED';

/* Constants */
export const OVERDUE_TASK_DATE_RANGE = 20;
export const MIN_TASK_DATE_RANGE = 3;
export const MAX_TASK_DATE_RANGE = 7;
export const ASYNC_THRESHOLD_TASKS = 10;
export const PRIMARY_EMAIL_ARGUMENT = 'primaryEmail';
