import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { canShareTemplate } from 'web/templates/services/templateSharingService';
import { commonUserAdminTeamPermission } from 'web/shared/services/accountService';
import { isAdminOrSameUser } from 'web/user/services/userService';
import SlideOutPaneActionBar from 'components/layouts/slideOutPane/actionBar';
import { getUserCanEditTemplates } from 'web/templates/selectors/templatesSelectors';
import { ButtonColors } from 'components/buttons/action';
import './editTemplateActionBar.scss';

class EditTemplateActionBar extends PureComponent {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;
    this.cloneText = formatMessage({ id: 'common.clone' });
    this.moveText = formatMessage({ id: 'common.move' });
    this.archiveText = formatMessage({ id: 'common.archive' });
    this.deleteText = formatMessage({ id: 'common.delete' });
  }

  render() {
    const {
      actionCreators: { onBack },
    } = this.props;

    return (
      <SlideOutPaneActionBar
        buttons={this._getButtons()}
        onBack={onBack}
        options={this._getOptions()}
      />
    );
  }

  _getButtons = () => {
    const {
      actionCreators,
      tabLoading,
      template,
      userCanEditTemplates,
    } = this.props;
    return [
      {
        disabled: tabLoading || this._isInArchivedCategory(),
        onClick: actionCreators.useTemplate,
        textId: 'web.editTemplateSlideOut.useTemplate',
      },
      ...(userCanEditTemplates
        ? [
            {
              color: ButtonColors.gray,
              disabled: tabLoading || !canShareTemplate(template),
              onClick: actionCreators.shareTemplate,
              textId: 'common.share',
            },
          ]
        : []),
    ];
  };

  _getOptions = () => {
    const {
      actionCreators,
      tabLoading,
      template,
      user,
      viewer,
      userCanEditTemplates,
    } = this.props;
    const disabled = !commonUserAdminTeamPermission(template.user_id);

    if (!userCanEditTemplates) {
      return [];
    }

    return [
      {
        disabledStyle: tabLoading || !isAdminOrSameUser(user, viewer),
        label: this.cloneText,
        onClick: actionCreators.cloneTemplate,
        value: 'clone',
      },
      {
        disabledStyle: tabLoading || disabled,
        label: this.moveText,
        onClick: actionCreators.moveTemplate,
        value: 'move',
      },
      {
        disabledStyle: tabLoading || disabled || this._isInArchivedCategory(),
        label: this.archiveText,
        onClick: actionCreators.archiveTemplate,
        value: 'archive',
      },
      {
        disabledStyle: tabLoading || disabled,
        label: this.deleteText,
        onClick: actionCreators.deleteTemplate,
        value: 'delete',
      },
    ];
  };

  _isInArchivedCategory = () => {
    const {
      archivedCategoryId,
      template: { category_id: categoryId },
    } = this.props;
    return categoryId === archivedCategoryId;
  };
}

EditTemplateActionBar.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  archivedCategoryId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  tabLoading: PropTypes.bool,
  template: PropTypes.object,
  user: PropTypes.object.isRequired,
  userCanEditTemplates: PropTypes.bool.isRequired,
  viewer: PropTypes.object,
};

EditTemplateActionBar.defaultProps = {
  tabLoading: false,
  template: {},
  viewer: {},
};

const mapStateToProps = (state) => ({
  userCanEditTemplates: getUserCanEditTemplates(state),
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(EditTemplateActionBar);
