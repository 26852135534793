import ContentActionTypes from '../libs/contentActionTypes';
import {
  deleteFiles as deleteFilesCall,
  getContent as getContentCall,
} from '../services/contentService';

export const getContent = (force) => (dispatch, getState) => {
  const { content } = getState();

  if (force || content.length === 0) {
    dispatch({ type: ContentActionTypes.fetching });
    getContentCall()
      .then((contentData) => {
        dispatch({
          type: ContentActionTypes.set,
          content: contentData,
        });
        dispatch({ type: ContentActionTypes.fetchingDone });
      })
      .catch((error) => {
        dispatch({ type: ContentActionTypes.getFailure, error });
        dispatch({ type: ContentActionTypes.fetchingDone });
      });
  }
};

// Optimistically delete files
export const deleteFiles = (fileIds = []) => (dispatch) => {
  let ids = fileIds;
  if (typeof fileIds === 'number') {
    ids = [fileIds];
  }

  if (ids.length) {
    dispatch({ type: ContentActionTypes.delete, ids });
    deleteFilesCall(ids);
  }
};
