import React from 'react';
import { connect } from 'react-redux';
import AutoBcc from '../components/bcc';
import { getBccIntegration } from 'web/integrations/bcc/selectors/bccSelector';
import {
  getIntegration,
  updateIntegration,
  openDeleteBccConfirmation,
} from '../actionCreators/bccActionCreators';

const AutoBccContainer = (props) => <AutoBcc {...props} />;

const mapStateToProps = (state) => ({
  bccIntegration: getBccIntegration(state),
});

const mapDispatchToProps = {
  getIntegration,
  updateIntegration,
  openDeleteBccConfirmation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoBccContainer);
