import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './peopleSourceModal.scss';
import SourceEditing from 'web/person/components/personDetails/complianceCard/editing/sourceEditing';
import { SourceTypes } from 'web/compliance/libs/complianceConstants';

class PeopleSourceModal extends Component {
  constructor(props) {
    super(props);

    const { formatMessage } = props.intl;

    this.sourceItems = [
      {
        value: SourceTypes.crmSync,
        label: formatMessage({ id: 'web.people.compliance.crmSync' }),
      },
      {
        value: SourceTypes.import,
        label: formatMessage({ id: 'web.people.compliance.import' }),
      },
      {
        value: SourceTypes.manualUpload,
        label: formatMessage({ id: 'web.people.compliance.manualUpload' }),
      },
      {
        value: SourceTypes.chromeExtension,
        label: formatMessage({ id: 'web.people.compliance.chromeExtension' }),
      },
      {
        value: SourceTypes.other,
        label: formatMessage({ id: 'common.other' }),
      },
    ];
  }

  render() {
    const { selectedPeopleSource } = this.props;

    return (
      <div className="people-source-modal">
        <p>
          <FormattedMessage id="web.people.compliance.modal.sourcePrompt" />
        </p>
        <SourceEditing
          complianceEditState={selectedPeopleSource}
          labelClassName="people-source-select"
          sideLabel={false}
          updateCompliance={this.props.actionCreators.changePeopleSource}
        />
      </div>
    );
  }

  _updateSelect = (source) => {
    const selected = source ? source.value : '';
    const {
      actionCreators: { changePeopleSource },
    } = this.props;

    changePeopleSource({ selected });
  };

  _updateOther = (other) =>
    this.props.actionCreators.changePeopleSource({ other });
}

PeopleSourceModal.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  selectedPeopleSource: PropTypes.object.isRequired,
};

export default injectIntl(PeopleSourceModal);
