import React from 'react';
import PropTypes from 'prop-types';
import Engage from 'web/liveFeed/engage/containers/engageContainer';
import Pipeline from 'web/liveFeed/pipeline/containers/pipelineContainer';
import Tasks from 'web/liveFeed/tasks/containers/tasksContainer';
import { LiveFeedTabsEnum } from 'web/liveFeed/libs/liveFeedConstants';

const LiveFeedTabView = ({ selectedTabValue }) => {
  switch (selectedTabValue) {
    case LiveFeedTabsEnum.engagement.value:
      return <Engage />;
    case LiveFeedTabsEnum.tasks.value:
      return <Tasks />;
    case LiveFeedTabsEnum.target.value:
      return <Pipeline />;
    default:
      return null;
  }
};

LiveFeedTabView.propTypes = {
  selectedTabValue: PropTypes.string,
};

LiveFeedTabView.defaultProps = {
  selectedTabValue: LiveFeedTabsEnum.engagement.value,
};

export default LiveFeedTabView;
