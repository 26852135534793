import cloneDeep from 'lodash/cloneDeep';
import LiveFeedActionTypes from '../libs/liveFeedActionTypes';
import { ToutEvents } from '../libs/liveFeedConstants';

const initLiveFeedAnalyticsState = { pitches: [], events: [] };

export function liveFeedAnalytics(
  state = initLiveFeedAnalyticsState,
  { type = '', analytics = initLiveFeedAnalyticsState }
) {
  switch (type) {
    case LiveFeedActionTypes.analytics:
      return analytics;
    case LiveFeedActionTypes.updateEventsAnalytics: {
      const updatedEventsAnalytics = cloneDeep(state);
      updatedEventsAnalytics.events.push(analytics);
      return updatedEventsAnalytics;
    }
    case LiveFeedActionTypes.updatePitchAnalytics: {
      const updatedPitchAnalytics = cloneDeep(state);
      updatedPitchAnalytics.pitches.push(analytics);
      return updatedPitchAnalytics;
    }
    default:
      return state;
  }
}

export function liveFeedChartEvent(
  state = ToutEvents.open,
  { type = '', event = ToutEvents.open }
) {
  switch (type) {
    case LiveFeedActionTypes.chartEvent:
      return event;
    default:
      return state;
  }
}

export function liveFeedDealInformation(
  state = {},
  { type = '', dealInformation = {} }
) {
  switch (type) {
    case LiveFeedActionTypes.updateDealAlert:
      return dealInformation;
    default:
      return state;
  }
}
