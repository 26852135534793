import {
  openPopup,
  closePopup,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  addAlert,
  updateTemplate,
  resetComposeContent,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import {
  ModalComponentIds,
  AlertIds,
} from 'web/composeWindow/libs/composeWindowConstants';
import {
  RecommendedCategory,
  RecentlyUsedCategory,
} from 'web/composeWindow/libs/composeTemplatesConstants';
import { getCategoriesTemplates } from 'web/categories/services/categoriesTemplatesService';
import ComposeTemplatesActionTypes from 'web/composeWindow/libs/composeTemplatesActionTypes';
import { esFetch } from 'web/elasticSearch/services/esFetch';
import {
  buildFavoritesPayload,
  buildTemplatesQuery,
  filterTemplatesByTerm,
  handleFavoritesResponsePayload,
} from 'web/composeWindow/helpers/composeTemplatesHelpers';
import {
  getPinnedTemplateCategories,
  getRecommendedTemplates as getRecommendedTemplatesCall,
  savePinnedTemplateCategories,
} from 'web/composeWindow/services/composeTemplatesServices';
import { getOneOffPersonId } from 'web/composeWindow/selectors/composeWindowSelectors';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';
import sortBy from 'lodash/sortBy';
import ComposeWindowActionTypes from 'web/composeWindow/libs/composeWindowActionTypes';
import { updateUserSettings } from 'web/user/actionCreators/userActionCreators';
import { fetchTemplate } from 'web/composeWindow/services/composeTemplatesServices';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';

export const setComposeTemplatesLoading = (loading) => ({
  loading,
  type: ComposeTemplatesActionTypes.setLoading,
});

export const openEditPinnedCategoriesPopup = () => (dispatch) => {
  dispatch(openPopup(ModalComponentIds.editPinnedTemplateCategories, {}));
};

export const setTemplateCategories = (templateCategories = []) => ({
  templateCategories: sortBy(
    templateCategories,
    (category) => category.name && category.name.toLowerCase()
  ),
  type: ComposeTemplatesActionTypes.setCategories,
});

export const getTemplateCategories = () => (dispatch) => {
  getCategoriesTemplates()
    .then((categories = []) => dispatch(setTemplateCategories(categories)))
    .catch(() => {
      dispatch(addAlert(TemplateAlertIds.getCategoriesError));
    });
};

export const setTemplates = ({ templates, term, total, page }) => ({
  page,
  templates,
  term,
  total,
  type: ComposeTemplatesActionTypes.setTemplates,
});

export const appendTemplates = ({ templates, page }) => ({
  page,
  templates,
  type: ComposeTemplatesActionTypes.appendTemplates,
});

export const getTemplates = ({ categoryId, term, page = 1 }) => async (
  dispatch,
  getState
) => {
  dispatch(setComposeTemplatesLoading(true));
  if (categoryId !== RecommendedCategory.id) {
    const {
      user: { id, name },
      recentlyUsedTemplates,
    } = getState();
    const viewer = { id, name, type: 'user' };

    const query = buildTemplatesQuery({ categoryId, page, term, viewer });
    if (categoryId === RecentlyUsedCategory.id) {
      query.filters = {
        es_document_type: ElasticSearchTypes.pitchTemplates,
        id: recentlyUsedTemplates,
      };
    }
    try {
      const { results: templates, total } = await esFetch(query);
      if (page === 1) {
        dispatch(setTemplates({ page, templates, term, total }));
      } else {
        dispatch(appendTemplates({ page, templates }));
      }
    } catch {
      dispatch(addAlert(TemplateAlertIds.fetchError));
    }
  } else {
    await dispatch(getRecommendedTemplates(term));
  }

  dispatch(setComposeTemplatesLoading(false));
};

export const setPinnedCategories = (pinnedCategories = []) => ({
  pinnedCategories,
  type: ComposeTemplatesActionTypes.setPinnedTemplateCategories,
});

export const setPinnedCategoriesLoading = (loading) => ({
  loading,
  type: ComposeTemplatesActionTypes.setPinnedCategoriesLoading,
});

export const getPinnedCategories = () => async (dispatch) => {
  try {
    dispatch(setPinnedCategoriesLoading(true));
    const favorites = await getPinnedTemplateCategories();
    dispatch(setPinnedCategories(handleFavoritesResponsePayload(favorites)));
  } catch {
    dispatch(addAlert(AlertIds.fetchPinnedError));
  } finally {
    dispatch(setPinnedCategoriesLoading(false));
  }
};

export const setEditPinnedCategories = (editPinnedCategories) => ({
  editPinnedCategories,
  type: ComposeTemplatesActionTypes.setEditPinnedCategories,
});

export const clearEditPinnedCategories = () => ({
  type: ComposeTemplatesActionTypes.clearEditPinnedCategories,
});

export const savePinnedCategories = () => async (dispatch, getState) => {
  const {
    composeTemplatesEditPinnedCategories,
    composeTemplatesPinnedCategories,
  } = getState();
  const favoritesPayload = buildFavoritesPayload(
    composeTemplatesEditPinnedCategories,
    composeTemplatesPinnedCategories
  );

  try {
    dispatch(setPopupLoading(true));
    const response = await savePinnedTemplateCategories(favoritesPayload);
    const categories = handleFavoritesResponsePayload(response);
    dispatch(setPinnedCategories(categories));
    dispatch(addAlert(AlertIds.pinnedSaveSuccess));
  } catch {
    dispatch(addAlert(AlertIds.pinnedSaveError));
  } finally {
    dispatch(setPopupLoading(false));
    dispatch(closePopup());
  }
};

export const sendDuplicateCategoryAlert = () =>
  addAlert(AlertIds.duplicateTemplateCategory);

export const setSelectedTemplate = (template) => (dispatch) => {
  dispatch({
    template,
    type: ComposeWindowActionTypes.setSelectedTemplate,
  });
  dispatch(updateTemplate());
};

export const deselectTemplate = () => (dispatch) => {
  dispatch({
    type: ComposeWindowActionTypes.deselectTemplate,
  });
  dispatch(resetComposeContent());
};

export const handleTemplateClick = (template) => (dispatch, getState) => {
  const {
    composeWindowState: { editorInstance },
    userSettings: {
      disable_notify_template_edits: disabledNotifyTemplateEdits,
    },
  } = getState();
  const isEdited = editorInstance && editorInstance.isDirty();

  if (isEdited && !disabledNotifyTemplateEdits) {
    dispatch(
      notifyComposeEdits('web.composeWindow.clearEditsModal.template', () =>
        dispatch(setSelectedTemplate(template))
      )
    );
  } else {
    dispatch(setSelectedTemplate(template));
  }
};

export const notifyComposeEdits = (messageId, confirmedCallback) => (
  dispatch
) => {
  dispatch(
    openPopup(ModalComponentIds.clearEditsModal, {
      confirmEdit: (isDisableChecked) => {
        if (isDisableChecked) {
          dispatch(updateUserSettings({ disable_notify_template_edits: true }));
        }
        confirmedCallback();
        dispatch(closePopup());
      },
      isDisableChecked: false,
      messageId,
    })
  );
};

export const getRecommendedTemplates = (term) => async (dispatch, getState) => {
  const {
    composeWindowState: { bulkViewOpened },
  } = getState();

  const personId = getOneOffPersonId(getState());
  if (!personId || bulkViewOpened) {
    return;
  }

  try {
    const {
      recommended_templates: recommendedTemplates,
    } = await getRecommendedTemplatesCall(personId);

    if (recommendedTemplates) {
      const textFilteredTemplates = filterTemplatesByTerm(
        recommendedTemplates,
        term
      );
      dispatch({ type: ComposeTemplatesActionTypes.showRecommendedTemplates });
      dispatch(setTemplates({ templates: textFilteredTemplates, term }));
    } else {
      dispatch({ type: ComposeTemplatesActionTypes.hideRecommendedTemplates });
    }
  } catch {
    dispatch(addAlert(TemplateAlertIds.fetchError));
  }
};

export const fetchAndSetByTemplateId = (templateId) => async (dispatch) => {
  const template = await fetchTemplate(templateId);
  dispatch(setSelectedTemplate(template));
};
