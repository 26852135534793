import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Card from 'components/card';
import { getI18nImageUrl } from 'web/libs/constants';
import './notValidInvitationOnboarding.scss';

const NotValidInvitationOnboarding = (adminEmail) => (
  <Card className="onboarding-not-valid-invitation" width={500}>
    <h1 className="title">
      <FormattedMessage id="createAccount.notValidInvitation.title" />
    </h1>

    <div className="not-valid-information">
      <FormattedMessage id="createAccount.notValidInvitation.onboarding.expiredInfo" />
      <FormattedHTMLMessage
        id="createAccount.notValidInvitation.onboarding.contactWithAdmin"
        values={adminEmail}
      />
    </div>
    <img
      className="not-valid-envelope-icon"
      src={getI18nImageUrl('notValidInvitation', 'png')}
    />
  </Card>
);

export default NotValidInvitationOnboarding;
