import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import Label from 'components/label';
import Select2 from 'components/selects/select2';
import CategorySelect2 from 'web/categories/components/categorySelect2';
import { getTeams } from 'web/shared/services/accountService';
import { canUnshareTemplate } from 'web/templates/services/templateSharingService';
import './shareTemplate.scss';

class ShareTemplate extends PureComponent {
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.options = this._generateOptions();
    this.noResultsTeamText = formatMessage({
      id: 'web.templates.shareTemplate.noResultsTeams',
    });
    this.placeholderTeam = formatMessage({
      id: 'web.templates.shareTemplate.placeholderTeams',
    });
  }

  render() {
    const {
      initCategoryId,
      selectedCategory,
      sharableCategories,
      teamIds,
      updateSharedCategory,
    } = this.props;
    return (
      <div className="share-template">
        <Label
          classWrapper="margin-bottom-full"
          id="share-template-label"
          messageKey="web.templates.shareTemplate.teamLabel"
        >
          <Select2
            clearable={false}
            disabled={false}
            multi
            noResultsText={this.noResultsTeamText}
            onChange={this.updateSharedTeamIds}
            options={this.options}
            placeholder={this.placeholderTeam}
            value={teamIds}
          />
        </Label>
        <Label id="share-template-label" messageKey="common.category">
          <div className="margin-bottom-half">
            <FormattedMessage id="web.templates.shareTemplate.categoryHelp" />
          </div>
          <CategorySelect2
            categories={sharableCategories}
            initCategoryId={initCategoryId}
            labelInitCategory
            selectedCategory={selectedCategory}
            updateCategory={updateSharedCategory}
          />
        </Label>
      </div>
    );
  }

  _generateOptions = () => {
    const { template } = this.props;
    const usersTeams = getTeams().map((element) => ({
      value: element.id,
      label: element.name,
      clearableValue: this._canClear(element.id),
    }));
    const templateSharedTeams = getTeams(template.team_ids).map((element) => ({
      value: element.id,
      label: element.name,
      clearableValue: this._canClear(element.id),
    }));
    return unionBy(usersTeams, templateSharedTeams, 'value');
  };

  updateSharedTeamIds = (val) =>
    this.props.updateSharedTeamIds(map(val, 'value'));

  _canClear = (id) => canUnshareTemplate(this.props.template, id);
}

ShareTemplate.propTypes = {
  initCategoryId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  sharableCategories: PropTypes.array.isRequired,
  teamIds: PropTypes.array.isRequired,
  template: PropTypes.object.isRequired,
  updateSharedCategory: PropTypes.func.isRequired,
  updateSharedTeamIds: PropTypes.func.isRequired,
};

export default injectIntl(ShareTemplate);
