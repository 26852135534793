import React from 'react';
import PropTypes from 'prop-types';
import './labeledInput.scss';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import InputText from 'components/inputText';
import { ValidationError } from 'web/form/helpers/validators';

const LabeledInput = ({
  classesInput,
  classesLabel,
  className,
  error,
  id,
  messageKey,
  labelCommentMessageKey,
  setRef,
  sideLabel,
  toutUiStyle,
  type,
  ...other
}) => {
  const label = classNames('labeled-input-label', classesLabel, {
    error,
    'side-label': sideLabel,
    'side-label-textarea': type === 'textarea',
    toutUiStyle,
  });

  const input = classNames('labeled-input-input', classesInput, {
    error,
    'side-label': sideLabel,
    'full-width': !sideLabel,
  });

  // TODO add error handling states and stuff
  return (
    <div
      className={classNames(
        'labeled-input',
        { 'side-label': sideLabel },
        className
      )}
    >
      <label className={label} htmlFor={id}>
        {messageKey ? <FormattedHTMLMessage id={messageKey} /> : null}
        {labelCommentMessageKey && (
          <span className="label-comment">
            <FormattedHTMLMessage id={labelCommentMessageKey} />
          </span>
        )}
      </label>
      <InputText
        className={input}
        id={id}
        setRef={setRef}
        toutUiStyle={toutUiStyle}
        type={type}
        {...other}
      />
      {error && (
        <p className="labeled-input-error-msg">
          <FormattedMessage
            id={error instanceof ValidationError ? error.messageId : error}
          />
        </p>
      )}
    </div>
  );
};

LabeledInput.propTypes = {
  classesInput: PropTypes.string,
  classesLabel: PropTypes.string,
  className: PropTypes.string,
  // temporary solution in future use only ValidationError object for error handle
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(ValidationError),
  ]),
  id: PropTypes.string.isRequired,
  messageKey: PropTypes.string.isRequired,
  labelCommentMessageKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setRef: PropTypes.func,
  sideLabel: PropTypes.bool,
  toutUiStyle: PropTypes.bool,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
};

LabeledInput.defaultProps = {
  classesInput: '',
  classesLabel: '',
  className: '',
  error: '',
  labelCommentMessageKey: '',
  setRef: null,
  sideLabel: false,
  toutUiStyle: false,
  value: '',
};

export default LabeledInput;
