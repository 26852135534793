import languages from 'languages';

function percentage(dividend, divisor) {
  if (
    divisor === 0 ||
    divisor === undefined ||
    dividend === undefined ||
    Number.isNaN(divisor) ||
    Number.isNaN(dividend)
  ) {
    return 0;
  }
  return ((dividend / divisor) * 100).toFixed(0);
}

function minutes(seconds) {
  if (seconds === 0 || Number.isNaN(seconds) || seconds === undefined) {
    return 0;
  }
  return (seconds / 60).toFixed(0);
}

function color(value) {
  if (value > 49) {
    return 'green';
  } else if (value > 0 && value <= 49) {
    return 'yellow';
  }
  return 'blue';
}

export function parseEmailAnalytics({
  emails_sent: emailsSent,
  emails_clicked: emailsClicked,
  emails_replied: emailsReplied,
  emails_viewed: emailsViewed,
  emails_bounced: emailsBounced,
}) {
  const views = percentage(emailsViewed, emailsSent);
  const clicks = percentage(emailsClicked, emailsSent);
  const replies = percentage(emailsReplied, emailsSent);
  const bounced = percentage(emailsBounced, emailsSent);
  return [
    {
      label: 'common.delivered',
      value: `${emailsSent || 0}`,
      size: 'large',
    },
    {
      label: 'common.views',
      value: `${views}%`,
      size: 'small',
      color: color(views),
    },
    {
      label: 'common.clicks',
      value: `${clicks}%`,
      size: 'small',
      color: color(clicks),
    },
    {
      label: 'common.replies',
      value: `${replies}%`,
      size: 'small',
      color: color(replies),
    },
    {
      label: 'common.bounced',
      value: `${bounced}%`,
      size: 'small',
      color: color(bounced),
    },
  ];
}

export function parseTaskAnalytics({ num_tasks: numTasks }) {
  return [
    {
      label: 'common.completed',
      value: `${numTasks || 0}`,
      size: 'large',
    },
  ];
}

export function parseGeneralAnalytics({
  num_used: numUsed,
  num_success: numSuccess,
}) {
  const success = percentage(numSuccess, numUsed);
  return [
    {
      label: 'common.used',
      value: `${numUsed || 0}`,
      size: 'large',
    },
    {
      label: 'common.success',
      value: `${success}%`,
      size: 'small',
      color: color(success),
    },
  ];
}

export function parseCallAnalytics({
  num_calls: numCalls,
  num_calls_connected: numCallsConnected,
  total_call_duration: totalCallDuration,
}) {
  const connected = percentage(numCallsConnected, numCalls);
  return [
    {
      label: 'common.completed',
      value: `${numCalls || 0}`,
      size: 'large',
    },
    {
      label: 'common.connected',
      value: `${connected}%`,
      size: 'small',
      color: color(connected),
    },
    {
      label: 'common.duration',
      value: languages.getStr('common.numMin', {
        num: minutes(totalCallDuration),
      }),
      size: 'small',
    },
  ];
}
