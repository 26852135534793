import ActionTypes from 'web/libs/actionTypes/actionTypes';

export const initState = {
  selectedIds: {},
};

export function updateCampaignTemplatesModalState(state = initState, action) {
  switch (action.type) {
    case ActionTypes.updateCampaignTemplates.state.selectedIds: {
      const tempIds = { ...state.selectedIds };
      action.ids.forEach((id) => {
        if (action.checked) {
          tempIds[id] = true;
        } else {
          Reflect.deleteProperty(tempIds, id);
        }
      });
      return { ...state, selectedIds: tempIds };
    }
    case ActionTypes.modal.state.close:
      return initState;
    default:
      return state;
  }
}
