import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import UnsavedChangesPopup from 'web/safelyChangeRoute/components/unsavedChangesPopup';

class SyncSettingsAccordionPopupContainer extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actionCreators = {
      onStay: () => dispatch(closePopup()),
      onDiscard: () => {
        dispatch(closePopup());
        this.props.onDiscard();
      },
    };
  }

  render() {
    const { popup, popupId } = this.props;
    const { onStay, onDiscard } = this.actionCreators;

    return popup === popupId ? (
      <UnsavedChangesPopup onStay={onStay} onDiscard={onDiscard} />
    ) : null;
  }
}

SyncSettingsAccordionPopupContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  popup: PropTypes.string.isRequired,
  popupId: PropTypes.string.isRequired,
  onDiscard: PropTypes.func,
};

SyncSettingsAccordionPopupContainer.defaultProps = {
  onDiscard: () => {},
};

const mapStateToProps = (state) => ({
  popup: state.popup,
});

export default connect(mapStateToProps)(SyncSettingsAccordionPopupContainer);
