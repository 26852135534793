/**
 *
 * SwitchCell
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Switch, {
  SwitchColors as BaseSwitchColors,
} from 'components/buttons/switch';
import './switchCell.scss';

class SwitchCell extends PureComponent {
  render() {
    const { className, color, disabled, property, rowData } = this.props;
    const checked = rowData[property];

    return (
      <Switch
        checked={checked}
        className={classNames('cell-switch', className)}
        color={color}
        disabled={disabled}
        name={`switch-cell-${rowData.id}`}
        onChange={this.onChange}
      />
    );
  }

  onChange = (on) => this.props.onChange(this.props.rowData, on);
}

export const SwitchColors = BaseSwitchColors;

SwitchCell.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(SwitchColors)),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
};

SwitchCell.defaultProps = {
  className: '',
  disabled: false,
  property: 'id',
  rowData: {},
};

export default SwitchCell;
