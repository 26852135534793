import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import I18N from 'languages';
import { getTimezones } from 'web/timezones/actionCreators/timezonesActionCreators';
import { changeTimezoneSettings } from '../actionCreators/timezoneSettingsActionCreators';
import TimezoneSettings from './timezoneSettings';
import {
  languagesOptions,
  localeOptions,
  defaultLocaleOptions,
} from '../libs/timezoneSettingsConstants';
import {
  parseItems,
  parseFormValues,
  setToken,
  filterLocalOptions,
  isValuesEqual,
  getDefaultLocaleFromLanguage,
} from '../helpers/timezoneSettingsHelper';
import {
  getUserTimezoneSelectOption,
  getFormValues,
} from '../selectors/timezoneSettingsSelectors';
import { isI18NFeatureFlagEnabled } from 'web/user/selectors/userSelectors';

import './timezoneSettingsContainer.scss';

const TimezoneSettingsContainer = (props) => {
  const {
    getTimezones,
    handleSubmit,
    timezones,
    changeTimezoneSettings,
    pending,
    intl: { formatMessage },
    ...rest
  } = props;

  useEffect(() => {
    getTimezones();
    setToken();
  }, []);

  useEffect(
    () => {
      props.change('timezone', props.initialValues.timezone);
    },
    [props.initialValues.timezone]
  );

  const [filteredLocaleOptions, setFilteredLocaleOptions] = useState(
    localeOptions
  );

  useEffect(
    () => {
      if (!props.formValues.language) return;

      const filteredOptions = filterLocalOptions(props.formValues.language);
      const selectedOptions = getDefaultLocaleFromLanguage(
        props.formValues.language
      );
      const userLocale =
        filteredOptions.find(
          (locale) => locale.value === I18N.currUserLocale
        ) || selectedOptions[0];
      setFilteredLocaleOptions(filteredOptions);
      props.change('locale', userLocale);
    },
    [props.formValues.language]
  );

  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(
    () => {
      setIsFormChanged(!isValuesEqual(props.initialValues, props.formValues));
    },
    [props.formValues]
  );

  const languagePlaceholder = formatMessage({
    id: 'web.settings.myProfile.chooseLanguage',
  });
  const localePlaceholder = formatMessage({
    id: 'web.settings.myProfile.chooseLocale',
  });
  const yourTimezonePlaceholder = formatMessage({
    id: 'web.settings.myProfile.chooseTimezone',
  });

  const onFormSubmit = (values) => {
    const newSettings = props.i18nFeatureFlagEnadled
      ? values
      : { timezone: values.timezone };
    changeTimezoneSettings(parseFormValues(newSettings));
  };

  return (
    <React.Fragment>
      <h3 className="my-profile-timezone-title">
        <FormattedMessage id="web.settings.myProfile.locationSettings.header" />
      </h3>
      <TimezoneSettings
        {...rest}
        isFormChanged={isFormChanged}
        languageOptions={languagesOptions}
        languagePlaceholder={languagePlaceholder}
        localeOptions={filteredLocaleOptions}
        localePlaceholder={localePlaceholder}
        onFormSubmit={handleSubmit(onFormSubmit)}
        pending={pending}
        yourTimezoneOptions={parseItems(timezones)}
        yourTimezonePlaceholder={yourTimezonePlaceholder}
      />
    </React.Fragment>
  );
};

TimezoneSettingsContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues(state),
  i18nFeatureFlagEnadled: isI18NFeatureFlagEnabled(state),
  initialValues: {
    language:
      languagesOptions.find((l) => l.value === I18N.currLanguage) ||
      languagesOptions[0],
    locale:
      localeOptions.find((l) => l.value === I18N.currLocale) ||
      defaultLocaleOptions[0],
    timezone: getUserTimezoneSelectOption(state),
  },
  pending: state.timezoneSettingsReducer.pending,
  timezones: state.timezones,
});

const mapDispatchToProps = {
  changeTimezoneSettings,
  getTimezones,
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({ enableReinitialize: true, form: 'timezoneForm' })
)(TimezoneSettingsContainer);
