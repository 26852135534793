import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function modal(state = {}, action) {
  switch (action.type) {
    case ActionTypes.modal.state.open:
      return { id: action.id, data: action.data };
    case ActionTypes.modal.state.setComponent:
      return {
        id: state.id,
        component: action.component,
        data: action.data || state.data,
      };
    case ActionTypes.modal.state.close:
      return {};
    default:
      return state;
  }
}

export function modalLoading(state = false, { type, isLoading }) {
  switch (type) {
    case ActionTypes.modal.state.loading:
      return isLoading;
    case ActionTypes.modal.state.open:
    case ActionTypes.modal.state.close:
      return false;
    default:
      return state;
  }
}

export function autocompleteValue(state = '', action) {
  switch (action.type) {
    case ActionTypes.autocomplete.state.value:
      return action.data;
    case ActionTypes.addToCampaignModal.state.reset:
    case ActionTypes.autocomplete.state.clear:
      return '';
    default:
      return state;
  }
}

export function autocompleteLoading(state = false, action) {
  switch (action.type) {
    case ActionTypes.api.autocomplete.request:
      return true;
    case ActionTypes.api.autocomplete.success:
    case ActionTypes.api.autocomplete.failure:
    case ActionTypes.addToCampaignModal.state.reset:
    case ActionTypes.autocomplete.state.clear:
      return false;
    default:
      return state;
  }
}

export function initStore(state = false, action) {
  switch (action.type) {
    case ActionTypes.common.state.initStore:
      return true;
    default:
      return state;
  }
}
