import PropTypes from 'prop-types';
import ApiShapes from './apiShapes';
import { SortingDirection as SortingDirectionConstant } from 'table/modules/constants';

/* Added to - onCheckClick, sort */
const Header = PropTypes.shape({
  height: PropTypes.number,
  isSelected: PropTypes.bool,
  onCheckClick: PropTypes.func,
  onSortClick: PropTypes.func,
  isVisible: PropTypes.bool,
});

/* Added to - onCheckClick */
const Row = PropTypes.shape({
  height: PropTypes.number,
  onCheckClick: PropTypes.func,
  onRadioClick: PropTypes.func,
  onClick: PropTypes.func,
});

const CellMetadata = PropTypes.shape({
  // just for TextCell so not needed
  property: PropTypes.string,
  wrapLines: PropTypes.number,
});

const CellHeaderMetadata = PropTypes.shape({
  // just for default header so not needed
  label: PropTypes.string,
});

/* Modified - onClick (if allItems)
   Added to - currentPage, totalPages
   Removed - perPage, total
*/
const Pagination = PropTypes.shape({
  // just point to class?
  clearSelected: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  itemsLength: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  scollTop: PropTypes.func,
  totalPages: PropTypes.number.isRequired,
  unknownTotal: PropTypes.bool,
  width: PropTypes.number,
});

/* Modified (if allItems) - onChange, onPrefixRemove, onResultsSelected, prefix, value
   Added to - results */
const Search = PropTypes.shape({
  maxWidth: PropTypes.number,
  noDropdown: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onPrefixRemove: PropTypes.func,
  onResultSelected: PropTypes.func,
  placeholderText: PropTypes.string,
  prefix: PropTypes.string,
  results: PropTypes.array,
  scollTop: PropTypes.func,
  value: PropTypes.string.isRequired,
});

/* Modified - selected, onChange (if allItems), Added to - index */
const SmartFilter = PropTypes.shape({
  // just point to class?
  filters: PropTypes.arrayOf(ApiShapes.SmartFilterItem),
  height: PropTypes.number,
  index: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  scollTop: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), //todo test adding req
  width: PropTypes.number,
});

/* Modified - to array */
const SmartFilters = PropTypes.arrayOf(SmartFilter);

/* Added to - onClearAllClick, onSelectAllClick, selectedCount, totalCount //todo figure out mapping to BulkActions component props */
const BulkActions = PropTypes.shape({
  // just point to class?
  actions: PropTypes.arrayOf(ApiShapes.BulkActionsAction).isRequired,
  itemsLabel: PropTypes.string,
  onActionClick: PropTypes.func.isRequired,
  onClearAllClick: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  selectedCount: PropTypes.number,
  totalCount: PropTypes.number,
  selectAll: PropTypes.bool,
  selectAllDisabled: PropTypes.bool,
});

/* Added to - checked, onChange */
const ColumnFilter = PropTypes.shape({
  // just point to class?
  checked: PropTypes.objectOf(PropTypes.bool),
  columns: PropTypes.array,
  onChange: PropTypes.func.isRequired,
});

const SortingDirection = PropTypes.oneOf([
  ...Object.values(SortingDirectionConstant),
]);

export default {
  Align: ApiShapes.Align, // same
  BulkActions, // class
  BulkActionsAction: ApiShapes.BulkActionsAction, // same/not used
  Cell: ApiShapes.Cell, // same/not used
  CellHeader: ApiShapes.CellHeader, // same/not used
  CellMetadata, // nah
  CellHeaderMetadata, // nah
  Column: ApiShapes.Column, // same
  ColumnFilter, // class
  Columns: ApiShapes.Columns, // same
  Header, // here
  Pagination, // class
  Row, // here
  Search,
  SmartFilter, // class
  SmartFilters,
  SortingDirection, // here
  WidthMode: ApiShapes.WidthMode, // same
  ZeroState: ApiShapes.ZeroState, // same
};
