import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  storeEditorInstance,
  createEmailComposeInstance,
  destroyEmailComposeInstance,
  insertContent,
  insertTemplate,
  processUpdatingTemplate,
  updateEditorBodies,
  updateEditorBody,
} from 'web/emailComposeBody/actionCreators/emailComposeActionCreators';
import { setInfoBoxState } from 'web/emailComposeBody/actionCreators/popupActionCreators';
import {
  deleteAttachment,
  uploadFiles,
} from 'web/emailComposeBody/actionCreators/fileActionCreators';
import { deleteFiles } from 'web/content/actionCreators/contentActionCreators';
import { openSaveAsTemplate } from 'web/emailComposeBody/actionCreators/saveAsTemplateActionCreators';
import {
  getAllTemplates,
  getFavoriteTemplates,
} from 'web/templates/actionCreators/templatesDataActionCreators';
import EmailComposeBodyComponent from 'web/emailComposeBody/components/emailComposeBody';
import { getTemplates } from './selectors/emailComposeBodySelectors';
import { isBoxIntegrationEnabled } from 'web/settings/adminSettings/general/selectors/generalPageSelectors';

class EmailComposeBody extends Component {
  constructor(props) {
    super(props);
    const { defaultAttachments, dispatch, id } = props;

    this.actionCreators = {
      createEmailComposeInstance: () =>
        dispatch(createEmailComposeInstance(id, defaultAttachments)),
      deleteAttachment: (file) => dispatch(deleteAttachment(id, file)),
      deleteContent: (ids) => dispatch(deleteFiles(ids)),
      destroyEmailComposeInstance: () =>
        dispatch(destroyEmailComposeInstance(id)),
      insertContent: (content) => dispatch(insertContent(id, content)),
      insertTemplate: (template) => dispatch(insertTemplate(id, template)),
      openSaveAsTemplate: () => dispatch(openSaveAsTemplate(id, this.props)),
      processUpdatingTemplate: (template) =>
        dispatch(processUpdatingTemplate(id, template)),
      setInfoBoxState: (newState) => dispatch(setInfoBoxState(id, newState)),
      storeEditorInstance: (instance) =>
        dispatch(storeEditorInstance(id, instance)),
      updateBodies: (body) => dispatch(updateEditorBodies(id, body)),
      updateBody: (body) => dispatch(updateEditorBody(id, body)), // TJ -> is this even used?
      uploadFiles: (files, type) => dispatch(uploadFiles(id, files, type)),
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.props.dispatch(getAllTemplates()); //todo when Compose moved to react, move to bulk and single template updates
    this.props.dispatch(getFavoriteTemplates());
  }

  componentDidMount() {
    this.actionCreators.createEmailComposeInstance();
  }

  componentWillUnmount() {
    this.actionCreators.destroyEmailComposeInstance();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (!this.props.selectedTemplate && nextProps.selectedTemplate) {
      this.actionCreators.processUpdatingTemplate(nextProps.selectedTemplate);
      this.props.updateState(this.props.id, {
        template: nextProps.selectedTemplate,
      });
    }
    const { id, bodies } = this.props;
    const body = bodies[id];
    const nextBody = nextProps.bodies[id];
    const canPopulateBodies =
      body === undefined && nextBody === '' && nextProps.selectedTemplate;
    if (canPopulateBodies) {
      this.actionCreators.updateBodies(nextProps.selectedTemplate.body);
    }
  }

  render() {
    const {
      attachments,
      bodies,
      contentUploads,
      // eslint-disable-next-line no-unused-vars
      dispatch: ignored,
      id,
      ...other
    } = this.props;

    return (
      <EmailComposeBodyComponent
        actionCreators={this.actionCreators}
        attachments={attachments[id] || []}
        body={bodies[id] || ''}
        contentUploads={contentUploads[id] || []}
        id={id}
        {...other}
      />
    );
  }
}

EmailComposeBody.propTypes = {
  attachments: PropTypes.object.isRequired,
  bcc: PropTypes.string, // passed in
  bodies: PropTypes.object.isRequired,
  cc: PropTypes.string, // passed in
  content: PropTypes.array.isRequired,
  contentPartners: PropTypes.object.isRequired,
  contentUploads: PropTypes.object.isRequired,
  defaultAttachments: PropTypes.array, // passed in
  defaultText: PropTypes.string, // passed in
  disableCCs: PropTypes.bool, // passed in
  disableEdit: PropTypes.bool, // passed in
  dispatch: PropTypes.func.isRequired,
  hideInsertTemplate: PropTypes.bool, // passed in
  hideSaveAsTemplate: PropTypes.bool, // passed in
  hideToolbar: PropTypes.bool, // passed in
  id: PropTypes.string.isRequired, // passed in
  infoBoxState: PropTypes.object.isRequired,
  isBoxIntegrationEnabled: PropTypes.bool.isRequired,
  lastTemplateCategory: PropTypes.string,
  selectedTemplate: PropTypes.object, // passed in
  subject: PropTypes.string, // passed in
  templates: PropTypes.array.isRequired,
  templatesFavorites: PropTypes.array.isRequired,
  updateState: PropTypes.func.isRequired, // passed in
};

function mapStateToProps(state) {
  return {
    attachments: state.emailComposeAttachmentsMap,
    bodies: state.emailComposeCkeBodies,
    content: state.content,
    contentPartners: state.contentPartners,
    contentUploads: state.emailComposeContentUploadsMap,
    infoBoxState: state.emailComposeInfoBoxState,
    isBoxIntegrationEnabled: isBoxIntegrationEnabled(state),
    lastTemplateCategory: state.emailComposeLastTemplateCategory,
    templates: getTemplates(state),
    templatesFavorites: state.templatesFavorites,
  };
}

export default connect(mapStateToProps)(EmailComposeBody);
