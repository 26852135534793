import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { getUserChannel, initPusherService } from 'web/services/pusherService';
import { PusherEvents } from 'web/libs/constants';
import { ModalOverlayIds } from 'web/modals/addToCampaign/libs/addToCampaignModalConstants';
import {
  salesforceImportComplete,
  salesforceImportFailed,
  salesforceImportProgress,
} from './salesforceActionCreators';
import { setPopupOverlay } from 'web/popup/actionCreators/popupActionCreators';

export function initPusher() {
  return (dispatch, getState) => {
    const user = getState().user;
    initPusherService(user.id, user.subscription.id);
  };
}

export function bindSalesforceImport(jobId) {
  return (dispatch) => {
    const channel = getUserChannel();

    channel.bind(PusherEvents.contactImportComplete, (data = {}) => {
      if (jobId === data.job_id) {
        dispatch(salesforceImportComplete(data.people_ids, data.failed));
      }
    });

    channel.bind(PusherEvents.contactImportProgress, (data = {}) => {
      const { job_id, percent_complete } = data;
      if (jobId === job_id) {
        dispatch(salesforceImportProgress(percent_complete));
      }
    });

    channel.bind(PusherEvents.contactImportFailed, (data = {}) => {
      if (jobId === data.job_id) {
        dispatch(salesforceImportFailed(data.error));
      }
    });
  };
}

export function unbindSalesforceImport() {
  return () => {
    const channel = getUserChannel();
    channel.unbind(PusherEvents.contactImportComplete);
    channel.unbind(PusherEvents.contactImportProgress);
    channel.unbind(PusherEvents.contactImportFailed);
  };
}
