import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import HoverTooltip from 'components/hoverTooltip';
import './emailRedirect.scss';
import { EmailProviders } from 'web/settings/emailConnection/lib/emailConnectionConstants';

const EmailRedirect = (props) => {
  const { emailConnectionProvider } = props;

  return (
    <div className="email-redirect">
      <p className="email-redirect-body-text">
        <FormattedHTMLMessage
          id="web.settings.emailConnection.modal.redirect.body"
          values={{ provider: emailConnectionProvider }}
        />
      </p>
      <span>
        <FormattedMessage id="web.settings.emailConnection.modal.redirect.permissionsInclude" />
      </span>
      <ul className="permissions-list">
        <li>
          <FormattedMessage id="web.settings.emailConnection.modal.redirect.readAccess" />
        </li>
        <li>
          <FormattedMessage id="web.settings.emailConnection.modal.redirect.writeAccess" />
        </li>
        <li>
          <FormattedMessage id="web.settings.emailConnection.modal.redirect.manageContactsCalendars" />
          <HoverTooltip
            className="manage-tooltip"
            delayHide={0}
            place="right"
            textId="web.settings.emailConnection.modal.redirect.manageContactsCalendarsTooltip"
            multiline
          >
            <i className="tout-icon-warning" />
          </HoverTooltip>
        </li>
      </ul>
      {emailConnectionProvider.toLowerCase() === EmailProviders.gmail && (
        <p className="text-medium">
          <FormattedHTMLMessage id="web.settings.emailConnection.modal.gmailDisclosureStatement" />
        </p>
      )}
      <p className="text-medium">
        <FormattedHTMLMessage
          id="web.settings.emailConnection.modal.redirect.navigateToAuth"
          values={{ provider: emailConnectionProvider }}
        />
      </p>
    </div>
  );
};

EmailRedirect.propTypes = {
  emailConnectionProvider: PropTypes.string.isRequired,
};

export default EmailRedirect;
