import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { navigateToCommandCenterEmails } from 'web/services/routerService';
import FilterBlockContainer from 'web/commandCenter/modules/filterBlock/containers/filterBlockContainer';
import Square from 'components/buttons/square';

const SavedSearchEmailsTitle = ({ selectedSaveSearchName }) => {
  const closeAdvSearchHandler = () => navigateToCommandCenterEmails();

  return (
    <span className="emails-advanced-search-title">
      <Square
        className="margin-left-half margin-right-half chevron-square-btn chevron-square-btn-left emails-advanced-search-title-backbtn"
        onClick={closeAdvSearchHandler}
      />
      <FormattedMessage id="web.commandCenter.advancedSearchSaved.title" />
      <span className="margin-left-half emails-advanced-search-title-name">
        {selectedSaveSearchName}
      </span>
    </span>
  );
};

SavedSearchEmailsTitle.propTypes = {
  selectedSaveSearchName: PropTypes.string.isRequired,
};

const SavedSearchEmailsBody = ({
  actionCreators: { paginateEmails },
  tableId,
}) => (
  <div className="emails-filter-boxes">
    <FilterBlockContainer
      collectionId={tableId}
      onRemoveFilterBlock={paginateEmails}
    />
  </div>
);

SavedSearchEmailsBody.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  tableId: PropTypes.string.isRequired,
};

export default {
  Body: SavedSearchEmailsBody,
  Title: SavedSearchEmailsTitle,
};
