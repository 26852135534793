/*
 *  Notes:
 *    Uses ReactDOM to get height as inherit doesn't work for height and
 *      setting large max-height causes inconsitent animation transition.
 *    Defaults to use TextButton but you can pass Action or Icon and state is still
 *      maintained. Can also pass a buttonRenderer function but you will want to follow state.
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextButton from 'components/buttons/text';
import './readMore.scss';

class ReadMore extends Component {
  constructor(props) {
    super(props);
    this.wrappedComponentRef = null;
  }

  state = {
    nodeHeight: 0,
    showAll: false,
    showButton: false,
  };

  componentDidMount() {
    //eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this._setWrappedComponentRef);
    if (node.clientHeight > this.props.height) {
      this.setState(() => ({ showButton: true })); // eslint-disable-line react/no-did-mount-set-state
    }
  }

  componentWillUpdate(nextProps, nextState) {
    //eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this._setWrappedComponentRef);
    const showButton = node.clientHeight > nextProps.height;

    if (node.clientHeight !== nextState.nodeHeight) {
      //eslint-disable-next-line react/no-will-update-set-state
      this.setState(() => ({ nodeHeight: node.clientHeight }));
    }
    if (showButton !== nextState.showButton) {
      //eslint-disable-next-line react/no-will-update-set-state
      this.setState(() => ({ showButton }));
    }
  }

  render() {
    const { WrappedComponent, wrappedProps } = this.props;
    const { showButton } = this.state;

    return (
      <div className="read-more">
        <div className="read-more-children-wrapper" style={this._getStyles()}>
          <WrappedComponent {...wrappedProps} ref={this._setRef} />
        </div>
        {showButton && (
          <div className="read-more-button-wrapper">{this._renderButton()}</div>
        )}
      </div>
    );
  }

  _setRef = (node) => {
    this._setWrappedComponentRef = node;
  };

  _getStyles = () => {
    const { height, maxHeight, transitionDuration } = this.props;
    const { nodeHeight, showAll } = this.state;
    const styles = {
      transition: `max-height ${transitionDuration}ms ease-in-out`,
    };

    if (showAll && maxHeight) {
      styles.maxHeight = `${maxHeight}px`;
      styles.overflow = 'auto';
    } else if (showAll) {
      styles.maxHeight = `${nodeHeight}px`;
    } else {
      styles.maxHeight = `${height}px`;
    }

    return styles;
  };

  _renderButton = () => {
    const {
      ButtonComponent,
      buttonRenderer,
      showLessTextId,
      showMoreTextId,
    } = this.props;
    const { showAll } = this.state;
    const text = (showAll && showLessTextId) || showMoreTextId;

    if (buttonRenderer) {
      return buttonRenderer(showAll);
    }
    return (
      <ButtonComponent className="margin-top-half" onClick={this.toggleState}>
        <FormattedMessage id={text} />
      </ButtonComponent>
    );
  };

  toggleState = () => {
    const nextState = !this.state.showAll;
    this.setState(() => ({ showAll: nextState }));
    this.props.readMoreOnClick(nextState);
  };
}

ReadMore.propTypes = {
  ButtonComponent: PropTypes.func,
  buttonRenderer: PropTypes.func,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  readMoreOnClick: PropTypes.func,
  showLessTextId: PropTypes.string,
  showMoreTextId: PropTypes.string,
  transitionDuration: PropTypes.number,
  WrappedComponent: PropTypes.func.isRequired,
  wrappedProps: PropTypes.object.isRequired,
};

ReadMore.defaultProps = {
  ButtonComponent: TextButton,
  buttonRenderer: undefined,
  height: 200,
  maxHeight: 0,
  readMoreOnClick: () => {},
  showLessTextId: 'readMore.showLess',
  showMoreTextId: 'readMore.showMore',
  transitionDuration: 250,
};

export default ReadMore;
