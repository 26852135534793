import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Urls } from 'web/libs/routes';
import './step5.scss';

const Step5 = () => (
  <div className="productTour-step4-rightColumn-wrapper">
    <div className="productTour-step4-content">
      <h2 className="text-bold productTour-step4-title">
        <FormattedHTMLMessage id={'web.productTour.finishTitle'} />
      </h2>
      <br />
      <FormattedHTMLMessage id={'web.productTour.finishInfo'} />
      <div className="productTour-step4-links">
        <a href={Urls.salesforceLaunch}>
          <FormattedMessage id="web.productTour.finishSalesforceLink" />
        </a>
        <br />
        <br />
        <a href="#">
          <FormattedMessage id="web.productTour.finishWebAppLink" />
        </a>
      </div>
    </div>
  </div>
);

export default Step5;
