export default {
  initStore: 'PIPELINE_INIT_STORE',
  bestBets: 'PIPELINE_BEST_BETS',
  bestBetExtraData: 'BEST_BET_EXTRA_DATA',
  addOpenChildrenId: 'PIPELINE_ADD_OPEN_CHILDREN_ID',
  removeOpenChildrenId: 'PIPELINE_REMOVE_OPEN_CHILDREN_ID',
  updateBestBet: 'UPDATE_BEST_BET',
  updateBestBetSalesforce: 'UPDATE_BEST_BET_SALESFORCE',
  visible: 'PIPELINE_IS_VISIBLE',
  updateSalesforceInstanceUrl: 'UPDATE_SALESFORCE_INSTANCE_URL',
  pipelineErrorMessageId: 'PIPELINE_ERROR_MESSAGE_ID',
  clearPipelineErrorMessageId: 'CLEAR_PIPELINE_ERROR_MESSAGE_ID',
  clearBestBets: 'CLEAR_BEST_BETS',
  updateBestBets: 'UPDATE_BEST_BETS',
  markEventAsSeen: 'PIPELINE_MARK_EVENT_AS_SEEN',
};
