import { basePost, baseDelete, baseGet } from 'web/services/api';
import { Urls } from 'web/libs/constants';
import apiHelper from 'web/services/apiHelper';
import { navigateToUrl } from 'web/services/routerService';

export const getLogin = (username, password) => {
  const csrfToken = apiHelper.getCsrfTokenFromDocument();

  return new Promise((resolve, reject) => {
    const params = {
      authenticity_token: csrfToken,
      commit: 'Login',
      user_session: {
        email: username,
        password,
        remember_me: true,
      },
    };

    basePost(Urls.authenticate, params, resolve, reject);
  });
};

export const doLogout = () =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.authenticate, {}, resolve, reject);
  }).then(() => navigateToUrl(Urls.loginPage));

export const requestLoginMethod = (username) => {
  return new Promise((resolve, reject) => {
    const params = {
      email: username,
    };
    baseGet('/login_methods', params, resolve, reject);
  });
};
