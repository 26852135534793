import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { CampaignsSpecialCategories } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { getCampaignsCategories } from 'web/campaigns/campaignsTab/actionCreators/campaignsCategoriesActionCreators';
import { getCampaigns } from 'web/campaigns/campaignsTab/actionCreators/campaignsActionCreators';
import { navigateToCampaignCategory } from 'web/services/routerService';
import { setCurrentCategory } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabStartupActionCreators';
import { getViewer } from 'web/campaigns/campaignsTab/helpers/campaignsHelpers';

export const getCampaignsAndCategoriesForViewer = () => (dispatch) => {
  dispatch(setCurrentCategory(CampaignsSpecialCategories.all.id));
  dispatch(getCampaignsCategories());
  navigateToCampaignCategory(CampaignsSpecialCategories.all.id);
  dispatch(getCampaigns());
};

export const setViewer = (viewer) => ({
  type: ActionTypes.campaignsTab.state.setViewer,
  viewer,
});

export const setCampaignViewAsFilterInput = (inputValue = '') => ({
  type: ActionTypes.campaignsTab.state.setViewAsFilterInput,
  inputValue,
});

export const onCampaignViewAsFilterSelect = ({ label: name, value: id }) => (
  dispatch
) => {
  if (id) {
    const viewerWithType = getViewer(id, name);

    dispatch(setViewer(viewerWithType));
    dispatch(setCampaignViewAsFilterInput(name));
    dispatch(getCampaignsAndCategoriesForViewer());
  }
};
