import React from 'react';
import PropTypes from 'prop-types';
import './creatorCell.scss';

const CreatorCell = ({ rowData: { user: { name = '' } = { name: '' } } }) => (
  <div className="creator-cell text-overflow">{`${name}`}</div>
);

CreatorCell.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default CreatorCell;
