import ActionTypes from 'web/onboarding/lib/onboardingActionTypes';

export const completedStepsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.setCompletedStep:
      return [...state, action.completedStep];
    default:
      return state;
  }
};

export const onboardingAuthentications = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.getOnboardingAuthentications:
      return [...state, ...action.onboardingAuthentications];
    default:
      return state;
  }
};
