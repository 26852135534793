import React from 'react';
import PropTypes from 'prop-types';
import Cell from 'table/modules/cell';
import { BaseUrlImages } from 'web/libs/constants';
import 'table/modules/cells/dragAndDropCell/dragAndDropCell.scss';

const DragAndDropCell = (props) => {
  const { disabled, isHeader } = props;

  return (
    <Cell
      classes="drag-and-drop-cell"
      height={60}
      isHeader={isHeader}
      width={15}
    >
      {!isHeader && !disabled ? (
        <div className="drag-and-drop-icon">
          <img src={`${BaseUrlImages}/icon-grabber.svg`} />
        </div>
      ) : null}
    </Cell>
  );
};

DragAndDropCell.propTypes = {
  disabled: PropTypes.bool,
  isHeader: PropTypes.bool,
};

DragAndDropCell.defaultProps = {
  disabled: false,
  isHeader: false,
};

export default DragAndDropCell;
