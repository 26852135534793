import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { TaskPriority } from 'libs/constantsShared';
import './priorityCell.scss';

class PriorityCell extends Component {
  render() {
    const { className, rowData } = this.props;
    const classes = classNames(
      className,
      'cell-priority text-overflow text-medium',
      this._getColorClass(rowData.priority)
    );

    return (
      <div className={classes}>
        <span>
          <FormattedMessage id={this._getPriorityId(rowData.priority)} />
        </span>
      </div>
    );
  }

  _getColorClass(priority) {
    if (priority === TaskPriority.high) {
      return 'text-red';
    } else if (priority === TaskPriority.low) {
      return 'text-gray-light';
    } else {
      return 'text-blue';
    }
  }

  _getPriorityId(priority) {
    if (priority === TaskPriority.high) {
      return 'task.high';
    } else if (priority === TaskPriority.low) {
      return 'task.low';
    } else {
      return 'task.normal';
    }
  }
}

PriorityCell.propTypes = {
  className: PropTypes.string,
  rowData: PropTypes.object.isRequired,
};

PriorityCell.defaultProps = {
  className: '',
};

export default PriorityCell;
