import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Radio from 'components/buttons/radio';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import { EmailProviders } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import './outlookSelectionBody.scss';

const OutlookSelectionBody = (props) => {
  const { emailConnectionProvider, setEmailProvider } = props;

  const onChange = (value) => {
    setEmailProvider(value);
  };

  const getLabelText = (provider) => {
    switch (provider) {
      case EmailProviders.office365:
        return {
          id: 'web.settings.emailConnection.modal.useOffice365',
          tip: 'web.settings.emailConnection.modal.office365.tooltip',
          tooltipId: EmailProviders.office365,
        };
      case EmailProviders.onPremise:
        return {
          id: 'web.settings.emailConnection.modal.useOnPremise',
          tip: 'web.settings.emailConnection.modal.onPremise.tooltip',
          tooltipId: EmailProviders.onPremise,
        };
      default:
        return null;
    }
  };

  const createRadioLabelForProvider = (provider) => {
    const { id, tip, tooltipId } = getLabelText(provider);

    return (
      <div className="outlook-selection-radio-row">
        <FormattedMessage className="outlook-selection-radio-label" id={id} />
        <HoverTooltip
          className="outlook-selection-tooltip"
          delayHide={0}
          place={HoverTooltipPlace.right}
          textId={tip}
          tooltipId={tooltipId}
        >
          <i className="tout-icon-warning" />
        </HoverTooltip>
      </div>
    );
  };

  return (
    <div className="outlook-selection-body">
      <h5 className="outlook-selection-body-prompt">
        <FormattedMessage id="web.settings.emailConnection.modal.outlookPrompt" />
      </h5>
      <Radio
        checked={emailConnectionProvider === EmailProviders.office365}
        className="outlook-selection"
        label={createRadioLabelForProvider(EmailProviders.office365)}
        name="outlook-selection"
        onChange={onChange}
        value={EmailProviders.office365}
      />
      <Radio
        checked={emailConnectionProvider === EmailProviders.onPremise}
        className="outlook-selection"
        label={createRadioLabelForProvider(EmailProviders.onPremise)}
        name="outlook-selection"
        onChange={onChange}
        value={EmailProviders.onPremise}
      />
      <p className="outlook-selection-body-noclue">
        <FormattedMessage id="web.settings.emailConnection.modal.noClue.tooltip" />
      </p>
    </div>
  );
};

OutlookSelectionBody.propTypes = {
  emailConnectionProvider: PropTypes.string.isRequired,
  setEmailProvider: PropTypes.func.isRequired,
};

export default OutlookSelectionBody;
