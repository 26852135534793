import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Popup from 'components/popups/popup';
import { PopupSize } from 'libs/constantsShared';

class SalesforceCustomizationPreUpgradePopup extends Component {
  componentWillMount() {
    const { actionCreators } = this.props;

    actionCreators.setDefaultProcessValues();
  }

  render() {
    const { actionCreators } = this.props;

    return (
      <Popup
        className="salesforce-customization-popup"
        footer={{
          onPrimary: actionCreators.install,
          onSecondary: actionCreators.closePopup,
          primaryTextId: 'common.upgrade',
          secondaryTextId: 'common.cancel',
        }}
        header={{
          onClose: actionCreators.closePopup,
          textId: 'web.salesforceCustomization.popup.installation.title',
        }}
        onOutsideClick={actionCreators.closePopup}
        size={PopupSize.small}
      >
        <div className="margin-bottom-full">
          <FormattedHTMLMessage id="web.salesforceCustomization.popup.preUpgrade.content" />
        </div>
      </Popup>
    );
  }
}

SalesforceCustomizationPreUpgradePopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
};

export default SalesforceCustomizationPreUpgradePopup;
