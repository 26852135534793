/*eslint react/no-unused-prop-types: 0*/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EmailGridAdvancedSearch from 'web/commandCenter/modules/emailGridAdvancedSearch/components/emailGridAdvancedSearch/emailGridAdvancedSearch';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  searchEmailAdvancedSearch,
  setAdvancedSearchValue,
  clearAdvancedSearchValue,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/emailGridAdvancedSearchActionCreators';
import { clearAdvancedSearchFilters } from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/clearAdvancedSearchActionCreators';
import {
  openSaveSearchPopup,
  saveAdvancedSearchChanges,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/saveAdvancedSearchActionCreators';
import {
  getAdvancedSearchString,
  isSavedAdvancesSearchChanged,
  isSavedAdvancesSearch,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import AdvancedSearchPopupContainer from 'web/commandCenter/modules/emailGridAdvancedSearch/containers/advancedSearchPopupContainer';
import { getIsScheduleStatus } from 'web/commandCenter/modules/statusSmartFilter/selectors/statusSmartFilterSelectors';

const EmailGridAdvancedSearchContainer = (props) => (
  <Fragment>
    <EmailGridAdvancedSearch {...props} />
    <AdvancedSearchPopupContainer />
  </Fragment>
);

EmailGridAdvancedSearchContainer.propTypes = {
  searchValue: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearAdvancedSearchFilters,
      clearAdvancedSearchValue,
      closePopup,
      openSaveSearchPopup,
      saveAdvancedSearchChanges,
      searchEmailAdvancedSearch,
      setAdvancedSearchValue,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  isSavedAdvancesSearch: isSavedAdvancesSearch(state),
  isSavedAdvancesSearchChanged: isSavedAdvancesSearchChanged(state),
  isScheduleStatus: getIsScheduleStatus(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  }),
  searchValue: getAdvancedSearchString(state, {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailGridAdvancedSearchContainer);
