import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { PreviewViewTypes } from 'libs/constantsShared';
import classNames from 'classnames';
import './failedContactImportItem.scss';

class FailedContactImportItem extends Component {
  render() {
    const { className, failures, simple, selected } = this.props;
    const totalFailed = Object.values(failures).reduce(
      (itemA, itemB) => itemA + itemB
    );
    const classes = classNames(className, {
      active: selected && this._onSelect,
      clickable: this._onSelect,
    });

    return (
      <div className={classes} onClick={this._onSelect}>
        <div className="text-medium invalid-item">
          <FormattedMessage
            id="failedContactImportItem.peopleFailedToImport"
            values={{ count: totalFailed, simple }}
          />
        </div>
      </div>
    );
  }

  _onSelect = () => {
    this.props.onSelect(0, PreviewViewTypes.failedImports);
  };
}

FailedContactImportItem.propTypes = {
  className: PropTypes.string.isRequired,
  failures: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  simple: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default injectIntl(FailedContactImportItem);
