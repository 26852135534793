import { Urls } from 'web/libs/routes';
import { baseGet, basePost, baseDelete } from 'web/services/api';

export const getInvitations = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.invitations(), {}, resolve, reject);
  });

export const deleteInvitation = (invitationId) =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.invitations(invitationId), {}, resolve, reject);
  });

export const resendInvitation = (invitationId) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.invitationsResend(invitationId), {}, resolve, reject);
  });

export const batchDestroyInvitations = (invitationIds = []) =>
  new Promise((resolve, reject) => {
    const params = { invitation_ids: invitationIds };
    basePost(Urls.invitationsBatchDestroy, params, resolve, reject);
  });

export const batchResendInvitations = (invitationIds = []) =>
  new Promise((resolve, reject) => {
    const params = { invitation_ids: invitationIds };
    basePost(Urls.invitationsBatchResend, params, resolve, reject);
  });

export const inviteManyUsers = ({
  emails = [],
  workspaces = {},
  teamIds = [],
}) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.invitationsInviteMany,
      {
        emails,
        team_ids: teamIds,
        workspaces,
      },
      resolve,
      reject
    );
  });

export const resendInvitationByEmail = (email) =>
  new Promise((resolve, reject) => {
    baseGet(Urls.invitationsResendByEmail(email), {}, resolve, reject);
  });
