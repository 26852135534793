import React from 'react';
import ProgressBar from 'components/progressBar';
import { bool, number, object } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './bulkUpdateProgress.scss';

const PROGRESS_BAR_HEIGHT = 15;

const BulkUpdateProgress = (props) => {
  const {
    intl: { formatMessage, formatHTMLMessage },
    isComplete,
    progress,
    results: { successes, errors },
  } = props;

  const generateMessage = () => {
    let message = formatMessage({ id: 'web.bulkUpdate.completeMessage' });
    if (successes.length) {
      const successMessage = formatHTMLMessage(
        { id: 'web.bulkUpdate.successMessage' },
        { success: successes.length }
      );
      message += ` ${successMessage}`;
    }
    if (errors.length) {
      const errorMessage = formatHTMLMessage(
        { id: 'web.bulkUpdate.errorMessage' },
        { errors: errors.length }
      );
      message += ` ${errorMessage}`;
    }
    return message;
  };

  const processingMessage = isComplete
    ? 'web.bulkUpdate.processingComplete'
    : 'web.bulkUpdate.processing';

  return (
    <div>
      <div className="margin-bottom-full">
        <FormattedMessage id={processingMessage} />
      </div>
      <ProgressBar
        progress={progress}
        height={PROGRESS_BAR_HEIGHT}
        animate={!isComplete}
      />
      <div className="margin-top-full margin-bottom-quarter bulk-update-message">
        {isComplete && generateMessage()}
      </div>
    </div>
  );
};

BulkUpdateProgress.propTypes = {
  intl: intlShape.isRequired,
  isComplete: bool.isRequired,
  progress: number.isRequired,
  results: object.isRequired,
};

export default injectIntl(BulkUpdateProgress);
