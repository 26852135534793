import BccActionTypes from '../libs/bccActionTypes';
import { getBccIntegration as getBccIntegrationCall } from '../services/bccService';
import {
  updateContactIntegration as updateContactIntegrationCall,
  deleteContactIntegration as deleteContactIntegrationCall,
  createContactIntegration as createContactIntegrationCall,
} from 'web/contactIntegrations/services/contactIntegrationsService';
import { getBccIntegration as getBccIntegrationSelector } from '../selectors/bccSelector';

export const setIntegration = (item = {}) => ({
  type: BccActionTypes.setIntegration,
  item,
});

export const setIntegrationFetching = (fetching = true) => ({
  type: BccActionTypes.setIntegrationFetching,
  fetching,
});

export const setIntegrationFetched = (fetched = true) => ({
  type: BccActionTypes.setIntegrationFetched,
  fetched,
});

export const getBccIntegration = () => (dispatch) => {
  dispatch(setIntegrationFetching());
  return getBccIntegrationCall()
    .then((integration) => {
      dispatch(setIntegration(integration));
      dispatch(setIntegrationFetched());
      return integration;
    })
    .finally(() => {
      dispatch(setIntegrationFetching(false));
    });
};

export const updateBccIntegration = (data = {}) => (dispatch, getState) => {
  const { service, url, token, id } = getBccIntegrationSelector(getState());
  const newIntegration = { service, url, token, ...data };
  const apiCall = id
    ? updateContactIntegrationCall
    : createContactIntegrationCall;
  const apiCallArguments = id ? [id, newIntegration] : [newIntegration];
  dispatch(setIntegrationFetching());
  return apiCall(...apiCallArguments)
    .then((integration) => {
      dispatch(setIntegration(integration));
      return integration;
    })
    .finally(() => {
      dispatch(setIntegrationFetching(false));
    });
};

export const deleteBccIntegration = () => (dispatch, getState) => {
  const { id } = getBccIntegrationSelector(getState());
  dispatch(setIntegrationFetching());
  return deleteContactIntegrationCall(id)
    .then(() => dispatch(getBccIntegration()))
    .finally(() => {
      dispatch(setIntegrationFetching(false));
    });
};
