import React from 'react';
import PropTypes from 'prop-types';
import { TEAM_CAMPAIGN_LIMIT_FORM_ID } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import CampaignLimitsForm from 'web/settings/adminSettings/general/components/campaignLimitsForm';

const CampaignLimits = (props) => {
  const {
    teamCampaignLimit,
    onTeamCampaignLimitFormError,
    subscriptionCampaignLimit,
    actionCreators: { updateTeamEmailLimit },
  } = props;

  return (
    <CampaignLimitsForm
      form={TEAM_CAMPAIGN_LIMIT_FORM_ID}
      initialValues={{ teamCampaignLimit }}
      onError={onTeamCampaignLimitFormError}
      onSubmit={updateTeamEmailLimit}
      subscriptionCampaignLimit={subscriptionCampaignLimit}
    />
  );
};

CampaignLimits.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  onTeamCampaignLimitFormError: PropTypes.func.isRequired,
  subscriptionCampaignLimit: PropTypes.number.isRequired,
  teamCampaignLimit: PropTypes.string.isRequired,
};

export default CampaignLimits;
