import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './metric.scss';

class Metric extends Component {
  render() {
    const { value, percent } = this.props;
    const classes = this._getClass(value, percent, typeof value === 'string');

    return (
      <div className={`metric-value ${classes}`}>
        {`${value || 0}${percent ? '%' : ''}`}
      </div>
    );
  }

  _getClass(value, percent, isString) {
    if (percent && value > 50) {
      return 'text-green';
    } else if (percent) {
      return 'text-yellow';
    } else if (isString) {
      return 'text-gray-light';
    } else {
      return 'text-gray-dark';
    }
  }
}

Metric.propTypes = {
  percent: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Metric.defaultProps = {
  percent: false,
  value: null,
};

export default Metric;
