import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button, { ButtonColors } from 'components/buttons/action';
import Label from 'components/label';
import Text from './inputs/text';
import {
  IDENTITY_FORM_ID,
  LABEL_WIDTH,
} from '../../../libs/editIdentitySlideOutConstants';

class Editing extends Component {
  render() {
    const {
      handleSubmit,
      onCancel,
      isCreateNewIdentityMode,
      isIdentityFormDirty,
      onValidate,
    } = this.props;

    return (
      <Form
        className="identity-details identity-form"
        onSubmit={handleSubmit}
        autoComplete="false"
      >
        <div className="identity-section">
          <h4 className="identity-section-title">
            <FormattedMessage id="common.identity" />
          </h4>
          <div className="identity-field-item">
            <Label
              id="identity-name"
              className="identity-field-label required"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.name"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field name="name" component={Text} autoFocus />
            </Label>
          </div>
          <div className="identity-field-item">
            <Label
              id="identity-title"
              className="identity-field-label"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.title"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field name="title" component={Text} autoFocus={false} />
            </Label>
          </div>
          <div className="identity-field-item">
            <Label
              id="identity-company"
              className="identity-field-label"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.company"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field name="company" component={Text} autoFocus={false} />
            </Label>
          </div>
          <br className="clear" />
        </div>

        <div className="identity-section">
          <h4 className="identity-section-title">
            <FormattedMessage id="common.address" />
          </h4>
          <div className="identity-field-item">
            <Label
              id="identity-email"
              className="identity-field-label required"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.email"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field name="email" component={Text} autoFocus={false} />
            </Label>
          </div>
          <div className="identity-field-item">
            <Label
              id="identity-website"
              className="identity-field-label"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.website"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field
                name="website"
                component={Text}
                autoFocus={false}
                onMouseOut={onValidate}
              />
            </Label>
          </div>
          <div className="identity-field-item">
            <Label
              id="identity-address"
              className="identity-field-label"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.mailing"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field name="address" component={Text} autoFocus={false} />
            </Label>
          </div>
          <div className="identity-field-item">
            <Label
              id="identity-mobile"
              className="identity-field-label"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.mobile"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field
                name="mobile_phone"
                component={Text}
                autoFocus={false}
                onMouseOut={onValidate}
              />
            </Label>
          </div>
          <div className="identity-field-item">
            <Label
              id="identity-work"
              className="identity-field-label"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.workPhone"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field
                name="work_phone"
                component={Text}
                autoFocus={false}
                onMouseOut={onValidate}
              />
            </Label>
          </div>
          <br className="clear" />
        </div>

        <div className="margin-top-half actions">
          {!isCreateNewIdentityMode && (
            <Button color={ButtonColors.gray} onClick={onCancel}>
              <FormattedMessage id="common.cancel" />
            </Button>
          )}
          <Button
            color={ButtonColors.blue}
            disabled={!isIdentityFormDirty}
            type="submit"
          >
            <FormattedMessage
              id={isCreateNewIdentityMode ? 'common.create' : 'common.save'}
            />
          </Button>
        </div>
      </Form>
    );
  }
}

Editing.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  isCreateNewIdentityMode: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  isIdentityFormDirty: PropTypes.bool.isRequired,
};

export default reduxForm({ form: IDENTITY_FORM_ID })(injectIntl(Editing));
