import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Form, Field, reduxForm } from 'redux-form';
import { getOptionMapper } from 'web/form/helpers/formHelpers';
import {
  SYNC_TASKS_OPTIONS,
  DONT_SYNC_TASKS_OPTIONS,
} from '../../libs/salesforceConstants';
import Radio from 'web/form/components/fields/radio/radio';
import './syncTasksForm.scss';
import defer from 'lodash/defer';
import { compose } from 'redux';

const FIELD_SKIPPED_TASK_ACTION = 'skipped_task_action';
const FIELD_SYNC_TASKS = 'sync_tasks';

class SyncTasksForm extends Component {
  render() {
    const { disabled, handleSubmit } = this.props;
    return (
      <Form
        className="salesforce-sync-tasks-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="skipped-task-field-wrapper">
          {this.selectOptions.map((option) => (
            <Field
              key={option.label}
              name={FIELD_SKIPPED_TASK_ACTION}
              component={Radio}
              label={option.label}
              value={option.value}
              onChange={this.onSkippedTaskClick}
              disabled={disabled}
              type="radio"
            />
          ))}
          {this.selectOptionsDontSync.map((option) => (
            <Field
              key={option.label}
              name={FIELD_SYNC_TASKS}
              component={Radio}
              label={option.label}
              value={option.value}
              onChange={this.onSyncTasksClick}
              disabled={disabled}
              type="radio"
            />
          ))}
        </div>
      </Form>
    );
  }

  onSkippedTaskClick = (e, skippedTaskAction) =>
    this.onRadioClick({
      [FIELD_SKIPPED_TASK_ACTION]: skippedTaskAction,
      [FIELD_SYNC_TASKS]: true,
    });

  onSyncTasksClick = (e, syncTasks) =>
    this.onRadioClick({
      [FIELD_SYNC_TASKS]: syncTasks,
    });

  onRadioClick = (params = {}) => {
    const { submit, initialize } = this.props;
    initialize(params); // Avoid 2 radio controls switched ON while api call performed
    defer(submit);
  };

  get selectOptions() {
    const {
      intl: { formatMessage },
    } = this.props;

    if (!this._selectOptions) {
      this._selectOptions = SYNC_TASKS_OPTIONS.map(
        getOptionMapper(formatMessage)
      );
    }

    return this._selectOptions;
  }

  get selectOptionsDontSync() {
    const {
      intl: { formatMessage },
    } = this.props;

    if (!this._selectOptionsDontSync) {
      this._selectOptionsDontSync = DONT_SYNC_TASKS_OPTIONS.map(
        getOptionMapper(formatMessage)
      );
    }

    return this._selectOptionsDontSync;
  }
}

SyncTasksForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SyncTasksForm.defaultProps = {
  disabled: false,
};

export default compose(
  reduxForm(),
  injectIntl
)(SyncTasksForm);
