/**
 *
 * LinkCell
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './linkCell.scss';

class LinkCell extends PureComponent {
  render() {
    const {
      className,
      formatText,
      link,
      openBlank,
      property,
      rowData,
      style,
    } = this.props;
    const containerClasses = classNames(
      className,
      'cell-link',
      'text-overflow'
    );
    const text = formatText ? formatText(rowData) : rowData[property];

    return (
      <div className={containerClasses} style={style}>
        <a
          className="text-overflow"
          href={link(rowData)}
          onClick={this._onClick}
          target={openBlank ? '_blank' : ''}
        >
          {text}
        </a>
      </div>
    );
  }

  _onClick = (e) => {
    const { onClick, rowData } = this.props;
    if (onClick) {
      e.stopPropagation();
      e.preventDefault();
      onClick(rowData);
    }
  };
}

LinkCell.propTypes = {
  className: PropTypes.string,
  formatText: PropTypes.func,
  link: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  openBlank: PropTypes.bool,
  property: PropTypes.string,
  rowData: PropTypes.object.isRequired,
  style: PropTypes.object,
};

LinkCell.defaultProps = {
  className: '',
  formatText: undefined,
  link: () => '',
  onClick: undefined,
  openBlank: false,
  property: 'id',
  rowData: {},
  style: {},
};

export default LinkCell;
