import TeamManagementActionTypes from '../libs/teamManagementActionTypes';
import { TeamManagementTabs } from '../libs/teamManagementConstants';

export const activeTab = (
  state = TeamManagementTabs.teamMembers,
  { type, tab }
) => {
  switch (type) {
    case TeamManagementActionTypes.setTab:
      return tab;
    default:
      return state;
  }
};
