import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './slideOutPaneHeaderDetails.scss';

const SlideOutPaneHeaderDetails = ({
  bottomAlign,
  edit,
  rightChildren,
  leftChildren,
  text,
}) => {
  const headerClasses = classNames(
    'slide-out-pane-header-details-title',
    'text-overflow',
    {
      'can-edit': edit,
    }
  );
  const rightChildrenClasses = classNames(
    'slide-out-pane-header-details-right-children',
    {
      'slide-out-pane-header-details-right-children--bottom': bottomAlign,
    }
  );
  const leftChildrenClasses = classNames(
    'slide-out-pane-header-details-left-children',
    {
      'slide-out-pane-header-details-left-children--bottom': bottomAlign,
    }
  );

  return (
    <div className="slide-out-pane-header-details">
      <div className="slide-out-pane-header-details-left text-overflow">
        <div className={leftChildrenClasses}>{leftChildren}</div>
        <h2 className={headerClasses} onClick={edit}>
          {text}
        </h2>
      </div>
      <div className={rightChildrenClasses}>{rightChildren}</div>
    </div>
  );
};

SlideOutPaneHeaderDetails.propTypes = {
  bottomAlign: PropTypes.bool,
  edit: PropTypes.func,
  rightChildren: PropTypes.node,
  leftChildren: PropTypes.node,
  text: PropTypes.string,
};

SlideOutPaneHeaderDetails.defaultProps = {
  bottomAlign: false,
  edit: undefined,
  rightChildren: undefined,
  leftChildren: undefined,
  text: '',
};

export default SlideOutPaneHeaderDetails;
