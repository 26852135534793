import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import CampaignDayStepHeaderNarrow from './campaignDayStepHeaderNarrow';
import CampaignDayStepHeaderWide from './campaignDayStepHeaderWide';
import CampaignEditEmailTemplateContainer from '../../containers/campaignEditEmailTemplate/campaignEditEmailTemplateContainer';
import CampaignEditReminder from '../campaignEditReminder';
import { canEditCampaign } from 'web/campaigns/campaignsTab/services/campaignService';
import { DragSource as dragSource } from 'react-dnd';
import { CampaignStepActions, DragTypes } from 'web/libs/constants';
import classnames from 'classnames';
import './campaignDayStep.scss';

const cardSource = {
  beginDrag(props) {
    return { noop: props.noop, step: props.step };
  },
  canDrag(props) {
    return (
      canEditCampaign(props.selectedCampaign) &&
      !props.editingSteps[props.step.id]
    );
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class CampaignDayStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.id = `day-step-${props.step.id}`;
    this.SMOOTH_SCROLL_SIZE = 10;
    this.CAMPAIGN_DAY_CARD_WIDTH = 590;
  }

  // don't changed this method because it may cause incomprehensible behavior
  // just add disable line for eslint
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.state.open && !nextProps.editingSteps[nextProps.step.id]) {
      this.setState({ open: false });
    }
  }

  _readableAction = () => {
    const {
      step: { action },
      intl: { formatMessage },
    } = this.props;
    switch (action) {
      case 'make_call':
        return formatMessage({ id: 'web.campaigns.makeCall' });
      case 'send_inmail':
        return formatMessage({ id: 'web.campaigns.sendInmail' });
      case 'send_email':
        return formatMessage({ id: 'web.campaigns.sendEmail' });
      case 'custom_step':
        return formatMessage({ id: 'web.campaigns.customStep' });
      case 'email_reminder':
        return formatMessage({ id: 'web.campaigns.emailReminder' });

      default:
        return action.replace(/_/gi, ' ');
    }
  };

  editStep = (e) => {
    e.preventDefault();
    this.setState(
      (prevState) => ({ open: !prevState.open }),
      () => {
        if (this.state.open) {
          this._smoothScroll();
        }
      }
    );
  };

  deleteStep = (e) => {
    e.preventDefault();
    const { step, actionCreators } = this.props;
    actionCreators.deleteCampaignStep(step);
  };

  onCancel = () => {
    this.setState({ open: false });
  };

  onAction = (id, editingStep) => {
    const { actionCreators, step } = this.props;

    if (
      step.action === CampaignStepActions.email ||
      step.action === CampaignStepActions.emailTask
    ) {
      actionCreators.updateEmailStep(id, editingStep);
    } else {
      actionCreators.updateStep(id, editingStep);
    }
  };

  render() {
    const {
      attachments,
      actionCreators,
      campaignEditStates,
      campaignTemplates,
      ckeBodies,
      connectDragSource,
      editingSteps,
      isFirstEmailStep,
      isDragging,
      selectedCampaign,
      step,
      timezone,
      timezones,
      wide,
      userCanEditCampaigns,
    } = this.props;
    const { open } = this.state;
    const editingStep = editingSteps[step.id];
    const readableAction = this._readableAction();
    const headerProps = {
      deleteStep: this.deleteStep,
      editStep: this.editStep,
      readableAction,
      selectedCampaign,
      step,
      timezones,
      userCanEditCampaigns,
    };
    const disableEdit =
      !canEditCampaign(selectedCampaign) || !userCanEditCampaigns;
    const dayStepClasses = classnames('campaign-day-step draggable', {
      dragging: isDragging,
      uneditable: disableEdit,
    });

    return connectDragSource(
      <div className={dayStepClasses} id={this.id}>
        {wide ? (
          <CampaignDayStepHeaderWide {...headerProps} />
        ) : (
          <CampaignDayStepHeaderNarrow {...headerProps} />
        )}
        {open &&
        this._isCampaignEditEmailTemplateContainer(
          step,
          CampaignStepActions
        ) ? (
          <CampaignEditEmailTemplateContainer
            attachments={attachments}
            campaignEditStates={campaignEditStates}
            campaignTemplates={campaignTemplates}
            ckeBodies={ckeBodies}
            destroyState={actionCreators.destroyCampaignEditState}
            editingStep={editingStep}
            initState={actionCreators.initCampaignEditEmailState}
            isFirstEmailStep={isFirstEmailStep}
            onAction={this.onAction}
            onCancel={this.onCancel}
            previewMode={disableEdit}
            selectedCampaign={selectedCampaign}
            step={step}
            timezone={timezone}
            timezones={timezones}
            updateCampaignEditState={actionCreators.updateCampaignEditState}
            wide={wide}
          />
        ) : null}
        {open && this._isCampaignEditReminder(step, CampaignStepActions) ? (
          <CampaignEditReminder
            action={step.action}
            campaignEditStates={campaignEditStates}
            createState={actionCreators.initCampaignEditReminderState}
            destroyState={actionCreators.destroyCampaignEditState}
            editingStep={editingStep}
            onAction={this.onAction}
            onCancel={this.onCancel}
            previewMode={disableEdit}
            step={step}
            updateCampaignEditState={actionCreators.updateCampaignEditState}
          />
        ) : null}
      </div>
    );
  }

  _isCampaignEditEmailTemplateContainer = (step, CampaignStepActions) =>
    step.action === CampaignStepActions.email ||
    step.action === CampaignStepActions.emailTask;

  _isCampaignEditReminder = (step, CampaignStepActions) =>
    step.action === CampaignStepActions.call ||
    step.action === CampaignStepActions.inmail ||
    step.action === CampaignStepActions.custom;

  // Same as CampaignDayCardNewStep scrolling
  _smoothScrollUp = (div, offset, initOffset) => {
    const offsetNormalized =
      offset > this.SMOOTH_SCROLL_SIZE ? this.SMOOTH_SCROLL_SIZE : offset;

    if (offsetNormalized > 0 && div.scrollTop < initOffset) {
      div.scrollTop += offsetNormalized; // eslint-disable-line no-param-reassign

      setTimeout(
        window.requestAnimationFrame(() =>
          this._smoothScrollUp(
            div,
            offset - this.SMOOTH_SCROLL_SIZE,
            initOffset
          )
        ),
        this.SMOOTH_SCROLL_SIZE / 2
      );
    }
  };

  _setLeft(scrollingDiv, stepDiv) {
    const offsetRight =
      stepDiv.offsetParent.offsetLeft + this.CAMPAIGN_DAY_CARD_WIDTH;

    if (offsetRight > scrollingDiv.offsetWidth) {
      const newLeft =
        offsetRight - scrollingDiv.offsetWidth + scrollingDiv.scrollLeft;
      window.requestAnimationFrame(() => {
        scrollingDiv.scrollLeft = newLeft; // eslint-disable-line no-param-reassign
      });
    }
  }

  _smoothScroll = () => {
    const scrollingDiv = document.getElementsByClassName(
      'campaign-tab-view'
    )[0];
    const stepDiv = document.getElementById(this.id);
    const offset =
      stepDiv.offsetTop +
      stepDiv.offsetParent.offsetTop -
      scrollingDiv.scrollTop;

    this._smoothScrollUp(scrollingDiv, offset, offset);

    if (!this.props.wide) {
      this._setLeft(scrollingDiv, stepDiv);
    }
  };
}

CampaignDayStep.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  attachments: PropTypes.object.isRequired,
  campaignEditStates: PropTypes.object.isRequired,
  campaignTemplates: PropTypes.array.isRequired,
  ckeBodies: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  editingSteps: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isFirstEmailStep: PropTypes.bool,
  noop: PropTypes.array.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  timezones: PropTypes.array.isRequired,
  wide: PropTypes.bool,
};
CampaignDayStep.defaultProps = {
  isFirstEmailStep: undefined,
  wide: undefined,
};

export default dragSource(DragTypes.step, cardSource, collect)(
  injectIntl(CampaignDayStep)
);
