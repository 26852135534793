import ActionTypes from 'web/libs/actionTypes/actionTypes';

export const campaignFilterLoading = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.campaignsTab.state.contentViewLoading:
      return action.loading;
    default:
      return state;
  }
};
