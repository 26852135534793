import React from 'react';
import PropTypes from 'prop-types';
import { insertContentPartnerTrackedContent } from 'web/composeWindow/helpers/composeWindowHelpers';
import ContentPartnerIframe from 'web/modals/addContent/components/contentPartnerIframe/contentPartnerIframe';
import { HIGHSPOT_VALUE } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import apiHelper from 'web/services/apiHelper';

const HighSpotContentWindow = ({
  contentPartners,
  closeFileAttachmentModal,
  editorInstance,
  getContentPartnersMetadata,
  partnerMetadata,
}) => {
  const csrfToken = apiHelper.getCsrfTokenFromDocument();
  if (csrfToken) {
    apiHelper.setCsrfToken(csrfToken);
  }

  const { partners } = contentPartners;
  const highSpotPartner = partners.find(({ name }) => name === HIGHSPOT_VALUE);

  return (
    <ContentPartnerIframe
      addContent={(data) =>
        insertContentPartnerTrackedContent(data, editorInstance)
      }
      closePopup={closeFileAttachmentModal}
      getPartnerMetadata={getContentPartnersMetadata}
      partner={highSpotPartner}
      partnerMetadata={partnerMetadata}
      windowMode={false}
    />
  );
};

HighSpotContentWindow.propTypes = {
  closeFileAttachmentModal: PropTypes.func.isRequired,
  contentPartners: PropTypes.array.isRequired,
  editorInstance: PropTypes.object.isRequired,
  getContentPartnersMetadata: PropTypes.func.isRequired,
  partnerMetadata: PropTypes.object.isRequired,
};

export default HighSpotContentWindow;
