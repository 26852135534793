import { createSelector } from 'reselect';
import { getAdvancedSearchTableId } from 'web/commandCenter/modules/emailGridAdvancedSearch/helpers/advancedSearchHelpers';
import { getSmartFiltersValues } from 'web/tables/smartFilters/selectors/smartFiltersSelectors';

const getPropsCollectionId = (state, props) =>
  (props && props.collectionId) || getAdvancedSearchTableId();

export const getActiveFilters = createSelector(
  [getSmartFiltersValues, getPropsCollectionId],
  (smartFilters, collectionId) => {
    if (smartFilters && smartFilters[collectionId]) {
      const filters = smartFilters[collectionId];
      return Object.keys(filters).map((filterId) => ({
        filterId,
        ...filters[filterId],
      }));
    }

    return undefined;
  }
);
