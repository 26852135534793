export const getItemStyles = (offset) => {
  if (!offset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = offset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};
