import MarketoCampaignsActionTypes from 'web/marketo/campaigns/libs/marketoCampaignsActionTypes';
import { getMarketoCampaigns as getMarketoCampaignsCall } from 'web/marketo/campaigns/services/marketoCampaignsService';

export const setMarketoCampaigns = (
  marketoCampaigns,
  marketoWorkspaceName
) => ({
  type: MarketoCampaignsActionTypes.campaigns.set,
  marketoCampaigns,
  marketoWorkspaceName,
});

export const getMarketoCampaigns = (campaignParams = {}) => (
  dispatch,
  getState
) => {
  const { workspaceName: marketoWorkspaceName } = campaignParams;

  return getMarketoCampaignsCall(campaignParams).then(
    ({ result: marketoCampaigns }) => {
      dispatch(setMarketoCampaigns(marketoCampaigns, marketoWorkspaceName));
    }
  );
};
