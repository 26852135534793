import TableExternals from 'web/libs/tableExternals';

export const inviteesTableExternals = new TableExternals();

export const formatInviteUsersRequestParams = (
  emails = [],
  workspaceOptions = [],
  teamsOptions = []
) => {
  const params = { emails, teamIds: teamsOptions };

  if (workspaceOptions.length) {
    params.workspaces = emails.reduce(
      (accumulator, email) => ({
        ...accumulator,
        [email]: workspaceOptions,
      }),
      {}
    );
  }

  return params;
};
