import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  EmailServerAlertIds,
  EmailServerValidatorTypes,
} from '../../libs/emailServerConstants';
import Alert, { AlertColors } from 'components/alert';
import './emailServerAlert.scss';

class EmailServerAlert extends PureComponent {
  render() {
    const alert = this.getAlert();

    return alert ? <Alert {...alert} /> : null;
  }

  getAlert = () => {
    const {
      intl: { formatMessage },
      alert: { id, values },
      closeViewAlert,
    } = this.props;
    const defaultAlert = {
      classes: 'email-server-error-alert',
      color: AlertColors.red,
      onClose: closeViewAlert,
      manual: true,
    };

    switch (id) {
      case EmailServerAlertIds.connected:
        return {
          ...defaultAlert,
          manual: false,
          color: AlertColors.green,
          textId: 'web.settings.emailServer.alert.connected',
        };
      case EmailServerAlertIds.disconnected:
        return {
          ...defaultAlert,
          manual: false,
          color: AlertColors.green,
          textId: 'web.settings.emailServer.alert.disconnected',
        };
      case EmailServerAlertIds.error:
        return {
          ...defaultAlert,
          textId: 'web.settings.emailServer.smtpNotConnectedErrorMessage',
        };
      case EmailServerAlertIds.validationError: {
        const {
          field,
          validationError: { messageId, validatorParams, validatorType },
        } = values;

        if (
          [
            EmailServerValidatorTypes.notGmailSmtp,
            EmailServerValidatorTypes.notOffice365Smtp,
          ].includes(validatorType)
        ) {
          return { ...defaultAlert, html: true, textId: messageId };
        }

        const fieldLabel = formatMessage({
          id: `web.settings.emailServer.fieldLabel.${field}`,
        });
        const errorMessage = formatMessage(
          { id: messageId, html: true },
          { ...validatorParams }
        );

        return {
          ...defaultAlert,
          html: true,
          textId: 'common.validation.alert.formFieldErrorMessage',
          textValues: { fieldLabel, errorMessage },
        };
      }
      default:
        return null;
    }
  };
}

EmailServerAlert.propTypes = {
  intl: intlShape.isRequired,
  alert: PropTypes.object.isRequired,
  closeViewAlert: PropTypes.func.isRequired,
};

export default injectIntl(EmailServerAlert);
