import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './unfilledPromptInAutomaticEmail.scss';

class UnfilledPromptInAutomaticEmail extends Component {
  render() {
    const { notFilledPrompts } = this.props;

    const incorrectItems = notFilledPrompts.map((field, i) => (
      <li key={`unfilled-prompts-${field || i}`}>{field}</li>
    ));

    return (
      <div className="unfilled-prompt-in-automatic-email">
        <div className="explanation margin-bottom-full">
          <FormattedMessage id="unfilledPromptInAutomaticEmail.explanation" />
        </div>
        <div className="fields-list">
          <ul>{incorrectItems}</ul>
        </div>
      </div>
    );
  }
}

UnfilledPromptInAutomaticEmail.propTypes = {
  notFilledPrompts: PropTypes.array.isRequired,
};

export const UNFILLED_PROMPT_IN_AUTOMATIC_EMAIL_POPUP_WIDTH = 510;
export default UnfilledPromptInAutomaticEmail;
