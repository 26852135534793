import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import AccordionItem from 'components/accordion/item';
import AccordionItemHeader from 'components/accordion/item/header';
import AccordionItemBody from 'components/accordion/item/body';
import Page from 'components/layouts/page';
import PageView from 'components/layouts/page/view';
import Card from 'components/card';
import SyncTasksCard from '../../../syncTasksCard';

const SyncTaskItem = (props) => {
  const { accordionProps, isDirtySyncTasksFormAdmin } = props;

  const onToggle = () =>
    accordionProps.onToggle({
      shouldShowUnsavedPopup: isDirtySyncTasksFormAdmin,
    });

  return (
    <AccordionItem {...accordionProps} onToggle={onToggle}>
      <AccordionItemHeader>
        <FormattedMessage id="web.settings.accountSettings.salesforce.syncSettings.accordion.syncTasks.title" />
      </AccordionItemHeader>
      <AccordionItemBody>
        <Page>
          <PageView className="padding-15 sync-task-item">
            <Card>
              <SyncTasksCard {...props} />
            </Card>
          </PageView>
        </Page>
      </AccordionItemBody>
    </AccordionItem>
  );
};

SyncTaskItem.propTypes = {
  accordionProps: PropTypes.object.isRequired,
  isDirtySyncTasksFormAdmin: PropTypes.bool,
};

SyncTaskItem.defaultProps = {
  isDirtySyncTasksFormAdmin: false,
};

export default SyncTaskItem;
