import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Alert, { AlertColors } from 'components/alert';
import TemplateAlertIds from 'web/templates/libs/templateAlertIds';
import { TEMPLATE_SPECIAL_CATEGORY } from 'web/templates/libs/templatesConstants';
import filter from 'lodash/filter';
import I18N from 'languages';
class TemplatesAlert extends Component {
  render() {
    return <div>{this.getAlertComponent()}</div>;
  }

  getAlertComponent = () => {
    const {
      actionCreators,
      templatesAlert: {
        id,
        values: { successes, errors, updateAttributes },
      },
    } = this.props;
    const defaultProperties = {
      color: AlertColors.redDark,
      location: 'top',
      onClose: actionCreators.closeViewAlert,
    };

    switch (id) {
      case TemplateAlertIds.bulkUpdateError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.templates.bulkUpdate.errorMessage"
            textValues={{ errors: `${errors}` }}
          />
        );
      case TemplateAlertIds.bulkUpdateSuccess:
        const textId =
          updateAttributes.category == TEMPLATE_SPECIAL_CATEGORY.delete.name
            ? 'web.templates.bulkUpdate.successDestroyMessage'
            : 'web.templates.bulkUpdate.successMovedToMessage';
        const categoryName =
          (filter(TEMPLATE_SPECIAL_CATEGORY, { name: updateAttributes.category }).length > 0)
            ? I18N.getConstStr('common', updateAttributes.category)
            : updateAttributes.category;
        return (
          <Alert
            {...defaultProperties}
            action={actionCreators.undoBulkMoveTemplate}
            actionLabel="common.undo"
            color={AlertColors.greenDark}
            textId={textId}
            textValues={{
              category: categoryName,
              successes: `${successes}`,
            }}
          />
        );
      case TemplateAlertIds.bulkUndoSuccess:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.greenDark}
            delay={8000}
            textId="web.templates.bulkUpdate.undoSuccessMessage"
          />
        );
      case TemplateAlertIds.fetchError:
        return (
          <Alert {...defaultProperties} textId="web.templates.fetchError" />
        );
      case TemplateAlertIds.getCategoriesError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.templates.getCategoriesError"
          />
        );
      case TemplateAlertIds.syncUpdateError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.templates.updateTemplateError"
          />
        );
      case TemplateAlertIds.renameCategoryError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.templates.renameTemplateCategoryError"
          />
        );
      case TemplateAlertIds.renameDuplicateCategoryError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.templates.createDuplicateTemplateCategoryError"
          />
        );
      case TemplateAlertIds.deleteCategoryError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.templates.deleteTemplateCategoryError"
          />
        );
      case TemplateAlertIds.deleteCategoryNotEmptyError:
        return (
          <Alert
            {...defaultProperties}
            textId="web.templates.deleteTemplateCategoryNotEmptyError"
          />
        );
      case TemplateAlertIds.processing:
        return (
          <Alert
            {...defaultProperties}
            color={AlertColors.greenDark}
            textId="web.templates.bulkUpdate.processing"
          />
        );
      default:
        return null;
    }
  };
}

TemplatesAlert.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  templatesAlert: PropTypes.object.isRequired,
};

export default TemplatesAlert;
