import React, { PureComponent } from 'react';
import PeopleImportStepper from 'web/people/components/peopleImportStepper';
import './importPeopleStepThree.scss';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';

class ImportPeopleStepThree extends PureComponent {
  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="step-three">
        <PeopleImportStepper activeStep={3} />
        <div className="success-message">
          <h2>{formatMessage({ id: 'common.great' })}</h2>
          <p>
            <FormattedHTMLMessage id="web.people.importer.csvImportSuccess" />
          </p>
        </div>
      </div>
    );
  }
}

ImportPeopleStepThree.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ImportPeopleStepThree);
