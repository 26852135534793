import { commonEmailServerSuccess } from 'web/settings/emailServer/actionCreators/emailServerActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  getSmtpDeliveryChannel,
  deleteSmtpDeliveryChannel,
  createSmtpDeliveryChannel,
  updateSmtpDeliveryChannel,
} from 'web/deliveryChannels/actionCreators/smtpDeliveryChannelActionCreators';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { EmailServerAlertIds } from 'web/settings/emailServer/libs/emailServerConstants';
import { getErrorMessage } from 'web/settings/emailServer/helpers/emailServerHelpers';
import {
  SmtpServerAlertIds,
  SmtpServerPopupIds,
} from '../libs/smtpServerConstants';

export const saveSmtpServer = (model) => (dispatch, getState) => {
  const promise = getState().smtpDeliveryChannel
    ? dispatch(updateSmtpDeliveryChannel(model))
    : dispatch(createSmtpDeliveryChannel(model));

  promise
    .then(() => {
      dispatch(commonEmailServerSuccess());
      dispatch(openViewAlert(SmtpServerAlertIds.connected));
    })
    .catch((error) =>
      dispatch(openViewAlert(EmailServerAlertIds.error, getErrorMessage(error)))
    );
};

export const removeSmtpServer = () => (dispatch) => {
  dispatch(deleteSmtpDeliveryChannel())
    .then(() => {
      dispatch(commonEmailServerSuccess());
      dispatch(openViewAlert(EmailServerAlertIds.disconnected));
    })
    .catch((error) =>
      dispatch(openViewAlert(EmailServerAlertIds.error, getErrorMessage(error)))
    );
};

export const getSmtpServer = () => (dispatch) => {
  dispatch(getSmtpDeliveryChannel()).catch((error) =>
    dispatch(openViewAlert(EmailServerAlertIds.error, getErrorMessage(error)))
  );
};

export const showValidationGmailSmtpErrorMessage = () => (dispatch) => {
  dispatch(
    openViewAlert(SmtpServerAlertIds.serverValidationGmailSmtpErrorMessage)
  );
};

export const showValidationOutlookSmtpErrorMessage = () => (dispatch) => {
  dispatch(
    openViewAlert(SmtpServerAlertIds.serverValidationOutlookSmtpErrorMessage)
  );
};

export const openRemoveConfirmationPopup = () => (dispatch) => {
  dispatch(openPopup(SmtpServerPopupIds.removeConfirmation));
};
