import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';

const getUser = (state) => state.user;
export const userViewersFilterItems = createSelector(
  [getUser],
  ({ id, subscription: { users = [] } = {} } = {}) => {
    const userIndex = users.findIndex(
      ({ id: currentUserId }) => currentUserId === id
    );
    const currentUser = users[userIndex];
    const sortableUsers = [
      ...users.slice(0, userIndex),
      ...users.slice(userIndex + 1),
    ];

    const sortedUsers = sortBy(
      sortableUsers,
      ({ name }) => name && name.toLowerCase()
    );
    const allUsers = currentUser ? [currentUser, ...sortedUsers] : sortedUsers;

    return allUsers.map(({ id: value, name: label, email }) => ({
      label: label || email,
      value,
    }));
  }
);
