import ActionTypes from 'table/modules/actionTypes';
import trackerEvents from 'table/modules/tracker/trackerEvents';
import track from 'table/modules/tracker';
import { Pagination } from 'table/modules/constants';
import { localStorageInstance } from 'web/services/storageService';
import { LocalStorageKeys } from 'web/libs/constants';

export function initPagination(pagination, paginationItems) {
  return (dispatch) => {
    if (pagination) {
      const { clearSelected, currentPage, onClick, perPage } = pagination;

      /* Set default page when allItems */
      if (!onClick && currentPage) {
        dispatch({
          type: ActionTypes.paginationSetCurrentPage,
          page: currentPage,
        });
      }

      /* Turn on pagination */
      dispatch({ type: ActionTypes.paginationSet, set: true });
      dispatch({
        type: ActionTypes.paginationSetItems,
        items: paginationItems,
      });
      dispatch({ type: ActionTypes.paginationSetClearSelected, clearSelected });
      dispatch({
        type: ActionTypes.paginationSetPerPage,
        perPage,
      });
    }
  };
}

function checkSelected() {
  return (dispatch, getState) => {
    if (getState().paginationClearSelected) {
      dispatch({ type: ActionTypes.selectableClearSelectedItems });
    }
  };
}

export function onPaginationClick(nextPage) {
  track(trackerEvents.paginationSelected, { nextPage });
  return (dispatch) => {
    dispatch({
      type: ActionTypes.paginationSetCurrentPage,
      page: nextPage,
    });

    dispatch(checkSelected());
  };
}

export const changeRowsPerPage = (
  perPage = Pagination.perPageValues[0],
  onClickHandler = () => {}
) => (dispatch) => {
  dispatch({
    type: ActionTypes.paginationSetPerPage,
    perPage,
  });
  onClickHandler(perPage);
  localStorageInstance.setItem(LocalStorageKeys.tables.perPage, perPage);
};

/* Does not pass page to paginationSetCurrentPage to leave page number to parent. */
export function onPaginationClickProp(nextPage, onClickProp) {
  track(trackerEvents.paginationSelected, {});
  return (dispatch) => {
    dispatch({
      type: ActionTypes.paginationSetCurrentPage,
    });

    dispatch(checkSelected());
    onClickProp(nextPage);
  };
}
