import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import { setTablePage } from 'web/people/actionCreators/peopleTableActionCreators';
import { getPeople } from 'web/people/actionCreators/peopleFetchActionCreators';
import { DEFAULT_PAGE } from 'web/people/libs/peopleConstants';

export const peopleSmartFilterChange = (index, smartFilterValue) => (
  dispatch
) => {
  dispatch({
    type: PeopleActionTypes.smartFilters.set,
    value: smartFilterValue,
  });
  dispatch(setTablePage(DEFAULT_PAGE));
  return dispatch(getPeople());
};
