import React from 'react';
import PropTypes from 'prop-types';
import CategorySelect2 from '../categorySelect2';
import { injectIntl, intlShape } from 'react-intl';
import { PITCH_TEMPLATE } from 'web/templates/libs/templatesConstants';
import { isSpecialCategory } from 'web/templates/helpers/templatesHelper';
import I18N from 'languages';

const UpdateCategory = ({
  categories,
  currentCategoryId,
  intl: { formatMessage },
  selectedCategory,
  selectedMessage,
  updateCategory,
}) => {
  const { name, category_type } = categories.find(
    ({ id }) => id === currentCategoryId
  ) || { name: '' };
  const categoryName =
    category_type === PITCH_TEMPLATE && isSpecialCategory(name)
      ? I18N.getStr(`common.${name.toLowerCase()}`)
      : name;
  return (
    <div>
      <div className="margin-top-half margin-bottom-full">
        {selectedMessage && (
          <div className="text-medium">{selectedMessage}</div>
        )}
        <span className="text-medium margin-right-half">
          {`${formatMessage({
            id: 'web.campaigns.currentCategory',
          })}:`}
        </span>
        <span className="text-light">{categoryName}</span>
      </div>
      <CategorySelect2
        categories={categories}
        initCategoryId={currentCategoryId}
        selectedCategory={selectedCategory}
        updateCategory={updateCategory}
      />
    </div>
  );
};

UpdateCategory.propTypes = {
  categories: PropTypes.array,
  currentCategoryId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  selectedMessage: PropTypes.string,
  updateCategory: PropTypes.func.isRequired,
};

UpdateCategory.defaultProps = {
  categories: [],
  selectedMessage: '',
};

export default injectIntl(UpdateCategory);
