import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/buttons/icon';
import './marketoCampaignsCard.scss';
import MarketoCampaignsHistory from './marketoCampaignsHistory';

const MarketoCampaignsCard = ({ person: { marketoCampaigns } }) => (
  <div className="person-details-marketo-campaigns-card">
    <h3 className="margin-bottom-half">
      <FormattedMessage id="web.person.personDetails.marketoCampaign.header" />
    </h3>
    {marketoCampaigns && marketoCampaigns.length !== 0 ? (
      <div className="marketo-campaigns-info">
        <FormattedMessage id="web.person.personDetails.marketoCampaign.withCampaignHistoryInfo" />
      </div>
    ) : (
      <div className="marketo-campaigns-info-null">
        <div className="marketo-campaigns-tip-icon">
          <Icon classes="tip-icon" />
        </div>
        <div className="marketo-campaigns-info-tip-message">
          <FormattedMessage id="web.person.personDetails.marketoCampaign.noCampaignHistoryInfo" />
        </div>
      </div>
    )}
    {marketoCampaigns ? (
      <MarketoCampaignsHistory marketoCampaigns={marketoCampaigns} />
    ) : (
      <div className="person-details-marketo-campaigns-history">
        <FormattedMessage id="web.person.personDetails.marketoCampaign.noMarketoCampaigns" />
      </div>
    )}
  </div>
);

MarketoCampaignsCard.propTypes = {
  person: PropTypes.object.isRequired,
};

MarketoCampaignsCard.defaultProps = {};

export default MarketoCampaignsCard;
