export default {
  setInvitations: 'INVITATIONS_SET_INVITATIONS',
  setInvitationsFetching: 'INVITATIONS_SET_INVITATIONS_FETCHING',
  setInvitationsFetched: 'INVITATIONS_SET_INVITATIONS_FETCHED',
  deleteInvitation: 'INVITATIONS_DELETE',
  batchDestroyInvitations: 'INVITATIONS_BATCH_DESTROY',
  resendInvitation: 'INVITATIONS_RESEND',
  batchResendInvitations: 'INVITATIONS_BATCH_RESEND',
  updateInvitations: 'INVITATIONS_UPDATE_INVITATIONS',
};
