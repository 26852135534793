import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CampaignsUsingTemplateContainer from 'web/campaignsUsingTemplate/container/campaignsUsingTemplateContainer';
import Card from 'components/card';
import './campaignsView.scss';

class CampaignsView extends PureComponent {
  render() {
    const { templateId } = this.props;

    return (
      <div className="edit-template-campaigns-view">
        <Card>
          <CampaignsUsingTemplateContainer templateId={templateId} />
        </Card>
      </div>
    );
  }
}

CampaignsView.propTypes = {
  templateId: PropTypes.number.isRequired,
};

CampaignsView.defaultProps = {};

export default CampaignsView;
