import { combineReducers } from 'redux';
import TrackingActionTypes from '../libs/trackingActionTypes';

export const events = (state = [], action = {}) => {
  switch (action.type) {
    case TrackingActionTypes.setWebsiteTrackingEvents:
      return action.events;
    default:
      return state;
  }
};

export const fetching = (state = false, action = {}) => {
  switch (action.type) {
    case TrackingActionTypes.setWebsiteTrackingFetching:
      return action.fetching;
    default:
      return state;
  }
};

export default combineReducers({ events, fetching });
