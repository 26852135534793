import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function updateWhen(when) {
  return {
    type: ActionTypes.previewEmails.state.updateScheduled,
    data: { when },
  };
}

export function updateScheduledLaunch(scheduled) {
  return {
    type: ActionTypes.previewEmails.state.updateScheduled,
    data: scheduled,
  };
}
