import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  getPersonName,
  getUnsubscribe,
  isUnsubscribed,
} from 'web/person/helpers/personHelpers';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import './personDetailsHeader.scss';
import { BlockWay } from '../../../libs/personDetailsConstants';

class PersonDetailsHeader extends PureComponent {
  render() {
    const { person, unsubscribedFrom } = this.props;
    const unsubscribed = isUnsubscribed(person);
    const unsubscribedClassName = classNames('text-overflow', {
      'text-gray text-italic': unsubscribed,
    });

    return (
      <div className="person-details-header text-eclipse-overflow">
        <h2 className={`${unsubscribedClassName} margin-bottom-quarter`}>
          {getPersonName(person)}
        </h2>
        <h2 className={unsubscribedClassName}>{this._getWork()}</h2>
        {unsubscribed && (
          <div className="text-right">
            <h5 className="text-orange text-italic">
              <HoverTooltip
                getContent={this.getTip}
                place={HoverTooltipPlace.left}
                tooltipId="person-details-header-tooltip"
              >
                <FormattedMessage id="common.unsubscribed" />
              </HoverTooltip>
            </h5>
            {getUnsubscribe(person).block_way === BlockWay.email && (
              <span>{unsubscribedFrom}</span>
            )}
          </div>
        )}
      </div>
    );
  }

  _getWork = () => {
    const {
      company: { name: companyName = '' } = {},
      title,
    } = this.props.person;

    if (companyName && title) {
      return [
        <span className="margin-right-half" key="person-details-header-title">
          {title}
        </span>,
        <span key="person-details-header-separator">|</span>,
        <span className="margin-left-half" key="person-details-header-company">
          {companyName}
        </span>,
      ];
    } else {
      return companyName || title || null;
    }
  };

  getTip() {
    return (
      <FormattedMessage id="web.person.personDetails.header.unsubscribeTooltip" />
    );
  }
}

PersonDetailsHeader.propTypes = {
  person: PropTypes.object.isRequired,
  unsubscribedFrom: PropTypes.string,
};

PersonDetailsHeader.defaultProps = {
  unsubscribedFrom: '',
};

export default PersonDetailsHeader;
