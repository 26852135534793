import PopupActionTypes from '../libs/popupActionTypes';
import ActionTypes from 'web/libs/actionTypes/actionTypes';

export const popup = (state = '', { type, id }) => {
  switch (type) {
    case PopupActionTypes.open:
      return id;
    case ActionTypes.modal.state.close: // legacy
    case PopupActionTypes.close:
      return '';
    default:
      return state;
  }
};

export const popupData = (state = {}, { type, data }) => {
  switch (type) {
    case PopupActionTypes.open:
      return data || {};
    case PopupActionTypes.setData:
      return data;
    case ActionTypes.modal.state.close: // legacy - still needed
    case PopupActionTypes.close:
      return {};
    default:
      return state;
  }
};

const initLoading = false;
export const popupLoading = (
  state = initLoading,
  { type, isLoading = false }
) => {
  switch (type) {
    case PopupActionTypes.loading:
      return isLoading;
    case ActionTypes.modal.state.close: // legacy
    case PopupActionTypes.open:
    case PopupActionTypes.close:
      return initLoading;
    default:
      return state;
  }
};

export const popupAlert = (state = '', { type, id = '' }) => {
  switch (type) {
    case PopupActionTypes.alert:
      return id;
    case ActionTypes.modal.state.close: // legacy - still needed
    case PopupActionTypes.close:
      return '';
    default:
      return state;
  }
};

const initTextValues = {};
export const popupAlertTextValues = (
  state = initTextValues,
  { type, textValues = initTextValues }
) => {
  switch (type) {
    case PopupActionTypes.alert:
      return textValues;
    case ActionTypes.modal.state.close: // legacy - still needed
    case PopupActionTypes.close:
      return initTextValues;
    default:
      return state;
  }
};

export const popupOverlay = (state = '', { type, id = '' }) => {
  switch (type) {
    case PopupActionTypes.overlay:
      return id;
    case ActionTypes.modal.state.close: // legacy
    case PopupActionTypes.close:
      return '';
    default:
      return state;
  }
};

export const popupOverlayTextValues = (
  state = initTextValues,
  { type, textValues = initTextValues }
) => {
  switch (type) {
    case PopupActionTypes.overlay:
      return textValues;
    case ActionTypes.modal.state.close: // legacy
    case PopupActionTypes.close:
      return initTextValues;
    default:
      return state;
  }
};
