import { createSelector } from 'reselect';
import {
  isApplyTeamSettingsFlag,
  isActivityViaApiFlag,
  isSyncTasksFlag,
  getSalesforceData,
} from '../helpers/salesforceHelpers';
import { getLoading as accountSalesforceSettingsLoading } from 'web/settings/accountSettings/salesforce/selectors/syncSettingsSelectors';

const isAdminSettingsFetching = (state) => state.isAdminSettingsFetching;
const userSubscription = (state) => state.user.subscription;

export const isSalesforcePageFetching = createSelector(
  [isAdminSettingsFetching, accountSalesforceSettingsLoading],
  (adminSettingsFetching, accountLoading) =>
    adminSettingsFetching || accountLoading
);

export const getSalesforceOptions = createSelector(
  [userSubscription],
  getSalesforceData
);

export const isApplyTeamSettingsChecked = createSelector(
  [getSalesforceOptions],
  isApplyTeamSettingsFlag
);

export const isActivityViaApiChecked = createSelector(
  [getSalesforceOptions],
  isActivityViaApiFlag
);

export const isSyncTasksChecked = createSelector(
  [getSalesforceOptions],
  isSyncTasksFlag
);
