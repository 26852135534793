import AlertActionTypes from 'web/view/libs/alertActionTypes';

export function openViewAlert(id, values) {
  return {
    id,
    type: AlertActionTypes.view.open,
    values,
  };
}

export function closeViewAlert() {
  return {
    type: AlertActionTypes.view.close,
  };
}
