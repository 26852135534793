import apiHelper from 'web/services/apiHelper';
import {
  languagesOptions,
  localeOptions,
  defaultLocaleOptions,
} from '../libs/timezoneSettingsConstants';
import I18N from 'languages';

export const setToken = () => {
  if (document.querySelector('meta[name="csrf-token"]')) {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');
    if (csrfToken) {
      apiHelper.setCsrfToken(csrfToken);
    }
  }
};

export const parseItems = ([us = [], international = []] = []) => {
  const usSet = new Set(us.map(({ value }) => value));
  const rest = international.filter(({ value }) => !usSet.has(value));

  return [...us, ...rest].map(({ label, displayName: value }) => ({
    label,
    value,
  }));
};

export const parseFormValues = (values) =>
  Object.keys(values).reduce((acc, key) => {
    if (typeof values[key] === 'string') {
      return { ...acc, [key]: values[key] };
    }
    if (key === 'timezone') {
      return { ...acc, [key]: values[key].displayName };
    }
    return { ...acc, [key]: values[key].value };
  }, {});

export const filterLocalOptions = (language) => {
  const currentLanguage =
    typeof language === 'object' ? language.value : language;
  if (I18N.isQALangauge(currentLanguage)) {
    return localeOptions.filter(
      (option) => option.value === I18N.defaultLocale
    );
  } else {
    return localeOptions.filter(
      (option) => option.value.slice(0, 2) === currentLanguage
    );
  }
};

export const parseLanguageFormValue = (language) => {
  const newLanguage = language.length > 2 ? language.substr(0, 2) : language;
  return languagesOptions.filter((lang) => lang.value === newLanguage);
};

export const getDefaultLocaleFromLanguage = (language) => {
  const currentLanguage =
    typeof language === 'object' ? language.value : language;
  const newLanguage = I18N.isQALangauge(currentLanguage)
    ? I18N.defaultLanguage
    : currentLanguage;
  return defaultLocaleOptions.filter(
    (lang) => lang.value.substr(0, 2) === newLanguage
  );
};

export const parseLanguageItems = (languages) =>
  languages.map((item) => ({
    id: item.value,
    name: item.label,
  }));

export const parseObjectValues = (values) =>
  Object.keys(values).reduce((acc, key) => {
    if (typeof values[key] === 'string') {
      return { ...acc, [key]: values[key] };
    }
    return { ...acc, [key]: values[key].value };
  }, {});

export const isValuesEqual = (initialValues, currentValues) =>
  isFormFieldEqual(initialValues.timezone, currentValues.timezone) &&
  isFormFieldEqual(initialValues.language, currentValues.language) &&
  isFormFieldEqual(initialValues.locale, currentValues.locale);

export const isFormFieldEqual = (initialValue, currentValue) => {
  if (
    initialValue &&
    (initialValue.value || initialValue.displayName) &&
    currentValue
  ) {
    if (typeof currentValue === 'string') {
      return (
        initialValue.value === currentValue ||
        initialValue.displayName === currentValue
      );
    }
    return (
      initialValue.value === currentValue.value ||
      initialValue.displayName === currentValue.value
    );
  }
  return false;
};
