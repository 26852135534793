import React from 'react';
import PropTypes from 'prop-types';
import './twoPaneLayout.scss';

const TwoPaneLayout = ({ children }) => (
  <div className="two-pane-wrap">
    <div className="two-pane-sidebar">{children[0]}</div>
    <div className="two-pane-content">{children[1]}</div>
  </div>
);

TwoPaneLayout.propTypes = {
  children: PropTypes.array.isRequired,
};

export default TwoPaneLayout;
