import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import I18N, { moment } from 'languages';
import { Align } from 'table/modules/constants';
import { injectIntl, intlShape } from 'react-intl';
import ToutTable from 'table/modules/toutTable';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import TextCell from 'table/modules/cells/textCell';
import SubjectCell from './cells/subjectCell';
import ActionsCell from 'table/modules/cells/actionsCell';
import { alphabetical } from 'table/modules/sorting';
import { getImageUrl } from 'web/libs/constants';
import {
  DiagnosticsTableColumns,
  TABLE_ID,
  CURRENT_PAGE,
  ROW_HEIGHT,
} from '../libs/diagnosticsTableConstants';
import { TablesFiltersConstants } from 'web/tables/libs/tablesFiltersConstants';
import DateRangePicker from 'components/dates/dateRangePicker';
import { tablePerPageFromStorage } from 'web/services/storageService';
import { isAdmin } from 'web/shared/services/accountService';
import { PeopleFilters } from 'web/campaigns/people/libs/campaignsPeopleConstants';
import { filterItemsByDate } from 'web/tables/helpers/itemsTableFilters';
import './diagnosticsTable.scss';

class DiagnosticsTable extends Component {
  state = {
    columnsData: this.props.itemsDiagnosticsAll,
    openDateRangePicker: false,
    selectedDataFilter: this.props.statusSelectedValue,
    selectedValueFilter: PeopleFilters.all,
  };

  componentDidMount() {
    this.diagnosticFilter({ currentData: TablesFiltersConstants.lastSeven });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.viewAs = formatMessage({ id: 'web.templates.viewAs' });
    this.datesLabel = formatMessage({ id: 'common.dateLabel' });
    this.today = formatMessage({ id: 'common.today' });
    this.todayPast = formatMessage({ id: 'web.filters.todayPast' });
    this.lastSeven = formatMessage({ id: 'web.filters.lastSeven' });
    this.yesterday = formatMessage({ id: 'common.yesterday' });
    this.custom = formatMessage({ id: 'common.custom' });

    this.retry = formatMessage({ id: 'common.retry' });
    this.delete = formatMessage({ id: 'common.delete' });
    this.diagnostics = formatMessage({ id: 'common.diagnostics' });

    this.columnDateLabel = formatMessage({ id: 'common.date' });
    this.columnSourceLabel = formatMessage({ id: 'common.source' });
    this.columnRecipientLabel = formatMessage({
      id: 'web.diagnostics.recipient',
    });
    this.columnTypeLabel = formatMessage({ id: 'common.type' });
    this.columnSubjectLabel = formatMessage({ id: 'common.subject' });
    this.columnErrorLabel = formatMessage({ id: 'common.error' });
    this.userErrorLabel = formatMessage({ id: 'common.user' });
    this.columnActionsLabel = formatMessage({ id: 'common.actions' });
    this.searchPlaceholder = formatMessage({
      id: 'web.diagnostics.searchPlaceholder',
    });

    this.props.actionCreators.onTableMount();
  }

  updateState = () => {
    this.setState({
      openDateRangePicker: false,
    });
  };

  render() {
    const columns = this.getColumns();
    const bulkActions = this.getBulkActions();
    const { openDateRangePicker, columnsData } = this.state;
    const {
      actionCreators,
      columnSettings,
      columnFilter,
      fetching,
      loading,
      dates,
    } = this.props;

    return (
      <div className="diagnostics-table">
        <ToutTable
          bulkActions={bulkActions}
          changeColumnsSettingsHandler={actionCreators.changeColumnsSettings}
          columnFilter={columnFilter}
          columnSettings={columnSettings}
          columns={columns}
          defaultSortingColumn={DiagnosticsTableColumns.createdAt}
          fetching={fetching}
          items={columnsData}
          loading={loading}
          loadingGetUrl={getImageUrl}
          pagination={{
            currentPage: CURRENT_PAGE,
            perPage: tablePerPageFromStorage(),
          }}
          row={{
            height: ROW_HEIGHT,
          }}
          search={{
            onClear: () => {},
            placeholderText: this.searchPlaceholder,
            searchers: this.getSearchers(),
            showClear: false,
          }}
          smartFilters={this.getSmartFilters()}
          tableId={TABLE_ID}
          allItems
          isResize
          reorderColumnsEnable
          selectable
        />
        {openDateRangePicker && (
          <DateRangePicker
            className="hidden"
            endDate={dates.end}
            onDatesChange={this.onCustomDatesChange}
            startDate={dates.start}
            updateState={this.updateState}
            openOnMount
            withPortal
          />
        )}
      </div>
    );
  }

  diagnosticFilter(dataFilter) {
    const { dates, itemsDiagnosticsAll } = this.props;
    const { selectedValueFilter, selectedDataFilter } = this.state;
    const {
      indexViewAs = selectedValueFilter,
      customDate = dates,
      currentData = selectedDataFilter,
    } = dataFilter;

    const filterByDate = filterItemsByDate(
      itemsDiagnosticsAll,
      currentData,
      customDate,
      'created_at'
    );

    const columns =
      indexViewAs === PeopleFilters.all
        ? filterByDate
        : filterByDate.filter((column) => column['user_id'] === indexViewAs);

    this.setState({
      columnsData: columns,
      selectedDataFilter: currentData,
      selectedValueFilter: indexViewAs,
    });
  }

  getSmartFilters() {
    const {
      statusSelectedValue,
      intl: { formatMessage },
      usersMasterTeamSubscription,
    } = this.props;
    const { selectedValueFilter } = this.state;
    const usersMasterTeamSubscriptionData = Object.values(
      usersMasterTeamSubscription
    ).map((user) => ({ label: user.name, value: user.id }));

    const filterViewAs = [
      {
        label: formatMessage({ id: 'common.all' }),
        value: PeopleFilters.all,
      },
    ].concat(usersMasterTeamSubscriptionData);

    if (isAdmin()) {
      return [
        {
          filters: filterViewAs,
          label: this.viewAs,
          onChange: (prev, current) =>
            this.diagnosticFilter({ indexViewAs: current }),
          selectedValue: selectedValueFilter,
          width: 235,
        },
        {
          filters: this.getDatesSmartFilters(),
          label: this.datesLabel,
          onChange: this.onDateSmartFilterChange,
          selectedValue: statusSelectedValue,
          width: 235,
        },
      ];
    }

    return [
      {
        filters: this.getDatesSmartFilters(),
        label: this.datesLabel,
        onChange: this.onDateSmartFilterChange,
        selectedValue: statusSelectedValue,
        width: 235,
      },
    ];
  }

  onCustomDatesChange = (start, end) => {
    const {
      actionCreators: { onItemsDatesSmartFilterChange },
    } = this.props;
    if (start && end) {
      this.diagnosticFilter({
        currentData: TablesFiltersConstants.custom,
        customDate: { end, start },
      });
      onItemsDatesSmartFilterChange(TablesFiltersConstants.custom, start, end);
    }
    this.setState({ openDateRangePicker: false });
  };

  onDateSmartFilterChange = (index, value) => {
    const {
      actionCreators: { onItemsDatesSmartFilterChange },
    } = this.props;
    this.diagnosticFilter({ currentData: value });

    if (value !== TablesFiltersConstants.custom) {
      onItemsDatesSmartFilterChange(value);
      this.setState({ openDateRangePicker: false });
    } else {
      this.setState({ openDateRangePicker: true });
    }
  };

  getDatesSmartFilters() {
    return [
      { label: this.today, value: TablesFiltersConstants.today },
      { label: this.todayPast, value: TablesFiltersConstants.todayOverdue },
      { label: this.yesterday, value: TablesFiltersConstants.yesterday },
      { label: this.lastSeven, value: TablesFiltersConstants.lastSeven },
      {
        className: 'tout-icon-smartfilter-calendar',
        label: this.custom,
        value: TablesFiltersConstants.custom,
      },
    ];
  }

  getBulkActions() {
    const { actionCreators } = this.props;
    return {
      actions: [
        { label: this.retry, onClick: actionCreators.retryItems },
        { label: this.delete, onClick: actionCreators.removeItems },
      ],
      itemsLabel: this.diagnostics,
    };
  }

  dateSort(rowData) {
    const date = new Date(rowData.createdAt);
    return -date.getTime();
  }

  getColumns() {
    const { actionCreators } = this.props;
    const { selectedValueFilter } = this.state;
    const isShowColumnUser =
      isAdmin() && selectedValueFilter === PeopleFilters.all;

    return [
      {
        id: DiagnosticsTableColumns.createdAt,
        name: this.columnDateLabel,
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) =>
              moment(data.created_at).format(I18N.DateFormats.DATETIME_LONG),
            property: 'createdAt',
            wrapLines: 2,
          },
        },
        sorting: this.dateSort,
        width: 190,
      },
      {
        id: DiagnosticsTableColumns.source,
        name: this.columnSourceLabel,
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'source',
            wrapLines: 2,
          },
        },
        sorting: alphabetical('source'),
        width: 100,
      },
      {
        id: DiagnosticsTableColumns.recipient,
        name: this.columnRecipientLabel,
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'recipient',
            wrapLines: 2,
          },
        },
        sorting: alphabetical('recipient'),
        width: 150,
      },
      {
        id: DiagnosticsTableColumns.type,
        name: this.columnTypeLabel,
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'type',
            wrapLines: 2,
          },
        },
        sorting: alphabetical('type'),
        width: 75,
      },
      {
        id: DiagnosticsTableColumns.subject,
        name: this.columnSubjectLabel,
        rowCell: {
          component: SubjectCell,
          metadata: {
            property: 'subject',
            wrapLines: 2,
          },
        },
        sorting: alphabetical('subject'),
        width: 175,
      },
      ...(isShowColumnUser
        ? [
            {
              flex: true,
              id: DiagnosticsTableColumns.user,
              name: this.userErrorLabel,
              rowCell: {
                component: TextCustomCell,
                metadata: {
                  formatText: (data) => data['user_name'],
                  property: 'usersError',
                  wrapLines: 2,
                },
              },
              sorting: alphabetical('usersError'),
              width: 175,
            },
          ]
        : []),
      {
        flex: true,
        id: DiagnosticsTableColumns.errorCode,
        name: this.columnErrorLabel,
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) => {
              let body = [];

              if (data.response.response) {
                body = data.response.response.body;
              }
              if (data.response.body) {
                body = data.response.body;
              }

              return body.reduce((prev, next) => `${prev} ${next.message}`, '');
            },
            property: 'errorCode',
            wrapLines: 2,
          },
        },
        sorting: alphabetical('errorCode'),
        width: 175,
      },
      {
        align: Align.CENTER,
        id: DiagnosticsTableColumns.actions,
        name: this.columnActionsLabel,
        rowCell: {
          component: ActionsCell,
          metadata: {
            actions: [
              { label: this.retry, onClick: actionCreators.retryItems },
              { label: this.delete, onClick: actionCreators.removeItems },
            ],
          },
        },
        width: 140,
      },
    ];
  }

  getSearchers() {
    return [
      { label: this.columnRecipientLabel, searching: (item) => item.recipient },
      { label: this.columnSubjectLabel, searching: (item) => item.subject },
    ];
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.diagnostics.fetching,
    itemsDiagnostics: state.diagnostics.items,
    itemsDiagnosticsAll: state.diagnostics.allItems,
  };
}

DiagnosticsTable.propTypes = {
  // eslint-disable-next-line react/require-default-props
  actionCreators: PropTypes.object.isRequired,
  columnFilter: PropTypes.bool,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/require-default-props
  dates: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  datesSelectedValue: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  id: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  // eslint-disable-next-line react/require-default-props
  items: PropTypes.array.isRequired,
  itemsDiagnostics: PropTypes.array.isRequired,
  itemsDiagnosticsAll: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  showDateFilter: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  statusSelectedValue: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  usersMasterTeamSubscription: PropTypes.array,
};

DiagnosticsTable.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  actionCreators: {},
  columnFilter: true,
  columnSettings: [],
  // eslint-disable-next-line react/default-props-match-prop-types
  dates: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  datesSelectedValue: '',
  fetching: true,
  // eslint-disable-next-line react/default-props-match-prop-types
  id: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  items: [],
  itemsDiagnostics: [],
  itemsDiagnosticsAll: [],
  loading: true,
  showDateFilter: true,
  // eslint-disable-next-line react/default-props-match-prop-types
  statusSelectedValue: TablesFiltersConstants.lastSeven,
  usersMasterTeamSubscription: [],
};

export default compose(
  connect(mapStateToProps),
  injectIntl
)(DiagnosticsTable);
