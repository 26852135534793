import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Label from 'components/label';
import AddressFieldItemPill from './addressFieldItemPill';
import AddressFieldItemInput from './addressFieldItemInput';
import { LabelErrorIds } from 'web/composeWindow/libs/composeWindowConstants';
import Icon from 'components/buttons/icon';
import HoverTooltip, {
  HoverTooltipPlace,
} from 'components/hoverTooltip/hoverTooltip';
import './addressField.scss';

export default class AddressField extends React.Component {
  setRef = (child) => (this.child = child);

  focusInput = () => {
    if (this.child) this.child.addressInput.focus();
  };

  removeLastAddress = () => {
    const { addresses, removeAddressByIndex } = this.props;
    const lastIndex = addresses.length - 1;
    if (lastIndex >= 0) {
      removeAddressByIndex(lastIndex);
    }
  };

  readOnlyAddressedField = () => {
    const { addresses } = this.props;
    return (
      <div className="read-only-addresses">
        {addresses && addresses.length ? (
          addresses.map((address) => {
            return (
              (address.replacementTextId && (
                <FormattedMessage id={address.replacementTextId} />
              )) ||
              address.email
            );
          })
        ) : (
          <FormattedMessage id="web.composeWindow.bulkEmails.toLabel.allRecipients" />
        )}
        <div className="read-only-warning-tooltip">
          <HoverTooltip
            place={HoverTooltipPlace.bottom}
            textId="web.composeWindow.pitch.toField.ReadOnlyWarning"
            tooltipId="read-only-address-tooltip"
          >
            <Icon classes="tout-icon-warning" />
          </HoverTooltip>
        </div>
      </div>
    );
  };

  activeAddressedField = () => {
    const { addresses, disabled, isBypassUnsubscribe, removeAddressByIndex } = this.props;

    return addresses.map((address, index) => {
      const key = `${address.email}-${index}`;
      const handleClose = () => {
        if (!disabled) {
          removeAddressByIndex(index);
        }
      };

      return (
        <AddressFieldItemPill
          address={address}
          className="field-item"
          handleClose={handleClose}
          isBypassUnsubscribe={isBypassUnsubscribe}
          key={key}
          loading={!address.validated}
        />
      );
    });
  };

  renderAddresses() {
    const { readOnlyAddress } = this.props;

    return readOnlyAddress
      ? this.readOnlyAddressedField()
      : this.activeAddressedField();
  }

  render() {
    const {
      addresses,
      appendAddress,
      children,
      disabled,
      errors,
      groupComposeInit,
      hideGroupResults,
      label,
      limit,
      placeholder,
      createNewAddress,
      fetchSearchResults,
      searchResults,
      readOnlyAddress,
    } = this.props;
    const classnames = classNames('compose-label', {
      error: errors.includes(LabelErrorIds.error),
      warning: errors.includes(LabelErrorIds.warning),
    });
    const showAddressInput = !disabled && addresses.length < limit;

    return (
      <div className="compose-field" onClick={this.focusInput}>
        {children}
        <Label
          className={classnames}
          id={`compose-window-${label}`}
          messageKey={label}
          sideLabel
        >
          <div className="address-input-field">
            {this.renderAddresses()}
            {showAddressInput &&
              !readOnlyAddress && (
                <AddressFieldItemInput
                  appendAddress={appendAddress}
                  className="field-item"
                  createNewAddress={createNewAddress}
                  fetchSearchResults={fetchSearchResults}
                  groupComposeInit={groupComposeInit}
                  hideGroupResults={hideGroupResults}
                  key={addresses.length}
                  placeholder={placeholder}
                  ref={this.setRef}
                  removeLastAddress={this.removeLastAddress}
                  searchResults={searchResults}
                />
              )}
          </div>
        </Label>
      </div>
    );
  }
}

AddressField.propTypes = {
  addresses: PropTypes.array.isRequired,
  appendAddress: PropTypes.func.isRequired,
  children: PropTypes.node,
  createNewAddress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  fetchSearchResults: PropTypes.func.isRequired,
  groupComposeInit: PropTypes.func,
  hideGroupResults: PropTypes.bool.isRequired,
  isBypassUnsubscribe: PropTypes.bool,
  label: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  readOnlyAddress: PropTypes.bool,
  removeAddressByIndex: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
};

AddressField.defaultProps = {
  children: null,
  disabled: false,
  errors: [],
  groupComposeInit: () => {},
  isBypassUnsubscribe: false,
  placeholder: '',
  readOnlyAddress: false,
  searchResults: [],
};
