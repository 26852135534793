import moment from 'moment';
import { TaskType } from 'libs/constantsShared';
import { OVERDUE_TASK_DATE_RANGE } from 'web/tasks/libs/tasksConstants';

const initTotals = { additional: 0, calls: 0, emails: 0 };
const TODAY = 'today';
const OVERDUE = 'overdue';

export function getInitTotals() {
  return { [TODAY]: { ...initTotals }, [OVERDUE]: { ...initTotals } };
}

export function filterWelcomeTasks(tasks = []) {
  const today = moment();
  const minusThree = moment()
    .subtract(OVERDUE_TASK_DATE_RANGE, 'd')
    .startOf('d');
  const totals = getInitTotals();
  const filteredTasks = tasks.filter(
    (task) =>
      task.remind_at &&
      moment(task.remind_at).isBetween(minusThree, today, 'd', '[]')
  );

  for (let i = 0; i < filteredTasks.length; i++) {
    const isToday = moment(filteredTasks[i].remind_at).isSame(today, 'd')
      ? TODAY
      : OVERDUE;
    switch (filteredTasks[i].remind_type) {
      case TaskType.email:
        totals[isToday].emails += 1;
        break;
      case TaskType.call:
        totals[isToday].calls += 1;
        break;
      default:
        totals[isToday].additional += 1;
        break;
    }
  }
  return totals;
}
