import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { LocalStorageKeys } from 'web/libs/constants';
import { getTemplateInfo } from '../services/templateService';
import { createContentHtml } from '../services/attachmentsService';
import { setInfoBoxState } from './popupActionCreators';
import { getContent } from 'web/content/actionCreators/contentActionCreators';
import { getContentPartners } from 'web/modals/addContent/actionCreators/contentActionCreators';

export function insertTemplate(componentId, template) {
  return (dispatch) => {
    dispatch(setInfoBoxState(componentId, ''));

    window.localStorage.setItem(
      LocalStorageKeys.emailComposeBody.lastTemplateCategory,
      template.category
    );
    dispatch({
      data: template.category,
      type: ActionTypes.emailComposeBody.state.lastTemplateCategory,
    });

    getTemplateInfo(template.id).then((data) => {
      dispatch(updateEditorBody(componentId, data.body));
      if (data.file_attachments && data.file_attachments.length) {
        dispatch({
          data: data.file_attachments,
          id: componentId,
          type: ActionTypes.emailComposeBody.state.templateAttachmentsAdd,
        });
      }
    });
  };
}

export function insertContent(componentId, attachments) {
  return (dispatch, getState) => {
    const instance = getState().emailComposeEditors[componentId];
    if (instance) {
      instance.insertContent(createContentHtml(attachments));
    }
    dispatch(setInfoBoxState(componentId, ''));
  };
}

export function insertHtml(ckeId, html) {
  return (dispatch, getState) => {
    const instance = getState().emailComposeEditors[ckeId];
    if (instance) {
      instance.insertContent(html);
    }
  };
}

// Passed to ckeditor instance to update bodies on change
export function updateEditorBodies(id, body = '') {
  return {
    body,
    id,
    type: ActionTypes.emailComposeBody.state.ckEditorBodyUpdate,
  };
}

export function updateEditorBody(editorId, body) {
  return (dispatch, getState) => {
    const instance = getState().emailComposeEditors[editorId];
    if (instance) {
      instance.setContent(body);
    }
  };
}

export const setDynamicFieldsActiveCategory = (
  editorId,
  dynamicFieldsCategoryName
) => ({
  defaultCategory: dynamicFieldsCategoryName,
  editorId,
  type: ActionTypes.emailComposeBody.state.setDynamicFieldsActiveCategory,
});

export function removeEditorInstance(id) {
  return {
    id,
    type: ActionTypes.emailComposeBody.state.ckEditorDestroy,
  };
}

export function processUpdatingTemplate(
  editorId,
  { body, id: templateId },
  attachments = []
) {
  return (dispatch) => {
    if (templateId) {
      dispatch(updateEditorBody(editorId, body));
    }

    if (attachments.length) {
      dispatch({
        data: attachments,
        id: editorId,
        type: ActionTypes.emailComposeBody.state.templateAttachmentsAdd,
      });
    } else if (templateId) {
      getTemplateInfo(templateId).then((data) => {
        if (data.file_attachments && data.file_attachments.length) {
          dispatch({
            data: data.file_attachments,
            id: editorId,
            type: ActionTypes.emailComposeBody.state.templateAttachmentsAdd,
          });
        }
      });
    }
  };
}

export function initTinyMceEditor() {
  return (dispatch, getState) => {
    const {
      contentPartners: { loaded },
    } = getState();

    if (!loaded) {
      dispatch(getContentPartners());
    }
  };
}

export function createEmailComposeInstance(editorId, defaultAttachments) {
  return (dispatch) => {
    dispatch(initTinyMceEditor());
    dispatch(processUpdatingTemplate(editorId, {}, defaultAttachments));
    dispatch(getContent());

    const lastTemplateCategory = window.localStorage.getItem(
      LocalStorageKeys.emailComposeBody.lastTemplateCategory
    );
    if (lastTemplateCategory) {
      dispatch({
        data: lastTemplateCategory,
        type: ActionTypes.emailComposeBody.state.lastTemplateCategory,
      });
    }
  };
}

// Note: files added as attachments but not eventually used are cleaned up in parent's actionCreators, i.e., destroyCampaignEditState
export function destroyEmailComposeInstance(editorId) {
  return (dispatch) => {
    dispatch({
      id: editorId,
      type: ActionTypes.emailComposeBody.state.editEmailDestroy,
    });
  };
}

export const storeEditorInstance = (id, instance) => ({
  id,
  instance,
  type: ActionTypes.emailComposeBody.state.ckEditorCreate,
});
