import TeamMembersActionTypes from 'web/teamMembers/libs/teamMembersActionTypes';
import TeamsActionTypes from '../libs/teamsActionTypes';
import castArray from 'lodash/castArray';
import assign from 'lodash/assign';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';
import unset from 'lodash/unset';
import union from 'lodash/union';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import reject from 'lodash/reject';
import concat from 'lodash/concat';
import filter from 'lodash/filter';

export function byId(state = {}, action) {
  switch (action.type) {
    case TeamsActionTypes.setTeams:
      return keyBy(action.teams, 'id');
    case TeamsActionTypes.createTeam:
    case TeamsActionTypes.grantTeamAdmin:
    case TeamsActionTypes.revokeTeamAdmin:
    case TeamsActionTypes.updateTeam: {
      const diff = keyBy(castArray(action.team), 'id');
      return assign({}, state, diff);
    }
    case TeamsActionTypes.deleteTeam: {
      const newState = { ...state };
      unset(newState, action.id);
      return newState;
    }
    default:
      return state;
  }
}

export function allIds(state = [], action) {
  switch (action.type) {
    case TeamsActionTypes.setTeams:
      return map(action.teams, 'id');
    case TeamsActionTypes.createTeam: {
      const diff = map(castArray(action.team), 'id');
      return union(state, diff);
    }
    case TeamsActionTypes.deleteTeam: {
      return difference(state, castArray(action.id));
    }
    default:
      return state;
  }
}

export function teamToAdmin(state = [], action) {
  switch (action.type) {
    case TeamsActionTypes.setTeams:
      return flatMap(action.teams, (team) =>
        team.admin_ids.map((adminId) => ({
          teamId: team.id,
          adminId,
        }))
      );
    case TeamsActionTypes.createTeam: {
      const newTeamIdAdminId = action.team.admin_ids.map((adminId) => ({
        teamId: action.team.id,
        adminId,
      }));
      return concat(state, newTeamIdAdminId);
    }
    case TeamsActionTypes.grantTeamAdmin:
    case TeamsActionTypes.revokeTeamAdmin:
    case TeamsActionTypes.updateTeam: {
      const newState = reject(state, ['teamId', action.team.id]);
      const newTeamIdAdminId = action.team.admin_ids.map((adminId) => ({
        teamId: action.team.id,
        adminId,
      }));
      return concat(newState, newTeamIdAdminId);
    }
    case TeamsActionTypes.deleteTeam: {
      return reject(state, ['teamId', action.id]);
    }
    case TeamMembersActionTypes.deleteTeamMember: {
      if (action.teamId)
        return reject(state, { teamId: action.teamId, adminId: action.id });
      else {
        return reject(state, { adminId: action.id });
      }
    }
    case TeamMembersActionTypes.batchDestroyTeamMembers: {
      return reject(state, ({ teamId, adminId }) => {
        if (action.teamId) {
          if (action.teamId === teamId && action.ids.includes(adminId))
            return true;
        } else if (action.ids.includes(adminId)) {
          return true;
        }
        return false;
      });
    }
    case TeamMembersActionTypes.updateTeamMember: {
      const allowedTeamIds = map(action.teamMember.teams, 'id');
      return filter(state, ({ teamId, adminId }) => {
        if (action.teamMember.id === adminId) {
          return allowedTeamIds.includes(teamId);
        }
        return true;
      });
    }
    default:
      return state;
  }
}

export function fetched(state = false, action) {
  switch (action.type) {
    case TeamsActionTypes.setTeamsFetched:
      return action.fetched;
    default:
      return state;
  }
}

export function fetching(state = false, action) {
  switch (action.type) {
    case TeamsActionTypes.setTeamsFetching:
      return action.fetching;
    default:
      return state;
  }
}
