import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { cloneCampaign as cloneCampaignCall } from '../services/campaignService';
import find from 'lodash/find';
import {
  GlobalAlertIds,
  GlobalPopupIds,
  CampaignManagementActions,
} from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { navigateToCampaignDetailsTab } from 'web/services/routerService';
import { openCampaignAlert } from 'web/campaigns/campaignsTab/actionCreators/campaignsAlertActionCreators';
import {
  openPopup,
  closePopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { track } from 'web/services/mixpanelService';
import { CampaignActionEvents } from 'web/libs/mixpanelEvents';
import languages from 'languages';

export function cloneCampaign(id) {
  return (dispatch, getState) => {
    const { campaigns } = getState();
    const campaign = find(campaigns, { id });
    const { name } = campaign;
    dispatch({ type: ActionTypes.api.cloneCampaign.request });
    const campaignObject = {
      category: campaign.category,
      category_id: campaign.category_id,
      clone_steppables: true,
      name: languages.getStr('web.campaigns.cloneName', { name }),
    };
    return cloneCampaignCall(id, campaignObject)
      .then((clonedCampaign) => {
        dispatch({
          type: ActionTypes.api.cloneCampaign.success,
          data: clonedCampaign,
        });
        dispatch(
          openPopup(GlobalPopupIds.cloneCampaignConfirmation, {
            clonedCampaign,
          })
        );

        track(CampaignActionEvents.manageCampaign, {
          'Action Type': CampaignManagementActions.clone,
        });
      })
      .catch(() => {
        dispatch(openCampaignAlert(GlobalAlertIds.cloneCampaignError));
        dispatch({ type: ActionTypes.api.cloneCampaign.failure });
      });
  };
}

export function affirmCloneCampaign(clonedCategoryId, clonedId) {
  return (dispatch, getState) => {
    const { campaignsTabSelectedDetailsTab: selectedTab } = getState();
    dispatch(closePopup());
    navigateToCampaignDetailsTab(clonedCategoryId, clonedId, selectedTab);
  };
}
