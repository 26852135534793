export const TeamManagementTabs = {
  teamMembers: 'team-members',
};

export const TeamManagementAlertIds = {
  dataChangesSaved: 'TEAM_MANAGEMENT_DATA_SAVED_SUCCESSFULLY',
  dataChangesNotSaved: 'TEAM_MANAGEMENT_DATA_NOT_SAVED',
  addToTeamsSuccess: 'TEAM_MANAGEMENT_ADDED_TO_TEAMS_SUCCESS',
};

export const TeamManagementDuplicateValueValidationString =
  'has already been taken';

export const TeamManagementCommonErrorTextId = 'web.common.alert.genericIssue';
export const TeamManagementNameDuplicateErrorTextId =
  'web.settings.adminSettings.teamManagement.teamMembers.changeTeam.nameDuplicate';
