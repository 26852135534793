/*
*
* ToutTable
*
*/

import React, { Component } from 'react';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import reducers from 'table/modules/reducers';
import TableContainer from 'table/modules/tableContainer';

class ToutTable extends Component {
  constructor(props) {
    super(props);
    const { initialState } = props;
    this.store = createStore(
      reducers,
      initialState || {},
      applyMiddleware(thunk)
    );
  }

  render() {
    const { tableRef, ...rest } = this.props;
    return (
      <Provider store={this.store}>
        <TableContainer ref={tableRef} {...rest} />
      </Provider>
    );
  }
}

export default ToutTable;
