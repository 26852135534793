import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import PropTypes from 'prop-types';
import './needMoreSeatsPopup.scss';

const NeedMoreSeatsPopup = ({
  actionCreators: { closePopup },
  allowedSeatsCount,
  usedSeatsCount,
}) => (
  <Popup
    className="need-more-seats-popup"
    footer={{
      onPrimary: closePopup,
      primaryTextId: 'common.ok',
    }}
    header={{
      onClose: closePopup,
      textId:
        'web.settings.adminSettings.userManagement.invitees.needMoreSeats',
    }}
    onOutsideClick={closePopup}
    size={PopupSize.small}
  >
    <div>
      <FormattedHTMLMessage
        id="web.settings.adminSettings.userManagement.invitees.yourCurrentSeats"
        values={{ allowedSeatsCount, usedSeatsCount }}
      />
      <br />
      <FormattedHTMLMessage id="web.settings.adminSettings.userManagement.invitees.purchaseMoreSeats" />
    </div>
  </Popup>
);

NeedMoreSeatsPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  allowedSeatsCount: PropTypes.number.isRequired,
  usedSeatsCount: PropTypes.number.isRequired,
};

export default NeedMoreSeatsPopup;
