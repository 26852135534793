import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextButton from 'components/buttons/text';
import {
  AuthorizationTypes,
  SourceTypes,
} from 'web/compliance/libs/complianceConstants';
import './complianceDetails.scss';
import I18N from 'languages';
import { formatSourceTypeText } from 'web/person/helpers/personHelpers';
import AuthorizationTypeText from 'web/person/components/personDetails/authorizationTypeText';

class ComplianceDetails extends Component {
  render() {
    const {
      edit,
      person: { complianceDetails },
    } = this.props;

    return (
      <div className="person-details-compliance-details text-wrap">
        <div className="compliance-details-header-row margin-bottom-half">
          <h3>
            <FormattedMessage id="web.people.compliance.header" />
          </h3>
          <TextButton onClick={edit} underline>
            <FormattedMessage id="common.edit" />
          </TextButton>
        </div>

        {(this._complianceDetailsPresent(complianceDetails) && (
          <div className="compliance-details-body">
            <div className="consent-row">
              {this._authorizationDetails(complianceDetails)}
              {this._mainConsentDetails(complianceDetails)}
            </div>
            {this._consentNotesDetails(complianceDetails)}
            {this._sourceDetails(complianceDetails)}
          </div>
        )) || <FormattedMessage id="web.people.compliance.zeroState" />}
      </div>
    );
  }

  _complianceDetailsPresent = ({ authorizationType, sourceType }) =>
    authorizationType || sourceType;

  _authorizationDetails = ({ authorizationType, otherAuthorizationValue }) =>
    authorizationType && (
      <div className="compliance-detail-block">
        <h5 className="compliance-field-header">
          <FormattedMessage id="web.people.compliance.authorizationType" />
        </h5>
        <p className="compliance-field-data">
          <AuthorizationTypeText authorizationType={authorizationType} />
          {authorizationType === AuthorizationTypes.other
            ? ` - ${otherAuthorizationValue}`
            : ''}
        </p>
      </div>
    );

  _mainConsentDetails = ({
    authorizationType,
    consentDate,
    consentPurposeValue,
  }) =>
    authorizationType === AuthorizationTypes.consent && [
      <div key="consent-date" className="compliance-detail-block">
        <h5 className="compliance-field-header">
          <FormattedMessage id="web.people.compliance.dateOfConsent" />
        </h5>
        <p className="compliance-field-data">
          {I18N.getLocalizedDate(consentDate, I18N.DateFormats.DATE_SLASH)}
        </p>
      </div>,
      <div key="consent-purpose" className="compliance-detail-block">
        <h5 className="compliance-field-header">
          <FormattedMessage id="web.people.compliance.purposeOfProcessing" />
        </h5>
        <p className="compliance-field-data">{consentPurposeValue}</p>
      </div>,
    ];

  _consentNotesDetails = ({ consentNotesValue }) =>
    consentNotesValue && (
      <div className="compliance-detail-block compliance-detail-notes">
        <h5 className="compliance-field-header">
          <FormattedMessage id="web.people.compliance.notesOfConsent" />
        </h5>
        <p className="compliance-field-data">{consentNotesValue}</p>
      </div>
    );

  _sourceDetails = ({ sourceType, otherSourceValue }) =>
    sourceType && (
      <div className="compliance-detail-block compliance-detail-source">
        <div className="compliance-detail-block">
          <h5 className="compliance-field-header">
            <FormattedMessage id="web.people.compliance.sourceType" />
          </h5>
          <p className="compliance-field-data">
            <FormattedMessage id={formatSourceTypeText[sourceType]} />
            {sourceType === SourceTypes.other ? ` - ${otherSourceValue}` : ''}
          </p>
        </div>
      </div>
    );
}

ComplianceDetails.propTypes = {
  edit: PropTypes.func.isRequired,
  person: PropTypes.object.isRequired,
};

export default ComplianceDetails;
