import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Label from 'components/label';
import Select2 from 'components/selects/select2';
import I18N from 'languages';
import { TEAM_NAMES } from 'web/user/libs/userConstants';

const LABEL_WIDTH = 50;

class sharingSettingsAddTeams extends Component {
  render() {
    const {
      intl: { formatMessage },
      selectedValues,
    } = this.props;

    return (
      <div className="sharing-settings-add-teams">
        <Label
          id="select-identity"
          messageKey="common.teams"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Select2
            noResultsText={formatMessage({
              id: 'web.settings.sharing.noGroups',
            })}
            onChange={this.onChange}
            options={this._generateOptions()}
            value={selectedValues}
            placeholder={formatMessage({
              id: 'web.settings.sharing.chooseTeams',
            })}
            disabled={false}
            clearable={false}
            multi
          />
        </Label>
      </div>
    );
  }

  _generateOptions = () =>
    this.props.teams.map(({ id, name, admin }) => ({
      value: id,
      label:
        admin || TEAM_NAMES.everyone === name
          ? I18N.getStr('common.everyone')
          : name,
    }));

  onChange = (val = []) => {
    this.props.onChange(val.map((element) => element.value));
  };
}

sharingSettingsAddTeams.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
};

export default injectIntl(sharingSettingsAddTeams);
