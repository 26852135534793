import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMasterTeamMembers } from 'web/teamMembers/selectors/teamMembersSelectors';
import {
  openDeleteUserConfirmationPopup,
  openDeleteUsersConfirmationPopup,
  grantAdminRole,
  grantUserRole,
} from '../actionCreators/usersActionCreators';
import {
  getFetching,
  getLoading,
  getMasterTeamAdminIds,
} from '../selectors/usersSelectors';
import Users from '../components/users';
import { COLUMN_SETTINGS_USERS_TABLE_ID } from 'web/settings/adminSettings/userManagement/libs/usersConstants';
import { getUserColumnSettingsForTable } from 'web/user/selectors/userSelectors';
import { changeColumnsSettings } from 'web/user/actionCreators/userActionCreators';
import { isUserManagementConnectionStatusDisabled } from 'web/user/selectors/subscriptionSelectors';
import PropTypes from 'prop-types';

class UsersContainer extends Component {
  render() {
    return <Users {...this.props} />;
  }
}

UsersContainer.propTypes = {
  columnSettings: PropTypes.arrayOf(PropTypes.object),
};

UsersContainer.defaultProps = {
  columnSettings: [],
};

const mapStateToProps = (state) => ({
  columnSettings: getUserColumnSettingsForTable(
    state,
    COLUMN_SETTINGS_USERS_TABLE_ID
  ),
  currentUser: state.user,
  fetching: getFetching(state),
  loading: getLoading(state),
  masterTeamAdminIds: getMasterTeamAdminIds(state),
  showConnectionStatusColumns: !isUserManagementConnectionStatusDisabled(state),
  users: getMasterTeamMembers(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        grantAdminRole,
        grantUserRole,
        openDeleteUserConfirmationPopup,
        openDeleteUsersConfirmationPopup,
      },
      dispatch
    ),
    changeColumnsSettings: (columnsSettings) =>
      dispatch(
        changeColumnsSettings(COLUMN_SETTINGS_USERS_TABLE_ID, columnsSettings)
      ),
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersContainer);
