import ReleaseNotesActionTypes from '../libs/releaseNotesActionTypes';

export const releaseNotesIsVisible = (
  state = true,
  { type = '', visible = true }
) => {
  switch (type) {
    case ReleaseNotesActionTypes.setVisible:
      return visible;
    default:
      return state;
  }
};

export const releaseNotesIsOpen = (
  state = false,
  { type = '', open = false }
) => {
  switch (type) {
    case ReleaseNotesActionTypes.setOpen:
      return open;
    default:
      return state;
  }
};

export const releaseNotesData = (
  state = [],
  { type = '', releaseNotes = [] }
) => {
  switch (type) {
    case ReleaseNotesActionTypes.setNotes:
      return releaseNotes;
    default:
      return state;
  }
};

export const releaseNotesInitStore = (state = false, { type }) => {
  switch (type) {
    case ReleaseNotesActionTypes.initStore:
      return true;
    default:
      return state;
  }
};
