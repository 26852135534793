import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from 'components/card';
import SalesforceIcon from 'components/icons/salesforceIcon';
import { stepsNames } from 'web/onboarding/lib/stepsRouting';
import { isSFSandboxAuthenticationEnabled } from 'web/createAccount/helpers/createAccountHelper';
import { CodeTypes } from 'web/createAccount/libs/createAccountConstants';
import './createAccountFromSF.scss';

class CreateAccountFromSF extends Component {
  render() {
    const {
      actionCreators: { createSalesforceAuthRequest, updateOnboardingSettings },
      isAccountCreatedFromSF,
      match: {
        params: {
          activation_code: activationCode,
          invitation_code: invitationCode,
        },
      },
    } = this.props;

    if (isAccountCreatedFromSF) {
      updateOnboardingSettings(stepsNames.connectCRM);
    }

    let codeType;
    let code;
    if (invitationCode) {
      codeType = CodeTypes.invitation;
      code = invitationCode;
    } else if (activationCode) {
      codeType = CodeTypes.activation;
      code = activationCode;
    }

    return !isAccountCreatedFromSF ? (
      <Card className="create-account-from-sf" width={600}>
        <h1 className="title">
          <FormattedMessage id={'createAccountOnboardingFromSF.card.title'} />
        </h1>
        <div className="connection-info">
          <FormattedMessage
            id={'createAccountOnboardingFromSF.card.connectionInfo'}
          />
          <FormattedMessage
            id={'createAccountOnboardingFromSF.card.syncInfo'}
          />
        </div>
        <div
          className="sign-in-sf-button"
          onClick={() => {
            createSalesforceAuthRequest(
              code,
              codeType,
              isSFSandboxAuthenticationEnabled
            );
          }}
        >
          <SalesforceIcon />
          <FormattedMessage
            id={
              isSFSandboxAuthenticationEnabled
                ? 'createAccountOnboardingFromSF.activateAccountWithSFSandbox'
                : 'createAccountOnboardingFromSF.activateAccountWithSF'
            }
          />
        </div>
      </Card>
    ) : (
      <Card className="create-account-from-sf-success" width={600}>
        <h1 className="connect-success-title">
          <FormattedMessage id="web.unauthorized.title" />
        </h1>
        <h2 className="account-activated-text">
          <FormattedMessage
            id={'createAccountOnboardingFromSF.activatedAccount'}
          />
        </h2>
        <div className="success-icon" />
        <div className="success-icon-text">
          <FormattedMessage id={'web.onboarding.title.success'} />
        </div>
      </Card>
    );
  }
}

CreateAccountFromSF.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isAccountCreatedFromSF: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      invitation_code: PropTypes.string,
    }),
  }).isRequired,
};

CreateAccountFromSF.defaultProps = {};

export default injectIntl(CreateAccountFromSF);
