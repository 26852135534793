/* eslint-disable sort-keys */
import accountDetailsActionTypes from '../libs/accountDetailsActionTypes';
import { saveAccountDetails } from '../services/accountDetailsServices';
import {
  successAlertOptions,
  errorAlertOptions,
} from '../libs/accountDetailsAlerts';
import { getUser } from 'web/user/actionCreators/userActionCreators';
import { showAlert } from '../../../actionCreators/myProfileActionCreators';
import { getUser as getUserSelector } from 'web/user/selectors/userSelectors';
import { setSidebarFilterInput } from 'web/templates/actionCreators/templatesViewerActionCreators';
import { updateIdentity } from 'web/user/actionCreators/identitiesActionCreators';
import { getChangePaswordErrorText } from '../helpers/accountDetailsHelpers';
import { getDefaultIdentity } from 'web/user/selectors/identitiesSelectors';

export const setLoading = (pending = true) => ({
  type: accountDetailsActionTypes.setLoading,
  pending,
});

export const changeAccountDetails = (newSettings, resetForm) => (
  dispatch,
  getState
) => {
  dispatch(setLoading());
  saveAccountDetails(newSettings)
    .then(() => dispatch(getUser()))
    .then(() => {
      // workaround for correct update user name across the app
      const state = getState();
      const userData = getUserSelector(state);
      const { name = '', identity_id: identityId } = userData;
      dispatch(setSidebarFilterInput(name));
      if (identityId) {
        const defaultIdentity = getDefaultIdentity(state) || {};
        dispatch(
          updateIdentity({
            ...defaultIdentity,
            ...userData,
            id: identityId,
          })
        );
      }
      dispatch(showAlert(successAlertOptions));
      resetForm();
    })
    .catch((error) => {
      dispatch(
        showAlert({
          ...errorAlertOptions,
          textId: getChangePaswordErrorText(error),
        })
      );
    })
    .finally(() => dispatch(setLoading(false)));
};
