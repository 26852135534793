import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InviteesPopupIds } from '../../../libs/inviteesConstants';
import WithdrawInviteConfirmationPopup from '../withdrawInviteConfirmationPopup';
import WithdrawInvitesConfirmationPopup from '../withdrawInvitesConfirmationPopup';
import InviteUsersPopup from '../inviteUsersPopup';
import InviteUsersResultPopup from '../inviteUsersResultPopup';
import NeedMoreSeatsPopup from '../needMoreSeatsPopup';
import popupWithErrors from 'components/hocs/popupWithErrors';

class InviteesPopup extends Component {
  render() {
    const { popup } = this.props;

    switch (popup) {
      case InviteesPopupIds.withdrawInviteConfirmation:
        return <WithdrawInviteConfirmationPopup {...this.props} />;
      case InviteesPopupIds.withdrawInvitesConfirmation:
        return <WithdrawInvitesConfirmationPopup {...this.props} />;
      case InviteesPopupIds.needMoresSeats:
        return <NeedMoreSeatsPopup {...this.props} />;
      case InviteesPopupIds.inviteUsers:
        return <InviteUsersPopup {...this.props} />;
      case InviteesPopupIds.inviteUsersResult:
        return <InviteUsersResultPopup {...this.props} />;
      default:
        return null;
    }
  }
}

InviteesPopup.propTypes = {
  popup: PropTypes.any,
};

InviteesPopup.defaultProps = {
  popup: null,
};

export default popupWithErrors(InviteesPopup);
