export default {
  setTeams: 'TEAMS_SET_TEAMS',
  createTeam: 'TEAMS_CREATE_TEAM',
  updateTeam: 'TEAMS_UPDATE_TEAM',
  deleteTeam: 'TEAMS_DELETE_TEAM',
  grantTeamAdmin: 'TEAMS_SET_GRANT_TEAM_ADMIN',
  revokeTeamAdmin: 'TEAMS_SET_REVOKE_TEAM_ADMIN',
  setTeamsFetching: 'TEAMS_SET_TEAMS_FETCHING',
  setTeamsFetched: 'TEAMS_SET_TEAMS_FETCHED',
};
