import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { injectIntl, intlShape } from 'react-intl';
import InputText from 'components/inputText';
import { DayCardTypes } from 'web/libs/constants';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';
import { canEditCampaign } from 'web/campaigns/campaignsTab/services/campaignService';
import './campaignDayCardHeader.scss';

const TWO_YEARS_IN_DAYS = 730;

class CampaignDayCardHeader extends Component {
  constructor(props) {
    super(props);

    this.campaignDayHeaderInput = null;
    this.dayLabel = props.intl.formatMessage({ id: 'common.day' });

    // tempDay used to modify exisiting days so that we only call api in _handleClick, not onChange
    this.state = {
      editingName: props.type === DayCardTypes.newDayCard,
      originalDay:
        props.type === DayCardTypes.defaultDayOneCard ? 1 : props.day,
      tempDay: props.day,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { type, actionCreators } = this.props;
    if (type === DayCardTypes.defaultDayOneCard) {
      actionCreators.setNewDayNumber(1);
    }
  }

  componentDidMount() {
    window.addEventListener('mousedown', this._handleClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this._handleClick, false);
  }

  _updateSteps = (newDay) => {
    const { steps, actionCreators } = this.props;
    const stepsClone = cloneDeep(steps);
    for (const step in stepsClone) {
      if ({}.hasOwnProperty.call(stepsClone, step)) {
        stepsClone[step].day = newDay;
        actionCreators.optimisticUpdateCampaignStep(stepsClone[step]);
      }
    }
  };

  /*eslint complexity: [1, 12]*/
  _handleClick = (e) => {
    if (
      this.state.editingName &&
      ReactDOM.findDOMNode(this.campaignDayHeaderInput) && // eslint-disable-line
      !ReactDOM.findDOMNode(this.campaignDayHeaderInput).contains(e.target) // eslint-disable-line
    ) {
      this._processLeavingDayUpdate();
    }
  };

  _processLeavingDayUpdate = () => {
    const { actionCreators, newDayNumber, type } = this.props;
    this.setState({ editingName: false });

    switch (type) {
      case DayCardTypes.newDayCard:
        if (!newDayNumber) {
          actionCreators.notAddingNewDay();
        } else {
          this._updateSteps(newDayNumber);
        }
        break;
      case DayCardTypes.defaultDayOneCard:
        if (!newDayNumber) {
          this.setState((prevState) => ({ tempDay: prevState.originalDay }));
        } else {
          this._updateSteps(newDayNumber);
        }
        break;
      case DayCardTypes.standardCard:
        if (!this.state.tempDay) {
          this.setState((prevState) => ({ tempDay: prevState.originalDay }));
        } else {
          this._updateSteps(this.state.tempDay);
        }
        break;
      default:
        break;
    }
  };

  _handleKeyDown = (e) => {
    if (e.keyCode === 9 || e.keyCode === 13) {
      // tab or return
      e.preventDefault();
      this._processLeavingDayUpdate();
    }
  };

  setEditName = () => {
    if (this.state.originalDay !== 1) {
      this.setState({ editingName: true });
    }
  };

  updateDay = (e) => {
    const { actionCreators, type } = this.props;
    const temp = parseInt(e.target.value, 10);
    const num = Number.isNaN(temp) && e.target.value === '' ? '' : temp;

    if (num !== '' && (Number.isNaN(num) || num < 1)) {
      return;
    } else if (num > TWO_YEARS_IN_DAYS) {
      actionCreators.openCampaignDayLimitAlert();
      return;
    }

    if (
      type === DayCardTypes.defaultDayOneCard ||
      type === DayCardTypes.newDayCard
    ) {
      // saves new day number on store so that we can show an empty day and loading state in campaignSetup
      actionCreators.setNewDayNumber(num);
    } else {
      this.setState({ tempDay: num });
    }
  };

  getDayValue = () => {
    const { newDayNumber, type } = this.props;
    if (
      type === DayCardTypes.defaultDayOneCard ||
      type === DayCardTypes.newDayCard
    ) {
      return newDayNumber;
    } else {
      return this.state.tempDay;
    }
  };

  render() {
    const { selectedCampaign, wide } = this.props;
    const day = this.getDayValue();

    return (
      <div className={classNames('day-header-container', { wide })}>
        {!this.state.editingName ? (
          <h2
            className={classNames('day-title', {
              uneditable:
                this.state.originalDay === 1 ||
                !canEditCampaign(selectedCampaign),
              wide,
            })}
            onClick={this.setEditName}
          >
            {`${this.dayLabel} ${day}`}
          </h2>
        ) : (
          <div>
            <h2 className={classNames('campaign-new-day-title', { wide })}>
              {this.dayLabel}
            </h2>
            <span className={classNames('campaign-day-input-div', { wide })}>
              <InputText
                className={classNames('campaign-day-input', { wide })}
                onBlur={this._processLeavingDayUpdate}
                onChange={this.updateDay}
                onKeyDown={this._handleKeyDown}
                ref={this.setCampaignDayHeaderInputRef}
                type="text"
                value={day}
                autoFocus
              />
            </span>
          </div>
        )}
      </div>
    );
  }

  _setCampaignDayHeaderInputRef = (node) => {
    this.campaignDayHeaderInput = node;
  };
}

CampaignDayCardHeader.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  day: PropTypes.number,
  intl: intlShape.isRequired,
  newDayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedCampaign: PropTypes.object,
  steps: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  wide: PropTypes.bool,
};

CampaignDayCardHeader.defaultProps = {
  day: undefined,
  newDayNumber: undefined,
  selectedCampaign: undefined,
  wide: undefined,
};

export default injectIntl(CampaignDayCardHeader);
