/* global __DEV__ */
import { ButtonIds, SourceOpenerIds } from 'libs/constantsShared';

/* Production settings */
let backendUrl = 'https://toutapp.com';
let imageUrl = 'https://d2owagxlf083ds.cloudfront.net/owa/images';
let mixpanelKey = 'bddeb22e7f9c4aa15e14cb950b06ecea';
let rollbarToken = 'f2b5e0f1820049bf8bd5aec24a447a79';
let pusherEnvironment = 'owaproduction';
let pusherKey = '3479fa99c49db0822997';
let rollbarEnvironment = 'production';

/* istanbul ignore else */
if (__DEV__) {
  const environment = require('./environment'); // eslint-disable-line global-require
  backendUrl = environment.BaseUrl;
  imageUrl = environment.BaseUrlImages;
  pusherEnvironment = environment.PusherEnvironment;
  mixpanelKey = '82f22312605b93e535fd8e6fe2d6f55a';
  rollbarToken = 'f2b5e0f1820049bf8bd5aec24a447a79';
  rollbarEnvironment = environment.RollbarEnvironment;
  pusherKey = environment.PusherKey;
}

export const BaseUrl = backendUrl;
export const BaseUrlImages = imageUrl;
export const NextUrlImages = 'https://d2owagxlf083ds.cloudfront.net/next';
export const PusherEnvironment = pusherEnvironment;
export const MixpanelKey = mixpanelKey;
export const RollbarToken = rollbarToken;
export const ExtensionType = 'OWA';
export const PusherKey = pusherKey;
export const RollbarEnvironment = rollbarEnvironment;
export const ReadingItemAttempts = 30;
export const ReadingItemInterval = 1500;
export const WindowsOwaMode = 'win_owa';

export const Urls = {
  authenticate: `${BaseUrl}/user_session.json`,
  billing: `${BaseUrl}/payment/edit`,
  blank: 'about:blank',
  calendar: `${BaseUrl}/owa/eng/calendar`,
  callBase: `${BaseUrl}/modal?sourceOpener=${SourceOpenerIds.owa}#modal/call`,
  campaignDetailsUser: `${BaseUrl}/workflows/for_emails`,
  campaigns: `${BaseUrl}/workflows.json`,
  campaignsStop: `${BaseUrl}/workflows/stop`,
  connectToOutlook: `${BaseUrl}/next/owa#office365_connection_owa`,
  connectToSalesforce: `${BaseUrl}/next/owa#salesforce_connection_owa`,
  content: `${BaseUrl}/attachments`,
  contentCollection: `${BaseUrl}/file_attachments/collection`,
  destroySession: `${BaseUrl}/user_session`,
  elasticSearch: `${BaseUrl}/fetch.json`,
  engConnect: `${BaseUrl}/next#settings/my-settings/email_settings/email-connection`,
  engConnection: `${BaseUrl}/owa/eng/valid_connection.json`,
  fileUpload: `${BaseUrl}/attachments.json`,
  getHelp: 'https://nation.marketo.com/t5/ToutApp-Support/ct-p/toutapp-support',
  gotoToutApp: 'https://toutapp.com/next#emails',
  inMailBase: `${BaseUrl}/outlook/linked_in_search?keywords=`,
  linkPreview: 'https://outlook.office.com/owa/?path=/options/linkpreview', // actually used by en_us.json
  newEmail: `${BaseUrl}/modal?sourceOpener=${
    SourceOpenerIds.owa
  }#modal/compose_email`, // 'https://outlook.office.com/?path=/mail/action/compose&to=:#email',
  newEmailTemplate: `${BaseUrl}/modal?sourceOpener=${
    SourceOpenerIds.owa
  }#modal/compose_email/person/:#personId/address/:#addressId/reminder/:#taskId/template/:#templateId/campaign/:#campaignId`,
  newEmailTo: `${BaseUrl}/modal?sourceOpener=${
    SourceOpenerIds.owa
  }#modal/compose_email/person/:#personId/address/:#addressId`,
  newEmailWithReminder: `${BaseUrl}/modal?sourceOpener=${
    SourceOpenerIds.owa
  }#modal/compose_email/person/:#personId/address/:#addressId/reminder/:#taskId`,
  owaHelpPage:
    'https://nation.marketo.com/community/toutapp/pages/tout-support',
  owaKnowledgeBasePage: (locale) =>
    `https://docs.marketo.com/display/public/DOCS/Marketo+Sales+Connect?lang=${locale}`,
  passwordReset: `${BaseUrl}/password_reset`,
  people: `${BaseUrl}/people/:#id.json`,
  peopleLookup: `${BaseUrl}/people/lookup.json`,
  personDetailsBase: `${BaseUrl}/next#people/groups/everyone`,
  personHistory: (personId) => `${BaseUrl}/next#people/${personId}/history`,
  pitchIdentifierUpdate: `${BaseUrl}/owa/eng/stage_update_pitch_identifier`,
  popupAddToCompaign: `${BaseUrl}/owa/add_to_campaign?sourceOpener=${
    SourceOpenerIds.owa
  }`,
  popupCalendar: `${BaseUrl}/owa/calendar_popup`,
  popupSaveAsTemplate: `${BaseUrl}/owa/save_as_template`,
  pusherAuth: `${BaseUrl}/pusher/auth`,
  salesforceCheck: `${BaseUrl}/salesforce/check`,
  salesforceLog: `${BaseUrl}/salesforce/log`,
  setUserCookies: `${BaseUrl}/owa/eng/set_user_cookies?tout_user_id=#id`,
  submitFeedback: 'https://goo.gl/forms/DesnGXzlMWQC76ow2',
  tags: `${BaseUrl}/tags/of_tag`,
  tasks: `${BaseUrl}/reminders`,
  tasksCompleted: `${BaseUrl}/reminders/completed.json`,
  tasksUncompleted: `${BaseUrl}/reminders/uncompleted.json`,
  tasksUpdate: `${BaseUrl}/reminders/:#id`, // can't use ${} due to dynamically defined
  templateCategories: `${BaseUrl}/template_categories`,
  templates: `${BaseUrl}/pitch_templates`,
  templatesId: `${BaseUrl}/pitch_templates/:#id`, // can't use ${} due to dynamically defined
  templatesRecommended: `${BaseUrl}/pitch_templates/recommended.json`,
  timezones: `${BaseUrl}/timezones.json`,
  toutApp: `${BaseUrl}`,
  toutIt: `${BaseUrl}/eng/stage`,
  toutItLinkify: `${BaseUrl}/owa/eng/stage_api`,
  toutItStage: `${BaseUrl}/owa/eng/stage`,
  upsell: `${BaseUrl}/google/upsell`,
  userAccount: `${BaseUrl}/users/:#id.json`,
  userMetric: `${BaseUrl}/user_metric.json`,
};

export const VerifyTokenStatus = {
  notConnectedToGmail: 'not_connected_to_gmail',
  notLoggedIn: 'not_logged_in',
  ok: 'ok',
};

export const EmailTargets = {
  bcc: 'bcc',
  body: 'body',
  cc: 'cc',
  from: 'from',
  subject: 'subject',
  to: 'to',
};

export const Regex = {
  dynamicFields: /{{([a-zA-Z0-9_]*)}}/g,
  dynamicFieldPrompts: /{{!\s*\S.*}}/g,
  // email: /([+a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g,
  nbsp: /(&nbsp;|&nbsp)$/,
};

export const RecentlyUsed = {
  campaigns: 'campaigns',
  templates: 'templates',
};

export const Linkified = {
  custom: 'custom_content',
  tracker: 'view_tracker',
};

export const Subscriptions = {
  appSumo: 'appsumo',
  enterprise: 'enterprise',
  free: 'free',
  g1Lite: 'g1lite',
  g1MidSize: 'g1midsize',
  g1SmallTeam: 'g1smallteam',
  g1Solo: 'g1solo',
  g1StartUp: 'g1startup',
  g3SmallTeam: 'g3smallteam',
  g3Solo: 'g3solo',
  g3StartUp: 'g3startup',
  g4SmallTeam: 'g4smallteam',
  grandfatheredPremium: 'grandfatheredpremium',
  largeTeam: 'largeteam',
  medTeam: 'medteam',
  mobile: 'mobile',
  smallTeam: 'smallteam',
  solo: 'solo',
  starter: 'starter',
  vip: 'vip',
};

export const OfficeStatus = {
  failed: 'failed',
  succeeded: 'succeeded',
};

export const OfficeErrorCodes = {
  invalidRecipients: 9013,
};

export const OfficeResponseCodes = {
  noError: 'NoError',
};

export const HostName = {
  ios: 'OutlookIOS',
  mac: 'Outlook',
  web: 'OutlookWebApp',
  windows: 'Outlook',
};

export const OfficeNotificationTypes = {
  error: 'errorMessage',
  informational: 'informationalMessage',
  progress: 'progressIndicator',
};

export const OfficeNotificationKeys = {
  error: 'error',
  progress: 'progress',
  success: 'success',
};

export const GetItemExtensions = {
  base: 'base',
  salesforce: 'salesforce',
  toutIt: 'toutIt',
};

export const ScheduledMails = {
  folderName: 'Marketo Sales Scheduled Emails',
  parentFolderId: 'drafts',
};

export const PusherEvents = {
  blank: '',
  refreshLogout: 'client-refresh-logout',
  reminderDestroy: 'Reminder:destroy',
  reminderUpdate: 'Reminder:update',
};

export const ErrorResponseText = {
  missingReqs: 'no_recipient_subject',
  overLimit: 'over_limit',
  pastDue: 'past_due',
  unverifiedEmail: 'unverified',
};

export const NotificationPopups = {
  campaignAlreadyOnOne: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'campaigns.errors.alreadyOnOne.body',
      button: 'common.close',
      title: 'campaigns.errors.alreadyOnOne.title',
    },
    showHelp: false,
  },
  campaignMarkSuccess: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'campaigns.errors.markSuccess.title',
    },
    showHelp: true,
  },
  campaignRemove: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'campaigns.errors.remove.title',
    },
    showHelp: true,
  },
  campaignsGet: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'campaigns.errors.unexpected.title',
    },
    showHelp: true,
  },
  contentGet: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'content.errors.unexpected.title',
    },
    showHelp: true,
  },
  fileAttachmentsAlreadyExist: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'contentAttachments.alreadyExist.body',
      button: 'common.ok',
      messageList: [],
      title: 'contentAttachments.alreadyExist.title',
    },
    showHelp: false,
  },
  officeAttachments: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'office.errors.attachments.body',
      button: 'common.close',
      title: 'office.errors.attachments.title',
    },
    showHelp: false,
  },
  officeCcBcc: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'office.errors.ccBcc.body',
      button: 'common.close',
      title: 'office.errors.template.title',
    },
    showHelp: false,
  },
  officeContent: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'office.errors.content.body',
      button: 'common.close',
      title: 'office.errors.content.title',
    },
    showHelp: false,
  },
  officeIssue: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'office.errors.issue.body',
      button: 'common.close',
      title: 'office.errors.issue.title',
    },
    showHelp: false,
  },
  officeMeetings: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'office.errors.meetings.body',
      button: 'common.close',
      title: 'office.errors.meetings.title',
    },
    showHelp: false,
  },
  officeTemplate: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'office.errors.template.body',
      button: 'common.close',
      title: 'office.errors.template.title',
    },
    showHelp: false,
  },
  salesforceIssue: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'salesforce.errors.log.body',
      button: 'common.close',
      title: 'salesforce.errors.log.title',
    },
    showHelp: false,
  },
  taskDestroy: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'tasks.errors.delete.body',
      button: 'common.close',
      title: 'tasks.errors.delete.title',
    },
    showHelp: true,
  },
  taskUpdate: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'tasks.errors.update.body',
      button: 'common.close',
      title: 'tasks.errors.update.title',
    },
    showHelp: true,
  },
  templateInfo: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'templates.errors.templateInfo.title',
    },
    showHelp: false,
  },
  templateSave: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'templates.errors.save.body',
      button: 'common.close',
      title: 'templates.errors.save.title',
    },
    showHelp: true,
  },
  templateSaveAsInfoError: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'templates.errors.saveAsInfo.body',
      button: 'common.close',
      title: 'templates.errors.saveAsInfo.title',
    },
    showHelp: false,
  },
  templatesGet: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'common.errors.unexpected.body',
      button: 'common.close',
      title: 'templates.errors.unexpected.title',
    },
    showHelp: true,
  },
  toutItCantAddCookie: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.cantAddCookie',
      button: 'common.close',
      title: 'toutit.errors.unexpected.title',
    },
    showHelp: false,
  },
  toutItDynamicFields: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.hasDynamicFields.body',
      button: 'common.ok',
      title: 'toutit.errors.hasDynamicFields.title',
      values: { workaround: '{{...}}' },
    },
    showHelp: false,
  },
  toutItEngDisconnected: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.engDisconnected.body',
      button: 'common.ok',
      title: 'toutit.errors.engDisconnected.title',
    },
    showHelp: false,
  },
  toutItGetEmailFromOutlook: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.getEmailFromOutlook',
      button: 'common.close',
      title: 'toutit.errors.unexpected.title',
    },
    showHelp: false,
  },
  toutItGetExchangeInfoError: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.getExchangeInfoError',
      button: 'common.close',
      title: 'toutit.errors.unexpected.title',
    },
    showHelp: false,
  },
  toutItMissingReqs: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.noRecipientSubject.body',
      button: 'common.ok',
      title: 'toutit.errors.noRecipientSubject.title',
    },
    showHelp: false,
  },
  toutItOverLimit: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.overLimit.body',
      button: 'toutit.errors.overLimit.button',
      title: 'toutit.errors.overLimit.title',
    },
    showHelp: false,
  },
  toutItPastDue: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.pastDue.body',
      button: 'toutit.errors.pastDue.button',
      title: 'toutit.errors.pastDue.title',
    },
    showHelp: false,
  },
  toutItSaveInCoreSuccess: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutIt.notifications.saveCore.success.body',
      title: 'toutIt.notifications.saveCore.success.title',
    },
    showHelp: false,
  },
  toutItSaveInExchangeInfoError: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.saveInExchangeInfoError',
      button: 'common.close',
      title: 'toutit.errors.unexpected.title',
    },
    showHelp: false,
  },
  toutItScheduledSuccess: {
    id: ButtonIds.notificationCloseEmail,
    messageKeys: {
      body: 'toutit.notifications.officeScheduledSuccess.body',
      button: 'common.close',
      title: 'toutit.notifications.officeScheduledSuccess.title',
    },
    showHelp: false,
  },
  toutItSendEmailError: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.sendEmailError',
      button: 'common.close',
      title: 'toutit.errors.unexpected.title',
    },
    showHelp: false,
  },
  toutItSuccess: {
    id: ButtonIds.notificationCloseEmail,
    messageKeys: {
      body: 'toutIt.notifications.success.body',
      button: 'common.close',
      title: 'toutIt.notifications.success.title',
    },
    showHelp: false,
  },
  toutItUnexpected: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.unexpected.body',
      button: 'common.close',
      title: 'toutit.errors.unexpected.title',
    },
    showHelp: false,
  },
  toutItUnfilledPrompts: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.hasUnfilledPrompts.body',
      button: 'common.ok',
      title: 'toutit.errors.hasUnfilledPrompts.title',
      values: { workaround: '{{...}}' },
    },
    showHelp: false,
  },
  toutItUnverifiedEmail: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.unverified.body',
      button: 'common.close', // todo find way to find verification email
      title: 'toutit.errors.unverified.title',
    },
    showHelp: false,
  },
  toutItUpdateBodyByLinkifiedError: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.updateBodyByLinkifiedError',
      button: 'common.close',
      title: 'toutit.errors.unexpected.title',
    },
    showHelp: false,
  },
  toutItUpdatePitchIdentifierError: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'toutit.errors.updatePitchIdentifierError',
      button: 'common.close',
      title: 'toutit.errors.unexpected.title',
    },
    showHelp: false,
  },
  warningLinkPreview: {
    id: ButtonIds.notification,
    messageKeys: {
      body: 'warning.linkPreview.body',
      button: 'common.close',
      title: 'warning.linkPreview.title',
    },
    showHelp: false,
  },
};

export const PopupMesages = {
  close: 'closePopup',
  injectMeetings: 'injectMeetings',
  office365ConnectionSuccess: 'office365ConnectionSuccess',
  salesforceConnectionSuccess: 'salesforceConnectionSuccess',
  saveTemplate: 'saveTemplate',
};

export const ARCHIVED = 'Archived';

export const MESSAGE_ID = 'Message-ID';

export const COLUMN_PITCHES_SENT = 'pitches_sent';

export const ALL_PERSON_QUERY_FIELDS = [
  'first_name',
  'last_name',
  'addresses',
  'email_addresses',
  'title',
  'company',
];

export const PEOPLE_SEARCH_RESULT_FIELDS = 'people_search_result_fields';

export const MAX_AUTOCOMPLETE_RESULTS = 3;

export const SortDirections = {
  asc: 'asc',
  desc: 'desc',
};

export const ElasticSearchTypes = {
  people: 'person',
};
