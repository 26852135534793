import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonColors, ButtonSizes } from 'components/buttons/action';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import NonHoverTooltipContainer from 'web/nonHoverTooltip/nonHoverTooltipContainer/nonHoverTooltipContainer';
import {
  EditIdentityEditorId,
  AutomaticallyFilledSignature,
} from 'web/slideOuts/editIdentity/libs/editIdentitySlideOutConstants';
import TinyMceEditor from 'web/tinyMceEditor/components/tinyMceEditor';

class Editing extends Component {
  constructor(props) {
    super(props);
    this.editorId = EditIdentityEditorId;
  }

  componentWillUnmount() {
    const {
      actionCreators: { removeEditorInstance },
    } = this.props;
    removeEditorInstance(this.editorId);
  }

  onClickAutoFillSignature = () => {
    const {
      actionCreators: { updateEditorBody },
    } = this.props;
    updateEditorBody(this.editorId, AutomaticallyFilledSignature);
  };

  saveSignature = () => {
    const { onSave } = this.props;
    onSave(this.editorId);
  };

  getEditor = () => {
    const { signature, actionCreators } = this.props;

    return (
      <TinyMceEditor
        handleEditorChange={(body) =>
          actionCreators.updateEditorBodies(this.editorId, body)
        }
        id={this.editorId}
        name={this.editorId}
        options={{ disableAddContent: true }}
        storeEditorInstance={(instance) =>
          actionCreators.storeEditorInstance(this.editorId, instance)
        }
        value={signature}
      />
    );
  };

  render() {
    const { onCancel, isSignatureFormDirty } = this.props;

    return (
      <div className="identity-details identity-form">
        <div className="identity-section">
          <h4 className="identity-section-title">
            <FormattedMessage id="common.signature" />
          </h4>
          <div>
            <Button
              className="margin-bottom-full"
              color={ButtonColors.gray}
              onClick={this.onClickAutoFillSignature}
            >
              <FormattedMessage id="web.identities.editButtons.autofillSignature" />
            </Button>
            {this.getEditor()}
            <NonHoverTooltipContainer />
          </div>
          <div className="margin-top-half actions">
            <Button
              color={ButtonColors.gray}
              onClick={onCancel}
              size={ButtonSizes.standard}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              className="margin-left-full"
              color={ButtonColors.blue}
              disabled={!isSignatureFormDirty}
              onClick={this.saveSignature}
              size={ButtonSizes.standard}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Editing.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isSignatureFormDirty: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  signature: PropTypes.string.isRequired,
};

export default injectIntl(Editing);
