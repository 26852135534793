import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { track } from 'web/services/mixpanelService';
import { CampaignListEvents } from 'web/libs/mixpanelEvents';

export function filterCampaignsList(filter, dontTrack = false) {
  if (!dontTrack) {
    track(CampaignListEvents.filtered, { filter });
  }

  return {
    type: ActionTypes.campaignsTab.state.campaignsListFilter,
    filter,
  };
}
