export default {
  completedFailure: 'TASKS_COMPLETED_FAILURE',
  completedRequest: 'TASKS_COMPLETED_REQUEST',
  completedReset: 'TASKS_COMPLETED_RESET',
  completedSuccess: 'TASKS_COMPLETED_SUCCESS',
  completing: 'TASKS_COMPLETING',
  completingBulk: 'TASKS_COMPLETING_BULK',
  customReset: 'TASKS_CUSTOM_RESET',
  destroy: 'TASKS_DESTROY',
  destroyBulk: 'TASKS_DESTROY_BULK',
  fetching: 'TASKS_FETCHING',
  fetchingDone: 'TASKS_FETCHING_DONE',
  init: 'TASKS_INIT',
  uncompletedFailure: 'TASKS_UNCOMPLETED_FAILURE',
  uncompletedRequest: 'TASKS_UNCOMPLETED_REQUEST',
  uncompletedSuccess: 'TASKS_UNCOMPLETED_SUCCESS',
  uncompleting: 'TASKS_UNCOMPLETING',
  update: 'TASKS_UPDATE',
};
