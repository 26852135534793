import React, { useLayoutEffect, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import ActionBar from 'web/composeWindow/components/actionBar';
import AddressBar from 'web/composeWindow/components/addressBar/addressBar';
import ComposeEmailHeaderBar from 'web/composeWindow/components/composeEmailHeaderBar';
import {
  bulkSendPitch,
  closeAlertById,
  closeComposeWindow,
  intendSendPitch as sendPitch,
  wrappedSetPitchSendAt as setPitchSendAt,
  setMinimizeView,
  setTemplatesMinimized,
  setEditorFocused,
  toggleBulkView,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import TinyMCEEditor from './composeEditorContainer';
import {
  imageInsertUploadFiles,
  closeImageInsertModal,
  removeAttachment,
} from 'web/composeWindow/actionCreators/fileActionCreators';
import {
  getPitchState,
  getSendLoading,
  isCcBccValid,
} from 'web/composeWindow/selectors/composeWindowSelectors';
import ComposeWindowSpinnerContainer from 'web/composeWindow/containers/composeWindowSpinnerContainer';
import ComposePopupsContainer from 'web/composeWindow/containers/composePopupsContainer';
import ComposeBulkEmailsContainer from 'web/composeWindow/containers/composeBulkEmailsContainer';
import classNames from 'classnames';
import ComposeTemplates from 'web/composeWindow/containers/composeTemplatesContainer';
import ComposeWindowAlerts from 'web/composeWindow/components/composeWindowAlerts/composeWindowAlerts';
import SchedulerPopUp from 'web/composeWindow/components/schedulerPopUp';
import TemplateAttachment from 'components/templateAttachment';
import AlertsCollapsible from 'components/alertsCollapsible';
import Alert, {
  AlertColors,
  AlertIconTypes,
  AlertLocations,
} from 'components/alert';
import { AlertIds } from '../libs/composeWindowConstants';
import { shouldHideDeliveryChannels as shouldHideDeliveryChannelsSelector } from 'web/deliveryChannels/selectors/deliveryChannelsSelectors';
import { Urls } from 'web/libs/routes';
import './composeWindowContainer.scss';

const ComposeWindowContainer = (props) => {
  const {
    alerts,
    bulkSendPitch,
    ccBccValid,
    closeAlertById,
    closeComposeWindow,
    composeBulkViewOpened,
    composeEmailAttachments,
    composeWindowLoading,
    composeWindowMinimized,
    composeWindowOpened,
    composeWindowReadOnly,
    editorFocused,
    group,
    identity,
    isModalMode,
    isSendLoading,
    pitchSendAt,
    pitchState,
    removeAttachment,
    setMinimizeView,
    sendPitch,
    setPitchSendAt,
    subject,
    timezones,
    userTimezone,
    setTemplatesMinimized,
    isTemplatesMinimized,
    setEditorFocused,
    templatesLoading,
    pinnedCategoriesLoading,
    shouldHideDeliveryChannels,
  } = props;

  const [isCcShowing, setCcShowing] = useState(false);
  const [isBccShowing, setBccShowing] = useState(false);
  const [isSchedulerShowing, setSchedulerShowing] = useState(false);
  const [errorsAlert, setErrorsAlert] = useState([]);
  const [successesAlert, setSuccessesAlert] = useState([]);
  const [isShowDeliveryBanner, setShowDeliveryBanner] = useState(true);
  const successStr = 'SUCCESS';

  useLayoutEffect(() => minimizeCcBcc(), [editorFocused]);

  useEffect(
    () => {
      setErrorsAlert(
        alerts
          .filter((alert) => !alert.id.includes(successStr))
          .filter(
            (alert) => !alert.id.includes(AlertIds.duplicateTemplateCategory)
          )
      );
      setSuccessesAlert(
        alerts.filter((alert) => alert.id.includes(successStr))
      );
    },
    [alerts]
  );

  function minimizeCcBcc() {
    if (ccBccValid) {
      setCcShowing(false);
      setBccShowing(false);
    }
  }

  function handleFocus() {
    setTemplatesMinimized(true);
    setSchedulerShowing(false);
    setEditorFocused(true);
  }

  function handleBlur() {
    setEditorFocused(false);
  }

  function hasInvalidDeliveryChannel() {
    return (
      shouldHideDeliveryChannels &&
      !isEmpty(identity) &&
      !identity.authentication_id
    );
  }

  const isBulkModal = composeBulkViewOpened && isModalMode;

  const composeContainerClassNames = classNames(
    'tout-ui-styles compose-window-container',
    {
      'bulk-modal': isBulkModal,
      closed: !composeWindowOpened,
      minimized: composeWindowMinimized,
      'modal-mode': isModalMode,
    }
  );

  const isLoading =
    composeWindowLoading || pinnedCategoriesLoading || templatesLoading;

  const hideGroupResults = isModalMode && !isBulkModal;

  const defaultProperties = {
    delay: 5000,
    location: AlertLocations.top,
    onClose: () => {
      setErrorsAlert([]);
      setSuccessesAlert([]);
    },
    outsideClickDisabled: true,
  };

  const errorProperties = {
    color: AlertColors.redDark,
    ...defaultProperties,
  };

  return (
    <div className={composeContainerClassNames}>
      <ComposePopupsContainer modalMode={isModalMode} />
      <ComposeBulkEmailsContainer
        composeBulkViewOpened={composeBulkViewOpened}
      />
      <div className="master-email-view">
        {isLoading && <ComposeWindowSpinnerContainer />}
        <ComposeEmailHeaderBar
          closeComposeWindow={closeComposeWindow}
          composeBulkViewOpened={composeBulkViewOpened}
          composeWindowMinimized={composeWindowMinimized}
          modalMode={isModalMode}
          pitchState={pitchState}
          setMinimizeView={setMinimizeView}
          subject={subject}
        />
        <div className="compose-window-content">
          {!successesAlert.length && errorsAlert.length > 1 ? (
            <AlertsCollapsible
              {...errorProperties}
              countErrors={errorsAlert.length}
              textId="web.composeWindow.addressBar.composeTitleError"
            >
              <ComposeWindowAlerts
                alerts={errorsAlert}
                closeAlertById={closeAlertById}
              />
            </AlertsCollapsible>
          ) : (
            <ComposeWindowAlerts
              alerts={errorsAlert}
              closeAlertById={closeAlertById}
            />
          )}
          {successesAlert.length ? (
            <ComposeWindowAlerts
              alerts={successesAlert}
              closeAlertById={closeAlertById}
            />
          ) : null}
          {hasInvalidDeliveryChannel() &&
            isShowDeliveryBanner && (
              <Alert
                color={AlertColors.redDark}
                html={true}
                icon={AlertIconTypes.warning}
                location={AlertLocations.top}
                manual={true}
                onClose={() => setShowDeliveryBanner(false)}
                outsideClickDisabled={true}
                textId="web.composeWindow.pinnedCategories.deliveryChannelBannerError"
                textValues={{ url: Urls.emailConnectionTabActive }}
              />
            )}
          <AddressBar
            disabled={composeWindowReadOnly}
            hideGroupResults={hideGroupResults}
            isBccShowing={isBccShowing}
            isCcShowing={isCcShowing}
            minimizeCcBcc={minimizeCcBcc}
            setBccShowing={setBccShowing}
            setCcShowing={setCcShowing}
          />
          <ComposeTemplates
            isTemplatesMinimized={isTemplatesMinimized}
            setTemplatesMinimized={setTemplatesMinimized}
          />
          <TinyMCEEditor
            disabled={composeWindowReadOnly}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
          />

          {!!composeEmailAttachments.length && (
            <TemplateAttachment
              attachments={composeEmailAttachments}
              removeAttachment={removeAttachment}
            />
          )}
          <ActionBar
            bulkSendPitch={bulkSendPitch}
            composeBulkViewOpened={composeBulkViewOpened}
            group={group}
            isSchedulerShowing={isSchedulerShowing}
            isSendLoading={isSendLoading}
            pitchSendAt={pitchSendAt}
            sendPitch={sendPitch}
            setSchedulerShowing={setSchedulerShowing}
            setShowDeliveryBanner={setShowDeliveryBanner}
            shouldHideDeliveryChannels={hasInvalidDeliveryChannel()}
          />
          {isSchedulerShowing && (
            <SchedulerPopUp
              closePopup={() => setSchedulerShowing(false)}
              pitchSendAt={pitchSendAt}
              setPitchSendAt={setPitchSendAt}
              timezones={timezones}
              userTimezone={userTimezone}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ComposeWindowContainer.propTypes = {
  alerts: PropTypes.array.isRequired,
  bulkSendPitch: PropTypes.func.isRequired,
  ccBccValid: PropTypes.bool.isRequired,
  closeAlertById: PropTypes.func.isRequired,
  closeComposeWindow: PropTypes.func.isRequired,
  composeBulkViewOpened: PropTypes.bool,
  composeEmailAttachments: PropTypes.array,
  composeWindowLoading: PropTypes.bool.isRequired,
  composeWindowMinimized: PropTypes.bool.isRequired,
  composeWindowOpened: PropTypes.bool.isRequired,
  composeWindowReadOnly: PropTypes.bool.isRequired,
  editorFocused: PropTypes.bool.isRequired,
  group: PropTypes.object,
  identity: PropTypes.object.isRequired,
  isModalMode: PropTypes.bool.isRequired,
  isSendLoading: PropTypes.bool.isRequired,
  isTemplatesMinimized: PropTypes.bool.isRequired,
  pinnedCategoriesLoading: PropTypes.bool.isRequired,
  pitchSendAt: PropTypes.string,
  pitchState: PropTypes.string.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  sendPitch: PropTypes.func.isRequired,
  setEditorFocused: PropTypes.func.isRequired,
  setMinimizeView: PropTypes.func.isRequired,
  setPitchSendAt: PropTypes.func.isRequired,
  setTemplatesMinimized: PropTypes.func.isRequired,
  shouldHideDeliveryChannels: PropTypes.bool.isRequired,
  subject: PropTypes.string.isRequired,
  templatesLoading: PropTypes.bool.isRequired,
  timezones: PropTypes.array.isRequired,
  userTimezone: PropTypes.string.isRequired,
};

ComposeWindowContainer.defaultProps = {
  composeBulkViewOpened: false,
  composeEmailAttachments: [],
  group: undefined,
  pitchSendAt: null,
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        bulkSendPitch,
        closeAlertById,
        closeComposeWindow,
        closeImageInsertModal,
        imageInsertUploadFiles,
        removeAttachment,
        sendPitch,
        setEditorFocused,
        setMinimizeView,
        setPitchSendAt,
        setTemplatesMinimized,
        toggleBulkView,
      },
      dispatch
    ),
  };
}

const mapStateToProps = (state) => ({
  alerts: state.composeWindowAlerts,
  ccBccValid: isCcBccValid(state),
  composeBulkViewOpened: state.composeWindowState.bulkViewOpened,
  composeEmailAttachments: state.composeEmailAttachments,
  composeEmailEditorImages: state.composeEmailEditorImages,
  composeWindowLoading: state.composeWindowState.isLoading,
  composeWindowMinimized: state.composeWindowState.minimized,
  composeWindowOpened: state.composeWindowState.opened,
  composeWindowReadOnly: state.composeWindowState.readOnly,
  dynamicFields: state.dynamicFields,
  editorFocused: state.composeWindowState.editorFocused,
  group: state.composeBulkEmails.group,
  identity: state.composeFrom.identity,
  isModalMode: state.composeWindowState.modalMode,
  isSendLoading: getSendLoading(state),
  isTemplatesMinimized: state.composeTemplateMinimized,
  pinnedCategoriesLoading: state.composePinnedCategoriesLoading,
  pitchSendAt: state.pitchSendAt,
  pitchState: getPitchState(state),
  popup: state.popup,
  popupData: state.popupData,
  shouldHideDeliveryChannels: shouldHideDeliveryChannelsSelector(state),
  subject: state.composeSubject.subject,
  templateCategories: state.composeTemplatesCategories,
  templatesLoading: state.composeTemplatesLoading,
  timezones: state.timezones,
  userTimezone: state.userTimezone,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeWindowContainer);
