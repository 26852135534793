import MarketoSettingsActionTypes from '../libs/marketoSettingsActionTypes';
import {
  TeamAccessSmartFilterValues,
  DefaultWorkspace,
} from '../libs/marketoSettingsConstants';

export function marketoUsersAuthenticationsBeingToggled(
  state = [],
  { type = '', userIds = [] }
) {
  switch (type) {
    case MarketoSettingsActionTypes.userAccess.grantAccess:
    case MarketoSettingsActionTypes.userAccess.revokeAccess:
      return state.concat(userIds);
    case MarketoSettingsActionTypes.userAccess.grantAccessSuccess:
    case MarketoSettingsActionTypes.userAccess.grantAccessFail:
    case MarketoSettingsActionTypes.userAccess.revokeAccessSuccess:
    case MarketoSettingsActionTypes.userAccess.revokeAccessFail:
      return [...state].filter((id) => !userIds.includes(id));
    default:
      return state;
  }
}

export function marketoAuthenticationUserIds(
  state = [],
  { type = '', userIds = [] }
) {
  switch (type) {
    case MarketoSettingsActionTypes.getConnectionSuccess:
      return userIds;
    case MarketoSettingsActionTypes.userAccess.grantAccessSuccess:
    case MarketoSettingsActionTypes.userAccess.revokeAccessFail:
      return state.concat(userIds);
    case MarketoSettingsActionTypes.userAccess.revokeAccessSuccess:
    case MarketoSettingsActionTypes.userAccess.grantAccessFail:
      return [...state].filter((id) => !userIds.includes(id));
    default:
      return state;
  }
}

export function marketoAuthenticationUsers(
  state = [],
  { type = '', auths = [] }
) {
  switch (type) {
    case MarketoSettingsActionTypes.userAccess.getMarketoAuthenticationUsers:
      return auths;
    default:
      return state;
  }
}

export function marketoAdminSubscriptionUsers(
  state = [],
  { type = '', users = [] }
) {
  switch (type) {
    case MarketoSettingsActionTypes.userAccess.getSubscriptionUsersSuccess:
      return users;
    default:
      return state;
  }
}

export function marketoAdminTeamAccessGettingUsers(
  state = false,
  { type = '' }
) {
  switch (type) {
    case MarketoSettingsActionTypes.userAccess.getSubscriptionUsers:
      return true;
    case MarketoSettingsActionTypes.userAccess.getSubscriptionUsersSuccess:
      return false;
    default:
      return state;
  }
}

export function marketoAdminTeamAccessSmartFilter(
  state = TeamAccessSmartFilterValues.all,
  { type = '', filter = '' }
) {
  switch (type) {
    case MarketoSettingsActionTypes.userAccess.smartFilter:
      return filter;
    default:
      return state;
  }
}

export const marketoAdminPage = (state = 1, { type, page = 1 }) => {
  switch (type) {
    case MarketoSettingsActionTypes.userAccess.page:
      return page;
    default:
      return state;
  }
};

export function marketoAdminWorkspaces(
  state = [{ name: DefaultWorkspace }],
  { type = '', workspaces = [] }
) {
  switch (type) {
    case MarketoSettingsActionTypes.workspacesModal.getWorkspacesSuccess:
      return workspaces;
    default:
      return state;
  }
}

export function marketoAdminSelectedWorkspaces(
  state = [DefaultWorkspace],
  { type = '', workspaces = [DefaultWorkspace] }
) {
  switch (type) {
    case MarketoSettingsActionTypes.workspacesModal.updateWorkspaces:
      return workspaces;
    default:
      return state;
  }
}
