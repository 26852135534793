import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { LocalStorageKeys } from 'web/libs/constants';
import { track } from 'web/services/mixpanelService';
import {
  CampaignSetupTabEvents,
  CampaignSetupTabProperties,
  CampaignTabEvents,
  CampaignTabProperties,
} from 'web/libs/mixpanelEvents';

export function getCampaignsTabSettings() {
  return (dispatch) => {
    const slideOutMenuOpen = [null, 'true'].includes(
      window.localStorage.getItem(LocalStorageKeys.campaigns.slideOutMenuOpen)
    );
    const setupHorizontal =
      window.localStorage.getItem(
        LocalStorageKeys.campaigns.setupHorizontal
      ) === 'true';
    dispatch({
      type: ActionTypes.campaignsTab.state.settings,
      state: {
        slideOutMenuOpen,
        setupHorizontal,
      },
    });
  };
}

export function setCampaignSetupView(horizontal = false) {
  window.localStorage.setItem(
    LocalStorageKeys.campaigns.setupHorizontal,
    horizontal
  );
  track(CampaignSetupTabEvents.viewClick, {
    view: horizontal
      ? CampaignSetupTabProperties.horizontal
      : CampaignSetupTabProperties.vertical,
  });
  return {
    type: ActionTypes.campaignsTab.state.settingsSetupHorizontal,
    state: horizontal,
  };
}

export function setSlideOutMenuOpen(open = true) {
  return (dispatch) => {
    window.localStorage.setItem(
      LocalStorageKeys.campaigns.slideOutMenuOpen,
      open
    );
    track(CampaignTabEvents.dotaMenuOpen, {
      view: open ? CampaignTabProperties.open : CampaignTabProperties.close,
    });
    dispatch({
      type: ActionTypes.campaignsTab.state.settingsSlideOutMenuOpen,
      state: open,
    });
  };
}
