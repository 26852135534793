import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import './campaignEditWarning.scss';

class CampaignEditWarning extends Component {
  render() {
    const users = this._generateUsers();
    return (
      <div className="margin-bottom-full">
        <FormattedHTMLMessage id="web.campaigns.editingLiveCampaign" />
        <div className="edit-live-warning-people-list">
          {users.map((user, index) => (
            <div
              className={classNames({
                'edit-live-warning-people-list-item': users.length > 1,
              })}
              key={`edit-warning-user-${index}`} /* eslint-disable-line react/no-array-index-key */
            >
              <FormattedHTMLMessage
                id="web.campaigns.personUsingCampaign"
                values={{ user: user.name, count: user.count }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  _generateUsers = () => {
    const { selectedCampaign, account } = this.props;
    const users = [];

    for (const user in selectedCampaign.currently_active_count) {
      if (
        {}.hasOwnProperty.call(selectedCampaign.currently_active_count, user)
      ) {
        users.push(parseInt(user, 10));
      }
    }

    const filteredUsers = account.subscription.users.filter((user) =>
      users.includes(user.id)
    );

    return filteredUsers.map((user) => ({
      name: user.name,
      count: selectedCampaign.currently_active_count[user.id],
    }));
  };
}

CampaignEditWarning.propTypes = {
  selectedCampaign: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  account: PropTypes.object.isRequired,
  loading: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

CampaignEditWarning.defaultProps = {
  loading: false,
};

export default CampaignEditWarning;
