import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import {
  TrackingPopupIds,
  TrackingTabs,
  TrackingAlertIds,
} from '../libs/trackingConstants';
import {
  saveCustomTrackingDomain,
  deleteCustomTrackingDomain,
  getRecentWebsiteTrackingEvents,
} from 'web/tracking/actionCreators/trackingActionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import TrackingActionTypes from '../libs/trackingActionTypes';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';

export const setTab = (tab) => ({
  type: TrackingActionTypes.setTab,
  tab,
});

export const trackingStartup = ({
  tab = TrackingTabs.customDomainTracking,
}) => (dispatch) => {
  const activeTab = Object.values(TrackingTabs).includes(tab)
    ? tab
    : TrackingTabs.customDomainTracking;
  dispatch(commonStartup());
  dispatch(setTab(activeTab));
  if (tab === TrackingTabs.websiteTracking) {
    dispatch(getRecentWebsiteTrackingEvents());
  }
};

export const saveCustomDomain = (form = {}) => (dispatch) => {
  const { custom_tracking_domain: domain } = form;
  return dispatch(saveCustomTrackingDomain(domain))
    .then(() => {
      dispatch(openViewAlert(TrackingAlertIds.success));
    })
    .catch(({ status = null }) => {
      if (status === 422) {
        dispatch(openViewAlert(TrackingAlertIds.cnameNotValid));
      } else {
        dispatch(openViewAlert(TrackingAlertIds.failure));
      }
    });
};

export const deleteCustomDomain = () => (dispatch) =>
  dispatch(deleteCustomTrackingDomain())
    .then(() => {
      dispatch(openViewAlert(TrackingAlertIds.success));
    })
    .catch(() => {
      dispatch(openViewAlert(TrackingAlertIds.failure));
    });

export const openDeleteCustomDomainConfirmation = () => (dispatch) =>
  dispatch(openPopup(TrackingPopupIds.deleteCustomDomain));
