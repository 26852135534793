import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { BackgroundColors, FULL } from 'libs/constantsStyles';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import { commonUserAdminTeamPermission } from 'web/shared/services/accountService';
import Cards, { Direction } from 'components/layouts/cards';
import InfoCard from '../infoCard';
import GroupsCard from '../groupsCard';
import AccountInfoCard from '../accountInfoCard';
import CustomFieldsCard from '../customFieldsCard';
import ComplianceCard from '../complianceCard';
import UnsubscribeHistory from '../unsubscribeHistory';
import NotesCard from 'web/notes/components/notesCard';
import './aboutView.scss';

class AboutView extends Component {
  render() {
    return (
      <div className="person-details-about-view">
        <Cards
          backgroundColor={BackgroundColors.grayExtraLight}
          cards={this._getCards()}
          direction={Direction.vertical}
          maxWidth={0}
          minWidth={0}
          marginAround={FULL}
          marginBetween={FULL}
        />
      </div>
    );
  }

  _getCards = () => {
    const {
      actionCreators,
      accountInfo,
      accountInfoLoading,
      accountInfoEditState,
      complianceCardCanSave,
      complianceEditState,
      customFieldsEditState,
      customFieldsCardHasChanges,
      editing,
      groupsAddable,
      groupsCardHasChanges,
      groupsEditState,
      infoCardHasChanges,
      intl: { formatMessage },
      isMsiActions,
      salesforceRequiredFields,
      infoEditState,
      person,
      salesforceInstanceUrl,
      unsubscribeHistory,
      unsubscribeOptInReasons,
      unsubscribeReasons,
      userSubscriptionUsers,
      user,
    } = this.props;
    const disableEditing = !commonUserAdminTeamPermission(person.id);

    const cards = [
      {
        children: (
          <InfoCard
            actionCreators={actionCreators}
            disableEditing={disableEditing}
            infoCardHasChanges={infoCardHasChanges}
            isEditing={editing[PersonDetailsCards.info]}
            salesforceRequiredFields={salesforceRequiredFields}
            infoEditState={infoEditState}
            person={person}
            user={user}
            salesforceInstanceUrl={salesforceInstanceUrl}
          />
        ),
        id: PersonDetailsCards.info,
      },
      {
        children: (
          <GroupsCard
            actionCreators={actionCreators}
            disableEditing={disableEditing}
            isEditing={editing[PersonDetailsCards.groups]}
            groupsAddable={groupsAddable}
            groupsCardHasChanges={groupsCardHasChanges}
            groupsEditState={groupsEditState}
            person={person}
          />
        ),
        id: PersonDetailsCards.groups,
      },
      {
        children: salesforceInstanceUrl &&
          !accountInfoLoading && (
            <AccountInfoCard
              actionCreators={actionCreators}
              accountInfo={accountInfo}
              accountInfoLoading={accountInfoLoading}
              accountInfoEditState={accountInfoEditState}
              salesforceRequiredFields={salesforceRequiredFields}
              isEditing={editing[PersonDetailsCards.accountInfo]}
              person={person}
              salesforceInstanceUrl={salesforceInstanceUrl}
            />
          ),
        id: PersonDetailsCards.accountInfo,
      },
      !isMsiActions && {
        children: (
          <CustomFieldsCard
            actionCreators={actionCreators}
            isEditing={editing[PersonDetailsCards.customFields]}
            customFieldsCardHasChanges={customFieldsCardHasChanges}
            customFieldsEditState={customFieldsEditState}
            person={person}
          />
        ),
        id: PersonDetailsCards.customFields,
      },
      {
        children: (
          <ComplianceCard
            actionCreators={actionCreators}
            complianceCardCanSave={complianceCardCanSave}
            complianceEditState={complianceEditState}
            isEditing={editing[PersonDetailsCards.compliance]}
            person={person}
          />
        ),
        id: PersonDetailsCards.compliance,
      },
      {
        children: (
          <NotesCard
            createNote={actionCreators.createNote}
            deleteNote={actionCreators.openDeleteNote}
            editNote={actionCreators.editNote}
            notes={person.notesParsed}
          />
        ),
        id: PersonDetailsCards.notes,
      },
    ];

    if (unsubscribeHistory.length) {
      cards.push({
        children: (
          <UnsubscribeHistory
            formatMessage={formatMessage}
            items={unsubscribeHistory}
            userSubscriptionUsers={userSubscriptionUsers}
            unsubscribeOptInReasons={unsubscribeOptInReasons}
            unsubscribeReasons={unsubscribeReasons}
          />
        ),
        id: PersonDetailsCards.unsubscribeHistory,
      });
    }

    return cards;
  };
}

AboutView.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  accountInfo: PropTypes.array,
  accountInfoLoading: PropTypes.bool.isRequired,
  accountInfoEditState: PropTypes.object.isRequired,
  complianceCardCanSave: PropTypes.bool.isRequired,
  complianceEditState: PropTypes.object.isRequired,
  customFieldsEditState: PropTypes.array.isRequired,
  customFieldsCardHasChanges: PropTypes.bool.isRequired,
  editing: PropTypes.object.isRequired,
  groupsAddable: PropTypes.array.isRequired,
  groupsCardHasChanges: PropTypes.bool.isRequired,
  groupsEditState: PropTypes.object.isRequired,
  infoCardHasChanges: PropTypes.bool.isRequired,
  salesforceRequiredFields: PropTypes.object.isRequired,
  infoEditState: PropTypes.object.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  person: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string,
  setScrollTop: PropTypes.func.isRequired,
  unsubscribeHistory: PropTypes.array,
  unsubscribeOptInReasons: PropTypes.object,
  unsubscribeReasons: PropTypes.object,
  user: PropTypes.object.isRequired,
  userSubscriptionUsers: PropTypes.object,
};

AboutView.defaultProps = {
  accountInfo: [],
  salesforceInstanceUrl: '',
  unsubscribeHistory: [],
  unsubscribeOptInReasons: {},
  unsubscribeReasons: {},
  userSubscriptionUsers: {},
};

export default injectIntl(AboutView);
