import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Label from 'components/label';
import HoverTooltip from 'components/hoverTooltip';
import { LABEL_WIDTH } from '../../../libs/editIdentitySlideOutConstants';
import { getDeliveryChannelLabel } from 'web/deliveryChannels/helpers/identitiesHelper';

function Details(props) {
  const {
    actionCreators: { navigateToSmtpServer },
    intl: { formatMessage },
    identityModel,
    deliveryChannels,
    shouldHideDeliveryChannels,
  } = props;

  const deliveryChannel = deliveryChannels.find(
    (channel) => channel.id === identityModel.authentication_id
  );

  const deliveryChannelLabel = getDeliveryChannelLabel({
    deliveryChannels,
    deliveryChannel,
    formatMessage,
    shouldHideDeliveryChannels,
  });

  const getDetailsText = () => {
    if (shouldHideDeliveryChannels && !deliveryChannel) {
      if (deliveryChannels.length) {
        return (
          <FormattedHTMLMessage id="web.identities.details.chooseDeliveryChannel" />
        );
      } else {
        return (
          <a onClick={navigateToSmtpServer}>
            <FormattedMessage id="web.identities.setSmtpServer" />
          </a>
        );
      }
    } else {
      return <span>{deliveryChannelLabel}</span>;
    }
  };

  return (
    <div className="identity-details">
      <div className="identity-section">
        <h4 className="identity-section-title">
          <FormattedMessage id="web.identities.form.section.channel.title" />
        </h4>
        <p className="identity-section-description">
          <FormattedHTMLMessage id="web.identities.form.section.channel.description" />
        </p>
        <div className="identity-field-item">
          <Label
            id="common.channel"
            className="identity-field-label"
            classWrapper="identity-field"
            classChildren="identity-field-label-child"
            messageKey="common.channel"
            sideLabel
            width={LABEL_WIDTH}
          >
            {deliveryChannel &&
            ['google', 'google_oauth2'].includes(deliveryChannel.provider) ? (
              <div className="channel-warning-wrapper">
                <HoverTooltip
                  place="top"
                  textId="web.identities.form.section.channel.googleHoverText"
                  stayOpen={false}
                  tooltipId="channel-authentication-tooltip"
                >
                  <span>{deliveryChannelLabel}</span>
                  <i className="channel-warning" />
                </HoverTooltip>
              </div>
            ) : (
              <div>{getDetailsText()}</div>
            )}
          </Label>
        </div>
        <br className="clear" />
      </div>
    </div>
  );
}

Details.propTypes = {
  intl: intlShape.isRequired,
  identityModel: PropTypes.object,
  deliveryChannels: PropTypes.array.isRequired,
  shouldHideDeliveryChannels: PropTypes.bool.isRequired,
  actionCreators: PropTypes.object.isRequired,
};

export default injectIntl(Details);
