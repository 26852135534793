import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { ProgressStatus } from 'libs/constantsShared';
import { Icons } from 'libs/constantsStyles';
import ProgressBar from 'components/progressBar';
import './status.scss';

const DEFAULT_PROGRESS_BAR_WIDTH = 180;
const STATUS_FINISHED = 'finished';

class Status extends Component {
  state = {
    finishedUploadTimeout: false,
    timeoutId: 0,
  };

  componentWillUnmount() {
    const { timeoutId } = this.state;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.file.status === STATUS_FINISHED &&
      this.props.file.status !== STATUS_FINISHED
    ) {
      this._startTimeout();
    }
  }

  render() {
    return <div className="status-file">{this._getProgressComponent()}</div>;
  }

  _getProgressComponent = () => {
    const {
      file,
      height,
      intl: { formatMessage },
      width,
    } = this.props;
    const { finishedUploadTimeout } = this.state;
    if (file.status === ProgressStatus.finished && !finishedUploadTimeout) {
      return (
        <div className="uploaded">
          <div className={Icons.circleCheck} />
          {formatMessage({ id: 'progressBar.uploaded' })}
        </div>
      );
    }
    if (file.status === ProgressStatus.uploading) {
      return (
        <ProgressBar
          animate
          height={height}
          progress={file.progress}
          width={width || DEFAULT_PROGRESS_BAR_WIDTH}
        />
      );
    }
    if (file.status === ProgressStatus.issue) {
      return (
        <div className="text-center">
          {formatMessage({ id: 'progressBar.issue' })}
        </div>
      );
    }
    if (file.status === ProgressStatus.error) {
      return (
        <div className="text-center error">
          {formatMessage({ id: 'progressBar.error' })}
        </div>
      );
    }
    return null;
  };

  _startTimeout = () => {
    const self = this;
    const id = setTimeout(() => {
      self.setState({ finishedUploadTimeout: true, timeoutId: 0 });
    }, 5000);
    this.setState({ timeoutId: id });
  };
}

Status.propTypes = {
  file: PropTypes.object.isRequired,
  height: PropTypes.number,
  intl: intlShape.isRequired,
  // onClickX: PropTypes.func.isRequired,
  width: PropTypes.number,
};

Status.defaultProps = {
  height: 0,
  width: 0,
};

export default injectIntl(Status);
