import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Colors, Aligns } from 'libs/constantsShared';
import ToutTable from 'table/modules/toutTable';
import TextCell from 'table/modules/cells/textCell';
import SelectCell from 'table/modules/cells/selectCell';
import MenuCell from 'table/modules/cells/menuCell';
import { alphabetical } from 'table/modules/sorting';
import { getImageUrl } from 'web/libs/constants';
import {
  TeamMembersTableColumns,
  TeamMembersRoles,
  COLUMN_SETTINGS_TEAM_MEMBERS_TABLE_ID,
} from '../../libs/teamMembersConstants';
import { teamMembersTableExternals } from '../../helpers/teamMembersHelpers';
import { tablePerPageFromStorage } from 'web/services/storageService';
import './teamMembersTable.scss';

const CURRENT_PAGE = 1;
const ROW_HEIGHT = 70;
const TABLE_ID = 'all-members-table';

class TeamMembersTable extends Component {
  constructor(props) {
    super(props);

    this.bulkActions = this.getBulkActions();
    this.pagination = this.getPagination();
    this.search = this.getSearch();
    this.zeroState = this._getZeroState();
  }

  render() {
    const {
      actionCreators,
      columnSettings,
      fetching,
      loading,
      teamMembers,
    } = this.props;
    const columns = this.getColumns();

    return (
      <div className="team-members-table">
        <ToutTable
          animateRow={false}
          bulkActions={this.bulkActions}
          changeColumnsSettingsHandler={(newColumnsSettings) => {
            actionCreators.changeColumnsSettings(
              COLUMN_SETTINGS_TEAM_MEMBERS_TABLE_ID,
              newColumnsSettings
            );
          }}
          columnSettings={columnSettings}
          columns={columns}
          defaultSortingColumn={TeamMembersTableColumns.name}
          fetching={fetching}
          items={teamMembers}
          loading={loading}
          loadingGetUrl={getImageUrl}
          pagination={this.pagination}
          row={{ height: ROW_HEIGHT }}
          search={this.search}
          setTableExternals={this.setTableExternals}
          tableId={TABLE_ID}
          zeroState={this.zeroState}
          allItems
          columnFilter
          isResize
          reorderColumnsEnable
          selectable
        />
      </div>
    );
  }

  componentWillUnmount() {
    teamMembersTableExternals.unset();
  }

  getBulkActions() {
    const {
      actionCreators: {
        openAddTeamMembersToTeamsPopup,
        openDeleteTeamMembersConfirmationPopup,
      },
      intl: { formatMessage },
    } = this.props;

    return {
      actions: [
        {
          label: formatMessage({
            id:
              'web.settings.adminSettings.teamManagement.teamMembers.addToTeams',
          }),
          onClick: openAddTeamMembersToTeamsPopup,
        },
        {
          color: Colors.red,
          hideCount: true,
          label: formatMessage({
            id:
              'web.settings.adminSettings.teamManagement.teamMembers.deleteTeamMembers',
          }),
          onClick: openDeleteTeamMembersConfirmationPopup,
        },
      ],
    };
  }

  getColumns() {
    const {
      actionCreators: {
        openEditTeamsPopup,
        openDeleteTeamMemberConfirmationPopup,
      },
      intl: { formatMessage },
    } = this.props;

    return [
      {
        align: Aligns.left,
        id: TeamMembersTableColumns.name,
        name: formatMessage({ id: 'common.name' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'name',
          },
        },
        sorting: alphabetical('name'),
        width: 200,
      },
      {
        align: Aligns.left,
        id: TeamMembersTableColumns.email,
        name: formatMessage({ id: 'common.email' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'email',
          },
        },
        sorting: alphabetical('email'),
        width: 250,
      },
      {
        align: Aligns.center,
        id: TeamMembersTableColumns.role,
        name: formatMessage({
          id: 'common.role',
        }),
        rowCell: {
          component: SelectCell,
          metadata: {
            disabled: this.isChangeRoleDisabled,
            disabledTooltipTextId:
              'web.settings.adminSettings.teamManagement.teamMembers.adminStatusTooltip',
            onChange: this.onChangeRole,
            selectItems: [
              {
                label: formatMessage({
                  id: 'web.settings.adminSettings.teamManagement.teamAdmin',
                }),
                value: TeamMembersRoles.admin,
              },
              {
                label: formatMessage({
                  id: 'common.user',
                }),
                value: TeamMembersRoles.user,
              },
            ],
            selectedValue: this.getRole,
          },
        },
        tooltip:
          'web.settings.adminSettings.teamManagement.teamMembers.roles.description',
        width: 200,
      },
      {
        align: Aligns.center,
        id: TeamMembersTableColumns.actions,
        name: formatMessage({
          id: 'common.actions',
        }),
        rowCell: {
          component: MenuCell,
          metadata: {
            menuItems: [
              {
                action: openEditTeamsPopup,
                disabled: this.isEditTeamsDisabled,
                label: formatMessage({
                  id:
                    'web.settings.adminSettings.teamManagement.teamMembers.editTeams',
                }),
              },
              {
                action: openDeleteTeamMemberConfirmationPopup,
                disabled: this.isDeleteUserDisabled,
                label: formatMessage({
                  id:
                    'web.settings.adminSettings.teamManagement.teamMembers.deleteTeamMembers',
                }),
              },
            ],
          },
        },
        width: 100,
      },
    ];
  }

  isEditTeamsDisabled = (user) => {
    const { currentUser, isAdmin } = this.props;
    return user.id === currentUser.id && !isAdmin;
  };

  onChangeRole = (role, user) => {
    const {
      actionCreators: { grantAdminRole, grantUserRole },
    } = this.props;

    if (TeamMembersRoles.admin === role) {
      grantAdminRole(user.id);
    } else if (TeamMembersRoles.user === role) {
      grantUserRole(user.id);
    }
  };

  isChangeRoleDisabled = (user) => {
    const { currentUser, isAdmin } = this.props;
    return user.id === currentUser.id && !isAdmin;
  };

  getRole = (user) => {
    const { selectedTeamAdminIds } = this.props;
    return selectedTeamAdminIds.includes(user.id)
      ? TeamMembersRoles.admin
      : TeamMembersRoles.user;
  };

  isDeleteUserDisabled = (user) => {
    const { currentUser, isAdmin } = this.props;
    return user.id === currentUser.id && !isAdmin;
  };

  getPagination() {
    return { currentPage: CURRENT_PAGE, perPage: tablePerPageFromStorage() };
  }

  setTableExternals = (externals) => teamMembersTableExternals.set(externals);

  getSearch() {
    const {
      intl: { formatMessage },
    } = this.props;
    return {
      onClear: () => {},
      placeholderText: formatMessage({
        id: 'common.search',
      }),
      searchers: [
        {
          label: formatMessage({ id: 'common.name' }),
          searching: (item) => item.name,
        },
        {
          label: formatMessage({ id: 'common.email' }),
          searching: (item) => item.email,
        },
      ],
      showClear: false,
    };
  }

  _getZeroState = () => {
    const {
      intl: { formatMessage },
    } = this.props;

    return {
      bodyText: formatMessage({
        id:
          'web.settings.adminSettings.teamManagement.teamMembers.zeroStateBody',
      }),
      imageUrl: getImageUrl('rocket-blueprint', 'svg'),
      titleText: formatMessage({
        id:
          'web.settings.adminSettings.teamManagement.teamMembers.zeroStateTitle',
      }),
    };
  };
}

TeamMembersTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedTeamAdminIds: PropTypes.array.isRequired,
  teamMembers: PropTypes.array.isRequired,
};

TeamMembersTable.defaultProps = {
  columnSettings: [],
};

export default injectIntl(TeamMembersTable);
