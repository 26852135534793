import { deleteContactIntegration as deleteContactIntegrationCall } from 'web/contactIntegrations/services/contactIntegrationsService';
import { boxName } from '../libs/boxConstants';
import BoxActionTypes from '../libs/boxActionTypes';
import { getContactIntegrations } from 'web/contactIntegrations/actionCreators/contactIntegrationsActionCreators';

export const setIntegrationFetching = (fetching = true) => ({
  type: BoxActionTypes.setIntegrationFetching,
  fetching,
});

export const setIntegrationFetched = (fetched = true) => ({
  type: BoxActionTypes.setIntegrationFetched,
  fetched,
});

export const deleteBoxIntegration = () => (dispatch) => {
  dispatch(setIntegrationFetching());
  return deleteContactIntegrationCall(boxName)
    .then(() => dispatch(getContactIntegrations()))
    .finally(() => {
      dispatch(setIntegrationFetching(false));
    });
};
