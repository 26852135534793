import dialerActionTypes from '../libs/dialerActionTypes';

export const callOutcomeOptions = (
  state = [],
  { type, callOutcomeOptions }
) => {
  switch (type) {
    case dialerActionTypes.getCallOutcomeOptionsSuccess:
      return callOutcomeOptions;
    default:
      return state;
  }
};

export const callOutcomeValidTypes = (
  state = [],
  { type, callOutcomeValidTypes }
) => {
  switch (type) {
    case dialerActionTypes.getCallOutcomeOptionsSuccess:
      return callOutcomeValidTypes;
    default:
      return state;
  }
};

export const callOutcomeOptionsLoading = (
  state = false,
  { type, isLoading }
) => {
  switch (type) {
    case dialerActionTypes.callOutcomeOptionsLoading:
      return isLoading;
    default:
      return state;
  }
};
