import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import I18N from 'languages';
import moment from 'moment';
import { Aligns } from 'libs/constantsShared';
import ToutTable from 'table/modules/toutTable';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import TextCell from 'table/modules/cells/textCell';
import MenuCell from 'table/modules/cells/menuCell';
import { alphabetical } from 'table/modules/sorting';
import { getImageUrl } from 'web/libs/constants';
import { TeamMembersTableColumns } from '../../libs/teamMembersConstants';
import { allMembersTableExternals } from '../../helpers/teamMembersHelpers';
import { tablePerPageFromStorage } from 'web/services/storageService';
import './allMembersTable.scss';
import { COLUMN_SETTINGS_ALL_MEMBERS_TABLE_ID } from 'web/settings/adminSettings/teamManagement/libs/teamMembersConstants';

const CURRENT_PAGE = 1;
const ROW_HEIGHT = 70;
const TABLE_ID = 'all-members-table';

class AllMembersTable extends Component {
  constructor(props) {
    super(props);

    this.pagination = this.getPagination();
    this.search = this.getSearch();
    this.zeroState = this._getZeroState();
  }

  render() {
    const {
      actionCreators,
      columnSettings,
      fetching,
      loading,
      teamMembers,
    } = this.props;

    const bulkActions = this.getBulkActions();
    const columns = this.getColumns();

    return (
      <div className="team-members-table">
        <ToutTable
          animateRow={false}
          bulkActions={bulkActions}
          changeColumnsSettingsHandler={(newColumnsSettings) => {
            actionCreators.changeColumnsSettings(
              COLUMN_SETTINGS_ALL_MEMBERS_TABLE_ID,
              newColumnsSettings
            );
          }}
          columnSettings={columnSettings}
          columns={columns}
          defaultSortingColumn={TeamMembersTableColumns.name}
          fetching={fetching}
          items={teamMembers}
          loading={loading}
          loadingGetUrl={getImageUrl}
          pagination={this.pagination}
          row={{ height: ROW_HEIGHT }}
          search={this.search}
          setTableExternals={this.setTableExternals}
          tableId={TABLE_ID}
          zeroState={this.zeroState}
          allItems
          columnFilter
          isResize
          reorderColumnsEnable
          selectable
        />
      </div>
    );
  }

  componentWillUnmount() {
    allMembersTableExternals.unset();
  }

  getBulkActions() {
    const {
      actionCreators: { openAddTeamMembersToTeamsPopup },
      intl: { formatMessage },
      teamsSelectOptions,
    } = this.props;

    return {
      actions: [
        {
          isHidden: !teamsSelectOptions.length,
          label: formatMessage({
            id:
              'web.settings.adminSettings.teamManagement.teamMembers.addToTeams',
          }),
          onClick: openAddTeamMembersToTeamsPopup,
        },
      ],
    };
  }

  getColumns() {
    const {
      actionCreators: { openEditTeamsPopup },
      intl: { formatMessage },
    } = this.props;

    return [
      {
        align: Aligns.left,
        id: TeamMembersTableColumns.name,
        name: formatMessage({ id: 'common.name' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'name',
          },
        },
        sorting: alphabetical('name'),
        width: 200,
      },
      {
        align: Aligns.left,
        id: TeamMembersTableColumns.email,
        name: formatMessage({ id: 'common.email' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'email',
          },
        },
        sorting: alphabetical('email'),
        width: 250,
      },
      {
        align: Aligns.center,
        id: TeamMembersTableColumns.createdAt,
        name: formatMessage({
          id: 'web.settings.adminSettings.teamManagement.createdAt',
        }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) =>
              moment(data.created_at).format(I18N.DateFormats.DATE_SHORT),
          },
        },
        sorting: alphabetical('created_at'),
        width: 200,
      },
      {
        align: Aligns.center,
        id: TeamMembersTableColumns.actions,
        name: formatMessage({
          id: 'common.actions',
        }),
        rowCell: {
          component: MenuCell,
          metadata: {
            menuItems: [
              {
                action: openEditTeamsPopup,
                disabled: this.isEditTeamsDisabled,
                label: formatMessage({
                  id:
                    'web.settings.adminSettings.teamManagement.teamMembers.editTeams',
                }),
              },
            ],
          },
        },
        width: 100,
      },
    ];
  }

  isEditTeamsDisabled = (user) => {
    const { currentUser, isAdmin, teamsSelectOptions } = this.props;
    return (
      !teamsSelectOptions.length || (user.id === currentUser.id && !isAdmin)
    );
  };

  getPagination() {
    return { currentPage: CURRENT_PAGE, perPage: tablePerPageFromStorage() };
  }

  setTableExternals = (externals) => allMembersTableExternals.set(externals);

  getSearch() {
    const {
      intl: { formatMessage },
    } = this.props;
    return {
      onClear: () => {},
      placeholderText: formatMessage({
        id: 'common.search',
      }),
      searchers: [
        {
          label: formatMessage({ id: 'common.name' }),
          searching: (item) => item.name,
        },
        {
          label: formatMessage({ id: 'common.email' }),
          searching: (item) => item.email,
        },
      ],
      showClear: false,
    };
  }

  _getZeroState = () => {
    const {
      intl: { formatMessage },
    } = this.props;

    return {
      bodyText: formatMessage({
        id:
          'web.settings.adminSettings.teamManagement.teamMembers.zeroStateBody',
      }),
      imageUrl: getImageUrl('rocket-blueprint', 'svg'),
      titleText: formatMessage({
        id:
          'web.settings.adminSettings.teamManagement.teamMembers.zeroStateTitle',
      }),
    };
  };
}

AllMembersTable.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  teamMembers: PropTypes.array.isRequired,
  teamsSelectOptions: PropTypes.array.isRequired,
};

AllMembersTable.defaultProps = {
  columnSettings: [],
};

export default injectIntl(AllMembersTable);
