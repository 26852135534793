import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { PersonDetailsTabs } from 'web/person/libs/personDetailsConstants';
import Page from 'components/layouts/page';
import TabNavBar from 'components/layouts/page/tabNavBar';
import TabNav from 'components/layouts/page/tabNav';
import View from 'components/layouts/page/view';
import ActionBar from './actionBar';
import Header from './header';
import LoadingSpinner from 'components/loadingSpinner';
import TabAlert from './tabAlert';
import AboutView from './aboutView';
import EngagementView from './engagementView';
import HistoryView from './historyView';
import TasksView from './tasksView';
import './personDetails.scss';

const TAB_WIDTH = 100;
const TAB_WIDTH_ENG = 140;
const TAB_WIDTH_LSN = 135;

class PersonDetails extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = null;
  }

  state = {
    tabs: [],
  };

  // eslint-disable-next-line
  componentWillMount() {
    const {
      intl: { formatMessage },
      tasksUncompletedCount,
    } = this.props;
    this.aboutTab = {
      title: formatMessage({ id: 'common.about' }),
      value: PersonDetailsTabs.about,
      width: TAB_WIDTH_LSN,
    };
    this.engagementTab = {
      title: formatMessage({ id: 'common.engagement' }),
      value: PersonDetailsTabs.engagement,
      width: TAB_WIDTH_ENG,
    };
    this.historyTab = {
      title: formatMessage({ id: 'common.history' }),
      value: PersonDetailsTabs.history,
    };
    this.tasksTab = {
      alertCount: tasksUncompletedCount,
      title: formatMessage({ id: 'common.tasks' }),
      value: PersonDetailsTabs.tasks,
    };

    this._setTabs();
  }

  // eslint-disable-next-line
  componentWillReceiveProps({ tasksUncompletedCount: nextCount }) {
    if (this.props.tasksUncompletedCount !== nextCount) {
      this._setTabs(nextCount);
    }
  }

  render() {
    const {
      actionCreators,
      exportData,
      exporting,
      groups,
      person,
      oldestAdminId,
      tab,
      tabAlert,
      tabLoading,
      unsubscribedFrom,
      user,
      userSubscriptionUsers,
    } = this.props;
    const { tabs } = this.state;

    return (
      <Page className="person-details">
        <ActionBar
          actionCreators={actionCreators}
          exportData={exportData}
          exporting={exporting}
          groups={groups}
          person={person}
          tabLoading={tabLoading}
          user={user}
        />
        <Header person={person} unsubscribedFrom={unsubscribedFrom} />
        <TabNavBar border>
          <TabNav
            itemWidth={TAB_WIDTH}
            list={tabs}
            onTabSelect={actionCreators.onTabSelect}
            selectedTabValue={tab}
          />
        </TabNavBar>
        <View className="person-details-page-view" ref={this._setScrollRef}>
          {tabLoading && <LoadingSpinner imageUrl={getImageUrl} />}
          <TabAlert
            actionCreators={actionCreators}
            adminData={userSubscriptionUsers[oldestAdminId]}
            alert={tabAlert}
          />
          {this._getView()}
        </View>
      </Page>
    );
  }

  _getView = () => {
    const {
      actionCreators,
      accountInfo,
      accountInfoLoading,
      accountInfoEditState,
      callRecordingEnabled,
      complianceCardCanSave,
      complianceEditState,
      customFieldsEditState,
      customFieldsCardHasChanges,
      dispatch,
      editing,
      emailsState,
      groupsAddable,
      groupsCardHasChanges,
      groupsEditState,
      infoCardHasChanges,
      isMsiActions,
      marketoEventsState,
      salesforceRequiredFields,
      infoEditState,
      person,
      tab,
      salesforceConnectionData,
      toutEventsState,
      unsubscribeHistory,
      unsubscribeOptInReasons,
      unsubscribeReasons,
      user,
      userSubscriptionUsers,
    } = this.props;

    switch (tab) {
      case PersonDetailsTabs.about:
        return (
          <AboutView
            accountInfo={accountInfo}
            accountInfoEditState={accountInfoEditState}
            accountInfoLoading={accountInfoLoading}
            actionCreators={actionCreators}
            complianceCardCanSave={complianceCardCanSave}
            complianceEditState={complianceEditState}
            customFieldsCardHasChanges={customFieldsCardHasChanges}
            customFieldsEditState={customFieldsEditState}
            editing={editing}
            groupsAddable={groupsAddable}
            groupsCardHasChanges={groupsCardHasChanges}
            groupsEditState={groupsEditState}
            infoCardHasChanges={infoCardHasChanges}
            infoEditState={infoEditState}
            isMsiActions={isMsiActions}
            person={person}
            salesforceInstanceUrl={salesforceConnectionData.instanceUrl}
            salesforceRequiredFields={salesforceRequiredFields}
            setScrollTop={this.setScrollTop}
            unsubscribeHistory={unsubscribeHistory}
            unsubscribeOptInReasons={unsubscribeOptInReasons}
            unsubscribeReasons={unsubscribeReasons}
            user={user}
            userSubscriptionUsers={userSubscriptionUsers}
          />
        );
      case PersonDetailsTabs.engagement:
        return (
          <EngagementView
            actionCreators={actionCreators}
            marketoEventsState={marketoEventsState}
            toutEventsState={toutEventsState}
            user={user}
          />
        );
      case PersonDetailsTabs.history:
        return (
          <HistoryView
            actionCreators={actionCreators}
            callRecordingEnabled={callRecordingEnabled}
            dispatch={dispatch}
            emailsState={emailsState}
            person={person}
            setScrollTop={this.setScrollTop}
            user={user}
          />
        );
      case PersonDetailsTabs.tasks: {
        return (
          <TasksView
            actionCreators={actionCreators}
            tasks={person.tasks}
            user={user}
          />
        );
      }
      default:
        return null;
    }
  };

  _setScrollRef = (node) => {
    this.scrollRef = node;
  };

  setScrollTop = (pixels) => {
    const ref = ReactDOM.findDOMNode(this.scrollRef); // eslint-disable-line react/no-find-dom-node
    if (ref) {
      ref.scrollTop += pixels;
    }
  };

  _setTabs = (tasksCount) => {
    const tabs = [
      this.aboutTab,
      this.engagementTab,
      this.historyTab,
      { ...this.tasksTab, alertCount: tasksCount },
    ];

    this.setState({ tabs });
  };
}

PersonDetails.propTypes = {
  accountInfo: PropTypes.array,
  accountInfoEditState: PropTypes.object.isRequired,
  accountInfoLoading: PropTypes.bool.isRequired,
  actionCreators: PropTypes.object.isRequired,
  callRecordingEnabled: PropTypes.bool.isRequired,
  complianceCardCanSave: PropTypes.bool.isRequired,
  complianceEditState: PropTypes.object.isRequired,
  customFieldsCardHasChanges: PropTypes.bool.isRequired,
  customFieldsEditState: PropTypes.array.isRequired,
  editing: PropTypes.object.isRequired,
  emailsState: PropTypes.object.isRequired,
  exportData: PropTypes.object.isRequired,
  exporting: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  groupsAddable: PropTypes.array.isRequired,
  groupsCardHasChanges: PropTypes.bool.isRequired,
  groupsEditState: PropTypes.object.isRequired,
  infoCardHasChanges: PropTypes.bool.isRequired,
  infoEditState: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isMsiActions: PropTypes.bool.isRequired,
  marketoEventsState: PropTypes.object.isRequired,
  oldestAdminId: PropTypes.number.isRequired,
  person: PropTypes.object.isRequired,
  salesforceConnectionData: PropTypes.object.isRequired,
  salesforceRequiredFields: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  tabAlert: PropTypes.object.isRequired,
  tabLoading: PropTypes.bool.isRequired,
  tasksUncompletedCount: PropTypes.number.isRequired,
  toutEventsState: PropTypes.object.isRequired,
  unsubscribeHistory: PropTypes.array,
  unsubscribeOptInReasons: PropTypes.object,
  unsubscribeReasons: PropTypes.object,
  unsubscribedFrom: PropTypes.string,
  user: PropTypes.object.isRequired,
  userSubscriptionUsers: PropTypes.object,
};

PersonDetails.defaultProps = {
  accountInfo: [],
  unsubscribeHistory: [],
  unsubscribeOptInReasons: {},
  unsubscribeReasons: {},
  unsubscribedFrom: '',
  userSubscriptionUsers: {},
};

export default injectIntl(PersonDetails);
