import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import './onboardingStepTitle.scss';

const OnboardingStepTitle = ({ titleId }) => (
  <h1 className="onboarding-title">
    <FormattedMessage id={titleId} />
  </h1>
);

OnboardingStepTitle.propTypes = {
  titleId: PropTypes.string,
};

OnboardingStepTitle.defaultProps = {
  titleId: '',
};

export default OnboardingStepTitle;
