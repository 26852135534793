import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { injectIntl, intlShape } from 'react-intl';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import Search from 'components/inputs/search';
import './multiSearch.scss';
import classNames from 'classnames';
import MultiSearchResults from './multiSearchResults/multiSearchResults';
import { track } from 'web/services/mixpanelService';

import { setSidebarSearchQuery } from 'web/shared/actionCreators/sidebarActionCreators';

class MultiSearch extends Component {
  constructor(props) {
    super(props);

    this.state = { searchValue: '' };
    this.debouncedSearch = debounce(this.search.bind(this), 600);
    this.inputRef = null;
    this.searchResultsRef = null;
  }

  componentDidMount() {
    const { onSearch, onSearchResultClick } = this.props;
    if (onSearchResultClick) {
      document.addEventListener('click', this._handleClick, false);
    }
    onSearch();
  }

  componentWillUnmount() {
    const { onSearchResultClick } = this.props;
    if (onSearchResultClick) {
      document.removeEventListener('click', this._handleClick, false);
    }
  }

  render() {
    const {
      intl: { formatMessage },
      searchResults,
      onSearchResultClick,
      isCustomClickHandler,
    } = this.props;
    const { searchValue } = this.state;
    const searchClasses = classNames('multi-search', {
      focus: searchResults.length,
    });

    return (
      <div>
        {onSearchResultClick ? (
          <div className="multi-search-container">
            <Search
              autoFocus={false}
              classIcon="search-icon-color"
              classInput="multi-search-input"
              className={searchClasses}
              clearSearch={this.clearSearch}
              onChange={this.onChangeSearch}
              placeholder={formatMessage({ id: 'common.search' })}
              ref={this.setInputRef}
              value={searchValue}
            />
            <MultiSearchResults
              searchResults={searchResults}
              onSearchResultClick={onSearchResultClick}
              isCustomClickHandler={isCustomClickHandler}
              ref={this.setSearchResultsRef}
            />
          </div>
        ) : null}
      </div>
    );
  }

  setInputRef = (div) => {
    this.inputRef = div;
  };

  setSearchResultsRef = (div) => {
    this.searchResultsRef = div;
  };

  search = () => this.props.onSearch(this.state.searchValue);

  onChangeSearch = (searchValue) =>
    this.setState({ searchValue }, this.debouncedSearch);

  _handleClick = (e) => {
    const { onSearch, mixpanelEvent, searchResults } = this.props;
    // eslint-disable-next-line
    if (ReactDOM.findDOMNode(this.inputRef).contains(e.target)) {
      track(mixpanelEvent);
      this.debouncedSearch(e);
    } else if (searchResults.length > 0) {
      track(mixpanelEvent);
      onSearch();
    }
  };
}

MultiSearch.propTypes = {
  intl: intlShape.isRequired,
  onSearch: PropTypes.func.isRequired,
  mixpanelEvent: PropTypes.string,
  searchResults: PropTypes.array,
  onSearchResultClick: PropTypes.func,
  isCustomClickHandler: PropTypes.bool,
};

MultiSearch.defaultProps = {
  mixpanelEvent: null,
  searchResults: [],
  onSearchResultClick: null,
  isCustomClickHandler: false,
};

MultiSearch.defaultProps = {
  searchResults: [],
};

const mapDispatchToProps = (dispatch, { onSearch = noop }) => ({
  onSearch(query) {
    dispatch(setSidebarSearchQuery(query));
    onSearch(query);
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  injectIntl
)(MultiSearch);
