import SettingsUnsubscribesActionTypes from '../libs/settingsUnsubscribesActionTypes';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import { getUnsubscribeSettings } from 'web/settings/adminSettings/unsubscribes/actionCreators/adminUnsubscribesIntegrationsActionCreators';

export const updateUnsubscribeSettings = (settings) => ({
  type: SettingsUnsubscribesActionTypes.updateSettings,
  settings,
});

export const settingsUnsubscribesStartup = (data) => (dispatch) => {
  dispatch(commonStartup());
  dispatch(updateUnsubscribeSettings(data));
  dispatch(getUnsubscribeSettings());
};
