import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import merge from 'lodash/merge';
import viewRoot from 'web/view/reducers/viewRootReducer';
import * as contentPartnerReducer from 'web/contentPartners/reducers/contentPartnerReducer';
import templatesRoot from 'web/templates/reducers/templatesRootReducer';
import popupReducer from 'web/popup/reducers/popupRootReducer';
import * as nonHoverTooltipReducer from 'web/nonHoverTooltip/reducers/nonHoverTooltipReducer';
import bulkUpdateRoot from 'web/bulkUpdate/reducers/bulkUpdateRootReducer';
import commandCenterRoot from 'web/commandCenter/reducers/commandCenterRootReducer';
import settingsRoot, {
  settingsRootReducer,
} from 'web/settings/reducers/settingsRootReducer';
import diagnosticsRoot from 'web/diagnostics/reducers/diagnosticsRootReducer';
import slideOutsRoot from 'web/slideOuts/reducers/slideOutsRootReducer';
import tablesTasksRoot from 'web/tables/tasks/reducers/tablesTasksRootReducer';
import smartFiltersRootReducer from 'web/tables/smartFilters/reducers/smartFiltersRootReducer';
import * as dateRangeSmartFiltersReducers from 'web/tables/dateRangeSmartFilter/reducers/dateRangeSmartFilterReducers';
import advancedSearchRootReducer from 'web/commandCenter/modules/emailGridAdvancedSearch/reducers/advancedSearchRootReducer';
import taskRoot from 'web/task/reducers/taskRootReducer';
import manageRecordingNoticeRootReducer from 'web/manageRecordingNotice/reducers/manageRecordingNoticeRootReducer';
import tasksRoot from 'web/tasks/reducers/tasksRootReducer';
import dynamicFieldsRoot from 'web/dynamicFields/reducers/dynamicFieldsRootReducer';
import groupsRoot from 'web/groups/reducers/groupsRootReducer';
import marketoSettingsReducer from 'web/marketoSettings/reducers/marketoSettingsRootReducer';
import userRoot from 'web/user/reducers/userRootReducer';
import contentRoot from 'web/content/reducers/contentRootReducer';
import contactIntegrationsReducers from 'web/contactIntegrations/reducers/contactIntegrationsReducers';
import chooseMarketoWorkspacesRoot from 'web/modals/chooseMarketoWorkspaces/reducers/chooseMarketoWorkspacesRootReducer';
import peopleRoot from 'web/people/reducers/peopleRootReducer';
import personReducer from 'web/person/reducers/personRootReducer';
import productTourRootReducer from 'web/productTour/reducers/productTourRootReducer.js';
import liveFeedRoot from 'web/liveFeed/reducers/liveFeedRootReducer';
import releaseNotesRoot from 'web/releaseNotes/reducers/releaseNotesRootReducer';
import marketoRoot from 'web/marketo/marketoRootReducer';
import timezonesRoot from 'web/timezones/reducers/timezonesRootReducer';
import unsubscribeFormRoot from 'web/unsubscribe/unsubscribeForm/reducers/unsubscribeFormRootReducer';
import emailIntegrationRootReducer from 'web/emailIntegration/reducers/emailIntegrationRootReducer';
import emailSettingsRootReducer from 'web/settings/email/reducers/emailSettingsRootReducer';
import generalRootReducer from 'web/settings/adminSettings/general/reducers/generalRootReducer';
import teamManagementRootReducer from 'web/settings/adminSettings/teamManagement/reducers/teamManagementRootReducer';
import sidebarViewersRoot from 'web/sidebar/viewers/reducers/viewersRootReducer';
import deliveryChannelsRootReducer from 'web/deliveryChannels/reducers/deliveryChannelsRootReducer';
import optInReasonRoot from 'web/unsubscribe/optInReasons/reducers/optInReasonsRootReducer';
import unsubscribeOptionsRoot from 'web/unsubscribe/unsubscribeOptions/reducers/unsubscribeOptionsRootReducer';
import forgotPasswordRoot from 'web/forgotPassword/reducers/forgotPasswordRootReducer';
import setPasswordRoot from 'web/setPassword/reducers/setPasswordRootReducer';
import peopleSourceRootReducer from 'web/modals/peopleSource/reducers/peopleSourceRootReducer';
import teamSettingsRootReducer from 'web/teamSettings/reducers/teamSettingsRootReducer';
import createAccountRoot from 'web/createAccount/reducers/createAccountRootReducer';
import emailConnectionRoot from 'web/settings/emailConnection/reducers/emailConnectionRootReducer';
import modalsRoot from './modals/modalsRootReducer';
import commonRoot from './common/commonRootReducer';
import gongRoot from './gong/gongRootReducer';
import campaignsRoot from './campaigns/campaignsRootReducer';
import teamsRootReducer from 'web/teams/reducers/teamsRootReducer';
import teamMembersRootReducer from 'web/teamMembers/reducers/teamMembersRootReducer';
import invitationsRootReducer from 'web/invitations/reducers/invitationsRootReducer';
import composeWindowRootReducer from 'web/composeWindow/reducers/composeWindowRootReducers';
import composeTemplatesRootReducer from 'web/composeWindow/reducers/composeTemplatesRootReducer';
import salesforceRootReducers from 'web/salesforce/reducers/salesforceRootReducer';
import trackingRootReducer from 'web/tracking/reducers/trackingRootReducer';
import integrarionsRootReducer from 'web/integrations/reducers/integrationsRootReducer';
import globalBannerAlertsReducer from 'web/globalBannerAlerts/reducers/globalBannerAlertsRootReducer';
import onboardingRootReducer from 'web/onboarding/reducers/onboardingRootReducer';
import dialerRootReducer from 'web/settings/adminSettings/dialer/reducers/dialerRootReducer';
import loginRootReducer from 'web/login/reducers/loginRootReducer';
import * as sidebar from 'web/reducers/sidebar/sidebarReducer';

const root = combineReducers(
  merge(
    {
      entities: combineReducers({
        contactIntegrations: contactIntegrationsReducers,
        integrations: integrarionsRootReducer,
        invitations: invitationsRootReducer,
        salesforce: salesforceRootReducers,
        teamMembers: teamMembersRootReducer,
        teams: teamsRootReducer,
        tracking: trackingRootReducer,
      }),
      ui: combineReducers({
        settings: settingsRootReducer,
      }),
    },
    bulkUpdateRoot,
    campaignsRoot,
    chooseMarketoWorkspacesRoot,
    commandCenterRoot,
    commonRoot,
    composeTemplatesRootReducer,
    composeWindowRootReducer,
    contentPartnerReducer,
    contentRoot,
    diagnosticsRoot,
    dynamicFieldsRoot,
    emailConnectionRoot,
    emailIntegrationRootReducer,
    emailSettingsRootReducer,
    generalRootReducer,
    teamManagementRootReducer,
    gongRoot,
    groupsRoot,
    liveFeedRoot,
    manageRecordingNoticeRootReducer,
    marketoRoot,
    marketoSettingsReducer,
    modalsRoot,
    nonHoverTooltipReducer,
    optInReasonRoot,
    peopleRoot,
    personReducer,
    productTourRootReducer,
    peopleSourceRootReducer,
    popupReducer,
    releaseNotesRoot,
    settingsRoot,
    sidebarViewersRoot,
    slideOutsRoot,
    tablesTasksRoot,
    smartFiltersRootReducer,
    dateRangeSmartFiltersReducers,
    advancedSearchRootReducer,
    teamSettingsRootReducer,
    taskRoot,
    tasksRoot,
    templatesRoot,
    timezonesRoot,
    unsubscribeFormRoot,
    unsubscribeOptionsRoot,
    userRoot,
    viewRoot,
    forgotPasswordRoot,
    { routing: routerReducer },
    deliveryChannelsRootReducer,
    {
      form: formReducer,
    },
    setPasswordRoot,
    createAccountRoot,
    globalBannerAlertsReducer,
    onboardingRootReducer,
    dialerRootReducer,
    loginRootReducer,
    sidebar
  )
);

export default root;
