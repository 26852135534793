import {
  disabledPrefix,
  SENT_ACTIVITIES,
} from 'web/commandCenter/modules/statusSmartFilter/libs/statusSmartFilterConstants';
import { CommandCenterEmailsTabsEnum } from 'web/commandCenter/libs/commandCenterConstants';
import I18N from 'languages';

export const convertSubTabsToArray = (subTabs) =>
  Object.values(subTabs).map(({ label, value }) => ({
    label,
    value,
  }));

export const getSubActivities = () =>
  Object.values(SENT_ACTIVITIES).map((sentActivity) =>
    Object.values(sentActivity).map(({ label, value }) => ({
      id: value,
      name: I18N.getStr(label),
    }))
  );

export const getFilterValues = (subTabIds, tabId) => {
  const isSentTab = tabId === CommandCenterEmailsTabsEnum.sent.value;
  if (isSentTab) {
    return getSentFilterValues(subTabIds);
  }
  return subTabIds.map((subTabId) => ({
    value: subTabId,
    valueLabel: I18N.getStr(
      CommandCenterEmailsTabsEnum[tabId].subTabs[subTabId].label
    ),
  }));
};

const getSentFilterValues = (subTabIds) => {
  if (subTabIds.length === 0) {
    return [];
  }
  const sentActivities = Object.values(SENT_ACTIVITIES);
  return subTabIds.map((subTabId) => {
    const { label, value } = sentActivities.find((sentActivity) =>
      isActivityValueSelected(Object.values(sentActivity), subTabId)
    )[subTabId];
    return {
      value,
      valueLabel: I18N.getStr(label),
    };
  });
};

const isActivityValueSelected = (sentActivityValues, subTabId) =>
  !!sentActivityValues.find(
    ({ value: activityValue }) => activityValue === subTabId
  );

export const getSubTabIdIndex = (subTabId, subTabIds) =>
  subTabIds.findIndex(
    (id) =>
      id.toLowerCase() === `${disabledPrefix}${subTabId}` ||
      `${disabledPrefix}${id}` === subTabId.toLowerCase() ||
      id === subTabId
  );

export const getSelectedIdByActivity = (subTabId, subTabIds) =>
  subTabIds[getSubTabIdIndex(subTabId, subTabIds)] || subTabId;

export const getSentFilterInitialActivitiesValues = (
  statusFilterSubTabIds = []
) => {
  const defaultViewsValue = getSelectedIdByActivity(
    SENT_ACTIVITIES.views.viewed.value,
    statusFilterSubTabIds
  );
  const defaultClicksValue = getSelectedIdByActivity(
    SENT_ACTIVITIES.clicks.clicked.value,
    statusFilterSubTabIds
  );
  const defaultRepliesValue = getSelectedIdByActivity(
    SENT_ACTIVITIES.replies.replied.value,
    statusFilterSubTabIds
  );
  return [defaultViewsValue, defaultClicksValue, defaultRepliesValue];
};
