import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Button, { ButtonColors } from 'components/buttons/action';
import Label from 'components/label';
import Select from './inputs/select';
import { getDeliveryChannelLabel } from 'web/deliveryChannels/helpers/identitiesHelper';
import {
  DELIVERY_CHANNEL_FORM_ID,
  LABEL_WIDTH,
} from '../../../libs/editIdentitySlideOutConstants';

class Editing extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
    } = props;

    this.noneSelectedPlaceholder = formatMessage({ id: 'common.noneSelected' });
  }

  render() {
    const { handleSubmit, onCancel, canChangeDeliveryChannel } = this.props;

    return (
      <Form
        className="identity-details identity-form"
        onSubmit={handleSubmit}
        autoComplete="false"
      >
        <div className="identity-section">
          <h4 className="identity-section-title">
            <FormattedMessage id="web.identities.form.section.channel.title" />
          </h4>
          <p className="identity-section-description">
            <FormattedHTMLMessage id="web.identities.form.section.channel.description" />
          </p>
          <div className="identity-field-item">
            <Label
              id="identity-delivery-channel"
              className="identity-field-label"
              classWrapper="identity-field"
              classChildren="identity-field-label-child"
              messageKey="common.channel"
              sideLabel
              width={LABEL_WIDTH}
            >
              <Field
                name="authentication_id"
                component={Select}
                options={this.getDeliveryChannelsOptions()}
                format={this.authentificationIdFieldFormatFn}
                parse={this.authentificationIdFieldParseFn}
                multi={false}
                clearable={false}
                disabled={canChangeDeliveryChannel}
                showAbove
                placeholder={this.noneSelectedPlaceholder}
                noResultsText=""
              />
            </Label>
          </div>
        </div>
        <br className="clear" />
        <div className="margin-top-half actions">
          <Button color={ButtonColors.gray} onClick={onCancel}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button color={ButtonColors.blue} type="submit">
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </Form>
    );
  }

  authentificationIdFieldFormatFn = (value) => value || 0;

  authentificationIdFieldParseFn = (value) => value || null;

  getDeliveryChannelsOptions = () => {
    const {
      intl: { formatMessage },
      composedDeliveryChannels,
    } = this.props;

    return composedDeliveryChannels.map((deliveryChannel) => ({
      value: deliveryChannel ? deliveryChannel.id : 0,
      label: getDeliveryChannelLabel({ deliveryChannel, formatMessage }),
    }));
  };
}

Editing.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  composedDeliveryChannels: PropTypes.array.isRequired,
  canChangeDeliveryChannel: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default reduxForm({ form: DELIVERY_CHANNEL_FORM_ID })(
  injectIntl(Editing)
);
