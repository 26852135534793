import TeamMembersActionTypes from '../libs/teamMembersActionTypes';
import TeamsActionTypes from 'web/teams/libs/teamsActionTypes';
import castArray from 'lodash/castArray';
import assign from 'lodash/assign';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';
import unset from 'lodash/unset';
import union from 'lodash/union';
import map from 'lodash/map';
import reject from 'lodash/reject';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';

export function byId(state = {}, action) {
  switch (action.type) {
    case TeamMembersActionTypes.setTeamMembers: {
      const diff = keyBy(action.teamMembers, 'id');
      return assign({}, state, diff);
    }
    case TeamMembersActionTypes.updateTeamMember: {
      const diff = keyBy(castArray(action.teamMember), 'id');
      return assign({}, state, diff);
    }
    case TeamMembersActionTypes.deleteTeamMember: {
      if (action.teamId) {
        return state;
      }
      const newState = { ...state };
      unset(newState, action.id);
      return newState;
    }
    case TeamMembersActionTypes.batchDestroyTeamMembers: {
      if (action.teamId) {
        return state;
      }
      const newState = { ...state };
      action.ids.forEach((id) => unset(newState, id));
      return newState;
    }
    case TeamMembersActionTypes.createTeamMembersInTeams: {
      const teams = values(action.teamsTeamMmebers);
      const teamMembers = uniqBy(concat(...teams), 'id');
      const diff = keyBy(teamMembers, 'id');
      return assign({}, state, diff);
    }
    default:
      return state;
  }
}

export function allIds(state = [], action) {
  switch (action.type) {
    case TeamMembersActionTypes.setTeamMembers: {
      const diff = map(action.teamMembers, 'id');
      return union(state, diff);
    }
    case TeamMembersActionTypes.updateTeamMember: {
      const diff = map(castArray(action.teamMember), 'id');
      return union(state, diff);
    }
    case TeamMembersActionTypes.deleteTeamMember: {
      if (action.teamId) {
        return state;
      }
      return difference(state, castArray(action.id));
    }
    case TeamMembersActionTypes.batchDestroyTeamMembers: {
      if (action.teamId) {
        return state;
      }
      return difference(state, action.ids);
    }
    case TeamMembersActionTypes.createTeamMembersInTeams: {
      const teams = values(action.teamsTeamMmebers);
      const teamMembers = uniqBy(concat(...teams), 'id');
      const diff = map(teamMembers, 'id');
      return union(state, diff);
    }
    default:
      return state;
  }
}

export function teamMemberToTeam(state = [], action) {
  switch (action.type) {
    case TeamMembersActionTypes.setTeamMembers: {
      let newState = { ...state };

      action.teamMembers.forEach((teamMember) => {
        newState = reject(newState, ['teamMemberId', teamMember.id]);
        const newTeamMemberIdTeamId = teamMember.teams.map((team) => ({
          teamMemberId: teamMember.id,
          teamId: team.id,
        }));
        newState = concat(newState, newTeamMemberIdTeamId);
      });

      return newState;
    }
    case TeamMembersActionTypes.updateTeamMember: {
      const newState = reject(state, ['teamMemberId', action.teamMember.id]);
      const newTeamMemberIdTeamId = action.teamMember.teams.map((team) => ({
        teamMemberId: action.teamMember.id,
        teamId: team.id,
      }));
      return concat(newState, newTeamMemberIdTeamId);
    }
    case TeamMembersActionTypes.deleteTeamMember: {
      if (action.teamId)
        return reject(state, {
          teamMemberId: action.id,
          teamId: action.teamId,
        });
      else {
        return reject(state, { teamMemberId: action.id });
      }
    }
    case TeamMembersActionTypes.batchDestroyTeamMembers: {
      return reject(state, ({ teamMemberId, teamId }) => {
        if (action.teamId) {
          if (action.teamId === teamId && action.ids.includes(teamMemberId))
            return true;
        } else if (action.ids.includes(teamMemberId)) {
          return true;
        }
        return false;
      });
    }
    case TeamMembersActionTypes.createTeamMembersInTeams: {
      const teams = values(action.teamsTeamMmebers);
      const teamMembers = uniqBy(concat(...teams), 'id');
      let newState = { ...state };

      teamMembers.forEach((teamMember) => {
        newState = reject(newState, ['teamMemberId', teamMember.id]);
        const newTeamMemberIdTeamId = teamMember.teams.map((team) => ({
          teamMemberId: teamMember.id,
          teamId: team.id,
        }));
        newState = concat(newState, newTeamMemberIdTeamId);
      });

      return newState;
    }
    case TeamsActionTypes.deleteTeam: {
      return reject(state, ['teamId', action.id]);
    }
    default:
      return state;
  }
}

export function fetched(state = {}, action) {
  switch (action.type) {
    case TeamMembersActionTypes.setTeamMembersFetched: {
      if (state[action.teamId] === action.fetched) {
        return state;
      }
      return { ...state, [action.teamId]: action.fetched };
    }
    case TeamsActionTypes.deleteTeam: {
      if (typeof state[action.id] === 'undefined') {
        return state;
      }
      const newState = { ...state };
      delete newState[action.id];
      return newState;
    }
    default:
      return state;
  }
}

export function fetching(state = {}, action) {
  switch (action.type) {
    case TeamMembersActionTypes.setTeamMembersFetching: {
      if (state[action.teamId] === action.fetching) {
        return state;
      }
      return { ...state, [action.teamId]: action.fetching };
    }
    case TeamsActionTypes.deleteTeam: {
      if (typeof state[action.id] === 'undefined') {
        return state;
      }
      const newState = { ...state };
      delete newState[action.id];
      return newState;
    }
    default:
      return state;
  }
}
