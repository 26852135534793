import { getSalesforceConnectLink as getSalesforceConnectLinkSelector } from '../selectors/salesforceSelectors';
import { openNewTab } from 'web/services/routerService';

export const getSalesforceConnectPageLink = () => (dispatch, getState) =>
  getSalesforceConnectLinkSelector(getState());

export const openSalesforceConnectPage = () => (dispatch) => {
  const link = dispatch(getSalesforceConnectPageLink());
  openNewTab(link);
};
