import { Urls } from 'web/libs/routes';
import { PopupSize } from 'web/liveFeed/libs/liveFeedConstants';
import { openNewTab, openNewTabWithSize } from 'web/services/routerService';
import I18N from 'languages';

export const openGong = () => (dispatch) => {
  const {
    gong: { width, height },
  } = PopupSize;
  dispatch(openNewTabWithSize(Urls.gong.show, width, height));
};

export const openHelp = () => (dispatch) => {
  dispatch(openNewTab(`${Urls.common.help}?lang=${I18N.getLocale()}`));
};
