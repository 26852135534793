import { baseGet, basePost, basePut, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getCampaignsCategories(params = {}) {
  return new Promise((resolve, reject) => {
    baseGet(Urls.campaignsCategories, params, resolve, reject);
  });
}

export function createCampaignsCategory(name) {
  return new Promise((resolve, reject) => {
    basePost(Urls.campaignsCategories, { category: { name } }, resolve, reject);
  });
}

export function updateCampaignsCategory(id, name) {
  return new Promise((resolve, reject) => {
    basePut(
      `${Urls.campaignsCategories}/${id}`,
      { category: { name } },
      resolve,
      reject
    );
  });
}

export function deleteCampaignCategory(id) {
  return new Promise((resolve, reject) => {
    baseDelete(`${Urls.campaignsCategories}/${id}`, {}, resolve, reject);
  });
}
