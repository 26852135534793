/* eslint-disable sort-keys */
import {
  ACTION_HEALTH_STATUSES_LOAD_FAILED,
  ACTION_HEALTH_STATUSES_LOADED,
  ACTION_INITIALIZE_HEALTH_STATUSES,
  SET_FETCHING,
} from '../libs/yourIntegrationsConstants';

const initialState = {
  fetching: false,
  statuses: {},
};

export function yourIntegrations(state = initialState, action) {
  switch (action.type) {
    case ACTION_INITIALIZE_HEALTH_STATUSES: {
      return {
        ...state,
      };
    }
    case SET_FETCHING:
      return {
        ...state,
        fetching: action.fetching,
      };
    case ACTION_HEALTH_STATUSES_LOADED: {
      const response = action.payload;
      return {
        ...state,
        statuses: {
          isExchangeOnpremIntegrationSetup:
            response.is_exchange_onprem_integration_setup,
          isMarketoSetUp: response.is_marketo_integration_setup,
          isMarketoConnectionBroken: response.is_marketo_integration_broken,
          isMarketoAccessGrantedToCurrentAccount:
            response.is_marketo_access_granted,
          isSalesforceSetUpForCurrentAccount:
            response.is_salesforce_integration_setup,
          isSalesforceBrokenForCurrentAccount:
            response.is_salesforce_integration_broken,
          isGmailIntegrationSetUp: response.is_gmail_integration_setup,
          isGmailIntegrationBroken: response.is_gmail_integration_broken,
          isOutlookIntegrationSetUp: response.is_outlook_integration_setup,
          isOutlookIntegrationBroken: response.is_outlook_integration_broken,
          isCustomDeliveryChannelSetUp:
            response.is_custom_delivery_channel_integration_setup,
          isCustomDeliveryChannelBroken:
            response.is_custom_delivery_channel_integration_broken,
          isTeamSmtpServerSetUp: response.is_team_smtp_server_integration_setup,
          isTeamSmtpServerBroken:
            response.is_team_smtp_server_integration_broken,
          isToutIntegrationSetup: response.is_tout_integration_setup,
        },
      };
    }
    case ACTION_HEALTH_STATUSES_LOAD_FAILED: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
}
