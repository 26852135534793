import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Popup from 'components/popups/popup';
import { PopupSize } from 'libs/constantsShared';
import { EmailComposePopupIds } from '../../libs/composeEmailPopupConstants';
import { getEmailFromResponse } from '../../helpers/composeEmailHelpers';

class ActionsRequiredPopup extends Component {
  state = {
    sendingVerification: false,
  };

  render() {
    const {
      actionCreators: { closePopup },
      options: {
        content: { responseText },
      },
    } = this.props;
    const { sendingVerification } = this.state;

    return (
      <Popup
        className="compose-email-popup"
        footer={{
          onPrimary: this.resendVerification,
          onSecondary: closePopup,
          primaryTextId: 'web.composeEmail.popup.actionsRequired.button',
          secondaryTextId: 'common.cancel',
          primaryDisabled: sendingVerification,
        }}
        header={{
          onClose: closePopup,
          textId: 'web.composeEmail.popup.actionsRequired.header',
        }}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <p>
          <FormattedMessage id="web.composeEmail.popup.actionsRequired.body.title" />
        </p>
        <FormattedHTMLMessage
          id="web.composeEmail.popup.actionsRequired.body.firstCondition"
          values={{ email: getEmailFromResponse(responseText) }}
        />
        <p>
          <FormattedMessage id="web.composeEmail.popup.actionsRequired.body.secondCondition" />
        </p>
      </Popup>
    );
  }

  resendVerification = () => {
    const {
      options: {
        content: { identityId },
      },
      actionCreators: { resendVerificationMail },
    } = this.props;

    this.setState({ sendingVerification: true });

    resendVerificationMail({
      identityId,
      target: EmailComposePopupIds.actionsRequired,
    });
  };
}

ActionsRequiredPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ActionsRequiredPopup);
