import { createReducer } from '@reduxjs/toolkit';
import GeneralActionTypes from '../libs/generalActionTypes';
import {
  EMAIL_THROTTLING_SOURCE_TYPES,
  GeneralTabs,
} from 'web/settings/adminSettings/general/libs/generalPageConstants';
import { setEmailThrottlingLimits } from 'web/settings/adminSettings/general/actionCreators/teamEmailLimitActionCreators';

export const activeTab = (
  state = GeneralTabs.globalSettings,
  { type, tab = GeneralTabs.globalSettings }
) => {
  switch (type) {
    case GeneralActionTypes.setTab:
      return tab;
    default:
      return state;
  }
};

export const emailThrottlingLimits = createReducer(
  {
    emailsAmount: 0,
    isEnabled: false,
    source: EMAIL_THROTTLING_SOURCE_TYPES.manual,
    subscriptionId: null,
    timeAmount: 0,
    timeUnit: 'min',
  },
  {
    [setEmailThrottlingLimits.type]: (state, action) => action.payload,
  }
);
