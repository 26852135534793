import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/buttons/action';
import SalesforceActions from 'web/liveFeed/components/marketoCard/salesforceActions';
import './marketoConvertActions.scss';

class MarketoConvertActions extends PureComponent {
  render() {
    const {
      isLoading,
      salesforceContactIsLead,
      bestBet,
      openLinkedInSearch,
      openSalesforceContact,
      salesforceInstanceUrl,
      salesforceId,
    } = this.props;

    const disableActions = bestBet.unsubscribed;
    return (
      <div className="marketo-convert-actions">
        <SalesforceActions
          bestBet={bestBet}
          isLoading={isLoading}
          openLinkedInSearch={openLinkedInSearch}
          openSalesforceContact={openSalesforceContact}
          salesforceId={salesforceId}
          salesforceContactIsLead={salesforceContactIsLead}
          salesforceInstanceUrl={salesforceInstanceUrl}
        />
        <div className="marketo-convert-actions-column marketo-convert-actions-column-buttons">
          <div className="marketo-convert-actions-buttons-container">
            <Button
              className="marketo-convert-actions-button margin-bottom-most"
              disabled={disableActions}
              onClick={this.openEmail}
            >
              <FormattedMessage id="web.liveFeed.engagement.email" />
            </Button>
            <Button
              className="marketo-convert-actions-button margin-bottom-most"
              disabled={disableActions}
              onClick={this.openCall}
            >
              <FormattedMessage id="web.liveFeed.engagement.call" />
            </Button>
            <Button
              className="marketo-convert-actions-button"
              disabled={disableActions}
              onClick={this.openAddToCampaign}
            >
              <FormattedMessage id="web.liveFeed.engagement.addToCampaign" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  openEmail = () => {
    const { bestBet, openEmail } = this.props;
    openEmail(bestBet);
  };

  openCall = () => {
    const {
      bestBet: { personId },
      openCall,
    } = this.props;
    openCall(personId);
  };

  openAddToCampaign = () => {
    const {
      bestBet: { personId },
      openAddToCampaign,
    } = this.props;
    openAddToCampaign(personId);
  };
}

MarketoConvertActions.propTypes = {
  bestBet: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  openAddToCampaign: PropTypes.func.isRequired,
  openCall: PropTypes.func.isRequired,
  openEmail: PropTypes.func.isRequired,
  openLinkedInSearch: PropTypes.func.isRequired,
  openSalesforceContact: PropTypes.func.isRequired,
  salesforceContactIsLead: PropTypes.bool,
  salesforceId: PropTypes.string,
  salesforceInstanceUrl: PropTypes.string.isRequired,
};

MarketoConvertActions.defaultProps = {
  isLoading: false,
  salesforceContactIsLead: false,
  salesforceId: '',
};

export default MarketoConvertActions;
