import { Urls } from 'web/libs/routes';
import { basePost, baseDelete, baseGet } from 'web/services/api';

export const saveCustomTrackingDomain = (domain = null) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.customTrackingDomain,
      { custom_tracking_domain: domain },
      resolve,
      reject
    );
  });

export const deleteCustomTrackingDomain = () =>
  new Promise((resolve, reject) => {
    baseDelete(Urls.customTrackingDomain, {}, resolve, reject);
  });

export const getRecentWebsiteTrackingEvents = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.recentWebsiteTrackingEvents, null, resolve, reject);
  });
