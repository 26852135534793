import UsersActionTypes from '../libs/usersActionTypes';

export const deleteUserLoading = (state = false, { type, loading }) => {
  switch (type) {
    case UsersActionTypes.setDeleteUserLoading:
      return loading;
    default:
      return state;
  }
};

export const deleteUsersLoading = (state = false, { type, loading }) => {
  switch (type) {
    case UsersActionTypes.setDeleteUsersLoading:
      return loading;
    default:
      return state;
  }
};

export const changeRoleLoading = (state = false, { type, loading }) => {
  switch (type) {
    case UsersActionTypes.setChangeRoleLoading:
      return loading;
    default:
      return state;
  }
};
