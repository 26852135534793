import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/buttons/icon';
import BounceIcon from 'components/icons/bounceIcon';
import './engagementMetric.scss';
import I18N from 'languages';

class EngagementMetric extends Component {
  render() {
    const { metric } = this.props;
    const viewedTitle = I18N.getStr('common.viewed');
    const clickedTitle = I18N.getStr('common.clicked');
    const repliedTitle = I18N.getStr('common.replied');
    const bouncedTitle = I18N.getStr('common.bounced');
    return (
      <div className="metric engagement-metric">
        <div className="metric-icon metric-view-icon" title={viewedTitle} />
        <div className="metric-value">
          {this.percent(metric.emailsViewed, metric.emailsSent)}
        </div>
        <div className="metric-icon metric-click-icon" title={clickedTitle} />
        <div className="metric-value">
          {this.percent(metric.emailsClicked, metric.emailsSent)}
        </div>
        <div className="metric-icon metric-reply-icon" title={repliedTitle} />
        <div className="metric-value">
          {this.percent(metric.emailsReplied, metric.emailsSent)}
        </div>
        <Icon className="metric-icon metric-bounce-icon" title={bouncedTitle} >
          <BounceIcon />
        </Icon>
        <div className="metric-value">
          {this.percent(metric.emailsBounced, metric.emailsSent)}
        </div>
      </div>
    );
  }

  percent = (num, denom) => `${((num / denom || 0) * 100).toFixed()}%`;
}

EngagementMetric.propTypes = {
  metric: PropTypes.object.isRequired,
};

export default EngagementMetric;
