import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import Action from 'components/buttons/action';
import TinyMceEditorField from 'web/tinyMceEditor/components/tinyMceEditorField';
import { AUTO_APPEND_FIELD_ID } from '../../libs/generalPageConstants';

class AutoAppendForm extends Component {
  render() {
    const { customAppendContent, pristine, submitting } = this.props;

    return (
      <Form className="margin-0" onSubmit={this.onSubmit()}>
        <div className="margin-top-20">
          <Field
            body={customAppendContent}
            component={TinyMceEditorField}
            id={AUTO_APPEND_FIELD_ID}
            name={AUTO_APPEND_FIELD_ID}
            options={{ disableAddContent: true, disableDynamicFields: true }}
          />
        </div>
        <div className="margin-top-full right">
          <Action disabled={pristine || submitting}>
            <FormattedMessage id="common.save" type="submit" />
          </Action>
        </div>
      </Form>
    );
  }

  onSubmitError = (e) => {
    const { onError } = this.props;
    onError();
    e.preventDefault();
  };

  onSubmit = () => {
    const { handleSubmit, valid } = this.props;
    return valid ? handleSubmit : this.onSubmitError;
  };
}

AutoAppendForm.propTypes = {
  customAppendContent: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

AutoAppendForm.defaultProps = {
  customAppendContent: null,
};

export default compose(
  reduxForm({ enableReinitialize: true }),
  injectIntl
)(AutoAppendForm);
