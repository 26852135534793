import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import outsideClickHandler from 'components/hocs/outsideClickHandler/outsideClickHandler';

import './AlertList.scss';

const CLASS_DROPDOWN = 'navbar dropdown notification-widget';

// temporary component, will be replaced in future sprint
export class AlertList extends React.Component {
  render() {
    const { isOpened } = this.props;
    return (
      <div className={classnames(CLASS_DROPDOWN, { open: isOpened })}>
        <ul className="dropdown-menu alert-item">
          <li className="no-alerts"><FormattedMessage id="web.topnavbar.alerts.noNewAlerts" /></li>
        </ul>
      </div>
    );
  }
}

AlertList.propTypes = {
  isOpened: PropTypes.bool,
};

AlertList.defaultProps = {
  isOpened: false,
};

export default injectIntl(outsideClickHandler(AlertList));
