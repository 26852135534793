import React from 'react';
import AccordionWrapper from 'web/salesforce/components/syncSettings/accordion';
import LogEmail from './accordionItems/logEmailActivity';
import SyncTasks from './accordionItems/syncTasks';
import { SalesforcePopupIds } from 'web/salesforce/libs/salesforceConstants';

const Accordion = (props) => (
  <AccordionWrapper
    {...props}
    items={[LogEmail, SyncTasks]}
    popupId={SalesforcePopupIds.unsavedSyncFormAdmin}
  />
);

export default Accordion;
