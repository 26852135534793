import ActionTypes from 'web/libs/actionTypes/actionTypes';

export function campaignsPeopleSearch(state = [], action) {
  switch (action.type) {
    case ActionTypes.campaignPeople.data.peopleSearch:
      return action.data;
    case ActionTypes.campaignPeople.data.updatePeopleSearch:
      return [
        ...state.slice(0, action.index),
        action.data,
        ...state.slice(action.index + 1),
      ];
    case ActionTypes.campaignPeople.state.resetSearch:
      return [];
    default:
      return state;
  }
}

export function campaignsPeopleSearchString(state = '', action) {
  switch (action.type) {
    case ActionTypes.campaignPeople.state.searchChange:
      return action.data;
    case ActionTypes.campaignPeople.state.resetSearch:
      return action.all ? '' : state;
    default:
      return state;
  }
}

export function campaignsPeopleIsSearching(state = false, action) {
  switch (action.type) {
    case ActionTypes.campaignPeople.state.setSearching:
      return true;
    case ActionTypes.campaignPeople.state.resetSearch:
      return false;
    default:
      return state;
  }
}

export const initPage = 1;
export function campaignsPeopleSearchPagination(state = initPage, action) {
  switch (action.type) {
    case ActionTypes.campaignPeople.state.resetSearch:
    case ActionTypes.campaignPeople.state.filter:
    case ActionTypes.campaignPeople.state.searchChange:
      return initPage;
    case ActionTypes.campaignPeople.state.paginationSearch:
      return action.data;
    default:
      return state;
  }
}
