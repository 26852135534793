/**
 *
 * Row
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Shapes from 'table/modules/shapes';
import { Align, SelectableWidth, WidthMode } from 'table/modules/constants';
import Cell from 'table/modules/cell';
import TableCheckbox from 'table/modules/tableCheckbox';
import TableRadio from 'table/modules/tableRadio';
import TextCell from 'table/modules/cells/textCell';
import Collapse, { Timeouts } from 'components/collapse';

import './row.scss';
import 'table/modules/styles/styles.scss';
import withDndSource from 'table/modules/withDnd/withDndSource';
import DragAndDropCell from 'table/modules/cells/dragAndDropCell';

class Row extends PureComponent {
  constructor() {
    super();
    this.state = { animationClasses: '' };
  }

  render() {
    const {
      animate: ignored1, // eslint-disable-line no-unused-vars
      columns,
      data,
      disableDrag: ignored9, // eslint-disable-line no-unused-vars
      getAnimation: ignored2, // eslint-disable-line no-unused-vars
      height,
      highlightOnClick: ignored3, // eslint-disable-line no-unused-vars
      isSelected,
      isResize,
      onClick,
      onCheckClick: ignored4, // eslint-disable-line no-unused-vars
      onHighlightClickRow: ignored5, // eslint-disable-line no-unused-vars
      onRadioClick: ignored6, // eslint-disable-line no-unused-vars
      radio: ignored7, // eslint-disable-line no-unused-vars
      selectable: ignored8, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;
    const { animationClasses } = this.state;
    const rowStyle = {
      height: `${height}px`,
    };
    const wrapperClass = classNames(
      'tout-row-animation',
      'flex---column--full',
      animationClasses
    );
    const rowClass = classNames('tout-row', {
      'tout-row-clickable': !!onClick,
      'tout-row-selected': isSelected,
    });

    const rowCellClassNames = (rowCell) => {
      let classes;
      const meta = rowCell.metadata;
      if (meta) {
        classes = [meta.classNames && meta.classNames(data), meta.className];
      }
      return classes;
    };

    const realTimeAnimate = this.props.getAnimation().animate;
    const selectableComponent = this.getSelectable();
    const dragAndDropComponent = this.getDragAndDrop();

    /* wrapper as rowClass would overwrite animation classes */
    return (
      <Collapse
        {...other}
        appear={realTimeAnimate}
        disableAnimation={!realTimeAnimate}
        onEnter={this.setAnimationClass}
        onEntered={this.setAnimationClass}
        onExit={this.setAnimationClass}
        onExited={this.setAnimationClass}
        timeoutEnter={Timeouts.threeHundred}
        timeoutExit={Timeouts.fiveHundred}
      >
        <div className={wrapperClass} style={rowStyle}>
          <div
            className={rowClass}
            key={`tout-row-${data.id}`}
            onClick={this.rowClick}
          >
            {dragAndDropComponent}
            {selectableComponent}
            {columns.map(
              ({ align = Align.LEFT, flex, rowCell, width, widthMode, id }) => {
                const className = `flex flex--align-middle flex--align-${align} text-${align}`; //hook flex--align-center flex--align-left flex--align-right
                return (
                  <Cell
                    className="tout-row-transition"
                    flex={flex}
                    height={height}
                    isResize={isResize}
                    key={`cell-${id}`}
                    width={width}
                    widthMode={widthMode}
                  >
                    <div className={`resize-body-id-${id}`}>
                      {rowCell && rowCell.component ? (
                        <rowCell.component
                          rowData={data}
                          {...rowCell.metadata}
                          className={classNames(
                            className,
                            rowCellClassNames(rowCell)
                          )}
                        />
                      ) : (
                        <TextCell
                          className={className}
                          rowData={data}
                          {...rowCell.metadata}
                        />
                      )}
                    </div>
                  </Cell>
                );
              }
            )}
          </div>
        </div>
      </Collapse>
    );
  }

  getSelectable = () => {
    const { height, isSelected, data, selectable, radio } = this.props;
    let selectableComponent = null;

    if (selectable || radio) {
      if (selectable) {
        selectableComponent = (
          <TableCheckbox
            checked={isSelected}
            disabled={data.isSelectedDisabled || data.person_id === null}
            name={`tout-table-checkbox-${data.id}`}
            onChange={this.checkClick}
          />
        );
      } else if (radio) {
        selectableComponent = (
          <TableRadio
            checked={isSelected}
            disabled={data.isSelectedDisabled}
            name={`tout-table-checkbox-${data.id}`}
            onChange={this.radioClick}
            value={data.id}
          />
        );
      }

      return (
        <Cell
          className="tout-row-transition"
          height={height}
          width={SelectableWidth}
          widthMode={WidthMode.PIXEL}
        >
          {selectableComponent}
        </Cell>
      );
    }

    return null;
  };

  getDragAndDrop = () => {
    const { draggable, data } = this.props;
    if (draggable) {
      return (
        <DragAndDropCell disabled={data.isSelectedDisabled} isHeader={false} />
      );
    }
  };

  setAnimationClass = () => {
    const colorClass = this.getAnimationColorClass(this.props.getAnimation());
    this.setState(() => ({ animationClasses: colorClass }));
  };

  getAnimationColorClass({ color, animate }) {
    return classNames({ [`tout-row-animation-${color}`]: animate && color });
  }

  rowClick = (e) => {
    const {
      data,
      onClick,
      selectable,
      highlightOnClick,
      onHighlightClickRow,
    } = this.props;

    if (selectable && onClick) {
      e.stopPropagation();
      e.preventDefault();
      onClick(data);
    } else if (onClick) {
      onClick(data);
    }

    if (highlightOnClick) {
      onHighlightClickRow(data);
    }
  };

  checkClick = (checked, name, e) => {
    const { data, onCheckClick } = this.props;
    onCheckClick(checked, data, e);
  };

  radioClick = () => {
    const { data, onRadioClick } = this.props;
    onRadioClick(data.id);
  };
}

Row.propTypes = {
  animate: PropTypes.bool,
  columns: PropTypes.arrayOf(Shapes.Column).isRequired,
  data: PropTypes.object.isRequired,
  getAnimation: PropTypes.func.isRequired,
  height: PropTypes.number,
  highlightOnClick: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  onCheckClick: PropTypes.func,
  onClick: PropTypes.func,
  onHighlightClickRow: PropTypes.func,
  onRadioClick: PropTypes.func,
  radio: PropTypes.bool,
  selectable: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  animate: false,
  height: 44,
  highlightOnClick: false,
  onCheckClick: () => {},
  onClick: () => {},
  onHighlightClickRow: () => {},
  onRadioClick: () => {},
  radio: false,
};

export default Row;
export const DraggableRow = withDndSource(Row);
