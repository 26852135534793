import { setSmartFilter } from 'web/tables/smartFilters/actionCreators/smartFiltersActionCreators';
import { VIEW_AS_FILTER_ID } from 'web/commandCenter/modules/whoSmartFilter/libs/whoSmartFilterConstants';
import { ADVANCED_SEARCH_FILTERS_COLLECTION } from 'web/commandCenter/modules/emailGridAdvancedSearch/libs/emailGridAdvancedSearchConstants';
import ViewersConstants from 'web/sidebar/viewers/libs/viewersConstants';
import { getSelectedUserForViewAsFilter } from 'web/commandCenter/modules/whoSmartFilter/selectors/viewAsFilterSelectors';
import { onSidebarFilterSelect } from 'web/sidebar/viewers/actionCreators/viewersActionCreators';
import CCActionTypes from 'web/commandCenter/libs/commandCenterActionTypes';
import { getActiveEmailTableId } from 'web/commandCenter/selectors/commandCenterSelectors';
import { getAdvancedSearchTableId } from 'web/commandCenter/modules/emailGridAdvancedSearch/helpers/advancedSearchHelpers';

export const setViewAsFilterValue = ({ label: name, value: id } = {}) => (
  dispatch
) => {
  const { user } = ViewersConstants.sidebarViewer.viewer.types;

  dispatch(
    setSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: VIEW_AS_FILTER_ID,
      payload: { viewAsViewer: { id, name, type: user } },
    })
  );
};

export const changeViewAsFilterInputValue = (inputValue) => (
  dispatch,
  getState
) => {
  const currentViewAsViewer = getSelectedUserForViewAsFilter(getState(), {
    collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
  });

  dispatch(
    setSmartFilter({
      collectionId: ADVANCED_SEARCH_FILTERS_COLLECTION,
      filterId: VIEW_AS_FILTER_ID,
      payload: { viewAsViewer: { ...currentViewAsViewer, name: inputValue } },
    })
  );
};

export const setSidebarViewerFromEamilGridAdvancedSearch = () => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentTableId = getActiveEmailTableId(state);
  const viewAsFilter = getSelectedUserForViewAsFilter(state, {
    collectionId: currentTableId,
  });

  const advancedSearchTableId = getAdvancedSearchTableId();

  if (
    currentTableId === advancedSearchTableId &&
    viewAsFilter &&
    viewAsFilter.id
  ) {
    dispatch(
      onSidebarFilterSelect({
        label: viewAsFilter.name,
        value: viewAsFilter.id,
      })
    );
    dispatch({ type: CCActionTypes.state.changeViewAsFilter });
  }
};
