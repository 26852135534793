import IdentitiesTableActionTypes from '../libs/identitiesTableActionTypes';

const initState = {
  loading: false,
  error: false,
};

export function identitiesTable(state = initState, action) {
  switch (action.type) {
    case IdentitiesTableActionTypes.setLoading: {
      const { loading } = action;
      return {
        ...state,
        loading,
      };
    }
    case IdentitiesTableActionTypes.showAlert: {
      return {
        ...initState,
        loading: false,
        error: true,
      };
    }
    case IdentitiesTableActionTypes.clearAlert: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    default:
      return state;
  }
}
