import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Action from 'components/buttons/action';
import { Urls } from 'web/libs/routes';

class SalesforceMsiPackageInstallationCard extends Component {
  installPackage = () => {
    window.open(Urls.salesforceAppExchange, 'msi_app_exchange');
  };

  render() {
    return (
      <div className="salesforce-msi-package-installation-card">
        <h3>
          <FormattedMessage id="web.salesforce.msiPackageInstallationCard.title" />
        </h3>
        <div className="margin-top-20">
          <FormattedHTMLMessage id="web.salesforce.msiPackageInstallationCard.body" />
        </div>
        <div className="margin-top-20 right">
          <Action onClick={this.installPackage} type="button">
            <FormattedMessage id="web.salesforce.msiPackageInstallationCard.installPackage.button" />
          </Action>
        </div>
      </div>
    );
  }
}

export default SalesforceMsiPackageInstallationCard;
