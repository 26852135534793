import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import NavBar from 'components/navBar/navBar';
import LiveFeedHeader from 'web/liveFeed/components/liveFeedHeader';
import LiveFeedTabs from 'web/liveFeed/components/liveFeedTabs';
import './liveFeed.scss';
import Part from 'components/navBar/part';
import Item from 'components/navBar/item';
import { LiveFeedTabsEnum } from '../libs/liveFeedConstants';
import classNames from 'classnames';

class LiveFeed extends Component {
  render() {
    const {
      actionCreators,
      analytics,
      chartEvent,
      dealInformation,
      isOwaMode,
    } = this.props;

    const classes = classNames('live-feed-component', {
      'is-owa-mode': isOwaMode,
    });

    return (
      <div className={classes}>
        {!isOwaMode && (
          <NavBar showLogo onLogoClick={this.handleLogoClick}>
            <Part right>
              <Item icon="icon-cup" onClick={actionCreators.openGong} />
              <Item icon="icon-light-bulb" onClick={actionCreators.openHelp} />
            </Part>
          </NavBar>
        )}
        <div className="live-feed-header-tabs-container">
          <LiveFeedHeader
            actionCreators={actionCreators}
            analytics={analytics}
            chartEvent={chartEvent}
            dealInformation={dealInformation}
          />
          <LiveFeedTabs {...this.props} />
        </div>
      </div>
    );
  }

  handleLogoClick = () => {
    const {
      actionCreators: { selectTab },
    } = this.props;

    const [first] = Object.keys(LiveFeedTabsEnum);
    const { value: tabName } = LiveFeedTabsEnum[first];

    selectTab(tabName);
  };
}

LiveFeed.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  chartEvent: PropTypes.string.isRequired,
  dealInformation: PropTypes.object.isRequired,
  engageFeatureEnabled: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
  isOwaMode: PropTypes.bool,
};

LiveFeed.defaultProps = {
  isOwaMode: false,
};

export default injectIntl(LiveFeed);
