import apiHelper from 'web/services/apiHelper';
import { closeModal, openModal } from './campaignTemplatesPopupActionCreators';
import { getActiveCampaignTemplates } from './campaignTemplatesActionCreators';
import { setUser } from 'web/user/actionCreators/userActionCreators';

export function campaignTemplatesStartup(id, activeTemplatesData, modalId) {
  return (dispatch) => {
    if (document.querySelector('meta[name="csrf-token"]')) {
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');
      if (csrfToken) {
        apiHelper.setCsrfToken(csrfToken);
      }
    }
    dispatch(closeModal()); // reset any previous states
    dispatch(openModal(modalId, { id }));

    dispatch(setUser(window.toutAppBootstrap.accountData));
    dispatch(getActiveCampaignTemplates(id, activeTemplatesData));
  };
}
