import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextButton from 'components/buttons/text';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import AccountInfoEditing from './editing';
import AccountInfoDetails from './details';
import './accountInfoCard.scss';

class AccountInfoCard extends Component {
  render() {
    const {
      actionCreators,
      accountInfo,
      accountInfoEditState,
      salesforceRequiredFields,
      isEditing,
      person,
      salesforceInstanceUrl,
    } = this.props;
    if (accountInfo) {
      return (
        <AccountInfoDetails
          actionCreators={actionCreators}
          accountInfo={accountInfo}
          person={person}
          salesforceInstanceUrl={salesforceInstanceUrl}
        />
      );
    } else if (isEditing) {
      return (
        <AccountInfoEditing
          actionCreators={actionCreators}
          accountInfoEditState={accountInfoEditState}
          requiredFields={salesforceRequiredFields}
          person={person}
        />
      );
    } else {
      return (
        <div className="person-details-account-info-card">
          <div className="account-info-card-header-row margin-bottom-half">
            <h3 className="margin-bottom-half">
              <FormattedMessage id="web.person.personDetails.accountInfo.title" />
            </h3>
            <TextButton onClick={this.edit} underline>
              <FormattedMessage id="web.person.personDetails.accountInfo.addToSalesforce" />
            </TextButton>
          </div>
          <div>
            <FormattedMessage id="web.person.personDetails.accountInfo.notInSalesforce" />
          </div>
        </div>
      );
    }
  }

  edit = () =>
    this.props.actionCreators.openCardEditing(PersonDetailsCards.accountInfo);
}

AccountInfoCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  accountInfo: PropTypes.array,
  accountInfoEditState: PropTypes.object.isRequired,
  salesforceRequiredFields: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  person: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string.isRequired,
};

AccountInfoCard.defaultProps = {
  accountInfo: undefined,
  isEditing: undefined,
};

export default AccountInfoCard;
