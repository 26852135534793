import moment from 'moment';
import { baseGet, basePost } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import {
  HEART_BEAT_INTERVAL,
  LiveFeedAnalytics,
  LiveFeedHeartbeat,
} from 'web/liveFeed/libs/liveFeedConstants';

let heartBeatsTimer = null;
const NEXT_DAY = 1;

export const sendHeartBeats = (userId, clientId) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.liveFeedHeartBeats,
      {
        user_id: userId,
        channel: LiveFeedHeartbeat.liveFeed,
        topic: LiveFeedHeartbeat.interestingMoments,
        client_id: clientId,
        client_name: LiveFeedHeartbeat.liveFeedWeb,
      },
      resolve,
      reject
    );
  });

export const initPolling = (userId) =>
  new Promise((resolve, reject) => {
    basePost(Urls.liveFeedInitPolling, { user_id: userId }, resolve, reject);
  });

export const checkLeadInSalesforce = (email) =>
  new Promise((resolve, reject) => {
    baseGet(
      Urls.checkLeadInSalesforce,
      { sf_from_email: email },
      resolve,
      reject
    );
  });

export const getSalesforceInstance = () =>
  new Promise((resolve, reject) => {
    baseGet(Urls.salesforceInstance, {}, resolve, reject);
  });

export const heartBeatsService = (userId) => {
  heartBeatsTimer = setInterval(() => {
    const timeStamp = Date.now().toString();
    sendHeartBeats(userId, timeStamp);
  }, HEART_BEAT_INTERVAL);
};

export const clearHeartBeat = () => {
  clearInterval(heartBeatsTimer);
};

export const getLiveFeedAnalytics = () =>
  new Promise((resolve, reject) => {
    const from = moment()
      .startOf('d')
      .subtract(LiveFeedAnalytics.daysLimit, 'd')
      .toISOString();
    const to = moment()
      .startOf('d')
      .add(NEXT_DAY, 'd')
      .toISOString();

    baseGet(
      Urls.liveFeedAnalytics,
      {
        from,
        to,
        interval: LiveFeedAnalytics.interval,
        raw: true,
        scope: LiveFeedAnalytics.scope,
      },
      resolve,
      reject
    );
  });
