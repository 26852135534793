import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup, { PopupButtonColors } from 'components/popups';
import { FormattedMessage } from 'react-intl';
import { PopupSize } from 'libs/constantsShared';

class AutoAppendConfirmationPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      loading,
    } = this.props;

    return (
      <Popup
        footer={{
          onPrimary: this.onSave,
          onSecondary: closePopup,
          primaryTextId: 'common.save',
          secondaryTextId: 'common.cancel',
          primaryColor: PopupButtonColors.blue,
        }}
        header={{
          onClose: closePopup,
          textId: 'web.settings.adminSettings.general.autoAppend.popup.header',
        }}
        loading={loading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <FormattedMessage id="web.settings.adminSettings.general.autoAppend.popup.content" />
      </Popup>
    );
  }

  onSave = () => {
    const {
      actionCreators: { updateCustomAppendContent },
      popupData,
    } = this.props;
    updateCustomAppendContent(popupData);
  };
}

AutoAppendConfirmationPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popupData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

AutoAppendConfirmationPopup.defaultProps = {
  popupData: {},
};

export default AutoAppendConfirmationPopup;
