import React from 'react';
import PropTypes from 'prop-types';
import { VictoryContainer, VictoryChart, VictoryAxis } from 'victory';
import LineChart from 'components/chart/line';
import { ColorHexCodes } from 'libs/constantsStyles';
import { parseChartData } from 'web/liveFeed/helpers/liveFeedParsers';
import { ENGAGEMENT_CHART_DAYS } from 'web/liveFeed/libs/liveFeedConstants';
import { moment } from 'languages';

const nullTick = () => null;
const dayTick = (date, i) => {
  const count = ENGAGEMENT_CHART_DAYS - (i + 1);
  const labelDate = moment()
    .subtract(count, 'days')
    .startOf('day')
    .toDate();
  return moment(labelDate).format('dd');
};
const tickCount = (maxEmails) => {
  if (maxEmails < ENGAGEMENT_CHART_DAYS) {
    return maxEmails || 1;
  }
  return null;
};

const chartStyle = {
  animate: { duration: 300, onLoad: { duration: 0 } },
  height: 135,
  width: 250,
  padding: { top: 50, left: 30, bottom: 35, right: 55 },
};

const xAxisStyle = {
  axis: { stroke: ColorHexCodes.toutGrayLight },
  tickLabels: {
    fontFamily: 'Graphik',
    fill: ColorHexCodes.toutGrayLight,
    fontSize: 12,
  },
};

const yAxisStyle = { grid: { stroke: ColorHexCodes.toutGrayExtraLight } };

export default function EngagementChart({ analytics, event }) {
  const sentData = parseChartData(analytics);
  const eventData = parseChartData(analytics, event);
  const maxEmails = Math.max(
    ...sentData.concat(eventData).map((coord) => coord.y)
  );

  return (
    <VictoryChart
      containerComponent={<VictoryContainer />}
      domain={{ x: [1, ENGAGEMENT_CHART_DAYS], y: [0, maxEmails || 1] }}
      {...chartStyle}
    >
      <VictoryAxis style={xAxisStyle} tickFormat={dayTick} />
      <VictoryAxis
        dependentAxis
        tickCount={tickCount(maxEmails)}
        tickFormat={nullTick}
        axisComponent={<svg />}
        style={yAxisStyle}
      />
      <LineChart data={sentData} />
      <LineChart color={ColorHexCodes.toutPinkLight} data={eventData} />
    </VictoryChart>
  );
}

EngagementChart.propTypes = {
  analytics: PropTypes.object.isRequired,
  event: PropTypes.string.isRequired,
};
