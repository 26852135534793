// eslint-disable-line
// because of "Cannot find module 'webpackbar'"
import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Switch, Route, Redirect } from 'react-router-dom';
import Router from 'web/router/Router';
import I18N from 'languages';
import 'styles/tout-ui-styles.scss';
import AddToCampaignContainer from 'web/modals/addToCampaign/container/addToCampaignContainer';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import CampaignTabContainer from 'web/campaigns/campaignsTab/campaignTabContainer';
import CampaignDetailsContainer from 'web/campaigns/campaignsTab/containers/campaignDetailsContainer/campaignDetailsContainer';
import { campaignsTabStartup } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabStartupActionCreators';
import { addToCampaignStartup } from 'web/modals/addToCampaign/actionCreators/startup';
import { addContentStartup } from 'web/modals/addContent/actionCreators/startup';
import AddContentPartnersContainer from 'web/modals/addContent/container/addContentPartnersContainer';
import { gongStartup } from 'web/modals/gong/actionCreators/startup';
import GongContainer from 'web/modals/gong/containers/gongContainer';
import TaskModalContainer from 'web/modals/task/container/taskModalContainer';
import DynamicFieldsModalContainer from 'web/modals/dynamicFields/container/dynamicFieldsModalContainer';
import { imageInsertStartup } from 'web/modals/imageInsert/actionCreators/imageInsertStartup';
import ImageInsertContainer from 'web/modals/imageInsert/container/imageInsertContainer';
import { peopleDeleteStartup } from 'web/modals/peopleDelete/actionCreators/peopleDeleteStartup';
import PeopleDeleteContainer from 'web/modals/peopleDelete/containers/peopleDeleteContainer';
import TemplatesTabContainer from 'web/templates/containers/templatesTabContainer';
import { templatesStartup } from 'web/templates/actionCreators/templatesStartupActionCreators';
import CampaignsUsingTemplateContainer from 'web/campaignsUsingTemplate/container/campaignsUsingTemplateContainer';
import { campaignTemplatesStartup } from 'web/modals/campaignTemplates/actionCreators/campaignTemplatesStartup';
import CampaignTemplatesModalContainer from 'web/modals/campaignTemplates/container/campaignTemplatesModalContainer';
import ComposeEmailContainer from 'web/modals/composeEmail/containers/composeEmailContainer';
import { composeEmailStartup } from 'web/modals/composeEmail/actionCreators/composeEmailActionCreators';
import { ModalPopupIds } from 'web/libs/constants';
import CommandCenterContainer from 'web/commandCenter/containers/commandCenterContainer';
import GetStartedContainer from 'web/getStarted/containers/getStartedContainer';
import TasksTabContainer from 'web/commandCenter/containers/commandCenterTasksTabContainer';
import TasksGridContainer from 'web/commandCenter/containers/commandCenterTasksGrigContainer';
import EmailsTabContainer from 'web/commandCenter/containers/commandCenterEmailsTabContainer';
import { emailsStartup } from 'web/commandCenter/actionCreators/commandCenterEmailsStartupActionCreators';
import { tasksStartup } from 'web/commandCenter/actionCreators/commandCenterTasksStartupActionCreators';
import SharingSettingsContainer from 'web/settings/sharing/containers/sharingSettingsContainer';
import AdminUnsubscribes from 'web/settings/adminSettings/unsubscribes/containers/adminUnsubscribesContainer';
import UserIntegrations from 'web/settings/accountSettings/integrations/containers/integrationsContainer';
import { integrationsStartup } from 'web/settings/accountSettings/integrations/actionCreators/integrationsActionCreators';
import NonHoverTooltipContainer from 'web/nonHoverTooltip/nonHoverTooltipContainer/nonHoverTooltipContainer';
import MarketoNonAdminContainer from 'web/marketoNonAdmin/containers/marketoNonAdminContainer';
import MarketoAdminSettingsContainer from 'web/marketoSettings/containers/marketoAdminSettingsContainer';
import LiveFeedPipelineContainer from 'web/liveFeed/pipeline/containers/pipelineContainer';
import LiveFeedEngageContainer from 'web/liveFeed/engage/containers/engageContainer';
import LiveFeedTasksContainer from 'web/liveFeed/tasks/containers/tasksContainer';
import LiveFeedContainer from 'web/liveFeed/containers/liveFeedContainer';
import GeneralAdminSettingsContainer from 'web/settings/adminSettings/general/containers/generalContainer';
import { paste } from 'web/nonHoverTooltip/actionCreators/actionCreators';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import ChooseMarketoWorkspacesContainer from 'web/modals/chooseMarketoWorkspaces/containers';
import { ChooseMarketoWorkspacesPopupid } from 'web/modals/chooseMarketoWorkspaces/libs/chooseMarketoWorkspacesConstants';
import SalesforceCustomizationContainer from 'web/modals/salesforceCustomization/container/salesforceCustomizationContainer';
import UnsubscribeForm from 'web/unsubscribe/unsubscribeForm/containers/unsubscribeFormContainer';
import { settingsUnsubscribesStartup } from 'web/settings/unsubscribes/actionCreators/settingsUnsubscribesActionCreators';
import PersonDetailsNextContainer from 'web/person/containers/personDetailsNextContainer';
import { setNextPersonDetails } from 'web/person/actionCreators/personDetailsNextActionCreators';
import { peopleStartup } from 'web/people/actionCreators/peopleStartupActionCreators';
import PeopleTabContainer from 'web/people/containers/peopleTabContainer';
import DiagnosticsContainer from 'web/diagnostics/containers/diagnosticsContainer';
import PeopleAuthorizationContainer from 'web/people/containers/peopleAuthorizationContainer';
import { peopleAuthorizationStartup } from 'web/people/actionCreators/peopleAuthorizationActionCreators';
import { peopleSourceStartUp } from 'web/modals/peopleSource/actionCreators/peopleSourceActionCreators';
import PeopleSourceContainer from 'web/modals/peopleSource/containers/peopleSourceContainer';
import EmailSettingsContainer from 'web/settings/email/containers/emailSettingsContainer';
import SalesforceAdminContainer from 'web/settings/adminSettings/salesforce/containers/salesforceContainer';
import SalesforceAccountContainer from 'web/settings/accountSettings/salesforce/containers/salesforceContainer';
import TrackingAdminContainer from 'web/settings/adminSettings/tracking/containers/trackingContainer';
import UserManagementContainer from 'web/settings/adminSettings/userManagement/containers/userManagementContainer';
import TeamManagementContainer from 'web/settings/adminSettings/teamManagement/containers/teamManagementContainer';
import { emailSettingsStartup } from 'web/settings/email/actionCreators/emailSettingsActionCreators';
import { salesforceStartup as accountSalesforceStartup } from 'web/settings/accountSettings/salesforce/actionCreators/salesforceActionCreators';
import { trackingStartup as adminTrackingStartup } from 'web/settings/adminSettings/tracking/actionCreators/trackingActionCreators';
import { salesforceStartup as adminSalesforceStartup } from 'web/settings/adminSettings/salesforce/actionCreators/salesforceActionCreators';
import { connectionsCustomizationsStartup } from 'web/settings/adminSettings/salesforce/actionCreators/connectionsCustomizationsActionCreators';
import { adminGeneralStartup } from 'web/settings/adminSettings/general/actionCreators/generalActionCreators';
import { userManagementStartup } from 'web/settings/adminSettings/userManagement/actionCreators/userManagementActionCreators';
import { teamManagementStartup } from 'web/settings/adminSettings/teamManagement/actionCreators/teamManagementActionCreators';
import LoginContainer from 'web/login/containers/loginContainer';
import GmailStart from 'gmail/components/start/gmailStart';
import LoginSsoContainer from 'web/loginSso/containers/loginSsoContainer';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import ForgotPasswordContainer from 'web/forgotPassword/containers/forgotPasswordContainer';
import SetPasswordContainer from 'web/setPassword/containers/setPasswordContainer';
import CreateAccountContainer from 'web/createAccount/containers/createAccountContainer';
import ComposeWindowContainer from 'web/composeWindow/containers/composeWindowContainer';
import {
  initComposeWindowView,
  setAsModalMode,
  setMinimizeView,
  toggleBulkView,
} from 'web/composeWindow/actionCreators/composeWindowActionCreators';
import ConnectionsCustomizationsContainer from 'web/settings/accountSettings/salesforce/containers/connectionsCustomizationsContainer';
import TopNavbarContainer from 'web/topNavbar/containers/topNavbarContainer';
import ProfileContainer from 'web/settings/myProfile/containers/profileContainer';
import OutlookDescriptionContainer from 'web/login/containers/outlookDescriptionContainer';
import { profileContainerStartup } from 'web/settings/myProfile/components/timezoneSettings/actionCreators/timezoneSettingsActionCreators';
import OnboardingContainer from 'web/onboarding/containers/onboardingContainer/onboardingContainer';
import { COMPOSE_DOM_TARGET_ID } from 'web/composeWindow/libs/composeWindowConstants';
import RecordingNoticeContainer from 'web/modals/manageRecordingNotice/containers/recordingNoticeContainer';
import EmailConnectionFlowContainer from 'web/settings/emailConnection/containers/emailConnectionFlowContainer';
import { outlookEmailConnectionStartup } from 'web/settings/emailConnection/actionCreators/emailConnectionActionCreators';
import ProductTourContainer from 'web/productTour/containers/productTourContainer';
import ProductTourStepsContainer from 'web/productTour/containers/productTourStepsContainer/productTourStepsContainer';
import DialerSettingsContainer from 'web/settings/adminSettings/dialer/containers/dialerSettingsContainer.js';
import { dialerSettingsStartup } from 'web/settings/adminSettings/dialer/actionCreators/dialerSettingsActionCreators';
import ResendInvitationContainer from 'web/invitations/containers/resendInvitationContainer';
import ManageCallOutcomesModal from '../../packages/web/settings/adminSettings/dialer/components/popups/manageCallOutcomesModal';
import ManageCallReasonsModal from '../../packages/web/settings/adminSettings/dialer/components/popups/manageCallReasonsModal';

const intl = I18N.init();

// Loading l10n style sheet for language specific
require('languages/libs/l10nStyle');

/* Wait to load reducers until after intl init as some reducers use intl for default states */
const configureStore = require('web/reducers/configureStore').default;

const store = configureStore(undefined);

toutBackboneHelper.init(store);

const rootId = 'root';
const rootModalId = 'root-modal';
const rightPopoutId = 'right-popout';
const diagnosticId = 'diagnostic';

if (!global.Intl) {
  global.Intl = require('intl'); // eslint-disable-line global-require
}

function renderReactWithStore({ elemId = rootId, component: Component }) {
  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider {...intl}>
        <Component />
      </IntlProvider>
    </Provider>,
    typeof elemId === 'string' ? document.getElementById(elemId) : elemId
  );
}

export default {
  addContentModal: (windowMode) => {
    store.dispatch(addContentStartup(windowMode));
    renderReactWithStore({
      component: AddContentPartnersContainer,
      elemId: rootModalId,
    });
  },
  addToCampaignModal: (selectedIds, campaignId, mode, sourceOpener) => {
    store.dispatch(
      addToCampaignStartup(selectedIds, campaignId, mode, sourceOpener)
    );
    renderReactWithStore({
      component: AddToCampaignContainer,
      elemId: rootModalId,
    });
  },
  adminUnsubscribes: (elemId, data) => {
    store.dispatch(settingsUnsubscribesStartup(data));
    renderReactWithStore({
      component: AdminUnsubscribes,
      elemId,
    });
  },
  campaignsUsingTemplate: (elemId, templateId) => {
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <CampaignsUsingTemplateContainer templateId={templateId} />
      ),
      elemId,
    });
  },
  campaignsView: (categoryId, campaignId, tab, selectedPerson) => {
    store.dispatch(
      campaignsTabStartup(categoryId, campaignId, tab, selectedPerson)
    );
    renderReactWithStore({ component: CampaignTabContainer });
  },

  campaignDetails: (categoryId, campaignId, tab, selectedPerson) => {
    store.dispatch(
      campaignsTabStartup(categoryId, campaignId, tab, selectedPerson)
    );
    renderReactWithStore({ component: CampaignDetailsContainer });
  },

  chooseMarketoWorkspaces: (emails, success, failure) => {
    store.dispatch(
      openPopup(ChooseMarketoWorkspacesPopupid, { emails, failure, success })
    );
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <ChooseMarketoWorkspacesContainer
          emails={emails}
          failure={failure}
          success={success}
        />
      ),
      elemId: rootModalId,
    });
  },
  commandCenter: (elemId) => {
    store.dispatch(tasksStartup());

    renderReactWithStore({
      component: CommandCenterContainer,
      elemId,
    });
  },
  composeEmailModal: (elemId, options) => {
    store.dispatch(composeEmailStartup(options));
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => <ComposeEmailContainer options={options} />,
      elemId,
    });
  },
  composeWindow: (elemId = COMPOSE_DOM_TARGET_ID, { options }) => {
    const {
      composeWindowState: { minimized, opened },
    } = store.getState();

    if (options.modalMode) {
      store.dispatch(commonStartup());
      store.dispatch(setAsModalMode());
    }

    if (options.multipleRecipients) {
      store.dispatch(toggleBulkView(true));
    }

    if (!opened) {
      renderReactWithStore({
        // eslint-disable-next-line react/display-name
        component: () => <ComposeWindowContainer />,
        elemId,
      });
    }

    if (opened && minimized) {
      store.dispatch(setMinimizeView({ minimized: false }));
    }

    store.dispatch(initComposeWindowView(options));
  },
  deleteParentTemplateModal: (id, activeTemplates) => {
    store.dispatch(
      campaignTemplatesStartup(
        id,
        activeTemplates,
        ModalPopupIds.deleteParentTemplate
      )
    );
    renderReactWithStore({
      component: CampaignTemplatesModalContainer,
      elemId: rootModalId,
    });
  },
  deletePeopleModal: (people) => {
    store.dispatch(peopleDeleteStartup(people));
    renderReactWithStore({
      component: PeopleDeleteContainer,
      elemId: rootModalId,
    });
  },
  diagnosticView: () => {
    store.dispatch(commonStartup());
    renderReactWithStore({
      component: DiagnosticsContainer,
      elemId: diagnosticId,
    });
  },
  dialerSettings: (elemId) => {
    store.dispatch(dialerSettingsStartup());
    renderReactWithStore({
      component: DialerSettingsContainer,
      elemId,
    });
  },
  dynamicFieldsModal: (options = {}) => {
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => <DynamicFieldsModalContainer {...options} />,
      elemId: rootModalId,
    });
  },
  emailSettings: (elemId, options = {}) => {
    store.dispatch(emailSettingsStartup(options));
    renderReactWithStore({
      component: EmailSettingsContainer,
      elemId,
    });
  },
  emails: (options = {}) => {
    store.dispatch(emailsStartup(options));
    renderReactWithStore({ component: EmailsTabContainer });
  },
  generalAdminSettings: (elemId, options = {}) => {
    store.dispatch(adminGeneralStartup(options));
    renderReactWithStore({
      component: GeneralAdminSettingsContainer,
      elemId,
    });
  },
  getStarted: (elemId) => {
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <Router>
          <GetStartedContainer />
        </Router>
      ),
      elemId,
    });
  },
  gongModal: (windowMode, userMetric, accountData, sourceOpener) => {
    store.dispatch(
      gongStartup(windowMode, userMetric, accountData, sourceOpener)
    );
    renderReactWithStore({
      component: GongContainer,
      elemId: rootModalId,
    });
  },
  insertImageModal: (name, data) => {
    store.dispatch(imageInsertStartup(name, data));
    renderReactWithStore({
      component: ImageInsertContainer,
      elemId: rootModalId,
    });
  },
  liveFeed: (options) => {
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <Router>
          <LiveFeedContainer isOwaMode={options && options.isOwaMode} />
        </Router>
      ),
      elemId: rootModalId,
    });
  },
  liveFeedEngage: (elemId) => {
    renderReactWithStore({
      component: LiveFeedEngageContainer,
      elemId,
    });
  },
  liveFeedPipeline: (elemId) => {
    renderReactWithStore({
      component: LiveFeedPipelineContainer,
      elemId,
    });
  },
  liveFeedTasks: (elemId) => {
    renderReactWithStore({
      component: LiveFeedTasksContainer,
      elemId,
    });
  },
  manageCallOutcomesModal: () => {
    renderReactWithStore({
      component: ManageCallOutcomesModal,
      elemId: rootModalId,
    });
  },
  manageCallReasonsModal: () => {
    renderReactWithStore({
      component: ManageCallReasonsModal,
      elemId: rootModalId,
    });
  },
  marketoAdminSettings: (elemId) => {
    store.dispatch(commonStartup());
    renderReactWithStore({
      component: MarketoAdminSettingsContainer,
      elemId,
    });
  },
  marketoNonAdmin: (elemId) => {
    store.dispatch(commonStartup());
    renderReactWithStore({
      component: MarketoNonAdminContainer,
      elemId,
    });
  },
  nonHoverTooltip: (position, text) => {
    store.dispatch(paste(position, text));
    renderReactWithStore({
      component: NonHoverTooltipContainer,
      elemId: rootModalId,
    });
  },
  onboardingContainer: (elemId) => {
    store.dispatch(commonStartup());
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <Router>
          <OnboardingContainer />
        </Router>
      ),
      elemId,
    });
  },
  outlookConnectionOwa: (elemId) => {
    store.dispatch(commonStartup());
    store.dispatch(outlookEmailConnectionStartup());
    renderReactWithStore({
      component: EmailConnectionFlowContainer,
      elemId,
    });
  },
  people: (elemId, options = {}) => {
    store.dispatch(peopleStartup(options));
    renderReactWithStore({
      component: PeopleTabContainer,
      elemId,
    });
  },
  peopleAuthorizationModal: (selectedIds) => {
    store.dispatch(peopleAuthorizationStartup(selectedIds));
    renderReactWithStore({
      component: PeopleAuthorizationContainer,
      elemId: rootModalId,
    });
  },
  peopleSourceModal: (selectedIds) => {
    store.dispatch(peopleSourceStartUp(selectedIds));
    renderReactWithStore({
      component: PeopleSourceContainer,
      elemId: rootModalId,
    });
  },
  productTour: (elemId) => {
    store.dispatch(commonStartup());
    renderReactWithStore({
      component: ProductTourContainer,
      elemId,
    });
  },

  productTourSteps: (elemId) => {
    store.dispatch(commonStartup());
    renderReactWithStore({
      component: ProductTourStepsContainer,
      elemId,
    });
  },

  profileContainer: (elemId, options) => {
    store.dispatch(profileContainerStartup(options));
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <Router>
          <ProfileContainer {...options} />
        </Router>
      ),
      elemId,
    });
  },
  recordingManagerNotice: () => {
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => <RecordingNoticeContainer />,
      elemId: rootModalId,
    });
  },
  salesforceAccountSettings: (elemId, options = {}) => {
    store.dispatch(accountSalesforceStartup(options));
    renderReactWithStore({
      component: SalesforceAccountContainer,
      elemId,
    });
  },
  salesforceAdminSettings: (elemId, options = {}) => {
    store.dispatch(adminSalesforceStartup(options));
    renderReactWithStore({
      component: SalesforceAdminContainer,
      elemId,
    });
  },
  salesforceConnectionOwa: (elemId) => {
    store.dispatch(commonStartup());
    store.dispatch(connectionsCustomizationsStartup());
    renderReactWithStore({
      component: ConnectionsCustomizationsContainer,
      elemId,
    });
  },
  salesforceCustomizationBlock: (elemId) => {
    store.dispatch(commonStartup());
    renderReactWithStore({
      component: SalesforceCustomizationContainer,
      elemId,
    });
  },
  setupPersonDetails: ({ id, ...options }) => {
    store.dispatch(setNextPersonDetails(id, options));
    renderReactWithStore({
      component: PersonDetailsNextContainer,
      elemId: rightPopoutId,
    });
  },
  sharingSettings: (elemId) => {
    renderReactWithStore({
      component: SharingSettingsContainer,
      elemId,
    });
  },
  taskModal: (task = {}, options = {}, mode, sourceOpener) => {
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <TaskModalContainer
          mode={mode}
          options={options}
          sourceOpener={sourceOpener}
          task={task}
        />
      ),
      elemId: rootModalId,
    });
  },
  tasks: () => {
    store.dispatch(tasksStartup());
    renderReactWithStore({ component: TasksTabContainer });
  },

  tasksGrid: () => {
    store.dispatch(tasksStartup());
    renderReactWithStore({ component: TasksGridContainer });
  },

  teamManagement: (elemId, options = {}) => {
    store.dispatch(teamManagementStartup(options));
    renderReactWithStore({
      component: TeamManagementContainer,
      elemId,
    });
  },
  templates: (categoryId, templateId, isEditing, isCreating) => {
    store.dispatch(
      templatesStartup(categoryId, templateId, isEditing, isCreating)
    );
    renderReactWithStore({ component: TemplatesTabContainer });
  },
  topNavbarContainer: (elemId, options) => {
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <Router>
          <TopNavbarContainer {...options} />
        </Router>
      ),
      elemId,
    });
  },
  trackingAdminSettings: (elemId, options = {}) => {
    store.dispatch(adminTrackingStartup(options));
    renderReactWithStore({
      component: TrackingAdminContainer,
      elemId,
    });
  },
  unauthorizedApp: (elemId, isOwaMode = false, isGmailMode = false) => {
    store.dispatch(commonStartup());
    const isDirectLogin = window.location.search.includes('direct_login=true');
    renderReactWithStore({
      // eslint-disable-next-line react/display-name
      component: () => (
        <Router>
          <React.Fragment>
            <Switch>
              <Route
                path="/login"
                render={(routeProps) =>
                  isOwaMode && !isDirectLogin ? (
                    <OutlookDescriptionContainer />
                  ) : (
                    <LoginContainer
                      {...routeProps}
                      isGmailMode={isGmailMode}
                      isOwaMode={isOwaMode}
                    />
                  )
                }
              />
              <Route component={LoginSsoContainer} path="/saml/new" />
              <Route component={GetStartedContainer} path="/nux/go" />
              <Route component={GmailStart} path="/gmail/start" />
              <Route
                component={ForgotPasswordContainer}
                path="/password_reset/new"
              />
              <Route
                component={SetPasswordContainer}
                path="/password_reset/:id/edit"
              />
              <Route
                component={CreateAccountContainer}
                path="/activate_account/:activation_code"
              />
              <Route
                component={CreateAccountContainer}
                path="/invitation/:invitation_code"
              />
              <Route
                component={ResendInvitationContainer}
                path="/unauthorized_index/resend_invitation/:invitation_code"
              />
              <Redirect to="/login" />
            </Switch>
          </React.Fragment>
        </Router>
      ),
      elemId,
    });
  },
  unmountNode: (divId) => {
    ReactDOM.unmountComponentAtNode(document.getElementById(divId));
  },
  unsubscribeForm: (elemId) => {
    renderReactWithStore({
      component: UnsubscribeForm,
      elemId,
    });
  },
  updateCampaignTemplatesModal: (id, activeTemplates) => {
    store.dispatch(
      campaignTemplatesStartup(
        id,
        activeTemplates,
        ModalPopupIds.updateCampaignTemplates
      )
    );
    renderReactWithStore({
      component: CampaignTemplatesModalContainer,
      elemId: rootModalId,
    });
  },
  userIntegrations: (elemId) => {
    store.dispatch(integrationsStartup());

    renderReactWithStore({
      component: UserIntegrations,
      elemId,
    });
  },
  userManagement: (elemId, options = {}) => {
    store.dispatch(userManagementStartup(options));
    renderReactWithStore({
      component: UserManagementContainer,
      elemId,
    });
  },
};
