import EngageActionTypes from 'web/liveFeed/engage/libs/engageActionTypes';
import LiveFeedActionTypes from '../libs/liveFeedActionTypes';
import PipelineActionTypes from 'web/liveFeed/pipeline/libs/pipelineActionTypes';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';
import {
  findOrCreatePerson as findOrCreatePersonCall,
  addToSalesforce,
  getPersonById,
} from 'web/people/services/peopleService';
import {
  formatInitialToutEvents,
  formatMarketoLeads,
  mergeMarketoToutEvents,
} from 'web/liveFeed/engage/helpers/engageParsers';
import { getLiveFeedAnalytics } from 'web/liveFeed/actionCreators/liveFeedHeaderActionCreators';
import {
  initLiveFeedToutPusherService,
  initLiveFeedMarketoPusherService,
} from 'web/liveFeed/actionCreators/liveFeedPusherActionCreators';
import { getToutEvents as getToutEventsCall } from 'web/liveFeed/engage/services/engageService';
import {
  getSalesforceInstance as getSalesforceInstanceCall,
  initPolling as initPollingCall,
} from 'web/liveFeed/services/liveFeedService';
import {
  openEmail as openEmailCall,
  openAddToCampaign as openAddToCampaignCall,
  openCall as openCallModal,
  openSalesforceContact as openSalesforceContactCall,
  openLinkedInSearch as openLinkedInSearchCall,
} from 'web/liveFeed/services/liveFeedWindowService';
import { Urls } from 'web/libs/routes';
import { initPusher as initTaskPusher } from 'web/tasks/actionCreators/tasksPusherActionCreators';
import { openNewTab } from 'web/services/routerService';
import { track } from 'web/services/mixpanelService';
import { LiveFeed } from 'web/libs/mixpanelEvents';
import { LiveFeedTabsEnum } from 'web/liveFeed/libs/liveFeedConstants';
import { PEOPLE_GENERAL_GROUPS } from 'web/people/libs/peopleConstants';
import { SalesforceTypes } from 'web/person/libs/personDetailsConstants';
import { getPrimaryAddress } from 'web/person/helpers/personHelpers';

export const setLoading = (loading = false) => ({
  loading,
  type: LiveFeedActionTypes.setLoading,
});

const initStore = () => ({
  type: LiveFeedActionTypes.initStore,
});

export const selectTab = (tabName) => {
  switch (tabName) {
    case LiveFeedTabsEnum.engagement.value:
      track(LiveFeed.engagement);
      break;
    case LiveFeedTabsEnum.tasks.value:
      track(LiveFeed.tasks);
      break;
    case LiveFeedTabsEnum.target.value:
      track(LiveFeed.target);
      break;
    default:
      break;
  }
  return {
    type: LiveFeedActionTypes.tabSelected[tabName],
  };
};

export const getMessageId = (
  {
    marketo_authentication_available: marketoAuthenticationAvailable,
    salesforce_authentication_available: salesforceAuthenticationAvailable,
  },
  admin
) => {
  let messageId;
  if (
    marketoAuthenticationAvailable === false &&
    salesforceAuthenticationAvailable === false
  ) {
    messageId = admin
      ? 'web.liveFeed.pipeline.noIntegration'
      : 'web.liveFeed.target.noIntegrationAndNotAdmin';
  } else if (salesforceAuthenticationAvailable) {
    messageId = admin
      ? 'web.liveFeed.pipeline.noMarketoIntegration'
      : 'web.liveFeed.target.noMarketoAndNotAdmin';
  } else if (marketoAuthenticationAvailable) {
    messageId = 'web.liveFeed.target.noSalesforceIntegration';
  } else {
    messageId = 'web.liveFeed.pipeline.errorFetchingBets';
  }
  return messageId;
};

export const initPolling = () => (dispatch, getState) => {
  dispatch(setLoading(true));
  const {
    user: {
      id: userId,
      admin: isAdmin,
      subscription: { id: subscriptionId } = {},
    },
  } = getState();
  let triggerMarketoPusher = true;
  Promise.all([
    initPollingCall(userId).catch(({ response = {} }) => {
      const { body: error = {} } = response;
      const messageId = getMessageId(error, isAdmin);
      triggerMarketoPusher = false;
      dispatch({
        messageId,
        type: PipelineActionTypes.pipelineErrorMessageId,
      });
      return response;
    }),
    getToutEventsCall().catch((e) => e),
  ])
    .then(
      ([
        {
          dynamic_chat_activities: dynamicChatActivities = [],
          interesting_moments: interestingMoments = [],
          best_bets,
        }, // eslint-disable-line
        toutEvents,
      ]) => {
        const activities = [...dynamicChatActivities, ...interestingMoments];

        const leads = mergeMarketoToutEvents(
          formatMarketoLeads(activities),
          formatInitialToutEvents(toutEvents)
        );

        leads.forEach((lead) => {
          lead.events.forEach((event) => {
            event.unseen = false; // eslint-disable-line
          });
        });

        dispatch({
          leads,
          type: EngageActionTypes.events,
        });

        dispatch(initLiveFeedToutPusherService(userId, subscriptionId));

        if (triggerMarketoPusher) {
          const bestBets = formatMarketoLeads(best_bets); // eslint-disable-line

          bestBets.forEach((bestBet) => {
            bestBet.events.forEach((event) => {
              event.unseen = false; // eslint-disable-line
            });
          });

          dispatch({
            bestBets,
            type: PipelineActionTypes.bestBets,
          });

          dispatch(initLiveFeedMarketoPusherService(userId, subscriptionId));
        }
      }
    )
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
      dispatch({
        loading: false,
        type: EngageActionTypes.initialLoading,
      });
    });
};

export const getSalesforceInstance = () => (dispatch) => {
  getSalesforceInstanceCall()
    .then(({ instance_url: instanceUrl }) => {
      dispatch({
        salesforceInstanceUrl: instanceUrl,
        type: LiveFeedActionTypes.updateSalesforceInstanceUrl,
      });
    })
    .catch(() => {
      dispatch({
        salesforceInstanceUrl: Urls.connectToSalesforce,
        type: LiveFeedActionTypes.updateSalesforceInstanceUrl,
      });
    });
};

export const setEngageAuthenthication = (engageFeatureEnabled) => ({
  engageFeatureEnabled,
  type: LiveFeedActionTypes.engageUserAuthentication,
});

export const startupLiveFeed = () => (dispatch, getState) => {
  const { liveFeedInitStore } = getState();

  dispatch(commonStartup());
  dispatch(getSalesforceInstance());
  dispatch(initTaskPusher());
  dispatch(getLiveFeedAnalytics());

  const {
    admin_marketo_auth_enabled: adminMarketoAuthEnabled,
  } = getState().user.subscription;
  dispatch(setEngageAuthenthication(adminMarketoAuthEnabled));

  if (!liveFeedInitStore) {
    dispatch(initStore());
  }
  dispatch(initPolling());
};

export const openEmail = ({ personId, addressId }) => () => {
  openEmailCall(personId, addressId);
};

export const openAddToCampaign = (personId) => () => {
  openAddToCampaignCall(personId);
};

export const openCall = (personId, taskId = '') => () => {
  openCallModal(personId, taskId);
};

const createSalesforceContact = async (personId) => {
  const person = await getPersonById(personId);
  const sobject = {
    Email: getPrimaryAddress(person.addresses).address,
    FirstName: person.first_name,
    LastName: person.last_name,
    Title: person.title,
  };

  const { id: salesforceId } = await addToSalesforce(SalesforceTypes.contact, {
    sobject,
  });
  return salesforceId;
};

export const openSalesforceContact = (
  salesforceInstanceUrl,
  salesforceId,
  personId
) => async () => {
  if (!salesforceId && personId) {
    const salesforceId = await createSalesforceContact(personId);
    openSalesforceContactCall(salesforceInstanceUrl, salesforceId);
  } else {
    openSalesforceContactCall(salesforceInstanceUrl, salesforceId);
  }
};

export const openLinkedInSearch = (name) => () => {
  openLinkedInSearchCall(name);
};

export const openPdvForMarketoLead = (lead) => () => {
  const newTab = openNewTab();
  findOrCreatePersonCall(lead)
    .then((response) => {
      const { addressable_id: personId } = response.person.primary_address;
      newTab.location.href = Urls.personDetailsGroupUrl
        .replace(':group_id', PEOPLE_GENERAL_GROUPS.everyone.id)
        .replace(':person_id', personId);
    })
    .catch(() => {
      newTab.location.href = Urls.peoplePageUrl;
    });
};
