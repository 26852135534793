import React from 'react';
import { FormattedMessage } from 'react-intl';
import Templates from './templates';
import Campaigns from './campaigns';

const ContentLockDown = (props) => (
  <div>
    <h3>
      <FormattedMessage id="web.settings.adminSettings.general.contentLockDown.title" />
    </h3>
    <div className="margin-top-20">
      <FormattedMessage id="web.settings.adminSettings.general.contentLockDown.description" />
    </div>
    <Templates {...props} />
    <Campaigns {...props} />
  </div>
);

export default ContentLockDown;
