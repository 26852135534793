import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarketoHeader from './marketoHeader';
import LiveFeedEvents from '../liveFeedEvents';
import { FormattedMessage } from 'react-intl';
import './marketoCard.scss';

class MarketoCard extends Component {
  render() {
    const {
      actionCreators,
      bestBet,
      salesforceInstanceUrl,
      isLoading,
      showMarketoBorder,
      targetTab,
    } = this.props;

    return (
      <div className="marketo-card">
        <MarketoHeader
          actionCreators={actionCreators}
          bestBet={bestBet}
          salesforceInstanceUrl={salesforceInstanceUrl}
          isLoading={isLoading}
          showMarketoBorder={showMarketoBorder}
          targetTab={targetTab}
        />
        {this._displayEvents()}
      </div>
    );
  }

  _displayEvents = () => {
    const {
      events,
      actionCreators: { openLink, markEventAsSeen },
    } = this.props;

    return events.length ? (
      <LiveFeedEvents
        events={events}
        openLink={openLink}
        markEventAsSeen={markEventAsSeen}
      />
    ) : (
      <div className="empty-marketing-activities">
        <FormattedMessage id="web.liveFeed.target.noMarketingActivities" />
      </div>
    );
  };
}

MarketoCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  bestBet: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  showMarketoBorder: PropTypes.bool,
  targetTab: PropTypes.bool,
};

MarketoCard.defaultProps = {
  showMarketoBorder: false,
  targetTab: false,
};

export default MarketoCard;
