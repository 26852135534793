import { createSelector } from 'reselect';
import differenceBy from 'lodash/differenceBy';

const getDynamicFields = (state) => state.dynamicFields;
const getPropsExclude = (state, props) => props.excludeFields;

export const getFilteredDynamicFields = createSelector(
  [getDynamicFields, getPropsExclude],
  (dynamicFields, excludeFields) =>
    differenceBy(dynamicFields, excludeFields, (element) => {
      if (typeof element === 'string') {
        return (element[0] !== '{' && `{{${element}}}`) || element;
      } else {
        return element.value;
      }
    })
);
