import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import SlideOutsActionTypes from 'web/slideOuts/libs/slideOutsActionTypes';
import { EditTemplateTabs } from '../libs/editTemplateSlideOutConstants';

export function editTemplateSlideOutTab(
  state = '',
  { type = '', tab = EditTemplateTabs.template }
) {
  switch (type) {
    case EditTemplateActionTypes.open:
    case EditTemplateActionTypes.setTab:
      return tab;
    case EditTemplateActionTypes.close:
    case SlideOutsActionTypes.close:
      return '';
    default:
      return state;
  }
}

const EMPTY_OBJECT = {};
export function editTemplateSlideOutTemplate(
  state = EMPTY_OBJECT,
  { type = '', note = {}, template = EMPTY_OBJECT }
) {
  switch (type) {
    case EditTemplateActionTypes.setTemplate:
      return template;
    case TemplatesActionTypes.templates.update:
      return (state.id === template.id && template) || state;
    case EditTemplateActionTypes.addTemplateNote:
      if (note.id) {
        return { ...state, notes: [...state.notes, note] };
      } else {
        return state;
      }
    case EditTemplateActionTypes.updateTemplateNote: {
      const index = state.notes.findIndex(
        (noteState) => noteState.id === note.id
      );
      if (note.id && index >= 0) {
        return {
          ...state,
          notes: [
            ...state.notes.slice(0, index),
            note,
            ...state.notes.slice(index + 1),
          ],
        };
      } else if (note.id) {
        return { ...state, notes: [...state.notes, note] };
      } else {
        return state;
      }
    }
    case EditTemplateActionTypes.deleteTemplateNote: {
      const index = state.notes.findIndex(
        (noteState) => noteState.id === note.id
      );
      if (note.id && index >= 0) {
        return {
          ...state,
          notes: [
            ...state.notes.slice(0, index),
            ...state.notes.slice(index + 1),
          ],
        };
      } else {
        return state;
      }
    }
    case EditTemplateActionTypes.close:
    case SlideOutsActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
}

export function editTemplateSlideOutHeader(
  state = '',
  { type = '', template = { name: '' } }
) {
  switch (type) {
    case EditTemplateActionTypes.open:
    case EditTemplateActionTypes.setTemplate:
      return template.name || '';
    default:
      return state;
  }
}

export function editTemplateSlideOutTabLoading(
  state = false,
  { type = '', loading = false }
) {
  switch (type) {
    case EditTemplateActionTypes.setTabLoading:
      return loading;
    case EditTemplateActionTypes.close:
    case EditTemplateActionTypes.setTab:
    case SlideOutsActionTypes.close:
      return false;
    default:
      return state;
  }
}

export const initAlert = { id: '', textValues: EMPTY_OBJECT };
export const editTemplateSlideOutTabAlert = (
  state = initAlert,
  { type, id = '', textValues = EMPTY_OBJECT }
) => {
  switch (type) {
    case EditTemplateActionTypes.setTabAlert:
      return { id, textValues };
    case EditTemplateActionTypes.clearTabAlert:
    case EditTemplateActionTypes.setTab:
    case EditTemplateActionTypes.close:
    case SlideOutsActionTypes.close:
      return initAlert;
    default:
      return state;
  }
};
