import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  emailConnectionStartup,
  getAuthIntegrations,
  saveChanges,
  disconnectEmailConnection,
  closePopup,
  openDisconnectModal,
} from 'web/settings/emailConnection/actionCreators/emailConnectionActionCreators';
import './emailConnectionContainer.scss';
import EmailDisconnect from 'web/settings/emailConnection/components/emailDisconnect/emailDisconnect';
import Popup, { PopupButtonColors } from 'components/popups/popup';
import { ModalComponentIds } from 'web/settings/emailConnection/lib/emailConnectionConstants';
import EmailConnection from '../components/emailConnection';
import { determineProviderText } from 'web/settings/emailConnection/helpers/emailConnectionHelpers';
import LoadingSpinner from 'components/loadingSpinner';
import { getImageUrl } from 'web/libs/constants';

class EmailConnectionContainer extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;

    this.actionCreators = {
      ...bindActionCreators(
        {
          closePopup,
          disconnectEmailConnection,
          emailConnectionStartup,
          getAuthIntegrations,
          openDisconnectModal,
          saveChanges,
        },
        dispatch
      ),
    };
  }

  componentDidMount() {
    // CN: adding class to only email connection page to remove identity card slideout div for owa-plugin
    const emailSettingsPage = document.getElementsByClassName(
      'email-settings-container'
    )[0];
    emailSettingsPage.classList.add('email-connection-page');

    const {
      emailConnectionAuthFetched,
      emailLoadingStates: { emailConnectionFetching },
    } = this.props;

    if (!emailConnectionAuthFetched && !emailConnectionFetching) {
      this.actionCreators.getAuthIntegrations();
    }
  }

  componentWillUnmount() {
    // CN: removing class when unmounted see comment above
    const emailSettingsPage = document.getElementsByClassName(
      'email-settings-container'
    )[0];
    emailSettingsPage.classList.remove('email-connection-page');
  }

  getDisconnectModal = (providerText) => ({
    children: <EmailDisconnect providerText={providerText} />,
    header: {
      textId: 'web.settings.emailConnection.modal.disconnectHeader',
      textValues: { provider: providerText },
      onClose: this.actionCreators.closePopup,
      className: 'email-connection-flow-header',
    },
    footer: {
      onPrimary: this.actionCreators.disconnectEmailConnection,
      onSecondary: this.actionCreators.closePopup,
      primaryTextId: 'common.disconnect',
      secondaryTextId: 'common.cancel',
      primaryColor: PopupButtonColors.red,
    },
  });

  render() {
    const {
      popup,
      emailLoadingStates: { isDisconnecting },
      emailConnectionAuth: { service = '' },
    } = this.props;

    const providerText = determineProviderText(service);
    const { children, ...other } = this.getDisconnectModal(providerText);

    return (
      <div className="email-connection-container">
        {popup === ModalComponentIds.disconnect && (
          <Popup {...other}>
            {isDisconnecting && (
              <LoadingSpinner imageUrl={getImageUrl} size={70} />
            )}
            {children}
          </Popup>
        )}
        <EmailConnection actionCreators={this.actionCreators} {...this.props} />
      </div>
    );
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  user: state.user,
  emailLoadingStates: state.emailLoadingStates,
  popup: state.popup,
  emailConnectionFetching: state.emailConnectionFetching,
  emailConnectionAuth: state.emailConnectionAuth,
  emailConnectionAuthFetched: state.emailConnectionAuthFetched,
});

EmailConnectionContainer.propTypes = {
  emailLoadingStates: PropTypes.object.isRequired,
  popup: PropTypes.string.isRequired,
  emailConnectionAuth: PropTypes.object.isRequired,
  emailConnectionAuthFetched: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(EmailConnectionContainer);
