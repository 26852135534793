import React from 'react';
import CustomDragLayer from './customDragLayer';
import { DragDropContextWrapper } from 'web/shared/components/dragDropContext/dragDropContext';
import { isDetectIE } from 'web/libs/isDetectIE';

export const DndContext = React.createContext('Dnd');

export default function(WrappedComponent) {
  function WithDndContext(props) {
    const { forwardedRef, ...rest } = props;
    return (
      <React.Fragment>
        {/* Fixed problem in Edge with a custom dragging preview layer */}
        {!isDetectIE() && <CustomDragLayer />}
        <WrappedComponent ref={forwardedRef} {...rest} />
      </React.Fragment>
    );
  }
  const WithDndContextForwardedRef = React.forwardRef((props, ref) => (
    <WithDndContext {...props} forwardedRef={ref} />
  ));
  return DragDropContextWrapper(WithDndContextForwardedRef);
}
