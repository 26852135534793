import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './part.scss';

const CLASS_BASE = 'top-navbar-part';
const CLASS_LEFT = 'top-navbar-part-left';
const CLASS_RIGHT = 'top-navbar-part-right';

export default function Part(props) {
  const { left, right, children } = props;
  const className = classnames(CLASS_BASE, {
    [CLASS_LEFT]: left || !right,
    [CLASS_RIGHT]: right,
  });

  return <ul className={className}>{children}</ul>;
}

Part.defaultProps = {
  left: false,
  right: false,
};

Part.propTypes = {
  children: PropTypes.node.isRequired,
  left: PropTypes.bool,
  right: PropTypes.bool,
};
