import React from 'react';
import PropTypes from 'prop-types';
import { SalesforcePopupIds } from 'web/salesforce/libs/salesforceConstants';
import ConnectConfirmationPopup from 'web/salesforce/components/popups/connectConfirmationPopup';
import DisconnectConfirmationPopup from 'web/salesforce/components/popups/disconnectConfirmationPopup';
import ActivateApiConfirmationPopup from 'web/salesforce/components/popups/activateApiConfirmationPopup';
import ActivateBccConfirmationPopup from 'web/salesforce/components/popups/activateBccConfirmationPopup';
import ActivityDynamicFields from 'web/dynamicFields/components/activityDynamicFields/activityDynamicFields';

const Popups = (props) => {
  const { popup } = props;

  switch (popup) {
    case SalesforcePopupIds.connectConfirmation:
      return <ConnectConfirmationPopup {...props} />;
    case SalesforcePopupIds.disconnectConfirmation:
      return <DisconnectConfirmationPopup {...props} />;
    case SalesforcePopupIds.activateApi:
      return <ActivateApiConfirmationPopup {...props} />;
    case SalesforcePopupIds.activateBcc:
      return <ActivateBccConfirmationPopup {...props} />;
    case SalesforcePopupIds.activityCustomizationDynamicFields:
      return <ActivityDynamicFields {...props} />;
    default:
      return null;
  }
};

Popups.propTypes = {
  popup: PropTypes.any,
  popupData: PropTypes.any,
};

Popups.defaultProps = {
  popup: null,
  popupData: {},
};

export default Popups;
