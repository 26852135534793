import React from 'react';
import PropTypes from 'prop-types';
import './tinymce.scss';
import { Editor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce/tinymce';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/themes/silver';
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/media';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/link';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autolink';
import { injectIntl } from 'react-intl';
import { HIGHSPOT_VALUE } from 'web/settings/adminSettings/general/libs/generalPageConstants';
import { boxName as BOX_VALUE } from 'web/integrations/box/libs/boxConstants';
import I18N from 'languages';
import 'languages/tinymce/af';
import 'languages/tinymce/de';
import 'languages/tinymce/en-US';
import 'languages/tinymce/es';
import 'languages/tinymce/fr';
import 'languages/tinymce/hu';
import 'languages/tinymce/ja';
import 'languages/tinymce/pt';
import {
  BaseUrlCustomFonts,
  TinymceDefaultFonts,
  TinymceCustomFonts,
  TinymceDefaultStyle,
} from 'web/libs/constants';

const TINY_MCE_CSS_FILE =
  'https://s3.amazonaws.com/tout-user-store/assets/tinymceEditorStyles.css';
const CUSTOM_FONTS_CSS_FILE = `${BaseUrlCustomFonts}/add-fonts.css`;

const defaultEditorOptions = {
  branding: false,
  browser_spellcheck: true,
  content_css: `${TINY_MCE_CSS_FILE},${CUSTOM_FONTS_CSS_FILE}`,
  content_style: TinymceDefaultStyle,
  contextmenu: false,
  convert_urls: false,
  entity_encoding: 'raw',
  font_formats: (I18N.currLanguage === I18N.Languages.ja) ? `${TinymceDefaultFonts} ${TinymceCustomFonts}` : TinymceDefaultFonts,
  height: '100%',
  language: I18N.getLanguage(),
  link_context_toolbar: true,
  menubar: false,
  object_resizing: true,
  resize: false,
  skin: false,
  statusbar: false,
};

class TinyMCE extends React.Component {
  componentDidMount() {
    const { storeEditorInstance } = this.props;
    const instance = this._getEditorInstance();
    storeEditorInstance(instance);
  }

  _getEditorInstance = () => {
    const { editorId } = this.props;
    return tinymce.get(editorId);
  };

  handleOnChange = (content) => {
    const { handleEditorChange } = this.props;
    handleEditorChange(content);
  };

  getContentMenuItems = () => {
    const {
      contentPartners: { loaded, partners },
      intl: { formatMessage },
      isBoxIntegrationEnabled,
    } = this.props;

    const items = [];

    if (isBoxIntegrationEnabled) {
      items.push({
        text: formatMessage({ id: 'contentPartners.modal.partnerName.box' }),
        tooltip: formatMessage({ id: 'contentPartners.modal.partnerName.box' }),
        type: 'choiceitem',
        value: BOX_VALUE,
      });
    }

    if (!loaded) return items;

    partners.forEach(({ name }) => {
      switch (name) {
        case HIGHSPOT_VALUE:
          items.push({
            text: formatMessage({
              id: 'contentPartners.modal.partnerName.highspot',
            }),
            tooltip: formatMessage({
              id: 'contentPartners.modal.partnerName.highspot',
            }),
            type: 'choiceitem',
            value: HIGHSPOT_VALUE,
          });
          break;
        default:
          break;
      }
    });

    return items;
  };

  getAddContentButton = (editor) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const menuItems = this.getContentMenuItems();
    const addContentSettings = {
      icon: 'upload',
      onAction: () => {
        this.props.openContentFilesPopup(this.props.editorId);
      },
      tooltip: formatMessage({ id: 'web.emailComposeBody.toolbar.addContent' }),
    };

    if (menuItems.length) {
      editor.ui.registry.addSplitButton('addContent', {
        fetch: (cb) => cb(menuItems),
        onItemAction: (api, value) => {
          if (value === HIGHSPOT_VALUE) {
            this.props.openHighSpotPopup(this.props.editorId);
          }
          if (value === BOX_VALUE) {
            this.props.openBoxContentPopup(this.props.editorId);
          }
        },
        ...addContentSettings,
      });
    } else {
      editor.ui.registry.addButton('addContent', addContentSettings);
    }
  };

  getInsertImageButton = (editor) => {
    const {
      intl: { formatMessage },
    } = this.props;

    editor.ui.registry.addButton('imageInsert', {
      icon: 'image',
      onAction: () => {
        this.props.openImageInsertPopup(this.props.editorId);
      },
      tooltip: formatMessage({ id: 'web.imageInsert.title' }),
    });
  };

  getDynamicFieldsButton = (editor) => {
    const {
      intl: { formatMessage },
    } = this.props;

    editor.ui.registry.addButton('dynamicFields', {
      onAction: () =>
        this.props.openInsertDynamicFieldsPopup(this.props.editorId),
      text: '{{}}',
      tooltip: formatMessage({
        id: 'web.campaignsTab.insertDynamicField.title',
      }),
    });
  };

  getComposeWindowOptions = () => ({
    ...defaultEditorOptions,
    plugins: 'link image code paste lists autolink table',
    setup: (editor) => {
      this.getInsertImageButton(editor);
      this.getAddContentButton(editor);
    },
    toolbar:
      'fontselect | fontsizeselect | bold italic underline | align numlist bullist | removeFormat | link addContent customInsertButton imageInsert | undo redo',
    toolbar_drawer: 'sliding',
  });

  getEditingOptions = ({ disableAddContent, disableDynamicFields }) => ({
    ...defaultEditorOptions,
    plugins: 'link image code paste lists autolink table',
    setup: (editor) => {
      !disableDynamicFields && this.getDynamicFieldsButton(editor);
      this.getInsertImageButton(editor);
      !disableAddContent && this.getAddContentButton(editor);
    },
    toolbar:
      'fontselect | fontsizeselect | bold italic underline forecolor | align numlist bullist table removeFormat | link addContent customInsertButton imageInsert | undo redo code dynamicFields',
  });

  render() {
    const {
      disabled,
      editorId,
      handleBlur,
      handleFocus,
      isComposeWindow,
      options,
      value,
    } = this.props;

    const initOptions = isComposeWindow
      ? this.getComposeWindowOptions()
      : this.getEditingOptions(options);

    return (
      <Editor
        disabled={disabled}
        id={editorId}
        init={initOptions}
        onBlur={handleBlur}
        onEditorChange={this.handleOnChange}
        onFocus={handleFocus}
        value={value}
      />
    );
  }
}

TinyMCE.propTypes = {
  disabled: PropTypes.bool,
  editorId: PropTypes.string.isRequired,
  handleEditorChange: PropTypes.func.isRequired,
  isBoxIntegrationEnabled: PropTypes.bool,
  isComposeWindow: PropTypes.bool,
  options: PropTypes.object,
  storeEditorInstance: PropTypes.func,
  value: PropTypes.string.isRequired,
};

TinyMCE.defaultProps = {
  disabled: false,
  isBoxIntegrationEnabled: false,
  isComposeWindow: false,
  options: {},
  storeEditorInstance: () => {},
};

export default injectIntl(TinyMCE);
