import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import {
  EditTemplatePopupAlertIds,
  EditTemplatePopupIds,
  EditTemplateRecommendedFieldsExclude,
} from '../libs/editTemplateSlideOutConstants';
import {
  closePopup,
  openPopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { openModalDynamicFields } from 'web/modals/dynamicFields';
import { getMappingFromField } from '../helpers/editTemplateParsers';
import {
  closeCardEditingAll,
  openCompose,
  setTab,
  updateRecommendEditState,
} from './editTemplateActionCreators';
import { close } from './editTemplateExternalActionCreators';
import {
  redirectOnCreate,
  updateCreateTemplateState,
} from 'web/templates/actionCreators/templatesCreateActionCreators';
import {
  destroyTemplate,
  updateTemplate,
} from 'web/templates/actionCreators/templatesUpdateActionCreators';
import { filterByCategory } from 'web/templates/actionCreators/templateCategoriesActionCreators';
import { changeCategory } from 'web/templates/actionCreators/templatesSidebarActionCreators';
import { hasChanges } from '../helpers/editTemplateHasChangesHelper';
import { getCategoriesTemplatesSharable } from 'web/categories/services/categoriesTemplatesService';
import { updateTemplateSharedWith } from 'web/templates/services/templateSharingService';
import {
  createTemplate,
  cloneTemplate as cloneTemplateCall,
  deleteTemplate as deleteTemplateCall,
  moveTemplate as moveTemplateCall,
  moveTemplateToArchive as moveTemplateToArchiveCall,
} from 'web/templates/services/templateService';
import { deleteNote as deleteNoteCall } from 'web/notes/services/notesService';
import { navigateToTemplatesTab } from 'web/services/routerService';
import {
  getArchivedCategoryId,
  getViewableCategories,
} from 'web/templates/selectors/templatesSelectors';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import xor from 'lodash/xor';
import I18N from 'languages';
import {
  TEMPLATE_SPECIAL_CATEGORY,
  TEMPLATE_TAG_TYPES,
} from 'web/templates/libs/templatesConstants';

export const openDynamicFields = (index, options = {}) => (
  dispatch,
  getState
) => {
  const { dynamicFieldsMap } = getState();

  openModalDynamicFields({
    onClose: () => {},
    onSelected: (field) =>
      dispatch(
        updateRecommendEditState(
          getMappingFromField(field, dynamicFieldsMap),
          index
        )
      ),
    ...options,
  });
};

export const openDynamicFieldsRecommendCard = (index) => (dispatch) => {
  const options = { excludeFields: EditTemplateRecommendedFieldsExclude };

  dispatch(openDynamicFields(index, options));
};

export const openCloneTemplate = (name, categoryId) => (dispatch) => {
  dispatch(
    updateCreateTemplateState({
      categoryId,
      name: I18N.getStr('web.templates.createTemplate.cloneName', { name }),
    })
  );
  dispatch(openPopup(EditTemplatePopupIds.clone));
};

export const onOpenCloneTemplate = () => (dispatch, getState) => {
  const state = getState();
  const { category_id: categoryId, name } = state.editTemplateSlideOutTemplate;
  if (hasChanges(state)) {
    dispatch(
      openPopup(EditTemplatePopupIds.hasChanges, {
        callback: 'openCloneTemplate',
        args: [name, categoryId],
      })
    );
  } else {
    dispatch(openCloneTemplate(name, categoryId));
  }
};

export const openSharing = () => (dispatch, getState) => {
  const state = getState();
  const { editTemplateSlideOutTemplate: template } = state;
  const category =
    getViewableCategories(state).find(
      (cat) => cat.id === template.category_id
    ) || {};
  dispatch({
    type: EditTemplateActionTypes.setSharingState,
    selectedCategory: category,
    initCategoryId: category.id,
    teamIds: template.team_ids,
  });
  dispatch(openPopup(EditTemplatePopupIds.share));
  dispatch(setPopupLoading(true));

  getCategoriesTemplatesSharable()
    .then((sharableCategories = []) => {
      if (!sharableCategories.find((cat) => cat.id === category.id)) {
        sharableCategories.unshift(category);
      }

      dispatch({
        type: EditTemplateActionTypes.setSharableCategories,
        sharableCategories,
      });
      dispatch(setPopupLoading(false));
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
    });
};

export const onOpenSharing = () => (dispatch, getState) => {
  if (hasChanges(getState())) {
    dispatch(
      openPopup(EditTemplatePopupIds.hasChanges, {
        callback: 'openSharing',
        args: [],
      })
    );
  } else {
    dispatch(openSharing());
  }
};

export const onOpenPopup = (popupId) => (dispatch, getState) => {
  if (hasChanges(getState())) {
    dispatch(
      openPopup(EditTemplatePopupIds.hasChanges, {
        callback: 'openPopup',
        args: [popupId],
      })
    );
  } else {
    dispatch(openPopup(popupId));
  }
};

export const onOpenCompose = () => (dispatch, getState) => {
  const { id: templateId } = getState().editTemplateSlideOutTemplate;
  if (hasChanges(getState())) {
    dispatch(
      openPopup(EditTemplatePopupIds.hasChanges, {
        callback: 'openCompose',
        args: [templateId],
      })
    );
  } else {
    dispatch(openCompose(templateId));
  }
};

// Not using constants as names map to the function they call
export const hasChangesCallback = () => (dispatch, getState) => {
  const {
    popupData: { args, callback },
  } = getState();
  dispatch(closeCardEditingAll());

  switch (callback) {
    case 'changeCategory':
      dispatch(changeCategory(...args));
      break;
    case 'close':
      dispatch(close(...args));
      break;
    case 'navigateToTemplatesTab':
      navigateToTemplatesTab(...args);
      break;
    case 'openCloneTemplate':
      dispatch(openCloneTemplate(...args));
      break;
    case 'openSharing':
      dispatch(openSharing(...args));
      break;
    case 'openCompose':
      dispatch(openCompose(...args));
      break;
    case 'openPopup':
      dispatch(openPopup(...args));
      break;
    case 'setTab':
      dispatch(setTab(...args));
      break;
    default:
      break;
  }
};

export const updateMoveCategory = (category) => ({
  type: EditTemplateActionTypes.updateMoveCategory,
  category,
});

export const updateSharedTeamIds = (teamIds) => ({
  type: EditTemplateActionTypes.updateSharedTeamIds,
  teamIds,
});

export const updateSharedCategory = (selectedCategory) => ({
  type: EditTemplateActionTypes.updateSharedCategory,
  selectedCategory,
});

const updateTemplateTeams = (originalTemplate, teamIds) => {
  if (xor(originalTemplate.team_ids, teamIds).length > 0) {
    return updateTemplateSharedWith(originalTemplate.id, teamIds).then(
      (data = {}) => {
        if (data.success) {
          return data.template.team_ids || [];
        } else {
          throw new Error('failed to update shared category');
        }
      }
    );
  } else {
    return Promise.resolve(originalTemplate.team_ids);
  }
};

const updateTemplateCategory = (originalTemplate, selectedCategory) => {
  if (originalTemplate.category_id !== selectedCategory.id) {
    return moveTemplateCall(originalTemplate.id, selectedCategory.id);
  } else {
    return Promise.resolve(originalTemplate);
  }
};

export const shareTemplate = () => (dispatch, getState) => {
  const {
    editTemplateSlideOutTemplate: originalTemplate,
    editTemplateSlideOutPopupShare: { selectedCategory, teamIds },
  } = getState();

  dispatch(setPopupLoading(true));
  let updatedTeamIds;

  updateTemplateTeams(originalTemplate, teamIds)
    .then((newTeamIds) => {
      updatedTeamIds = newTeamIds;
      return updateTemplateCategory(originalTemplate, selectedCategory);
    })
    .then(({ category, category_id: categoryId }) => {
      const updatedTemplate = {
        ...originalTemplate,
        team_ids: updatedTeamIds,
        category,
        category_id: categoryId,
      };
      dispatch(updateTemplate(updatedTemplate));
      dispatch(closePopup());
      navigateToTemplatesTab(updatedTemplate);
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(EditTemplatePopupAlertIds.shareTemplate));
    });
};

export const cloneTemplate = () => (dispatch, getState) => {
  const {
    editTemplateSlideOutTemplate: template,
    templatesCreateTemplateState: { categoryId, name },
  } = getState();

  dispatch(setPopupLoading(true));
  cloneTemplateCall(name, categoryId, template)
    .then((clonedTemplate = {}) => {
      if (clonedTemplate.id && clonedTemplate.id !== template.id) {
        toutBackboneHelper.templateUpdate(clonedTemplate);
        dispatch(redirectOnCreate(clonedTemplate));
        dispatch(closePopup());
      } else {
        throw new Error('failed to clone template');
      }
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(EditTemplatePopupAlertIds.cloneTemplate));
    });
};

export const moveTemplate = () => (dispatch, getState) => {
  const {
    editTemplateSlideOutTemplate: originalTemplate,
    editTemplateSlideOutPopupMove: category,
  } = getState();

  dispatch(setPopupLoading(true));
  moveTemplateCall(originalTemplate.id, category.value)
    .then((movedTemplate = {}) => {
      if (
        movedTemplate.id &&
        movedTemplate.category_id !== originalTemplate.category_id
      ) {
        const updatedTemplate = {
          ...originalTemplate,
          category: movedTemplate.category,
          category_id: movedTemplate.category_id,
        };
        dispatch(updateTemplate(updatedTemplate));
        dispatch(closePopup());
        toutBackboneHelper.templateUpdate(movedTemplate);
        navigateToTemplatesTab(updatedTemplate);
      } else {
        throw new Error('failed to move template');
      }
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(EditTemplatePopupAlertIds.moveTemplate));
    });
};

export const archiveTemplate = () => (dispatch, getState) => {
  const state = getState();
  const { editTemplateSlideOutTemplate: originalTemplate } = state;
  const categoryId = getArchivedCategoryId(state);

  if (!categoryId) {
    dispatch(setPopupAlert(EditTemplatePopupAlertIds.archivedCategoryMissing));
    return;
  }

  dispatch(setPopupLoading(true));
  moveTemplateToArchiveCall(originalTemplate.id)
    .then((response = {}) => {
      if ('successes' in response && response.successes.length) {
        const updatedTemplate = {
          ...originalTemplate,
          category: TEMPLATE_SPECIAL_CATEGORY.archived.apiName,
          category_id: TEMPLATE_SPECIAL_CATEGORY.archived.id,
        };

        dispatch(updateTemplate(updatedTemplate));
        dispatch(closePopup());
        toutBackboneHelper.templateUpdate(updatedTemplate);
        navigateToTemplatesTab(updatedTemplate, TEMPLATE_TAG_TYPES.archived);
      } else {
        throw new Error('failed to set popup loading');
      }
    })
    .catch(() => {
      dispatch(setPopupAlert(EditTemplatePopupAlertIds.archiveTemplate));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};

export const deleteTemplate = () => (dispatch, getState) => {
  const { editTemplateSlideOutTemplate: template } = getState();

  dispatch(setPopupLoading(true));
  deleteTemplateCall(template.id)
    .then(() => {
      dispatch(destroyTemplate(template.id));
      dispatch(closePopup());
      toutBackboneHelper.templateDelete(template.id);
      dispatch(close());
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(EditTemplatePopupAlertIds.deleteTemplate));
    });
};

export const deleteNote = () => (dispatch, getState) => {
  const { id } = getState().popupData;
  dispatch(setPopupLoading(true));

  deleteNoteCall(id)
    .then(() => {
      dispatch({
        type: EditTemplateActionTypes.deleteTemplateNote,
        note: { id },
      });
      dispatch(closePopup());
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(EditTemplatePopupAlertIds.deleteNote));
    });
};

export const addAsTemplate = () => (dispatch, getState) => {
  const {
    editTemplateSlideOutEmails: emails,
    popupData: { emailId },
    templatesCreateTemplateState: { categoryId, name },
  } = getState();
  dispatch(setPopupLoading(true));

  // eslint-disable-next-line camelcase
  const { file_attachments = [], body, subject } =
    emails.find(({ id }) => id === emailId) || {};

  createTemplate({
    categoryId,
    name,
    subject,
    body,
    attachments: file_attachments.map(({ id }) => id),
  })
    .then((template) => {
      // track(PersonDetailsEvents.salesEmails, { [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.addAsTemplate });
      dispatch(filterByCategory(categoryId));
      navigateToTemplatesTab(template, categoryId, true);
    })
    .catch(() => {
      dispatch(setPopupAlert(EditTemplatePopupAlertIds.createTemplate));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};
