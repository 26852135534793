import SmtpDeliveryChannelActionTypes from '../libs/smtpDeliveryChannelActionTypes';
import {
  getDeliveryChannel as getDeliveryChannelCall,
  deleteDeliveryChannel as deleteDeliveryChannelCall,
  createDeliveryChannel as createDeliveryChannelCall,
  updateDeliveryChannel as updateDeliveryChannelCall,
} from '../services/deliveryChannelsService';
import { SMTP_DELIVERY_CHANNEL } from '../libs/deliveryChannelsConstants';

export const setSmtpDeliveryChannelFetching = () => ({
  type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannelFetching,
});

export const getSmtpDeliveryChannel = () => (dispatch) => {
  dispatch(setSmtpDeliveryChannelFetching());

  return getDeliveryChannelCall(SMTP_DELIVERY_CHANNEL)
    .then((deliveryChannel) => {
      dispatch({
        type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannel,
        deliveryChannel,
      });
      return deliveryChannel;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannel,
          deliveryChannel: null,
        });
        return null;
      } else {
        dispatch({
          type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannelError,
        });
        return Promise.reject(error);
      }
    });
};

export const deleteSmtpDeliveryChannel = () => (dispatch) => {
  dispatch(setSmtpDeliveryChannelFetching());

  return deleteDeliveryChannelCall(SMTP_DELIVERY_CHANNEL)
    .then(() => {
      dispatch({
        type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannel,
        deliveryChannel: null,
      });
    })
    .catch((error) => {
      dispatch({
        type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannelError,
      });
      return Promise.reject(error);
    });
};

export const createSmtpDeliveryChannel = (newDeliveryChannel) => (dispatch) => {
  dispatch(setSmtpDeliveryChannelFetching());

  return createDeliveryChannelCall(newDeliveryChannel)
    .then((deliveryChannel) => {
      dispatch({
        type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannel,
        deliveryChannel,
      });
      return deliveryChannel;
    })
    .catch((error) => {
      dispatch({
        type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannelError,
      });
      return Promise.reject(error);
    });
};

export const updateSmtpDeliveryChannel = (updatedDeliveryChannel) => (
  dispatch
) => {
  dispatch(setSmtpDeliveryChannelFetching());

  return updateDeliveryChannelCall(
    updatedDeliveryChannel,
    SMTP_DELIVERY_CHANNEL
  )
    .then((deliveryChannel) => {
      dispatch({
        type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannel,
        deliveryChannel,
      });
      return deliveryChannel;
    })
    .catch((error) => {
      dispatch({
        type: SmtpDeliveryChannelActionTypes.setSmtpDeliveryChannelError,
      });
      return Promise.reject(error);
    });
};
