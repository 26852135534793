import { getReleaseNotes } from 'web/releaseNotes/actionCreators/releaseNoteAnchorBoxActionCreators';
import ReleaseNotesActionTypes from 'web/releaseNotes/libs/releaseNotesActionTypes';

const initStore = () => ({
  type: ReleaseNotesActionTypes.initStore,
});

export const releaseNotesStartup = () => (dispatch, getState) => {
  if (!getState().releaseNotesInitStore) {
    dispatch(getReleaseNotes());

    dispatch(initStore());
  }
};
