import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import Select from 'web/form/components/fields/select';
import { injectIntl, intlShape } from 'react-intl';
import { requiredMultiSelect } from 'web/form/helpers/validators';

class AddMembersToTeamsForm extends Component {
  constructor(props) {
    super(props);

    this.requiredValidator = requiredMultiSelect();
    this.selectPlaceholder = props.intl.formatMessage({
      id: 'common.search',
    });
  }

  render() {
    const {
      handleSubmit,
      onAddMembersToTeamsFormSubmit,
      teamsSelectOptions,
      intl: { formatMessage },
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(onAddMembersToTeamsFormSubmit)} noValidate>
        <Field
          className="margin-top-10"
          name="teams"
          component={Select}
          options={teamsSelectOptions}
          placeholder={this.selectPlaceholder}
          validate={[this.requiredValidator]}
          multi
          searchable
          clearable
          clearAllText={formatMessage({
            id:
              'web.settings.adminSettings.teamManagement.teamMembers.addMembersToTeam.clearAll',
          })}
        />
      </Form>
    );
  }
}

AddMembersToTeamsForm.propTypes = {
  teamsSelectOptions: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAddMembersToTeamsFormSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(reduxForm()(AddMembersToTeamsForm));
