import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { KeyCodes } from 'web/libs/constants';
import Chip from '../chip';
import './chips.scss';

export default class Chips extends Component {
  state = {
    editingId: null,
    selectedIds: [],
  };

  render() {
    const { className, items, stopPattern, validator, children } = this.props;
    const chipsClassName = classNames('tout-chips', className);
    return (
      <div
        className={chipsClassName}
        onKeyDown={this.handleKeyDown}
        tabIndex={0}
      >
        {items.map((item) => (
          <Chip
            key={item.id}
            value={item.value}
            onChange={(value) => this.onChange(item.id, value)}
            editing={item.id === this.state.editingId}
            valid={!validator(item.value)}
            onEdit={() => this.onEdit(item.id)}
            selected={this.state.selectedIds.includes(item.id)}
            onSelect={(ctrl, shift) => this.onSelect(item.id, ctrl, shift)}
            onRemove={() => this.onRemove(item.id)}
            onRemovePrevious={() => this.onRemovePrevious(item.id)}
            stopPattern={stopPattern}
          />
        ))}
        {children}
      </div>
    );
  }

  onEdit = (id) => {
    this.setState({
      editingId: id,
      selectedIds: [],
    });
  };

  handleKeyDown = (event) => {
    const { onDelete, items } = this.props;
    if (this.state.selectedIds.length === 1) {
      if ([KeyCodes.backspace].includes(event.keyCode)) {
        onDelete(this.state.selectedIds);
        const currentIndex = items.findIndex(
          (el) => el.id === this.state.selectedIds[0]
        );
        if (currentIndex > 0) {
          this.setState({
            selectedIds: [items[currentIndex - 1].id],
          });
        } else {
          this.setState({
            selectedIds: [],
          });
        }
      } else if ([KeyCodes.delete].includes(event.keyCode)) {
        onDelete(this.state.selectedIds);
        const currentIndex = items.findIndex(
          (el) => el.id === this.state.selectedIds[0]
        );
        if (currentIndex < items.length - 1) {
          this.setState({
            selectedIds: [items[currentIndex + 1].id],
          });
        } else {
          this.setState({
            selectedIds: [],
          });
        }
      }
    } else if (this.state.selectedIds.length > 1) {
      if ([KeyCodes.backspace, KeyCodes.delete].includes(event.keyCode)) {
        onDelete(this.state.selectedIds);
        this.setState({
          selectedIds: [],
        });
      }
    }
  };

  onChange = (id, value) => {
    this.setState({
      editingId: null,
      selectedIds: [],
    });
    this.props.onUpdate(id, value);
  };

  onSelect = (id, ctrl, shift) => {
    let { selectedIds } = this.state;

    if (ctrl) {
      if (selectedIds.includes(id)) {
        selectedIds = selectedIds.filter((el) => el !== id);
      } else {
        selectedIds = [...selectedIds, id];
      }
    } else if (shift && selectedIds.length) {
      const firstSelectedIndex = this.props.items.findIndex(
        (item) => item.id === selectedIds[0]
      );
      const selectedIndex = this.props.items.findIndex(
        (item) => item.id === id
      );

      if (firstSelectedIndex < selectedIndex) {
        selectedIds = this.props.items
          .slice(firstSelectedIndex, selectedIndex + 1)
          .map((item) => item.id);
      } else {
        selectedIds = this.props.items
          .slice(selectedIndex, firstSelectedIndex + 1)
          .map((item) => item.id);
      }
    } else if (selectedIds.includes(id)) {
      if (selectedIds.length > 1) {
        selectedIds = [id];
      } else {
        selectedIds = [];
      }
    } else {
      selectedIds = [id];
    }

    this.setState({
      selectedIds,
    });
  };

  onRemove = (id) => {
    const { onDelete } = this.props;
    onDelete(id);
  };

  onRemovePrevious = (id) => {
    const { items, onDelete } = this.props;
    const currentIndex = items.findIndex((el) => el.id === id);
    if (currentIndex > 0) {
      onDelete(items[currentIndex - 1].id);
    }
  };
}

Chips.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  stopPattern: PropTypes.objectOf(RegExp),
  validator: PropTypes.func,
  children: PropTypes.any,
};

Chips.defaultProps = {
  className: '',
  items: [],
  onUpdate: () => {},
  onDelete: () => {},
  stopPattern: null,
  validator: () => {},
  children: null,
};
