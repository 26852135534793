import { getUserFromSubscriptionTeams } from 'web/user/services/teamsService';
import { htmlToText } from 'web/services/parsers';

const parseNote = (
  { content, id, updated_at: updatedAt, user_id: userId } = {},
  users
) => ({
  content: htmlToText(content),
  updatedAt: updatedAt || '',
  id: id || 0,
  ...getUserFromSubscriptionTeams(userId, users),
});

const sortNotes = (first, second) =>
  first.updatedAt >= second.updatedAt ? -1 : 1;

export const parseNotes = (notes = [], users = {}) =>
  notes.map((note) => parseNote(note, users)).sort(sortNotes);
