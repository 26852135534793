import filter from 'lodash/filter';
import { createSelector } from 'reselect';

export const getFetched = (state) => state.entities.teams.fetched;
export const getFetching = (state) => state.entities.teams.fetching;
export const getAllIds = (state) => state.entities.teams.allIds;
export const getById = (state) => state.entities.teams.byId;
export const getTeamToAdmin = (state) => state.entities.teams.teamToAdmin;

export const getTeams = createSelector([getAllIds, getById], (allIds, byId) =>
  allIds.map((id) => byId[id])
);

export const shouldInvalidate = createSelector(
  [getFetched, getFetching],
  (fetched, fetching) => !fetched && !fetching
);

export const getMasterTeam = createSelector([getTeams], (teams) =>
  teams.find((team) => team.master)
);

export const getMasterTeamToAdmin = createSelector(
  [getMasterTeam, getTeamToAdmin],
  (masterTeam, teamToAdmin) => {
    if (!masterTeam) {
      return [];
    }
    return filter(teamToAdmin, ['teamId', masterTeam.id]);
  }
);
