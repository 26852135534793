import get from 'lodash/get';
import DialerActionTypes from '../libs/dialerActionTypes';
import {
  getCallOutcomeOptionsRequest,
  bulkCallOutcomeOptionsRequest,
} from '../services/dialerServices';
import { getUser } from 'web/user/actionCreators/userActionCreators';
import { showSettingsAlert } from './dialerSettingsActionCreators';
import { DialerSettingsAlertIds } from '../libs/dialerConstants';

export const getCallOutcomeOptionsSuccess = ({
  callOutcomeOptions,
  callOutcomeValidTypes,
}) => ({
  callOutcomeOptions,
  callOutcomeValidTypes,
  type: DialerActionTypes.getCallOutcomeOptionsSuccess,
});

export const callOutcomeOptionsLoading = ({ isLoading }) => ({
  isLoading,
  type: DialerActionTypes.callOutcomeOptionsLoading,
});

export const getCallOutcomeOptions = (useLoading = true) => async (
  dispatch
) => {
  if (useLoading) dispatch(callOutcomeOptionsLoading({ isLoading: true }));
  try {
    const {
      call_outcome_options: callOutcomeOptions,
      valid_types: callOutcomeValidTypes,
    } = await getCallOutcomeOptionsRequest();
    dispatch(
      getCallOutcomeOptionsSuccess({
        callOutcomeOptions,
        callOutcomeValidTypes,
      })
    );
  } catch (err) {
    dispatch(showSettingsAlert(DialerSettingsAlertIds.somethingWentWrong));
  }
  if (useLoading) dispatch(callOutcomeOptionsLoading({ isLoading: false }));
};

export const bulkCallOutcomeOptions = ({ create, ids_to_delete }) => async (
  dispatch
) => {
  dispatch(callOutcomeOptionsLoading({ isLoading: true }));
  try {
    await bulkCallOutcomeOptionsRequest({
      call_outcome_options: { create, ids_to_delete },
    });
    const {
      call_outcome_options: callOutcomeOptions,
      valid_types: callOutcomeValidTypes,
    } = await getCallOutcomeOptionsRequest();
    dispatch(
      getCallOutcomeOptionsSuccess({
        callOutcomeOptions,
        callOutcomeValidTypes,
      })
    );
    dispatch(showSettingsAlert(DialerSettingsAlertIds.changesSaved));
  } catch (err) {
    dispatch(showSettingsAlert(DialerSettingsAlertIds.changesNotSaved));
  }
  dispatch(callOutcomeOptionsLoading({ isLoading: false }));
};

export const alertEmptyRequiredCallOutcomeOption = ({
  isEnabled = null,
  isRequired = null,
  loadOptions = true,
}) => async (dispatch, getState) => {
  try {
    if (isEnabled === null || isRequired === null) {
      await dispatch(getUser());
      isEnabled = get(
        getState(),
        'user.subscription.admin_settings.call_outcome_enabled',
        false
      );
      isRequired = get(
        getState(),
        'user.subscription.admin_settings.force_call_outcome',
        false
      );
    }
    if (isEnabled && isRequired && loadOptions) {
      await dispatch(getCallOutcomeOptions(false));
    }

    const callOutcomeOptions = getState().callOutcomeOptions || [];
    if (isEnabled && isRequired && callOutcomeOptions.length === 0) {
      dispatch(
        showSettingsAlert(DialerSettingsAlertIds.noCallOutcomesConfigured)
      );
    }
  } catch (err) {
    dispatch(showSettingsAlert(DialerSettingsAlertIds.somethingWentWrong));
  }
};
