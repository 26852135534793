import ActionTypes from 'web/libs/actionTypes/actionTypes';
import PopupActionTypes from 'web/popup/libs/popupActionTypes';

export function gongLoading(state = false, action) {
  switch (action.type) {
    case ActionTypes.gong.showLoading:
      return true;
    case ActionTypes.gong.hideLoading:
    case PopupActionTypes.close:
      return false;
    default:
      return state;
  }
}

export function gongSource(state = '', { type = '', source = '' }) {
  switch (type) {
    case ActionTypes.gong.setSource:
      return source;
    case PopupActionTypes.close:
      return '';
    default:
      return state;
  }
}

export function gongWindowMode(state = false, { type = '' }) {
  switch (type) {
    case ActionTypes.gong.windowMode:
      return true;
    case PopupActionTypes.close:
      return false;
    default:
      return state;
  }
}

export function gongConfirmation(state = false, action) {
  switch (action.type) {
    case ActionTypes.gong.displayConfirmation:
      return action.data;
    case PopupActionTypes.close:
      return false;
    default:
      return state;
  }
}
