/*eslint complexity:0*/
import moment from 'moment';
import {
  MIN_TASK_DATE_RANGE,
  OVERDUE_TASK_DATE_RANGE,
} from '../libs/tasksConstants';

export const BASE_START = moment()
  .subtract(OVERDUE_TASK_DATE_RANGE, 'd')
  .startOf('d');
export const BASE_END = moment()
  .add(MIN_TASK_DATE_RANGE, 'd')
  .startOf('d');

export function getDatesForCalls(storedStart, storedEnd, newStart, newEnd) {
  if (!(storedStart && storedEnd) && newStart && newEnd) {
    // if no stored but new, get new
    return [{ start: moment(newStart), end: moment(newEnd) }];
  } else if (!storedStart || !storedEnd) {
    // if no dates, get base
    return [{ start: moment(BASE_START), end: moment(BASE_END) }];
  } else if (
    !newStart ||
    !newEnd || // if no new range or new range between stored dates, get nothing
    (newStart.isBetween(storedStart, storedEnd, 'd', '[]') &&
      newEnd.isBetween(storedStart, storedEnd, 'd', '[]'))
  ) {
    return [];
  } else if (newStart < storedStart && newEnd > storedStart) {
    // if new range encompasses stored range, just get the edges
    return [
      { start: newStart, end: moment(storedStart).subtract(1, 'd') },
      { start: moment(storedEnd).add(1, 'd'), end: newEnd },
    ];
  } else if (
    newStart < storedStart &&
    newEnd.isBetween(storedStart, storedEnd, 'd', '[]')
  ) {
    // if overlapping end range, just get the start edge
    return [{ start: newStart, end: moment(storedStart).subtract(1, 'd') }];
  } else if (
    newEnd > storedEnd &&
    newStart.isBetween(storedStart, storedEnd, 'd', '[]')
  ) {
    // if overlapping start range, just get the end edge
    return [{ start: moment(storedEnd).add(1, 'd'), end: newEnd }];
  } else if (newEnd < storedStart || newStart > storedEnd) {
    // if new range completely outside stored range, get it
    return [{ start: moment(newStart), end: moment(newEnd) }];
  } else {
    /* istanbul ignore next */
    return []; // added as just in case but not able to get to in testing
  }
}

export function taskIsBetweenBase(task = {}) {
  return (
    (task.remind_at &&
      moment(task.remind_at).isBetween(BASE_START, BASE_END, 'd', '[]')) ||
    false
  );
}

export function taskIsBetweenDates(task = {}, start, end) {
  return (
    (task.remind_at &&
      start &&
      end &&
      moment(task.remind_at).isBetween(start, end, 'd', '[]')) ||
    false
  );
}
