import I18N from 'languages';

export const DEFAULT_PAGE = 1;

export const TEMPLATE_SMART_FILTER_VALUES = {
  all: 'ALL_TEMPLATES',
  favorite: 'FAVORITE_TEMPLATES',
  mine: 'MY_TEMPLATES',
  shared: 'SHARED_TEMPLATES',
  unshared: 'UNSHARED_TEMPLATES',
  unused: 'UNUSED_TEMPLATES',
};

export const BULK_ACTIONS = {
  archive: 'bulk_archive',
  destroy: 'bulk_destroy',
  move: 'bulk_move',
};

export const SORT_DIRECTION = {
  ascending: 'asc',
  descending: 'desc',
};

export const TEMPLATE_TAG_TYPES = {
  archived: 'archived',
  favorite: 'favorite',
};

export const TEMPLATES_VIEWER_TYPES = {
  subscription: 'subscription',
  user: 'user',
};

export const TEMPLATES_FEEDBACK_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScmjNZrvlQ7WLip5skIilnl_qusg_L9-QWNP62nr52cmGA75g/viewform?usp=sf_link';

export const TABLE_ID = 'templates-tab-table';

export const TEMPLATE_SEARCH_FIELDS = ['name', 'subject'];

export const TEMPLATE_SEARCH_AUTOCOMPLETE_INPUT_MIN = 1;

export const TEMPLATE_UNUSED_NUM_DAYS_AGO = -90;

// Use this to keep track of all special category ids
export const SpecialCategoryIds = {
  all: -1,
  archived: -3,
  favorites: -2,
  recentlyUsed: -5,
  recommended: -4,
};

export const TEMPLATE_SPECIAL_CATEGORY = {
  all: {
    apiName: 'All',
    id: SpecialCategoryIds.all,
    name: 'All',
  },
  archived: {
    apiName: 'Archived',
    id: SpecialCategoryIds.archived,
    name: 'Archived',
  },
  delete: {
    name: 'Delete',
  },
  favorites: {
    apiName: 'Favorites',
    id: SpecialCategoryIds.favorites,
    name: 'Favorites',
  },
  general: {
    apiName: 'General',
    name: 'General',
  },
};

export const TEMPLATE_TOP_VIEWABLE_CATEGORIES = [
  TEMPLATE_SPECIAL_CATEGORY.all,
  TEMPLATE_SPECIAL_CATEGORY.archived,
  TEMPLATE_SPECIAL_CATEGORY.favorites,
];

export const TEMPLATE_NON_UPDATABLE_CATEGORIES = [
  TEMPLATE_SPECIAL_CATEGORY.all,
  TEMPLATE_SPECIAL_CATEGORY.archived,
  TEMPLATE_SPECIAL_CATEGORY.favorites,
];

export const TEMPLATES_TAG_UPDATE_ACTIONS = {
  add: 'add',
  remove: 'remove',
};

export const STATS_TEAM = {
  exclude: 'exclude',
  group: 'group',
  include: 'include',
};

export const PITCH_TEMPLATE = 'pitch_template';

export const SPECIAL_FILTER_CATEGORIES = {
  ALL_USERS: -1,
};

export const TemplatesSearchDataTypes = {
  categories: {
    name: I18N.getStr('common.categories'),
    type: 'CATEGORIES',
  },
  templates: {
    name: I18N.getStr('common.templates'),
    type: 'TEMPLATES',
  },
};

export const COLUMN_SETTINGS_TEMPLATES_TABLE_ID =
  'columns_settings_templates_table_id';
