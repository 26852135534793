// import moment from 'moment';

export function validCreate(state = {}) {
  return !!(
    state.title &&
    state.title.trim() &&
    state.date &&
    state.priority &&
    state.type
  );
}

export function validUpdate(state = {} /*, original = {}*/) {
  return validCreate(state); // &&
  // (
  //   state.title !== original.subject ||
  //   state.type !== original.remind_type ||
  //   state.priority !== original.priority ||
  //   state.date !== moment(original.remind_at.format('L')) ||  // costly
  //   state.notes !== (original.script || original.notes || '') ||
  //   state.contactId !== original.person_id ||
  //   state.contactEmail !== original.parent_email
  //   state.markComplete !== (original.unless === 'replied')
  // );
}
