import React from 'react';
import PropsTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import RadioGroup from 'components/buttons/radioGroup';
import './unsubscribesSyncToSalesforce.scss';

const UnsubscribesSyncToSalesforce = ({ items, onChange, selected }) => (
  <div className="flex--center-vert">
    <span className="text-bold margin-right-half">
      <FormattedMessage id="web.settings.unsubscribe.salesforce.syncTo" />
    </span>
    <RadioGroup
      items={items}
      onChange={onChange}
      selected={selected}
      className="unsubscribe-sync-to-salesforce"
    />
  </div>
);

UnsubscribesSyncToSalesforce.propTypes = {
  items: PropsTypes.array.isRequired,
  onChange: PropsTypes.func.isRequired,
  selected: PropsTypes.string.isRequired,
};

export default UnsubscribesSyncToSalesforce;
