import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoBox from 'components/infoBox';
import TemplateChooser from 'components/templateChooserSimple';
import ContentChooser from 'components/contentChooser';
import './emailComposeInfoBox.scss';

export default class EmailComposeInfoBox extends Component {
  render() {
    return <div className="emailComposeInfoBox">{this._getInfoBox()}</div>;
  }

  _getInfoBox = () => {
    const {
      content,
      contentUploads,
      deleteContent,
      lastTemplateCategory,
      insertContent,
      insertTemplate,
      insertTemplateHidden,
      outsideClickHandler,
      selectedTemplateId,
      templates,
      templatesFavorites,
      type,
      uploadFiles,
    } = this.props;

    switch (type) {
      case 'template-chooser':
        return (
          <InfoBox
            className="template-chooser first-button"
            outsideClickHandler={outsideClickHandler}
          >
            <TemplateChooser
              initCategory={lastTemplateCategory}
              insertTemplate={insertTemplate}
              selectedId={selectedTemplateId}
              templates={templates}
              templatesFavorites={templatesFavorites}
            />
          </InfoBox>
        );
      case 'content-chooser':
        return (
          <InfoBox
            className={`content-chooser ${(insertTemplateHidden && 'first') ||
              'third'}-button`}
            outsideClickHandler={outsideClickHandler}
          >
            <ContentChooser
              content={content}
              contentDelete={deleteContent}
              contentInsert={insertContent}
              uploadFiles={uploadFiles}
              uploads={contentUploads}
            />
          </InfoBox>
        );
      default:
        return null;
    }
  };
}

EmailComposeInfoBox.propTypes = {
  content: PropTypes.array.isRequired,
  contentUploads: PropTypes.array.isRequired,
  deleteContent: PropTypes.func.isRequired,
  insertContent: PropTypes.func.isRequired,
  insertTemplate: PropTypes.func.isRequired,
  insertTemplateHidden: PropTypes.bool.isRequired,
  lastTemplateCategory: PropTypes.string,
  outsideClickHandler: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  templatesFavorites: PropTypes.array.isRequired,
  selectedTemplateId: PropTypes.number,
  type: PropTypes.string,
  uploadFiles: PropTypes.func.isRequired,
};
