import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  disconnectSalesforce,
  connectSalesforce,
} from '../actionCreators/connectionsCustomizationsActionCreators';
import {
  activateApi,
  activateBcc,
} from 'web/salesforce/actionCreators/salesforceActionCreators';
import Popups from '../components/popups';

const SalesforcePopupContainer = (props) => <Popups {...props} />;

const mapStateToProps = (state) => ({
  activityDynamicFieldsSalesforce: state.activityDynamicFieldsSalesforce,
  popup: state.popup,
  popupData: state.popupData,
});

const mapDispatchToProps = (dispatch) => ({
  actionCreators: {
    ...bindActionCreators(
      {
        activateApi,
        activateBcc,
        closePopup,
        connectSalesforce,
        disconnectSalesforce,
      },
      dispatch
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesforcePopupContainer);
