import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import LoadingSpinner from 'components/loadingSpinner';
import ViewMore from 'components/viewMore';
import Email from './email';

const SHOWN_COUNT = 2;

class EmailsCard extends Component {
  render() {
    const { emailId, emails, loading } = this.props;

    return (
      <div className="emails-card flex--column" ref={this._setRef}>
        {loading && <LoadingSpinner imageUrl={getImageUrl} />}
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.person.personDetails.emailsCard.title" />
        </h3>
        {(emails.length && (
          <ViewMore
            getKey={this.getKey}
            itemRenderer={this.itemRenderer}
            items={emails}
            showAll={emailId > 0}
            showCount
            shownCount={SHOWN_COUNT}
            viewMoreOnClick={this.viewMoreOnClick}
          />
        )) || (
          <FormattedMessage id="web.person.personDetails.emailsCard.noEmails" />
        )}
      </div>
    );
  }

  itemRenderer = ({ id, ...other }, index) => {
    const {
      actionCreators: {
        archiveEmail,
        openAddAsTemplate,
        openItemClick,
        openEvents,
        openFollowUp,
        unarchiveEmail,
      },
      emailId,
      user,
    } = this.props;

    return (
      <Email
        {...other}
        addAsTemplate={openAddAsTemplate}
        archiveEmail={archiveEmail}
        className={(index > 0 && 'margin-top-most') || ''}
        id={id}
        key={`emails-card-${id}`}
        openItemClick={openItemClick}
        openEvents={openEvents}
        openFollowUp={openFollowUp}
        scrollToOnChange={emailId === id}
        unarchiveEmail={unarchiveEmail}
        user={user}
      />
    );
  };

  viewMoreOnClick = () =>
    this.props.actionCreators.viewMoreOnClick(PersonDetailsCards.emails);
}

EmailsCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  emailId: PropTypes.number,
  emails: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

EmailsCard.defaultProps = {
  emailId: 0,
  loading: false,
};

export default EmailsCard;
