import queue from 'async/queue';
import { PusherEvents } from 'web/libs/constants';
import PipelineActionTypes from 'web/liveFeed/pipeline/libs/pipelineActionTypes';
import { formatMarketoLeads } from 'web/liveFeed/engage/helpers/engageParsers';
import { getCustomChannel } from 'web/services/pusherService';

const queuedliveFeedCall = queue(({ data = [], dispatch }, cb) => {
  dispatch({
    type: PipelineActionTypes.updateBestBets,
    bestBets: formatMarketoLeads(data),
  });
  cb();
}, 100);

export function initBestBetsPusher() {
  return (dispatch) => {
    const pollingChannel = getCustomChannel('liveFeedChannel');
    pollingChannel.bind(PusherEvents.bestBets, (data) => {
      queuedliveFeedCall.push({ data, dispatch });
      if (formatMarketoLeads(data).length > 0) {
        dispatch({
          type: PipelineActionTypes.clearPipelineErrorMessageId,
        });
      }
    });
  };
}
