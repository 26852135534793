import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import OnboardingActionButtons from 'components/popups/popupFooter/popupFooter';
import { EditIdentityEditorId } from 'web/slideOuts/editIdentity/libs/editIdentitySlideOutConstants';
import {
  removeEditorInstance,
  storeEditorInstance,
  updateEditorBodies,
} from 'web/emailComposeBody/actionCreators/emailComposeActionCreators';
import {
  getEditIdentityForNewUser,
  isSignatureFormDirty,
} from 'web/slideOuts/editIdentity/selectors/editIdentitySelectors';
import { stepsUrls } from 'web/onboarding/lib/stepsRouting';
import { getImageUrl } from 'web/libs/constants';
import { getIdentities } from 'web/user/actionCreators/identitiesActionCreators';
import {
  setCompletedStep,
  removeSkippedStep,
  updateIdentityEmailSignature,
  getOnboardingAuthenticationsAc,
} from 'web/onboarding/actionCreators/onboardingActionCreators';
import { updateOnboardingSettings } from 'web/user/actionCreators/userActionCreators';
import LoadingSpinner from 'components/loadingSpinner';
import onboardingPopupIds from 'web/onboarding/lib/onboardingPopupIds';
import TinyMceEditor from 'web/tinyMceEditor/components/tinyMceEditor';
import Icon from 'components/buttons/icon';
import { stepsNames } from 'web/onboarding/lib/stepsRouting';
import { FormattedMessage } from 'react-intl';
import { navigateToUrl } from 'web/services/routerService';
import { DeliveryChannelProviders } from 'web/deliveryChannels/libs/deliveryChannelsConstants';
import EmailComposePopupContainer from 'web/emailComposeBody/emailComposePopupContainer';

import './emailSignature.scss';

class EmailSignature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successSetup: false,
    };
  }

  componentDidMount() {
    const { getIdentities, getOnboardingAuthenticationsAc } = this.props;

    getIdentities();
    getOnboardingAuthenticationsAc();
  }

  componentWillUnmount() {
    this.props.removeEditorInstance(EditIdentityEditorId);
  }

  render() {
    const {
      openPopup,
      loading,
      identity,
      onboarding: { onboardingAuthentications },
    } = this.props;
    const { preview_signature } = identity;
    const isGmailProvider = onboardingAuthentications.find(
      (item) => item.provider === DeliveryChannelProviders.gmail
    );

    const skipStepPopupData = {
      primaryTextID: 'web.onboarding.popup.skipEmailSignature.textPrimary',
      redirectUrl: stepsUrls.finish,
      secondaryTextId: 'web.onboarding.popup.skipEmailSignature.textSecondary',
    };
    // add appropriate state for this
    const { successSetup } = this.state;
    const isFinishPage = location.hash === stepsUrls.finish;

    if (!loading && successSetup) {
      navigateToUrl(stepsUrls.finish);
    }

    return (
      <React.Fragment>
        {loading ? (
          <div className="onboarding-spinner-wrapper">
            <LoadingSpinner imageUrl={getImageUrl} size={130} />
          </div>
        ) : null}
        {!loading && !successSetup && !isFinishPage ? (
          <div className="email-signature-wrapper">
            {isGmailProvider && (
              <div className="marketo-campaigns-info-null">
                <div className="marketo-campaigns-tip-icon">
                  <Icon classes="tip-icon" />
                </div>
                <div className="marketo-campaigns-info-tip-message">
                  <FormattedMessage id="web.onboarding.email.signature.content" />
                </div>
              </div>
            )}
            <EmailComposePopupContainer showHint={false} />
            <TinyMceEditor
              handleEditorChange={(body) =>
                this.props.updateEditorBodies(EditIdentityEditorId, body)
              }
              id={EditIdentityEditorId}
              name={EditIdentityEditorId}
              options={{ disableAddContent: true }}
              storeEditorInstance={(instance) =>
                this.props.storeEditorInstance(EditIdentityEditorId, instance)
              }
              value={preview_signature}
            />

            <OnboardingActionButtons
              className="email-signature-action-buttons"
              onPrimary={this.saveSignature}
              onSecondary={() =>
                openPopup(onboardingPopupIds.skipStep, skipStepPopupData)
              }
              primaryTextId={'common.save'}
              secondaryTextId={'common.skip'}
              full
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  saveSignature = () => {
    const {
      identity,
      updateIdentityEmailSignature,
      ckeBody,
      updateOnboardingSettings,
      setCompletedStep,
      removeSkippedStep,
    } = this.props;

    const { preview_signature } = identity;
    const newIdentity = {
      ...identity,
      signature: ckeBody || preview_signature,
    };

    updateIdentityEmailSignature(newIdentity).then(() => {
      this.setState({ successSetup: true });
    });

    updateOnboardingSettings(stepsNames.emailSignature);
    setCompletedStep(3);
    removeSkippedStep(3);
  };
}

EmailSignature.propTypes = {
  ckeBody: PropTypes.string,
  getIdentities: PropTypes.func.isRequired,
  getOnboardingAuthenticationsAc: PropTypes.func.isRequired,
  identity: PropTypes.object,
  loading: PropTypes.bool,
  onboarding: PropTypes.object,
  openPopup: PropTypes.func.isRequired,
  removeEditorInstance: PropTypes.func.isRequired,
  removeSkippedStep: PropTypes.func.isRequired,
  setCompletedStep: PropTypes.func.isRequired,
  signature: PropTypes.string,
  storeEditorInstance: PropTypes.func.isRequired,
  updateEditorBodies: PropTypes.func.isRequired,
  updateIdentityEmailSignature: PropTypes.func.isRequired,
  updateOnboardingSettings: PropTypes.func.isRequired,
  userSettings: PropTypes.object,
};

EmailSignature.defaultProps = {
  ckeBody: '',
  identity: {},
  loading: false,
  signature: '',
  userSettings: {},
};

const mapStateToProps = (state) => ({
  ckeBody: state.emailComposeCkeBodies[EditIdentityEditorId],
  identity: getEditIdentityForNewUser(state),
  isSignatureFormDirty: isSignatureFormDirty(state),
  loading: false,
  onboarding: state.onboarding,
  userSettings: state.userSettings,
});

const mapDispatchToProps = {
  getIdentities,
  getOnboardingAuthenticationsAc,
  openPopup,
  removeEditorInstance,
  removeSkippedStep,
  setCompletedStep,
  storeEditorInstance,
  updateEditorBodies,
  updateIdentityEmailSignature,
  updateOnboardingSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailSignature);
