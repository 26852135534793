import { bindActionCreators } from 'redux';
import { onClearAll } from 'table/modules/selectable/selectableActionCreators';
import { getSelectableSelectedItems } from 'table/modules/selectable/selectableSelectors';

let getReduxState;
let dispatch;

const getExternals = (storeDispatch, thunkGetState) => {
  dispatch = storeDispatch;
  getReduxState = thunkGetState;

  return {
    ...bindActionCreators(
      {
        clearSelectedIds: onClearAll,
      },
      dispatch
    ),
    getSelectedItems: () => getSelectableSelectedItems(getReduxState()),
  };
};

export const getState = () => getReduxState();

export default getExternals;
