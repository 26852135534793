import React from 'react';
import PropsTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import lowerFirst from 'lodash/lowerFirst';

const UnsubscribeFormSuccessMessage = ({ unsubscribedFrom }) => (
  <div className="unsubscribe-form-success-message">
    <h3>
      <FormattedMessage id="web.settings.unsubscribe.message.sorry" />
    </h3>
    <p>
      <FormattedMessage id="web.settings.unsubscribe.message.successUnsubscribe" />
      <strong>{lowerFirst(unsubscribedFrom)}</strong>
    </p>
  </div>
);

UnsubscribeFormSuccessMessage.defaultProps = {
  unsubscribedFrom: null,
};

UnsubscribeFormSuccessMessage.propTypes = {
  unsubscribedFrom: PropsTypes.string,
};

export default UnsubscribeFormSuccessMessage;
