import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import TableExternals from 'web/templates/libs/templatesTableExternals';
import { EditTemplatePopupIds } from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutConstants';
import { hasChanges } from 'web/slideOuts/editTemplate/helpers/editTemplateHasChangesHelper';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { navigateToTemplatesTab } from 'web/services/routerService';
import { TEMPLATES_CATEGORY_MAP } from 'web/templates/helpers/templatesHelper';

export const setTableSort = (column, direction) => ({
  type: TemplatesActionTypes.table.sortInfo,
  column,
  direction,
});

export const setTableFilter = (filters) => ({
  type: TemplatesActionTypes.table.filter,
  filters,
});

export const setTablePage = (page) => ({
  type: TemplatesActionTypes.table.page,
  page,
});

export const setTablePerPage = (perPage) => ({
  type: TemplatesActionTypes.table.perPage,
  perPage,
});

export const clearSelectedIds = () => {
  const templatesTableExternals = TableExternals.get();
  if (templatesTableExternals.clearSelectedIds) {
    templatesTableExternals.clearSelectedIds();
  }
};

export const resetTable = () => ({
  type: TemplatesActionTypes.table.reset,
});

export const openTemplateDetails = (template) => (dispatch, getState) => {
  const { templatesSelectedTemplateCategoryId } = getState();
  const { category_id: templateCategoryId } = template;
  const categoryId =
    TEMPLATES_CATEGORY_MAP[templatesSelectedTemplateCategoryId] ||
    templateCategoryId;
  if (hasChanges(getState())) {
    dispatch(
      openPopup(EditTemplatePopupIds.hasChanges, {
        callback: 'navigateToTemplatesTab',
        args: [template, categoryId],
      })
    );
  } else {
    navigateToTemplatesTab(template, categoryId);
  }
};
