import { destroyEditState } from 'web/task/actionCreators/taskActionCreators';
import {
  taskCreate,
  taskDelete,
  taskUpdate,
} from 'web/tasks/actionCreators/tasksActionCreators';
import { parseEditStateToApi } from 'web/task/helpers/parsers';
import { getSource, getTaskMode, track } from 'web/services/mixpanelService';
import { TaskEvents, TaskProperties } from 'web/libs/mixpanelEvents';

/* Haven't figured out how to unit test returning a dispatch called on a promise */
export function saveTask() {
  return (dispatch, getState) => {
    const { taskEditState, taskOriginal } = getState();

    const ac = taskOriginal.id ? taskUpdate : taskCreate;
    return dispatch(ac(parseEditStateToApi(taskEditState, taskOriginal))).then(
      (data) => {
        dispatch(destroyEditState());
        track((taskOriginal.id && TaskEvents.edit) || TaskEvents.create, {
          Mode: getTaskMode(),
          Source: getSource(getState().modalsTaskSourceOpener),
          'Task Type': data.remind_type,
        });
        return data;
      }
    ); // let parent handle catch
  };
}

export function deleteTask() {
  return (dispatch, getState) => {
    const { taskEditState } = getState();

    return dispatch(taskDelete(taskEditState)).then((task) => {
      dispatch(destroyEditState());
      track(TaskEvents.complete, {
        'Action Type': TaskProperties.actionType.delete,
        'Bulk Action Enabled': false,
        'Campaign Task':
          (task &&
            task.workflow_details &&
            task.workflow_details.workflow_id &&
            true) ||
          false,
        Source: getSource(getState().modalsTaskSourceOpener),
        'Task Type': task.remind_type,
      });
      return task;
    }); // let parent handle catch
  };
}
