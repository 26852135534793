import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget as dropTarget } from 'react-dnd';
import classnames from 'classnames';
import { ColumnOrderDragType } from 'table/modules/constants';

const columnHeaderTarget = {
  drop(props, monitor) {
    const { columnId, changeColumnOrderHandler } = props;
    const item = monitor.getItem();
    changeColumnOrderHandler(item.columnId, columnId);
  },
};

function collect(connect, monitor) {
  const item = monitor.getItem();
  return {
    connectDropTarget: connect.dropTarget(),
    isDragging: !!item,
    isOver: monitor.isOver(),
  };
}

class HeaderColumnDropZone extends Component {
  constructor() {
    super();
    this.state = { showDropZone: false };
  }

  switchTableToDraggingMode = (isDragging) => {
    const { parentTable } = this.props;

    if (parentTable) {
      const draggingModeClass = 'dragging-mode';
      if (isDragging && !parentTable.classList.contains(draggingModeClass)) {
        parentTable.classList.add(draggingModeClass);
      } else if (!isDragging) {
        parentTable.classList.remove(draggingModeClass);
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { isDragging } = this.props;
    if (isDragging !== prevProps.isDragging) {
      this.switchTableToDraggingMode(isDragging);
    }
  }

  changeShowDropZoneState = (isDragging) => {
    const { showDropZone } = this.state;
    if (isDragging !== showDropZone) {
      setTimeout(() => {
        this.setState({ showDropZone: isDragging });
      }, 0);
    }
  };

  getColumnWidth = (columnId) => {
    const outsideSpace = 100;
    if (columnId) {
      const column = document.querySelector(
        `div[data-column-id="${columnId}"]`
      );
      if (column) {
        return parseInt(column.parentNode.parentNode.style.width);
      }
    }

    return outsideSpace;
  };

  getDropZonePlace = () => {
    const { columnId, previousColumnId, isOver } = this.props;
    const dragSpace = 10;
    const currentColumnWidth = this.getColumnWidth(columnId);
    const previousColumnWidth = this.getColumnWidth(previousColumnId);

    const dropZoneWidth =
      Math.floor(currentColumnWidth / 2) +
      Math.floor(previousColumnWidth / 2) -
      dragSpace;
    const dropZoneLeft =
      Math.floor(previousColumnWidth / 2) - Math.floor(dragSpace / 2);

    return (
      <div
        className={classnames('drop-zone-place', {
          'over-drop-zone': isOver,
        })}
        style={{
          marginLeft: `-${dropZoneLeft}px`,
          width: `${dropZoneWidth}px`,
        }}
      />
    );
  };

  render() {
    const { isDragging, connectDropTarget, isOver } = this.props;
    const { showDropZone } = this.state;
    this.changeShowDropZoneState(isDragging);

    let dropZonePlace = null;
    if (showDropZone) {
      dropZonePlace = this.getDropZonePlace();
    }

    return connectDropTarget(
      <div
        className={classnames('header-column-drop-zone', {
          dragging: isDragging,
        })}
      >
        {showDropZone && dropZonePlace}
        {isOver && <div className="drop-line" />}
      </div>
    );
  }
}

HeaderColumnDropZone.propTypes = {
  canDrop: PropTypes.bool,
  changeColumnOrderHandler: PropTypes.func,
  columnId: PropTypes.string,
  connectDropTarget: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  parentTable: PropTypes.instanceOf(Element),
  previousColumnId: PropTypes.string,
};

HeaderColumnDropZone.defaultProps = {
  canDrop: false,
  changeColumnOrderHandler: () => {},
  columnId: undefined,
  parentTable: null,
  previousColumnId: undefined,
};

export default dropTarget(ColumnOrderDragType, columnHeaderTarget, collect)(
  HeaderColumnDropZone
);
