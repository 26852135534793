/*
  To disable browser email/password autofill past this component
  into form as first child
*/

import React from 'react';
import InputText from 'components/inputText/inputText';

const styles = {
  opacity: 0,
  position: 'absolute',
  height: 0,
  width: 0,
  padding: 0,
  margin: 0,
};

const PreventBrowserInputAutofill = () => (
  <div style={styles}>
    <InputText type="text" style={styles} />
    <InputText type="password" style={styles} />
  </div>
);

export default PreventBrowserInputAutofill;
