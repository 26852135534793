import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup, { PopupBackgroundColors } from 'components/popups';
import { PopupSize } from 'libs/constantsShared';
import TeamCreateForm from './createTeamForm';
import { getTeamChangeErrorTextId } from '../../../helpers/teamManagementHelpers';
import { TEAM_CREATE_FORM_ID } from '../../../libs/teamsConstants';
import { getImageUrl } from 'web/libs/constants';

class CreateTeamPopup extends Component {
  render() {
    const {
      actionCreators: { closePopup },
      isPristineCreateTeamForm,
      createTeamLoading,
    } = this.props;

    return (
      <Popup
        alert={this.getAlert()}
        footer={{
          onPrimary: this.submitForm,
          onSecondary: closePopup,
          primaryTextId: 'common.create',
          secondaryTextId: 'common.cancel',
          primaryDisabled: isPristineCreateTeamForm,
        }}
        header={{
          onClose: closePopup,
          textId:
            'web.settings.adminSettings.teamManagement.teamMembers.createNewTeam',
        }}
        getImageUrl={getImageUrl}
        loading={createTeamLoading}
        onOutsideClick={closePopup}
        size={PopupSize.small}
      >
        <TeamCreateForm form={TEAM_CREATE_FORM_ID} {...this.props} />
      </Popup>
    );
  }

  getAlert = () => {
    const {
      popupData: { errors },
      shouldShowAlert,
      clearAlert,
    } = this.props;

    return shouldShowAlert
      ? {
          color: PopupBackgroundColors.red,
          onClose: clearAlert,
          textId: getTeamChangeErrorTextId(errors),
        }
      : null;
  };

  submitForm = () => {
    const {
      actionCreators: { submitForm },
    } = this.props;
    submitForm(TEAM_CREATE_FORM_ID);
  };
}

CreateTeamPopup.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  popupData: PropTypes.object,
  shouldShowAlert: PropTypes.bool.isRequired,
  clearAlert: PropTypes.func.isRequired,
};

CreateTeamPopup.defaultProps = {
  popupData: {},
};

export default CreateTeamPopup;
