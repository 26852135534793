import { Urls } from 'web/libs/constants';
import { postFile, baseGet, basePut } from 'web/services/api';

export function uploadFile(file, progress) {
  return new Promise((resolve, reject) => {
    postFile(Urls.fileUpload, file, progress, resolve, reject);
  });
}

export function updateCsvMappings(fileId, data) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.importPeopleCsvMeta.replace(':file_id', fileId),
      data,
      resolve,
      reject
    );
  });
}

export function getAvailableMapFields(fileId) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.importPeopleCsvMeta.replace(':file_id', fileId),
      { _: Date.now() },
      resolve,
      reject
    );
  });
}

export function readFileAsText(inputFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new Error('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsText(inputFile);
  });
}
