import PeopleActionTypes from 'web/people/libs/peopleActionTypes';
import {
  closePopup,
  setPopupAlert,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import {
  getAllPeopleGroups,
  getEmailIds,
  getGroup,
} from 'web/people/selectors/peopleSelectors';
import {
  createPeopleGroup,
  bulkAddPeopleToGroup,
  updatePeopleGroup as updatePeopleGroupCall,
  deletePeopleGroup as deletePeopleGroupCall,
} from 'web/people/services/peopleGroupService';
import {
  updateGroup,
  removeGroup,
  getGroups,
} from 'web/groups/actionCreators/groupsActionCreators';
import PeopleAlertIds from 'web/people/libs/peopleAlertIds';
import { navigateToGroup } from 'web/services/routerService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { validateGroupName } from 'web/people/helpers/peopleHelpers';
import { getPeople, getPeopleIdsByGroup } from './peopleFetchActionCreators';
import { openAddGroupToCampaign } from 'web/people/actionCreators/peoplePopupActionCreators';
import { PEOPLE_GENERAL_GROUPS } from 'web/people/libs/peopleConstants';
import { updateGroupSharedWith } from 'web/people/services/groupSharingService';
import { bulkRemoveFromGroups } from 'web/groups/services/groupsService';

export const clearAddNewGroupForm = () => ({
  type: PeopleActionTypes.group.clearAddNewGroupForm,
});

export const setPeopleAddNewGroupName = (name) => ({
  name,
  type: PeopleActionTypes.group.setPeopleAddNewGroupName,
});

export const setPeopleAddNewGroupDescription = (description) => ({
  description,
  type: PeopleActionTypes.group.setPeopleAddNewGroupDescription,
});

export const createNewGroupAction = () => (dispatch, getState) => {
  const state = getState();
  const {
    peopleNewGroupName,
    peopleNewGroupDescription,
    user: { id: userId },
  } = state;
  const allPeopleGroups = getAllPeopleGroups(state);
  const error = validateGroupName(peopleNewGroupName, allPeopleGroups, userId);

  if (error) {
    dispatch(setPopupAlert(error.id, error.textValues));
    return;
  }

  dispatch(setPopupLoading(true));
  createPeopleGroup(peopleNewGroupName.trim(), peopleNewGroupDescription.trim())
    .then((newGroup) => {
      dispatch(setPopupLoading(false));
      dispatch(setPeopleAddNewGroupName(''));
      dispatch(setPeopleAddNewGroupDescription(''));
      dispatch(updateGroup(newGroup));
      dispatch(closePopup());
      navigateToGroup(newGroup.id);
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(PeopleAlertIds.createGroupError));
    });
};

export const setPeopleGroupDescription = (groupId, newDescription) => (
  dispatch,
  getState
) => {
  const state = getState();
  const group = getGroup(groupId)(state);
  const oldName = group && group.name;
  const oldDescription = group && group.description;

  if (oldDescription === newDescription) {
    return false;
  }

  updatePeopleGroupCall(groupId, {
    description: newDescription,
    name: oldName,
  }).then(() => {
    dispatch(
      updateGroup({
        ...group,
        description: newDescription,
        name: oldName,
      })
    );
  });
};

export const editGroupAction = (groupId) => async (dispatch, getState) => {
  const state = getState();
  const {
    peopleNewGroupName: newName,
    peopleNewGroupDescription: newDescription,
    user: { id: userId },
  } = state;
  const group = getGroup(groupId)(state);
  const oldName = group && group.name;
  const oldDescription = group && group.description;

  if (oldName === newName && oldDescription === newDescription) {
    dispatch(closePopup());
    return false;
  }

  if (oldName !== newName) {
    const allPeopleGroups = getAllPeopleGroups(state);
    const error = validateGroupName(newName, allPeopleGroups, userId);
    if (error) {
      dispatch(setPopupAlert(error.id, error.textValues));
      return false;
    }
  }

  dispatch(setPopupLoading(true));
  try {
    await updatePeopleGroupCall(groupId, {
      description: newDescription,
      name: newName,
    });

    dispatch(
      updateGroup({
        ...group,
        description: newDescription,
        name: newName,
      })
    );
    dispatch(closePopup());
  } catch (error) {
    dispatch(setPopupAlert(PeopleAlertIds.editGroupError));
    dispatch(setPopupLoading(false));
    return false;
  }
  dispatch(setPopupLoading(false));
  return true;
};

export const setCurrentGroupId = (groupId) => ({
  groupId,
  type: PeopleActionTypes.sidebar.setPeopleCurrentGroup,
});

export const onSidebarItemClick = (id) => () => {
  navigateToGroup(id);
};

export const setSelectedAddPeopleToGroupPeople = (people) => ({
  people,
  type: PeopleActionTypes.group.setSelectedAddPeopleToGroupPeople,
});

export const setSelectedEmailIdAddPeopleToGroup = (contactEmailId) => ({
  contactEmailId,
  type: PeopleActionTypes.group.setSelectedEmailIdAddPeopleToGroup,
});

export const setSelectedAddPeopleToGroupGroupId = ({ id }) => ({
  id,
  type: PeopleActionTypes.group.setSelectedAddPeopleToGroupGroupId,
});

export const addPeopleToGroupAction = () => (dispatch, getState) => {
  const state = getState();

  dispatch(setPopupLoading(true));

  bulkAddPeopleToGroup(state.selectedAddToGroupGroupId, getEmailIds(state))
    .then(() => {
      dispatch(setPopupLoading(false));
      dispatch(getPeople());
      dispatch(setSelectedAddPeopleToGroupPeople([]));
      dispatch(closePopup());
      dispatch(openViewAlert(PeopleAlertIds.addPeopleToGroupSuccess));
    })
    .catch(() => {
      dispatch(setPopupLoading(false));
      dispatch(setPopupAlert(PeopleAlertIds.addPeopleToGroupError));
    });
};

export const removePeopleFromGroupAction = (peopleIds, onSuccess) => (
  dispatch,
  getState
) => {
  const { peopleCurrentGroupId } = getState();
  dispatch(setPopupLoading(true));
  const payload = peopleIds.map((personId) => ({
    group_id: peopleCurrentGroupId,
    person_id: personId,
  }));

  bulkRemoveFromGroups(payload)
    .then(() => {
      dispatch(getPeople());
      dispatch(closePopup());
      dispatch(openViewAlert(PeopleAlertIds.removePeopleFromGroupSuccess));
      onSuccess();
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.removePeopleFromGroupError));
    })
    .finally(() => {
      dispatch(setPopupLoading(false));
    });
};

export const onSelectedEmailChange = (value, { id }) => (dispatch) => {
  dispatch(setSelectedEmailIdAddPeopleToGroup({ emailId: value, id }));
};

export function deleteGroupConfirm(id) {
  return (dispatch, getState) => {
    if (!id) {
      return;
    }

    dispatch(setPopupLoading(true));

    deletePeopleGroupCall(id)
      .then(() => {
        const { peopleCurrentGroupId } = getState();

        dispatch(setPopupLoading(false));
        dispatch(closePopup());
        dispatch(removeGroup(id));

        if (
          peopleCurrentGroupId &&
          peopleCurrentGroupId.toString() === id.toString()
        ) {
          navigateToGroup(PEOPLE_GENERAL_GROUPS.everyone.id);
        }
      })
      .catch(() => {
        dispatch(setPopupAlert(PeopleAlertIds.deleteGroupError));
        dispatch(setPopupLoading(false));
      });
  };
}

export const openAddGroupToCampaignHandler = (groupId) => async (dispatch) => {
  const peopleIds = await dispatch(getPeopleIdsByGroup(groupId));
  dispatch(openAddGroupToCampaign(peopleIds));
};

export const updateGroupShareTeamIds = (ids) => ({
  ids,
  type: PeopleActionTypes.group.setGroupShareTeamIds,
});

export const updateShareGroupComment = (value) => ({
  type: PeopleActionTypes.group.setShareGroupComment,
  value,
});

export const setShareGroup = (group) => ({
  group,
  type: PeopleActionTypes.group.setShareGroup,
});

export const shareGroupConfirm = () => (dispatch, getState) => {
  dispatch(setPopupLoading(true));
  const {
    shareGroupComment,
    shareGroupTeamIds,
    shareGroup: { id: groupId },
    peopleCurrentGroupId,
  } = getState();

  updateGroupSharedWith(groupId, {
    comment: shareGroupComment,
    team_ids: shareGroupTeamIds,
  })
    .then((group) => {
      dispatch(getGroups(true));

      if (group.id === peopleCurrentGroupId) {
        dispatch(getPeople());
      }

      dispatch(setPopupLoading(false));
      dispatch(closePopup());
    })
    .catch(() => {
      dispatch(setPopupAlert(PeopleAlertIds.shareGroupError));
      dispatch(setPopupLoading(false));
    });
};
