import { createSelector } from 'reselect';
import { getSmartFilterById } from 'web/commandCenter/modules/emailGridAdvancedSearch/selectors/emailGridAdvancedSearchSelectors';
import { VIEW_AS_FILTER_ID } from 'web/commandCenter/modules/whoSmartFilter/libs/whoSmartFilterConstants';

export const advancedSearchViewAsDefaultFilterValue = (state) =>
  state.sidebarViewer;

export const getSelectedUserForViewAsFilter = createSelector(
  [
    getSmartFilterById(VIEW_AS_FILTER_ID),
    advancedSearchViewAsDefaultFilterValue,
  ],
  (filter, defaultValue) => (filter && filter.viewAsViewer) || defaultValue
);
