/*eslint react/no-unused-prop-types: 0*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CreateAccount from '../components/createAccount';
import CreateAccountOnboarding from 'web/createAccount/components/createAccountOnboarding';
import CreateAccountFromSF from 'web/createAccount/components/createAccountFromSF';
import NotValidInvitation from 'web/createAccount/components/notValidInvitation/notValidInvitation';
import NotValidInvitationOnboarding from 'web/createAccount/components/notValidInvitationOnboarding/notValidInvitationOnboarding';
import {
  setLoading,
  createAccountRequest,
  createAccountActivationRequest,
  createEmailAdminInvitation,
  createSalesforceAuthRequest,
  checkActivationCode,
} from '../actionCreators/createAccountActionCreators';
import { updateOnboardingSettings } from 'web/user/actionCreators/userActionCreators';
import unauthorizedAdobePage from 'components/hocs/unauthorizedAdobePage';
import onboardingPage from 'components/hocs/onboardingPage';
import {
  email,
  firstName,
  lastName,
  isInvitationValid,
  isOnboardingMode,
  createNewAccountFromSF,
} from '../helpers/createAccountHelper';

const CreateAccountContainer = (props) => {
  const [invitationEmail, setInvitationEmail] = useState('');
  const [isActivationCodeValid, setIsActivationCodeValid] = useState(false);

  const {
    actionCreators: { checkActivationCode, createEmailAdminInvitation },
    match: {
      params: {
        invitation_code: invitationCode,
        activation_code: activationCode,
      },
    },
  } = props;

  useEffect(() => {
    if (invitationCode) {
      createEmailAdminInvitation(invitationCode).then((adminEmail) =>
        setInvitationEmail(adminEmail)
      );
    } else if (activationCode) {
      checkActivationCode(activationCode).then((isValid) => {
        setIsActivationCodeValid(isValid);
      });
    }
  }, []);

  return (
    <div className="create-account-container">
      {isInvitationValid() || isActivationCodeValid
        ? getCreateAccountComponent(props)
        : getNotValidInvitationComponent(invitationEmail)}
    </div>
  );
};

const getNotValidInvitationComponent = (invitationEmail) =>
  isOnboardingMode ? (
    <NotValidInvitationOnboarding adminEmail={invitationEmail} />
  ) : (
    <NotValidInvitation />
  );

const getCreateAccountComponent = (props) => {
  if (isOnboardingMode) {
    return createNewAccountFromSF ? (
      <CreateAccountFromSF {...props} />
    ) : (
      <CreateAccountOnboarding
        initialValues={{
          email,
          first_name: firstName,
          last_name: lastName,
        }}
        {...props}
      />
    );
  } else {
    return (
      <CreateAccount
        initialValues={{
          email,
          first_name: firstName,
          last_name: lastName,
        }}
        {...props}
      />
    );
  }
};

const validInvitationParams = {
  cardWidth: 500,
};

const invalidInvitationParams = {
  cardWidth: 900,
};

const initialParams = isInvitationValid()
  ? validInvitationParams
  : invalidInvitationParams;

CreateAccountContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  isAccountCreatedFromSF: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      invitation_code: PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state) => ({
  isAccountCreatedFromSF: state.isAccountCreatedFromSF,
  loading: state.createAccountRequestLoading,
});

const mapDispatchToProps = {
  checkActivationCode,
  createAccountActivationRequest,
  createAccountRequest,
  createEmailAdminInvitation,
  createSalesforceAuthRequest,
  setLoading,
  updateOnboardingSettings,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  (component) =>
    isOnboardingMode
      ? onboardingPage(component)
      : unauthorizedAdobePage(component, {
          titleKey: 'common.signup',
          ...initialParams,
        })
)(CreateAccountContainer);
