import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Details from './details';
import Editing from './editing';
import './slideOutPaneHeader.scss';

class SlideOutPaneHeader extends PureComponent {
  state = {
    isEditing: false,
  };

  render() {
    const {
      className,
      rightChildren,
      leftChildren,
      saveEdit,
      text,
    } = this.props;
    const { isEditing } = this.state;

    return (
      <div className={classNames('slide-out-pane-header', className)}>
        {(isEditing && (
          <Editing cancel={this.edit} name={text} save={this.save} />
        )) || (
          <Details
            edit={saveEdit && this.edit}
            leftChildren={leftChildren}
            rightChildren={rightChildren}
            text={text}
          />
        )}
      </div>
    );
  }

  edit = () => {
    this.setState(({ isEditing }) => ({ isEditing: !isEditing }));
  };

  save = (name) => {
    this.edit();
    this.props.saveEdit(name);
  };
}

SlideOutPaneHeader.propTypes = {
  className: PropTypes.string,
  rightChildren: PropTypes.node,
  leftChildren: PropTypes.node,
  saveEdit: PropTypes.func,
  text: PropTypes.string,
};

SlideOutPaneHeader.defaultProps = {
  className: '',
  rightChildren: undefined,
  leftChildren: undefined,
  saveEdit: undefined,
  text: '',
};

export default SlideOutPaneHeader;
