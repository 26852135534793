import ActionTypes from 'web/libs/actionTypes/actionTypes';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';

export function openModal(id, data) {
  return {
    data,
    id,
    type: ActionTypes.modal.state.open,
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.modal.state.close });
    dispatch(closePopup());
  };
}
