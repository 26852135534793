import { EditTemplateCards } from '../libs/editTemplateSlideOutConstants';

export const isSubjectMissing = (cardId = '', state = {}) => {
  if (cardId === EditTemplateCards.template) {
    const { subject = '' } = state.editTemplateSlideOutTemplateEditState || {
      subject: '',
    };
    return !subject.length;
  } else {
    return false;
  }
};

const enableMappingsHasChanges = (orig, checked, value) => {
  if (orig === 0) {
    return checked;
  } else if (!checked) {
    return true;
  } else {
    return orig !== value;
  }
};

export const recommendCardHasChanges = (
  template,
  { enableMappingsChecked, enableMappingsValue, mappings = [] }
) => {
  if (
    enableMappingsHasChanges(
      template.enable_mappings,
      enableMappingsChecked,
      enableMappingsValue
    ) ||
    template.template_mappings.length !== mappings.length
  ) {
    return true;
  } else {
    for (let i = 0; i < mappings.length; i++) {
      const current = mappings[i];
      const orig = template.template_mappings[i];
      if (
        !current.id ||
        current.id !== orig.id ||
        current.field !== orig.field ||
        current.condition !== orig.condition ||
        current.value !== orig.value
      ) {
        return true;
      }
    }

    return false;
  }
};

// Check for mappings that are either missing field or value but not if missing both
export const recommendedCardFieldsMissing = ({ mappings = [] } = {}) =>
  mappings.some(({ field, value }) => !field || !value);

/* Can't get around ckeditor.getDate() and template.body ever matching so showing changes if editing template */
export const hasChanges = (state) => {
  const template = state.editTemplateSlideOutTemplate;
  const editing = state.editTemplateSlideOutEditing;
  if (
    template.id &&
    (editing[EditTemplateCards.template] ||
      (editing[EditTemplateCards.recommend] &&
        recommendCardHasChanges(
          template,
          state.editTemplateSlideOutRecommendEditState
        )))
  ) {
    return true;
  } else {
    return false;
  }
};
