import {
  getTeamChannel,
  getUserChannel,
  initPusherService,
} from 'web/services/pusherService';
import { PusherEvents } from 'web/libs/constants';
import { updateWorkflowInstance } from 'web/campaigns/people/actionCreators/campaignsPeopleActionCreators';
import {
  pusherWorkflowUpdated,
  pusherCampaignMetricsUpdated,
} from './campaignsActionCreators';
import queue from 'async/queue';

const queuedUpdateCall = queue(({ data, dispatch }, cb) => {
  dispatch(pusherWorkflowUpdated(data));
  cb();
}, 100);

const queuedInstanceUpdateCall = queue(({ data, dispatch }, cb) => {
  dispatch(updateWorkflowInstance(data));
  cb();
}, 100);

const queuedMetricUpdateCall = queue(({ data, dispatch }, cb) => {
  dispatch(pusherCampaignMetricsUpdated(data));
  cb();
}, 100);

export function initPusher() {
  return (dispatch, getState) => {
    const { user } = getState();
    initPusherService(user.id, user.subscription.id);
    getTeamChannel().bind(PusherEvents.workflowMetricUpdate, (data) =>
      queuedMetricUpdateCall.push({ data, dispatch })
    );
    getTeamChannel().bind(PusherEvents.workflowUpdate, (data) =>
      queuedUpdateCall.push({ data, dispatch })
    );
    getUserChannel().bind(PusherEvents.workflowInstanceUpdate, (data) =>
      queuedInstanceUpdateCall.push({ data, dispatch })
    );
  };
}
