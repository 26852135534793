import { commonUserAdminTeamPermission } from 'web/shared/services/accountService';
import { basePost } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import get from 'lodash/get';

export function canShareTemplate(template = {}) {
  const account = window.toutAppBootstrap.accountData;
  const sharedEnabled = get(
    account,
    'subscription.template_share_enabled',
    false
  );
  return commonUserAdminTeamPermission(template.user_id) && sharedEnabled;
}

export function canUnshareTemplate(template = {}) {
  const account = window.toutAppBootstrap.accountData;
  const sharedEnabled = get(
    account,
    'subscription.template_share_enabled',
    false
  );
  return commonUserAdminTeamPermission(template.user_id) && sharedEnabled;
}

export function canEditRecommendedTemplate(template = {}) {
  const account = window.toutAppBootstrap.accountData;
  const editEnabled = get(
    account,
    'subscription.template_recommend_enabled',
    false
  );
  return commonUserAdminTeamPermission(template.user_id) && editEnabled;
}

export const updateTemplateSharedWith = (id, ids = []) =>
  new Promise((resolve, reject) => {
    basePost(
      Urls.templateUpdateSharedWith.replace(':#id', id),
      { team_ids: ids },
      resolve,
      reject
    );
  });
