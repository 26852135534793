import {
  openPopup,
  setPopupLoading,
} from 'web/popup/actionCreators/popupActionCreators';
import { inviteNewUsers } from '../services/chooseMarketoWorkspacesService';
import { ChooseMarketoWorkspacesPopupid } from '../libs/chooseMarketoWorkspacesConstants';
import {
  closePopup,
  getWorkspaces,
} from 'web/marketoSettings/actionCreators/adminPopupActionCreators';
import { commonStartup } from 'web/shared/actionCreators/commonStartupActionCreators';

export const startup = (emails, success, failure) => (dispatch) => {
  dispatch(commonStartup());
  dispatch(getWorkspaces());
  dispatch(
    openPopup(ChooseMarketoWorkspacesPopupid, { emails, success, failure })
  );
};

export const setWorkspaces = () => (dispatch, getState) => {
  const data = getState().popupData;
  const selectedWorkspaces = getState().marketoAdminSelectedWorkspaces;
  const workspaceIds = getState()
    .marketoAdminWorkspaces.filter((workspace) =>
      selectedWorkspaces.includes(workspace.name)
    )
    .map((workspace) => workspace.id);
  dispatch(setPopupLoading(true));
  inviteNewUsers(data.emails, workspaceIds)
    .then((response) => {
      dispatch(setPopupLoading(false));
      dispatch(closePopup());
      data.success(response);
    })
    .catch((errors) => {
      dispatch(setPopupLoading(false));
      dispatch(closePopup());
      data.failure(errors);
    });
};
