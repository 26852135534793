import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Button, { ButtonSizes } from 'components/buttons/action';
import { productTourSteps } from 'web/productTour/containers/productTourStepsContainer/lib/productTourConstants';
import './step2.scss';

const Step2 = (props) => {
  const { changeTab, productTourLoadedImages } = props;
  const imgURL = productTourLoadedImages.length
    ? `url(${productTourLoadedImages[1]})`
    : `url(${getImageUrl('productTour/1920x1080/step2', 'png')})`;

  return (
    <div className="productTour-step2-rightColumn-wrapper">
      <div
        className="productTour-step2-screen"
        style={{
          backgroundImage: imgURL,
        }}
      />
      <div className="productTour-step2-content">
        <h1>
          <FormattedHTMLMessage id={'web.productTour.automateWorkflow'} />
        </h1>
        <div>
          <FormattedHTMLMessage
            id={'web.productTour.firstTouchCampingContent'}
          />
        </div>
      </div>
      <Button
        onClick={() => changeTab(productTourSteps.step3)}
        size={ButtonSizes.medium}
      >
        <FormattedMessage id="common.next" />
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productTourLoadedImages: state.productTourLoadedImages,
});

const mapDispatchToProps = {};

Step2.defaultProps = {
  changeTab: () => {},
};

Step2.propTypes = {
  changeTab: PropTypes.func,
  productTourLoadedImages: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2);
