import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { PitchStates } from 'web/emails/libs/emailsConstants';
import { ColorClasses } from 'libs/constantsStyles';
import { isCurrentUser } from 'web/user/services/userService';
import OptionDotaInfobox from 'components/optionDotas/infobox';
import Stats from '../stats';
import './emailsSubjectStats.scss';
import { getTemplatesEditingDisabledFlag } from 'web/settings/adminSettings/general/helpers/generalPageHelpers.js';

const showOptions = (state) => {
  switch (state) {
    case PitchStates.bounced:
    case PitchStates.deliveryInProgress:
    case PitchStates.draft:
    case PitchStates.enqueuedForDelivery:
    case PitchStates.failedDelivery:
    case PitchStates.scheduledForDelivery:
    case PitchStates.spam:
      return false;
    default:
      return true;
  }
};

const getOptions = ({
  addAsTemplate,
  archived,
  archiveEmail,
  id,
  intl: { formatMessage },
  openFollowUp,
  pitchState,
  unarchiveEmail,
  user,
  userId,
}) => {
  if (showOptions(pitchState)) {
    if (archived) {
      return [
        {
          label: formatMessage({ id: 'common.unarchive' }),
          onClick: unarchiveEmail,
          value: id,
        },
      ];
    }

    const { admin, subscription } = user;
    const canAddAsTemplate =
      admin || !getTemplatesEditingDisabledFlag(subscription);
    const options = [
      {
        label: formatMessage({ id: 'common.archive' }),
        onClick: archiveEmail,
        value: id,
      },
    ];

    if (canAddAsTemplate) {
      options.unshift({
        label: formatMessage({ id: 'common.addAsTemplate' }),
        onClick: addAsTemplate,
        value: id,
      });
    }

    if (isCurrentUser(user, userId)) {
      options.unshift({
        label: formatMessage({ id: 'web.emails.emailsSubjectStats.followUp' }),
        onClick: openFollowUp,
        value: id,
      });
    }

    return options;
  } else {
    return null;
  }
};

const getStatusComponent = ({
  archived,
  clicks,
  id,
  openEvents,
  pitchState,
  replies,
  succeeded,
  views,
}) => {
  //eslint-disable-line react/prop-types
  let color = ColorClasses.yellow;
  let textId = '';
  switch (pitchState) {
    case PitchStates.bounced:
      color = ColorClasses.red;
      textId = 'common.bounced';
      break;
    case PitchStates.deliveryInProgress:
      textId = 'web.emails.emailsSubjectStats.deliveryInProgress';
      break;
    case PitchStates.draft:
      textId = 'common.draft';
      break;
    case PitchStates.enqueuedForDelivery:
      textId = 'web.emails.emailsSubjectStats.enqueuedForDelivery';
      break;
    case PitchStates.failedDelivery:
      color = ColorClasses.red;
      textId = 'web.emails.emailsSubjectStats.failedDelivery';
      break;
    case PitchStates.outOfOffice:
      textId = 'web.emails.emailsSubjectStats.outOfOffice';
      break;
    case PitchStates.scheduledForDelivery:
      color = ColorClasses.green;
      textId = 'web.emails.emailsSubjectStats.scheduledForDelivery';
      break;
    case PitchStates.spam:
      textId = 'common.spam';
      break;
    default:
      break;
  }

  if (textId) {
    return (
      <span className={`text-${color}`}>
        <FormattedMessage id={textId} />
      </span>
    );
  } else {
    return (
      <Stats
        archived={archived}
        clicks={clicks}
        id={id}
        isSuccess={succeeded}
        openEvents={openEvents}
        replies={replies}
        views={views}
      />
    );
  }
};

const EmailsSubjectStats = (props) => {
  const { className, subject, ...other } = props;
  const options = getOptions(props);

  return (
    <div
      className={classNames(
        'emails-subject-stats flex--horz-spread',
        className
      )}
    >
      <div className="emails-subject text-medium text-overflow">{subject}</div>
      <div className="flex">
        {getStatusComponent({ ...other })}
        {options && <OptionDotaInfobox options={options} />}
      </div>
    </div>
  );
};

EmailsSubjectStats.propTypes = {
  addAsTemplate: PropTypes.func.isRequired,
  archiveEmail: PropTypes.func.isRequired,
  archived: PropTypes.bool,
  className: PropTypes.string,
  clicks: PropTypes.number,
  id: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  openEvents: PropTypes.func.isRequired,
  openFollowUp: PropTypes.func.isRequired,
  pitchState: PropTypes.string,
  reason: PropTypes.string,
  replies: PropTypes.number,
  subject: PropTypes.string,
  succeeded: PropTypes.bool,
  unarchiveEmail: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userId: PropTypes.number,
  views: PropTypes.number,
};

EmailsSubjectStats.defaultProps = {
  archived: false,
  className: '',
  clicks: 0,
  pitchState: '',
  reason: '',
  replies: 0,
  subject: '',
  succeeded: false,
  userId: 0,
  views: 0,
};

export default injectIntl(EmailsSubjectStats);
