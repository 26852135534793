import PopupActionTypes from 'web/popup/libs/popupActionTypes';
import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';

export const templatesGridData = (
  state = [],
  { type, results = [], template = {}, templateId = 0 }
) => {
  switch (type) {
    case TemplatesActionTypes.templates.set:
      return results;
    case TemplatesActionTypes.templates.add: {
      const index = state.findIndex(({ id }) => id === template.id);
      if (index === -1) {
        return [template, ...state];
      } else {
        return state;
      }
    }
    case TemplatesActionTypes.templates.update: {
      const index = state.findIndex(({ id }) => id === template.id);
      if (index > -1) {
        const updatedTemplate = { ...state[index], ...template };
        return [
          ...state.slice(0, index),
          updatedTemplate,
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    }
    case TemplatesActionTypes.templates.destroy: {
      const index = state.findIndex(({ id }) => id === templateId);
      if (index > -1) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export const initCreateTemplateState = { categoryId: 0, name: '' };
export const templatesCreateTemplateState = (
  state = initCreateTemplateState,
  { type = '', update = {} }
) => {
  switch (type) {
    case TemplatesActionTypes.createTemplate.update:
      return { ...state, ...update };
    case PopupActionTypes.close:
      return initCreateTemplateState;
    default:
      return state;
  }
};

export const mostRecentlyCreatedTemplate = (
  state = {},
  { type, template = {} }
) => {
  switch (type) {
    case TemplatesActionTypes.mostRecentlyCreated.set:
      return template;
    case TemplatesActionTypes.mostRecentlyCreated.reset:
      return {};
    default:
      return state;
  }
};

export const templatesInitStore = (state = false, { type }) => {
  switch (type) {
    case TemplatesActionTypes.store.init:
      return true;
    default:
      return state;
  }
};

export const templatesSearchData = (state = [], { type, results = [] }) => {
  switch (type) {
    case TemplatesActionTypes.templates.search:
      return results;
    default:
      return state;
  }
};

export const templatesSearchLoading = (
  state = false,
  { type, isLoading = false }
) => {
  switch (type) {
    case TemplatesActionTypes.templates.searchLoading:
      return isLoading;
    default:
      return state;
  }
};

export const templatesLastMoved = (state = [], { type, movedTemplates }) => {
  switch (type) {
    case TemplatesActionTypes.templates.setMovedTemplates:
      return movedTemplates;
    case TemplatesActionTypes.templates.resetMovedTemplates:
      return [];
    default:
      return state;
  }
};
