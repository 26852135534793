import {
  COUNT,
  ENGAGEMENT_CHART_DAYS,
  ToutEvents,
} from '../libs/liveFeedConstants';
import I18N, { moment } from 'languages';

export const parseEvent = (event) => ({
  event: event.event,
  eventAt: event.event_at,
  id: event.id,
  pitchId: event.pitch_id,
});

export const parsePitch = (pitch) => ({
  deliveredAt: pitch.delivered_at,
  id: parseInt(pitch.id, 10),
});

export const formatToutAnalytics = (analytics = {}) => {
  const { events, pitches } = analytics;

  return {
    events: events.map(parseEvent),
    pitches: pitches.map(parsePitch),
  };
};

export const parseChartData = (analytics = {}, event = COUNT) => {
  const parsedChartData = [];
  const countEvent = ToutEvents.count;

  for (let i = ENGAGEMENT_CHART_DAYS; i--; ) {
    const date = moment()
      .subtract(i, 'days')
      .startOf('day')
      .toDate();
    const count = (analytics[date] && analytics[date][event]) || 0;
    const emailSentCount =
      (analytics[date] && analytics[date][countEvent]) || 0;
    const dateLabel = I18N.getLocalizedFullDate(date);
    const overlap = !!(event !== countEvent && count === emailSentCount);
    parsedChartData.push({
      x: dateLabel,
      y: count,
      event,
      overlap,
      countEvent,
    });
  }
  return parsedChartData;
};

export const displayTitle = (title) => {
  const titleValue =
    title && title.split(':').length > 1 && title.substring(0, 3) === 'Re:'
      ? title.substring(4, title.length)
      : title;
  return titleValue;
};

export const displayLiveFeedCelebration = (amount) => {
  const unformattedAmount = (typeof amount === 'number' && !isNaN(parseFloat(amount)));
  const formattedAmount = (typeof amount === 'string' && amount.length > 0);
  return unformattedAmount || formattedAmount;
};
