import React from 'react';
import PropTypes from 'prop-types';

const AdobeIcon = (props) => {
  const { width, height } = props;
  return (
    <svg
      height={height}
      viewBox="0 0 25.64 25"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {
            '.adobe-logo-icon-svg-1{fill: #ed2224;}.adobe-logo-icon-svg-2{fill:#fff;}'
          }
        </style>
      </defs>
      <g data-name="Layer 2" id="Layer_2">
        <g data-name="Layer 1" id="Layer_1-2">
          <path
            className="adobe-logo-icon-svg-1"
            d="M20,0H5.66A5.66,5.66,0,0,0,0,5.67V19.33A5.66,5.66,0,0,0,5.66,25H20a5.67,5.67,0,0,0,5.67-5.67V5.67A5.67,5.67,0,0,0,20,0Zm.11,18.77-.14,0H16.89a.68.68,0,0,1-.63-.4L13,10.67a.14.14,0,0,0-.18-.1.13.13,0,0,0-.1.1l-2.06,4.9a.17.17,0,0,0,.09.23l.07,0h2.27a.33.33,0,0,1,.32.21l1,2.21a.4.4,0,0,1-.22.53.41.41,0,0,1-.16,0H5.74a.38.38,0,0,1-.38-.38.23.23,0,0,1,0-.14L10.64,5.79a.71.71,0,0,1,.67-.45h3a.71.71,0,0,1,.67.45l5.28,12.49A.37.37,0,0,1,20.08,18.77Z"
          />
          <path
            className="adobe-logo-icon-svg-2"
            d="M20.08,18.77l-.14,0H16.89a.68.68,0,0,1-.63-.4L13,10.67a.14.14,0,0,0-.18-.1.13.13,0,0,0-.1.1l-2.06,4.9a.17.17,0,0,0,.09.23l.07,0h2.27a.33.33,0,0,1,.32.21l1,2.21a.4.4,0,0,1-.22.53.41.41,0,0,1-.16,0H5.74a.38.38,0,0,1-.38-.38.23.23,0,0,1,0-.14L10.64,5.79a.71.71,0,0,1,.67-.45h3a.71.71,0,0,1,.67.45l5.28,12.49A.37.37,0,0,1,20.08,18.77Z"
          />
        </g>
      </g>
    </svg>
  );
};

AdobeIcon.defaultProps = {
  height: 24,
  width: 24,
};

AdobeIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default AdobeIcon;
