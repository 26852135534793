export default {
  progress: {
    set: 'BULK_UPDATE_PROGRESS_SET',
    clear: 'BULK_UPDATE_PROGRESS_CLEAR',
  },
  complete: {
    set: 'BULK_UPDATE_COMPLETE_SET',
  },
  results: {
    set: 'BULK_UPDATE_RESULTS_SET',
  },
};
