import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import './deleteUsersDisclaimer.scss';

class DeleteUsersDisclaimer extends Component {
  state = {
    readMore: false,
  };

  render() {
    const { readMore } = this.state;
    const { isMsiActions } = this.props;

    return (
      <div className="delete-users-popup-warning">
        <div className="tout-icon-warning" />
        <div>
          <FormattedMessage
            id={
              isMsiActions
                ? 'web.settings.adminSettings.userManagement.users.salesInsightActionsPlan.delete.ifYouDelete'
                : 'web.settings.adminSettings.userManagement.users.delete.ifYouDelete'
            }
          />
          <div>
            {readMore ? (
              this.getReadMore(isMsiActions)
            ) : (
              <div
                onClick={this.toggleReadMore}
                className="blue pointer margin-top-5"
              >
                <FormattedMessage id="common.readMore" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  getReadMore = (isMsiActions) => (
    <div className="margin-top-10">
      <ul className="delete-description-list gray">
        <li>
          <FormattedMessage id="web.settings.adminSettings.userManagement.users.delete.list.allTemplates" />
        </li>
        <li>
          <FormattedMessage id="web.settings.adminSettings.userManagement.users.delete.list.allUnshared" />
        </li>
        <li>
          <FormattedMessage
            id={
              isMsiActions
                ? 'web.settings.adminSettings.userManagement.users.salesInsightActionsPlan.delete.list.allEmails'
                : 'web.settings.adminSettings.userManagement.users.delete.list.allEmails'
            }
          />
        </li>
        <li>
          <FormattedMessage id="web.settings.adminSettings.userManagement.users.delete.list.allAuthentication" />
        </li>
        <li>
          <FormattedMessage id="web.settings.adminSettings.userManagement.users.delete.list.anyEmails" />
        </li>
        <li>
          <FormattedMessage id="web.settings.adminSettings.userManagement.users.delete.list.anyProgress" />
        </li>
      </ul>
    </div>
  );

  toggleReadMore = () => {
    this.setState((prevState) => ({
      readMore: !prevState.readMore,
    }));
  };
}

DeleteUsersDisclaimer.propTypes = {
  isMsiActions: PropTypes.bool.isRequired,
};

export default DeleteUsersDisclaimer;
