import indexOf from 'lodash/indexOf';
import {
  ArchivesCategory,
  FavoritesCategory,
  ComposeTemplatesConstants,
  AllCategory,
  TEMPLATE_SEARCH_FIELDS,
  COMPOSE_TEMPLATES_PAGE_SIZE,
  RECENTLY_USED_TEMPLATES_LOCAL_STORAGE_KEY,
  RECENTLY_USED_TEMPLATES_COUNT,
} from 'web/composeWindow/libs/composeTemplatesConstants';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';
import { Regex } from 'web/libs/constants';
import { isSpecialCategory } from 'web/templates/helpers/templatesHelper';
import I18N from 'languages';

export const mapTemplateCategoryItems = (templateCategories) =>
  templateCategories.map(({ name, id, ...otherProps }) => ({
    id,
    label: localizedCategory(name),
    name,
    value: id,
    ...otherProps,
  }));

export const mapFavoritesToCategories = (favorites) =>
  favorites.map(({ favoriteable_id: favoriteableId, id, ...rest }) => ({
    ...rest,
    favorite_id: id,
    id: favoriteableId,
  }));

export const handleFavoritesResponsePayload = ({
  favorite_order: favoriteOrder,
  favorites,
}) => {
  const categories = [...mapFavoritesToCategories(favorites)];

  if (favoriteOrder !== null) {
    categories.splice(favoriteOrder, 0, FavoritesCategory);
  }

  return categories;
};

export const buildFavoritesPayload = (editedCategories, initialCategories) => {
  const upserts = [];
  const deletes = [];
  let favoriteOrder = null;
  const editedIds = {};
  editedCategories.forEach(({ id, favorite_id: favoriteId }, index) => {
    if (id !== FavoritesCategory.id) {
      upserts.push({
        favoriteable_id: id,
        favoriteable_type: ComposeTemplatesConstants.favoriteableType,
        id: favoriteId,
      });
      editedIds[favoriteId] = true;
    } else {
      favoriteOrder = index;
    }
  });

  initialCategories.forEach(({ favorite_id: favoriteId }) => {
    if (!editedIds[favoriteId] && favoriteId) {
      deletes.push({ id: favoriteId });
    }
  });

  return { deletes, favoriteOrder, upserts };
};

export const buildTemplatesQuery = ({
  viewer,
  categoryId,
  term = '',
  page = 1,
}) => {
  const filters = {
    archived: false,
    workflow_pitch_template: false,
  };
  const perPage = COMPOSE_TEMPLATES_PAGE_SIZE;
  const textSearch = term ? { fields: TEMPLATE_SEARCH_FIELDS, term } : {};
  const query = {
    filters,
    page,
    perPage,
    tags: [],
    textSearch,
    type: ElasticSearchTypes.pitchTemplates,
    viewer,
  };

  switch (categoryId) {
    case FavoritesCategory.id:
      query.tags = FavoritesCategory.tags;
      return query;
    case ArchivesCategory.id:
      query.tags = ArchivesCategory.tags;
      return query;
    case AllCategory.id:
      return query;
    default:
      filters.category_id = categoryId;
      return query;
  }
};

export const hasMissingDynamicFields = (string = '') =>
  string.match(Regex.dynamicFieldsPattern);

export const markMissingDynamicFields = (body) => {
  const terms = body.split(Regex.dynamicFieldsPattern);
  return terms
    .map(
      (term) =>
        hasMissingDynamicFields(term)
          ? `<span style="background-color: orange">${term}</span>`
          : term
    )
    .join('');
};

export const getHtmlSignature = (signature) =>
  `<div class="signature">${signature}</div>`;

export const appendSignatureToBody = (body, signature) => {
  // if signature is already there, return body as is to avoid doubling of signature
  const signaturePrefix = '<div class="signature">';
  if (body.includes(signaturePrefix)) {
    return body;
  }
  return body + getHtmlSignature(signature);
};

export const replaceSignatureInBody = (body, signature) => {
  const div = document.createElement('div');
  div.innerHTML = body;
  const oldSignature = div.querySelector('.signature');

  if (oldSignature) oldSignature.remove();

  const removedSignatureBody = div.innerHTML;
  const htmlSignature = getHtmlSignature(signature);

  return removedSignatureBody + htmlSignature;
};

export const missingDynamicFieldsPresent = ({ body, subject }) =>
  hasMissingDynamicFields(body) || hasMissingDynamicFields(subject);

export function filterTemplatesByTerm(templates = [], term = '') {
  if (term.length === 0) {
    return templates;
  }

  const caseInsensitiveTerm = term.toLowerCase();

  return templates.filter((template) => {
    return (
      template.name.toLowerCase().includes(caseInsensitiveTerm) ||
      template.body.toLowerCase().includes(caseInsensitiveTerm) ||
      template.subject.toLowerCase().includes(caseInsensitiveTerm)
    );
  });
}

export const fetchRecentlyUsedTemplates = () => {
  const storage = window.localStorage;
  return storage.getItem(RECENTLY_USED_TEMPLATES_LOCAL_STORAGE_KEY);
};

// TODO think about sync this method with the same methods in OWA and Gmail plugins
// gmail plugin addRecentlyUsed - packages/gmail/actionCreators/toutIt.js
// owa plugin addRecentlyUsed - packages/owa/actionCreators/compose.js
export const addRecentlyUsedTemplates = (templateId) => {
  const storage = window.localStorage;
  const recent = fetchRecentlyUsedTemplates();
  let recentlyUsed = {};

  if (recent) {
    recentlyUsed = JSON.parse(recent);
    if (indexOf(recentlyUsed.templates, templateId) > -1) {
      return;
    }
  }

  if (!Array.isArray(recentlyUsed.templates)) {
    recentlyUsed.templates = [];
  }

  recentlyUsed.templates.unshift(templateId);
  if (recentlyUsed.templates.length > RECENTLY_USED_TEMPLATES_COUNT) {
    recentlyUsed.templates.splice(RECENTLY_USED_TEMPLATES_COUNT);
  }

  storage.setItem(
    RECENTLY_USED_TEMPLATES_LOCAL_STORAGE_KEY,
    JSON.stringify(recentlyUsed)
  );
};

export const localizedCategory = (name) => {
  return isSpecialCategory(name)
    ? I18N.getStr(`common.${name.toLowerCase()}`)
    : name;
};

export const hasUnfilledPrompts = (string = '') =>
  string.match(Regex.dynamicFieldsPrompt);
