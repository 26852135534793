import { baseGet, basePut, basePost, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function createIdentity(identity) {
  return new Promise((resolve, reject) => {
    basePost(Urls.identities(), { identity }, resolve, reject);
  });
}

export function getIdentities() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.identities(), null, resolve, reject);
  });
}

export function getIdentity(id) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.identities(id),
      {
        details: true,
      },
      resolve,
      reject
    );
  });
}

export function updateIdentity(identity, makeDefault = false) {
  const data = {
    identity,
  };
  if (makeDefault) {
    data.make_default = true;
  }
  return new Promise((resolve, reject) => {
    basePut(Urls.identities(identity.id), data, resolve, reject);
  });
}

export function removeIdentity(id) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.identities(id), {}, resolve, reject);
  });
}
