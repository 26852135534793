import ActionTypes from 'table/modules/actionTypes';

export const initLocalStorage = {};

/* Labeled with table prefix to avoid window.localStorage clashing */
export function tableLocalStorage(
  state = initLocalStorage,
  { type = '', stored = initLocalStorage }
) {
  switch (type) {
    case ActionTypes.tableSetLocalStorage:
      return stored || initLocalStorage;
    default:
      return state;
  }
}
