import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import PeoplePopupIds from 'web/people/libs/peoplePopupIds';
import { PopupSize } from 'libs/constantsShared';
import PeopleUnsubscribe from 'web/people/components/peopleUnsubscribe';
import Icon from 'components/buttons/icon';
import {
  createNewGroupAction,
  editGroupAction,
  setPeopleAddNewGroupName,
  setPeopleGroupDescription,
  setPeopleAddNewGroupDescription,
  addPeopleToGroupAction,
  deleteGroupConfirm,
  shareGroupConfirm,
  removePeopleFromGroupAction,
} from 'web/people/actionCreators/peopleGroupActionCreators';
import CreateNewGroup from 'web/people/components/createNewGroup/createNewGroup';
import {
  closePopup,
  clearPopupAlert,
  setPopupAlert,
} from 'web/popup/actionCreators/popupActionCreators';
import { unsubscribePeople } from 'web/people/actionCreators/peopleUnsubscribeActionCreators';
import { connect } from 'react-redux';
import { getAlert } from 'web/people/services/peoplePopupAlertService';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import bind from 'memoize-bind';
import { getImageUrl } from 'web/libs/constants';
import { ButtonColors } from 'components/buttons/action';
import { isAdmin } from 'web/shared/services/accountService';
import PeopleDelete from 'web/people/components/peopleDelete/peopleDelete';
import AuthorizationPopupBody from 'web/compliance/components/authorizationPopupBody';
import {
  savePeopleAuthorization,
  updateAuthorization,
} from 'web/people/actionCreators/peopleAuthorizationActionCreators';
import PeopleSourceModal from 'web/modals/peopleSource/components/peopleSourceModal/peopleSourceModal';
import ComplianceSuccess from 'web/compliance/components/complianceSuccess/complianceSuccess';
import {
  getDeletablePeopleIds,
  getPeople,
  getUnsubscribedPeopleCount,
} from 'web/people/selectors/peopleDeleteSelector';
import { deletePeople } from 'web/people/actionCreators/peopleDeleteActionCreators';
import AddToCampaignContainer from 'web/modals/addToCampaign/container/addToCampaignContainer';
import { isValidAuthorizationInput } from 'web/people/helpers/peopleAuthorizationHelpers';
import { GlobalPopupIds } from 'web/campaigns/campaignsTab/libs/campaignsTabConstants';
import { removePeopleFromCampaign } from 'web/people/actionCreators/peopleRemoveFromCampaignActionCreators';
import { isOtherSourceType } from 'web/compliance/helpers/complianceHelpers';
import * as PeopleSourceActionCreators from 'web/modals/peopleSource/actionCreators/peopleSourceActionCreators';
import AddPeopleToGroup from 'web/people/containers/addPeopleToGroup';
import {
  getIsAddPeopleToGroupDisabled,
  getClickedPersonData,
} from 'web/people/selectors/peopleSelectors';
import ImportPeopleStepOne from 'web/people/components/importPeopleStepOne';
import ImportPeopleStepTwo from 'web/people/components/importPeopleStepTwo';
import ImportPeopleStepThree from 'web/people/components/importPeopleStepThree';
import {
  uploadFile,
  parseFileHeaders,
  updateCsvMappings,
  onSalesConnectFieldChange,
  setSalesConnectFieldList,
  updateInviteesTable,
} from 'web/people/actionCreators/peopleImportActionCreators';
import { closeImportPeopleStepThree } from 'web/people/actionCreators/peoplePopupActionCreators';
import PersonGroups from 'web/people/components/personGroups';
import ShareGroup from 'web/people/containers/shareGroup';
import './peoplePopupContainer.scss';

const POPUP_SIZE = 535;

class PeoplePopupContainer extends Component {
  render() {
    const popupObject = this.getPopupObject();
    const { personDetailsSlideOutPersonId } = this.props;

    return [
      // don't include this component twice as it's already included in person details
      !personDetailsSlideOutPersonId && (
        <AddToCampaignContainer key="people-popup-add-to-campaign-container" />
      ),
      <div key="people-popups">
        {popupObject && popupObject.children ? (
          <Popup {...popupObject} refName="peopleTabPopup" />
        ) : null}
      </div>,
    ];
  }

  getPopupObject = () => {
    const { popupAlert, popupAlertTextValues, actionCreators } = this.props;
    return {
      ...this.getPopupComponent(),
      alert: getAlert(actionCreators, popupAlert, popupAlertTextValues),
      getImageUrl,
      loading: this.props.popupLoading,
    };
  };

  getPopupComponent = () => {
    const {
      popupId,
      actionCreators,
      people,
      deletableIds,
      unsubscribedCount,
      popupData: { peopleIds, callBack: onSuccess },
      selectedPeopleSource,
    } = this.props;
    const deletableCount = deletableIds.length;
    const isUserManagementPage =
      window.location.href.includes('admin/user-management') && isAdmin();

    switch (popupId) {
      case PeoplePopupIds.importPeopleStepOne: {
        const { importLocalFile } = this.props;
        return {
          children: (
            <ImportPeopleStepOne
              importLocalFile={importLocalFile}
              isUserManagementPage={isUserManagementPage}
              parseFileHeaders={actionCreators.parseFileHeaders}
            />
          ),
          footer: {
            onPrimary: actionCreators.uploadFile,
            onSecondary: actionCreators.closePopup,
            primaryColor: ButtonColors.blue,
            primaryDisabled: !importLocalFile,
            primaryTextId: 'common.next',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: isUserManagementPage
              ? 'web.people.importUsers'
              : 'web.people.importPeople',
          },
          size: PopupSize.large,
        };
      }
      case PeoplePopupIds.importPeopleStepTwo: {
        const {
          popupLoading,
          importFieldMaps,
          salesConnectFieldList,
        } = this.props;

        return {
          children: (
            <ImportPeopleStepTwo
              actionCreators={actionCreators}
              importFieldMaps={importFieldMaps}
              mapFieldsLoading={popupLoading}
              salesConnectFieldList={salesConnectFieldList}
            />
          ),
          footer: {
            onPrimary: isUserManagementPage
              ? actionCreators.updateInviteesTable
              : actionCreators.updateCsvMappings,
            onSecondary: actionCreators.closePopup,
            primaryColor: ButtonColors.blue,
            primaryTextId: isUserManagementPage
              ? 'common.invite'
              : 'common.next',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: isUserManagementPage
              ? 'web.people.importUsers'
              : 'web.people.importPeople',
          },
          size: PopupSize.large,
        };
      }
      case PeoplePopupIds.importPeopleStepThree: {
        return {
          children: <ImportPeopleStepThree />,
          footer: {
            backgroundImage: `url(${getImageUrl('toutapp-hero', 'png')})`,
            className: 'import-final-step-background',
            onPrimary: actionCreators.closeImportPeopleStepThree,
            primaryColor: ButtonColors.blue,
            primaryTextId: 'common.close',
          },
          header: {
            onClose: actionCreators.closeImportPeopleStepThree,
            textId: 'web.people.importPeople',
          },
          onOutsideClick: actionCreators.closeImportPeopleStepThree,
          size: PopupSize.large,
        };
      }
      case PeoplePopupIds.deletePeople: {
        return {
          children: (
            <PeopleDelete
              deletableCount={deletableCount}
              isAdmin={isAdmin()}
              people={people}
              unsubscribedCount={unsubscribedCount}
            />
          ),
          footer: {
            onPrimary: bind(
              actionCreators.deletePeople,
              this,
              deletableIds,
              onSuccess
            ),
            onSecondary: actionCreators.closePopup,
            primaryColor: ButtonColors.red,
            primaryDisabled: !deletableCount,
            primaryText: this.props.intl.formatMessage(
              { id: 'web.peopleDelete.deleteButton' },
              { count: deletableCount }
            ),
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            text: this.props.intl.formatMessage(
              { id: 'web.peopleDelete.header' },
              { count: peopleIds.length }
            ),
          },
          onOutsideClick: actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      case PeoplePopupIds.createGroup: {
        const { newGroupName, newGroupDescription } = this.props;
        const {
          setPeopleAddNewGroupName,
          setPeopleAddNewGroupDescription,
        } = actionCreators;

        const createAc = bind(actionCreators.createNewGroupAction, this);
        return {
          children: (
            <CreateNewGroup
              newGroupDescription={newGroupDescription}
              newGroupName={newGroupName}
              onCreate={createAc}
              onGroupDescriptionChange={setPeopleAddNewGroupDescription}
              onGroupNameChange={setPeopleAddNewGroupName}
            />
          ),
          footer: {
            onPrimary: createAc,
            onSecondary: actionCreators.closePopup,
            primaryDisabled: !newGroupName,
            primaryTextId: 'common.create',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: 'web.people.createGroup.header',
          },
          onOutsideClick: actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      case PeoplePopupIds.editGroup: {
        const {
          newGroupName,
          newGroupDescription,
          popupData: { groupId },
        } = this.props;
        const {
          setPeopleAddNewGroupName,
          setPeopleAddNewGroupDescription,
        } = actionCreators;

        const createAc = bind(actionCreators.editGroupAction, this, groupId);

        return {
          children: (
            <CreateNewGroup
              newGroupDescription={newGroupDescription}
              newGroupName={newGroupName}
              onCreate={createAc}
              onGroupDescriptionChange={setPeopleAddNewGroupDescription}
              onGroupNameChange={setPeopleAddNewGroupName}
            />
          ),
          footer: {
            onPrimary: createAc,
            onSecondary: actionCreators.closePopup,
            primaryDisabled: !newGroupName,
            primaryTextId: 'common.save',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: 'web.people.editGroup.header',
          },
          onOutsideClick: actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      case PeoplePopupIds.sourcePeople: {
        return {
          children: (
            <PeopleSourceModal
              actionCreators={actionCreators}
              selectedPeopleSource={selectedPeopleSource}
            />
          ),
          footer: {
            onPrimary: actionCreators.savePeoplesSource,
            onSecondary: actionCreators.closePopup,
            primaryDisabled: this._isSourceModalPrimaryBtnDisabled(),
            primaryTextId: 'common.save',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            text: this.props.intl.formatMessage(
              { id: 'web.people.compliance.modal.sourceHeader' },
              { count: peopleIds.length }
            ),
          },
          onOutsideClick: actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      case PeoplePopupIds.sourcePeopleSuccess: {
        return {
          children: (
            <ComplianceSuccess
              count={this.props.popupData.peopleCount}
              typeText={this.props.intl.formatMessage({ id: 'common.source' })}
            />
          ),
          footer: {
            onPrimary: actionCreators.closePopup,
            primaryTextId: 'common.ok',
          },
          header: {
            textId: 'web.people.compliance.modal.sourceSuccessful',
          },
          onOutsideClick: actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      case PeoplePopupIds.addPeopleToGroup: {
        const {
          isAddPeopleToGroupDisabled,
          popupData: { callback: onSuccess },
        } = this.props;

        return {
          children: <AddPeopleToGroup />,
          footer: {
            onPrimary: bind(
              actionCreators.addPeopleToGroupAction,
              this,
              onSuccess
            ),
            onSecondary: actionCreators.closePopup,
            primaryDisabled: isAddPeopleToGroupDisabled,
            primaryTextId: 'common.add',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: 'web.people.addPeopleToGroupHeader',
          },
          onOutsideClick: actionCreators.closePopup,
          size: PopupSize.large,
        };
      }
      case PeoplePopupIds.deleteGroup: {
        const {
          popupData: { groupId },
        } = this.props;
        const message = this.props.intl.formatMessage({
          id: 'web.people.deleteGroupConfirm',
        });

        return {
          children: (
            <div className="margin-top-full margin-bottom-full">{message}</div>
          ),
          footer: {
            onPrimary: bind(actionCreators.deleteGroupConfirm, this, groupId),
            onSecondary: actionCreators.closePopup,
            primaryTextId: 'common.delete',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: 'web.people.deleteGroup',
          },
          onOutsideClick: actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case PeoplePopupIds.unsubscribePeople: {
        return {
          children: <PeopleUnsubscribe />,
          footer: {
            onPrimary: bind(
              actionCreators.unsubscribePeople,
              this,
              peopleIds,
              onSuccess
            ),
            onSecondary: actionCreators.closePopup,
            primaryColor: ButtonColors.red,
            primaryText: this.props.intl.formatMessage(
              { id: 'web.peopleUnsubscribe.unsubscribe' },
              { count: peopleIds.length }
            ),
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            text: this.props.intl.formatMessage(
              { id: 'web.peopleUnsubscribe.header' },
              { count: peopleIds.length }
            ),
          },
          onOutsideClick: actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      case GlobalPopupIds.removeConfirmation: {
        const {
          isAdmin,
          popupData: { callback, ids },
        } = this.props;
        return {
          children: (
            <div className="margin-top-full margin-bottom-full">
              <Icon className="tout-icon-warning" />
              <FormattedMessage
                id={
                  isAdmin
                    ? 'web.campaigns.admin.removeConfirmation'
                    : 'web.campaigns.removeConfirmation'
                }
                values={{ people: ids.length }}
              />
            </div>
          ),
          footer: {
            onPrimary: bind(
              actionCreators.removePeopleFromCampaign,
              this,
              ids,
              isAdmin,
              callback
            ),
            onSecondary: actionCreators.closePopup,
            primaryTextId: 'common.remove',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: 'web.campaigns.removeCampaign',
          },
          onOutsideClick: actionCreators.closePopup,
          size: PopupSize.small,
        };
      }
      case PeoplePopupIds.authorizationPeople: {
        const {
          isValidInput,
          complianceEditState,
          actionCreators: {
            updateAuthorization,
            savePeopleAuthorization,
            closePopup,
          },
        } = this.props;
        return {
          children: (
            <AuthorizationPopupBody
              complianceEditState={complianceEditState}
              updateAuthorization={updateAuthorization}
            />
          ),
          footer: {
            onPrimary: savePeopleAuthorization,
            onSecondary: closePopup,
            primaryDisabled: !isValidInput,
            primaryTextId: 'common.save',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: closePopup,
            textId: 'web.people.compliance.modal.authorizationHeader',
            textValues: { count: peopleIds.length },
          },
          onOutsideClick: closePopup,
          size: POPUP_SIZE,
        };
      }
      case PeoplePopupIds.authorizationPeopleSuccess: {
        return {
          children: (
            <ComplianceSuccess
              count={this.props.popupData.peopleCount}
              typeText={this.props.intl.formatMessage({
                id: 'common.authorization',
              })}
            />
          ),
          footer: {
            onPrimary: actionCreators.closePopup,
            primaryTextId: 'common.ok',
          },
          header: {
            textId: 'web.people.compliance.modal.authorizationSuccess',
          },
          onOutsideClick: actionCreators.closePopup,
          size: POPUP_SIZE,
        };
      }
      case PeoplePopupIds.personGroupsModal: {
        const {
          clickedPersonData: { groups, name },
        } = this.props;

        return {
          children: <PersonGroups personGroupsList={groups} />,
          footer: {
            onSecondary: actionCreators.closePopup,
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: 'web.people.personGroupsListHeader',
            textValues: {
              name,
            },
          },
          onOutsideClick: actionCreators.closePopup,
          size: PopupSize.large,
        };
      }
      case PeoplePopupIds.shareGroup: {
        const {
          actionCreators: { shareGroupConfirm },
        } = this.props;

        return {
          children: <ShareGroup />,
          footer: {
            onPrimary: shareGroupConfirm,
            onSecondary: actionCreators.closePopup,
            primaryTextId: 'common.share',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: actionCreators.closePopup,
            textId: 'web.people.shareGroupHeader',
          },
          onOutsideClick: actionCreators.shareGroupConfirm,
          size: PopupSize.large,
        };
      }
      case PeoplePopupIds.removePeopleFromGroup: {
        const {
          actionCreators: { closePopup, removePeopleFromGroupAction },
        } = this.props;

        return {
          children: <FormattedMessage id="web.people.removeFromGroup.body" />,
          footer: {
            onPrimary: () => removePeopleFromGroupAction(peopleIds, onSuccess),
            onSecondary: closePopup,
            primaryTextId: 'common.remove',
            secondaryTextId: 'common.cancel',
          },
          header: {
            onClose: closePopup,
            textId: 'web.people.removeFromGroup.header',
          },
          onOutsideClick: closePopup,
          size: PopupSize.small,
        };
      }
      default:
        return null;
    }
  };

  _isSourceModalPrimaryBtnDisabled = () => {
    const {
      selectedPeopleSource: { otherSourceValue, sourceType } = {},
    } = this.props;
    if (isOtherSourceType(sourceType)) {
      return !otherSourceValue.trim();
    }
    return false;
  };
}

PeoplePopupContainer.propTypes = {
  clickedPersonData: PropTypes.object.isRequired,
  deletableIds: PropTypes.array.isRequired,
  importFieldMaps: PropTypes.array.isRequired,
  importLocalFile: PropTypes.object,
  intl: intlShape.isRequired,
  newGroupDescription: PropTypes.string.isRequired,
  newGroupName: PropTypes.string.isRequired,
  people: PropTypes.array.isRequired,
  personDetailsSlideOutPersonId: PropTypes.number,
  popupAlert: PropTypes.string.isRequired,
  popupAlertTextValues: PropTypes.object.isRequired,
  popupData: PropTypes.object.isRequired,
  popupId: PropTypes.string.isRequired,
  popupLoading: PropTypes.bool.isRequired,
  salesConnectFieldList: PropTypes.array.isRequired,
  selectedAddToGroupPeopleIds: PropTypes.array,
  unsubscribedCount: PropTypes.number.isRequired,
};

PeoplePopupContainer.defaultProps = {
  importLocalFile: null,
  personDetailsSlideOutPersonId: undefined,
  selectedAddToGroupPeopleIds: [],
};

const mapStateToProps = (state) => ({
  clickedPersonData: getClickedPersonData(state),
  complianceEditState: state.peopleAuthorization,
  createPeopleState: state.peopleCreateGroupState,
  deletableIds: getDeletablePeopleIds(state),
  importFieldMaps: state.importFieldMaps,
  importLocalFile: state.importLocalFile,
  isAddPeopleToGroupDisabled: getIsAddPeopleToGroupDisabled(state),
  isAdmin: isAdmin(),
  isValidInput: isValidAuthorizationInput(state),
  newGroupDescription: state.peopleNewGroupDescription,
  newGroupName: state.peopleNewGroupName,
  people: getPeople(state),
  personDetailsSlideOutPersonId: state.personDetailsSlideOutPersonId,
  popupAlert: state.popupAlert,
  popupAlertTextValues: state.popupAlertTextValues,
  popupData: state.popupData,
  popupId: state.popup,
  popupLoading: state.popupLoading,
  salesConnectFieldList: state.salesConnectFieldList,
  selectedAddToGroupPeopleIds: state.selectedAddToGroupPeopleIds,
  selectedPeopleSource: state.selectedPeopleSource,
  unsubscribedCount: getUnsubscribedPeopleCount(state),
});

const mapDispatchToProps = {
  addPeopleToGroupAction,
  clearPopupAlert,
  closeImportPeopleStepThree,
  closePopup,
  createNewGroupAction,
  deleteGroupConfirm,
  deletePeople,
  editGroupAction,
  onSalesConnectFieldChange,
  parseFileHeaders,
  removePeopleFromCampaign,
  removePeopleFromGroupAction,
  savePeopleAuthorization,
  setPeopleAddNewGroupDescription,
  setPeopleAddNewGroupName,
  setPeopleGroupDescription,
  setPopupAlert,
  setSalesConnectFieldList,
  shareGroupConfirm,
  unsubscribePeople,
  updateAuthorization,
  updateCsvMappings,
  updateInviteesTable,
  uploadFile,
  ...PeopleSourceActionCreators,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  injectIntl
)(PeoplePopupContainer);
