import { createSelector } from 'reselect';
import {
  TeamAccessSmartFilterValues,
  Roles,
} from '../libs/marketoSettingsConstants';

const { all, granted, notGranted } = TeamAccessSmartFilterValues;

const getMarketoAdminSubscriptionUsers = (state) =>
  state.marketoAdminSubscriptionUsers;
const getMarketoAuthenticationUserIds = (state) =>
  state.marketoAuthenticationUserIds;
const getUserId = (state) => state.user && state.user.id;
const getMarketoAdminTeamAccessSmartFilter = (state) =>
  state.marketoAdminTeamAccessSmartFilter;
const getMarketoUsersAuthsBeingToggled = (state) =>
  state.marketoUsersAuthenticationsBeingToggled;
const getMarketoAuthenticationUsers = (state) =>
  state.marketoAuthenticationUsers;

export const getFormattedUsers = createSelector(
  getMarketoAdminSubscriptionUsers,
  getMarketoAuthenticationUserIds,
  getUserId,
  getMarketoAdminTeamAccessSmartFilter,
  getMarketoUsersAuthsBeingToggled,
  getMarketoAuthenticationUsers,
  (users, authUserIds, currentUserId, filter, beingToggledIds, authUsers) => {
    const formattedUsers = [];
    users.forEach((user) => {
      const hasAuth = authUserIds.indexOf(user.id) !== -1;

      let isAdmin = false;
      authUsers.forEach((authUser) => {
        if (authUser.user_id === user.id) {
          isAdmin = authUser.access_role === Roles.engageAdmin;
        }
      });

      if (user.id === currentUserId) {
        return;
      }
      const hasAuthAndGranted = hasAuth && filter === granted;
      const noAuthNotGranted = !hasAuth && filter === notGranted;
      if (
        user.id !== currentUserId &&
        (filter === all || hasAuthAndGranted || noAuthNotGranted)
      ) {
        formattedUsers.push({
          ...user,
          hasAuth,
          isBeingToggled: beingToggledIds.includes(user.id),
          isSelectedDisabled: isAdmin,
        });
      }
    });
    return formattedUsers;
  }
);
