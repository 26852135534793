import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import {
  CUSTOMIZATION_WIZARD_LEFT_PANE_WIDTH,
  CUSTOMIZATION_WIZARD_LEFT_PANE_BORDER,
  CUSTOMIZATION_WIZARD_TABLE_HEIGHT,
  CUSTOMIZATION_WIZARD_TABLE_ROW_HEIGHT,
  CUSTOMIZATION_WIZARD_TABLE_CELL_LINES,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import TwoPaneLayout from 'components/layouts/twoPane';
import List from 'components/list';
import Table from 'table/modules/table';
import TextCustomCell from 'table/modules/cells/textCustomCell';

class SalesforceCustomizationWizardStepItems extends Component {
  state = {
    selectedCategoryIndex: 0,
  };

  tableBodyRef = {};

  componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      this.setSelectedCategoryIndex(0);
    }
  }

  render() {
    const {
      className,
      intl,
      items,
      description,
      labelColumnHeader,
      descriptionColumnHeader,
    } = this.props;
    const { selectedCategoryIndex } = this.state;

    return (
      <div className={className}>
        <TwoPaneLayout
          sidebarWidth={CUSTOMIZATION_WIZARD_LEFT_PANE_WIDTH}
          borderColor={CUSTOMIZATION_WIZARD_LEFT_PANE_BORDER}
        >
          <div>
            <div className="margin-bottom-full margin-right-full">
              <FormattedHTMLMessage id={description} />
            </div>
            <List
              items={items}
              selected={selectedCategoryIndex}
              handleSelected={this.onCategorySelect}
              itemRenderer={this.renderListItem}
            />
          </div>
          <div className="margin-left-full">
            <h3 className="margin-bottom-full">
              <FormattedMessage id={items[selectedCategoryIndex].label} />
            </h3>
            <Table
              maxHeight={CUSTOMIZATION_WIZARD_TABLE_HEIGHT}
              items={items[selectedCategoryIndex].items}
              row={{
                height: CUSTOMIZATION_WIZARD_TABLE_ROW_HEIGHT,
              }}
              setBodyRef={this.setBodyRef}
              columns={[
                {
                  id: 'label',
                  name: intl.formatMessage({ id: labelColumnHeader }),
                  rowCell: {
                    component: TextCustomCell,
                    metadata: {
                      property: 'label',
                      className: 'no-word-break',
                      wrapLines: CUSTOMIZATION_WIZARD_TABLE_CELL_LINES,
                      formatText: this.renderGridLabelCell,
                    },
                  },
                },
                {
                  id: 'description',
                  name: intl.formatMessage({ id: descriptionColumnHeader }),
                  rowCell: {
                    component: TextCustomCell,
                    metadata: {
                      property: 'description',
                      className: 'no-word-break',
                      wrapLines: CUSTOMIZATION_WIZARD_TABLE_CELL_LINES,
                      formatText: this.renderGridDescriptionCell,
                    },
                  },
                },
              ]}
            />
          </div>
        </TwoPaneLayout>
      </div>
    );
  }

  setBodyRef = (bodyRef) => {
    this.tableBodyRef = bodyRef;
  };

  scrollTop = () => {
    this.tableBodyRef.scrollTop = 0;
  };

  renderListItem = ({ label }) => (
    <div className="tout-list-item-default">
      <FormattedMessage id={label} />
    </div>
  );

  renderGridLabelCell = ({ label }) => <FormattedMessage id={label} />;

  renderGridDescriptionCell = ({ description }) => (
    <FormattedMessage id={description} />
  );

  onCategorySelect = (data, index) => {
    this.setSelectedCategoryIndex(index);
    this.scrollTop();
  };

  setSelectedCategoryIndex(index) {
    this.setState({
      selectedCategoryIndex: index,
    });
  }
}

const itemShape = PropTypes.shape({
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

const categoryShape = PropTypes.shape({
  items: PropTypes.arrayOf(itemShape).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

SalesforceCustomizationWizardStepItems.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  descriptionColumnHeader: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  items: PropTypes.arrayOf(categoryShape),
  itemsTitle: PropTypes.string.isRequired,
  labelColumnHeader: PropTypes.string.isRequired,
};

export default injectIntl(SalesforceCustomizationWizardStepItems);
