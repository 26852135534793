import TrackingActionTypes from '../libs/trackingActionTypes';
import {
  saveCustomTrackingDomain as saveCustomTrackingDomainCall,
  deleteCustomTrackingDomain as deleteCustomTrackingDomainCall,
  getRecentWebsiteTrackingEvents as getRecentWebsiteTrackingEventsCall,
} from '../services/trackingServices';
import { getUser } from 'web/user/actionCreators/userActionCreators';
import { openViewAlert } from '../../view/actionCreators/alertActionCreators';
import { TrackingAlertIds } from 'web/settings/adminSettings/tracking/libs/trackingConstants';

export const setCustomDomainFetching = (fetching = true) => ({
  type: TrackingActionTypes.setCustomDomainFetching,
  fetching,
});

export const saveCustomTrackingDomain = (domain) => (dispatch) => {
  dispatch(setCustomDomainFetching());
  return saveCustomTrackingDomainCall(domain)
    .then(async (customDomain) => {
      await dispatch(getUser());
      return customDomain;
    })
    .finally(() => dispatch(setCustomDomainFetching(false)));
};

export const deleteCustomTrackingDomain = () => (dispatch) => {
  dispatch(setCustomDomainFetching());
  return deleteCustomTrackingDomainCall()
    .then(async () => {
      await dispatch(getUser());
    })
    .finally(() => dispatch(setCustomDomainFetching(false)));
};

export const setWebsiteTrackingFetching = (fetching) => ({
  type: TrackingActionTypes.setWebsiteTrackingFetching,
  fetching,
});

export const setWebsiteTrackingEvents = (events) => ({
  type: TrackingActionTypes.setWebsiteTrackingEvents,
  events,
});

export const getRecentWebsiteTrackingEvents = () => (dispatch) => {
  dispatch(setWebsiteTrackingFetching(true));
  return getRecentWebsiteTrackingEventsCall()
    .then((events) => dispatch(setWebsiteTrackingEvents(events)))
    .catch(() => {
      dispatch(
        openViewAlert(TrackingAlertIds.unableToGetWebsiteTrackingEvents)
      );
    })
    .finally(() => {
      dispatch(setWebsiteTrackingFetching(false));
    });
};
