import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/layouts/page';
import PageHeader from 'components/layouts/page/header';
import PageView from 'components/layouts/page/view';
import SettingsUnsubscribesAlert from '../../containers/settingsUnsubscribesAlertContainer';
import TabNavBar from 'components/layouts/page/tabNavBar';
import TabNav from 'components/layouts/page/tabNav';
import './settingsUnsubscribes.scss';

const SettingsUnsubscribes = ({ children, tabs }) => (
  <Page className="settings-unsubscribes">
    <SettingsUnsubscribesAlert />
    <PageHeader textId="web.settings.unsubscribe.settings" />
    <TabNavBar border>
      <TabNav {...tabs} />
    </TabNavBar>
    <PageView className="settings-unsubscribes-content background-gray-extra-light">
      {children}
    </PageView>
  </Page>
);

SettingsUnsubscribes.propTypes = {
  children: PropTypes.node,
  tabs: PropTypes.object,
};

SettingsUnsubscribes.defaultProps = {
  children: null,
  tabs: {},
};

export default SettingsUnsubscribes;
