/* eslint-disable sort-keys */
import PersonDetailsSlideOutActionTypes from '../libs/personDetailsSlideOutActionTypes';
import {
  navigateToEditUnsubscribedPeopleInGroup,
  navigateToEditPeopleInGroup,
  setSelectedEmailURL,
  changeToLastUrl,
} from 'web/services/routerService';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import PersonDetailsActionTypes from 'web/person/libs/personDetailsActionTypes';
import { PersonDetailsTabs } from 'web/person/libs/personDetailsConstants';

export const openPersonDetails = (id, options) => (dispatch, getState) => {
  dispatch({
    tab: PersonDetailsTabs.about,
    type: PersonDetailsActionTypes.setTab,
  });
  dispatch({
    clear: getState().personDetailsSlideOutPersonId <= 0,
    id,
    options,
    type: PersonDetailsSlideOutActionTypes.open,
  });
  if (options && options.emailId) {
    setSelectedEmailURL(options.emailId);
  }
};

export const openGroupPersonDetails = (groupId, id) => () => {
  navigateToEditPeopleInGroup(groupId, id);
};

export const openUnsubscribePersonDetails = (id) => () => {
  navigateToEditUnsubscribedPeopleInGroup(id);
};

export const closePersonDetails = () => (dispatch, getState) => {
  const { peopleCurrentGroupId } = getState();
  dispatch(closePersonDetailsSlideOut());

  if (peopleCurrentGroupId) {
    toutBackboneHelper.closePersoneDetailSlideOut(peopleCurrentGroupId);
  } else {
    changeToLastUrl();
  }
};

export const clearPersonDetails = () => (dispatch) => {
  dispatch({
    clear: true,
    type: PersonDetailsSlideOutActionTypes.clear,
  });
};

export const closePersonDetailsSlideOut = () => (dispatch) => {
  dispatch({
    type: PersonDetailsSlideOutActionTypes.close,
  });
};
