import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './inputText.scss';

export default class InputText extends Component {
  constructor(props) {
    super(props);
    this._inputRef = null;
    this._searchBarRef = null;
  }

  _setInputRef = (input) => {
    if (this.props.setRef) {
      this.props.setRef(input);
    }
    this._inputRef = input;
  };

  _setSearchBarRef = (input) => (this._searchBarRef = input);

  _clearSearch = () => {
    const { onChange } = this.props;
    this._searchBarRef.value = '';
    if (onChange) {
      onChange({ currentTarget: { value: '' } });
    }
    this._searchBarRef.focus();
  };

  _calcTextareaHeight(rows = 4) {
    const LINE_HEIGHT = 25;
    const PADDING = 4;
    const BORDER = 1;
    return {
      height: `${rows * LINE_HEIGHT +
        PADDING * 2 +
        BORDER * 2 +
        LINE_HEIGHT / 2}px`,
    }; // half added to show extra is scrollable
  }

  render() {
    const {
      type,
      className,
      clickX,
      autoFocus,
      rows,
      toutUiStyle,
      ...other
    } = this.props; // eslint-disable-line no-unused-vars
    //ignore unused getRef, we need remove it from properties because it not legal DOM property https://reactjs.org/warnings/unknown-prop.html

    const classes = classNames('inputText', { toutUiStyle }, className);
    const shouldAutofocus = typeof autoFocus !== 'undefined' ? autoFocus : true;

    switch (type) {
      case 'textarea':
        return (
          <textarea
            autoFocus={shouldAutofocus}
            className={classes}
            ref={this._setInputRef}
            rows={rows}
            style={this._calcTextareaHeight(rows)}
            type="textarea"
            {...other}
          />
        );
      case 'text':
        return (
          <input
            autoFocus={shouldAutofocus}
            className={classes}
            ref={this._setInputRef}
            type="text"
            {...other}
          />
        );
      case 'number':
        return (
          <input
            autoFocus={shouldAutofocus}
            className={classes}
            ref={this._setInputRef}
            type="number"
            {...other}
          />
        );
      case 'password':
        return (
          <input
            autoFocus={shouldAutofocus}
            className={classes}
            ref={this._setInputRef}
            type="password"
            {...other}
          />
        );
      case 'email':
        return (
          <input
            autoFocus={shouldAutofocus}
            className={classes}
            ref={this._setInputRef}
            type="email"
            {...other}
          />
        );
      case 'search':
        return (
          <div className="inputText search">
            <span className="tout-icon-search" />
            <span
              className="tout-icon-close"
              onClick={clickX || this._clearSearch}
            />
            <input
              autoFocus={shouldAutofocus}
              className={classes}
              ref={this._setSearchBarRef}
              type="text"
              {...other}
            />
          </div>
        );
      default:
        return null;
    }
  }
}

InputText.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  clickX: PropTypes.func,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  setRef: PropTypes.func,
  toutUiStyle: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

InputText.defaultProps = {
  autoFocus: false,
  className: '',
  clickX: null,
  onChange: null,
  rows: 5,
  setRef: null,
  toutUiStyle: false,
};
