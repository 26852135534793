import { createSelector } from 'reselect';
import { TasksTableStatusFilterValues } from 'web/tables/tasks/libs/tablesTasksConstants';
import {
  filterItemsByDate,
  filterTasksByCampaign,
} from 'web/tables/helpers/itemsTableFilters';
import {
  getDatesSmartFilter,
  getSelectedDates,
  getStatusSmartFilter,
} from 'web/tables/tasks/selectors/tablesTasksSelectors';

const getCurrentCampaign = (state) => state.campaignsTabSelectedCampaign;
const getTasksFetching = (state) => state.tasksFetching;
const getTasksCompleted = (state) => state.tasksCompleted;
const getTasksUncompleted = (state) => state.tasksUncompleted;

export const getFilteredTasks = createSelector(
  [
    getStatusSmartFilter,
    getDatesSmartFilter,
    getTasksUncompleted,
    getTasksCompleted,
    getSelectedDates,
    getCurrentCampaign,
  ],
  (statusValue, datesValue, uncompleted, completed, dates, campaignId) => {
    const tasksToFilter =
      statusValue === TasksTableStatusFilterValues.completed
        ? completed
        : uncompleted;
    const campaignTasks = filterTasksByCampaign(
      tasksToFilter.valueSeq().toArray(),
      campaignId
    );
    return filterItemsByDate(campaignTasks, datesValue, dates);
  }
);

export const getCampaignsTabViewFetching = createSelector(
  [getTasksFetching],
  (tasksFetching) => tasksFetching.length > 0
);
