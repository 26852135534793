import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CardHighlight from 'components/cardHighlight/cardHighlight';
import Switch from 'components/buttons/switch';
import I18N from 'languages';
import './marketoCheckCard.scss';

class MarketoCheckCard extends Component {
  render() {
    const { marketoCheckState } = this.props;
    const label = I18N.getStr(
      'web.settings.unsubscribe.marketoCard.toggleDescription'
    );

    return (
      <Fragment>
        <h3 className="margin-bottom-half">
          <FormattedMessage id="web.settings.unsubscribe.marketoCard.title" />
        </h3>
        <p>
          <FormattedMessage id="web.settings.unsubscribe.marketoCard.description" />
        </p>
        <Switch
          rightLabel
          className="marketo-unsubscribes-switch"
          label={label}
          checked={marketoCheckState}
          onChange={this.onChange}
          name="marketoCheckSwitch"
        />
        <CardHighlight
          className="marketo-card-warning"
          textId="web.settings.unsubscribe.marketoCard.warningDescription"
        />
      </Fragment>
    );
  }

  onChange = (isChecked) => {
    const { onSave } = this.props;
    onSave(isChecked);
  };
}

MarketoCheckCard.propTypes = {
  onSave: PropTypes.func.isRequired,
  marketoCheckState: PropTypes.bool.isRequired,
};

export default MarketoCheckCard;
