import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Popup from 'components/popups';
import { PopupSize } from 'libs/constantsShared';

const DeleteConfirmationPopup = (props) => {
  const { closePopup, deleteIntegration, headerTextId, provider } = props;

  return (
    <Popup
      footer={{
        onPrimary: () => {
          deleteIntegration();
          closePopup();
        },
        onSecondary: closePopup,
        primaryTextId: 'common.ok',
        secondaryTextId: 'common.cancel',
      }}
      header={{
        onClose: closePopup,
        textId: headerTextId,
      }}
      onOutsideClick={closePopup}
      size={PopupSize.small}
    >
      <FormattedMessage
        id="web.settings.accountSettings.integrations.delete.areYouSure"
        values={{ provider }}
      />
    </Popup>
  );
};

DeleteConfirmationPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  deleteIntegration: PropTypes.func.isRequired,
  headerTextId: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
};

export default DeleteConfirmationPopup;
