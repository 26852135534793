import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popups';
import StepItems from 'web/modals/salesforceCustomization/components/wizard/stepItems';
import StepSummary from 'web/modals/salesforceCustomization/components/wizard/stepSummary';
import StepProfileUsers from 'web/modals/salesforceCustomization/components/wizard/stepProfileUsers';
import StepProfileAccess from 'web/modals/salesforceCustomization/components/wizard/stepProfileAccess';
import { PopupSize } from 'libs/constantsShared';
import {
  CUSTOMIZATION_OPTIONS_FIELDS,
  CUSTOMIZATION_OPTIONS_BUTTONS,
  CUSTOMIZATION_OPTIONS_OTHER,
  CUSTOMIZATION_OPTIONS_PROFILE_USERS,
  CUSTOMIZATION_OPTIONS_PROFILE_ACCESS,
  CUSTOMIZATION_SUMMARY,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import './salesforceCustomizationWizard.scss';

class SalesforceCustomizationWizard extends Component {
  render() {
    const {
      isFirstStep,
      isLastStep,
      actionCreators: { onNext, onBack, onInstall, onClose },
    } = this.props;
    const primaryButtonText = isLastStep
      ? 'web.salesforceCustomization.wizard.button.install'
      : 'common.next';
    const secondaryButtonText = isFirstStep ? 'common.cancel' : 'common.back';
    const primaryButtonHandler = isLastStep ? onInstall : onNext;
    const secondaryButtonHandler = isFirstStep ? onClose : onBack;

    return (
      <Popup
        className="salesforce-customization-wizard"
        footer={{
          onPrimary: primaryButtonHandler,
          onSecondary: secondaryButtonHandler,
          primaryTextId: primaryButtonText,
          secondaryTextId: secondaryButtonText,
        }}
        header={{
          onClose,
          textId: 'web.salesforceCustomization.wizard.title',
        }}
        onOutsideClick={onClose}
        size={PopupSize.massive}
      >
        {this.renderCurrentStep()}
      </Popup>
    );
  }

  renderCurrentStep() {
    const {
      currentStep,
      items,
      profiles,
      loading,
      actionCreators: {
        setProfileUser,
        updateProfileAvailableUsers,
        switchAllProfileAvailableUsers,
      },
    } = this.props;

    switch (currentStep) {
      case CUSTOMIZATION_OPTIONS_FIELDS:
        return (
          <StepItems
            className="salesforce-customization-wizard-step"
            items={items}
            description="web.salesforceCustomization.wizard.step.fields.description"
            itemsTitle="web.salesforceCustomization.wizard.step.fields.itemsTitle"
            labelColumnHeader="web.salesforceCustomization.wizard.step.fields.labelColumnHeader"
            descriptionColumnHeader="web.salesforceCustomization.wizard.step.fields.descriptionColumnHeader"
          />
        );

      case CUSTOMIZATION_OPTIONS_BUTTONS:
        return (
          <StepItems
            className="salesforce-customization-wizard-step"
            items={items}
            description="web.salesforceCustomization.wizard.step.buttons.description"
            itemsTitle="web.salesforceCustomization.wizard.step.buttons.itemsTitle"
            labelColumnHeader="web.salesforceCustomization.wizard.step.buttons.labelColumnHeader"
            descriptionColumnHeader="web.salesforceCustomization.wizard.step.buttons.descriptionColumnHeader"
          />
        );

      case CUSTOMIZATION_OPTIONS_OTHER:
        return (
          <StepItems
            className="salesforce-customization-wizard-step"
            items={items}
            description="web.salesforceCustomization.wizard.step.other.description"
            itemsTitle="web.salesforceCustomization.wizard.step.other.itemsTitle"
            labelColumnHeader="web.salesforceCustomization.wizard.step.other.labelColumnHeader"
            descriptionColumnHeader="common.description"
          />
        );

      case CUSTOMIZATION_OPTIONS_PROFILE_USERS:
        return (
          <StepProfileUsers
            className="salesforce-customization-wizard-step"
            profiles={profiles}
            setProfileUser={setProfileUser}
          />
        );

      case CUSTOMIZATION_OPTIONS_PROFILE_ACCESS:
        return (
          <StepProfileAccess
            className="salesforce-customization-wizard-step"
            profiles={profiles}
            loading={loading}
            updateProfileAvailableUsers={updateProfileAvailableUsers}
            switchAllProfileAvailableUsers={switchAllProfileAvailableUsers}
          />
        );

      case CUSTOMIZATION_SUMMARY:
        return <StepSummary className="salesforce-customization-wizard-step" />;

      default:
        return null;
    }
  }
}

const itemShape = PropTypes.shape({
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

const categoryShape = PropTypes.shape({
  items: PropTypes.arrayOf(itemShape).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

SalesforceCustomizationWizard.propTypes = {
  actionCreators: PropTypes.shape({
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onInstall: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    setProfileUser: PropTypes.func.isRequired,
    updateProfileAvailableUsers: PropTypes.func.isRequired,
    switchAllProfileAvailableUsers: PropTypes.func.isRequired,
  }).isRequired,
  currentStep: PropTypes.string,
  isFirstStep: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(categoryShape).isRequired,
  profiles: PropTypes.object.isRequired,
};

SalesforceCustomizationWizard.defaultProps = {
  currentStep: null,
};

export default SalesforceCustomizationWizard;
