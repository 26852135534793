/*eslint react/no-unused-prop-types: 0*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TasksTablePopupIds } from '../libs/tablesTasksConstants';
import TasksTablePopupContainer from './tablesTasksPopupContainer';
import TasksTable from '../components/tasksTable';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import {
  getDatesSmartFilter,
  getLoading,
  getSelectedDates,
  getStatusSmartFilter,
} from '../selectors/tablesTasksSelectors';
import { openPersonDetails } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import {
  onDatesSmartFilterChange,
  onRowAnimated,
  onStatusSmartFilterChange,
  onTableMount,
  onTableUnmount,
} from '../actionCreators/tablesTasksStateActionCreators';
import {
  openCampaign,
  openTasksDelete,
  taskAction,
} from '../actionCreators/tablesTasksActionActionCreators';
import {
  taskDelete,
  taskDone,
  taskSkip,
  taskSuccess,
  taskUndone,
} from '../actionCreators/tablesTasksSingleActionCreators';
import { openModalTask } from 'web/modals/task';
import './tablesTasksContainer.scss';
import { getUserColumnSettingsForTable } from 'web/user/selectors/userSelectors';
import { changeColumnsSettings } from 'web/user/actionCreators/userActionCreators';

class TasksTableContainer extends Component {
  constructor(props) {
    super(props);
    const { actionCreators, allowViewAsFilter, dispatch, tableId } = props;

    this.actionCreators = {
      changeColumnsSettings: (columnsSettings) =>
        dispatch(changeColumnsSettings(tableId, columnsSettings)),
      onTableMount: () => dispatch(onTableMount(tableId)),
      onTableRowAnimated: () => dispatch(onRowAnimated(tableId)),
      onTableUnmount: () => dispatch(onTableUnmount(tableId)),
      onTasksDatesSmartFilterChange: (value, start, end) =>
        dispatch(
          onDatesSmartFilterChange(
            tableId,
            value,
            start,
            end,
            allowViewAsFilter
          )
        ),
      onTasksStatusSmartFilterChange: (index, value) =>
        dispatch(onStatusSmartFilterChange(tableId, value, allowViewAsFilter)),
      openCampaign: (...args) => dispatch(openCampaign(...args)),
      openPersonDetails: (id) => dispatch(openPersonDetails(id)),
      openTask: (task) => openModalTask(task),
      taskAction: (task) => dispatch(taskAction(tableId, task)),
      taskDelete: (task) => dispatch(taskDelete(tableId, task)),
      taskDone: (task) => dispatch(taskDone(tableId, task)),
      taskSkip: (task) => dispatch(taskSkip(tableId, task)),
      taskSuccess: (task) => dispatch(taskSuccess(tableId, task)),
      taskUndone: (task) => dispatch(taskUndone(tableId, task)),
      tasksConfirmationDelete: (ids, callback) =>
        dispatch(openTasksDelete(ids, callback)),
      tasksConfirmationDone: (ids, callback) =>
        dispatch(
          openPopup(TasksTablePopupIds.tasksConfirmationDone, {
            callback,
            ids,
            tableId,
          })
        ),
      tasksConfirmationSkip: (ids, callback) =>
        dispatch(
          openPopup(TasksTablePopupIds.tasksConfirmationSkip, {
            callback,
            ids,
            tableId,
          })
        ),
      tasksConfirmationSuccess: (ids, callback) =>
        dispatch(
          openPopup(TasksTablePopupIds.tasksConfirmationSuccess, {
            callback,
            ids,
            tableId,
          })
        ),
      ...actionCreators,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.actionCreators.onTableMount();
  }

  componentWillUnmount() {
    this.actionCreators.onTableUnmount();
  }

  render() {
    return (
      <div className="tasks-table-container">
        <TasksTablePopupContainer
          tableId={this.props.tableId}
          tasks={this.props.tasks}
        />
        <TasksTable {...this.props} actionCreators={this.actionCreators} />
      </div>
    );
  }
}

TasksTableContainer.propTypes = {
  actionCreators: PropTypes.object.isRequired, // passed in and merged
  allowViewAsFilter: PropTypes.bool.isRequired, // passed in and merged
  animateRowColor: PropTypes.string.isRequired,
  columnSettings: PropTypes.arrayOf(PropTypes.object),
  dates: PropTypes.object.isRequired,
  datesSelectedValue: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetching: PropTypes.bool, // passed in
  loading: PropTypes.bool, // passed in prop merged with state
  statusSelectedValue: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired, // passed in
  tasks: PropTypes.array.isRequired, // passed in
};

TasksTableContainer.defaultProps = {
  allowViewAsFilter: false,
  columnSettings: [],
  fetching: false,
  loading: false,
};

function mapStateToProps(state, ownProps) {
  return {
    animateRowColor: state.tablesTasksAnimateRowColor,
    columnSettings: getUserColumnSettingsForTable(state, ownProps.tableId),
    dates: getSelectedDates(state, ownProps),
    datesSelectedValue: getDatesSmartFilter(state, ownProps),
    loading: getLoading(state, ownProps),
    statusSelectedValue: getStatusSmartFilter(state, ownProps),
  };
}

export default connect(mapStateToProps)(TasksTableContainer);
