import EmailPluginsActionTypes from 'web/settings/emailPlugins/libs/emailPluginsActionTypes';

const initialState = {
  gmailChromeExtensionInstalled: false,
};

export const emailPlugins = (state = initialState, payload = {}) => {
  const { type, ...data } = payload;

  switch (type) {
    case EmailPluginsActionTypes.setGmailChromeExtensionInstalledState:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};
