import moment from 'moment';
import {
  EditTemplateAnalyticsDatesSelect,
  EditTemplateCards,
  EditTemplateMappingsValues,
} from '../libs/editTemplateSlideOutConstants';
import { countEmailEvents } from 'web/emails/helpers/emailsParsers';
import { parseIds } from 'web/campaigns/campaignsTab/services/attachmentsService';

export const LABEL = 'label';

const parseUser = (user = {}) => ({
  userName: user.name || '',
  userEmail: user.email || '',
  userId: user.id || 0,
});

export const getNotesFromTemplate = (notes = [], users = {}) =>
  notes.map((note) => ({
    content: note.content || '',
    createdAt: note.created_at || '',
    id: note.id || 0,
    ...parseUser(users[note.user_id]),
  }));

const getTemplateEditState = (template = {}) => ({
  bcc: template.bcc || '',
  body: template.body || '',
  cc: template.cc || '',
  id: template.id || 0,
  subject: template.subject || '',
});

export const getMappingFromField = (field = '', map = {}) => {
  const strippedField = field.replace(/[{}]/g, '');
  const dynamicField = map[strippedField] || { label: strippedField };
  return {
    field: strippedField,
    [LABEL]: dynamicField.label,
  };
};

export const getTemplateMappingsFromTemplate = (
  mappings = [],
  dynamicFieldsMap = {}
) =>
  mappings.map((mapping) => {
    const dynamicField = dynamicFieldsMap[mapping.field] || {
      label: mapping.field,
    };
    return {
      ...mapping,
      [LABEL]: dynamicField.label,
    };
  });

const getRecommendEditState = (template = {}, dynamicFieldsMap) => ({
  enableMappingsChecked:
    Number.isInteger(template.enable_mappings) &&
    template.enable_mappings !== EditTemplateMappingsValues.disabled,
  enableMappingsValue:
    template.enable_mappings || EditTemplateMappingsValues.any,
  id: template.id || 0,
  mappings: getTemplateMappingsFromTemplate(
    template.template_mappings,
    dynamicFieldsMap
  ),
});

export const getEditStateByCard = (cardId, state = {}) => {
  const template = state.editTemplateSlideOutTemplate;

  switch (cardId) {
    case EditTemplateCards.template:
      return getTemplateEditState(template);
    case EditTemplateCards.recommend:
      return getRecommendEditState(template, state.dynamicFieldsMap);
    default:
      return {};
  }
};

const getTemplateDetails = (state) => {
  const {
    editTemplateSlideOutTemplateEditState: {
      bcc,
      cc,
      editorId = '',
      id,
      subject,
    },
    emailComposeAttachmentsMap: attachmentsMap = {},
    emailComposeEditors = {},
  } = state;

  const instance = emailComposeEditors[editorId];
  let body;
  if (instance) {
    body = instance.getContent();
  } else {
    return {};
  }

  const attachments = parseIds(attachmentsMap[editorId]);

  return {
    attachments,
    templateDetails: {
      bcc: bcc || '',
      body: body || '',
      cc: cc || '',
      id: id || 0,
      subject: subject || '',
    },
  };
};

const getEnableMappingsValue = (checked, value) => {
  if (!checked || !Number.isInteger(value)) {
    return EditTemplateMappingsValues.disabled;
  } else {
    return value;
  }
};

const getTemplateMappingsFromEditState = (mappings = []) =>
  mappings.map((mapping) => {
    const temp = { ...mapping };
    Reflect.deleteProperty(temp, LABEL);
    return temp;
  });

const getRecommendDetails = ({
  editTemplateSlideOutRecommendEditState: {
    enableMappingsChecked,
    enableMappingsValue,
    id,
    mappings,
  },
}) => ({
  templateDetails: {
    enable_mappings: getEnableMappingsValue(
      enableMappingsChecked,
      enableMappingsValue
    ),
    id: id || 0,
    template_mappings: getTemplateMappingsFromEditState(mappings),
  },
});

export const getDetailsByCard = (cardId, state) => {
  switch (cardId) {
    case EditTemplateCards.template:
      return getTemplateDetails(state);
    case EditTemplateCards.recommend:
      return getRecommendDetails(state);
    default:
      return {};
  }
};

export const parseAnalytics = ({
  bounced,
  clicked,
  delivered,
  failed_delivery,
  replied,
  sent,
  spam,
  successful,
  successful_delivery,
  viewed,
}) => ({
  bounced: bounced || 0,
  clicked: clicked || 0,
  deliveredSuccessfully: successful_delivery || 0,
  failed: failed_delivery || 0,
  noEngagement: delivered || 0,
  replied: replied || 0,
  sent: sent || 0, // send incorporates delivered, viewed, clicked, replied, successful, bounced, & spam
  spam: spam || 0,
  successful: successful || 0,
  totalEngagement: delivered + viewed + clicked + replied + successful || 0,
  totalSent: sent + failed_delivery || 0,
  viewed: viewed || 0,
});

export const getDatesFromAnalyticsSelect = (datesSelect) => {
  const to = moment();
  switch (datesSelect) {
    case EditTemplateAnalyticsDatesSelect.lastThirtyDays:
      return { from: moment().subtract(30, 'd'), to };
    case EditTemplateAnalyticsDatesSelect.lastSevenDays:
      return { from: moment().subtract(7, 'd'), to };
    case EditTemplateAnalyticsDatesSelect.lastTwelveWeeks:
    default:
      return { from: moment().subtract(12, 'w'), to };
  }
};

export const parseEmail = (email = {}) => ({
  ...email,
  ...countEmailEvents(email.events),
  campaignId: email.workflow_id || 0,
  deliveredAt: email.delivered_at || null,
  parentPitchId: email.parent_pitch_id || 0,
  lastEventAt: email.last_event_at || null,
  succeeded: email.success != null,
  userId: email.user_id || 0,
});
