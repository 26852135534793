import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

const itemShape = PropTypes.shape({
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  formatMessageValues: PropTypes.oneOfType([PropTypes.string]),
});

class PureList extends PureComponent {
  render() {
    const { className } = this.props;

    return (
      <div className={classNames('tout-pure-list', className)}>
        <ul className="tout-pure-list-container">{this._renderItems()}</ul>
      </div>
    );
  }

  _renderItems = () => {
    const { itemRenderer, items } = this.props;

    return items.map((item, index) => itemRenderer(item, index, this.props));
  };

  static _itemRenderer = ({ text, formatMessageValues }, index, props) => {
    const { listClassName, keyName, shouldBeFormatted } = props;

    return (
      <li
        className={classNames('tout-pure-list-item-default', listClassName)}
        key={`${keyName}-${index}`}
      >
        {shouldBeFormatted ? (
          <FormattedHTMLMessage id={text} values={{ ...formatMessageValues }} />
        ) : (
          <span>{text}</span>
        )}
      </li>
    );
  };

  static propTypes = {
    className: PropTypes.string,
    itemRenderer: PropTypes.func,
    items: PropTypes.arrayOf(itemShape).isRequired,
    keyName: PropTypes.string,
    listClassName: PropTypes.string,
    shouldBeFormatted: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    itemRenderer: PureList._itemRenderer,
    keyName: 'pure-list',
    listClassName: '',
    shouldBeFormatted: false,
  };
}

export default PureList;
