import SalesforceActionTypes from '../libs/salesforceActionTypes';
import { getSalesforceStats as getSalesforceStatsCall } from '../services/salesforceService';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { SalesforceAlertIds } from 'web/salesforce/libs/salesforceConstants';

export const setStats = (stats = {}) => ({
  type: SalesforceActionTypes.setStats,
  stats,
});

export const setStatsFetching = (fetching = true) => ({
  type: SalesforceActionTypes.setStatsFetching,
  fetching,
});

export const setStatsFetched = (fetched = true) => ({
  type: SalesforceActionTypes.setStatsFetched,
  fetched,
});

export const getSalesforceApiCallsStats = () => (dispatch, getState) => {
  dispatch(setStatsFetching());
  return getSalesforceStatsCall(getState().user.id)
    .then((stats) => {
      dispatch(setStats(stats));
      return stats;
    })
    .catch(() => {
      dispatch(openViewAlert(SalesforceAlertIds.somethingWentWrong));
    })
    .finally(() => {
      dispatch(setStatsFetching(false));
      dispatch(setStatsFetched());
    });
};
