import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import difference from 'lodash/difference';
import { createSelector } from 'reselect';
import {
  getFetching as getTeamsFetching,
  getFetched as getTeamsFetched,
  getMasterTeam,
} from 'web/teams/selectors/teamsSelectors';
import {
  getById as getTeamMemberById,
  getAllIds as getTeamMembersAllIds,
  getTeamMemberToTeam,
  getMasterTeamMembersFetching,
  getMasterTeamMembersFetched,
} from 'web/teamMembers/selectors/teamMembersSelectors';
import { getSelectedTeam } from './teamsSelector';
import { getUserColumnSettingsForTable } from 'web/user/selectors/userSelectors';
import {
  COLUMN_SETTINGS_TEAM_MEMBERS_TABLE_ID,
  COLUMN_SETTINGS_ALL_MEMBERS_TABLE_ID,
} from 'web/settings/adminSettings/teamManagement/libs/teamMembersConstants';

export const getEditTeamsLoading = (state) =>
  state.ui.settings.adminSettings.teamManagement.teamMembers.editTeamsLoading;

export const getAddTeamMembersToTeamsLoading = (state) =>
  state.ui.settings.adminSettings.teamManagement.teamMembers
    .addTeamMembersToTeamsLoading;

export const getDeleteTeamMembersLoading = (state) =>
  state.ui.settings.adminSettings.teamManagement.teamMembers
    .deleteTeamMembersLoading;

export const getChangeRoleLoading = (state) =>
  state.ui.settings.adminSettings.teamManagement.teamMembers.changeRoleLoading;

export const getAddTeamMembersToTeamLoading = (state) =>
  state.ui.settings.adminSettings.teamManagement.teamMembers
    .addTeamMembersToTeamLoading;

export const getTeamMemberToSelectedTeam = createSelector(
  [getSelectedTeam, getTeamMemberToTeam],
  (selectedTeam, teamMemberToTeam) => {
    if (!selectedTeam) {
      return [];
    }
    return filter(teamMemberToTeam, ['teamId', selectedTeam.id]);
  }
);

export const getSelectedTeamMembers = createSelector(
  [getTeamMemberToSelectedTeam, getTeamMemberById],
  (teamMemberToSelectedTeam, byId) =>
    teamMemberToSelectedTeam.map(({ teamMemberId }) => byId[teamMemberId])
);

export const getFetching = createSelector(
  [
    getTeamsFetching,
    getTeamsFetched,
    getMasterTeamMembersFetching,
    getMasterTeamMembersFetched,
  ],
  (
    teamsFetching,
    teamsFetched,
    selectedTeamMembersFetching,
    selectedTeamMembersFetched
  ) =>
    teamsFetching ||
    !teamsFetched ||
    selectedTeamMembersFetching ||
    !selectedTeamMembersFetched
);

export const getLoading = createSelector(
  [getChangeRoleLoading],
  (changeRoleLoading) => changeRoleLoading
);

export const getPopupTeamMemberId = (state) =>
  get(state, 'popupData.teamMember.id');

export const getPopupTeamMemberTeamIds = createSelector(
  [getPopupTeamMemberId, getTeamMemberToTeam],
  (teamMemberId, teamMemberToTeam) => {
    if (!teamMemberId) {
      return [];
    }
    return map(
      filter(teamMemberToTeam, ['teamMemberId', teamMemberId]),
      'teamId'
    );
  }
);

export const isMasterTeamSelected = createSelector(
  [getSelectedTeam, getMasterTeam],
  (selectedTeam, masterTeam = {}) => selectedTeam === masterTeam
);

export const geUsersSelectOptions = createSelector(
  [getTeamMemberById, getTeamMemberToSelectedTeam, getTeamMembersAllIds],
  (byId, teamMemberToSelectedTeamIds, allIds) =>
    difference(allIds, map(teamMemberToSelectedTeamIds, 'teamMemberId')).map(
      (id) => ({
        label: `${byId[id].name} (${byId[id].email})`,
        value: id,
      })
    )
);

export const isAddTeamMembersToTeamEnabled = createSelector(
  [getFetching, isMasterTeamSelected],
  (fetching, isMasterTeam) => !fetching && !isMasterTeam
);

export const getUserColumnSettingsForTeamMembersTable = (state) => {
  const isMasterTeam = isMasterTeamSelected(state);
  const columnsSettingsTableId = isMasterTeam
    ? COLUMN_SETTINGS_ALL_MEMBERS_TABLE_ID
    : COLUMN_SETTINGS_TEAM_MEMBERS_TABLE_ID;
  return getUserColumnSettingsForTable(state, columnsSettingsTableId);
};
