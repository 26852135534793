import get from 'lodash/get';
import find from 'lodash/find';
import { createSelector } from 'reselect';
import { BccName } from '../libs/bccConstants';

export const getContactIntegrations = (state) =>
  get(state, 'entities.contactIntegrations.items', []);

export const getBccFromContactIntegrations = createSelector(
  [getContactIntegrations],
  (contactIntegrations) => find(contactIntegrations, { name: BccName })
);

export const getBccIntegration = (state) =>
  get(state, 'entities.integrations.bcc.item', {});

export const getBccIntegrationFetching = (state) =>
  state.entities.integrations.bcc.fetching;
export const getBccIntegrationFetched = (state) =>
  state.entities.integrations.bcc.fetched;

export const shouldInvalidateIntegration = createSelector(
  [getBccIntegrationFetched, getBccIntegrationFetching],
  (fetched, fetching) => !fetched && !fetching
);
