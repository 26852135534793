import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Button, { ButtonSizes } from 'components/buttons/action';
import { productTourSteps } from 'web/productTour/containers/productTourStepsContainer/lib/productTourConstants';
import './step4.scss';

const Step4 = (props) => {
  const { changeTab, productTourLoadedImages } = props;
  const getImgURL = (folder) =>
    productTourLoadedImages.length
      ? `${productTourLoadedImages[3]}`
      : `${getImageUrl(`productTour/${folder}/step4`, 'png')}`;

  return (
    <div className="productTour-step4-rightColumn-wrapper">
      <picture>
        <source media="(min-width: 1920px)" srcSet={getImgURL('1920x1080')} />
        <source media="(max-width: 1536px)" srcSet={getImgURL('1536x864')} />
        <source media="(max-width: 1440px)" srcSet={getImgURL('1440x900')} />
        <img alt="step 4" src={getImgURL('1920x1080')} />
      </picture>
      <div className="productTour-step4-content">
        <h1>
          <FormattedHTMLMessage id={'web.productTour.emailComposeTitle'} />
        </h1>
        <br />
        <div>
          <FormattedHTMLMessage id={'web.productTour.emailComposeInfo'} />
        </div>
        <Button
          onClick={() => changeTab(productTourSteps.step5)}
          size={ButtonSizes.medium}
        >
          <FormattedMessage id="web.onboarding.actionButtons.finish" />
        </Button>
      </div>
    </div>
  );
};

Step4.defaultProps = {
  changeTab: () => {},
};

Step4.propTypes = {
  changeTab: PropTypes.func,
  productTourLoadedImages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  productTourLoadedImages: state.productTourLoadedImages,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step4);
