import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ColorClasses } from 'libs/constantsStyles';
import './statsLine.scss';

class StatsLine extends PureComponent {
  render() {
    const {
      barColor,
      barBackgroundColor,
      textColor,
      percent,
      className,
      showPercent,
    } = this.props;
    const containerClasses = classNames('stats-line', className, {
      [`background-${barBackgroundColor}`]: barBackgroundColor,
    });
    const barClasses = classNames('stats-line-bar', {
      [`background-${barColor}`]: barColor,
    });

    return (
      <div className={containerClasses}>
        <div className={barClasses} style={this.getBarStyles()} />
        {showPercent && (
          <div
            className={classNames('stats-line-count', {
              [`text-${textColor}`]: textColor,
            })}
          >
            {(percent && `${percent}%`) || '0%'}
          </div>
        )}
      </div>
    );
  }

  getBarStyles = () => {
    const { percent } = this.props;
    return {
      width: `${percent}%`,
    };
  };
}

StatsLine.propTypes = {
  className: PropTypes.string,
  barColor: PropTypes.string,
  barBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  percent: PropTypes.number,
  showPercent: PropTypes.bool,
};

StatsLine.defaultProps = {
  className: '',
  barColor: ColorClasses.green,
  barBackgroundColor: ColorClasses.grayExtraLight,
  textColor: ColorClasses.grayDark,
  percent: 0,
  showPercent: true,
};

export default StatsLine;
