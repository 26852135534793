import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import Text from 'web/form/components/fields/text';
import { required } from 'web/form/helpers/validators';
import Label from 'components/label';

const LABEL_WIDTH = 90;

class TeamCreateForm extends Component {
  constructor(props) {
    super(props);

    this.requiredValidator = required();
  }

  render() {
    const {
      handleSubmit,
      actionCreators: { createTeam },
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(createTeam)} noValidate>
        <Label
          id="create-team-name"
          messageKey="web.settings.adminSettings.teamManagement.teamMembers.teamName"
          sideLabel
          width={LABEL_WIDTH}
        >
          <Field
            name="teamName"
            validate={[this.requiredValidator]}
            component={Text}
          />
        </Label>
      </Form>
    );
  }
}

TeamCreateForm.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm()(TeamCreateForm);
