import { combineReducers } from 'redux';
import apiCallsReducers from './apiCallsReducer';
import integrationReducers from './integrationReducer';
import accountReducers from './accountReducers';

export default combineReducers({
  apiCalls: apiCallsReducers,
  integration: integrationReducers,
  account: accountReducers,
});
