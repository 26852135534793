import { baseGet, basePut, basePost } from 'web/services/api';
import { Urls } from 'web/libs/routes';
import { PitchAPICommits } from 'web/composeWindow/libs/composeWindowConstants';

export const createPitch = (data) => {
  return new Promise((resolve, reject) => {
    basePost(Urls.emails, data, resolve, reject);
  });
};

export const sendNewPitch = (data) => {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.emails,
      { ...data, commit: PitchAPICommits.send },
      resolve,
      reject
    );
  });
};

export const updatePitch = (id, data) => {
  return new Promise((resolve, reject) => {
    basePut(Urls.emailsId(id), data, resolve, reject);
  });
};

export const sendPitch = (id, data) => {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.emailsId(id),
      { ...data, commit: PitchAPICommits.send },
      resolve,
      reject
    );
  });
};

export const fetchPitch = (id) => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.emailsId(id), {}, resolve, reject);
  });
};

export const getUserIdentities = () => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.emailIdentities, {}, resolve, reject);
  });
};

export const bulkSendPitch = (drafts) => {
  return new Promise((resolve, reject) => {
    basePost(`${Urls.bulkEmails}`, { drafts }, resolve, reject);
  });
};

export const getLiquifiedFields = ({
  body,
  email,
  identityId,
  recipientId,
  subject,
}) => {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.liquify,
      {
        body,
        email,
        identity_id: identityId,
        recipient_id: recipientId,
        subject,
      },
      resolve,
      reject
    );
  });
};

export const bulkLiquifiedFields = (pitches, identifier) => {
  return new Promise((resolve, reject) => {
    basePost(Urls.bulkLiquifies, { identifier, pitches }, resolve, reject);
  });
};

export const createFindPersonWithAddress = (data) => {
  return new Promise((resolve, reject) => {
    basePost(Urls.peopleEmailLookup, data, resolve, reject);
  });
};

export const searchPeople = (query, type) => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.composePeopleSearch, { query, type }, resolve, reject);
  });
};

export const checkBlockedDomains = (emailDomains) => {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.userDomainBlocks,
      { email_domains: emailDomains },
      resolve,
      reject
    );
  });
};

export const getPersonEmails = (personId) => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.emails, { person_id: personId }, resolve, reject);
  });
};

export const fetchPersonById = (personId) => {
  return new Promise((resolve, reject) => {
    baseGet(Urls.personById.replace(':#id', personId), {}, resolve, reject);
  });
};
