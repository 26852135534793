import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './progressBar.scss';

class ProgressBar extends Component {
  render() {
    const { animate, color, height, progress, width } = this.props;
    const progressPercent = this._normalizeProgress(progress);

    const containerClasses = classNames('progressBar', {
      'text-center': progressPercent === 100,
      border: progressPercent < 100,
    });

    const barClasses = classNames('tout-progress', {
      animate,
      [`background-${color}`]: color,
    });

    return (
      <div
        className={containerClasses}
        style={this._getContainerStyles(height, width)}
      >
        <div
          className={barClasses}
          style={this._getProgressStyles(progressPercent, height)}
        />
      </div>
    );
  }

  _getContainerStyles(height, width) {
    const containerStyles = {};
    if (height) {
      containerStyles.height = `${height}px`;
    }
    if (width) {
      containerStyles.width = `${width}px`;
    }

    return containerStyles;
  }

  _getProgressStyles(progress, height) {
    const progressStyles = { width: `${progress}%` };
    if (height) {
      progressStyles.borderRadius = `${height}px`;
    }

    return progressStyles;
  }

  _normalizeProgress(progress) {
    if (progress < 0) {
      return 0;
    }
    if (progress > 100) {
      return 100;
    }
    return progress;
  }
}

ProgressBar.propTypes = {
  animate: PropTypes.bool,
  color: PropTypes.string,
  height: PropTypes.number,
  progress: PropTypes.number.isRequired,
  width: PropTypes.number,
};

ProgressBar.defaultProps = {
  animate: false,
  color: 'green',
  height: 0,
  width: 0,
};

export default ProgressBar;
