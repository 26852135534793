import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from 'web/libs/constants';
import {
  CUSTOMIZATION_WIZARD_LEFT_PANE_WIDTH,
  CUSTOMIZATION_WIZARD_LEFT_PANE_BORDER,
} from 'web/modals/salesforceCustomization/libs/salesforceCustomizationConstants';
import TwoPaneLayout from 'components/layouts/twoPane/twoPaneLayout';

class SalesforceCustomizationWizardCommonStep extends Component {
  render() {
    const { className, children } = this.props;

    return (
      <div className={className}>
        <TwoPaneLayout
          sidebarWidth={CUSTOMIZATION_WIZARD_LEFT_PANE_WIDTH}
          borderColor={CUSTOMIZATION_WIZARD_LEFT_PANE_BORDER}
        >
          <div className="margin-top-full">
            <div
              className="summary-image"
              style={{
                backgroundImage: `url(${getImageUrl(
                  'salesforce-logo',
                  'svg'
                )})`,
              }}
            />
            <div
              className="summary-image"
              style={{
                backgroundImage: `url(${getImageUrl('icon-tilt', 'svg')})`,
              }}
            />
          </div>
          <div className="margin-left-full">{children}</div>
        </TwoPaneLayout>
      </div>
    );
  }
}

SalesforceCustomizationWizardCommonStep.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

SalesforceCustomizationWizardCommonStep.defaultProps = {
  className: '',
  children: null,
};

export default SalesforceCustomizationWizardCommonStep;
