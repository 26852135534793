import moment from 'moment';
import { DateTypes } from 'web/liveFeed/tasks/libs/tasksConstants';
import {
  startOfToday,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
  startOfYesterday,
  endOfYesterday,
  endOfNextNumDays,
  startOfLastNumDays,
} from 'web/liveFeed/tasks/helpers/tasksDateHelper';

export const sortTodoPriorityAndTime = (
  { priority: firstPriority, remind_at: firstRemindAt },
  { priority: secondPriority, remind_at: secondRemindAt }
) => {
  if (
    (firstPriority === 'high' && secondPriority === 'high') ||
    (firstPriority === 'normal' && secondPriority === 'normal')
  ) {
    return moment(secondRemindAt) > moment(firstRemindAt) ? -1 : 1;
  } else if (firstPriority === 'high') {
    return -1;
  } else if (firstPriority === 'normal') {
    return 1;
  } else {
    return moment(firstRemindAt) - moment(secondRemindAt);
  }
};

export const sortOverduePriorityAndTime = (
  { priority: firstPriority, remind_at: firstRemindAt },
  { priority: secondPriority, remind_at: secondRemindAt }
) => {
  if (
    (firstPriority === 'high' && secondPriority === 'high') ||
    (firstPriority === 'normal' && secondPriority === 'normal')
  ) {
    return moment(secondRemindAt) < moment(firstRemindAt) ? -1 : 1;
  } else if (firstPriority === 'high') {
    return -1;
  } else if (firstPriority === 'normal') {
    return 1;
  } else {
    return moment(secondRemindAt) - moment(firstRemindAt);
  }
};

export const getFilterDates = (dateFilter) => {
  switch (dateFilter) {
    case DateTypes.todayAndTomorrow:
      return { start: startOfToday(), end: endOfTomorrow() };
    case DateTypes.today:
      return { start: startOfToday(), end: endOfToday() };
    case DateTypes.tomorrow:
      return { start: startOfTomorrow(), end: endOfTomorrow() };
    case DateTypes.nextSevenDays:
      return { start: startOfToday(), end: endOfNextNumDays(7) };
    case DateTypes.lastThreeDays:
      return { start: startOfLastNumDays(3), end: endOfYesterday() };
    case DateTypes.yesterday:
      return { start: startOfYesterday(), end: endOfYesterday() };
    case DateTypes.lastSevenDays:
      return { start: startOfLastNumDays(7), end: endOfYesterday() };
    default:
      return { start: startOfToday(), end: endOfToday() };
  }
};
