import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';

const AddToCampaignSelectedPeople = ({
  selectedMessageKey,
  selectedMessageValues,
}) => (
  <div className="margin-bottom-full">
    <FormattedHTMLMessage
      id={selectedMessageKey}
      values={selectedMessageValues}
    />
  </div>
);

AddToCampaignSelectedPeople.propTypes = {
  selectedMessageKey: PropTypes.string.isRequired,
  selectedMessageValues: PropTypes.object,
};

AddToCampaignSelectedPeople.defaultProps = {
  selectedMessageValues: {},
};

export default AddToCampaignSelectedPeople;
