import { steps } from 'web/onboarding/lib/stepsRouting';
import { hasOnboardingFeatureFlag } from 'web/shared/helpers/featureFlagHelper';

export const defineActiveStep = (url) =>
  steps.reduce(
    (activeStep, step, idx) => (url.includes(step.url) ? idx + 1 : activeStep),
    0
  );

export const isAvailable = ({ route, user, isNewUser }) =>
  !!(
    route.includes('#onboarding') &&
    user.subscription &&
    hasOnboardingFeatureFlag(user) &&
    isNewUser
  );

export const getSuccessStepSetupTextId = (statuses) => {
  if (statuses.isGmailIntegrationSetUp) {
    return 'web.onboarding.successStepSetup.gmailConnected';
  } else if (statuses.isOutlookIntegrationSetUp) {
    return 'web.onboarding.successStepSetup.outlookConnected';
  } else if (statuses.isExchangeOnpremIntegrationSetup) {
    return 'web.onboarding.successStepSetup.exchangeOnpremConnected';
  } else {
    return '';
  }
};
