/*  To use, wrap your component:
 *    export default outsideClickHandler(Component, mapProps);
 *
 *  API:
 *    Component - React Component - component to be wrapped
 *    mapProps - { disableClick: String, onOutsideClick: String } - passed if you need to map prop names,
 *      i.e., your component is passed a prop called 'outsideClick' that turns off click. Passing
 *      { disableClick: 'outsideClick' } will map the property for use in _onOutsideClick.
 *
 *  Notes:
 *    Set eventType and useCapture only when absolutely necessary. Prop was added b/c div was removed
 *      from DOM on mousedown instead of click so target was modal-background and currentTarget
 *      became document.
 *    Set onOutsideClick & disableClick as method on wrapped component class and HOC will use it for onClick instead of checking props
 *.   Wrapped component can not be stateless. React throws a warning v16+ that it no longer internally wraps stateless components in class
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import closest from 'closest';

export default function outsideClickHandler(WrappedComponent, mapProps = {}) {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;
  const disableClickPropName = mapProps.disableClick || 'disableClick';
  const onOutsideClickPropName = mapProps.onOutsideClick || 'onOutsideClick';

  return class OutsideClick extends Component {
    constructor(props) {
      super(props);
      this.wrappedComponentRef = null;
    }

    static displayName = `Wrapped${componentName}`;

    componentDidMount() {
      this.addListener(this.props.eventType, this.props.useCapture);
    }

    componentWillUnmount() {
      this.removeListener(this.props.eventType, this.props.useCapture);
    }

    componentWillReceiveProps(nextProps) {
      if (
        nextProps.useCapture !== this.props.useCapture ||
        nextProps.eventType !== this.props.eventType
      ) {
        this.removeListener(this.props.eventType, this.props.useCapture);
        this.addListener(nextProps.eventType, nextProps.useCapture);
      }
    }

    render() {
      const {
        disableClick: ignored1,
        exceptionSelectors: ignored2,
        onOutsideClick: ignored3,
        ...other
      } = this.props;

      return <WrappedComponent {...other} ref={this._setRef} />;
    }

    _setRef = (node) => {
      this.wrappedComponentRef = node;
    };

    // checks for WrappedComponet onOutsideClick to allow wrapped component to override/manipulate (i.e., format params or call multiple functions) onOutsideClick passed to it
    // before checking the mapped prop name.
    _getOnClick = () =>
      (this.wrappedComponentRef &&
        typeof this.wrappedComponentRef.onOutsideClick === 'function' &&
        this.wrappedComponentRef.onOutsideClick) ||
      this.props[onOutsideClickPropName];

    _getDisableClick() {
      return (
        (this.wrappedComponentRef && this.wrappedComponentRef.disableClick) ||
        this.props[disableClickPropName]
      );
    }

    _onOutsideClick = (e) => {
      const { exceptionSelectors } = this.props;
      const onClick = this._getOnClick();

      if (this._getDisableClick() || !onClick) {
        return;
      }

      const domNode = ReactDOM.findDOMNode(this); //eslint-disable-line react/no-find-dom-node
      let exceptionNode = false;
      if (exceptionSelectors) {
        exceptionNode = exceptionSelectors.some((selector) =>
          closest(e.target, selector)
        );
      }

      if ((!domNode || !domNode.contains(e.target)) && !exceptionNode) {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }
    };

    addListener = (eventType, useCapture) => {
      if (document.addEventListener) {
        // `useCapture` flag is set to true so that a `stopPropagation` in the children will
        // not prevent all outside click handlers from firing
        document.addEventListener(eventType, this._onOutsideClick, useCapture);
      } else {
        document.attachEvent(`on${eventType}`, this._onOutsideClick);
      }
    };

    removeListener = (eventType, useCapture) => {
      if (document.removeEventListener) {
        document.removeEventListener(
          eventType,
          this._onOutsideClick,
          useCapture
        );
      } else {
        document.detachEvent(`on${eventType}`, this._onOutsideClick);
      }
    };

    static propTypes = {
      disableClick: PropTypes.bool,
      exceptionSelectors: PropTypes.array,
      onOutsideClick: PropTypes.func,
      eventType: PropTypes.string,
      useCapture: PropTypes.bool,
    };

    static defaultProps = {
      disableClick: false,
      exceptionSelectors: undefined,
      onOutsideClick: undefined,
      eventType: 'click',
      useCapture: true,
    };
  };
}
