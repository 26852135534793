import TasksActionTypes from '../libs/tasksActionTypes';
import { TASKS_COMPLETED } from '../libs/tasksConstants';
import { getDatesForCalls } from '../services/tasksHelpers';
import { getTasksCompleted as getTasksCompletedCall } from '../services/tasksService';

/* should only use if you want to overwrite current stored completed tasks range */
export function getTasksCompleted({ start, end }, userId = null) {
  return (dispatch) => {
    dispatch({
      id: TASKS_COMPLETED,
      type: TasksActionTypes.fetching,
    });
    getTasksCompletedCall(start, end, userId)
      .then((data) => {
        dispatch({
          data,
          end,
          start,
          type: TasksActionTypes.completedSuccess,
        });
        dispatch({ type: TasksActionTypes.fetchingDone });
      })
      .catch((error) => {
        dispatch({
          error,
          type: TasksActionTypes.completedFailure,
        });
        dispatch({ type: TasksActionTypes.fetchingDone });
      });
  };
}

/* Used for default ranges so that it only gets the dates it doesn't have. */
export function getTasksCompletedRange({ start, end }) {
  return (dispatch, getState) => {
    const { tasksCompletedDates: storedDates } = getState();
    const callsArray = getDatesForCalls(
      storedDates.get('start'),
      storedDates.get('end'),
      start,
      end
    );

    for (let i = 0; i < Math.min(callsArray.length, 2); i++) {
      dispatch(getTasksCompleted(callsArray[i]));
    }
  };
}

export function resetCompleted() {
  return {
    type: TasksActionTypes.completedReset,
  };
}
