import { Urls } from 'web/libs/routes';
import LivefeedTasksActionTypes from '../libs/livefeedTasksActionTypes';
import {
  DEFAULT_SEVEN_DAYS,
  TaskType,
} from 'web/liveFeed/tasks/libs/tasksConstants';
import { PRIMARY_EMAIL_ARGUMENT } from 'web/tasks/libs/tasksConstants';
import {
  startOfNextNumDays,
  startOfLastNumDays,
} from 'web/liveFeed/tasks/helpers/tasksDateHelper';
import { getTasksUncompleted as getTasksUncompletedCall } from 'web/tasks/actionCreators/tasksUncompletedActionCreators';
import {
  openAddTask as openAddTaskCall,
  openEditTask as openEditTaskCall,
  openPdv as openPdvCall,
} from 'web/liveFeed/tasks/services/tasksWindowService';
import {
  openLinkedInSearch,
  openCall,
} from 'web/liveFeed/actionCreators/liveFeedActionCreators';
import { openNewTabWithSize } from 'web/services/routerService';

export const openAddTask = () => {
  openAddTaskCall();
};

export const openEditTask = (id) => {
  openEditTaskCall(id);
};

export const openEmail = (event) => () => {
  const {
    person_id: personId,
    id: taskId,
    workflow_details: workflowDetails,
  } = event;
  const task = (taskId && `/reminder/${taskId}`) || '';

  let newTabUrl = `${Urls.composeWindow}${task}`;
  if (personId) {
    if (workflowDetails && workflowDetails.step) {
      const {
        step: { steppable_type, steppable_id },
        workflow_id: campaignId,
      } = workflowDetails;

      if (steppable_type === 'PitchTemplate') {
        newTabUrl = `${Urls.composeWindowCampaignTemplateModal
          .replace(':#personId', personId)
          .replace(':#addressId', PRIMARY_EMAIL_ARGUMENT)
          .replace(':#taskId', taskId)
          .replace(':#templateId', steppable_id)
          .replace(':#campaignId', campaignId)}`;
      }
    } else {
      newTabUrl = `${Urls.composeWindowPersonModal
        .replace(':#personId', personId)
        .replace(':#addressId', PRIMARY_EMAIL_ARGUMENT)}${task}`;
    }
  }
  openNewTabWithSize(newTabUrl, 725, 800);
};

export const taskTypeAction = (event) => {
  const { remind_type: remindType } = event;
  switch (remindType) {
    case TaskType.call:
      return {
        taskAction: openCall(event.person_id, event.id),
        taskType: TaskType.call,
      };
    case TaskType.email:
      return {
        taskAction: openEmail(event),
        taskType: TaskType.email,
      };
    case TaskType.inmail:
      return {
        taskAction: openLinkedInSearch(event.parent_name),
        taskType: TaskType.inMail,
      };
    default:
      return {
        action: null,
        type: '',
      };
  }
};

export const openPdv = (personId) => {
  openPdvCall(personId);
};

export const getTasksUncompleted = () => (dispatch, getState) => {
  const { doneFetchingTasks } = getState();
  if (!doneFetchingTasks) {
    const start = startOfLastNumDays(DEFAULT_SEVEN_DAYS);
    const end = startOfNextNumDays(DEFAULT_SEVEN_DAYS);
    dispatch(getTasksUncompletedCall({ end, start }));
  }
};

export const changeOverdueSelectedDateFilter = (dateFilter) => ({
  dateFilter,
  type: LivefeedTasksActionTypes.selectOverdueDateFilter,
});

export const changeTodoSelectedDateFilter = (dateFilter) => ({
  dateFilter,
  type: LivefeedTasksActionTypes.selectTodoDateFilter,
});
