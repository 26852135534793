import { basePost } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getActivePeopleByIds(ids, workflowId) {
  return new Promise((resolve, reject) => {
    basePost(
      Urls.campaignsActivePeople,
      { options: { all: true }, people_ids: ids, workflow_id: workflowId },
      resolve,
      reject
    );
  });
}
