import { createSelector } from 'reselect';
import {
  getIntegrationFetched,
  getIntegrationFetching,
  getAccountFetched,
  getAccountFetching,
} from 'web/salesforce/selectors/salesforceSelectors';

export const getDisconnectSalesforceLoading = (state) =>
  state.ui.settings.accountSettings.salesforce.connectionsCustomizations
    .disconnectSalesforceLoading;

export const getFetching = createSelector(
  [
    getIntegrationFetching,
    getIntegrationFetched,
    getAccountFetching,
    getAccountFetched,
  ],
  (integrationFetching, integrationFetched, accountFetching, accountFetched) =>
    integrationFetching ||
    !integrationFetched ||
    accountFetching ||
    !accountFetched
);

export const getLoading = createSelector(
  [getDisconnectSalesforceLoading],
  (disconnectSalesforceLoading) => disconnectSalesforceLoading
);
