/* eslint-disable prettier/prettier, global-require, sort-keys  */

/* global __DEV__ */

let baseUrl = 'https://toutapp.com';

if (__DEV__) {
  const environment = require('./environment');
  baseUrl = environment.BaseUrl;
}

// This is to handle CORS issues if someone navigates to www.toutapp.com
// Calls to toutapp.com will fail.

if (window && window.location.host === 'www.toutapp.com') {
  baseUrl = 'https://www.toutapp.com';
}

export const Urls = {
  activateConsentMessage: (id) => `${baseUrl}/recordings/${id}/activate`,
  activityDynamicFieldsSalesforce: '/activity_dynamic_fields/salesforce',
  addToCampaign: 'components/add_to_campaign',
  addToSalesforce: '/salesforce_proxy/sobjects/:#type',
  admin: {
    domainBlocks: {
      destroy: (id) => `/admin/domain_blocks/${id}`,
      get: '/admin/domain_blocks',
      post: '/admin/domain_blocks',
    }
  },
  adminSettings: 'admin_settings',
  authenticate: '/user_session.json',
  autoAppendDelete: `${baseUrl}/team/remove_auto_append`,
  autoAppendModify: `${baseUrl}/team/auto_append_content`,
  billing: '/payment/edit',
  billingEdit: '/billing/edit',
  blank: 'about:blank',
  boxContent: `${baseUrl}/box.json:#query`,
  bulkCallOutcomeOptions: 'bulk_call_outcome_options',
  bulkCallReasonOptions: 'bulk_call_reason_options',
  bulkEmails: '/bulk_pitches',
  bulkLiquifies: '/bulk_liquifies',
  calendar: '/calendar',
  callBase: '/modal#modal/call',
  callOutcomeOptions: '/call_outcome_options',
  callReasonOptions: '/call_reason_options',
  campaignActivePeople: `${baseUrl}/workflows/:#id/workflow_instances/active_people`,
  campaignCategoryView: '/next#campaign_categories/:categoryId',
  campaignCurrentUsers: 'workflows/:#id/users',
  campaignDetailsTab: '/next#campaign_categories/:categoryId/campaigns/:campaignId/:tab',
  campaignDetailsUser: '/workflows/for_emails',
  campaignExecuteWithPreviews: `${baseUrl}/workflows/:#id/runs/execute_with_previews`,
  campaignInstancePreviewByPId: `${baseUrl}/workflows/:#id/people/:#personId/preview`,
  campaignInstances: `${baseUrl}/workflows/:#id/workflow_instances`,
  campaignSettings: '/workflows/:#id/workflow_setting',
  campaignStep: '/workflows/:#id/steps',
  campaignTab: `${baseUrl}/next#campaigns/:#id/:#tab`,
  campaigns: `${baseUrl}/workflows.json?all=true`,
  campaignsActive: '/workflows/active',
  campaignsActivePeople: `${baseUrl}/workflows/active_people`,
  campaignsAllStop: '/workflows/stop',
  campaignsBase: `${baseUrl}/workflows`,
  campaignsCategories: `${baseUrl}/campaign_categories`,
  campaignsStop: '/workflows/:#id/workflow_instances/stop',
  campaignsStopAsync: '/workflows/:#id/workflow_instances/stop_async',
  campaignsTab: `${baseUrl}/next#campaigns`,
  campaignsUsingTemplate: `${baseUrl}/pitch_templates/:#id/workflow_pitch_templates`,
  checkLeadInSalesforce: '/salesforce/check',
  composeWindowDraft: (draftId) => `/next#compose_email/draft/${draftId}`,
  composePeopleSearch: '/searching/native_compose_window/people',
  composeWindowPerson: '/next#compose_email/person/:#personId/address/:#addressId',
  composeWindowPersonModal: '/modal#modal/compose_email/person/:#personId/address/:#addressId',
  composeWindowCampaignTemplate: '/next#compose_email/person/:#personId/address/:#addressId/template/:#templateId/reminder/:#taskId/campaign/:#campaignId',
  composeWindowCampaignTemplateModal: '/modal#modal/compose_email/person/:#personId/address/:#addressId/reminder/:#taskId/template/:#templateId/campaign/:#campaignId',
  cloneCampaign: '/workflows/:#id/clone',
  commandCenter: '/next#command_center',
  commandCenterHashRoute: '#command_center',
  commandCenterSelectedMenuItem: '/next#command_center/:selectedMenuItem',
  common: {
    contactUs: 'https://www.marketo.com/company/contact/',
    demo: 'https://www.marketo.com/demo/',
    howToShareGroupLearnMore: 'https://docs.marketo.com/display/public/DOCS/How+to+Share+a+Group',
    help: 'https://toutapp.com/help',
    learnMore: 'https://docs.marketo.com/display/public/DOCS/Marketo+Sales+Engage',
    main: 'https://www.marketo.com/software/marketo-sales-connect/',
    privacy: 'https://marketo.com/privacy/',
  },
  composeWindow: `${baseUrl}/next#compose_email`,
  composeWindowHashRoute: '#compose_email',
  composeWindowMulti: '/next#compose_emails',
  connectAdminToSalesforceNewUI: '/auth/salesforce?redirect_path=%23settings%2Fadmin%2Fsalesforce%2Fsalesforce',
  connectAdminToSalesforceSandboxNewUI: '/auth/salesforce_sandbox?redirect_path=%23settings%2Fadmin%2Fsalesforce%2Fsalesforce',
  connectBox: '/auth/box?redirect_path=%23settings%2Fmy-settings%2Fintegrations',
  connectToSalesforce: '/next#settings/crm/salesforce/about',
  connectToSalesforceNewUI: '/auth/salesforce?redirect_path=%23settings%2Faccount%2Fsalesforce%2Fsalesforce',
  connectToSalesforceSandbox: '/auth/salesforce_sandbox',
  connectToSalesforceSandboxNewUI: '/auth/salesforce_sandbox?redirect_path=%23settings%2Faccount%2Fsalesforce%2Fsalesforce',
  contact: (contactId) => `${baseUrl}/next#people/groups/everyone/${contactId}`,
  contactIntegrations: {
    all: '/contact_integrations',
    byId: (id) => `/contact_integrations/${id}`,
    byProvider: (provider) => `/contact_integrations/${provider}`,
    googleAuth: '/contact_integrations/googleoauth2',
    office365Auth: '/contact_integrations/office365',
  },
  connectAdminToSalesforceOnboarding: '/auth/salesforce?redirect_path=%23onboarding%2Fcrm_salesforce_connected_successfully',
  connectToSalesforceOnboarding: '/auth/salesforce?redirect_path=%23onboarding%2Fcrm_salesforce_connected&onboardingConnectingCrm=true',
  contactPage: `${baseUrl}/next#people`,
  content: '/attachments',
  contentCollection: '/file_attachments/collection',
  contentPartners: '/subscription/content_partners',
  conversation_filter: '/conversation_filters/:#id',
  conversation_filters: `/conversation_filters`,
  createNewAccount: '/account',
  createNewTask: (sourceOpener) => `${baseUrl}/components/reminder?sourceOpener=${sourceOpener}`,
  customTrackingDomain: '/custom_tracking_domain',
  deliveryChannels: (id = '') => `${baseUrl}/delivery_channels/${id}`,
  onboardingDeliveryChannels: `${baseUrl}/delivery_channels.json`,
  onboardingAuthentications: `${baseUrl}/authentications.json`,
  diagnostics: {
    destroyActivity: (userId) => `${baseUrl}/users/${userId}/diagnostics/destroy_from_sync`,
    getActivities: (userId) => `${baseUrl}/users/${userId}/diagnostics`,
    getActivityEmail: (pitchId) => `${baseUrl}/next#emails/${pitchId}`,
    getStats: (userId, source) => `${baseUrl}/users/${userId}/diagnostics/stats?source=${source}`,
    retryActivity: (userId) => `${baseUrl}/users/${userId}/diagnostics/retry`,
  },
  drafts: `${baseUrl}/next#command_center/emails/pending/drafts`,
  deleteCall: (id) => `${baseUrl}/calls/${id}`,
  dontHaveAccount: 'https://www.marketo.com/software/marketo-sales-connect/',
  dynamicFields: '/dynamic_fields',
  emailArchive: `${baseUrl}/pitches/:#id/archive`,
  emailAuth: {
    google: `${baseUrl}/auth/google_oauth2?emailSync=true`,
    office365: `${baseUrl}/auth/office365`,
    owaOffice365: `${baseUrl}/auth/office365?owaOffice365Connecting=true`,
  },
  onboardingConnectionGmail: `${baseUrl}/auth/google_oauth2?onboardingConnectingGmail=true`,
  onboardingConnectionOwaOffice365: `${baseUrl}/auth/office365?onboardingConnectingOwaOffice365=true`,
  onboardingConnectionOffice365: `${baseUrl}/auth/office365?onboardingConnectingOffice365=true`,
  emailConnectionTab: '/next#settings/my-settings/email_settings/email-connection',
  emailConnectionTabActive: `${baseUrl}/next#settings/my-settings/email_settings/email-connection`,
  emailIdentities: `${baseUrl}/identities`,
  emailLimits: `${baseUrl}/email_limits`,
  emailMarkSuccess: `${baseUrl}/pitches/:#id/success`,
  emailRetrySend: (id) => `${baseUrl}/pitches/${id}/retry`,
  emailServiceProxy: `${baseUrl}/email_service`,
  emailSettings: 'settings/my-settings/email_settings/:tab',
  emailSettingsEditIdentityDeliverability: 'settings/my-settings/email_settings/identities/edit/:id?scrollTo=deliverability',
  emailSettingsEditIdentitySlideout: 'settings/my-settings/email_settings/identities/edit/:id',
  emailSettingsHashRoute: '#settings/my-settings/email_settings',
  emailSettingsNewIdentitySlideout: 'settings/my-settings/email_settings/identities/new',
  emailUnarchive: `${baseUrl}/pitches/:#id/unarchive`,
  emails: `${baseUrl}/pitches`,
  emailsBulk: `${baseUrl}/pitches/bulk`,
  emailsId: (id) => `${baseUrl}/pitches/${id}.json`,
  emailsMarkAsRead: 'pitches/mark_as_read',
  emailsSubTab: (tab, subTab, param) => `/next#command_center/emails/${tab}/${subTab}${param ? `/${param}` : ''}`,
  emailsTab: '/next#command_center/emails/:tab',
  emailThrottlingSettingsEndpoint: 'throttling_settings',
  emailsUnreadCounter: 'pitches/unread',
  esFetch: `${baseUrl}/fetch.json`,
  esFetchIds: `${baseUrl}/fetch_ids.json`,
  events: `${baseUrl}/events`,
  exchangeOnprem: `${baseUrl}/auth/exchange_onprem`,
  exportAPI: `${baseUrl}/search/export.json`,
  favorites: `${baseUrl}/favorites`,
  favoritesBulk: `${baseUrl}/bulk_favorites`,
  fileUpload: '/attachments.json',
  getReplyEmailDraft: 'pitches/reply_draft',
  getStarted: '/nux/go#getting-started',
  gmailConnectToSalesforceUI: '/auth/salesforce?redirect_path=/gmail_plugin%23salesforce_connected_successfully',
  gmailConnectToGoogleUI: '/auth/google_oauth2?gmailPluginConnectedSuccessfully=true',
  gong: {
    getOpportunities: '/gong/deals',
    postOpportunities: '/gong/deals',
    putOpportunities: (id) => `/gong/deals/${id}`,
    show: '/components/gong'
  },
  googleAuth: '/auth/google_oauth2?emailSync=true',
  groupId: (id) => `${baseUrl}/groups/${id}`,
  groupMembers: `${baseUrl}/groups/:#id/group_members`,
  groupMembersBulk: `${baseUrl}/group_members`,
  groupPeople: `${baseUrl}/groups/:#id/people.json`,
  groupPeopleIds: `${baseUrl}/groups/:#id/people_ids.json`,
  groupUpdateSharedWith: 'groups/:#id/update_shared_with',
  groups: `${baseUrl}/groups.json`,
  identities: (id = '') => `${baseUrl}/identities/${id}`,
  importPeopleCsvMeta: `${baseUrl}/csvs/:file_id.json`,
  inMailBase: 'https://www.linkedin.com/search/results/all/?keywords=',
  invitations: (id = '') => `/invitations/${id}`,
  invitationsEmail: (token = '') => `/invitations/${token}/admin`,
  invitationsBatchDestroy: '/invitations/batch_destroy',
  invitationsBatchResend: '/invitations/batch_resend',
  invitationsInviteMany: '/invitations/invite_many',
  invitationsResend: (id = '') => `/invitations/${id}/resend`,
  invitationsResendByEmail: (email = '') => `/invitations/resend_by_email?email=${email}`,
  inviteNewUsers: '/invitations/invite_many',
  isPersonShared: `${baseUrl}/people/is_person_shared_with_user`,
  liquify: '/liquifies',
  liveFeed: '/next#live',
  liveFeedAnalytics: `${baseUrl}/live_feed/analytics.json`,
  liveFeedHeartBeats: `${baseUrl}/heart_beats`,
  liveFeedInitPolling: `${baseUrl}/live_feed`,
  liveFeedPitch: (pitchId) => `${baseUrl}/next#command_center/emails/pitch/${pitchId}`,
  loginGoogle: '/auth/google_oauth2?login_google=true',
  loginOWADirect: `${baseUrl}/login?sourceOpener=OWA&direct_login=true`,
  loginPage: '/login',
  loginSalesforce: '/auth/salesforce',
  loginSalesforceWithInvitation: (code, type, useSandbox) => `/auth/salesforce${useSandbox ? "_sandbox" : ""}?code=${code}&type=${type}&redirect_path=%23onboardingCrm%2Fsuccess`,
  loginSso: '/saml/new',
  loginSsoInit: '/saml/init',
  marketoActivities: `${baseUrl}/marketo/activities`,
  marketoAdminSettings: '/next#settings/marketo-admin',
  marketoBestBets: '/marketo/best_bets',
  marketoBulkAction: `${baseUrl}/auth/marketo/users/bulk`,
  marketoBulkActionAsync: `${baseUrl}/auth/marketo/users/bulk_async`,
  marketoCampaigns: '/marketo/campaigns',
  marketoCampaignsLaunch: '/marketo/campaigns/:#id?update_action=trigger',
  marketoIntegrationConnect: `${baseUrl}/auth/marketo/users`,
  marketoInterestingMoments: `${baseUrl}/marketo/interesting_moments`,
  marketoSubDetails: '/marketo/current_subscription/details',
  marketoWorkspaces: `${baseUrl}/auth/marketo/workspaces`,
  manageRecordingNotice: `${baseUrl}/recordings`,
  manageRecordingNoticeById: (id) => `${baseUrl}/recordings/${id}`,
  deleteRecordingNotice: (id) => `${baseUrl}/recordings/${id}`,
  manageRecordingNoticeAudio: (id, publicKey, fileName) => `${baseUrl}/f/${id}/${publicKey}/${fileName}.json`,
  newEmail: 'https://outlook.office.com/?path=/mail/action/compose&to=:#email',
  next: {
    chrome_extension: '/chrome_extension',
    emails: '/next#emails',
    hash: '/next#',
    outlook_plugin: '/outlook_plugin',
    settingsSalesforce: '/next#settings/crm/salesforce/configure',
    settings_crm: '/next#settings/crm',
    settings_email: (tab) => `/next#settings/my-settings/email_settings/${tab}`
  },
  notes: `${baseUrl}/notes`,
  notesId: `${baseUrl}/notes/:#id`,
  onboarding: {
    makeUserNew: '/users/change_user_status',
    welcome: `${baseUrl}/next#onboarding/welcome`,
  },
  owaConnectAdminToSalesforceNewUI: '/auth/salesforce?redirect_path=/next/owa%23salesforce_connected_successfully',
  owaConnectAdminToGoogleNewUI: '/auth/google_oauth2?owaGmailConnectedSuccessfully=true',
  owaConnectAdminToSSONewUI: '/saml/new?outlook=true',
  owaConnectAdminToSalesforceSandboxNewUI: '/auth/salesforce_sandbox?redirect_path=/next/owa%23salesforce_connected_successfully',
  passwordReset: '/password_reset',
  passwordResetNew: '/password_reset/new',
  people: `${baseUrl}/people`,
  peopleByIds: `${baseUrl}/people/0.json`,
  peopleUnsubscribe: `${baseUrl}/people/bulk_unsubscribe`,
  peopleCompliance: `${baseUrl}/people`,
  peopleCreate: '/people/create',
  peopleEmailLookup: `${baseUrl}/people/email_lookup`,
  peopleGroup: `people/groups/:group_id`,
  peopleLookup: `${baseUrl}/people/lookup.json`,
  peoplePageUrl: `${baseUrl}/next#people`,
  permissionsCampaignsCategories: `${baseUrl}/permissions/workflow_categories`,
  permissionsTemplatesCategories: `${baseUrl}/permissions/template_categories`,
  person: `${baseUrl}/people/:#id`,
  personById: `${baseUrl}/people/:#id.json`,
  personDetailsBase: '/next#people/groups/everyone',
  personDetailsGroup: 'people/groups/:group_id/:person_id',
  personDetailsGroupUrl: `${baseUrl}/next#people/groups/:group_id/:person_id`,
  personDetailsUnsubscribed: (personId) => `people/groups/unsubscribed-contacts/${personId}`,
  personExists: `${baseUrl}/people/validate_address`,
  personInfo: `${baseUrl}/people.json`,
  personUnsubscribe: `${baseUrl}/people/:#id/unsubscribe`,
  popupCalendar: '/owa/calendar_popup',
  productTour: {
    welcome: `${baseUrl}/next#product_tour/welcome`,
    steps: `${baseUrl}/next#product_tour/steps`,
  },
  pusherAuth: `${baseUrl}/pusher/auth`,
  recentWebsiteTrackingEvents: `${baseUrl}/website_tracking`,
  recordings: `${baseUrl}/recordings`,
  releaseNotes: `${baseUrl}/release_notes`,
  salesforceLaunch: 'https://www.salesforce.com/',
  salesforce: (tab = '') => `settings/admin/salesforce/${tab}`,
  salesforceAccount: (tab = '') => `settings/account/salesforce/${tab}`,
  salesforceAccountEntity: '/salesforce/account',
  salesforceAdmin: (tab = '') => `settings/admin/salesforce/${tab}`,
  salesforceAppExchange: "https://appexchange.salesforce.com/listingDetail?listingId=a0N30000001SVZmEAO",
  salesforceCustomizationApi: '/salesforce_customization',
  salesforceCustomizationProfiles: '/salesforce_customization/profiles',
  salesforceFind: '/salesforce/lookup',
  salesforceImport: `${baseUrl}/imports/salesforce.json`,
  salesforceInstance: `${baseUrl}/salesforce/meta`,
  searchPeople: `${baseUrl}/people/bulk_lookup`,
  selectedEmail: (emailId) => `#command_center/emails/pitch/${emailId}`,
  setNewPassword: (id) => `/password_reset/${id}`,
  settings: {
    accountDetails: `/users/update_profile`,
    localeSettings: `/account/update_locale_settings`,
    timezoneSettings: `/account/update_my_settings`,
    yourIntegrations: `/integrations/primary.json`,
  },
  settingsGeneral: (tab = '') => `settings/admin/general/${tab}`,
  sharableCategories: `${baseUrl}/template_categories/sharable`,
  shareCampaign: '/workflows/:#id/update_shared_with',
  sharingPermissionsEnabled: `${baseUrl}/permissions/enable`,
  slackAuth: `${baseUrl}/auth/slack?redirect_path=%23settings%2Fmy-settings%2Fintegrations`,
  smtpServerSettingsTab: '/next#settings/my-settings/email_settings/smtp-server',
  accountSalesforceConnecting: '/next#settings/account/salesforce',
  // teams services
  subscriptionDeliveryChannels: (id = '') => `${baseUrl}/subscription/delivery_channels/${id}`,
  subscriptionSettings: '/subscription/settings',
  subscriptionUsers: '/teams/:#id/team_members',
  // team members services
  tags: `${baseUrl}/tags/of_tag`,
  tasks: `${baseUrl}/reminders`,
  tasksBulk: `${baseUrl}/reminders/bulk_update`,
  tasksBulkAsync: `${baseUrl}/reminders/bulk_update_async`,
  tasksCompleted: '/reminders/completed.json',
  tasksEditWindow: (taskId, sourceOpener) => `${baseUrl}/components/reminder?id=${taskId}&sourceOpener=${sourceOpener}`,
  // invitations services
  tasksUncompleted: '/reminders/uncompleted.json',
  tasksUpdate: `${baseUrl}/reminders/:#id`,
  teamManagement: (tab = '') => `settings/admin/team-management/${tab}`,
  teamMembers: (teamId = '') => `settings/admin/team-management/team-members/${teamId}`,
  teamSettings: '/settings?team=true',
  // ui routes
  teamSmtpServerSettingsTab: '/next#settings/email-server',
  teamDeliveryChannelServer: '/next#settings/admin/general/team-delivery-channel',
  teams: (id = '') => `/teams/${id}`,
  teamsAddTeamMembers: '/teams/add_team_members',
  teamsGrantAdmin: (id) => `/teams/${id}/grant_admin`,

  teamsRemoveTeamMembers: (teamId) => `/teams/${teamId}/remove_team_members`,
  teamsRevokeAdmin: (id) => `/teams/${id}/revoke_admin`,
  teamsTeamMembers: (teamId) => `/teams/${teamId}/team_members`,
  templateBypassUnsubscribe: (id) => `/pitch_templates/${id}/bypass_unsubscribe`,
  templateCategories: 'template_categories',
  templateCategory: '/next#template_categories/:category_id',
  templateCategoryAndTemplate: '/next#template_categories/:category_id/template/:template_id',
  templateCategoryView: '/next#template_categories/:categoryId',  // can't use ${} due to dynamically defined
  templateDetails: '/next#templates/:templateId',
  templateEmails: `${baseUrl}/pitch_templates/:#id/pitches.json`,
  templateStats: `${baseUrl}/pitch_templates/:#id/stats`,
  templateUpdateSharedWith: 'pitch_templates/:#id/update_shared_with',
  templates: '/pitch_templates',
  templatesCategories: `${baseUrl}/template_categories`,
  templateCategoriesHashRoute: '#template_categories',
  templatesHashRoute: '#templates',
  templatesId: '/pitch_templates/:#id',
  templatesRecommended: '/pitch_templates/recommended.json',
  templatesTab: '/next#templates',
  templatesTag: '/tags/:#id/:#action',
  templatesUpdateAsync: '/pitch_templates/bulk_action_async',
  templatesUpdateSync: '/pitch_templates/bulk_action_sync',
  timezones: '/timezones.json',
  topNavbar: {
    analytics: `/next#analytics`,
    campaign_categories: `/next#campaign_categories`,
    campaigns: `/next#campaigns`,
    emailSettings: `${baseUrl}/next#settings/my-settings/email_settings`,
    emails: `${baseUrl}/next#emails`,
    feedback: 'https://forms.office.com/r/vgnCvdSFCB',
    getHelp: 'http://support.marketo.com/',
    gettingStarted: (locale) =>  `https://experienceleague.adobe.com/docs/marketo/using/product-docs/marketo-sales-connect/getting-started/sales-connect-overview.html?lang=${locale}`,
    gong: `${baseUrl}/next#gong`,
    home: `${baseUrl}/next`,
    knowledgeBase: (locale) => `https://experienceleague.adobe.com/docs/marketo/using/home.html?lang=${locale}`,
    liveFeed: `${baseUrl}/live`,
    myProfile: `${baseUrl}/next#settings/my-settings/my-profile`,
    mySubscriptionPlan: (id) => `${baseUrl}/next#settings/my-settings/subscriptions/${id}`,
    people: `/next#people`,
    relationships: `/next#people`,
    settings: `${baseUrl}/next#settings`,
    template_categories: `/next#template_categories`,
    templates: `/next#templates`,
  },
  toutIt: '/eng/stage',

  // Tracking
  toutItLinkify: '/owa/eng/stage_api',
  toutItStage: '/owa/eng/stage',
  trackingAdmin: (tab = '') => `settings/admin/tracking/${tab}`,
  unsubscribeHistory: '/people/:#id/unsubscribe_history',
  unsubscribeManual: '/dont-bug-me/:#id/new_unsubscribe/:#email',
  unsubscribeOptInReason: '/unsubscribes/unsubscribe_opt_in_reasons',
  unsubscribeOptions: '/unsubscribe_options.json',
  updateCampaignTemplates: `${baseUrl}/pitch_templates/:#id/workflow_pitch_templates/propagate_changes`,
  updateUnsubscribe: '/account/update_unsubscribe',
  upsell: '/google/upsell',
  upsellLearnMore: 'https://docs.marketo.com/display/public/DOCS/Marketo+Sales+Engage',
  user: '/users/:#id.json',
  userCache: `${baseUrl}/user_cache/:#userId`,
  userDomainBlocks: `/domain_blocks`,
  userManagement: (tab = '') => `settings/admin/user-management/${tab}`,
  userMetric: '/user_metric.json',
  userSession: `${baseUrl}/users/user_session.json`,
  userSettings: '/settings',
  users: (userId) => `/users/${userId}`,
  usersBatchDestroy: '/users/batch_destroy',
  validatePasswordReset: (code) => `/password_reset/${code}/validate`,

  // top navbar
  usersUpdateTeams: (userId) => `/users/${userId}/update_teams`,
  verifications: `${baseUrl}/verifications/new`
};
