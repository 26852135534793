import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import LabeledInput from 'components/labeledInput';
import './dealSize.scss';
import I18N from 'languages';

class DealSize extends Component {
  handleChange = (e) => {
    const { changeDealSize, clearError } = this.props;

    clearError();
    changeDealSize(e.target.value);
  };

  render() {
    const { placeholder, dealSize, error } = this.props;
    let errorClass = '';
    let errorMessage = '';

    if (!isEmpty(error) && error.amount) {
      errorClass = ' error';
      errorMessage = error.amount
        ? I18N.getConstStr('web.gong', error.amount)
        : error.amount;
    }

    const className = `deal-size-input-group${errorClass}`;

    return (
      <div>
        <div className={className}>
          <span class="input-group-currency-symbol">
            {I18N.CURRENCY_SYMBOL}
          </span>
          <LabeledInput
            classesInput="deal-size-input"
            classesLabel="deal-size-label"
            autoFocus={false}
            id="gong-deal-size"
            onChange={this.handleChange}
            messageKey="gong.dealSize"
            placeholder={placeholder}
            ref="dealSize"
            sideLabel
            toutUiStyle
            type="text"
            value={dealSize}
            maxLength={21}
          />
        </div>
        {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
      </div>
    );
  }
}

DealSize.propTypes = {
  placeholder: PropTypes.string.isRequired,
  dealSize: PropTypes.string.isRequired,
  changeDealSize: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default DealSize;
