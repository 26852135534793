import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { AttachmentTypes, ProgressStatus } from 'libs/constantsShared';
import Status from './status';
import './file.scss';

class File extends Component {
  render() {
    const {
      classes,
      className,
      file,
      onClickX,
      showDate,
      showSize,
    } = this.props;

    return (
      <div className={`file ${className || classes}`}>
        <div
          className={this._getTypeClasses(file.file_file_name || file.name)}
        />
        <div className="title margin-left-half text-overflow">
          {this._getName(file)}
          {this._getSize(file.file_file_size, showSize)}
        </div>
        <div>
          {this._getDate(file.created_at, showDate, file.status)}
          <Status file={file} />
          {onClickX &&
          (!file.status || file.status !== ProgressStatus.uploading) ? (
            <div className="tout-icon-close" onClick={this.onClickX} />
          ) : null}
        </div>
      </div>
    );
  }

  onClickX = () => {
    const { file, onClickX } = this.props;
    if (onClickX && file) {
      onClickX(file);
    }
  };

  /*eslint complexity: [1, 12]*/
  _getTypeClasses(name) {
    const type = name.match(/\.([^.]+)$/);
    if (!type || !type.length) {
      return 'tout-icon-other';
    }

    switch (type[1]) {
      case AttachmentTypes.doc:
      case AttachmentTypes.docx:
        return 'tout-icon-doc';
      case AttachmentTypes.ppt:
      case AttachmentTypes.pptx:
        return 'tout-icon-ppt';
      case AttachmentTypes.jpg:
      case AttachmentTypes.png:
        return 'tout-icon-img';
      case AttachmentTypes.pdf:
        return 'tout-icon-pdf';
      case AttachmentTypes.xls:
        return 'tout-icon-excel';
      default:
        return 'tout-icon-other';
    }
  }

  _getName(file) {
    if (file.file_file_name) {
      return (
        <span className="text-overflow">
          <a href={file.document_url} target="_blank" rel="noreferrer noopener">
            {file.file_file_name}
          </a>
        </span>
      );
    }
    return <div className="text-overflow">{file.name}</div>;
  }

  _getSize(size, show) {
    const { formatMessage } = this.props.intl;
    let unit = '';
    let sized = '';

    if (!show || !size) {
      return null;
    }
    if (size < 1000) {
      unit = formatMessage({ id: 'common.sizeByte' });
      sized = size;
    } else if (size < 1000000) {
      unit =
        formatMessage({ id: 'common.sizeK' }) +
        formatMessage({ id: 'common.sizeByte' });
      sized = Math.round(size / 1000);
    } else if (size < 1000000000) {
      unit =
        formatMessage({ id: 'common.sizeM' }) +
        formatMessage({ id: 'common.sizeByte' });
      sized = Math.round(size / 1000000);
    } else if (size < 1000000000000) {
      unit =
        formatMessage({ id: 'common.sizeG' }) +
        formatMessage({ id: 'common.sizeByte' });
      sized = Math.round(size / 1000000000);
    }

    return <span>{!sized ? '' : ` - ${sized}${unit}`}</span>;
  }

  _getDate(datetime, show, status) {
    if (!show || !datetime || status === ProgressStatus.finished) {
      // if no date, or was just uploaded
      return null;
    }
    return <div className="date">{moment(datetime).format('L')}</div>;
  }
}

File.propTypes = {
  classes: PropTypes.string, //legacy
  className: PropTypes.string,
  file: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  onClickX: PropTypes.func,
  showDate: PropTypes.bool,
  showSize: PropTypes.bool,
};

File.defaultProps = {
  classes: '',
  className: '',
  onClickX: undefined, // func used as truthy
  showDate: false,
  showSize: false,
};

export default injectIntl(File);
