import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LiveFeed from 'web/liveFeed/components/liveFeed';
import { getAnalytics } from 'web/liveFeed/selectors/liveFeedSelectors';
import {
  startupLiveFeed,
  selectTab,
} from 'web/liveFeed/actionCreators/liveFeedActionCreators';
import * as navBarActionCreators from 'web/navBar/actionCreators/navBarActionCreators';
import { updateChartEvent } from '../actionCreators/liveFeedHeaderActionCreators';
import { clearHeartBeat } from '../services/liveFeedService';

class LiveFeedContainer extends Component {
  componentWillMount() {
    this.props.actionCreators.startupLiveFeed();
  }

  componentWillUnmount() {
    clearHeartBeat();
  }

  render() {
    return <LiveFeed showMarketoBorder showToutBorder {...this.props} />;
  }
}

LiveFeedContainer.propTypes = {
  analytics: PropTypes.object.isRequired,
  actionCreators: PropTypes.object.isRequired,
  dealInformation: PropTypes.object.isRequired,
  isOwaMode: PropTypes.bool,
};

LiveFeedContainer.defaultProps = {
  isOwaMode: false,
};

const mapStateToProps = (state) => ({
  analytics: getAnalytics(state),
  chartEvent: state.liveFeedChartEvent,
  dealInformation: state.liveFeedDealInformation,
  engageFeatureEnabled: state.liveFeedEngageUserAuthentication,
  selectedTabValue: state.liveFeedSelectedTabValue,
});

const mapDispatchToProps = {
  startupLiveFeed,
  selectTab,
  updateChartEvent,
  ...navBarActionCreators,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actionCreators: dispatchProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LiveFeedContainer);
