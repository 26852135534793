import { createAction } from '@reduxjs/toolkit';
import {
  beforeChangeSmartFilterAdvancedSearchAction,
  afterChangeSmartFilterAdvancedSearchAction,
} from 'web/commandCenter/modules/emailGridAdvancedSearch/actionCreators/openAdvancedSearchActionCreators';

const setSmartFilterAction = createAction('SMART_FILTERS_SET_ITEM');
export const setSmartFilterType = setSmartFilterAction.type;

export const setSmartFilter = (params) => (dispatch) => {
  dispatch(beforeChangeSmartFilterAdvancedSearchAction(params));
  dispatch(setSmartFilterAction(params));
  dispatch(afterChangeSmartFilterAdvancedSearchAction(params));
};

const removeSmartFilterAction = createAction('SMART_FILTERS_REMOVE_ITEM');
export const removeSmartFilterType = removeSmartFilterAction.type;

export const removeSmartFilter = (params) => (dispatch) => {
  dispatch(beforeChangeSmartFilterAdvancedSearchAction(params));
  dispatch(removeSmartFilterAction(params));
  dispatch(afterChangeSmartFilterAdvancedSearchAction(params));
};

export const copySmartFilters = createAction('SMART_FILTERS_COPY_COLLECTION');
export const setSmartFilterCollection = createAction(
  'SMART_FILTERS_SET_COLLECTION'
);
