import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './item.scss';

const CLASS_CONTAINER = 'top-navbar-item';
const CLASS_CONTAINER_ACTIVE = 'top-navbar-item-active';
const CLASS_LINK = 'top-navbar-item-link';
const CLASS_LINK_ACTIVE = 'top-navbar-item-link-active';
const CLASS_ITEM_RIGHT = 'top-navbar-item-right-part';
const CLASS_ICON = 'top-navbar-item-icon';
const CLASS_BADGE = 'top-navbar-item-badge';
const CLASS_TITLE = 'top-navbar-item-title';

export class Item extends React.Component {
  render() {
    const { children, link, icon, titleId, badge } = this.props;

    const content = (
      <React.Fragment>
        {icon && this.getIconElement()}
        {badge && this.getBadgeElement()}
        {titleId && this.getTitleElement()}
        {children}
      </React.Fragment>
    );

    if (link) {
      return this.renderAsLink(content);
    }
    return this.renderAsIs(content);
  }

  renderAsLink(content) {
    const { link } = this.props;
    const containerClassNames = { [CLASS_CONTAINER_ACTIVE]: this.isActive() };

    return this.decorateWithContainer(
      <NavLink
        to={link}
        className={CLASS_LINK}
        isActive={this.isActive}
        activeClassName={CLASS_LINK_ACTIVE}
      >
        {content}
      </NavLink>,
      containerClassNames
    );
  }

  renderAsIs(content) {
    const { isActive, onClick } = this.props;
    const classNames = classnames(CLASS_ITEM_RIGHT, {
      [CLASS_LINK_ACTIVE]: isActive,
    });
    return this.decorateWithContainer(
      <span className={classNames} onClick={onClick}>
        {content}
      </span>
    );
  }

  decorateWithContainer(content, additionalClassName) {
    const { className } = this.props;
    const classNames = classnames(
      CLASS_CONTAINER,
      className,
      additionalClassName
    );
    return <li className={classNames}>{content}</li>;
  }

  getIconElement() {
    const { icon } = this.props;
    return <span className={classnames(CLASS_ICON, icon)} />;
  }

  getBadgeElement() {
    const { badge } = this.props;
    return <span className={classnames(CLASS_BADGE)}>{badge}</span>;
  }

  getTitleElement() {
    const { titleId, titleClassName } = this.props;
    return (
      <span className={classnames(CLASS_TITLE, titleClassName)}>
        <FormattedMessage id={titleId} />
      </span>
    );
  }

  isActive = () => {
    /**
     * React doesn't match URL `by hash`
     * (Backbone links contain hashes, like `next#command_center`),
     * that's why a custom `isActive` method is written.
     * Once we completely migrate to React this method will be redundant.
     */
    const {
      link,
      location: { pathname, hash },
    } = this.props;
    const currentUrl = `${pathname}${hash}`;

    const { isActive } = this.props;
    if (isActive) {
      return isActive(currentUrl);
    }

    return currentUrl.startsWith(link);
  };
}

Item.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  titleId: PropTypes.string,
  titleClassName: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  isActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClick: PropTypes.func,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location: PropTypes.object.isRequired,
};

Item.defaultProps = {
  children: null,
  className: null,
  titleId: null,
  titleClassName: null,
  icon: null,
  link: null,
  isActive: null,
  onClick: null,
  badge: null,
};

export default withRouter(injectIntl(Item));
