import {
  emailServiceGet,
  emailServicePost,
  emailServicePut,
} from 'web/services/emailService';
import { Urls } from 'web/libs/routes';

export const getEmailThrottlingSettings = (data) =>
  emailServiceGet(Urls.emailThrottlingSettingsEndpoint, data);

export const createEmailThrottlingSettings = (data) =>
  emailServicePost(Urls.emailThrottlingSettingsEndpoint, data);

export const updateEmailThrottlingSettings = (data) =>
  emailServicePut(Urls.emailThrottlingSettingsEndpoint, data);
