import { baseGet, basePut, baseDelete } from 'web/services/api';
import { Urls } from 'web/libs/routes';

export function getCategoryPermissionsCampaigns(categoryId) {
  return new Promise((resolve, reject) => {
    baseGet(
      `${Urls.permissionsCampaignsCategories}/${categoryId}`,
      {},
      resolve,
      reject
    );
  });
}

export function updateCategoryPermissionCampaign(
  categoryId,
  teamIds = [],
  shareEveryone = false
) {
  return new Promise((resolve, reject) => {
    basePut(
      `${Urls.permissionsCampaignsCategories}/${categoryId}`,
      {
        share_everyone: shareEveryone,
        team_ids: teamIds,
      },
      resolve,
      reject
    );
  });
}

export function deleteCategoryPermissionCampaign(categoryId, teamIds = []) {
  return new Promise((resolve, reject) => {
    baseDelete(
      `${Urls.permissionsCampaignsCategories}/${categoryId}`,
      { team_ids: teamIds },
      resolve,
      reject
    );
  });
}

export function getCategoryPermissionsTemplates(categoryId) {
  return new Promise((resolve, reject) => {
    baseGet(
      `${Urls.permissionsTemplatesCategories}/${categoryId}`,
      {},
      resolve,
      reject
    );
  });
}

export function updateCategoryPermissionTemplate(
  categoryId,
  teamIds = [],
  shareEveryone = false
) {
  return new Promise((resolve, reject) => {
    basePut(
      `${Urls.permissionsTemplatesCategories}/${categoryId}`,
      {
        share_everyone: shareEveryone,
        team_ids: teamIds,
      },
      resolve,
      reject
    );
  });
}

export function deleteCategoryPermissionTemplate(categoryId, teamIds = []) {
  return new Promise((resolve, reject) => {
    baseDelete(
      `${Urls.permissionsTemplatesCategories}/${categoryId}`,
      { team_ids: teamIds },
      resolve,
      reject
    );
  });
}

export function updateSharingPermissionsEnabled(sharingEnabled = true) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.sharingPermissionsEnabled,
      {
        enabled: sharingEnabled,
      },
      resolve,
      reject
    );
  });
}

export function getSharingPermissionsEnabled() {
  return new Promise((resolve, reject) => {
    baseGet(Urls.sharingPermissionsEnabled, {}, resolve, reject);
  });
}
