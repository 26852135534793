import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedHTMLMessage } from 'react-intl';
import PreviewRecipients from './previewRecipients';
import PreviewCompose from './previewCompose';
import BlockedPreview from './blockedPreview';
import FailedImportPreview from './failedImportPreview';
import './previewEmail.scss';

class PreviewEmail extends Component {
  render() {
    const {
      attachmentsCount,
      changePreview,
      currentId,
      viewType,
      recipients,
      failedImports,
    } = this.props;
    return (
      <div>
        <div className="margin-bottom-full">
          <FormattedHTMLMessage id="campaigns.previewEmail.instructions" />
        </div>
        <div
          className={classNames('previewEmail', {
            'has-attachments': attachmentsCount,
          })}
        >
          <div className="people-sidebar">
            <PreviewRecipients
              changePreview={changePreview}
              currentPersonId={currentId}
              recipients={recipients}
              failedImports={failedImports}
            />
          </div>
          <div className="preview-content">
            {viewType === 'importFailures' ? (
              <FailedImportPreview failedImports={failedImports} />
            ) : (
              this.renderImportedPeople()
            )}
          </div>
        </div>
      </div>
    );
  }

  renderImportedPeople = () => {
    const {
      attachmentsCount,
      currentId,
      editorId,
      getImageUrl,
      loading,
      preview,
      recipients,
      updatePreview,
    } = this.props;
    return recipients.map[currentId].blockedReason ? (
      <BlockedPreview
        blockedReason={recipients.map[currentId].blockedReason}
        campaignBy={recipients.map[currentId].activeCampaignBy}
        campaignName={recipients.map[currentId].activeCampaignName}
        name={recipients.map[currentId].name}
        maxCampaignLimit={recipients.map[currentId].max_campaign_limit}
      />
    ) : (
      <PreviewCompose
        attachmentsCount={attachmentsCount}
        editorId={editorId}
        getImageUrl={getImageUrl}
        preview={preview}
        loading={loading}
        update={updatePreview}
      />
    );
  };
}

PreviewEmail.propTypes = {
  attachmentsCount: PropTypes.number.isRequired,
  changePreview: PropTypes.func.isRequired,
  currentId: PropTypes.number.isRequired,
  viewType: PropTypes.string.isRequired,
  editorId: PropTypes.string.isRequired,
  getImageUrl: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  preview: PropTypes.object.isRequired,
  recipients: PropTypes.object.isRequired,
  updatePreview: PropTypes.func.isRequired,
  failedImports: PropTypes.object,
};

PreviewEmail.defaultProps = {
  loading: false,
  failedImports: {},
};

export default PreviewEmail;
