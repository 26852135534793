import React from 'react';
import { connect } from 'react-redux';
import MarketoNonAdminPage from 'web/marketoNonAdmin/components/nonAdminPage/marketoNonAdminPage';
import { getMarketoIntegration } from 'web/marketoSettings/actionCreators/adminSetupActionCreators';
import { getSalesforceConnectLink } from 'web/salesforce/selectors/salesforceSelectors';

const MarketoNonAdminContainer = (props) => <MarketoNonAdminPage {...props} />;

const mapStateToProps = (state) => ({
  connectionInfo: state.marketoAdminConnectionInfo,
  connectionState: state.marketoAdminConnectionState,
  saleforceConnectLink: getSalesforceConnectLink(state),
});

const mapDispatchToProps = {
  getMarketoIntegration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketoNonAdminContainer);
