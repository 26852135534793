import TasksActionTypes from '../libs/tasksActionTypes';

export function tasksInit(state = false, { type = '' }) {
  switch (type) {
    case TasksActionTypes.init:
      return true;
    default:
      return state;
  }
}

export function tasksFetching(state = '', { type = '', id = '' }) {
  switch (type) {
    case TasksActionTypes.fetching:
      return id;
    case TasksActionTypes.fetchingDone:
      return '';
    default:
      return state;
  }
}
