import MarketoSettingsActionTypes from '../libs/marketoSettingsActionTypes';
import {
  TabValues,
  MarketoSettingsAlertIds,
} from '../libs/marketoSettingsConstants';

export function marketoAdminSelectedTabValue(
  state = TabValues.setup,
  { type = '' }
) {
  switch (type) {
    case MarketoSettingsActionTypes.tabSelected.setup:
      return TabValues.setup;
    case MarketoSettingsActionTypes.tabSelected.settings:
      return TabValues.settings;
    case MarketoSettingsActionTypes.tabSelected.userAccess:
      return TabValues.userAccess;
    default:
      return state;
  }
}

export function marketoAdminAlertId(state = null, { type = '' }) {
  switch (type) {
    case MarketoSettingsActionTypes.getConnectionFailed:
      return MarketoSettingsAlertIds.connectionFailed;
    case MarketoSettingsActionTypes.userAccess.grantAccessSuccess:
      return MarketoSettingsAlertIds.grantAccessSuccess;
    case MarketoSettingsActionTypes.userAccess.revokeAccessSuccess:
      return MarketoSettingsAlertIds.revokeAccessSuccess;
    case MarketoSettingsActionTypes.userAccess.grantAccessFail:
      return MarketoSettingsAlertIds.grantAccessFailed;
    case MarketoSettingsActionTypes.userAccess.grantAccessSomeFailed:
      return MarketoSettingsAlertIds.grantAccessSomeFailed;
    case MarketoSettingsActionTypes.userAccess.revokeAccessFail:
      return MarketoSettingsAlertIds.revokeAccessFailed;
    case MarketoSettingsActionTypes.userAccess.revokeAccessSomeFailed:
      return MarketoSettingsAlertIds.revokeAccessSomeFailed;
    case MarketoSettingsActionTypes.userAccess.allSelectedUsersConnected:
      return MarketoSettingsAlertIds.allSelectedUsersConnected;
    case MarketoSettingsActionTypes.userAccess.allSelectedUsersDisconnected:
      return MarketoSettingsAlertIds.allSelectedUsersDisconnected;
    case MarketoSettingsActionTypes.workspacesModal.getWorkspacesFailed:
      return MarketoSettingsAlertIds.getWorkspacesFailed;
    case MarketoSettingsActionTypes.closeAlert:
      return null;
    case MarketoSettingsActionTypes.setup.disconnectFailed:
      return MarketoSettingsAlertIds.disconnectionFailed;
    case MarketoSettingsActionTypes.setup.alreadyExists:
      return MarketoSettingsAlertIds.alreadyExists;
    default:
      return state;
  }
}
