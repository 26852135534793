import { createReducer } from '@reduxjs/toolkit';
import {
  setLoginStep,
  setSFSandboxAuthenticationEnabled,
  setLoginMethodsLoading,
  setLoginFormUserName,
  setUnauthorizedHeaderKey,
  showMsiActionLoginError,
  hideMsiActionLoginError,
} from 'web/login/actionCreators/loginActionCreators';
import { LOGIN_STEPS } from 'web/login/libs/loginConstants';

export const loginStepSFSandboxAuthenticationEnabled = createReducer(false, {
  [setSFSandboxAuthenticationEnabled.type]: (state, action) => !!action.payload,
});

export const currentLoginStep = createReducer(LOGIN_STEPS.firstStep, {
  [setLoginStep.type]: (state, action) => action.payload,
});

export const loginMethodsLoading = createReducer(false, {
  [setLoginMethodsLoading.type]: (state, action) => action.payload,
});

export const unauthorizedHeaderKey = createReducer('', {
  [setUnauthorizedHeaderKey.type]: (state, action) => {
    return action.payload;
  },
});

export const loginFormUserName = createReducer('', {
  [setLoginFormUserName.type]: (state, action) => {
    return action.payload;
  },
});

export const displayMsiActionLoginError = createReducer(false, {
  [showMsiActionLoginError.type]: () => true,
  [hideMsiActionLoginError.type]: () => false,
});
