import ActionTypes from 'web/libs/actionTypes/actionTypes';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { CampaignTemplatesAlertIds } from 'web/modals/campaignTemplates/libs/campaignTemplatesConstants';
import { parseActiveCampaignTemplates } from 'web/campaignsUsingTemplate/services/parsers';
import {
  updateCampaignTemplates as updateCampaignTemplatesCall,
  deleteCampaignTemplate as deleteCampaignTemplateCall,
} from 'web/modals/campaignTemplates/services/campaignTemplatesService';
import {
  closeModal,
  setLoading,
} from 'web/modals/campaignTemplates/actionCreators/campaignTemplatesPopupActionCreators';
import { setPopupAlert } from 'web/popup/actionCreators/popupActionCreators';
import { setOrFetchCampaigns } from 'web/campaigns/campaignsTab/actionCreators/campaignsTabStartupActionCreators';

/* Call is done by Next but wanted to keep parsing in BM so didn't have to re-write */
export function getActiveCampaignTemplates(id, activeTemplatesData) {
  return (dispatch, getState) => {
    const parsedData = parseActiveCampaignTemplates(
      activeTemplatesData,
      getState().user
    );
    if (parsedData.length) {
      dispatch({
        data: {
          campaignTemplates: parsedData,
          id,
        },
        type: ActionTypes.campaignsUsingTemplate.state.update,
      });
    } else {
      toutBackboneHelper.updateCampaignTemplatesDone();
      dispatch(closeModal());
    }
  };
}

export function updateCampaignTemplates() {
  return async (dispatch, getState) => {
    const {
      campaigns,
      campaignsUsingTemplateState,
      updateCampaignTemplatesModalState,
    } = getState();
    dispatch(setLoading(true));
    const selectedIds = Object.keys(
      updateCampaignTemplatesModalState.selectedIds
    );
    try {
      await updateCampaignTemplatesCall(
        campaignsUsingTemplateState.id,
        selectedIds
      );
      // if campaigns not fetched -> fetch them and manually populate new values
      // since it might take a while for ES to process it on the back-end side
      if (!campaigns || !campaigns.length) {
        await dispatch(setOrFetchCampaigns(null, true));
      }
      dispatch(bulkUpdateCampaignSteps(selectedIds));
      toutBackboneHelper.updateCampaignTemplatesDone();
      dispatch(closeModal());
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(
        setPopupAlert(CampaignTemplatesAlertIds.updateCampaignTemplateIssue)
      );
    }
  };
}

export function bulkUpdateCampaignSteps(selectedIds) {
  return (dispatch, getState) => {
    const {
      campaignsUsingTemplateState: { campaignTemplates } = {},
      editTemplateSlideOutTemplateEditState: { subject },
    } = getState();
    campaignTemplates
      .filter(({ id }) => selectedIds.includes(id))
      .forEach(({ id: steppable_id, campaignId }) => {
        dispatch({
          id: campaignId,
          step: {
            name: subject,
            searchBy: 'steppable_id',
            steppable_id,
            workflow_id: campaignId,
          },
          type: ActionTypes.common.data.campaignUpdateStep,
        });
      });
  };
}

export function dontUpdate() {
  return (dispatch) => {
    toutBackboneHelper.updateCampaignTemplatesDone();
    dispatch(closeModal());
  };
}

/* This table needs to know the Selected Ids instead of relying on bulk actions so must handle
 * it's own ids object and select All (it's sort of a hack right now). */
export function setSelectedIds(checked, ids) {
  return (dispatch, getState) => {
    let selected = ids;
    if (!Array.isArray(selected)) {
      selected = getState().campaignsUsingTemplateState.campaignTemplates.map(
        (elem) => elem.id
      );
    }

    dispatch({
      checked,
      ids: selected,
      type: ActionTypes.updateCampaignTemplates.state.selectedIds,
    });
  };
}

export const deleteCampaignTemplate = (id) => (dispatch) => {
  dispatch(setLoading(true));
  deleteCampaignTemplateCall(id)
    .then(() => {
      dispatch(closeModal());
      toutBackboneHelper.templateDelete(id);
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(
        setPopupAlert(CampaignTemplatesAlertIds.deleteParentTemplateIssue)
      );
    });
};
