import { baseGet, baseDelete, basePut } from 'web/services/api';
import { Urls } from 'web/libs/constants';

export function getRecordingNotice(active) {
  return new Promise((resolve, reject) => {
    baseGet(Urls.manageRecordingNotice, { active }, resolve, reject);
  });
}

export function disableConsentMessage(id) {
  return new Promise((resolve, reject) => {
    basePut(
      Urls.manageRecordingNoticeById(id),
      { recording: { active: false } },
      resolve,
      reject
    );
  });
}

export function enableConsentMessage(id) {
  return new Promise((resolve, reject) => {
    basePut(Urls.activateConsentMessage(id), {}, resolve, reject);
  });
}

export function getRecordingNoticeAudio(id, publicKey, fileName) {
  return new Promise((resolve, reject) => {
    baseGet(
      Urls.manageRecordingNoticeAudio(id, publicKey, fileName),
      null,
      resolve,
      reject
    );
  });
}

export function deleteRecordingNotice(id) {
  return new Promise((resolve, reject) => {
    baseDelete(Urls.deleteRecordingNotice(id), {}, resolve, reject);
  });
}
