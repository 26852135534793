import { setSelectedPartner } from 'web/modals/addContent/actionCreators/contentActionCreators';
import { closePopup } from 'web/popup/actionCreators/popupActionCreators';

export function closeModal() {
  return (dispatch, getState) => {
    const windowMode = getState().addContentModalWindowMode;
    dispatch(closePopup());
    dispatch(setSelectedPartner({}));

    if (windowMode) {
      window.close();
    }
  };
}
