import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Shapes from 'table/modules/shapes';
import { SortingDirection, Align } from 'table/modules/constants';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';
import Cell from 'table/modules/cell';
import SortChevron from 'components/sortChevron';

class HeaderColumn extends Component {
  render() {
    const {
      column,
      height,
      resizeTableHandler,
      sortingColumn,
      sortingDirection,
      sortingFunction,
      isResize,
    } = this.props;

    const {
      align,
      flex,
      headerCell,
      id,
      name,
      sorting,
      width: columnWidth,
      widthMode,
      tooltip,
    } = column;

    const isSortedColumn = id === sortingColumn;

    const className = classNames(
      `resize-header-id-${id}`,
      'flex flex--full flex--align-middle',
      `flex--align-${(align || Align.LEFT).toLowerCase()}`,
      {
        'header-cell-sorting': sorting,
        'text-medium': isSortedColumn,
      }
    ); //hook flex--align-center flex--align-left flex--align-right

    return (
      <Cell
        cssClasses={isResize && name ? 'tout-header-resize-cell' : ''}
        flex={flex}
        height={height}
        isResize={isResize}
        key={`header-cell-${id}`}
        resizeTableHandler={
          isResize && name
            ? (event) => {
                resizeTableHandler(event, id);
              }
            : () => {}
        }
        width={columnWidth}
        widthMode={widthMode}
        isHeader
      >
        <div className={className}>
          <div
            className="sorting-container"
            data-column-id={id}
            onClick={sorting && sortingFunction}
          >
            {headerCell && headerCell.component ? (
              <headerCell.component
                className="text-overflow"
                {...headerCell.metadata}
              />
            ) : (
              <div
                className={classNames('text-overflow', {
                  'margin-right-half': sorting,
                })}
              >
                <span>{name}</span>
              </div>
            )}
            {sorting && (
              <SortChevron
                className="margin-left-quarter"
                down={
                  isSortedColumn && sortingDirection === SortingDirection.ASC
                }
                up={
                  isSortedColumn && sortingDirection === SortingDirection.DESC
                }
              />
            )}
          </div>
          {tooltip && (
            <HoverTooltip
              className="margin-left-quarter"
              place={HoverTooltipPlace.right}
              textId={tooltip}
              tooltipId={`table-header-row-tooltip-${id}`}
            >
              <i className="tout-icon-warning" />
            </HoverTooltip>
          )}
        </div>
      </Cell>
    );
  }
}

HeaderColumn.propTypes = {
  column: Shapes.Column.isRequired,
  height: PropTypes.number,
  isResize: PropTypes.bool,
  resizeTableHandler: PropTypes.func,
  sortingColumn: PropTypes.string,
  sortingDirection: Shapes.SortingDirection,
  sortingFunction: PropTypes.func,
};

HeaderColumn.defaultProps = {
  height: 44,
  isResize: false,
  resizeTableHandler: () => {},
  sortingColumn: '',
  sortingDirection: SortingDirection.ASC,
  sortingFunction: () => {},
};

export default HeaderColumn;
