export const DELIVERY_CHANNEL_VALUE_LABEL_MAP = {
  'toutapp-exchange_office365': 'O365',
  'toutapp-exchange_onprem': 'Exchange',
  'toutapp-gmail': 'Gmail',
};

export const DEFAULT_DELIVERY_CHANNEL = 'default';
export const DEFAULT_SENDGRID_DELIVERY_CHANNEL = 'smtp.sendgrid.net';
export const OUTLOOK_PLUGIN_PREFIX = 'OutlookWebApp';
export const GMAIL_PLUGIN_PREFIX = 'gmail/Mozilla';
