/* eslint-disable consistent-return */
import { getPeopleIdsByGroup } from './peopleService';
import {
  isGeneralPeopleGroup,
  isEmptyGroup,
} from 'web/people/helpers/peopleHelpers';
import {
  PEOPLE_VIEWER_TYPES,
  PEOPLE_FETCH_REQUEST_MODES,
  PEOPLE_FETCH_REQUEST_SPECIAL_TAGS,
  PEOPLE_FETCH_REQUEST_SEARCH_COLUMNS,
  FETCH_REQUEST_TYPES,
  VIEWER_ALL_ID,
  PEOPLE_SMART_FILTER_VALUES,
  PEOPLE_GENERAL_GROUPS,
} from 'web/people/libs/peopleConstants';

export const getPeopleRequestParamsFetch = async (state) => {
  const {
    peoplePage,
    peoplePerPage,
    peopleSmartFilterValue,
    peopleSearchString,
    user: { id: currentUserId, admin: isAdmin },
    peopleSort: { column, direction },
    peopleCurrentGroupId,
  } = state;
  let peopleIds = [];

  const baseRequestParams = {
    ...(peopleSearchString
      ? {
          text_search: {
            fields: PEOPLE_FETCH_REQUEST_SEARCH_COLUMNS,
            term: peopleSearchString,
          },
        }
      : {}),
    mode: PEOPLE_FETCH_REQUEST_MODES.all,
    page: peoplePage,
    per_page: peoplePerPage,
    sort_filters: {
      column,
      direction,
    },
    type: FETCH_REQUEST_TYPES.person,
    viewer: {
      id: currentUserId,
      type: PEOPLE_VIEWER_TYPES.user,
    },
  };

  if (peopleCurrentGroupId && !isGeneralPeopleGroup(peopleCurrentGroupId)) {
    // Fetch people from group
    peopleIds = await getPeopleIdsByGroup(peopleCurrentGroupId);

    if (isEmptyGroup(peopleIds)) {
      return;
    } else {
      baseRequestParams.must = {
        id: peopleIds,
      };

      // When viewing a group, admins should be able to view all people regardless of sharing status
      if (isAdmin) {
        baseRequestParams.viewer = {
          id: VIEWER_ALL_ID,
          type: PEOPLE_VIEWER_TYPES.subscription,
        };
      }
    }
  }

  switch (peopleCurrentGroupId) {
    case PEOPLE_GENERAL_GROUPS.everyone.id:
      return {
        ...baseRequestParams,
        viewer: { id: VIEWER_ALL_ID, type: PEOPLE_VIEWER_TYPES.subscription },
      };
    case PEOPLE_GENERAL_GROUPS.unsubscribed.id:
      return {
        ...baseRequestParams,
        tags: PEOPLE_FETCH_REQUEST_SPECIAL_TAGS.unsubscribed,
        viewer:
          peopleSmartFilterValue === PEOPLE_SMART_FILTER_VALUES.mine
            ? { id: currentUserId, type: PEOPLE_VIEWER_TYPES.user }
            : {
                id: VIEWER_ALL_ID,
                type: PEOPLE_VIEWER_TYPES.subscription,
              },
      };
    case PEOPLE_GENERAL_GROUPS.myContacts.id:
      return {
        ...baseRequestParams,
        mode: PEOPLE_FETCH_REQUEST_MODES.mine,
      };
    default:
      return baseRequestParams;
  }
};
