import EmailPluginsActionTypes from 'web/settings/emailPlugins/libs/emailPluginsActionTypes';
import { EmailSettingsTabs } from 'web/settings/email/libs/emailSettingsConstants';
import { GoogleExtensionInstallUrl } from 'web/settings/emailPlugins/libs/emailPluginsConstants';
import { installChromeExtension } from 'web/services/chromeService';
import { navigateToEmailSettingsTab } from 'web/services/routerService';

export const setGmailChromeExtensionInstalledState = (
  gmailChromeExtensionInstalled = false
) => (dispatch) => {
  dispatch({
    type: EmailPluginsActionTypes.setGmailChromeExtensionInstalledState,
    gmailChromeExtensionInstalled,
  });
};

export const switchToEmailSyncTab = () => (dispatch) => {
  navigateToEmailSettingsTab(EmailSettingsTabs.emailConnection);
};

export const installGooglePlugin = () => (dispatch) => {
  installChromeExtension(GoogleExtensionInstallUrl, () => {
    dispatch(setGmailChromeExtensionInstalledState(true));
    dispatch(switchToEmailSyncTab());
  });
};
