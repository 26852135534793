import { track } from 'web/services/mixpanelService';
import {
  TablesEvents,
  TablesProperties,
  TablesSources,
} from 'web/libs/mixpanelEvents';

function mapTableToTemplatesTable(mixpanelEvent, data) {
  switch (mixpanelEvent) {
    case 'COLUMN_FILTER_CHANGE': //TableTrackerEvents.columnFilterChange:
      return {
        name: TablesEvents.columnFilterChange,
        data: {
          [TablesProperties.source]: TablesSources.templates,
          [TablesProperties.columnSelected]: data.name,
          [TablesProperties.checked]: data.checked,
        },
      };
    default:
      return null;
  }
}

export function tracker(tableEvent = '', data = {}) {
  const mixpanelEvent = mapTableToTemplatesTable(tableEvent, data);
  if (mixpanelEvent) {
    track(mixpanelEvent.name, mixpanelEvent.data);
  }
}
