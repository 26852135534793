import React from 'react';
import unauthorizedPage from 'components/hocs/unauthorizedPage';
import { FormattedMessage } from 'react-intl';
import Action, { ButtonSizes } from 'components/buttons/action';
import { Urls } from '../../libs/constants';
import { getImageUrl } from 'web/libs/constants';
import { navigateToUrl } from 'web/services/routerService';
import AbleListItem from './ableListItem';
import './gmailStart.scss';

const GmailStart = ({ location }) => {
  const getTitle = () => {
    switch (location.search) {
      case '?updated=true':
        return (
          <React.Fragment>
            <h2>
              <FormattedMessage id="gmail.start.update.title" />
            </h2>
            <h2>
              <FormattedMessage id="gmail.start.update.refreshMessage" />
            </h2>
          </React.Fragment>
        );
      default:
        return (
          <h2>
            <FormattedMessage id="gmail.start.title" />
          </h2>
        );
    }
  };
  return (
    <div className="gmail-start">
      <div className="gmail-start-title margin-bottom-full">{getTitle()}</div>

      <div className="flex flex--horz-spread">
        <div className="able-block">
          <p className="now-able-to">
            <FormattedMessage id="gmail.start.ableTo.title" />
          </p>

          <ul className="able-list">
            <AbleListItem>
              <FormattedMessage id="gmail.start.ableTo.track" />
            </AbleListItem>
            <AbleListItem>
              <FormattedMessage id="gmail.start.ableTo.schedule" />
            </AbleListItem>
            <AbleListItem>
              <FormattedMessage id="gmail.start.ableTo.kickOff" />
            </AbleListItem>
            <AbleListItem>
              <FormattedMessage id="gmail.start.ableTo.log" />
            </AbleListItem>
            <AbleListItem>
              <FormattedMessage id="gmail.start.ableTo.access" />
            </AbleListItem>
          </ul>
        </div>
        <img width={100} src={getImageUrl('gmail-icon', 'svg')} alt="" />
      </div>

      <div className="text-right">
        <Action
          onClick={() => navigateToUrl(Urls.googleAuth)}
          size={ButtonSizes.medium}
        >
          <FormattedMessage id="gmail.start.goToGmail" />
        </Action>
      </div>
    </div>
  );
};

export default unauthorizedPage(GmailStart, {
  titleKey: 'common.gmail',
  cardWidth: 500,
  parentClassName: 'gmail-start-container',
});
