import moment from 'moment';
import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';
import PersonDetailsSlideOutActionTypes from 'web/slideOuts/personDetails/libs/personDetailsSlideOutActionTypes';
import TasksActionTypes from 'web/tasks/libs/tasksActionTypes';
import { PersonDetailsTabs } from '../libs/personDetailsConstants';
import { MARKETO_EVENTS_DAYS_LIMIT } from 'web/marketo/interestingMoments/libs/interestingMomentsConstants';
import AddContactSlideOutActionTypes from 'web/slideOuts/addContact/libs/addContactSlideOutActionTypes';
import {
  updatePersonState,
  removeFromPersonState,
} from './personDetailsReducersHelper';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const personDetailsPerson = (
  state = EMPTY_OBJECT,
  {
    type = '',
    salesforceType = '',
    clear = true,
    deletedCallId = [],
    id = 0,
    note = EMPTY_OBJECT,
    person = EMPTY_OBJECT,
    marketoCampaigns = [],
    pitch = EMPTY_OBJECT,
    salesforceId = 0,
    task = EMPTY_OBJECT,
    groups = EMPTY_ARRAY,
    addresses = EMPTY_ARRAY,
  }
) => {
  switch (type) {
    case PersonDetailsActionTypes.setPerson:
      return { ...state, ...person };
    case PersonDetailsActionTypes.setMarketoCampaign:
      return { ...state, marketoCampaigns };
    case TasksActionTypes.update:
    case TasksActionTypes.completing:
    case TasksActionTypes.uncompleting:
      return updatePersonState(task, 'all_reminders', state);
    case TasksActionTypes.destroy:
      return removeFromPersonState(id, 'all_reminders', state);
    case PersonDetailsActionTypes.updatePitch: {
      return updatePersonState(pitch, 'pitches', state);
    }
    case PersonDetailsActionTypes.updateNote: {
      return updatePersonState(note, 'notes', state);
    }
    case PersonDetailsActionTypes.deleteNote: {
      return removeFromPersonState(id, 'notes', state);
    }
    case PersonDetailsActionTypes.deleteCall: {
      return {
        ...state,
        deletedCallId: state.deletedCallId
          ? state.deletedCallId.concat(deletedCallId)
          : [].concat(deletedCallId),
      };
    }
    case PersonDetailsActionTypes.updateGroups: {
      return {
        ...state,
        group_members: groups,
      };
    }
    case PersonDetailsActionTypes.updateAddresses: {
      return {
        ...state,
        addresses,
      };
    }
    case PersonDetailsSlideOutActionTypes.clear:
    case PersonDetailsSlideOutActionTypes.open:
    case PersonDetailsSlideOutActionTypes.close:
    case AddContactSlideOutActionTypes.close:
      return clear ? EMPTY_OBJECT : state;
    case PersonDetailsActionTypes.setPersonSalesforceId:
      if (salesforceType) {
        return { ...state, [`salesforce_${salesforceType}_id`]: salesforceId };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const INIT_EMAILS_STATE = {
  emailId: 0,
  emails: EMPTY_ARRAY,
  loading: false,
};
export const personDetailsEmailsState = (
  state = INIT_EMAILS_STATE,
  {
    type = '',
    clear = true,
    email = {},
    emailId = 0,
    emails = EMPTY_ARRAY,
    loading = false,
  }
) => {
  switch (type) {
    case PersonDetailsActionTypes.setEmails:
      return { ...state, emails };
    case PersonDetailsActionTypes.setEmail: {
      const index = state.emails.findIndex(({ id }) => id === email.id);
      if (index > -1) {
        return {
          ...state,
          emails: [
            ...state.emails.slice(0, index),
            { ...state.emails[index], ...email },
            ...state.emails.slice(index + 1),
          ],
        };
      } else {
        return state;
      }
    }
    case PersonDetailsActionTypes.setEmailsLoading: {
      const clearEmails = loading ? [] : state.emails;
      return { ...state, emails: clearEmails, loading };
    }
    case PersonDetailsActionTypes.setEmailsId:
      return { ...state, emailId };
    case PersonDetailsSlideOutActionTypes.open:
      return clear ? INIT_EMAILS_STATE : state;
    default:
      return state;
  }
};

export const INIT_MARKETO_EVENTS_STATE = {
  endDate: moment().toISOString(),
  error: false,
  events: [],
  loading: false,
  startDate: moment()
    .startOf('d')
    .subtract(MARKETO_EVENTS_DAYS_LIMIT, 'd')
    .toISOString(),
};
export const personDetailsMarketoEventsState = (
  state = INIT_MARKETO_EVENTS_STATE,
  {
    type = '',
    clear = true,
    endDate = undefined,
    error = false,
    events = EMPTY_ARRAY,
    loading = false,
    startDate = undefined,
  }
) => {
  switch (type) {
    case PersonDetailsActionTypes.setMarketoEvents:
      return {
        endDate,
        error,
        events: state.events.concat(events),
        loading: false,
        startDate,
      };
    case PersonDetailsActionTypes.setMarketoEventsLoading:
      return {
        ...state,
        loading,
      };
    case PersonDetailsActionTypes.open:
      return clear ? INIT_MARKETO_EVENTS_STATE : state;
    case PersonDetailsActionTypes.setPerson:
      return INIT_MARKETO_EVENTS_STATE;
    default:
      return state;
  }
};

export const personDetailsAccountInfo = (
  state = null,
  { type = '', accountInfo = null }
) => {
  switch (type) {
    case PersonDetailsActionTypes.accountInfo:
      return accountInfo;
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.close:
    case PersonDetailsSlideOutActionTypes.close:
      return null;
    default:
      return state;
  }
};

export const personDetailsSalesforceConnectionData = (
  state = EMPTY_OBJECT,
  { type = '', connectionData = EMPTY_OBJECT }
) => {
  switch (type) {
    case PersonDetailsActionTypes.salesforceConnectionData:
      return connectionData;
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.close:
    case PersonDetailsSlideOutActionTypes.close:
      return EMPTY_OBJECT;
    default:
      return state;
  }
};

export const personDetailsAccountInfoLoading = (
  state = false,
  { type = '', loading = false }
) => {
  switch (type) {
    case PersonDetailsActionTypes.open:
      return true;
    case PersonDetailsActionTypes.accountInfoLoading:
      return loading;
    default:
      return state;
  }
};

const initTab = PersonDetailsTabs.about;
export const personDetailsTab = (
  state = initTab,
  { type = '', tab = initTab }
) => {
  switch (type) {
    case PersonDetailsActionTypes.open:
    case PersonDetailsActionTypes.setTab:
      return tab;
    default:
      return state;
  }
};

export const initAlert = { id: '', textValues: EMPTY_OBJECT };
export const personDetailsTabAlert = (
  state = initAlert,
  { type = '', id = '', textValues = {} }
) => {
  switch (type) {
    case PersonDetailsActionTypes.setTabAlert:
      return { id, textValues };
    case PersonDetailsActionTypes.clearTabAlert:
      return initAlert;
    default:
      return state;
  }
};

export const personDetailsTabLoading = (
  state = false,
  { type = '', clear = true, loading = false }
) => {
  switch (type) {
    case PersonDetailsActionTypes.setTabLoading:
      return loading;
    case PersonDetailsSlideOutActionTypes.open:
      return clear ? false : state;
    default:
      return state;
  }
};

export function personDetailsInit(state = false, { type = '' }) {
  switch (type) {
    case PersonDetailsActionTypes.init:
      return true;
    case PersonDetailsActionTypes.close:
      return false;
    default:
      return state;
  }
}

const unsubscribeHistoryInitialState = [];

export function personDetailsUnsubscribeHistory(
  state = unsubscribeHistoryInitialState,
  { type = '', data = unsubscribeHistoryInitialState }
) {
  switch (type) {
    case PersonDetailsActionTypes.setUnsubscribeHistory:
      return data;

    case PersonDetailsSlideOutActionTypes.close:
      return unsubscribeHistoryInitialState;

    default:
      return state;
  }
}

export function personDetailsExporting(
  state = false,
  { type = '', exportFile = false }
) {
  switch (type) {
    case PersonDetailsActionTypes.export:
      return exportFile;
    default:
      return state;
  }
}
