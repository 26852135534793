import filter from 'lodash/filter';
import map from 'lodash/map';
import get from 'lodash/get';
import difference from 'lodash/difference';
import { createSelector } from 'reselect';
import {
  getFetching,
  getFetched,
  getTeams as getTeamsEntities,
  getById as getTeamsById,
  getTeamToAdmin,
  getMasterTeam,
  getAllIds as getTeamsAllIds,
} from 'web/teams/selectors/teamsSelectors';
import { overrideTeams } from '../helpers/teamMembersHelpers';
import { isAdmin } from 'web/user/selectors/userSelectors';

export const getSelectedTeamId = (state) =>
  state.ui.settings.adminSettings.teamManagement.teams.selectedTeamId;

export const getEditingTeamId = (state) =>
  state.ui.settings.adminSettings.teamManagement.teams.editingTeamId;

export const getCreateTeamLoading = (state) =>
  state.ui.settings.adminSettings.teamManagement.teams.createTeamLoading;

export const getUpdateTeamLoading = (state) =>
  state.ui.settings.adminSettings.teamManagement.teams.updateTeamLoading;

export const getDeleteTeamLoading = (state) =>
  state.ui.settings.adminSettings.teamManagement.teams.deleteTeamLoading;

export const getSelectedTeam = createSelector(
  [getSelectedTeamId, getTeamsById],
  (id, byId) => byId[id]
);

export const getSelectedTeamToAdmin = createSelector(
  [getSelectedTeam, getTeamToAdmin],
  (selectedTeam, teamToAdmin) => {
    if (!selectedTeam) {
      return [];
    }
    return filter(teamToAdmin, ['teamId', selectedTeam.id]);
  }
);

export const getSelectedTeamAdminIds = createSelector(
  [getSelectedTeamToAdmin],
  (selectedTeamToAdmin) => map(selectedTeamToAdmin, 'adminId')
);

export const getLoading = createSelector(
  [getFetched, getFetching, getUpdateTeamLoading],
  (fetched, fetching, updateTeamLoading) =>
    !fetched || fetching || updateTeamLoading
);

export const getTeams = createSelector(
  [getTeamsEntities, isAdmin],
  overrideTeams
);

export const getTeamsSelectOptions = createSelector(
  [getTeamsById, getSelectedTeamId, getMasterTeam, getTeamsAllIds],
  (byId, selectedTeamId, masterTeam, allIds) =>
    difference(allIds, [selectedTeamId, get(masterTeam, 'id')]).map((id) => ({
      value: id,
      label: byId[id].name,
    }))
);

export const getTeamsSelectOptionsWithoutSelected = createSelector(
  [getTeamsById, getMasterTeam, getTeamsAllIds],
  (byId, masterTeam, allIds) =>
    difference(allIds, [get(masterTeam, 'id')]).map((id) => ({
      value: id,
      label: byId[id].name,
    }))
);
