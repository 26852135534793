import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Popup from 'components/popups';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ToutTable from 'table/modules/toutTable';
import LinkCell from 'table/modules/cells/linkCell/linkCell';
import { PopupSize } from 'libs/constantsShared';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Action, { ButtonSizes } from 'components/buttons/action';
import Text from 'components/inputs/text';
import {
  bulkCallReasonOptions,
  alertEmptyRequiredCallReasonOption,
} from '../../actionCreators/callReasonActionCreators';
import { compose } from 'redux';
import './manageDialerSettingsModal.scss';
import I18N from 'languages';
import TextCustomCell from 'table/modules/cells/textCustomCell';
import HoverTooltip, { HoverTooltipPlace } from 'components/hoverTooltip';

const REASON_LIMIT = 15;
const CHAR_LIMIT = 50;

class ManageCallReasonsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callReasonOptions: this.props.callReasonOptions,
      create: [],
      ids_to_delete: [],
      newName: '',
      tempId: 0,
    };
  }

  addOptionHandler = () => {
    const { tempId: id, newName: name } = this.state;
    const newOption = { id, name };
    this.setState(({ callReasonOptions, create, tempId }) => ({
      callReasonOptions: [...callReasonOptions, newOption],
      create: [...create, newOption],
      newName: '',
      tempId: tempId - 1,
    }));
  };

  deleteOptionHandler = (rowData, event) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState(({ callReasonOptions, ids_to_delete }) => {
      const newState = {
        callReasonOptions: callReasonOptions.filter(
          (item) => item.id !== rowData.id
        ),
      };
      if (rowData.id > 0) {
        newState.ids_to_delete = [...ids_to_delete, rowData.id];
      }
      return newState;
    });
  };

  getHoverTooltipWrappedText = (text) => (
    <HoverTooltip
      place={HoverTooltipPlace.bottom}
      textValue={text}
      tooltipId={`reason-tooltip-${text}`}
    >
      <div className="text-overflow">{text}</div>
    </HoverTooltip>
  );

  getColumns = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return [
      {
        id: 'name',
        name: formatMessage({
          id: 'web.settings.adminSettings.dialerSettings.reason',
        }),
        rowCell: {
          component: TextCustomCell,
          metadata: {
            formatText: (data) => this.getHoverTooltipWrappedText(data.name),
            property: 'name',
          },
        },
        width: 450,
      },
      {
        id: 'action',
        name: formatMessage({
          id: 'web.settings.adminSettings.dialerSettings.action',
        }),
        rowCell: {
          component: LinkCell,
          metadata: {
            formatText: () => I18N.getStr('common.delete'),
            onClick: this.deleteOptionHandler,
          },
        },
        width: 100,
      },
    ];
  };

  onClose = async () => {
    const { dispatch } = this.props;
    const { create, ids_to_delete } = this.state;
    ReactDOM.unmountComponentAtNode(document.getElementById('root-modal'));
    if (create.length || ids_to_delete.length) {
      await dispatch(bulkCallReasonOptions({ create, ids_to_delete }));
    }
    dispatch(alertEmptyRequiredCallReasonOption({ loadOptions: false }));
  };

  getTitle = (limitReached) => {
    const {
      intl: { formatMessage },
    } = this.props;
    return limitReached
      ? formatMessage(
          {
            id:
              'web.settings.adminSettings.dialerSettings.manageCallOutcomesModalAddTooltip',
          },
          { count: REASON_LIMIT }
        )
      : '';
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const { callReasonOptions, newName } = this.state;
    const limitReached = callReasonOptions.length >= REASON_LIMIT;
    const charLimitReached = newName.length > CHAR_LIMIT;
    return (
      <Popup
        header={{
          onClose: this.onClose,
          textId: 'web.settings.adminSettings.dialerSettings.manageCallReasons',
        }}
        size={PopupSize.large}
      >
        <div className="margin-top-20 margin-bottom-20">
          <FormattedHTMLMessage
            id="web.settings.adminSettings.dialerSettings.manageCallReasonsModalNotice"
            values={{ count: callReasonOptions.length }}
          />
        </div>
        <div className="manage-dialer-settings-table">
          <ToutTable
            actionHeaderHeight={0}
            columns={this.getColumns()}
            items={callReasonOptions}
            maxHeight={500}
            minHeight={500}
            allItems
          />
        </div>
        <div className="manage-dialer-settings-modal-footer margin-top-20">
          <Text
            onChange={(value) => this.setState({ newName: value })}
            placeholder={formatMessage({
              id: 'web.settings.adminSettings.dialerSettings.enterReasonName',
            })}
            value={newName}
          />
          <Action
            disabled={!newName.trim() || limitReached || charLimitReached}
            onClick={this.addOptionHandler}
            size={ButtonSizes.medium}
            title={this.getTitle(limitReached)}
          >
            <FormattedMessage id="common.add" />
          </Action>
        </div>
        <div className="manage-dialer-settings-reason-char-counter">
          {newName.length}
        </div>
        <div className="manage-dialer-settings-char-lim-error-message">
          {charLimitReached ? (
            <FormattedHTMLMessage id="web.settings.adminSettings.dialerSettings.charReasonLimit" />
          ) : null}
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => ({
  callReasonOptions: state.callReasonOptions,
  popup: state.popup,
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(ManageCallReasonsModal);

ManageCallReasonsModal.propTypes = {
  callReasonOptions: PropTypes.array,
  callReasonValidTypes: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  popup: PropTypes.string,
};

ManageCallReasonsModal.defaultProps = {
  callReasonOptions: [],
  popup: false,
};
