import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './liveFeedContact.scss';

class LiveFeedContact extends PureComponent {
  render() {
    const { name, openPdv } = this.props;

    return (
      <div className="live-feed-contact">
        <span onClick={openPdv} className="name text-medium text-overflow">
          {name || <FormattedMessage id="common.unknown" />}
        </span>
      </div>
    );
  }
}

LiveFeedContact.propTypes = {
  name: PropTypes.string,
  openPdv: PropTypes.func,
};

LiveFeedContact.defaultProps = {
  name: '',
  openPdv: null,
};

export default LiveFeedContact;
