import moment from 'moment';
import PersonDetailsActionTypes from '../libs/personDetailsActionTypes';
import {
  PersonDetailsCards,
  PersonDetailsPopupIds,
  PersonDetailsTabAlertIds,
  PersonDetailsTabs,
  SalesforceTypes,
} from '../libs/personDetailsConstants';
import { getSalesforceId } from '../helpers/personHelpers';
import { parseSalesforceAccountInfo } from '../helpers/personDetailsParsers';
import { getEditStateByCard } from '../helpers/personDetailsEditStateParsers';
import { hasAnyChanges } from '../helpers/personDetailsHasChangesHelper';
import {
  getPersonById as getPersonByIdCall,
  getSalesforceAccountInfo,
  getUnsubscribeHistory as getUnsubscribeHistoryCall,
} from 'web/people/services/peopleService';
import { getCompliance as getComplianceCall } from 'web/compliance/services/complianceService';
import { getEmailsByPersonId } from 'web/emails/services/emailsServices';
import { getGroups } from 'web/groups/actionCreators/groupsActionCreators';
import { MARKETO_EVENTS_DAYS_LIMIT } from 'web/marketo/interestingMoments/libs/interestingMomentsConstants';
import { getInterestingMomentsByPerson } from 'web/marketo/interestingMoments/services/interestingMomentsServices';
import { openPopup } from 'web/popup/actionCreators/popupActionCreators';
import { getMarketoCampaignsByPersonId } from 'web/person/actionCreators/personDetailsInfoActionCreators';
import { bindPusher, unbindPusher } from './personDetailsPusherActionCreators';
import { getUnsubscribeOptions } from 'web/unsubscribe/unsubscribeOptions/actionCreators/unsubscribeOptionsActionCreators';
import { isUnsubscribeReasonsFull } from 'web/unsubscribe/unsubscribeOptions/helpers/unsubscribeOptionsHelpers';
import toutBackboneHelper from 'web/libs/toutBackboneHelper';
import { hasMarketo } from 'web/shared/helpers/subscriptionHelper';
import { getTabSource, openItemClick } from '../helpers/personDetailsMixpanel';
import {
  PersonDetailsEvents,
  PersonDetailsProperties,
} from 'web/libs/mixpanelEvents';
import { getSource, track } from 'web/services/mixpanelService';
import { closePersonDetails as closeSlideOut } from 'web/slideOuts/personDetails/actionCreators/personDetailsSlideOutActionCreators';
import EmailAlertIds from 'web/commandCenter/libs/commandCenterEmailsAlertIds';
import { openViewAlert } from 'web/view/actionCreators/alertActionCreators';
import { getMarketoSubDetails } from 'web/marketo/subscriptions/actionCreators/marketoSubscriptionsActionCreators.js';

export const setTabLoading = (loading) => ({
  loading,
  type: PersonDetailsActionTypes.setTabLoading,
});

export const setTabAlert = (id, textValues) => ({
  id,
  textValues,
  type: PersonDetailsActionTypes.setTabAlert,
});

export const clearTabAlert = () => ({
  type: PersonDetailsActionTypes.clearTabAlert,
});

export const getMarketoEvents = (allowRetry = true, init = false) => (
  dispatch,
  getState
) => {
  const {
    personDetailsMarketoEventsState: { endDate, error, startDate },
    personDetailsPerson: { id },
    personDetailsTab,
  } = getState();

  let start = startDate;
  let end = endDate;

  if (!init && !error) {
    start = moment(startDate)
      .startOf('d')
      .subtract(MARKETO_EVENTS_DAYS_LIMIT, 'd')
      .toISOString();
    end = moment(endDate)
      .startOf('d')
      .subtract(MARKETO_EVENTS_DAYS_LIMIT, 'd')
      .toISOString();
  }

  dispatch({
    loading: true,
    type: PersonDetailsActionTypes.setMarketoEventsLoading,
  });

  getInterestingMomentsByPerson(id, start, end)
    .then((events) => {
      dispatch({
        endDate: end,
        events,
        startDate: start,
        type: PersonDetailsActionTypes.setMarketoEvents,
      });

      if (allowRetry && events.length < 10) {
        dispatch(getMarketoEvents(false, false));
      }
    })
    .catch(() => {
      if (personDetailsTab === PersonDetailsTabs.engagement) {
        dispatch(setTabAlert(PersonDetailsTabAlertIds.getMarketoEventsFail));
      }
      dispatch({
        endDate: end,
        error: true,
        events: [],
        startDate: start,
        type: PersonDetailsActionTypes.setMarketoEvents,
      });
    });
};

export const getAccountInfo = (person = {}) => (dispatch) => {
  const identifier = getSalesforceId(person);
  dispatch({
    loading: true,
    type: PersonDetailsActionTypes.accountInfoLoading,
  });
  return getSalesforceAccountInfo({ person_id: person.id, ...identifier })
    .then((accountInfo = {}) => {
      if (identifier.email && accountInfo.attributes) {
        dispatch({
          salesforceId: accountInfo.Id,
          salesforceType:
            SalesforceTypes[accountInfo.attributes.type.toLowerCase()],
          type: PersonDetailsActionTypes.setPersonSalesforceId,
        });
      }
      dispatch({
        connectionData: {
          instanceUrl: accountInfo.salesforce_instance_url,
          userId: accountInfo.salesforce_user_id,
        },
        type: PersonDetailsActionTypes.salesforceConnectionData,
      });
      dispatch({
        accountInfo: parseSalesforceAccountInfo(accountInfo),
        type: PersonDetailsActionTypes.accountInfo,
      });
    })
    .catch((response = {}) => {
      if (response.status !== 422) {
        dispatch(setTabAlert(PersonDetailsTabAlertIds.getAccountInfoFail));
      }
    })
    .finally(() => {
      dispatch({
        loading: false,
        type: PersonDetailsActionTypes.accountInfoLoading,
      });
    });
};

export const getEmails = (id) => (dispatch) => {
  dispatch({ loading: true, type: PersonDetailsActionTypes.setEmailsLoading });
  getEmailsByPersonId(id)
    .then((emails) => {
      dispatch({ emails, type: PersonDetailsActionTypes.setEmails });
    })
    .catch(() => {
      dispatch(setTabAlert(PersonDetailsTabAlertIds.getEmailsFail));
    })
    .finally(() => {
      dispatch({
        loading: false,
        type: PersonDetailsActionTypes.setEmailsLoading,
      });
    });
};

export const getUnsubscribeHistory = (id) => (dispatch) =>
  getUnsubscribeHistoryCall(id).then((data) => {
    dispatch({
      data,
      type: PersonDetailsActionTypes.setUnsubscribeHistory,
    });
  });

export const getPersonById = (id, showLoader = false) => (
  dispatch,
  getState
) => {
  const { optInReasonsItems, unsubscribeReasons } = getState();
  const promises = [getPersonByIdCall(id), getComplianceCall(id)];

  if (
    (optInReasonsItems && !optInReasonsItems.length) ||
    !isUnsubscribeReasonsFull(unsubscribeReasons)
  ) {
    promises.push(dispatch(getUnsubscribeOptions()));
  }

  promises.push(dispatch(getUnsubscribeHistory(id)));

  if (showLoader) {
    dispatch(setTabLoading(true));
  }

  return Promise.all(promises)
    .then(([fulfilledPerson, complianceDetails]) => {
      const person = { ...fulfilledPerson };
      if (person.external_references.length === 0) {
        person.external_references = null;
      }
      dispatch({
        person: { ...person, complianceDetails },
        type: PersonDetailsActionTypes.setPerson,
      });

      dispatch(getEmails(id));

      dispatch(getAccountInfo(person));

      if (hasMarketo(getState().user)) {
        dispatch(getMarketoEvents(true, true));
      }
    })
    .catch((error) => {
      if (error.status === 404) {
        dispatch(closeSlideOut());
        dispatch(openViewAlert(EmailAlertIds.personForEmailNotFoundError));
        return;
      }
      dispatch(setTabAlert(PersonDetailsTabAlertIds.getPersonById));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
};

export const onTabSelect = (tab) => (dispatch, getState) => {
  if (hasAnyChanges(getState())) {
    dispatch(
      openPopup(PersonDetailsPopupIds.hasChanges, {
        args: [tab],
        callback: 'onTabSelect',
      })
    );
  } else {
    track(PersonDetailsEvents.tabViewed, {
      [PersonDetailsProperties.source]: getTabSource(tab),
    });
    dispatch({
      tab,
      type: PersonDetailsActionTypes.setTab,
    });
  }
};

export const setEmailId = (emailId) => (dispatch) => {
  if (emailId) {
    dispatch(onTabSelect(PersonDetailsTabs.history));
  }

  dispatch({ emailId, type: PersonDetailsActionTypes.setEmailsId });
};

export const openEmailInHistory = (emailId) => (dispatch) => {
  dispatch(setEmailId(emailId));
  openItemClick(PersonDetailsCards.toutEvents);
};

export const openCardEditing = (cardId) => (dispatch, getState) => {
  dispatch({
    cardId,
    editState: getEditStateByCard(cardId, getState()),
    type: PersonDetailsActionTypes.openCardEditing,
  });
};

export const closeCardEditing = (cardId) => ({
  cardId,
  type: PersonDetailsActionTypes.closeCardEditing,
});

export function closeCardEditingAll() {
  return {
    type: PersonDetailsActionTypes.closeCardEditingAll,
  };
}

export const initPersonDetails = (id, tab) => (dispatch) => {
  dispatch({ tab, type: PersonDetailsActionTypes.open });
  dispatch(getPersonById(id, true));
  dispatch(getGroups());
  dispatch(getMarketoCampaignsByPersonId(id));
  dispatch(getMarketoSubDetails());
  dispatch(bindPusher());
  track(PersonDetailsEvents.open, {
    [PersonDetailsProperties.source]: getSource(),
  });
};

export const openPersonDetails = (id, options = {}) => (dispatch, getState) => {
  if (hasAnyChanges(getState())) {
    dispatch(
      openPopup(PersonDetailsPopupIds.hasChanges, {
        args: [id, options],
        callback: 'initPersonDetails',
      })
    );
  } else {
    dispatch(initPersonDetails(id, options.tab));
    dispatch(setEmailId(options.emailId));
  }
};

let onCloseProp; // used to avoid passing functions through redux
export const exitPersonDetails = (onClose = onCloseProp) => (dispatch) => {
  dispatch({ type: PersonDetailsActionTypes.close });
  dispatch(unbindPusher());
  toutBackboneHelper.personDetailsClose();

  if (onClose) {
    onCloseProp = undefined;
    onClose();
  }
};

export const closePersonDetails = (onClose) => (dispatch, getState) => {
  if (hasAnyChanges(getState())) {
    onCloseProp = onClose;
    dispatch(
      openPopup(PersonDetailsPopupIds.hasChanges, {
        args: [],
        callback: 'exitPersonDetails',
      })
    );
  } else {
    dispatch(exitPersonDetails(onClose));
  }
};

export const updatePitch = (pitch) => ({
  pitch,
  type: PersonDetailsActionTypes.updatePitch,
});
