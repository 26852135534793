import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/buttons/icon';
import {
  getPrimaryEmail,
  getPrimaryPhone,
} from 'web/shared/services/peopleService';
import { track } from 'web/services/mixpanelService';
import { CampaignPeopleTabEvents } from 'web/libs/mixpanelEvents';
import { injectIntl, intlShape } from 'react-intl';
import './quickActionsCell.scss';

class QuickActionsCell extends Component {
  navigateToLinkedIn = (e) => {
    const {
      actionCreators,
      rowData: { person },
    } = this.props;
    e.stopPropagation();
    track(CampaignPeopleTabEvents.quickAction, { type: 'inmail' });
    actionCreators.router.navigateToLinkedIn(
      person.first_name,
      person.last_name
    );
  };

  navigateToCompose = (e) => {
    const {
      actionCreators,
      rowData: { person },
    } = this.props;
    e.stopPropagation();
    const primaryEmail = getPrimaryEmail(person);
    const primaryEmailId = primaryEmail ? primaryEmail.id : null;
    track(CampaignPeopleTabEvents.quickAction, { type: 'email' });
    actionCreators.router.navigateToComposeWindow(person.id, primaryEmailId);
  };

  openToutPhone = (e) => {
    const {
      actionCreators,
      rowData: { person },
    } = this.props;
    e.stopPropagation();
    const primaryPhone = getPrimaryPhone(person);
    const primaryPhoneNumber = primaryPhone ? primaryPhone.address : null;
    track(CampaignPeopleTabEvents.quickAction, { type: 'call' });
    actionCreators.openToutPhone({
      number: primaryPhoneNumber,
      person,
    });
  };

  openTaskModal = (e) => {
    const {
      actionCreators,
      rowData: { person },
    } = this.props;
    e.stopPropagation();
    track(CampaignPeopleTabEvents.quickAction, { type: 'custom' });
    actionCreators.openTask({ person });
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="campaign-quick-actions-container">
        <span>
          <Icon
            classes="people-icon people-icon-email"
            color="gray"
            onClick={this.navigateToCompose}
            title={formatMessage({ id: 'common.email' })}
          />
          <Icon
            classes="people-icon people-icon-phone"
            color="gray"
            onClick={this.openToutPhone}
            title={formatMessage({ id: 'common.call' })}
          />
          <Icon
            classes="people-icon people-icon-inmail"
            color="gray"
            onClick={this.navigateToLinkedIn}
            title={formatMessage({ id: 'common.inMail' })}
          />
          <Icon
            classes="people-icon people-icon-task"
            color="gray"
            onClick={this.openTaskModal}
            title={formatMessage({ id: 'common.task' })}
          />
        </span>
      </div>
    );
  }
}

QuickActionsCell.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default injectIntl(QuickActionsCell);
