import EditTemplateActionTypes from '../libs/editTemplateSlideOutActionTypes';
import {
  EditTemplateTabAlertIds,
  EditTemplatePopupIds,
  EditTemplateTabs,
} from '../libs/editTemplateSlideOutConstants';
import { getEditStateByCard } from '../helpers/editTemplateParsers';
import {
  hasChanges,
  isSubjectMissing,
} from '../helpers/editTemplateHasChangesHelper';
import { navigateToComposeWindow } from 'web/services/routerService';
import {
  closePopup,
  openPopup,
} from 'web/popup/actionCreators/popupActionCreators';
import { getTemplateCategories } from 'web/categories/actionCreators/categoriesTemplatesActionCreators';
import {
  getTemplateEmails,
  getTemplateAnalytics,
} from './editTemplateTemplateActionCreators';
import { updateCreateTemplateState } from 'web/templates/actionCreators/templatesCreateActionCreators';
import { close } from './editTemplateExternalActionCreators';
import {
  archiveEmail as archiveEmailCall,
  unarchiveEmail as unarchiveEmailCall,
} from 'web/emails/services/emailsServices';
import { openFollowUpEmail } from 'web/emails/services/composeServices';
import { parseEventsFromEmail } from 'web/emails/helpers/emailsParsers';
import I18N from 'languages';

export const setTab = (tab = EditTemplateTabs.template) => (dispatch) => {
  dispatch(closePopup());
  dispatch({
    type: EditTemplateActionTypes.setTab,
    tab,
  });
};

export const onTabSelect = (tab) => (dispatch, getState) => {
  if (hasChanges(getState())) {
    dispatch(
      openPopup(EditTemplatePopupIds.hasChanges, {
        callback: 'setTab',
        args: [tab],
      })
    );
  } else {
    dispatch(setTab(tab));
  }
};

export const onBack = () => (dispatch, getState) => {
  if (hasChanges(getState())) {
    dispatch(
      openPopup(EditTemplatePopupIds.hasChanges, {
        callback: 'close',
        args: [true],
      })
    );
  } else {
    dispatch(close(true));
  }
};

export const setTabLoading = (loading) => ({
  type: EditTemplateActionTypes.setTabLoading,
  loading,
});

export const setTabAlert = (id, textValues) => ({
  type: EditTemplateActionTypes.setTabAlert,
  id,
  textValues,
});

export const openCardEditing = (cardId) => (dispatch, getState) => {
  dispatch({
    type: EditTemplateActionTypes.openCardEditing,
    cardId,
    editState: getEditStateByCard(cardId, getState()),
  });
};

export const closeCardEditing = (cardId) => (dispatch, getState) => {
  if (isSubjectMissing(cardId, getState())) {
    dispatch(setTabAlert(EditTemplateTabAlertIds.missingSubject));
  } else {
    dispatch({
      type: EditTemplateActionTypes.closeCardEditing,
      cardId,
    });
  }
};

export function closeCardEditingAll() {
  return {
    type: EditTemplateActionTypes.closeCardEditingAll,
  };
}

export const clearTabAlert = () => ({
  type: EditTemplateActionTypes.clearTabAlert,
});

export const updateTemplateEditState = (editorId, edits) => ({
  type: EditTemplateActionTypes.updateTemplateEditState,
  edits,
});

export const updateRecommendEditState = (edits, index) => ({
  type: EditTemplateActionTypes.updateRecommendEditState,
  edits,
  index,
});

export const addRecommendedRow = () => ({
  type: EditTemplateActionTypes.addRecommendedRow,
});

export const removeRecommendedRow = (index) => ({
  type: EditTemplateActionTypes.removeRecommendedRow,
  index,
});

export const initAnalyticsView = () => (dispatch) => {
  dispatch({
    type: EditTemplateActionTypes.initAnalyticsView,
  });
  dispatch(getTemplateAnalytics());
};

export const setAnalyticsTeamsSelect = (teamsSelect) => (dispatch) => {
  dispatch({
    type: EditTemplateActionTypes.setAnalyticsTeamsSelect,
    teamsSelect,
  });
  dispatch(getTemplateAnalytics());
};

export const setAnalyticsDatesSelect = (datesSelect) => (dispatch) => {
  dispatch({
    type: EditTemplateActionTypes.setAnalyticsDatesSelect,
    datesSelect,
  });
  dispatch(getTemplateAnalytics());
};

export const initEmailsView = () => (dispatch) => {
  dispatch(getTemplateEmails());
  dispatch({
    type: EditTemplateActionTypes.initEmailsView,
  });
};

export const setEmailsTeamsSelect = (teamsSelect) => ({
  type: EditTemplateActionTypes.setEmailsTeamsSelect,
  teamsSelect,
});

export const setEmailsStateSelect = (stateSelect) => ({
  type: EditTemplateActionTypes.setEmailsStateSelect,
  stateSelect,
});

export const openCompose = (templateId) => (dispatch) => {
  dispatch(close());
  navigateToComposeWindow(null, null, templateId);
};

const getOnSuccessFollowUp = (dispatch) => () => dispatch(setTabLoading(false));

const getOnFailureFollowUp = (dispatch) => () => {
  dispatch(setTabAlert(EditTemplateTabAlertIds.followUpFail));
  dispatch(setTabLoading(false));
};

export const openFollowUp = (emailId) => (dispatch) => {
  dispatch(setTabLoading(true));
  openFollowUpEmail(
    emailId,
    getOnSuccessFollowUp(dispatch),
    getOnFailureFollowUp(dispatch)
  );
  // track(PersonDetailsEvents.salesEmails, { [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.followUp });
};

export const openAddAsTemplate = (emailId) => (dispatch, getState) => {
  dispatch(setTabLoading(true));
  dispatch(getTemplateCategories())
    .then(() => {
      const {
        editTemplateSlideOutTemplate: { name },
        templatesSelectedTemplateCategoryId: categoryId,
      } = getState();
      dispatch(
        updateCreateTemplateState({
          categoryId,
          name,
        })
      );
      dispatch(openPopup(EditTemplatePopupIds.addAsTemplate, { emailId }));
    })
    .catch(() => {
      dispatch(setTabAlert(EditTemplateTabAlertIds.getTemplateCategoriesFail));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
};

export const archiveEmail = (emailId) => (dispatch) => {
  dispatch(setTabLoading(true));
  archiveEmailCall(emailId)
    .then(() => {
      dispatch({
        type: EditTemplateActionTypes.setEmail,
        email: { archived: true, id: emailId },
      });
      // track(PersonDetailsEvents.salesEmails, { [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.archive });
    })
    .catch(() => {
      dispatch(setTabAlert(EditTemplateTabAlertIds.archivingEmail));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
};

export const unarchiveEmail = (emailId) => (dispatch) => {
  dispatch(setTabLoading(true));
  unarchiveEmailCall(emailId)
    .then(() => {
      dispatch({
        type: EditTemplateActionTypes.setEmail,
        email: { archived: false, id: emailId },
      });
      // track(PersonDetailsEvents.salesEmails, { [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.unarchive });
    })
    .catch(() => {
      dispatch(setTabAlert(EditTemplateTabAlertIds.unarchivingEmail));
    })
    .finally(() => {
      dispatch(setTabLoading(false));
    });
};

export const openEvents = (emailId) => (dispatch, getState) => {
  const { editTemplateSlideOutEmails: emails } = getState();

  const email = emails.find(({ id }) => id === emailId);
  const events = (email && parseEventsFromEmail(email)) || [];

  dispatch(openPopup(EditTemplatePopupIds.events, { events }));
  // track(PersonDetailsEvents.salesEmails, { [PersonDetailsProperties.actionType]: PersonDetailsPropertyTypes.openSalesEvents });
};
