import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import './emailDisconnect.scss';

const EmailDisconnect = (props) => {
  const { providerText } = props;

  return (
    <div className="email-disconnect">
      <p>
        <FormattedHTMLMessage
          id="web.settings.emailConnection.modal.disconnectPrompt"
          values={{ provider: providerText }}
        />
      </p>
      <div className="warning-block">
        <i className="tout-icon-warning" />
        <p className="margin-left-most">
          <FormattedHTMLMessage
            id="web.settings.emailConnection.modal.disconnectWarning"
            values={{ provider: providerText }}
          />
        </p>
      </div>
    </div>
  );
};

EmailDisconnect.propTypes = {
  providerText: PropTypes.string.isRequired,
};

export default EmailDisconnect;
