import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/inputs/text';
import Menu from 'components/menu';
import Chevron, {
  ChevronColors,
  ChevronDirections,
} from 'components/buttons/chevron';
import classNames from 'classnames';
import './smartSelect.scss';
import Highlighter from 'components/highlighter';

export const SmartSelectThemes = {
  dark: 'dark',
  light: 'light',
};

class SmartSelect extends Component {
  constructor(props) {
    super(props);
    this.resetInputValue = '';
    this.smartSelectInput = null;
  }

  state = {
    focused: false,
    open: false,
    searching: false, // shows entire list of items if not searching
  };

  render() {
    const {
      className,
      exceptionSelectors,
      inputValue,
      label,
      rowHeight,
      rowsShown,
      theme,
      id,
      autoFocus,
      clearInput,
      placeholder,
    } = this.props;
    const { open, focused } = this.state;
    const menuItems = this.getMenuItems();
    const selectedIndex = this.getSelectedIndex(menuItems);
    const chevron = this.getChevron();
    const smartSelectThemeClass = `${theme}-theme`;
    const showClearInput = inputValue && clearInput;

    return (
      <div
        className={classNames('smart-select', className, {
          focused,
          open,
          [smartSelectThemeClass]: true,
        })}
        onClick={this.toggleMenu}
      >
        <div className="smart-select-input-wrapper">
          <div className="smart-select-label">{label}</div>
          <Text
            autoFocus={autoFocus}
            className={classNames('smart-select-input', 'text-overflow', {
              focused,
              open,
            })}
            id={id}
            onBlur={this.onBlur}
            onChange={this.onInputChange}
            onClick={this.openMenu}
            onFocus={this.onFocus}
            placeholder={placeholder}
            ref={this.setSmartSelectInputRef}
            value={inputValue}
          />
          {showClearInput && (
            <div className="tout-icon-close" onClick={clearInput} />
          )}
          {chevron}
        </div>
        {open && (
          <Menu
            className="smart-select-menu"
            exceptionSelectors={exceptionSelectors}
            handleSelected={this.handleSelected}
            itemRenderer={this.itemRenderer}
            items={menuItems}
            onOutsideClick={this.outsideClick}
            rowHeight={rowHeight}
            rowsShown={rowsShown}
            selected={selectedIndex}
          />
        )}
      </div>
    );
  }

  getChevron = () => {
    const { theme } = this.props;
    const { open } = this.state;
    const { top, bottom } = ChevronDirections;
    const { gray, white } = ChevronColors;
    const { dark } = SmartSelectThemes;

    const direction = open ? top : bottom;
    const color = theme === dark ? white : gray;

    return <Chevron color={color} direction={direction} />;
  };

  getSelectedIndex = (menuItems) => {
    const { selectedItem } = this.props;

    return menuItems.findIndex(({ value }) => value === selectedItem);
  };

  itemRenderer = ({ label, value }) => {
    const { selectedItem, inputValue, highlightInputValue } = this.props;

    return (
      <div
        className={classNames('smart-select-item', {
          selected: value === selectedItem,
        })}
      >
        <div className="text-overflow">
          {highlightInputValue ? (
            <Highlighter highlightTerm={inputValue} text={label} />
          ) : (
            label
          )}
        </div>
      </div>
    );
  };

  setSmartSelectInputRef = (ref) => {
    this.smartSelectInput = ref;
  };

  handleSelected = (selectedItem) => {
    this.toggleMenu();
    this.props.onSelect(selectedItem);
  };

  onInputChange = (inputValue) => {
    const { onChange } = this.props;
    const { searching } = this.state;

    this.openMenu();

    if (!searching) {
      this.setState(() => ({ searching: true }));
    }

    onChange(inputValue);
  };

  outsideClick = () => {
    const {
      useOnlyPredefinedValues,
      onChange,
      items,
      inputValue,
      onReset,
    } = this.props;
    if (useOnlyPredefinedValues) {
      const isSelected = items.some(({ label }) => label === inputValue);
      if (!isSelected && !onReset) {
        onChange('');
      }
    }

    if (onReset) {
      onReset();
    }
    this.toggleMenu();
  };

  openMenu = (e) => {
    if (e) {
      e.stopPropagation(); // stops event from bubbling from input -> menu
    }

    const { open } = this.state;

    if (!open) {
      this.toggleMenu();
    }
  };

  toggleMenu = () => {
    const { open } = this.state;

    this.handleInputOnToggle(open);
    this.setState(() => ({ open: !open, searching: false }));
  };

  handleInputOnToggle = (isOpen) => {
    const { inputValue } = this.props;

    if (!isOpen) {
      this.resetInputValue = inputValue;
      this.smartSelectInput.inputRef.focus();
    } else {
      this.smartSelectInput.inputRef.blur();
    }
  };

  getMenuItems = () => {
    const { items, inputValue } = this.props;
    const { searching } = this.state;

    if (!searching) {
      return items;
    }

    return items.filter(({ label }) =>
      label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  onFocus = () => this.setState(() => ({ focused: true }));

  onBlur = () => this.setState(() => ({ focused: false }));
}

SmartSelect.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  clearInput: PropTypes.func,
  exceptionSelectors: PropTypes.array, // outsideClickHandler
  highlightInputValue: PropTypes.bool,
  id: PropTypes.string,
  inputValue: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rowHeight: PropTypes.number,
  rowsShown: PropTypes.number,
  selectedItem: PropTypes.number,
  theme: PropTypes.oneOf(Object.values(SmartSelectThemes)),
  useOnlyPredefinedValues: PropTypes.bool,
};

SmartSelect.defaultProps = {
  autoFocus: false,
  className: '',
  clearInput: null,
  exceptionSelectors: ['.smart-select'],
  highlightInputValue: false,
  id: '',
  label: '',
  onReset: null,
  placeholder: '',
  rowHeight: 24,
  rowsShown: 4,
  selectedItem: -1,
  theme: SmartSelectThemes.light,
  useOnlyPredefinedValues: true,
};

export default SmartSelect;
