import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Icon from 'components/buttons/icon';
import { isDone } from 'web/tables/tasks/helpers/tablesTasksHelpers';
import './markDoneCell.scss';

class MarkDoneCell extends Component {
  render() {
    const {
      intl: { formatMessage },
      rowData,
      isActionsDisabled,
    } = this.props;
    const done = isDone(rowData);
    const { backgroundColor, classes, color } = this._getCellProps(done);

    return (
      <div className="cell-mark-done">
        <Icon
          backgroundColor={backgroundColor}
          classes={classes}
          color={color}
          disabled={isActionsDisabled(rowData)}
          onClick={this._onClick}
          title={formatMessage({
            id:
              (done && 'web.campaigns.tasks.markUndone') ||
              'web.campaigns.tasks.markDone',
          })}
        />
      </div>
    );
  }

  _getCellProps(done) {
    const props = {
      backgroundColor: '',
      classes: 'tout-icon-check',
      color: 'blue',
    };
    if (done) {
      props.backgroundColor = 'green';
      props.classes += ' done';
      props.color = 'white';
    }
    return props;
  }

  _onClick = (e) => {
    const { markDone, markUndone, rowData } = this.props;
    e.stopPropagation();
    e.preventDefault();

    if (isDone(rowData)) {
      markUndone(rowData);
    } else {
      markDone(rowData);
    }
  };
}

MarkDoneCell.propTypes = {
  intl: intlShape.isRequired,
  isActionsDisabled: PropTypes.func.isRequired,
  markDone: PropTypes.func.isRequired,
  markUndone: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default injectIntl(MarkDoneCell);
