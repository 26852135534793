import TemplatesActionTypes from 'web/templates/libs/templatesActionTypes';
import { getTemplates } from './templatesFetchActionCreators';
import {
  TEMPLATE_SMART_FILTER_VALUES,
  TEMPLATE_UNUSED_NUM_DAYS_AGO,
  TEMPLATE_TAG_TYPES,
  DEFAULT_PAGE,
} from '../libs/templatesConstants';
import { ElasticSearchMode } from 'web/elasticSearch/libs/elasticSearchConstants';
import { track } from 'web/services/mixpanelService';
import { setTablePage } from 'web/templates/actionCreators/templatesTableActionCreators';
import {
  TablesEvents,
  TablesProperties,
  TablesSources,
} from 'web/libs/mixpanelEvents';

export const templatesSmartFilterReset = () => (dispatch) => {
  dispatch({ type: TemplatesActionTypes.mode.set });
  dispatch({ type: TemplatesActionTypes.table.reset });
  dispatch({ type: TemplatesActionTypes.smartFilters.set });
};

export const templatesSmartFilterChange = (index, smartFilterValue) => (
  dispatch
) => {
  let smartFilters = {};
  let mode = null;
  let tags = [];
  switch (smartFilterValue) {
    case TEMPLATE_SMART_FILTER_VALUES.shared: {
      mode = ElasticSearchMode.shared;
      break;
    }
    case TEMPLATE_SMART_FILTER_VALUES.mine: {
      mode = ElasticSearchMode.mine;
      break;
    }
    case TEMPLATE_SMART_FILTER_VALUES.unused: {
      mode = ElasticSearchMode.all;
      smartFilters = {
        dateRange: {
          last_used: {
            end_range: TEMPLATE_UNUSED_NUM_DAYS_AGO,
            null_also: true,
          },
        },
      };
      break;
    }
    case TEMPLATE_SMART_FILTER_VALUES.favorite: {
      mode = ElasticSearchMode.all;
      tags = [TEMPLATE_TAG_TYPES.favorite];
      break;
    }
    case TEMPLATE_SMART_FILTER_VALUES.unshared: {
      mode = ElasticSearchMode.unshared;
      tags = [];
      break;
    }
    case TEMPLATE_SMART_FILTER_VALUES.all:
    default: {
      mode = ElasticSearchMode.all;
      break;
    }
  }
  dispatch({ type: TemplatesActionTypes.mode.set, mode });
  dispatch({
    type: TemplatesActionTypes.smartFilters.set,
    filters: smartFilters,
    label: smartFilterValue,
  });
  dispatch({ type: TemplatesActionTypes.tags.set, tags });
  dispatch(setTablePage(DEFAULT_PAGE));
  dispatch(getTemplates());
  track(TablesEvents.viewChange, {
    [TablesProperties.view]: smartFilterValue,
    [TablesProperties.source]: TablesSources.templates,
  });
};
