import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PersonDetailsCards } from 'web/person/libs/personDetailsConstants';
import InfoEditing from './editing';
import InfoDetails from './details';

class InfoCard extends Component {
  render() {
    const {
      actionCreators: {
        addInfoRow,
        closeCardEditing,
        onAddressClick,
        onInfoEditAddressTypeChange,
        openInMarketo,
        openInSalesforce,
        removeInfoRow,
        saveInfo,
        updateInfoEditStateAddresses,
        updateInfoEditStateContact,
        updateInfoEditStateSetPrimary,
        updateInfoEditStateSocial,
        updateInfoApiKeys,
        validateApiKeys,
        validateAddress,
        displayAlertInvalidMessage,
      },
      infoCardHasChanges,
      salesforceRequiredFields,
      hiddenButtons,
      infoEditState,
      isEditing,
      isNewContact,
      person,
      user,
      salesforceInstanceUrl,
    } = this.props;

    if (isEditing) {
      return (
        <InfoEditing
          addInfoRow={addInfoRow}
          cancel={closeCardEditing}
          displayAlertInvalidMessage={displayAlertInvalidMessage}
          editState={infoEditState}
          hiddenButtons={hiddenButtons}
          infoCardHasChanges={infoCardHasChanges}
          isNewContact={isNewContact}
          onAddressTypeChange={onInfoEditAddressTypeChange}
          openInMarketo={openInMarketo}
          openInSalesforce={openInSalesforce}
          person={person}
          removeInfoRow={removeInfoRow}
          requiredFields={salesforceRequiredFields}
          save={saveInfo}
          setPrimary={updateInfoEditStateSetPrimary}
          updateAddresses={updateInfoEditStateAddresses}
          updateApiKeys={updateInfoApiKeys}
          updateContact={updateInfoEditStateContact}
          updateSocial={updateInfoEditStateSocial}
          user={user}
          validateAddress={validateAddress}
          validateApiKeys={validateApiKeys}
        />
      );
    } else {
      return (
        <InfoDetails
          edit={this.edit}
          onAddressClick={onAddressClick}
          person={person}
          salesforceInstanceUrl={salesforceInstanceUrl}
        />
      );
    }
  }

  edit = () =>
    this.props.actionCreators.openCardEditing(PersonDetailsCards.info);
}

InfoCard.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  hiddenButtons: PropTypes.bool,
  infoCardHasChanges: PropTypes.bool.isRequired,
  infoEditState: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  isNewContact: PropTypes.bool,
  person: PropTypes.object.isRequired,
  salesforceInstanceUrl: PropTypes.string,
  salesforceRequiredFields: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

InfoCard.defaultProps = {
  hiddenButtons: false,
  isEditing: false,
  isNewContact: false,
  salesforceInstanceUrl: '',
};

export default InfoCard;
