import React from 'react';
import Popup from 'components/popups';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DYNAMIC_FIELDS_POPUP_WIDTH } from 'web/dynamicFields/components/dynamicFields';
import {
  imageInsertUploadFiles,
  uploadFiles,
  closeImageInsertModal,
  grabSelectedIds,
  toggleTrackedContent,
  bulkDeleteFiles,
  closeFileAttachmentModal,
  addAttachmentToEmail,
} from 'web/composeWindow/actionCreators/fileActionCreators';
import { PopupSize } from 'libs/constantsShared';
import {
  closePopup,
  setPopupData,
} from 'web/popup/actionCreators/popupActionCreators';
import { AlertColors } from 'components/alert';
import {
  ModalComponentIds,
  AlertIds,
} from 'web/composeWindow/libs/composeWindowConstants';
import { getImageUrl } from 'web/libs/constants';
import { savePinnedCategories } from 'web/composeWindow/actionCreators/composeTemplatesActionCreators';
import ComposeTemplatesEditPinnedCategories from 'web/composeWindow/components/composeTemplatesEditPinnedCategories/composeTemplatesEditPinnedCategories';
import { getContentPartnersMetadata } from 'web/modals/addContent/actionCreators/contentActionCreators';
import { boxName } from 'web/integrations/box/libs/boxConstants';
import ClearEditsModal from 'web/composeWindow/components/clearEditsModal/clearEditsModal';
import {
  getPopupEditorInstance,
  getPopupEditorId,
} from 'web/composeWindow/selectors/composeWindowSelectors';
import {
  insertImageDragAndDropDetails,
  attachFilesAndContentDetails,
} from 'web/tinyMceEditor/components/tinyMceEditorPopups';
import HighSpotContentWindow from 'web/tinyMceEditor/components/HighSpotContentWindow';
import { defaultPartnerName } from 'web/contentPartners/libs/contentPartnersConstants';

const ComposeWindowPopupsContainer = ({
  alert = {},
  closeImageInsertModal,
  closePopup,
  composeWindowEditorInstance,
  composeEmailEditorImages,
  loading,
  imageInsertUploadFiles,
  modalMode,
  pinnedCategories,
  popup,
  popupData,
  savePinnedCategories,
  setPopupData,
  templateCategories,
  ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  function getAlert() {
    const defaultAlert = {
      color: AlertColors.red,
      // eslint-disable-next-line no-undef
      onClose: hideAlert,
    };

    switch (alert.id) {
      case AlertIds.duplicateTemplateCategory:
        return {
          ...defaultAlert,
          textId: 'web.composeWindow.pinnedCategories.duplicateCategory',
        };
      default:
        return null;
    }
  }

  function getPopupDetails() {
    switch (popup) {
      case ModalComponentIds.insertImageDragAndDrop: {
        return insertImageDragAndDropDetails({
          closeImageInsertModal,
          editorInstance: composeWindowEditorInstance,
          imageInsertUploadFiles,
          images: composeEmailEditorImages,
        });
      }
      case ModalComponentIds.editPinnedTemplateCategories:
        return editPinnedTemplateCategories();
      case ModalComponentIds.attachFilesAndContent: {
        const size = modalMode ? DYNAMIC_FIELDS_POPUP_WIDTH : undefined;

        return attachFilesAndContentDetails({
          closePopup,
          contentProvider: defaultPartnerName,
          editorInstance: composeWindowEditorInstance,
          size,
          ...rest,
        });
      }
      case ModalComponentIds.attachBoxContents: {
        const size = modalMode ? DYNAMIC_FIELDS_POPUP_WIDTH : undefined;

        return attachFilesAndContentDetails({
          closePopup,
          contentProvider: boxName,
          editorInstance: composeWindowEditorInstance,
          size,
          ...rest,
        });
      }
      case ModalComponentIds.clearEditsModal:
        return clearEditsWarning();
      default:
        return {};
    }
  }

  function editPinnedTemplateCategories() {
    return {
      children: (
        <ComposeTemplatesEditPinnedCategories
          pinnedCategories={pinnedCategories}
          templateCategories={templateCategories}
        />
      ),
      footer: {
        onPrimary: savePinnedCategories,
        onSecondary: closePopup,
        primaryTextId: 'common.saveChanges',
        secondaryTextId: 'common.cancel',
      },
      getImageUrl,
      header: {
        onClose: closePopup,
        textId: 'web.composeWindow.pinnedCategories.header',
      },
      loading,
      onOutsideClick: closePopup,
      size: DYNAMIC_FIELDS_POPUP_WIDTH,
    };
  }

  if (popup === ModalComponentIds.highSpotContent) {
    return HighSpotContentWindow({
      ...rest,
      editorInstance: composeWindowEditorInstance,
    });
  }
  function clearEditsWarning() {
    const { confirmEdit, isDisableChecked = false, messageId } = popupData;
    const setDisableChecked = (checked) =>
      setPopupData({ ...popupData, isDisableChecked: checked });

    return {
      children: (
        <ClearEditsModal
          isDisableChecked={isDisableChecked}
          messageId={messageId}
          setDisableChecked={setDisableChecked}
        />
      ),
      footer: {
        onPrimary: () => confirmEdit(isDisableChecked),
        onSecondary: closePopup,
        primaryTextId: 'common.clear',
        secondaryTextId: 'common.cancel',
      },
      header: {
        onClose: closePopup,
        textId: 'web.composeWindow.clearEditsModal.header',
      },
      size: PopupSize.small,
    };
  }

  const { children, ...popupDetails } = getPopupDetails();

  return children ? <Popup {...popupDetails}>{children}</Popup> : null;
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        addAttachmentToEmail,
        bulkDeleteFiles,
        closeFileAttachmentModal,
        closeImageInsertModal,
        closePopup,
        getContentPartnersMetadata,
        grabSelectedIds,
        imageInsertUploadFiles,
        savePinnedCategories,
        setPopupData,
        toggleTrackedContent,
        uploadFiles,
      },
      dispatch
    ),
  };
}

const mapStateToProps = (state) => ({
  alert: state.composeWindowAlerts,
  boxContentFiles: state.boxContentFiles,
  composeEmailAttachments: state.composeEmailAttachments,
  composeEmailEditorImages: state.composeEmailEditorImages,
  composeWindowEditorInstance: getPopupEditorInstance(state),
  content: state.content,
  contentPartners: state.contentPartners,
  dynamicFields: state.dynamicFields,
  editorId: getPopupEditorId(state),
  loading: state.popupLoading,
  partnerMetadata: state.partnerMetadata,
  pinnedCategories: state.composeTemplatesPinnedCategories,
  popup: state.popup,
  popupData: state.popupData,
  selectedComposeFiles: state.selectedComposeFiles,
  templateCategories: state.composeTemplatesCategories,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeWindowPopupsContainer);
