import React from 'react';
import CampaignContentContainer from 'web/campaigns/campaignsTab/containers/campaignContentContainer/campaignContentContainer';
import './campaignDetailsContainer.scss';

const CampaignDetailsContainer = () => (
  <div key="campaign-details-container" className="campaign-details-container">
    <CampaignContentContainer />
  </div>
);

export default CampaignDetailsContainer;
