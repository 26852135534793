export default {
  columnFilterChangeColumn: 'COLUMN_FILTER_CHANGE_COLUMN',
  columnFilterChangeColumns: 'COLUMN_FILTER_CHANGE_COLUMNS',
  columnFilterSetColumn: 'COLUMN_FILTER_SET_COLUMN',
  columnOrderSetOrder: 'COLUMN_ORDER_SET_ORDER',
  columnSettingsSetColumnsWidth: 'COLUMN_SETTINGS_SET_COLUMNS_WIDTH',
  paginationSet: 'PAGINATION_SET',
  paginationSetClearSelected: 'PAGINATION_SET_CLEAR_SELECTED',
  paginationSetCurrentPage: 'PAGINATION_SET_CURRENT_PAGE',
  paginationSetItems: 'PAGINATION_SET_ITEMS',
  paginationSetPerPage: 'PAGINATION_SET_PER_PAGE',
  searchClearPrefix: 'SEARCH_CLEAR_PREFIX',
  searchSetPrefix: 'SEARCH_SET_PREFIX',
  searchSetValue: 'SEARCH_SET_VALUE',
  selectableClearSelectedItems: 'SELECTABLE_CLEAR_SELECTED_ITEMS',
  selectableRangeSelection: 'SELECTABLE_RANGE_SELECTION',
  selectableSelectAllItems: 'SELECTABLE_SELECT_ALL_ITEMS',
  selectableSetHeader: 'SELECTABLE_SET_HEADER',
  selectableSetOneSelectedItem: 'SELECTABLE_SET_ONE_SELECTED_ITEM',
  selectableSetSelectedItem: 'SELECTABLE_SET_SELECTED_ITEM',
  smartFilterSetSelectedValue: 'SMART_FILTER_SET_SELECTED_VALUE',
  sortingSetColumn: 'SORTING_SET_COLUMN',
  sortingSetDirection: 'SORTING_SET_DIRECTION',
  tableSetAllItems: 'TABLE_SET_ALL_ITEMS',
  tableSetColumns: 'TABLE_SET_COLUMNS',
  tableSetId: 'TABLE_SET_ID',
  tableSetItems: 'TABLE_SET_ITEMS',
  tableSetLocalStorage: 'TABLE_SET_LOCAL_STORAGE',
};
