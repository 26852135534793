import apiHelper from 'web/services/apiHelper';

export const getNewAccountData = (formValues, invitation_code) => {
  const {
    first_name,
    last_name,
    password,
    job_title = '',
    country,
  } = formValues;
  const authenticity_token = apiHelper.getCsrfTokenFromDocument();

  const user_metric_attributes = {
    country,
    title: job_title,
  };

  const user = {
    first_name,
    last_name,
    password,
    user_metric_attributes,
  };

  const newAccountData = {
    authenticity_token,
    format: 'json',
    invitation_code,
    mixpanel_distinct_id: -1,
    user,
  };

  return newAccountData;
};

export const getMetaField = (fieldName) => {
  const metaSelector = `meta[name="${fieldName}"]`;
  let metaValue = null;
  if (document.querySelector(metaSelector)) {
    metaValue = document.querySelector(metaSelector).getAttribute('content');
  }
  return metaValue;
};

export const firstName = getMetaField('invitation-first-name');
export const lastName = getMetaField('invitation-last-name');
export const email = getMetaField('invitation-email');
export const isInvitationExpired =
  getMetaField('invitation-expired') === 'true';
export const isOnboardingMode =
  getMetaField('invitation-is-onboarding-mode') === 'true';
export const createNewAccountFromSF =
  getMetaField('invitation-is-login_method_sf_only') === 'true';
export const isSFSandboxAuthenticationEnabled =
  getMetaField('invitation-is-sf-sandbox-authentication-enabled') === 'true';

export const isInvitationValid = () => !!email && !isInvitationExpired;
