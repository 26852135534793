import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text from '../text';
import { Regex, INVALID_DATE } from 'libs/constantsShared';
import './timeInput.scss';
import I18N from 'languages';

export default class TimeInput extends Component {
  state = {
    error: false,
  };

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.error === 'boolean') {
      // allows component's error to be controlled by parent if not a boolean or control itself
      this.setState({ error: nextProps.error });
    }
  }

  render() {
    const {
      className,
      error: ignored,
      onChange: ignored2,
      ...other
    } = this.props; // necessary to pull out onChange & error as ...other will overwrite the original prop passed into InputText
    const { error } = this.state;

    if (error) {
      other.value =
        other.value === INVALID_DATE
          ? I18N.getStr('scheduleLater.errorDate')
          : other.value;
    }

    return (
      <div className={classNames('timeInput', className)}>
        <Text
          autoFocus={false}
          className="time-input-text"
          error={error}
          onChange={this.onChange}
          {...other}
        />
      </div>
    );
  }

  onChange = (value = '') => {
    const { error, onChange } = this.props;
    let regex = true;

    if (typeof error !== 'boolean') {
      regex = value.match(Regex.time);

      this.setState({
        error: !regex,
      });
    }

    onChange(value, !regex);
  };
}

TimeInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

TimeInput.defaultProps = {
  className: '',
  error: undefined, // if boolean, error is controlled by parent, otherwise, let TimeInput handle
};
