export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 25;
export const MASKED_BULLET_POINTS = '•';
export const DEFAULT_REPEATED_AMOUNT = 32;

export const TabValues = {
  settings: 'settings',
  setup: 'setup',
  userAccess: 'userAccess',
};

export const ConnectionStates = {
  connected: 'connected',
  connecting: 'connecting',
  disconnected: 'disconnected',
  disconnecting: 'disconnecting',
  gettingIntegration: 'gettingIntegration',
  hasConnection: 'hasConnection',
  noSalesforceConnection: 'noSalesforceConnection',
};

export const Roles = {
  engageAdmin: 'Engage Admin User',
  salesUser: 'Engage Sales User',
};

export const TeamAccessSmartFilterValues = {
  all: 'ALL_USERS',
  granted: 'USERS_GRANTED_ACCESS',
  notGranted: 'USERS_NOT_GRANTED_ACCESS',
};

export const EveryoneTeamName = 'Everyone';
export const DefaultWorkspace = 'Default';

export const MarketoSettingsAlertIds = {
  allSelectedUsersConnected: 'marketoSettingsAllSelectedUsersConnected',
  allSelectedUsersDisconnected: 'marketoSettingsAllSelectedUsersDisconnected',
  alreadyExists: 'marketoSettingsAlreadyExists',
  connectionFailed: 'marketoSettingsConnectionFailed',
  disconnectionFailed: 'marketoSettingsDisconnectionFailed',
  getWorkspacesFailed: 'marketoSettingsGetWorkspacesFailed',
  grantAccessFailed: 'marketoSettingsGrantAccessFailed',
  grantAccessSomeFailed: 'marketoSettingsGrantAccessSomeFailed',
  grantAccessSuccess: 'marketoSettingsGrantAccessSuccess',
  revokeAccessFailed: 'marketoSettingsRevokeAccessFailed',
  revokeAccessSomeFailed: 'marketoSettingsRevokeAccessSomeFailed',
  revokeAccessSuccess: 'marketoSettingsRevokeAccessSuccess',
};

export const BULK_ACTIONS = {
  create: 'bulk_create',
  destroy: 'bulk_destroy',
};

export const WORKSPACES_ERROR = "The Marketo API didn't return workspaces.";

export const ACCESS_ROLE = 'access_role';

export const ErrorMessages = {
  alreadyExists:
    'Another subscription is already connected with this Munchkin ID',
};
