import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabValues } from '../../libs/sharingSettingsConstants';
import { getImageUrl } from 'web/libs/constants';
import SidebarWhite from 'components/sidebars/white';
import './sharingSettingsSidebar.scss';

class SharingSettingsSidebar extends Component {
  render() {
    const {
      actionCreators,
      categories,
      loading,
      selectedCategoryIndex,
      selectedTabValue,
    } = this.props;

    return (
      <div className="sharing-settings-sidebar">
        <SidebarWhite
          getImageUrl={getImageUrl}
          handleSelected={actionCreators.onCategorySelect}
          headerTextId="common.categories"
          items={categories}
          loading={loading}
          selectedIndex={selectedCategoryIndex}
          zeroStateTextId="web.settings.sharing.sidebar.empty"
          zeroStateTextValues={{
            isTemplates: selectedTabValue === TabValues.templates,
          }}
        />
      </div>
    );
  }
}

SharingSettingsSidebar.propTypes = {
  actionCreators: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedCategoryIndex: PropTypes.number.isRequired,
  selectedTabValue: PropTypes.string.isRequired,
};

export default SharingSettingsSidebar;
