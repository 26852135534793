import merge from 'lodash/merge';
import pipelineRoot from '../pipeline/reducers/pipelineRootReducer';
import engageRoot from '../engage/reducers/engageRootReducer';
import * as liveFeedReducers from './liveFeedReducers';
import tasksRoot from '../tasks/reducers/tasksRootReducer';
import * as liveFeedHeaderReducers from './liveFeedHeaderReducers';

export default merge(
  {},
  engageRoot,
  pipelineRoot,
  tasksRoot,
  liveFeedReducers,
  liveFeedHeaderReducers
);
