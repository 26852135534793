import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EnableSyncSalesforce from 'web/settings/adminSettings/unsubscribes/containers/enableSyncSalesforceContainer';
import SalesforceMarketoSalesOptOut from 'web/settings/adminSettings/unsubscribes/components/salesforceMarketoSalesOptOut';

// eslint-disable-next-line react/prefer-stateless-function
class SalesforceSyncCard extends Component {
  render() {
    const { enableUnsubscribeSync, salesforceSyncField } = this.props;

    return (
      <Fragment>
        <h3>
          <FormattedMessage id="web.settings.unsubscribe.salesforce.title" />
        </h3>
        <div className="margin-top-full margin-bottom-full">
          <FormattedMessage id="web.settings.unsubscribe.salesforce.desc" />
        </div>
        <EnableSyncSalesforce />
        {enableUnsubscribeSync ? (
          <SalesforceMarketoSalesOptOut
            salesforceSyncField={salesforceSyncField}
          />
        ) : null}
      </Fragment>
    );
  }
}

SalesforceSyncCard.propTypes = {
  enableUnsubscribeSync: PropTypes.bool.isRequired,
  salesforceSyncField: PropTypes.string.isRequired,
};

export default SalesforceSyncCard;
