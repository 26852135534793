import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'table/modules/table';
import './addToCampaignTable.scss';

const TABLE_ID = 'ADD_TO_CAMPAIGN_TABLE_ID';

class AddToCampaignTable extends Component {
  render() {
    const { columns, campaigns, onSelect, selectedId } = this.props;
    const selectedIds = selectedId ? { [selectedId]: true } : {};

    return (
      <div className="add-to-campaign-table tout-table">
        <Table
          tableId={TABLE_ID}
          columns={columns}
          items={campaigns}
          radio
          row={{
            onRadioClick: onSelect,
            onClick: this.onRowClick,
          }}
          selectedIds={selectedIds}
        />
      </div>
    );
  }

  onRowClick = ({ id }) => {
    const { onSelect } = this.props;
    onSelect(id);
  };
}

AddToCampaignTable.propTypes = {
  columns: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedId: PropTypes.number,
};

export default AddToCampaignTable;
