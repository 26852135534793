import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { getImageUrl } from 'web/libs/constants';
import LoadingSpinner from 'components/loadingSpinner';
import SafelyChangeRouteContainer from 'web/safelyChangeRoute/containers/safelyChangeRouteContainer';
import SmtpServerPopupContainer from '../../containers/smtpServerPopupContainer';
import SmtpServerPage from '../smtpServerPage';

class SmtpServer extends Component {
  render() {
    const {
      actionCreators,
      loading,
      model,
      formName,
      isSafeToChangeRoute,
      additionalServerValidators,
      isTeamSmtpDeliveryChannelExists,
    } = this.props;

    return [
      loading && (
        <LoadingSpinner imageUrl={getImageUrl} key="loading-spinner" />
      ),
      <SafelyChangeRouteContainer
        key="safely-change-route-container"
        isSafeToChangeRoute={isSafeToChangeRoute}
        beforeUnsavedChangesPopupOpen={actionCreators.closeViewAlert}
      />,
      <SmtpServerPopupContainer key="smtp-server-popup-container" />,
      <SmtpServerPage
        key="smtp-server-page"
        actionCreators={actionCreators}
        model={model}
        formName={formName}
        additionalServerValidators={additionalServerValidators}
        isTeamSmtpDeliveryChannelExists={isTeamSmtpDeliveryChannelExists}
      />,
    ];
  }
}

SmtpServer.propTypes = {
  intl: intlShape.isRequired,
  actionCreators: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.object,
  formName: PropTypes.string.isRequired,
  isSafeToChangeRoute: PropTypes.bool.isRequired,
  additionalServerValidators: PropTypes.array,
  isTeamSmtpDeliveryChannelExists: PropTypes.bool.isRequired,
};

SmtpServer.defaultProps = {
  loading: true,
  model: null,
  additionalServerValidators: [],
  isSafeToChangeRoute: true,
  isTeamSmtpDeliveryChannelExists: false,
};

export default injectIntl(SmtpServer);
