import union from 'lodash/union';
import ComposeWindowActionTypes from '../libs/composeWindowActionTypes';
import AddressBarActionTypes from '../libs/addressBarActionTypes';
import {
  BulkEmails,
  PitchStates,
} from 'web/composeWindow/libs/composeWindowConstants';
import {
  hasBlockedStates,
  hasDynamicFieldStates,
} from 'web/composeWindow/helpers/composeWindowHelpers';
import personDetailsSlideOutActionTypes from 'web/slideOuts/personDetails/libs/personDetailsSlideOutActionTypes';
import templateSlideOutActionTypes from 'web/slideOuts/editTemplate/libs/editTemplateSlideOutActionTypes';
import { SortingDirection } from 'table/modules/constants';

export const initialComposeWindowState = {
  bulkViewOpened: false,
  currentPitchId: null,
  editorFocused: false,
  editorInstance: null,
  isLoading: false,
  isProcessingPitch: false,
  minimized: false,
  modalMode: false,
  newPitch: true,
  opened: false,
  queuingSendPitch: false,
  readOnly: false,
};

export function composeWindowState(
  state = initialComposeWindowState,
  { type, payload }
) {
  switch (type) {
    case ComposeWindowActionTypes.initComposeWindowSuccess:
      return {
        ...state,
        isLoading: false,
      };
    case ComposeWindowActionTypes.setLoading: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case ComposeWindowActionTypes.openComposeWindowView:
      return {
        ...state,
        isLoading: true,
        opened: true,
      };
    case ComposeWindowActionTypes.closeComposeWindowView:
      return { ...initialComposeWindowState };
    case ComposeWindowActionTypes.storeEditorInstance:
      return {
        ...state,
        editorInstance: payload,
      };
    case ComposeWindowActionTypes.setEditorFocused:
      return {
        ...state,
        editorFocused: payload,
      };
    case ComposeWindowActionTypes.toggleBulkEmailView:
      return {
        ...state,
        bulkViewOpened: payload,
      };
    case ComposeWindowActionTypes.setMinimizeView:
      return {
        ...state,
        bulkViewOpened: payload.bulkViewOpened,
        editorFocused: false,
        minimized: payload.minimized,
      };
    case ComposeWindowActionTypes.setComposeReadOnly:
      return {
        ...state,
        readOnly: payload,
      };
    case personDetailsSlideOutActionTypes.open:
    case templateSlideOutActionTypes.open:
      return state.opened
        ? {
            ...state,
            bulkViewOpened: false,
            minimized: true,
          }
        : state;
    case ComposeWindowActionTypes.setAsModalMode:
      return {
        ...state,
        modalMode: true,
      };
    default:
      return state;
  }
}

const initialEditorContentState = '<div>&nbsp;</div>';

export function composeEditorContent(
  state = initialEditorContentState,
  { type, payload }
) {
  switch (type) {
    case ComposeWindowActionTypes.handleEditorChange:
      return payload;
    case ComposeWindowActionTypes.resetEditorValue:
    case ComposeWindowActionTypes.reset:
      return initialEditorContentState;
    default:
      return state;
  }
}

const initialComposeUserInfo = {
  dynamicFields: {},
  identities: [],
};

export const composeUserInfo = (
  state = initialComposeUserInfo,
  { type, payload }
) => {
  switch (type) {
    case ComposeWindowActionTypes.initComposeWindowSuccess:
      return {
        dynamicFields: payload.dynamicFields,
        identities: payload.identities,
      };
    default:
      return state;
  }
};

export const composeEmailAttachments = (state = [], { type, payload }) => {
  switch (type) {
    case ComposeWindowActionTypes.setAttachments:
      return payload;
    case ComposeWindowActionTypes.addAttachmentToEmail: {
      const attachments = {};
      [...payload, ...state].forEach((attachment) => {
        const { id } = attachment;
        attachments[id] = attachment;
      });
      return Object.values(attachments);
    }
    case ComposeWindowActionTypes.removeAttachment: {
      return state.filter((attachment) => attachment.id !== payload);
    }
    case ComposeWindowActionTypes.clearComposeAttachments:
    case ComposeWindowActionTypes.reset:
      return [];
    default:
      return state;
  }
};

export const boxContentFiles = (state = [], { type, boxContentFiles }) => {
  switch (type) {
    case ComposeWindowActionTypes.setBoxContentFiles:
      return boxContentFiles;
    default:
      return state;
  }
};

const initialSelectedComposeFilesState = {
  ids: [],
  tracked: false,
};

export const selectedComposeFiles = (
  state = initialSelectedComposeFilesState,
  { type, payload }
) => {
  switch (type) {
    case ComposeWindowActionTypes.grabSelectedIds:
      return {
        ...state,
        ids: payload,
      };
    case ComposeWindowActionTypes.toggleTrackedContent:
      return {
        ...state,
        tracked: payload,
      };
    case ComposeWindowActionTypes.resetSelectedFiles:
      return { ...initialSelectedComposeFilesState };
    default:
      return state;
  }
};

export const composeEmailEditorImages = (state = [], { type, payload }) => {
  switch (type) {
    case ComposeWindowActionTypes.initImageUploadSuccess:
    case ComposeWindowActionTypes.initImageUploadUpdating: {
      const newState = [...state];
      const { reference } = payload;
      const foundIndex = state.findIndex(
        (attachment) => attachment.reference === reference
      );
      newState[foundIndex] = { ...newState[foundIndex], ...payload };
      return newState;
    }
    case ComposeWindowActionTypes.initImageUploadRequest:
      return [...state, payload];
    case ComposeWindowActionTypes.resetEmailEditorImages:
      return [];
    default:
      return state;
  }
};

export const pitchSendAt = (state = null, { type, sendAt = null }) => {
  switch (type) {
    case ComposeWindowActionTypes.setPitchSendAt:
      return sendAt;
    case ComposeWindowActionTypes.reset:
      return null;
    default:
      return state;
  }
};

const initSlidingWindow = {
  endingWindow: BulkEmails.slidingWindow.max,
  startingWindow: 0,
};
export const composeBulkSlidingWindow = (
  state = initSlidingWindow,
  { type, startingWindow, endingWindow }
) => {
  switch (type) {
    case ComposeWindowActionTypes.bulkView.setSlidingWindow:
      return { endingWindow, startingWindow };
    case ComposeWindowActionTypes.bulkView.resetBulkView:
    case ComposeWindowActionTypes.bulkView.resetSlidingWindow:
      return initSlidingWindow;
    default:
      return state;
  }
};

const composeBulkInitState = {
  attachmentIds: [],
  drafts: {},
  group: null,
  masterEmail: {
    attachmentIds: [],
    bcc: [],
    body: '',
    cc: [],
    identity: {},
    sendAt: '',
    subject: '',
  },
  recipients: [],
  searchResults: [],
  searchValue: '',
};

const stateValueMap = {
  [BulkEmails.stateReason.invalidDynamicFields]:
    BulkEmails.sortWeight.dynamicField,
  [BulkEmails.stateReason.blocked]: BulkEmails.sortWeight.blocked,
  [BulkEmails.stateReason.unsubscribed]: BulkEmails.sortWeight.unsubscribed,
  [BulkEmails.stateReason.success]: BulkEmails.sortWeight.success,
};
export const composeBulkEmails = (
  state = composeBulkInitState,
  { type, payload }
) => {
  switch (type) {
    case ComposeWindowActionTypes.bulkView.setLoading: {
      return { ...state, bulkComposeLoading: payload };
    }
    case ComposeWindowActionTypes.bulkView.setGroup: {
      return { ...state, group: payload };
    }
    case ComposeWindowActionTypes.bulkView.setRecipients:
      return { ...state, recipients: payload };
    case ComposeWindowActionTypes.bulkView.sortRecipients: {
      const { recipients } = state;

      let sortedRecipients = recipients.sort((recipientA, recipientB) => {
        let aValue =
          stateValueMap[recipientA.state] || BulkEmails.sortWeight.default;
        let bValue =
          stateValueMap[recipientB.state] || BulkEmails.sortWeight.default;

        if (aValue > bValue) {
          return 1;
        }

        if (aValue < bValue) {
          return -1;
        }

        return 0;
      });

      sortedRecipients =
        payload === SortingDirection.DESC
          ? sortedRecipients.reverse()
          : sortedRecipients;

      return { ...state, recipients: [...sortedRecipients] };
    }
    case ComposeWindowActionTypes.bulkView.clearRecipients:
      return { ...state, drafts: {}, recipients: [] };
    case ComposeWindowActionTypes.bulkView.removeRecipient: {
      const recipients = state.recipients.filter((r) => r.id !== payload.id);
      const updatedDrafts = { ...state.drafts };
      delete updatedDrafts[payload.id];
      return { ...state, drafts: updatedDrafts, recipients };
    }
    case ComposeWindowActionTypes.bulkView.addRecipient: {
      return { ...state, recipients: [...state.recipients, payload] };
    }
    case ComposeWindowActionTypes.bulkView.updateRecipientState: {
      const { hasDynamicFieldErrors, recipientId } = payload;
      const { recipients } = state;
      const recipient = recipients.find(
        (recipient) => recipient.id === recipientId
      );

      if (hasDynamicFieldErrors && !hasBlockedStates(recipient.state)) {
        recipient.state = BulkEmails.stateReason.invalidDynamicFields;
      } else if (!hasDynamicFieldErrors && !hasBlockedStates(recipient.state)) {
        recipient.state = BulkEmails.stateReason.success;
      }

      return { ...state, recipients: [...recipients] };
    }
    case ComposeWindowActionTypes.bulkView.clearSingleRecipientFieldState: {
      const recipientId = payload;
      const { recipients } = state;
      const recipient = recipients.find(
        (recipient) => recipient.id === recipientId
      );

      if (hasDynamicFieldStates(recipient.state)) {
        recipient.state = null;
      }

      return { ...state, recipients: [...recipients] };
    }
    case ComposeWindowActionTypes.bulkView.clearAllRecipientFieldState: {
      const { recipients } = state;
      const updatedRecipients = recipients.map((recipient) => {
        if (hasDynamicFieldStates(recipient.state)) {
          return {
            ...recipient,
            state: null,
          };
        } else {
          return { ...recipient };
        }
      });

      return { ...state, recipients: updatedRecipients };
    }
    case ComposeWindowActionTypes.bulkView.searchPeople:
      return { ...state, searchResults: [...payload] };
    case ComposeWindowActionTypes.bulkView.setSearchValue:
      return { ...state, searchValue: payload };
    case ComposeWindowActionTypes.bulkView.searchResultClear:
      return { ...state, searchResults: [] };
    case ComposeWindowActionTypes.bulkView.setDrafts: {
      return { ...state, drafts: { ...payload } };
    }
    case ComposeWindowActionTypes.bulkView.addDraft: {
      return { ...state, drafts: { ...state.drafts, ...payload } };
    }
    case ComposeWindowActionTypes.bulkView.updateAllDraftBodies: {
      const { drafts } = state;
      for (const recipientId in drafts) {
        if (Object.prototype.hasOwnProperty.call(drafts, recipientId)) {
          drafts[recipientId].body = payload;
        }
      }
      return { ...state, drafts };
    }
    case ComposeWindowActionTypes.bulkView.updateSingleDraftBody: {
      const { recipientId, body } = payload;
      const { drafts } = state;
      const draft = drafts[recipientId];
      draft.body = body;
      return {
        ...state,
        drafts: { ...state.drafts, [recipientId]: draft },
      };
    }
    case ComposeWindowActionTypes.bulkView.updateAllDraftSubjects: {
      const { drafts } = state;
      for (const recipientId in drafts) {
        if (Object.prototype.hasOwnProperty.call(drafts, recipientId)) {
          drafts[recipientId].subject = payload;
        }
      }
      return { ...state, drafts };
    }
    case ComposeWindowActionTypes.bulkView.updateSingleDraftSubject: {
      const { recipientId, subject } = payload;
      const { drafts } = state;
      const draft = drafts[recipientId];
      draft.subject = subject;
      return {
        ...state,
        drafts: { ...state.drafts, [recipientId]: draft },
      };
    }
    case ComposeWindowActionTypes.bulkView.selectCurrentRecipient:
      return { ...state, currentlySelectedRecipient: payload };
    case ComposeWindowActionTypes.bulkView.updateMasterEmailBody:
      return { ...state, masterEmail: { ...state.masterEmail, body: payload } };
    case ComposeWindowActionTypes.bulkView.updateMasterEmailSubject:
      return {
        ...state,
        masterEmail: { ...state.masterEmail, subject: payload },
      };
    case ComposeWindowActionTypes.bulkView.addAttachmentIdsToMasterEmail: {
      const { masterEmail } = state;
      return {
        ...state,
        masterEmail: {
          ...masterEmail,
          attachmentIds: union(masterEmail.attachmentIds, payload),
        },
      };
    }
    case ComposeWindowActionTypes.bulkView.removeAttachmentIdFromMasterEmail: {
      const { masterEmail } = state;
      return {
        ...state,
        masterEmail: {
          ...masterEmail,
          attachmentIds: masterEmail.attachmentIds.filter(
            (id) => id !== payload
          ),
        },
      };
    }
    case ComposeWindowActionTypes.bulkView.setMasterEmailAttachmentIds: {
      const { masterEmail } = state;
      return {
        ...state,
        masterEmail: {
          ...masterEmail,
          attachmentIds: payload,
        },
      };
    }
    case ComposeWindowActionTypes.bulkView.setAllDraftsAttachmentIds: {
      const { drafts } = state;
      const updatedDrafts = { ...drafts };
      for (const recipientId in updatedDrafts) {
        if (Object.prototype.hasOwnProperty.call(updatedDrafts, recipientId)) {
          const draft = updatedDrafts[recipientId];
          draft.attachmentIds = payload;
        }
      }

      return { ...state, drafts: updatedDrafts };
    }
    case ComposeWindowActionTypes.bulkView.setSingleDraftAttachmentIds: {
      const { drafts } = state;
      const { attachmentIds, recipientId } = payload;

      const updatedDraft = { ...drafts[recipientId], attachmentIds };
      return { ...state, drafts: { ...drafts, [recipientId]: updatedDraft } };
    }
    case ComposeWindowActionTypes.bulkView.addAttachmentIdsToSingleEmail: {
      const { recipientId, attachmentIds } = payload;
      const { drafts } = state;
      const draft = drafts[recipientId];
      const existingAttachmentIds = draft.attachmentIds || [];
      draft.attachmentIds = union(existingAttachmentIds, attachmentIds);
      return {
        ...state,
        drafts: { ...state.drafts, [recipientId]: draft },
      };
    }
    case ComposeWindowActionTypes.bulkView.removeAttachmentIdFromSingleEmail: {
      const { recipientId, attachmentId } = payload;
      const { drafts } = state;
      const draft = drafts[recipientId];
      draft.attachmentIds = draft.attachmentIds.filter(
        (id) => id !== attachmentId
      );
      return {
        ...state,
        drafts: { ...state.drafts, [recipientId]: draft },
      };
    }
    case ComposeWindowActionTypes.bulkView.removeAttachmentIdFromAllDrafts: {
      const { drafts } = state;
      for (const recipientId in drafts) {
        if (Object.prototype.hasOwnProperty.call(drafts, recipientId)) {
          const draft = drafts[recipientId];
          draft.attachmentIds = draft.attachmentIds.filter(
            (id) => id !== payload
          );
        }
      }
      return { ...state, drafts };
    }
    case ComposeWindowActionTypes.bulkView.updateAllDraftAttachments: {
      const { drafts } = state;
      for (const recipientId in drafts) {
        if (Object.prototype.hasOwnProperty.call(drafts, recipientId)) {
          const draft = drafts[recipientId];
          const existingAttachmentIds = draft.attachmentIds || [];
          draft.attachmentIds = union(existingAttachmentIds, payload);
        }
      }
      return { ...state, drafts };
    }
    case ComposeWindowActionTypes.bulkView.addCcAndBccToMasterEmail: {
      const { masterEmail } = state;
      const { address, key: addressType } = payload;

      const updatedMasterEmail = { ...masterEmail };
      updatedMasterEmail[addressType] = [...masterEmail[addressType], address];

      return { ...state, masterEmail: updatedMasterEmail };
    }
    case ComposeWindowActionTypes.bulkView.addCcAndBccToSingleEmail: {
      const { recipientId, address, key: addressType } = payload;
      const { drafts } = state;
      const updatedDraft = { ...drafts[recipientId] };
      updatedDraft[addressType] = [...updatedDraft[addressType], address];

      return {
        ...state,
        drafts: { ...state.drafts, [recipientId]: updatedDraft },
      };
    }
    case ComposeWindowActionTypes.bulkView.addCcAndBccToAllDrafts: {
      const { drafts, masterEmail } = state;
      const { address, key: addressType } = payload;

      const updatedDrafts = { ...drafts };

      for (const recipientId in updatedDrafts) {
        if (Object.prototype.hasOwnProperty.call(updatedDrafts, recipientId)) {
          const updatedDraft = updatedDrafts[recipientId];
          updatedDraft[addressType] = [...masterEmail[addressType], address];

          updatedDrafts[recipientId] = { ...updatedDraft };
        }
      }

      return { ...state, drafts: updatedDrafts };
    }
    case ComposeWindowActionTypes.bulkView.removeCcAndBccFromAllDrafts: {
      const { drafts, masterEmail } = state;
      const { index: indexToRemove, key: addressType } = payload;

      const updatedDrafts = { ...drafts };
      const updatedMasterAddress = masterEmail[addressType].filter(
        (address, index) => index !== indexToRemove
      );

      for (const recipientId in updatedDrafts) {
        if (Object.prototype.hasOwnProperty.call(updatedDrafts, recipientId)) {
          const updatedDraft = updatedDrafts[recipientId];
          updatedDraft[addressType] = [...updatedMasterAddress];

          updatedDrafts[recipientId] = updatedDraft;
        }
      }

      return { ...state, drafts: updatedDrafts };
    }
    case ComposeWindowActionTypes.bulkView.removeCcAndBccFromMasterEmail: {
      const { masterEmail } = state;
      const { index: indexToRemove, key: addressType } = payload;

      const updatedMasterEmail = { ...masterEmail };
      updatedMasterEmail[addressType] = masterEmail[addressType].filter(
        (address, index) => index !== indexToRemove
      );

      return { ...state, masterEmail: updatedMasterEmail };
    }
    case ComposeWindowActionTypes.bulkView.removeCcAndBccFromSingleEmail: {
      const { drafts } = state;
      const { index: indexToRemove, key: addressType, recipientId } = payload;

      const updatedDraft = { ...drafts[recipientId] };
      updatedDraft[addressType] = updatedDraft[addressType].filter(
        (address, index) => index !== indexToRemove
      );

      return {
        ...state,
        drafts: { ...state.drafts, [recipientId]: updatedDraft },
      };
    }
    case ComposeWindowActionTypes.bulkView.updateSingleDraftIdentity: {
      const { drafts } = state;
      const { identity, recipientId } = payload;

      const updatedDraft = { ...drafts[recipientId], identity };

      return {
        ...state,
        drafts: { ...state.drafts, [recipientId]: updatedDraft },
      };
    }
    case ComposeWindowActionTypes.bulkView.updateAllDraftIdentities: {
      const { drafts } = state;
      const identity = payload;

      const updatedDrafts = { ...drafts };

      for (const recipientId in drafts) {
        if (Object.prototype.hasOwnProperty.call(updatedDrafts, recipientId)) {
          updatedDrafts[recipientId].identity = identity;
        }
      }

      return { ...state, drafts: updatedDrafts };
    }
    case ComposeWindowActionTypes.bulkView.updateMasterEmailIdentity: {
      const { masterEmail } = state;
      const identity = payload;

      const updatedMasterEmail = { ...masterEmail, identity };

      return { ...state, masterEmail: updatedMasterEmail };
    }
    case ComposeWindowActionTypes.bulkView.updateSingleDraftTemplateId: {
      const { drafts } = state;
      const { recipientId, templateId } = payload;

      const updatedDraft = { ...drafts[recipientId], templateId };
      return {
        ...state,
        drafts: { ...drafts, [recipientId]: updatedDraft },
      };
    }
    case ComposeWindowActionTypes.bulkView.updateAllDraftTemplateIds: {
      const { drafts } = state;
      const templateId = payload;

      const updatedDrafts = { ...drafts };
      for (const recipientId in drafts) {
        if (Object.prototype.hasOwnProperty.call(updatedDrafts, recipientId)) {
          updatedDrafts[recipientId].templateId = templateId;
        }
      }

      return { ...state, drafts: updatedDrafts };
    }
    case ComposeWindowActionTypes.bulkView.updateMasterEmailTemplateId: {
      const { masterEmail } = state;
      const templateId = payload;

      const updatedMasterEmail = { ...masterEmail, templateId };

      return { ...state, masterEmail: updatedMasterEmail };
    }
    case ComposeWindowActionTypes.bulkView.updateAllDraftSchedules: {
      const { drafts } = state;
      const sendAt = payload;

      const updatedDrafts = { ...drafts };
      for (const recipientId in drafts) {
        if (Object.prototype.hasOwnProperty.call(updatedDrafts, recipientId)) {
          updatedDrafts[recipientId].sendAt = sendAt;
        }
      }

      return { ...state, drafts: updatedDrafts };
    }
    case ComposeWindowActionTypes.bulkView.updateMasterEmailSchedule: {
      const { masterEmail } = state;
      const sendAt = payload;

      const updatedMasterEmail = { ...masterEmail, sendAt };

      return { ...state, masterEmail: updatedMasterEmail };
    }
    case ComposeWindowActionTypes.bulkView.setMasterEmail: {
      const {
        attachmentIds,
        bcc,
        body,
        cc,
        identity,
        sendAt,
        subject,
        templateId,
      } = payload;
      return {
        ...state,
        masterEmail: {
          ...composeBulkInitState.masterEmail,
          attachmentIds,
          bcc,
          body,
          cc,
          identity,
          sendAt,
          subject,
          templateId,
        },
      };
    }
    case ComposeWindowActionTypes.bulkView.resetBulkView:
      return composeBulkInitState;
    default:
      return state;
  }
};

export const composeWindowAlerts = (state = [], { type, payload }) => {
  switch (type) {
    case ComposeWindowActionTypes.addAlert:
      return [...state, { id: payload.id }];
    case ComposeWindowActionTypes.closeAlertById: {
      return state.filter((alert) => alert.id !== payload.id);
    }
    case ComposeWindowActionTypes.setAlerts:
      return [...payload.alerts];
    case ComposeWindowActionTypes.clearAlerts:
      return [];
    default:
      return state;
  }
};

export const selectedTemplate = (state = null, { type, template }) => {
  switch (type) {
    case ComposeWindowActionTypes.setSelectedTemplate:
      return template;
    case ComposeWindowActionTypes.deselectTemplate:
    case ComposeWindowActionTypes.reset:
      return null;
    default:
      return state;
  }
};

export const recentlyUsedTemplates = (state = [], { type, payload }) => {
  switch (type) {
    case ComposeWindowActionTypes.setRecentlyUsedTemplates:
      return payload;
    default:
      return state;
  }
};

const initialAddressesState = {
  bcc: [],
  cc: [],
  searchResults: {
    bcc: [],
    cc: [],
    to: [],
  },
  to: [],
};

export function composeAddresses(state = initialAddressesState, action) {
  switch (action.type) {
    case ComposeWindowActionTypes.reset:
    case ComposeWindowActionTypes.resetAddresses:
      return initialAddressesState;
    case AddressBarActionTypes.setAddresses: {
      return {
        ...state,
        [action.key]: [...action.addresses],
      };
    }
    case ComposeWindowActionTypes.setSearchResults:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          [action.key]: [...action.results],
        },
      };
    case AddressBarActionTypes.removeAddressByIndex: {
      const newAddresses = state[action.key].filter(
        (address, index) => index !== action.index
      );
      return {
        ...state,
        [action.key]: newAddresses,
      };
    }
    case AddressBarActionTypes.appendAddress: {
      return {
        ...state,
        [action.key]: [...state[action.key], action.address],
      };
    }
    case AddressBarActionTypes.updateAddressByEmail: {
      const newAddresses = state[action.key].map((address) => {
        if (address.email === action.email) {
          return action.data;
        } else {
          return address;
        }
      });
      return {
        ...state,
        [action.key]: newAddresses,
      };
    }
    default:
      return state;
  }
}

const initialComposePersonDetailsState = {
  about: {},
  errored: false,
  events: [],
  loading: true,
  personId: null,
};

export function composePersonDetails(
  state = initialComposePersonDetailsState,
  action
) {
  switch (action.type) {
    case ComposeWindowActionTypes.fetchPersonDetails:
      return {
        ...initialComposePersonDetailsState,
        loading: true,
        personId: action.id,
      };
    case ComposeWindowActionTypes.populatePersonDetails:
      if (state.personId === action.id) {
        return {
          ...state,
          about: action.about,
          errored: false,
          events: action.events,
          loading: false,
        };
      } else {
        return state;
      }
    case ComposeWindowActionTypes.errorPersonDetails:
      if (state.personId === action.id) {
        return {
          ...initialComposePersonDetailsState,
          errored: true,
          loading: false,
          personId: action.id,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

const initialFromState = {
  identities: [],
  identity: {},
};

export function composeFrom(state = initialFromState, action) {
  switch (action.type) {
    case ComposeWindowActionTypes.reset:
      return initialFromState;
    case AddressBarActionTypes.updateIdentity:
      return {
        ...state,
        identity: action.identity,
      };
    default:
      return state;
  }
}

const initialSubjectState = {
  subject: '',
};

export function composeSubject(state = initialSubjectState, action) {
  switch (action.type) {
    case ComposeWindowActionTypes.reset:
      return initialSubjectState;
    case AddressBarActionTypes.updateSubject:
      return {
        ...state,
        subject: action.subject,
      };
    default:
      return state;
  }
}

const initialPitchState = {
  errors: {
    bcc: [],
    body: [],
    cc: [],
    subject: [],
    to: [],
  },
  id: '',
  pitchState: PitchStates.new,
  reminderId: null,
  save: false,
  send: false,
  workflowId: null,
};

export function composePitch(
  state = initialPitchState,
  { type, errors, boolean, id, pitchState, pitchInfo }
) {
  switch (type) {
    case ComposeWindowActionTypes.reset:
      return initialPitchState;
    case ComposeWindowActionTypes.updateValidationErrors:
      return {
        ...state,
        errors,
      };
    case ComposeWindowActionTypes.setSend:
      return {
        ...state,
        send: boolean,
      };
    case ComposeWindowActionTypes.setSave:
      return {
        ...state,
        save: boolean,
      };
    case ComposeWindowActionTypes.setPitchId:
      return {
        ...state,
        id,
        state: PitchStates.draft,
      };
    case ComposeWindowActionTypes.setPitchInfo:
      return {
        ...state,
        ...pitchInfo,
      };
    case ComposeWindowActionTypes.setPitchState:
      return {
        ...state,
        pitchState,
      };
    default:
      return state;
  }
}
export function composeFileAttachmentsLoading(
  state = false,
  { type, loading }
) {
  switch (type) {
    case ComposeWindowActionTypes.setFileAttachmentsLoading:
      return loading;
    default:
      return state;
  }
}

export function liquifyProgress(
  state = { current: 0, maximum: 1 },
  { type, identifier, maximum }
) {
  switch (type) {
    case ComposeWindowActionTypes.incrementLiquifyProgress: {
      const { current, maximum } = state;
      return { ...state, active: true, current: current + 1, maximum };
    }
    case ComposeWindowActionTypes.liquifyInit: {
      return { ...state, active: true, identifier, maximum };
    }
    case ComposeWindowActionTypes.resetLiquifyProgress: {
      return { active: false, current: 0, maximum: 1 };
    }
    default:
      return state;
  }
}

// TL: This is a hack because getUserChannel().unbind(..) doesn't f**king work
export function liquifyPitchPusherInitialized(
  state = false,
  { type, isInitialized }
) {
  switch (type) {
    case ComposeWindowActionTypes.setLiquifyPusherInitialized: {
      return isInitialized;
    }
    default:
      return state;
  }
}
