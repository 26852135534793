import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import Select from 'web/form/components/fields/select';
import { injectIntl, intlShape } from 'react-intl';
import { requiredMultiSelect } from 'web/form/helpers/validators';

class AddToTeamForm extends Component {
  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
    } = props;
    this.selectPlaceholder = formatMessage({
      id: 'common.search',
    });
    this.requiredValidator = requiredMultiSelect();
  }

  render() {
    const {
      handleSubmit,
      addTeamMembersToTeam,
      usersSelectOptions,
      intl: { formatMessage },
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(addTeamMembersToTeam)} noValidate>
        <Field
          className="margin-top-10"
          name="teamMembers"
          component={Select}
          options={usersSelectOptions}
          placeholder={this.selectPlaceholder}
          validate={[this.requiredValidator]}
          multi
          searchable
          clearable
          clearAllText={formatMessage({
            id:
              'web.settings.adminSettings.teamManagement.teamMembers.addMembersToTeam.clearAll',
          })}
        />
      </Form>
    );
  }
}
AddToTeamForm.propTypes = {
  usersSelectOptions: PropTypes.array.isRequired,
  addTeamMembersToTeam: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(reduxForm()(AddToTeamForm));
