import React, { Component } from 'react';
import PeopleImportStepper from 'web/people/components/peopleImportStepper';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Table from 'table/modules/table';
import MapFieldsCell from 'web/people/components/importPeopleStepTwo/mapFieldsCell';
import TextCell from 'table/modules/cells/textCell';
import {
  TABLE_MAP_IMPORT_FIELDS_ID,
  TABLE_MAP_IMPORT_FIELDS_MAX_HEIGHT,
} from 'web/people/libs/peopleConstants';

import './importPeopleStepTwo.scss';

class ImportPeopleStepTwo extends Component {
  render() {
    const { mapFieldsLoading, importFieldMaps } = this.props;

    return (
      <div className="step-two">
        <PeopleImportStepper activeStep={2} />
        <div className="map-import-fields-table tout-table">
          <Table
            tableId={TABLE_MAP_IMPORT_FIELDS_ID}
            columns={this.getColumns()}
            items={importFieldMaps}
            maxHeight={TABLE_MAP_IMPORT_FIELDS_MAX_HEIGHT}
            loading={mapFieldsLoading}
            row={{
              height: 84,
            }}
          />
        </div>
      </div>
    );
  }

  getColumns = () => {
    const {
      intl: { formatMessage },
      actionCreators,
      salesConnectFieldList,
    } = this.props;

    return [
      {
        id: 'csvField',
        name: formatMessage({ id: 'web.people.importer.csvField' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'csvField',
            wrapLines: 2,
          },
        },
        flex: true,
        width: 120,
      },
      {
        id: 'salesConnectField',
        name: formatMessage({ id: 'web.people.importer.salesConnectField' }),
        rowCell: {
          component: MapFieldsCell,
          metadata: {
            items: salesConnectFieldList,
            propertyName: 'salesConnectField',
            onChange: actionCreators.onSalesConnectFieldChange,
            rowsShown: 8,
            setSalesConnectFieldList: actionCreators.setSalesConnectFieldList,
            setPopupAlert: actionCreators.setPopupAlert,
          },
        },
        flex: true,
        initHide: false,
        width: 300,
      },
      {
        id: 'firstRecord',
        name: formatMessage({ id: 'web.people.importer.firstRecord' }),
        rowCell: {
          component: TextCell,
          metadata: {
            property: 'firstRecord',
            wrapLines: 2,
          },
        },
        flex: true,
        width: 120,
      },
    ];
  };
}

ImportPeopleStepTwo.propTypes = {
  intl: intlShape.isRequired,
  mapFieldsLoading: PropTypes.bool.isRequired,
  actionCreators: PropTypes.object.isRequired,
  importFieldMaps: PropTypes.array.isRequired,
  salesConnectFieldList: PropTypes.array.isRequired,
};

export default injectIntl(ImportPeopleStepTwo);
