import PeopleActionTypes from 'web/people/libs/peopleActionTypes';

export const peopleSearchString = (state = '', { type, string = '' }) => {
  switch (type) {
    case PeopleActionTypes.search.set:
      return string;
    case PeopleActionTypes.table.reset:
    case PeopleActionTypes.search.reset:
      return '';
    default:
      return state;
  }
};
