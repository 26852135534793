import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18N from 'languages';
import { injectIntl, FormattedMessage } from 'react-intl';
import sortBy from 'lodash/sortBy';
import LoadingSpinner from 'components/loadingSpinner';
import {
  campaignFilterOptions,
  campaignFilterValues,
} from 'web/commandCenter/modules/campaignSmartFilter/libs/campaignSmartFilterConstants';
import caseFunctional from 'web/commandCenter/helpers/caseFunctional';
import { getImageUrl } from 'web/libs/constants';
import SmartSelect from 'components/selects/smartSelect';
import './campaignSmartFilter.scss';

const CampaignSmartFilter = ({
  campaignFilterValue,
  campaignFilterLoading,
  clearCampaignFilterValue,
  campaigns,
  setCampaignFilterValue,
}) => {
  const isNoFilterSelected = I18N.getStr(
    'web.emails.advancedSearchFilter.noFilterSelected'
  );

  const getDefaultOption = (value) => {
    if (value) {
      return typeof value === 'number'
        ? campaigns.find((item) => item.id === value)
        : campaignFilterOptions
            .map((item) => ({ ...item, name: item.label }))
            .find((item) => item.value === value);
    }

    return { name: isNoFilterSelected };
  };

  const [currentOption, setCurrentOption] = useState(
    getDefaultOption(campaignFilterValue)
  );

  const getCampaignFilterOptions = (dataOptions) =>
    dataOptions.map((item, index) => {
      const { label, value } = item;
      const optionKey = `${index}_option_key`;

      return { id: optionKey, label: label, name: label, value: value };
    });

  const getCustomCampaignFilterOptions = (dataOptions) => {
    const campaigns = dataOptions.map((item) => ({
      ...item,
      label: item.name,
      value: item.name,
    }));

    return sortBy(campaigns, (x) => x.label.toUpperCase());
  };

  const getOptions = (data) => {
    const { campaignFilterOptions, campaigns } = data;

    return [
      ...getCampaignFilterOptions(campaignFilterOptions),
      ...getCustomCampaignFilterOptions(campaigns),
    ];
  };

  const findCurrentOption = (selectedValue) =>
    getOptions({ campaignFilterOptions, campaigns }).find(
      (x) => x.id === selectedValue.id
    );

  const onSelectCampaign = (selectedValue) => {
    const selectedCampaign = findCurrentOption(selectedValue);

    const isCampaignEmails = I18N.getStr(
      'web.emails.advancedSearchFilter.campaignEmails'
    );
    const isNonCampaignEmails = I18N.getStr(
      'web.emails.advancedSearchFilter.nonCampaignEmails'
    );

    const getCurrentOption = caseFunctional({
      [isCampaignEmails]: () => ({
        campaignOption: true,
        value: campaignFilterValues.campaignEmails,
        valueLabel: isCampaignEmails,
      }),
      [isNoFilterSelected]: () => ({
        campaignOption: true,
        value: campaignFilterValues.noFilterSelected,
        valueLabel: isNoFilterSelected,
      }),
      [isNonCampaignEmails]: () => ({
        campaignOption: true,
        value: campaignFilterValues.nonCampaignEmails,
        valueLabel: isNonCampaignEmails,
      }),
    })(() => ({
      customCampaignOption: true,
      value: selectedCampaign.id,
      valueLabel: selectedCampaign.name,
    }));

    const currentFilter = getCurrentOption(selectedCampaign.name)();

    if (currentFilter.value === campaignFilterValues.noFilterSelected) {
      clearCampaignFilterValue();
    } else {
      setCampaignFilterValue({
        filterLabel: I18N.getStr('common.campaign'),
        filterValues: [currentFilter],
      });
    }

    setCurrentOption(selectedCampaign);
  };

  const onChangeCampaign = (selectedValue) => {
    setCurrentOption({
      name: selectedValue,
    });
  };

  const onResetCampaign = () => {
    const isFilterDirty = !(
      currentOption.id &&
      findCurrentOption({ id: currentOption.id }).name === currentOption.name
    );

    if (isFilterDirty) {
      setCurrentOption(getDefaultOption());
      clearCampaignFilterValue();
    }
  };

  return campaignFilterLoading ? (
    <LoadingSpinner imageUrl={getImageUrl} />
  ) : (
    <div className="email-grid-campaign-filter">
      <h3>
        <FormattedMessage id="common.campaigns" />
      </h3>
      <div className="margin-top-most" key="campaign_container">
        <div className="campaign-smart-filter-label">
          <FormattedMessage id="web.commandCenter.advancedSearch.byCampaign" />
        </div>
        <SmartSelect
          className="campaignSmart-filter-combo-box"
          exceptionSelectors={[]}
          inputValue={currentOption.name}
          items={getOptions({ campaignFilterOptions, campaigns })}
          onChange={onChangeCampaign}
          onReset={onResetCampaign}
          onSelect={onSelectCampaign}
          rowHeight={40}
        />
      </div>
    </div>
  );
};

CampaignSmartFilter.defaultProps = {
  campaignFilterSelectedCampaign: null,
  campaignFilterValue: undefined,
  campaigns: [],
};

CampaignSmartFilter.propTypes = {
  campaignFilterLoading: PropTypes.bool.isRequired,
  campaignFilterSelectedCampaign: PropTypes.object,
  campaignFilterValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  clearCampaignFilterValue: PropTypes.func.isRequired,
  setCampaignFilterValue: PropTypes.func.isRequired,
};

export default injectIntl(CampaignSmartFilter);
