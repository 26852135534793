import React from 'react';
import PropTypes from 'prop-types';
import AddressSearchResult from 'web/composeWindow/components/addressSearchResult/addressSearchResult';
import Autocomplete from 'components/inputs/autocomplete';
import { ElasticSearchTypes } from 'web/elasticSearch/libs/elasticSearchConstants';
import { KeyCodes } from 'web/libs/constants';

export default class AddressFieldItemInput extends React.Component {
  state = {
    newAddress: { email: '' },
  };

  resetSearchResults = () => {
    this.props.fetchSearchResults('');
  };

  handleSelection = (selected) => {
    const { appendAddress, groupComposeInit } = this.props;
    this.setState({
      newAddress: { email: '' },
    });
    this.resetSearchResults();

    if (selected.es_document_type === ElasticSearchTypes.groups) {
      groupComposeInit(selected.id);
    } else {
      appendAddress({ email: selected.addresses[0], person: selected });
    }

    this.addressInput.value = '';
  };

  handleKeyDown = (e) => {
    if ([KeyCodes.enter, KeyCodes.tab].includes(e.keyCode)) {
      const value = this.state.newAddress.email.trim();
      if (value !== '') {
        this._populateAddressesId();
      }
    } else if (
      e.keyCode === KeyCodes.backspace &&
      this.state.newAddress.email === ''
    ) {
      this.props.removeLastAddress();
    }
  };

  handleBlur = () => {
    this.resetSearchResults();
    const value = this.state.newAddress.email.trim();
    if (value !== '') {
      this._populateAddressesId();
    }
  };

  handleInputChange = (email) => {
    const { hideGroupResults } = this.props;
    const type = hideGroupResults ? ['person'] : ['person', 'group'];

    this.setState({ newAddress: { email } });
    this.props.fetchSearchResults(email, type);
  };

  _populateAddressesId = () => {
    this.props.createNewAddress(this.state.newAddress);
    this.addressInput.value = '';
  };

  setRef = (input) => (this.addressInput = input);

  resultsRenderer(result) {
    return <AddressSearchResult result={result} />;
  }

  render() {
    const { placeholder, searchResults } = this.props;
    const searchBarHeight = 35;
    const searchBarWidth = 320;

    return (
      <div className="address-input-container field-item">
        <Autocomplete
          height={searchBarHeight}
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          onLeaving={this.handleBlur}
          onSelection={this.handleSelection}
          placeholder={placeholder}
          results={searchResults}
          resultsRenderer={this.resultsRenderer}
          setRef={this.setRef}
          value={this.state.newAddress.email}
          width={searchBarWidth}
          dropdownScrollable
          hideWhenNoValue
        />
      </div>
    );
  }
}

AddressFieldItemInput.propTypes = {
  appendAddress: PropTypes.func.isRequired,
  createNewAddress: PropTypes.func.isRequired,
  fetchSearchResults: PropTypes.func.isRequired,
  groupComposeInit: PropTypes.func,
  hideGroupResults: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  removeLastAddress: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
};

AddressFieldItemInput.defaultProps = {
  groupComposeInit: () => {},
  placeholder: '',
};
