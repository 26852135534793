import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './taskEvent.scss';
import classNames from 'classnames';
import Pill from 'components/pill';
import { ColorClasses } from 'libs/constantsStyles';
import { getDateInformation } from 'web/liveFeed/tasks/helpers/tasksHelper';
import TaskEventType from 'web/task/components/taskEventType/taskEventType';
import TaskEventContact from '../taskEventContact/taskEventContact';
import TaskEventSubject from '../taskEventSubject/taskEventSubject';
import TaskEventAction from '../taskEventAction/taskEventAction';
import TaskEventDota from '../taskEventDota/taskEventDota';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import GlobalPopup from 'components/globalPopup';

class TaskEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
    };

    const { formatMessage } = props.intl;
    this.dotaOptions = [
      {
        label: formatMessage({ id: 'common.edit' }),
        onClick: this._openEditTask,
        value: 'edit',
      },
      {
        label: formatMessage({ id: 'common.delete' }),
        onClick: this._deleteTask,
        value: 'delete',
      },
    ];
  }

  _openEditTask = () => {
    const {
      openEditTask,
      event: { id },
    } = this.props;
    openEditTask(id);
  };

  _deleteTask = () => {
    this.setState({ showDeleteModal: true });
  };

  _getPopup() {
    return (
      <GlobalPopup
        affirmTextId="common.yes"
        cancelTextId="common.cancel"
        onAffirm={this._onSubmitDelete}
        onCancel={this._onCancelDelete}
        onClose={this._onCancelDelete}
        outsideElemClick={this._onCancelDelete}
        refName="deleteTaskGlobalPopup"
        titleId="web.liveFeed.tasks.deleteTaskHeader"
        inner
      >
        <FormattedHTMLMessage id="web.liveFeed.tasks.deleteTaskBody" />
      </GlobalPopup>
    );
  }

  _onCancelDelete = () => {
    this.setState({ showDeleteModal: false });
  };

  _onSubmitDelete = () => {
    const { taskDelete, event: taskToDelete } = this.props;
    taskDelete(taskToDelete);
    this.setState({ showDeleteModal: false });
  };

  _completeTask = () => {
    const { taskDone, event } = this.props;
    taskDone(event);
  };

  render() {
    const {
      event: { subject, title, company, parent_name: parentName, priority },
      overdue,
    } = this.props;
    const { showDeleteModal } = this.state;

    return (
      <div className="task-event">
        {showDeleteModal ? this._getPopup() : null}
        <TaskEventAction completeTask={this._completeTask} />
        <div className="task-event-info">
          <TaskEventSubject
            priority={priority}
            overdue={overdue}
            subject={subject}
          />
          <TaskEventContact
            parentName={parentName}
            title={title}
            company={company}
            openPdv={this._openPdv}
          />
          <div className="event-label-wrapper">{this._getDateLabel()}</div>
        </div>
        <div className="flex flex-right">
          <TaskEventType {...this.props} />
          <TaskEventDota dotaOptions={this.dotaOptions} />
        </div>
      </div>
    );
  }

  _getDateLabel = () => {
    const {
      overdue,
      event: { remind_at: remindAt },
    } = this.props;
    const { date, i18nDate } = getDateInformation(remindAt);
    return (
      <Pill
        id={date}
        color={ColorClasses.blueGrayLight}
        textColor={ColorClasses.blueDark}
        className={classNames('event-label', { 'overdue-task': overdue })}
        textId={i18nDate}
        text={date}
      />
    );
  };

  _openPdv = () => {
    const {
      openPdv,
      event: { person_id: personId },
    } = this.props;
    openPdv(personId);
  };
}

TaskEvent.propTypes = {
  event: PropTypes.object.isRequired,
  subject: PropTypes.string,
  person_id: PropTypes.number,
  intl: intlShape.isRequired,
  openEditTask: PropTypes.func.isRequired,
  openPdv: PropTypes.func.isRequired,
  taskDone: PropTypes.func.isRequired,
  taskDelete: PropTypes.func.isRequired,
  overdue: PropTypes.bool,
};

TaskEvent.defaultProps = {
  subject: '',
  person_id: null,
  overdue: false,
};

export default injectIntl(TaskEvent);
