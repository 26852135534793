import delay from 'lodash/delay';
import { SubmissionError } from 'redux-form';
import { getLogin } from '../services/loginService';
import { Urls } from 'web/libs/constants';
import { navigateToUrl } from 'web/services/routerService';
import {
  convertErrorsForReduxForm,
  defaultErrorMessage,
} from 'web/shared/helpers/parser';
import { LOGIN_OWA_POPUP_SUCCESS_MESSAGE } from 'web/login/libs/loginConstants';
import { updateLanguageSettings } from 'web/settings/myProfile/components/timezoneSettings/actionCreators/timezoneSettingsActionCreators';
import I18N from 'languages';

const INCORRECT_EMAIL_PASSWORD = 'Incorrect email or password.';
const FAILED_LOGIN_ACCOUNT_DISBLED =
  'Consecutive failed logins limit exceeded, account has been temporarily disabled.';

export const doLogin = ({ username, password }, isOwaMode, isGmailMode) =>
  getLogin(username, password)
    .then(() => {
      if (isOwaMode) {
        window.Office.context.ui.messageParent(LOGIN_OWA_POPUP_SUCCESS_MESSAGE);
      } else if (isGmailMode) {
        window.opener.postMessage({ action: 'login', success: true }, '*');
        window.close();
      } else if (I18N.getCookie('i18nLangUpdated') === 'true') {
        updateLanguageSettings().finally(() => {
          navigateToUrl(window.originalUrl || Urls.commandCenter);
        });
      } else {
        navigateToUrl(window.originalUrl || Urls.commandCenter);
      }
    })
    .catch((err) => {
      let errorMessages = {};

      if (err.status === 401) {
        try {
          const responseText = JSON.parse(err.response.text);
          const showMessageDurationInMilliseconds = 4000;

          if (responseText.expired) {
            errorMessages = {
              _error: 'login.form.passwordExpired',
              password: ' ',
            };
            delay(() => {
              navigateToUrl(Urls.passwordResetNew);
            }, showMessageDurationInMilliseconds);
          } else if (
            convertErrorsForReduxForm(responseText.errors)._error ===
            INCORRECT_EMAIL_PASSWORD
          ) {
            errorMessages = {
              _error: 'login.form.incorrectEmailPassword',
            };
          } else if (
            convertErrorsForReduxForm(responseText.errors)._error ===
            FAILED_LOGIN_ACCOUNT_DISBLED
          ) {
            errorMessages = {
              _error: 'login.form.failedLoginAccountDisabled',
            };
          } else {
            errorMessages = convertErrorsForReduxForm(responseText.errors);
          }
        } catch (error) {
          errorMessages = defaultErrorMessage;
        }
      } else {
        errorMessages = defaultErrorMessage;
      }

      throw new SubmissionError(errorMessages);
    });
