/* istanbul ignore next */
import { basePost } from 'web/services/api';
import { Urls } from 'web/libs/constants';
import ToutQueryParser from 'web/libs/toutQueryParser';

const maxExpansions = 1024;

/* Translated from search_parse_helper.coffee - SearchParseHelper isFuzzyField() */
/* istanbul ignore next */
function isFuzzyField(field) {
  const array = [
    'first_name',
    'last_name',
    'full_name',
    'name',
    'email',
    'addresses',
    'title',
    'company',
    'category',
  ];
  return array.indexOf(field) > -1;
}

/* Translated from search_parse_helper.coffee - SearchParseHelper generateQuery() */
/* istanbul ignore next */
function searchParseGenerateQuery(element) {
  if (element.op === ':' || element.op === ':~') {
    const match = {};
    if (isFuzzyField(element.field)) {
      match[element.field] = {
        max_expansions: maxExpansions,
        query: element.val,
        type: 'phrase_prefix',
      };
    } else {
      match[element.field] = element.val;
    }
    return { match };
  } else if (element.op === '>') {
    const range = {};
    range[element.field] = { gt: element.val };
    return { range };
  } else if (element.op === '<') {
    const range = {};
    range[element.field] = { lt: element.val };
    return { range };
  } else if (element.op === '>=') {
    const range = {};
    range[element.field] = { gte: element.val };
    return { range };
  } else if (element.op === '<=') {
    const range = {};
    range[element.field] = { lte: element.val };
    return { range };
  }
  return null;
}

/* Translated from search_parse_helper.coffee - SearchParseHelper parse() */
/* istanbul ignore next */
export function searchParseHelperParse(input) {
  const queryFields = [];
  let parsed = [];

  try {
    parsed = ToutQueryParser.parse(input);
  } catch (e) {
    return [
      {
        match: {
          _all: {
            max_expansions: maxExpansions,
            query: input,
            type: 'phrase_prefix',
          },
        },
      },
    ];
  }

  if (parsed.length === 0) {
    return [
      {
        match: {
          _all: {
            max_expansions: maxExpansions,
            query: input,
            type: 'phrase_prefix',
          },
        },
      },
    ];
  }

  parsed.forEach((element) => {
    queryFields.push(searchParseGenerateQuery(element[0]));
  });
  return queryFields;
}

/* Translated from contact_autocomplete_view.coffee - constructAutocompleteEsQuery() */
/* istanbul ignore next */
export function buildAutocompleteQuery(input, userId, subscriptionId, teamIds) {
  let queries = [];
  const fAnd = [];
  let queryS;

  if (input.length > 0) {
    queries = searchParseHelperParse(input);
  } else {
    queries.push({ match_all: {} });
  }

  if (queries.length === 1) {
    const [first] = queries;
    queryS = first;
  } else {
    queryS = { bool: { must: [] } };
    queries.forEach((qy) => queryS.bool.must.push(qy));
  }

  fAnd.push({
    or: [
      { term: { user_id: userId } }, //app.account.id
      { term: { subscription_id: subscriptionId } }, //app.account.get("subscription").id
      { terms: { team_ids: teamIds } }, //_.pluck(app.account.visibleTeams(), 'id')
    ],
  });

  const filter = {};
  if (fAnd.length > 0) {
    filter.and = fAnd;
  }

  const esQuery = { filtered: { filter, query: queryS } };
  const sort = { pitches_sent: { order: 'desc' } };
  const query = {
    fields: ['id'],
    from: 0,
    query: esQuery,
    size: 5,
    sort,
  };

  return JSON.stringify(query);
}

export const getAllItemsIdsFetch = (params) =>
  new Promise((resolve, reject) => {
    basePost(Urls.esFetchIds, params, resolve, reject);
  });
