import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CustomDomainPage from './page';
import LoadingSpinner from 'components/loadingSpinner';
import SafelyChangeRouteContainer from 'web/safelyChangeRoute/containers/safelyChangeRouteContainer';
import { getImageUrl } from 'web/libs/constants';

const CustomDomain = (props) => {
  const { loading, isSafeToChangeRoute } = props;
  return (
    <Fragment>
      {loading && <LoadingSpinner imageUrl={getImageUrl} />}
      <CustomDomainPage {...props} />
      <SafelyChangeRouteContainer isSafeToChangeRoute={isSafeToChangeRoute} />
    </Fragment>
  );
};

CustomDomain.propTypes = {
  loading: PropTypes.bool.isRequired,
  isSafeToChangeRoute: PropTypes.bool.isRequired,
};

export default CustomDomain;
